import React, { useEffect, useMemo, useState } from 'react'
import MetaTags from "react-meta-tags";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Spinner,
    Badge
} from "reactstrap";
// import { Badge } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import Drawer from 'react-modern-drawer'
import TableContainer from './Components/TableContainer'
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { FaArrowRight } from 'react-icons/fa';
import { retriveIncidentsList,retriveIncidentsInfo  } from   '../../../store/incidentReport/incdreportslice'

const IncidentList = ( props ) => {
    const dispatch = useDispatch()
    const incidentReport = useSelector(state => state.incdreportslice)
    console.log(incidentReport,'incidentReport'
    );
    const incidentsList = incidentReport.incidentsList

    useEffect(()=>{
        console.log("Incidents List ");
         sessionStorage.removeItem("incidentInfo")
        dispatch(retriveIncidentsList())
    } , [])
    const columns = useMemo(() => [
        {
            accessor: "action",
            Header: "Incidents",
            filterable: true,
            Cell: ({ cell: { value } }) =>  
            <div style={{
                maxWidth: '500px',  // Set your desired max width
                whiteSpace: 'wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}>
                {value}
            </div>,
        },
       
       
        // {
        //     accessor: "action",
        //     Header: "Incidents",
        //     filterable: true,
        //     Cell: ({ cell: { value } }) => <>{value}</>,
        // },
        {
            accessor: "incd_type.incd_type_name",
            Header: "Incident Type",
            filterable: true,
            Cell: ({ cell: { value } }) =>
                //  <>{value.incd_type_name}</>,
            <div style={{
                maxWidth: '500px',  // Set your desired max width
                whiteSpace: 'wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}>
                {value}
            </div>,
        },
        {
            accessor: "incd_h_location.node_name",
            Header: "Location",
            filterable: true,
            Cell: ({ cell: { value } }) => <>{value}</>,
        },

        {
            accessor: "task_priority.severity_name",
            Header: "Severity",
            filterable: true,
            Cell: ({ cell: { value } }) => 
                <div style={{
                    maxWidth: '500px',  // Set your desired max width
                    whiteSpace: 'wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>
                    {value === undefined ? "-" : value}
                </div>,
            // <>{value === null ? "-" : value?.severity_name}</>
            
        },

        // {
        //     accessor: "task_users",
        //     Header: "Allocated To",
        //     filterable: true,
        //     Cell: ({ cell: { value } }) => <>{value.map((ele,idx)=>{
        //         return(
        //             <>{ele.name},</>
        //         )
        //     })}</>,
        // },



        {
            accessor: "task_users",
            Header: "Allocated To",
            filterable: true,
            Cell: ({ cell: { value } }) =>
             <>
           <>
  {value.length > 0 ? (
    value.map((ele, idx) => {
      if (ele.node_id) {
        return (
          <span key={idx}>
            {ele.name}
            {idx < value.length - 1 && value[idx + 1].node_id ? ', ' : ''}
          </span>
        );
      }
      return null;
    })
  ) : (
    '-'
  )}
</>
            </>,
        },


        {
            accessor: "incd_resp_person",
            id: "managed_by",  // Add a unique id for the "Managed By" column
            Header: "Managed By",
            filterable: true,
            Cell: ({ cell: { value } }) => {
                return (
                    <>

                        {value.map((ele, idx) => {
                            // console.log('ele.node_id :>> ', ele);

                            return (
                                <span key={idx}>{ele.user_name} 
                                {idx < value.length - 1  ? ', ' : ''}
                                </span>
                            );

                        })}

                    </>
                );
            },
        },



        // {
        //     accessor: "task_status",
        //     Header: "Status",
        //     filterable: true,
        //     Cell: ({ cell: { value } }) => <>{value === "0" ?"Not Started" :value === "1" ? "In progress" :value === "2" ? "Completed" : value === "3" ? "Closed" : value === "4" ? "Overdue" :value === "5" ? "Reopen" :value==="6" ?  "Rejected" :""}</>,
        // },
      
        {
            accessor: "task_status",
            Header: "Status",
            filterable: true,
            // Cell: ({ cell: { value } }) => {

                Cell: ({ cell: { value }, row: { original } }) => {

                
                let statusLabel = "";
                let badgeVariant = "";

                if(original.incd_rejec_by){
                    statusLabel = "Rejected";
                    badgeVariant = "danger";
                }
                else  if(original.task_users.length <  1){
                    statusLabel = "Not Allocated";
                    badgeVariant = "secondary"; // Light Grey
                }

        
                switch (value) {
                    case "0":
                        statusLabel = "Not Started";
                        badgeVariant = "secondary"; 
                        break;
                    case "1":
                        statusLabel = "In Progress";
                        badgeVariant = "warning";
                        break;
                    case "2":
                        statusLabel = "Completed";
                        badgeVariant = "primary"; 
                        break;
                    case "3":
                        statusLabel = "Closed";
                        badgeVariant = "success";
                        break;
                    case "4":
                        statusLabel = "Overdue";
                        badgeVariant = "danger"; 
                        break;
                    case "5":
                        statusLabel = "Reopen";
                        badgeVariant = "dark"; 
                        break;
                 
                }
        
                return (
                    <>
                    {
                    value === "2" || value === "1" || value ==="3"  ?
                    <>
                    <span 
                    className={"font-size-12 badge bg-" +badgeVariant}
                     >
                        {statusLabel}
                    </span>  (<span>{original.task_completion_perc === null ? 0 : original.task_completion_perc}% </span>)
                    </>
                  
                    :
                    <span 
                    className={"font-size-12 badge bg-" +badgeVariant}
                     >
                        {statusLabel}
                    </span>
                    }
                    </>
                );
            },
        } ,

      
        // {
        //     accessor: "task_status",
        //     Header: "Status",
        //     filterable: true,
        //     // Cell: ({ cell: { value } }) => {

        //         Cell: ({ cell: { value }, row: { original } }) => {

                
        //         let statusLabel = "";
        //         let badgeVariant = "";

        //         if(original.incd_rejec_by){
        //             statusLabel = "Rejected";
        //             badgeVariant = "danger";
        //         }
        //         else  if(original.task_users.length <  1){
        //             statusLabel = "Not Allocated";
        //             badgeVariant = "info"; // Light Grey
        //         }







        
        //         switch (value) {
        //             case "0":
        //                 statusLabel = "Not Started";
        //                 badgeVariant = "secondary"; // Grey
        //                 break;
        //             case "1":
        //                 statusLabel = "In Progress";
        //                 badgeVariant = "primary"; // Blue
        //                 break;
        //             case "2":
        //                 statusLabel = "Completed";
        //                 badgeVariant = "success"; // Green
        //                 break;
        //             case "3":
        //                 statusLabel = "Closed";
        //                 badgeVariant = "success"; // Dark Grey/Black
        //                 break;
        //             case "4":
        //                 statusLabel = "Overdue";
        //                 badgeVariant = "danger"; // Red
        //                 break;
        //             case "5":
        //                 statusLabel = "Reopen";
        //                 badgeVariant = "warning"; // Yellow/Orange
        //                 break;
                 
        //         }
        
        //         return (
        //             <Badge 
        //             className={"font-size-12 badge-soft-" +badgeVariant}
        //              >
        //                 {statusLabel}
        //             </Badge>
        //         );
        //     },
        // } ,
      
      
      
        // {
        //     accessor: "task_status",
        //     Header: "Status",
        //     filterable: true,
        //     Cell: ({ cell: { value } }) => {
        //         let statusLabel = "";
        //         let badgeVariant = "";
        
        //         switch (value) {
        //             case "0":
        //                 statusLabel = "Not Started";
        //                 badgeVariant = "secondary"; // Grey
        //                 break;
        //             case "1":
        //                 statusLabel = "In Progress";
        //                 badgeVariant = "primary"; // Blue
        //                 break;
        //             case "2":
        //                 statusLabel = "Completed";
        //                 badgeVariant = "success"; // Green
        //                 break;
        //             case "3":
        //                 statusLabel = "Closed";
        //                 badgeVariant = "success"; // Dark Grey/Black
        //                 break;
        //             case "4":
        //                 statusLabel = "Overdue";
        //                 badgeVariant = "danger"; // Red
        //                 break;
        //             case "5":
        //                 statusLabel = "Reopen";
        //                 badgeVariant = "warning"; // Yellow/Orange
        //                 break;
        //             case "6":
        //                 statusLabel = "Rejected";
        //                 badgeVariant = "danger"; // Red
        //                 break;
        //             default:
        //                 statusLabel = "Not Allocated";
        //                 badgeVariant = "light"; // Light Grey
        //         }
        
        //         return (
        //             <Badge 
        //             className={"font-size-12 badge-soft-" +badgeVariant}
        //              >
        //                 {statusLabel}
        //             </Badge>
        //         );
        //     },
        // },

        {
            accessor: "createdAt",
            Header: "Submitted On",
            filterable: true,
            Cell: ({ cell: { value } }) => <>{new Intl.DateTimeFormat("en-GB", {
                month: "short",
                day: "2-digit",
                year: "numeric",
            }).format(new Date(value))}</>,
        },
        // {
        //     accessor: "incd_closed_on",
        //     Header: "Closed on",
        //     filterable: true,
        //     Cell: ({ cell: { value } }) => <>{value}</>,
        // },
        // {
        //     accessor: "id",
        //     Header: "Actions",
        //     Cell: ({ row }) => (

        //         <div>
        //             <Link
        //                 to={'/incident_info'}
        //                 style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '24px' }}
        //             >
        //                {/* { sessionStorage.setItem("incidentInfo",JSON.stringify(row.original))} */}
                        
        //                 <FaArrowRight />
        //             </Link>
        //         </div>
        //     )
        // }
        {
            accessor: "id",
            Header: "Action",
            Cell: ({ row }) => {
                const handleClick = () => {
                    sessionStorage.setItem("incidentInfo", JSON.stringify(row.original))
                    dispatch(retriveIncidentsInfo(row.original))
                    props.history.push('/incident_info');

                };
                return (
                    <div>
                        <button
                            onClick={handleClick}
                            style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '18px' }}
                        >
                            <FaArrowRight />
                        </button>
                        {
                            (row.original.task_status === "1" || row.original.task_status === "2" || row.original.task_status === "3" || row.original.task_status === "0") &&
                            <button className='btn btn-sm btn-info' onClick={() => { props.history.push("/my_task"); sessionStorage.setItem("task_info", JSON.stringify(row.original)) }}>
                                Chat
                            </button>


                        }
                            
                    </div>
                );
            }
        }
    ], []);




    return (
        <React.Fragment>
            <div className="page-content">

                <MetaTags>
                    <title>AuditVista | Manage Incidents</title>
                </MetaTags>

                <Container fluid>
                    <Breadcrumbs title="Manage Incidents" breadcrumbItem="Manage Incidents" />
                    {
                        incidentsList.length > 0 ?
                        <>
                            <Row>
                                <Col lg='12'>
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={incidentsList}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={true}
                                                customPageSize={10}
                                                style={{ width: "100%" }}
                                                isPagination={true}
                                                filterable={false}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            </>
                            :
                            <>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody style={{ height: "100vh" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            </>

                    }
                
                </Container>
            </div>
        </React.Fragment>
    )
}

export default IncidentList