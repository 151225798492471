import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import SweetAlert from "react-bootstrap-sweetalert"



const _ = require('lodash')
var urlSocket = require("../../../../helpers/urlSocket")

export default class NewEndPoint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: true,
            dataloaded: false,
            enableAddCode:true
           // auditUser:'choose',
           // target_date: moment(this.props.epdata.audit_pbd_ends_on,).format('YYYY-MM-DD'),

        };


    }

    componentDidMount() {
        var db_info = JSON.parse(sessionStorage.getItem('db_info'))
        this.setState({db_info},()=>this.getAllUsers())
    }

    async getAllUsers() {
        try {
            urlSocket.post("webphlbconf/getallusers", {
                userInfo: {
                    encrypted_db_url:this.state.db_info.encrypted_db_url,
                    created_by: this.props.user_data._id,
                    company_id: this.props.user_data.company_id,
                },
                publishtemplateInfo: this.props.audit_data
            })
                .then((response) => {
                    this.setState({
                        userdata: response.data.data,
                        dataloaded: true
                    })
                })
        } catch (error) {
            console.log("catch error", error)
        }
    }

    changeAuditUserHandler = (event) => {
        if (String(event.target.value) === this.state.reviewUser) {
            this.setState({ auditUser:"",dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
        }
        else {
            var getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value })
            var getUser = this.state.userdata[getNewUserIndex]
                var audit_user = {
                    audit_type: "1",
                    designation: getUser.designation,
                    name: getUser.firstname,
                    user_code: getUser.usercode,
                    user_id: getUser._id,
                }
                this.setState({audit_user, auditUser:String(event.target.value)})
        }

    }

    changeReviewUserHandler = (event) => {
        if (String(event.target.value) === this.state.auditUser) {
            this.setState({ reviewUser:"",dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
        }
        else {
            var getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value })
            var getUser = this.state.userdata[getNewUserIndex]
                var review_user = {
                    audit_type: "2",
                    designation: getUser.designation,
                    name: getUser.firstname,
                    user_code: getUser.usercode,
                    user_id: getUser._id,
                }
                this.setState({review_user, reviewUser:String(event.target.value)})
        }

    }

   

    getadtRevUsers() {
        var auditUser
        var getIndex = _.findIndex(this.props.epdata.audit_pbd_users, { audit_type: "1" })
        if (getIndex !== -1) {
            auditUser = this.props.epdata.audit_pbd_users[getIndex]
        }
        else {
            auditUser = undefined
        }

        var reviewUser
        var getIndex = _.findIndex(this.props.epdata.audit_pbd_users, { audit_type: "2" })
        if (getIndex !== -1) {
            reviewUser = this.props.epdata.audit_pbd_users[getIndex]
        }
        else {
            reviewUser = undefined
        }
        this.setState({
            auditUser,
            reviewUser
        })
    }

    listOutCategory = () => {
        var category = _.map(this.props.endpoints, item => {
            return item.hlevel
        })

        var uniqueHlevels = _.uniqBy(category)

        return uniqueHlevels
    }

    selectCat = (event) => {
        this.setState({ createNewCat: event.target.value == "1" ? true : false, catErrorEnabled: false, catError: "" })

    }

    validateNode = (values) => {
        var flatData = this.props.endpoints

        var returnValue = true
        var getCatValue = -1

        if (values.hlevel_cat == "1") {
            var getSubtitle = String(values.hlevel).replace(/\s/g, '').toLowerCase()
            getCatValue = _.findIndex(flatData, function (o) { if (String(o.hlevel).replace(/\s/g, '').toLowerCase() == getSubtitle) { return true } else { return false } });

            if (getCatValue != -1) {
                this.setState({
                    catErrorEnabled: true,
                    hlevel: values.hlevel,
                    hlevel_cat: values.hlevel_cat,
                    catError: "Category is already available",
                })
                returnValue = false
            }
        }
        else if (values.hlevel_cat == "0" || values.hlevel_cat == "") {
            this.setState({
                catErrorEnabled: true,
                hlevel: "",
                hlevel_cat: values.hlevel_cat,
                catError: "Select Category",
            })
            returnValue = false
        }
        else {
            this.setState({
                catErrorEnabled: false,
                catError: "",
            })
        }

        if (this.state.enableCode) {
            var getCode = String(values.code).replace(/\s/g, '').toLowerCase()
            var getCodeValue = _.findIndex(flatData, function (o) {
                if (String(o.code).replace(/\s/g, '').toLowerCase() == getCode) { return true } else { return false }
            });

            if (getCode === this.state.getCode) {

            }
            else {
                if (getCodeValue != -1) {
                    this.setState({
                        codeErrorEnabled: true,
                        getCode: values.code,
                        codeError: "Code is already available"
                    })
                    returnValue = false
                }
                else {
                    this.setState({
                        codeErrorEnabled: false,
                        codeError: "",
                    })
                }
            }


        }


        return returnValue
    }

    submitData = (event, values) => {
        var allowToWrite = this.validateNode(values)

        var endpointInfo
        if(this.props.publishtemplateInfo.enable_review)
        {
            endpointInfo = {
                "name" : values.hlevel_name, 
                "code" : values.code !== undefined?values.code:"", 
                "category" : values.hlevel_cat, 
                "audit_pbd_users" : [
                    this.state.audit_user, 
                    this.state.review_user,
                ], 
                "audit_pbd_ends_on" : values.target_date, 
            }
        }
        else
        {
            endpointInfo = {
                "name" : values.hlevel_name, 
                "code" : values.code !== undefined?values.code:"", 
                "category" : values.hlevel_cat, 
                "audit_pbd_users" : [
                    this.state.audit_user, 
                ], 
                "audit_pbd_ends_on" : values.target_date, 
            }
        }
        



        if (allowToWrite) {

            try {
                
                urlSocket.post("webpbdadtdta/addpdbadtendpoint", {
                    userInfo:{
                        encrypted_db_url:this.state.db_info.encrypted_db_url,
                        _id:this.props.user_data._id,
                        company_id:this.props.user_data.company_id
                    },
                    endpointInfo,
                    auditInfo:this.props.publishtemplateInfo
                })
                .then(response => {
                    if(response.data.response_code === 500)
                    {
                        this.props.onClose()
                    }
                    else
                    {
                        this.setState({
                            error_message:response.data.message
                        })
                    }
                })

            } catch (error) {
                console.log("catch error", error)
            }

        }

    }


    render() {
        if (this.state.dataloaded) {
            const optionGroup = this.listOutCategory()
            return (
                <React.Fragment>
                    <Container fluid>
                        <Row >
                            <Col >
                                <Card className="overflow-hidden">
                                    {
                                        !this.state.formDisabled ?
                                            <CardBody className="pt-0">
                                                <div className="p-5">
                                                    <AvForm className="form-horizontal" onValidSubmit={this.submitData} onInvalidSubmit={this.handleInvalidSubmit}>
                                                        <Row className="my-4">
                                                        <div className="d-sm-flex align-items-center justify-content-between">
                                                        <div className="text-danger font-size-18">Location Information</div>
                                                <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
                                            </div>
                                                            <hr className="my-4" />
                                                        </Row>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="hlevel_name"
                                                                label="Name"
                                                                type="text"
                                                                //defaultValue={this.state.hlevel_name}
                                                                value={this.state.hlevel_name}
                                                                required
                                                                placeholder="Enter Name"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="" htmlFor="autoSizingSelect">Category</Label>
                                                            <AvInput
                                                                type="select"
                                                                name="hlevel_cat"
                                                                label="Name"
                                                                value={this.state.hlevel_cat}
                                                                className="form-select"
                                                                id="cate"
                                                                required
                                                                onChange={(e) => this.selectCat(e)}>
                                                                <option value="" disabled selected>Choose...</option>
                                                                <option value="1"  >Create New</option>
                                                                {
                                                                    optionGroup.map((data, idx) => {
                                                                        return (
                                                                            <option value={data} key={idx}>{data}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </AvInput>
                                                        </div>
                                                        {
                                                            this.state.createNewCat ?
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        name="hlevel"
                                                                        type="text"
                                                                        value={this.state.hlevel}
                                                                        required
                                                                        onChange={() => this.setState({ catErrorEnabled: false, catError: "" })}
                                                                        placeholder="Enter New Category"
                                                                    />
                                                                </div> : null
                                                        }
                                                        {this.state.catErrorEnabled ? <Label className="text-danger" style={{ margin: '0 0 7px 0' }} htmlFor="autoSizingSelect">{this.state.catError}</Label> : null}
                                                        {
                                                            this.state.enableAddCode ? <div className="form-check mb-3">
                                                                <AvInput
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    name="code_enable"
                                                                    id="invalidCheck"
                                                                    value={this.state.enableCode}
                                                                    onChange={() => this.setState({ enableCode: !this.state.enableCode ? true : false })}
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    htmlFor="invalidCheck"
                                                                >{" "}
                                                                    Add Code
                                                                </Label>
                                                            </div> : null
                                                        }

                                                        {
                                                            this.state.enableCode ?
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        name="code"
                                                                        label="Code"
                                                                        type="text"
                                                                        //errorMessage={this.state.codeError}
                                                                        value={this.state.getCode}
                                                                        validate={{
                                                                            required: { value: true, errorMessage: this.state.codeError },
                                                                        }}
                                                                        placeholder="Enter Code"
                                                                    />
                                                                    {this.state.codeErrorEnabled ? <Label className="text-danger" style={{ margin: '5px 0 7px 0' }} htmlFor="autoSizingSelect">{this.state.codeError}</Label> : null}

                                                                </div> : null
                                                        }

                                                        <Row className="">

                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="target_date"
                                                                    type="date"
                                                                    label="Audit end date"
                                                                    errorMessage="Please provide a valid date."
                                                                    className="form-control"
                                                                    value={this.state.target_date}
                                                                    onChange={(e) => { this.setState({ target_date: e.target.value }) }}
                                                                    validate={{ required: { value: true } }}
                                                                    id="td"
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Label className="" htmlFor="">Audit assigned to</Label>
                                                                <AvInput
                                                                    type="select"
                                                                    name="auditUser"
                                                                    label="auditUser"
                                                                    value={this.state.auditUser}
                                                                    className="form-select"
                                                                    id="adtuser1"
                                                                    required
                                                                    onChange={(e) => this.changeAuditUserHandler(e)}>
                                                                    <option value="" disabled >Choose...</option>
                                                                    {/* <option value="add" >Add New User</option> */}
                                                                    {
                                                                        this.state.userdata.map((data, idx) => {
                                                                            return (
                                                                                <option value={data._id}  key={idx}>{data.firstname} ({data.usercode}) - {data.designation}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </AvInput>
                                                            </div>

                                                            {
                                                                this.props.publishtemplateInfo.enable_review ?
                                                                    <div className="mb-3">
                                                                        <label>Review assigned to</label>
                                                                        <AvInput
                                                                            type="select"
                                                                            name="reviewUser"
                                                                            label="Reviewer"
                                                                            value={this.state.reviewUser}
                                                                            className="form-select"
                                                                            id="rvwUser1"
                                                                            required
                                                                            onChange={(e) => this.changeReviewUserHandler(e)}>
                                                                            <option value="" disabled >Choose...</option>
                                                                            {/* <option value="add" >Add New User</option> */}
                                                                            {
                                                                                this.state.userdata.map((data, idx) => {
                                                                                    return (
                                                                                        <option value={data._id} key={idx}>{data.firstname} ({data.usercode}) - {data.designation}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </AvInput>
                                                                    </div> : null
                                                            }

                                                        </Row>


                                                            <Row>
                                                                <Col sm="12" >
                                                                    <span className="font-size-16 text-danger">{this.state.error_message}</span>
                                                                </Col>
                                                            </Row>
                                                        <div className="mt-4 d-flex flex-row">
                                                            <button
                                                                className="btn btn-success btn-block m-1"
                                                                type="submit"
                                                            >
                                                                {this.props.editItem !== null ? "Update Location" : "Add Location"}
                                                            </button>
                                                            <button
                                                                className="btn btn-danger btn-block m-1"
                                                                onClick={() => this.props.onCancel()}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </CardBody> :
                                            <CardBody>
                                                <div className="mt-4 text-center">
                                                    <div className="mb-0" style={{ fontSize: 20 }}>
                                                        <span className="text-danger">{this.state.firstname}</span> has added successfully
                                                    </div>
                                                    <div style={{ marginTop: 20 }}>
                                                        <Link className="btn btn-success" onClick={() => this.props.onClose()}>
                                                            Back
                                                        </Link>
                                                    </div>
                                                </div>
                                            </CardBody>
                                    }
                                </Card>

                            </Col>
                        </Row>
                        {this.state.dataAlert ? (
                            <SweetAlert
                                title={this.state.alertMessage}
                                onConfirm={() => this.setState({ dataAlert: false })}
                            ></SweetAlert>
                        ) : null}
                    </Container>
                </React.Fragment>
            )
        }
        else {
            return null
        }
    }
}