import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../../store/chat/chatslice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import Drawer from '@material-ui/core/Drawer';
import {createGroup} from '../../../helpers/socket'

const CreateGroupDrawer = ({ open, toggle ,userData}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1); // Step 1: Select users, Step 2: Enter group details

  useEffect(() => {
    dispatch(getAllUsers("0"));
  }, [dispatch]);

  const chatData = useSelector((state) => state.chatslice);
  const recentuserlist = chatData.recentuserlist;

  const filtereduser = _.filter(recentuserlist,{type:"0"})

  const userList = chatData.userlist;


  const userlistfilter = _.reject(userList,{_id:userData.user_data._id})




  const formik = useFormik({
    initialValues: {
      groupName: '',
      groupDescription: '',
      selectedUsers: [],
    },
    validationSchema: Yup.object({
      groupName: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('Required'),
      groupDescription: Yup.string()
        .min(10, 'Must be at least 10 characters')
        .required('Required'),
      selectedUsers: Yup.array()
        .min(1, 'Please select at least one user')
        .required('Please select at least one user'),
    }),
    onSubmit: values => {
      console.log('Form values:', values);
      createGroup(values)
      formik.resetForm()
      setStep(1);
      toggle(); 
    },
  });

  const handleCheckboxChange = user => {
    const selectedUsers = formik.values.selectedUsers.includes(user)
      ? formik.values.selectedUsers.filter(u => u !== user)
      : [...formik.values.selectedUsers, user];
    formik.setFieldValue('selectedUsers', selectedUsers);
  };

  const proceedToNextStep = () => {
    if (formik.values.selectedUsers.length > 0) {
      setStep(2);
    } else {
      formik.setFieldTouched('selectedUsers', true);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggle}
    >
      <div style={{ width: '600px', padding: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', borderBottom: '1px solid #ccc' }}>
                  <h6>Create Group</h6>

                  <div
                      className={`rounded-circle avatar-xs bg-danger`}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', cursor: 'pointer' }}
                      onClick={() => toggle()}
                  >
                      <i className="mdi mdi-close text-white" />
                  </div>
              </div>
        <form onSubmit={formik.handleSubmit}>
          {step === 1 ? (
            <>
              <FormGroup>
                <h6 className='mt-3' >Recents</h6>
                {filtereduser.map((user, index) => (
                  <div className="d-flex flex-column mb-2 p-2 bg-light" key={"ru" + index} style={{ borderRadius: 25 }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-row align-items-center">
                        <input
                          type="checkbox"
                          className="me-2"
                          checked={formik.values.selectedUsers.includes(user)}
                          onChange={() => handleCheckboxChange(user)}
                        />
                        <div
                          className={`rounded-circle avatar-xs me-2 bg-success text-dark bg-opacity-25`}
                          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
                        >
                          {user.sender_name?.charAt(0).toUpperCase()}
                        </div>
                        <div className="d-flex flex-column">
                          <div className="font-size-13 text-dark">{user.sender_name}</div>
                          <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </FormGroup>
              <FormGroup>
                <h6>Contacts</h6>
                {userlistfilter.map((user, index) => (
                  <div className="d-flex flex-column mb-2 p-2 bg-light" key={"cu" + index} style={{ borderRadius: 25 }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-row align-items-center">
                        <input
                          type="checkbox"
                          className="me-2"
                          checked={formik.values.selectedUsers.includes(user)}
                          onChange={() => handleCheckboxChange(user)}
                        />
                        <div
                          className={`rounded-circle avatar-xs me-2 bg-success text-dark bg-opacity-25`}
                          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
                        >
                          {user.firstname.charAt(0).toUpperCase()}
                        </div>
                        <div className="d-flex flex-column">
                          <div className="font-size-13 text-dark">{user.firstname}</div>
                          <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {formik.touched.selectedUsers && formik.errors.selectedUsers ? (
                  <FormFeedback className="d-block">{formik.errors.selectedUsers}</FormFeedback>
                ) : null}
              </FormGroup>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="button" color="success" onClick={proceedToNextStep}>
                  Next
                </Button>
              </div>
            </>
          ) : (
            <>
              <FormGroup>
                <Label for="groupName">Group Name</Label>
                <Input
                  id="groupName"
                  name="groupName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.groupName}
                  invalid={formik.touched.groupName && !!formik.errors.groupName}
                />
                {formik.touched.groupName && formik.errors.groupName ? (
                  <FormFeedback>{formik.errors.groupName}</FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label for="groupDescription">Group Description</Label>
                <Input
                  id="groupDescription"
                  name="groupDescription"
                  type="textarea"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.groupDescription}
                  invalid={formik.touched.groupDescription && !!formik.errors.groupDescription}
                />
                {formik.touched.groupDescription && formik.errors.groupDescription ? (
                  <FormFeedback>{formik.errors.groupDescription}</FormFeedback>
                ) : null}
              </FormGroup>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="button" color="secondary" onClick={() => setStep(1)} style={{ marginRight: '10px' }}>
                  Back
                </Button>
                <Button type="submit" color="success">
                  Create Group
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </Drawer>
  );
};

export default CreateGroupDrawer;




// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAllUsers } from '../../../store/chat/chatslice';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
// import Drawer from '@material-ui/core/Drawer';

// const CreateGroupDrawer = ({ open, toggle }) => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllUsers("0"));
//   }, [dispatch]);

//   const chatData = useSelector((state) => state.chatslice) 
//   const recentuserlist = chatData.recentuserlist
//   const userList = chatData.userlist


//   const formik = useFormik({
//     initialValues: {
//       groupName: '',
//       groupDescription: '',
//       selectedUsers: [],
//     },
//     validationSchema: Yup.object({
//       groupName: Yup.string()
//         .min(3, 'Must be at least 3 characters')
//         .required('Required'),
//       groupDescription: Yup.string()
//         .min(10, 'Must be at least 10 characters')
//         .required('Required'),
//       selectedUsers: Yup.array()
//         .min(1, 'Please select at least one user')
//         .required('Please select at least one user'),
//     }),
//     onSubmit: values => {
//       console.log('Form values:', values);
//       toggle(); // Close drawer after form submission
//     },
//   });

//   const handleUserSelectChange = event => {
//     const selectedUsers = Array.from(event.target.selectedOptions, option => option.value);
//     formik.setFieldValue('selectedUsers', selectedUsers);
//   };

//   return (
//     <Drawer
//       anchor="right"
//       open={open}
//       onClose={toggle}
//     >
//       <div style={{ width: '600px', padding: '20px' }}>
//         <h4>Create Group</h4>
//         <form onSubmit={formik.handleSubmit}>
//           <FormGroup>
//             <Label for="groupName">Group Name</Label>
//             <Input
//               id="groupName"
//               name="groupName"
//               type="text"
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.groupName}
//               invalid={formik.touched.groupName && !!formik.errors.groupName}
//             />
//             {formik.touched.groupName && formik.errors.groupName ? (
//               <FormFeedback>{formik.errors.groupName}</FormFeedback>
//             ) : null}
//           </FormGroup>
//           <FormGroup>
//             <Label for="groupDescription">Group Description</Label>
//             <Input
//               id="groupDescription"
//               name="groupDescription"
//               type="textarea"
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.groupDescription}
//               invalid={formik.touched.groupDescription && !!formik.errors.groupDescription}
//             />
//             {formik.touched.groupDescription && formik.errors.groupDescription ? (
//               <FormFeedback>{formik.errors.groupDescription}</FormFeedback>
//             ) : null}
//           </FormGroup>
//           <FormGroup>
//           {
//                         checkedUsers.length !== 0 ?
//                             <div className='my-3 mx-3'>
//                                 <div className="d-flex justify-content-end mb-3">
//                                     <div className="me-auto">
//                                         Checked Users: {checkedUsers.length}
//                                     </div>
//                                     {/* <div className="ms-2">
//                                         <button className="btn btn-primary rounded-circle d-flex justify-content-center align-items-center" onClick={handleSendClick}>
//                                             <FontAwesomeIcon icon={faPaperPlane} />
//                                         </button>
//                                     </div> */}
//                                 </div>
//                             </div> : (null)}

//                     <div className='my-3 mx-3 '>
//                         <h6>Recents</h6>
//                         {recentuserlist.map((user, index) => (
//                             <div className="d-flex flex-column mb-2 p-2 bg-light" key={"tu" + index} style={{ borderRadius: 25 }}>
//                                 <div className="d-flex justify-content-between align-items-center">
//                                     <div className="d-flex flex-row align-items-center">
//                                         <input
//                                             type="checkbox"
//                                             className="me-2"
//                                             checked={checkedUsers.includes(user)}
//                                             onChange={() => handleCheckboxChange(user)}
//                                         />
//                                         <div
//                                             className={`rounded-circle avatar-xs me-2 bg-success text-dark bg-opacity-25`}
//                                             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
//                                         >
//                                             {user.sender_name.charAt(0).toUpperCase()}
//                                         </div>
//                                         <div className="d-flex flex-column">
//                                             <div className="font-size-13 text-dark">{user.sender_name}</div>
//                                             <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>

//                     <div className='my-3 mx-3 '>
//                         <h6>Contacts</h6>
//                         {userList.map((user, index) => (
//                             <div className="d-flex flex-column mb-2 p-2 bg-light" key={"tu" + index} style={{ borderRadius: 25 }}>
//                                 <div className="d-flex justify-content-between align-items-center">
//                                     <div className="d-flex flex-row align-items-center">
//                                         <input
//                                             type="checkbox"
//                                             className="me-2"
//                                             checked={checkedUsers.includes(user)}
//                                             onChange={() => handleCheckboxChange(user)}
//                                         />
//                                         <div
//                                             className={`rounded-circle avatar-xs me-2 bg-success text-dark bg-opacity-25`}
//                                             style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
//                                         >
//                                             {user.firstname.charAt(0).toUpperCase()}
//                                         </div>
//                                         <div className="d-flex flex-column">
//                                             <div className="font-size-13 text-dark">{user.firstname}</div>
//                                             <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//           </FormGroup>
//           <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
//             <Button type="button" color="secondary" onClick={toggle} style={{ marginRight: '10px' }}>
//               Close
//             </Button>
//             <Button type="submit" color="success">
//               Create Group
//             </Button>
//           </div>
//         </form>
//       </div>
//     </Drawer>
//   );
// };

// export default CreateGroupDrawer;
