import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
// var randomColor = require('randomcolor');

//actions
// import { getEarningChartsData } from "../../store/actions";

//redux
// import { useSelector, useDispatch } from "react-redux";

import getChartColorsArray from "../AdminDashboard/getChartcolor";

const Earning = ({ dataColors }) => {

    const apexearningChartColors = getChartColorsArray(dataColors);

    // const getChartColorsArray = (colors) => {
    //     colors = JSON.parse(colors);
    //     return colors.map(function (value) {
    //         var newValue = value.replace(" ", "");
    //         if (newValue.indexOf(",") === -1) {
    //             var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
    
    //             if (color.indexOf("#") !== -1)
    //                 color = color.replace(" ", "");
    //             if (color) return color;
    //             else return newValue;
    //         } else {
    //             var val = value.split(',');
    //             if (val.length === 2) {
    //                 var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
    //                 rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
    //                 return rgbaColor;
    //             } else {
    //                 return newValue;
    //             }
    //         }
    //     });
    // };



//   const dispatch = useDispatch();

  const earningChartData =[31,40,36,51,49,72,69,56,68,82,68,76]
// var color = randomColor();


  const series = [
    {
      name: "Series 1",
      data: [...earningChartData],
    },
  ];

  const options = {
    chart: {
      toolbar: "false",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    colors: apexearningChartColors,
    stroke: {
      curve: "smooth",
      width: 3,
    },
  };

  /*
  call api action to receive data
  */
//   useEffect(() => {
//     dispatch(getEarningChartsData("jan"));
//   }, [dispatch]);

  const [seletedMonth, setSeletedMonth] = useState("jan");
  
  const onChangeMonth = value => {
    setSeletedMonth(value);
    // dispatch(getEarningChartsData(value));
  };

  return (
    <React.Fragment>
      <Col xl="8" className="pe-0">
        <Card className="mb-1">
          <CardBody>
            {/* <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group-sm">
                  <select
                    className="form-select form-select-sm"
                    value={seletedMonth}
                    onChange={(e) => {
                      onChangeMonth(e.target.value);
                    }}
                  >
                    <option value="jan">Jan</option>
                    <option value="dec">Dec</option>
                    <option value="nov">Nov</option>
                    <option value="oct">Oct</option>
                  </select>
                  <label className="input-group-text">Month</label>
                </div>
              </div>
              <h4 className="card-title mb-4">Earning</h4>
            </div> */}

            <Row>
              <Col lg="8">
              <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
                {/* <div className="text-muted">
                  <div className="mb-4">
                    <p>This month</p>
                    <h4>$2453.35</h4>
                    <div>
                      <span className="badge badge-soft-success font-size-12 me-1">
                        {" "}
                        + 0.2%{" "}
                      </span>{" "}
                      From previous period
                    </div>
                  </div>

                  <div>
                    <Link to="#" className="btn btn-primary  btn-sm">
                      View Details{" "}
                      <i className="mdi mdi-chevron-right ms-1"></i>
                    </Link>
                  </div>

                  <div className="mt-4">
                    <p className="mb-2">Last month</p>
                    <h5>$2281.04</h5>
                  </div>
                </div> */}
              </Col>

              <Col lg="8">
                {/* <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div> */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default Earning;
