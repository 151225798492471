import React, { Component } from "react";
import { useHistory } from 'react-router-dom';
import MetaTags from "react-meta-tags";
import {
    Container,
    Row,
    Col,
    Button,
    Alert,
    Input,
    Card,
    CardBody,
    CardText,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    Collapse,
    DropdownMenu,
    DropdownToggle,
    UncontrolledAlert,
    UncontrolledDropdown,
    Tooltip,
    Spinner, Progress,
    Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";

import {
    Sparklines,
    SparklinesLine,
    SparklinesBars,
    SparklinesSpots,
    SparklinesReferenceLine,
    SparklinesCurve,
  } from "react-sparklines"

import CardTitle from "reactstrap/lib/CardTitle";

import ReviewOPType from "./Components/review_optype";
import PreviewImage from "./Components/preview_images";
import PreviewDocuments from "./Components/preview_documents";
import PreviewObservation from "./Components/preview_observation";
import PreviewCAPA from "./Components/preview_CAPA";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaArrowRight, FaChevronDown, FaChevronUp, } from "react-icons/fa";
// import overview_img from '/Users/imac_may2023/Documents/project/AuditVista-User/Development/AuditVista_User/src/assets/images/verification-img.png'
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import overview_img from '../../../assets/images/verification-img.png'
import NestedChildren from './NestedChildren'
import ExpandNestedChildren from './ExpandNestedChildren'
import SortableTree, {
    addNodeUnderParent,
    removeNodeAtPath,
    toggleExpandedForAll,
    defaultGetNodeKey,
    getTreeFromFlatData,
    getNodeAtPath,
    changeNodeAtPath,
    getFlatDataFromTree,
    walk,
    map, find
} from 'react-sortable-tree/dist/index.cjs.js';
import Breadcrumbs from "./Components/Breadcrumb2"
// import { PDFDownloadLink, Document, Page, View, Text, StyleSheet, PDFViewer } from '@react-pdf/renderer';
// import { jsPDF } from 'jspdf';
// import * as html2pdf from 'html2pdf.js';

// import html2canvas from 'html2canvas';
import moment from 'moment'
// import canvas from 'html2canvas';
// import htmlToPdfmake from 'html-to-pdfmake';
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Empty } from 'antd'
import { AiOutlineWarning } from 'react-icons/ai';
import auditvista_logo from "../../../assets/images/auditvista_logo.png";
import { LoadingOutlined } from '@ant-design/icons';
// var urlSocket = require("../../helpers/urlSocket");
import urlSocket from "../../../helpers/urlSocket";
import { async } from "q";
// var moment = require("moment");
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
import PreviewLocation from "./Components/preview_Location";
// import Piechart from './Chart/PieChart/pieChart'
import MultiStackedBarChart from "./Chart/D3-Chart/multiStackedBarChart";
import TypeMultiStackedBarChart from './Chart/D3-Chart/typeMultiStackedBarChart'
import PieChart from "./Chart/PieChart";
// import Geolocation from '../AdminDashboard/GeoLocation'
import CombinedChart from "./Chart/D3-Chart/combinedChart";
import CompliantMultiStackedBarChart from "./Chart/D3-Chart/compliantMultiStackedBarChart";
import NonCompliantMultiStackedBarChart from "./Chart/D3-Chart/nonCompliantMultiStackedBarChart";
import PartiallyCompliantMultiStackedBarChart from "./Chart/D3-Chart/partiallyCompliantMultiStackedBarChart";
import NotApplicableMultiStackedBarChart from "./Chart/D3-Chart/NotApplicableMultiStackedBarChart";
import Geolocation from '../../../components/GeoLocation'

import classnames from "classnames";

const _ = require("lodash");


export default class CheckpointCollapseReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight,
            position: "right",
            open: false,
            dataloaded: false,
            selectedCheckpoint: null,
            filterStatus: "all",
            showOption: false,
            attachImages: [],
            attachDocuments: [],
            selectedOption: null,
            docfileStatus: "clear",
            fileStatus: "clear",
            doc_warning_enabled: false,
            warning_enabled: false,
            issign_empty: true,
            signee: null,

            isCollapseOpen: [], // Add the isCollapseOpen state variable
            parentCollapseOpen: true,
            pageLoading: true,


            overview_collapse: true,
            completion_collapse: true,
            auditCollapseOpen: true,
            flagged_items: true,
            download_audit: true,


            col1: false,
            treeData: [], // Your treeData array
            isCollapseOpen: false, // State for parent collapse
            isChildCollapseOpen: [], // States for child collapses
            collapse_all: true,
            expand_all: true,
            isExpanded: false,
            filter_cp_compliant: '',
            flatData: [],
            selectedImpactLevel: null,
            selectedCPCompliant: null,
            selectedType: null,
            dup_flatData: [],
            no_data: true,
            loading: false,
            blur_loading: false,

            nonCompliantCheckpoints: [],
            showActionplanModal: false,
            actionplan_data: [],
            selected_checkpoint: '',
            cardWidth: null,
            customActiveTab: "1"

        };
        this.pageDiv = React.createRef();
        this.cardRef = React.createRef()

        // this.handleBack=this.handleBack.bind(this)
        // this.goBack = this.goBack.bind(this);
        // const history =useNavigate()
    }

    async componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var client_logo = data.client_info[0].client_logo
        this.setState({
            client_logo: client_logo
        })
        await this.getSessionData();
        await this.loadEnpointData()
        await this.loadCheckpoints();
        // await this.summaryData()

        await this.updateCardWidth();
        window.addEventListener('resize', this.updateCardWidth);

    }

    toggleCustom = tab => {
        if (this.state.customActiveTab !== tab) {
            this.setState({ customActiveTab: tab })
        }
    };

    updateCardWidth = () => {
        // console.log("177777" , this.cardRef.current , )
        if (this.cardRef.current) {
            const currentCardWidth = this.cardRef.current.clientWidth;
            //   this.setState({ cardWidth: currentCardWidth });
            // console.log("1111111", currentCardWidth)
        }

        let width_1 = 0
        let height_1 = 0
        const targetElement = document.getElementById("card_container");
        // console.log('targetelement', targetElement)
        // console.log('targetElement 182', targetElement?.offsetWidth)
        if (targetElement) {
            const style = targetElement.style;
            width_1 = parseFloat(targetElement?.offsetWidth);         // Get the width
            height_1 = parseFloat(style.height); // Get the height
            // console.log('Width 187:', width_1, 'Height:', height_1);
            this.setState({ cardWidth: width_1 })
        }
    };

    loadEnpointData = () => {
        var endpointData = JSON.parse(sessionStorage.getItem("endpointInfo"));
        // console.log(endpointData,'endpointData')

        try {
            urlSocket.post("webpbdadtdta/getpublishedauditlocationinfo", {
                auditInfo: endpointData,
                // userInfo: this.state.userData,
                encrypted_db_url: this.state.db_info.encrypted_db_url,
                // configData: this.state.configData
            }).then(response => {
                console.log(response, 'response')
                this.setState({
                    endpointData: response.data.data[0]
                })

            })

        }
        catch (error) {

        }

    }


    async getSessionData() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        var endpointData = JSON.parse(sessionStorage.getItem("endpointInfo"));
        var redirect = sessionStorage.getItem("redirect")
        if (endpointData.audit_score?.$numberDecimal !== undefined) {
            var total_audit = (parseFloat(endpointData.audit_score.$numberDecimal).toFixed(2) / parseFloat(endpointData.max_score.$numberDecimal).toFixed(2)) * 100
        }
        else {
            var total_audit = (parseFloat(endpointData.audit_score).toFixed(2) / parseFloat(endpointData.max_score).toFixed(2)) * 100
        }
        // var total_audit = (parseFloat(endpointData.audit_score.$numberDecimal).toFixed(2) / parseFloat(endpointData.max_score.$numberDecimal).toFixed(2)) * 100

        var publishedAuditData = JSON.parse(sessionStorage.getItem("publishedAuditData"));
        var audiData 
        if(publishedAuditData === null){
            var audiData =  JSON.parse(sessionStorage.getItem("auditData"));
            audiData["template_name"]=audiData.audit_pbd_name
        }
        
        console.log(publishedAuditData,'publishedAuditData')
        this.setState({
            configData: data.config_data,
            userData: data.user_data,
            endpointData,
            db_info: db_info,
            imagePreviewUrl: data.config_data.img_url,
            imageToken: data.config_data.img_url,
            total_audit,
            redirect,
            publishedAuditData : publishedAuditData === null ? audiData : publishedAuditData
        });
    }

    async loadCheckpoints() {
        try {
            urlSocket
                .post("epadtprcs/getepcheckpoints", {
                    auditInfo: {
                        audit_pbd_id: this.state.endpointData.audit_pbd_id,
                    },
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.state.userData._id,
                        company_id: this.state.userData.company_id,
                    },
                    endpointInfo: this.state.endpointData,
                    summary: true
                })
                .then(async (response) => {
                    console.log(response, 'response')
                    if (response.data.response_code === 500) {
                        const checkpoints = _.filter(response.data.data, {
                            document_type: "2",
                        });

                        const nonCompliantCheckpoints = checkpoints.filter((checkpoint) => {
                            return checkpoint.cp_compliance.name.toLowerCase() === "non compliant";
                        });

                        // Output or use the filtered array as needed
                        //   console.log("Non-Compliant Checkpoints:", nonCompliantCheckpoints);

                        // console.log('checkpoints', checkpoints)
                        // await this.getSummaryCounts(checkpoints)
                        const isCollapseOpen = new Array(checkpoints.length).fill(false); // Initialize isCollapseOpen array
                        var updated_flat_data = await this.convertFlatDataToTreeData(response.data.data, true)
                        console.log(updated_flat_data, 'updated_flat_data')
                        this.setState({
                            checkpoints,
                            filteredData: checkpoints,
                            isCollapseOpen,
                            flatData: response.data.data,
                            dup_flatData: response.data.data,
                            nonCompliantCheckpoints: nonCompliantCheckpoints,
                            summary_info: response.data.summary,
                            // treeData: response.data.data,
                            pageLoading: false
                        }, () => {
                            // this.setState({
                            //     dataloaded: true
                            // })
                            this.summaryData()
                        });
                    }
                });
        } catch (error) {
            console.log("catch error", error);
        }
    }

    summaryData = () => {
        try {
            urlSocket
                .post("epadtprcs/report-summary-info", {
                    auditInfo: {
                        audit_pbd_id: this.state.endpointData.audit_pbd_id,
                    },
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.state.userData._id,
                        company_id: this.state.userData.company_id,
                    },
                    endpointInfo: this.state.endpointData,
                    // summary: true
                })
                .then(async (response) => {
                    console.log(response, 'response')
                    this.setState({
                        summary_report: response.data.summary,
                        graph_info: response.data.test_data
                    }, () => {
                        //     setTimeout(() => {
                            this.setState({ dataloaded: true })
                        // }, 500);
                    })


                })

        } catch (error) {

        }
    }


    // getSummaryCounts = async (checkpoints) => {

    //     // Initialize counts
    //     let compliantCount = 0;
    //     let nonCompliantCount = 0;
    //     let partiallyCompliantCount = 0;
    //     let notApplicableCount = 0;

    //     // Iterate through the array
    //     checkpoints.forEach((checkpoint) => {
    //         switch (checkpoint.cp_compliance.name.toLowerCase()) {
    //             case "compliant":
    //                 console.log('checkpoint.cp_compliance.name', checkpoint.cp_compliance.name)

    //                 compliantCount++;
    //                 break;
    //             case "non compliant":
    //                 nonCompliantCount++;
    //                 break;
    //             case "partially compliant":
    //                 partiallyCompliantCount++;
    //                 break;
    //             case "not applicable":
    //                 notApplicableCount++;
    //                 break;
    //             default:
    //                 // Handle unexpected status
    //                 break;
    //         }
    //     });

    //     // Output the counts
    //     // console.log("Compliant Count:", compliantCount);
    //     // console.log("Non-Compliant Count:", nonCompliantCount);
    //     // console.log("Partially Compliant Count:", partiallyCompliantCount);
    //     // console.log("Not Applicable Count:", notApplicableCount);


    //     const nonCompliantCheckpoints = checkpoints.filter((checkpoint) => {
    //         return checkpoint.cp_compliance.name.toLowerCase() === "non compliant";
    //       });

    //       // Output or use the filtered array as needed
    //       console.log("Non-Compliant Checkpoints:", nonCompliantCheckpoints);

    //     this.setState({
    //         compliantCount: compliantCount,
    //         nonCompliantCount: nonCompliantCount,
    //         partiallyCompliantCount: partiallyCompliantCount,
    //         notApplicableCount: notApplicableCount,
    //         nonCompliantCheckpoints: nonCompliantCheckpoints
    //     })




    // }




    filterCheckpoints(filterStatus) {
        if (filterStatus === "all") {
            this.setState({
                filteredData: this.state.checkpoints,
                showOption: false,
                dataloaded: true,
                //selectedCheckpoint:null,
                idx: -1,
            });
        } else if (filterStatus === "nr") {
            this.setState({
                filteredData: _.filter(this.state.checkpoints, (item) => {
                    return item.cp_review_status === null;
                }),
                showOption: false,
                selectedCheckpoint: null,
                idx: -1,
                dataloaded: true,
            });
        } else {
            this.setState({
                filteredData: _.filter(this.state.checkpoints, (item) => {
                    return item.cp_review_status !== null;
                }),
                showOption: false,
                selectedCheckpoint: null,
                idx: -1,
                dataloaded: true,
            });
        }
    }

    // toggleCollapse = (index) => {
    // const isCollapseOpen = [...this.state.isCollapseOpen];
    // isCollapseOpen[index] = !isCollapseOpen[index];
    // this.setState({ isCollapseOpen });
    // };

    openParentCollapse = (type) => {
        if (type === 1) {
            if (this.state.overview_collapse) {
                this.setState({ overview_collapse: false })
            } else {
                this.setState({ overview_collapse: true })
            }
        }

        if (type === 2) {
            if (this.state.auditCollapseOpen) {
                this.setState({ auditCollapseOpen: false });
            } else {
                this.setState({ auditCollapseOpen: true });
            }
        }

        if (type === 3) {
            if (this.state.completion_collapse) {
                this.setState({ completion_collapse: false });
            } else {
                this.setState({ completion_collapse: true });
            }
        }

        if (type === 4) {
            if (this.state.flagged_items) {
                this.setState({ flagged_items: false });
            } else {
                this.setState({ flagged_items: true });
            }
        }
        // this.setState({dataloaded: false}, ()=>{this.setState({dataloaded: true})})
    };

    t_col1 = () => {
        if (this.state.col1) {
            this.setState({ col1: false });
        } else {
            this.setState({ col1: true });
        }
    };

    // toggleCollapse = () => {
    //     this.setState((prevState) => ({
    //         isCollapseOpen: !prevState.isCollapseOpen,
    //     }));
    // };

    // toggleChildCollapse = (index) => {
    //     this.setState((prevState) => {
    //         const isChildCollapseOpen = [...prevState.isChildCollapseOpen];
    //         isChildCollapseOpen[index] = !isChildCollapseOpen[index];
    //         return { isChildCollapseOpen };
    //     });
    // };
    // toggleChildCollapse = (index) => {
    //     const { isChildCollapseOpen } = this.state;
    //     isChildCollapseOpen[index] = !isChildCollapseOpen[index];
    //     this.setState({ isChildCollapseOpen: [...isChildCollapseOpen] });
    //   };

    //// working
    // convertFlatDataToTreeData = (flatData) => {
    //     if (flatData !== undefined) {
    //         var parent_data = flatData.filter(item2 => item2.parent_id === null);
    //         parent_data.sort(function (a, b) {
    //             return (a.document_id) - (b.document_id);
    //         });
    //         parent_data.map((parentItem) => {
    //             parentItem.children = this.getChildren(parentItem.document_id, flatData);
    //         });


    //         this.setState({ treeData: parent_data });
    //     }
    // }

    // getChildren = (parentId, flatData) => {
    //     var children = flatData.filter(item => item.parent_id === parentId);
    //     children.sort(function (a, b) {
    //         return (a.document_id) - (b.document_id);
    //     });
    //     children.map((childItem) => {
    //         childItem.children = this.getChildren(childItem.document_id, flatData);
    //     });
    //     return children;
    // }

    // convertFlatDataToTreeData = async(flatData,mode) => {
    //     // if (mode == undefined) {
    //         if (flatData !== undefined) {
    //             var parent_data = flatData.filter(item2 => item2.parent_id === null);
    //             parent_data.sort(function (a, b) {
    //                 return a.document_id - b.document_id;
    //             });

    //             const treeData = parent_data.map((parentItem) => {
    //                 parentItem.children = this.getChildren(parentItem.document_id, flatData);
    //                 return parentItem;
    //             });

    //             // Remove objects with empty children array
    //             //   const filteredTreeData = treeData.filter(item => item.children.length > 0);
    //             if (mode == undefined) {
    //             this.setState({ treeData: treeData });
    //             }
    //             else{
    //                 this.setState({ treeData: treeData });
    //                 var converted_treeDataToFlat = await this.treeDataToFlat(treeData)
    //                 var removed_node = _.map(converted_treeDataToFlat, "node")
    //                 // console.log(converted_treeDataToFlat, 'converted_treeDataToFlat', removed_node)
    //                 return removed_node

    //             }
    //         }
    //     // }
    //     // else{

    //     // }
    //   }

    convertFlatDataToTreeData = (flatData) => {
        console.log(flatData, 'flatData')
        if (flatData !== undefined) {
            var parent_data = flatData.filter(item2 => item2.parent_id === null);
            parent_data.sort(function (a, b) {
                return (a.document_id) - (b.document_id);
            });
            parent_data.map((parentItem) => {
                parentItem.children = this.getChildren(parentItem.document_id, flatData);
            });


            this.setState({ treeData: parent_data });
        }
    }


    treeDataToFlat = (treeData) => {
        var getNodeKey = ({ treeIndex }) => treeIndex
        var flatData = getFlatDataFromTree(
            {
                treeData: treeData,
                getNodeKey,
                ignoreCollapsed: false,
            })

        var explicitData = _.filter(flatData, item => {
            return item
        })

        return explicitData




    }

    getChildren = (parentId, flatData) => {
        var children = flatData.filter(item => item.parent_id === parentId);
        children.sort(function (a, b) {
            return a.document_id - b.document_id;
        });

        return children.map((childItem) => {
            childItem.children = this.getChildren(childItem.document_id, flatData);
            return childItem;
        });
    }



    handleToggleExpand = () => {

        if (this.state.isExpanded) {
            this.setState({ isExpanded: false, overview_collapse: true, auditCollapseOpen: true, completion_collapse: true, selectedCPCompliant: null, selectedType: null, selectedImpactLevel: null, flagged_items: true })
            this.loadCheckpoints()
        } else {
            this.setState({ isExpanded: true, overview_collapse: false, auditCollapseOpen: false, completion_collapse: false, flagged_items: false })
        }

        // this.setState((prevState) => ({
        //     isExpanded: !prevState.isExpanded,
        //     overview_collapse: !prevState.overview_collapse,
        //     auditCollapseOpen: !prevState.overview_collapse,
        //     completion_collapse: !prevState.overview_collapse

        // }));
    };




    filterTags = (select_value, value) => {
        if (value == "1") {
            /// imapact
            if (select_value == "All") {
                this.setState({ selectedImpactLevel: null, selectedCPCompliant: null, selectedType: null, impactAll: select_value, cpAll: null, typeAll: null, dataloaded: false }, () => { this.setState({ dataloaded: true }) })
            }
            else {

                this.setState({ selectedImpactLevel: select_value, impactAll: null, cpAll: null, typeAll: null, dataloaded: false }, () => { this.setState({ dataloaded: true }) })
            }
        }
        if (value == "2") {
            //// cpcompliance
            if (select_value == "All") {
                this.setState({ selectedImpactLevel: null, selectedCPCompliant: null, selectedType: null, cpAll: select_value, impactAll: null, typeAll: null, dataloaded: false }, () => { this.setState({ dataloaded: true }) })
            }
            else {
                this.setState({ selectedCPCompliant: select_value, impactAll: null, cpAll: null, typeAll: null, dataloaded: false }, () => { this.setState({ dataloaded: true }) })
            }
        }
        if (value == "3") {
            //// type
            if (select_value == "All") {
                this.setState({ selectedImpactLevel: null, selectedCPCompliant: null, selectedType: null, typeAll: select_value, impactAll: null, cpAll: null, dataloaded: false }, () => { this.setState({ dataloaded: true }) })
            }
            else {
                this.setState({ selectedType: select_value, impactAll: null, cpAll: null, typeAll: null, dataloaded: false }, () => { this.setState({ dataloaded: true }) })
            }
        }
        setTimeout(() => {
            const { selectedImpactLevel, selectedCPCompliant, selectedType } = this.state;

            let parent_array = this.state.dup_flatData.filter(e => {
                if ((e.parent_id !== null && e.document_type === '0') || e.parent_id === null) {
                    return true;
                }
                return false;
            });
            if (selectedImpactLevel !== null && selectedCPCompliant !== null && selectedType !== null) {
                // console.log("expected one");

                // Use a Set to store unique elements
                var uniqueData = new Set();
                // Iterate through flatData and add items that meet the criteria to the Set
                this.state.flatData.forEach((item) => {
                    if (
                        item.impact_level === selectedImpactLevel &&
                        (item.cp_compliance !== null && (item.cp_compliance.name === selectedCPCompliant) &&
                            item.compl_type.includes(selectedType))
                    ) {
                        uniqueData.add(item);
                    }
                });

                // Convert the Set back to an Array
                var mergedData = Array.from(uniqueData);
                // console.log(mergedData, 'mergedData')
                if (mergedData.length > 0) {
                    mergedData.filter(e => {
                        parent_array.push(e)
                    })
                    this.convertFlatDataToTreeData(parent_array)
                    this.setState({ no_data: true })
                } else {
                    this.setState({ no_data: false })
                }
            } else {
                // if(selectedImpactLevel !==null && selectedCPCompliant !== null && selectedType !==null){
                //     console.log("expcted one")
                //     var impact_level = _.filter(this.state.flatData ,{impact_level : selectedImpactLevel})
                //     var cp_compliance = this.state.flatData.filter((item,pos)=>{
                //         if(item.cp_compliance !== null && item.cp_compliance.name == selectedCPCompliant){
                //             return item
                //         }
                //     })
                //     var compl_type = this.state.flatData.filter((item,pos)=>{
                //         if(item.compl_type.includes(selectedType)){
                //             return item
                //         }
                //     })
                //     console.log(impact_level,compl_type,cp_compliance)


                // }


                var filter_data = _.filter(this.state.flatData, e => {
                    // if (selectedImpactLevel !== null && selectedCPCompliant !== null && selectedType !== null) {
                    //     console.log(e.compl_type.includes(selectedType), e, (e.cp_compliance !== null && e.cp_compliance.name == selectedCPCompliant), e.impact_level == selectedImpactLevel, '!!!!!!!!', e.impact_level, selectedImpactLevel, selectedCPCompliant, e.compl_type, selectedType, e.impact_level == selectedImpactLevel && (e.cp_compliance !== null && e.cp_compliance.name == selectedCPCompliant) && e.compl_type.includes(selectedType))
                    //     if(e.impact_level == selectedImpactLevel  && ((e.cp_compliance !== null && e.cp_compliance.name == selectedCPCompliant) && e.compl_type.includes(selectedType))){
                    //         console.log('ifffffff')
                    //         return true
                    //     }
                    // }

                    if (selectedImpactLevel !== null && selectedCPCompliant !== null) {
                        if (e.impact_level == selectedImpactLevel && (e.cp_compliance !== null && e.cp_compliance.name == selectedCPCompliant)) {
                            return true
                        }
                    }
                    else if (selectedType !== null && selectedCPCompliant !== null) {
                        if (e.cp_compliance !== null && e.cp_compliance.name == selectedCPCompliant && (e.compl_type && e.compl_type.includes(selectedType))) {
                            return true
                        }
                    }
                    else if (selectedType !== null && selectedImpactLevel !== null) {

                        if (e.impact_level == selectedImpactLevel && (e.compl_type && e.compl_type.includes(selectedType))) {
                            return true
                        }
                    }
                    else if (selectedImpactLevel !== null) {
                        if (e.impact_level == selectedImpactLevel) {
                            return true
                        }
                    }
                    else if (selectedCPCompliant !== null) {
                        if (e.cp_compliance !== null && e.cp_compliance.name == selectedCPCompliant) {
                            return true
                        }
                    }

                    else if (selectedType !== null) {
                        if (e.compl_type && e.compl_type.includes(selectedType)) {
                            return true
                        }
                    }
                    else {
                        if (e.document_type === '2') return true
                    }
                })

                // let check_filter_data = []
                if (filter_data.length > 0) {
                    filter_data.filter(e => {
                        parent_array.push(e)
                    })
                    this.convertFlatDataToTreeData(parent_array)
                    this.setState({ no_data: true })
                } else {
                    this.setState({ no_data: false })
                }
            }
        }, 200);



    }


    handleBack = () => {
        // goBackk()
        // this.props.history.goBack()
        if (this.state.redirect === "1") {
            //     this.props.history.goBack()
            sessionStorage.removeItem("redirect")
            this.props.history.push("/usrenpdts")
        }
        else {
            this.props.history.push("/pbdrpt")
        }
    }

    // handlePdfDownload = () => {
    //     try {
    //         urlSocket.post('cog/pdf-auditvista', { pdf_data: this.state.flatData, location_data: [this.state.endpointData], cmpny_data: this.state.client_logo })
    //             .then((response) => {
    //                 if (response.data.response_code === 500) {

    //                 }
    //             })

    //     } catch (error) {

    //     }
    // }


    handlePdfDownload = () => {
        console.log(this.state.endpointData, 'this.state.endpointData', this.state.flatData)
        this.setState({ loading: true, blur_loading: true });
        try {
            urlSocket.post('cog/pdf-auditvista', { summary_data :{summary_report: this.state.summary_report,summary_info : this.state.summary_info , graph_info: this.state.graph_info},pdf_data: this.state.flatData, location_data: [this.state.endpointData], cmpny_data: this.state.client_logo, encrypted_db_url: this.state.db_info.encrypted_db_url })
                .then((response) => {
                    if (response.data.response_code === 500) {
                        const original_name = response.data.pdf_data.orginalName
                        // const img_url = this.state.img_Url
                        const download_pdf = this.state.imagePreviewUrl + original_name
                        window.open(download_pdf)
                        this.setState({ loading: false, blur_loading: false });

                    }
                })
        } catch (error) {
            console.log('error', error)
        }
    }



    onShowActionPlan = (actionplan_data) => {
        console.log('actionplan_data', actionplan_data)
        this.setState({ actionplan_data: actionplan_data.cp_actionplans, selected_checkpoint: actionplan_data }, () => { this.setState({ showActionplanModal: true }) })

    }


    // goBack=()=>{
    //     setTimeout(() => {
    //         this.props.history.goBack();
    //         // this.goBack()
    //         this.props.history.goBack();
    //     }, 10);
    // }
    handleTimeChange = (time) => {
        // setSelectedTime(time);
        this.setState({ setSelectedTime: time })
    };


    render() {
        const { treeData, isCollapseOpen, isChildCollapseOpen } = this.state;
        if (this.state.dataloaded) {
            return (
                <React.Fragment>
                    <div className={this.state.blur_loading ? 'page-content  blur' : 'page-content'} id='content'>
                        <MetaTags>
                            <title>AuditVista | Check Point Report</title>
                        </MetaTags>



                        <Container fluid>

                            <Breadcrumbs
                                title={this.state.publishedAuditData.template_name}
                                link={"Audit Report / "}
                                description={this.state.endpointData.name + " / " + this.state.endpointData.code}
                                changeAuditEditMode={() => {
                                    this.setState(prevState => ({
                                        modal: !prevState.modal,
                                    }))
                                }}
                                breadcrumbItem="Template"
                                isBackButtonEnable={true}
                                gotoBack={() => this.handleBack()}
                            />



                            <div className="my-4" style={{ paddingRight: 25, textAlign: 'right' }}>

                                <Button type="button" color="primary" className="downloadpdf btn-sm mt-2" onClick={this.handlePdfDownload} disabled={this.state.loading} style={{ padding: 10 }} >
                                    {this.state.loading && <LoadingOutlined />}{this.state.loading ? '...' : <i className="bx bx-download align-middle me-2" style={{ fontSize: '20px' }}></i>}
                                    {this.state.loading ? ' ' : 'PDF'}
                                </Button>
                            </div>

                            <Row>
                                
                                <Col sm="12" >
                                    <div >

                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                            `<NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: this.state.customActiveTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        this.toggleCustom("1");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="fas fa-home"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">Summary</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: this.state.customActiveTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        this.toggleCustom("2");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="far fa-user"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">Items & Actions</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: this.state.customActiveTab === "3",
                                                    })}
                                                    onClick={() => {
                                                        this.toggleCustom("3");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="far fa-envelope"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">Audit Report</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: this.state.customActiveTab === "4",
                                                    })}
                                                    onClick={() => {
                                                        this.toggleCustom("4");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="fas fa-cog"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">Conclusion</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>

                                        <TabContent
                                            activeTab={this.state.customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                <Row style={{ height: "76vh", overflow: "auto" }}>
                                                    <Col sm="12">
                                                        <div id="overview">
                                                            <Card style={{ marginBottom: 0 }} className="mt-1">
                                                                <CardBody className="py-0">
                                                                    <Row className="my-2 align-items-center py-2">

                                                                        <Col className="col-auto">
                                                                            <CardText className="font-size-15 text-secondary mb-3">Audit</CardText>
                                                                            <CardTitle
                                                                                className="mt-0 text-dark"
                                                                                style={{
                                                                                    fontSize: "1rem",
                                                                                    fontWeight: "500",
                                                                                    color: "rgb(31, 37, 51)",
                                                                                    lineHeight: "0.5rem",
                                                                                    textTransform: "initial"
                                                                                }}
                                                                            >
                                                                                {this.state.endpointData.audit_pbd_name}


                                                                            </CardTitle>

                                                                            <CardText
                                                                                style={{
                                                                                    fontSize: "0.8rem",
                                                                                    color: "rgb(84, 95, 112)",
                                                                                }}
                                                                            >
                                                                                {this.state.endpointData.name}
                                                                                {" / "}
                                                                                {this.state.endpointData.code}
                                                                            </CardText>
                                                                        </Col>
                                                                    </Row>
                                                                    {
                                                                        <Row
                                                                            style={{ borderTop: "1px solid lightgrey" }} className="py-3 gap-3"
                                                                        >
                                                                            {
                                                                                this.state.endpointData.audit_score !== undefined &&
                                                                                this.state.endpointData.min_score !== undefined &&
                                                                                this.state.endpointData.max_score !== undefined &&
                                                                                <>
                                                                                    <Col className="col-auto"  >
                                                                                        <div className="reportCollapseLabel ">
                                                                                            <span className="text-secondary font-size-12">Audit Score</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label style={{ fontSize: "1rem", }} >

                                                                                                {this.state.endpointData.audit_score !==
                                                                                                    undefined &&
                                                                                                    this.state.endpointData.audit_score !==
                                                                                                    "undefined" &&
                                                                                                    this.state.endpointData.audit_score !==
                                                                                                    null ? (
                                                                                                    <>
                                                                                                        {Number(
                                                                                                            this.state.endpointData.audit_score
                                                                                                                .$numberDecimal
                                                                                                        ).toFixed(2)}{" "}

                                                                                                    </>
                                                                                                ) : (
                                                                                                    this.state.endpointData.audit_signature.length > 0 ?
                                                                                                        <>0</>
                                                                                                        :
                                                                                                        <>Not Started</>
                                                                                                )}
                                                                                                {/* {Math.round(Number(this.state.endpointData.audit_score.$numberDecimal))} */}
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col className="col-auto"  >
                                                                                        <div className="reportCollapseLabel">
                                                                                            <span className="text-secondary font-size-12">Minimum Score</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label style={{ fontSize: "1rem" }}>
                                                                                                {this.state.endpointData.min_score !==
                                                                                                    undefined &&
                                                                                                    this.state.endpointData.min_score !==
                                                                                                    "undefined" &&
                                                                                                    this.state.endpointData.min_score !== null ? (
                                                                                                    <>
                                                                                                        {Number(
                                                                                                            this.state.endpointData.min_score
                                                                                                                .$numberDecimal
                                                                                                        ).toFixed(2)}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    this.state.endpointData.audit_signature.length > 0 ?
                                                                                                        <>0</>
                                                                                                        :
                                                                                                        <>Not Started</>
                                                                                                )}
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col className="col-auto" >
                                                                                        <div className="reportCollapseLabel">
                                                                                            <span className="text-secondary font-size-12">Maximum Score</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label style={{ fontSize: "1rem" }}>
                                                                                                {this.state.endpointData.max_score !==
                                                                                                    undefined &&
                                                                                                    this.state.endpointData.max_score !==
                                                                                                    "undefined" &&
                                                                                                    this.state.endpointData.max_score !== null ? (
                                                                                                    <>
                                                                                                        {Number(
                                                                                                            this.state.endpointData.max_score
                                                                                                                .$numberDecimal
                                                                                                        ).toFixed(2)}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    this.state.endpointData.audit_signature.length > 0 ?
                                                                                                        <>0</>
                                                                                                        :
                                                                                                        <>Not Started</>
                                                                                                )}
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                </>

                                                                            }
                                                                        {
                                                                             this.state.endpointData.audit_pbd_users.length === 2 ?
                                                                           <>
                                                                           <Col className="col-auto">
                                                                                <div className="text-secondary font-size-12">Audited by:</div>
                                                                                <div style={{ fontSize: "1rem" }}>{this.state.endpointData.audit_pbd_users[0].name}</div>
                                                                            </Col>
                                                                            <Col className="col-auto">
                                                                                <div className="text-secondary font-size-12">Started on:</div>
                                                                                <div style={{ fontSize: "1rem" }}>
                                                                                    {this.state.endpointData.audit_started_on !== null ?
                                                                                        moment(this.state.endpointData.audit_started_on).format("DD-MMM-YYYY")
                                                                                        :
                                                                                        <span className={"badge badge-soft-danger font-size-11 m-1"} >
                                                                                            {"Not Submitted"}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="col-auto" >
                                                                                <div className="text-secondary font-size-12">Completed on:</div>
                                                                                <div style={{ fontSize: "1rem" }}>
                                                                                    {this.state.endpointData.audit_submitted_on !== null ?
                                                                                        moment(this.state.endpointData.audit_submitted_on).format("DD-MMM-YYYY")
                                                                                        :
                                                                                        <span className={"badge badge-soft-danger font-size-11 m-1"} >
                                                                                            {"Not Submitted"}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="col-auto">
                                                                                <div className="text-secondary font-size-12">Reviewer:</div>
                                                                                <div style={{ fontSize: "1rem" }}>{this.state.endpointData.audit_pbd_users[1].name}</div>
                                                                            </Col>
                                                                            <Col className="col-auto">
                                                                                <div className="text-secondary font-size-12">Reviewed on:</div>
                                                                                <div style={{ fontSize: "1rem" }}>
                                                                                    {this.state.endpointData.audit_reviewed_on !== null ?
                                                                                        moment(this.state.endpointData.audit_reviewed_on).format("DD-MMM-YYYY")
                                                                                        :
                                                                                        <span className={"badge badge-soft-danger font-size-11 m-1"} >
                                                                                            {"Not Submitted"}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                          
                                                                            </>
                                                                            :
                                                                            <>
                                                                            <Col className="col-auto">
                                                                            <div className="text-secondary font-size-12">Audited by:</div>
                                                                            <div style={{ fontSize: "1rem" }}>{this.state.endpointData.audit_pbd_users[0].name}</div>
                                                                        </Col>
                                                                        <Col className="col-auto">
                                                                            <div className="text-secondary font-size-12">Started on:</div>
                                                                            <div style={{ fontSize: "1rem" }}>
                                                                                {this.state.endpointData.audit_started_on !== null ?
                                                                                    moment(this.state.endpointData.audit_started_on).format("DD-MMM-YYYY")
                                                                                    :
                                                                                    <span className={"badge badge-soft-danger font-size-11 m-1"} >
                                                                                        {"Not Submitted"}
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                        <Col className="col-auto" >
                                                                                <div className="text-secondary font-size-12">Completed on:</div>
                                                                                <div style={{ fontSize: "1rem" }}>
                                                                                    {this.state.endpointData.audit_submitted_on !== null ?
                                                                                        moment(this.state.endpointData.audit_submitted_on).format("DD-MMM-YYYY")
                                                                                        :
                                                                                        <span className={"badge badge-soft-danger font-size-11 m-1"} >
                                                                                            {"Not Submitted"}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                        </>
        }



                                                                            {/* <Col className="col-auto" >
                                                                                <div className="text-secondary font-size-12">Completed on:</div>
                                                                                <div style={{ fontSize: "1rem" }}>
                                                                                    {this.state.endpointData.audit_submitted_on !== null ?
                                                                                        moment(this.state.endpointData.audit_submitted_on).format("DD-MMM-YYYY")
                                                                                        :
                                                                                        <span className={"badge badge-soft-danger font-size-11 m-1"} >
                                                                                            {"Not Submitted"}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </Col> */}
                                                                        </Row>
                                                                    }
                                                                </CardBody>
                                                            </Card>


                                                                    {/* status based total count */}
                                                            <Row className="g-1 mt-1">


                                                                <Col lg="12">
                                                                    <Card className="mini-stats-wid mb-0">
                                                                        <CardBody className="d-flex flex-column justify-content-center" >
                                                                            <div className="d-flex flex-row gap-4 mb-3 pb-3  border-bottom border-secondary" style={{ textAlign: "center" }}>
                                                                                <div >
                                                                                    <h2 className="fw-light text-dark mb-0"  >{this.state.summary_report.total_checkpoints}</h2>
                                                                                    <div className="font-size-12 text-secondary">Total Checkpoints</div>
                                                                                </div>
                                                                                <div >
                                                                                    <h2 className="fw-light text-success mb-0"  >{this.state.summary_info?.compliantCount}</h2>
                                                                                    <div className="font-size-12 text-secondary">Compliant</div>
                                                                                </div>
                                                                                <div >
                                                                                    <h2 className="fw-light text-danger mb-0" >{this.state.summary_info?.nonCompliantCount}</h2>
                                                                                    <div className="font-size-12 text-secondary">Non Compliant</div>
                                                                                </div>
                                                                                <div >
                                                                                    <h2 className="fw-light text-warning mb-0" >{this.state.summary_info?.partiallyCompliantCount}</h2>
                                                                                    <div className="font-size-12 text-secondary">Partially Compliant</div>
                                                                                </div>
                                                                                <div >
                                                                                    <h2 className="fw-light text-secondary mb-0" >{this.state.summary_info?.notApplicableCount}</h2>
                                                                                    <div className="font-size-12 text-secondary">Not Applicable</div>
                                                                                </div>

                                                                            </div>
                                                                            <Table responsive>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ fontSize: '12px' }}>Type</th>
                                                                                        <th className="text-dark" style={{ fontSize: '12px' }}>Total</th>
                                                                                        <th className="text-danger" style={{ fontSize: '12px' }}>Critical</th>
                                                                                        <th className="text-warning" style={{ fontSize: '12px' }}>High</th>
                                                                                        <th className="text-info" style={{ fontSize: '12px' }}>Medium</th>
                                                                                        <th className="text-success" style={{ fontSize: '12px' }}>Low</th>
                                                                                        <th className="text-primary" style={{ fontSize: '12px' }}>No Impact</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr >
                                                                                        <td>Compliant</td>
                                                                                        <td>{this.state.summary_info?.compliantCount}</td>
                                                                                        <td>{this.state.summary_report.complaint_critical_count}</td>
                                                                                        <td>{this.state.summary_report.complaint_high_count}</td>
                                                                                        <td>{this.state.summary_report.complaint_medium_count}</td>
                                                                                        <td>{this.state.summary_report.complaint_low_count}</td>
                                                                                        <td>{this.state.summary_report.complaint_no_impact_count}</td>
                                                                                    </tr>
                                                                                    <tr >
                                                                                        <td>Non Compliant</td>
                                                                                        <td>{this.state.summary_info?.nonCompliantCount}</td>
                                                                                        <td>{this.state.summary_report.non_compliant_critical_count}</td>
                                                                                        <td>{this.state.summary_report.non_compliant_high_count}</td>
                                                                                        <td>{this.state.summary_report.non_compliant_medium_count}</td>
                                                                                        <td>{this.state.summary_report.non_compliant_low_count}</td>
                                                                                        <td>{this.state.summary_report.non_compliant_no_impact_count}</td>
                                                                                    </tr>
                                                                                    <tr >
                                                                                        <td>Partially Compliant</td>
                                                                                        <td>{this.state.summary_info?.partiallyCompliantCount}</td>
                                                                                        <td>{this.state.summary_report.partially_compliant_critical_count}</td>
                                                                                        <td>{this.state.summary_report.partially_compliant_high_count}</td>
                                                                                        <td>{this.state.summary_report.partially_compliant_medium_count}</td>
                                                                                        <td>{this.state.summary_report.partially_compliant_low_count}</td>
                                                                                        <td>{this.state.summary_report.partially_compliant_no_impact_count}</td>
                                                                                    </tr>
                                                                                    <tr >
                                                                                        <td>Not Applicable</td>
                                                                                        <td>{this.state.summary_info?.notApplicableCount}</td>
                                                                                        <td>{this.state.summary_report.not_applicable_critical_count}</td>
                                                                                        <td>{this.state.summary_report.not_applicable_high_count}</td>
                                                                                        <td>{this.state.summary_report.not_applicable_medium_count}</td>
                                                                                        <td>{this.state.summary_report.not_applicable_low_count}</td>
                                                                                        <td>{this.state.summary_report.not_applicable_no_impact_count}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </Table>

                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>


                                                            </Row>






                                                                    {/* status based PieChart and impact level MultiStackedBarChart*/}
                                                                    <Row className="g-1 mt-1">
                                                                        <Col md={6} style={{ maxHeight: 350 }}>
                                                                            <Card
                                                                                style={{ marginBottom: 0 }}
                                                                            >
                                                                                <CardBody style={{ padding: "12px 20px" }}>
                                                                                    <CardTitle>Status</CardTitle>
                                                                                    <PieChart
                                                                                        dataColors='["--bs-success","--bs-primary", "--bs-danger","--bs-info", "--bs-warning"]'
                                                                                        total_checkpoints={this.state.summary_report.total_checkpoints}
                                                                                        compliantCount={this.state.summary_info?.compliantCount}
                                                                                        nonCompliantCount={this.state.summary_info?.nonCompliantCount}
                                                                                        partiallyCompliantCount={this.state.summary_info?.partiallyCompliantCount}
                                                                                        notApplicableCount={this.state.summary_info?.notApplicableCount}
                                                                                    />

                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                        <Col md={6} style={{ maxHeight: 350 }}>
                                                                            <Card
                                                                                style={{ marginBottom: 0 }}
                                                                            >
                                                                                <CardBody style={{ padding: "12px 20px" }}>
                                                                                    <CardTitle>Impact Level</CardTitle>
                                                                                  
                                                                                    {/* <div className="d-flex" style={{justifyContent: 'space-evenly'}}> */}
                                                                                    <MultiStackedBarChart
                                                                                        total_checkpoints={this.state.summary_report.total_checkpoints}

                                                                                        compliantCount={this.state.summary_info.compliantCount}
                                                                                        nonCompliantCount={this.state.summary_info.nonCompliantCount}
                                                                                        partiallyCompliantCount={this.state.summary_info.partiallyCompliantCount}
                                                                                        notApplicableCount={this.state.summary_info.notApplicableCount}

                                                                                        complaint_critical_count={this.state.summary_report.complaint_critical_count}
                                                                                        complaint_high_count={this.state.summary_report.complaint_high_count}
                                                                                        complaint_low_count={this.state.summary_report.complaint_low_count}
                                                                                        complaint_medium_count={this.state.summary_report.complaint_medium_count}
                                                                                        complaint_no_impact_count={this.state.summary_report.complaint_no_impact_count}


                                                                                        non_compliant_critical_count={this.state.summary_report.non_compliant_critical_count}
                                                                                        non_compliant_high_count={this.state.summary_report.non_compliant_high_count}
                                                                                        non_compliant_low_count={this.state.summary_report.non_compliant_low_count}
                                                                                        non_compliant_medium_count={this.state.summary_report.non_compliant_medium_count}
                                                                                        non_compliant_no_impact_count={this.state.summary_report.non_compliant_no_impact_count}



                                                                                        partially_compliant_critical_count={this.state.summary_report.partially_compliant_critical_count}
                                                                                        partially_compliant_high_count={this.state.summary_report.partially_compliant_high_count}
                                                                                        partially_compliant_low_count={this.state.summary_report.partially_compliant_low_count}
                                                                                        partially_compliant_medium_count={this.state.summary_report.partially_compliant_medium_count}
                                                                                        partially_compliant_no_impact_count={this.state.summary_report.partially_compliant_no_impact_count}


                                                                                        not_applicable_critical_count={this.state.summary_report.not_applicable_critical_count}
                                                                                        not_applicable_high_count={this.state.summary_report.not_applicable_high_count}
                                                                                        not_applicable_low_count={this.state.summary_report.not_applicable_low_count}
                                                                                        not_applicable_medium_count={this.state.summary_report.not_applicable_medium_count}
                                                                                        not_applicable_no_impact_count={this.state.summary_report.not_applicable_no_impact_count}
                                                                                    />
                                                                                    {/* </div> */}
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>

                                                                    {/* Type based MultiStackedBarChart*/}
                                                                    <Row className="g-1 mt-2">
                                                                        <Col md={12} >
                                                                            <Card id="card_container"  >

                                                                                <CardBody  >
                                                                                    <CardTitle>Types</CardTitle>
                                                                                    <Container>
                                                                                        <div>
                                                                                            <TypeMultiStackedBarChart
                                                                                                // card_container={"card_container"}
                                                                                                // cardWidth={this.state.cardWidth}
                                                                                                total_checkpoints={this.state.summary_report.total_checkpoints}

                                                                                                compliantCount={this.state.summary_report.total_complaint_type_count}
                                                                                                nonCompliantCount={this.state.summary_report.total_non_complaint_type_count}
                                                                                                partiallyCompliantCount={this.state.summary_report.total_partially_type_count}
                                                                                                notApplicableCount={this.state.summary_report.total_not_applicable_type_count}


                                                                                                complaint_type_array={this.state.summary_report.unique_type_complaint}
                                                                                                non_complaint_type_array={this.state.summary_report.unique_type_non_complaint}
                                                                                                partially_compliant_type_array={this.state.summary_report.unique_type_partially_complaint}
                                                                                                not_applicable_type_array={this.state.summary_report.unique_type_not_applicable}
                                                                                            />
                                                                                        </div>
                                                                                    </Container>
                                                                                </CardBody>
                                                                            </Card>

                                                                        </Col>
                                                                    </Row>



                                                                    {
                                                                        (this.state.summary_report.unique_type_complaint.length > 0 ||
                                                                            this.state.summary_report.unique_type_non_complaint.length > 0 ||
                                                                            this.state.summary_report.unique_type_partially_complaint.length > 0 ||
                                                                            this.state.summary_report.unique_type_not_applicable.length > 0) &&
                                                                        <>
                                                                            {/* Compliant - MultiStackedBarChart */}
                                                                            <Row className="g-2 mt-2">
                                                                                <Col md={12}>
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            <CardTitle>Compliant</CardTitle>
                                                                                            <Container>
                                                                                                {this.state.summary_report.unique_type_complaint.length > 0 ?
                                                                                                    <>
                                                                                                        <Row className="g-2">
                                                                                                            <Col md={12}>
                                                                                                                <Table bordered responsive>
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th style={{ fontSize: '12px' }}>Type</th>
                                                                                                                            <th className="text-danger" style={{ fontSize: '12px' }}>Critical</th>
                                                                                                                            <th className="text-warning" style={{ fontSize: '12px' }}>High</th>
                                                                                                                            <th className="text-info" style={{ fontSize: '12px' }}>Medium</th>
                                                                                                                            <th className="text-success" style={{ fontSize: '12px' }}>Low</th>
                                                                                                                            <th className="text-primary" style={{ fontSize: '12px' }}>No Impact</th>
                                                                                                                            <th style={{ fontSize: '12px' }}>Total</th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {this.state.summary_report.unique_type_complaint.map((element, indx) => (
                                                                                                                            <tr key={indx}>
                                                                                                                                <td>{element.type_name}</td>
                                                                                                                                <td>{element.critical_count}</td>
                                                                                                                                <td>{element.high_count}</td>
                                                                                                                                <td>{element.medium_count}</td>
                                                                                                                                <td>{element.low_count}</td>
                                                                                                                                <td>{element.no_impact_count}</td>
                                                                                                                                <td>{element.count}</td>
                                                                                                                            </tr>
                                                                                                                        ))}
                                                                                                                    </tbody>
                                                                                                                </Table>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <CompliantMultiStackedBarChart
                                                                                                            complaint_critical_count={this.state.graph_info.compliant_info.complaint_critical_count}
                                                                                                            complaint_high_count={this.state.graph_info.compliant_info.complaint_high_count}
                                                                                                            complaint_low_count={this.state.graph_info.compliant_info.complaint_low_count}
                                                                                                            complaint_medium_count={this.state.graph_info.compliant_info.complaint_medium_count}
                                                                                                            complaint_no_impact_count={this.state.graph_info.compliant_info.complaint_no_impact_count}


                                                                                                            critical_type_info={this.state.graph_info.compliant_info.critical_type_info}
                                                                                                            high_type_info={this.state.graph_info.compliant_info.high_type_info}
                                                                                                            low_type_info={this.state.graph_info.compliant_info.low_type_info}
                                                                                                            medium_type_info={this.state.graph_info.compliant_info.medium_type_info}
                                                                                                            no_impact_type_info={this.state.graph_info.compliant_info.no_impact_type_info}
                                                                                                        // compliant_info = {this.state.graph_info.compliant_info}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    <Row>
                                                                                                        <Col className="text-center">
                                                                                                            <CardTitle>No Data Available</CardTitle>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }
                                                                                            </Container>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>

                                                                            {/* Non Compliant - MultiStackedBarChart */}
                                                                            <Row className="g-2 mt-2">
                                                                                <Col md={12}>
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            <CardTitle>Non Compliant</CardTitle>
                                                                                            <Container>

                                                                                                {this.state.summary_report.unique_type_non_complaint.length > 0 ?
                                                                                                    <>
                                                                                                        <Row className="g-2">
                                                                                                            <Col md={12}>
                                                                                                                <Table bordered responsive>
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th style={{ fontSize: '12px' }}>Type</th>
                                                                                                                            <th className="text-danger" style={{ fontSize: '12px' }}>Critical</th>
                                                                                                                            <th className="text-warning" style={{ fontSize: '12px' }}>High</th>
                                                                                                                            <th className="text-info" style={{ fontSize: '12px' }}>Medium</th>
                                                                                                                            <th className="text-success" style={{ fontSize: '12px' }}>Low</th>
                                                                                                                            <th className="text-primary" style={{ fontSize: '12px' }}>No Impact</th>
                                                                                                                            <th style={{ fontSize: '12px' }}>Total</th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {this.state.summary_report.unique_type_non_complaint.map((element, indx) => (
                                                                                                                            <tr key={indx}>
                                                                                                                                <td>{element.type_name}</td>
                                                                                                                                <td>{element.critical_count}</td>
                                                                                                                                <td>{element.high_count}</td>
                                                                                                                                <td>{element.medium_count}</td>
                                                                                                                                <td>{element.low_count}</td>
                                                                                                                                <td>{element.no_impact_count}</td>
                                                                                                                                <td>{element.count}</td>
                                                                                                                            </tr>
                                                                                                                        ))}
                                                                                                                    </tbody>
                                                                                                                </Table>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <NonCompliantMultiStackedBarChart
                                                                                                            complaint_critical_count={this.state.graph_info.non_compliant_info.non_complaint_critical_count}
                                                                                                            complaint_high_count={this.state.graph_info.non_compliant_info.non_complaint_high_count}
                                                                                                            complaint_low_count={this.state.graph_info.non_compliant_info.non_complaint_low_count}
                                                                                                            complaint_medium_count={this.state.graph_info.non_compliant_info.non_complaint_medium_count}
                                                                                                            complaint_no_impact_count={this.state.graph_info.non_compliant_info.non_complaint_no_impact_count}


                                                                                                            critical_type_info={this.state.graph_info.non_compliant_info.critical_type_info}
                                                                                                            high_type_info={this.state.graph_info.non_compliant_info.high_type_info}
                                                                                                            low_type_info={this.state.graph_info.non_compliant_info.low_type_info}
                                                                                                            medium_type_info={this.state.graph_info.non_compliant_info.medium_type_info}
                                                                                                            no_impact_type_info={this.state.graph_info.non_compliant_info.no_impact_type_info}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    <Row>
                                                                                                        <Col className="text-center">
                                                                                                            <CardTitle>No Data Available</CardTitle>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }
                                                                                            </Container>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>


                                                                            {/* Partially Compliant - MultiStackedBarChart */}
                                                                            <Row className="g-2 mt-2">
                                                                                <Col md={12}>
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            <CardTitle>Partially Compliant</CardTitle>
                                                                                            <Container>

                                                                                                {this.state.summary_report.unique_type_partially_complaint.length > 0 ?
                                                                                                    <>
                                                                                                        <Row className="g-2">
                                                                                                            <Col md={12}>
                                                                                                                <Table bordered responsive>
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th style={{ fontSize: '12px' }}>Type</th>
                                                                                                                            <th className="text-danger" style={{ fontSize: '12px' }}>Critical</th>
                                                                                                                            <th className="text-warning" style={{ fontSize: '12px' }}>High</th>
                                                                                                                            <th className="text-info" style={{ fontSize: '12px' }}>Medium</th>
                                                                                                                            <th className="text-success" style={{ fontSize: '12px' }}>Low</th>
                                                                                                                            <th className="text-primary" style={{ fontSize: '12px' }}>No Impact</th>
                                                                                                                            <th style={{ fontSize: '12px' }}>Total</th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {this.state.summary_report.unique_type_partially_complaint.map((element, indx) => (
                                                                                                                            <tr key={indx}>
                                                                                                                                <td>{element.type_name}</td>
                                                                                                                                <td>{element.critical_count}</td>
                                                                                                                                <td>{element.high_count}</td>
                                                                                                                                <td>{element.medium_count}</td>
                                                                                                                                <td>{element.low_count}</td>
                                                                                                                                <td>{element.no_impact_count}</td>
                                                                                                                                <td>{element.count}</td>
                                                                                                                            </tr>
                                                                                                                        ))}
                                                                                                                    </tbody>
                                                                                                                </Table>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <PartiallyCompliantMultiStackedBarChart
                                                                                                            complaint_critical_count={this.state.graph_info.partially_compliant_info.partially_complaint_critical_count}
                                                                                                            complaint_high_count={this.state.graph_info.partially_compliant_info.partially_complaint_high_count}
                                                                                                            complaint_low_count={this.state.graph_info.partially_compliant_info.partially_complaint_low_count}
                                                                                                            complaint_medium_count={this.state.graph_info.partially_compliant_info.partially_complaint_medium_count}
                                                                                                            complaint_no_impact_count={this.state.graph_info.partially_compliant_info.partially_complaint_no_impact_count}


                                                                                                            critical_type_info={this.state.graph_info.partially_compliant_info.critical_type_info}
                                                                                                            high_type_info={this.state.graph_info.partially_compliant_info.high_type_info}
                                                                                                            low_type_info={this.state.graph_info.partially_compliant_info.low_type_info}
                                                                                                            medium_type_info={this.state.graph_info.partially_compliant_info.medium_type_info}
                                                                                                            no_impact_type_info={this.state.graph_info.partially_compliant_info.no_impact_type_info}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    <Row>
                                                                                                        <Col className="text-center">
                                                                                                            <CardTitle>No Data Available</CardTitle>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }
                                                                                            </Container>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>


                                                                            {/* Not Applicable - MultiStackedBarChart */}
                                                                            <Row className="g-2 mt-2">
                                                                                <Col md={12}>
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            <CardTitle>Not Applicable</CardTitle>
                                                                                            <Container>

                                                                                                {this.state.summary_report.unique_type_not_applicable.length > 0 ?
                                                                                                    <>
                                                                                                        <Row className="g-2">
                                                                                                            <Col md={12}>
                                                                                                                <Table bordered responsive>
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th style={{ fontSize: '12px' }}>Type</th>
                                                                                                                            <th className="text-danger" style={{ fontSize: '12px' }}>Critical</th>
                                                                                                                            <th className="text-warning" style={{ fontSize: '12px' }}>High</th>
                                                                                                                            <th className="text-info" style={{ fontSize: '12px' }}>Medium</th>
                                                                                                                            <th className="text-success" style={{ fontSize: '12px' }}>Low</th>
                                                                                                                            <th className="text-primary" style={{ fontSize: '12px' }}>No Impact</th>
                                                                                                                            <th style={{ fontSize: '12px' }}>Total</th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {this.state.summary_report.unique_type_not_applicable.map((element, indx) => (
                                                                                                                            <tr key={indx}>
                                                                                                                                <td>{element.type_name}</td>
                                                                                                                                <td>{element.critical_count}</td>
                                                                                                                                <td>{element.high_count}</td>
                                                                                                                                <td>{element.medium_count}</td>
                                                                                                                                <td>{element.low_count}</td>
                                                                                                                                <td>{element.no_impact_count}</td>
                                                                                                                                <td>{element.count}</td>
                                                                                                                            </tr>
                                                                                                                        ))}
                                                                                                                    </tbody>
                                                                                                                </Table>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <NotApplicableMultiStackedBarChart
                                                                                                            complaint_critical_count={this.state.graph_info.not_applicable_info.not_applicable_critical_count}
                                                                                                            complaint_high_count={this.state.graph_info.not_applicable_info.not_applicable_high_count}
                                                                                                            complaint_low_count={this.state.graph_info.not_applicable_info.not_applicable_low_count}
                                                                                                            complaint_medium_count={this.state.graph_info.not_applicable_info.not_applicable_medium_count}
                                                                                                            complaint_no_impact_count={this.state.graph_info.not_applicable_info.not_applicable_no_impact_count}


                                                                                                            critical_type_info={this.state.graph_info.not_applicable_info.critical_type_info}
                                                                                                            high_type_info={this.state.graph_info.not_applicable_info.high_type_info}
                                                                                                            low_type_info={this.state.graph_info.not_applicable_info.low_type_info}
                                                                                                            medium_type_info={this.state.graph_info.not_applicable_info.medium_type_info}
                                                                                                            no_impact_type_info={this.state.graph_info.not_applicable_info.no_impact_type_info}
                                                                                                        />
                                                                                                    </>
                                                                                                    :
                                                                                                    <Row>
                                                                                                        <Col className="text-center">
                                                                                                            <CardTitle>No Data Available</CardTitle>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }
                                                                                            </Container>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    }






                                                                    {/* Location details Section */}
                                                                    {
                                                                        this.state.endpointData.audit_lat !== undefined && this.state.endpointData.audit_long !== undefined && this.state.endpointData.audit_lat !== null && this.state.endpointData.audit_long !== null &&
                                                                        <Row className="g-2">
                                                                            <Col md={12}>
                                                                                <Card
                                                                                    style={{ borderRadius: "0.5rem", marginBottom: 0 }}
                                                                                    className="mt-1"
                                                                                >
                                                                                    <CardBody style={{ padding: "12px 20px" }}>
                                                                                        <CardTitle>Location Details</CardTitle>
                                                                                        <Geolocation latitude={this.state.endpointData.audit_lat} longitude={this.state.endpointData.audit_long} />
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                    }


                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row style={{ height: "76vh", overflow: "auto" }}>
                                                    <Col sm="12">
                                                        {this.state.nonCompliantCheckpoints.length > 0 &&
                                                            <Card className="mt-2" style={{ borderLeft: "3px solid rgb(244, 106, 106)" }}>
                                                                <CardBody>
                                                                    <Table bordered responsive>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-secondary">S.No</th>
                                                                                <th className="text-secondary">Checkpoint</th>
                                                                                <th className="text-secondary">CP Compliance</th>
                                                                                <th className="text-secondary">Impact Level</th>
                                                                                <th className="text-secondary">Type</th>
                                                                                <th className="text-secondary">Max Score</th>
                                                                                <th className="text-secondary">Min Score</th>
                                                                                <th className="text-secondary">Audit Score</th>
                                                                                <th className="text-secondary">Audit Score %</th>
                                                                                <th className="text-secondary">Score Gap</th>
                                                                                <th className="text-secondary">Score Gap %</th>
                                                                                <th className="text-secondary">Action Plan</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.state.nonCompliantCheckpoints.map((item, i) => (
                                                                                    <tr key={i} >
                                                                                        <td>{i + 1}</td>
                                                                                        <td>{item.checkpoint}</td>
                                                                                        <td>{
                                                                                            item.cp_status !== "0" ? <><span className="font-size-10"> </span><label className={item.cp_is_compliance ? "badge badge-soft-success font-size-10" : "badge badge-soft-danger font-size-10"}>{item.cp_compliance?.name}</label> </> : null
                                                                                        }
                                                                                        </td>
                                                                                        <td>{
                                                                                            item.impact_level === 'Low'
                                                                                                ? <label className="font-size-12 badge badge-soft-success">Low</label>
                                                                                                : item.impact_level === 'Medium'
                                                                                                    ? <label className="font-size-12 badge badge-soft-info">Medium</label>
                                                                                                    : item.impact_level === 'High'
                                                                                                        ? <label className="font-size-12 badge badge-soft-warning">High</label>
                                                                                                        : item.impact_level === 'Critical'
                                                                                                            ? <label className="font-size-12 badge badge-soft-danger">Critical</label>
                                                                                                            : <label className="font-size-12 badge badge-soft-primary ">No impact</label>

                                                                                        }</td>
                                                                                        <td>{item.compl_type.map((item2, index) => {
                                                                                            const formattedItem = index === item.compl_type.length - 1 ? item2 + '.' : item2;
                                                                                            return formattedItem;
                                                                                        }).join(', ')}</td>

                                                                                        <td>{item.max_score}</td>
                                                                                        <td>{item.min_score}</td>
                                                                                        <td>{item.cp_otpion_score !== undefined && item.cp_otpion_score !== null ? parseFloat(item.cp_otpion_score?.$numberDecimal).toFixed(2) : 'No Score'}</td>
                                                                                        <td>
  {item.cp_otpion_score !== undefined && item.cp_otpion_score !== null 
    ? (item.cp_otpion_score?.$numberDecimal / item.max_score * 100).toFixed(2) 
    : 'No Score'}
</td>
<td>
  {(item.max_score - (item.cp_otpion_score !== undefined && item.cp_otpion_score !== null 
    ? parseFloat(item.cp_otpion_score?.$numberDecimal) 
    : 0)).toFixed(2)}
</td>
<td>
  {((item.max_score - (item.cp_otpion_score !== undefined && item.cp_otpion_score !== null 
    ? parseFloat(item.cp_otpion_score?.$numberDecimal) 
    : 0)) / item.max_score * 100).toFixed(2)}
</td>

                                                                                        {/* <td>{item.cp_otpion_score !== undefined && item.cp_otpion_score !== null ? item.cp_otpion_score?.$numberDecimal / item.max_score * 100 : 'No Score'}</td>
                                                                                        <td>{item.max_score - (item.cp_otpion_score !== undefined && item.cp_otpion_score !== null ? item.cp_otpion_score?.$numberDecimal : 0)}</td>
                                                                                        <td>{(item.max_score - item.cp_otpion_score !== undefined && item.cp_otpion_score !== null ? item.cp_otpion_score?.$numberDecimal : 0) / item.max_score * 100}</td> */}
                                                                                        <td className="text-center">
                                                                                            {item.cp_actionplans.length !== 0 ?
                                                                                                <i className="mdi mdi-arrow-right font-size-20 text-primary" style={{ cursor: 'pointer' }} onClick={() => this.onShowActionPlan(item)}></i>
                                                                                                :
                                                                                                <label className="font-size-10">No Actionplan</label>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                        </tbody>
                                                                    </Table>

                                                                </CardBody>
                                                            </Card>
                                                        }

                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <Row >
                                                    <Col style={{ maxWidth: 240, height: "80vh", borderRight:"1px solid #dedede"  }}>
                                                        

                                                                <div
                                                                    className="form-check form-switch mb-3 "
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizesm"
                                                                        defaultChecked
                                                                        checked={this.state.isExpanded}
                                                                        onClick={
                                                                            this.handleToggleExpand
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="customSwitchsizesm"
                                                                    >
                                                                        {this.state.isExpanded ? 'Expand All' : 'Collapse All'}
                                                                    </label>
                                                                </div>
                                                                {
                                                                    !this.state.isExpanded && this.state.auditCollapseOpen &&
                                                                    <div className="col gap-2">
                                                                        {/* Impact level filters */}
                                                                        <div className="border-bottom border-secondary pb-2 my-3">
                                                                        <label>Impact Level</label>

                                                                        <div className="button-items mb-2">
                                                                            <div className="btn-group-vertical" role="group" aria-label="Basic radio toggle button group">
                                                                                <Button color={"danger"} className="btn-sm" style={{width:150}} outline={this.state.impactAll !== 'All'} onClick={() => this.filterTags('All', '1')}>
                                                                                    All
                                                                                </Button>
                                                                                <Button color={"danger"} className="btn-sm" style={{width:150}} outline={this.state.selectedImpactLevel !== 'Critical'} onClick={() => this.filterTags('Critical', '1')}>
                                                                                    Critical
                                                                                </Button>
                                                                                <Button color={"warning"} className="btn-sm" style={{width:150}} outline={this.state.selectedImpactLevel !== 'High'} onClick={() => this.filterTags('High', '1')}>
                                                                                    High
                                                                                </Button>
                                                                                <Button color={"info"} className="btn-sm" style={{width:150}} outline={this.state.selectedImpactLevel !== 'Medium'} onClick={() => this.filterTags('Medium', '1')}>
                                                                                    Medium
                                                                                </Button>
                                                                                <Button color="success" className="btn-sm" style={{width:150}} outline={this.state.selectedImpactLevel !== 'Low'} onClick={() => this.filterTags('Low', '1')}>
                                                                                    Low
                                                                                </Button>
                                                                                <Button color={"primary"} className="btn-sm" style={{width:150}} outline={this.state.selectedImpactLevel !== 'no_impact'} onClick={() => this.filterTags('no_impact', '1')}>
                                                                                    No impact
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        {/* Cp Compliants Filter */}
                                                                        <div className="border-bottom border-secondary pb-2 my-3">
                                                                        <label>Compliance Status</label>
                                                                        <div className="button-items mb-2">
                                                                            <div className="btn-group-vertical" role="group" aria-label="Basic radio toggle button group">
                                                                                <Button color={"info"} className="btn-sm" style={{width:150}} outline={this.state.cpAll !== 'All'} onClick={() => this.filterTags('All', '2')}>
                                                                                    All
                                                                                </Button>
                                                                                <Button color={"success"} className="btn-sm" style={{width:150}} outline={this.state.selectedCPCompliant !== 'Compliant'} onClick={() => this.filterTags('Compliant', '2')}>
                                                                                    Compliant
                                                                                </Button>
                                                                                <Button color={"danger"} className="btn-sm" style={{width:150}} outline={this.state.selectedCPCompliant !== 'Non Compliant'} onClick={() => this.filterTags('Non Compliant', '2')}>
                                                                                    Non-Compliant
                                                                                </Button>
                                                                                <Button color={"warning"} className="btn-sm" style={{width:150}} outline={this.state.selectedCPCompliant !== 'Partially Compliant'} onClick={() => this.filterTags('Partially Compliant', '2')}>
                                                                                    Partially Compliant
                                                                                </Button>
                                                                                <Button color="secondary" className="btn-sm" style={{width:150}} outline={this.state.selectedCPCompliant !== 'Not Applicable'} onClick={() => this.filterTags('Not Applicable', '2')}>
                                                                                    Not Applicable
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        </div>

                                                                        <div className="">
                                                                        <label>Type</label>

                                                                        {/* Type Filter */}
                                                                        <div className="button-items">
                                                                            <div className="btn-group-vertical" role="group" aria-label="Basic radio toggle button group">
                                                                                <Button color={"info"} className="btn-sm" style={{width:150}} outline={this.state.typeAll !== 'All'} onClick={() => this.filterTags('All', '3')}>
                                                                                    All
                                                                                </Button>
                                                                                <Button color={"info"} className="btn-sm" style={{width:150}} outline={this.state.selectedType !== 'Regulatory'} onClick={() => this.filterTags('Regulatory', '3')}>
                                                                                    Regulatory
                                                                                </Button>
                                                                                <Button color={"success"} className="btn-sm" style={{width:150}} outline={this.state.selectedType !== 'Internal'} onClick={() => this.filterTags('Internal', '3')}>
                                                                                    Internal
                                                                                </Button>
                                                                                <Button color={"danger"} className="btn-sm" style={{width:150}} outline={this.state.selectedType !== 'External'} onClick={() => this.filterTags('External', '3')}>
                                                                                    External
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        </div>

                                                                    </div>
                                                                }
                                                            

                                                    </Col>
                                                    <Col className="w-100" style={{ height: "76vh", overflow: "auto" }}>
                                                        {
                                                            !this.state.isExpanded ?
                                                                <>

                                                                    {
                                                                        this.state.no_data ?
                                                                            <>
                                                                                {
                                                                                    treeData.map((child, index) => (

                                                                                        <NestedChildren
                                                                                            key={child._id}
                                                                                            // children={child}
                                                                                            endpointData={this.state.endpointData}
                                                                                            index={index}
                                                                                        >
                                                                                            {child}
                                                                                        </NestedChildren>
                                                                                    ))}
                                                                            </>
                                                                            :
                                                                            <div style={{ textAlign: 'center', padding: '20px' }}>
                                                                                <Empty
                                                                                    image={<AiOutlineWarning style={{ fontSize: '48px', color: '#f46a6a' }} />} // Use the imported icon
                                                                                    description="No Data Found"
                                                                                />
                                                                            </div>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        treeData.map((child, index) => (
                                                                            <ExpandNestedChildren
                                                                                key={child._id}
                                                                                // children={child}
                                                                                endpointData={this.state.endpointData}
                                                                                index={index}
                                                                                expanded={this.state.isExpanded}

                                                                            >
                                                                                {child}
                                                                            </ExpandNestedChildren>
                                                                        ))}
                                                                </>
                                                        }
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="4">
                                                <Row style={{ height: "76vh", overflow: "auto" }}>
                                                    <Col sm="12">
                                                        {this.state.endpointData.audit_signature
                                                            .length !== 0 ? (
                                                            <Card style={{ borderRadius: "0.5rem" }} className="mt-1">
                                                                <CardBody style={{ paddingBottom: 0 }}>
                                                                    <div style={{ color: "rgb(84, 95, 112)", marginBottom: 10 }}>
                                                                        {" "}
                                                                        Name & Signature of Maintenance Personnel{" "}
                                                                    </div>
                                                                    <Row className="py-3 g-2">



                                                                        {this.state.endpointData.audit_signature.map(
                                                                            (item, i) => {
                                                                                return (
                                                                                    <Col className="col-auto" key={i}>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "column",
                                                                                                border: "1px solid #dedede",
                                                                                                borderRadius: 15,
                                                                                                padding: 10
                                                                                            }}
                                                                                        >
                                                                                            <div className="p-2">
                                                                                                <img
                                                                                                    height="80"
                                                                                                    src={
                                                                                                        this.state.imagePreviewUrl +
                                                                                                        item.name
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="p-2">
                                                                                                <label className="">
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: "13px",
                                                                                                            color: "rgb(31, 37, 51)",
                                                                                                            fontWeight: 400,
                                                                                                        }}
                                                                                                    >
                                                                                                        Name : {item.sign_by}

                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: "13px",
                                                                                                            color: "rgb(31, 37, 51)",
                                                                                                            fontWeight: 400,
                                                                                                        }}
                                                                                                    ></span>Designation : {item.designation}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                );
                                                                            }
                                                                        )}

                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>


                                    </div>
                                </Col>
                            </Row>


                            <Modal size="lg" isOpen={this.state.showActionplanModal} toggle={() => this.setState({ showActionplanModal: false, actionplan_data: [] })}>
                                <div className="modal-header">
                                    <h6 className="modal-title">Actionplan : </h6>
                                    <button type="button" className="close" onClick={() => this.setState({ showActionplanModal: false, actionplan_data: [] })}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {/* <div className="modal-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '10px' }}> */}


                                <div className="">
                                    <Card>
                                        <CardBody>
                                            <h5>{this.state.selected_checkpoint.checkpoint}</h5>
                                            <label className="font-size-12">Corrective And Preventive Action(CAPA)</label>
                                            <PreviewCAPA actionplans={this.state.actionplan_data} />


                                            {/* <label>Corrective & Preventive Correction(CAPA)</label> */}
                                            {/* <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th className="text-primary">Action Plan</th>
                                                        <th className="text-primary">Observation</th>
                                                        <th className="text-primary">Root Cause</th>
                                                        <th className="text-primary">Action</th>
                                                        <th className="text-primary">Target Date</th>
                                                        <th className="text-primary">Assign to Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.actionplan_data.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>Action plan {i + 1}</td>
                                                            <td>{item.observation}</td>
                                                            <td>{item.root_cause}</td>
                                                            <td>{item.actionplan}</td>
                                                            <td>{item.target_date}</td>
                                                            <td>{item.to_email}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table> */}
                                        </CardBody>
                                    </Card>
                                </div>
                            </Modal>
                        </Container>


                    </div>
                    {this.state.blur_loading && (
                        <>
                            <div className="loader-overlay">
                                <div className="loader">
                                    <LoadingOutlined />
                                </div>
                            </div>

                        </>
                    )}
                </React.Fragment>
            );
        }
        else if (this.state.pageLoading) {
            return (
                <React.Fragment>
                    <div className="page-content" style={{ marginTop: 0 }}  >
                        <Container fluid >
                            <Row style={{ minHeight: "calc(100vh - 450px)" }}>
                                <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                                    <Spinner className="mb-2" color="primary" />
                                    <div>Loading ...</div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            )

        }
        else {
            return null;
        }
    }
}

{/* <div className="mb-2">
                                                                                        <Progress multi>
                                                                                            <Progress bar color="success" value={(this.state.summary_info?.compliantCount / 4 * 100)}>{this.state.summary_info?.compliantCount}</Progress>
                                                                                            <Progress bar color="danger" value={(this.state.summary_info?.nonCompliantCount / 4 * 100)}>{this.state.summary_info?.nonCompliantCount}</Progress>
                                                                                            <Progress bar color="warning" value={(this.state.summary_info?.partiallyCompliantCount / 4 * 100)}>{this.state.summary_info?.partiallyCompliantCount}</Progress>
                                                                                            <Progress bar color="secondary" value={(this.state.summary_info?.notApplicableCount / 4 * 100)}>{this.state.summary_info?.notApplicableCount}</Progress>
                                                                                        </Progress>
                                                                                    </div> */}