
import uuid from 'react-uuid'
const _ = require('lodash')
var urlSocket = require("../../../../helpers/urlSocket")



let signature = {

    submit:(canvas) => {
       // if (!this.state.issign_empty) {
            canvas.current.exportImage("png").then(data => {
                this.setState({ sign_url: data })
                var ImageURL = data
                var block = ImageURL.split(";");
                var contentType = block[0].split(":")[1];// In this case "image/gif"
                var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
                var blob = this.b64toBlob(realData, contentType);


                this.sign_upload(blob)
            })
       // }
       // else {
           // this.setState({ sign_valid: true })
        //}
    },

    b64toBlob:(b64Data, contentType, sliceSize)=> {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    },




    clear:() => { this.canvas.current.clearCanvas(); this.setState({ sign_url: null, up_sign_url: null, issign_empty: true, sign_valid: false }) },
    undo:() => { this.canvas.current.undo() },
    redo:() => { this.canvas.current.redo() },

    sign_upload :async (image_info) => {

        const img = new FormData()
        let u_id = uuid()

        img.append('image', image_info, u_id + 'sign.png')
        // console.log(u_id + 'sign.png');

        await urlSocket.post('/storeImage/uploadAzure', img)
            .then((res) => {
                if (res.status == 200) {
                    this.setState({ up_sign_url: String(u_id) + 'sign.png' })
                    // console.log("uploaded _sign", res)
                    this.update_sign(u_id)
                }
            })
    },

    update_sign:(u_id) => {
        var signature = {
            "id": String(u_id),
            "fname": String(u_id) + 'sign.png',
            "filename": String(u_id) + 'sign.png',
            "filepath": String(u_id) + 'sign.png',
            "filetype": 'image/jpg;base64',
            "sync_status": "1",
            "download_status": "0",
            "designation": this.props.index == 0 ? "Territory MANAGER" : this.props.index == 1 ? this.state.department_name + " MANAGER" : this.state.designation,
            "name": this.props.index == 0 ? this.props.tm_name : this.state.name,
            //
            "designation_id": "0",
        }


        // console.log("entity_id", this.props.entity_id)
        // console.log("signature", signature)
        urlSocket.post('/web/web_img_sync_summary_entities', {
            entity_id: this.props.entity_id,
            signature: signature,
            index: this.props.index
        })
            .then((res) => {

                this.setState({
                    dummy: true,
                    uploaded_sign: true,
                    uploaded_sign_url: res.data.data.fname
                })
            })
            this.props.signature_update(signature)
    },

    del_sign : () => {
        this.props.del_sign(this.props.entity_id, this.props.current_data)
    }
}

export default signature;