import React, { useEffect, useState } from 'react';
import { Col , Button,Row} from 'reactstrap';

//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector,useDispatch } from 'react-redux';
import { handleGlobalIncidentFilter,setIncidentsList } from '../../../../store/incidentReport/incdreportslice';

const JobListGlobalFilter = ({setGlobalFilter,data}) => {
    const dispatch = useDispatch()
    const incidentReport = useSelector(state => state.incdreportslice)
    const [selectedStatus,setSelectedStatus] = useState("All")
    const [selectDate, setSelectDate] = useState();
    const filterOptions = incidentReport.incidentsListFilterInfo

    // const [selectedFilter,setSelectedFilter] = useState([])
    const [selectedFilter,setSelectedFilter] =useState(
        [
            {
            id : "1"
        },
        {
            id : "0"
        },
        {
            id : "2"
        },
        {
            id : "null"
        }

    ]
        // ["1","0","2",'null']
    )

    useEffect(()=>{
        // setSelectedFilter(filterOptions)
    },[incidentReport.incidentsListFilterInfo])


    const dateChange = (date) => {
        setSelectDate(date)
    };
    const [authUser,setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
//     const [filterOptions,setFilterOptions]=useState([
//         {id:"" , name:"All" , color :"btn btn-sm btn-dark"},
//         {id : "null" , name :"Not Allocated" , color :"btn btn-sm btn-secondary"},
//         {id : "0" , name :"Not Started" , color :"btn btn-sm btn-secondary"},
//         {id : "1" , name :"In Progress" ,color :"btn btn-sm btn-warning"},
//         {id : "2" , name :"Completed" , color :"btn btn-sm btn-primary"},
//         {id : "3" , name :"Closed" , color :"btn btn-sm btn-success"},
//         {id : "4" , name :"Rejected" , color :"btn btn-sm btn-danger"},
//     ]
// )


    useEffect(()=>{
        // handleSelectChange()
        async function retriveData() {
            var page_query= {
                incd_resp_person:{
                $elemMatch: { user_id: authUser.user_data._id }
            }
            }
            console.log(page_query,'page_query');
            var data = await dispatch(handleGlobalIncidentFilter(_.map(selectedFilter,"id"),page_query))
            console.log(data,'data');
            // dispatch(setIncidentsList(data)) 
        } 

        retriveData()
       
    },[])




    const handleSelectChange=async(ele)=>{
        console.log('event',ele);
        const updatedinfo = _.cloneDeep(selectedFilter)
        const getIdx = _.findIndex(selectedFilter,{id :ele.id})
        console.log(getIdx,'getIdx',updatedinfo);
        if(getIdx === -1){
            updatedinfo.push(ele)
        }
        else{
            updatedinfo.splice(getIdx,1)
        }
        setSelectedFilter(updatedinfo)
        var page_query= {
            incd_resp_person:{
            $elemMatch: { user_id: authUser.user_data._id }
        }
        }
        console.log(page_query,'page_query');
      var data = await dispatch(handleGlobalIncidentFilter(_.map(updatedinfo,'id'),page_query))
      console.log(data,'data');
      dispatch(setIncidentsList(data))
      
    }


    return (
        <React.Fragment>  

            {
                console.log(filterOptions,'filterOptions',_.map(selectedFilter,"id"))
            }
            {/* <Row> */}
            <Col>
            {
                filterOptions.map((ele,idx)=>{
                    return(
                        <>
                        <input onChange={(e)=>{handleSelectChange(ele);
                          

                        }} type='checkbox'
                        defaultChecked={_.map(selectedFilter,"id").includes(ele.id)}
                        // defaultChecked={
                            // ele.id == "null" || ele.id === "0" || ele.id === "1" ||ele.id === "2" 
                        // }
                        /> &nbsp;
                    <button onClick={()=>{handleSelectChange(ele.id);setSelectedStatus(ele.name)}} key={idx} className={ele.color + ' me-1'}>{ele.name}({ele.count})</button>
                    </>
                    )
                })
            }

            


                {/* <select className="form-control select2 mb-3 mb-xxl-0"  onChange={(e)=>{
                    handleSelectChange(e.target.value)
                }}
                    >
                    {
                        filterOptions.map((ele,idx)=>{
                            return(
                            <option key={idx} value={ele.id}>{ele.name}</option>
                            )
                        })
                    }
                  
                </select> */}

            </Col>
            <Col>
            <div className="mb-0 font-size-16">
                {
                selectedStatus + " count : "  +data.length
            }
            </div>
            
            </Col>
            {/* </Row> */}
              {/* <option>Status</option>
                    <option value="Active">Active</option>
                    <option value="New">New</option>
                    <option value="Close">Close</option> */}
            {/* <Col xxl={2} lg={4}>
                <select className="form-control select2 mb-3 mb-xxl-0">
                    <option>Select Type</option>
                    <option value="1">Full Time</option>
                    <option value="2">Part Time</option>
                </select>
            </Col> */}
            {/* <Col xxl={2} lg={4}>
                <div id="datepicker1">
                    <DatePicker
                        className="form-control mb-3 mb-xxl-0"
                        selected={selectDate}
                        onChange={dateChange}
                    />
                </div>
            </Col> */}
            {/* <Col xxl={1} lg={4}>
                <div className='mb-3 mb-xxl-0'>
                    <button type="button" className="btn btn-soft-secondary w-100">
                        <i className="mdi mdi-filter-outline align-middle"></i> Filter</button>
                </div>
            </Col> */}
        </React.Fragment>
    )
};
export default JobListGlobalFilter;
