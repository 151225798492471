import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
    Container, Row, Col, Card, CardBody, CardTitle, Table, Button, Badge,
    Progress, Pagination, PaginationItem, PaginationLink,
    Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Multiselect } from 'multiselect-react-dropdown';
import urlSocket from '../../../../helpers/urlSocket';
import CryptoJS from 'crypto-js'
import Swal from "sweetalert2";
import { LoadingOutlined } from '@ant-design/icons';




// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */

const CrudUser = (props) => {
    console.log(props.location_info.method_selected,'props.location_info.method_selected')
    if(props.location_info.method_selected === "2"){
        const [show, setShow] = useState(true)
        const [dataLoaded, setdataLoaded] = useState(false)
        const [errorMessage, seterrorMessage] = useState(false)
        const [invalid_user, setinvalid_user] = useState(false)
        const [invalid_phone_num, setinvalid_phone_num] = useState(false)
        const [categoryDisabled, setcategoryDisabled] = useState(false)
        const [cat_name_exist, setcat_name_exist] = useState(false)
        const [givenEmail, setgivenEmail] = useState("")
        const [countryCode, setcountryCode] = useState(null)
        const [country_code_err, setcountry_code_err] = useState(false)
        const [givenName, setgivenName] = useState("")
        const [assigned_task_users, setassigned_task_users] = useState([])
        const [locationUserlvl, setLocationUserlvl] = useState(props.location_info.location_user_level)
        const [secret_key, setsecret_key] = useState('Pkdh347ey%3Tgs')
        const [catName, setcatName] = useState('')
        const [rolePermisson, setrolePermisson] = useState('')
        const [roleErr, setroleErr] = useState(false)
        const [btn_load,setbtn_load] = useState(false)
    
    
        const [db_info, setDbInfo] = useState({
            db_name: JSON.parse(sessionStorage.getItem("db_info")).db_name,
            encrypted_db_url: JSON.parse(sessionStorage.getItem("db_info")).encrypted_db_url
        })
        const [sessionUserInfo, setsessionUserInfo] = useState(JSON.parse(sessionStorage.getItem('authUser')))
    
        const multiRef = React.useRef()
    
    
    
        useEffect(() => {
            console.log("create users")
            setdataLoaded(true)

            var createNewExists = locationUserlvl.some(user => user.hirerachy_name === "+Create new");
            console.log(createNewExists,'createNewExists',locationUserlvl)
            if(!createNewExists){
                locationUserlvl.unshift({
                    hirerachy_name: "+Create new"
                })
            }

            // locationUserlvl.unshift({
            //     hirerachy_name: "+Create new"
            // })
            console.log(props.location_info, 'props.location_info')
        }, [])
    
    
        const emai_addrs = (e) => {
            var email = e.target.value
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                setgivenEmail(email)
                seterrorMessage(false)
            }
            else {
                seterrorMessage(true)
                setgivenEmail(email)
            }
    
        }
    
        const onSelectValues = (selectedList, selectedItem) => {
            if (selectedItem.hirerachy_name === "+Create new") {
                console.log(selectedList, selectedItem, multiRef)
                setcategoryDisabled(true)
    
            }
            else {
                console.log(selectedList, selectedItem)
                setassigned_task_users(selectedList)
            }
        }
    
        const onRemove = (selectedItem) => {
            console.log(selectedItem, 'selectedItem', assigned_task_users)
        }
    
    
    
        const Validsubmit = async (events, values) => {
            console.log(events, values, props.location_info, assigned_task_users)
            var password = CryptoJS.AES.encrypt(values.password, secret_key).toString()
    
            values["password"] = password
            values["confirmpassword"] = password
            values["userPoolId"] = JSON.parse(sessionStorage.getItem("client_info"))[0]["userPoolId"]
            values["clientId"] = JSON.parse(sessionStorage.getItem("client_info"))[0]["clientId"]
            values["encrypted_db_url"] = db_info.encrypted_db_url
            values["db_name"] = db_info.db_name
            values["facilities"] = JSON.parse(sessionStorage.getItem("client_info"))[0]["facilities"]
            values["company_id"] = sessionUserInfo.client_info[0]._id
            values["created_by"] = sessionUserInfo.user_data._id
            values["short_name"] = JSON.parse(sessionStorage.getItem("client_info"))[0]["short_name"]
            values["user_id"] = sessionUserInfo.user_data._id
            if (countryCode == null) {
                setcountry_code_err(true)
            }
            else {
                values["countrycode"] = countryCode
                setcountry_code_err(false)
            }
            if (rolePermisson == "") {
                setroleErr(true)
            }
            else {
                setroleErr(false)
                values["role_permission"] = rolePermisson
    
            }
            if (invalid_user === false && invalid_phone_num === false && country_code_err === false && values["countrycode"] !== undefined && cat_name_exist == false && rolePermisson !=="" ) {
                setbtn_load(true)
                try {
                    urlSocket.post('cog/cruduser', values).then(async (res) => {
                        console.log(res, 'res')
                        if (res.data.response_code === 500) {
                            if (!categoryDisabled) {
                                assigned_task_users.map((ele, idx) => {
                                    props.location_info.location_user_path.push({
                                        cat_type: ele.cat_type,
                                        designation: "",
                                        _id: res.data.admn_user[0]["_id"],
                                        name: values.firstname,
                                        title: props.location_info.name,
                                        hirerachy_name: ele.hirerachy_name,
                                        user_id: res.data.admn_user[0]["_id"],
                                        role_permission:rolePermisson,
                                        type :2
    
    
                                    })
                                })
    
                                props.location_info.location_unique_users.push({
                                    cat_type: assigned_task_users.map((ele, pos) => ele.cat_type),
                                    hirerachy_name: assigned_task_users.map((ele, pos) => ele.hirerachy_name),
                                    designation: "",
                                    _id: res.data.admn_user[0]["_id"],
                                    name: values.firstname,
                                    title: props.location_info.name,
                                    user_id: res.data.admn_user[0]["_id"],
                                    role_permission:rolePermisson,
                                    type :2
    
                                })
                            }
                            else {
                                var value = {}
                                props.location_info.location_user_level = _.reject(props.location_info.location_user_level, { hirerachy_name: "+Create new" })
                                console.log(props.location_info, 'locationinfo')
                                const maxCatType = Math.max(...props.location_info.location_user_level.map((item) => parseInt(item.cat_type, 10)));
                                console.log(maxCatType, 'maxCatType')
                                value['cat_type'] = String(maxCatType + 1)
                                value['hirerachy_name'] = catName
                                props.location_info.location_user_level.push(value)
                                console.log(props.location_info.location_user_level)
    
                                props.location_info.location_user_path.push({
                                    cat_type: value.cat_type,
                                    designation: "",
                                    _id: res.data.admn_user[0]["_id"],
                                    name: values.firstname,
                                    title: props.location_info.name,
                                    hirerachy_name: value.hirerachy_name,
                                    user_id: res.data.admn_user[0]["_id"],
                                    role_permission:rolePermisson,
                                    type :2,
                                })
    
                                props.location_info.location_unique_users.push({
                                    cat_type: [value.cat_type],
                                    hirerachy_name: [value.hirerachy_name],
                                    designation: "",
                                    _id: res.data.admn_user[0]["_id"],
                                    name: values.firstname,
                                    title: props.location_info.name,
                                    user_id: res.data.admn_user[0]["_id"],
                                    role_permission:rolePermisson,
                                    type :2
    
                                })
    
                            }
                            console.log(props.location_info,'props.location_info')
                            setbtn_load(false)

                            Swal.fire({
                                icon: 'success',
                                title: 'User Created successfully',
                                text: 'User Created successfully',
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Close',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sessionStorage.setItem(JSON.stringify(location_info), 'endpointData')
                                    window.location.reload()
                                }
        
                            })
                            // await updtPublishedLocation(props.location_info)
                        }
    
                    })
                }
                catch (error) {
    
                }
            }
    
        }
    
    
        const updtPublishedLocation = (location_info) => {
            location_info = _.reject(location_info.location_user_level,{hirerachy_name:"+Create new"})
    
            try {
                urlSocket.post('task/update-published-location', {
                    locationInfo: location_info,
                    encrypted_db_url: db_info.encrypted_db_url,
                    mode : "2"
                }).then((res) => {
                    console.log(res)
                    if (res.status === 200) {
                        setbtn_load(false)

                        Swal.fire({
                            icon: 'success',
                            title: 'User Created successfully',
                            text: 'User Created successfully',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Close',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                sessionStorage.setItem(JSON.stringify(location_info), 'endpointData')
                                window.location.reload()
                            }
    
                        })
                    }
                })
    
            } catch (error) {
    
            }
    
    
        }
    
    
    
    
        const validate_email = (e, mode) => {
            var admn_info = {}
            if (mode == 1) {
                admn_info["email_id"] = e.target.value
            }
            if (mode === 2) {
                admn_info["phone_number"] = e.target.value
            }
    
            admn_info["encrypted_db_url"] = db_info.encrypted_db_url
            admn_info["db_name"] = db_info.db_name
            admn_info["user_id"] = sessionUserInfo.user_data._id
    
            try {
                urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
                    if (mode == 1) {
                        if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                            setinvalid_user(true)
                        }
                        else {
                            setinvalid_user(false)
                        }
    
                    }
                    if (mode == 2) {
                        if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                            setinvalid_phone_num(true)
                        }
                        else {
                            setinvalid_phone_num(false)
    
                        }
    
    
                    }
                })
            }
            catch (error) {
                console.log(error, 'error')
            }
    
    
        }
    
    
    
        const validateCatName = (event) => {
    
            var validate_category_name = _.filter(locationUserlvl, item => {
                const cleanedHierarchyName = item.hirerachy_name?.replace(/\s/g, '').toLowerCase();
                const cleanedEventValue = event.target.value?.replace(/\s/g, '').toLowerCase();
                return cleanedHierarchyName === cleanedEventValue;
    
            })
            if (validate_category_name.length > 0) {
                setcat_name_exist(true)
            }
            else {
                setcat_name_exist(false)
            }
    
    
    
        }
    
    
    
        if (dataLoaded) {
            return (
                <div>
                    <Modal isOpen={props.modal} toggle={props.toggle}  >
                        <ModalHeader toggle={props.toggle} tag="h4">
                            Create User
                        </ModalHeader>
                        <ModalBody>
                            <AvForm className="form-horizontal" onValidSubmit={Validsubmit}>
                                <div className="mb-3">
                                    <label>Full Name :<span className="text-danger"> *</span></label>
                                    <AvField
                                        name="firstname"
                                        errorMessage="Please enter your name"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        type="text"
                                        onChange={(e) => { setgivenName(e.target.value) }}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Email Id :<span className="text-danger"> *</span></label>
                                    <AvField
                                        name="email_id"
                                        errorMessage="Please enter your Email ID"
                                        className="form-control"
                                        placeholder="Enter User Email"
                                        onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("e.preventDefault()") }}
                                        type="email"
                                        onChange={(e) => {
                                            emai_addrs(e)
                                                ; validate_email(e, 1)
                                        }}
                                        required
                                    />
                                    {
                                        errorMessage &&
                                        <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>
    
                                    }
                                    {
                                        invalid_user &&
                                        <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Email ID already exist fo another user</div>
    
                                    }
                                </div>
                                <div className="mb-3">
                                    <Row>
                                        <Col className="col-12">
                                            <Row>
                                                <label>Phone Number:<span className="text-danger"> *</span></label>
                                                <Col md={3} className="pe-0">
                                                    <select name="countrycode"
                                                        onChange={(e) => {
                                                            setcountryCode(e.target.value)
                                                        }}
                                                        className="form-select" required>
                                                        <option value="">Select</option>
                                                        {
                                                            props.client_info.countries.map((c, idx) => (
                                                                <option key={idx} value={c.code}>
                                                                    {c.code}{""}&nbsp;{c.label}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </Col>
                                                <Col md={9} className="ps-0">
                                                    <AvField
                                                        name="phone_number"
                                                        className="form-control"
                                                        placeholder="Enter Phone number"
                                                        errorMessage="Please enter your Phone Number"
                                                        type="number"
                                                        onChange={(e) => { validate_email(e, 2) }}
                                                        validate={{
                                                            required: { value: true },
                                                            minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                            maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                        }}
                                                    />
                                                    {
                                                        invalid_phone_num &&
                                                        <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Phone Number already exist fo another user</div>
    
                                                    }
                                                    {
                                                        country_code_err &&
                                                        <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter the country code</div>
    
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                {
                                    console.log(locationUserlvl,'locationUserlvl')
                                }
                                {
                                    !categoryDisabled &&
                                    <div className="mb-3">
                                        <label>Category :<span className="text-danger"> *</span></label>
                                        <Multiselect
                                            ref={multiRef}
                                            onRemove={(selectedList, selectedItem) => {
                                                onRemove(selectedItem)
                                            }
                                            }
                                            onSelect={(selectedList, selectedItem) => {
                                                onSelectValues(selectedList, selectedItem);
                                            }}
                                            options={locationUserlvl}
                                            displayValue="hirerachy_name"
                                            closeOnSelect={false}
                                        // disable={categoryDisabled}
                                        />
                                    </div>
                                }
    
                                {
                                    console.log(categoryDisabled, 'categoryDisabled')
                                }
                                {
                                    categoryDisabled &&
                                    <>
                                        <div className="mb-3">
                                            <label>New Category :<span className="text-danger"> *</span>&nbsp;<button style={{ padding: 'revert' }} onClick={() => { setcategoryDisabled(false) }} className='btn btn-danger'>cancel</button></label>
                                            <AvField
                                                name="category"
                                                type="text"
                                                required
                                                placeholder="Enter New Category Name"
                                                onChange={(e) => {validateCatName(e);setcatName(e.target.value)}}
                                            />
                                        {
                                            cat_name_exist &&
                                            <div className='text-danger' style={{fontSize :'smaller'}}>Entered category name already exist, Enter new one</div>
    
                                        }
                                        </div>
    
                                    </>
                                }
    
                                <div className="mb-3">
                                    <label>Username :<span className="text-danger"> *</span></label>
                                    <AvField
                                        name="username"
                                        type="text"
                                        required
                                        placeholder="Enter username"
                                        disabled={true}
                                        value={givenEmail}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Password :<span className="text-danger"> *</span></label>
                                    <Row>
                                        <Col className='pe-0' md={11} >
                                            <AvField
                                                name="password"
                                                autoComplete="new-password"
                                                type={show ? "password" : "text"}
                                                style={{ width: "100%" }}
                                                required
                                                placeholder="Enter password"
                                                validate={{
                                                    required: { value: true, errorMessage: 'Password is required' },
                                                    minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
                                                    pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                                }} />
                                        </Col>
                                        <Col className='ps-0' md={1}>
                                            <button
                                                onClick={() => { setShow(!show) }}
                                                className="btn btn-light"
                                                type="button"
                                                id="password-addon"
                                            >
                                                <i className={show ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i>
                                            </button>
                                        </Col>
    
                                    </Row>
    
    
                                </div>
    
                                <label>Role Permission :<span className="text-danger"> *</span></label>
                                <div className="mb-3">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
    
                                        <div className="form-check mx-2 form-radio-danger">
                                            <input
                                                name="Owner"
                                                type="radio"
                                                required
                                                id="Owner"
                                                onChange={(e) => { setrolePermisson(e.target.name);setroleErr(false) }}
                                                checked={rolePermisson === "Owner"}
    
                                            /> &nbsp;
                                            <label
                                                htmlFor='Owner'
                                            >
                                                Owner
                                            </label>
                                        </div>
                                        <div className="form-check mx-2 form-radio-danger">
                                            <input
                                                name="Read"
                                                type="radio"
                                                required
                                                id="Read"
                                                onChange={(e) => { setrolePermisson(e.target.name);setroleErr(false) }}
                                                checked={rolePermisson === "Read"}
                                            /> &nbsp;
                                            <label
                                                htmlFor='Read'
                                            >
                                                Read
                                            </label>
                                        </div>
                                        <div className="form-check mx-2 form-radio-danger">
                                            <input
                                                name={"Read&Write"}
                                                type="radio"
                                                required
                                                id="Read_Write"
                                                onChange={(e) => { setrolePermisson(e.target.name);setroleErr(false) }}
                                                checked={rolePermisson === "Read&Write"}
    
                                            /> &nbsp;
                                            <label
                                                htmlFor='Read_Write'
                                            >
                                                {"Read & Write"}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                   {
                                    roleErr &&
                                    <div className='text-danger' style={{fontSize :'smaller'}}>Please Select the User role</div>
                                   }             
    
    
                                <div className="mb-3">
                                    <Button style={{ width: "100%" }} type='submit' className='btn btn-danger'>{btn_load && <LoadingOutlined/>}{btn_load ? '...' : 'Submit'}  </Button>
    
                                </div>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                </div>);
        }
        else {
            return null
        }


    }
    else{
        
    const [show, setShow] = useState(true)
    const [dataLoaded, setdataLoaded] = useState(false)
    const [errorMessage, seterrorMessage] = useState(false)
    const [invalid_user, setinvalid_user] = useState(false)
    const [invalid_phone_num, setinvalid_phone_num] = useState(false)
    const [categoryDisabled, setcategoryDisabled] = useState(false)
    const [cat_name_exist, setcat_name_exist] = useState(false)
    const [givenEmail, setgivenEmail] = useState("")
    const [countryCode, setcountryCode] = useState(null)
    const [country_code_err, setcountry_code_err] = useState(false)
    const [givenName, setgivenName] = useState("")
    const [assigned_task_users, setassigned_task_users] = useState([])
    const [locationUserlvl, setLocationUserlvl] = useState(props.location_info.location_user_level)
    const [secret_key, setsecret_key] = useState('Pkdh347ey%3Tgs')
    const [catName, setcatName] = useState('')
    const [rolePermisson, setrolePermisson] = useState('')
    const [roleErr, setroleErr] = useState(false)


    const [db_info, setDbInfo] = useState({
        db_name: JSON.parse(sessionStorage.getItem("db_info")).db_name,
        encrypted_db_url: JSON.parse(sessionStorage.getItem("db_info")).encrypted_db_url
    })
    const [sessionUserInfo, setsessionUserInfo] = useState(JSON.parse(sessionStorage.getItem('authUser')))

    const multiRef = React.useRef()



    useEffect(() => {
        console.log("create users")
        setdataLoaded(true)
        var createNewExists = locationUserlvl.some(user => user.user_lvl_name === "+Create new");
        console.log(createNewExists,'createNewExists',locationUserlvl)
        if(!createNewExists){
            locationUserlvl.unshift({
                user_lvl_name: "+Create new"
            })
        }
       
        console.log(props.location_info, 'props.location_info')
    }, [])


    const emai_addrs = (e) => {
        var email = e.target.value
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setgivenEmail(email)
            seterrorMessage(false)
        }
        else {
            seterrorMessage(true)
            setgivenEmail(email)
        }

    }

    const onSelectValues = (selectedList, selectedItem) => {
        if (selectedItem.user_lvl_name === "+Create new") {
            console.log(selectedList, selectedItem, multiRef)
            setcategoryDisabled(true)

        }
        else {
            console.log(selectedList, selectedItem)
            setassigned_task_users(selectedList)
        }
    }

    const onRemove = (selectedItem) => {
        console.log(selectedItem, 'selectedItem', assigned_task_users)
    }



    const Validsubmit = async (events, values) => {
        console.log(events, values, props.location_info, assigned_task_users)
        var password = CryptoJS.AES.encrypt(values.password, secret_key).toString()

        values["password"] = password
        values["confirmpassword"] = password
        values["userPoolId"] = JSON.parse(sessionStorage.getItem("client_info"))[0]["userPoolId"]
        values["clientId"] = JSON.parse(sessionStorage.getItem("client_info"))[0]["clientId"]
        values["encrypted_db_url"] = db_info.encrypted_db_url
        values["db_name"] = db_info.db_name
        values["facilities"] = JSON.parse(sessionStorage.getItem("client_info"))[0]["facilities"]
        values["company_id"] = sessionUserInfo.client_info[0]._id
        values["created_by"] = sessionUserInfo.user_data._id
        values["short_name"] = JSON.parse(sessionStorage.getItem("client_info"))[0]["short_name"]
        values["user_id"] = sessionUserInfo.user_data._id
        if (countryCode == null) {
            setcountry_code_err(true)
        }
        else {
            values["countrycode"] = countryCode
            setcountry_code_err(false)
        }
        if (rolePermisson == "") {
            setroleErr(true)
        }
        else {
            setroleErr(false)
            values["role_permission"] = rolePermisson

        }
        if (invalid_user === false && invalid_phone_num === false && country_code_err === false && values["countrycode"] !== undefined && cat_name_exist == false && rolePermisson !=="" ) {
            try {
                urlSocket.post('cog/cruduser', values).then(async (res) => {
                    console.log(res, 'res')
                    if (res.data.response_code === 500) {
                        if (!categoryDisabled) {
                            assigned_task_users.map((ele, idx) => {
                                props.location_info.location_user_path.push({
                                    cat_type: ele.cat_type,
                                    designation: "",
                                    _id: res.data.admn_user[0]["_id"],
                                    // _id: "6578410163ea3828d949a8c7",
                                    name: values.firstname,
                                    title: props.location_info.name,
                                    user_lvl_name: ele.user_lvl_name,
                                    user_id: res.data.admn_user[0]["_id"],
                                    role_permission:rolePermisson
                                    // user_id: "6578410163ea3828d949a8c7"


                                })
                            })

                            props.location_info.location_unique_users.push({
                                cat_type: assigned_task_users.map((ele, pos) => ele.cat_type),
                                user_lvl_name: assigned_task_users.map((ele, pos) => ele.user_lvl_name),
                                designation: "",
                                _id: res.data.admn_user[0]["_id"],
                                // _id: "6578410163ea3828d949a8c7",

                                name: values.firstname,
                                title: props.location_info.name,
                                user_id: res.data.admn_user[0]["_id"],
                                role_permission:rolePermisson

                                // user_id: "6578410163ea3828d949a8c7",

                            })
                        }
                        else {
                            var value = {}
                            props.location_info.location_user_level = _.reject(props.location_info.location_user_level, { user_lvl_name: "+Create new" })
                            console.log(props.location_info, 'locationinfo')
                            const maxCatType = Math.max(...props.location_info.location_user_level.map((item) => parseInt(item.cat_type, 10)));
                            console.log(maxCatType, 'maxCatType')
                            value['cat_type'] = String(maxCatType + 1)
                            value['user_lvl_name'] = catName
                            props.location_info.location_user_level.push(value)
                            console.log(props.location_info.location_user_level)

                            props.location_info.location_user_path.push({
                                cat_type: value.cat_type,
                                designation: "",
                                _id: res.data.admn_user[0]["_id"],
                                // _id: "6578410163ea3828d949a8c7",
                                name: values.firstname,
                                title: props.location_info.name,
                                user_lvl_name: value.user_lvl_name,
                                user_id: res.data.admn_user[0]["_id"],
                                role_permission:rolePermisson

                                // user_id: "6578410163ea3828d949a8c7"
                            })

                            props.location_info.location_unique_users.push({
                                cat_type: [value.cat_type],
                                user_lvl_name: [value.user_lvl_name],
                                designation: "",
                                _id: res.data.admn_user[0]["_id"],
                                // _id: "6578410163ea3828d949a8c7",

                                name: values.firstname,
                                title: props.location_info.name,
                                user_id: res.data.admn_user[0]["_id"],
                                role_permission:rolePermisson

                                // user_id: "6578410163ea3828d949a8c7",

                            })

                        }
                        await updtPublishedLocation(props.location_info)
                    }

                })
            }
            catch (error) {

            }
        }

    }


    const updtPublishedLocation = (location_info) => {

        try {
            urlSocket.post('task/update-published-location', {
                locationInfo: location_info,
                encrypted_db_url: db_info.encrypted_db_url,
                mode:"1"
            }).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'User Created successfully',
                        text: 'User Created successfully',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Close',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            sessionStorage.setItem(JSON.stringify(location_info), 'endpointData')
                            window.location.reload()
                        }

                    })
                }
            })

        } catch (error) {

        }


    }




    const validate_email = (e, mode) => {
        var admn_info = {}
        if (mode == 1) {
            admn_info["email_id"] = e.target.value
        }
        if (mode === 2) {
            admn_info["phone_number"] = e.target.value
        }

        admn_info["encrypted_db_url"] = db_info.encrypted_db_url
        admn_info["db_name"] = db_info.db_name
        admn_info["user_id"] = sessionUserInfo.user_data._id

        try {
            urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
                if (mode == 1) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        setinvalid_user(true)
                    }
                    else {
                        setinvalid_user(false)
                    }

                }
                if (mode == 2) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        setinvalid_phone_num(true)
                    }
                    else {
                        setinvalid_phone_num(false)

                    }


                }
            })
        }
        catch (error) {
            console.log(error, 'error')
        }


    }



    const validateCatName = (event) => {

        var validate_category_name = _.filter(locationUserlvl, item => {
            const cleanedHierarchyName = item.user_lvl_name?.replace(/\s/g, '').toLowerCase();
            const cleanedEventValue = event.target.value?.replace(/\s/g, '').toLowerCase();
            return cleanedHierarchyName === cleanedEventValue;

        })
        if (validate_category_name.length > 0) {
            setcat_name_exist(true)
        }
        else {
            setcat_name_exist(false)
        }



    }



    if (dataLoaded) {
        return (
            <div>
                <Modal isOpen={props.modal} toggle={props.toggle}  >
                    <ModalHeader toggle={props.toggle} tag="h4">
                        Create User
                    </ModalHeader>
                    <ModalBody>
                        <AvForm className="form-horizontal" onValidSubmit={Validsubmit}>
                            <div className="mb-3">
                                <label>Full Name :<span className="text-danger"> *</span></label>
                                <AvField
                                    name="firstname"
                                    errorMessage="Please enter your name"
                                    className="form-control"
                                    placeholder="Enter First Name"
                                    type="text"
                                    onChange={(e) => { setgivenName(e.target.value) }}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label>Email Id :<span className="text-danger"> *</span></label>
                                <AvField
                                    name="email_id"
                                    errorMessage="Please enter your Email ID"
                                    className="form-control"
                                    placeholder="Enter User Email"
                                    onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("e.preventDefault()") }}
                                    type="email"
                                    onChange={(e) => {
                                        emai_addrs(e)
                                            ; validate_email(e, 1)
                                    }}
                                    required
                                />
                                {
                                    errorMessage &&
                                    <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>

                                }
                                {
                                    invalid_user &&
                                    <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Email ID already exist fo another user</div>

                                }
                            </div>
                            <div className="mb-3">
                                <Row>
                                    <Col className="col-12">
                                        <Row>
                                            <label>Phone Number:<span className="text-danger"> *</span></label>
                                            <Col md={3} className="pe-0">
                                                <select name="countrycode"
                                                    onChange={(e) => {
                                                        setcountryCode(e.target.value)
                                                    }}
                                                    className="form-select" required>
                                                    <option value="">Select</option>
                                                    {
                                                        props.client_info.countries.map((c, idx) => (
                                                            <option key={idx} value={c.code}>
                                                                {c.code}{""}&nbsp;{c.label}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </Col>
                                            <Col md={9} className="ps-0">
                                                <AvField
                                                    name="phone_number"
                                                    className="form-control"
                                                    placeholder="Enter Phone number"
                                                    errorMessage="Please enter your Phone Number"
                                                    type="number"
                                                    onChange={(e) => { validate_email(e, 2) }}
                                                    validate={{
                                                        required: { value: true },
                                                        minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                        maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                    }}
                                                />
                                                {
                                                    invalid_phone_num &&
                                                    <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Phone Number already exist fo another user</div>

                                                }
                                                {
                                                    country_code_err &&
                                                    <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter the country code</div>

                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            {
                                !categoryDisabled &&
                                <div className="mb-3">
                                    <label>Category :<span className="text-danger"> *</span></label>
                                    <Multiselect
                                        ref={multiRef}
                                        onRemove={(selectedList, selectedItem) => {
                                            onRemove(selectedItem)
                                        }
                                        }
                                        onSelect={(selectedList, selectedItem) => {
                                            onSelectValues(selectedList, selectedItem);
                                        }}
                                        options={locationUserlvl}
                                        displayValue="user_lvl_name"
                                        closeOnSelect={false}
                                    // disable={categoryDisabled}
                                    />
                                </div>
                            }

                            {
                                console.log(categoryDisabled, 'categoryDisabled')
                            }
                            {
                                categoryDisabled &&
                                <>
                                    <div className="mb-3">
                                        <label>New Category :<span className="text-danger"> *</span>&nbsp;<button style={{ padding: 'revert' }} onClick={() => { setcategoryDisabled(false) }} className='btn btn-danger'>cancel</button></label>
                                        <AvField
                                            name="category"
                                            type="text"
                                            required
                                            placeholder="Enter New Category Name"
                                            onChange={(e) => {validateCatName(e);setcatName(e.target.value)}}
                                        />
                                    {
                                        cat_name_exist &&
                                        <div className='text-danger' style={{fontSize :'smaller'}}>Entered category name already exist, Enter new one</div>

                                    }
                                    </div>

                                </>
                            }

                            <div className="mb-3">
                                <label>Username :<span className="text-danger"> *</span></label>
                                <AvField
                                    name="username"
                                    type="text"
                                    required
                                    placeholder="Enter username"
                                    disabled={true}
                                    value={givenEmail}
                                />
                            </div>
                            <div className="mb-3">
                                <label>Password :<span className="text-danger"> *</span></label>
                                <Row>
                                    <Col className='pe-0' md={11} >
                                        <AvField
                                            name="password"
                                            autoComplete="new-password"
                                            type={show ? "password" : "text"}
                                            style={{ width: "100%" }}
                                            required
                                            placeholder="Enter password"
                                            validate={{
                                                required: { value: true, errorMessage: 'Password is required' },
                                                minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
                                                pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                            }} />
                                    </Col>
                                    <Col className='ps-0' md={1}>
                                        <button
                                            onClick={() => { setShow(!show) }}
                                            className="btn btn-light"
                                            type="button"
                                            id="password-addon"
                                        >
                                            <i className={show ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i>
                                        </button>
                                    </Col>

                                </Row>


                            </div>

                            <label>Role Permission :<span className="text-danger"> *</span></label>
                            <div className="mb-3">
                                <div style={{ display: 'flex', flexDirection: 'row' }}>

                                    <div className="form-check mx-2 form-radio-danger">
                                        <input
                                            name="Owner"
                                            type="radio"
                                            required
                                            id="Owner"
                                            onChange={(e) => { setrolePermisson(e.target.name);setroleErr(false) }}
                                            checked={rolePermisson === "Owner"}

                                        /> &nbsp;
                                        <label
                                            htmlFor='Owner'
                                        >
                                            Owner
                                        </label>
                                    </div>
                                    <div className="form-check mx-2 form-radio-danger">
                                        <input
                                            name="Read"
                                            type="radio"
                                            required
                                            id="Read"
                                            onChange={(e) => { setrolePermisson(e.target.name);setroleErr(false) }}
                                            checked={rolePermisson === "Read"}
                                        /> &nbsp;
                                        <label
                                            htmlFor='Read'
                                        >
                                            Read
                                        </label>
                                    </div>
                                    <div className="form-check mx-2 form-radio-danger">
                                        <input
                                            name={"Read&Write"}
                                            type="radio"
                                            required
                                            id="Read_Write"
                                            onChange={(e) => { setrolePermisson(e.target.name);setroleErr(false) }}
                                            checked={rolePermisson === "Read&Write"}

                                        /> &nbsp;
                                        <label
                                            htmlFor='Read_Write'
                                        >
                                            {"Read & Write"}
                                        </label>
                                    </div>
                                </div>
                            </div>
                               {
                                roleErr &&
                                <div className='text-danger' style={{fontSize :'smaller'}}>Please Select the User role</div>
                               }             


                            <div className="mb-3">
                                <Button style={{ width: "100%" }} type='submit' className='btn btn-danger'>Submit</Button>

                            </div>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </div>);
    }
    else {
        return null
    }
}

}

CrudUser.propTypes = propTypes;
CrudUser.defaultProps = defaultProps;
// #endregion

export default CrudUser;