import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Alert, CardBody, Col, Container, Row, Card } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// import images
import logodark from "../../../assets/images/logo-dark.png"
import logolight from "../../../assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"
import { LoadingOutlined } from '@ant-design/icons';
// {this.state.loading_btn && <LoadingOutlined />}   {this.state.loading_btn ? "..." : "Windows login"}

// import images
import profile from "../../../assets/images/logo_blue.png";

import auditvista_logo from "../../../assets/images/auditvista_logo.png";
import Swal from "sweetalert2";
// import { BeatLoader } from "react-spinners";

// import Logo from "../../assets/images/logo-light-1.png";
import CryptoJS from 'crypto-js';
// var urlSocket = require('../../helpers/urlSocket');
// import urlSocket from "helpers/urlSocket";
import urlSocket from "../../../helpers/urlSocket";

export default class Login2 extends Component {


  constructor(props) {
    super(props);
    this.state = {
      login_status: false,
      loading: false,
      login_msg: '',
      invalid_credentials: false,
      err_msg: '',
      dataloaded: false,
      geturl_data: {},
      parsed_data:{},
      blur_loading: false,
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }


  //  Login Submit
  //    handleValidSubmit(event, values) {

  //     // this.props.loginUser(values, this.props.history);
  //     let deviceInfo = { ipAddress: "", macAddress: "" }
  //     try {
  //         urlSocket.post('/authentication/login', {
  //             username: values.username,
  //             password: values.password,
  //             mac_address: "",
  //             device_info: [deviceInfo],
  //             //   

  //         })
  //             .then((response) => {

  //                 if (response.data.response_code == 500) {
  //                         this.setState({ loading: true, login_status: true, login_msg: response.data.message })
  //                         const loginSession = response.data
  //                         sessionStorage.setItem("authUser", JSON.stringify(loginSession));
  //                         if(response.data.user_data.usertype == "99")
  //                         {
  //                           this.props.history.push("/admindashboard");
  //                           //!response.data.user_data.ftis ? this.props.history.push("/ftisusrpfl"):this.props.history.push("/admindashboard");
  //                         }
  //                         else
  //                         {
  //                             this.props.history.push("/dashboard");
  //                         }
  //                 }
  //                 else {
  //                     this.setState({ login_status: true, login_msg: response.data.message })

  //                 }
  //             })
  //     }
  //     catch (error) {

  //     }

  //     this.remove_sessions();

  // }

  remove_sessions() {
    sessionStorage.removeItem("tm_list");
    sessionStorage.removeItem("entities_list_page");
    sessionStorage.removeItem("audit_parameters");
    sessionStorage.removeItem("userNodeInfo");
  }
  componentDidMount() {
    // this.props.apiError("");
    // window.addEventListener('DOMContentLoaded', this.getDataFromURL);
    this.getDataFromURL();
    console.log("LOgin")

    var path = window.location.hostname
    try {
      urlSocket.post('cog/find-short-name').then((response) => {
        console.log(response,'response')
        if (response.data.response_code === 500) {
          this.setState({ client_data: response.data.data, dataloaded: true })
          sessionStorage.setItem('short_name', response.data.data.short_name)
          if(this.state.parsed_data.marketing === true ){
            this.setState({blur_loading: true},()=>{this.handleValidSubmit('e', this.state.parsed_data)})
            
          }else{
          }
        }
        else {
          sessionStorage.clear()
          this.props.history.push('/url-not-found')
        }
      })

    } catch (error) {

    }
  }

  getDataFromURL = () => {
    var urlParams = new URLSearchParams(window.location.search);
    var encryptedData = urlParams.get('data');
    var decoded=  decodeURIComponent(encryptedData);
    var parsed_data = JSON.parse(decoded)
    try {
      if(Object.keys(parsed_data).length !==0){
        // var decryptedData = this.decryptData(encryptedData)
          this.setState({parsed_data :parsed_data })
      }
    } catch (error) {
      
    }
  
  };
  
  decryptData = (encryptedData) => {
    var key = 'encryptionKey'; // Replace with your encryption key
  
    try {
      // Decrypt the data using CryptoJS
      var decryptedData = CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);
      return decryptedData;
    } catch (error) {
      return null;
    }
  }
  
  async encrytypion (data, secretKey) {
    var encrypted_userpoolId = CryptoJS.AES.encrypt(data, secretKey).toString()
    return encrypted_userpoolId

  }


 async handleValidSubmit(event, values) {
    // var short_name = values.username
    var short_name = sessionStorage.getItem('short_name')
    // values["username"] = short_name+'.'+values.username
    if(values.marketing === true){
      values["short_name"] = short_name
      // values.short_name = await this.encrytypion(values.short_name,"Pkdh347ey%3Tgs")
      // values.username = await this.encrytypion(values.username,"Pkdh347ey%3Tgs")
      // values.password = await this.encrytypion(values.password,"Pkdh347ey%3Tgs")
      values.db_name = await this.encrytypion(this.state.client_data.database_name,"Pkdh347ey%3Tgs")
      values.database_url = await this.encrytypion(this.state.client_data.database_url,"Pkdh347ey%3Tgs")

      // values["db_name"] = this.state.client_data.database_name
      // values["database_url"] = this.state.client_data.database_url
  

    // values.short_name == await this.encrytypion(values.short_name,"Pkdh347ey%3Tgs")
    // values.username == await this.encrytypion(values.username,"Pkdh347ey%3Tgs")
    // values.password == await this.encrytypion(values.password,"Pkdh347ey%3Tgs")
  }
  else{


    values["db_name"] = this.state.client_data.database_name
    values["database_url"] = this.state.client_data.database_url

    values["short_name"] = short_name
  }

    this.setState({ loading: true })
    try {
      urlSocket.post('cog/login-authenticate', values).then(async (res) => {
        console.log(res,'res');
        if (res.data.response_code === 500) {
          var loginSession = res.data
          var db_info = res.data.db_info
          var client_info = res.data.client_info
          const endDate = new Date(client_info[0].client_configure.end_date);
          const today = new Date();
          const endDateDateOnly = endDate.toISOString().split('T')[0];
          const todayDateOnly = today.toISOString().split('T')[0];
          const isTodayOrBefore = endDateDateOnly < todayDateOnly;
          // const endDate = new Date(client_info[0].client_configure.end_date)
          // const today = new Date();
          // const endDateDateOnly = endDate.toISOString().split('T')[0];
          // const todayDateOnly = today.toISOString().split('T')[0];
          // const isToday = endDateDateOnly === todayDateOnly;
          if (loginSession.role_status === true) {
            if (isTodayOrBefore || loginSession.client_info[0].status === false || loginSession.user_data.status === false) {
              Swal.fire({
                icon: 'warning',
                title: 'Expired!',
                text: 'Your company package has been Expired , For Extends Please Contact Admin !',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
              }).then((result) => {
                if(result.isConfirmed === true){
                  this.setState({ loading: false })

                }
                
              })
            }
            else if (isTodayOrBefore == false) {

              // if(client_info[0].client_configure.end_date === )
              // setauth["config_data"] = client_info[0]
              // setauth["user_data"] = loginSession
              setTimeout(() => {
                this.setState({ loading: false })
                sessionStorage.setItem("authUser", JSON.stringify(loginSession));
              sessionStorage.setItem('db_info', JSON.stringify(db_info))
              sessionStorage.setItem('client_info', JSON.stringify(client_info))
              this.props.history.push('/dashboard')
              }, 1000);
              
              // var create_login_history = await this.crud_login_history(loginSession)

            }
          }
          else {
            Swal.fire({
              icon: 'warning',
              title: 'Inactivate!',
              text: 'Your Role Status has been Inactivated , For Activation Please Contact Admin !',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then((result) => {
              // this.props.history.push('/login')
              if(result.isConfirmed == true){
              this.setState({ loading: false })
            }

            })
          }
        }
        // else if(loginSession.role_status === false)
        // else if (res.data.error.name == "NotAuthorizedException" || res.data.error.code == "NotAuthorizedException") {
        //   this.setState({ invalid_credentials: true, loading: false, activation_err: false })
        // }
        else if (res.data.error === "404db") {
          this.setState({ activation_err: true,loading: false })
        }
        else if (res.data.response_code === 504) {
          // this.state.parsed_data.marketing = false;
          if (Object.keys(res.data.error).length === 0) {
            this.setState({ invalid_credentials: true, loading: false, activation_err: false })

          }
          else {
            setTimeout(() => {
            this.setState({ loading: false, err_msg: res.data.message, activation_err: false, blur_loading: false })              
            }, 1000);
          }
        }
        else {
          this.setState({ loading: false })
          this.props.history.push('/login')

        }

      })
    } catch (error) {
      this.setState({ loading: false , err_msg: "Some thing has went wrong, please try again later." })
      
      
    }
  }
  crud_login_history=(data)=>{
   
    
    var api_data = {}
    api_data["browser_type"]=data.browser
    api_data["device"]=data.device
    api_data["firstname"]=data.user_data.firstname
    api_data["user_id"]=data.user_data._id
    api_data["db_name"]=data.db_info.db_name
    api_data["encrypted_db_url"]=data.db_info.encrypted_db_url



    try {
        urlSocket.post('cog/save-user-details',api_data).then((response)=>{
        })

    } catch (error) {
      
    }
 }

  render() {
    if (this.state.dataloaded) {
      return (
        <React.Fragment>
          
          <div className={this.state.blur_loading ? 'page-container blur' : 'page-container'}>
            <MetaTags>
              <title>AuditVista</title>
            </MetaTags>
            <Container fluid className="p-0">
              <Row className="g-0">
                <CarouselPage />

                <Col xl={3} style={{background: 'white'}}>
                  <div className="auth-full-page-content p-md-5 p-4">
                    <div className="w-100">
                      <div className="d-flex flex-column h-100">
                        <div className="mb-4 mb-md-5 d-flex justify-content-between align-items-center">
                          <div style={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link to="dashboard" className="d-block auth-logo">
                              <img
                                src={auditvista_logo}
                                alt=""
                                style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                              />
                            </Link>
                          </div>
                        </div>

                      
                        <div className="my-auto p-10">
                          <Row className="mb-4">
                            <Col md={12} style={{ textAlign: '-webkit-center' }}>
                              {
                                this.state.client_data.client_logo_rzd === undefined || this.state.client_data.client_logo_rzd === null || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ?
                                  null
                                  :
                                  <div style={{ height: '60px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img
                                      alt="Header Avatar"
                                      className=""
                                      src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname}
                                      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                                    />
                                  </div>
                              }
                            </Col>
                          </Row>
                          <div className="text-center">
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p className="text-muted">Sign in to continue to AuditVista.</p>
                          </div>
                          <div className="mt-4 p-2">

                            <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                              {this.state.login_status ? <Alert color="danger">{this.state.login_msg}</Alert> : null}
                              {/* Check the Username and Password */}


                              <div className="form-group">
                                <AvField name="username" label="Email ID" className="form-control" placeholder="Enter Email ID" required />
                              </div>



                              <div className="mt-3 input-group">
                                <div className="flex-grow-1">
                                  <AvField name="password" label="Password" value="" onChange={() => { this.setState({ invalid_credentials: false }); }} type={this.state.show_pwd ? 'text' : 'password'} required placeholder="Enter Password" />
                                </div>
                                <div style={{ marginLeft: '', padding: '3px 0px 4px 2px' }}>
                                  <button style={{ marginLeft: '', padding: '8px' }} onClick={() => this.setState({ show_pwd: !this.state.show_pwd })} className="btn btn-light mt-4" type="button" id="password-addon" >
                                    <i className={this.state.show_pwd ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off'} ></i>
                                  </button>
                                </div>
                              </div>

                              {
                                this.state.err_msg !== '' &&
                                <div className="text-danger" style={{ fontSize: 'smaller' }}>{this.state.err_msg}</div>

                              }
                              {
                                this.state.invalid_credentials &&
                                <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}> Invalid Username or Password</div>

                              }
                              {
                                this.state.activation_err &&
                                <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Your account is not activated.</div>

                              }
                              {/* <div className="form-group mt-3">
                                <AvField name="password" label="Password" value="" onChange={() => { this.setState({ err_msg: '', invalid_credentials: false }) }} type="password" required placeholder="Enter Password" />
                              </div> */}
                              <div className="float-end mt-2">
                                <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
                              </div>


                              <div className="mt-3">
                                <button className="btn btn-danger btn-block waves-effect waves-light" type="submit" disabled={this.state.loading}>{this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Log In"} </button>
                              </div>
                            </AvForm>
                            {/* <div className="mt-5 text-center">
                            <p>Don't have an account ? <a href="register" className="fw-medium text-primary"> Signup now </a> </p>
                          </div> */}
                          </div>
                        </div>


                        <div className="mt-4 mt-md-5 text-center">
                          <p className="mb-0">
                            © {new Date().getFullYear()} AuditVista.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {this.state.blur_loading && (
            <>
            
            <div className="loader-overlay">
              <div className="loader">
                <LoadingOutlined />
              </div>
            </div>
           
            </>
          )}
          
        </React.Fragment>
      )
    }
    else {
      return null
    }
  }
}



















// import React, { Component } from "react"
// import MetaTags from 'react-meta-tags';
// import { Link } from "react-router-dom"
// import { Alert, Col, Container, Row } from "reactstrap"

// // availity-reactstrap-validation
// import { AvField, AvForm } from "availity-reactstrap-validation"

// // import images
// import logodark from "../../assets/images/logo-dark.png"
// import logolight from "../../assets/images/logo-light.png"
// import CarouselPage from "./CarouselPage"
// import { LoadingOutlined } from '@ant-design/icons';
// // {this.state.loading_btn && <LoadingOutlined />}   {this.state.loading_btn ? "..." : "Windows login"}

// // import images
// import profile from "../../assets/images/logo_blue.png";

// import auditvista_logo from "../../assets/images/auditvista_logo.png";
// import Swal from "sweetalert2";
// // import Logo from "../../assets/images/logo-light-1.png";
// import CryptoJS from 'crypto-js';
// var urlSocket = require('../../helpers/urlSocket');

// export default class Login2 extends Component {


//   constructor(props) {
//     super(props);
//     this.state = {
//       login_status: false,
//       loading: false,
//       login_msg: '',
//       invalid_credentials: false,
//       err_msg: '',
//       dataloaded: false,
//       geturl_data: {},
//       parsed_data:{}
//     }

//     // handleValidSubmit
//     this.handleValidSubmit = this.handleValidSubmit.bind(this);
//   }


//   //  Login Submit
//   //    handleValidSubmit(event, values) {

//   //     // this.props.loginUser(values, this.props.history);
//   //     let deviceInfo = { ipAddress: "", macAddress: "" }
//   //     try {
//   //         urlSocket.post('/authentication/login', {
//   //             username: values.username,
//   //             password: values.password,
//   //             mac_address: "",
//   //             device_info: [deviceInfo],
//   //             //   

//   //         })
//   //             .then((response) => {


//   //                 if (response.data.response_code == 500) {
//   //                         this.setState({ loading: true, login_status: true, login_msg: response.data.message })
//   //                         const loginSession = response.data
//   //                         sessionStorage.setItem("authUser", JSON.stringify(loginSession));
//   //                         if(response.data.user_data.usertype == "99")
//   //                         {
//   //                           this.props.history.push("/admindashboard");
//   //                           //!response.data.user_data.ftis ? this.props.history.push("/ftisusrpfl"):this.props.history.push("/admindashboard");
//   //                         }
//   //                         else
//   //                         {
//   //                             this.props.history.push("/dashboard");
//   //                         }
//   //                 }
//   //                 else {
//   //                     this.setState({ login_status: true, login_msg: response.data.message })

//   //                 }
//   //             })
//   //     }
//   //     catch (error) {

//   //     }

//   //     this.remove_sessions();

//   // }

//   remove_sessions() {
//     sessionStorage.removeItem("tm_list");
//     sessionStorage.removeItem("entities_list_page");
//     sessionStorage.removeItem("audit_parameters");
//     sessionStorage.removeItem("userNodeInfo");
//   }
//   componentDidMount() {
//     // this.props.apiError("");
//     // window.addEventListener('DOMContentLoaded', this.getDataFromURL);
//     this.getDataFromURL();


//     var path = window.location.hostname

//     try {
//       urlSocket.post('cog/find-short-name').then((response) => {
//         if (response.data.response_code === 500) {


//           this.setState({ client_data: response.data.data, dataloaded: true })
//           sessionStorage.setItem('short_name', response.data.data.short_name)
//           if(this.state.parsed_data.marketing === true ){
//             this.handleValidSubmit('e', this.state.parsed_data)
//           }else{

//           }
//         }
//         else {
//           sessionStorage.clear()
//           this.props.history.push('/url-not-found')
//         }
//       })

//     } catch (error) {

//     }
//   }

//   getDataFromURL = () => {
//     var urlParams = new URLSearchParams(window.location.search);
//     var encryptedData = urlParams.get('data');
//     var decoded=  decodeURIComponent(encryptedData);
//     var parsed_data = JSON.parse(decoded)

//     try {
//       if(Object.keys(parsed_data).length !==0){
//         // var decryptedData = this.decryptData(encryptedData)
//           this.setState({parsed_data :parsed_data })
//       }
//     } catch (error) {
      
//     }
  
//   };
  
//   decryptData = (encryptedData) => {
//     var key = 'encryptionKey'; // Replace with your encryption key
  
//     try {
//       // Decrypt the data using CryptoJS
//       var decryptedData = CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);
//       return decryptedData;
//     } catch (error) {

//       return null;
//     }
//   }
  
//   async encrytypion (data, secretKey) {
//     var encrypted_userpoolId = CryptoJS.AES.encrypt(data, secretKey).toString()
//     return encrypted_userpoolId

//   }


//  async handleValidSubmit(event, values) {
//     // var short_name = values.username
//     var short_name = sessionStorage.getItem('short_name')

//     // values["username"] = short_name+'.'+values.username

//     if(values.marketing === true){
//       values["short_name"] = short_name
//       // values.short_name = await this.encrytypion(values.short_name,"Pkdh347ey%3Tgs")
//       // values.username = await this.encrytypion(values.username,"Pkdh347ey%3Tgs")
//       // values.password = await this.encrytypion(values.password,"Pkdh347ey%3Tgs")
//       values.db_name = await this.encrytypion(this.state.client_data.database_name,"Pkdh347ey%3Tgs")
//       values.database_url = await this.encrytypion(this.state.client_data.database_url,"Pkdh347ey%3Tgs")

//       // values["db_name"] = this.state.client_data.database_name
//       // values["database_url"] = this.state.client_data.database_url
  


//     // values.short_name == await this.encrytypion(values.short_name,"Pkdh347ey%3Tgs")
//     // values.username == await this.encrytypion(values.username,"Pkdh347ey%3Tgs")
//     // values.password == await this.encrytypion(values.password,"Pkdh347ey%3Tgs")
//   }
//   else{


//     values["db_name"] = this.state.client_data.database_name
//     values["database_url"] = this.state.client_data.database_url

//     values["short_name"] = short_name
//   }

//     this.setState({ loading: true })
//     try {
//       urlSocket.post('cog/login-authenticate', values).then(async (res) => {

//         if (res.data.response_code === 500) {
//           this.setState({ loading: false })
//           var loginSession = res.data
//           var db_info = res.data.db_info
//           var client_info = res.data.client_info
//           const endDate = new Date(client_info[0].client_configure.end_date);
//           const today = new Date();
//           const endDateDateOnly = endDate.toISOString().split('T')[0];
//           const todayDateOnly = today.toISOString().split('T')[0];
//           const isTodayOrBefore = endDateDateOnly <= todayDateOnly;

//           // const endDate = new Date(client_info[0].client_configure.end_date)
//           // const today = new Date();
//           // const endDateDateOnly = endDate.toISOString().split('T')[0];
//           // const todayDateOnly = today.toISOString().split('T')[0];
//           // const isToday = endDateDateOnly === todayDateOnly;

//           if (loginSession.role_status === true) {
//             if (isTodayOrBefore || loginSession.client_info[0].status === false || loginSession.user_data.status === false) {
//               Swal.fire({
//                 icon: 'warning',
//                 title: 'Inactivated!',
//                 text: 'Your company package has been Inactivated , For Activation Please Contact Admin !',
//                 confirmButtonColor: '#3085d6',
//                 confirmButtonText: 'OK'
//               }).then((result) => {
//               })
//             }
//             else if (isTodayOrBefore == false) {

//               // if(client_info[0].client_configure.end_date === )
//               // setauth["config_data"] = client_info[0]
//               // setauth["user_data"] = loginSession
//               sessionStorage.setItem("authUser", JSON.stringify(loginSession));
//               sessionStorage.setItem('db_info', JSON.stringify(db_info))
//               sessionStorage.setItem('client_info', JSON.stringify(client_info))
//               this.props.history.push('/dashboard')
//               // var create_login_history = await this.crud_login_history(loginSession)

//             }
//           }
//           else {
//             Swal.fire({
//               icon: 'warning',
//               title: 'Inactivate!',
//               text: 'Your Role Status has been Inactivated , For Activation Please Contact Admin !',
//               confirmButtonColor: '#3085d6',
//               confirmButtonText: 'OK'
//             }).then((result) => {
//               this.props.history.push('/login')
//             })
//           }
//         }
//         // else if(loginSession.role_status === false)
//         // else if (res.data.error.name == "NotAuthorizedException" || res.data.error.code == "NotAuthorizedException") {
//         //   this.setState({ invalid_credentials: true, loading: false, activation_err: false })
//         // }
//         else if (res.data.error === "404db") {
//           this.setState({ activation_err: true,loading: false })
//         }
//         else if (res.data.response_code === 504) {
//           if (Object.keys(res.data.error).length === 0) {
//             this.setState({ invalid_credentials: true, loading: false, activation_err: false })

//           }
//           else {
//             this.setState({ loading: false, err_msg: res.data.message, activation_err: false } })
//           }
//         }
//         else {
//           this.setState({ loading: false })
//           this.props.history.push('/login')

//         }

//       })
//     } catch (error) {

//     }
//   }
//   crud_login_history=(data)=>{

//     var api_data = {}
//     api_data["browser_type"]=data.browser
//     api_data["device"]=data.device
//     api_data["firstname"]=data.user_data.firstname
//     api_data["user_id"]=data.user_data._id
//     api_data["db_name"]=data.db_info.db_name
//     api_data["encrypted_db_url"]=data.db_info.encrypted_db_url



//     try {
//         urlSocket.post('cog/save-user-details',api_data).then((response)=>{

//         })

//     } catch (error) {
      
//     }
//  }

//   render() {
//     if (this.state.dataloaded) {
//       return (
//         <React.Fragment>
//           <div>
//             <MetaTags>
//               <title>AuditVista</title>
//             </MetaTags>
//             <Container fluid className="p-0">
//               <Row className="g-0">
//                 <CarouselPage />

//                 <Col xl={3}>
//                   <div className="auth-full-page-content p-md-5 p-4">
//                     <div className="w-100">
//                       <div className="d-flex flex-column h-100">
//                         <div className="mb-4 mb-md-5 d-flex justify-content-between align-items-center">
//                           <div style={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                             <Link to="dashboard" className="d-block auth-logo">
//                               <img
//                                 src={auditvista_logo}
//                                 alt=""
//                                 style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                               />
//                             </Link>
//                           </div>
//                           {/* {this.state.client_data.client_logo_rzd === undefined || this.state.client_data.client_logo_rzd === null || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ? null :
//                             <div style={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                               <img
//                                 alt="Header Avatar"
//                                 className=""
//                                 src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname}
//                                 style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                               />
//                             </div>
//                           } */}
//                         </div>






//                         {/* <div className="mb-4 mb-md-5 d-flex justify-content-between align-items-center">
//                           <Link to="dashboard" className="d-block auth-logo">
//                             <img
//                               src={auditvista_logo}
//                               alt=""
//                               height="30"
//                               className="auth-logo-dark"
//                             />
//                           </Link>
//                           {
//                            this.state.client_data.client_logo_rzd === undefined ||this.state.client_data.client_logo_rzd === null  || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ?
//                            <>
//                            </>
//                             :
//                             <img src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname} alt="" height="30" />

//                           }

//                         </div> */}
//                         {/* <div> */}

//                         {/* </div> */}
//                         {/* <Row className="mt-4">
//                           <Col md={12} style={{ textAlign: '-webkit-center' }}>
//                             {this.state.client_data.client_logo_rzd === undefined || this.state.client_data.client_logo_rzd === null || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ? null :
//                               <div style={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                 <img
//                                   alt="Header Avatar"
//                                   className=""
//                                   src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname}
//                                   style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                                 />
//                               </div>
//                             }
//                           </Col>
//                         </Row> */}
                        
//                         {/* <Row className="mb-4">
//                             <Col md={12} style={{textAlign: '-webkit-center'}}>
//                               <div style={{ height: '50px', width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                 <Link to="dashboard" className="d-block auth-logo">
//                                   <img
//                                     src={auditvista_logo}
//                                     alt=""
//                                     style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                                   />
//                                 </Link>
//                               </div>
//                             </Col>
//                           </Row> */}


//                         <div className="my-auto">
//                           <Row className="mb-4">
//                             <Col md={12} style={{ textAlign: '-webkit-center' }}>
//                               {
//                                 this.state.client_data.client_logo_rzd === undefined || this.state.client_data.client_logo_rzd === null || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ?
//                                   null
//                                   :
//                                   <div style={{ height: '60px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                     <img
//                                       alt="Header Avatar"
//                                       className=""
//                                       src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname}
//                                       style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                                     />
//                                   </div>
//                               }
//                             </Col>
//                           </Row>
//                           <div className="text-center">
//                             <h5 className="text-primary">Welcome Back !</h5>
//                             <p className="text-muted">Sign in to continue to AuditVista.</p>
//                           </div>

//                           <div className="mt-4 p-2">

//                             <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

//                               {this.state.login_status ? <Alert color="danger">{this.state.login_msg}</Alert> : null}
//                               {/* Check the Username and Password */}


//                               <div className="form-group">
//                                 <AvField name="username" label="Username" className="form-control" placeholder="Enter Username" required />
//                               </div>

                          

//                               <div className="mt-3 input-group">
//                                 <div className="flex-grow-1">
//                                   <AvField name="password" label="Password" value="" onChange={() => { this.setState({ invalid_credentials: false }); }} type={this.state.show_pwd ? 'text' : 'password'} required placeholder="Enter Password" />
//                                 </div>
//                                 <div style={{marginLeft: '', padding: '4px 0px 4px 2px'}}>
//                                   <button style={{marginLeft: '', padding: '8px'}} onClick={() => this.setState({ show_pwd: !this.state.show_pwd })} className="btn btn-light mt-4" type="button" id="password-addon" >
//                                     <i className={this.state.show_pwd ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off'} ></i>
//                                   </button>
//                                 </div>
//                               </div>


//                               {/* <div className="form-group mt-3">
//                                 <AvField name="password" label="Password" value="" onChange={() => { this.setState({ err_msg: '', invalid_credentials: false }) }} type="password" required placeholder="Enter Password" />
//                               </div> */}
//                               <div className="float-end mt-2">
//                                 <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
//                               </div>
//                               {
//                                 this.state.err_msg !== '' &&
//                                 <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>{this.state.err_msg}</div>

//                               }
//                               {
//                                 this.state.invalid_credentials &&
//                                 <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}> Invalid Username or Password</div>

//                               }
//                               {
//                                 this.state.activation_err &&
//                                 <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Your account is not activated.</div>

//                               }

//                               <div className="mt-3">
//                                 <button className="btn btn-danger btn-block waves-effect waves-light" type="submit" disabled={this.state.loading}>{this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Log In"} </button>
//                               </div>
//                             </AvForm>
//                             {/* <div className="mt-5 text-center">
//                             <p>Don't have an account ? <a href="register" className="fw-medium text-primary"> Signup now </a> </p>
//                           </div> */}
//                           </div>
//                         </div>


//                         <div className="mt-4 mt-md-5 text-center">
//                           <p className="mb-0">
//                             © {new Date().getFullYear()} AuditVista.
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </Container>
//           </div>
//         </React.Fragment>
//       )
//     }
//     else {
//       return null
//     }
//   }
// }

























// import React, { Component } from "react"
// import MetaTags from 'react-meta-tags';
// import { Link } from "react-router-dom"
// import { Alert, Col, Container, Row } from "reactstrap"

// // availity-reactstrap-validation
// import { AvField, AvForm } from "availity-reactstrap-validation"

// // import images
// import logodark from "../../assets/images/logo-dark.png"
// import logolight from "../../assets/images/logo-light.png"
// import CarouselPage from "./CarouselPage"
// import { LoadingOutlined } from '@ant-design/icons';
// // {this.state.loading_btn && <LoadingOutlined />}   {this.state.loading_btn ? "..." : "Windows login"}

// // import images
// import profile from "../../assets/images/logo_blue.png";

// import auditvista_logo from "../../assets/images/auditvista_logo.png";
// import Swal from "sweetalert2";
// // import Logo from "../../assets/images/logo-light-1.png";


// var urlSocket = require('../../helpers/urlSocket');

// export default class Login2 extends Component {


//   constructor(props) {
//     super(props);
//     this.state = {
//       login_status: false,
//       loading: false,
//       login_msg: '',
//       invalid_credentials: false,
//       err_msg: '',
//       dataloaded: false,
//       show_pwd : false
//     }

//     // handleValidSubmit
//     this.handleValidSubmit = this.handleValidSubmit.bind(this);
//   }


//   //  Login Submit
//   //    handleValidSubmit(event, values) {

//   //     // this.props.loginUser(values, this.props.history);
//   //     let deviceInfo = { ipAddress: "", macAddress: "" }
//   //     try {
//   //         urlSocket.post('/authentication/login', {
//   //             username: values.username,
//   //             password: values.password,
//   //             mac_address: "",
//   //             device_info: [deviceInfo],
//   //             //   

//   //         })
//   //             .then((response) => {


//   //                 if (response.data.response_code == 500) {
//   //                         this.setState({ loading: true, login_status: true, login_msg: response.data.message })
//   //                         const loginSession = response.data
//   //                         sessionStorage.setItem("authUser", JSON.stringify(loginSession));
//   //                         if(response.data.user_data.usertype == "99")
//   //                         {
//   //                           this.props.history.push("/admindashboard");
//   //                           //!response.data.user_data.ftis ? this.props.history.push("/ftisusrpfl"):this.props.history.push("/admindashboard");
//   //                         }
//   //                         else
//   //                         {
//   //                             this.props.history.push("/dashboard");
//   //                         }
//   //                 }
//   //                 else {
//   //                     this.setState({ login_status: true, login_msg: response.data.message })

//   //                 }
//   //             })
//   //     }
//   //     catch (error) {

//   //     }

//   //     this.remove_sessions();

//   // }

//   remove_sessions() {
//     sessionStorage.removeItem("tm_list");
//     sessionStorage.removeItem("entities_list_page");
//     sessionStorage.removeItem("audit_parameters");
//     sessionStorage.removeItem("userNodeInfo");
//   }
//   componentDidMount() {
//     // this.props.apiError("");
//     var path = window.location.hostname

//     try {
//       urlSocket.post('cog/find-short-name').then((response) => {
//         if (response.data.response_code === 500) {
//           this.setState({ client_data: response.data.data, dataloaded: true })
//           sessionStorage.setItem('short_name', response.data.data.short_name)
//         }
//         else {
//           sessionStorage.clear()
//           this.props.history.push('/url-not-found')
//         }
//       })

//     } catch (error) {

//     }
//   }

//   handleValidSubmit(event, values) {
//     // var short_name = values.username
//     var short_name = sessionStorage.getItem('short_name')

//     // values["username"] = short_name+'.'+values.username
//    
//     values["db_name"] = this.state.client_data.database_name
//     values["database_url"] = this.state.client_data.database_url

//     values["short_name"] = short_name

//     this.setState({ loading: true })
//     try {
//       urlSocket.post('cog/login-authenticate', values).then(async (res) => {
//         if (res.data.response_code === 500) {
//           this.setState({ loading: false })
//           var loginSession = res.data
//           var db_info = res.data.db_info
//           var client_info = res.data.client_info
//           const endDate = new Date(client_info[0].client_configure.end_date);
//           const today = new Date();
//           const endDateDateOnly = endDate.toISOString().split('T')[0];
//           const todayDateOnly = today.toISOString().split('T')[0];
//           const isTodayOrBefore = endDateDateOnly <= todayDateOnly;

//           // const endDate = new Date(client_info[0].client_configure.end_date)
//           // const today = new Date();
//           // const endDateDateOnly = endDate.toISOString().split('T')[0];
//           // const todayDateOnly = today.toISOString().split('T')[0];
//           // const isToday = endDateDateOnly === todayDateOnly;
//           if (loginSession.role_status === true) {
//             if (isTodayOrBefore || loginSession.client_info[0].status === false || loginSession.user_data.status === false) {
//               Swal.fire({
//                 icon: 'warning',
//                 title: 'Inactivated!',
//                 text: 'Your company package has been Inactivated , For Activation Please Contact Admin !',
//                 confirmButtonColor: '#3085d6',
//                 confirmButtonText: 'OK'
//               }).then((result) => {
//               })
//             }
//             else if (isTodayOrBefore == false) {

//               // if(client_info[0].client_configure.end_date === )
//               // setauth["config_data"] = client_info[0]
//               // setauth["user_data"] = loginSession
//               sessionStorage.setItem("authUser", JSON.stringify(loginSession));
//               sessionStorage.setItem('db_info', JSON.stringify(db_info))
//               sessionStorage.setItem('client_info', JSON.stringify(client_info))
//               this.props.history.push('/dashboard')
//               // var create_login_history = await this.crud_login_history(loginSession)

//             }
//           }
//           else {
//             Swal.fire({
//               icon: 'warning',
//               title: 'Inactivate!',
//               text: 'Your Role Status has been Inactivated , For Activation Please Contact Admin !',
//               confirmButtonColor: '#3085d6',
//               confirmButtonText: 'OK'
//             }).then((result) => {
//               this.props.history.push('/login')
//             })
//           }
//         }
//         else if (res.data.response_code === 504) {
//           if (Object.keys(res.data.error).length === 0) {
//             this.setState({ invalid_credentials: true, loading: false, activation_err: false })

//           }
//           else {
//             this.setState({ loading: false, err_msg: res.data.message, activation_err: false })
//           }
//         }
//         // else if(loginSession.role_status === false)
//         else if (res.data.error.name == "NotAuthorizedException" || res.data.error.code == "NotAuthorizedException") {
//           this.setState({ invalid_credentials: true, loading: false, activation_err: false })
//         }
//         else if (res.data.error === "404db") {
//           this.setState({ activation_err: true,loading: false })
//         }
      
//         else {
//           this.setState({ loading: false })
//           this.props.history.push('/login')

//         }

//       })
//     } catch (error) {

//     }
//   }
//   crud_login_history=(data)=>{
//     var api_data = {}
//     api_data["browser_type"]=data.browser
//     api_data["device"]=data.device
//     api_data["firstname"]=data.user_data.firstname
//     api_data["user_id"]=data.user_data._id
//     api_data["db_name"]=data.db_info.db_name
//     api_data["encrypted_db_url"]=data.db_info.encrypted_db_url



//     try {
//         urlSocket.post('cog/save-user-details',api_data).then((response)=>{
//         })

//     } catch (error) {
      
//     }
//  }

//   render() {
//     if (this.state.dataloaded) {
//       return (
//         <React.Fragment>
//           <div>
//             <MetaTags>
//               <title>AuditVista</title>
//             </MetaTags>
//             <Container fluid className="p-0">
//               <Row className="g-0">
//                 <CarouselPage />

//                 <Col xl={3}>
//                   <div className="auth-full-page-content p-md-5 p-4">
//                     <div className="w-100">
//                       <div className="d-flex flex-column h-100">
//                         <div className="mb-4 mb-md-5 d-flex justify-content-between align-items-center">
//                           <div style={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                             <Link to="dashboard" className="d-block auth-logo">
//                               <img
//                                 src={auditvista_logo}
//                                 alt=""
//                                 style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                               />
//                             </Link>
//                           </div>
//                           {/* {this.state.client_data.client_logo_rzd === undefined || this.state.client_data.client_logo_rzd === null || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ? null :
//                             <div style={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                               <img
//                                 alt="Header Avatar"
//                                 className=""
//                                 src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname}
//                                 style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                               />
//                             </div>
//                           } */}
//                         </div>






//                         {/* <div className="mb-4 mb-md-5 d-flex justify-content-between align-items-center">
//                           <Link to="dashboard" className="d-block auth-logo">
//                             <img
//                               src={auditvista_logo}
//                               alt=""
//                               height="30"
//                               className="auth-logo-dark"
//                             />
//                           </Link>
//                           {
//                            this.state.client_data.client_logo_rzd === undefined ||this.state.client_data.client_logo_rzd === null  || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ?
//                            <>
//                            </>
//                             :
//                             <img src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname} alt="" height="30" />

//                           }

//                         </div> */}
//                         {/* <div> */}

//                         {/* </div> */}
//                         {/* <Row className="mt-4">
//                           <Col md={12} style={{ textAlign: '-webkit-center' }}>
//                             {this.state.client_data.client_logo_rzd === undefined || this.state.client_data.client_logo_rzd === null || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ? null :
//                               <div style={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                 <img
//                                   alt="Header Avatar"
//                                   className=""
//                                   src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname}
//                                   style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                                 />
//                               </div>
//                             }
//                           </Col>
//                         </Row> */}
                        
//                         {/* <Row className="mb-4">
//                             <Col md={12} style={{textAlign: '-webkit-center'}}>
//                               <div style={{ height: '50px', width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                 <Link to="dashboard" className="d-block auth-logo">
//                                   <img
//                                     src={auditvista_logo}
//                                     alt=""
//                                     style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                                   />
//                                 </Link>
//                               </div>
//                             </Col>
//                           </Row> */}

//                         <div className="my-auto">
//                           <Row className="mb-4">
//                             <Col md={12} style={{ textAlign: '-webkit-center' }}>
//                               {
//                                 this.state.client_data.client_logo_rzd === undefined || this.state.client_data.client_logo_rzd === null || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ?
//                                   <>
//                                   </>
//                                   :
//                                   <div style={{ height: '60px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                     <img
//                                       alt="Header Avatar"
//                                       className=""
//                                       src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname}
//                                       style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                                     />
//                                   </div>
//                               }
//                             </Col>
//                           </Row>
//                           <div className="text-center">
//                             <h5 className="text-primary">Welcome Back !</h5>
//                             <p className="text-muted">Sign in to continue to AuditVista.</p>
//                           </div>

//                           <div className="mt-4 p-2">

//                             <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

//                               {this.state.login_status ? <Alert color="danger">{this.state.login_msg}</Alert> : null}
//                               {/* Check the Username and Password */}


//                               <div className="form-group">
//                                 <AvField name="username" label="Username" className="form-control" placeholder="Enter Username" required />
//                               </div>
//                               <div className="form-group mt-3">
//                                 <AvField name="password" label="Password" value="" onChange={() => { this.setState({ err_msg: '', invalid_credentials: false }) }} type={this.state.show_pwd ?"text":"password"} required placeholder="Enter Password" />
//                                 <button onClick={() => this.setState({show_pwd : !this.state.show_pwd})} className="btn btn-light " type="button" id="password-addon">
//                                 <i className={this.state.show_pwd ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//                               </div> 
                               
//                               {/* <div className="form-group mt-3">
//                                 <AvField name="password" label="Password" value="" onChange={() => { this.setState({ err_msg: '', invalid_credentials: false }) }} type={this.state.show_pwd ?"text":"password"} required placeholder="Enter Password" />
//                                 <button onClick={() => this.setState({show_pwd : !this.state.show_pwd})} className="btn btn-light " type="button" id="password-addon">
//                                 <i className={this.state.show_pwd ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//                               </div> */}
//                               <div className="float-end mt-2">
//                                 <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
//                               </div>
//                               {
//                                 this.state.err_msg !== '' &&
//                                 <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>{this.state.err_msg}</div>

//                               }
//                               {
//                                 this.state.invalid_credentials &&
//                                 <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}> Invalid Username or Password</div>

//                               }
//                               {
//                                 this.state.activation_err &&
//                                 <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Your account is not activated.</div>

//                               }

//                               <div className="mt-3">
//                                 <button className="btn btn-danger btn-block waves-effect waves-light" type="submit" disabled={this.state.loading}>{this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Log In"} </button>
//                               </div>
//                             </AvForm>
//                             {/* <div className="mt-5 text-center">
//                             <p>Don't have an account ? <a href="register" className="fw-medium text-primary"> Signup now </a> </p>
//                           </div> */}
//                           </div>
//                         </div>


//                         <div className="mt-4 mt-md-5 text-center">
//                           <p className="mb-0">
//                             © {new Date().getFullYear()} AuditVista.
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </Container>
//           </div>
//         </React.Fragment>
//       )
//     }
//     else {
//       return null
//     }
//   }
// }


















//b4-26-06-23
// import React, { Component } from "react"
// import MetaTags from 'react-meta-tags';
// import { Link } from "react-router-dom"
// import { Alert, Col, Container, Row } from "reactstrap"

// // availity-reactstrap-validation
// import { AvField, AvForm } from "availity-reactstrap-validation"

// // import images
// import logodark from "../../assets/images/logo-dark.png"
// import logolight from "../../assets/images/logo-light.png"
// import CarouselPage from "./CarouselPage"
// import { LoadingOutlined } from '@ant-design/icons';
// // {this.state.loading_btn && <LoadingOutlined />}   {this.state.loading_btn ? "..." : "Windows login"}

// // import images
// import profile from "../../assets/images/logo_blue.png";

// import auditvista_logo from "../../assets/images/auditvista_logo.png";
// import Swal from "sweetalert2";
// // import Logo from "../../assets/images/logo-light-1.png";


// var urlSocket = require('../../helpers/urlSocket');

// export default class Login2 extends Component {


//   constructor(props) {
//     super(props);
//     this.state = {
//       login_status: false,
//       loading: false,
//       login_msg: '',
//       invalid_credentials: false,
//       err_msg: '',
//       dataloaded: false
//     }

//     // handleValidSubmit
//     this.handleValidSubmit = this.handleValidSubmit.bind(this);
//   }


//   //  Login Submit
//   //    handleValidSubmit(event, values) {

//   //     // this.props.loginUser(values, this.props.history);
//   //     let deviceInfo = { ipAddress: "", macAddress: "" }
//   //     try {
//   //         urlSocket.post('/authentication/login', {
//   //             username: values.username,
//   //             password: values.password,
//   //             mac_address: "",
//   //             device_info: [deviceInfo],
//   //             //   

//   //         })
//   //             .then((response) => {

//   //                 if (response.data.response_code == 500) {
//   //                         this.setState({ loading: true, login_status: true, login_msg: response.data.message })
//   //                         const loginSession = response.data
//   //                         sessionStorage.setItem("authUser", JSON.stringify(loginSession));
//   //                         if(response.data.user_data.usertype == "99")
//   //                         {
//   //                           this.props.history.push("/admindashboard");
//   //                           //!response.data.user_data.ftis ? this.props.history.push("/ftisusrpfl"):this.props.history.push("/admindashboard");
//   //                         }
//   //                         else
//   //                         {
//   //                             this.props.history.push("/dashboard");
//   //                         }
//   //                 }
//   //                 else {
//   //                     this.setState({ login_status: true, login_msg: response.data.message })

//   //                 }
//   //             })
//   //     }
//   //     catch (error) {
//   //     }

//   //     this.remove_sessions();

//   // }

//   remove_sessions() {
//     sessionStorage.removeItem("tm_list");
//     sessionStorage.removeItem("entities_list_page");
//     sessionStorage.removeItem("audit_parameters");
//     sessionStorage.removeItem("userNodeInfo");
//   }
//   componentDidMount() {
//     // this.props.apiError("");
//     var path = window.location.hostname
//     try {
//       urlSocket.post('cog/find-short-name').then((response) => {
//         if (response.data.response_code === 500) {
//           this.setState({ client_data: response.data.data, dataloaded: true })
//           sessionStorage.setItem('short_name', response.data.data.short_name)
//         }
//         else {
//           sessionStorage.clear()
//           this.props.history.push('/url-not-found')
//         }
//       })

//     } catch (error) {

//     }
//   }

//   handleValidSubmit(event, values) {
//     // var short_name = values.username
//     var short_name = sessionStorage.getItem('short_name')
//     // values["username"] = short_name+'.'+values.username
//     values["db_name"] = this.state.client_data.database_name
//     values["database_url"] = this.state.client_data.database_url

//     values["short_name"] = short_name

//     this.setState({ loading: true })
//     try {
//       urlSocket.post('cog/login-authenticate', values).then(async (res) => {
//         if (res.data.response_code === 500) {
//           this.setState({ loading: false })
//           var loginSession = res.data
//           var db_info = res.data.db_info
//           var client_info = res.data.client_info
//           const endDate = new Date(client_info[0].client_configure.end_date);
//           const today = new Date();
//           const endDateDateOnly = endDate.toISOString().split('T')[0];
//           const todayDateOnly = today.toISOString().split('T')[0];
//           const isTodayOrBefore = endDateDateOnly <= todayDateOnly;
//           // const endDate = new Date(client_info[0].client_configure.end_date)
//           // const today = new Date();
//           // const endDateDateOnly = endDate.toISOString().split('T')[0];
//           // const todayDateOnly = today.toISOString().split('T')[0];
//           // const isToday = endDateDateOnly === todayDateOnly;
//           if (loginSession.role_status === true) {
//             if (isTodayOrBefore || loginSession.client_info[0].status === false || loginSession.user_data.status === false) {
//               Swal.fire({
//                 icon: 'warning',
//                 title: 'Inactivated!',
//                 text: 'Your company package has been Inactivated , For Activation Please Contact Admin !',
//                 confirmButtonColor: '#3085d6',
//                 confirmButtonText: 'OK'
//               }).then((result) => {
//               })
//             }
//             else if (isTodayOrBefore == false) {

//               // if(client_info[0].client_configure.end_date === )
//               // setauth["config_data"] = client_info[0]
//               // setauth["user_data"] = loginSession
//               sessionStorage.setItem("authUser", JSON.stringify(loginSession));
//               sessionStorage.setItem('db_info', JSON.stringify(db_info))
//               sessionStorage.setItem('client_info', JSON.stringify(client_info))
//               this.props.history.push('/dashboard')
//               // var create_login_history = await this.crud_login_history(loginSession)

//             }
//           }
//           else {
//             Swal.fire({
//               icon: 'warning',
//               title: 'Inactivate!',
//               text: 'Your Role Status has been Inactivated , For Activation Please Contact Admin !',
//               confirmButtonColor: '#3085d6',
//               confirmButtonText: 'OK'
//             }).then((result) => {
//               this.props.history.push('/login')
//             })
//           }
//         }
//         // else if(loginSession.role_status === false)
//         else if (res.data.error.name == "NotAuthorizedException" || res.data.error.code == "NotAuthorizedException") {
//           this.setState({ invalid_credentials: true, loading: false, activation_err: false })
//         }
//         else if (res.data.error === "404db") {
//           this.setState({ activation_err: true,loading: false })
//         }
//         else if (res.data.response_code === 504) {
//           if (Object.keys(res.data.error).length === 0) {
//             this.setState({ invalid_credentials: true, loading: false, activation_err: false })

//           }
//           else {
//             this.setState({ loading: false, err_msg: res.data.error.name, activation_err: false })
//           }
//         }
//         else {
//           this.setState({ loading: false })
//           this.props.history.push('/login')

//         }

//       })
//     } catch (error) {
//     }
//   }
//   crud_login_history=(data)=>{
//     var api_data = {}
//     api_data["browser_type"]=data.browser
//     api_data["device"]=data.device
//     api_data["firstname"]=data.user_data.firstname
//     api_data["user_id"]=data.user_data._id
//     api_data["db_name"]=data.db_info.db_name
//     api_data["encrypted_db_url"]=data.db_info.encrypted_db_url



//     try {
//         urlSocket.post('cog/save-user-details',api_data).then((response)=>{
//         })

//     } catch (error) {
      
//     }
//  }

//   render() {
//     if (this.state.dataloaded) {
//       return (
//         <React.Fragment>
//           <div>
//             <MetaTags>
//               <title>AuditVista</title>
//             </MetaTags>
//             <Container fluid className="p-0">
//               <Row className="g-0">
//                 <CarouselPage />

//                 <Col xl={3}>
//                   <div className="auth-full-page-content p-md-5 p-4">
//                     <div className="w-100">
//                       <div className="d-flex flex-column h-100">
//                       <div className="mb-4 mb-md-5 d-flex justify-content-between align-items-center">
//                           <div style={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                             <Link to="dashboard" className="d-block auth-logo">
//                               <img
//                                 src={auditvista_logo}
//                                 alt=""
//                                 style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                               />
//                             </Link>
//                           </div>
//                           {this.state.client_data.client_logo_rzd === undefined || this.state.client_data.client_logo_rzd === null || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ? null :
//                             <div style={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                               <img
//                                 alt="Header Avatar"
//                                 className=""
//                                 src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname}
//                                 style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                               />
//                             </div>
//                           }
//                         </div>






//                         {/* <div className="mb-4 mb-md-5 d-flex justify-content-between align-items-center">
//                           <Link to="dashboard" className="d-block auth-logo">
//                             <img
//                               src={auditvista_logo}
//                               alt=""
//                               height="30"
//                               className="auth-logo-dark"
//                             />
//                           </Link>
//                           {
//                            this.state.client_data.client_logo_rzd === undefined ||this.state.client_data.client_logo_rzd === null  || Object.keys(this.state.client_data.client_logo_rzd).length === 0 ?
//                            <>
//                            </>
//                             :
//                             <img src={this.state.client_data.base_url + this.state.client_data.client_logo_rzd.originalname} alt="" height="30" />

//                           }

//                         </div> */}
//                         {/* <div> */}

//                         {/* </div> */}

//                         <div className="my-auto">
//                           <div>
//                             <h5 className="text-primary">Welcome Back !</h5>
//                             <p className="text-muted">Sign in to continue to AuditVista.</p>
//                           </div>

//                           <div className="mt-4 p-2">

//                             <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

//                               {this.state.login_status ? <Alert color="danger">{this.state.login_msg}</Alert> : null}
//                               {/* Check the Username and Password */}


//                               <div className="form-group">
//                                 <AvField name="username" label="Username" className="form-control" placeholder="Enter Username" required />
//                               </div>
//                               <div className="form-group mt-3">
//                                 <AvField name="password" label="Password" value="" onChange={() => { this.setState({ err_msg: '', invalid_credentials: false }) }} type="password" required placeholder="Enter Password" />
//                               </div>
//                               <div className="float-end mt-2">
//                                 <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
//                               </div>
//                               {
//                                 this.state.err_msg !== '' &&
//                                 <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>{this.state.err_msg}</div>

//                               }
//                               {
//                                 this.state.invalid_credentials &&
//                                 <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}> Invalid Username or Password</div>

//                               }
//                               {
//                                 this.state.activation_err &&
//                                 <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Your account is not activated.</div>

//                               }

//                               <div className="mt-3">
//                                 <button className="btn btn-danger btn-block waves-effect waves-light" type="submit" disabled={this.state.loading}>{this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Log In"} </button>
//                               </div>
//                             </AvForm>
//                             {/* <div className="mt-5 text-center">
//                             <p>Don't have an account ? <a href="register" className="fw-medium text-primary"> Signup now </a> </p>
//                           </div> */}
//                           </div>
//                         </div>


//                         <div className="mt-4 mt-md-5 text-center">
//                           <p className="mb-0">
//                             © {new Date().getFullYear()} AuditVista.
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </Container>
//           </div>
//         </React.Fragment>
//       )
//     }
//     else {
//       return null
//     }
//   }
// }
