import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    Media,
    Row,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Table
} from "reactstrap"
import moment from 'moment'

export default class PreviewCAPA extends Component {


    render() {
        return (
            <Row >
                {/* <Table bordered responsive>
                    <thead>
                        <tr>
                            <th className="text-primary">Action Plan</th>
                            <th className="text-primary">Observation</th>
                            <th className="text-primary">Root Cause</th>
                            <th className="text-primary">Action</th>
                            <th className="text-primary">Target Date</th>
                            <th className="text-primary">Assign to Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.actionplans.map((item, i) => (
                            <tr key={i}>
                                <td>Action plan {i + 1}</td>
                                <td>{item.observation}</td>
                                <td>{item.root_cause}</td>
                                <td>{item.actionplan}</td>
                                <td>{item.target_date}</td>
                                <td>{item.to_email}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table> */}








                {
                    this.props.actionplans.map((item, i) => {
                        return (
                            <Row key={i}>
                                <div className="p-2 m-2" style={{ borderTop: "1px solid #e9e9e9" }}>
                                    <Col className="col-auto">
                                        <label className="font-size-13">Action plan {String(i + 1)}</label>
                                        <Row className='mb-2'>
                                            <Col>
                                                <label className="text-primary font-size-12">Observation</label>
                                                <div>{item.observation}</div>
                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col>
                                                <label className="text-primary font-size-12">Root Cause</label>
                                                <div>{item.root_cause}</div>
                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col>
                                                <label className="text-primary font-size-12">Action </label>
                                                <div>{item.actionplan}</div>
                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col>
                                                <label className="text-primary font-size-12">Target date</label>
                                                <div>{moment(item.target_date).format("DD-MMM-YYYY")}</div>
                                            </Col>
                                        </Row>

                                        <Row className='mb-2'>
                                            <Col>
                                                <label className="text-primary font-size-12">Assign to email</label>
                                                <div className="d-flex gap-1"> {item.responsible_person?.map((item2, index) => (
                                                <React.Fragment key={index}>
                                                    <label>{item2.name}</label>
                                                    {index < item2.responsible_person?.length - 1 ? ',' : '.'}
                                                </React.Fragment>
                                            ))}</div>
                                            </Col>
                                        </Row>
                                                {/* <div className="mb-2">
                                                    <span className="text-primary font-size-12">Phone number</span><br />
                                                    <span>{item.to_phone}</span>
                                                </div> */}
                                           

                                    </Col>
                                </div>
                            </Row>
                        )
                    })
                }






                {/* {
                    this.props.actionplans.map((item, i) => {
                        return (
                            <Row >
                                <div className="p-2 m-2" style={{borderTop:"1px solid #e9e9e9"}}>
                                    <Col className="col-auto">
                                        <label>Action plan {String(i + 1)}</label>
                                        <Row>
                                            <Col>
                                                <label className="text-primary font-size-12">Observation</label>
                                                <div>{item.observation}</div>
                                            </Col>
                                            <Col>
                                                <label className="text-primary font-size-12">Root Cause</label>
                                                <div>{item.root_cause}</div>
                                            </Col>
                                            <Col>
                                                <label className="text-primary font-size-12">Action </label>
                                                <div>{item.actionplan}</div>
                                            </Col>
                                            <Col>
                                                <div className="mb-2">
                                                    <span className="text-primary font-size-12">Target date</span><br />
                                                    <span>{moment(item.target_date).format("DD/MM/YYYY")}</span>
                                                </div>
                                                <div className="mb-2">
                                                    <span className="text-primary font-size-12">Assign to email</span><br />
                                                    <span>{item.to_email}</span>
                                                </div>
                                                <div className="mb-2">
                                                    <span className="text-primary font-size-12">Phone number</span><br />
                                                    <span>{item.to_phone}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        
                                    </Col>
                                </div>
                            </Row>
                        )
                                        })
                } */}
            </Row>

        )
    }


}
