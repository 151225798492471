import React, { useState } from 'react';
import { Col ,Button,Row} from 'reactstrap';

//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { setIncidentTypeList,setRaisedIncidentList,handleGlobalIncidentFilter } from '../../../../store/incidentReport/incdreportslice';

const JobListGlobalFilter = (props) => {
    const dispatch = useDispatch()
    const [authUser,setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [selectDate, setSelectDate] = useState();
    const [selectedStatus,setSelectedStatus] = useState("All")
    const dateChange = (date) => {
        setSelectDate(date)
    };
    // const [filterOptions,setFilterOptions]=useState([
    //     {id:"" , name:"All"},
    //     {id : "null" , name :"Not Allocated"},
    //     {id : "0" , name :"Not Started"},
    //     {id : "1" , name :"In Progress"},
    //     {id : "2" , name :"Completed"},
    //     {id : "3" , name :"Closed"},
    //     {id : "4" , name :"Rejected"},
    // ])

    const [filterOptions,setFilterOptions]=useState([
        {id:"" , name:"All" , color :"btn btn-sm btn-dark"},
        {id : "null" , name :"Not Allocated" , color :"btn btn-sm btn-secondary"},
        {id : "0" , name :"Not Started" , color :"btn btn-sm btn-secondary"},
        {id : "1" , name :"In Progress" ,color :"btn btn-sm btn-warning"},
        {id : "2" , name :"Completed" , color :"btn btn-sm btn-primary"},
        {id : "3" , name :"Closed" , color :"btn btn-sm btn-success"},
        {id : "4" , name :"Rejected" , color :"btn btn-sm btn-danger"},
    ])

    const handleSelectChange=async(value)=>{
        var page_query= {
            "incd_submitted_by.user_id": authUser.user_data._id
        }
        var data = await dispatch(handleGlobalIncidentFilter(value,page_query))
        console.log(data,'data');
        dispatch(setRaisedIncidentList(data))
        
      }




    return (
        <React.Fragment>   

            <Col>

            {
                filterOptions.map((ele,idx)=>{
                    return(
                    <button onClick={()=>{handleSelectChange(ele.id);setSelectedStatus(ele.name)}} key={idx} className={ele.color + ' me-1'}>{ele.name}</button>
                    )
                })
            }






                {/* <select className="form-control select2 mb-3 mb-xxl-0" onChange={(e) => {
                    handleSelectChange(e.target.value)
                }}
                >
                    {
                        filterOptions.map((ele, idx) => {
                            return (
                                <option key={idx} value={ele.id}>{ele.name}</option>
                            )
                        })
                    }
                </select> */}
            </Col>
            <Col>
            <div className="mb-0 font-size-16">
                {
                selectedStatus + " count : "  +props.data.length
            }
            </div>
            
            </Col>

            {/* <Col xxl={2} lg={6}>
                <select className="form-control select2 mb-3 mb-xxl-0">
                    <option>Status</option>
                    <option value="Active">Active</option>
                    <option value="New">New</option>
                    <option value="Close">Close</option>
                </select>
            </Col>
            <Col xxl={2} lg={4}>
                <select className="form-control select2 mb-3 mb-xxl-0">
                    <option>Select Type</option>
                    <option value="1">Full Time</option>
                    <option value="2">Part Time</option>
                </select>
            </Col>
            <Col xxl={2} lg={4}>
                <div id="datepicker1">
                    <DatePicker
                        className="form-control mb-3 mb-xxl-0"
                        selected={selectDate}
                        onChange={dateChange}
                    />
                </div>
            </Col>
            <Col xxl={1} lg={4}>
                <div className='mb-3 mb-xxl-0'>
                    <button type="button" className="btn btn-soft-secondary w-100">
                        <i className="mdi mdi-filter-outline align-middle"></i> Filter</button>
                </div>
            </Col> */}
        </React.Fragment>
    )
};
export default JobListGlobalFilter;
