import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component
// import Test from "../pages/Test/Test";
import UrlNotFound from '../pages/Audit/Utility/UrlNotFound'

// Authentication related pages
import Login from "../pages/PublicAccess/Authentication/Login";
import Login2 from "../pages/PublicAccess/Authentication/Login2";
import Logout from "../pages/PublicAccess/Authentication/Logout";
// import Register from "../pages/Registration/Register";
import ForgetPwd from "../pages/PublicAccess/Authentication/ForgetPassword";
import UserProfile from "../components/CommonForBoth/UserProfile";
// import ThankyouReg from "../pages/Registration/ThankyouReg";
import ForgetPassword from "../pages/PublicAccess/Authentication/ForgotPassword";


// import Chat from "../pages/ActionPlan/FollowUpUserAudit/chat";
// import Chat from "../pages/Chat";
import Chat from '../pages/Singlechat/chat'

//Landing Page


// Dashboard
import Dashboard from "../pages/Audit/AdminDashboard/Dashboard";

// import AuditAnalytics from "../pages/Dashboard/audit_analytics";
// import AuditReport from "../pages/Dashboard/auditreport";
// import ParameterReport from "../pages/Dashboard/parameterreport";
// import DetailedAdtReport from "../pages/Dashboard/detailedadtreport";

//Pages
import PagesMaintenance from "../pages/Audit/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Audit/Utility/pages-comingsoon";
import Pages404 from "../pages/Audit/Utility/pages-404";
import Pages500 from "../pages/Audit/Utility/pages-500";

//Manage Master Template
import manageMasterTemplate from "../pages/Audit/ManageMasterTemplate/index";
import create_template from "../pages/Audit/ManageMasterTemplate/createTemplate";

// Manage Published Template
import managePublishedTemplate from "../pages/Audit/ManagePublishedTemplate/index";
import editPublishedTemplate from "../pages/Audit/ManagePublishedTemplate/editPublishedTemplate";
import publishconfig from "../pages/Audit/ManagePublishedTemplate/publish-config";
import PublishedReport from "../pages/Audit/ManagePublishedTemplate/publishedReport";
import HLevelPublishedReport from "../pages/Audit/ManagePublishedTemplate/hlevelPublishedReport";
import CheckpointReport from "../pages/Audit/ManagePublishedTemplate/checkpoint_report";
import AuditAnalyticalReport from "../pages/Audit/ManagePublishedTemplate/audit_analytical_report";
import Location from "../pages/Audit/ManagePublishedTemplate/Location";
import CheckpointCollapseReport from '../pages/Audit/ManagePublishedTemplate/CheckpointCollapseReport'

//Manage Hirerachy
import Hirerachy from "../pages/Audit/ManageHirerachy/index";
import CRUDHStructure from "../pages/Audit/ManageHirerachy/crudHStructure";
import MapUser from "../pages/Audit/ManageHirerachy/mapusers";
import mapUserManualLocation from "../pages/Audit/ManageEntities/Components/mapUserManualLocation";
import NewHstructure from "../pages/Audit/ManageHirerachy/NewHstructure";
// import MapEndpoints from "../pages/ManageHirerachy/mapendpoints";

//Manage Endpoint
import Mels from "../pages/Audit/ManageEntities/mels";
import AddEndpoint from "../pages/Audit/ManageEntities/addendpoint";
import EntitiesOverview from "../pages/Audit/ManageEntities/edtent";
// import NewLocation from '../pages/ManagePublishedTemplate/NewLocation'
// //Manage Users
import ManageUsers from "../pages/Audit/ManageUsers/musr";
import AddUsers from "../pages/Audit/ManageUsers/adduser";
import UsersOverview from "../pages/Audit/ManageUsers/edtusr";
// import FTISUserProfile from "../pages/Registration/FTISUserProfile"


//User Audits
import UserAudits from "../pages/Audit/UserAudit/index"
import Endpoints from "../pages/Audit/UserAudit/endpoints"
import Checkpoints from "../pages/Audit/UserAudit/checkpoints"
import ReviewCheckpoints from "../pages/Audit/UserAudit/review_checkpoints"
import ViewCheckpoints from "../pages/Audit/UserAudit/viewCheckpoint";
import PreviewAuditScore from '../pages/Audit/UserAudit/Components/preview_audit_score'
// // Manage Roles
import ManageRoles from '../pages/Audit/ManageRoles/ManageRoles'
import CreateRoles from "../pages/Audit/ManageRoles/CreateRoles";
import WebcamSketch from "../pages/Audit/Test";
import Preview_Layout from '../pages/ReportD3/NoRespGridLayOut'
import BarChartWithSlicer from "../pages/ReportD3/Components/ChartSlicer";


//report
// import report from "../pages/report/report";

// settings
// import settings from "../pages/settings/settings";

//Reset
// import reset from "../pages/reset/reset";
import ForgetPassword2 from "../pages/PublicAccess/Authentication/ForgotPassword";


// Reports 

// import ManageReport from '../pages/Report/index'
// import Layout from '../pages/Report/Layout'
import Layout from '../pages/ReportD3/Layout'
import LayoutInfo from "../pages/ReportD3/LayoutInfo";

// import PageTree from "../pages/Report/PageTree";
import PageTree from "../pages/ReportD3/PageTree";
import ImageSketch from '../pages/Audit/sketch'

//  import ManageReport from '../pages/Report/ManageReport'
//  import Layout from '../pages/Report/ManageReports_page'



import FollowUpUserAudit from '../pages/AplnFollowUp'
import auditLocations from "../pages/AplnFollowUp/locations"
import auditActionPlans from "../pages/AplnFollowUp/actionplans";



//Report rework
import ReportTemplate from "../pages/ReportD3";
import ReportUser from "../pages/ReportD3/ReportUser";
import HierarchyReportUser from "../pages/ReportD3/HierarchyReportUser";
// import ViewMyReport from "../pages/Report/ViewMyReport";
import ViewMyReport from "../pages/ReportD3/ViewMyReport";
// import MyReport from "../pages/Report/MyReport";
import MyReport from '../pages/ReportD3/MyReport'
// import PublishType from "../pages/Report/PublishType";
import PublishType from '../pages/ReportD3/PublishType'
import { components } from "react-select";
import { path } from "d3";


//incident report module 
import IncidentType from '../pages/IncidentReport/IncidentType/index'
import ManageEscaltion from "../pages/IncidentReport/ManageEscalation";
import SeverityType from '../pages/IncidentReport/SeverityType/index'
import IncidentInfo from '../pages/IncidentReport/Incident/Components/IncidentInfo.js'
import Incident from "../pages/IncidentReport/Incident/index.js"
import SubmitIncidents from '../pages/IncidentReport/ReportIncident/index.js'

import IncidentReportTree from '../pages/IncidentReport/ManageHirerachy/index.js'
import IncidentNewHstructure from '../pages/IncidentReport/ManageHirerachy/NewHstructure.js'
import IncidentMapUser from '../pages/IncidentReport/ManageHirerachy/mapusers.js'
import PublishConsole from '../pages/IncidentReport/PublishConsole/index.js'
import MyTask from "../pages/IncidentReport/MyTask/index.js";
import RaisedIncidents from "../pages/IncidentReport/RaisedIncidents/index.js";
import IncMapUser from '../pages/IncidentReport/Incident/Components/mapusers.js'
import IRDashboard from '../pages/IncidentReport/IncidentReportDashBoard/index.js'
import RaisedInfo from "../pages/IncidentReport/RaisedIncidents/Components/raisedinfo.js";



const authProtectedRoutes = [
    { path:"/ir-dashboard",component : IRDashboard},

    { path: "/dashboard", component: Dashboard },
    // { path: "/auditreport", component: AuditReport },
    // { path: "/detailedadtReport", component: DetailedAdtReport },
    // { path: "/parameterreport", component: ParameterReport },
    // { path: "/admindashboard", component: AdminDashboard },
    {path:'/slicer',component : BarChartWithSlicer},
    { path: "/sketch", component: ImageSketch },
    //manage hirerachy
    { path: "/hirchy", component: Hirerachy },

    { path: "/incd-hirchy", component: IncidentReportTree },
    { path: "/incd-crudhstre", component: IncidentNewHstructure },


    { path: "/crudhstre", component: NewHstructure },
    { path: "/mpusr", component: MapUser },
    { path: "/incd-mpusr", component: IncidentMapUser },
    { path: "/publish_console", component: PublishConsole },

    { path: "/my_task", component: MyTask },
    { path: "/raised_incd", component: RaisedIncidents },
    

    //manage endpoint
    { path: "/mels", component: Mels },
    { path: "/adendpt", component: AddEndpoint },
    { path: "/edtent", component: EntitiesOverview },
    // {path :'/newloc',component : NewLocation},
    //manage users
    { path: "/murs", component: ManageUsers },
    { path: "/adusrs", component: AddUsers },
    { path: "/edtusr", component: UsersOverview },
    {path : '/follow_audit', component :FollowUpUserAudit},
    // { path: '/follow_location', component: Chat },
    { path: '/chat', component: Chat },
    // //manage master template
    { path: "/mngmtrtmplt", component: manageMasterTemplate },
    { path: "/crttmplt", component: create_template },

    // //manage published template
    { path: "/mngpblhtempt", component: managePublishedTemplate },
    { path: "/edtpblhtempt", component: editPublishedTemplate },
    { path: "/pblhcfg", component: publishconfig },
    { path: "/pbdrpt", component: PublishedReport },
    { path: "/hlvlpbdrpt", component: HLevelPublishedReport },
    // { path: "/chkpntrprt", component: CheckpointReport },
    { path: "/adtaltclrprt", component: AuditAnalyticalReport },
    { path: "/location", component: Location },
    { path: "/chkpntrprt", component: CheckpointCollapseReport },


    // //user audits
    { path: "/usradt", component: UserAudits },
    { path: "/usrenpdts", component: Endpoints },
    { path: "/enpcpts", component: Checkpoints },
    { path: "/rewenpcpts", component: ReviewCheckpoints },
    { path: "/viewcpts", component: ViewCheckpoints },
    { path: '/preview', component: PreviewAuditScore },
    // // manage roles 
    { path: "/mroles", component: ManageRoles },
    { path: "/createroles", component: CreateRoles },
    { path: "/test", component: WebcamSketch },

    { path: "/adtlctns", component: auditLocations },
    { path: "/adtactnplns", component: auditActionPlans },
    // { path: "/adtactnplninfo", component: auditActionPlanInfo },

    { path: "/incdinfo-mpusr", component: IncMapUser },



    // Reports 
    { path: "/report", component: ReportTemplate },

    { path: "/report_page", component: LayoutInfo },
    // { path: "/layout", component: LayoutInfo },
    { path: "/page_tree", component: PageTree },

    {path:"/map-user",component :mapUserManualLocation},

    { path: "/preview_page", component: Preview_Layout },

    // { path: "/report", component: report },

    // { path: "/setting", component: settings },
    { path: "/profile", component: UserProfile },


    // Report rework
    // { path: "/reporttemplate", component: ReportTemplate },
    { path: "/report-users", component: ReportUser },
    { path: '/hryreport', component: HierarchyReportUser },
    { path: "/view_report", component: ViewMyReport },
    { path: "/publish_type", component: PublishType },
    {path :"/my-reports", component : MyReport},
    { path: "/new-hstructure", component: NewHstructure },
    // { path: "/reset", component: reset },


    //Incident Report Module
    { path: "/inc_type_list", component: IncidentType },
    { path: "/escalation_list", component: ManageEscaltion },

    { path: "/severity_list", component: SeverityType },
    { path: "/incident_info", component: IncidentInfo },
    { path: "/incident_list", component: Incident },
    { path: "/submit_incident", component: SubmitIncidents },
    { path: "/raised_info", component: RaisedInfo },



    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/pages-404" /> }
];

const publicRoutes = [
    // { path: "/ThankyouReg", component: ThankyouReg },

    // { path: "/landing", component: Landing },
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/login2", component: Login2 },
    { path: "/forget-password", component: ForgetPwd },
    // { path: "/register", component: Register },
    // { path: "/ftisusrpfl", component: FTISUserProfile },
    { path: "/pages-maintenance", component: PagesMaintenance },
    { path: "/pages-comingsoon", component: PagesComingsoon },
    { path: "/url-not-found", component: UrlNotFound },
    { path: "/pages-404", component: Pages404 },
    { path: "/pages-500", component: Pages500 },
    { path: "/auth-recoverpw-2", component: ForgetPassword },

    // { path: "/Test", component: Test },

];

export { authProtectedRoutes, publicRoutes };


















// import React from "react";
// import { Redirect } from "react-router-dom";

// // Pages Component
// // import Test from "../pages/Test/Test";
// import UrlNotFound from '../pages/Utility/UrlNotFound'

// // Authentication related pages
// import Login from "../pages/Authentication/Login";
// import Login2 from "../pages/Authentication/Login2";
// import Logout from "../pages/Authentication/Logout";
// // import Register from "../pages/Registration/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";
// import UserProfile from "../components/CommonForBoth/UserProfile";
// // import ThankyouReg from "../pages/Registration/ThankyouReg";
// import ForgetPassword from "../pages/Authentication/ForgotPassword";

// //Landing Page

// // import Landing from "../pages/Landing/index"

// // Dashboard
// // import AdminDashboard from "../pages/AdminDashboard/index";
// // import Dashboard from "../pages/Dashboard/index";
// // import Dashboard from "../pages/AdminDashboard/Dashboard";

// // import AuditAnalytics from "../pages/Dashboard/audit_analytics";
// // import AuditReport from "../pages/Dashboard/auditreport";
// // import ParameterReport from "../pages/Dashboard/parameterreport";
// // import DetailedAdtReport from "../pages/Dashboard/detailedadtreport";

// //Pages
// import PagesMaintenance from "../pages/Utility/pages-maintenance";
// import PagesComingsoon from "../pages/Utility/pages-comingsoon";
// import Pages404 from "../pages/Utility/pages-404";
// import Pages500 from "../pages/Utility/pages-500";

// //Manage Master Template
// // import manageMasterTemplate from "../pages/ManageMasterTemplate/index";
// // import create_template from "../pages/ManageMasterTemplate/createTemplate";

// //Manage Published Template
// // import managePublishedTemplate from "../pages/ManagePublishedTemplate/index";
// // import editPublishedTemplate from "../pages/ManagePublishedTemplate/editPublishedTemplate";
// // import publishconfig from "../pages/ManagePublishedTemplate/publish-config";
// // import PublishedReport from "../pages/ManagePublishedTemplate/publishedReport";
// // import HLevelPublishedReport from "../pages/ManagePublishedTemplate/hlevelPublishedReport";
// // import CheckpointReport from "../pages/ManagePublishedTemplate/checkpoint_report";
// // import AuditAnalyticalReport from "../pages/ManagePublishedTemplate/audit_analytical_report";
// // import Location from "../pages/ManagePublishedTemplate/Location";
// // import CheckpointCollapseReport from '../pages/ManagePublishedTemplate/CheckpointCollapseReport'

// //Manage Hirerachy
// // import Hirerachy from "../pages/ManageHirerachy/index";
// // import CRUDHStructure from "../pages/ManageHirerachy/crudHStructure";
// // import MapUser from "../pages/ManageHirerachy/mapusers";
// // import MapEndpoints from "../pages/ManageHirerachy/mapendpoints";

// //Manage Endpoint
// // import Mels from "../pages/ManageEntities/mels";
// // import AddEndpoint from "../pages/ManageEntities/addendpoint";
// // import EntitiesOverview from "../pages/ManageEntities/edtent";
// // import NewLocation from '../pages/ManagePublishedTemplate/NewLocation'
// //Manage Users
// // import ManageUsers from "../pages/ManageUsers/musr";
// // import AddUsers from "../pages/ManageUsers/adduser";
// // import UsersOverview from "../pages/ManageUsers/edtusr";
// // import FTISUserProfile from "../pages/Registration/FTISUserProfile"


// //User Audits
// // import UserAudits from "../pages/UserAudit/index"
// // import Endpoints from "../pages/UserAudit/endpoints"
// // import Checkpoints from "../pages/UserAudit/checkpoints"
// // import ReviewCheckpoints from "../pages/UserAudit/review_checkpoints"
// // import ViewCheckpoints from "../pages/UserAudit/viewCheckpoint";
// // import PreviewAuditScore from '../pages/UserAudit/Components/preview_audit_score'
// // // Manage Roles
// // import ManageRoles from '../pages/ManageRoles/ManageRoles'
// // import CreateRoles from "../pages/ManageRoles/CreateRoles";



// // //report
// // import report from "../pages/report/report";

// // // settings
// // import settings from "../pages/settings/settings";

// // //Reset
// // import reset from "../pages/reset/reset";
// // import ForgetPassword2 from "../pages/Authentication/ForgotPassword";


// const authProtectedRoutes = [

// 	// { path: "/dashboard", component: Dashboard },
// 	// { path: "/auditanalytics", component: AuditAnalytics },
// 	// { path: "/auditreport", component: AuditReport },
// 	// { path: "/detailedadtReport", component: DetailedAdtReport },
// 	// { path: "/parameterreport", component: ParameterReport },
// 	// { path: "/admindashboard", component: AdminDashboard },

// 	//manage hirerachy
// 	// { path: "/hirchy", component: Hirerachy },
// 	// { path: "/crudhstre", component: CRUDHStructure },
// 	// { path: "/mpusr", component: MapUser },
// 	// { path: "/mpendpt", component: MapEndpoints },

// 	//manage endpoint
// 	// { path: "/mels", component: Mels },
// 	// { path: "/adendpt", component: AddEndpoint },
// 	// { path: "/edtent", component: EntitiesOverview },
// 	// {path :'/newloc',component : NewLocation},
// 	// //manage users
// 	// { path: "/murs", component: ManageUsers },
// 	// { path: "/adusrs", component: AddUsers },
// 	// { path: "/edtusr", component: UsersOverview },

// 	// //manage master template
// 	// { path: "/mngmtrtmplt", component: manageMasterTemplate },
// 	// { path: "/crttmplt", component: create_template },

// 	//manage published template
// 	// { path: "/mngpblhtempt", component: managePublishedTemplate },
// 	// { path: "/edtpblhtempt", component: editPublishedTemplate },
// 	// { path: "/pblhcfg", component: publishconfig },
// 	// { path: "/pbdrpt", component: PublishedReport },
// 	// { path: "/hlvlpbdrpt", component: HLevelPublishedReport },
// 	// // { path: "/chkpntrprt", component: CheckpointReport },
// 	// { path: "/adtaltclrprt", component: AuditAnalyticalReport },
// 	// { path: "/location", component: Location },
// 	// { path: "/chkpntrprt", component: CheckpointCollapseReport },


// 	//user audits
// 	// { path: "/usradt", component: UserAudits },
// 	// { path: "/usrenpdts", component: Endpoints },
// 	// { path: "/enpcpts", component: Checkpoints },
// 	// { path: "/rewenpcpts", component: ReviewCheckpoints },
// 	// { path: "/viewcpts", component: ViewCheckpoints },
// 	// { path: '/preview', component: PreviewAuditScore },
// 	// // manage roles 
// 	// { path: "/mroles", component: ManageRoles },
// 	// { path: "/createroles", component: CreateRoles },



// 	// { path: "/report", component: report },

// 	// { path: "/setting", component: settings },
// 	{ path: "/profile", component: UserProfile },

// 	// { path: "/reset", component: reset },

// 	// this route should be at the end of all other routes
// 	{ path: "/", exact: true, component: () => <Redirect to="/pages-404" /> }
// ];

// const publicRoutes = [
// 	// { path: "/ThankyouReg", component: ThankyouReg },

// 	// { path: "/landing", component: Landing },
// 	{ path: "/logout", component: Logout },
// 	{ path: "/login", component: Login },
// 	{ path: "/login2", component: Login2 },
// 	{ path: "/forget-password", component: ForgetPwd },
// 	// { path: "/register", component: Register },
// 	// { path: "/ftisusrpfl", component: FTISUserProfile },
// 	{ path: "/pages-maintenance", component: PagesMaintenance },
// 	{ path: "/pages-comingsoon", component: PagesComingsoon },
// 	{ path: "/url-not-found", component: UrlNotFound },
// 	{ path: "/pages-404", component: Pages404 },
// 	{ path: "/pages-500", component: Pages500 },
// 	{ path: "/auth-recoverpw-2", component: ForgetPassword },

// 	// { path: "/Test", component: Test },

// ];

// export { authProtectedRoutes, publicRoutes };
