// import React, { useEffect,useState } from 'react'
// import PieChart from './Components/PieChart'
// import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap"
// import { useDispatch, useSelector } from 'react-redux'
// import { IrDashboardAnalytics } from '../../../store/incidentReport/incdreportslice'
// import MetaTags from "react-meta-tags";
// import CardUser from './Components/CardUser'

// const IRDashboard = () => {
//     const dispatch = useDispatch()
//     const incidentReport = useSelector(state => state.incdreportslice)
//     const [authUser,setAuthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
//     console.log(incidentReport, 'incidentReport')

//     useEffect(() => {
//         console.log("get ready for dasboard")
//         dispatch(IrDashboardAnalytics())

//     }, [])

//     return (
//         <React.Fragment>
//             <div className="page-content">
//                 <MetaTags>
//                     <title>AuditVista | Incident Type</title>
//                 </MetaTags>
//                 <Row  className='mt-2'>

//                 {
//                     authUser &&
//                     <CardUser user_data ={authUser.user_data} clientInfo = {authUser.client_info[0]} />
//                 }

//                 {
//                     incidentReport.irDashBoardData.length > 0 ?(

//                         incidentReport.irDashBoardData.map((ele,pos)=>{
//                                 return(

//                                     <Col key={pos} xl={6}>
//                                         <Card>
//                                             <CardBody>
//                                                 <CardTitle className="mb-4">Manage Incident Status of : {ele.email}</CardTitle>
//                                                 <PieChart irDashBoardData={ele.dashBoardData} dataColors={
//                                                     // _.map(ele.dashBoardData, 'color')
//                                                     ['--bs-secondary', '--bs-secondary', '--bs-warning', '--bs-primary', '--bs-success', '--bs-danger']
//                                                     } />
//                                             </CardBody>
//                                         </Card>
//                                     </Col>

//                                 )


//                         })


//                         )
//                         :
//                         <Row>
//                             <Col lg="12">
//                                 <Card>
//                                     <CardBody style={{ height: "100vh" }}>
//                                         <div className="d-flex flex-column justify-content-center align-items-center">
//                                             <div>Loading...</div>
//                                             <Spinner className="ms-2" color="primary" />
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>
//                         </Row>
// }

// </Row>


//             </div>
//         </React.Fragment>
//     )
// }

// export default IRDashboard








import React, { useEffect, useState } from 'react'
import PieChart from './Components/PieChart'
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import { IrDashboardAnalytics } from '../../../store/incidentReport/incdreportslice'
import MetaTags from "react-meta-tags";
import CardUser from './Components/CardUser'
import BarChart from './Components/BarChart'
import LineChart from './Components/LineChart'

const IRDashboard = () => {
    const dispatch = useDispatch()
    const incidentReport = useSelector(state => state.incdreportslice)
    const [authUser, setAuthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    console.log(incidentReport, 'incidentReport')

    useEffect(() => {
        console.log("get ready for dasboard")
        dispatch(IrDashboardAnalytics())

    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>AuditVista | Incident Type</title>
                </MetaTags>
                <Row className='mt-2'>

                    {
                        authUser &&
                        <CardUser user_data={authUser.user_data} clientInfo={authUser.client_info[0]} />
                    }
                    {/* {
                        incidentReport.irDashBoardData.map((item, idx) => {
                            return (
                                    <select key={idx}>All</select>
                                )
                        })
                    } */}
                    {
                        incidentReport.irDashBoardData.length > 0 ? (


                            

                            incidentReport.irDashBoardData.map((ele, pos) => {
                                
                                return (

                                    <Col key={pos} xl={6}>
                                        <Card>
                                            {
                                                ele.chart_name === 'pie_chart' ?
                                                    <CardBody>
                                                        <CardTitle className="mb-4">Incident type used for Incident submisson Total ({ele.chart_data.length})</CardTitle>
                                                        <PieChart irDashBoardData={ele.chart_data} dataColors={
                                                            _.map(ele.chart_data, 'color')
                                                        } />
                                                    </CardBody>
                                                    :
                                                    ele.chart_name === 'bar_chart' ?
                                                        <CardBody>
                                                            <CardTitle className="mb-4">Manage Incident Status 
                                                                {/* of :  */}
                                                                {/* {ele.chart_data[0].email} */}
                                                                </CardTitle>
                                                            <BarChart irDashBoardData={ele.chart_data} dataColors={
                                                                _.map(ele.chart_data, 'color')
                                                            } />
                                                        </CardBody>
                                                        :
                                                        ele.chart_name ==="line_chart" ?
                                                        <CardBody>
                                                        <CardTitle className="mb-4">Severity Type used for Incident Allocated</CardTitle>
                                                        <LineChart lineChartData={ele.chart_data} dataColors={
                                                            _.map(ele.chart_data, 'color')
                                                        } />
                                                    </CardBody>
                                                        :
                                                        <>
                                                        </>

                                            }

                                        </Card>
                                    </Col>

                                )


                            })


                        )
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody style={{ height: "100vh" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }

                </Row>


            </div>
        </React.Fragment>
    )
}

export default IRDashboard
