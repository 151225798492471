import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    Media,
    Row,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap"
import moment from 'moment'

export default class PreviewCAPA extends Component {


    render() {
        return (
            <Row >
                {
                    this.props.actionplans.map((item, i) => {
                        return (
                            <Row key={i} >
                            <div className="p-2 m-2" style={{borderTop:"1px solid #e9e9e9"}}>
                                <Col className="col-auto">
                                    <label>Action plan {String(i + 1)}</label>
                                    <Row>
                                        <Col>
                                            <label className="text-primary font-size-12">Observation<span className='text-danger'>*</span></label>
                                            <div>{item.observation}</div>
                                        </Col>
                                        <Col>
                                            <label className="text-primary font-size-12">Root Cause<span className='text-danger'>*</span></label>
                                            <div>{item.root_cause}</div>
                                        </Col>
                                        <Col>
                                            <label className="text-primary font-size-12">Action plan<span className='text-danger'>*</span> </label>
                                            <div>{item.actionplan}</div>
                                        </Col>
                                        <Col>
                                            <div className="mb-2">
                                                <span className="text-primary font-size-12">Target date<span className='text-danger'>*</span></span><br />
                                                <span>{moment(item.target_date).format("DD/MMM/YYYY")}</span>
                                            </div>
                                            <div className="mb-2">
                                                <span className="text-primary font-size-12">Assign to email<span className='text-danger'>*</span></span><br />
                                                {/* <span>{item.to_email}</span> */}
                                                <div className="d-flex gap-1">
                                            {item.responsible_person.map((item2, index) => (
                                                <React.Fragment key={index}>
                                                    <label>{item2.name}</label>
                                                    {index < item2.responsible_person?.length - 1 ? ',' : '.'}
                                                </React.Fragment>
                                            ))}
                                        </div>

                                            </div>
                                            {/* <div className="mb-2">
                                                <span className="text-primary font-size-12">Phone number</span><br />
                                                <span>{item.to_phone}</span>
                                            </div> */}
                                        </Col>
                                    </Row>
                                    
                                </Col>
                            </div>
                        </Row>
                        )
                    })


                }
            </Row>

        )
    }


}
