import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
  Badge
} from "reactstrap"
import { size, map } from "lodash"

import tmplIcon from '../../../assets/images/template/tmpl-icon-1.png';
import user1 from '../../../assets/images/avatar.jpg';


//import images from "../../assets/images"

class CardContact extends Component {
  render() {
    const docItem = this.props.docItem
    const contactkey = this.props.contactkey
    const textStyle = {
      maxWidth: '100%',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
    function toCamelCase(str) {
      return str.replace(/(^|-)(\w)/g, (match, p1, p2) => (p1 === "-" ? "-" : "") + p2.toUpperCase());
    }

    const className = docItem.tmpltCtdBy === "0" ? "textInfo textUppercase" : "textPrimary textUppercase";
    const templateNameCamelCase = toCamelCase(docItem.template_name);
    return (
      <Col xxl="3" xl="4" lg="5" md="6" sm="6" className="g-3">
        
        <Card className="custom-card mb-1" style={{ borderRadius: '8px', border: docItem.total_checkpoints !== 0 ? '1px solid #34c38f' : '1px solid #74788d'}}>
          <CardBody className="custom-body" style={{ paddingBottom: 4, height:280 }}>

            <Row className="my-2" >
              <Col className="col-4" style={{ paddingLeft: 10 }}>
                <div
                  style={{
                    height: "60px",
                    width: "60px",
                    padding: 10,
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "1rem",
                    alignItems: "center",
                    background: "rgb(243, 246, 251)",
                  }}
                >
                  <img
                    src={tmplIcon}
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-3" style={{minHeight:110}}>
              <Col className="col-12">
                <h5 className="font-size-14 mb-1 fw-bold" >
                  <div className={className}>
                    {templateNameCamelCase}
                  </div>
                </h5>
                <div className="py-1" style={{ height: 50 }}>
                  <div className="text-muted font-size-12" style={textStyle} >{docItem.template_info}</div>
                </div>
                <Badge className={docItem.total_checkpoints !== 0 ? "font-size-12 badge-soft-success" : "font-size-12 badge-soft-secondary"}  pill  > Check points :  {docItem.total_checkpoints}</Badge>

              </Col>
            </Row>

            <div>
              {map(
                docItem.tags,
                (tag, index) =>
                  index < 2 && (
                    <Link
                      to="#"
                      className="badge bg-primary font-size-11 m-1"
                      key={"_skill_" + contactkey}
                    >
                      {tag}
                    </Link>
                  )
              )}
              {size(docItem.tags) > 2 && (
                <Link
                  to="#"
                  className="badge bg-primary font-size-11 m-1"
                  key={"_skill_" + contactkey}
                >
                  {size(docItem.tags) - 1} + more
                </Link>
              )}
            </div>

            <Row className="py-3 border-top mt-2 ">
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-primary"
                    id={`viewtooltip-${docItem._id}`}
                    onClick={() => this.props.onEdit(docItem)}
                  >
                    <i className="mdi mdi-clipboard-text-outline" />
                  </Link>
                </li>
                <UncontrolledTooltip placement="top" target={`viewtooltip-${docItem._id}`}>
                  Edit Template
                </UncontrolledTooltip>

                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => { this.props.onRename(docItem) }}
                    id={`edittooltip-${docItem._id}`}
                  >
                    <i className="mdi mdi-pencil-outline" />
                    <UncontrolledTooltip placement="top" target={`edittooltip-${docItem._id}`} >
                      Rename Template
                    </UncontrolledTooltip>
                  </Link>
                </li>

                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => this.props.onDelete(docItem)}
                    id={`deletetooltip-${docItem._id}`}
                  >
                    <i className="mdi mdi-delete-outline" />
                    <UncontrolledTooltip placement="top" target={`deletetooltip-${docItem._id}`}>
                      Delete Template
                    </UncontrolledTooltip>
                  </Link>
                </li>

                <li>
                  <Link
                    to="#"
                    className={this.props.protect_routes.read_write_checked === true && docItem.total_checkpoints !== 0 ? "btn btn-sm btn-success" : "btn btn-sm btn-secondary"}
                    onClick={() => {
                      this.props.protect_routes.read_write_checked === true && docItem.total_checkpoints !== 0 &&
                        this.props.useThisTemplate(docItem)
                    }}
                    id={`shareaudit-${docItem._id}`}
                  >
                    <i className={ docItem.total_checkpoints === 0 ? "mdi mdi-share-off" : "mdi mdi-share"} />
                    <UncontrolledTooltip placement="top" target={`shareaudit-${docItem._id}`}>
                      {docItem.total_checkpoints === 0 ? "Add check points and use this template" : "Use this template as"}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
            </Row>
            

          </CardBody>
        </Card>
        {/* </div> */}
      </Col>
    )
  }
}

CardContact.propTypes = {
  user: PropTypes.object
}

export default CardContact
