import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react'
import { Row, Col, Container, Card, CardBody, Button, InputGroup } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Multiselect } from 'multiselect-react-dropdown';
// import urlSocket from 
import urlSocket from '../../../helpers/urlSocket'
import CryptoJS from 'crypto-js'
import Swal from "sweetalert2";
import { Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { crudNewUser, checkUserAvailableApi, updtPublishedLocation } from '../../../store/aplnfollowup/aplnflwupslice';
import store from '../../../store';

const CreateNewUser = (props) => {
    const formRef = useRef(null);
    const [show, setShow] = useState(false)
    const [acplanRoleInfo, setacplanRoleInfo] = useState([])
    const [rolePermisson, setrolePermisson] = useState("")
    const [locationInfo, setLocationInfo] = useState(JSON.parse(sessionStorage.getItem("endpointData")))
    const [userEmail, setuserEmail] = useState("")
    const [sessionUserInfo, setsessionUserInfo] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [clientInfo, setclientInfo] = useState(JSON.parse(sessionStorage.getItem("client_info"))[0])
    const [countryCode, setcountryCode] = useState(null)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [roleErr, setroleErr] = useState(false)
    const [countryCodeErr, setcountryCodeErr] = useState(false)
    const [secretKey, setsecretKey] = useState('Pkdh347ey%3Tgs')
    const [categoryErr, setcategoryErr] = useState(false)
    const [historyPermission, setHistoryPermission] = useState('1');
    const [Name, setName] = useState('')
    const [phonenumber, setphonenumber] = useState('')
    const [exist, setexist] = useState(false)

    const dispatch = useDispatch()
    const followUpSlice = useSelector(state => state.acplnFollowUpSliceReducer)
    const emailExist = followUpSlice.emailExist
    const phoneNumExist = followUpSlice.phoneNumExist



    useEffect(() => {
        var roleInfo = JSON.parse(sessionStorage.getItem("authUser")).config_data.action_plan_roles

        var roleInfo = roleInfo.filter((e)=>{
            if(e.id >= followUpSlice.validUser[0].id){
                return e
            }
        })
        setacplanRoleInfo(roleInfo)
    
    }, [])



    const Validsubmit = async (events, values) => {
        const encryptedPassword = CryptoJS.AES.encrypt(values.password, secretKey).toString();

        Object.assign(values, {
            password: encryptedPassword,
            confirmpassword: encryptedPassword,
            userPoolId: clientInfo.userPoolId,
            clientId: clientInfo.clientId,
            encrypted_db_url: sessionUserInfo.db_info.encrypted_db_url,
            db_name: sessionUserInfo.db_info.db_name,
            facilities: clientInfo.facilities,
            company_id: sessionUserInfo.client_info[0]._id,
            created_by: sessionUserInfo.user_data._id,
            short_name: clientInfo.short_name,
            user_id: sessionUserInfo.user_data._id
        });

        if (!countryCode) {
            setcountryCodeErr(true);
        } else {
            values.countrycode = countryCode;
            setcountryCodeErr(false);
        }

        if (!rolePermisson) {
            setroleErr(true);
        } else {
            values.role_permission = rolePermisson;
            setroleErr(false);
        }

        const validationConditions = [
            invalidEmail,
            phoneNumExist,
            countryCodeErr,
            !values.countrycode,
            !rolePermisson,
            categoryErr
        ];

        if (validationConditions.every(cond => !cond)) {

            values["action_plan_user"] = true
            try {
                if (!exist) {
                    await dispatch(crudNewUser(values))
                }
                var followUpSlice = store.getState().acplnFollowUpSliceReducer


                const userId = followUpSlice.existUser ? followUpSlice.existUser._id : followUpSlice.userInfo[0]["_id"];
                const commonUserData = {
                    _id: userId,
                    name: values.firstname,
                    title: locationInfo.location_name,
                    user_id: userId,
                    role_permission: rolePermisson,
                    history_permission: historyPermission,
                    id: rolePermisson.id,
                    role_name: rolePermisson.role_name,
                    facilities: rolePermisson.facilities,
                    user_status:"0"
                };

                locationInfo.location_user_path.push({
                    ...commonUserData,
                });

                locationInfo["created_user"] = {
                    ...commonUserData,
                };

                locationInfo.location_unique_users.push({
                    ...commonUserData,
                });

                locationInfo.location_permission_acpln.push({
                    ...commonUserData,
                    role_description: rolePermisson.role_description,
                });


                locationInfo['recent_user'] = followUpSlice.existUser ? followUpSlice.existUser : followUpSlice.userInfo[0]
                dispatch(updtPublishedLocation(locationInfo, sessionUserInfo, rolePermisson, historyPermission, props.selectedActionplan,props.selectedCheckpoint))
                formRef.current.reset()
                setcountryCode(null)
                setrolePermisson("")    
                props.onClose()

            } catch (error) {
                console.log(error, 'error');
            }
        }
    };

    const checkValidEmail = (e) => {
        var email = e.target.value
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setuserEmail(email)
            setInvalidEmail(false)
        }
        else {
            setInvalidEmail(true)
            setuserEmail(email)
        }

    }

    const checkUserExist = (e, mode) => {
        var admn_info = {}
        if (mode == 1) {
            admn_info["email_id"] = e.target.value
        }
        if (mode === 2) {
            admn_info["phone_number"] = e.target.value
        }

        admn_info["encrypted_db_url"] = sessionUserInfo.db_info.encrypted_db_url
        admn_info["db_name"] = sessionUserInfo.db_info.db_name
        admn_info["user_id"] = sessionUserInfo.user_data._id
        dispatch(checkUserAvailableApi(admn_info, mode))
    }


    const handleConfirm = () => {
        setName(followUpSlice.existUser.firstname)
        setcountryCode(followUpSlice.existUser.countrycode)
        setphonenumber(followUpSlice.existUser.phone_number)
        setexist(true)
    }



    const selectRole = (event) => {
        var id = event.target.value
        var getSelectedRole = _.filter(acplanRoleInfo, { id: Number(id) })
        if (getSelectedRole.length > 0) {
            setrolePermisson(getSelectedRole[0])
            setroleErr(false)
        }


    }



    return (<div className='mt-3'>

        <Row className='m-0' >
            <Col lg="12">
                <AvForm ref={formRef} className="form-horizontal" onValidSubmit={Validsubmit}>
                    <div className="mb-3">
                        <label>Full Name :<span className="text-danger"> *</span></label>
                        <AvField
                            name="firstname"
                            errorMessage="Please enter your name"
                            className="form-control"
                            placeholder="Enter First Name"
                            type="text"
                            value={Name}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label>Email Id :<span className="text-danger"> *</span></label>
                        <AvField
                            name="email_id"
                            errorMessage="Please enter your Email ID"
                            className="form-control"
                            placeholder="Enter User Email"
                            onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("") }}
                            onChange={(e) => {
                                checkValidEmail(e)
                                    ; checkUserExist(e, 1)
                            }}
                            type="email"
                            required
                        />
                        {
                            emailExist &&
                            <>
                                <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Email ID already exist fo another user</div>
                                <Popconfirm
                                    title="Use this User"
                                    description="Are you sure to Use this User?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={handleConfirm}
                                    zIndex={10000}
                                >
                                    <Button danger>Use this User</Button>
                                </Popconfirm>
                                <div>
                                </div>
                            </>
                        }
                        {
                            invalidEmail &&
                            <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>
                        }
                    </div>
                    <div className="mb-3">
                        <Row>
                            <label>Phone Number:<span className="text-danger"> *</span></label>
                            <Col md={4} className="pe-0">
                                <select name="countrycode"
                                    onChange={(e) => {
                                        setcountryCode(e.target.value)
                                    }}
                                    value={countryCode}
                                    className="form-select" required>
                                    <option value="">Select</option>
                                    {
                                        clientInfo.countries.map((c, idx) => (
                                            <option key={idx} value={c.code}>
                                                {c.code}{""}&nbsp;{c.label}
                                            </option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={8} className="ps-0">
                                <AvField
                                    name="phone_number"
                                    className="form-control"
                                    placeholder="Enter Phone number"
                                    errorMessage="Please enter your Phone Number"
                                    type="number"
                                    value={phonenumber}
                                    onChange={(e) => { checkUserExist(e, 2) }}
                                    validate={{
                                        required: { value: true },
                                        minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                        maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                    }}
                                />

                                {
                                    phoneNumExist &&
                                    <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Phone Number already exist fo another user</div>
                                }
                                {
                                    countryCodeErr &&
                                    <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter the country code</div>

                                }

                            </Col>
                        </Row>
                    </div>
                    {!exist &&
                        <div>
                            <div >
                                <label>Username :<span className="text-danger"> *</span></label>
                                <AvField
                                    name="username"
                                    type="text"
                                    required
                                    placeholder="Enter username"
                                    disabled={true}
                                    value={userEmail}
                                />
                            </div>
                            <div >
                                <label>Password :<span className="text-danger"> *</span></label>
                                <div className="input-group d-flex w-100">
                                    <AvField
                                        name="password"
                                        autoComplete="new-password"
                                        type={show ? "password" : "text"}
                                        required
                                        placeholder="Enter password"
                                        className="form-control"
                                        style={{width:250}}
                                        validate={{
                                            required: { value: true, errorMessage: 'Password is required' },
                                            minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
                                            pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                        }} />
                                    <div className="input-group-append" >
                                        <button
                                            onClick={() => { setShow(!show) }}
                                            className="btn btn-light"
                                            type="button"
                                            id="password-addon"
                                        >
                                            <i className={show ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                    <label>Role Permission :<span className="text-danger"> *</span></label>
                    <div className="mb-3">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {
                                <div className="select-container">
                                    <select defaultValue={rolePermisson?.id == null ? "Select Role" : rolePermisson.id}
                                        onChange={(e) => {
                                            selectRole(e)
                                        }}
                                        className='form-select'
                                       >
                                        <option disabled value={'Select Role'}>{"Select Role"}</option>
                                        {
                                            acplanRoleInfo.map((data, idx) => (
                                                <option key={idx} value={data.id}>{data.role_name} </option>
                                            ))
                                        }

                                    </select>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        roleErr &&
                        <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the User role</div>
                    }

                    <div className="mb-3">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="form-check mx-2 form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    checked={historyPermission === '0'}
                                    onChange={(e) => {
                                        setHistoryPermission(e.target.checked ? '0' : '1');
                                    }}
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                    Show conversation history
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <Button style={{ width: "100%" }} type='submit' className='btn btn-danger'>Submit</Button>

                    </div>
                </AvForm>
            </Col>
        </Row>
    </div>);
}


export default CreateNewUser;