
import React, { Component } from 'react'
import { Tabs } from 'antd';
import {
    CardBody, CardTitle, Row, Col,
    Button, TabContent, TabPane, NavLink, NavItem, Nav,
    FormGroup, Card, Form,
    UncontrolledDropdown,
    UncontrolledTooltip, Input
} from "reactstrap";
import { Link } from "react-router-dom"
import classnames from "classnames";
import Guideline from './Guideline'
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import Switch from "react-switch"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import '../manageAudit.css'
const _ = require('lodash')

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
        </div>
    )
}

const OnSymbol = props => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Yes
        </div>
    )
}

export default class YesorNo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: "1",
            tags: [],
            compliance: [],
            complianceSelected: "",

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this);
    }

    componentDidMount() {
        var config_data = JSON.parse(sessionStorage.getItem('authUser'))
        console.log(config_data, 'config_data')
        this.setState({ compliance: this.props.checkpointCompliance, max_video_duration: Number(config_data.client_info[0].max_video_duration.$numberDecimal), config_data: config_data.client_info[0] })

    }


    ValidateAndSend = () => {
        // this.setState({ currentTab: "1" })
        // let data = this.props.checkpointinfo
        // data.min_score = this.get_minScore(this.props.parameter_options)
        // data.max_score = this.get_maxScore(this.props.parameter_options)
        // data.parameter_options = this.props.parameter_options
        // this.props.onSave(data)

    }

    onChangeConfigReq = (e, data) => {
        let value = e.target.checked ? true : false
        data.show_config = value
        this.setState({ refresh: true })
    }

    onChangeCompliance = (e, data) => {
        let value = e.target.checked ? true : false
        data.is_compliance = value
        this.setState({ refresh: true })
    }

    onChangePhotoReq = (e, data) => {
        let value = e.target.checked ? true : false
        data.enable_img = value
        this.setState({ refresh: true })
    }

    onChangeVideoReq = (e, data) => {
        let value = e.target.checked ? true : false
        data.enable_video = value
        this.setState({ refresh: true })
    }

    onChangeActionReq = (e, data) => {
        let value = e.target.checked ? true : false
        data.enable_nc = value
        this.setState({ refresh: true })
    }

    onChangeDocReq = (e, data) => {
        let value = e.target.checked ? true : false
        data.enable_doc = value
        this.setState({ refresh: true })
    }

    onChangeScoreReq = (e, data) => {
        let value = e.target.checked ? true : false
        data.enable_score = value
        this.setState({ refresh: true })
    }

    get_minScore = (data) => {
        try {
            return isNaN(Math.min.apply(Math, data.map((o) => { return o.score }))) ? 0 : Math.min.apply(Math, data.map((o) => { return o.score }))
        } catch (error) {
            return 0
        }
    }

    get_maxScore = (data) => {
        try {
            return isNaN(Math.max.apply(Math, data.map((o) => { return o.score }))) ? 0 : Math.max.apply(Math, data.map((o) => { return o.score }))
        } catch (error) {
            return 0
        }
    }


    handleChange(tags, data) {
        data.documents = tags
        this.setState({ refresh: true })
    }

    handleKeyDown = (e) => {
        const allowedCharacters = /^[a-zA-Z0-9]+$/;
        if (!allowedCharacters.test(e.key)) {
            e.preventDefault();
        }
        // if (e.key == '-' && e.target.value.includes('-')) {

        //     e.preventDefault(); // Prevent entering consecutive "-" characters
        //   }
    }

    scoreHandle = (e, idx) => {
        var valof = e.target.value
        if (valof == "") {
            e.preventDefault();
        }
        this.props.checkpointinfo.checkpoint_options[idx].score = parseFloat(e.target.value); this.setState({ refresh: true })
    }

    addOptions() {
        this.props.checkpointinfo.checkpoint_options = this.props.checkpointinfo.checkpoint_options.concat([
            {
                "option_text": "",
                "score": 0,
                "enable_img": false,
                "no_of_img": 0,
                "no_of_video": 0,
                "optn_editable": true,
                "enable_nc": false,
                "enable_doc": false,
                "enable_score": false,
                "documents": [],
                "nc_mandatory": false,
                "enable_cam": false,
                "enable_gallery": false,
                "show_config": false,
                "images": [],
                "videos": [],
                "documents_attached": [],
                "compliance": [
                    {
                        "id": "1",
                        "name": "Compliant",
                        "color": "success",
                        "is_selected": false
                    },
                    {
                        "id": "2",
                        "name": "Non Compliant",
                        "color": "danger",
                        "is_selected": false
                    },
                    {
                        "id": "3",
                        "name": "Partially Compliant",
                        "color": "warning",
                        "is_selected": false
                    }
                ]
            }
        ])


        this.setState({ refresh: true })
    }

    deleteOption(id) {
        this.props.checkpointinfo.checkpoint_options.splice(id, 1)
        this.setState({ refresh: true })
    }

    handleRadioGroupChange(event, idx, data) {
        _.each(this.props.checkpointinfo.checkpoint_options[idx].compliance, item => {
            if (item.name === data.name) {
                item["is_selected"] = true
                //this.setState({complianceSelected:item})
            }
            else {
                item["is_selected"] = false
            }
        })
        this.setState({ compliance: this.state.compliance })
        // this.state.configdatacheckpoint[this.state.cpId].impact_level = event.target.name; 
        // this.setState({refresh:true})
    }

    handleKeyDown = (e) => {
        if (e.key === '-' && e.target.value.includes('-')) {
            e.preventDefault(); // Prevent entering consecutive "-" characters
        }
    };


    ScoreChange = (e, idx) => {
        this.props.checkpointinfo.checkpoint_options[idx].score = parseInt(e.target.value);
        this.setState({ refresh: true })
    }

    handleKeypress = (event) => {
        const { key, target } = event;
        if (key === '-' || key === '+') {
            // Prevent the entry of more than one subtraction symbol (-)
            if (this.state.minusPressed || target.value.includes('-') || target.value.includes('+')) {
                event.preventDefault();
            } else {
                this.setState({ minusPressed: true });
            }
        } else if (key === 'Backspace') {
            // Allow the entry of the subtraction symbol (-) after backspace is pressed
            this.setState({ minusPressed: false });
        }
    };



    render() {
        return (
            <div >
                <div>
                    <div className="button-items ">
                        <div className="btn-group-vertical " role="group" aria-label="Basic radio toggle button group">

                            <Row>
                                {
                                    this.props.checkpointinfo.checkpoint_options.map((data, idx) => {
                                        var getComplianceStatus = _.some(data.compliance, { 'is_selected': true });


                                        return (
                                            <Col md={12} key={idx} >
                                                <div className="border border-secondary my-2"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        padding: 15,
                                                        borderRadius: 8,
                                                        border: this.props.submitprocess && (data.option_text == "" ||
                                                            (data.enable_img && (Number.isNaN(data.no_of_img) || data.no_of_img == 0 || (!data.enable_gallery && !data.enable_cam))) ||
                                                            (data.enable_video && (Number.isNaN(data.no_of_video) || data.no_of_video == 0 || (!data.enable_video_gallery && !data.enable_video_cam)))
                                                            ||
                                                            (data.enable_doc && data.documents.length == 0) || (data.enable_score && Number.isNaN(data.score)) || !getComplianceStatus) ? '1px solid #ff0000' : '0px'
                                                    }}
                                                >
                                                    <div className="position-relative" style={{ borderBottom: '1px solid #f0f0f0', paddingBottom: 3 }}>
                                                        <AvField
                                                            name={"option " + idx + 1}
                                                            type="text"
                                                            value={data.option_text}
                                                            onKeyPress={this.onKeyPress}
                                                            onChange={(e) => { this.props.checkpointinfo.checkpoint_options[idx].option_text = (e.target.value); this.setState({ refresh: true }) }}
                                                            disabled={!this.props.checkpointinfo.enable_addOptns}
                                                            className={this.props.checkpointinfo.enable_addOptns ? "mb-2 form-control" : "mb-2 form-control chat-input"}
                                                            placeholder="Enter Option..."
                                                            validate={{ required: { value: this.props.submitprocess && data.option_text == "" ? true : false } }}
                                                        />
                                                        <div className='d-flex flex-row align-items-center' role="group">

                                                            {
                                                                data.compliance &&
                                                                data.compliance.map((item, indx) => {
                                                                    var radioId = "nc" + indx + idx
                                                                    return (
                                                                        <div className={"form-check  my-2 me-2 form-radio-" + item.color} key={"cpmt" + String(indx)}>
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type="radio"
                                                                                name={radioId}
                                                                                id={radioId}
                                                                                // disabled={data.na_id === "1"}
                                                                                value={item.name}
                                                                                onChange={(event) => { this.handleRadioGroupChange(event, idx, item) }}
                                                                                checked={item.is_selected}
                                                                            />

                                                                            <label
                                                                                className={data.is_compliance ? "form-check-label text-primary font-size-12" : "form-check-label text-Dark font-size-12"}
                                                                                htmlFor={"nc" + indx + idx}
                                                                            >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                            {
                                                                this.props.submitprocess && !getComplianceStatus && <label className='text-danger p-0 m-0'> Select any one option</label>
                                                            }
                                                        </div>



                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #f0f0f0', paddingBottom: 5, marginTop: 5 }} >
                                                        <div
                                                            className="form-check form-switch form-switch-sm "
                                                            style={{
                                                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                            }}
                                                        >

                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input me-2 "
                                                                id={"config" + idx}
                                                                onChange={(e) => { this.onChangeConfigReq(e, data) }}
                                                                // disabled={data.na_id === "1"}
                                                                checked={data.show_config}
                                                            />

                                                            <label
                                                                className={data.show_config ? "form-check-label text-primary font-size-12" : "form-check-label text-Dark font-size-12"}
                                                                htmlFor={"config" + idx}
                                                            >
                                                                Show Configuration
                                                            </label>
                                                        </div>

                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                            {idx > 0 && this.props.checkpointinfo.enable_addOptns ?
                                                                <div className='me-1'>
                                                                    <button className="btn btn-primary btn-block btn-sm" onClick={() => this.addOptions()}>Add Option</button>
                                                                </div> : null
                                                            }
                                                            {idx > 1 && this.props.checkpointinfo.enable_addOptns ?
                                                                <div>
                                                                    <button className="btn btn-danger btn-block btn-sm" onClick={() => this.deleteOption(idx)}>Delete this Option</button>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    {data.show_config ?
                                                        <div>

                                                            {/* --------------------- */}
                                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, borderBottom: '1px solid #f0f0f0', paddingBottom: 5, marginTop: 10 }} >

                                                                <div className='col-6'>

                                                                    <div style={{ display: 'flex', flexDirection: 'column', }} >

                                                                        <div
                                                                            className="form-check form-switch form-switch-sm bg-light  py-1"
                                                                            style={{
                                                                                display: 'flex', flexDirection: 'row', marginBottom: 5, borderRadius: 10, justifyContent: "center"
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input me-2"
                                                                                id={"photo" + idx}
                                                                                onChange={(e) => { this.onChangePhotoReq(e, data) }}
                                                                                checked={data.enable_img}
                                                                            />
                                                                            <label
                                                                                className={data.enable_img ? "form-check-label text-primary" : "form-check-label text-Dark"}
                                                                                htmlFor={"photo" + idx}
                                                                            >
                                                                                Photos
                                                                            </label>
                                                                        </div>
                                                                        {
                                                                            data.enable_img ?
                                                                                <div style={{ display: 'flex', flexDirection: 'column', }} >
                                                                                    <div className="row m-0 " >

                                                                                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 5, borderRadius: 10, justifyContent: "center", alignItems: "center" }} className='bg-light py-2'>
                                                                                            <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                                                                <div className="form-check form-check-primary me-2" >
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        id={"videocam" + idx}
                                                                                                        checked={data.enable_cam}
                                                                                                        onChange={(e) => { data.enable_cam = e.target.checked; this.setState({ refresh: true }) }}
                                                                                                        data-validate={{ required: { value: data.enable_img } }}
                                                                                                    />
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"videocam" + idx}
                                                                                                    >
                                                                                                        Camera
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="form-check form-check-primary">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        id={"videogal" + idx}
                                                                                                        checked={data.enable_gallery}
                                                                                                        onChange={(e) => { data.enable_gallery = e.target.checked; this.setState({ refresh: true }) }}
                                                                                                    />
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"videogal" + idx}
                                                                                                    >
                                                                                                        Gallery
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            {data.enable_img && (!data.enable_gallery && !data.enable_cam) ? <div style={{ marginLeft: 7 }} >
                                                                                                <span className="font-size-10 text-danger" style={{ lineHeight: 1, }}>Select any one </span>
                                                                                            </div> : null}
                                                                                        </div>
                                                                                        {
                                                                                            (data.enable_gallery || data.enable_cam) &&
                                                                                            <div className='bg-light py-2 d-flex justify-content-center ' style={{ marginBottom: 5, borderRadius: 10, }} >
                                                                                                <div className='form-floating' style={{ width: "100%" }}>
                                                                                                    <input
                                                                                                        className='form-control'
                                                                                                        name={"iCount" + idx}
                                                                                                        type="number"
                                                                                                        id="numberOfImages"

                                                                                                        // errorMessage={data.no_of_img == 0?"0 is not valid": data.no_of_img == ""?"Enter No. of Photos":data.no_of_img >(this.props.edit ?this.props.config_data.max_photos :this.state.config_data.max_photos ) ?"Maximum No.of.photos Allowed is " + this.props.edit ?this.props.config_data.max_photos :this.state.config_data.max_photos :"The field is invalid"}
                                                                                                        value={data.no_of_img}
                                                                                                        min={1}
                                                                                                        max={this.props.edit ? this.props.config_data.max_photos : this.state.config_data.max_photos}
                                                                                                        onChange={(e) => { this.props.checkpointinfo.checkpoint_options[idx].no_of_img = parseInt(e.target.value); this.setState({ refresh: true }) }}
                                                                                                    // validate={{
                                                                                                    //     required: { value: data.enable_img && String(data.no_of_img) == "0" ? true:false, },
                                                                                                    //     pattern: { value: "^[0-9]+$"}
                                                                                                    // }}
                                                                                                    />
                                                                                                    <label htmlFor="numberOfImages" >No. of Photos</label>
                                                                                                </div>


                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            this.props.checkpointinfo.checkpoint_options[idx].no_of_img > this.state.config_data?.max_photos &&
                                                                                            <div className='col text-danger' style={{ fontSize: "smaller" }}>
                                                                                                Maximum No.of.photos Allowed is {Number(this.state.config_data?.max_photos)}
                                                                                            </div>
                                                                                        }
                                                                                    </div>

                                                                                </div> : null}


                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div style={{ display: 'flex', flexDirection: 'column', }} >
                                                                        <div
                                                                            className="form-check form-switch form-switch-sm bg-light  py-1"
                                                                            style={{
                                                                                display: 'flex', flexDirection: 'row', marginBottom: 5, borderRadius: 10, justifyContent: "center"
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input me-2"
                                                                                id={"video" + idx}
                                                                                onChange={(e) => { this.onChangeVideoReq(e, data) }}
                                                                                checked={data.enable_video}
                                                                            />
                                                                            <label
                                                                                className={data.enable_video ? "form-check-label text-primary" : "form-check-label text-Dark"}
                                                                                htmlFor={"video" + idx}
                                                                            >
                                                                                Videos
                                                                            </label>
                                                                        </div>
                                                                        {
                                                                            data.enable_video ?
                                                                                <div style={{ display: 'flex', flexDirection: 'column', }}>

                                                                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 5, borderRadius: 10, justifyContent: "center", alignItems: "center" }} className='bg-light py-2'>
                                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                            <div className="form-check form-check-primary me-2" >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="form-check-input"
                                                                                                    id={"cam" + idx}
                                                                                                    checked={data.enable_video_cam}
                                                                                                    onChange={(e) => { data.enable_video_cam = e.target.checked; this.setState({ refresh: true }) }}
                                                                                                    data-validate={{ required: { value: data.enable_video } }}
                                                                                                />
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor={"cam" + idx}
                                                                                                >
                                                                                                    Camera
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="form-check form-check-primary">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="form-check-input"
                                                                                                    id={"gal" + idx}
                                                                                                    checked={data.enable_video_gallery}
                                                                                                    onChange={(e) => { data.enable_video_gallery = e.target.checked; this.setState({ refresh: true }) }}
                                                                                                />
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor={"gal" + idx}
                                                                                                >
                                                                                                    Gallery
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {data.enable_video && (!data.enable_video_gallery && !data.enable_video_cam) ? <div style={{ marginLeft: 7 }} >
                                                                                            <span className="font-size-10 text-danger" style={{ lineHeight: 1, }}>Select any one </span>
                                                                                        </div> : null}
                                                                                    </div>
                                                                                    {data.enable_video && (data.enable_video_gallery || data.enable_video_cam) &&
                                                                                        <div className='bg-light p-2 d-flex flex-row' style={{ marginBottom: 5, borderRadius: 10, }} >
                                                                                            <div className='form-floating me-2' style={{ width: "48%" }}>
                                                                                                <input
                                                                                                    name={"iCount" + idx}
                                                                                                    type="number"
                                                                                                    id="noOfVideos"
                                                                                                    className='form-control w-100'
                                                                                                    // errorMessage={data.no_of_video == 0 ? "0 is not valid" : data.no_of_video == "" ? "Enter No. of Photos" : data.no_of_video > (this.props.edit ? this.props.config_data.max_photos : this.state.config_data.max_photos) ? "Maximum No.of.photos Allowed is " + this.props.edit ? this.props.config_data.max_photos : this.state.config_data.max_photos : "The field is invalid"}
                                                                                                    value={data.no_of_video === undefined || data.no_of_video === null ? 1 : data.no_of_video}
                                                                                                    style={{ width: 80, }}
                                                                                                    min={1}
                                                                                                    max={this.props.edit ? this.props.config_data.max_photos : this.state.config_data.max_photos}
                                                                                                    onChange={(e) => { this.props.checkpointinfo.checkpoint_options[idx].no_of_video = parseInt(e.target.value); this.setState({ refresh: true }) }}
                                                                                                // validate={{
                                                                                                //     required: { value: data.enable_video && String(data.no_of_video) == "0" ? true : false, },
                                                                                                //     pattern: { value: "^[0-9]+$" }
                                                                                                // }}
                                                                                                />
                                                                                                <label htmlFor='noOfVideos' >No. of Videos</label>
                                                                                            </div>
                                                                                            {
                                                                                                this.props.checkpointinfo.checkpoint_options[idx].no_of_video > Number(this.state.config_data?.max_photos) &&
                                                                                                <div className='text-danger' style={{ fontSize: "smaller" }}>
                                                                                                    Maximum video Allowed is {Number(this.state.config_data.max_photos)}
                                                                                                </div>
                                                                                            }
                                                                                            <div className='form-floating' style={{ width: "48%" }}>

                                                                                                <input
                                                                                                    name={"iCount" + idx}
                                                                                                    type="number"
                                                                                                    className='form-control w-100'
                                                                                                    id="videoDuration"
                                                                                                    // errorMessage={data.default_video_duration > Number(this.state.max_video_duration) ? "Exceeds" :data.default_video_duration }
                                                                                                    // errorMessage={data.default_video_duration == 0 ? "0 is not valid" : data.default_video_duration == "" ? "Enter No. of Photos" : data.default_video_duration > (this.props.edit ? Number(this.props.config_data.max_video_duration) : this.state.max_video_duration) ? "Maximum No.of.photos Allowed is " + this.props.edit ? Number(this.props.config_data.max_video_duration) : this.state.max_video_duration : "The field is invalid"}
                                                                                                    value={data.default_video_duration}
                                                                                                    style={{ width: 80, }}
                                                                                                    min={1}
                                                                                                    max={this.props.edit ? Number(this.props.config_data.max_video_duration) : Number(this.state.max_video_duration)}
                                                                                                    onChange={(e) => { this.props.checkpointinfo.checkpoint_options[idx].default_video_duration = parseInt(e.target.value); this.setState({ refresh: true }) }}
                                                                                                // validate={{
                                                                                                //     required: { value: data.enable_video && String(data.no_of_video) == "0" ? true : false, },
                                                                                                //     pattern: { value: "^[0-9]+$" }
                                                                                                // }}
                                                                                                />
                                                                                                <label htmlFor='videoDuration' >Duration(Minutes)</label>
                                                                                            </div>
                                                                                            {
                                                                                                console.log(this.state.max_video_duration, 'this.state.max_video_duration')
                                                                                            }
                                                                                            {
                                                                                                this.props.checkpointinfo.checkpoint_options[idx].default_video_duration > Number(this.state.max_video_duration) &&
                                                                                                <div className='text-danger' style={{ fontSize: "smaller" }}>
                                                                                                    Maximum video Duration Allowed is {Number(this.state.max_video_duration)}
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    }

                                                                                </div> : null}
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            {/* --------------------- */}
                                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, borderBottom: '1px solid #f0f0f0', paddingBottom: 5, marginTop: 10 }} >

                                                                <div className='col-6'>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', }} >

                                                                        <div
                                                                            className="form-check form-switch form-switch-sm bg-light  py-1"
                                                                            style={{
                                                                                display: 'flex', flexDirection: 'row', marginBottom: 5, borderRadius: 10, justifyContent: "center"
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input me-2"
                                                                                id={"score" + idx}
                                                                                onChange={(e) => { this.onChangeScoreReq(e, data) }}

                                                                                checked={data.enable_score}
                                                                            />
                                                                            <label
                                                                                className={data.enable_score ? "form-check-label text-primary" : "form-check-label text-Dark"}
                                                                                htmlFor={"score" + idx}
                                                                            >
                                                                                Score
                                                                            </label>
                                                                        </div>
                                                                        {
                                                                            data.enable_score ?
                                                                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 5, borderRadius: 10, justifyContent: "center", alignItems: "center" }} className='bg-light py-2'>
                                                                                    <div className='form-floating'>

                                                                                        <input
                                                                                            name={"iscore" + idx}
                                                                                            type="number"
                                                                                            id="scoreValue"
                                                                                            className="form-control"
                                                                                            // errorMessage={this.props.edit ? this.props.config_data.max_score_value : this.state.config_data.max_score_value < String(data.score) ? "Number exceeds more than alloted score value" : "Enter Score"}
                                                                                            value={String(data.score)}
                                                                                            max={this.props.edit ? this.props.config_data.max_score_value : this.state.config_data.max_score_value}
                                                                                            onChange={(e) => { this.props.checkpointinfo.checkpoint_options[idx].score = parseInt(e.target.value); this.setState({ refresh: true }) }}
                                                                                            onKeyDown={(e) => { this.handleKeypress(e) }}
                                                                                            required
                                                                                        />
                                                                                        <label htmlFor='scoreValue'>Value</label>
                                                                                    </div>

                                                                                    {
                                                                                        <div className='text-danger'>{this.state.errorMessage}</div>
                                                                                    }


                                                                                </div> : null

                                                                        }

                                                                    </div>
                                                                </div>

                                                                <div className='col-6'>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                                        <div
                                                                            className="form-check form-switch form-switch-sm bg-light  py-1"
                                                                            style={{
                                                                                display: 'flex', flexDirection: 'row', marginBottom: 5, borderRadius: 10, justifyContent: "center"
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input me-2"
                                                                                id={"nc" + idx}
                                                                                onChange={(e) => { this.onChangeActionReq(e, data) }}

                                                                                checked={data.enable_nc}
                                                                            />
                                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                <label
                                                                                    className={data.enable_nc ? "form-check-label text-primary" : "form-check-label text-Dark"}
                                                                                    htmlFor={"nc" + idx}
                                                                                >
                                                                                    CAPA <span className="font-size-10" style={{ lineHeight: 1 }}>Corrective and Preventive Actions</span>
                                                                                </label>

                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            data.enable_nc ?
                                                                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 5, borderRadius: 10, justifyContent: "center", alignItems: "center" }} className='bg-light py-2'>
                                                                                    <div className="form-check form-check-primary " >
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input "
                                                                                            id={"ncm" + idx}
                                                                                            checked={data.nc_mandatory}
                                                                                            onChange={(e) => { data.nc_mandatory = e.target.checked; this.setState({ refresh: true }) }}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor={"ncm" + idx}
                                                                                        >
                                                                                            Mandatory
                                                                                        </label>
                                                                                    </div>
                                                                                </div> : null
                                                                        }

                                                                    </div>

                                                                </div>

                                                            </div>

                                                            {/* --------------------- */}
                                                            <div style={{ display: 'flex', flexDirection: 'column', borderBottom: '1px solid #f0f0f0', paddingBottom: 10, marginTop: 10 }} >
                                                                <div className="" style={{}}>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                                                        <div
                                                                            className="form-check form-switch form-switch-sm"
                                                                            style={{
                                                                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                                                paddingRight: 15, borderRight: '1px solid #f0f0f0', height: 36, width: "25%"
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input me-2"
                                                                                id={"doc" + idx}
                                                                                onChange={(e) => { this.onChangeDocReq(e, data) }}

                                                                                checked={data.enable_doc}
                                                                            />
                                                                            <label
                                                                                className={data.enable_doc ? "form-check-label text-primary" : "form-check-label text-Dark"}
                                                                                style={{ margin: 0 }}
                                                                                htmlFor={"doc" + idx}
                                                                            >Documents
                                                                            </label>
                                                                        </div>
                                                                        {
                                                                            data.enable_doc ?
                                                                                <div
                                                                                    style={{ width: "75%", flexDirection: 'row', paddingRight: 15, borderRight: '1px solid #f0f0f0', }}
                                                                                >

                                                                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 7 }}>
                                                                                        <TagsInput
                                                                                            value={data.documents}
                                                                                            onChange={(e) => this.handleChange(e, data)}
                                                                                            className={data.documents.length === 0 ? `${"form-control react-tagsinput-false bg-white"}` : `${"form-control react-tagsinput-true bg-white"}`}
                                                                                            inputProps={
                                                                                                {
                                                                                                    style: { width: "100%" },
                                                                                                    placeholder: 'Input document name and hit enter'
                                                                                                }
                                                                                            }
                                                                                            maxTags={this.props.edit ? this.props.config_data.max_documents : this.state.config_data.max_documents}
                                                                                        />
                                                                                        {data.documents.length == 0 ? <div  >
                                                                                            <span className="font-size-10 text-danger" style={{ lineHeight: 1, }}>Enter minimum one document name</span>
                                                                                        </div> :
                                                                                            null}

                                                                                        {
                                                                                            data.documents.length === (this.props.edit ? this.props.config_data.max_documents : this.state.config_data.max_documents) ?
                                                                                                <div  >
                                                                                                    <span className="font-size-10 text-danger" style={{ lineHeight: 1, }}>exceeded maximum alloted document {this.props.edit ? this.props.config_data.max_documents : this.state.config_data.max_documents}</span>
                                                                                                </div> :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                </div> : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </div> : null}
                                                </div>
                                            </Col>
                                        )
                                    }
                                    )
                                }
                            </Row>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}
