import PropTypes from "prop-types";
import React from "react";
import { Col, Card, CardBody, Row } from "reactstrap";
import { Link, withRouter } from "react-router-dom"


const MiniWidget = ({ reports,total_user,user_data }) => {
  // var role_name = new RegExp('^' +user_data.role_name + '$', 'i')
  // const isRoleNameMatch = role_name.test("AUDITER");


  return (
      <Row className="px-2">
      {reports.map((report, key) => (
        <div key={key} className="col-4 gx-1" >
          <Link to={report.url}>
            <Card className="mb-1">
            <CardBody >
              <div className="d-flex align-items-center mb-3">
                <div className="avatar-xs me-3">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    <i className={report.icon} />
                  </span>
                </div>
                <h5 className="font-size-14 mb-0">{report.title}</h5>
              </div>
              <div className="text-muted mt-4"  >
                <h4 style={{textAlign:'center'}}>
                  {report.value}{" "}
                  {/* <i className="mdi mdi-chevron-up ms-1 text-success" /> */}
                </h4>
                <div className="d-flex">
                  <span
                    className={
                      "badge badge-soft-" + report.color + " font-size-12"
                    }
                  >
                    {" "}
                    {report.badgeValue}{" "}
                  </span>{" "}
                  <span className="ms-2 text-truncate">{report.desc}</span>
                </div>
              </div>
            </CardBody>
          </Card></Link>
        </div>
      ))}
      </Row>
  )
}

MiniWidget.propTypes = {
  reports: PropTypes.array,
}

export default MiniWidget;
