import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
    Container, Row, Col, Button, Alert, Input, Card, CardBody,
    Modal, ModalHeader, ModalBody,
    Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
} from "reactstrap"
import ReactDrawer from 'react-drawer';
import { DatePicker, Drawer, Badge } from 'antd';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import SweetAlert from "react-bootstrap-sweetalert"
import ParentChildBtn from "../../../components/ParentChildBtn"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import InputTemplate from "./Components/InputTemplate"
import 'react-drawer/lib/react-drawer.css';
import facility from '../FacilityScreen'
import $ from 'jquery';
import MetaTags from 'react-meta-tags';

import SortableTree, {
    addNodeUnderParent,
    removeNodeAtPath,
    toggleExpandedForAll,
    getTreeFromFlatData,
    getNodeAtPath,
    changeNodeAtPath,
    getFlatDataFromTree,
    walk,
    map, find
} from '@nosferatu500/react-sortable-tree';
import "@nosferatu500/react-sortable-tree/style.css";
//import SortableTree from 'react-sortable-tree';
//import 'react-sortable-tree/style.css';
//import './react-tree-style.css';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
//import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';



import "react-perfect-scrollbar/dist/css/styles.css"
import "./manageAudit.css"
import urlSocket from "../../../helpers/urlSocket";
import "react-rangeslider/lib/index.css"
const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")

export default class editPublishedTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: props.height,
            successmessage: false,
            changeToUpdate: false,
            dataLoaded: false,
            open: false,
            position: 'right',
            height: props.height,
            successmsgshow: false,
            nodeChildren: [],
            nodeTitle: "",
            nodeSelected:false,
            treeData:[],
        }
        this.toggle = this.toggle.bind(this)
        this.childtoggle = this.childtoggle.bind(this)
        this.handleUserClicks = this.handleUserClicks.bind(this)

        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.setPosition = this.setPosition.bind(this);
    }

    async componentDidMount() {
        var templateData = JSON.parse(sessionStorage.getItem("EditPublishedData"))
        console.log('templateData', templateData)
        var navigationMode = sessionStorage.getItem("nagiationMode");
        var data = JSON.parse(sessionStorage.getItem("authUser"))
        var db_info = JSON.parse(sessionStorage.getItem("db_info"))
        var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"))


        var protect_routes = await facility(user_facilities,'mngpblhtempt')
        if(protect_routes !== undefined){
        this.setState({
            height: window.innerHeight,
            configData: data.config_data,
            userData: data.user_data,
            templateData,
            template_name: templateData.template_name,
            template_info: templateData.template_info,
            configdatacheckpoint: data.config_data.question_type_info,
            navigationMode,
            dataLoaded: false,
            db_info:db_info
        },
            function () {
                this.getDocuments()
            }
        )
    }
    else{
        this.props.history.push('/dashboard')
    }
    }
    handleValidUserSubmit = (event, values) => {
        console.log('values', values, event)
        if (this.state.audit_name_exist !== true) {
            try {
                urlSocket.post("webmngpbhtmplt/crudpublishedtemplate", {
                    templateInfo: {
                        template_id: this.state.templateData._id,
                        template_name: values.template_name.trim(),
                        template_info: values.template_info
                    },
                    userInfo: {
                        created_by: this.state.userData._id,
                        company_id: this.state.userData.company_id,
                        encrypted_db_url: this.state.db_info.encrypted_db_url
                    }
                })
                    .then((response) => {
                        console.log('response', response, this.state.template_name, this.state.template_info)
                        if (response.data.response_code == 500) {
                            this.setState({
                                successmessage: true,
                                updateTemplateStatus: response.data.message,
                                changeToUpdate: false,
                                template_name: this.state.template_name,
                                template_info: this.state.template_info,
                            })
                            setTimeout(() => { this.setState({ successmessage: false, }) }, 2500)
                        }
                    })
            } catch (error) {
                console.log('error', error)
            }
        }
    }


    navigateTo=()=>
    {
        if(this.state.navigationMode == "from_config")
        {
            sessionStorage.removeItem("nagiationMode");
            this.props.history.push("/pblhcfg")
        }
        else
        {
            sessionStorage.removeItem("nagiationMode");
            this.props.history.push("/mngpblhtempt")
        }
    }

    treeDataToFlat = () => {
        var getNodeKey = ({ treeIndex }) => treeIndex
        var flatData = getFlatDataFromTree(
            {
                treeData: this.state.treeData,
                getNodeKey,
                ignoreCollapsed: false,
            })

        var explicitData = _.filter(flatData, item => {
            return item
        })

        return explicitData
    }

    saveHStructure = () => {
        var explicitData = this.treeDataToFlat()
        this.saveData(explicitData)
    }

    saveData = (flatData) => {
        this.setState({ saving: true })
        try {
            urlSocket.post("webmngpbhtmplt/savepblhcpstructure", {
                templateInfo: {
                    template_id: this.state.templateData._id,
                    flatData: _.map(flatData, "node"),
                    checkpointlength:_.filter(_.map(flatData, "node"),{type:2}).length,
                    lastGeneratedId: this.state.totalHLength
                },
                userInfo: {
                    created_by: this.state.userData._id,
                    company_id: this.state.userData.company_id,
                    encrypted_db_url : this.state.db_info.encrypted_db_url
                }
            })
                .then((response) => {
                    if (response.data.response_code == 500) {
                        this.setState({ saving: false, successmsgshow: true, alertMessage: response.data.message })
                        setTimeout(() => { this.setState({ successmsgshow: false, }) }, 2500)

                    }
                })
        } catch (error) {

        }
    }


    getDocuments() {
        try {
            urlSocket.post('/webmngpbhtmplt/getpblhtemplatestructure', {
                templateInfo: {
                    template_id: this.state.templateData._id,
                    tmplatedBy: this.state.templateData.tmplt_ctd_by
                },
                userInfo: {
                    encrypted_db_url:this.state.db_info.encrypted_db_url,
                    created_by: this.state.userData._id,
                    company_id: this.state.userData.company_id
                }

            })
                .then(response => {

                    var FlatData = response.data.data.template_structure
                    this.setState(
                        {
                            dataLoaded: FlatData.length > 0 ? false : true,
                            totalHLength: response.data.data.lastGeneratedId,
                            audit_info : response.data.data
                        },
                        function () {
                            this.convertFlatDataToTreeData(FlatData)
                        }
                    )
                })
                .catch(error => {

                })
        } catch (error) {
            console.log("catch error", error)
        }
    }

    convertFlatDataToTreeData = (FlatData) => {

        this.setState({
            treeData: getTreeFromFlatData(
                {
                    flatData: FlatData,
                    getKey: (node) => node.id,
                    getParentKey: (node) => node.parent,
                    rootKey: null,
                    expanded: true
                }),
            dataLoaded: true,
        }
        )
    }




    addMainNode(type) {

        const getNodeKey = ({ treeIndex }) => treeIndex;
        this.setState({
            path: [],
            getNodeKey: getNodeKey,
            crud: true,
            crudStatus: 0,
            type,
        })
    }

    addParameterAsMain(type,) {

        const getNodeKey = ({ treeIndex }) => treeIndex;
        this.setState({
            path: [],
            getNodeKey: getNodeKey,
            crud: false,
            crudStatus: 0,
            type,
            position: 'right',
            mainToggle: false,
            open: !this.state.open,
            //qTypeInfo: this.state.configData.question_type_info,
            configdatacheckpoint: this.state.configData.question_type_info
        })
    }

    addCheckpoint = (node, path, getNodeKey, type, udp, dp) => {
        this.setState({ position: 'right' });
        this.setState({ open: !this.state.open });
        $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
        $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

        var getNodeInfo = getNodeAtPath({
            treeData: this.state.treeData,
            path,
            getNodeKey,
        })
        this.setState({
            type,
            path: path,
            getNodeKey: getNodeKey,
            crud: false,
            editcrud: false,
            crudStatus: 1,
            title: getNodeInfo.node.title,
            getTitle: "",
            getSubTitle: "",
            getSubTitledd: "0",
            id: getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0 ? getNodeInfo.node.id : getNodeInfo.node.children.length + 1,
            parent: getNodeInfo.node.id,
            mode: "0",
            //checkpointInfo: this.state.configData.question_type_info,
            configdatacheckpoint: this.state.configData.question_type_info
        })
    }

    AECheckPointUnderNode(values, mode) {
        this.setState({ open: !this.state.open, })
        if (mode == "0") {
            this.addCheckPointUnderNode(values)
        }
        else
            if (mode == "1") {
                this.editCheckpointAtPath(values)
            }
    }

    addCheckPointUnderNode(values) {
        const maxValue = values.checkpoint_options.reduce((max, obj) => (obj.score > max ? obj.score : max), -Infinity);
        const minValue = values.checkpoint_options.reduce((min, obj) => (obj.score < min ? obj.score : min), Infinity);

        values["max_score"]=maxValue
        values["min_score"]=minValue
        this.setState(state => ({
            treeData: addNodeUnderParent({
                treeData: state.treeData,
                parentKey: this.state.path[this.state.path.length - 1],
                expandParent: true,
                getNodeKey: this.state.getNodeKey,
                newNode: {
                    "company_id": this.state.userData.company_id,
                    "owner_id": this.state.userData._id,
                    "template_id": this.state.templateData._id,
                    "document_id": this.state.totalHLength + 1,
                    "parent_id": this.state.parent,
                    "document_type": String(this.state.type),
                    "checkpoint": values.checkpoint,
                    "checkpoint_type_id": values.checkpoint_type_id,
                    "checkpoint_type": values.checkpoint_type,
                    "enable_addOptns": values.enable_addOptns,
                    "checkpoint_options": values.checkpoint_options,
                    "min_score": values.min_score,
                    "max_score": values.max_score,
                    "custom_tbx": values.custom_tbx,
                    "impact_level": values.impact_level,
                    "compl_type": values.compl_type,
                    "creator_remarks": values.creator_remarks,
                    "guideline": values.guideline,
                    "guideline_image": values.guideline_image,
                    "id": this.state.totalHLength + 1,
                    "parent": this.state.parent,
                    "title": values.checkpoint,
                    "subtitle": "",
                    type: this.state.type,
                },
                //addAsFirstChild: state.addAsFirstChild,
            }).treeData,
            crud: false,
            childToggle: false,
            mainToggle: false,
            editcrud: false,
            totalHLength: this.state.totalHLength + 1,
        }
        ),
            function () {
                this.saveHStructure()
            }
        )
    }

    editCheckpoint = (path, getNodeKey, udp, dp) => {

        this.setState({ position: 'right' });
        this.setState({ open: !this.state.open });
        $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
        $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

        var getNodeInfo = getNodeAtPath({
            treeData: this.state.treeData,
            path,
            getNodeKey,
        })
        console.log(getNodeInfo.node,'getNodeInfo.node')

        this.setState({
            crud: false,
            editcrud: false,
            crudStatus: 2,
            path: path,
            getNodeInfo: getNodeInfo.node,
            getNodeKey: getNodeKey,
            getCode: getNodeInfo.node.code,
            getTitle: getNodeInfo.node.title,
            type: getNodeInfo.node.type,
            mode: "1",
            checkpointInfo: [getNodeInfo.node],
        })

    }

    editCheckpointAtPath(values) {

        const maxValue = values.checkpoint_options.reduce((max, obj) => (obj.score > max ? obj.score : max), -Infinity);
        const minValue = values.checkpoint_options.reduce((min, obj) => (obj.score < min ? obj.score : min), Infinity);

        values["max_score"]=maxValue
        values["min_score"]=minValue
        this.setState(state => ({
            treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: this.state.path,
                expandParent: true,
                newNode: {
                    children: this.state.getNodeInfo.children,
                    "company_id": values.company_id,
                    "owner_id": values.owner_id,
                    "template_id": values.template_id,
                    "document_id": values.document_id,
                    "parent_id": values.parent_id,
                    "document_type": values.document_type,
                    "checkpoint": values.checkpoint,
                    "checkpoint_type_id": values.checkpoint_type_id,
                    "checkpoint_type": values.checkpoint_type,
                    "enable_addOptns": values.enable_addOptns,
                    "checkpoint_options": values.checkpoint_options,
                    "min_score": values.min_score,
                    "max_score": values.max_score,
                    "custom_tbx": values.custom_tbx,
                    "impact_level": values.impact_level,
                    "compl_type": values.compl_type,
                    "creator_remarks": values.creator_remarks,
                    "guideline": values.guideline,
                    "guideline_image": values.guideline_image,
                    "id": values.id,
                    "parent": values.parent,
                    "title": values.checkpoint,
                    "subtitle": "",
                    type: this.state.type,
                },
                getNodeKey: this.state.getNodeKey,
            }),
            crud: false,
            editcrud: false,
            childToggle: false,
            mainToggle: false,
        }),
            function () {
                this.saveHStructure()
            }
        )
    }

    addNode = (node, path, getNodeKey, type) => {
        var getNodeInfo = getNodeAtPath({
            treeData: this.state.treeData,
            path,
            getNodeKey,
        })
        this.setState({
            type,
            path: path,
            getNodeKey: getNodeKey,
            crud: true,
            editcrud: false,
            crudStatus: 1,
            title: getNodeInfo.node.title,
            getTitle: "",
            getSubTitle: "",
            getSubTitledd: "0",
            id: getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0 ? getNodeInfo.node.id : getNodeInfo.node.children.length + 1,
            parent: getNodeInfo.node.id,
        })
    }

    editNode = (path, getNodeKey) => {

        var getNodeInfo = getNodeAtPath({
            treeData: this.state.treeData,
            path,
            getNodeKey,
        })

        this.setState({
            crud: false,
            editcrud: true,
            crudStatus: 2,
            path: path,
            getNodeInfo: getNodeInfo.node,
            getNodeKey: getNodeKey,
            getCode: getNodeInfo.node.code,
            getTitle: getNodeInfo.node.title,
            type: getNodeInfo.node.type,
            id: getNodeInfo.node.id,
            parent: getNodeInfo.node.parent,
        })

    }

    deleteNode = async (node, path, getNodeKey, udp, dp) => {
        $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
        $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

        this.setState(state => ({
            treeData: removeNodeAtPath({
                treeData: state.treeData,
                path,
                getNodeKey,
            }),
            crud: false,
        }),
            function () {
                this.saveHStructure()
            }
        )
    }

    crudNode = (event, values) => {

        this.onDrawerClose()
        if (this.state.crudStatus == 1) { //Add new node
            this.setState(state => ({
                treeData: addNodeUnderParent({
                    treeData: state.treeData,
                    parentKey: this.state.path[this.state.path.length - 1],
                    expandParent: true,
                    getNodeKey: this.state.getNodeKey,
                    newNode: {
                        "company_id": this.state.userData.company_id,
                        "owner_id": this.state.userData._id,
                        "template_id": this.state.templateData._id,
                        "document_id": this.state.totalHLength + 1,
                        "parent_id": this.state.parent,
                        "document_type": String(this.state.type),
                        "checkpoint": values.title,
                        "checkpoint_type_id": null,
                        "checkpoint_type": null,
                        "enable_addOptns": false,
                        "checkpoint_options": [

                        ],
                        "min_score": 0,
                        "max_score": 0,
                        "custom_tbx": [

                        ],
                        "impact_level": null,
                        "compl_type": [

                        ],
                        "creator_remarks": " ",
                        "guideline": "",
                        "guideline_image": [

                        ],
                        "id": this.state.totalHLength + 1,
                        "parent": this.state.parent,
                        "title": values.title,
                        "subtitle": "",
                        type: this.state.type,
                    },
                    addAsFirstChild: state.addAsFirstChild,
                }).treeData,
                crud: false,
                childToggle: false,
                mainToggle: false,
                editcrud: false,
                totalHLength: this.state.totalHLength + 1,
            }
            ),
                function () {
                    this.saveHStructure()
                }
            )
        }
        else
            if (this.state.crudStatus == 2) // Edit node
            {
                this.setState(state => ({
                    treeData: changeNodeAtPath({
                        treeData: this.state.treeData,
                        path: this.state.path,
                        expandParent: true,
                        newNode: {
                            children: this.state.getNodeInfo.children,
                            "company_id": this.state.getNodeInfo.company_id,
                            "owner_id": this.state.getNodeInfo.owner_id,
                            "template_id": this.state.getNodeInfo.template_id,
                            "document_type": this.state.getNodeInfo.document_type,
                            "document_id": this.state.getNodeInfo.document_id,
                            "parent_id": this.state.getNodeInfo.parent_id,
                            "checkpoint": values.title,
                            "checkpoint_type_id": this.state.getNodeInfo.checkpoint_type_id,
                            "checkpoint_type": this.state.getNodeInfo.checkpoint_type,
                            "enable_addOptns": this.state.getNodeInfo.enable_addOptns,
                            "checkpoint_options": this.state.getNodeInfo.checkpoint_options,
                            "min_score": this.state.getNodeInfo.min_score,
                            "max_score": this.state.getNodeInfo.max_score,
                            "custom_tbx": this.state.getNodeInfo.custom_tbx,
                            "impact_level": this.state.getNodeInfo.impact_level,
                            "compl_type": this.state.getNodeInfo.compl_type,
                            "creator_remarks": this.state.getNodeInfo.creator_remarks,
                            "guideline": this.state.getNodeInfo.guideline,
                            "guideline_image": this.state.getNodeInfo.guideline_image,
                            "title": values.title,
                            "subtitle": "",
                            "id": this.state.getNodeInfo.id,
                            "parent": this.state.getNodeInfo.parent,
                            type: this.state.getNodeInfo.type,
                        },
                        getNodeKey: this.state.getNodeKey,
                    }),
                    crud: false,
                    editcrud: false,
                    childToggle: false,
                    mainToggle: false,
                }),
                    function () {
                        this.saveHStructure()
                    }
                )
            }
            else
                if (this.state.crudStatus == 0) {

                    this.setState(state => ({
                        treeData: addNodeUnderParent({
                            treeData: state.treeData,
                            parentKey: this.state.path[this.state.path.length - 1],
                            expandParent: true,
                            getNodeKey: this.state.getNodeKey,
                            newNode: {
                                "company_id": this.state.userData.company_id,
                                "owner_id": this.state.userData._id,
                                "template_id": this.state.templateData._id,
                                "document_id": this.state.totalHLength + 1,
                                "parent_id": null,
                                "document_type": String(this.state.type),
                                "checkpoint": values.title,
                                "checkpoint_type_id": null,
                                "checkpoint_type": null,
                                "enable_addOptns": false,
                                "checkpoint_options": [

                                ],
                                "min_score": 0,
                                "max_score": 0,
                                "custom_tbx": [

                                ],
                                "impact_level": null,
                                "compl_type": [

                                ],
                                "creator_remarks": [

                                ],
                                "guideline": "",
                                "guideline_image": [

                                ],
                                "id": this.state.totalHLength + 1,
                                "parent": null,
                                "title": values.title,
                                "subtitle": "",
                                "type": this.state.type,

                            },
                            addAsFirstChild: state.addAsFirstChild,
                        }).treeData,
                        crud: false,
                        mainToggle: false,
                        totalHLength: this.state.totalHLength + 1,
                    }
                    ),
                        function () {
                            this.saveHStructure()
                        }
                    )
                }
    }


    dndNode = (droppedNode, getNodeKey) => {
        this.setState(state => ({
            treeData: changeNodeAtPath({
                treeData: state.treeData,
                path: droppedNode.path,
                getNodeKey,
                newNode: {
                    'parent_id': droppedNode.nextParentNode && droppedNode.nextParentNode !== undefined  !== null ? droppedNode.nextParentNode.id : null,
                    'parent': droppedNode.nextParentNode !== null && droppedNode.nextParentNode !== undefined  ? droppedNode.nextParentNode.id : null,
                    "company_id": droppedNode.node.company_id,
                    "owner_id": droppedNode.node.owner_id,
                    "template_id": droppedNode.node.template_id,
                    "document_id": droppedNode.node.document_id,
                    // "parent_id": droppedNode.nextParentNode.id,
                    // "parent": droppedNode.nextParentNode.id,
                    "document_type": droppedNode.node.document_type,
                    "checkpoint": droppedNode.node.checkpoint,
                    "checkpoint_type_id": droppedNode.node.checkpoint_type_id,
                    "checkpoint_type": droppedNode.node.checkpoint_type,
                    "enable_addOptns": droppedNode.node.enable_addOptns,
                    "checkpoint_options": droppedNode.node.checkpoint_options,
                    "min_score": droppedNode.node.min_score,
                    "max_score": droppedNode.node.max_score,
                    "custom_tbx": droppedNode.node.custom_tbx,
                    "impact_level": droppedNode.node.impact_level,
                    "compl_type": droppedNode.node.compl_type,
                    "creator_remarks": droppedNode.node.creator_remarks,
                    "guideline": droppedNode.node.guideline,
                    "guideline_image": droppedNode.node.guideline_image,
                    "id": droppedNode.node.id,
                    "title": droppedNode.node.title,
                    "subtitle": droppedNode.node.subtitle,
                    type: droppedNode.node.type,
                    children: droppedNode.node.children,
                },
            }),
            crud: false,
        }),
            function () {
                this.saveHStructure()
            }
        )
    }

    audit_name=(e)=>{
        var audit_name = e.target.value

        try {

            urlSocket.post('cog/validate-edit-audit-name',{audit_name,db_url:this.state.db_info.encrypted_db_url,user_id:this.state.userData._id,audit_id :this.state.audit_info._id }).then((response)=>{
              if(response.data.data.length >0){
                this.setState({audit_name_exist : true})
              }
              else{
                this.setState({audit_name_exist : false})
    
              }
    
    
    
            })        
          } catch (error) {
            
          }



    }

    getNodeData(nodeData) {
        this.setState({ nodeTitle: nodeData.type != 2 ? nodeData.title : "" })
        if (nodeData.children != undefined) {
            if (nodeData.children.length != 0) {
                var getNodeKey = ({ treeIndex }) => treeIndex
                var flatData = getFlatDataFromTree(
                    {
                        treeData: nodeData.children,
                        getNodeKey,
                        ignoreCollapsed: false,
                    })

                var explicitData = _.filter(flatData, item => {
                    if (item.node.type == 2)
                        return { node: item.node, parent: item.parentNode }
                })

                this.setState({ nodeChildren: explicitData })
            }
        }
        else {
            if (nodeData.type == 2) {
                this.setState({ nodeChildren: [{ node: nodeData, parentNode: null }] })
            }
            else {
                this.setState({ nodeChildren: [] })
            }
        }
    }






    toggle() {
        this.setState(prevState => ({
            mainToggle: !prevState.mainToggle,
        }))
    }

    childtoggle(udp, dp) {
        $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
        $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")
    }

    handleUserClicks = arg => {
        this.setState({ users: '', isEdit: false })
        this.toggle()
    }

    changeAuditEditMode = (status) => { this.setState({ changeToUpdate: status, showCloseButton: status, modal: !this.state.modal ? true : false }) }

    setPosition(e) {
        this.setState({ position: e.target.value });
    }

    closeDrawer() {
        this.setState({ open: false });
    }
    onDrawerClose() {
        this.setState({ open: false });
    }

    toggleToolTip = targetName => {
        if (!this.state[targetName]) {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: true
                }
            });
        } else {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: !this.state[targetName].tooltipOpen
                }
            });
        }
    };

    isToolTipOpen = targetName => {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    };


   


    render() {

        const { department_name } = this.state
        const { isEdit } = this.state
        if (this.state.dataLoaded) {
            const getNodeKey = ({ treeIndex }) => treeIndex;
            return (
                <React.Fragment>

                    <div className="page-content">
                     


                        <MetaTags>
                            <title>AuditVista | Edit Template</title>
                        </MetaTags>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-1">
                            <div style={{ width: '60%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
                                Edit Template
                            </div>
                            <div style={{ width: '40%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ textAlign: 'right' }}>
                                    <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{paddingLeft:"12px"}}>
                            <div className="left-column" style={{ background: 'white' }}>
                                {/* <Card>
                                        <CardBody style={{}}> */}
                                <div className="mb-2 mt-4">
                                    <UncontrolledDropdown isOpen={this.state.mainToggle} toggle={() => this.toggle()}>
                                        <DropdownToggle
                                            className="btn btn-light w-100 "
                                            color="#eff2f7"
                                            type="button"
                                            onClick={() => this.addMainNode(0)}
                                        >
                                            <i className="mdi mdi-plus me-1 "></i> Create New
                                        </DropdownToggle>
                                        <DropdownMenu style={{ width: 250 }} className="">
                                            <div className="p-4">
                                                <AvForm onValidSubmit={this.crudNode}>
                                                    <div className="my-2">
                                                        <AvField
                                                            name="title"
                                                            label="Menu Name  "
                                                            placeholder="Enter Menu"
                                                            type="text"
                                                            errorMessage="Enter Menu"
                                                            validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
                                                            defaultValue={""}
                                                            onChange={(e) => { this.setState({ menuName: e.target.value }) }}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-primary btn-block m-1"
                                                            type="submit" style={{ marginRight: 5 }}>Add Menu</button>
                                                    </div>
                                                </AvForm>
                                            </div>
                                            {/* <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to="#"
                                                onClick={() => this.addParameterAsMain(2)}
                                            >
                                                <i className="bx bx-file me-1"></i> Add Check point
                                            </Link> */}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                                <div style={{ height: '70vh', overflow: 'auto' }}>
                                    <SortableTree
                                        treeData={this.state.treeData}
                                        onChange={(treeData, node) => {
                                            this.setState({ treeData, crud: false })
                                        }}
                                        onMoveNode={object => this.dndNode(object, getNodeKey)}
                                        // canDrop={object => object.nextParent.type == 2 ? false : true}
                                        canDrop={(object) =>
                                            // console.log(object,'}')
                                            object.nextParent !== undefined && object.nextParent.type == 2
                                              ? false
                                              : true
                                            // }
                                          }
                                        // canDrop={(object) => object.nextParent !== null && object.nextParent.type == 2 ? false : true }
                                        scaffoldBlockPxWidth={25}
                                        slideRegionSize={25}
                                        rowHeight={50}
                                        generateNodeProps={({ node, path }) => ({
                                            listIndex: 0,
                                            lowerSiblingCounts: [],
                                            rowdirection: "rtl",
                                            className: node.type == 0 ? `${"icon-a"}` : `${"icon-b"}`,
                                            onClick: (event) => {
                                                if (event.target.className.includes('collapseButton') || event.target.className.includes('expandButton')) {
                                                }
                                                else {
                                                    this.setState({ selectedNodeId: node.id, nodeSelected: true })
                                                    this.getNodeData(node)
                                                }
                                            },
                                            // style: { fontSize: 14, border: this.state.selectedNodeId == node.id ? "2px solid #f1b44c" : '1px solid #74788d' },
                                            style: {
                                                fontSize: 14,
                                                border: this.state.selectedNodeId === node.id ? "1px solid #74788d" : '1px solid #74788d',
                                                // border: this.state.selectedNodeId === node.id ? "2px solid #556ee6bf" : '1px solid #74788d',
                                                boxShadow: this.state.selectedNodeId === node.id &&  node.type === 0 ?
                                                   "2px 4px 5px 2px rgb(255 165 0 / 40%)" :
                                                   this.state.selectedNodeId === node.id &&  node.type !== 0 ?
                                                    "2px 4px 5px 2px  rgb(85 110 230 / 52%)" :
                                                  ''
                                              },
                                            title: (
                                                <div >
                                                    <div style={{ maxWidth: 350, }} key={`div-${node.id}`} >

                                                        <div>
                                                            <Tooltip
                                                                placement="bottom"
                                                                isOpen={this.isToolTipOpen(`btn-${node.id}`)}
                                                                target={`btn-${node.id}`}
                                                                toggle={() => this.toggleToolTip(`btn-${node.id}`)}
                                                            >
                                                                {node.title}
                                                            </Tooltip>
                                                            <Link
                                                                to="#"
                                                                id={`btn-${node.id}`}
                                                                style={{ fontSize: 12, fontWeight: 500 }}
                                                            // onClick={()=>{}
                                                            >
                                                                {String(node.title).slice(0, 20) + (node.title.length > 20 ? "..." : "")}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                            ),

                                            buttons: [
                                                <div key={""}>

                                                    <UncontrolledDropdown className="mb-0 bg-white " direction="right"
                                                        id={"udp" + String(node.id)}
                                                    //onClick={()=>this.setState({crud:false, editcrud:false})}
                                                    >
                                                        <DropdownToggle
                                                            color="white"
                                                            className="font-size-16 text-muted"
                                                            // className="font-size-16 text-muted dropdown-toggle"
                                                            tag="a"
                                                            onClick={()=>{this.setState({editcrud : false,crud : false})}}

                                                        >
                                                            <i className="mdi mdi-dots-vertical font-size-16"></i>
                                                        </DropdownToggle>

                                                        <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)} >
                                                            {!this.state.crud && node.type == 0 ? <Link className="dropdown-item " to="#"
                                                                onClick={
                                                                    () => this.addNode(node, path, getNodeKey, 0)
                                                                }
                                                            > Add Sub Menu
                                                            </Link> : null}
                                                            {
                                                                this.state.crud && node.type == 0 ?
                                                                    <div className="px-4">
                                                                        <AvForm onValidSubmit={this.crudNode}>
                                                                            <div className="my-2">
                                                                                <AvField
                                                                                    name="title"
                                                                                    label="Sub Menu Name  "
                                                                                    placeholder="Enter Sub Menu"
                                                                                    type="text"
                                                                                    errorMessage="Enter Menu"
                                                                                    validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
                                                                                    defaultValue={""}
                                                                                    onChange={(e) => { this.setState({ menuName: e.target.value }) }}
                                                                                />
                                                                            </div>
                                                                            <div className="my-3">
                                                                                <button className="btn btn-primary btn-block m-1"
                                                                                    type="submit" onClick={() => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Add Menu</button>
                                                                            </div>
                                                                        </AvForm>
                                                                    </div>
                                                                    : null
                                                            }

                                                            {!this.state.editcrud && node.type == 0 ? <div>
                                                                <div className="dropdown-divider"></div>
                                                                <Link className="dropdown-item" to="#"
                                                                    onClick={
                                                                        () => this.editNode(path, getNodeKey)
                                                                    }
                                                                > Edit Menu
                                                                </Link> </div> : null}
                                                            {
                                                                this.state.editcrud ?
                                                                    <div className="px-4">
                                                                        <AvForm onValidSubmit={this.crudNode}>
                                                                            <div className="my-2">
                                                                                <AvField
                                                                                    name="title"
                                                                                    label="Edit Menu Name  "
                                                                                    placeholder="Enter Menu Name"
                                                                                    type="text"
                                                                                    errorMessage="Enter Menu"
                                                                                    validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
                                                                                    defaultValue={this.state.getTitle}
                                                                                    onChange={(e) => { this.setState({ menuName: e.target.value }) }}
                                                                                />
                                                                            </div>
                                                                            <div className="my-3">
                                                                                <button className="btn btn-primary btn-block m-1"
                                                                                    type="submit" onClick={(event) => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Update</button>
                                                                            </div>
                                                                        </AvForm>
                                                                    </div>
                                                                    : null
                                                            }
                                                            {node.type == 0 ? <div><div className="dropdown-divider"></div>
                                                                <Link className="dropdown-item" to="#"
                                                                    onClick={
                                                                        () => this.addCheckpoint(node, path, getNodeKey, 2, "udp" + String(node.id), "dp" + String(node.id))
                                                                    }
                                                                > Add Check point
                                                                </Link></div> : null}
                                                            {node.type == 2 ? <div><div className="dropdown-divider"></div>
                                                                <Link className="dropdown-item" to="#"
                                                                    onClick={
                                                                        () => this.editCheckpoint(path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))
                                                                    }
                                                                > Edit Check point
                                                                </Link></div> : null}
                                                            <div className="dropdown-divider"></div>
                                                            <Link className="dropdown-item text-danger" to="#"
                                                                onClick={() => this.deleteNode(node, path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))}
                                                            > {node.type != 2 ? "Delete Menu" : "Delete Check point"} <i className="mdi mdi-delete font-size-18"
                                                                style={{ margin: 5 }}
                                                            ></i></Link>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            ]
                                        })}

                                    />
                                </div>
                                {/* </CardBody>
                                    </Card> */}
                            </div>
                            <div className="right-column">
                                <div >
                                    <Alert color="success" isOpen={this.state.successmessage} className="mb-1" ><strong>Well done! </strong> {this.state.updateTemplateStatus}</Alert>
                                    {
                                        this.state.changeToUpdate ?
                                            <Card className="mb-1">
                                                <CardBody>
                                                    <div>
                                                        <AvForm onValidSubmit={this.handleValidUserSubmit} 
                                                        onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("null") }}
                                                        >
                                                            <Row>
                                                                <Col >
                                                                    <div className="my-3">
                                                                        <div >
                                                                            <div className="my-2">
                                                                                <AvField
                                                                                    name="template_name"
                                                                                    label="Audit Name  "
                                                                                    placeholder="Enter Audit Name"
                                                                                    type="text"
                                                                                    errorMessage="Enter Audit Name"
                                                                                    validate={{ required: { value: true }, minLength: { value: 6, errorMessage: "Min 6 chars." } }}
                                                                                    defaultValue={this.state.template_name}
                                                                                    // onChange={(e) => { this.setState({ template_name: e.target.value }) }}
                                                                                    onChange ={(e)=>{this.setState({ template_name: e.target.value });this.audit_name(e)}}
                                                                                />
                                                                                {
                                                                                    this.state.audit_name_exist &&
                                                                                    <div className="text-danger" style={{fontSize : 'smaller'}}>This audit name already exist.</div>
                                                                                }
                                                                            </div>
                                                                            <div className="my-1">
                                                                                <label>Description</label>
                                                                                <AvField type="textarea" name="template_info" id="message" defaultValue={this.state.template_info} onChange={(e) => { this.setState({ template_info: e.target.value }) }} />
                                                                            </div>
                                                                            <div className="my-3">
                                                                                <button className="btn btn-primary btn-block" style={{ marginRight: 5 }}>Update</button>
                                                                                <button className="btn btn-danger btn-block" onClick={(e) => {e.preventDefault(); this.setState({ changeToUpdate: false, template_name : this.state.templateData.template_name,audit_name_exist : false })}}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            :
                                            <div>
                                                <Card className="mb-1">
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="my-1">
                                                                    <h5 className="text-wrap font-size-15">
                                                                        <span className="text-primary">{this.state.template_name}&nbsp;&nbsp;&nbsp;</span>
                                                                        {this.state.templateData.tmplt_ctd_by == "0" ? null : <i className="fas fa-edit" onClick={() => this.changeAuditEditMode(true)}></i>}</h5>
                                                                    <span className="text-muted " >{this.state.template_info} </span>
                                                                </div>
                                                            </Col>
                                                            {/* <Col md={6}>
                                                                <div style={{ textAlign: 'right' }} className="mb-2">
                                                                    <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
                                                                </div>
                                                            </Col> */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                    }
                                </div>
                                <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
                                    {
                                        this.state.nodeSelected ?
                                            <div style={{ height: '100vh', overflow: 'auto' }}>
                                                <div className={this.state.nodeTitle != "" ? 'm-1 mb-1 p-3 bg-white' : ''}>
                                                    <span>{this.state.nodeTitle}</span>
                                                </div>
                                                {

                                                    this.state.nodeChildren.map((item, i) => {
                                                        var getColor = item.node.impact_level == 'Critical' ? '#f46a6a' :
                                                            item.node.impact_level == 'High' ? '#f1b44c' :
                                                                item.node.impact_level == 'Medium' ? '#50a5f1' :
                                                                    item.node.impact_level == 'Low' ? '#34c38f' : '#556ee6'
                                                        return (
                                                            <div className='mb-1 m-1 d-flex flex-column p-3 bg-white' key={"cp" + i} style={{ borderBottom: '1px solid', borderColor: getColor }} >
                                                                <div>
                                                                    <span className='font-size-10 text-secondary'>{item.parentNode != null ? item.parentNode.title : ""}</span>
                                                                </div>
                                                                <div className='my-2'>
                                                                    <span style={{ color: getColor }}>{String(i).length > 1 ? (i + 1) : "0" + (i + 1)}</span>{" "}<span className='font-size-13'>{item.node.checkpoint}</span>
                                                                </div>
                                                                <div className='my-2'>
                                                                    {
                                                                        item.node.checkpoint_options.map((child, idx) => (
                                                                            <div className='d-flex flex-column p-2 my-1' style={{ border: '1px solid #f0f0f0', borderRadius: 10 }} key={"cp" + i + "ptn" + idx}>
                                                                                <span>{child.option_text} {" "} {child.enable_img ? <i className='mdi mdi-camera-plus' /> : null} {" "} {child.enable_doc ? <i className='mdi mdi-file-document' /> : null}</span>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                {
                                                                    item.node.impact_level != "" ?
                                                                        <div className='my-2'>
                                                                            <span className='badge badge-soft p-1 font-size-12' style={{ backgroundColor: getColor }}>{item.node.impact_level}</span>
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </div>
                                            : null
                                            // <div style={{ maxHeight: '70vh', height: '100vh' }}>
                                            //     <Card className="mb-1">
                                            //         <CardBody style={{ height: '100vh' }}>

                                            //         </CardBody>
                                            //     </Card>
                                            // </div>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="full-width-column">
                                <Card className="mb-1">
                                    <CardBody>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: 5 }}>
                                            <div className='col-4' >
                                                <UncontrolledAlert
                                                    color="success"
                                                    className="alert-dismissible fade show"
                                                    role="alert"
                                                    isOpen={this.state.successmsgshow}
                                                    style={{ margin: 0 }}
                                                >
                                                    <i className="mdi mdi-check-all me-2"></i>{this.state.alertMessage}
                                                </UncontrolledAlert>
                                            </div>
                                            <div className="col-4 m-1" >
                                                <Button className="btn btn-primary w-100" onClick={() => this.navigateTo()}> Go back </Button>
                                            </div>
                                            <div className="col-4 m-1" >
                                                <Button className={this.state.treeData.length > 0 ? "btn btn-success w-100" : "btn btn-secondary w-100"} onClick={() => this.navigateTo()} disabled={this.state.treeData.length > 0 ? false : true}> Save </Button>
                                            </div>
                                        </div>

                                    </CardBody>
                                </Card>
                            </div>
                        </div>

                    </div>


                   
                    {
                        this.state.saveAndSuccess && (
                            <SweetAlert
                                title={this.state.messageHeading}
                                danger={this.state.messageHeading == "Delete" ? true : false}
                                success={this.state.messageHeading == "Success" ? true : false}
                                showCancel={this.state.messageHeading == "Success" ? false : true}
                                confirmBtnBsStyle={this.state.messageHeading == "Success" ? "success" : "danger"}
                                cancelBtnBsStyle={this.state.messageHeading == "Delete" ? "success" : "danger"}
                                onConfirm={() => this.confirmation()}
                                onCancel={() => this.setState({ saveAndSuccess: false, })}
                            >{this.state.message}</SweetAlert>
                        )
                    }
                   

                    <ReactDrawer
                        open={this.state.open}
                        className="drawer-open"
                        position={this.state.position}
                        onClose={this.onDrawerClose}
                        style={{ maxHeight: 1000 }}
                        noOverlay={false}
                    >

                        <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>
                            {this.state.open ? <InputTemplate
                                checkpointinfo={this.state.checkpointInfo}
                                configdatacheckpoint={this.state.configData.question_type_info}
                                mode={this.state.mode}
                                parameterData={true}
                                onClose={this.onDrawerClose}
                                onSubmit={(values) => { this.AECheckPointUnderNode(values, this.state.mode) }}
                            /> : null}
                        </div>
                    </ReactDrawer>

                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="page-content" style={{ minHeight: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div
                                className="spinner-border text-secondary m-1"
                                role="status"
                            >  </div>
                            <div>Loading, Please wait.</div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}




























//02-06-23
// import React, { Component } from "react"
// import { Link } from "react-router-dom"
// import {
//     Container, Row, Col, Button, Alert, Input, Card, CardBody,
//     Modal, ModalHeader, ModalBody,
//     Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
// } from "reactstrap"
// import ReactDrawer from 'react-drawer';
// import { DatePicker, Drawer, Badge } from 'antd';
// import { AvForm, AvField } from "availity-reactstrap-validation"
// import Select from "react-select"
// import SweetAlert from "react-bootstrap-sweetalert"
// import ParentChildBtn from "../../components/ParentChildBtn"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
// import InputTemplate from "./Components/InputTemplate"
// import 'react-drawer/lib/react-drawer.css';

// import $ from 'jquery';

// import SortableTree, {
//     addNodeUnderParent,
//     removeNodeAtPath,
//     toggleExpandedForAll,
//     getTreeFromFlatData,
//     getNodeAtPath,
//     changeNodeAtPath,
//     getFlatDataFromTree,
//     walk,
//     map, find
// } from 'react-sortable-tree/dist/index.cjs.js';
// //import SortableTree from 'react-sortable-tree';
// //import 'react-sortable-tree/style.css';
// //import './react-tree-style.css';
// import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
// //import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';



// import "react-perfect-scrollbar/dist/css/styles.css"
// import "./manageAudit.css"

// import "react-rangeslider/lib/index.css"
// const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")

// export default class editPublishedTemplate extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             height: props.height,
//             successmessage: false,
//             changeToUpdate: false,
//             dataLoaded: false,
//             open: false,
//             position: 'right',
//             height: props.height,
//             successmsgshow: false,
//             nodeChildren: [],
//             nodeTitle: "",
//             nodeSelected:false,
//             treeData:[],
//         }
//         this.toggle = this.toggle.bind(this)
//         this.childtoggle = this.childtoggle.bind(this)
//         this.handleUserClicks = this.handleUserClicks.bind(this)

//         this.closeDrawer = this.closeDrawer.bind(this);
//         this.onDrawerClose = this.onDrawerClose.bind(this);
//         this.setPosition = this.setPosition.bind(this);
//     }

//     componentWillMount() {
//         var templateData = JSON.parse(sessionStorage.getItem("EditPublishedData"))
//         var navigationMode = sessionStorage.getItem("nagiationMode");
//         var data = JSON.parse(sessionStorage.getItem("authUser"))
//         var db_info = JSON.parse(sessionStorage.getItem("db_info"))


//         this.setState({
//             height: window.innerHeight,
//             configData: data.config_data,
//             userData: data.user_data,
//             templateData,
//             template_name: templateData.template_name,
//             template_info: templateData.template_info,
//             configdatacheckpoint: data.config_data.question_type_info,
//             navigationMode,
//             dataLoaded: false,
//             db_info:db_info
//         },
//             function () {
//                 this.getDocuments()
//             }
//         )
//     }

//     handleValidUserSubmit = (event, values) => {
//         try {
//             urlSocket.post("webmngpbhtmplt/crudpublishedtemplate", {
//                 templateInfo: {
//                     template_id: this.state.templateData._id,
//                     template_name: values.template_name,
//                     template_info: values.template_info
//                 },
//                 userInfo: {
//                     created_by: this.state.userData._id,
//                     company_id: this.state.userData.company_id
//                 }
//             })
//                 .then((response) => {
//                     if (response.data.response_code == 500) {
//                         this.setState({
//                             successmessage: true,
//                             updateTemplateStatus: response.data.message,
//                             changeToUpdate: false,
//                             template_name: this.state.template_name,
//                             template_info: this.state.template_info,
//                         })
//                         setTimeout(() => { this.setState({ successmessage: false, }) }, 2500)
//                     }
//                 })
//         } catch (error) {
//             console.log('error', error)
//         }
//     }


//     navigateTo=()=>
//     {
//         if(this.state.navigationMode == "from_config")
//         {
//             sessionStorage.removeItem("nagiationMode");
//             this.props.history.push("/pblhcfg")
//         }
//         else
//         {
//             sessionStorage.removeItem("nagiationMode");
//             this.props.history.push("/mngpblhtempt")
//         }
//     }

//     treeDataToFlat = () => {
//         var getNodeKey = ({ treeIndex }) => treeIndex
//         var flatData = getFlatDataFromTree(
//             {
//                 treeData: this.state.treeData,
//                 getNodeKey,
//                 ignoreCollapsed: false,
//             })

//         var explicitData = _.filter(flatData, item => {
//             return item
//         })

//         return explicitData
//     }

//     saveHStructure = () => {
//         var explicitData = this.treeDataToFlat()
//         this.saveData(explicitData)
//     }

//     saveData = (flatData) => {
//         this.setState({ saving: true })
//         try {
//             urlSocket.post("webmngpbhtmplt/savepblhcpstructure", {
//                 templateInfo: {
//                     template_id: this.state.templateData._id,
//                     flatData: _.map(flatData, "node"),
//                     checkpointlength:_.filter(_.map(flatData, "node"),{type:2}).length,
//                     lastGeneratedId: this.state.totalHLength
//                 },
//                 userInfo: {
//                     created_by: this.state.userData._id,
//                     company_id: this.state.userData.company_id
//                 }
//             })
//                 .then((response) => {
//                     if (response.data.response_code == 500) {
//                         this.setState({ saving: false, successmsgshow: true, alertMessage: response.data.message })
//                         setTimeout(() => { this.setState({ successmsgshow: false, }) }, 2500)

//                     }
//                 })
//         } catch (error) {

//         }
//     }


//     getDocuments() {
//         try {
//             urlSocket.post('/webmngpbhtmplt/getpblhtemplatestructure', {
//                 templateInfo: {
//                     template_id: this.state.templateData._id,
//                     tmplatedBy: this.state.templateData.tmplt_ctd_by
//                 },
//                 userInfo: {
//                     encrypted_db_url:this.state.db_info.encrypted_db_url,
//                     created_by: this.state.userData._id,
//                     company_id: this.state.userData.company_id
//                 }

//             })
//                 .then(response => {

//                     var FlatData = response.data.data.template_structure
//                     this.setState(
//                         {
//                             dataLoaded: FlatData.length > 0 ? false : true,
//                             totalHLength: response.data.data.lastGeneratedId
//                         },
//                         function () {
//                             this.convertFlatDataToTreeData(FlatData)
//                         }
//                     )
//                 })
//                 .catch(error => {

//                 })
//         } catch (error) {
//             console.log("catch error", error)
//         }
//     }

//     convertFlatDataToTreeData = (FlatData) => {

//         this.setState({
//             treeData: getTreeFromFlatData(
//                 {
//                     flatData: FlatData,
//                     getKey: (node) => node.id,
//                     getParentKey: (node) => node.parent,
//                     rootKey: null,
//                     expanded: true
//                 }),
//             dataLoaded: true,
//         }
//         )
//     }




//     addMainNode(type) {

//         const getNodeKey = ({ treeIndex }) => treeIndex;
//         this.setState({
//             path: [],
//             getNodeKey: getNodeKey,
//             crud: true,
//             crudStatus: 0,
//             type,
//         })
//     }

//     addParameterAsMain(type,) {

//         const getNodeKey = ({ treeIndex }) => treeIndex;
//         this.setState({
//             path: [],
//             getNodeKey: getNodeKey,
//             crud: false,
//             crudStatus: 0,
//             type,
//             position: 'right',
//             mainToggle: false,
//             open: !this.state.open,
//             //qTypeInfo: this.state.configData.question_type_info,
//             configdatacheckpoint: this.state.configData.question_type_info
//         })
//     }

//     addCheckpoint = (node, path, getNodeKey, type, udp, dp) => {
//         this.setState({ position: 'right' });
//         this.setState({ open: !this.state.open });
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })
//         this.setState({
//             type,
//             path: path,
//             getNodeKey: getNodeKey,
//             crud: false,
//             editcrud: false,
//             crudStatus: 1,
//             title: getNodeInfo.node.title,
//             getTitle: "",
//             getSubTitle: "",
//             getSubTitledd: "0",
//             id: getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0 ? getNodeInfo.node.id : getNodeInfo.node.children.length + 1,
//             parent: getNodeInfo.node.id,
//             mode: "0",
//             //checkpointInfo: this.state.configData.question_type_info,
//             configdatacheckpoint: this.state.configData.question_type_info
//         })
//     }

//     AECheckPointUnderNode(values, mode) {
//         this.setState({ open: !this.state.open, })
//         if (mode == "0") {
//             this.addCheckPointUnderNode(values)
//         }
//         else
//             if (mode == "1") {
//                 this.editCheckpointAtPath(values)
//             }
//     }

//     addCheckPointUnderNode(values) {
//         this.setState(state => ({
//             treeData: addNodeUnderParent({
//                 treeData: state.treeData,
//                 parentKey: this.state.path[this.state.path.length - 1],
//                 expandParent: true,
//                 getNodeKey: this.state.getNodeKey,
//                 newNode: {
//                     "company_id": this.state.userData.company_id,
//                     "owner_id": this.state.userData._id,
//                     "template_id": this.state.templateData._id,
//                     "document_id": this.state.totalHLength + 1,
//                     "parent_id": this.state.parent,
//                     "document_type": String(this.state.type),
//                     "checkpoint": values.checkpoint,
//                     "checkpoint_type_id": values.checkpoint_type_id,
//                     "checkpoint_type": values.checkpoint_type,
//                     "enable_addOptns": values.enable_addOptns,
//                     "checkpoint_options": values.checkpoint_options,
//                     "min_score": values.min_score,
//                     "max_score": values.max_score,
//                     "custom_tbx": values.custom_tbx,
//                     "impact_level": values.impact_level,
//                     "compl_type": values.compl_type,
//                     "creator_remarks": values.creator_remarks,
//                     "guideline": values.guideline,
//                     "guideline_image": values.guideline_image,
//                     "id": this.state.totalHLength + 1,
//                     "parent": this.state.parent,
//                     "title": values.checkpoint,
//                     "subtitle": "",
//                     type: this.state.type,
//                 },
//                 //addAsFirstChild: state.addAsFirstChild,
//             }).treeData,
//             crud: false,
//             childToggle: false,
//             mainToggle: false,
//             editcrud: false,
//             totalHLength: this.state.totalHLength + 1,
//         }
//         ),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     editCheckpoint = (path, getNodeKey, udp, dp) => {

//         this.setState({ position: 'right' });
//         this.setState({ open: !this.state.open });
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })


//         this.setState({
//             crud: false,
//             editcrud: false,
//             crudStatus: 2,
//             path: path,
//             getNodeInfo: getNodeInfo.node,
//             getNodeKey: getNodeKey,
//             getCode: getNodeInfo.node.code,
//             getTitle: getNodeInfo.node.title,
//             type: getNodeInfo.node.type,
//             mode: "1",
//             checkpointInfo: [getNodeInfo.node],
//         })

//     }

//     editCheckpointAtPath(values) {
//         this.setState(state => ({
//             treeData: changeNodeAtPath({
//                 treeData: this.state.treeData,
//                 path: this.state.path,
//                 expandParent: true,
//                 newNode: {
//                     children: this.state.getNodeInfo.children,
//                     "company_id": values.company_id,
//                     "owner_id": values.owner_id,
//                     "template_id": values.template_id,
//                     "document_id": values.document_id,
//                     "parent_id": values.parent_id,
//                     "document_type": values.document_type,
//                     "checkpoint": values.checkpoint,
//                     "checkpoint_type_id": values.checkpoint_type_id,
//                     "checkpoint_type": values.checkpoint_type,
//                     "enable_addOptns": values.enable_addOptns,
//                     "checkpoint_options": values.checkpoint_options,
//                     "min_score": values.min_score,
//                     "max_score": values.max_score,
//                     "custom_tbx": values.custom_tbx,
//                     "impact_level": values.impact_level,
//                     "compl_type": values.compl_type,
//                     "creator_remarks": values.creator_remarks,
//                     "guideline": values.guideline,
//                     "guideline_image": values.guideline_image,
//                     "id": values.id,
//                     "parent": values.parent,
//                     "title": values.checkpoint,
//                     "subtitle": "",
//                     type: this.state.type,
//                 },
//                 getNodeKey: this.state.getNodeKey,
//             }),
//             crud: false,
//             editcrud: false,
//             childToggle: false,
//             mainToggle: false,
//         }),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     addNode = (node, path, getNodeKey, type) => {
//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })
//         this.setState({
//             type,
//             path: path,
//             getNodeKey: getNodeKey,
//             crud: true,
//             editcrud: false,
//             crudStatus: 1,
//             title: getNodeInfo.node.title,
//             getTitle: "",
//             getSubTitle: "",
//             getSubTitledd: "0",
//             id: getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0 ? getNodeInfo.node.id : getNodeInfo.node.children.length + 1,
//             parent: getNodeInfo.node.id,
//         })
//     }

//     editNode = (path, getNodeKey) => {

//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })

//         this.setState({
//             crud: false,
//             editcrud: true,
//             crudStatus: 2,
//             path: path,
//             getNodeInfo: getNodeInfo.node,
//             getNodeKey: getNodeKey,
//             getCode: getNodeInfo.node.code,
//             getTitle: getNodeInfo.node.title,
//             type: getNodeInfo.node.type,
//             id: getNodeInfo.node.id,
//             parent: getNodeInfo.node.parent,
//         })

//     }

//     deleteNode = async (node, path, getNodeKey, udp, dp) => {
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

//         this.setState(state => ({
//             treeData: removeNodeAtPath({
//                 treeData: state.treeData,
//                 path,
//                 getNodeKey,
//             }),
//             crud: false,
//         }),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     crudNode = (event, values) => {

//         this.onDrawerClose()
//         if (this.state.crudStatus == 1) { //Add new node
//             this.setState(state => ({
//                 treeData: addNodeUnderParent({
//                     treeData: state.treeData,
//                     parentKey: this.state.path[this.state.path.length - 1],
//                     expandParent: true,
//                     getNodeKey: this.state.getNodeKey,
//                     newNode: {
//                         "company_id": this.state.userData.company_id,
//                         "owner_id": this.state.userData._id,
//                         "template_id": this.state.templateData._id,
//                         "document_id": this.state.totalHLength + 1,
//                         "parent_id": this.state.parent,
//                         "document_type": String(this.state.type),
//                         "checkpoint": values.title,
//                         "checkpoint_type_id": null,
//                         "checkpoint_type": null,
//                         "enable_addOptns": false,
//                         "checkpoint_options": [

//                         ],
//                         "min_score": 0,
//                         "max_score": 0,
//                         "custom_tbx": [

//                         ],
//                         "impact_level": null,
//                         "compl_type": [

//                         ],
//                         "creator_remarks": " ",
//                         "guideline": "",
//                         "guideline_image": [

//                         ],
//                         "id": this.state.totalHLength + 1,
//                         "parent": this.state.parent,
//                         "title": values.title,
//                         "subtitle": "",
//                         type: this.state.type,
//                     },
//                     addAsFirstChild: state.addAsFirstChild,
//                 }).treeData,
//                 crud: false,
//                 childToggle: false,
//                 mainToggle: false,
//                 editcrud: false,
//                 totalHLength: this.state.totalHLength + 1,
//             }
//             ),
//                 function () {
//                     this.saveHStructure()
//                 }
//             )
//         }
//         else
//             if (this.state.crudStatus == 2) // Edit node
//             {
//                 this.setState(state => ({
//                     treeData: changeNodeAtPath({
//                         treeData: this.state.treeData,
//                         path: this.state.path,
//                         expandParent: true,
//                         newNode: {
//                             children: this.state.getNodeInfo.children,
//                             "company_id": this.state.getNodeInfo.company_id,
//                             "owner_id": this.state.getNodeInfo.owner_id,
//                             "template_id": this.state.getNodeInfo.template_id,
//                             "document_type": this.state.getNodeInfo.document_type,
//                             "document_id": this.state.getNodeInfo.document_id,
//                             "parent_id": this.state.getNodeInfo.parent_id,
//                             "checkpoint": values.title,
//                             "checkpoint_type_id": this.state.getNodeInfo.checkpoint_type_id,
//                             "checkpoint_type": this.state.getNodeInfo.checkpoint_type,
//                             "enable_addOptns": this.state.getNodeInfo.enable_addOptns,
//                             "checkpoint_options": this.state.getNodeInfo.checkpoint_options,
//                             "min_score": this.state.getNodeInfo.min_score,
//                             "max_score": this.state.getNodeInfo.max_score,
//                             "custom_tbx": this.state.getNodeInfo.custom_tbx,
//                             "impact_level": this.state.getNodeInfo.impact_level,
//                             "compl_type": this.state.getNodeInfo.compl_type,
//                             "creator_remarks": this.state.getNodeInfo.creator_remarks,
//                             "guideline": this.state.getNodeInfo.guideline,
//                             "guideline_image": this.state.getNodeInfo.guideline_image,
//                             "title": values.title,
//                             "subtitle": "",
//                             "id": this.state.getNodeInfo.id,
//                             "parent": this.state.getNodeInfo.parent,
//                             type: this.state.getNodeInfo.type,
//                         },
//                         getNodeKey: this.state.getNodeKey,
//                     }),
//                     crud: false,
//                     editcrud: false,
//                     childToggle: false,
//                     mainToggle: false,
//                 }),
//                     function () {
//                         this.saveHStructure()
//                     }
//                 )
//             }
//             else
//                 if (this.state.crudStatus == 0) {

//                     this.setState(state => ({
//                         treeData: addNodeUnderParent({
//                             treeData: state.treeData,
//                             parentKey: this.state.path[this.state.path.length - 1],
//                             expandParent: true,
//                             getNodeKey: this.state.getNodeKey,
//                             newNode: {
//                                 "company_id": this.state.userData.company_id,
//                                 "owner_id": this.state.userData._id,
//                                 "template_id": this.state.templateData._id,
//                                 "document_id": this.state.totalHLength + 1,
//                                 "parent_id": null,
//                                 "document_type": String(this.state.type),
//                                 "checkpoint": values.title,
//                                 "checkpoint_type_id": null,
//                                 "checkpoint_type": null,
//                                 "enable_addOptns": false,
//                                 "checkpoint_options": [

//                                 ],
//                                 "min_score": 0,
//                                 "max_score": 0,
//                                 "custom_tbx": [

//                                 ],
//                                 "impact_level": null,
//                                 "compl_type": [

//                                 ],
//                                 "creator_remarks": [

//                                 ],
//                                 "guideline": "",
//                                 "guideline_image": [

//                                 ],
//                                 "id": this.state.totalHLength + 1,
//                                 "parent": null,
//                                 "title": values.title,
//                                 "subtitle": "",
//                                 "type": this.state.type,

//                             },
//                             addAsFirstChild: state.addAsFirstChild,
//                         }).treeData,
//                         crud: false,
//                         mainToggle: false,
//                         totalHLength: this.state.totalHLength + 1,
//                     }
//                     ),
//                         function () {
//                             this.saveHStructure()
//                         }
//                     )
//                 }
//     }


//     dndNode = (droppedNode, getNodeKey) => {
//         this.setState(state => ({
//             treeData: changeNodeAtPath({
//                 treeData: state.treeData,
//                 path: droppedNode.path,
//                 getNodeKey,
//                 newNode: {
//                     "company_id": droppedNode.node.company_id,
//                     "owner_id": droppedNode.node.owner_id,
//                     "template_id": droppedNode.node.template_id,
//                     "document_id": droppedNode.node.document_id,
//                     "parent_id": droppedNode.nextParentNode.id,
//                     "document_type": droppedNode.node.document_type,
//                     "checkpoint": droppedNode.node.checkpoint,
//                     "checkpoint_type_id": droppedNode.node.checkpoint_type_id,
//                     "checkpoint_type": droppedNode.node.checkpoint_type,
//                     "enable_addOptns": droppedNode.node.enable_addOptns,
//                     "checkpoint_options": droppedNode.node.checkpoint_options,
//                     "min_score": droppedNode.node.min_score,
//                     "max_score": droppedNode.node.max_score,
//                     "custom_tbx": droppedNode.node.custom_tbx,
//                     "impact_level": droppedNode.node.impact_level,
//                     "compl_type": droppedNode.node.compl_type,
//                     "creator_remarks": droppedNode.node.creator_remarks,
//                     "guideline": droppedNode.node.guideline,
//                     "guideline_image": droppedNode.node.guideline_image,
//                     "id": droppedNode.node.id,
//                     "parent": droppedNode.nextParentNode.id,
//                     "title": droppedNode.node.title,
//                     "subtitle": droppedNode.node.subtitle,
//                     type: droppedNode.node.type,
//                     children: droppedNode.node.children,
//                 },
//             }),
//             crud: false,
//         }),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     getNodeData(nodeData) {
//         this.setState({ nodeTitle: nodeData.type != 2 ? nodeData.title : "" })
//         if (nodeData.children != undefined) {
//             if (nodeData.children.length != 0) {
//                 var getNodeKey = ({ treeIndex }) => treeIndex
//                 var flatData = getFlatDataFromTree(
//                     {
//                         treeData: nodeData.children,
//                         getNodeKey,
//                         ignoreCollapsed: false,
//                     })

//                 var explicitData = _.filter(flatData, item => {
//                     if (item.node.type == 2)
//                         return { node: item.node, parent: item.parentNode }
//                 })

//                 this.setState({ nodeChildren: explicitData })
//             }
//         }
//         else {
//             if (nodeData.type == 2) {
//                 this.setState({ nodeChildren: [{ node: nodeData, parentNode: null }] })
//             }
//             else {
//                 this.setState({ nodeChildren: [] })
//             }
//         }
//     }






//     toggle() {
//         this.setState(prevState => ({
//             mainToggle: !prevState.mainToggle,
//         }))
//     }

//     childtoggle(udp, dp) {
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")
//     }

//     handleUserClicks = arg => {
//         this.setState({ users: '', isEdit: false })
//         this.toggle()
//     }

//     changeAuditEditMode = (status) => { this.setState({ changeToUpdate: status, showCloseButton: status, modal: !this.state.modal ? true : false }) }

//     setPosition(e) {
//         this.setState({ position: e.target.value });
//     }

//     closeDrawer() {
//         this.setState({ open: false });
//     }
//     onDrawerClose() {
//         this.setState({ open: false });
//     }

//     toggleToolTip = targetName => {
//         if (!this.state[targetName]) {
//             this.setState({
//                 ...this.state,
//                 [targetName]: {
//                     tooltipOpen: true
//                 }
//             });
//         } else {
//             this.setState({
//                 ...this.state,
//                 [targetName]: {
//                     tooltipOpen: !this.state[targetName].tooltipOpen
//                 }
//             });
//         }
//     };

//     isToolTipOpen = targetName => {
//         return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
//     };


   


//     render() {

//         const { department_name } = this.state
//         const { isEdit } = this.state
//         if (this.state.dataLoaded) {
//             const getNodeKey = ({ treeIndex }) => treeIndex;
//             return (
//                 <React.Fragment>

                   


                 

//                     <div className="page-content" >
//                         <label>Edit Template</label>


//                         <div class="">
//                             <div class="row">
//                                 <div class="left-column">
//                                     <Card>
//                                         <CardBody>
//                                         <div className="mb-2" >
//                                             <UncontrolledDropdown isOpen={this.state.mainToggle} toggle={() => this.toggle()}>
//                                                 <DropdownToggle
//                                                     className="btn btn-light dropdown-toggle w-100 "
//                                                     color="#eff2f7"
//                                                     type="button"
//                                                     onClick={() => this.addMainNode(0)}
//                                                 >
//                                                     <i className="mdi mdi-plus me-1 "></i> Create New
//                                                 </DropdownToggle>
//                                                 <DropdownMenu style={{ width: 250 }} className="dropdown-toggle">
//                                                     <div className="p-4">
//                                                         <AvForm onValidSubmit={this.crudNode}>
//                                                             <div className="my-2">
//                                                                 <AvField
//                                                                     name="title"
//                                                                     label="Menu Name  "
//                                                                     placeholder="Enter Menu"
//                                                                     type="text"
//                                                                     errorMessage="Enter Menu"
//                                                                     validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                     defaultValue={""}
//                                                                     onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                 />
//                                                             </div>
//                                                             <div className="my-3">
//                                                                 <button className="btn btn-primary btn-block m-1"
//                                                                     type="submit" style={{ marginRight: 5 }}>Add Menu</button>
//                                                             </div>
//                                                         </AvForm>
//                                                     </div>
//                                                     <div className="dropdown-divider"></div>
//                                                     <Link className="dropdown-item" to="#"
//                                                         onClick={() => this.addParameterAsMain(2)}
//                                                     >
//                                                         <i className="bx bx-file me-1"></i> Add Check point
//                                                     </Link>
//                                                 </DropdownMenu>
//                                             </UncontrolledDropdown>
//                                         </div>
//                                         <div style={{height: '80vh', overflow: 'auto'}} >
//                                             <SortableTree
//                                                 treeData={this.state.treeData}
//                                                 onChange={(treeData, node) => {
//                                                     this.setState({ treeData, crud: false })
//                                                 }}
//                                                 onMoveNode={object => this.dndNode(object, getNodeKey)}
//                                                 canDrop={object => object.nextParent.type == 2 ? false : true}
//                                                 scaffoldBlockPxWidth={25}
//                                                 slideRegionSize={25}
//                                                 rowHeight={50}
//                                                 generateNodeProps={({ node, path }) => ({
//                                                     listIndex: 0,
//                                                     lowerSiblingCounts: [],
//                                                     rowdirection: "rtl",
//                                                     className: node.type == 0 ? `${"icon-a"}` : `${"icon-b"}`,
//                                                     onClick: (event) => {
//                                                         if (event.target.className.includes('collapseButton') || event.target.className.includes('expandButton')) {
//                                                         }
//                                                         else {
//                                                             this.setState({ selectedNodeId: node.id, nodeSelected: true })
//                                                             this.getNodeData(node)
//                                                         }
//                                                     },
//                                                     style: { fontSize: 14, border: this.state.selectedNodeId == node.id ? "2px solid #f1b44c" : '1px solid #74788d' },

//                                                     title: (
//                                                         <div >
//                                                             <div style={{ maxWidth: 350, }} key={`div-${node.id}`} >

//                                                                 <div>
//                                                                     <Tooltip
//                                                                         placement="bottom"
//                                                                         isOpen={this.isToolTipOpen(`btn-${node.id}`)}
//                                                                         target={`btn-${node.id}`}
//                                                                         toggle={() => this.toggleToolTip(`btn-${node.id}`)}
//                                                                     >
//                                                                         {node.title}
//                                                                     </Tooltip>
//                                                                     <Link
//                                                                         to="#"
//                                                                         id={`btn-${node.id}`}
//                                                                         style={{ fontSize: 12, fontWeight: 500 }}
//                                                                     // onClick={()=>{}
//                                                                     >
//                                                                         {String(node.title).slice(0, 20) + (node.title.length > 20 ? "..." : "")}
//                                                                     </Link>
//                                                                 </div>
//                                                             </div>
//                                                         </div>

//                                                     ),

//                                                     buttons: [
//                                                         <div>

//                                                             <UncontrolledDropdown className="mb-0 bg-white " direction="right"
//                                                                 id={"udp" + String(node.id)}
//                                                             //onClick={()=>this.setState({crud:false, editcrud:false})}
//                                                             >
//                                                                 <DropdownToggle
//                                                                     color="white"
//                                                                     className="font-size-16 text-muted dropdown-toggle"
//                                                                     tag="a"

//                                                                 >
//                                                                     <i className="mdi mdi-dots-vertical font-size-16"></i>
//                                                                 </DropdownToggle>

//                                                                 <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)} >
//                                                                     {!this.state.crud && node.type == 0 ? <Link className="dropdown-item " to="#"
//                                                                         onClick={
//                                                                             () => this.addNode(node, path, getNodeKey, 0)
//                                                                         }
//                                                                     > Add Sub Menu
//                                                                     </Link> : null}
//                                                                     {
//                                                                         this.state.crud && node.type == 0 ?
//                                                                             <div className="px-4">
//                                                                                 <AvForm onValidSubmit={this.crudNode}>
//                                                                                     <div className="my-2">
//                                                                                         <AvField
//                                                                                             name="title"
//                                                                                             label="Sub Menu Name  "
//                                                                                             placeholder="Enter Sub Menu"
//                                                                                             type="text"
//                                                                                             errorMessage="Enter Menu"
//                                                                                             validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                                             defaultValue={""}
//                                                                                             onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                                         />
//                                                                                     </div>
//                                                                                     <div className="my-3">
//                                                                                         <button className="btn btn-primary btn-block m-1"
//                                                                                             type="submit" onClick={() => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Add Menu</button>
//                                                                                     </div>
//                                                                                 </AvForm>
//                                                                             </div>
//                                                                             : null
//                                                                     }

//                                                                     {!this.state.editcrud && node.type == 0 ? <div>
//                                                                         <div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={
//                                                                                 () => this.editNode(path, getNodeKey)
//                                                                             }
//                                                                         > Edit Menu
//                                                                         </Link> </div> : null}
//                                                                     {
//                                                                         this.state.editcrud ?
//                                                                             <div className="px-4">
//                                                                                 <AvForm onValidSubmit={this.crudNode}>
//                                                                                     <div className="my-2">
//                                                                                         <AvField
//                                                                                             name="title"
//                                                                                             label="Edit Menu Name  "
//                                                                                             placeholder="Enter Menu Name"
//                                                                                             type="text"
//                                                                                             errorMessage="Enter Menu"
//                                                                                             validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                                             defaultValue={this.state.getTitle}
//                                                                                             onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                                         />
//                                                                                     </div>
//                                                                                     <div className="my-3">
//                                                                                         <button className="btn btn-primary btn-block m-1"
//                                                                                             type="submit" onClick={(event) => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Update</button>
//                                                                                     </div>
//                                                                                 </AvForm>
//                                                                             </div>
//                                                                             : null
//                                                                     }
//                                                                     {node.type == 0 ? <div><div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={
//                                                                                 () => this.addCheckpoint(node, path, getNodeKey, 2, "udp" + String(node.id), "dp" + String(node.id))
//                                                                             }
//                                                                         > Add Check point
//                                                                         </Link></div> : null}
//                                                                     {node.type == 2 ? <div><div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={
//                                                                                 () => this.editCheckpoint(path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))
//                                                                             }
//                                                                         > Edit Check point
//                                                                         </Link></div> : null}
//                                                                     <div className="dropdown-divider"></div>
//                                                                     <Link className="dropdown-item text-danger" to="#"
//                                                                         onClick={() => this.deleteNode(node, path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))}
//                                                                     > {node.type != 2 ? "Delete Menu" : "Delete Check point"} <i className="mdi mdi-delete font-size-18"
//                                                                         style={{ margin: 5 }}
//                                                                     ></i></Link>
//                                                                 </DropdownMenu>
//                                                             </UncontrolledDropdown>
//                                                         </div>
//                                                     ]
//                                                 })}

//                                             />
//                                         </div>
//                                         </CardBody>
//                                     </Card>
//                                 </div>
//                                 <div class="right-column">
                                   
//                                             <div >
//                                                 <Alert color="success" isOpen={this.state.successmessage} ><strong>Well done! </strong> {this.state.updateTemplateStatus}</Alert>
//                                                 {
//                                                     this.state.changeToUpdate ?
//                                                         <Card>
//                                                             <CardBody>
//                                                                 <div>
//                                                                     <AvForm onValidSubmit={this.handleValidUserSubmit} >
//                                                                         <Row>
//                                                                             <Col >
//                                                                                 <div className="my-3">
//                                                                                     <div >
//                                                                                         <div className="my-2">
//                                                                                             <AvField
//                                                                                                 name="template_name"
//                                                                                                 label="Audit Name  "
//                                                                                                 placeholder="Enter Audit Name"
//                                                                                                 type="text"
//                                                                                                 errorMessage="Enter Audit Name"
//                                                                                                 validate={{ required: { value: true }, minLength: { value: 6, errorMessage: "Min 6 chars." } }}
//                                                                                                 defaultValue={this.state.template_name}
//                                                                                                 onChange={(e) => { this.setState({ template_name: e.target.value }) }}
//                                                                                             />
//                                                                                         </div>
//                                                                                         <div className="my-1">
//                                                                                             <label>Description</label>
//                                                                                             <AvField type="textarea" name="template_info" id="message" defaultValue={this.state.template_info} onChange={(e) => { this.setState({ template_info: e.target.value }) }} />
//                                                                                         </div>
//                                                                                         <div className="my-3">
//                                                                                             <button className="btn btn-primary btn-block" style={{ marginRight: 5 }}>Update</button>
//                                                                                             <button className="btn btn-danger btn-block" onClick={() => this.setState({ changeToUpdate: false })}>Close</button>
//                                                                                         </div>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </Col>
//                                                                         </Row>
//                                                                     </AvForm>
//                                                                 </div>
//                                                             </CardBody>
//                                                         </Card>
//                                                         :
//                                                         <div>
//                                                             <Card>
//                                                                 <CardBody>
//                                                                     <Row>
//                                                                         <Col md={6}>
//                                                                             <div className="my-1">
//                                                                                 <h5 className="text-wrap font-size-15">
//                                                                                     <span className="text-primary">{this.state.template_name}&nbsp;&nbsp;&nbsp;</span>
//                                                                                     {this.state.templateData.tmplt_ctd_by == "0" ? null : <i className="fas fa-edit" onClick={() => this.changeAuditEditMode(true)}></i>}</h5>
//                                                                                 <span className="text-muted " >{this.state.template_info} </span>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={6}>
//                                                                             <div style={{ textAlign: 'right' }} className="mb-2">
//                                                                                 <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//                                                                             </div>
//                                                                         </Col>
//                                                                     </Row>
//                                                                 </CardBody>
//                                                             </Card>
//                                                         </div>
//                                                 }
//                                             </div>
//                                             <div>
//                                                 {
//                                                     this.state.nodeSelected ?
//                                                         <div style={{ height: '76vh', overflow: 'auto' }}>
//                                                             <div className={this.state.nodeTitle != "" ? 'm-2 p-3 bg-white' : ''}>
//                                                                 <span>{this.state.nodeTitle}</span>
//                                                             </div>
//                                                             {

//                                                                 this.state.nodeChildren.map((item, i) => {
//                                                                     var getColor = item.node.impact_level == 'Critical' ? '#f46a6a' :
//                                                                         item.node.impact_level == 'High' ? '#f1b44c' :
//                                                                             item.node.impact_level == 'Medium' ? '#50a5f1' :
//                                                                                 item.node.impact_level == 'Low' ? '#34c38f' : '#556ee6'
//                                                                     return (
//                                                                         <div className='mb-2 m-2 d-flex flex-column p-3 bg-white' key={"cp" + i} style={{ borderBottom: '1px solid', borderColor: getColor }} >
//                                                                             <div>
//                                                                                 <span className='font-size-10 text-secondary'>{item.parentNode != null ? item.parentNode.title : ""}</span>
//                                                                             </div>
//                                                                             <div className='my-2'>
//                                                                                 <span style={{ color: getColor }}>{String(i).length > 1 ? (i + 1) : "0" + (i + 1)}</span>{" "}<span className='font-size-13'>{item.node.checkpoint}</span>
//                                                                             </div>
//                                                                             <div className='my-2'>
//                                                                                 {
//                                                                                     item.node.checkpoint_options.map((child, idx) => (
//                                                                                         <div className='d-flex flex-column p-2 my-1' style={{ border: '1px solid #f0f0f0', borderRadius: 10 }} key={"cp" + i + "ptn" + idx}>
//                                                                                             <span>{child.option_text} {" "} {child.enable_img ? <i className='mdi mdi-camera-plus' /> : null} {" "} {child.enable_doc ? <i className='mdi mdi-file-document' /> : null}</span>
//                                                                                         </div>
//                                                                                     ))
//                                                                                 }
//                                                                             </div>
//                                                                             {
//                                                                                 item.node.impact_level != "" ?
//                                                                                     <div className='my-2'>
//                                                                                         <span className='badge badge-soft p-1 font-size-12' style={{ backgroundColor: getColor }}>{item.node.impact_level}</span>
//                                                                                     </div> : null
//                                                                             }
//                                                                         </div>
//                                                                     )
//                                                                 }
//                                                                 )
//                                                             }
//                                                         </div>
//                                                         :
//                                                 <div>
//                                                     <Card>
//                                                         <CardBody style={{ height: '76vh' }}>

//                                                         </CardBody>
//                                                     </Card>
//                                                 </div>
//                                                 }
//                                             </div>

                         
                                       
//                                 </div>
//                             </div>
//                             <div class="row">
//                                 <div class="full-width-column">

//                                     <Card>
//                                         <CardBody>
//                                             <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: 5 }}>
//                                                 <div className='col-4' >
//                                                     <UncontrolledAlert
//                                                         color="success"
//                                                         className="alert-dismissible fade show"
//                                                         role="alert"
//                                                         isOpen={this.state.successmsgshow}
//                                                         style={{ margin: 0 }}
//                                                     >
//                                                         <i className="mdi mdi-check-all me-2"></i>{this.state.alertMessage}
//                                                     </UncontrolledAlert>
//                                                 </div>
//                                                 <div className="col-4 m-1" >
//                                                     <Button className="btn btn-primary w-100" onClick={() => this.navigateTo()}> Go back </Button>
//                                                 </div>
//                                                 <div className="col-4 m-1" >
//                                                     <Button className={this.state.treeData.length > 0 ? "btn btn-success w-100" : "btn btn-secondary w-100"} onClick={() => this.navigateTo()} disabled={this.state.treeData.length > 0 ? false : true}> Save </Button>
//                                                 </div>
//                                             </div>

//                                         </CardBody>
//                                     </Card>
//                                 </div>

//                             </div>
//                         </div>















// {/*
//                         <div className="row" style={{height: '80vh' }}>
//                             <div style={{  maxWidth: '400px', height: '80vh' }}>
//                                 <Card >
//                                     <CardBody>
//                                         <div className="mb-2" >
//                                             <UncontrolledDropdown isOpen={this.state.mainToggle} toggle={() => this.toggle()}>
//                                                 <DropdownToggle
//                                                     className="btn btn-light dropdown-toggle w-100 "
//                                                     color="#eff2f7"
//                                                     type="button"
//                                                     onClick={() => this.addMainNode(0)}
//                                                 >
//                                                     <i className="mdi mdi-plus me-1 "></i> Create New
//                                                 </DropdownToggle>
//                                                 <DropdownMenu style={{ width: 250 }} className="dropdown-toggle">
//                                                     <div className="p-4">
//                                                         <AvForm onValidSubmit={this.crudNode}>
//                                                             <div className="my-2">
//                                                                 <AvField
//                                                                     name="title"
//                                                                     label="Menu Name  "
//                                                                     placeholder="Enter Menu"
//                                                                     type="text"
//                                                                     errorMessage="Enter Menu"
//                                                                     validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                     defaultValue={""}
//                                                                     onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                 />
//                                                             </div>
//                                                             <div className="my-3">
//                                                                 <button className="btn btn-primary btn-block m-1"
//                                                                     type="submit" style={{ marginRight: 5 }}>Add Menu</button>
//                                                             </div>
//                                                         </AvForm>
//                                                     </div>
//                                                     <div className="dropdown-divider"></div>
//                                                     <Link className="dropdown-item" to="#"
//                                                         onClick={() => this.addParameterAsMain(2)}
//                                                     >
//                                                         <i className="bx bx-file me-1"></i> Add Check point
//                                                     </Link>
//                                                 </DropdownMenu>
//                                             </UncontrolledDropdown>
//                                         </div>
//                                         <div style={{height: '80vh', overflow: 'auto'}} >
//                                             <SortableTree
//                                                 treeData={this.state.treeData}
//                                                 onChange={(treeData, node) => {
//                                                     this.setState({ treeData, crud: false })
//                                                 }}
//                                                 onMoveNode={object => this.dndNode(object, getNodeKey)}
//                                                 canDrop={object => object.nextParent.type == 2 ? false : true}
//                                                 scaffoldBlockPxWidth={25}
//                                                 slideRegionSize={25}
//                                                 rowHeight={50}
//                                                 generateNodeProps={({ node, path }) => ({
//                                                     listIndex: 0,
//                                                     lowerSiblingCounts: [],
//                                                     rowdirection: "rtl",
//                                                     className: node.type == 0 ? `${"icon-a"}` : `${"icon-b"}`,
//                                                     onClick: (event) => {
//                                                         if (event.target.className.includes('collapseButton') || event.target.className.includes('expandButton')) {
//                                                         }
//                                                         else {
//                                                             this.setState({ selectedNodeId: node.id, nodeSelected: true })
//                                                             this.getNodeData(node)
//                                                         }
//                                                     },
//                                                     style: { fontSize: 14, border: this.state.selectedNodeId == node.id ? "2px solid #f1b44c" : '1px solid #74788d' },

//                                                     title: (
//                                                         <div >
//                                                             <div style={{ maxWidth: 350, }} key={`div-${node.id}`} >

//                                                                 <div>
//                                                                     <Tooltip
//                                                                         placement="bottom"
//                                                                         isOpen={this.isToolTipOpen(`btn-${node.id}`)}
//                                                                         target={`btn-${node.id}`}
//                                                                         toggle={() => this.toggleToolTip(`btn-${node.id}`)}
//                                                                     >
//                                                                         {node.title}
//                                                                     </Tooltip>
//                                                                     <Link
//                                                                         to="#"
//                                                                         id={`btn-${node.id}`}
//                                                                         style={{ fontSize: 12, fontWeight: 500 }}
//                                                                     // onClick={()=>{}
//                                                                     >
//                                                                         {String(node.title).slice(0, 20) + (node.title.length > 20 ? "..." : "")}
//                                                                     </Link>
//                                                                 </div>
//                                                             </div>
//                                                         </div>

//                                                     ),

//                                                     buttons: [
//                                                         <div>

//                                                             <UncontrolledDropdown className="mb-0 bg-white " direction="right"
//                                                                 id={"udp" + String(node.id)}
//                                                             //onClick={()=>this.setState({crud:false, editcrud:false})}
//                                                             >
//                                                                 <DropdownToggle
//                                                                     color="white"
//                                                                     className="font-size-16 text-muted dropdown-toggle"
//                                                                     tag="a"

//                                                                 >
//                                                                     <i className="mdi mdi-dots-vertical font-size-16"></i>
//                                                                 </DropdownToggle>

//                                                                 <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)} >
//                                                                     {!this.state.crud && node.type == 0 ? <Link className="dropdown-item " to="#"
//                                                                         onClick={
//                                                                             () => this.addNode(node, path, getNodeKey, 0)
//                                                                         }
//                                                                     > Add Sub Menu
//                                                                     </Link> : null}
//                                                                     {
//                                                                         this.state.crud && node.type == 0 ?
//                                                                             <div className="px-4">
//                                                                                 <AvForm onValidSubmit={this.crudNode}>
//                                                                                     <div className="my-2">
//                                                                                         <AvField
//                                                                                             name="title"
//                                                                                             label="Sub Menu Name  "
//                                                                                             placeholder="Enter Sub Menu"
//                                                                                             type="text"
//                                                                                             errorMessage="Enter Menu"
//                                                                                             validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                                             defaultValue={""}
//                                                                                             onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                                         />
//                                                                                     </div>
//                                                                                     <div className="my-3">
//                                                                                         <button className="btn btn-primary btn-block m-1"
//                                                                                             type="submit" onClick={() => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Add Menu</button>
//                                                                                     </div>
//                                                                                 </AvForm>
//                                                                             </div>
//                                                                             : null
//                                                                     }

//                                                                     {!this.state.editcrud && node.type == 0 ? <div>
//                                                                         <div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={
//                                                                                 () => this.editNode(path, getNodeKey)
//                                                                             }
//                                                                         > Edit Menu
//                                                                         </Link> </div> : null}
//                                                                     {
//                                                                         this.state.editcrud ?
//                                                                             <div className="px-4">
//                                                                                 <AvForm onValidSubmit={this.crudNode}>
//                                                                                     <div className="my-2">
//                                                                                         <AvField
//                                                                                             name="title"
//                                                                                             label="Edit Menu Name  "
//                                                                                             placeholder="Enter Menu Name"
//                                                                                             type="text"
//                                                                                             errorMessage="Enter Menu"
//                                                                                             validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                                             defaultValue={this.state.getTitle}
//                                                                                             onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                                         />
//                                                                                     </div>
//                                                                                     <div className="my-3">
//                                                                                         <button className="btn btn-primary btn-block m-1"
//                                                                                             type="submit" onClick={(event) => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Update</button>
//                                                                                     </div>
//                                                                                 </AvForm>
//                                                                             </div>
//                                                                             : null
//                                                                     }
//                                                                     {node.type == 0 ? <div><div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={
//                                                                                 () => this.addCheckpoint(node, path, getNodeKey, 2, "udp" + String(node.id), "dp" + String(node.id))
//                                                                             }
//                                                                         > Add Check point
//                                                                         </Link></div> : null}
//                                                                     {node.type == 2 ? <div><div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={
//                                                                                 () => this.editCheckpoint(path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))
//                                                                             }
//                                                                         > Edit Check point
//                                                                         </Link></div> : null}
//                                                                     <div className="dropdown-divider"></div>
//                                                                     <Link className="dropdown-item text-danger" to="#"
//                                                                         onClick={() => this.deleteNode(node, path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))}
//                                                                     > {node.type != 2 ? "Delete Menu" : "Delete Check point"} <i className="mdi mdi-delete font-size-18"
//                                                                         style={{ margin: 5 }}
//                                                                     ></i></Link>
//                                                                 </DropdownMenu>
//                                                             </UncontrolledDropdown>
//                                                         </div>
//                                                     ]
//                                                 })}

//                                             />
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </div>
//                             <div className="col" style={{ height: '80vh' }}>
//                                 <div >
//                                     <Alert color="success" isOpen={this.state.successmessage} ><strong>Well done! </strong> {this.state.updateTemplateStatus}</Alert>
//                                     {
//                                         this.state.changeToUpdate ?
//                                             <Card>
//                                                 <CardBody>
//                                                     <div>
//                                                         <AvForm onValidSubmit={this.handleValidUserSubmit} >
//                                                             <Row>
//                                                                 <Col >
//                                                                     <div className="my-3">
//                                                                         <div >
//                                                                             <div className="my-2">
//                                                                                 <AvField
//                                                                                     name="template_name"
//                                                                                     label="Audit Name  "
//                                                                                     placeholder="Enter Audit Name"
//                                                                                     type="text"
//                                                                                     errorMessage="Enter Audit Name"
//                                                                                     validate={{ required: { value: true }, minLength: { value: 6, errorMessage: "Min 6 chars." } }}
//                                                                                     defaultValue={this.state.template_name}
//                                                                                     onChange={(e) => { this.setState({ template_name: e.target.value }) }}
//                                                                                 />
//                                                                             </div>
//                                                                             <div className="my-1">
//                                                                                 <label>Description</label>
//                                                                                 <AvField type="textarea" name="template_info" id="message" defaultValue={this.state.template_info} onChange={(e) => { this.setState({ template_info: e.target.value }) }} />
//                                                                             </div>
//                                                                             <div className="my-3">
//                                                                                 <button className="btn btn-primary btn-block" style={{ marginRight: 5 }}>Update</button>
//                                                                                 <button className="btn btn-danger btn-block" onClick={() => this.setState({ changeToUpdate: false })}>Close</button>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>
//                                                         </AvForm>
//                                                     </div>
//                                                 </CardBody>
//                                             </Card>
//                                             :
//                                             <div>
//                                                 <Card>
//                                                     <CardBody>
//                                                         <Row>
//                                                             <Col md={6}>
//                                                                 <div className="my-1">
//                                                                     <h5 className="text-wrap font-size-15">
//                                                                         <span className="text-primary">{this.state.template_name}&nbsp;&nbsp;&nbsp;</span>
//                                                                         {this.state.templateData.tmplt_ctd_by == "0" ? null : <i className="fas fa-edit" onClick={() => this.changeAuditEditMode(true)}></i>}</h5>
//                                                                     <span className="text-muted " >{this.state.template_info} </span>
//                                                                 </div>
//                                                             </Col>
//                                                             <Col md={6}>
//                                                                 <div style={{ textAlign: 'right' }} className="mb-2">
//                                                                     <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//                                                                 </div>
//                                                             </Col>
//                                                         </Row>
//                                                     </CardBody>
//                                                 </Card>
//                                             </div>
//                                     }
//                                 </div>
//                                 <div>
//                                     {
//                                         this.state.nodeSelected ?
//                                             <div style={{height: '80vh', overflow: 'auto'}}>
//                                                 <div className={this.state.nodeTitle != "" ? 'm-2 p-3 bg-white' : ''}>
//                                                     <span>{this.state.nodeTitle}</span>
//                                                 </div>
//                                                 {

//                                                     this.state.nodeChildren.map((item, i) => {
//                                                         var getColor = item.node.impact_level == 'Critical' ? '#f46a6a' :
//                                                             item.node.impact_level == 'High' ? '#f1b44c' :
//                                                                 item.node.impact_level == 'Medium' ? '#50a5f1' :
//                                                                     item.node.impact_level == 'Low' ? '#34c38f' : '#556ee6'
//                                                         return (
//                                                             <div className='mb-2 m-2 d-flex flex-column p-3 bg-white' key={"cp" + i} style={{ borderBottom: '1px solid', borderColor: getColor}} >
//                                                                 <div>
//                                                                     <span className='font-size-10 text-secondary'>{item.parentNode != null ? item.parentNode.title : ""}</span>
//                                                                 </div>
//                                                                 <div className='my-2'>
//                                                                     <span style={{ color: getColor }}>{String(i).length > 1 ? (i + 1) : "0" + (i + 1)}</span>{" "}<span className='font-size-13'>{item.node.checkpoint}</span>
//                                                                 </div>
//                                                                 <div className='my-2'>
//                                                                     {
//                                                                         item.node.checkpoint_options.map((child, idx) => (
//                                                                             <div className='d-flex flex-column p-2 my-1' style={{ border: '1px solid #f0f0f0', borderRadius: 10 }} key={"cp" + i + "ptn" + idx}>
//                                                                                 <span>{child.option_text} {" "} {child.enable_img ? <i className='mdi mdi-camera-plus' /> : null} {" "} {child.enable_doc ? <i className='mdi mdi-file-document' /> : null}</span>
//                                                                             </div>
//                                                                         ))
//                                                                     }
//                                                                 </div>
//                                                                 {
//                                                                     item.node.impact_level != "" ?
//                                                                         <div className='my-2'>
//                                                                             <span className='badge badge-soft p-1 font-size-12' style={{ backgroundColor: getColor }}>{item.node.impact_level}</span>
//                                                                         </div> : null
//                                                                 }
//                                                             </div>
//                                                         )
//                                                     }
//                                                     )
//                                                 }
//                                             </div>
//                                             :
//                                             <div >
//                                                 <Card >
//                                                     <CardBody style={{height: '80vh' }}>

//                                                     </CardBody>
//                                                 </Card>
//                                             </div>
//                                     }
//                                 </div>
//                             </div>

//                         </div>
                       
//                         <div className="mt-2 row" >
//                             <Card>
//                                 <CardBody>
//                                     <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: 5 }}>
//                                         <div className='col-4' >
//                                             <UncontrolledAlert
//                                                 color="success"
//                                                 className="alert-dismissible fade show"
//                                                 role="alert"
//                                                 isOpen={this.state.successmsgshow}
//                                                 style={{ margin: 0 }}
//                                             >
//                                                 <i className="mdi mdi-check-all me-2"></i>{this.state.alertMessage}
//                                             </UncontrolledAlert>
//                                         </div>
//                                         <div className="col-4 m-1" >
//                                             <Button className="btn btn-primary w-100" onClick={() => this.navigateTo()}> Go back </Button>
//                                         </div>
//                                         <div className="col-4 m-1" >
//                                             <Button className={this.state.treeData.length > 0 ? "btn btn-success w-100" : "btn btn-secondary w-100"} onClick={() => this.navigateTo()} disabled={this.state.treeData.length > 0 ? false : true}> Save </Button>
//                                         </div>
//                                     </div>

//                                 </CardBody>
//                             </Card>
//                         </div> */}

//                     </div>
//                     {
//                                 this.state.saveAndSuccess && (
//                                     <SweetAlert
//                                         title={this.state.messageHeading}
//                                         danger={this.state.messageHeading == "Delete" ? true : false}
//                                         success={this.state.messageHeading == "Success" ? true : false}
//                                         showCancel={this.state.messageHeading == "Success" ? false : true}
//                                         confirmBtnBsStyle={this.state.messageHeading == "Success" ? "success" : "danger"}
//                                         cancelBtnBsStyle={this.state.messageHeading == "Delete" ? "success" : "danger"}
//                                         onConfirm={() => this.confirmation()}
//                                         onCancel={() => this.setState({ saveAndSuccess: false, })}
//                                     >{this.state.message}</SweetAlert>
//                                 )
//                             }
                   

//                     <ReactDrawer
//                         open={this.state.open}
//                         className="drawer-open"
//                         position={this.state.position}
//                         onClose={this.onDrawerClose}
//                         style={{ maxHeight: 1000 }}
//                         noOverlay={false}
//                     >

//                         <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>
//                             {this.state.open ? <InputTemplate
//                                 checkpointinfo={this.state.checkpointInfo}
//                                 configdatacheckpoint={this.state.configData.question_type_info}
//                                 mode={this.state.mode}
//                                 parameterData={true}
//                                 onSubmit={(values) => { this.AECheckPointUnderNode(values, this.state.mode) }}
//                             /> : null}
//                         </div>
//                     </ReactDrawer>

//                 </React.Fragment>
//             )
//         }
//         else {
//             return (
//                 <React.Fragment>
//                     <div className="page-content" style={{ minHeight: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//                             <div
//                                 className="spinner-border text-secondary m-1"
//                                 role="status"
//                             >  </div>
//                             <div>Loading, Please wait.</div>
//                         </div>
//                     </div>
//                 </React.Fragment>
//             )
//         }
//     }
// }


























// 01-06-23
// import React, { Component } from "react"
// import { Link } from "react-router-dom"
// import {
//     Container, Row, Col, Button, Alert, Input, Card, CardBody,
//     Modal, ModalHeader, ModalBody,
//     Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
// } from "reactstrap"
// import ReactDrawer from 'react-drawer';
// import { DatePicker, Drawer, Badge } from 'antd';
// import { AvForm, AvField } from "availity-reactstrap-validation"
// import Select from "react-select"
// import SweetAlert from "react-bootstrap-sweetalert"
// import ParentChildBtn from "../../components/ParentChildBtn"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
// import InputTemplate from "./Components/InputTemplate"
// import 'react-drawer/lib/react-drawer.css';

// import $ from 'jquery';

// import SortableTree, {
//     addNodeUnderParent,
//     removeNodeAtPath,
//     toggleExpandedForAll,
//     getTreeFromFlatData,
//     getNodeAtPath,
//     changeNodeAtPath,
//     getFlatDataFromTree,
//     walk,
//     map, find
// } from 'react-sortable-tree/dist/index.cjs.js';
// //import SortableTree from 'react-sortable-tree';
// //import 'react-sortable-tree/style.css';
// //import './react-tree-style.css';
// import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
// //import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';



// import "react-perfect-scrollbar/dist/css/styles.css"
// import "./manageAudit.css"

// import "react-rangeslider/lib/index.css"
// const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")

// export default class editPublishedTemplate extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             height: props.height,
//             successmessage: false,
//             changeToUpdate: false,
//             dataLoaded: false,
//             open: false,
//             position: 'right',
//             height: props.height,
//             successmsgshow: false,
//             nodeChildren: [],
//             nodeTitle: "",
//             nodeSelected: false,
//             treeData: [],
//         }
//         this.toggle = this.toggle.bind(this)
//         this.childtoggle = this.childtoggle.bind(this)
//         this.handleUserClicks = this.handleUserClicks.bind(this)

//         this.closeDrawer = this.closeDrawer.bind(this);
//         this.onDrawerClose = this.onDrawerClose.bind(this);
//         this.setPosition = this.setPosition.bind(this);
//     }

//     componentWillMount() {
//         var templateData = JSON.parse(sessionStorage.getItem("EditPublishedData"))
//         var navigationMode = sessionStorage.getItem("nagiationMode");
//         var data = JSON.parse(sessionStorage.getItem("authUser"))
//         var db_info = JSON.parse(sessionStorage.getItem("db_info"))


//         this.setState({
//             height: window.innerHeight,
//             configData: data.config_data,
//             userData: data.user_data,
//             templateData,
//             template_name: templateData.template_name,
//             template_info: templateData.template_info,
//             configdatacheckpoint: data.config_data.question_type_info,
//             navigationMode,
//             dataLoaded: false,
//             db_info: db_info
//         },
//             function () {
//                 this.getDocuments()
//             }
//         )
//     }

//     handleValidUserSubmit = (event, values) => {
//         try {
//             urlSocket.post("webmngpbhtmplt/crudpublishedtemplate", {
//                 templateInfo: {
//                     template_id: this.state.templateData._id,
//                     template_name: values.template_name,
//                     template_info: values.template_info
//                 },
//                 userInfo: {
//                     created_by: this.state.userData._id,
//                     company_id: this.state.userData.company_id
//                 }
//             })
//                 .then((response) => {
//                     if (response.data.response_code == 500) {
//                         this.setState({
//                             successmessage: true,
//                             updateTemplateStatus: response.data.message,
//                             changeToUpdate: false,
//                             template_name: this.state.template_name,
//                             template_info: this.state.template_info,
//                         })
//                         setTimeout(() => { this.setState({ successmessage: false, }) }, 2500)
//                     }
//                 })
//         } catch (error) {
//             console.log('error', error)
//         }
//     }


//     navigateTo = () => {
//         if (this.state.navigationMode == "from_config") {
//             sessionStorage.removeItem("nagiationMode");
//             this.props.history.push("/pblhcfg")
//         }
//         else {
//             sessionStorage.removeItem("nagiationMode");
//             this.props.history.push("/mngpblhtempt")
//         }
//     }

//     treeDataToFlat = () => {
//         // console.log("this.state.treeData", this.state.treeData)
//         var getNodeKey = ({ treeIndex }) => treeIndex
//         var flatData = getFlatDataFromTree(
//             {
//                 treeData: this.state.treeData,
//                 getNodeKey,
//                 ignoreCollapsed: false,
//             })

//         var explicitData = _.filter(flatData, item => {
//             return item
//         })

//         return explicitData
//     }

//     saveHStructure = () => {
//         var explicitData = this.treeDataToFlat()
//         console.log("explicitData", explicitData)
//         this.saveData(explicitData)
//     }

//     saveData = (flatData) => {
//         this.setState({ saving: true })
//         try {
//             urlSocket.post("webmngpbhtmplt/savepblhcpstructure", {
//                 templateInfo: {
//                     template_id: this.state.templateData._id,
//                     flatData: _.map(flatData, "node"),
//                     checkpointlength: _.filter(_.map(flatData, "node"), { type: 2 }).length,
//                     lastGeneratedId: this.state.totalHLength
//                 },
//                 userInfo: {
//                     created_by: this.state.userData._id,
//                     company_id: this.state.userData.company_id
//                 }
//             })
//                 .then((response) => {
//                     console.log("response.data", response.data)
//                     if (response.data.response_code == 500) {
//                         this.setState({ saving: false, successmsgshow: true, alertMessage: response.data.message })
//                         setTimeout(() => { this.setState({ successmsgshow: false, }) }, 2500)

//                     }
//                 })
//         } catch (error) {

//         }
//     }


//     getDocuments() {
//         console.log("templateData", this.state.templateData)
//         try {
//             urlSocket.post('/webmngpbhtmplt/getpblhtemplatestructure', {
//                 templateInfo: {
//                     template_id: this.state.templateData._id,
//                     tmplatedBy: this.state.templateData.tmplt_ctd_by
//                 },
//                 userInfo: {
//                     encrypted_db_url: this.state.db_info.encrypted_db_url,
//                     created_by: this.state.userData._id,
//                     company_id: this.state.userData.company_id
//                 }

//             })
//                 .then(response => {

//                     var FlatData = response.data.data.template_structure
//                     console.log("response", response.data.data)
//                     this.setState(
//                         {
//                             dataLoaded: FlatData.length > 0 ? false : true,
//                             totalHLength: response.data.data.lastGeneratedId
//                         },
//                         function () {
//                             this.convertFlatDataToTreeData(FlatData)
//                         }
//                     )
//                 })
//                 .catch(error => {

//                 })
//         } catch (error) {
//             console.log("catch error", error)
//         }
//     }

//     convertFlatDataToTreeData = (FlatData) => {

//         this.setState({
//             treeData: getTreeFromFlatData(
//                 {
//                     flatData: FlatData,
//                     getKey: (node) => node.id,
//                     getParentKey: (node) => node.parent,
//                     rootKey: null,
//                     expanded: true
//                 }),
//             dataLoaded: true,
//         }
//         )
//     }




//     addMainNode(type) {

//         const getNodeKey = ({ treeIndex }) => treeIndex;
//         this.setState({
//             path: [],
//             getNodeKey: getNodeKey,
//             crud: true,
//             crudStatus: 0,
//             type,
//         })
//     }

//     addParameterAsMain(type,) {

//         const getNodeKey = ({ treeIndex }) => treeIndex;
//         this.setState({
//             path: [],
//             getNodeKey: getNodeKey,
//             crud: false,
//             crudStatus: 0,
//             type,
//             position: 'right',
//             mainToggle: false,
//             open: !this.state.open,
//             //qTypeInfo: this.state.configData.question_type_info,
//             configdatacheckpoint: this.state.configData.question_type_info
//         })
//     }

//     addCheckpoint = (node, path, getNodeKey, type, udp, dp) => {
//         this.setState({ position: 'right' });
//         this.setState({ open: !this.state.open });
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })
//         this.setState({
//             type,
//             path: path,
//             getNodeKey: getNodeKey,
//             crud: false,
//             editcrud: false,
//             crudStatus: 1,
//             title: getNodeInfo.node.title,
//             getTitle: "",
//             getSubTitle: "",
//             getSubTitledd: "0",
//             id: getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0 ? getNodeInfo.node.id : getNodeInfo.node.children.length + 1,
//             parent: getNodeInfo.node.id,
//             mode: "0",
//             //checkpointInfo: this.state.configData.question_type_info,
//             configdatacheckpoint: this.state.configData.question_type_info
//         })
//     }

//     AECheckPointUnderNode(values, mode) {
//         console.log("this.state.configdatacheckpoint", this.state.configdatacheckpoint)
//         this.setState({ open: !this.state.open, })
//         if (mode == "0") {
//             this.addCheckPointUnderNode(values)
//         }
//         else
//             if (mode == "1") {
//                 this.editCheckpointAtPath(values)
//             }
//     }

//     addCheckPointUnderNode(values) {
//         this.setState(state => ({
//             treeData: addNodeUnderParent({
//                 treeData: state.treeData,
//                 parentKey: this.state.path[this.state.path.length - 1],
//                 expandParent: true,
//                 getNodeKey: this.state.getNodeKey,
//                 newNode: {
//                     "company_id": this.state.userData.company_id,
//                     "owner_id": this.state.userData._id,
//                     "template_id": this.state.templateData._id,
//                     "document_id": this.state.totalHLength + 1,
//                     "parent_id": this.state.parent,
//                     "document_type": String(this.state.type),
//                     "checkpoint": values.checkpoint,
//                     "checkpoint_type_id": values.checkpoint_type_id,
//                     "checkpoint_type": values.checkpoint_type,
//                     "enable_addOptns": values.enable_addOptns,
//                     "checkpoint_options": values.checkpoint_options,
//                     "min_score": values.min_score,
//                     "max_score": values.max_score,
//                     "custom_tbx": values.custom_tbx,
//                     "impact_level": values.impact_level,
//                     "compl_type": values.compl_type,
//                     "creator_remarks": values.creator_remarks,
//                     "guideline": values.guideline,
//                     "guideline_image": values.guideline_image,
//                     "id": this.state.totalHLength + 1,
//                     "parent": this.state.parent,
//                     "title": values.checkpoint,
//                     "subtitle": "",
//                     type: this.state.type,
//                 },
//                 //addAsFirstChild: state.addAsFirstChild,
//             }).treeData,
//             crud: false,
//             childToggle: false,
//             mainToggle: false,
//             editcrud: false,
//             totalHLength: this.state.totalHLength + 1,
//         }
//         ),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     editCheckpoint = (path, getNodeKey, udp, dp) => {

//         this.setState({ position: 'right' });
//         this.setState({ open: !this.state.open });
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })

//         console.log("getNodeInfo.node", getNodeInfo.node)

//         this.setState({
//             crud: false,
//             editcrud: false,
//             crudStatus: 2,
//             path: path,
//             getNodeInfo: getNodeInfo.node,
//             getNodeKey: getNodeKey,
//             getCode: getNodeInfo.node.code,
//             getTitle: getNodeInfo.node.title,
//             type: getNodeInfo.node.type,
//             mode: "1",
//             checkpointInfo: [getNodeInfo.node],
//         })

//     }

//     editCheckpointAtPath(values) {
//         this.setState(state => ({
//             treeData: changeNodeAtPath({
//                 treeData: this.state.treeData,
//                 path: this.state.path,
//                 expandParent: true,
//                 newNode: {
//                     children: this.state.getNodeInfo.children,
//                     "company_id": values.company_id,
//                     "owner_id": values.owner_id,
//                     "template_id": values.template_id,
//                     "document_id": values.document_id,
//                     "parent_id": values.parent_id,
//                     "document_type": values.document_type,
//                     "checkpoint": values.checkpoint,
//                     "checkpoint_type_id": values.checkpoint_type_id,
//                     "checkpoint_type": values.checkpoint_type,
//                     "enable_addOptns": values.enable_addOptns,
//                     "checkpoint_options": values.checkpoint_options,
//                     "min_score": values.min_score,
//                     "max_score": values.max_score,
//                     "custom_tbx": values.custom_tbx,
//                     "impact_level": values.impact_level,
//                     "compl_type": values.compl_type,
//                     "creator_remarks": values.creator_remarks,
//                     "guideline": values.guideline,
//                     "guideline_image": values.guideline_image,
//                     "id": values.id,
//                     "parent": values.parent,
//                     "title": values.checkpoint,
//                     "subtitle": "",
//                     type: this.state.type,
//                 },
//                 getNodeKey: this.state.getNodeKey,
//             }),
//             crud: false,
//             editcrud: false,
//             childToggle: false,
//             mainToggle: false,
//         }),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     addNode = (node, path, getNodeKey, type) => {
//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })
//         this.setState({
//             type,
//             path: path,
//             getNodeKey: getNodeKey,
//             crud: true,
//             editcrud: false,
//             crudStatus: 1,
//             title: getNodeInfo.node.title,
//             getTitle: "",
//             getSubTitle: "",
//             getSubTitledd: "0",
//             id: getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0 ? getNodeInfo.node.id : getNodeInfo.node.children.length + 1,
//             parent: getNodeInfo.node.id,
//         })
//     }

//     editNode = (path, getNodeKey) => {

//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })

//         this.setState({
//             crud: false,
//             editcrud: true,
//             crudStatus: 2,
//             path: path,
//             getNodeInfo: getNodeInfo.node,
//             getNodeKey: getNodeKey,
//             getCode: getNodeInfo.node.code,
//             getTitle: getNodeInfo.node.title,
//             type: getNodeInfo.node.type,
//             id: getNodeInfo.node.id,
//             parent: getNodeInfo.node.parent,
//         })

//     }

//     deleteNode = async (node, path, getNodeKey, udp, dp) => {
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

//         this.setState(state => ({
//             treeData: removeNodeAtPath({
//                 treeData: state.treeData,
//                 path,
//                 getNodeKey,
//             }),
//             crud: false,
//         }),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     crudNode = (event, values) => {

//         console.log("value", values, this.state.totalHLength)
//         this.onDrawerClose()
//         if (this.state.crudStatus == 1) { //Add new node
//             this.setState(state => ({
//                 treeData: addNodeUnderParent({
//                     treeData: state.treeData,
//                     parentKey: this.state.path[this.state.path.length - 1],
//                     expandParent: true,
//                     getNodeKey: this.state.getNodeKey,
//                     newNode: {
//                         "company_id": this.state.userData.company_id,
//                         "owner_id": this.state.userData._id,
//                         "template_id": this.state.templateData._id,
//                         "document_id": this.state.totalHLength + 1,
//                         "parent_id": this.state.parent,
//                         "document_type": String(this.state.type),
//                         "checkpoint": values.title,
//                         "checkpoint_type_id": null,
//                         "checkpoint_type": null,
//                         "enable_addOptns": false,
//                         "checkpoint_options": [

//                         ],
//                         "min_score": 0,
//                         "max_score": 0,
//                         "custom_tbx": [

//                         ],
//                         "impact_level": null,
//                         "compl_type": [

//                         ],
//                         "creator_remarks": " ",
//                         "guideline": "",
//                         "guideline_image": [

//                         ],
//                         "id": this.state.totalHLength + 1,
//                         "parent": this.state.parent,
//                         "title": values.title,
//                         "subtitle": "",
//                         type: this.state.type,
//                     },
//                     addAsFirstChild: state.addAsFirstChild,
//                 }).treeData,
//                 crud: false,
//                 childToggle: false,
//                 mainToggle: false,
//                 editcrud: false,
//                 totalHLength: this.state.totalHLength + 1,
//             }
//             ),
//                 function () {
//                     this.saveHStructure()
//                 }
//             )
//         }
//         else
//             if (this.state.crudStatus == 2) // Edit node
//             {
//                 this.setState(state => ({
//                     treeData: changeNodeAtPath({
//                         treeData: this.state.treeData,
//                         path: this.state.path,
//                         expandParent: true,
//                         newNode: {
//                             children: this.state.getNodeInfo.children,
//                             "company_id": this.state.getNodeInfo.company_id,
//                             "owner_id": this.state.getNodeInfo.owner_id,
//                             "template_id": this.state.getNodeInfo.template_id,
//                             "document_type": this.state.getNodeInfo.document_type,
//                             "document_id": this.state.getNodeInfo.document_id,
//                             "parent_id": this.state.getNodeInfo.parent_id,
//                             "checkpoint": values.title,
//                             "checkpoint_type_id": this.state.getNodeInfo.checkpoint_type_id,
//                             "checkpoint_type": this.state.getNodeInfo.checkpoint_type,
//                             "enable_addOptns": this.state.getNodeInfo.enable_addOptns,
//                             "checkpoint_options": this.state.getNodeInfo.checkpoint_options,
//                             "min_score": this.state.getNodeInfo.min_score,
//                             "max_score": this.state.getNodeInfo.max_score,
//                             "custom_tbx": this.state.getNodeInfo.custom_tbx,
//                             "impact_level": this.state.getNodeInfo.impact_level,
//                             "compl_type": this.state.getNodeInfo.compl_type,
//                             "creator_remarks": this.state.getNodeInfo.creator_remarks,
//                             "guideline": this.state.getNodeInfo.guideline,
//                             "guideline_image": this.state.getNodeInfo.guideline_image,
//                             "title": values.title,
//                             "subtitle": "",
//                             "id": this.state.getNodeInfo.id,
//                             "parent": this.state.getNodeInfo.parent,
//                             type: this.state.getNodeInfo.type,
//                         },
//                         getNodeKey: this.state.getNodeKey,
//                     }),
//                     crud: false,
//                     editcrud: false,
//                     childToggle: false,
//                     mainToggle: false,
//                 }),
//                     function () {
//                         this.saveHStructure()
//                     }
//                 )
//             }
//             else
//                 if (this.state.crudStatus == 0) {

//                     this.setState(state => ({
//                         treeData: addNodeUnderParent({
//                             treeData: state.treeData,
//                             parentKey: this.state.path[this.state.path.length - 1],
//                             expandParent: true,
//                             getNodeKey: this.state.getNodeKey,
//                             newNode: {
//                                 "company_id": this.state.userData.company_id,
//                                 "owner_id": this.state.userData._id,
//                                 "template_id": this.state.templateData._id,
//                                 "document_id": this.state.totalHLength + 1,
//                                 "parent_id": null,
//                                 "document_type": String(this.state.type),
//                                 "checkpoint": values.title,
//                                 "checkpoint_type_id": null,
//                                 "checkpoint_type": null,
//                                 "enable_addOptns": false,
//                                 "checkpoint_options": [

//                                 ],
//                                 "min_score": 0,
//                                 "max_score": 0,
//                                 "custom_tbx": [

//                                 ],
//                                 "impact_level": null,
//                                 "compl_type": [

//                                 ],
//                                 "creator_remarks": [

//                                 ],
//                                 "guideline": "",
//                                 "guideline_image": [

//                                 ],
//                                 "id": this.state.totalHLength + 1,
//                                 "parent": null,
//                                 "title": values.title,
//                                 "subtitle": "",
//                                 "type": this.state.type,

//                             },
//                             addAsFirstChild: state.addAsFirstChild,
//                         }).treeData,
//                         crud: false,
//                         mainToggle: false,
//                         totalHLength: this.state.totalHLength + 1,
//                     }
//                     ),
//                         function () {
//                             this.saveHStructure()
//                         }
//                     )
//                 }
//     }


//     dndNode = (droppedNode, getNodeKey) => {
//         this.setState(state => ({
//             treeData: changeNodeAtPath({
//                 treeData: state.treeData,
//                 path: droppedNode.path,
//                 getNodeKey,
//                 newNode: {
//                     "company_id": droppedNode.node.company_id,
//                     "owner_id": droppedNode.node.owner_id,
//                     "template_id": droppedNode.node.template_id,
//                     "document_id": droppedNode.node.document_id,
//                     "parent_id": droppedNode.nextParentNode.id,
//                     "document_type": droppedNode.node.document_type,
//                     "checkpoint": droppedNode.node.checkpoint,
//                     "checkpoint_type_id": droppedNode.node.checkpoint_type_id,
//                     "checkpoint_type": droppedNode.node.checkpoint_type,
//                     "enable_addOptns": droppedNode.node.enable_addOptns,
//                     "checkpoint_options": droppedNode.node.checkpoint_options,
//                     "min_score": droppedNode.node.min_score,
//                     "max_score": droppedNode.node.max_score,
//                     "custom_tbx": droppedNode.node.custom_tbx,
//                     "impact_level": droppedNode.node.impact_level,
//                     "compl_type": droppedNode.node.compl_type,
//                     "creator_remarks": droppedNode.node.creator_remarks,
//                     "guideline": droppedNode.node.guideline,
//                     "guideline_image": droppedNode.node.guideline_image,
//                     "id": droppedNode.node.id,
//                     "parent": droppedNode.nextParentNode.id,
//                     "title": droppedNode.node.title,
//                     "subtitle": droppedNode.node.subtitle,
//                     type: droppedNode.node.type,
//                     children: droppedNode.node.children,
//                 },
//             }),
//             crud: false,
//         }),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     getNodeData(nodeData) {
//         this.setState({ nodeTitle: nodeData.type != 2 ? nodeData.title : "" })
//         if (nodeData.children != undefined) {
//             if (nodeData.children.length != 0) {
//                 var getNodeKey = ({ treeIndex }) => treeIndex
//                 var flatData = getFlatDataFromTree(
//                     {
//                         treeData: nodeData.children,
//                         getNodeKey,
//                         ignoreCollapsed: false,
//                     })

//                 var explicitData = _.filter(flatData, item => {
//                     if (item.node.type == 2)
//                         return { node: item.node, parent: item.parentNode }
//                 })

//                 console.log("data", (explicitData))
//                 this.setState({ nodeChildren: explicitData })
//             }
//         }
//         else {
//             if (nodeData.type == 2) {
//                 this.setState({ nodeChildren: [{ node: nodeData, parentNode: null }] })
//             }
//             else {
//                 this.setState({ nodeChildren: [] })
//             }
//         }
//     }






//     toggle() {
//         this.setState(prevState => ({
//             mainToggle: !prevState.mainToggle,
//         }))
//     }

//     childtoggle(udp, dp) {
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")
//     }

//     handleUserClicks = arg => {
//         this.setState({ users: '', isEdit: false })
//         this.toggle()
//     }

//     changeAuditEditMode = (status) => { this.setState({ changeToUpdate: status, showCloseButton: status, modal: !this.state.modal ? true : false }) }

//     setPosition(e) {
//         this.setState({ position: e.target.value });
//     }

//     closeDrawer() {
//         this.setState({ open: false });
//     }
//     onDrawerClose() {
//         this.setState({ open: false });
//     }

//     toggleToolTip = targetName => {
//         if (!this.state[targetName]) {
//             this.setState({
//                 ...this.state,
//                 [targetName]: {
//                     tooltipOpen: true
//                 }
//             });
//         } else {
//             this.setState({
//                 ...this.state,
//                 [targetName]: {
//                     tooltipOpen: !this.state[targetName].tooltipOpen
//                 }
//             });
//         }
//     };

//     isToolTipOpen = targetName => {
//         return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
//     };





//     render() {

//         const { department_name } = this.state
//         const { isEdit } = this.state
//         if (this.state.dataLoaded) {
//             const getNodeKey = ({ treeIndex }) => treeIndex;
//             return (
//                 <React.Fragment>






//                     <div className="page-content" >
//                         <label>Edit Template</label>
//                         <div className="row">
//                             <div style={{ maxWidth: '400px', maxHeight: '100vh', height: '100vh', overflow:'auto'}}>
//                                 <Card >
//                                     <CardBody>
//                                         <div className="mb-2" >
//                                             <UncontrolledDropdown isOpen={this.state.mainToggle} toggle={() => this.toggle()}>
//                                                 <DropdownToggle
//                                                     className="btn btn-light dropdown-toggle w-100 "
//                                                     color="#eff2f7"
//                                                     type="button"
//                                                     onClick={() => this.addMainNode(0)}
//                                                 >
//                                                     <i className="mdi mdi-plus me-1 "></i> Create New
//                                                 </DropdownToggle>
//                                                 <DropdownMenu style={{ width: 250 }} className="dropdown-toggle">
//                                                     <div className="p-4">
//                                                         <AvForm onValidSubmit={this.crudNode}>
//                                                             <div className="my-2">
//                                                                 <AvField
//                                                                     name="title"
//                                                                     label="Menu Name "
//                                                                     placeholder="Enter Menu"
//                                                                     type="text"
//                                                                     errorMessage="Enter Menu"
//                                                                     validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                     defaultValue={""}
//                                                                     onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                 />
//                                                             </div>
//                                                             <div className="my-3">
//                                                                 <button className="btn btn-primary btn-block m-1"
//                                                                     type="submit" style={{ marginRight: 5 }}>Add Menu</button>
//                                                             </div>
//                                                         </AvForm>
//                                                     </div>
//                                                     <div className="dropdown-divider"></div>
//                                                     <Link className="dropdown-item" to="#"
//                                                         onClick={() => this.addParameterAsMain(2)}
//                                                     >
//                                                         <i className="bx bx-file me-1"></i> Add Check point
//                                                     </Link>
//                                                 </DropdownMenu>
//                                             </UncontrolledDropdown>
//                                         </div>
//                                         <div style={{ height: '90vh', overflow: 'auto' }} >
//                                             <SortableTree
//                                                 treeData={this.state.treeData}
//                                                 onChange={(treeData, node) => {
//                                                     this.setState({ treeData, crud: false })
//                                                 }}
//                                                 onMoveNode={object => this.dndNode(object, getNodeKey)}
//                                                 canDrop={object => object.nextParent.type == 2 ? false : true}
//                                                 scaffoldBlockPxWidth={25}
//                                                 slideRegionSize={25}
//                                                 rowHeight={50}
//                                                 generateNodeProps={({ node, path }) => ({
//                                                     listIndex: 0,
//                                                     lowerSiblingCounts: [],
//                                                     rowdirection: "rtl",
//                                                     className: node.type == 0 ? `${"icon-a"}` : `${"icon-b"}`,
//                                                     onClick: (event) => {
//                                                         if (event.target.className.includes('collapseButton') || event.target.className.includes('expandButton')) {
//                                                         }
//                                                         else {
//                                                             this.setState({ selectedNodeId: node.id, nodeSelected: true })
//                                                             this.getNodeData(node)
//                                                         }
//                                                     },
//                                                     style: { fontSize: 14, border: this.state.selectedNodeId == node.id ? "2px solid #f1b44c" : '1px solid #74788d' },

//                                                     title: (
//                                                         <div >
//                                                             <div style={{ maxWidth: 500, }} key={`div-${node.id}`} >

//                                                                 <div>
//                                                                     <Tooltip
//                                                                         placement="bottom"
//                                                                         isOpen={this.isToolTipOpen(`btn-${node.id}`)}
//                                                                         target={`btn-${node.id}`}
//                                                                         toggle={() => this.toggleToolTip(`btn-${node.id}`)}
//                                                                     >
//                                                                         {node.title}
//                                                                     </Tooltip>
//                                                                     <Link
//                                                                         to="#"
//                                                                         id={`btn-${node.id}`}
//                                                                         style={{ fontSize: 12, fontWeight: 500 }}
//                                                                     // onClick={()=>{}
//                                                                     >
//                                                                         {String(node.title).slice(0, 20) + (node.title.length > 20 ? "..." : "")}
//                                                                     </Link>
//                                                                 </div>
//                                                             </div>
//                                                         </div>

//                                                     ),

//                                                     buttons: [
//                                                         <div>

//                                                             <UncontrolledDropdown className="mb-0 bg-white " direction="right"
//                                                                 id={"udp" + String(node.id)}
//                                                             //onClick={()=>this.setState({crud:false, editcrud:false})} 
//                                                             >
//                                                                 <DropdownToggle
//                                                                     color="white"
//                                                                     className="font-size-16 text-muted dropdown-toggle"
//                                                                     tag="a"

//                                                                 >
//                                                                     <i className="mdi mdi-dots-vertical font-size-16"></i>
//                                                                 </DropdownToggle>

//                                                                 <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)} >
//                                                                     {!this.state.crud && node.type == 0 ? <Link className="dropdown-item " to="#"
//                                                                         onClick={
//                                                                             () => this.addNode(node, path, getNodeKey, 0)
//                                                                         }
//                                                                     > Add Sub Menu
//                                                                     </Link> : null}
//                                                                     {
//                                                                         this.state.crud && node.type == 0 ?
//                                                                             <div className="px-4">
//                                                                                 <AvForm onValidSubmit={this.crudNode}>
//                                                                                     <div className="my-2">
//                                                                                         <AvField
//                                                                                             name="title"
//                                                                                             label="Sub Menu Name "
//                                                                                             placeholder="Enter Sub Menu"
//                                                                                             type="text"
//                                                                                             errorMessage="Enter Menu"
//                                                                                             validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                                             defaultValue={""}
//                                                                                             onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                                         />
//                                                                                     </div>
//                                                                                     <div className="my-3">
//                                                                                         <button className="btn btn-primary btn-block m-1"
//                                                                                             type="submit" onClick={() => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Add Menu</button>
//                                                                                     </div>
//                                                                                 </AvForm>
//                                                                             </div>
//                                                                             : null
//                                                                     }

//                                                                     {!this.state.editcrud && node.type == 0 ? <div>
//                                                                         <div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={
//                                                                                 () => this.editNode(path, getNodeKey)
//                                                                             }
//                                                                         > Edit Menu
//                                                                         </Link> </div> : null}
//                                                                     {
//                                                                         this.state.editcrud ?
//                                                                             <div className="px-4">
//                                                                                 <AvForm onValidSubmit={this.crudNode}>
//                                                                                     <div className="my-2">
//                                                                                         <AvField
//                                                                                             name="title"
//                                                                                             label="Edit Menu Name "
//                                                                                             placeholder="Enter Menu Name"
//                                                                                             type="text"
//                                                                                             errorMessage="Enter Menu"
//                                                                                             validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                                             defaultValue={this.state.getTitle}
//                                                                                             onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                                         />
//                                                                                     </div>
//                                                                                     <div className="my-3">
//                                                                                         <button className="btn btn-primary btn-block m-1"
//                                                                                             type="submit" onClick={(event) => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Update</button>
//                                                                                     </div>
//                                                                                 </AvForm>
//                                                                             </div>
//                                                                             : null
//                                                                     }
//                                                                     {node.type == 0 ? <div><div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={
//                                                                                 () => this.addCheckpoint(node, path, getNodeKey, 2, "udp" + String(node.id), "dp" + String(node.id))
//                                                                             }
//                                                                         > Add Check point
//                                                                         </Link></div> : null}
//                                                                     {node.type == 2 ? <div><div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={
//                                                                                 () => this.editCheckpoint(path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))
//                                                                             }
//                                                                         > Edit Check point
//                                                                         </Link></div> : null}
//                                                                     <div className="dropdown-divider"></div>
//                                                                     <Link className="dropdown-item text-danger" to="#"
//                                                                         onClick={() => this.deleteNode(node, path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))}
//                                                                     > {node.type != 2 ? "Delete Menu" : "Delete Check point"} <i className="mdi mdi-delete font-size-18"
//                                                                         style={{ margin: 5 }}
//                                                                     ></i></Link>
//                                                                 </DropdownMenu>
//                                                             </UncontrolledDropdown>
//                                                         </div>
//                                                     ]
//                                                 })}

//                                             />
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </div>
//                             <div className="col" style={{ maxHeight: '100vh', height: '100vh', overflow: 'auto' }}>
//                                 <div >
//                                     <Alert color="success" isOpen={this.state.successmessage} ><strong>Well done! </strong> {this.state.updateTemplateStatus}</Alert>
//                                     {
//                                         this.state.changeToUpdate ?
//                                             <Card>
//                                                 <CardBody>
//                                                     <div>
//                                                         <AvForm onValidSubmit={this.handleValidUserSubmit} >
//                                                             <Row>
//                                                                 <Col >
//                                                                     <div className="my-3">
//                                                                         <div >
//                                                                             <div className="my-2">
//                                                                                 <AvField
//                                                                                     name="template_name"
//                                                                                     label="Audit Name "
//                                                                                     placeholder="Enter Audit Name"
//                                                                                     type="text"
//                                                                                     errorMessage="Enter Audit Name"
//                                                                                     validate={{ required: { value: true }, minLength: { value: 6, errorMessage: "Min 6 chars." } }}
//                                                                                     defaultValue={this.state.template_name}
//                                                                                     onChange={(e) => { this.setState({ template_name: e.target.value }) }}
//                                                                                 />
//                                                                             </div>
//                                                                             <div className="my-1">
//                                                                                 <label>Description</label>
//                                                                                 <AvField type="textarea" name="template_info" id="message" defaultValue={this.state.template_info} onChange={(e) => { this.setState({ template_info: e.target.value }) }} />
//                                                                             </div>
//                                                                             <div className="my-3">
//                                                                                 <button className="btn btn-primary btn-block" style={{ marginRight: 5 }}>Update</button>
//                                                                                 <button className="btn btn-danger btn-block" onClick={() => this.setState({ changeToUpdate: false })}>Close</button>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>
//                                                         </AvForm>
//                                                     </div>
//                                                 </CardBody>
//                                             </Card>
//                                             :
//                                             <div>
//                                                 <Card>
//                                                     <CardBody>
//                                                         <Row>
//                                                             <Col md={6}>
//                                                                 <div className="my-1">
//                                                                     <h5 className="text-wrap font-size-15">
//                                                                         <span className="text-primary">{this.state.template_name}&nbsp;&nbsp;&nbsp;</span>
//                                                                         {this.state.templateData.tmplt_ctd_by == "0" ? null : <i className="fas fa-edit" onClick={() => this.changeAuditEditMode(true)}></i>}</h5>
//                                                                     <span className="text-muted " >{this.state.template_info} </span>
//                                                                 </div>
//                                                             </Col>
//                                                             <Col md={6}>
//                                                                 <div style={{ textAlign: 'right' }} className="mb-2">
//                                                                     <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//                                                                 </div>
//                                                             </Col>
//                                                         </Row>
//                                                     </CardBody>
//                                                 </Card>
//                                             </div>
//                                     }
//                                 </div>
//                                 <div>
//                                     {
//                                         this.state.nodeSelected ?
//                                             <div style={{ height: '100vh', overflow: 'auto' }} >
//                                                 <div className={this.state.nodeTitle != "" ? 'm-2 p-3 bg-white' : ''}>
//                                                     <span>{this.state.nodeTitle}</span>
//                                                 </div>
//                                                 {

//                                                     this.state.nodeChildren.map((item, i) => {
//                                                         var getColor = item.node.impact_level == 'Critical' ? '#f46a6a' :
//                                                             item.node.impact_level == 'High' ? '#f1b44c' :
//                                                                 item.node.impact_level == 'Medium' ? '#50a5f1' :
//                                                                     item.node.impact_level == 'Low' ? '#34c38f' : '#556ee6'
//                                                         return (
//                                                             <div className='m-2 d-flex flex-column p-3 bg-white' key={"cp" + i} style={{ borderBottom: '1px solid', borderColor: getColor }} >
//                                                                 <div>
//                                                                     <span className='font-size-10 text-secondary'>{item.parentNode != null ? item.parentNode.title : ""}</span>
//                                                                 </div>
//                                                                 <div className='my-2'>
//                                                                     <span style={{ color: getColor }}>{String(i).length > 1 ? (i + 1) : "0" + (i + 1)}</span>{" "}<span className='font-size-13'>{item.node.checkpoint}</span>
//                                                                 </div>
//                                                                 <div className='my-2'>
//                                                                     {
//                                                                         item.node.checkpoint_options.map((child, idx) => (
//                                                                             <div className='d-flex flex-column p-2 my-1' style={{ border: '1px solid #f0f0f0', borderRadius: 10 }} key={"cp" + i + "ptn" + idx}>
//                                                                                 <span>{child.option_text} {" "} {child.enable_img ? <i className='mdi mdi-camera-plus' /> : null} {" "} {child.enable_doc ? <i className='mdi mdi-file-document' /> : null}</span>
//                                                                             </div>
//                                                                         ))
//                                                                     }
//                                                                 </div>
//                                                                 {
//                                                                     item.node.impact_level != "" ?
//                                                                         <div className='my-2'>
//                                                                             <span className='badge badge-soft p-1 font-size-12' style={{ backgroundColor: getColor }}>{item.node.impact_level}</span>
//                                                                         </div> : null
//                                                                 }
//                                                             </div>
//                                                         )
//                                                     }
//                                                     )
//                                                 }
//                                             </div>
//                                             :
//                                             <div >
//                                                 <Card >
//                                                     <CardBody style={{ height: '100vh' }}>

//                                                     </CardBody>
//                                                 </Card>
//                                             </div>
//                                     }
//                                 </div>
//                             </div>

//                             {
//                                 this.state.saveAndSuccess && (
//                                     <SweetAlert
//                                         title={this.state.messageHeading}
//                                         danger={this.state.messageHeading == "Delete" ? true : false}
//                                         success={this.state.messageHeading == "Success" ? true : false}
//                                         showCancel={this.state.messageHeading == "Success" ? false : true}
//                                         confirmBtnBsStyle={this.state.messageHeading == "Success" ? "success" : "danger"}
//                                         cancelBtnBsStyle={this.state.messageHeading == "Delete" ? "success" : "danger"}
//                                         onConfirm={() => this.confirmation()}
//                                         onCancel={() => this.setState({ saveAndSuccess: false, })}
//                                     >{this.state.message}</SweetAlert>
//                                 )
//                             }


//                         </div>

//                         <div className="mt-2">
//                             <Card>
//                                 <CardBody>
//                                     <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: 5 }}>
//                                         <div className='col-4' >
//                                             <UncontrolledAlert
//                                                 color="success"
//                                                 className="alert-dismissible fade show"
//                                                 role="alert"
//                                                 isOpen={this.state.successmsgshow}
//                                                 style={{ margin: 0 }}
//                                             >
//                                                 <i className="mdi mdi-check-all me-2"></i>{this.state.alertMessage}
//                                             </UncontrolledAlert>
//                                         </div>
//                                         <div className="col-4 m-1" >
//                                             <Button className="btn btn-primary w-100" onClick={() => this.navigateTo()}> Go back </Button>
//                                         </div>
//                                         <div className="col-4 m-1" >
//                                             <Button className={this.state.treeData.length > 0 ? "btn btn-success w-100" : "btn btn-secondary w-100"} onClick={() => this.navigateTo()} disabled={this.state.treeData.length > 0 ? false : true}> Save </Button>
//                                         </div>
//                                     </div>

//                                 </CardBody>
//                             </Card>
//                         </div>

//                     </div>

//                     <ReactDrawer
//                         open={this.state.open}
//                         className="drawer-open"
//                         position={this.state.position}
//                         onClose={this.onDrawerClose}
//                         style={{ maxHeight: 1000 }}
//                         noOverlay={false}
//                     >

//                         <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>
//                             {this.state.open ? <InputTemplate
//                                 checkpointinfo={this.state.checkpointInfo}
//                                 configdatacheckpoint={this.state.configData.question_type_info}
//                                 mode={this.state.mode}
//                                 parameterData={true}
//                                 onSubmit={(values) => { this.AECheckPointUnderNode(values, this.state.mode); console.log("values", values) }}
//                             /> : null}
//                         </div>
//                     </ReactDrawer>

//                 </React.Fragment>
//             )
//         }
//         else {
//             return (
//                 <React.Fragment>
//                     <div className="page-content" style={{ minHeight: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//                             <div
//                                 className="spinner-border text-secondary m-1"
//                                 role="status"
//                             > </div>
//                             <div>Loading, Please wait.</div>
//                         </div>
//                     </div>
//                 </React.Fragment>
//             )
//         }
//     }
// }


































// import React, { Component } from "react"
// import { Link } from "react-router-dom"
// import {
//     Container, Row, Col, Button, Alert, Input, Card, CardBody,
//     Modal, ModalHeader, ModalBody,
//     Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
// } from "reactstrap"
// import ReactDrawer from 'react-drawer';
// import { DatePicker, Drawer, Badge } from 'antd';
// import { AvForm, AvField } from "availity-reactstrap-validation"
// import Select from "react-select"
// import SweetAlert from "react-bootstrap-sweetalert"
// import ParentChildBtn from "../../components/ParentChildBtn"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
// import InputTemplate from "./Components/InputTemplate"
// import 'react-drawer/lib/react-drawer.css';

// import $ from 'jquery';

// import SortableTree, {
//     addNodeUnderParent,
//     removeNodeAtPath,
//     toggleExpandedForAll,
//     getTreeFromFlatData,
//     getNodeAtPath,
//     changeNodeAtPath,
//     getFlatDataFromTree,
//     walk,
//     map, find
// } from 'react-sortable-tree/dist/index.cjs.js';
// //import SortableTree from 'react-sortable-tree';
// //import 'react-sortable-tree/style.css';
// //import './react-tree-style.css';
// import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
// //import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';



// import "react-perfect-scrollbar/dist/css/styles.css"
// import "./manageAudit.css"

// import "react-rangeslider/lib/index.css"
// const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")

// export default class editPublishedTemplate extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             height: props.height,
//             successmessage: false,
//             changeToUpdate: false,
//             dataLoaded: false,
//             open: false,
//             position: 'right',
//             height: props.height,
//             successmsgshow: false,
//             nodeChildren: [],
//             nodeTitle: "",
//             nodeSelected:false,
//             treeData:[],
//         }
//         this.toggle = this.toggle.bind(this)
//         this.childtoggle = this.childtoggle.bind(this)
//         this.handleUserClicks = this.handleUserClicks.bind(this)

//         this.closeDrawer = this.closeDrawer.bind(this);
//         this.onDrawerClose = this.onDrawerClose.bind(this);
//         this.setPosition = this.setPosition.bind(this);
//     }

//     componentWillMount() {
//         var templateData = JSON.parse(sessionStorage.getItem("EditPublishedData"))
//         var navigationMode = sessionStorage.getItem("nagiationMode");
//         var data = JSON.parse(sessionStorage.getItem("authUser"))
//         var db_info = JSON.parse(sessionStorage.getItem("db_info"))


//         this.setState({
//             height: window.innerHeight,
//             configData: data.config_data,
//             userData: data.user_data,
//             templateData,
//             template_name: templateData.template_name,
//             template_info: templateData.template_info,
//             configdatacheckpoint: data.config_data.question_type_info,
//             navigationMode,
//             dataLoaded: false,
//             db_info:db_info
//         },
//             function () {
//                 this.getDocuments()
//             }
//         )
//     }

//     handleValidUserSubmit = (event, values) => {
//         console.log("values", values)
//         try {
//             urlSocket.post("webmngpbhtmplt/crudpublishedtemplate", {
//                 templateInfo: {
//                     template_id: this.state.templateData._id,
//                     template_name: values.template_name,
//                     template_info: values.template_info
//                 },
//                 userInfo: {
//                     created_by: this.state.userData._id,
//                     company_id: this.state.userData.company_id,
//                     encrypted_db_url : this.state.db_info.encrypted_db_url
//                 }
//             })
//                 .then((response) => {
//                     console.log("update template info", response.data)
//                     if (response.data.response_code == 500) {
//                         this.setState({
//                             successmessage: true,
//                             updateTemplateStatus: response.data.message,
//                             changeToUpdate: false,
//                             template_name: this.state.template_name,
//                             template_info: this.state.template_info,
//                         })
//                         setTimeout(() => { this.setState({ successmessage: false, }) }, 2500)
//                     }
//                 })
//         } catch (error) {

//         }
//     }


//     navigateTo=()=>
//     {
//         if(this.state.navigationMode == "from_config")
//         {
//             sessionStorage.removeItem("nagiationMode");
//             this.props.history.push("/pblhcfg")
//         }
//         else
//         {
//             sessionStorage.removeItem("nagiationMode");
//             this.props.history.push("/mngpblhtempt")
//         }
//     }

//     treeDataToFlat = () => {
//         // console.log("this.state.treeData", this.state.treeData)
//         var getNodeKey = ({ treeIndex }) => treeIndex
//         var flatData = getFlatDataFromTree(
//             {
//                 treeData: this.state.treeData,
//                 getNodeKey,
//                 ignoreCollapsed: false,
//             })

//         var explicitData = _.filter(flatData, item => {
//             return item
//         })

//         return explicitData
//     }

//     saveHStructure = () => {
//         var explicitData = this.treeDataToFlat()
//         console.log("explicitData", explicitData)
//         this.saveData(explicitData)
//     }

//     saveData = (flatData) => {
//         this.setState({ saving: true })
//         try {
//             urlSocket.post("webmngpbhtmplt/savepblhcpstructure", {
//                 templateInfo: {
//                     template_id: this.state.templateData._id,
//                     flatData: _.map(flatData, "node"),
//                     checkpointlength:_.filter(_.map(flatData, "node"),{type:2}).length,
//                     lastGeneratedId: this.state.totalHLength
//                 },
//                 userInfo: {
//                     created_by: this.state.userData._id,
//                     company_id: this.state.userData.company_id
//                 }
//             })
//                 .then((response) => {
//                     console.log("response.data", response.data)
//                     if (response.data.response_code == 500) {
//                         this.setState({ saving: false, successmsgshow: true, alertMessage: response.data.message })
//                         setTimeout(() => { this.setState({ successmsgshow: false, }) }, 2500)

//                     }
//                 })
//         } catch (error) {

//         }
//     }


//     getDocuments() {
//         console.log("templateData", this.state.templateData)
//         try {
//             urlSocket.post('/webmngpbhtmplt/getpblhtemplatestructure', {
//                 templateInfo: {
//                     template_id: this.state.templateData._id,
//                     tmplatedBy: this.state.templateData.tmplt_ctd_by
//                 },
//                 userInfo: {
//                     encrypted_db_url:this.state.db_info.encrypted_db_url,
//                     created_by: this.state.userData._id,
//                     company_id: this.state.userData.company_id
//                 }

//             })
//                 .then(response => {

//                     var FlatData = response.data.data.template_structure
//                     console.log("response", response.data.data)
//                     this.setState(
//                         {
//                             dataLoaded: FlatData.length > 0 ? false : true,
//                             totalHLength: response.data.data.lastGeneratedId
//                         },
//                         function () {
//                             this.convertFlatDataToTreeData(FlatData)
//                         }
//                     )
//                 })
//                 .catch(error => {

//                 })
//         } catch (error) {
//             console.log("catch error", error)
//         }
//     }

//     convertFlatDataToTreeData = (FlatData) => {

//         this.setState({
//             treeData: getTreeFromFlatData(
//                 {
//                     flatData: FlatData,
//                     getKey: (node) => node.id,
//                     getParentKey: (node) => node.parent,
//                     rootKey: null,
//                     expanded: true
//                 }),
//             dataLoaded: true,
//         }
//         )
//     }




//     addMainNode(type) {

//         const getNodeKey = ({ treeIndex }) => treeIndex;
//         this.setState({
//             path: [],
//             getNodeKey: getNodeKey,
//             crud: true,
//             crudStatus: 0,
//             type,
//         })
//     }

//     addParameterAsMain(type,) {

//         const getNodeKey = ({ treeIndex }) => treeIndex;
//         this.setState({
//             path: [],
//             getNodeKey: getNodeKey,
//             crud: false,
//             crudStatus: 0,
//             type,
//             position: 'right',
//             mainToggle: false,
//             open: !this.state.open,
//             //qTypeInfo: this.state.configData.question_type_info,
//             configdatacheckpoint: this.state.configData.question_type_info
//         })
//     }

//     addCheckpoint = (node, path, getNodeKey, type, udp, dp) => {
//         this.setState({ position: 'right' });
//         this.setState({ open: !this.state.open });
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })
//         this.setState({
//             type,
//             path: path,
//             getNodeKey: getNodeKey,
//             crud: false,
//             editcrud: false,
//             crudStatus: 1,
//             title: getNodeInfo.node.title,
//             getTitle: "",
//             getSubTitle: "",
//             getSubTitledd: "0",
//             id: getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0 ? getNodeInfo.node.id : getNodeInfo.node.children.length + 1,
//             parent: getNodeInfo.node.id,
//             mode: "0",
//             //checkpointInfo: this.state.configData.question_type_info,
//             configdatacheckpoint: this.state.configData.question_type_info
//         })
//     }

//     AECheckPointUnderNode(values, mode) {
//         console.log("this.state.configdatacheckpoint", this.state.configdatacheckpoint)
//         this.setState({ open: !this.state.open, })
//         if (mode == "0") {
//             this.addCheckPointUnderNode(values)
//         }
//         else
//             if (mode == "1") {
//                 this.editCheckpointAtPath(values)
//             }
//     }

//     addCheckPointUnderNode(values) {
//         this.setState(state => ({
//             treeData: addNodeUnderParent({
//                 treeData: state.treeData,
//                 parentKey: this.state.path[this.state.path.length - 1],
//                 expandParent: true,
//                 getNodeKey: this.state.getNodeKey,
//                 newNode: {
//                     "company_id": this.state.userData.company_id,
//                     "owner_id": this.state.userData._id,
//                     "template_id": this.state.templateData._id,
//                     "document_id": this.state.totalHLength + 1,
//                     "parent_id": this.state.parent,
//                     "document_type": String(this.state.type),
//                     "checkpoint": values.checkpoint,
//                     "checkpoint_type_id": values.checkpoint_type_id,
//                     "checkpoint_type": values.checkpoint_type,
//                     "enable_addOptns": values.enable_addOptns,
//                     "checkpoint_options": values.checkpoint_options,
//                     "min_score": values.min_score,
//                     "max_score": values.max_score,
//                     "custom_tbx": values.custom_tbx,
//                     "impact_level": values.impact_level,
//                     "compl_type": values.compl_type,
//                     "creator_remarks": values.creator_remarks,
//                     "guideline": values.guideline,
//                     "guideline_image": values.guideline_image,
//                     "id": this.state.totalHLength + 1,
//                     "parent": this.state.parent,
//                     "title": values.checkpoint,
//                     "subtitle": "",
//                     type: this.state.type,
//                 },
//                 //addAsFirstChild: state.addAsFirstChild,
//             }).treeData,
//             crud: false,
//             childToggle: false,
//             mainToggle: false,
//             editcrud: false,
//             totalHLength: this.state.totalHLength + 1,
//         }
//         ),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     editCheckpoint = (path, getNodeKey, udp, dp) => {

//         this.setState({ position: 'right' });
//         this.setState({ open: !this.state.open });
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })

//         console.log("getNodeInfo.node", getNodeInfo.node)

//         this.setState({
//             crud: false,
//             editcrud: false,
//             crudStatus: 2,
//             path: path,
//             getNodeInfo: getNodeInfo.node,
//             getNodeKey: getNodeKey,
//             getCode: getNodeInfo.node.code,
//             getTitle: getNodeInfo.node.title,
//             type: getNodeInfo.node.type,
//             mode: "1",
//             checkpointInfo: [getNodeInfo.node],
//         })

//     }

//     editCheckpointAtPath(values) {
//         this.setState(state => ({
//             treeData: changeNodeAtPath({
//                 treeData: this.state.treeData,
//                 path: this.state.path,
//                 expandParent: true,
//                 newNode: {
//                     children: this.state.getNodeInfo.children,
//                     "company_id": values.company_id,
//                     "owner_id": values.owner_id,
//                     "template_id": values.template_id,
//                     "document_id": values.document_id,
//                     "parent_id": values.parent_id,
//                     "document_type": values.document_type,
//                     "checkpoint": values.checkpoint,
//                     "checkpoint_type_id": values.checkpoint_type_id,
//                     "checkpoint_type": values.checkpoint_type,
//                     "enable_addOptns": values.enable_addOptns,
//                     "checkpoint_options": values.checkpoint_options,
//                     "min_score": values.min_score,
//                     "max_score": values.max_score,
//                     "custom_tbx": values.custom_tbx,
//                     "impact_level": values.impact_level,
//                     "compl_type": values.compl_type,
//                     "creator_remarks": values.creator_remarks,
//                     "guideline": values.guideline,
//                     "guideline_image": values.guideline_image,
//                     "id": values.id,
//                     "parent": values.parent,
//                     "title": values.checkpoint,
//                     "subtitle": "",
//                     type: this.state.type,
//                 },
//                 getNodeKey: this.state.getNodeKey,
//             }),
//             crud: false,
//             editcrud: false,
//             childToggle: false,
//             mainToggle: false,
//         }),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     addNode = (node, path, getNodeKey, type) => {
//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })
//         this.setState({
//             type,
//             path: path,
//             getNodeKey: getNodeKey,
//             crud: true,
//             editcrud: false,
//             crudStatus: 1,
//             title: getNodeInfo.node.title,
//             getTitle: "",
//             getSubTitle: "",
//             getSubTitledd: "0",
//             id: getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0 ? getNodeInfo.node.id : getNodeInfo.node.children.length + 1,
//             parent: getNodeInfo.node.id,
//         })
//     }

//     editNode = (path, getNodeKey) => {

//         var getNodeInfo = getNodeAtPath({
//             treeData: this.state.treeData,
//             path,
//             getNodeKey,
//         })

//         this.setState({
//             crud: false,
//             editcrud: true,
//             crudStatus: 2,
//             path: path,
//             getNodeInfo: getNodeInfo.node,
//             getNodeKey: getNodeKey,
//             getCode: getNodeInfo.node.code,
//             getTitle: getNodeInfo.node.title,
//             type: getNodeInfo.node.type,
//             id: getNodeInfo.node.id,
//             parent: getNodeInfo.node.parent,
//         })

//     }

//     deleteNode = async (node, path, getNodeKey, udp, dp) => {
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")

//         this.setState(state => ({
//             treeData: removeNodeAtPath({
//                 treeData: state.treeData,
//                 path,
//                 getNodeKey,
//             }),
//             crud: false,
//         }),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     crudNode = (event, values) => {

//         console.log("value", values, this.state.totalHLength)
//         this.onDrawerClose()
//         if (this.state.crudStatus == 1) { //Add new node
//             this.setState(state => ({
//                 treeData: addNodeUnderParent({
//                     treeData: state.treeData,
//                     parentKey: this.state.path[this.state.path.length - 1],
//                     expandParent: true,
//                     getNodeKey: this.state.getNodeKey,
//                     newNode: {
//                         "company_id": this.state.userData.company_id,
//                         "owner_id": this.state.userData._id,
//                         "template_id": this.state.templateData._id,
//                         "document_id": this.state.totalHLength + 1,
//                         "parent_id": this.state.parent,
//                         "document_type": String(this.state.type),
//                         "checkpoint": values.title,
//                         "checkpoint_type_id": null,
//                         "checkpoint_type": null,
//                         "enable_addOptns": false,
//                         "checkpoint_options": [

//                         ],
//                         "min_score": 0,
//                         "max_score": 0,
//                         "custom_tbx": [

//                         ],
//                         "impact_level": null,
//                         "compl_type": [

//                         ],
//                         "creator_remarks": " ",
//                         "guideline": "",
//                         "guideline_image": [

//                         ],
//                         "id": this.state.totalHLength + 1,
//                         "parent": this.state.parent,
//                         "title": values.title,
//                         "subtitle": "",
//                         type: this.state.type,
//                     },
//                     addAsFirstChild: state.addAsFirstChild,
//                 }).treeData,
//                 crud: false,
//                 childToggle: false,
//                 mainToggle: false,
//                 editcrud: false,
//                 totalHLength: this.state.totalHLength + 1,
//             }
//             ),
//                 function () {
//                     this.saveHStructure()
//                 }
//             )
//         }
//         else
//             if (this.state.crudStatus == 2) // Edit node
//             {
//                 this.setState(state => ({
//                     treeData: changeNodeAtPath({
//                         treeData: this.state.treeData,
//                         path: this.state.path,
//                         expandParent: true,
//                         newNode: {
//                             children: this.state.getNodeInfo.children,
//                             "company_id": this.state.getNodeInfo.company_id,
//                             "owner_id": this.state.getNodeInfo.owner_id,
//                             "template_id": this.state.getNodeInfo.template_id,
//                             "document_type": this.state.getNodeInfo.document_type,
//                             "document_id": this.state.getNodeInfo.document_id,
//                             "parent_id": this.state.getNodeInfo.parent_id,
//                             "checkpoint": values.title,
//                             "checkpoint_type_id": this.state.getNodeInfo.checkpoint_type_id,
//                             "checkpoint_type": this.state.getNodeInfo.checkpoint_type,
//                             "enable_addOptns": this.state.getNodeInfo.enable_addOptns,
//                             "checkpoint_options": this.state.getNodeInfo.checkpoint_options,
//                             "min_score": this.state.getNodeInfo.min_score,
//                             "max_score": this.state.getNodeInfo.max_score,
//                             "custom_tbx": this.state.getNodeInfo.custom_tbx,
//                             "impact_level": this.state.getNodeInfo.impact_level,
//                             "compl_type": this.state.getNodeInfo.compl_type,
//                             "creator_remarks": this.state.getNodeInfo.creator_remarks,
//                             "guideline": this.state.getNodeInfo.guideline,
//                             "guideline_image": this.state.getNodeInfo.guideline_image,
//                             "title": values.title,
//                             "subtitle": "",
//                             "id": this.state.getNodeInfo.id,
//                             "parent": this.state.getNodeInfo.parent,
//                             type: this.state.getNodeInfo.type,
//                         },
//                         getNodeKey: this.state.getNodeKey,
//                     }),
//                     crud: false,
//                     editcrud: false,
//                     childToggle: false,
//                     mainToggle: false,
//                 }),
//                     function () {
//                         this.saveHStructure()
//                     }
//                 )
//             }
//             else
//                 if (this.state.crudStatus == 0) {

//                     this.setState(state => ({
//                         treeData: addNodeUnderParent({
//                             treeData: state.treeData,
//                             parentKey: this.state.path[this.state.path.length - 1],
//                             expandParent: true,
//                             getNodeKey: this.state.getNodeKey,
//                             newNode: {
//                                 "company_id": this.state.userData.company_id,
//                                 "owner_id": this.state.userData._id,
//                                 "template_id": this.state.templateData._id,
//                                 "document_id": this.state.totalHLength + 1,
//                                 "parent_id": null,
//                                 "document_type": String(this.state.type),
//                                 "checkpoint": values.title,
//                                 "checkpoint_type_id": null,
//                                 "checkpoint_type": null,
//                                 "enable_addOptns": false,
//                                 "checkpoint_options": [

//                                 ],
//                                 "min_score": 0,
//                                 "max_score": 0,
//                                 "custom_tbx": [

//                                 ],
//                                 "impact_level": null,
//                                 "compl_type": [

//                                 ],
//                                 "creator_remarks": [

//                                 ],
//                                 "guideline": "",
//                                 "guideline_image": [

//                                 ],
//                                 "id": this.state.totalHLength + 1,
//                                 "parent": null,
//                                 "title": values.title,
//                                 "subtitle": "",
//                                 "type": this.state.type,

//                             },
//                             addAsFirstChild: state.addAsFirstChild,
//                         }).treeData,
//                         crud: false,
//                         mainToggle: false,
//                         totalHLength: this.state.totalHLength + 1,
//                     }
//                     ),
//                         function () {
//                             this.saveHStructure()
//                         }
//                     )
//                 }
//     }


//     dndNode = (droppedNode, getNodeKey) => {
//         this.setState(state => ({
//             treeData: changeNodeAtPath({
//                 treeData: state.treeData,
//                 path: droppedNode.path,
//                 getNodeKey,
//                 newNode: {
//                     "company_id": droppedNode.node.company_id,
//                     "owner_id": droppedNode.node.owner_id,
//                     "template_id": droppedNode.node.template_id,
//                     "document_id": droppedNode.node.document_id,
//                     "parent_id": droppedNode.nextParentNode.id,
//                     "document_type": droppedNode.node.document_type,
//                     "checkpoint": droppedNode.node.checkpoint,
//                     "checkpoint_type_id": droppedNode.node.checkpoint_type_id,
//                     "checkpoint_type": droppedNode.node.checkpoint_type,
//                     "enable_addOptns": droppedNode.node.enable_addOptns,
//                     "checkpoint_options": droppedNode.node.checkpoint_options,
//                     "min_score": droppedNode.node.min_score,
//                     "max_score": droppedNode.node.max_score,
//                     "custom_tbx": droppedNode.node.custom_tbx,
//                     "impact_level": droppedNode.node.impact_level,
//                     "compl_type": droppedNode.node.compl_type,
//                     "creator_remarks": droppedNode.node.creator_remarks,
//                     "guideline": droppedNode.node.guideline,
//                     "guideline_image": droppedNode.node.guideline_image,
//                     "id": droppedNode.node.id,
//                     "parent": droppedNode.nextParentNode.id,
//                     "title": droppedNode.node.title,
//                     "subtitle": droppedNode.node.subtitle,
//                     type: droppedNode.node.type,
//                     children: droppedNode.node.children,
//                 },
//             }),
//             crud: false,
//         }),
//             function () {
//                 this.saveHStructure()
//             }
//         )
//     }

//     getNodeData(nodeData) {
//         this.setState({ nodeTitle: nodeData.type != 2 ? nodeData.title : "" })
//         if (nodeData.children != undefined) {
//             if (nodeData.children.length != 0) {
//                 var getNodeKey = ({ treeIndex }) => treeIndex
//                 var flatData = getFlatDataFromTree(
//                     {
//                         treeData: nodeData.children,
//                         getNodeKey,
//                         ignoreCollapsed: false,
//                     })

//                 var explicitData = _.filter(flatData, item => {
//                     if (item.node.type == 2)
//                         return { node: item.node, parent: item.parentNode }
//                 })

//                 console.log("data", (explicitData))
//                 this.setState({ nodeChildren: explicitData })
//             }
//         }
//         else {
//             if (nodeData.type == 2) {
//                 this.setState({ nodeChildren: [{ node: nodeData, parentNode: null }] })
//             }
//             else {
//                 this.setState({ nodeChildren: [] })
//             }
//         }
//     }






//     toggle() {
//         this.setState(prevState => ({
//             mainToggle: !prevState.mainToggle,
//         }))
//     }

//     childtoggle(udp, dp) {
//         $("#" + udp).attr('class', "mb-0 bg-white dropright dropdown")
//         $("#" + dp).attr('class', "dropdown-menu-end dropdown-menu")
//     }

//     handleUserClicks = arg => {
//         this.setState({ users: '', isEdit: false })
//         this.toggle()
//     }

//     changeAuditEditMode = (status) => { this.setState({ changeToUpdate: status, showCloseButton: status, modal: !this.state.modal ? true : false }) }

//     setPosition(e) {
//         this.setState({ position: e.target.value });
//     }

//     closeDrawer() {
//         this.setState({ open: false });
//     }
//     onDrawerClose() {
//         this.setState({ open: false });
//     }

//     toggleToolTip = targetName => {
//         if (!this.state[targetName]) {
//             this.setState({
//                 ...this.state,
//                 [targetName]: {
//                     tooltipOpen: true
//                 }
//             });
//         } else {
//             this.setState({
//                 ...this.state,
//                 [targetName]: {
//                     tooltipOpen: !this.state[targetName].tooltipOpen
//                 }
//             });
//         }
//     };

//     isToolTipOpen = targetName => {
//         return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
//     };


//     render() {

//         const { department_name } = this.state
//         const { isEdit } = this.state
//         if (this.state.dataLoaded) {
//             const getNodeKey = ({ treeIndex }) => treeIndex;
//             return (
//                 <React.Fragment>
//                     <div className="page-content" >
//                         <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 20, alignItems: 'center' }}>
//                             <div style={{ width: '80%', padding: '0 20px' }}><h4></h4></div>
//                             <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
//                                 <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//                             </div>
//                         </div>

//                         <div >
//                             <Container fluid>
//                                 <div >
//                                     <Alert color="success" isOpen={this.state.successmessage} ><strong>Well done! </strong> {this.state.updateTemplateStatus}</Alert>
//                                     {
//                                         this.state.changeToUpdate ?
//                                             <Card>
//                                                 <CardBody>
//                                                     <div>
//                                                         <AvForm onValidSubmit={this.handleValidUserSubmit} >
//                                                             <Row>
//                                                                 <Col >
//                                                                     <div className="my-3">
//                                                                         <div >
//                                                                             <div className="my-2">
//                                                                                 <AvField
//                                                                                     name="template_name"
//                                                                                     label="Audit Name  "
//                                                                                     placeholder="Enter Audit Name"
//                                                                                     type="text"
//                                                                                     errorMessage="Enter Audit Name"
//                                                                                     validate={{ required: { value: true }, minLength: { value: 6, errorMessage: "Min 6 chars." } }}
//                                                                                     defaultValue={this.state.template_name}
//                                                                                     onChange={(e) => { this.setState({ template_name: e.target.value }) }}
//                                                                                 />
//                                                                             </div>
//                                                                             <div className="my-1">
//                                                                                 <label>Description</label>
//                                                                                 <AvField type="textarea" name="template_info" id="message" defaultValue={this.state.template_info} onChange={(e) => { this.setState({ template_info: e.target.value }) }} />
//                                                                             </div>
//                                                                             <div className="my-3">
//                                                                                 <button className="btn btn-primary btn-block" style={{ marginRight: 5 }}>Update</button>
//                                                                                 <button className="btn btn-danger btn-block" onClick={() => this.setState({ changeToUpdate: false })}>Close</button>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>
//                                                         </AvForm>
//                                                     </div>
//                                                 </CardBody>
//                                             </Card>
//                                             :
//                                             <div>
//                                                 <Card>
//                                                     <CardBody>
//                                                         <div className="my-1">
//                                                             <h5 className="text-wrap font-size-15">
//                                                                 <span className="text-primary">{this.state.template_name}&nbsp;&nbsp;&nbsp;</span>
//                                                                 {this.state.templateData.tmplt_ctd_by == "0" ? null : <i className="fas fa-edit" onClick={() => this.changeAuditEditMode(true)}></i>}</h5>
//                                                             <span className="text-muted " >{this.state.template_info} </span>
//                                                         </div>

//                                                     </CardBody>
//                                                 </Card>
//                                             </div>
//                                     }

//                                 </div>
//                                 <Row >
//                                     <div className="d-xl-flex" >
//                                         <div className="w-100">
//                                             <div className="d-md-flex" >
//                                                 <div className="me-md-2" style={{ width: '40%' }}>
//                                                     <Card >
//                                                         <CardBody style={{ height: this.state.height - 350 }}>
//                                                             <div className="my-3" >
//                                                                 <UncontrolledDropdown isOpen={this.state.mainToggle} toggle={() => this.toggle()}>
//                                                                     <DropdownToggle
//                                                                         className="btn btn-light dropdown-toggle w-100 "
//                                                                         color="#eff2f7"
//                                                                         type="button"
//                                                                         onClick={() => this.addMainNode(0)}
//                                                                     >
//                                                                         <i className="mdi mdi-plus me-1 "></i> Create New
//                                                                     </DropdownToggle>
//                                                                     <DropdownMenu style={{ width: 250 }} className="dropdown-toggle">
//                                                                         <div className="p-4">
//                                                                             <AvForm onValidSubmit={this.crudNode}>
//                                                                                 <div className="my-2">
//                                                                                     <AvField
//                                                                                         name="title"
//                                                                                         label="Menu Name  "
//                                                                                         placeholder="Enter Menu"
//                                                                                         type="text"
//                                                                                         errorMessage="Enter Menu"
//                                                                                         validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                                         defaultValue={""}
//                                                                                         onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                                     />
//                                                                                 </div>
//                                                                                 <div className="my-3">
//                                                                                     <button className="btn btn-primary btn-block m-1"
//                                                                                         type="submit" style={{ marginRight: 5 }}>Add Menu</button>
//                                                                                 </div>
//                                                                             </AvForm>
//                                                                         </div>
//                                                                         <div className="dropdown-divider"></div>
//                                                                         <Link className="dropdown-item" to="#"
//                                                                             onClick={() => this.addParameterAsMain(2)}
//                                                                         >
//                                                                             <i className="bx bx-file me-1"></i> Add Check point
//                                                                         </Link>
//                                                                     </DropdownMenu>
//                                                                 </UncontrolledDropdown>
//                                                             </div>
//                                                             <div style={{ height: this.state.height - 450, overflow: 'auto' }} >
//                                                                 <SortableTree
//                                                                     //theme={FileExplorerTheme}
//                                                                     treeData={this.state.treeData}
//                                                                     onChange={(treeData, node) => {
//                                                                         this.setState({ treeData, crud: false })
//                                                                     }}
//                                                                     onMoveNode={object => this.dndNode(object, getNodeKey)}
//                                                                     canDrop={object => object.nextParent.type == 2 ? false : true}
//                                                                     scaffoldBlockPxWidth={25}
//                                                                     slideRegionSize={25}
//                                                                     rowHeight={50}
//                                                                     generateNodeProps={({ node, path }) => ({
//                                                                         listIndex: 0,
//                                                                         lowerSiblingCounts: [],
//                                                                         rowdirection: "rtl",
//                                                                         className: node.type == 0 ? `${"icon-a"}` : `${"icon-b"}`,
//                                                                         onClick: (event) => {
//                                                                             if (event.target.className.includes('collapseButton') || event.target.className.includes('expandButton')) {
//                                                                             }
//                                                                             else {
//                                                                                 this.setState({ selectedNodeId: node.id, nodeSelected:true })
//                                                                                 this.getNodeData(node)
//                                                                             }
//                                                                         },
//                                                                         style: { fontSize: 14, border: this.state.selectedNodeId == node.id ? "2px solid #f1b44c" : '1px solid #74788d' },

//                                                                         title: (
//                                                                             <div >
//                                                                                 <div style={{ maxWidth: 500, }} key={`div-${node.id}`} >

//                                                                                     <div>
//                                                                                         <Tooltip
//                                                                                             placement="bottom"
//                                                                                             isOpen={this.isToolTipOpen(`btn-${node.id}`)}
//                                                                                             target={`btn-${node.id}`}
//                                                                                             toggle={() => this.toggleToolTip(`btn-${node.id}`)}
//                                                                                         >
//                                                                                             {node.title}
//                                                                                         </Tooltip>
//                                                                                         <Link
//                                                                                             to="#"
//                                                                                             id={`btn-${node.id}`}
//                                                                                             style={{ fontSize: 12, fontWeight: 500 }}
//                                                                                         // onClick={()=>{}
//                                                                                         >
//                                                                                             {String(node.title).slice(0, 20) + (node.title.length > 20 ? "..." : "")}
//                                                                                         </Link>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>

//                                                                         ),

//                                                                         buttons: [
//                                                                             <div>

//                                                                                 <UncontrolledDropdown className="mb-0 bg-white " direction="right"
//                                                                                     id={"udp" + String(node.id)}
//                                                                                 //onClick={()=>this.setState({crud:false, editcrud:false})} 
//                                                                                 >
//                                                                                     <DropdownToggle
//                                                                                         color="white"
//                                                                                         className="font-size-16 text-muted dropdown-toggle"
//                                                                                         tag="a"

//                                                                                     >
//                                                                                         <i className="mdi mdi-dots-vertical font-size-16"></i>
//                                                                                     </DropdownToggle>

//                                                                                     <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)} >
//                                                                                         {!this.state.crud && node.type == 0 ? <Link className="dropdown-item " to="#"
//                                                                                             onClick={
//                                                                                                 () => this.addNode(node, path, getNodeKey, 0)
//                                                                                             }
//                                                                                         > Add Sub Menu
//                                                                                         </Link> : null}
//                                                                                         {
//                                                                                             this.state.crud && node.type == 0 ?
//                                                                                                 <div className="px-4">
//                                                                                                     <AvForm onValidSubmit={this.crudNode}>
//                                                                                                         <div className="my-2">
//                                                                                                             <AvField
//                                                                                                                 name="title"
//                                                                                                                 label="Sub Menu Name  "
//                                                                                                                 placeholder="Enter Sub Menu"
//                                                                                                                 type="text"
//                                                                                                                 errorMessage="Enter Menu"
//                                                                                                                 validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                                                                 defaultValue={""}
//                                                                                                                 onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                                                             />
//                                                                                                         </div>
//                                                                                                         <div className="my-3">
//                                                                                                             <button className="btn btn-primary btn-block m-1"
//                                                                                                                 type="submit" onClick={() => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Add Menu</button>
//                                                                                                         </div>
//                                                                                                     </AvForm>
//                                                                                                 </div>
//                                                                                                 : null
//                                                                                         }

//                                                                                         {!this.state.editcrud && node.type == 0 ? <div>
//                                                                                             <div className="dropdown-divider"></div>
//                                                                                             <Link className="dropdown-item" to="#"
//                                                                                                 onClick={
//                                                                                                     () => this.editNode(path, getNodeKey)
//                                                                                                 }
//                                                                                             > Edit Menu
//                                                                                             </Link> </div> : null}
//                                                                                         {
//                                                                                             this.state.editcrud ?
//                                                                                                 <div className="px-4">
//                                                                                                     <AvForm onValidSubmit={this.crudNode}>
//                                                                                                         <div className="my-2">
//                                                                                                             <AvField
//                                                                                                                 name="title"
//                                                                                                                 label="Edit Menu Name  "
//                                                                                                                 placeholder="Enter Menu Name"
//                                                                                                                 type="text"
//                                                                                                                 errorMessage="Enter Menu"
//                                                                                                                 validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars." } }}
//                                                                                                                 defaultValue={this.state.getTitle}
//                                                                                                                 onChange={(e) => { this.setState({ menuName: e.target.value }) }}
//                                                                                                             />
//                                                                                                         </div>
//                                                                                                         <div className="my-3">
//                                                                                                             <button className="btn btn-primary btn-block m-1"
//                                                                                                                 type="submit" onClick={(event) => this.childtoggle("udp" + String(node.id), "dp" + String(node.id))} style={{ marginRight: 5 }}>Update</button>
//                                                                                                         </div>
//                                                                                                     </AvForm>
//                                                                                                 </div>
//                                                                                                 : null
//                                                                                         }
//                                                                                         {node.type == 0 ? <div><div className="dropdown-divider"></div>
//                                                                                             <Link className="dropdown-item" to="#"
//                                                                                                 onClick={
//                                                                                                     () => this.addCheckpoint(node, path, getNodeKey, 2, "udp" + String(node.id), "dp" + String(node.id))
//                                                                                                 }
//                                                                                             > Add Check point
//                                                                                             </Link></div> : null}
//                                                                                         {node.type == 2 ? <div><div className="dropdown-divider"></div>
//                                                                                             <Link className="dropdown-item" to="#"
//                                                                                                 onClick={
//                                                                                                     () => this.editCheckpoint(path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))
//                                                                                                 }
//                                                                                             > Edit Check point
//                                                                                             </Link></div> : null}
//                                                                                         <div className="dropdown-divider"></div>
//                                                                                         <Link className="dropdown-item text-danger" to="#"
//                                                                                             onClick={() => this.deleteNode(node, path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id))}
//                                                                                         > {node.type != 2 ? "Delete Menu" : "Delete Check point"} <i className="mdi mdi-delete font-size-18"
//                                                                                             style={{ margin: 5 }}
//                                                                                         ></i></Link>
//                                                                                     </DropdownMenu>
//                                                                                 </UncontrolledDropdown>
//                                                                             </div>
//                                                                         ]
//                                                                     })}

//                                                                 />
//                                                             </div>
//                                                         </CardBody>
//                                                     </Card>
//                                                 </div>
//                                                 <div className="" style={{ width: '60%', }}>
//                                                     {
//                                                         this.state.nodeSelected ?
//                                                             <div style={{ maxHeight: this.state.height - 350, overflow: 'auto' }} >
//                                                                 <div className={this.state.nodeTitle != "" ? 'm-2 p-3 bg-white' : ''}>
//                                                                     <span>{this.state.nodeTitle}</span>
//                                                                 </div>
//                                                                 {

//                                                                     this.state.nodeChildren.map((item, i) => {
//                                                                         var getColor = item.node.impact_level == 'Critical' ? '#f46a6a' :
//                                                                             item.node.impact_level == 'High' ? '#f1b44c' :
//                                                                                 item.node.impact_level == 'Medium' ? '#50a5f1' :
//                                                                                     item.node.impact_level == 'Low' ? '#34c38f' : '#556ee6'
//                                                                         return (
//                                                                             <div className='m-2 d-flex flex-column p-3 bg-white' key={"cp" + i} style={{ borderBottom: '1px solid', borderColor: getColor }} >
//                                                                                 <div>
//                                                                                     <span className='font-size-10 text-secondary'>{item.parentNode != null ? item.parentNode.title : ""}</span>
//                                                                                 </div>
//                                                                                 <div className='my-2'>
//                                                                                     <span style={{ color: getColor }}>{String(i).length > 1 ? (i + 1) : "0" + (i + 1)}</span>{" "}<span className='font-size-13'>{item.node.checkpoint}</span>
//                                                                                 </div>
//                                                                                 <div className='my-2'>
//                                                                                     {
//                                                                                         item.node.checkpoint_options.map((child, idx) => (
//                                                                                             <div className='d-flex flex-column p-2 my-1' style={{ border: '1px solid #f0f0f0', borderRadius: 10 }} key={"cp" + i + "ptn" + idx}>
//                                                                                                 <span>{child.option_text} {" "} {child.enable_img ? <i className='mdi mdi-camera-plus' /> : null} {" "} {child.enable_doc ? <i className='mdi mdi-file-document' /> : null}</span>
//                                                                                             </div>
//                                                                                         ))
//                                                                                     }
//                                                                                 </div>
//                                                                                 {
//                                                                                     item.node.impact_level != "" ?
//                                                                                         <div className='my-2'>
//                                                                                             <span className='badge badge-soft p-1 font-size-12' style={{ backgroundColor: getColor }}>{item.node.impact_level}</span>
//                                                                                         </div> : null
//                                                                                 }
//                                                                             </div>
//                                                                         )
//                                                                     }
//                                                                     )
//                                                                 }
//                                                             </div>
//                                                             : 
//                                                             <div >
//                                                                 <Card >
//                                                                     <CardBody style={{height: this.state.height - 350,}}>

//                                                                     </CardBody>
//                                                                 </Card>
//                                                             </div>
//                                                     }
//                                                 </div>
//                                             </div></div></div>
//                                 </Row>
//                                 {/* <hr className="border"></hr> */}
//                                 {
//                                     this.state.saveAndSuccess && (
//                                         <SweetAlert
//                                             title={this.state.messageHeading}
//                                             danger={this.state.messageHeading == "Delete" ? true : false}
//                                             success={this.state.messageHeading == "Success" ? true : false}
//                                             showCancel={this.state.messageHeading == "Success" ? false : true}
//                                             confirmBtnBsStyle={this.state.messageHeading == "Success" ? "success" : "danger"}
//                                             cancelBtnBsStyle={this.state.messageHeading == "Delete" ? "success" : "danger"}
//                                             onConfirm={() => this.confirmation()}
//                                             onCancel={() => this.setState({ saveAndSuccess: false, })}
//                                         >{this.state.message}</SweetAlert>
//                                     )
//                                 }

//                                 <div>
//                                     <Card>
//                                         <CardBody>
//                                             <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: 5 }}>
//                                                 <div className='col-4' >
//                                                     <UncontrolledAlert
//                                                         color="success"
//                                                         className="alert-dismissible fade show"
//                                                         role="alert"
//                                                         isOpen={this.state.successmsgshow}
//                                                         style={{ margin: 0 }}
//                                                     >
//                                                         <i className="mdi mdi-check-all me-2"></i>{this.state.alertMessage}
//                                                     </UncontrolledAlert>
//                                                 </div>
//                                                 <div className="col-4 m-1" >
//                                                     <Button className="btn btn-primary w-100" onClick={()=>this.navigateTo()}> Go back </Button>
//                                                 </div>
//                                                 <div className="col-4 m-1" >
//                                                     <Button className={this.state.treeData.length > 0 ?"btn btn-success w-100" : "btn btn-secondary w-100"} onClick={()=>this.navigateTo()} disabled={this.state.treeData.length > 0 ? false:true}> Save </Button>
//                                                 </div>
//                                             </div>

//                                         </CardBody>
//                                     </Card>
//                                 </div>

//                             </Container>
//                         </div>

//                     </div>

//                     <ReactDrawer
//                         open={this.state.open}
//                         className="drawer-open"
//                         position={this.state.position}
//                         onClose={this.onDrawerClose}
//                         style={{ maxHeight: 1000 }}
//                         noOverlay={false}
//                     >

//                         <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>
//                             {this.state.open ? <InputTemplate
//                                 checkpointinfo={this.state.checkpointInfo}
//                                 configdatacheckpoint={this.state.configData.question_type_info}
//                                 mode={this.state.mode}
//                                 parameterData={true}
//                                 onSubmit={(values) => { this.AECheckPointUnderNode(values, this.state.mode); console.log("values", values) }}
//                             /> : null}
//                         </div>
//                     </ReactDrawer>

//                 </React.Fragment>
//             )
//         }
//         else {
//             return (
//                 <React.Fragment>
//                     <div className="page-content" style={{ minHeight: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//                             <div
//                                 className="spinner-border text-secondary m-1"
//                                 role="status"
//                             >  </div>
//                             <div>Loading, Please wait.</div>
//                         </div>
//                     </div>
//                 </React.Fragment>
//             )
//         }
//     }
// }