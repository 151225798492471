import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { retrivePageTree } from '../../store/reportd3/treedataSlice';
import { useState, useEffect } from 'react';
// import TreeStructure from '../Report/Components/SortableTree/TreeStructure';
import TreeStructure from './Components/SortableTree/TreeStructure';
import { Container,Row,Col } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const PageTree = () => {

    const [pageInfo, setpageInfo] = useState(JSON.parse(sessionStorage.getItem("page_data")))
    const dispatch = useDispatch()
    const history = useHistory()
    const reportSlice = useSelector(state => state.reportSliceReducer)

    useEffect(() => {
        console.log("use effect")
        dispatch(retrivePageTree())
    }, [])

    const gotoBack=()=>{
        history.push("/report")
    }




    return (<React.Fragment>
        <div className="page-content" >
            <Container fluid>
                <Breadcrumbs
                    title={pageInfo.name}
                    link={"Layout / "}
                    description={pageInfo.description}
                    breadcrumbItem="Page Layout"
                    isBackButtonEnable={true}
                    gotoBack={() => gotoBack()}
                />


                <Row>
                    <Col md={12}>
                        <TreeStructure />
                    </Col>
                </Row>

            </Container>

        </div>


    </React.Fragment>);
}

PageTree.propTypes = propTypes;
PageTree.defaultProps = defaultProps;
// #endregion

export default PageTree;