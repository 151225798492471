import React, { Component } from "react";

import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from "react-router-dom"
import {
    Container, Row, Col, Card, CardBody, CardTitle, Table, Button, Badge,
    Progress, Pagination, PaginationItem, PaginationLink,
    Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Multiselect } from 'multiselect-react-dropdown';
import TableContainer from "../Components/TableContainer";
import { LoadingOutlined } from '@ant-design/icons';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Swal from "sweetalert2";
import _, { reject } from "lodash";
import { isEmpty, size, map } from "lodash"
// import facility from '../FacilityScreen'
import CrudCategory from "./crudCategory";
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import MapRoleForUser from "./MapRoleForUser";
import { changeNodeAtPath, addNodeUnderParent, getFlatDataFromTree, getNodeAtPath, removeNodeAtPath, getVisibleNodeCount,getTreeFromFlatData } from 'react-sortable-tree';
import { IncidentaddUsers,treeDataToFlat } from "../../../../store/incidentReport/htree";

import urlSocket from "../../../../helpers/urlSocket";
class MapUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            usertype: '',
            dataloaded: false,
            auditStatusData: [
                { status: "Active", count: 0, color: "#fbae17", id: "1", badgeClass: "success" },
                { status: "In active", count: 0, color: "#303335", id: "0", badgeClass: "danger" },
            ],
            userHLevel: "All",
            resultData: [],
            ddchange: false,
            selectedUsers: [],
            hirerachy_user_level: [],
            dup_state: [],
            open: false,
            roleUser : null,
            config_data : JSON.parse(sessionStorage.getItem("authUser")).config_data,
            treeData : [],
            flatData :[]

        };

        this.childCrudCategory = React.createRef()

    }

    retriveExistCategory = () => {
        try {
            console.log(" this.state.nodeInf   " , this.state.nodeInfo      );
            urlSocket.post('cog/incd-retrive-categories', {
           
                encrypted_db_url: this.state.db_info.encrypted_db_url,
                hierarchy_id: this.state.nodeInfo.node.h_id
            }).then((response) => {
                console.log(response,'response 63')
                //(response, 'response')
                if (response.data.response_code === 500) {
                    this.setState({ hirerachy_user_level: response.data.data[0].hirerachy_user_level }, () => { this.getUserList() })
                }
            })
        } catch (error) {

        }
    }


    getHdata = async () => {
        const hInfo = JSON.parse(sessionStorage.getItem("hInfo"))
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        console.log('hInfoauthUser :>> ',  hInfo);
        try {

            return new Promise(async (resolve, reject) => {
                await urlSocket.post("webhstre/incd-gethstructure", {
                    info: {
                        _id: hInfo._id,
                        company_id: hInfo.company_id,
                        encrypted_db_url: authUser.db_info.encrypted_db_url,
                    }
                }).then(async (response) => {
                    console.log(response, 'response')
                    if (response.data.response_code === 500) {
                        var treeData = response.data.data.hstructure
                        // const treeData = await this.getTreeFromFlatDataAsync(FlatData);
                        var FlatData = await treeDataToFlat(treeData)
                        var FlatData = _.map(FlatData,'node')

                        console.log(treeData, 'treeData',FlatData)
                        // return { treeData, FlatData };
                        resolve({treeData,FlatData})
                    }
                })
            })

        } catch (error) {
            console.log(error, 'error')
        }

    }


    //  treeDataToFlat = (treeData) => {
    //     var flatData = getFlatDataFromTree({
    //         treeData: treeData,
    //         getNodeKey,
    //         ignoreCollapsed: false,
    //     });
    //     var explicitData = _.filter(flatData, (item) => {
    //         return item;
    //     });
    //     return explicitData;
    // };






    getTreeFromFlatDataAsync = (FlatData) => {
        // FlatData
        console.log(FlatData)
        try {
          return new Promise((resolve, reject) => {
            try {
              const treeData = getTreeFromFlatData({
                flatData: FlatData,
                getKey: (node) => node.id,
                getParentKey: (node) => node.parent,
                rootKey: null,
                expanded: true
              });
              console.log(treeData, 'treeData')
              resolve(treeData);
            } catch (error) {
              reject(error);
            }
          });
        } catch (error) {
    
        }
    
      }


    async componentDidMount() {
       var hData = await this.getHdata()
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        var nodeInfo = JSON.parse(sessionStorage.getItem("nodeInfo"));
        //(nodeInfo, 'nodeInfo')
        nodeInfo["treeData"]=hData.treeData
        nodeInfo["flatData"]=hData.FlatData
        console.log(nodeInfo,'nodeInfo')
        var childrenUsers = []
        await this.getAllChildUsers(nodeInfo.node.children, childrenUsers, nodeInfo.cat_type)
        var addedUsers = nodeInfo.node.user_path.concat(childrenUsers)
        var filtered_users = _.filter(addedUsers, e => {
            if (addedUsers.length == 0) {
                if (e.parent_node.title == nodeInfo.node.label) {
                    //(e, 'e')
                    return e
                }
            }
            else {
                if (e.title == nodeInfo.node.label && e.cat_type == nodeInfo.cat_type) {
                    return e
                }
            }
        })

        // var protect_routes = await facility(data.facility_data, 'hirchy')
        // if (protect_routes !== undefined) {
            //(addedUsers,'addedUsers')
            this.setState(
                {
                    userInfo: data.user_data,
                    configData: data.config_data,
                    addedUsers: addedUsers,
                    nodeInfo,
                    dataloaded: false,
                    db_info: db_info

                },
                async function () {
                    await this.retriveExistCategory()
                    this.loadUserLabels()

                }
            )

        // }
        // else {
        //     this.props.history.push('/dashboard')
        // }
    }

    async getAllChildUsers(childNode, childs, cat_type) {
        //(childNode, 'childNode', childs, cat_type)
        _.each(childNode, item => {
            //("item", item)
            if (item.type === 2 && cat_type == item.cat_type) {

                childs.push(item)
            }
            if (item.children) {
                this.getAllChildUsers(item.children, childs)
            }
        })
    }

    validateData = () => {
        var userNextLevel = _.map(_.filter(this.state.configData.hierarchy_info, ({ hierarchy_level }) => hierarchy_level >= this.state.userInfo.hierarchy_level_value), "hierarchy_level_name")
        var hlData = ""
        _.each(userNextLevel, (item, index) => {
            if (index == 1) {
                hlData = this.state.userInfo.departmentandhierarchy[0][item]
            }
            else if (index > 1) {
                if (this.state.userInfo.departmentandhierarchy[0][item] != "" && this.state.userInfo.departmentandhierarchy[0][item] != undefined)
                    hlData = hlData + " / " + this.state.userInfo.departmentandhierarchy[0][item]
            }
        })
        //(hlData, 'hlData')
        this.setState({ hlData })
    }



    getUserList = () => {
        //('userllisy', this.state.userInfo)
        try {
            var userInfo = {
                company_id: this.state.userInfo.company_id,
                encrypted_db_url: this.state.db_info.encrypted_db_url
            }
            //(userInfo, 'userInfo')
            urlSocket.post("webUsers/usersList", userInfo).then((response) => {
                //("response", response.data.data)
                var selectedValue = []
                var selectedValueName = []
                var active_usrs = _.filter(response.data.data, { active: "1" })
                //(active_usrs, 'active_usrs')
                _.orderBy(active_usrs, ['active'], ['asc'])
                var user1 = active_usrs
                var mappingInfo = active_usrs

                var userNextLevel = _.filter(this.state.configData.hierarchy_info, ({ hierarchy_level }) => hierarchy_level < this.state.userInfo.hierarchy_level_value)

                var getUniqueRecords = _.uniqWith(mappingInfo, _.isEqual)

                var uniqueHlevels
                _.map(userNextLevel, item => {
                    uniqueHlevels = _.uniqBy(getUniqueRecords, el => `${el[item.hierarchy_level_name]}`)
                })

                var uniqueNextLevels = []
                _.map(userNextLevel, item => {
                    selectedValue.push("All")
                    selectedValueName.push(item.hierarchy_level_name)
                    uniqueNextLevels.push({ hlevelName: item.hierarchy_level_name, hlevelNameValue: item.hierarchy_level, hlevelValues: _.uniqBy(getUniqueRecords, el => `${el[item.hierarchy_level_name]}`) })
                })
                this.setState({
                    dataSource: active_usrs,
                    entitiesAuditData: active_usrs,
                    statusBasedFilteredData: active_usrs,
                    uniqueNextLevels,
                    selectedValue,
                    selectedValueName,
                    dataloaded: false,

                })
                this.createStatus()

            })
        } catch (error) {
            //(error, 'error')
        }
    }


    dateConvertion = (dateToConvert) => {
        if (dateToConvert != null) {
            var date = typeof (dateToConvert) == "object" ? String(dateToConvert.toISOString()) : String(dateToConvert)
            var convertedDate = date.slice(8, 10) + ' / ' + (date.slice(5, 7)) + ' / ' + date.slice(0, 4);//prints expected format. 
            if (convertedDate == "01 / 01 / 1970") {
                return "-- / -- / --"
            }
            else {
                return convertedDate
            }
        }
        else {
            return "-- / -- / --"
        }
    }

    completedStatus = (data) => {
        var labels = []
        data == undefined ? labels.push({ status: "", color: "#303335", badgeClass: "danger" }) :
            data == "1" ? labels.push({ status: "Active", color: "#fbae17", badgeClass: "success" }) :
                labels.push({ status: "In active", color: "#303335", badgeClass: "danger" })
        return labels
    }

    navigateTo = (userData, mode) => {
        if (mode === 0) {
            sessionStorage.removeItem("userData");
            sessionStorage.setItem("userData", JSON.stringify(userData));
            this.props.history.push("/edtusr");
        }
        else
            if (mode === 1) {
                sessionStorage.setItem("redirect", 1)
                this.props.history.push("/adusrs");
            }
    }

    filterEntityData = (event, h_level, hlevelNameValue, selectedIndex) => {
        this.setState({ dataloaded: false })
        var h_levelValue = event.target.value
        var selectedValue = this.state.selectedValue

        var sourceAll = _.filter(this.state.dataSource, item => {
            var mapInfo = _.filter(item.departmentandhierarchy, { "department_name": this.state.userInfo.departmentandhierarchy[0].department_name })[0]

            if ((selectedIndex == 0 && h_levelValue == "All")) {
                return item
            }
            else {
                if (h_levelValue == "All") {
                    if ((selectedIndex - 1 == 0 && selectedValue[selectedIndex - 1] == "All")) {
                        return item
                    }
                    else {
                        if (mapInfo[String(this.state.selectedValueName[selectedIndex - 1])] == selectedValue[selectedIndex - 1]) {
                            return item
                        }
                    }
                }
                else {
                    if (mapInfo[String(h_level)] == h_levelValue) {
                        return item
                    }
                }

            }


        })

        var mappingInfo = _.map(sourceAll, item => {
            let h_level = _.filter(item.departmentandhierarchy, { "department_name": this.state.userInfo.departmentandhierarchy[0].department_name })[0]
            return h_level
        })
        var userNextLevel = _.filter(this.state.configData.hierarchy_info, ({ hierarchy_level }) => hierarchy_level < hlevelNameValue)
        if (userNextLevel.length > 0) {
            var uniqueHlevels

            _.map(userNextLevel, item => {
                uniqueHlevels = _.uniqBy(mappingInfo, el => `${el[item.hierarchy_level_name]}`)
            })

            var getUniqueRecords = _.uniqWith(mappingInfo, _.isEqual)

            var uniqueNextLevels = []
            _.map(userNextLevel, (item, idx) => {


                var findLevel = _.find(this.state.uniqueNextLevels, ["hlevelName", item.hierarchy_level_name]);
                findLevel.hlevelValues = []

                uniqueNextLevels.push({
                    hlevelName: item.hierarchy_level_name,
                    hlevelNameValue: item.hierarchy_level,
                    hlevelValues: _.uniqBy(getUniqueRecords, el => `${el[item.hierarchy_level_name]}`)
                })


                let index = this.state.uniqueNextLevels.findIndex(i => i.hlevelName === item.hierarchy_level_name);
                let getuniqueLevelIndex = uniqueNextLevels.findIndex(i => i.hlevelName === item.hierarchy_level_name)
                if (index != -1) {
                    this.state.uniqueNextLevels.splice(index, 1, uniqueNextLevels[getuniqueLevelIndex]);
                }



                selectedValue[index] = "All"
                selectedValue[selectedIndex] = h_levelValue
            })

        }
        else {
            selectedValue[selectedIndex] = h_levelValue
        }


        var filteredData = this.state.dataSource
        _.map(selectedValue, (data, idx) => {
            filteredData = _.filter(filteredData, item => {
                if (data == "All") {
                    return item

                }
                else {
                    var mapInfo = _.filter(item.departmentandhierarchy, { "department_name": this.state.userInfo.departmentandhierarchy[0].department_name })[0]
                    if (mapInfo[this.state.selectedValueName[idx]] == data) {
                        return item
                    }

                }
            })

        })


        this.setState({
            entitiesAuditData: filteredData,
            uniqueNextLevels: this.state.uniqueNextLevels,
            selectedValue: selectedValue,
            h_levelName: h_level,
            dataloaded: true
        })
        setTimeout(() => {
            this.createStatus()
            this.filterStatus('All')
        }, 5);



    }


    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    createStatus = () => {
        var entityStatus = _.map(_.countBy(this.state.entitiesAuditData, "active"), (val, key) => ({ status: key, count: val }))
        var totalEntities = 0
        var result = []
        result = _.each(this.state.auditStatusData, function (audititem) {
            audititem.count = 0;
            _.each(entityStatus, itemStatus => {
                if (itemStatus.status == audititem.id) {
                    audititem.count = itemStatus.count
                    totalEntities = totalEntities + itemStatus.count
                }
            })
        })
        this.setState({ resultData: result, totalEntities, dataloaded: true })
    }


    filterStatus = (data) => {
        var filteredData;
        if (data == "In active") {
            filteredData = _.filter(this.state.entitiesAuditData, { "active": 0 })
        }
        else
            if (data == "Active") {
                filteredData = _.filter(this.state.entitiesAuditData, { "active": 1 })
            }
            else
                if (data == "All") {
                    filteredData = this.state.entitiesAuditData
                }
        this.setState({
            statusBasedFilteredData: filteredData,
        })



    }

    uniqueByKeys = (data, keys) => {
        const seen = new Set();
        return data.filter(item => {
            const key = keys.map(k => item[k]).join('-');
            if (!seen.has(key)) {
                seen.add(key);
                return true;
            }
            return false;
        });
    }


    mapSelectedUser = async () => {
        sessionStorage.removeItem("userNodeInfo");

console.log("504------->",  this.state.nodeInfo ,  this.state.selectedUsers);



        var userNodeInfo = {
            nodeInfo: this.state.nodeInfo,
            selectedUsers: this.state.selectedUsers
        }
        var unique_userpath = await this.uniqueByKeys(userNodeInfo.nodeInfo.node.user_path, ['_id', 'cat_type', 'hirerachy_name'])
        userNodeInfo["nodeInfo"]["node"]["user_path"] = unique_userpath
        console.log(userNodeInfo,'userNodeInfo')
        if(userNodeInfo.nodeInfo.node.unique_users.length === userNodeInfo.nodeInfo.node?.user_permission_acpln?.length ){
            IncidentaddUsers(userNodeInfo)
            this.props.history.push("/incident_list")
        }
        else{
            Swal.fire({
                icon: 'error', // You can use 'warning', 'error', or any other appropriate icon
                title: 'Role Confirmation',
                text: 'Please Assign Role for the selected users !', // Customize the confirmation message
                // showCancelButton: true,
                confirmButtonColor: '#d33',
                // cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                // cancelButtonText: 'Cancel',
            }).then((result) => {



            })

        }


      
    }

    viewUserInfo = (data) => {
        //("user Info", data, this.state.statusBasedFilteredData)
        var user_view = _.filter(this.state.statusBasedFilteredData, { "_id": data._id })
        //(user_view, 'user_view')
        this.setState({ user_view_data: user_view[0], modal: true })


    }

    handleSelectAll = (selected, rows) => {
        //(selected, rows, 'row')
        var getUsers = []
        if (selected == true) {
            rows.map((data, idx) => {
                var userInfo = {
                    title: this.state.nodeInfo.node.label,
                    designation: data.designation,
                    name: data.firstname,
                    type: this.state.nodeInfo.type,
                    parent: this.state.nodeInfo.node.id,
                    user_id: data._id,
                    _id: data._id,
                    cat_type: this.state.nodeInfo.cat_type

                }
                getUsers.push(userInfo)
            })
            this.setState({ selectedUsers: getUsers })
        }
        else {
            this.setState({ selectedUsers: [] })

        }

    }

    onSelectValues = async (event, data, index) => {
        //(this.state.nodeInfo, 'node', this.state.selectedUsers)
        var getUsers = []
        var userInfo = {
            title: this.state.nodeInfo.node.label,
            designation: this.state.statusBasedFilteredData[index].designation,
            name: this.state.statusBasedFilteredData[index].firstname,
            email_id: this.state.statusBasedFilteredData[index].email_id,
            type: this.state.nodeInfo.type,
            parent: this.state.nodeInfo.node.id,
            user_id: this.state.statusBasedFilteredData[index]._id,
            _id: this.state.statusBasedFilteredData[index]._id,
            cat_type: data.cat_type,
            hirerachy_name: data.hirerachy_name
        }
        this.state.selectedUsers.push(userInfo)
        this.state.nodeInfo.node.user_path.push(userInfo)
        var nodeInfo = { ...this.state.nodeInfo }
        this.state.addedUsers.push(userInfo)
        if (nodeInfo.node["unique_users"] == undefined) {
            nodeInfo.node["unique_users"] = []
            nodeInfo.node["hirerachy_name"] = []
            nodeInfo.node["unique_users"].push({ ...userInfo })
            nodeInfo.node["unique_users"][0]["cat_type"] = [this.state.nodeInfo.node["unique_users"][0]["cat_type"]]
            nodeInfo.node["unique_users"][0]["hirerachy_name"] = [this.state.nodeInfo.node["unique_users"][0]["hirerachy_name"]]

        }
        else {
            var find_exist_user = _.find(this.state.nodeInfo.node.unique_users, { _id: userInfo._id })
            if (find_exist_user !== undefined) {
                find_exist_user["cat_type"].push(data.cat_type)
                find_exist_user["hirerachy_name"].push(data.hirerachy_name)
            }
            else {
                nodeInfo.node["unique_users"].push({ ...userInfo })
                var find_exist_user_unique = _.findIndex(this.state.nodeInfo.node.unique_users, { _id: userInfo._id })
                nodeInfo.node["unique_users"][find_exist_user_unique]["cat_type"] = [this.state.nodeInfo.node["unique_users"][find_exist_user_unique]["cat_type"]]
                nodeInfo.node["unique_users"][find_exist_user_unique]["hirerachy_name"] = [this.state.nodeInfo.node["unique_users"][find_exist_user_unique]["hirerachy_name"]]

            }
        }
        var unique_userpath = await this.uniqueByKeys(this.state.nodeInfo.node.user_path, ['_id', 'cat_type', 'hirerachy_name'])
        //(unique_userpath, 'unique_userpath')
        nodeInfo["node"]["user_path"] = unique_userpath
        //(this.state.nodeInfo, 'this.state.nodeInfo')
        this.setState({ selectedUsers: this.state.selectedUsers, addedUsers: unique_userpath, nodeInfo: nodeInfo }, () => { console.log(this.state.selectedUsers, 'users') })

    }



    onRemove = async (item, idx) => {
        //(item, 'idx', idx, this.state.nodeInfo, this.state.addedUsers)
        item["_id"] = this.state.statusBasedFilteredData[idx]._id
        var removed_data = []
        _.filter(this.state.addedUsers, e => {
            if ((e.cat_type == item.cat_type) && (e._id == this.state.statusBasedFilteredData[idx]._id)) {
                //(e, 'e', item)
            }
            else {
                removed_data.push(e)
            }

        })
        var update_node_data = []
        this.state.nodeInfo.node.user_path.map((data, idx1) => {
            if ((data.cat_type == item.cat_type) && (data._id == this.state.statusBasedFilteredData[idx]._id)) {

            }
            else {
                update_node_data.push(data)
            }
        })
        var nodeInfo = { ...this.state.nodeInfo }
        nodeInfo.node["user_path"] = update_node_data
        var find_unique_user_idx = _.findIndex(this.state.nodeInfo.node.unique_users, { _id: item._id })
        //(find_unique_user_idx, 'find_unique_user_idx')
        if (find_unique_user_idx !== -1) {
            var filtered_unique_user = this.state.nodeInfo.node.unique_users[find_unique_user_idx].cat_type.filter(ele => ele !== item.cat_type);
            var filtered_unique_h_name = this.state.nodeInfo.node.unique_users[find_unique_user_idx].hirerachy_name.filter(ele => ele !== item.hirerachy_name);

            //(filtered_unique_user, 'user')
            nodeInfo.node.unique_users[find_unique_user_idx].cat_type = filtered_unique_user
            nodeInfo.node.unique_users[find_unique_user_idx].hirerachy_name = filtered_unique_h_name

            if (filtered_unique_user.length == 0) {
                var updated_unique_userdata = this.state.nodeInfo.node.unique_users.filter((ele, index) => index !== find_unique_user_idx);
                nodeInfo.node["unique_users"] = updated_unique_userdata
                //(updated_unique_userdata, 'updated_unique_userdata')
            }
        }
        var unique_userpath = await this.uniqueByKeys(this.state.nodeInfo.node.user_path, ['_id', 'cat_type', 'hirerachy_name'])
        //(unique_userpath, 'unique_userpath')
        nodeInfo["node"]["user_path"] = unique_userpath
        this.setState({ addedUsers: unique_userpath, dataloaded: false, nodeInfo: nodeInfo }, () => this.setState({ dataloaded: true }))

    }

    onDrawerClose = () => {
        this.setState({ open: false, dataloaded: false,component:'' }, () => { this.setState({ dataloaded: true }); this.retriveExistCategory() })
    }




    getSelectedUsers = (row, isSelect, rowIndex, e) => {
        //(row, isSelect, rowIndex, e, 'row, isSelect, rowIndex, e')
        var statusBasedFilteredData = [...this.state.statusBasedFilteredData]
        if (isSelect == true) {
            statusBasedFilteredData[rowIndex]["multi"] = true


        }
        else {
            statusBasedFilteredData[rowIndex]["multi"] = false
        }
        this.setState({
            statusBasedFilteredData: statusBasedFilteredData,
            dup_state: [statusBasedFilteredData[rowIndex]].concat[statusBasedFilteredData[rowIndex]],
            dataloaded: false
        }
            , () => this.setState({ dataloaded: true })
        )
    }

    updateAddUsers = (user_path) => {
        //(user_path, 'user_path', '691', this.state.selectedUsers, this.state.addedUsers)
        this.setState({
            addedUsers: user_path,
            dataloaded: false
        })
    }


    loadUserLabels() {
        try {
            urlSocket.post("userSettings/get-user-group", {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.state.userInfo._id,
                    company_id: this.state.userInfo.company_id
                }
            })
                .then(response => {
                    //(response, 'response')
                    this.setState({
                        labelData: response.data.data,

                    }, () => { console.log(this.state.labelData) })
                })

        } catch (error) {
        }
    }


    updateNodeInfo=(nodeInfo)=>{
        this.setState({
            nodeInfo : nodeInfo,
        })


    }


    labelSelected(data) {

        if (data.target.value === "all") {
            this.retriveExistCategory()
        }
        else {
            var mylabel = this.state.labelData[data.target.value]

            try {

                urlSocket.post("userSettings/load-group-users", {
                    labelInfo: mylabel,
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.state.userInfo._id,
                        company_id: this.state.userInfo.company_id
                    }
                })
                    .then(response => {
                        this.setState({
                            statusBasedFilteredData: response.data.data,
                        })
                    })
            } catch (error) {
            }

        }
        this.setState({ user_group_selected: data.target.value })

    }





    render() {
        //("nodeInfo", this.state.modal)
        if (this.state.dataloaded) {
            const options = {
                sizePerPage: 10,
                totalSize: this.state.statusBasedFilteredData.length,
                custom: true,
            };
            const columns = [
                {
                    accessor: 'firstname',
                    Header: 'User Name',
                    sort: true,
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        var user_idx = _.filter(this.state.addedUsers, { _id: item._id })
                        return (
                            <div className={`row-highlight ${user_idx.length >0 ? 'highlighted' : ''}`}>
                                <div className="d-flex " style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className=" font-size-14 ">
                                        {item.firstname}
                                    </div>
                                    <div className=" font-size-11 ">
                                        {item.designation}
                                    </div>
                                </div>
                                </div>
                        )
                    }
                },
                {
                    accessor: 'mapping',
                    Header: 'Mapped with',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        var index = cellProps.row.index
                        //(item, this.state.addedUsers, 'user', this.state.hirerachy_user_level)
                        var user_idx = _.filter(this.state.addedUsers, { _id: item._id })
                        //(user_idx, 'user_idx')
                        user_idx.map((data, idx) => {
                            if (data.cat_type == "1") {
                                user_idx[idx]['hirerachy_name'] = "Auditor"
                            }
                            if (data.cat_type == "2") {
                                user_idx[idx]['hirerachy_name'] = "Reviewer"
                            }
                            if (data.cat_type == "3") {
                                user_idx[idx]['hirerachy_name'] = user_idx[idx]['hirerachy_name']
                            }
                        })
                        //(this.state.hirerachy_user_level,'this.state.hirerachy_user_level')
                        const filteredOptions = this.state.hirerachy_user_level.filter((option) => {
                            return !user_idx.some((user) => user.cat_type === option.cat_type);
                        });
                        //(filteredOptions, 'filteredOptions')
                        const highlightRow = user_idx.length > 0;
                        return (
                            <div className={`row-highlight ${highlightRow ? 'highlighted' : ''}`}>
                                <Multiselect
                                    onRemove={(selectedList, selectedItem) => { this.onRemove(selectedItem, index) }}
                                    onSelect={(selectedList, selectedItem) => {
                                        this.onSelectValues(selectedList, selectedItem, index);
                                    }}
                                    options={filteredOptions}
                                    displayValue="hirerachy_name"
                                    closeOnSelect={false}
                                    selectedValues={user_idx}
                                />
                           </div>

                        )

                    }
                },
                {
                    accessor: "menu",
                    Header: "Action",
                    Cell: (cellProps) => {
                        var user = cellProps.row.original
                        return (
                            <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                                <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light" onClick={() => { this.viewUserInfo(user) }}>
                                    View Detail
                                </Button>
                            </div>
                        )
                    },
                },
                {
                    accessor: "assign",
                    Header: "Role for Action Plan user",
                    // width: "10%",
                    Cell:(cellProps)=>{
                        //(cellProps.row.original,'prgona;')
                        var item = cellProps.row.original
                        var user_idx = _.filter(this.state.addedUsers, { _id: item._id })
                        //(user_idx, 'user_idx')
                        const highlightRow = user_idx.length > 0;
                        //(highlightRow,'highlightRow')
                        return (
                            <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                                                    <Button type="button" color="success" size="sm" className="waves-effect waves-light " 
                            onClick={() => { 
                                this.setState({
                                    open : true,
                                    component : "role",
                                    position : "relative",
                                    roleUser : item
                                })
                                //   setOpen(true);
                                //     setcomponent("role");
                                //     setposition("relative");
                                //     setRoleUser(user)
                                }}
                                disabled={!highlightRow}
                            >
                                Assign User as
                            </Button>

                            </div>



                        )



                    }




                }
            ];

            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                    // this.getSelectedUsers(row, isSelect, rowIndex, e)
                    this.getSelectedUsers(row, isSelect, rowIndex, e)
                },
                onSelectAll: (isSelect, rows, e) => {
                    //("select all", rows, isSelect)
                    // this.handleSelectAll(isSelect,rows)
                },

            };
            return (
                <React.Fragment>
                    <div className="page-content" style={{ minHeight: "100vh" }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 20, alignItems: 'center' }}>
                            <div style={{ width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
                                <span>Map Users under &nbsp;</span><h5 className="text-warning">{this.state.nodeInfo.node.label}</h5>
                            </div>
                            <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Link to="/incident_info"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
                            </div>
                        </div>
                       
                        <Container fluid>
                            <Row>
                                <Col xl="12" >

                                    <div style={{ width: '80%', padding: '0 20px' }}><h6>{this.state.hlData}</h6></div>
                                    {
                                        this.state.uniqueNextLevels.length != 0 ?
                                            <div style={{ display: 'flex', flexDirection: 'row', border: '1px solid #ABB8C3', padding: 10 }}>
                                                {
                                                    this.state.uniqueNextLevels.map((item, idx) => {

                                                        return (
                                                            <div className="col-md-3" key={idx}>
                                                                <div className="form-group">
                                                                    <label className="col-md-12 col-form-label">Select {item.hlevelName}</label>
                                                                    <div className="col-md-12">
                                                                        <select className="custom-select" value={this.state.selectedValue[idx]} id={item.hlevelName} onChange={(e) => this.filterEntityData(e, item.hlevelName, item.hlevelNameValue, idx)}>
                                                                            <option value="All">All</option>
                                                                            {
                                                                                item.hlevelValues.map((data,idx1) => {
                                                                                    if (data[item.hlevelName] != undefined)
                                                                                        return (
                                                                                            <option key={idx1} value={data[item.hlevelName]}>{data[item.hlevelName]}</option>
                                                                                        )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div> : null
                                    }

                                </Col>
                            </Row>

                            <Row style={{ marginTop: 10 }}>
                                <Col sm="4">
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
                                        <div className="select-container me-2">
                                        </div>
                                    </div>


                                </Col>
                                <Col sm="8">
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
                                        <div className="select-container me-2">
                                            <select style={{ marginTop: '6px' }} className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
                                                <option value="">Select User Group</option>
                                                <option value="all">All</option>
                                                {
                                                    this.state.labelData?.map((data, idx) => {
                                                        return (
                                                            <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
                                                        )
                                                    })
                                                }

                                            </select>
                                        </div>

                                        <div style={{ padding: 5, display: 'flex', flexDirection: 'row', marginRight: 5, position: 'relative', }} >
                                            <Button color={"primary"} outline
                                                onClick={() => { this.setState({ open: true, component: 'crud', selectedUsers: [] }, () => { this.childCrudCategory.current.retriveExistCategory() }) }}
                                            >
                                                Create new category
                                            </Button>
                                        </div>

                                        <div style={{ padding: 5, display: 'flex', flexDirection: 'row', marginRight: 5, position: 'relative', }} >
                                            <Button color={"dark"} outline
                                                onClick={() => this.filterStatus('All')}
                                            >
                                                Total &nbsp;&nbsp;&nbsp;{this.state.totalEntities}
                                            </Button>

                                        </div>
                                        <div style={{ padding: 5, display: 'flex', flexDirection: 'row', marginLeft: 20, position: 'relative', }} >
                                            {/* <Button type="button" color="primary" size="lm" className="btn-rounded waves-effect waves-light" onClick={() => { this.navigateTo("", 1) }}>
                                                Add User
                                            </Button> */}

                                        </div>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={this.state.statusBasedFilteredData}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={false}
                                                customPageSize={10}
                                                style={{ width: '100%' }}
                                                iscustomPageSizeOptions={true}
                                                isPagination={true}
                                                filterable={false}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-end mb-2"

                                            />

                                        </CardBody>
                                    </Card>
                                    <div className="mt-4 d-grid">
                                        <button
                                            className="btn btn-danger btn-block"
                                            type="submit"
                                            onClick={() => { this.mapSelectedUser() }}
                                        >
                                            Map Selected Users
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Modal isOpen={this.state.modal} toggle={this.toggle}  >
                                <ModalHeader toggle={this.toggle} tag="h4">
                                    User Info
                                </ModalHeader>
                                <ModalBody>
                                    <AvForm className="form-horizontal" onValidSubmit={this.Validsubmit}>
                                        <div className="mb-3">
                                            <label>Full Name :<span className="text-danger"> *</span></label>
                                            <AvField
                                                name="firstname"
                                                value={this.state.user_view_data !== undefined ? this.state.user_view_data.firstname : ''}
                                                disabled={true}
                                                errorMessage="Please enter your name"
                                                className="form-control"
                                                placeholder="Enter First Name"
                                                type="text"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label>Email Id :<span className="text-danger"> *</span></label>
                                            <AvField
                                                name="email_id"
                                                value={this.state.user_view_data !== undefined ? this.state.user_view_data.email_id : ''}
                                                disabled={true}
                                                errorMessage="Please enter your Email ID"
                                                className="form-control"
                                                placeholder="Enter User Email"
                                                type="email"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Row>
                                                <Col className="col-12">
                                                    <Row>
                                                        <label>Phone Number:<span className="text-danger"> *</span></label>
                                                        <Col md={3} className="pe-0">
                                                            <input
                                                                type="text"
                                                                value={this.state.user_view_data !== undefined ? this.state.user_view_data.countrycode : ''}
                                                                className="form-control"
                                                                disabled={true}
                                                            />
                                                        </Col>
                                                        <Col md={9} className="ps-0">
                                                            <AvField
                                                                name="phone_number"
                                                                className="form-control"
                                                                placeholder="Enter Phone number"
                                                                errorMessage="Please enter your Phone Number"
                                                                value={this.state.user_view_data !== undefined ? this.state.user_view_data.phone_number : ''}
                                                                disabled={true}
                                                                type="number"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="mb-3">
                                            <label>Username :<span className="text-danger"> *</span></label>
                                            <AvField
                                                name="username"
                                                type="text"
                                                required
                                                placeholder="Enter username"
                                                disabled={true}
                                                value={this.state.user_view_data !== undefined ? this.state.user_view_data.username : ''}
                                            />
                                        </div>
                                    </AvForm>


                                </ModalBody>
                            </Modal>
                            {
                                console.log( this.state.component, this.state.open,this.state.position, ' this.state.component')
                            }
                            <ReactDrawer
                                open={this.state.open}
                                className="drawer-open"
                                // position={'top'}
                                onClose={this.onDrawerClose}
                                style={{ maxHeight: 1000 }}
                                noOverlay={false}
                            >
                                {
                                    this.state.component == 'crud' ?
                                        <CrudCategory
                                            onClose={this.onDrawerClose}
                                            ref={this.childCrudCategory}
                                            treeData={this.state.nodeInfo.treeData}
                                            nodeInfo={this.state.nodeInfo}
                                            updateAddUsers={this.updateAddUsers}
                                        />
                                        :
                                        this.state.component == "role" ?
                                        <MapRoleForUser
                                        roleUser={this.state.roleUser}
                                        ref={this.childRoleRef}
                                        onClose={this.onDrawerClose}
                                        config_data ={this.state.config_data}
                                        nodeInfo={this.state.nodeInfo}
                                        updateNodeInfo ={this.updateNodeInfo}
                                        />

                                        :

                                        null
                                }

                            </ReactDrawer>


                        </Container>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return null
        }

    }
}

export default MapUsers;

