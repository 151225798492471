import React, { useEffect, useState, useRef } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
    FormFeedback,
    Spinner
} from "reactstrap";
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getHmaxLevel, retriveSeverityTypeList, createEscalationInfo, retriveEscalationList, seteditEscalationInfo, getHierarchyDesignation } from '../../../../store/incidentReport/incdreportslice';
import { Popconfirm } from 'antd';

const EscalationForm = (props) => {
    const incidentReport = useSelector(state => state.incdreportslice)
     const [selectedHierarchy, setSelectedHierarchy] = useState(Object.keys(incidentReport.editEscalationInfo).length > 0 ? incidentReport.editEscalationInfo.hierarchy_id : '');
    const [escalationLvl, setescalationLvl] = useState([])
    const [severityTypeList, setseverityTypeList] = useState([])
    const [selectedValues, setSelectedValues] = useState([])
    const [options, setOptions] = useState([] )  
    const [errMsg, seterrMsg] = useState('')
    const [errMsgStatus,seterrMsgStatus] = useState(false)  
    const [escalErr,setescalErr] = useState(false)  
    const [dataLoaded, setdataLoaded] = useState(false)


    const [escalationLvlErr, setescalationLvlErr] = useState(false)
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem('authUser')))
    const inputRef = useRef()
    // const [selectedHierarchy, setSelectedHierarchy] = useState("")
    const dispatch = useDispatch()

    useEffect(()=>{
        if(Object.keys(incidentReport.editEscalationInfo).length > 0){
            setSelectedHierarchy(incidentReport.editEscalationInfo.hierarchy_id)
        }
        else{
            setSelectedHierarchy("")
        }

    },[incidentReport.editEscalationInfo])


    useEffect(() => {
        setOptions(incidentReport.designationList);
    }, [incidentReport.designationList]);

  
    useEffect(() => {
        if (selectedHierarchy !== '') {
            dispatch(retriveSeverityTypeList());
            var escalationInfo = _.cloneDeep(incidentReport.editEscalationInfo.escln_lvl)
            console.log('escalationInfo  555:>> ', escalationInfo);
            setescalationLvl(escalationInfo)
            // setOptions(incidentReport.designationList);
            dispatch(getHmaxLevel(selectedHierarchy))
            dispatch(getHierarchyDesignation(selectedHierarchy))

            var arr =[]
            escalationInfo.map(( data , indx)=>{
                console.log('deata :>> ', data);

                arr.push(data.desgn.cat_type)
            })

            console.log("arrarr", arr);
            setSelectedValues(arr)   

        }
        setdataLoaded(true)

    }, [])
  
  
  
  
  
  
    // useEffect(() => {
    //     if (selectedHierarchy !== '') {
    //         dispatch(retriveSeverityTypeList());
    //         var escalationInfo = _.cloneDeep(incidentReport.editEscalationInfo.escln_lvl)
    //         setescalationLvl(escalationInfo)
    //         // setOptions(incidentReport.designationList);
    //         dispatch(getHmaxLevel(selectedHierarchy))
    //         dispatch(getHierarchyDesignation(selectedHierarchy))

    //     }
    //     setdataLoaded(true)

    // }, [])


    useEffect(() => {
        console.log(incidentReport.severityTypeList,selectedHierarchy,incidentReport.escalationList, 'incidentReport.severityTypeList', incidentReport.editEscalationInfo)
        if(incidentReport.severityTypeList.length >0){
        var escalationInfo = _.filter(incidentReport.escalationList, { hierarchy_id: selectedHierarchy })
        if(Object.keys(incidentReport.editEscalationInfo).length !==0){
            var escalationInfo = escalationInfo.filter((ele)=>{
                    if(ele.severity.severity_id !== incidentReport.editEscalationInfo.severity.severity_id){
                        return ele
                    }
            })
            // _.filter(escalationInfo,{})
        }
        console.log(escalationInfo, 'escalationInfo')
        
        const array2SeverityIds = escalationInfo.map(item => item.severity.severity_id);

        const nonMatchingSeverity = incidentReport.severityTypeList.filter(item => !array2SeverityIds.includes(item.severity_id));
        console.log(nonMatchingSeverity);
        setseverityTypeList(_.cloneDeep(nonMatchingSeverity))
        if(nonMatchingSeverity.length ===0){
            setescalErr(true)
        }
        else{
            setescalErr(false)
        }
        }


    }, [incidentReport.severityTypeList])


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            hierarchy_id: incidentReport.editEscalationInfo.hierarchy_id || "",
            severity: incidentReport.editEscalationInfo.severity?.severity_id || "",
        },
        validationSchema: Yup.object({
            hierarchy_id: Yup.string().required("Please Choose the Hierarchy type"),
            severity : Yup.string().required("Please Choose the Severity type"),
            // incd_h_id: Yup.string().required("Please Choose the Hierarchy type"),
            // action: Yup.string().required("Please Enter the Incident observation"),
        }),
        onSubmit: async(values) => {
            console.log(values, 'values')
            if (severityTypeList.length > 0 && escalationLvl.length >0) {
                const isValid = await validateJsonArray(escalationLvl)
                console.log(isValid,'isValid')
                if(isValid){
                     values["escln_lvl"] = escalationLvl
                var severityInfo = _.filter(incidentReport.severityTypeList, { severity_id: values.severity })
                var hnIfo = _.filter(incidentReport.hierarchyList, { _id: values.hierarchy_id })
                values["hierarchy_name"] = hnIfo.length > 0 ? hnIfo[0]["hname"] : ""
                values["created_by"] = authUser.user_data._id
                if (severityInfo.length > 0) {
                    values["severity"] = {
                        severity_id: severityInfo[0].severity_id,
                        severity_name: severityInfo[0].severity_name,
                    }
                }
                if (Object.keys(incidentReport.editEscalationInfo).length > 0) {
                    values["_id"] = incidentReport.editEscalationInfo._id
                }
                dispatch(createEscalationInfo(values))
                props.onClose()
                }
                else{
                    seterrMsg("Please Fill all fields")
                    seterrMsgStatus(true)
                }

                // values["escln_lvl"] = escalationLvl
                // var severityInfo = _.filter(incidentReport.severityTypeList, { severity_id: values.severity })
                // var hnIfo = _.filter(incidentReport.hierarchyList, { _id: values.hierarchy_id })
                // values["hierarchy_name"] = hnIfo.length > 0 ? hnIfo[0]["hname"] : ""
                // values["created_by"] = authUser.user_data._id
                // if (severityInfo.length > 0) {
                //     values["severity"] = {
                //         severity_id: severityInfo[0].severity_id,
                //         severity_name: severityInfo[0].severity_name,
                //     }
                // }
                // if (Object.keys(incidentReport.editEscalationInfo).length > 0) {
                //     values["_id"] = incidentReport.editEscalationInfo._id
                // }
                // dispatch(createEscalationInfo(values))
                // props.onClose()
                // dispatch(retriveEscalationList())
            }
            else {
                if(escalationLvl.length === 0){
                    // seterrMsg(true)
                    seterrMsgStatus(true)
                    seterrMsg('Please add minimum on Escalation Level')
                }
                else{
                    props.onClose()
                    dispatch(retriveEscalationList())
                }
              
            }

        }
    });

    const handleHierarchyChange = (event) => {
        validation.handleChange(event);
        setSelectedHierarchy(event.target.value)
        dispatch(getHmaxLevel(event.target.value))
        dispatch(getHierarchyDesignation(event.target.value))

    }


    const validateJsonArray = (data) => {
        const requiredKeys = ["desgn", 'duration_format', 'escln_level', 'id'];
    
        return data.every(item => 
            requiredKeys.every(key => item.hasOwnProperty(key))
        );
    };

    const addEscalationLvl = () => {
        console.log(escalationLvl, 'addEscalationLvl')
        var updatedEscalation = [...escalationLvl]
        if (updatedEscalation.length === 0) {
            var id = 1
            updatedEscalation.push({
                id: id,
                escln_level: "Level " + id
            })
        }
        else {
            const maxLevel = _.maxBy(updatedEscalation, 'id').id;
            updatedEscalation.push({
                id: maxLevel + 1,
                escln_level: "Level " + (maxLevel + 1)
            })

        }
        console.log(updatedEscalation, 'updatedEscalation')
        setescalationLvl(updatedEscalation)
    }

    const deleteLvl = (item, pos) => {
        var updatedEscalation = [...escalationLvl]
        var updtDsgnation = [...selectedValues]
        updatedEscalation.splice(pos, 1)
        console.log(item, pos, 'pos', updatedEscalation)
        // setescalationLvl(updatedEscalation)
        // setSelectedValues(updtDsgnation)
        setdataLoaded(false)
        setTimeout(() => {
            setescalationLvl(updatedEscalation)
            setSelectedValues(updtDsgnation)
            setdataLoaded(true)
        }, 50);

    }


    // const handleselectdesgn =(e , idx) =>{
    //     const newValue = e.target.value;
    //     var updatedEscalation = [...escalationLvl]
    //     updatedEscalation[idx]["desgn"] ={
    //         hirerachy_name :  _.filter(incidentReport.designationList,{cat_type : newValue})[0].hirerachy_name,
    //         cat_type : newValue
    //     }
    //     setescalationLvl(updatedEscalation)

    //     setSelectedValues(prevState => {
    //         var updatedValues = [...prevState]; 
    //         updatedValues[idx] = newValue; 
    //         return updatedValues; 
    //     });

    //     const allSelectedValues = Object.values({
    //         ...selectedValues,
    //         [idx]: newValue
    //     });

    //     const nonMatchingOption = incidentReport.designationList.filter(
    //         item => !allSelectedValues.includes(item.hirerachy_name)
    //     );
    //     seterrMsgStatus(false)
    //     seterrMsg('')


    // }



    const handleselectdesgn = (e, idx) => {
        const newValue = e.target.value;
        var updatedEscalation = [...escalationLvl]
        updatedEscalation[idx]["desgn"] = {
            hirerachy_name: _.filter(incidentReport.designationList, { cat_type: newValue })[0].hirerachy_name,
            cat_type: newValue
        }
        setescalationLvl(updatedEscalation)

        setSelectedValues(prevState => {
            var updatedValues = [...prevState];
            updatedValues[idx] = newValue;
            return updatedValues;
        });

        const allSelectedValues = Object.values({
            ...selectedValues,
            [idx]: newValue
        });

        const nonMatchingOption = incidentReport.designationList.filter(
            item => !allSelectedValues.includes(item.hirerachy_name)
        );
        seterrMsgStatus(false)
        seterrMsg('')


    }


    const addDuration = (pos, value, mode) => {
        console.log(pos, 'value',mode, value)
        var updatedEscalation = [...escalationLvl]
        if (mode === 1) {
            updatedEscalation[pos]["duration_format"] = value
            // delete updatedEscalation[pos]["duration"]
        }
        if (mode === 2) {
            updatedEscalation[pos]["duration"] = value
        }
        seterrMsgStatus(false)
        seterrMsg('')
        setescalationLvl(updatedEscalation)

    }


    const handleChangeSeverity=(event)=>{
        validation.handleChange(event)
        setescalationLvl([])

    }



    return (
        <React.Fragment>
            <Container fluid>
                <Card >
                    <CardBody className="pt-0">
                        <Row >
                            <Col >
                                <div className="p-2 mb-3">
                                    {
                                        dataLoaded ?
                                        <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row className="my-4">
                                            <div className="d-flex align-items-center justify-content-between">
                                                        <div className="text-primary font-size-18">{Object.keys(incidentReport.editEscalationInfo).length > 0 ? "Edit" : "Create"} Escalation</div>
                                                <button className="btn btn-outline-dark " onClick={() => { props.onClose(); dispatch(seteditEscalationInfo({})); dispatch(retriveEscalationList({})) }}> Close </button>
                                            </div>
                                            <hr className="my-4" />
                                        </Row>
                                        <div className="mb-3">
                                            <Label className="form-label">Select Hierarchy :<label className="text-danger"> *</label></Label>
                                            <select
                                                id="hierarchy_id"
                                                name="hierarchy_id"
                                                onChange={handleHierarchyChange}
                                                onBlur={validation.handleBlur}
                                                className={`form-control ${validation.touched.hierarchy_id && validation.errors.hierarchy_id ? 'is-invalid' : ''}`}
                                                // className='form-control'
                                                defaultValue={selectedHierarchy === "" ? "Select" : selectedHierarchy}
                                            >
                                                <option disabled value={"Select"}>Select</option>
                                                {incidentReport.hierarchyList?.map((data, idx) => {
                                                    return (
                                                        <option key={idx} value={data._id}>{data.hname}</option>
                                                    )

                                                })
                                                }
                                            </select>
                                            {validation.touched.hierarchy_id && validation.errors.hierarchy_id ? (
                                                <FormFeedback type="invalid">{validation.errors.hierarchy_id}</FormFeedback>
                                            ) : null}

                                            {
                                                selectedHierarchy === "" ?
                                                <>
                                                </>
                                                :
                                                escalErr &&
                                                // severityTypeList.length === 0 && selectedHierarchy !== "" &&
                                                <div className="text-danger mt-1" style={{ fontSize: 'smaller', marginLeft: "46%" }}>
                                                    All severity added for this Hierarchy, please choose another Hierarchy
                                                </div>
                                            }
                                        </div>
                                        {
                                            selectedHierarchy && severityTypeList.length > 0 &&
                                            <div className="mb-3">
                                                <Label className="form-label">Select Severity :<label className="text-danger"> *</label></Label>
                                                <select
                                                    id="severity"
                                                    name="severity"
                                                    onChange={(e)=>{handleChangeSeverity(e)}}
                                                    onBlur={validation.handleBlur}
                                                    className={`form-control ${validation.touched.severity && validation.errors.severity ? 'is-invalid' : ''}`}
                                                   
                                                    // className='form-control'
                                                    defaultValue={Object.keys(incidentReport.editEscalationInfo).length === 0 ? "Select" : validation.values.severity}
                                                >
                                                    <option disabled value={"Select"}>Select</option>
                                                    {severityTypeList?.map((data, idx) => {
                                                        return (
                                                            <option key={idx} value={data.severity_id}>{data.severity_name}</option>
                                                        )

                                                    })
                                                    }
                                                </select>
                                                {validation.touched.severity && validation.errors.severity ? (
                                                <FormFeedback type="invalid">{validation.errors.severity}</FormFeedback>
                                            ) : null}

                                            </div>
                                        }
                                        {
                                            selectedHierarchy !== "" && incidentReport.severityTypeList.length === 0 &&
                                            <div className='text-danger' style={{fontSize:'smaller'}}>No Severity found, please create severity.</div>
                                        }

                                        {

                                            escalationLvl?.length > 0 &&
                                            <>
                                                <>
                                                    <table className="table" >
                                                        <thead>
                                                            <tr>
                                                                <th>Level</th>
                                                                <th>Escalation Time</th>
                                                                <th>Hierarchy Name</th>
                                                                <th>Designation</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                escalationLvl.map((ele, idx) => {
                                                                    return (
                                                                        <tr key={idx}>
                                                                            <td>
                                                                                {ele.escln_level}
                                                                            </td>
                                                                            <td>
                                                                                <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                                                                                    <input ref={inputRef} defaultValue={ele?.duration} min={1} required max={ele?.duration_format === "Hour" ? 24 : 20}
                                                                                        onInput={(e) => {
                                                                                            const maxValue = ele?.duration_format === "Hour" ? 24 : 20;
                                                                                            if (e.target.value > maxValue) {
                                                                                                e.target.value = maxValue;
                                                                                                setescalationLvlErr(`Value cannot exceed ${maxValue}`);
                                                                                                addDuration(idx, e.target.value, 2)
                                                                                            } else {
                                                                                                setescalationLvlErr('');
                                                                                                addDuration(idx, e.target.value, 2)
                                                                                            }
                                                                                        }}
                                                                                        onKeyDown={(e) => {
                                                                                            const maxValue = ele?.duration_format === "Hour" ? 24 : 20;
                                                                                            if (parseInt(e.key) > maxValue) {
                                                                                                e.preventDefault();
                                                                                                setescalationLvlErr(`Value cannot exceed ${maxValue}`);
                                                                                            } else {
                                                                                                setescalationLvlErr('');
                                                                                                addDuration(idx, e.target.value, 2)
                                                                                            }
                                                                                        }}

                                                                                        type="number" className="form-control" />
                                                                                    <select className="form-control" value={ele?.duration_format} defaultValue={"Select Hour/Day"} onChange={(e) => {
                                                                                        //  inputRef.current.value = "";
                                                                                          addDuration(idx, e.target.value, 1) }}>
                                                                                        <option value={"Select Hour/Day"} disabled>Select Hour/Day</option>
                                                                                        <option >Hour</option>
                                                                                        <option >Days</option>
                                                                                    </select>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {incidentReport.hierarchyList.find(item => item._id === selectedHierarchy)?.hname}
                                                                            </td>
                                                                            <td>

                                                                                <select
                                                                                    id="dsgnation"
                                                                                    name="designation"
                                                                                    onChange={(e) => handleselectdesgn(e, idx)}
                                                                                    className='form-control'
                                                                                    // value={selectedValues[idx] || "Select" || ele?.desgn?.cat_type}
                                                                                    value={ele?.desgn?.cat_type || "Select"}
                                                                                    required
                                                                                >
                                                                                    <option disabled value={"Select"}>Select</option>

                                                                                    {options?.map(item => (
                                                                                        <option key={item.cat_type} value={item.cat_type} disabled={selectedValues.some(selctd => selctd === item.cat_type)}>
                                                                                            {item.hirerachy_name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>


                                                                                {/* <select
                                                                                    id="dsgnation"
                                                                                    name="designation"
                                                                                    onChange={(e) => handleselectdesgn(e, idx)}
                                                                                    className='form-control'
                                                                                    // value={selectedValues[idx] || "Select" || ele?.desgn?.cat_type}
                                                                                    value={ele?.desgn?.cat_type || "Select"}
                                                                                    required
                                                                                >
                                                                                    <option disabled value={"Select"}>Select</option>
                                                                                   
                                                                                    {options?.map(item => (
                                                                                        <option key={item.cat_type} value={item.cat_type} disabled={selectedValues.some(selctd => selctd === item.cat_type)}>
                                                                                            {item.hirerachy_name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>  */}
                                                                            </td>
                                                                            <td>
                                                                                <Popconfirm
                                                                                    description="Are you sure you want to change this Action?"
                                                                                    title="Warning"
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                    onConfirm={(e) => { deleteLvl(ele, idx) }}
                                                                                    zIndex={10000}
                                                                                >

                                                                                    <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18"
                                                                                    />{" "}</Link>
                                                                                </Popconfirm>
                                                                            </td>

                                                                        </tr>
                                                                    )

                                                                })

                                                            }


                                                        </tbody>

                                                    </table>

                                                </>
                                            </>
                                        }
                                        {
                                            console.log(escalationLvl,'escalationLvl')
                                        }
                                        {
                                            validation.values.severity && incidentReport.maxEscalationLevel > 0 &&
                                            <>
                                                <button disabled={incidentReport.maxEscalationLevel === escalationLvl.length} onClick={() => {seterrMsg("");seterrMsgStatus(false); incidentReport.maxEscalationLevel > escalationLvl.length ? addEscalationLvl() : "" }} style={{ marginLeft: "74%" }} className='btn btn-outline-primary'>Add Escalation Level</button>
                                                {
                                                    incidentReport.maxEscalationLevel === escalationLvl.length &&
                                                    <div className="text-danger mt-1" style={{ fontSize: 'smaller', marginLeft: "46%" }}>
                                                        Escalation Level exceed to the Maximum level for hierarchy
                                                    </div>
                                                }
                                            </>
                                        }

                                        <Row>
                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    type="submit"
                                                >
                                                    {Object.keys(incidentReport.editIncidentTypeInfo).length > 0 ? "Update" : "Submit"}
                                                </button>
                                            </div>
                                            {
                                                errMsgStatus &&
                                                <div className='text-danger' style={{fontSize:'smaller'}}>
                                                    {errMsg}
                                                </div> 
                                            }
                                        </Row>
                                    </Form>
                                    :
                                    <Col lg="12">
                                    <Card>
                                        <CardBody style={{ height: "100vh" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                    }
                                   
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </React.Fragment>
    )
}

export default EscalationForm
