import React, { Component } from "react"
import { Card, CardBody, Col, Table, } from "reactstrap"
import { Link } from "react-router-dom"

// import images
import img1 from '../../../assets/images/img-7.jpg';
import img2 from '../../../assets/images/img-4.jpg';
import img3 from '../../../assets/images/img-6.jpg';
import { map, get } from "lodash"
import _ from "lodash";

export default class RightBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orgeditstatus:false
    };
  }


  orgEdit=()=>{
    this.setState({orgeditstatus:!this.state.orgeditstatus?true:false})
  }


  render() {
    const entityOrgInfo = _.filter(this.props.entityInfo.org_mapping_info)
    const entityDlrInfo = _.filter(this.props.entityInfo.mapping_info)
    const uniqueNextLevels = this.props.uniqueNextLevels
    return (
      <React.Fragment>
        <Col xl={3} lg={4}>
          <Card>
            <CardBody className="p-4">
              
              <div>
                <div style={{display:'flex', flexDirection:'row', }}>
                  <div style={{display:'flex', width:'90%', alignItems:'center'}}>
                  <div className="text-muted">Organisation Mapping Info</div>
                  </div>
                  <div style={{display:'flex', width:'10%', alignItems:'center'}}>
                  <i className={this.state.orgeditstatus?"mdi mdi-close font-size-20 text-danger":"mdi mdi-pencil font-size-18 text-danger"} id="edittooltip" onClick={()=>this.orgEdit()}></i>
                  </div>
                </div>
                {
                  this.state.orgeditstatus?
                  <div className="table-responsive">
                  {
                    uniqueNextLevels.length != 0 ?
                    <div style={{ display: 'flex', flexDirection: 'column', }}>
                        {
                            uniqueNextLevels.map((item, idx) => {

                                return (
                                    <div key={idx} style={{borderTop: '1px solid #eff2f7',}}>
                                        <div className="form-group">
                                            <label className="col-md-12 col-form-label">Select {item.hlevelName}</label>
                                            <div className="col-md-12">
                                                <select className="form-select">
                                                    <option value="All">All</option>
                                                    {
                                                        item.hlevelValues.map((data,key) => {
                                                            if (data[item.hlevelName] != undefined)
                                                                return (
                                                                    <option  key={key} value={data[item.hlevelName]}>{data[item.hlevelName]}</option>
                                                                )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                      <div style={{marginTop:20}}>
                      <button type="submit" className="btn btn-primary w-md">
                              Submit
                        </button>
                      </div>
                    </div> : null
                  }
                </div>
                  :
                  <div className="table-responsive">
                  <Table className="table align-middle table-nowrap">
                    <tbody>
                      {map(entityOrgInfo, (member, k) => (
                        <tr key={"_member_" + k}>
                          <td>
                            <h5 className="font-size-14 m-0" style={{paddingBottom:3}}>
                              {member.hierarchy_level} - {member.hierarchy_leveldata}
                            </h5>
                            <h5 className="font-size-14 m-0" style={{color:member.user_name == ""?"#ed1c24":"#000"}} >
                              {member.user_name == ""?"No Information":member.user_name}
                            </h5>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                }
                
              </div>

              <hr className="my-4" />

              <div>
                <p className="text-muted">Dealership Mapping Info</p>
                <div className="table-responsive">
                  <Table className="table align-middle table-nowrap">
                    <tbody>
                      {map(entityOrgInfo, (member, k) => (
                        <tr key={"_member_" + k}>
                          <td>
                            <h5 className="font-size-14 m-0">
                                {member.hierarchy_level} - {entityDlrInfo[0][member.hierarchy_level]} 
                            </h5>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>

              <hr className="my-4" />

              
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}
