import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    Media,
    Row,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Card, CardBody,
    CardText,
  CardTitle,
  Modal
} from "reactstrap"

import Dropzone from "react-dropzone"
import MediaPreview from "./media_preview";
import MarkerImage from './MarkerImage';

import WebcamCapture from './WebCam_Comp';
import * as markerjs2 from 'markerjs2';

const _ = require('lodash')

// var urlSocket = require("../../../helpers/urlSocket")
import urlSocket from '../../../../helpers/urlSocket';


export default class CRUD_Images extends Component {

    constructor(props){
        super(props)
        this.state={
            attachImages: [],
            fileStatus:"clear",
            warning_enabled:false,
            max_video_length:'',
            preview_image_modal : false,
            uploadMerkerFile: [],
            dataLoaded: true,
            db_info: JSON.parse(sessionStorage.getItem("db_info"))
        }
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
        this.imgRef = React.createRef()
    }

    componentDidMount(){
      var data = JSON.parse(sessionStorage.getItem("authUser"))
      //(data,'data')
      this.setState({
        max_video_length : data.config_data.video_ends_after
      })
    }



    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }





      handleAcceptedFiles = files => {
        if(this.state.preview_image_modal === false){
        if(files[0].type.startsWith('image/')){
          console.log(files,'files')
          this.setState({ imageUploading: true, fileStatus: "clear", warning_enabled: false })
        files.map(file => {
          if (file.size > 5120000) {
            this.setState({
              fileStatus: "exceed",
            })
          }
          else {
            Object.assign(file, {
                "preview": URL.createObjectURL(file),
                "formattedSize": this.formatBytes(file.size),
                "uploading": false,
                "filetype": file.type,
                "uploadingStatus": 'Not uploaded',
                "originalName": file.name,
            })
          }
        }
        )
        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });

        if (this.state.fileStatus !== "exceed" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length + files.length <= this.props.selected_option.no_of_img) {
         
          console.log("make modal true")
          this.setState({
            preview_image_modal : true,
            preview_image: files[0].preview,
            uploadMerkerFile : files
          })
          console.log('files', files,this.props.selectedCheckpoint)
         
          this.setState({
            attachImages: this.state.attachImages.concat(files)
          }, ()=>{
            let formData = new FormData();
            for (const key of Object.keys(this.state.attachImages)) {
              formData.append('imagesArray', this.state.attachImages[key])
            }
            try {
              urlSocket.post("storeImage/awswebupload", formData,{
                headers: {
                  'Content-Type': 'multipart/form-data', // Set the Content-Type header
                }}, {
                onUploadProgress: progressEvent => {
                  if (progressEvent.loaded === progressEvent.total) {
                  }
                }
              }).then(response => {
                console.log(response.data.data[0],'response.data.data[0]')
                if (response.data.response_code == 500) {
                  this.setState({attachImages:[]})
                  var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
                    is_selected: true,
                  });
                  // this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].orginalImage
                  if(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].orginalImage === undefined){
                    this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].orginalImage =[]
                    this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].orginalImage.push({orginalImage: response.data.data[0].originalname})
                  }
                  else{
                    this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].orginalImage.push({orginalImage: response.data.data[0].originalname})
                  }
                      //  =
                    // this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images = []
          
                    //           _.each(response.data.data, item => {
          //             _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, child => {
          //               if (child.name == item.originalname) {
          //                 let splitString = item.key.split("/");
          //                 let getFileName = splitString[splitString.length - 1];
          //                 child["uploading"] = false
          //                 child["uploadingStatus"] = "Uploaded"
          //                 child["preview"] = getFileName
          //                 child["source"] = "library"
          //               
          //             })
          //           })
          //           this.setState({ imageUploading: false, refresh: true })
          //           this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
                 this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
          //       }
          //       else {
          //         _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
          //           child["uploading"] = false
          //           child["uploadingStatus"] = "Not Uploaded"
          //         })
          //         this.setState({ imageUploading: false, refresh: true })
                }
      
              })
            } catch (error) {
            }
          })
        }
        else {
          if (this.state.fileStatus === "exceed") {
            this.setState({
              warning_enabled: true,
              warning_message: "One of selected file size is exceed more than 5mb"
            })
          }
          else {
            this.setState({
              warning_enabled: true,
              warning_message: "Maximum Number of files is 5"
            })
          }
        }
      }
      else{
        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });
        files.map(file => {
            Object.assign(file, {
                "preview": URL.createObjectURL(file),
                "formattedSize": this.formatBytes(file.size),
                "uploading": false,
                "filetype": file.type,
                "uploadingStatus": 'Not uploaded',
                "originalName": file.name,
            })
          }
        )

        this.setState({
          attachImages: this.state.attachImages.concat(files)
        }, ()=>{
          let formData = new FormData();
          for (const key of Object.keys(this.state.attachImages)) {
            formData.append('imagesArray', this.state.attachImages[key])
          }
            try {
              urlSocket.post("storeImage/awswebupload", formData,{
                headers: {
                  'Content-Type': 'multipart/form-data', // Set the Content-Type header
                }},
                 {
                onUploadProgress: progressEvent => {
                  if (progressEvent.loaded === progressEvent.total) {
                  }
                }
              }).then(response => {
                if (response.data.response_code == 500) {
                  this.setState({attachImages:[]})

                  var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
                    is_selected: true,
                  });
  
            this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
                  _.each(response.data.data, item => {
                    _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, async child => {
                      if (child.originalname == item.originalname) {
                        var track_loc = await this.access_location()

                        let splitString = item.key.split("/");
                        let getFileName = splitString[splitString.length - 1];
                        child["uploading"] = false
                        child["uploadingStatus"] = "Uploaded"
                        child["preview"] = getFileName
                        child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
                        child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
                        child["source"] = "camera"
                        await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint);
                      }
                    })
                  })
                  this.setState({ imageUploading: false, refresh: true })
                  this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
                }
                else {
                  _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
                    child["uploading"] = false
                    child["uploadingStatus"] = "Not Uploaded"
                  })
                  this.setState({ imageUploading: false, refresh: true })
                }
              })
              .catch(error => {
              })
            }
            catch (error) {
            }
          })
      }
      }
      }





    
      // handleAcceptedFiles = files => {
      //   //(files,'files')
      //   if(files[0].type.startsWith('image/')){
       
       
      //     console.log(files,'files')
      //     this.setState({ imageUploading: true, fileStatus: "clear", warning_enabled: false })
      //   files.map(file => {
      //     if (file.size > 5120000) {
      //       this.setState({
      //         fileStatus: "exceed",
      //       })
      //     }
      //     else {
      //       Object.assign(file, {
      //           "preview": URL.createObjectURL(file),
      //           "formattedSize": this.formatBytes(file.size),
      //           "uploading": false,
      //           "filetype": file.type,
      //           "uploadingStatus": 'Not uploaded',
      //           "originalName": file.name,
      //           // "captured_on": new Date(),
      //           // "path": file.name,
      //           // "name": file.name,
      //           // "type": file.type,
      //           // "uri": URL.createObjectURL(file),
      //       })
      //     }
      //   }
      //   )

        
        
      //   var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
      //     is_selected: true,
      //   });

      //   if (this.state.fileStatus !== "exceed" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length + files.length <= this.props.selected_option.no_of_img) {

         
      //     //this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images = {...this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, ...files}

      //     //this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.cp_attach_images.concat(files)
    
      //     this.setState({
      //       attachImages: this.state.attachImages.concat(files)
      //     }, ()=>{

      //       let formData = new FormData();
    
      //       for (const key of Object.keys(this.state.attachImages)) {
      //         // this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(this.state.attachImages[key])
      //         formData.append('imagesArray', this.state.attachImages[key])
      //       }
      
      //       try {
      //         urlSocket.post("storeImage/awswebupload", formData,{
      //           headers: {
      //             'Content-Type': 'multipart/form-data', // Set the Content-Type header
      //           }}, {
      //           onUploadProgress: progressEvent => {
      //             if (progressEvent.loaded === progressEvent.total) {
      //               //this.progress.current++
      //             }
      //           }
      //         }).then(response => {
      //           //(response,'response')
      //           if (response.data.response_code == 500) {
      //             this.setState({attachImages:[]})

      //             var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
      //               is_selected: true,
      //             });
      //             this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
  
                  
      //             _.each(response.data.data, item => {
      //               _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, child => {
      //                 if (child.name == item.originalname) {
      //                   let splitString = item.key.split("/");
      //                   let getFileName = splitString[splitString.length - 1];
      //                   child["uploading"] = false
      //                   child["uploadingStatus"] = "Uploaded"
      //                   child["preview"] = getFileName
      //                   // child["lat"] = String(this.props.latitude)
      //                   // child["long"] = String(this.props.longitude)
      //                   child["source"] = "library"

      //                 }
      //               })
      //             })
      //             this.setState({ imageUploading: false, refresh: true })
      //             this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
      //             this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
      //           }
      //           else {
      //             _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
      //               child["uploading"] = false
      //               child["uploadingStatus"] = "Not Uploaded"
      //             })
      //             this.setState({ imageUploading: false, refresh: true })
      //           }
      
      //         })
      //       } catch (error) {
      //         //("error", error)
      //       }
      //     })
    
          
      //   }
      //   else {
      //     if (this.state.fileStatus === "exceed") {
      //       this.setState({
      //         warning_enabled: true,
      //         warning_message: "One of selected file size is exceed more than 5mb"
      //       })
      //     }
      //     else {
      //       this.setState({
      //         warning_enabled: true,
      //         warning_message: "Maximum Number of files is 5"
      //       })
      //     }
      //   }

        
      // }
      // else{
      //   //(files)

      //   var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
      //     is_selected: true,
      //   });
      //   files.map(file => {
      //     // if (file.size > 5120000) {
      //     //   this.setState({
      //     //     fileStatus: "exceed",
      //     //   })
      //     // }
      //     // else {
      //       Object.assign(file, {
      //           "preview": URL.createObjectURL(file),
      //           "formattedSize": this.formatBytes(file.size),
      //           "uploading": false,
      //           "filetype": file.type,
      //           "uploadingStatus": 'Not uploaded',
      //           "originalName": file.name,
      //           // "captured_on": new Date(),
      //           // "path": file.name,
      //           // "name": file.name,
      //           // "type": file.type,
      //           // "uri": URL.createObjectURL(file),
      //       })
      //   //   }
      //   // }
      //     }
      //   )

      //   this.setState({
      //     attachImages: this.state.attachImages.concat(files)
      //   }, ()=>{

      //     // let formData = new FormData();
      //     let formData = new FormData();
      //     //(this.state.attachImages,'this.state.attachImages')
      //     for (const key of Object.keys(this.state.attachImages)) {
      //       // this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(this.state.attachImages[key])
      //       formData.append('imagesArray', this.state.attachImages[key])
      //     }
    
      //       // formData.append('imagesArray', files)
      
      //       try {
      //         urlSocket.post("storeImage/awswebupload", formData,{
      //           headers: {
      //             'Content-Type': 'multipart/form-data', // Set the Content-Type header
      //           }},
      //            {
      //           onUploadProgress: progressEvent => {
      //             if (progressEvent.loaded === progressEvent.total) {
      //               //this.progress.current++
      //             }
      //           }
      //         }).then(response => {
      //           //(response,'response')
      //           if (response.data.response_code == 500) {
      //             this.setState({attachImages:[]})

      //             var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
      //               is_selected: true,
      //             });
  
      //       this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
      //               //(this.props.selectedCheckpoint.checkpoint_options,'this.props.selectedCheckpoint.checkpoint_options')
      //             _.each(response.data.data, item => {
      //               _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, async child => {
      //                 //(child,item.originalname,'child.name == item.originalname')

      //                 if (child.originalname == item.originalname) {
      //                   var track_loc = await this.access_location()

      //                   let splitString = item.key.split("/");
      //                   let getFileName = splitString[splitString.length - 1];
      //                   child["uploading"] = false
      //                   child["uploadingStatus"] = "Uploaded"
      //                   child["preview"] = getFileName
      //                   child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
      //                   child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
      //                   child["source"] = "camera"
      //                   await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint);
      //                 }
      //               })
      //             })
      //             this.setState({ imageUploading: false, refresh: true })
      //             this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
      //             // this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
      //           }
      //           else {
      //             _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
      //               child["uploading"] = false
      //               child["uploadingStatus"] = "Not Uploaded"
      //             })
      //             this.setState({ imageUploading: false, refresh: true })
      //           }
      //         })
      //         .catch(error => {
      //           //("error", error)
      //         })
      //       }
      //       catch (error) {
      //         //("catch error", error)
      //       }
      //     })
      // }
      // }

      // access_location =async ()=>{
      //   navigator.geolocation.getCurrentPosition(
      //     position =>{
      //         var location_data ={latitude :position.coords.latitude ,longitude : position.coords.longitude  }
      //         return location_data
      //         // this.props.history.push("/enpcpts");
      //       // });
      //     },
      //     error =>{
      //       //(error,'error')
      //       return null

      //     }
      //   )



      // }

      access_location = () => {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            position => {
              var location_data = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              };
              resolve(location_data);
            },
            error => {
              //(error, 'error');
              reject(null);
            }
          );
        });
      };
      

      uploadWebCamVedio=(file)=>{
        //(file,'file')
        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });

        let formData = new FormData();
    
            formData.append('imagesArray', file)
      
            try {
              urlSocket.post("storeImage/awswebupload", formData,{
                headers: {
                  'Content-Type': 'multipart/form-data', // Set the Content-Type header
                }},
                 {
                onUploadProgress: progressEvent => {
                  if (progressEvent.loaded === progressEvent.total) {
                    //this.progress.current++
                  }
                }
              }).then(response => {
                //(response,'response')
                if (response.data.response_code == 500) {
                  this.setState({attachImages:[]})

                  var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
                    is_selected: true,
                  });
  
            this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
                    //(this.props.selectedCheckpoint.checkpoint_options,'this.props.selectedCheckpoint.checkpoint_options')
                  _.each(response.data.data, item => {
                    _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, async child => {
                      //(child,item.originalname,'child.name == item.originalname')

                      if (child.originalname == item.originalname) {
                        var track_loc = await this.access_location()

                        let splitString = item.key.split("/");
                        let getFileName = splitString[splitString.length - 1];
                        child["uploading"] = false
                        child["uploadingStatus"] = "Uploaded"
                        child["preview"] = getFileName
                        child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
                        child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
                        child["source"] = "camera"
                        await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint);
                      }
                    })
                  })
                  this.setState({ imageUploading: false, refresh: true })
                  this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
                  // this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
                }
                else {
                  _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
                    child["uploading"] = false
                    child["uploadingStatus"] = "Not Uploaded"
                  })
                  this.setState({ imageUploading: false, refresh: true })
                }
              })
              .catch(error => {
                //("error", error)
              })
            }
            catch (error) {
              //("catch error", error)
            }


      }








      uploadWebCamImage = async(file) => {

        Object.assign(file, {
          "preview": file.name, //URL.createObjectURL(file),
          "formattedSize": this.formatBytes(file.size),
          "uploading": false,
          "filetype": file.type,
          "uploadingStatus": 'Not uploaded',
          "originalName": file.name,
           "captured_on": new Date(),
            "path": file.name,
          //  "name": image_info.name,
          // "type": file.type,
            //"uri": URL.createObjectURL(image_info),
      })
      //window.location = URL.createObjectURL(file);

        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });

        let formData = new FormData();
    
            formData.append('imagesArray', file)
           
      
            try {
              urlSocket.post("storeImage/awswebupload", formData,{
                headers: {
                  'Content-Type': 'multipart/form-data', // Set the Content-Type header
                }},
                 {
                onUploadProgress: progressEvent => {
                  if (progressEvent.loaded === progressEvent.total) {
                    //this.progress.current++
                  }
                }
              }).then(response => {
                //(response,'response')
                if (response.data.response_code == 500) {
                  this.setState({attachImages:[]})

                  var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
                    is_selected: true,
                  });
                  this.setState({
                    preview_image_modal : true,
                    preview_image: this.props.imagePreviewUrl+response.data.data[0].originalname,
                    uploadMerkerFile : file
                  })
                  this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].orginalImage=response.data.data[0].originalname
  
                  
                  // _.each(response.data.data, item => {
                  //   _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, async child => {
                  //    //(child,item.originalname,'child.name == item.originalname')
                  //     if (child.originalname == item.originalname) {
                  //       var track_loc = await this.access_location()

                  //       let splitString = item.key.split("/");
                  //       let getFileName = splitString[splitString.length - 1];
                  //       child["uploading"] = false
                  //       child["uploadingStatus"] = "Uploaded"
                  //       child["preview"] = getFileName
                  //       child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
                  //       child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
                  //       child["source"] = "camera"
                  // this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images

                  this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint);
                  //     }
                  //   })
                  // })
                  // this.setState({ imageUploading: false, refresh: true })
                  // this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
                }
                // else {
                //   _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
                //     child["uploading"] = false
                //     child["uploadingStatus"] = "Not Uploaded"
                //   })
                //   this.setState({ imageUploading: false, refresh: true })
                // }
              })
              .catch(error => {
                //("error", error)
              })
            }
            catch (error) {
              //("catch error", error)
            }
      }

      updateCheckpointImages(data, item) {
        item["cp_attach_images"] = data
        // if(item["cp_attach_videos"]== undefined){
        //   item["cp_attach_videos"]=[]
        // }
        // //(item,'item')
        this.props.saveCheckpoint(item)
      }


      deleteFileInAws=(fileInfo)=>{

        try {

          urlSocket.post('storeImage/delete-file-aws',{
            fileInfo :fileInfo
          }).then((response)=>{
            console.log(response,'response')

          })
          
        } catch (error) {
          
        }



      }
    
      deleteImage =async item => {

        await this.deleteFileInAws(item)

        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });
        //(item,'item')
        var getIndex = _.findIndex(
          this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images,
          { originalName: item.originalName }
        );

        console.log(item,'item',this.props.selectedCheckpoint.checkpoint_options[getOptionIndex])

        this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.splice(getIndex, 1);
        // if(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].orginalImage !== undefined){
        //  this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].orginalImage?.splice(getIndex, 1);
        // }
        this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
        this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
        this.setState({ fileStatus: "clear", warning_enabled: false, refresh: true ,markers:"" })
        
      }

      
      // showMarkerArea =()=>{
      //   console.log("start marking image",this.imgRef)
      //   if (this.imgRef.current !== null) {
      //     const markerArea = new markerjs2.MarkerArea(this.imgRef.current);
      //     markerArea.uiStyleSettings.redoButtonVisible = true;
      //     markerArea.uiStyleSettings.notesButtonVisible = true;
      //     markerArea.uiStyleSettings.zoomButtonVisible = true;
      //     markerArea.uiStyleSettings.zoomOutButtonVisible = true;
      //     markerArea.uiStyleSettings.clearButtonVisible = true;
      //     var maState
      //     markerArea.addEventListener('render', event => {
      //       if (this.imgRef.current) {
      //         this.imgRef.current.src = event.dataUrl;
      //         maState = event.state;
      //         console.log( event.state,' event.state')
      //       }
      //     });
      //     console.log('dataUrl', this.imgRef.current.src)
      //     markerArea.show();
      //     if (maState) {
      //       markerArea.restoreState(maState);
      //     }
      //   }

      // }


    //   onUploadMarkerFile = (markerFile) => {
    //     console.log('markerFile', markerFile, this.state.uploadMerkerFile);
    
    //     // Update the preview property of each file with the sketched image data
    //     const updatedFiles = this.state.uploadMerkerFile.map(file => {
    //         return  Object.assign
    //         (file, {
    //             preview: markerFile,
    //         })
    //     });
    
    //     // Update the state with the modified files
    //     this.setState({ uploadMerkerFile: updatedFiles }, () => {
    //         console.log('updatedFiles', this.state.uploadMerkerFile);
    
    //         // Concatenate the modified files with attachImages and prepare FormData
    //         const formData = new FormData();
    //         this.state.uploadMerkerFile.forEach(file => {
    //             formData.append('imagesArray', file);
    //         });
    
    //         // Make the API request to upload the images along with their sketched versions
    //         urlSocket.post("storeImage/awswebupload", formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data', // Set the Content-Type header
    //             }
    //         }).then(response => {
    //             console.log('Upload response:', response);
    //             // Handle response as needed
    //         }).catch(error => {
    //             console.error('Upload error:', error);
    //             // Handle error as needed
    //         });
    //     });
    // }
    
    updateMarkerInfo=async(marker_info,dataUrl,originalname)=>{
    
      try {
        urlSocket.post('cog/update-marker-info',{
          marker_info:marker_info,
          dataUrl:dataUrl,
          db_info :this.state.db_info,
          originalname:originalname,
          checkpointInfo : this.props.selectedCheckpoint
        }).then((response)=>{
            console.log(response,'response')
        })
        
      } catch (error) {
        
      }
    }


    getMarkerInfo=(fileInfo,mode)=>{
      var apiUrl = mode === 0 ? "get-marker-info" :"delete-marker-img"
      try {
          urlSocket.post(`cog/${apiUrl}`,{
            fileInfo:fileInfo,
            db_info :this.state.db_info,
            checkpointInfo : this.props.selectedCheckpoint
          }).then((response)=>{
            console.log(response,'response')
            if(response.data.response_code === 500 && mode === 0){
              this.setState({
                markers : response.data.data.length >0 ? response.data.data[0].marker_info : "",
                preview_image_modal: true
              },()=>{
                console.log(this.state.markers,'markers')
              })
            }
          
          })        
      } catch (error) {
          console.log(error,'error')
      } 
    }

    


  onUploadMarkerFile =async (markerFile, markerData) => {
    console.log('markerFile', markerFile, markerData, this.state.uploadMerkerFile, this.state.markers)
  
    // var markerStageApi = this.state.markers ? "awswebupload" : "awswebupload"
    console.log(this.state.editOrginalName,'this.state.editOrginalName')
    if(this.state.markers){
      var fileInfo={
        originalname :this.state.editOrginalName
      }
     await this.deleteFileInAws(fileInfo)
    }
    let formData = new FormData();
    formData.append('imagesArray', markerFile)
    try {
      urlSocket.post(`storeImage/awswebupload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the Content-Type header
        }
      }, {
        onUploadProgress: progressEvent => {
          if (progressEvent.loaded === progressEvent.total) {
          }
        }
      }).then(async response => {
        console.log(response, 'response')
        if (response.data.response_code == 500) {
          this.setState({ attachImages: [], preview_image_modal: false })
          var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
            is_selected: true,
          });
          await this.updateMarkerInfo(markerData.state,markerData.dataUrl,response.data.data[0].originalname)
          // response.data.data[0]["marker_info"] = markerData.state
          // response.data.data[0]["dataUrl"] = markerData.dataUrl
          console.log(this.state.editPos,'this.state.editPos')
          if(this.state.editPos !== undefined){
            response.data.data[0]["originalName"]=response.data.data[0]["originalname"]
            this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images[this.state.editPos]=response.data.data[0]
          }
          else{
          console.log("else",'this.state.editPos')
          response.data.data[0]["originalName"]=response.data.data[0]["originalname"]
            this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
          }

          _.each(response.data.data, item => {
            _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, child => {
              console.log(child.name == item.originalname, 'child.name == item.originalname', child.name, item.originalname)
              if (child.name == item.originalname) {
                let splitString = item.key.split("/");
                let getFileName = splitString[splitString.length - 1];
                child["uploading"] = false
                child["uploadingStatus"] = "Uploaded"
                child["preview"] = getFileName
                child["source"] = "library"

              }
            })
          })
          this.setState({ imageUploading: false, refresh: false, dataLoaded: false }, () => {
            this.setState({
              dataLoaded: true,
              refresh: true,
              editPos : undefined,
              markers:""
            })
          })
          this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
          this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
        }
        else {
          _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
            child["uploading"] = false
            child["uploadingStatus"] = "Not Uploaded"
          })
          this.setState({ imageUploading: false, refresh: true })
        }

      })
    } catch (error) {
    }
    // })
    // });

  }



    render() {

        var selected_option = this.props.selected_option

        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });
        //(selected_option,this.props)
        if(this.state.dataLoaded){
        return (
            <div >
              {
                this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length !==  this.props.selectedCheckpoint.cp_noof_images?
                <div>
                  <label>Add Images</label>
                  <Row className="my-3">
                    <WebcamCapture video={false} uploadWebCamImage = {(data)=>{this.uploadWebCamImage(data)}} uploadWebCamVedio = {(data)=>{this.uploadWebCamVedio(data)}} max_video_length={Number(this.state.max_video_length)} />
                  </Row>
                <Row className="my-3">
                    <Col sm={"12"} lg={"12"}>
                        <div style={{ zoom: 0.5 }}>
                            <Form>
                                <Dropzone
                                    onDrop={acceptedFiles =>
                                        this.handleAcceptedFiles(acceptedFiles)
                                    }
                                   
                                    accept={[".jpg", ".jpeg", ".png"]}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()}
                                                 onKeyDown={e => {
                                                  console.log(e,'e')
                                                  if (e.key === 'Enter') {
                                                      e.preventDefault();
                                                  }
                                              }}
                                                 />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Drop Images or Videos here or click to upload.</h4>

                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                            </Form>
                        </div>
                        <div className="mt-2 font-size-11 text-dark text-center">
                            <span >Files accepted - .jpg, .jpeg, .png, .bmp </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span >Maximum individual file size - 5mb</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span >Minimum Number of files - {selected_option.no_of_img}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span >Maximum upto 10 files</span>
                        </div>
                    </Col>
                </Row>
                {/* } */}
                </div>: this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.cp_noof_images !== 0 ?
                <div style={{padding:10}}><label style={{fontSize:12, color:"#ff6666"}}>You are reached the Maximum requirment. If you want to retake or upload image, delete one of the image uploaded and capture/upload the picture </label></div>:null
              }
                
                {
                    this.state.warning_enabled ? <Row>
                        <div className="my-2 font-size-12 text-danger">{this.state.warning_message}</div>
                    </Row> : null
                }
                <Row>
                    <Col sm={"12"} lg={"12"}>
                        <Row
                            className="dropzone-previews"
                            id="file-previews"

                        >
                         
                            {this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length !== 0 && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.map((f, i) => {
                                return (
                                    <Col lg={"6"} key={String(i) + "-file"}>
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            
                                        >
                                      {
                                        console.log(f,this.props.imagePreviewUrl+f.originalName, 'this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images')
                                      }
                                            <div style={{ display: 'flex', flexDirection: 'row' }} >
                                                <div className="p-2 col-11" >
                                                    <Row className="align-items-center">
                                                        <Col className="col-12">
                                                          {
                                                            f?.mimetype === undefined ? f?.filetype.startsWith("video/") : f?.mimetype.startsWith("video/") ?
                                                            // f?.mimetype.startsWith("video/") || f?.filetype.startsWith("video/") ?
                                                            <video
                                                    style={{ width: "500px" }}
                                                   src={this.props.imagePreviewUrl + f.originalname}
                                                    // src={"https://d3pnv0bkd16srd.cloudfront.net/eaudit-file/" + f.originalname}
                                                    controls
                                                  />
                                                            :
                                                            <img
                                                            data-dz-thumbnail=""
                                                            width="100%"
                                                            className=" bg-light"
                                                            alt={f.uploadingStatus === "Uploaded" ? f.path : f.name}
                                                          src={this.props.imagePreviewUrl + (f.originalName === undefined ? f.originalname : f.originalName) }
                                                            // src={"https://d3pnv0bkd16srd.cloudfront.net/eaudit-file/" + f.originalname}

                                                        />
                                                          }

                                                        </Col>
                                                       
                                                        {/* <Col>
                                                            <Link
                                                                to="#"
                                                                className="text-muted font-weight-bold"
                                                            >
                                                                {f.uploadingStatus === "Uploaded" ? f.path : f.name}
                                                            </Link>
                                                            <p className="mb-0">
                                                                <strong>{f.formattedSize}</strong>
                                                            </p>
                                                        </Col> */}

                                                    </Row>
                                                    <div className="mt-1">
                                                        <span className={f.uploading ? "font-size-10 text-danger" : "font-size-10 text-success"}>{f.uploadingStatus}</span>
                                                    </div>

                                                </div>
                                                {
                                                  this.props.audit_status !== "3" && this.props.audit_status !== "4"?

                                                  <div className="col-1 text-end me-2">
                                             <Link to="#" onClick={()=>{this.getMarkerInfo(f,0);this.setState({editPos:i, preview_image :this.props.imagePreviewUrl + this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].orginalImage[i].orginalImage ,editOrginalName:f.originalname })}}><i className="mdi mdi-pencil-outline font-size-20 text-primary" /></Link>
                                                    <Link to="#" onClick={() => {this.deleteImage(f);this.getMarkerInfo(f,1)}}><i className="mdi mdi-close-circle-outline font-size-20 text-danger" /></Link>
                                                </div>:null
                                                }
                                                
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                {
                  this.state.preview_image_modal &&
                  <div>
                    <MarkerImage
                      preview_image_modal={this.state.preview_image_modal}
                      preview_url={this.state.preview_image}
                      onCancelModal={() => this.setState({ preview_image_modal: !this.state.preview_image_modal, preview_image: "" })}
                      onUploadMarkerFile={(markerFile, markerData) => this.onUploadMarkerFile(markerFile, markerData)}
                      markers={this.state.markers}
                      editOrginalName={this.state.editOrginalName}
                    />
                  </div>
                }
                    </Col>
                </Row>
            </div>
        )
      }
      else{
        return null
      }

    }

}














// //b4-08-apr-24
// import React, { Component } from 'react'
// import { Link } from "react-router-dom";
// import {
//     Button,
//     Col,
//     Form,
//     Media,
//     Row,
//     UncontrolledDropdown,
//     Dropdown,
//     DropdownItem,
//     DropdownMenu,
//     DropdownToggle,
//     Card, CardBody,
//     CardText,
//   CardTitle,
// } from "reactstrap"

// import Dropzone from "react-dropzone"
// import MediaPreview from "./media_preview";

// import WebcamCapture from './WebCam_Comp';

// const _ = require('lodash')

// // var urlSocket = require("../../../helpers/urlSocket")
// import urlSocket from '../../../../helpers/urlSocket';


// export default class CRUD_Images extends Component {

//     constructor(props){
//         super(props)
//         this.state={
//             attachImages: [],
//             fileStatus:"clear",
//             warning_enabled:false,
//             max_video_length:''
//         }
//         this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
//     }

//     componentDidMount(){
//       var data = JSON.parse(sessionStorage.getItem("authUser"))
//       //(data,'data')
//       this.setState({
//         max_video_length : data.config_data.video_ends_after
//       })
//     }



//     formatBytes = (bytes, decimals = 2) => {
//         if (bytes === 0) return "0 Bytes"
//         const k = 1024
//         const dm = decimals < 0 ? 0 : decimals
//         const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
//         const i = Math.floor(Math.log(bytes) / Math.log(k))
//         return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
//       }
    
//       handleAcceptedFiles = files => {
//         //(files,'files')
//         if(files[0].type.startsWith('image/')){
//         this.setState({ imageUploading: true, fileStatus: "clear", warning_enabled: false })
//         files.map(file => {
//           if (file.size > 5120000) {
//             this.setState({
//               fileStatus: "exceed",
//             })
//           }
//           else {
//             Object.assign(file, {
//                 "preview": URL.createObjectURL(file),
//                 "formattedSize": this.formatBytes(file.size),
//                 "uploading": false,
//                 "filetype": file.type,
//                 "uploadingStatus": 'Not uploaded',
//                 "originalName": file.name,
//                 // "captured_on": new Date(),
//                 // "path": file.name,
//                 // "name": file.name,
//                 // "type": file.type,
//                 // "uri": URL.createObjectURL(file),
//             })
//           }
//         }
//         )

        
        
//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });

//         if (this.state.fileStatus !== "exceed" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length + files.length <= this.props.selected_option.no_of_img) {

         
//           //this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images = {...this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, ...files}

//           //this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.cp_attach_images.concat(files)
    
//           this.setState({
//             attachImages: this.state.attachImages.concat(files)
//           }, ()=>{

//             let formData = new FormData();
    
//             for (const key of Object.keys(this.state.attachImages)) {
//               // this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(this.state.attachImages[key])
//               formData.append('imagesArray', this.state.attachImages[key])
//             }
      
//             try {
//               urlSocket.post("storeImage/awswebupload", formData,{
//                 headers: {
//                   'Content-Type': 'multipart/form-data', // Set the Content-Type header
//                 }}, {
//                 onUploadProgress: progressEvent => {
//                   if (progressEvent.loaded === progressEvent.total) {
//                     //this.progress.current++
//                   }
//                 }
//               }).then(response => {
//                 //(response,'response')
//                 if (response.data.response_code == 500) {
//                   this.setState({attachImages:[]})

//                   var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//                     is_selected: true,
//                   });
//                   this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
  
                  
//                   _.each(response.data.data, item => {
//                     _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, child => {
//                       if (child.name == item.originalname) {
//                         let splitString = item.key.split("/");
//                         let getFileName = splitString[splitString.length - 1];
//                         child["uploading"] = false
//                         child["uploadingStatus"] = "Uploaded"
//                         child["preview"] = getFileName
//                         // child["lat"] = String(this.props.latitude)
//                         // child["long"] = String(this.props.longitude)
//                         child["source"] = "library"

//                       }
//                     })
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                   this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
//                   this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
//                 }
//                 else {
//                   _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
//                     child["uploading"] = false
//                     child["uploadingStatus"] = "Not Uploaded"
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                 }
      
//               })
//             } catch (error) {
//               //("error", error)
//             }
//           })
    
          
//         }
//         else {
//           if (this.state.fileStatus === "exceed") {
//             this.setState({
//               warning_enabled: true,
//               warning_message: "One of selected file size is exceed more than 5mb"
//             })
//           }
//           else {
//             this.setState({
//               warning_enabled: true,
//               warning_message: "Maximum Number of files is 5"
//             })
//           }
//         }
//       }
//       else{
//         //(files)

//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });
//         files.map(file => {
//           // if (file.size > 5120000) {
//           //   this.setState({
//           //     fileStatus: "exceed",
//           //   })
//           // }
//           // else {
//             Object.assign(file, {
//                 "preview": URL.createObjectURL(file),
//                 "formattedSize": this.formatBytes(file.size),
//                 "uploading": false,
//                 "filetype": file.type,
//                 "uploadingStatus": 'Not uploaded',
//                 "originalName": file.name,
//                 // "captured_on": new Date(),
//                 // "path": file.name,
//                 // "name": file.name,
//                 // "type": file.type,
//                 // "uri": URL.createObjectURL(file),
//             })
//         //   }
//         // }
//           }
//         )

//         this.setState({
//           attachImages: this.state.attachImages.concat(files)
//         }, ()=>{

//           // let formData = new FormData();
//           let formData = new FormData();
//           //(this.state.attachImages,'this.state.attachImages')
//           for (const key of Object.keys(this.state.attachImages)) {
//             // this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(this.state.attachImages[key])
//             formData.append('imagesArray', this.state.attachImages[key])
//           }
    
//             // formData.append('imagesArray', files)
      
//             try {
//               urlSocket.post("storeImage/awswebupload", formData,{
//                 headers: {
//                   'Content-Type': 'multipart/form-data', // Set the Content-Type header
//                 }},
//                  {
//                 onUploadProgress: progressEvent => {
//                   if (progressEvent.loaded === progressEvent.total) {
//                     //this.progress.current++
//                   }
//                 }
//               }).then(response => {
//                 //(response,'response')
//                 if (response.data.response_code == 500) {
//                   this.setState({attachImages:[]})

//                   var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//                     is_selected: true,
//                   });
  
//             this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
//                     //(this.props.selectedCheckpoint.checkpoint_options,'this.props.selectedCheckpoint.checkpoint_options')
//                   _.each(response.data.data, item => {
//                     _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, async child => {
//                       //(child,item.originalname,'child.name == item.originalname')

//                       if (child.originalname == item.originalname) {
//                         var track_loc = await this.access_location()

//                         let splitString = item.key.split("/");
//                         let getFileName = splitString[splitString.length - 1];
//                         child["uploading"] = false
//                         child["uploadingStatus"] = "Uploaded"
//                         child["preview"] = getFileName
//                         child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
//                         child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
//                         child["source"] = "camera"
//                         await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint);
//                       }
//                     })
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                   this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
//                   // this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
//                 }
//                 else {
//                   _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
//                     child["uploading"] = false
//                     child["uploadingStatus"] = "Not Uploaded"
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                 }
//               })
//               .catch(error => {
//                 //("error", error)
//               })
//             }
//             catch (error) {
//               //("catch error", error)
//             }
//           })
//       }
//       }

//       // access_location =async ()=>{
//       //   navigator.geolocation.getCurrentPosition(
//       //     position =>{
//       //         var location_data ={latitude :position.coords.latitude ,longitude : position.coords.longitude  }
//       //         return location_data
//       //         // this.props.history.push("/enpcpts");
//       //       // });
//       //     },
//       //     error =>{
//       //       //(error,'error')
//       //       return null

//       //     }
//       //   )



//       // }

//       access_location = () => {
//         return new Promise((resolve, reject) => {
//           navigator.geolocation.getCurrentPosition(
//             position => {
//               var location_data = {
//                 latitude: position.coords.latitude,
//                 longitude: position.coords.longitude
//               };
//               resolve(location_data);
//             },
//             error => {
//               //(error, 'error');
//               reject(null);
//             }
//           );
//         });
//       };
      

//       uploadWebCamVedio=(file)=>{
//         //(file,'file')
//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });

//         let formData = new FormData();
    
//             formData.append('imagesArray', file)
      
//             try {
//               urlSocket.post("storeImage/awswebupload", formData,{
//                 headers: {
//                   'Content-Type': 'multipart/form-data', // Set the Content-Type header
//                 }},
//                  {
//                 onUploadProgress: progressEvent => {
//                   if (progressEvent.loaded === progressEvent.total) {
//                     //this.progress.current++
//                   }
//                 }
//               }).then(response => {
//                 //(response,'response')
//                 if (response.data.response_code == 500) {
//                   this.setState({attachImages:[]})

//                   var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//                     is_selected: true,
//                   });
  
//             this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
//                     //(this.props.selectedCheckpoint.checkpoint_options,'this.props.selectedCheckpoint.checkpoint_options')
//                   _.each(response.data.data, item => {
//                     _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, async child => {
//                       //(child,item.originalname,'child.name == item.originalname')

//                       if (child.originalname == item.originalname) {
//                         var track_loc = await this.access_location()

//                         let splitString = item.key.split("/");
//                         let getFileName = splitString[splitString.length - 1];
//                         child["uploading"] = false
//                         child["uploadingStatus"] = "Uploaded"
//                         child["preview"] = getFileName
//                         child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
//                         child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
//                         child["source"] = "camera"
//                         await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint);
//                       }
//                     })
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                   this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
//                   // this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
//                 }
//                 else {
//                   _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
//                     child["uploading"] = false
//                     child["uploadingStatus"] = "Not Uploaded"
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                 }
//               })
//               .catch(error => {
//                 //("error", error)
//               })
//             }
//             catch (error) {
//               //("catch error", error)
//             }


//       }








//       uploadWebCamImage = async(file) => {

//         Object.assign(file, {
//           "preview": file.name, //URL.createObjectURL(file),
//           "formattedSize": this.formatBytes(file.size),
//           "uploading": false,
//           "filetype": file.type,
//           "uploadingStatus": 'Not uploaded',
//           "originalName": file.name,
//            "captured_on": new Date(),
//             "path": file.name,
//           //  "name": image_info.name,
//           // "type": file.type,
//             //"uri": URL.createObjectURL(image_info),
//       })
//       //window.location = URL.createObjectURL(file);

//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });

//         let formData = new FormData();
    
//             formData.append('imagesArray', file)
      
//             try {
//               urlSocket.post("storeImage/awswebupload", formData,{
//                 headers: {
//                   'Content-Type': 'multipart/form-data', // Set the Content-Type header
//                 }},
//                  {
//                 onUploadProgress: progressEvent => {
//                   if (progressEvent.loaded === progressEvent.total) {
//                     //this.progress.current++
//                   }
//                 }
//               }).then(response => {
//                 //(response,'response')
//                 if (response.data.response_code == 500) {
//                   this.setState({attachImages:[]})

//                   var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//                     is_selected: true,
//                   });
//                   this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
  
                  
//                   _.each(response.data.data, item => {
//                     _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, async child => {
//                      //(child,item.originalname,'child.name == item.originalname')
//                       if (child.originalname == item.originalname) {
//                         var track_loc = await this.access_location()

//                         let splitString = item.key.split("/");
//                         let getFileName = splitString[splitString.length - 1];
//                         child["uploading"] = false
//                         child["uploadingStatus"] = "Uploaded"
//                         child["preview"] = getFileName
//                         child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
//                         child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
//                         child["source"] = "camera"
//                         await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint);
//                       }
//                     })
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                   this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
//                   // this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
//                 }
//                 else {
//                   _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
//                     child["uploading"] = false
//                     child["uploadingStatus"] = "Not Uploaded"
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                 }
//               })
//               .catch(error => {
//                 //("error", error)
//               })
//             }
//             catch (error) {
//               //("catch error", error)
//             }
//       }

//       updateCheckpointImages(data, item) {
//         item["cp_attach_images"] = data
//         // if(item["cp_attach_videos"]== undefined){
//         //   item["cp_attach_videos"]=[]
//         // }
//         // //(item,'item')
//         this.props.saveCheckpoint(item)
//       }
    
//       deleteImage = item => {

//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });
//         //(item,'item')
//         var getIndex = _.findIndex(
//           this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images,
//           { originalName: item.originalName }
//         );

//         console.log(item,'item')

//         this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.splice(getIndex, 1);
//         this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
//         this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
//         this.setState({ fileStatus: "clear", warning_enabled: false, refresh: true  })
        
//       }

      




//     render() {

//         var selected_option = this.props.selected_option

//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });
//         //(selected_option,this.props)

//         return (
//             <div >
//               {
//                 this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length !==  this.props.selectedCheckpoint.cp_noof_images?
//                 <div>
//                   <label>Add Images</label>
//                   {/* {
//                     this.props.selected_option.enable_cam ==  true && */}
//                   <Row className="my-3">
//                     <WebcamCapture video={false} uploadWebCamImage = {(data)=>{this.uploadWebCamImage(data)}} uploadWebCamVedio = {(data)=>{this.uploadWebCamVedio(data)}} max_video_length={Number(this.state.max_video_length)} />
//                   </Row>
//                   {/* } */}

//                   {/* {
//                     this.props.selected_option.enable_gallery ==  true && */}
//                 <Row className="my-3">
//                     <Col sm={"12"} lg={"12"}>
//                         <div style={{ zoom: 0.5 }}>
//                             <Form>
//                                 <Dropzone
//                                     onDrop={acceptedFiles =>
//                                         this.handleAcceptedFiles(acceptedFiles)
//                                     }
//                                     accept={[".jpg", ".jpeg", ".png"]}
//                                 >
//                                     {({ getRootProps, getInputProps }) => (
//                                         <div className="dropzone">
//                                             <div
//                                                 className="dz-message needsclick"
//                                                 {...getRootProps()}
//                                             >
//                                                 <input {...getInputProps()} />
//                                                 <div className="mb-3">
//                                                     <i className="display-4 text-muted bx bxs-cloud-upload" />
//                                                 </div>
//                                                 <h4>Drop Images or Videos here or click to upload.</h4>

//                                             </div>
//                                         </div>
//                                     )}
//                                 </Dropzone>

//                             </Form>
//                         </div>
//                         <div className="mt-2 font-size-11 text-dark text-center">
//                             <span >Files accepted - .jpg, .jpeg, .png, .bmp </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                             <span >Maximum individual file size - 5mb</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                             <span >Minimum Number of files - {selected_option.no_of_img}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                             <span >Maximum upto 10 files</span>
//                         </div>
//                     </Col>
//                 </Row>
//                 {/* } */}
//                 </div>: this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.cp_noof_images !== 0 ?
//                 <div style={{padding:10}}><label style={{fontSize:12, color:"#ff6666"}}>You are reached the Maximum requirment. If you want to retake or upload image, delete one of the image uploaded and capture/upload the picture </label></div>:null
//               }
                
//                 {
//                     this.state.warning_enabled ? <Row>
//                         <div className="my-2 font-size-12 text-danger">{this.state.warning_message}</div>
//                     </Row> : null
//                 }
//                 <Row>
//                     <Col sm={"12"} lg={"12"}>
//                         <Row
//                             className="dropzone-previews"
//                             id="file-previews"

//                         >
//                             {this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length !== 0 && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.map((f, i) => {
//                                 return (
//                                     <Col lg={"6"} key={String(i) + "-file"}>
//                                         <Card
//                                             className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            
//                                         >
//                                             <div style={{ display: 'flex', flexDirection: 'row' }} >
//                                                 <div className="p-2 col-11" >
//                                                     <Row className="align-items-center">
//                                                         <Col className="col-12">
//                                                           {
//                                                             //(f,'f')
//                                                           }
//                                                           {
//                                                             f.mimetype.startsWith("video/") ?
//                                                             <video
//                                                     style={{ width: "500px" }}
//                                                    src={this.props.imagePreviewUrl + f.originalname}
//                                                     // src={"https://d3pnv0bkd16srd.cloudfront.net/eaudit-file/" + f.originalname}
//                                                     controls
//                                                   />
//                                                             :
//                                                             <img
//                                                             data-dz-thumbnail=""
//                                                             width="100%"
//                                                             className=" bg-light"
//                                                             alt={f.uploadingStatus === "Uploaded" ? f.path : f.name}
//                                                           src={this.props.imagePreviewUrl + f.originalname }
//                                                             // src={"https://d3pnv0bkd16srd.cloudfront.net/eaudit-file/" + f.originalname}

//                                                         />
//                                                           }

//                                                         </Col>
                                                       
//                                                         {/* <Col>
//                                                             <Link
//                                                                 to="#"
//                                                                 className="text-muted font-weight-bold"
//                                                             >
//                                                                 {f.uploadingStatus === "Uploaded" ? f.path : f.name}
//                                                             </Link>
//                                                             <p className="mb-0">
//                                                                 <strong>{f.formattedSize}</strong>
//                                                             </p>
//                                                         </Col> */}

//                                                     </Row>
//                                                     <div className="mt-1">
//                                                         <span className={f.uploading ? "font-size-10 text-danger" : "font-size-10 text-success"}>{f.uploadingStatus}</span>
//                                                     </div>

//                                                 </div>
//                                                 {
//                                                   this.props.audit_status !== "3" && this.props.audit_status !== "4"?
//                                                   <div className="col-1 text-end me-2">
//                                                     <Link to="#" onClick={() => this.deleteImage(f)}><i className="mdi mdi-close-circle-outline font-size-20 text-danger" /></Link>
//                                                 </div>:null
//                                                 }
                                                
//                                             </div>
//                                         </Card>
//                                     </Col>
//                                 )
//                             })}
//                         </Row>

//                     </Col>
//                 </Row>
//             </div>
//         )

//     }

// }










// import React, { Component } from 'react'
// import { Link } from "react-router-dom";
// import {
//     Button,
//     Col,
//     Form,
//     Media,
//     Row,
//     UncontrolledDropdown,
//     Dropdown,
//     DropdownItem,
//     DropdownMenu,
//     DropdownToggle,
//     Card, CardBody,
//     CardText,
//   CardTitle,
// } from "reactstrap"

// import Dropzone from "react-dropzone"
// import MediaPreview from "./media_preview";

// import WebcamCapture from './WebCam_Comp';

// const _ = require('lodash')

// // var urlSocket = require("../../../helpers/urlSocket")
// import urlSocket from '../../../../helpers/urlSocket';


// export default class CRUD_Images extends Component {

//     constructor(props){
//         super(props)
//         this.state={
//             attachImages: [],
//             fileStatus:"clear",
//             warning_enabled:false,
//         }
//         this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
//     }


//     formatBytes = (bytes, decimals = 2) => {
//         if (bytes === 0) return "0 Bytes"
//         const k = 1024
//         const dm = decimals < 0 ? 0 : decimals
//         const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
//         const i = Math.floor(Math.log(bytes) / Math.log(k))
//         return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
//       }
    
//       handleAcceptedFiles = files => {
//         //(files,'files')
//         this.setState({ imageUploading: true, fileStatus: "clear", warning_enabled: false })
//         files.map(file => {
//           if (file.size > 5120000) {
//             this.setState({
//               fileStatus: "exceed",
//             })
//           }
//           else {
//             Object.assign(file, {
//                 "preview": URL.createObjectURL(file),
//                 "formattedSize": this.formatBytes(file.size),
//                 "uploading": false,
//                 "filetype": file.type,
//                 "uploadingStatus": 'Not uploaded',
//                 "originalName": file.name,
//                 // "captured_on": new Date(),
//                 // "path": file.name,
//                 // "name": file.name,
//                 // "type": file.type,
//                 // "uri": URL.createObjectURL(file),
//             })
//           }
//         }
//         )

        
        
//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });

//         if (this.state.fileStatus !== "exceed" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length + files.length <= this.props.selected_option.no_of_img) {

         
//           //this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images = {...this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, ...files}

//           //this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.cp_attach_images.concat(files)
    
//           this.setState({
//             attachImages: this.state.attachImages.concat(files)
//           },async ()=>{

//             let formData = new FormData();
            
//             for (const key of Object.keys(this.state.attachImages)) {
//               //(this.state.attachImages,'this.state.attachImages[key]')
//               this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(this.state.attachImages[key])
//               formData.append('imagesArray', this.state.attachImages[key])
//             }
      
//             try {
//             await  urlSocket.post("storeImage/awswebupload", formData,{
//                 headers: {
//                   'Content-Type': 'multipart/form-data', // Set the Content-Type header
//                 },
//               }, {
//                 onUploadProgress: progressEvent => {
//                   if (progressEvent.loaded === progressEvent.total) {
//                     //this.progress.current++
//                   }
//                 }
//               }).then(response => {
//                 //(response,'response')
//                 if (response.data.response_code == 500) {
//                   this.setState({attachImages:[]})

//                   var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//                     is_selected: true,
//                   });
  
                  
//                   _.each(response.data.data, item => {
//                     _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, child => {
//                       if (child.name == item.originalname) {
//                         let splitString = item.key.split("/");
//                         let getFileName = splitString[splitString.length - 1];
//                         child["uploading"] = false
//                         child["uploadingStatus"] = "Uploaded"
//                         child["preview"] = getFileName
//                         // child["lat"] = String(this.props.latitude)
//                         // child["long"] = String(this.props.longitude)
//                         child["source"] = "library"

//                       }
//                     })
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                   this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
//                   this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
//                 }
//                 else {
//                   _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
//                     child["uploading"] = false
//                     child["uploadingStatus"] = "Not Uploaded"
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                 }
      
//               })
//             } catch (error) {
//               //("error", error)
//             }
//           })
    
          
//         }
//         else {
//           if (this.state.fileStatus === "exceed") {
//             this.setState({
//               warning_enabled: true,
//               warning_message: "One of selected file size is exceed more than 5mb"
//             })
//           }
//           else {
//             this.setState({
//               warning_enabled: true,
//               warning_message: "Maximum Number of files is 5"
//             })
//           }
//         }
    
//       }

//       // access_location =async ()=>{
//       //   navigator.geolocation.getCurrentPosition(
//       //     position =>{
//       //         var location_data ={latitude :position.coords.latitude ,longitude : position.coords.longitude  }
//       //         return location_data
//       //         // this.props.history.push("/enpcpts");
//       //       // });
//       //     },
//       //     error =>{
//       //       //(error,'error')
//       //       return null

//       //     }
//       //   )



//       // }

//       access_location = () => {
//         return new Promise((resolve, reject) => {
//           navigator.geolocation.getCurrentPosition(
//             position => {
//               var location_data = {
//                 latitude: position.coords.latitude,
//                 longitude: position.coords.longitude
//               };
//               resolve(location_data);
//             },
//             error => {
//               //(error, 'error');
//               reject(null);
//             }
//           );
//         });
//       };
      







//       uploadWebCamImage = async(file) => {
//         //(file,'file')
//         Object.assign(file, {
//           "preview": file.name, //URL.createObjectURL(file),
//           "formattedSize": this.formatBytes(file.size),
//           "uploading": false,
//           "filetype": file.type,
//           "uploadingStatus": 'Not uploaded',
//           "originalName": file.name,
//            "captured_on": new Date(),
//             "path": file.name,
//           //  "name": image_info.name,
//           // "type": file.type,
//             //"uri": URL.createObjectURL(image_info),
//       })
//       //window.location = URL.createObjectURL(file);

//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });

//         let formData = new FormData();
    
//             this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(file)
//             formData.append('imagesArray', file)
      
//             try {
//               urlSocket.post("storeImage/awswebupload", formData,{
//                 headers: {
//                   'Content-Type': 'multipart/form-data', // Set the Content-Type header
//                 },
//               }, {
//                 onUploadProgress: progressEvent => {
//                   if (progressEvent.loaded === progressEvent.total) {
//                     //this.progress.current++
//                   }
//                 }
//               }).then(response => {
//                 if (response.data.response_code == 500) {
//                   this.setState({attachImages:[]})

//                   var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//                     is_selected: true,
//                   });
  
//                   this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data) 
//                 //(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images,'this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images')
//                   _.each(response.data.data, item => {
//                     _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, async child => {
//                      //(child.name ,item.originalname)
//                       if (child.originalname == item.originalname) {
//                         var track_loc = await this.access_location()

//                         let splitString = item.key.split("/");
//                         let getFileName = splitString[splitString.length - 1];
//                         child["uploading"] = false
//                         child["uploadingStatus"] = "Uploaded"
//                         child["preview"] = getFileName
//                         child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
//                         child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
//                         child["source"] = "camera"
//                         await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint);
//                       }
//                     })
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                   this.props.selectedCheckpoint.cp_attach_images= this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
//                   // this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
//                 }
//                 else {
//                   _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
//                     child["uploading"] = false
//                     child["uploadingStatus"] = "Not Uploaded"
//                   })
//                   this.setState({ imageUploading: false, refresh: true })
//                 }
//               })
//               .catch(error => {
//                 //("error", error)
//               })
//             }
//             catch (error) {
//               //("catch error", error)
//             }
//       }

//       updateCheckpointImages(data, item) {
//         //(data, item,'data, item')
//         item["cp_attach_images"] = data
//         this.props.saveCheckpoint(item)
//       }
    
//       deleteImage = item => {

//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });

//         var getIndex = _.findIndex(
//           this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images,
//           { originalName: item.originalName }
//         );

//         this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.splice(getIndex, 1);
//         this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
//         this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
//         this.setState({ fileStatus: "clear", warning_enabled: false, refresh: true  })

//       }

      




//     render() {

//         var selected_option = this.props.selected_option

//         var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
//           is_selected: true,
//         });


//         return (
//             <div >
//               {
//                 this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length !==  this.props.selectedCheckpoint.cp_noof_images?
//                 <div>
//                   <label>Add Images</label>
//                   {/* {
//                     this.props.selected_option.enable_cam ==  true && */}
//                   <Row className="my-3">
//                     <WebcamCapture uploadWebCamImage = {(data)=>{this.uploadWebCamImage(data)}} selectedCheckpoint={this.props.selectedCheckpoint} updateCheckpointVideos= {this.props.saveCheckpoint}/>
//                   </Row>
//                   {/* } */}

//                   {/* {
//                     this.props.selected_option.enable_gallery ==  true && */}
//                 <Row className="my-3">
//                     <Col sm={"12"} lg={"12"}>
//                         <div style={{ zoom: 0.5 }}>
//                             <Form>
//                                 <Dropzone
//                                     onDrop={acceptedFiles =>
//                                         this.handleAcceptedFiles(acceptedFiles)
//                                     }
//                                     accept={[".jpg", ".jpeg", ".png", ".bmp"]}
//                                 >
//                                     {({ getRootProps, getInputProps }) => (
//                                         <div className="dropzone">
//                                             <div
//                                                 className="dz-message needsclick"
//                                                 {...getRootProps()}
//                                             >
//                                                 <input {...getInputProps()} />
//                                                 <div className="mb-3">
//                                                     <i className="display-4 text-muted bx bxs-cloud-upload" />
//                                                 </div>
//                                                 <h4>Drop Images here or click to upload.</h4>

//                                             </div>
//                                         </div>
//                                     )}
//                                 </Dropzone>

//                             </Form>
//                         </div>
//                         <div className="mt-2 font-size-11 text-dark text-center">
//                             <span >Files accepted - .jpg, .jpeg, .png, .bmp, </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                             <span >Maximum individual file size - 5mb</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                             <span >Minimum Number of files - {selected_option.no_of_img}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                             <span >Maximum upto 10 files</span>
//                         </div>
//                     </Col>
//                 </Row>
//                 {/* } */}
//                 </div>: this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.cp_noof_images !== 0 ?
//                 <div style={{padding:10}}><label style={{fontSize:12, color:"#ff6666"}}>You are reached the Maximum requirment. If you want to retake or upload image, delete one of the image uploaded and capture/upload the picture </label></div>:null
//               }
                
//                 {
//                     this.state.warning_enabled ? <Row>
//                         <div className="my-2 font-size-12 text-danger">{this.state.warning_message}</div>
//                     </Row> : null
//                 }{
//                   //(this.props.selectedCheckpoint,'this.props.selectedCheckpoint')
//                 }
//                 <Row>
//                     <Col sm={"12"} lg={"12"}>
//                         <Row
//                             className="dropzone-previews"
//                             id="file-previews"

//                         >
//                             {this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.length !== 0 && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.map((f, i) => {
//                                 return (
//                                     <Col lg={"6"} key={String(i) + "-file"}>
//                                         <Card
//                                             className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            
//                                         >
//                                             <div style={{ display: 'flex', flexDirection: 'row' }} >
//                                                 <div className="p-2 col-11" >
//                                                     <Row className="align-items-center">
//                                                         <Col className="col-12">
//                                                           {
//                                                             f.mimetype ==="video/webm" ?
//                                                             <video
//                                                             style={{ width: "500px" }}
//                                                             src={"https://d3pnv0bkd16srd.cloudfront.net/eaudit-file/" + f.originalname}
//                                                             controls
//                                                             />
//                                                             :
//                                                             <img
//                                                                 data-dz-thumbnail=""
//                                                                 width="100%"
//                                                                 className=" bg-light"
//                                                                 alt={f.uploadingStatus === "Uploaded" ? f.path : f.name}
//                                                                 src={"https://d3pnv0bkd16srd.cloudfront.net/eaudit-file/" + f.originalname}
//                                                             /> 

//                                                           }
//                                                             {/* <img
//                                                                 data-dz-thumbnail=""
//                                                                 width="100%"
//                                                                 className=" bg-light"
//                                                                 alt={f.uploadingStatus === "Uploaded" ? f.path : f.name}
//                                                                 src={this.props.imagePreviewUrl + f.originalName}
//                                                             /> */}
//                                                         </Col>
//                                                     </Row>
//                                                     <div className="mt-1">
//                                                         <span className={f.uploading ? "font-size-10 text-danger" : "font-size-10 text-success"}>{f.uploadingStatus}</span>
//                                                     </div>

//                                                 </div>
//                                                 {
//                                                   this.props.audit_status !== "3" && this.props.audit_status !== "4"?
//                                                   <div className="col-1 text-end me-2">
//                                                     <Link to="#" onClick={() => this.deleteImage(f)}><i className="mdi mdi-close-circle-outline font-size-20 text-danger" /></Link>
//                                                 </div>:null
//                                                 }
                                                
//                                             </div>
//                                         </Card>
//                                     </Col>
//                                 )
//                             })}
                             
//                         </Row>

//                     </Col>
//                 </Row>
//             </div>
//         )

//     }

// }