import React from 'react';
import styled from 'styled-components';
import PropTypes, { element } from 'prop-types';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import urlSocket from '../../../helpers/urlSocket';
import {Link} from 'react-router-dom'
// #region constants
import _ from 'lodash'
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import BootstrapTable from "react-bootstrap-table-next"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import TableContainer from '../../../common/TableContainer';
import Swal from "sweetalert2";
import { Popconfirm } from 'antd'
import SortableTree, {
    addNodeUnderParent,
    removeNodeAtPath,
    toggleExpandedForAll,
    defaultGetNodeKey,
    getTreeFromFlatData,
    getNodeAtPath,
    changeNodeAtPath,
    getFlatDataFromTree,
  } from 'react-sortable-tree/dist/index.cjs.js';

const propTypes = {};

const defaultProps = {};

/**
 * 
 */
// console.log(props)
class CrudCategory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hirerachy_user_level: [],
            temp_hirerachy_user_level: [],
            configureData: [],
            created_hirerachy_user_level: [],
            dataloaded: false,
            edit_category:{},
            cat_name_exist : false,
            edit:false
        };
        this.submitData = this.submitData.bind(this)
    }


    componentDidMount() {
        console.log("Category",this.props.treeData)
        var data = JSON.parse(sessionStorage.getItem('authUser'))
        var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0]
        var db_info = JSON.parse(sessionStorage.getItem('db_info'))
        this.setState({
            user_data: data.user_data,
            client_info: client_info[0],
            db_info: db_info

        }, () => { this.retriveExistCategory() })
    }

    retriveExistCategory = () => {
        console.log(this.props.treeData,this.props.nodeInfo)
        try {
            urlSocket.post('cog/incd-retrive-categories', {
                encrypted_db_url: this.state.db_info.encrypted_db_url,
                hierarchy_id : this.props.nodeInfo.node.h_id
            }).then((response) => {
                console.log(response, 'response')
                if (response.data.response_code == 500) {
                    var hirerachy_user_level = response.data.data[0].hirerachy_user_level
                    // var created_hirerachy_user_level = []
                    // _.filter(hirerachy_user_level, e => {
                    //     if (e.cat_type !== "3" && e.cat_type !== "2" && e.cat_type !== "1") {
                    //         // console.log("object")
                    //         created_hirerachy_user_level.push(e)

                    //     }
                    //     else {
                    //         // console.log("LLL")
                    //     }
                    // })
                    this.setState({ dataloaded: false, created_hirerachy_user_level: hirerachy_user_level, temp_hirerachy_user_level: hirerachy_user_level, configure_id: response.data.data[0]._id, configureData: response.data.data[0] }, () => { this.setState({ dataloaded: true }) })
                }
            })

        } catch (error) {

        }
    }

    // updateRecursively = (treeData, count, value) => {
    //     console.log(treeData, count, value)
    //     value["cat_type"] = this.state.edit_category.cat_type
    //     // treeData[count]


    //     const uniqueUserIndex = treeData[count]["user_path"].findIndex(user => user.cat_type === this.state.edit_category.cat_type);
    //     console.log(uniqueUserIndex, 'uniqueUserIndex')
    //     if (uniqueUserIndex !== -1) {
    //         treeData[count]["user_path"][uniqueUserIndex].hirerachy_name = value.hirerachy_name;
    //         console.log(treeData[count]["user_path"][uniqueUserIndex])
    //         var find_unique_user_index = treeData[count]["user_path"].findIndex(user => user._id == treeData[count]["user_path"][uniqueUserIndex]["_id"])
    //         var cat_type_idx = treeData[count]["unique_users"][find_unique_user_index]["cat_type"].indexOf(this.state.edit_category.cat_type)
    //         treeData[count]["unique_users"][find_unique_user_index]["hirerachy_name"][cat_type_idx] = value.hirerachy_name
    //         if (treeData[count].children.length !== 0) {
    //             // console.log("check nex")
    //             // count ++;
    //             // this.updateRecursively(treeData[count].children,)
    //         }
    //         console.log(find_unique_user_index, treeData[count]["unique_users"][find_unique_user_index], cat_type_idx)

    //     }
    // }
    getTreeFromFlatDataAsync = (FlatData) => {
        return new Promise((resolve, reject) => {
          try {
            const treeData = getTreeFromFlatData({
              flatData: FlatData,
              getKey: (node) => node.id,
              getParentKey: (node) => node.parent,
              rootKey: null,
              expanded: true
            });
      
            resolve(treeData);
          } catch (error) {
            reject(error);
          }
        });
      }

      treeDataToFlat = (treeData) => {
        // //("this.state.treeData", this.state.treeData)
        var getNodeKey = ({ treeIndex }) => treeIndex
        var flatData = getFlatDataFromTree(
          {
            treeData: treeData,
            getNodeKey,
            ignoreCollapsed: false,
          })
    
        var explicitData = _.filter(flatData, item => {
          return item
        })
    
        return explicitData
      }


    convertFlatDataToTreeData= async(FlatData)=>{
        console.log(FlatData);
        try {
            const treeData = await this.getTreeFromFlatDataAsync(FlatData);
            console.log(treeData,'treeData')
            // var explicitData = this.treeDataToFlat(treeData)
            this.saveData(treeData)
        } catch (error) {
            
        }

    }


    saveData = (flatData) => {
        // this.setState({ saving: true })
        // var mapped = _.map(flatData, "node")
        // console.log(mapped, 'mapped')
        //("test",flatData)
        console.log(flatData,'flatData')
        try {
            urlSocket.post("webhstre/incd-savehstructure", {
                info: {
                    _id: this.state.configureData._id,
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    company_id: this.state.configureData.company_id,
                    flatData: flatData,
                    lastGeneratedId: this.state.configureData.lastGeneratedId,
                }
                })
                .then((response) => {
                    console.log(response, this.props.nodeInfo.flatData,'response',this.props.nodeInfo)
                    if(response.data.response_code == 500){
                        var updated_node_data = _.filter(this.props.nodeInfo.flatData,{title :this.props.nodeInfo.node.title})
                        console.log(updated_node_data,'updated_node_data')
                        this.props.nodeInfo.node =updated_node_data[0]
                        sessionStorage.setItem("nodeInfo", JSON.stringify(this.props.nodeInfo));
                        // sessionStorage.setItem()
                        this.props.updateAddUsers(this.props.nodeInfo.node.user_path)
                        this.props.onClose()
                    }
                })
        }
        catch (error) {

        }

    }


    updateRecursively = (flat_data, count, value) => {
        console.log(this.state.nodeInfo,value,'value',flat_data,this.state.edit_category.cat_type,this.state.selectedUsers)
        flat_data?.map((data, idx) => {
            console.log(data,'data')
            data.user_path.map((ele, idx1) => {
                if (ele.cat_type === this.state.edit_category.cat_type) {
                    ele["hirerachy_name"] = value.hirerachy_name
                }
            })
            if (data.unique_users !== undefined) {
                data.unique_users.map((data1, index2) => {
                    if (data1.cat_type.includes(this.state.edit_category.cat_type)) {
                        const indexOfValue = data1.cat_type.indexOf(this.state.edit_category.cat_type);
                        console.log(indexOfValue,'indexOfValue')
                        data1["hirerachy_name"][indexOfValue] = value.hirerachy_name
                        console.log("tru", indexOfValue)
                    }
                })
            }
        })
        console.log(flat_data, 'flat_data')
        // this.updateFlatData(flat_data)
         this.convertFlatDataToTreeData(flat_data)

    }

    


    submitData(event, value) {
        console.log(value, 'value',this.props, this.props.treeData, this.state.edit, this.state.temp_hirerachy_user_level, this.state.configureData.hirerachy_user_level)
        var configureData = {...this.state.configureData}
        if (this.state.cat_name_exist == false) {
            if (this.state.edit !== true) {
                const maxCatType = Math.max(...this.state.temp_hirerachy_user_level.map((item) => parseInt(item.cat_type, 10)));
                console.log(maxCatType, 'maxCatType')
                value['cat_type'] = String(maxCatType + 1)
                this.state.configureData.hirerachy_user_level.push(value)
                console.log(this.state.configureData)

            }

            else {
                var findHierarchyIdx = _.findIndex(this.state.configureData.hirerachy_user_level, { "cat_type": this.state.edit_category.cat_type })
                configureData.hirerachy_user_level[findHierarchyIdx].hirerachy_name = value.hirerachy_name
                console.log(this.state.configureData, this.state.edit_category, findHierarchyIdx,this.props.nodeInfo)
                this.updateRecursively(this.props.nodeInfo.flatData, 0, value)
            }
            // // this.updateRecursively(this.props.nodeInfo.flat_data,0,value)

            try {
                urlSocket.post('cog/incd-crud-category', {
                    confiGuration: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        hirerachyUserlvl: configureData,
                        _id: this.state.configure_id
                    }
                }).then((response) => {
                    console.log(response, 'response')
                    if (response.data.response_code == 500) {
                        this.setState({ edit: false, edit_category: {} }, () => {
                            this.retriveExistCategory()
                        })

                    }
                })
            } catch (error) {

            }
        }
    }







    // submitData(event, value) {
    //     console.log(value, 'value', this.props.treeData, this.state.edit, this.state.temp_hirerachy_user_level, this.state.configureData.hirerachy_user_level);
    //     if (this.state.cat_name_exist === false) {
    //       if (this.state.edit !== true) {
    //         const maxCatType = Math.max(...this.state.temp_hirerachy_user_level.map((item) => parseInt(item.cat_type, 10)))
    //         console.log(maxCatType, 'maxCatType');
    //         value['cat_type'] = String(maxCatType + 1);
    //         const updatedHirerachyUserLevel = [...this.state.configureData.hirerachy_user_level];
    //         updatedHirerachyUserLevel.push(value);
    //         this.setState({ configureData: { ...this.state.configureData, hirerachy_user_level: updatedHirerachyUserLevel } }, () => {
    //           console.log(this.state.configureData);
    //         });
    //       } else {
    //         const findHierarchyIdx = _.findIndex(this.state.configureData.hirerachy_user_level, { cat_type: this.state.edit_category.cat_type });
    //         const updatedHirerachyUserLevel = [...this.state.configureData.hirerachy_user_level];
    //         updatedHirerachyUserLevel[findHierarchyIdx].hirerachy_name = value.hirerachy_name;
    //         console.log(this.state.configureData, this.state.edit_category, findHierarchyIdx, this.props.nodeInfo);
    //         this.setState({ configureData: { ...this.state.configureData, hirerachy_user_level: updatedHirerachyUserLevel } }, () => {
    //           this.updateRecursively(this.props.nodeInfo.flat_data, 0, value);
    //         });
    //       }
      
    //       try {
    //         urlSocket.post('cog/crud-category', {
    //           confiGuration: {
    //             encrypted_db_url: this.state.db_info.encrypted_db_url,
    //             hirerachyUserlvl: this.state.configureData,
    //             _id: this.state.configure_id,
    //           },
    //         }).then((response) => {
    //           console.log(response, 'response');
    //           if (response.data.response_code === 500) {
    //             this.setState({ edit: false, edit_category: {} }, () => {
    //               this.retriveExistCategory();
    //             });
    //           }
    //         });
    //       } catch (error) {}
    //     }
    //   }
      


    validateCatName=(event)=>{
        console.log(event.target.value,this.state.created_hirerachy_user_level)
        if (this.state.edit == false) {
            console.log("validate starts")

            var validate_category_name = _.filter(this.state.created_hirerachy_user_level, item => {
                const cleanedHierarchyName = item.hirerachy_name.replace(/\s/g, '').toLowerCase();
                const cleanedEventValue = event.target.value.replace(/\s/g, '').toLowerCase();
                return cleanedHierarchyName === cleanedEventValue;
            });
            if (validate_category_name.length > 0) {
                this.setState({ cat_name_exist: true })
            }
            else {
                this.setState({ cat_name_exist: false })

            }
        }
         if(this.state.edit == true){
            // console.log("validate starts")
            // var edit_validate_cat_name = _.filter(this.state.created_hirerachy_user_level,{hirerachy_name : this.state.edit_category.hirerachy_name})
            var edit_validate_cat_name = this.state.created_hirerachy_user_level.filter((item,posi)=>{
                if(item.hirerachy_name !== this.state.edit_category.hirerachy_name){
                    return item
                }
            })
            var validate_category_name = _.filter(edit_validate_cat_name, item => {
                const cleanedHierarchyName = item.hirerachy_name.replace(/\s/g, '').toLowerCase();
                const cleanedEventValue = event.target.value.replace(/\s/g, '').toLowerCase();
                return cleanedHierarchyName === cleanedEventValue;
            });
            if (validate_category_name.length > 0) {
                this.setState({ cat_name_exist: true })
            }
            else {
                this.setState({ cat_name_exist: false })

            }


            // console.log(validate_category_name,'edit_validate_cat_name')
        }
    }

    updateCategory = (event, data, mode) => {
        console.log(data, 'data',this.props.nodeInfo);
        if (mode === "1") {
          this.setState({ edit_category: data, edit: true },()=>{
            console.log(this.state.edit_category,'edit_category')
          });
        } else {
          var findHierarchyIdx = _.findIndex(this.state.configureData.hirerachy_user_level, { cat_type: data.cat_type });
          var updatedUniqueUserData = this.state.configureData.hirerachy_user_level.filter((ele, index) => index !== findHierarchyIdx);
      
          const updatedFlatData = this.props.nodeInfo.flatData.map((data1, idx1) => {
            var updatedUserPath = _.filter(data1.user_path, (obj) => obj.cat_type !== data.cat_type);
            console.log(updatedUserPath, 'updated_userpath');
            this.props.nodeInfo.flatData[idx1]["user_path"] = updatedUserPath;
      
            if (data1.unique_users !== undefined) {
              data1.unique_users.forEach(user => {
                user.cat_type = user.cat_type.filter(cat => cat !== data.cat_type);
                user.hirerachy_name = user.hirerachy_name.filter(cat => cat !== data.hirerachy_name);
              });
              data1.unique_users = data1.unique_users.filter(user => user.cat_type.length > 0);
            }
      
            return data1;
          });
          console.log(updatedFlatData,'updatedFlatData')
          this.convertFlatDataToTreeData(updatedFlatData);
      
          try {
            urlSocket.post('cog/incd-crud-category', {
              confiGuration: {
                encrypted_db_url: this.state.db_info.encrypted_db_url,
                hirerachyUserlvl: { ...this.state.configureData, hirerachy_user_level: updatedUniqueUserData },
                _id: this.state.configure_id,
              },
            }).then((response) => {
              console.log(response, 'response');
              if (response.data.response_code === 500) {
                this.setState({ edit: false, edit_category: {} }, () => {
                  this.retriveExistCategory();
                });
              }
            });
          } catch (error) {}
        }
      }
      











    // updateCategory = (event, data, mode) => {
    //     console.log(data, 'data')
    //     if (mode == "1") {
    //         this.setState({ edit_category: data, edit: true })
    //     }
    //     else {
    //         var findHierarchyIdx = _.findIndex(this.state.configureData.hirerachy_user_level, { "cat_type": data.cat_type })
    //         var updated_unique_userdata = this.state.configureData.hirerachy_user_level.filter((ele, index) => index !== findHierarchyIdx);
    //         this.state.configureData["hirerachy_user_level"] = updated_unique_userdata
    //         console.log(this.state.configureData,'this.state.configureData',this.props.nodeInfo,this.props.nodeInfo.flat_data)
            
    //         this.props.nodeInfo.flat_data.map((data1, idx1) => {
    //             var updated_userpath = _.filter(data1.user_path, (obj) => obj.cat_type !== data.cat_type);
    //             console.log(updated_userpath,'updated_userpath')
    //             this.props.nodeInfo.flat_data[idx1]["user_path"] = updated_userpath
    //             if(data1.unique_users !== undefined){
    //             data1.unique_users.forEach(user => {
    //                 user.cat_type = user.cat_type.filter(cat => cat !== data.cat_type);
    //                 user.hirerachy_name = user.hirerachy_name.filter(cat => cat !== data.hirerachy_name);

    //             });
    //             data1.unique_users = data1.unique_users.filter(user => user.cat_type.length > 0);
    //         }
    //         })

    //         this.convertFlatDataToTreeData(this.props.nodeInfo.flat_data)

    //                 try {
    //                     urlSocket.post('cog/crud-category', {
    //                         confiGuration: {
    //                             encrypted_db_url: this.state.db_info.encrypted_db_url,
    //                             hirerachyUserlvl: this.state.configureData,
    //                             _id: this.state.configure_id
    //                         }
    //                     }).then((response) => {
    //                         console.log(response, 'response')
    //                         if (response.data.response_code == 500) {
    //                             this.setState({ edit: false, edit_category: {} }, () => {
    //                                 this.retriveExistCategory()
    //                             })

    //                         }
    //                     })
    //                 } catch (error) {

    //                 }
    //     }
    // }

    render() {

        if (this.state.dataloaded == true) {
            // const { SearchBar } = Search;
            const options = {
                sizePerPage: 10,
                totalSize: this.state.created_hirerachy_user_level.length, // replace later with size(users),
                custom: true,
            };
            const columns = [
                {
                    accessor: "Category Name",
                    Header: "hirerachy_name",
                    // sort: true,
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return(
                            <>
                            <div className="d-flex " style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className=" font-size-14 ">
                                {item.hirerachy_name}
                            </div>
                        </div>  
                        </>  
                        )

                    }
                },
                {
                    accessor: "menu",
                    // isDummyField: true,
                    // editable: false,
                    Header: "Action",
                    Cell: (cellProps) => {
                        // console.log(item)
                        var item = cellProps.row.original
                        return(
                        item.cat_type == "1" || item.cat_type =="2"?
                         
                        <>
                        
                        
                        </>
                        :
                        <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                              <Link className="text-primary" to="#"><i className="mdi mdi-pencil font-size-18" onClick={(e)=>{this.updateCategory(e,item,"1")}} />{" "}</Link>
                            <Popconfirm
                                title="Warning"
                                description="Are you sure you want to change this Action?"
                                // onConfirm={(e) => { this.updateCategory(e, item, "2") }}
                            >

                                <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" onClick={(e) => { this.updateCategory(e, item, "2") }} />{" "}</Link>

                            </Popconfirm>

                              
                              
                              
                              
                        </div>
                    )},
                },
            ]



            return (
                <React.Fragment>
                    <Container fluid>
                        <Card className="overflow-hidden">
                            <CardBody className="pt-0">
                                <Row >
                                    <Col >
                                        <div className="p-2 mb-3">
                                            <AvForm className="form-horizontal" onValidSubmit={this.submitData}>
                                                <Row className="my-4">
                                                    <div className="d-sm-flex align-items-center justify-content-between">
                                                        <div className="text-danger font-size-18">Category Information</div>
                                                        <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
                                                    </div>
                                                    <hr className="my-4" />
                                                </Row>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="hirerachy_name"
                                                        label="Category"
                                                        type="text"
                                                        value={this.state.edit_category.hirerachy_name}
                                                        onChange={(e)=>{this.validateCatName(e)}}
                                                        required
                                                        placeholder="Enter Category Name"
                                                    />
                                                    {
                                                        this.state.cat_name_exist &&
                                                        <div className='text-danger' style={{ fontSize: 'smaller' }}>Category Name already Exist.</div>
                                                    }
                                                </div>
                                                <button
                                                    className={this.state.edit ? "btn btn-primary btn-block m-1" : "btn btn-success btn-block m-1"}
                                                    type="submit"
                                                >
                                                    {this.state.edit ? "Update" : "Create"}
                                                </button>

                                            </AvForm>
                                        </div>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col lg="12">
                                    <div className="p-2">
                                            <TableContainer
                                                columns={columns}
                                                data={this.state.created_hirerachy_user_level}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={false}
                                                customPageSize={10}
                                                style={{ width: '100%' }}
                                                // dynamicBtn={true}
                                                // btnClick={(param1, param2) => this.navigateTo("", 1)}
                                                // iscustomPageSizeOptions={true}
                                                // dynamicParams={params1,params2}
                                                isPagination={true}
                                                filterable={false}
                                                btnName={"Add User"}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-end mb-2"

                                            />


                                        {/* <PaginationProvider
                                            keyField="id"
                                            data={this.state.created_hirerachy_user_level}
                                            columns={columns}
                                            pagination={paginationFactory(options)}
                                        >
                                            {
                                                ({
                                                    paginationProps,
                                                    paginationTableProps
                                                }) => (
                                                    <ToolkitProvider
                                                        keyField="_id"
                                                        data={this.state.created_hirerachy_user_level}
                                                        columns={columns}
                                                        search
                                                    >
                                                        {
                                                            toolkitprops => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2" style={{ paddingTop: 0 }}>
                                                                        <Col sm="4">
                                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                                <div className="position-relative">
                                                                                    <SearchBar {...toolkitprops.searchProps} />
                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                <BootstrapTable
                                                                                    {...toolkitprops.baseProps}
                                                                                    {...paginationTableProps}
                                                                                    // defaultSorted={defaultSorted}
                                                                                    // selectRow={selectRow}

                                                                                    classes={
                                                                                        "table align-middle table-nowrap table-hover"
                                                                                    }
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    responsive
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                    </ToolkitProvider>
                                                )}
                                        </PaginationProvider> */}
                                        </div>
                                        {/* </CardBody>
                                    </Card> */}
                                    </Col>
                                </Row>
                            </CardBody>


                        </Card>
                    </Container>
                </React.Fragment>
            )
                ;
        }
        else {
            return null
        }
    }
}

CrudCategory.propTypes = propTypes;
CrudCategory.defaultProps = defaultProps;
// #endregion

export default CrudCategory;
























// import React from 'react';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';
// import {
//     Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
//     Input,
// } from "reactstrap";
// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// import urlSocket from '../../helpers/urlSocket';
// import {Link} from 'react-router-dom'
// // #region constants
// import _ from 'lodash'
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import BootstrapTable from "react-bootstrap-table-next"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import Swal from "sweetalert2";
// import { Popconfirm } from 'antd'
// const propTypes = {};

// const defaultProps = {};

// /**
//  * 
//  */
// class CrudCategory extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             hirerachy_user_level: [],
//             temp_hirerachy_user_level: [],
//             configureData: [],
//             created_hirerachy_user_level: [],
//             dataloaded: false,
//             edit_category:{}
//         };
//         this.submitData = this.submitData.bind(this)
//     }

//     componentDidMount() {
//         console.log("Category",this.props.treeData)
//         var data = JSON.parse(sessionStorage.getItem('authUser'))
//         var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0]
//         var db_info = JSON.parse(sessionStorage.getItem('db_info'))
//         this.setState({
//             user_data: data.user_data,
//             client_info: client_info[0],
//             db_info: db_info

//         }, () => { this.retriveExistCategory() })
//     }

//     retriveExistCategory = () => {
//         console.log(this.props.treeData)
//         try {
//             urlSocket.post('cog/retrive-categories', {
//                 encrypted_db_url: this.state.db_info.encrypted_db_url,
//             }).then((response) => {
//                 console.log(response, 'response')
//                 if (response.data.response_code == 500) {
//                     var hirerachy_user_level = response.data.data[0].hirerachy_user_level
//                     // var created_hirerachy_user_level = []
//                     // _.filter(hirerachy_user_level, e => {
//                     //     if (e.cat_type !== "3" && e.cat_type !== "2" && e.cat_type !== "1") {
//                     //         // console.log("object")
//                     //         created_hirerachy_user_level.push(e)

//                     //     }
//                     //     else {
//                     //         // console.log("LLL")
//                     //     }
//                     // })
//                     this.setState({ dataloaded: false, created_hirerachy_user_level: response.data.data[0].hirerachy_user_level, temp_hirerachy_user_level: hirerachy_user_level, configure_id: response.data.data[0]._id, configureData: response.data.data[0] }, () => { this.setState({ dataloaded: true }) })
//                 }
//             })

//         } catch (error) {

//         }
//     }



//     submitData(event, value) {
//         console.log(value, 'value')
//         if(this.state.edit !== true){
//         value['cat_type'] = String(this.state.temp_hirerachy_user_level.length + 1)
//         this.state.configureData.hirerachy_user_level.push(value)
//         console.log(this.state.configureData)

//         }
//         else{
//             var findHierarchyIdx = _.findIndex(this.state.configureData.hirerachy_user_level, { "cat_type": this.state.edit_category.cat_type })
//             this.state.configureData.hirerachy_user_level[findHierarchyIdx].hirerachy_name = value.hirerachy_name
//             console.log(this.state.configureData,this.state.edit_category,findHierarchyIdx)

//         }
//         try {
//             urlSocket.post('cog/crud-category', {
//                 confiGuration: {
//                     encrypted_db_url: this.state.db_info.encrypted_db_url,
//                     hirerachyUserlvl: this.state.configureData,
//                     _id: this.state.configure_id
//                 }
//             }).then((response) => {
//                 console.log(response, 'response')
//                 if (response.data.response_code == 500) {
//                     this.setState({edit : false , edit_category :{}},()=>{
//                         this.retriveExistCategory()
//                     })
                    
//                 }
//             })
//         } catch (error) {

//         }
//     }


//     updateCategory = (event, data, mode) => {
//         console.log(data, 'data')
//         if (mode == "1") {
//             this.setState({ edit_category: data, edit: true })
//         }
//         else {
//             var findHierarchyIdx = _.findIndex(this.state.configureData.hirerachy_user_level, { "cat_type": data.cat_type })
//             var updated_unique_userdata = this.state.configureData.hirerachy_user_level.filter((ele, index) => index !== findHierarchyIdx);
//             this.state.configureData["hirerachy_user_level"] = updated_unique_userdata
//             // Swal.fire({
//             //     icon: 'error', 
//             //     title: 'Delete Confirmation',
//             //     text: 'Are you sure you want to delete this category?', 
//             //     showCancelButton: true,
//             //     confirmButtonColor: '#d33',
//             //     cancelButtonColor: '#3085d6',
//             //     confirmButtonText: 'Delete',
//             //     cancelButtonText: 'Cancel',
//             // }).then((result) => {
//                 // if (result.isConfirmed) {
//                     try {
//                         urlSocket.post('cog/crud-category', {
//                             confiGuration: {
//                                 encrypted_db_url: this.state.db_info.encrypted_db_url,
//                                 hirerachyUserlvl: this.state.configureData,
//                                 _id: this.state.configure_id
//                             }
//                         }).then((response) => {
//                             console.log(response, 'response')
//                             if (response.data.response_code == 500) {
//                                 this.setState({ edit: false, edit_category: {} }, () => {
//                                     this.retriveExistCategory()
//                                 })

//                             }
//                         })
//                     } catch (error) {

//                     }
//                 // }
//             // })

//         }
//     }

//     render() {

//         if (this.state.dataloaded == true) {
//             const { SearchBar } = Search;
//             const options = {
//                 sizePerPage: 10,
//                 totalSize: this.state.created_hirerachy_user_level.length, // replace later with size(users),
//                 custom: true,
//             };
//             const columns = [
//                 {
//                     text: "Category Name",
//                     dataField: "hirerachy_name",
//                     sort: true,
//                     formatter: (cellContent, item) => {
//                         return(
//                             <>
//                             <div className="d-flex " style={{ display: 'flex', flexDirection: 'column' }}>
//                             <div className=" font-size-14 ">
//                                 {item.hirerachy_name}
//                             </div>
//                         </div>  
//                         </>  
//                         )

//                     }
//                 },
//                 {
//                     dataField: "menu",
//                     isDummyField: true,
//                     editable: false,
//                     text: "Action",
//                     formatter: (cellContent, item) => (
//                         // console.log(item)
//                         item.cat_type == "1" || item.cat_type =="2"?
                         
//                         <>
                        
                        
//                         </>
//                         :
//                         <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
//                               <Link className="text-primary" to="#"><i className="mdi mdi-pencil font-size-18" onClick={(e)=>{this.updateCategory(e,item,"1")}} />{" "}</Link>
//                             <Popconfirm
//                                 title="Warning"
//                                 description="Are you sure you want to change this Action?"
//                                 // onConfirm={(e) => { this.updateCategory(e, item, "2") }}
//                             >

//                                 <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" onClick={(e) => { this.updateCategory(e, item, "2") }} />{" "}</Link>

//                             </Popconfirm>

                              
                              
                              
                              
//                         </div>
//                     ),
//                 },
//             ]



//             return (
//                 <React.Fragment>
//                     <Container fluid>
//                         <Card className="overflow-hidden">
//                             <CardBody className="pt-0">
//                                 <Row >
//                                     <Col >
//                                         <div className="p-2 mb-3">
//                                             <AvForm className="form-horizontal" onValidSubmit={this.submitData}>
//                                                 <Row className="my-4">
//                                                     <div className="d-sm-flex align-items-center justify-content-between">
//                                                         <div className="text-danger font-size-18">Category Information</div>
//                                                         <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
//                                                     </div>
//                                                     <hr className="my-4" />
//                                                 </Row>
//                                                 <div className="mb-3">
//                                                     <AvField
//                                                         name="hirerachy_name"
//                                                         label="Category"
//                                                         type="text"
//                                                         value={this.state.edit_category.hirerachy_name}
//                                                         required
//                                                         placeholder="Enter Category Name"
//                                                     />

//                                                 </div>
//                                                 <button
//                                                     className={this.state.edit ? "btn btn-primary btn-block m-1" : "btn btn-success btn-block m-1"}
//                                                     type="submit"
//                                                 >
//                                                     {this.state.edit ? "Update" : "Create"}
//                                                 </button>

//                                             </AvForm>
//                                         </div>
//                                     </Col>
//                                 </Row>
//                                 <Row >
//                                     <Col lg="12">
//                                     <div className="p-2">
//                                         <PaginationProvider
//                                             keyField="id"
//                                             data={this.state.created_hirerachy_user_level}
//                                             columns={columns}
//                                             pagination={paginationFactory(options)}
//                                         >
//                                             {
//                                                 ({
//                                                     paginationProps,
//                                                     paginationTableProps
//                                                 }) => (
//                                                     <ToolkitProvider
//                                                         keyField="_id"
//                                                         data={this.state.created_hirerachy_user_level}
//                                                         columns={columns}
//                                                         search
//                                                     >
//                                                         {
//                                                             toolkitprops => (
//                                                                 <React.Fragment>
//                                                                     <Row className="mb-2" style={{ paddingTop: 0 }}>
//                                                                         <Col sm="4">
//                                                                             <div className="search-box ms-2 mb-2 d-inline-block">
//                                                                                 <div className="position-relative">
//                                                                                     <SearchBar {...toolkitprops.searchProps} />
//                                                                                     <i className="bx bx-search-alt search-icon" />
//                                                                                 </div>
//                                                                             </div>
//                                                                         </Col>
//                                                                     </Row>
//                                                                     <Row>
//                                                                         <Col xl="12">
//                                                                             <div className="table-responsive">
//                                                                                 <BootstrapTable
//                                                                                     {...toolkitprops.baseProps}
//                                                                                     {...paginationTableProps}
//                                                                                     // defaultSorted={defaultSorted}
//                                                                                     // selectRow={selectRow}

//                                                                                     classes={
//                                                                                         "table align-middle table-nowrap table-hover"
//                                                                                     }
//                                                                                     bordered={false}
//                                                                                     striped={false}
//                                                                                     responsive
//                                                                                 />

//                                                                             </div>
//                                                                         </Col>
//                                                                     </Row>
//                                                                     <Row className="align-items-md-center mt-30">
//                                                                         <Col className="pagination pagination-rounded justify-content-end mb-2">
//                                                                             <PaginationListStandalone
//                                                                                 {...paginationProps}
//                                                                             />
//                                                                         </Col>
//                                                                     </Row>
//                                                                 </React.Fragment>
//                                                             )}
//                                                     </ToolkitProvider>
//                                                 )}
//                                         </PaginationProvider>
//                                         </div>
//                                         {/* </CardBody>
//                                     </Card> */}
//                                     </Col>
//                                 </Row>
//                             </CardBody>


//                         </Card>
//                     </Container>
//                 </React.Fragment>
//             )
//                 ;
//         }
//         else {
//             return null
//         }
//     }
// }

// CrudCategory.propTypes = propTypes;
// CrudCategory.defaultProps = defaultProps;
// // #endregion

// export default CrudCategory;