import React, { useEffect, useState } from 'react'
import { Container, Form, Card, CardBody, Row, Col, Label, Input, FormFeedback } from 'reactstrap'
import * as Yup from "yup";
import { useFormik } from "formik";
// import Breadcrumbs from '../../../components/Common/Breadcrumb'
import Breadcrumbs from './Components/BreadCrumb';
import Dropzone from "react-dropzone";
import { incidentSubmitConfiguration, getHdata, submitIncidentInfo, setIncidentsInfo } from '../../../store/incidentReport/incdreportslice';
import { useDispatch, useSelector } from 'react-redux';
import { Tree, Popconfirm } from 'antd';
import { FileOutlined, DeleteTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import urlSocket from '../../../helpers/urlSocket';
import { useHistory } from 'react-router-dom';
// import { defaultExpandAll } from "../../../../node_modules/antd/es/tree"
import WebcamCapture from './Components/WebCam'
import Swal from 'sweetalert2';

const ReportIncidet = () => {
    const history = useHistory()

    const dispatch = useDispatch()
    const incidentReport = useSelector(state => state.incdreportslice)
    const [treeNodes, setTreeNodes] = useState([])
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [mediaInfo, setmediaInfo] = useState([]);
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")));
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [annonyms, setAnnonyms] = useState(false);
    // const [locErr, setLocErr] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(false)


    const [charCount, setCharCount] = useState(0);


    console.log(incidentReport, 'incidentReport')

    useEffect(() => {
        console.log("submit a incident")
        dispatch(incidentSubmitConfiguration())
    }, [])



    useEffect(() => {
        var treeNodes = buildTreeNodes(incidentReport.hTreeData)
        console.log(treeNodes, 'treeNodes', incidentReport.hTreeData)
        setTreeNodes(treeNodes)
        // if(incidentReport.escalatedHList.length >0 && incidentReport.escalatedHList.length < 2){
        //     validation.values.incd_h_id = incidentReport.escalatedHList[0]["_id"]
        // }

    }, [incidentReport.hTreeData])


    // const buildTreeNodes = (nodes) => {
    //     console.log(nodes, 'nodes')




    //     return nodes.map((node) => {
    //         const { value, label, children, ep_level, code, ep_level_value, id, node_id, parent, user_path, unique_users, user_permission_acpln } = node;
    //         const hasChildren = (children && children.length > 0);



    //         // Function to handle checkbox checking
    //         const handleCheck = () => {
    //             // Here, you would update the `checkedKeys` state with the value of the node being clicked
    //             const newCheckedKeys = [...checkedKeys];
    //             console.log(newCheckedKeys,'newCheckedKeys',node,checkedKeys,newCheckedKeys.includes(value))
    //             if (!newCheckedKeys.includes(value)) {
    //                 newCheckedKeys.push(value);
    //             } else {
    //                 newCheckedKeys.splice(newCheckedKeys.indexOf(value), 1);
    //             }
    //             onCheck(newCheckedKeys, { node, checked: !newCheckedKeys.includes(value) });
    //             setCheckedKeys(newCheckedKeys)
    //         };



    //         return {
    //             key: value,
    //             label: label,
    //             user_path: user_path,
    //             unique_users: unique_users,
    //             value,
    //             ep_level,
    //             ep_level_value,
    //             id,
    //             node_id,
    //             parent,
    //             children: hasChildren ? buildTreeNodes(children) : [],
    //             user_permission_acpln: user_permission_acpln,
    //             code: code,
    //             title: (
    //                 <div

    //                 onClick={handleCheck} // Trigger check on click
    //                     style={{
    //                         background: 'transparent',
    //                         position: 'relative',
    //                         zIndex: 'auto',
    //                         minHeight: '24px',
    //                         margin: '0',
    //                         padding: '0 4px',
    //                         color: 'inherit',
    //                         lineHeight: '24px',
    //                         borderRadius: '6px',
    //                         cursor: 'pointer',
    //                         transition: 'all 0.2s, border 0s, line - height 0s, box - shadow 0s'
    //                     }}
    //                 >

    //                     <FileOutlined
    //                         style={{
    //                             cursor: 'pointer',
    //                             color: '#556EE6',
    //                             opacity: 1,
    //                         }}
    //                     />
    //                     <span style={{ marginLeft: '4px', opacity: 1, fontWeight: 600 }}>
    //                         {label}
    //                     </span>
    //                 </div>
    //             ),

    //         };
    //     });



    // }


    const buildTreeNodes = (nodes) => {

        return nodes.map((node) => {
            const { value, label, children, ep_level, code, ep_level_value, id, node_id, parent, user_path, unique_users, user_permission_acpln } = node;
            const hasChildren = (children && children.length > 0);

            // Function to handle checkbox checking
            const handleCheck = () => {
                setCheckedKeys((prevCheckedKeys) => {
                    const newCheckedKeys = [...prevCheckedKeys];

                    if (newCheckedKeys.length > 0 && !newCheckedKeys.includes(value)) {
                        setCheckedKeys([value])
                    }
                    // Add or remove the value from the list
                    if (!newCheckedKeys.includes(value)) {
                        newCheckedKeys.push(value);
                    } else {
                        newCheckedKeys.splice(newCheckedKeys.indexOf(value), 1);
                    }
                    return newCheckedKeys;
                });

                // setSelectedBox([value]);
            };

            return {
                key: value,
                label: label,
                user_path: user_path,
                unique_users: unique_users,
                value,
                ep_level,
                ep_level_value,
                id,
                node_id,
                parent,
                children: hasChildren ? buildTreeNodes(children) : [],
                user_permission_acpln: user_permission_acpln,
                code: code,
                title: (
                    <div

                        onClick={handleCheck} // Trigger check on click
                        style={{
                            background: 'transparent',
                            position: 'relative',
                            zIndex: 'auto',
                            minHeight: '24px',
                            margin: '0',
                            padding: '0 4px',
                            color: 'inherit',
                            lineHeight: '24px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                            transition: 'all 0.2s, border 0s, line - height 0s, box - shadow 0s'
                        }}
                    >

                        <FileOutlined
                            style={{
                                cursor: 'pointer',
                                color: '#556EE6',
                                opacity: 1,
                            }}
                        />
                        <span style={{ marginLeft: '4px', opacity: 1, fontWeight: 600 }}>
                            {label}
                        </span>
                    </div>
                ),

            };
        });



    }
    // const buildTreeNodes = (nodes) => {
    //     console.log(nodes,'nodes')

    //     return nodes.map((node) => {
    //         const { value, label, children, ep_level, code, ep_level_value, id, node_id, parent, user_path, unique_users, user_permission_acpln } = node;
    //         const hasChildren = (children && children.length > 0);
    //         return {
    //             key: value,
    //             label: label,
    //             user_path: user_path,
    //             unique_users: unique_users,
    //             value,
    //             ep_level,
    //             ep_level_value,
    //             id,
    //             node_id,
    //             parent,
    //             children: hasChildren ? buildTreeNodes(children) : [],
    //             user_permission_acpln: user_permission_acpln,
    //             code: code,
    //             title: (
    //                 <div
    //                     style={{
    //                         background: 'transparent',
    //                         position: 'relative',
    //                         zIndex: 'auto',
    //                         minHeight: '24px',
    //                         margin: '0',
    //                         padding: '0 4px',
    //                         color: 'inherit',
    //                         lineHeight: '24px',
    //                         borderRadius: '6px',
    //                         cursor: 'pointer',
    //                         transition: 'all 0.2s, border 0s, line - height 0s, box - shadow 0s'
    //                     }}
    //                 >

    //                     <FileOutlined
    //                         style={{
    //                             cursor: 'pointer',
    //                             color: '#556EE6',
    //                             opacity: 1,
    //                         }}
    //                     />
    //                     <span style={{ marginLeft: '4px', opacity: 1, fontWeight: 600 }} >
    //                         {label}
    //                     </span>
    //                 </div>
    //             ),

    //         };
    //     });



    // }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    const handleCameraUpload = (file) => {

        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Do you want to upload this image ?',
            showCancelButton: true,
            confirmButtonColor: '#2ba92b',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                console.log(file, 'file')
                console.log(file, 'file')
                await handleAcceptedFiles([file])
                // console.log(item, 'item')
                // dispatch(deleteIncidentType(item._id))
                // setDataLoaded(false); setTimeout(() => {
                //     setDataLoaded(true)
                // }, 100);
            }

        })

    }



    const handleAcceptedFiles = async (files) => {
        console.log(files, 'files')
        files.map(async file => {
            const formattedFile = Object.assign(file, {
                "preview": URL.createObjectURL(file),
                "formattedSize": formatBytes(file.size),
                // "uploading": false,
                "filetype": file.type,
                // "uploadingStatus": 'Not uploaded',
                "originalName": file.name,
            })

            var formData = new FormData();
            formData.append("files", formattedFile)
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            try {
                const responseData = await urlSocket.post("storeImage/awswebupload", formData, config)
                console.log(responseData, 'responseData')

                if (responseData.status === 200) {
                    const uptmediaInfo = _.cloneDeep(mediaInfo)
                    uptmediaInfo.push(responseData.data.data[0])
                    setmediaInfo(uptmediaInfo)
                }

            } catch (error) {

            }
        })


        // uploadImageToAws[files[0]]
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            incident_id: '',
            incd_h_id: incidentReport.escalatedHList && incidentReport.escalatedHList.length > 0 && incidentReport.escalatedHList.length < 2 ? incidentReport.escalatedHList[0]._id : '',
            action: ''
        },
        validationSchema: Yup.object({
            incident_id: Yup.string().required("Please Choose the Incident type"),
            incd_h_id: Yup.string().required("Please Choose the Hierarchy type"),
            action: Yup.string().required("Please Enter the Incident observation").matches(/\S+/, "Observation cannot be just spaces"),
        }),
        onSubmit: async (values) => {
            console.log(values, 'values')
            if (checkedKeys.length > 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Confirmation',
                    text: 'Are you sure you want to submit this incident?',
                    showCancelButton: true,
                    confirmButtonColor: 'rgb(42 159 0)',
                    cancelButtonColor: '#F46A6A',
                    confirmButtonText: 'Submit',
                    cancelButtonText: 'Cancel',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setSelectedLocation(false)
                        var nodeInfo = _.filter(incidentReport.flatData, { _id: checkedKeys[0] })
                        console.log(nodeInfo, incidentReport.flatData)
                        var onLevelupNode = _.filter(incidentReport.flatData, { id: nodeInfo[0].parent })
                        values["incd_h_location"] = {
                            node_name: nodeInfo[0].hlevel_name,
                            node_id: nodeInfo[0].id,
                            node_level: nodeInfo[0].hlevel,

                        }
                        values["attachments"] = mediaInfo
                        values["incd_resp_person"] = []
                        // onLevelupNode[0].unique_users.map((ele, pos) => {
                        //     values["incd_resp_person"].push({
                        //         user_id: ele.user_id,
                        //         user_name: ele.name,
                        //         user_designation: ele.hirerachy_name
                        //     })
                        // })
                        // _.map(nodeInfo[0].unique_users, "user_id")
                        var incident_type_info = _.filter(incidentReport.incidentReportTypeList, { incd_type_id: values.incident_id })
                        console.log(incident_type_info, 'incident_type_info')
                        values["incd_type"] = {
                            incd_type_name: incident_type_info[0].incd_type_name,
                            incd_type_id: incident_type_info[0].incd_type_id,
                            incd_type_descrption: incident_type_info[0].incd_type_descrption,
                            incd_resp_desgntion: incident_type_info[0].incd_resp_desgntion,
                        }
                        values["incd_submitted_by"] = {
                            user_id: authUser.user_data._id,
                            user_name: authUser.user_data.firstname,
                            email_id: authUser.user_data.email_id,
                        }
                        console.log(incidentReport.flatData, '', nodeInfo)
                        var resp_flat_data = []
                        incidentReport.flatData.map((ele, idx) => {
                            if (ele.id <= nodeInfo[0].id) {
                                resp_flat_data.push(ele)
                            }
                        })

                        // var resp_person = await recuriveCheck(resp_flat_data,nodeInfo.length,nodeInfo)
                        var resp_users = [];

                        var getCatType = _.map(incident_type_info[0].incd_resp_desgntion, 'cat_type')
                        console.log(getCatType, '')
                        // const matchedData = resp_flat_data.map(item => {
                        //     const matchedUsers = _.filter(item.unique_users, user => user.cat_type && user.cat_type.includes(getCatType));

                        //     resp_users = [...resp_users, ...matchedUsers]; // Directly push matched users into resp_users

                        //     return {
                        //         ...item,
                        //         unique_users: matchedUsers
                        //     };
                        // }).filter(item => item.unique_users.length > 0);

                        //  connectedRoleData([nodeInfo[0]],nodeInfo[0],resp_flat_data).then((result)=>{
                        //     console.log(result,'result');
                        //  })

                        var retriveConnectedData = await connectedRoleData([nodeInfo[0]], nodeInfo[0], resp_flat_data)
                        console.log(retriveConnectedData, 'retriveConnectedData')





                        const matchedData = retriveConnectedData.map(item => {
                            const matchedUsers = _.filter(item.unique_users, user => {
                                return user.cat_type && getCatType.some(cat => user.cat_type.includes(cat));
                            });

                            resp_users = [...resp_users, ...matchedUsers]; // Directly push matched users into resp_users

                            return {
                                ...item,
                                unique_users: matchedUsers
                            };
                        }).filter(item => item.unique_users.length > 0);


                        console.log(resp_users, 'resp_users', resp_flat_data, incidentReport.flatData);
                        resp_users.map((ele, pos) => {
                            values["incd_resp_person"].push({
                                user_id: ele.user_id,
                                user_name: ele.name,
                                user_designation: ele.hirerachy_name,
                                user_email_id: ele.email_id,
                                user_cat_type: ele.cat_type
                            })
                        })

                        values["incd_resp_person"] = _.uniqBy(values["incd_resp_person"], "user_id")
                        values["incd_annyms_status"] = annonyms
                        console.log(values, 'resp_flat_data')
                        dispatch(setIncidentsInfo({}))
                        dispatch(submitIncidentInfo(values))
                        history.push("/raised_incd")
                    }


                })



            }
            else {
                setSelectedLocation(true)
            }
        }
    })


    const getAllKeys = (nodes) => {
        let keys = [];
        nodes.forEach((node) => {
            keys.push(node.key);
            if (node.children) {
                keys = keys.concat(getAllKeys(node.children));
            }
        });
        return keys;
    };


    useEffect(() => {
        const keys = getAllKeys(treeNodes);
        setExpandedKeys(keys);
    }, [treeNodes]);

    const handleExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
    };



    const connectedRoleData = (nodeArr, nodeInfo, flatInfo) => {
        return new Promise((resolve, reject) => {
            if (nodeInfo.parent !== null) {
                const item = _.find(flatInfo, { id: nodeInfo.parent });
                nodeArr.push(item);

                // Recursively call the function and resolve the final result
                connectedRoleData(nodeArr, item, flatInfo).then(resolve).catch(reject);
            } else {
                resolve(nodeArr); // Resolve the final array when the base case is reached
            }
        });
    }

    // const connectedRoleData=async(nodeArr,nodeInfo,flatInfo)=>{
    //     if(nodeInfo.parent !== null){
    //         var item = _.find(flatInfo,{id : nodeInfo.parent})
    //         nodeArr.push(item)
    //         await connectedRoleData(nodeArr,item,flatInfo)
    //     }
    //     else{
    //         // nodeArr.push(nodeInfo)
    //         console.log(nodeArr,'nodeArr',flatInfo);
    //         return nodeArr
    //     }

    // }


    const handleHierarchyChange = (event) => {
        validation.handleChange(event);
        dispatch(getHdata(event.target.value))

    }


    const onCheck = (checked, event) => {
        console.log(checked, 'event', event)
        setSelectedLocation(false)
        // setLocErr(false)
        if (event.checked === true) {
            setCheckedKeys([event.node.key])
        }
        else {
            setCheckedKeys([])
        }

    }

    const gotoBack = () => {
        history.push("/raised_incd")
    }


    const handleInputChange = (e) => {
        const { value } = e.target;
        if (value.length <= 200) {
            setCharCount(value.length);
            validation.setFieldValue("action", value);
        }
    };



    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <Breadcrumbs
                        title="Submit Incident"
                        breadcrumbItem="Submit Incident"
                        isBackButtonEnable={true}
                        gotoBack={() => gotoBack()}

                    />
                    <Card style={{ height: '90vh', overflow: "auto" }} >
                        <CardBody className="pt-0" >
                            {/* <div className="form-check form-switch form-switch-sm mt-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={"customreview"}
                                />
                                <label className={"form-check-label text-primary"} htmlFor={"customreview"}>
                                    Enable Annonyms Submission
                                </label>
                            </div> */}
                            <hr />
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col xs={12} md={6} lg={6} xl={6} xxl={4} className="mb-3" style={{ maxHeight: "1000px", height: '100%' }} >
                                        {/* <Col md={3} > */}
                                        <Row className='my-3'>
                                            <Col>
                                                <h6>Select Incident Type :<span className='text-danger'>*</span></h6>
                                                <select
                                                    id="incident_id"
                                                    name="incident_id"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    // style={{
                                                    //     width: '200px',
                                                    //     padding: '8px',
                                                    //     borderRadius: '4px',
                                                    //     border: '1px solid #ccc'
                                                    //   }}
                                                    className={`form-control ${validation.touched.incident_id && validation.errors.incident_id ? 'is-invalid' : ''}`}
                                                    defaultValue={"Select"}
                                                >
                                                    <option disabled value={"Select"}> Select</option>
                                                    {
                                                        incidentReport.incidentReportTypeList.map((ele, pos) => {
                                                            return (
                                                                <option key={pos} value={ele.incd_type_id}>
                                                                    {ele.incd_type_name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {validation.touched.incident_id && validation.errors.incident_id ? (
                                                    <FormFeedback type="invalid">{validation.errors.incident_id}</FormFeedback>
                                                ) : null}

                                            </Col>
                                        </Row>
                                        {
                                            incidentReport.escalatedHList.length > 1 &&
                                            <Row className='my-3'>
                                                <Col>
                                                    <h6>Select Hierarchy :<span className='text-danger'>*</span></h6>
                                                    <select
                                                        id="incd_h_id"
                                                        name="incd_h_id"
                                                        defaultValue={"Select"}
                                                        onChange={handleHierarchyChange}
                                                        onBlur={validation.handleBlur}
                                                        className={`form-control ${validation.touched.incd_h_id && validation.errors.incd_h_id ? 'is-invalid' : ''}`}
                                                    >
                                                        <option disabled value={"Select"}>
                                                            Select
                                                        </option>
                                                        {
                                                            incidentReport.escalatedHList.map((ele, pos) => {
                                                                return (
                                                                    <option key={pos} value={ele._id}>{ele.hierarchy_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {validation.touched.incd_h_id && validation.errors.incd_h_id ? (
                                                        <FormFeedback type="invalid">{validation.errors.incd_h_id}</FormFeedback>
                                                    ) : null}

                                                </Col>
                                            </Row>
                                        }

                                        {

                                            validation.values.incd_h_id &&
                                            <Row>
                                                <Col>
                                                    <h6>Choose Incident Location :<span className='text-danger'>*  </span><span className='font-size-12 text-danger'> {selectedLocation ? "( Please Select the Location )" : ""}</span> {selectedLocation && (
                                                        <ExclamationCircleOutlined style={{ color: 'red' }} />
                                                    )}</h6>
                                                    {/* <h6>Choose Incident Location :<span className='text-danger'>*</span></h6> */}
                                                    {/* {
                                                locErr &&
                                                <div className='text-danger' style={{fontSize:"smaller"}}>Please Choose the location</div>
                                            } */}
                                                    <div style={{ maxHeight: '600px', overflow: "auto" }}>
                                                        <Tree
                                                            style={{ borderColor: '#150101', marginBottom: '10px', color: selectedLocation ? '#ff6666' : '', border: selectedLocation && '1px dashed #ff9999', borderRadius: '8px' }}
                                                            // style={{ borderColor: '#150101', marginBottom: '10px' }}
                                                            defaultExpandAll={true}
                                                            checkable
                                                            treeData={treeNodes}
                                                            checkedKeys={checkedKeys}
                                                            onCheck={(checked, event) => { onCheck(checked, event) }}
                                                            showLine={true}
                                                            showIcon={true}
                                                            checkStrictly={true}
                                                            expandedKeys={expandedKeys}
                                                            onExpand={handleExpand}
                                                        />

                                                    </div>
                                                </Col>

                                            </Row>
                                        }

                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={6} xxl={4} className="mb-3" style={{ overflow: "hidden" }}>
                                        {/* <Col md={3} style={{overflow:"scroll"}}> */}
                                        <Row className='my-3'>
                                            <Col>
                                                <h6>Observation :<span className='text-danger'>*</span></h6>
                                                {/* <Input
                                                className="form-control"
                                                placeholder="Enter Incident Description"
                                                type="textarea"
                                                style={{ height: "120px" }}
                                                name="action"
                                                invalid={
                                                    validation.touched.action && validation.errors.action ? true : false
                                                }
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                            />
                                            {validation.touched.action && validation.errors.action ? (
                                                <FormFeedback type="invalid">{validation.errors.action}</FormFeedback>
                                            ) : null} */}



                                                <Input
                                                    className="form-control"
                                                    placeholder="Enter Incident Description"
                                                    type="textarea"
                                                    name="action"
                                                    value={validation.values.action}
                                                    invalid={validation.touched.action && validation.errors.action ? true : false}
                                                    onChange={handleInputChange}
                                                    onBlur={validation.handleBlur}
                                                    style={{ maxHeight: '200px', height: "120px" }}
                                                />


                                                {/* <Input
                                                    className="form-control"
                                                    placeholder="Enter Incident Description"
                                                    type="textarea"
                                                    // style={{ height: "120px" }}
                                                    name="action"
                                                    value={validation.values.action}
                                                    invalid={validation.touched.action && validation.errors.action ? true : false}
                                                    onChange={handleInputChange}
                                                    onBlur={validation.handleBlur}
                                                    style={{ maxHeight: '200px', height: "120px" }}
                                                /> */}
                                                {validation.touched.action && validation.errors.action ? (
                                                    <FormFeedback type="invalid">{validation.errors.action}</FormFeedback>
                                                ) : null}
                                                <div className="text-end">
                                                    <small>{charCount} / 200 characters</small>
                                                </div>


                                            </Col>

                                        </Row>

                                        <Row className='my-3'>
                                            <Col>
                                                <h6>Upload Image/Video :</h6>

                                                {
                                                    mediaInfo.length === 0 ? (
                                                        <>
                                                            <WebcamCapture handleCameraUpload={(file) => { handleCameraUpload(file) }} />

                                                            <Dropzone
                                                                onDrop={acceptedFiles => {
                                                                    handleAcceptedFiles(acceptedFiles)
                                                                }}
                                                                style={{ width: "100%" }}
                                                                maxFiles={1}
                                                                // accept={[".jpg", ".jpeg", ".png", ".avi", ".mov", ".mkv", ".mp4"]}
                                                                accept={{
                                                                    'image/jpeg': ['.jpg', '.jpeg'],
                                                                    'image/png': ['.png'],
                                                                    'video/avi': ['.avi'],
                                                                    'video/quicktime': ['.mov'],
                                                                    'video/x-matroska': ['.mkv'],
                                                                    'video/mp4': ['.mp4']
                                                                }}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick mt-2"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} />
                                                                            <div className="mb-3">
                                                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                            </div>
                                                                            <h4>Drop files here or click to upload.</h4>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                            <label className="py-2 text-danger">* Only one file to select. Accepted format .jpeg, .jpg, .png ,.avi, .mov, .mkv, .mp4</label>
                                                        </>
                                                    )
                                                        :

                                                        <>
                                                            {
                                                                console.log(mediaInfo, 'mediaInfo')
                                                            }

                                                            {

                                                                mediaInfo?.map((ele, idx) => {
                                                                    return (
                                                                        // <>
                                                                        ele?.mimetype.startsWith("video/") ?
                                                                            <>
                                                                                <video
                                                                                    src={authUser.client_info[0].base_url + ele.originalname}
                                                                                    className="avatar-xl bg-light"
                                                                                    controls
                                                                                />
                                                                                <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => {
                                                                                    //  setmediaInfo([])
                                                                                    const updtmediaInfo = _.cloneDeep(mediaInfo)
                                                                                    updtmediaInfo.splice(idx,1)
                                                                                     setmediaInfo(updtmediaInfo)
                                                                                     
                                                                                     }}>
                                                                                    <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
                                                                                </Popconfirm>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <img
                                                                                    data-dz-thumbnail=""
                                                                                    className="avatar-xl bg-light"
                                                                                    src={authUser.client_info[0].base_url + (ele.originalname)}
                                                                                />
                                                                                <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => {
                                                                                    const updtmediaInfo = _.cloneDeep(mediaInfo)
                                                                                    updtmediaInfo.splice(idx,1)
                                                                                     setmediaInfo(updtmediaInfo)
                                                                                      }}>
                                                                                    <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
                                                                                </Popconfirm>
                                                                            </>

                                                                    )


                                                                })
                                                            }
                                                            <WebcamCapture  handleCameraUpload={(file) => { handleCameraUpload(file) }} />

                                                            <Dropzone
                                                                onDrop={acceptedFiles => {
                                                                    handleAcceptedFiles(acceptedFiles)
                                                                }}
                                                                style={{ width: "100%" }}
                                                                maxFiles={1}
                                                                // accept={[".jpg", ".jpeg", ".png", ".avi", ".mov", ".mkv", ".mp4"]}
                                                                accept={{
                                                                    'image/jpeg': ['.jpg', '.jpeg'],
                                                                    'image/png': ['.png'],
                                                                    'video/avi': ['.avi'],
                                                                    'video/quicktime': ['.mov'],
                                                                    'video/x-matroska': ['.mkv'],
                                                                    'video/mp4': ['.mp4']
                                                                }}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick mt-2"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} />
                                                                            <div className="mb-3">
                                                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                            </div>
                                                                            <h4>Drop files here or click to upload.</h4>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                            <label className="py-2 text-danger">* Only one file to select. Accepted format .jpeg, .jpg, .png ,.avi, .mov, .mkv, .mp4</label>

                                                        </>


                                                }



                                            </Col>
                                            {/* <Col>
                                            <h6>Observation :<span className='text-danger'>*</span></h6>
                                            <Input
                                                className="form-control"
                                                placeholder="Enter Incident Description"
                                                type="textarea"
                                                style={{ height: "120px" }}
                                                name="action"
                                                invalid={
                                                    validation.touched.action && validation.errors.action ? true : false
                                                }
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                            />
                                            {validation.touched.action && validation.errors.action ? (
                                                <FormFeedback type="invalid">{validation.errors.action}</FormFeedback>
                                            ) : null}

                                            </Col> */}
                                        </Row>
                                        {
                                            console.log(annonyms, 'e.target.checked')
                                        }
                                        <input
                                            type="checkbox"
                                            className="form-check-input me-2"
                                            id={"customreview"}
                                            onChange={(e) => { setAnnonyms(e.target.checked) }}
                                        />
                                        <label className={"form-check-label text-primary"} htmlFor={"customreview"}>
                                            Enable Anonymous Submission
                                        </label>
                                        <Row>
                                            <Col md={12} className='text-end'>
                                                <button type='submit' onClick={(e) => {
                                                    // e.preventDefault(); 
                                                    checkedKeys.length > 0 ?

                                                        setSelectedLocation(false)
                                                        // setLocErr(false) 
                                                        :
                                                        setSelectedLocation(true)
                                                    //  setLocErr(true)
                                                }} className='btn btn-success'>Submit</button>
                                            </Col>

                                        </Row>
                                    </Col>


                                </Row>



                                {/* <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={6}>
                                            <h6>Select Incident Type :<span className='text-danger'>*</span></h6>
                                            <select
                                                id="incident_id"
                                                name="incident_id"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                className={`form-control ${validation.touched.incident_id && validation.errors.incident_id ? 'is-invalid' : ''}`}
                                            // className='form-control' 
                                            defaultValue={"Select"}
                                            >
                                                <option disabled value={"Select"}> Select</option>
                                                {
                                                    incidentReport.incidentReportTypeList.map((ele,pos)=>{
                                                        return(
                                                            <option key={pos} value={ele.incd_type_id}>{ele.incd_type_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {validation.touched.incident_id && validation.errors.incident_id ? (
                                                <FormFeedback type="invalid">{validation.errors.incident_id}</FormFeedback>
                                            ) : null}
                                        </Col>
                                        <Col md={6}>
                                            <h6>Select Hierarchy :<span className='text-danger'>*</span></h6>
                                            <select
                                              id="incd_h_id"
                                                name="incd_h_id"
                                            // className='form-control' 
                                            defaultValue={"Select"}
                                            // onChange={validation.handleChange}
                                            onChange={handleHierarchyChange}
                                            onBlur={validation.handleBlur}
                                            className={`form-control ${validation.touched.incd_h_id && validation.errors.incd_h_id ? 'is-invalid' : ''}`}
                                            // invalid={
                                            //     validation.touched.h_id && validation.errors.h_id ? true : false
                                            // }
                                            >
                                                <option disabled value={"Select"}>
                                                    Select
                                                </option>
                                                {
                                                    incidentReport.escalatedHList.map((ele,pos)=>{
                                                        return(
                                                            <option key={pos} value={ele._id}>{ele.hierarchy_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {validation.touched.incd_h_id && validation.errors.incd_h_id ? (
                                                <FormFeedback type="invalid">{validation.errors.incd_h_id}</FormFeedback>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={6}>
                                            <h6>Upload Image/Video :</h6>
                                            <div className="d-flex flex-row gap-2 align-items-center justify-content-center">
                                                <div className="col-11">
                                                    <div className="d-flex flex-row gap-2 align-items-center" >
                                                        <div className="d-flex flex-column  align-items-center">
                                                            <Dropzone
                                                                onDrop={acceptedFiles => {
                                                                    handleAcceptedFiles(acceptedFiles)
                                                                }}
                                                                maxFiles={1}
                                                                accept={[".jpg", ".jpeg", ".png",".avi" ,".mov",".mkv",".mp4"]}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick mt-2"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} />
                                                                            <div className="mb-3">
                                                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                            </div>
                                                                            <h4>Drop files here or click to upload.</h4>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                            <label className="py-2 text-danger">* Only one file to select. Accepted format .jpeg, .jpg, .png</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col md={6}>
                                            <h6>Observation :<span className='text-danger'>*</span></h6>
                                            <Input
                                                className="form-control"
                                                placeholder="Enter Incident Description"
                                                type="textarea"
                                                style={{ height: "80%" }}
                                                  name="action"
                                                  invalid={
                                                    validation.touched.action && validation.errors.action ? true : false
                                                }
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                            />
                                                 {validation.touched.action && validation.errors.action ? (
                                                <FormFeedback type="invalid">{validation.errors.action}</FormFeedback>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                        <button className='btn btn-success'>Submit</button>
                                        </Col>
                                        
                                    </Row>
                                </Col>
                                <Col md={6}>
                                        <Tree
                                            style={{ borderColor: '#150101' }}
                                            defaultExpandAll={true}
                                            checkable
                                            treeData={treeNodes}
                                            checkedKeys={checkedKeys}
                                            onCheck={(checked, event) => { onCheck(checked, event) }}
                                            showLine={true}
                                            showIcon={true}
                                            checkStrictly={true}
                                        />

                              
                              
                              </Col>
                            </Row> */}
                                {/* <Row>
                             
                                


                            </Row> */}



                            </Form>

                        </CardBody>
                    </Card>


                </Container>


            </div>


        </React.Fragment>

    )
}

export default ReportIncidet
