import React, { Component } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';

import {
    Container, Row, Col, Button, Alert, Input, Card, CardBody, CardText, Table,
    Modal, ModalHeader, ModalBody,
    Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
} from "reactstrap"



import CardTitle from "reactstrap/lib/CardTitle";



import ReviewOPType from "./Components/review_optype";
import PreviewImage from "./Components/preview_images";
import PreviewDocuments from "./Components/preview_documents";
import PreviewObservation from "./Components/preview_observation";
import PreviewCAPA from "./Components/preview_CAPA";





const _ = require('lodash')

var urlSocket = require("../../../helpers/urlSocket")
var moment = require('moment')

export default class CheckpointReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight,
            position: 'right',
            open: false,
            dataloaded: false,
            selectedCheckpoint: null,
            filterStatus: "all",
            showOption: false,
            attachImages: [],
            attachDocuments: [],
            selectedOption: null,
            docfileStatus: "clear",
            fileStatus: "clear",
            doc_warning_enabled: false,
            warning_enabled: false,
            issign_empty: true,
            signee: null,
        }
    }

    async componentDidMount() {
        await this.getSessionData()
        await this.loadCheckpoints()
    }


    async getSessionData() {

        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        var endpointData = JSON.parse(sessionStorage.getItem("endpointInfo"));


        this.setState({
            configData: data.config_data,
            userData: data.user_data,
            endpointData,
            db_info:db_info,
            imagePreviewUrl: data.config_data.img_url,
            imageToken: data.config_data.img_url
        })

    }

    async loadCheckpoints() {
        try {

            urlSocket.post("epadtprcs/getepcheckpoints", {
                auditInfo: {
                    audit_pbd_id: this.state.endpointData.audit_pbd_id
                },
                userInfo: {
                    encrypted_db_url:this.state.db_info.encrypted_db_url,
                    _id: this.state.userData._id,
                    company_id: this.state.userData.company_id
                },
                endpointInfo: this.state.endpointData
            })
                .then(response => {
                    if (response.data.response_code === 500) {
                        this.setState({
                            checkpoints: _.filter(response.data.data, { document_type: "2" }),


                        }, function () {
                            // this.getCheckpointStatus()
                            this.filterCheckpoints(this.state.filterStatus)
                        })
                    }

                })

        } catch (error) {
            console.log("catch error", error)
        }
    }

    filterCheckpoints(filterStatus) {
        if (filterStatus === "all") {
            this.setState({
                filteredData: this.state.checkpoints,
                showOption: false,
                dataloaded: true,
                //selectedCheckpoint:null,
                idx: -1
            })
        }
        else
            if (filterStatus === "nr") {
                this.setState({
                    filteredData: _.filter(this.state.checkpoints, item => {
                        return item.cp_review_status === null
                    }),
                    showOption: false,
                    selectedCheckpoint: null,
                    idx: -1,
                    dataloaded: true,
                })
            }
            else {
                this.setState({
                    filteredData: _.filter(this.state.checkpoints, item => {
                        return item.cp_review_status !== null
                    }),
                    showOption: false,
                    selectedCheckpoint: null,
                    idx: -1,
                    dataloaded: true
                })
            }
    }

    render() {
        if (this.state.dataloaded) {
            return (
                <React.Fragment>
                    <div className="page-content">
                        <MetaTags>
                            <title>AuditVista | Check Point Report</title>
                        </MetaTags>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row',  alignItems: 'center' }} className="mb-2">
                            <div style={{fontWeight: 600, width: '80%', padding: '0 20px' }}>
                                Check Point Report
                                </div>
                            <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Link to="/pbdrpt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
                            </div>
                        </div>
                        <Container fluid >

                            <Card body>
                                <Row>
                                    <Col lg={10}>
                                        <div className="font-size-11 text-secondary">Audit title</div>
                                        <CardTitle className="h4 mt-0">
                                            {this.state.endpointData.audit_pbd_name}
                                        </CardTitle>
                                        <div className="font-size-11 text-secondary">Endpoint</div>
                                        <CardText>
                                            {this.state.endpointData.name}{" / "}{this.state.endpointData.code}
                                        </CardText>


                                    </Col>
                                    <Col md={2}>
                                        <label>Total Audit Score : &nbsp;{ parseFloat(this.state.endpointData.audit_score.$numberDecimal).toFixed(2)} </label>
                                        <label>Total Review Score : &nbsp;{ parseFloat(this.state.endpointData.review_score.$numberDecimal).toFixed(2)} </label>
                                        <label>Max Checkpoint Score : &nbsp;{ parseFloat(this.state.endpointData.max_score.$numberDecimal).toFixed(2)} </label>
                                    
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                            </Card>
                            


                            {
                                this.state.filteredData.map((item, i) => {

                                    return (

                                        <Card key={i} style={{
                                            borderTop: item.cp_review_status !== null ? "5px solid #556ee6" : item.cp_status === "0" ? "5px solid #74788d" : item.cp_status === "1" ? "5px solid #f1b44c" : item.cp_status === "2" ? "5px solid #34c38f" : "5px solid #50a5f1",
                                          }}>
                                            <CardBody>
                                            
                                                <div className="mb-2">
                                                    <span className="font-size-10">Audit status - </span><label className={item.cp_status === "0" ? "badge badge-soft-secondary font-size-10 me-2" : item.cp_status === "1" ? "badge badge-soft-warning font-size-10 me-2" : "badge badge-soft-success font-size-10 me-2"}>{item.cp_status === "0" ? "Not started" : item.cp_status === "1" ? "In progress" : "Completed"}</label>
                                                    {
                                                        this.state.endpointData.status === "4" && item.cp_review_status !== null?
                                                           <> <span className="font-size-10">Review Status - <span className={item.cp_review_status === null ? "badge badge-soft-secondary font-size-10 me-2":"badge badge-soft-primary font-size-10 me-2"}>{item.cp_review_status === null?"Not Reviewed":"Reviewed"}</span></span><span
                                                                className={item.cp_review_status === "0" ? "badge badge-soft-danger font-size-10 me-2" : "badge badge-soft-success font-size-10 me-2"}
                                                            >
                                                                {item.cp_review_status === "0" ? "Rejected" : "Approved"}</span> </>: null
                                                    }
                                                    {
                                                        // item.cp_status !== "0" ? <><span className="font-size-10">Compliance status - </span><label className={item.cp_is_compliance ? "badge badge-soft-success font-size-10":"badge badge-soft-danger font-size-10"}>{item.cp_is_compliance ? "Compliance" : "Non-Compliance"}</label> </>:null
                                                        item.cp_status !== "0" ? <><span className="font-size-10">Compliance status - </span><label className={item.cp_is_compliance ? "badge badge-soft-success font-size-10":"badge badge-soft-danger font-size-10"}>{item.cp_compliance.name}</label> </>:null

                                                    }
                                                    
                                                </div>
                                                <div className="text-end">
                                                <label className="text-primary">Score Obtained: &nbsp; <span className="text-success">{item.cp_otpion_score?.$numberDecimal}</span></label>
                                                </div>
                                                
                                                <label>Checkpoint</label>
                                                <div className="mb-3">
                                                    <span className="font-size-14 fw-bold">{item.checkpoint}</span>
                                                </div>

                                                
                                                <label style={{fontWeight: 500}}>Options</label>
                                                {
                                                    item.checkpoint_type_id === "1" || item.checkpoint_type_id === "2" || item.checkpoint_type_id == "3"||item.checkpoint_type_id == "4"||item.checkpoint_type_id == "5"   ?
                                                        <ReviewOPType
                                                            options={item.checkpoint_options}

                                                        />
                                                        : null
                                                }
                                                
                                              
                                                {
                                                    item.cp_attach_images.length !== 0 ?
                                                        <div className="mt-4">
                                                            <label>Images Attached</label>
                                                            <PreviewImage
                                                                imagePreviewUrl={this.state.imagePreviewUrl}
                                                                images={item.cp_attach_images}
                                                            />
                                                        </div> : null
                                                }
   
                                                {
                                                    item.cp_documents.length !== 0 ?
                                                        <div className="mt-4">
                                                            <label>Documents Attached</label>
                                                            <PreviewDocuments
                                                                imagePreviewUrl={this.state.imagePreviewUrl}
                                                                images={item.cp_documents}
                                                            />
                                                        </div> : null

                                                }

                                                {
                                                    item.cp_observation !== null ?
                                                        <div className="mt-4">
                                                            <label>Observation</label>
                                                            <PreviewObservation
                                                                observation={item.cp_observation}
                                                            />
                                                        </div> : null
                                                }

                                                {
                                                    item.cp_actionplans.length !== 0 ?
                                                        <div className="mt-4">
                                                            <label>CAPA</label>
                                                            <PreviewCAPA
                                                                actionplans={item.cp_actionplans}
                                                            />
                                                        </div> : null
                                                }

                                                {/* <hr className="mt-5" />

                                                <div className="mt-3">
                                                    <label className="font-size-10 me-3">Audit by: {item.audit_user.first_name} ({item.audit_user.user_code})</label>
                                                    <label className="font-size-10">Review by: {item.review_user.first_name} ({item.review_user.user_code})</label>
                                                </div> */}
                                                

                                            </CardBody>
                                        </Card>
                                    )
                                })
                            }



                        </Container>
                    </div>
                </React.Fragment>
            )
        }
        else {
            return null
        }
    }


}