import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem, Button } from "reactstrap";

class Breadcrumbs extends Component {


    render() {
        return (
            <React.Fragment>
                <Row className='px-2'>
                    <Col xs={this.props.isBackButtonEnable ? 9 : 12}>
                        <div className="d-flex align-items-center justify-content-between py-3">
                            <div className="mb-0 font-size-16">{this.props.title}</div>
                            {/* <div className="page-title-right">
                                <Breadcrumb listClassName="m-0">
                                    <BreadcrumbItem>
                                        <Link to="#">{this.props.first}</Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <Link to="#">{this.props.title}</Link>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div> */}
                        </div>
                    </Col>
                    {
                        this.props.isBackButtonEnable &&
                        <Col xs={3} className='d-flex align-items-center justify-content-end'>
                            <button className='btn btn-outline-secondary btn-sm' color="primary" onClick={() => this.props.gotoBack()}>Back <i className="mdi mdi-arrow-left"></i></button>
                        </Col>

                    }
                </Row>
            </React.Fragment>

        );
    }
}

export default Breadcrumbs;