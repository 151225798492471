import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-drawer/lib/react-drawer.css";
import Drawer from "@mui/material/Drawer";
import Zoom from "@mui/material/Zoom";
import Select from "react-select";
import { Link } from "react-router-dom";
import { CompactPicker, TwitterPicker } from "react-color";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-drawer/lib/react-drawer.css";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, CardBody, Card, UncontrolledTooltip } from "reactstrap";
import urlSocket from "../../../helpers/urlSocket";
import { updateLayoutData,updateLayoutInfo,retriveClnKeys,retriveClnPrimaryValue, retrivePieChartvalue,retriveCardChartValue } from "../../../store/reportd3/reportslice";

const SidePanel = (props) => {
  const dispatch = useDispatch();
  var isOpen = props.isOpen;
  var onClose = props.onClose;
  var dbCollections = props.dbCollections;
  var db_data = props.db_data;
  var updateLayout = props.updateLayout;
  var show_table_function = props.show_table_function;
  const blockData = props.data;
  const [prefered_selection, setprefered_selection] = useState([
    { name: "SUM", value: "Sum" },
    { name: "AVG", value: "Average" },
    { name: "COUNT", value: "Total Count" },
    { name: "MIN", value: "Minimum" },
    { name: "MAX", value: "Maximum" },
  ]);
  const [selectedCalc, setSelectedCalc] = useState({});
  const [dbInfo, setDbinfo] = useState(JSON.parse(sessionStorage.getItem("db_info")));
  const [primaryKey, setprimaryKey] = useState([]);
  const [primaryKeyYaxis, setprimaryKeyYaxis] = useState([]);
  const [primaryKeyValue, setprimaryKeyValue] = useState([]);
  const [XaxisValue, setXaxisValue] = useState([]);
  const [selectedXaxisKey, setselectedXaxisKey] = useState("");
  const [selectedYaxisKey, setselectedYaxisKey] = useState({});
  const [YaxisValue, setYaxisValue] = useState([]);
  const [selectedPrimaryKey, setselectedPrimaryKey] = useState({});
  const [selectedPrimaryValue, setselectedPrimaryValue] = useState({});
  const [seletedClnName, setseletedClnName] = useState({});
  const [xaxislabel, setxaxislabel] = useState("");
  const [yaxislabel, setyaxislabel] = useState("");
  const [addTransitiondata, setAddTransitiondata] = useState(0);
  const [numAdditionalAxes, setNumAdditionalAxes] = useState(0);
  const [colorArr, setColorArr] = useState([]);
  const [deletecard_index, setdeletecard_index] = useState(0);
  const [additionalYLabels, setAdditionalYLabels] = useState([]);
  const [yAxisArr, setYAxisArr] = useState([]);
  const [mergedArr, setmergedArr] = useState([]);
  const [combinedArr, setcombinedArr] = useState([]);
  const [yAxisarrMod, setYaxisarrMod] = useState([]);
  const [pieData, setpieData] = useState([]);
  const [cardData, setcardData] = useState([]);
  const [showMouseoverMulti, setShowMouseoverMulti] = useState(false);
  const [showMouseoverMain, setShowMouseoverMain] = useState(false);
  const [dataLoaded, setdataLoaded] = useState(false);
  const [showvalues, setshowvalues] = useState(false);
  const [showLine, setshowLine] = useState(false);
  const [showTable, setshowTable] = useState(false);
  const [show_grid_toggle, setshow_grid_toggle] = useState(false);
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [fontSize, setFontSize] = useState(16);
  const [fontColor, setFontColor] = useState("#000000");
  const [labelname_arr, setlabelname_arr] = useState([]);
  const [showSquare, setshowSquare] = useState(false);
  const [showWarningCard, setshowWarningCard] = useState(false);
  const [barwidth, setBarWidth] = useState(150);
  const [cardFielderr, setCardFielderr] = useState([])
  const [textClrarr, setTextClrarr] = useState([]);
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [formattedValue, setFormattedValue] = useState("");
  const [tempselectedYaxisKey, settempselectedYaxisKey] = useState({});
  const [chartName, setChartName] = useState("");
  const [decimalDigitsCount, setdecimalDigitsCount] = useState();
  const [cardDatavalue, setCardDataValue] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [preferedSelected, setpreferedSelected] = useState(false);
  const [errors, setErrors] = useState(yAxisArr?.map(() => ({ error: false })));
  const reportSlice = useSelector((state) => state.reportSliceReducer);
  const layout =reportSlice.layoutInfo


  const selectCollection = (val) => {
    console.log(val,'val')
    try {
      getFieldsName(val);
    } catch (error) {
      console.log("something went wrong", error);
    }
  };

  var clone_lay = [...layout];
  var updating_layObj = { ...clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] };
  clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] = updating_layObj;

  useEffect(()=>{
     if(props.data.selected_cln_name !== undefined){
      selectCollection(props.data.selected_cln_name)
    }
  },[])

  useEffect(() => {
    // setlayout(LayoutArray?.layoutInfo);
    console.log("use Effect",props)
    // if(props.data.selected_cln_name !== undefined){
    //   selectCollection(props.data.selected_cln_name)
    // }
    // if()
  }, [layout]);

  useEffect(() => {
    if (blockData.selected_primary_key !== undefined) {
      setseletedClnName(blockData.selected_cln_name);
      selectPrimaryKey(blockData.selected_primary_key);
      selectCollection(blockData.selected_cln_name);
      setselectedPrimaryKey(blockData.selected_primary_key);
      setselectedPrimaryValue(blockData.selected_primary_value);
      settempselectedYaxisKey(blockData.x_axis_key);
      setShowMouseoverMain(blockData.mouseovered);
      setxaxislabel(blockData.x_axis_label);
      setyaxislabel(blockData.y_axis_label);
      setChartName(blockData.chart_name);
      setBarWidth(blockData.barWidth)
      if (
        blockData.name === "stack_bar" ||
        blockData.name === "line_chart" ||
        blockData.name === "area_chart" ||
        blockData.name === "hor_stack" ||
        blockData.name === "Vertical_linechart"
      ) {
        setselectedXaxisKey(blockData.x_axis_key);
        setAddTransitiondata(blockData.add_transition_data);
        setNumAdditionalAxes(blockData.num_add_axes);
        setselectedYaxisKey(blockData.y_axis_key);
        setcombinedArr(blockData.combined_arr || []);
        setYAxisArr(blockData.yAxis_arr || []);
        setmergedArr(blockData.merged_arr || []);
        setXaxisValue(blockData.X_axis_value);
        setShowMouseoverMulti(blockData.mouseovered_type);
        setshowvalues(blockData.show_bar_values);
        setshowLine(blockData.show_Line);
        setshowSquare(blockData.show_Square);
        setshowTable(blockData.show_table);
        setshow_grid_toggle(blockData.show_Grid);
        setlabelname_arr(blockData.label_arr_data || []);
        const colorArray = (blockData?.chart_customize_clr_arr || []).map((color) =>
          color !== null ? color : generateRandomColor()
        );
        setColorArr(colorArray);
        setdataLoaded(true);
      } else if (blockData.name === "rectangle_card") {
        setselectedXaxisKey(blockData.x_axis_key);
        setXaxisValue(blockData.x_axis_key);
        selectcard_field_name(blockData.x_axis_key, "1");
        setSelectedCalc(blockData.prefrd_calc);
        setpreferedSelected(blockData.prefrd_calc ? true : false);
        setFormattedValue(blockData.count);
        setdecimalDigitsCount(blockData.decimal_count);
        setdataLoaded(true);
      } else if (blockData.name === "pie_chart") {
        setselectedPrimaryValue(blockData.selected_primary_value);
        setselectedXaxisKey(blockData.x_axis_key);
        setdataLoaded(true);
      } else if (blockData.name === "table") {
        setselectedXaxisKey(blockData.x_axis_key);
        setAddTransitiondata(blockData.add_transition_data);
        setNumAdditionalAxes(blockData.num_add_axes);
        setselectedYaxisKey(blockData.y_axis_key);
        setcombinedArr(blockData.combined_arr || []);
        setYAxisArr(blockData.yAxis_arr);
        setmergedArr(blockData.merged_arr || []);
        setXaxisValue(blockData.X_axis_value);
        setlabelname_arr(blockData.label_arr_data);
        setdataLoaded(true);
      } else {
        setselectedYaxisKey(blockData.y_axis_key);
        setseletedClnName(blockData.selected_cln_name);
        selectPrimaryKey(blockData.selected_primary_key);
        selectCollection(blockData.selected_cln_name);
        setselectedPrimaryKey(blockData.selected_primary_key);
        setselectedPrimaryValue(blockData.selected_primary_value);
        setXaxisValue(blockData.X_axis_value);
        selectXaxis(blockData.x_axis_key, "1");
        setshowvalues(blockData.show_bar_values);
        setshowTable(blockData.show_table);
        selectXaxis(blockData.y_axis_key, "2");
        setshowLine(blockData.show_Line);
        setdataLoaded(true);
      }
    } else {
      setseletedClnName({});
      setselectedPrimaryKey({});
      setselectedPrimaryValue({});
      setselectedXaxisKey("");
      setAddTransitiondata(0);
      setNumAdditionalAxes(0);
      setselectedYaxisKey({});
      setYAxisArr([]);
      setmergedArr([]);
      setcombinedArr([]);
      setXaxisValue([]);
      setshowLine(blockData?.show_Line);
      setshowSquare(blockData?.show_Square);
      setshowLine(blockData.show_Line);
      setshowSquare(blockData.show_Square);
      setShowMouseoverMain(blockData.mouseovered);
      setdataLoaded(true);
    }
  }, [blockData]);

  useEffect(() => {
    try {
      if (mergedArr?.length !== 0) {
        setcombinedArr((prev) => {
          const newState_clone = Array.isArray(prev) ? [...prev] : [prev];
          mergedArr?.forEach((innerArr, index) => {
            innerArr.forEach((item) => {
              const fieldName = `value${index + 1}`;
              const existingItemIndex = newState_clone.findIndex((stateItem) => stateItem._id === item._id);
              if (existingItemIndex !== -1) {
                const updatedItem = { ...newState_clone[existingItemIndex] };
                updatedItem[fieldName] = item.value;
                newState_clone[existingItemIndex] = updatedItem;
              } else {
                newState_clone.push({
                  _id: item._id,
                  [fieldName]: item.value,
                });
              }
            });
          });
          const intersectedArray = _.intersectionBy(XaxisValue, newState_clone, "_id").map(({ _id }) => {
            const yearObject = _.find(XaxisValue, { _id });
            const valueObject = _.find(newState_clone, { _id });
            const values = {};
            Object.keys(valueObject).forEach((key) => {
              if (key.startsWith("value")) {
                const index = parseInt(key.replace("value", ""), 10);
                values[`value${index}`] = valueObject[key];
              }
            });
            return { _id, year: yearObject.year, ...values };
          });

          if (intersectedArray.length > 0) {
            updating_layObj.data = intersectedArray;
            updating_layObj.selected_cln_name = seletedClnName;
            updating_layObj.selected_primary_key = selectedPrimaryKey;
            updating_layObj.selected_primary_value = selectedPrimaryValue;
            updating_layObj.x_axis_key = selectedXaxisKey;
            updating_layObj.x_axis_label = xaxislabel;
            updating_layObj.add_transition_data = addTransitiondata;
            updating_layObj.num_add_axes = numAdditionalAxes;
            updating_layObj.yAxis_arr = yAxisArr;
            updating_layObj.combined_arr = newState_clone;
            updating_layObj.merged_arr = mergedArr;
            updating_layObj.X_axis_value = XaxisValue;
            updating_layObj.chart_customize_clr_arr = colorArr;
            updating_layObj.labelname_arr = labelname_arr;
            updating_layObj.barWidth = barwidth;
            dispatch(updateLayoutInfo(clone_lay));
            dispatch(updateLayoutData(clone_lay, db_data));
            return newState_clone;
          }
        });
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  }, [mergedArr]);

  useEffect(() => {
    try {
      if (XaxisValue.length !== 0 && YaxisValue !== 0) {
        console.log(YaxisValue,'YaxisValue')
        const intersectedArray = _.intersectionBy(XaxisValue, YaxisValue, "_id").map(({ _id }) => {
          console.log(_id,'_id')
          const yearObject = _.find(XaxisValue, { _id });
          const valueObject = _.find(YaxisValue, { _id });
          return { _id, year: yearObject.year, value: valueObject.value };
        });
        console.log(intersectedArray,'intersectedArray')
        updating_layObj.data = intersectedArray;
        updating_layObj.selected_cln_name = seletedClnName;
        updating_layObj.selected_primary_key = selectedPrimaryKey;
        updating_layObj.selected_primary_value = selectedPrimaryValue;
        updating_layObj.x_axis_key = selectedXaxisKey;
        updating_layObj.y_axis_key = selectedYaxisKey;
        updating_layObj.x_axis_label = xaxislabel;
        updating_layObj.X_axis_value = XaxisValue;
        updating_layObj.barWidth = barwidth;
        dispatch(updateLayoutInfo(clone_lay));
        dispatch(updateLayoutData(clone_lay, db_data));
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  }, [yAxisarrMod]);

  useEffect(() => {
    try {
      if (seletedClnName.cln_name !== undefined) {
        updating_layObj.selected_cln_name = seletedClnName;
        updating_layObj.selected_primary_key = selectedPrimaryKey;
        updating_layObj.selected_primary_value = selectedPrimaryValue;
        updating_layObj.x_axis_key = selectedXaxisKey;
        updating_layObj.y_axis_key = selectedYaxisKey;
        const data ={
          selected_cln_name: seletedClnName,
          selected_primary_key: selectedPrimaryKey,
          selected_primary_value: selectedPrimaryValue,
          fieldName: selectedXaxisKey,
          encrypted_db_url: dbInfo.encrypted_db_url,
          db_name: dbInfo.db_name,
        }
        dispatch(retrivePieChartvalue(data,clone_lay,db_data))
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  }, [pieData]);

  useEffect(() => {
    try {
      if (seletedClnName.cln_name !== undefined) {
        var updating_layObj={
          ...updating_layObj
        }
        updating_layObj.selected_cln_name = seletedClnName;
        updating_layObj.selected_primary_key = selectedPrimaryKey;
        updating_layObj.selected_primary_value = selectedPrimaryValue;
        updating_layObj.x_axis_key = selectedXaxisKey;
        updating_layObj.y_axis_key = selectedYaxisKey;
        console.log(updating_layObj,'updating_layObj')
        const data ={
          selected_cln_name: seletedClnName,
          selected_primary_key: selectedPrimaryKey,
          selected_primary_value: selectedPrimaryValue,
          fieldName: selectedXaxisKey,
          encrypted_db_url: dbInfo.encrypted_db_url,
          db_name: dbInfo.db_name,

        }
          dispatch(retriveCardChartValue(data,clone_lay,selectedCalc,db_data))
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  }, [cardData]);

  const decimal_finder = async (value) => {
    setCardDataValue(value);
    var check = await isDecimal(value);
    if (check) {
      setFormattedValue(value);
    } else {
      const DigitsCounted = (value?.toString().split(".")[1] || "").length;
      setdecimalDigitsCount(DigitsCounted);
      setFormattedValue(value);
    }
  };

  const isDecimal = async (value) => {
    if (value !== undefined && value !== null && value !== "") {
      const stringValue = value?.toString();
      return /^\d+$/.test(stringValue);
    } else {
      return true;
    }
  };

  const getFieldsName = (value) => {
    return new Promise(async(resolve, reject) => {
      if (value !== undefined) {
        try {
        
          var response = await dispatch(retriveClnKeys(value))
              if (response.status === 200) {
                setprimaryKey(response.data.data);
                setprimaryKeyYaxis(response.data.y_keys);
                setseletedClnName(value);
                let updating_layObj_clone = { ...updating_layObj };
                updating_layObj_clone.selected_cln_name = value;
                dispatch(updateLayoutInfo(clone_lay));
                dispatch(updateLayoutData(clone_lay, db_data));
                resolve(response.data);
              } else {
                reject(new Error(`Request failed with status: ${response.status}`));
              }
        } catch (error) { }
      }
    });
  };

  const selectPrimaryKey = async(value) => {
    try {
      var collection_name =
        seletedClnName !== undefined && seletedClnName.cln_name !== undefined
          ? seletedClnName
          : blockData.selected_cln_name;
      if (collection_name) {
        const data ={
          collection_name: collection_name.cln_name,
          encrypted_db_url: dbInfo.encrypted_db_url,
          db_name: dbInfo.db_name,
          primary_key: value.name,
          mode: "0",
        }
        console.log(data,'data')
        var response = await dispatch(retriveClnPrimaryValue(data))
        console.log(response,'response')
            if (response.status === 200) {
              setprimaryKeyValue(response.data.data);
              setselectedPrimaryKey(value);
              let updating_layObj_clone = { ...updating_layObj };
              updating_layObj_clone.selected_primary_key = value;
              dispatch(updateLayoutInfo(clone_lay));
              dispatch(updateLayoutData(clone_lay, db_data));
            }
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const selectPrimaryValues = (e) => {
    setselectedPrimaryValue(e);
    updating_layObj.selected_primary_value = e;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const selectXaxis = async (value, mode) => {
    console.log(value, mode,'value, mode',selectedPrimaryKey,)
    try {
      if (mode === "2") {
        updating_layObj.charts_loaded = false;
        updating_layObj.chart_progress_count = null;
        dispatch(updateLayoutInfo(clone_lay));
      } else {
        updating_layObj.charts_loaded = true;
        dispatch(updateLayoutInfo(clone_lay));
      }
      if (seletedClnName !== undefined ) {
        const data ={
          collection_name: seletedClnName.cln_name,
          encrypted_db_url: dbInfo.encrypted_db_url,
          db_name: dbInfo.db_name,
          primary_key: selectedPrimaryKey,
          selected_primary_key: value.name,
          selected_primary_value: selectedPrimaryValue,
          chart_position: mode,
          mode: "1",
        }
        console.log(data,'data')

       var response = await dispatch(retriveClnPrimaryValue(data))
       console.log(response,'response')
            if (response.status === 200) {
              if (mode === "1") {
                setXaxisValue(response.data.x_label);
                setselectedXaxisKey(value);
              }
              if (mode === "2") {
                setYaxisValue(response.data.x_label);
                setselectedYaxisKey(value);
                setYaxisarrMod(value);
                updating_layObj = { ...updating_layObj };
                updating_layObj.charts_loaded = true;
                var clone_lay_mod = [...clone_lay];
                clone_lay_mod[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] = updating_layObj;
                dispatch(updateLayoutInfo(clone_lay_mod));
                dispatch(updateLayoutData(clone_lay, db_data));
              }
            }
          // });
      }
    } catch (error) { }
  };

  const handleColorChange = (event) => {
    updating_layObj.chart_customize_clr = event.hex;
    clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] = updating_layObj;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };


  const handletext_clr = async (event, i) => {
    updating_layObj.text_customize_clr = event.target.value;
    clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] = updating_layObj;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  //STACK CHART CONFIG PORTION************************!SECTION
  const setYAxisArrValue = (newValue, index) => {
    if (yAxisArr?.length > 0) {
      setCardFielderr((prevArr) => {
        const updated_AxisArr = [...prevArr];
        updated_AxisArr[index] = newValue;
        return updated_AxisArr;
      })

      setYAxisArr((prevArr) => {
        const updated_AxisArr = [...prevArr];
        updated_AxisArr[index] = newValue;
        return updated_AxisArr;
      });
    } else {
      setYAxisArr((prevArr) => {
        const updated_AxisArr = [...prevArr];
        updated_AxisArr[index] = newValue;
        return updated_AxisArr;
      });

      setCardFielderr((prevArr) => {
        const updated_AxisArr = [...prevArr];
        updated_AxisArr[index] = newValue;
        return updated_AxisArr;
      })
    }

    if (cardFielderr?.[index] !== undefined) {
      const updatedErrors = [...errors];
      updatedErrors[index] = { ...updatedErrors[index], error: false };
      setCardFielderr(updatedErrors)

    } else {
      const updatedErrors = [...errors];
      updatedErrors[index] = { ...updatedErrors[index], error: true };
      setCardFielderr(updatedErrors)
    }
  };

  const selectXaxis_arr = async (value, mode, indx) => {
    try {
      if (seletedClnName !== undefined && selectedPrimaryValue !== undefined && selectedPrimaryKey !== undefined) {
        const data ={
          collection_name: seletedClnName.cln_name,
          encrypted_db_url: dbInfo.encrypted_db_url,
          db_name: dbInfo.db_name,
          primary_key: selectedPrimaryKey,
          selected_primary_key: value.name,
          selected_primary_value: selectedPrimaryValue,
          chart_position: mode,
          mode: "1",
        }
        var response = await dispatch(retriveClnPrimaryValue(data))
            if (response.status === 200) {
              if (mode === "1") {
                setXaxisValue(response.data.x_label);
                setselectedXaxisKey(value);
                updating_layObj.x_axis_key = value;
                updating_layObj.X_axis_value = response.data.x_label;
                dispatch(updateLayoutInfo(clone_lay));
                dispatch(updateLayoutData(clone_lay, db_data));
              }
              if (mode === "2") {
                setYaxisValue(response.data.x_label);
                setselectedYaxisKey(value);
                setYAxisArrValue(value.name, indx);
                let data_modified = response.data.x_label;
                var AutoColr_arr;
                AutoColr_arr = await generateRandomColor(indx + 1);
                setColorArr((prevColorArr) => {
                  const blockIdx = Number(JSON.parse(sessionStorage.getItem("blockIdx")));
                  if (!isNaN(blockIdx) && typeof clone_lay[blockIdx] === "object" && clone_lay[blockIdx] !== null) {
                    let updating_layObj_clone = { ...clone_lay[blockIdx] };
                    let updatedColorArr = [...prevColorArr];
                    updatedColorArr[indx] = AutoColr_arr;
                    if (updating_layObj_clone.chart_customize_clr_arr !== undefined) {
                      if (AutoColr_arr !== updating_layObj_clone.chart_customize_clr_arr[indx]) {
                        updating_layObj_clone.chart_customize_clr_arr = [...updatedColorArr];
                      }
                    } else {
                      updating_layObj_clone.chart_customize_clr_arr = updatedColorArr;
                    }
                    if (Object.isFrozen(clone_lay)) {
                      clone_lay = [...clone_lay];
                    }
                    clone_lay[blockIdx] = updating_layObj_clone;
                    dispatch(updateLayoutInfo(clone_lay));
                    dispatch(updateLayoutData(clone_lay, db_data));
                    return updatedColorArr;
                  } else {
                    console.error("Invalid blockIdx or clone_lay[blockIdx] is not an object.");
                    return prevColorArr;
                  }
                });

                setmergedArr((prevArr) => {
                  const updatedArr = [...prevArr];
                  updatedArr[indx] = data_modified;
                  return updatedArr;
                });
              }
              setShowWarning(false);
            }
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const onselectField = (err, i) => {
    if (yAxisArr?.[i] !== undefined) {
      const updatedErrors1 = [...errors];
      updatedErrors1[i] = { ...updatedErrors1[i], error: false };
      setErrors(updatedErrors1);
    } else {
      const updatedErrors = [...errors];
      updatedErrors[i] = { ...updatedErrors[i], error: true };
      setErrors(updatedErrors);
    }
    if (cardFielderr?.[i] !== undefined) {
      const updatedErrors = [...errors];
      updatedErrors[i] = { ...updatedErrors[i], error: false };
      setCardFielderr(updatedErrors)
    } else {
      const updatedErrors = [...errors];
      updatedErrors[i] = { ...updatedErrors[i], error: true };
      setCardFielderr(updatedErrors)
    }
  };
  const onClickDelete = (e, index) => {
    setAddTransitiondata(addTransitiondata - 1);
    setdeletecard_index(index);
    delete_card(index);
  };

  const handleColorChange_arr = async (event, i) => {
    const blockIdx = Number(JSON.parse(sessionStorage.getItem("blockIdx")));
    setColorArr((prevColorArr) => {
      if (!isNaN(blockIdx) && typeof clone_lay[blockIdx] === "object" && clone_lay[blockIdx] !== null) {
        let updating_layObj_clone = { ...clone_lay[blockIdx] };
        let updatedColorArr = [...prevColorArr];
        updatedColorArr[i] = event.hex;
        if (updating_layObj_clone.chart_customize_clr_arr !== undefined) {
          if (event.hex === updating_layObj_clone.chart_customize_clr_arr[i]) {
          } else {
            updating_layObj_clone.chart_customize_clr_arr = [...updatedColorArr];
            if (Object.isFrozen(clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))])) {
              clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] = [
                ...clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))],
              ];
            }
            clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] = updating_layObj_clone;
          }
        } else {
          updating_layObj_clone.chart_customize_clr_arr = [...updatedColorArr];
          clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] = updating_layObj_clone;
        }
        return updatedColorArr;
      } else {
        return prevColorArr;
      }
    });
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const handletext_clr_arr = async (event, i) => {
    var updatedColorArr = [];
    setTextClrarr((prevColorArr) => {
      updatedColorArr = [...prevColorArr];
      updatedColorArr[i] = event.target.value;
      updating_layObj.text_customize_clr_arr = updatedColorArr
      clone_lay[Number(JSON.parse(sessionStorage.getItem('blockIdx')))] = updating_layObj;
      dispatch(updateLayoutInfo(clone_lay));
      dispatch(updateLayoutData(clone_lay, db_data));
      return updatedColorArr;
    });
  };

  const add_axis_data = (e) => {
    if (selectedXaxisKey === "" || selectedXaxisKey === undefined) {
      setShowWarning(true);
    } else if (yAxisArr?.length > 0) {
      if (labelname_arr.length - 1 === yAxisArr?.length) {
        setShowWarning(false);
        setAddTransitiondata(addTransitiondata + 1);
        setNumAdditionalAxes(numAdditionalAxes + 1);
        getFieldsName(blockData.selected_cln_name);
      } else {
        setShowWarning(true);
      }
    } else {
      setShowWarning(false);
      setAddTransitiondata(addTransitiondata + 1);
      setNumAdditionalAxes(numAdditionalAxes + 1);
      getFieldsName(blockData.selected_cln_name);
    }
  };

  const delete_card = async (remove_index) => {
    var updatedArray;
    if (mergedArr.length === 0) {
      if (blockData.selected_primary_value !== undefined) {
        let mergedArr_mod = layout[Number(JSON.parse(sessionStorage.getItem("blockIdx")))]["merged_arr"];
        updatedArray = removeItemByIndex(mergedArr_mod, remove_index);
      }
    } else {
      updatedArray = removeItemByIndex(mergedArr, remove_index);
    }
    const blockIdx = Number(JSON.parse(sessionStorage.getItem("blockIdx")));
    const labelarr = removeItemByIndex(additionalYLabels, remove_index + 1);
    const labelname_mod = removeItemByIndex(labelname_arr, remove_index + 1);
    const colorarr_modified = removeItemByIndex(colorArr, remove_index);
    const mod_yAxisarr = removeItemByIndex(yAxisArr, remove_index);
    setNumAdditionalAxes((prev) => {
      var removed_Axes = prev - 1
      var updtObj = { ...clone_lay[blockIdx] };
      updtObj.num_add_axes = numAdditionalAxes - 1;
      updtObj.add_transition_data = addTransitiondata - 1;
      updtObj.yAxis_arr = mod_yAxisarr
      updtObj.label_arr_data = labelname_mod;
      var updatedLayout = [...clone_lay];
      updatedLayout[blockIdx] = updtObj;
      dispatch(updateLayoutInfo(updatedLayout));
      dispatch(updateLayoutData(updatedLayout, db_data));
      return removed_Axes;
    });

    setlabelname_arr((prevColorArr = []) => {
      var updatedLabel = [...prevColorArr];
      updatedLabel = labelname_mod;
      updating_layObj.label_arr_data = updatedLabel;
      dispatch(updateLayoutInfo(clone_lay));
      dispatch(updateLayoutData(clone_lay, db_data));
      return labelname_mod;
    });

    setColorArr((prevColorArr) => {
      if (!Array.isArray(prevColorArr)) {
        prevColorArr = [];
      }
      const updatedColorArr = colorarr_modified;
      var updtObj = { ...clone_lay[blockIdx] };
      updtObj.chart_customize_clr_arr = updatedColorArr;
      var updatedLayout = [...clone_lay];
      updatedLayout[blockIdx] = updtObj;
      dispatch(updateLayoutInfo(updatedLayout));
      dispatch(updateLayoutData(updatedLayout, db_data));
      return updatedColorArr;
    });
    setcombinedArr([]);
    setYAxisArr(mod_yAxisarr);
    setAdditionalYLabels(labelarr);
    setdataLoaded(false)
    setTimeout(() => {
      setdataLoaded(true)
      setmergedArr(updatedArray);
    }, 50);
  };

  const removeItemByIndex = (dataArray, indexToRemove) => {
    if (indexToRemove >= 0 && indexToRemove < dataArray.length) {
      const newArray = [...dataArray];
      newArray.splice(indexToRemove, 1);
      return newArray;
    } else {
      return dataArray;
    }
  };

  const selectXaxis_pie_chart = async(value, mode) => {
    try {
        const data ={
          collection_name: seletedClnName.cln_name,
          encrypted_db_url: dbInfo.encrypted_db_url,
              db_name: dbInfo.db_name,
              primary_key: selectedPrimaryKey,
              selected_primary_key: value.name,
              selected_primary_value: selectedPrimaryValue,
              chart_position: mode,
            mode: "1",
        }
      var response = await dispatch(retriveClnPrimaryValue(data))


          if (response.status === 200) {
            if (mode === "1") {
              setXaxisValue(response.data.x_label);
              setselectedXaxisKey(value);
              setpieData(value);
            }
            if (mode === "2") {
              setYaxisValue(response.data.x_label);
              setselectedYaxisKey(value);
            }
          }
    } catch (error) { }
  };

  const curved_lines = (e) => {
    updating_layObj.curved_line_chrt = e.target.checked;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const mouseover_toggle = (e) => {
    const isChecked = e.target.checked;
    setShowMouseoverMain(isChecked);
    if (isChecked) {
      setShowMouseoverMulti(true);
      updating_layObj.mouseovered = e.target.checked;
      updating_layObj.mouseovered_type = !showMouseoverMulti;
      dispatch(updateLayoutInfo(clone_lay));
      dispatch(updateLayoutData(clone_lay, db_data));
    } else {
      setShowMouseoverMulti(false);
      updating_layObj.mouseovered = e.target.checked;
      updating_layObj.mouseovered_type = false;
      dispatch(updateLayoutInfo(clone_lay));
      dispatch(updateLayoutData(clone_lay, db_data));
    }
  };

  const mouseover_type = (e) => {
    setShowMouseoverMulti(!showMouseoverMulti);
    updating_layObj.mouseovered_type = e.target.checked;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const OnChangeWidth = (e) => {
    setBarWidth(e.target.value)
    updating_layObj.barWidth = e.target.value;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  }
  const show_bar_val = (e) => {
    setshowvalues(!showvalues);
    updating_layObj.show_bar_values = e.target.checked;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const show_line = (e) => {
    setshowLine(e.target.checked);
    updating_layObj.show_Line = e.target.checked;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const show_table = async (e) => {
    try {
      const blockIdx = Number(JSON.parse(sessionStorage.getItem("blockIdx")));
      const blockdata = Number(JSON.parse(sessionStorage.getItem("blockdata")));
      const updatedLayout = [...layout]; // Assuming layout is an array
      show_table_function(e, blockdata, blockIdx, layout);
    } catch (error) {
    }
  };

  const show_Grid = (e) => {
    setshow_grid_toggle(e.target.checked);
    updating_layObj.show_Grid = e.target.checked;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const handleFormatToggle = (format, val) => {
    if (val === "1") {
      switch (format) {
        case "bold":
          setBold(!bold);
          updating_layObj.label_bold = !bold;
          dispatch(updateLayoutInfo(clone_lay));
          dispatch(updateLayoutData(clone_lay, db_data));
          break;
        case "italic":
          setItalic(!italic);
          updating_layObj.label_italic = !italic;
          dispatch(updateLayoutInfo(clone_lay));
          dispatch(updateLayoutData(clone_lay, db_data));
          break;
        case "underline":
          setUnderline(!underline);
          updating_layObj.label_underline = !underline;
          dispatch(updateLayoutInfo(clone_lay));
          dispatch(updateLayoutData(clone_lay, db_data));
          break;
        default:
          break;
      }
    } else {
      switch (format) {
        case "bold":
          setBold(!bold);
          updating_layObj.value_bold = !bold;
          dispatch(updateLayoutInfo(clone_lay));
          dispatch(updateLayoutData(clone_lay, db_data));
          break;
        case "italic":
          setItalic(!italic);
          updating_layObj.value_italic = !italic;
          dispatch(updateLayoutInfo(clone_lay));
          dispatch(updateLayoutData(clone_lay, db_data));
          break;
        case "underline":
          setUnderline(!underline);
          updating_layObj.value_underline = !underline;
          dispatch(updateLayoutInfo(clone_lay));
          dispatch(updateLayoutData(clone_lay, db_data));
          break;
        default:
          break;
      }
    }
  };

  const label_mod = (e) => {
    updating_layObj.text = e.target.value;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const handleFontSizeChange = (selectedSize, val) => {
    setFontSize(selectedSize);
    if (val === "1") {
      updating_layObj.label_fontsize = selectedSize;
      dispatch(updateLayoutInfo(clone_lay));
      dispatch(updateLayoutData(clone_lay, db_data));
    } else {
      updating_layObj.value_fontsize = selectedSize;
      dispatch(updateLayoutInfo(clone_lay));
      dispatch(updateLayoutData(clone_lay, db_data));
    }
  };

  const handleFontColorChange = (selectedColor, val) => {
    setFontColor(selectedColor);
    if (val === "1") {
      updating_layObj.label_fontColor = selectedColor;
      dispatch(updateLayoutInfo(clone_lay));
      dispatch(updateLayoutData(clone_lay, db_data));
    } else {
      updating_layObj.value_fontColor = selectedColor;
      dispatch(updateLayoutInfo(clone_lay));
      dispatch(updateLayoutData(clone_lay, db_data));
    }
  };

  const selectcard_field_name =async (value, mode) => {
    setshowWarningCard(false);
    settempselectedYaxisKey(value);
    try {
      const data ={
        collection_name: seletedClnName.cln_name,
        encrypted_db_url: dbInfo.encrypted_db_url,
        db_name: dbInfo.db_name,
        primary_key: selectedPrimaryKey,
        selected_primary_key: value.name,
        selected_primary_value: selectedPrimaryValue,
            chart_position: mode,
            mode: "1",

      }


      var response = await dispatch(retriveClnPrimaryValue(data))

          if (response.status === 200) {
            if (mode === "1") {
              setXaxisValue(response.data.x_label);
              setselectedXaxisKey(value);
              setTimeout(() => {
                setcardData(value);
                setpreferedSelected(true);
              }, 1000);
            }
          }
    } catch (error) { }
  };

  const onChangePrefered_Calc = (e) => {
    setSelectedCalc(e);
    setTimeout(() => {
      setcardData(e);
    }, 500);
  };

  const handleDecimalPlacesChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setdecimalDigitsCount(value);
    setDecimalPlaces(value);
    const formatted = formatDecimal(cardDatavalue, value);
    setFormattedValue(formatted);
    updating_layObj.count = formatted;
    updating_layObj.decimal_count = value;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const formatDecimal = (value, places) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return parsedValue.toFixed(places);
    }
    return "";
  };

  const InputLabelName = (e, indx) => {
    const newValue = e.target.value.trim();
    if (newValue !== "") {
      setlabelname_arr((prevColorArr = []) => {
        const updatedLabelArr = [...prevColorArr];
        updatedLabelArr[indx + 1] = newValue;
        const updatedObj = {
          ...updating_layObj,
          label_arr_data: updatedLabelArr,
        };
        const cloned = [...clone_lay];
        cloned[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] = updatedObj;
        dispatch(updateLayoutInfo(cloned));
        dispatch(updateLayoutData(cloned, db_data));
        return updatedLabelArr;
      });
    }
  };

  const Add_table_label = (e) => {
    const newValue = e.target.value.trim();
    setlabelname_arr((prevColorArr = []) => {
      const updatedLabelArr = [...prevColorArr];
      updatedLabelArr[0] = newValue;
      updating_layObj.label_arr_data = updatedLabelArr;
      dispatch(updateLayoutInfo(clone_lay));
      dispatch(updateLayoutData(clone_lay, db_data));
      return updatedLabelArr;
    });
  };
  const Addaxislabel = async (e, name) => {
    const newValue = e.target.value.trim();
    if (name === "x") {
      updating_layObj.x_axis_label = newValue;
      setxaxislabel(newValue);
    } else {
      updating_layObj.y_axis_label = newValue;
      clone_lay[Number(JSON.parse(sessionStorage.getItem("blockIdx")))] = updating_layObj;
    }
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const show_square = (e) => {
    setshowSquare(!showSquare);
    updating_layObj.show_Square = e.target.checked;
    dispatch(updateLayoutInfo(clone_lay));
    dispatch(updateLayoutData(clone_lay, db_data));
  };

  const sidepanelclose = () => {
    updating_layObj.selected_primary_key = selectedPrimaryKey;
    updating_layObj.selected_primary_value = selectedPrimaryValue;
    updating_layObj.X_axis_value = XaxisValue;
    updateLayout(layout);
    if (yAxisArr?.length > 0) {
      if (labelname_arr.length - 1 === yAxisArr?.length) {
        setShowWarning(false);
        updating_layObj.num_add_axes = numAdditionalAxes;
        updating_layObj.x_axis_key = selectedXaxisKey;
        updating_layObj.add_transition_data = addTransitiondata;
        updating_layObj.yAxis_arr = yAxisArr;
        updating_layObj.merged_arr = mergedArr;
        updating_layObj.x_axis_label = xaxislabel;
        updating_layObj.label_arr_data = labelname_arr;
        dispatch(updateLayoutData(clone_lay, db_data));
        onClose();
      } else {
        setShowWarning(true);
      }
    } else {
      onClose();
    }
  };

  const generateRandomColor = async (indx) => {
    const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16); // Generate random hex color
    return randomColor;
  };

  return (
    dataLoaded && (
      <>
        <Drawer
          open={isOpen}
          onClose={sidepanelclose}
          anchor="right"
          transitionDuration={500}
          TransitionComponent={Zoom}
          style={{ width: "900px", maxwidth: "900px" }}
        >
          <div style={{ padding: "20px", width: "900px" }}>
            <h2>Side Panel</h2>
            <button
              onClick={(e) => sidepanelclose()}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>

            <>
              <Row className="mt-2">
                <Col md={10}>
                  <Card style={{ borderRadius: "13px" }} className="preClassCard">
                    {props.data.name === "rectangle_card" ? (
                      <CardBody>
                        <>
                          {/* <Row>
                            <Col md={12}>
                              <h6>
                                <b>Choose Collection:</b>
                              </h6>
                              <Select
                                classNamePrefix="react-select"
                                showSearch
                                options={dbCollections}
                                getOptionLabel={(option) => option.user_friendly_name}
                                onChange={(e) => {
                                  selectCollection(e);
                                }}
                                value={blockData.selected_cln_name}
                              />
                            </Col>
                          </Row> */}
                          {/* <Row className="mt-3">
                            <Col md={12}>
                              <h6>
                                <b>Choose PrimaryKey:</b>
                              </h6>
                              <Select
                                classNamePrefix="react-select"
                                options={primaryKey}
                                getOptionLabel={(option) => option.name}
                                onChange={(e) => selectPrimaryKey(e)}
                                value={selectedPrimaryKey}
                                isDisabled={seletedClnName?.cln_name !== undefined ? false : true}
                              />
                            </Col>
                          </Row> */}
                          {/* <Row className="mt-3">
                            <Col md={12}>
                              <h6>
                                <b>Choose Primary Value:</b>
                              </h6>
                              <Select
                                classNamePrefix="react-select"
                                options={primaryKeyValue}
                                getOptionLabel={(option) => option.value}
                                onChange={(e) => {
                                  setselectedPrimaryValue(e);
                                }}
                                value={blockData.selected_primary_value}
                                isDisabled={selectedPrimaryKey?.name !== undefined ? false : true}
                              />
                            </Col>
                          </Row> */}

                          <Row className="mt-3">
                            <Col md={12}>
                              <h6>
                                <b>Field name1:</b>
                              </h6>
                              <Select
                                classNamePrefix="react-select"
                                options={primaryKey}
                                getOptionLabel={(option) => option.name}
                                onChange={(e) => {
                                  selectcard_field_name(e, "1");
                                }}
                                value={tempselectedYaxisKey}
                                // isDisabled={selectedPrimaryValue?.value !== undefined ? false : true}
                              />
                              {showWarningCard && (
                                <span style={{ color: "red" }}>Please Re-select the Correct fields</span>
                              )}
                            </Col>
                          </Row>
                          <br />
                          {preferedSelected && (
                            <Row className="mt-3">
                              <Col md={12}>
                                <h6>
                                  <b>Prefered :</b>
                                </h6>
                                <Select
                                  classNamePrefix="react-select"
                                  options={prefered_selection}
                                  getOptionLabel={(option) => option.value}
                                  onChange={(e) => {
                                    onChangePrefered_Calc(e);
                                  }}
                                  value={
                                    Object.keys(selectedCalc).length === 0
                                      ? { name: "COUNT", value: "Total Count" }
                                      : selectedCalc
                                  }
                                />
                              </Col>
                            </Row>
                          )}
                          {Object.keys(selectedCalc).length > 0 ? (
                            <Row className="mt-4">
                              <Col md={6}>
                                <h6>
                                  <b>Number of Decimal Places:</b>
                                </h6>
                                <div>
                                  <input
                                    type="number"
                                    value={decimalDigitsCount}
                                    min={0}
                                    onChange={handleDecimalPlacesChange}
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <h6>Formated value : {formattedValue}</h6>
                              </Col>
                            </Row>
                          ) : null}
                          <br />
                          <Row className="mt-3">
                            <Col>
                              <h6>
                                <b>Text Label:</b>
                              </h6>
                              <Input
                                type="text"
                                placeholder="Enter Bar Name"
                                name="bar"
                                defaultValue={props.data.text}
                                onChange={(e) => label_mod(e)}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <div className="my-2 d-flex gap-3">
                              <button
                                className={`btn ${bold ? "active btn-primary" : "btn-light"}`}
                                onClick={() => handleFormatToggle("bold", "1")}
                              >
                                <b>B</b>
                              </button>
                              <button
                                className={`btn ${italic ? "active btn-success" : "btn-light"}`}
                                onClick={() => handleFormatToggle("italic", "1")}
                              >
                                <i>I</i>
                              </button>
                              <button
                                className={`btn ${underline ? "active btn-info" : "btn-light"}`}
                                onClick={() => handleFormatToggle("underline", "1")}
                              >
                                <u>U</u>
                              </button>
                            </div>
                            <div className="editor-content" contentEditable="true"></div>
                          </Row>
                          <Row>
                            <div className="font-size-controls">
                              <label htmlFor="fontSizeDropdown">Font Size:</label>
                              <select
                                id="fontSizeDropdown"
                                value={fontSize}
                                onChange={(e) => handleFontSizeChange(parseInt(e.target.value), "1")}
                              >
                                <option value={12}>12px</option>
                                <option value={16}>16px</option>
                                <option value={20}>20px</option>
                                <option value={24}>24px</option>
                              </select>
                            </div>
                          </Row>
                          <Row>
                            <div className="font-color-controls">
                              <label htmlFor="fontColorPicker">Font Color:</label>
                              <input
                                type="color"
                                id="fontColorPicker"
                                value={fontColor}
                                onChange={(e) => handleFontColorChange(e.target.value, "1")}
                              />
                            </div>
                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <h6>
                                <b>Count value :</b>
                              </h6>
                              <Input
                                type="text"
                                value={formattedValue}
                              />
                            </Col>
                          </Row>
                          <div className="my-2 d-flex gap-3">
                            <button
                              className={`btn ${bold ? "active btn-primary" : "btn-light"}`}
                              onClick={() => handleFormatToggle("bold", "2")}
                            >
                              <b>B</b>
                            </button>
                            <button
                              className={`btn ${italic ? "active btn-success" : "btn-light"}`}
                              onClick={() => handleFormatToggle("italic", "2")}
                            >
                              <i>I</i>
                            </button>
                            <button
                              className={`btn ${underline ? "active btn-info" : "btn-light"}`}
                              onClick={() => handleFormatToggle("underline", "2")}
                            >
                              <u>U</u>
                            </button>
                          </div>
                          <Row>
                            <div className="font-size-controls">
                              <label htmlFor="fontSizeDropdown">Font Size:</label>
                              <select
                                id="fontSizeDropdown"
                                value={fontSize}
                                onChange={(e) => handleFontSizeChange(parseInt(e.target.value), "2")}
                              >
                                <option value={12}>12px</option>
                                <option value={16}>16px</option>
                                <option value={20}>20px</option>
                                <option value={24}>24px</option>
                              </select>
                            </div>
                          </Row>
                          <Row>
                            <div className="font-color-controls">
                              <label htmlFor="fontColorPicker">Font Color:</label>
                              <input
                                type="color"
                                id="fontColorPicker"
                                value={fontColor}
                                onChange={(e) => handleFontColorChange(e.target.value)}
                              />
                            </div>
                          </Row>
                        </>
                      </CardBody>
                    ) : (
                      <CardBody>
                        <>
                          <Row className="mt-3">
                            <Col>
                              <h6>
                                <b>{props.data.name != "table" ? "Chart Name" : "Table Name"}:</b>
                              </h6>
                              <Input
                                type="text"
                                placeholder="Enter Chart Name"
                                name="bar"
                                maxLength={15}
                                defaultValue={chartName}
                                onChange={(e) => {
                                  setChartName(e.target.value);
                                  updating_layObj.chart_name = e.target.value;
                                  dispatch(updateLayoutInfo(clone_lay));
                                  dispatch(updateLayoutData(clone_lay, db_data));
                                }}
                              />
                            </Col>
                          </Row>
                          {/* <Row className="mt-3">
                            <Col md={12}>
                              <h6>
                                <b>Choose Collection:</b>
                              </h6>
                              <Select
                                classNamePrefix="react-select"
                                showSearch
                                options={dbCollections}
                                getOptionLabel={(option) => option.user_friendly_name}
                                onChange={(e) => {
                                  selectCollection(e);
                                }}
                                value={blockData.selected_cln_name}
                                isDisabled={false}
                              />
                            </Col>
                          </Row> */}
                          {/* <Row className="mt-3">
                            <Col md={12}>
                              <h6>
                                <b>Choose PrimaryKey:</b>
                              </h6>
                              <Select
                                classNamePrefix="react-select"
                                options={primaryKey}
                                getOptionLabel={(option) => option.name}
                                onChange={(e) => selectPrimaryKey(e)}
                                value={selectedPrimaryKey}
                                isDisabled={seletedClnName?.cln_name !== undefined ? false : true}
                              />
                            </Col>
                          </Row> */}
                          {/* <Row className="mt-3">
                            <Col md={12}>
                              <h6>
                                <b>Choose Primary Value:</b>
                              </h6>
                              <Select
                                classNamePrefix="react-select"
                                options={primaryKeyValue}
                                getOptionLabel={(option) => option.value}
                                onChange={(e) => {
                                  selectPrimaryValues(e);
                                }}
                                value={selectedPrimaryValue}
                                isDisabled={selectedPrimaryKey?.name !== undefined ? false : true}
                              />
                            </Col>
                          </Row> */}
                          {props.data.name !== "pie_chart" ? (
                            <>
                              <Row className="mt-3">
                                <Col md={12}>
                                  <h6>
                                    <b>{props.data.name === "table" ? "Choose Table Column" : "X Axis field"}:</b>
                                  </h6>
                                  <Select
                                    classNamePrefix="react-select"
                                    options={primaryKey}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e) => {
                                      props.data.name === "bar_charts" ? selectXaxis(e, "1") : selectXaxis_arr(e, "1");
                                    }}
                                    value={blockData.x_axis_key}
                                    // isDisabled={selectedPrimaryValue?.value !== undefined ? false : true}
                                  />
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md={12}>
                                  <h6>
                                    <b>{props.data.name === "table" ? "Column Label" : "X Axis Label"}:</b>
                                  </h6>
                                  {
                                    console.log(xaxislabel,'xaxislabel')
                                  }
                                  <Input
                                    type="text"
                                    defaultValue={
                                      props.data.name === "table"
                                        ? labelname_arr?.[0]
                                        : xaxislabel !== undefined
                                          ? xaxislabel
                                          : "label"
                                    }
                                    onChange={(e) => {
                                      props.data.name === "table" ? Add_table_label(e) : Addaxislabel(e, "x");
                                    }}
                                    required
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <Row className="mt-3">
                                <Col md={12}>
                                  <h6>
                                    <b>Field name1:</b>
                                  </h6>
                                  <Select
                                    classNamePrefix="react-select"
                                    options={primaryKey}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e) => {
                                      selectXaxis_pie_chart(e, "1");
                                    }}
                                    // value={blockData.x_axis_key}
                                    value={selectedXaxisKey != "" ? selectedXaxisKey : blockData.x_axis_key}
                                    // isDisabled={selectedPrimaryValue?.value !== undefined ? false : true}
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <label className="toggle-btn">
                                    <input
                                      type="checkbox"
                                      onClick={(e) => mouseover_toggle(e)}
                                      defaultChecked={blockData.mouseovered}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  &nbsp; &nbsp; &nbsp;
                                  <span>Mouseover </span>
                                </Col>
                              </Row>
                            </>
                          )}
                          <br />
                          {props.data.name === "bar_charts" || props.data.name === "hor_barcharts" ? (
                            <>
                              <Row className="mt-3">
                                <Col md={12}>
                                  <h6>
                                    <b>Y Axis field:</b>
                                  </h6>
                                  <Select
                                    classNamePrefix="react-select"
                                    options={primaryKeyYaxis}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e) => {
                                      selectXaxis(e, "2");
                                    }}
                                    value={props.data.y_axis_key}
                                  />
                                </Col>
                              </Row>
                              {/* {
                                props.data.y_axis_key !== undefined && 
                              <Row>
                                    <Col md={12}>
                                    <h6>
                                    <b>Aggregate :</b>
                                  </h6>
                                    <Select
                                  classNamePrefix="react-select"
                                    options={operations}
                                    getOptionLabel={(option) => option.name}
                                    />
                                    </Col>
                             </Row>
                              } */}

                              <Row className="mt-3">
                                <Col md={12}>
                                  <h6>
                                    <b>Y Axis Label:</b>
                                  </h6>
                                  <Input
                                    type="text"
                                    maxLength={15}
                                    onChange={(e) => Addaxislabel(e, "y")}
                                    defaultValue={yaxislabel !== undefined ? yaxislabel : "Ylabel"}
                                  />
                                </Col>
                              </Row>
                              {(props.data.name === "bar_charts" || props.data.name === "hor_barcharts") && (
                                <div style={{ overflowX: "auto" }}>
                                  <Row className="mt-3">
                                    <Col md={12}>
                                      <h6>
                                        <b>Choose Bar Color</b>
                                      </h6>
                                      <CompactPicker
                                        color={blockData.chart_customize_clr}
                                        onChange={(e) => {
                                          handleColorChange(e);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>

                                    <b>Choose Text Color  </b>
                                  </Row>
                                  <br />
                                  <input type="color" id="textColor" name="textColor" onChange={(e) => handletext_clr(e)} />
                                </div>
                              )}
                              <br />
                              <Row>
                                <Col>
                                  <label className="toggle-btn">
                                    <input
                                      type="checkbox"
                                      onClick={(e) => mouseover_toggle(e)}
                                      defaultChecked={blockData.mouseovered}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  &nbsp; &nbsp; &nbsp;
                                  <span>Mouseover222 </span>
                                </Col>
                              </Row>

                              <br />
                              <Row>
                                <Col md={4}>
                                  <h6>
                                    <b>Bar Width:</b>
                                  </h6>
                                  <Input
                                    type="number"
                                    maxLength={15}
                                    onChange={(e) => OnChangeWidth(e)}
                                    defaultValue={barwidth !== undefined ? barwidth : "150"}
                                  />
                                </Col>
                              </Row>



                            </>
                          ) : (
                            <div
                              style={{
                                overflowY: "auto",
                              }}
                            >
                              {[...Array(numAdditionalAxes)].map((_, i) => (
                                <Card
                                  key={i}
                                  className="my-3 p-3"
                                  style={{ backgroundColor: "#e4e4e4", borderRadius: "7px" }}
                                >
                                  <Row>
                                    <Col>
                                      <h6>
                                        <b>
                                          {" "}
                                          {props.data.name === "table"
                                            ? "Choose Column"
                                            : "Additional Y-axis Field"}{" "}
                                          {i + 1}:
                                        </b>
                                      </h6>
                                    </Col>
                                    <Col span={3} style={{ textAlign: "right", fill: "white" }}>
                                      {i > 0 && (
                                        <Link
                                          to="#"
                                          className="btn btn-sm btn-soft-danger"
                                          onClick={(e) => {
                                            onClickDelete(e, i);
                                          }}
                                        >
                                          <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                          <UncontrolledTooltip placement="top" target="deletetooltip">
                                            Delete
                                          </UncontrolledTooltip>
                                        </Link>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row className="mt-3">
                                    <Col md={12}>
                                      <Select
                                        value={
                                          yAxisArr !== undefined && yAxisArr.length > 0
                                            ? { name: yAxisArr[i] }
                                            : {
                                              name: "Select...",
                                            }
                                        }
                                        options={primaryKeyYaxis}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e) => {
                                          selectXaxis_arr(e, "2", i);
                                        }}
                                        onBlur={() => onselectField(errors, i)}
                                      />

                                      {(cardFielderr?.[i]?.error) ? (
                                        <span style={{ color: "red" }}>Please select an option</span>
                                      ) :
                                        null}
                                    </Col>
                                  </Row>
                                  <br />
                                  <h6>
                                    <b>
                                      {" "}
                                      {props.data.name === "table" ? "Column Label" : "Additional Y-axis Label"} {i + 1}
                                      :
                                    </b>
                                  </h6>
                                  <Row>
                                    <Col>
                                      <Input
                                        type="text"
                                        placeholder={`Enter label Name ${i + 1}`}
                                        maxLength={15}
                                        name={`additional_y_label_${i}`}
                                        onChange={(e) => {
                                          InputLabelName(e, i);
                                        }}
                                        defaultValue={labelname_arr !== undefined ? labelname_arr[i + 1] : "label"}
                                        disabled={yAxisArr !== undefined && yAxisArr[i] ? false : true}
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  {props.data.name !== "table" && (
                                    <div>
                                      <Row>
                                        <b>Choose a Bar Color {i + 1}:</b>
                                      </Row>
                                      <br />

                                      <Row>
                                        <Col md={12}>
                                          <TwitterPicker
                                            width="600px"
                                            color={
                                              colorArr !== undefined && colorArr.length !== 0 ? colorArr[i - 1] : []
                                            }
                                            onChange={(e) =>
                                              !(yAxisArr !== undefined && yAxisArr[i])
                                                ? setShowWarning(true)
                                                : handleColorChange_arr(e, i)
                                            }
                                            disabled={!(yAxisArr !== undefined && yAxisArr[i])}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <b>Choose Text Color  {i + 1} :</b>
                                      </Row>
                                      <br />
                                      <input type="color" id="textColor" name="textColor" onChange={(e) => handletext_clr_arr(e, i)} />
                                    </div>
                                  )}
                                </Card>
                              ))}
                            </div>
                          )}
                        </>

                        {XaxisValue !== "" &&
                          props.data.name !== "bar_charts" &&
                          props.data.name !== "pie_chart" &&
                          props.data.name !== "hor_barcharts" && (
                            <div>
                              <Row>
                                <Col>
                                  <button
                                    className="btn btn-dark"
                                    style={{ opacity: yAxisArr?.length !== addTransitiondata ? "50%" : "80%" }}
                                    onClick={(e) =>
                                      addTransitiondata === yAxisArr?.length ? add_axis_data(e) : setShowWarning(true)
                                    }
                                    disabled={
                                      props.data.name === "table"
                                        ? labelname_arr?.length > 0
                                          ? false
                                          : true
                                        : xaxislabel !== undefined && xaxislabel !== ""
                                          ? false
                                          : true
                                    }
                                  >
                                    {props.data.name === "table" ? "Add Column" : "Add"} +
                                  </button>
                                </Col>
                                {showWarning && (
                                  <span style={{ color: "red" }}>Please fill out the required fields</span>
                                )}
                              </Row>
                              <br />
                              {props.data.name !== "bar_charts" &&
                                props.data.name !== "pie_chart" &&
                                props.data.name !== "table" && (
                                  <>
                                    <Row>
                                      <Col>
                                        <label className="toggle-btn">
                                          <input
                                            type="checkbox"
                                            onClick={(e) => mouseover_toggle(e)}
                                            defaultChecked={blockData.mouseovered}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                        &nbsp; &nbsp; &nbsp;
                                        <span>Mouseover1 </span>
                                      </Col>
                                      {showMouseoverMain && (
                                        <Col>
                                          <div>
                                            <span style={{ color: blockData.mouseover_type ? "lightgrey" : "green" }}>
                                              Single Mouseover{" "}
                                            </span>
                                            &nbsp; &nbsp;
                                            <label className="toggle-btn">
                                              <input
                                                type="checkbox"
                                                id={`toggleSwitch-9`}
                                                defaultChecked={showMouseoverMulti || blockData.mouseovered_type}
                                                onClick={(e) => mouseover_type(e)}
                                              />
                                              <span className="slider round"></span>
                                            </label>
                                            &nbsp;&nbsp;
                                            <span style={{ color: blockData.mouseover_type ? "green" : "lightgrey" }}>
                                              {" "}
                                              Multi Mouseover
                                            </span>
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                    {props.data.name !== "area_chart" &&
                                      props.data.name !== "Vertical_linechart" &&
                                      props.data.name !== "line_chart" &&
                                      props.data.name !== "pie_chart" &&
                                      props.data.name !== "table" && (
                                        <Row>
                                          <Col md={4}>
                                            <h6>
                                              <b>Bar Width:</b>
                                            </h6>
                                            <Input
                                              type="number"
                                              maxLength={15}
                                              onChange={(e) => OnChangeWidth(e)}
                                              defaultValue={barwidth !== undefined ? barwidth : "150"}
                                            />
                                          </Col>
                                        </Row>
                                      )}
                                  </>
                                )}
                            </div>
                          )}
                        {props.data.name === "line_chart" && (
                          <>
                            <Col>
                              <div>
                                <span style={{ color: blockData.show_Square ? "lightgrey" : "green" }}>
                                  Show square node
                                </span>
                                &nbsp;&nbsp;
                                <label className="toggle-btn">
                                  <input
                                    type="checkbox"
                                    id={`toggleSwitch-square`}
                                    defaultChecked={blockData.show_Square}
                                    onClick={(e) => show_square(e)}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </Col>
                          </>
                        )}
                        <br />
                        {props.data.name !== "table" && (
                          <div>
                            <Row>
                              <Col md={4}>
                                <label className="toggle-btn">
                                  <input
                                    type="checkbox"
                                    id={`toggleSwitch-1`}
                                    defaultChecked={blockData.show_bar_values}
                                    onClick={(e) => show_bar_val(e)}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                &nbsp;&nbsp; &nbsp;&nbsp;
                                <span>Show Values as Text </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              {props.data.name !== "pie_chart" &&
                                props.data.name !== "line_chart" &&
                                props.data.name !== "Vertical_linechart" &&
                                props.data.name !== "area_chart" && (
                                  <>
                                    {" "}
                                    <Col md={4}>
                                      <label className="toggle-btn">
                                        <input
                                          type="checkbox"
                                          id={`toggleSwitch-2`}
                                          defaultChecked={blockData.show_Line}
                                          onClick={(e) => show_line(e)}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                      &nbsp;&nbsp; &nbsp;&nbsp;
                                      <span>Show line graph</span>
                                    </Col>
                                    <Row>
                                      <Col></Col>
                                    </Row>
                                  </>
                                )}
                              {showLine ? (
                                <Col>
                                  <div>
                                    <span style={{ color: blockData.show_line ? "lightgrey" : "green" }}>
                                      Show Square Node{" "}
                                    </span>
                                    &nbsp; &nbsp;
                                    <label className="toggle-btn">
                                      <input
                                        type="checkbox"
                                        id={`toggleSwitch-9`}
                                        defaultChecked={blockData.show_Square}
                                        onClick={(e) => show_square(e)}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    &nbsp;&nbsp;
                                    <span style={{ color: blockData.show_circle ? "green" : "lightgrey" }}>
                                      Show Circle Node
                                    </span>
                                  </div>
                                </Col>
                              ) : (
                                (props.data.name === "area_chart" || props.data.name === "Vertical_linechart") && (
                                  <Col>
                                    <div>
                                      <span style={{ color: "green" }}>Show Square Node </span>
                                      &nbsp; &nbsp;
                                      <label className="toggle-btn">
                                        <input
                                          type="checkbox"
                                          id={`toggleSwitch-9`}
                                          defaultChecked={blockData.show_Square}
                                          onClick={(e) => show_square(e)}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                      &nbsp;&nbsp; &nbsp; &nbsp;
                                      <span style={{ color: "green" }}>Show Circle Node</span>
                                    </div>
                                  </Col>
                                )
                              )}
                            </Row>
                            <Row>
                              <Col>
                                {(props.data.name === "line_chart" ||
                                  props.data.name === "Vertical_linechart" ||
                                  props.data.name === "stack_bar" ||
                                  props.data.name === "hor_stack" ||
                                  props.data.name === "bar_charts" ||
                                  props.data.name === "hor_barcharts") && (
                                    <div>
                                      <br />
                                      <Col>
                                        <label className="toggle-btn">
                                          <input
                                            type="checkbox"
                                            defaultChecked={blockData.curved_line_chrt}
                                            onClick={(e) => curved_lines(e)}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                        &nbsp; &nbsp; &nbsp;
                                        <span>Curved Line </span>
                                      </Col>
                                    </div>
                                  )}
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col md={4}>
                                <label className="toggle-btn">
                                  <input
                                    type="checkbox"
                                    id={`toggleSwitch-3`}
                                    defaultChecked={blockData.show_table}
                                    onClick={async (e) => show_table(e)}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                &nbsp;&nbsp; &nbsp;&nbsp;
                                <span>Show Table </span>
                              </Col>
                            </Row>
                            <br />
                          </div>
                        )}
                        {props.data.name !== "pie_chart" && props.data.name !== "table" && (
                          <Row>
                            <Col md={4}>
                              <label className="toggle-btn">
                                <input
                                  type="checkbox"
                                  id={`toggleSwitch-1`}
                                  defaultChecked={blockData.show_Grid}
                                  onClick={(e) => show_Grid(e)}
                                />
                                <span className="slider round"></span>
                              </label>
                              &nbsp;&nbsp; &nbsp;&nbsp;
                              <span> Grid </span>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    )}
                  </Card>
                  <Row className="mt-3">
                    <Col md={3}>
                      <Button
                        onClick={() => {
                          onClose(false);
                        }}
                      >
                        {" "}
                        Back{" "}
                      </Button>
                    </Col>
                  </Row>
                  {/* )} */}
                </Col>
                <Col md={10}>
                  <Card></Card>
                </Col>
              </Row>
            </>
          </div>
        </Drawer>
      </>
    )
  );
};

export default SidePanel;