import React, { useRef, useEffect } from 'react';
import * as markerjs2 from 'markerjs2';
import auditvista_logo from "../../assets/images/bg-auth-overlay3.png";

const MyMarkerComponent = () => {
  const imgRef = useRef(null);

  useEffect(() => {
  console.log('imgRef', imgRef)
  }, [imgRef])

  const showMarkerArea = () => {
    if (imgRef.current !== null) {
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      console.log(markerArea,'markerArea')
      markerArea.uiStyleSettings.redoButtonVisible = true;
      markerArea.uiStyleSettings.notesButtonVisible = true;
      markerArea.uiStyleSettings.zoomButtonVisible = true;
      markerArea.uiStyleSettings.zoomOutButtonVisible = true;
      markerArea.uiStyleSettings.clearButtonVisible = true;
      var maState
      markerArea.addEventListener('render', event => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;
          maState = event.state;
          console.log( event.state,' event.state')
        }
      });
      console.log('dataUrl', imgRef.current.src, maState)
      markerArea.show();
      if (maState) {
        markerArea.restoreState(maState);
      }
    }
  };

  return (
    <div className="page-content">
      <h1>marker.js 2 Demo</h1>
      <img
        ref={imgRef}
        src={auditvista_logo}
        alt="sample"
        style={{ width: '50%' }}
        onClick={showMarkerArea}
      />
    </div>
  );
};

export default MyMarkerComponent;





// import React, { useEffect } from 'react';
// import * as markerjs2 from 'markerjs2';
// import auditvista_logo from "../../assets/images/auditvista_logo.png";

// function ImageAnnotationComponent() {
//   useEffect(() => {
//     // Create an instance of MarkerArea and pass the target image reference
//     const markerArea = new markerjs2.MarkerArea(document.getElementById('myimg'));
//     // Register an event listener for when the user clicks OK/save in the Marker.js UI
//     markerArea.addEventListener('render', event => {
//       // Replace the original image with the annotated image
//       document.getElementById('myimg').src = event.dataUrl;
//     });

//     // Call the show() method to open the Marker.js UI
//     markerArea.show();

//     // Cleanup function
//     return () => {
//       // Cleanup Marker.js instance
//       markerArea.destroy();
//     };
//   }, []); // Run this effect only once after component mount

//   return (
//     <div>
//       {/* Replace 'path/to/your/image.jpg' with the actual path to your image */}
//       <img
//         id="myimg"
//         src={auditvista_logo}
//         alt="Annotated Image"
//         style={{ maxWidth: '100%'}}
//       />
//     </div>
//   );
// }

// export default ImageAnnotationComponent;















// import React, { useRef ,useState, useEffect} from 'react';
// import {
//   MarkerArea,
//   RectMarker,
//   TextMarker,
//   HighlightMarker, // Depending on the type of annotation you need
// } from 'markerjs';

// import auditvista_logo from "../../assets/images/auditvista_logo.png";

// const styles = {
//   border: '0.0625rem solid #9c9c9c',
//   borderRadius: '0.25rem',
// };

// function SketchingComponent() {
//   const canvasRef = useRef(null);
//   const [imageURL, setImageURL] = useState(null);
//   const [marker, setMarker] = useState(null);

//   useEffect(() => {
//     if (canvasRef.current) {
//       const m = new MarkerArea({
//         element: canvasRef.current,
//         // Add any Marker.js options here
//       });
//       setMarker(m);
//     }
//   }, []);

//   const handleUndo = () => {
//     marker.undo();
//   };

//   const handleRedo = () => {
//     marker.redo();
//   };

//   const handleDraw = () => {
//     console.log('A stroke has been drawn.');
//   };

//   const handleUpload = async () => {
//     // Render annotations on canvas
//     marker.renderToCanvas().then(canvas => {
//       // Convert canvas to data URL
//       const dataURL = canvas.toDataURL();
//       setImageURL(dataURL);
//     });
//   };

//   return (
//     <div>
//       <canvas
//         ref={canvasRef}
//         style={styles}
//         width={600}
//         height={400}
//       />
    
//       <button onClick={handleUndo} className='btn btn-warning'>Undo</button>&nbsp;
//       <button onClick={handleRedo} className='btn btn-info'>Redo</button>&nbsp;
//       <button onClick={handleUpload} className='btn btn-success'>Upload</button>&nbsp;

//       {imageURL && (
//         <div>
//           <h3>Uploaded Image Preview</h3>
//           <img src={imageURL} alt="Uploaded Image" />
//         </div>
//       )}
//     </div>
//   );
// }

// export default SketchingComponent;
















// import React, { useRef ,useState} from 'react';
// import { ReactSketchCanvas } from 'react-sketch-canvas';
// import auditvista_logo from "../../assets/images/auditvista_logo.png";

// const styles = {
//   border: '0.0625rem solid #9c9c9c',
//   borderRadius: '0.25rem',
// };

// function SketchingComponent() {
//   const sketchRef = useRef(null);
//   const [imageURL, setImageURL] = useState(null);

//   const handleUndo = () => {
//     sketchRef.current.undo();
//   };

//   const handleRedo = () => {
//     sketchRef.current.redo();
//   };

//   const handleDraw = () => {
//     console.log('A stroke has been drawn.');
//   };

//   const handleUpload=async()=>{
//     sketchRef.current.exportImage("png").then(async data => {
//         var ImageURL = data
//         var block = ImageURL.split(";");
//         var contentType = block[0].split(":")[1];
//         var realData = block[1].split(",")[1];
//         var blob = await b64toBlob(realData, contentType);
//         const blobURL = URL.createObjectURL(blob);
//         setImageURL(blobURL);
//     })

//   }


//  const b64toBlob=(b64Data, contentType, sliceSize)=> {
//     contentType = contentType || '';
//     sliceSize = sliceSize || 512;

//     var byteCharacters = atob(b64Data);
//     var byteArrays = [];

//     for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//       var slice = byteCharacters.slice(offset, offset + sliceSize);

//       var byteNumbers = new Array(slice.length);
//       for (var i = 0; i < slice.length; i++) {
//         byteNumbers[i] = slice.charCodeAt(i);
//       }

//       var byteArray = new Uint8Array(byteNumbers);

//       byteArrays.push(byteArray);
//     }

//     var blob = new Blob(byteArrays, { type: contentType });
//     return blob;
//   }

//   return (
//     <div>
//       <ReactSketchCanvas
//         ref={sketchRef}
//         style={styles}
//         width={600}
//         height={400}
//         strokeWidth={4}
//         strokeColor="red"
//         onStroke={handleDraw}
//         backgroundImage={auditvista_logo}
//       />
    
//       <button onClick={handleUndo} className='btn btn-warning'>Undo</button>&nbsp;
//       <button onClick={handleRedo} className='btn btn-info'>Redo</button>&nbsp;
//       <button onClick={handleUpload} className='btn btn-success'>Upload</button>&nbsp;

//       {imageURL && (
//         <div>
//           <h3>Uploaded Image Preview</h3>
//           <img src={imageURL} alt="Uploaded Image" />
//         </div>
//       )}
//     </div>
//   );
// }

// export default SketchingComponent;














// import React, { useRef, useEffect } from 'react';

// function SketchingComponent() {
//   const canvasRef = useRef(null);
//   const imageRef = useRef(null);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext('2d');
  
//     // Load the image onto the canvas
//     const image = imageRef.current;
//     image.onload = () => {
//       ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
//     };
  
//     // Add event listeners to draw lines
//     let isDrawing = false;
//     const handleMouseDown = (e) => {
//       isDrawing = true;
//       ctx.beginPath();
//       ctx.moveTo(e.clientX - canvas.offsetLeft, e.clientY - canvas.offsetTop);
//     };
//     const handleMouseMove = (e) => {
//       if (isDrawing) {
//         ctx.lineTo(e.clientX - canvas.offsetLeft, e.clientY - canvas.offsetTop);
//         ctx.stroke();
//       }
//     };
//     const handleMouseUp = () => {
//       isDrawing = false;
//     };
//     const handleMouseOut = () => {
//       isDrawing = false;
//     };
  
//     canvas.addEventListener('mousedown', handleMouseDown);
//     canvas.addEventListener('mousemove', handleMouseMove);
//     canvas.addEventListener('mouseup', handleMouseUp);
//     canvas.addEventListener('mouseout', handleMouseOut);
  
//     return () => {
//       // Remove event listeners when component unmounts
//       canvas.removeEventListener('mousedown', handleMouseDown);
//       canvas.removeEventListener('mousemove', handleMouseMove);
//       canvas.removeEventListener('mouseup', handleMouseUp);
//       canvas.removeEventListener('mouseout', handleMouseOut);
//     };
//   }, []);

//   return (
//     <div>
//       <img ref={imageRef} src="https://d3pnv0bkd16srd.cloudfront.net/lms-demo/0857f-33a-0af5-73a-1eb5eefcff4content.png" style={{ display: 'none' }} alt="reference" />
//       <canvas ref={canvasRef} width={800} height={600} style={{ border: '1px solid black' }} />
//     </div>
//   );
// }

// export default SketchingComponent;
