

import React, { Component } from "react";
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
    Input,
} from "reactstrap";
// import Dropzone from "react-dropzone"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"



import moment from 'moment'

const _ = require('lodash')




export default class Observation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataloaded: false,
            previousData: {},
            show_comment_box : false
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    handleValidSubmit(event, values){

        this.props.selectedCheckpoint.cp_observation = values.cp_remarks
        this.props.saveCheckpoint(this.props.selectedCheckpoint)
        this.setState({
            show_comment_box : false
        })

    }
    editComment=()=>{
        this.setState({
            show_comment_box : true
        })
    }


    render() {
        return (
            <Container className="p-3 mb-3">
                <Row>
                    <Col sm={"12"} lg={"12"}>
                    <label>Comment</label> {
                        this.props.selectedCheckpoint.cp_observation !== null &&  this.state.show_comment_box !== true &&
                        <i onClick={()=>{this.editComment()}} className="mdi mdi-pencil font-size-16 text-primary me-1" />
                    }
                    {
                        this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.cp_observation == null ?
                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                        <div className="mb-3" >
                            <AvField
                                name="cp_remarks"
                                value={this.props.selectedCheckpoint.cp_observation}
                                onChange={(e) => {  this.setState({ observation: e.target.value }) }}
                                className="form-control"
                                placeholder="Enter Comment"
                                type="textarea"
                                required
                            />
                        </div>
                        <div>
                            <Button color={"primary"} >Save</Button>
                        </div>
                    </AvForm>
                    :
                    <div>
                        <p>{this.props.selectedCheckpoint.cp_observation}</p>
                    </div>
                    }
                    {
                        this.state.show_comment_box == true && this.props.audit_status !== "3" && this.props.audit_status !== "4" ?
                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                        <div className="mb-3" >
                            <AvField
                                name="cp_remarks"
                                value={this.props.selectedCheckpoint.cp_observation}
                                onChange={(e) => {  this.setState({ observation: e.target.value }) }}
                                className="form-control"
                                placeholder="Enter Comment"
                                type="textarea"
                                required
                            />
                        </div>
                        <div>
                            <Button color={"primary"} >Save</Button>
                        </div>
                    </AvForm>
                    :
                    null
                
                    }
                    
                    </Col>
                </Row>
            </Container>
        )
    }

}