import _, { repeat } from "lodash";
var moment = require('moment')


let validation = {

    checkpointStatus:(checkpoint) => {
      console.log(checkpoint,'checkpoint')
        var optionSelectedStatus = _.some(checkpoint.checkpoint_options, {
          is_selected: true,
        });
         
      
        var getOptionIndex = _.findIndex(checkpoint.checkpoint_options, {
          is_selected: true,
        });

        var selected_option = checkpoint.checkpoint_options[getOptionIndex];
        var Images =
          checkpoint.cp_attach_images.length >= checkpoint.cp_noof_images
            ? true
            : false;
         var Videos =
            checkpoint.cp_attach_videos.length >= checkpoint.cp_noof_videos
              ? true
              : false;
        var Documents =
          checkpoint.cp_documents.length >= checkpoint.cp_noof_documents
            ? true
            : false;
        var apln = !selected_option.nc_mandatory
          ? true
          : selected_option.nc_mandatory && checkpoint.cp_actionplans.length >= 1 &&  checkpoint.cp_actionplans.every(item => item.actionplan !== "")
          ? true
          : false;
            
        var validate = [Images, Documents, apln,Videos];
        var validationStatus = _.every(validate);
      
        var value =
          optionSelectedStatus && validationStatus
            ? "2"
            : optionSelectedStatus
            ? "1"
            : "0";
      
      
        return value;
      },

}

export default validation;









// import _, { repeat } from "lodash";
// var moment = require('moment')


// let validation = {

//   checkpointStatus: (checkpoint) => {
//     console.log(checkpoint, 'checkpoint')
//     var optionSelectedStatus = _.some(checkpoint.checkpoint_options, {
//       is_selected: true,
//     });


//     var getOptionIndex = _.findIndex(checkpoint.checkpoint_options, {
//       is_selected: true,
//     });

//     var selected_option = checkpoint.checkpoint_options[getOptionIndex];
//     var Images =
//       checkpoint.cp_attach_images.length >= checkpoint.cp_noof_images
//         ? true
//         : false;
//     if (checkpoint.cp_attach_videos !== undefined) {
//       var videos =
//         checkpoint.cp_attach_videos.length >= checkpoint.cp_noof_videos
//           ? true
//           : false;
//     }
//     var Documents =
//       checkpoint.cp_documents.length >= checkpoint.cp_noof_documents
//         ? true
//         : false;
//     var apln = !selected_option.nc_mandatory
//       ? true
//       : selected_option.nc_mandatory && checkpoint.cp_actionplans.length >= 1
//         ? true
//         : false;
//     if (checkpoint.cp_attach_videos !== undefined) {
//       var validate = [Images, videos, Documents, apln];
//     }
//     else {
//       var validate = [Images, Documents, apln];

//     }
//     var validationStatus = _.every(validate);
//     console.log(validationStatus, 'validationStatus', validate)
//     var value =
//       optionSelectedStatus && validationStatus
//         ? "2"
//         : optionSelectedStatus
//           ? "1"
//           : "0";
//     console.log(Images, 'Images', value)

//     return value;
//   },

// }

// export default validation;