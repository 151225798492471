import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import CryptoJS from 'crypto-js'
import { LoadingOutlined } from '@ant-design/icons';

const _ = require('lodash')
var urlSocket = require("../../../../helpers/urlSocket")

export default class AddNode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataloaded: false,
            alertEnable:false,
            formDisabled:false,
            userInfo:{},
            secret_key: 'Pkdh347ey%3Tgs',
            loading:false,
            invalid_user: false,
            invalid_phone_num: false,
            invalid_usercode: false,
            country_code_err:false,
            permissionsadded:[],
            given_email:'',

        };


    }

    componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));

        data.config_data.facilities.map((ele,idx)=>{
            if(ele.id === "7"){
                data.config_data.facilities[idx].enabled = true
                this.setState({permissionsadded:[data.config_data.facilities[idx]]})
            }
        })

        this.setState(
            {
                sessionUserInfo: data.user_data,
                configData: data.config_data,
                
                dataloaded: true,
                db_info:db_info,
                client_info:client_info

            },
            function () {
            //    this.getEntityDetaitedData()
            }
        )
    }
    select_country=(e)=>{
        this.setState({countrycode:e.target.value})
    }
    


    addPermissions(e, item){

        var getIndex = _.findIndex(this.state.permissionsadded, {id:item.id})

        if(getIndex === -1)
        {
            this.state.permissionsadded.push(item)
            this.setState({
                permissionsadded:this.state.permissionsadded
            })
        }
        else
        {
            this.state.permissionsadded.splice(getIndex, 1)
            this.setState({
                permissionsadded:this.state.permissionsadded
            })
        }


    }

    validate_email = (e, mode) => {
        var admn_info = {}
        if (mode == 1) {
            admn_info["email_id"] = e.target.value
        }
        if (mode === 2) {
            admn_info["phone_number"] = e.target.value
        }
        if (mode === 3) {
            admn_info["usercode"] = e.target.value
        }
        admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        admn_info["db_name"] = this.state.db_info.db_name
        admn_info["user_id"] = this.state.sessionUserInfo._id

        if(this.state.usercode !==undefined || admn_info["email_id"] !== undefined || admn_info["phone_number"] !== undefined ){
        try {
            urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
                if (mode == 1) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_user: true })
                    }
                    else {
                        this.setState({ invalid_user: false })
                    }
                }
                if (mode == 2) {

                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_phone_num: true })
                    }
                    else {
                        this.setState({ invalid_phone_num: false })
                    }
                }
                if (mode === 3) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_usercode: true })
                    }
                    else {
                        this.setState({ invalid_usercode: false })
                    }


                }
            })

        } catch (error) {

        }
    }

    }


    handleValidSubmit = (event, values) => {
        const updatedFacilities = this.state.client_info.facilities.map((facility) => {
            const updatedFacility = { ...facility };
            const matchingPermission = this.state.permissionsadded.find((permission) => permission.id === facility.id);
            if (matchingPermission) {
              updatedFacility.enabled = true;
            }
            return updatedFacility;
          });
        
          this.setState({
            client_info: {
              ...this.state.client_info,
              facilities: updatedFacilities,
            },
          });

        // this.state.client_info.facilities.map((ele,idx)=>{
        //     this.state.permissionsadded.map((ele1,idx1)=>{
        //         if(ele1.id === ele.id){
        //             this.state.client_info.facilities[idx].enabled = true
        //         }
        //     })
        // })


        var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
        values["password"] = password
        values["confirmpassword"] = password
        values["userPoolId"] = this.state.client_info.userPoolId
        values["clientId"] = this.state.client_info.clientId
        values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        values["db_name"] = this.state.db_info.db_name
        values["facilities"] = this.state.client_info.facilities
        values["company_id"] = this.state.sessionUserInfo.m_client_id
         values["created_by"] = this.state.sessionUserInfo._id
         values["user_id"] = this.state.sessionUserInfo._id



        if (this.state.countrycode === undefined) {
            this.setState({ country_code_err: true })
        }
        else {
            values["countrycode"] = this.state.countrycode
            this.setState({ country_code_err: false })

        }
        if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false) {
            this.setState({loading:true})

            try {
                urlSocket.post('cog/cruduser', values).then((res) => {
                    if(res.data.response_code === 500){
                        var created_user = res.data.admn_user[0]
                         this.setState({loading:false})
                           var event ={}
                         var target ={}
                          target["value"]=created_user._id
                          event["target"]=target
                          this.props.didmount()
                        //  window.location.reload()
                        //  this.props.updtUser(event,created_user)
                        //  this.props.getAllUsers()
                        //   this.props.onClose()
                        //  this.props.rowData.adt_users.push({
                        //     user_id: created_user._id,
                        //     name: created_user.firstname,
                        //     designation: created_user.designation,
                        //     user_code: created_user.usercode,
                        //     audit_type: "1"
                        //   })
                        //   var event ={}
                        //   var target ={}
                        //   target["value"]=created_user._id
                        //   event["target"]=target
                        // //  window.location.reload()
                        //  this.props.updtUser(event,this.props.rowData)
                        // this.props.history.push('/murs')
                        // window.location.href('/pblhcfg')
                }
                else{
                    this.setState({loading:false})

                }
                })

            } catch (error) {
                console.log(error, 'error')
            }

        }

        //    values[] 


    }






    // handleValidSubmit = (event, values) => {
    //     try {
    //         var loggedUserInfo = {
    //             company_id: this.state.sessionUserInfo.company_id,
    //             company_name: this.state.sessionUserInfo.company_name,
    //             _id:this.state.sessionUserInfo._id
    //         }
    //         urlSocket.post("webUsers/cruduser", {
    //             loggedUserInfo: loggedUserInfo,
    //             newUserInfo: values,
    //             permissionInfo:this.state.permissionsadded
    //         })
    //             .then((response) => {
    //                 if (response.data.response_code == 504) {
    //                     this.setState({ formDisabled: false, alertEnable: true, alertColor: "danger", message: response.data.message })
    //                 }
    //                 else
    //                     if (response.data.response_code == 500) {
    //                         this.setState({
    //                             first_name: response.data.data.first_name,
    //                             formDisabled: true,
    //                             alertEnable: true,
    //                             alertColor: "success",
    //                             message: response.data.message
    //                         })
    //                     }
    //             })
    //     } catch (error) {

    //     }
    // }

    emai_addrs = (e) => {
        this.setState({ given_email: e.target.value })
    }

    userCode=(e)=>{
        this.setState({usercode : e.target.value})
    }


    


    render() {
        if (this.state.dataloaded) {
            return (
                <React.Fragment>
                        <Container fluid>
                            <Row >
                                <Col >
                                    <Card className="overflow-hidden">
                                        {
                                            !this.state.formDisabled ?
                                                <CardBody className="pt-0">
                                                    <div className="p-5">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


                                                            <Row className="my-4">
                                                            <div className="d-sm-flex align-items-center justify-content-between">
                                                                <div className="text-danger font-size-18">User Information</div>

                                                                <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
                                                            </div>
                                                                <hr className="my-4" />
                                                            </Row>

                                                            <Row>
                                                                <Col className="col-6">
                                                                <div className="mb-3" >
                                                                        <label>First Name :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="firstname"
                                                                            // label="First Name"
                                                                            className="form-control"
                                                                            placeholder="Enter First Name"
                                                                            type="text"
                                                                            required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                <div className="mb-3" >
                                                                        <label>Last Name :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="lastname"
                                                                            // label="Last Name"
                                                                            className="form-control"
                                                                            placeholder="Enter Last Name"
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="col-12">
                                                                <div className="mb-3" >
                                                                <AvField
                                                                            name="addrss_1"
                                                                            label="Address"
                                                                            className="form-control"
                                                                            placeholder="Enter Address"
                                                                            type="text"
                                                                        />
                                                                    
                                                                    </div>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        <Row>
                                                            <Col className="col-4">
                                                                <div className="mb-3" >
                                                                    <AvField
                                                                        name="city"
                                                                        label="City"
                                                                        className="form-control"
                                                                        placeholder="Enter City"
                                                                        type="text"
                                                                    />

                                                                </div>

                                                            </Col>
                                                            <Col className="col-4">
                                                                <div className="mb-3" >
                                                                    <AvField
                                                                        name="state"
                                                                        label="State"
                                                                        className="form-control"
                                                                        placeholder="Enter State"
                                                                        type="text"
                                                                    />

                                                                </div>

                                                            </Col>
                                                            <Col className="col-4">
                                                                <div className="mb-3" >
                                                                    <AvField
                                                                        name="country"
                                                                        label="Country"
                                                                        className="form-control"
                                                                        placeholder="Enter Country"
                                                                        type="text"
                                                                    />

                                                                </div>

                                                            </Col>
                                                        </Row>

                                                            

                                                            <Row>
                                                                <Col className="col-6">
                                                                <div className="mb-3" >
                                                                        <label>Email Id :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="email_id"
                                                                            onChange={(e) => { this.emai_addrs(e) }}
                                                                            onBlur={(e) => { this.validate_email(e, 1) }}
                                                                            // label="User Email"
                                                                            className="form-control"
                                                                            placeholder="Enter User Email"
                                                                            type="email"
                                                                            required
                                                                        />
                                                                        {
                                                                            this.state.invalid_user &&
                                                                            <div className='text-danger' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                <div className="mb-3">
                                                                    <label>Phone Number:<span className="text-danger"> *</span></label>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <select name="countrycode" onChange={(e) => { this.select_country(e) }} className="form-select" required>
                                                                                <option value="">Select</option>
                                                                                {
                                                                                    this.state.client_info.countries.map((c, idx) => (
                                                                                        <option key={idx} value={c.code}>
                                                                                            {c.code}
                                                                                        </option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <AvField
                                                                                name="phone_number"
                                                                                // label="Phone Number"
                                                                                className="form-control"
                                                                                placeholder="Enter Phone number"
                                                                                type="number"
                                                                                onBlur={(e) => { this.validate_email(e, 2) }}
                                                                                validate={{
                                                                                    required: { value: true },
                                                                                    minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                    maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                }}
                                                                            />
                                                                            {
                                                                                this.state.invalid_phone_num &&
                                                                                <div className='text-danger' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.country_code_err &&
                                                                    <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


                                                                }




                                                                    {/* <div className="mb-3" >
                                                                        <AvField
                                                                            name="phone_number"
                                                                            label="Phone Number"
                                                                            className="form-control"
                                                                            placeholder="Enter Phone number"
                                                                            type="number"
                                                                            validate={{
                                                                                required: { value: true },
                                                                                minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                            }}
                                                                        />
                                                                    </div> */}
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="designation"
                                                                            label="Designation"
                                                                            type="text"
                                                                            // required
                                                                            placeholder="Enter Designation"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="usercode"
                                                                            label="User Code"
                                                                            type="text"
                                                                            // required
                                                                            placeholder="Enter User Code"
                                                                            onChange={(e)=>{this.userCode(e)}}
                                                                            onBlur={(e) => this.validate_email(e, 3)}
                                                                        />
                                                                          {
                                                                            this.state.invalid_usercode &&
                                                                            <div className="text-danger" style={{ fontSize: 'smaller' }}>User Code already exist for another user</div>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="my-4">
                                                                <div className="text-danger font-size-18">Login Information</div>
                                                                <hr className="my-4" />
                                                            </Row>

                                                            <Row>
                                                                <Col className="col-6">
                                                                <div className="mb-3">
                                                                        <label>Username :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="username"
                                                                            // label="Username"
                                                                            type="text"
                                                                            required
                                                                            placeholder="Enter username"
                                                                            disabled={true}
                                                                            value={this.state.client_info.short_name + '.' + this.state.given_email}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            {/* </Row> */}

                                                            {/* <Row> */}
                                                                <Col className="col-6">
                                                                <div className="mb-3">
                                                                        <label>Password :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="password"
                                                                            autoComplete = "new-password"
                                                                            // label="Password"
                                                                            type="password"
                                                                            required
                                                                            placeholder="Enter password"
                                                                            validate={{
                                                                                required: { value: true, errorMessage: 'Password is required' },
                                                                                minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
                                                                                pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                {/* <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="confirmpassword"
                                                                            label="Confirm Password"
                                                                            type="password"
                                                                            required
                                                                            placeholder="Enter Confirm password"
                                                                        />
                                                                    </div>
                                                                </Col> */}
                                                            </Row>


                                                            <Row className="my-4">
                                                                <div className="text-danger font-size-18">Set Permissions</div>
                                                                <hr className="my-4" />
                                                            </Row>   

                                                        <Row>

                                                            {
                                                                this.state.configData.facilities.map((item, idx)=>{
                                                                    return(
                                                                        <Col className="col-auto" key={"pn"+idx}>
                                                                            <div className="form-check mb-3">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    value=""
                                                                                    id={"pmsn"+idx}
                                                                                    disabled={item.id === "7" ? false : true}
                                                                                    defaultChecked={item.id === "7"}
                                                                                    onChange={(e) => { this.addPermissions(e, item) }}
                                                                                    //checked={item.is_compliance}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={"pmsn"+idx}
                                                                                >
                                                                                    {item.interfacename}
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                            
                                                        </Row>         


                                                            <div className="mb-3" >

                                                            </div>
                                                            {
                                                                this.state.alertEnable ?
                                                                    <div className="mt-4 d-grid">
                                                                        <Alert color={this.state.alertColor}>
                                                                            {this.state.message}
                                                                        </Alert>
                                                                    </div>
                                                                    : null
                                                            }


                                                            <div className="mt-4 d-grid">
                                                                <button
                                                                    className="btn btn-danger btn-block"
                                                                    type="submit"
                                                                    disabled={this.state.loading}
                                                                >
                                                                   {this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Add User"}
                                                                </button>
                                                            </div>

                                                        </AvForm>
                                                    </div>
                                                </CardBody> :
                                                <CardBody>
                                                    <div className="mt-4 text-center">
                                                        <div className="mb-0" style={{ fontSize: 20 }}>
                                                            <span className="text-danger">{this.state.first_name}</span> has added successfully
                                                        </div>
                                                        <div style={{ marginTop: 20 }}>
                                                            <Link  className="btn btn-success" onClick={()=>this.props.onClose()}>
                                                                Back
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                        }
                                    </Card>

                                </Col>
                            </Row>
                        </Container>
                </React.Fragment>
            )
        }
        else {
            return null
        }
    }
}


