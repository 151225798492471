import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import html2canvas from 'html2canvas';
import { useDispatch, useSelector } from 'react-redux';
import { sortFunc, sortDescending, sortDefault, sortInfo } from '../../../../store/report/Slice/SortingSlice';
import { handleDownload_bar, img_download_svg, img_download_png_mod } from '../../../../store/report/Slice/DownloadSlice';

const D3Stack_bar_chart = (props) => {
  const dispatch = useDispatch();
  var containerWidth = props.containerWidth
  var containerHeight = props.containerHeight
  var chart_data = props.chart_data
  var svgHeight = props.chart_height
  var BarWidth = props.BarWidth
  var chart_color = props.chart_color
  var i = props.id
  var show_bar_values = props.show_bar_values
  var mouseovered = props.mouseovered
  var mouseovered_type = props.mouseovered_type
  var enable_table = props.show_table
  var xLabel = 'Name'
  var show_Grid = props.show_Grid
  var show_Line = props.show_Line
  var temp_containerWidth = props.temp_containerWidth
  var temp_containerHeight = props.temp_containerHeight
  var fullScreen_enabled = props.show_Full_Screen_toggle
  var show_Square = props.show_Square
  var curved_line = props.curved_line;
  var YLabel = props.YLabel
  var resized = props.resized
  var text_color_arr = props.text_color
  var barLabel = props.label;

  const chartRef = useRef();
  const [data, setData] = useState(chart_data);
  const [text_Color_bar, Set_text_Color_bar] = useState([])
  const [showOptions, setShowOptions] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [showvalues, setshowvalues] = useState(show_bar_values);
  const [mouseover_enabled, setmouseover_enabled] = useState(mouseovered)
  const [mouseover_switch_type, setmouseover_switch_type] = useState(mouseovered_type)
  const [enabled_table, setenabled_table] = useState(enable_table)
  const [show_table, Setshow_table] = useState(false)
  const [table_columns, Set_table_columns] = useState(["year", "value"]);
  const [showDiv, setshowDiv] = useState(false)
  const [show_grid_enabled, setshow_grid_enabled] = useState(show_Grid);
  const [sortshowOptions, setshowsortoption] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [arr_values, setarr_values] = useState([])
  const [sortingField, setSortingField] = useState(null);
  const [chart_height, setchart_height] = useState(svgHeight)
  const [showLine, setShowLine] = useState(true);
  const [sortOrder, setSortOrder] = useState('')
  const [chartWidth, setChrtWidth] = useState(BarWidth === undefined ? containerWidth : '200')
  const SortArr = useSelector(state => state.SortArr);
  const [sortdata, setsortdata] = useState([]);
  useEffect(() => {
    if (chart_data !== undefined && chart_data.length > 0) {
      setData(chart_data)
      Set_text_Color_bar(chart_color)
      setshowvalues(show_bar_values)
      setmouseover_enabled(mouseovered)
      setmouseover_switch_type(mouseovered_type)
      setenabled_table(enable_table)
      setshow_grid_enabled(show_Grid)
      setShowLine(show_Line)
      setchart_height(svgHeight)
    }

  }, [chart_data, chart_color, show_bar_values, mouseovered, mouseovered_type, enable_table, show_Grid, temp_containerWidth, fullScreen_enabled, temp_containerHeight, show_Line, svgHeight])


  if (props.chart_data.length !== 0) {
    var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
    var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
  }
  const margin = { top: 70, right: 30, bottom: 80, left: 40 };
  useEffect(() => {
    if (sortOrder === 'asc') {
      handleSortAscending()
    }
    else if (sortOrder === 'desc') {
      handleSortDescending()
    }
    else {
      handleSortDefault()
    }

  }, [chart_data])

  useEffect(() => {
    var mod_data;
    var chart_id = i;
    if (datakeys !== undefined) {
      const marginTop = margin.top;
      const marginRight = margin.right;
      const marginBottom = margin.bottom;
      const marginLeft = margin.left;

      var sortSlice = SortArr[chart_id]?.sortedData; 
      if (sortSlice && sortSlice.length !== 0) {
        mod_data = sortSlice; 
      } else {
        mod_data = data; 
      }

      if (svgHeight !== undefined && svgHeight !== '') {
        containerHeight = containerHeight - 200
      }
      else {
        containerHeight = containerHeight
      }
      var width
      var height

      if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
        width = temp_containerWidth - margin.left - margin.right;
        height = temp_containerHeight - margin.top - margin.bottom - (enabled_table ? 200 : 0)
        containerHeight = temp_containerHeight - (enabled_table ? 200 : 0)
        containerWidth = temp_containerWidth
      }
      else {
        width = containerWidth - margin.left - margin.right;
        height = containerHeight - margin.top - margin.bottom;
      }
      const temp_barWidth = (BarWidth !== undefined ? Number(BarWidth) : 150)


      containerWidth = BarWidth !== undefined ?             mod_data.length * temp_barWidth : props.containerWidth
    containerWidth =  fullScreen_enabled ? (BarWidth !== undefined ? mod_data.length * temp_barWidth :  temp_containerWidth) : containerWidth


      const x = d3.scaleBand()
        .domain(mod_data.map(d => d.year))
        // .range([0, containerWidth - margin.left - margin.right])
        .range([marginLeft, containerWidth - marginRight]) //containerWidth
        .padding(0.1);

      const y = d3.scaleLinear()
        .domain([0, d3.max(mod_data, d => d3.sum(datakeys.map(key => d[key])))]).nice()
        .range([containerHeight - margin.bottom - marginTop, 0]);

      d3.selectAll(`#my_dataviz${i}`).selectAll("svg").remove();
      d3.selectAll(`#my_dataviz${i}`).selectAll("div").remove();
      d3.selectAll(`#my_dataviz${i}`).selectAll("div").remove();

      // Remove the existing svg declaration
      d3.selectAll(`#my_dataviz${i}`).selectAll('svg').append('g').remove();
      d3.selectAll('svg').selectAll('div').remove();
      d3.select(`#my_dataviz${i}`).selectAll("div").remove();
      d3.selectAll(`#my_dataviz${i}`).selectAll("div").remove();
      d3.select(`#my_dataviz${i}`).selectAll("svg").remove();
      const extent = [[marginLeft, marginTop], [containerWidth - marginRight, height - marginTop]];
      const zoom = d3.zoom()
        .scaleExtent([1, 8])
        .translateExtent(extent)
        .extent(extent)
        .on("zoom", zoomed);


      const svgContainer = d3.select(`#my_dataviz${i}`)
        .style("width", '100%')
        .style("overflow-x", "auto")
        .append("div")
        .style("width", `${ fullScreen_enabled ?  temp_containerWidth   : props.containerWidth}px`)
        .style("height", `${containerHeight}px`)
        .style("overflow-y", "hidden")

      const svg = svgContainer
        .append("svg")
        .attr("width", containerWidth) //containerWidth
        .attr("height", containerHeight);

      // Call zoom on SVG
      svg.call(zoom);

      // Add a chart box
      svg.append("rect")
        .attr("class", `chart-box${i}`)
        .attr("x", marginLeft)
        .attr("y", 0)
        .attr("width", containerWidth - marginLeft - marginRight) //containerWidth
        .attr("height", containerHeight - marginTop - marginBottom)
        .attr("fill", "none")
        .attr("stroke", "black")

      // Group for the main chart
      const g = svg
        .attr("transform", `translate(${0},${margin.top})`);

      // Create color scale
      const color = d3.scaleOrdinal()
        .domain(datakeys)
        .range(chart_color !== undefined && chart_color.length > 0
          ? chart_color
          : d3.quantize(d3.interpolateRainbow, datakeys.length + 2));

      // Create stack
      const stack = d3.stack()
        .keys(datakeys);

      // Stack the data
      const series = stack(mod_data);

console.log('text_color_arr', text_color_arr)
     
      // const text_color = d3.scaleOrdinal()
      //   .domain(datakeys)
      //   // .range(d3.quantize(d3.interpolateRainbow, datakeys.length + 2));  
      //   .range(text_color_arr !== undefined && text_color_arr.length > 0
      //     ? text_color_arr
      //     : d3.quantize(d3.interpolateRainbow, datakeys.length + 2));

      function generateRandomColors(numColors) {
        return Array.from({ length: numColors }, () => `#${Math.floor(Math.random()*16777215).toString(16)}`);
    }
    

      const text_color = d3.scaleOrdinal()
    .domain(datakeys)
    .range(
        text_color_arr !== undefined && text_color_arr.length > 0
            ? text_color_arr
            : generateRandomColors(datakeys.length)
    );






      const tooltip = d3.select(`#my_dataviz${i}`)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "10px");
      // Add groups for each series
      const seriesGroups = svg.selectAll(".series")
        .data(series)
        .enter().append("g")
        .attr("class", "series")
        .attr("fill", d => color(d.key));

      // Add rects for each segment
      seriesGroups.selectAll("rect")
        .data(d => d)
        .enter().append("rect")
        .attr("x", d => x(d.data.year))
        .attr("y", d => y(d[1]))
        .attr("height", d => y(d[0]) - y(d[1]))
        .attr("width", x.bandwidth())
        .on("mouseover", function (event, d) {

          var mouseX = d3.pointer(this)[0];

          var chartContainer = d3.select(`#my_dataviz${i}`).node();
          var chartContainerRect = chartContainer.getBoundingClientRect();
          if (mouseover_enabled) {
            if (mouseover_switch_type) {
              var totalValue = d3.sum(datakeys.map(key => d.data[key]));
              d3.select(this).attr('class', 'highlighted');
              let tooltipContent = `<span style="color:red;">${barLabel}:</span> ${d.data.year}<br>`;
              datakeys.forEach(key => {
                tooltipContent += `<span style="color:red;">${key}:</span> <span style="color:black;">${d.data[key]}</span><br>`;
              });
              tooltipContent += `<span style="color:red;">Total:</span> <span style="color:black;">${totalValue}</span>`;

              tooltip
                .html(tooltipContent)
                .style("opacity", 1)






    // Calculate tooltip dimensions
    const tooltipNode = tooltip.node();
    const tooltipRect = tooltipNode.getBoundingClientRect();
    const tooltipWidth = tooltipRect.width;
    const tooltipHeight = tooltipRect.height;

    // Calculate the tooltip's left and top positions
    let leftPosition = event.pageX - chartContainerRect.left + 40;
    var topPosition = event.pageY - chartContainerRect.top - 10;

    // Adjust the position if the tooltip exceeds the container width
    if (leftPosition + tooltipWidth > containerWidth) {
        leftPosition = event.pageX - chartContainerRect.left - tooltipWidth - 10; // Move tooltip to the left
    }

    // Adjust the position if the tooltip exceeds the container height
    if (topPosition + tooltipHeight > containerHeight) {
        topPosition = event.pageY - chartContainerRect.top - tooltipHeight - 10; // Move tooltip upwards
    }

    // Position the tooltip
    tooltip.style("left", leftPosition + "px")
           .style("top", topPosition + "px");




                // .style("left", (event.pageX - chartContainerRect.left + 40) + "px")
                // .style("top", (event.pageY - chartContainerRect.top - 10) + "px");
            } else {
              let tooltipContent = `<span style="color:red;">${barLabel}:</span> ${d.data.year}<br>`;

              // Iterate over datakeys to display each subgroup value
              datakeys.forEach(key => {
                tooltipContent += `<span style="color:red;">${key}:</span> <span style="color:black;">${d.data[key]}</span><br>`;
              });
              tooltipContent += `<span style="color:red;">Total:</span> <span style="color:black;">${totalValue}</span>`;
              tooltip
                .html(tooltipContent)
                .style("opacity", 1)
                .style("background-color", "white")
                .style("color", "black")
                .style("border", "solid")
                .style("border-width", "1px")
                .style("border-radius", "5px")
                .style("padding", "10px")
                .style("left", (event.offsetX + 30) + "px")
                .style("top", (event.offsetY) + "px")

            }
          }
        })
        .on("mousemove", function (event, d) {
          var chartContainer = d3.select(`#my_dataviz${i}`).node();
          var chartContainerRect = chartContainer.getBoundingClientRect();

          if (mouseover_enabled) {
            if (mouseover_switch_type) {
              console.log("286" , containerWidth);
              var totalValue = d3.sum(datakeys.map(key => d.data[key]));
              d3.select(this).attr('class', 'highlighted');
              let tooltipContent = `<span style="color:red;">${barLabel}:</span> ${d.data.year}<br>`;

              datakeys.forEach(key => {
                tooltipContent += `<span style="color:red;">${key}:</span> <span style="color:black;">${d.data[key]}</span><br>`;
              });
              tooltipContent += `<span style="color:red;">Total:</span> <span style="color:black;">${totalValue}</span>`;

              tooltip
                .html(tooltipContent)
                .style("opacity", 1)


    // Calculate tooltip dimensions
    const tooltipNode = tooltip.node();
    const tooltipRect = tooltipNode.getBoundingClientRect();
    const tooltipWidth = tooltipRect.width;
    const tooltipHeight = tooltipRect.height;

    // Calculate the tooltip's left and top positions
    let leftPosition = event.pageX - chartContainerRect.left + 40;
    var topPosition = event.pageY - chartContainerRect.top - 10;

    // Adjust the position if the tooltip exceeds the container width
    if (leftPosition + tooltipWidth > containerWidth) {
        leftPosition = event.pageX - chartContainerRect.left - tooltipWidth - 10; // Move tooltip to the left
    }

    // Adjust the position if the tooltip exceeds the container height
    if (topPosition + tooltipHeight > containerHeight) {
        topPosition = event.pageY - chartContainerRect.top - tooltipHeight - 10; // Move tooltip upwards
    }

    // Position the tooltip
    tooltip.style("left", leftPosition + "px")
           .style("top", topPosition + "px");



              

            } else {
              var subgroupName = d3.select(this.parentNode).datum().key;
              var subgroupValue = d.data[subgroupName];
              const tooltipContent = `${barLabel}: ${d.data.year}<br>${subgroupName}: ${subgroupValue}`;

              

              tooltip
                .html(tooltipContent)
                .style("opacity", 1)
                .style("left", (event.offsetX + 30) + "px")
                .style("top", (event.offsetY) + "px");
            }
          }
        })
        .on("mouseleave", function (event, d) {
          tooltip.transition().duration(100).style("opacity", 0);
        })

      !resized &&
        g.selectAll('g')
          .transition() // Apply transition to animate changes
          .duration(1000)

      if (show_Line) {
        createLineChart(data, datakeys, props.chart_color);
      }

      function createLineChart(data, keys, colors) {
        const stack = d3.stack().keys(keys);
        const series = stack(mod_data);

        keys.forEach((key, index) => {
          const lineGenerator = d3.line()
            .x(d => x(d.data.year) + x.bandwidth() / 2) // Center the line within the band
            .y(d => y(d[1])) // Use d[1] for y position

          if (curved_line) {
            lineGenerator.curve(d3.curveCatmullRom.alpha(0.5));
          }
          g.append('path')
            .datum(series[index]) // Use series[index] instead of data
            .attr('class', `line-${key}`)
            .attr('d', lineGenerator)
            .attr('fill', 'none')
            .attr('stroke', 'black')
            .attr('stroke-width', 2.5)
            .attr('stroke-dasharray', '5,5');

          series[index].forEach((point, pointIndex) => {
            const xscale = x(point.data.year) + x.bandwidth() / 2;
            const yscale = y(point[1]);


            if (!show_Square) {
              g.append('rect') // Changed from circle to rect
                .datum(point)
                .attr('class', `node-${key}`)
                .attr('x', xscale - 5) // Adjust the position to center the square
                .attr('y', yscale - 5) // Adjust the position to center the square
                .attr('width', 10) // Width of the square
                .attr('height', 10) // Height of the square
                .attr('fill', "pink")
                .on('mouseover', function (event, d) {
                  const tooltipX = event.pageX + 10;
                  const tooltipY = event.pageY - 50;
            
                  let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                  if (tooltip.empty()) {
                    tooltip = d3.select('body').append('div')
                      .attr('id', 'tooltip')
                      .attr('class', 'tooltip')
                      .style('position', 'absolute')
                      .style('background-color', 'white')
                      .style('border', '1px solid black')
                      .style('padding', '5px')
                      .style('opacity', 0);
                  }
            
                  tooltip.html(`Value: ${d[1] - d[0]},${barLabel}: ${d.data.year}`)
                    .style("left", `${tooltipX}px`)
                    .style("top", `${tooltipY}px`)
                    .style('opacity', 0.9).raise();
                })
                .on('mouseout', function (event, d) {
                  d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip')
                    .style('opacity', 0);
                });
            
            } else {
              g.append('circle')
                .datum(point)
                .attr('class', `node-${key}`)
                .attr('cx', xscale)
                .attr('cy', yscale)
                .attr('r', 4)
                .attr('fill', "green")
                .on('mouseover', function (event, d) {
                  const tooltipX = event.pageX + 10;
                  const tooltipY = event.pageY - 50;
            
                  let tooltip = d3.select(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                  if (tooltip.empty()) {
                    tooltip = d3.select('body').append('div')
                      .attr('id', 'tooltip')
                      .attr('class', 'tooltip')
                      .style('position', 'absolute')
                      .style('background-color', 'white')
                      .style('border', '1px solid black')
                      .style('padding', '5px')
                      .style('opacity', 0);
                  }
            
                  tooltip.html(`Value: ${d[1] - d[0]}, ${barLabel}: ${d.data.year}`)
                    .style("left", `${tooltipX}px`)
                    .style("top", `${tooltipY}px`)
                    .style('opacity', 0.9).raise();
                })
                .on('mouseout', function (event, d) {
                  d3.select(fullScreen_enabled ? `#tooltip${i}` : '#tooltip')
                    .style('opacity', 0);
                });
            }
            

            // if (!show_Square) {
            //   g.append('rect') // Changed from circle to rect
            //     .datum(point)
            //     .attr('class', `node-${key}`)
            //     .attr('x', xscale - 5) // Adjust the position to center the square
            //     .attr('y', yscale - 5) // Adjust the position to center the square
            //     .attr('width', 10) // Width of the square
            //     .attr('height', 10) // Height of the square
            //     .attr('fill', "pink")
            //     .on('mouseover', function (event, d) {
            //       const tooltipX = event.pageX + 10;
            //       const tooltipY = event.pageY - 50;

            //       let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
            //       if (tooltip.empty()) {
            //         tooltip = d3.select('body').append('div')
            //           .attr('id', 'tooltip')
            //           .attr('class', 'tooltip')
            //           .style('position', 'absolute')
            //           .style('background-color', 'white')
            //           .style('border', '1px solid black')
            //           .style('padding', '5px')
            //           .style('opacity', 0)
            //       }

            //       tooltip.html(`Value: ${d[1] - d[0]}, Year: ${d.data.year}`)
            //         .style("left", `${tooltipX}px`)
            //         .style("top", `${tooltipY}px`)
            //         .style('opacity', 0.9).raise()
            //     })
            //     .on('mouseout', function (event, d) {
            //       d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip')
            //         .style('opacity', 0);
            //     });

            // } else {
            //   g.append('circle')
            //     .attr('class', `node-${key}`)
            //     .attr('cx', xscale)
            //     .attr('cy', yscale)
            //     .attr('r', 4)
            //     .attr('fill', "green")
            //     .on('mouseover', function (event, d) {

            //       const tooltipX = event.pageX + 10;
            //       const tooltipY = event.pageY - 50;

            //       let tooltip = d3.select(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
            //       if (tooltip.empty()) {
            //         tooltip = d3.select('body').append('div')
            //           .attr('id', 'tooltip')
            //           .attr('class', 'tooltip')
            //           .style('position', 'absolute')
            //           .style('background-color', 'white')
            //           .style('border', '1px solid black')
            //           .style('padding', '5px')
            //           .style('opacity', 0);
            //       }

            //       tooltip.html(`Value: ${point[1]}, Year: ${point.data.year}`)
            //         .style("left", `${tooltipX}px`)
            //         .style("top", `${tooltipY}px`)
            //         .style('opacity', 0.9).raise()
            //     })
            //     .on('mouseout', function (event, d) {
            //       d3.select(fullScreen_enabled ? `#tooltip${i}` : '#tooltip')
            //         .style('opacity', 0);
            //     });
            // }
          });
        });
      }
      //___________________________________________________************* Stack LINE CHART  ENDS ****************************************______________________________________________________________________

      // g.selectAll('g')
      //   .data(series)
      //   .selectAll('text')
      //   .data(d => d)
      //   .enter().append('text')
      //   .attr('class', 'barValues')

      //   .attr('x', d => x(d.data.year) + x.bandwidth() / 2)
      //   .attr('y', d => y(d[1]) + (y(d[0]) - y(d[1])) / 2)
      //   .text(d => d3.format(",")(d[1] - d[0]))
      //   .attr('text-anchor', 'middle')
      //   .attr('dy', '0.35em') // Adjust the vertical alignment if needed
      //   .style('fill', 'black')// Adjust the text color if needed
      //   .style("display", function () {
      //     return showvalues ? "block" : "none";
      //   });




      // svg.append('g')
      // .selectAll('g')
      // .data(series)
      // .enter().append('g')
      // .attr('fill', d => color(d.key))
      // .selectAll('rect')
      // .data(d => d)
      // .enter().append('rect')
      // .attr('x', d => x(d.data.year))
      // .attr('y', d => y(d[1]))
      // .attr('height', d => y(d[0]) - y(d[1]))
      // .attr('width', x.bandwidth());
    
    const textGroup = g.selectAll('g')
    
    const renderText = () => {
      textGroup.selectAll('text').remove();
      series.forEach((seriesData, seriesIndex) => {
        textGroup.selectAll(`.text-${seriesIndex}`)
          .data(seriesData)
          .enter().append('text')
          .attr('class', `text-${seriesIndex}`)
          .attr('x', d => x(d.data.year) + x.bandwidth() / 2)
          .attr('y', d => y(d[1]) + (y(d[0]) - y(d[1])) / 2)
          .text(d => d3.format(",")(d[1] - d[0]))
          .attr('text-anchor', 'middle')
          .attr('dy', '0.35em')
          .style('fill', text_color(datakeys[seriesIndex]))
          // .style('fill', 'green')

          .style("display", function () {
            return showvalues ? "block" : "none";
          });
      });
    };
    
    renderText();
    
    // const controls = d3.select('#controls');
    // datakeys.forEach((key, index) => {
    //   controls.append('label')
    //     .text(`${key} color: `)
    //     .append('input')
    //     .attr('type', 'color')
    //     .attr('value', text_color_arr[index])
    //     .on('input', function() {
    //       text_color_arr[index] = this.value;
    //       text_color.range(text_color_arr);
    //       renderText();
    //     });
    //   controls.append('br');
    // });
    

      // Create X-axis
      const axisLabels = g.append('g')
        .attr('class', `x-axis`)
        .attr('transform', `translate(0, ${containerHeight - marginBottom - marginTop})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'middle')
        .style('font-size', '14px')
        .attr('fill', 'black')
        .style("text-transform", "capitalize")
        .style("font-weight", (d, i) => i % 2 === 0 ? "bold" : "normal") // Apply different font weights
        .style('cursor', 'pointer')


      let rotationAngle = 0; // Variable to track the rotation angle
      axisLabels.each(function (data, i) {
        const label = this;
        d3.select(label).on('click', function () {
          const currentRotation = rotationAngle === 0 ? -45 : 0;
          const currentAnchor = rotationAngle === 0 ? 'end' : "middle";
          axisLabels.attr('transform', `rotate(${currentRotation})`)
            .style("text-anchor", currentAnchor)
          rotationAngle = currentRotation;
        });
      });

      g.append('g')
        .call(d3.axisLeft(y).ticks(5).tickSize(0))

      if (show_grid_enabled) {
        svg.append("g")
          .selectAll("line")
          .attr('class', 'x-grid')
          .data(mod_data)
          .join("line")
          .attr("x1", (d) => x(d.year) + x.bandwidth() / 2)
          .attr("x2", (d) => x(d.year) + x.bandwidth() / 2)
          .attr("y1", 0)
          .attr("y2", containerHeight - marginBottom - marginTop)
          .attr("stroke", "lightgrey")

        svg.append('g')
          .attr('class', 'y-grid')
          .attr('transform', `translate(${marginLeft},${marginTop - marginBottom + 10})`) // Adjust the y-axis grid position
          .call(d3.axisLeft(y)
            .tickSize(-(containerWidth - marginLeft - marginRight))
            .tickFormat('')
            .ticks(5) // Adjust the number of ticks based on the desired granularity
          )
          .select('.domain , line')
          .remove(); // Remove the y-axis line

        svg.selectAll('.x-grid .tick line')
          .attr('class', 'x-grid-line')
          .attr('stroke', 'lightgrey')
          .attr('opacity', 0.5);

        svg.selectAll('.y-grid .tick line')
          .attr('class', 'y-grid-line')
          .attr('stroke', 'lightgrey')
          .attr('opacity', 0.5);

      }
      else {
        svg.selectAll('.grid').remove();
      }


      const newWidth = Math.max(temp_barWidth + margin.left + margin.right, containerWidth); // Ensure new width is at least as wide as containerWidth
      setChrtWidth(BarWidth !== undefined ? newWidth : containerWidth)


      const yAxisContainer = d3.select(`#my_dataviz${i}`)
        .attr('class', 'y-axis')
        .append("div") // Use a separate div for the y-axis container
        .style("position", "absolute")
        .style("top", `${0}px`) // Adjust the top position as needed
        .style("left", "0")
        .style("width", `${marginLeft}px`) // Adjust the width as needed
        .style("height", `${containerHeight - 80}px`);

      const yAxis = yAxisContainer.append("svg")
        .attr("width", '100%')
        .attr("height", containerHeight)
        .append("g")
        .attr("transform", `translate(${marginLeft},${marginTop})`)
        .call(d3.axisLeft(y).ticks(fullScreen_enabled ? 20 : containerHeight / 50))
        .selectAll('.domain, text')
        .attr('stroke', fullScreen_enabled ? 'black' : 'black')
        .style("font-size", '10px')
        .call(g => g.select(".domain").remove())

      // Adjust the position of the y-axis line
      yAxis.select(".domain")
        .attr("transform", `translate(${-60}, 0)`); // Set the desired X position

      // Change the color of the y-axis line
      yAxis.select(".domain")
        .style("stroke", 'green'); // Set the desired color

      // Change the thickness of the y-axis line
      yAxis.select(".domain")
        .style("stroke-width", 2); // Set the desired line width

      yAxis.selectAll("text")

        .attr('class', 'yAxis-text')
        .attr("x", -10)
        .attr('fill', 'black')
        .attr("dx", "-3.99em")
        .style('font-weight', 'bold')
        .style("font-size", '12px');// y-axis value color

      yAxis.selectAll("line")
        .attr("transform", `translate(${-50}, 0)`)
        .attr('stroke', 'black')
        .attr("dx", "-2em");// y-axis value color

      var datakeys_mod

      if (YLabel.length > 0) {
        datakeys_mod = YLabel.slice(1)
      }
      else {
        datakeys_mod = datakeys
      }
      var datakeys_mod

      if (YLabel.length > 0) {
        datakeys_mod = YLabel.slice(1)
      }
      else {
        datakeys_mod = datakeys
      }

      // const legendContainer = d3.select(`#legend${i}`);
      // const legendWidth = datakeys.length * 120;
      // const legendX = (containerWidth - legendWidth) / 2;
      // const legendY = height + margin.bottom - 40;
      // legendContainer.selectAll('*').remove(); // Clear previous legend content

      // datakeys_mod.forEach((key, i) => {
      //   const legendItemGroup = legendContainer.append('div')
      //     .attr('class', 'legend-item')

      //   legendItemGroup.append('div')
      //     .attr('class', 'legend-rect')
      //     .style('background-color', (d) => {
      //       // var colorToUse = (chart_color?.length > 0 && chart_color[i + 1] != null) ? chart_color[i] : color(key);
      //       var colorToUse = (chart_color?.length > 0 ? (chart_color[i] != undefined) ? chart_color[i] : chart_color[i + 1] : color(key));
      //       // console.log("Color to use:", colorToUse, color(i + 1), chart_color, color(key));
      //       // console.log('colorToUse', colorToUse)
      //       return colorToUse;
      //     })
      //   legendItemGroup.append('text')
      //     .attr('fill', color(key))
      //     .text(key)
      // });



      const legendContainer = d3.selectAll(`#legend${i}`);
      const legendWidth = datakeys.length * 120;
      const legendX = (containerWidth - legendWidth) / 2;
      const legendY = height + margin.bottom - 40;
      legendContainer.selectAll('*').remove(); // Clear previous legend content

      datakeys_mod.forEach((key, i) => {
        const legendItemGroup = legendContainer.append('div')
          .attr('class', 'legend-item')
          .style('display', 'flex')
          .style('align-items', 'center')
          .style('margin-right', '10px'); // Add some space between legend items

        legendItemGroup.append('div')
          .attr('class', 'legend-rect')
          .style('width', '13px')
          .style('height', '13px')
          .style('background-color', () => {
            var colorToUse = (chart_color?.length > 0 ? (chart_color[i] != undefined) ? chart_color[i] : chart_color[i + 1] : color(key));
            return colorToUse;
          })
          .style('margin-right', '5px'); // Space between rectangle and text

        legendItemGroup.append('text')
          .attr('class', 'legend-text')
          .style('color', 'green')
          .text(key);
      });


      if (enable_table) {
        show_table_fn(true)
      }
      else {
        show_table_fn(false)
      }

      function zoomed(event) {
        const { transform } = event;
        const delta = event.sourceEvent.deltaX;
        const factor = 1 + delta / containerWidth; // Adjust the factor based on the delta and chart width
        const newXDomain = x.domain().map(d => { return d }); // Update the x-domain
        const newXScale = x.domain(newXDomain); // Update the x-scale with the new domain
        const newBarWidth = x.bandwidth() // Adjust the bar width
        let calc = containerWidth / 100

        x.range([marginLeft, containerWidth - marginRight].map(d => event.transform.applyX(d))); //containerwidth
        svg.selectAll("path").remove();
        g.selectAll(".circle-node, .square-node").remove();
        svg.selectAll(".bar-label").remove();
        g.selectAll(".x-grid , node").remove();
        svg.selectAll(".y-grid").remove();
        svg.selectAll(".domain, line").remove();
        g.selectAll('.barValues').remove();
        svg.select(".x-axis").call(d3.axisBottom(newXScale));

        datakeys.forEach((key, index) => {
          d3.selectAll(`.node-${key}`).remove();
        })

        if (show_Line) {
          createLineChart(data, datakeys, props.chart_color);
        }

        seriesGroups.selectAll("rect")
          .attr("x", d => newXScale(d.data.year))
          .attr("width", x.bandwidth())
          .style('cursor', 'pointer')

        svg
          .attr("transform", `translate(${0},${margin.top})`);

        // g.selectAll('g')
        //   .data(series)
        //   .selectAll('text')
        //   .data(d => d)
        //   .enter().append('text')
        //   .attr('class', 'barValues')

        //   .attr('x', d => x(d.data.year) + x.bandwidth() / 2)
        //   .attr('y', d => y(d[1]) + (y(d[0]) - y(d[1])) / 2)
        //   .text(d => d3.format(",")(d[1] - d[0]))
        //   .attr('text-anchor', 'middle')
        //   .attr('dy', '0.35em') // Adjust the vertical alignment if needed
        //   .style('fill', 'black')// Adjust the text color if needed
        //   .style("display", function () {
        //     return showvalues ? "block" : "none";
        //   });

          renderText();
        d3.select(`.chart-box${i}`)
          .attr("x", marginLeft)
          .attr("y", 0)
          .attr("width", containerWidth - marginLeft) //containerWidth
          .attr("height", containerHeight - marginTop - marginBottom)
          .attr("fill", "none")
          .attr("stroke", "black")

        if (show_grid_enabled) {
          svg.append("g")
            .selectAll("line")
            .attr('class', 'x-grid')
            .data(mod_data)
            .join("line")
            .attr("x1", (d) => x(d.year) + x.bandwidth() / 2)
            .attr("x2", (d) => x(d.year) + x.bandwidth() / 2)
            .attr("y1", 0)
            .attr("y2", containerHeight - marginBottom - marginTop)
            .attr("stroke", "lightgrey");

          svg.append('g')
            .attr('class', 'y-grid')
            .attr('transform', `translate(${marginLeft},${marginTop - marginBottom + 10})`) // Adjust the y-axis grid position
            .call(d3.axisLeft(y)
              .tickSize(-(containerWidth - marginLeft - marginRight))
              .tickFormat('')
              .ticks(5) // Adjust the number of ticks based on the desired granularity
            )

          svg.selectAll('.y-grid .tick line')
            .attr('class', 'y-grid-line')
            .attr('stroke', 'lightgrey')
        }

      }

      const handleResetButtonClick = () => {
        d3.selectAll(`my_dataviz${i}`).select('.node').remove()
        d3.selectAll('.barValues').remove();
        svg.selectAll(".bar-label").remove();
        g.selectAll(".x-grid , node").remove();
        svg.selectAll(".y-grid").remove();
        svg.selectAll("path").remove();
        g.selectAll(".circle-node, .square-node").remove();
        svg.selectAll(".circle-node, .square-node").remove();
        svg.selectAll(".domain, line").remove();

        const newXScale = x.range([marginLeft, containerWidth - marginRight]);
        svg.attr("width", containerWidth);
        svg.select(".x-axis").call(d3.axisBottom(newXScale));

        seriesGroups.selectAll("rect")
          .attr("x", d => newXScale(d.data.year))
          .attr("width", newXScale.bandwidth());



        g.selectAll('g')
          .data(series)
          .selectAll('text')
          .data(d => d)
          .enter().append('text')
          .attr('class', 'barValues')

          .attr('x', d => x(d.data.year) + x.bandwidth() / 2)
          .attr('y', d => y(d[1]) + (y(d[0]) - y(d[1])) / 2)
          .text(d => d3.format(",")(d[1] - d[0]))
          .attr('text-anchor', 'middle')
          .attr('dy', '0.35em') // Adjust the vertical alignment if needed
          .style('fill', 'black')// Adjust the text color if needed
          .style("display", function () {
            return showvalues ? "block" : "none";
          });


        if (show_grid_enabled) {
          d3.selectAll('.x-grid').remove()

          g.selectAll(".x-grid").remove();
          svg.selectAll(".y-grid").remove();
          svg.selectAll(".domain, line").remove();

          svg.select(".x-axis").call(d3.axisBottom(newXScale));

          svg.append('g')
            .attr('class', 'y-grid')
            .attr('transform', `translate(${marginLeft},${marginTop - marginBottom + 10})`)
            .call(d3.axisLeft(y)
              .tickSize(-(containerWidth - marginLeft - marginRight))
              .tickFormat('')
              .ticks(5) // Adjust the number of ticks based on the desired granularity
            )
            .select('.domain , line')
            .remove()

          svg.selectAll('.y-grid .tick line')
            .attr('class', 'y-grid-line')
            .attr('stroke', 'lightgrey')
            .attr('opacity', 0.5);


          svg.append("g")
            .selectAll("line")
            .attr('class', 'x-grid')
            .data(mod_data)
            .join("line")
            .attr("x1", (d) => newXScale(d.year) + x.bandwidth() / 2)
            .attr("x2", (d) => newXScale(d.year) + x.bandwidth() / 2)
            .attr("y1", 0)
            .attr("y2", containerHeight - marginBottom - marginTop)
            .attr("stroke", "lightgrey");
        }


        if (show_Line) {

          datakeys.forEach((key, index) => {
            d3.selectAll(`.node-${key}`).remove();
          })
          createLineChart(data, datakeys, props.chart_color);
        }
        function createLineChart(data, keys, colors) {
          const stack = d3.stack().keys(keys);
          const series = stack(mod_data);
          keys.forEach((key, index) => {
            const lineGenerator = d3.line()
              .x(d => x(d.data.year) + x.bandwidth() / 2) // Center the line within the band
              .y(d => y(d[1])) // Use d[1] for y position
            if (curved_line) {
              lineGenerator.curve(d3.curveCatmullRom.alpha(0.5));
            }
            g.append('path')
              .datum(series[index]) // Use series[index] instead of data
              .attr('class', `line-${key}`)
              .attr('d', lineGenerator)
              .attr('fill', 'none')
              .attr('stroke', 'black')
              .attr('stroke-width', 2.5)
              .attr('stroke-dasharray', '5,5');
            series[index].forEach((point, pointIndex) => {
              const xscale = x(point.data.year) + x.bandwidth() / 2;
              const yscale = y(point[1]);
              if (!show_Square) {
                g.append('rect') // Changed from circle to rect
                  .datum(point)
                  .attr('class', `node-${key}`)
                  .attr('x', xscale - 5) // Adjust the position to center the square
                  .attr('y', yscale - 5) // Adjust the position to center the square
                  .attr('width', 10) // Width of the square
                  .attr('height', 10) // Height of the square
                  .attr('fill', "pink")
                  .on('mouseover', function (event, d) {
                    const tooltipX = event.pageX + 10;
                    const tooltipY = event.pageY - 50;
                    let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                    if (tooltip.empty()) {
                      tooltip = d3.select('body').append('div')
                        .attr('id', 'tooltip')
                        .attr('class', 'tooltip')
                        .style('position', 'absolute')
                        .style('background-color', 'white')
                        .style('border', '1px solid black')
                        .style('padding', '5px')
                        .style('opacity', 0);
                    }
                    // tooltip.html(`${barLabel}: ${d.year}<br>Value: ${d.value}`)
                    tooltip.html(`Value: ${d[1] - d[0]}<br>${barLabel}: ${d.data.year}`) // Added <br> for line break
                      .style("left", `${tooltipX}px`)
                      .style("top", `${tooltipY}px`)
                      .style('opacity', 0.9);
                  })
                  .on('mouseout', function (event, d) {
                    let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                    tooltip
                      .style('opacity', 0);
                  });
              } else {
                g.append('circle')
                  .attr('class', `node-${key}`)
                  .attr('cx', xscale)
                  .attr('cy', yscale)
                  .attr('r', 4)
                  .attr('fill', "green")
                  .on('mouseover', function (event, d) {
                    const tooltipX = event.pageX + 10;
                    const tooltipY = event.pageY - 50;
                    // let tooltip = d3.select('#tooltip');
                    let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                    if (tooltip.empty()) {
                      tooltip = d3.select('body').append('div')
                        .attr('id', 'tooltip')
                        .attr('class', 'tooltip')
                        .style('position', 'absolute')
                        .style('background-color', 'white')
                        .style('border', '1px solid black')
                        .style('padding', '5px')
                        .style('opacity', 0);
                    }

                    // tooltip.html(`Value: ${d[1] - d[0]}<br>${barLabel}: ${d.data.year}`) // Added <br> for line break

                    tooltip.html(`Value: ${point[1]}<br>${barLabel}: ${point.data.year}`) // Added <br> for line break
                      .style("left", `${tooltipX}px`)
                      .style("top", `${tooltipY}px`)
                      .style('opacity', 0.9).raise()
                  })
                  .on('mouseout', function (event, d) {
                    let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                    tooltip
                      .style('opacity', 0);
                  });
              }
            });
          });
        }


      };
      document.getElementById(`togglereset-${i}`).addEventListener('click', function (event) {
        handleResetButtonClick();
      });
    }
  }, [containerWidth, BarWidth, containerHeight, text_Color_bar, showvalues, mouseover_enabled, enabled_table, mouseover_switch_type, chart_color, show_grid_enabled, temp_containerWidth, fullScreen_enabled, temp_containerHeight, sortdata, showLine, YLabel, enable_table, chart_height, enabled_table, svgHeight, curved_line, show_Square, resized, SortArr,text_color_arr]);

  const handleMenuClick = (e) => {
    setShowOptions(!showOptions);
  };


  const show_table_fn = async (val) => {
    const fieldNames = Object.keys(data[0]).filter(key => key !== "_id");
    if (val) {
      await tabulate(data, fieldNames)
    }
    else {
      setshowDiv(false)
      d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
    }
  }

  const tabulate = async (data, columns, y_axis_name) => {
    const header = columns;
    var data_exist;
    if (data !== undefined) {
      data_exist = data;
    } else {
      data_exist = data;
    }
    var tableContainer = document.getElementById(`tableContainer${i}`);

    if (tableContainer !== null) {
      tableContainer.innerHTML = ""; // Clear previous content

    }


    var table = d3.select(`#tableContainer${i}`)
      .attr("class", "table-responsive")
      .append("table")
      .style("width", `${fullScreen_enabled ? temp_containerWidth : props.containerWidth - 12}px`);

    var thead = table.append("thead");
    var tbody = table.append("tbody");

    d3.select(tableContainer)
      .attr('class', 'table_body')
      .style("overflow-y", "scroll")
      .style("overflow-x", "hidden");

    thead.append("tr")
      .selectAll("th")
      .data(header)
      .enter()
      .append("th")
      .text(function (column) { return column; })
      .attr("style", "text-align: center")
      .style('color', 'black')

    var rows = tbody.selectAll("tr")
      .data(data_exist)
      .enter()
      .append("tr");

    var cells = rows.selectAll("td")
      .data(function (row) {
        return columns.map(function (column) {
          return { column: column, value: row[column] };
        });
      })
      .enter()
      .append("td")
      .attr("class", function (d) { return "cell " + d.column; })
      .html(function (d) { return d.value; })
      .attr("style", "text-align: center")
      .style('color', 'black')
    return table;
  }

  const handleSortIconClick = (e) => {
    setshowsortoption(!sortshowOptions)
  };

  const handlesort = () => {
    var chart_id = i;
    dispatch(sortFunc({ data, arr_values, chart_id }));
  }

  const handlesortdesc = () => {
    var chart_id = i;
    dispatch(sortDescending({ data, arr_values, chart_id }));
  }

  const handleSortDefault = () => {
    dispatch(sortInfo({ data: chart_data, chart_id: i })); // Assuming 'i' is the chart_id
    setsortdata([...chart_data]); // Update local state with the original data
    setSortingField(null); // Reset any sorting field
  };


  const handleCheckboxChange = (e, value) => {
    if (e.target.checked) {
      setarr_values((prevData) => [
        ...prevData,
        value
      ]);
    } else {
      setarr_values(arr_values.filter((val) => val !== value));
    }
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((val) => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  return (
    <div>
      <div id={`tooltip${i}`} style={{ position: 'absolute', opacity: 0, background: 'lightgray', padding: '10px', borderRadius: '5px' }} ></div>
      <div className="chart-container" >
        <div id={`my_dataviz${i}`} style={{ maxWidth: '100%', }} onMouseLeave={() => { setShowOptions(false); setshowsortoption(false); }} >
          <svg ref={chartRef} width={(fullScreen_enabled ? (BarWidth === undefined ? temp_containerWidth : chartWidth) : chartWidth)} height={(fullScreen_enabled ? temp_containerHeight : containerHeight)}>
          </svg>
        </div>
      </div>

      <div id={`legend${i}`} style={{ maxWidth: containerWidth / 2, minWidth: '200px', overflowX: 'auto', position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'centre', marginLeft: (fullScreen_enabled ? temp_containerWidth : containerWidth) / 4, marginTop: '-40px', boxShadow: 'none' }}></div>

      {showOptions && (
        <div
          className="download-options"
          style={{
            position: 'absolute',
            top: '46px',
            right: '70px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            color: '#000080',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            cursor: 'pointer',
            justifyContent: 'center'
          }}
          onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
        >


          <p onClick={() => handleDownload_bar('0', datakeys_name, datakeys, data)}>Download as CSV</p>

          <p onClick={() => img_download_svg(`my_dataviz${i}`)}>Download as SVG</p>

          <p onClick={() => img_download_png_mod(i)} className='mt-1'>Download as PNG</p>
        </div>
      )}
      <span>
        <i
          className="bx bx-sort"
          style={{
            cursor: 'pointer',
            fontSize: '20px',
            width: '35px',
            position: 'absolute',
            top: '9px',
            right: '95px',
            zIndex: '1',
            color: '#6666B2',
            height: '50px',
            backgroundColor: ''
          }}
          onMouseOver={() => { handleSortIconClick(); setShowOptions(false); }} onMouseOut={() => { setshowsortoption(false) }}
        ></i>
      </span>
      <div>


        <i className="bx bx-reset"
          style={{
            cursor: 'pointer',
            fontSize: '20px',
            width: '35px',
            position: 'absolute',
            top: '9px',
            right: '250px',
            zIndex: '1',
            color: '#6666B2',
          }}

          id={`togglereset-${i}`}
        ></i>


        <i
          className="bx bx-download"
          style={{
            cursor: 'pointer',
            fontSize: '25px',
            width: '40px',
            position: 'absolute',
            top: '7px',
            right: '60px',
            zIndex: '1',
            color: '#6666B2',
            height: '50px',
            backgroundColor: 'transparent'
          }}
          onMouseOver={() => { handleMenuClick(); setshowsortoption(false); }}
          onMouseUp={() => { setShowOptions(false); }}
        ></i>
      </div>
      {sortshowOptions && (
        <div
          onMouseOver={() => setshowsortoption(true)}
          className="download-options"
          style={{
            position: 'absolute',
            top: '45px',
            right: '116px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            color: '#000080',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            boxShadow: 'none',
            // maxHeight:'90px',
            maxHeight: (`${containerHeight - 120}px`),
            overflow: 'auto',
            zIndex: '5',
          }}

        >

          {selectedValues.length > 0 && (
            <div style={{ marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button className='btn btn-sm btn-primary me-2' onClick={handlesort}>
                  Ascending
                </button>
                <button className='btn btn-sm btn-primary me-2' onClick={handlesortdesc}>
                  {/* onClick={handleSortDescending} */}
                  Descending
                </button>
                <button className='btn btn-sm btn-primary me-2' onClick={handleSortDefault} >
                  {/* onClick={handleSortDefault} */}
                  Default
                </button>
              </div>
            </div>
          )}

          <div>
            {datakeys.map((value) => (
              <div key={value} style={{ marginBottom: '10px', boxShadow: 'none' }}>
                <label>
                  <input
                    type="checkbox"
                    value={value}
                    checked={selectedValues.includes(value)}
                    onChange={(e) => handleCheckboxChange(e, value)}
                  />
                  {value.toUpperCase()}
                </label>
              </div>
            ))}
          </div>

        </div>
      )}
      {isLoading &&
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>}

      {enabled_table ? (
        <>
          <div style={{
            //  position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: '#fff',
            height: (fullScreen_enabled ? '240px' : '200px')
          }} id={`tableContainer${i}`}>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default D3Stack_bar_chart;






//pravis code 24 june 24
// import React, { useRef, useEffect, useState } from 'react';
// import * as d3 from 'd3';
// import { useDispatch, useSelector } from 'react-redux';
// import { sortFunc, sortDescending, sortInfo, handleDownloadBar, imgDownloadSvg, imgDownloadPng} from '../../../../store/reportd3/reportslice';

// const StackChart = (props) => {
//     const dispatch = useDispatch();
//     var containerWidth = props.containerWidth
//     var containerHeight = props.containerHeight
//     var chart_data = props.chart_data
//     var svgHeight = props.chart_height
//     var BarWidth = props.BarWidth
//     var chart_color = props.chart_color
//     var i = props.id
//     var show_bar_values = props.show_bar_values
//     var mouseovered = props.mouseovered
//     var mouseovered_type = props.mouseovered_type
//     var enable_table = props.show_table
//     var show_Grid = props.show_Grid
//     var show_Line = props.show_Line
//     var temp_containerWidth = props.temp_containerWidth
//     var temp_containerHeight = props.temp_containerHeight
//     var fullScreen_enabled = props.show_Full_Screen_toggle
//     var show_Square = props.show_Square
//     var curved_line = props.curved_line;
//     var YLabel = props.YLabel
//     var resized = props.resized
//     var text_color_arr = props.text_color

//     const chartRef = useRef();
//     const [data, setData] = useState(chart_data);
//     const [textColorbar, settextColorbar] = useState([])
//     const [showOptions, setShowOptions] = useState(false)
//     const [isLoading, setIsLoading] = useState(false);
//     const [showValues, setshowValues] = useState(show_bar_values);
//     const [mouseoverEnabled, setMouseoverEnabled] = useState(mouseovered)
//     const [mouseoverSwitchtype, setMouseoverSwitchtype] = useState(mouseovered_type)
//     const [enabledTable, setEnabledTable] = useState(enable_table)
//     const [showGridenabled, setShowGridenabled] = useState(show_Grid)
//     const [sortShowOptions, setSortShowOptions] = useState(false);
//     const [selectedValues, setSelectedValues] = useState([]);
//     const [arrValues, setArrValues] = useState([])
//     const [chart_height, setchart_height] = useState(svgHeight)
//     const [showLine, setShowLine] = useState(true);
//     const [sortOrder, setSortOrder] = useState('')
//     const [chartWidth, setChrtWidth] = useState(BarWidth === undefined ? containerWidth : '200')
//     const SortArr = useSelector(state => state.reportSliceReducer);
//     const [sortData, setSortData] = useState([]);

//     useEffect(() => {
//         if (chart_data !== undefined && chart_data.length > 0) {
//             setData(chart_data)
//             settextColorbar(chart_color)
//             setshowValues(show_bar_values)
//             setMouseoverEnabled(mouseovered)
//             setMouseoverSwitchtype(mouseovered_type)
//             setEnabledTable(enable_table)
//             setShowGridenabled(show_Grid)
//             setShowLine(show_Line)
//             setchart_height(svgHeight)
//         }

//     }, [chart_data, chart_color, show_bar_values, mouseovered, mouseovered_type, enable_table, show_Grid, temp_containerWidth, fullScreen_enabled, temp_containerHeight, show_Line, svgHeight])


//     if (props.chart_data.length !== 0) {
//         var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
//         var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
//     }
//     const margin = { top: 70, right: 30, bottom: 80, left: 40 };


//     useEffect(() => {
//         if (sortOrder === 'asc') {
//             handleSortAscending()
//         }
//         else if (sortOrder === 'desc') {
//             handleSortDescending()
//         }
//         else {
//             handleSortDefault()
//         }

//     }, [chart_data])

//     useEffect(() => {
//         var mod_data;
//         var chart_id = i;
//         if (datakeys !== undefined) {
//             const marginTop = margin.top;
//             const marginRight = margin.right;
//             const marginBottom = margin.bottom;
//             const marginLeft = margin.left;

//             var sortSlice = SortArr[chart_id]?.sortedData;
//             if (sortSlice && sortSlice.length !== 0) {
//                 mod_data = sortSlice;
//             } else {
//                 mod_data = data;
//             }

//             if (svgHeight !== undefined && svgHeight !== '') {
//                 containerHeight = containerHeight - 200
//             }
//             else {
//                 containerHeight = containerHeight
//             }
//             var width
//             var height

//             if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
//                 width = temp_containerWidth - margin.left - margin.right;
//                 height = temp_containerHeight - margin.top - margin.bottom - (enabledTable ? 200 : 0)
//                 containerHeight = temp_containerHeight - (enabledTable ? 200 : 0)
//                 containerWidth = temp_containerWidth
//             }
//             else {
//                 width = containerWidth - margin.left - margin.right;
//                 height = containerHeight - margin.top - margin.bottom;
//             }
//             const temp_barWidth = (BarWidth !== undefined ? Number(BarWidth) : 150)
//             const x = d3.scaleBand()
//                 .domain(mod_data.map(d => d.year))
//                 .range([marginLeft, mod_data.length * temp_barWidth - marginRight])
//                 .padding(0.1);

//             const y = d3.scaleLinear()
//                 .domain([0, d3.max(mod_data, d => d3.sum(datakeys.map(key => d[key])))]).nice()
//                 .range([containerHeight - margin.bottom - marginTop, 0]);

//             d3.selectAll(`#my_dataviz${i}`).selectAll("svg").remove();
//             d3.selectAll(`#my_dataviz${i}`).selectAll("div").remove();
//             d3.selectAll(`#my_dataviz${i}`).selectAll("div").remove();
//             d3.selectAll(`#my_dataviz${i}`).selectAll('svg').append('g').remove();
//             d3.selectAll('svg').selectAll('div').remove();
//             d3.select(`#my_dataviz${i}`).selectAll("div").remove();
//             d3.selectAll(`#my_dataviz${i}`).selectAll("div").remove();
//             d3.select(`#my_dataviz${i}`).selectAll("svg").remove();

//             const extent = [[marginLeft, marginTop], [containerWidth - marginRight, height - marginTop]];
//             const zoom = d3.zoom()
//                 .scaleExtent([1, 8])
//                 .translateExtent(extent)
//                 .extent(extent)
//                 .on("zoom", zoomed);

//             const svgContainer = d3.select(`#my_dataviz${i}`)
//                 .style("width", '100%')
//                 .style("overflow-x", "auto")
//                 .append("div")
//                 .style("width", `${containerWidth}px`)
//                 .style("height", `${containerHeight}px`)
//                 .style("overflow-y", "hidden")

//             const svg = svgContainer
//                 .append("svg")
//                 .attr("width", mod_data.length * temp_barWidth)
//                 .attr("height", containerHeight);

//             svg.call(zoom);

//             svg.append("rect")
//                 .attr("class", `chart-box${i}`)
//                 .attr("x", marginLeft)
//                 .attr("y", 0)
//                 .attr("width", mod_data.length * temp_barWidth - marginLeft - marginRight)
//                 .attr("height", containerHeight - marginTop - marginBottom)
//                 .attr("fill", "none")
//                 .attr("stroke", "black")

//             const g = svg
//                 .attr("transform", `translate(${0},${margin.top})`);

//             const color = d3.scaleOrdinal()
//                 .domain(datakeys)
//                 .range(chart_color !== undefined && chart_color.length > 0
//                     ? chart_color
//                     : d3.quantize(d3.interpolateRainbow, datakeys.length + 2));

//             const stack = d3.stack()
//                 .keys(datakeys);
//             const series = stack(mod_data);

//             const text_color = d3.scaleOrdinal()
//                 .domain(datakeys)
//                 .range(text_color_arr !== undefined && text_color_arr.length > 0
//                     ? text_color_arr
//                     : d3.quantize(d3.interpolateRainbow, datakeys.length + 2));

//             const tooltip = d3.select(`#my_dataviz${i}`)
//                 .append("div")
//                 .style("opacity", 0)
//                 .attr("class", "tooltip")
//                 .style("position", "absolute")
//                 .style("background-color", "white")
//                 .style("border", "solid")
//                 .style("border-width", "1px")
//                 .style("border-radius", "5px")
//                 .style("padding", "10px");
//             const seriesGroups = svg.selectAll(".series")
//                 .data(series)
//                 .enter().append("g")
//                 .attr("class", "series")
//                 .attr("fill", d => color(d.key));

//             seriesGroups.selectAll("rect")
//                 .data(d => d)
//                 .enter().append("rect")
//                 .attr("x", d => x(d.data.year))
//                 .attr("y", d => y(d[1]))
//                 .attr("height", d => y(d[0]) - y(d[1]))
//                 .attr("width", x.bandwidth())
//                 .on("mouseover", function (event, d) {

//                     var mouseX = d3.pointer(this)[0];

//                     var chartContainer = d3.select(`#my_dataviz${i}`).node();
//                     var chartContainerRect = chartContainer.getBoundingClientRect();
//                     if (mouseoverEnabled) {
//                         if (mouseoverSwitchtype) {
//                             var totalValue = d3.sum(datakeys.map(key => d.data[key]));
//                             d3.select(this).attr('class', 'highlighted');
//                             let tooltipContent = `<span style="color:red;">Label:</span> ${d.data.year}<br>`;
//                             datakeys.forEach(key => {
//                                 tooltipContent += `<span style="color:red;">${key}:</span> <span style="color:black;">${d.data[key]}</span><br>`;
//                             });
//                             tooltipContent += `<span style="color:red;">Total:</span> <span style="color:black;">${totalValue}</span>`;

//                             tooltip
//                                 .html(tooltipContent)
//                                 .style("opacity", 1)
//                             const tooltipNode = tooltip.node();
//                             const tooltipRect = tooltipNode.getBoundingClientRect();
//                             const tooltipWidth = tooltipRect.width;
//                             const tooltipHeight = tooltipRect.height;

//                             let leftPosition = event.pageX - chartContainerRect.left + 40;
//                             var topPosition = event.pageY - chartContainerRect.top - 10;

//                             if (leftPosition + tooltipWidth > containerWidth) {
//                                 leftPosition = event.pageX - chartContainerRect.left - tooltipWidth - 10;
//                             }

//                             if (topPosition + tooltipHeight > containerHeight) {
//                                 topPosition = event.pageY - chartContainerRect.top - tooltipHeight - 10;
//                             }


//                             tooltip.style("left", leftPosition + "px")
//                                 .style("top", topPosition + "px");
//                         } else {
//                             let tooltipContent = `<span style="color:red;">Label:</span> ${d.data.year}<br>`;

//                             datakeys.forEach(key => {
//                                 tooltipContent += `<span style="color:red;">${key}:</span> <span style="color:black;">${d.data[key]}</span><br>`;
//                             });
//                             tooltipContent += `<span style="color:red;">Total:</span> <span style="color:black;">${totalValue}</span>`;
//                             tooltip
//                                 .html(tooltipContent)
//                                 .style("opacity", 1)
//                                 .style("background-color", "white")
//                                 .style("color", "black")
//                                 .style("border", "solid")
//                                 .style("border-width", "1px")
//                                 .style("border-radius", "5px")
//                                 .style("padding", "10px")
//                                 .style("left", (event.offsetX + 30) + "px")
//                                 .style("top", (event.offsetY) + "px")

//                         }
//                     }
//                 })

//                 .on("mousemove", function (event, d) {
//                     var chartContainer = d3.select(`#my_dataviz${i}`).node();
//                     var chartContainerRect = chartContainer.getBoundingClientRect();

//                     if (mouseoverEnabled) {
//                         if (mouseoverSwitchtype) {
//                             var totalValue = d3.sum(datakeys.map(key => d.data[key]));
//                             d3.select(this).attr('class', 'highlighted');
//                             let tooltipContent = `<span style="color:red;">Label:</span> ${d.data.year}<br>`;
//                             datakeys.forEach(key => {
//                                 tooltipContent += `<span style="color:red;">${key}:</span> <span style="color:black;">${d.data[key]}</span><br>`;
//                             });
//                             tooltipContent += `<span style="color:red;">Total:</span> <span style="color:black;">${totalValue}</span>`;

//                             tooltip
//                                 .html(tooltipContent)
//                                 .style("opacity", 1)
//                             const tooltipNode = tooltip.node();
//                             const tooltipRect = tooltipNode.getBoundingClientRect();
//                             const tooltipWidth = tooltipRect.width;
//                             const tooltipHeight = tooltipRect.height;

//                             let leftPosition = event.pageX - chartContainerRect.left + 40;
//                             var topPosition = event.pageY - chartContainerRect.top - 10;

//                             if (leftPosition + tooltipWidth > containerWidth) {
//                                 leftPosition = event.pageX - chartContainerRect.left - tooltipWidth - 10;
//                             }

//                             if (topPosition + tooltipHeight > containerHeight) {
//                                 topPosition = event.pageY - chartContainerRect.top - tooltipHeight - 10;
//                             }

//                             tooltip.style("left", leftPosition + "px")
//                                 .style("top", topPosition + "px");
//                         } else {
//                             var subgroupName = d3.select(this.parentNode).datum().key;
//                             var subgroupValue = d.data[subgroupName];
//                             const tooltipContent = `Label: ${d.data.year}<br>${subgroupName}: ${subgroupValue}`;

//                             tooltip
//                                 .html(tooltipContent)
//                                 .style("opacity", 1)
//                                 .style("left", (event.offsetX + 30) + "px")
//                                 .style("top", (event.offsetY) + "px");
//                         }
//                     }
//                 })
//                 .on("mouseleave", function (event, d) {
//                     tooltip.transition().duration(100).style("opacity", 0);
//                 })

//             !resized &&
//                 g.selectAll('g')
//                     .transition()
//                     .duration(1000)

//             if (show_Line) {
//                 createLineChart(data, datakeys, props.chart_color);
//             }

//             function createLineChart(data, keys, colors) {
//                 const stack = d3.stack().keys(keys);
//                 const series = stack(mod_data);

//                 keys.forEach((key, index) => {
//                     const lineGenerator = d3.line()
//                         .x(d => x(d.data.year) + x.bandwidth() / 2)
//                         .y(d => y(d[1]))

//                     if (curved_line) {
//                         lineGenerator.curve(d3.curveCatmullRom.alpha(0.5));
//                     }
//                     g.append('path')
//                         .datum(series[index])
//                         .attr('class', `line-${key}`)
//                         .attr('d', lineGenerator)
//                         .attr('fill', 'none')
//                         .attr('stroke', 'black')
//                         .attr('stroke-width', 2.5)
//                         .attr('stroke-dasharray', '5,5');

//                     series[index].forEach((point, pointIndex) => {
//                         const xscale = x(point.data.year) + x.bandwidth() / 2;
//                         const yscale = y(point[1]);

//                         if (!show_Square) {
//                             g.append('rect')
//                                 .datum(point)
//                                 .attr('class', `node-${key}`)
//                                 .attr('x', xscale - 5)
//                                 .attr('y', yscale - 5)
//                                 .attr('width', 10)
//                                 .attr('height', 10)
//                                 .attr('fill', "pink")
//                                 .on('mouseover', function (event, d) {
//                                     const tooltipX = event.pageX + 10;
//                                     const tooltipY = event.pageY - 50;

//                                     let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
//                                     if (tooltip.empty()) {
//                                         tooltip = d3.select('body').append('div')
//                                             .attr('id', 'tooltip')
//                                             .attr('class', 'tooltip')
//                                             .style('position', 'absolute')
//                                             .style('background-color', 'white')
//                                             .style('border', '1px solid black')
//                                             .style('padding', '5px')
//                                             .style('opacity', 0)
//                                     }

//                                     tooltip.html(`Value: ${d[1] - d[0]}, Year: ${d.data.year}`)
//                                         .style("left", `${tooltipX}px`)
//                                         .style("top", `${tooltipY}px`)
//                                         .style('opacity', 0.9).raise()
//                                 })
//                                 .on('mouseout', function (event, d) {
//                                     d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip')
//                                         .style('opacity', 0);
//                                 });

//                         } else {
//                             g.append('circle')
//                                 .attr('class', `node-${key}`)
//                                 .attr('cx', xscale)
//                                 .attr('cy', yscale)
//                                 .attr('r', 4)
//                                 .attr('fill', "green")
//                                 .on('mouseover', function (event, d) {

//                                     const tooltipX = event.pageX + 10;
//                                     const tooltipY = event.pageY - 50;

//                                     let tooltip = d3.select(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
//                                     if (tooltip.empty()) {
//                                         tooltip = d3.select('body').append('div')
//                                             .attr('id', 'tooltip')
//                                             .attr('class', 'tooltip')
//                                             .style('position', 'absolute')
//                                             .style('background-color', 'white')
//                                             .style('border', '1px solid black')
//                                             .style('padding', '5px')
//                                             .style('opacity', 0);
//                                     }

//                                     tooltip.html(`Value: ${point[1]}, Year: ${point.data.year}`)
//                                         .style("left", `${tooltipX}px`)
//                                         .style("top", `${tooltipY}px`)
//                                         .style('opacity', 0.9).raise()
//                                 })
//                                 .on('mouseout', function (event, d) {
//                                     d3.select(fullScreen_enabled ? `#tooltip${i}` : '#tooltip')
//                                         .style('opacity', 0);
//                                 });
//                         }
//                     });
//                 });
//             }

//             const textGroup = g.selectAll('g')

//             const renderText = () => {
//                 textGroup.selectAll('text').remove();
//                 series.forEach((seriesData, seriesIndex) => {
//                     textGroup.selectAll(`.text-${seriesIndex}`)
//                         .data(seriesData)
//                         .enter().append('text')
//                         .attr('class', `text-${seriesIndex}`)
//                         .attr('x', d => x(d.data.year) + x.bandwidth() / 2)
//                         .attr('y', d => y(d[1]) + (y(d[0]) - y(d[1])) / 2)
//                         .text(d => d3.format(",")(d[1] - d[0]))
//                         .attr('text-anchor', 'middle')
//                         .attr('dy', '0.35em')
//                         .style('fill', text_color(datakeys[seriesIndex]))
//                         .style("display", function () {
//                             return showValues ? "block" : "none";
//                         });
//                 });
//             };

//             renderText();

//             const axisLabels = g.append('g')
//                 .attr('class', `x-axis`)
//                 .attr('transform', `translate(0, ${containerHeight - marginBottom - marginTop})`)
//                 .call(d3.axisBottom(x))
//                 .selectAll('text')
//                 .style('text-anchor', 'middle')
//                 .style('font-size', '14px')
//                 .attr('fill', 'black')
//                 .style("text-transform", "capitalize")
//                 .style("font-weight", (d, i) => i % 2 === 0 ? "bold" : "normal")
//                 .style('cursor', 'pointer')

//             let rotationAngle = 0;
//             axisLabels.each(function (data, i) {
//                 const label = this;
//                 d3.select(label).on('click', function () {
//                     const currentRotation = rotationAngle === 0 ? -45 : 0;
//                     const currentAnchor = rotationAngle === 0 ? 'end' : "middle";
//                     axisLabels.attr('transform', `rotate(${currentRotation})`)
//                         .style("text-anchor", currentAnchor)
//                     rotationAngle = currentRotation;
//                 });
//             });

//             g.append('g')
//                 .call(d3.axisLeft(y).ticks(5).tickSize(0))

//             if (showGridenabled) {
//                 svg.append("g")
//                     .selectAll("line")
//                     .attr('class', 'x-grid')
//                     .data(mod_data)
//                     .join("line")
//                     .attr("x1", (d) => x(d.year) + x.bandwidth() / 2)
//                     .attr("x2", (d) => x(d.year) + x.bandwidth() / 2)
//                     .attr("y1", 0)
//                     .attr("y2", containerHeight - marginBottom - marginTop)
//                     .attr("stroke", "lightgrey")

//                 svg.append('g')
//                     .attr('class', 'y-grid')
//                     .attr('transform', `translate(${marginLeft},${marginTop - marginBottom + 10})`)
//                     .call(d3.axisLeft(y)
//                         .tickSize(-(mod_data.length * temp_barWidth - marginLeft - marginRight))
//                         .tickFormat('')
//                         .ticks(5)
//                     )
//                     .select('.domain , line')
//                     .remove();

//                 svg.selectAll('.x-grid .tick line')
//                     .attr('class', 'x-grid-line')
//                     .attr('stroke', 'lightgrey')
//                     .attr('opacity', 0.5);

//                 svg.selectAll('.y-grid .tick line')
//                     .attr('class', 'y-grid-line')
//                     .attr('stroke', 'lightgrey')
//                     .attr('opacity', 0.5);

//             }
//             else {
//                 svg.selectAll('.grid').remove();
//             }
//             const newWidth = Math.max(temp_barWidth + margin.left + margin.right, containerWidth);
//             setChrtWidth(BarWidth !== undefined ? newWidth : containerWidth)
//             const yAxisContainer = d3.select(`#my_dataviz${i}`)
//                 .attr('class', 'y-axis')
//                 .append("div")
//                 .style("position", "absolute")
//                 .style("top", `${0}px`)
//                 .style("left", "0")
//                 .style("width", `${marginLeft}px`)
//                 .style("height", `${containerHeight - 80}px`);

//             const yAxis = yAxisContainer.append("svg")
//                 .attr("width", '100%')
//                 .attr("height", containerHeight)
//                 .append("g")
//                 .attr("transform", `translate(${marginLeft},${marginTop})`)
//                 .call(d3.axisLeft(y).ticks(fullScreen_enabled ? 20 : containerHeight / 50))
//                 .selectAll('.domain, text')
//                 .attr('stroke', fullScreen_enabled ? 'black' : 'black')
//                 .style("font-size", '10px')
//                 .call(g => g.select(".domain").remove())

//             yAxis.select(".domain")
//                 .attr("transform", `translate(${-60}, 0)`);

//             yAxis.select(".domain")
//                 .style("stroke", 'green');

//             yAxis.select(".domain")
//                 .style("stroke-width", 2);

//             yAxis.selectAll("text")
//                 .attr('class', 'yAxis-text')
//                 .attr("x", -10)
//                 .attr('fill', 'black')
//                 .attr("dx", "-3.99em")
//                 .style('font-weight', 'bold')
//                 .style("font-size", '12px');

//             yAxis.selectAll("line")
//                 .attr("transform", `translate(${-50}, 0)`)
//                 .attr('stroke', 'black')
//                 .attr("dx", "-2em");

//             var datakeys_mod

//             if (YLabel.length > 0) {
//                 datakeys_mod = YLabel.slice(1)
//             }
//             else {
//                 datakeys_mod = datakeys
//             }
//             var datakeys_mod

//             if (YLabel.length > 0) {
//                 datakeys_mod = YLabel.slice(1)
//             }
//             else {
//                 datakeys_mod = datakeys
//             }

//             // const legendContainer = d3.select(`#legend${i}`);
//             // const legendWidth = datakeys.length * 120;
//             // const legendX = (containerWidth - legendWidth) / 2;
//             // const legendY = height + margin.bottom - 40;
//             // legendContainer.selectAll('*').remove();

//             // datakeys_mod.forEach((key, i) => {
//             //     const legendItemGroup = legendContainer.append('div')
//             //         .attr('class', 'legend-item')

//             //     legendItemGroup.append('div')
//             //         .attr('class', 'legend-rect')
//             //         .style('background-color', (d) => {
//             //             var colorToUse = (chart_color?.length > 0 ? (chart_color[i] != undefined) ? chart_color[i] : chart_color[i + 1] : color(key));
//             //             return colorToUse;
//             //         })
//             //     legendItemGroup.append('text')
//             //         .attr('fill', color(key))
//             //         .text(key)
//             // });

//             const legendContainer = d3.selectAll(`#legend${i}`);
//             const legendWidth = datakeys.length * 120;
//             const legendX = (containerWidth - legendWidth) / 2;
//             const legendY = height + margin.bottom - 40;
//             legendContainer.selectAll('*').remove(); // Clear previous legend content
            
//             datakeys_mod.forEach((key, i) => {
//               const legendItemGroup = legendContainer.append('div')
//                 .attr('class', 'legend-item')
//                 .style('display', 'flex')
//                 .style('align-items', 'center')
//                 .style('margin-right', '10px'); // Add some space between legend items
            
//               legendItemGroup.append('div')
//                 .attr('class', 'legend-rect')
//                 .style('width', '20px')
//                 .style('height', '20px')
//                 .style('background-color', () => {
//                   var colorToUse = (chart_color?.length > 0 ? (chart_color[i] != undefined) ? chart_color[i] : chart_color[i + 1] : color(key));
//                   return colorToUse;
//                 })
//                 .style('margin-right', '5px'); // Space between rectangle and text
            
//               legendItemGroup.append('text')
//                 .attr('class', 'legend-text')
//                 .style('color', 'green')
//                 .text(key);
//             });
//             if (enable_table) {
//                 showTableFunction(true)
//             }
//             else {
//                 showTableFunction(false)
//             }

//             function zoomed(event) {
//                 const { transform } = event;
//                 const delta = event.sourceEvent.deltaX;
//                 const factor = 1 + delta / containerWidth;
//                 const newXDomain = x.domain().map(d => { return d });
//                 const newXScale = x.domain(newXDomain);
//                 const newBarWidth = x.bandwidth()
//                 let calc = containerWidth / 100
//                 x.range([marginLeft, mod_data.length * temp_barWidth - marginRight].map(d => event.transform.applyX(d)));
//                 svg.selectAll("path").remove();
//                 g.selectAll(".circle-node, .square-node").remove();
//                 svg.selectAll(".bar-label").remove();
//                 g.selectAll(".x-grid , node").remove();
//                 svg.selectAll(".y-grid").remove();
//                 svg.selectAll(".domain, line").remove();
//                 g.selectAll('.barValues').remove();
//                 svg.select(".x-axis").call(d3.axisBottom(newXScale));
//                 datakeys.forEach((key, index) => {
//                     d3.selectAll(`.node-${key}`).remove();
//                 })
//                 if (show_Line) {
//                     createLineChart(data, datakeys, props.chart_color);
//                 }
//                 seriesGroups.selectAll("rect")
//                     .attr("x", d => newXScale(d.data.year))
//                     .attr("width", x.bandwidth())
//                     .style('cursor', 'pointer')
//                 svg
//                     .attr("transform", `translate(${0},${margin.top})`);
//                 renderText();
//                 d3.select(`.chart-box${i}`)
//                     .attr("x", marginLeft)
//                     .attr("y", 0)
//                     .attr("width", mod_data.length * temp_barWidth - marginLeft)
//                     .attr("height", containerHeight - marginTop - marginBottom)
//                     .attr("fill", "none")
//                     .attr("stroke", "black")

//                 if (showGridenabled) {
//                     svg.append("g")
//                         .selectAll("line")
//                         .attr('class', 'x-grid')
//                         .data(mod_data)
//                         .join("line")
//                         .attr("x1", (d) => x(d.year) + x.bandwidth() / 2)
//                         .attr("x2", (d) => x(d.year) + x.bandwidth() / 2)
//                         .attr("y1", 0)
//                         .attr("y2", containerHeight - marginBottom - marginTop)
//                         .attr("stroke", "lightgrey");

//                     svg.append('g')
//                         .attr('class', 'y-grid')
//                         .attr('transform', `translate(${marginLeft},${marginTop - marginBottom + 10})`)
//                         .call(d3.axisLeft(y)
//                             .tickSize(-(mod_data.length * temp_barWidth - marginLeft - marginRight))
//                             .tickFormat('')
//                             .ticks(5)
//                         )

//                     svg.selectAll('.y-grid .tick line')
//                         .attr('class', 'y-grid-line')
//                         .attr('stroke', 'lightgrey')
//                 }

//             }

//             const handleResetButtonClick = () => {
//                 d3.selectAll(`my_dataviz${i}`).select('.node').remove()
//                 d3.selectAll('.barValues').remove();
//                 svg.selectAll(".bar-label").remove();
//                 g.selectAll(".x-grid , node").remove();
//                 svg.selectAll(".y-grid").remove();
//                 svg.selectAll("path").remove();
//                 g.selectAll(".circle-node, .square-node").remove();
//                 svg.selectAll(".circle-node, .square-node").remove();
//                 svg.selectAll(".domain, line").remove();

//                 const newXScale = x.range([marginLeft, mod_data.length * temp_barWidth - marginRight]);
//                 svg.attr("width", mod_data.length * temp_barWidth);
//                 svg.select(".x-axis").call(d3.axisBottom(newXScale));

//                 seriesGroups.selectAll("rect")
//                     .attr("x", d => newXScale(d.data.year))
//                     .attr("width", newXScale.bandwidth());
//                 g.selectAll('g')
//                     .data(series)
//                     .selectAll('text')
//                     .data(d => d)
//                     .enter().append('text')
//                     .attr('class', 'barValues')
//                     .attr('x', d => x(d.data.year) + x.bandwidth() / 2)
//                     .attr('y', d => y(d[1]) + (y(d[0]) - y(d[1])) / 2)
//                     .text(d => d3.format(",")(d[1] - d[0]))
//                     .attr('text-anchor', 'middle')
//                     .attr('dy', '0.35em')
//                     .style('fill', 'black')
//                     .style("display", function () {
//                         return showValues ? "block" : "none";
//                     });


//                 if (showGridenabled) {
//                     d3.selectAll('.x-grid').remove()
//                     g.selectAll(".x-grid").remove();
//                     svg.selectAll(".y-grid").remove();
//                     svg.selectAll(".domain, line").remove();

//                     svg.select(".x-axis").call(d3.axisBottom(newXScale));

//                     svg.append('g')
//                         .attr('class', 'y-grid')
//                         .attr('transform', `translate(${marginLeft},${marginTop - marginBottom + 10})`)
//                         .call(d3.axisLeft(y)
//                             .tickSize(-(mod_data.length * temp_barWidth - marginLeft - marginRight))
//                             .tickFormat('')
//                             .ticks(5)
//                         )
//                         .select('.domain , line')
//                         .remove()
//                     svg.selectAll('.y-grid .tick line')
//                         .attr('class', 'y-grid-line')
//                         .attr('stroke', 'lightgrey')
//                         .attr('opacity', 0.5);
//                     svg.append("g")
//                         .selectAll("line")
//                         .attr('class', 'x-grid')
//                         .data(mod_data)
//                         .join("line")
//                         .attr("x1", (d) => newXScale(d.year) + x.bandwidth() / 2)
//                         .attr("x2", (d) => newXScale(d.year) + x.bandwidth() / 2)
//                         .attr("y1", 0)
//                         .attr("y2", containerHeight - marginBottom - marginTop)
//                         .attr("stroke", "lightgrey");
//                 }
//                 if (show_Line) {
//                     datakeys.forEach((key, index) => {
//                         d3.selectAll(`.node-${key}`).remove();
//                     })
//                     createLineChart(data, datakeys, props.chart_color);
//                 }
//                 function createLineChart(data, keys, colors) {
//                     const stack = d3.stack().keys(keys);
//                     const series = stack(mod_data);
//                     keys.forEach((key, index) => {
//                         const lineGenerator = d3.line()
//                             .x(d => x(d.data.year) + x.bandwidth() / 2)
//                             .y(d => y(d[1]))
//                         if (curved_line) {
//                             lineGenerator.curve(d3.curveCatmullRom.alpha(0.5));
//                         }
//                         g.append('path')
//                             .datum(series[index])
//                             .attr('class', `line-${key}`)
//                             .attr('d', lineGenerator)
//                             .attr('fill', 'none')
//                             .attr('stroke', 'black')
//                             .attr('stroke-width', 2.5)
//                             .attr('stroke-dasharray', '5,5');
//                         series[index].forEach((point, pointIndex) => {
//                             const xscale = x(point.data.year) + x.bandwidth() / 2;
//                             const yscale = y(point[1]);
//                             if (!show_Square) {
//                                 g.append('rect')
//                                     .datum(point)
//                                     .attr('class', `node-${key}`)
//                                     .attr('x', xscale - 5)
//                                     .attr('y', yscale - 5)
//                                     .attr('width', 10)
//                                     .attr('height', 10)
//                                     .attr('fill', "pink")
//                                     .on('mouseover', function (event, d) {
//                                         const tooltipX = event.pageX + 10;
//                                         const tooltipY = event.pageY - 50;
//                                         let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
//                                         if (tooltip.empty()) {
//                                             tooltip = d3.select('body').append('div')
//                                                 .attr('id', 'tooltip')
//                                                 .attr('class', 'tooltip')
//                                                 .style('position', 'absolute')
//                                                 .style('background-color', 'white')
//                                                 .style('border', '1px solid black')
//                                                 .style('padding', '5px')
//                                                 .style('opacity', 0);
//                                         }
//                                         tooltip.html(`Value: ${d[1] - d[0]}<br>Year: ${d.data.year}`)
//                                             .style("left", `${tooltipX}px`)
//                                             .style("top", `${tooltipY}px`)
//                                             .style('opacity', 0.9);
//                                     })
//                                     .on('mouseout', function (event, d) {
//                                         let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
//                                         tooltip
//                                             .style('opacity', 0);
//                                     });
//                             } else {
//                                 g.append('circle')
//                                     .attr('class', `node-${key}`)
//                                     .attr('cx', xscale)
//                                     .attr('cy', yscale)
//                                     .attr('r', 4)
//                                     .attr('fill', "green")
//                                     .on('mouseover', function (event, d) {
//                                         const tooltipX = event.pageX + 10;
//                                         const tooltipY = event.pageY - 50;
//                                         let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
//                                         if (tooltip.empty()) {
//                                             tooltip = d3.select('body').append('div')
//                                                 .attr('id', 'tooltip')
//                                                 .attr('class', 'tooltip')
//                                                 .style('position', 'absolute')
//                                                 .style('background-color', 'white')
//                                                 .style('border', '1px solid black')
//                                                 .style('padding', '5px')
//                                                 .style('opacity', 0);
//                                         }
//                                         tooltip.html(`Value: ${point[1]}<br>Year: ${point.data.year}`)
//                                             .style("left", `${tooltipX}px`)
//                                             .style("top", `${tooltipY}px`)
//                                             .style('opacity', 0.9).raise()
//                                     })
//                                     .on('mouseout', function (event, d) {
//                                         let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
//                                         tooltip
//                                             .style('opacity', 0);
//                                     });
//                             }
//                         });
//                     });
//                 }


//             };
//             document.getElementById(`togglereset-${i}`).addEventListener('click', function (event) {
//                 handleResetButtonClick();
//             });
//         }
//     }, [containerWidth, BarWidth, containerHeight, textColorbar, showValues, mouseoverEnabled, enabledTable, mouseoverSwitchtype, chart_color, showGridenabled, temp_containerWidth, fullScreen_enabled, temp_containerHeight, sortData, showLine, YLabel, enable_table, chart_height, enabledTable, svgHeight, curved_line, show_Square, resized, SortArr, text_color_arr]);

//     const handleMenuClick = (e) => {
//         setShowOptions(!showOptions);
//     };

//     const showTableFunction = async (val) => {
//         const fieldNames = Object.keys(data[0]).filter(key => key !== "_id");
//         if (val) {
//             await tabulate(data, fieldNames)
//         }
//         else {
//             d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
//         }
//     }
//     const tabulate = async (data, columns, y_axis_name) => {
//         const header = columns;
//         var data_exist;
//         if (data !== undefined) {
//             data_exist = data;
//         } else {
//             data_exist = data;
//         }
//         var tableContainer = document.getElementById(`tableContainer${i}`);

//         if (tableContainer !== null) {
//             tableContainer.innerHTML = "";
//         }
//         var table = d3.select(`#tableContainer${i}`)
//             .attr("class", "table-responsive")
//             .append("table")
//             .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth - 12}px`);

//         var thead = table.append("thead");
//         var tbody = table.append("tbody");

//         d3.select(tableContainer)
//             .attr('class', 'table_body')
//             .style("overflow-y", "scroll")
//             .style("overflow-x", "hidden");

//         thead.append("tr")
//             .selectAll("th")
//             .data(header)
//             .enter()
//             .append("th")
//             .text(function (column) { return column; })
//             .attr("style", "text-align: center")
//             .style('color', 'black')

//         var rows = tbody.selectAll("tr")
//             .data(data_exist)
//             .enter()
//             .append("tr");

//         var cells = rows.selectAll("td")
//             .data(function (row) {
//                 return columns.map(function (column) {
//                     return { column: column, value: row[column] };
//                 });
//             })
//             .enter()
//             .append("td")
//             .attr("class", function (d) { return "cell " + d.column; })
//             .html(function (d) { return d.value; })
//             .attr("style", "text-align: center")
//             .style('color', 'black')
//         return table;
//     }
//     const handleSortIconClick = (e) => {
//         setSortShowOptions(!sortShowOptions)
//     };
//     const handleSortAscending = () => {
//         var chart_id = i;
//         dispatch(sortFunc({ data, arrValues, chart_id }));
//     }
//     const handleSortDescending = () => {
//         var chart_id = i;
//         dispatch(sortDescending({ data, arrValues, chart_id }));
//     }
//     const handleSortDefault = () => {
//         dispatch(sortInfo({ data: chart_data, chart_id: i }));
//         setSortData([...chart_data]);
//     };
//     const handleCheckboxChange = (e, value) => {
//         if (e.target.checked) {
//             setArrValues((prevData) => [
//                 ...prevData,
//                 value
//             ]);
//         } else {
//             setArrValues(arrValues.filter((val) => val !== value));
//         }
//         if (selectedValues.includes(value)) {
//             setSelectedValues(selectedValues.filter((val) => val !== value));
//         } else {
//             setSelectedValues([...selectedValues, value]);
//         }
//     };

//     return (
//         <div>
//             <div id={`tooltip${i}`} style={{ position: 'absolute', opacity: 0, background: 'lightgray', padding: '10px', borderRadius: '5px' }} ></div>
//             <div className="chart-container" >
//                 <div id={`my_dataviz${i}`} style={{ maxWidth: '100%', }} onMouseLeave={() => { setShowOptions(false); setSortShowOptions(false); }} >
//                     <svg ref={chartRef} width={(fullScreen_enabled ? (BarWidth === undefined ? temp_containerWidth : chartWidth) : chartWidth)} height={(fullScreen_enabled ? temp_containerHeight : containerHeight)}>
//                     </svg>
//                 </div>
//             </div>

//             <div id={`legend${i}`} style={{ maxWidth: containerWidth / 2, minWidth: '300px', overflowX: 'auto', position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'centre', marginLeft: (fullScreen_enabled ? temp_containerWidth : containerWidth) / 4, marginTop: '-40px', boxShadow: 'none' }}></div>

//             {showOptions && (
//                 <div
//                     className="download-options"
//                     style={{
//                         position: 'absolute',
//                         top: '46px',
//                         right: '70px',
//                         backgroundColor: '#fff',
//                         border: '1px solid #ccc',
//                         borderRadius: '4px',
//                         color: '#000080',
//                         padding: '5px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'flex-end',
//                         cursor: 'pointer',
//                         justifyContent: 'center'
//                     }}
//                     onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
//                 >
//                     <p onClick={() => handleDownloadBar('0', datakeys_name, datakeys, data)}>Download as CSV</p>
//                     <p onClick={() => imgDownloadSvg(`my_dataviz${i}`)}>Download as SVG</p>
//                     <p onClick={() => imgDownloadPng(i)} className='mt-1'>Download as PNG</p>
//                 </div>
//             )}
//             <span>
//                 <i
//                     className="bx bx-sort"
//                     style={{
//                         cursor: 'pointer',
//                         fontSize: '25px',
//                         width: '35px',
//                         position: 'absolute',
//                         top: '7px',
//                         right: '113px',
//                         zIndex: '1',
//                         color: '#6666B2',
//                         height: '50px',
//                         backgroundColor: ''
//                     }}
//                     onMouseOver={() => { handleSortIconClick(); setShowOptions(false); }} onMouseOut={() => { setSortShowOptions(false) }}
//                 ></i>
//             </span>
//             <div>
//                 <i className="bx bx-reset"
//                     style={{
//                         cursor: 'pointer',
//                         fontSize: '25px',
//                         width: '35px',
//                         position: 'absolute',
//                         top: '7px',
//                         right: '300px',
//                         zIndex: '1',
//                         color: '#6666B2',
//                     }}
//                     id={`togglereset-${i}`}
//                 ></i>
//                 <i
//                     className="bx bx-download"
//                     style={{
//                         cursor: 'pointer',
//                         fontSize: '30px',
//                         width: '40px',
//                         position: 'absolute',
//                         top: '4px',
//                         right: '70px',
//                         zIndex: '1',
//                         color: '#6666B2',
//                         height: '50px',
//                         backgroundColor: 'transparent'
//                     }}
//                     onMouseOver={() => { handleMenuClick(); setSortShowOptions(false); }}
//                     onMouseUp={() => { setShowOptions(false); }}
//                 ></i>
//             </div>
//             {sortShowOptions && (
//                 <div
//                     onMouseOver={() => setSortShowOptions(true)}
//                     className="download-options"
//                     style={{
//                         position: 'absolute',
//                         top: '45px',
//                         right: '116px',
//                         backgroundColor: '#fff',
//                         border: '1px solid #ccc',
//                         borderRadius: '4px',
//                         color: '#000080',
//                         padding: '5px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'flex-end',
//                         boxShadow: 'none',
//                         maxHeight: (`${containerHeight - 120}px`),
//                         overflow: 'auto',
//                         zIndex: '5',
//                     }}
//                 >
//                     {selectedValues.length > 0 && (
//                         <div style={{ marginBottom: '10px' }}>
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <button className='btn btn-sm btn-primary me-2' onClick={handleSortAscending}>
//                                     Ascending
//                                 </button>
//                                 <button className='btn btn-sm btn-primary me-2' onClick={handleSortDescending}>

//                                     Descending
//                                 </button>
//                                 <button className='btn btn-sm btn-primary me-2' onClick={handleSortDefault} >

//                                     Default
//                                 </button>
//                             </div>
//                         </div>
//                     )}
//                     <div>
//                         {datakeys.map((value) => (
//                             <div key={value} style={{ marginBottom: '10px', boxShadow: 'none' }}>
//                                 <label>
//                                     <input
//                                         type="checkbox"
//                                         value={value}
//                                         checked={selectedValues.includes(value)}
//                                         onChange={(e) => handleCheckboxChange(e, value)}
//                                     />
//                                     {value.toUpperCase()}
//                                 </label>
//                             </div>
//                         ))}
//                     </div>

//                 </div>
//             )}
//             {isLoading &&
//                 <div className="loader-overlay">
//                     <div className="loader"></div>
//                 </div>}
//             {enabledTable ? (
//                 <>
//                     <div style={{

//                         bottom: 0,
//                         left: 0,
//                         backgroundColor: '#fff',
//                         height: (fullScreen_enabled ? '240px' : '200px')
//                     }} id={`tableContainer${i}`}>
//                     </div>
//                 </>
//             ) : null}
//         </div>
//     );
// };

// export default StackChart;
