import React, { Component } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';

import {
    Container, Row, Col, Button, Alert, Input, Card, CardBody,
    Modal, ModalHeader, ModalBody,
    Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
} from "reactstrap"

import ReactDrawer from 'react-drawer';
import { DatePicker, Drawer, Badge } from 'antd';
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
// import TableContainer from "../../../common/TableContainer";
import TableContainer from "./ReportComponents/TableContainer";


// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import CardTitle from "reactstrap/lib/CardTitle";

import EditEP from "./Components/EditEP_PBD"
import AddEndpointNode from "./Components/NewEndPoint"
import UpdtAuditInfo from "./Components/UpdatePdbAuditInfo"

import SortableTree, {
    addNodeUnderParent,
    removeNodeAtPath,
    toggleExpandedForAll,
    getTreeFromFlatData,
    getNodeAtPath,
    changeNodeAtPath,
    getFlatDataFromTree,
    walk,
    map, find
  } from 'react-sortable-tree/dist/index.cjs.js';

import CheckboxTree, {
    getNode, flattenNodes, deserializeLists, serializeList
  } from 'react-checkbox-tree';
  import './CSS/checkboxtree.css';
  import { MdCheckBox, MdCheckBoxOutlineBlank, MdChevronRight, MdKeyboardArrowDown, MdAddBox, MdIndeterminateCheckBox, MdLocationCity, MdStore, MdFolder, MdCheckCircle, MdDescription } from "react-icons/md";
  



const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from "../../../helpers/urlSocket";
var moment = require('moment')

const icons = {
    check: <MdCheckBox className="rct-icon rct-icon-check text-success" />,
    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
    halfCheck: (
      <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
    ),
    expandClose: (
      <MdChevronRight className="" />
    ),
    expandOpen: (
      <MdKeyboardArrowDown className="" />
    ),
    expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
    collapseAll: (
      <MdIndeterminateCheckBox className="" />
    ),
    parentClose: <MdLocationCity className="" />,
    parentOpen: <MdLocationCity className="" />,
    leaf: <MdStore className="" />
  }

export default class HLevelPublishedReport extends Component {


    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight,
            position: 'right',
            open: false,
            locationData: [],
            dataloaded: false,
            expanded: [],
        }
        this.onDrawerClose = this.onDrawerClose.bind(this);
    }

    async componentDidMount() {
        await this.getSessionData()
    }

    async getSessionData() {

        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var publishedAuditData = JSON.parse(sessionStorage.getItem("publishedAuditData"));
       // convertedFlatData = this.convertFlatDataToTreeData(publishedAuditData.endpointData)
        this.setState({
            configData: data.config_data,
            userData: data.user_data,
            publishedAuditData,
            locationData: publishedAuditData.ep_structure,
            db_info : data.db_info
        },()=>{
        this.getAuditInfo()

        })

    }

    getAuditInfo() {
        this.setState({ dataloaded: false })
        try {

            urlSocket.post("webpbdadtdta/getpublishedauditdata", {
                auditInfo: this.state.publishedAuditData,
                userInfo: this.state.userData,
                configData: this.state.configData,
                encrypted_db_url : this.state.db_info.encrypted_db_url
            })
                .then(response => {
                    console.log(response,'response')
                    if (response.data.response_code === 500) {

                        var in_progress_count = _.filter(response.data.data, (e) => {
                            return e.status === "1";
                          });
                
                          var not_started_count = _.filter(response.data.data, (e) => {
                            return e.status === "0";
                          });
                          var completed_count = _.filter(response.data.data, (e) => {
                            return e.status === "2";
                          });
                          var submitted_count = _.filter(response.data.data, (e) => {
                            return e.status === "3";
                          });
                          var reviewed_count = _.filter(response.data.data, (e) => {
                            return e.status === "7";
                          });
                


                        this.setState({
                            endpoints: response.data.data,
                            total_audit: response.data.data.length,
                            in_progress_count: in_progress_count.length,
                            not_started_count: not_started_count.length,
                            completed_count: completed_count.length,
                            submitted_count: submitted_count.length,
                            reviewed_count: reviewed_count.length
                        },
                            function () {
                                console.log(this.state.reviewed_count,this.state.submitted_count,this.state.in_progress_count,this.state.not_started_count,'not_started_count',this.state.completed_count)
                                this.filterStatus('All')
                            })
                    }
                })
                .catch((error)=>{
                    console.log(error,'error')
                })

        } catch (error) {
            console.log(error,'error')
        }

    }

    closeDrawer() {
        this.setState({ open: false, enableEdit: false });
    }
    onDrawerClose() {
        this.setState({ open: false, enableEdit: false });
        this.getAuditInfo()
    }

    filterStatus = (data) => {
        console.log(data,'data')
        var filteredData;
        if (data == "Not started") {
            filteredData = _.filter(this.state.endpoints, { "status": "0" })
        }
        else
            if (data == "In progress") {
                filteredData = _.filter(this.state.endpoints, { "status": "1" })
            }
            else
                if (data == "Completed") {
                    filteredData = _.filter(this.state.endpoints, { "status": "2" })
                }
                else
                    if (data == "Submitted") {
                        filteredData = _.filter(this.state.endpoints, { "status": "3" })
                    }
                    else 
                    if (data == "Reviewed") {
                        filteredData = _.filter(this.state.endpoints, { "status": "7" })
                      }
                    else
                        if (data == "All") {
                            filteredData = this.state.endpoints
                        }
        this.setState({
            statusBasedFilteredData: filteredData,
            dupFilteredData: filteredData,
            dataloaded: true
        })
    }

    activeInactive = (item) => {
        if (item.active === "0") {
            // item["active"] = "1"
            this.setState({
                confirm_alert: true,
                epData: item,
                confirmButtonText: "Yes, make it active!",
                dynamic_title: "Active",
                dynamic_description: "This end point is activated",
                active_status:"1"

            })
        }
        else {
            // item["active"] = "0"
            this.setState({
                confirm_alert: true,
                epData: item,
                confirmButtonText: "Yes, make it inactive!",
                dynamic_title: "Inactive",
                dynamic_description: "This end point is inactivated",
                active_status:"0"
            })
        }

    }

    updateEndpoint() {
        var epData ={...this.state.epData}
        epData["active"]=this.state.active_status
        try {

            urlSocket.post("webpbdadtdta/ainendpoints", {
                encrypted_db_url:this.state.db_info.encrypted_db_url,
                userInfo: this.state.userData,
                endpointInfo: epData,
                auditInfo: this.state.publishedAuditData
            })
                .then(response => {
                    if (response.data.response_code === 500) {
                        this.getAuditInfo()
                        //this.props.onClose()
                    }
                })

        } catch (error) {
            console.log("catch error", error)
        }
    }

    showEndpointReport(data) {
        if (data.status === "0") {
            this.setState({
                showWarning: true,
                submitMessage: "Audit is not started, you cannot view report"
            })
        }
        else {
            sessionStorage.removeItem("endpointInfo");
            sessionStorage.setItem("endpointInfo", JSON.stringify(data));
            this.props.history.push("/chkpntrprt");
        }

    }
    handleEndpointExpanded = (expanded) => {
        this.setState((prevState) => ({
            expanded,
            publishedAuditData: {
                ...prevState.publishedAuditData,
                ep_structure: prevState.locationData,
                ep_expanded: expanded
            }
        }), () => {
            // Call any necessary functions or perform other actions here
            // after the state has been updated.
            // this.updateCheckedepStructure()
        });
    }

    // handleEndpointExpanded = expanded => {
    //     this.setState({ expanded }, function () {
    //       this.state.publishedAuditData.ep_structure = this.state.locationData
    //       this.state.publishedAuditData.ep_expanded = expanded
    //      // this.updateCheckedepStructure()
    //     })
    //   }

    updateEPData = (updatedData) => {
        this.setState({ epdata: updatedData });
      }
         

    getNodeEndpoints(data) {
        console.log(data, 'data')
        //_.map([data], item => ({value: item.value, children: item.children}));
        if (this.state.publishedAuditData.method_selected === "1") {

            var getNodeKey = ({ treeIndex }) => treeIndex
            var flatData = getFlatDataFromTree(
                {
                    treeData: [data],
                    getNodeKey,
                    ignoreCollapsed: false,
                })

            var explicitData = _.filter(flatData, item => {
                return item
            })
            console.log(_.map(explicitData, "node"), 'explicitData', this.state.publishedAuditData)
            //return explicitData
            var result = _.filter(_.map(explicitData, "node"), { ep_level_value: this.state.publishedAuditData.eplevel_value })
            console.log(result, 'result')
            var epids = _.map(result, "node_id")
            var fillteredData = _.filter(this.state.endpoints, item => {
                if (epids.indexOf(String(item.ref_id)) !== -1) {
                    return item
                }

            })
            this.setState({
                statusBasedFilteredData: fillteredData
            })
        }
        else {
            console.log(this.state.publishedAuditData, 'method 2')
            var filtered_info =  _.filter(this.state.dupFilteredData,{name : data.label})
            console.log(filtered_info,'filtered_info')
            this.setState({
                statusBasedFilteredData: filtered_info
            })
        }

    }

    render() {
        if (this.state.dataloaded) {
            const { locationData } = this.state
            // const { SearchBar } = Search;
            // const { ExportCSVButton } = CSVExport;
            // const { users } = this.state

            const { isEdit } = this.state
            console.log(this.state.statusBasedFilteredData,'this.state.statusBasedFilteredData')
            const options = {
                // pageStartIndex: 0,

                sizePerPage: 10,
                totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
                custom: true,
            };
            console.log( this.state.publishedAuditData,' this.state.publishedAuditData',this.state.statusBasedFilteredData)
            var columns
            if(this.state.publishedAuditData.enable_review === true){
            columns = [
                {
                    accessor: 'name',
                    Header: 'Name',
                    filterable: true,
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={item.active == "1" ? "font-size-14 text-dark fw-bold" : "font-size-14 text-black-50"} style={{ marginBottom: 5 }}>
                                        <i
                                            className={
                                                item.status === '0'
                                                    ? "mdi mdi-circle text-secondary font-size-10" :
                                                    item.status === '1' ?
                                                        "mdi mdi-circle text-warning font-size-10" :
                                                        item.status === '2' ?
                                                            "mdi mdi-circle text-success font-size-10" :
                                                            item.status === '3' ?
                                                                "mdi mdi-circle text-info font-size-10" : "mdi mdi-circle text-primary font-size-10"
                                            }
                                        />{" "} {item.name}
                                    </div>
                                    <div>
                                        <span className="font-size-10">Audit started on - {item.audit_started_on === null ? "-- / -- / --" : moment(item.audit_started_on).format("DD/MM/YYY")}</span> <br />
                                        <span className="font-size-10">Audit submitted on - {item.audit_submitted_on === null ? "-- / -- / --" : moment(item.audit_submitted_on).format("DD/MM/YYY")}</span> <br />
                                        <span className="font-size-10">Audit reviewed on - {item.audit_reviewed_on === null ? "-- / -- / --" : moment(item.audit_reviewed_on).format("DD/MM/YYY")}</span> <br />
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'code',
                    Header: 'Code',
                    filterable: true,
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"} style={{ marginBottom: 5 }}>
                                        {item.code}
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'audit_pdb_total_cp',
                    Header: 'Check points',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <span className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"}>{item.audit_pdb_total_cp}</span>
                            </>
                        )
                    }
                },
                {
                    accessor: 'audit_pbd_ends_on',
                    Header: 'Ends on',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <span className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"}>{moment(item.audit_pbd_ends_on).format("DD/MM/YYYY")}</span>
                            </>
                        )
                    }
                },
                {
                    accessor: 'audit_pbd_users',
                    Header: 'Audit assigned to',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original

                        var getUser = _.find(item.audit_pbd_users, { audit_type: "1" })
                        return (
                            <>
                                <div>
                                    <span className={item.active == "1" ? null : "font-size-14 text-black-50"}>{getUser.name} ({getUser.user_code})</span><br /><span className={item.active == "1" ? "font-size-10 text-primary" : "font-size-10 text-black-50"}>{getUser.designation}</span>
                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'audit_pbd_users_',
                    Header: 'Review assigned to',
                    hidden: this.state.publishedAuditData.enable_review === false ? false : true,
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        console.log(item,'item')
                        var getUser = _.find(item.audit_pbd_users, { audit_type: "2" })
                        console.log(getUser,'getUser')
                        return (
                            <>
                                <div>
                                    <span className={item.active == "1" ? null : "font-size-14 text-black-50"}>{getUser.name} ({getUser.user_code})</span><br /><span className={item.active == "1" ? "font-size-10 text-primary" : "font-size-10 text-black-50"}>{getUser.designation}</span>
                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'status',
                    Header: 'Status',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <span className={item.status === "0" ? "badge badge-soft-secondary font-size-11 m-1" :
                                    item.status === "1" ? "badge badge-soft-warning font-size-11 m-1" : item.status === "2" ? "badge badge-soft-success font-size-11 m-1" : item.status === "3" ? "badge badge-soft-info font-size-11 m-1" : "badge badge-soft-primary font-size-11 m-1"}
                                >
                                    {item.status === "0" ? "Not started" : item.status === "1" ? "In progress" : item.status === "2" ? "Completed" : item.status === "3" ? "Submitted" : "Reviewed"}
                                </span>
                            </>
                        )
                    }
                },
                {
                    accessor: "menu",
                    // isDummyField: true,
                    // editable: false,
                    Header: "Action",
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return(
                        <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                            <div className="d-flex gap-1">
                                {
                                    item.active === "1" && item.status !== "3" && item.status !== "4" ?
                                        <Link className="btn btn-primary btn-sm" to="#" onClick={() => { this.setState({ open: true, enableEdit: true, epData: item }) }}>Edit</Link>
                                        : null
                                }
                                <Link className={item.active == "1" ? "btn btn-primary btn-sm" : "btn btn-secondary btn-sm"} to="#" onClick={() => { this.showEndpointReport(item) }}>Report</Link>
                                {item.status !== "3" && item.status !== "4" ? <Link className={item.active == "1" ? "btn btn-danger btn-sm" : "btn btn-info btn-sm"} to="#" onClick={() => { this.activeInactive(item) }}>{item.active === "0" ? "Active" : "Make Inactive"}</Link> : null}
                            </div>
                        </div>
                        )
                    },
                },

            ];
        }
        else{
            columns = [
                {
                    accessor: 'name',
                    Header: 'Name',
                    filterable: true,
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={item.active == "1" ? "font-size-14 text-dark fw-bold" : "font-size-14 text-black-50"} style={{ marginBottom: 5 }}>
                                        <i
                                            className={
                                                item.status === '0'
                                                    ? "mdi mdi-circle text-secondary font-size-10" :
                                                    item.status === '1' ?
                                                        "mdi mdi-circle text-warning font-size-10" :
                                                        item.status === '2' ?
                                                            "mdi mdi-circle text-success font-size-10" :
                                                            item.status === '3' ?
                                                                "mdi mdi-circle text-info font-size-10" : "mdi mdi-circle text-primary font-size-10"
                                            }
                                        />{" "} {item.name}
                                    </div>
                                    <div>
                                        <span className="font-size-10">Audit started on - {item.audit_started_on === null ? "-- / -- / --" : moment(item.audit_started_on).format("DD/MM/YYY")}</span> <br />
                                        <span className="font-size-10">Audit submitted on - {item.audit_submitted_on === null ? "-- / -- / --" : moment(item.audit_submitted_on).format("DD/MM/YYY")}</span> <br />
                                        <span className="font-size-10">Audit reviewed on - {item.audit_reviewed_on === null ? "-- / -- / --" : moment(item.audit_reviewed_on).format("DD/MM/YYY")}</span> <br />
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'code',
                    Header: 'Code',
                    filterable: true,
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"} style={{ marginBottom: 5 }}>
                                        {item.code}
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'audit_pdb_total_cp',
                    Header: 'Check points',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <span className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"}>{item.audit_pdb_total_cp}</span>
                            </>
                        )
                    }
                },
                {
                    accessor: 'audit_pbd_ends_on',
                    Header: 'Ends on',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <span className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"}>{moment(item.audit_pbd_ends_on).format("DD/MM/YYYY")}</span>
                            </>
                        )
                    }
                },
                {
                    accessor: 'audit_pbd_users',
                    Header: 'Audit assigned to',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original

                        var getUser = _.find(item.audit_pbd_users, { audit_type: "1" })
                        return (
                            <>
                                <div>
                                    <span className={item.active == "1" ? null : "font-size-14 text-black-50"}>{getUser.name} ({getUser.user_code})</span><br /><span className={item.active == "1" ? "font-size-10 text-primary" : "font-size-10 text-black-50"}>{getUser.designation}</span>
                                </div>
                            </>
                        )
                    }
                },
                // {
                //     accessor: 'audit_pbd_users_',
                //     Header: 'Review assigned to',
                //     hidden: this.state.publishedAuditData.enable_review === false ? false : true,
                //     Cell: (cellProps) => {
                //         var item = cellProps.row.original
                //         console.log(item,'item')
                //         var getUser = _.find(item.audit_pbd_users, { audit_type: "2" })
                //         console.log(getUser,'getUser')
                //         return (
                //             <>
                //                 <div>
                //                     <span className={item.active == "1" ? null : "font-size-14 text-black-50"}>{getUser.name} ({getUser.user_code})</span><br /><span className={item.active == "1" ? "font-size-10 text-primary" : "font-size-10 text-black-50"}>{getUser.designation}</span>
                //                 </div>
                //             </>
                //         )
                //     }
                // },
                {
                    accessor: 'status',
                    Header: 'Status',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <span className={item.status === "0" ? "badge badge-soft-secondary font-size-11 m-1" :
                                    item.status === "1" ? "badge badge-soft-warning font-size-11 m-1" : item.status === "2" ? "badge badge-soft-success font-size-11 m-1" : item.status === "3" ? "badge badge-soft-info font-size-11 m-1" : "badge badge-soft-primary font-size-11 m-1"}
                                >
                                    {item.status === "0" ? "Not started" : item.status === "1" ? "In progress" : item.status === "2" ? "Completed" : item.status === "3" ? "Submitted" : "Reviewed"}
                                </span>
                            </>
                        )
                    }
                },
                {
                    accessor: "menu",
                    // isDummyField: true,
                    // editable: false,
                    Header: "Action",
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return(
                        <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                            <div className="d-flex gap-1">
                                {
                                    item.active === "1" && item.status !== "3" && item.status !== "4" ?
                                        <Link className="btn btn-primary btn-sm" to="#" onClick={() => { this.setState({ open: true, enableEdit: true, epData: item }) }}>Edit</Link>
                                        : null
                                }
                                <Link className={item.active == "1" ? "btn btn-primary btn-sm" : "btn btn-secondary btn-sm"} to="#" onClick={() => { this.showEndpointReport(item) }}>Report</Link>
                                {item.status !== "3" && item.status !== "4" ? <Link className={item.active == "1" ? "btn btn-danger btn-sm" : "btn btn-info btn-sm"} to="#" onClick={() => { this.activeInactive(item) }}>{item.active === "0" ? "Active" : "Make Inactive"}</Link> : null}
                            </div>
                        </div>
                        )
                    },
                },

            ];
        }
            const defaultSorted = [{
                dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
                order: 'asc' // desc or asc
            }];

            return (
                <React.Fragment>
                    <div className="page-content">
                        <MetaTags>
                            <title>AuditVista | Published Audit</title>
                        </MetaTags>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 20, alignItems: 'center' }}>
                            <div style={{ width: '80%', padding: '0 20px' }}><h4>Published Audit</h4></div>
                            <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
                            </div>
                        </div>
                        <Container fluid>

                            <div className="d-xl-flex">
                                <div className="w-100">
                                    <div className="d-md-flex">


                                        <Card className="w-25 me-md-1  pb-3 ">
                                            <CardBody style={{ maxHeight: this.state.height - 150, overflow: 'auto' }} className="overflow-auto">

                                                <CheckboxTree
                                                    nodes={locationData}
                                                    checked={this.state.checked}
                                                    expanded={(this.state.expanded)}
                                                   // onCheck={(checked, event) => { this.handleEndpointSelection(checked, event) }}
                                                    onClick = {(nodes) => {this.getNodeEndpoints(nodes)}}
                                                    onExpand={expanded => this.handleEndpointExpanded(expanded)}
                                                    icons={icons}
                                                    showNodeIcon={false}
                                                    showExpandAll
                                                // showExpandAll={true}
                                                >
                                                    test

                                                </CheckboxTree>
                                            </CardBody>

                                        </Card>

                                        <div className="w-100">
                                            <Row >
                                                <Col lg="12">
                                                    <Card >
                                                        <CardBody>
                                                            <CardTitle className="text-primary">{this.state.publishedAuditData.template_name}</CardTitle>
                                                            <TableContainer
                                                             columns={columns}
                                                             data={this.state.statusBasedFilteredData}
                                                             isGlobalFilter={true}
                                                             isAddOptions={false}
                                                             isJobListGlobalFilter={false}
                                                             customPageSize={10}
                                                             isPagination={true}
                                                             tableClass="align-middle table-nowrap table-check"
                                                             theadClass="table-light"
                                                             pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                                             total_audit={this.state.total_audit}
                                                             not_started_count={this.state.not_started_count}
                                                             in_progress_count={this.state.in_progress_count}
                                                             completed_count={this.state.completed_count}
                                                             submitted_count={this.state.submitted_count}
                                                             reviewed_count={this.state.reviewed_count}
                                                             filterStatus={(data) => this.filterStatus(data)}
                                                            
                                                             //  style={{ width: '100%' }}
                                                            //  dynamicBtn={true}
                                                            //  btnClick={(param1, param2) => this.navigateTo("",1)}
                                                            //  // dynamicParams={params1,params2}
                                                            //  btnName ={"Add User"}
                                                            
                                                            />
                                                         
                                                         
                                                            {/* <PaginationProvider
                                                                keyField="id"
                                                                data={this.state.statusBasedFilteredData}
                                                                columns={columns}
                                                                pagination={paginationFactory(options)}
                                                            >
                                                                {
                                                                    ({
                                                                        paginationProps,
                                                                        paginationTableProps
                                                                    }) => (
                                                                        <ToolkitProvider
                                                                            keyField="id"
                                                                            data={this.state.statusBasedFilteredData}
                                                                            columns={columns}
                                                                            search
                                                                            exportCSV
                                                                        >
                                                                            {
                                                                                toolkitprops => (
                                                                                    <React.Fragment>
                                                                                        <Row className="" style={{ paddingTop: 10 }}>
                                                                                            <Col  >
                                                                                                <div className="search-box ms-2 mb-1 d-inline-block">
                                                                                                    <div className="position-relative">
                                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="button-items mb-1 ms-2 ">
                                                                                                    <div className="btn-group mt-2 mt-xl-0" role="group" aria-label="Basic radio toggle button group">
                                                                                                        <input type="radio" className="btn-check" name="btnradio1" id="btnradio0" autoComplete="off" defaultChecked onClick={() => { this.filterStatus('All') }} />
                                                                                                        <label className="btn btn-outline-dark btn-sm" htmlFor="btnradio0">All</label>

                                                                                                        <input type="radio" className="btn-check" name="btnradio1" id="btnradio1" autoComplete="off" onClick={() => { this.filterStatus('Not started') }} />
                                                                                                        <label className="btn btn-outline-secondary btn-sm" htmlFor="btnradio1">Not started</label>

                                                                                                        <input type="radio" className="btn-check" name="btnradio1" id="btnradio2" autoComplete="off" onClick={() => this.filterStatus('In progress')} />
                                                                                                        <label className="btn btn-outline-warning btn-sm" htmlFor="btnradio2">In progress</label>

                                                                                                        <input type="radio" className="btn-check" name="btnradio1" id="btnradio3" autoComplete="off" onClick={() => this.filterStatus('Completed')} />
                                                                                                        <label className="btn btn-outline-success btn-sm" htmlFor="btnradio3">Completed</label>

                                                                                                        <input type="radio" className="btn-check" name="btnradio1" id="btnradio4" autoComplete="off" onClick={() => this.filterStatus('Submitted')} />
                                                                                                        <label className="btn btn-outline-info btn-sm" htmlFor="btnradio4">Submitted</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col >
                                                                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
                                                                                                    <Button className="btn-sm me-2 float-right" color={"primary"} outline onClick={() => { this.setState({ enabbleAddEndPoint: true, open: true }) }}>Add Location</Button>
                                                                                                    <Button className="btn-sm me-2 float-right" color={"primary"} outline onClick={() => { this.setState({ updatePbdAudit: true, open: true }) }}>Change Audit End date</Button>
                                                                                                    <ExportCSVButton className=" btn-sm  btn-outline-primary float-right" {...toolkitprops.csvProps}>Export as CSV</ExportCSVButton>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col xl="12">
                                                                                                <div className="table-responsive">
                                                                                                    <BootstrapTable
                                                                                                        {...toolkitprops.baseProps}
                                                                                                        {...paginationTableProps}
                                                                                                        defaultSorted={defaultSorted}
                                                                                                        classes={
                                                                                                            "table align-middle table-nowrap table-hover"
                                                                                                        }
                                                                                                        bordered={false}
                                                                                                        striped={false}
                                                                                                        responsive
                                                                                                    />


                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="align-items-md-center mt-30">
                                                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                                                <PaginationListStandalone
                                                                                                    {...paginationProps}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </React.Fragment>
                                                                                )}
                                                                        </ToolkitProvider>
                                                                    )}
                                                            </PaginationProvider> */}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div>
                                </div>
                            </div>



                        </Container>
                    </div>
                    <ReactDrawer
                        open={this.state.open}
                        className="drawer-open"
                        position={this.state.position}
                        onClose={this.onDrawerClose}
                        style={{ maxHeight: 1000 }}
                        noOverlay={false}
                    >

                        <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>
                            {
                                console.log(this.state.enableEdit,'this.state.enableEdit')
                            }
                            {this.state.enableEdit ? <EditEP
                                epdata={this.state.epData}
                                user_data={this.state.userData}
                                audit_data={this.state.publishedAuditData}
                                onClose={this.onDrawerClose}
                                updateEPData={this.updateEPData}
                            /> :
                                this.state.enabbleAddEndPoint ?
                                    <AddEndpointNode
                                        user_data={this.state.userData}
                                        endpoints={this.state.publishedAuditData.endpoints}
                                        publishtemplateInfo={this.state.publishedAuditData}
                                        onClose={this.onDrawerClose}
                                        editItem={null}
                                        onCancel={this.onDrawerClose}
                                    />
                                    : this.state.updatePbdAudit ?
                                        <UpdtAuditInfo
                                            epdata={this.state.epData}
                                            user_data={this.state.userData}
                                            audit_data={this.state.publishedAuditData}
                                            onClose={this.onDrawerClose}
                                        /> : null
                            }
                        </div>
                    </ReactDrawer>
                    {this.state.confirm_alert ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmButtonText={this.state.confirmButtonText}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                this.setState({
                                    confirm_alert: false,
                                    success_dlg: true,
                                    dynamic_title: this.state.dynamic_title,
                                    dynamic_description: this.state.dynamic_description,
                                },()=>{
                                    this.updateEndpoint()
                                })
                                    ; 
                            }
                            }
                            onCancel={() =>
                                this.setState({
                                    confirm_alert: false,
                                })
                            }
                        >
                            You want to do this!
                        </SweetAlert>
                    ) : null}
                    {this.state.success_dlg ? (
                        <SweetAlert
                            success
                            title={this.state.dynamic_title}
                            onConfirm={() => { this.setState({ success_dlg: false }) }}
                        >
                            {this.state.dynamic_description}
                        </SweetAlert>
                    ) : null}
                    {this.state.showWarning ? (
                        <SweetAlert
                            title="Message"
                            warning
                            onConfirm={() => this.setState({ showWarning: false })}
                        >
                            {this.state.submitMessage}
                        </SweetAlert>
                    ) : null}
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="page-content">
                        <MetaTags>
                            <title>AuditVista | Published Audit</title>
                        </MetaTags>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 20, alignItems: 'center' }}>
                            <div style={{ width: '80%', padding: '0 20px' }}><h4>Published Audit</h4></div>
                            <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
                            </div>
                        </div>
                        <Container >
                        </Container>
                    </div>
                </React.Fragment>
            )
        }
    }

}