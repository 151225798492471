import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    Media,
    Row,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    CardImg,
} from "reactstrap"

import Lightbox from "react-image-lightbox";
const _ = require('lodash')

export default class PreviewVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            dataloaded: false,
            photoIndex: 0,
        };
    }


    render() {

        var images = []
        var imageInfo = []

        _.each(this.props.images, data => {
            if (data.preview != undefined) {
                images.push(this.props.imagePreviewUrl + data.originalName)
                imageInfo.push(data)
                // imageInfo.push()
            }

        })

        const { photoIndex } = this.state;
        return (
            <Row >
                {/* {this.state.isGallery ? (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={
                            images[(photoIndex + images.length - 1) % images.length]
                        }
                        enableZoom={false}
                        onCloseRequest={() => this.setState({ isGallery: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    // imageCaption={"Location - "+ String(imageInfo[photoIndex].address) + " | Captured on - " + this.dateConvertion(imageInfo[photoIndex].date)}
                    />
                ) : null} */}
                {
                    console.log(this.props.image,'this.props.image')
                }
                {
                    this.props.videos.map((item, idx) => {
                       
                        return (
                            <Col mg={6} xl={3} className="p-2" key={idx} >
                             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.setState({ isGallery: true, photoIndex: idx }) }>
                                <video
                                style={{ width: "100%" , height:"100%" }}
                                 src={this.props.imagePreviewUrl+  item.originalname}
                               
                                // src={"https://d3pnv0bkd16srd.cloudfront.net/eaudit-file/" + item.originalname}
                                controls
                              />
                                  </div>
                                {/* <div >
                                <Link to="#" onClick={() =>
                                        this.setState({ isGallery: true, photoIndex: idx })
                                    }>
                                        <CardImg  style={{width: "100%",height: "10vw",objectFit: "cover"}}  src={this.props.imagePreviewUrl + item.originalName} alt="Skote" />
                                </Link>
                                </div> */}
                            </Col>

                        )
                    })


                }
            </Row>

        )
    }


}
