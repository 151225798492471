



import React, { Component } from 'react'
import { Tabs } from 'antd';
import { Row, Col, Button, TabContent, TabPane, NavLink, NavItem, Nav, Form, FormGroup } from "reactstrap";
import classnames from "classnames";
import Guideline from './Guideline'
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { Popconfirm } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';

import '../manageAudit.css'

export default class OptionsType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: "1",

        }
    }

    componentDidMount() {


    }
    onChangeTab = (tab) => {
        if (this.state.currentTab !== tab) {
            this.setState({ currentTab: tab })
        }
    }

    onChangeimgReq = (e) => {
        let value = e.target.checked ? true : false
        this.props.parameterData.img_availability = value
        this.setState({ refresh: true })
    }

    onChangeOptionimgReq = (e, data) => {
        let value = e.target.checked ? true : false
        data.img_availability = value
        this.setState({ refresh: true })
    }

    isManditoryChange = (e, data) => {
        let value = e.target.checked ? true : false
        data.is_manditory = value
        this.setState({ refresh: true })
    }

    onChangeAplnReq = (e) => {
        let value = e.target.checked ? true : false
        this.props.parameterData.apln_available = value
        this.setState({ refresh: true })
    }



    ValidateAndSend = () => {
        this.setState({ currentTab: "1" })
        let data = this.props.parameterData
        data.min_score=this.get_minScore(this.props.parameter_options)
        data.max_score=this.get_maxScore(this.props.parameter_options)
        data.parameter_options = this.props.parameter_options
        this.props.onSave(data)

    }
    get_minScore = (data) => {
        try {
            return isNaN(Math.min.apply(Math, data.map((o) => { return o.score }))) ? 0 : Math.min.apply(Math, data.map((o) => { return o.score }))
        } catch (error) {
            return 0
        }
    }

    get_maxScore = (data) => {
        try {
            return isNaN(Math.max.apply(Math, data.map((o) => { return o.score }))) ? 0 : Math.max.apply(Math, data.map((o) => { return o.score }))
        } catch (error) {
            return 0
        }
    }
    addRow = (data) => {
        let row = { img_availability: null, no_of_img: null, option_selected: false, option_text: "", score: 0 }
        data.push(row)
        this.setState({ refresh: true })
    }
    deleteParameter = (data, i) => {

        data.splice(i, 1)
        this.setState({ visible: false })
    }

 

    render() {
        return (
            this.props.editable?
            <div >
                <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: this.state.currentTab === "1" })} onClick={() => { this.onChangeTab("1") }} >
                            <span className="d-none d-sm-block">Parameter</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: this.state.currentTab === "2" })} onClick={() => { this.onChangeTab("2"); }} >
                            <span className="d-none d-sm-block">Guideline</span>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.currentTab}>
                    <TabPane tabId="1" >
                        <div className="blueDiv">
                            <AvForm onValidSubmit={this.ValidateAndSend}>
                                <Row>
                                    <Col md={12}>
                                        <AvField
                                            name="Parameter"
                                            label="Enter Audit Parameter"
                                            type="textarea"
                                            errorMessage="Please enter Parameter"
                                            value={this.props.parameterData.parameter}
                                            onChange={(e) => this.props.parameterData.parameter = e.target.value}
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {
                                            this.props.parameter_options.map((data, i) =>
                                                <Row key={i}>
                                                    <Col md={12}>
                                                        <div className="flexsb">
                                                            <div>
                                                                <AvField
                                                                    name={"option " + i + 1}
                                                                    type="text"
                                                                    label={"Option " + (i + 1)}
                                                                    errorMessage={"option"}
                                                                    placeholder={"option"}
                                                                    style={{ width: 400 }}
                                                                    value={data.option_text || ''}
                                                                    onChange={(e) => data.option_text = e.target.value}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <AvField
                                                                    name={"score" + i}
                                                                    type="number"
                                                                    label={"Score"}
                                                                    errorMessage="Score"
                                                                    value={String(data.score)}
                                                                    style={{ width: "80px", }}
                                                                    onChange={(e) => { data.score = parseFloat(e.target.value); this.setState({ refresh: true }) }}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <label >Img. Required</label>
                                                                </div>
                                                                <div style={{ padding: '8px 0px' }}>
                                                                    <div className="square-switch">
                                                                        <input type="checkbox" id={this.props.parameterData._id + i + "option"} data-switch="info" checked={data.img_availability == "1" ? true : false} onChange={(e) => { this.onChangeOptionimgReq(e, data) }} /><label htmlFor={this.props.parameterData._id + i + "option"} data-on-label="Yes" data-off-label="No"  ></label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div style={{ width: "110px" }}>
                                                                {
                                                                    data.img_availability == "1" &&
                                                                    <>
                                                                        <div>
                                                                            <label >Manditory</label>
                                                                        </div>
                                                                        <div style={{ padding: '8px 0px' }}>
                                                                            <div className="square-switch">
                                                                                <input type="checkbox" id={this.props.parameterData._id + i + "manditory"} data-switch="info" checked={data.is_manditory == "1" ? true : false} onChange={(e) => { this.isManditoryChange(e, data) }} /><label htmlFor={this.props.parameterData._id + i + "manditory"} data-on-label="Yes" data-off-label="No"  ></label>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }


                                                            </div>
                                                            <div style={{ width: "110px" }}>
                                                                {
                                                                    data.img_availability == "1" &&
                                                                    <AvField
                                                                        name={"Image" + i}
                                                                        type="number"
                                                                        label={"No of Image"}
                                                                        errorMessage="No of Image"
                                                                        value={data.no_of_img || ''}
                                                                        style={{ width: "75px", }}
                                                                        onChange={(e) => data.no_of_img = parseInt(e.target.value)}
                                                                        validate={{ required: { value: true } }}
                                                                    />
                                                                }
                                                            </div>

                                                            <div className="align-self-center">
                                                                <Popconfirm placement="leftBottom" title={`Are You sure to delete option ${i + 1} ?`} onConfirm={() => { this.deleteParameter(this.props.parameter_options, i) }} >
                                                                    <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
                                                                </Popconfirm>
                                                            </div>
                                                        </div>
                                                    </Col>


                                                </Row>
                                            )
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <button type="button" className="btn btn-primary" onClick={() => this.addRow(this.props.parameter_options)}><i className="bx bx-plus font-size-16 align-middle me-2" ></i> Add Row</button>
                                    </Col>
                                </Row>

                                <Row className="mt-2" style={{ margin: 0 }}>
                                    <div style={{ flex: 1 }} >
                                        <div>
                                            <label >Action Plan Required</label>
                                        </div>
                                        <div style={{ padding: '8px 2px' }}>
                                            <div className="square-switch" style={{ marginTop: 7 }}>
                                                <input type="checkbox" id={this.props.parameterData._id + "apln"} data-switch="info" checked={this.props.parameterData.apln_available == "1" ? true : false} onChange={(e) => { this.onChangeAplnReq(e) }} /><label htmlFor={this.props.parameterData._id + "apln"} data-on-label="Yes" data-off-label="No"  ></label>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        this.props.parameterData.apln_available == "1" &&
                                        <>
                                            <div style={{ flex: 1 }} className="align-center udb" >
                                                <AvField
                                                    name="Action Plan Eligibility %"
                                                    label="Enter Eligibility %"
                                                    type="number"
                                                    placeholder="Eligibility %"
                                                    errorMessage="Enter Eligibility %"
                                                    value={String(this.props.parameterData.apln_eligibility_per)}
                                                    onChange={(e) => this.props.parameterData.apln_eligibility_per = parseFloat(e.target.value)}
                                                    style={{ width: 120 }}
                                                    validate={{
                                                        required: { value: this.state.apln_available },
                                                        pattern: { value: "^[0-9]+$" }
                                                    }}
                                                />
                                            </div>
                                            <div style={{ flex: 6 }} >
                                                <AvField
                                                    name="Observation"
                                                    label="Enter Observation"
                                                    type="textarea"
                                                    errorMessage="Observation"
                                                    value={String(this.props.parameterData.apln_observation == null ? '' : this.props.parameterData.apln_observation)}
                                                    onChange={(e) => this.props.parameterData.apln_observation = e.target.value}
                                                    validate={{ required: { value: this.state.apln_available } }}
                                                />
                                            </div>
                                        </>
                                    }
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <label >Periodicity</label>
                                        <div>
                                            <Select
                                                value={this.props.parameterData.periodicity[0]}
                                                onChange={(e) => { this.props.parameterData.periodicity = [e]; this.setState({ refresh: true }) }}
                                                options={this.props.periodicity_info}
                                                getOptionLabel={(option) => option.periodicity}

                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <label >Mode</label>
                                        <div>
                                            <Select
                                                value={{ label: this.props.parameterData.entry_mode == null ? 'Select' : this.props.parameterData.entry_mode }}
                                                onChange={(e) => { this.props.parameterData.entry_mode = e.label; this.setState({ refresh: true }) }}
                                                options={this.props.entry_mode}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3} className="align-self-end">
                                        <div>
                                            <label>Minimum Score : {this.get_minScore(this.props.parameter_options)}</label>
                                        </div>
                                    </Col>
                                    <Col md={3} className="align-self-end">
                                        <div>
                                            <label>Maximum Score : {this.get_maxScore(this.props.parameter_options)}</label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={12}>
                                        <FormGroup>
                                            <Button color="primary" type="submit" >Save</Button>
                                            <Button color="danger" onClick={() => { this.props.deleteParameter(this.props.parameterData) }} style={{ marginLeft: "10px" }} >Delete</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </AvForm>
                        </div>
                    </TabPane>
                    <TabPane tabId="2" >
                        <Guideline
                            ValidateAndSend={() => this.ValidateAndSend()}
                            guideline_image={this.props.parameterData.guideline_image}
                            _id={this.props.parameterData._id}
                            parameterData={this.props.parameterData}
                        />

                    </TabPane>
                    <TabPane tabId="3" >

                    </TabPane>
                </TabContent>
            </div>:
                <div >

                    <div className="mt-1">

                        <div className="d-flex flex-wrap">
                            <div className="me-2">
                                <h4>{this.props.parameterData.parameter}</h4>
                            </div>
                        </div>

                        {/* <hr className="mt-2" /> */}

                        <div className="list-group list-group-flush">
                            {
                                this.props.parameter_options.map((data, i) => {
                                    return (<div key={i} className="text-muted">
                                        <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                        {data.option_text}
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
        )
    }
}
