
import uuid from 'react-uuid'

const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    let u_id = uuid();
    var file = new File(byteArrays, u_id+".jpeg", { type: contentType });
    return file;

    // var blob = new Blob(byteArrays, { type: contentType });
    // return blob;
  }

  const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }




let ImageProcess = {
  convertBaseToURL: (image_url) => {
    var ImageURL = image_url;
    var block = ImageURL.split(";");
    var contentType = block[0].split(":")[1]; // In this case "image/gif"
    var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    var file = b64toBlob(realData, contentType);
    
    //var btof = blobToFile(blob, u_id, {type: contentType, lastModified: Date.now(), name:u_id, path:u_id, orginalName:u_id})


    return file

  },

 
  
};

 export default ImageProcess;
