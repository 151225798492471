import React, { Component } from "react"
import { Bar } from "react-chartjs-2"

class BarChart extends Component {
  render() {
    const data = {
      labels: ["Total","Not Started", "In progress", "Completed", "Submitted", "Reviewed"],
      datasets: [
        {
          label: this.props.auditData.audit_pbd_name,
          backgroundColor: ["#343a40","#74788d","#f1b44c", "#34c38f", "#50a5f1", "#556ee6"],
          borderColor: ["#343a40","#74788d","#f1b44c", "#34c38f", "#50a5f1", "#556ee6"],
          borderWidth: 1,
          hoverBackgroundColor: ["#343a40","#74788d","#f1b44c", "#34c38f", "#50a5f1", "#556ee6"],
          hoverBorderColor: ["#343a40", "#74788d","#f1b44c", "#34c38f", "#50a5f1", "#556ee6"],
          data: [this.props.auditData.total,this.props.auditData.not_started, 
            this.props.auditData.in_progress,
            this.props.auditData.completed,
            this.props.auditData.submitted,
            this.props.auditData.reviewed,
          ],
        },
      ],
    }

    const option = {
      scales: {
        dataset: [
          {
            barPercentage: 0.4,
          },
        ],
      },
    }

    return (
      <React.Fragment>
        <Bar width={474} height={100} data={data} options={option} />
      </React.Fragment>
    )
  }
}

export default BarChart
