import React, { useEffect, useMemo, useState } from 'react'
import MetaTags from "react-meta-tags";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Spinner , Button
} from "reactstrap";
import { retriveEscalationList, deleteEscalationInfo, retriveSeverityTypeList, retriveHlist, seteditEscalationInfo } from '../../../store/incidentReport/incdreportslice'
import { useDispatch, useSelector } from 'react-redux'
import Drawer from 'react-modern-drawer'
import TableContainer from './Components/TableContainer'
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import EscalationForm from './Components/EscalationForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';


const ManageEscaltion = () => {

    const dispatch = useDispatch()
    const [drawerOpen, setdrawerOpen] = useState(false)
    const [dataLoaded, setdataLoaded] = useState(true)

    const incidentReport = useSelector(state => state.incdreportslice)
    const escalationList = incidentReport.escalationList

    useEffect(() => {
        dispatch(retriveEscalationList())

    }, [])


    const deleteEscalation = (item) => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Do you want to delete this Incident type ?',
            showCancelButton: true,
            confirmButtonColor: '#2ba92b',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteEscalationInfo(item._id))
            }

        })



    }
    const drawerStyles = {
        width: '100%', // Default to full width for small screens
        maxWidth: '700px', // Maximum width for larger screens
    };

    const columns = useMemo(() => [
        {
            accessor: "severity.severity_name",
            Header: "Severity",
            filterable: true,
            Cell: (cellProps) => {
                var item = cellProps.row.original;
                return (
                    <div className="text-dark"
                        style={{ cursor: "pointer" }}
                    >
                        {item.severity.severity_name}
                    </div>
                )

            }
        },
        {
            accessor: "escln_lvl",
            Header: "Level",
            filterable: true,
            Cell: (cellProps) => {
                var item = cellProps.row.original;
                return (
                    <>
                        {
                            item.escln_lvl.map((ele, idx) => {
                                return (
                                    <div key={idx} className="text-dark" style={{ cursor: "pointer" }}>
                                        {ele.escln_level}<hr />
                                    </div>
                                )
                            })
                        }
                    </>
                )

            }
        },
        {
            accessor: "escln_lvl1",
            Header: "Time Duration",
            filterable: true,
            Cell: (cellProps) => {
                var item = cellProps.row.original;
                return (
                    <>
                        {
                            item.escln_lvl.map((ele, idx) => {
                                return (
                                    <div key={idx} className="text-dark" style={{ cursor: "pointer" }}>
                                        {ele.duration} {ele.duration_format}<hr />
                                    </div>
                                )
                            })
                        }
                    </>
                )

            }
        },
        {
            accessor: "hierarchy_name",
            Header: "Hierarchy Name",
            filterable: true,
            Cell: (cellProps) => {
                var item = cellProps.row.original;
                return (
                    <div className="text-dark"
                        style={{ cursor: "pointer" }}
                    >
                        {item.hierarchy_name}
                    </div>
                )

            }
        },
        {
            accessor: "menu",
            disableFilters: true,
            Header: "Action",
            Cell: (cellProps) => {
                var item = cellProps.row.original;
                return (
                    <div className="action-cell">
                        <button onClick={() => { dispatch(seteditEscalationInfo(item)); dispatch(retriveHlist()); setdrawerOpen(true) }} className="btn btn-sm btn-primary mx-1">
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => {
                            deleteEscalation(item)
                        }} className="btn btn-sm btn-danger mx-1">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </div>
                );
            },
        }
    ])

    if (dataLoaded) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>AuditVista | Escalation List</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Manage Escalation" breadcrumbItem="Incident Type" />
                        <Row>
                            {
                                escalationList ?
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <div className='text-end my-2 pe-2' >

                                                    <Button
                                                        color="primary"

                                                        onClick={() => { dispatch(retriveHlist()); dispatch(seteditEscalationInfo({})); setdrawerOpen(true) }}
                                                    >
                                                        Add Escalation
                                                    </Button>
                                                </div>
                                                <TableContainer
                                                    columns={columns}
                                                    data={escalationList}
                                                    isGlobalFilter={true}
                                                    isAddOptions={false}
                                                    isJobListGlobalFilter={false}
                                                    customPageSize={10}
                                                    style={{ width: "100%" }}
                                                    isPagination={true}
                                                    filterable={false}
                                                    tableClass="align-middle table-nowrap table-check"
                                                    theadClass="table-light"
                                                    pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                                    // btnName={"Add Escalation"}
                                                    // dynamicBtn={true}
                                                    // btnClick={() => { dispatch(retriveHlist()); dispatch(seteditEscalationInfo({})); setdrawerOpen(true) }}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col> :
                                    <Col lg="12">
                                        <Card>
                                            <CardBody style={{ height: "100vh" }}>
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div>Loading...</div>
                                                    <Spinner className="ms-2" color="primary" />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }

                            <Drawer
                                open={drawerOpen}
                                onClose={() => { setdrawerOpen(false) }}
                                direction="right"
                                size={'700px'}
                                zIndex={9999}
                                noOverlay={false}
                                style={drawerStyles}
                            >
                                <div
                                    style={{
                                        padding: 10,
                                        maxHeight: '100vh',
                                        overflow: "auto",
                                    }}
                                >
                                    {
                                        drawerOpen &&
                                        <EscalationForm
                                            onClose={() => { setdrawerOpen(false);setdataLoaded(false);
                                                setTimeout(() => {
                                                    setdataLoaded(true);
                                                }, 100);
                                                // dispatch(retriveEscalationList())
                                             }}
                                        />
                                    }
                                </div>
                            </Drawer>
                        </Row>


                    </Container>

                </div>
            </React.Fragment>
        )
    }
    else {
        return (
            <Col lg="12">
                <Card>
                    <CardBody style={{ height: "100vh" }}>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div>Loading...</div>
                            <Spinner className="ms-2" color="primary" />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

export default ManageEscaltion
