import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
import TagsInput from 'react-tagsinput'
import TableContainer from "../../../../common/TableContainer";

// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"

import 'react-tagsinput/react-tagsinput.css'


import urlSocket from "../../../../helpers/urlSocket";
const _ = require('lodash')
// var urlSocket = require("../../../helpers/urlSocket")

export default class LoodEndpointNode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            selectedEOPT:[],
            dup_selectedEOPT:[],
            all_selectedEOPT:[],
            selected_id :[]

        };

    }


    async componentDidMount() {
        var db_info = JSON.parse(sessionStorage.getItem('db_info'))
        this.setState({db_info},()=>{this.loadEOPTS()})        
    }

    loadEOPTS = () => {
        this.setState({ tableDataloaded: false })
        try {
            urlSocket.post("webEntities/endpointlist", {
                userInfo: {
                    encrypted_db_url:this.state.db_info.encrypted_db_url,
                    company_id: this.props.userInfo.company_id,
                    user_id: this.props.userInfo._id,
                },
            })
                .then((response) => {
                    this.setState({
                        statusBasedFilteredData: response.data.data,
                        tableDataloaded: true,
                        allowTableRowToSelect: true,
                        labelDefault: true,
                        label: {
                            endpoints: []
                        },


                    }, function () {
                        this.loadUserLabels()
                    })

                })
        } catch (error) {

        }
    }


    loadUserLabels() {
        try {
            urlSocket.post("userSettings/getuserlabels", {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.props.userInfo._id,
                    company_id: this.props.userInfo.company_id
                }
            })
                .then(response => {
                    this.setState({
                        labelData: response.data.data,
                        dataLoaded: true

                    })
                })

        } catch (error) {
            console.log("catch error", error)
        }
    }

    labelSelected(data) {

        if(data.target.value === "all")
        {
            this.loadEOPTS()
        }
        else
        {

            var mylabel = this.state.labelData[data.target.value]
    
            this.setState({
                tableDataloaded: false, selectedLabel: mylabel._id,
                label: mylabel
            })
    
            try {
    
                urlSocket.post("webEntities/loadlabeleopts", {
                    labelInfo: mylabel,
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.props.userInfo._id,
                        company_id: this.props.userInfo.company_id
                    }
                })
                    .then(response => {
                        this.setState({
                            statusBasedFilteredData: response.data.data,
                            labelDefault: false,
                            tableDataloaded: true
                        })
                    })
    
            } catch (error) {
                console.log("catch error", error)
            }
    
        }

    }

    // getSelectedEOPT = (row, isSelect, rowIndex, e) => {
    //     var getEOPTId = _.map(this.state.selectedEOPT, "_id")
    //     var getIndex = getEOPTId.indexOf(row._id)
    //     if (getIndex === -1) {
    //         this.state.selectedEOPT.push(row)
    //         var temp = this.state.all_selectedEOPT
    //         temp.push(row)
    //         this.setState({ all_selectedEOPT: temp })
    //         this.setState({
    //             selectedEOPT: temp
    //         },()=>{this.handleSelectAll(isSelect,0,this.state.selectedEOPT)})
    //     }
    //     else {
    //         this.state.selectedEOPT.splice(getIndex, 1)
    //         this.setState({
    //             selectedEOPT: this.state.selectedEOPT
    //         },()=>{ this.handleSelectAll(this.state.selectedEOPT)})
    //     }


    // }

    getSelectedEOPT = (row, isSelect, rowIndex, e) => {
        console.log(row,'row')
        var getEOPTId = _.map(this.state.selectedEOPT, "_id")
        var getIndex = getEOPTId.indexOf(row._id)

        if (getIndex === -1) {
            this.state.selectedEOPT.push(row)

            this.setState({
                selectedEOPT: this.state.selectedEOPT
            })
        }
        else {
            this.state.selectedEOPT.splice(getIndex, 1)
            this.setState({
                selectedEOPT: this.state.selectedEOPT
            })
        }


    }






    addEndpoints(){
        try {
            var loggedUserInfo = {
                encrypted_db_url: this.state.db_info.encrypted_db_url,
                company_id: this.props.userInfo.company_id,
                company_name: this.props.userInfo.company_name,
                created_by:this.props.userInfo._id
            }
            console.log(this.state.selectedEOPT,'this.state.selectedEOPT',this.props.publishtemplateInfo)
            urlSocket.post("webphlbconf/addmultipleendpoints", {
                userInfo: loggedUserInfo,
                publishtemplateInfo: this.props.publishtemplateInfo,
                endpointInfo: this.state.selectedEOPT
            })
            .then(response=>{
                console.log(response,'response')
                if(response.data.response_code === 500) {
                        this.props.onClose()
                }
            })
        } catch (error) {
            console.log("catch error", error)
        }
    }


    handleSelectAll = (e,mode,rows) => {
        const isChecked = e;
        const data = mode==1 ? this.state.statusBasedFilteredData :rows ;
        // Create a new array of selected row keys
        const selectedRowKeys = isChecked ? data.map(item => item._id) : [];
        // Update the selectedEOPT state
        this.setState({
          selectedEOPT: selectedRowKeys,
          dup_selectedEOPT: isChecked? data :[],
        });
      };



      select_func = (isSelect, rows, e, mode) => {
        console.log(rows,'rows',isSelect)
        if (mode === "1" || mode !== "1") {
          let selectedEOPT = [];
          let selected_id = [];
      
          if (isSelect) {
            selectedEOPT = rows;
            rows.forEach((data) => {
              selected_id.push(data._id);
            });
          }
      
          this.setState({
            selectedEOPT,
            selected_id,
          });
        }
      };


    //   select_func =(isSelect, rows, e,mode)=>{
    //     if(mode == "1"){
    //       if (isSelect) {
    //         var selected_id =[]
    //           this.state.selectedEOPT = rows
    //           rows.map((data,idx)=>{
    //             selected_id.push(data._id)
    //           })
    //           this.setState({
    //               selectedEOPT: this.state.selectedEOPT,
    //               selected_id : selected_id
    //           })
    //       }
    //       else {
    //           this.setState({
    //               selectedEOPT: [],
    //               selected_id:[]
    //           })
    //       }
          
    //     }
    //     else{
    //         var selected_id=[]
    //         if (isSelect) {
    //             this.state.selectedEOPT = rows
    //             rows.map((data,idx)=>{
    //                 selected_id.push(data._id)
    //               })
    //             this.setState({
    //                 selectedEOPT: this.state.selectedEOPT,
    //                 selected_id:selected_id
    //             })
    //         }
    //         else {
    //             this.setState({
    //                 selectedEOPT: [],
    //                 selected_id :[]
    //             })
    //         }


    //     }


    //   }

    handleSelectAllCheckbox = (event) => {
        const isChecked = event.target.checked;
        const rows = this.state.statusBasedFilteredData;

        this.setState({
            selectedEOPT: isChecked ? rows : [],
        });
    };

      

    render() {
        if (this.state.dataLoaded) {
            // const { SearchBar } = Search;
            // const { users } = this.state
            const options = {
                // pageStartIndex: 0,

                sizePerPage: 7,
                totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
                custom: true,
            };
            const columns = [
                {
                    Header: (cellProps) => <div className="form-check font-size-16" >
                        <input className="form-check-input" onChange={() => {
                            // Determine if all rows are currently selected
                            const allSelected = cellProps.page.every((row) => row.original.selected);
                            console.log(allSelected,'allSelected',cellProps.page)
                            // Update the selection for each row
                            cellProps.page.forEach((row) => {
                                row.original.selected = !allSelected;
                            }); this.select_func(!allSelected, _.map(cellProps.page,"original"), "e", '1')
                        }} type="checkbox" checked={cellProps.page.length > 0 && cellProps.page.every((row) => row.original.selected)} id="checkAll" />
                        <label className="form-check-label" htmlFor="checkAll"></label>
                    </div>,
                    accessor: '#',
                    width: '20px',
                    filterable: true,
                    Cell: ({ row }) => (
                        <div className="form-check font-size-16" >
                            <input className="form-check-input" checked={row.original.selected} type="checkbox" onChange={(e) => { row.original.selected = !row.original.selected; this.getSelectedEOPT(row.original) }} id="checkAll" />
                            <label className="form-check-label" htmlFor="checkAll"></label>
                        </div>
                    )
                },
                {
                    accessor: 'name',
                    Header: 'Name',
                    filterable: true,
                    formatter: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className=" font-size-14 text-dark" style={{ marginBottom: 5 }}>
                                        {item.name}
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'code',
                    Header: 'Code'
                },
                {
                    accessor: 'category',
                    Header: 'Category'
                },                
            ];
            const defaultSorted = [{
                dataField: 'created_on', // if dataField is not match to any column you defined, it will be ignored.
                order: 'desc' // desc or asc
            }];

            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true, // this.state.allowTableRowToSelect,
                // selected : this.state.selected_id,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.getSelectedEOPT(row, isSelect, rowIndex, e)
                },
                onSelectAll: (isSelect, rows, e) => {
                    this.select_func(isSelect, rows, e,'1')
          
                },
            }


            // const selectRow = {
            //     mode: 'checkbox',
            //     clickToSelect: true,
            //     selected: this.state.selectedEOPT, // Pass the selected row keys here
            //     onSelect: (row, isSelect, rowIndex, e) => {
            //       this.getSelectedEOPT(row, isSelect, rowIndex, e);
            //     },
            //     onSelectAll:(row, isSelect, rowIndex, e) => {
            //     this.handleSelectAll(row,0,isSelect)
            //     }
            //   };
            return (
                <React.Fragment>
                    <Container fluid>
                        <Row >
                            <Col >
                                <div className="px-5">
                                    <Row className="my-4">
                                        <div className="d-sm-flex align-items-center justify-content-between">
                                            <div className="text-danger font-size-18">Load Location</div>
                                            <button className="btn btn-outline-dark" onClick={() => this.props.onClose()}> Close </button>
                                        </div>

                                        <hr className="my-4" />
                                    </Row>
                                    <Row>
                                        <AvForm className="form-horizontal" onValidSubmit={this.submitData} onInvalidSubmit={this.handleInvalidSubmit}>
                                            <div className="mb-1">
                                                <Label className="" htmlFor="autoSizingSelect">Select label</Label>
                                                <AvInput
                                                    type="select"
                                                    name="hlevel_cat"
                                                    label="Name"
                                                    value={null}
                                                    className="form-select"
                                                    id="cate"
                                                    required
                                                    onChange={(e) => this.labelSelected(e)}>
                                                    <option value="" disabled selected>Choose...</option>
                                                    <option value="all"  >ALL</option>
                                                    {
                                                        this.state.labelData.map((data, idx) => {
                                                            return (
                                                                <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
                                                            )
                                                        })
                                                    }
                                                </AvInput>
                                            </div>
                                        </AvForm>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="p-5">
                                {
                                    this.state.tableDataloaded ?
                                    <TableContainer
                                    columns={columns}
                                    data={this.state.statusBasedFilteredData}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    isJobListGlobalFilter={false}
                                    customPageSize={10}
                                    style={{ width: '100%' }}
                                    isPagination={true}
                                    filterable={false}
                                    btnName ={"Add User"}
                                    tableClass="align-middle table-nowrap table-check"
                                    theadClass="table-light"
                                    pagination="pagination pagination-rounded justify-content-end mb-2"
                                    // dynamicBtn={true}
                                    // btnClick={(param1, param2) => this.navigateTo("",1)}
                                    // // dynamicParams={params1,params2}
                                    // btnName ={"Add User"}
      
                                />
                                        // <PaginationProvider
                                        //     keyField="id"
                                        //     data={this.state.statusBasedFilteredData}
                                        //     columns={columns}
                                        //     pagination={paginationFactory(options)}
                                        // >
                                        //     {
                                        //         ({
                                        //             paginationProps,
                                        //             paginationTableProps
                                        //         }) => (
                                        //             <ToolkitProvider
                                        //                 keyField="_id"
                                        //                 data={this.state.statusBasedFilteredData}
                                        //                 columns={columns}
                                        //                 search
                                        //             >
                                        //                 {
                                        //                     toolkitprops => (
                                        //                         <React.Fragment>
                                                                 
                                        //                             <Row className="mb-2" style={{ paddingTop: 10 }}>
                                        //                                 <Col sm="10">
                                        //                                     <div className="search-box ms-2 d-inline-block">
                                        //                                         <div className="position-relative">
                                        //                                             {/* <SearchBar {...toolkitprops.searchProps} /> */}
                                        //                                             <i className="bx bx-search-alt search-icon" />
                                        //                                         </div>
                                        //                                     </div>
                                        //                                 </Col>
                                        //                                 <Col md={2}>
                                                                       
                                        //                                 </Col>
                                        //                             </Row>
                                        //                             <hr className="my-2" />

                                        //                             <Row>
                                        //                                 <Col xl="12">
                                        //                                     <div className="table-responsive">
                                        //                                         <BootstrapTable
                                        //                                             {...toolkitprops.baseProps}
                                        //                                             {...paginationTableProps}
                                        //                                             defaultSorted={defaultSorted}
                                        //                                             selectRow={selectRow}
                                        //                                             classes={
                                        //                                                 "table align-middle table-nowrap table-hover"
                                        //                                             }
                                        //                                             bordered={false}
                                        //                                             striped={false}
                                        //                                             responsive
                                        //                                             ref={this.tableRef}
                                        //                                         />

                                        //                                     </div>
                                        //                                 </Col>
                                        //                             </Row>
                                        //                             <Row className="align-items-md-center mt-30">
                                        //                                 <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        //                                     <PaginationListStandalone
                                        //                                         {...paginationProps}
                                        //                                     />
                                        //                                 </Col>
                                        //                             </Row>
                                        //                         </React.Fragment>
                                        //                     )}
                                        //             </ToolkitProvider>
                                        //         )}
                                        // </PaginationProvider>
                                         : null
                                }
                                <Button color="primary" onClick={()=>{this.addEndpoints()}} > Add Selected Location</Button>
                            </div>
                            
                        </Row>
                    </Container>
                </React.Fragment>
            )
        }
        else {
            return null
        }
    }
}




























// import React, { Component } from "react";
// import ReactDOM from 'react-dom';
// import {
//     Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
//     Input,
// } from "reactstrap";
// import Select from "react-select";
// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
// import TagsInput from 'react-tagsinput'

// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"

// import 'react-tagsinput/react-tagsinput.css'



// const _ = require('lodash')
// var urlSocket = require("../../../helpers/urlSocket")

// export default class LoodEndpointNode extends React.Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             dataLoaded: false,
//             selectedEOPT:[]

//         };
//         this.table = React.createRef();  
//     }



//     async componentDidMount() {
//         var db_info = JSON.parse(sessionStorage.getItem('db_info'))
//         this.setState({db_info},()=>{this.loadEOPTS()})        
//     }

//     loadEOPTS = () => {
//         this.setState({ tableDataloaded: false })
//         try {
//             urlSocket.post("webEntities/endpointlist", {
//                 userInfo: {
//                     encrypted_db_url:this.state.db_info.encrypted_db_url,
//                     company_id: this.props.userInfo.company_id,
//                     user_id: this.props.userInfo._id,
//                 },
//             })
//                 .then((response) => {
//                     this.setState({
//                         statusBasedFilteredData: response.data.data,
//                         tableDataloaded: true,
//                         allowTableRowToSelect: true,
//                         labelDefault: true,
//                         label: {
//                             endpoints: []
//                         },


//                     }, function () {
//                         this.loadUserLabels()
//                     })

//                 })
//         } catch (error) {

//         }
//     }


//     loadUserLabels() {
//         try {
//             urlSocket.post("userSettings/getuserlabels", {
//                 userInfo: {
//                     encrypted_db_url: this.state.db_info.encrypted_db_url,
//                     _id: this.props.userInfo._id,
//                     company_id: this.props.userInfo.company_id
//                 }
//             })
//                 .then(response => {
//                     this.setState({
//                         labelData: response.data.data,
//                         dataLoaded: true

//                     })
//                 })

//         } catch (error) {
//         }
//     }

//     labelSelected(data) {

//         if(data.target.value === "all")
//         {
//             this.loadEOPTS()
//         }
//         else
//         {

//             var mylabel = this.state.labelData[data.target.value]
    
//             this.setState({
//                 tableDataloaded: false, selectedLabel: mylabel._id,
//                 label: mylabel
//             })
    
//             try {
    
//                 urlSocket.post("webEntities/loadlabeleopts", {
//                     labelInfo: mylabel,
//                     userInfo: {
//                         encrypted_db_url: this.state.db_info.encrypted_db_url,
//                         _id: this.props.userInfo._id,
//                         company_id: this.props.userInfo.company_id
//                     }
//                 })
//                     .then(response => {
//                         this.setState({
//                             statusBasedFilteredData: response.data.data,
//                             labelDefault: false,
//                             tableDataloaded: true
//                         })
//                     })
    
//             } catch (error) {
//                 console.log("catch error", error)
//             }
    
//         }

//     }

//     getSelectedEOPT = (row, isSelect, rowIndex, e) => {

//         var getEOPTId = _.map(this.state.selectedEOPT, "_id")
//         var getIndex = getEOPTId.indexOf(row._id)

//         if (getIndex === -1) {
//             this.state.selectedEOPT.push(row)

//             this.setState({
//                 selectedEOPT: this.state.selectedEOPT
//             })
//         }
//         else {
//             this.state.selectedEOPT.splice(getIndex, 1)
//             this.setState({
//                 selectedEOPT: this.state.selectedEOPT
//             })
//         }


//     }

//     // selectAll(event,data){
//     //     this.setState({selectedEOPT : data, disabled_box:true})
        
        
//     // }

//     // selectAll = (event, data,selectRows) => {
//     //     var row = data.slice(0,7)
//     //     selectRows.onSelectAll(event.target.checked,row)
//     //     // this.setState({ selectedEOPT: data });
      
//     //     // const { selectRow } = selectRows;
//     //     // const { onSelectAll } = selectRow;

//     //     // if (onSelectAll) {
//     //     //   const isSelect = true;
//     //     //   const selectedRows = data.map(row => {
//     //     //     return { ...row, selected: isSelect }; // Set the 'selected' property to 'true'
//     //     //   });
//     //     //   onSelectAll(isSelect, selectedRows, event);
//     //     // }
//     //   };


//     selectAll = (event, data, selectRows) => {
//         const row = data.slice(0, 7);
        
//         // Update the selected property of each row to isSelect value
//         const updatedRows = row.map(rowItem => ({
//           ...rowItem,
//           selected: event.target.checked
//         }));
//         selectRows.onSelectAll(event.target.checked, updatedRows);
//       }



//     addEndpoints(){
//         try {
//             var loggedUserInfo = {
//                 encrypted_db_url: this.state.db_info.encrypted_db_url,
//                 company_id: this.props.userInfo.company_id,
//                 company_name: this.props.userInfo.company_name,
//                 created_by:this.props.userInfo._id
//             }
//             urlSocket.post("webphlbconf/addmultipleendpoints", {
//                 userInfo: loggedUserInfo,
//                 publishtemplateInfo: this.props.publishtemplateInfo,
//                 endpointInfo: this.state.selectedEOPT
//             })
//             .then(response=>{
//                 if(response.data.response_code === 500)
//                 {
//                         this.props.onClose()
//                 }
//             })
//         } catch (error) {
//             console.log("catch error", error)
//         }


//     }


//     onSelectAll = (isSelected) => {
//         if (isSelected) {
//           return this.state.statusBasedFilteredData.map(row => row.id);
//         }
//       }




//     render() {
//         if (this.state.dataLoaded) {
//             const { SearchBar } = Search;
//             const options = {
//                 // pageStartIndex: 0,

//                 sizePerPage: 7,
//                 totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
//                 custom: true,
//             };
//             const columns = [
//                 {
//                     text: "id",
//                     dataField: "_id",
//                     sort: true,
//                     hidden: true,
//                     formatter: (cellContent, item) => (
//                         <>
//                             {item._id}
//                         </>
//                     ),
//                 },
//                 {
//                     dataField: 'name',
//                     text: 'Name',
//                     sort: true,
//                     formatter: (cellContent, item) => {
//                         return (
//                             <>
//                                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                                     <div className=" font-size-14 text-dark" style={{ marginBottom: 5 }}>
//                                         {item.name}
//                                     </div>
//                                 </div>
//                             </>
//                         )
//                     }
//                 },
//                 {
//                     dataField: 'code',
//                     text: 'Code'
//                 },
//                 {
//                     dataField: 'category',
//                     text: 'Category'
//                 },
//                 {
//                     dataField: 'created_on',
//                     isDummyField: true,
//                     hidden: true,
//                     text: 'updated on'
//                 },
                
                
//             ];
//             const defaultSorted = [{
//                 dataField: 'created_on', // if dataField is not match to any column you defined, it will be ignored.
//                 order: 'desc' // desc or asc
//             }];

//             const selectRow = {
//                 mode: 'checkbox',
//                 clickToSelect: true, 
//                 // this.state.allowTableRowToSelect,
//                 onSelect: (row, isSelect, rowIndex, e) => {
//                     this.getSelectedEOPT(row, isSelect, rowIndex, e)
//                 },
//                 onSelectAll: (isSelect, rows, e) => {
//                     this.state.selectedEOPT = rows
//                     this.setState({
//                         selectedEOPT:this.state.selectedEOPT
//                     })
            
            
//                 },

//                 // onSelectAll: this.onSelectAll
//                 // nonSelectable: this.state.label.endpoints

//             };
//             return (
//                 <React.Fragment>
//                     <Container fluid>
//                         <Row >
//                             <Col >
//                                 <div className="px-5">
//                                     <Row className="my-4">
//                                     <div className="d-sm-flex align-items-center justify-content-between">
//                                     <div className="text-danger font-size-18">Load End points</div>

//                                                 <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
//                                             </div>
//                                         <hr className="my-4" />
//                                     </Row>
//                                     <Row>
//                                         <AvForm className="form-horizontal" onValidSubmit={this.submitData} onInvalidSubmit={this.handleInvalidSubmit}>
//                                             <div className="mb-1">
//                                                 <Label className="" htmlFor="autoSizingSelect">Select label</Label>
//                                                 <AvInput
//                                                     type="select"
//                                                     name="hlevel_cat"
//                                                     label="Name"
//                                                     value={null}
//                                                     className="form-select"
//                                                     id="cate"
//                                                     required
//                                                     onChange={(e) => this.labelSelected(e)}>
//                                                     <option value="" disabled selected>Choose...</option>
//                                                     <option value="all"  >ALL</option>
//                                                     {
//                                                         this.state.labelData.map((data, idx) => {
//                                                             return (
//                                                                 <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
//                                                             )
//                                                         })
//                                                     }
//                                                 </AvInput>
//                                             </div>
//                                         </AvForm>
//                                     </Row>
//                                 </div>
//                             </Col>
//                         </Row>
//                         <Row>
//                             <div className="p-5">
//                                 {
//                                     this.state.tableDataloaded ?

//                                         <PaginationProvider
//                                             keyField="id"
//                                             data={this.state.statusBasedFilteredData}
//                                             columns={columns}
//                                             pagination={paginationFactory(options)}
//                                         >
//                                             {
//                                                 ({
//                                                     paginationProps,
//                                                     paginationTableProps
//                                                 }) => (
//                                                     <ToolkitProvider
//                                                     // ref="table"
//                                                         keyField="_id"
//                                                         data={this.state.statusBasedFilteredData}
//                                                         columns={columns}
//                                                         search
//                                                     >
//                                                         {
//                                                             toolkitprops => (
//                                                                 <React.Fragment>
//                                                                     <Row className="mb-2" style={{ paddingTop: 10 }}>
//                                                                         <Col sm="10">
//                                                                             <div className="search-box ms-2 d-inline-block">
//                                                                                 <div className="position-relative">
//                                                                                     <SearchBar {...toolkitprops.searchProps} />
//                                                                                     <i className="bx bx-search-alt search-icon" />
//                                                                                 </div>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={2}>
//                                                                         {/* <input type="checkbox" onClick={(e)=>{selectRow.onSelectAll(e.target.checked,this.state.statusBasedFilteredData)}}/> &nbsp;

//                                                                         <label>Select All</label> */}
                                                                        
//                                                                         </Col>
//                                                                     </Row>
//                                                                     <hr className="my-2" />

//                                                                     <Row>
//                                                                         <Col xl="12">
//                                                                             <div className="table-responsive">
//                                                                                 <BootstrapTable
//                                                                                     {...toolkitprops.baseProps}
//                                                                                     {...paginationTableProps}
//                                                                                     defaultSorted={defaultSorted}
//                                                                                     selectRow={selectRow}
//                                                                                     classes={
//                                                                                         "table align-middle table-nowrap table-hover"
//                                                                                     }
//                                                                                     bordered={false}
//                                                                                     striped={false}
//                                                                                     responsive
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                     </Row>
//                                                                     <Row className="align-items-md-center mt-30">
//                                                                         <Col className="pagination pagination-rounded justify-content-end mb-2">
//                                                                             <PaginationListStandalone
//                                                                                 {...paginationProps}
//                                                                             />
//                                                                         </Col>
//                                                                     </Row>
//                                                                 </React.Fragment>
//                                                             )}
//                                                     </ToolkitProvider>
//                                                 )}
//                                         </PaginationProvider> : null
//                                 }
//                                 <Button color="primary" onClick={()=>{this.addEndpoints()}} > Add Selected End points</Button>
//                             </div>
                            
//                         </Row>
//                     </Container>
//                 </React.Fragment>
//             )
//         }
//         else {
//             return null
//         }
//     }
// }