import React, { Component } from "react"
import { isEmpty } from "lodash"
import MetaTags from 'react-meta-tags';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

import { Row, Col, CardBody, Card, Container, Alert, Button } from "reactstrap"
import facility from '../FacilityScreen'
import _ from "lodash";
// var urlSocket = require("../../helpers/urlSocket");
import urlSocket from "../../../helpers/urlSocket";
import Breadcrumbs from "../../../components/Common/Breadcrumb"

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataloaded: false,
            alertEnable:false,
            formDisabled:false,
            userInfo:{},
            invalid_usercode:false,

            invalid_phone_num: false,


        };
    }
    async componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("selectedUser"));
        var auth_user = JSON.parse(sessionStorage.getItem("authUser"));
      var db_info = JSON.parse(sessionStorage.getItem("db_info"));
      var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
      var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"));


       var protect_routes = await facility(user_facilities, 'murs')
      var user_info ={}
      user_info["encrypted_db_url"] = db_info.encrypted_db_url
      user_info["db_name"] = db_info.db_name
      user_info["email_id"] = data.email_id
      user_info["user_id"] = auth_user.user_data._id
    if(protect_routes !== undefined){

        this.setState(
            {
                sessionUserInfo: data,
                api_data:user_info,
                dataloaded: false,
                db_info:db_info,
                client_info:client_info,
                user_data : auth_user.user_data,
                protect_routes


            },
            function () {
                this.getUserInfo()
            }
        )
    }
    else{
        this.props.history.push('/dashboard')
    }
    }

    getUserInfo = () => {

      try {
        urlSocket.post("cog/getUserInfo", 
        this.state.api_data)
          .then((response) => {
            if (response.data.response_code == 500) {
              this.setState({
                userInfo:response.data.user_info[0],
                dataloaded: true,
              },()=>{this.setState({countrycode : this.state.userInfo.countrycode})})
            }
          })
      } catch (error) {
        console.log("catch error", error)
      }
    }


//  handleValidSubmit = (event, values) => {
//         try {
//             var selectedUserInfo = {
//                 _id:this.state.sessionUserInfo._id,
//                 company_id: this.state.sessionUserInfo.company_id,
//             }
//             urlSocket.post("webUsers/cruduser", {
//                 selectedUserInfo: selectedUserInfo,
//                 updateUserInfo: values
//             })
//                 .then((response) => {
//                     if (response.data.response_code == 504) {
//                         this.setState({ formDisabled: false, alertEnable: true, alertColor: "danger", message: response.data.message })
//                     }
//                     else
//                         if (response.data.response_code == 500) {
//                             this.setState({
//                                 first_name: response.data.data.first_name,
//                                 formDisabled: true,
//                                 alertEnable: true,
//                                 alertColor: "success",
//                                 message: response.data.message
//                             })
//                         }
//                 })
//         } catch (error) {

//         }
//     }

    handleValidSubmit = (event, values) => {
        console.log(values,'values')
        values["_id"]=this.state.userInfo._id
        values["facilities"]=this.state.userInfo.facilities
        values["encrypted_db_url"]=this.state.api_data.encrypted_db_url
        values["db_name"]=this.state.api_data.db_name
        values["countrycode"]=this.state.countrycode
        values["confirmpassword"]=this.state.userInfo.confirmpassword
        values["company_id"] = this.state.userInfo.company_id
        values["created_by"] = this.state.userInfo.created_by
        values["role_id"] = this.state.userInfo.role_id
        values["role_name"] = this.state.userInfo.role_name
        values["user_id"] = this.state.userInfo.user_id
        values["active"] = this.state.userInfo.active
        values["status"] = this.state.userInfo.status



        if(this.state.invalid_usercode === false && this.state.invalid_phone_num === false){
             try {
                 urlSocket.post('cog/cruduser', values).then((res) => {
                   this.props.history.push('/murs')
                 })
 
             } catch (error) {
                 console.log(error, 'error')
             }
 
     //    values[] 
    }
 
     }

    validate_user = (e) => {
        var admn_info = {}
        admn_info["usercode"] = e.target.value
        admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        admn_info["db_name"] = this.state.db_info.db_name
        admn_info["_id"] = this.state.sessionUserInfo._id
        admn_info["user_id"] = this.state.user_data._id


        try {
            urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((res) => {
                if (res.data.data.length > 0 && res.data.message === "Email Id already exist for another user") {
                    this.setState({ invalid_usercode: true })
                }
                else {
                    this.setState({ invalid_usercode: false })

                }

            })
        } catch (error) {
            console.log(error, 'error')
        }



    }






    // handleValidSubmit = (event, values) => {
    //     try {
    //         var selectedUserInfo = {
    //             _id:this.state.sessionUserInfo._id,
    //             company_id: this.state.sessionUserInfo.company_id,
    //         }
    //         urlSocket.post("webUsers/cruduser", {
    //             selectedUserInfo: selectedUserInfo,
    //             updateUserInfo: values
    //         })
    //             .then((response) => {
    //                 if (response.data.response_code == 504) {
    //                     this.setState({ formDisabled: false, alertEnable: true, alertColor: "danger", message: response.data.message })
    //                 }
    //                 else
    //                     if (response.data.response_code == 500) {
    //                         this.setState({
    //                             first_name: response.data.data.first_name,
    //                             formDisabled: true,
    //                             alertEnable: true,
    //                             alertColor: "success",
    //                             message: response.data.message
    //                         })
    //                     }
    //             })
    //     } catch (error) {

    //     }
    // }

    select_country=(e)=>{
        this.setState({countrycode:e.target.value})
    }

    validate_email = (e, mode) => {
        console.log(e.target.value, "events")
        var admn_info = {}
        if (mode === 2) {
            admn_info["phone_number"] = e.target.value
        }
        admn_info["encrypted_db_url"] = this.state.api_data.encrypted_db_url
        admn_info["db_name"] = this.state.api_data.db_name
        admn_info["user_id"] = this.state.sessionUserInfo._id
        admn_info["edit"] = true
        console.log(admn_info, 'admn_info')
        try {
            urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
                console.log(data, 'data')
                // if (mode == 2) {
                if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                    this.setState({ invalid_phone_num: true })
                } else {
                    this.setState({ invalid_phone_num: false })
                }
            })
        } catch (error) {

        }
    }

    gotoBack = () => {
        this.props.history.push('/murs')
    }

    render() {
        if (this.state.dataloaded) {
            return (
                <div className="page-content">

                    <MetaTags>
                        <title>AuditVista | View User Details</title>
                    </MetaTags>

                    <Container fluid>

                        <Breadcrumbs
                            title="User Details"
                            breadcrumbItem="User Info"
                            isBackButtonEnable={true}
                            gotoBack={() => this.gotoBack()}
                        />

                        <Row >
                            <Col >
                                <Card className="overflow-hidden">
                                    {
                                        !this.state.formDisabled ?
                                            <CardBody className="pt-0">
                                                <div className="p-2">
                                                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


                                                        <Row className="my-4">
                                                            <div className="text-primary font-size-15">User Information</div>
                                                            <hr className="my-2 border-secondary opacity-50" />
                                                        </Row>
                                                        <div className="col-8">
                                                            <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3" >
                                                                        <label>Full Name :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="firstname"
                                                                            // label="First Name"
                                                                            className="form-control"
                                                                            placeholder="Enter First Name"
                                                                            type="text"
                                                                            value={this.state.userInfo.firstname}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                    <div className="mb-3" >
                                                                        <label>Email Id :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="email_id"
                                                                            // label="User Email"
                                                                            className="form-control"
                                                                            placeholder="Enter User Email"
                                                                            type="email"
                                                                            required
                                                                            value={this.state.userInfo.email_id}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="col-6">
                                                                    <label>Phone Number:<span className="text-danger"> *</span></label>
                                                                    <Row>
                                                                        <Col md={3} className="pe-0">
                                                                            <select name="countrycode" onChange={(e) => { this.select_country(e) }} className="form-select" required>
                                                                                <option value={this.state.countrycode}>{this.state.userInfo.countrycode}</option>
                                                                                {
                                                                                    this.state.client_info.countries.map((c, idx) => (
                                                                                        <option key={idx} value={c.code}>
                                                                                            {c.code}{""}&nbsp;{c.label}
                                                                                        </option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            {
                                                                                this.state.country_code_err &&
                                                                                <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


                                                                            }
                                                                        </Col>
                                                                        <Col md={9} className="ps-0">
                                                                            <AvField
                                                                                name="phone_number"
                                                                                className="form-control"
                                                                                placeholder="Enter Phone number"
                                                                                type="number"
                                                                                value={this.state.userInfo.phone_number}
                                                                                onChange={(e) => { this.validate_email(e, 2) }}
                                                                                onKeyPress={(e) => {
                                                                                    if (!/[0-9]/.test(e.key)) {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                                onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("null") }}
                                                                                validate={{ required: { value: true }, minLength: { value: 10, errorMessage: "Enter 10 Digit Number." }, maxLength: { value: 15, errorMessage: "Enter 15 Digit Number." }, }}
                                                                            />
                                                                            {
                                                                                this.state.invalid_phone_num &&
                                                                                <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {/* <Row>
                                                                <Col className="col-8">
                                                                    <Row>
                                                                <div className="mb-3">
                                                                        <label>Phone Number:<span className="text-danger"> *</span></label>
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <select name="countrycode" onChange={(e)=>{this.select_country(e)}} className="form-select" required>
                                                                                    <option value={this.state.countrycode}>{this.state.userInfo.countrycode}</option>
                                                                                    {
                                                                                        this.state.client_info.countries.map((c,idx)=>(
                                                                                            <option key={idx} value={c.code}>
                                                                                                {c.code}{""}&nbsp;{c.label}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                        <AvField
                                                                            name="phone_number"
                                                                            // label="Phone Number"
                                                                            className="form-control"
                                                                            placeholder="Enter Phone number"
                                                                            type="number"
                                                                            value = {this.state.userInfo.phone_number}
                                                                            validate={{
                                                                                required: { value: true },
                                                                                minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                            }}
                                                                        />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </Row>
                                                                </Col>
                                                            </Row> */}
                                                        {
                                                            this.state.country_code_err &&
                                                            <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


                                                        }
                                                        {/* <Row>
                                                                <Col className="col-4">
                                                                    <div className="mb-3" >
                                                                        <AvField
                                                                            name="city"
                                                                            label="City"
                                                                            className="form-control"
                                                                            placeholder="Enter City"
                                                                            type="text"
                                                                            value = {this.state.userInfo.city}
                                                                            //required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-4">
                                                                    <div className="mb-3" >
                                                                        <AvField
                                                                            name="state"
                                                                            label="State"
                                                                            className="form-control"
                                                                            placeholder="Enter State"
                                                                            type="text"
                                                                            value = {this.state.userInfo.state}
                                                                           // required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-4">
                                                                    <div className="mb-3" >
                                                                        <AvField
                                                                            name="country"
                                                                            label="Country"
                                                                            className="form-control"
                                                                            placeholder="Enter Country"
                                                                            type="text"
                                                                            value = {this.state.userInfo.country}
                                                                           // required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row> */}

                                                        {/* <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3" >
                                                                    <label>Email Id :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="email_id"
                                                                            // label="User Email"
                                                                            className="form-control"
                                                                            placeholder="Enter User Email"
                                                                            type="email"
                                                                            required
                                                                            value = {this.state.userInfo.email_id}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <label>Phone Number:<span className="text-danger"> *</span></label>
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <select name="countrycode" onChange={(e)=>{this.select_country(e)}} className="form-select" required>
                                                                                    <option value={this.state.countrycode}>{this.state.userInfo.countrycode}</option>
                                                                                    {
                                                                                        this.state.client_info.countries.map((c,idx)=>(
                                                                                            <option key={idx} value={c.code}>
                                                                                                {c.code}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                        <AvField
                                                                            name="phone_number"
                                                                            className="form-control"
                                                                            placeholder="Enter Phone number"
                                                                            type="number"
                                                                            value = {this.state.userInfo.phone_number}
                                                                            validate={{
                                                                                required: { value: true },
                                                                                minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                            }}
                                                                        />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                {
                                                                    this.state.country_code_err &&
                                                                    <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


                                                                }

                                                           </Row> */}
                                                        {/* 
                                                            <Row className="my-4">
                                                                <div className="text-danger font-size-18">Company Information</div>
                                                                <hr className="my-4" />
                                                            </Row>

                                                            <Row>
                                                            <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="qualification"
                                                                            label="Qualification"
                                                                            type="text"
                                                                           // required
                                                                            placeholder="Enter Qualification"
                                                                          value = {this.state.userInfo.qualification}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="doj"
                                                                            label="Date of Joining"
                                                                            type="text"
                                                                           // required
                                                                            placeholder="Enter Date of Joining"
                                                                            value = {this.state.userInfo.doj}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                    <label>Designation :</label>
                                                                        <AvField
                                                                            name="designation"
                                                                            // label="Designation"
                                                                            type="text"
                                                                            // required
                                                                            placeholder="Enter Designation"
                                                                            value = {this.state.userInfo.designation}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="experience"
                                                                            label="Experience"
                                                                            type="number"
                                                                           // required
                                                                            placeholder="Enter Experience"
                                                                           value = {this.state.userInfo.experience}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                            <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="usercode"
                                                                            label="User Code"
                                                                            type="text"
                                                                            // required
                                                                            placeholder="Enter User Code"
                                                                            value = {this.state.userInfo.usercode}
                                                                            onBlur={(e)=>{this.validate_user(e)}}
                                                                        />
                                                                        {
                                                                            this.state.invalid_usercode && 
                                                                            <div className="text-danger" style={{ fontSize: 'smaller' }}>User Code already exist for another user</div> 
                                                                        }
                                                                    </div>
                                                                </Col>

                                                            </Row> */}

                                                        <Row className="my-4">
                                                            <div className="text-primary font-size-15">Login Information</div>
                                                            <hr className="my-2 border-secondary opacity-50" />
                                                        </Row>
                                                        <div className="col-8">
                                                            <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <label>Username :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="username"
                                                                            // label="Username"
                                                                            type="text"
                                                                            required
                                                                            placeholder="Enter username"
                                                                            value={this.state.userInfo.username}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                {/* <Col className="col-6">
                                                                    <div className="mb-3">
                                                                    <label>Password :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="password"
                                                                            autoComplete = "new-password"
                                                                            // label="Password"
                                                                            type="password"
                                                                            required
                                                                            placeholder="Enter password"
                                                                            value={this.state.userInfo.password}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </Col> */}
                                                            </Row>
                                                        </div>
                                                        {/* <Row>
                                                               
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="confirmpassword"
                                                                            label="Confirm Password"
                                                                            type="password"
                                                                            required
                                                                            placeholder="Enter Confirm password"
                                                                            value={this.state.userInfo.confirmpassword}
                                                                            disabled={true}

                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row> */}





                                                       
                                                        {
                                                            this.state.alertEnable ?
                                                                <div className="mt-4 d-grid">
                                                                    <Alert color={this.state.alertColor}>
                                                                        {this.state.message}
                                                                    </Alert>
                                                                </div>
                                                                : null
                                                        }

                                                        <div className="mt-4 d-flex justify-content-start">
                                                            <div>
                                                                <button
                                                                    className="btn btn-danger me-2"
                                                                    // type="submit"
                                                                    disabled={this.state.loading || this.state.errorMessage}
                                                                    onClick={(event) => {event.preventDefault();this.props.history.goBack()}}
                                                                >
                                                                    Cancel

                                                                </button>
                                                                <button
                                                                    className="btn btn-secondary"
                                                                    type="submit"
                                                                    disabled={this.state.protect_routes.read_checked === true ? true : false}
                                                                >
                                                                    Update

                                                                </button>
                                                            </div>

                                                        </div>
                                                        

                                                    </AvForm>
                                                </div>
                                            </CardBody> :
                                            <CardBody>
                                                <div className="mt-4 text-center">
                                                    <div className="mb-0" style={{ fontSize: 20 }}>
                                                        <span className="text-danger">{this.state.first_name}</span> has updated successfully
                                                    </div>
                                                    <div style={{ marginTop: 20 }}>
                                                        <Link className="btn btn-success" onClick={() => this.props.history.goBack()}>
                                                            Click here to redirect
                                                        </Link>
                                                    </div>
                                                </div>
                                            </CardBody>
                                    }
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }
        else {
            return null
        }
    }
}



export default EditUser




























// import React, { Component } from "react"
// import { isEmpty } from "lodash"
// import MetaTags from 'react-meta-tags';
// import { AvForm, AvField } from "availity-reactstrap-validation"
// import { Link } from "react-router-dom"

// import { Row, Col, CardBody, Card, Container, Alert, Button } from "reactstrap"
// import facility from '../FacilityScreen'
// import _ from "lodash";
// var urlSocket = require("../../helpers/urlSocket");


// class EditUser extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             dataloaded: false,
//             alertEnable:false,
//             formDisabled:false,
//             userInfo:{},
//             invalid_usercode:false

//         };
//     }
//     async componentDidMount() {
//         var data = JSON.parse(sessionStorage.getItem("selectedUser"));
//         var auth_user = JSON.parse(sessionStorage.getItem("authUser"));
//       var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//       var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
//         console.log(data,'data')
//        var protect_routes = await facility(auth_user.facility_data, 'murs')
//       var user_info ={}
//       user_info["encrypted_db_url"] = db_info.encrypted_db_url
//       user_info["db_name"] = db_info.db_name
//       user_info["email_id"] = data.email_id
//     if(protect_routes !== undefined){

//         this.setState(
//             {
//                 sessionUserInfo: data,
//                 api_data:user_info,
//                 dataloaded: false,
//                 db_info:db_info,
//                 client_info:client_info


//             },
//             function () {
//                 this.getUserInfo()
//             }
//         )
//     }
//     else{
//         this.props.history.push('/dashboard')
//     }
//     }

//     getUserInfo = () => {
//       console.log("edit user info", this.state.api_data)

//       try {
//         urlSocket.post("cog/getUserInfo", 
//         this.state.api_data)
//           .then((response) => {
//             console.log(response,'response')
//             if (response.data.response_code == 500) {
//               this.setState({
//                 userInfo:response.data.user_info[0],
//                 dataloaded: true,
//               },()=>{this.setState({countrycode : this.state.userInfo.countrycode})})
//             }
//           })
//       } catch (error) {
//         console.log("catch error", error)
//       }
//     }


// //  handleValidSubmit = (event, values) => {
// //         try {
// //             var selectedUserInfo = {
// //                 _id:this.state.sessionUserInfo._id,
// //                 company_id: this.state.sessionUserInfo.company_id,
// //             }
// //             urlSocket.post("webUsers/cruduser", {
// //                 selectedUserInfo: selectedUserInfo,
// //                 updateUserInfo: values
// //             })
// //                 .then((response) => {
// //                     if (response.data.response_code == 504) {
// //                         this.setState({ formDisabled: false, alertEnable: true, alertColor: "danger", message: response.data.message })
// //                     }
// //                     else
// //                         if (response.data.response_code == 500) {
// //                             this.setState({
// //                                 first_name: response.data.data.first_name,
// //                                 formDisabled: true,
// //                                 alertEnable: true,
// //                                 alertColor: "success",
// //                                 message: response.data.message
// //                             })
// //                         }
// //                 })
// //         } catch (error) {

// //         }
// //     }

//     handleValidSubmit = (event, values) => {
//         values["_id"]=this.state.userInfo._id
//         values["facilities"]=this.state.userInfo.facilities
//         values["encrypted_db_url"]=this.state.api_data.encrypted_db_url
//         values["db_name"]=this.state.api_data.db_name
//         values["countrycode"]=this.state.countrycode
//         values["confirmpassword"]=this.state.userInfo.confirmpassword
//         values["company_id"] = this.state.userInfo.company_id
//         values["created_by"] = this.state.userInfo.created_by


//         console.log(values,'values',event,this.state.userInfo)
//         if(this.state.invalid_usercode === false){
//              try {
//                  urlSocket.post('cog/cruduser', values).then((res) => {
//                      console.log(res, 'res')
//                    this.props.history.push('/murs')
//                  })
 
//              } catch (error) {
//                  console.log(error, 'error')
//              }
 
//      //    values[] 
//     }
 
//      }

//     validate_user = (e) => {
//         console.log(e.target.value, 'events')
//         var admn_info = {}
//         admn_info["usercode"] = e.target.value
//         admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//         admn_info["db_name"] = this.state.db_info.db_name
//         admn_info["_id"] = this.state.sessionUserInfo._id


//         try {
//             urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((res) => {
//                 console.log(res, 'data')
//                 if (res.data.data.length > 0 && res.data.message === "Email Id already exist for another user") {
//                     this.setState({ invalid_usercode: true })
//                 }
//                 else {
//                     this.setState({ invalid_usercode: false })

//                 }

//             })
//         } catch (error) {
//             console.log(error, 'error')
//         }



//     }






//     // handleValidSubmit = (event, values) => {
//     //     try {
//     //         var selectedUserInfo = {
//     //             _id:this.state.sessionUserInfo._id,
//     //             company_id: this.state.sessionUserInfo.company_id,
//     //         }
//     //         urlSocket.post("webUsers/cruduser", {
//     //             selectedUserInfo: selectedUserInfo,
//     //             updateUserInfo: values
//     //         })
//     //             .then((response) => {
//     //                 if (response.data.response_code == 504) {
//     //                     this.setState({ formDisabled: false, alertEnable: true, alertColor: "danger", message: response.data.message })
//     //                 }
//     //                 else
//     //                     if (response.data.response_code == 500) {
//     //                         this.setState({
//     //                             first_name: response.data.data.first_name,
//     //                             formDisabled: true,
//     //                             alertEnable: true,
//     //                             alertColor: "success",
//     //                             message: response.data.message
//     //                         })
//     //                     }
//     //             })
//     //     } catch (error) {

//     //     }
//     // }

//     select_country=(e)=>{
//         console.log(e.target.value)
//         this.setState({countrycode:e.target.value})
//     }

//     render() {
//         if (this.state.dataloaded) {
//             return (
//                 <React.Fragment>
//                     <div className="page-content">

//                     <MetaTags>
//                         <title>AuditVista | View User Details</title>
//                     </MetaTags>
//                     <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-2">
//                             <div style={{ width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
//                             View User Details
//                             </div>
//                             <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
//                                 <Link to={'/murs'}><Button color="primary" >Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//                             </div>
//                             {/* onClick={()=>this.props.history.goBack()} */}
//                         </div>
                       
//                         <Container fluid>
//                             <Row >
//                                 <Col >
//                                     <Card className="overflow-hidden">
//                                         {
//                                             !this.state.formDisabled ?
//                                                 <CardBody className="pt-0">
//                                                     <div className="p-5">
//                                                         <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


//                                                             <Row className="my-4">
//                                                                 <div className="text-danger font-size-18">User Information</div>
//                                                                 <hr className="my-4" />
//                                                             </Row>
//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3" >
//                                                                     <label>First Name :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="firstname"
//                                                                             // label="First Name"
//                                                                             className="form-control"
//                                                                             placeholder="Enter First Name"
//                                                                             type="text"
//                                                                             value = {this.state.userInfo.firstname}
//                                                                             required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3" >
//                                                                     <label>Last Name :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="lastname"
//                                                                             // label="Last Name"
//                                                                             className="form-control"
//                                                                             placeholder="Enter Last Name"
//                                                                             type="text"
//                                                                             value = {this.state.userInfo.lastname}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>
//                                                             <Row>
//                                                                 <Col className="col-12">
//                                                                     <div className="mb-3" >
//                                                                         <AvField
//                                                                             name="addrss_1"
//                                                                             label="Address"
//                                                                             className="form-control"
//                                                                             placeholder="Enter Address"
//                                                                             type="text"
//                                                                             value = {this.state.userInfo.addrss_1}
//                                                                             //required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>
//                                                             <Row>
//                                                                 <Col className="col-4">
//                                                                     <div className="mb-3" >
//                                                                         <AvField
//                                                                             name="city"
//                                                                             label="City"
//                                                                             className="form-control"
//                                                                             placeholder="Enter City"
//                                                                             type="text"
//                                                                             value = {this.state.userInfo.city}
//                                                                             //required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-4">
//                                                                     <div className="mb-3" >
//                                                                         <AvField
//                                                                             name="state"
//                                                                             label="State"
//                                                                             className="form-control"
//                                                                             placeholder="Enter State"
//                                                                             type="text"
//                                                                             value = {this.state.userInfo.state}
//                                                                            // required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-4">
//                                                                     <div className="mb-3" >
//                                                                         <AvField
//                                                                             name="country"
//                                                                             label="Country"
//                                                                             className="form-control"
//                                                                             placeholder="Enter Country"
//                                                                             type="text"
//                                                                             value = {this.state.userInfo.country}
//                                                                            // required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>

//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3" >
//                                                                     <label>Email Id :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="email_id"
//                                                                             // label="User Email"
//                                                                             className="form-control"
//                                                                             placeholder="Enter User Email"
//                                                                             type="email"
//                                                                             required
//                                                                             value = {this.state.userInfo.email_id}
//                                                                             disabled={true}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col md={6}>
//                                                                     <div className="mb-3">
//                                                                         <label>Phone Number:<span className="text-danger"> *</span></label>
//                                                                         <div className="input-group">
//                                                                             <div className="input-group-prepend">
//                                                                                 <select name="countrycode" onChange={(e)=>{this.select_country(e)}} className="form-select" required>
//                                                                                     <option value={this.state.countrycode}>{this.state.userInfo.countrycode}</option>
//                                                                                     {
//                                                                                         this.state.client_info.countries.map((c,idx)=>(
//                                                                                             <option key={idx} value={c.code}>
//                                                                                                 {c.code}
//                                                                                             </option>
//                                                                                         ))
//                                                                                     }
//                                                                                     {/* <option value="+1">USA (+1)</option>
//                                                                                     <option value="+52">Mexico (+52)</option>
//                                                                                     <option value="+1">Canada (+1)</option> */}
//                                                                                 </select>
//                                                                             </div>
//                                                                             <div className="flex-grow-1">
//                                                                             {/* <label>Phone Number :<span className="text-danger"> *</span></label> */}
//                                                                         <AvField
//                                                                             name="phone_number"
//                                                                             // label="Phone Number"
//                                                                             className="form-control"
//                                                                             placeholder="Enter Phone number"
//                                                                             type="number"
//                                                                             value = {this.state.userInfo.phone_number}
//                                                                             // disabled={true}
//                                                                             validate={{
//                                                                                 required: { value: true },
//                                                                                 minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                                                 maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                                             }}
//                                                                         />
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </Col>
//                                                                 {
//                                                                     this.state.country_code_err &&
//                                                                     <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


//                                                                 }






//                                                                 {/* <Col className="col-6">
//                                                                     <div className="mb-3" >
//                                                                     <label>Phone Number :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="phone_number"
//                                                                             // label="Phone Number"
//                                                                             className="form-control"
//                                                                             placeholder="Enter Phone number"
//                                                                             type="number"
//                                                                             value = {this.state.userInfo.phone_number}
//                                                                             // disabled={true}
//                                                                             validate={{
//                                                                                 required: { value: true },
//                                                                                 minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                                                 maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                                             }}
//                                                                         />
//                                                                     </div>
//                                                                 </Col> */}
//                                                             </Row>

//                                                             <Row className="my-4">
//                                                                 <div className="text-danger font-size-18">Company Information</div>
//                                                                 <hr className="my-4" />
//                                                             </Row>

//                                                             <Row>
//                                                             <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="qualification"
//                                                                             label="Qualification"
//                                                                             type="text"
//                                                                            // required
//                                                                             placeholder="Enter Qualification"
//                                                                           value = {this.state.userInfo.qualification}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="doj"
//                                                                             label="Date of Joining"
//                                                                             type="text"
//                                                                            // required
//                                                                             placeholder="Enter Date of Joining"
//                                                                             value = {this.state.userInfo.doj}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 {/* <Col className="col-4">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="experience"
//                                                                             label="Experience"
//                                                                             type="number"
//                                                                            // required
//                                                                             placeholder="Enter Experience"
//                                                                            value = {this.state.userInfo.experience}
//                                                                         />
//                                                                     </div>
//                                                                 </Col> */}
//                                                             </Row>

//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                     <label>Designation :</label>
//                                                                         <AvField
//                                                                             name="designation"
//                                                                             // label="Designation"
//                                                                             type="text"
//                                                                             // required
//                                                                             placeholder="Enter Designation"
//                                                                             value = {this.state.userInfo.designation}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="experience"
//                                                                             label="Experience"
//                                                                             type="number"
//                                                                            // required
//                                                                             placeholder="Enter Experience"
//                                                                            value = {this.state.userInfo.experience}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>
//                                                             <Row>
//                                                             <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="usercode"
//                                                                             label="User Code"
//                                                                             type="text"
//                                                                             // required
//                                                                             placeholder="Enter User Code"
//                                                                             value = {this.state.userInfo.usercode}
//                                                                             onBlur={(e)=>{this.validate_user(e)}}
//                                                                         />
//                                                                         {
//                                                                             this.state.invalid_usercode && 
//                                                                             <div className="text-danger" style={{ fontSize: 'smaller' }}>User Code already exist for another user</div> 
//                                                                         }
//                                                                     </div>
//                                                                 </Col>

//                                                             </Row>

//                                                             <Row className="my-4">
//                                                                 <div className="text-danger font-size-18">Login Information</div>
//                                                                 <hr className="my-4" />
//                                                             </Row>

//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                     <label>Username :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="username"
//                                                                             // label="Username"
//                                                                             type="text"
//                                                                             required
//                                                                             placeholder="Enter username"
//                                                                             value={this.state.userInfo.username}
//                                                                             disabled={true}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 {/* <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                     <label>Password :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="password"
//                                                                             autoComplete = "new-password"
//                                                                             // label="Password"
//                                                                             type="password"
//                                                                             required
//                                                                             placeholder="Enter password"
//                                                                             value={this.state.userInfo.password}
//                                                                             disabled={true}
//                                                                         />
//                                                                     </div>
//                                                                 </Col> */}
//                                                             </Row>

//                                                             {/* <Row>
                                                               
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="confirmpassword"
//                                                                             label="Confirm Password"
//                                                                             type="password"
//                                                                             required
//                                                                             placeholder="Enter Confirm password"
//                                                                             value={this.state.userInfo.confirmpassword}
//                                                                             disabled={true}

//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row> */}





//                                                             <div className="mb-3" >

//                                                             </div>
//                                                             {
//                                                                 this.state.alertEnable ?
//                                                                     <div className="mt-4 d-grid">
//                                                                         <Alert color={this.state.alertColor}>
//                                                                             {this.state.message}
//                                                                         </Alert>
//                                                                     </div>
//                                                                     : null
//                                                             }


//                                                             <div className="mt-4 d-grid">
//                                                                 <button
//                                                                     className="btn btn-danger btn-block"
//                                                                     type="submit"
//                                                                 >
//                                                                     Update User
//                                                                 </button>
//                                                             </div>

//                                                         </AvForm>
//                                                     </div>
//                                                 </CardBody> :
//                                                 <CardBody>
//                                                     <div className="mt-4 text-center">
//                                                         <div className="mb-0" style={{ fontSize: 20 }}>
//                                                             <span className="text-danger">{this.state.first_name}</span> has updated successfully
//                                                         </div>
//                                                         <div style={{ marginTop: 20 }}>
//                                                             <Link  className="btn btn-success" onClick={()=>this.props.history.goBack()}>
//                                                                 Click here to redirect
//                                                             </Link>
//                                                         </div>
//                                                     </div>
//                                                 </CardBody>
//                                         }
//                                     </Card>

//                                 </Col>
//                             </Row>
//                         </Container>
//                     </div>
//                 </React.Fragment>
//             )
//         }
//         else {
//             return null
//         }
//     }
// }



// export default EditUser
