import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { map, get } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Media,
  Row,
  Table,
} from "reactstrap"

import _ from "lodash";

class ORGMappingInfo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false
    }
  }

  render()
  {
    const entityOrgInfo = _.filter(this.props.project.org_mapping_info, {"department_name":"SALES"})
  return (
    <Card>
      <CardBody>
      <CardTitle className="mb-4">Organisation Mapping Info</CardTitle>
      <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <tbody>
              {map(entityOrgInfo, (member, k) => (
                <tr key={"_member_" + k}>
                  
                  <td>
                    <h5 className="font-size-14 m-0">
                      
                        {member.hierarchy_level} - {member.hierarchy_leveldata}
                    </h5>
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  )
  }
}


export default ORGMappingInfo