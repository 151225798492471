import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Components/ChartsDynamicColor";
import _ from "lodash";

const BarChart = (props) => {
//   const colorArrayString = JSON.stringify(props.dataColors);
//   console.log(props.dataColors, 'props.dataColors',props);
//   const getChartColorsArrayInfo = (colorArrayString) => {
//     const colorArray = JSON.parse(colorArrayString);
//     return colorArray.map(color => getComputedStyle(document.documentElement).getPropertyValue(color?.trim()));
//   };




//   const barChartColors = getChartColorsArrayInfo(colorArrayString);;

//   console.log('barChartColors :>> ', barChartColors);
//   // Extract series and labels from props
console.log(props,'props');
  const categories = _.map(props.irDashBoardData, "name");
  const series = [{
    name: 'Count',
    data: _.map(props.irDashBoardData, "count")
  }];

  // Define a color mapping for each x-axis category
  const colorMapping = {
    "Not Allocated": "#6c757d", // Color for 'Category 1'
    "Not Started": "#95aec4", // Color for 'Category 1'
    "In progress": "#d97e07", // Color for 'Category 2'
    "Completed": "#007bff", // Color for 'Category 3'
    "Closed": "#28a745", // Color for 'Category 3'
    "Rejected": "#dc3545", // Color for 'Category 3'
    // Add more mappings based on your x-axis labels
  };

  // Map colors based on x-axis names
  const barColors = categories.map(category => colorMapping[category] || "#000000"); // Default to black if no match

  const options = {
    chart: {
      height: 320,
      type: 'bar',



      toolbar: {
        show: true, // Show the toolbar
        tools: {
          download: true, // Enable the download button
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: "chart-data",
            columnDelimiter: ',',
            headerCategory: 'Category',
            headerValue: 'Value',
          },
          svg: {
            filename: "chart-image",
          },
          png: {
            filename: "chart-image",
          }
        },
      },
    },
    series: series,
    xaxis: {
      categories: _.map(props.irDashBoardData, "name"),
      title: {
        text: 'Incident Status',
      },
    },
    yaxis: {
      title: {
        text: 'Counts',
      },
    },
    colors: barColors,
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
      itemMargin: {
        horizontal: 10, // Space between legend items horizontally
        vertical: 5,    // Space between legend items vertically
      },
    },
    plotOptions: {
      bar: {
        distributed: true, // Ensures each bar gets its respective color
      },
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          height: 240
        },
        legend: {
          show: false
        },
      }
    }]
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height="320" />
  );
};

export default BarChart;


