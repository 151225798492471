import React, { useState } from 'react'
import { useEffect } from 'react'
import { Multiselect } from 'multiselect-react-dropdown'
import { CardBody, Container, Card, Row, Col, Form, FormFeedback } from 'reactstrap'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector,useDispatch } from 'react-redux'
import { publishIncidentInfo } from '../../../../store/incidentReport/incdreportslice';

const PublishIncidentTypeForm = (props) => {

    const incidentReportSlice = useSelector(state => state.incdreportslice)
    const [authUser,setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [multiSelectErr,setMultiSelectErr] = useState(false)
    const [publishStatus,setpublishStatus] = useState("0")
    const dispatch = useDispatch()


    useEffect(() => {
      

        // _.filter(incidentReportSlice.filteredPublishedIncdType,{_id: incidentReportSlice.editPublishedIncidentTypeList.incd_master_id})
    }, [])


    useEffect(()=>{
        const result = _.filter(
            incidentReportSlice.filteredPublishedIncdType,
            item => _.includes(incidentReportSlice.editPublishedIncidentTypeList.incd_master_id, item._id)
          );
        console.log("useEffect",result, incidentReportSlice)

    },[incidentReportSlice.filteredPublishedIncdType])


    const handleHierarchyChange = (event) => {
        console.log(event.target.value, 'handleHierarchyChange')
        validation.handleChange(event);

    }



    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            incd_pubsh_h_id: Object.keys(incidentReportSlice.editPublishedIncidentTypeList).length ===0 ? incidentReportSlice.escalatedHList && incidentReportSlice.escalatedHList.length > 0 && incidentReportSlice.escalatedHList.length < 2 ? incidentReportSlice.escalatedHList[0]._id : '':incidentReportSlice.editPublishedIncidentTypeList.incd_pubsh_h_id ,
            incd_desgn_id :Object.keys(incidentReportSlice.editPublishedIncidentTypeList).length ===0 ?'' :incidentReportSlice.editPublishedIncidentTypeList.incd_resp_desgntion[0].cat_type ,
            incd_master_id :Object.keys(incidentReportSlice.editPublishedIncidentTypeList).length === 0 ? [] : incidentReportSlice.editPublishedIncidentTypeList.incd_master_id
        },
        validationSchema: Yup.object({
            incd_pubsh_h_id: Yup.string().required("Please Choose the Hierarchy"),
            incd_desgn_id :Yup.string().required("Please Choose the Designation"),
            incd_master_id :Yup.array()
            .min(1, 'At least one incident type is required')
            .required('Incident types are required'),
        }),
        onSubmit: async (values) => {
            console.log(incidentReportSlice.designationList,'incidentReportSlice.designationList');
            values["incd_resp_desgntion"]= _.filter(incidentReportSlice.designationList,{cat_type : values.incd_desgn_id})
            values["incd_pubsh_by"]= authUser.user_data._id
            values["publish_status"]=publishStatus
            if(Object.keys(incidentReportSlice.editPublishedIncidentTypeList).length>0){
                values["_id"]= incidentReportSlice.editPublishedIncidentTypeList._id
            }
            console.log(values, 'values')
            dispatch(publishIncidentInfo(values))
            props.onClose()


        }
    })

    return (
        <React.Fragment>
            <Container>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <div className="p-2 mb-3">
                                    <Row className="my-4">
                                        <div className="d-sm-flex align-items-center justify-content-between">
                                            <div className="text-primary font-size-18">
                                                {/* Publish Incident Type  */}
                                                Configure Incident Type
                                                  </div>
                                            <button className="btn btn-outline-dark " onClick={() => props.onClose()}> Close </button>
                                        </div>
                                        <hr className="my-4" />
                                    </Row>
                                </div>
                                <hr />
                                <Form
                                    className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                      {
                                            console.log(incidentReportSlice.filteredPublishedIncdType)
                                        }
                                    {
                                        incidentReportSlice.escalatedHList.length > 1
                                        &&
                                        < Row className='my-3'>
                                            <Col>
                                                <h6>Select Hierarchy :<span className='text-danger'>*</span></h6>
                                                <select
                                                    name="incd_pubsh_h_id"
                                                    defaultValue={"Select"}
                                                    onChange={handleHierarchyChange}
                                                    className='form-control'
                                                >
                                                    <option disabled value={"Select"}>
                                                        Select
                                                    </option>
                                                    {
                                                        incidentReportSlice.escalatedHList.map((ele, pos) => {
                                                            return (
                                                                <option key={pos} value={ele._id}>{ele.hierarchy_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </Col>
                                        </Row>
                                    }
                                    {validation.touched.incd_pubsh_h_id && validation.errors.incd_pubsh_h_id ? (
                                        <FormFeedback type="invalid">{validation.errors.incd_pubsh_h_id}</FormFeedback>
                                    ) : null}
                                    <Row>
                                        <Col>
                                      
                                        <h6>Choose Incident Type :<span className='text-danger'>*</span></h6>
                                            <Multiselect
                                                onRemove={(selectedList, selectedItem) => {
                                                    validation.setFieldValue('incd_master_id', _.map(selectedList,"_id"));
                                                    // setMultiSelectErr(false)
                                                }
                                                }
                                                onSelect={(selectedList, selectedItem) => {
                                                    validation.setFieldValue('incd_master_id', _.map(selectedList,"_id"));
                                                    setMultiSelectErr(false)
                                                    console.log(validation,'validation',selectedList,_.map(selectedList,"_id"))
                                                    // onSelectValues(selectedList, selectedItem);
                                                }}
                                                options={incidentReportSlice.filteredPublishedIncdType}
                                                displayValue="incd_type_name"
                                                closeOnSelect={false}
                                                selectedValues={_.filter(
                                                    incidentReportSlice.filteredPublishedIncdType,
                                                    item => _.includes(incidentReportSlice.editPublishedIncidentTypeList.incd_master_id, item._id)
                                                  )}
                                                
                                            />
                                          
                                        </Col>
                                        {
                                            multiSelectErr && 
                                            // <FormFeedback type="invalid">
                                                <div className='text-danger' style={{fontSize:"smaller"}}>{"At least one incident type is required"}</div>
                                                // </FormFeedback>

                                        }
                                        {/* {validation.touched.incd_master_id && validation.errors.incd_master_id ? (
                                                <FormFeedback type="invalid">{validation.errors.incd_master_id}</FormFeedback>
                                            ) : null} */}
                                    </Row>

                                    <Row className='my-3'>
                                            <Col>
                                                <h6>Select Designation :<span className='text-danger'>*</span></h6>
                                                <select
                                                    name="incd_desgn_id"
                                                    defaultValue={ Object.keys(incidentReportSlice.editPublishedIncidentTypeList).length >0 ?
                                                         incidentReportSlice.editPublishedIncidentTypeList?.incd_resp_desgntion[0]?.cat_type 
                                                        // {}
                                                         : "Select"}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    className={`form-control ${validation.touched.incd_desgn_id && validation.errors.incd_desgn_id ? 'is-invalid' : ''}`}
                                                >
                                                    <option disabled value={"Select"}>
                                                        Select
                                                    </option>
                                                    {
                                                        incidentReportSlice.designationList.map((ele, pos) => {
                                                            return (
                                                                <option key={pos} value={ele.cat_type}>{ele.hirerachy_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {validation.touched.incd_desgn_id && validation.errors.incd_desgn_id ? (
                                                <FormFeedback type="invalid">{validation.errors.incd_desgn_id}</FormFeedback>
                                            ) : null}

                                            </Col>
                                        </Row>

                                    <Row>
                                        <Col md={12} className='text-end'>
                                            {/* <button onClick={()=>{validation.values.incd_master_id.length === 0 ? setMultiSelectErr(true):setMultiSelectErr(false)  }} className='btn btn-success'>Submit</button>
                                             */}
                                            <button onClick={()=>{validation.values.incd_master_id.length === 0 ? setMultiSelectErr(true):setMultiSelectErr(false);setpublishStatus("0")  }} className='btn btn-primary me-2'>Save</button>

                                            <button onClick={()=>{validation.values.incd_master_id.length === 0 ? setMultiSelectErr(true):setMultiSelectErr(false);setpublishStatus("1")  }} className='btn btn-success'>Save & Publish</button>

                                        </Col>

                                    </Row>
                                </Form>


                            </Col>


                        </Row>

                    </CardBody>

                </Card>

            </Container>
        </React.Fragment>
    )
}


export default PublishIncidentTypeForm
