import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation } from 'react-router-dom';

import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import {
    Row, Col, FormGroup, Card, CardBody, Container, Alert, Label, Form, Progress,
    FormFeedback, Button, Spinner,
    Badge,

} from "reactstrap";
import { Input, Space, Image } from 'antd';//Select,
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { retriveIncidentsInfo, submitIncidentInfo, setIncidentsInfo , retriveIncidentSeverityList} from '../../../../store/incidentReport/incdreportslice'
import { Tree } from 'antd'
import { FileOutlined,ExclamationCircleOutlined, DeleteTwoTone, UserOutlined,FolderOutlined,UserAddOutlined } from '@ant-design/icons';
import Swal from "sweetalert2";
import {  incAddNodeUser } from '../../../../store/incidentReport/htree'
import { Link, useHistory } from 'react-router-dom';




const IncidentInfo = (props) => {
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [treeNodes, setTreeNodes] = useState([])
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [taskUsers, settaskUsers] = useState([]);
    const [enableReason, setenableReason] = useState(false)
    const [rejectReason, setRejectReason] = useState("")
    const [selectedSeverityId, setselectedSeverityId] = useState("")
    const [rejectErr, setrejectErr] = useState(false)
    const [adminInfo, setadminInfo] = useState({})
    const [usererr, setusererr] = useState(false)
    const [charCount, setCharCount] = useState(0);

    const history = useHistory()

    const dispatch = useDispatch()
    const incidentReport = useSelector(state => state.incdreportslice)

    const incidentsInfo = incidentReport.incidentsInfo


    useEffect(() => {
        var incidentInfo = JSON.parse(sessionStorage.getItem("incidentInfo"))
        console.log(incidentInfo,'incidentInfo')
        setadminInfo(authUser.config_data.action_plan_roles[0])
        dispatch(retriveIncidentsInfo(incidentInfo))
        settaskUsers(incidentInfo.task_users)
        dispatch(retriveIncidentSeverityList(incidentInfo))
    }, [])

    useEffect(() => {
        const keys = getAllKeys(treeNodes);
        setExpandedKeys(keys);
    }, [treeNodes]);


    const handleExpand = (newExpandedKeys, { node }) => {
        let updatedExpandedKeys = [...newExpandedKeys];

        // If the node is being collapsed, remove its users' keys
        if (!newExpandedKeys.includes(node.key)) {
            if (node.unique_users && node.unique_users.length > 0) {
                const userKeys = node.unique_users.map(user => `${node.key}-${user.user_id}`);
                updatedExpandedKeys = updatedExpandedKeys.filter(key => !userKeys.includes(key));
            }
        } else {
            // If the node is being expanded, add its users' keys
            if (node.unique_users && node.unique_users.length > 0) {
                const userKeys = node.unique_users.map(user => `${node.key}-${user.user_id}`);
                updatedExpandedKeys = updatedExpandedKeys.concat(userKeys);
            }
        }

        setExpandedKeys(updatedExpandedKeys);
    };






    const getAllKeys = (nodes) => {
        let keys = [];
        nodes.forEach((node) => {
            keys.push(node.key);
            if (node.children) {
                keys = keys.concat(getAllKeys(node.children));
            }
        });
        return keys;
    };


    useEffect(() => {
        var treeNodes = buildTreeNodes(incidentReport.hTreeData)
        console.log(treeNodes, 'treeNodes', incidentReport.hTreeData)
        setTreeNodes(treeNodes)

    }, [incidentReport.hTreeData])

    // const addUser = (userInfo,node_id) => {
    //     settaskUsers(prevSelectedUsers => [
    //         ...prevSelectedUsers,
    //         {
    //             ...userInfo,
    //             user_category_name: userInfo.hirerachy_name,
    //             user_category_type: userInfo.cat_type,
    //             user_status: "0",
    //             id: adminInfo.id,
    //             role_description: adminInfo.role_description,
    //             facilities: adminInfo.facilities,
    //             role_name : adminInfo.role_name,
    //             node_id : node_id
    //         }
    //     ]);
    // };




    const addUser = (userInfo, node_id) => {
        setusererr(false);
        console.log('Task 161:>> ', taskUsers, incidentsInfo.task_users, userInfo);


        // Remove user from incidentsInfo.task_users if already present
        const updatedTaskUsers = incidentsInfo.task_users.filter(user => user.user_id !== userInfo.user_id);

        settaskUsers(prevSelectedUsers => {
            // Step 1: Filter out the existing user with the same user_id
            const filteredUsers = prevSelectedUsers?.filter(user => user.user_id !== userInfo.user_id);

            // Step 2: Prepare the new array
            const newTaskUsers = [
                ...filteredUsers,
                {
                    ...userInfo,
                    user_category_name: userInfo.hirerachy_name,
                    user_category_type: userInfo.cat_type,
                    user_status: "0",
                    id: adminInfo.id,
                    role_description: adminInfo.role_description,
                    facilities: adminInfo.facilities,
                    role_name: adminInfo.role_name,
                    node_id: node_id
                }
            ];

            // Log the new array
            console.log('newTaskUsers :>> ', newTaskUsers);

            // Step 3: Return the new array
            return newTaskUsers;
        });

        console.log('updatedTaskUsers :>> ', updatedTaskUsers);


    };




    const removeUser = (userId,node_id) => {
        console.log('userId :>> ',node_id, userId);
        settaskUsers(prevSelectedUsers =>
            prevSelectedUsers.filter(user => user._id !== userId)
        );
    };


    // const buildTreeNodes = (nodes) => {
    //     return nodes.map((node) => {
    //         const {
    //             value, label, children, ep_level, code, ep_level_value,
    //             id, node_id, parent, user_path, unique_users,
    //             user_permission_acpln
    //         } = node;
    //         // console.log(node,'node_id',node_id)
    //         const hasChildren = (children && children.length > 0);

    //         const userNodes = unique_users.map(user => ({
    //             key: `${value}-${user.user_id}`,
    //             label: user.name,
    //             isLeaf: true, // Users don't have children
    //             title: (
    //                 <div style={{
    //                     display: 'flex',
    //                     alignItems: 'center',
    //                     padding: '0 4px',
    //                     margin: '4px 0',
    //                     background: 'transparent',
    //                     cursor: 'pointer',
    //                 }}> 
    //                 {/* <input type='checkbox' onClick={()=>{addUser(user)}} /> */}

    //                     <input
    //                         type='checkbox'
    //                         onClick={(e) => {
    //                             if (e.target.checked) {
    //                                 addUser(user,node_id);
    //                             } else {
    //                                 removeUser(user._id);
    //                             }
    //                         }}
    //                     />

    //                 {/* <Checkbox onClick={()=>{addUser(user)}} /> */}
    //                     <UserOutlined
    //                         className='mx-2'
    //                         style={{
    //                             cursor: 'pointer',
    //                             color: '#556EE6',
    //                             opacity: 1,

    //                         }}
    //                     />

    //                     <span className='me-1'>{user.name}</span>
    //                     ( <span>{user.hirerachy_name}</span>)
    //                     -<span>{user.email_id}</span>
    //                 </div>
    //             )
    //         }));

    //         return {
    //             key: value,
    //             label: label,
    //             user_path: user_path,
    //             value,
    //             ep_level,
    //             ep_level_value,
    //             id,
    //             node_id,
    //             parent,
    //             children: [...userNodes, ...(hasChildren ? buildTreeNodes(children) : [])],
    //             user_permission_acpln: user_permission_acpln,
    //             code: code,
    //             title: (
    //                 <div style={{
    //                     background: 'transparent',
    //                     position: 'relative',
    //                     zIndex: 'auto',
    //                     minHeight: '24px',
    //                     margin: '0',
    //                     padding: '0 4px',
    //                     color: 'inherit',
    //                     lineHeight: '24px',
    //                     borderRadius: '6px',
    //                     cursor: 'pointer',
    //                     transition: 'all 0.2s, border 0s, line-height 0s, box-shadow 0s'
    //                 }}>
    //                     <div style={{ display: 'flex', alignItems: 'center' }}>
    //                         <FileOutlined
    //                             style={{
    //                                 cursor: 'pointer',
    //                                 color: '#556EE6',
    //                                 opacity: 1,
    //                             }}
    //                         />
    //                         <span style={{ marginLeft: '4px', opacity: 1, fontWeight: 600 }}>
    //                             {label}
    //                         </span>
    //                     </div>
    //                 </div>
    //             ),
    //         };
    //     });
    // };


    const buildTreeNodes = (nodes) => {
        return nodes?.map((node) => {
            const {
                value, label, children, ep_level, code, ep_level_value,
                id, node_id, parent, user_path, unique_users,
                user_permission_acpln
            } = node;

            const hasChildren = (children && children.length > 0);

            // Define user nodes
            const userNodes = unique_users?.map(user => {
                const checkboxId = `${value}-${user.user_id}`;
                return {
                    key: checkboxId,
                    label: user.name,
                    isLeaf: true, // Users don't have children
                    title: (
                        <div style={{
                            padding: '0 4px',
                            margin: '4px 0',
                            background: 'transparent',
                            cursor: 'pointer',
                        }}>
                            <input
                                type='checkbox'
                                id={checkboxId}
                                defaultChecked={incidentsInfo.task_users?.some(item => item.node_id === node_id && user.email_id === item.email_id)}
                                onClick={(e) => {
                                    if (e.target.checked) {
                                        addUser(user, node_id);
                                    } else {
                                        removeUser(user._id, node_id);
                                    }
                                }}
                            />
                            <label htmlFor={checkboxId} style={{ cursor: 'pointer' }}>
                                <UserOutlined
                                    className='mx-2'
                                    style={{
                                        cursor: 'pointer',
                                        color: '#556EE6',
                                        opacity: 1,
                                    }}
                                />
                                <span className='me-1'>{user.name}</span>
                                (<span>{user.hirerachy_name}</span>)
                                -<span>{user.email_id}</span>
                            </label>
                        </div>
                    )
                };
            });

            return {
                key: value,
                label: label,
                user_path: user_path,
                value,
                ep_level,
                ep_level_value,
                id,
                node_id,
                parent,
                children: [...userNodes, ...(hasChildren ? buildTreeNodes(children) : [])],
                user_permission_acpln: user_permission_acpln,
                code: code,
                title: (
                    <div style={{
                        background: 'transparent',
                        position: 'relative',
                        zIndex: 'auto',
                        minHeight: '24px',
                        margin: '0',
                        padding: '0 4px',
                        color: 'inherit',
                        lineHeight: '24px',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        transition: 'all 0.2s, border 0s, line-height 0s, box-shadow 0s'
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {hasChildren ? (
                                <FolderOutlined
                                    style={{
                                        cursor: 'pointer',
                                        color: '#556EE6',
                                        opacity: 1,
                                    }}
                                />
                            ) : (
                                <FileOutlined
                                    style={{
                                        cursor: 'pointer',
                                        color: '#556EE6',
                                        opacity: 1,
                                    }}
                                />
                            )}
                            <span style={{ marginLeft: '4px', opacity: 1, fontWeight: 600 }}>
                                {label}
                            </span>
                            <UserAddOutlined
                                onClick={() => {
                                    console.log('treeNodes 387:>> ', treeNodes, node, "incidentReport.hTreeData", incidentReport.hTreeData, node_id);
                                    sessionStorage.setItem("hInfo", JSON.stringify(incidentReport.hTreeData[0]));
                                    sessionStorage.setItem("nodeInfo", JSON.stringify(node));

                                    incAddNodeUser(incidentReport.hTreeData, node, 2, history, null, incidentReport.path)
                                }}
                                style={{
                                    marginLeft: '8px',
                                    cursor: 'pointer',
                                    color: '#556EE6',
                                }}
                            />
                        </div>
                    </div>
                ),
            };
        });
    };




    // const buildTreeNodes = (nodes) => {
    //     return nodes?.map((node) => {
    //         const {
    //             value, label, children, ep_level, code, ep_level_value,
    //             id, node_id, parent, user_path, unique_users,
    //             user_permission_acpln
    //         } = node;
            
    //         const hasChildren = (children && children.length > 0);
    
    //         // Define user nodes
    //         const userNodes = unique_users?.map(user => ({
    //             key: `${value}-${user.user_id}`,
    //             label: user.name,
    //             isLeaf: true, // Users don't have children
    //             title: (
    //                 <div style={{
    //                     display: 'flex',
    //                     alignItems: 'center',
    //                     padding: '0 4px',
    //                     margin: '4px 0',
    //                     background: 'transparent',
    //                     cursor: 'pointer',
    //                 }}>
    //                     <input
    //                         type='checkbox'
    //                         defaultChecked={incidentsInfo.task_users?.some(item => item.node_id === node_id && user.email_id === item.email_id)}
    //                         onClick={(e) => {
    //                             if (e.target.checked) {
    //                                 addUser(user, node_id);
    //                             } else {
    //                                 removeUser(user._id,node_id);
    //                             }
    //                         }}
    //                     />
    //                     <UserOutlined
    //                         className='mx-2'
    //                         style={{
    //                             cursor: 'pointer',
    //                             color: '#556EE6',
    //                             opacity: 1,
    //                         }}
    //                     />
    //                     <span className='me-1'>{user.name}</span>
    //                     ( <span>{user.hirerachy_name}</span>)
    //                     -<span>{user.email_id}</span>
    //                 </div>
    //             )
    //         }));
    

    

    //         return {
    //             key: value,
    //             label: label,
    //             user_path: user_path,
    //             value,
    //             ep_level,
    //             ep_level_value,
    //             id,
    //             node_id,
    //             parent,
    //             children: [...userNodes, ...(hasChildren ? buildTreeNodes(children) : [])],
    //             user_permission_acpln: user_permission_acpln,
    //             code: code,
    //             title: (
    //                 <div style={{
    //                     background: 'transparent',
    //                     position: 'relative',
    //                     zIndex: 'auto',
    //                     minHeight: '24px',
    //                     margin: '0',
    //                     padding: '0 4px',
    //                     color: 'inherit',
    //                     lineHeight: '24px',
    //                     borderRadius: '6px',
    //                     cursor: 'pointer',
    //                     transition: 'all 0.2s, border 0s, line-height 0s, box-shadow 0s'
    //                 }}>
    //                     <div style={{ display: 'flex', alignItems: 'center' }}>
    //                         {hasChildren ? (
    //                             <FolderOutlined
    //                                 style={{
    //                                     cursor: 'pointer',
    //                                     color: '#556EE6',
    //                                     opacity: 1,
    //                                 }}
    //                             />
    //                         ) : (
    //                             <FileOutlined
    //                                 style={{
    //                                     cursor: 'pointer',
    //                                     color: '#556EE6',
    //                                     opacity: 1,
    //                                 }}
    //                             />
    //                         )}
    //                         <span style={{ marginLeft: '4px', opacity: 1, fontWeight: 600 }}>
    //                             {label} 
    //                         </span>
    //                         {/* <Dropdown overlay={menu} trigger={['click']}> */}
    //                         <UserAddOutlined

    //                             onClick={() => {
    //                                 console.log('treeNodes  387:>> ', treeNodes, node, "incidentReport.hTreeData", incidentReport.hTreeData , node_id);
    //                                 sessionStorage.setItem("hInfo", JSON.stringify(incidentReport.hTreeData[0]));
    //                                 sessionStorage.setItem("nodeInfo", JSON.stringify(node));

    //                                 incAddNodeUser(incidentReport.hTreeData, node, 2, history, null, incidentReport.path)
    //                             }}
    //                             style={{
    //                                 marginLeft: '8px',
    //                                 cursor: 'pointer',
    //                                 color: '#556EE6',
    //                             }}
    //                         />
    //                     {/* </Dropdown> */}
    //                     </div>
    //                 </div>
    //             ),
    //         };
    //     });
    // };


    // const buildTreeNodes = (nodes) => {
    //     return nodes?.map((node) => {
    //         const {
    //             value, label, children, ep_level, code, ep_level_value,
    //             id, node_id, parent, user_path, unique_users,
    //             user_permission_acpln
    //         } = node;
    //         // console.log(node, 'node_id', node_id)
    //         const hasChildren = (children && children.length > 0);

    //         const userNodes = unique_users?.map(user => ({
    //             key: `${value}-${user.user_id}`,
    //             label: user.name,
    //             isLeaf: true, // Users don't have children
    //             title: (
    //                 <div style={{
    //                     display: 'flex',
    //                     alignItems: 'center',
    //                     padding: '0 4px',
    //                     margin: '4px 0',
    //                     background: 'transparent',
    //                     cursor: 'pointer',
    //                 }}>
    //                     <input
    //                         type='checkbox'

    //                         // checked={true}

    //                         defaultChecked={incidentsInfo.task_users?.some(item => item.node_id === node_id && user.email_id === item.email_id)}
    //                         onClick={(e) => {
    //                             if (e.target.checked) {
    //                                 addUser(user, node_id);
    //                             } else {
    //                                 removeUser(user._id);
    //                             }
    //                         }}
    //                     />

    //                     {/* <Checkbox onClick={()=>{addUser(user)}} /> */}
    //                     <UserOutlined
    //                         className='mx-2'
    //                         style={{
    //                             cursor: 'pointer',
    //                             color: '#556EE6',
    //                             opacity: 1,

    //                         }}
    //                     />

    //                     <span className='me-1'>{user.name}</span>
    //                     ( <span>{user.hirerachy_name}</span>)
    //                     -<span>{user.email_id}</span>
    //                 </div>
    //             )
    //         }));

    //         return {
    //             key: value,
    //             label: label,
    //             user_path: user_path,
    //             value,
    //             ep_level,
    //             ep_level_value,
    //             id,
    //             node_id,
    //             parent,
    //             children: [...userNodes, ...(hasChildren ? buildTreeNodes(children) : [])],
    //             user_permission_acpln: user_permission_acpln,
    //             code: code,
    //             title: (
    //                 <div style={{
    //                     background: 'transparent',
    //                     position: 'relative',
    //                     zIndex: 'auto',
    //                     minHeight: '24px',
    //                     margin: '0',
    //                     padding: '0 4px',
    //                     color: 'inherit',
    //                     lineHeight: '24px',
    //                     borderRadius: '6px',
    //                     cursor: 'pointer',
    //                     transition: 'all 0.2s, border 0s, line-height 0s, box-shadow 0s'
    //                 }}>
    //                     <div style={{ display: 'flex', alignItems: 'center' }}>
    //                         <FileOutlined
    //                             style={{
    //                                 cursor: 'pointer',
    //                                 color: '#556EE6',
    //                                 opacity: 1,
    //                             }}
    //                         />
    //                         <span style={{ marginLeft: '4px', opacity: 1, fontWeight: 600 }}>
    //                             {label}
    //                         </span>
    //                     </div>
    //                 </div>
    //             ),
    //         };
    //     });
    // };





    const gotoBack = () => {
        sessionStorage.removeItem("incidentInfo")
        dispatch(setIncidentsInfo({}))
        props.history.push('/incident_list')
    }


    const onCheck = (checked, event) => {
        console.log(checked, 'event', event)
        if (event.checked === true) {
            setCheckedKeys([event.node.key])
        }
        else {
            setCheckedKeys([])
        }

    }


    const incidentStatus = (status) => {
        if (status === "2") {
            if (String(rejectReason).length > 0) {
                Swal.fire({
                    icon: 'error', // You can use 'warning', 'error', or any other appropriate icon
                    title: 'Reject Confirmation',
                    text: 'Are you sure you want to reject this incident?', // Customize the confirmation message
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Reject',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        var updatedIncidentInfo = _.cloneDeep(incidentReport.incidentsInfo)
                        updatedIncidentInfo["incd_status"] = status
                        updatedIncidentInfo["incd_rejec_reason"] = rejectReason
                        updatedIncidentInfo["incd_rejec_by"] = {
                            "email_id": authUser.user_data.email_id,
                            "user_id": authUser.user_data._id,
                            "user_name": authUser.user_data.firstname,
                            "rejected_on": new Date()
                        }
                        // updatedIncidentInfo["incd_rejec_user_name"]=authUser.user_data.full_name                      
                        console.log(updatedIncidentInfo, 'updatedIncidentInfo')
                        dispatch(submitIncidentInfo(updatedIncidentInfo))
                        dispatch(setIncidentsInfo(updatedIncidentInfo))
                        gotoBack()

                    }
                })
            }
            else {
                setrejectErr(true)
            }
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: 'Confirmation',
                text: 'Are you sure you want to approve this incident?',
                showCancelButton: true,
                confirmButtonColor: 'rgb(42 159 0)',
                cancelButtonColor: '#F46A6A',
                confirmButtonText: 'Approve',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    var updatedIncidentInfo = _.cloneDeep(incidentReport.incidentsInfo)
                    updatedIncidentInfo["incd_status"] = status
                    updatedIncidentInfo["incd_approved_by"] = {
                        "email_id": authUser.user_data.email_id,
                        "user_id": authUser.user_data._id,
                        "user_name": authUser.user_data.firstname,
                        "approved_on": new Date()
                    }
                    console.log(updatedIncidentInfo, 'updatedIncidentInfo')
                    dispatch(submitIncidentInfo(updatedIncidentInfo))
                    dispatch(setIncidentsInfo(updatedIncidentInfo))
                }
            })
        }

    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            severity_id: incidentsInfo.task_priority?.severity_id !== '' ? incidentsInfo.task_priority?.severity_id : '',
            allot_guideline: ''
        },
        validationSchema: Yup.object({
            severity_id: Yup.string().required("Please Choose the Severity type"),
        }),
        onSubmit: (values) => {
            if (taskUsers.length > 0) {
                setusererr(false)
                var updatedIncidentInfo = _.cloneDeep(incidentReport.incidentsInfo)
                var severity_info = _.find(incidentReport.severityTypeList, { severity_id: values.severity_id })
                updatedIncidentInfo["task_priority"] = {
                    severity_descrption: severity_info.severity_descrption,
                    severity_id: severity_info.severity_id,
                    severity_name: severity_info.severity_name,
                }

                var submissonUserInfo = _.find(updatedIncidentInfo.incd_resp_person, { user_id: authUser.user_data._id })

                const filteredTaskUsers = taskUsers.filter(user => user.user_id !== submissonUserInfo.user_id);
                console.log('filteredTaskUsers :>> ',taskUsers, filteredTaskUsers);

                if (filteredTaskUsers) {
                    updatedIncidentInfo["task_users"] = [...filteredTaskUsers, {
                        email_id: submissonUserInfo.user_email_id,
                        user_category_type: submissonUserInfo.user_cat_type,
                        user_category_name: submissonUserInfo.user_designation,
                        name: submissonUserInfo.user_name,
                        user_id: submissonUserInfo.user_id,
                        _id: submissonUserInfo.user_id,
                        user_status: "0",
                        id: adminInfo.id,
                        role_description: adminInfo.role_description,
                        facilities: adminInfo.facilities,
                        role_name: adminInfo.role_name,
                        allocated_person: true
                    }];
                }

                updatedIncidentInfo["incd_submitted_status"] = "1"
                updatedIncidentInfo["task_status"] = updatedIncidentInfo.task_status === null ? "0" : updatedIncidentInfo.task_status
                updatedIncidentInfo["task_completed_perc"] = 0
                updatedIncidentInfo["task_assigned_name"] = authUser.user_data.firstname

                console.log(updatedIncidentInfo,'updatedIncidentInfo')

                Swal.fire({
                    icon: 'warning',
                    title: 'Confirmation',
                    text: 'Are you sure you want to submit this incident?',
                    showCancelButton: true,
                    confirmButtonColor: 'rgb(42 159 0)',
                    cancelButtonColor: '#F46A6A',
                    confirmButtonText: 'Submit',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(submitIncidentInfo(updatedIncidentInfo))
                        dispatch(setIncidentsInfo(updatedIncidentInfo))
                        gotoBack()
                    }
                })

                console.log(updatedIncidentInfo, 'values')
            }
            else {
                console.log("NO USERS FOUNF");
                setusererr(true)
            }
        }
    });



    return (
        <React.Fragment>
            {console.log('taskUsers :>> ', taskUsers,incidentsInfo)}
            <div className="page-content">
                <Container fluid>
                    {/* <Breadcrumbs title="Incidents" breadcrumbItem="Incidents" /> */}
                    <Breadcrumbs
                        title="Manage Incidents"
                        breadcrumbItem="Manage Incidents"
                        isBackButtonEnable={true}
                        gotoBack={() => gotoBack()}
                    />
                    {
                        Object.keys(incidentsInfo).length > 0 ?
                            <Card style={{ height: '90vh' ,  overflow: 'auto' }} >
                                <CardBody className="pt-0" >
                                    <hr />
                                    <Row className='w-100 ' style={{ backgroundColor: '' }}>
                                        <Col style={{ borderRight: '1px solid black' }}>
                                            <div >
                                                <section className="incident-details">
                                                    <Row>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Incident Type</div>
                                                            <div className="font-size-15 text-dark">{incidentsInfo.incd_type?.incd_type_name}</div>
                                                        </Col>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Location</div>
                                                            <div className="font-size-15 text-dark">{incidentsInfo.incd_h_location.node_name}</div>
                                                        </Col>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Status</div>
                                                            <div className="font-size-15 text-dark">
                                                                {(() => {
                                                                    let statusLabel = "";
                                                                    let badgeVariant = "";


                                                                    if(incidentsInfo.incd_rejec_by){
                                                                            statusLabel = "Rejected";
                                                                            badgeVariant = "danger"; // Red
                                                                    }  
                                                                    else if (incidentsInfo.task_users.length < 1) {
                                                                        statusLabel = "Not Allocated";
                                                                        badgeVariant = "secondary"; // Light Grey
                                                                    } 



                                                                    switch (incidentsInfo.task_status) {
                                                                        case "0":
                                                                            statusLabel = "Not Started";
                                                                            badgeVariant = "secondary"; // Grey
                                                                            break;
                                                                        case "1":
                                                                            statusLabel = "In Progress";
                                                                            badgeVariant = "warning"; // Blue
                                                                            break;
                                                                        case "2":
                                                                            statusLabel = "Completed";
                                                                            badgeVariant = "primary"; // Green
                                                                            break;
                                                                        case "3":
                                                                            statusLabel = "Closed";
                                                                            badgeVariant = "success"; // Dark Grey/Black
                                                                            break;
                                                                        case "4":
                                                                            statusLabel = "Overdue";
                                                                            badgeVariant = "danger"; // Red
                                                                            break;
                                                                        case "5":
                                                                            statusLabel = "Reopen";
                                                                            badgeVariant = "warning"; // Yellow/Orange
                                                                            break;
                                                                      
                                                                    }

                                                                    return (
                                                                        <Badge className={"badge-soft-" + badgeVariant}>
                                                                            {statusLabel}
                                                                        </Badge>
                                                                    );
                                                                })()}
                                                                </div>
                                                            {/* <div className="font-size-15 text-dark">{incidentsInfo.incd_status === "0" ? "Not Allocated" : incidentsInfo.incd_status === "1" ? "Rejected" : incidentsInfo.incd_status === "2" ? "Approved" : null}</div> */}
                                                        </Col>
                                                        {/* <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Incident ID</div>
                                                            <div className="font-size-15 text-dark">{incidentsInfo.incd_type.incd_type_id}</div>
                                                        </Col> */}
                                                    </Row>

                                                    <Row>
                                                        <Col className="col-12 mt-3 mb-1" >
                                                            <div className="text-secondary font-size-14" > Observation </div>
                                                            <p className="text-dark font-size-15 " style={{ lineHeight: 1.5 }} >{incidentsInfo.action}</p>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        incidentsInfo.attachments.length > 0 &&
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <div className="text-secondary font-size-14" > Attachments </div>
                                                                    <div className="my-2 d-flex flex-row gap-2">




                                                                    {incidentsInfo.attachments.map((ele, idx) => {
                                                                            const fileExtension = ele.originalname.split('.').pop().toLowerCase();
                                                                            const isVideo = fileExtension === 'mp4' || fileExtension === 'avi' || fileExtension === 'mov'; // Add other video formats as needed

                                                                            return isVideo ? (
                                                                                <video
                                                                                    key={idx}
                                                                                    height="100px"
                                                                                    controls
                                                                                    style={{ borderRadius: '10px' }}
                                                                                    className="border border-secondary border-opacity-50 m-0"
                                                                                >
                                                                                    <source src={authUser.client_info[0].base_url + ele.originalname} type={`video/${fileExtension}`} />
                                                                                    Your browser does not support the video tag.
                                                                                </video>
                                                                            ) : (
                                                                                <Image
                                                                                    key={idx}
                                                                                    src={authUser.client_info[0].base_url + ele.originalname}
                                                                                    alt="caption"
                                                                                    height="80px"
                                                                                    style={{ borderRadius: '10px' }}
                                                                                    className="border border-secondary border-opacity-50 m-0"
                                                                                />
                                                                            );
                                                                        })}


                                                                        
                                                                        {/* {
                                                                            incidentsInfo.attachments.map((ele, idx) => {
                                                                                return (
                                                                                    <Image
                                                                                        key={idx}
                                                                                        src={authUser.client_info[0].base_url + ele.originalname}
                                                                                        alt="caption"
                                                                                        height="80px"
                                                                                        style={{ borderRadius: '10px' }}
                                                                                        className="border border-secondary border-opacity-50 m-0"
                                                                                    />
                                                                                )

                                                                            })
                                                                        } */}

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                        </>

                                                    }

                                                    <Row>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Submitted By</div>
                                                            <div className="font-size-15 text-dark">{incidentsInfo.incd_annyms_status ? "Anonymous User" :incidentsInfo.incd_submitted_by.user_name}</div>
                                                        </Col>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Submitted On</div>
                                                            <div className="font-size-15 text-dark">   {new Intl.DateTimeFormat("en-GB", {
                                                                month: "short",
                                                                day: "2-digit",
                                                                year: "numeric",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit",  // Optional: include this if you want seconds
                                                                hour12: true
                                                            }).format(new Date(incidentsInfo.createdAt))}</div>
                                                        </Col>
                                                        {
                                                            incidentsInfo.task_status === "3" &&
                                                            <>
                                                                {/* <Col className="col-auto me-2" >
                                                                    <div className="font-size-14 text-secondary">Issue Status</div>
                                                                    <Badge className={"font-size-12 badge-soft-success"}>Closed</Badge>
                                                                </Col> */}
                                                                <Col className="col-auto me-2" >
                                                                    <div className="font-size-14 text-secondary">Email Status</div>
                                                                    <Badge className={"font-size-12 badge-soft-info"}>Email sent to the Incident raised user</Badge>
                                                                    
                                                                    {/* <div className="font-size-15 text-success">Email sent to the Incident raised user</div> */}
                                                                </Col>
                                                                <Col className="col-auto me-2" >
                                                                    <div className="font-size-14 text-secondary">Email Sent On:</div>
                                                                    <div className="font-size-15 text-dark">   {new Intl.DateTimeFormat("en-GB", {
                                                                        month: "short",
                                                                        day: "2-digit",
                                                                        year: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        second: "2-digit",  // Optional: include this if you want seconds
                                                                        hour12: true
                                                                    }).format(new Date(incidentsInfo.incd_submitted_by.email_sent_on))}</div>
                                                                </Col>
                                                                </>
                                                        }
                                                      
                                                    </Row>
                                                    <br />
                                                    {
                                                        incidentsInfo.incd_status === "2" ?
                                                            <>
                                                                <Row>
                                                                    <Col className="col-auto me-2" >
                                                                        <div className="font-size-14 text-secondary">Rejected Reason :</div>
                                                                        <div className="font-size-15 text-dark">{incidentsInfo.incd_rejec_reason}</div>
                                                                    {/* <Badge className={"font-size-12 badge-soft-danger"}>{incidentsInfo.incd_rejec_reason}</Badge> */}

                                                                    </Col>
                                                                    <Col className="col-auto me-2" >
                                                                        <div className="font-size-14 text-secondary">Rejected by : </div>
                                                                        <div className="font-size-15 text-dark">   {incidentsInfo.incd_rejec_by.user_name}({incidentsInfo.incd_rejec_by.email_id})</div>
                                                                    </Col>
                                                                </Row>
                                                                <br />
                                                                <Row>
                                                                    <Col className="col-auto me-2" >
                                                                        <div className="font-size-14 text-secondary">Rejected On :</div>
                                                                        <div className="font-size-15 text-dark">{new Intl.DateTimeFormat("en-GB", {
                                                                            month: "short",
                                                                            day: "2-digit",
                                                                            year: "numeric",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            second: "2-digit",  // Optional: include this if you want seconds
                                                                            hour12: true
                                                                        }).format(new Date(incidentsInfo.incd_rejec_by.rejected_on))}</div>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            incidentsInfo.incd_status === "1" ?
                                                                <>
                                                                    <Row>
                                                                        <Col className="col-auto me-2" >
                                                                            <div className="font-size-14 text-secondary">Approved By : </div>
                                                                            <div className="font-size-15 text-dark">   {incidentsInfo.incd_approved_by.user_name}({incidentsInfo.incd_approved_by.email_id})</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row>
                                                                        <Col className="col-auto me-2" >
                                                                            <div className="font-size-14 text-secondary">Approved On :</div>
                                                                            <div className="font-size-15 text-dark">{new Intl.DateTimeFormat("en-GB", {
                                                                                month: "short",
                                                                                day: "2-digit",
                                                                                year: "numeric",
                                                                                hour: "2-digit",
                                                                                minute: "2-digit",
                                                                                second: "2-digit",  // Optional: include this if you want seconds
                                                                                hour12: true
                                                                            }).format(new Date(incidentsInfo.incd_approved_by.approved_on))}</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <br/>
                                                                    {/* <Row>
                                                                        <Col className="col-auto me-2" >
                                                                            <div className="font-size-14 text-secondary">Approved On :</div>
                                                                            <div className="font-size-15 text-dark">{new Intl.DateTimeFormat("en-GB", {
                                                                                month: "short",
                                                                                day: "2-digit",
                                                                                year: "numeric",
                                                                                hour: "2-digit",
                                                                                minute: "2-digit",
                                                                                second: "2-digit",  // Optional: include this if you want seconds
                                                                                hour12: true
                                                                            }).format(new Date(incidentsInfo.incd_approved_by.approved_on))}</div>
                                                                        </Col>
                                                                    </Row> */}

                                                                </>
                                                                :
                                                                null

                                                    }
                                                    {/* {
                                                            incidentsInfo.task_status === "6" && incidentsInfo.incd_submitted_status === "2" &&
                                                        <Row>
                                                            <Col className="col-auto me-2" >
                                                                <div className="font-size-14 text-secondary">Rejected by : </div>
                                                                <div className="font-size-15 text-dark">   {incidentsInfo.task_user_reject_info[0].user_name}({incidentsInfo.task_user_reject_info[0].email_id})</div>
                                                            </Col>
                                                                <Col className="col-auto me-2">
                                                                    <div className="font-size-13 text-secondary mb-1">Rejected Reason</div>
                                                                    <div className="font-size-14 text-dark" >{incidentsInfo.task_user_reject_info[0].reason}</div>
                                                                </Col>
                                                                <Col className="col-auto me-2">
                                                                    <div className="font-size-13 text-secondary mb-1">Rejected On</div>
                                                                    <div className="font-size-14 text-dark" >{new Intl.DateTimeFormat("en-GB", {
                                                                        month: "short",
                                                                        day: "2-digit",
                                                                        year: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        second: "2-digit",  // Optional: include this if you want seconds
                                                                        hour12: true
                                                                    }).format(new Date(incidentsInfo.task_user_reject_info[0].rejected_on))}</div>
                                                                </Col>
                                                        </Row>
                                                        //  <br />





                                                    } */}
                                                    {
                                                        incidentsInfo.task_status === "6" && incidentsInfo.incd_submitted_status === "2" &&
                                                        incidentsInfo.task_user_reject_info.map((rejectInfo, index) => (
                                                            <Row key={index}>
                                                                <Col className="col-auto me-2">
                                                                    <div className="font-size-14 text-secondary">Rejected by:</div>
                                                                    <div className="font-size-15 text-dark">
                                                                        {rejectInfo.user_name} ({rejectInfo.email_id})
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-auto me-2">
                                                                    <div className="font-size-13 text-secondary mb-1">Rejected Reason</div>
                                                                    <Badge className={"font-size-12 badge-soft-danger"}>{rejectInfo.reason}</Badge>
                                                                   
                                                                    {/* <div className="font-size-14 text-dark"><span className='text-danger'>{rejectInfo.reason}</span></div> */}
                                                                </Col>
                                                                <Col className="col-auto me-2">
                                                                    <div className="font-size-13 text-secondary mb-1">Rejected On</div>
                                                                    <div className="font-size-14 text-dark">
                                                                        {new Intl.DateTimeFormat("en-GB", {
                                                                            month: "short",
                                                                            day: "2-digit",
                                                                            year: "numeric",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            second: "2-digit",
                                                                            hour12: true
                                                                        }).format(new Date(rejectInfo.rejected_on))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        ))
                                                    }



                                                    {
                                                        incidentsInfo.incd_status === "0" &&
                                                        <div className='my-3'>
                                                            {
                                                                !enableReason &&
                                                                <>
                                                                    <Button className='btn btn-md btn-success' onClick={() => { incidentStatus("1") }} >Approve</Button>&nbsp;

                                                                    <Button className='btn btn-md btn-danger' onClick={() => {
                                                                        // incidentStatus("2")
                                                                        setenableReason(true)
                                                                    }} >Reject this incident</Button>
                                                                </>
                                                            }

                                                        </div>
                                                    }
                                                    {
                                                        enableReason &&
                                                        <Row>
                                                            <Col md={6}>
                                                                <label>Rejection Reason:<span className='text-danger'>*</span></label>
                                                                <textarea
                                                                    className='w-100'
                                                                    required
                                                                    onChange={(e) => {
                                                                        if(e.target.value.length <=200){
                                                                        setRejectReason(e.target.value); setrejectErr(false)
                                                                        setCharCount(e.target.value.length);
                                                                        }
                                                                    }}
                                                                    maxLength={200}
                                                                />
                                                                    <div className="text-end">
                                                                        <small>{charCount} / 200 characters</small>
                                                                    </div>
                                                                {
                                                                    rejectErr &&
                                                                    <div className='text-danger' style={{ fontSize: "smaller" }}>
                                                                        Please Enter the Rejection Reason
                                                                    </div>
                                                                }
                                                                <div className='my-3'>
                                                                    <Button type='submit' className='btn btn-md btn-success' onClick={() => { incidentStatus("2") }}>Submit</Button>&nbsp;
                                                                    <Button className='btn btn-md btn-danger' onClick={() => { setenableReason(false); setrejectErr(false) }}>Cancel</Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }

                                                </section>
                                            </div>
                                        </Col>

                                        {
                                            incidentsInfo.incd_status === "1" &&incidentsInfo.task_status !== "3" &&

                                            <Col>
                                                <section className="incident-actions">
                                                    <Form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}>
                                                        <div className='mb-3'>
                                                            {/* <div className="font-size-14 text-secondary my-2">Severity<span className='text-danger'>*</span></div>
                                                            <div>
                                                                <select
                                                                    id="severity_id"
                                                                    name="severity_id"
                                                                    // className='form-control'
                                                                    className={`form-control ${validation.touched.severity_id && validation.errors.severity_id ? 'is-invalid' : ''}`}
                                                                    defaultValue={incidentsInfo.task_priority?.severity_id || "Select"}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={(e) => { validation.handleChange(e); setselectedSeverityId(e.target.value) }}>
                                                                    <option value={"Select"}>
                                                                        Select
                                                                    </option>
                                                                    {
                                                                        incidentReport.severityTypeList.map((ele, idx) => {
                                                                            return (
                                                                                <option key={idx} value={ele.severity_id}>{ele.severity_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select> */}



                                                                <div className="font-size-14 text-secondary my-2">Severity<span className='text-danger'>*</span></div>
                                                                <div>
                                                                    <select
                                                                        id="severity_id"
                                                                        name="severity_id"
                                                                        disabled={incidentsInfo.incd_submitted_status === '1' ? true : false}
                                                                        // className='form-control'
                                                                        className={`form-control ${validation.touched.severity_id && validation.errors.severity_id ? 'is-invalid' : ''}`}
                                                                        defaultValue={incidentsInfo.task_priority?.severity_id || "Select"}
                                                                        onBlur={validation.handleBlur}
                                                                        onChange={(e) => { validation.handleChange(e); setselectedSeverityId(e.target.value) }}>
                                                                        <option value={"Select"}>
                                                                            Select
                                                                        </option>
                                                                        {
                                                                            console.log(' incidentReport.incdseverityTypeList :>> ', incidentReport.incdseverityTypeList)
                                                                        }
                                                                        {
                                                                            incidentReport.incdseverityTypeList.map((ele, idx) => {
                                                                                console.log("ele", ele.severity.severity_id);
                                                                                return (
                                                                                    <option key={idx} value={ele.severity.severity_id}>{ele.severity.severity_name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>





                                                                {validation.touched.severity_id && validation.errors.severity_id ? (
                                                                    <FormFeedback type="invalid">{validation.errors.severity_id}</FormFeedback>
                                                                ) : null}


                                                            </div>
                                                        </div>
                                                        <div className='mb-3'>
                                                            <div className="font-size-14 text-secondary my-2">Allocated To<span className='text-danger'>*</span><span className='font-size-12 text-danger'> {usererr ? "( Please choose the users )" : ""}</span>{
                                                                usererr &&(
                                                                    <ExclamationCircleOutlined style={{ color: 'red' }} />
                                                                )
                                                            }</div>
                                                            <div style={{ maxHeight: "600px", height: '100%', overflow: "auto" }}>
                                                                <Tree
                                                                 style={{ borderColor: '#150101', marginBottom: '10px', color: usererr ? '#ff6666' : '', border: usererr && '1px dashed #ff9999', borderRadius: '8px' }}
                                                                    // style={{ borderColor: '#150101' }}
                                                                    defaultExpandAll={true}
                                                                    // checkable
                                                                    treeData={treeNodes}
                                                                    checkedKeys={checkedKeys}
                                                                    onCheck={(checked, event) => { onCheck(checked, event) }}
                                                                    showLine={true}
                                                                    showIcon={true}
                                                                    checkStrictly={true}
                                                                    expandedKeys={expandedKeys}
                                                                    onExpand={handleExpand}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className='mb-3'>
                                                            <div className="font-size-14 text-secondary my-2">Action</div>
                                                            <div>
                                                                <textarea
                                                                    className='w-100'
                                                                    id="allot_guideline"
                                                                    name="allot_guideline"
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                // value={action}
                                                                // onChange={handleActionChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            console.log(taskUsers,'taskUsers',checkedKeys)
                                                        }
                                                        <Button type='submit' className='btn btn-success me-2' onClick={()=>{
                                                            taskUsers.length === 0 ? setusererr(true) : setusererr(false)
                                                        }} >Submit</Button>
                                                        <Button className=' btn btn-secondary' onClick={gotoBack}     >Cancel</Button>
                                                    </Form>
                                                </section>
                                                {/* {
                                                    usererr &&
                                                    <div className='text-danger' style={{ fontSize: "small" }}>
                                                        Please Select the Task Users
                                                    </div>
                                                } */}
                                            </Col>
                                        }

                                    </Row>
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <CardBody style={{ height: "100vh" }}>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div>Loading...</div>
                                        <Spinner className="ms-2" color="primary" />
                                    </div>
                                </CardBody>
                            </Card>
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default IncidentInfo
