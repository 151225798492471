import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    Container, Row, Col, Card, CardBody, CardTitle, Table, Button, Badge,
    Progress, Pagination, PaginationItem, PaginationLink,
    Modal, ModalHeader, ModalBody
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { LoadingOutlined } from '@ant-design/icons';
import { Multiselect } from 'multiselect-react-dropdown';
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
class CrudUserHlvl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        console.log('crud use lvl')
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle}  >
                    <ModalHeader toggle={this.props.toggle} tag="h4">
                        User Info
                    </ModalHeader>
                    <ModalBody>
                        <AvForm className="form-horizontal" onValidSubmit={this.props.onValidSubmit}>
                            <div className="mb-3">
                                <label>Full Name :<span className="text-danger"> *</span></label>
                                <AvField
                                    name="firstname"
                                    // value={this.state.user_view_data !== undefined ? this.state.user_view_data.firstname : ''}
                                    // disabled={true}
                                    errorMessage="Please enter your name"
                                    className="form-control"
                                    placeholder="Enter First Name"
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label>Email Id :<span className="text-danger"> *</span></label>
                                <AvField
                                    name="email_id"
                                    // value={this.state.user_view_data !== undefined ? this.state.user_view_data.email_id : ''}
                                    // disabled={true}
                                    onChange={(e) => { this.props.email_format(e); this.props.validate_email(e, 1) }}
                                    errorMessage="Please enter your Email ID"
                                    className="form-control"
                                    placeholder="Enter User Email"
                                    type="email"
                                    required
                                />
                                {
                                    this.props.valid_email &&
                                    <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>

                                }
                                {
                                    this.props.email_err &&
                                    <div className='text-danger' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
                                }
                            </div>
                            <Row>
                                <Col className="col-12">
                                    <Row>
                                        <div className="mb-3">
                                            <label>Phone Number:<span className="text-danger"> *</span></label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <select name="countrycode" onChange={(e) => { this.props.select_country(e) }} className="form-select" required>
                                                        <option value="">Select</option>
                                                        {
                                                            this.props.countries.map((c, idx) => (
                                                                <option key={idx} value={c.code}>
                                                                    {c.code}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <AvField
                                                        name="phone_number"
                                                        className="form-control"
                                                        placeholder="Enter Phone number"
                                                        type="number"
                                                        validate={{
                                                            required: { value: true },
                                                            minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                            maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            {
                                this.props.country_code_err &&
                                <div className='text-danger' style={{fontSize :'smaller'}}>Please select the country code</div>
                            }
                            {/* </div> */}
                            <div className='mb-3'>
                                <label>Category Type :<span className="text-danger"> *</span></label>
                                <Multiselect
                                    options={this.props.hirerachy_user_level}
                                    onSelect={(selectedList, selectedItem) => {
                                        this.props.category_select(selectedList, selectedItem);
                                    }}
                                    // name="category"
                                    displayValue="hirerachy_name"
                                    onRemove={(selectedList, selectedItem) => { this.props.onRemove(selectedItem,selectedList) }}
                                    closeOnSelect={false}
                                />

                            </div>

                            <div className="mb-3">
                                <label>Username :<span className="text-danger"> *</span></label>
                                <AvField
                                    name="username"
                                    type="text"
                                    required
                                    placeholder="Enter username"
                                    disabled={true}
                                    value={this.props.given_email}
                                />
                            </div>
                            <Row>
                                <Col md={11}>
                                    <div className="mb-3">
                                        <label>Password :<span className="text-danger"> *</span></label>
                                        <AvField
                                            name="password"
                                            type={this.props.show_password ? "password" : "text"}
                                            autoComplete="new-password"
                                            required
                                            placeholder="Enter password"
                                            // disabled={true}
                                            validate={{
                                                required: { value: true, errorMessage: 'Password is required' }, minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' }, pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={1} style={{ marginTop: '27px',marginLeft:"-24px" }}>
                                    <button onClick={() => this.props.passWordBtn()} className="btn btn-light " type="button" id="password-addon" >
                                        <i className={this.props.show_password ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"} ></i>
                                    </button>
                                </Col>
                            </Row>
                            <div className="mt-4 d-grid">
                                <button
                                    className="btn btn-danger btn-block"
                                    type="submit"
                                    disabled={this.props.loading}
                                >
                                    {this.props.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Add User"}
                                </button>
                            </div>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

CrudUserHlvl.propTypes = propTypes;
CrudUserHlvl.defaultProps = defaultProps;
// #endregion

export default CrudUserHlvl;