import React, { useEffect, useState } from 'react'
import { retriveMyTaskList } from '../../../store/incidentReport/incdreportslice'
import { useDispatch } from 'react-redux'
import MetaTags from "react-meta-tags";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Container,
    Spinner,
    Progress,
    Badge,
    Toast,
    ToastBody,
    ToastHeader,
    Button
} from "reactstrap";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useSelector } from 'react-redux';
import FollowComponent from '../MyTask/Components/FollowUpComponent'
import { Empty } from "antd";
import Conversation from './Components/Conversation';
import TaskUsers from './Components/TaskUser'
import chaticon from "../../../assets/images/icons/chat.png"
import { setSelectedApln, setValidUser, handleGlobalIncidentFilter,submitIncidentInfo,setenableReason,setMyTaskList,setcloseStatus } from '../../../store/incidentReport/incdreportslice'
import socket,{emitIncidentReport} from '../../../helpers/socket';
import moment from 'moment'
import Swal from 'sweetalert2';


const AcceptReject = () => {

    const dispatch = useDispatch()
    const incidentReportSlice = useSelector(state => state.incdreportslice)
    const enableReason = incidentReportSlice.enableReason
    const selectedActionplan = incidentReportSlice.selectedApln
    // const [enableReason, setenableReason] = useState(false)
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [rejectReason, setRejectReason] = useState("")
    const [rejectErr, setrejectErr] = useState(false)

    const taskUserRespStatus = (status) => {
        if (status === "2") {
            if (String(rejectReason).length > 0) {
                Swal.fire({
                    icon: 'error', // You can use 'warning', 'error', or any other appropriate icon
                    title: 'Reject Confirmation',
                    text: 'Are you sure you want to reject this incident?', // Customize the confirmation message
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Reject',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        var updatedIncidentInfo = _.cloneDeep(incidentReportSlice.selectedApln)
                        updatedIncidentInfo["task_users_resp_status"] = status
                        updatedIncidentInfo["incd_submitted_status"]=status
                        updatedIncidentInfo["task_status"]="6"
                        
                        
                        updatedIncidentInfo["task_user_reject_info"] = [{
                            "email_id": authUser.user_data.email_id,
                            "user_id": authUser.user_data._id,
                            "user_name": authUser.user_data.firstname,
                            "rejected_on": new Date(),
                            "reason": rejectReason
                        }]                
                        console.log(updatedIncidentInfo, 'updatedIncidentInfo')
                        dispatch(submitIncidentInfo(updatedIncidentInfo))
                        dispatch(setSelectedApln(updatedIncidentInfo))
                    }
                })
            }
            else {
                setrejectErr(true)
            }
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: 'Accept Confirmation',
                text: 'Are you sure you want to Accept this incident?',
                showCancelButton: true,
                confirmButtonColor: 'rgb(42 159 0)',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Accept',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    var updatedIncidentInfo = _.cloneDeep(incidentReportSlice.selectedApln)
                    updatedIncidentInfo["task_users_resp_status"] = status
                    updatedIncidentInfo["task_users_resp_accept_by"] = {
                        "email_id": authUser.user_data.email_id,
                        "user_id": authUser.user_data._id,
                        "user_name": authUser.user_data.firstname,
                        "approved_on": new Date()
                    }
                    console.log(updatedIncidentInfo, 'updatedIncidentInfo')
                    dispatch(submitIncidentInfo(updatedIncidentInfo))
                    dispatch(setSelectedApln(updatedIncidentInfo))
                      const value = `${authUser.user_data.firstname} Accepted the task `
                    emitIncidentReport(updatedIncidentInfo,authUser,value,"import_user")
                }
            })
        }

    }

    return (
        <Row className="m-0">
            <div className="d-flex flex-column">
                <div className="">
                    <div className="">
                        <Row>
                            <Row style={{ maxWidth: "90%" }}>
                                <Col className="col-12 mb-3" >
                                    <div className="text-secondary font-size-3" > Incident Task </div>
                                    <p className="text-dark font-size-13 " style={{ lineHeight: 1.5 }} >{selectedActionplan?.action}</p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col className="col-auto me-2 mb-2" >
                                    <div className="font-size-13 text-secondary mb-1">Target Date</div>
                                    <div className="font-size-14 text-dark">{selectedActionplan.task_target_date === null ? (
                                        <label className="text-secondary">{"-- / -- / --"}</label>
                                    ) : (
                                        <label className="text-dark">
                                            {moment(selectedActionplan.task_target_date).format("DD-MMM-YYYY")}
                                        </label>
                                    )}</div>
                                </Col>
                                <Col className="col-auto me-2" >
                                    <div className="font-size-13 text-secondary mb-1">Priority</div>
                                    <div>
                                        {/* <span
                                            className={`
                                            badge badge-soft-${selectedActionplan.task_priority?.severity_name === "No impact" ? "secondary" :
                                                    selectedActionplan.task_priority?.severity_name === "Low" ? "success" :
                                                        selectedActionplan.task_priority?.severity_name === "Medium" ? "warning" :
                                                            selectedActionplan.task_priority?.severity_name === "High" ? "danger" :
                                                                selectedActionplan.task_priority?.severity_name === "Critical" && "danger"
                                                } font-size-14`}>{selectedActionplan.task_priority?.severity_name}</span> */}
                                                <span className='badge badge-soft-danger font-size-14'>{selectedActionplan.task_priority?.severity_name}</span>
                                                </div>

                                </Col>

                                <Col className="col-auto me-2" >
                                    <div className="font-size-13 text-secondary mb-1">Completion</div>
                                    <div><span
                                        className={`badge badge-soft-${Number(selectedActionplan.task_completion_perc) === 0 ? "secondary" :
                                            Number(selectedActionplan.task_completion_perc) >= 10 && Number(selectedActionplan.task_completion_perc) <= 90 ? "warning" : Number(selectedActionplan.task_completion_perc) > 90 && "primary"} font-size-14`}>{selectedActionplan.task_completion_perc}%</span></div>
                                </Col>
                                <Col className="col-auto me-2" >
                                    <div className="font-size-13 text-secondary mb-1">Assigned by</div>
                                    <div className="font-size-14 text-dark" >{selectedActionplan.task_assigned_name}
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <div className='my-3'>
                                        {
                                            !enableReason &&
                                          

                                                <button className='btn btn-danger' onClick={() => {
                                                    dispatch(setenableReason(true))
                                                }}>Reject</button>
                                        }


                                        {
                                            enableReason &&
                                            <Row>
                                                <Col md={6}>
                                                    <label>Rejection Reason:<span className='text-danger'>*</span></label>
                                                    <textarea
                                                        className='w-100'
                                                        required
                                                        onChange={(e) => { setRejectReason(e.target.value); setrejectErr(false) }}
                                                    />
                                                    {
                                                        rejectErr &&
                                                        <div className='text-danger' style={{ fontSize: "smaller" }}>
                                                            Please Enter the Rejection Reason
                                                        </div>
                                                    }
                                                    <div className='my-3'>
                                                        <Button type='submit' className='btn btn-md btn-success' onClick={() => { taskUserRespStatus("2") }}>Submit</Button>
                                                        <Button className='btn btn-md btn-danger' onClick={() => { dispatch(setenableReason(false)); setrejectErr(false) }}>Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </Col>

                            </Row>
                        </Row>
                    </div>
                </div>
            </div>
        </Row>
    )
}

const RejectedInfo = () => {
    const incidentReportSlice = useSelector(state => state.incdreportslice)
    const selectedActionplan = incidentReportSlice.selectedApln

    return (
        <Row className="m-0">
            <div className="d-flex flex-column">
                <div className="">
                    <div className="">
                        <Row>
                            <Row style={{ maxWidth: "90%" }}>
                                <Col className="col-12 mb-3" >
                                    <div className="text-secondary font-size-3" > Incident Task </div>
                                    <p className="text-dark font-size-13 " style={{ lineHeight: 1.5 }} >{selectedActionplan?.action}</p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col className="col-auto me-2 mb-2" >
                                    <div className="font-size-13 text-secondary mb-1">Target Date</div>
                                    <div className="font-size-14 text-dark">{selectedActionplan.task_target_date === null ? (
                                        <label className="text-secondary">{"-- / -- / --"}</label>
                                    ) : (
                                        <label className="text-dark">
                                            {moment(selectedActionplan.task_target_date).format("DD-MMM-YYYY")}
                                        </label>
                                    )}</div>
                                </Col>
                                <Col className="col-auto me-2" >
                                    <div className="font-size-13 text-secondary mb-1">Priority</div>
                                    <div>
                                        {/* <span
                                            className={`
                                            badge badge-soft-${selectedActionplan.task_priority?.severity_name === "No impact" ? "secondary" :
                                                    selectedActionplan.task_priority?.severity_name === "Low" ? "success" :
                                                        selectedActionplan.task_priority?.severity_name === "Medium" ? "warning" :
                                                            selectedActionplan.task_priority?.severity_name === "High" ? "danger" :
                                                                selectedActionplan.task_priority?.severity_name === "Critical" && "danger"
                                                } font-size-14`}>{selectedActionplan.task_priority?.severity_name}</span> */}

                                            {/* <span className='badge badge-soft-danger font-size-14'>{selectedActionplan.task_priority?.severity_name}</span> */}

                                            <span className='badge badge-soft-danger font-size-14'>{selectedActionplan.task_priority?.severity_name}</span>
                                            {/* <span className="badge badge-soft-danger font-size-12">{selectedActionplan.task_priority?.severity_name}</span>                                                 */}
                                                </div>

                                </Col>

                                <Col className="col-auto me-2" >
                                    <div className="font-size-13 text-secondary mb-1">Completion</div>
                                    <div><span
                                        className={`badge badge-soft-${Number(selectedActionplan.task_completion_perc) === 0 ? "secondary" :
                                            Number(selectedActionplan.task_completion_perc) >= 10 && Number(selectedActionplan.task_completion_perc) <= 90 ? "warning" : Number(selectedActionplan.task_completion_perc) > 90 && "primary"} font-size-14`}>{selectedActionplan.task_completion_perc}%</span></div>
                                </Col>
                                <Col className="col-auto me-2" >
                                    <div className="font-size-13 text-secondary mb-1">Assigned by</div>
                                    <div className="font-size-14 text-dark" >{selectedActionplan.task_assigned_name}
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col className="col-auto me-2">
                                    <div className="font-size-13 text-secondary mb-1">Rejected by</div>
                                    <div className="font-size-14 text-dark" >{selectedActionplan.task_user_reject_info[0].user_name}</div>
                                </Col>
                                <Col className="col-auto me-2">
                                    <div className="font-size-13 text-secondary mb-1">Rejected Reason</div>
                                    <div className="font-size-14 text-dark" >{selectedActionplan.task_user_reject_info[0].reason}</div>
                                </Col>
                                <Col className="col-auto me-2">
                                    <div className="font-size-13 text-secondary mb-1">Rejected On</div>
                                    <div className="font-size-14 text-dark" >{new Intl.DateTimeFormat("en-GB", {
                                        month: "short",
                                        day: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",  // Optional: include this if you want seconds
                                        hour12: true
                                    }).format(new Date(selectedActionplan.task_user_reject_info[0].rejected_on))}</div>
                                </Col>

                            </Row>
                        </Row>
                    </div>
                </div>
            </div>
        </Row>
    )


}




const MyTask = () => {

    const dispatch = useDispatch()
    const [selectedIdx, setSelectedIdx] = useState('')
    const incidentReportSlice = useSelector(state => state.incdreportslice)
    const [showUserPanel, setShowUserPanel] = useState(false)
    const [showTaskEditor, setShowTaskEditor] = useState(false)
    const [connectedRoomUsers, setConnectedRoomUsers] = useState(null)
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [currentUser, setCurrentUser] = useState(null)
    console.log(incidentReportSlice, 'incidentReportSlice')
    const [selectedStatus,setSelectedStatus] = useState("All")

    const [filterOptions,setFilterOptions]=useState([
        {id:"" , name:"All",color :"btn btn-sm btn-dark" },
        // {id : "null" , name :"Not Allocated"},
        {id : "0" , name :"Not Started", color :"btn btn-sm btn-secondary"},
        {id : "1" , name :"In Progress" ,color :"btn btn-sm btn-warning" },
        {id : "2" , name :"Completed" ,color :"btn btn-sm btn-primary"},
        {id : "3" , name :"Closed" , color :"btn btn-sm btn-success"},
        // {id : "4" , name :"Rejected"},
    ])


    useEffect(() => {
        if (socket) {
            socket.on("update_users_in_ir_room", (data) => {
                console.log(data, 'data')
                setConnectedRoomUsers(data)
            });

        }

    }, [socket])


    useEffect(() => {
        // dispatch(retriveMyTaskList())
        // console.log(JSON.parse(sessionStorage.getItem("task_info")), 'task_info');
        // var info = JSON.parse(sessionStorage.getItem("task_info"))
        //     if (info) {
        //         var myTaskList = _.cloneDeep(incidentReportSlice.myTaskList)
        //         console.log(myTaskList,'myTaskList');
        //         var getIdx = _.findIndex(myTaskList, { _id: info._id })
        //         console.log(getIdx, 'getIdx');
        //         if (getIdx !== -1) {
        //             loadSelectedCheckpoint(getIdx, info)
        //         }
        //     }

        dispatch(retriveMyTaskList()).then((data) => {
            console.log(data,'data');
            const info = JSON.parse(sessionStorage.getItem("task_info"));
            if (info) {
                const myTaskList = incidentReportSlice.myTaskList.length === 0 ? _.cloneDeep(data.data.data) : _.cloneDeep(incidentReportSlice.myTaskList);
                console.log(myTaskList, 'myTaskList');
                const getIdx = _.findIndex(myTaskList, { _id: info._id });
                console.log(getIdx, 'getIdx');
                if (getIdx !== -1) {
                    loadSelectedCheckpoint(getIdx, info);
                }
            }
        }).catch(error => {
            console.error('Failed to retrieve task list:', error);
        });


      
    }, [])


    // useEffect(()=>{
    //     var info = JSON.parse(sessionStorage.getItem("task_info"))
    //     if (info) {
    //         var myTaskList = _.cloneDeep(incidentReportSlice.myTaskList)
    //         var getIdx = _.findIndex(myTaskList, { _id: info._id })
    //         console.log(getIdx,'getIdx');
    //         if (getIdx !== -1) {
    //             loadSelectedCheckpoint(getIdx, info)
    //         }
    //    }


    // },[incidentReportSlice.myTaskList])


    const loadSelectedCheckpoint = (idx, item2) => {
        var item2 = _.cloneDeep(item2)
        var myTaskList=  _.cloneDeep(incidentReportSlice.myTaskList)
        const validUser = item2.task_users.filter(item => item.user_id === userData.user_data._id)
        console.log(validUser, 'validUser', item2)
        dispatch(setValidUser(validUser))
        setSelectedIdx(idx)
        setShowTaskEditor(true)
        var userInfo = _.filter(item2.task_users, { "user_id": userData.user_data._id })[0]
        setCurrentUser(userInfo)
        const closeInfo= _.filter(item2.task_users,{allocated_person : true , user_id : userData.user_data._id})
        dispatch(setcloseStatus(closeInfo))
        // item2["unreadCount"]=0
        myTaskList[idx]["unreadCount"]=0
        dispatch(setSelectedApln(item2))
        dispatch(setMyTaskList(myTaskList))
        dispatch(setenableReason(false))

    }

    const handleSelectChange=async(value)=>{
        var page_query= {
            "task_users":{$elemMatch:{user_id:userData.user_data._id}}
        }
        var data = await dispatch(handleGlobalIncidentFilter(value,page_query))
        console.log(data,'data');
        dispatch(setMyTaskList(data))
    }   


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>AuditVista | Incident Task</title>
                </MetaTags>

                <Container fluid>

                    <Breadcrumbs
                        title={"Incident Task"}
                        breadcrumbItem="Incident Task"
                    />
                    <Row className="m-0 mt-1 ">
                        <Col lg={4}>

                        {
                filterOptions.map((ele,idx)=>{
                    return(
                    <button onClick={()=>{handleSelectChange(ele.id);setSelectedStatus(ele.name)}} key={idx} className={ele.color + ' me-1'}>{ele.name}</button>
                    )
                })
            }



                            {/* <select className="form-control select2 mb-3 mb-xxl-0" onChange={(e) => {
                                handleSelectChange(e.target.value)
                            }}
                            >
                                {
                                    filterOptions.map((ele, idx) => {
                                        return (
                                            <option key={idx} value={ele.id}>{ele.name}</option>
                                        )
                                    })
                                }
                            </select> */}
                            <div className="font-size-16 text-end">
                                {
                                    selectedStatus + " count : " + incidentReportSlice.myTaskList.length
                                }
                            </div>
                        </Col>
                        {/* <Col lg={4}> */}
                       
                        
                        {/* </Col> */}


                    </Row>
                    {/* <Row className="m-0 mt-1 " >
                        <Col lg="12" className="m-0 p-0">
                            <div className="d-lg-flex gap-1">
                                <div className={`col-4 bg-white `} style={{ minWidth: "400px", height: "82vh", overflow: "auto" }}>

                                    <Row className="m-0">
                                        {
                                            incidentReportSlice.myTaskList && incidentReportSlice.myTaskList.length > 0 ?
                                                <div>
                                                    {
                                                        incidentReportSlice.myTaskList.map((ele, idx) => {
                                                            return (
                                                                <FollowComponent
                                                                    key={idx}
                                                                    item2={ele}
                                                                    i2={idx}
                                                                    selectedTaskIndex={selectedIdx}
                                                                    loadSelectedCheckpoint={(i2, item2) => {
                                                                        loadSelectedCheckpoint(idx, ele)
                                                                    }}
                                                                />
                                                            )
                                                        })

                                                    }


                                                </div>
                                                :
                                                <div
                                                    style={{
                                                        height: "100vh",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Empty />
                                                </div>
                                        }

                                    </Row>
                                </div>
                                <div className={`user-chat bg-white ${showUserPanel ? "col-5" : "col-8"}`} style={{ minWidth: "400px", height: "82vh", }}>
                                    {
                                        showTaskEditor && incidentReportSlice.selectedApln ?
                                            <div>
                                                {
                                                    incidentReportSlice.selectedApln.task_users_resp_status === "0" && !incidentReportSlice.enableReason ?
                                                        <Conversation
                                                            selectedApln={incidentReportSlice.selectedApln}
                                                            userData={userData}
                                                            connectedRoomUsers={connectedRoomUsers}
                                                            showUserPanel={() => { setShowUserPanel(!showUserPanel) }}
                                                        />

                                                    
                                                            :
                                                            <>
                                                              

                                                            </>

                                                }
                                                {
                                                    incidentReportSlice.enableReason  &&  incidentReportSlice.selectedApln.task_users_resp_status=== "0" ?
                                                    <AcceptReject />
                                                    :
                                                    incidentReportSlice.selectedApln.task_users_resp_status === "2" ?
                                                    <RejectedInfo />
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </div> :
                                            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "82vh" }}>
                                                <img src={chaticon} style={{ width: "25%" }} className="pb-2" />
                                                <p>Your conversation starts here</p>
                                            </div>
                                    }
                                </div>
                                <div className="col-3 bg-white" style={{ minWidth: "400px", height: "82vh" }}>
                                    {
                                        currentUser && currentUser.user_status === "0" && showUserPanel &&
                                        <TaskUsers
                                            selectedApln={incidentReportSlice.selectedApln}
                                            userData={userData.user_data}
                                            connectedRoomUsers={connectedRoomUsers}
                                            showUserPanel={() => { setShowUserPanel(!showUserPanel) }}
                                        />
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row> */}


                       {/* Main Container */}
                       <Row className="m-0 mt-1">
                        <Col lg="12" md="12" xl="12" sm="12" className="m-0 p-0">

                            <div className="d-flex flex-column flex-lg-row gap-1">

                                {/* Left Section - Task List */}
                                <div
                                    className={`col-12 col-lg-4 col-md-12 bg-white`}
                                    style={{ minWidth: "200px", height: "82vh", overflow: "auto" }}
                                >
                                    <Row className="m-0">
                                        {incidentReportSlice.myTaskList && incidentReportSlice.myTaskList.length > 0 ? (
                                            <div>
                                                {incidentReportSlice.myTaskList.map((ele, idx) => (
                                                    <FollowComponent
                                                        key={idx}
                                                        item2={ele}
                                                        i2={idx}
                                                        selectedTaskIndex={selectedIdx}
                                                        loadSelectedCheckpoint={(i2, item2) => {
                                                            loadSelectedCheckpoint(idx, ele);
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    height: "100vh",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Empty />
                                            </div>
                                        )}
                                    </Row>
                                </div>

                                {/* Middle Section - Conversation or Editor */}
                                <div
                                    className={`user-chat bg-white ${showUserPanel ? "col-lg-5 col-md-12" : "col-lg-8 col-md-12 col-sm-12"} col-12`}
                                    style={{ minWidth: "300px", height: "" }}
                                >
                                    {showTaskEditor && incidentReportSlice.selectedApln ? (
                                        <div>
                                            {incidentReportSlice.selectedApln.task_users_resp_status === "0" &&
                                                !incidentReportSlice.enableReason ? (
                                                <Conversation
                                                    selectedApln={incidentReportSlice.selectedApln}
                                                    userData={userData}
                                                    connectedRoomUsers={connectedRoomUsers}
                                                    showUserPanel={() => {
                                                        setShowUserPanel(!showUserPanel);
                                                    }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            {incidentReportSlice.enableReason &&
                                                incidentReportSlice.selectedApln.task_users_resp_status === "0" ? (
                                                <AcceptReject />
                                            ) : incidentReportSlice.selectedApln.task_users_resp_status === "2" ? (
                                                <RejectedInfo />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            className="d-flex flex-column justify-content-center align-items-center"
                                            style={{ height: "82vh" }}
                                        >
                                            <img src={chaticon} style={{ width: "25%" }} className="pb-2" />
                                            <p>Your conversation starts here</p>
                                        </div>
                                    )}
                                </div>

                                {/* Right Section - Task Users */}
                                <div
                                    className={`col-12 col-lg-3 col-md-12 col-sm-12 bg-white`}
                                    style={{ height: "82vh", overflow: "auto" }}
                                >
                                    {currentUser &&
                                        currentUser.user_status === "0" &&
                                        showUserPanel && (
                                            <TaskUsers
                                                selectedApln={incidentReportSlice.selectedApln}
                                                userData={userData.user_data}
                                                connectedRoomUsers={connectedRoomUsers}
                                                showUserPanel={() => {
                                                    setShowUserPanel(!showUserPanel);
                                                }}
                                            />
                                        )}
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}
export default MyTask
