import React, { Component } from "react";
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import {
  Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
  Input,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"
import facility from '../FacilityScreen'
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import CardTitle from "reactstrap/lib/CardTitle";
import moment from 'moment'
import SweetAlert from "react-bootstrap-sweetalert"
import Swal from "sweetalert2";
import FuzzySearch from "../../../common/FuzzySearch";
import { PaginationComponent } from "../../../common/DataTable";
import TableContainer from "../../../common/TableContainer";

// import "flatpickr/dist/themes/material_blue.css"
// import Flatpickr from "react-flatpickr"

const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from "../../../helpers/urlSocket";

const statusColor = ["#555657", "#FDA705", "#31D9AC", "#0DE0E8", "#0DB0F9", "#F76518", "#FDA705", "#0D68F9"]
const statusBadgeBGColor = ["#DDDDDD", "#fcedcf", "#D9F0EA", "#e1f3fa", "#CAEAF8", "#F8CFB9", "#fcedcf", "#C6DBFC"]
const statusText = ["Not started", "In progress", "Completed", "Submitted", "Review In progress", "Retake", "Retake In progress", "Reviewed"]


export default class Endpoints extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataloaded: false,

      search_files: [],
      dup_search_files: [],
      temp_search_files: [],
      currentPage: 1,
      totalItems: 0,
      items_per_page: 10,
    }
  }

  async componentDidMount() {
    await this.getSessionData()
    
  }

  async getSessionData() {

    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var auditData = JSON.parse(sessionStorage.getItem("auditData"));
    var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"));


    var protect_routes = await facility(user_facilities, 'usradt')
    if (protect_routes !== undefined) {
      this.setState({
        configData: data.config_data,
        userData: data.user_data,
        db_info: db_info,
        protect_routes: protect_routes,
        auditData,
      },()=>{
        this.loadEndpoints()
      })
    }
    else {
      this.props.history.push('/dashboard')
    }

  }
  loadEndpoints() {

    try {

      urlSocket.post("webpbdadtdta/getendpoints", {
        auditInfo: this.state.auditData,
        userInfo: {
          _id: this.state.userData._id,
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.state.userData.company_id
        }
      })
        .then(response => {
          console.log(response,'response')
          let filter_value = _.filter(response.data.data, e => {
            return e.active === '1'
          })
          // console.log('filter_value', filter_value)

          this.setState({
            dupendpointData : filter_value,
            endpointData: filter_value,
            search_files: filter_value,
            dup_search_files: filter_value,  
            temp_search_files: filter_value
          },()=>{this.pageClick(1);this.setState({dataloaded: true})})
        })

    } catch (error) {
      console.log("catch error", error)
    }

  }

  access_location =(mode)=>{
    if(mode == "1"){
    navigator.geolocation.getCurrentPosition(
      position =>{
          var location_data ={latitude :position.coords.latitude ,longitude : position.coords.longitude  }
          this.props.history.push({
            pathname: '/enpcpts',
            state: { data: location_data },
          });
      },
      error =>{
        if(error.code ==1){
          Swal.fire({
            icon: 'info',
            title: '<span style="color: #3085d6; font-size: 24px;">You need to enable Location to conduct this audit</span>',
            html: `<span style="font-size: 18px;">To enable location access, please go to your browser settings and grant permission for <strong>https://${sessionStorage.getItem('short_name')}.auditvista.com</strong> to access your location.</span>`,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
            customClass: {
              popup: 'swal-popup',
              title: 'swal-title',
              htmlContainer: 'swal-html',
              confirmButton: 'swal-button'
            }
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      }
    )
  }
  else{
    // rewenpcpts

    navigator.geolocation.getCurrentPosition(
      position =>{
          var location_data ={latitude :position.coords.latitude ,longitude : position.coords.longitude  }
          this.props.history.push({
            pathname: '/rewenpcpts',
            state: { data: location_data },
          });
      },
      error =>{
        if(error.code ==1){
          Swal.fire({
            icon: 'info',
            title: '<span style="color: #3085d6; font-size: 24px;">You need to enable Location to conduct this review</span>',
            html: `<span style="font-size: 18px;">To enable location access, please go to your browser settings and grant permission for <strong>https://${sessionStorage.getItem('short_name')}.auditvista.com</strong> to access your location.</span>`,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
            customClass: {
              popup: 'swal-popup',
              title: 'swal-title',
              htmlContainer: 'swal-html',
              confirmButton: 'swal-button'
            }
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      }
    )

  }
   
  }
  navigateTorprt=(endpoint_data)=>{
    console.log(endpoint_data,'endpoint_data')
      sessionStorage.removeItem("endpointInfo");
      sessionStorage.setItem("endpointInfo", JSON.stringify(endpoint_data));
      this.props.history.push("/chkpntrprt");
      sessionStorage.setItem("redirect",1)
  }

  navigateTo(endpoint_data) {
    // console.log(endpoint_data, 'endpoint_data')
    const endDate = new Date(endpoint_data.audit_pbd_ends_on)
    const today = new Date();
    const endDateDateOnly = endDate.toISOString().split('T')[0];
    const todayDateOnly = today.toISOString().split('T')[0];
    const isTodayOrBefore = endDateDateOnly < todayDateOnly;


    sessionStorage.removeItem("endpointData");
    sessionStorage.setItem("endpointData", JSON.stringify(endpoint_data));

    // console.log("endpoint data", endpoint_data.audit_pbd_users.audit_type)

    if (isTodayOrBefore) {
      Swal.fire({
        icon: 'warning',
        title: 'Audit Expired!',
        text: 'Your Audit has been expired,contact Admin !',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      }).then((result) => {
      })
    }
    // if(report !== undefined){
    //   sessionStorage.removeItem("endpointInfo");
    //   sessionStorage.setItem("endpointInfo", JSON.stringify(endpoint_data));
    //   this.props.history.push("/chkpntrprt");
    // }
    else {

      if (endpoint_data.audit_pbd_users.audit_type == "1") {
        if (endpoint_data.status === "0" || endpoint_data.status === "1" || endpoint_data.status === "2" || endpoint_data.status === "5") {
          if(endpoint_data.audit_coords_enable == true){
              this.access_location("1")
          }
          else{
            this.props.history.push("/enpcpts");
          }


          // this.props.history.push("/enpcpts");
        }
        else {
          this.props.history.push("/viewcpts")
        }

      }
      else
        if (endpoint_data.audit_pbd_users.audit_type == "2") {
          if (endpoint_data.status === "3" || endpoint_data.status === "4") {
            if(endpoint_data.review_coords_enable == true){
              this.access_location("2")
            }
            else{
              this.props.history.push("/rewenpcpts");
            }
            // this.props.history.push("/rewenpcpts");
          }
          else {
            this.props.history.push("/viewcpts")
          }
        }
    }

  }

  get_audit_status =(data)=>{
    const endDate = new Date(data.audit_pbd_ends_on)
    const today = new Date();
    const endDateDateOnly = endDate.toISOString().split('T')[0];
    const todayDateOnly = today.toISOString().split('T')[0];
    const isTodayOrBefore = endDateDateOnly < todayDateOnly;
    return isTodayOrBefore
  }



  pageClick = (currentPage) => {
    this.setState({ currentPage }, () => { this.paginationProcess() })
  }

  paginationProcess = () => {
    let { currentPage, items_per_page, search_files, dup_search_files } = this.state
    let result = search_files.slice((currentPage - 1) * items_per_page, (currentPage - 1) * items_per_page + items_per_page)
    if (result.length > 0) {
      this.setState({ endpointData: result})
    } else {
      this.setState({ endpointData: search_files, search_files })
    }
  }

  getFuzzySearch = (search_files) => {
    // this.setState({ endpointData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files })
    this.setState({ endpointData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files },()=>{ this.pageClick(this.state.currentPage)})

    // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })
  }

  render() {
    if (this.state.dataloaded) {
      // const { SearchBar } = Search;
      // const { ExportCSVButton } = CSVExport;
      // const { users } = this.state

      const { isEdit } = this.state
      const options = {
        // pageStartIndex: 0,

        sizePerPage: 10,
        totalSize: this.state.endpointData.length, // replace later with size(users),
        custom: true,
      };

      const columns = [
        {
          accessor: 'name',
          Header: 'Audit',
          filterable: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                <div className={item.audit_pbd_users.audit_type === "1" ? "fw-bold text-primary" : "fw-bold text-info"}>
                  {item.name}
                </div>
              </>
            )
          }
        },
        {
          accessor: 'code',
          Header: 'Code',
          filterable: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                <div >
                  {item.code}
                </div>
              </>
            )
          }
        },
        {
          accessor: '',
          Header: 'Status',
          disableFilters: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            // console.log(item,'item')
            
            return (
              <>
               <div style={{backgroundColor:statusColor[Number(item.status)]}}
                  className="font-size-11 badge m-1"
                >
                  {statusText[Number(item.status)]}
                </div>
              </>
            )
          }
        },
        {
          accessor: 'audit_started_on',
          Header: 'Started on',
          disableFilters: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                <div >
                  {item.audit_started_on === null ? "-- / -- / --" : moment(item.audit_started_on).format("DD-MMM-YYYY")}
                </div>
              </>
            )
          }
        },
        {
          accessor: 'audit_submitted_on',
          Header: 'Submitted on',
          disableFilters: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                <div >
                  {item.audit_submitted_on === null ? "-- / -- / --" : moment(item.audit_submitted_on).format("DD-MMM-YYYY")}
                </div>
              </>
            )
          }
        }, 
        {
          accessor: 'audit_score',
          Header: 'Audit Score',
          disableFilters: true,
          Cell :(cellProps)=>{
            // var perc = (parseFloat(item.audit_score?.$numberDecimal).toFixed(2)/parseFloat(item.max_score?.$numberDecimal).toFixed(2))*100
            // console.log(perc.toFixed(2),'perr')
            var item = cellProps.row.original

            if(item.audit_score_preview){
            return(
              <>
              <div>
              <label>{item.audit_score.$numberDecimal == undefined ? parseFloat(item.audit_score).toFixed(2) : parseFloat(item.audit_score.$numberDecimal).toFixed(2)}</label>
              </div>
              </>
            )
          }
          else{
            return(
            // this.setState({hidden_colm : true})
            <>
            <label style={{ fontSize:12, fontStyle:"italic", color:"#ff0000"}} className="text-danger">Audit score preview not applicable</label>
            </>
            )
          }


          }
        },
        {
          accessor: 'review_score',
          Header: 'Review Score',
          disableFilters: true,
          Cell :(cellProps)=>{
            var item = cellProps.row.original
            if(item.review_score_preview){
            return(
              <>
              <div>
              <label >{item.review_score.$numberDecimal == undefined? parseFloat(item.review_score).toFixed(2) : item.review_score.$numberDecimal }</label>
              </div>
              </>
            )
          }
          else{
            return(
            // this.setState({hidden_colm : true})
            <>
            <label style={{ fontSize:12, fontStyle:"italic", color:"#ff0000"}} className="text-danger">Review score preview not applicable</label>
            </>
            )
          }


          }
        },
        {
          accessor: 'status',
          Header: 'Audit Status',
          disableFilters: true,
          Cell :(cellProps)=>{
            // console.log(item.audit_started_on,'item',item.audit_pbd_ends_on)
            var item = cellProps.row.original
            var audit_status = this.get_audit_status(item)
            // console.log(audit_status,'audit_status')
            return (
              
              <>
                <div style={{backgroundColor:audit_status ? "red" : "green" }}
                  className="font-size-11 badge m-1"
                >
                  {/* {statusText[Number(item.status)]} */}
                 {audit_status ? "Expired" : "Active"}
                </div>
              </>
            )



          }


        },










        // {
        //   dataField: 'dummy',
        //   text: 'Action plan',
        //   sort: true,
        //   formatter: (cellContent, item) => {
        //     return (
        //       <>
        //         <div >
        //           {item.audit_open_aplns !== null && item.audit_open_aplns !== undefined ?
        //           <Button className="btn btn-sm" color="danger" onClick={() => { this.navigateTo(item) }}>{item.audit_open_aplns.length} Actions</Button>:null
        //           }
        //         </div>
        //       </>
        //     )
        //   }
        // },
        // this.state.protect_routes.read_write_checked == true &&
        {
          accessor: "menu",
          disableFilters: true,
          Header: "Action",
          Cell: (cellProps) => {
            var item = cellProps.row.original
            // console.log("item.status", item.audit_pbd_users.audit_type, item.status)
            return(
            <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
              <div className="d-flex gap-1">
                {
                  item.audit_pbd_users.audit_type === "2" &&  
                  (item.status === "0" ||  item.status === "1" || item.status === "2" || item.status === "6")  ?
                  <label style={{ fontSize:12, fontStyle:"italic", color:"#ff0000"}}>Not allow to review till audit is completed</label> :
                  item.audit_pbd_users.audit_type === "2" && (item.status === "5" ||  item.status === "7")?
                  <Button className="btn btn-sm" disabled={this.state.protect_routes.read_write_checked == true ? false : true} color={"info"} onClick={() => { this.navigateTo(item) }}>View</Button>
                   :
                  item.audit_pbd_users.audit_type === "2" && <Button className="btn btn-sm" color={"info"} disabled={this.state.protect_routes.read_write_checked == true ? false : true} onClick={() => { this.navigateTo(item) }}>Review</Button>
                }
                {
                  item.status >="3" &&
                  <Button className="btn btn-sm" color={"primary"} onClick={() => { this.navigateTorprt(item) }} disabled={this.state.protect_routes.read_write_checked == true ? false : true}>Report</Button>

                }

                {
                  item.audit_pbd_users.audit_type === "1" && 
                  (item.status === "3" || item.status === "4" || item.status === "7") ?
                  <Button className="btn btn-sm" color={"info"} disabled={this.state.protect_routes.read_write_checked == true ? false : true} onClick={() => { this.navigateTo(item) }}>View</Button> : 
                  item.audit_pbd_users.audit_type === "1" && item.status === "5" ?
                   <Button className="btn btn-sm" color={"info"} disabled={this.state.protect_routes.read_write_checked == true ? false : true} onClick={() => { this.navigateTo(item) }}>Retake</Button> :
                  item.audit_pbd_users.audit_type === "1" && <Button className="btn btn-sm" color={"info"} disabled={this.state.protect_routes.read_write_checked == true ? false : true} onClick={() => { this.navigateTo(item) }}>Audit</Button>
                }
                {/* <Button className="btn btn-sm" color={item.audit_pbd_users.audit_type === "1" ? "primary" : "info"} onClick={() => { this.navigateTo(item) }}>{item.audit_pbd_users.audit_type === "1" ? "Audit" : "Review"}</Button> */}
              </div>
            </div>
          )},
        },

      ]
      const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
      }];
      return (
        <React.Fragment>
          <div className="page-content" >
           
            <MetaTags>
              <title>AuditVista | End points</title>
            </MetaTags>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-1">
              <div style={{fontWeight: 600, width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
                End points
              </div>
              <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Link to="/usradt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
              </div>
            </div>
            <Container fluid>
              <Row >
                <Col lg="12">
                  <Card >
                    <CardBody>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'space-evenly' }}>
                        <CardTitle>{this.state.auditData.audit_pbd_name}</CardTitle>
                      </div>
                     
                      <TableContainer
                        columns={columns}
                        data={this.state.dupendpointData}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        isJobListGlobalFilter={false}
                        customPageSize={10}
                        style={{ width: '100%' }}
                        // dynamicBtn={true}
                        // btnClick={(param1, param2) => this.navigateTo("", 1)}
                        // dynamicParams={params1,params2}
                        isPagination={true}
                        filterable={false}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"

                      />

                      {/* <PaginationProvider
                        keyField="id"
                        data={this.state.endpointData}
                        columns={columns}
                        pagination={paginationFactory(options)}
                      >
                        {
                          ({
                            paginationProps,
                            paginationTableProps
                          }) => (
                            <ToolkitProvider
                              keyField="id"
                              data={this.state.endpointData}
                              columns={columns}
                              search
                              exportCSV
                            >
                              {
                                toolkitprops => (
                                  <React.Fragment>
                                    <Row className="" style={{ paddingTop: 10 }}>
                                      <Col sm="3" >
                                        <div className="search-box ms-2 mb-1 d-grid">
                                          <div className="position-relative">

                                            <FuzzySearch
                                              search_files={this.state.search_files}
                                              getFuzzySearch={(search_files) => this.getFuzzySearch(search_files)}
                                              dup_search_files={this.state.dup_search_files}
                                              temp_search_files= {this.state.temp_search_files}
                                              keyword={["name"]}
                                            />
                                          </div>
                                        </div>

                                      </Col>
                                      <Col sm='1'>
                                      </Col>
                                      <Col sm="4">
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'space-evenly' }}>
                                          <CardTitle>{this.state.auditData.audit_pbd_name}</CardTitle>                                        
                                        </div>
                                      </Col>
                                      <Col sm="4">
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
                                          <ExportCSVButton className="btn-sm btn-outline-primary" {...toolkitprops.csvProps}>Export as CSV</ExportCSVButton>
                                        </div>
                                      </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-hover"
                                            }
                                            bordered={false}
                                            striped={false}
                                            responsive
                                          />


                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationComponent
                                          total={this.state.search_files.length}
                                          itemsPerPage={this.state.items_per_page}
                                          currentPage={this.state.currentPage}
                                          onPageChange={(currentPage) => this.pageClick(currentPage)}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                            </ToolkitProvider>
                          )}
                      </PaginationProvider> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {this.state.showWarning ? (
                <SweetAlert
                  title="Message"
                  warning
                  onConfirm={() => this.setState({ showWarning: false })}
                >
                  {this.state.message}
                </SweetAlert>
              ) : null}

            </Container>
          </div>
        </React.Fragment>
      )
    }
    else {
      return null
    }
  }

}