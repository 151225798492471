import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Spinner,
    Progress
} from "reactstrap";
import TableContainer from "./Components/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getAplnAuditLocationData } from "../../store/aplnfollowup/aplnflwupslice"
import Breadcrumbs from "./Components/breadCrumb"
import moment from "moment";


const AplnAuditLocations = (props) => {

    const dispatch = useDispatch();
    const { aplnAuditLocationList } = useSelector(state => state.acplnFollowUpSliceReducer)
    const [auditData, setAuditData] = useState(null)


    useEffect(() => {
        const auditData = JSON.parse(sessionStorage.getItem("auditData"));
        setAuditData(auditData)
        dispatch(getAplnAuditLocationData(auditData));

        return () => { };
    }, [dispatch]);

    const navigateToActionPlan = (endpoint_data) => {
        sessionStorage.removeItem("endpointData");
        sessionStorage.setItem("endpointData", JSON.stringify(endpoint_data));
        props.history.push("/adtactnplns");
    };

    const columns = useMemo(
        () => [
            {
                accessor: 'location_name',
                Header: "Locations",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    return (
                        <div
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => { navigateToActionPlan(item) }}
                        >
                            <span>{item.location_name}</span>
                        </div>
                    )
                }
            },
            {
                accessor: 'code',
                Header: "Last updated on",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    return (
                        <div className="text-secondary">
                            {item.audit_started_on === null ? "-- / -- / --" : moment(item.updatedAt).format("DD-MMM-YYYY")}
                        </div>
                    )
                }
            },
            {
                accessor: 'total',
                Header: <span className="badge bg-dark font-size-12">Total</span>,
                disableFilters: true,
                bgColor:"bg-dark",
                Cell: (cellProps) => {
                    var item = cellProps.row.original

                    
                    return (
                        <div className="text-dark">{item.task_status[0].status_info[0].count}</div>
                    )
                }
            },
            {
                accessor: 'not_started',
                Header: <span className="badge bg-secondary font-size-12"><i className="fas fa-male  me-2" /> Not Started</span>,
                disableFilters: true,
                bgColor:"bg-secondary",
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    return (
                        <div className="text-secondary">{item.task_status[0].status_info[1].count}</div>
                    )
                }
            },
            {
                accessor: 'in_progress',
                Header:<span className="badge bg-warning font-size-12"><i className="fas fa-walking  me-2" /> In Progress</span>,
                disableFilters: true,
                bgColor:"bg-warning",
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    return (
                        <div className="text-warning">{item.task_status[0].status_info[2].count}</div>
                    )
                }
            },
            {
                accessor: 'completed',
                Header:<span className="badge bg-primary font-size-12"><i className="fas fa-flag-checkered  me-2" /> Completed</span>,
                disableFilters: true,
                bgColor:"bg-primary",
                Cell: (cellProps) => {
                    var item = cellProps.row.original

                    return (
                        <div className="text-primary">{item.task_status[0].status_info[3].count}</div>
                    )
                }
            },
            {
                accessor: 'closed',
                Header:<span className="badge bg-success font-size-12"><i className="fas fa-check  me-2" /> Closed</span>,
                bgColor:"bg-success",
                disableFilters: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original

                    return (
                        <div className="text-success">{item.task_status[0].status_info[4].count}</div>
                    )
                }
            },
            {
                accessor: 'overdue',
                Header:<span className="badge bg-danger font-size-12"><i className="fas fa-calendar-times  me-2" /> Overdue</span>,
                bgColor:"bg-danger",
                disableFilters: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original

                    return (
                        <div className="text-danger">{item.task_status[0].status_info[5].count}</div>
                    )
                }
            },
            {
                accessor: 'reopen',
                Header:<span className="badge bg-dark font-size-12"><i className="fas fa-redo  me-2" /> Reopen</span>,
                bgColor:"bg-dark",
                disableFilters: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original

                    return (
                        <div className="text-dark">{item.task_status[0].status_info[6].count}</div>
                    )
                }
            },
            {
                accessor: "menu",
                disableFilters: true,
                Header:"Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    return (
                        <div >
                            <i className="bx bx-right-arrow-alt text-primary" style={{ cursor: 'pointer', fontSize: '28px' }} onClick={() => { navigateToActionPlan(item) }} />
                        </div>
                    )
                },
            },
        ],
        []
    )

    const gotoBack = () => {
        props.history.push('/follow_audit')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>AuditVista | Action plan Audit Locations</title>
                </MetaTags>

                <Container fluid>
                    <Breadcrumbs
                        title={auditData && "Audit : " + auditData.activity_name}
                        location={"Locations"}
                        breadcrumbItem="Location action plans"
                        isBackButtonEnable={true}
                        gotoBack={() => gotoBack()}
                    />
                    <Row>
                        {
                            aplnAuditLocationList ?
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={aplnAuditLocationList}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={false}
                                                customPageSize={10}
                                                style={{ width: "100%" }}
                                                isPagination={true}
                                                filterable={false}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col> :
                                <Col lg="12">
                                    <Card>
                                        <CardBody style={{ height: "100vh" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )


}

export default AplnAuditLocations;