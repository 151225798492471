import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { sortLine, sortFunc, sortDescending,handleDownloadBar, imgDownloadSvg, imgDownloadPng } from '../../../../store/reportd3/reportslice';


const LineChart = (props) => {
    const dispatch = useDispatch();
    const chartRef = useRef();
    var containerWidth = props.containerWidth
    var containerHeight = props.containerHeight
    var chart_data = props.chart_data
    var chart_color = props.chart_color
    var curved_line = props.curved_line
    var mouseovered = props.mouseovered
    var mouseovered_type = props.mouseovered_type
    var i = props.id
    var enable_table = props.show_table
    var svgHeight = props.chart_height
    var show_Grid = props.show_Grid
    var YLabel = props.YLabel
    var show_Square = props.show_Square
    var temp_containerWidth = props.temp_containerWidth
    var temp_containerHeight = props.temp_containerHeight
    var fullScreen_enabled = props.show_Full_Screen_toggle
    var show_bar_values = props.show_bar_values
    var text_color_arr = props.text_color
  var barLabel = props.label;


    const [data, setData] = useState(chart_data);
    const [textLinecolorbar, setTextLinecolorbar] = useState([])
    const [curved, setCurved] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [mouseoverEnabled, setmouseoverEnabled] = useState(mouseovered)
    const [mouseoverSwitchType, setMouseoverSwitchType] = useState(mouseovered_type)
    const [enabledTable, setEnabledTable] = useState(enable_table)
    const [showDiv, setshowDiv] = useState(false)
    const [showGridenabled, setShowGridenabled] = useState(show_Grid)
    const [sortShowOptions, setSortShowOptions] = useState(false);
    const [sortingField, setSortingField] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [arrValues, setArrValues] = useState([])
    const [sortOrder, setSortOrder] = useState('')
    const SortArr = useSelector(state => state.reportSliceReducer);
    const [sortData, setSortData] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [scrollDelta, setScrollDelta] = useState(0);

    useEffect(() => {
        if (chart_data !== undefined && chart_data.length > 0) {
            if (sortingField) {
                setSortData([])
            }
            setData(chart_data)
            setTextLinecolorbar(chart_color)
            setCurved(curved_line)
            setmouseoverEnabled(mouseovered)
            setMouseoverSwitchType(mouseovered_type)
            setEnabledTable(enable_table)
            setShowGridenabled(show_Grid)
        }
    }, [chart_data, containerHeight, chart_color, mouseovered, mouseovered_type, enable_table, show_Grid, temp_containerWidth, fullScreen_enabled, temp_containerHeight, show_bar_values])
    if (props.chart_data.length !== 0) {
        var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
        var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
    }
    useEffect(() => {
        if (sortOrder === 'asc') {
            handleSortAscending()
        }
        else if (sortOrder === 'desc') {
            handleSortdesc()
        }
        else {
            handleSortDefault()
        }
    }, [chart_data])

    useEffect(() => {
        const data = chart_data;
        if (datakeys !== undefined) {
            var mod_data;
            var chart_id = i;
            if (SortArr[chart_id] && SortArr[chart_id].linesorted) {
                mod_data = SortArr[chart_id].linesorted;
            } else {
                mod_data = data;
            }

            const margin = { top: 70, right: 30, bottom: 80, left: 40 };
            if (svgHeight !== undefined && svgHeight !== '') {
                containerHeight = containerHeight - 200
            }
            else {
                containerHeight = containerHeight
            }

            var width
            var height
            if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
                width = temp_containerWidth - margin.left - margin.right;
                height = temp_containerHeight - margin.top - margin.bottom - (enabledTable ? 200 : 0)
                containerWidth = temp_containerWidth
                containerHeight = temp_containerHeight - (enabledTable ? 200 : 0)
            }
            else {
                width = containerWidth - margin.left - margin.right;
                height = containerHeight - margin.top - margin.bottom;
            }
            d3.select(chartRef.current).selectAll('*').remove();
            d3.selectAll(`#my_dataviz${i}`).selectAll("div").remove();

            const drag = d3.drag()
                .on("start", dragStarted)
                .on("drag", dragged)
                .on("end", dragEnded)

            const svg = d3
                .select(chartRef.current)
                .attr('width', containerWidth)
                .attr('height', containerHeight)
                .append("g")
                .call(drag);

            d3.selectAll(`#my_dataviz${i}`).call(drag);
            function dragStarted(event, d) {
            }
            let initialMouseX, initialMouseY;
            let initialX, initialY;
            function dragged(event, d) {
                if (!initialMouseX) {
                    initialMouseX = d3.pointer(event)[0];
                    initialMouseY = d3.pointer(event)[1];
                    const initialTransform = svg.attr("transform");
                    initialX = initialTransform ? parseFloat(initialTransform.split("(")[1].split(",")[0]) : 0;
                    initialY = initialTransform ? parseFloat(initialTransform.split(",")[1].split(")")[0]) : 0;
                }

                const mouseX = d3.pointer(event)[0];
                const deltaX = mouseX - initialMouseX;
                const newX = initialX + deltaX;
                if (newX >= 0) {
                } else {
                    svg.attr("transform", `translate(${newX},${initialY})`);
                }
            }
            function dragEnded(event, d) {
                initialMouseX = null;
                initialMouseY = null;
            }
            const chartGroup = svg
                .append("g")
                .attr('transform', `translate(${margin.left},${margin.top})`);

            const xScale = d3
                .scalePoint()
                .domain(mod_data.map((d) => d.year))
                .range([0, width]);

            const yScale = d3
                .scaleLinear()
                .domain([0, d3.max(mod_data, (d) => d3.max(datakeys.map((key) => d[key])))])
                .nice()
                .range([height, 0]);

            const color = d3.scaleOrdinal().range(
                textLinecolorbar !== '' && textLinecolorbar !== undefined && textLinecolorbar.length > 0
                    ? textLinecolorbar
                    : ['red', 'blue', 'green', 'orange', 'purple', 'cyan']
            );

            const text_color = d3.scaleOrdinal()
                .domain(datakeys)
                .range(text_color_arr !== undefined && text_color_arr.length > 0
                    ? text_color_arr
                    : d3.quantize(d3.interpolateRainbow, datakeys.length + 2));

            var lineClass
            const xDomain = d3.extent(mod_data, d => d.year);
            const defaultXValue = xDomain[0];
            const defaultIntersectionData = mod_data.find(d => d.year === defaultXValue);
            const verticalLine = chartGroup.append("line")
                .attr("class", "vertical-line")
                .attr("x1", 0)
                .attr("x2", 0)
                .attr("y1", 0)
                .attr("y2", height)
                .attr("stroke", "black")
                .attr("stroke-width", 1.5)
                .attr('pointer', 'cursor')
                .style("opacity", 0);
            datakeys.forEach((valueKey, index) => {
                const line = getLineGenerator(curved_line, valueKey);
                lineClass = `line-${valueKey}`;
                function getLineGenerator(useCurvedLines, key) {
                    return useCurvedLines
                        ? d3
                            .line()
                            .x((d) => xScale(d.year))
                            .y((d) => yScale(d[key]))
                            .curve(d3.curveCatmullRom.alpha(0.5))
                        : d3
                            .line()
                            .x((d) => xScale(d.year))
                            .y((d) => yScale(d[key]));
                }

                const labelGroup = chartGroup.append('g')
                    .attr('class', 'label-group')
                    .style('fill', color(valueKey));

                labelGroup.selectAll('.line-label')
                    .data(mod_data)
                    .enter()
                    .append('text')
                    .attr('class', 'line-label1')
                    .attr('x', d => xScale(d.year))
                    .attr('y', d => yScale(d[valueKey]) - 20)
                    .text(d => {
                        return show_bar_values ? d[valueKey] : '';
                    })
                    .attr('text-anchor', 'middle')
                    .attr('fill', 'black')
                    .style("opacity", 0)
                    .transition()
                    .duration(10)
                    .style("opacity", 1);

                const tooltip = chartGroup
                    .append("g")
                    .attr("class", `tooltip-${valueKey}`)
                    .style("opacity", 0);
                tooltip
                    .append("rect")
                    .attr("width", 100)
                    .attr("height", 40)
                    .attr("fill", "lightgrey")
                    .style("border", "1px solid black");
                const tooltipText = tooltip
                    .append("text")
                    .attr("x", 10)
                    .attr("y", 20)
                    .style("font-size", "12px");
                const text = chartGroup.append("text")
                    .attr("class", `tooltip-text-${valueKey}`)
                    .attr("x", 10)
                    .attr("y", 10)
                    .attr("fill", "black")
                    .style("font-size", "12px")
                    .style("opacity", 0);
            });
            // const axisLabels = chartGroup.append('g')
            //     .attr('transform', `translate(0, ${height})`)
            //     .selectAll('text')
            //     .style("text-anchor", "middle")
            //     .attr("font-size", "14px")
            //     .attr('fill', 'black')
            //     .style("text-transform", "capitalize")
            //     .style("font-weight", (d, i) => i % 2 === 0 ? "bold" : "normal")
            // let rotationAngle = 0;
            // axisLabels.each(function (_, i) {
            //     const label = this;
            //     d3.select(label).on('click', function () {
            //         const currentRotation = rotationAngle === 0 ? -45 : 0;
            //         const currentAnchor = rotationAngle === 0 ? 'end' : "middle";
            //         axisLabels.attr('transform', `rotate(${currentRotation})`)
            //             .style("text-anchor", currentAnchor)
            //         rotationAngle = currentRotation;
            //     });
            // });


    
            // axisLabels.each(function (_, i) {
            //     const label = this;
            //     d3.select(label).on('click', function () {
            //         const currentRotation = rotationAngle === 0 ? -45 : 0;
            //         const currentAnchor = rotationAngle === 0 ? 'end' : "middle";
            //         axisLabels.attr('transform', `rotate(${currentRotation})`)
            //             .style("text-anchor", currentAnchor)
            //         rotationAngle = currentRotation;
            //     });
            // });


            

            d3.selectAll('.y-val').remove()
            const yAxisContainer = d3.select(`#my_dataviz${i}`)
                .append("div")
                .style("position", "absolute")
                .style("top", `${0}px`)
                .style("left", "0")
                .style("width", `${margin.left}px`)
                .style("height", `${containerHeight - 100}px`);
            const yAxis = yAxisContainer.append("svg")
                .attr('class', 'y-val')
                .attr("width", '100%')
                .attr("height", fullScreen_enabled ? temp_containerHeight : containerHeight)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)
                .call(d3.axisLeft(yScale).ticks(fullScreen_enabled ? 20 : containerHeight / 50))
                .selectAll('.domain, text')
                .attr('stroke', fullScreen_enabled ? 'black' : 'black')
                .style("font-size", '10px')
                .call(g => g.select(".domain").remove())
            yAxis.select(".domain")
                .attr("transform", `translate(${-60}, 0)`);
            yAxis.select(".domain")
                .style("stroke", 'green');
            yAxis.select(".domain")
                .style("stroke-width", 2);
            yAxis.selectAll("text")
                .attr('class', 'yAxis-text')
                .attr("x", -10)
                .attr('fill', 'black')
                .attr("dx", "-3.99em")
                .style('font-weight', 'bold')
                .style("font-size", '12px');
            yAxis.selectAll("line")
                .attr("transform", `translate(${-50}, 0)`)
                .attr('stroke', 'black')
                .attr("dx", "-2em");

            if (show_Grid) {
                chartGroup.insert('g', ':first-child')
                    .attr('class', 'grid')
                    .attr('transform', `translate(0, ${height})`)
                    .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'lightgray');
                chartGroup.insert('g', ':first-child')
                    .attr('class', 'grid')
                    .call(d3.axisLeft(yScale).ticks(5).tickSize(-width).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'lightgray');
            } else {
                chartGroup.selectAll('.grid').remove();
            }

            var newContainerWidth = containerWidth + scrollDelta;
            if (newContainerWidth > containerWidth) {
                var new_xScale = xScale.range([0, newContainerWidth - margin.left - margin.right]);
                svg.append("g").attr('width', newContainerWidth);

                if (show_Grid) {
                    chartGroup.selectAll('.grid').remove();
                    chartGroup.insert('g', ':first-child')
                        .attr('class', 'grid')
                        .attr('transform', `translate(0, ${height})`)
                        .call(d3.axisBottom(new_xScale).tickSize(-height).tickFormat(''))
                        .selectAll('line')
                        .attr('stroke', 'lightgray');
                    chartGroup.insert('g', ':first-child')
                        .attr('class', 'grid')
                        .call(d3.axisLeft(yScale).ticks(5).tickSize(-newContainerWidth).tickFormat(''))
                        .selectAll('line')
                        .attr('stroke', 'lightgray');
                } else {
                    chartGroup.selectAll('.grid').remove();
                }
            } else {
                xScale.range([0, containerWidth - margin.left - margin.right]);
                svg.append("g").attr('width', containerWidth);
            }
            d3.selectAll(`line ${lineClass}`).remove()
            d3.selectAll(`.square-node-rect${i} , .line-label1`).remove()
            const path = svg
                .append('path')
                .datum(mod_data)
                .attr('class', `line ${lineClass}`).remove
            if (newContainerWidth >= containerWidth) {
                d3.select(`#my_dataviz${i}`).on('wheel', handleScroll);
            }
            else {
                setScrollDelta(0)
            }

            var chart = svg
                .append("g")
                .attr('transform', `translate(${margin.left},${margin.top})`);

            // chart.append('g')
            //     .attr('transform', `translate(0, ${height})`)
            //     .call(d3.axisBottom(xScale))
            //     .selectAll('text')
            //     .style("text-anchor", "middle")
            //     .attr("font-size", "14px")
            //     .attr('fill', 'black')
            //     .style("text-transform", "capitalize")
            //     .style("font-weight", (d, i) => i % 2 === 0 ? "bold" : "normal")
            //     .style('cursor', 'pointer')

            const axisGroup = chart.append('g')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            .selectAll('text')
            .style("text-anchor", "middle")
            .attr("font-size", "14px")
            .attr('fill', 'black')
            .style("text-transform", "capitalize")
            .style("font-weight", (d, i) => i % 2 === 0 ? "bold" : "normal")
            .style('cursor', 'pointer')
            

            // .call(d3.axisTop(xScale).ticks(5).tickFormat(d => typeof d === 'string' ? d.toUpperCase() : d))
            // .attr('transform', `translate(${margin.left}, ${margin.top})`);
    
        const axisLabels = chart.selectAll('text')
            .attr("fill", "black")
            .style("font-size", '13px')
            .style("font-style", "normal")
            .attr('transform', `translate(0, ${0})`);
        
        let rotationAngle = 0;
        axisLabels.on('click', function () {
            console.log('416', 416)
            rotationAngle = rotationAngle === 0 ? -45 : 0;
            const textAnchor = rotationAngle === 0 ? 'middle' : 'end';
            axisGroup
            
                .style("text-anchor", textAnchor)
                .attr('transform', `translate(0, ${0}) rotate(${rotationAngle})`);
        });



        
            datakeys.forEach((valueKey, index) => {
                d3.selectAll(`line-${valueKey}`).remove()
                d3.selectAll(`line ${lineClass}`).remove()
                datakeys.forEach((valueKey, index) => {
                    const line = getLineGenerator(curved_line, valueKey);
                    lineClass = `line-${valueKey}`;
                    function getLineGenerator(useCurvedLines, key) {
                        return useCurvedLines
                            ? d3
                                .line()
                                .x((d) => xScale(d.year))
                                .y((d) => yScale(d[key]))
                                .curve(d3.curveCatmullRom.alpha(0.5))
                            : d3
                                .line()
                                .x((d) => xScale(d.year))
                                .y((d) => yScale(d[key]));
                    }

                    const path = chartGroup
                        .append('path')
                        .datum(mod_data)
                        .attr('class', `line ${lineClass}`)
                        .attr('fill', 'none')
                        .attr('stroke', color(valueKey))
                        .attr('stroke-width', 2)
                        .attr('d', line)
                        .style('stroke-dasharray', function () {
                            const totalLength = this.getTotalLength();
                            return totalLength + ' ' + totalLength;
                        })
                        .style('stroke-dashoffset', function () {
                            return this.getTotalLength();
                        })
                        .transition()
                        .duration(100)
                        .style('stroke-dashoffset', 0)

                    const labelGroup = chartGroup.append('g');
                    const textGroup = chartGroup.append('g');

                    const renderText = () => {
                        textGroup.selectAll('text').remove();

                        datakeys.forEach((seriesData, seriesIndex) => {
                            textGroup.selectAll(`.text-${seriesIndex}`)
                                .data(mod_data)
                                .enter().append('text')
                                .attr('class', `text-${seriesIndex}`);

                            labelGroup.selectAll(`.line-label-${seriesIndex}`)
                                .data(mod_data)
                                .enter()
                                .append('text')
                                .attr('class', `line-label-${seriesIndex}`)
                                .attr('x', d => xScale(d.year))
                                .attr('y', d => yScale(d[seriesData]) - 20)
                                .text(d => show_bar_values ? d[seriesData] : '')
                                .attr('text-anchor', 'middle')
                                .attr('fill', text_color(datakeys[seriesIndex]))
                                .style("opacity", 0)
                                .transition()
                                .duration(500)
                                .style("opacity", 1);
                        });
                    };

                    renderText();

                    const circlesGroup = chartGroup
                        .append("g")
                        .attr("class", `circles-group-${valueKey}`);
                    mod_data.forEach(d => {
                        const cx = xScale(d.year);
                        const cy = yScale(d[valueKey]);
                        const circle = circlesGroup.append("circle")
                            .datum(d)
                            .attr("cx", cx)
                            .attr("cy", cy)
                            .attr("r", 6)
                            .attr("fill", "green")
                            .style("opacity", 1)
                            .on('mouseover', function (event, d) {
                                mouseover(event, d, valueKey);
                            })
                            .on('mouseout', function (event, d) {
                                mouseout(event, d, valueKey);
                            });

                        function mouseout(event, d, valueKey) {
                            const circle = chartGroup.select(`.intersection-circle-${valueKey}`);
                            const tooltip = chartGroup.select(`.tooltip-${valueKey}`);
                            circle.style("opacity", 0);
                            tooltip.style("opacity", 0);
                            const tooltip_text = chartGroup.select(`.tooltip-text-${valueKey}`)
                            const tooltip_card = chartGroup.select(`.tooltip-card-${valueKey}`)
                            tooltip_text.style('opacity', 0)
                            tooltip_card.style('opacity', 0)

                            datakeys.forEach((yKey) => {
                                const tooltip_text = chartGroup.select(`.tooltip-text-${yKey}`)
                                tooltip_text.style('opacity', 0)
                            })
                            verticalLine.style("opacity", 0);
                        }

                        function mouseover(event, d, valueKey) {
                            if (mouseoverEnabled) {
                                const mouseX = d3.pointer(event)[0];
                                const closestDataPoint = mod_data.reduce((prev, curr) => {
                                    const prevDiff = Math.abs(xScale(prev.year) - mouseX);
                                    const currDiff = Math.abs(xScale(curr.year) - mouseX);
                                    return currDiff < prevDiff ? curr : prev;
                                });

                                if (mouseoverSwitchType) {
                                    var tooltipX = xScale(closestDataPoint.year);
                                    var tooltipY = yScale(d[valueKey])
                                    const tooltipWidth = 100;
                                    const tooltipHeight = 40;
                                    if (tooltipX + tooltipWidth > newContainerWidth) {
                                        tooltipX = (newContainerWidth - tooltipWidth) - 100;
                                    }
                                    datakeys.forEach((yKey) => {
                                        tooltipX = xScale(closestDataPoint.year);
                                        tooltipY = yScale(closestDataPoint[yKey]);
                                        if (tooltipX + tooltipWidth > newContainerWidth) {
                                            tooltipX = (newContainerWidth - tooltipWidth) - 100;
                                        }

                                        const circle = chartGroup.select(`.intersection-circle-${yKey}`);
                                        const text = chartGroup.select(`.tooltip-text-${yKey}`);
                                        circle.attr("cx", tooltipX).attr("cy", tooltipY).style("opacity", 1);
                                        tooltipText.text(`${barLabel}: ${closestDataPoint.year}, y: ${closestDataPoint[yKey]}`);
                                        tooltip
                                            .attr("transform", `translate(${tooltipX + 10}, ${tooltipY - 40})`)
                                            .style("opacity", 1);
                                        text
                                            .attr("x", tooltipX + 10)
                                            .attr("y", tooltipY)
                                            .style('font-size', '15px')
                                            .html(`<tspan font-weight="bold">x: </tspan>&nbsp; ${closestDataPoint.year}, <tspan font-weight="bold">y: </tspan> &nbsp;${closestDataPoint[yKey]}`)
                                            .style("opacity", 1);

                                        var tooltipX1 = xScale(closestDataPoint.year);
                                        verticalLine.attr("x1", tooltipX1).attr("x2", tooltipX1).style("opacity", 1);
                                    });

                                }
                                else {
                                    var tooltipX = xScale(closestDataPoint.year);
                                    var tooltipY = yScale(d[valueKey])
                                    const tooltipWidth = 100;
                                    const tooltipHeight = 40;
                                    if (tooltipX + tooltipWidth > newContainerWidth) {
                                        tooltipX = (newContainerWidth - tooltipWidth) - 120;
                                    }
                                    const circle = chartGroup.select(`.intersection-circle-${valueKey}`);
                                    const text = chartGroup.select(`.tooltip-text-${valueKey}`);
                                    const tooltipCard = chartGroup.select(`.tooltip-card-${valueKey}`);
                                    if (!tooltipCard.node()) {
                                        const cardGroup = chartGroup
                                            .append('g')
                                            .attr('class', `tooltip-card-${valueKey}`)
                                            .style('opacity', 0);

                                        cardGroup
                                            .append('rect')
                                            .attr('width', 120)
                                            .attr('height', 50)
                                            .attr('fill', 'white')
                                            .attr('stroke', 'black');

                                        cardGroup
                                            .append('text')
                                            .attr('x', 10)
                                            .attr('y', 20)
                                            .attr('fill', color(valueKey))
                                            .style('font-size', '12px')
                                            .attr('class', `tooltip-text-${valueKey}-line-1`);

                                        cardGroup
                                            .append('text')
                                            .attr('x', 10)
                                            .attr('y', 40)
                                            .attr('fill', color(valueKey))
                                            .style('font-size', '12px')
                                            .attr('class', `tooltip-text-${valueKey}-line-2`);
                                    }

                                    circle.attr('cx', tooltipX).attr('cy', tooltipY).style('opacity', 1);
                                    text.style('opacity', 0);

                                    tooltipCard
                                        .select(`.tooltip-text-${valueKey}-line-1`)
                                        .text(`${barLabel}: ${closestDataPoint.year}`);
                                    tooltipCard
                                        .select(`.tooltip-text-${valueKey}-line-2`)
                                        .text(`${valueKey}: ${d[valueKey]}`);

                                    tooltipCard
                                        .attr('transform', `translate(${tooltipX + 10}, ${tooltipY - 40})`)
                                        .style('opacity', 1);
                                }
                            }
                        }

                        const tooltip = circle.append("g")
                            .attr("class", `tooltip-${valueKey}`)
                            .style("opacity", 0);

                        tooltip
                            .append("rect")
                            .attr("width", 100)
                            .attr("height", 40)
                            .attr("fill", "lightgrey")
                            .style("border", "1px solid black");

                        tooltip
                            .append("text")
                            .attr("x", 10)
                            .attr("y", 20)
                            .style("font-size", "12px")

                        circle.tooltip = tooltip;
                        const defaultTooltipY = yScale(defaultIntersectionData[valueKey]);
                    });

                    if (show_Square) {
                        mod_data.forEach(d => {
                            chartGroup.append('rect')
                                .attr('x', xScale(d.year) - 6)
                                .attr('y', yScale(d[valueKey]) - 6)
                                .attr('width', 12)
                                .attr('height', 12)
                        });
                        const squaresGroup = chartGroup
                            .append("g")
                            .attr("class", `squares-group-${valueKey}`);
                        mod_data.forEach(d => {
                            const x = xScale(d.year) - 6;
                            const y = yScale(d[valueKey]) - 6;
                            const square = squaresGroup.append("rect")
                                .datum(d)
                                .attr("x", x)
                                .attr("y", y)
                                .attr("width", 12)
                                .attr("height", 12)
                                .attr("fill", color(valueKey))
                                .style("opacity", 0)
                                .on('mouseover', function (event, d) {
                                    mouseover(event, d, valueKey);
                                })
                                .on('mouseout', function (event, d) {
                                    mouseout(event, d, valueKey);
                                });
                            function mouseout(event, d, valueKey) {
                                const circle = chartGroup.select(`.squares-group-${valueKey}`);
                                const tooltip = chartGroup.select(`.tooltip-${valueKey}`);
                                circle.style("opacity", 0);
                                tooltip.style("opacity", 0);
                                const tooltip_text = chartGroup.select(`.tooltip-text-${valueKey}`)
                                const tooltip_card = chartGroup.select(`.tooltip-card-${valueKey}`)
                                tooltip_text.style('opacity', 0)
                                tooltip_card.style('opacity', 0)

                                datakeys.forEach((yKey) => {
                                    const tooltip_text = chartGroup.select(`.tooltip-text-${yKey}`)
                                    tooltip_text.style('opacity', 0)
                                })
                                verticalLine.style("opacity", 0);
                            }

                            function mouseover(event, d, valueKey) {
                                if (mouseoverEnabled) {
                                    const mouseX = d3.pointer(event)[0];
                                    const closestDataPoint = mod_data.reduce((prev, curr) => {
                                        const prevDiff = Math.abs(xScale(prev.year) - mouseX);
                                        const currDiff = Math.abs(xScale(curr.year) - mouseX);
                                        return currDiff < prevDiff ? curr : prev;
                                    });

                                    if (mouseoverSwitchType) {
                                        var tooltipX = xScale(closestDataPoint.year);
                                        var tooltipY = yScale(d[valueKey])
                                        const tooltipWidth = 100;
                                        const tooltipHeight = 40;
                                        if (tooltipX + tooltipWidth > newContainerWidth) {
                                            tooltipX = (newContainerWidth - tooltipWidth) - 100;
                                        }
                                        datakeys.forEach((yKey) => {
                                            tooltipX = xScale(closestDataPoint.year);
                                            tooltipY = yScale(closestDataPoint[yKey]);
                                            if (tooltipX + tooltipWidth > newContainerWidth) {
                                                tooltipX = (newContainerWidth - tooltipWidth) - 100;
                                            }

                                            const circle = chartGroup.select(`.intersection-circle-${yKey}`);
                                            const text = chartGroup.select(`.tooltip-text-${yKey}`);
                                            circle.attr("cx", tooltipX).attr("cy", tooltipY).style("opacity", 1);
                                            tooltipText.text(`${barLabel}: ${closestDataPoint.year}, y: ${closestDataPoint[yKey]}`);
                                            tooltip
                                                .attr("transform", `translate(${tooltipX + 10}, ${tooltipY - 40})`)
                                                .style("opacity", 1);
                                            text
                                                .attr("x", tooltipX + 10)
                                                .attr("y", tooltipY)
                                                .style('font-size', '15px')
                                                .html(`<tspan font-weight="bold">x123: </tspan>&nbsp; ${closestDataPoint.year}, <tspan font-weight="bold">y: </tspan> &nbsp;${closestDataPoint[yKey]}`)
                                                .style("opacity", 1);

                                            var tooltipX1 = xScale(closestDataPoint.year);
                                            verticalLine.attr("x1", tooltipX1).attr("x2", tooltipX1).style("opacity", 1);
                                        });

                                    }
                                    else {
                                        var tooltipX = xScale(closestDataPoint.year);
                                        var tooltipY = yScale(d[valueKey])
                                        const tooltipWidth = 100;
                                        const tooltipHeight = 40;
                                        if (tooltipX + tooltipWidth > newContainerWidth) {
                                            tooltipX = (newContainerWidth - tooltipWidth) - 120;
                                        }

                                        const circle = chartGroup.select(`.intersection-circle-${valueKey}`);
                                        const text = chartGroup.select(`.tooltip-text-${valueKey}`);
                                        const tooltipCard = chartGroup.select(`.tooltip-card-${valueKey}`);
                                        if (!tooltipCard.node()) {
                                            const cardGroup = chartGroup
                                                .append('g')
                                                .attr('class', `tooltip-card-${valueKey}`)
                                                .style('opacity', 0);

                                            cardGroup
                                                .append('rect')
                                                .attr('width', 120)
                                                .attr('height', 50)
                                                .attr('fill', 'white')
                                                .attr('stroke', 'black');

                                            cardGroup
                                                .append('text')
                                                .attr('x', 10)
                                                .attr('y', 20)
                                                .attr('fill', color(valueKey))
                                                .style('font-size', '12px')
                                                .attr('class', `tooltip-text-${valueKey}-line-1`);

                                            cardGroup
                                                .append('text')
                                                .attr('x', 10)
                                                .attr('y', 40)
                                                .attr('fill', color(valueKey))
                                                .style('font-size', '12px')
                                                .attr('class', `tooltip-text-${valueKey}-line-2`);
                                        }

                                        circle.attr('cx', tooltipX).attr('cy', tooltipY).style('opacity', 1);
                                        text.style('opacity', 0);

                                        tooltipCard
                                            .select(`.tooltip-text-${valueKey}-line-1`)
                                            // .text(`xabc: ${closestDataPoint.year}`);
                                            .text(`${barLabel}: ${closestDataPoint.year}`);

                                        tooltipCard
                                            .select(`.tooltip-text-${valueKey}-line-2`)
                                            .text(`${valueKey}: ${d[valueKey]}`);

                                        tooltipCard
                                            .attr('transform', `translate(${tooltipX + 10}, ${tooltipY - 40})`)
                                            .style('opacity', 1);
                                    }
                                }
                            }

                            const tooltip = square.append("g")
                                .attr("class", `tooltip-${valueKey}`)
                                .style("opacity", 0);
                            tooltip
                                .append("rect")
                                .attr("width", 100)
                                .attr("height", 40)
                                .attr("fill", "lightgrey")
                                .style("border", "1px solid black");
                            tooltip
                                .append("text")
                                .attr("x", 10)
                                .attr("y", 20)
                                .style("font-size", "12px");
                        });
                    }
                    const tooltip = chartGroup
                        .append("g")
                        .attr("class", `tooltip-${valueKey}`)
                        .style("opacity", 0);
                    tooltip
                        .append("rect")
                        .attr("width", 100)
                        .attr("height", 40)
                        .attr("fill", "lightgrey")
                        .style("border", "1px solid black");
                    const tooltipText = tooltip
                        .append("text")
                        .attr("x", 10)
                        .attr("y", 20)
                        .style("font-size", "12px");
                    const text = chartGroup.append("text")
                        .attr("class", `tooltip-text-${valueKey}`)
                        .attr("x", 10)
                        .attr("y", 10)
                        .attr("fill", "black")
                        .style("font-size", "12px")
                        .style("opacity", 0);
                });
            })
            var datakeys_mod
            if (YLabel.length > 0) {
                datakeys_mod = YLabel.slice(1)
            }
            else {
                datakeys_mod = datakeys
            }
            d3.selectAll('.line-label ,label-group ').remove();

            // const legendContainer = d3.select(`#legend${i}`);
            // const legendWidth = datakeys.length * 120;
            // const legendX = (containerWidth - legendWidth) / 2;
            // const legendY = height + margin.bottom - 40;
            // legendContainer.selectAll('*').remove();
            // datakeys_mod.forEach((key, i) => {
            //     const legendItemGroup = legendContainer.append('div')
            //         .attr('class', 'legend-item')
            //     legendItemGroup.append('div')
            //         .attr('class', 'legend-rect')
            //         .style('background-color', (chart_color?.length > 0 && chart_color[i + 1] != null) ? chart_color[i] : color(key))
            //     const legend = legendContainer.attr('transform', `translate(${250}, ${legendY})`);
            //     legendItemGroup.append('text')
            //         .attr('fill', color(key))
            //         .text(key)

            // });


            const legendContainer = d3.selectAll(`#legend${i}`);
            const legendWidth = datakeys.length * 120;
            const legendX = (containerWidth - legendWidth) / 2;
            const legendY = height + margin.bottom - 40;
            legendContainer.selectAll('*').remove(); // Clear previous legend content
            
            datakeys_mod.forEach((key, i) => {
              const legendItemGroup = legendContainer.append('div')
                .attr('class', 'legend-item')
                .style('display', 'flex')
                .style('align-items', 'center')
                .style('margin-right', '10px'); // Add some space between legend items
            
              legendItemGroup.append('div')
                .attr('class', 'legend-rect')
                .style('width', '13px')
                .style('height', '13px')
                .style('background-color', () => {
                  var colorToUse = (chart_color?.length > 0 ? (chart_color[i] != undefined) ? chart_color[i] : chart_color[i + 1] : color(key));
                  return colorToUse;
                })
                .style('margin-right', '5px'); // Space between rectangle and text
            
              legendItemGroup.append('text')
                .attr('class', 'legend-text')
                .style('color', 'green')
                .text(key);
            });
            if (enable_table) {
                showTableFunc(true)
            }
            else {
                showTableFunc(false)
            }
            const handleResetButtonClick = () => {
                setScrollDelta(0);
                xScale.range([0, containerWidth - margin.left - margin.right])
                svg.attr("width", containerWidth)
                svg.append('g').attr("transform", `translate(${margin.left},${margin.top})`)
            };
            document.getElementById(`togglereset-${i}`).addEventListener('click', function (event) {
                handleResetButtonClick()
            })
        }
    }, [containerWidth, containerHeight, textLinecolorbar, curved, mouseoverEnabled, mouseoverSwitchType, showGridenabled, temp_containerWidth, fullScreen_enabled, temp_containerHeight, sortData, show_bar_values, YLabel, svgHeight, enable_table, show_Square, curved_line, chart_data, SortArr, scrollDelta, text_color_arr]);
    const handleMenuClick = (e) => {
        setShowOptions(!showOptions);
    };
    const showTableFunc = async (val) => {
        const fieldNames = Object.keys(data[0]).filter(key => key !== "_id");
        if (val) {
            await tabulate(data, fieldNames)
        }
        else {
            setshowDiv(false)
            d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
        }
    }
    const tabulate = async (data, columns, y_axis_name) => {
        const header = columns;
        var data_exist;
        if (data !== undefined) {
            data_exist = data;
        } else {
            data_exist = data;
        }
        var tableContainer = document.getElementById(`tableContainer${i}`);
        if (tableContainer !== null) {
            tableContainer.innerHTML = "";
        }
        var table = d3.select(`#tableContainer${i}`)
            .attr("class", "table-responsive")
            .append("table")
            .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth - 12}px`);
        var thead = table.append("thead");
        var tbody = table.append("tbody");
        d3.select(tableContainer)
            .attr('class', 'table_body')
            .style("overflow-y", "scroll")
            .style("overflow-x", "hidden");
        thead.append("tr")
            .selectAll("th")
            .data(header)
            .enter()
            .append("th")
            .text(function (column) { return column; })
            .attr("style", "text-align: center")
            .style('color', 'black')
        var rows = tbody.selectAll("tr")
            .data(data_exist)
            .enter()
            .append("tr");

        var cells = rows.selectAll("td")
            .data(function (row) {
                return columns.map(function (column) {
                    return { column: column, value: row[column] };
                });
            })
            .enter()
            .append("td")
            .attr("class", function (d) { return "cell " + d.column; })
            .html(function (d) { return d.value; })
            .attr("style", "text-align: center")
            .style('color', 'black')
        return table;
    }
    const handleSortIconClick = (e) => {
        setSortShowOptions(!sortShowOptions)
    };
    async function handlePlusIconClick(value) {
        let deltaYExtension;
        if (value) {
            deltaYExtension = 100;
        } else {

            if (scrollDelta < 0) {
                deltaYExtension = 0;
            }
            else {
                deltaYExtension = -100;
            }

        }
        const wheelEvent = new WheelEvent("wheel", {
            deltaY: deltaYExtension,
            view: window,
            bubbles: true,
            cancelable: true
        });
        handleScroll(wheelEvent)
    }

    const handleScroll = (event) => {
        event.preventDefault()
        const delta = event.deltaY;
        setScrollDelta(prevDelta => prevDelta + delta);
        const newZoomLevel = zoomLevel + (delta > 0 ? -0.1 : 0.1);
        if (newZoomLevel > 0) {
            setZoomLevel(newZoomLevel);
        }
    };

    const handleSortAscending = () => {
        var chart_id = i;
        dispatch(sortFunc({ data, arrValues, chart_id }));
    }

    const handleSortdesc = () => {
        var chart_id = i;
        dispatch(sortDescending({ data, arrValues, chart_id }));
    }

    const handleSortDefault = () => {
        dispatch(sortLine({ data: chart_data, chart_id: i }));
        setSortData([...chart_data]);
        setSortingField(null);
    };

    const handleCheckboxChange = (e, value) => {
        if (e.target.checked) {
            setArrValues((prevData) => [
                ...prevData,
                value
            ]);
        } else {
            setArrValues(arrValues.filter((val) => val !== value));
        }
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((val) => val !== value));
        } else {
            setSelectedValues([...selectedValues, value]);
        }
    };
    return (
        <div>
            <div id={`my_dataviz${i}`} onMouseLeave={() => { setShowOptions(false); setSortShowOptions(false); }}>
                <svg ref={chartRef} width={fullScreen_enabled ? temp_containerWidth : containerWidth} >
                    <g ></g>
                </svg>
            </div>
            <div id={`legend${i}`} style={{ maxWidth: containerWidth / 2 - 90, minWidth: '300px', overflowX: 'auto', position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'centre', marginLeft: (fullScreen_enabled ? temp_containerWidth : containerWidth) / 3 - 90, marginTop: '-40px', boxShadow: 'none' }}></div>
            {showOptions && (
                <div
                    className="download-options"
                    onMouseLeave={() => setShowOptions(false)}
                    style={{
                        position: 'absolute',
                        top: '46px',
                        right: '110px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        cursor: 'pointer',
                        justifyContent: 'center'
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
                >
                    <p onClick={() => handleDownloadBar('0', datakeys_name, datakeys, data)}>Download as CSV</p>
                    <p onClick={() => imgDownloadSvg(`my_dataviz${i}`)}>Download as SVG</p>
                    <p onClick={() => imgDownloadPng(i)} className='mt-1'>Download as PNG</p>

                </div>
            )}
            <span onMouseOver={() => { handleSortIconClick(); setShowOptions(false); }} >
                <i
                    className="bx bx-sort"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        width: '35px',
                        position: 'absolute',
                        top: '9px',
                        right: '94px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                ></i>
            </span>

            <i className="bx bx-reset"
                style={{
                    cursor: 'pointer',
                    fontSize: '20px',
                    width: '35px',
                    position: 'absolute',
                    top: '9px',
                    right: '324px',
                    zIndex: '1',
                    color: '#6666B2',
                }}

                id={`togglereset-${i}`}
            ></i>
            <span >
                <i className="bx bx-zoom-in"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        width: '35px',
                        position: 'absolute',
                        top: '9px',
                        right: '259px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                    onClick={() => handlePlusIconClick(true)}
                    id='plus-icon'
                ></i>
            </span>
            <span >
                <i
                    className="bx bx-zoom-out"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        width: '35px',
                        position: 'absolute',
                        top: '9px',
                        right: '291px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                    id='minus-icon'
                    onClick={() => handlePlusIconClick(false)}
                ></i>
            </span>
            <span onMouseOver={() => { handleMenuClick(); setSortShowOptions(false); }} >
                <i
                    className="bx bx-download"
                    style={{
                        cursor: 'pointer',
                        fontSize: '23px',
                        width: '45px',
                        position: 'absolute',
                        top: '7px',
                        right: '55px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                ></i>
            </span>
            {sortShowOptions && (
                <div
                    onMouseOver={() => setSortShowOptions(true)}
                    className="download-options"
                    style={{
                        position: 'absolute',
                        top: '45px',
                        right: '116px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        maxHeight: (`${containerHeight - 120}px`),
                        overflow: 'auto',
                        zIndex: '5',

                    }}
                    onMouseLeave={() => setSortShowOptions(false)}
                >
                    {selectedValues.length > 0 && (
                        <div style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button className='btn btn-sm btn-primary me-2' onClick={handleSortAscending}>
                                    Ascending
                                </button>
                                <button className='btn btn-sm btn-primary me-2' onClick={handleSortdesc}>
                                    Descending
                                </button>
                                <button className='btn btn-sm btn-primary me-2' onClick={handleSortDefault}>
                                    Default
                                </button>
                            </div>
                        </div>
                    )}
                    <div>
                        {datakeys.map((value) => (
                            <div key={value} style={{ marginBottom: '10px', boxShadow: 'none' }}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={value}
                                        checked={selectedValues.includes(value)}
                                        onChange={(e) => handleCheckboxChange(e, value)}
                                    />
                                    {value.toUpperCase()}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {isLoading &&
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>}
            {enable_table ? (
                <>
                    <div style={{
                        bottom: 0,
                        left: 0,
                        backgroundColor: '#fff',
                        height: (fullScreen_enabled ? '240px' : '200px')
                    }} id={`tableContainer${i}`}>
                    </div>
                </>
            ) : null}

        </div>
    );
};

export default LineChart;