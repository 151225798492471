import React, { useEffect, useMemo, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import MetaTags from "react-meta-tags";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Spinner , Button
} from "reactstrap";
import { retriveIncidentTypeList, seteditIncidentTypeInfo, setAutomatedIncidentId, deleteIncidentType,setincidentTypeExist } from '../../../store/incidentReport/incdreportslice';
import { useDispatch, useSelector } from 'react-redux';
import TableContainer from './Components/TableContainer';
import Drawer from 'react-modern-drawer'
import IncidentTypeForm from './Components/IncidentTypeForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const IncidentType = () => {
    const dispatch = useDispatch()
    const [drawerOpen, setdrawerOpen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(true)

    const incidentReport = useSelector(state => state.incdreportslice)
    const incidentTypeList = incidentReport.incidentTypeList

    useEffect(() => {
        console.log("start incident type page")
        dispatch(retriveIncidentTypeList())

    }, [])


    const deleteIncident = (item) => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Do you want to delete this Incident type ?',
            showCancelButton: true,
            confirmButtonColor: '#2ba92b',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(item, 'item')
                dispatch(deleteIncidentType(item._id))
                setDataLoaded(false); setTimeout(() => {
                    setDataLoaded(true)
                }, 100);
            }

        })




    }

  const drawerStyles = {
        width: '100%', // Default to full width for small screens
        maxWidth: '700px', // Maximum width for larger screens
    };

    const columns = useMemo(
        () => [
            {
                accessor: "incd_type_name",
                Header: "Incident Type Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.incd_type_name}
                        </div>

                    )
                }
            },
            // {
            //     accessor: "incd_type_id",
            //     Header: "Incident Id",
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         var item = cellProps.row.original;
            //         return (
            //             <div className="text-dark fw-bold"
            //                 style={{ cursor: "pointer" }}
            //             >
            //                 {item.incd_type_id}
            //             </div>

            //         )
            //     }
            // },
            {
                accessor: "createdAt",
                Header: "Created On",
                disableFilters: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="">
                            {new Intl.DateTimeFormat("en-GB", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            }).format(new Date(item.createdAt))}
                        </div>
                    )
                },
            },
            {
                accessor: "menu",
                disableFilters: true,
                Header: "Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="action-cell">
                            <button onClick={() => { dispatch(seteditIncidentTypeInfo(item)); dispatch(setAutomatedIncidentId(item.incd_type_id));dispatch(setincidentTypeExist(false)); setdrawerOpen(true) }} className="btn btn-sm btn-primary mx-1">
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button onClick={() => {
                                deleteIncident(item)
                            }} className="btn btn-sm btn-danger mx-1">
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </div>
                    );
                },
            }
        ]
    )

    const createIncidentType = () => {
        console.log("ssss")
        setdrawerOpen(true)




    }




    if (dataLoaded) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>AuditVista | Incident Type</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Manage Incidents" breadcrumbItem="Incident Type" />
                        <Row>
                            {
                                incidentTypeList ?
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>

                                                <div className='text-end my-2 pe-2' >

                                                    <Button
                                                        color="primary"

                                                        onClick={() => { dispatch(seteditIncidentTypeInfo({})); createIncidentType(); dispatch(setincidentTypeExist(false)) }}
                                                    >
                                                        Create Incident Type
                                                    </Button>
                                                </div>


                                                <TableContainer
                                                    columns={columns}
                                                    data={incidentTypeList}
                                                    isGlobalFilter={true}
                                                    isAddOptions={false}
                                                    isJobListGlobalFilter={false}
                                                    customPageSize={10}
                                                    style={{ width: "100%" }}
                                                    isPagination={true}
                                                    filterable={false}
                                                    tableClass="align-middle table-nowrap table-check"
                                                    theadClass="table-light"
                                                    pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                                    // btnName={"Create Incident Type"}
                                                    // dynamicBtn={true}
                                                    // btnClick={() => { dispatch(seteditIncidentTypeInfo({})); createIncidentType();dispatch(setincidentTypeExist(false)) }}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col> :
                                    <Col lg="12">
                                        <Card>
                                            <CardBody style={{ height: "100vh" }}>
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div>Loading...</div>
                                                    <Spinner className="ms-2" color="primary" />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }

                            <Drawer
                                open={drawerOpen}
                                onClose={() => { setdrawerOpen(false) }}
                                direction="right"
                                size={'700px'}
                                zIndex={9999}
                                noOverlay={false}
                                style={drawerStyles}




                            >
                                <div
                                    style={{
                                        padding: 10,
                                        maxHeight: '100vh',
                                        overflow: "auto",
                                    }}
                                >
                                    {
                                        drawerOpen &&
                                        <IncidentTypeForm
                                            onClose={() => {
                                                setdrawerOpen(false); setDataLoaded(false); setTimeout(() => {
                                                    setDataLoaded(true)
                                                }, 100);
                                            }}
                                        />
                                    }
                                </div>
                            </Drawer>
                        </Row>


                    </Container>

                </div>
            </React.Fragment>
        )
    }
    else {
        return (
            <Col lg="12">
                <Card>
                    <CardBody style={{ height: "100vh" }}>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div>Loading...</div>
                            <Spinner className="ms-2" color="primary" />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )
    }

}

export default IncidentType
