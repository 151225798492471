import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Form, Label, Input, FormFeedback } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthCode from "react-auth-code-input"

// import images
// import logodark from "../../assets/images/logo-dark.png";
// import logolight from "../../assets/images/logo-light.png";
import auditvista_logo from "../../../assets/images/auditvista_logo.png";
import Swal from 'sweetalert2'

import CarouselPage from "./CarouselPage";
import urlSocket from "../../../helpers/urlSocket";

const ForgetPassword2 = () => {

  //meta title
  document.title = "Forget Password | AuditVista";

  const [showPassword, setShowpassword] = useState(false)
  const [otp, setOtp] = useState()
  const [email, setEmail] = useState('')
  const [showSuccess,setShowSuccess]=useState(false)
  const [otpErr,setotpErr]=useState(false)
  const [pwdErr,serPwdErr]=useState(false)


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: '',
      new_password: '',

    },
    validationSchema: () => {
      let schema = Yup.object().shape({
        username: Yup.string().required("Please Enter Your Email ID"),
      });
  
      if (showPassword) {
        schema = schema.shape({
          new_password: Yup.string().required("Please Enter Your New Password"),
        });
      }
  
      return schema;
    },
    // validationSchema: Yup.object({
    //   username: Yup.string().required("Please Enter Your username"),
    //   new_password: Yup.string().required("Please Enter Your New Password"),

    // }),
    onSubmit: (values) => {
      values["new_password"] = validation.values.new_password
      values["confirmationCode"] = otp
      values["short_name"]= sessionStorage.getItem('short_name')
      if (values.confirmationCode === undefined) {
        setotpErr(true)
      }
      // if (values.new_password === "") {
      //   serPwdErr(true)
      // }
      if (showPassword === false ) {
        urlSocket.post('cog/forget-password', values).then((data) => {
          if (data.data.response_code === 500) {
            setShowpassword(true)
            setEmail(data.data.email)
          }
          else if (data.data.data.error.code === "LimitExceededException") {
            Swal.fire({
              icon: 'warning',
              title: 'Exceed !',
              text: 'You have exceeded more than the Limit',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then((result) => {
            })
          }
          else {
            Swal.fire({
              icon: 'warning',
              title: 'Invalid !',
              text: 'Invalid Username not found',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then((result) => {
            })
          }
        })
      }
      if (showPassword === true && values.new_password !== '' && values.confirmationCode !== undefined) {

        urlSocket.post('cog/confirm-reset-password', values).then((response) => {
          if(response.data.response_code === 500){
            setShowSuccess(true)
          }
          else if(response.data.response_code === 504 && response.data.error.error.code === "CodeMismatchException"){
            Swal.fire({
              icon: 'warning',
              title: 'Invalid !',
              text: 'Invalid OTP you have entered,Please verify the OTP sent through email',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then((result) => {
            })

          }
        })

      }


    }
  });

  const pwd_validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      new_password: '',
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(
          /^(?=.*[A-Z]).+$/,
          "Password must contain at least one uppercase letter"
        )
        .required("Please Enter the New Password"),
    }),
    onSubmit: (values) => {
    }
  });










  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
          {
            !showSuccess &&
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={auditvista_logo}
                          alt=""
                          height="30"
                          className="auth-logo-dark"
                        />
                        <img
                          src={auditvista_logo}
                          alt=""
                          height="30"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Forgot Password</h5>
                        {/* <p className="text-muted">Reset-Password with AuditVista.
                        </p> */}
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email ID :</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter Email ID"
                              type="username"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              disabled={showPassword}
                              invalid={
                                validation.touched.username && validation.errors.username ? true : false
                              }
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}
                          </div>
                          {/* <br /> */}
                          {
                            showPassword &&
                            <>
                              <div style={{ position: "relative" }}>
                                <h3 className="fontInter header-subtitle">Enter OTP</h3>

                              </div>
                              <p>We have sent one time password to <span className="text-primary">{email}</span>. Never share your OTP with anyone.</p>
                              <div className="text-center otpBox">
                                <AuthCode
                                  characters={4}
                                  onChange={(otp) => {
                                    setOtp(otp);
                                    setotpErr(false); // Reset the error state when OTP changes
                                  }}
                                  className="form-control form-control-lg text-center otpInput"
                                  pattern="[0-9]*"
                                  required
                                  inputStyle={{
                                    padding: '7px',
                                    borderRadius: '0.4rem',
                                    fontSize: '1rem',
                                    textAlign: 'center',
                                    marginRight: '7px',
                                    border: '1px solid rgb(206, 212, 218)',
                                    textTransform: 'uppercase',
                                  }}
                                />
                              </div>
                              {
                                otpErr && 
                                    <label className="text-danger" style={{ fontSize: 'smaller' }}>Please Enter the OTP </label>
                              }
                              {/* <br />
                              <div className="float-end">
                              <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
                            </div> */}
                            <br/>
                              <div className="mb-3">
                                <Label className="form-label">New Password :</Label>
                                <Input
                                  name="new_password"
                                  className="form-control"
                                  placeholder="Enter New Password"
                                  type="password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.new_password || ""}
                                  invalid={
                                    validation.touched.new_password && validation.errors.new_password ? true : false
                                  }
                                  autoComplete='new-password'
                                />
                                
                                {validation.touched.new_password && validation.errors.new_password ? (
                                  <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                                ) : null}
                              </div>
                              {/* {
                                pwdErr &&
                                <label className="text-danger">Please Enter the Password</label>
                              } */}
                            </>
                          }

                          <div className="mt-3 text-end">
                            <Button
                              color="primary"
                              // className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              {showPassword ? "Submit" : "verify Email ID"}
                            </Button>
                          </div>

                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} {" "}AuditVista{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          }
          {
            showSuccess &&
            <Col xl={3}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <a href="/" className="d-block auth-logo">
                      <img
                        src={auditvista_logo}
                        alt=""
                        height="30"
                        className="logo-dark-element"
                      />
                      {/* <img
                        src={auditvista_logo}
                        alt=""
                        height="30"
                        className="logo-light-element"
                      /> */}
                    </a>
                  </div>
                  <div className="my-auto">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Success !</h4>
                        <title>Password changed successfully</title>
                        <p className="text-muted">
                        You can now log in to your account using your new password.
                         {/* User login password has been changed successfully . please use the updated password to Login.  */}
                        </p>
                        <div className="mt-4">
                          <Link to="/login" className="btn btn-success">
                            Back to Login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()}{" "} AuditVista
                      {/* <i className="mdi mdi-heart text-danger"></i> by
                      Themesbrand */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>



          }

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword2;
