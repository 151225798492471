import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "d3";
import urlSocket from "../../helpers/urlSocket";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';
import * as d3 from 'd3';



const reportSlice = createSlice({
    name: "reportSlice",
    initialState: {
        reportTemplateList: [],
        reportRequestLoading : false,
        reportRequestError : false,
        openModal : false,
        selectedReportTemplateInfo : null,
        totalReportTemplates :0,
        totalListCount : 0,
        pageInfo :{
            pageIndex : 0,
            pageSize : 10
        },
        reportUsers:[],
        selectedReportUser :[],
        userReports : [],
        reportTemplateTree :[],
        pageNodeInfo : JSON.parse(sessionStorage.getItem("pageNodeInfo")),
        reportTreeData :[],
        layoutInfo:[],
        layoutId:'',
        hierarchyList:[],
        hierarchyTree:[],
        filterXaxisValue :[],
        slicerValues :[],
        saveData :true,
        //sort state
        sortedData: [],
        linesorted: [],
        horstack: [],
        vertilinesorted: [],
        areasorted: [],
        horbarsorted: [],
        verticalbarsorted: [],
        name: "",
        breakPoints:{},
        globalFilterValues :[],
        selectedFilterList :[],
        selectedCollectionData: []

    },
    reducers: {
        setReportTemplate: (state, action) => {
            console.log(action.payload,'action')
            state.reportTemplateList = action.payload
        },
        setReportRequestLoading : (state,action)=>{
            state.reportRequestLoading  = action.payload
        },
        setReportRequestError:(state,action)=>{
            state.reportRequestError = action.payload
        },
        setSaveData:(state,action)=>{
            state.saveData = action.payload
        },
        setopenModal : (state,action)=>{
            console.log(action,'action')
            state.openModal = action.payload
        },
        setSelectedReportInfo : (state,action)=>{
            state.selectedReportTemplateInfo = action.payload
        },
        setTotalReportItems:(state,action)=>{
            console.log(action.payload,'action')
            state.totalReportTemplates = action.payload
        },
        setPageInfo : (state,action)=>{
            console.log(action.payload,'action')
            state.pageInfo = action.payload
        },
        setReportUser:(state,action)=>{
            state.reportUsers = action.payload
        },
        setSelectedReportUser:(state,action)=>{
            state.selectedReportUser = action.payload
        },
        setUserReport :(state,action)=>{
            state.userReports = action.payload
        },
        setReportTemplateTree:(state,action)=>{
            state.reportTemplateTree = action.payload
        },
        setReportTreeNodeInfo:(state,action)=>{
            state.pageNodeInfo = action.payload
        },
        setreportTreeData:(state,action)=>{
            state.reportTreeData = action.payload
        },
        updateLayoutInfo: (state, action) => {
            console.log(action.payload,'action.payload')
            state.layoutInfo = action.payload;
        },
        setHierarchyData:(state,action)=>{
            state.hierarchyList =action.payload
        },
        setHierarchTree:(state,action)=>{
            console.log(action.payload,'action')
            state.hierarchyTree = action.payload
        },
        setGlobalFilter:(state,action)=>{
            console.log(action.payload,'action')
            state.globalFilterValues = action.payload
        },
        setselectedFilterList:(state,action)=>{
            state.selectedFilterList = action.payload
        },
        setXaxisFilterValue:(state,action)=>{
            console.log(action.payload,'action.payload')
            state.filterXaxisValue = action.payload
        },
        setSlicerValues:(state,action)=>{
            console.log(action.payload,'action.payload')
            state.slicerValues = action.payload
        },
        setSelectedCollectionData:(state,action)=>{
            console.log(action.payload,'action.payload')
            state.selectedCollectionData = action.payload
        },

        // createLayout: (state, action) => {
        //     const val = action.payload;
        //     console.log(val,'val',state.layoutInfo)
        //     const pageNodeInfo = JSON.parse(sessionStorage.getItem("pageNodeInfo"))
        //     const layout = state.layoutInfo;
        //     const uniqueArr = _.uniqBy(layout, "y");
        //     const totalHeight = uniqueArr.reduce((acc, item) => acc + item.h, 0);
        //     const newY = totalHeight;
        //     console.log(pageNodeInfo.selected_cln_name[0],'pageNodeInfo.selected_cln_name[0]')
        //     var lay1
        //     const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        //     const pageInfo = JSON.parse(sessionStorage.getItem('page_data'))
        //     const requestInfo ={
        //         dbInfo : authUser.db_info,
        //         layoutId : state.layoutId,
        //         pageInfo : pageInfo,
        //         userInfo : authUser.user_data
        
        //     }
            
        //     if (val === '1') {
        //         lay1 = [
        //             ...layout,
        //             { i: uuidv4(), x: 0, y: newY, w: 12, h: 3 ,minH : 2 , minW : 5,
        //             selected_cln_name : pageNodeInfo.selected_cln_name[0]
        //         },

        //         ];
        //     } else if (val === '2') {
        //         lay1 = [
        //             ...layout,
        //             { i: uuidv4(), x: 0, y: newY, w: 6, h: 3 ,minH : 2 , minW : 5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 6, y: newY, w: 6, h: 3 ,minH : 2 , minW : 5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]}
        //         ];
        //     } else if (val === '3') {
        //         lay1 = [
        //             ...layout,
        //             { i: uuidv4(), x: 0, y: newY, w: 4, h: 3,minH : 2 , minW : 2 ,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 4, y: newY, w: 4, h: 3,minH : 2 , minW : 2,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 8, y: newY, w: 4, h: 3,minH : 2 , minW : 2,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]}
        //         ];
        //     } else if (val === '4') {
        //         lay1 = [
        //             ...layout,
        //             { i: uuidv4(), x: 0, y: newY, w: 3, h: 2 ,minH : 2 , minW : 1.5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 3, y: newY, w: 3, h: 2 ,minH : 2 , minW : 1.5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 6, y: newY, w: 3, h: 2 ,minH : 2 , minW : 1.5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 9, y: newY, w: 3, h: 2 ,minH : 2 , minW : 1.5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]}
        //         ];
        //     } else if (val === '5') {
        //         lay1 = [
        //             ...layout,
        //             { i: uuidv4(), x: 0, y: newY, w: 3, h: 1 ,minH : 2 , minW : 1.5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 3, y: newY, w: 3, h: 1 ,minH : 2 , minW : 1.5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 6, y: newY, w: 2, h: 1 ,minH : 2 , minW : 1.5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 8, y: newY, w: 2, h: 1 ,minH : 2 , minW : 1.5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]},
        //             { i: uuidv4(), x: 10, y: newY, w: 2, h: 1 ,minH : 2 , minW : 1.5,
        //                 selected_cln_name : pageNodeInfo.selected_cln_name[0]}
        //         ];
        //     }
        //     state.layoutInfo = lay1;
        //     dispatch(updateLayoutData(lay1,requestInfo))

        // },
        textBlock: (state, action) => {
            const indx = action.payload;
            let newHeader;
            if (indx === '1') {
                newHeader = { i: uuidv4(), x: 0, y: 0, w: 12, h: 1, type: 'text', value: 'Header 1', fontsize: '24px', isResizable: false };
            } else if (indx === '2') {
                newHeader = { i: uuidv4(), x: 0, y: 0, w: 12, h: 0.8, type: 'text', value: 'Header 2', fontsize: '20px', isResizable: false };
            } else if (indx === '3') {
                newHeader = { i: uuidv4(), x: 0, y: 0, w: 12, h: 0.7, type: 'text', value: 'Header 3', fontsize: '18px', isResizable: false };
            } else {
                newHeader = { i: uuidv4(), x: 0, y: 0, w: 12, h: 0.5, type: 'text', value: 'Header 4', fontsize: '14px', isResizable: false };
            }
            const totalHeaderHeight = newHeader.h;
            const updatedLayout = state.layoutInfo.map(item => ({ ...item, y: item.y + totalHeaderHeight }));
            const newLayout = [newHeader, ...updatedLayout];
            state.layoutInfo = newLayout;

        },
        setLayoutId:(state,action)=>{
            state.layoutId = action.payload
        },
        setBreakpoints:(state,action)=>{
            state.breakPoints = action.payload
        },


        //sort functionality


        sortInfo: (state, action) => {
            const { chart_id, sortedData } = action.payload;
            state[chart_id] = sortedData;
        },
        sortLine: (state, action) => {
            const { chart_id, sortedData } = action.payload;

            state[chart_id] = sortedData;
        },

        sortHorstack: (state, action) => {

            const { chart_id, sortedData } = action.payload;
            state[chart_id] = sortedData;
        },
        sortVerticalline: (state, action) => {
            const { chart_id, sortedData } = action.payload;
            state[chart_id] = sortedData;
        },
        sortArea: (state, action) => {
            const { chart_id, sortedData } = action.payload;
            state[chart_id] = sortedData;
        },
        sortBar: (state, action) => {
            const { chart_id, sortedData } = action.payload;
            state[chart_id] = sortedData;
        },
        verticalBar: (state, action) => {
            const { chart_id, sortedData } = action.payload;
            state[chart_id] = sortedData;

        },

        sortFunc: (state, action) => {
            console.log(action.payload,'action.payload')
            const { data, arrValues, chart_id } = action.payload;
            const sortedData = [...data].sort((a, b) => {
                for (const value of arrValues) {
                    if (a[value] !== b[value]) {
                        return a[value] - b[value];
                    }
                }
                return 0;
            });
            state[chart_id] = { ...state[chart_id], linesorted: sortedData, sortedData, horstack: sortedData, vertilinesorted: sortedData, areasorted: sortedData, barsorted: sortedData };
        },
        sortDescending: (state, action) => {
            const { data, arrValues, chart_id } = action.payload;

            const sortedData = [...data].sort((a, b) => {
                for (const value of arrValues) {
                    if (a[value] !== b[value]) {
                        return b[value] - a[value];
                    }
                }
                return 0;
            });
            console.log(action.payload,'action.payload',sortedData)
            state[chart_id] = { ...state[chart_id], linesorted: sortedData, sortedData, horstack: sortedData, vertilinesorted: sortedData, areasorted: sortedData, barsorted: sortedData };
        },

        barSorting: (state, action) => {
            console.log(action.payload,'action.payload')
            const { data, chart_id } = action.payload;
            const sortedData = [...data].sort((a, b) => a.value - b.value);
            console.log(sortedData,'sortedData')
            state[chart_id] = { ...state[chart_id], horbarsorted: sortedData, verticalbarsorted: sortedData };
        },

        barDescending: (state, action) => {
            const { data, chart_id } = action.payload;
            const sortedData = [...data].sort((a, b) => b.value - a.value);
            state[chart_id] = { ...state[chart_id], horbarsorted: sortedData, verticalbarsorted: sortedData };
        },    
    }
})

export const createLayout =(val)=>{

    return async (dispatch,getState)=>{
        const state = getState().reportSliceReducer;
        console.log(state,'state')
        const pageNodeInfo = JSON.parse(sessionStorage.getItem("pageNodeInfo"))
        const layout = [...state.layoutInfo];
        const uniqueArr = _.uniqBy(layout, "y");
        const totalHeight = uniqueArr.reduce((acc, item) => acc + item.h, 0);
        const newY = totalHeight;
        console.log(pageNodeInfo.selected_cln_name[0],'pageNodeInfo.selected_cln_name[0]')
        var lay1
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        const pageInfo = JSON.parse(sessionStorage.getItem('page_data'))
        const requestInfo ={
            dbInfo : authUser.db_info,
            layoutId : state.layoutId,
            pageInfo : pageInfo,
            userInfo : authUser.user_data
    
        }
        
        if (val === '1') {
            lay1 = [
                ...layout,
                { i: uuidv4(), x: 0, y: newY, w: 12, h: 3 ,minH : 2 , minW : 5,
                selected_cln_name : pageNodeInfo.selected_cln_name[0]
            },

            ];
        } else if (val === '2') {
            lay1 = [
                ...layout,
                { i: uuidv4(), x: 0, y: newY, w: 6, h: 3 ,minH : 2 , minW : 5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 6, y: newY, w: 6, h: 3 ,minH : 2 , minW : 5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]}
            ];
        } else if (val === '3') {
            lay1 = [
                ...layout,
                { i: uuidv4(), x: 0, y: newY, w: 4, h: 3,minH : 2 , minW : 2 ,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 4, y: newY, w: 4, h: 3,minH : 2 , minW : 2,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 8, y: newY, w: 4, h: 3,minH : 2 , minW : 2,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]}
            ];
        } else if (val === '4') {
            lay1 = [
                ...layout,
                { i: uuidv4(), x: 0, y: newY, w: 3, h: 2 ,minH : 2 , minW : 1.5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 3, y: newY, w: 3, h: 2 ,minH : 2 , minW : 1.5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 6, y: newY, w: 3, h: 2 ,minH : 2 , minW : 1.5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 9, y: newY, w: 3, h: 2 ,minH : 2 , minW : 1.5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]}
            ];
        } else if (val === '5') {
            lay1 = [
                ...layout,
                { i: uuidv4(), x: 0, y: newY, w: 3, h: 1 ,minH : 2 , minW : 1.5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 3, y: newY, w: 3, h: 1 ,minH : 2 , minW : 1.5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 6, y: newY, w: 2, h: 1 ,minH : 2 , minW : 1.5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 8, y: newY, w: 2, h: 1 ,minH : 2 , minW : 1.5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]},
                { i: uuidv4(), x: 10, y: newY, w: 2, h: 1 ,minH : 2 , minW : 1.5,
                    selected_cln_name : pageNodeInfo.selected_cln_name[0]}
            ];
        }
        console.log(lay1,'lay1')
        // dispatch(updateLayoutInfo(lay1))
        dispatch(updateLayoutData(lay1,requestInfo))




    }





}











export const getReportTemplateList =(pageSize,pageIndex)=>{

    return async dispatch =>{

        try {
            dispatch(setReportRequestLoading(true))
            const data ={
                encrypted_db_url : JSON.parse(sessionStorage.getItem('db_info')).encrypted_db_url,
                created_by :  JSON.parse(sessionStorage.getItem('authUser')).user_data._id,
                // page_size : pageSize,
                // page_index : pageIndex +1
            }
            const responseData = await urlSocket.post("report/find-report-page",data)
            console.log(responseData,'responseData')
            dispatch(setReportRequestLoading(false))
            if(responseData.status === 200){
                dispatch(setReportTemplate(responseData.data.data))
                dispatch(setTotalReportItems(responseData.data.totalItems))
            }
            else{
                dispatch(setReportRequestError(true))
            }

            
        } catch (error) {
                console.log(error,'error')
                dispatch(setReportRequestError(true))
        }
    }

}


export const crudReportPage=(values)=>{

    return async dispatch =>{

        try {
            dispatch(setReportRequestLoading(true))
            const responseData = await urlSocket.post("report/crud-report-page",values)
            dispatch(setReportRequestLoading(false))
            dispatch(setopenModal(false))
            if(responseData.status === 200){
                dispatch(getReportTemplateList())
            }
            else{
                dispatch(setReportRequestError(true))
            }
            
        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }
}


export const getUserList = (authuser, pageInfo) => {

    return async dispatch => {

        try {
            dispatch(setReportRequestLoading(true))
            const data = {
                userInfo: {
                    encrypted_db_url: authuser.db_info.encrypted_db_url,
                    created_by: authuser.user_data._id,
                    company_id: authuser.client_info[0].company_id,
                    template_id: pageInfo._id
                }
            }

            const responseData = await urlSocket.post('webphlbconf/get-all-users-report', data)
            dispatch(setReportRequestLoading(false))
            if (responseData.data.response_code === 500) {
                dispatch(setReportUser(responseData.data.data))
                dispatch(setSelectedReportUser(responseData.data.selected_user))
            }
            else {
                dispatch(setReportRequestError(true))
            }

        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }
}



export const publishSelectedUser = (selectedReportUser,pageInfo,authuser,history) => {

    return async dispatch => {

        try {
            dispatch(setReportRequestLoading(true))
            const data ={
                userList :selectedReportUser,
                template_id : pageInfo._id,
                template_name : pageInfo.name,
                published_by : authuser.user_data._id,
                encrypted_db_url :authuser.db_info.encrypted_db_url
            }
            const responseData = await urlSocket.post("report/publish-report-for-users",data)
            dispatch(setReportRequestLoading(false))
            if(responseData.status === 200){
                history.push("/my-reports")
            }
            else{
                dispatch(setReportRequestError(true))
            }

        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }
}


export const getUserReport=(authUser)=>{

    return async dispatch =>{

        try {
            dispatch(setReportRequestLoading(true))
            const data ={
                user_id :  authUser.user_data._id,
                encrypted_db_url : authUser.db_info.encrypted_db_url
            }

            const responseData = await urlSocket.post("report/get-user-report",data)
            dispatch(setReportRequestLoading(false))
            if(responseData.status === 200){
                dispatch(setUserReport(responseData.data.data))
            }
            else{
                dispatch(setReportRequestError(true))
            }


        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }
}

export const getReportInfo =(authUser,pageInfo)=>{

    return async dispatch =>{

        try {
            dispatch(setReportRequestLoading(true))
            const data ={
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                _id : pageInfo._id,
            }
            const responseData = await urlSocket.post("report/retrive-page-tree",data)
            console.log(responseData,'responsedata')
            dispatch(setReportRequestLoading(false))
            if(responseData.status === 200){
                dispatch(setReportTemplateTree(responseData.data.data[0].tree_info))
            }
            else{
                dispatch(setReportRequestError(true))
            }

            
        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }
}


export const retrivePageLayout =()=>{

    return async dispatch =>{

        try {
            dispatch(setReportRequestLoading(true))
            const authUser= JSON.parse(sessionStorage.getItem("authUser"))
            const nodeInfo = JSON.parse(sessionStorage.getItem("pageNodeInfo"))
            const templateInfo = JSON.parse(sessionStorage.getItem("page_data"))
            const data ={
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                template_id : templateInfo._id,
                created_by : templateInfo.created_by,
                node_id : nodeInfo.id,
                db_name : authUser.db_info.db_name,
                collection_name : nodeInfo.selected_cln_name[0].cln_name
            }
    
            const responseData = await urlSocket.post("report/find-page-layout",data)
            console.log(responseData,'responseData')
            dispatch(setReportRequestLoading(false))
            if(responseData.status === 200 && responseData.data.data.length >0){
                const isDataKeyPresent = _.some(responseData.data.data[0]?.layout, obj => _.has(obj, 'data'));
                console.log(isDataKeyPresent,'isDataKeyPresent')
                if(isDataKeyPresent){
                responseData.data.data[0]?.layout.map((ele,i)=>{
                    console.log(ele,'ele')
                    if (ele.data !== undefined) {
                        dispatch(mathOperation(ele,"1",responseData.data.data[0]?.layout))
                    }

                })
                }
                else{
                    dispatch(updateLayoutInfo(responseData.data.data[0]?.layout))
                }
                dispatch(setLayoutId(responseData.data.data[0]._id === undefined ? "" : responseData.data.data[0]._id))
                console.log(responseData.data.xAxisValues,'responseData.data.xAxisValues')
                let dynamic_data = responseData.data.dynamic_data
                console.log(dynamic_data, 'dynamic_data')
                dispatch(setSelectedCollectionData(dynamic_data))
            }
            else{
                dispatch(setReportRequestError(true))
            }
            
        } catch (error) {
            console.log(error,'error')
            dispatch(setReportRequestError(true))
        }  
    }
}


export const calculateOperation = (data, mode) => {
    if (mode === "1") {
        return d3.rollup(data, v => d3.sum(v, d => d.value), d => d.year);
    }
    if (mode === "2") {
        const avg = d3.mean(data, d => d.value);
        var average = data.map(d => ({ ...d, value: avg }));
        console.log(average, 'aver')
        return average

    }
    if (mode === "3") {
        return d3.rollup(data, v => d3.min(v, d => d.value), d => d.year);
    }
    if (mode === "4") {
        return d3.rollup(data, v => d3.max(v, d => d.value), d => d.year);
    }
    if (mode === "5") {
        return d3.rollup(data, v => v.length, d => d.year);
    }
    if (mode === "6") {
        return d3.rollup(data, v => d3.deviation(v, d => d.value), d => d.year);
    }
    if (mode === "7") {
        return d3.rollup(data, v => d3.variance(v, d => d.value), d => d.year);
    }
    if (mode === "8") {
        return d3.rollup(data, v => d3.median(v, d => d.value), d => d.year);
    }
}

export const mathOperation = (item, value,layout,mode) =>(dispatch)=> {
    console.log(mode,'mode')
    var itemInfo = { ...item };
    var getIdx = _.findIndex(layout, { i: itemInfo.i });
    var updateLayoutInfoData = [...layout];
    if (getIdx !== -1) {
        console.log(itemInfo, 'itemInfo');
        const operationResult = calculateOperation(itemInfo.filtered_data === undefined ? itemInfo.data : itemInfo.filtered_data, value);
        let groupedData;
        if (value === "1" || value === "5" || value === "3" || value === "4" || value === "6" || value === "7" || value == "8") {
            groupedData = Array.from(operationResult, ([year, value]) => ({ year, value }));
        } else if (value === "2") {
            groupedData = operationResult;
        }

        console.log(groupedData, 'groupedData');
        updateLayoutInfoData[getIdx] = {
            ...updateLayoutInfoData[getIdx],
            filtered_data: groupedData,
            selected_operation: Number(value)
        };
        console.log(updateLayoutInfoData,'updateLayoutInfoData')
        dispatch(updateLayoutInfo(updateLayoutInfoData));
    }
}



export const updateLayoutData = (layout, db) => {

    return async dispatch => {
        try {
            dispatch(setReportRequestLoading(true))
            const nodeInfo = JSON.parse(sessionStorage.getItem("pageNodeInfo"))
            const templateInfo = JSON.parse(sessionStorage.getItem("page_data"))

            const data = {
                layout: layout,
                encrypted_db_url: db.dbInfo.encrypted_db_url,
                page_id: db.pageInfo._id,
                page_name: db.pageInfo.name,
                created_by: db.userInfo._id,
                node_id: nodeInfo.id,
                _id: db.layoutId == "" || db.layoutId === undefined ? undefined : db.layoutId,
                template_id: templateInfo._id,
                template_name: templateInfo.name,
                node_type: nodeInfo.type
            }
            const responseData = await urlSocket.post("report/crud-report-layout", data)
            console.log(responseData, 'responseData')
            dispatch(setReportRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(updateLayoutInfo(responseData.data.data.layout))
            }
            else {
                dispatch(setReportRequestError(true))
            }

        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }

}


export const renameKeys = (obj, newKeys) => {
    const keyEntries = Object.entries(obj);
    const renamedObj = keyEntries.reduce((acc, [key, value]) => {
        const newKey = newKeys[key] || key;
        acc[newKey] = value;
        return acc;
    }, {});
    return renamedObj;
};


export const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(obj => Object.values(obj).join(',')).join('\n');
    return header + rows;
}


export const handleDownloadBar = (value,datakeys_name,datakeys,data) => {
    let url;
    datakeys_name
    const newKeys = {
        label: datakeys_name,
        value: datakeys,
    };
    const renamedData = data.map(obj => renameKeys(obj, newKeys));
    if (value === "0") {
        const datas = renamedData.map(obj => {
            const { _id, ...rest } = obj;
            return rest
        })
        const csv = convertToCSV(datas);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'table.csv';
        a.click();
        URL.revokeObjectURL(url);
    }
    else {
        const data1 = data.filter(item => item !== null);
        const data = data1;
        const csv = convertToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'table.csv';
        a.click();
        URL.revokeObjectURL(url);
    }
}



export const imgDownloadPng = async (i) => {
    const chartContainer = document.getElementById(`my_dataviz${i}`);
    if (!chartContainer) {
        return;
    }
    const chartWidth = chartContainer.scrollWidth;
    const chartHeight = chartContainer.offsetHeight;
    const chartCanvas = await html2canvas(chartContainer, {
        width: chartWidth,
        height: chartHeight,
    });
    const pngData = chartCanvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = pngData;
    link.download = "chart.png";
    link.click();
};


export const imgDownloadSvg = (id) => {
    const svgElement = document.getElementById(id)
    if (!svgElement) {
        return;
    }
    const svgString = new XMLSerializer().serializeToString(svgElement);
    if (!svgString) {
        return;
    }
    const blob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "chart.svg";
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
};


export const updateChartData =(chart_data,layoutId)=>{

    return async dispatch =>{

        try {
            dispatch(setReportRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const templateInfo = JSON.parse(sessionStorage.getItem("page_data"))
            const nodeInfo = JSON.parse(sessionStorage.getItem("pageNodeInfo"))

            const data ={
                chart_data: chart_data,
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                layout_id: layoutId ==="" ? undefined :layoutId ,
                template_id: templateInfo._id,
                template_name: templateInfo.name,
                chart_name : chart_data.name,
                node_id : nodeInfo.id
            }

            const responseData = await urlSocket.post("report/updt-chart-data",data)
            console.log(responseData,'responsedata')
            dispatch(setReportRequestLoading(false))
            
        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }

}



export const retrivePieChartvalue=(data,layout,db_data)=>{

    return async dispatch =>{

        try {
            dispatch(setReportRequestLoading(true))
             var blockIdx = Number(sessionStorage.getItem("blockIdx"))
             const responseData = await urlSocket.post("report/retrive-pie-chart-value",data)
            console.log(responseData,'responseData')
             dispatch(setReportRequestLoading(false))
             if(responseData.status === 200){
                layout[blockIdx]["data"]= responseData.data.data[0].data
                dispatch(updateLayoutInfo(layout))
                dispatch(updateLayoutData(layout, db_data));
            }
            else{
                dispatch(setReportRequestError(true))
            }


        } catch (error) {
            dispatch(setReportRequestError(true))
        }

    }
}


export const retriveCardChartValue=(data,layout,selectedCalc,db_data)=>{


    return async dispatch =>{

        try{
            dispatch(setReportRequestLoading(true))
            var blockIdx = Number(sessionStorage.getItem("blockIdx"))
            const responseData = await urlSocket.post("report/retrive-card-chart-value",data)
            dispatch(setReportRequestLoading(false))
            if(responseData.status === 200){
                layout[blockIdx].data = responseData.data.data[0].data
                layout[blockIdx].sum = responseData.data.data[0].sum
                layout[blockIdx].avg = responseData.data.data[0].avg
                layout[blockIdx].minimum = responseData.data.data[0].minimum
                layout[blockIdx].maximum = responseData.data.data[0].maximum
                layout[blockIdx].prefrd_calc = responseData.data.data[0].prefrd_calc
                layout[blockIdx].selected_cln_name = responseData.data.data[0].selected_cln_name
                layout[blockIdx].selected_primary_value = responseData.data.data[0].selected_primary_value
                layout[blockIdx].selected_primary_key = responseData.data.data[0].selected_primary_key
                layout[blockIdx].x_axis_key = responseData.data.data[0].x_axis_key
                if (selectedCalc.name === "SUM") {
                    layout[blockIdx].count = responseData.data.data[0].sum
                }
               else if (selectedCalc.name === "AVG") {
                    layout[blockIdx].count = responseData.data.data[0].avg
                }
                else if (selectedCalc.name === "MIN") {
                    layout[blockIdx].count = responseData.data.data[0].minimum
                }
                else if (selectedCalc.name === "MAX") {
                    layout[blockIdx].count = responseData.data.data[0].maximum
                }
                else{
                    layout[blockIdx].count = responseData.data.data[0].total_count
                }
                dispatch(updateLayoutInfo(layout));
                dispatch(updateLayoutData(layout, db_data));
           }
            else{
                dispatch(setReportRequestError(true))
            }
        }
        catch(error){
            dispatch(setReportRequestError(true))
        }
    }
}


export const retriveClnKeys=(value)=>{

    return async dispatch =>{
 
        try {
            dispatch(setReportRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const data ={
                collection_name: value.cln_name,
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                db_name: authUser.db_info.db_name,
            }
            const responseData = await urlSocket.post("report/retrive-cln-keys",data)
            console.log(responseData,'responseData')
            dispatch(setReportRequestLoading(false))
            return responseData
            
        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }
}


export const retriveClnPrimaryValue =(data)=>{

    return async dispatch =>{

        try {
            dispatch(setReportRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
         
            const responseData = await urlSocket.post("report/retrive-cln-primary-value",data)
            console.log(responseData,'responseData')
            dispatch(setReportRequestLoading(false))
            return responseData

        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }
}


export const hierarchyList =()=>{

    return async dispatch =>{

        const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        const data ={
            userInfo :{
                created_by : authUser.user_data._id,
                company_id : authUser.user_data.company_id,
                encrypted_db_url : authUser.db_info.encrypted_db_url
            }
        }

        try{
            dispatch(setReportRequestLoading(true))
            const responseData = await urlSocket.post("webhstre/gethslist",data)
            console.log(responseData,'responseData')
            dispatch(setReportRequestLoading(false))
            if(responseData.status === 200){
                dispatch(setHierarchyData(responseData.data.data))
            }
            else{
                dispatch(setReportRequestError(true))
            }


        }
        catch(error){

        }
    }
}

export const getHierarchyInfo=(hInfo)=>{

    return async dispatch =>{

        try {
            dispatch(setReportRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const data ={
                info:{
                    _id : hInfo._id,
                    company_id : hInfo.company_id,
                    encrypted_db_url : authUser.db_info.encrypted_db_url
                }
            }

            const responseData = await urlSocket.post("webhstre/gethstructure",data)
            console.log(responseData,'responseData',responseData.data.data.hstructure)
            dispatch(setReportRequestLoading(false))
            if(responseData.status === 200){
                if(responseData.data.data.hstructure.length >0){
                    dispatch(setHierarchTree([responseData.data.data.hstructure[0]]))

                }
            }

            
        } catch (error) {
            
        }

    }

}



export const selectGlobalXaxis = (selectedList) => {

    return async dispatch => {
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        const nodeInfo = JSON.parse(sessionStorage.getItem("pageNodeInfo"))
        const templateInfo = JSON.parse(sessionStorage.getItem("page_data"))

        try {
            dispatch(setReportRequestLoading(true))
            const data = {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                template_id: templateInfo._id,
                created_by: templateInfo.created_by,
                node_id: nodeInfo.id,
                selected_x_axis: _.map(selectedList, '_id')
            }
            console.log(selectedList,'selectedList')
            dispatch(setselectedFilterList(selectedList))
            const responseData = await urlSocket.post('report/global-chart-layout-data', data)
            dispatch(setReportRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(updateLayoutInfo(responseData.data.data))
                dispatch(setGlobalFilter(responseData.data.filter_keys))
            }
            else {
                dispatch(setReportRequestError(true))
            }


        } catch (error) {
            dispatch(setReportRequestError(true))
        }
    }
}







export const {
    setReportRequestLoading,
    setReportTemplate,
    setReportRequestError,
    setopenModal,
    setSelectedReportInfo,
    setTotalReportItems,
    setTotalListCount,
    setPageInfo,
    setReportUser,
    setSelectedReportUser,
    setUserReport,
    setReportTemplateTree,
    setReportTreeNodeInfo,
    setreportTreeData,
    updateLayoutInfo,
    textBlock,
    setBreakpoints,
    sortInfo, sortFunc, sortLine, sortDescending, sortHorstack, sortVerticalline, sortArea, areasorted, horbarsorted, sortBar, barSorting, barDescending, verticalBar, verticalbarsorted,
    setHierarchyData,
    setGlobalFilter,
    setHierarchTree,
    setLayoutId,
    setselectedFilterList,
    setXaxisFilterValue,
    setSlicerValues,
    setSaveData,
    setSelectedCollectionData
    
} = reportSlice.actions






export default reportSlice.reducer