import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"

import moment from 'moment'
import SweetAlert from "react-bootstrap-sweetalert"

// import "flatpickr/dist/themes/material_blue.css"
// import Flatpickr from "react-flatpickr"

const _ = require('lodash')

// var urlSocket = require("../../../helpers/urlSocket")
import urlSocket from "../../../../helpers/urlSocket";


export default class UpdatePdbAuditInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: true,
            target_date: moment(this.props.audit_data.end_date,).format('YYYY-MM-DD'),
            dataloaded: true,

        }
    }

    componentDidMount = async () => {

        var db_info = JSON.parse(sessionStorage.getItem('db_info'))
        this.setState({db_info})
       // this.getadtRevUsers()
       // this.getAllUsers()
    }

    

    updateEndpoint(){
        var endpointData = JSON.parse(sessionStorage.getItem("endpointData"))

        if (String(this.state.target_date).length > 0) {
            this.props.audit_data["end_date"] = this.state.target_date

            try {

                urlSocket.post("webpbdadtdta/updateallendpointdate", {
                    userInfo: this.props.user_data,
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    auditInfo: this.props.audit_data
                })
                    .then(response => {
                        console.log(response)
                        if (response.data.response_code === 500) {
                            this.props.onClose()
                        }
                    })

            } catch (error) {
                console.log("catch error", error)
            }
        }
    }


    render() {
        if (this.state.dataloaded) {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            const tomorrowFormatted = tomorrow.toISOString().split("T")[0];
            return (
                <div>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={12}>
                                <AvForm className="form-horizontal" 
                                // onValidSubmit={this.handleValidUserSubmit} 
                                // onInvalidSubmit={() => this.checkUsersSelected()}
                                >
                                    <div className="p-3">
                                        <Row className="my-3 pb-3" style={{borderBottom:"1px solid #dedede"}}>
                                            <div className="d-sm-flex align-items-center justify-content-between">
                                                <div className=" font-size-14">Update</div>
                                                <button className="btn btn-dark" onClick={() => this.props.onClose()}> Close </button>
                                            </div>
                                        </Row>

                                       

                                        <Row className="">
                                            <div className="mb-3">
                                                <AvField
                                                    name="target_date"
                                                    type="date"
                                                    label="Audit end date"
                                                    errorMessage="Please provide a valid date."
                                                    className="form-control"
                                                    value={this.state.target_date}
                                                    min={tomorrowFormatted}
                                                    onChange={(e) => { this.setState({ target_date: e.target.value }) }}
                                                    onKeyDown={(e)=>{e.preventDefault();}}
                                                    validate={{ required: { value: true } }}
                                                    id="td"
                                                />
                                            </div>


                                        </Row>
                                        <Row>
                                            <hr className="my-4" />
                                            <div className="d-flex flex-row flex-end gap-3">
                                                <button className="btn btn-danger" onClick={() => this.props.onClose()}> Cancel </button>
                                                <button className="btn btn-primary"
                                                 onClick={()=>{this.updateEndpoint()}}>Update</button>
                                            </div>
                                        </Row>
                                    </div>

                                </AvForm>
                            </Col>
                        </Row>
                        {this.state.dataAlert ? (
                            <SweetAlert
                                title={this.state.alertMessage}
                                onConfirm={() => this.setState({ dataAlert: false })}
                            ></SweetAlert>
                        ) : null}
                    </Container>
                </div>
            )
        }
        else {
            return null
        }
    }


}