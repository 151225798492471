import React, { useEffect, useState } from 'react'
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
    FormFeedback
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { createSeverityType, retriveSeverityTypeList, checkSeverityTypeExists,setseverityTypeExist } from '../../../../store/incidentReport/incdreportslice';

const SeverityTypeForm = (props) => {
    const dispatch = useDispatch()
    const incidentReport = useSelector(state => state.incdreportslice)
    const severityTypeExist = incidentReport.severityTypeExist
    const [charCount, setCharCount] = useState(0);
    console.log(incidentReport,'incidentReport')
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            severity_name: incidentReport.editSeverityTypeInfo.severity_name || '',
            severity_id: incidentReport.autoMatedSeverityId,
            severity_descrption: incidentReport.editSeverityTypeInfo.severity_descrption || '',
        },
        validationSchema: Yup.object({
            severity_name: Yup.string().required("Please Enter Your Incident Type")
                .test(async function (value) {
                    return !(await dispatch(checkSeverityTypeExists(value, incidentReport.editSeverityTypeInfo)));
                })
                .matches(/\S+/, "Severity Type cannot be just spaces").min(2, "Severity name must be at least 2 characters")
                ,
            severity_descrption: Yup.string().required("Description is required")
            .matches(/\S+/, "Severity Type cannot be just spaces").min(10, "Description must be at least 10 characters"),
        }),
        onSubmit: (values) => {
            console.log(values, ' Severity values', incidentReport)
            if (Object.keys(incidentReport.editSeverityTypeInfo).length > 0) {
                values["_id"] = incidentReport.editSeverityTypeInfo._id
            }
            if (!severityTypeExist) {
                dispatch(createSeverityType(values))
                props.onClose()
            }

        }
    });




    const handleInputChange=(e)=>{
        const { value } = e.target;
        if(value.length <=200){
            setCharCount(value.length)
            validation.setFieldValue("severity_descrption",value)
        }


    }


    return (
        <React.Fragment>
            <Container fluid>
                <Card >
                    <CardBody className="pt-0">
                        <Row >
                            <Col >
                                <div className="p-2 mb-3">
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row className="my-4">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-primary font-size-18">   {Object.keys(incidentReport.editSeverityTypeInfo).length > 0 ? "Edit" : "Create"}    Severity    </div>
                                                <button className="btn btn-outline-dark " onClick={() =>{ props.onClose();validation.resetForm();dispatch(setseverityTypeExist(false))}}> Close </button>
                                            </div>
                                            <hr className="my-4" />
                                        </Row>
                                        <div className="mb-3">
                                            <Label className="form-label">Severity Name :<label className="text-danger"> *</label></Label>
                                            <Input
                                                name="severity_name"
                                                className="form-control"
                                                placeholder="Enter Severity Name"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.severity_name || ""}
                                                invalid={
                                                    validation.touched.severity_name && validation.errors.severity_name ? true : false
                                                }
                                            />
                                            {validation.touched.severity_name && validation.errors.severity_name ? (
                                                <FormFeedback type="invalid">{validation.errors.severity_name}</FormFeedback>
                                            ) : null}
                                        </div>
                                        {severityTypeExist &&
                                            <div className='text-danger' style={{ fontSize: 'smaller' }}>
                                                Severity type already exist
                                            </div>
                                        }
                                        <div className="mb-3">
                                            <Label className="form-label">Description :<label className="text-danger"> *</label></Label>

                                            <Input
                                                name="severity_descrption"
                                                className="form-control"
                                                placeholder="Enter Description"
                                                type="textarea"
                                                onChange={handleInputChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.severity_descrption || ""}
                                                invalid={
                                                    validation.touched.severity_descrption && validation.errors.severity_descrption ? true : false
                                                }
                                            />
                                            {validation.touched.severity_descrption && validation.errors.severity_descrption ? (
                                                <FormFeedback type="invalid">{validation.errors.severity_descrption}</FormFeedback>
                                            ) : null}
                                            <div className="text-end">
                                                    <small>{charCount} / 200 characters</small>
                                                </div>
                                        </div>
                                        <Row>
                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    type="submit"
                                                >
                                                    {Object.keys(incidentReport.editSeverityTypeInfo).length > 0 ? "Update" : "Submit"}
                                                </button>
                                            </div>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </React.Fragment>
    )
}

export default SeverityTypeForm
