import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import InputType from './InputType';
import OptionsType from './OptionsType';
import TrueFalse from './TrueFalse';
import OptionalComponent from './optionalComponent.js';

const _ = require('lodash')
// var urlSocket = require("../../../../helpers/urlSocket")
import urlSocket from "../../../../helpers/urlSocket";

export default class InputTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: true,
            dataLoaded: false,
            selectedInputValue: "0",
            rows: [],
            rows1: [],
            selectedFiles: [],
            tags: [],
            renderChild: true,
            checkpointStatus:false,
            submitProcess:false,
            imageUploading:false,
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeTextBox = this.handleChangeTextBox.bind(this);
        this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this);

        this.handleChildUnmount = this.handleChildUnmount.bind(this);
        this.getSession()

    }

    componentDidMount = async () => {
        this.getSession()
    }

    getSession = () => {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        let configData = data.config_data
        let config_data = data
        this.setState({
            imagePreviewUrl:data.config_data.img_url, 
            imageToken:data.config_data.img_url,
            checkpointCompliance:configData.checkpoint_compliance,
            config_data:config_data.client_info[0],
            max_video_duration : Number(config_data.client_info[0].max_video_duration.$numberDecimal)
        })


        var getObjectId = 0
       

        if(this.props.mode == "0")
        {
            this.setState({
                configdatacheckpoint: configData.question_type_info.map(obj => ({...obj})),
                cpId:getObjectId,
                dataLoaded: true,
            })   
        }
        else
        {
           var cpk = configData.question_type_info.map(obj => ({...obj}))
                _.each(this.props.checkpointinfo, child=>{
                    _.each(cpk, (item,idx)=>{
                    if(child.checkpoint_type_id == item.checkpoint_type_id)
                    {
                        cpk[idx] = child
                        getObjectId = idx
                    }
                    
                })

            })
            this.setState({
                configdatacheckpoint: cpk,
                cpId:getObjectId,
                selectedInputValue:cpk[getObjectId].checkpoint_type_id,
                checkpoint_type:cpk[getObjectId].checkpoint_type_id,
                dataLoaded: true,
            })        
        }

        

    }

    onChangeParameterType = (event, values) => {
        this.setState({ checkpoint_type:event.target.value, selectedInputValue:event.target.value, cpId:(parseInt(event.target.value) - 1) })
    }

    onSave = (data) => { this.props.submitData(data) }

    handleChildUnmount() {
        this.setState({ renderChild: false });
    }

    showTemplates = (checkpoint_type) => {
        console.log(this.state.configdatacheckpoint,'this.state.configdatacheckpoint')
        switch (checkpoint_type) {

            case "1":
                {
                    return this.state.renderChild ?
                        <OptionalComponent
                            unmountMe={this.handleChildUnmount}
                            checkpointinfo={this.state.configdatacheckpoint[this.state.cpId]}
                            submitprocess={this.state.submitProcess}
                            checkpointCompliance={this.state.checkpointCompliance}
                            edit={true}
                            config_data={this.state.config_data}
                        /> :
                        null
                }
            case "2":
                {
                    return this.state.renderChild ?
                        <OptionalComponent
                            unmountMe={this.handleChildUnmount}
                            checkpointinfo={this.state.configdatacheckpoint[this.state.cpId]}
                            submitprocess={this.state.submitProcess}
                            checkpointCompliance={this.state.checkpointCompliance}
                            edit={true}
                            config_data={this.state.config_data}
                        /> :
                        null
                }
            case "3":
                {
                    return this.state.renderChild ?
                        <OptionalComponent
                            unmountMe={this.handleChildUnmount}
                            checkpointinfo={this.state.configdatacheckpoint[this.state.cpId]}
                            submitprocess={this.state.submitProcess}
                            checkpointCompliance={this.state.checkpointCompliance}
                            edit={true}
                            config_data={this.state.config_data}
                        /> :
                        null
                }
                case "4":
                    {
                        return this.state.renderChild ?
                            <OptionalComponent
                                unmountMe={this.handleChildUnmount}
                                checkpointinfo={this.state.configdatacheckpoint[this.state.cpId]}
                                submitprocess={this.state.submitProcess}
                                checkpointCompliance={this.state.checkpointCompliance}
                                edit={true}
                                config_data={this.state.config_data}
                            /> :
                            null
                    }
                    case "5":
                        {
                            return this.state.renderChild ?
                                <OptionalComponent
                                    unmountMe={this.handleChildUnmount}
                                    checkpointinfo={this.state.configdatacheckpoint[this.state.cpId]}
                                    submitprocess={this.state.submitProcess}
                                    checkpointCompliance={this.state.checkpointCompliance}
                                    edit={true}
                                    config_data={this.state.config_data}
                                /> :
                                null
                        }
    



            default:
                return null

        }
    }



    handleValidSubmit(event, values) {
        this.props.onSubmit(event, values)
    }

    handleAddRow = () => {
        const item = {
            name: "",
        }
        this.setState({
            rows: [...this.state.rows, item],
        })
    }

    handleAddRowNested = () => {
        const item1 = {
            name1: "",
        }
        this.setState({
            rows1: [...this.state.rows1, item1],
        })
    }
    handleRemoveRow = (e, idx) => {
        if (typeof idx != "undefined")
            document.getElementById("addr" + idx).style.display = "none"
    }
    handleRemoveRowNested = (e, idx) => {
        document.getElementById("nested" + idx).style.display = "none"
    }



    handleAcceptedFiles = files => {

        this.setState({imageUploading:true})
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
                uploading:true,
                uploadingStatus:"Uploading"
            })
        )
        var configdatacheckpoint= [...this.state.configdatacheckpoint]
        var selectedFiles =[...this.state.selectedFiles]

        // configdatacheckpoint[this.state.cpId].guideline_image = configdatacheckpoint[this.state.cpId].guideline_image.concat(files)
        this.setState({ selectedFiles: selectedFiles.concat(files) })

        let formData = new FormData();

        for (const key of Object.keys(files)) {
            formData.append('imagesArray', files[key])
        }
        console.log(configdatacheckpoint,'configdatacheckpoint')
        // this.setState({
        //     configdatacheckpoint
        // },()=>{
        try {
            urlSocket.post("storeImage/awswebupload", formData,{
                headers: {
                  'Content-Type': 'multipart/form-data', // Set the Content-Type header
                }},
             {
                onUploadProgress: progressEvent => {
                    if (progressEvent.loaded === progressEvent.total) {
                      //this.progress.current++
                    }
                    // save the individual file's progress percentage in object
                    //this.fileProgress[file.name] = progressEvent.loaded * 100 / progressEvent.total
                    // sum up all file progress percentages to calculate the overall progress
                    //let totalPercent = this.fileProgress ? Object.values(this.fileProgress).reduce((sum, num) => sum + num, 0) : 0
                    // divide the total percentage by the number of files
                    //this.progress.percent = parseInt(Math.round(totalPercent / this.progress.total))
                  }
            }).then(response => {
                console.log(response,'response')
                if(response.data.response_code == 500)
                {
                    configdatacheckpoint[this.state.cpId].guideline_image.push(response.data.data[0])
                    this.setState({
                        configdatacheckpoint
                    },()=>{

                    _.each(response.data.data, item=>{
                        _.each(this.state.configdatacheckpoint[this.state.cpId].guideline_image, child=>{
                            console.log(child,item)
                            if(child.originalname == item.originalname)
                            {
                                let splitString = item.key.split("/");
                                let getFileName = splitString[splitString.length - 1];

                                child["uploading"] = false
                                child["uploadingStatus"] = "Uploaded"
                                child["preview"] = this.state.imagePreviewUrl+getFileName
                            }
                        })
                    })
                    this.setState({imageUploading:false, refresh:true})
                })
                }
                else
                {
                    _.each(this.state.configdatacheckpoint[this.state.cpId].guideline_image, child=>{
                        child["uploading"] = false
                        child["uploadingStatus"] = "Not Uploaded"
                    })
                    this.setState({imageUploading:false, refresh:true})
                }
                
            })
        } catch (error) {
            console.log(error,'error308')
        }
    // })
        
    }














    // handleAcceptedFiles = files => {

    //     this.setState({imageUploading:true})
    //     files.map(file =>
    //         Object.assign(file, {
    //             preview: URL.createObjectURL(file),
    //             formattedSize: this.formatBytes(file.size),
    //             uploading:true,
    //             uploadingStatus:"Uploading"
    //         })
    //     )
    //     const updatedGuidelineImage = [...this.state.configdatacheckpoint[this.state.cpId].guideline_image];
    //     const updatedSelectedFiles = [...this.state.selectedFiles];

    //     // Update the copied data
    //     updatedGuidelineImage.push(...files);
    //     updatedSelectedFiles.push(...files);

    //     // Set the updated data using setState
    //     this.setState({
    //         configdatacheckpoint: {
    //             ...this.state.configdatacheckpoint,
    //             [this.state.cpId]: {
    //                 ...this.state.configdatacheckpoint[this.state.cpId],
    //                 guideline_image: updatedGuidelineImage,
    //             },
    //         },
    //         selectedFiles: updatedSelectedFiles,
    //     });
    //     // this.state.configdatacheckpoint[this.state.cpId].guideline_image = this.state.configdatacheckpoint[this.state.cpId].guideline_image.concat(files)
    //     // this.setState({ selectedFiles: this.state.selectedFiles.concat(files) })

    //     let formData = new FormData();

    //     for (const key of Object.keys(files)) {
    //         formData.append('imagesArray', files[key])
    //     }

    //     try {
    //         urlSocket.post("storeImage/awswebupload", formData, {
    //             onUploadProgress: progressEvent => {
    //                 if (progressEvent.loaded === progressEvent.total) {
    //                   //this.progress.current++
    //                 }
    //                 // save the individual file's progress percentage in object
    //                 //this.fileProgress[file.name] = progressEvent.loaded * 100 / progressEvent.total
    //                 // sum up all file progress percentages to calculate the overall progress
    //                 //let totalPercent = this.fileProgress ? Object.values(this.fileProgress).reduce((sum, num) => sum + num, 0) : 0
    //                 // divide the total percentage by the number of files
    //                 //this.progress.percent = parseInt(Math.round(totalPercent / this.progress.total))
    //               }
    //         }).then(response => {
    //             if(response.data.response_code == 500)
    //             {
    //                 _.each(response.data.data, item=>{
    //                     _.each(this.state.configdatacheckpoint[this.state.cpId].guideline_image, child=>{
    //                         if(child.name == item.originalname)
    //                         {
    //                             let splitString = item.key.split("/");
    //                             let getFileName = splitString[splitString.length - 1];

    //                             child["uploading"] = false
    //                             child["uploadingStatus"] = "Uploaded"
    //                             child["preview"] = this.state.imagePreviewUrl+getFileName
    //                         }
    //                     })
    //                 })
    //                 this.setState({imageUploading:false, refresh:true})
    //             }
    //             else
    //             {
    //                 _.each(this.state.configdatacheckpoint[this.state.cpId].guideline_image, child=>{
    //                     child["uploading"] = false
    //                     child["uploadingStatus"] = "Not Uploaded"
    //                 })
    //                 this.setState({imageUploading:false, refresh:true})
    //             }
                
    //         })
    //     } catch (error) {
    //     }
        
    // }

    deleteImage = id => {
        this.state.configdatacheckpoint[this.state.cpId].guideline_image.splice(id,1)
        this.setState({refresh:true})
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    // handleChangeTextBox(tags) {
    //     // Create a copy of the current state
    //     const updatedConfigDataCheckpoint = { ...this.state.configdatacheckpoint };
      
    //     // Update the custom_tbx property in the copied state
    //     updatedConfigDataCheckpoint[this.state.cpId].custom_tbx = tags;
      
    //     // Update the state with the new state object
    //     this.setState({
    //       configdatacheckpoint: updatedConfigDataCheckpoint,
    //       refresh: true,
    //     });
    //   }

    //   handleChangeType(tags) {
    //     // Declare updatedConfigDataCheckpoint with var
    //     var updatedConfigDataCheckpoint = { ...this.state.configdatacheckpoint };
      
    //     // Update the compl_type property in the copied state
    //     updatedConfigDataCheckpoint[this.state.cpId].compl_type = tags;
      
    //     // Update the state with the new state object
    //     this.setState({
    //       configdatacheckpoint: updatedConfigDataCheckpoint,
    //       refresh: true,
    //     });
    //   }
      

    // handleChangeTextBox(tags) {
    //     this.state.configdatacheckpoint[this.state.cpId].custom_tbx = tags
    //     this.setState({ refresh:true })
    // }

    // handleChangeType(tags) {
    //     this.state.configdatacheckpoint[this.state.cpId].compl_type = tags
    //     this.setState({ refresh:true })
    // }
      

    // handleRadioGroupChange(event){
    //     this.state.configdatacheckpoint[this.state.cpId].impact_level = event.target.name; 
    //     this.setState({refresh:true})
    // }


    handleChangeTextBox(tags) {
        const updatedConfigData = [...this.state.configdatacheckpoint];
        updatedConfigData[this.state.cpId].custom_tbx = tags;
        this.setState({ configdatacheckpoint: updatedConfigData, refresh: true });
      }
      
      handleChangeType(tags) {
        const updatedConfigData = [...this.state.configdatacheckpoint];
        updatedConfigData[this.state.cpId].compl_type = tags;
        this.setState({ configdatacheckpoint: updatedConfigData, refresh: true });
      }
      
      handleRadioGroupChange(event) {
        const updatedConfigData = [...this.state.configdatacheckpoint];
        updatedConfigData[this.state.cpId].impact_level = event.target.name;
        this.setState({ configdatacheckpoint: updatedConfigData, refresh: true });
      }
      


    validateCheckpoint(data){

        this.setState({submitProcess:true})
        var inputMissing = false

        if(data.checkpoint=="")
        {
            inputMissing = true
        }
        console.log(data,'data')

        _.each(data.checkpoint_options, item=>{
            {
                console.log(item,item.no_of_video > this.state.config_data.max_photos)
            }
            if(item.option_text == "")
            {
                inputMissing = true
            }
            if(item.enable_img && (Number.isNaN(item.no_of_img) || item.no_of_img == 0 || (!item.enable_gallery && !item.enable_cam)))
            {
                inputMissing = true
            }
            if(item.enable_video && item.no_of_video > this.state.config_data.max_photos) 
            {
                // console.log(item,'item')
                inputMissing = true
            }
            if(item.enable_video && item.no_of_video === undefined ) 
            {
                item.no_of_video =1
                // console.log(item,'item')
                // inputMissing = true
            }
             if(item.enable_video && (Number.isNaN(item.no_of_video) || item.no_of_video == 0 || (!item.enable_video_gallery && !item.enable_video_cam)))
            {
                inputMissing = true
            }
            if(item.enable_video && (Number.isNaN(item.no_of_video) || item.no_of_video == 0 || (!item.enable_video_gallery && !item.enable_video_cam)))
            {
                inputMissing = true
            }
            if (item.default_video_duration > Number(this.state.max_video_duration)) {
                inputMissing = true
            }
            if(item.enable_doc && item.documents.length == 0)
            {
                inputMissing = true
            }
            if(item.enable_score && Number.isNaN(item.score))
            {
                inputMissing = true
            }
            var getComplianceStatus = _.some(item.compliance, { 'is_selected': true });
            if(!getComplianceStatus)
            {
                inputMissing = true
            }
        })

        if(data.impact_level=="")
        {
            inputMissing = true
        }

        if(!inputMissing && !this.state.imageUploading)
        {
            this.props.onSubmit(data)
            // console.log(data,'data')
        }
    }

    


    render() {
        if (this.state.dataLoaded) {
            // console.log(this.state.configdatacheckpoint,'cnfiguration')
            const { parameter_type_data } = this.state;
            return (
                <div>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={12}>
                            <AvForm className="form-horizontal" >
                                <div className="px-4 py-2">
                                    <Row className="border-bottom border-secondary py-3">
                                          <div className="d-sm-flex align-items-center justify-content-between">
                                                <div className="text-danger font-size-18">Add Check point</div>
                                                <button className="btn btn-outline-dark " onClick={() => ( this.props.onClose())}> Close </button>
                                            </div>
                                    </Row>
                                    <Row className="mt-4">
                                        <div className="mb-4">
                                            <Label className="" htmlFor="autoSizingSelect">Select Check point Type<span className="text-danger">*</span></Label>
                                            <select
                                                type="select"
                                                name="subtitledd"
                                                label="Name"
                                                value={this.state.selectedInputValue}
                                                className="form-select"
                                                id="cat"
                                                onChange={(e) => this.onChangeParameterType(e)}
                                            >
                                                <option value="0" defaultValue="0">Choose...</option>
                                                {
                                                    this.state.configdatacheckpoint.map((data, idx) => {
                                                        return (
                                                            <option value={data.checkpoint_type_id} key={idx}>{data.checkpoint_type}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </Row>
                                    {this.state.selectedInputValue != "0" ?
                                    
                                        <Row>
                                            <div className="mb-3" >
                                                    {/* <AvField
                                                        name="title"
                                                        label="Check point"
                                                        value={this.state.configdatacheckpoint[this.state.cpId].checkpoint}
                                                        onChange={(e) => {
                                                            const updatedConfigDataCheckpoint = { ...this.state.configdatacheckpoint };
                                                            updatedConfigDataCheckpoint[this.state.cpId].checkpoint = e.target.value;

                                                            this.setState({
                                                                configdatacheckpoint: updatedConfigDataCheckpoint,
                                                                refresh: true
                                                            });
                                                        }}
                                                        className="form-control"
                                                        placeholder="Enter Check point"
                                                        type="textarea"
                                                        validate={{
                                                            required: {
                                                                value: this.state.submitProcess && this.state.configdatacheckpoint[this.state.cpId].checkpoint === "" ? true : false
                                                            }
                                                        }}
                                                    /> */}

                                                {/* <AvField
                                                    name="title"
                                                    label="Check point"
                                                    value={this.state.configdatacheckpoint[this.state.cpId].checkpoint}
                                                    onChange={(e) => { this.state.configdatacheckpoint[this.state.cpId].checkpoint = (e.target.value); this.setState({refresh:true}) }}
                                                    className="form-control"
                                                    placeholder="Enter Check point"
                                                    type="textarea"
                                                    validate={{ required: { value: this.state.submitProcess && this.state.configdatacheckpoint[this.state.cpId].checkpoint==""?true:false } }}
                                                /> */}
                                                    <AvField
                                                        name="title"
                                                        label="Check point"
                                                        value={this.state.configdatacheckpoint[this.state.cpId].checkpoint}
                                                        onChange={(e) => {
                                                            const updatedConfigData = [...this.state.configdatacheckpoint];
                                                            updatedConfigData[this.state.cpId].checkpoint = e.target.value;
                                                            this.setState({ configdatacheckpoint: updatedConfigData, refresh: true });
                                                        }}
                                                        className="form-control"
                                                        placeholder="Enter Check point"
                                                        type="textarea"
                                                        validate={{
                                                            required: {
                                                                value: this.state.submitProcess && this.state.configdatacheckpoint[this.state.cpId].checkpoint === "" ? true : false
                                                            }
                                                        }}
                                                    />
                                            </div>
                                        </Row>
                                    :null
                                            }

                                    <Row>
                                        <Col md={12} >
                                            
                                            {
                                                this.showTemplates(this.state.checkpoint_type)
                                            }
                                        </Col>
                                    </Row>
                                    {this.state.selectedInputValue != "0" ?
                                        <Row style={{ padding: 10 }}>
                                            <div className="my-2 d-flex flex-column" style={{ padding: 10, }}>
                                                <label>Add Textbox</label>
                                                <div>
                                                    <TagsInput
                                                        value={this.state.configdatacheckpoint[this.state.cpId].custom_tbx}
                                                        onChange={this.handleChangeTextBox}
                                                        inputProps={
                                                            { placeholder: 'Input textbox name and hit enter' }
                                                        }
                                                    />
                                                </div>

                                            </div>
                                            <div className="my-2 d-flex flex-column" style={{ padding: 10, 
                                             border:this.state.submitProcess && this.state.configdatacheckpoint[this.state.cpId].impact_level == "" ? '1px solid #ff0000': '0px'
                                             }}>
                                                <label>Impact Level</label>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div className="form-check mx-2 form-radio-danger">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="Critical"
                                                            id="exampleRadios1"
                                                            value={this.state.configdatacheckpoint[this.state.cpId].impact_level}
                                                            onChange={this.handleRadioGroupChange}
                                                            checked={this.state.configdatacheckpoint[this.state.cpId].impact_level==="Critical"}
                                                        />
                                                        <label
                                                            className="form-check-label text-danger"
                                                            htmlFor="exampleRadios1"
                                                        >
                                                            Critical
                                                        </label>
                                                    </div>
                                                    <div className="form-check mx-2 form-radio-warning">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="High"
                                                            id="exampleRadios2"
                                                            value={this.state.configdatacheckpoint[this.state.cpId].impact_level}
                                                            onChange={this.handleRadioGroupChange}
                                                            checked={this.state.configdatacheckpoint[this.state.cpId].impact_level==="High"}
                                                        />
                                                        <label
                                                            className="form-check-label text-warning"
                                                            htmlFor="exampleRadios2"
                                                        >
                                                            High
                                                        </label>
                                                    </div>
                                                    <div className="form-check mx-2 form-radio-info">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="Medium"
                                                            id="exampleRadios3"
                                                            value={this.state.configdatacheckpoint[this.state.cpId].impact_level}
                                                            onChange={this.handleRadioGroupChange}
                                                            checked={this.state.configdatacheckpoint[this.state.cpId].impact_level==="Medium"}
                                                        />
                                                        <label
                                                            className="form-check-label text-info"
                                                            htmlFor="exampleRadios3"
                                                        >
                                                            Medium
                                                        </label>
                                                    </div>
                                                    <div className="form-check mx-2 form-radio-success">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="Low"
                                                            id="exampleRadios4"
                                                            value={this.state.configdatacheckpoint[this.state.cpId].impact_level}
                                                            onChange={this.handleRadioGroupChange}
                                                            checked={this.state.configdatacheckpoint[this.state.cpId].impact_level==="Low"}
                                                        />
                                                        <label
                                                            className="form-check-label text-success"
                                                            htmlFor="exampleRadios4"
                                                        >
                                                            Low
                                                        </label>
                                                    </div>
                                                    <div className="form-check mx-2 form-radio-primary">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="No impact"
                                                            id="exampleRadios5"
                                                            value={this.state.configdatacheckpoint[this.state.cpId].impact_level}
                                                            onChange={this.handleRadioGroupChange}
                                                            checked={this.state.configdatacheckpoint[this.state.cpId].impact_level==="No impact"}
                                                        />
                                                        <label
                                                            className="form-check-label text-primary"
                                                            htmlFor="exampleRadios5"
                                                        >
                                                            No impact
                                                        </label>
                                                    </div>
                                                </div>
                                                {this.state.submitProcess && this.state.configdatacheckpoint[this.state.cpId].impact_level == "" ?<div  >
                                                                    <span className="font-size-10 text-danger" style={{lineHeight:1,}}>Select any one impact level </span>
                                                 </div>:null}
                                            </div>

                                            <div className="my-2 d-flex flex-column" style={{ padding: 10, }}>
                                                <label>Type</label>
                                                <div >
                                                    <TagsInput
                                                        value={this.state.configdatacheckpoint[this.state.cpId].compl_type}
                                                        onChange={this.handleChangeType}
                                                        inputProps={
                                                            { placeholder: 'Add a type and hit enter' }
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="my-2 d-flex flex-column" style={{ padding: 10, }}>
                                                <div>
                                                    <label>Remarks</label>
                                                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                                                        <div className="mb-3" >
                                                                {/* <AvField
                                                                    name="cp_remarks"
                                                                    value={this.state.configdatacheckpoint[this.state.cpId].creator_remarks}
                                                                    onChange={(e) => {
                                                                        const updatedConfigDataCheckpoint = { ...this.state.configdatacheckpoint };
                                                                        updatedConfigDataCheckpoint[this.state.cpId].creator_remarks = e.target.value;

                                                                        this.setState({
                                                                            configdatacheckpoint: updatedConfigDataCheckpoint,
                                                                            refresh: true
                                                                        });
                                                                    }}
                                                                    className="form-control"
                                                                    placeholder="Comments about check point"
                                                                    type="textarea"
                                                                    required
                                                                /> */}

                                                                <AvField
                                                                    name="cp_remarks"
                                                                    value={this.state.configdatacheckpoint[this.state.cpId].creator_remarks}
                                                                    onChange={(e) => {
                                                                        const updatedConfigData = [...this.state.configdatacheckpoint];
                                                                        updatedConfigData[this.state.cpId].creator_remarks = e.target.value;
                                                                        this.setState({ configdatacheckpoint: updatedConfigData, refresh: true });
                                                                    }}
                                                                    className="form-control"
                                                                    placeholder="Comments about check point"
                                                                    type="textarea"
                                                                    required
                                                                />

                                                         
                                                         
                                                         
                                                            {/* <AvField
                                                                name="cp_remarks"
                                                                value={this.state.configdatacheckpoint[this.state.cpId].creator_remarks}
                                                                onChange={(e) => { this.state.configdatacheckpoint[this.state.cpId].creator_remarks = (e.target.value); this.setState({refresh:true}) }}
                                                                className="form-control"
                                                                placeholder="Comments about check point"
                                                                type="textarea"
                                                                required
                                                            /> */}
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </div>

                                            <div className="my-2 d-flex flex-column" style={{ padding: 10, }}>
                                                <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                                                    <div className="mb-3" >
                                                            {/* <AvField
                                                                name="guideline"
                                                                label="Guideline"
                                                                value={this.state.configdatacheckpoint[this.state.cpId].guideline}
                                                                onChange={(e) => {
                                                                    const updatedConfigDataCheckpoint = { ...this.state.configdatacheckpoint };
                                                                    updatedConfigDataCheckpoint[this.state.cpId].guideline = e.target.value;

                                                                    this.setState({
                                                                        configdatacheckpoint: updatedConfigDataCheckpoint,
                                                                        refresh: true
                                                                    });
                                                                }}
                                                                className="form-control"
                                                                placeholder="Enter Guideline"
                                                                type="textarea"
                                                                required
                                                            /> */}
                                                            <AvField
                                                                name="guideline"
                                                                label="Guideline"
                                                                value={this.state.configdatacheckpoint[this.state.cpId].guideline}
                                                                onChange={(e) => {
                                                                    const updatedConfigData = [...this.state.configdatacheckpoint];
                                                                    updatedConfigData[this.state.cpId].guideline = e.target.value;
                                                                    this.setState({ configdatacheckpoint: updatedConfigData, refresh: true });
                                                                }}
                                                                className="form-control"
                                                                placeholder="Enter Guideline"
                                                                type="textarea"
                                                                required
                                                            />
                                                        
                                                        
                                                        
                                                        {/* <AvField
                                                            name="guideline"
                                                            label="Guideline"
                                                            value={this.state.configdatacheckpoint[this.state.cpId].guideline}
                                                            onChange={(e) => { this.state.configdatacheckpoint[this.state.cpId].guideline = (e.target.value); this.setState({refresh:true}) }}
                                                            className="form-control"
                                                            placeholder="Enter Guideline"
                                                            type="textarea"
                                                            required
                                                        /> */}
                                                    </div>
                                                </AvForm>
                                                <label>Add images for guidelines</label>
                                                <Form>
                                                    <Dropzone
                                                        onDrop={acceptedFiles =>
                                                            this.handleAcceptedFiles(acceptedFiles)
                                                        }
                                                        accept={[".jpg", ".jpeg", ".png"]}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div className="dropzone">
                                                                <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <div className="mb-3">
                                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                    </div>
                                                                    <h4>Drop files here or click to upload.</h4>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    <div
                                                        className="dropzone-previews mt-3"
                                                        id="file-previews"
                                                    >
                                                        {this.state.configdatacheckpoint[this.state.cpId].guideline_image.map((f, i) => {
                                                            return (
                                                                <Card
                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={i + "-file"}
                                                                >
                                                                    <div style={{display:'flex', flexDirection:'row'}}>
                                                                    <div className="p-2" style={{width:'95%'}}>
                                                                        <Row className="align-items-center">
                                                                            <Col className="col-auto">
                                                                                <img
                                                                                    data-dz-thumbnail=""
                                                                                    height="80"
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    alt={f.name}
                                                                                    src={f.preview}
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <Link
                                                                                    to="#"
                                                                                    className="text-muted font-weight-bold"
                                                                                >
                                                                                    {f.name}
                                                                                </Link>
                                                                                <p className="mb-0">
                                                                                    <strong>{f.formattedSize}</strong>
                                                                                </p>
                                                                            </Col>
                                                                            
                                                                        </Row>
                                                                        <div style={{margin:'5px 0 5px 0'}}>
                                                                            <span className={f.uploading?"font-size-10 text-danger":"font-size-10 text-success"}>{f.uploadingStatus}</span>
                                                                            </div>
                                                                        
                                                                    </div>
                                                                    <div style={{width:'5%', textAlign:'center'}}>
                                                                        <Link to="#" onClick={()=>this.deleteImage(i)}><i className="mdi mdi-close-circle-outline font-size-20 text-danger"/></Link>
                                                                    </div>
                                                                    </div>
                                                                </Card>
                                                            )
                                                        })}
                                                    </div>

                                                </Form>
                                            </div>

                                        </Row> : null
                                    }

                                    {this.state.selectedInputValue != "0" ?
                                        <Row>

                                        </Row> : null
                                    }

                                    {this.state.selectedInputValue != "0" ?
                                        <Row>
                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn btn-danger btn-block"
                                                    type="submit"
                                                    onClick={()=>this.validateCheckpoint(this.state.configdatacheckpoint[this.state.cpId])}
                                                >
                                                    {this.props.mode == "0"?"Submit":"Update"}
                                                </button>
                                            </div>
                                        </Row> : null
                                    }
                                </div>
                                </AvForm>
                            </Col>
                        </Row>
                    </Container>


                </div>
            )
        }
        else {
            return null
        }
    }
}


