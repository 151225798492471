import React, { useState ,useEffect} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'; 
import { UncontrolledDropdown, DropdownToggle,DropdownItem ,DropdownMenu} from 'reactstrap'; 
import { isEmpty, map } from "lodash";
import {  Image } from "antd";
import {emitChanSingleMessage,emitForwardSingleMessage,emitchangeingroupmessage} from '../../helpers/socket'
import Drawer from 'react-drawer';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';




const GroupChatBox = ({
    selectedUser,
    messages,
    userData,
    isSliderOpen,
    selectedContent,
    
}) => {
    const [messageBox, setMessageBox] = useState(null);
    const [EditTimer, setEditTimer] = useState(3);
    const [open, setOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [checkedUsers, setCheckedUsers] = useState([]);
    const [selectedmessage, setselectedmessage] = useState([]);
    const chatData = useSelector((state) => state.chatslice) 
    const recentuserlist = chatData.recentuserlist

    const userList = chatData.userlist
    const filteredUser = _.reject(userList, {_id: userData.user_data._id});
    const ownuser = _.filter (selectedUser?.group_users,{_id  : userData.user_data._id})



    const MessageMediaContent = ({ userMsg }) => {

        return <>{userMsg.message.image && userMsg.message.image[0] && (
            <Image src={"https://d3pnv0bkd16srd.cloudfront.net/followup-uploads/" + userMsg.message.image[0]} className="my-2" alt="" width="120px" style={{ borderRadius: '10px' }} />
        )}
    
            {userMsg.message.attachment && userMsg.message.attachment[0] && (
                <div className="mb-2">
                
                            <a
                                href={"https://d3pnv0bkd16srd.cloudfront.net/followup-uploads/" + userMsg.message.attachment[0].url_name}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-light font-size-12 px-2 py-1 text-dark border border-secondary border-opacity-50"
                                style={{ borderRadius: 20 }}
                            >
                                {userMsg.message.attachment[0].original_file_name.substring(0, 15)} <i className="ms-2 mdi mdi-download" />
                            </a>
                           
    
                </div>
            )}
    
            {userMsg.message.audio && userMsg.message.audio.length > 0 && (
                <div className="">
                    <audio controls controlsList="nodownload" preload="auto" >
                        <source
                            src={"https://d3pnv0bkd16srd.cloudfront.net/followup-uploads/" + userMsg.message.audio}
                            type="audio/mp3"
                        />
                        Your browser does not support the audio tag.
                    </audio>
                </div>
            )}
    
            {userMsg.message.video !== undefined && userMsg.message.video?.[0] && (
                <div className="">
                    <video width="320" height="240" controls controlsList="nodownload">
                        <source
                            src={"https://d3pnv0bkd16srd.cloudfront.net/followup-uploads/" + userMsg.message.video[0]}
                            type="audio/mp3"
                        />
                    </video>
                </div>
            )}
    
        </>
    }
    

    const scrollToBottom = () => {
        if (messageBox) {
            messageBox.scrollTop = messageBox.scrollHeight + 1000;
        }
    };

    useEffect(() => {
        if (!isEmpty(messages)) scrollToBottom();
    }, [messages]);
        

    const deleteMsg = (msg, mode) => {
        var selectedcontent = {
            message: msg,
            mode, mode
        }


        emitchangeingroupmessage(userData,selectedcontent,selectedUser,'')
        // emitChanSingleMessage(userData, selectedcontent, selectedUser, '')
    }









    const forwardMsg = (userMsg) => {
 
        setOpen(true)
        setselectedmessage(userMsg)
    }

    const handleCheckboxChange = (user) => {
        if (checkedUsers.includes(user)) {
            setCheckedUsers(checkedUsers.filter(u => u !== user));
        } else {
            setCheckedUsers([...checkedUsers, user]);
        }
    };






    const handleCloseClick = () => {
        setOpen(false);
        setCheckedUsers([]);
    };


    const handleSendClick = () => {

        emitForwardSingleMessage(checkedUsers,selectedmessage)
        setOpen(false);
        setCheckedUsers([]);
    };




    return (
        <div className="chat-conversation">
            <ul className="list-unstyled mb-0 p-1">
                <PerfectScrollbar
                    style={{ height: isSliderOpen ? "60vh" : "70vh" }}
                    containerRef={(ref) => setMessageBox(ref)}
                >
                    {messages &&
                        messages.map((message, idx) => (
                            <React.Fragment key={idx}>
                                <li>
                                    <div className="position-relative z-index-1 mb-3 mt-2" style={{ textAlign: "center" }}>
                                        <span className="title px-3 py-1 text-primary bg-light bg-opacity-75" style={{ borderRadius: 25 }}>{message.date}</span>
                                    </div>
                                </li>
                                {message.messages.map((userMsg, idx) => (
                                    <>
                                    {
                                        userMsg.message_type === "6" ? ( <li>
                                            <div className="position-relative z-index-1 mb-3 mt-2" style={{ textAlign: "center" }}>
                                                <span className="title px-3 py-1 text-primary bg-light bg-opacity-75" style={{ borderRadius: 25 }}>{userMsg.message.text}</span>
                                            </div>
                                        </li>):(  <li
                                        key={"msg_" + idx}
                                      
                                        className={
                                            userMsg.message_type === "1" ? userMsg.forwarded_from_id === userData.user_data._id
                                                ? "right mb-0 pe-3"
                                                : "mb-0 ps-3"
                                                :
                                                userMsg.group_user_id === userData.user_data._id
                                                    ? "right mb-0 pe-3"
                                                    : "mb-0 ps-3"
                                        }
                                    >
                                        <div className="conversation-list mb-1" style={{ maxWidth: 500 }}>
                                            {
                                            userMsg.message_type !== "3" && ownuser[0].status === "0" &&
                                            <UncontrolledDropdown>
                                                <DropdownToggle href="#" tag="a" className="dropdown-toggle">
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {/* {(new Date() - new Date(message.createdAt)) / (1000 * 60) <= EditTimer && message.sender_id === userData._id && ( */}
                                                        <DropdownItem onClick={(e) => selectedContent(userMsg, "Edit")} href="#">
                                                            Edit
                                                        </DropdownItem>
                                                    {/* // )} */}

                                                    <DropdownItem onClick={(e) => selectedContent(userMsg, "Reply")}>
                                                        Reply
                                                    </DropdownItem>
                                                    <DropdownItem href="#" onClick={(e) => forwardMsg(userMsg)}>
                                                        Forward
                                                    </DropdownItem>

                                                    {userMsg.group_user_id === userData.user_data._id && (
                                                        <DropdownItem onClick={(e) => deleteMsg(userMsg,"Delete")} href="#">
                                                            Delete
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            }

                                            <div className="ctext-wrap p-3">
                                               
                                                            {
                                                                userMsg.message_type === "1" ?
                                                                    (userMsg.group_user_id === userData.user_data._id ?
                                                                        (
                                                                            <div className="text-primary font-size-11" style={{ textAlign: "right" }}>
                                                                                {"You"}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="text-primary font-size-11" style={{ textAlign: "left" }}>
                                                                                {userMsg.forwarded_from}
                                                                            </div>
                                                                        )
                                                                    ) : userMsg.group_user_id === userData.user_data._id ? (
                                                                        <div className="text-primary font-size-11" style={{ textAlign: "right" }}>
                                                                            {"You"}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="text-primary font-size-11" style={{ textAlign: "left" }}>
                                                                            {userMsg.message_from}
                                                                        </div>
                                                                    )
                                                            }

                                                {
                                                    userMsg.message_type !== '2' && userMsg.message_type !== '5' && userMsg.message_type !== '3' &&
                                                    <div className={"mt-2 d-flex flex-row gap-3"}>
                                                        <div>
                                                            <MessageMediaContent
                                                                userMsg={userMsg}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userMsg.message_type === '2' &&
                                                    <div className="mt-2 d-flex flex-row" >
                                                        <div className="bg-secondary px-1 py-2" style={{ borderRadius: "10px 0 0 10px", }}>
                                                            <div className="bg-light p-2" style={{ borderRadius: "0px 10px 10px 0px", maxWidth: "100%" }} >
                                                        <div className="font-size-12 fw-bold  ">{userMsg.message.replied_msg[0].sender_name}</div>
                                                      
                                                        {
                                                            userMsg.message.replied_msg[0].message?.image.length !== 0 && userMsg.message.replied_msg[0].message?.image[0] !== undefined &&
                                                                
                                                            (
                                                                <div className="d-flex align-items-center">
                                                                <div className="mt-2">
                                                                    <img
                                                                        src={"https://d3pnv0bkd16srd.cloudfront.net/followup-uploads/" + userMsg.message.replied_msg[0].message.image[0]}
                                                                        style={{ width: "40px", height: "40px", borderRadius: "5px" }}
                                                                    />
                                                                </div>
                                                                <div className="font-size-12 fw-bold ms-1 me-5 mt-2 text-primary"> <i className="mdi mdi-image mx-1"></i>{"Image"}</div>
                                                            </div>
                                                            )
                                                        }
                                                        {
                                                            userMsg.message.replied_msg !== undefined &&
                                                            userMsg.message.replied_msg[0].message?.attachment &&
                                                            userMsg.message.replied_msg[0].message?.attachment.length !== 0 &&
                                                            <div className="text-dark mt-1" style={{ fontSize: "0.75rem" }} >
                                                                <i className="mdi mdi-file-document-outline"></i>
                                                                {userMsg.message.replied_msg[0].message?.attachment[0].original_file_name}
                                                            </div>
                                                        }
                                                        {
                                                            userMsg.message.replied_msg[0].message.audio &&
                                                            userMsg.message.replied_msg[0].message.audio.length !== 0 &&
                                                            <div>
                                                                <i className=" mdi mdi-microphone-outline"></i>{"Audio"}
                                                            </div>
                                                        }
                                                        {
                                                            userMsg.message.replied_msg[0].message.video &&
                                                            userMsg.message.replied_msg[0].message.video.length !== 0 &&
                                                            <div>
                                                                <i className=" mdi mdi-video"></i>{"video"}
                                                            </div>
                                                        }
                                                         <div className="text-dark mt-1" style={{ fontSize: "0.75rem" }}>{userMsg.message.replied_msg[0].message.text}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                }
                                              

                                                {
                                                    userMsg.message_type === '1' &&
                                                    <p className="mb-1" style={{ textAlign: "left" }}>{userMsg.message.forwared_msg[0].message.text}</p>

                                                }   

                                                
                                                {
                                                    userMsg.message_type !== '3' &&
                                                    <p className="mb-1" style={{ textAlign: "left" }}>{userMsg.message.text}</p>

                                                }
                                                {
                                                    userMsg.message_type === '3' &&
                                                    <div className="d-flex mt-2 mb-2 pe-3" >
                                                        <p className="mb-1" style={{ textAlign: "left" }}>{"This message was deleted"}</p>
                                                    </div>
                                                }
                                                    {userMsg.message_type === "4"?<p className='font-size-10 text-dark'>Edited</p>:(null)}
                                                    {userMsg.message_type === "1"?<p className='font-size-10 text-dark'>forwarded</p>:(null)}

                                                <p className="chat-time mb-0 font-size-10 text-dark">
                                                    {userMsg.updatedAt ? (
                                                        new Date(userMsg.updatedAt).toLocaleTimeString("en-US", {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            hour12: true,
                                                        })
                                                    ) : (
                                                        new Date().toLocaleTimeString("en-US", {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            hour12: true,
                                                        })
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </li>)
                                    }
                                   
                                    
                                  
                                    </>
                                ))}
                            </React.Fragment>
                        ))}
                </PerfectScrollbar>
            </ul>


            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                position='right'
            >
                <div style={{ width: 'auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', borderBottom: '1px solid #ccc' }}>
                        <h6>Forward To</h6>
                        <div
                            className={`rounded-circle avatar-xs bg-danger`}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
                            onClick={() => handleCloseClick()}
                        >
                            <i className="mdi mdi-close text-white" />
                        </div>
                    </div>
                    {
                        checkedUsers.length !== 0 ?
                            <div className='my-3 mx-3'>
                                <div className="d-flex justify-content-end mb-3">
                                    <div className="me-auto">
                                        Checked Users: {checkedUsers.length}
                                    </div>
                                    <div className="ms-2">
                                        <button className="btn btn-primary rounded-circle d-flex justify-content-center align-items-center" onClick={handleSendClick}>
                                            <FontAwesomeIcon icon={faPaperPlane} />
                                        </button>
                                    </div>
                                </div>
                            </div> : (null)}

                    <div className='my-3 mx-3 '>
                        <h6>Recents</h6>
                        {recentuserlist.map((user, index) => (
                            <div className="d-flex flex-column mb-2 p-2 bg-light" key={"tu" + index} style={{ borderRadius: 25 }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-row align-items-center">
                                        <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={checkedUsers.includes(user)}
                                            onChange={() => handleCheckboxChange(user)}
                                        />
                                        <div
                                            className={`rounded-circle avatar-xs me-2 bg-success text-dark bg-opacity-25`}
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
                                        >
                                            {user.sender_name.charAt(0).toUpperCase()}
                                        </div>
                                        <div className="d-flex flex-column">
                                            <div className="font-size-13 text-dark">{user.sender_name}</div>
                                            <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='my-3 mx-3 '>
                        <h6>Contacts</h6>
                        {filteredUser.map((user, index) => (
                            <div className="d-flex flex-column mb-2 p-2 bg-light" key={"tu" + index} style={{ borderRadius: 25 }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-row align-items-center">
                                        <input
                                            type="checkbox"
                                            className="me-2"
                                            checked={checkedUsers.includes(user)}
                                            onChange={() => handleCheckboxChange(user)}
                                        />
                                        <div
                                            className={`rounded-circle avatar-xs me-2 bg-success text-dark bg-opacity-25`}
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
                                        >
                                            {user.firstname.charAt(0).toUpperCase()}
                                        </div>
                                        <div className="d-flex flex-column">
                                            <div className="font-size-13 text-dark">{user.firstname}</div>
                                            <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default GroupChatBox;
