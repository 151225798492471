import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    Card,
    CardBody,
    Col,
    DropdownMenu,
    DropdownToggle,
    Form,
    Row,
    UncontrolledDropdown,
    Collapse,
    UncontrolledAlert,
    Button,
    Alert,
    Label,
    Input,
    DropdownItem,
    Table,
    Tooltip
} from "reactstrap"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import _ from 'lodash'
import Select from 'react-select';


// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
class CrudNode extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invalid_title:false
        };
    }

    componentDidMount() {
        // console.log("reusable component")
    }

    validateNodeName = (e) => {
        console.log(e.target.value, this.props.node)
        if (this.props.node.children !== undefined) {
            if(this.props.node.children.length >0){
            var validate_node = this.props.node.children.filter((e) => e.node_level == (this.props.node.node_level + 1))
            // console.log(validate_node,'validate_node')

            const formattedNameToValidate = e.target.value.replace(/\s/g, '').toLowerCase();

            const isNameValid = validate_node.some((name) =>
                name.title.replace(/\s/g, '').toLowerCase() === formattedNameToValidate
            );
            //   console.log(isNameValid,'isNameValid')
            this.setState({ invalid_title: isNameValid })
        }
        else{
            const filter_same_lvl_data = this.props.flatData.filter(item =>
                item.node_level === this.props.node.node_level && item.title !== this.props.node.title
              );
              
            console.log(filter_same_lvl_data, 'filter_same_lvl_data')

            const formattedNameToValidate = e.target.value.replace(/\s/g, '').toLowerCase();

            const isNameValid = filter_same_lvl_data.some((name) =>
                name.title.replace(/\s/g, '').toLowerCase() === formattedNameToValidate
            );
            this.setState({ invalid_title: isNameValid })
        }
        }
        else {
            //  var filter_same_lvl_data = _.filter(this.props.flatData, { node_level: this.props.node.node_level , title : !this.props.node.title })
        
            const filter_same_lvl_data = this.props.flatData.filter(item =>
                item.node_level === this.props.node.node_level && item.title !== this.props.node.title
              );
              
            console.log(filter_same_lvl_data, 'filter_same_lvl_data')

            const formattedNameToValidate = e.target.value.replace(/\s/g, '').toLowerCase();

            const isNameValid = filter_same_lvl_data.some((name) =>
                name.title.replace(/\s/g, '').toLowerCase() === formattedNameToValidate
            );
            this.setState({ invalid_title: isNameValid })
        }

        // filter_same_lvl_data.map((ele,idx)=>{
        //     if(ele.title.includes(e.target.value)){
        //         console.log("same name")
        //     }
        // })
       
            // const regex = new RegExp('^' + e.target.value + '$', 'i');

        // // Use Array.prototype.some() with the regular expression
        // const isNameValid = validate_node.some(item => regex.test(item.title));
        // console.log(isNameValid,'valid')
         // new RegExp('^' + values[0] + '$', 'i')
    }

    render() {
        const { optionGroup, value, onChange } = this.props;

        const options = optionGroup.map((data, idx) => ({
            label: (
                <div>
                    {data}
                    <i className="mdi mdi-pencil font-size-12 text-primary me-5" ></i>
                </div>
            ),
            value: data,
        }));
        // console.log(this.props,'this.porps')
       // console.log(this.props, 'get props data',this.props.validate_node)
        return(
         <div>
            <DropdownMenu className="" style={{ minWidth: "350px", zIndex: 2, padding: 10 }}>
                <div className="px-4" style={{ overflow: 'auto' }}>
                    <div className="mt-1" style={{ display: 'inline-flex' }}>Add Node Under</div>
                    <div className="font-size-13 text-primary mt-2 px-2 py-0" style={{ display: 'inline-flex' }}  >{this.props.title}</div>
                    <div className="font-size-11 text-dark">{this.props.subtitle}</div>

                    <AvForm className="form-horizontal" onValidSubmit={this.props.edit_cat_name === true ? this.props.saveAllnode : this.props.crudNode} onInvalidSubmit={this.props.handleInvalidSubmit}>
                        <div className="my-2">
                            <AvField
                                name="title"
                                label="Name"
                                type="text"
                                value={this.props.getTitle}
                                onChange={(e)=>{this.validateNodeName(e)}}
                                required
                                placeholder="Enter Name"
                            />
                        </div>
                        {
                            this.state.invalid_title &&
                            <div style={{fontSize :'smaller'}} className='text-danger'>Title name should not same for same level of node</div>
                        }
                        <div className="my-2">

                            <Label className="" htmlFor="autoSizingSelect">Category</Label>
                            {
                                this.props.edit_node ?
                                // <AvInput
                                //     type="select"
                                //     name="subtitledd"
                                //     label="Name"
                                //     value={this.props.node.category_input == true ? this.props.node.subtitle : this.props.getSubTitledd}
                                //     className="form-select"
                                //     id="cat"
                                //     // required
                                //     onChange={(e) => this.props.selectCat(e)}>
                                //     <option value="0" defaultValue>Choose...</option>
                                //     <option value="1" selected >Create New</option>
                                //     {
                                //       this.props.optionGroup?.map((data, idx) => {
                                //         return (
                                //           <option value={data} selected key={idx} className="option-with-icon">{data}</option>
                                //         )
                                //       })
                                //     }
                                //   </AvInput> 

                                        <Select
                                            options={options}
                                            value={options.find((option) => option.value === this.props.node.subtitle)}
                                            onChange={(e) => this.props.editCat(e)}
                                        />
                                    :
                                    <AvInput
                                    type="select"
                                    name="subtitledd"
                                    label="Name"
                                    value={this.props.getSubTitledd}
                                    className="form-select"
                                    id="cat"
                                    // required
                                    onChange={(e) => this.props.selectCat(e)}>
                                    <option value="0" defaultValue>Choose...</option>
                                    <option value="1" selected >Create New</option>
                                    {
                                      this.props.optionGroup?.map((data, idx) => {
                                        return (
                                          <option value={data} selected key={idx}>{data}</option>
                                        )
                                      })
                                    }
                                  </AvInput> 

                                     // <AvInput
                                    //     name="subtitledd"
                                    //     label="Name"
                                    //     type="text"
                                    //     value={this.props.subtitle}
                                    //     // value={}
                                    //     // value={this.props.getSubTitle}
                                    //     // value={"Level " + (this.props.node.node_level + 1)}
                                    //     // disabled={true}
                                    //     id="cat"
                                    //     required
                                    //     placeholder="Enter Category">

                                    // </AvInput>

                                    // <AvInput
                                    //     name="subtitledd"
                                    //     label="Name"
                                    //     type="text"
                                    //     // value={this.props.main_level == true ? "Level 1" : Object.keys(this.props.node).length > 0 ? "Level " + (this.props.node.node_level + 2) : null}
                                    //     // disabled={true}
                                    //     id="cat"
                                    //     required
                                    //     placeholder="Enter Category">

                                    // </AvInput>
                            }
                            <div className='my-2'>
                            {
                                this.props.edit_cat_name &&
                                    <AvInput
                                        name="subtitledd"
                                        label="Name"
                                        type="text"
                                        value={this.props.subtitle}
                                        // value={}
                                        // value={this.props.getSubTitle}
                                        // value={"Level " + (this.props.node.node_level + 1)}
                                        // disabled={true}
                                        id="cat"
                                        required
                                        placeholder="Enter Category">

                                    </AvInput>
                          
                          
                          }
                        </div>




                        </div>
                        
                        {
                            this.props.createNewCat ?
                                <div className="my-2">
                                    <AvField
                                        name="subtitle"
                                        type="text"
                                        value={this.props.getSubTitle}
                                        // required
                                        placeholder="Enter New Category"
                                    />
                                </div> : null
                        }
                        {
                            this.props.catError !=="" &&
                        <Label className="text-danger" style={{ margin: '0 0 7px 0' }} htmlFor="autoSizingSelect">{this.props.catError}</Label>
                    }
                        {this.props.catErrorEnabled ? <Label className="text-danger" style={{ margin: '0 0 7px 0' }} htmlFor="autoSizingSelect">{this.props.catError}</Label> : null}
                        {
                            this.props.enableAddCode ? <div className="form-check">
                                <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="invalidCheck"
                                    onChange={() => this.props.Oncancel("checkbox")}
                                />
                                <Label
                                    className="form-check-label"
                                    htmlFor="invalidCheck"
                                >{" "}
                                    Add Code
                                </Label>
                            </div> : null
                        }

                        {
                            this.props.enableCode ?
                                <div className="mb-3">
                                    <AvField
                                        name="code"
                                        label="Code"
                                        type="text"
                                        value={this.props.getCode}
                                        validate={{
                                            required: { value: true, errorMessage: this.props.codeError },
                                        }}
                                        placeholder="Enter Code"
                                    />
                                    {this.props.codeErrorEnabled ? <Label className="text-danger" style={{ margin: '5px 0 7px 0' }} htmlFor="autoSizingSelect">{"Code is already available"}</Label> : null}

                                </div> : null
                        }



                        <div className="my-2 d-flex flex-row">
                            <button
                                className="btn btn-success btn-block m-1"
                                disabled={this.state.invalid_title}
                                type="submit"
                            >
                                {this.props.crudStatus == 1 ? "Add" : this.props.crudStatus == 2 ? "Update" : "Add Main Level"}
                            </button>
                            {/* {
                                this.props.createNewCat &&
                                <button className="btn btn-primary btn-block m-1" onClick={(e) => { this.props.selectCat(e, true) }}>
                                    Remove Category Input
                                </button>
                            } */}
                            <button
                                className="btn btn-danger btn-block m-1"
                                type="submit"
                                onClick={() => { this.props.Oncancel() }}
                            >
                                Cancel
                            </button>
                        </div>
                    </AvForm>

                </div>


            </DropdownMenu>

        </div>
        )
    }
}

CrudNode.propTypes = propTypes;
CrudNode.defaultProps = defaultProps;
// #endregion

export default CrudNode;