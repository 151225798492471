import { dispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import urlSocket from '../../../helpers/urlSocket'
import { retriveClnPrimaryValue, setSaveData, setSlicerValues, updateLayoutInfo, updateLayoutData,mathOperation } from '../../../store/reportd3/reportslice'
import { useDispatch, useSelector } from 'react-redux'
// import { useSelector } from 'react-redux'
import { Multiselect } from 'multiselect-react-dropdown';
import { _ } from 'core-js'

const Slicer = (props) => {
    const dispatch = useDispatch()
    const reportSlice = useSelector(state => state.reportSliceReducer)
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem('authUser')))
    const [selectedValues, setSelectedValues] = useState([])
    console.log(reportSlice, 'reportSlice')

    useEffect(() => {
        console.log(props.data, 'props.data')

    }, [props.data, dispatch]);


    const updateSlicerInfoRecursively = async (updatedLayout, counter, currentIdx, filterKey, filterValue,selectedVal) => {
        let layoutCopy = JSON.parse(JSON.stringify(updatedLayout));
        console.log(filterValue, 'filterValue', filterKey)
        if (layoutCopy.length > counter) {
            if (layoutCopy[counter]["chart_name"] === "slicer" && (currentIdx !== counter)) {
                if (layoutCopy[counter]["selected_filter"] === undefined) {
                    layoutCopy[counter]["selected_filter"] = []
                    layoutCopy[counter]["selected_filter"].push({
                        filterKey: filterKey,
                        filterValue: filterValue
                    })
                }
                else {
                    if (filterValue.length === 0) {

                        var getIdx = _.findIndex(layoutCopy[counter]["selected_filter"], { filterKey: filterKey })
                        console.log(getIdx, 'getIdx')
                        if (getIdx !== -1) {
                            layoutCopy[counter]["selected_filter"].splice(getIdx, 1);
                        }
                    }
                    else {
                        var getIdx = _.findIndex(layoutCopy[counter]["selected_filter"], { filterKey: filterKey })
                        console.log(getIdx, 'getIdx')
                        if (getIdx === -1) {
                            layoutCopy[counter]["selected_filter"].push({
                                filterKey: filterKey,
                                filterValue: filterValue
                            })
                        }
                        else {
                            layoutCopy[counter]["selected_filter"][getIdx] = {
                                filterKey: filterKey,
                                filterValue: filterValue
                            }
                        }
                    }
                }

                var filteredInfo = _.filter(reportSlice.selectedCollectionData, item => _.includes([selectedVal], item[filterKey]));
                // var filteredInfo = _.filter(reportSlice.selectedCollectionData, item =>
                //     _.every(layoutCopy[counter]["selected_filter"], filter => _.includes(filter.filterValue, item[filter.filterKey]))
                // );
                var updateData = filteredInfo.map(ele => ({ value: ele[layoutCopy[counter]["name"]], _id: ele._id }));
                const uniqueNamesArray = _.uniqBy(updateData, "value").map(item => ({ ...item, is_checked: true }));;
                console.log(uniqueNamesArray, 'filteredInfo', filteredInfo);
                layoutCopy[counter].filtered_slicer_values = uniqueNamesArray;
                console.log(layoutCopy[counter].slicer_values, 'layoutCopy[counter]')
                counter++;
                await updateSlicerInfoRecursively(layoutCopy, counter, currentIdx, filterKey, filterValue,selectedVal);
            }
            if (layoutCopy[counter]["chart_name"] === "slicer" && (currentIdx === counter)) {

                if(layoutCopy[counter]["layout_filter"] === undefined){
                    layoutCopy[counter]["layout_filter"] = []
                    layoutCopy[counter]["layout_filter"].push({
                        filterKey: filterKey,
                        filterValue: selectedVal
                    })
                }
                else{
                    layoutCopy[counter]["layout_filter"][0]["filterValue"]=selectedVal
                }

                if (layoutCopy[counter]["selected_filter"] === undefined) {
                    layoutCopy[counter]["selected_filter"] = []
                    layoutCopy[counter]["selected_filter"].push({
                        filterKey: filterKey,
                        filterValue: [selectedVal]
                    })
                }
                else {
                    if (filterValue.length === 0) {
                        var getIdx = _.findIndex(layoutCopy[counter]["selected_filter"], { filterKey: filterKey })
                        console.log(getIdx, 'getIdx')
                        if (getIdx !== -1) {
                            layoutCopy[counter]["selected_filter"].splice(getIdx, 1);
                        }
                        if (layoutCopy[counter]["selected_filter"].length === 0) {
                            var filteredInfo = _.filter(reportSlice.selectedCollectionData, item => _.includes([selectedVal], item[filterKey]));

                            var updateData = filteredInfo.map(ele => ({ value: ele[layoutCopy[counter]["name"]], _id: ele._id }));
                            const uniqueNamesArray = _.uniqBy(updateData, "value");
                            console.log(uniqueNamesArray, 'filteredInfo', filteredInfo);
                            layoutCopy[counter].filtered_slicer_values = uniqueNamesArray;
                            console.log(console.log(layoutCopy[counter].slicer_values, 'layoutCopy[counter]'), 'console.log(layoutCopy[counter].slicer_values,172')
                        }
                    }
                    else {
                        var getIdx = _.findIndex(layoutCopy[counter]["selected_filter"], { filterKey: filterKey })
                        console.log(getIdx, 'getIdx')
                        if (getIdx === -1) {
                            layoutCopy[counter]["selected_filter"].push({
                                filterKey: filterKey,
                                filterValue: filterValue
                            })
                        }
                        else {
                            layoutCopy[counter]["selected_filter"][getIdx] = {
                                filterKey: filterKey,
                                filterValue: [selectedVal]
                            }
                        }
                    }
                }
            }
            if (layoutCopy[counter].x_axis_key !== undefined && layoutCopy[counter].y_axis_key !== undefined) {
                var getSelectedFilterIdx = _.findIndex(layoutCopy, { chart_name: "slicer" })
                console.log(getSelectedFilterIdx, 'getSelectedFilterIdx')
                var filteredInfo = _.filter(reportSlice.selectedCollectionData, item =>
                    _.every(layoutCopy[getSelectedFilterIdx]["selected_filter"], filter => _.includes(filter.filterValue, item[filter.filterKey]))
                );
                console.log(filteredInfo, 'filteredInfo', layoutCopy[counter]["selected_filter"])
                var updatedData = filteredInfo.map(ele => {
                    const yValue = ele[layoutCopy[counter].y_axis_key.name];
                    console.log(`Value before parseFloat:`, yValue);
                    const numericString = (typeof yValue === 'object' && yValue.$numberDecimal) ? yValue.$numberDecimal : yValue;
                    const parsedValue = isNaN(parseFloat(numericString)) ? 0 : parseFloat(numericString);

                    return {
                        year: ele[layoutCopy[counter].x_axis_key.name],
                        _id: ele._id,
                        value: parsedValue
                    };
                });
                console.log(updatedData, 'updatedData')
                const uniqueNamesArray = _.uniqBy(updatedData, "year");
                layoutCopy[counter].filtered_data = updatedData;
                console.log(uniqueNamesArray, 'updatedData');

                counter++;
                await updateSlicerInfoRecursively(layoutCopy, counter, currentIdx, filterKey, filterValue,selectedVal);

            } else {
                counter++;
                await updateSlicerInfoRecursively(layoutCopy, counter, currentIdx, filterKey, filterValue,selectedVal);
            }
        }
        else {
            console.log("alll over", updatedLayout)
            updatedLayout.map((ele,idx)=>{
                if(ele.data !== undefined){
                    dispatch(mathOperation(ele,"1",updatedLayout))
                }
            })
        }
    };






    const selectList = (event, item) => {
        var selectedCollectionData = reportSlice.selectedCollectionData
        console.log('selectedCollectionData', selectedCollectionData)
        var itemInfo = { ...item }
        console.log(itemInfo,'itemInfo')
        itemInfo["is_checked"] = event.target.checked

        var updatedInfo = [...selectedValues]
        if (event.target.checked) {
            updatedInfo.push(itemInfo)
        }
        else {
            var getIdx = _.findIndex(updatedInfo, { _id: itemInfo._id })
            console.log(getIdx, 'getIdx')
            if (getIdx !== -1) {
                updatedInfo = [
                    ...updatedInfo.slice(0, getIdx),
                    ...updatedInfo.slice(getIdx + 1)
                ];
            }
        }
        const layout = [...reportSlice.layoutInfo]
        var getIdx = _.findIndex(layout, { i: props.data.i })
        console.log(getIdx, 'getIdx')
        setSelectedValues(updatedInfo)
        var filterValue = _.map(updatedInfo, 'value')
        updateSlicerInfoRecursively(layout, 0, getIdx, props.data.name, filterValue,itemInfo.value)


    }



    return (
        <div>
            <label>Selected Key :{props.data.name}</label>
            <div className="mb-2 mb-md-0">
                {
                    console.log(props.data,'props.data')
                }
                {/* <Multiselect
                        onRemove={(selectedList, selectedItem) => {
                            console.log(selectedList, selectedItem,'selectedList, selectedItem')
                        }}
                        onSelect={(selectedList, selectedItem) => {
                            multiSelectFilter(selectedList, selectedItem)
                        }}
                        options={props.data.slicer_values}
                        displayValue="value"
                        closeOnSelect={false}
                    /> */}
                {
                    props.data.filtered_slicer_values !== undefined ?
                        props.data.filtered_slicer_values.map((ele, pos) => {
                            return (
                                <div key={pos}>
                                    <input type='radio'
                                        // checked={props.data?.layout_filter[0]?.["filterValue"] === ele.value}
                                        checked={props.data.layout_filter !== undefined ? props.data.layout_filter[0].filterValue === ele.value ? true : false : false}
                                        onChange={(e) => { selectList(e, ele) }}
                                        className='me-2'
                                    />{ele.value}
                                </div>
                            )
                        })
                        :
                        props.data?.slicer_values?.map((ele, indx) => {
                            return (
                                <div key={indx}>
                                    <input type='radio'
                                        onChange={(e) => { selectList(e, ele) }}
                                        checked={props.data.layout_filter !== undefined ? props.data.layout_filter[0].filterValue === ele.value ? true : false : false}
                                        className='me-2'
                                    />{ele.value}
                                </div>
                            )
                        })
                }
                {/* {
                    props.data?.slicer_values?.map((ele, indx) => {
                        return (
                            <div key={indx}>
                                <input type='checkbox'
                                    onChange={(e) => { selectList(e, ele) }}
                                    className='me-2'
                                />{ele.value}
                            </div>
                        )
                    })
                } */}


            </div>

        </div>
    )
}

export default Slicer



































//multi selection slicer
// import { dispatch } from 'react-redux'
// import React, { useEffect, useState } from 'react'
// import urlSocket from '../../../helpers/urlSocket'
// import { retriveClnPrimaryValue,setSaveData, setSlicerValues,updateLayoutInfo,updateLayoutData } from '../../../store/reportd3/reportslice'
// import { useDispatch, useSelector } from 'react-redux'
// // import { useSelector } from 'react-redux'
// import { Multiselect } from 'multiselect-react-dropdown';
// import { _ } from 'core-js'

// const Slicer = (props) => {
//     const dispatch = useDispatch()
//     const reportSlice = useSelector(state => state.reportSliceReducer)
//     const [authUser,setauthUser] = useState(JSON.parse(sessionStorage.getItem('authUser')))
//     const [selectedValues,setSelectedValues] = useState([])
//     console.log(reportSlice, 'reportSlice')

//     useEffect(() => {
//         console.log(props.data,'props.data')
     

//         // if(props.data.chart_name === "slicer" && props.data.slicer_values === undefined){
//         //     dispatch(setSaveData(false))
//         // const layoutInfo = [...reportSlice.layoutInfo]
//         //     dispatch(updateLayoutData(layoutInfo, props.requestInfo));
//         // }

//     }, [props.data, dispatch]);

//     const multiSelectFilter = async(selectedList, selectedItem) => {
//         console.log(selectedList, selectedItem, 'selectedList, selectedItem')
//         const templateInfo = JSON.parse(sessionStorage.getItem("page_data"))
//         const nodeInfo = JSON.parse(sessionStorage.getItem("pageNodeInfo"))
//         var getIdx = _.findIndex(reportSlice.layoutInfo,{i : props.data.i})
//         console.log(getIdx,'getIdx')
//         try {
//             const responseData = await urlSocket.post("report/slicer-filter-values",{
//                 selectedList : selectedList,
//                 encrypted_db_url : authUser.db_info.encrypted_db_url,
//                 template_id : templateInfo._id,
//                 node_id : nodeInfo.id,
//                 selected_cln_name : nodeInfo.selected_cln_name[0].cln_name,
//                 db_name : authUser.db_info.db_name,
//                 filterKey : props.data.name,
//                 layoutIdx : getIdx
//             })

//             console.log(responseData,'responseData')
//             if(responseData.status === 200){
//                 dispatch(updateLayoutInfo(responseData.data.data))
//             }
            
//         } catch (error) {
//                 console.log(error,'error')
//         }
//     }


//     //  const updateSlicerInfoRecursively=async(updatedLayout,counter,currentIdx,filterKey,filterValue)=>{

//     //     let layoutCopy = JSON.parse(JSON.stringify(updatedLayout));


//     //     if(layoutCopy.length > counter){

//     //         if (layoutCopy[counter]["chart_name"] === "slicer" && (currentIdx !== counter)) {
//     //                 console.log(layoutCopy,counter,currentIdx,filterKey,filterValue,'updatedLayoutInfo,counter,currentIdx,filterKey,filterValue')
//     //                var filteredInfo = _.filter(reportSlice.selectedCollectionData, item => _.includes(filterValue, item[filterKey]));
//     //                 var updateData = filteredInfo.map(ele => ({ value: ele[layoutCopy[counter]["name"]] , _id: ele._id }));
//     //                 const uniqueNamesArray = _.uniqBy(updateData,"value");
//     //                 console.log(uniqueNamesArray,'filteredInfo')
//     //                 layoutCopy[counter].slicer_values = uniqueNamesArray
//     //                 counter ++
//     //                   await updateSlicerInfoRecursively(layoutCopy,counter,currentIdx,filterKey,filterValue)
//     //          }
//     //          if(layoutCopy[counter].x_axis_key !== undefined && layoutCopy[counter].y_axis_key !== undefined){
//     //             var filteredInfo = _.filter(reportSlice.selectedCollectionData, item => _.includes(filterValue, item[filterKey]));
//     //             var updatedData = filteredInfo.map(ele => ({ year: ele[layoutCopy[counter].x_axis_key.name] , _id: ele._id , value: parseFloat(ele[layoutCopy[counter].y_axis_key.name])   }));
//     //             console.log(updatedData,'updatedData')
//     //             const uniqueNamesArray = _.uniqBy(updateData,"year");
//     //             layoutCopy[counter].data = uniqueNamesArray

//     //          }
//     //         else{
//     //             counter ++
//     //             await updateSlicerInfoRecursively(layoutCopy,counter,currentIdx,filterKey,filterValue)
//     //         }

//     //    }
//     //    else{
//     //     console.log("alll over",updatedLayout)
//     //    }
//     //  }



//     const updateSlicerInfoRecursively = async (updatedLayout, counter, currentIdx, filterKey, filterValue) => {
//         let layoutCopy = JSON.parse(JSON.stringify(updatedLayout));
//         console.log(filterValue,'filterValue',filterKey)
//         if (layoutCopy.length > counter) {
//             if (layoutCopy[counter]["chart_name"] === "slicer" && (currentIdx !== counter)) {
//                 if(layoutCopy[counter]["selected_filter"] === undefined){
//                     layoutCopy[counter]["selected_filter"] =[]
//                     layoutCopy[counter]["selected_filter"].push({
//                         filterKey : filterKey,
//                         filterValue : filterValue
//                     })
//                 }
//                 else {
//                     if (filterValue.length === 0) {

//                         var getIdx = _.findIndex(layoutCopy[counter]["selected_filter"], { filterKey: filterKey })
//                         console.log(getIdx, 'getIdx')
//                         if (getIdx !== -1) {
//                             layoutCopy[counter]["selected_filter"].splice(getIdx, 1);
//                         }
//                     }
//                     else {
//                         var getIdx = _.findIndex(layoutCopy[counter]["selected_filter"], { filterKey: filterKey })
//                         console.log(getIdx, 'getIdx')
//                         if (getIdx === -1) {
//                             layoutCopy[counter]["selected_filter"].push({
//                                 filterKey: filterKey,
//                                 filterValue: filterValue
//                             })
//                         }
//                         else {
//                             layoutCopy[counter]["selected_filter"][getIdx] = {
//                                 filterKey: filterKey,
//                                 filterValue: filterValue
//                             }
//                         }
//                     }
//                 }
//                 console.log(layoutCopy, counter, currentIdx, filterKey, filterValue, 'layoutCopy, counter, currentIdx, filterKey, filterValue');

//                 var filteredInfo = _.filter(reportSlice.selectedCollectionData, item => 
//                     _.every(layoutCopy[counter]["selected_filter"], filter => _.includes(filter.filterValue, item[filter.filterKey]))
//                 );
//                 var updateData = filteredInfo.map(ele => ({ value: ele[layoutCopy[counter]["name"]], _id: ele._id }));
//                 const uniqueNamesArray = _.uniqBy(updateData, "value").map(item => ({ ...item, is_checked: true }));;
//                 console.log(uniqueNamesArray, 'filteredInfo',filteredInfo);
//                 layoutCopy[counter].filtered_slicer_values = uniqueNamesArray; 
//                 console.log(layoutCopy[counter].slicer_values,'layoutCopy[counter]')
//                 counter++;
//                 await updateSlicerInfoRecursively(layoutCopy, counter, currentIdx, filterKey, filterValue);
//             }
//             if(layoutCopy[counter]["chart_name"] === "slicer" && (currentIdx === counter)){
//                 if(layoutCopy[counter]["selected_filter"] === undefined){
//                     layoutCopy[counter]["selected_filter"] =[]
//                     layoutCopy[counter]["selected_filter"].push({
//                         filterKey : filterKey,
//                         filterValue : filterValue
//                     })
//                 }
//                 else{

//                     if (filterValue.length === 0) {

//                         var getIdx = _.findIndex(layoutCopy[counter]["selected_filter"], { filterKey: filterKey })
//                         console.log(getIdx, 'getIdx')
//                         if (getIdx !== -1) {
//                             layoutCopy[counter]["selected_filter"].splice(getIdx, 1);
//                         }
//                         if(layoutCopy[counter]["selected_filter"].length === 0){
//                             var filteredInfo = _.filter(reportSlice.selectedCollectionData, item => 
//                                 _.every(layoutCopy[counter]["selected_filter"], filter => _.includes(filter.filterValue, item[filter.filterKey]))
//                             );
//                             var updateData = filteredInfo.map(ele => ({ value: ele[layoutCopy[counter]["name"]], _id: ele._id }));
//                             const uniqueNamesArray = _.uniqBy(updateData, "value");
//                             console.log(uniqueNamesArray, 'filteredInfo',filteredInfo);
//                             layoutCopy[counter].filtered_slicer_values = uniqueNamesArray; 
//                             console.log(console.log(layoutCopy[counter].slicer_values,'layoutCopy[counter]'),'console.log(layoutCopy[counter].slicer_values,172')
//                         }


//                     }
//                     else {
//                         var getIdx = _.findIndex(layoutCopy[counter]["selected_filter"], { filterKey: filterKey })
//                         console.log(getIdx, 'getIdx')
//                         if (getIdx === -1) {
//                             layoutCopy[counter]["selected_filter"].push({
//                                 filterKey: filterKey,
//                                 filterValue: filterValue
//                             })
//                         }
//                         else {
//                             layoutCopy[counter]["selected_filter"][getIdx] = {
//                                 filterKey: filterKey,
//                                 filterValue: filterValue
//                             }
//                         }
//                     }
//                 }
//                 // counter++;
//                 // await updateSlicerInfoRecursively(layoutCopy, counter, currentIdx, filterKey, filterValue);
//            }
//             if (layoutCopy[counter].x_axis_key !== undefined && layoutCopy[counter].y_axis_key !== undefined) {
//                 var getSelectedFilterIdx = _.findIndex(layoutCopy,{chart_name :"slicer"})
//                 console.log(getSelectedFilterIdx,'getSelectedFilterIdx')
//                 var filteredInfo = _.filter(reportSlice.selectedCollectionData, item => 
//                     _.every(layoutCopy[getSelectedFilterIdx]["selected_filter"], filter => _.includes(filter.filterValue, item[filter.filterKey]))
//                 );
//                 console.log(filteredInfo,'filteredInfo',layoutCopy[counter]["selected_filter"])
//                 var updatedData = filteredInfo.map(ele => {
//                     const yValue = ele[layoutCopy[counter].y_axis_key.name];
//                     console.log(`Value before parseFloat:`, yValue);
//                     const numericString = (typeof yValue === 'object' && yValue.$numberDecimal) ? yValue.$numberDecimal : yValue;
//                     const parsedValue = isNaN(parseFloat(numericString)) ? 0 : parseFloat(numericString);

//                     return {
//                         year: ele[layoutCopy[counter].x_axis_key.name],
//                         _id: ele._id,
//                         value: parsedValue
//                     };
//                 });
//                 console.log(updatedData,'updatedData')
//                 const uniqueNamesArray = _.uniqBy(updatedData, "year");
//                 layoutCopy[counter].filtered_data = updatedData;
//                 console.log(uniqueNamesArray, 'updatedData');

//                 counter++;
//                 await updateSlicerInfoRecursively(layoutCopy, counter, currentIdx, filterKey, filterValue);

//             } else {
//                 counter++;
//                 await updateSlicerInfoRecursively(layoutCopy, counter, currentIdx, filterKey, filterValue);
//             }
//         }
//         else {
//             console.log("alll over", updatedLayout)
//             dispatch(updateLayoutInfo(updatedLayout))
//         }
//     };






//     const selectList = (event, item) => {
//         var selectedCollectionData = reportSlice.selectedCollectionData
//         console.log('selectedCollectionData', selectedCollectionData)
//         var itemInfo = { ...item }
//         itemInfo["is_checked"] = event.target.checked

//         var updatedInfo = [...selectedValues]
//         if (event.target.checked) {
//             updatedInfo.push(itemInfo)
//         }
//         else {
//             var getIdx = _.findIndex(updatedInfo, { _id: itemInfo._id })
//             console.log(getIdx,'getIdx')
//             if (getIdx !== -1) {
//                 updatedInfo = [
//                     ...updatedInfo.slice(0, getIdx),
//                     ...updatedInfo.slice(getIdx + 1)
//                 ];
//             }

//         }
//         const layout =[...reportSlice.layoutInfo]
//         var getIdx = _.findIndex(layout,{i : props.data.i})
//         console.log(getIdx,'getIdx')
//         setSelectedValues(updatedInfo)
//         var filterValue = _.map(updatedInfo,'value')
//         updateSlicerInfoRecursively(layout,0,getIdx,props.data.name,filterValue)

       
//     }



//     return (
//         <div>
//                     <label>Selected Key :{props.data.name}</label>
//                 <div className="mb-2 mb-md-0">
//                     {/* <Multiselect
//                         onRemove={(selectedList, selectedItem) => {
//                             console.log(selectedList, selectedItem,'selectedList, selectedItem')
//                         }}
//                         onSelect={(selectedList, selectedItem) => {
//                             multiSelectFilter(selectedList, selectedItem)
//                         }}
//                         options={props.data.slicer_values}
//                         displayValue="value"
//                         closeOnSelect={false}
//                     /> */}
//                     {
//                         console.log(props.data)
//                     }

//                     {
//                         props.data.filtered_slicer_values !== undefined ?
//                         props.data.filtered_slicer_values.map((ele,pos)=>{
//                             return (
//                                 <div key={pos}>
//                                 <input  type='checkbox' 
//                                 onChange={(e)=>{selectList(e,ele)}}
//                                 className='me-2'
//                                 />{ele.value}
//                                 </div>
//                             )
//                         })
//                         :
//                         props.data?.slicer_values?.map((ele,indx)=>{
//                             return (
//                                 <div key={indx}>
//                                 <input  type='checkbox' 
//                                 onChange={(e)=>{selectList(e,ele)}}
//                                 className='me-2'
//                                 />{ele.value}
//                                 </div>
//                             )
//                         })
//                     }
//                 {/* {
//                     props.data?.slicer_values?.map((ele, indx) => {
//                         return (
//                             <div key={indx}>
//                                 <input type='checkbox'
//                                     onChange={(e) => { selectList(e, ele) }}
//                                     className='me-2'
//                                 />{ele.value}
//                             </div>
//                         )
//                     })
//                 } */}


//                 </div>
      
//     </div>
//   )
// }

// export default Slicer
