
import React, { useRef, useEffect } from 'react';
import * as markerjs2 from 'markerjs2';

const MarkerImage = (props) => {
    const imgRef = useRef(null);

    useEffect(() => {
        console.log('imgRef', imgRef)
        setTimeout(() => {
        showMarkerArea()            
        }, 1000);
    }, [imgRef])

    const showMarkerArea = () => {
        console.log('save16',props)
        if (imgRef.current !== null) {
            const markerArea = new markerjs2.MarkerArea(imgRef.current);
            markerArea.uiStyleSettings.redoButtonVisible = true;
            markerArea.uiStyleSettings.notesButtonVisible = true;
            markerArea.uiStyleSettings.zoomButtonVisible = true;
            markerArea.uiStyleSettings.zoomOutButtonVisible = true;
            markerArea.uiStyleSettings.clearButtonVisible = true;
            markerArea.settings.displayMode = "popup";
    
            markerArea.uiStyleSettings.width = "50vh"; // Adjust as needed
            markerArea.styles.width = "50vh"; // Adjust as needed
            markerArea.uiStyleSettings.zIndex = 2000;
    
            let maState= props.markers? props.markers :'';      
            console.log('dataUrl', imgRef.current.src, maState)

            markerArea.addEventListener('render', async event => {
                if (imgRef.current) {
                    console.log(event.dataUrl,'event.dataUrl',event,event.state)
                    const response = await fetch(event.dataUrl);
                    console.log(response,'response')
                    const blob = await response.blob();
            
                    const originalFile = new File([blob], 'filename.png', { type: 'image/png' });
                    const previewBlob = new Blob([event.dataUrl], { type: 'image/png' });
                    const fileWithPreview = new File([originalFile, previewBlob],'filename-with-preview.png', { type: 'image/png' });
                    props.onUploadMarkerFile(fileWithPreview,event);
                }                
            });
    
            markerArea.show();
            if (maState) {
                markerArea.restoreState(maState);
            }
            markerArea.addEventListener('close', () => {
                props.onCancelModal()
            });

            document.addEventListener('keydown', function (event) {
                console.log(event,'event')
                if (event.key === 'Enter' &&  event.target.classList.contains('dz-message')) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            });
        }
    };
    

    return (
    <div >
        <img crossOrigin='anonymous' ref={imgRef} src={props.preview_url} alt="Preview" style={{ width: '100%'}} />
    </div>
    )
}


export default MarkerImage;














// import React, { useRef, useEffect } from 'react';
// import * as markerjs2 from 'markerjs2';
// import auditvista_logo from "../../../../assets/images/bg-auth-overlay3.png";

// const MyMarkerComponent = () => {
//   const imgRef = useRef(null);

//   useEffect(() => {
//   console.log('imgRef', imgRef)
//   }, [imgRef])

//   const showMarkerArea = () => {
//     if (imgRef.current !== null) {
//         const markerArea = new markerjs2.MarkerArea(imgRef.current);
//         console.log('markerArea', markerArea)
//         // Enable marker options
//         markerArea.uiStyleSettings.redoButtonVisible = true;
//         markerArea.uiStyleSettings.notesButtonVisible = true;
//         markerArea.uiStyleSettings.zoomButtonVisible = true;
//         markerArea.uiStyleSettings.zoomOutButtonVisible = true;
//         markerArea.uiStyleSettings.clearButtonVisible = true;
        
//         // Set display mode
//         markerArea.settings.displayMode = "popup";

//         // Set dimensions and other styles
//         markerArea.uiStyleSettings.width = "50vh"; // Adjust as needed
//         markerArea.uiStyleSettings.zIndex = 2000;

//         // Add event listener for render event
//         let maState;
//         markerArea.addEventListener('render', event => {
//             if (imgRef.current) {
//                 imgRef.current.src = event.dataUrl;
//                 maState = event.state;
//                 console.log(event.state, ' event.state');
//             }
//         });

//         // Show marker area
//         markerArea.show();

//         // Restore state if available
//         if (maState) {
//             markerArea.restoreState(maState);
//         }
//     }
// };


// //   const showMarkerArea = () => {
// //     if (imgRef.current !== null) {
// //       const markerArea = new markerjs2.MarkerArea(imgRef.current);
// //       console.log(markerArea,'markerArea')
// //       markerArea.uiStyleSettings.redoButtonVisible = true;
// //       markerArea.uiStyleSettings.notesButtonVisible = true;
// //       markerArea.uiStyleSettings.zoomButtonVisible = true;
// //       markerArea.uiStyleSettings.zoomOutButtonVisible = true;
// //       markerArea.uiStyleSettings.clearButtonVisible = true;
// //       markerArea.settings.displayMode = "popup"
// //     //   markerArea.uiStyleSettings.width ="50%"
// //     //   markerArea.uiStyleSettings.height ="100%"
// //       markerArea.uiStyleSettings.zIndex =2000


// //     //   markerArea.style.postion = "relative !important"

// //       var maState
// //       markerArea.addEventListener('render', event => {
// //         if (imgRef.current) {
// //           imgRef.current.src = event.dataUrl;
// //           maState = event.state;
// //           console.log( event.state,' event.state')
// //         }
// //       });
// //       console.log('dataUrl', imgRef.current.src, maState)
// //       markerArea.show();
// //       if (maState) {
// //         markerArea.restoreState(maState);
// //       }
// //     }
// //   };

//   return (
//     <div >
//       <h1>marker.js 2 Demo</h1>
//       <img
//         ref={imgRef}
//         src={auditvista_logo}
//         alt="sample"
//         style={{ width: '50%' }}
//         onClick={showMarkerArea}
//       />
//     </div>
//   );
// };

// export default MyMarkerComponent;












// import React, { useRef, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { Button, Modal } from "reactstrap";
// import * as markerjs2 from 'markerjs2';
// import 'markerjs2/dist/markerjs.css'; // Import CSS for markerjs2
// import auditvista_logo from "../../../../assets/images/bg-auth-overlay3.png";

// const MarkerImage = (props) => {
//     const imgRef = useRef(null);
//     const [maState, setMaState] = useState(null);

//     useEffect(() => {
//         console.log('imgRef', imgRef);
//     }, [imgRef]);

//     const showMarkerArea = () => {
//         if (imgRef.current !== null) {
//           const markerArea = new markerjs2.MarkerArea(imgRef.current);
//           console.log(markerArea,'markerArea')
//           markerArea.uiStyleSettings.redoButtonVisible = true;
//           markerArea.uiStyleSettings.notesButtonVisible = true;
//           markerArea.uiStyleSettings.zoomButtonVisible = true;
//           markerArea.uiStyleSettings.zoomOutButtonVisible = true;
//           markerArea.uiStyleSettings.clearButtonVisible = true;
//           var maState
//           markerArea.addEventListener('render', event => {
//             if (imgRef.current) {
//               imgRef.current.src = event.dataUrl;
//               maState = event.state;
//               console.log( event.state,' event.state')
//             }
//           });
//           console.log('dataUrl', imgRef.current.src, maState)
//           markerArea.show();
//           if (maState) {
//             markerArea.restoreState(maState);
//           }
//         }
//       };
    

//     return (
//         <div>
//             {/* <Modal isOpen={props.preview_image_modal} size='md'> */}
//                 <div className="modal-header">
//                     <h5 className="modal-title">Mark Image</h5>
//                 </div>
//                 <div className="modal-body">
//                     <img ref={imgRef} src={auditvista_logo} alt="Preview" className='mt-4' style={{ width: '100%' }} onClick={showMarkerArea} />
//                 </div>
//                 <div className="modal-footer">
//                     <Button color="primary" onClick={() => { props.onCacelModal() }}>Cancel</Button>
//                 </div>
//             {/* </Modal> */}
//         </div>
//     );
// }

// MarkerImage.propTypes = {
//     preview_image_modal: PropTypes.bool.isRequired,
//     preview_url: PropTypes.string.isRequired
// };

// export default MarkerImage;













// import React, { useRef, useEffect } from 'react';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';
// import {
//     Button,
//     Col,
//     Form,
//     Media,
//     Row,
//     UncontrolledDropdown,
//     Dropdown,
//     DropdownItem,
//     DropdownMenu,
//     DropdownToggle,
//     Card, CardBody,
//     CardText,
//     CardTitle,
//     Modal
// } from "reactstrap"
// import * as markerjs2 from 'markerjs2';

// // #region constants

// // #endregion

// // #region styled-components

// // #endregion

// // #region functions

// // #endregion

// // #region component
// const propTypes = {};

// const defaultProps = {};

// /**
//  * 
//  */
// const MarkerImage = (props) => {
//     const imgRef = useRef(null);

//     useEffect(() => {
//         console.log('imgRef', imgRef)
//         setTimeout(() => {
//         showMarkerArea()            
//         }, 1000);
//     }, [imgRef])

//     const showMarkerArea = () => {
//         if (imgRef.current !== null) {
//             const markerArea = new markerjs2.MarkerArea(imgRef.current);
//             console.log('markerArea', markerArea)
//             // Enable marker options
//             markerArea.uiStyleSettings.redoButtonVisible = true;
//             markerArea.uiStyleSettings.notesButtonVisible = true;
//             markerArea.uiStyleSettings.zoomButtonVisible = true;
//             markerArea.uiStyleSettings.zoomOutButtonVisible = true;
//             markerArea.uiStyleSettings.clearButtonVisible = true;
            
//             // Set display mode
//             markerArea.settings.displayMode = "popup";
    
//             // Set dimensions and other styles
//             markerArea.uiStyleSettings.width = "50vh"; // Adjust as needed
//             markerArea.styles.width = "50vh"; // Adjust as needed
//             markerArea.uiStyleSettings.zIndex = 2000;
    
//             // Add event listener for render event
//             let maState;
//             // markerArea.addEventListener('render', event => {
//             //     if (imgRef.current) {
//             //         imgRef.current.src = event.dataUrl;
//             //         maState = event.state;
//             //         console.log('event.state', imgRef.current.src);
//             //     }
//             // });
//             markerArea.addEventListener('render', async event => {
//                 if (imgRef.current) {
//                     const response = await fetch(event.dataUrl);
//                     const blob = await response.blob();
            
//                     const originalFile = new File([blob], 'filename.png', { type: 'image/png' });
//                     const previewBlob = new Blob([event.dataUrl], { type: 'image/png' });
//                     const fileWithPreview = new File([originalFile, previewBlob], 'filename-with-preview.png', { type: 'image/png' });
//                     props.onUploadMarkerFile(fileWithPreview);
//                 }

//                 // if (imgRef.current) {

//                 //     const response = await fetch(event.dataUrl);
//                 //     const blob = await response.blob();
            
//                 //     // Create a new File object from the blob data
//                 //     const file = new File([blob], 'filename.png', { type: 'image/png' });
            
//                 //     // Pass the File object to the onUploadMarkerFile function
//                 //     props.onUploadMarkerFile(file);


//                 //     // const response = await fetch(event.dataUrl);
//                 //     // const blob = await response.blob();
//                 //     // const blobUrl = URL.createObjectURL(blob);
//                 //     // imgRef.current.src = blobUrl;
//                 //     // maState = event.state;
//                 //     // console.log('event.state', blobUrl);
//                 //     // props.onUploadMarkerFile(blobUrl)
//                 // }
//             });
    
//             // Show marker area
//             markerArea.show();
//             console.log('dataUrl', imgRef.current.src, maState)
//             // Restore state if available
//             if (maState) {
//                 markerArea.restoreState(maState);
//             }
//         }
//     };
    


//     // const showMarkerArea = () => {
//     //     console.log('showMarkerArea called....', imgRef.current)
//     //     if (imgRef.current !== null) {
//     //         const markerArea = new markerjs2.MarkerArea(imgRef.current);
//     //         console.log(markerArea, 'markerArea')
//     //         markerArea.uiStyleSettings.redoButtonVisible = true;
//     //         markerArea.uiStyleSettings.notesButtonVisible = true;
//     //         markerArea.uiStyleSettings.zoomButtonVisible = true;
//     //         markerArea.uiStyleSettings.zoomOutButtonVisible = true;
//     //         markerArea.uiStyleSettings.clearButtonVisible = true;
//     //         var maState
//     //         markerArea.addEventListener('render', event => {
//     //             if (imgRef.current) {
//     //                 imgRef.current.src = event.dataUrl;
//     //                 maState = event.state;
//     //                 console.log(event.state, ' event.state')
//     //             }
//     //         });
//     //         console.log('dataUrl', imgRef.current.src)
//     //         markerArea.show();
//     //         if (maState) {
//     //             markerArea.restoreState(maState);
//     //         }
//     //     }
//     // };

//     return (
//     <div>      
//                 <img ref={imgRef} src={props.preview_url} alt="Preview" style={{ width: '100%' }} onClick={showMarkerArea} />

//     </div>
//     )
// }

// MarkerImage.propTypes = propTypes;
// MarkerImage.defaultProps = defaultProps;
// // #endregion

// export default MarkerImage;