import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const TypeMultiStackedBarChart = ({
  compliantCount,
  nonCompliantCount,
  partiallyCompliantCount,
  notApplicableCount,

  complaint_type_array,
  non_complaint_type_array,
  partially_compliant_type_array,
  not_applicable_type_array
}) => {
  const chartRef = useRef();

  const margin = { top: 20, right: 150, bottom: 50, left: 20 };
  const width = 1000 - margin.left - margin.right;
  const height = 400 - margin.top - margin.bottom;



  useEffect(() => {
    const svg = d3.select(chartRef.current);

    // Function to get ordered keys
    const getOrderedKeys = (dataArray) => {
      const keysSet = new Set();
      dataArray.forEach(categoryData => {
        Object.keys(categoryData).forEach(key => {
          // Exclude 'category' and 'count' keys
          if (key !== 'category' && key !== 'count') {
            keysSet.add(key);
          }
        });
      });

      // Convert the Set to an array and order it based on desiredOrder
      const orderedKeys = Array.from(keysSet).sort((a, b) => {
        return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
      });

      return orderedKeys;
    };

    const updateData = (dataArray, complaintArray, nonComplaintArray, partiallyComplaintArray, not_applicable_type_array) => {
      const updateCategoryData = (categoryData, typeArray) => {
        for (let j = 0; j < typeArray.length; j++) {
          const complaintType = typeArray[j];
          const typeName = complaintType.type_name.toLowerCase();
          categoryData[typeName] = complaintType.count;
        }
      };

      for (let i = 0; i < dataArray.length; i++) {
        const categoryData = dataArray[i];

        switch (categoryData.category) {
          case 'Compliant':
            updateCategoryData(categoryData, complaintArray);
            break;
          case 'Non Compliant':
            updateCategoryData(categoryData, nonComplaintArray);
            break;
          case 'Partially Compliant':
            updateCategoryData(categoryData, partiallyComplaintArray);
            break;
          // Add more cases if needed for other categories
          default:
          case 'Not Applicable':
            updateCategoryData(categoryData, not_applicable_type_array);
            break;
          // Add more cases if needed for other categories


        }
      }
    };

    const data = [
      // {
      //   category: 'Compliant',
      //   count: 9,
      // },
      // {
      //   category: 'Non Compliant',
      //   count: 13,
      // },
      // {
      //   category: 'Partially Compliant',
      //   count: 11,
      // },
      {
        category: 'Compliant',
        count: compliantCount,
      },
      {
        category: 'Non Compliant',
        count: nonCompliantCount,
      },
      {
        category: 'Partially Compliant',
        count: partiallyCompliantCount,
      },
      {
        category: 'Not Applicable',
        count: notApplicableCount,
      },
    ];
    updateData(data, complaint_type_array, non_complaint_type_array, partially_compliant_type_array, not_applicable_type_array);

    const totalByCategory = data.map(d => d.count);


    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.category))
      .range([margin.left, width + margin.left])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(totalByCategory)])
      .range([height + margin.top, margin.top]);

    // Dynamically generate domain for scaleOrdinal
    // Function to generate a random color
    // const getRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    const getRandomColor = () => {
      const component = () => Math.floor(Math.random() * 100 + 150); // Adjust the range for lighter colors
      return `rgb(${component()}, ${component()}, ${component()})`;
    };


    // Dynamically generate keys for stacking
    const domain = Array.from(new Set(data.flatMap(d => Object.keys(d).filter(key => key !== 'category' && key !== 'count'))));
    const colorScale = d3
      .scaleOrdinal()
      .domain(domain)
      // .range(['#50a5f1', '#34c38f', 'rgb(85, 110, 230)']);
      .range(domain.map(() => getRandomColor()));

    // Dynamically generate keys for stacking
    const desiredOrder = domain.map(item => item.charAt(0).toUpperCase() + item.slice(1));
    const stackedData = d3.stack().keys(getOrderedKeys(data))(data);
    svg.selectAll('.bar-group')
      .data(stackedData)
      .enter().append('g')
      .attr('class', 'bar-group')
      .attr('fill', d => colorScale(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
      .attr('x', d => xScale(d.data.category))
      .attr('y', d => yScale(d[1]))
      .attr('height', d => yScale(d[0]) - yScale(d[1]))
      .attr('width', xScale.bandwidth())
      .on('mouseover', function (event, d) {
        const [x, y] = d3.pointer(event);
        d3.select(this).style('opacity', 0.7);
        showTooltip(d.key, d.data[d.key], x + 10, y - 10);
      })
      .on('mouseout', function () {
        d3.select(this).style('opacity', 1);
      });

    // Display the count on top of each stack for each category
    svg.selectAll('.bar-group')
      .each(function (stack) {
        d3.select(this)
          .selectAll('.bar-total-text')
          .data(stack)
          .enter().append('text')
          .attr('class', 'bar-total-text')
          .attr('x', d => xScale(d.data.category) + xScale.bandwidth() / 2)
          .attr('y', d => yScale(d.data.count) - 5)
          .style('text-anchor', 'middle')
          .style('font-size', '12px')
          .style('fill', 'black')
          // .text(d => d.data.count);
          .text(d => d.data.count > 0 ? d.data.count : 'No Data Available');
      });


    svg.selectAll('.bar-label-group')
      .data(stackedData)
      .enter().append('g')
      .attr('class', 'bar-label-group')
      .selectAll('.bar-label')
      .data(d => d)
      .enter().append('text')
      .attr('class', 'bar-label')
      .attr('x', d => xScale(d.data.category) + xScale.bandwidth() / 2)
      .attr('y', d => (yScale(d[0]) + yScale(d[1])) / 2)
      .style('text-anchor', 'middle')
      .style('font-size', '12px')
      .text(function (d) {
        const key = d3.select(this.parentNode).datum().key;
        const value = d.data[key];
        if (value !== 0 && value !== undefined) {
          return `${key}: ${value}`;
        }
      });

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height + margin.top})`)
      .call(d3.axisBottom(xScale));

    svg.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale));

    // Add legend
    const legend = svg.append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${width + 10}, 0)`);

    const legendItems = domain.map(item => item.charAt(0).toUpperCase() + item.slice(1)).reverse();;
    legend.selectAll('.legend-item')
      .data(legendItems)
      .enter().append('g')
      .attr('class', 'legend-item')
      .attr('transform', (d, i) => `translate(0,${i * 20})`)
      .call(g => {
        g.append('rect')
          .attr('width', 18)
          .attr('height', 18)
          .attr('fill', d => colorScale(d.toLowerCase()));

        g.append('text')
          .attr('x', 24)
          .attr('y', 9)
          .attr('dy', '0.35em')
          .style('font-size', '12px')
          .text(d => d);
      });

    // Tooltip functions
    const tooltip = svg.append('g')
      .attr('class', 'tooltip')
      .style('display', 'none');

    tooltip.append('rect')
      .attr('width', 120)  // Adjusted width for longer text
      .attr('height', 50)
      .attr('fill', 'white')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .style('opacity', 0.8);

    tooltip.append('text')
      .attr('x', 10)
      .attr('y', 20)
      .style('font-size', '12px')
      .style('font-weight', 'bold');

    function showTooltip(label, value, x, y) {
      tooltip.style('display', 'block');
      tooltip.attr('transform', `translate(${x},${y})`);
      tooltip.select('text').html(`${label}: ${value}`);
      tooltip.select('rect')
        .attr('width', 120)  // Adjusted width for longer text
        .attr('height', 50)
        .attr('fill', 'white')
        .attr('stroke', 'black')
        .attr('stroke-width', 1)
        .style('opacity', 0.8);
    }

  }, [compliantCount, nonCompliantCount, partiallyCompliantCount, notApplicableCount, complaint_type_array, non_complaint_type_array, partially_compliant_type_array, not_applicable_type_array]);

  return (
    <svg ref={chartRef} width={width + margin.left + margin.right} height={height + margin.top + margin.bottom}></svg>
  );
};

export default TypeMultiStackedBarChart;










///sanjay 
// import React, { useRef, useEffect, useState } from 'react';
// import * as d3 from 'd3';

// const TypeMultiStackedBarChart = ({
//   compliantCount,
//   nonCompliantCount,
//   partiallyCompliantCount,
//   notApplicableCount,

//   complaint_type_array,
//   non_complaint_type_array,
//   partially_compliant_type_array,
//   not_applicable_type_array,
//   card_container,
//   cardWidth
// }) => {





//   let width_1 = 0
//   let height_1 = 0
//   let width = 0
//   useEffect(() => {

//     const targetElement = document.getElementById(card_container);
//     // console.log('targetElement 33', targetElement.offsetWidth)
//     if (targetElement) {
//       const style = targetElement.style;
//       width_1 = parseFloat(targetElement.offsetWidth);         // Get the width
//       height_1 = parseFloat(style.height); // Get the height
//       // console.log('Width 565:', width_1, 'Height:', height_1);
//       width = width_1
//       setScreenWidth(width_1)
//     }
//   }, []);

//   const chartRef = useRef();
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
//   const margin = { top: 20, right: 100, bottom: 50, left: 5 };

//   const height = 400 - margin.top - margin.bottom;

//   console.log('width', width, width + margin.left + margin.right, card_container, screenWidth)


//   useEffect(() => {
//     // console.log('cardWidth chart', cardWidth)
//     setScreenWidth(cardWidth)
//   }, [cardWidth])

//   useEffect(() => {

//     d3.select('#chart').selectAll('div').remove()
//     d3.selectAll('#chart').selectAll('div').remove()


//     const targetElement = document.getElementById(card_container);
//     console.log('targetElement 33 49', targetElement.offsetWidth)
//     if (targetElement) {
//       const style = targetElement.style;
//       width_1 = parseFloat(targetElement.offsetWidth);         // Get the width
//       height_1 = parseFloat(style.height); // Get the height
//       // console.log('Width 565:', width_1, 'Height:', height_1);
//       width = width_1 - margin.left - margin.right
//     }
//     // console.log('66666666', width)
//     setScreenWidth(width)

//     const svg = d3.select(chartRef.current);

//     svg.selectAll('.bar-group').remove();
//     svg.selectAll('.bar-total-text').remove();
//     svg.selectAll('.bar-label-group').remove();
//     svg.selectAll('.x-axis').remove();
//     svg.selectAll('.y-axis').remove();
//     svg.selectAll('.legend').remove();
//     svg.selectAll('.tooltip').remove();
//     // Function to get ordered keys
//     const getOrderedKeys = (dataArray) => {
//       const keysSet = new Set();

//       dataArray.forEach(categoryData => {
//         Object.keys(categoryData).forEach(key => {
//           // Exclude 'category' and 'count' keys
//           if (key !== 'category' && key !== 'count') {
//             keysSet.add(key);
//           }
//         });
//       });

//       // Convert the Set to an array and order it based on desiredOrder
//       const orderedKeys = Array.from(keysSet).sort((a, b) => {
//         return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
//       });

//       return orderedKeys;
//     };

//     const updateData = (dataArray, complaintArray, nonComplaintArray, partiallyComplaintArray, not_applicable_type_array) => {
//       // console.log('dataArray, complaintArray, nonComplaintArray, partiallyComplaintArray', dataArray, complaintArray, nonComplaintArray, partiallyComplaintArray)
//       const updateCategoryData = (categoryData, typeArray) => {
//         for (let j = 0; j < typeArray.length; j++) {
//           const complaintType = typeArray[j];
//           const typeName = complaintType.type_name.toLowerCase();
//           categoryData[typeName] = complaintType.count;
//         }
//       };

//       for (let i = 0; i < dataArray.length; i++) {
//         const categoryData = dataArray[i];

//         switch (categoryData.category) {
//           case 'Compliant':
//             updateCategoryData(categoryData, complaintArray);
//             break;
//           case 'Non Compliant':
//             updateCategoryData(categoryData, nonComplaintArray);
//             break;
//           case 'Partially Compliant':
//             updateCategoryData(categoryData, partiallyComplaintArray);
//             break;
//           // Add more cases if needed for other categories
//           default:
//           case 'Not Applicable':
//             updateCategoryData(categoryData, not_applicable_type_array);
//             break;
//         }
//       }
//     };

//     const data = [

//       {
//         category: 'Compliant',
//         count: compliantCount,
//       },
//       {
//         category: 'Non Compliant',
//         count: nonCompliantCount,
//       },
//       {
//         category: 'Partially Compliant',
//         count: partiallyCompliantCount,
//       },
//       {
//         category: 'Not Applicable',
//         count: notApplicableCount,
//       },
//     ];
//     console.log('not_applicable_type_array', notApplicableCount, not_applicable_type_array)
//     updateData(data, complaint_type_array, non_complaint_type_array, partially_compliant_type_array, not_applicable_type_array);

//     const totalByCategory = data.map(d => d.count);


//     const xScale = d3
//       .scaleBand()
//       .domain(data.map(d => d.category))
//       .range([margin.left, width + margin.left])
//       .padding(0.1);

//     const yScale = d3
//       .scaleLinear()
//       .domain([0, d3.max(totalByCategory)])
//       .range([height + margin.top, margin.top]);
//     const getRandomColor = () => {
//       const component = () => Math.floor(Math.random() * 100 + 150); // Adjust the range for lighter colors
//       return `rgb(${component()}, ${component()}, ${component()})`;
//     };

//     // Dynamically generate keys for stacking
//     const domain = Array.from(new Set(data.flatMap(d => Object.keys(d).filter(key => key !== 'category' && key !== 'count'))));
//     // console.log('domain', domain)
//     const colorScale = d3
//       .scaleOrdinal()
//       .domain(domain)
//       // .range(['#50a5f1', '#34c38f', 'rgb(85, 110, 230)']);
//       .range(domain.map(() => getRandomColor()));

//     // Dynamically generate keys for stacking
//     // console.log('domain', domain)
//     const desiredOrder = domain.map(item => item.charAt(0).toUpperCase() + item.slice(1));
//     // console.log('desiredOrder', desiredOrder, data);
//     const stackedData = d3.stack().keys(getOrderedKeys(data))(data);

//     svg.selectAll('.bar-group')
//       .data(stackedData)
//       .enter().append('g')
//       .attr('class', 'bar-group')
//       .attr('fill', d => colorScale(d.key))
//       .selectAll('rect')
//       .data(d => d)
//       .enter().append('rect')
//       .attr('x', d => xScale(d.data.category))
//       .attr('y', d => yScale(d[1]))
//       .attr('height', d => yScale(d[0]) - yScale(d[1]))
//       .attr('width', xScale.bandwidth())
//       .on('mouseover', function (event, d) {
//         const [x, y] = d3.pointer(event);
//         d3.select(this).style('opacity', 0.7);
//         showTooltip(d.key, d.data[d.key], x + 10, y - 10);
//       })
//       .on('mouseout', function () {
//         d3.select(this).style('opacity', 1);
//       });

//     svg.selectAll('.bar-group')
//       .each(function (stack) {
//         d3.select(this)
//           .selectAll('.bar-total-text')
//           .data(stack)
//           .enter().append('text')
//           .attr('class', 'bar-total-text')
//           .attr('x', d => xScale(d.data.category) + xScale.bandwidth() / 2)
//           .attr('y', d => yScale(d.data.count) - 5)
//           .style('text-anchor', 'middle')
//           .style('font-size', '12px')
//           .style('fill', 'black')
//           // .text(d => d.data.count);
//           .text(d => d.data.count > 0 ? d.data.count : 'No Data Available');
//       });


//     svg.selectAll('.bar-label-group')
//       .data(stackedData)
//       .enter().append('g')
//       .attr('class', 'bar-label-group')
//       .selectAll('.bar-label')
//       .data(d => d)
//       .enter().append('text')
//       .attr('class', 'bar-label')
//       .attr('x', d => xScale(d.data.category) + xScale.bandwidth() / 2)
//       .attr('y', d => (yScale(d[0]) + yScale(d[1])) / 2)
//       .style('text-anchor', 'middle')
//       .style('font-size', '12px')
//       .text(function (d) {
//         const key = d3.select(this.parentNode).datum().key;
//         const value = d.data[key];
//         if (value !== 0 && value !== undefined) {
//           return `${key}: ${value}`;
//         }
//       });

//     svg.append('g')
//       .attr('class', 'x-axis')
//       .attr('transform', `translate(0,${height + margin.top})`)
//       .call(d3.axisBottom(xScale));

//     svg.append('g')
//       .attr('class', 'y-axis')
//       .attr('transform', `translate(${margin.left},0)`)
//       .call(d3.axisLeft(yScale));

//     // Add legend
//     const legend = svg.append('g')
//       .attr('class', 'legend')
//       .attr('transform', `translate(${width + 10}, 0)`);

//     const legendTranslation = `translate(${screenWidth - 170}, 0)`;
//     legend.attr('transform', legendTranslation);

//     const legendItems = domain.map(item => item.charAt(0).toUpperCase() + item.slice(1)).reverse();;


//     // console.log('legendItems 263', legendItems)
//     legend.selectAll('.legend-item')
//       .data(legendItems)
//       .enter().append('g')
//       .attr('class', 'legend-item')
//       .attr('transform', (d, i) => `translate(0,${i * 20})`)
//       .call(g => {
//         g.append('rect')
//           .attr('width', 18)
//           .attr('height', 18)
//           .attr('fill', d => colorScale(d.toLowerCase()));

//         g.append('text')
//           .attr('x', 24)
//           .attr('y', 9)
//           .attr('dy', '0.35em')
//           .style('font-size', '12px')
//           .text(d => d);
//       });

//     // Tooltip functions
//     const tooltip = svg.append('g')
//       .attr('class', 'tooltip')
//       .style('display', 'none');

//     tooltip.append('rect')
//       .attr('width', 120)  // Adjusted width for longer text
//       .attr('height', 50)
//       .attr('fill', 'white')
//       .attr('stroke', 'black')
//       .attr('stroke-width', 1)
//       .style('opacity', 0.8);

//     tooltip.append('text')
//       .attr('x', 10)
//       .attr('y', 20)
//       .style('font-size', '12px')
//       .style('font-weight', 'bold');

//     function showTooltip(label, value, x, y) {
//       tooltip.style('display', 'block');
//       tooltip.attr('transform', `translate(${x},${y})`);
//       tooltip.select('text').html(`${label}: ${value}`);
//       tooltip.select('rect')
//         .attr('width', 120)  // Adjusted width for longer text
//         .attr('height', 50)
//         .attr('fill', 'white')
//         .attr('stroke', 'black')
//         .attr('stroke-width', 1)
//         .style('opacity', 0.8);
//     }

//   }, [compliantCount, nonCompliantCount, partiallyCompliantCount, notApplicableCount, complaint_type_array, non_complaint_type_array, partially_compliant_type_array, not_applicable_type_array, screenWidth]);

//   return (
//     <>     
//       <div id='chart'>
//         <svg ref={chartRef} width={screenWidth - 100} height={height + margin.top + margin.bottom}></svg>
//       </div>
//       {/* <svg ref={chartRef} width={width} height={height + margin.top + margin.bottom}></svg> */}
//     </>
//   );
// };

// export default TypeMultiStackedBarChart;



