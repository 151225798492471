

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody, Col, Container, Row, Label, Table, Button, Badge } from "reactstrap";
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags'
import urlSocket from '../../../helpers/urlSocket';
import { Switch } from 'antd'
import { CheckOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import facility from '../FacilityScreen'
import RoleTableContainer from './Component/TableContainer';
import _ from "lodash";

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { TableHeader, PaginationComponent } from "../../../common/DataTable"
import FuzzySearch from '../../../common/FuzzySearch';


const propTypes = {};

const defaultProps = {};



/**
 * 
 */
class ManageRoles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roles_info: [],


            search_files: [],
            dup_search_files: [],
            temp_search_files: [],
            currentPage: 1,
            totalItems: 0,
            items_per_page: 10,
        };
    }

    getdata = (e, data, idx) => {
        sessionStorage.setItem('role_id', data._id)
        this.props.history.push('/createroles')
    }

    async componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem('authUser'))
        var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"))


        var protect_routes = await facility(user_facilities, 'mroles')
        if (protect_routes !== undefined) {
            try {
                urlSocket.post('cog/manage-roles', { encrypted_db_url: data.db_info.encrypted_db_url, user_id: data.user_data._id, show_all: data.user_data.created_by === null ? true : false }).then((response) => {
                   console.log(response, 'response')
                    if (response.data.response_code === 500) {
                        this.setState({
                            roles_info: response.data.data, protect_routes: protect_routes,
                            statusBasedFilteredData: response.data.data, dataloaded: true,
                            search_files: response.data.data,
                            dup_search_files: response.data.data,
                            temp_search_files: response.data.data
                        }, () => { this.pageClick(1) })
                    }


                })

            } catch (error) {

            }
        }
        else {
            this.props.history.push('/dashboard')
        }

    }




    filterStatus = (data) => {
        var filteredData;
        if (data == "In active") {
            filteredData = _.filter(this.state.entitiesAuditData, { "active": "0" })
        }
        else
            if (data == "Active") {
                filteredData = _.filter(this.state.entitiesAuditData, { "active": "1" })
            }
            else
                if (data == "All") {
                    filteredData = this.state.entitiesAuditData
                }
        this.setState({
            statusBasedFilteredData: filteredData,
        })



    }


    completedStatus = (data) => {
        var labels = []
        data == false ? labels.push({ status: "In active", color: "#303335", badgeClass: "danger" }) :
            data == true ? labels.push({ status: "Active", color: "#fbae17", badgeClass: "success" }) :
                labels.push({ status: "In active", color: "#303335", badgeClass: "danger" })
        return labels
    }


    pageClick = (currentPage) => {
        this.setState({ currentPage }, () => { this.paginationProcess() })
    }

    paginationProcess = () => {
    }

    getFuzzySearch = (search_files) => {
        console.log('search_files', search_files)
        this.setState({ statusBasedFilteredData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files }, () => { this.pageClick(this.state.currentPage) })
    }



    render() {
        if (this.state.dataloaded) {
            // const { SearchBar } = Search;
            const options = {
                sizePerPage: 10,
                totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
                custom: true,
            };

            const columns = [
                {
                    accessor: 'role_name',
                    Header: 'Role Name',
                    filterable: true,
                    width: "80%",
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        
                        var itemStatus = this.completedStatus(item.role_status)[0]
                        var screens = ""
                        _.map(item.facilities, (item,idx )=> {
                            if(idx === 0) screens  = item.interfacename
                            else screens  = screens + " | " + item.interfacename
                        })
                        // console.log("item.role_status", screens)
                        return (
                            <div className="d-flex flex-row align-items-center" >
                                <div className="me-2">
                                    {
                                        itemStatus.badgeClass === "success" ?
                                        <i
                                        className={"mdi mdi-checkbox-marked-circle-outline font-size-20 text-" + itemStatus.badgeClass}
                                    /> :
                                    <i
                                        className={"mdi mdi-circle-slice-8 font-size-20 text-" + itemStatus.badgeClass}
                                    />
                                    }
                                    
                                </div>
                                <div className="d-flex flex-column">
                                    <div>{item.role_name}</div>
                                    <div className='font-size-13 text-secondary'>{screens}</div>
                                    
                                </div>
                            </div>
                        )
                    }
                },
                {
                    accessor: 'role_status',
                    Header: 'Status',
                    isSort: true,
                    width: "10%",
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        var itemStatus = this.completedStatus(item.role_status)[0]
                        return (
                            <>
                                <div className="d-flex gap-3">
                                    <Badge className={"font-size-12 badge-soft-" + itemStatus.badgeClass}
                                        color={itemStatus.color}
                                        pill
                                    >
                                        {itemStatus.status}
                                    </Badge>
                                </div>
                            </>
                        )
                    }
                },
                // this.state.protect_routes.read_write_checked === true &&
                {
                    accessor: "menu",
                    Header: "Action",
                    width: "10%",
                    Cell: (cellProps) => {
                        var data = cellProps.row.original
                        var index = cellProps.row.index
                        return (
                            <div className="d-flex gap-1" style={{ display: 'flex', alignContent: 'center' }}>
                                <Button type="button" color="primary" size="sm" className="btn-sm waves-effect waves-light me-2" onClick={(e) => { this.getdata(e, data, index) }}>
                                    Edit Role {""} <i className="mdi mdi-pencil  align-middle ms-2"></i>
                                </Button>
                            </div>
                        )
                    },
                },
            ];

            const defaultSorted = [{
                dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
                order: 'desc' // desc or asc
            }];

            return (

                <div className="page-content">
                    <MetaTags><title>AuditVista | Manage Roles</title></MetaTags>

                    <Container fluid >
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Roles" breadcrumbItem="Roles" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        {/* {
                                                console.log(columns,'columns',this.state.statusBasedFilteredData)
                                            } */}
                                        <RoleTableContainer
                                            columns={columns}
                                            data={this.state.statusBasedFilteredData}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            isJobListGlobalFilter={false}
                                            customPageSize={10}
                                            dynamicBtn={true}
                                            style={{ width: '100%' }}
                                            btnClick={() => { sessionStorage.removeItem('role_id'); this.props.history.push('/createroles') }}
                                            iscustomPageSizeOptions={false}
                                            // dynamicParams={params1,params2}
                                            isPagination={true}
                                            filterable={false}
                                            btnName={"Create Roles"}
                                            tableClass="table align-middle table-nowrap table-hover"
                                            theadClass="table-light"
                                            paginationDiv="col-sm-12 col-md-7"
                                            pagination="pagination justify-content-end pagination-rounded"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }
        else {
            return null
        }
    }
}


ManageRoles.propTypes = propTypes;
ManageRoles.defaultProps = defaultProps;
// #endregion

export default ManageRoles;