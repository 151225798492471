import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import ImageProcess from '../Components/imageprocess';
import uuid from "react-uuid";
import { Link } from "react-router-dom"
import { Flex, Spin } from 'antd';


const WebcamCapture = (props) => {
  const [devices, setDevices] = useState([]);
  const [cameraID, setCameraId] = useState(null);
  const [showWebCam, setShowCam] = useState(false);
  const [picture, setPicture] = useState('');
  const selectRef = React.useRef(null)



  const [showVideoRecording, setShowVideoRecording] = useState(false)
  const [showVideoPreview, setShowVideoPreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const [isRecording, setIsRecording] = React.useState(false)
  const [previewUrl, setpreviewUrl] = React.useState(null)
  const [recordvideoBlob, setrecordvideoBlob] = React.useState()
  const [duration, setDuration] = useState(0);
  const [showCaptured, setShowCaptured] = useState(false)
  const webcamRef = React.useRef(null);
  const videoRef = React.useRef(null)
  const mediaRecorderRef = React.useRef(null);
  const chunksRef = React.useRef([]);
  const recordingTimeoutRef = React.useRef(null);
  const durationTarget = 3 * 60 * 1000; 



     // Fetch available camera devices
     useEffect(() => {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => setDevices(devices.filter(device => device.kind === 'videoinput')))
        .catch(err => console.error("Error fetching devices:", err));
    }, []);
  

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowCam(false);
    setCameraId(event.target.value);
    setShowCam(true);
  };

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    var image_info = ImageProcess.convertBaseToURL(pictureSrc);
    setPicture(pictureSrc);
    console.log(image_info, 'image_info', pictureSrc);
    setShowCam(false); // Stop the camera after capturing the image
  }, [webcamRef]);


 // Preload the camera when the component is mounted
 useEffect(() => {
  if (showWebCam) {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000); // Mock loading time
  }
}, [showWebCam]);



  const recordVideo = async () => {
    // setShowVideoRecording(true)
    setShowVideoPreview(false)
    setShowCam(false)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      videoRef.current.srcObject = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);
      var chunks = []
      chunksRef.current = [];
      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };
      mediaRecorderRef.current.onstop = () => {

        const blob = new Blob(chunksRef.current, { type: "video/webm" });
        const videoUrl = URL.createObjectURL(blob);
        chunksRef.current.length = 0;
        setpreviewUrl(videoUrl)
        setIsRecording(false)
        setrecordvideoBlob(blob)
        setShowVideoRecording(false)
        setShowCaptured(true)
        setShowVideoPreview(true)

      };
      setIsRecording(true)
      mediaRecorderRef.current.start();

      recordingTimeoutRef.current = setTimeout(() => {
        stopRecording();
      }, durationTarget);

    } catch (error) {
      console.error("Error accessing user media:", error);
    }
  }


  const stopRecording = () => {

    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current);
    }

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      if (videoRef.current !== null) {
        videoRef.current.srcObject
          .getTracks()
          .forEach((track) => track.stop());
        // setMarkerEnable(false)
        setShowVideoRecording(false)
        setShowCaptured(true)
        setShowVideoPreview(true)

        setIsRecording(false)
        setDuration(0)
      }
    }
  };


  const handleClearSelection = () => {
    setShowCam(false);
    setCameraId('');
    selectRef.current.value = 'choose'; // Reset the select dropdown to default
  };

  return (
    <div style={{ width: "100%", borderBottom: "1px solid #dedede", marginLeft: 12, padding: 10 }}>
      {
        devices.length > 0 ? (
          <>
            <div><label>Select Camera</label></div>
            <div className="d-flex flex-row gap-2 ">
              <select ref={selectRef} defaultValue={"choose"} onChange={handleSubmit} >
                <option value="choose" disabled>Select Camera</option>
                {devices.map((device, key) => (
                  <option key={key} value={device.deviceId}>{device.label || `Device ${key + 1}`}</option>
                ))}
              </select>
              {
                showWebCam &&
                <div className="bx bx-x  text-danger bg-light" style={{ cursor: 'pointer', fontSize: '25px', width: "", zIndex: '0' }} onClick={handleClearSelection} > </div>
              }
            </div>
          </>
        ) : (
          <div><label style={{ fontSize: 12, color: "#ff6666" }}>No web camera detected</label></div>
        )
      }

      {
        showWebCam &&
      // (
        <div className="mt-3" style={{ width: "100%", position: "relative" }} >
          {isLoading ? (
            <div>Loading camera...
              <Flex align="center" gap="middle">
                {/* <Spin size="small" /> */}
                <Spin />
              </Flex>
            </div>


          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{ deviceId: cameraID }}
              width={500}
            />
          )}
             <div className=" d-flex gap-2 mt-3 ">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowCam(true)
                  setShowVideoRecording(false)

                  capture();
                }}
                className="btn btn-danger"
                disabled={picture !== ''}
              >
                Capture
              </button>


              <button
                onClick={() => {setShowVideoRecording(true);  recordVideo()}}
                className="btn btn-primary ml-2"
              >
                Record Video
              </button>
            </div>
          </div>
        // )

      }
              {
        picture !== '' && 
        <div className="mt-3" style={{ width: "100%", position: "relative" }} >
          <img src={picture} alt="Captured" />
          <div className="mt-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                setPicture('');
                setShowCam(true); // Restart the camera for retaking the picture
              }}
              className="btn btn-info"
            >
              Retake
            </button>
            &nbsp;
            <button
            type="button"
              onClick={(e) => {
                e.preventDefault()
                // props.handleCameraUpload()
                var image_info = ImageProcess.convertBaseToURL(picture);
                props.handleCameraUpload(image_info)
                // setPicture("");
                // selectRef.current.value ="choose"
                // e.preventDefault();
                // setPicture('');
                // setShowCam(true); // Restart the camera for retaking the picture
              }}
              className="btn btn-dark"
            >
              Upload
            </button>
            </div>
          </div>

      }


{
        showVideoRecording ?
          <div className="d-flex flex-row gap-2 align-items-center justify-content-center mt-4" style={{ minWidth: 500, minHeight: 300 }}>
            <div className="col-11">

              {isRecording &&
                <div className="recording-indicator">Recording...  <span className="duration-text ms-4">
                  {`${Math.floor((durationTarget - duration) / 1000 / 60)} minutes ${(Math.floor((durationTarget - duration) / 1000) % 60)} seconds remaining`}
                </span></div>
              }
              <video
                // style={{ width: "100%" }}
                ref={videoRef}
                autoPlay
                playsInline
                muted
                width={500}
              />
            </div>
            <div className="col-1 d-flex flex-column align-items-center justify-content-center gap-2">
              <div className="d-flex flex-column align-items-center gap-1">
                <Link to="#" className="btn btn-outline-danger" onClick={() => stopRecording()}><i className="mdi mdi-stop" style={{ fontSize: 30 }} /></Link>
                <label>Stop</label>
              </div>
            </div>
          </div>
          :
          null}



      <div className="col-11 mt-5">
        {
          
          showVideoPreview &&


          <div>
          <video
            // style={{ width: "100%" }}
            width={500}
            src={previewUrl}
            controls
          />



          <div className="mt-2">


          <button
                   onClick={() => {setShowCam(true); setShowVideoPreview(false); setpreviewUrl('')} }
                  className="btn btn-info"
                >
                  Retake
                </button>
                &nbsp;
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    // props.handleCameraUpload()
                    var image_info = ImageProcess.convertBaseToURL(picture);
                    props.handleCameraUpload(image_info)
                    // e.preventDefault();
                    // setPicture('');
                    // setShowCam(true); // Restart the camera for retaking the picture
                  }}
                  className="btn btn-dark"
                >
                  Upload
                </button>
              </div>


            </div>



            
}
                  </div>
    </div>
  );
}

export default WebcamCapture;








// import React, { useState, useEffect } from "react";
// import Webcam from "react-webcam";
// import ImageProcess from '../Components/imageprocess';
// import uuid from "react-uuid";

// const WebcamCapture = (props) => {
//   const [devices, setDevices] = useState([]);
//   const [cameraID, setCameraId] = useState(null);
//   const [showWebCam, setShowCam] = useState(false);
//   const [picture, setPicture] = useState('');
//   const selectRef = React.useRef(null)
//   const webcamRef = React.useRef(null);

//   const handleDevices = React.useCallback(
//     (mediaDevices) =>
//       setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
//     [setDevices]
//   );

//   useEffect(() => {
//     navigator.mediaDevices.enumerateDevices().then(handleDevices);
//   }, [handleDevices]);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setShowCam(false);
//     setCameraId(event.target.value);
//     setShowCam(true);
//   };

//   const capture = React.useCallback(() => {
//     const pictureSrc = webcamRef.current.getScreenshot();
//     var image_info = ImageProcess.convertBaseToURL(pictureSrc);
//     setPicture(pictureSrc);
//     console.log(image_info, 'image_info', pictureSrc);
//     setShowCam(false); // Stop the camera after capturing the image
//   }, [webcamRef]);

//   return (
//     <div style={{ width: "100%", borderBottom: "1px solid #dedede", marginLeft: 12, padding: 10 }}>
//       {
//         devices.length > 0 ? (
//           <>
//             <div><label>Select Camera</label></div>
//             <div>
//               <select ref={selectRef} defaultValue={"choose"} onChange={handleSubmit} >
//                 <option value="choose" disabled>Select Camera</option>
//                 {devices.map((device, key) => (
//                   <option key={key} value={device.deviceId}>{device.label || `Device ${key + 1}`}</option>
//                 ))}
//               </select>
//             </div>
//           </>
//         ) : (
//           <div><label style={{ fontSize: 12, color: "#ff6666" }}>No web camera detected</label></div>
//         )
//       }

//       {
//         showWebCam ? 
//         // (
//           <div className="mt-3" style={{ width: "100%", position: "relative" }} >
//             <Webcam
//               audio={false}
//               ref={webcamRef}
//               screenshotFormat="image/jpeg"
//               videoConstraints={{ deviceId: cameraID }}
//               width={500}
//             />
//             <div>
//               <button
//                 onClick={(e) => {
//                   e.preventDefault();
//                   capture();
//                 }}
//                 className="btn btn-danger"
//                 disabled={picture !== ''}
//               >
//                 Capture
//               </button>
//             </div>
//           </div>
//         // )
//         :
//         picture !== '' && 
//         <div className="mt-3" style={{ width: "100%", position: "relative" }} >
//           <img src={picture} alt="Captured" />
//           <div className="mt-2">
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setPicture('');
//                 setShowCam(true); // Restart the camera for retaking the picture
//               }}
//               className="btn btn-info"
//             >
//               Retake
//             </button>
//             &nbsp;
//             <button
//             type="button"
//               onClick={(e) => {
//                 e.preventDefault()
//                 // props.handleCameraUpload()
//                 var image_info = ImageProcess.convertBaseToURL(picture);
//                 props.handleCameraUpload(image_info)
//                 setPicture("");
//                 selectRef.current.value ="choose"
//                 // e.preventDefault();
//                 // setPicture('');
//                 // setShowCam(true); // Restart the camera for retaking the picture
//               }}
//               className="btn btn-dark"
//             >
//               Upload
//             </button>
//             </div>
//           </div>

//       }
// {/* 
//       {
//         picture !== '' && (
//           <div>
//             <img src={picture} alt="Captured" />
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setPicture('');
//                 setShowCam(true); // Restart the camera for retaking the picture
//               }}
//               className="btn btn-primary"
//             >
//               Retake
//             </button>
//           </div>
//         )
//       } */}
//     </div>
//   );
// }

// export default WebcamCapture;




















// import React, { useState,useEffect } from "react";
// import Webcam from "react-webcam";

// import ImageProcess from '../Components/imageprocess'
// import uuid from "react-uuid";

// const WebcamCapture=()=>{

// const [devices, setDevices] = React.useState([]);
// const [cameraID, setCameraId] = React.useState(null)
// const [showWebCam, setShowCam] = React.useState(false)
// const [picture, setPicture] = useState('')

// const webcamRef = React.useRef(null)
// // console.log(props,'props',devices,cameraID,showWebCam,picture)

// const handleDevices = React.useCallback(
//   (mediaDevices) =>
//     setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
//   [setDevices]
// );

// React.useEffect(() => {
//   // try {
//   navigator.mediaDevices.enumerateDevices().then(handleDevices);
//   // } catch (error) {
    
//   // }
// }, [handleDevices]);

// const handleSubmit = (event) => {
//   event.preventDefault()
//   setShowCam(false)
//   setCameraId(event.target.value)
//   setShowCam(true)
// }

// const formatBytes = (bytes, decimals = 2) => {
//   if (bytes === 0) return "0 Bytes"
//   const k = 1024
//   const dm = decimals < 0 ? 0 : decimals
//   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

//   const i = Math.floor(Math.log(bytes) / Math.log(k))
//   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
// }

// const capture = React.useCallback(() => {
//   const pictureSrc = webcamRef.current.getScreenshot()
//   var image_info = ImageProcess.convertBaseToURL(pictureSrc);
//   setPicture(pictureSrc)
//   console.log(image_info,'image_info',pictureSrc);
// })

// return (
//   <div style={{ width: "100%", borderBottom:"1px solid #dedede", marginLeft:12, padding:10 }}>
//     {
//       devices.length > 0 ? <>
//         <div><label>Select Camera</label></div>
//         <div>
//           <select defaultValue={"choose"} onChange={handleSubmit} >
//             <option value="choose" disabled>Select Camera</option>
//             {devices.map((device, key) => (
//               <option key={key} value={device.deviceId}>{device.label || `Device ${key + 1}`}</option>
//             ))}
//           </select>
//         </div>
//       </> :
//         <div><label style={{ fontSize: 12, color: "#ff6666" }}>No web camera detected</label></div>
//     }
   

//     {
//       showWebCam &&
//       <div className="mt-3" style={{ width: "100%", position: "relative" }} >
//         <Webcam
//           audio={false}
//           ref={webcamRef}
//           screenshotFormat="image/jpeg"
//           videoConstraints={{ deviceId: cameraID }}
//           width={500}
//         />
//         {/* <img src={picture} /> */}
//         <div>
//           {picture !== '' ? (
//             <button
//               onClick={(e) => {
//                 e.preventDefault()
//                 setPicture('')
//               }}
//               className="btn btn-primary"
//             >
//               Retake
//             </button>
//           ) : (
//             <button
//               onClick={(e) => {
//                 e.preventDefault()
//                 capture()
//               }}
//               className="btn btn-danger"
//             >
//               Capture
//             </button>
//           )}
//         </div>
//       </div>
//     }

//   </div>
// );
// }
// export default WebcamCapture