import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import SweetAlert from "react-bootstrap-sweetalert"



const _ = require('lodash')
// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from "../../../helpers/urlSocket";

export default class NewEndPoint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: true,
            dataloaded: false,
            enableAddCode: true,
            location_name_err: false,
            // auditUser:'choose',
            // target_date: moment(this.props.epdata.audit_pbd_ends_on,).format('YYYY-MM-DD'),
            disabled_submit: false

        };
        this.Avref = React.createRef()


    }

    componentDidMount() {
        var db_info = JSON.parse(sessionStorage.getItem('db_info'))
        var user_data = JSON.parse(sessionStorage.getItem('authUser'))
        this.setState({ db_info, user_data }, () => this.getAllUsers())
        // this.getUnpublishedLocation()
    }



    async getAllUsers() {
        try {
            urlSocket.post("webphlbconf/getallusers", {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    created_by: this.props.user_data._id,
                    company_id: this.props.user_data.company_id,
                },
                publishtemplateInfo: this.props.audit_data
            })
                .then((response) => {
                    this.setState({
                        userdata: response.data.data,
                        dataloaded: true
                    })
                })
        } catch (error) {
            console.log("catch error", error)
        }
    }

    changeAuditUserHandler = (event) => {
        if (String(event.target.value) === this.state.reviewUser) {
            this.setState({ auditUser: "", dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
        }
        else {
            var getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value })
            var getUser = this.state.userdata[getNewUserIndex]
            var audit_user = {
                audit_type: "1",
                designation: getUser.designation,
                name: getUser.firstname,
                user_code: getUser.usercode,
                user_id: getUser._id,
            }
            this.setState({ audit_user, auditUser: String(event.target.value) })
        }

    }

    changeReviewUserHandler = (event) => {
        if (String(event.target.value) === this.state.auditUser) {
            this.setState({ reviewUser: "", dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
        }
        else {
            var getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value })
            var getUser = this.state.userdata[getNewUserIndex]
            var review_user = {
                audit_type: "2",
                designation: getUser.designation,
                name: getUser.firstname,
                user_code: getUser.usercode,
                user_id: getUser._id,
            }
            this.setState({ review_user, reviewUser: String(event.target.value) })
        }

    }



    getadtRevUsers() {
        var auditUser
        var getIndex = _.findIndex(this.props.epdata.audit_pbd_users, { audit_type: "1" })
        if (getIndex !== -1) {
            auditUser = this.props.epdata.audit_pbd_users[getIndex]
        }
        else {
            auditUser = undefined
        }

        var reviewUser
        var getIndex = _.findIndex(this.props.epdata.audit_pbd_users, { audit_type: "2" })
        if (getIndex !== -1) {
            reviewUser = this.props.epdata.audit_pbd_users[getIndex]
        }
        else {
            reviewUser = undefined
        }
        this.setState({
            auditUser,
            reviewUser
        })
    }

    listOutCategory = () => {
        var category = _.map(this.props.endpoints, item => {
            return item.hlevel
        })

        var uniqueHlevels = _.uniqBy(category)

        return uniqueHlevels
    }

    selectCat = (event) => {
        this.setState({ createNewCat: event.target.value == "1" ? true : false, catErrorEnabled: false, catError: "" })

    }

    validateNode = (values) => {
        var flatData = this.props.endpoints

        var returnValue = true
        var getCatValue = -1

        if (values.hlevel_cat == "1") {
            var getSubtitle = String(values.hlevel).replace(/\s/g, '').toLowerCase()
            getCatValue = _.findIndex(flatData, function (o) { if (String(o.hlevel).replace(/\s/g, '').toLowerCase() == getSubtitle) { return true } else { return false } });

            if (getCatValue != -1) {
                this.setState({
                    catErrorEnabled: true,
                    hlevel: values.hlevel,
                    hlevel_cat: values.hlevel_cat,
                    catError: "Category is already available",
                })
                returnValue = false
            }
        }
        else if (values.hlevel_cat == "0" || values.hlevel_cat == "") {
            this.setState({
                catErrorEnabled: true,
                hlevel: "",
                hlevel_cat: values.hlevel_cat,
                catError: "Select Category",
            })
            returnValue = false
        }
        else {
            this.setState({
                catErrorEnabled: false,
                catError: "",
            })
        }

        if (this.state.enableCode) {
            var getCode = String(values.code).replace(/\s/g, '').toLowerCase()
            var getCodeValue = _.findIndex(flatData, function (o) {
                if (String(o.code).replace(/\s/g, '').toLowerCase() == getCode) { return true } else { return false }
            });

            if (getCode === this.state.getCode) {

            }
            else {
                if (getCodeValue != -1) {
                    this.setState({
                        codeErrorEnabled: true,
                        getCode: values.code,
                        codeError: "Code is already available"
                    })
                    returnValue = false
                }
                else {
                    this.setState({
                        codeErrorEnabled: false,
                        codeError: "",
                    })
                }
            }


        }


        return returnValue
    }

    validationApi = (location_value, mode) => {
        location_value["user_id"] = this.state.user_data.user_data._id
        try {
            urlSocket.post('cog/validate-location-info', location_value).then((response) => {
                if (response.data.response_code === 500) {
                    if (response.data.data.length > 0 && response.data.message == "name already exist") {
                        if (mode == 1) {
                            this.setState({ location_name_err: true })
                        }
                        if (mode == 2) {
                            this.setState({ code_err: true })
                        }
                    }
                    else {
                        if (mode == 1) {
                            this.setState({ location_name_err: false })
                        }
                        if (mode == 2) {
                            this.setState({ code_err: false })

                        }

                    }
                }
            })
        } catch (error) {

        }
    }

    validateLocation = (event, mode) => {
        var location_value = {}
        if (mode == 1) {
            location_value["name"] = event.target.value
            location_value["encrypted_db_url"] = this.state.db_info.encrypted_db_url
            this.validationApi(location_value, mode)
        }
        if (mode == 2) {
            location_value["code"] = event.target.value
            location_value["encrypted_db_url"] = this.state.db_info.encrypted_db_url
            this.validationApi(location_value, mode)
        }
        // this.validationApi(location_value,mode)


    }

    submitData = (event, values) => {
        // if(this.state.location_name_err == false && this.state.code_err == false){
        var allowToWrite = this.validateNode(values)

        var endpointInfo
        if (this.props.publishtemplateInfo.enable_review) {
            endpointInfo = {
                "name": values.hlevel_name,
                "code": values.code !== undefined ? values.code : "",
                "category": this.state.category,
                "audit_pbd_users": [
                    this.state.audit_user,
                    this.state.review_user,
                ],
                // "audit_pbd_ends_on" : values.target_date, 
                "adt_users": [
                    this.state.audit_user,
                    this.state.review_user,
                ],
                "published": false,
                auditInfo: this.props.publishtemplateInfo
            }
        }
        else {
            endpointInfo = {
                "name": values.hlevel_name,
                "code": values.code !== undefined ? values.code : "",
                "category": this.state.category,
                "audit_pbd_users": [
                    this.state.audit_user,
                ],
                // "audit_pbd_ends_on" : values.target_date, 
                "published": false,
                "adt_users": [
                    this.state.audit_user
                ],
                auditInfo: this.props.publishtemplateInfo
            }
        }



        if (allowToWrite && this.state.code_err == false && this.state.location_name_err == false) {
            this.setState({ disabled_submit: true })

            try {
                urlSocket.post('cog/crud-new-location', {
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.props.user_data._id,
                        company_id: this.props.user_data.company_id
                    },
                    endpointInfo,
                    auditInfo: this.props.publishtemplateInfo
                }).then((response) => {
                    if (response.data.response_code == 500) {
                        this.setState({ auditUser: undefined, dataloaded: false }, () => { this.setState({ dataloaded: true, disabled_submit: false }) })
                        this.props.onClose()
                        this.props.getUnpublishedLocation()
                    }
                    else {
                        var errorMessage = response.data.error
                        console.log(errorMessage)
                    }
                })

            } catch (error) {
                console.log(error, 'error')
            }

            // try {

            //     urlSocket.post("webpbdadtdta/addpdbadtendpoint", {
            //         userInfo:{
            //             encrypted_db_url:this.state.db_info.encrypted_db_url,
            //             _id:this.props.user_data._id,
            //             company_id:this.props.user_data.company_id
            //         },
            //         endpointInfo,
            //         auditInfo:this.props.publishtemplateInfo
            //     })
            //     .then(response => {
            //        // console.log("response", response)
            //         if(response.data.response_code === 500)
            //         {
            //             this.props.onClose()
            //         }
            //         else
            //         {
            //             this.setState({
            //                 error_message:response.data.message
            //             })
            //         }
            //     })

            // } catch (error) {
            //     console.log("catch error", error)
            // }


        }
        // }

    }
    handleKeypress = (event, values) => {
        // console.log(event.key,'event',this.Avref, values)
        if (event.key == "Enter" && !this.state.disabled_submit) {
            event.preventDefault();
            // this.Avref
            // this.submitData(event)
        }
        // if (this.state.disabled_submit) {
        //     event.preventDefault();
        // }
    };


    render() {
        if (this.state.dataloaded) {
            const optionGroup = this.listOutCategory()
            return (
                <div className="p-3">
                    <Row >
                        <Col >
                            <Card className="overflow-hidden">
                                {
                                    !this.state.formDisabled ?
                                        <CardBody className="pt-0">
                                            <div >
                                                <AvForm className="form-horizontal" onKeyDown={(e, value) => { this.handleKeypress(e, value) }} onValidSubmit={this.submitData} onInvalidSubmit={this.handleInvalidSubmit} >
                                                    
                                                    <Row className="my-2 pb-2" style={{ borderBottom: "1px solid #dedede" }}>
                                                        <div className="d-sm-flex align-items-center justify-content-between">
                                                            <div className="text-secondary font-size-18">Location Information</div>
                                                            <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
                                                        </div>
                                                    </Row>

                                                    <div className="mb-3">
                                                        <AvField
                                                            name="hlevel_name"
                                                            label="Name"
                                                            type="text"
                                                            //defaultValue={this.state.hlevel_name}
                                                            onChange={(e) => { this.validateLocation(e, 1) }}
                                                            value={this.state.hlevel_name}
                                                            required
                                                            placeholder="Enter Name"
                                                        />
                                                        {
                                                            this.state.location_name_err &&
                                                            <div className="text-danger" style={{ fontSize: 'smaller' }}>Location name already exist</div>
                                                        }
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="" htmlFor="autoSizingSelect">Category</Label>
                                                        <AvInput
                                                            type="select"
                                                            name="hlevel_cat"
                                                            label="Name"
                                                            value={this.state.hlevel_cat}
                                                            className="form-select"
                                                            id="cate"
                                                            required
                                                            onChange={(e) => this.selectCat(e)}>
                                                            <option value="" disabled selected>Choose...</option>
                                                            <option value="1"  >Create New</option>
                                                            {
                                                                optionGroup.map((data, idx) => {
                                                                    return (
                                                                        <option value={data} key={idx}>{data}</option>
                                                                    )
                                                                })
                                                            }
                                                        </AvInput>

                                                    </div>
                                                    {
                                                        this.state.createNewCat ?
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="hlevel"
                                                                    type="text"
                                                                    value={this.state.hlevel}
                                                                    required
                                                                    onChange={(e) => this.setState({ catErrorEnabled: false, catError: "", category: e.target.value })}
                                                                    placeholder="Enter New Category"
                                                                />
                                                            </div> : null
                                                    }
                                                    {this.state.catErrorEnabled ? <Label className="text-danger" style={{ margin: '0 0 7px 0' }} htmlFor="autoSizingSelect">{this.state.catError}</Label> : null}
                                                    {/* {
                                                            this.state.enableAddCode ? 
                                                            <div className="form-check mb-3">
                                                                <AvInput
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    name="code_enable"
                                                                    id="invalidCheck"
                                                                    value={this.state.enableCode}
                                                                    onChange={() => this.setState({ enableCode: !this.state.enableCode ? true : false })}
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    htmlFor="invalidCheck"
                                                                >{" "}
                                                                    Add Code
                                                                </Label>
                                                            </div> : null
                                                        } */}

                                                    {/* {
                                                            this.state.enableCode  ? */}
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="code"
                                                            label="Code"
                                                            type="text"
                                                            //errorMessage={this.state.codeError}
                                                            value={this.state.getCode}
                                                            onChange={(e) => { this.validateLocation(e, 2) }}
                                                            validate={{
                                                                required: { value: true, errorMessage: this.state.codeError },
                                                                pattern: { value: /^[a-zA-Z0-9_-]+$/, errorMessage: "Only numbers, letters, underscores, and hyphens are allowed." },
                                                            }}
                                                            placeholder="Enter Code"
                                                        />
                                                        {this.state.codeErrorEnabled ? <Label className="text-danger" style={{ margin: '5px 0 7px 0' }} htmlFor="autoSizingSelect">{this.state.codeError}</Label> : null}
                                                        {
                                                            this.state.code_err &&
                                                            <div className="text-danger" style={{ fontSize: "smaller" }}>Code already exist</div>
                                                        }
                                                    </div>

                                                    {/* : 
                                                                null */}
                                                    {/* } */}

                                                    <Row className="">

                                                        {/* <div className="mb-3">
                                                                <AvField
                                                                    name="target_date"
                                                                    type="date"
                                                                    label="Audit end date"
                                                                    errorMessage="Please provide a valid date."
                                                                    className="form-control"
                                                                    value={this.state.target_date}
                                                                    onChange={(e) => { this.setState({ target_date: e.target.value }) }}
                                                                    validate={{ required: { value: true } }}
                                                                    id="td"
                                                                />
                                                            </div> */}
                                                        {
                                                            console.log('this.state.auditUser', this.state.auditUser)
                                                        }

                                                        <div className="mb-3">
                                                            <Label className="" htmlFor="">Audit assigned to</Label>
                                                            <AvInput
                                                                type="select"
                                                                name="auditUser"
                                                                label="auditUser"
                                                                value={this.state.auditUser}
                                                                className="form-select"
                                                                id="adtuser1"
                                                                required
                                                                onChange={(e) => this.changeAuditUserHandler(e)}>
                                                                <option value="" disabled >Choose...</option>
                                                                {/* <option value="add" >Add New User</option> */}
                                                                {
                                                                    this.state.userdata.map((data, idx) => {
                                                                        return (
                                                                            <option value={data._id} key={idx}>{data.firstname} ({data.usercode}) - {data.designation}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </AvInput>
                                                        </div>

                                                        {
                                                            this.props.publishtemplateInfo.enable_review ?
                                                                <div className="mb-3">
                                                                    <label>Review assigned to</label>
                                                                    <AvInput
                                                                        type="select"
                                                                        name="reviewUser"
                                                                        label="Reviewer"
                                                                        value={this.state.reviewUser}
                                                                        className="form-select"
                                                                        id="rvwUser1"
                                                                        required
                                                                        onChange={(e) => this.changeReviewUserHandler(e)}>
                                                                        <option value="" disabled >Choose...</option>
                                                                        {/* <option value="add" >Add New User</option> */}
                                                                        {
                                                                            this.state.userdata.map((data, idx) => {
                                                                                return (
                                                                                    <option value={data._id} key={idx}>{data.firstname} ({data.usercode}) - {data.designation}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </AvInput>
                                                                </div> : null
                                                        }

                                                    </Row>


                                                    <Row>
                                                        <Col sm="12" >
                                                            <span className="font-size-16 text-danger">{this.state.error_message}</span>
                                                        </Col>
                                                    </Row>
                                                    <div className="mt-4 d-flex flex-row">
                                                        <button
                                                            className="btn btn-success btn-block m-1"
                                                            type="submit"
                                                            disabled={this.state.disabled_submit}
                                                        >
                                                            {this.props.editItem !== null ? "Update Location" : "Add Location"}
                                                        </button>
                                                        <button
                                                            className="btn btn-danger btn-block m-1"
                                                            onClick={() => this.props.onCancel()}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </CardBody> :
                                        <CardBody>
                                            <div className="mt-4 text-center">
                                                <div className="mb-0" style={{ fontSize: 20 }}>
                                                    <span className="text-danger">{this.state.firstname}</span> has added successfully
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <Link className="btn btn-success" onClick={() => this.props.onClose()}>
                                                        Back
                                                    </Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                }
                            </Card>

                        </Col>
                    </Row>
                    {this.state.dataAlert ? (
                        <SweetAlert
                            title={this.state.alertMessage}
                            onConfirm={() => this.setState({ dataAlert: false })}
                        ></SweetAlert>
                    ) : null}
                </div>
            )
        }
        else {
            return null
        }
    }
}