import React, { Component } from "react"

import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  InputGroup,
  Button,
  Alert,
  Modal
} from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"
import facility from '../FacilityScreen'
import SweetAlert from "react-bootstrap-sweetalert"
import LoadGroupUser from './Components/LoadGroupUser'
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  toggleExpandedForAll,
  defaultGetNodeKey,
  getTreeFromFlatData,
  getNodeAtPath,
  changeNodeAtPath,
  getFlatDataFromTree,
} from 'react-sortable-tree/dist/index.cjs.js';

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import TableContainer from "../../../common/TableContainer";
import CalendarPreview from "./Components/CalendarPreview";



import CrudUserHlvl from "./CrudUserHlvl";

import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

// import SortableTree, {
//   addNodeUnderParent,
//   removeNodeAtPath,
//   toggleExpandedForAll,
//   getTreeFromFlatData,
//   getNodeAtPath,
//   changeNodeAtPath,
//   getFlatDataFromTree,
//   walk,
//   map, find
// } from 'react-sortable-tree/dist/index.cjs.js';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';

import classnames from "classnames"

//import AddNode from "./Components/AddNode"

import AddUserNode from "./Components/AddUserNode"
import AddEndpointNode from "./Components/AddEndpointNode"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import CheckboxTree, {
  getNode, flattenNodes, deserializeLists, serializeList
} from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdChevronRight, MdKeyboardArrowDown, MdAddBox, MdIndeterminateCheckBox, MdLocationCity, MdStore, MdFolder, MdCheckCircle, MdDescription } from "react-icons/md";
import CryptoJS from 'crypto-js'
import _, { repeat } from "lodash";

import Validation from "./Functions/validation"
import LoadEndpoint from "./Components/LoadEndpoints"
import { LoadingOutlined } from '@ant-design/icons';

import { FolderOutlined, FileOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { DownOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import './custom-tree-styles.css';
import Swal from "sweetalert2";
import ImportUser from "./ImportUser";




import urlSocket from "../../../helpers/urlSocket";
import { event } from "jquery";
// import { er } from "@fullcalendar/core/internal-common";
// var urlSocket = require("../../helpers/urlSocket");
var moment = require('moment')

const icons = {
  check: <MdCheckBox className="rct-icon rct-icon-check text-success" />,
  uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
  halfCheck: (
    <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
  ),
  expandClose: (
    <MdChevronRight className="rct-icon rct-icon-expand-close" />
  ),
  expandOpen: (
    <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
  ),
  expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
  collapseAll: (
    <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
  ),
  parentClose: <MdLocationCity className="rct-icon rct-icon-parent-close" />,
  parentOpen: <MdLocationCity className="rct-icon rct-icon-parent-open" />,
  leaf: <MdStore className="rct-icon rct-icon-leaf-close" />
}


const nodes = [{
  "value": 'Mars',
  "label": 'Mars',
  "children": [
    { "value": 'Phobos', "label": 'Phobos' },
    { "value": 'Deimos', "label": 'Deimos' },
  ],
}];



export default class publishconfig extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      activeCustomTab: 1,
      selectedGroup: null,
      dataloaded: false,
      isEdit: false,
      show_occurence_valid : true,
      hirerachyData: [],
      hlevelData: [],
      hlevelIsSelected: false,
      endpointSelected: false,
      locationData: [],
      checked: [],
      expanded: [],
      hlevelSelected: '',
      auditUserlevelSelected: false,
      reviewUserlevelSelected: false,
      endpoints: [],
      endpointsAvailable: false,
      repeatModeData: [],
      weekDays: [],
      dayStartNumber: [],
      half_months:[] ,
      userdata: [],
      position: 'right',
      occurence :"",
      open: false,
      height: props.height,
      methodSelected: "0",
      component: "",
      triggerFrom: "",
      paginationPage: 1,
      sderror: false,
      ederror: false,
      publishTemplate: {},
      open_group: false,
      quater_periodicity:[],
      half_yearly_periodicity:[],
      hirerachy_user_level: [],
      allowed_audit_monthy:[],
      alertEnable: false,
      formDisabled: false,
      userInfo: {},
      secret_key: 'Pkdh347ey%3Tgs',
      loading: false,
      invalid_user: false,
      invalid_phone_num: false,
      invalid_usercode: false,
      country_code_err: false,
      permissionsadded: [],
      given_email: '',
      modal:false,
      selected_category:[],
      checkedKeys: [],
      treeData:[],
      dup_treeData:[],
      import_usr : false,
      to_import_users :[],
      selected_quater_months:[],
      selected_half_months:[],
      total_weeks:[],
      calendar_type:[],
      financial_quater_months:[],
      financial_half_months:[],
      allowed_audit_yearly:[],
      total_months:[],
      preview_calendar : false
      // selected_half_months:[]
    }
    this.toggleTab = this.toggleTab.bind(this)
    this.toggleCustomTab = this.toggleCustomTab.bind(this)
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
    this.handleMStartGroupChange = this.handleMStartGroupChange.bind(this)
    this.handleMEndGroupChange = this.handleMEndGroupChange.bind(this)
    this.getDateFormateMethod = this.getDateFormateMethod.bind(this)
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.onCancelDrawerClose = this.onCancelDrawerClose.bind(this)
    this.setPosition = this.setPosition.bind(this);
    this.endpointMethod = this.endpointMethod.bind(this);

  }

  closeDrawer() {
    this.setState({ open: false });
  }

  onDrawerClose() {
    this.setState({ auditUserlevelSelected: false, component: "" })
    this.setState({ open: false });
    try {
      urlSocket.post("webphlbconf/gettemplate", {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id,
        },
        publishtemplateInfo: this.state.publishTemplate
      })
        .then(response => {
          console.log(response,'response')
          if (response.data.response_code === 500) {
            this.setState({
              publishTemplate: response.data.data,
              endpoints: response.data.data.endpoints,
              auditUserlevelSelected: true,
            }, function () {
              this.updatePublishTemplateData()
              this.updateCCLevel()
            })

          }
        })
    } catch (error) {

    }

  }

  onCancelDrawerClose() {
    this.setState({ open: false, component: "" });
  }

  setPosition(e) {
    this.setState({ position: e.target.value });
  }

  getDidmountFunction() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var publishTemplate = JSON.parse(sessionStorage.getItem("publishData"));
    //.log(publishTemplate, 'publishTemplate')

    this.setState(
      {
        userInfo: data.user_data,
        client_info: data.client_info[0],
        configData: data.config_data,
        db_info: db_info,
        repeatModeData: data.config_data.repeat_mode,
        quater_months : data.config_data.quater_months,
        half_months : data.config_data.half_months,
        dataloaded: false,
        total_weeks:data.config_data.total_weeks,
        publishTemplate,
        height: window.innerHeight,
        calendar_type:data.config_data.calendar_type,
        dayStartNumber: this.generateNumber(1),
        dayEndNumber: this.generateNumber(2),
        hlevelIsSelected: false,
        allowed_audit_monthy : data.config_data.allowed_audit_monthy,
        financial_quater_months:data.config_data.financial_quater_months,
        financial_half_months : data.config_data.financial_half_months,
        allowed_audit_yearly : data.config_data.allowed_audit_yearly,
        total_months : data.config_data.total_months
      },
      async function () {
         await this.getTemplateMethod()
        this.getDateFormateMethod()
        this.loadUserLabels()
      }
    )




  }

  getEndpointsData = () => {
    try {
      urlSocket.post("webphlbconf/onchangeaudituserlevel", {
        userInfo: {
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id,
          encrypted_db_url: this.state.db_info.encrypted_db_url
        },
        hInfo: {
          h_id: this.state.hirerachyData._id,
          publishtemplateInfo: this.state.publishTemplate
        }
      })
        .then(response => {
          console.log(response,'response')
          //.log(response, 'response')
          this.setState({ publishTemplate: response.data.data }, () => { this.updatePublishTemplateData() })
        })
    }
    catch (error) {

    }
  }

  async componentDidMount() {

    this.getDidmountFunction()

    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    // var hirerachy_user_level = data.config_data.hirerachy_user_level
    // var hirerachy_user_level = await this.retriveExistCategory()
    // //.log(hirerachy_user_level,'hirerachy_user_level')
    data.config_data.facilities.map((ele, idx) => {
      if (ele.id === "7") {
        data.config_data.facilities[idx].enabled = true
        this.setState({ permissionsadded: [data.config_data.facilities[idx]] }, () => {
        })
      }
    })
    var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"));

    var protect_routes = await facility(user_facilities, 'mngpblhtempt')
    if (protect_routes !== undefined) {
      this.setState(
        {
          sessionUserInfo: data.user_data,
          configData: data.config_data,
          dataloaded: true,
          db_info: db_info,
          client_info: client_info,
          daysOfWeek : data.config_data.days_of_week,
          quater_periodicity: data.config_data.periodicity_quater,
          periodicity_half_yearly: data.config_data.periodicity_half_yearly
          // hirerachy_user_level

        },
        function () {
          // this.retriveExistCategory()
          //    this.getEntityDetaitedData()
        }
      )
    }
    else {
      this.props.history.push('/dashboard')
    }
  }

   retriveExistCategory = (hierarchy_id) => {
    return new Promise((resolve, reject) => {
      const db_info = JSON.parse(sessionStorage.getItem("db_info"));
  
      try {
        urlSocket.post('cog/retrive-categories', {
          encrypted_db_url: db_info.encrypted_db_url,
          hierarchy_id :hierarchy_id
        }).then((response) => {
          console.log(response,'response')
          //.log(response, 'response')
          if (response.data.response_code === 500) {
            this.setState({hirerachy_user_level : response.data.data[0].hirerachy_user_level})
            // resolve(response.data.data[0].hirerachy_user_level);
          } else {
            // resolve(null);
          }
        }).catch((error) => {
          //.error(error, 'error');
          reject(error);
        });
      } catch (error) {
        //.error(error, 'error');
        reject(error);
      }
    });
  }
  

  generateNumber(startNumber) {
    var genNumber = []
    for (var i = startNumber; i <= 31; i++) {
      genNumber.push({
        name: String(i),
        value: String(i)
      })
    }
    return genNumber
  }

  async endpointMethod(method) {
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.method_selected = method
    this.setState({
      methodSelected: method,
      dataloaded: false,
      publishTemplate
    },async function () {
    await this.updatePublishTemplateData()
      if (method === "2") {
        this.gethlevelMethod()
      }
      else {
        this.getCustomMethod()
      }
    })
  }

  resetEndpointMethod() {

    urlSocket.post("webphlbconf/resettemplate", {
      userInfo: {
        encrypted_db_url: this.state.db_info.encrypted_db_url,
        created_by: this.state.userInfo._id,
        company_id: this.state.userInfo.company_id,
      },
      publishtemplateInfo: this.state.publishTemplate
    })
      .then(response => {
        if (response.data.response_code == 500) {
          this.setState({
            publishTemplate: response.data.data,
            changeMethodConfirmation: false,
            methodSelected: response.data.data.method_selected,
            cc_level: response.data.data.cc_level,
            dataloaded: response.data.data.method_selected === "0" ? true : false
          })
        }
      })

  }

  async getTemplateMethod() {
    urlSocket.post("webphlbconf/gettemplate", {
      userInfo: {
        encrypted_db_url: this.state.db_info.encrypted_db_url,
        created_by: this.state.userInfo._id,
        company_id: this.state.userInfo.company_id,
      },
      publishtemplateInfo: this.state.publishTemplate
    })
      .then(response => {
        console.log(response,'response')
        //.log(response,'response')
        if (response.data.response_code == 500) {

          this.setState({
            publishTemplate: response.data.data,
            methodSelected: response.data.data.method_selected,
            cc_level: response.data.data.cc_level,
            dataloaded: response.data.data.method_selected === "0" ? true : false
          },
            function () {
              if(response.data.data.hlevel_id !== undefined && response.data.data.hlevel_id !== null){
                this.retriveExistCategory(response.data.data.hlevel_id)
              }
              if (response.data.data.method_selected === "2") {
                this.gethlevelMethod()
              }
              else if (response.data.data.method_selected === "1") {
                this.getCustomMethod()
              }
              
            }
          )

        }
      })
  }


  loadUserLabels() {
    try {
      urlSocket.post("userSettings/get-user-group", {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          _id: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id
        }
      })
        .then(response => {
          this.setState({
            labelData: response.data.data,

          })
        })

    } catch (error) {
    }
  }








  getCustomMethod() {
    urlSocket.post("webphlbconf/gethlmethod", {
      userInfo: {
        encrypted_db_url: this.state.db_info.encrypted_db_url,
        created_by: this.state.userInfo._id,
        company_id: this.state.userInfo.company_id,
      },
      publishtemplateInfo: this.state.publishTemplate
    })
      .then(response => {
        console.log(response,'response')
        if (response.data.response_code == 500) {

          var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })
          var wkdays = response.data.data.data.repeat_mode_config

          var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]
          var getHour = getTimeFromEvent.slice(0, 2)
          var getMinTime = String(parseInt(getHour + 1)) + "00"

          this.setState({
            publishTemplate: response.data.data.data,
            cc_level: response.data.data.data.cc_level,
            selectedRadioOption: this.state.publishTemplate.repeat_mode_config.based_on,
            occurence: this.state.publishTemplate.repeat_mode_config.no_of_occurance,
            selected_quater_months: response.data.data.data.repeat_mode === "Quaterly" ?response.data.data.data.repeat_mode_config.selected_quater_months :[] ,
            selected_half_months: response.data.data.data.repeat_mode === "Half-Yearly" ? response.data.data.data.repeat_mode_config.selected_half_yearly_months :[],
            dataSource: response.data.data.hlevelData,
            auditUserlevelSelected: true,
            customEnableReview: response.data.data.data.enable_review,
            reviewUserlevelSelected: false,
            endpoints: response.data.data.data.endpoints,
            ptl_startdate: response.data.data.data.start_date,
            ptl_enddate: response.data.data.data.end_date,
            repeat_mode: response.data.data.data.repeat_mode,
            rm_config: response.data.data.data.repeat_mode_config,
            daily_endtime: response.data.data.data.repeat_mode_config.end_time,
            daily_starttime: response.data.data.data.repeat_mode_config.start_time,
            getMinTime,
            selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',
            weekDays: response.data.data.data.repeat_mode_config.days,
            dsf: wkdays.start_on,
            eoem: wkdays.ends_on,
            mstartoption: wkdays.mstartoption,
            mendoption: wkdays.mendoption,
            dayStartNumber: this.generateNumber(1),
            dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
            // dataloaded: true,
          }, function () {
            this.getAllUsers()
          })
        }
      })
  }

  async gethlevelMethod() {
    urlSocket.post("webphlbconf/gethlmethod", {
      userInfo: {
        encrypted_db_url: this.state.db_info.encrypted_db_url,
        created_by: this.state.userInfo._id,
        company_id: this.state.userInfo.company_id,
      },
      publishtemplateInfo: this.state.publishTemplate
    })
      .then(response => {
        //.log(response, 'response')
        console.log(response,'response')
        if (response.data.response_code == 500) {

          var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })
          var wkdays = response.data.data.data.repeat_mode_config

          var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]
          var getHour = getTimeFromEvent.slice(0, 2)
          var getMinTime = String(parseInt(getHour + 1)) + "00"
          //.log(getSelectedHlevelIndex,'getSelectedHlevelIndex')
          if(getSelectedHlevelIndex == -1){
            response.data.data.data["hlevel_id"]=null
            response.data.data.data["hlevel"]=null
            response.data.data.data["hirearchy_type"]=null
            response.data.data.data["ep_selected"]=[]
            response.data.data.data["cc_level"]=0



            // this.state.publishTemplate.hirearchy_type

          }
          _.each(response.data.data.data.endpoints, item => {
                  if (response.data.data.data.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
                    item["rowValid"] = false
                  }
                  else if (!response.data.data.data.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
                    item["rowValid"] = false
                  }
                  else {
                    item["rowValid"] = true
                  }
                })


          if (response.data.data.data.hlevel != null) {

            var getUniqueRecords = _.uniqBy(response.data.data.endpointData, el => `${el.hlevel}-${el.node_positon}`)
            var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.ep_level , epnode_position :response.data.data.data.epnode_position    })
            var getAuditUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.audit_userlevel })
            var getReviewUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.review_userlevel })
            var convertedFlatData
            console.log(response.data.data.endpointData,'response.data.data.endpointData',getUniqueRecords,endpointIndex)

            if (response.data.data.data.ep_structure.length === 0) {
              console.log("if")
              convertedFlatData = this.convertFlatDataToTreeData(response.data.data.endpointData)
            }
            else {
              console.log("else",response,response.data.data.data.ep_structure)
              convertedFlatData = response.data.data.data.ep_structure

              if (response.data.data.data.hirearchy_type == "2" && response.data.data.data.ep_structure.length !==0) {
                this.getAllTreeStructure()
              }
              else {
                this.handleRecursive(convertedFlatData)
                //.log(convertedFlatData, 'convertedFlatData')

              }
            }
            console.log(response.data.data.hstructure,'response.data.data.hstructure')
            sessionStorage.setItem("hstructure", JSON.stringify(response.data.data.hstructure));
            this.setState({
              tree_flat:response.data.data.hstructure,
              publishTemplate: response.data.data.data,
              cc_level: response.data.data.data.cc_level,
              dataSource: response.data.data.hlevelData,
              selectedHLevelValue: String(getSelectedHlevelIndex + 1),
              hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,
              hlevelData: getUniqueRecords,
              hirerachyData: response.data.data.hlevelData[getSelectedHlevelIndex],
              selectedEndpoint: endpointIndex != -1 ? String(endpointIndex + 1) : "choose",
              endpointSelected: endpointIndex != -1 ? true : false,
              hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',
              locationData: convertedFlatData,
              checked: response.data.data.data.ep_selected,
              checkedKeys: response.data.data.data.ep_selected,
              expanded: response.data.data.data.ep_expanded,
              selectedAuditUserlevel: getAuditUserlevelIndex != -1 ? String(getAuditUserlevelIndex + 1) : "choose",
              audit_userlevel: response.data.data.data.audit_userlevel,
              review_userlevel: response.data.data.data.review_userlevel,
              auditUserlevelSelected: getAuditUserlevelIndex != -1 ? true : false,
              enableReview: response.data.data.data.enable_review,
              selectedReviewUserlevel: getReviewUserlevelIndex != -1 ? String(getReviewUserlevelIndex + 1) : "choose",
              reviewUserlevelSelected: getReviewUserlevelIndex != -1 ? true : false,
              endpoints: response.data.data.data.endpoints,
              ptl_startdate: response.data.data.data.start_date,
              ptl_enddate: response.data.data.data.end_date,
              repeat_mode: response.data.data.data.repeat_mode,
              rm_config: response.data.data.data.repeat_mode_config,
              selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',
              daily_endtime: response.data.data.data.repeat_mode_config.end_time,
              daily_starttime: response.data.data.data.repeat_mode_config.start_time,
              getMinTime,
              weekDays: response.data.data.data.repeat_mode_config.days,
              dsf: wkdays.start_on,
              eoem: wkdays.ends_on,
              mstartoption: wkdays.mstartoption,
              mendoption: wkdays.mendoption,
              dayStartNumber: this.generateNumber(1),
              dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
              // dataloaded: false,
            }, function () {
              this.getAllUsers()
            })
          }
          else {
            this.setState({
              publishTemplate: response.data.data.data,
              cc_level: response.data.data.data.cc_level,
              dataSource: response.data.data.hlevelData,
              selectedHLevelValue: getSelectedHlevelIndex != -1 ? String(getSelectedHlevelIndex + 1) : "0",
              hlevelIsSelected: false,
              hlevelData: [],
              selectedEndpoint: "choose",
              endpointSelected: false,
              hlevelSelected: '',
              locationData: [],
              selectedAuditUserlevel: 'choose',
              audit_userlevel: response.data.data.data.audit_userlevel,
              review_userlevel: response.data.data.data.review_userlevel,
              auditUserlevelSelected: false,
              enableReview: false,
              selectedReviewUserlevel: 'choose',
              reviewUserlevelSelected: false,
              endpoints: response.data.data.data.endpoints,
              ptl_startdate: response.data.data.data.start_date,
              ptl_enddate: response.data.data.data.end_date,
              repeat_mode: null,
              rm_config: response.data.data.data.repeat_mode_config,
              daily_endtime: response.data.data.data.repeat_mode_config.end_time,
              daily_starttime: response.data.data.data.repeat_mode_config.start_time,
              getMinTime,
              selectedRepeatMode: 'choose',
              weekDays: response.data.data.data.repeat_mode_config.days,
              dsf: wkdays.start_on,
              eoem: wkdays.ends_on,
              mstartoption: wkdays.mstartoption,
              mendoption: wkdays.mendoption,
              dayStartNumber: this.generateNumber(1),
              dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
              // dataloaded: false,
            }, function () {
              this.getAllUsers()
            })
          }
        }
      })
  }



  handleRecursive = (epTreedata) => {
    return epTreedata.map((item) => {
      if (item.children !== undefined && item.children !== null) {
        item["ep"] = true;
        item.children = this.handleRecursive(item.children);
      }
      return item;
    }).filter((item) => {
      // Filter out elements with null children and ep_level not matching
      return item.children !== null || this.state.publishTemplate.ep_level === item.ep_level;
    });
  }

  async getAllUsers() {
    try {
      urlSocket.post("webphlbconf/getallusers", {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id,
        },
        publishtemplateInfo: this.state.publishTemplate
      })
        .then((response) => {
          console.log(response,'response')
          this.setState({
            userdata: response.data.data,
            dataloaded: true
          })
        })
    } catch (error) {
      console.log(error,'error')
    }
  }


  getHStructureListData = () => {
    try {

      urlSocket.post("webphlbconf/gethirhylevels", {
        userInfo: {
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id,
        },
        publishtemplateInfo: this.state.publishTemplate
      })
        .then((response) => {
          var getSelectedHlevelIndex = _.findIndex(response.data.data, { hname: this.state.publishTemplate.hlevel })
         //.log(getSelectedHlevelIndex,'getSelectedHlevelIndex')
          this.setState({
            dataSource: response.data.data,
            selectedHLevelValue: String(getSelectedHlevelIndex + 1),
            hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,
            dataloaded: true,
          })
          if (getSelectedHlevelIndex !== -1) {
          }
        })
    } catch (error) {
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab === 2) {
        this.setState({ auditUserlevelSelected: false })
        setTimeout(() => {
          this.setState({ auditUserlevelSelected: true })
        }, 10)
      }
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        })
      }
    }
  }

  toggleCustomTab(tab) {
    if (this.state.activeCustomTab !== tab) {

      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeCustomTab: tab,
        })
      }
    }
  }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }

  selectLevel = (event) => {

    var hirerachyData = this.state.dataSource[parseInt(event.target.value) - 1] //JSON.parse(event.target.value)
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.hlevel = hirerachyData.hname
    publishTemplate.hlevel_id = hirerachyData._id
    publishTemplate.ep_level = null
    publishTemplate.ep_structure = []
    publishTemplate.eplevel_value = null
    publishTemplate.ep_selected = []
    publishTemplate.ep_expanded = []
    publishTemplate.endpoints = []
    publishTemplate.audit_userlevel = null
    publishTemplate.audit_userlevel_value = null
    publishTemplate.enable_review = false
    publishTemplate.review_userlevel = null
    publishTemplate.review_userlevel_value = null
    publishTemplate.start_date = null
    publishTemplate.end_date = null
    publishTemplate.frequency = "One time"
    publishTemplate.hirearchy_type = null

    this.setState({
      checked: [],
      selectedAuditUserlevel: 'choose',
      auditUserlevelSelected: false,
      checkedKeys: [],
      publishTemplate
    },()=>{

    if (this.state.endpointSelected) 
    // document.getElementById("location").value = "choose"

    this.updateCCLevel()
    try {
      urlSocket.post("webphlbconf/getlocationlevels", {
        userInfo: {
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id,
          encrypted_db_url: this.state.db_info.encrypted_db_url
        },
        hInfo: {
          h_id: hirerachyData._id,
          publishtemplateInfo: this.state.publishTemplate
        }
      })
        .then(response => {
          if (response.data.response_code == 500) {
            var getUniqueRecords = _.uniqBy(response.data.data, el => `${el.hlevel}`)
            var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: this.state.publishTemplate.ep_level })
            this.setState({
              cc_level: 0,
              hlevelData: getUniqueRecords,
              hirerachyData,
              selectedEndpoint: 'choose',
              endpointSelected: false,
              hlevelIsSelected: true,
              hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',
              ptl_startdate: null,
              ptl_enddate: null,
              ptl_frequency: "One time",
            },()=>{this.retriveExistCategory(hirerachyData._id);this.gethlevelMethod()})
          }
        })
    } catch (error) {
    }
  })
  }


  selectLocation(event) {

    var endpointInfo = this.state.hlevelData[parseInt(event.target.value) - 1]
    console.log(endpointInfo,'endpointInfo')
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.ep_level = endpointInfo.hlevel
    publishTemplate.eplevel_value = endpointInfo.hlevel_value
    publishTemplate.epnode_position = endpointInfo.node_positon
    publishTemplate.ep_structure = []
    publishTemplate.ep_selected = []
    publishTemplate.ep_expanded = []
    publishTemplate.audit_userlevel = null
    publishTemplate.audit_userlevel_value = null
    publishTemplate.enable_review = false
    publishTemplate.review_userlevel = null
    publishTemplate.review_userlevel_value = null
    publishTemplate.start_date = null
    publishTemplate.end_date = null
    publishTemplate.frequency = "One time"
    sessionStorage.setItem('hlevel',endpointInfo.hlevel)
    sessionStorage.setItem('node_positon',endpointInfo.node_positon)

    this.setState({ checked: [], expanded: [], selectedEndpoint: event.target.value, checkedKeys: [],publishTemplate },()=>{
    this.updateCCLevel()
    urlSocket.post("webphlbconf/getlocations", {
      userInfo: {
        created_by: this.state.userInfo._id,
        company_id: this.state.userInfo.company_id,
        encrypted_db_url: this.state.db_info.encrypted_db_url
      },
      hInfo: {
        h_id: this.state.hirerachyData._id,
        hlevelinfo: endpointInfo,
        publishtemplateInfo: this.state.publishTemplate
      }
    })
      .then(response => {
        console.log(response,'response')
        if (response.data.response_code == 500) {
          var convertedFlatData = this.convertFlatDataToTreeData(response.data.data)
          console.log(response.data.data,'response.data.data')
          var publishTemplate ={...this.state.publishTemplate}
          publishTemplate.ep_structure = convertedFlatData
          this.handleRecursive(convertedFlatData)
          // this.setState({
          //   locationData: convertedFlatData,
          // },
          //   function () {
          //     this.updateCheckedepStructure()
          //   }
          setTimeout(()=>{
            this.setState({locationData: convertedFlatData, publishTemplate},()=>(this.updateCheckedepStructure()))
          },100);
        
        }
      })

    this.setState({
      endpointSelected: true,
      hlevelSelected:
        this.state.hlevelData[parseInt(event.target.value) - 1].hlevel,
      selectedAuditUserlevel: 'choose',
      auditUserlevelSelected: false,
      enableReview: false,
      selectedReviewUserlevel: 'choose',
      reviewUserlevelSelected: false,
      cc_level: 0,
      ptl_startdate: null,
      ptl_enddate: null,
      ptl_frequency: "One time",
    })
  })
  }

  convertFlatDataToTreeData = (arr) => {

    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    console.log(arr,'arr')
    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = {
        "value": arr[i]._id,
        "label": arr[i].hlevel_name,
        "children": arr[i].children,
        "id": arr[i].id,
        "parent": arr[i].parent,
        "node_id": arr[i]._id,
        "ep_level": arr[i].hlevel,
        "ep_level_value": arr[i].hlevel_value,
        "user_path": arr[i].user_path,
        "unique_users": arr[i].unique_users,
        "node_positon":arr[i].node_positon,
        "user_permission_acpln" : arr[i].user_permission_acpln,
        "code" : arr[i].hlevel_code

      }
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]['children'] = []
    }


    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];

        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parent) {
          // //.log(mappedElem,'mappedElem',mappedArr,mappedArr[mappedElem['parent']])
          if (mappedArr[mappedElem['parent']] !== undefined) {
            mappedArr[mappedElem['parent']]['children'].push(mappedElem);
          }
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }

    let update = () => obj => {
      if (obj.children.length > 0)
        obj.children.forEach(update());
      else
        obj.children = null
    };

    tree.forEach(update());
    return tree;
  }

  handleEndpointSelection = (checked, event) => {
    var publishTemplate ={...this.state.publishTemplate}
    this.setState({ checkedKeys: checked, cc_level: checked.length > 0 ? 1 : 0 }, async function () {
      publishTemplate.ep_structure = this.state.locationData
      publishTemplate.ep_selected = checked
      publishTemplate["cc_level"] = checked.length > 0 ? 1 : 0
      this.updateCheckedepStructure("allow")
      this.setState({
        publishTemplate
      })
    });
  }

  handleEndpointExpanded = expanded => {
    var publishTemplate ={...this.state.publishTemplate}
    this.setState({ expanded }, function () {
      publishTemplate.ep_structure = this.state.locationData
      publishTemplate.ep_expanded = expanded
      this.updateCheckedepStructure()
      this.setState({
        publishTemplate
      })
    })
  }

  updateCheckedepStructure(access) {
    this.updateCCLevel()
    try {
      urlSocket.post("webphlbconf/updateepstructure", {
        userInfo: {
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id,
          encrypted_db_url: this.state.db_info.encrypted_db_url
        },
        hInfo: {
          publishtemplateInfo: this.state.publishTemplate
        }
      })
        .then(response => {
          console.log(response,'response')
          //.log(response,'response')
          if (access == "allow" && this.state.publishTemplate.hirearchy_type !== "2") {
            this.getEndpointsData()
          }
          // else {
          //   console.log('elseeeee',this.state.dataSource, this.state.selectedHLevelValue)
          //   var getSelectedHlevelIndex = _.findIndex(this.state.dataSource, { hname: this.state.publishTemplate.hlevel })
          //   this.setState({locationData: this.state.locationData, dataloaded: false , selectedHLevelValue : String(getSelectedHlevelIndex +1)},()=>{this.updateCCLevel()})
            
          // }
          else {
            this.updateCCLevel()
            this.updatePublishTemplateData()
            var getSelectedHlevelIndex = _.findIndex(this.state.dataSource, { hname: this.state.publishTemplate.hlevel })
            this.setState({locationData:this.state.locationData,dataloaded:false ,selectedHLevelValue : String(getSelectedHlevelIndex +1)},()=>{this.updateCCLevel()})
          }
        })
    } catch (error) {

    }
  }

  selectAuditUserlevel(event) {
    var endpoints = this.state.publishTemplate.endpoints
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.start_date = null
    publishTemplate.end_date = null
    publishTemplate.frequency = "One time"

    var selected_audit_type = event.target.value
    if (this.state.publishTemplate.hirearchy_type !== "2") {
      if (this.state.publishTemplate.review_userlevel !== event.target.value) {
        publishTemplate.audit_userlevel = event.target.value
        publishTemplate.audit_userlevel_value = Number(event.target.value)
        this.setState({
          publishTemplate,
          selectedAuditUserlevel: event.target.value,
          audit_userlevel: event.target.value == "1" ? "Auditor" : event.target.value == "2" ? "Reviewer" : event.target.value == "3" ? "External Auditer" : null,
        },()=>{
        

        try {
          urlSocket.post("webphlbconf/onchangeaudituserlevel", {
            userInfo: {
              created_by: this.state.userInfo._id,
              company_id: this.state.userInfo.company_id,
              encrypted_db_url: this.state.db_info.encrypted_db_url
            },
            hInfo: {
              h_id: this.state.hirerachyData._id,
              publishtemplateInfo: this.state.publishTemplate,
              selected_audit_type: event.target.value
            }
          })
            .then(response => {
              //.log(response,'response')
              if (response.data.response_code === 500) {
                // _.each(response.data.data.endpoints, item => {
                //   if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
                //     item["rowValid"] = false
                //   }
                //   else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
                //     item["rowValid"] = false
                //   }
                //   else {
                //     item["rowValid"] = true
                //   }
                // })
                publishTemplate.endpoints = response.data.data.endpoints
                publishTemplate = response.data.data
                this.setState({
                  endpoints: response.data.data.endpoints, endpointsAvailable: true,
                  publishTemplate,
                  enableReview: response.data.data.enable_review,
                  // selectedReviewUserlevel: 'choose',
                  auditUserlevelSelected: true,
                  // cc_level : 
                  // reviewUserlevelSelected: false,
                  cc_level: response.data.data.cc_level,
                  ptl_startdate: null,
                  ptl_enddate: null,
                  ptl_frequency: "One time",
                  auditUserlevelSelected: true,
                }, () => { this.updatePublishTemplateData() })
              }
            })
        } catch (error) {
        }
      })
      }
      else {
        publishTemplate["audit_userlevel"] = this.state.publishTemplate["audit_userlevel"]
        publishTemplate["audit_userlevel_value"] = this.state.publishTemplate["audit_userlevel_value"]
        this.setState({ dataAlert: true, publishTemplate,alertMessage: "This user is already selected to Reviewe. Try different user." })
      }
    }
    else {
      if (this.state.publishTemplate.review_userlevel !== event.target.value) {
        publishTemplate.audit_userlevel = event.target.value
        publishTemplate.audit_userlevel_value = Number(event.target.value)
        this.setState({
          selectedAuditUserlevel: event.target.value,
          audit_userlevel: event.target.value == "1" ? "Auditer" : event.target.value == "2" ? "Reviewer" : event.target.value == "3" ? "External Auditer" : null,
          auditUserlevelSelected: false,
          publishTemplate,
          endpoints: this.state.publishTemplate.endpoints
        },()=>{
        urlSocket.post('webphlbconf/update-unorganised-hierarchy-data-auditor', {
          userInfo: {
            created_by: this.state.userInfo._id,
            company_id: this.state.userInfo.company_id,
            encrypted_db_url: this.state.db_info.encrypted_db_url
          },
          hInfo: {
            h_id: this.state.hirerachyData._id,
            publishtemplateInfo: this.state.publishTemplate,
            selected_audit_type: event.target.value
          }
        }).then((response) => {
          if (response.data.response_code == 500) {
            _.each(response.data.data.publishtemplateInfo.endpoints, item => {
              if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
                item["rowValid"] = false
              }
              else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
                item["rowValid"] = false
              }
              else {
                item["rowValid"] = true
              }
            })
            this.setState({
              endpoints: response.data.data.publishtemplateInfo.endpoints,
              endpointsAvailable: true,
              publishTemplate: response.data.data.publishtemplateInfo,
              ptl_startdate: null,
              ptl_enddate: null,
              ptl_frequency: "One time",
              auditUserlevelSelected: true,
            }, () => { this.updatePublishTemplateData() })
          }
        })
      }
      )
      }

      else {
        publishTemplate["audit_userlevel"] = this.state.publishTemplate["audit_userlevel"]
        publishTemplate["audit_userlevel_value"] = this.state.publishTemplate["audit_userlevel_value"]
        this.setState({ dataAlert: true,publishTemplate, alertMessage: "This user is already selected to Reviewe. Try different user." })
      }

    }

  }


   appendUsers=(template_data, count)=> {
    console.log(template_data, count,'template_data, count')
    try {
      if (template_data.endpoints.length > count) {
        var auditor_data = _.filter(template_data.endpoints[count].user_path, {
          cat_type: "2",
        });
                      // var getIndex = _.findIndex(template_data.endpoints[count].adt_users)
        console.log(auditor_data.length, 'auditor_data');
        if (auditor_data.length > 0 && auditor_data.length < 2) {
          auditor_data[0]["audit_type"]= "2"
          var getIndex = _.findIndex(template_data.endpoints[count].adt_users,{user_id : auditor_data[0].user_id})
          console.log(getIndex,'getIndex')
          if(getIndex === -1){
          template_data.endpoints[count].adt_users.push(auditor_data[0]);
          }
          // else{
          //   template_data.endpoints[count].adt_users[getIndex] = auditor_data[0]

          // }
        }
        else{

        }
        count++;
        // Use return to propagate the result of the recursive call
        return this.appendUsers(template_data, count);
      } else {
        console.log("loop over", template_data.endpoints);
  
        // Return the result here
        return template_data;
      }
    } catch (error) {
      console.log(error,'error')
    }
  }









  onchangeCustomEnableReview =async (e) => {
    this.setState({ auditUserlevelSelected: false })
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.enable_review = e.target.checked
    var checkedData = e.target.checked
    console.log(publishTemplate,'publishTemplate')
    _.each(publishTemplate.endpoints, item => {
      if (checkedData && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
        item["rowValid"] = false
      }
      else if (!checkedData && !_.some(item.adt_users, { audit_type: "1" })) {
        item["rowValid"] = false
      }
      else{
        item["rowValid"] = false
      }
    })
    if(publishTemplate.enable_review === true){
     var updated_data= await this.appendUsers(publishTemplate,0)
     console.log(updated_data,'updated_data')
    }



    setTimeout(() => {
      this.setState({
        customEnableReview: checkedData,
        auditUserlevelSelected: true,
        publishTemplate
      },async()=>{
        // await this.retriveDefaultReviewer(this.state.publishTemplate)
        this.updatePublishTemplateData()
      })
    }, 10)  
  }


  retriveDefaultReviewer=(publishTemplate)=>{

    try {
      urlSocket.post("webphlbconf/retrive-default-reviewer",{
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id,
        },
        publishtemplateInfo: publishTemplate
      }).then((response)=>{
        console.log(response,'response')
        if(response.data.response_code === 500){
          this.setState({
            publishTemplate: response.data.data,
            endpoints : response.data.data.endpoints
          },()=>{
            this.updatePublishTemplateData()
          })
        } 
      })
    } catch (error) {
      
    }


  }


  onchangeEnableReview(e) {
    this.setState({ auditUserlevelSelected: false })
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.enable_review = e.target.checked
    publishTemplate.review_userlevel = null
    publishTemplate.review_userlevel_value = null
    publishTemplate.start_date = null
    publishTemplate.end_date = null
    publishTemplate.frequency = "One time"

    var checkedData = e.target.checked
    _.each(publishTemplate.endpoints, item => {

      if (checkedData && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
        item["rowValid"] = false
      }
      else if (!checkedData && !_.some(item.adt_users, { audit_type: "1" })) {
        // //.log(item,'item')
        item["rowValid"] = false
      }
      else {
        item["rowValid"] = true
      }
    })

    setTimeout(() => {
      this.setState({
        enableReview: checkedData,
        reviewUserlevelSelected: false,
        selectedReviewUserlevel: 'choose',
        ptl_startdate: null,
        ptl_enddate: null,
        ptl_frequency: "One time",
        review_userlevel: null,
        auditUserlevelSelected: true,
        publishTemplate
      })
    }, 10)
    if(checkedData == true){
      this.setState({
        publishTemplate
      },()=>{
        this.defaultSelectReviewUser("default")

      })
    }
    else{
      publishTemplate.endpoints.map((data,idx)=>{
        // if()
        var filtered_auditor_data = _.filter(data.adt_users, { audit_type: "1" })
        publishTemplate["endpoints"][idx]["adt_users"]=filtered_auditor_data
      })
      //.log(this.state.publishTemplate,'publish')
      this.setState({
        publishTemplate
      },()=>{
        this.updatePublishTemplateData()
      })
    } 
   

   
  }



  defaultSelectReviewUser =(eventValue)=>{
    this.setState({ auditUserlevelSelected: false })
    var publishTemplate ={...this.state.publishTemplate}
    if (eventValue == "default") {
      publishTemplate.review_userlevel = "2"
      publishTemplate.review_userlevel_value = 2
      publishTemplate.start_date = null
      publishTemplate.end_date = null
      // this.state.publishTemplate.repeat_mode = null
      try {
        urlSocket.post("webphlbconf/onchangereviewuserlevel", {
          userInfo: {
            created_by: this.state.userInfo._id,
            company_id: this.state.userInfo.company_id,
            encrypted_db_url: this.state.db_info.encrypted_db_url
          },
          hInfo: {
            h_id: this.state.hirerachyData._id,
            publishtemplateInfo: publishTemplate,
            selected_audit_type: undefined
          }
        })
          .then(response => {
            console.log(response,'response')
            //.log(response, 'response')
            if (response.data.response_code === 500) {
              if (publishTemplate.enable_review) {
                _.each(response.data.data.endpoints, item => {
                  if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
                    item["rowValid"] = false
                  }
                  else {
                    item["rowValid"] = true

                  }
                })
              }
              this.setState({
                publishTemplate: response.data.data,
                selectedReviewUserlevel: eventValue,
                reviewUserlevelSelected: true,
                cc_level: response.data.data.cc_level,
                ptl_startdate: null,
                ptl_enddate: null,
                repeatMode: null,
                endpoints: response.data.data.endpoints,
                review_userlevel: eventValue,
                auditUserlevelSelected: true,
              },()=>{this.updatePublishTemplateData()}
              )
            }
          })
      } catch (error) {
      }
    }



  }






  selectReviewUserlevel(event) {
    this.setState({ auditUserlevelSelected: false })
    // if (event !== "default") {
      var eventValue = event.target.value
    // }
    var publishTemplate ={...this.state.publishTemplate}
    if (this.state.publishTemplate.hirearchy_type !== "2") {
       if (this.state.publishTemplate.audit_userlevel !== eventValue) {
        this.updateCCLevel()
        publishTemplate.review_userlevel = eventValue
        publishTemplate.review_userlevel_value = Number(event.target.value)
        publishTemplate.start_date = null
        publishTemplate.end_date = null
        publishTemplate.repeat_mode = null
        try {
          urlSocket.post("webphlbconf/onchangereviewuserlevel", {
            userInfo: {
              created_by: this.state.userInfo._id,
              company_id: this.state.userInfo.company_id,
              encrypted_db_url: this.state.db_info.encrypted_db_url
            },
            hInfo: {
              h_id: this.state.hirerachyData._id,
              publishtemplateInfo: publishTemplate,
              selected_audit_type: eventValue
            }
          })
            .then(response => {

              this.setState({
                publishTemplate},()=>{
              this.updateCCLevel()
              if (response.data.response_code === 500) {
                if (publishTemplate.enable_review) {
                  _.each(response.data.data.endpoints, item => {
                    if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
                      item["rowValid"] = false
                    }
                    else {
                      item["rowValid"] = true

                    }
                  })
                }
                this.setState({
                  publishTemplate: response.data.data,
                  selectedReviewUserlevel: eventValue,
                  reviewUserlevelSelected: true,
                  cc_level: response.data.data.cc_level,
                  ptl_startdate: null,
                  ptl_enddate: null,
                  repeatMode: null,
                  endpoints: response.data.data.endpoints,
                  review_userlevel: eventValue,
                  auditUserlevelSelected: true,
                },
                )
              }
            })
            })
        } catch (error) {
        }
      }
      else {
        publishTemplate["review_userlevel"] = eventValue
        publishTemplate["review_userlevel_value"] = this.state.publishTemplate["review_userlevel_value"]
        this.setState({ dataAlert: true, publishTemplate,alertMessage: "This user is already selected to auditer. Try different user." })
      }
    }
    else {
      if (this.state.publishTemplate.audit_userlevel !== event.target.value) {
        publishTemplate.review_userlevel = event.target.value
        publishTemplate.review_userlevel_value = Number(event.target.value)
        try {
          urlSocket.post('webphlbconf/update-unorganised-hierarchy-data-reviewer', {
            userInfo: {
              created_by: this.state.userInfo._id,
              company_id: this.state.userInfo.company_id,
              encrypted_db_url: this.state.db_info.encrypted_db_url
            },
            hInfo: {
              h_id: this.state.hirerachyData._id,
              publishtemplateInfo: publishTemplate,
              selected_audit_type: event.target.value
            }
          }).then((response) => {
            if (response.data.response_code == 500) {
              _.each(response.data.data.publishtemplateInfo.endpoints, item => {
                if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
                  item["rowValid"] = false
                }
                else if (!publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
                  item["rowValid"] = false
                }
                else {
                  item["rowValid"] = true
                }
              })
              this.setState({
                endpoints: response.data.data.publishtemplateInfo.endpoints,
                endpointsAvailable: true,
                publishTemplate: response.data.data.publishtemplateInfo,
                ptl_startdate: null,
                ptl_enddate: null,
                ptl_frequency: "One time",
                review_userlevel: eventValue,
                auditUserlevelSelected: true,
                reviewUserlevelSelected: true,
                selectedReviewUserlevel: eventValue,
              }, () => { this.updatePublishTemplateData() })
            }


          })

        } catch (error) {

        }
      }
      else {
        publishTemplate["review_userlevel"] = this.state.publishTemplate["review_userlevel"]
        publishTemplate["review_userlevel_value"] = this.state.publishTemplate["review_userlevel_value"]
        this.setState({ dataAlert: true,publishTemplate, alertMessage: "This user is already selected to auditer. Try different user." })
      }


    }


  }








  changeAuditUserHandler(event, rowData, idx, parameter) {
    console.log(rowData,'rowData')
    var publishTemplate ={...this.state.publishTemplate}
    //.log(event.target.value, rowData, idx, parameter, 'event, rowData, idx,parameter', this.state.publishTemplate)
    if (parameter === "1" && event.target.value !== "add") {
      var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
    }
    else {
      var checkUserAlreadyExist = -1
    }
    //.log(checkUserAlreadyExist, 'checkUserAlreadyExist')
    if (checkUserAlreadyExist !== -1 && (this.state.customEnableReview || this.state.enableReview)) {
      this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
    }
    else if (event.target.value === "add") {
      this.setState({ modal: this.state.modal ? false : true,add_reviwer :false, component: "user", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData })
    }
    else if(event.target.value == "#import"){
      console.log("import",rowData,this.state.userdata)
      var filtered_users =_.differenceBy(this.state.userdata, rowData.unique_users, '_id');
      console.log(filtered_users,'filtered_users')
      this.setState({ open: this.state.open ? false : true,coloumn_type :"1", add_reviwer :false, component: "import_usr", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData ,to_import_users : filtered_users })
    }
    else if (event.target.value == "loadgroupusers") {
      this.setState({ open: this.state.open ? false : true, component: "loadgroupusers", endpoint_data: rowData })

    }

    else {
      this.setState({ auditUserlevelSelected: false })
      var auditUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
      var getIndex = _.findIndex(publishTemplate.endpoints, { _id: (rowData._id) })
      var getUserIndex = _.findIndex(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
      //.log(auditUser, getIndex, getUserIndex, 'getUserIndex')
      if (getUserIndex == -1) {
        publishTemplate.endpoints[getIndex].adt_users.push({
          user_id: auditUser._id,
          name: auditUser.firstname,
          designation: auditUser.designation,
          user_code: auditUser.usercode,
          cat_type: publishTemplate.audit_userlevel,
          audit_type: "1"
        })
        var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
        var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

        publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

      }
      else {
        publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
        publishTemplate.endpoints[getIndex].adt_users.push({
          user_id: auditUser._id,
          name: auditUser.firstname,
          designation: auditUser.designation,
          user_code: auditUser.usercode,
          cat_type: publishTemplate.audit_userlevel,
          audit_type: "1"
        })
        var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
        var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
        publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

      }
      this.setState({
        publishTemplate
      },()=>{
      //.log(this.state.publishTemplate, 'getIndex', this.state.cc_level)
      // this.updateCCLevel()
      this.updatePublishTemplateData()
      setTimeout(() => {
        this.setState({
          endpoints: this.state.publishTemplate.endpoints,
          auditUserlevelSelected: true,
        })
      }, 10)
    })
    }
    this.setState({ user_type: 0 })

  }

  changeReviewUserHandler(event, rowData, idx, parameter) {
    console.log(event, rowData, idx, parameter, 'event, rowData, idx, parameter')
    var publishTemplate ={...this.state.publishTemplate}
    if (parameter === "1") {
      var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
    }
    else {
      var checkUserAlreadyExist = -1
    }

    if (checkUserAlreadyExist !== -1) {
      this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
    }
    else {
      if (event.target.value === "add") {
        this.setState({ modal: this.state.modal ? false : true, component: "user", add_reviwer: true, selected_index: idx, given_email: "", location_info: rowData })
        // this.setState({ open: this.state.open ? false : true, component: "user", selected_index: idx })
      }
      else if (event.target.value == "#import") {
        var filtered_users = _.differenceBy(this.state.userdata, rowData.unique_users, '_id');
        console.log(filtered_users, 'filtered_users')
        this.setState({ open: this.state.open ? false : true, coloumn_type: "2", add_reviwer: true, component: "import_usr", emptData: rowData, selected_index: idx, given_email: "", location_info: rowData, to_import_users: filtered_users })
      }
      else {
        this.setState({ auditUserlevelSelected: false })

        var reviewUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
        var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })


        var getUserIndex = _.findIndex(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

        if (getUserIndex == -1) {
          publishTemplate.endpoints[getIndex].adt_users.push({
            user_id: reviewUser._id,
            name: reviewUser.firstname,
            designation: reviewUser.designation,
            user_code: reviewUser.usercode,
            cat_type: publishTemplate.review_userlevel,
            review_user: true,
            audit_type: "2"
          })
          var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
          var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
        }
        else {
          publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
          publishTemplate.endpoints[getIndex].adt_users.push({
            user_id: reviewUser._id,
            name: reviewUser.firstname,
            designation: reviewUser.designation,
            user_code: reviewUser.usercode,
            cat_type: publishTemplate.review_userlevel,
            review_user: true,
            audit_type: "2"
          })
          var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
          var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

        }
        _.each(publishTemplate.endpoints, item => {
          // //.log(!_.some(item.adt_users, { audit_type: "1"})
          if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
            item["rowValid"] = false
          }
          else if (!publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
            item["rowValid"] = false
          }
          else {
            item["rowValid"] = true
          }
        })
        this.setState({
          publishTemplate
        },()=>{
        this.updatePublishTemplateData()
        setTimeout(() => {
          this.setState({
            endpoints:this.state.publishTemplate.endpoints,
            auditUserlevelSelected: true,
          })

        }, 10)
      })
      }
    }
    this.setState({ user_type: 1 })
    // this.updateCCLevel()

  }

  // changeReviewUserHandler(event, rowData, idx, parameter) {
  //   console.log(event, rowData, idx, parameter,'event, rowData, idx, parameter')
  //   var publishTemplate ={...this.state.publishTemplate}
  //   if (parameter === "1") {
  //     var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
  //   }
  //   else {
  //     var checkUserAlreadyExist = -1
  //   }
  //   console.log(checkUserAlreadyExist,'checkUserAlreadyExist')
  //   if (checkUserAlreadyExist !== -1) {
  //     this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
  //   }
  //   else {
  //     if (event.target.value === "add") {
  //       this.setState({ modal: this.state.modal ? false : true, component: "user",add_reviwer:true, selected_index: idx , given_email :"" ,location_info :rowData })
  //       // this.setState({ open: this.state.open ? false : true, component: "user", selected_index: idx })
  //     }
  //     else if(event.target.value == "#import"){
  //       var filtered_users =_.differenceBy(this.state.userdata, rowData.unique_users, '_id');
  //       console.log(filtered_users,'filtered_users')
  //       this.setState({ open: this.state.open ? false : true,coloumn_type :"2", add_reviwer :true, component: "import_usr", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData ,to_import_users : filtered_users })
  //     }
  //     else {
  //       this.setState({ auditUserlevelSelected: false })

  //       var reviewUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
  //       var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })


  //       var getUserIndex = _.findIndex(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

  //       if (getUserIndex == -1) {
  //         publishTemplate.endpoints[getIndex].adt_users.push({
  //           user_id: reviewUser._id,
  //           name: reviewUser.firstname,
  //           designation: reviewUser.designation,
  //           user_code: reviewUser.usercode,
  //           cat_type: publishTemplate.review_userlevel,
  //           review_user: true,
  //           audit_type: "2"
  //         })
  //         var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
  //         var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
  //       }
  //       else {
  //         publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
  //         publishTemplate.endpoints[getIndex].adt_users.push({
  //           user_id: reviewUser._id,
  //           name: reviewUser.firstname,
  //           designation: reviewUser.designation,
  //           user_code: reviewUser.usercode,
  //           cat_type: publishTemplate.review_userlevel,
  //           review_user: true,
  //           audit_type: "2"
  //         })
  //         var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
  //         var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

  //       }
  //       _.each(publishTemplate.endpoints, item => {
  //         // //.log(!_.some(item.adt_users, { audit_type: "1"})
  //         if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
  //           item["rowValid"] = false
  //         }
  //         else if (!publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
  //           item["rowValid"] = false
  //         }
  //         else {
  //           item["rowValid"] = true
  //         }
  //       })


  //       this.setState({
  //         publishTemplate
  //       },()=>{

  //       this.updatePublishTemplateData()
  //       setTimeout(() => {
  //         this.setState({
  //           auditUserlevelSelected: true,
  //         })

  //       }, 10)
  //     })
  //     }
  //   }
  //   this.setState({ user_type: 1 })
  //   // this.updateCCLevel()

  // }

  checkEndpoints = () => {
    var checkAudituser = _.some(this.state.publishTemplate.endpoints, { audit_user: null })
    var checkReviewuser = _.some(this.state.publishTemplate.endpoints, { review_user: null })

    if (!this.state.customEnableReview && !checkAudituser) {
      return true //this.setState({cc_level:2})
    }
    else if (!this.state.checkAudituser && (this.state.customEnableReview && !checkReviewuser)) {
      return true //this.setState({cc_level:2})
    }
    else {
      return false
    }
  }

  getDateFormateMethod = () => {
    var today = new Date()
    const dd = today.getDate().toString().length == 1 ? "0" + today.getDate().toString() : today.getDate().toString()
    const mm = String(today.getMonth() + 1).length == 1 ? "0" + String(today.getMonth() + 1) : today.getMonth() + 1
    const yyyy = today.getFullYear()
    const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
    this.setState({ fromate_date })
  }

  // onChangeStartDate(event) {
  //   this.state.publishTemplate.start_date = event.target.value
  //   this.state.publishTemplate.end_date = null
  //   this.setState({ ptl_startdate: event.target.value, })
  //   this.updatePublishTemplateData()
  // }

  onChangeStartDate(event) {
    // console.log('evnt', event, this.state.ptl_startdate > this.state.ptl_enddate )
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.start_date = event.target.value === '' ? null : event.target.value
    publishTemplate.end_date = event.target.value > publishTemplate.end_date ? null : publishTemplate.end_date
    // this.state.publishTemplate.end_date = null
    //this.state.publishTemplate.cc_level = 3
    if (this.state.ptl_startdate > this.state.ptl_enddate) {
      publishTemplate.cc_level = 2
    } else {
      publishTemplate.cc_level = event.target.value === '' ? 2 : 3

    }
    this.setState({ ptl_startdate: event.target.value, cc_level: publishTemplate.cc_level, publishTemplate },()=>{
    this.updatePublishTemplateData()
  })
  }
   
  onChangeEndDate(event) {
    // console.log('event.target.value', event.target.value)
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.end_date = event.target.value === '' ? null : event.target.value
    publishTemplate.cc_level = event.target.value === '' ? 2 : 3
    this.setState({ ptl_enddate: event.target.value, cc_level: publishTemplate.cc_level, publishTemplate },()=>{
    this.updatePublishTemplateData()
  })
  }
     





  // onChangeEndDate(event) {
  //   this.state.publishTemplate.end_date = event.target.value
  //   this.setState({ ptl_enddate: event.target.value, })
  //   this.updatePublishTemplateData()
  // }

  onChangeStartTime(e) {

    var getTimeFromEvent = e[0].toLocaleTimeString()
    var getHour = getTimeFromEvent.slice(0, 2)
    var getMinTime = String(parseInt(getHour + 1)) + "00"
    var publishTemplate ={...this.state.publishTemplate}

    publishTemplate.repeat_mode_config = {
      mode: publishTemplate.repeat_mode_config.mode,
      mode_id: publishTemplate.repeat_mode_config.mode_id,
      start_date: publishTemplate.repeat_mode_config.start_date,
      end_date: publishTemplate.repeat_mode_config.end_date,
      days: publishTemplate.repeat_mode_config.days,
      start_time: [getTimeFromEvent],
      end_time: publishTemplate.repeat_mode_config.end_time,
      start_on: publishTemplate.repeat_mode_config.start_on,
      ends_on: publishTemplate.repeat_mode_config.ends_on,
      mstartoption: publishTemplate.repeat_mode_config.mstartoption,
      mendoption: publishTemplate.repeat_mode_config.mendoption,

    }


    this.setState({ daily_starttime: e, getMinTime ,publishTemplate})
  }

  onChangeEndTime(e) {

    var getTimeFromEvent = e[0].toLocaleTimeString()
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.repeat_mode_config = {
      mode: publishTemplate.repeat_mode_config.mode,
      mode_id: publishTemplate.repeat_mode_config.mode_id,
      start_date: publishTemplate.repeat_mode_config.start_date,
      end_date: publishTemplate.repeat_mode_config.end_date,
      days: publishTemplate.repeat_mode_config.days,
      start_time: publishTemplate.repeat_mode_config.start_time,
      end_time: [getTimeFromEvent],
      start_on: publishTemplate.repeat_mode_config.start_on,
      ends_on: publishTemplate.repeat_mode_config.ends_on,
      mstartoption: publishTemplate.repeat_mode_config.mstartoption,
      mendoption: publishTemplate.repeat_mode_config.mendoption,

    }
    this.setState({ daily_endtime: e,publishTemplate })
  }

  monthStartOn(event) {
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.repeat_mode_config = {
      mode: publishTemplate.repeat_mode_config.mode,
      mode_id: publishTemplate.repeat_mode_config.mode_id,
      start_date: publishTemplate.repeat_mode_config.start_date,
      end_date: publishTemplate.repeat_mode_config.end_date,
      days: publishTemplate.repeat_mode_config.days,
      start_time: publishTemplate.repeat_mode_config.start_time,
      end_time: publishTemplate.repeat_mode_config.end_time,
      start_on: event.target.value,
      ends_on: publishTemplate.repeat_mode_config.ends_on,
      mstartoption: publishTemplate.repeat_mode_config.mstartoption,
      mendoption: publishTemplate.repeat_mode_config.mendoption,
    }

    this.setState({
      dsf: event.target.value,
      dayEndNumber: this.generateNumber(parseInt(event.target.value) + 1),
      publishTemplate: publishTemplate
    },()=>{
    this.updatePublishTemplateData()
  })
  }

  monthEndsOn(event) {
    var publishTemplate ={...this.state.publishTemplate}

    publishTemplate.repeat_mode_config = {
      mode: publishTemplate.repeat_mode_config.mode,
      mode_id: publishTemplate.repeat_mode_config.mode_id,
      start_date: publishTemplate.repeat_mode_config.start_date,
      end_date: publishTemplate.repeat_mode_config.end_date,
      days: publishTemplate.repeat_mode_config.days,
      start_time: publishTemplate.repeat_mode_config.start_time,
      end_time: publishTemplate.repeat_mode_config.end_time,
      start_on: publishTemplate.repeat_mode_config.start_on,
      ends_on: event.target.value,
      mstartoption: publishTemplate.repeat_mode_config.mstartoption,
      mendoption: publishTemplate.repeat_mode_config.mendoption,
    }
    this.setState({
      eoem: event.target.value,
      publishTemplate: publishTemplate
    },()=>{
    this.updatePublishTemplateData()
    })
  }

  validateEndpoints=()=>{

    _.each(this.state.publishTemplate.endpoints, item => {
      if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
        item["rowValid"] = false
      }
      else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
        item["rowValid"] = false
      }
      else {
        item["rowValid"] = true
      }
    })
    console.log(this.state.publishTemplate)


  }




  updateCCLevel() {
    if (this.state.publishTemplate.endpoints.length > 0) {
          this.validateEndpoints()
    }
    var updateCCState = Validation.updatecclevel(this.state.methodSelected, this.state.publishTemplate)
    console.log(updateCCState,'ccstate')
    //.log(updateCCState,'updateCCState')
    this.setState({component:"",open: false, publishTemplate: updateCCState, cc_level: updateCCState.cc_level,dataloaded:true })
  }




  async updatePublishTemplateData() {
    this.updateCCLevel()
    console.log(this.state.publishTemplate,'publish')
    try {
      urlSocket.post("webphlbconf/updatepublishtemplate", {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id
        },
        hInfo: {
          h_id: this.state.hirerachyData._id,
          publishtemplateInfo: this.state.publishTemplate
        }
      })
        .then(response => {
          console.log(response,'response')
          //.log(response,'response')
          // response.data.data.endpoints



          if (response.data.response_code == 500) {
            this.setState({
              cc_level: response.data.data.cc_level,
              endpoints: response.data.data.endpoints,
              publishTemplate:response.data.data,
              dataloaded : false
            },()=>{
              this.setState({
                dataloaded : true
              })
            })
          }
        })
    } catch (error) {
    }
  }

  // selectRepeatMode(event) {
  //   var repeatMode = this.state.repeatModeData[parseInt(event.target.value)]
  //   console.log(event.target.value, repeatMode)
  //   // var key_name = event.target.value == "1" ? "daily" : "2" ? "weekly" : "3" ? "monthly" : "4" ? "quaterly" : "5" ? "half_yearly" : "6" ? "yearly" : "repeat_mode_config"
  //   this.state.publishTemplate.repeat_mode = repeatMode.mode
  //   this.state.publishTemplate.cc_level = 3
  //   this.state.publishTemplate.repeat_mode_config = {
  //     mode: repeatMode.mode,
  //     mode_id: repeatMode.mode_id,
  //     // start_date: this.state.publishTemplate.repeat_mode_config.start_date,
  //      start_date: null,
  //      end_date: null,
  //     // end_date: this.state.publishTemplate.repeat_mode_config.end_date,
  //     days: this.state.publishTemplate.repeat_mode_config.days,
  //     start_time: this.state.publishTemplate.repeat_mode_config.start_time,
  //     end_time: this.state.publishTemplate.repeat_mode_config.end_time,
  //     start_on: this.state.publishTemplate.repeat_mode_config.start_on,
  //     ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
  //     mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
  //     mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

  //   }

  //   this.setState({
  //     cc_level: 3,
  //     repeat_mode: repeatMode.mode,
  //     weekDays: this.state.publishTemplate.repeat_mode_config.days,
  //     rm_config: repeatMode,
  //     daily_starttime: this.state.publishTemplate.repeat_mode_config.start_time,
  //     daily_endtime: this.state.publishTemplate.repeat_mode_config.end_time,
  //     dsf: this.state.publishTemplate.repeat_mode_config.start_on,
  //     eoem: this.state.publishTemplate.repeat_mode_config.ends_on,
  //     selectedRepeatMode: event.target.value,
  //     selectedRadioOption: "",
  //     occurence: ''
  //   })
  //   console.log(this.state.publishTemplate, 'this.state.publishTemplate')
  //   this.updatePublishTemplateData()
  // }


  selectRepeatMode(event) {
    var repeatMode = this.state.repeatModeData[parseInt(event.target.value)]
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.repeat_mode = repeatMode.mode
    publishTemplate.cc_level = 3
    console.log(repeatMode.mode,'repeatMode.mode',repeatMode.mode_id)
    publishTemplate.repeat_mode_config = {
      mode: repeatMode.mode,
      mode_id: repeatMode.mode_id,
      // start_date: publishTemplate.repeat_mode_config.start_date,
      // end_date: publishTemplate.repeat_mode_config.end_date,
      days: publishTemplate.repeat_mode_config.days,
      start_time: publishTemplate.repeat_mode_config.start_time,
      end_time: publishTemplate.repeat_mode_config.end_time,
      start_on: publishTemplate.repeat_mode_config.start_on,
      ends_on: publishTemplate.repeat_mode_config.ends_on,
      mstartoption: publishTemplate.repeat_mode_config.mstartoption,
      mendoption: publishTemplate.repeat_mode_config.mendoption,
      start_date: null,
      end_date: null,
      calendar_type : null,
      // selected_quater_months :[],
      // selected_quater : null

    }

    this.setState({
      cc_level: 3,
      repeat_mode: repeatMode.mode,
      weekDays: publishTemplate.repeat_mode_config.days,
      rm_config: repeatMode,
      daily_starttime: publishTemplate.repeat_mode_config.start_time,
      daily_endtime: publishTemplate.repeat_mode_config.end_time,
      dsf: publishTemplate.repeat_mode_config.start_on,
      eoem: publishTemplate.repeat_mode_config.ends_on,
      selectedRepeatMode: event.target.value,
      selectedRadioOption: "",
      occurence: '',
      publishTemplate,
      // custom_modal : repeatMode.mode_id === "1" ? true : false

    },()=>{
    console.log(this.state.publishTemplate,'this.state.publishTemplate')
    this.updatePublishTemplateData()
  })
  }

  navigateTo = (data) => {
    sessionStorage.removeItem("EditPublishedData");
    sessionStorage.removeItem("nagiationMode");
    sessionStorage.setItem("EditPublishedData", JSON.stringify(data));
    sessionStorage.setItem("nagiationMode", "from_config");
    this.props.history.push("/edtpblhtempt");
  }

  handleWeekDays(e) {
    var getIndex = _.findIndex(this.state.weekDays, { day: e.target.id })
    console.log(getIndex,'getIndex')
    var weekDays =[...this.state.weekDays]
    var publishTemplate ={...this.state.publishTemplate}
    weekDays[getIndex]["checked"] = e.target.checked
    publishTemplate.repeat_mode_config = {
      mode: publishTemplate.repeat_mode_config.mode,
      mode_id: publishTemplate.repeat_mode_config.mode_id,
      start_date: publishTemplate.repeat_mode_config.start_date,
      end_date: publishTemplate.repeat_mode_config.end_date,
      days: weekDays,
      start_time: publishTemplate.repeat_mode_config.start_time,
      end_time: publishTemplate.repeat_mode_config.end_time,
      start_on: publishTemplate.repeat_mode_config.start_on,
      ends_on: publishTemplate.repeat_mode_config.ends_on,
      mstartoption: publishTemplate.repeat_mode_config.mstartoption,
      mendoption: publishTemplate.repeat_mode_config.mendoption,

    }

    if (publishTemplate.repeat_mode === "Weekly" &&
      _.some(publishTemplate.repeat_mode_config.days, { checked: true })) {
      publishTemplate["cc_level"] = 3
    }
    else {
      publishTemplate["cc_level"] = 2
    }

    this.setState({ weekDays, publishTemplate, cc_level:publishTemplate.cc_level })
  }

  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return "st";
    }
    if (j == 2 && k != 12) {
      return "nd";
    }
    if (j == 3 && k != 13) {
      return "rd";
    }
    return "th";
  }

  handleMStartGroupChange(event) {

    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.repeat_mode_config = {
      mode: publishTemplate.repeat_mode_config.mode,
      mode_id: publishTemplate.repeat_mode_config.mode_id,
      start_date: publishTemplate.repeat_mode_config.start_date,
      end_date: publishTemplate.repeat_mode_config.end_date,
      days: publishTemplate.repeat_mode_config.days,
      start_time: publishTemplate.repeat_mode_config.start_time,
      end_time: publishTemplate.repeat_mode_config.end_time,
      start_on: event.target.name === "month_start" ? null : publishTemplate.repeat_mode_config.start_on,
      ends_on: publishTemplate.repeat_mode_config.ends_on,
      mstartoption: event.target.name,
      mendoption: publishTemplate.repeat_mode_config.mendoption,
    }

    this.setState({
      mstartoption: event.target.name,
      publishTemplate
    },()=>{
    this.updatePublishTemplateData()
  })
  }

  handleMEndGroupChange(event) {
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.repeat_mode_config = {
      mode: publishTemplate.repeat_mode_config.mode,
      mode_id: publishTemplate.repeat_mode_config.mode_id,
      start_date: publishTemplate.repeat_mode_config.start_date,
      end_date: publishTemplate.repeat_mode_config.end_date,
      days: publishTemplate.repeat_mode_config.days,
      start_time: at_mode_config.start_time,
      end_time: publishTemplate.repeat_mode_config.end_time,
      start_on: publishTemplate.repeat_mode_config.start_on,
      ends_on: event.target.name === "month_end" ? null : publishTemplate.repeat_mode_config.ends_on,
      mstartoption: publishTemplate.repeat_mode_config.mstartoption,
      mendoption: event.target.name,
    }
    this.setState({
      mendoption: event.target.name,
      publishTemplate
    },()=>{
    this.updatePublishTemplateData()
  })
  }

  deleteEndpoint = () => {
    try {
      urlSocket.post("webphlbconf/deleteendpoints", {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id
        },
        hInfo: {
          h_id: this.state.hirerachyData._id,
          publishtemplateInfo: this.state.publishTemplate
        },
        endpointInfo: this.state.deleteItem
      })
        .then(response => {
          if (response.data.response_code === 500) {

            var publishTemplate = response.data.data


            this.setState({
              publishTemplate: publishTemplate,
              endpoints: response.data.data.endpoints,
              changeMethodConfirmation: false,
            }, function () {
              this.updateCCLevel()
            })
          }
        })
    } catch (error) {
    }
  }

  treeDataToFlat = async () => {
    var getNodeKey = ({ treeIndex }) => treeIndex
    var flatData = getFlatDataFromTree(
      {
        treeData: this.state.publishTemplate.template_structure,
        getNodeKey,
        ignoreCollapsed: false,
      })

    var explicitData = _.filter(flatData, item => {
      return item
    })

    return explicitData
  }

  checkPublishDate = () => {

    var currentDate = moment(new Date()).format("YYYY-MM-DD")
    var pbd_startDate = moment(new Date(this.state.publishTemplate.start_date)).format("YYYY-MM-DD")
    var pbd_endDate = moment(new Date(this.state.publishTemplate.end_date)).format("YYYY-MM-DD")

    if (moment(pbd_startDate).isBefore(currentDate)) {
      this.setState({ ederror: false, sderror: true })
    }

    if (moment(pbd_endDate).isBefore(currentDate)) {
      this.setState({ sderror: false, ederror: true })
    }

    if (moment(pbd_startDate).isBefore(currentDate) == false && moment(pbd_endDate).isBefore(currentDate) == false) {
      this.setState({ sderror: false, ederror: false })
      return true
    }
    else {
      return false
    }
  }

  calculateMaxDate() {
    const { ptl_startdate } = this.state;

    if (ptl_startdate === '' || ptl_startdate === undefined) {
      return null; // Return null if start date is not set
    }

    const startDate = new Date(ptl_startdate);
    startDate.setFullYear(startDate.getFullYear() + this.state.client_info.rept_mode_periodicity);

    return startDate.toISOString().slice(0, 10); // Format the date as "YYYY-MM-DD"
  }




  publishTmpltAsAudit = async () => {

    var checkpoints = await this.treeDataToFlat()
    var checkDates = this.checkPublishDate()
    var publishTemplate ={...this.state.publishTemplate}
    //("checkDates", checkDates)
    //(this.state.publishTemplate,'this.state.publishTemplate')
    if (checkDates) {
      if(this.state.methodSelected == "2"){
        var filter_ignored_location = _.filter(publishTemplate.endpoints, endpoint => !endpoint.disabled);
        publishTemplate["endpoints"] = filter_ignored_location
        //.log(filter_ignored_location,'filter_ignored_location',this.state.publishTemplate)
      }

      console.log(this.state.publishTemplate,'this.state.publishTemplate')
      try {
        urlSocket.post("webphlbprcs/publishtmpltasaudit", {
          userInfo: {
            encrypted_db_url: this.state.db_info.encrypted_db_url,
            created_by: this.state.userInfo._id,
            company_id: this.state.userInfo.company_id
          },
          hInfo: {
            publishtemplateInfo: publishTemplate,
          },
        })
          .then(response => {
            //("response", response)
            if (response.data.response_code === 500) {
              this.setState({
                tmpltPublishProcess: true,
                alertMessage: "Audit publishing started successfully click",
                processStatus: "1",
                publishTemplate
              })
            }
            else {
              this.setState({
                tmpltPublishProcess: true,
                alertMessage: "Audit not publish. Please check the data",
                processStatus: "0",
                publishTemplate
              })
            }
          })
      } catch (error) {
      }
    }
    else {
    }

  }

  redirectTo(processStatus) {

    this.setState({ tmpltPublishProcess: false })

    if (processStatus == "1") {
      sessionStorage.removeItem("EditPublishedData");
      sessionStorage.removeItem("nagiationMode");
      this.props.history.push("/mngpblhtempt");
    }
  }


  deleteAllRequest() {

    if (this.state.publishTemplate.endpoints.length === 0) {
      this.setState({ dataAlert: true, alertMessage: "No Locations to delete" })
    }
    else {
      this.setState({ triggerFrom: "deleteall", changeMethodConfirmation: true })
    }


  }


  select_country = (e) => {
    this.setState({ countrycode: e.target.value ,country_code_err : false })
  }


  addPermissions(e, item) {

    var getIndex = _.findIndex(this.state.permissionsadded, { id: item.id })

    if (getIndex === -1) {
      this.state.permissionsadded.push(item)
      this.setState({
        permissionsadded: this.state.permissionsadded
      })
    }
    else {
      this.state.permissionsadded.splice(getIndex, 1)
      this.setState({
        permissionsadded: this.state.permissionsadded
      })
    }


  }



  validate_email = (e, mode) => {
    var admn_info = {}
    if (mode == 1) {
      admn_info["email_id"] = e.target.value
    }
    if (mode === 2) {
      admn_info["phone_number"] = e.target.value
    }
    if (mode === 3) {
      admn_info["usercode"] = e.target.value
    }
    admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
    admn_info["db_name"] = this.state.db_info.db_name
    admn_info["user_id"] = this.state.userInfo._id

    if (this.state.usercode !== undefined || admn_info["email_id"] !== undefined || admn_info["phone_number"] !== undefined) {
      try {
        urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
          //.log(data,'data')
          if (mode == 1) {
            if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
              this.setState({ invalid_user: true })
            }
            else {
              this.setState({ invalid_user: false })
            }
          }
          if (mode == 2) {

            if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
              this.setState({ invalid_phone_num: true })
            }
            else {
              this.setState({ invalid_phone_num: false })
            }
          }
          if (mode === 3) {
            if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
              this.setState({ invalid_usercode: true })
            }
            else {
              this.setState({ invalid_usercode: false })
            }


          }
        })

      } catch (error) {

      }
    }

  }


  emai_addrs = (e) => {
    var email = e.target.value
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      this.setState({ given_email: email, errorMessage: false })
    }
    else {
      this.setState({ errorMessage: true, given_email: email })
    }

  }

  userCode = (e) => {
    this.setState({ usercode: e.target.value })
  }


  loadEOPTS = () => {
    this.setState({ tableDataloaded: false })
    try {
      urlSocket.post("cog/get-user-info",
        {
          db_info: this.state.db_info,
          user_id: this.state.userInfo._id
        }
      )
        .then((response) => {
          this.setState({
            userdata: response.data.user_list,
            auditUserlevelSelected: false
          }, function () {
            this.loadUserLabels()
            setTimeout(() => {
              this.setState({ auditUserlevelSelected: true })

            }, 50);
          })

        })
    } catch (error) {

    }
  }

  locationUserCrud=async (event,values)=>{
    console.log(values,this.state.sessionUserInfo,this.state.client_info)
    //.log(values,'values')
    values["selected_category"]=this.state.selected_category
    var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
    values["password"] = password
    values["confirmpassword"] = password
    values["userPoolId"] = this.state.client_info.userPoolId
    values["clientId"] = this.state.client_info.clientId
    values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
    values["db_name"] = this.state.db_info.db_name
    values["facilities"] = this.state.client_info.facilities
    values["company_id"] = this.state.client_info._id
    values["created_by"] = this.state.sessionUserInfo._id
    values["short_name"] = this.state.client_info.short_name
    values["user_id"] = this.state.sessionUserInfo._id
    if (this.state.countrycode === undefined) {
      this.setState({ country_code_err: true })
    }
      else {
        values["countrycode"] = this.state.countrycode
        this.setState({ country_code_err: false })
  
      }
    if (this.state.invalid_user === false && this.state.errorMessage == false && this.state.country_code_err === false) {
           this.setState({ loading: true })
          var hstructure =  JSON.parse(sessionStorage.getItem("hstructure"));
           //.log(this.state.location_info,this.state.tree_flat,hstructure,'location_id',this.state.selected_category)
          
      try {
        urlSocket.post('cog/cruduser', values).then(async (res) => {
          //.log(res, 'response')
          if (res.data.response_code === 500) {
            this.setState({ loading: false })
            var location_info ={...this.state.location_info}

            this.state.selected_category.map((ele, idx) => {
              if (this.state.add_reviwer == false) {
                var userInfo = {
                  cat_type: ele.cat_type,
                  designation: null,
                  hirerachy_name: ele.hirerachy_name,
                  name: values.firstname,
                  title: location_info.hlevel_name,
                  type: 2,
                  user_id: res.data.admn_user[0]._id,
                  _id: res.data.admn_user[0]._id

                }
              }
              if(this.state.add_reviwer == true){
                var userInfo = {
                  cat_type: ele.cat_type,
                  designation: null,
                  hirerachy_name: ele.hirerachy_name,
                  name: values.firstname,
                  title: location_info.hlevel_name,
                  type: 2,
                  review_user :true,
                  user_id: res.data.admn_user[0]._id,
                  _id: res.data.admn_user[0]._id

                }
              }
              location_info.user_path.push(userInfo)
              if (location_info["unique_users"] == undefined) {
                location_info["unique_users"] = []
                location_info["hirerachy_name"] = []
                location_info["unique_users"].push({ ...userInfo })
                location_info["unique_users"][0]["cat_type"] = [location_info["unique_users"][0]["cat_type"]]
                location_info["unique_users"][0]["hirerachy_name"] = [location_info["unique_users"][0]["hirerachy_name"]]
              }
              else {
                var find_exist_user = _.find(location_info.unique_users, { _id: userInfo._id })
                if (find_exist_user !== undefined) {
                  find_exist_user["cat_type"].push(ele.cat_type)
                  find_exist_user["hirerachy_name"].push(ele.hirerachy_name)
                }
                else {
                  location_info["unique_users"].push({ ...userInfo })
                  var find_exist_user_unique = _.findIndex(location_info.unique_users, { _id: userInfo._id })
                  location_info["unique_users"][find_exist_user_unique]["cat_type"] = [location_info["unique_users"][find_exist_user_unique]["cat_type"]]
                  location_info["unique_users"][find_exist_user_unique]["hirerachy_name"] = [location_info["unique_users"][find_exist_user_unique]["hirerachy_name"]]

                }
              }
            })
            this.setState({
              location_info 
            },async()=>{
            var update_flatData = await this.updateInFlatCln(this.state.location_info,hstructure)
          })
          }

        })
      } catch (error) {

      }
    }
  }


  updateInFlatCln = (flatData, hstructure) => {
    //.log(flatData)
    console.log(flatData, hstructure)
    try {
      urlSocket.post('cog/update-flat-cln', {
        flatData: flatData,
        encrypted_db_url: this.state.db_info.encrypted_db_url,
      }).then((response) => {
        //.log(response, 'response')
        console.log(response,'response')
        if (response.data.response_code === 500) {
          //.log(flatData,'flatData',hstructure)
          var path_data = hstructure.find((fcty) => fcty.title === flatData.hlevel_name && fcty.subtitle === flatData.hlevel)
          console.log(path_data, 'path_data', hstructure)
          //.log(path_data,'path_data')
          if (path_data !== undefined) {
            path_data["unique_users"] = flatData.unique_users
            path_data["user_path"] = flatData.user_path
            console.log(path_data, 'path_data',flatData,this.state.publishTemplate)
            if (flatData.adt_users.length == 0) {
              var user_info = {
                user_id: path_data["user_path"][0].user_id,
                _id: path_data["user_path"][0].user_id,
                name: path_data["user_path"][0].name,
                designation: path_data["user_path"][0].designation,
                cat_type: path_data["user_path"][0].cat_type,
                audit_type: this.state.add_reviwer ? "2" : "1",
                review_user: this.state.add_reviwer ? true : false
              }
              if (path_data.user_path.length > 0 && path_data.user_path.length < 2){
                // flatData["rowValid"] = true
                flatData["rowValid"] = true
              flatData.adt_users.push(user_info)
              // if(this.state.publishTemplate.endpoints.length ===0){
              //   this.state.publishTemplate.endpoints.push(flatData)
              // }
            }
            }
            // this.updateCCLevel()
            this.setState({
              publishTemplate: this.state.publishTemplate
            },()=>{this.updatePublishTemplateData();this.FlatDataToTreeData(hstructure)})
            
            // flatData.adt_
            
          }

        }
      })

    } catch (error) {

    }


  }



  FlatDataToTreeData = async (FlatData) => {
    //.log(FlatData);
    try {
      const treeData = await this.getTreeFromFlatDataAsync(FlatData);
      //.log(treeData,'treeData')
      this.setState({
        dup_treeData: treeData,
        // dataloaded: true,
      },()=>this.saveHstructure(treeData,FlatData));
    } catch (error) {
      //.error("Error:", error);
    }
  }

  saveHstructure=async (treeData,hstructure)=>{
    var explicitData = await this.HtreeDataToFlat(treeData)
    //.log(explicitData,'explicitData')
    try {
      urlSocket.post("webhstre/savehstructure", {
        info: {
          _id: this.state.publishTemplate.hlevel_id,
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.state.publishTemplate.company_id,
          flatData: _.map(explicitData, "node"),
          published:true
          // lastGeneratedId: this.state.totalHLength
        }
      })
        .then((response) => {
          //.log(response,'response')
          if(response.data.response_code == 500){
            this.setState({ loading: false , modal : false  , dataloaded : false},()=>{this.setState({dataloaded : true})})
            sessionStorage.setItem("hstructure", JSON.stringify(hstructure));
            this.getAllUsers()
          }
          //("response.data", response)
          // if (response.data.response_code == 500) {
          //   this.setState({ saving: false, successmsgshow: true, alertMessage: response.data.message },()=>{this.getHData("stop")})
          //   setTimeout(() => { this.setState({ successmsgshow: false, }) }, 2500)

          // }
        })
    } catch (error) {

    }





  }


  HtreeDataToFlat = (treeData) => {
    // //("this.state.treeData", this.state.treeData)
    var getNodeKey = ({ treeIndex }) => treeIndex
    var flatData = getFlatDataFromTree(
      {
        treeData: treeData,
        getNodeKey,
        ignoreCollapsed: false,
      })
      //.log(flatData,'flatData')
    var explicitData = _.filter(flatData, item => {
      return item
    })

    return explicitData
  }





  getTreeFromFlatDataAsync = (FlatData) => {
    return new Promise((resolve, reject) => {
      try {
        const treeData = getTreeFromFlatData({
          flatData: FlatData,
          getKey: (node) => node.id,
          getParentKey: (node) => node.parent,
          rootKey: null,
          expanded: true
        });
  
        resolve(treeData);
      } catch (error) {
        reject(error);
      }
    });
  }




  handleValidSubmit = (event, values) => {
    var client_info ={...this.state.client_info}
    client_info.facilities.map((ele, idx) => {
      this.state.permissionsadded.map((ele1, idx1) => {
        if (ele1.id === ele.id) {
          client_info.facilities[idx].enabled = true
        }
      })
    })


    var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
    values["password"] = password
    values["confirmpassword"] = password
    values["userPoolId"] = client_info.userPoolId
    values["clientId"] = client_info.clientId
    values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
    values["db_name"] = this.state.db_info.db_name
    values["facilities"] = client_info.facilities
    values["company_id"] = this.state.sessionUserInfo.m_client_id
    values["created_by"] = this.state.sessionUserInfo._id
    values["short_name"] = client_info.short_name
    values["user_id"] = this.state.sessionUserInfo._id

    if (this.state.countrycode === undefined) {
      this.setState({ country_code_err: true })
    }
    else {
      values["countrycode"] = this.state.countrycode
      this.setState({ country_code_err: false })

    }
    if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false) {
      this.setState({ loading: true })

      try {
        urlSocket.post('cog/cruduser', values).then((res) => {
          if (res.data.response_code === 500) {
            var publishTemplate ={...this.state.publishTemplate}
            var created_user = res.data.admn_user[0]
            this.setState({ loading: false })
            var event = {}
            var target = {}
            target["value"] = created_user._id
            event["target"] = target
            this.state.userdata.push(created_user)
            if (this.state.user_type === 0) {
              if (publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0) {
                publishTemplate.endpoints[this.state.selected_index].adt_users.push({
                  user_id: created_user._id,
                  name: created_user.firstname,
                  designation: created_user.designation,
                  user_code: created_user.usercode,
                  audit_type: "1"
                })
              } else {
                publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_id'] = created_user._id
                publishTemplate.endpoints[this.state.selected_index].adt_users[0]['name'] = created_user.firstname
                publishTemplate.endpoints[this.state.selected_index].adt_users[0]['designation'] = created_user.designation
                publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_code'] = created_user.user_code
                publishTemplate.endpoints[this.state.selected_index].adt_users[0]['audit_type'] = '1'
              }
            }
            else {
              if (this.state.user_type === 1) {
                if (publishTemplate.endpoints[this.state.selected_index].adt_users.length === 1 || publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0) {
                  publishTemplate.endpoints[this.state.selected_index].adt_users.push({
                    user_id: created_user._id,
                    name: created_user.firstname,
                    designation: created_user.designation,
                    user_code: created_user.usercode,
                    audit_type: "2",
                  })
                }
                else {
                  publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_id'] = created_user._id
                  publishTemplate.endpoints[this.state.selected_index].adt_users[1]['name'] = created_user.firstname
                  publishTemplate.endpoints[this.state.selected_index].adt_users[1]['designation'] = created_user.designation
                  publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_code'] = created_user.user_code
                  publishTemplate.endpoints[this.state.selected_index].adt_users[1]['audit_type'] = '2'

                }
              }

            }
            this.setState({ userdata: this.state.userdata, publishTemplate }, () => {

              this.state.user_type === 0 ?
                this.changeAuditUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index, "0")
                : this.changeReviewUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index, "0")
              this.onDrawerClose()
            })
          }
          else {
            this.setState({ loading: false })

          }
        })

      } catch (error) {
      }

    }

  }

  handle_click = (e) => {
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate[e.target.name] = e.target.checked
    this.setState({ publishTemplate},()=>{this.updatePublishTemplateData()})
  }


  labelSelected(data) {

    if (data.target.value === "all") {
      this.loadEOPTS()
    }
    else {
      var mylabel = this.state.labelData[data.target.value]

      try {

        urlSocket.post("userSettings/load-group-users", {
          labelInfo: mylabel,
          userInfo: {
            encrypted_db_url: this.state.db_info.encrypted_db_url,
            _id: this.state.userInfo._id,
            company_id: this.state.userInfo.company_id
          }
        })
          .then(response => {
            this.setState({
              statusBasedFilteredData: response.data.data, userdata: response.data.data, auditUserlevelSelected: false
            }, () => {
              setTimeout(() => {
                this.setState({ auditUserlevelSelected: true });

              }, 50);

            })
          })
      } catch (error) {
      }

    }
    this.setState({ user_group_selected: data.target.value })

  }



  deleteAllEndpoints() {



    try {
      var loggedUserInfo = {
        company_id: this.state.userInfo.company_id,
        company_name: this.state.userInfo.company_name,
        created_by: this.state.userInfo._id,
        encrypted_db_url: this.state.db_info.encrypted_db_url
      }
      urlSocket.post("webphlbconf/deleteallendpoints", {
        userInfo: loggedUserInfo,
        publishtemplateInfo: this.state.publishTemplate
      })
        .then(response => {
          if (response.data.response_code === 500) {
            this.setState({
              changeMethodConfirmation: false,
            }, function () {
              this.onDrawerClose()
            })

          }
        })
    } catch (error) {
    }

  }

  // endAfterMethod = (event) => {
  //   console.log(event.target.name, event.target.value)
  //   this.state.publishTemplate['repeat_mode_config']["start_date"] = null
  //   this.state.publishTemplate['repeat_mode_config']["end_date"] = null
  //   this.state.publishTemplate['repeat_mode_config']["based_on"] = event.target.name
  //   this.state.publishTemplate['repeat_mode_config']["no_of_occurance"] = null
  //   this.state.publishTemplate['repeat_mode_config']["allowed_audit_days"] = []
  //   this.state.publishTemplate['repeat_mode_config']["week_start_day"] = null

  //   this.state.publishTemplate.repeat_mode_config.days.map((data, idx) => {
  //     data["checked"] = false
  //   })
  //   this.setState({
  //     selectedRadioOption: event.target.name,
  //     occurence: "",
  //     weekDays: this.state.publishTemplate.repeat_mode_config.days
  //   }, () => {
  //     this.updateCCLevel()
  //   })
  // }


  endAfterMethod=(event)=>{
    console.log(event.target.name,event.target.value)
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate['repeat_mode_config']["start_date"]=null
    publishTemplate['repeat_mode_config']["end_date"]=null
    publishTemplate['repeat_mode_config']["based_on"]=event.target.name
    publishTemplate['repeat_mode_config']["no_of_occurance"]=null
    publishTemplate['repeat_mode_config']["allowed_audit_days"]=[]
    publishTemplate['repeat_mode_config']["week_start_day"]=null
    publishTemplate['repeat_mode_config']["allowed_audit_monthy"]=null
    publishTemplate['repeat_mode_config']["show_time_frame"]=false



    publishTemplate.repeat_mode_config.days.map((data, idx) => {
      data["checked"] = false
    })

    this.setState({
      selectedRadioOption : event.target.name,
      occurence :"",
      publishTemplate,
      weekDays: publishTemplate.repeat_mode_config.days

    },()=>{
      this.updateCCLevel()

    })
  }


//   scheduleStartDate = async(event) => {
//     console.log(event.target.value, 'value', this.state.publishTemplate,this.state.selectedRadioOption)
//     var publishTemplate ={...this.state.publishTemplate}
//     this.setState({
//       publishTemplate
//     },async()=>{

//     if(this.state.selectedRadioOption === "occurence"){
//     publishTemplate["repeat_mode_config"]["start_date"] = event.target.value

//     if (this.state.occurence <= 6) {
//       const endDate = await this.calculateEndDate();
//       const daysOfWeek =await this.getDaysOfWeekBetweenDates(new Date(this.state.publishTemplate.repeat_mode_config.start_date), new Date(endDate));
//       console.log(endDate,'endDate', daysOfWeek, this.state.publishTemplate)
//       this.setState({
//         weekDays : daysOfWeek
//       })
//     }
//     else{
//       this.setState({
//         weekDays : this.state.publishTemplate.repeat_mode_config.days
//       })
//     }
//   }
//   else{
//     publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
//     if(this.state.publishTemplate.repeat_mode_config.end_date !== null){
//       var startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//       var endDate = new Date(this.state.publishTemplate.repeat_mode_config.end_date);
//       var timeDifference = endDate.getTime() - startDate.getTime();
//       var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
//       // console.log(daysDifference,'daysDifference')
//       this.setState({
//         no_of_occurance : daysDifference + 1
//       })
//       if(daysDifference <= 6){
//         const daysOfWeek =await this.getDaysOfWeekBetweenDates(new Date(this.state.publishTemplate.repeat_mode_config.start_date), new Date(this.state.publishTemplate.repeat_mode_config.end_date));
//         console.log(daysOfWeek,'daysOfWeek')
//         this.setState({
//           weekDays : daysOfWeek
//         })
//       }
//       else{
//         this.setState({
//           weekDays : this.state.publishTemplate.repeat_mode_config.days
//         })
//       }

//     }
//   }
// })
//     this.setState({
//       publishTemplate
//     })

//   }

  

  // calculateEndDate = () => {
  //   // console.log(this.state.occurence, 'this.state.occurence', this.state.publishTemplate.repeat_mode_config.start_date);
  //   var publishTemplate ={...this.state.publishTemplate}
  //   if (this.state.occurence !== "" && publishTemplate.repeat_mode_config.start_date !== null) {
  //     var startDate = new Date(publishTemplate.repeat_mode_config.start_date);
  //     var occurrence = parseInt(this.state.occurence, 10);

  //     if (!isNaN(occurrence)) {
  //       startDate.setDate(startDate.getDate() + occurrence);

  //       const endYear = startDate.getFullYear();
  //       const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
  //       const endDay = String(startDate.getDate()).padStart(2, '0');

  //       // console.log(`${endYear}-${endMonth}-${endDay}`, startDate);
  //       // this.s
  //       publishTemplate["repeat_mode_config"]["end_date"] = `${endYear}-${endMonth}-${endDay}`
  //       this.setState({
  //         publishTemplate
  //       },()=>{
  //         return `${endYear}-${endMonth}-${endDay}`;

  //       })
  //     }
  //   }
  //   return null
  // }


  calculateEndDate = () => {
    // if(mode === "1"){
      var publishTemplate={...this.state.publishTemplate}
    if (this.state.occurence !== "" && publishTemplate.repeat_mode_config.start_date !== null) {
      var startDate = new Date(publishTemplate.repeat_mode_config.start_date);
      var occurrence = parseInt(this.state.occurence, 10);

      if (!isNaN(occurrence)) {
        startDate.setDate(startDate.getDate() + occurrence);

        const endYear = startDate.getFullYear();
        const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
        const endDay = String(startDate.getDate()).padStart(2, '0');
        publishTemplate["repeat_mode_config"]["end_date"] = `${endYear}-${endMonth}-${endDay}`
        this.setState({
          publishTemplate
        })
        return `${endYear}-${endMonth}-${endDay}`;
      }
    }
    return null
  }
  getDaysOfWeekBetweenDates = (startDate, endDate) => {
    // console.log(startDate, endDate, 'startDate, endDate')
    if (startDate !== null && endDate !== null) {
      const daysOfWeek = this.state.daysOfWeek
      const days = [];
      let current = new Date(startDate);

      while (current <= endDate) {
        days.push(daysOfWeek[current.getDay()]);
        current.setDate(current.getDate() + 1);
      }
      console.log(days, 'days')
      return days;
    }
  }
  
 

  handleOccurance = async (event, mode) => {
    var publishTemplate = { ...this.state.publishTemplate }
    publishTemplate["repeat_mode_config"]["no_of_occurance"] = event.target.value
    if (mode === "1") {
      console.log(mode, 'mode', this.state.publishTemplate, event.target.value)

      if (event.target.value <= 6) {
        this.setState({
          occurence: event.target.value,
          show_occurence_valid: false,
        }, async () => {
          var endDate = await this.calculateEndDate()
          var weekDays = await this.getDaysOfWeekBetweenDates(new Date(publishTemplate.repeat_mode_config.start_date), new Date(endDate));
          console.log(weekDays, 'week', endDate)
          this.setState({
            weekDays: weekDays
          }, () => {
            this.updatePublishTemplateData()
          })
        })
      }
      else {
        this.setState({
          weekDays: publishTemplate.repeat_mode_config.days,
          occurence: event.target.value,
          show_occurence_valid: false,
        }, async () => {
          var endDate = await this.calculateEndDate()
        })
      }
    }
    if (mode === "2") {
      console.log(mode, 'mode', this.state.publishTemplate, event.target.value)
      this.setState({
        occurence: event.target.value,
        show_occurence_valid: false,
      }, async () => {
        if (publishTemplate.repeat_mode_config.week_start_day !== undefined && publishTemplate.repeat_mode_config.week_start_day !== null) {
          var calculateEndDate = await this.calculateEnddateInWeek(publishTemplate["repeat_mode_config"]["week_start_day"]["day_id"], this.state.selectedRadioOption)
          publishTemplate["repeat_mode_config"]["end_date"] = calculateEndDate
        }
        this.setState({
          publishTemplate
        }, () => {
          this.updatePublishTemplateData()
        })
      })
    }
    if (mode == "3") {
      console.log(mode, 'mode', this.state.publishTemplate, event.target.value)
      this.setState({
        occurence: event.target.value,
        show_occurence_valid: false,
      }, async () => {
        console.log(this.state.occurence)
        if(publishTemplate.repeat_mode_config.start_date !== null){
          var calculateEndDate = await this.calculateEndDateInMonth()
          console.log(calculateEndDate,'calculateEndDate')
          publishTemplate["repeat_mode_config"]["end_date"] =calculateEndDate
          
        }
        this.setState({
          publishTemplate
        },()=>{
          this.updatePublishTemplateData()

        })
      })  
    }
    if (mode == "6") {
      this.setState({
        occurence: event.target.value,
        show_occurence_valid: false,
      }, async () => {

        this.updatePublishTemplateData()
      })

    }

  }

  scheduleStartDate = async (event,mode) => {
    console.log(event.target.value, 'value',mode, this.state.publishTemplate, this.state.selectedRadioOption)
  var publishTemplate ={...this.state.publishTemplate}
    this.setState({
      publishTemplate
    })

    if (mode === "1") {
      if (this.state.selectedRadioOption === "occurence") {
        publishTemplate["repeat_mode_config"]["start_date"] = event.target.value

        if (this.state.occurence <= 6) {
          const endDate = await this.calculateEndDate();
          const daysOfWeek = await this.getDaysOfWeekBetweenDates(new Date(publishTemplate.repeat_mode_config.start_date), new Date(endDate));
          console.log(endDate, 'endDate', daysOfWeek, this.state.publishTemplate)
          this.setState({
            weekDays: daysOfWeek
          })
        }
        else {
          this.setState({
            weekDays: publishTemplate.repeat_mode_config.days
          })
        }

      }
      else {
        publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
        if (publishTemplate.repeat_mode_config.end_date !== null) {
          var startDate = new Date(publishTemplate.repeat_mode_config.start_date);
          var endDate = new Date(publishTemplate.repeat_mode_config.end_date);
          var timeDifference = endDate.getTime() - startDate.getTime();
          var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          this.setState({
            no_of_occurance: daysDifference + 1
          })
          if (daysDifference <= 6) {
            const daysOfWeek = await this.getDaysOfWeekBetweenDates(new Date(publishTemplate.repeat_mode_config.start_date), new Date(publishTemplate.repeat_mode_config.end_date));
            console.log(daysOfWeek, 'daysOfWeek')
            this.setState({
              weekDays: daysOfWeek
            })
          }
          else {
            this.setState({
              weekDays: publishTemplate.repeat_mode_config.days
            })
          }

        }
      }
    }
    if(mode ==="2" ){
      if (this.state.selectedRadioOption === "occurence") {
        publishTemplate["repeat_mode_config"]["start_date"] = event.target.value

        if(publishTemplate.repeat_mode_config.week_start_day !== undefined && publishTemplate.repeat_mode_config.week_start_day !==null){
        // this.state.publishTemplate["repeat_mode_config"]["start_date"] = event.target.value
        var calculateEndDate = await this.calculateEnddateInWeek(publishTemplate["repeat_mode_config"]["week_start_day"]["day_id"],this.state.selectedRadioOption)
        publishTemplate["repeat_mode_config"]["end_date"] = calculateEndDate
        }
      }
      else {
        publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
        // this.state.publishTemplate["repeat_mode_config"]["start_date"] = event.target.value
        if (this.state.publishTemplate.repeat_mode_config.end_date !== null) {
          console.log(event.target.value, 'value',mode, this.state.publishTemplate, this.state.selectedRadioOption)

          var calculateOccurence = await this.calculateEnddateInWeek("",this.state.selectedRadioOption)
          console.log(calculateOccurence,'calculateOccurence')
        publishTemplate["repeat_mode_config"]["no_of_occurance"] = calculateOccurence

          this.setState({
            no_of_occurance : calculateOccurence
          })
        }
      }
    }
    if(mode === "3"){
      if (this.state.selectedRadioOption === "occurence") {
        console.log(this.state.publishTemplate)
        publishTemplate["repeat_mode_config"]["start_date"] = event.target.value
        var calculateEndDate = await this.calculateEndDateInMonth()
        console.log(calculateEndDate,'calculateEndDate')
        publishTemplate["repeat_mode_config"]["end_date"] = calculateEndDate

      }
      else {
        publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
        if(publishTemplate.repeat_mode_config.end_date !== null){
        var calculateMonthDifference = await this.calculateEndDateInMonth("option2")
        console.log(calculateMonthDifference,'calculateMonthDifference')
        publishTemplate["repeat_mode_config"]["no_of_occurance"] = calculateMonthDifference

        this.setState({
          no_of_occurance : calculateMonthDifference,
          publishTemplate 
        })
      }
      }
    }
    if(mode === "6"){
      publishTemplate["repeat_mode_config"]["start_date"] = event.target.value
      // var calculateEndDate = await this.calculateEndDateInMonth()
      const startDateStr = event.target.value
      const startDate = new Date(startDateStr); 

      // Add one year to the start date
      startDate.setFullYear(startDate.getFullYear() + Number(this.state.occurence));
      const endDateStr = startDate.toISOString().substr(0, 10);

      console.log(endDateStr);
      publishTemplate["repeat_mode_config"]["end_date"] = endDateStr
      this.setState({
        // no_of_occurance : calculateMonthDifference,
        publishTemplate 
      })


    }
    this.updatePublishTemplateData()
  }










  // handleOccurance =async (event) => {
  //   // console.log(event.target.value)
  
  //   // console.log(this.state.publishTemplate)
  //   if (event.target.value <= 6) {
  //     this.setState({
  //       occurence: event.target.value,
  //       show_occurence_valid: false,
  //     },async()=>{
  //     var endDate = await this.calculateEndDate()
  //     var weekDays =await this.getDaysOfWeekBetweenDates(new Date(this.state.publishTemplate.repeat_mode_config.start_date), new Date(endDate));
  //     console.log(weekDays, 'week',endDate)
  //     this.setState({
  //       weekDays: weekDays
  //     })
  //   })
  //   }
  //   else {
  //     this.setState({
  //       weekDays: this.state.publishTemplate.repeat_mode_config.days,
  //       occurence: event.target.value,
  //       show_occurence_valid: false,
  //     },async()=>{
  //       var endDate = await this.calculateEndDate()
  //     })
  //   }
  // }


  excludeWeeks = async (event, data, index,mode) => {
    console.log(mode,'mode')
    var publishTemplate = {...this.state.publishTemplate}
    if(mode === "1"){
    if (this.state.selectedRadioOption === "occurence") {

      var weekDays = [...this.state.weekDays]
      console.log(event.target.checked, 'event', data, index, weekDays)
      if (event.target.checked === true) {
        weekDays[index]["checked"] = true
      }
      else {
        weekDays[index]["checked"] = false
      }
      var excludeId = []
      weekDays.map((ele, idx) => {
        if (ele.checked == true) {
          excludeId.push(Number(ele.day_id))
        }
      })
      var excludedEndDate = await this.excludeExtendEnddate(excludeId)

      console.log(excludedEndDate, 'excludedEndDate', new Date(excludedEndDate))
      publishTemplate.repeat_mode_config.end_date = excludedEndDate
      publishTemplate.repeat_mode_config["excluded_days"] = excludeId

      this.setState({
        publishTemplate,
        weekDays
      })
    }

    else {

      var weekDays = [...this.state.weekDays]
      console.log(event.target.checked, 'event', data, index, weekDays)
      if (event.target.checked === true) {
        weekDays[index]["checked"] = true
      }
      else {
        weekDays[index]["checked"] = false
      }
      var excludeId = []
      weekDays.map((ele, idx) => {
        if (ele.checked == true) {
          excludeId.push(Number(ele.day_id))
        }
      })
      
      var excludedWeekOccurence = await this.excludeExtendOccurence(excludeId)
      publishTemplate.repeat_mode_config["excluded_days"] = excludeId

    }

  }
  if(mode === "2"){
    // var daysOfWeek =[...this.state.daysOfWeek]
    console.log(event.target.checked, 'event', data, index)

    if (event.target.checked === true) {
      publishTemplate.repeat_mode_config["days"][index]["checked"] = true
    }
    else {
      publishTemplate.repeat_mode_config["days"][index]["checked"] = false
    }
    var allowdId = []
    publishTemplate.repeat_mode_config["days"].map((ele, idx) => {
      if (ele.checked == true) {
        allowdId.push(Number(ele.day_id))
      }
    })
    this.setState({
      publishTemplate
    })
    
    publishTemplate.repeat_mode_config["allowed_audit_days"] = allowdId
    console.log(this.state.publishTemplate,'this.state.publishTemplate')

  }

    this.updatePublishTemplateData()
  }





  // excludeWeeks= async(event,data,index)=>{
  //   if(this.state.selectedRadioOption === "occurence"){
  //   var weekDays = [...this.state.weekDays]
  //   var publishTemplate ={...this.state.publishTemplate}
  //   console.log(event.target.checked, 'event', data, index, weekDays)
  //   if(event.target.checked === true){
  //   weekDays[index]["checked"] = true
  //   }
  //   else{
  //   weekDays[index]["checked"] = false
  //   }

  //   // this.setState({
  //   //   weekDays
  //   // },async()=>{
  //      var excludeId =[]
  //     weekDays.map((ele,idx)=>{
  //       if(ele.checked == true){
  //         excludeId.push(Number(ele.day_id))
  //       }
  //     })
  //     var excludedEndDate = await this.excludeExtendEnddate(excludeId)

  //     console.log(excludedEndDate, 'excludedEndDate',new Date(excludedEndDate))
  //     publishTemplate.repeat_mode_config.end_date =excludedEndDate
  //     publishTemplate.repeat_mode_config["excluded_days"] =excludeId

  //     this.setState({
  //       publishTemplate,
  //       weekDays
  //     })
  //   }
  //   else{
  //     var weekDays = [...this.state.weekDays]
  //     console.log(event.target.checked, 'event', data, index, weekDays)
  //     if(event.target.checked === true){
  //     weekDays[index]["checked"] = true
  //     }
  //     else{
  //     weekDays[index]["checked"] = false
  //     }
  //     var excludeId =[]
  //     weekDays.map((ele,idx)=>{
  //       if(ele.checked == true){
  //         excludeId.push(Number(ele.day_id))
  //       }
  //     })
  //     var excludedWeekOccurence = await this.excludeExtendOccurence(excludeId)
  //     publishTemplate.repeat_mode_config["excluded_days"] =excludeId
  //     this.setState({
  //       publishTemplate,
  //       weekDays
  //     })
  //     // console.log(excludedWeekOccurence,'excludedWeekOccurence')

  //   }
  // }


  excludeExtendOccurence = (excludeDay) => {
    const startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
    const endDate = new Date(this.state.publishTemplate.repeat_mode_config.end_date);

    let daysDifference = 0;

    while (startDate <= endDate) {
      // Check if the current date's day of the week is not in the excludeDay array
      if (!excludeDay.includes(startDate.getDay())) {
        daysDifference++;
      }

      // Move to the next day
      startDate.setDate(startDate.getDate() + 1);
    }
    console.log(daysDifference, 'daysDifference')

    this.setState({ no_of_occurance: daysDifference });
  };




  // excludeExtendOccurence = (excludeDay) => {
  //   const startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
  //   const endDate = new Date(this.state.publishTemplate.repeat_mode_config.end_date);
  
  //   let daysDifference = 0;
    
  //   while (startDate <= endDate) {
  //     // Check if the current date's day of the week is not in the excludeDay array
  //     if (!excludeDay.includes(startDate.getDay())) {
  //       daysDifference++;
  //     }
  
  //     // Move to the next day
  //     startDate.setDate(startDate.getDate() + 1);
  //   }
  //   console.log(daysDifference,'daysDifference')
  
  //   this.setState({ no_of_occurance: daysDifference });
  // };
 
  
  excludeExtendEnddate = (excludeId) => {
    // console.log(excludeId,'excludeId')
    if (this.state.occurence !== "" && this.state.publishTemplate.repeat_mode_config.start_date !== null) {
      var startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
      var occurrence = parseInt(this.state.occurence, 10);

      if (!isNaN(occurrence)) {
        while (occurrence > 0) {
          startDate.setDate(startDate.getDate() + 1);
          // Check if the current day is not a Sunday (Sunday is 0 in JavaScript's Date object)
          if (!excludeId.includes(startDate.getDay())) {
            // console.log(startDate,'startDate')
            occurrence--;
          }
        }

        const endYear = startDate.getFullYear();
        const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
        const endDay = String(startDate.getDate()).padStart(2, '0');
        return `${endYear}-${endMonth}-${endDay}`;
      }
    }
    return null;
  }





  // excludeExtendEnddate = (excludeId) => {
  //   // console.log(excludeId,'excludeId')
  //   if (this.state.occurence !== "" && this.state.publishTemplate.repeat_mode_config.start_date !== null) {
  //     var startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
  //     var occurrence = parseInt(this.state.occurence, 10);
     
  //     if (!isNaN(occurrence)) {
  //       while (occurrence > 0) {
  //         startDate.setDate(startDate.getDate() + 1);
  //         // Check if the current day is not a Sunday (Sunday is 0 in JavaScript's Date object)
  //         if (!excludeId.includes(startDate.getDay())) {
  //         // console.log(startDate,'startDate')
  //           occurrence--;
  //         }
  //       }

  //       const endYear = startDate.getFullYear();
  //       const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
  //       const endDay = String(startDate.getDate()).padStart(2, '0');
  //       return `${endYear}-${endMonth}-${endDay}`;
  //     }
  //   }
  //   return null;
  // }


  setTimeFrame = (event) => {
    console.log(this.state.publishTemplate, event.target.value, event.target.name)
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
    this.setState({
      publishTemplate 
    })


  }

  calculateEndDateInMonth=(mode)=>{
    if(mode === undefined){
    const start = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
    const year = start.getFullYear();
    const month = start.getMonth() + Number(this.state.occurence);
    console.log(start,year,month)
    // Calculate the new end date
    const endDate = new Date(year, month, start.getDate());
    console.log(endDate,'endDate')
    // Adjust for edge cases like exceeding the number of days in the month
    // while (endDate.getMonth() !== month) {
    // // console.log(endDate,'endDate')
    //   endDate.setDate(endDate.getDate() - 1);
    // }
  
    return endDate.toISOString().slice(0, 10);
  }
  if(mode === "option2"){
    const start = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
    const end = new Date(this.state.publishTemplate.repeat_mode_config.end_date);
  
    const startYear = start.getFullYear();
    const startMonth = start.getMonth();
  
    const endYear = end.getFullYear();
    const endMonth = end.getMonth();
  
    // Calculate the difference in months
    const monthsDifference = (endYear - startYear) * 12 + (endMonth - startMonth);
    console.log(monthsDifference,'monthsDifference')
    return Math.abs(monthsDifference);
  }

  }

  




  weekStartDay= async(e, item, idx,mode)=>{
    console.log(this.state.publishTemplate)
    var publishTemplate ={...this.state.publishTemplate}
    var checkedIndex = _.findIndex(publishTemplate.repeat_mode_config.days, { "checked": true })
    console.log(checkedIndex,'checkedIndex')
    if(checkedIndex !== -1){
      publishTemplate["repeat_mode_config"]["days"][checkedIndex]["checked"]=false
    }
    publishTemplate["repeat_mode_config"]["days"][idx]["checked"]=true
    publishTemplate["repeat_mode_config"]["week_start_day"] = item
    this.setState({
      publishTemplate 
    })
    var calculateEndDate = await this.calculateEnddateInWeek(publishTemplate["repeat_mode_config"]["week_start_day"]["day_id"],this.state.selectedRadioOption)
    console.log(calculateEndDate,'calculateEndDate')
    publishTemplate["repeat_mode_config"]["end_date"] = calculateEndDate
    this.updatePublishTemplateData()
    // this.updatePublishTemplateData()

  }

  calculateEnddateInWeek = (weekStartId, radioOption) => {
    if (radioOption === "occurence") {
      const start = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
      const weekStart = Number(weekStartId); // Assuming 0 for Sunday, 1 for Monday, and so on
      var daysToAdd = Number(this.state.publishTemplate.repeat_mode_config.no_of_occurance) * 7;
      console.log(start, weekStart, daysToAdd)
      if (start.getDay() !== weekStart) {
        daysToAdd += (7 - (start.getDay() - weekStart + 7) % 7);
      }
      const endDate = new Date(start);
      endDate.setDate(start.getDate() + daysToAdd);
      return endDate.toISOString().slice(0, 10);
    }
    else {
      const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
      const start = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
      const end = new Date(this.state.publishTemplate.repeat_mode_config.end_date);
      const differenceInDays = Math.round(Math.abs((start - end) / oneDay));
      const weeks = Math.floor(differenceInDays / 7);

      return weeks;
    }
  }

  getMinDate = (mode) => {
    if (mode == undefined) {
      const startDate =this.state.repeat_mode_config?.starts_on !=="immediate" ? new Date(this.state.repeat_mode_config?.starts_on) : new Date()
      console.log(startDate)
      startDate.setDate(startDate.getDate() + 6); // Add 7 days

      // Convert the updated date back to a string in 'YYYY-MM-DD' format
      const updatedDate = startDate.toISOString().slice(0, 10);
      // return upda
      console.log(updatedDate, 'updatedDate')
      return updatedDate
    }
    if (mode === "3") {
      const startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
      startDate.setDate(startDate.getDate() + 30); // Add 7 days

      // Convert the updated date back to a string in 'YYYY-MM-DD' format
      const updatedDate = startDate.toISOString().slice(0, 10);
      // return upda
      console.log(updatedDate, 'updatedDate')
      return updatedDate
    }
  }
  yearPeriodicity=(event,item,index,mode)=>{
    console.log(item,index,mode,'item,index,mode',this.state.quater_periodicity,this.state.quater_months)
    var publishTemplate ={...this.state.publishTemplate}
    // var quater_months={...this.state.quater_months}
    if(mode === "4"){
    if (event.target.checked === true) {
      item["checked"] = event.target.checked
      publishTemplate.repeat_mode_config["selected_quater"]= item
      publishTemplate.repeat_mode_config["selected_quater_months"]=this.state.publishTemplate.repeat_mode_config.calendar_type ==="Yearly calendar" ? this.state.quater_months[item.name] : this.state.publishTemplate.repeat_mode_config.calendar_type ==="Financial calendar" ? this.state.financial_quater_months[item.name] :this.state.quater_months[item.name]
      publishTemplate.repeat_mode_config["selected_months"]=null

      // th
    }
    this.setState({
      publishTemplate,
      selected_quater_months : this.state.publishTemplate.repeat_mode_config.calendar_type ==="Yearly calendar" ? this.state.quater_months[item.name] : this.state.publishTemplate.repeat_mode_config.calendar_type ==="Financial calendar" ? this.state.financial_quater_months[item.name] :this.state.quater_months[item.name]
    },()=>{
      this.updatePublishTemplateData()
    })
  }
  if(mode === "5"){
    if (event.target.checked === true) {
      item["checked"] = event.target.checked
      publishTemplate.repeat_mode_config["selected_half_yearly"]= item
      publishTemplate.repeat_mode_config["selected_half_yearly_months"]= this.state.publishTemplate.repeat_mode_config.calendar_type ==="Yearly calendar" ? this.state.half_months[item.name] :this.state.publishTemplate.repeat_mode_config.calendar_type ==="Financial calendar" ? this.state.financial_half_months[item.name] : this.state.half_months[item.name]
      publishTemplate.repeat_mode_config["selected_months"]=null

      // th
    }
    this.setState({
      publishTemplate,
      selected_half_months : publishTemplate.repeat_mode_config["selected_half_yearly_months"]
    },()=>{
      this.updatePublishTemplateData()
    })
  }


  } 
  allowedAuditForYearly=(event,item,index)=>{
    console.log(event,item,index,'event,item,index,')
    var allowed_audit_yearly =[...this.state.allowed_audit_yearly]
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate.repeat_mode_config["allowed_audit_yearly"]=item

    allowed_audit_yearly.map((ele,idx)=>{
      if(index !== idx){
        allowed_audit_yearly[idx]["checked"] = false
      }
      else{
        allowed_audit_yearly[idx]["checked"] = true

      }
    })
    this.setState({
      allowed_audit_yearly,
      publishTemplate
    },()=>{
      this.updatePublishTemplateData()
    })
  }

  allowedAuditForMonth=(event,item,index,day)=>{
    var allowed_audit_monthy =[...this.state.allowed_audit_monthy]
    var publishTemplate ={...this.state.publishTemplate}
    if(item !== undefined){
    item["checked"] = true
    publishTemplate.repeat_mode_config["allowed_audit_monthy"]=item
    allowed_audit_monthy.map((ele,idx)=>{
      if(index !== idx){
        allowed_audit_monthy[idx]["checked"] = false
      }
      else{
        allowed_audit_monthy[idx]["checked"] = true

      }
    })

    this.setState({
      allowed_audit_monthy,
      publishTemplate
    },()=>{
      this.updatePublishTemplateData()
    })
  }
  else{
    if(index === "2"){
    publishTemplate.repeat_mode_config["allowed_audit_monthy"][event.target.name]=this.state.specific_dates
    console.log(publishTemplate,'publishTemplate')
    }
    if(index === "3"){
      console.log(event.target.value,'event.target.value')
      day["checked"]=true
      publishTemplate.repeat_mode_config["allowed_audit_monthy"][event.target.name]=day

    }
    this.setState({
      // allowed_audit_monthy,
      publishTemplate
    },()=>{
      this.updatePublishTemplateData()
    })
  }
    // if(event.target.checked === true){
    //   allowed_audit_monthy[index]["checked"]= true
    // }
  }

  handleShowTimeFrame=(event)=>{
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate["repeat_mode_config"]["show_time_frame"]=event.target.checked
    this.setState({
      publishTemplate
    },()=>{
      this.updatePublishTemplateData()
    })
  }


  appendDayofMonth=(event,item)=>{
    var publishTemplate ={...this.state.publishTemplate}
    publishTemplate["repeat_mode_config"]["allowed_audit_monthy"]["position_of_the_week"]=item
    this.setState({
      publishTemplate
    },()=>{
      this.updatePublishTemplateData()
    })
  }

  calendarType=(event)=>{
    console.log(event.target.value,event)
    var publishTemplate ={...this.state.publishTemplate}

    publishTemplate["repeat_mode_config"]["calendar_type"]=event.target.value
    publishTemplate["repeat_mode_config"]["selected_quater"]=null
    publishTemplate["repeat_mode_config"]["selected_half_yearly"]=null
    publishTemplate["repeat_mode_config"]["selected_months"]=null

   

    this.setState({
      publishTemplate
    },()=>{
      this.updatePublishTemplateData()

    })

  }


  periodicityMonths=(events,data,idx,mode)=>{
    console.log(data,idx,this.state.selected_quater_months)
    var publishTemplate = {...this.state.publishTemplate}
    if (mode === "4") {
      var selected_quater_months = [...this.state.selected_quater_months]
      publishTemplate["repeat_mode_config"]["selected_months"] = data
    }
    if (mode === "5") {
      publishTemplate["repeat_mode_config"]["selected_months"] = data

    }
    this.setState({
      publishTemplate
    },()=>{
      this.updatePublishTemplateData()
    })

  }


  everyType=(event,mode)=>{
    console.log(event.target.value)
    if(mode === "1"){
      // var data ={
      //   selected_repeat : event.target.value,
      //   repea
      // }
      var repeat_mode_config = {...this.state.repeat_mode_config}
      repeat_mode_config["selected_repeat"] =event.target.value
    this.setState({
      selected_every_type : event.target.value,
      repeat_mode_config
      
    },()=>{
      console.log(this.state.repeat_mode_config,'repeat_mode_config')
    })
    }
    if(mode === "2"){
      var repeat_mode_config ={...this.state.repeat_mode_config}
      repeat_mode_config["repeat_on"]=event.target.value
      repeat_mode_config["selected_day"]=null 
      repeat_mode_config["selected_year_month_type"]=null
      repeat_mode_config["selected_year_month_type_on"]=null



      this.setState({
        selected_day_type : repeat_mode_config.selected_repeat === "Year" ? "" :event.target.value,
        repeat_mode_config,
        selected_year_type :repeat_mode_config.selected_repeat === "Year" ? event.target.value :""
      },()=>{
      console.log(this.state.repeat_mode_config,'repeat_mode_config')

      })
    }
    if(mode === "3"){
    console.log(event.target.value)

      var repeat_mode_config ={...this.state.repeat_mode_config}
      repeat_mode_config["selected_day"]=event.target.value
      this.setState({
        selected_year_type : event.target.value,
        repeat_mode_config
      },()=>{
      console.log(this.state.repeat_mode_config,'repeat_mode_config')

      })
    }
    if(mode === "4"){
      console.log(event.target.value)
  
        var repeat_mode_config ={...this.state.repeat_mode_config}
        repeat_mode_config["selected_year_month_type"]=event.target.value
        this.setState({
          // selected_year_type : event.target.value,
          repeat_mode_config
        },()=>{
        console.log(this.state.repeat_mode_config,'repeat_mode_config')
  
        })
      }
      if(mode === "5"){
        console.log(event.target.value)
    
          var repeat_mode_config ={...this.state.repeat_mode_config}
          repeat_mode_config["selected_year_month_type_on"]=event.target.value
          this.setState({
            // selected_year_type : event.target.value,
            repeat_mode_config
          },()=>{
          console.log(this.state.repeat_mode_config,'repeat_mode_config')
    
          })
        }
        if(mode === "6"){
          var repeat_mode_config ={...this.state.repeat_mode_config}
          console.log(event.target.name)
          if(event.target.name === "never"){
          repeat_mode_config["ends"]=event.target.name
          repeat_mode_config["ends_based_on"]=event.target.name

          }
          if(event.target.name === 'end_date'){
            repeat_mode_config["ends"]=event.target.value
          repeat_mode_config["ends_based_on"]=event.target.name
          }
          if(event.target.name === 'after'){
          repeat_mode_config["ends_based_on"]=event.target.name

          }
          this.setState({
            repeat_mode_config
          },()=>{
          console.log(this.state.repeat_mode_config,'repeat_mode_config')
    
          })


        }
  }





  repeatModeComponent(mode) {
    return (
      <Form action="#">

        {
          mode == "0" ?

            <div>
            </div>
            :
            mode == "1" ?

              <Row >
                <Col md={2}>

                </Col>
                <Col md={7}>
                  <Container fluid>
                    <Card style={{ border: "1px solid #bcbdbd" }}>
                      <CardBody>
                        <label>Repeat Every :</label>&nbsp; <input type={"number"}
                          onChange={(e) => {
                            var repeat_mode_config = { ...this.state.repeat_mode_config }
                            repeat_mode_config["repeat_duration"] = e.target.value
                            this.setState({
                              repeat_mode_config
                            }, () => {
                              console.log(this.state.repeat_mode_config, "repeat_mode_config")
                            })
                          }}
                          defaultValue={1} max={30} min={1} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span> &nbsp; :
                        <select onChange={(e) => { this.everyType(e, "1") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }}>
                          <option>Select</option>
                          <option >Day</option>
                          <option>Week</option>
                          <option>Month</option>
                          <option>Year</option>
                        </select>&nbsp;
                        {
                          this.state.selected_every_type === "Day" &&
                          <>
                            {
                              console.log("opens on")
                            }
                            <Row style={{ marginTop: "13px" }}>
                              <Col md={3}>
                                <label>Repeat Mode Starts :<span className="text-danger">*</span></label>&nbsp;
                                <div>
                                  <input type={"radio"}
                                    name="starts_on"
                                    id="starts_on"
                                    checked={this.state.repeat_mode_config?.starts_on !== "immediate" ? true : false}
                                  />&nbsp;
                                  <label htmlFor="starts_on">On</label> &nbsp;
                                  <input
                                    type={"date"}
                                    name="starts_on"
                                    htmlFor="starts_on"
                                    onChange={(e) => {
                                      var repeat_mode_config = { ...this.state.repeat_mode_config }
                                      repeat_mode_config[e.target.name] = e.target.value
                                      this.setState({
                                        repeat_mode_config
                                      }, () => {
                                        console.log(this.state.repeat_mode_config, "repeat")
                                      })
                                    }}
                                  />
                                </div>
                                <div>
                                  <input type={"radio"}
                                    name="immediate"
                                    id="immediate"
                                    checked={this.state.repeat_mode_config?.starts_on === "immediate" ? true : false}

                                    onChange={(e) => {
                                      var repeat_mode_config = { ...this.state.repeat_mode_config }
                                      repeat_mode_config["starts_on"] = e.target.name
                                      this.setState({
                                        repeat_mode_config
                                      })
                                    }}
                                  />&nbsp;
                                  <label htmlFor="immediate" style={{ marginLeft: "4px" }} >Immediately</label> &nbsp;
                                </div>

                              </Col>


                            </Row>


                            <Row style={{ marginTop: "13px" }}>
                              <Col md={3}>
                                <label>Audit Allowed Between:</label>&nbsp;
                                <input
                                  className="form-control mx-4  "
                                  type="time"
                                  onChange={(e) => {
                                    // console.log(e.target.value)
                                    var repeat_mode_config = { ...this.state.repeat_mode_config }
                                    repeat_mode_config[e.target.name] = e.target.value
                                    this.setState({
                                      repeat_mode_config
                                    }, () => {
                                      console.log(this.state.repeat_mode_config, 'config')
                                    })
                                    //  this.setTimeFrame(e)
                                  }}
                                  name="start_time"
                                  defaultValue="00:00:00"
                                  id="example-time-input"
                                />
                              </Col>
                              <Col md={3}>
                                {/* <label>Audit Close on:</label>&nbsp; */}
                                <label></label>
                                <input
                                  className="form-control mx-4  "
                                  type="time"
                                  style={{marginTop:"7px"}}
                                  onChange={(e) => {
                                    // console.log(e.target.value)
                                    var repeat_mode_config = { ...this.state.repeat_mode_config }
                                    repeat_mode_config[e.target.name] = e.target.value
                                    this.setState({
                                      repeat_mode_config
                                    }, () => {
                                      console.log(this.state.repeat_mode_config, 'config')
                                    })
                                    //  this.setTimeFrame(e)
                                  }}
                                  name="end_time"
                                  defaultValue="23:59:00"
                                  id="example-time-input"
                                />
                              </Col>
                            </Row>

                          </>
                        }
                        {/* <Flatpickr
                          className="form-control d-block"
                          placeholder="Select time"
                          onChange={(e)=>{
                            // console.log(e,'e')
                            var full_date = new Date(e)
                            var hours = full_date.getHours()
                            var minute = full_date.getMinutes()
                            var second = full_date.getSeconds()
                            console.log(`${hours}${minute}${second}`)

                          }}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i"
                          }}
                        /> */}
                        {
                          this.state.selected_every_type !== "Day" &&this.state.selected_every_type !== "Week"&&
                          // this.state.repeat_mode_config?.selected_every_type !== undefined &&
                          <>
                            {"ON"}&nbsp;
                            {
                              this.state.selected_every_type === "Week" ?
                                <select onChange={(e) => { this.everyType(e, "2") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                  <option >Select</option>
                                  <option>Start of a week</option>
                                  <option>Day</option>
                                  <option>End of a week</option>
                                  <option>After</option>
                                </select>
                                :
                                this.state.selected_every_type === "Year" ?
                                  <select onChange={(e) => { this.everyType(e, "2") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                    <option disabled={true}>Select</option>
                                    <option>Start</option>
                                    <option>Month</option>
                                    <option>End</option>
                                    <option>After</option>
                                  </select>
                                  :
                                  <select onChange={(e) => { this.everyType(e, "2") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                    <option >Select</option>
                                    <option>Start of a month</option>
                                    <option>Week</option>
                                    <option>Day</option>
                                    <option>End of a month</option>
                                    <option>After</option>
                                  </select>


                            }

                            {
                              this.state.selected_day_type === "Day" &&
                              <select onChange={(e) => { this.everyType(e, "3") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                <option disabled={true}>Select</option>
                                <option>Monday</option>
                                <option>Tuesday</option>
                                <option>Wednesday</option>
                                <option>Thursday</option>
                                <option>Friday</option>
                                <option>Saturday</option>
                                <option>Sunday</option>


                              </select>
                            }
                            {
                              this.state.repeat_mode_config?.repeat_on === "Month" &&
                              <select onChange={(e) => { this.setState({ selected_month_type: true }, () => { this.everyType(e, "3") }) }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                <option disabled={true}>Select</option>
                                <option>January</option>
                                <option>Febraury</option>
                                <option>March</option>
                                <option>April</option>
                                <option>May</option>
                                <option>June</option>
                                <option>July</option>
                                <option>August</option>
                                <option>September</option>
                                <option>October</option>
                                <option>November</option>
                                <option>December</option>
                              </select>
                            }
                            {
                              this.state.selected_year_type === "After" &&
                              <>
                                <label>a Audit is completed</label>
                                {/* &nbsp;<input type={"number"} defaultValue={1} max={30} min={1}  style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> &nbsp; Occurences */}
                              </>
                            }
                            {
                              this.state.selected_day_type === "Month" &&
                              <select onChange={(e) => { this.everyType(e, "3") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                {/* <option>Monday</option>
                            <option>Tuesday</option>
                            <option>Wednesday</option>
                            <option>Thursday</option>
                            <option>Friday</option>
                            <option>Saturday</option>
                            <option>Sunday</option> */}

                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                                <option>13</option>
                                <option>14</option>
                                <option>15</option>
                                <option>16</option>
                                <option>17</option>
                                <option>18</option>
                                <option>19</option>
                                <option>20</option>
                                <option>21</option>
                                <option>22</option>
                                <option>23</option>
                                <option>24</option>
                                <option>25</option>
                                <option>26</option>
                                <option>27</option>
                                <option>28</option>
                                <option>29</option>
                                <option>30</option>


                              </select>
                            }
                            {
                              this.state.selected_day_type === "Week" &&
                              <select onChange={(e) => { this.everyType(e, "3") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                <option disabled={true}>Select</option>
                                <option>1st Week</option>
                                <option>2nd Week</option>
                                <option>3rd Week</option>
                                <option>4th Week</option>
                              </select>
                            }
                            {
                              this.state.selected_month_type === true &&
                              <select onChange={(e) => { this.setState({ selected_year_month_type: e.target.value }, () => this.everyType(e, "4")) }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                <option disabled={true}>Select</option>
                                <option>Start of a month</option>
                                <option>Week</option>
                                <option>Day</option>
                                <option>End of a month</option>
                              </select>

                            }
                            {
                              this.state.selected_year_month_type === "Week" &&
                              <select onChange={(e) => { this.everyType(e, "5") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                <option disabled={true}>Select</option>
                                <option>1st Week</option>
                                <option>2nd Week</option>
                                <option>3rd Week</option>
                                <option>4th Week</option>
                              </select>
                            }
                            {
                              this.state.selected_year_month_type === "Day" &&

                              <select onChange={(e) => { this.everyType(e, "5") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                                <option>13</option>
                                <option>14</option>
                                <option>15</option>
                                <option>16</option>
                                <option>17</option>
                                <option>18</option>
                                <option>19</option>
                                <option>20</option>
                                <option>21</option>
                                <option>22</option>
                                <option>23</option>
                                <option>24</option>
                                <option>25</option>
                                <option>26</option>
                                <option>27</option>
                                <option>28</option>
                                <option>29</option>
                                <option>30</option>

                              </select>


                            }
                            {
                              this.state.selected_day_type === "After" &&
                              <>
                                <label>a Audit is completed</label>
                                {/* &nbsp;<input type={"number"} defaultValue={1} max={30} min={1}  style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> &nbsp; Occurences */}
                              </>
                            }
                          </>
                        }
                        {
                          this.state.selected_every_type ==="Week" &&
                          <>
                          <Row style={{ marginTop: "13px" }}>
                          <Col md={3}>
                            <label>Repeat Mode Starts :<span className="text-danger">*</span></label>&nbsp;
                            <div>
                              <input type={"radio"}
                                name="starts_on"
                                id="starts_on"
                                checked={this.state.repeat_mode_config?.starts_on !== "immediate" ? true : false}
                              />&nbsp;
                              <label htmlFor="starts_on">On</label> &nbsp;
                              <input
                                type={"date"}
                                name="starts_on"
                                htmlFor="starts_on"
                                onChange={(e) => {
                                  var repeat_mode_config = { ...this.state.repeat_mode_config }
                                  repeat_mode_config[e.target.name] = e.target.value
                                  this.setState({
                                    repeat_mode_config
                                  }, () => {
                                    console.log(this.state.repeat_mode_config, "repeat")
                                  })
                                }}
                              />
                            </div>
                            <div>
                              <input type={"radio"}
                                name="immediate"
                                id="immediate"
                                checked={this.state.repeat_mode_config?.starts_on === "immediate" ? true : false}

                                onChange={(e) => {
                                  var repeat_mode_config = { ...this.state.repeat_mode_config }
                                  repeat_mode_config["starts_on"] = e.target.name
                                  this.setState({
                                    repeat_mode_config
                                  })
                                }}
                              />&nbsp;
                              <label htmlFor="immediate" style={{ marginLeft: "4px" }} >Immediately</label> &nbsp;
                            </div>

                          </Col>


                        </Row>
                        {
                          this.state.selected_every_type === "Week" &&
                          <div>
                            <Label className="text-primary" style={{ marginTop: "11px" }} htmlFor="autoSizingSelect">Allowed Audits On :</Label>

                            <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

                              <div style={{
                                display: 'flex', flexDirection: 'row',
                                //  marginBottom: 10 
                              }}>
                                <div key={""}
                                  className="form-check mx-2 mt-2 ">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={this.state.all_checked === true ? true : false}
                                    // checked={_.filter(this.state.weekDays,{checked : true}).length === this.state.weekDays ? true : false}
                                    // id={item.day}
                                    // checked={
                                    //   var week
                                    // }
                                    // onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
                                    onChange={(event) => {
                                      var weekDays = [...this.state.weekDays]
                                      weekDays = _.map(weekDays, obj => ({ ...obj, checked: event.target.checked }))
                                      console.log(weekDays, 'weekDays')
                                      this.setState({
                                        weekDays,
                                        all_checked: event.target.checked
                                      })
                                      // weekDays[idx]["checked"]=event.target.checked
                                      // this.setState({
                                      //   weekDays
                                      // })
                                    }}

                                  />
                                  <label
                                    className="form-check-label"
                                  // htmlFor={item.day}
                                  >
                                    {/* {item.day} */}
                                    All Days
                                  </label>
                                </div>
                                {
                                  this.state.weekDays.map((item, idx) => {
                                    return (
                                      <div key={idx}
                                        className="form-check mx-2 mt-2 ">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={item.day}
                                          checked={item.checked}
                                          // onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
                                          onChange={(event) => {
                                            var weekDays = [...this.state.weekDays]
                                            weekDays[idx]["checked"] = event.target.checked
                                            this.setState({
                                              weekDays
                                            })
                                          }}

                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={item.day}
                                        >
                                          {item.day}
                                        </label>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>


                        }
                          <Row style={{ marginTop: "13px" }}>
                          <Col md={3}>
                            <label>Audit Allowed Between:</label>&nbsp;
                            <input
                              className="form-control mx-4  "
                              type="time"
                              onChange={(e) => {
                                // console.log(e.target.value)
                                var repeat_mode_config = { ...this.state.repeat_mode_config }
                                repeat_mode_config[e.target.name] = e.target.value
                                this.setState({
                                  repeat_mode_config
                                }, () => {
                                  console.log(this.state.repeat_mode_config, 'config')
                                })
                                //  this.setTimeFrame(e)
                              }}
                              name="start_time"
                              defaultValue="00:00:00"
                              id="example-time-input"
                            />
                          </Col>
                          <Col md={3}>
                            {/* <label>Audit Close on:</label>&nbsp; */}
                            <input
                              style={{marginTop:"28px"}}
                              className="form-control mx-4  "
                              type="time"
                              onChange={(e) => {
                                // console.log(e.target.value)
                                var repeat_mode_config = { ...this.state.repeat_mode_config }
                                repeat_mode_config[e.target.name] = e.target.value
                                this.setState({
                                  repeat_mode_config
                                }, () => {
                                  console.log(this.state.repeat_mode_config, 'config')
                                })
                                //  this.setTimeFrame(e)
                              }}
                              name="end_time"
                              defaultValue="23:59:00"
                              id="example-time-input"
                            />
                          </Col>
                        </Row>
                        </>
                        }
                        {
                          this.state.repeat_mode_config?.starts_on !== undefined &&
                          <>
                            <div style={{ marginTop: "15px" }}>
                              <label>Ends :<span className="text-danger">*</span></label>
                            </div>
                            <div>
                              <input type={"radio"}
                                checked={this.state.repeat_mode_config?.ends_based_on == "never" ? true : false}
                                name="never" id="never" onChange={(e) => { this.everyType(e, "6") }} />&nbsp;<label htmlFor="never">Never</label>
                            </div>
                            <div>
                              <input type={"radio"}
                                name="end_date"
                                checked={this.state.repeat_mode_config?.ends_based_on == "end_date" ? true : false}
                                id="On" onChange={(e) => { this.everyType(e, "6") }} />&nbsp;<label htmlFor="On">On</label> &nbsp;
                              <input
                                type={"date"}
                                name="end_date"
                                min={this.state.repeat_mode_config?.selected_repeat === "Week" ? this.getMinDate() : this.state.repeat_mode_config?.starts_on !== "immediate" ? this.state.repeat_mode_config?.starts_on :this.state.repeat_mode_config?.selected_repeat ==="Week"?  new Date(new Date().toISOString().split('T')[0]).setDate(new Date().getDate() + 7).toISOString().split('T')[0]:new Date("2023-11-29").toISOString().split('T')[0]}
                                htmlFor="On"
                                onChange={(e) => { this.everyType(e, "6") }}
                              />
                            </div>
                            <div>
                              <input type={"radio"} id="after" name="after" checked={this.state.repeat_mode_config?.ends_based_on == "after" ? true : false} onChange={(e) => { this.everyType(e, "6") }} />&nbsp;<label htmlFor="after">After</label> &nbsp;<input type={"number"}
                                onChange={(e) => {
                                  var occurence = e.target.value
                                  var repeat_mode_config = { ...this.state.repeat_mode_config }
                                  repeat_mode_config["occurence"] = occurence
                                  this.setState({
                                    repeat_mode_config
                                  })
                                }}
                                defaultValue={1} max={30} min={1} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> &nbsp; Occurences

                            </div>
                            {
                              console.log(this.state.repeat_mode_config, 'this.state.repeat_mode_config')
                            }
                            {
                              this.state.repeat_mode_config !== undefined &&  this.state.repeat_mode_config?.ends_on !== null && this.state.repeat_mode_config?.ends_on !== "" &&
                              <div
                                className="form-check form-switch form-switch-sm mt-2"
                                style={{
                                  display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}
                              >

                                <input
                                  type="checkbox"
                                  className="form-check-input me-2 mb-0"
                                  onChange={(e) => {
                                    this.setState({
                                      preview_calendar: !this.state.preview_calendar
                                    })
                                  }}
                                />

                                <label
                                  style={{ marginBottom: "0px" }}
                                >
                                  Preview in Calendar
                                </label>
                              </div>
                            }
                            {
                              this.state.preview_calendar &&
                              <CalendarPreview weekDays={this.state.repeat_mode_config.selected_repeat === "Week" ? this.state.weekDays : []} event_data={this.state.repeat_mode_config} occurence={this.state.repeat_mode_config.occurence !== undefined ? true : false} />
                            }
                          </>
                        }


                      </CardBody>
                    </Card>
                  </Container>
                </Col>
                <Col md={3}>

                </Col>
              </Row>
              :
              null

        }

      </Form>
    )
  }







  // repeatModeComponent(mode) {
  //   return (
  //     <Form action="#">

  //       {
  //         mode == "0" ?

  //           <div>
  //             {/* <div className="mb-3 d-flex flex-column  ">
  //               <div className="d-flex flex-row align-items-center">
  //                 <div className="col-3">
  //                   <Label className="text-primary" htmlFor="autoSizingSelect">Start date</Label>
  //                   <div className="col-md-10">
  //                     <input
  //                       className="form-control"
  //                       type="date"
  //                       min={this.state.fromate_date}
  //                       selected={this.state.ptl_startdate}
  //                       defaultValue={this.state.ptl_startdate}
  //                       id="start_date"
  //                       onChange={event => this.onChangeStartDate(event)}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-3">
  //                   <Label className="text-primary" htmlFor="autoSizingSelect">End date</Label>
  //                   <div className="col-md-10">
  //                     <input
  //                       className="form-control"
  //                       type="date"
  //                       min={this.state.fromate_date}
  //                       selected={this.state.ptl_enddate}
  //                       defaultValue={this.state.ptl_enddate}
  //                       id="end_date"
  //                       onChange={event => this.onChangeEndDate(event)}
  //                     />
  //                   </div>
  //                 </div>

  //               </div>
  //             </div> */}
  //           </div>
  //           :
  //           mode == "1" ?

  //             <div>
  //               <Row >
  //                 {
  //                   <React.Fragment>
  //                     <Row>
  //                       <Col md={12}>
  //                         <div className="form-check mb-1">
  //                           <input
  //                             className="form-check-input mt-2 "
  //                             type="radio"
  //                             onChange={(e) => { this.endAfterMethod(e) }}
  //                             name="occurence"
  //                             id="occurence"
  //                             checked={this.state.selectedRadioOption === "occurence"}
  //                             defaultChecked
  //                           />
  //                           <label
  //                             className="form-check-label"
  //                             htmlFor="occurence"
  //                           >
  //                             No. of Occurences
  //                           </label>
  //                           <input type={"number"} max={30} min={0} onChange={(e) => { this.handleOccurance(e,mode) }} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>
  //                           {(this.state.occurence === undefined || this.state.occurence == "") && this.state.selectedRadioOption === "occurence" ?
  //                             <span className="text-danger" style={{ fontSize: "smaller" }}> Please Enter the No.of.days</span>
  //                             :
  //                             null}
  //                         </div>
  //                         {
  //                           this.state.selectedRadioOption === "occurence" && this.state.occurence !== null && this.state.occurence !== undefined && this.state.occurence !== "" &&

  //                           <Row>
  //                             <Col md={12}>
  //                               <div className="d-flex gap-3">
  //                                 <div>
  //                                   <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
  //                                   <FormGroup className="mb-3">
  //                                     <input
  //                                       className="form-control mx-4 "
  //                                       type="date"
  //                                       onChange={(e) => { this.scheduleStartDate(e,mode) }}
  //                                       min={this.state.fromate_date}
  //                                       value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
  //                                     />
  //                                   </FormGroup>
  //                                 </div>
  //                                 <div>
  //                                   {
  //                                     this.state.publishTemplate.repeat_mode_config.start_date !== null &&
  //                                     <>
  //                                       <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date</Label>
  //                                       <FormGroup className="mb-3">
  //                                         <input
  //                                           className="form-control mx-4 "
  //                                           type="date"
  //                                           value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
  //                                           disabled={true}
  //                                         />
  //                                       </FormGroup>
  //                                     </>
  //                                   }

  //                                 </div>
  //                               </div>
  //                             </Col>

  //                           </Row>
  //                         }


  //                       </Col>
  //                     </Row>
  //                     <Row>
  //                       {
  //                         this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.selectedRadioOption === "occurence" &&
  //                         <Col md={12}>

  //                           <Label className="text-primary mx-4 " htmlFor="autoSizingSelect">Exclude Days</Label>

  //                           <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

  //                             <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                               {
  //                                 this.state.weekDays.map((item, idx) => {
  //                                   return (
  //                                     <div key={idx} className="form-check mx-2 mt-2 ">
  //                                       <input
  //                                         type="checkbox"
  //                                         className="form-check-input"
  //                                         id={item.day}
  //                                         checked={item.checked}
  //                                         onChange={e => { this.excludeWeeks(e, item, idx,mode) }}

  //                                       />
  //                                       <label
  //                                         className="form-check-label"
  //                                         htmlFor={item.day}
  //                                       >
  //                                         {item.day}
  //                                       </label>
  //                                     </div>
  //                                   )
  //                                 })
  //                               }
  //                             </div>
  //                           </div>
  //                         </Col>
  //                       }
  //                     </Row>
  //                     <Row>
  //                       <Col md={6}>

  //                         {
  //                           this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.selectedRadioOption === "occurence" &&
                            
  //                           <>
  //                             <Row>
  //                               <Col md={5}>
  //                                 <label className="text-primary mx-4 my-1">
  //                                   Show Time Frame
  //                                 </label>
                                 
  //                                   <input
  //                                     className="form-check-input"
  //                                     type="checkbox"
  //                                     // name="report"
  //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
  //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
  //                                     style={{marginTop:"7px"}}
  //                                   />
                                  
  //                               </Col>
  //                             </Row>
  //                             {
  //                               this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true && 
  //                               <Row>
  //                               <Col md={12}>
  //                                 <div className="d-flex gap-5 ">
  //                                   <div>
  //                                     <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
  //                                     <FormGroup className="mb-3">
  //                                       <input
  //                                         className="form-control mx-4  "
  //                                         type="time"
  //                                         onChange={(e) => { this.setTimeFrame(e) }}
  //                                         name="start_time"
  //                                         defaultValue="00:00:00"
  //                                         id="example-time-input"
  //                                       />
  //                                     </FormGroup>
  //                                   </div>
  //                                   <div className="mx-2">
  //                                     {
  //                                       this.state.publishTemplate.repeat_mode_config.start_date !== null &&
  //                                       <>
  //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
  //                                         <FormGroup className="mb-3">
  //                                           <input
  //                                             className="form-control mx-4"
  //                                             type="time"
  //                                             name="end_time"
  //                                             defaultValue="23:59:00"
  //                                             onChange={(e) => { this.setTimeFrame(e) }}
  //                                             id="example-time-input"
  //                                           />
  //                                         </FormGroup>
  //                                       </>
  //                                     }
  //                                   </div>
  //                                 </div>
  //                               </Col>
  //                             </Row>
  //                            }
                            
  //                           </>
  //                         }
  //                       </Col>

  //                     </Row>
  //                     <Row>
  //                       <Col md={12}>
  //                         <div className="form-check">
  //                           <input
  //                             className="form-check-input"
  //                             type="radio"
  //                             onChange={(e) => { this.endAfterMethod(e) }}
  //                             name="enddate"
  //                             id="enddate"
  //                             checked={this.state.selectedRadioOption === "enddate"}
  //                           />
  //                           <label
  //                             className="form-check-label"
  //                             htmlFor="enddate"
  //                           >
  //                             Date Range
  //                           </label>
  //                         </div>
  //                         {
  //                           this.state.selectedRadioOption === "enddate" &&
  //                           <>
  //                             <Row>
  //                               <Col md={12}>
  //                                 <div className="d-flex gap-3">
  //                                   <div>
  //                                     <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
  //                                     <FormGroup className="mb-3">
  //                                       <input
  //                                         className="form-control mx-4 "
  //                                         type="date"
  //                                         onChange={(e) => { this.scheduleStartDate(e,mode) }}
  //                                         name="start_date"
  //                                         min={this.state.fromate_date}
  //                                         value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
  //                                       />
  //                                     </FormGroup>
  //                                   </div>
  //                                   <div>
  //                                     <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
  //                                     <FormGroup className="mb-3">
  //                                       <input
  //                                         className="form-control mx-4 "
  //                                         type="date"
  //                                         name="end_date"
  //                                         min={this.state.publishTemplate.repeat_mode_config.start_date !== null ? this.state.publishTemplate.repeat_mode_config.start_date : this.state.fromate_date}
  //                                         onChange={(e) => { this.scheduleStartDate(e,mode) }}
  //                                         disabled={this.state.publishTemplate.repeat_mode_config.start_date === null}
  //                                         value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
  //                                       />
  //                                     </FormGroup>
  //                                   </div>
  //                                 </div>
  //                               </Col>
  //                             </Row>
  //                             <Row>
  //                               {
  //                                 this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null &&
  //                                 <Col md={12}>

  //                                   <Label className="text-primary mx-4 " htmlFor="autoSizingSelect">Exclude Days</Label>

  //                                   <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

  //                                     <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                       {
  //                                         this.state.weekDays.map((item, idx) => {
  //                                           return (
  //                                             <div key={idx} className="form-check mx-2 mt-2 ">
  //                                               <input
  //                                                 type="checkbox"
  //                                                 className="form-check-input"
  //                                                 id={item.day}
  //                                                 checked={item.checked}
  //                                                 onChange={e => { this.excludeWeeks(e, item, idx,mode) }}
  //                                               />
  //                                               <label
  //                                                 className="form-check-label"
  //                                                 htmlFor={item.day}
  //                                               >
  //                                                 {item.day}
  //                                               </label>
  //                                             </div>
  //                                           )
  //                                         })
  //                                       }
  //                                     </div>
  //                                   </div>
  //                                 </Col>
  //                               }
  //                             </Row>
  //                             <Row>
  //                               <Col md={12}>
  //                                 {
  //                                   this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null ?
  //                                     <div className="text-danger p-1 px-0" style={{ marginLeft: "2%", fontSize: '0.8125rem' }}> Number of occurences between the Start Date({new Date(this.state.publishTemplate.repeat_mode_config.start_date).toLocaleDateString('en-GB')}) and End Date ({new Date(this.state.publishTemplate.repeat_mode_config.end_date).toLocaleDateString('en-GB')}) is <span className="text-danger">{this.state.no_of_occurance} </span> occurences</div>
  //                                     :
  //                                     null
  //                                 }

  //                               </Col>
  //                             </Row>
  //                           </>

  //                         }
  //                         <Row>
  //                           <Col md={6}>
                         


  //                             {
  //                               this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.selectedRadioOption === "enddate" &&
  //                               <>
  //                                 <Row>
  //                               <Col md={5}>
  //                                 <label className="text-primary mx-4 my-1">
  //                                   Show Time Frame
  //                                 </label>
                                 
  //                                   <input
  //                                     className="form-check-input"
  //                                     type="checkbox"
  //                                     name="report"
  //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
  //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
  //                                     style={{marginTop:"7px"}}
  //                                   />
                                  
  //                               </Col>
  //                             </Row>
  //                             {
  //                                 this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true &&
                                  
  //                                 <Row>
  //                                 <Col md={12}>
  //                                   <div className="d-flex gap-5 ">
  //                                     <div>
  //                                       <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
  //                                       <FormGroup className="mb-3">
  //                                         <input
  //                                           className="form-control mx-4  "
  //                                           type="time"
  //                                           onChange={(e) => { this.setTimeFrame(e) }}
  //                                           name="start_time"
  //                                           defaultValue="00:00:00"
  //                                           id="example-time-input"
  //                                         />
  //                                       </FormGroup>
  //                                     </div>
  //                                     <div className="mx-2">
  //                                       <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
  //                                       <FormGroup className="mb-3">
  //                                         <input
  //                                           className="form-control mx-4"
  //                                           type="time"
  //                                           name="end_time"
  //                                           defaultValue="23:59:00"
  //                                           onChange={(e) => { this.setTimeFrame(e) }}
  //                                           id="example-time-input"
  //                                         />
  //                                       </FormGroup>
  //                                     </div>
  //                                   </div>
  //                                 </Col>
  //                               </Row>
  //                               }
                                  
  //                               </>
  //                             }
  //                           </Col>
  //                         </Row>
  //                       </Col>

  //                     </Row>
  //                   </React.Fragment>
  //                 }
  //               </Row>


  //             </div> :
  //             mode == "2" ?
  //               <div>
  //                 <React.Fragment>
  //                   <Row>
  //                     <Col md={12}>
  //                       <div className="form-check mb-1">
  //                         <input
  //                           className="form-check-input mt-2 "
  //                           type="radio"
  //                           onChange={(e) => { this.endAfterMethod(e) }}
  //                           name="occurence"
  //                           id="occurence"
  //                           checked={this.state.selectedRadioOption === "occurence"}
  //                           defaultChecked
  //                         />
  //                         <label
  //                           className="form-check-label"
  //                           htmlFor="occurence"
  //                         >
  //                           No. of Occurences
  //                         </label>
  //                         <input type={"number"} max={30} min={0} onChange={(e) => { this.handleOccurance(e,mode) }} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>
  //                         {(this.state.occurence === undefined || this.state.occurence == "") && this.state.selectedRadioOption === "occurence" ?
  //                           <span className="text-danger" style={{ fontSize: "smaller" }}> Please Enter the No.of.days</span>
  //                           :
  //                           null}
  //                       </div>
  //                       {
  //                         this.state.selectedRadioOption === "occurence" && this.state.occurence !== "" && this.state.occurence !== undefined && this.state.occurence !== null &&

  //                         <Row>
  //                           <Col md={12}>
  //                             <div className="d-flex gap-3">
  //                               <div>
  //                                 <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
  //                                 <FormGroup className="mb-3">
  //                                   <input
  //                                     className="form-control mx-4 "
  //                                     type="date"
  //                                     onChange={(e) => { this.scheduleStartDate(e,mode) }}
  //                                     min={this.state.fromate_date}
  //                                     value={this.state.publishTemplate.repeat_mode_config.start_date}
  //                                   />
  //                                 </FormGroup>
  //                               </div>
  //                               <div>
  //                                 {
  //                               this.state.publishTemplate.repeat_mode_config.start_date !== null &&
  //                               <>
  //                                <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Week Start Day <span className="text-danger">*</span></Label>
  //                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

  //                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                           {
  //                                             this.state.publishTemplate.repeat_mode_config.days.map((item, idx) => {
  //                                               return (
  //                                                 <div key={idx} className="form-check mx-2 mt-2 ">
  //                                                   <input
  //                                                     type="radio"
  //                                                     className="form-check-input"
  //                                                     id={item.day}
  //                                                     checked={this.state.publishTemplate.repeat_mode_config.week_start_day !== null? this.state.publishTemplate.repeat_mode_config.week_start_day.day === item.day ? true : false : false}
  //                                                     onChange={e => { this.weekStartDay(e, item, idx,mode) }}
  //                                                   />
  //                                                   <label
  //                                                     className="form-check-label"
  //                                                     htmlFor={item.day}
  //                                                   >
  //                                                     {item.day}
  //                                                   </label>
  //                                                 </div>
  //                                               )
  //                                             })
  //                                           }
  //                                         </div>
  //                                       </div>
  //                               </>
  //                                }
  //                               </div>
  //                             </div>
  //                           </Col>

  //                         </Row>
  //                       }
  //                     </Col>
  //                   </Row>
  //                   <Row >
  //                       <Col md={6}>
  //                       {
  //                               this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.publishTemplate.repeat_mode_config.week_start_day !== null && this.state.selectedRadioOption === "occurence" &&
  //                               <>
  //                                 <Row>
  //                                   <Col md={4}>
  //                                     <div className="d-flex gap-5 ">
  //                                       <div>
  //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Date</Label>
  //                                         <FormGroup className="mb-3">
  //                                           <input
  //                                             className="form-control mx-4  "
  //                                             type="date"
  //                                             disabled={true}
  //                                             value={this.state.publishTemplate.repeat_mode_config.end_date}
  //                                             // onChange={(e) => { this.setTimeFrame(e) }}
  //                                             // name="start_time"
  //                                             // defaultValue="00:00:00"
  //                                             // id="example-time-input"
  //                                           />
  //                                         </FormGroup>
  //                                       </div>
  //                                       </div>
  //                                       </Col>
                             
  //                           </Row>

  //                           <Row>
  //                           <Col md={6}>
  //                               <Label className="text-primary mx-4 ">Allow to Take Audit</Label>
  //                               <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                   {
  //                                     this.state.publishTemplate.repeat_mode_config.days.map((item, idx) => {
  //                                       return (
  //                                         <div key={idx} className="form-check mx-2 mt-2 ">
  //                                           <input
  //                                             type="checkbox"
  //                                             className="form-check-input"
  //                                             id={item.day}
  //                                             // checked={item.checked}
  //                                             checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days?.includes(Number(item.day_id)) ? true : false }
  //                                             onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
  //                                           />
  //                                           <label
  //                                             className="form-check-label"
  //                                             // htmlFor={item.day}
  //                                           >
  //                                             {item.day}
  //                                           </label>
  //                                         </div>
  //                                       )
  //                                     })
  //                                   }
  //                                 </div>
  //                               </div>
  //                             </Col>
  //                           </Row>
  //                           <Row>
  //                           <Col md={6}>

                           

  //                             {
  //                               this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.publishTemplate.repeat_mode_config.week_start_day !== null &&
  //                               <>
  //                                <Row>
  //                               <Col md={12}>
  //                                 <label className="text-primary mx-4 my-1">
  //                                   Show Time Frame
  //                                 </label>
                                 
  //                                   <input
  //                                     className="form-check-input"
  //                                     type="checkbox"
  //                                     name="report"
  //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
  //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
  //                                     style={{marginTop:"7px"}}
  //                                   />
                                  
  //                               </Col>
  //                             </Row>
  //                             {
  //                               this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true &&
  //                                 <Row>
  //                                   <Col md={12}>
  //                                     <div className="d-flex gap-5 ">
  //                                       <div>
  //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
  //                                         <FormGroup className="mb-3">
  //                                           <input
  //                                             className="form-control mx-4  "
  //                                             type="time"
  //                                             onChange={(e) => { this.setTimeFrame(e) }}
  //                                             name="start_time"
  //                                             defaultValue="00:00:00"
  //                                             id="example-time-input"
  //                                           />
  //                                         </FormGroup>
  //                                       </div>
  //                                       <div className="mx-2">
  //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
  //                                         <FormGroup className="mb-3">
  //                                           <input
  //                                             className="form-control mx-4"
  //                                             type="time"
  //                                             name="end_time"
  //                                             defaultValue="23:59:00"
  //                                             onChange={(e) => { this.setTimeFrame(e) }}
  //                                             id="example-time-input"
  //                                           />
  //                                         </FormGroup>
  //                                       </div>
  //                                     </div>
  //                                   </Col>
  //                                 </Row>
  //                             }

  //                               </>
  //                             }
  //                           </Col>
  //                         </Row>
  //                                       </>
  //                         }                     
  //                       </Col>
  //                       <Col>
  //                       </Col>

  //                   </Row>
  //                   <Row>
  //                     <Col md={12}>
  //                       <div className="form-check">
  //                         <input
  //                           className="form-check-input"
  //                           type="radio"
  //                           onChange={(e) => { this.endAfterMethod(e) }}
  //                           name="enddate"
  //                           id="enddate"
  //                           checked={this.state.selectedRadioOption === "enddate"}
  //                         />
  //                         <label
  //                           className="form-check-label"
  //                           htmlFor="enddate"
  //                         >
  //                           Date Range
  //                         </label>
  //                       </div>
  //                       {
  //                         this.state.selectedRadioOption === "enddate" &&
  //                         <>
  //                           <Row>
  //                             <Col md={12}>
  //                               <div className="d-flex gap-3">
  //                                 <div>
  //                                   <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
  //                                   <FormGroup className="mb-3">
  //                                     <input
  //                                       className="form-control mx-4 "
  //                                       type="date"
  //                                       onChange={(e) => { this.scheduleStartDate(e,mode) }}
  //                                       name="start_date"
  //                                       min={this.state.fromate_date}
  //                                       value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
  //                                     />
  //                                   </FormGroup>
  //                                 </div>
  //                                 <div>
  //                                   <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
  //                                   <FormGroup className="mb-3">
  //                                     <input
  //                                       className="form-control mx-4 "
  //                                       type="date"
  //                                       name="end_date"
  //                                       min={this.state.publishTemplate.repeat_mode_config.start_date !== null ? this.getMinDate() : this.state.fromate_date}
  //                                       onChange={(e) => { this.scheduleStartDate(e,mode) }}
  //                                       disabled={this.state.publishTemplate.repeat_mode_config.start_date === null}
  //                                       value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
  //                                     />
  //                                   </FormGroup>
  //                                 </div>
  //                               </div>
  //                             </Col>
  //                           </Row>
  //                           {
  //                             this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.selectedRadioOption === "enddate" &&
  //                          <>
  //                          <Row>
  //                           <Col md={6}>
  //                               <Label className="text-primary mx-4 ">Allow to Take Audit</Label>
  //                               <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                   {
  //                                     this.state.publishTemplate.repeat_mode_config.days.map((item, idx) => {
  //                                       return (
  //                                         <div key={idx} className="form-check mx-2 mt-2 ">
  //                                           <input
  //                                             type="checkbox"
  //                                             className="form-check-input"
  //                                             id={item.day}
  //                                             // checked={item.checked}
  //                                             checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
  //                                             onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
  //                                           />
  //                                           <label
  //                                             className="form-check-label"
  //                                             // htmlFor={item.day}
  //                                           >
  //                                             {item.day}
  //                                           </label>
  //                                         </div>
  //                                       )
  //                                     })
  //                                   }
  //                                 </div>
  //                               </div>
  //                             </Col>
  //                             </Row>
  //                             <Row>
  //                               <Col md={6}>
                               
  //                                     <div className="text-danger p-1 px-0" style={{ marginLeft: "2%", fontSize: '0.8125rem' }}> Number of occurences between the Start Date({new Date(this.state.publishTemplate.repeat_mode_config.start_date).toLocaleDateString('en-GB')}) and End Date ({new Date(this.state.publishTemplate.repeat_mode_config.end_date).toLocaleDateString('en-GB')}) is <span className="text-danger">{this.state.no_of_occurance} </span> occurences</div>
                                   
  //                               </Col>

  //                             </Row>
  //                             <Row>
  //                                   <Col md={12}>
  //                                   <Row>
  //                               <Col md={12}>
  //                                 <label className="text-primary mx-4 my-1">
  //                                   Show Time Frame
  //                                 </label>
                                 
  //                                   <input
  //                                     className="form-check-input"
  //                                     type="checkbox"
  //                                     name="report"
  //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
  //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
  //                                     style={{marginTop:"7px"}}
  //                                   />
                                  
  //                               </Col>
  //                             </Row>
  //                             {
  //                               this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true  &&
  //                                     <div className="d-flex gap-5 ">
  //                                       <div>
  //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
  //                                         <FormGroup className="mb-3">
  //                                           <input
  //                                             className="form-control mx-4  "
  //                                             type="time"
  //                                             onChange={(e) => { this.setTimeFrame(e) }}
  //                                             name="start_time"
  //                                             defaultValue="00:00:00"
  //                                             id="example-time-input"
  //                                           />
  //                                         </FormGroup>
  //                                       </div>
  //                                       <div className="mx-2">
  //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
  //                                         <FormGroup className="mb-3">
  //                                           <input
  //                                             className="form-control mx-4"
  //                                             type="time"
  //                                             name="end_time"
  //                                             defaultValue="23:59:00"
  //                                             onChange={(e) => { this.setTimeFrame(e) }}
  //                                             id="example-time-input"
  //                                           />
  //                                         </FormGroup>
  //                                       </div>
  //                                     </div>
  //                             }

  //                                   </Col>
  //                                 </Row>
  //                             </>
                              
  //                           }

  //                         </>
  //                       }
  //                     </Col>
  //                   </Row>


  //                 </React.Fragment>






  //               </div>
  //               // <div style={{ display: 'flex', flexDirection: 'Column' }}>

  //               //   <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
  //               //     {
  //               //       this.state.weekDays.map((item, idx) => {
  //               //         return (
  //               //           <div className="form-check mx-2 ">
  //               //             <input
  //               //               type="checkbox"
  //               //               className="form-check-input"
  //               //               id={item.day}
  //               //               checked={item.checked}
  //               //               onChange={e => { this.handleWeekDays(e) }}
  //               //             />
  //               //             <label
  //               //               className="form-check-label"
  //               //               htmlFor={item.day}
  //               //             >
  //               //               {item.day}
  //               //             </label>
  //               //           </div>
  //               //         )
  //               //       })
  //               //     }
  //               //   </div>



  //               // </div>
  //               :
  //                mode == "3" ?
  //                <div>
  //                <React.Fragment>
  //                  <Row>
  //                    <Col md={12}>
  //                      <div className="form-check mb-1">
  //                        <input
  //                          className="form-check-input mt-2 "
  //                          type="radio"
  //                          onChange={(e) => { this.endAfterMethod(e) }}
  //                          name="occurence"
  //                          id="occurence"
  //                          checked={this.state.selectedRadioOption === "occurence"}
  //                          defaultChecked
  //                        />
  //                        <label
  //                          className="form-check-label"
  //                          htmlFor="occurence"
  //                        >
  //                          No. of Occurences
  //                        </label>
  //                        <input type={"number"} max={12} min={0} onChange={(e) => { this.handleOccurance(e,mode) }} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>
  //                        {(this.state.occurence === undefined || this.state.occurence == "") && this.state.selectedRadioOption === "occurence" ?
  //                          <span className="text-danger" style={{ fontSize: "smaller" }}> Please Enter the No.of.days</span>
  //                          :
  //                          null}
  //                      </div>
  //                      {
  //                        this.state.selectedRadioOption === "occurence" && this.state.occurence !== undefined && this.state.occurence !== "" &&
  //                                                <>
  //                             <Row>
  //                               <Col md={12}>
  //                                 <div className="d-flex gap-3">
  //                                   <div>
  //                                     <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
  //                                     <FormGroup className="mb-3">
  //                                       <input
  //                                         className="form-control mx-4 "
  //                                         type="date"
  //                                         onChange={(e) => { this.scheduleStartDate(e, mode) }}
  //                                         name="start_date"
  //                                         min={this.state.fromate_date}
  //                                         value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
  //                                       />
  //                                     </FormGroup>
  //                                   </div>
  //                                 </div>
  //                               </Col>
  //                             </Row>
  //                             <Row>
  //                           <Col md={6}>
  //                               <Label className="text-primary mx-4 ">Allow to Take Audit<span className="text-danger"> *</span></Label>
  //                                 <div  className="mx-3">
  //                                   <div style={{ marginBottom: 10 }}>  
  //                                     {
  //                                       this.state.allowed_audit_monthy.map((data, index) => {
  //                                         return (
  //                                           <div key={index} className="form-check mx-2 mt-2 ">
  //                                             <input
  //                                               type="radio"
  //                                               className="form-check-input"
  //                                               id={data.mode}
  //                                               onChange={(e) => { this.allowedAuditForMonth(e, data, index) }}
  //                                               checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === data.id ? true : false : false}
  //                                             />
  //                                             <label
  //                                               className="form-check-label text-secondary"
  //                                               htmlFor={data.mode}
  //                                             >
  //                                               {data.mode}

  //                                             </label>
  //                                             {this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined &&
  //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null &&
  //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === data.id && (
  //                                                 data.id == "1" ?
  //                                                 <>
  //                                                 {
  //                                                   this.state.total_weeks.map((data,idx)=>{
  //                                                     return(
  //                                                       <div key={idx} className="form-check mx-2 mt-2">
  //                                                         <input type="radio" className="form-check-input" checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined ?this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week.mode_id === data.mode_id ? true : false : false : false} onChange={(e) => { this.appendDayofMonth(e, data) }} />
  //                                                         <label className="form-check-label text-secondary">{data.name}</label>
  //                                                         </div>
  //                                                     )
  //                                                   })
  //                                                 }
  //                                                 </>
  //                                                 :
  //                                                 data.id == "2"?
  //                                                 <>
  //                                                  <div className="form-check mx-1 mt-1">
  //                                                     <label
  //                                                       className="form-check-label text-primary mx-2"
  //                                                     >
  //                                                       Please Enter Specific Date of a month : <span className="text-danger">*</span>
  //                                                     </label>
  //                                                     <input type={"number"} max={30} min={1} name="specific_dates" value={Number(this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_dates)} onChange={(e) => { this.setState({ specific_dates: e.target.value }, () => { this.allowedAuditForMonth(e, undefined, "2") }) }} />
  //                                                     </div>
  //                                                   </>
  //                                                   :
  //                                                   data.id == "3"?
  //                                                   <>
  //                                                   <div className="form-check mx-1 mt-1">
  //                                                       <label
  //                                                         className="form-check-label text-primary mx-2"
  //                                                       >
  //                                                         Please check specific day of month : <span className="text-danger">*</span>
  //                                                       </label>
  //                                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                                           {
  //                                                             this.state.daysOfWeek.map((item, idx) => {
  //                                                               return (
  //                                                                 <div key={idx} className="form-check mx-2 mt-2 ">
  //                                                                   <input
  //                                                                     type="radio"
  //                                                                     className="form-check-input"
  //                                                                     id={item.day}
  //                                                                     name="specific_day"
  //                                                                     checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day.day_id === item.day_id ? true : false : false}
  //                                                                     // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
  //                                                                     onChange={e => { this.allowedAuditForMonth(e, undefined, "3", item) }}
  //                                                                   />
  //                                                                   <label
  //                                                                     className="form-check-label"
  //                                                                     htmlFor={item.day}
  //                                                                   >
  //                                                                     {item.day}
  //                                                                   </label>
  //                                                                 </div>
  //                                                               )
  //                                                             })
  //                                                           }
  //                                                         </div>
  //                                                       </div>
  //                                                       </div>
                                          
  //                                         </>
  //                                         :
  //                                                   null
  //                                               )}
  //                                           </div>
  //                                         )
  //                                       })
  //                                     }
  //                                   </div>
  //                                 </div>
  //                             </Col>
  //                             </Row>
  //                             {/* {
  //                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !==null && 
  //                             <Row>
  //                                     <Col md={6}>
  //                                       {
  //                                         this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === "2" ?
  //                                        <>
  //                                         <label
  //                                         className="form-check-label text-primary mx-4"
  //                                       >
  //                                         Please Enter Specific Date of a month : <span className="text-danger">*</span>
  //                                       </label>
  //                                           <input type={"number"} max={30} min={1} name="specific_dates" value={Number(this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_dates)} onChange={(e)=>{this.setState({specific_dates : e.target.value},()=>{this.allowedAuditForMonth(e,undefined,"2")})}}/>
  //                                       </>
  //                                         :
  //                                         this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === "3" ?
  //                                         <>
  //                                          <label
  //                                         className="form-check-label text-primary mx-4"
  //                                       >
  //                                         Please check specific day of month : <span className="text-danger">*</span>
  //                                       </label>
  //                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                   {
  //                                     this.state.daysOfWeek.map((item, idx) => {
  //                                       return (
  //                                         <div key={idx} className="form-check mx-2 mt-2 ">
  //                                           <input
  //                                             type="radio"
  //                                             className="form-check-input"
  //                                             id={item.day}
  //                                             name="specific_day"
  //                                             checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day.day_id === item.day_id ? true : false : false}
  //                                             onChange={e => { this.allowedAuditForMonth(e,undefined,"3",item) }}
  //                                           />
  //                                           <label
  //                                             className="form-check-label"
  //                                             htmlFor={item.day}
  //                                           >
  //                                             {item.day}
  //                                           </label>
  //                                         </div>
  //                                       )
  //                                     })
  //                                   }
  //                                 </div>
  //                               </div>
                                          
  //                                         </>
  //                                         :
  //                                         null
  //                                       }
                                      
  //                                     </Col>


  //                             </Row>
  //                             } */}
  //                             <Row>
  //                               <Col md={6}>
  //                             {
  //                                     this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !==undefined &&  this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null &&
  //                                     <div>
  //                                       <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
  //                                       <FormGroup className="mb-3">
  //                                         <input
  //                                           className="form-control mx-4 "
  //                                           type="date"
  //                                           name="end_date"
  //                                           // min={this.state.publishTemplate.repeat_mode_config.start_date !== null ? this.getMinDate() : this.state.fromate_date}
  //                                           onChange={(e) => { this.scheduleStartDate(e, mode) }}
  //                                           disabled={true}
  //                                           value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
  //                                         />
  //                                       </FormGroup>
  //                                     </div>
  //                                   }
  //                                   </Col>
  //                                   </Row>
                              
  //                                   <Row>
  //                               <Col md={5}>
  //                                 <label className="text-primary mx-4 my-1">
  //                                   Show Time Frame
  //                                 </label>
                                 
  //                                   <input
  //                                     className="form-check-input"
  //                                     type="checkbox"
  //                                     name="report"
  //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
  //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
  //                                     style={{marginTop:"7px"}}
  //                                   />
                                  
  //                               </Col>
  //                             </Row>

  //                        {
  //                         this.state.publishTemplate.repeat_mode_config.end_date !==null && this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true &&
  //                        <Row>
  //                        <Col md={12}>
  //                          <div className="d-flex gap-5 ">
  //                            <div>
  //                              <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
  //                              <FormGroup className="mb-3">
  //                                <input
  //                                  className="form-control mx-4  "
  //                                  type="time"
  //                                  onChange={(e) => { this.setTimeFrame(e) }}
  //                                  name="start_time"
  //                                  defaultValue="00:00:00"
  //                                  id="example-time-input"
  //                                />
  //                              </FormGroup>
  //                            </div>
  //                            <div className="mx-2">
  //                              <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
  //                              <FormGroup className="mb-3">
  //                                <input
  //                                  className="form-control mx-4"
  //                                  type="time"
  //                                  name="end_time"
  //                                  defaultValue="23:59:00"
  //                                  onChange={(e) => { this.setTimeFrame(e) }}
  //                                  id="example-time-input"
  //                                />
  //                              </FormGroup>
  //                            </div>
  //                          </div>
  //                        </Col>
  //                      </Row>
  //                        }

  //                      </>
  //                      }
  //                    </Col>
  //                  </Row>
  //                  <Row >
  //                      <Col md={6}>
  //                      </Col>
  //                      <Col>
  //                      </Col>

  //                  </Row>
  //                  <Row>
  //                    <Col md={12}>
  //                      <div className="form-check">
  //                        <input
  //                          className="form-check-input"
  //                          type="radio"
  //                          onChange={(e) => { this.endAfterMethod(e) }}
  //                          name="enddate"
  //                          id="enddate"
  //                          checked={this.state.selectedRadioOption === "enddate"}
  //                        />
  //                        <label
  //                          className="form-check-label"
  //                          htmlFor="enddate"
  //                        >
  //                          Month Range
  //                        </label>
  //                      </div>
  //                      {
  //                        this.state.selectedRadioOption === "enddate" &&
  //                        <>
  //                          <Row>
  //                            <Col md={12}>
  //                              <div className="d-flex gap-3">
  //                                <div>
  //                                  <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
  //                                  <FormGroup className="mb-3">
  //                                    <input
  //                                      className="form-control mx-4 "
  //                                      type="date"
  //                                      onChange={(e) => { this.scheduleStartDate(e,mode) }}
  //                                      name="start_date"
  //                                      min={this.state.fromate_date}
  //                                      value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
  //                                    />
  //                                  </FormGroup>
  //                                </div>
  //                                <div>
  //                                  <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
  //                                  <FormGroup className="mb-3">
  //                                    <input
  //                                      className="form-control mx-4 "
  //                                      type="date"
  //                                      name="end_date"
  //                                      min={this.state.publishTemplate.repeat_mode_config.start_date !== null ? this.getMinDate(mode) : this.state.fromate_date}
  //                                      onChange={(e) => { this.scheduleStartDate(e,mode) }}
  //                                      disabled={this.state.publishTemplate.repeat_mode_config.start_date === null}
  //                                      value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
  //                                    />
  //                                  </FormGroup>
  //                                </div>
  //                              </div>
  //                            </Col>
  //                          </Row>
  //                          {
  //                            this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.selectedRadioOption === "enddate" &&
  //                         <>
  //                            <Row>
  //                           <Col md={6}>
  //                               <Label className="text-primary mx-4 ">Allow to Take Audit<span className="text-danger"> *</span></Label>
  //                                 <div  className="mx-3">
  //                                   <div style={{ marginBottom: 10 }}>  
  //                                     {
  //                                       this.state.allowed_audit_monthy.map((data, index) => {
  //                                         return (
  //                                           <div key={index} className="form-check mx-2 mt-2 ">
  //                                             <input
  //                                               type="radio"
  //                                               className="form-check-input"
  //                                               id={data.mode}
  //                                               onChange={(e) => { this.allowedAuditForMonth(e, data, index) }}
  //                                               checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === data.id ? true : false : false}
  //                                             />
  //                                             <label
  //                                               className="form-check-label text-secondary"
  //                                               htmlFor={data.mode}
  //                                             >
  //                                               {data.mode}

  //                                             </label>
  //                                             {this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined &&
  //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null &&
  //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === data.id && (
  //                                                 data.id == "1" ?
  //                                                 <>
  //                                                 {
  //                                                   this.state.total_weeks.map((data,idx)=>{
  //                                                     return(
  //                                                       <div key={idx} className="form-check mx-2 mt-2">
  //                                                         <input type="radio" className="form-check-input" checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined ?this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week.mode_id === data.mode_id ? true : false : false : false} onChange={(e) => { this.appendDayofMonth(e, data) }} />
  //                                                         <label className="form-check-label text-secondary">{data.name}</label>
  //                                                         </div>
  //                                                     )
  //                                                   })
  //                                                 }
  //                                                 </>
  //                                                 :
  //                                                 data.id == "2"?
  //                                                 <>
  //                                                  <div className="form-check mx-1 mt-1">
  //                                                     <label
  //                                                       className="form-check-label text-primary mx-2"
  //                                                     >
  //                                                       Please Enter Specific Date of a month : <span className="text-danger">*</span>
  //                                                     </label>
  //                                                     <input type={"number"} max={30} min={1} name="specific_dates" value={Number(this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_dates)} onChange={(e) => { this.setState({ specific_dates: e.target.value }, () => { this.allowedAuditForMonth(e, undefined, "2") }) }} />
  //                                                     </div>
  //                                                   </>
  //                                                   :
  //                                                   data.id == "3"?
  //                                                   <>
  //                                                   <div className="form-check mx-1 mt-1">
  //                                                       <label
  //                                                         className="form-check-label text-primary mx-2"
  //                                                       >
  //                                                         Please check specific day of month : <span className="text-danger">*</span>
  //                                                       </label>
  //                                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                                           {
  //                                                             this.state.daysOfWeek.map((item, idx) => {
  //                                                               return (
  //                                                                 <div key={idx} className="form-check mx-2 mt-2 ">
  //                                                                   <input
  //                                                                     type="radio"
  //                                                                     className="form-check-input"
  //                                                                     id={item.day}
  //                                                                     name="specific_day"
  //                                                                     checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day.day_id === item.day_id ? true : false : false}
  //                                                                     // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
  //                                                                     onChange={e => { this.allowedAuditForMonth(e, undefined, "3", item) }}
  //                                                                   />
  //                                                                   <label
  //                                                                     className="form-check-label"
  //                                                                     htmlFor={item.day}
  //                                                                   >
  //                                                                     {item.day}
  //                                                                   </label>
  //                                                                 </div>
  //                                                               )
  //                                                             })
  //                                                           }
  //                                                         </div>
  //                                                       </div>
  //                                                       </div>
                                          
  //                                         </>
  //                                         :
  //                                                   null
  //                                               )}
  //                                           </div>
  //                                         )
  //                                       })
  //                                     }
  //                                   </div>
  //                                 </div>
  //                             </Col>
  //                             </Row>
  //                         {/* <Row>
  //                          <Col md={6}>
  //                              <Label className="text-primary mx-4 ">Allow to Take Audit</Label>
  //                              <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                  {
  //                                    this.state.publishTemplate.repeat_mode_config.days.map((item, idx) => {
  //                                      return (
  //                                        <div key={idx} className="form-check mx-2 mt-2 ">
  //                                          <input
  //                                            type="checkbox"
  //                                            className="form-check-input"
  //                                            id={item.day}
  //                                            // checked={item.checked}
  //                                            checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
  //                                            onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
  //                                          />
  //                                          <label
  //                                            className="form-check-label"
  //                                            // htmlFor={item.day}
  //                                          >
  //                                            {item.day}
  //                                          </label>
  //                                        </div>
  //                                      )
  //                                    })
  //                                  }
  //                                </div>
  //                              </div>
  //                            </Col>
  //                            </Row> */}
  //                            <Row>
  //                              <Col md={6}>
                              
  //                                    <div className="text-danger p-1 px-0" style={{ marginLeft: "2%", fontSize: '0.8125rem' }}> Number of occurences between the Start Date({new Date(this.state.publishTemplate.repeat_mode_config.start_date).toLocaleDateString('en-GB')}) and End Date ({new Date(this.state.publishTemplate.repeat_mode_config.end_date).toLocaleDateString('en-GB')}) is <span className="text-danger">{this.state.no_of_occurance} </span> occurences</div>
                                  
  //                              </Col>

  //                            </Row>
                          
  //                             {/* {
  //                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !==null && 
  //                             <Row>
  //                                     <Col md={6}>
  //                                       {
  //                                         this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === "2" ?
  //                                        <>
  //                                         <label
  //                                         className="form-check-label text-primary mx-4"
  //                                       >
  //                                         Please Enter Specific Date of a month : <span className="text-danger">*</span>
  //                                       </label>
  //                                           <input type={"number"} max={30} min={1} name="specific_dates" value={Number(this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_dates)} onChange={(e)=>{this.setState({specific_dates : e.target.value},()=>{this.allowedAuditForMonth(e,undefined,"2")})}}/>
  //                                       </>
  //                                         :
  //                                         this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === "3" ?
  //                                         <>
  //                                          <label
  //                                         className="form-check-label text-primary mx-4"
  //                                       >
  //                                         Please check specific day of month : <span className="text-danger">*</span>
  //                                       </label>
  //                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                   {
  //                                     this.state.daysOfWeek.map((item, idx) => {
  //                                       return (
  //                                         <div key={idx} className="form-check mx-2 mt-2 ">
  //                                           <input
  //                                             type="radio"
  //                                             className="form-check-input"
  //                                             id={item.day}
  //                                             name="specific_day"
  //                                             checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day.day_id === item.day_id ? true : false : false}
  //                                             // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
  //                                             onChange={e => { this.allowedAuditForMonth(e,undefined,"3",item) }}
  //                                           />
  //                                           <label
  //                                             className="form-check-label"
  //                                             htmlFor={item.day}
  //                                           >
  //                                             {item.day}
  //                                           </label>
  //                                         </div>
  //                                       )
  //                                     })
  //                                   }
  //                                 </div>
  //                               </div>
                                          
  //                                         </>
  //                                         :
  //                                         null
  //                                       }
                                      
  //                                     </Col>


  //                             </Row>
  //                             } */}
  //                              <Row>
  //                               <Col md={5}>
  //                                 <label className="text-primary mx-4 my-1">
  //                                   Show Time Frame
  //                                 </label>
                                 
  //                                   <input
  //                                     className="form-check-input"
  //                                     type="checkbox"
  //                                     name="report"
  //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
  //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
  //                                     style={{marginTop:"7px"}}
  //                                   />
                                  
  //                               </Col>
  //                             </Row>
  //                             {
  //                               this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true &&
  //                            <Row>
  //                                  <Col md={12}>
  //                                    <div className="d-flex gap-5 ">
  //                                      <div>
  //                                        <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
  //                                        <FormGroup className="mb-3">
  //                                          <input
  //                                            className="form-control mx-4  "
  //                                            type="time"
  //                                            onChange={(e) => { this.setTimeFrame(e) }}
  //                                            name="start_time"
  //                                            defaultValue="00:00:00"
  //                                            id="example-time-input"
  //                                          />
  //                                        </FormGroup>
  //                                      </div>
  //                                      <div className="mx-2">
  //                                        <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
  //                                        <FormGroup className="mb-3">
  //                                          <input
  //                                            className="form-control mx-4"
  //                                            type="time"
  //                                            name="end_time"
  //                                            defaultValue="23:59:00"
  //                                            onChange={(e) => { this.setTimeFrame(e) }}
  //                                            id="example-time-input"
  //                                          />
  //                                        </FormGroup>
  //                                      </div>
  //                                    </div>
  //                                  </Col>
  //                                </Row>
  //                             }

  //                            </>
                             
  //                          }

  //                        </>
  //                      }
  //                    </Col>
  //                  </Row>
  //                </React.Fragment>
  //              </div>           
  //                 : mode == "4" ?
  //                   // <div></div>
  //                   <>
  //                   <Row>
  //                     <Col md={6}>
  //                     <Label className="text-primary mx-3 ">Select calander type: <span className="text-danger">*</span></Label>

  //                         <select
  //                           defaultValue={this.state.publishTemplate.repeat_mode_config.calendar_type !== null && this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined ? this.state.publishTemplate.repeat_mode_config.calendar_type : "please select"}
  //                           onChange={(e) => { this.calendarType(e) }}
  //                         >
  //                           <option
  //                             disabled
  //                             value={"please select"}
  //                           >
  //                          please select
  //                             {/* {this.state.publishTemplate.repeat_mode_config.calendar_type !== null && this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined ? this.state.publishTemplate.repeat_mode_config.calendar_type : "please select"} */}
  //                           </option>
  //                           {this.state.calendar_type.map((data, idx) => {
  //                             return (
  //                               <option key={idx} value={data.mode_type}>{data.mode_type}</option>
  //                             );
  //                           })}
  //                         </select>
  //                     </Col>
  //                     </Row>                    
  //                   <Row>
  //                     <Col md={6}>
  //                     {
  //                       this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined &&this.state.publishTemplate.repeat_mode_config.calendar_type !== null &&
  //                       <>
  //                     <Label className="text-primary mx-3 ">Start Quarter</Label>
  //                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                           {
  //                             this.state.quater_periodicity.map((item, idx) => {
  //                               return (
  //                                 <div key={idx} className="form-check mx-2 mt-2 ">
  //                                   <input
  //                                     type="radio"
  //                                     className="form-check-input"
  //                                     id={item.name}
  //                                     // checked={item.checked}
  //                                     checked={this.state.publishTemplate.repeat_mode_config.selected_quater !== undefined && this.state.publishTemplate.repeat_mode_config.selected_quater !==null ?this.state.publishTemplate.repeat_mode_config.selected_quater.id === item.id ? true : false:false}
  //                                     onChange={e => { this.yearPeriodicity(e, item, idx, mode) }}
  //                                   />
  //                                   <label
  //                                     className="form-check-label"
  //                                     htmlFor={item.name}
  //                                   >
  //                                     {item.name}
  //                                   </label>
  //                                 </div>
  //                               )
  //                             })
  //                           }
  //                         </div>
  //                       </div>
  //                       </>
  //                     }

  //                     </Col>
  //                   </Row>
  //                   {
  //                     this.state.publishTemplate.repeat_mode_config.selected_quater !== undefined && this.state.publishTemplate.repeat_mode_config.selected_quater !== null &&this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined &&
  //                  <>
  //                  <Row>
  //                   <Col md={6}>
  //                     <Label className="text-primary mx-3 ">Quarter Start Months</Label>
  //                     <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                       <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                         {
  //                           this.state.selected_quater_months?.map((item, idx) => {
  //                             return (
  //                               <div key={idx} className="form-check mx-2 mt-2 ">
  //                                 <input
  //                                   type="radio"
  //                                   className="form-check-input"
  //                                   id={item.month_name}
  //                                   checked={this.state.publishTemplate.repeat_mode_config.selected_months !== undefined && this.state.publishTemplate.repeat_mode_config.selected_months !== null ? this.state.publishTemplate.repeat_mode_config.selected_months.month_id === item.month_id ? true : false : false  }
  //                                   onChange={(e)=>{this.periodicityMonths(e,item,idx,mode)}}
  //                                 />
  //                                 <label
  //                                   className="form-check-label"
  //                                   htmlFor={item.month_name}
  //                                 >
  //                                   {item.month_name}
  //                                 </label>
  //                               </div>
  //                             )
  //                           })
  //                         }
  //                       </div>
  //                     </div>
  //                   </Col>
  //                 </Row>
  //                 {/* <Row>
  //                   <Col md={3}>
  //                     <Label className="text-primary mx-3 ">Select Quarter</Label>
  //                 <select className="form-select">
  //                   <option>2020</option>
  //                   <option>2021</option>
  //                   <option>2023</option>
  //                   <option>2024</option>
  //                 </select>
  //                 </Col>
  //                 </Row> */}
  //                 </>
  //                   }
  //                 </>
  //                    : 
  //                    mode == "5" ?
  //                     // <div></div> 
  //                     <>
  //                      <Row>
  //                     <Col md={6}>
  //                     <Label className="text-primary mx-3 ">Select calander type: <span className="text-danger">*</span></Label>

  //                         <select
  //                           defaultValue={this.state.publishTemplate.repeat_mode_config.calendar_type !== null && this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined ? this.state.publishTemplate.repeat_mode_config.calendar_type : "please select"}
  //                           onChange={(e) => { this.calendarType(e) }}
  //                         >
  //                           <option
  //                             disabled
  //                             value={"please select"}
  //                           >
  //                             please select
  //                             {/* {this.state.publishTemplate.repeat_mode_config.calendar_type !== null && this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined ? this.state.publishTemplate.repeat_mode_config.calendar_type : "please select"} */}
  //                           </option>
  //                           {this.state.calendar_type.map((data, idx) => {
  //                             return (
  //                               <option key={idx} value={data.mode_type}>{data.mode_type}</option>
  //                             );
  //                           })}
  //                         </select>
  //                     </Col>
  //                     </Row> 
  //                     <Row>
  //                       <Col md={6}>
  //                         {
  //                            this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined &&this.state.publishTemplate.repeat_mode_config.calendar_type !== null &&
  //                        <>
  //                               <Label className="text-primary mx-3 ">Start Half-yearly</Label>
  //                               <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                   {
  //                                     this.state.periodicity_half_yearly.map((item, idx) => {
  //                                       return (
  //                                         <div key={idx} className="form-check mx-2 mt-2 ">
  //                                           <input
  //                                             type="radio"
  //                                             className="form-check-input"
  //                                             id={item.name}
  //                                             // checked={item.checked}
  //                                             checked={this.state.publishTemplate.repeat_mode_config.selected_half_yearly !== undefined && this.state.publishTemplate.repeat_mode_config.selected_half_yearly !== null ? this.state.publishTemplate.repeat_mode_config.selected_half_yearly.id === item.id ? true : false : false}
  //                                             onChange={e => { this.yearPeriodicity(e, item, idx, mode) }}
  //                                           />
  //                                           <label
  //                                             className="form-check-label"
  //                                             htmlFor={item.name}
  //                                           >
  //                                             {item.name}
  //                                           </label>
  //                                         </div>
  //                                       )
  //                                     })
  //                                   }
  //                                 </div>
  //                               </div>
  //                         </>
  //                         }

  //                       </Col>
  //                     </Row>
  //                     {
  //                       this.state.publishTemplate.repeat_mode_config.selected_half_yearly !== undefined && this.state.publishTemplate.repeat_mode_config.selected_half_yearly !== null &&
  //                    <>
  //                    <Row>
  //                     <Col md={6}>
  //                       <Label className="text-primary mx-3 ">Half-Yearly Start Months</Label>
  //                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                           {
  //                             this.state.selected_half_months.map((item, idx) => {
  //                               return (
  //                                 <div key={idx} className="form-check mx-2 mt-2 ">
  //                                   <input
  //                                     type="radio"
  //                                     className="form-check-input"
  //                                     id={item.month_name}
  //                                      checked={this.state.publishTemplate.repeat_mode_config.selected_months !== undefined && this.state.publishTemplate.repeat_mode_config.selected_months !== null ? this.state.publishTemplate.repeat_mode_config.selected_months.month_id === item.month_id ? true : false : false  }
  //                                     onChange={(e)=>{this.periodicityMonths(e,item,idx,mode)}}
  //                                     // checked={item.checked}
  //                                     // checked={this.state.publishTemplate.repeat_mode_config.selected_quater.id === item.id ? true : false}
  //                                     // onChange={e => { this.yearPeriodicity(e, item, idx, mode) }}
  //                                   />
  //                                   <label
  //                                     className="form-check-label"
  //                                     htmlFor={item.month_name}
  //                                   >
  //                                     {item.month_name}
  //                                   </label>
  //                                 </div>
  //                               )
  //                             })
  //                           }
  //                         </div>
  //                       </div>
  //                     </Col>
  //                   </Row>
  //                   {/* <Row>
  //                     <Col md={3}>
  //                       <Label className="text-primary mx-3 ">Select Half-Yearly</Label>
  //                   <select className="form-select">
  //                     <option>2020</option>
  //                     <option>2021</option>
  //                     <option>2023</option>
  //                     <option>2024</option>
  //                   </select>
  //                   </Col>
  //                   </Row> */}
  //                   </>
  //                     }
  //                   </>
  //                     :
  //                     mode =="6"?
  //                     <>  
  //                         <div>
  //                           <React.Fragment>
  //                             <Row>
  //                               <Col md={12}>
  //                                 <div className="form-check mb-1">
  //                                   {/* <input
  //                                     className="form-check-input mt-2 "
  //                                     type="radio"
  //                                     onChange={(e) => { this.endAfterMethod(e) }}
  //                                     name="occurence"
  //                                     id="occurence"
  //                                     checked={this.state.selectedRadioOption === "occurence"}
  //                                     defaultChecked
  //                                   /> */}
  //                                   <label
  //                                     className="form-check-label"
  //                                     htmlFor="occurence"
  //                                   >
  //                                     No. of Occurences
  //                                   </label>
  //                                   <input type={"number"} max={3} min={0} onChange={(e) => { this.handleOccurance(e, mode) }} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>
  //                                   {(this.state.occurence === undefined || this.state.occurence == "") && this.state.selectedRadioOption === "occurence" ?
  //                                     <span className="text-danger" style={{ fontSize: "smaller" }}> Please Enter the No.of.days</span>
  //                                     :
  //                                     null}
  //                                 </div>
  //                                 {/* <Row>
  //                                   <Col md={6}>
  //                                       <Label>Select Year </Label>
  //                                       <select >
  //                                         <option>2023</option>
  //                                         <option>2024</option>
  //                                       </select>
  //                                   </Col>
  //                                 </Row> */}
  //                                    {
  //                                   this.state.occurence !== null && this.state.occurence !== undefined && this.state.occurence !== "" &&

  //                                   <Row>
  //                                     <Col md={12}>
  //                                       <div className="d-flex gap-3">
  //                                         <div>
  //                                           <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
  //                                           <FormGroup className="mb-3">
  //                                             <input
  //                                               className="form-control mx-4 "
  //                                               type="date"
  //                                               onChange={(e) => { this.scheduleStartDate(e, mode) }}
  //                                               min={this.state.fromate_date}
  //                                               value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
  //                                             />
  //                                           </FormGroup>
  //                                         </div>
  //                                         <div>
  //                                           {
  //                                             this.state.publishTemplate.repeat_mode_config.end_date !== null &&
  //                                             <>
  //                                               <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date</Label>
  //                                               <FormGroup className="mb-3">
  //                                                 <input
  //                                                   className="form-control mx-4 "
  //                                                   type="date"
  //                                                   value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
  //                                                   disabled={true}
  //                                                 />
  //                                               </FormGroup>
  //                                             </>
  //                                           }

  //                                 </div>
  //                               </div>
  //                             </Col>

  //                           </Row>
  //                         }
  //                         {
  //                           this.state.publishTemplate.repeat_mode_config.start_date !== null &&
  //                                 <Row>
  //                           <Col md={6}>
  //                               <Label className="text-primary mx-4 ">Allow to Take Audit<span className="text-danger"> *</span></Label>
  //                                 <div  className="mx-3">
  //                                   <div style={{ marginBottom: 10 }}>  
  //                                     {
  //                                       this.state.allowed_audit_yearly.map((data, index) => {
  //                                         return (
  //                                           <>
  //                                           <div key={index} className="form-check mx-2 mt-2 ">
  //                                             <input
  //                                               type="radio"
  //                                               className="form-check-input"
  //                                               id={data.mode}
  //                                               onChange={(e) => { this.allowedAuditForYearly(e, data, index) }}
  //                                                checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== null ? this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly.id === data.id ? true : false : false}
  //                                             />
  //                                             <label
  //                                               className="form-check-label text-secondary"
  //                                               htmlFor={data.mode}
  //                                             >
  //                                               {data.mode}

  //                                             </label>
  //                                             </div>
  //                                             {this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== undefined &&
  //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== null &&
  //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly.id === data.id && (
  //                                                 <>
  //                                                 {
  //                                                   data.id == "1"?
  //                                                   <>
  //                                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                                           {
  //                                                             this.state.total_months.map((ele, idx) => {
  //                                                               return (
  //                                                                 <div key={idx} className="form-check mx-2 mt-2">
  //                                                                   <input type="checkbox" className="form-check-input"
  //                                                                   // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== undefined ?this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly.position_of_the_week !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week.mode_id === data.mode_id ? true : false : false : false}
  //                                                                   //  onChange={(e) => { this.appendDayofMonth(e, data) }}
  //                                                                   />
  //                                                                   <label className="form-check-label text-secondary">{ele.name}</label>
  //                                                                 </div>
  //                                                               )
  //                                                             })
  //                                                           }
  //                                                         </div>
  //                                                       </div>
  //                                                     </>

  //                                                   :
  //                                                     data.id == "2" ?
  //                                                       <>
  //                                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
  //                                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                                                       {
  //                                                         this.state.daysOfWeek.map((ele,idx)=>{
  //                                                           return(
  //                                                             <div key={idx} className="form-check mx-2 mt-2">
  //                                                             <input type="radio" className="form-check-input"
  //                                                             // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== undefined ?this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly.position_of_the_week !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week.mode_id === data.mode_id ? true : false : false : false}
  //                                                             //  onChange={(e) => { this.appendDayofMonth(e, data) }}
  //                                                             />
  //                                                             <label className="form-check-label text-secondary">{ele.day}</label>
  //                                                           </div>


  //                                                           )
  //                                                         })
  //                                                       }
  //                                                     </div>
  //                                                         </div>

  //                                                       </>

  //                                                   :
  //                                                   null
  //                                                 }

  //                                                 </>
  //                                               )}
  //                                             </>
  //                                         )
  //                                               })
  //                                       // })
  //                                     }
  //                                     </div>
  //                                     </div>
  //                                     </Col>
  //                                     </Row>
  //                         }

  //                                 {/* {
  //                                   this.state.occurence !== null && this.state.occurence !== undefined && this.state.occurence !== "" &&

  //                                   <Row>
  //                                     <Col md={12}>
  //                                       <div className="d-flex gap-3">
  //                                         <div>
  //                                           <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
  //                                           <FormGroup className="mb-3">
  //                                             <input
  //                                               className="form-control mx-4 "
  //                                               type="date"
  //                                               onChange={(e) => { this.scheduleStartDate(e, mode) }}
  //                                               min={this.state.fromate_date}
  //                                               value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
  //                                             />
  //                                           </FormGroup>
  //                                         </div>
  //                                         <div>
  //                                           {
  //                                             this.state.publishTemplate.repeat_mode_config.end_date !== null &&
  //                                             <>
  //                                               <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date</Label>
  //                                               <FormGroup className="mb-3">
  //                                                 <input
  //                                                   className="form-control mx-4 "
  //                                                   type="date"
  //                                                   value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
  //                                                   disabled={true}
  //                                                 />
  //                                               </FormGroup>
  //                                             </>
  //                                           }

  //                                 </div>
  //                               </div>
  //                             </Col>

  //                           </Row>
  //                         } */}
  //                                 {/* {
  //                                   this.state.occurence !==undefined && this.state.occurence !=="" &&
  //                                   <>
  //                                   <Label className="text-primary mx-3 ">Select Year</Label>
  //                                   <select>
  //                                     <option>
  //                                       2023
  //                                     </option>
  //                                     <option>
  //                                       2024
  //                                     </option>
  //                                     <option>
  //                                       2025
  //                                     </option>
  //                                   </select>
                                    
  //                                   </>
  //                                 } */}
  //                               </Col>
  //                             </Row>
  //                           </React.Fragment>
  //                         </div>

  //                     </>

  //                     :
  //                     null

  //       }

  //     </Form>
  //   )
  // }



  // repeatModeComponent(mode) {
  //   // console.log(mode,'mode')

  //   // var endDate = this.calculateEndDate()
  //   // console.log(endDate,'endDate')

  //   return (
  //     <Form action="#">

  //       {
  //         mode == "0" ?

  //           <div>
  //             {/* <div className="mb-3 d-flex flex-column  ">
  //               <div className="d-flex flex-row align-items-center">
  //                 <div className="col-3">
  //                   <Label className="text-primary" htmlFor="autoSizingSelect">Start date</Label>
  //                   <div className="col-md-10">
  //                     <input
  //                       className="form-control"
  //                       type="date"
  //                       min={this.state.fromate_date}
  //                       selected={this.state.ptl_startdate}
  //                       defaultValue={this.state.ptl_startdate}
  //                       id="start_date"
  //                       onChange={event => this.onChangeStartDate(event)}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-3">
  //                   <Label className="text-primary" htmlFor="autoSizingSelect">End date</Label>
  //                   <div className="col-md-10">
  //                     <input
  //                       className="form-control"
  //                       type="date"
  //                       min={this.state.fromate_date}
  //                       selected={this.state.ptl_enddate}
  //                       defaultValue={this.state.ptl_enddate}
  //                       id="end_date"
  //                       onChange={event => this.onChangeEndDate(event)}
  //                     />
  //                   </div>
  //                 </div>

  //               </div>
  //             </div> */}
  //           </div> : mode == "1" ?

  //             <div>
  //               <Row >
  //                   {
  //                     // this.state.publishTemplate.repeat_mode_config.start_date !== null &&
  //                     // console.log("shall go nxt step") 
  //                     <React.Fragment>
  //                       <Row>
  //                         <Col md={12}>
  //                           {/* <h5 className="font-size-14 mb-4 text-primary">End After</h5> */}
  //                           <div className="form-check mb-1">
  //                             <input
  //                               className="form-check-input mt-2 "
  //                               type="radio"
  //                               onChange={(e) => { this.endAfterMethod(e) }}
  //                               name="occurence"
  //                               id="occurence"
  //                               checked={this.state.selectedRadioOption === "occurence"}
  //                               defaultChecked
  //                             />
  //                             <label
  //                               className="form-check-label"
  //                               htmlFor="occurence"
  //                             >
  //                               No. of Occurences
  //                             </label>
  //                             <input type={"number"} max={30} min={0} onChange={(e)=>{this.handleOccurance(e)}} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>

  //                             {/* <input type={"number"} max={30} min={0} onChange={(e)=>{this.setState({occurence : e.target.value ,show_occurence_valid : false},()=>{this.calculateEndDate();}) }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} />  */}
  //                             {(this.state.occurence === undefined || this.state.occurence =="") && this.state.selectedRadioOption === "occurence"?
  //                             <span className="text-danger" style={{fontSize:"smaller"}}> Please Enter the No.of.days</span>
  //                             :
  //                             null}
  //                           </div>
  //                           {
  //                             this.state.selectedRadioOption === "occurence" &&

  //                           <Row>
  //                               <Col md={12}>
  //                                 <div className="d-flex gap-3">
  //                                   <div>
  //                                     <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
  //                                     <FormGroup className="mb-3">
  //                                       <input
  //                                         className="form-control mx-4 "
  //                                         type="date"
  //                                         onChange={(e) => { this.scheduleStartDate(e) }}
  //                                         min={this.state.fromate_date}
  //                                       />
  //                                     </FormGroup>
  //                                   </div>
  //                                   <div>
  //                                     {
  //                                       this.state.publishTemplate.repeat_mode_config.start_date !== null &&
  //                                       <>
  //                                         <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date</Label>
  //                                         <FormGroup className="mb-3">
  //                                           <input
  //                                             className="form-control mx-4 "
  //                                             type="date"
  //                                             // value={endDate == null ? "" :endDate}
  //                                             value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" :this.state.publishTemplate.repeat_mode_config.end_date}

  //                                             disabled={true}

  //                                           />
  //                                         </FormGroup>
  //                                       </>
  //                                     }

  //                                   </div>
  //                                 </div>
  //                               </Col>
                            
  //                             </Row>
  //                           }
                         

  //                         </Col>
  //                       </Row>
  //                     <Row>
  //                         {
  //                           this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.selectedRadioOption === "occurence" &&
  //                         <Col md={12}>

  //                           <Label className="text-primary mx-4 " htmlFor="autoSizingSelect">Exclude Days</Label>

  //                           <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

  //                             <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                               {
  //                                 this.state.weekDays.map((item, idx) => {
  //                                   return (
  //                                     <div key={idx} className="form-check mx-2 mt-2 ">
  //                                       <input
  //                                         type="checkbox"
  //                                         className="form-check-input"
  //                                         id={item.day}
  //                                         checked={item.checked}
  //                                         // onChange={e => { this.handleWeekDays(e) }}
  //                                         onChange={e => { this.excludeWeeks(e,item,idx) }}

  //                                       />
  //                                       <label
  //                                         className="form-check-label"
  //                                         htmlFor={item.day}
  //                                       >
  //                                         {item.day}
  //                                       </label>
  //                                     </div>
  //                                   )
  //                                 })
  //                               }
  //                             </div>
  //                           </div>
  //                         </Col>
  //                         }                     
  //                     </Row>
  //                     <Row>
  //                      <Col md={6}>

  //                     {
  //                         this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.selectedRadioOption === "occurence" &&
  //                       <>
  //                            <Row>
  //                               <Col md={12}>
  //                                 <div className="d-flex gap-5 ">
  //                                   <div>
  //                                     <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
  //                                     <FormGroup className="mb-3">
  //                                       <input
  //                                         className="form-control mx-4  "
  //                                         type="time"
  //                                         onChange={(e)=>{this.setTimeFrame(e)}}
  //                                         name="start_time"
  //                                         defaultValue="00:00:00"
  //                                         id="example-time-input"
  //                                       />
  //                                     </FormGroup>
  //                                   </div>
  //                                   <div className="mx-2">
  //                                     {
  //                                       this.state.publishTemplate.repeat_mode_config.start_date !== null &&
  //                                       <>
  //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
  //                                         <FormGroup className="mb-3">
  //                                           <input
  //                                             className="form-control mx-4"
  //                                             type="time"
  //                                             name="end_time"
  //                                             defaultValue="23:59:00"
  //                                             onChange={(e)=>{this.setTimeFrame(e)}}
  //                                             id="example-time-input"
  //                                           />
  //                                         </FormGroup>
  //                                       </>
  //                                     }
  //                                   </div>
  //                                 </div>
  //                               </Col>
  //                             </Row>
  //                        </>
  //                       }
  //                       </Col>

  //                     </Row>
  //                     <Row>
  //                       <Col md={12}>
  //                         <div className="form-check">
  //                           <input
  //                             className="form-check-input"
  //                             type="radio"
  //                             onChange={(e) => { this.endAfterMethod(e) }}
  //                             name="enddate"
  //                             id="enddate"
  //                             checked={this.state.selectedRadioOption === "enddate"}
  //                           />
  //                           <label
  //                             className="form-check-label"
  //                             htmlFor="enddate"
  //                           >
  //                             Date Range
  //                           </label>
  //                         </div>
  //                         {
  //                             this.state.selectedRadioOption === "enddate" &&
  //                             <>
  //                             <Row>
  //                             <Col md={12}>
  //                               <div className="d-flex gap-3">
  //                                 <div>
  //                                   <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
  //                                   <FormGroup className="mb-3">
  //                                     <input
  //                                       className="form-control mx-4 "
  //                                       type="date"
  //                                       onChange={(e) => { this.scheduleStartDate(e) }}
  //                                       name="start_date"
  //                                       min={this.state.fromate_date}
  //                                     />
  //                                   </FormGroup>
  //                                 </div>
  //                                 <div>
  //                                       <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
  //                                       <FormGroup className="mb-3">
  //                                         <input
  //                                           className="form-control mx-4 "
  //                                           type="date"
  //                                           name ="end_date"
  //                                           min={this.state.publishTemplate.repeat_mode_config.start_date !== null ?this.state.publishTemplate.repeat_mode_config.start_date : this.state.fromate_date}
  //                                           onChange={(e) => { this.scheduleStartDate(e) }}
  //                                           disabled={this.state.publishTemplate.repeat_mode_config.start_date === null}
  //                                         />
  //                                       </FormGroup>
  //                                 </div>
  //                               </div>
  //                             </Col>
  //                           </Row>

  //                           <Row>
  //                             <Col md={12}>
  //                               {
  //                                 this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null ?
  //                                <div className="text-danger" style={{marginLeft :"2%" , fontSize :'smaller'}}> Number of occurences between the Start Date({this.state.publishTemplate.repeat_mode_config.start_date}) and End Date ({this.state.publishTemplate.repeat_mode_config.end_date}) is <span className="text-danger">{this.state.no_of_occurance} </span> occurences</div>
  //                                 :
  //                                 null
  //                               }
                              
  //                             </Col>                            
  //                           </Row>
  //                           <Row>
  //                         {
  //                           this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null &&
  //                         <Col md={12}>

  //                           <Label className="text-primary mx-4 " htmlFor="autoSizingSelect">Exclude Days</Label>

  //                           <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

  //                             <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
  //                               {
  //                                 this.state.weekDays.map((item, idx) => {
  //                                   return (
  //                                     <div key={idx} className="form-check mx-2 mt-2 ">
  //                                       <input
  //                                         type="checkbox"
  //                                         className="form-check-input"
  //                                         id={item.day}
  //                                         checked={item.checked}
  //                                         // onChange={e => { this.handleWeekDays(e) }}
  //                                         onChange={e => { this.excludeWeeks(e,item,idx) }}

  //                                       />
  //                                       <label
  //                                         className="form-check-label"
  //                                         htmlFor={item.day}
  //                                       >
  //                                         {item.day}
  //                                       </label>
  //                                     </div>
  //                                   )
  //                                 })
  //                               }
  //                             </div>
  //                           </div>
  //                         </Col>
  //                         }                     
  //                     </Row>
  // </>

  //                           }
  //                    <Row>
  //                      <Col md={6}>

  //                     {
  //                         this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.selectedRadioOption === "enddate" &&
  //                       <>
  //                            <Row>
  //                               <Col md={12}>
  //                                 <div className="d-flex gap-5 ">
  //                                   <div>
  //                                     <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
  //                                     <FormGroup className="mb-3">
  //                                       <input
  //                                         className="form-control mx-4  "
  //                                         type="time"
  //                                         onChange={(e)=>{this.setTimeFrame(e)}}
  //                                         name="start_time"
  //                                         defaultValue="00:00:00"
  //                                         id="example-time-input"
  //                                       />
  //                                     </FormGroup>
  //                                   </div>
  //                                   <div className="mx-2">
  //                                     {/* {
  //                                       this.state.publishTemplate.repeat_mode_config.start_date !== null && */}
  //                                       {/* <> */}
  //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
  //                                         <FormGroup className="mb-3">
  //                                           <input
  //                                             className="form-control mx-4"
  //                                             type="time"
  //                                             name="end_time"
  //                                             defaultValue="23:59:00"
  //                                             onChange={(e)=>{this.setTimeFrame(e)}}
  //                                             id="example-time-input"
  //                                           />
  //                                         </FormGroup>
  //                                       {/* </> */}
  //                                     {/* } */}
  //                                   </div>
  //                                 </div>
  //                               </Col>
  //                             </Row>
  //                        </>
  //                       }
  //                       </Col>

  //                     </Row>






  //                       </Col>

  //                     </Row>
  //                     </React.Fragment>

  //                     // <div className="mt-4">
  //                     //   <h5 className="font-size-14 mb-4 text-primary">End After</h5>
  //                     //   <div className="form-check mb-3">
  //                     //     <input
  //                     //       className="form-check-input"
  //                     //       type="radio"
  //                     //       onChange={(e)=>{this.endAfterMethod(e)}}
  //                     //       name="occurence"
  //                     //       id="occurence"
  //                     //       checked={this.state.selectedRadioOption === "occurence"}
  //                     //       defaultChecked
  //                     //     />
  //                     //     <label
  //                     //       className="form-check-label"
  //                     //       htmlFor="occurence"
  //                     //     >
  //                     //      No. of Occurences
  //                     //     </label>
  //                     //     <input  type={"number"} style={{border:'1px solid #d3cfcf' ,padding :"4px", margin:"0 5px", borderRadius : "6px" , width : '38px'}}/>
  //                     //   </div>
  //                     //   <div className="form-check">
  //                     //     <input
  //                     //       className="form-check-input"
  //                     //       type="radio"
  //                     //       onChange={(e)=>{this.endAfterMethod(e)}}
  //                     //       name="enddate"
  //                     //       id="enddate"
  //                     //       checked={this.state.selectedRadioOption === "enddate"}
  //                     //     />
  //                     //     <label
  //                     //       className="form-check-label"
  //                     //       htmlFor="enddate"
  //                     //     >
  //                     //      Date Range
  //                     //     </label>
  //                     //   </div>
  //                     // </div>


  //                   }
  //                 {/* <FormGroup className="mb-3 col-3">
  //                   <Label className="text-primary" htmlFor="autoSizingSelect">End Time</Label>
  //                   <InputGroup>
  //                     <Flatpickr
  //                       className="form-control d-block"
  //                       placeholder="Select time"
  //                       options={{
  //                         minTime: this.state.getMinTime,
  //                         enableTime: true,
  //                         noCalendar: true,
  //                         dateFormat: "H:i K"
  //                       }}
  //                       value={this.state.daily_endtime}
  //                       onChange={e => this.onChangeEndTime(e)}
  //                     />
  //                     <div className="input-group-append">
  //                       <span className="input-group-text">
  //                         <i className="mdi mdi-clock-outline" />
  //                       </span>
  //                     </div>
  //                   </InputGroup>
  //                 </FormGroup> */}
  //               </Row>


  //             </div> : mode == "2" ?
  //               <div style={{ display: 'flex', flexDirection: 'Column' }}>

  //                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
  //                   {
  //                     this.state.weekDays.map((item, idx) => {
  //                       return (
  //                         <div key={idx} className="form-check mx-2 ">
  //                           <input
  //                             type="checkbox"
  //                             className="form-check-input"
  //                             id={item.day}
  //                             checked={item.checked}
  //                             onChange={e => { this.handleWeekDays(e) }}
  //                           />
  //                           <label
  //                             className="form-check-label"
  //                             htmlFor={item.day}
  //                           >
  //                             {item.day}
  //                           </label>
  //                         </div>
  //                       )
  //                     })
  //                   }
  //                 </div>



  //               </div> : mode == "3" ?
  //                 <div>
  //                   <Row>

  //                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 30 }}>
  //                       <span style={{ fontSize: 13, color: '#74788d' }}>Every month started on &nbsp;&nbsp;</span>
  //                       <div className="me-2">
  //                         <Label className="card-radio-label">
  //                           <Input
  //                             type="radio"
  //                             name="month_start"
  //                             id="ms-1"
  //                             className="card-radio-input"
  //                             value={this.state.mstartoption}
  //                             checked={this.state.mstartoption === "month_start"}
  //                             onChange={this.handleMStartGroupChange}
  //                           />

  //                           <div className="card-radio py-4">
  //                             <span style={{
  //                               color: this.state.mstartoption !== "month_start" || this.state.mstartoption === undefined ? "#c0c1c5" : "#74788d"
  //                             }}>Month 1st</span>
  //                           </div>
  //                         </Label>
  //                       </div>
  //                       <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>
  //                       <div className="me-2">
  //                         <Label className="card-radio-label">
  //                           <Input
  //                             type="radio"
  //                             name="custom_start"
  //                             id="ms-2"
  //                             className="card-radio-input"
  //                             value={this.state.mstartoption}
  //                             checked={this.state.mstartoption === "custom_start"}
  //                             onChange={this.handleMStartGroupChange}

  //                           />

  //                           <div className="card-radio d-flex flex-row align-items-center">
  //                             <select
  //                               type="select"
  //                               name="m_starton"
  //                               label="Name"
  //                               value={this.state.mstartoption === "month_start" || this.state.mstartoption === null ? 'choose' : this.state.dsf}
  //                               className="form-select"
  //                               style={{ width: 60 }}
  //                               id="m_starton"
  //                               disabled={this.state.mstartoption === "month_start" || this.state.mstartoption === null}
  //                               onChange={(e) => this.monthStartOn(e)}>
  //                               <option value="choose" disabled >Choose...</option>
  //                               {
  //                                 this.state.dayStartNumber.map((data, idx) => {
  //                                   return (
  //                                     <option value={data.value} selected key={idx}>{data.name}</option>
  //                                   )
  //                                 })
  //                               }
  //                             </select>
  //                             {this.state.mstartoption === "month_start" || this.state.mstartoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.dsf)}</span>}
  //                           </div>
  //                         </Label>
  //                       </div>
  //                       <span>&nbsp;and ends on &nbsp;&nbsp;</span>
  //                       <div className="me-2">
  //                         <Label className="card-radio-label">
  //                           <Input
  //                             type="radio"
  //                             name="month_end"
  //                             id="pay-methodoption1"
  //                             className="card-radio-input"
  //                             value={this.state.mendoption}
  //                             checked={this.state.mendoption === "month_end"}
  //                             onChange={this.handleMEndGroupChange}
  //                           />

  //                           <div className="card-radio py-4">
  //                             <span style={{
  //                               color: this.state.mendoption !== "month_end" || this.state.mendoption === null ? "#c0c1c5" : "#74788d"
  //                             }}>Month end</span>
  //                           </div>
  //                         </Label>
  //                       </div>
  //                       <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>
  //                       <div className="me-2">
  //                         <Label className="card-radio-label">
  //                           <Input
  //                             type="radio"
  //                             name="custom_end"
  //                             id="pay-methodoption3"
  //                             className="card-radio-input"
  //                             value={this.state.mendoption}
  //                             checked={this.state.mendoption === "custom_end"}
  //                             onChange={this.handleMEndGroupChange}

  //                           />

  //                           <div className="card-radio d-flex flex-row align-items-center">
  //                             <select
  //                               type="select"
  //                               name="m_endson"
  //                               label="Name"
  //                               value={this.state.mendoption === "month_end" || this.state.mendoption === null ? 'choose' : this.state.eoem}
  //                               className="form-select"
  //                               style={{ width: 60 }}
  //                               id="m_endson"
  //                               disabled={this.state.mendoption === "month_end" || this.state.mendoption === null}
  //                               onChange={(e) => this.monthEndsOn(e)}>
  //                               <option value="choose" disabled >Choose...</option>
  //                               {
  //                                 this.state.dayEndNumber.map((data, idx) => {
  //                                   return (
  //                                     <option value={data.value} selected key={idx}>{data.name}</option>
  //                                   )
  //                                 })
  //                               }
  //                             </select>
  //                             {this.state.mendoption === "month_end" || this.state.mendoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.eoem)}</span>}
  //                           </div>
  //                         </Label>
  //                       </div>
  //                     </div>
  //                   </Row>

  //                 </div> : mode == "4" ?
  //                   <div></div> : mode == "5" ?
  //                     <div></div> :
  //                     null

  //       }

  //     </Form>
  //   )
  // }

  onCheck = (checked, event, mode) => {
    //.log('checked, event', checked, event, mode, event.node)
    console.log( checked, event, mode, event.node)
    var publishTemplate ={...this.state.publishTemplate}

    if (mode == "2") {
      var findIdx = _.findIndex(publishTemplate.endpoints, { _id: event.node.node_id })
      //.log(findIdx, 'findIdx',this.state.publishTemplate.endpoints)
      if (event.checked == true) {
        var endPointArray = {
          adt_users: [],
          code: event.node.code,
          designation: "",
          h_id: publishTemplate.hlevel_id,
          hlevel: event.node.ep_level,
          hlevel_name: event.node.label,
          hlevel_value: event.node.ep_level_value,
          user_path: event.node.user_path,
          unique_users: event.node.unique_users,
          user_permission_acpln : event.node.user_permission_acpln,
          _id: event.node.node_id
        }
        var assign_auditUser = _.filter(event.node.user_path,{cat_type :"1"})
        if(assign_auditUser.length >0 && assign_auditUser.length <2){
          assign_auditUser[0]["audit_type"] = "1"
          endPointArray["rowValid"] = true
          // this.state.publishTemplate.endpoints
          // assign_auditUser[0]["rowValid"] = true
          endPointArray.adt_users.push(assign_auditUser[0])
        }
        // var assign_auditUser = _.filter(this.state.publishTemplate.endpoints)
        publishTemplate.endpoints.push(endPointArray)
        publishTemplate["audit_userlevel"]="1"
        publishTemplate["audit_userlevel_value"]=1
        publishTemplate["eplevel_value"]=event.node.ep_level_value
        // this.state.publishTemplate["enable_review"] = false
        // this.updatePublishTemplateData()

      }
      else {
        // this.state.publishTemplate["audit_userlevel"]="1"
        // this.state.publishTemplate["audit_userlevel_value"]=1
        publishTemplate["enable_review"] = false
        publishTemplate.endpoints.splice(findIdx, 1)
      }
      this.setState({
        enableReview: false,
        publishTemplate
      }, () => { this.handleEndpointSelection(checked.checked, event) })
      
    }
    else {
      publishTemplate["audit_userlevel"]="1"
      publishTemplate["audit_userlevel_value"]=1
      this.setState({
        publishTemplate
      },()=>{
        this.handleEndpointSelection(checked, event)
      })
      
    }

  };

  buildTreeNodes = (nodes) => {
    const { checkedKeys } = this.state;
    if(this.state.publishTemplate.hirearchy_type === "2"){
      console.log(nodes,'nodes')
    return nodes.map((node) => {
      const { value, label, children, ep_level,code, ep_level_value, id, node_id, parent, user_path,unique_users ,user_permission_acpln} = node;
      const hasChildren =  (children && children.length > 0) ;
      // console.log(nodes,'nodes')
      console.log(hasChildren,'hasChildren')
      // //.log(node,'node')
      //  console.log(hasChildren,'hasChildren',node ,children,this.state.publishTemplate)
      return {
        key: value,
        label: label,
        user_path: user_path,
        unique_users:unique_users,
        value,
        ep_level,
        ep_level_value,
        id,
        node_id,
        parent,
        children: hasChildren ? this.buildTreeNodes(children) : [],
        user_permission_acpln:user_permission_acpln,
        code:code,
        title: (
          <div // Use a div container for the entire node
            onClick={() => this.handleNodeClick(value)} // Handle node click
            style={{
              background: hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',

              // background: hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',
              position: 'relative',
              zIndex: 'auto',
              minHeight: '24px',
              margin: '0',
              padding: '0 4px',
              color: 'inherit',
              lineHeight: '24px',
              borderRadius: '6px',
              cursor: 'pointer',
              transition: 'all 0.2s, border 0s, line - height 0s, box - shadow 0s'
            }}
          >
            {hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? (
              <FolderOutlined style={{ cursor: 'unset', opacity: 0.5 }} />
            ) : (
              <FileOutlined
                style={{
                  cursor: 'pointer',
                  color: '#556EE6',
                  opacity: 1,
                }}
              />
            )}
            <span style={{ marginLeft: '4px', opacity: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? 0.5 : 1, fontWeight: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? '' : 600 }}>
              {label}
            </span>
          </div>
        ),

      };
    });
  }
  if(this.state.publishTemplate.hirearchy_type === "1"){
    return nodes.map((node) => {
      const { value, label, children, ep_level,code, ep_level_value, id, node_id,user_permission_acpln, parent, user_path,unique_users } = node;
      const hasChildren =  (children && children.length > 0) ;
      // console.log(nodes,'nodes')
      // //.log(node,'node')
      //  console.log(hasChildren,'hasChildren',node ,children,this.state.publishTemplate)
      return {
        key: value,
        label: label,
        user_path: user_path,
        unique_users:unique_users,
        value,
        ep_level,
        ep_level_value,
        id,
        node_id,
        code:code,
        parent,
        children: hasChildren ? this.buildTreeNodes(children) : [],
        user_permission_acpln : user_permission_acpln,

        title: (
          <div // Use a div container for the entire node
            onClick={() => this.handleNodeClick(value)} // Handle node click
            style={{
              background: node.ep_level === sessionStorage.getItem("hlevel")&& node.node_positon === sessionStorage.getItem("node_positon") ? "#E6F4FF" : "transparent",
              // hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',

              // background: hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',
              position: 'relative',
              zIndex: 'auto',
              minHeight: '24px',
              margin: '0',
              padding: '0 4px',
              color: 'inherit',
              lineHeight: '24px',
              borderRadius: '6px',
              cursor: 'pointer',
              transition: 'all 0.2s, border 0s, line - height 0s, box - shadow 0s'
            }}
          >
            {
              node.ep_level !== sessionStorage.getItem("hlevel") &&node.node_positon !== sessionStorage.getItem("node_positon") 
            // hasChildren && this.state.publishTemplate.hirearchy_type == "1"
             ? (
              <FolderOutlined style={{ cursor: 'unset', opacity: 0.5 }} />
            ) : (
              <FileOutlined
                style={{
                  cursor: 'pointer',
                  color: '#556EE6',
                  opacity: 1,
                }}
              />
            )}
             <span style={{ marginLeft: '4px', opacity: node.ep_level === sessionStorage.getItem("hlevel") &&node.node_positon === sessionStorage.getItem("node_positon")  ?  1 :0.5, fontWeight: node.ep_level === sessionStorage.getItem("hlevel") ? 600 :'' }}>
              {label}
            </span>
            {/* <span style={{ marginLeft: '4px', opacity: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? 0.5 : 1, fontWeight: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? '' : 600 }}>
              {label}
            </span> */}
          </div>
        ),

      };
    });
  }
  };


  getAllTreeStructure = () => {
    console.log(this.state.publishTemplate,this.state.hirerachyData._id,'this.state.publishTemplate')
    var publishTemplate={...this.state.publishTemplate}
    urlSocket.post("webphlbconf/getlocations", {
      userInfo: {
        created_by: this.state.userInfo._id,
        company_id: this.state.userInfo.company_id,
        encrypted_db_url: this.state.db_info.encrypted_db_url
      },
      hInfo: {
        // h_id: this.state.hirerachyData._id,
        h_id:publishTemplate.hlevel_id,
        hlevelinfo: "full_data",
        publishtemplateInfo: publishTemplate
      }
    })
      .then(response => {
        //.log(response, 'response')
        console.log(response,'response')
        if (response.data.response_code == 500) {
          var convertedFlatData = this.convertFlatDataToTreeData(response.data.data)
          publishTemplate.ep_structure = convertedFlatData
          this.setState({
            locationData: convertedFlatData,
          },
            function () {
              this.updateCheckedepStructure()
            }
          )

        }
      })

  }

  handleNodeClick = (value) => {
    //.log(value, 'value')
    console.log(value,'value')
    this.setState({ selectedKey: value });
  };

  hierarchyType = (mode) => {
    //.log(mode, 'mode', this.state.publishTemplate)
    var publishTemplate={...this.state.publishTemplate}
    publishTemplate['hirearchy_type'] = mode
    publishTemplate['endpoints'] = []
    publishTemplate['ep_level'] = null
    publishTemplate['ep_selected'] = []
    publishTemplate['eplevel_value'] = null
    publishTemplate['audit_userlevel'] = null
    publishTemplate['audit_userlevel_value'] = null
    publishTemplate['enable_review'] = false
    publishTemplate['review_userlevel'] = null
    publishTemplate['review_userlevel_value'] = null
    publishTemplate['cc_level'] = 0


    if (mode == "2") {
      this.setState({checkedKeys :[],enableReview : false,publishTemplate},()=>{this.getAllTreeStructure()})
    }
    else {
      this.setState({ publishTemplate, endpointSelected: false, selectedEndpoint: "choose", checkedKeys: [], enableReview: false }, () => { this.updatePublishTemplateData() })
    }
  }



  ignoreLocation = (event, idx, item, mode) => {
    //.log(event, 'ignode', idx, item, this.state.publishTemplate)
    var publishTemplate={...this.state.publishTemplate}
    if (mode == false || mode == undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Ignore Confirmation',
        text: 'Are you sure you want to ignore this location?',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ignore',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          // this.state.publishTemplate.endpoints.map((ele,idx)=>{
          //   //.log(ele._id)
          // })
          publishTemplate.endpoints[idx]["disabled"] = true
          // var ignore_location = this.state.publishTemplate.endpoints.filter((item, position) => position === idx)
          //  var ignore_ep_selected = this.state.publishTemplate.ep_selected.filter((data,position)=> data !== item._id)
          // //.log(ignore_location, 'ignore_location',idx)
          //  this.state.publishTemplate["endpoints"] = ignore_location
          //  this.state.publishTemplate["ep_selected"] = ignore_ep_selected
          this.setState({ publishTemplate , dataloaded : false }, () => {this.setState({dataloaded : true},()=>{this.updatePublishTemplateData()})})

        }

      })
    }
    else if(mode == true) {
      Swal.fire({
        icon: 'question',
        title: 'Reconsider Again ?',
        text: 'Are you sure want to Reconsider this Location again ?',
        showCancelButton: true,
        confirmButtonColor: '#2ba92b',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No'
        // confirmButtonText: 'Go to Published Audits',
      }).then((result) => {
        if (result.isConfirmed) {
          publishTemplate.endpoints[idx]["disabled"] = false
          // this.setState({ publishTemplate: this.state.publishTemplate }, () => { this.updatePublishTemplateData() })
          this.setState({ publishTemplate , dataloaded : false }, () => {this.setState({dataloaded : true},()=>{this.updatePublishTemplateData()})})
        }
      })
    }

  }

  onRemove=(selectedItem,selectedList)=>{
    //.log(selectedItem,'selectedItem',selectedList)
    this.setState({
      selected_category : selectedList
    })

    
  }








  clearUsers = (data, index) => {
    //.log(data, index, 'index')
    var publishTemplate={...this.state.publishTemplate}
    Swal.fire({
      icon: 'question',
      title: 'Reconsider Again ?',
      text: 'Are you sure want to Clear this Location Users ?',
      showCancelButton: true,
      confirmButtonColor: '#2ba92b',
      confirmButtonText: 'Yes',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No'
      // confirmButtonText: 'Go to Published Audits',
    }).then((result) => {

      if (result.isConfirmed) {
        //.log(data, index, 'index')
        publishTemplate["endpoints"][index]["adt_users"] = []
        publishTemplate["endpoints"][index]["rowValid"] =false
        this.setState({ publishTemplate, dataloaded: false }, () => { this.setState({ dataloaded: true }, () => { this.updatePublishTemplateData() }) })

      }

    })
  }

  // tog_center=()=>{
  //   this.setState(prevState => ({
  //     custom_modal: !prevState.custom_modal
  // }));
  // }


  toggle = () => {
    this.setState(prevState => ({
        modal: !prevState.modal
    }));
}

  passWordBtn = () => {
    //.log("triggree")
    this.setState({ show: !this.state.show, })
  }
// }


onSelectValues=(selectedList, selectedItem)=>{
  //.log(selectedList, selectedItem,'selectedList, selectedItem, index')
  this.setState({
    selected_category : selectedList
  })


}





  render() {
    var treeNodes = this.buildTreeNodes(this.state.locationData);
    console.log(this.state.locationData,'this.state.locationData',treeNodes)
    if (this.state.dataloaded) {
      const { locationData } = this.state
      // const { SearchBar } = Search;
      const { isEdit } = this.state
      const options = {
        page: this.state.paginationPage,

        sizePerPage: 10,
        totalSize: this.state.publishTemplate.endpoints.length, // replace later with size(users),
        custom: true,
        onPageChange: (e) => {
          this.setState({ paginationPage: e })
        }
      };
      var columns

      if(this.state.methodSelected == "2"){
       columns = [


        {
          accessor: 'hlevel_name',
          Header: 'Location',
          // sort: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                <div style={{ display: 'flex', opacity: item.disabled ? "50%": "none", flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>
                  {item.hlevel_name}
                </div>
              </>
            )
          }
        },
        {
          accessor: 'code',
          Header: 'Code',
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <div style={{ color: item.rowValid ? null : "#f46a6a" }}>
                {item.code}
              </div>
            )
          }
        },
        {
          accessor: 'hlevel',
          Header: 'Category',
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <div style={{ color: item.rowValid ? null : "#f46a6a" ,opacity: item.disabled ? "50%": "none"}}>
                {item.hlevel}
              </div>
            )
          }
        },

        {
          accessor: 'adt_usr',
          Header: 'Audit User',
          Cell: (cellProps) => {
            //.log(item,'item')
            var item = cellProps.row.original
            var index = cellProps.row.index
            var mappedUser
            var auditor_index

            if (this.state.publishTemplate.method_selected == "2") {

              var getIndex = _.findIndex(item.adt_users, (user) => {
                return (user.review_user === undefined || user.review_user === false) && (user.cat_type === "1" || user.cat_type === "2" || user.cat_type === "3" || user.cat_type == null || user.cat_type == user.cat_type);
              });
              if(item.unique_users.length >0){
              const auditor_idx = item.unique_users.findIndex(user => user.cat_type.includes('1'));
              auditor_index = item.unique_users[auditor_idx]?.cat_type.indexOf('1');
              //.log(auditor_index, 'catTypeIndex')
            }

            }
            else {
              var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
            }
            if (getIndex !== -1) {
              mappedUser = item.adt_users[getIndex]
             
              item["rowValid"] = true
            }
            else {
              mappedUser = undefined
            }
            console.log(mappedUser,'mappedUser',getIndex)
            return (
              <>
                <div>
                  <select
                    type="select"
                    name="adtuser"
                    label="Name"
                    value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
                    className="form-select"
                    id={"adtuser" + String(item._id)}
                    disabled={item.disabled ? true : false}
                    onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}>
                    <option value="choose" disabled >Choose...</option>
                    <option value="add" >Create New User</option>
                    <option value="#import" >+ Add Users</option>

                    {
                      //.log(item,'item')
                    }
                    {
                      item?.unique_users.map((data, idx) => {
                        const isUserDisabled = item.adt_users.some(user => user.user_id === data.user_id &&  user.audit_type === "2");

                        // const isUserDisabled = item.adt_users.some(user => user.user_id === data.user_id);
                        return (
                          <option value={data.user_id} disabled={isUserDisabled} key={idx}>{data.name} - {auditor_index !== undefined ? data.hirerachy_name[auditor_index] == undefined ? data.hirerachy_name[0] : data.hirerachy_name[auditor_index] :data.hirerachy_name[0]}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </>
            )
          }
        },

        // {
        //   accessor: '',
        //   Header: 'Review User',
        //   // hidden: this.state.methodSelected === "2" ? true : false,
        //   Cell: (cellProps) => {
        //     var item =  cellProps.row.original
        //     var index =  cellProps.row.index

        //     var mappedUser
        //     var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
        //     if (getIndex !== -1) {
        //       mappedUser = item.adt_users[getIndex]
        //       this.updateCCLevel()
        //     }
        //     else {
        //       mappedUser = undefined
        //     }

        //     return (
        //       <>
        //         {
        //           !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null
        //         }
        //         {
        //           this.state.customEnableReview ?
        //             <div>
        //               <select
        //                 type="select"
        //                 name="rvwuser"
        //                 label="Name"
        //                 value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
        //                 className="form-select"
        //                 id={"custrvwuser" + String(item._id)}
        //                 // disabled={}
        //                 onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
        //                 <option value="choose" disabled >Choose...</option>
        //                 <option value="add" >Add New User</option>
        //                 {
        //                   this.state.userdata.map((data, idx) => {
        //                     return (
        //                       <option value={data._id} selected key={idx}>{data.firstname}</option>
        //                     )
        //                   })
        //                 }
        //               </select>
        //             </div> : null
        //         }

        //       </>
        //     )
        //   }
        // },

        {
          accessor: '',
          Header: 'Review User',
          hidden: this.state.methodSelected === "2" ? false : true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            var index = cellProps.row.index
            var mappedUser
            var catTypeIndex
            //.log(this.state.publishTemplate.method_selected ,'this.state.publishTemplate.method_selected ')
            if (this.state.publishTemplate.method_selected == "2") {
              var getIndex = _.findIndex(item.adt_users, (user) => {
                return user.review_user === true && (user.cat_type === "1" || user.cat_type === "2" || user.cat_type === "3" || user.cat_type == null);
              });
              const reviwer_idx = item.unique_users.findIndex(user => user.cat_type.includes('2'));
              //.log(reviwer_idx,'reviwer_idx')
              if(reviwer_idx !== -1){
              catTypeIndex = item.unique_users[reviwer_idx].cat_type.indexOf('2');
            }
            }
            else {
              var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
            }
            if (getIndex !== -1) {
              mappedUser = item.adt_users[getIndex]
             
              //.log('index',catTypeIndex)
              // this.updateCCLevel()
            }
            else {
              mappedUser = undefined
            }
            //.log(catTypeIndex,'catTypeIndex')
            return (
              <>
                {
                  !this.state.enableReview ? <div className="text-secondary">Review not enable</div> : null
                }
                {
                  this.state.enableReview ?
                    <div>
                      <select
                        type="select"
                        name="rvwuser"
                        label="Name"
                        value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
                        className="form-select"
                        id="rvwuser"
                        disabled={!this.state.enableReview || item.disabled ? true : false}
                        onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
                        <option value="choose" disabled >Choose...</option>
                        <option value="add" >Create New User</option>
                    <option value="#import" >+ Add Users</option>
                      
                        {/* <option value="add" >Create New User</option> */}

                        {
                          item.unique_users.map((data, idx) => {
                            console.log(data,'data')
                            //.log(data.hirerachy_name[catTypeIndex],catTypeIndex)
                            const isUserDisabled = item.adt_users.some(user => user.user_id === data.user_id &&  user.audit_type === "1");
                            return (
                              <option value={data._id} disabled={isUserDisabled} selected key={idx}>{data.name} - {catTypeIndex !== undefined ? data.hirerachy_name[catTypeIndex] == undefined ? data.hirerachy_name[0] : data.hirerachy_name[catTypeIndex] :data.hirerachy_name[0]}</option>
                            )
                          })
                        }
                      </select>
                    </div> : null
                }

              </>
            )
          }
        },


        {
          accessor: "menu",
          // isDummyField: true,
          // editable: false,
          Header: "Reset Users",
          // hidden: this.state.methodSelected === "2" && this.state.publishTemplate.enable_review == false ? true : false,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            var index = cellProps.row.index
            return(
          <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
             {
                this.state.methodSelected =="2" && this.state.publishTemplate.enable_review &&
                <Button className={"btn btn-primary"} onClick={(e)=>{this.clearUsers(item,index)}}>Clear </Button>
             }

            </div>
          )

            },
        },

        {
          accessor: "menus",
          // isDummyField: true,
          // editable: false,
          Header: "Action",
          // hidden: this.state.methodSelected === "2" ? false : false,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            var index = cellProps.row.index
            return(
            <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
              {
                this.state.methodSelected == "1" ?
              <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>
                :
                  <Button className={item.disabled ? "btn btn-success" :"btn btn-danger"} onClick={(e)=>{this.ignoreLocation(e,index,item,item.disabled)}}>{item.disabled ? "Reconsider" : "Ignore"} </Button>
              }
            </div>
            )
          },
        },
      ];
    }
    if(this.state.methodSelected === "1"){
      if(this.state.publishTemplate.enable_review === true){
       columns = [
       {

        accessor: 'hlevel_names',

        Header: 'Location',

            // sort: true,

            Cell: (cellProps) => {
              var item = cellProps.row.original
                return (

                    <>

                        <div style={{ display: 'flex', flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>

                            {item.hlevel_name}

                        </div>

                    </>

                )

            }

        },

        {
          accessor: 'codes',
          Header: 'Code',
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <div style={{ color: item.rowValid ? null : "#f46a6a" }}>
                {item.code}
              </div>
            )
          }
        },

        {
          accessor: 'hlevels',
          Header: 'Category',
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <div style={{ color: item.rowValid ? null : "#f46a6a" ,opacity: item.disabled ? "50%": "none"}}>
                {item.hlevel}
              </div>
            )
          }
        },



        {
          accessor: 'adt',
          Header: 'Audit User',
          Cell: (cellProps) => {
            var item = cellProps.row.original
            var index = cellProps.row.index
                var mappedUser

                var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })

                if (getIndex !== -1) {

                    mappedUser = item.adt_users[getIndex]

                }

                else {

                    mappedUser = undefined

                }

                // console.log(cellContent, item, index)

                return (

                    <>

                        <div>

                            <select

                                type="select"

                                name="adtuser"

                                label="Name"

                                value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

                                className="form-select"

                                id={"adtuser" + String(item._id)}

                                onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}>

                                <option value="choose" disabled >Choose...</option>

                                <option value="add" >Add New User</option>

                                {

                                    this.state.userdata.map((data, idx) => {

                                        return (

                                            <option value={data._id} key={idx}>{data.firstname}</option>

                                        )

                                    })

                                }

                            </select>

                        </div>

                    </>

                )

            }

        },
       
        {
          accessor: 'rw',
          Header: 'Review User',
          // hidden: this.state.methodSelected === "2" ? true : false,
          Cell: (cellProps) => {
            var item =  cellProps.row.original
            var index =  cellProps.row.index

            var mappedUser
            var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
            if (getIndex !== -1) {
              mappedUser = item.adt_users[getIndex]
              // this.updateCCLevel()
            }
            else {
              mappedUser = undefined
            }

            return (
              <>
                {
                  !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null
                }
                {
                  this.state.customEnableReview ?
                    <div>
                      <select
                        type="select"
                        name="rvwuser"
                        label="Name"
                        value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
                        className="form-select"
                        id={"custrvwuser" + String(item._id)}
                        // disabled={}
                        onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
                        <option value="choose" disabled >Choose...</option>
                        <option value="add" >Add New User</option>
                        {
                          this.state.userdata.map((data, idx) => {
                            return (
                              <option value={data._id} selected key={idx}>{data.firstname}</option>
                            )
                          })
                        }
                      </select>
                    </div> : null
                }

              </>
            )
          }
        }
,

        {

          accessor: "menua",
            Header: "Action",
            Cell: (cellProps) => {
              var item = cellProps.row.original
              var index = cellProps.row.index
              return(

                <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                    <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>
                </div>
                )

              }

        },
        

    ];
  }
  else{
    columns = [
      {

       accessor: 'hlevel_names',

       Header: 'Location',

           // sort: true,

           Cell: (cellProps) => {
             var item = cellProps.row.original
               return (

                   <>

                       <div style={{ display: 'flex', flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>

                           {item.hlevel_name}

                       </div>

                   </>

               )

           }

       },

       {
         accessor: 'codes',
         Header: 'Code',
         Cell: (cellProps) => {
           var item = cellProps.row.original
           return (
             <div style={{ color: item.rowValid ? null : "#f46a6a" }}>
               {item.code}
             </div>
           )
         }
       },

       {
         accessor: 'hlevels',
         Header: 'Category',
         Cell: (cellProps) => {
           var item = cellProps.row.original
           return (
             <div style={{ color: item.rowValid ? null : "#f46a6a" ,opacity: item.disabled ? "50%": "none"}}>
               {item.hlevel}
             </div>
           )
         }
       },



       {
         accessor: 'adt',
         Header: 'Audit User',
         Cell: (cellProps) => {
           var item = cellProps.row.original
           var index = cellProps.row.index
               var mappedUser

               var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })

               if (getIndex !== -1) {

                   mappedUser = item.adt_users[getIndex]

               }

               else {

                   mappedUser = undefined

               }

               // console.log(cellContent, item, index)

               return (

                   <>

                       <div>

                           <select

                               type="select"

                               name="adtuser"

                               label="Name"

                               value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

                               className="form-select"

                               id={"adtuser" + String(item._id)}

                               onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}>

                               <option value="choose" disabled >Choose...</option>

                               <option value="add" >Add New User</option>

                               {

                                   this.state.userdata.map((data, idx) => {

                                       return (

                                           <option value={data._id} key={idx}>{data.firstname}</option>

                                       )

                                   })

                               }

                           </select>

                       </div>

                   </>

               )

           }

       },
      
//        {
//          accessor: 'rw',
//          Header: 'Review User',
//          // hidden: this.state.methodSelected === "2" ? true : false,
//          Cell: (cellProps) => {
//            var item =  cellProps.row.original
//            var index =  cellProps.row.index

//            var mappedUser
//            var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
//            if (getIndex !== -1) {
//              mappedUser = item.adt_users[getIndex]
//              this.updateCCLevel()
//            }
//            else {
//              mappedUser = undefined
//            }

//            return (
//              <>
//                {
//                  !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null
//                }
//                {
//                  this.state.customEnableReview ?
//                    <div>
//                      <select
//                        type="select"
//                        name="rvwuser"
//                        label="Name"
//                        value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
//                        className="form-select"
//                        id={"custrvwuser" + String(item._id)}
//                        // disabled={}
//                        onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
//                        <option value="choose" disabled >Choose...</option>
//                        <option value="add" >Add New User</option>
//                        {
//                          this.state.userdata.map((data, idx) => {
//                            return (
//                              <option value={data._id} selected key={idx}>{data.firstname}</option>
//                            )
//                          })
//                        }
//                      </select>
//                    </div> : null
//                }

//              </>
//            )
//          }
//        }
// ,

       {

         accessor: "menua",
           Header: "Action",
           Cell: (cellProps) => {
             var item = cellProps.row.original
             var index = cellProps.row.index
             return(

               <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                   <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>
               </div>
               )

             }

       },
       

   ];
  }
    }
      const defaultSorted = [{
        dataField: 'hname', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
      }];
      return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>AuditVista | Publishing</title>
            </MetaTags>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-1">
              <div style={{ width: '80%', padding: '0 20px' }}>Publishing</div>
              <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
              </div>
            </div>
            <Container fluid>
              {
                this.state.methodSelected === "0" ? <div>
                  <Card>
                    <CardBody>
                      <Label className="my-3">Add Location by</Label>

                      <Row>
                        <Col xl="6" sm="6">
                          <div className="me-2 text-wrap">
                            {/* <p className="card-title-desc">
                              Use the <code>.badge-pill</code> modifier className to
                              make badges more rounded (with a larger{" "}
                              <code>border-radius</code>
                              and additional horizontal <code>padding</code>).
                              Useful if you miss the badges from v3.
                            </p> */}
                            <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("2")}>Hirerachy Level</button>
                          </div>
                        </Col>

                        <Col xl="6" sm="6">

                          <div className="me-2 text-wrap">
                            {/* <p className="card-title-desc">
                              Use the <code>.badge-pill</code> modifier className to
                              make badges more rounded (with a larger{" "}
                              <code>border-radius</code>
                              and additional horizontal <code>padding</code>).
                              Useful if you miss the badges from v3.
                            </p> */}
                            <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("1")}>Manual Location</button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div> : null
              }

              {
                this.state.methodSelected === "1" ?
                  <div>

                    <div className="checkout-tabs">
                      <Row>
                        <Col lg="2" sm="3" className="pe-0">
                          <Nav className="flex-column" pills>
                            <NavItem>
                              <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f', marginBottom:"5px" }}
                                className={classnames({
                                  active: this.state.activeCustomTab === 1 && this.state.cc_level == 0,
                                  activechange: this.state.activeCustomTab === 1 && this.state.cc_level > 0
                                })

                                }
                                onClick={() => {
                                  this.toggleCustomTab(1)
                                }}
                              
                              >
                                <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />
                                <p className="font-weight-bold mb-4">Locations</p>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6', marginBottom:"5px" }}
                                className={classnames({
                                  active: this.state.activeCustomTab === 2 && this.state.cc_level > 1,
                                  activechange: this.state.activeCustomTab === 2 && this.state.cc_level > 2
                                })}
                                onClick={() => {
                                  this.toggleCustomTab(2)
                                }}
                                disabled={this.state.cc_level > 1 ? false : true}
                              >
                                <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />
                                <p className="font-weight-bold mb-4">Configuration</p>
                              </NavLink>
                            </NavItem>
                            <NavItem >
                              <NavLink style={{ border: this.state.cc_level < 3 ? "0px" : this.state.cc_level > 3 ? '1px solid #34c38f' : '1px solid #556ee6', marginBottom:"5px" }}
                                className={classnames({
                                  active: this.state.activeCustomTab === 3 && this.state.cc_level > 2,
                                  activechange: this.state.activeCustomTab === 3 && this.state.cc_level >= 4
                                })}
                                onClick={() => {
                                  this.toggleCustomTab(3)
                                }}
                                disabled={this.state.cc_level > 2 ? false : true}
                              >
                                <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
                                <p className="font-weight-bold mb-4">Confirmation</p>
                              </NavLink>
                            </NavItem>

                          </Nav>
                        </Col>

                        <Col lg="10" sm="9" className="px-1">
                          <AvForm className="form-horizontal" >
                            <Card>
                              <CardBody>
                                <TabContent activeTab={this.state.activeCustomTab}>

                                  <TabPane
                                    tabId={1}>
                                    <div>
                                      {
                                        this.state.auditUserlevelSelected ?
                                        <>
                                                           <Row className="mb-2" style={{ paddingTop: 10 }}>
                                                          <Col sm="3">
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                              <div className="position-relative">
                                                                {/* <SearchBar {...toolkitprops.searchProps} /> */}
                                                                {/* <i className="bx bx-search-alt search-icon" /> */}
                                                              </div>
                                                            </div>
                                                          </Col>
                                                          <Col sm="9" >
                                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
                                                              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20, position: 'relative', }} >
                                                                <div className="select-container me-2">
                                                                  <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
                                                                    <option value="">Select User Group</option>
                                                                    <option value="all">All</option>
                                                                    {
                                                                      this.state.labelData?.map((data, idx) => {
                                                                        return (
                                                                          <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
                                                                        )
                                                                      })
                                                                    }

                                                                  </select>
                                                                </div>
                                                                <div>
                                                                  <Button color={"primary"} outline className="btn-md me-2 " style={{ padding: '7px' }}
                                                                    onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadendpoint", editItem: null })}
                                                                  >
                                                                    Load locations
                                                                  </Button>
                                                                  <Button color={"primary"} outline className="btn-sm me-2" style={{ padding: '7px' }}
                                                                    onClick={() => this.setState({ open: this.state.open ? false : true, component: "endpoint", editItem: null })}
                                                                  >
                                                                    Add new location
                                                                  </Button>
                                                                  <Button color={"danger"} outline className="btn-sm" style={{ padding: '7px' }}
                                                                    onClick={() => { this.deleteAllRequest() }}
                                                                  >
                                                                    Delete all locations
                                                                  </Button>

                                                                </div>
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                        <Row>
                                                          <div className="form-check form-switch form-switch-sm mx-4">
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              id={"customreview"}
                                                              onChange={(e) => { this.onchangeCustomEnableReview(e) }}
                                                              checked={this.state.customEnableReview}
                                                            />
                                                            <label className={"form-check-label text-primary"} htmlFor={"customreview"}>
                                                              Enable Review
                                                            </label>
                                                          </div>
                                                        </Row>
                                            <TableContainer
                                              columns={columns}
                                              data={this.state.publishTemplate.endpoints}
                                              isGlobalFilter={true}
                                              isAddOptions={false}
                                              isJobListGlobalFilter={false}
                                              customPageSize={10}
                                              style={{ width: '100%' }}
                                              // dynamicBtn={true}
                                              // btnClick={() => { this.setState({ open: true, eopt: null }) }}
                                              // // dynamicParams={params1,params2}
                                              // btnName={"Add Location"}
                                              isPagination={true}
                                              // iscustomPageSizeOptions={true}
                                              filterable={false}
                                              tableClass="align-middle table-nowrap table-check"
                                              theadClass="table-light"
                                              pagination="pagination pagination-rounded justify-content-end mb-2"
                                            />
                                            <Row>
                                                          <Col md={11} className='text-end'>
                                                         </Col>
                                                      <Col md={1}>
                                                           <Button className={this.state.cc_level === 0 ? "btn btn-secondary" : "btn btn-success"} disabled={this.state.cc_level === 0} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
                                                          </Col>
                                                       </Row>
                                                       <br />
                                                      <Row>
                                                         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                         <Button color={"danger"}
                                                            onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method
                                                         </Button>
                                                          </div>
                                                        </Row>
  </>
                                          
                                          : null
                                      }

                                    </div>
                                  </TabPane>

                                  <TabPane
                                    tabId={2}
                                    id="v-pills-confir"
                                    role="tabpanel">
                                    <div>
                                      <div className='mb-3'>
                                        <span className='font-size-17'>Configuration</span>
                                      </div>

                                      <hr className="my-4" />

                                      <div>
                                        <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>
                                        <p className="card-title-desc">
                                          Use the <code>.badge-pill</code> modifier className to
                                          make badges more rounded (with a larger{" "}
                                          <code>border-radius</code>
                                          and additional horizontal <code>padding</code>).
                                          Useful if you miss the badges from v3.
                                        </p>
                                        <div className="mb-3 col-6">
                                          <select
                                            type="select"
                                            name="repeat_mode"
                                            label="Name"
                                            value={this.state.selectedRepeatMode}
                                            className="form-select"
                                            id="review_mode_level"
                                            required
                                            onChange={(e) => this.selectRepeatMode(e)}
                                          >
                                            <option value="choose" disabled>Choose...</option>
                                            {
                                              this.state.repeatModeData.map((data, idx) => {
                                                return (
                                                  <option value={String(idx)} selected key={idx}>{data.mode}</option>
                                                )
                                              })
                                            }
                                          </select>
                                        </div>
                                      </div>

                                      <div>
                                        {this.repeatModeComponent(this.state.selectedRepeatMode)}
                                      </div>
                                      <Row>
                                        <Col md={11} className='text-end'>
                                          <Button color="primary" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button>
                                        </Col>
                                        <Col md={1}>
                                          <Button color="success" disabled={this.state.cc_level == 2} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </TabPane>

                                  <TabPane
                                    tabId={3}
                                    id="v-pills-confir"
                                    role="tabpanel">
                                    <div>
                                      <div className='mb-2'>
                                        <span className='font-size-17'>Confirmation</span> <br />
                                        <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>
                                      </div>

                                      <Row className="justify-content-center">
                                        <Col xl={10}>

                                          <div className="mt-2">
                                            <div className="d-flex flex-wrap">
                                              <div className="me-2">
                                                <span className="me-2 font-size-18">Locations</span> <span
                                                  onClick={() => {
                                                    this.toggleCustomTab(1)
                                                  }}
                                                  className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
                                                  edit
                                                </span>

                                              </div>
                                              <div className="ms-auto">
                                                <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
                                              </div>
                                            </div>
                                            <hr className="mt-2" />

                                            <div className="list-group list-group-flush">
                                              <div
                                                to="#"
                                                className="list-group-item text-muted"
                                              >
                                                <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                Total Locations - <span className="text-success">{this.state.publishTemplate.endpoints.length}</span>
                                              </div>
                                            </div>
                                          </div>


                                          <div className="mt-5">
                                            <div className="d-flex flex-wrap">
                                              <div className="me-2">
                                                <span className="me-2 font-size-18">Configuration</span>
                                                <span
                                                  onClick={() => {
                                                    this.toggleCustomTab(2)
                                                  }}
                                                  className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
                                                  edit
                                                </span>
                                              </div>
                                              <div className="ms-auto">
                                                <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
                                              </div>
                                            </div>
                                            <hr className="mt-2" />

                                            <div className="list-group list-group-flush">

                                              <div
                                                to="#"
                                                className="list-group-item text-muted"
                                              >
                                                <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                Repeat Mode - <span className="text-success">{this.state.publishTemplate.repeat_mode}</span>
                                              </div>
                                              <div to="#"
                                                className="list-group-item text-muted d-flex">
                                                <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                Display &nbsp;&nbsp;
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                  <div>Audit Score:</div>&nbsp;&nbsp;
                                                  <input type={"checkbox"} name='audit_score_preview'  checked={this.state.publishTemplate.audit_score_preview === true ? true : false} onChange={(e) => this.handle_click(e)} />&nbsp;&nbsp;
                                                </div>
                                                {
                                                  this.state.customEnableReview === true &&
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>Review Score:</div>&nbsp;&nbsp;
                                                    <input type={"checkbox"} name="review_score_preview" checked={this.state.publishTemplate.review_score_preview === true ? true : false} onChange={(e) => this.handle_click(e)} />
                                                  </div>
                                                }


                                              </div>

                                              <div to="#"
                                                className="list-group-item text-muted d-flex">
                                                <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                Track Location &nbsp;&nbsp;
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                  <div>Auditor :</div>&nbsp;&nbsp;
                                                  <input type={"checkbox"} name='audit_coords_enable' checked={this.state.publishTemplate.audit_coords_enable === true ? true : false} onChange={(e) => this.handle_click(e)} />&nbsp;&nbsp;
                                                </div>
                                                {
                                                  this.state.customEnableReview === true &&
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>Reviewer :</div>&nbsp;&nbsp;
                                                    <input type={"checkbox"} name="review_coords_enable"  checked={this.state.publishTemplate.review_coords_enable === true ? true : false} onChange={(e) => this.handle_click(e)} />
                                                  </div>
                                                }

                                              </div>

                                              <div
                                                to="#"
                                                className="list-group-item text-muted"
                                              >
                                                <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                Publish on <span className="text-danger">*</span>
                                                <input
                                                  className="form-control"
                                                  type="date"
                                                  min={this.state.fromate_date}
                                                  selected={this.state.ptl_startdate}
                                                  defaultValue={this.state.ptl_startdate}
                                                  id="start_date"
                                                  onChange={event => this.onChangeStartDate(event)}
                                                  onKeyDown={(e) => { e.preventDefault() }}
                                                />
                                              </div>

                                              <div
                                                to="#"
                                                className="list-group-item text-muted"
                                              >
                                                <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                Audit End on <span className="text-danger">*</span>
                                                <input
                                                  className="form-control"
                                                  type="date"
                                                  min={this.state.ptl_startdate}
                                                  value={this.state.ptl_startdate > this.state.ptl_enddate ? "" : this.state.ptl_enddate}
                                                  selected={this.state.ptl_enddate}
                                                  defaultValue={this.state.ptl_enddate}
                                                  max={this.calculateMaxDate()}
                                                  disabled={this.state.ptl_startdate == '' || this.state.ptl_startdate == undefined}
                                                  id="end_date"
                                                  onChange={event => this.onChangeEndDate(event)}
                                                  onKeyDown={(e) => { e.preventDefault() }}
                                                />
                                              </div>



                                            </div>
                                          </div>

                                          <div className="mt-5">
                                            <div className="d-flex flex-wrap">
                                              <div className="me-2">
                                                <span className="me-2 font-size-18">Check points</span> <span
                                                  onClick={() => this.navigateTo(this.state.publishTemplate)}
                                                  className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
                                                  edit
                                                </span>
                                              </div>
                                              <div className="ms-auto">
                                                <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
                                              </div>
                                            </div>
                                            <hr className="mt-2" />

                                            <div className="list-group list-group-flush">
                                              <div

                                                className="list-group-item text-muted"
                                              >
                                                <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>
                                              </div>

                                            </div>
                                          </div>

                                        </Col>
                                      </Row>
                                      <Row>
                                        <div className="wizard clearfix">
                                          <div className="actions clearfix">
                                            <Button color="primary" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button>
                                            <button className='btn btn-primary mx-2' onClick={() => { window.location.href = "/mngpblhtempt" }}> Save </button>
                                            <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>
                                          </div>
                                          <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>
                                        </div>
                                      </Row>

                                    </div>

                                  </TabPane>

                                </TabContent>
                              </CardBody>
                            </Card>
                          </AvForm>
                        </Col>

                      </Row>
                    </div>



                  </div> :
                  this.state.methodSelected === "2" ?
                    <div>
                      <div className="checkout-tabs">
                        <Row>

                          <Col lg="2" sm="3">
                            <Nav className="flex-column" pills>
                              <NavItem>
                                <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f' }}
                                  className={classnames({
                                    active: this.state.activeTab === 1 && this.state.cc_level == 0,
                                    activechange: this.state.activeTab === 1 && this.state.cc_level > 0
                                  })

                                  }
                                  onClick={() => {
                                    this.toggleTab(1)
                                  }}
                                >
                                  <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />
                                  <p className="font-weight-bold mb-4">Hirerachy Level</p>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink style={{ border: this.state.cc_level == 0 ? "0px" : this.state.cc_level > 1 ? '1px solid #34c38f' : '1px solid #556ee6' }}
                                  className={classnames({
                                    active: this.state.activeTab === 2 && this.state.cc_level > 0,
                                    activechange: this.state.activeTab === 2 && this.state.cc_level > 1
                                  })}
                                  onClick={() => {
                                    this.toggleTab(2);
                                  }}
                                  disabled={this.state.cc_level > 0 ? false : true}
                                >
                                  <i className="bx bxs-user d-block check-nav-icon mt-4 mb-2" />
                                  <p className="font-weight-bold mb-4">Audit / Review</p>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6' }}
                                  className={classnames({
                                    active: this.state.activeTab === 3 && this.state.cc_level > 1,
                                    activechange: this.state.activeTab === 3 && this.state.cc_level > 2
                                  })}
                                  onClick={() => {
                                    this.toggleTab(3)
                                  }}
                                  disabled={this.state.cc_level > 1 ? false : true}
                                >
                                  <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />
                                  <p className="font-weight-bold mb-4">Configuration</p>
                                </NavLink>
                              </NavItem>
                              <NavItem >
                                <NavLink style={{ border: this.state.cc_level < 3 ? "0px" : this.state.cc_level === 3 ? '1px solid #556ee6' : '1px solid #34c38f' }}
                                  className={classnames({
                                    active: this.state.activeTab === 4 && this.state.cc_level > 2,
                                    activechange: this.state.activeTab === 4 && this.state.cc_level > 3
                                  })}
                                  onClick={() => {
                                    this.toggleTab(4)
                                  }}
                                  disabled={this.state.cc_level > 2 ? false : true}
                                >
                                  <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
                                  <p className="font-weight-bold mb-4">Confirmation</p>
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Col>
                          <Col lg="10" sm="9">
                            <AvForm className="form-horizontal" onValidSubmit={this.crudNode} onInvalidSubmit={this.handleInvalidSubmit}>
                              <Card>
                                <CardBody>
                                  <TabContent activeTab={this.state.activeTab}>

                                    <TabPane
                                      tabId={1}>
                                      <div>

                                        <Row className='mb-3'>
                                          <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                            <div className='col-8'>
                                              <span className='font-size-17'>Hirerachy Level</span>
                                            </div>
                                            <div className='col-4'>
                                              <button className="btn btn-danger" onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method</button>
                                            </div>
                                          </div>
                                        </Row>

                                        <div>
                                          <div>
                                            <Label className="text-primary" htmlFor="autoSizingSelect">Select Level</Label>
                                            <p className="card-title-desc">
                                              Select appropriate hirerachy level from dropdown or {" "}
                                              <badge className='btn badge badge-soft-primary rounded-pill font-size-12 me-1'>Create New Hirerachy level </badge>
                                            </p>
                                            <div className="mb-3 col-6">

                                              <AvInput
                                                type="select"
                                                name="hlevel"
                                                label="HirerachLevel"
                                                value={this.state.selectedHLevelValue}
                                                className="form-select"
                                                id="hlevel1"
                                                required
                                                onChange={(e) => this.selectLevel(e)}>
                                                <option value="0" defaultValue disabled >Choose...</option>
                                                {
                                                  this.state.dataSource?.map((data, idx) => {
                                                    return (
                                                      <option value={String(idx + 1)} selected key={idx}>{data.hname}</option>
                                                    )
                                                  })
                                                }
                                              </AvInput>
                                            </div>
                                          </div>

                                          <hr className="my-4" />
                                          {
                                            this.state.hlevelIsSelected ?
                                              <div>
                                                <div to="#"
                                                  className="list-group-item text-primary d-flex">
                                                  Select Hirerachy Type: &nbsp;&nbsp;
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '19px' }} className='mt-3'>
                                                  <input type={"radio"} checked={this.state.publishTemplate.hirearchy_type == "1" ? true : false} onChange={(e) => { this.hierarchyType("1") }} />&nbsp;&nbsp;<div onChange={(e) => { this.hierarchyType("1") }} >Organised Structure</div>&nbsp;&nbsp;
                                                  <input type={"radio"} checked={this.state.publishTemplate.hirearchy_type == "2" ? true : false} onChange={(e) => { this.hierarchyType("2") }} />&nbsp;&nbsp;
                                                  <div onChange={(e) => { this.hierarchyType("2") }} >Un-Organised Structure</div>&nbsp;&nbsp;
                                                </div>
                                              </div>
                                              : null
                                          }
                                          <hr className="my-4" />
                                          {
                                            console.log( this.state.hlevelIsSelected,' this.state.hlevelIsSelected')
                                          }
                                          {
                                            this.state.publishTemplate.hirearchy_type == "1" && this.state.hlevelIsSelected ?
                                              <div >
                                                <Label className="text-primary" htmlFor="autoSizingSelect">Select Location</Label>
                                                <p className="card-title-desc">
                                                  Use the <code>.badge-pill</code> modifier className to
                                                  make badges more rounded (with a larger{" "}
                                                  <code>border-radius</code>
                                                  and additional horizontal <code>padding</code>).
                                                  Useful if you miss the badges from v3.
                                                </p>
                                                <div className="mb-3 col-6">
                                                  <select
                                                    type="select"
                                                    name="location"
                                                    label="Name"
                                                    value={this.state.selectedEndpoint}
                                                    className="form-select"
                                                    id="location"
                                                    required
                                                    onChange={(e) => this.selectLocation(e)}>
                                                    <option value="choose" disabled >Choose...</option>
                                                    {
                                                      this.state.hlevelData.map((data, idx) => {
                                                        return (
                                                          <option value={String(idx + 1)} selected key={idx}>{data.hlevel}-({data.node_positon})</option>
                                                        )
                                                      })
                                                    }
                                                  </select>

                                                </div></div>
                                              :
                                              null
                                          }

                                          {this.state.hlevelData.length > 0 && this.state.publishTemplate.hirearchy_type == "1" ? <hr className="my-4" /> : null}
                                          {
                                            this.state.endpointSelected && this.state.publishTemplate.hirearchy_type == "1" ?
                                              <div>
                                                <Label className="text-primary" htmlFor="autoSizingSelect">Select {this.state.hlevelSelected}</Label>
                                                <p className="card-title-desc">
                                                  Use the <code>.badge-pill</code> modifier className to
                                                  make badges more rounded (with a larger{" "}
                                                  <code>border-radius</code>
                                                  and additional horizontal <code>padding</code>).
                                                  Useful if you miss the badges from v3.
                                                </p>
                                                {
                                                  console.log(this.state.checkedKeys,'this.state.checkedKeys')
                                                }
                                                <Tree
                                                  style={{ borderColor: '#150101' }}
                                                  defaultExpandAll={true}
                                                  treeData={treeNodes}
                                                  checkable
                                                  checkedKeys={this.state.checkedKeys}
                                                  onCheck={(checked, event) => { this.onCheck(checked, event) }}
                                                  showLine={true}
                                                  showIcon={true}
                                                />




                                              </div>
                                              :
                                              this.state.publishTemplate.hirearchy_type == "2" ?
                                                <div>
                                                  <Label className="text-primary" htmlFor="autoSizingSelect">Select Location</Label>
                                                  <p className="card-title-desc">
                                                    Use the <code>.badge-pill</code> modifier className to
                                                    make badges more rounded (with a larger{" "}
                                                    <code>border-radius</code>
                                                    and additional horizontal <code>padding</code>).
                                                    Useful if you miss the badges from v3.
                                                  </p>
                                                  {console.log('treeNodes', treeNodes)}
                                                  <Tree
                                                    style={{ borderColor: '#150101' }}
                                                    defaultExpandAll={true}
                                                    treeData={treeNodes}
                                                    checkable
                                                    checkedKeys={this.state.checkedKeys}
                                                    onCheck={(checked, event) => { this.onCheck(checked, event, "2") }}
                                                    showLine={true}
                                                    showIcon={true}
                                                    checkStrictly={true}
                                                  />
                                                </div>
                                                :
                                                null
                                          }


                                        </div>

                                      </div>
                                    </TabPane>
                                          {/* {
                                            //.log(this.state.publishTemplate,'publish')
                                          } */}
                                    <TabPane
                                      tabId={2}
                                      id="v-pills-confir"
                                      role="tabpanel">
                                      <div>
                                        <div className='mb-3'>
                                          <span className='font-size-17'>Audit & Review</span>
                                        </div>

                                        <div>
                                          <Label className="text-primary" htmlFor="autoSizingSelect">Select audit user level</Label>
                                          <p className="card-title-desc">
                                            Use the <code>.badge-pill</code> modifier className to
                                            make badges more rounded (with a larger <code>border-radius</code>
                                            and additional horizontal <code>padding</code>).
                                            Useful if you miss the badges from v3.
                                          </p>
                                          <div className="row">
                                            <div className="mb-3 col-6">
                                              <select
                                                type="select"
                                                name="audit_userlevel"
                                                label="Name"
                                                value={this.state.publishTemplate.audit_userlevel_value == null ? "choose" : String(this.state.publishTemplate.audit_userlevel_value)}
                                                className="form-select"
                                                id="audit_user_level"
                                                required
                                                onChange={(e) => this.selectAuditUserlevel(e)}
                                              >
                                                <option value="choose" disabled>Choose...</option>
                                                {
                                                  this.state.hirerachy_user_level.map((data, idx) => {
                                                    return (
                                                      <option value={data.cat_type} key={idx}>{data.hirerachy_name}</option>
                                                    )
                                                  })
                                                }
                                              </select>
                                            </div>
                                            {/* <div className="mb-3 col-6">
                                              <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
                                                <option value="">Select User Group</option>
                                                <option value="all">All</option>
                                                {this.state.labelData.map((data, idx) => {
                                                  return (
                                                    <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
                                                  )
                                                })}
                                              </select>
                                            </div> */}
                                          </div>
                                        </div>

                                        <hr className="my-4" />

                                        <div className="form-check form-switch form-switch-sm my-3">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={"review"}
                                            onChange={(e) => { this.onchangeEnableReview(e) }}
                                            checked={this.state.enableReview}
                                          />
                                          <label className={"form-check-label text-primary"} htmlFor={"review"}>
                                            Enable Review
                                          </label>
                                        </div>


                                        {this.state.enableReview ?
                                          <div>
                                            <Label className="text-primary" htmlFor="autoSizingSelect">Select review user level</Label>
                                            <p className="card-title-desc">
                                              Use the <code>.badge-pill</code> modifier className to
                                              make badges more rounded (with a larger{" "}
                                              <code>border-radius</code>
                                              and additional horizontal <code>padding</code>).
                                              Useful if you miss the badges from v3.
                                            </p>
                                            <div className="mb-3 col-6">
                                              <select
                                                type="select"
                                                name="review_userlevel"
                                                label="Name"
                                                value={this.state.publishTemplate.review_userlevel == null ? "choose" : String(this.state.publishTemplate.review_userlevel)}
                                                className="form-select"
                                                id="review_user_level"
                                                required
                                                onChange={(e) => this.selectReviewUserlevel(e)}
                                              >
                                                <option value="choose" disabled>Choose...</option>
                                                {
                                                  this.state.hirerachy_user_level.map((data, idx) => {
                                                    return (
                                                      <option value={data.cat_type} key={idx}>{data.hirerachy_name}</option>
                                                    )
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <hr className="my-4" />
                                          </div>
                                          : null}

                                        {
                                          this.state.auditUserlevelSelected ?
                                            <div>
                                               <TableContainer
                                        columns={columns}
                                        data={this.state.publishTemplate.endpoints}
                                        // isGlobalFilter={true}
                                        isAddOptions={false}
                                        isJobListGlobalFilter={false}
                                        customPageSize={10}
                                        style={{ width: '100%' }}
                                        // dynamicBtn={true}
                                        // btnClick={() => { this.setState({ open: true, eopt: null }) }}
                                        // // dynamicParams={params1,params2}
                                        // btnName={"Add Location"}
                                        isPagination={true}
                                        // iscustomPageSizeOptions={true}
                                        filterable={false}
                                        tableClass="align-middle table-nowrap table-check"
                                        theadClass="table-light"
                                        pagination="pagination pagination-rounded justify-content-end mb-2"
                                      />
                                              {/* <PaginationProvider
                                                keyField="id"
                                                data={this.state.publishTemplate.endpoints}
                                                columns={columns}
                                                pagination={paginationFactory(options)}
                                              >
                                                {
                                                  ({
                                                    paginationProps,
                                                    paginationTableProps
                                                  }) => (
                                                    <ToolkitProvider
                                                      keyField="id"
                                                      data={this.state.publishTemplate.endpoints}
                                                      columns={columns}
                                                      search
                                                    >
                                                      {
                                                        toolkitprops => (
                                                          <React.Fragment>
                                                            <Row className="mb-2" style={{ paddingTop: 10 }}>
                                                              <Col sm="4">
                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                  <div className="position-relative">
                                                                    <SearchBar {...toolkitprops.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                  </div>
                                                                </div>
                                                              </Col>
                                                            </Row>
                                                            <Row>
                                                              <Col xl="12">
                                                                <div className="table-responsive">
                                                                  <BootstrapTable
                                                                    {...toolkitprops.baseProps}
                                                                    {...paginationTableProps}
                                                                    defaultSorted={defaultSorted}
                                                                    classes={
                                                                      "table align-middle table-nowrap table-hover"
                                                                    }
                                                                    bordered={false}
                                                                    striped={false}
                                                                    responsive
                                                                    onTableChange={this.handleTableChange}
                                                                  />
                                                                </div>
                                                              </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                <PaginationListStandalone
                                                                  {...paginationProps}
                                                                />
                                                              </Col>
                                                            </Row>
                                                          </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                  )}
                                              </PaginationProvider> */}
                                            </div> : null
                                        }

                                      </div>
                                    </TabPane>

                                    <TabPane
                                      tabId={3}
                                      id="v-pills-config"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-config-tab"
                                    >
                                      <div>
                                        <div className='mb-3'>
                                          <span className='font-size-17'>Configuration</span>
                                        </div>

                                        <hr className="my-4" />

                                        <div>
                                          <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>
                                          <p className="card-title-desc">
                                            Use the <code>.badge-pill</code> modifier className to
                                            make badges more rounded (with a larger{" "}
                                            <code>border-radius</code>
                                            and additional horizontal <code>padding</code>).
                                            Useful if you miss the badges from v3.
                                          </p>
                                          <div className="mb-3 col-6">
                                            <select
                                              type="select"
                                              name="repeat_mode"
                                              label="Name"
                                              value={this.state.selectedRepeatMode}
                                              className="form-select"
                                              id="review_mode_level"
                                              required
                                              onChange={(e) => this.selectRepeatMode(e)}
                                            >
                                              <option value="choose" disabled>Choose...</option>
                                              {
                                                this.state.repeatModeData.map((data, idx) => {
                                                  return (
                                                    <option value={String(idx)} selected key={idx}>{data.mode}</option>
                                                  )
                                                })
                                              }
                                            </select>
                                          </div>
                                        </div>

                                        <div>
                                          {this.repeatModeComponent(this.state.selectedRepeatMode)}
                                        </div>

                                      </div>
                                    </TabPane>

                                    <TabPane
                                      tabId={4}
                                      id="v-pills-confir"
                                      role="tabpanel">
                                      <div>
                                        <div className='mb-2'>
                                          <span className='font-size-17'>Confirmation</span> <br />
                                          <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>
                                        </div>

                                        <Row className="justify-content-center">
                                          <Col xl={10}>

                                            <div className="mt-2">
                                              <div className="d-flex flex-wrap">
                                                <div className="me-2">
                                                  <span className="me-2 font-size-18">Hirerach Level</span> <span
                                                    onClick={() => {
                                                      this.toggleTab(1)
                                                    }}
                                                    className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
                                                    edit
                                                  </span>

                                                </div>
                                                <div className="ms-auto">
                                                  <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
                                                </div>
                                              </div>
                                              <hr className="mt-2" />

                                              <div className="list-group list-group-flush">
                                                <div
                                                  to="#"
                                                  className="list-group-item text-muted"
                                                >
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Hirerachy level - <span className="text-success">{this.state.publishTemplate.hlevel}</span>
                                                </div>

                                                <div
                                                  to="#"
                                                  className="list-group-item text-muted"
                                                >
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Endpoint - <span className="text-success">{this.state.publishTemplate.ep_level} </span>
                                                </div>

                                                <div
                                                  to="#"
                                                  className="list-group-item text-muted"
                                                >
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  No. of Endpoints selected - <span className="text-success">{this.state.publishTemplate.ep_selected.length}</span>
                                                </div>
                                              </div>
                                            </div>

                                            {/* <div className="mt-5">
                                              <div className="d-flex flex-wrap">
                                                <div className="me-2">
                                                  <span className="me-2 font-size-18">Audit / Review</span> <span
                                                    onClick={() => {
                                                      this.toggleTab(2)
                                                    }}
                                                    className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
                                                    edit
                                                  </span>
                                                </div>
                                                <div className="ms-auto">
                                                  <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
                                                </div>
                                              </div>
                                              <hr className="mt-2" />

                                              <div className="list-group list-group-flush">
                                                <div
                                                  to="#"
                                                  className="list-group-item text-muted"
                                                >
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Audit user level - <span className="text-success">{this.state.publishTemplate.audit_userlevel}</span>
                                                </div>

                                                <div
                                                  to="#"
                                                  className="list-group-item text-muted"
                                                >
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Review user level - {this.state.publishTemplate.review_userlevel == null ? <span className="text-danger"> No Review </span> : <span className="text-success">{this.state.publishTemplate.review_userlevel}</span>}
                                                </div>

                                              </div>
                                              <div  to="#"
                                                className="list-group list-group-flush">
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Display &nbsp;&nbsp;
                                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                                  <div>Audit Score:</div>&nbsp;&nbsp;
                                                  <input type={"checkbox"} name='audit_score_preview' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;
                                                  </div>
                                                {
                                                  this.state.customEnableReview === true &&
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>Review Score:</div>&nbsp;&nbsp;
                                                    <input type={"checkbox"} name="review_score_preview" onChange={(e) => this.handle_click(e)} />
                                                  </div>
                                                }


                                              </div>
                                            </div> */}


                                            <div className="mt-5">
                                              <div className="d-flex flex-wrap">
                                                <div className="me-2">
                                                  <span className="me-2 font-size-18">Audit / Review</span>
                                                  <span
                                                    onClick={() => {
                                                      this.toggleTab(2);
                                                    }}
                                                    className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1"
                                                  >
                                                    edit
                                                  </span>
                                                </div>
                                                <div className="ms-auto">
                                                  <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
                                                </div>
                                              </div>
                                              <hr className="mt-2" />

                                              <div className="list-group list-group-flush">
                                                <div to="#" className="list-group-item text-muted">
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Audit user level -{" "}
                                                  <span className="text-success">
                                                    {this.state.publishTemplate.audit_userlevel}
                                                  </span>
                                                </div>

                                                <div to="#" className="list-group-item text-muted">
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Review user level -{" "}
                                                  {this.state.publishTemplate.review_userlevel == null ? (
                                                    <span className="text-danger"> No Review </span>
                                                  ) : (
                                                    <span className="text-success">
                                                      {this.state.publishTemplate.review_userlevel}
                                                    </span>
                                                  )}
                                                </div>

                                                <div to="#" className="list-group-item text-muted d-flex gap-3">
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Display:{" "}
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>Audit Score:</div>&nbsp;&nbsp;
                                                    <input
                                                      type={"checkbox"}
                                                      name="audit_score_preview"
                                                      checked={this.state.publishTemplate.audit_score_preview === true ? true : false}
                                                      onChange={(e) => this.handle_click(e)}
                                                    />
                                                    &nbsp;&nbsp;
                                                  </div>
                                                  {this.state.publishTemplate.review_userlevel !== null && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                      <div>Review Score:</div>&nbsp;&nbsp;
                                                      <input
                                                        type={"checkbox"}
                                                        name="review_score_preview"
                                                        checked={this.state.publishTemplate.review_score_preview === true ? true : false}
                                                        onChange={(e) => this.handle_click(e)}
                                                      />
                                                    </div>
                                                  )}
                                                </div>

                                                <div to="#" className="list-group-item text-muted d-flex gap-3">
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Track Location :{" "}
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>Auditor :</div>&nbsp;&nbsp;
                                                    <input
                                                      type={"checkbox"}
                                                      name="audit_coords_enable"
                                                      checked={this.state.publishTemplate.audit_coords_enable === true ? true : false}
                                                      onChange={(e) => this.handle_click(e)}
                                                    />
                                                    &nbsp;&nbsp;
                                                  </div>
                                                  {this.state.publishTemplate.review_userlevel !== null && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                      <div>Reviewer:</div>&nbsp;&nbsp;
                                                      <input
                                                        type={"checkbox"}
                                                        name="review_coords_enable"
                                                        checked={this.state.publishTemplate.review_coords_enable === true ? true : false}
                                                        onChange={(e) => this.handle_click(e)}
                                                      />
                                                    </div>
                                                  )}
                                                </div>



                                              </div>
                                            </div>




                                            <div className="mt-5">
                                              <div className="d-flex flex-wrap">
                                                <div className="me-2">
                                                  <span className="me-2 font-size-18">Configuration</span> <span
                                                    onClick={() => {
                                                      this.toggleTab(3)
                                                    }}
                                                    className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
                                                    edit
                                                  </span>
                                                </div>
                                                <div className="ms-auto">
                                                  <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
                                                </div>
                                              </div>
                                              <hr className="mt-2" />

                                              <div className="list-group list-group-flush">
                                                <div
                                                  to="#"
                                                  className="list-group-item text-muted"
                                                >
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Publish on <span className="text-danger">*</span>
                                                  {/* <input
                                                    className="form-control"
                                                    type="date"
                                                    min={this.state.fromate_date}
                                                    selected={this.state.ptl_startdate}
                                                    defaultValue={this.state.ptl_startdate}
                                                    id="start_date"
                                                    onChange={event => this.onChangeStartDate(event)}
                                                  /> */}

                                                  <input
                                                    className="form-control"
                                                    type="date"
                                                    min={this.state.fromate_date}
                                                    selected={this.state.ptl_startdate}
                                                    defaultValue={this.state.ptl_startdate}
                                                    id="start_date"
                                                    onChange={event => this.onChangeStartDate(event)}
                                                    onKeyDown={(e) => { e.preventDefault() }}
                                                  />






                                                </div>

                                                <div
                                                  to="#"
                                                  className="list-group-item text-muted"
                                                >
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Audit End on <span className="text-danger">*</span>
                                                  {/* <input
                                                    className="form-control"
                                                    type="date"
                                                    min={this.state.fromate_date}
                                                    selected={this.state.ptl_enddate}
                                                    defaultValue={this.state.ptl_enddate}
                                                    id="end_date"
                                                    onChange={event => this.onChangeEndDate(event)}
                                                  /> */}
                                                  <input
                                                    className="form-control"
                                                    type="date"
                                                    min={this.state.ptl_startdate}
                                                    value={this.state.ptl_startdate > this.state.ptl_enddate ? "" : this.state.ptl_enddate}
                                                    selected={this.state.ptl_enddate}
                                                    defaultValue={this.state.ptl_enddate}
                                                    max={this.calculateMaxDate()}
                                                    disabled={this.state.ptl_startdate == '' || this.state.ptl_startdate == undefined}
                                                    onKeyDown={(e) => { e.preventDefault() }}
                                                    id="end_date"
                                                    onChange={event => this.onChangeEndDate(event)}
                                                  />





                                                </div>

                                                <div
                                                  to="#"
                                                  className="list-group-item text-muted"
                                                >
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Frequency - <span className="text-success">{this.state.publishTemplate.frequency}</span>
                                                </div>

                                              </div>
                                            </div>

                                            <div className="mt-5">
                                              <div className="d-flex flex-wrap">
                                                <div className="me-2">
                                                  <span className="me-2 font-size-18">Check points</span> <span
                                                    onClick={() => this.navigateTo(this.state.publishTemplate)}
                                                    className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
                                                    edit
                                                  </span>
                                                </div>
                                                <div className="ms-auto">
                                                  <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
                                                </div>
                                              </div>
                                              <hr className="mt-2" />

                                              <div className="list-group list-group-flush">
                                                <div

                                                  className="list-group-item text-muted"
                                                >
                                                  <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>
                                                </div>

                                              </div>
                                            </div>

                                          </Col>
                                        </Row>
                                        <Row>
                                          <div className="wizard clearfix">
                                            <div className="actions clearfix">
                                              <button className='btn btn-primary mx-2'> Save </button>
                                              <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>
                                            </div>
                                            <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>
                                          </div>
                                        </Row>
                                      </div>
                                    </TabPane>

                                  </TabContent>
                                </CardBody>
                              </Card>

                              {/* <Row>
                        <div className="wizard clearfix">
                          <div className="actions clearfix">
                            <ul>
                              <li
                                className={
                                  this.state.activeTab === 1 
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    this.toggleTab(this.state.activeTab - 1)
                                  }}
                                >
                                  Previous
                                </Link>
                              </li>
                              <li
                                className={
                                  this.state.activeTab === 4 
                                    ? "next disabled"
                                    : "next"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    this.toggleTab(this.state.activeTab + 1)
                                  }}
                                >
                                  Next
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Row> */}
                            </AvForm>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    : null
              }

            </Container>
          </div>

          {/* <Modal
            isOpen={this.state.custom_modal}
            toggle={() => {
              this.tog_center();
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Center Modal</h5>
              <button
                type="button"
                onClick={() => {
                  // setmodal_center(false);
                  this.setState({custom_modal : false})
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Cras mattis consectetur purus sit amet fermentum.
                Cras justo odio, dapibus ac facilisis in, egestas
                eget quam. Morbi leo risus, porta ac consectetur
                ac, vestibulum at eros.
              </p>
              <p>
                Praesent commodo cursus magna, vel scelerisque
                nisl consectetur et. Vivamus sagittis lacus vel
                augue laoreet rutrum faucibus dolor auctor.
              </p>
              <p className="mb-0">
                Aenean lacinia bibendum nulla sed consectetur.
                Praesent commodo cursus magna, vel scelerisque
                nisl consectetur et. Donec sed odio dui. Donec
                ullamcorper nulla non metus auctor fringilla.
              </p>
            </div>
          </Modal> */}








              {
                this.state.modal == true ?
              <CrudUserHlvl
                modal={this.state.modal}
                toggle={this.toggle}
                onValidSubmit={this.locationUserCrud}
                email_format={this.emai_addrs}
                validate_email={this.validate_email}
                countries={this.state.client_info.countries}
                select_country={this.select_country}
                given_email={this.state.given_email}
                show_password={this.state.show}
                loading={this.state.loading}
                hirerachy_user_level={this.state.hirerachy_user_level}
                passWordBtn={this.passWordBtn}
                country_code_err ={this.state.country_code_err}
                email_err = {this.state.invalid_user}
                valid_email = {this.state.errorMessage}
                category_select ={this.onSelectValues}
                onRemove={this.onRemove}
                />
              :
              null
              }






          <ReactDrawer
            open={this.state.open}
            className="drawer-open"
            position={this.state.position}
            onClose={this.onDrawerClose}
            style={{ maxHeight: 1000 }}
            noOverlay={false}
          >

            <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>
              {
                this.state.component === "user" ?
                // <CrudUserHlvl />
                <>
                </>
             
                  : this.state.component === "endpoint" ?
                    <AddEndpointNode
                      endpoints={this.state.publishTemplate.endpoints}
                      publishtemplateInfo={this.state.publishTemplate}
                      onClose={this.onDrawerClose}
                      editItem={this.state.editItem}
                      onCancel={this.onCancelDrawerClose}
                    /> : this.state.component === "loadendpoint" ?

                      <LoadEndpoint
                        userInfo={this.state.userInfo}
                        publishtemplateInfo={this.state.publishTemplate}
                        onClose={this.onDrawerClose}

                      />
                      :
                      this.state.component === "import_usr" ?
                      <ImportUser 
                      user_data ={this.state.to_import_users}
                      onClose={this.onDrawerClose}
                      rowData ={this.state.location_info}
                      coloumn_type={this.state.coloumn_type}
                      hstructure={JSON.parse(sessionStorage.getItem("hstructure"))}
                      updateInFlatCln={this.updateInFlatCln}
                      />
                      :
                      // this.state.component === "loadgroupusers" ?
                      // <LoadGroupUser
                      // userInfo={this.state.userInfo}
                      // endpoint_data ={this.state.endpoint_data}
                      // publishtemplateInfo={this.state.publishTemplate}
                      // onClose={this.onDrawerClose}
                      // />:


                      null
              }

            </div>
          </ReactDrawer>

          {this.state.changeMethodConfirmation ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                this.state.triggerFrom === "hlevel" ? this.resetEndpointMethod() : this.state.triggerFrom === "endpoint" ? this.deleteEndpoint() : this.state.triggerFrom === "deleteall" && this.deleteAllEndpoints()
              }
              }
              onCancel={() =>
                this.setState({
                  changeMethodConfirmation: false,
                  error_dlg: true,
                  dynamic_title: "Cancelled",
                  dynamic_description:
                    "Your imaginary file is safe :)",
                })
              }
            >
              {this.state.triggerFrom === "hlevel" ? "Changing the Add Location method will clear all saved data." : this.state.triggerFrom === "endpoint" ? "Deleting the Location can't revet." : this.state.triggerFrom === "deleteall" ? "Deleteing all Location can't revert" : ""}
            </SweetAlert>
          ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ success_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}
          {this.state.dataAlert ? (
            <SweetAlert
              title={this.state.alertMessage}
              onConfirm={() => this.setState({
                dataAlert: false,
                publishTemplate: this.state.publishTemplate,
                selectedReviewUserlevel: this.state.selectedReviewUserlevel,
                reviewUserlevelSelected: true,
                cc_level: this.state.cc_level,
                ptl_startdate: null,
                ptl_enddate: null,
                repeatMode: null,
                endpoints: this.state.endpoints,
                review_userlevel: this.state.review_userlevel,
                auditUserlevelSelected: true
              })}
            ></SweetAlert>
          ) : null}

          {this.state.tmpltPublishProcess ? (
            <SweetAlert
              title={this.state.alertMessage}
              onConfirm={() => this.redirectTo(this.state.processStatus)}
            ></SweetAlert>
          ) : null}
        </React.Fragment>
      )
    }
    else {
      // console.log("Here comes the else part")
      return null
    }
  }
}














// import React, { Component } from "react"

// import MetaTags from 'react-meta-tags';
// import {
//   Container,
//   Row,
//   Col,
//   Table,
//   Input,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   TabPane,
//   Card,
//   Form,
//   FormGroup,
//   Label,
//   CardBody,
//   CardTitle,
//   CardSubtitle,
//   InputGroup,
//   Button,
//   Alert,
//   Modal
// } from "reactstrap"
// import Select from "react-select"
// import { Link } from "react-router-dom"
// import facility from '../FacilityScreen'
// import SweetAlert from "react-bootstrap-sweetalert"
// import LoadGroupUser from './Components/LoadGroupUser'
// import SortableTree, {
//   addNodeUnderParent,
//   removeNodeAtPath,
//   toggleExpandedForAll,
//   defaultGetNodeKey,
//   getTreeFromFlatData,
//   getNodeAtPath,
//   changeNodeAtPath,
//   getFlatDataFromTree,
// } from 'react-sortable-tree/dist/index.cjs.js';

// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// // import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// // import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// // import BootstrapTable from "react-bootstrap-table-next"
// import TableContainer from "../../../common/TableContainer";
// import CalendarPreview from "./Components/CalendarPreview";



// import CrudUserHlvl from "./CrudUserHlvl";

// import makeAnimated from "react-select/animated"
// import "flatpickr/dist/themes/material_blue.css"
// import Flatpickr from "react-flatpickr"

// // import SortableTree, {
// //   addNodeUnderParent,
// //   removeNodeAtPath,
// //   toggleExpandedForAll,
// //   getTreeFromFlatData,
// //   getNodeAtPath,
// //   changeNodeAtPath,
// //   getFlatDataFromTree,
// //   walk,
// //   map, find
// // } from 'react-sortable-tree/dist/index.cjs.js';
// import ReactDrawer from 'react-drawer';
// import 'react-drawer/lib/react-drawer.css';

// import classnames from "classnames"

// //import AddNode from "./Components/AddNode"

// import AddUserNode from "./Components/AddUserNode"
// import AddEndpointNode from "./Components/AddEndpointNode"

// //Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import CheckboxTree, {
//   getNode, flattenNodes, deserializeLists, serializeList
// } from 'react-checkbox-tree';
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// import { MdCheckBox, MdCheckBoxOutlineBlank, MdChevronRight, MdKeyboardArrowDown, MdAddBox, MdIndeterminateCheckBox, MdLocationCity, MdStore, MdFolder, MdCheckCircle, MdDescription } from "react-icons/md";
// import CryptoJS from 'crypto-js'
// import _, { repeat } from "lodash";

// import Validation from "./Functions/validation"
// import LoadEndpoint from "./Components/LoadEndpoints"
// import { LoadingOutlined } from '@ant-design/icons';

// import { FolderOutlined, FileOutlined } from '@ant-design/icons';
// import classNames from 'classnames';
// import { DownOutlined } from '@ant-design/icons';
// import { Tree } from 'antd';
// import './custom-tree-styles.css';
// import Swal from "sweetalert2";
// import ImportUser from "./ImportUser";




// import urlSocket from "../../../helpers/urlSocket";
// import { event } from "jquery";
// // import { er } from "@fullcalendar/core/internal-common";
// // var urlSocket = require("../../helpers/urlSocket");
// var moment = require('moment')

// const icons = {
//   check: <MdCheckBox className="rct-icon rct-icon-check text-success" />,
//   uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
//   halfCheck: (
//     <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
//   ),
//   expandClose: (
//     <MdChevronRight className="rct-icon rct-icon-expand-close" />
//   ),
//   expandOpen: (
//     <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
//   ),
//   expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
//   collapseAll: (
//     <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
//   ),
//   parentClose: <MdLocationCity className="rct-icon rct-icon-parent-close" />,
//   parentOpen: <MdLocationCity className="rct-icon rct-icon-parent-open" />,
//   leaf: <MdStore className="rct-icon rct-icon-leaf-close" />
// }


// const nodes = [{
//   "value": 'Mars',
//   "label": 'Mars',
//   "children": [
//     { "value": 'Phobos', "label": 'Phobos' },
//     { "value": 'Deimos', "label": 'Deimos' },
//   ],
// }];



// export default class publishconfig extends Component {

//   constructor(props) {
//     super(props);

//     this.state = {
//       activeTab: 1,
//       activeCustomTab: 1,
//       selectedGroup: null,
//       dataloaded: false,
//       isEdit: false,
//       show_occurence_valid : true,
//       hirerachyData: [],
//       hlevelData: [],
//       hlevelIsSelected: false,
//       endpointSelected: false,
//       locationData: [],
//       checked: [],
//       expanded: [],
//       hlevelSelected: '',
//       auditUserlevelSelected: false,
//       reviewUserlevelSelected: false,
//       endpoints: [],
//       endpointsAvailable: false,
//       repeatModeData: [],
//       weekDays: [],
//       dayStartNumber: [],
//       half_months:[] ,
//       userdata: [],
//       position: 'right',
//       occurence :"",
//       open: false,
//       height: props.height,
//       methodSelected: "0",
//       component: "",
//       triggerFrom: "",
//       paginationPage: 1,
//       sderror: false,
//       ederror: false,
//       publishTemplate: {},
//       open_group: false,
//       quater_periodicity:[],
//       half_yearly_periodicity:[],
//       hirerachy_user_level: [],
//       allowed_audit_monthy:[],
//       alertEnable: false,
//       formDisabled: false,
//       userInfo: {},
//       secret_key: 'Pkdh347ey%3Tgs',
//       loading: false,
//       invalid_user: false,
//       invalid_phone_num: false,
//       invalid_usercode: false,
//       country_code_err: false,
//       permissionsadded: [],
//       given_email: '',
//       modal:false,
//       selected_category:[],
//       checkedKeys: [],
//       treeData:[],
//       dup_treeData:[],
//       import_usr : false,
//       to_import_users :[],
//       selected_quater_months:[],
//       selected_half_months:[],
//       total_weeks:[],
//       calendar_type:[],
//       financial_quater_months:[],
//       financial_half_months:[],
//       allowed_audit_yearly:[],
//       total_months:[],
//       preview_calendar : false
//       // selected_half_months:[]
//     }
//     this.toggleTab = this.toggleTab.bind(this)
//     this.toggleCustomTab = this.toggleCustomTab.bind(this)
//     this.handleSelectGroup = this.handleSelectGroup.bind(this)
//     this.handleMStartGroupChange = this.handleMStartGroupChange.bind(this)
//     this.handleMEndGroupChange = this.handleMEndGroupChange.bind(this)
//     this.getDateFormateMethod = this.getDateFormateMethod.bind(this)
//     this.closeDrawer = this.closeDrawer.bind(this);
//     this.onDrawerClose = this.onDrawerClose.bind(this);
//     this.onCancelDrawerClose = this.onCancelDrawerClose.bind(this)
//     this.setPosition = this.setPosition.bind(this);
//     this.endpointMethod = this.endpointMethod.bind(this);

//   }

//   closeDrawer() {
//     this.setState({ open: false });
//   }

//   onDrawerClose() {
//     this.setState({ auditUserlevelSelected: false, component: "" })
//     this.setState({ open: false });
//     try {
//       urlSocket.post("webphlbconf/gettemplate", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//         },
//         publishtemplateInfo: this.state.publishTemplate
//       })
//         .then(response => {
//           console.log(response,'response')
//           if (response.data.response_code === 500) {
//             this.setState({
//               publishTemplate: response.data.data,
//               endpoints: response.data.data.endpoints,
//               auditUserlevelSelected: true,
//             }, function () {
//               this.updatePublishTemplateData()
//               this.updateCCLevel()
//             })

//           }
//         })
//     } catch (error) {

//     }

//   }

//   onCancelDrawerClose() {
//     this.setState({ open: false, component: "" });
//   }

//   setPosition(e) {
//     this.setState({ position: e.target.value });
//   }

//   getDidmountFunction() {
//     var data = JSON.parse(sessionStorage.getItem("authUser"));
//     var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//     var publishTemplate = JSON.parse(sessionStorage.getItem("publishData"));
//     //.log(publishTemplate, 'publishTemplate')

//     this.setState(
//       {
//         userInfo: data.user_data,
//         client_info: data.client_info[0],
//         configData: data.config_data,
//         db_info: db_info,
//         repeatModeData: data.config_data.repeat_mode,
//         quater_months : data.config_data.quater_months,
//         half_months : data.config_data.half_months,
//         dataloaded: false,
//         total_weeks:data.config_data.total_weeks,
//         publishTemplate,
//         height: window.innerHeight,
//         calendar_type:data.config_data.calendar_type,
//         dayStartNumber: this.generateNumber(1),
//         dayEndNumber: this.generateNumber(2),
//         hlevelIsSelected: false,
//         allowed_audit_monthy : data.config_data.allowed_audit_monthy,
//         financial_quater_months:data.config_data.financial_quater_months,
//         financial_half_months : data.config_data.financial_half_months,
//         allowed_audit_yearly : data.config_data.allowed_audit_yearly,
//         total_months : data.config_data.total_months
//       },
//       async function () {
//          await this.getTemplateMethod()
//         this.getDateFormateMethod()
//         this.loadUserLabels()
//       }
//     )




//   }

//   getEndpointsData = () => {
//     try {
//       urlSocket.post("webphlbconf/onchangeaudituserlevel", {
//         userInfo: {
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//           encrypted_db_url: this.state.db_info.encrypted_db_url
//         },
//         hInfo: {
//           h_id: this.state.hirerachyData._id,
//           publishtemplateInfo: this.state.publishTemplate
//         }
//       })
//         .then(response => {
//           console.log(response,'response')
//           //.log(response, 'response')
//           this.setState({ publishTemplate: response.data.data }, () => { this.updatePublishTemplateData() })
//         })
//     }
//     catch (error) {

//     }
//   }

//   async componentDidMount() {

//     this.getDidmountFunction()

//     var data = JSON.parse(sessionStorage.getItem("authUser"));
//     var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
//     var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//     // var hirerachy_user_level = data.config_data.hirerachy_user_level
//     // var hirerachy_user_level = await this.retriveExistCategory()
//     // //.log(hirerachy_user_level,'hirerachy_user_level')
//     data.config_data.facilities.map((ele, idx) => {
//       if (ele.id === "7") {
//         data.config_data.facilities[idx].enabled = true
//         this.setState({ permissionsadded: [data.config_data.facilities[idx]] }, () => {
//         })
//       }
//     })
//     var protect_routes = await facility(data.facility_data, 'mngpblhtempt')
//     if (protect_routes !== undefined) {
//       this.setState(
//         {
//           sessionUserInfo: data.user_data,
//           configData: data.config_data,
//           dataloaded: true,
//           db_info: db_info,
//           client_info: client_info,
//           daysOfWeek : data.config_data.days_of_week,
//           quater_periodicity: data.config_data.periodicity_quater,
//           periodicity_half_yearly: data.config_data.periodicity_half_yearly
//           // hirerachy_user_level

//         },
//         function () {
//           // this.retriveExistCategory()
//           //    this.getEntityDetaitedData()
//         }
//       )
//     }
//     else {
//       this.props.history.push('/dashboard')
//     }
//   }

//    retriveExistCategory = (hierarchy_id) => {
//     return new Promise((resolve, reject) => {
//       const db_info = JSON.parse(sessionStorage.getItem("db_info"));
  
//       try {
//         urlSocket.post('cog/retrive-categories', {
//           encrypted_db_url: db_info.encrypted_db_url,
//           hierarchy_id :hierarchy_id
//         }).then((response) => {
//           console.log(response,'response')
//           //.log(response, 'response')
//           if (response.data.response_code === 500) {
//             this.setState({hirerachy_user_level : response.data.data[0].hirerachy_user_level})
//             // resolve(response.data.data[0].hirerachy_user_level);
//           } else {
//             // resolve(null);
//           }
//         }).catch((error) => {
//           //.error(error, 'error');
//           reject(error);
//         });
//       } catch (error) {
//         //.error(error, 'error');
//         reject(error);
//       }
//     });
//   }
  

//   generateNumber(startNumber) {
//     var genNumber = []
//     for (var i = startNumber; i <= 31; i++) {
//       genNumber.push({
//         name: String(i),
//         value: String(i)
//       })
//     }
//     return genNumber
//   }

//   async endpointMethod(method) {
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.method_selected = method
//     this.setState({
//       methodSelected: method,
//       dataloaded: false,
//       publishTemplate
//     },async function () {
//     await this.updatePublishTemplateData()
//       if (method === "2") {
//         this.gethlevelMethod()
//       }
//       else {
//         this.getCustomMethod()
//       }
//     })
//   }

//   resetEndpointMethod() {

//     urlSocket.post("webphlbconf/resettemplate", {
//       userInfo: {
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//       },
//       publishtemplateInfo: this.state.publishTemplate
//     })
//       .then(response => {
//         if (response.data.response_code == 500) {
//           this.setState({
//             publishTemplate: response.data.data,
//             changeMethodConfirmation: false,
//             methodSelected: response.data.data.method_selected,
//             cc_level: response.data.data.cc_level,
//             dataloaded: response.data.data.method_selected === "0" ? true : false
//           })
//         }
//       })

//   }

//   async getTemplateMethod() {
//     urlSocket.post("webphlbconf/gettemplate", {
//       userInfo: {
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//       },
//       publishtemplateInfo: this.state.publishTemplate
//     })
//       .then(response => {
//         console.log(response,'response')
//         //.log(response,'response')
//         if (response.data.response_code == 500) {

//           this.setState({
//             publishTemplate: response.data.data,
//             methodSelected: response.data.data.method_selected,
//             cc_level: response.data.data.cc_level,
//             dataloaded: response.data.data.method_selected === "0" ? true : false
//           },
//             function () {
//               if(response.data.data.hlevel_id !== undefined && response.data.data.hlevel_id !== null){
//                 this.retriveExistCategory(response.data.data.hlevel_id)
//               }
//               if (response.data.data.method_selected === "2") {
//                 this.gethlevelMethod()
//               }
//               else if (response.data.data.method_selected === "1") {
//                 this.getCustomMethod()
//               }
              
//             }
//           )

//         }
//       })
//   }


//   loadUserLabels() {
//     try {
//       urlSocket.post("userSettings/get-user-group", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           _id: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id
//         }
//       })
//         .then(response => {
//           this.setState({
//             labelData: response.data.data,

//           })
//         })

//     } catch (error) {
//     }
//   }








//   getCustomMethod() {
//     urlSocket.post("webphlbconf/gethlmethod", {
//       userInfo: {
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//       },
//       publishtemplateInfo: this.state.publishTemplate
//     })
//       .then(response => {
//         console.log(response,'response')
//         if (response.data.response_code == 500) {

//           var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })
//           var wkdays = response.data.data.data.repeat_mode_config

//           var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]
//           var getHour = getTimeFromEvent.slice(0, 2)
//           var getMinTime = String(parseInt(getHour + 1)) + "00"

//           this.setState({
//             publishTemplate: response.data.data.data,
//             cc_level: response.data.data.data.cc_level,
//             selectedRadioOption: this.state.publishTemplate.repeat_mode_config.based_on,
//             occurence: this.state.publishTemplate.repeat_mode_config.no_of_occurance,
//             selected_quater_months: response.data.data.data.repeat_mode === "Quaterly" ?response.data.data.data.repeat_mode_config.selected_quater_months :[] ,
//             selected_half_months: response.data.data.data.repeat_mode === "Half-Yearly" ? response.data.data.data.repeat_mode_config.selected_half_yearly_months :[],
//             dataSource: response.data.data.hlevelData,
//             auditUserlevelSelected: true,
//             customEnableReview: response.data.data.data.enable_review,
//             reviewUserlevelSelected: false,
//             endpoints: response.data.data.data.endpoints,
//             ptl_startdate: response.data.data.data.start_date,
//             ptl_enddate: response.data.data.data.end_date,
//             repeat_mode: response.data.data.data.repeat_mode,
//             rm_config: response.data.data.data.repeat_mode_config,
//             daily_endtime: response.data.data.data.repeat_mode_config.end_time,
//             daily_starttime: response.data.data.data.repeat_mode_config.start_time,
//             getMinTime,
//             selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',
//             weekDays: response.data.data.data.repeat_mode_config.days,
//             dsf: wkdays.start_on,
//             eoem: wkdays.ends_on,
//             mstartoption: wkdays.mstartoption,
//             mendoption: wkdays.mendoption,
//             dayStartNumber: this.generateNumber(1),
//             dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
//             // dataloaded: true,
//           }, function () {
//             this.getAllUsers()
//           })
//         }
//       })
//   }

//   async gethlevelMethod() {
//     urlSocket.post("webphlbconf/gethlmethod", {
//       userInfo: {
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//       },
//       publishtemplateInfo: this.state.publishTemplate
//     })
//       .then(response => {
//         //.log(response, 'response')
//         console.log(response,'response')
//         if (response.data.response_code == 500) {

//           var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })
//           var wkdays = response.data.data.data.repeat_mode_config

//           var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]
//           var getHour = getTimeFromEvent.slice(0, 2)
//           var getMinTime = String(parseInt(getHour + 1)) + "00"
//           //.log(getSelectedHlevelIndex,'getSelectedHlevelIndex')
//           if(getSelectedHlevelIndex == -1){
//             response.data.data.data["hlevel_id"]=null
//             response.data.data.data["hlevel"]=null
//             response.data.data.data["hirearchy_type"]=null
//             response.data.data.data["ep_selected"]=[]
//             response.data.data.data["cc_level"]=0



//             // this.state.publishTemplate.hirearchy_type

//           }
//           _.each(response.data.data.data.endpoints, item => {
//                   if (response.data.data.data.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                     item["rowValid"] = false
//                   }
//                   else if (!response.data.data.data.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//                     item["rowValid"] = false
//                   }
//                   else {
//                     item["rowValid"] = true
//                   }
//                 })


//           if (response.data.data.data.hlevel != null) {


//             var getUniqueRecords = _.uniqBy(response.data.data.endpointData, el => `${el.hlevel}`)
//             var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.ep_level })
//             var getAuditUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.audit_userlevel })
//             var getReviewUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.review_userlevel })
//             var convertedFlatData
//             if (response.data.data.data.ep_structure.length === 0) {
//               console.log("if")
//               convertedFlatData = this.convertFlatDataToTreeData(response.data.data.endpointData)
//             }
//             else {
//               console.log("else",response,response.data.data.data.ep_structure)
//               convertedFlatData = response.data.data.data.ep_structure

//               if (response.data.data.data.hirearchy_type == "2" && response.data.data.data.ep_structure.length !==0) {
//                 this.getAllTreeStructure()
//               }
//               else {
//                 this.handleRecursive(convertedFlatData)
//                 //.log(convertedFlatData, 'convertedFlatData')

//               }
//             }
//             console.log(response.data.data.hstructure,'response.data.data.hstructure')
//             sessionStorage.setItem("hstructure", JSON.stringify(response.data.data.hstructure));
//             this.setState({
//               tree_flat:response.data.data.hstructure,
//               publishTemplate: response.data.data.data,
//               cc_level: response.data.data.data.cc_level,
//               dataSource: response.data.data.hlevelData,
//               selectedHLevelValue: String(getSelectedHlevelIndex + 1),
//               hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,
//               hlevelData: getUniqueRecords,
//               hirerachyData: response.data.data.hlevelData[getSelectedHlevelIndex],
//               selectedEndpoint: endpointIndex != -1 ? String(endpointIndex + 1) : "choose",
//               endpointSelected: endpointIndex != -1 ? true : false,
//               hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',
//               locationData: convertedFlatData,
//               checked: response.data.data.data.ep_selected,
//               checkedKeys: response.data.data.data.ep_selected,
//               expanded: response.data.data.data.ep_expanded,
//               selectedAuditUserlevel: getAuditUserlevelIndex != -1 ? String(getAuditUserlevelIndex + 1) : "choose",
//               audit_userlevel: response.data.data.data.audit_userlevel,
//               review_userlevel: response.data.data.data.review_userlevel,
//               auditUserlevelSelected: getAuditUserlevelIndex != -1 ? true : false,
//               enableReview: response.data.data.data.enable_review,
//               selectedReviewUserlevel: getReviewUserlevelIndex != -1 ? String(getReviewUserlevelIndex + 1) : "choose",
//               reviewUserlevelSelected: getReviewUserlevelIndex != -1 ? true : false,
//               endpoints: response.data.data.data.endpoints,
//               ptl_startdate: response.data.data.data.start_date,
//               ptl_enddate: response.data.data.data.end_date,
//               repeat_mode: response.data.data.data.repeat_mode,
//               rm_config: response.data.data.data.repeat_mode_config,
//               selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',
//               daily_endtime: response.data.data.data.repeat_mode_config.end_time,
//               daily_starttime: response.data.data.data.repeat_mode_config.start_time,
//               getMinTime,
//               weekDays: response.data.data.data.repeat_mode_config.days,
//               dsf: wkdays.start_on,
//               eoem: wkdays.ends_on,
//               mstartoption: wkdays.mstartoption,
//               mendoption: wkdays.mendoption,
//               dayStartNumber: this.generateNumber(1),
//               dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
//               // dataloaded: false,
//             }, function () {
//               this.getAllUsers()
//             })
//           }
//           else {
//             this.setState({
//               publishTemplate: response.data.data.data,
//               cc_level: response.data.data.data.cc_level,
//               dataSource: response.data.data.hlevelData,
//               selectedHLevelValue: getSelectedHlevelIndex != -1 ? String(getSelectedHlevelIndex + 1) : "0",
//               hlevelIsSelected: false,
//               hlevelData: [],
//               selectedEndpoint: "choose",
//               endpointSelected: false,
//               hlevelSelected: '',
//               locationData: [],
//               selectedAuditUserlevel: 'choose',
//               audit_userlevel: response.data.data.data.audit_userlevel,
//               review_userlevel: response.data.data.data.review_userlevel,
//               auditUserlevelSelected: false,
//               enableReview: false,
//               selectedReviewUserlevel: 'choose',
//               reviewUserlevelSelected: false,
//               endpoints: response.data.data.data.endpoints,
//               ptl_startdate: response.data.data.data.start_date,
//               ptl_enddate: response.data.data.data.end_date,
//               repeat_mode: null,
//               rm_config: response.data.data.data.repeat_mode_config,
//               daily_endtime: response.data.data.data.repeat_mode_config.end_time,
//               daily_starttime: response.data.data.data.repeat_mode_config.start_time,
//               getMinTime,
//               selectedRepeatMode: 'choose',
//               weekDays: response.data.data.data.repeat_mode_config.days,
//               dsf: wkdays.start_on,
//               eoem: wkdays.ends_on,
//               mstartoption: wkdays.mstartoption,
//               mendoption: wkdays.mendoption,
//               dayStartNumber: this.generateNumber(1),
//               dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
//               // dataloaded: false,
//             }, function () {
//               this.getAllUsers()
//             })
//           }
//         }
//       })
//   }



//   handleRecursive = (epTreedata) => {
//     return epTreedata.map((item) => {
//       if (item.children !== undefined && item.children !== null) {
//         item["ep"] = true;
//         item.children = this.handleRecursive(item.children);
//       }
//       return item;
//     }).filter((item) => {
//       // Filter out elements with null children and ep_level not matching
//       return item.children !== null || this.state.publishTemplate.ep_level === item.ep_level;
//     });
//   }

//   async getAllUsers() {
//     try {
//       urlSocket.post("webphlbconf/getallusers", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//         },
//         publishtemplateInfo: this.state.publishTemplate
//       })
//         .then((response) => {
//           console.log(response,'response')
//           this.setState({
//             userdata: response.data.data,
//             dataloaded: true
//           })
//         })
//     } catch (error) {
//       console.log(error,'error')
//     }
//   }


//   getHStructureListData = () => {
//     try {

//       urlSocket.post("webphlbconf/gethirhylevels", {
//         userInfo: {
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//         },
//         publishtemplateInfo: this.state.publishTemplate
//       })
//         .then((response) => {
//           var getSelectedHlevelIndex = _.findIndex(response.data.data, { hname: this.state.publishTemplate.hlevel })
//          //.log(getSelectedHlevelIndex,'getSelectedHlevelIndex')
//           this.setState({
//             dataSource: response.data.data,
//             selectedHLevelValue: String(getSelectedHlevelIndex + 1),
//             hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,
//             dataloaded: true,
//           })
//           if (getSelectedHlevelIndex !== -1) {
//           }
//         })
//     } catch (error) {
//     }
//   }

//   toggleTab(tab) {
//     if (this.state.activeTab !== tab) {
//       if (tab === 2) {
//         this.setState({ auditUserlevelSelected: false })
//         setTimeout(() => {
//           this.setState({ auditUserlevelSelected: true })
//         }, 10)
//       }
//       if (tab >= 1 && tab <= 4) {
//         this.setState({
//           activeTab: tab,
//         })
//       }
//     }
//   }

//   toggleCustomTab(tab) {
//     if (this.state.activeCustomTab !== tab) {

//       if (tab >= 1 && tab <= 4) {
//         this.setState({
//           activeCustomTab: tab,
//         })
//       }
//     }
//   }

//   handleSelectGroup = selectedGroup => {
//     this.setState({ selectedGroup })
//   }

//   selectLevel = (event) => {

//     var hirerachyData = this.state.dataSource[parseInt(event.target.value) - 1] //JSON.parse(event.target.value)
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.hlevel = hirerachyData.hname
//     publishTemplate.hlevel_id = hirerachyData._id
//     publishTemplate.ep_level = null
//     publishTemplate.ep_structure = []
//     publishTemplate.eplevel_value = null
//     publishTemplate.ep_selected = []
//     publishTemplate.ep_expanded = []
//     publishTemplate.endpoints = []
//     publishTemplate.audit_userlevel = null
//     publishTemplate.audit_userlevel_value = null
//     publishTemplate.enable_review = false
//     publishTemplate.review_userlevel = null
//     publishTemplate.review_userlevel_value = null
//     publishTemplate.start_date = null
//     publishTemplate.end_date = null
//     publishTemplate.frequency = "One time"
//     publishTemplate.hirearchy_type = null

//     this.setState({
//       checked: [],
//       selectedAuditUserlevel: 'choose',
//       auditUserlevelSelected: false,
//       checkedKeys: [],
//       publishTemplate
//     },()=>{

//     if (this.state.endpointSelected) 
//     // document.getElementById("location").value = "choose"

//     this.updateCCLevel()
//     try {
//       urlSocket.post("webphlbconf/getlocationlevels", {
//         userInfo: {
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//           encrypted_db_url: this.state.db_info.encrypted_db_url
//         },
//         hInfo: {
//           h_id: hirerachyData._id,
//           publishtemplateInfo: this.state.publishTemplate
//         }
//       })
//         .then(response => {
//           if (response.data.response_code == 500) {
//             var getUniqueRecords = _.uniqBy(response.data.data, el => `${el.hlevel}`)
//             var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: this.state.publishTemplate.ep_level })
//             this.setState({
//               cc_level: 0,
//               hlevelData: getUniqueRecords,
//               hirerachyData,
//               selectedEndpoint: 'choose',
//               endpointSelected: false,
//               hlevelIsSelected: true,
//               hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',
//               ptl_startdate: null,
//               ptl_enddate: null,
//               ptl_frequency: "One time",
//             },()=>{this.retriveExistCategory(hirerachyData._id);this.gethlevelMethod()})
//           }
//         })
//     } catch (error) {
//     }
//   })
//   }


//   selectLocation(event) {

//     var endpointInfo = this.state.hlevelData[parseInt(event.target.value) - 1]
//     console.log(endpointInfo,'endpointInfo')
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.ep_level = endpointInfo.hlevel
//     publishTemplate.eplevel_value = endpointInfo.hlevel_value
//     publishTemplate.ep_structure = []
//     publishTemplate.ep_selected = []
//     publishTemplate.ep_expanded = []
//     publishTemplate.audit_userlevel = null
//     publishTemplate.audit_userlevel_value = null
//     publishTemplate.enable_review = false
//     publishTemplate.review_userlevel = null
//     publishTemplate.review_userlevel_value = null
//     publishTemplate.start_date = null
//     publishTemplate.end_date = null
//     publishTemplate.frequency = "One time"
//     sessionStorage.setItem('hlevel',endpointInfo.hlevel)

//     this.setState({ checked: [], expanded: [], selectedEndpoint: event.target.value, checkedKeys: [],publishTemplate },()=>{
//     this.updateCCLevel()
//     urlSocket.post("webphlbconf/getlocations", {
//       userInfo: {
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//         encrypted_db_url: this.state.db_info.encrypted_db_url
//       },
//       hInfo: {
//         h_id: this.state.hirerachyData._id,
//         hlevelinfo: endpointInfo,
//         publishtemplateInfo: this.state.publishTemplate
//       }
//     })
//       .then(response => {
//         console.log(response,'response')
//         if (response.data.response_code == 500) {
//           var convertedFlatData = this.convertFlatDataToTreeData(response.data.data)
//           console.log(response.data.data,'response.data.data')
//           var publishTemplate ={...this.state.publishTemplate}
//           publishTemplate.ep_structure = convertedFlatData
//           this.handleRecursive(convertedFlatData)
//           // this.setState({
//           //   locationData: convertedFlatData,
//           // },
//           //   function () {
//           //     this.updateCheckedepStructure()
//           //   }
//           setTimeout(()=>{
//             this.setState({locationData: convertedFlatData, publishTemplate},()=>(this.updateCheckedepStructure()))
//           },100);
        
//         }
//       })

//     this.setState({
//       endpointSelected: true,
//       hlevelSelected:
//         this.state.hlevelData[parseInt(event.target.value) - 1].hlevel,
//       selectedAuditUserlevel: 'choose',
//       auditUserlevelSelected: false,
//       enableReview: false,
//       selectedReviewUserlevel: 'choose',
//       reviewUserlevelSelected: false,
//       cc_level: 0,
//       ptl_startdate: null,
//       ptl_enddate: null,
//       ptl_frequency: "One time",
//     })
//   })
//   }

//   convertFlatDataToTreeData = (arr) => {

//     var tree = [],
//       mappedArr = {},
//       arrElem,
//       mappedElem;

//     // First map the nodes of the array to an object -> create a hash table.
//     for (var i = 0, len = arr.length; i < len; i++) {
//       arrElem = {
//         "value": arr[i]._id,
//         "label": arr[i].hlevel_name,
//         "children": arr[i].children,
//         "id": arr[i].id,
//         "parent": arr[i].parent,
//         "node_id": arr[i]._id,
//         "ep_level": arr[i].hlevel,
//         "ep_level_value": arr[i].hlevel_value,
//         "user_path": arr[i].user_path,
//         "unique_users": arr[i].unique_users

//       }
//       mappedArr[arrElem.id] = arrElem;
//       mappedArr[arrElem.id]['children'] = []
//     }


//     for (var id in mappedArr) {
//       if (mappedArr.hasOwnProperty(id)) {
//         mappedElem = mappedArr[id];

//         // If the element is not at the root level, add it to its parent array of children.
//         if (mappedElem.parent) {
//           // //.log(mappedElem,'mappedElem',mappedArr,mappedArr[mappedElem['parent']])
//           if (mappedArr[mappedElem['parent']] !== undefined) {
//             mappedArr[mappedElem['parent']]['children'].push(mappedElem);
//           }
//         }
//         // If the element is at the root level, add it to first level elements array.
//         else {
//           tree.push(mappedElem);
//         }
//       }
//     }

//     let update = () => obj => {
//       if (obj.children.length > 0)
//         obj.children.forEach(update());
//       else
//         obj.children = null
//     };

//     tree.forEach(update());
//     return tree;
//   }

//   handleEndpointSelection = (checked, event) => {
//     var publishTemplate ={...this.state.publishTemplate}
//     this.setState({ checkedKeys: checked, cc_level: checked.length > 0 ? 1 : 0 }, async function () {
//       publishTemplate.ep_structure = this.state.locationData
//       publishTemplate.ep_selected = checked
//       publishTemplate["cc_level"] = checked.length > 0 ? 1 : 0
//       this.updateCheckedepStructure("allow")
//       this.setState({
//         publishTemplate
//       })
//     });
//   }

//   handleEndpointExpanded = expanded => {
//     var publishTemplate ={...this.state.publishTemplate}
//     this.setState({ expanded }, function () {
//       publishTemplate.ep_structure = this.state.locationData
//       publishTemplate.ep_expanded = expanded
//       this.updateCheckedepStructure()
//       this.setState({
//         publishTemplate
//       })
//     })
//   }

//   updateCheckedepStructure(access) {
//     this.updateCCLevel()
//     try {
//       urlSocket.post("webphlbconf/updateepstructure", {
//         userInfo: {
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//           encrypted_db_url: this.state.db_info.encrypted_db_url
//         },
//         hInfo: {
//           publishtemplateInfo: this.state.publishTemplate
//         }
//       })
//         .then(response => {
//           console.log(response,'response')
//           //.log(response,'response')
//           if (access == "allow" && this.state.publishTemplate.hirearchy_type !== "2") {
//             this.getEndpointsData()
//           }
//           // else {
//           //   console.log('elseeeee',this.state.dataSource, this.state.selectedHLevelValue)
//           //   var getSelectedHlevelIndex = _.findIndex(this.state.dataSource, { hname: this.state.publishTemplate.hlevel })
//           //   this.setState({locationData: this.state.locationData, dataloaded: false , selectedHLevelValue : String(getSelectedHlevelIndex +1)},()=>{this.updateCCLevel()})
            
//           // }
//           else {
//             this.updateCCLevel()
//             this.updatePublishTemplateData()
//             var getSelectedHlevelIndex = _.findIndex(this.state.dataSource, { hname: this.state.publishTemplate.hlevel })
//             this.setState({locationData:this.state.locationData,dataloaded:false ,selectedHLevelValue : String(getSelectedHlevelIndex +1)},()=>{this.updateCCLevel()})
//           }
//         })
//     } catch (error) {

//     }
//   }

//   selectAuditUserlevel(event) {
//     var endpoints = this.state.publishTemplate.endpoints
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.start_date = null
//     publishTemplate.end_date = null
//     publishTemplate.frequency = "One time"

//     var selected_audit_type = event.target.value
//     if (this.state.publishTemplate.hirearchy_type !== "2") {
//       if (this.state.publishTemplate.review_userlevel !== event.target.value) {
//         publishTemplate.audit_userlevel = event.target.value
//         publishTemplate.audit_userlevel_value = Number(event.target.value)
//         this.setState({
//           publishTemplate,
//           selectedAuditUserlevel: event.target.value,
//           audit_userlevel: event.target.value == "1" ? "Auditor" : event.target.value == "2" ? "Reviewer" : event.target.value == "3" ? "External Auditer" : null,
//         },()=>{
        

//         try {
//           urlSocket.post("webphlbconf/onchangeaudituserlevel", {
//             userInfo: {
//               created_by: this.state.userInfo._id,
//               company_id: this.state.userInfo.company_id,
//               encrypted_db_url: this.state.db_info.encrypted_db_url
//             },
//             hInfo: {
//               h_id: this.state.hirerachyData._id,
//               publishtemplateInfo: this.state.publishTemplate,
//               selected_audit_type: event.target.value
//             }
//           })
//             .then(response => {
//               //.log(response,'response')
//               if (response.data.response_code === 500) {
//                 // _.each(response.data.data.endpoints, item => {
//                 //   if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                 //     item["rowValid"] = false
//                 //   }
//                 //   else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//                 //     item["rowValid"] = false
//                 //   }
//                 //   else {
//                 //     item["rowValid"] = true
//                 //   }
//                 // })
//                 publishTemplate.endpoints = response.data.data.endpoints
//                 publishTemplate = response.data.data
//                 this.setState({
//                   endpoints: response.data.data.endpoints, endpointsAvailable: true,
//                   publishTemplate,
//                   enableReview: response.data.data.enable_review,
//                   // selectedReviewUserlevel: 'choose',
//                   auditUserlevelSelected: true,
//                   // cc_level : 
//                   // reviewUserlevelSelected: false,
//                   cc_level: response.data.data.cc_level,
//                   ptl_startdate: null,
//                   ptl_enddate: null,
//                   ptl_frequency: "One time",
//                   auditUserlevelSelected: true,
//                 }, () => { this.updatePublishTemplateData() })
//               }
//             })
//         } catch (error) {
//         }
//       })
//       }
//       else {
//         publishTemplate["audit_userlevel"] = this.state.publishTemplate["audit_userlevel"]
//         publishTemplate["audit_userlevel_value"] = this.state.publishTemplate["audit_userlevel_value"]
//         this.setState({ dataAlert: true, publishTemplate,alertMessage: "This user is already selected to Reviewe. Try different user." })
//       }
//     }
//     else {
//       if (this.state.publishTemplate.review_userlevel !== event.target.value) {
//         publishTemplate.audit_userlevel = event.target.value
//         publishTemplate.audit_userlevel_value = Number(event.target.value)
//         this.setState({
//           selectedAuditUserlevel: event.target.value,
//           audit_userlevel: event.target.value == "1" ? "Auditer" : event.target.value == "2" ? "Reviewer" : event.target.value == "3" ? "External Auditer" : null,
//           auditUserlevelSelected: false,
//           publishTemplate,
//           endpoints: this.state.publishTemplate.endpoints
//         },()=>{
//         urlSocket.post('webphlbconf/update-unorganised-hierarchy-data-auditor', {
//           userInfo: {
//             created_by: this.state.userInfo._id,
//             company_id: this.state.userInfo.company_id,
//             encrypted_db_url: this.state.db_info.encrypted_db_url
//           },
//           hInfo: {
//             h_id: this.state.hirerachyData._id,
//             publishtemplateInfo: this.state.publishTemplate,
//             selected_audit_type: event.target.value
//           }
//         }).then((response) => {
//           if (response.data.response_code == 500) {
//             _.each(response.data.data.publishtemplateInfo.endpoints, item => {
//               if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                 item["rowValid"] = false
//               }
//               else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//                 item["rowValid"] = false
//               }
//               else {
//                 item["rowValid"] = true
//               }
//             })
//             this.setState({
//               endpoints: response.data.data.publishtemplateInfo.endpoints,
//               endpointsAvailable: true,
//               publishTemplate: response.data.data.publishtemplateInfo,
//               ptl_startdate: null,
//               ptl_enddate: null,
//               ptl_frequency: "One time",
//               auditUserlevelSelected: true,
//             }, () => { this.updatePublishTemplateData() })
//           }
//         })
//       }
//       )
//       }

//       else {
//         publishTemplate["audit_userlevel"] = this.state.publishTemplate["audit_userlevel"]
//         publishTemplate["audit_userlevel_value"] = this.state.publishTemplate["audit_userlevel_value"]
//         this.setState({ dataAlert: true,publishTemplate, alertMessage: "This user is already selected to Reviewe. Try different user." })
//       }

//     }

//   }


//    appendUsers=(template_data, count)=> {
//     console.log(template_data, count,'template_data, count')
//     try {
//       if (template_data.endpoints.length > count) {
//         var auditor_data = _.filter(template_data.endpoints[count].user_path, {
//           cat_type: "2",
//         });
//                       // var getIndex = _.findIndex(template_data.endpoints[count].adt_users)
//         console.log(auditor_data.length, 'auditor_data');
//         if (auditor_data.length > 0 && auditor_data.length < 2) {
//           auditor_data[0]["audit_type"]= "2"
//           var getIndex = _.findIndex(template_data.endpoints[count].adt_users,{user_id : auditor_data[0].user_id})
//           console.log(getIndex,'getIndex')
//           if(getIndex === -1){
//           template_data.endpoints[count].adt_users.push(auditor_data[0]);
//           }
//           // else{
//           //   template_data.endpoints[count].adt_users[getIndex] = auditor_data[0]

//           // }
//         }
//         else{

//         }
//         count++;
//         // Use return to propagate the result of the recursive call
//         return this.appendUsers(template_data, count);
//       } else {
//         console.log("loop over", template_data.endpoints);
  
//         // Return the result here
//         return template_data;
//       }
//     } catch (error) {
//       console.log(error,'error')
//     }
//   }









//   onchangeCustomEnableReview =async (e) => {
//     this.setState({ auditUserlevelSelected: false })
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.enable_review = e.target.checked
//     var checkedData = e.target.checked
//     console.log(publishTemplate,'publishTemplate')
//     _.each(publishTemplate.endpoints, item => {
//       if (checkedData && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//         item["rowValid"] = false
//       }
//       else if (!checkedData && !_.some(item.adt_users, { audit_type: "1" })) {
//         item["rowValid"] = false
//       }
//       else{
//         item["rowValid"] = false
//       }
//     })
//     if(publishTemplate.enable_review === true){
//      var updated_data= await this.appendUsers(publishTemplate,0)
//      console.log(updated_data,'updated_data')
//     }



//     setTimeout(() => {
//       this.setState({
//         customEnableReview: checkedData,
//         auditUserlevelSelected: true,
//         publishTemplate
//       },async()=>{
//         // await this.retriveDefaultReviewer(this.state.publishTemplate)
//         this.updatePublishTemplateData()
//       })
//     }, 10)  
//   }


//   retriveDefaultReviewer=(publishTemplate)=>{

//     try {
//       urlSocket.post("webphlbconf/retrive-default-reviewer",{
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//         },
//         publishtemplateInfo: publishTemplate
//       }).then((response)=>{
//         console.log(response,'response')
//         if(response.data.response_code === 500){
//           this.setState({
//             publishTemplate: response.data.data,
//             endpoints : response.data.data.endpoints
//           },()=>{
//             this.updatePublishTemplateData()
//           })
//         } 
//       })
//     } catch (error) {
      
//     }


//   }


//   onchangeEnableReview(e) {
//     this.setState({ auditUserlevelSelected: false })
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.enable_review = e.target.checked
//     publishTemplate.review_userlevel = null
//     publishTemplate.review_userlevel_value = null
//     publishTemplate.start_date = null
//     publishTemplate.end_date = null
//     publishTemplate.frequency = "One time"

//     var checkedData = e.target.checked
//     _.each(publishTemplate.endpoints, item => {

//       if (checkedData && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//         item["rowValid"] = false
//       }
//       else if (!checkedData && !_.some(item.adt_users, { audit_type: "1" })) {
//         // //.log(item,'item')
//         item["rowValid"] = false
//       }
//       else {
//         item["rowValid"] = true
//       }
//     })

//     setTimeout(() => {
//       this.setState({
//         enableReview: checkedData,
//         reviewUserlevelSelected: false,
//         selectedReviewUserlevel: 'choose',
//         ptl_startdate: null,
//         ptl_enddate: null,
//         ptl_frequency: "One time",
//         review_userlevel: null,
//         auditUserlevelSelected: true,
//         publishTemplate
//       })
//     }, 10)
//     if(checkedData == true){
//       this.setState({
//         publishTemplate
//       },()=>{
//         this.defaultSelectReviewUser("default")

//       })
//     }
//     else{
//       publishTemplate.endpoints.map((data,idx)=>{
//         // if()
//         var filtered_auditor_data = _.filter(data.adt_users, { audit_type: "1" })
//         publishTemplate["endpoints"][idx]["adt_users"]=filtered_auditor_data
//       })
//       //.log(this.state.publishTemplate,'publish')
//       this.setState({
//         publishTemplate
//       },()=>{
//         this.updatePublishTemplateData()
//       })
//     } 
   

   
//   }



//   defaultSelectReviewUser =(eventValue)=>{
//     this.setState({ auditUserlevelSelected: false })
//     var publishTemplate ={...this.state.publishTemplate}
//     if (eventValue == "default") {
//       publishTemplate.review_userlevel = "2"
//       publishTemplate.review_userlevel_value = 2
//       publishTemplate.start_date = null
//       publishTemplate.end_date = null
//       // this.state.publishTemplate.repeat_mode = null
//       try {
//         urlSocket.post("webphlbconf/onchangereviewuserlevel", {
//           userInfo: {
//             created_by: this.state.userInfo._id,
//             company_id: this.state.userInfo.company_id,
//             encrypted_db_url: this.state.db_info.encrypted_db_url
//           },
//           hInfo: {
//             h_id: this.state.hirerachyData._id,
//             publishtemplateInfo: publishTemplate,
//             selected_audit_type: undefined
//           }
//         })
//           .then(response => {
//             console.log(response,'response')
//             //.log(response, 'response')
//             if (response.data.response_code === 500) {
//               if (publishTemplate.enable_review) {
//                 _.each(response.data.data.endpoints, item => {
//                   if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                     item["rowValid"] = false
//                   }
//                   else {
//                     item["rowValid"] = true

//                   }
//                 })
//               }
//               this.setState({
//                 publishTemplate: response.data.data,
//                 selectedReviewUserlevel: eventValue,
//                 reviewUserlevelSelected: true,
//                 cc_level: response.data.data.cc_level,
//                 ptl_startdate: null,
//                 ptl_enddate: null,
//                 repeatMode: null,
//                 endpoints: response.data.data.endpoints,
//                 review_userlevel: eventValue,
//                 auditUserlevelSelected: true,
//               },()=>{this.updatePublishTemplateData()}
//               )
//             }
//           })
//       } catch (error) {
//       }
//     }



//   }






//   selectReviewUserlevel(event) {
//     this.setState({ auditUserlevelSelected: false })
//     // if (event !== "default") {
//       var eventValue = event.target.value
//     // }
//     var publishTemplate ={...this.state.publishTemplate}
//     if (this.state.publishTemplate.hirearchy_type !== "2") {
//        if (this.state.publishTemplate.audit_userlevel !== eventValue) {
//         this.updateCCLevel()
//         publishTemplate.review_userlevel = eventValue
//         publishTemplate.review_userlevel_value = Number(event.target.value)
//         publishTemplate.start_date = null
//         publishTemplate.end_date = null
//         publishTemplate.repeat_mode = null
//         try {
//           urlSocket.post("webphlbconf/onchangereviewuserlevel", {
//             userInfo: {
//               created_by: this.state.userInfo._id,
//               company_id: this.state.userInfo.company_id,
//               encrypted_db_url: this.state.db_info.encrypted_db_url
//             },
//             hInfo: {
//               h_id: this.state.hirerachyData._id,
//               publishtemplateInfo: publishTemplate,
//               selected_audit_type: eventValue
//             }
//           })
//             .then(response => {

//               this.setState({
//                 publishTemplate},()=>{
//               this.updateCCLevel()
//               if (response.data.response_code === 500) {
//                 if (publishTemplate.enable_review) {
//                   _.each(response.data.data.endpoints, item => {
//                     if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                       item["rowValid"] = false
//                     }
//                     else {
//                       item["rowValid"] = true

//                     }
//                   })
//                 }
//                 this.setState({
//                   publishTemplate: response.data.data,
//                   selectedReviewUserlevel: eventValue,
//                   reviewUserlevelSelected: true,
//                   cc_level: response.data.data.cc_level,
//                   ptl_startdate: null,
//                   ptl_enddate: null,
//                   repeatMode: null,
//                   endpoints: response.data.data.endpoints,
//                   review_userlevel: eventValue,
//                   auditUserlevelSelected: true,
//                 },
//                 )
//               }
//             })
//             })
//         } catch (error) {
//         }
//       }
//       else {
//         publishTemplate["review_userlevel"] = eventValue
//         publishTemplate["review_userlevel_value"] = this.state.publishTemplate["review_userlevel_value"]
//         this.setState({ dataAlert: true, publishTemplate,alertMessage: "This user is already selected to auditer. Try different user." })
//       }
//     }
//     else {
//       if (this.state.publishTemplate.audit_userlevel !== event.target.value) {
//         publishTemplate.review_userlevel = event.target.value
//         publishTemplate.review_userlevel_value = Number(event.target.value)
//         try {
//           urlSocket.post('webphlbconf/update-unorganised-hierarchy-data-reviewer', {
//             userInfo: {
//               created_by: this.state.userInfo._id,
//               company_id: this.state.userInfo.company_id,
//               encrypted_db_url: this.state.db_info.encrypted_db_url
//             },
//             hInfo: {
//               h_id: this.state.hirerachyData._id,
//               publishtemplateInfo: publishTemplate,
//               selected_audit_type: event.target.value
//             }
//           }).then((response) => {
//             if (response.data.response_code == 500) {
//               _.each(response.data.data.publishtemplateInfo.endpoints, item => {
//                 if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                   item["rowValid"] = false
//                 }
//                 else if (!publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//                   item["rowValid"] = false
//                 }
//                 else {
//                   item["rowValid"] = true
//                 }
//               })
//               this.setState({
//                 endpoints: response.data.data.publishtemplateInfo.endpoints,
//                 endpointsAvailable: true,
//                 publishTemplate: response.data.data.publishtemplateInfo,
//                 ptl_startdate: null,
//                 ptl_enddate: null,
//                 ptl_frequency: "One time",
//                 review_userlevel: eventValue,
//                 auditUserlevelSelected: true,
//                 reviewUserlevelSelected: true,
//                 selectedReviewUserlevel: eventValue,
//               }, () => { this.updatePublishTemplateData() })
//             }


//           })

//         } catch (error) {

//         }
//       }
//       else {
//         publishTemplate["review_userlevel"] = this.state.publishTemplate["review_userlevel"]
//         publishTemplate["review_userlevel_value"] = this.state.publishTemplate["review_userlevel_value"]
//         this.setState({ dataAlert: true,publishTemplate, alertMessage: "This user is already selected to auditer. Try different user." })
//       }


//     }


//   }








//   changeAuditUserHandler(event, rowData, idx, parameter) {
//     console.log(rowData,'rowData')
//     var publishTemplate ={...this.state.publishTemplate}
//     //.log(event.target.value, rowData, idx, parameter, 'event, rowData, idx,parameter', this.state.publishTemplate)
//     if (parameter === "1" && event.target.value !== "add") {
//       var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
//     }
//     else {
//       var checkUserAlreadyExist = -1
//     }
//     //.log(checkUserAlreadyExist, 'checkUserAlreadyExist')
//     if (checkUserAlreadyExist !== -1 && (this.state.customEnableReview || this.state.enableReview)) {
//       this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
//     }
//     else if (event.target.value === "add") {
//       this.setState({ modal: this.state.modal ? false : true,add_reviwer :false, component: "user", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData })
//     }
//     else if(event.target.value == "#import"){
//       console.log("import",rowData,this.state.userdata)
//       var filtered_users =_.differenceBy(this.state.userdata, rowData.unique_users, '_id');
//       console.log(filtered_users,'filtered_users')
//       this.setState({ open: this.state.open ? false : true,coloumn_type :"1", add_reviwer :false, component: "import_usr", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData ,to_import_users : filtered_users })
//     }
//     else if (event.target.value == "loadgroupusers") {
//       this.setState({ open: this.state.open ? false : true, component: "loadgroupusers", endpoint_data: rowData })

//     }

//     else {
//       this.setState({ auditUserlevelSelected: false })
//       var auditUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
//       var getIndex = _.findIndex(publishTemplate.endpoints, { _id: (rowData._id) })
//       var getUserIndex = _.findIndex(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//       //.log(auditUser, getIndex, getUserIndex, 'getUserIndex')
//       if (getUserIndex == -1) {
//         publishTemplate.endpoints[getIndex].adt_users.push({
//           user_id: auditUser._id,
//           name: auditUser.firstname,
//           designation: auditUser.designation,
//           user_code: auditUser.usercode,
//           cat_type: publishTemplate.audit_userlevel,
//           audit_type: "1"
//         })
//         var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//         var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

//         publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

//       }
//       else {
//         publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
//         publishTemplate.endpoints[getIndex].adt_users.push({
//           user_id: auditUser._id,
//           name: auditUser.firstname,
//           designation: auditUser.designation,
//           user_code: auditUser.usercode,
//           cat_type: publishTemplate.audit_userlevel,
//           audit_type: "1"
//         })
//         var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//         var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
//         publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

//       }
//       this.setState({
//         publishTemplate
//       },()=>{
//       //.log(this.state.publishTemplate, 'getIndex', this.state.cc_level)
//       // this.updateCCLevel()
//       this.updatePublishTemplateData()
//       setTimeout(() => {
//         this.setState({
//           endpoints: this.state.publishTemplate.endpoints,
//           auditUserlevelSelected: true,
//         })
//       }, 10)
//     })
//     }
//     this.setState({ user_type: 0 })

//   }

//   changeReviewUserHandler(event, rowData, idx, parameter) {
//     console.log(event, rowData, idx, parameter, 'event, rowData, idx, parameter')
//     var publishTemplate ={...this.state.publishTemplate}
//     if (parameter === "1") {
//       var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
//     }
//     else {
//       var checkUserAlreadyExist = -1
//     }

//     if (checkUserAlreadyExist !== -1) {
//       this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
//     }
//     else {
//       if (event.target.value === "add") {
//         this.setState({ modal: this.state.modal ? false : true, component: "user", add_reviwer: true, selected_index: idx, given_email: "", location_info: rowData })
//         // this.setState({ open: this.state.open ? false : true, component: "user", selected_index: idx })
//       }
//       else if (event.target.value == "#import") {
//         var filtered_users = _.differenceBy(this.state.userdata, rowData.unique_users, '_id');
//         console.log(filtered_users, 'filtered_users')
//         this.setState({ open: this.state.open ? false : true, coloumn_type: "2", add_reviwer: true, component: "import_usr", emptData: rowData, selected_index: idx, given_email: "", location_info: rowData, to_import_users: filtered_users })
//       }
//       else {
//         this.setState({ auditUserlevelSelected: false })

//         var reviewUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
//         var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })


//         var getUserIndex = _.findIndex(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

//         if (getUserIndex == -1) {
//           publishTemplate.endpoints[getIndex].adt_users.push({
//             user_id: reviewUser._id,
//             name: reviewUser.firstname,
//             designation: reviewUser.designation,
//             user_code: reviewUser.usercode,
//             cat_type: publishTemplate.review_userlevel,
//             review_user: true,
//             audit_type: "2"
//           })
//           var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//           var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
//         }
//         else {
//           publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
//           publishTemplate.endpoints[getIndex].adt_users.push({
//             user_id: reviewUser._id,
//             name: reviewUser.firstname,
//             designation: reviewUser.designation,
//             user_code: reviewUser.usercode,
//             cat_type: publishTemplate.review_userlevel,
//             review_user: true,
//             audit_type: "2"
//           })
//           var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//           var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

//         }
//         _.each(publishTemplate.endpoints, item => {
//           // //.log(!_.some(item.adt_users, { audit_type: "1"})
//           if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//             item["rowValid"] = false
//           }
//           else if (!publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//             item["rowValid"] = false
//           }
//           else {
//             item["rowValid"] = true
//           }
//         })
//         this.setState({
//           publishTemplate
//         },()=>{
//         this.updatePublishTemplateData()
//         setTimeout(() => {
//           this.setState({
//             endpoints:this.state.publishTemplate.endpoints,
//             auditUserlevelSelected: true,
//           })

//         }, 10)
//       })
//       }
//     }
//     this.setState({ user_type: 1 })
//     // this.updateCCLevel()

//   }

//   // changeReviewUserHandler(event, rowData, idx, parameter) {
//   //   console.log(event, rowData, idx, parameter,'event, rowData, idx, parameter')
//   //   var publishTemplate ={...this.state.publishTemplate}
//   //   if (parameter === "1") {
//   //     var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
//   //   }
//   //   else {
//   //     var checkUserAlreadyExist = -1
//   //   }
//   //   console.log(checkUserAlreadyExist,'checkUserAlreadyExist')
//   //   if (checkUserAlreadyExist !== -1) {
//   //     this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
//   //   }
//   //   else {
//   //     if (event.target.value === "add") {
//   //       this.setState({ modal: this.state.modal ? false : true, component: "user",add_reviwer:true, selected_index: idx , given_email :"" ,location_info :rowData })
//   //       // this.setState({ open: this.state.open ? false : true, component: "user", selected_index: idx })
//   //     }
//   //     else if(event.target.value == "#import"){
//   //       var filtered_users =_.differenceBy(this.state.userdata, rowData.unique_users, '_id');
//   //       console.log(filtered_users,'filtered_users')
//   //       this.setState({ open: this.state.open ? false : true,coloumn_type :"2", add_reviwer :true, component: "import_usr", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData ,to_import_users : filtered_users })
//   //     }
//   //     else {
//   //       this.setState({ auditUserlevelSelected: false })

//   //       var reviewUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
//   //       var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })


//   //       var getUserIndex = _.findIndex(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

//   //       if (getUserIndex == -1) {
//   //         publishTemplate.endpoints[getIndex].adt_users.push({
//   //           user_id: reviewUser._id,
//   //           name: reviewUser.firstname,
//   //           designation: reviewUser.designation,
//   //           user_code: reviewUser.usercode,
//   //           cat_type: publishTemplate.review_userlevel,
//   //           review_user: true,
//   //           audit_type: "2"
//   //         })
//   //         var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//   //         var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
//   //       }
//   //       else {
//   //         publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
//   //         publishTemplate.endpoints[getIndex].adt_users.push({
//   //           user_id: reviewUser._id,
//   //           name: reviewUser.firstname,
//   //           designation: reviewUser.designation,
//   //           user_code: reviewUser.usercode,
//   //           cat_type: publishTemplate.review_userlevel,
//   //           review_user: true,
//   //           audit_type: "2"
//   //         })
//   //         var checkAuditUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//   //         var checkReviewUserExist = _.some(publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

//   //       }
//   //       _.each(publishTemplate.endpoints, item => {
//   //         // //.log(!_.some(item.adt_users, { audit_type: "1"})
//   //         if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//   //           item["rowValid"] = false
//   //         }
//   //         else if (!publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//   //           item["rowValid"] = false
//   //         }
//   //         else {
//   //           item["rowValid"] = true
//   //         }
//   //       })


//   //       this.setState({
//   //         publishTemplate
//   //       },()=>{

//   //       this.updatePublishTemplateData()
//   //       setTimeout(() => {
//   //         this.setState({
//   //           auditUserlevelSelected: true,
//   //         })

//   //       }, 10)
//   //     })
//   //     }
//   //   }
//   //   this.setState({ user_type: 1 })
//   //   // this.updateCCLevel()

//   // }

//   checkEndpoints = () => {
//     var checkAudituser = _.some(this.state.publishTemplate.endpoints, { audit_user: null })
//     var checkReviewuser = _.some(this.state.publishTemplate.endpoints, { review_user: null })

//     if (!this.state.customEnableReview && !checkAudituser) {
//       return true //this.setState({cc_level:2})
//     }
//     else if (!this.state.checkAudituser && (this.state.customEnableReview && !checkReviewuser)) {
//       return true //this.setState({cc_level:2})
//     }
//     else {
//       return false
//     }
//   }

//   getDateFormateMethod = () => {
//     var today = new Date()
//     const dd = today.getDate().toString().length == 1 ? "0" + today.getDate().toString() : today.getDate().toString()
//     const mm = String(today.getMonth() + 1).length == 1 ? "0" + String(today.getMonth() + 1) : today.getMonth() + 1
//     const yyyy = today.getFullYear()
//     const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
//     this.setState({ fromate_date })
//   }

//   // onChangeStartDate(event) {
//   //   this.state.publishTemplate.start_date = event.target.value
//   //   this.state.publishTemplate.end_date = null
//   //   this.setState({ ptl_startdate: event.target.value, })
//   //   this.updatePublishTemplateData()
//   // }

//   onChangeStartDate(event) {
//     // console.log('evnt', event, this.state.ptl_startdate > this.state.ptl_enddate )
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.start_date = event.target.value === '' ? null : event.target.value
//     publishTemplate.end_date = event.target.value > publishTemplate.end_date ? null : publishTemplate.end_date
//     // this.state.publishTemplate.end_date = null
//     //this.state.publishTemplate.cc_level = 3
//     if (this.state.ptl_startdate > this.state.ptl_enddate) {
//       publishTemplate.cc_level = 2
//     } else {
//       publishTemplate.cc_level = event.target.value === '' ? 2 : 3

//     }
//     this.setState({ ptl_startdate: event.target.value, cc_level: publishTemplate.cc_level, publishTemplate },()=>{
//     this.updatePublishTemplateData()
//   })
//   }
   
//   onChangeEndDate(event) {
//     // console.log('event.target.value', event.target.value)
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.end_date = event.target.value === '' ? null : event.target.value
//     publishTemplate.cc_level = event.target.value === '' ? 2 : 3
//     this.setState({ ptl_enddate: event.target.value, cc_level: publishTemplate.cc_level, publishTemplate },()=>{
//     this.updatePublishTemplateData()
//   })
//   }
     





//   // onChangeEndDate(event) {
//   //   this.state.publishTemplate.end_date = event.target.value
//   //   this.setState({ ptl_enddate: event.target.value, })
//   //   this.updatePublishTemplateData()
//   // }

//   onChangeStartTime(e) {

//     var getTimeFromEvent = e[0].toLocaleTimeString()
//     var getHour = getTimeFromEvent.slice(0, 2)
//     var getMinTime = String(parseInt(getHour + 1)) + "00"
//     var publishTemplate ={...this.state.publishTemplate}

//     publishTemplate.repeat_mode_config = {
//       mode: publishTemplate.repeat_mode_config.mode,
//       mode_id: publishTemplate.repeat_mode_config.mode_id,
//       start_date: publishTemplate.repeat_mode_config.start_date,
//       end_date: publishTemplate.repeat_mode_config.end_date,
//       days: publishTemplate.repeat_mode_config.days,
//       start_time: [getTimeFromEvent],
//       end_time: publishTemplate.repeat_mode_config.end_time,
//       start_on: publishTemplate.repeat_mode_config.start_on,
//       ends_on: publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: publishTemplate.repeat_mode_config.mendoption,

//     }


//     this.setState({ daily_starttime: e, getMinTime ,publishTemplate})
//   }

//   onChangeEndTime(e) {

//     var getTimeFromEvent = e[0].toLocaleTimeString()
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.repeat_mode_config = {
//       mode: publishTemplate.repeat_mode_config.mode,
//       mode_id: publishTemplate.repeat_mode_config.mode_id,
//       start_date: publishTemplate.repeat_mode_config.start_date,
//       end_date: publishTemplate.repeat_mode_config.end_date,
//       days: publishTemplate.repeat_mode_config.days,
//       start_time: publishTemplate.repeat_mode_config.start_time,
//       end_time: [getTimeFromEvent],
//       start_on: publishTemplate.repeat_mode_config.start_on,
//       ends_on: publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: publishTemplate.repeat_mode_config.mendoption,

//     }
//     this.setState({ daily_endtime: e,publishTemplate })
//   }

//   monthStartOn(event) {
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.repeat_mode_config = {
//       mode: publishTemplate.repeat_mode_config.mode,
//       mode_id: publishTemplate.repeat_mode_config.mode_id,
//       start_date: publishTemplate.repeat_mode_config.start_date,
//       end_date: publishTemplate.repeat_mode_config.end_date,
//       days: publishTemplate.repeat_mode_config.days,
//       start_time: publishTemplate.repeat_mode_config.start_time,
//       end_time: publishTemplate.repeat_mode_config.end_time,
//       start_on: event.target.value,
//       ends_on: publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: publishTemplate.repeat_mode_config.mendoption,
//     }

//     this.setState({
//       dsf: event.target.value,
//       dayEndNumber: this.generateNumber(parseInt(event.target.value) + 1),
//       publishTemplate: publishTemplate
//     },()=>{
//     this.updatePublishTemplateData()
//   })
//   }

//   monthEndsOn(event) {
//     var publishTemplate ={...this.state.publishTemplate}

//     publishTemplate.repeat_mode_config = {
//       mode: publishTemplate.repeat_mode_config.mode,
//       mode_id: publishTemplate.repeat_mode_config.mode_id,
//       start_date: publishTemplate.repeat_mode_config.start_date,
//       end_date: publishTemplate.repeat_mode_config.end_date,
//       days: publishTemplate.repeat_mode_config.days,
//       start_time: publishTemplate.repeat_mode_config.start_time,
//       end_time: publishTemplate.repeat_mode_config.end_time,
//       start_on: publishTemplate.repeat_mode_config.start_on,
//       ends_on: event.target.value,
//       mstartoption: publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: publishTemplate.repeat_mode_config.mendoption,
//     }
//     this.setState({
//       eoem: event.target.value,
//       publishTemplate: publishTemplate
//     },()=>{
//     this.updatePublishTemplateData()
//     })
//   }

//   validateEndpoints=()=>{

//     _.each(this.state.publishTemplate.endpoints, item => {
//       if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//         item["rowValid"] = false
//       }
//       else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//         item["rowValid"] = false
//       }
//       else {
//         item["rowValid"] = true
//       }
//     })
//     console.log(this.state.publishTemplate)


//   }




//   updateCCLevel() {
//     if (this.state.publishTemplate.endpoints.length > 0) {
//           this.validateEndpoints()
//     }
//     var updateCCState = Validation.updatecclevel(this.state.methodSelected, this.state.publishTemplate)
//     console.log(updateCCState,'ccstate')
//     //.log(updateCCState,'updateCCState')
//     this.setState({component:"",open: false, publishTemplate: updateCCState, cc_level: updateCCState.cc_level,dataloaded:true })
//   }




//   async updatePublishTemplateData() {
//     this.updateCCLevel()
//     console.log(this.state.publishTemplate,'publish')
//     try {
//       urlSocket.post("webphlbconf/updatepublishtemplate", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id
//         },
//         hInfo: {
//           h_id: this.state.hirerachyData._id,
//           publishtemplateInfo: this.state.publishTemplate
//         }
//       })
//         .then(response => {
//           console.log(response,'response')
//           //.log(response,'response')
//           // response.data.data.endpoints



//           if (response.data.response_code == 500) {
//             this.setState({
//               cc_level: response.data.data.cc_level,
//               endpoints: response.data.data.endpoints,
//               publishTemplate:response.data.data,
//               dataloaded : false
//             },()=>{
//               this.setState({
//                 dataloaded : true
//               })
//             })
//           }
//         })
//     } catch (error) {
//     }
//   }

//   // selectRepeatMode(event) {
//   //   var repeatMode = this.state.repeatModeData[parseInt(event.target.value)]
//   //   console.log(event.target.value, repeatMode)
//   //   // var key_name = event.target.value == "1" ? "daily" : "2" ? "weekly" : "3" ? "monthly" : "4" ? "quaterly" : "5" ? "half_yearly" : "6" ? "yearly" : "repeat_mode_config"
//   //   this.state.publishTemplate.repeat_mode = repeatMode.mode
//   //   this.state.publishTemplate.cc_level = 3
//   //   this.state.publishTemplate.repeat_mode_config = {
//   //     mode: repeatMode.mode,
//   //     mode_id: repeatMode.mode_id,
//   //     // start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//   //      start_date: null,
//   //      end_date: null,
//   //     // end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//   //     days: this.state.publishTemplate.repeat_mode_config.days,
//   //     start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//   //     end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//   //     start_on: this.state.publishTemplate.repeat_mode_config.start_on,
//   //     ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
//   //     mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
//   //     mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

//   //   }

//   //   this.setState({
//   //     cc_level: 3,
//   //     repeat_mode: repeatMode.mode,
//   //     weekDays: this.state.publishTemplate.repeat_mode_config.days,
//   //     rm_config: repeatMode,
//   //     daily_starttime: this.state.publishTemplate.repeat_mode_config.start_time,
//   //     daily_endtime: this.state.publishTemplate.repeat_mode_config.end_time,
//   //     dsf: this.state.publishTemplate.repeat_mode_config.start_on,
//   //     eoem: this.state.publishTemplate.repeat_mode_config.ends_on,
//   //     selectedRepeatMode: event.target.value,
//   //     selectedRadioOption: "",
//   //     occurence: ''
//   //   })
//   //   console.log(this.state.publishTemplate, 'this.state.publishTemplate')
//   //   this.updatePublishTemplateData()
//   // }


//   selectRepeatMode(event) {
//     var repeatMode = this.state.repeatModeData[parseInt(event.target.value)]
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.repeat_mode = repeatMode.mode
//     publishTemplate.cc_level = 3
//     console.log(repeatMode.mode,'repeatMode.mode',repeatMode.mode_id)
//     publishTemplate.repeat_mode_config = {
//       mode: repeatMode.mode,
//       mode_id: repeatMode.mode_id,
//       // start_date: publishTemplate.repeat_mode_config.start_date,
//       // end_date: publishTemplate.repeat_mode_config.end_date,
//       days: publishTemplate.repeat_mode_config.days,
//       start_time: publishTemplate.repeat_mode_config.start_time,
//       end_time: publishTemplate.repeat_mode_config.end_time,
//       start_on: publishTemplate.repeat_mode_config.start_on,
//       ends_on: publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: publishTemplate.repeat_mode_config.mendoption,
//       start_date: null,
//       end_date: null,
//       calendar_type : null,
//       // selected_quater_months :[],
//       // selected_quater : null

//     }

//     this.setState({
//       cc_level: 3,
//       repeat_mode: repeatMode.mode,
//       weekDays: publishTemplate.repeat_mode_config.days,
//       rm_config: repeatMode,
//       daily_starttime: publishTemplate.repeat_mode_config.start_time,
//       daily_endtime: publishTemplate.repeat_mode_config.end_time,
//       dsf: publishTemplate.repeat_mode_config.start_on,
//       eoem: publishTemplate.repeat_mode_config.ends_on,
//       selectedRepeatMode: event.target.value,
//       selectedRadioOption: "",
//       occurence: '',
//       publishTemplate,
//       // custom_modal : repeatMode.mode_id === "1" ? true : false

//     },()=>{
//     console.log(this.state.publishTemplate,'this.state.publishTemplate')
//     this.updatePublishTemplateData()
//   })
//   }

//   navigateTo = (data) => {
//     sessionStorage.removeItem("EditPublishedData");
//     sessionStorage.removeItem("nagiationMode");
//     sessionStorage.setItem("EditPublishedData", JSON.stringify(data));
//     sessionStorage.setItem("nagiationMode", "from_config");
//     this.props.history.push("/edtpblhtempt");
//   }

//   handleWeekDays(e) {
//     var getIndex = _.findIndex(this.state.weekDays, { day: e.target.id })
//     console.log(getIndex,'getIndex')
//     var weekDays =[...this.state.weekDays]
//     var publishTemplate ={...this.state.publishTemplate}
//     weekDays[getIndex]["checked"] = e.target.checked
//     publishTemplate.repeat_mode_config = {
//       mode: publishTemplate.repeat_mode_config.mode,
//       mode_id: publishTemplate.repeat_mode_config.mode_id,
//       start_date: publishTemplate.repeat_mode_config.start_date,
//       end_date: publishTemplate.repeat_mode_config.end_date,
//       days: weekDays,
//       start_time: publishTemplate.repeat_mode_config.start_time,
//       end_time: publishTemplate.repeat_mode_config.end_time,
//       start_on: publishTemplate.repeat_mode_config.start_on,
//       ends_on: publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: publishTemplate.repeat_mode_config.mendoption,

//     }

//     if (publishTemplate.repeat_mode === "Weekly" &&
//       _.some(publishTemplate.repeat_mode_config.days, { checked: true })) {
//       publishTemplate["cc_level"] = 3
//     }
//     else {
//       publishTemplate["cc_level"] = 2
//     }

//     this.setState({ weekDays, publishTemplate, cc_level:publishTemplate.cc_level })
//   }

//   ordinal_suffix_of(i) {
//     var j = i % 10,
//       k = i % 100;
//     if (j == 1 && k != 11) {
//       return "st";
//     }
//     if (j == 2 && k != 12) {
//       return "nd";
//     }
//     if (j == 3 && k != 13) {
//       return "rd";
//     }
//     return "th";
//   }

//   handleMStartGroupChange(event) {

//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.repeat_mode_config = {
//       mode: publishTemplate.repeat_mode_config.mode,
//       mode_id: publishTemplate.repeat_mode_config.mode_id,
//       start_date: publishTemplate.repeat_mode_config.start_date,
//       end_date: publishTemplate.repeat_mode_config.end_date,
//       days: publishTemplate.repeat_mode_config.days,
//       start_time: publishTemplate.repeat_mode_config.start_time,
//       end_time: publishTemplate.repeat_mode_config.end_time,
//       start_on: event.target.name === "month_start" ? null : publishTemplate.repeat_mode_config.start_on,
//       ends_on: publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: event.target.name,
//       mendoption: publishTemplate.repeat_mode_config.mendoption,
//     }

//     this.setState({
//       mstartoption: event.target.name,
//       publishTemplate
//     },()=>{
//     this.updatePublishTemplateData()
//   })
//   }

//   handleMEndGroupChange(event) {
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.repeat_mode_config = {
//       mode: publishTemplate.repeat_mode_config.mode,
//       mode_id: publishTemplate.repeat_mode_config.mode_id,
//       start_date: publishTemplate.repeat_mode_config.start_date,
//       end_date: publishTemplate.repeat_mode_config.end_date,
//       days: publishTemplate.repeat_mode_config.days,
//       start_time: at_mode_config.start_time,
//       end_time: publishTemplate.repeat_mode_config.end_time,
//       start_on: publishTemplate.repeat_mode_config.start_on,
//       ends_on: event.target.name === "month_end" ? null : publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: event.target.name,
//     }
//     this.setState({
//       mendoption: event.target.name,
//       publishTemplate
//     },()=>{
//     this.updatePublishTemplateData()
//   })
//   }

//   deleteEndpoint = () => {
//     try {
//       urlSocket.post("webphlbconf/deleteendpoints", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id
//         },
//         hInfo: {
//           h_id: this.state.hirerachyData._id,
//           publishtemplateInfo: this.state.publishTemplate
//         },
//         endpointInfo: this.state.deleteItem
//       })
//         .then(response => {
//           if (response.data.response_code === 500) {

//             var publishTemplate = response.data.data


//             this.setState({
//               publishTemplate: publishTemplate,
//               endpoints: response.data.data.endpoints,
//               changeMethodConfirmation: false,
//             }, function () {
//               this.updateCCLevel()
//             })
//           }
//         })
//     } catch (error) {
//     }
//   }

//   treeDataToFlat = async () => {
//     var getNodeKey = ({ treeIndex }) => treeIndex
//     var flatData = getFlatDataFromTree(
//       {
//         treeData: this.state.publishTemplate.template_structure,
//         getNodeKey,
//         ignoreCollapsed: false,
//       })

//     var explicitData = _.filter(flatData, item => {
//       return item
//     })

//     return explicitData
//   }

//   checkPublishDate = () => {

//     var currentDate = moment(new Date()).format("YYYY-MM-DD")
//     var pbd_startDate = moment(new Date(this.state.publishTemplate.start_date)).format("YYYY-MM-DD")
//     var pbd_endDate = moment(new Date(this.state.publishTemplate.end_date)).format("YYYY-MM-DD")

//     if (moment(pbd_startDate).isBefore(currentDate)) {
//       this.setState({ ederror: false, sderror: true })
//     }

//     if (moment(pbd_endDate).isBefore(currentDate)) {
//       this.setState({ sderror: false, ederror: true })
//     }

//     if (moment(pbd_startDate).isBefore(currentDate) == false && moment(pbd_endDate).isBefore(currentDate) == false) {
//       this.setState({ sderror: false, ederror: false })
//       return true
//     }
//     else {
//       return false
//     }
//   }

//   calculateMaxDate() {
//     const { ptl_startdate } = this.state;

//     if (ptl_startdate === '' || ptl_startdate === undefined) {
//       return null; // Return null if start date is not set
//     }

//     const startDate = new Date(ptl_startdate);
//     startDate.setFullYear(startDate.getFullYear() + this.state.client_info.rept_mode_periodicity);

//     return startDate.toISOString().slice(0, 10); // Format the date as "YYYY-MM-DD"
//   }




//   publishTmpltAsAudit = async () => {

//     var checkpoints = await this.treeDataToFlat()
//     var checkDates = this.checkPublishDate()
//     var publishTemplate ={...this.state.publishTemplate}
//     //("checkDates", checkDates)
//     //(this.state.publishTemplate,'this.state.publishTemplate')
//     if (checkDates) {
//       if(this.state.methodSelected == "2"){
//         var filter_ignored_location = _.filter(publishTemplate.endpoints, endpoint => !endpoint.disabled);
//         publishTemplate["endpoints"] = filter_ignored_location
//         //.log(filter_ignored_location,'filter_ignored_location',this.state.publishTemplate)
//       }

//       console.log(this.state.publishTemplate,'this.state.publishTemplate')
//       try {
//         urlSocket.post("webphlbprcs/publishtmpltasaudit", {
//           userInfo: {
//             encrypted_db_url: this.state.db_info.encrypted_db_url,
//             created_by: this.state.userInfo._id,
//             company_id: this.state.userInfo.company_id
//           },
//           hInfo: {
//             publishtemplateInfo: publishTemplate,
//           },
//         })
//           .then(response => {
//             //("response", response)
//             if (response.data.response_code === 500) {
//               this.setState({
//                 tmpltPublishProcess: true,
//                 alertMessage: "Audit publishing started successfully click",
//                 processStatus: "1",
//                 publishTemplate
//               })
//             }
//             else {
//               this.setState({
//                 tmpltPublishProcess: true,
//                 alertMessage: "Audit not publish. Please check the data",
//                 processStatus: "0",
//                 publishTemplate
//               })
//             }
//           })
//       } catch (error) {
//       }
//     }
//     else {
//     }

//   }

//   redirectTo(processStatus) {

//     this.setState({ tmpltPublishProcess: false })

//     if (processStatus == "1") {
//       sessionStorage.removeItem("EditPublishedData");
//       sessionStorage.removeItem("nagiationMode");
//       this.props.history.push("/mngpblhtempt");
//     }
//   }


//   deleteAllRequest() {

//     if (this.state.publishTemplate.endpoints.length === 0) {
//       this.setState({ dataAlert: true, alertMessage: "No Locations to delete" })
//     }
//     else {
//       this.setState({ triggerFrom: "deleteall", changeMethodConfirmation: true })
//     }


//   }


//   select_country = (e) => {
//     this.setState({ countrycode: e.target.value ,country_code_err : false })
//   }


//   addPermissions(e, item) {

//     var getIndex = _.findIndex(this.state.permissionsadded, { id: item.id })

//     if (getIndex === -1) {
//       this.state.permissionsadded.push(item)
//       this.setState({
//         permissionsadded: this.state.permissionsadded
//       })
//     }
//     else {
//       this.state.permissionsadded.splice(getIndex, 1)
//       this.setState({
//         permissionsadded: this.state.permissionsadded
//       })
//     }


//   }



//   validate_email = (e, mode) => {
//     var admn_info = {}
//     if (mode == 1) {
//       admn_info["email_id"] = e.target.value
//     }
//     if (mode === 2) {
//       admn_info["phone_number"] = e.target.value
//     }
//     if (mode === 3) {
//       admn_info["usercode"] = e.target.value
//     }
//     admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//     admn_info["db_name"] = this.state.db_info.db_name
//     admn_info["user_id"] = this.state.userInfo._id

//     if (this.state.usercode !== undefined || admn_info["email_id"] !== undefined || admn_info["phone_number"] !== undefined) {
//       try {
//         urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
//           //.log(data,'data')
//           if (mode == 1) {
//             if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
//               this.setState({ invalid_user: true })
//             }
//             else {
//               this.setState({ invalid_user: false })
//             }
//           }
//           if (mode == 2) {

//             if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
//               this.setState({ invalid_phone_num: true })
//             }
//             else {
//               this.setState({ invalid_phone_num: false })
//             }
//           }
//           if (mode === 3) {
//             if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
//               this.setState({ invalid_usercode: true })
//             }
//             else {
//               this.setState({ invalid_usercode: false })
//             }


//           }
//         })

//       } catch (error) {

//       }
//     }

//   }


//   emai_addrs = (e) => {
//     var email = e.target.value
//     if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
//       this.setState({ given_email: email, errorMessage: false })
//     }
//     else {
//       this.setState({ errorMessage: true, given_email: email })
//     }

//   }

//   userCode = (e) => {
//     this.setState({ usercode: e.target.value })
//   }


//   loadEOPTS = () => {
//     this.setState({ tableDataloaded: false })
//     try {
//       urlSocket.post("cog/get-user-info",
//         {
//           db_info: this.state.db_info,
//           user_id: this.state.userInfo._id
//         }
//       )
//         .then((response) => {
//           this.setState({
//             userdata: response.data.user_list,
//             auditUserlevelSelected: false
//           }, function () {
//             this.loadUserLabels()
//             setTimeout(() => {
//               this.setState({ auditUserlevelSelected: true })

//             }, 50);
//           })

//         })
//     } catch (error) {

//     }
//   }

//   locationUserCrud=async (event,values)=>{
//     console.log(values,this.state.sessionUserInfo,this.state.client_info)
//     //.log(values,'values')
//     values["selected_category"]=this.state.selected_category
//     var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
//     values["password"] = password
//     values["confirmpassword"] = password
//     values["userPoolId"] = this.state.client_info.userPoolId
//     values["clientId"] = this.state.client_info.clientId
//     values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//     values["db_name"] = this.state.db_info.db_name
//     values["facilities"] = this.state.client_info.facilities
//     values["company_id"] = this.state.client_info._id
//     values["created_by"] = this.state.sessionUserInfo._id
//     values["short_name"] = this.state.client_info.short_name
//     values["user_id"] = this.state.sessionUserInfo._id
//     if (this.state.countrycode === undefined) {
//       this.setState({ country_code_err: true })
//     }
//       else {
//         values["countrycode"] = this.state.countrycode
//         this.setState({ country_code_err: false })
  
//       }
//     if (this.state.invalid_user === false && this.state.errorMessage == false && this.state.country_code_err === false) {
//            this.setState({ loading: true })
//           var hstructure =  JSON.parse(sessionStorage.getItem("hstructure"));
//            //.log(this.state.location_info,this.state.tree_flat,hstructure,'location_id',this.state.selected_category)
          
//       try {
//         urlSocket.post('cog/cruduser', values).then(async (res) => {
//           //.log(res, 'response')
//           if (res.data.response_code === 500) {
//             this.setState({ loading: false })
//             var location_info ={...this.state.location_info}

//             this.state.selected_category.map((ele, idx) => {
//               if (this.state.add_reviwer == false) {
//                 var userInfo = {
//                   cat_type: ele.cat_type,
//                   designation: null,
//                   hirerachy_name: ele.hirerachy_name,
//                   name: values.firstname,
//                   title: location_info.hlevel_name,
//                   type: 2,
//                   user_id: res.data.admn_user[0]._id,
//                   _id: res.data.admn_user[0]._id

//                 }
//               }
//               if(this.state.add_reviwer == true){
//                 var userInfo = {
//                   cat_type: ele.cat_type,
//                   designation: null,
//                   hirerachy_name: ele.hirerachy_name,
//                   name: values.firstname,
//                   title: location_info.hlevel_name,
//                   type: 2,
//                   review_user :true,
//                   user_id: res.data.admn_user[0]._id,
//                   _id: res.data.admn_user[0]._id

//                 }
//               }
//               location_info.user_path.push(userInfo)
//               if (location_info["unique_users"] == undefined) {
//                 location_info["unique_users"] = []
//                 location_info["hirerachy_name"] = []
//                 location_info["unique_users"].push({ ...userInfo })
//                 location_info["unique_users"][0]["cat_type"] = [location_info["unique_users"][0]["cat_type"]]
//                 location_info["unique_users"][0]["hirerachy_name"] = [location_info["unique_users"][0]["hirerachy_name"]]
//               }
//               else {
//                 var find_exist_user = _.find(location_info.unique_users, { _id: userInfo._id })
//                 if (find_exist_user !== undefined) {
//                   find_exist_user["cat_type"].push(ele.cat_type)
//                   find_exist_user["hirerachy_name"].push(ele.hirerachy_name)
//                 }
//                 else {
//                   location_info["unique_users"].push({ ...userInfo })
//                   var find_exist_user_unique = _.findIndex(location_info.unique_users, { _id: userInfo._id })
//                   location_info["unique_users"][find_exist_user_unique]["cat_type"] = [location_info["unique_users"][find_exist_user_unique]["cat_type"]]
//                   location_info["unique_users"][find_exist_user_unique]["hirerachy_name"] = [location_info["unique_users"][find_exist_user_unique]["hirerachy_name"]]

//                 }
//               }
//             })
//             this.setState({
//               location_info 
//             },async()=>{
//             var update_flatData = await this.updateInFlatCln(this.state.location_info,hstructure)
//           })
//           }

//         })
//       } catch (error) {

//       }
//     }
//   }


//   updateInFlatCln = (flatData, hstructure) => {
//     //.log(flatData)
//     console.log(flatData, hstructure)
//     try {
//       urlSocket.post('cog/update-flat-cln', {
//         flatData: flatData,
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//       }).then((response) => {
//         //.log(response, 'response')
//         console.log(response,'response')
//         if (response.data.response_code === 500) {
//           //.log(flatData,'flatData',hstructure)
//           var path_data = hstructure.find((fcty) => fcty.title === flatData.hlevel_name && fcty.subtitle === flatData.hlevel)
//           console.log(path_data, 'path_data', hstructure)
//           //.log(path_data,'path_data')
//           if (path_data !== undefined) {
//             path_data["unique_users"] = flatData.unique_users
//             path_data["user_path"] = flatData.user_path
//             console.log(path_data, 'path_data',flatData,this.state.publishTemplate)
//             if (flatData.adt_users.length == 0) {
//               var user_info = {
//                 user_id: path_data["user_path"][0].user_id,
//                 _id: path_data["user_path"][0].user_id,
//                 name: path_data["user_path"][0].name,
//                 designation: path_data["user_path"][0].designation,
//                 cat_type: path_data["user_path"][0].cat_type,
//                 audit_type: this.state.add_reviwer ? "2" : "1",
//                 review_user: this.state.add_reviwer ? true : false
//               }
//               if (path_data.user_path.length > 0 && path_data.user_path.length < 2){
//                 // flatData["rowValid"] = true
//                 flatData["rowValid"] = true
//               flatData.adt_users.push(user_info)
//               // if(this.state.publishTemplate.endpoints.length ===0){
//               //   this.state.publishTemplate.endpoints.push(flatData)
//               // }
//             }
//             }
//             // this.updateCCLevel()
//             this.setState({
//               publishTemplate: this.state.publishTemplate
//             },()=>{this.updatePublishTemplateData();this.FlatDataToTreeData(hstructure)})
            
//             // flatData.adt_
            
//           }

//         }
//       })

//     } catch (error) {

//     }


//   }



//   FlatDataToTreeData = async (FlatData) => {
//     //.log(FlatData);
//     try {
//       const treeData = await this.getTreeFromFlatDataAsync(FlatData);
//       //.log(treeData,'treeData')
//       this.setState({
//         dup_treeData: treeData,
//         // dataloaded: true,
//       },()=>this.saveHstructure(treeData,FlatData));
//     } catch (error) {
//       //.error("Error:", error);
//     }
//   }

//   saveHstructure=async (treeData,hstructure)=>{
//     var explicitData = await this.HtreeDataToFlat(treeData)
//     //.log(explicitData,'explicitData')
//     try {
//       urlSocket.post("webhstre/savehstructure", {
//         info: {
//           _id: this.state.publishTemplate.hlevel_id,
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           company_id: this.state.publishTemplate.company_id,
//           flatData: _.map(explicitData, "node"),
//           published:true
//           // lastGeneratedId: this.state.totalHLength
//         }
//       })
//         .then((response) => {
//           //.log(response,'response')
//           if(response.data.response_code == 500){
//             this.setState({ loading: false , modal : false  , dataloaded : false},()=>{this.setState({dataloaded : true})})
//             sessionStorage.setItem("hstructure", JSON.stringify(hstructure));
//             this.getAllUsers()
//           }
//           //("response.data", response)
//           // if (response.data.response_code == 500) {
//           //   this.setState({ saving: false, successmsgshow: true, alertMessage: response.data.message },()=>{this.getHData("stop")})
//           //   setTimeout(() => { this.setState({ successmsgshow: false, }) }, 2500)

//           // }
//         })
//     } catch (error) {

//     }





//   }


//   HtreeDataToFlat = (treeData) => {
//     // //("this.state.treeData", this.state.treeData)
//     var getNodeKey = ({ treeIndex }) => treeIndex
//     var flatData = getFlatDataFromTree(
//       {
//         treeData: treeData,
//         getNodeKey,
//         ignoreCollapsed: false,
//       })
//       //.log(flatData,'flatData')
//     var explicitData = _.filter(flatData, item => {
//       return item
//     })

//     return explicitData
//   }





//   getTreeFromFlatDataAsync = (FlatData) => {
//     return new Promise((resolve, reject) => {
//       try {
//         const treeData = getTreeFromFlatData({
//           flatData: FlatData,
//           getKey: (node) => node.id,
//           getParentKey: (node) => node.parent,
//           rootKey: null,
//           expanded: true
//         });
  
//         resolve(treeData);
//       } catch (error) {
//         reject(error);
//       }
//     });
//   }




//   handleValidSubmit = (event, values) => {
//     var client_info ={...this.state.client_info}
//     client_info.facilities.map((ele, idx) => {
//       this.state.permissionsadded.map((ele1, idx1) => {
//         if (ele1.id === ele.id) {
//           client_info.facilities[idx].enabled = true
//         }
//       })
//     })


//     var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
//     values["password"] = password
//     values["confirmpassword"] = password
//     values["userPoolId"] = client_info.userPoolId
//     values["clientId"] = client_info.clientId
//     values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//     values["db_name"] = this.state.db_info.db_name
//     values["facilities"] = client_info.facilities
//     values["company_id"] = this.state.sessionUserInfo.m_client_id
//     values["created_by"] = this.state.sessionUserInfo._id
//     values["short_name"] = client_info.short_name
//     values["user_id"] = this.state.sessionUserInfo._id

//     if (this.state.countrycode === undefined) {
//       this.setState({ country_code_err: true })
//     }
//     else {
//       values["countrycode"] = this.state.countrycode
//       this.setState({ country_code_err: false })

//     }
//     if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false) {
//       this.setState({ loading: true })

//       try {
//         urlSocket.post('cog/cruduser', values).then((res) => {
//           if (res.data.response_code === 500) {
//             var publishTemplate ={...this.state.publishTemplate}
//             var created_user = res.data.admn_user[0]
//             this.setState({ loading: false })
//             var event = {}
//             var target = {}
//             target["value"] = created_user._id
//             event["target"] = target
//             this.state.userdata.push(created_user)
//             if (this.state.user_type === 0) {
//               if (publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0) {
//                 publishTemplate.endpoints[this.state.selected_index].adt_users.push({
//                   user_id: created_user._id,
//                   name: created_user.firstname,
//                   designation: created_user.designation,
//                   user_code: created_user.usercode,
//                   audit_type: "1"
//                 })
//               } else {
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_id'] = created_user._id
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['name'] = created_user.firstname
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['designation'] = created_user.designation
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_code'] = created_user.user_code
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['audit_type'] = '1'
//               }
//             }
//             else {
//               if (this.state.user_type === 1) {
//                 if (publishTemplate.endpoints[this.state.selected_index].adt_users.length === 1 || publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0) {
//                   publishTemplate.endpoints[this.state.selected_index].adt_users.push({
//                     user_id: created_user._id,
//                     name: created_user.firstname,
//                     designation: created_user.designation,
//                     user_code: created_user.usercode,
//                     audit_type: "2",
//                   })
//                 }
//                 else {
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_id'] = created_user._id
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['name'] = created_user.firstname
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['designation'] = created_user.designation
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_code'] = created_user.user_code
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['audit_type'] = '2'

//                 }
//               }

//             }
//             this.setState({ userdata: this.state.userdata, publishTemplate }, () => {

//               this.state.user_type === 0 ?
//                 this.changeAuditUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index, "0")
//                 : this.changeReviewUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index, "0")
//               this.onDrawerClose()
//             })
//           }
//           else {
//             this.setState({ loading: false })

//           }
//         })

//       } catch (error) {
//       }

//     }

//   }

//   handle_click = (e) => {
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate[e.target.name] = e.target.checked
//     this.setState({ publishTemplate},()=>{this.updatePublishTemplateData()})
//   }


//   labelSelected(data) {

//     if (data.target.value === "all") {
//       this.loadEOPTS()
//     }
//     else {
//       var mylabel = this.state.labelData[data.target.value]

//       try {

//         urlSocket.post("userSettings/load-group-users", {
//           labelInfo: mylabel,
//           userInfo: {
//             encrypted_db_url: this.state.db_info.encrypted_db_url,
//             _id: this.state.userInfo._id,
//             company_id: this.state.userInfo.company_id
//           }
//         })
//           .then(response => {
//             this.setState({
//               statusBasedFilteredData: response.data.data, userdata: response.data.data, auditUserlevelSelected: false
//             }, () => {
//               setTimeout(() => {
//                 this.setState({ auditUserlevelSelected: true });

//               }, 50);

//             })
//           })
//       } catch (error) {
//       }

//     }
//     this.setState({ user_group_selected: data.target.value })

//   }



//   deleteAllEndpoints() {



//     try {
//       var loggedUserInfo = {
//         company_id: this.state.userInfo.company_id,
//         company_name: this.state.userInfo.company_name,
//         created_by: this.state.userInfo._id,
//         encrypted_db_url: this.state.db_info.encrypted_db_url
//       }
//       urlSocket.post("webphlbconf/deleteallendpoints", {
//         userInfo: loggedUserInfo,
//         publishtemplateInfo: this.state.publishTemplate
//       })
//         .then(response => {
//           if (response.data.response_code === 500) {
//             this.setState({
//               changeMethodConfirmation: false,
//             }, function () {
//               this.onDrawerClose()
//             })

//           }
//         })
//     } catch (error) {
//     }

//   }

//   // endAfterMethod = (event) => {
//   //   console.log(event.target.name, event.target.value)
//   //   this.state.publishTemplate['repeat_mode_config']["start_date"] = null
//   //   this.state.publishTemplate['repeat_mode_config']["end_date"] = null
//   //   this.state.publishTemplate['repeat_mode_config']["based_on"] = event.target.name
//   //   this.state.publishTemplate['repeat_mode_config']["no_of_occurance"] = null
//   //   this.state.publishTemplate['repeat_mode_config']["allowed_audit_days"] = []
//   //   this.state.publishTemplate['repeat_mode_config']["week_start_day"] = null

//   //   this.state.publishTemplate.repeat_mode_config.days.map((data, idx) => {
//   //     data["checked"] = false
//   //   })
//   //   this.setState({
//   //     selectedRadioOption: event.target.name,
//   //     occurence: "",
//   //     weekDays: this.state.publishTemplate.repeat_mode_config.days
//   //   }, () => {
//   //     this.updateCCLevel()
//   //   })
//   // }


//   endAfterMethod=(event)=>{
//     console.log(event.target.name,event.target.value)
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate['repeat_mode_config']["start_date"]=null
//     publishTemplate['repeat_mode_config']["end_date"]=null
//     publishTemplate['repeat_mode_config']["based_on"]=event.target.name
//     publishTemplate['repeat_mode_config']["no_of_occurance"]=null
//     publishTemplate['repeat_mode_config']["allowed_audit_days"]=[]
//     publishTemplate['repeat_mode_config']["week_start_day"]=null
//     publishTemplate['repeat_mode_config']["allowed_audit_monthy"]=null
//     publishTemplate['repeat_mode_config']["show_time_frame"]=false



//     publishTemplate.repeat_mode_config.days.map((data, idx) => {
//       data["checked"] = false
//     })

//     this.setState({
//       selectedRadioOption : event.target.name,
//       occurence :"",
//       publishTemplate,
//       weekDays: publishTemplate.repeat_mode_config.days

//     },()=>{
//       this.updateCCLevel()

//     })
//   }


// //   scheduleStartDate = async(event) => {
// //     console.log(event.target.value, 'value', this.state.publishTemplate,this.state.selectedRadioOption)
// //     var publishTemplate ={...this.state.publishTemplate}
// //     this.setState({
// //       publishTemplate
// //     },async()=>{

// //     if(this.state.selectedRadioOption === "occurence"){
// //     publishTemplate["repeat_mode_config"]["start_date"] = event.target.value

// //     if (this.state.occurence <= 6) {
// //       const endDate = await this.calculateEndDate();
// //       const daysOfWeek =await this.getDaysOfWeekBetweenDates(new Date(this.state.publishTemplate.repeat_mode_config.start_date), new Date(endDate));
// //       console.log(endDate,'endDate', daysOfWeek, this.state.publishTemplate)
// //       this.setState({
// //         weekDays : daysOfWeek
// //       })
// //     }
// //     else{
// //       this.setState({
// //         weekDays : this.state.publishTemplate.repeat_mode_config.days
// //       })
// //     }
// //   }
// //   else{
// //     publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
// //     if(this.state.publishTemplate.repeat_mode_config.end_date !== null){
// //       var startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
// //       var endDate = new Date(this.state.publishTemplate.repeat_mode_config.end_date);
// //       var timeDifference = endDate.getTime() - startDate.getTime();
// //       var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
// //       // console.log(daysDifference,'daysDifference')
// //       this.setState({
// //         no_of_occurance : daysDifference + 1
// //       })
// //       if(daysDifference <= 6){
// //         const daysOfWeek =await this.getDaysOfWeekBetweenDates(new Date(this.state.publishTemplate.repeat_mode_config.start_date), new Date(this.state.publishTemplate.repeat_mode_config.end_date));
// //         console.log(daysOfWeek,'daysOfWeek')
// //         this.setState({
// //           weekDays : daysOfWeek
// //         })
// //       }
// //       else{
// //         this.setState({
// //           weekDays : this.state.publishTemplate.repeat_mode_config.days
// //         })
// //       }

// //     }
// //   }
// // })
// //     this.setState({
// //       publishTemplate
// //     })

// //   }

  

//   // calculateEndDate = () => {
//   //   // console.log(this.state.occurence, 'this.state.occurence', this.state.publishTemplate.repeat_mode_config.start_date);
//   //   var publishTemplate ={...this.state.publishTemplate}
//   //   if (this.state.occurence !== "" && publishTemplate.repeat_mode_config.start_date !== null) {
//   //     var startDate = new Date(publishTemplate.repeat_mode_config.start_date);
//   //     var occurrence = parseInt(this.state.occurence, 10);

//   //     if (!isNaN(occurrence)) {
//   //       startDate.setDate(startDate.getDate() + occurrence);

//   //       const endYear = startDate.getFullYear();
//   //       const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
//   //       const endDay = String(startDate.getDate()).padStart(2, '0');

//   //       // console.log(`${endYear}-${endMonth}-${endDay}`, startDate);
//   //       // this.s
//   //       publishTemplate["repeat_mode_config"]["end_date"] = `${endYear}-${endMonth}-${endDay}`
//   //       this.setState({
//   //         publishTemplate
//   //       },()=>{
//   //         return `${endYear}-${endMonth}-${endDay}`;

//   //       })
//   //     }
//   //   }
//   //   return null
//   // }


//   calculateEndDate = () => {
//     // if(mode === "1"){
//       var publishTemplate={...this.state.publishTemplate}
//     if (this.state.occurence !== "" && publishTemplate.repeat_mode_config.start_date !== null) {
//       var startDate = new Date(publishTemplate.repeat_mode_config.start_date);
//       var occurrence = parseInt(this.state.occurence, 10);

//       if (!isNaN(occurrence)) {
//         startDate.setDate(startDate.getDate() + occurrence);

//         const endYear = startDate.getFullYear();
//         const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
//         const endDay = String(startDate.getDate()).padStart(2, '0');
//         publishTemplate["repeat_mode_config"]["end_date"] = `${endYear}-${endMonth}-${endDay}`
//         this.setState({
//           publishTemplate
//         })
//         return `${endYear}-${endMonth}-${endDay}`;
//       }
//     }
//     return null
//   }
//   getDaysOfWeekBetweenDates = (startDate, endDate) => {
//     // console.log(startDate, endDate, 'startDate, endDate')
//     if (startDate !== null && endDate !== null) {
//       const daysOfWeek = this.state.daysOfWeek
//       const days = [];
//       let current = new Date(startDate);

//       while (current <= endDate) {
//         days.push(daysOfWeek[current.getDay()]);
//         current.setDate(current.getDate() + 1);
//       }
//       console.log(days, 'days')
//       return days;
//     }
//   }
  
 

//   handleOccurance = async (event, mode) => {
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate["repeat_mode_config"]["no_of_occurance"] = event.target.value
//     if (mode === "1") {
//       console.log(mode, 'mode', this.state.publishTemplate, event.target.value)

//       if (event.target.value <= 6) {
//         this.setState({
//           occurence: event.target.value,
//           show_occurence_valid: false,
//         }, async () => {
//           var endDate = await this.calculateEndDate()
//           var weekDays = await this.getDaysOfWeekBetweenDates(new Date(publishTemplate.repeat_mode_config.start_date), new Date(endDate));
//           console.log(weekDays, 'week', endDate)
//           this.setState({
//             weekDays: weekDays
//           }, () => {
//             this.updatePublishTemplateData()
//           })
//         })
//       }
//       else {
//         this.setState({
//           weekDays: publishTemplate.repeat_mode_config.days,
//           occurence: event.target.value,
//           show_occurence_valid: false,
//         }, async () => {
//           var endDate = await this.calculateEndDate()
//         })
//       }
//     }
//     if (mode === "2") {
//       console.log(mode, 'mode', this.state.publishTemplate, event.target.value)
//       this.setState({
//         occurence: event.target.value,
//         show_occurence_valid: false,
//       }, async () => {
//         if (publishTemplate.repeat_mode_config.week_start_day !== undefined && publishTemplate.repeat_mode_config.week_start_day !== null) {
//           var calculateEndDate = await this.calculateEnddateInWeek(publishTemplate["repeat_mode_config"]["week_start_day"]["day_id"], this.state.selectedRadioOption)
//           publishTemplate["repeat_mode_config"]["end_date"] = calculateEndDate
//         }
//         this.setState({
//           publishTemplate
//         }, () => {
//           this.updatePublishTemplateData()
//         })
//       })
//     }
//     if (mode == "3") {
//       console.log(mode, 'mode', this.state.publishTemplate, event.target.value)
//       this.setState({
//         occurence: event.target.value,
//         show_occurence_valid: false,
//       }, async () => {
//         console.log(this.state.occurence)
//         if(publishTemplate.repeat_mode_config.start_date !== null){
//           var calculateEndDate = await this.calculateEndDateInMonth()
//           console.log(calculateEndDate,'calculateEndDate')
//           publishTemplate["repeat_mode_config"]["end_date"] =calculateEndDate
          
//         }
//         this.setState({
//           publishTemplate
//         },()=>{
//           this.updatePublishTemplateData()

//         })
//       })  
//     }
//     if (mode == "6") {
//       this.setState({
//         occurence: event.target.value,
//         show_occurence_valid: false,
//       }, async () => {

//         this.updatePublishTemplateData()
//       })

//     }

//   }

//   scheduleStartDate = async (event,mode) => {
//     console.log(event.target.value, 'value',mode, this.state.publishTemplate, this.state.selectedRadioOption)
//   var publishTemplate ={...this.state.publishTemplate}
//     this.setState({
//       publishTemplate
//     })

//     if (mode === "1") {
//       if (this.state.selectedRadioOption === "occurence") {
//         publishTemplate["repeat_mode_config"]["start_date"] = event.target.value

//         if (this.state.occurence <= 6) {
//           const endDate = await this.calculateEndDate();
//           const daysOfWeek = await this.getDaysOfWeekBetweenDates(new Date(publishTemplate.repeat_mode_config.start_date), new Date(endDate));
//           console.log(endDate, 'endDate', daysOfWeek, this.state.publishTemplate)
//           this.setState({
//             weekDays: daysOfWeek
//           })
//         }
//         else {
//           this.setState({
//             weekDays: publishTemplate.repeat_mode_config.days
//           })
//         }

//       }
//       else {
//         publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
//         if (publishTemplate.repeat_mode_config.end_date !== null) {
//           var startDate = new Date(publishTemplate.repeat_mode_config.start_date);
//           var endDate = new Date(publishTemplate.repeat_mode_config.end_date);
//           var timeDifference = endDate.getTime() - startDate.getTime();
//           var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
//           this.setState({
//             no_of_occurance: daysDifference + 1
//           })
//           if (daysDifference <= 6) {
//             const daysOfWeek = await this.getDaysOfWeekBetweenDates(new Date(publishTemplate.repeat_mode_config.start_date), new Date(publishTemplate.repeat_mode_config.end_date));
//             console.log(daysOfWeek, 'daysOfWeek')
//             this.setState({
//               weekDays: daysOfWeek
//             })
//           }
//           else {
//             this.setState({
//               weekDays: publishTemplate.repeat_mode_config.days
//             })
//           }

//         }
//       }
//     }
//     if(mode ==="2" ){
//       if (this.state.selectedRadioOption === "occurence") {
//         publishTemplate["repeat_mode_config"]["start_date"] = event.target.value

//         if(publishTemplate.repeat_mode_config.week_start_day !== undefined && publishTemplate.repeat_mode_config.week_start_day !==null){
//         // this.state.publishTemplate["repeat_mode_config"]["start_date"] = event.target.value
//         var calculateEndDate = await this.calculateEnddateInWeek(publishTemplate["repeat_mode_config"]["week_start_day"]["day_id"],this.state.selectedRadioOption)
//         publishTemplate["repeat_mode_config"]["end_date"] = calculateEndDate
//         }
//       }
//       else {
//         publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
//         // this.state.publishTemplate["repeat_mode_config"]["start_date"] = event.target.value
//         if (this.state.publishTemplate.repeat_mode_config.end_date !== null) {
//           console.log(event.target.value, 'value',mode, this.state.publishTemplate, this.state.selectedRadioOption)

//           var calculateOccurence = await this.calculateEnddateInWeek("",this.state.selectedRadioOption)
//           console.log(calculateOccurence,'calculateOccurence')
//         publishTemplate["repeat_mode_config"]["no_of_occurance"] = calculateOccurence

//           this.setState({
//             no_of_occurance : calculateOccurence
//           })
//         }
//       }
//     }
//     if(mode === "3"){
//       if (this.state.selectedRadioOption === "occurence") {
//         console.log(this.state.publishTemplate)
//         publishTemplate["repeat_mode_config"]["start_date"] = event.target.value
//         var calculateEndDate = await this.calculateEndDateInMonth()
//         console.log(calculateEndDate,'calculateEndDate')
//         publishTemplate["repeat_mode_config"]["end_date"] = calculateEndDate

//       }
//       else {
//         publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
//         if(publishTemplate.repeat_mode_config.end_date !== null){
//         var calculateMonthDifference = await this.calculateEndDateInMonth("option2")
//         console.log(calculateMonthDifference,'calculateMonthDifference')
//         publishTemplate["repeat_mode_config"]["no_of_occurance"] = calculateMonthDifference

//         this.setState({
//           no_of_occurance : calculateMonthDifference,
//           publishTemplate 
//         })
//       }
//       }
//     }
//     if(mode === "6"){
//       publishTemplate["repeat_mode_config"]["start_date"] = event.target.value
//       // var calculateEndDate = await this.calculateEndDateInMonth()
//       const startDateStr = event.target.value
//       const startDate = new Date(startDateStr); 

//       // Add one year to the start date
//       startDate.setFullYear(startDate.getFullYear() + Number(this.state.occurence));
//       const endDateStr = startDate.toISOString().substr(0, 10);

//       console.log(endDateStr);
//       publishTemplate["repeat_mode_config"]["end_date"] = endDateStr
//       this.setState({
//         // no_of_occurance : calculateMonthDifference,
//         publishTemplate 
//       })


//     }
//     this.updatePublishTemplateData()
//   }










//   // handleOccurance =async (event) => {
//   //   // console.log(event.target.value)
  
//   //   // console.log(this.state.publishTemplate)
//   //   if (event.target.value <= 6) {
//   //     this.setState({
//   //       occurence: event.target.value,
//   //       show_occurence_valid: false,
//   //     },async()=>{
//   //     var endDate = await this.calculateEndDate()
//   //     var weekDays =await this.getDaysOfWeekBetweenDates(new Date(this.state.publishTemplate.repeat_mode_config.start_date), new Date(endDate));
//   //     console.log(weekDays, 'week',endDate)
//   //     this.setState({
//   //       weekDays: weekDays
//   //     })
//   //   })
//   //   }
//   //   else {
//   //     this.setState({
//   //       weekDays: this.state.publishTemplate.repeat_mode_config.days,
//   //       occurence: event.target.value,
//   //       show_occurence_valid: false,
//   //     },async()=>{
//   //       var endDate = await this.calculateEndDate()
//   //     })
//   //   }
//   // }


//   excludeWeeks = async (event, data, index,mode) => {
//     console.log(mode,'mode')
//     var publishTemplate = {...this.state.publishTemplate}
//     if(mode === "1"){
//     if (this.state.selectedRadioOption === "occurence") {

//       var weekDays = [...this.state.weekDays]
//       console.log(event.target.checked, 'event', data, index, weekDays)
//       if (event.target.checked === true) {
//         weekDays[index]["checked"] = true
//       }
//       else {
//         weekDays[index]["checked"] = false
//       }
//       var excludeId = []
//       weekDays.map((ele, idx) => {
//         if (ele.checked == true) {
//           excludeId.push(Number(ele.day_id))
//         }
//       })
//       var excludedEndDate = await this.excludeExtendEnddate(excludeId)

//       console.log(excludedEndDate, 'excludedEndDate', new Date(excludedEndDate))
//       publishTemplate.repeat_mode_config.end_date = excludedEndDate
//       publishTemplate.repeat_mode_config["excluded_days"] = excludeId

//       this.setState({
//         publishTemplate,
//         weekDays
//       })
//     }

//     else {

//       var weekDays = [...this.state.weekDays]
//       console.log(event.target.checked, 'event', data, index, weekDays)
//       if (event.target.checked === true) {
//         weekDays[index]["checked"] = true
//       }
//       else {
//         weekDays[index]["checked"] = false
//       }
//       var excludeId = []
//       weekDays.map((ele, idx) => {
//         if (ele.checked == true) {
//           excludeId.push(Number(ele.day_id))
//         }
//       })
      
//       var excludedWeekOccurence = await this.excludeExtendOccurence(excludeId)
//       publishTemplate.repeat_mode_config["excluded_days"] = excludeId

//     }

//   }
//   if(mode === "2"){
//     // var daysOfWeek =[...this.state.daysOfWeek]
//     console.log(event.target.checked, 'event', data, index)

//     if (event.target.checked === true) {
//       publishTemplate.repeat_mode_config["days"][index]["checked"] = true
//     }
//     else {
//       publishTemplate.repeat_mode_config["days"][index]["checked"] = false
//     }
//     var allowdId = []
//     publishTemplate.repeat_mode_config["days"].map((ele, idx) => {
//       if (ele.checked == true) {
//         allowdId.push(Number(ele.day_id))
//       }
//     })
//     this.setState({
//       publishTemplate
//     })
    
//     publishTemplate.repeat_mode_config["allowed_audit_days"] = allowdId
//     console.log(this.state.publishTemplate,'this.state.publishTemplate')

//   }

//     this.updatePublishTemplateData()
//   }





//   // excludeWeeks= async(event,data,index)=>{
//   //   if(this.state.selectedRadioOption === "occurence"){
//   //   var weekDays = [...this.state.weekDays]
//   //   var publishTemplate ={...this.state.publishTemplate}
//   //   console.log(event.target.checked, 'event', data, index, weekDays)
//   //   if(event.target.checked === true){
//   //   weekDays[index]["checked"] = true
//   //   }
//   //   else{
//   //   weekDays[index]["checked"] = false
//   //   }

//   //   // this.setState({
//   //   //   weekDays
//   //   // },async()=>{
//   //      var excludeId =[]
//   //     weekDays.map((ele,idx)=>{
//   //       if(ele.checked == true){
//   //         excludeId.push(Number(ele.day_id))
//   //       }
//   //     })
//   //     var excludedEndDate = await this.excludeExtendEnddate(excludeId)

//   //     console.log(excludedEndDate, 'excludedEndDate',new Date(excludedEndDate))
//   //     publishTemplate.repeat_mode_config.end_date =excludedEndDate
//   //     publishTemplate.repeat_mode_config["excluded_days"] =excludeId

//   //     this.setState({
//   //       publishTemplate,
//   //       weekDays
//   //     })
//   //   }
//   //   else{
//   //     var weekDays = [...this.state.weekDays]
//   //     console.log(event.target.checked, 'event', data, index, weekDays)
//   //     if(event.target.checked === true){
//   //     weekDays[index]["checked"] = true
//   //     }
//   //     else{
//   //     weekDays[index]["checked"] = false
//   //     }
//   //     var excludeId =[]
//   //     weekDays.map((ele,idx)=>{
//   //       if(ele.checked == true){
//   //         excludeId.push(Number(ele.day_id))
//   //       }
//   //     })
//   //     var excludedWeekOccurence = await this.excludeExtendOccurence(excludeId)
//   //     publishTemplate.repeat_mode_config["excluded_days"] =excludeId
//   //     this.setState({
//   //       publishTemplate,
//   //       weekDays
//   //     })
//   //     // console.log(excludedWeekOccurence,'excludedWeekOccurence')

//   //   }
//   // }


//   excludeExtendOccurence = (excludeDay) => {
//     const startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//     const endDate = new Date(this.state.publishTemplate.repeat_mode_config.end_date);

//     let daysDifference = 0;

//     while (startDate <= endDate) {
//       // Check if the current date's day of the week is not in the excludeDay array
//       if (!excludeDay.includes(startDate.getDay())) {
//         daysDifference++;
//       }

//       // Move to the next day
//       startDate.setDate(startDate.getDate() + 1);
//     }
//     console.log(daysDifference, 'daysDifference')

//     this.setState({ no_of_occurance: daysDifference });
//   };




//   // excludeExtendOccurence = (excludeDay) => {
//   //   const startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//   //   const endDate = new Date(this.state.publishTemplate.repeat_mode_config.end_date);
  
//   //   let daysDifference = 0;
    
//   //   while (startDate <= endDate) {
//   //     // Check if the current date's day of the week is not in the excludeDay array
//   //     if (!excludeDay.includes(startDate.getDay())) {
//   //       daysDifference++;
//   //     }
  
//   //     // Move to the next day
//   //     startDate.setDate(startDate.getDate() + 1);
//   //   }
//   //   console.log(daysDifference,'daysDifference')
  
//   //   this.setState({ no_of_occurance: daysDifference });
//   // };
 
  
//   excludeExtendEnddate = (excludeId) => {
//     // console.log(excludeId,'excludeId')
//     if (this.state.occurence !== "" && this.state.publishTemplate.repeat_mode_config.start_date !== null) {
//       var startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//       var occurrence = parseInt(this.state.occurence, 10);

//       if (!isNaN(occurrence)) {
//         while (occurrence > 0) {
//           startDate.setDate(startDate.getDate() + 1);
//           // Check if the current day is not a Sunday (Sunday is 0 in JavaScript's Date object)
//           if (!excludeId.includes(startDate.getDay())) {
//             // console.log(startDate,'startDate')
//             occurrence--;
//           }
//         }

//         const endYear = startDate.getFullYear();
//         const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
//         const endDay = String(startDate.getDate()).padStart(2, '0');
//         return `${endYear}-${endMonth}-${endDay}`;
//       }
//     }
//     return null;
//   }





//   // excludeExtendEnddate = (excludeId) => {
//   //   // console.log(excludeId,'excludeId')
//   //   if (this.state.occurence !== "" && this.state.publishTemplate.repeat_mode_config.start_date !== null) {
//   //     var startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//   //     var occurrence = parseInt(this.state.occurence, 10);
     
//   //     if (!isNaN(occurrence)) {
//   //       while (occurrence > 0) {
//   //         startDate.setDate(startDate.getDate() + 1);
//   //         // Check if the current day is not a Sunday (Sunday is 0 in JavaScript's Date object)
//   //         if (!excludeId.includes(startDate.getDay())) {
//   //         // console.log(startDate,'startDate')
//   //           occurrence--;
//   //         }
//   //       }

//   //       const endYear = startDate.getFullYear();
//   //       const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
//   //       const endDay = String(startDate.getDate()).padStart(2, '0');
//   //       return `${endYear}-${endMonth}-${endDay}`;
//   //     }
//   //   }
//   //   return null;
//   // }


//   setTimeFrame = (event) => {
//     console.log(this.state.publishTemplate, event.target.value, event.target.name)
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate["repeat_mode_config"][event.target.name] = event.target.value
//     this.setState({
//       publishTemplate 
//     })


//   }

//   calculateEndDateInMonth=(mode)=>{
//     if(mode === undefined){
//     const start = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//     const year = start.getFullYear();
//     const month = start.getMonth() + Number(this.state.occurence);
//     console.log(start,year,month)
//     // Calculate the new end date
//     const endDate = new Date(year, month, start.getDate());
//     console.log(endDate,'endDate')
//     // Adjust for edge cases like exceeding the number of days in the month
//     // while (endDate.getMonth() !== month) {
//     // // console.log(endDate,'endDate')
//     //   endDate.setDate(endDate.getDate() - 1);
//     // }
  
//     return endDate.toISOString().slice(0, 10);
//   }
//   if(mode === "option2"){
//     const start = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//     const end = new Date(this.state.publishTemplate.repeat_mode_config.end_date);
  
//     const startYear = start.getFullYear();
//     const startMonth = start.getMonth();
  
//     const endYear = end.getFullYear();
//     const endMonth = end.getMonth();
  
//     // Calculate the difference in months
//     const monthsDifference = (endYear - startYear) * 12 + (endMonth - startMonth);
//     console.log(monthsDifference,'monthsDifference')
//     return Math.abs(monthsDifference);
//   }

//   }

  




//   weekStartDay= async(e, item, idx,mode)=>{
//     console.log(this.state.publishTemplate)
//     var publishTemplate ={...this.state.publishTemplate}
//     var checkedIndex = _.findIndex(publishTemplate.repeat_mode_config.days, { "checked": true })
//     console.log(checkedIndex,'checkedIndex')
//     if(checkedIndex !== -1){
//       publishTemplate["repeat_mode_config"]["days"][checkedIndex]["checked"]=false
//     }
//     publishTemplate["repeat_mode_config"]["days"][idx]["checked"]=true
//     publishTemplate["repeat_mode_config"]["week_start_day"] = item
//     this.setState({
//       publishTemplate 
//     })
//     var calculateEndDate = await this.calculateEnddateInWeek(publishTemplate["repeat_mode_config"]["week_start_day"]["day_id"],this.state.selectedRadioOption)
//     console.log(calculateEndDate,'calculateEndDate')
//     publishTemplate["repeat_mode_config"]["end_date"] = calculateEndDate
//     this.updatePublishTemplateData()
//     // this.updatePublishTemplateData()

//   }

//   calculateEnddateInWeek = (weekStartId, radioOption) => {
//     if (radioOption === "occurence") {
//       const start = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//       const weekStart = Number(weekStartId); // Assuming 0 for Sunday, 1 for Monday, and so on
//       var daysToAdd = Number(this.state.publishTemplate.repeat_mode_config.no_of_occurance) * 7;
//       console.log(start, weekStart, daysToAdd)
//       if (start.getDay() !== weekStart) {
//         daysToAdd += (7 - (start.getDay() - weekStart + 7) % 7);
//       }
//       const endDate = new Date(start);
//       endDate.setDate(start.getDate() + daysToAdd);
//       return endDate.toISOString().slice(0, 10);
//     }
//     else {
//       const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
//       const start = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//       const end = new Date(this.state.publishTemplate.repeat_mode_config.end_date);
//       const differenceInDays = Math.round(Math.abs((start - end) / oneDay));
//       const weeks = Math.floor(differenceInDays / 7);

//       return weeks;
//     }
//   }

//   getMinDate = (mode) => {
//     if (mode == undefined) {
//       const startDate =this.state.repeat_mode_config?.starts_on !=="immediate" ? new Date(this.state.repeat_mode_config?.starts_on) : new Date()
//       console.log(startDate)
//       startDate.setDate(startDate.getDate() + 6); // Add 7 days

//       // Convert the updated date back to a string in 'YYYY-MM-DD' format
//       const updatedDate = startDate.toISOString().slice(0, 10);
//       // return upda
//       console.log(updatedDate, 'updatedDate')
//       return updatedDate
//     }
//     if (mode === "3") {
//       const startDate = new Date(this.state.publishTemplate.repeat_mode_config.start_date);
//       startDate.setDate(startDate.getDate() + 30); // Add 7 days

//       // Convert the updated date back to a string in 'YYYY-MM-DD' format
//       const updatedDate = startDate.toISOString().slice(0, 10);
//       // return upda
//       console.log(updatedDate, 'updatedDate')
//       return updatedDate
//     }
//   }
//   yearPeriodicity=(event,item,index,mode)=>{
//     console.log(item,index,mode,'item,index,mode',this.state.quater_periodicity,this.state.quater_months)
//     var publishTemplate ={...this.state.publishTemplate}
//     // var quater_months={...this.state.quater_months}
//     if(mode === "4"){
//     if (event.target.checked === true) {
//       item["checked"] = event.target.checked
//       publishTemplate.repeat_mode_config["selected_quater"]= item
//       publishTemplate.repeat_mode_config["selected_quater_months"]=this.state.publishTemplate.repeat_mode_config.calendar_type ==="Yearly calendar" ? this.state.quater_months[item.name] : this.state.publishTemplate.repeat_mode_config.calendar_type ==="Financial calendar" ? this.state.financial_quater_months[item.name] :this.state.quater_months[item.name]
//       publishTemplate.repeat_mode_config["selected_months"]=null

//       // th
//     }
//     this.setState({
//       publishTemplate,
//       selected_quater_months : this.state.publishTemplate.repeat_mode_config.calendar_type ==="Yearly calendar" ? this.state.quater_months[item.name] : this.state.publishTemplate.repeat_mode_config.calendar_type ==="Financial calendar" ? this.state.financial_quater_months[item.name] :this.state.quater_months[item.name]
//     },()=>{
//       this.updatePublishTemplateData()
//     })
//   }
//   if(mode === "5"){
//     if (event.target.checked === true) {
//       item["checked"] = event.target.checked
//       publishTemplate.repeat_mode_config["selected_half_yearly"]= item
//       publishTemplate.repeat_mode_config["selected_half_yearly_months"]= this.state.publishTemplate.repeat_mode_config.calendar_type ==="Yearly calendar" ? this.state.half_months[item.name] :this.state.publishTemplate.repeat_mode_config.calendar_type ==="Financial calendar" ? this.state.financial_half_months[item.name] : this.state.half_months[item.name]
//       publishTemplate.repeat_mode_config["selected_months"]=null

//       // th
//     }
//     this.setState({
//       publishTemplate,
//       selected_half_months : publishTemplate.repeat_mode_config["selected_half_yearly_months"]
//     },()=>{
//       this.updatePublishTemplateData()
//     })
//   }


//   } 
//   allowedAuditForYearly=(event,item,index)=>{
//     console.log(event,item,index,'event,item,index,')
//     var allowed_audit_yearly =[...this.state.allowed_audit_yearly]
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate.repeat_mode_config["allowed_audit_yearly"]=item

//     allowed_audit_yearly.map((ele,idx)=>{
//       if(index !== idx){
//         allowed_audit_yearly[idx]["checked"] = false
//       }
//       else{
//         allowed_audit_yearly[idx]["checked"] = true

//       }
//     })
//     this.setState({
//       allowed_audit_yearly,
//       publishTemplate
//     },()=>{
//       this.updatePublishTemplateData()
//     })
//   }

//   allowedAuditForMonth=(event,item,index,day)=>{
//     var allowed_audit_monthy =[...this.state.allowed_audit_monthy]
//     var publishTemplate ={...this.state.publishTemplate}
//     if(item !== undefined){
//     item["checked"] = true
//     publishTemplate.repeat_mode_config["allowed_audit_monthy"]=item
//     allowed_audit_monthy.map((ele,idx)=>{
//       if(index !== idx){
//         allowed_audit_monthy[idx]["checked"] = false
//       }
//       else{
//         allowed_audit_monthy[idx]["checked"] = true

//       }
//     })

//     this.setState({
//       allowed_audit_monthy,
//       publishTemplate
//     },()=>{
//       this.updatePublishTemplateData()
//     })
//   }
//   else{
//     if(index === "2"){
//     publishTemplate.repeat_mode_config["allowed_audit_monthy"][event.target.name]=this.state.specific_dates
//     console.log(publishTemplate,'publishTemplate')
//     }
//     if(index === "3"){
//       console.log(event.target.value,'event.target.value')
//       day["checked"]=true
//       publishTemplate.repeat_mode_config["allowed_audit_monthy"][event.target.name]=day

//     }
//     this.setState({
//       // allowed_audit_monthy,
//       publishTemplate
//     },()=>{
//       this.updatePublishTemplateData()
//     })
//   }
//     // if(event.target.checked === true){
//     //   allowed_audit_monthy[index]["checked"]= true
//     // }
//   }

//   handleShowTimeFrame=(event)=>{
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate["repeat_mode_config"]["show_time_frame"]=event.target.checked
//     this.setState({
//       publishTemplate
//     },()=>{
//       this.updatePublishTemplateData()
//     })
//   }


//   appendDayofMonth=(event,item)=>{
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate["repeat_mode_config"]["allowed_audit_monthy"]["position_of_the_week"]=item
//     this.setState({
//       publishTemplate
//     },()=>{
//       this.updatePublishTemplateData()
//     })
//   }

//   calendarType=(event)=>{
//     console.log(event.target.value,event)
//     var publishTemplate ={...this.state.publishTemplate}

//     publishTemplate["repeat_mode_config"]["calendar_type"]=event.target.value
//     publishTemplate["repeat_mode_config"]["selected_quater"]=null
//     publishTemplate["repeat_mode_config"]["selected_half_yearly"]=null
//     publishTemplate["repeat_mode_config"]["selected_months"]=null

   

//     this.setState({
//       publishTemplate
//     },()=>{
//       this.updatePublishTemplateData()

//     })

//   }


//   periodicityMonths=(events,data,idx,mode)=>{
//     console.log(data,idx,this.state.selected_quater_months)
//     var publishTemplate = {...this.state.publishTemplate}
//     if (mode === "4") {
//       var selected_quater_months = [...this.state.selected_quater_months]
//       publishTemplate["repeat_mode_config"]["selected_months"] = data
//     }
//     if (mode === "5") {
//       publishTemplate["repeat_mode_config"]["selected_months"] = data

//     }
//     this.setState({
//       publishTemplate
//     },()=>{
//       this.updatePublishTemplateData()
//     })

//   }


//   everyType=(event,mode)=>{
//     console.log(event.target.value)
//     if(mode === "1"){
//       // var data ={
//       //   selected_repeat : event.target.value,
//       //   repea
//       // }
//       var repeat_mode_config = {...this.state.repeat_mode_config}
//       repeat_mode_config["selected_repeat"] =event.target.value
//     this.setState({
//       selected_every_type : event.target.value,
//       repeat_mode_config
      
//     },()=>{
//       console.log(this.state.repeat_mode_config,'repeat_mode_config')
//     })
//     }
//     if(mode === "2"){
//       var repeat_mode_config ={...this.state.repeat_mode_config}
//       repeat_mode_config["repeat_on"]=event.target.value
//       repeat_mode_config["selected_day"]=null 
//       repeat_mode_config["selected_year_month_type"]=null
//       repeat_mode_config["selected_year_month_type_on"]=null



//       this.setState({
//         selected_day_type : repeat_mode_config.selected_repeat === "Year" ? "" :event.target.value,
//         repeat_mode_config,
//         selected_year_type :repeat_mode_config.selected_repeat === "Year" ? event.target.value :""
//       },()=>{
//       console.log(this.state.repeat_mode_config,'repeat_mode_config')

//       })
//     }
//     if(mode === "3"){
//     console.log(event.target.value)

//       var repeat_mode_config ={...this.state.repeat_mode_config}
//       repeat_mode_config["selected_day"]=event.target.value
//       this.setState({
//         selected_year_type : event.target.value,
//         repeat_mode_config
//       },()=>{
//       console.log(this.state.repeat_mode_config,'repeat_mode_config')

//       })
//     }
//     if(mode === "4"){
//       console.log(event.target.value)
  
//         var repeat_mode_config ={...this.state.repeat_mode_config}
//         repeat_mode_config["selected_year_month_type"]=event.target.value
//         this.setState({
//           // selected_year_type : event.target.value,
//           repeat_mode_config
//         },()=>{
//         console.log(this.state.repeat_mode_config,'repeat_mode_config')
  
//         })
//       }
//       if(mode === "5"){
//         console.log(event.target.value)
    
//           var repeat_mode_config ={...this.state.repeat_mode_config}
//           repeat_mode_config["selected_year_month_type_on"]=event.target.value
//           this.setState({
//             // selected_year_type : event.target.value,
//             repeat_mode_config
//           },()=>{
//           console.log(this.state.repeat_mode_config,'repeat_mode_config')
    
//           })
//         }
//         if(mode === "6"){
//           var repeat_mode_config ={...this.state.repeat_mode_config}
//           console.log(event.target.name)
//           if(event.target.name === "never"){
//           repeat_mode_config["ends"]=event.target.name
//           repeat_mode_config["ends_based_on"]=event.target.name

//           }
//           if(event.target.name === 'end_date'){
//             repeat_mode_config["ends"]=event.target.value
//           repeat_mode_config["ends_based_on"]=event.target.name
//           }
//           if(event.target.name === 'after'){
//           repeat_mode_config["ends_based_on"]=event.target.name

//           }
//           this.setState({
//             repeat_mode_config
//           },()=>{
//           console.log(this.state.repeat_mode_config,'repeat_mode_config')
    
//           })


//         }
//   }





//   repeatModeComponent(mode) {
//     return (
//       <Form action="#">

//         {
//           mode == "0" ?

//             <div>
//             </div>
//             :
//             mode == "1" ?

//               <Row >
//                 <Col md={2}>

//                 </Col>
//                 <Col md={7}>
//                   <Container fluid>
//                     <Card style={{ border: "1px solid #bcbdbd" }}>
//                       <CardBody>
//                         <label>Repeat Every :</label>&nbsp; <input type={"number"}
//                           onChange={(e) => {
//                             var repeat_mode_config = { ...this.state.repeat_mode_config }
//                             repeat_mode_config["repeat_duration"] = e.target.value
//                             this.setState({
//                               repeat_mode_config
//                             }, () => {
//                               console.log(this.state.repeat_mode_config, "repeat_mode_config")
//                             })
//                           }}
//                           defaultValue={1} max={30} min={1} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span> &nbsp; :
//                         <select onChange={(e) => { this.everyType(e, "1") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }}>
//                           <option>Select</option>
//                           <option >Day</option>
//                           <option>Week</option>
//                           <option>Month</option>
//                           <option>Year</option>
//                         </select>&nbsp;
//                         {
//                           this.state.selected_every_type === "Day" &&
//                           <>
//                             {
//                               console.log("opens on")
//                             }
//                             <Row style={{ marginTop: "13px" }}>
//                               <Col md={3}>
//                                 <label>Repeat Mode Starts :<span className="text-danger">*</span></label>&nbsp;
//                                 <div>
//                                   <input type={"radio"}
//                                     name="starts_on"
//                                     id="starts_on"
//                                     checked={this.state.repeat_mode_config?.starts_on !== "immediate" ? true : false}
//                                   />&nbsp;
//                                   <label htmlFor="starts_on">On</label> &nbsp;
//                                   <input
//                                     type={"date"}
//                                     name="starts_on"
//                                     htmlFor="starts_on"
//                                     onChange={(e) => {
//                                       var repeat_mode_config = { ...this.state.repeat_mode_config }
//                                       repeat_mode_config[e.target.name] = e.target.value
//                                       this.setState({
//                                         repeat_mode_config
//                                       }, () => {
//                                         console.log(this.state.repeat_mode_config, "repeat")
//                                       })
//                                     }}
//                                   />
//                                 </div>
//                                 <div>
//                                   <input type={"radio"}
//                                     name="immediate"
//                                     id="immediate"
//                                     checked={this.state.repeat_mode_config?.starts_on === "immediate" ? true : false}

//                                     onChange={(e) => {
//                                       var repeat_mode_config = { ...this.state.repeat_mode_config }
//                                       repeat_mode_config["starts_on"] = e.target.name
//                                       this.setState({
//                                         repeat_mode_config
//                                       })
//                                     }}
//                                   />&nbsp;
//                                   <label htmlFor="immediate" style={{ marginLeft: "4px" }} >Immediately</label> &nbsp;
//                                 </div>

//                               </Col>


//                             </Row>


//                             <Row style={{ marginTop: "13px" }}>
//                               <Col md={3}>
//                                 <label>Audit Allowed Between:</label>&nbsp;
//                                 <input
//                                   className="form-control mx-4  "
//                                   type="time"
//                                   onChange={(e) => {
//                                     // console.log(e.target.value)
//                                     var repeat_mode_config = { ...this.state.repeat_mode_config }
//                                     repeat_mode_config[e.target.name] = e.target.value
//                                     this.setState({
//                                       repeat_mode_config
//                                     }, () => {
//                                       console.log(this.state.repeat_mode_config, 'config')
//                                     })
//                                     //  this.setTimeFrame(e)
//                                   }}
//                                   name="start_time"
//                                   defaultValue="00:00:00"
//                                   id="example-time-input"
//                                 />
//                               </Col>
//                               <Col md={3}>
//                                 {/* <label>Audit Close on:</label>&nbsp; */}
//                                 <label></label>
//                                 <input
//                                   className="form-control mx-4  "
//                                   type="time"
//                                   style={{marginTop:"7px"}}
//                                   onChange={(e) => {
//                                     // console.log(e.target.value)
//                                     var repeat_mode_config = { ...this.state.repeat_mode_config }
//                                     repeat_mode_config[e.target.name] = e.target.value
//                                     this.setState({
//                                       repeat_mode_config
//                                     }, () => {
//                                       console.log(this.state.repeat_mode_config, 'config')
//                                     })
//                                     //  this.setTimeFrame(e)
//                                   }}
//                                   name="end_time"
//                                   defaultValue="23:59:00"
//                                   id="example-time-input"
//                                 />
//                               </Col>
//                             </Row>

//                           </>
//                         }
//                         {/* <Flatpickr
//                           className="form-control d-block"
//                           placeholder="Select time"
//                           onChange={(e)=>{
//                             // console.log(e,'e')
//                             var full_date = new Date(e)
//                             var hours = full_date.getHours()
//                             var minute = full_date.getMinutes()
//                             var second = full_date.getSeconds()
//                             console.log(`${hours}${minute}${second}`)

//                           }}
//                           options={{
//                             enableTime: true,
//                             noCalendar: true,
//                             dateFormat: "H:i"
//                           }}
//                         /> */}
//                         {
//                           this.state.selected_every_type !== "Day" &&this.state.selected_every_type !== "Week"&&
//                           // this.state.repeat_mode_config?.selected_every_type !== undefined &&
//                           <>
//                             {"ON"}&nbsp;
//                             {
//                               this.state.selected_every_type === "Week" ?
//                                 <select onChange={(e) => { this.everyType(e, "2") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                   <option >Select</option>
//                                   <option>Start of a week</option>
//                                   <option>Day</option>
//                                   <option>End of a week</option>
//                                   <option>After</option>
//                                 </select>
//                                 :
//                                 this.state.selected_every_type === "Year" ?
//                                   <select onChange={(e) => { this.everyType(e, "2") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                     <option disabled={true}>Select</option>
//                                     <option>Start</option>
//                                     <option>Month</option>
//                                     <option>End</option>
//                                     <option>After</option>
//                                   </select>
//                                   :
//                                   <select onChange={(e) => { this.everyType(e, "2") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                     <option >Select</option>
//                                     <option>Start of a month</option>
//                                     <option>Week</option>
//                                     <option>Day</option>
//                                     <option>End of a month</option>
//                                     <option>After</option>
//                                   </select>


//                             }

//                             {
//                               this.state.selected_day_type === "Day" &&
//                               <select onChange={(e) => { this.everyType(e, "3") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                 <option disabled={true}>Select</option>
//                                 <option>Monday</option>
//                                 <option>Tuesday</option>
//                                 <option>Wednesday</option>
//                                 <option>Thursday</option>
//                                 <option>Friday</option>
//                                 <option>Saturday</option>
//                                 <option>Sunday</option>


//                               </select>
//                             }
//                             {
//                               this.state.repeat_mode_config?.repeat_on === "Month" &&
//                               <select onChange={(e) => { this.setState({ selected_month_type: true }, () => { this.everyType(e, "3") }) }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                 <option disabled={true}>Select</option>
//                                 <option>January</option>
//                                 <option>Febraury</option>
//                                 <option>March</option>
//                                 <option>April</option>
//                                 <option>May</option>
//                                 <option>June</option>
//                                 <option>July</option>
//                                 <option>August</option>
//                                 <option>September</option>
//                                 <option>October</option>
//                                 <option>November</option>
//                                 <option>December</option>
//                               </select>
//                             }
//                             {
//                               this.state.selected_year_type === "After" &&
//                               <>
//                                 <label>a Audit is completed</label>
//                                 {/* &nbsp;<input type={"number"} defaultValue={1} max={30} min={1}  style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> &nbsp; Occurences */}
//                               </>
//                             }
//                             {
//                               this.state.selected_day_type === "Month" &&
//                               <select onChange={(e) => { this.everyType(e, "3") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                 {/* <option>Monday</option>
//                             <option>Tuesday</option>
//                             <option>Wednesday</option>
//                             <option>Thursday</option>
//                             <option>Friday</option>
//                             <option>Saturday</option>
//                             <option>Sunday</option> */}

//                                 <option>1</option>
//                                 <option>2</option>
//                                 <option>3</option>
//                                 <option>4</option>
//                                 <option>5</option>
//                                 <option>6</option>
//                                 <option>7</option>
//                                 <option>8</option>
//                                 <option>9</option>
//                                 <option>10</option>
//                                 <option>11</option>
//                                 <option>12</option>
//                                 <option>13</option>
//                                 <option>14</option>
//                                 <option>15</option>
//                                 <option>16</option>
//                                 <option>17</option>
//                                 <option>18</option>
//                                 <option>19</option>
//                                 <option>20</option>
//                                 <option>21</option>
//                                 <option>22</option>
//                                 <option>23</option>
//                                 <option>24</option>
//                                 <option>25</option>
//                                 <option>26</option>
//                                 <option>27</option>
//                                 <option>28</option>
//                                 <option>29</option>
//                                 <option>30</option>


//                               </select>
//                             }
//                             {
//                               this.state.selected_day_type === "Week" &&
//                               <select onChange={(e) => { this.everyType(e, "3") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                 <option disabled={true}>Select</option>
//                                 <option>1st Week</option>
//                                 <option>2nd Week</option>
//                                 <option>3rd Week</option>
//                                 <option>4th Week</option>
//                               </select>
//                             }
//                             {
//                               this.state.selected_month_type === true &&
//                               <select onChange={(e) => { this.setState({ selected_year_month_type: e.target.value }, () => this.everyType(e, "4")) }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                 <option disabled={true}>Select</option>
//                                 <option>Start of a month</option>
//                                 <option>Week</option>
//                                 <option>Day</option>
//                                 <option>End of a month</option>
//                               </select>

//                             }
//                             {
//                               this.state.selected_year_month_type === "Week" &&
//                               <select onChange={(e) => { this.everyType(e, "5") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                 <option disabled={true}>Select</option>
//                                 <option>1st Week</option>
//                                 <option>2nd Week</option>
//                                 <option>3rd Week</option>
//                                 <option>4th Week</option>
//                               </select>
//                             }
//                             {
//                               this.state.selected_year_month_type === "Day" &&

//                               <select onChange={(e) => { this.everyType(e, "5") }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '92px' }} >
//                                 <option>1</option>
//                                 <option>2</option>
//                                 <option>3</option>
//                                 <option>4</option>
//                                 <option>5</option>
//                                 <option>6</option>
//                                 <option>7</option>
//                                 <option>8</option>
//                                 <option>9</option>
//                                 <option>10</option>
//                                 <option>11</option>
//                                 <option>12</option>
//                                 <option>13</option>
//                                 <option>14</option>
//                                 <option>15</option>
//                                 <option>16</option>
//                                 <option>17</option>
//                                 <option>18</option>
//                                 <option>19</option>
//                                 <option>20</option>
//                                 <option>21</option>
//                                 <option>22</option>
//                                 <option>23</option>
//                                 <option>24</option>
//                                 <option>25</option>
//                                 <option>26</option>
//                                 <option>27</option>
//                                 <option>28</option>
//                                 <option>29</option>
//                                 <option>30</option>

//                               </select>


//                             }
//                             {
//                               this.state.selected_day_type === "After" &&
//                               <>
//                                 <label>a Audit is completed</label>
//                                 {/* &nbsp;<input type={"number"} defaultValue={1} max={30} min={1}  style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> &nbsp; Occurences */}
//                               </>
//                             }
//                           </>
//                         }
//                         {
//                           this.state.selected_every_type ==="Week" &&
//                           <>
//                           <Row style={{ marginTop: "13px" }}>
//                           <Col md={3}>
//                             <label>Repeat Mode Starts :<span className="text-danger">*</span></label>&nbsp;
//                             <div>
//                               <input type={"radio"}
//                                 name="starts_on"
//                                 id="starts_on"
//                                 checked={this.state.repeat_mode_config?.starts_on !== "immediate" ? true : false}
//                               />&nbsp;
//                               <label htmlFor="starts_on">On</label> &nbsp;
//                               <input
//                                 type={"date"}
//                                 name="starts_on"
//                                 htmlFor="starts_on"
//                                 onChange={(e) => {
//                                   var repeat_mode_config = { ...this.state.repeat_mode_config }
//                                   repeat_mode_config[e.target.name] = e.target.value
//                                   this.setState({
//                                     repeat_mode_config
//                                   }, () => {
//                                     console.log(this.state.repeat_mode_config, "repeat")
//                                   })
//                                 }}
//                               />
//                             </div>
//                             <div>
//                               <input type={"radio"}
//                                 name="immediate"
//                                 id="immediate"
//                                 checked={this.state.repeat_mode_config?.starts_on === "immediate" ? true : false}

//                                 onChange={(e) => {
//                                   var repeat_mode_config = { ...this.state.repeat_mode_config }
//                                   repeat_mode_config["starts_on"] = e.target.name
//                                   this.setState({
//                                     repeat_mode_config
//                                   })
//                                 }}
//                               />&nbsp;
//                               <label htmlFor="immediate" style={{ marginLeft: "4px" }} >Immediately</label> &nbsp;
//                             </div>

//                           </Col>


//                         </Row>
//                         {
//                           this.state.selected_every_type === "Week" &&
//                           <div>
//                             <Label className="text-primary" style={{ marginTop: "11px" }} htmlFor="autoSizingSelect">Allowed Audits On :</Label>

//                             <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

//                               <div style={{
//                                 display: 'flex', flexDirection: 'row',
//                                 //  marginBottom: 10 
//                               }}>
//                                 <div key={""}
//                                   className="form-check mx-2 mt-2 ">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input"
//                                     checked={this.state.all_checked === true ? true : false}
//                                     // checked={_.filter(this.state.weekDays,{checked : true}).length === this.state.weekDays ? true : false}
//                                     // id={item.day}
//                                     // checked={
//                                     //   var week
//                                     // }
//                                     // onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
//                                     onChange={(event) => {
//                                       var weekDays = [...this.state.weekDays]
//                                       weekDays = _.map(weekDays, obj => ({ ...obj, checked: event.target.checked }))
//                                       console.log(weekDays, 'weekDays')
//                                       this.setState({
//                                         weekDays,
//                                         all_checked: event.target.checked
//                                       })
//                                       // weekDays[idx]["checked"]=event.target.checked
//                                       // this.setState({
//                                       //   weekDays
//                                       // })
//                                     }}

//                                   />
//                                   <label
//                                     className="form-check-label"
//                                   // htmlFor={item.day}
//                                   >
//                                     {/* {item.day} */}
//                                     All Days
//                                   </label>
//                                 </div>
//                                 {
//                                   this.state.weekDays.map((item, idx) => {
//                                     return (
//                                       <div key={idx}
//                                         className="form-check mx-2 mt-2 ">
//                                         <input
//                                           type="checkbox"
//                                           className="form-check-input"
//                                           id={item.day}
//                                           checked={item.checked}
//                                           // onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
//                                           onChange={(event) => {
//                                             var weekDays = [...this.state.weekDays]
//                                             weekDays[idx]["checked"] = event.target.checked
//                                             this.setState({
//                                               weekDays
//                                             })
//                                           }}

//                                         />
//                                         <label
//                                           className="form-check-label"
//                                           htmlFor={item.day}
//                                         >
//                                           {item.day}
//                                         </label>
//                                       </div>
//                                     )
//                                   })
//                                 }
//                               </div>
//                             </div>
//                           </div>


//                         }
//                           <Row style={{ marginTop: "13px" }}>
//                           <Col md={3}>
//                             <label>Audit Allowed Between:</label>&nbsp;
//                             <input
//                               className="form-control mx-4  "
//                               type="time"
//                               onChange={(e) => {
//                                 // console.log(e.target.value)
//                                 var repeat_mode_config = { ...this.state.repeat_mode_config }
//                                 repeat_mode_config[e.target.name] = e.target.value
//                                 this.setState({
//                                   repeat_mode_config
//                                 }, () => {
//                                   console.log(this.state.repeat_mode_config, 'config')
//                                 })
//                                 //  this.setTimeFrame(e)
//                               }}
//                               name="start_time"
//                               defaultValue="00:00:00"
//                               id="example-time-input"
//                             />
//                           </Col>
//                           <Col md={3}>
//                             {/* <label>Audit Close on:</label>&nbsp; */}
//                             <input
//                               style={{marginTop:"28px"}}
//                               className="form-control mx-4  "
//                               type="time"
//                               onChange={(e) => {
//                                 // console.log(e.target.value)
//                                 var repeat_mode_config = { ...this.state.repeat_mode_config }
//                                 repeat_mode_config[e.target.name] = e.target.value
//                                 this.setState({
//                                   repeat_mode_config
//                                 }, () => {
//                                   console.log(this.state.repeat_mode_config, 'config')
//                                 })
//                                 //  this.setTimeFrame(e)
//                               }}
//                               name="end_time"
//                               defaultValue="23:59:00"
//                               id="example-time-input"
//                             />
//                           </Col>
//                         </Row>
//                         </>
//                         }
//                         {
//                           this.state.repeat_mode_config?.starts_on !== undefined &&
//                           <>
//                             <div style={{ marginTop: "15px" }}>
//                               <label>Ends :<span className="text-danger">*</span></label>
//                             </div>
//                             <div>
//                               <input type={"radio"}
//                                 checked={this.state.repeat_mode_config?.ends_based_on == "never" ? true : false}
//                                 name="never" id="never" onChange={(e) => { this.everyType(e, "6") }} />&nbsp;<label htmlFor="never">Never</label>
//                             </div>
//                             <div>
//                               <input type={"radio"}
//                                 name="end_date"
//                                 checked={this.state.repeat_mode_config?.ends_based_on == "end_date" ? true : false}
//                                 id="On" onChange={(e) => { this.everyType(e, "6") }} />&nbsp;<label htmlFor="On">On</label> &nbsp;
//                               <input
//                                 type={"date"}
//                                 name="end_date"
//                                 min={this.state.repeat_mode_config?.selected_repeat === "Week" ? this.getMinDate() : this.state.repeat_mode_config?.starts_on !== "immediate" ? this.state.repeat_mode_config?.starts_on :this.state.repeat_mode_config?.selected_repeat ==="Week"?  new Date(new Date().toISOString().split('T')[0]).setDate(new Date().getDate() + 7).toISOString().split('T')[0]:new Date("2023-11-29").toISOString().split('T')[0]}
//                                 htmlFor="On"
//                                 onChange={(e) => { this.everyType(e, "6") }}
//                               />
//                             </div>
//                             <div>
//                               <input type={"radio"} id="after" name="after" checked={this.state.repeat_mode_config?.ends_based_on == "after" ? true : false} onChange={(e) => { this.everyType(e, "6") }} />&nbsp;<label htmlFor="after">After</label> &nbsp;<input type={"number"}
//                                 onChange={(e) => {
//                                   var occurence = e.target.value
//                                   var repeat_mode_config = { ...this.state.repeat_mode_config }
//                                   repeat_mode_config["occurence"] = occurence
//                                   this.setState({
//                                     repeat_mode_config
//                                   })
//                                 }}
//                                 defaultValue={1} max={30} min={1} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> &nbsp; Occurences

//                             </div>
//                             {
//                               console.log(this.state.repeat_mode_config, 'this.state.repeat_mode_config')
//                             }
//                             {
//                               this.state.repeat_mode_config !== undefined &&  this.state.repeat_mode_config?.ends_on !== null && this.state.repeat_mode_config?.ends_on !== "" &&
//                               <div
//                                 className="form-check form-switch form-switch-sm mt-2"
//                                 style={{
//                                   display: 'flex', flexDirection: 'row', alignItems: 'center',
//                                 }}
//                               >

//                                 <input
//                                   type="checkbox"
//                                   className="form-check-input me-2 mb-0"
//                                   onChange={(e) => {
//                                     this.setState({
//                                       preview_calendar: !this.state.preview_calendar
//                                     })
//                                   }}
//                                 />

//                                 <label
//                                   style={{ marginBottom: "0px" }}
//                                 >
//                                   Preview in Calendar
//                                 </label>
//                               </div>
//                             }
//                             {
//                               this.state.preview_calendar &&
//                               <CalendarPreview weekDays={this.state.repeat_mode_config.selected_repeat === "Week" ? this.state.weekDays : []} event_data={this.state.repeat_mode_config} occurence={this.state.repeat_mode_config.occurence !== undefined ? true : false} />
//                             }
//                           </>
//                         }


//                       </CardBody>
//                     </Card>
//                   </Container>
//                 </Col>
//                 <Col md={3}>

//                 </Col>
//               </Row>
//               :
//               null

//         }

//       </Form>
//     )
//   }







//   // repeatModeComponent(mode) {
//   //   return (
//   //     <Form action="#">

//   //       {
//   //         mode == "0" ?

//   //           <div>
//   //             {/* <div className="mb-3 d-flex flex-column  ">
//   //               <div className="d-flex flex-row align-items-center">
//   //                 <div className="col-3">
//   //                   <Label className="text-primary" htmlFor="autoSizingSelect">Start date</Label>
//   //                   <div className="col-md-10">
//   //                     <input
//   //                       className="form-control"
//   //                       type="date"
//   //                       min={this.state.fromate_date}
//   //                       selected={this.state.ptl_startdate}
//   //                       defaultValue={this.state.ptl_startdate}
//   //                       id="start_date"
//   //                       onChange={event => this.onChangeStartDate(event)}
//   //                     />
//   //                   </div>
//   //                 </div>
//   //                 <div className="col-3">
//   //                   <Label className="text-primary" htmlFor="autoSizingSelect">End date</Label>
//   //                   <div className="col-md-10">
//   //                     <input
//   //                       className="form-control"
//   //                       type="date"
//   //                       min={this.state.fromate_date}
//   //                       selected={this.state.ptl_enddate}
//   //                       defaultValue={this.state.ptl_enddate}
//   //                       id="end_date"
//   //                       onChange={event => this.onChangeEndDate(event)}
//   //                     />
//   //                   </div>
//   //                 </div>

//   //               </div>
//   //             </div> */}
//   //           </div>
//   //           :
//   //           mode == "1" ?

//   //             <div>
//   //               <Row >
//   //                 {
//   //                   <React.Fragment>
//   //                     <Row>
//   //                       <Col md={12}>
//   //                         <div className="form-check mb-1">
//   //                           <input
//   //                             className="form-check-input mt-2 "
//   //                             type="radio"
//   //                             onChange={(e) => { this.endAfterMethod(e) }}
//   //                             name="occurence"
//   //                             id="occurence"
//   //                             checked={this.state.selectedRadioOption === "occurence"}
//   //                             defaultChecked
//   //                           />
//   //                           <label
//   //                             className="form-check-label"
//   //                             htmlFor="occurence"
//   //                           >
//   //                             No. of Occurences
//   //                           </label>
//   //                           <input type={"number"} max={30} min={0} onChange={(e) => { this.handleOccurance(e,mode) }} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>
//   //                           {(this.state.occurence === undefined || this.state.occurence == "") && this.state.selectedRadioOption === "occurence" ?
//   //                             <span className="text-danger" style={{ fontSize: "smaller" }}> Please Enter the No.of.days</span>
//   //                             :
//   //                             null}
//   //                         </div>
//   //                         {
//   //                           this.state.selectedRadioOption === "occurence" && this.state.occurence !== null && this.state.occurence !== undefined && this.state.occurence !== "" &&

//   //                           <Row>
//   //                             <Col md={12}>
//   //                               <div className="d-flex gap-3">
//   //                                 <div>
//   //                                   <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
//   //                                   <FormGroup className="mb-3">
//   //                                     <input
//   //                                       className="form-control mx-4 "
//   //                                       type="date"
//   //                                       onChange={(e) => { this.scheduleStartDate(e,mode) }}
//   //                                       min={this.state.fromate_date}
//   //                                       value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
//   //                                     />
//   //                                   </FormGroup>
//   //                                 </div>
//   //                                 <div>
//   //                                   {
//   //                                     this.state.publishTemplate.repeat_mode_config.start_date !== null &&
//   //                                     <>
//   //                                       <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date</Label>
//   //                                       <FormGroup className="mb-3">
//   //                                         <input
//   //                                           className="form-control mx-4 "
//   //                                           type="date"
//   //                                           value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
//   //                                           disabled={true}
//   //                                         />
//   //                                       </FormGroup>
//   //                                     </>
//   //                                   }

//   //                                 </div>
//   //                               </div>
//   //                             </Col>

//   //                           </Row>
//   //                         }


//   //                       </Col>
//   //                     </Row>
//   //                     <Row>
//   //                       {
//   //                         this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.selectedRadioOption === "occurence" &&
//   //                         <Col md={12}>

//   //                           <Label className="text-primary mx-4 " htmlFor="autoSizingSelect">Exclude Days</Label>

//   //                           <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

//   //                             <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                               {
//   //                                 this.state.weekDays.map((item, idx) => {
//   //                                   return (
//   //                                     <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                       <input
//   //                                         type="checkbox"
//   //                                         className="form-check-input"
//   //                                         id={item.day}
//   //                                         checked={item.checked}
//   //                                         onChange={e => { this.excludeWeeks(e, item, idx,mode) }}

//   //                                       />
//   //                                       <label
//   //                                         className="form-check-label"
//   //                                         htmlFor={item.day}
//   //                                       >
//   //                                         {item.day}
//   //                                       </label>
//   //                                     </div>
//   //                                   )
//   //                                 })
//   //                               }
//   //                             </div>
//   //                           </div>
//   //                         </Col>
//   //                       }
//   //                     </Row>
//   //                     <Row>
//   //                       <Col md={6}>

//   //                         {
//   //                           this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.selectedRadioOption === "occurence" &&
                            
//   //                           <>
//   //                             <Row>
//   //                               <Col md={5}>
//   //                                 <label className="text-primary mx-4 my-1">
//   //                                   Show Time Frame
//   //                                 </label>
                                 
//   //                                   <input
//   //                                     className="form-check-input"
//   //                                     type="checkbox"
//   //                                     // name="report"
//   //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
//   //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
//   //                                     style={{marginTop:"7px"}}
//   //                                   />
                                  
//   //                               </Col>
//   //                             </Row>
//   //                             {
//   //                               this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true && 
//   //                               <Row>
//   //                               <Col md={12}>
//   //                                 <div className="d-flex gap-5 ">
//   //                                   <div>
//   //                                     <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
//   //                                     <FormGroup className="mb-3">
//   //                                       <input
//   //                                         className="form-control mx-4  "
//   //                                         type="time"
//   //                                         onChange={(e) => { this.setTimeFrame(e) }}
//   //                                         name="start_time"
//   //                                         defaultValue="00:00:00"
//   //                                         id="example-time-input"
//   //                                       />
//   //                                     </FormGroup>
//   //                                   </div>
//   //                                   <div className="mx-2">
//   //                                     {
//   //                                       this.state.publishTemplate.repeat_mode_config.start_date !== null &&
//   //                                       <>
//   //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
//   //                                         <FormGroup className="mb-3">
//   //                                           <input
//   //                                             className="form-control mx-4"
//   //                                             type="time"
//   //                                             name="end_time"
//   //                                             defaultValue="23:59:00"
//   //                                             onChange={(e) => { this.setTimeFrame(e) }}
//   //                                             id="example-time-input"
//   //                                           />
//   //                                         </FormGroup>
//   //                                       </>
//   //                                     }
//   //                                   </div>
//   //                                 </div>
//   //                               </Col>
//   //                             </Row>
//   //                            }
                            
//   //                           </>
//   //                         }
//   //                       </Col>

//   //                     </Row>
//   //                     <Row>
//   //                       <Col md={12}>
//   //                         <div className="form-check">
//   //                           <input
//   //                             className="form-check-input"
//   //                             type="radio"
//   //                             onChange={(e) => { this.endAfterMethod(e) }}
//   //                             name="enddate"
//   //                             id="enddate"
//   //                             checked={this.state.selectedRadioOption === "enddate"}
//   //                           />
//   //                           <label
//   //                             className="form-check-label"
//   //                             htmlFor="enddate"
//   //                           >
//   //                             Date Range
//   //                           </label>
//   //                         </div>
//   //                         {
//   //                           this.state.selectedRadioOption === "enddate" &&
//   //                           <>
//   //                             <Row>
//   //                               <Col md={12}>
//   //                                 <div className="d-flex gap-3">
//   //                                   <div>
//   //                                     <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
//   //                                     <FormGroup className="mb-3">
//   //                                       <input
//   //                                         className="form-control mx-4 "
//   //                                         type="date"
//   //                                         onChange={(e) => { this.scheduleStartDate(e,mode) }}
//   //                                         name="start_date"
//   //                                         min={this.state.fromate_date}
//   //                                         value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
//   //                                       />
//   //                                     </FormGroup>
//   //                                   </div>
//   //                                   <div>
//   //                                     <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
//   //                                     <FormGroup className="mb-3">
//   //                                       <input
//   //                                         className="form-control mx-4 "
//   //                                         type="date"
//   //                                         name="end_date"
//   //                                         min={this.state.publishTemplate.repeat_mode_config.start_date !== null ? this.state.publishTemplate.repeat_mode_config.start_date : this.state.fromate_date}
//   //                                         onChange={(e) => { this.scheduleStartDate(e,mode) }}
//   //                                         disabled={this.state.publishTemplate.repeat_mode_config.start_date === null}
//   //                                         value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
//   //                                       />
//   //                                     </FormGroup>
//   //                                   </div>
//   //                                 </div>
//   //                               </Col>
//   //                             </Row>
//   //                             <Row>
//   //                               {
//   //                                 this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null &&
//   //                                 <Col md={12}>

//   //                                   <Label className="text-primary mx-4 " htmlFor="autoSizingSelect">Exclude Days</Label>

//   //                                   <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

//   //                                     <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                       {
//   //                                         this.state.weekDays.map((item, idx) => {
//   //                                           return (
//   //                                             <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                               <input
//   //                                                 type="checkbox"
//   //                                                 className="form-check-input"
//   //                                                 id={item.day}
//   //                                                 checked={item.checked}
//   //                                                 onChange={e => { this.excludeWeeks(e, item, idx,mode) }}
//   //                                               />
//   //                                               <label
//   //                                                 className="form-check-label"
//   //                                                 htmlFor={item.day}
//   //                                               >
//   //                                                 {item.day}
//   //                                               </label>
//   //                                             </div>
//   //                                           )
//   //                                         })
//   //                                       }
//   //                                     </div>
//   //                                   </div>
//   //                                 </Col>
//   //                               }
//   //                             </Row>
//   //                             <Row>
//   //                               <Col md={12}>
//   //                                 {
//   //                                   this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null ?
//   //                                     <div className="text-danger p-1 px-0" style={{ marginLeft: "2%", fontSize: '0.8125rem' }}> Number of occurences between the Start Date({new Date(this.state.publishTemplate.repeat_mode_config.start_date).toLocaleDateString('en-GB')}) and End Date ({new Date(this.state.publishTemplate.repeat_mode_config.end_date).toLocaleDateString('en-GB')}) is <span className="text-danger">{this.state.no_of_occurance} </span> occurences</div>
//   //                                     :
//   //                                     null
//   //                                 }

//   //                               </Col>
//   //                             </Row>
//   //                           </>

//   //                         }
//   //                         <Row>
//   //                           <Col md={6}>
                         


//   //                             {
//   //                               this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.selectedRadioOption === "enddate" &&
//   //                               <>
//   //                                 <Row>
//   //                               <Col md={5}>
//   //                                 <label className="text-primary mx-4 my-1">
//   //                                   Show Time Frame
//   //                                 </label>
                                 
//   //                                   <input
//   //                                     className="form-check-input"
//   //                                     type="checkbox"
//   //                                     name="report"
//   //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
//   //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
//   //                                     style={{marginTop:"7px"}}
//   //                                   />
                                  
//   //                               </Col>
//   //                             </Row>
//   //                             {
//   //                                 this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true &&
                                  
//   //                                 <Row>
//   //                                 <Col md={12}>
//   //                                   <div className="d-flex gap-5 ">
//   //                                     <div>
//   //                                       <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
//   //                                       <FormGroup className="mb-3">
//   //                                         <input
//   //                                           className="form-control mx-4  "
//   //                                           type="time"
//   //                                           onChange={(e) => { this.setTimeFrame(e) }}
//   //                                           name="start_time"
//   //                                           defaultValue="00:00:00"
//   //                                           id="example-time-input"
//   //                                         />
//   //                                       </FormGroup>
//   //                                     </div>
//   //                                     <div className="mx-2">
//   //                                       <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
//   //                                       <FormGroup className="mb-3">
//   //                                         <input
//   //                                           className="form-control mx-4"
//   //                                           type="time"
//   //                                           name="end_time"
//   //                                           defaultValue="23:59:00"
//   //                                           onChange={(e) => { this.setTimeFrame(e) }}
//   //                                           id="example-time-input"
//   //                                         />
//   //                                       </FormGroup>
//   //                                     </div>
//   //                                   </div>
//   //                                 </Col>
//   //                               </Row>
//   //                               }
                                  
//   //                               </>
//   //                             }
//   //                           </Col>
//   //                         </Row>
//   //                       </Col>

//   //                     </Row>
//   //                   </React.Fragment>
//   //                 }
//   //               </Row>


//   //             </div> :
//   //             mode == "2" ?
//   //               <div>
//   //                 <React.Fragment>
//   //                   <Row>
//   //                     <Col md={12}>
//   //                       <div className="form-check mb-1">
//   //                         <input
//   //                           className="form-check-input mt-2 "
//   //                           type="radio"
//   //                           onChange={(e) => { this.endAfterMethod(e) }}
//   //                           name="occurence"
//   //                           id="occurence"
//   //                           checked={this.state.selectedRadioOption === "occurence"}
//   //                           defaultChecked
//   //                         />
//   //                         <label
//   //                           className="form-check-label"
//   //                           htmlFor="occurence"
//   //                         >
//   //                           No. of Occurences
//   //                         </label>
//   //                         <input type={"number"} max={30} min={0} onChange={(e) => { this.handleOccurance(e,mode) }} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>
//   //                         {(this.state.occurence === undefined || this.state.occurence == "") && this.state.selectedRadioOption === "occurence" ?
//   //                           <span className="text-danger" style={{ fontSize: "smaller" }}> Please Enter the No.of.days</span>
//   //                           :
//   //                           null}
//   //                       </div>
//   //                       {
//   //                         this.state.selectedRadioOption === "occurence" && this.state.occurence !== "" && this.state.occurence !== undefined && this.state.occurence !== null &&

//   //                         <Row>
//   //                           <Col md={12}>
//   //                             <div className="d-flex gap-3">
//   //                               <div>
//   //                                 <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
//   //                                 <FormGroup className="mb-3">
//   //                                   <input
//   //                                     className="form-control mx-4 "
//   //                                     type="date"
//   //                                     onChange={(e) => { this.scheduleStartDate(e,mode) }}
//   //                                     min={this.state.fromate_date}
//   //                                     value={this.state.publishTemplate.repeat_mode_config.start_date}
//   //                                   />
//   //                                 </FormGroup>
//   //                               </div>
//   //                               <div>
//   //                                 {
//   //                               this.state.publishTemplate.repeat_mode_config.start_date !== null &&
//   //                               <>
//   //                                <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Week Start Day <span className="text-danger">*</span></Label>
//   //                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

//   //                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                           {
//   //                                             this.state.publishTemplate.repeat_mode_config.days.map((item, idx) => {
//   //                                               return (
//   //                                                 <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                                   <input
//   //                                                     type="radio"
//   //                                                     className="form-check-input"
//   //                                                     id={item.day}
//   //                                                     checked={this.state.publishTemplate.repeat_mode_config.week_start_day !== null? this.state.publishTemplate.repeat_mode_config.week_start_day.day === item.day ? true : false : false}
//   //                                                     onChange={e => { this.weekStartDay(e, item, idx,mode) }}
//   //                                                   />
//   //                                                   <label
//   //                                                     className="form-check-label"
//   //                                                     htmlFor={item.day}
//   //                                                   >
//   //                                                     {item.day}
//   //                                                   </label>
//   //                                                 </div>
//   //                                               )
//   //                                             })
//   //                                           }
//   //                                         </div>
//   //                                       </div>
//   //                               </>
//   //                                }
//   //                               </div>
//   //                             </div>
//   //                           </Col>

//   //                         </Row>
//   //                       }
//   //                     </Col>
//   //                   </Row>
//   //                   <Row >
//   //                       <Col md={6}>
//   //                       {
//   //                               this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.publishTemplate.repeat_mode_config.week_start_day !== null && this.state.selectedRadioOption === "occurence" &&
//   //                               <>
//   //                                 <Row>
//   //                                   <Col md={4}>
//   //                                     <div className="d-flex gap-5 ">
//   //                                       <div>
//   //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Date</Label>
//   //                                         <FormGroup className="mb-3">
//   //                                           <input
//   //                                             className="form-control mx-4  "
//   //                                             type="date"
//   //                                             disabled={true}
//   //                                             value={this.state.publishTemplate.repeat_mode_config.end_date}
//   //                                             // onChange={(e) => { this.setTimeFrame(e) }}
//   //                                             // name="start_time"
//   //                                             // defaultValue="00:00:00"
//   //                                             // id="example-time-input"
//   //                                           />
//   //                                         </FormGroup>
//   //                                       </div>
//   //                                       </div>
//   //                                       </Col>
                             
//   //                           </Row>

//   //                           <Row>
//   //                           <Col md={6}>
//   //                               <Label className="text-primary mx-4 ">Allow to Take Audit</Label>
//   //                               <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                   {
//   //                                     this.state.publishTemplate.repeat_mode_config.days.map((item, idx) => {
//   //                                       return (
//   //                                         <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                           <input
//   //                                             type="checkbox"
//   //                                             className="form-check-input"
//   //                                             id={item.day}
//   //                                             // checked={item.checked}
//   //                                             checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days?.includes(Number(item.day_id)) ? true : false }
//   //                                             onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
//   //                                           />
//   //                                           <label
//   //                                             className="form-check-label"
//   //                                             // htmlFor={item.day}
//   //                                           >
//   //                                             {item.day}
//   //                                           </label>
//   //                                         </div>
//   //                                       )
//   //                                     })
//   //                                   }
//   //                                 </div>
//   //                               </div>
//   //                             </Col>
//   //                           </Row>
//   //                           <Row>
//   //                           <Col md={6}>

                           

//   //                             {
//   //                               this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.publishTemplate.repeat_mode_config.week_start_day !== null &&
//   //                               <>
//   //                                <Row>
//   //                               <Col md={12}>
//   //                                 <label className="text-primary mx-4 my-1">
//   //                                   Show Time Frame
//   //                                 </label>
                                 
//   //                                   <input
//   //                                     className="form-check-input"
//   //                                     type="checkbox"
//   //                                     name="report"
//   //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
//   //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
//   //                                     style={{marginTop:"7px"}}
//   //                                   />
                                  
//   //                               </Col>
//   //                             </Row>
//   //                             {
//   //                               this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true &&
//   //                                 <Row>
//   //                                   <Col md={12}>
//   //                                     <div className="d-flex gap-5 ">
//   //                                       <div>
//   //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
//   //                                         <FormGroup className="mb-3">
//   //                                           <input
//   //                                             className="form-control mx-4  "
//   //                                             type="time"
//   //                                             onChange={(e) => { this.setTimeFrame(e) }}
//   //                                             name="start_time"
//   //                                             defaultValue="00:00:00"
//   //                                             id="example-time-input"
//   //                                           />
//   //                                         </FormGroup>
//   //                                       </div>
//   //                                       <div className="mx-2">
//   //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
//   //                                         <FormGroup className="mb-3">
//   //                                           <input
//   //                                             className="form-control mx-4"
//   //                                             type="time"
//   //                                             name="end_time"
//   //                                             defaultValue="23:59:00"
//   //                                             onChange={(e) => { this.setTimeFrame(e) }}
//   //                                             id="example-time-input"
//   //                                           />
//   //                                         </FormGroup>
//   //                                       </div>
//   //                                     </div>
//   //                                   </Col>
//   //                                 </Row>
//   //                             }

//   //                               </>
//   //                             }
//   //                           </Col>
//   //                         </Row>
//   //                                       </>
//   //                         }                     
//   //                       </Col>
//   //                       <Col>
//   //                       </Col>

//   //                   </Row>
//   //                   <Row>
//   //                     <Col md={12}>
//   //                       <div className="form-check">
//   //                         <input
//   //                           className="form-check-input"
//   //                           type="radio"
//   //                           onChange={(e) => { this.endAfterMethod(e) }}
//   //                           name="enddate"
//   //                           id="enddate"
//   //                           checked={this.state.selectedRadioOption === "enddate"}
//   //                         />
//   //                         <label
//   //                           className="form-check-label"
//   //                           htmlFor="enddate"
//   //                         >
//   //                           Date Range
//   //                         </label>
//   //                       </div>
//   //                       {
//   //                         this.state.selectedRadioOption === "enddate" &&
//   //                         <>
//   //                           <Row>
//   //                             <Col md={12}>
//   //                               <div className="d-flex gap-3">
//   //                                 <div>
//   //                                   <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
//   //                                   <FormGroup className="mb-3">
//   //                                     <input
//   //                                       className="form-control mx-4 "
//   //                                       type="date"
//   //                                       onChange={(e) => { this.scheduleStartDate(e,mode) }}
//   //                                       name="start_date"
//   //                                       min={this.state.fromate_date}
//   //                                       value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
//   //                                     />
//   //                                   </FormGroup>
//   //                                 </div>
//   //                                 <div>
//   //                                   <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
//   //                                   <FormGroup className="mb-3">
//   //                                     <input
//   //                                       className="form-control mx-4 "
//   //                                       type="date"
//   //                                       name="end_date"
//   //                                       min={this.state.publishTemplate.repeat_mode_config.start_date !== null ? this.getMinDate() : this.state.fromate_date}
//   //                                       onChange={(e) => { this.scheduleStartDate(e,mode) }}
//   //                                       disabled={this.state.publishTemplate.repeat_mode_config.start_date === null}
//   //                                       value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
//   //                                     />
//   //                                   </FormGroup>
//   //                                 </div>
//   //                               </div>
//   //                             </Col>
//   //                           </Row>
//   //                           {
//   //                             this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.selectedRadioOption === "enddate" &&
//   //                          <>
//   //                          <Row>
//   //                           <Col md={6}>
//   //                               <Label className="text-primary mx-4 ">Allow to Take Audit</Label>
//   //                               <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                   {
//   //                                     this.state.publishTemplate.repeat_mode_config.days.map((item, idx) => {
//   //                                       return (
//   //                                         <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                           <input
//   //                                             type="checkbox"
//   //                                             className="form-check-input"
//   //                                             id={item.day}
//   //                                             // checked={item.checked}
//   //                                             checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
//   //                                             onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
//   //                                           />
//   //                                           <label
//   //                                             className="form-check-label"
//   //                                             // htmlFor={item.day}
//   //                                           >
//   //                                             {item.day}
//   //                                           </label>
//   //                                         </div>
//   //                                       )
//   //                                     })
//   //                                   }
//   //                                 </div>
//   //                               </div>
//   //                             </Col>
//   //                             </Row>
//   //                             <Row>
//   //                               <Col md={6}>
                               
//   //                                     <div className="text-danger p-1 px-0" style={{ marginLeft: "2%", fontSize: '0.8125rem' }}> Number of occurences between the Start Date({new Date(this.state.publishTemplate.repeat_mode_config.start_date).toLocaleDateString('en-GB')}) and End Date ({new Date(this.state.publishTemplate.repeat_mode_config.end_date).toLocaleDateString('en-GB')}) is <span className="text-danger">{this.state.no_of_occurance} </span> occurences</div>
                                   
//   //                               </Col>

//   //                             </Row>
//   //                             <Row>
//   //                                   <Col md={12}>
//   //                                   <Row>
//   //                               <Col md={12}>
//   //                                 <label className="text-primary mx-4 my-1">
//   //                                   Show Time Frame
//   //                                 </label>
                                 
//   //                                   <input
//   //                                     className="form-check-input"
//   //                                     type="checkbox"
//   //                                     name="report"
//   //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
//   //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
//   //                                     style={{marginTop:"7px"}}
//   //                                   />
                                  
//   //                               </Col>
//   //                             </Row>
//   //                             {
//   //                               this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true  &&
//   //                                     <div className="d-flex gap-5 ">
//   //                                       <div>
//   //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
//   //                                         <FormGroup className="mb-3">
//   //                                           <input
//   //                                             className="form-control mx-4  "
//   //                                             type="time"
//   //                                             onChange={(e) => { this.setTimeFrame(e) }}
//   //                                             name="start_time"
//   //                                             defaultValue="00:00:00"
//   //                                             id="example-time-input"
//   //                                           />
//   //                                         </FormGroup>
//   //                                       </div>
//   //                                       <div className="mx-2">
//   //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
//   //                                         <FormGroup className="mb-3">
//   //                                           <input
//   //                                             className="form-control mx-4"
//   //                                             type="time"
//   //                                             name="end_time"
//   //                                             defaultValue="23:59:00"
//   //                                             onChange={(e) => { this.setTimeFrame(e) }}
//   //                                             id="example-time-input"
//   //                                           />
//   //                                         </FormGroup>
//   //                                       </div>
//   //                                     </div>
//   //                             }

//   //                                   </Col>
//   //                                 </Row>
//   //                             </>
                              
//   //                           }

//   //                         </>
//   //                       }
//   //                     </Col>
//   //                   </Row>


//   //                 </React.Fragment>






//   //               </div>
//   //               // <div style={{ display: 'flex', flexDirection: 'Column' }}>

//   //               //   <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
//   //               //     {
//   //               //       this.state.weekDays.map((item, idx) => {
//   //               //         return (
//   //               //           <div className="form-check mx-2 ">
//   //               //             <input
//   //               //               type="checkbox"
//   //               //               className="form-check-input"
//   //               //               id={item.day}
//   //               //               checked={item.checked}
//   //               //               onChange={e => { this.handleWeekDays(e) }}
//   //               //             />
//   //               //             <label
//   //               //               className="form-check-label"
//   //               //               htmlFor={item.day}
//   //               //             >
//   //               //               {item.day}
//   //               //             </label>
//   //               //           </div>
//   //               //         )
//   //               //       })
//   //               //     }
//   //               //   </div>



//   //               // </div>
//   //               :
//   //                mode == "3" ?
//   //                <div>
//   //                <React.Fragment>
//   //                  <Row>
//   //                    <Col md={12}>
//   //                      <div className="form-check mb-1">
//   //                        <input
//   //                          className="form-check-input mt-2 "
//   //                          type="radio"
//   //                          onChange={(e) => { this.endAfterMethod(e) }}
//   //                          name="occurence"
//   //                          id="occurence"
//   //                          checked={this.state.selectedRadioOption === "occurence"}
//   //                          defaultChecked
//   //                        />
//   //                        <label
//   //                          className="form-check-label"
//   //                          htmlFor="occurence"
//   //                        >
//   //                          No. of Occurences
//   //                        </label>
//   //                        <input type={"number"} max={12} min={0} onChange={(e) => { this.handleOccurance(e,mode) }} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>
//   //                        {(this.state.occurence === undefined || this.state.occurence == "") && this.state.selectedRadioOption === "occurence" ?
//   //                          <span className="text-danger" style={{ fontSize: "smaller" }}> Please Enter the No.of.days</span>
//   //                          :
//   //                          null}
//   //                      </div>
//   //                      {
//   //                        this.state.selectedRadioOption === "occurence" && this.state.occurence !== undefined && this.state.occurence !== "" &&
//   //                                                <>
//   //                             <Row>
//   //                               <Col md={12}>
//   //                                 <div className="d-flex gap-3">
//   //                                   <div>
//   //                                     <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
//   //                                     <FormGroup className="mb-3">
//   //                                       <input
//   //                                         className="form-control mx-4 "
//   //                                         type="date"
//   //                                         onChange={(e) => { this.scheduleStartDate(e, mode) }}
//   //                                         name="start_date"
//   //                                         min={this.state.fromate_date}
//   //                                         value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
//   //                                       />
//   //                                     </FormGroup>
//   //                                   </div>
//   //                                 </div>
//   //                               </Col>
//   //                             </Row>
//   //                             <Row>
//   //                           <Col md={6}>
//   //                               <Label className="text-primary mx-4 ">Allow to Take Audit<span className="text-danger"> *</span></Label>
//   //                                 <div  className="mx-3">
//   //                                   <div style={{ marginBottom: 10 }}>  
//   //                                     {
//   //                                       this.state.allowed_audit_monthy.map((data, index) => {
//   //                                         return (
//   //                                           <div key={index} className="form-check mx-2 mt-2 ">
//   //                                             <input
//   //                                               type="radio"
//   //                                               className="form-check-input"
//   //                                               id={data.mode}
//   //                                               onChange={(e) => { this.allowedAuditForMonth(e, data, index) }}
//   //                                               checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === data.id ? true : false : false}
//   //                                             />
//   //                                             <label
//   //                                               className="form-check-label text-secondary"
//   //                                               htmlFor={data.mode}
//   //                                             >
//   //                                               {data.mode}

//   //                                             </label>
//   //                                             {this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined &&
//   //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null &&
//   //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === data.id && (
//   //                                                 data.id == "1" ?
//   //                                                 <>
//   //                                                 {
//   //                                                   this.state.total_weeks.map((data,idx)=>{
//   //                                                     return(
//   //                                                       <div key={idx} className="form-check mx-2 mt-2">
//   //                                                         <input type="radio" className="form-check-input" checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined ?this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week.mode_id === data.mode_id ? true : false : false : false} onChange={(e) => { this.appendDayofMonth(e, data) }} />
//   //                                                         <label className="form-check-label text-secondary">{data.name}</label>
//   //                                                         </div>
//   //                                                     )
//   //                                                   })
//   //                                                 }
//   //                                                 </>
//   //                                                 :
//   //                                                 data.id == "2"?
//   //                                                 <>
//   //                                                  <div className="form-check mx-1 mt-1">
//   //                                                     <label
//   //                                                       className="form-check-label text-primary mx-2"
//   //                                                     >
//   //                                                       Please Enter Specific Date of a month : <span className="text-danger">*</span>
//   //                                                     </label>
//   //                                                     <input type={"number"} max={30} min={1} name="specific_dates" value={Number(this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_dates)} onChange={(e) => { this.setState({ specific_dates: e.target.value }, () => { this.allowedAuditForMonth(e, undefined, "2") }) }} />
//   //                                                     </div>
//   //                                                   </>
//   //                                                   :
//   //                                                   data.id == "3"?
//   //                                                   <>
//   //                                                   <div className="form-check mx-1 mt-1">
//   //                                                       <label
//   //                                                         className="form-check-label text-primary mx-2"
//   //                                                       >
//   //                                                         Please check specific day of month : <span className="text-danger">*</span>
//   //                                                       </label>
//   //                                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                                           {
//   //                                                             this.state.daysOfWeek.map((item, idx) => {
//   //                                                               return (
//   //                                                                 <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                                                   <input
//   //                                                                     type="radio"
//   //                                                                     className="form-check-input"
//   //                                                                     id={item.day}
//   //                                                                     name="specific_day"
//   //                                                                     checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day.day_id === item.day_id ? true : false : false}
//   //                                                                     // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
//   //                                                                     onChange={e => { this.allowedAuditForMonth(e, undefined, "3", item) }}
//   //                                                                   />
//   //                                                                   <label
//   //                                                                     className="form-check-label"
//   //                                                                     htmlFor={item.day}
//   //                                                                   >
//   //                                                                     {item.day}
//   //                                                                   </label>
//   //                                                                 </div>
//   //                                                               )
//   //                                                             })
//   //                                                           }
//   //                                                         </div>
//   //                                                       </div>
//   //                                                       </div>
                                          
//   //                                         </>
//   //                                         :
//   //                                                   null
//   //                                               )}
//   //                                           </div>
//   //                                         )
//   //                                       })
//   //                                     }
//   //                                   </div>
//   //                                 </div>
//   //                             </Col>
//   //                             </Row>
//   //                             {/* {
//   //                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !==null && 
//   //                             <Row>
//   //                                     <Col md={6}>
//   //                                       {
//   //                                         this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === "2" ?
//   //                                        <>
//   //                                         <label
//   //                                         className="form-check-label text-primary mx-4"
//   //                                       >
//   //                                         Please Enter Specific Date of a month : <span className="text-danger">*</span>
//   //                                       </label>
//   //                                           <input type={"number"} max={30} min={1} name="specific_dates" value={Number(this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_dates)} onChange={(e)=>{this.setState({specific_dates : e.target.value},()=>{this.allowedAuditForMonth(e,undefined,"2")})}}/>
//   //                                       </>
//   //                                         :
//   //                                         this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === "3" ?
//   //                                         <>
//   //                                          <label
//   //                                         className="form-check-label text-primary mx-4"
//   //                                       >
//   //                                         Please check specific day of month : <span className="text-danger">*</span>
//   //                                       </label>
//   //                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                   {
//   //                                     this.state.daysOfWeek.map((item, idx) => {
//   //                                       return (
//   //                                         <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                           <input
//   //                                             type="radio"
//   //                                             className="form-check-input"
//   //                                             id={item.day}
//   //                                             name="specific_day"
//   //                                             checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day.day_id === item.day_id ? true : false : false}
//   //                                             onChange={e => { this.allowedAuditForMonth(e,undefined,"3",item) }}
//   //                                           />
//   //                                           <label
//   //                                             className="form-check-label"
//   //                                             htmlFor={item.day}
//   //                                           >
//   //                                             {item.day}
//   //                                           </label>
//   //                                         </div>
//   //                                       )
//   //                                     })
//   //                                   }
//   //                                 </div>
//   //                               </div>
                                          
//   //                                         </>
//   //                                         :
//   //                                         null
//   //                                       }
                                      
//   //                                     </Col>


//   //                             </Row>
//   //                             } */}
//   //                             <Row>
//   //                               <Col md={6}>
//   //                             {
//   //                                     this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !==undefined &&  this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null &&
//   //                                     <div>
//   //                                       <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
//   //                                       <FormGroup className="mb-3">
//   //                                         <input
//   //                                           className="form-control mx-4 "
//   //                                           type="date"
//   //                                           name="end_date"
//   //                                           // min={this.state.publishTemplate.repeat_mode_config.start_date !== null ? this.getMinDate() : this.state.fromate_date}
//   //                                           onChange={(e) => { this.scheduleStartDate(e, mode) }}
//   //                                           disabled={true}
//   //                                           value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
//   //                                         />
//   //                                       </FormGroup>
//   //                                     </div>
//   //                                   }
//   //                                   </Col>
//   //                                   </Row>
                              
//   //                                   <Row>
//   //                               <Col md={5}>
//   //                                 <label className="text-primary mx-4 my-1">
//   //                                   Show Time Frame
//   //                                 </label>
                                 
//   //                                   <input
//   //                                     className="form-check-input"
//   //                                     type="checkbox"
//   //                                     name="report"
//   //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
//   //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
//   //                                     style={{marginTop:"7px"}}
//   //                                   />
                                  
//   //                               </Col>
//   //                             </Row>

//   //                        {
//   //                         this.state.publishTemplate.repeat_mode_config.end_date !==null && this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true &&
//   //                        <Row>
//   //                        <Col md={12}>
//   //                          <div className="d-flex gap-5 ">
//   //                            <div>
//   //                              <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
//   //                              <FormGroup className="mb-3">
//   //                                <input
//   //                                  className="form-control mx-4  "
//   //                                  type="time"
//   //                                  onChange={(e) => { this.setTimeFrame(e) }}
//   //                                  name="start_time"
//   //                                  defaultValue="00:00:00"
//   //                                  id="example-time-input"
//   //                                />
//   //                              </FormGroup>
//   //                            </div>
//   //                            <div className="mx-2">
//   //                              <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
//   //                              <FormGroup className="mb-3">
//   //                                <input
//   //                                  className="form-control mx-4"
//   //                                  type="time"
//   //                                  name="end_time"
//   //                                  defaultValue="23:59:00"
//   //                                  onChange={(e) => { this.setTimeFrame(e) }}
//   //                                  id="example-time-input"
//   //                                />
//   //                              </FormGroup>
//   //                            </div>
//   //                          </div>
//   //                        </Col>
//   //                      </Row>
//   //                        }

//   //                      </>
//   //                      }
//   //                    </Col>
//   //                  </Row>
//   //                  <Row >
//   //                      <Col md={6}>
//   //                      </Col>
//   //                      <Col>
//   //                      </Col>

//   //                  </Row>
//   //                  <Row>
//   //                    <Col md={12}>
//   //                      <div className="form-check">
//   //                        <input
//   //                          className="form-check-input"
//   //                          type="radio"
//   //                          onChange={(e) => { this.endAfterMethod(e) }}
//   //                          name="enddate"
//   //                          id="enddate"
//   //                          checked={this.state.selectedRadioOption === "enddate"}
//   //                        />
//   //                        <label
//   //                          className="form-check-label"
//   //                          htmlFor="enddate"
//   //                        >
//   //                          Month Range
//   //                        </label>
//   //                      </div>
//   //                      {
//   //                        this.state.selectedRadioOption === "enddate" &&
//   //                        <>
//   //                          <Row>
//   //                            <Col md={12}>
//   //                              <div className="d-flex gap-3">
//   //                                <div>
//   //                                  <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
//   //                                  <FormGroup className="mb-3">
//   //                                    <input
//   //                                      className="form-control mx-4 "
//   //                                      type="date"
//   //                                      onChange={(e) => { this.scheduleStartDate(e,mode) }}
//   //                                      name="start_date"
//   //                                      min={this.state.fromate_date}
//   //                                      value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
//   //                                    />
//   //                                  </FormGroup>
//   //                                </div>
//   //                                <div>
//   //                                  <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
//   //                                  <FormGroup className="mb-3">
//   //                                    <input
//   //                                      className="form-control mx-4 "
//   //                                      type="date"
//   //                                      name="end_date"
//   //                                      min={this.state.publishTemplate.repeat_mode_config.start_date !== null ? this.getMinDate(mode) : this.state.fromate_date}
//   //                                      onChange={(e) => { this.scheduleStartDate(e,mode) }}
//   //                                      disabled={this.state.publishTemplate.repeat_mode_config.start_date === null}
//   //                                      value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
//   //                                    />
//   //                                  </FormGroup>
//   //                                </div>
//   //                              </div>
//   //                            </Col>
//   //                          </Row>
//   //                          {
//   //                            this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.selectedRadioOption === "enddate" &&
//   //                         <>
//   //                            <Row>
//   //                           <Col md={6}>
//   //                               <Label className="text-primary mx-4 ">Allow to Take Audit<span className="text-danger"> *</span></Label>
//   //                                 <div  className="mx-3">
//   //                                   <div style={{ marginBottom: 10 }}>  
//   //                                     {
//   //                                       this.state.allowed_audit_monthy.map((data, index) => {
//   //                                         return (
//   //                                           <div key={index} className="form-check mx-2 mt-2 ">
//   //                                             <input
//   //                                               type="radio"
//   //                                               className="form-check-input"
//   //                                               id={data.mode}
//   //                                               onChange={(e) => { this.allowedAuditForMonth(e, data, index) }}
//   //                                               checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === data.id ? true : false : false}
//   //                                             />
//   //                                             <label
//   //                                               className="form-check-label text-secondary"
//   //                                               htmlFor={data.mode}
//   //                                             >
//   //                                               {data.mode}

//   //                                             </label>
//   //                                             {this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined &&
//   //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== null &&
//   //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === data.id && (
//   //                                                 data.id == "1" ?
//   //                                                 <>
//   //                                                 {
//   //                                                   this.state.total_weeks.map((data,idx)=>{
//   //                                                     return(
//   //                                                       <div key={idx} className="form-check mx-2 mt-2">
//   //                                                         <input type="radio" className="form-check-input" checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined ?this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week.mode_id === data.mode_id ? true : false : false : false} onChange={(e) => { this.appendDayofMonth(e, data) }} />
//   //                                                         <label className="form-check-label text-secondary">{data.name}</label>
//   //                                                         </div>
//   //                                                     )
//   //                                                   })
//   //                                                 }
//   //                                                 </>
//   //                                                 :
//   //                                                 data.id == "2"?
//   //                                                 <>
//   //                                                  <div className="form-check mx-1 mt-1">
//   //                                                     <label
//   //                                                       className="form-check-label text-primary mx-2"
//   //                                                     >
//   //                                                       Please Enter Specific Date of a month : <span className="text-danger">*</span>
//   //                                                     </label>
//   //                                                     <input type={"number"} max={30} min={1} name="specific_dates" value={Number(this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_dates)} onChange={(e) => { this.setState({ specific_dates: e.target.value }, () => { this.allowedAuditForMonth(e, undefined, "2") }) }} />
//   //                                                     </div>
//   //                                                   </>
//   //                                                   :
//   //                                                   data.id == "3"?
//   //                                                   <>
//   //                                                   <div className="form-check mx-1 mt-1">
//   //                                                       <label
//   //                                                         className="form-check-label text-primary mx-2"
//   //                                                       >
//   //                                                         Please check specific day of month : <span className="text-danger">*</span>
//   //                                                       </label>
//   //                                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                                           {
//   //                                                             this.state.daysOfWeek.map((item, idx) => {
//   //                                                               return (
//   //                                                                 <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                                                   <input
//   //                                                                     type="radio"
//   //                                                                     className="form-check-input"
//   //                                                                     id={item.day}
//   //                                                                     name="specific_day"
//   //                                                                     checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day.day_id === item.day_id ? true : false : false}
//   //                                                                     // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
//   //                                                                     onChange={e => { this.allowedAuditForMonth(e, undefined, "3", item) }}
//   //                                                                   />
//   //                                                                   <label
//   //                                                                     className="form-check-label"
//   //                                                                     htmlFor={item.day}
//   //                                                                   >
//   //                                                                     {item.day}
//   //                                                                   </label>
//   //                                                                 </div>
//   //                                                               )
//   //                                                             })
//   //                                                           }
//   //                                                         </div>
//   //                                                       </div>
//   //                                                       </div>
                                          
//   //                                         </>
//   //                                         :
//   //                                                   null
//   //                                               )}
//   //                                           </div>
//   //                                         )
//   //                                       })
//   //                                     }
//   //                                   </div>
//   //                                 </div>
//   //                             </Col>
//   //                             </Row>
//   //                         {/* <Row>
//   //                          <Col md={6}>
//   //                              <Label className="text-primary mx-4 ">Allow to Take Audit</Label>
//   //                              <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                  {
//   //                                    this.state.publishTemplate.repeat_mode_config.days.map((item, idx) => {
//   //                                      return (
//   //                                        <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                          <input
//   //                                            type="checkbox"
//   //                                            className="form-check-input"
//   //                                            id={item.day}
//   //                                            // checked={item.checked}
//   //                                            checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
//   //                                            onChange={e => { this.excludeWeeks(e, item, idx, mode) }}
//   //                                          />
//   //                                          <label
//   //                                            className="form-check-label"
//   //                                            // htmlFor={item.day}
//   //                                          >
//   //                                            {item.day}
//   //                                          </label>
//   //                                        </div>
//   //                                      )
//   //                                    })
//   //                                  }
//   //                                </div>
//   //                              </div>
//   //                            </Col>
//   //                            </Row> */}
//   //                            <Row>
//   //                              <Col md={6}>
                              
//   //                                    <div className="text-danger p-1 px-0" style={{ marginLeft: "2%", fontSize: '0.8125rem' }}> Number of occurences between the Start Date({new Date(this.state.publishTemplate.repeat_mode_config.start_date).toLocaleDateString('en-GB')}) and End Date ({new Date(this.state.publishTemplate.repeat_mode_config.end_date).toLocaleDateString('en-GB')}) is <span className="text-danger">{this.state.no_of_occurance} </span> occurences</div>
                                  
//   //                              </Col>

//   //                            </Row>
                          
//   //                             {/* {
//   //                               this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy !==null && 
//   //                             <Row>
//   //                                     <Col md={6}>
//   //                                       {
//   //                                         this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === "2" ?
//   //                                        <>
//   //                                         <label
//   //                                         className="form-check-label text-primary mx-4"
//   //                                       >
//   //                                         Please Enter Specific Date of a month : <span className="text-danger">*</span>
//   //                                       </label>
//   //                                           <input type={"number"} max={30} min={1} name="specific_dates" value={Number(this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_dates)} onChange={(e)=>{this.setState({specific_dates : e.target.value},()=>{this.allowedAuditForMonth(e,undefined,"2")})}}/>
//   //                                       </>
//   //                                         :
//   //                                         this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.id === "3" ?
//   //                                         <>
//   //                                          <label
//   //                                         className="form-check-label text-primary mx-4"
//   //                                       >
//   //                                         Please check specific day of month : <span className="text-danger">*</span>
//   //                                       </label>
//   //                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                   {
//   //                                     this.state.daysOfWeek.map((item, idx) => {
//   //                                       return (
//   //                                         <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                           <input
//   //                                             type="radio"
//   //                                             className="form-check-input"
//   //                                             id={item.day}
//   //                                             name="specific_day"
//   //                                             checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.specific_day.day_id === item.day_id ? true : false : false}
//   //                                             // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_days.includes(Number(item.day_id)) ? true : false }
//   //                                             onChange={e => { this.allowedAuditForMonth(e,undefined,"3",item) }}
//   //                                           />
//   //                                           <label
//   //                                             className="form-check-label"
//   //                                             htmlFor={item.day}
//   //                                           >
//   //                                             {item.day}
//   //                                           </label>
//   //                                         </div>
//   //                                       )
//   //                                     })
//   //                                   }
//   //                                 </div>
//   //                               </div>
                                          
//   //                                         </>
//   //                                         :
//   //                                         null
//   //                                       }
                                      
//   //                                     </Col>


//   //                             </Row>
//   //                             } */}
//   //                              <Row>
//   //                               <Col md={5}>
//   //                                 <label className="text-primary mx-4 my-1">
//   //                                   Show Time Frame
//   //                                 </label>
                                 
//   //                                   <input
//   //                                     className="form-check-input"
//   //                                     type="checkbox"
//   //                                     name="report"
//   //                                     checked={this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true ? true : false }
//   //                                     onChange={(e)=>{this.handleShowTimeFrame(e)}}
//   //                                     style={{marginTop:"7px"}}
//   //                                   />
                                  
//   //                               </Col>
//   //                             </Row>
//   //                             {
//   //                               this.state.publishTemplate.repeat_mode_config.show_time_frame !== undefined &&this.state.publishTemplate.repeat_mode_config.show_time_frame === true &&
//   //                            <Row>
//   //                                  <Col md={12}>
//   //                                    <div className="d-flex gap-5 ">
//   //                                      <div>
//   //                                        <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
//   //                                        <FormGroup className="mb-3">
//   //                                          <input
//   //                                            className="form-control mx-4  "
//   //                                            type="time"
//   //                                            onChange={(e) => { this.setTimeFrame(e) }}
//   //                                            name="start_time"
//   //                                            defaultValue="00:00:00"
//   //                                            id="example-time-input"
//   //                                          />
//   //                                        </FormGroup>
//   //                                      </div>
//   //                                      <div className="mx-2">
//   //                                        <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
//   //                                        <FormGroup className="mb-3">
//   //                                          <input
//   //                                            className="form-control mx-4"
//   //                                            type="time"
//   //                                            name="end_time"
//   //                                            defaultValue="23:59:00"
//   //                                            onChange={(e) => { this.setTimeFrame(e) }}
//   //                                            id="example-time-input"
//   //                                          />
//   //                                        </FormGroup>
//   //                                      </div>
//   //                                    </div>
//   //                                  </Col>
//   //                                </Row>
//   //                             }

//   //                            </>
                             
//   //                          }

//   //                        </>
//   //                      }
//   //                    </Col>
//   //                  </Row>
//   //                </React.Fragment>
//   //              </div>           
//   //                 : mode == "4" ?
//   //                   // <div></div>
//   //                   <>
//   //                   <Row>
//   //                     <Col md={6}>
//   //                     <Label className="text-primary mx-3 ">Select calander type: <span className="text-danger">*</span></Label>

//   //                         <select
//   //                           defaultValue={this.state.publishTemplate.repeat_mode_config.calendar_type !== null && this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined ? this.state.publishTemplate.repeat_mode_config.calendar_type : "please select"}
//   //                           onChange={(e) => { this.calendarType(e) }}
//   //                         >
//   //                           <option
//   //                             disabled
//   //                             value={"please select"}
//   //                           >
//   //                          please select
//   //                             {/* {this.state.publishTemplate.repeat_mode_config.calendar_type !== null && this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined ? this.state.publishTemplate.repeat_mode_config.calendar_type : "please select"} */}
//   //                           </option>
//   //                           {this.state.calendar_type.map((data, idx) => {
//   //                             return (
//   //                               <option key={idx} value={data.mode_type}>{data.mode_type}</option>
//   //                             );
//   //                           })}
//   //                         </select>
//   //                     </Col>
//   //                     </Row>                    
//   //                   <Row>
//   //                     <Col md={6}>
//   //                     {
//   //                       this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined &&this.state.publishTemplate.repeat_mode_config.calendar_type !== null &&
//   //                       <>
//   //                     <Label className="text-primary mx-3 ">Start Quarter</Label>
//   //                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                           {
//   //                             this.state.quater_periodicity.map((item, idx) => {
//   //                               return (
//   //                                 <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                   <input
//   //                                     type="radio"
//   //                                     className="form-check-input"
//   //                                     id={item.name}
//   //                                     // checked={item.checked}
//   //                                     checked={this.state.publishTemplate.repeat_mode_config.selected_quater !== undefined && this.state.publishTemplate.repeat_mode_config.selected_quater !==null ?this.state.publishTemplate.repeat_mode_config.selected_quater.id === item.id ? true : false:false}
//   //                                     onChange={e => { this.yearPeriodicity(e, item, idx, mode) }}
//   //                                   />
//   //                                   <label
//   //                                     className="form-check-label"
//   //                                     htmlFor={item.name}
//   //                                   >
//   //                                     {item.name}
//   //                                   </label>
//   //                                 </div>
//   //                               )
//   //                             })
//   //                           }
//   //                         </div>
//   //                       </div>
//   //                       </>
//   //                     }

//   //                     </Col>
//   //                   </Row>
//   //                   {
//   //                     this.state.publishTemplate.repeat_mode_config.selected_quater !== undefined && this.state.publishTemplate.repeat_mode_config.selected_quater !== null &&this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined &&
//   //                  <>
//   //                  <Row>
//   //                   <Col md={6}>
//   //                     <Label className="text-primary mx-3 ">Quarter Start Months</Label>
//   //                     <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                       <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                         {
//   //                           this.state.selected_quater_months?.map((item, idx) => {
//   //                             return (
//   //                               <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                 <input
//   //                                   type="radio"
//   //                                   className="form-check-input"
//   //                                   id={item.month_name}
//   //                                   checked={this.state.publishTemplate.repeat_mode_config.selected_months !== undefined && this.state.publishTemplate.repeat_mode_config.selected_months !== null ? this.state.publishTemplate.repeat_mode_config.selected_months.month_id === item.month_id ? true : false : false  }
//   //                                   onChange={(e)=>{this.periodicityMonths(e,item,idx,mode)}}
//   //                                 />
//   //                                 <label
//   //                                   className="form-check-label"
//   //                                   htmlFor={item.month_name}
//   //                                 >
//   //                                   {item.month_name}
//   //                                 </label>
//   //                               </div>
//   //                             )
//   //                           })
//   //                         }
//   //                       </div>
//   //                     </div>
//   //                   </Col>
//   //                 </Row>
//   //                 {/* <Row>
//   //                   <Col md={3}>
//   //                     <Label className="text-primary mx-3 ">Select Quarter</Label>
//   //                 <select className="form-select">
//   //                   <option>2020</option>
//   //                   <option>2021</option>
//   //                   <option>2023</option>
//   //                   <option>2024</option>
//   //                 </select>
//   //                 </Col>
//   //                 </Row> */}
//   //                 </>
//   //                   }
//   //                 </>
//   //                    : 
//   //                    mode == "5" ?
//   //                     // <div></div> 
//   //                     <>
//   //                      <Row>
//   //                     <Col md={6}>
//   //                     <Label className="text-primary mx-3 ">Select calander type: <span className="text-danger">*</span></Label>

//   //                         <select
//   //                           defaultValue={this.state.publishTemplate.repeat_mode_config.calendar_type !== null && this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined ? this.state.publishTemplate.repeat_mode_config.calendar_type : "please select"}
//   //                           onChange={(e) => { this.calendarType(e) }}
//   //                         >
//   //                           <option
//   //                             disabled
//   //                             value={"please select"}
//   //                           >
//   //                             please select
//   //                             {/* {this.state.publishTemplate.repeat_mode_config.calendar_type !== null && this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined ? this.state.publishTemplate.repeat_mode_config.calendar_type : "please select"} */}
//   //                           </option>
//   //                           {this.state.calendar_type.map((data, idx) => {
//   //                             return (
//   //                               <option key={idx} value={data.mode_type}>{data.mode_type}</option>
//   //                             );
//   //                           })}
//   //                         </select>
//   //                     </Col>
//   //                     </Row> 
//   //                     <Row>
//   //                       <Col md={6}>
//   //                         {
//   //                            this.state.publishTemplate.repeat_mode_config.calendar_type !== undefined &&this.state.publishTemplate.repeat_mode_config.calendar_type !== null &&
//   //                        <>
//   //                               <Label className="text-primary mx-3 ">Start Half-yearly</Label>
//   //                               <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                   {
//   //                                     this.state.periodicity_half_yearly.map((item, idx) => {
//   //                                       return (
//   //                                         <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                           <input
//   //                                             type="radio"
//   //                                             className="form-check-input"
//   //                                             id={item.name}
//   //                                             // checked={item.checked}
//   //                                             checked={this.state.publishTemplate.repeat_mode_config.selected_half_yearly !== undefined && this.state.publishTemplate.repeat_mode_config.selected_half_yearly !== null ? this.state.publishTemplate.repeat_mode_config.selected_half_yearly.id === item.id ? true : false : false}
//   //                                             onChange={e => { this.yearPeriodicity(e, item, idx, mode) }}
//   //                                           />
//   //                                           <label
//   //                                             className="form-check-label"
//   //                                             htmlFor={item.name}
//   //                                           >
//   //                                             {item.name}
//   //                                           </label>
//   //                                         </div>
//   //                                       )
//   //                                     })
//   //                                   }
//   //                                 </div>
//   //                               </div>
//   //                         </>
//   //                         }

//   //                       </Col>
//   //                     </Row>
//   //                     {
//   //                       this.state.publishTemplate.repeat_mode_config.selected_half_yearly !== undefined && this.state.publishTemplate.repeat_mode_config.selected_half_yearly !== null &&
//   //                    <>
//   //                    <Row>
//   //                     <Col md={6}>
//   //                       <Label className="text-primary mx-3 ">Half-Yearly Start Months</Label>
//   //                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                           {
//   //                             this.state.selected_half_months.map((item, idx) => {
//   //                               return (
//   //                                 <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                   <input
//   //                                     type="radio"
//   //                                     className="form-check-input"
//   //                                     id={item.month_name}
//   //                                      checked={this.state.publishTemplate.repeat_mode_config.selected_months !== undefined && this.state.publishTemplate.repeat_mode_config.selected_months !== null ? this.state.publishTemplate.repeat_mode_config.selected_months.month_id === item.month_id ? true : false : false  }
//   //                                     onChange={(e)=>{this.periodicityMonths(e,item,idx,mode)}}
//   //                                     // checked={item.checked}
//   //                                     // checked={this.state.publishTemplate.repeat_mode_config.selected_quater.id === item.id ? true : false}
//   //                                     // onChange={e => { this.yearPeriodicity(e, item, idx, mode) }}
//   //                                   />
//   //                                   <label
//   //                                     className="form-check-label"
//   //                                     htmlFor={item.month_name}
//   //                                   >
//   //                                     {item.month_name}
//   //                                   </label>
//   //                                 </div>
//   //                               )
//   //                             })
//   //                           }
//   //                         </div>
//   //                       </div>
//   //                     </Col>
//   //                   </Row>
//   //                   {/* <Row>
//   //                     <Col md={3}>
//   //                       <Label className="text-primary mx-3 ">Select Half-Yearly</Label>
//   //                   <select className="form-select">
//   //                     <option>2020</option>
//   //                     <option>2021</option>
//   //                     <option>2023</option>
//   //                     <option>2024</option>
//   //                   </select>
//   //                   </Col>
//   //                   </Row> */}
//   //                   </>
//   //                     }
//   //                   </>
//   //                     :
//   //                     mode =="6"?
//   //                     <>  
//   //                         <div>
//   //                           <React.Fragment>
//   //                             <Row>
//   //                               <Col md={12}>
//   //                                 <div className="form-check mb-1">
//   //                                   {/* <input
//   //                                     className="form-check-input mt-2 "
//   //                                     type="radio"
//   //                                     onChange={(e) => { this.endAfterMethod(e) }}
//   //                                     name="occurence"
//   //                                     id="occurence"
//   //                                     checked={this.state.selectedRadioOption === "occurence"}
//   //                                     defaultChecked
//   //                                   /> */}
//   //                                   <label
//   //                                     className="form-check-label"
//   //                                     htmlFor="occurence"
//   //                                   >
//   //                                     No. of Occurences
//   //                                   </label>
//   //                                   <input type={"number"} max={3} min={0} onChange={(e) => { this.handleOccurance(e, mode) }} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>
//   //                                   {(this.state.occurence === undefined || this.state.occurence == "") && this.state.selectedRadioOption === "occurence" ?
//   //                                     <span className="text-danger" style={{ fontSize: "smaller" }}> Please Enter the No.of.days</span>
//   //                                     :
//   //                                     null}
//   //                                 </div>
//   //                                 {/* <Row>
//   //                                   <Col md={6}>
//   //                                       <Label>Select Year </Label>
//   //                                       <select >
//   //                                         <option>2023</option>
//   //                                         <option>2024</option>
//   //                                       </select>
//   //                                   </Col>
//   //                                 </Row> */}
//   //                                    {
//   //                                   this.state.occurence !== null && this.state.occurence !== undefined && this.state.occurence !== "" &&

//   //                                   <Row>
//   //                                     <Col md={12}>
//   //                                       <div className="d-flex gap-3">
//   //                                         <div>
//   //                                           <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
//   //                                           <FormGroup className="mb-3">
//   //                                             <input
//   //                                               className="form-control mx-4 "
//   //                                               type="date"
//   //                                               onChange={(e) => { this.scheduleStartDate(e, mode) }}
//   //                                               min={this.state.fromate_date}
//   //                                               value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
//   //                                             />
//   //                                           </FormGroup>
//   //                                         </div>
//   //                                         <div>
//   //                                           {
//   //                                             this.state.publishTemplate.repeat_mode_config.end_date !== null &&
//   //                                             <>
//   //                                               <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date</Label>
//   //                                               <FormGroup className="mb-3">
//   //                                                 <input
//   //                                                   className="form-control mx-4 "
//   //                                                   type="date"
//   //                                                   value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
//   //                                                   disabled={true}
//   //                                                 />
//   //                                               </FormGroup>
//   //                                             </>
//   //                                           }

//   //                                 </div>
//   //                               </div>
//   //                             </Col>

//   //                           </Row>
//   //                         }
//   //                         {
//   //                           this.state.publishTemplate.repeat_mode_config.start_date !== null &&
//   //                                 <Row>
//   //                           <Col md={6}>
//   //                               <Label className="text-primary mx-4 ">Allow to Take Audit<span className="text-danger"> *</span></Label>
//   //                                 <div  className="mx-3">
//   //                                   <div style={{ marginBottom: 10 }}>  
//   //                                     {
//   //                                       this.state.allowed_audit_yearly.map((data, index) => {
//   //                                         return (
//   //                                           <>
//   //                                           <div key={index} className="form-check mx-2 mt-2 ">
//   //                                             <input
//   //                                               type="radio"
//   //                                               className="form-check-input"
//   //                                               id={data.mode}
//   //                                               onChange={(e) => { this.allowedAuditForYearly(e, data, index) }}
//   //                                                checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== undefined && this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== null ? this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly.id === data.id ? true : false : false}
//   //                                             />
//   //                                             <label
//   //                                               className="form-check-label text-secondary"
//   //                                               htmlFor={data.mode}
//   //                                             >
//   //                                               {data.mode}

//   //                                             </label>
//   //                                             </div>
//   //                                             {this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== undefined &&
//   //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== null &&
//   //                                               this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly.id === data.id && (
//   //                                                 <>
//   //                                                 {
//   //                                                   data.id == "1"?
//   //                                                   <>
//   //                                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                                           {
//   //                                                             this.state.total_months.map((ele, idx) => {
//   //                                                               return (
//   //                                                                 <div key={idx} className="form-check mx-2 mt-2">
//   //                                                                   <input type="checkbox" className="form-check-input"
//   //                                                                   // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== undefined ?this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly.position_of_the_week !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week.mode_id === data.mode_id ? true : false : false : false}
//   //                                                                   //  onChange={(e) => { this.appendDayofMonth(e, data) }}
//   //                                                                   />
//   //                                                                   <label className="form-check-label text-secondary">{ele.name}</label>
//   //                                                                 </div>
//   //                                                               )
//   //                                                             })
//   //                                                           }
//   //                                                         </div>
//   //                                                       </div>
//   //                                                     </>

//   //                                                   :
//   //                                                     data.id == "2" ?
//   //                                                       <>
//   //                                                       <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">
//   //                                                         <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                                                       {
//   //                                                         this.state.daysOfWeek.map((ele,idx)=>{
//   //                                                           return(
//   //                                                             <div key={idx} className="form-check mx-2 mt-2">
//   //                                                             <input type="radio" className="form-check-input"
//   //                                                             // checked={this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly !== undefined ?this.state.publishTemplate.repeat_mode_config.allowed_audit_yearly.position_of_the_week !== undefined ? this.state.publishTemplate.repeat_mode_config.allowed_audit_monthy.position_of_the_week.mode_id === data.mode_id ? true : false : false : false}
//   //                                                             //  onChange={(e) => { this.appendDayofMonth(e, data) }}
//   //                                                             />
//   //                                                             <label className="form-check-label text-secondary">{ele.day}</label>
//   //                                                           </div>


//   //                                                           )
//   //                                                         })
//   //                                                       }
//   //                                                     </div>
//   //                                                         </div>

//   //                                                       </>

//   //                                                   :
//   //                                                   null
//   //                                                 }

//   //                                                 </>
//   //                                               )}
//   //                                             </>
//   //                                         )
//   //                                               })
//   //                                       // })
//   //                                     }
//   //                                     </div>
//   //                                     </div>
//   //                                     </Col>
//   //                                     </Row>
//   //                         }

//   //                                 {/* {
//   //                                   this.state.occurence !== null && this.state.occurence !== undefined && this.state.occurence !== "" &&

//   //                                   <Row>
//   //                                     <Col md={12}>
//   //                                       <div className="d-flex gap-3">
//   //                                         <div>
//   //                                           <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
//   //                                           <FormGroup className="mb-3">
//   //                                             <input
//   //                                               className="form-control mx-4 "
//   //                                               type="date"
//   //                                               onChange={(e) => { this.scheduleStartDate(e, mode) }}
//   //                                               min={this.state.fromate_date}
//   //                                               value={this.state.publishTemplate.repeat_mode_config.start_date == null ? "" : this.state.publishTemplate.repeat_mode_config.start_date}
//   //                                             />
//   //                                           </FormGroup>
//   //                                         </div>
//   //                                         <div>
//   //                                           {
//   //                                             this.state.publishTemplate.repeat_mode_config.end_date !== null &&
//   //                                             <>
//   //                                               <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date</Label>
//   //                                               <FormGroup className="mb-3">
//   //                                                 <input
//   //                                                   className="form-control mx-4 "
//   //                                                   type="date"
//   //                                                   value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" : this.state.publishTemplate.repeat_mode_config.end_date}
//   //                                                   disabled={true}
//   //                                                 />
//   //                                               </FormGroup>
//   //                                             </>
//   //                                           }

//   //                                 </div>
//   //                               </div>
//   //                             </Col>

//   //                           </Row>
//   //                         } */}
//   //                                 {/* {
//   //                                   this.state.occurence !==undefined && this.state.occurence !=="" &&
//   //                                   <>
//   //                                   <Label className="text-primary mx-3 ">Select Year</Label>
//   //                                   <select>
//   //                                     <option>
//   //                                       2023
//   //                                     </option>
//   //                                     <option>
//   //                                       2024
//   //                                     </option>
//   //                                     <option>
//   //                                       2025
//   //                                     </option>
//   //                                   </select>
                                    
//   //                                   </>
//   //                                 } */}
//   //                               </Col>
//   //                             </Row>
//   //                           </React.Fragment>
//   //                         </div>

//   //                     </>

//   //                     :
//   //                     null

//   //       }

//   //     </Form>
//   //   )
//   // }



//   // repeatModeComponent(mode) {
//   //   // console.log(mode,'mode')

//   //   // var endDate = this.calculateEndDate()
//   //   // console.log(endDate,'endDate')

//   //   return (
//   //     <Form action="#">

//   //       {
//   //         mode == "0" ?

//   //           <div>
//   //             {/* <div className="mb-3 d-flex flex-column  ">
//   //               <div className="d-flex flex-row align-items-center">
//   //                 <div className="col-3">
//   //                   <Label className="text-primary" htmlFor="autoSizingSelect">Start date</Label>
//   //                   <div className="col-md-10">
//   //                     <input
//   //                       className="form-control"
//   //                       type="date"
//   //                       min={this.state.fromate_date}
//   //                       selected={this.state.ptl_startdate}
//   //                       defaultValue={this.state.ptl_startdate}
//   //                       id="start_date"
//   //                       onChange={event => this.onChangeStartDate(event)}
//   //                     />
//   //                   </div>
//   //                 </div>
//   //                 <div className="col-3">
//   //                   <Label className="text-primary" htmlFor="autoSizingSelect">End date</Label>
//   //                   <div className="col-md-10">
//   //                     <input
//   //                       className="form-control"
//   //                       type="date"
//   //                       min={this.state.fromate_date}
//   //                       selected={this.state.ptl_enddate}
//   //                       defaultValue={this.state.ptl_enddate}
//   //                       id="end_date"
//   //                       onChange={event => this.onChangeEndDate(event)}
//   //                     />
//   //                   </div>
//   //                 </div>

//   //               </div>
//   //             </div> */}
//   //           </div> : mode == "1" ?

//   //             <div>
//   //               <Row >
//   //                   {
//   //                     // this.state.publishTemplate.repeat_mode_config.start_date !== null &&
//   //                     // console.log("shall go nxt step") 
//   //                     <React.Fragment>
//   //                       <Row>
//   //                         <Col md={12}>
//   //                           {/* <h5 className="font-size-14 mb-4 text-primary">End After</h5> */}
//   //                           <div className="form-check mb-1">
//   //                             <input
//   //                               className="form-check-input mt-2 "
//   //                               type="radio"
//   //                               onChange={(e) => { this.endAfterMethod(e) }}
//   //                               name="occurence"
//   //                               id="occurence"
//   //                               checked={this.state.selectedRadioOption === "occurence"}
//   //                               defaultChecked
//   //                             />
//   //                             <label
//   //                               className="form-check-label"
//   //                               htmlFor="occurence"
//   //                             >
//   //                               No. of Occurences
//   //                             </label>
//   //                             <input type={"number"} max={30} min={0} onChange={(e)=>{this.handleOccurance(e)}} value={this.state.occurence} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> <span className="text-danger">*</span>

//   //                             {/* <input type={"number"} max={30} min={0} onChange={(e)=>{this.setState({occurence : e.target.value ,show_occurence_valid : false},()=>{this.calculateEndDate();}) }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} />  */}
//   //                             {(this.state.occurence === undefined || this.state.occurence =="") && this.state.selectedRadioOption === "occurence"?
//   //                             <span className="text-danger" style={{fontSize:"smaller"}}> Please Enter the No.of.days</span>
//   //                             :
//   //                             null}
//   //                           </div>
//   //                           {
//   //                             this.state.selectedRadioOption === "occurence" &&

//   //                           <Row>
//   //                               <Col md={12}>
//   //                                 <div className="d-flex gap-3">
//   //                                   <div>
//   //                                     <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date <span className="text-danger">*</span></Label>
//   //                                     <FormGroup className="mb-3">
//   //                                       <input
//   //                                         className="form-control mx-4 "
//   //                                         type="date"
//   //                                         onChange={(e) => { this.scheduleStartDate(e) }}
//   //                                         min={this.state.fromate_date}
//   //                                       />
//   //                                     </FormGroup>
//   //                                   </div>
//   //                                   <div>
//   //                                     {
//   //                                       this.state.publishTemplate.repeat_mode_config.start_date !== null &&
//   //                                       <>
//   //                                         <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date</Label>
//   //                                         <FormGroup className="mb-3">
//   //                                           <input
//   //                                             className="form-control mx-4 "
//   //                                             type="date"
//   //                                             // value={endDate == null ? "" :endDate}
//   //                                             value={this.state.publishTemplate.repeat_mode_config.end_date == null ? "" :this.state.publishTemplate.repeat_mode_config.end_date}

//   //                                             disabled={true}

//   //                                           />
//   //                                         </FormGroup>
//   //                                       </>
//   //                                     }

//   //                                   </div>
//   //                                 </div>
//   //                               </Col>
                            
//   //                             </Row>
//   //                           }
                         

//   //                         </Col>
//   //                       </Row>
//   //                     <Row>
//   //                         {
//   //                           this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.selectedRadioOption === "occurence" &&
//   //                         <Col md={12}>

//   //                           <Label className="text-primary mx-4 " htmlFor="autoSizingSelect">Exclude Days</Label>

//   //                           <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

//   //                             <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                               {
//   //                                 this.state.weekDays.map((item, idx) => {
//   //                                   return (
//   //                                     <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                       <input
//   //                                         type="checkbox"
//   //                                         className="form-check-input"
//   //                                         id={item.day}
//   //                                         checked={item.checked}
//   //                                         // onChange={e => { this.handleWeekDays(e) }}
//   //                                         onChange={e => { this.excludeWeeks(e,item,idx) }}

//   //                                       />
//   //                                       <label
//   //                                         className="form-check-label"
//   //                                         htmlFor={item.day}
//   //                                       >
//   //                                         {item.day}
//   //                                       </label>
//   //                                     </div>
//   //                                   )
//   //                                 })
//   //                               }
//   //                             </div>
//   //                           </div>
//   //                         </Col>
//   //                         }                     
//   //                     </Row>
//   //                     <Row>
//   //                      <Col md={6}>

//   //                     {
//   //                         this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.selectedRadioOption === "occurence" &&
//   //                       <>
//   //                            <Row>
//   //                               <Col md={12}>
//   //                                 <div className="d-flex gap-5 ">
//   //                                   <div>
//   //                                     <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
//   //                                     <FormGroup className="mb-3">
//   //                                       <input
//   //                                         className="form-control mx-4  "
//   //                                         type="time"
//   //                                         onChange={(e)=>{this.setTimeFrame(e)}}
//   //                                         name="start_time"
//   //                                         defaultValue="00:00:00"
//   //                                         id="example-time-input"
//   //                                       />
//   //                                     </FormGroup>
//   //                                   </div>
//   //                                   <div className="mx-2">
//   //                                     {
//   //                                       this.state.publishTemplate.repeat_mode_config.start_date !== null &&
//   //                                       <>
//   //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
//   //                                         <FormGroup className="mb-3">
//   //                                           <input
//   //                                             className="form-control mx-4"
//   //                                             type="time"
//   //                                             name="end_time"
//   //                                             defaultValue="23:59:00"
//   //                                             onChange={(e)=>{this.setTimeFrame(e)}}
//   //                                             id="example-time-input"
//   //                                           />
//   //                                         </FormGroup>
//   //                                       </>
//   //                                     }
//   //                                   </div>
//   //                                 </div>
//   //                               </Col>
//   //                             </Row>
//   //                        </>
//   //                       }
//   //                       </Col>

//   //                     </Row>
//   //                     <Row>
//   //                       <Col md={12}>
//   //                         <div className="form-check">
//   //                           <input
//   //                             className="form-check-input"
//   //                             type="radio"
//   //                             onChange={(e) => { this.endAfterMethod(e) }}
//   //                             name="enddate"
//   //                             id="enddate"
//   //                             checked={this.state.selectedRadioOption === "enddate"}
//   //                           />
//   //                           <label
//   //                             className="form-check-label"
//   //                             htmlFor="enddate"
//   //                           >
//   //                             Date Range
//   //                           </label>
//   //                         </div>
//   //                         {
//   //                             this.state.selectedRadioOption === "enddate" &&
//   //                             <>
//   //                             <Row>
//   //                             <Col md={12}>
//   //                               <div className="d-flex gap-3">
//   //                                 <div>
//   //                                   <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">Start Date<span className="text-danger">*</span></Label>
//   //                                   <FormGroup className="mb-3">
//   //                                     <input
//   //                                       className="form-control mx-4 "
//   //                                       type="date"
//   //                                       onChange={(e) => { this.scheduleStartDate(e) }}
//   //                                       name="start_date"
//   //                                       min={this.state.fromate_date}
//   //                                     />
//   //                                   </FormGroup>
//   //                                 </div>
//   //                                 <div>
//   //                                       <Label className="text-primary mx-4 my-1" htmlFor="autoSizingSelect">End Date<span className="text-danger">*</span></Label>
//   //                                       <FormGroup className="mb-3">
//   //                                         <input
//   //                                           className="form-control mx-4 "
//   //                                           type="date"
//   //                                           name ="end_date"
//   //                                           min={this.state.publishTemplate.repeat_mode_config.start_date !== null ?this.state.publishTemplate.repeat_mode_config.start_date : this.state.fromate_date}
//   //                                           onChange={(e) => { this.scheduleStartDate(e) }}
//   //                                           disabled={this.state.publishTemplate.repeat_mode_config.start_date === null}
//   //                                         />
//   //                                       </FormGroup>
//   //                                 </div>
//   //                               </div>
//   //                             </Col>
//   //                           </Row>

//   //                           <Row>
//   //                             <Col md={12}>
//   //                               {
//   //                                 this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null ?
//   //                                <div className="text-danger" style={{marginLeft :"2%" , fontSize :'smaller'}}> Number of occurences between the Start Date({this.state.publishTemplate.repeat_mode_config.start_date}) and End Date ({this.state.publishTemplate.repeat_mode_config.end_date}) is <span className="text-danger">{this.state.no_of_occurance} </span> occurences</div>
//   //                                 :
//   //                                 null
//   //                               }
                              
//   //                             </Col>                            
//   //                           </Row>
//   //                           <Row>
//   //                         {
//   //                           this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null &&
//   //                         <Col md={12}>

//   //                           <Label className="text-primary mx-4 " htmlFor="autoSizingSelect">Exclude Days</Label>

//   //                           <div style={{ display: 'flex', flexDirection: 'Column' }} className="mx-3">

//   //                             <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
//   //                               {
//   //                                 this.state.weekDays.map((item, idx) => {
//   //                                   return (
//   //                                     <div key={idx} className="form-check mx-2 mt-2 ">
//   //                                       <input
//   //                                         type="checkbox"
//   //                                         className="form-check-input"
//   //                                         id={item.day}
//   //                                         checked={item.checked}
//   //                                         // onChange={e => { this.handleWeekDays(e) }}
//   //                                         onChange={e => { this.excludeWeeks(e,item,idx) }}

//   //                                       />
//   //                                       <label
//   //                                         className="form-check-label"
//   //                                         htmlFor={item.day}
//   //                                       >
//   //                                         {item.day}
//   //                                       </label>
//   //                                     </div>
//   //                                   )
//   //                                 })
//   //                               }
//   //                             </div>
//   //                           </div>
//   //                         </Col>
//   //                         }                     
//   //                     </Row>
//   // </>

//   //                           }
//   //                    <Row>
//   //                      <Col md={6}>

//   //                     {
//   //                         this.state.publishTemplate.repeat_mode_config.start_date !== null && this.state.publishTemplate.repeat_mode_config.end_date !== null && this.state.selectedRadioOption === "enddate" &&
//   //                       <>
//   //                            <Row>
//   //                               <Col md={12}>
//   //                                 <div className="d-flex gap-5 ">
//   //                                   <div>
//   //                                     <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">Start Time Frame</Label>
//   //                                     <FormGroup className="mb-3">
//   //                                       <input
//   //                                         className="form-control mx-4  "
//   //                                         type="time"
//   //                                         onChange={(e)=>{this.setTimeFrame(e)}}
//   //                                         name="start_time"
//   //                                         defaultValue="00:00:00"
//   //                                         id="example-time-input"
//   //                                       />
//   //                                     </FormGroup>
//   //                                   </div>
//   //                                   <div className="mx-2">
//   //                                     {/* {
//   //                                       this.state.publishTemplate.repeat_mode_config.start_date !== null && */}
//   //                                       {/* <> */}
//   //                                         <Label className="text-primary mx-4 my-1" htmlFor="example-time-input">End Time Frame</Label>
//   //                                         <FormGroup className="mb-3">
//   //                                           <input
//   //                                             className="form-control mx-4"
//   //                                             type="time"
//   //                                             name="end_time"
//   //                                             defaultValue="23:59:00"
//   //                                             onChange={(e)=>{this.setTimeFrame(e)}}
//   //                                             id="example-time-input"
//   //                                           />
//   //                                         </FormGroup>
//   //                                       {/* </> */}
//   //                                     {/* } */}
//   //                                   </div>
//   //                                 </div>
//   //                               </Col>
//   //                             </Row>
//   //                        </>
//   //                       }
//   //                       </Col>

//   //                     </Row>






//   //                       </Col>

//   //                     </Row>
//   //                     </React.Fragment>

//   //                     // <div className="mt-4">
//   //                     //   <h5 className="font-size-14 mb-4 text-primary">End After</h5>
//   //                     //   <div className="form-check mb-3">
//   //                     //     <input
//   //                     //       className="form-check-input"
//   //                     //       type="radio"
//   //                     //       onChange={(e)=>{this.endAfterMethod(e)}}
//   //                     //       name="occurence"
//   //                     //       id="occurence"
//   //                     //       checked={this.state.selectedRadioOption === "occurence"}
//   //                     //       defaultChecked
//   //                     //     />
//   //                     //     <label
//   //                     //       className="form-check-label"
//   //                     //       htmlFor="occurence"
//   //                     //     >
//   //                     //      No. of Occurences
//   //                     //     </label>
//   //                     //     <input  type={"number"} style={{border:'1px solid #d3cfcf' ,padding :"4px", margin:"0 5px", borderRadius : "6px" , width : '38px'}}/>
//   //                     //   </div>
//   //                     //   <div className="form-check">
//   //                     //     <input
//   //                     //       className="form-check-input"
//   //                     //       type="radio"
//   //                     //       onChange={(e)=>{this.endAfterMethod(e)}}
//   //                     //       name="enddate"
//   //                     //       id="enddate"
//   //                     //       checked={this.state.selectedRadioOption === "enddate"}
//   //                     //     />
//   //                     //     <label
//   //                     //       className="form-check-label"
//   //                     //       htmlFor="enddate"
//   //                     //     >
//   //                     //      Date Range
//   //                     //     </label>
//   //                     //   </div>
//   //                     // </div>


//   //                   }
//   //                 {/* <FormGroup className="mb-3 col-3">
//   //                   <Label className="text-primary" htmlFor="autoSizingSelect">End Time</Label>
//   //                   <InputGroup>
//   //                     <Flatpickr
//   //                       className="form-control d-block"
//   //                       placeholder="Select time"
//   //                       options={{
//   //                         minTime: this.state.getMinTime,
//   //                         enableTime: true,
//   //                         noCalendar: true,
//   //                         dateFormat: "H:i K"
//   //                       }}
//   //                       value={this.state.daily_endtime}
//   //                       onChange={e => this.onChangeEndTime(e)}
//   //                     />
//   //                     <div className="input-group-append">
//   //                       <span className="input-group-text">
//   //                         <i className="mdi mdi-clock-outline" />
//   //                       </span>
//   //                     </div>
//   //                   </InputGroup>
//   //                 </FormGroup> */}
//   //               </Row>


//   //             </div> : mode == "2" ?
//   //               <div style={{ display: 'flex', flexDirection: 'Column' }}>

//   //                 <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
//   //                   {
//   //                     this.state.weekDays.map((item, idx) => {
//   //                       return (
//   //                         <div key={idx} className="form-check mx-2 ">
//   //                           <input
//   //                             type="checkbox"
//   //                             className="form-check-input"
//   //                             id={item.day}
//   //                             checked={item.checked}
//   //                             onChange={e => { this.handleWeekDays(e) }}
//   //                           />
//   //                           <label
//   //                             className="form-check-label"
//   //                             htmlFor={item.day}
//   //                           >
//   //                             {item.day}
//   //                           </label>
//   //                         </div>
//   //                       )
//   //                     })
//   //                   }
//   //                 </div>



//   //               </div> : mode == "3" ?
//   //                 <div>
//   //                   <Row>

//   //                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 30 }}>
//   //                       <span style={{ fontSize: 13, color: '#74788d' }}>Every month started on &nbsp;&nbsp;</span>
//   //                       <div className="me-2">
//   //                         <Label className="card-radio-label">
//   //                           <Input
//   //                             type="radio"
//   //                             name="month_start"
//   //                             id="ms-1"
//   //                             className="card-radio-input"
//   //                             value={this.state.mstartoption}
//   //                             checked={this.state.mstartoption === "month_start"}
//   //                             onChange={this.handleMStartGroupChange}
//   //                           />

//   //                           <div className="card-radio py-4">
//   //                             <span style={{
//   //                               color: this.state.mstartoption !== "month_start" || this.state.mstartoption === undefined ? "#c0c1c5" : "#74788d"
//   //                             }}>Month 1st</span>
//   //                           </div>
//   //                         </Label>
//   //                       </div>
//   //                       <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>
//   //                       <div className="me-2">
//   //                         <Label className="card-radio-label">
//   //                           <Input
//   //                             type="radio"
//   //                             name="custom_start"
//   //                             id="ms-2"
//   //                             className="card-radio-input"
//   //                             value={this.state.mstartoption}
//   //                             checked={this.state.mstartoption === "custom_start"}
//   //                             onChange={this.handleMStartGroupChange}

//   //                           />

//   //                           <div className="card-radio d-flex flex-row align-items-center">
//   //                             <select
//   //                               type="select"
//   //                               name="m_starton"
//   //                               label="Name"
//   //                               value={this.state.mstartoption === "month_start" || this.state.mstartoption === null ? 'choose' : this.state.dsf}
//   //                               className="form-select"
//   //                               style={{ width: 60 }}
//   //                               id="m_starton"
//   //                               disabled={this.state.mstartoption === "month_start" || this.state.mstartoption === null}
//   //                               onChange={(e) => this.monthStartOn(e)}>
//   //                               <option value="choose" disabled >Choose...</option>
//   //                               {
//   //                                 this.state.dayStartNumber.map((data, idx) => {
//   //                                   return (
//   //                                     <option value={data.value} selected key={idx}>{data.name}</option>
//   //                                   )
//   //                                 })
//   //                               }
//   //                             </select>
//   //                             {this.state.mstartoption === "month_start" || this.state.mstartoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.dsf)}</span>}
//   //                           </div>
//   //                         </Label>
//   //                       </div>
//   //                       <span>&nbsp;and ends on &nbsp;&nbsp;</span>
//   //                       <div className="me-2">
//   //                         <Label className="card-radio-label">
//   //                           <Input
//   //                             type="radio"
//   //                             name="month_end"
//   //                             id="pay-methodoption1"
//   //                             className="card-radio-input"
//   //                             value={this.state.mendoption}
//   //                             checked={this.state.mendoption === "month_end"}
//   //                             onChange={this.handleMEndGroupChange}
//   //                           />

//   //                           <div className="card-radio py-4">
//   //                             <span style={{
//   //                               color: this.state.mendoption !== "month_end" || this.state.mendoption === null ? "#c0c1c5" : "#74788d"
//   //                             }}>Month end</span>
//   //                           </div>
//   //                         </Label>
//   //                       </div>
//   //                       <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>
//   //                       <div className="me-2">
//   //                         <Label className="card-radio-label">
//   //                           <Input
//   //                             type="radio"
//   //                             name="custom_end"
//   //                             id="pay-methodoption3"
//   //                             className="card-radio-input"
//   //                             value={this.state.mendoption}
//   //                             checked={this.state.mendoption === "custom_end"}
//   //                             onChange={this.handleMEndGroupChange}

//   //                           />

//   //                           <div className="card-radio d-flex flex-row align-items-center">
//   //                             <select
//   //                               type="select"
//   //                               name="m_endson"
//   //                               label="Name"
//   //                               value={this.state.mendoption === "month_end" || this.state.mendoption === null ? 'choose' : this.state.eoem}
//   //                               className="form-select"
//   //                               style={{ width: 60 }}
//   //                               id="m_endson"
//   //                               disabled={this.state.mendoption === "month_end" || this.state.mendoption === null}
//   //                               onChange={(e) => this.monthEndsOn(e)}>
//   //                               <option value="choose" disabled >Choose...</option>
//   //                               {
//   //                                 this.state.dayEndNumber.map((data, idx) => {
//   //                                   return (
//   //                                     <option value={data.value} selected key={idx}>{data.name}</option>
//   //                                   )
//   //                                 })
//   //                               }
//   //                             </select>
//   //                             {this.state.mendoption === "month_end" || this.state.mendoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.eoem)}</span>}
//   //                           </div>
//   //                         </Label>
//   //                       </div>
//   //                     </div>
//   //                   </Row>

//   //                 </div> : mode == "4" ?
//   //                   <div></div> : mode == "5" ?
//   //                     <div></div> :
//   //                     null

//   //       }

//   //     </Form>
//   //   )
//   // }

//   onCheck = (checked, event, mode) => {
//     //.log('checked, event', checked, event, mode, event.node)
//     console.log( checked, event, mode, event.node)
//     var publishTemplate ={...this.state.publishTemplate}

//     if (mode == "2") {
//       var findIdx = _.findIndex(publishTemplate.endpoints, { _id: event.node.node_id })
//       //.log(findIdx, 'findIdx',this.state.publishTemplate.endpoints)
//       if (event.checked == true) {
//         var endPointArray = {
//           adt_users: [],
//           code: null,
//           designation: "",
//           h_id: publishTemplate.hlevel_id,
//           hlevel: event.node.ep_level,
//           hlevel_name: event.node.label,
//           hlevel_value: event.node.ep_level_value,
//           user_path: event.node.user_path,
//           unique_users: event.node.unique_users,
//           _id: event.node.node_id
//         }
//         var assign_auditUser = _.filter(event.node.user_path,{cat_type :"1"})
//         if(assign_auditUser.length >0 && assign_auditUser.length <2){
//           assign_auditUser[0]["audit_type"] = "1"
//           endPointArray["rowValid"] = true
//           // this.state.publishTemplate.endpoints
//           // assign_auditUser[0]["rowValid"] = true
//           endPointArray.adt_users.push(assign_auditUser[0])
//         }
//         // var assign_auditUser = _.filter(this.state.publishTemplate.endpoints)
//         publishTemplate.endpoints.push(endPointArray)
//         publishTemplate["audit_userlevel"]="1"
//         publishTemplate["audit_userlevel_value"]=1
//         // this.state.publishTemplate["enable_review"] = false
//         // this.updatePublishTemplateData()

//       }
//       else {
//         // this.state.publishTemplate["audit_userlevel"]="1"
//         // this.state.publishTemplate["audit_userlevel_value"]=1
//         publishTemplate["enable_review"] = false
//         publishTemplate.endpoints.splice(findIdx, 1)
//       }
//       this.setState({
//         enableReview: false,
//         publishTemplate
//       }, () => { this.handleEndpointSelection(checked.checked, event) })
      
//     }
//     else {
//       publishTemplate["audit_userlevel"]="1"
//       publishTemplate["audit_userlevel_value"]=1
//       this.setState({
//         publishTemplate
//       },()=>{
//         this.handleEndpointSelection(checked, event)
//       })
      
//     }

//   };

//   buildTreeNodes = (nodes) => {
//     const { checkedKeys } = this.state;
//     if(this.state.publishTemplate.hirearchy_type === "2"){
//     return nodes.map((node) => {
//       const { value, label, children, ep_level, ep_level_value, id, node_id, parent, user_path,unique_users } = node;
//       const hasChildren =  (children && children.length > 0) ;
//       // console.log(nodes,'nodes')
//       // //.log(node,'node')
//       //  console.log(hasChildren,'hasChildren',node ,children,this.state.publishTemplate)
//       return {
//         key: value,
//         label: label,
//         user_path: user_path,
//         unique_users:unique_users,
//         value,
//         ep_level,
//         ep_level_value,
//         id,
//         node_id,
//         parent,
//         children: hasChildren ? this.buildTreeNodes(children) : [],

//         title: (
//           <div // Use a div container for the entire node
//             onClick={() => this.handleNodeClick(value)} // Handle node click
//             style={{
//               background: hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',

//               // background: hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',
//               position: 'relative',
//               zIndex: 'auto',
//               minHeight: '24px',
//               margin: '0',
//               padding: '0 4px',
//               color: 'inherit',
//               lineHeight: '24px',
//               borderRadius: '6px',
//               cursor: 'pointer',
//               transition: 'all 0.2s, border 0s, line - height 0s, box - shadow 0s'
//             }}
//           >
//             {hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? (
//               <FolderOutlined style={{ cursor: 'unset', opacity: 0.5 }} />
//             ) : (
//               <FileOutlined
//                 style={{
//                   cursor: 'pointer',
//                   color: '#556EE6',
//                   opacity: 1,
//                 }}
//               />
//             )}
//             <span style={{ marginLeft: '4px', opacity: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? 0.5 : 1, fontWeight: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? '' : 600 }}>
//               {label}
//             </span>
//           </div>
//         ),

//       };
//     });
//   }
//   if(this.state.publishTemplate.hirearchy_type === "1"){
//     return nodes.map((node) => {
//       const { value, label, children, ep_level, ep_level_value, id, node_id, parent, user_path,unique_users } = node;
//       const hasChildren =  (children && children.length > 0) ;
//       // console.log(nodes,'nodes')
//       // //.log(node,'node')
//       //  console.log(hasChildren,'hasChildren',node ,children,this.state.publishTemplate)
//       return {
//         key: value,
//         label: label,
//         user_path: user_path,
//         unique_users:unique_users,
//         value,
//         ep_level,
//         ep_level_value,
//         id,
//         node_id,
//         parent,
//         children: hasChildren ? this.buildTreeNodes(children) : [],

//         title: (
//           <div // Use a div container for the entire node
//             onClick={() => this.handleNodeClick(value)} // Handle node click
//             style={{
//               background: node.ep_level === sessionStorage.getItem("hlevel") ? "#E6F4FF" : "transparent",
//               // hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',

//               // background: hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',
//               position: 'relative',
//               zIndex: 'auto',
//               minHeight: '24px',
//               margin: '0',
//               padding: '0 4px',
//               color: 'inherit',
//               lineHeight: '24px',
//               borderRadius: '6px',
//               cursor: 'pointer',
//               transition: 'all 0.2s, border 0s, line - height 0s, box - shadow 0s'
//             }}
//           >
//             {
//               node.ep_level !== sessionStorage.getItem("hlevel") 
//             // hasChildren && this.state.publishTemplate.hirearchy_type == "1"
//              ? (
//               <FolderOutlined style={{ cursor: 'unset', opacity: 0.5 }} />
//             ) : (
//               <FileOutlined
//                 style={{
//                   cursor: 'pointer',
//                   color: '#556EE6',
//                   opacity: 1,
//                 }}
//               />
//             )}
//              <span style={{ marginLeft: '4px', opacity: node.ep_level === sessionStorage.getItem("hlevel") ?  1 :0.5, fontWeight: node.ep_level === sessionStorage.getItem("hlevel") ? 600 :'' }}>
//               {label}
//             </span>
//             {/* <span style={{ marginLeft: '4px', opacity: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? 0.5 : 1, fontWeight: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? '' : 600 }}>
//               {label}
//             </span> */}
//           </div>
//         ),

//       };
//     });
//   }
//   };


//   getAllTreeStructure = () => {
//     console.log(this.state.publishTemplate,this.state.hirerachyData._id,'this.state.publishTemplate')
//     var publishTemplate={...this.state.publishTemplate}
//     urlSocket.post("webphlbconf/getlocations", {
//       userInfo: {
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//         encrypted_db_url: this.state.db_info.encrypted_db_url
//       },
//       hInfo: {
//         // h_id: this.state.hirerachyData._id,
//         h_id:publishTemplate.hlevel_id,
//         hlevelinfo: "full_data",
//         publishtemplateInfo: publishTemplate
//       }
//     })
//       .then(response => {
//         //.log(response, 'response')
//         console.log(response,'response')
//         if (response.data.response_code == 500) {
//           var convertedFlatData = this.convertFlatDataToTreeData(response.data.data)
//           publishTemplate.ep_structure = convertedFlatData
//           this.setState({
//             locationData: convertedFlatData,
//           },
//             function () {
//               this.updateCheckedepStructure()
//             }
//           )

//         }
//       })

//   }

//   handleNodeClick = (value) => {
//     //.log(value, 'value')
//     console.log(value,'value')
//     this.setState({ selectedKey: value });
//   };

//   hierarchyType = (mode) => {
//     //.log(mode, 'mode', this.state.publishTemplate)
//     var publishTemplate={...this.state.publishTemplate}
//     publishTemplate['hirearchy_type'] = mode
//     publishTemplate['endpoints'] = []
//     publishTemplate['ep_level'] = null
//     publishTemplate['ep_selected'] = []
//     publishTemplate['eplevel_value'] = null
//     publishTemplate['audit_userlevel'] = null
//     publishTemplate['audit_userlevel_value'] = null
//     publishTemplate['enable_review'] = false
//     publishTemplate['review_userlevel'] = null
//     publishTemplate['review_userlevel_value'] = null
//     publishTemplate['cc_level'] = 0


//     if (mode == "2") {
//       this.setState({checkedKeys :[],enableReview : false,publishTemplate},()=>{this.getAllTreeStructure()})
//     }
//     else {
//       this.setState({ publishTemplate, endpointSelected: false, selectedEndpoint: "choose", checkedKeys: [], enableReview: false }, () => { this.updatePublishTemplateData() })
//     }
//   }



//   ignoreLocation = (event, idx, item, mode) => {
//     //.log(event, 'ignode', idx, item, this.state.publishTemplate)
//     var publishTemplate={...this.state.publishTemplate}
//     if (mode == false || mode == undefined) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Ignore Confirmation',
//         text: 'Are you sure you want to ignore this location?',
//         showCancelButton: true,
//         confirmButtonColor: '#d33',
//         cancelButtonColor: '#3085d6',
//         confirmButtonText: 'Ignore',
//         cancelButtonText: 'Cancel',
//       }).then((result) => {
//         if (result.isConfirmed) {
//           // this.state.publishTemplate.endpoints.map((ele,idx)=>{
//           //   //.log(ele._id)
//           // })
//           publishTemplate.endpoints[idx]["disabled"] = true
//           // var ignore_location = this.state.publishTemplate.endpoints.filter((item, position) => position === idx)
//           //  var ignore_ep_selected = this.state.publishTemplate.ep_selected.filter((data,position)=> data !== item._id)
//           // //.log(ignore_location, 'ignore_location',idx)
//           //  this.state.publishTemplate["endpoints"] = ignore_location
//           //  this.state.publishTemplate["ep_selected"] = ignore_ep_selected
//           this.setState({ publishTemplate , dataloaded : false }, () => {this.setState({dataloaded : true},()=>{this.updatePublishTemplateData()})})

//         }

//       })
//     }
//     else if(mode == true) {
//       Swal.fire({
//         icon: 'question',
//         title: 'Reconsider Again ?',
//         text: 'Are you sure want to Reconsider this Location again ?',
//         showCancelButton: true,
//         confirmButtonColor: '#2ba92b',
//         confirmButtonText: 'Yes',
//         cancelButtonColor: '#d33',
//         cancelButtonText: 'No'
//         // confirmButtonText: 'Go to Published Audits',
//       }).then((result) => {
//         if (result.isConfirmed) {
//           publishTemplate.endpoints[idx]["disabled"] = false
//           // this.setState({ publishTemplate: this.state.publishTemplate }, () => { this.updatePublishTemplateData() })
//           this.setState({ publishTemplate , dataloaded : false }, () => {this.setState({dataloaded : true},()=>{this.updatePublishTemplateData()})})
//         }
//       })
//     }

//   }

//   onRemove=(selectedItem,selectedList)=>{
//     //.log(selectedItem,'selectedItem',selectedList)
//     this.setState({
//       selected_category : selectedList
//     })

    
//   }








//   clearUsers = (data, index) => {
//     //.log(data, index, 'index')
//     var publishTemplate={...this.state.publishTemplate}
//     Swal.fire({
//       icon: 'question',
//       title: 'Reconsider Again ?',
//       text: 'Are you sure want to Clear this Location Users ?',
//       showCancelButton: true,
//       confirmButtonColor: '#2ba92b',
//       confirmButtonText: 'Yes',
//       cancelButtonColor: '#d33',
//       cancelButtonText: 'No'
//       // confirmButtonText: 'Go to Published Audits',
//     }).then((result) => {

//       if (result.isConfirmed) {
//         //.log(data, index, 'index')
//         publishTemplate["endpoints"][index]["adt_users"] = []
//         publishTemplate["endpoints"][index]["rowValid"] =false
//         this.setState({ publishTemplate, dataloaded: false }, () => { this.setState({ dataloaded: true }, () => { this.updatePublishTemplateData() }) })

//       }

//     })
//   }

//   // tog_center=()=>{
//   //   this.setState(prevState => ({
//   //     custom_modal: !prevState.custom_modal
//   // }));
//   // }


//   toggle = () => {
//     this.setState(prevState => ({
//         modal: !prevState.modal
//     }));
// }

//   passWordBtn = () => {
//     //.log("triggree")
//     this.setState({ show: !this.state.show, })
//   }
// // }


// onSelectValues=(selectedList, selectedItem)=>{
//   //.log(selectedList, selectedItem,'selectedList, selectedItem, index')
//   this.setState({
//     selected_category : selectedList
//   })


// }





//   render() {
//     var treeNodes = this.buildTreeNodes(this.state.locationData);
//     console.log(this.state.locationData,'this.state.locationData')
//     if (this.state.dataloaded) {
//       const { locationData } = this.state
//       // const { SearchBar } = Search;
//       const { isEdit } = this.state
//       const options = {
//         page: this.state.paginationPage,

//         sizePerPage: 10,
//         totalSize: this.state.publishTemplate.endpoints.length, // replace later with size(users),
//         custom: true,
//         onPageChange: (e) => {
//           this.setState({ paginationPage: e })
//         }
//       };
//       var columns

//       if(this.state.methodSelected == "2"){
//        columns = [


//         {
//           accessor: 'hlevel_name',
//           Header: 'Location',
//           // sort: true,
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (
//               <>
//                 <div style={{ display: 'flex', opacity: item.disabled ? "50%": "none", flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>
//                   {item.hlevel_name}
//                 </div>
//               </>
//             )
//           }
//         },
//         {
//           accessor: 'code',
//           Header: 'Code',
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (
//               <div style={{ color: item.rowValid ? null : "#f46a6a" }}>
//                 {item.code}
//               </div>
//             )
//           }
//         },
//         {
//           accessor: 'hlevel',
//           Header: 'Category',
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (
//               <div style={{ color: item.rowValid ? null : "#f46a6a" ,opacity: item.disabled ? "50%": "none"}}>
//                 {item.hlevel}
//               </div>
//             )
//           }
//         },

//         {
//           accessor: 'adt_usr',
//           Header: 'Audit User',
//           Cell: (cellProps) => {
//             //.log(item,'item')
//             var item = cellProps.row.original
//             var index = cellProps.row.index
//             var mappedUser
//             var auditor_index

//             if (this.state.publishTemplate.method_selected == "2") {

//               var getIndex = _.findIndex(item.adt_users, (user) => {
//                 return (user.review_user === undefined || user.review_user === false) && (user.cat_type === "1" || user.cat_type === "2" || user.cat_type === "3" || user.cat_type == null || user.cat_type == user.cat_type);
//               });
//               if(item.unique_users.length >0){
//               const auditor_idx = item.unique_users.findIndex(user => user.cat_type.includes('1'));
//               auditor_index = item.unique_users[auditor_idx]?.cat_type.indexOf('1');
//               //.log(auditor_index, 'catTypeIndex')
//             }

//             }
//             else {
//               var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
//             }
//             if (getIndex !== -1) {
//               mappedUser = item.adt_users[getIndex]
             
//               item["rowValid"] = true
//             }
//             else {
//               mappedUser = undefined
//             }
//             console.log(mappedUser,'mappedUser',getIndex)
//             return (
//               <>
//                 <div>
//                   <select
//                     type="select"
//                     name="adtuser"
//                     label="Name"
//                     value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
//                     className="form-select"
//                     id={"adtuser" + String(item._id)}
//                     disabled={item.disabled ? true : false}
//                     onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}>
//                     <option value="choose" disabled >Choose...</option>
//                     <option value="add" >Create New User</option>
//                     <option value="#import" >+ Add Users</option>

//                     {
//                       //.log(item,'item')
//                     }
//                     {
//                       item?.unique_users.map((data, idx) => {
//                         const isUserDisabled = item.adt_users.some(user => user.user_id === data.user_id &&  user.audit_type === "2");

//                         // const isUserDisabled = item.adt_users.some(user => user.user_id === data.user_id);
//                         return (
//                           <option value={data.user_id} disabled={isUserDisabled} key={idx}>{data.name} - {auditor_index !== undefined ? data.hirerachy_name[auditor_index] == undefined ? data.hirerachy_name[0] : data.hirerachy_name[auditor_index] :data.hirerachy_name[0]}</option>
//                         )
//                       })
//                     }
//                   </select>
//                 </div>
//               </>
//             )
//           }
//         },

//         // {
//         //   accessor: '',
//         //   Header: 'Review User',
//         //   // hidden: this.state.methodSelected === "2" ? true : false,
//         //   Cell: (cellProps) => {
//         //     var item =  cellProps.row.original
//         //     var index =  cellProps.row.index

//         //     var mappedUser
//         //     var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
//         //     if (getIndex !== -1) {
//         //       mappedUser = item.adt_users[getIndex]
//         //       this.updateCCLevel()
//         //     }
//         //     else {
//         //       mappedUser = undefined
//         //     }

//         //     return (
//         //       <>
//         //         {
//         //           !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null
//         //         }
//         //         {
//         //           this.state.customEnableReview ?
//         //             <div>
//         //               <select
//         //                 type="select"
//         //                 name="rvwuser"
//         //                 label="Name"
//         //                 value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
//         //                 className="form-select"
//         //                 id={"custrvwuser" + String(item._id)}
//         //                 // disabled={}
//         //                 onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
//         //                 <option value="choose" disabled >Choose...</option>
//         //                 <option value="add" >Add New User</option>
//         //                 {
//         //                   this.state.userdata.map((data, idx) => {
//         //                     return (
//         //                       <option value={data._id} selected key={idx}>{data.firstname}</option>
//         //                     )
//         //                   })
//         //                 }
//         //               </select>
//         //             </div> : null
//         //         }

//         //       </>
//         //     )
//         //   }
//         // },

//         {
//           accessor: '',
//           Header: 'Review User',
//           hidden: this.state.methodSelected === "2" ? false : true,
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             var index = cellProps.row.index
//             var mappedUser
//             var catTypeIndex
//             //.log(this.state.publishTemplate.method_selected ,'this.state.publishTemplate.method_selected ')
//             if (this.state.publishTemplate.method_selected == "2") {
//               var getIndex = _.findIndex(item.adt_users, (user) => {
//                 return user.review_user === true && (user.cat_type === "1" || user.cat_type === "2" || user.cat_type === "3" || user.cat_type == null);
//               });
//               const reviwer_idx = item.unique_users.findIndex(user => user.cat_type.includes('2'));
//               //.log(reviwer_idx,'reviwer_idx')
//               if(reviwer_idx !== -1){
//               catTypeIndex = item.unique_users[reviwer_idx].cat_type.indexOf('2');
//             }
//             }
//             else {
//               var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
//             }
//             if (getIndex !== -1) {
//               mappedUser = item.adt_users[getIndex]
             
//               //.log('index',catTypeIndex)
//               // this.updateCCLevel()
//             }
//             else {
//               mappedUser = undefined
//             }
//             //.log(catTypeIndex,'catTypeIndex')
//             return (
//               <>
//                 {
//                   !this.state.enableReview ? <div className="text-secondary">Review not enable</div> : null
//                 }
//                 {
//                   this.state.enableReview ?
//                     <div>
//                       <select
//                         type="select"
//                         name="rvwuser"
//                         label="Name"
//                         value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
//                         className="form-select"
//                         id="rvwuser"
//                         disabled={!this.state.enableReview || item.disabled ? true : false}
//                         onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
//                         <option value="choose" disabled >Choose...</option>
//                         <option value="add" >Create New User</option>
//                     <option value="#import" >+ Add Users</option>
                      
//                         {/* <option value="add" >Create New User</option> */}

//                         {
//                           item.unique_users.map((data, idx) => {
//                             console.log(data,'data')
//                             //.log(data.hirerachy_name[catTypeIndex],catTypeIndex)
//                             const isUserDisabled = item.adt_users.some(user => user.user_id === data.user_id &&  user.audit_type === "1");
//                             return (
//                               <option value={data._id} disabled={isUserDisabled} selected key={idx}>{data.name} - {catTypeIndex !== undefined ? data.hirerachy_name[catTypeIndex] == undefined ? data.hirerachy_name[0] : data.hirerachy_name[catTypeIndex] :data.hirerachy_name[0]}</option>
//                             )
//                           })
//                         }
//                       </select>
//                     </div> : null
//                 }

//               </>
//             )
//           }
//         },


//         {
//           accessor: "menu",
//           // isDummyField: true,
//           // editable: false,
//           Header: "Reset Users",
//           // hidden: this.state.methodSelected === "2" && this.state.publishTemplate.enable_review == false ? true : false,
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             var index = cellProps.row.index
//             return(
//           <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
//              {
//                 this.state.methodSelected =="2" && this.state.publishTemplate.enable_review &&
//                 <Button className={"btn btn-primary"} onClick={(e)=>{this.clearUsers(item,index)}}>Clear </Button>
//              }

//             </div>
//           )

//             },
//         },

//         {
//           accessor: "menus",
//           // isDummyField: true,
//           // editable: false,
//           Header: "Action",
//           // hidden: this.state.methodSelected === "2" ? false : false,
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             var index = cellProps.row.index
//             return(
//             <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
//               {
//                 this.state.methodSelected == "1" ?
//               <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>
//                 :
//                   <Button className={item.disabled ? "btn btn-success" :"btn btn-danger"} onClick={(e)=>{this.ignoreLocation(e,index,item,item.disabled)}}>{item.disabled ? "Reconsider" : "Ignore"} </Button>
//               }
//             </div>
//             )
//           },
//         },
//       ];
//     }
//     if(this.state.methodSelected === "1"){
//       if(this.state.publishTemplate.enable_review === true){
//        columns = [
//        {

//         accessor: 'hlevel_names',

//         Header: 'Location',

//             // sort: true,

//             Cell: (cellProps) => {
//               var item = cellProps.row.original
//                 return (

//                     <>

//                         <div style={{ display: 'flex', flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>

//                             {item.hlevel_name}

//                         </div>

//                     </>

//                 )

//             }

//         },

//         {
//           accessor: 'codes',
//           Header: 'Code',
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (
//               <div style={{ color: item.rowValid ? null : "#f46a6a" }}>
//                 {item.code}
//               </div>
//             )
//           }
//         },

//         {
//           accessor: 'hlevels',
//           Header: 'Category',
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (
//               <div style={{ color: item.rowValid ? null : "#f46a6a" ,opacity: item.disabled ? "50%": "none"}}>
//                 {item.hlevel}
//               </div>
//             )
//           }
//         },



//         {
//           accessor: 'adt',
//           Header: 'Audit User',
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             var index = cellProps.row.index
//                 var mappedUser

//                 var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })

//                 if (getIndex !== -1) {

//                     mappedUser = item.adt_users[getIndex]

//                 }

//                 else {

//                     mappedUser = undefined

//                 }

//                 // console.log(cellContent, item, index)

//                 return (

//                     <>

//                         <div>

//                             <select

//                                 type="select"

//                                 name="adtuser"

//                                 label="Name"

//                                 value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

//                                 className="form-select"

//                                 id={"adtuser" + String(item._id)}

//                                 onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}>

//                                 <option value="choose" disabled >Choose...</option>

//                                 <option value="add" >Add New User</option>

//                                 {

//                                     this.state.userdata.map((data, idx) => {

//                                         return (

//                                             <option value={data._id} key={idx}>{data.firstname}</option>

//                                         )

//                                     })

//                                 }

//                             </select>

//                         </div>

//                     </>

//                 )

//             }

//         },
       
//         {
//           accessor: 'rw',
//           Header: 'Review User',
//           // hidden: this.state.methodSelected === "2" ? true : false,
//           Cell: (cellProps) => {
//             var item =  cellProps.row.original
//             var index =  cellProps.row.index

//             var mappedUser
//             var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
//             if (getIndex !== -1) {
//               mappedUser = item.adt_users[getIndex]
//               // this.updateCCLevel()
//             }
//             else {
//               mappedUser = undefined
//             }

//             return (
//               <>
//                 {
//                   !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null
//                 }
//                 {
//                   this.state.customEnableReview ?
//                     <div>
//                       <select
//                         type="select"
//                         name="rvwuser"
//                         label="Name"
//                         value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
//                         className="form-select"
//                         id={"custrvwuser" + String(item._id)}
//                         // disabled={}
//                         onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
//                         <option value="choose" disabled >Choose...</option>
//                         <option value="add" >Add New User</option>
//                         {
//                           this.state.userdata.map((data, idx) => {
//                             return (
//                               <option value={data._id} selected key={idx}>{data.firstname}</option>
//                             )
//                           })
//                         }
//                       </select>
//                     </div> : null
//                 }

//               </>
//             )
//           }
//         }
// ,

//         {

//           accessor: "menua",
//             Header: "Action",
//             Cell: (cellProps) => {
//               var item = cellProps.row.original
//               var index = cellProps.row.index
//               return(

//                 <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
//                     <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>
//                 </div>
//                 )

//               }

//         },
        

//     ];
//   }
//   else{
//     columns = [
//       {

//        accessor: 'hlevel_names',

//        Header: 'Location',

//            // sort: true,

//            Cell: (cellProps) => {
//              var item = cellProps.row.original
//                return (

//                    <>

//                        <div style={{ display: 'flex', flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>

//                            {item.hlevel_name}

//                        </div>

//                    </>

//                )

//            }

//        },

//        {
//          accessor: 'codes',
//          Header: 'Code',
//          Cell: (cellProps) => {
//            var item = cellProps.row.original
//            return (
//              <div style={{ color: item.rowValid ? null : "#f46a6a" }}>
//                {item.code}
//              </div>
//            )
//          }
//        },

//        {
//          accessor: 'hlevels',
//          Header: 'Category',
//          Cell: (cellProps) => {
//            var item = cellProps.row.original
//            return (
//              <div style={{ color: item.rowValid ? null : "#f46a6a" ,opacity: item.disabled ? "50%": "none"}}>
//                {item.hlevel}
//              </div>
//            )
//          }
//        },



//        {
//          accessor: 'adt',
//          Header: 'Audit User',
//          Cell: (cellProps) => {
//            var item = cellProps.row.original
//            var index = cellProps.row.index
//                var mappedUser

//                var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })

//                if (getIndex !== -1) {

//                    mappedUser = item.adt_users[getIndex]

//                }

//                else {

//                    mappedUser = undefined

//                }

//                // console.log(cellContent, item, index)

//                return (

//                    <>

//                        <div>

//                            <select

//                                type="select"

//                                name="adtuser"

//                                label="Name"

//                                value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

//                                className="form-select"

//                                id={"adtuser" + String(item._id)}

//                                onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}>

//                                <option value="choose" disabled >Choose...</option>

//                                <option value="add" >Add New User</option>

//                                {

//                                    this.state.userdata.map((data, idx) => {

//                                        return (

//                                            <option value={data._id} key={idx}>{data.firstname}</option>

//                                        )

//                                    })

//                                }

//                            </select>

//                        </div>

//                    </>

//                )

//            }

//        },
      
// //        {
// //          accessor: 'rw',
// //          Header: 'Review User',
// //          // hidden: this.state.methodSelected === "2" ? true : false,
// //          Cell: (cellProps) => {
// //            var item =  cellProps.row.original
// //            var index =  cellProps.row.index

// //            var mappedUser
// //            var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
// //            if (getIndex !== -1) {
// //              mappedUser = item.adt_users[getIndex]
// //              this.updateCCLevel()
// //            }
// //            else {
// //              mappedUser = undefined
// //            }

// //            return (
// //              <>
// //                {
// //                  !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null
// //                }
// //                {
// //                  this.state.customEnableReview ?
// //                    <div>
// //                      <select
// //                        type="select"
// //                        name="rvwuser"
// //                        label="Name"
// //                        value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
// //                        className="form-select"
// //                        id={"custrvwuser" + String(item._id)}
// //                        // disabled={}
// //                        onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
// //                        <option value="choose" disabled >Choose...</option>
// //                        <option value="add" >Add New User</option>
// //                        {
// //                          this.state.userdata.map((data, idx) => {
// //                            return (
// //                              <option value={data._id} selected key={idx}>{data.firstname}</option>
// //                            )
// //                          })
// //                        }
// //                      </select>
// //                    </div> : null
// //                }

// //              </>
// //            )
// //          }
// //        }
// // ,

//        {

//          accessor: "menua",
//            Header: "Action",
//            Cell: (cellProps) => {
//              var item = cellProps.row.original
//              var index = cellProps.row.index
//              return(

//                <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
//                    <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>
//                </div>
//                )

//              }

//        },
       

//    ];
//   }
//     }
//       const defaultSorted = [{
//         dataField: 'hname', // if dataField is not match to any column you defined, it will be ignored.
//         order: 'asc' // desc or asc
//       }];
//       return (
//         <React.Fragment>
//           <div className="page-content">
//             <MetaTags>
//               <title>AuditVista | Publishing</title>
//             </MetaTags>
//             <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-1">
//               <div style={{ width: '80%', padding: '0 20px' }}>Publishing</div>
//               <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
//                 <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//               </div>
//             </div>
//             <Container fluid>
//               {
//                 this.state.methodSelected === "0" ? <div>
//                   <Card>
//                     <CardBody>
//                       <Label className="my-3">Add Location by</Label>

//                       <Row>
//                         <Col xl="6" sm="6">
//                           <div className="me-2 text-wrap">
//                             {/* <p className="card-title-desc">
//                               Use the <code>.badge-pill</code> modifier className to
//                               make badges more rounded (with a larger{" "}
//                               <code>border-radius</code>
//                               and additional horizontal <code>padding</code>).
//                               Useful if you miss the badges from v3.
//                             </p> */}
//                             <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("2")}>Hirerachy Level</button>
//                           </div>
//                         </Col>

//                         <Col xl="6" sm="6">

//                           <div className="me-2 text-wrap">
//                             {/* <p className="card-title-desc">
//                               Use the <code>.badge-pill</code> modifier className to
//                               make badges more rounded (with a larger{" "}
//                               <code>border-radius</code>
//                               and additional horizontal <code>padding</code>).
//                               Useful if you miss the badges from v3.
//                             </p> */}
//                             <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("1")}>Manual Location</button>
//                           </div>
//                         </Col>
//                       </Row>
//                     </CardBody>
//                   </Card>
//                 </div> : null
//               }

//               {
//                 this.state.methodSelected === "1" ?
//                   <div>

//                     <div className="checkout-tabs">
//                       <Row>
//                         <Col lg="2" sm="3" className="pe-0">
//                           <Nav className="flex-column" pills>
//                             <NavItem>
//                               <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f', marginBottom:"5px" }}
//                                 className={classnames({
//                                   active: this.state.activeCustomTab === 1 && this.state.cc_level == 0,
//                                   activechange: this.state.activeCustomTab === 1 && this.state.cc_level > 0
//                                 })

//                                 }
//                                 onClick={() => {
//                                   this.toggleCustomTab(1)
//                                 }}
                              
//                               >
//                                 <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />
//                                 <p className="font-weight-bold mb-4">Locations</p>
//                               </NavLink>
//                             </NavItem>
//                             <NavItem>
//                               <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6', marginBottom:"5px" }}
//                                 className={classnames({
//                                   active: this.state.activeCustomTab === 2 && this.state.cc_level > 1,
//                                   activechange: this.state.activeCustomTab === 2 && this.state.cc_level > 2
//                                 })}
//                                 onClick={() => {
//                                   this.toggleCustomTab(2)
//                                 }}
//                                 disabled={this.state.cc_level > 1 ? false : true}
//                               >
//                                 <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />
//                                 <p className="font-weight-bold mb-4">Configuration</p>
//                               </NavLink>
//                             </NavItem>
//                             <NavItem >
//                               <NavLink style={{ border: this.state.cc_level < 3 ? "0px" : this.state.cc_level > 3 ? '1px solid #34c38f' : '1px solid #556ee6', marginBottom:"5px" }}
//                                 className={classnames({
//                                   active: this.state.activeCustomTab === 3 && this.state.cc_level > 2,
//                                   activechange: this.state.activeCustomTab === 3 && this.state.cc_level >= 4
//                                 })}
//                                 onClick={() => {
//                                   this.toggleCustomTab(3)
//                                 }}
//                                 disabled={this.state.cc_level > 2 ? false : true}
//                               >
//                                 <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
//                                 <p className="font-weight-bold mb-4">Confirmation</p>
//                               </NavLink>
//                             </NavItem>

//                           </Nav>
//                         </Col>

//                         <Col lg="10" sm="9" className="px-1">
//                           <AvForm className="form-horizontal" >
//                             <Card>
//                               <CardBody>
//                                 <TabContent activeTab={this.state.activeCustomTab}>

//                                   <TabPane
//                                     tabId={1}>
//                                     <div>
//                                       {
//                                         this.state.auditUserlevelSelected ?
//                                         <>
//                                                            <Row className="mb-2" style={{ paddingTop: 10 }}>
//                                                           <Col sm="3">
//                                                             <div className="search-box ms-2 mb-2 d-inline-block">
//                                                               <div className="position-relative">
//                                                                 {/* <SearchBar {...toolkitprops.searchProps} /> */}
//                                                                 {/* <i className="bx bx-search-alt search-icon" /> */}
//                                                               </div>
//                                                             </div>
//                                                           </Col>
//                                                           <Col sm="9" >
//                                                             <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
//                                                               <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20, position: 'relative', }} >
//                                                                 <div className="select-container me-2">
//                                                                   <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
//                                                                     <option value="">Select User Group</option>
//                                                                     <option value="all">All</option>
//                                                                     {
//                                                                       this.state.labelData?.map((data, idx) => {
//                                                                         return (
//                                                                           <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
//                                                                         )
//                                                                       })
//                                                                     }

//                                                                   </select>
//                                                                 </div>
//                                                                 <div>
//                                                                   <Button color={"primary"} outline className="btn-md me-2 " style={{ padding: '7px' }}
//                                                                     onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadendpoint", editItem: null })}
//                                                                   >
//                                                                     Load locations
//                                                                   </Button>
//                                                                   <Button color={"primary"} outline className="btn-sm me-2" style={{ padding: '7px' }}
//                                                                     onClick={() => this.setState({ open: this.state.open ? false : true, component: "endpoint", editItem: null })}
//                                                                   >
//                                                                     Add new location
//                                                                   </Button>
//                                                                   <Button color={"danger"} outline className="btn-sm" style={{ padding: '7px' }}
//                                                                     onClick={() => { this.deleteAllRequest() }}
//                                                                   >
//                                                                     Delete all locations
//                                                                   </Button>

//                                                                 </div>
//                                                               </div>
//                                                             </div>
//                                                           </Col>
//                                                         </Row>
//                                                         <Row>
//                                                           <div className="form-check form-switch form-switch-sm mx-4">
//                                                             <input
//                                                               type="checkbox"
//                                                               className="form-check-input"
//                                                               id={"customreview"}
//                                                               onChange={(e) => { this.onchangeCustomEnableReview(e) }}
//                                                               checked={this.state.customEnableReview}
//                                                             />
//                                                             <label className={"form-check-label text-primary"} htmlFor={"customreview"}>
//                                                               Enable Review
//                                                             </label>
//                                                           </div>
//                                                         </Row>
//                                             <TableContainer
//                                               columns={columns}
//                                               data={this.state.publishTemplate.endpoints}
//                                               isGlobalFilter={true}
//                                               isAddOptions={false}
//                                               isJobListGlobalFilter={false}
//                                               customPageSize={10}
//                                               style={{ width: '100%' }}
//                                               // dynamicBtn={true}
//                                               // btnClick={() => { this.setState({ open: true, eopt: null }) }}
//                                               // // dynamicParams={params1,params2}
//                                               // btnName={"Add Location"}
//                                               isPagination={true}
//                                               // iscustomPageSizeOptions={true}
//                                               filterable={false}
//                                               tableClass="align-middle table-nowrap table-check"
//                                               theadClass="table-light"
//                                               pagination="pagination pagination-rounded justify-content-end mb-2"
//                                             />
//                                             <Row>
//                                                           <Col md={11} className='text-end'>
//                                                          </Col>
//                                                       <Col md={1}>
//                                                            <Button className={this.state.cc_level === 0 ? "btn btn-secondary" : "btn btn-success"} disabled={this.state.cc_level === 0} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
//                                                           </Col>
//                                                        </Row>
//                                                        <br />
//                                                       <Row>
//                                                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
//                                                          <Button color={"danger"}
//                                                             onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method
//                                                          </Button>
//                                                           </div>
//                                                         </Row>
//   </>
                                          
//                                           : null
//                                       }

//                                     </div>
//                                   </TabPane>

//                                   <TabPane
//                                     tabId={2}
//                                     id="v-pills-confir"
//                                     role="tabpanel">
//                                     <div>
//                                       <div className='mb-3'>
//                                         <span className='font-size-17'>Configuration</span>
//                                       </div>

//                                       <hr className="my-4" />

//                                       <div>
//                                         <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>
//                                         <p className="card-title-desc">
//                                           Use the <code>.badge-pill</code> modifier className to
//                                           make badges more rounded (with a larger{" "}
//                                           <code>border-radius</code>
//                                           and additional horizontal <code>padding</code>).
//                                           Useful if you miss the badges from v3.
//                                         </p>
//                                         <div className="mb-3 col-6">
//                                           <select
//                                             type="select"
//                                             name="repeat_mode"
//                                             label="Name"
//                                             value={this.state.selectedRepeatMode}
//                                             className="form-select"
//                                             id="review_mode_level"
//                                             required
//                                             onChange={(e) => this.selectRepeatMode(e)}
//                                           >
//                                             <option value="choose" disabled>Choose...</option>
//                                             {
//                                               this.state.repeatModeData.map((data, idx) => {
//                                                 return (
//                                                   <option value={String(idx)} selected key={idx}>{data.mode}</option>
//                                                 )
//                                               })
//                                             }
//                                           </select>
//                                         </div>
//                                       </div>

//                                       <div>
//                                         {this.repeatModeComponent(this.state.selectedRepeatMode)}
//                                       </div>
//                                       <Row>
//                                         <Col md={11} className='text-end'>
//                                           <Button color="primary" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button>
//                                         </Col>
//                                         <Col md={1}>
//                                           <Button color="success" disabled={this.state.cc_level == 2} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
//                                         </Col>
//                                       </Row>
//                                     </div>
//                                   </TabPane>

//                                   <TabPane
//                                     tabId={3}
//                                     id="v-pills-confir"
//                                     role="tabpanel">
//                                     <div>
//                                       <div className='mb-2'>
//                                         <span className='font-size-17'>Confirmation</span> <br />
//                                         <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>
//                                       </div>

//                                       <Row className="justify-content-center">
//                                         <Col xl={10}>

//                                           <div className="mt-2">
//                                             <div className="d-flex flex-wrap">
//                                               <div className="me-2">
//                                                 <span className="me-2 font-size-18">Locations</span> <span
//                                                   onClick={() => {
//                                                     this.toggleCustomTab(1)
//                                                   }}
//                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                   edit
//                                                 </span>

//                                               </div>
//                                               <div className="ms-auto">
//                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                               </div>
//                                             </div>
//                                             <hr className="mt-2" />

//                                             <div className="list-group list-group-flush">
//                                               <div
//                                                 to="#"
//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Total Locations - <span className="text-success">{this.state.publishTemplate.endpoints.length}</span>
//                                               </div>
//                                             </div>
//                                           </div>


//                                           <div className="mt-5">
//                                             <div className="d-flex flex-wrap">
//                                               <div className="me-2">
//                                                 <span className="me-2 font-size-18">Configuration</span>
//                                                 <span
//                                                   onClick={() => {
//                                                     this.toggleCustomTab(2)
//                                                   }}
//                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                   edit
//                                                 </span>
//                                               </div>
//                                               <div className="ms-auto">
//                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                               </div>
//                                             </div>
//                                             <hr className="mt-2" />

//                                             <div className="list-group list-group-flush">

//                                               <div
//                                                 to="#"
//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Repeat Mode - <span className="text-success">{this.state.publishTemplate.repeat_mode}</span>
//                                               </div>
//                                               <div to="#"
//                                                 className="list-group-item text-muted d-flex">
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Display &nbsp;&nbsp;
//                                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                   <div>Audit Score:</div>&nbsp;&nbsp;
//                                                   <input type={"checkbox"} name='audit_score_preview'  checked={this.state.publishTemplate.audit_score_preview === true ? true : false} onChange={(e) => this.handle_click(e)} />&nbsp;&nbsp;
//                                                 </div>
//                                                 {
//                                                   this.state.customEnableReview === true &&
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Review Score:</div>&nbsp;&nbsp;
//                                                     <input type={"checkbox"} name="review_score_preview" checked={this.state.publishTemplate.review_score_preview === true ? true : false} onChange={(e) => this.handle_click(e)} />
//                                                   </div>
//                                                 }


//                                               </div>

//                                               <div to="#"
//                                                 className="list-group-item text-muted d-flex">
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Track Location &nbsp;&nbsp;
//                                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                   <div>Auditor :</div>&nbsp;&nbsp;
//                                                   <input type={"checkbox"} name='audit_coords_enable' checked={this.state.publishTemplate.audit_coords_enable === true ? true : false} onChange={(e) => this.handle_click(e)} />&nbsp;&nbsp;
//                                                 </div>
//                                                 {
//                                                   this.state.customEnableReview === true &&
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Reviewer :</div>&nbsp;&nbsp;
//                                                     <input type={"checkbox"} name="review_coords_enable"  checked={this.state.publishTemplate.review_coords_enable === true ? true : false} onChange={(e) => this.handle_click(e)} />
//                                                   </div>
//                                                 }

//                                               </div>

//                                               <div
//                                                 to="#"
//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Publish on <span className="text-danger">*</span>
//                                                 <input
//                                                   className="form-control"
//                                                   type="date"
//                                                   min={this.state.fromate_date}
//                                                   selected={this.state.ptl_startdate}
//                                                   defaultValue={this.state.ptl_startdate}
//                                                   id="start_date"
//                                                   onChange={event => this.onChangeStartDate(event)}
//                                                   onKeyDown={(e) => { e.preventDefault() }}
//                                                 />
//                                               </div>

//                                               <div
//                                                 to="#"
//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Audit End on <span className="text-danger">*</span>
//                                                 <input
//                                                   className="form-control"
//                                                   type="date"
//                                                   min={this.state.ptl_startdate}
//                                                   value={this.state.ptl_startdate > this.state.ptl_enddate ? "" : this.state.ptl_enddate}
//                                                   selected={this.state.ptl_enddate}
//                                                   defaultValue={this.state.ptl_enddate}
//                                                   max={this.calculateMaxDate()}
//                                                   disabled={this.state.ptl_startdate == '' || this.state.ptl_startdate == undefined}
//                                                   id="end_date"
//                                                   onChange={event => this.onChangeEndDate(event)}
//                                                   onKeyDown={(e) => { e.preventDefault() }}
//                                                 />
//                                               </div>



//                                             </div>
//                                           </div>

//                                           <div className="mt-5">
//                                             <div className="d-flex flex-wrap">
//                                               <div className="me-2">
//                                                 <span className="me-2 font-size-18">Check points</span> <span
//                                                   onClick={() => this.navigateTo(this.state.publishTemplate)}
//                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                   edit
//                                                 </span>
//                                               </div>
//                                               <div className="ms-auto">
//                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                               </div>
//                                             </div>
//                                             <hr className="mt-2" />

//                                             <div className="list-group list-group-flush">
//                                               <div

//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>
//                                               </div>

//                                             </div>
//                                           </div>

//                                         </Col>
//                                       </Row>
//                                       <Row>
//                                         <div className="wizard clearfix">
//                                           <div className="actions clearfix">
//                                             <Button color="primary" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button>
//                                             <button className='btn btn-primary mx-2' onClick={() => { window.location.href = "/mngpblhtempt" }}> Save </button>
//                                             <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>
//                                           </div>
//                                           <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>
//                                         </div>
//                                       </Row>

//                                     </div>

//                                   </TabPane>

//                                 </TabContent>
//                               </CardBody>
//                             </Card>
//                           </AvForm>
//                         </Col>

//                       </Row>
//                     </div>



//                   </div> :
//                   this.state.methodSelected === "2" ?
//                     <div>
//                       <div className="checkout-tabs">
//                         <Row>

//                           <Col lg="2" sm="3">
//                             <Nav className="flex-column" pills>
//                               <NavItem>
//                                 <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f' }}
//                                   className={classnames({
//                                     active: this.state.activeTab === 1 && this.state.cc_level == 0,
//                                     activechange: this.state.activeTab === 1 && this.state.cc_level > 0
//                                   })

//                                   }
//                                   onClick={() => {
//                                     this.toggleTab(1)
//                                   }}
//                                 >
//                                   <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />
//                                   <p className="font-weight-bold mb-4">Hirerachy Level</p>
//                                 </NavLink>
//                               </NavItem>
//                               <NavItem>
//                                 <NavLink style={{ border: this.state.cc_level == 0 ? "0px" : this.state.cc_level > 1 ? '1px solid #34c38f' : '1px solid #556ee6' }}
//                                   className={classnames({
//                                     active: this.state.activeTab === 2 && this.state.cc_level > 0,
//                                     activechange: this.state.activeTab === 2 && this.state.cc_level > 1
//                                   })}
//                                   onClick={() => {
//                                     this.toggleTab(2);
//                                   }}
//                                   disabled={this.state.cc_level > 0 ? false : true}
//                                 >
//                                   <i className="bx bxs-user d-block check-nav-icon mt-4 mb-2" />
//                                   <p className="font-weight-bold mb-4">Audit / Review</p>
//                                 </NavLink>
//                               </NavItem>
//                               <NavItem>
//                                 <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6' }}
//                                   className={classnames({
//                                     active: this.state.activeTab === 3 && this.state.cc_level > 1,
//                                     activechange: this.state.activeTab === 3 && this.state.cc_level > 2
//                                   })}
//                                   onClick={() => {
//                                     this.toggleTab(3)
//                                   }}
//                                   disabled={this.state.cc_level > 1 ? false : true}
//                                 >
//                                   <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />
//                                   <p className="font-weight-bold mb-4">Configuration</p>
//                                 </NavLink>
//                               </NavItem>
//                               <NavItem >
//                                 <NavLink style={{ border: this.state.cc_level < 3 ? "0px" : this.state.cc_level === 3 ? '1px solid #556ee6' : '1px solid #34c38f' }}
//                                   className={classnames({
//                                     active: this.state.activeTab === 4 && this.state.cc_level > 2,
//                                     activechange: this.state.activeTab === 4 && this.state.cc_level > 3
//                                   })}
//                                   onClick={() => {
//                                     this.toggleTab(4)
//                                   }}
//                                   disabled={this.state.cc_level > 2 ? false : true}
//                                 >
//                                   <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
//                                   <p className="font-weight-bold mb-4">Confirmation</p>
//                                 </NavLink>
//                               </NavItem>
//                             </Nav>
//                           </Col>
//                           <Col lg="10" sm="9">
//                             <AvForm className="form-horizontal" onValidSubmit={this.crudNode} onInvalidSubmit={this.handleInvalidSubmit}>
//                               <Card>
//                                 <CardBody>
//                                   <TabContent activeTab={this.state.activeTab}>

//                                     <TabPane
//                                       tabId={1}>
//                                       <div>

//                                         <Row className='mb-3'>
//                                           <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
//                                             <div className='col-8'>
//                                               <span className='font-size-17'>Hirerachy Level</span>
//                                             </div>
//                                             <div className='col-4'>
//                                               <button className="btn btn-danger" onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method</button>
//                                             </div>
//                                           </div>
//                                         </Row>

//                                         <div>
//                                           <div>
//                                             <Label className="text-primary" htmlFor="autoSizingSelect">Select Level</Label>
//                                             <p className="card-title-desc">
//                                               Select appropriate hirerachy level from dropdown or {" "}
//                                               <badge className='btn badge badge-soft-primary rounded-pill font-size-12 me-1'>Create New Hirerachy level </badge>
//                                             </p>
//                                             <div className="mb-3 col-6">

//                                               <AvInput
//                                                 type="select"
//                                                 name="hlevel"
//                                                 label="HirerachLevel"
//                                                 value={this.state.selectedHLevelValue}
//                                                 className="form-select"
//                                                 id="hlevel1"
//                                                 required
//                                                 onChange={(e) => this.selectLevel(e)}>
//                                                 <option value="0" defaultValue disabled >Choose...</option>
//                                                 {
//                                                   this.state.dataSource?.map((data, idx) => {
//                                                     return (
//                                                       <option value={String(idx + 1)} selected key={idx}>{data.hname}</option>
//                                                     )
//                                                   })
//                                                 }
//                                               </AvInput>
//                                             </div>
//                                           </div>

//                                           <hr className="my-4" />
//                                           {
//                                             this.state.hlevelIsSelected ?
//                                               <div>
//                                                 <div to="#"
//                                                   className="list-group-item text-primary d-flex">
//                                                   Select Hirerachy Type: &nbsp;&nbsp;
//                                                 </div>
//                                                 <div style={{ display: 'flex', alignItems: 'center', marginLeft: '19px' }} className='mt-3'>
//                                                   <input type={"radio"} checked={this.state.publishTemplate.hirearchy_type == "1" ? true : false} onChange={(e) => { this.hierarchyType("1") }} />&nbsp;&nbsp;<div onChange={(e) => { this.hierarchyType("1") }} >Organised Structure</div>&nbsp;&nbsp;
//                                                   <input type={"radio"} checked={this.state.publishTemplate.hirearchy_type == "2" ? true : false} onChange={(e) => { this.hierarchyType("2") }} />&nbsp;&nbsp;
//                                                   <div onChange={(e) => { this.hierarchyType("2") }} >Un-Organised Structure</div>&nbsp;&nbsp;
//                                                 </div>
//                                               </div>
//                                               : null
//                                           }
//                                           <hr className="my-4" />
//                                           {
//                                             console.log( this.state.hlevelIsSelected,' this.state.hlevelIsSelected')
//                                           }
//                                           {
//                                             this.state.publishTemplate.hirearchy_type == "1" && this.state.hlevelIsSelected ?
//                                               <div >
//                                                 <Label className="text-primary" htmlFor="autoSizingSelect">Select Location</Label>
//                                                 <p className="card-title-desc">
//                                                   Use the <code>.badge-pill</code> modifier className to
//                                                   make badges more rounded (with a larger{" "}
//                                                   <code>border-radius</code>
//                                                   and additional horizontal <code>padding</code>).
//                                                   Useful if you miss the badges from v3.
//                                                 </p>
//                                                 <div className="mb-3 col-6">
//                                                   <select
//                                                     type="select"
//                                                     name="location"
//                                                     label="Name"
//                                                     value={this.state.selectedEndpoint}
//                                                     className="form-select"
//                                                     id="location"
//                                                     required
//                                                     onChange={(e) => this.selectLocation(e)}>
//                                                     <option value="choose" disabled >Choose...</option>
//                                                     {
//                                                       this.state.hlevelData.map((data, idx) => {
//                                                         return (
//                                                           <option value={String(idx + 1)} selected key={idx}>{data.hlevel}</option>
//                                                         )
//                                                       })
//                                                     }
//                                                   </select>

//                                                 </div></div>
//                                               :
//                                               null
//                                           }

//                                           {this.state.hlevelData.length > 0 && this.state.publishTemplate.hirearchy_type == "1" ? <hr className="my-4" /> : null}
//                                           {
//                                             this.state.endpointSelected && this.state.publishTemplate.hirearchy_type == "1" ?
//                                               <div>
//                                                 <Label className="text-primary" htmlFor="autoSizingSelect">Select {this.state.hlevelSelected}</Label>
//                                                 <p className="card-title-desc">
//                                                   Use the <code>.badge-pill</code> modifier className to
//                                                   make badges more rounded (with a larger{" "}
//                                                   <code>border-radius</code>
//                                                   and additional horizontal <code>padding</code>).
//                                                   Useful if you miss the badges from v3.
//                                                 </p>
//                                                 {
//                                                   console.log(this.state.checkedKeys,'this.state.checkedKeys')
//                                                 }
//                                                 <Tree
//                                                   style={{ borderColor: '#150101' }}
//                                                   defaultExpandAll={true}
//                                                   treeData={treeNodes}
//                                                   checkable
//                                                   checkedKeys={this.state.checkedKeys}
//                                                   onCheck={(checked, event) => { this.onCheck(checked, event) }}
//                                                   showLine={true}
//                                                   showIcon={true}
//                                                 />




//                                               </div>
//                                               :
//                                               this.state.publishTemplate.hirearchy_type == "2" ?
//                                                 <div>
//                                                   <Label className="text-primary" htmlFor="autoSizingSelect">Select Location</Label>
//                                                   <p className="card-title-desc">
//                                                     Use the <code>.badge-pill</code> modifier className to
//                                                     make badges more rounded (with a larger{" "}
//                                                     <code>border-radius</code>
//                                                     and additional horizontal <code>padding</code>).
//                                                     Useful if you miss the badges from v3.
//                                                   </p>
//                                                   <Tree
//                                                     style={{ borderColor: '#150101' }}
//                                                     defaultExpandAll={true}
//                                                     treeData={treeNodes}
//                                                     checkable
//                                                     checkedKeys={this.state.checkedKeys}
//                                                     onCheck={(checked, event) => { this.onCheck(checked, event, "2") }}
//                                                     showLine={true}
//                                                     showIcon={true}
//                                                     checkStrictly={true}
//                                                   />
//                                                 </div>
//                                                 :
//                                                 null
//                                           }


//                                         </div>

//                                       </div>
//                                     </TabPane>
//                                           {/* {
//                                             //.log(this.state.publishTemplate,'publish')
//                                           } */}
//                                     <TabPane
//                                       tabId={2}
//                                       id="v-pills-confir"
//                                       role="tabpanel">
//                                       <div>
//                                         <div className='mb-3'>
//                                           <span className='font-size-17'>Audit & Review</span>
//                                         </div>

//                                         <div>
//                                           <Label className="text-primary" htmlFor="autoSizingSelect">Select audit user level</Label>
//                                           <p className="card-title-desc">
//                                             Use the <code>.badge-pill</code> modifier className to
//                                             make badges more rounded (with a larger <code>border-radius</code>
//                                             and additional horizontal <code>padding</code>).
//                                             Useful if you miss the badges from v3.
//                                           </p>
//                                           <div className="row">
//                                             <div className="mb-3 col-6">
//                                               <select
//                                                 type="select"
//                                                 name="audit_userlevel"
//                                                 label="Name"
//                                                 value={this.state.publishTemplate.audit_userlevel_value == null ? "choose" : String(this.state.publishTemplate.audit_userlevel_value)}
//                                                 className="form-select"
//                                                 id="audit_user_level"
//                                                 required
//                                                 onChange={(e) => this.selectAuditUserlevel(e)}
//                                               >
//                                                 <option value="choose" disabled>Choose...</option>
//                                                 {
//                                                   this.state.hirerachy_user_level.map((data, idx) => {
//                                                     return (
//                                                       <option value={data.cat_type} key={idx}>{data.hirerachy_name}</option>
//                                                     )
//                                                   })
//                                                 }
//                                               </select>
//                                             </div>
//                                             {/* <div className="mb-3 col-6">
//                                               <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
//                                                 <option value="">Select User Group</option>
//                                                 <option value="all">All</option>
//                                                 {this.state.labelData.map((data, idx) => {
//                                                   return (
//                                                     <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
//                                                   )
//                                                 })}
//                                               </select>
//                                             </div> */}
//                                           </div>
//                                         </div>

//                                         <hr className="my-4" />

//                                         <div className="form-check form-switch form-switch-sm my-3">
//                                           <input
//                                             type="checkbox"
//                                             className="form-check-input"
//                                             id={"review"}
//                                             onChange={(e) => { this.onchangeEnableReview(e) }}
//                                             checked={this.state.enableReview}
//                                           />
//                                           <label className={"form-check-label text-primary"} htmlFor={"review"}>
//                                             Enable Review
//                                           </label>
//                                         </div>


//                                         {this.state.enableReview ?
//                                           <div>
//                                             <Label className="text-primary" htmlFor="autoSizingSelect">Select review user level</Label>
//                                             <p className="card-title-desc">
//                                               Use the <code>.badge-pill</code> modifier className to
//                                               make badges more rounded (with a larger{" "}
//                                               <code>border-radius</code>
//                                               and additional horizontal <code>padding</code>).
//                                               Useful if you miss the badges from v3.
//                                             </p>
//                                             <div className="mb-3 col-6">
//                                               <select
//                                                 type="select"
//                                                 name="review_userlevel"
//                                                 label="Name"
//                                                 value={this.state.publishTemplate.review_userlevel == null ? "choose" : String(this.state.publishTemplate.review_userlevel)}
//                                                 className="form-select"
//                                                 id="review_user_level"
//                                                 required
//                                                 onChange={(e) => this.selectReviewUserlevel(e)}
//                                               >
//                                                 <option value="choose" disabled>Choose...</option>
//                                                 {
//                                                   this.state.hirerachy_user_level.map((data, idx) => {
//                                                     return (
//                                                       <option value={data.cat_type} key={idx}>{data.hirerachy_name}</option>
//                                                     )
//                                                   })
//                                                 }
//                                               </select>
//                                             </div>
//                                             <hr className="my-4" />
//                                           </div>
//                                           : null}

//                                         {
//                                           this.state.auditUserlevelSelected ?
//                                             <div>
//                                                <TableContainer
//                                         columns={columns}
//                                         data={this.state.publishTemplate.endpoints}
//                                         // isGlobalFilter={true}
//                                         isAddOptions={false}
//                                         isJobListGlobalFilter={false}
//                                         customPageSize={10}
//                                         style={{ width: '100%' }}
//                                         // dynamicBtn={true}
//                                         // btnClick={() => { this.setState({ open: true, eopt: null }) }}
//                                         // // dynamicParams={params1,params2}
//                                         // btnName={"Add Location"}
//                                         isPagination={true}
//                                         // iscustomPageSizeOptions={true}
//                                         filterable={false}
//                                         tableClass="align-middle table-nowrap table-check"
//                                         theadClass="table-light"
//                                         pagination="pagination pagination-rounded justify-content-end mb-2"
//                                       />
//                                               {/* <PaginationProvider
//                                                 keyField="id"
//                                                 data={this.state.publishTemplate.endpoints}
//                                                 columns={columns}
//                                                 pagination={paginationFactory(options)}
//                                               >
//                                                 {
//                                                   ({
//                                                     paginationProps,
//                                                     paginationTableProps
//                                                   }) => (
//                                                     <ToolkitProvider
//                                                       keyField="id"
//                                                       data={this.state.publishTemplate.endpoints}
//                                                       columns={columns}
//                                                       search
//                                                     >
//                                                       {
//                                                         toolkitprops => (
//                                                           <React.Fragment>
//                                                             <Row className="mb-2" style={{ paddingTop: 10 }}>
//                                                               <Col sm="4">
//                                                                 <div className="search-box ms-2 mb-2 d-inline-block">
//                                                                   <div className="position-relative">
//                                                                     <SearchBar {...toolkitprops.searchProps} />
//                                                                     <i className="bx bx-search-alt search-icon" />
//                                                                   </div>
//                                                                 </div>
//                                                               </Col>
//                                                             </Row>
//                                                             <Row>
//                                                               <Col xl="12">
//                                                                 <div className="table-responsive">
//                                                                   <BootstrapTable
//                                                                     {...toolkitprops.baseProps}
//                                                                     {...paginationTableProps}
//                                                                     defaultSorted={defaultSorted}
//                                                                     classes={
//                                                                       "table align-middle table-nowrap table-hover"
//                                                                     }
//                                                                     bordered={false}
//                                                                     striped={false}
//                                                                     responsive
//                                                                     onTableChange={this.handleTableChange}
//                                                                   />
//                                                                 </div>
//                                                               </Col>
//                                                             </Row>
//                                                             <Row className="align-items-md-center mt-30">
//                                                               <Col className="pagination pagination-rounded justify-content-end mb-2">
//                                                                 <PaginationListStandalone
//                                                                   {...paginationProps}
//                                                                 />
//                                                               </Col>
//                                                             </Row>
//                                                           </React.Fragment>
//                                                         )}
//                                                     </ToolkitProvider>
//                                                   )}
//                                               </PaginationProvider> */}
//                                             </div> : null
//                                         }

//                                       </div>
//                                     </TabPane>

//                                     <TabPane
//                                       tabId={3}
//                                       id="v-pills-config"
//                                       role="tabpanel"
//                                       aria-labelledby="v-pills-config-tab"
//                                     >
//                                       <div>
//                                         <div className='mb-3'>
//                                           <span className='font-size-17'>Configuration</span>
//                                         </div>

//                                         <hr className="my-4" />

//                                         <div>
//                                           <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>
//                                           <p className="card-title-desc">
//                                             Use the <code>.badge-pill</code> modifier className to
//                                             make badges more rounded (with a larger{" "}
//                                             <code>border-radius</code>
//                                             and additional horizontal <code>padding</code>).
//                                             Useful if you miss the badges from v3.
//                                           </p>
//                                           <div className="mb-3 col-6">
//                                             <select
//                                               type="select"
//                                               name="repeat_mode"
//                                               label="Name"
//                                               value={this.state.selectedRepeatMode}
//                                               className="form-select"
//                                               id="review_mode_level"
//                                               required
//                                               onChange={(e) => this.selectRepeatMode(e)}
//                                             >
//                                               <option value="choose" disabled>Choose...</option>
//                                               {
//                                                 this.state.repeatModeData.map((data, idx) => {
//                                                   return (
//                                                     <option value={String(idx)} selected key={idx}>{data.mode}</option>
//                                                   )
//                                                 })
//                                               }
//                                             </select>
//                                           </div>
//                                         </div>

//                                         <div>
//                                           {this.repeatModeComponent(this.state.selectedRepeatMode)}
//                                         </div>

//                                       </div>
//                                     </TabPane>

//                                     <TabPane
//                                       tabId={4}
//                                       id="v-pills-confir"
//                                       role="tabpanel">
//                                       <div>
//                                         <div className='mb-2'>
//                                           <span className='font-size-17'>Confirmation</span> <br />
//                                           <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>
//                                         </div>

//                                         <Row className="justify-content-center">
//                                           <Col xl={10}>

//                                             <div className="mt-2">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Hirerach Level</span> <span
//                                                     onClick={() => {
//                                                       this.toggleTab(1)
//                                                     }}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                     edit
//                                                   </span>

//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Hirerachy level - <span className="text-success">{this.state.publishTemplate.hlevel}</span>
//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Endpoint - <span className="text-success">{this.state.publishTemplate.ep_level} </span>
//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   No. of Endpoints selected - <span className="text-success">{this.state.publishTemplate.ep_selected.length}</span>
//                                                 </div>
//                                               </div>
//                                             </div>

//                                             {/* <div className="mt-5">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Audit / Review</span> <span
//                                                     onClick={() => {
//                                                       this.toggleTab(2)
//                                                     }}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                     edit
//                                                   </span>
//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Audit user level - <span className="text-success">{this.state.publishTemplate.audit_userlevel}</span>
//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Review user level - {this.state.publishTemplate.review_userlevel == null ? <span className="text-danger"> No Review </span> : <span className="text-success">{this.state.publishTemplate.review_userlevel}</span>}
//                                                 </div>

//                                               </div>
//                                               <div  to="#"
//                                                 className="list-group list-group-flush">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Display &nbsp;&nbsp;
//                                                   <div style={{display: 'flex', alignItems: 'center'}}>
//                                                   <div>Audit Score:</div>&nbsp;&nbsp;
//                                                   <input type={"checkbox"} name='audit_score_preview' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;
//                                                   </div>
//                                                 {
//                                                   this.state.customEnableReview === true &&
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Review Score:</div>&nbsp;&nbsp;
//                                                     <input type={"checkbox"} name="review_score_preview" onChange={(e) => this.handle_click(e)} />
//                                                   </div>
//                                                 }


//                                               </div>
//                                             </div> */}


//                                             <div className="mt-5">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Audit / Review</span>
//                                                   <span
//                                                     onClick={() => {
//                                                       this.toggleTab(2);
//                                                     }}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1"
//                                                   >
//                                                     edit
//                                                   </span>
//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div to="#" className="list-group-item text-muted">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Audit user level -{" "}
//                                                   <span className="text-success">
//                                                     {this.state.publishTemplate.audit_userlevel}
//                                                   </span>
//                                                 </div>

//                                                 <div to="#" className="list-group-item text-muted">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Review user level -{" "}
//                                                   {this.state.publishTemplate.review_userlevel == null ? (
//                                                     <span className="text-danger"> No Review </span>
//                                                   ) : (
//                                                     <span className="text-success">
//                                                       {this.state.publishTemplate.review_userlevel}
//                                                     </span>
//                                                   )}
//                                                 </div>

//                                                 <div to="#" className="list-group-item text-muted d-flex gap-3">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Display:{" "}
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Audit Score:</div>&nbsp;&nbsp;
//                                                     <input
//                                                       type={"checkbox"}
//                                                       name="audit_score_preview"
//                                                       checked={this.state.publishTemplate.audit_score_preview === true ? true : false}
//                                                       onChange={(e) => this.handle_click(e)}
//                                                     />
//                                                     &nbsp;&nbsp;
//                                                   </div>
//                                                   {this.state.publishTemplate.review_userlevel !== null && (
//                                                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                       <div>Review Score:</div>&nbsp;&nbsp;
//                                                       <input
//                                                         type={"checkbox"}
//                                                         name="review_score_preview"
//                                                         checked={this.state.publishTemplate.review_score_preview === true ? true : false}
//                                                         onChange={(e) => this.handle_click(e)}
//                                                       />
//                                                     </div>
//                                                   )}
//                                                 </div>

//                                                 <div to="#" className="list-group-item text-muted d-flex gap-3">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Track Location :{" "}
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Auditor :</div>&nbsp;&nbsp;
//                                                     <input
//                                                       type={"checkbox"}
//                                                       name="audit_coords_enable"
//                                                       checked={this.state.publishTemplate.audit_coords_enable === true ? true : false}
//                                                       onChange={(e) => this.handle_click(e)}
//                                                     />
//                                                     &nbsp;&nbsp;
//                                                   </div>
//                                                   {this.state.publishTemplate.review_userlevel !== null && (
//                                                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                       <div>Reviewer:</div>&nbsp;&nbsp;
//                                                       <input
//                                                         type={"checkbox"}
//                                                         name="review_coords_enable"
//                                                         checked={this.state.publishTemplate.review_coords_enable === true ? true : false}
//                                                         onChange={(e) => this.handle_click(e)}
//                                                       />
//                                                     </div>
//                                                   )}
//                                                 </div>



//                                               </div>
//                                             </div>




//                                             <div className="mt-5">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Configuration</span> <span
//                                                     onClick={() => {
//                                                       this.toggleTab(3)
//                                                     }}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                     edit
//                                                   </span>
//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Publish on <span className="text-danger">*</span>
//                                                   {/* <input
//                                                     className="form-control"
//                                                     type="date"
//                                                     min={this.state.fromate_date}
//                                                     selected={this.state.ptl_startdate}
//                                                     defaultValue={this.state.ptl_startdate}
//                                                     id="start_date"
//                                                     onChange={event => this.onChangeStartDate(event)}
//                                                   /> */}

//                                                   <input
//                                                     className="form-control"
//                                                     type="date"
//                                                     min={this.state.fromate_date}
//                                                     selected={this.state.ptl_startdate}
//                                                     defaultValue={this.state.ptl_startdate}
//                                                     id="start_date"
//                                                     onChange={event => this.onChangeStartDate(event)}
//                                                     onKeyDown={(e) => { e.preventDefault() }}
//                                                   />






//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Audit End on <span className="text-danger">*</span>
//                                                   {/* <input
//                                                     className="form-control"
//                                                     type="date"
//                                                     min={this.state.fromate_date}
//                                                     selected={this.state.ptl_enddate}
//                                                     defaultValue={this.state.ptl_enddate}
//                                                     id="end_date"
//                                                     onChange={event => this.onChangeEndDate(event)}
//                                                   /> */}
//                                                   <input
//                                                     className="form-control"
//                                                     type="date"
//                                                     min={this.state.ptl_startdate}
//                                                     value={this.state.ptl_startdate > this.state.ptl_enddate ? "" : this.state.ptl_enddate}
//                                                     selected={this.state.ptl_enddate}
//                                                     defaultValue={this.state.ptl_enddate}
//                                                     max={this.calculateMaxDate()}
//                                                     disabled={this.state.ptl_startdate == '' || this.state.ptl_startdate == undefined}
//                                                     onKeyDown={(e) => { e.preventDefault() }}
//                                                     id="end_date"
//                                                     onChange={event => this.onChangeEndDate(event)}
//                                                   />





//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Frequency - <span className="text-success">{this.state.publishTemplate.frequency}</span>
//                                                 </div>

//                                               </div>
//                                             </div>

//                                             <div className="mt-5">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Check points</span> <span
//                                                     onClick={() => this.navigateTo(this.state.publishTemplate)}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                     edit
//                                                   </span>
//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div

//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>
//                                                 </div>

//                                               </div>
//                                             </div>

//                                           </Col>
//                                         </Row>
//                                         <Row>
//                                           <div className="wizard clearfix">
//                                             <div className="actions clearfix">
//                                               <button className='btn btn-primary mx-2'> Save </button>
//                                               <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>
//                                             </div>
//                                             <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>
//                                           </div>
//                                         </Row>
//                                       </div>
//                                     </TabPane>

//                                   </TabContent>
//                                 </CardBody>
//                               </Card>

//                               {/* <Row>
//                         <div className="wizard clearfix">
//                           <div className="actions clearfix">
//                             <ul>
//                               <li
//                                 className={
//                                   this.state.activeTab === 1 
//                                     ? "previous disabled"
//                                     : "previous"
//                                 }
//                               >
//                                 <Link
//                                   to="#"
//                                   onClick={() => {
//                                     this.toggleTab(this.state.activeTab - 1)
//                                   }}
//                                 >
//                                   Previous
//                                 </Link>
//                               </li>
//                               <li
//                                 className={
//                                   this.state.activeTab === 4 
//                                     ? "next disabled"
//                                     : "next"
//                                 }
//                               >
//                                 <Link
//                                   to="#"
//                                   onClick={() => {
//                                     this.toggleTab(this.state.activeTab + 1)
//                                   }}
//                                 >
//                                   Next
//                                 </Link>
//                               </li>
//                             </ul>
//                           </div>
//                         </div>
//                       </Row> */}
//                             </AvForm>
//                           </Col>
//                         </Row>
//                       </div>
//                     </div>
//                     : null
//               }

//             </Container>
//           </div>

//           {/* <Modal
//             isOpen={this.state.custom_modal}
//             toggle={() => {
//               this.tog_center();
//             }}
//             centered
//           >
//             <div className="modal-header">
//               <h5 className="modal-title mt-0">Center Modal</h5>
//               <button
//                 type="button"
//                 onClick={() => {
//                   // setmodal_center(false);
//                   this.setState({custom_modal : false})
//                 }}
//                 className="close"
//                 data-dismiss="modal"
//                 aria-label="Close"
//               >
//                 <span aria-hidden="true">&times;</span>
//               </button>
//             </div>
//             <div className="modal-body">
//               <p>
//                 Cras mattis consectetur purus sit amet fermentum.
//                 Cras justo odio, dapibus ac facilisis in, egestas
//                 eget quam. Morbi leo risus, porta ac consectetur
//                 ac, vestibulum at eros.
//               </p>
//               <p>
//                 Praesent commodo cursus magna, vel scelerisque
//                 nisl consectetur et. Vivamus sagittis lacus vel
//                 augue laoreet rutrum faucibus dolor auctor.
//               </p>
//               <p className="mb-0">
//                 Aenean lacinia bibendum nulla sed consectetur.
//                 Praesent commodo cursus magna, vel scelerisque
//                 nisl consectetur et. Donec sed odio dui. Donec
//                 ullamcorper nulla non metus auctor fringilla.
//               </p>
//             </div>
//           </Modal> */}








//               {
//                 this.state.modal == true ?
//               <CrudUserHlvl
//                 modal={this.state.modal}
//                 toggle={this.toggle}
//                 onValidSubmit={this.locationUserCrud}
//                 email_format={this.emai_addrs}
//                 validate_email={this.validate_email}
//                 countries={this.state.client_info.countries}
//                 select_country={this.select_country}
//                 given_email={this.state.given_email}
//                 show_password={this.state.show}
//                 loading={this.state.loading}
//                 hirerachy_user_level={this.state.hirerachy_user_level}
//                 passWordBtn={this.passWordBtn}
//                 country_code_err ={this.state.country_code_err}
//                 email_err = {this.state.invalid_user}
//                 valid_email = {this.state.errorMessage}
//                 category_select ={this.onSelectValues}
//                 onRemove={this.onRemove}
//                 />
//               :
//               null
//               }






//           <ReactDrawer
//             open={this.state.open}
//             className="drawer-open"
//             position={this.state.position}
//             onClose={this.onDrawerClose}
//             style={{ maxHeight: 1000 }}
//             noOverlay={false}
//           >

//             <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>
//               {
//                 this.state.component === "user" ?
//                 // <CrudUserHlvl />
//                 <>
//                 </>
             
//                   : this.state.component === "endpoint" ?
//                     <AddEndpointNode
//                       endpoints={this.state.publishTemplate.endpoints}
//                       publishtemplateInfo={this.state.publishTemplate}
//                       onClose={this.onDrawerClose}
//                       editItem={this.state.editItem}
//                       onCancel={this.onCancelDrawerClose}
//                     /> : this.state.component === "loadendpoint" ?

//                       <LoadEndpoint
//                         userInfo={this.state.userInfo}
//                         publishtemplateInfo={this.state.publishTemplate}
//                         onClose={this.onDrawerClose}

//                       />
//                       :
//                       this.state.component === "import_usr" ?
//                       <ImportUser 
//                       user_data ={this.state.to_import_users}
//                       onClose={this.onDrawerClose}
//                       rowData ={this.state.location_info}
//                       coloumn_type={this.state.coloumn_type}
//                       hstructure={JSON.parse(sessionStorage.getItem("hstructure"))}
//                       updateInFlatCln={this.updateInFlatCln}
//                       />
//                       :
//                       // this.state.component === "loadgroupusers" ?
//                       // <LoadGroupUser
//                       // userInfo={this.state.userInfo}
//                       // endpoint_data ={this.state.endpoint_data}
//                       // publishtemplateInfo={this.state.publishTemplate}
//                       // onClose={this.onDrawerClose}
//                       // />:


//                       null
//               }

//             </div>
//           </ReactDrawer>

//           {this.state.changeMethodConfirmation ? (
//             <SweetAlert
//               title="Are you sure?"
//               warning
//               showCancel
//               confirmBtnBsStyle="success"
//               cancelBtnBsStyle="danger"
//               onConfirm={() => {
//                 this.state.triggerFrom === "hlevel" ? this.resetEndpointMethod() : this.state.triggerFrom === "endpoint" ? this.deleteEndpoint() : this.state.triggerFrom === "deleteall" && this.deleteAllEndpoints()
//               }
//               }
//               onCancel={() =>
//                 this.setState({
//                   changeMethodConfirmation: false,
//                   error_dlg: true,
//                   dynamic_title: "Cancelled",
//                   dynamic_description:
//                     "Your imaginary file is safe :)",
//                 })
//               }
//             >
//               {this.state.triggerFrom === "hlevel" ? "Changing the Add Location method will clear all saved data." : this.state.triggerFrom === "endpoint" ? "Deleting the Location can't revet." : this.state.triggerFrom === "deleteall" ? "Deleteing all Location can't revert" : ""}
//             </SweetAlert>
//           ) : null}
//           {this.state.success_dlg ? (
//             <SweetAlert
//               success
//               title={this.state.dynamic_title}
//               onConfirm={() => this.setState({ success_dlg: false })}
//             >
//               {this.state.dynamic_description}
//             </SweetAlert>
//           ) : null}
//           {this.state.dataAlert ? (
//             <SweetAlert
//               title={this.state.alertMessage}
//               onConfirm={() => this.setState({
//                 dataAlert: false,
//                 publishTemplate: this.state.publishTemplate,
//                 selectedReviewUserlevel: this.state.selectedReviewUserlevel,
//                 reviewUserlevelSelected: true,
//                 cc_level: this.state.cc_level,
//                 ptl_startdate: null,
//                 ptl_enddate: null,
//                 repeatMode: null,
//                 endpoints: this.state.endpoints,
//                 review_userlevel: this.state.review_userlevel,
//                 auditUserlevelSelected: true
//               })}
//             ></SweetAlert>
//           ) : null}

//           {this.state.tmpltPublishProcess ? (
//             <SweetAlert
//               title={this.state.alertMessage}
//               onConfirm={() => this.redirectTo(this.state.processStatus)}
//             ></SweetAlert>
//           ) : null}
//         </React.Fragment>
//       )
//     }
//     else {
//       // console.log("Here comes the else part")
//       return null
//     }
//   }
// }



































// import React, { Component } from "react"

// import MetaTags from 'react-meta-tags';
// import {
//   Container,
//   Row,
//   Col,
//   Table,
//   Input,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   TabPane,
//   Card,
//   Form,
//   FormGroup,
//   Label,
//   CardBody,
//   CardTitle,
//   CardSubtitle,
//   InputGroup,
//   Button,
//   Alert
// } from "reactstrap"
// import Select from "react-select"
// import { Link } from "react-router-dom"
// import facility from '../FacilityScreen'
// import SweetAlert from "react-bootstrap-sweetalert"
// import LoadGroupUser from './Components/LoadGroupUser'
// import SortableTree, {
//   addNodeUnderParent,
//   removeNodeAtPath,
//   toggleExpandedForAll,
//   defaultGetNodeKey,
//   getTreeFromFlatData,
//   getNodeAtPath,
//   changeNodeAtPath,
//   getFlatDataFromTree,
// } from 'react-sortable-tree/dist/index.cjs.js';

// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// import TableContainer from "../../common/TableContainer";
// // import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// // import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// // import BootstrapTable from "react-bootstrap-table-next"
// import CrudUserHlvl from "./CrudUserHlvl";

// import makeAnimated from "react-select/animated"
// import "flatpickr/dist/themes/material_blue.css"
// import Flatpickr from "react-flatpickr"

// // import SortableTree, {
// //   addNodeUnderParent,
// //   removeNodeAtPath,
// //   toggleExpandedForAll,
// //   getTreeFromFlatData,
// //   getNodeAtPath,
// //   changeNodeAtPath,
// //   getFlatDataFromTree,
// //   walk,
// //   map, find
// // } from 'react-sortable-tree/dist/index.cjs.js';
// import ReactDrawer from 'react-drawer';
// import 'react-drawer/lib/react-drawer.css';

// import classnames from "classnames"

// //import AddNode from "./Components/AddNode"

// import AddUserNode from "./Components/AddUserNode"
// import AddEndpointNode from "./Components/AddEndpointNode"

// //Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"

// import CheckboxTree, {
//   getNode, flattenNodes, deserializeLists, serializeList
// } from 'react-checkbox-tree';
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// import { MdCheckBox, MdCheckBoxOutlineBlank, MdChevronRight, MdKeyboardArrowDown, MdAddBox, MdIndeterminateCheckBox, MdLocationCity, MdStore, MdFolder, MdCheckCircle, MdDescription } from "react-icons/md";
// import CryptoJS from 'crypto-js'
// import _, { repeat } from "lodash";

// import Validation from "./Functions/validation"
// import LoadEndpoint from "./Components/LoadEndpoints"
// import { LoadingOutlined } from '@ant-design/icons';

// import { FolderOutlined, FileOutlined } from '@ant-design/icons';
// import classNames from 'classnames';
// import { DownOutlined } from '@ant-design/icons';
// import { Tree } from 'antd';
// import './custom-tree-styles.css';
// import Swal from "sweetalert2";
// import ImportUser from "./ImportUser";





// // var urlSocket = require("../../helpers/urlSocket");
// import urlSocket from "../../helpers/urlSocket";
// var moment = require('moment')

// const icons = {
//   check: <MdCheckBox className="rct-icon rct-icon-check text-success" />,
//   uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
//   halfCheck: (
//     <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
//   ),
//   expandClose: (
//     <MdChevronRight className="rct-icon rct-icon-expand-close" />
//   ),
//   expandOpen: (
//     <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
//   ),
//   expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
//   collapseAll: (
//     <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
//   ),
//   parentClose: <MdLocationCity className="rct-icon rct-icon-parent-close" />,
//   parentOpen: <MdLocationCity className="rct-icon rct-icon-parent-open" />,
//   leaf: <MdStore className="rct-icon rct-icon-leaf-close" />
// }


// const nodes = [{
//   "value": 'Mars',
//   "label": 'Mars',
//   "children": [
//     { "value": 'Phobos', "label": 'Phobos' },
//     { "value": 'Deimos', "label": 'Deimos' },
//   ],
// }];



// export default class publishconfig extends Component {

//   constructor(props) {
//     super(props);

//     this.state = {
//       activeTab: 1,
//       activeCustomTab: 1,
//       selectedGroup: null,
//       dataloaded: false,
//       isEdit: false,
//       show_occurence_valid : true,
//       hirerachyData: [],
//       hlevelData: [],
//       hlevelIsSelected: false,
//       endpointSelected: false,
//       locationData: [],
//       checked: [],
//       expanded: [],
//       hlevelSelected: '',
//       auditUserlevelSelected: false,
//       reviewUserlevelSelected: false,
//       endpoints: [],
//       endpointsAvailable: false,
//       repeatModeData: [],
//       weekDays: [],
//       dayStartNumber: [],
//       userdata: [],
//       position: 'right',
//       occurence :"",
//       open: false,
//       height: props.height,
//       methodSelected: "0",
//       component: "",
//       triggerFrom: "",
//       paginationPage: 1,
//       sderror: false,
//       ederror: false,
//       publishTemplate: {},
//       open_group: false,
//       hirerachy_user_level: [],
//       alertEnable: false,
//       formDisabled: false,
//       userInfo: {},
//       secret_key: 'Pkdh347ey%3Tgs',
//       loading: false,
//       invalid_user: false,
//       invalid_phone_num: false,
//       invalid_usercode: false,
//       country_code_err: false,
//       permissionsadded: [],
//       given_email: '',
//       modal:false,
//       selected_category:[],
//       checkedKeys: [],
//       treeData:[],
//       dup_treeData:[],
//       import_usr : false,
//       to_import_users :[]
//     }
//     this.toggleTab = this.toggleTab.bind(this)
//     this.toggleCustomTab = this.toggleCustomTab.bind(this)
//     this.handleSelectGroup = this.handleSelectGroup.bind(this)
//     this.handleMStartGroupChange = this.handleMStartGroupChange.bind(this)
//     this.handleMEndGroupChange = this.handleMEndGroupChange.bind(this)
//     this.getDateFormateMethod = this.getDateFormateMethod.bind(this)
//     this.closeDrawer = this.closeDrawer.bind(this);
//     this.onDrawerClose = this.onDrawerClose.bind(this);
//     this.onCancelDrawerClose = this.onCancelDrawerClose.bind(this)
//     this.setPosition = this.setPosition.bind(this);
//     this.endpointMethod = this.endpointMethod.bind(this);

//   }

//   closeDrawer() {
//     this.setState({ open: false });
//   }

//   onDrawerClose() {
//     this.setState({ auditUserlevelSelected: false, component: "" })
//     this.setState({ open: false });
//     try {
//       urlSocket.post("webphlbconf/gettemplate", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//         },
//         publishtemplateInfo: this.state.publishTemplate
//       })
//         .then(response => {
//           if (response.data.response_code === 500) {
//             this.setState({
//               publishTemplate: response.data.data,
//               endpoints: response.data.data.endpoints,
//               auditUserlevelSelected: true,
//             }, function () {
//               this.updateCCLevel()
//             })

//           }
//         })
//     } catch (error) {

//     }

//   }

//   onCancelDrawerClose() {
//     this.setState({ open: false, component: "" });
//   }

//   setPosition(e) {
//     this.setState({ position: e.target.value });
//   }

//   getDidmountFunction() {
//     var data = JSON.parse(sessionStorage.getItem("authUser"));
//     var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//     var publishTemplate = JSON.parse(sessionStorage.getItem("publishData"));
//     //.log(publishTemplate, 'publishTemplate')

//     this.setState(
//       {
//         userInfo: data.user_data,
//         client_info: data.client_info[0],
//         configData: data.config_data,
//         db_info: db_info,
//         repeatModeData: data.config_data.repeat_mode,
//         dataloaded: false,
//         publishTemplate,
//         height: window.innerHeight,
//         dayStartNumber: this.generateNumber(1),
//         dayEndNumber: this.generateNumber(2),
//         hlevelIsSelected: false,
//       },
//       async function () {
//         await this.getTemplateMethod()
//         this.getDateFormateMethod()
//         this.loadUserLabels()
//       }
//     )




//   }

//   getEndpointsData = () => {
//     try {
//       urlSocket.post("webphlbconf/onchangeaudituserlevel", {
//         userInfo: {
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//           encrypted_db_url: this.state.db_info.encrypted_db_url
//         },
//         hInfo: {
//           h_id: this.state.hirerachyData._id,
//           publishtemplateInfo: this.state.publishTemplate
//         }
//       })
//         .then(response => {
//           console.log(response,'response')
//           //.log(response, 'response')
//           this.setState({ publishTemplate: response.data.data }, () => { this.updatePublishTemplateData() })
//         })
//     }
//     catch (error) {

//     }
//   }

//   async componentDidMount() {

//     this.getDidmountFunction()

//     var data = JSON.parse(sessionStorage.getItem("authUser"));
//     var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
//     var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//     // var hirerachy_user_level = data.config_data.hirerachy_user_level
//     // var hirerachy_user_level = await this.retriveExistCategory()
//     // //.log(hirerachy_user_level,'hirerachy_user_level')
//     data.config_data.facilities.map((ele, idx) => {
//       if (ele.id === "7") {
//         data.config_data.facilities[idx].enabled = true
//         this.setState({ permissionsadded: [data.config_data.facilities[idx]] }, () => {
//         })
//       }
//     })
//     var protect_routes = await facility(data.facility_data, 'mngpblhtempt')
//     if (protect_routes !== undefined) {
//       this.setState(
//         {
//           sessionUserInfo: data.user_data,
//           configData: data.config_data,
//           dataloaded: true,
//           db_info: db_info,
//           client_info: client_info,
//           // hirerachy_user_level

//         },
//         function () {
//           // this.retriveExistCategory()
//           //    this.getEntityDetaitedData()
//         }
//       )
//     }
//     else {
//       this.props.history.push('/dashboard')
//     }
//   }

//    retriveExistCategory = (hierarchy_id) => {
//     return new Promise((resolve, reject) => {
//       const db_info = JSON.parse(sessionStorage.getItem("db_info"));
  
//       try {
//         urlSocket.post('cog/retrive-categories', {
//           encrypted_db_url: db_info.encrypted_db_url,
//           hierarchy_id :hierarchy_id
//         }).then((response) => {
//           console.log(response,'response')
//           //.log(response, 'response')
//           if (response.data.response_code === 500) {
//             this.setState({hirerachy_user_level : response.data.data[0].hirerachy_user_level})
//             // resolve(response.data.data[0].hirerachy_user_level);
//           } else {
//             // resolve(null);
//           }
//         }).catch((error) => {
//           //.error(error, 'error');
//           reject(error);
//         });
//       } catch (error) {
//         //.error(error, 'error');
//         reject(error);
//       }
//     });
//   }
  

//   generateNumber(startNumber) {
//     var genNumber = []
//     for (var i = startNumber; i <= 31; i++) {
//       genNumber.push({
//         name: String(i),
//         value: String(i)
//       })
//     }
//     return genNumber
//   }

//   async endpointMethod(method) {
//     var publishTemplate = {...this.state.publishTemplate}
//     publishTemplate.method_selected = method
//     console.log(publishTemplate,method,'publishTemplate')
//     this.setState({
//       methodSelected: method,
//       publishTemplate: publishTemplate,
//       dataloaded: false
//     },async function () {
//       if (method === "2") {
//         this.gethlevelMethod()
//       }
//       else {
//         this.getCustomMethod()
//       }
//     await this.updatePublishTemplateData()

//     })
//   }

//   resetEndpointMethod() {

//     urlSocket.post("webphlbconf/resettemplate", {
//       userInfo: {
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//       },
//       publishtemplateInfo: this.state.publishTemplate
//     })
//       .then(response => {
//         if (response.data.response_code == 500) {
//           this.setState({
//             publishTemplate: response.data.data,
//             changeMethodConfirmation: false,
//             methodSelected: response.data.data.method_selected,
//             cc_level: response.data.data.cc_level,
//             dataloaded: response.data.data.method_selected === "0" ? true : false
//           })
//         }
//       })

//   }

//   async getTemplateMethod() {
//     urlSocket.post("webphlbconf/gettemplate", {
//       userInfo: {
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//       },
//       publishtemplateInfo: this.state.publishTemplate
//     })
//       .then(response => {
//         console.log(response,'response')
//         //.log(response,'response')
//         if (response.data.response_code == 500) {

//           this.setState({
//             publishTemplate: response.data.data,
//             methodSelected: response.data.data.method_selected,
//             cc_level: response.data.data.cc_level,
//             dataloaded: response.data.data.method_selected === "0" ? true : false
//           },
//             function () {
//               if(response.data.data.hlevel_id !== undefined && response.data.data.hlevel_id !== null){
//                 this.retriveExistCategory(response.data.data.hlevel_id)
//               }
//               if (response.data.data.method_selected === "2") {
//                 this.gethlevelMethod()
//               }
//               else if (response.data.data.method_selected === "1") {
//                 this.getCustomMethod()
//               }
              
//             }
//           )

//         }
//       })
//   }


//   loadUserLabels() {
//     try {
//       urlSocket.post("userSettings/get-user-group", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           _id: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id
//         }
//       })
//         .then(response => {
//           this.setState({
//             labelData: response.data.data,

//           })
//         })

//     } catch (error) {
//     }
//   }








//   getCustomMethod() {
//     urlSocket.post("webphlbconf/gethlmethod", {
//       userInfo: {
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//       },
//       publishtemplateInfo: this.state.publishTemplate
//     })
//       .then(response => {
//         if (response.data.response_code == 500) {

//           var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })
//           var wkdays = response.data.data.data.repeat_mode_config

//           var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]
//           var getHour = getTimeFromEvent.slice(0, 2)
//           var getMinTime = String(parseInt(getHour + 1)) + "00"

//           this.setState({
//             publishTemplate: response.data.data.data,
//             cc_level: response.data.data.data.cc_level,
//             dataSource: response.data.data.hlevelData,
//             auditUserlevelSelected: true,
//             customEnableReview: response.data.data.data.enable_review,
//             reviewUserlevelSelected: false,
//             endpoints: response.data.data.data.endpoints,
//             ptl_startdate: response.data.data.data.start_date,
//             ptl_enddate: response.data.data.data.end_date,
//             repeat_mode: response.data.data.data.repeat_mode,
//             rm_config: response.data.data.data.repeat_mode_config,
//             daily_endtime: response.data.data.data.repeat_mode_config.end_time,
//             daily_starttime: response.data.data.data.repeat_mode_config.start_time,
//             getMinTime,
//             selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',
//             weekDays: response.data.data.data.repeat_mode_config.days,
//             dsf: wkdays.start_on,
//             eoem: wkdays.ends_on,
//             mstartoption: wkdays.mstartoption,
//             mendoption: wkdays.mendoption,
//             dayStartNumber: this.generateNumber(1),
//             dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
//             dataloaded: false,
//           }, function () {
//             this.getAllUsers()
//           })
//         }
//       })
//   }

//   async gethlevelMethod() {
//     urlSocket.post("webphlbconf/gethlmethod", {
//       userInfo: {
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//       },
//       publishtemplateInfo: this.state.publishTemplate
//     })
//       .then(response => {
//         //.log(response, 'response')
//         console.log(response,'response')
//         if (response.data.response_code == 500) {

//           var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })
//           var wkdays = response.data.data.data.repeat_mode_config

//           var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]
//           var getHour = getTimeFromEvent.slice(0, 2)
//           var getMinTime = String(parseInt(getHour + 1)) + "00"
//           //.log(getSelectedHlevelIndex,'getSelectedHlevelIndex')
//           if(getSelectedHlevelIndex == -1){
//             response.data.data.data["hlevel_id"]=null
//             response.data.data.data["hlevel"]=null
//             response.data.data.data["hirearchy_type"]=null
//             response.data.data.data["ep_selected"]=[]
//             response.data.data.data["cc_level"]=0



//             // this.state.publishTemplate.hirearchy_type

//           }
//           _.each(response.data.data.data.endpoints, item => {
//                   if (response.data.data.data.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                     item["rowValid"] = false
//                   }
//                   else if (!response.data.data.data.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//                     item["rowValid"] = false
//                   }
//                   else {
//                     item["rowValid"] = true
//                   }
//                 })


//           if (response.data.data.data.hlevel != null) {


//             var getUniqueRecords = _.uniqBy(response.data.data.endpointData, el => `${el.hlevel}`)
//             var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.ep_level })
//             var getAuditUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.audit_userlevel })
//             var getReviewUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.review_userlevel })
//             var convertedFlatData
//             if (response.data.data.data.ep_structure.length === 0) {
//               console.log("if")
//               convertedFlatData = this.convertFlatDataToTreeData(response.data.data.endpointData)
//             }
//             else {
//               console.log("else",response,response.data.data.data.ep_structure)
//               convertedFlatData = response.data.data.data.ep_structure

//               if (response.data.data.data.hirearchy_type == "2" && response.data.data.data.ep_structure.length !==0) {
//                 this.getAllTreeStructure()
//               }
//               else {
//                 this.handleRecursive(convertedFlatData)
//                 //.log(convertedFlatData, 'convertedFlatData')

//               }
//             }
//             console.log(response.data.data.hstructure,'response.data.data.hstructure')
//             sessionStorage.setItem("hstructure", JSON.stringify(response.data.data.hstructure));
//             this.setState({
//               tree_flat:response.data.data.hstructure,
//               publishTemplate: response.data.data.data,
//               cc_level: response.data.data.data.cc_level,
//               dataSource: response.data.data.hlevelData,
//               selectedHLevelValue: String(getSelectedHlevelIndex + 1),
//               hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,
//               hlevelData: getUniqueRecords,
//               hirerachyData: response.data.data.hlevelData[getSelectedHlevelIndex],
//               selectedEndpoint: endpointIndex != -1 ? String(endpointIndex + 1) : "choose",
//               endpointSelected: endpointIndex != -1 ? true : false,
//               hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',
//               locationData: convertedFlatData,
//               checked: response.data.data.data.ep_selected,
//               checkedKeys: response.data.data.data.ep_selected,
//               expanded: response.data.data.data.ep_expanded,
//               selectedAuditUserlevel: getAuditUserlevelIndex != -1 ? String(getAuditUserlevelIndex + 1) : "choose",
//               audit_userlevel: response.data.data.data.audit_userlevel,
//               review_userlevel: response.data.data.data.review_userlevel,
//               auditUserlevelSelected: getAuditUserlevelIndex != -1 ? true : false,
//               enableReview: response.data.data.data.enable_review,
//               selectedReviewUserlevel: getReviewUserlevelIndex != -1 ? String(getReviewUserlevelIndex + 1) : "choose",
//               reviewUserlevelSelected: getReviewUserlevelIndex != -1 ? true : false,
//               endpoints: response.data.data.data.endpoints,
//               ptl_startdate: response.data.data.data.start_date,
//               ptl_enddate: response.data.data.data.end_date,
//               repeat_mode: response.data.data.data.repeat_mode,
//               rm_config: response.data.data.data.repeat_mode_config,
//               selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',
//               daily_endtime: response.data.data.data.repeat_mode_config.end_time,
//               daily_starttime: response.data.data.data.repeat_mode_config.start_time,
//               getMinTime,
//               weekDays: response.data.data.data.repeat_mode_config.days,
//               dsf: wkdays.start_on,
//               eoem: wkdays.ends_on,
//               mstartoption: wkdays.mstartoption,
//               mendoption: wkdays.mendoption,
//               dayStartNumber: this.generateNumber(1),
//               dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
//               // dataloaded: false,
//             }, function () {
//               this.getAllUsers()
//             })
//           }
//           else {
//             this.setState({
//               publishTemplate: response.data.data.data,
//               cc_level: response.data.data.data.cc_level,
//               dataSource: response.data.data.hlevelData,
//               selectedHLevelValue: getSelectedHlevelIndex != -1 ? String(getSelectedHlevelIndex + 1) : "0",
//               hlevelIsSelected: false,
//               hlevelData: [],
//               selectedEndpoint: "choose",
//               endpointSelected: false,
//               hlevelSelected: '',
//               locationData: [],
//               selectedAuditUserlevel: 'choose',
//               audit_userlevel: response.data.data.data.audit_userlevel,
//               review_userlevel: response.data.data.data.review_userlevel,
//               auditUserlevelSelected: false,
//               enableReview: false,
//               selectedReviewUserlevel: 'choose',
//               reviewUserlevelSelected: false,
//               endpoints: response.data.data.data.endpoints,
//               ptl_startdate: response.data.data.data.start_date,
//               ptl_enddate: response.data.data.data.end_date,
//               repeat_mode: null,
//               rm_config: response.data.data.data.repeat_mode_config,
//               daily_endtime: response.data.data.data.repeat_mode_config.end_time,
//               daily_starttime: response.data.data.data.repeat_mode_config.start_time,
//               getMinTime,
//               selectedRepeatMode: 'choose',
//               weekDays: response.data.data.data.repeat_mode_config.days,
//               dsf: wkdays.start_on,
//               eoem: wkdays.ends_on,
//               mstartoption: wkdays.mstartoption,
//               mendoption: wkdays.mendoption,
//               dayStartNumber: this.generateNumber(1),
//               dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
//               // dataloaded: false,
//             }, function () {
//               this.getAllUsers()
//             })
//           }
//         }
//       })
//   }



//   handleRecursive = (epTreedata) => {
//     return epTreedata.map((item) => {
//       if (item.children !== undefined && item.children !== null) {
//         item["ep"] = true;
//         item.children = this.handleRecursive(item.children);
//       }
//       return item;
//     }).filter((item) => {
//       // Filter out elements with null children and ep_level not matching
//       return item.children !== null || this.state.publishTemplate.ep_level === item.ep_level;
//     });
//   }

//   async getAllUsers() {
//     try {
//       urlSocket.post("webphlbconf/getallusers", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//         },
//         publishtemplateInfo: this.state.publishTemplate
//       })
//         .then((response) => {
//           this.setState({
//             userdata: response.data.data,
//             dataloaded: true
//           })
//         })
//     } catch (error) {
//     }
//   }


//   getHStructureListData = () => {
//     try {

//       urlSocket.post("webphlbconf/gethirhylevels", {
//         userInfo: {
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//         },
//         publishtemplateInfo: this.state.publishTemplate
//       })
//         .then((response) => {
//           var getSelectedHlevelIndex = _.findIndex(response.data.data, { hname: this.state.publishTemplate.hlevel })
//          //.log(getSelectedHlevelIndex,'getSelectedHlevelIndex')
//           this.setState({
//             dataSource: response.data.data,
//             selectedHLevelValue: String(getSelectedHlevelIndex + 1),
//             hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,
//             dataloaded: true,
//           })
//           if (getSelectedHlevelIndex !== -1) {
//           }
//         })
//     } catch (error) {
//     }
//   }

//   toggleTab(tab) {
//     if (this.state.activeTab !== tab) {
//       if (tab === 2) {
//         this.setState({ auditUserlevelSelected: false })
//         setTimeout(() => {
//           this.setState({ auditUserlevelSelected: true })
//         }, 10)
//       }
//       if (tab >= 1 && tab <= 4) {
//         this.setState({
//           activeTab: tab,
//         })
//       }
//     }
//   }

//   toggleCustomTab(tab) {
//     if (this.state.activeCustomTab !== tab) {

//       if (tab >= 1 && tab <= 4) {
//         this.setState({
//           activeCustomTab: tab,
//         })
//       }
//     }
//   }

//   handleSelectGroup = selectedGroup => {
//     this.setState({ selectedGroup })
//   }

//   selectLevel = (event) => {

//     var hirerachyData = this.state.dataSource[parseInt(event.target.value) - 1] //JSON.parse(event.target.value)
//     var publishTemplate = {...this.state.publishTemplate}
//     publishTemplate.hlevel = hirerachyData.hname
//     publishTemplate.hlevel_id = hirerachyData._id
//     publishTemplate.ep_level = null
//     publishTemplate.ep_structure = []
//     publishTemplate.eplevel_value = null
//     publishTemplate.ep_selected = []
//     publishTemplate.ep_expanded = []
//     publishTemplate.endpoints = []
//     publishTemplate.audit_userlevel = null
//     publishTemplate.audit_userlevel_value = null
//     publishTemplate.enable_review = false
//     publishTemplate.review_userlevel = null
//     publishTemplate.review_userlevel_value = null
//     publishTemplate.start_date = null
//     publishTemplate.end_date = null
//     publishTemplate.frequency = "One time"
//     publishTemplate.hirearchy_type = null

//     this.setState({
//       publishTemplate:publishTemplate,
//       checked: [],
//       selectedAuditUserlevel: 'choose',
//       auditUserlevelSelected: false,
//       checkedKeys: []
//     })

//     if (this.state.endpointSelected) document.getElementById("location").value = "choose"

//     this.updateCCLevel()
//     try {
//       urlSocket.post("webphlbconf/getlocationlevels", {
//         userInfo: {
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//           encrypted_db_url: this.state.db_info.encrypted_db_url
//         },
//         hInfo: {
//           h_id: hirerachyData._id,
//           publishtemplateInfo: publishTemplate
//         }
//       })
//         .then(response => {
//           if (response.data.response_code == 500) {
//             var getUniqueRecords = _.uniqBy(response.data.data, el => `${el.hlevel}`)
//             var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: this.state.publishTemplate.ep_level })
//             this.setState({
//               cc_level: 0,
//               hlevelData: getUniqueRecords,
//               hirerachyData,
//               selectedEndpoint: 'choose',
//               endpointSelected: false,
//               hlevelIsSelected: true,
//               hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',
//               ptl_startdate: null,
//               ptl_enddate: null,
//               ptl_frequency: "One time",
//             },()=>{this.retriveExistCategory(hirerachyData._id);this.gethlevelMethod()})
//           }
//         })
//     } catch (error) {
//     }

//   }


//   selectLocation(event) {

//     var endpointInfo = this.state.hlevelData[parseInt(event.target.value) - 1]
//     var publishTemplate = {...this.state.publishTemplate}

//     publishTemplate.ep_level = endpointInfo.hlevel
//     publishTemplate.eplevel_value = endpointInfo.hlevel_value
//     publishTemplate.ep_structure = []
//     publishTemplate.ep_selected = []
//     publishTemplate.ep_expanded = []
//     publishTemplate.audit_userlevel = null
//     publishTemplate.audit_userlevel_value = null
//     publishTemplate.enable_review = false
//     publishTemplate.review_userlevel = null
//     publishTemplate.review_userlevel_value = null
//     publishTemplate.start_date = null
//     publishTemplate.end_date = null
//     publishTemplate.frequency = "One time"


//     this.setState({ checked: [], expanded: [], selectedEndpoint: event.target.value, checkedKeys: [],publishTemplate:publishTemplate })
//     this.updateCCLevel()
//     urlSocket.post("webphlbconf/getlocations", {
//       userInfo: {
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//         encrypted_db_url: this.state.db_info.encrypted_db_url
//       },
//       hInfo: {
//         h_id: this.state.hirerachyData._id,
//         hlevelinfo: endpointInfo,
//         publishtemplateInfo: publishTemplate
//       }
//     })
//       .then(response => {
//         console.log(response,'response')
//         if (response.data.response_code == 500) {
//           var convertedFlatData = this.convertFlatDataToTreeData(response.data.data)
//           publishTemplate.ep_structure = convertedFlatData
//           this.handleRecursive(convertedFlatData)
//           // this.setState({
//           //   locationData: convertedFlatData,
//           // },
//           //   function () {
//           //     this.updateCheckedepStructure()
//           //   }
//           setTimeout(()=>{
//             this.setState({locationData: convertedFlatData,publishTemplate:publishTemplate},()=>(this.updateCheckedepStructure()))
//           },100);
        
//         }
//       })

//     this.setState({
//       endpointSelected: true,
//       hlevelSelected:
//         this.state.hlevelData[parseInt(event.target.value) - 1].hlevel,
//       selectedAuditUserlevel: 'choose',
//       auditUserlevelSelected: false,
//       enableReview: false,
//       selectedReviewUserlevel: 'choose',
//       reviewUserlevelSelected: false,
//       cc_level: 0,
//       ptl_startdate: null,
//       ptl_enddate: null,
//       ptl_frequency: "One time",
//     })
//   }

//   convertFlatDataToTreeData = (arr) => {

//     var tree = [],
//       mappedArr = {},
//       arrElem,
//       mappedElem;

//     // First map the nodes of the array to an object -> create a hash table.
//     for (var i = 0, len = arr.length; i < len; i++) {
//       arrElem = {
//         "value": arr[i]._id,
//         "label": arr[i].hlevel_name,
//         "children": arr[i].children,
//         "id": arr[i].id,
//         "parent": arr[i].parent,
//         "node_id": arr[i]._id,
//         "ep_level": arr[i].hlevel,
//         "ep_level_value": arr[i].hlevel_value,
//         "user_path": arr[i].user_path,
//         "unique_users": arr[i].unique_users

//       }
//       mappedArr[arrElem.id] = arrElem;
//       mappedArr[arrElem.id]['children'] = []
//     }


//     for (var id in mappedArr) {
//       if (mappedArr.hasOwnProperty(id)) {
//         mappedElem = mappedArr[id];

//         // If the element is not at the root level, add it to its parent array of children.
//         if (mappedElem.parent) {
//           // //.log(mappedElem,'mappedElem',mappedArr,mappedArr[mappedElem['parent']])
//           if (mappedArr[mappedElem['parent']] !== undefined) {
//             mappedArr[mappedElem['parent']]['children'].push(mappedElem);
//           }
//         }
//         // If the element is at the root level, add it to first level elements array.
//         else {
//           tree.push(mappedElem);
//         }
//       }
//     }

//     let update = () => obj => {
//       if (obj.children.length > 0)
//         obj.children.forEach(update());
//       else
//         obj.children = null
//     };

//     tree.forEach(update());
//     return tree;
//   }

//   handleEndpointSelection = (checked, event) => {
//     var publishTemplate ={...this.state.publishTemplate}
//     console.log(checked,'checked',publishTemplate)

//     this.setState({ checkedKeys: checked, cc_level: checked.length > 0 ? 1 : 0 }, async function () {
//       publishTemplate.ep_structure = this.state.locationData
//     publishTemplate.ep_selected = checked
//     publishTemplate["cc_level"] = checked.length > 0 ? 1 : 0
//     this.setState({publishTemplate : publishTemplate})
//       this.updateCheckedepStructure("allow")
//     });
//   }

//   handleEndpointExpanded = expanded => {
//     var publishTemplate ={...this.state.publishTemplate}
//     this.setState({ expanded }, function () {
//       publishTemplate.ep_structure = this.state.locationData
//       publishTemplate.ep_expanded = expanded
//       this.setState({publishTemplate : publishTemplate})
//       this.updateCheckedepStructure()
//     })
//   }

//   updateCheckedepStructure(access) {
//     this.updateCCLevel()
//     try {
//       urlSocket.post("webphlbconf/updateepstructure", {
//         userInfo: {
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//           encrypted_db_url: this.state.db_info.encrypted_db_url
//         },
//         hInfo: {
//           publishtemplateInfo: this.state.publishTemplate
//         }
//       })
//         .then(response => {
//           console.log(response,'response')
//           //.log(response,'response')
//           if (access == "allow" && this.state.publishTemplate.hirearchy_type !== "2") {
//             this.getEndpointsData()
//           }
//           // else {
//           //   console.log('elseeeee',this.state.dataSource, this.state.selectedHLevelValue)
//           //   var getSelectedHlevelIndex = _.findIndex(this.state.dataSource, { hname: this.state.publishTemplate.hlevel })
//           //   this.setState({locationData: this.state.locationData, dataloaded: false , selectedHLevelValue : String(getSelectedHlevelIndex +1)},()=>{this.updateCCLevel()})
            
//           // }
//           else {
//             this.updateCCLevel()
//             this.updatePublishTemplateData()
//             var getSelectedHlevelIndex = _.findIndex(this.state.dataSource, { hname: this.state.publishTemplate.hlevel })
//             this.setState({locationData:this.state.locationData,dataloaded:false ,selectedHLevelValue : String(getSelectedHlevelIndex +1)},()=>{this.updateCCLevel()})
//           }
//         })
//     } catch (error) {

//     }
//   }

//   selectAuditUserlevel(event) {
//     var endpoints = this.state.publishTemplate.endpoints
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.start_date = null
//     publishTemplate.end_date = null
//     publishTemplate.frequency = "One time"

//     var selected_audit_type = event.target.value
//     if (publishTemplate.hirearchy_type !== "2") {
//       if (publishTemplate.review_userlevel !== event.target.value) {
//         publishTemplate.audit_userlevel = event.target.value
//         publishTemplate.audit_userlevel_value = Number(event.target.value)
//         this.setState({
//           publishTemplate:publishTemplate,
//           selectedAuditUserlevel: event.target.value,
//           audit_userlevel: event.target.value == "1" ? "Auditor" : event.target.value == "2" ? "Reviewer" : event.target.value == "3" ? "External Auditer" : null,
//         },
//         )

//         try {
//           urlSocket.post("webphlbconf/onchangeaudituserlevel", {
//             userInfo: {
//               created_by: this.state.userInfo._id,
//               company_id: this.state.userInfo.company_id,
//               encrypted_db_url: this.state.db_info.encrypted_db_url
//             },
//             hInfo: {
//               h_id: this.state.hirerachyData._id,
//               publishtemplateInfo: publishTemplate,
//               selected_audit_type: event.target.value
//             }
//           })
//             .then(response => {
//               //.log(response,'response')
//               if (response.data.response_code === 500) {
//                 // _.each(response.data.data.endpoints, item => {
//                 //   if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                 //     item["rowValid"] = false
//                 //   }
//                 //   else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//                 //     item["rowValid"] = false
//                 //   }
//                 //   else {
//                 //     item["rowValid"] = true
//                 //   }
//                 // })
//                 publishTemplate.endpoints = response.data.data.endpoints
//                 this.setState({
//                   endpoints: response.data.data.endpoints, endpointsAvailable: true,
//                   publishTemplate: response.data.data,
//                   enableReview: response.data.data.enable_review,
//                   // selectedReviewUserlevel: 'choose',
//                   auditUserlevelSelected: true,
//                   // cc_level : 
//                   // reviewUserlevelSelected: false,
//                   cc_level: response.data.data.cc_level,
//                   ptl_startdate: null,
//                   ptl_enddate: null,
//                   ptl_frequency: "One time",
//                   auditUserlevelSelected: true,
//                 }, () => { this.updatePublishTemplateData() })
//               }
//             })
//         } catch (error) {
//         }
//       }
//       else {
//         publishTemplate["audit_userlevel"] = this.state.publishTemplate["audit_userlevel"]
//         publishTemplate["audit_userlevel_value"] = this.state.publishTemplate["audit_userlevel_value"]
//         this.setState({ dataAlert: true, alertMessage: "This user is already selected to Reviewe. Try different user." })
//       }
//     }
//     else {
//       if (this.state.publishTemplate.review_userlevel !== event.target.value) {
//         publishTemplate.audit_userlevel = event.target.value
//         publishTemplate.audit_userlevel_value = Number(event.target.value)
//         this.setState({
//           selectedAuditUserlevel: event.target.value,
//           audit_userlevel: event.target.value == "1" ? "Auditer" : event.target.value == "2" ? "Reviewer" : event.target.value == "3" ? "External Auditer" : null,
//           auditUserlevelSelected: false,
//           publishTemplate: publishTemplate,
//           endpoints: publishTemplate.endpoints
//         },
//         )
//         urlSocket.post('webphlbconf/update-unorganised-hierarchy-data-auditor', {
//           userInfo: {
//             created_by: this.state.userInfo._id,
//             company_id: this.state.userInfo.company_id,
//             encrypted_db_url: this.state.db_info.encrypted_db_url
//           },
//           hInfo: {
//             h_id: this.state.hirerachyData._id,
//             publishtemplateInfo: publishTemplate,
//             selected_audit_type: event.target.value
//           }
//         }).then((response) => {
//           if (response.data.response_code == 500) {
//             _.each(response.data.data.publishtemplateInfo.endpoints, item => {
//               if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                 item["rowValid"] = false
//               }
//               else if (!publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//                 item["rowValid"] = false
//               }
//               else {
//                 item["rowValid"] = true
//               }
//             })
//             this.setState({
//               endpoints: response.data.data.publishtemplateInfo.endpoints,
//               endpointsAvailable: true,
//               publishTemplate: response.data.data.publishtemplateInfo,
//               ptl_startdate: null,
//               ptl_enddate: null,
//               ptl_frequency: "One time",
//               auditUserlevelSelected: true,
//             }, () => { this.updatePublishTemplateData() })
//           }
//         })
//       }

//       else {
//         publishTemplate["audit_userlevel"] = this.state.publishTemplate["audit_userlevel"]
//         publishTemplate["audit_userlevel_value"] = this.state.publishTemplate["audit_userlevel_value"]
//         this.setState({ dataAlert: true, alertMessage: "This user is already selected to Reviewe. Try different user." })
//       }

//     }

//   }

//   onchangeCustomEnableReview = (e) => {
//     this.setState({ auditUserlevelSelected: false })
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.enable_review = e.target.checked
//     var checkedData = e.target.checked
//     _.each(publishTemplate.endpoints, item => {
//       if (checkedData && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//         item["rowValid"] = false
//       }
//       else if (!checkedData && !_.some(item.adt_users, { audit_type: "1" })) {
//         item["rowValid"] = false
//       }
//     })

//     setTimeout(() => {
//       this.setState({
//         customEnableReview: checkedData,
//         auditUserlevelSelected: true,
//         publishTemplate:publishTemplate
//       },()=>{
//         this.updatePublishTemplateData()
//       })
//     }, 10)

   

//   }


//   onchangeEnableReview(e) {
//     this.setState({ auditUserlevelSelected: false })
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.enable_review = e.target.checked
//     publishTemplate.review_userlevel = null
//     publishTemplate.review_userlevel_value = null
//     publishTemplate.start_date = null
//     publishTemplate.end_date = null
//     publishTemplate.frequency = "One time"

//     var checkedData = e.target.checked
//     _.each(publishTemplate.endpoints, item => {

//       if (checkedData && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//         item["rowValid"] = false
//       }
//       else if (!checkedData && !_.some(item.adt_users, { audit_type: "1" })) {
//         // //.log(item,'item')
//         item["rowValid"] = false
//       }
//       else {
//         item["rowValid"] = true
//       }
//     })

//     setTimeout(() => {
//       this.setState({
//         enableReview: checkedData,
//         reviewUserlevelSelected: false,
//         selectedReviewUserlevel: 'choose',
//         ptl_startdate: null,
//         ptl_enddate: null,
//         ptl_frequency: "One time",
//         review_userlevel: null,
//         auditUserlevelSelected: true,
//         publishTemplate:publishTemplate
//       })
//     }, 10)
//     if(checkedData == true){
//       this.defaultSelectReviewUser("default")
//     }
//     else{
//       this.state.publishTemplate.endpoints.map((data,idx)=>{
//         // if()
//         var filtered_auditor_data = _.filter(data.adt_users, { audit_type: "1" })
//         publishTemplate["endpoints"][idx]["adt_users"]=filtered_auditor_data
//       })
//       //.log(this.state.publishTemplate,'publish')
//     } 

//     this.updatePublishTemplateData()
//   }



//   defaultSelectReviewUser =(eventValue)=>{
//     this.setState({ auditUserlevelSelected: false })
//     var publishTemplate = { ...this.state.publishTemplate }
//     if (eventValue == "default") {
//       publishTemplate.review_userlevel = "2"
//       publishTemplate.review_userlevel_value = 2
//       publishTemplate.start_date = null
//       publishTemplate.end_date = null
//       // this.state.publishTemplate.repeat_mode = null
//       try {
//         urlSocket.post("webphlbconf/onchangereviewuserlevel", {
//           userInfo: {
//             created_by: this.state.userInfo._id,
//             company_id: this.state.userInfo.company_id,
//             encrypted_db_url: this.state.db_info.encrypted_db_url
//           },
//           hInfo: {
//             h_id: this.state.hirerachyData._id,
//             publishtemplateInfo:publishTemplate,
//             selected_audit_type: undefined
//           }
//         })
//           .then(response => {
//             console.log(response,'response')
//             //.log(response, 'response')
//             if (response.data.response_code === 500) {
//               if (publishTemplate.enable_review) {
//                 _.each(response.data.data.endpoints, item => {
//                   if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                     item["rowValid"] = false
//                   }
//                   else {
//                     item["rowValid"] = true

//                   }
//                 })
//               }
//               this.setState({
//                 publishTemplate: response.data.data,
//                 selectedReviewUserlevel: eventValue,
//                 reviewUserlevelSelected: true,
//                 cc_level: response.data.data.cc_level,
//                 ptl_startdate: null,
//                 ptl_enddate: null,
//                 repeatMode: null,
//                 endpoints: response.data.data.endpoints,
//                 review_userlevel: eventValue,
//                 auditUserlevelSelected: true,
//               },()=>{this.updatePublishTemplateData()}
//               )
//             }
//           })
//       } catch (error) {
//       }
//     }



//   }






//   selectReviewUserlevel(event) {
//     this.setState({ auditUserlevelSelected: false })
//     // if (event !== "default") {
//       var eventValue = event.target.value
//       var publishTemplate = { ...this.state.publishTemplate }
//     // }
//     if (publishTemplate.hirearchy_type !== "2") {
//        if (publishTemplate.audit_userlevel !== eventValue) {
//         this.updateCCLevel()
//         publishTemplate.review_userlevel = eventValue
//         publishTemplate.review_userlevel_value = Number(event.target.value)
//         publishTemplate.start_date = null
//         publishTemplate.end_date = null
//         publishTemplate.repeat_mode = null
//         try {
//           urlSocket.post("webphlbconf/onchangereviewuserlevel", {
//             userInfo: {
//               created_by: this.state.userInfo._id,
//               company_id: this.state.userInfo.company_id,
//               encrypted_db_url: this.state.db_info.encrypted_db_url
//             },
//             hInfo: {
//               h_id: this.state.hirerachyData._id,
//               publishtemplateInfo: publishTemplate,
//               selected_audit_type: eventValue
//             }
//           })
//             .then(response => {
//               this.updateCCLevel()
//               if (response.data.response_code === 500) {
//                 if (publishTemplate.enable_review) {
//                   _.each(response.data.data.endpoints, item => {
//                     if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                       item["rowValid"] = false
//                     }
//                     else {
//                       item["rowValid"] = true

//                     }
//                   })
//                 }
//                 this.setState({
//                   publishTemplate: response.data.data,
//                   selectedReviewUserlevel: eventValue,
//                   reviewUserlevelSelected: true,
//                   cc_level: response.data.data.cc_level,
//                   ptl_startdate: null,
//                   ptl_enddate: null,
//                   repeatMode: null,
//                   endpoints: response.data.data.endpoints,
//                   review_userlevel: eventValue,
//                   auditUserlevelSelected: true,
//                 },
//                 )
//               }
//             })
//         } catch (error) {
//         }
//       }
//       else {
//         publishTemplate["review_userlevel"] = eventValue
//         publishTemplate["review_userlevel_value"] = this.state.publishTemplate["review_userlevel_value"]
//         this.setState({ dataAlert: true, alertMessage: "This user is already selected to auditer. Try different user." })
//       }
//     }
//     else {
//       if (publishTemplate.audit_userlevel !== event.target.value) {
//         publishTemplate.review_userlevel = event.target.value
//         publishTemplate.review_userlevel_value = Number(event.target.value)
//         try {
//           urlSocket.post('webphlbconf/update-unorganised-hierarchy-data-reviewer', {
//             userInfo: {
//               created_by: this.state.userInfo._id,
//               company_id: this.state.userInfo.company_id,
//               encrypted_db_url: this.state.db_info.encrypted_db_url
//             },
//             hInfo: {
//               h_id: this.state.hirerachyData._id,
//               publishtemplateInfo: publishTemplate,
//               selected_audit_type: event.target.value
//             }
//           }).then((response) => {
//             if (response.data.response_code == 500) {
//               _.each(response.data.data.publishtemplateInfo.endpoints, item => {
//                 if (publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                   item["rowValid"] = false
//                 }
//                 else if (!publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//                   item["rowValid"] = false
//                 }
//                 else {
//                   item["rowValid"] = true
//                 }
//               })
//               this.setState({
//                 endpoints: response.data.data.publishtemplateInfo.endpoints,
//                 endpointsAvailable: true,
//                 publishTemplate: response.data.data.publishtemplateInfo,
//                 ptl_startdate: null,
//                 ptl_enddate: null,
//                 ptl_frequency: "One time",
//                 review_userlevel: eventValue,
//                 auditUserlevelSelected: true,
//                 reviewUserlevelSelected: true,
//                 selectedReviewUserlevel: eventValue,
//               }, () => { this.updatePublishTemplateData() })
//             }


//           })

//         } catch (error) {

//         }
//       }
//       else {
//         publishTemplate["review_userlevel"] = this.state.publishTemplate["review_userlevel"]
//         publishTemplate["review_userlevel_value"] = this.state.publishTemplate["review_userlevel_value"]
//         this.setState({ dataAlert: true, alertMessage: "This user is already selected to auditer. Try different user." })
//       }


//     }


//   }








//   changeAuditUserHandler(event, rowData, idx, parameter) {
//     console.log(rowData,'rowData')
//     //.log(event.target.value, rowData, idx, parameter, 'event, rowData, idx,parameter', this.state.publishTemplate)
//     var publishTemplate = { ...this.state.publishTemplate }
//     if (parameter === "1" && event.target.value !== "add") {
//       var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
//     }
//     else {
//       var checkUserAlreadyExist = -1
//     }
//     //.log(checkUserAlreadyExist, 'checkUserAlreadyExist')
//     if (checkUserAlreadyExist !== -1 && (this.state.customEnableReview || this.state.enableReview)) {
//       this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
//     }
//     else if (event.target.value === "add" && this.state.publishTemplate.method_selected == "2") {
//       this.setState({ modal: this.state.modal ? false : true,add_reviwer :false, component: "user", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData })
//     }
//     else if (event.target.value === "add" && this.state.publishTemplate.method_selected == "1") {
//       this.setState({ open: this.state.open ? false : true,add_reviwer :false, component: "user", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData })
//     }
//     else if(event.target.value == "#import"){
//       console.log("import",rowData,this.state.userdata)
//       var filtered_users =_.differenceBy(this.state.userdata, rowData.unique_users, '_id');
//       console.log(filtered_users,'filtered_users')
//       this.setState({ open: this.state.open ? false : true,coloumn_type :"1", add_reviwer :false, component: "import_usr", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData ,to_import_users : filtered_users })
//     }
//     else if (event.target.value == "loadgroupusers") {
//       this.setState({ open: this.state.open ? false : true, component: "loadgroupusers", endpoint_data: rowData })

//     }
//     else {
//       this.setState({ auditUserlevelSelected: false })
//       var auditUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
//       var getIndex = _.findIndex(this.state.publishTemplate.endpoints, { _id: (rowData._id) })
//       var getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//       //.log(auditUser, getIndex, getUserIndex, 'getUserIndex')
//       if (getUserIndex == -1) {
//         this.state.publishTemplate.endpoints[getIndex].adt_users.push({
//           user_id: auditUser._id,
//           name: auditUser.firstname,
//           designation: auditUser.designation,
//           user_code: auditUser.usercode,
//           cat_type: this.state.publishTemplate.audit_userlevel,
//           audit_type: "1"
//         })
//         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
//         publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

//       }
//       else {
//         publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
//         publishTemplate.endpoints[getIndex].adt_users.push({
//           user_id: auditUser._id,
//           name: auditUser.firstname,
//           designation: auditUser.designation,
//           user_code: auditUser.usercode,
//           cat_type: publishTemplate.audit_userlevel,
//           audit_type: "1"
//         })
//         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
//         publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

//       }
//       //.log(this.state.publishTemplate, 'getIndex', this.state.cc_level)
//       // this.updateCCLevel()
//       this.updatePublishTemplateData()
//       setTimeout(() => {
//         this.setState({
//           endpoints: publishTemplate.endpoints,
//           auditUserlevelSelected: true,
//           publishTemplate:publishTemplate
//         })
//       }, 10)
//     }
//     this.setState({ user_type: 0 })

//   }

//   changeReviewUserHandler(event, rowData, idx, parameter) {
//     console.log(event, rowData, idx, parameter,'event, rowData, idx, parameter')
//     var publishTemplate = { ...this.state.publishTemplate }
//     if (parameter === "1") {
//       var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
//     }
//     else {
//       var checkUserAlreadyExist = -1
//     }

//     if (checkUserAlreadyExist !== -1) {
//       this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
//     }
//     else {
//       if (event.target.value === "add") {
//         this.setState({ modal: this.state.modal ? false : true, component: "user",add_reviwer:true, selected_index: idx , given_email :"" ,location_info :rowData })
//         // this.setState({ open: this.state.open ? false : true, component: "user", selected_index: idx })
//       }
//       else if(event.target.value == "#import"){
//         var filtered_users =_.differenceBy(this.state.userdata, rowData.unique_users, '_id');
//         console.log(filtered_users,'filtered_users')
//         this.setState({ open: this.state.open ? false : true,coloumn_type :"2", add_reviwer :true, component: "import_usr", emptData: rowData, selected_index: idx , given_email :"" ,location_info :rowData ,to_import_users : filtered_users })
//       }
//       else {
//         this.setState({ auditUserlevelSelected: false })

//         var reviewUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
//         var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })


//         var getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

//         if (getUserIndex == -1) {
//           publishTemplate.endpoints[getIndex].adt_users.push({
//             user_id: reviewUser._id,
//             name: reviewUser.firstname,
//             designation: reviewUser.designation,
//             user_code: reviewUser.usercode,
//             cat_type: this.state.publishTemplate.review_userlevel,
//             review_user: true,
//             audit_type: "2"
//           })
//           var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//           var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
//         }
//         else {
//           publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
//           publishTemplate.endpoints[getIndex].adt_users.push({
//             user_id: reviewUser._id,
//             name: reviewUser.firstname,
//             designation: reviewUser.designation,
//             user_code: reviewUser.usercode,
//             cat_type: this.state.publishTemplate.review_userlevel,
//             review_user: true,
//             audit_type: "2"
//           })
//           var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//           var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

//         }
//         _.each(this.state.publishTemplate.endpoints, item => {
//           // //.log(!_.some(item.adt_users, { audit_type: "1"})
//           if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//             item["rowValid"] = false
//           }
//           else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//             item["rowValid"] = false
//           }
//           else {
//             item["rowValid"] = true
//           }
//         })

//         this.updatePublishTemplateData()
//         setTimeout(() => {
//           this.setState({
//             auditUserlevelSelected: true,
//             publishTemplate:publishTemplate
//           })

//         }, 10)
//       }
//     }
//     this.setState({ user_type: 1 })
//     // this.updateCCLevel()

//   }

//   checkEndpoints = () => {
//     var checkAudituser = _.some(this.state.publishTemplate.endpoints, { audit_user: null })
//     var checkReviewuser = _.some(this.state.publishTemplate.endpoints, { review_user: null })

//     if (!this.state.customEnableReview && !checkAudituser) {
//       return true //this.setState({cc_level:2})
//     }
//     else if (!this.state.checkAudituser && (this.state.customEnableReview && !checkReviewuser)) {
//       return true //this.setState({cc_level:2})
//     }
//     else {
//       return false
//     }
//   }

//   getDateFormateMethod = () => {
//     var today = new Date()
//     const dd = today.getDate().toString().length == 1 ? "0" + today.getDate().toString() : today.getDate().toString()
//     const mm = String(today.getMonth() + 1).length == 1 ? "0" + String(today.getMonth() + 1) : today.getMonth() + 1
//     const yyyy = today.getFullYear()
//     const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
//     this.setState({ fromate_date })
//   }

//   // onChangeStartDate(event) {
//   //   this.state.publishTemplate.start_date = event.target.value
//   //   this.state.publishTemplate.end_date = null
//   //   this.setState({ ptl_startdate: event.target.value, })
//   //   this.updatePublishTemplateData()
//   // }

//   onChangeStartDate(event) {
//     // console.log('evnt', event, this.state.ptl_startdate > this.state.ptl_enddate )
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.start_date = event.target.value === '' ? null : event.target.value
//     publishTemplate.end_date = event.target.value > this.state.publishTemplate.end_date ? null : this.state.publishTemplate.end_date
//     // this.state.publishTemplate.end_date = null
//     //this.state.publishTemplate.cc_level = 3
//     if (this.state.ptl_startdate > this.state.ptl_enddate) {
//       publishTemplate.cc_level = 2
//     } else {
//       publishTemplate.cc_level = event.target.value === '' ? 2 : 3

//     }
//     console.log(publishTemplate)
//     this.setState({ ptl_startdate: event.target.value, cc_level: this.state.publishTemplate.cc_level, publishTemplate: publishTemplate },()=>{
//     this.updatePublishTemplateData()

//     })
//   }
   
//   onChangeEndDate(event) {
//     // console.log('event.target.value', event.target.value)
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.end_date = event.target.value === '' ? null : event.target.value
//     publishTemplate.cc_level = event.target.value === '' ? 2 : 3
//     this.setState({ ptl_enddate: event.target.value, cc_level: this.state.publishTemplate.cc_level, publishTemplate: publishTemplate },()=>{
//     this.updatePublishTemplateData()

//     })
//   }
     





//   // onChangeEndDate(event) {
//   //   this.state.publishTemplate.end_date = event.target.value
//   //   this.setState({ ptl_enddate: event.target.value, })
//   //   this.updatePublishTemplateData()
//   // }

//   onChangeStartTime(e) {

//     var getTimeFromEvent = e[0].toLocaleTimeString()
//     var getHour = getTimeFromEvent.slice(0, 2)
//     var getMinTime = String(parseInt(getHour + 1)) + "00"

//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.repeat_mode_config = {
//       mode: this.state.publishTemplate.repeat_mode_config.mode,
//       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
//       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//       days: this.state.publishTemplate.repeat_mode_config.days,
//       start_time: [getTimeFromEvent],
//       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
//       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

//     }


//     this.setState({ daily_starttime: e, getMinTime , publishTemplate:publishTemplate })
//   }

//   onChangeEndTime(e) {

//     var getTimeFromEvent = e[0].toLocaleTimeString()
//     var publishTemplate = { ...this.state.publishTemplate }


//     publishTemplate.repeat_mode_config = {
//       mode: this.state.publishTemplate.repeat_mode_config.mode,
//       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
//       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//       days: this.state.publishTemplate.repeat_mode_config.days,
//       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//       end_time: [getTimeFromEvent],
//       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
//       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

//     }
//     this.setState({ daily_endtime: e ,publishTemplate})
//   }

//   monthStartOn(event) {
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.repeat_mode_config = {
//       mode: this.state.publishTemplate.repeat_mode_config.mode,
//       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
//       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//       days: this.state.publishTemplate.repeat_mode_config.days,
//       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//       start_on: event.target.value,
//       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,
//     }

//     this.setState({
//       dsf: event.target.value,
//       dayEndNumber: this.generateNumber(parseInt(event.target.value) + 1),
//       publishTemplate: publishTemplate
//     })
//     this.updatePublishTemplateData()
//   }

//   monthEndsOn(event) {
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.repeat_mode_config = {
//       mode: this.state.publishTemplate.repeat_mode_config.mode,
//       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
//       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//       days: this.state.publishTemplate.repeat_mode_config.days,
//       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
//       ends_on: event.target.value,
//       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,
//     }
//     this.setState({
//       eoem: event.target.value,
//       publishTemplate:publishTemplate
//     })
//     this.updatePublishTemplateData()
//   }

//   validateEndpoints=()=>{

//     _.each(this.state.publishTemplate.endpoints, item => {
//       if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//         item["rowValid"] = false
//       }
//       else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
//         item["rowValid"] = false
//       }
//       else {
//         item["rowValid"] = true
//       }
//     })
//     console.log(this.state.publishTemplate)


//   }




//   updateCCLevel() {
//     if (this.state.publishTemplate.endpoints.length > 0) {
//           this.validateEndpoints()
//     }
//     var updateCCState = Validation.updatecclevel(this.state.methodSelected, this.state.publishTemplate)
//     console.log(updateCCState,'ccstate')
//     //.log(updateCCState,'updateCCState')
//     this.setState({component:"",open: false, publishTemplate: updateCCState, cc_level: updateCCState.cc_level,dataloaded:true })
//   }




//   async updatePublishTemplateData() {
//     this.updateCCLevel()
//     console.log(this.state.publishTemplate,'publish')
//     try {
//       urlSocket.post("webphlbconf/updatepublishtemplate", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id
//         },
//         hInfo: {
//           h_id: this.state.hirerachyData._id,
//           publishtemplateInfo: this.state.publishTemplate
//         }
//       })
//         .then(response => {
//           console.log(response,'response')
//           //.log(response,'response')
//           // response.data.data.endpoints



//           if (response.data.response_code == 500) {
//             this.setState({
//               cc_level: response.data.data.cc_level,
//               endpoints: response.data.data.endpoints,
//               publishTemplate:response.data.data,
//               dataloaded : false
//             },()=>{
//               this.setState({dataloaded : true})
//               //.log(this.state.cc_level)
//             })
//           }
//         })
//     } catch (error) {
//     }
//   }


//   selectRepeatMode(event) {
//     var repeatMode = this.state.repeatModeData[parseInt(event.target.value)]
//     console.log(repeatMode,'repeatMode',event.target.value)
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.repeat_mode = repeatMode.mode
//     publishTemplate.cc_level = 3
//     publishTemplate.repeat_mode_config = {
//       mode: repeatMode.mode,
//       mode_id: repeatMode.mode_id,
//       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//       days: this.state.publishTemplate.repeat_mode_config.days,
//       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
//       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

//     }
//     console.log(publishTemplate,'publishTemplate')
//     this.setState({
//       cc_level: 3,
//       repeat_mode: repeatMode.mode,
//       weekDays: this.state.publishTemplate.repeat_mode_config.days,
//       rm_config: repeatMode,
//       daily_starttime: this.state.publishTemplate.repeat_mode_config.start_time,
//       daily_endtime: this.state.publishTemplate.repeat_mode_config.end_time,
//       dsf: this.state.publishTemplate.repeat_mode_config.start_on,
//       eoem: this.state.publishTemplate.repeat_mode_config.ends_on,
//       selectedRepeatMode: event.target.value,
//       publishTemplate:publishTemplate
//     },()=>{
//     this.updatePublishTemplateData()

//     })
//     console.log(this.state.publishTemplate,'this.state.publishTemplate')
//   }

//   navigateTo = (data) => {
//     sessionStorage.removeItem("EditPublishedData");
//     sessionStorage.removeItem("nagiationMode");
//     sessionStorage.setItem("EditPublishedData", JSON.stringify(data));
//     sessionStorage.setItem("nagiationMode", "from_config");
//     this.props.history.push("/edtpblhtempt");
//   }

//   handleWeekDays(e) {
//     var getIndex = _.findIndex(this.state.weekDays, { day: e.target.id })
//     var weekDays =[...this.state.weekDays]
//     weekDays[getIndex]["checked"] = e.target.checked
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.repeat_mode_config = {
//       mode: this.state.publishTemplate.repeat_mode_config.mode,
//       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
//       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//       days: [...this.state.weekDays],
//       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
//       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

//     }

//     if (this.state.publishTemplate.repeat_mode === "Weekly" &&
//       _.some(this.state.publishTemplate.repeat_mode_config.days, { checked: true })) {
//       publishTemplate["cc_level"] = 3
//     }
//     else {
//       publishTemplate["cc_level"] = 2
//     }

//     this.setState({ weekDays: weekDays, publishTemplate: publishTemplate, cc_level: publishTemplate.cc_level })
//   }

//   ordinal_suffix_of(i) {
//     var j = i % 10,
//       k = i % 100;
//     if (j == 1 && k != 11) {
//       return "st";
//     }
//     if (j == 2 && k != 12) {
//       return "nd";
//     }
//     if (j == 3 && k != 13) {
//       return "rd";
//     }
//     return "th";
//   }

//   handleMStartGroupChange(event) {

//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.repeat_mode_config = {
//       mode: this.state.publishTemplate.repeat_mode_config.mode,
//       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
//       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//       days: this.state.publishTemplate.repeat_mode_config.days,
//       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//       start_on: event.target.name === "month_start" ? null : this.state.publishTemplate.repeat_mode_config.start_on,
//       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: event.target.name,
//       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,
//     }

//     this.setState({
//       mstartoption: event.target.name,
//       publishTemplate
//     })
//     this.updatePublishTemplateData()
//   }

//   handleMEndGroupChange(event) {
//     var publishTemplate = { ...this.state.publishTemplate }
//     publishTemplate.repeat_mode_config = {
//       mode: this.state.publishTemplate.repeat_mode_config.mode,
//       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
//       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//       days: this.state.publishTemplate.repeat_mode_config.days,
//       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
//       ends_on: event.target.name === "month_end" ? null : this.state.publishTemplate.repeat_mode_config.ends_on,
//       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
//       mendoption: event.target.name,
//     }
//     this.setState({
//       mendoption: event.target.name,
//       publishTemplate
//     })
//     this.updatePublishTemplateData()
//   }

//   deleteEndpoint = () => {
//     try {
//       urlSocket.post("webphlbconf/deleteendpoints", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id
//         },
//         hInfo: {
//           h_id: this.state.hirerachyData._id,
//           publishtemplateInfo: this.state.publishTemplate
//         },
//         endpointInfo: this.state.deleteItem
//       })
//         .then(response => {
//           if (response.data.response_code === 500) {

//             var publishTemplate = response.data.data


//             this.setState({
//               publishTemplate: publishTemplate,
//               endpoints: response.data.data.endpoints,
//               changeMethodConfirmation: false,
//             }, function () {
//               this.updateCCLevel()
//             })
//           }
//         })
//     } catch (error) {
//     }
//   }

//   treeDataToFlat = async () => {
//     var getNodeKey = ({ treeIndex }) => treeIndex
//     var flatData = getFlatDataFromTree(
//       {
//         treeData: this.state.publishTemplate.template_structure,
//         getNodeKey,
//         ignoreCollapsed: false,
//       })

//     var explicitData = _.filter(flatData, item => {
//       return item
//     })

//     return explicitData
//   }

//   checkPublishDate = () => {

//     var currentDate = moment(new Date()).format("YYYY-MM-DD")
//     var pbd_startDate = moment(new Date(this.state.publishTemplate.start_date)).format("YYYY-MM-DD")
//     var pbd_endDate = moment(new Date(this.state.publishTemplate.end_date)).format("YYYY-MM-DD")

//     if (moment(pbd_startDate).isBefore(currentDate)) {
//       this.setState({ ederror: false, sderror: true })
//     }

//     if (moment(pbd_endDate).isBefore(currentDate)) {
//       this.setState({ sderror: false, ederror: true })
//     }

//     if (moment(pbd_startDate).isBefore(currentDate) == false && moment(pbd_endDate).isBefore(currentDate) == false) {
//       this.setState({ sderror: false, ederror: false })
//       return true
//     }
//     else {
//       return false
//     }
//   }

//   calculateMaxDate() {
//     const { ptl_startdate } = this.state;

//     if (ptl_startdate === '' || ptl_startdate === undefined) {
//       return null; // Return null if start date is not set
//     }

//     const startDate = new Date(ptl_startdate);
//     startDate.setFullYear(startDate.getFullYear() + this.state.client_info.rept_mode_periodicity);

//     return startDate.toISOString().slice(0, 10); // Format the date as "YYYY-MM-DD"
//   }




//   publishTmpltAsAudit = async () => {

//     var checkpoints = await this.treeDataToFlat()
//     var checkDates = this.checkPublishDate()
//     var publishTemplate = { ...this.state.publishTemplate }
//     //("checkDates", checkDates)
//     //(this.state.publishTemplate,'this.state.publishTemplate')
//     if (checkDates) {
//       if(this.state.methodSelected == "2"){
//         var filter_ignored_location = _.filter(this.state.publishTemplate.endpoints, endpoint => !endpoint.disabled);
//         publishTemplate["endpoints"] = filter_ignored_location
//         //.log(filter_ignored_location,'filter_ignored_location',this.state.publishTemplate)
//       }

//       console.log(this.state.publishTemplate,'this.state.publishTemplate')
//       try {
//         urlSocket.post("webphlbprcs/publishtmpltasaudit", {
//           userInfo: {
//             encrypted_db_url: this.state.db_info.encrypted_db_url,
//             created_by: this.state.userInfo._id,
//             company_id: this.state.userInfo.company_id
//           },
//           hInfo: {
//             publishtemplateInfo: publishTemplate,
//           },
//         })
//           .then(response => {
//             //("response", response)
//             if (response.data.response_code === 500) {
//               this.setState({
//                 tmpltPublishProcess: true,
//                 alertMessage: "Audit publishing started successfully click",
//                 processStatus: "1"
//               })
//             }
//             else {
//               this.setState({
//                 tmpltPublishProcess: true,
//                 alertMessage: "Audit not publish. Please check the data",
//                 processStatus: "0"
//               })
//             }
//           })
//       } catch (error) {
//       }
//     }
//     else {
//     }

//   }

//   redirectTo(processStatus) {

//     this.setState({ tmpltPublishProcess: false })

//     if (processStatus == "1") {
//       sessionStorage.removeItem("EditPublishedData");
//       sessionStorage.removeItem("nagiationMode");
//       this.props.history.push("/mngpblhtempt");
//     }
//   }


//   deleteAllRequest() {

//     if (this.state.publishTemplate.endpoints.length === 0) {
//       this.setState({ dataAlert: true, alertMessage: "No end points to delete" })
//     }
//     else {
//       this.setState({ triggerFrom: "deleteall", changeMethodConfirmation: true })
//     }


//   }


//   select_country = (e) => {
//     this.setState({ countrycode: e.target.value ,country_code_err : false })
//   }


//   addPermissions(e, item) {

//     var getIndex = _.findIndex(this.state.permissionsadded, { id: item.id })

//     if (getIndex === -1) {
//       this.state.permissionsadded.push(item)
//       this.setState({
//         permissionsadded: this.state.permissionsadded
//       })
//     }
//     else {
//       this.state.permissionsadded.splice(getIndex, 1)
//       this.setState({
//         permissionsadded: this.state.permissionsadded
//       })
//     }


//   }



//   validate_email = (e, mode) => {
//     var admn_info = {}
//     if (mode == 1) {
//       admn_info["email_id"] = e.target.value
//     }
//     if (mode === 2) {
//       admn_info["phone_number"] = e.target.value
//     }
//     if (mode === 3) {
//       admn_info["usercode"] = e.target.value
//     }
//     admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//     admn_info["db_name"] = this.state.db_info.db_name
//     admn_info["user_id"] = this.state.userInfo._id

//     if (this.state.usercode !== undefined || admn_info["email_id"] !== undefined || admn_info["phone_number"] !== undefined) {
//       try {
//         urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
//           //.log(data,'data')
//           if (mode == 1) {
//             if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
//               this.setState({ invalid_user: true })
//             }
//             else {
//               this.setState({ invalid_user: false })
//             }
//           }
//           if (mode == 2) {

//             if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
//               this.setState({ invalid_phone_num: true })
//             }
//             else {
//               this.setState({ invalid_phone_num: false })
//             }
//           }
//           if (mode === 3) {
//             if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
//               this.setState({ invalid_usercode: true })
//             }
//             else {
//               this.setState({ invalid_usercode: false })
//             }


//           }
//         })

//       } catch (error) {

//       }
//     }

//   }


//   emai_addrs = (e) => {
//     var email = e.target.value
//     if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
//       this.setState({ given_email: email, errorMessage: false })
//     }
//     else {
//       this.setState({ errorMessage: true, given_email: email })
//     }

//   }

//   userCode = (e) => {
//     this.setState({ usercode: e.target.value })
//   }


//   loadEOPTS = () => {
//     this.setState({ tableDataloaded: false })
//     try {
//       urlSocket.post("cog/get-user-info",
//         {
//           db_info: this.state.db_info,
//           user_id: this.state.userInfo._id
//         }
//       )
//         .then((response) => {
//           this.setState({
//             userdata: response.data.user_list,
//             auditUserlevelSelected: false
//           }, function () {
//             this.loadUserLabels()
//             setTimeout(() => {
//               this.setState({ auditUserlevelSelected: true })

//             }, 50);
//           })

//         })
//     } catch (error) {

//     }
//   }

//   locationUserCrud=async (event,values)=>{
//     console.log(values,this.state.sessionUserInfo,this.state.client_info)
//     //.log(values,'values')
//     values["selected_category"]=this.state.selected_category
//     var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
//     values["password"] = password
//     values["confirmpassword"] = password
//     values["userPoolId"] = this.state.client_info.userPoolId
//     values["clientId"] = this.state.client_info.clientId
//     values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//     values["db_name"] = this.state.db_info.db_name
//     values["facilities"] = this.state.client_info.facilities
//     values["company_id"] = this.state.client_info._id
//     values["created_by"] = this.state.sessionUserInfo._id
//     values["short_name"] = this.state.client_info.short_name
//     values["user_id"] = this.state.sessionUserInfo._id
//     if (this.state.countrycode === undefined) {
//       this.setState({ country_code_err: true })
//     }
//       else {
//         values["countrycode"] = this.state.countrycode
//         this.setState({ country_code_err: false })
  
//       }
//     if (this.state.invalid_user === false && this.state.errorMessage == false && this.state.country_code_err === false) {
//            this.setState({ loading: true })
//           var hstructure =  JSON.parse(sessionStorage.getItem("hstructure"));
//            //.log(this.state.location_info,this.state.tree_flat,hstructure,'location_id',this.state.selected_category)
          
//       try {
//         urlSocket.post('cog/cruduser', values).then(async (res) => {
//           //.log(res, 'response')
//           if (res.data.response_code === 500) {
//             this.setState({ loading: false })
//             var location_info={...this.state.location_info}
//             this.state.selected_category.map((ele, idx) => {
//               if (this.state.add_reviwer == false) {
//                 var userInfo = {
//                   cat_type: ele.cat_type,
//                   designation: null,
//                   hirerachy_name: ele.hirerachy_name,
//                   name: values.firstname,
//                   title: this.state.location_info.hlevel_name,
//                   type: 2,
//                   user_id: res.data.admn_user[0]._id,
//                   _id: res.data.admn_user[0]._id

//                 }
//               }
//               if(this.state.add_reviwer == true){
//                 var userInfo = {
//                   cat_type: ele.cat_type,
//                   designation: null,
//                   hirerachy_name: ele.hirerachy_name,
//                   name: values.firstname,
//                   title: this.state.location_info.hlevel_name,
//                   type: 2,
//                   review_user :true,
//                   user_id: res.data.admn_user[0]._id,
//                   _id: res.data.admn_user[0]._id

//                 }
//               }
//               this.state.location_info.user_path.push(userInfo)
//               if (this.state.location_info["unique_users"] == undefined) {
//                 location_info["unique_users"] = []
//                 location_info["hirerachy_name"] = []
//                 location_info["unique_users"].push({ ...userInfo })
//                 location_info["unique_users"][0]["cat_type"] = [this.state.location_info["unique_users"][0]["cat_type"]]
//                 location_info["unique_users"][0]["hirerachy_name"] = [this.state.location_info["unique_users"][0]["hirerachy_name"]]
//               }
//               else {
//                 var find_exist_user = _.find(this.state.location_info.unique_users, { _id: userInfo._id })
//                 if (find_exist_user !== undefined) {
//                   find_exist_user["cat_type"].push(ele.cat_type)
//                   find_exist_user["hirerachy_name"].push(ele.hirerachy_name)
//                 }
//                 else {
//                   location_info["unique_users"].push({ ...userInfo })
//                   var find_exist_user_unique = _.findIndex(this.state.location_info.unique_users, { _id: userInfo._id })
//                   location_info["unique_users"][find_exist_user_unique]["cat_type"] = [this.state.location_info["unique_users"][find_exist_user_unique]["cat_type"]]
//                   location_info["unique_users"][find_exist_user_unique]["hirerachy_name"] = [this.state.location_info["unique_users"][find_exist_user_unique]["hirerachy_name"]]

//                 }
//               }
//             })
//             var update_flatData = await this.updateInFlatCln(this.state.location_info,hstructure)
//           }

//         })
//       } catch (error) {

//       }
//     }
//   }


//   updateInFlatCln = (flatData, hstructure) => {
//     //.log(flatData)
//     console.log(flatData, hstructure)
//     try {
//       urlSocket.post('cog/update-flat-cln', {
//         flatData: flatData,
//         encrypted_db_url: this.state.db_info.encrypted_db_url,
//       }).then((response) => {
//         //.log(response, 'response')
//         console.log(response,'response')
//         if (response.data.response_code === 500) {
//           //.log(flatData,'flatData',hstructure)
//           var path_data = hstructure.find((fcty) => fcty.title === flatData.hlevel_name && fcty.subtitle === flatData.hlevel)
//           console.log(path_data, 'path_data', hstructure)
//           //.log(path_data,'path_data')
//           if (path_data !== undefined) {
//             path_data["unique_users"] = flatData.unique_users
//             path_data["user_path"] = flatData.user_path
//             console.log(path_data, 'path_data',flatData,this.state.publishTemplate)
//             if (flatData.adt_users.length == 0) {
//               var user_info = {
//                 user_id: path_data["user_path"][0].user_id,
//                 _id: path_data["user_path"][0].user_id,
//                 name: path_data["user_path"][0].name,
//                 designation: path_data["user_path"][0].designation,
//                 cat_type: path_data["user_path"][0].cat_type,
//                 audit_type: this.state.add_reviwer ? "2" : "1",
//                 review_user: this.state.add_reviwer ? true : false
//               }
//               if (path_data.user_path.length > 0 && path_data.user_path.length < 2){
//                 // flatData["rowValid"] = true
//                 flatData["rowValid"] = true
//               flatData.adt_users.push(user_info)
//               // if(this.state.publishTemplate.endpoints.length ===0){
//               //   this.state.publishTemplate.endpoints.push(flatData)
//               // }
//             }
//             }
//             // this.updateCCLevel()
//             this.setState({
//               publishTemplate: this.state.publishTemplate
//             },()=>{this.updatePublishTemplateData();this.FlatDataToTreeData(hstructure)})
            
//             // flatData.adt_
            
//           }

//         }
//       })

//     } catch (error) {

//     }


//   }



//   FlatDataToTreeData = async (FlatData) => {
//     //.log(FlatData);
//     try {
//       const treeData = await this.getTreeFromFlatDataAsync(FlatData);
//       //.log(treeData,'treeData')
//       this.setState({
//         dup_treeData: treeData,
//         // dataloaded: true,
//       },()=>this.saveHstructure(treeData,FlatData));
//     } catch (error) {
//       //.error("Error:", error);
//     }
//   }

//   saveHstructure=async (treeData,hstructure)=>{
//     var explicitData = await this.HtreeDataToFlat(treeData)
//     //.log(explicitData,'explicitData')
//     try {
//       urlSocket.post("webhstre/savehstructure", {
//         info: {
//           _id: this.state.publishTemplate.hlevel_id,
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           company_id: this.state.publishTemplate.company_id,
//           flatData: _.map(explicitData, "node"),
//           published:true
//           // lastGeneratedId: this.state.totalHLength
//         }
//       })
//         .then((response) => {
//           //.log(response,'response')
//           if(response.data.response_code == 500){
//             this.setState({ loading: false , modal : false  , dataloaded : false},()=>{this.setState({dataloaded : true})})
//             sessionStorage.setItem("hstructure", JSON.stringify(hstructure));
//             this.getAllUsers()
//           }
//           //("response.data", response)
//           // if (response.data.response_code == 500) {
//           //   this.setState({ saving: false, successmsgshow: true, alertMessage: response.data.message },()=>{this.getHData("stop")})
//           //   setTimeout(() => { this.setState({ successmsgshow: false, }) }, 2500)

//           // }
//         })
//     } catch (error) {

//     }





//   }


//   HtreeDataToFlat = (treeData) => {
//     // //("this.state.treeData", this.state.treeData)
//     var getNodeKey = ({ treeIndex }) => treeIndex
//     var flatData = getFlatDataFromTree(
//       {
//         treeData: treeData,
//         getNodeKey,
//         ignoreCollapsed: false,
//       })
//       //.log(flatData,'flatData')
//     var explicitData = _.filter(flatData, item => {
//       return item
//     })

//     return explicitData
//   }





//   getTreeFromFlatDataAsync = (FlatData) => {
//     return new Promise((resolve, reject) => {
//       try {
//         const treeData = getTreeFromFlatData({
//           flatData: FlatData,
//           getKey: (node) => node.id,
//           getParentKey: (node) => node.parent,
//           rootKey: null,
//           expanded: true
//         });
  
//         resolve(treeData);
//       } catch (error) {
//         reject(error);
//       }
//     });
//   }




//   handleValidSubmit = (event, values) => {
//     console.log(this.state.client_info,'this.state.client_info')
//     var client_info ={...this.state.client_info}
//     var publishTemplate ={...this.state.publishTemplate}

//     client_info.facilities.map((ele, idx) => {
//       this.state.permissionsadded.map((ele1, idx1) => {
//         if (ele1.id === ele.id) {
//           client_info.facilities[idx].enabled = true
//         }
//       })
//     })


//     var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
//     values["password"] = password
//     values["confirmpassword"] = password
//     values["userPoolId"] = this.state.client_info.userPoolId
//     values["clientId"] = this.state.client_info.clientId
//     values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//     values["db_name"] = this.state.db_info.db_name
//     values["facilities"] = this.state.client_info.facilities
//     values["company_id"] = this.state.sessionUserInfo.m_client_id
//     values["created_by"] = this.state.sessionUserInfo._id
//     values["short_name"] = this.state.client_info.short_name
//     values["user_id"] = this.state.sessionUserInfo._id

//     if (this.state.countrycode === undefined) {
//       this.setState({ country_code_err: true })
//     }
//     else {
//       values["countrycode"] = this.state.countrycode
//       this.setState({ country_code_err: false })

//     }
//     if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false) {
//       this.setState({ loading: true })

//       try {
//         urlSocket.post('cog/cruduser', values).then((res) => {
//           if (res.data.response_code === 500) {
//             var created_user = res.data.admn_user[0]
//             this.setState({ loading: false })
//             var event = {}
//             var target = {}
//             target["value"] = created_user._id
//             event["target"] = target
//             this.state.userdata.push(created_user)
//             if (this.state.user_type === 0) {
//               if (this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0) {
//                 publishTemplate.endpoints[this.state.selected_index].adt_users.push({
//                   user_id: created_user._id,
//                   name: created_user.firstname,
//                   designation: created_user.designation,
//                   user_code: created_user.usercode,
//                   audit_type: "1"
//                 })
//               } else {
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_id'] = created_user._id
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['name'] = created_user.firstname
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['designation'] = created_user.designation
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_code'] = created_user.user_code
//                 publishTemplate.endpoints[this.state.selected_index].adt_users[0]['audit_type'] = '1'
//               }
//             }
//             else {
//               if (this.state.user_type === 1) {
//                 if (this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.length === 1 || this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0) {
//                   publishTemplate.endpoints[this.state.selected_index].adt_users.push({
//                     user_id: created_user._id,
//                     name: created_user.firstname,
//                     designation: created_user.designation,
//                     user_code: created_user.usercode,
//                     audit_type: "2",
//                   })
//                 }
//                 else {
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_id'] = created_user._id
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['name'] = created_user.firstname
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['designation'] = created_user.designation
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_code'] = created_user.user_code
//                   publishTemplate.endpoints[this.state.selected_index].adt_users[1]['audit_type'] = '2'

//                 }
//               }

//             }
//             this.setState({ userdata: this.state.userdata, publishTemplate: this.state.publishTemplate }, () => {

//               this.state.user_type === 0 ?
//                 this.changeAuditUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index, "0")
//                 : this.changeReviewUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index, "0")
//               this.onDrawerClose()
//             })
//           }
//           else {
//             this.setState({ loading: false })

//           }
//         })

//       } catch (error) {
//       }

//     }

//   }

//   handle_click = (e) => {
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate[e.target.name] = e.target.checked
//     this.setState({ publishTemplate },()=>{
//       // console.log(publishTemplate,'publishTemplate')
//       this.updatePublishTemplateData()
//     })
//   }


//   labelSelected(data) {

//     if (data.target.value === "all") {
//       this.loadEOPTS()
//     }
//     else {
//       var mylabel = this.state.labelData[data.target.value]

//       try {

//         urlSocket.post("userSettings/load-group-users", {
//           labelInfo: mylabel,
//           userInfo: {
//             encrypted_db_url: this.state.db_info.encrypted_db_url,
//             _id: this.state.userInfo._id,
//             company_id: this.state.userInfo.company_id
//           }
//         })
//           .then(response => {
//             this.setState({
//               statusBasedFilteredData: response.data.data, userdata: response.data.data, auditUserlevelSelected: false
//             }, () => {
//               setTimeout(() => {
//                 this.setState({ auditUserlevelSelected: true });

//               }, 50);

//             })
//           })
//       } catch (error) {
//       }

//     }
//     this.setState({ user_group_selected: data.target.value })

//   }



//   deleteAllEndpoints() {



//     try {
//       var loggedUserInfo = {
//         company_id: this.state.userInfo.company_id,
//         company_name: this.state.userInfo.company_name,
//         created_by: this.state.userInfo._id,
//         encrypted_db_url: this.state.db_info.encrypted_db_url
//       }
//       urlSocket.post("webphlbconf/deleteallendpoints", {
//         userInfo: loggedUserInfo,
//         publishtemplateInfo: this.state.publishTemplate
//       })
//         .then(response => {
//           if (response.data.response_code === 500) {
//             this.setState({
//               changeMethodConfirmation: false,
//             }, function () {
//               this.onDrawerClose()
//             })

//           }
//         })
//     } catch (error) {
//     }

//   }

//   endAfterMethod=(event)=>{
//     console.log(event.target.name,event.target.value)
//     this.setState({
//       selectedRadioOption : event.target.name
//     })
//   }


//   scheduleStartDate=(event)=>{
//     console.log(event.target.value,'value',this.state.publishTemplate)
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate["repeat_mode_config"]["start_date"]=event.target.value
//     this.setState({
//       publishTemplate : publishTemplate
//     })

//   }



//   repeatModeComponent(mode) {
//     console.log(mode,'mode')
//     return (
//       <Form action="#">

//         {
//           mode == "0" ?

//             <div>
//               {/* <div className="mb-3 d-flex flex-column  ">
//                 <div className="d-flex flex-row align-items-center">
//                   <div className="col-3">
//                     <Label className="text-primary" htmlFor="autoSizingSelect">Start date</Label>
//                     <div className="col-md-10">
//                       <input
//                         className="form-control"
//                         type="date"
//                         min={this.state.fromate_date}
//                         selected={this.state.ptl_startdate}
//                         defaultValue={this.state.ptl_startdate}
//                         id="start_date"
//                         onChange={event => this.onChangeStartDate(event)}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-3">
//                     <Label className="text-primary" htmlFor="autoSizingSelect">End date</Label>
//                     <div className="col-md-10">
//                       <input
//                         className="form-control"
//                         type="date"
//                         min={this.state.fromate_date}
//                         selected={this.state.ptl_enddate}
//                         defaultValue={this.state.ptl_enddate}
//                         id="end_date"
//                         onChange={event => this.onChangeEndDate(event)}
//                       />
//                     </div>
//                   </div>

//                 </div>
//               </div> */}
//             </div> : mode == "1" ?

//               <div>
//                 <Row >
//                   {/* <FormGroup className="mb-3 col-3">
//                     <Label className="text-primary" htmlFor="autoSizingSelect">Start Date</Label>
//                       <input
//                         className="form-control"
//                         type="date"
//                         onChange={(e)=>{this.scheduleStartDate(e)}}
//                       />
                   
                   
                  
//                   </FormGroup> */}
//                     {/* <InputGroup>
//                       <Flatpickr
//                         className="form-control d-block"
//                         placeholder="Select time"
//                         options={{
//                           enableTime: true,
//                           noCalendar: true,
//                           dateFormat: "H:i K"
//                         }}
//                         value={this.state.daily_starttime}
//                         onChange={e => this.onChangeStartTime(e)}
//                       />
//                       <div className="input-group-append">
//                         <span className="input-group-text">
//                           <i className="mdi mdi-clock-outline" />
//                         </span>
//                       </div>
//                     </InputGroup> */}
//                     {
//                       // this.state.publishTemplate.repeat_mode_config.start_date !== null &&
//                       // console.log("shall go nxt step") 
//                       <React.Fragment>
//                         <Row>
//                           <Col md={12}>
//                             {/* <h5 className="font-size-14 mb-4 text-primary">End After</h5> */}
//                             <div className="form-check mb-1">
//                               <input
//                                 className="form-check-input mt-2 "
//                                 type="radio"
//                                 onChange={(e) => { this.endAfterMethod(e) }}
//                                 name="occurence"
//                                 id="occurence"
//                                 checked={this.state.selectedRadioOption === "occurence"}
//                                 defaultChecked
//                               />
//                               <label
//                                 className="form-check-label"
//                                 htmlFor="occurence"
//                               >
//                                 No. of Occurences
//                               </label>
//                               <input type={"number"} onChange={(e)=>{this.setState({occurence : e.target.value ,show_occurence_valid : false}) }} style={{ border: '1px solid #d3cfcf', padding: "4px", margin: "0 5px", borderRadius: "6px", width: '38px' }} /> 
//                               {(this.state.occurence === undefined || this.state.occurence =="") && this.state.selectedRadioOption === "occurence"?
//                               <span className="text-danger" style={{fontSize:"smaller"}}> Please Enter the No.of.days</span>
//                               :
//                               null}
//                             </div>
//                             {
//                               this.state.selectedRadioOption === "occurence" &&
//                             <>
//                               <Label className="text-primary mx-4 " htmlFor="autoSizingSelect">Start Date</Label>
//                               <FormGroup className="mb-3 col-3">

//                               <input
//                                 className="form-control mx-4 "
//                                 type="date"
//                                 onChange={(e) => { this.scheduleStartDate(e) }}
//                               />
//                               </FormGroup>

                              
//                             </>

//                             }
                         

//                           </Col>
//                         </Row>
//                       <Row>
//                         <Col md={12}>
//                           <div className="form-check">
//                             <input
//                               className="form-check-input"
//                               type="radio"
//                               onChange={(e) => { this.endAfterMethod(e) }}
//                               name="enddate"
//                               id="enddate"
//                               checked={this.state.selectedRadioOption === "enddate"}
//                             />
//                             <label
//                               className="form-check-label"
//                               htmlFor="enddate"
//                             >
//                               Date Range
//                             </label>
//                             {/* <FormGroup className="mb-3 col-3">
//                               <input
//                                 className="form-control mt-2 "
//                                 type="date"
//                                 disabled={this.state.selectedRadioOption === "occurence"}
//                               // onChange={(e) => { this.scheduleStartDate(e) }}
//                               />

//                             </FormGroup> */}
//                           </div>
//                           {
//                               this.state.selectedRadioOption === "enddate" &&
//                             <>
//                               <Label className="text-primary mx-4 mt-2 " htmlFor="autoSizingSelect">Start Date</Label>
//                               <FormGroup className="mb-3 mx-4  col-3">

//                               <input
//                                 className="form-control"
//                                 type="date"
//                                 onChange={(e) => { this.scheduleStartDate(e) }}
//                               />
//                               </FormGroup>
//                             </>

//                             }
//                             {
//                               this.state.publishTemplate.repeat_mode_config.start_date !== null &&
//                               <>
                                                          
//                           <Label className="text-primary" htmlFor="autoSizingSelect">End Date</Label>
//                               <input
//                                     className="form-check-input"
//                                     type="date"
                                  
//                                   />
//                                   </>
//                             }

//                         </Col>

//                       </Row>
//                       </React.Fragment>

//                       // <div className="mt-4">
//                       //   <h5 className="font-size-14 mb-4 text-primary">End After</h5>
//                       //   <div className="form-check mb-3">
//                       //     <input
//                       //       className="form-check-input"
//                       //       type="radio"
//                       //       onChange={(e)=>{this.endAfterMethod(e)}}
//                       //       name="occurence"
//                       //       id="occurence"
//                       //       checked={this.state.selectedRadioOption === "occurence"}
//                       //       defaultChecked
//                       //     />
//                       //     <label
//                       //       className="form-check-label"
//                       //       htmlFor="occurence"
//                       //     >
//                       //      No. of Occurences
//                       //     </label>
//                       //     <input  type={"number"} style={{border:'1px solid #d3cfcf' ,padding :"4px", margin:"0 5px", borderRadius : "6px" , width : '38px'}}/>
//                       //   </div>
//                       //   <div className="form-check">
//                       //     <input
//                       //       className="form-check-input"
//                       //       type="radio"
//                       //       onChange={(e)=>{this.endAfterMethod(e)}}
//                       //       name="enddate"
//                       //       id="enddate"
//                       //       checked={this.state.selectedRadioOption === "enddate"}
//                       //     />
//                       //     <label
//                       //       className="form-check-label"
//                       //       htmlFor="enddate"
//                       //     >
//                       //      Date Range
//                       //     </label>
//                       //   </div>
//                       // </div>


//                     }
//                   {/* <FormGroup className="mb-3 col-3">
//                     <Label className="text-primary" htmlFor="autoSizingSelect">End Time</Label>
//                     <InputGroup>
//                       <Flatpickr
//                         className="form-control d-block"
//                         placeholder="Select time"
//                         options={{
//                           minTime: this.state.getMinTime,
//                           enableTime: true,
//                           noCalendar: true,
//                           dateFormat: "H:i K"
//                         }}
//                         value={this.state.daily_endtime}
//                         onChange={e => this.onChangeEndTime(e)}
//                       />
//                       <div className="input-group-append">
//                         <span className="input-group-text">
//                           <i className="mdi mdi-clock-outline" />
//                         </span>
//                       </div>
//                     </InputGroup>
//                   </FormGroup> */}
//                 </Row>


//               </div> : mode == "2" ?
//                 <div style={{ display: 'flex', flexDirection: 'Column' }}>

//                   <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
//                     {
//                       this.state.weekDays.map((item, idx) => {
//                         return (
//                           <div key={idx} className="form-check mx-2 ">
//                             <input
//                               type="checkbox"
//                               className="form-check-input"
//                               id={item.day}
//                               checked={item.checked}
//                               onChange={e => { this.handleWeekDays(e) }}
//                             />
//                             <label
//                               className="form-check-label"
//                               htmlFor={item.day}
//                             >
//                               {item.day}
//                             </label>
//                           </div>
//                         )
//                       })
//                     }
//                   </div>



//                 </div> : mode == "3" ?
//                   <div>
//                     <Row>

//                       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 30 }}>
//                         <span style={{ fontSize: 13, color: '#74788d' }}>Every month started on &nbsp;&nbsp;</span>
//                         <div className="me-2">
//                           <Label className="card-radio-label">
//                             <Input
//                               type="radio"
//                               name="month_start"
//                               id="ms-1"
//                               className="card-radio-input"
//                               value={this.state.mstartoption}
//                               checked={this.state.mstartoption === "month_start"}
//                               onChange={this.handleMStartGroupChange}
//                             />

//                             <div className="card-radio py-4">
//                               <span style={{
//                                 color: this.state.mstartoption !== "month_start" || this.state.mstartoption === undefined ? "#c0c1c5" : "#74788d"
//                               }}>Month 1st</span>
//                             </div>
//                           </Label>
//                         </div>
//                         <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>
//                         <div className="me-2">
//                           <Label className="card-radio-label">
//                             <Input
//                               type="radio"
//                               name="custom_start"
//                               id="ms-2"
//                               className="card-radio-input"
//                               value={this.state.mstartoption}
//                               checked={this.state.mstartoption === "custom_start"}
//                               onChange={this.handleMStartGroupChange}

//                             />

//                             <div className="card-radio d-flex flex-row align-items-center">
//                               <select
//                                 type="select"
//                                 name="m_starton"
//                                 label="Name"
//                                 value={this.state.mstartoption === "month_start" || this.state.mstartoption === null ? 'choose' : this.state.dsf}
//                                 className="form-select"
//                                 style={{ width: 60 }}
//                                 id="m_starton"
//                                 disabled={this.state.mstartoption === "month_start" || this.state.mstartoption === null}
//                                 onChange={(e) => this.monthStartOn(e)}>
//                                 <option value="choose" disabled >Choose...</option>
//                                 {
//                                   this.state.dayStartNumber.map((data, idx) => {
//                                     return (
//                                       <option value={data.value} selected key={idx}>{data.name}</option>
//                                     )
//                                   })
//                                 }
//                               </select>
//                               {this.state.mstartoption === "month_start" || this.state.mstartoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.dsf)}</span>}
//                             </div>
//                           </Label>
//                         </div>
//                         <span>&nbsp;and ends on &nbsp;&nbsp;</span>
//                         <div className="me-2">
//                           <Label className="card-radio-label">
//                             <Input
//                               type="radio"
//                               name="month_end"
//                               id="pay-methodoption1"
//                               className="card-radio-input"
//                               value={this.state.mendoption}
//                               checked={this.state.mendoption === "month_end"}
//                               onChange={this.handleMEndGroupChange}
//                             />

//                             <div className="card-radio py-4">
//                               <span style={{
//                                 color: this.state.mendoption !== "month_end" || this.state.mendoption === null ? "#c0c1c5" : "#74788d"
//                               }}>Month end</span>
//                             </div>
//                           </Label>
//                         </div>
//                         <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>
//                         <div className="me-2">
//                           <Label className="card-radio-label">
//                             <Input
//                               type="radio"
//                               name="custom_end"
//                               id="pay-methodoption3"
//                               className="card-radio-input"
//                               value={this.state.mendoption}
//                               checked={this.state.mendoption === "custom_end"}
//                               onChange={this.handleMEndGroupChange}

//                             />

//                             <div className="card-radio d-flex flex-row align-items-center">
//                               <select
//                                 type="select"
//                                 name="m_endson"
//                                 label="Name"
//                                 value={this.state.mendoption === "month_end" || this.state.mendoption === null ? 'choose' : this.state.eoem}
//                                 className="form-select"
//                                 style={{ width: 60 }}
//                                 id="m_endson"
//                                 disabled={this.state.mendoption === "month_end" || this.state.mendoption === null}
//                                 onChange={(e) => this.monthEndsOn(e)}>
//                                 <option value="choose" disabled >Choose...</option>
//                                 {
//                                   this.state.dayEndNumber.map((data, idx) => {
//                                     return (
//                                       <option value={data.value} selected key={idx}>{data.name}</option>
//                                     )
//                                   })
//                                 }
//                               </select>
//                               {this.state.mendoption === "month_end" || this.state.mendoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.eoem)}</span>}
//                             </div>
//                           </Label>
//                         </div>
//                       </div>
//                     </Row>

//                   </div> : mode == "4" ?
//                     <div></div> : mode == "5" ?
//                       <div></div> :
//                       null

//         }

//       </Form>
//     )
//   }

//   onCheck = (checked, event, mode) => {
//     //.log('checked, event', checked, event, mode, event.node)
//     console.log( checked, event, mode, event.node)
//     var publishTemplate ={...this.state.publishTemplate}

//     if (mode == "2") {
//       var findIdx = _.findIndex(this.state.publishTemplate.endpoints, { _id: event.node.node_id })
//       //.log(findIdx, 'findIdx',this.state.publishTemplate.endpoints)
//       if (event.checked == true) {
//         var endPointArray = {
//           adt_users: [],
//           code: null,
//           designation: "",
//           h_id: this.state.publishTemplate.hlevel_id,
//           hlevel: event.node.ep_level,
//           hlevel_name: event.node.label,
//           hlevel_value: event.node.ep_level_value,
//           user_path: event.node.user_path,
//           unique_users: event.node.unique_users,
//           _id: event.node.node_id
//         }
//         var assign_auditUser = _.filter(event.node.user_path,{cat_type :"1"})
//         if(assign_auditUser.length >0 && assign_auditUser.length <2){
//           assign_auditUser[0]["audit_type"] = "1"
//           endPointArray["rowValid"] = true
//           // this.state.publishTemplate.endpoints
//           // assign_auditUser[0]["rowValid"] = true
//           endPointArray.adt_users.push(assign_auditUser[0])
//         }
//         // var assign_auditUser = _.filter(this.state.publishTemplate.endpoints)
//         publishTemplate.endpoints.push(endPointArray)
//         publishTemplate["audit_userlevel"]="1"
//         publishTemplate["audit_userlevel_value"]=1
//         // this.state.publishTemplate["enable_review"] = false
//         // this.updatePublishTemplateData()

//       }
//       else {
//         // this.state.publishTemplate["audit_userlevel"]="1"
//         // this.state.publishTemplate["audit_userlevel_value"]=1
//         publishTemplate["enable_review"] = false
//         publishTemplate.endpoints.splice(findIdx, 1)
//       }
//       this.setState({
//         enableReview: false
//       }, () => { this.handleEndpointSelection(checked.checked, event) })
      
//     }
//     else {
//       publishTemplate["audit_userlevel"]="1"
//       publishTemplate["audit_userlevel_value"]=1
//       this.handleEndpointSelection(checked, event)
//     }

//   };

//   buildTreeNodes = (nodes) => {
//     const { checkedKeys } = this.state;
//     return nodes.map((node) => {
//       const { value, label, children, ep_level, ep_level_value, id, node_id, parent, user_path,unique_users } = node;
//       const hasChildren =  (children && children.length > 0) ;
//       // console.log(nodes,'nodes')
//       // //.log(node,'node')
//       //  console.log(hasChildren,'hasChildren',node ,children,this.state.publishTemplate)
//       return {
//         key: value,
//         label: label,
//         user_path: user_path,
//         unique_users:unique_users,
//         value,
//         ep_level,
//         ep_level_value,
//         id,
//         node_id,
//         parent,
//         children: hasChildren ? this.buildTreeNodes(children) : [],

//         title: (
//           <div // Use a div container for the entire node
//             onClick={() => this.handleNodeClick(value)} // Handle node click
//             style={{
//               background: hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',

//               // background: hasChildren ? 'transparent' : this.state.publishTemplate.hirearchy_type == "1" ? '#E6F4FF' : 'transparent',
//               position: 'relative',
//               zIndex: 'auto',
//               minHeight: '24px',
//               margin: '0',
//               padding: '0 4px',
//               color: 'inherit',
//               lineHeight: '24px',
//               borderRadius: '6px',
//               cursor: 'pointer',
//               transition: 'all 0.2s, border 0s, line - height 0s, box - shadow 0s'
//             }}
//           >
//             {hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? (
//               <FolderOutlined style={{ cursor: 'unset', opacity: 0.5 }} />
//             ) : (
//               <FileOutlined
//                 style={{
//                   cursor: 'pointer',
//                   color: '#556EE6',
//                   opacity: 1,
//                 }}
//               />
//             )}
//             <span style={{ marginLeft: '4px', opacity: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? 0.5 : 1, fontWeight: hasChildren && this.state.publishTemplate.hirearchy_type == "1" ? '' : 600 }}>
//               {label}
//             </span>
//           </div>
//         ),

//       };
//     });
//   };


//   getAllTreeStructure = () => {
//     console.log(this.state.publishTemplate,this.state.hirerachyData._id,'this.state.publishTemplate')
//     var publishTemplate ={...this.state.publishTemplate}
//     urlSocket.post("webphlbconf/getlocations", {
//       userInfo: {
//         created_by: this.state.userInfo._id,
//         company_id: this.state.userInfo.company_id,
//         encrypted_db_url: this.state.db_info.encrypted_db_url
//       },
//       hInfo: {
//         // h_id: this.state.hirerachyData._id,
//         h_id:this.state.publishTemplate.hlevel_id,
//         hlevelinfo: "full_data",
//         publishtemplateInfo:publishTemplate
//       }
//     })
//       .then(response => {
//         //.log(response, 'response')
//         console.log(response,'response')
//         if (response.data.response_code == 500) {
//           var convertedFlatData = this.convertFlatDataToTreeData(response.data.data)
//           publishTemplate.ep_structure = convertedFlatData
//           this.setState({
//             locationData: convertedFlatData,
//           },
//             function () {
//               this.updateCheckedepStructure()
//             }
//           )

//         }
//       })

//   }

//   handleNodeClick = (value) => {
//     //.log(value, 'value')
//     this.setState({ selectedKey: value });
//   };

//   hierarchyType = (mode) => {
//     //.log(mode, 'mode', this.state.publishTemplate)
//     var publishTemplate ={...this.state.publishTemplate}
//     publishTemplate['hirearchy_type'] = mode
//     publishTemplate['endpoints'] = []
//     publishTemplate['ep_level'] = null
//     publishTemplate['ep_selected'] = []
//     publishTemplate['eplevel_value'] = null
//     publishTemplate['audit_userlevel'] = null
//     publishTemplate['audit_userlevel_value'] = null
//     publishTemplate['enable_review'] = false
//     publishTemplate['review_userlevel'] = null
//     publishTemplate['review_userlevel_value'] = null
//     publishTemplate['cc_level'] = 0


//     if (mode == "2") {
//       this.setState({checkedKeys :[],enableReview : false},()=>{this.getAllTreeStructure()})
//     }
//     else {
//       this.setState({ publishTemplate: publishTemplate, endpointSelected: false, selectedEndpoint: "choose", checkedKeys: [], enableReview: false }, () => { this.updatePublishTemplateData() })
//     }
//   }



//   ignoreLocation = (event, idx, item, mode) => {
//     //.log(event, 'ignode', idx, item, this.state.publishTemplate)
//     var publishTemplate ={...this.state.publishTemplate}
//     if (mode == false || mode == undefined) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Ignore Confirmation',
//         text: 'Are you sure you want to ignore this location?',
//         showCancelButton: true,
//         confirmButtonColor: '#d33',
//         cancelButtonColor: '#3085d6',
//         confirmButtonText: 'Ignore',
//         cancelButtonText: 'Cancel',
//       }).then((result) => {
//         if (result.isConfirmed) {
//           // this.state.publishTemplate.endpoints.map((ele,idx)=>{
//           //   //.log(ele._id)
//           // })
//           publishTemplate.endpoints[idx]["disabled"] = true
//           // var ignore_location = this.state.publishTemplate.endpoints.filter((item, position) => position === idx)
//           //  var ignore_ep_selected = this.state.publishTemplate.ep_selected.filter((data,position)=> data !== item._id)
//           // //.log(ignore_location, 'ignore_location',idx)
//           //  this.state.publishTemplate["endpoints"] = ignore_location
//           //  this.state.publishTemplate["ep_selected"] = ignore_ep_selected
//           this.setState({ publishTemplate: publishTemplate , dataloaded : false }, () => {this.setState({dataloaded : true},()=>{this.updatePublishTemplateData()})})

//         }

//       })
//     }
//     else if(mode == true) {
//       Swal.fire({
//         icon: 'question',
//         title: 'Reconsider Again ?',
//         text: 'Are you sure want to Reconsider this Location again ?',
//         showCancelButton: true,
//         confirmButtonColor: '#2ba92b',
//         confirmButtonText: 'Yes',
//         cancelButtonColor: '#d33',
//         cancelButtonText: 'No'
//         // confirmButtonText: 'Go to Published Audits',
//       }).then((result) => {
//         if (result.isConfirmed) {
//           publishTemplate.endpoints[idx]["disabled"] = false
//           // this.setState({ publishTemplate: this.state.publishTemplate }, () => { this.updatePublishTemplateData() })
//           this.setState({ publishTemplate: publishTemplate , dataloaded : false }, () => {this.setState({dataloaded : true},()=>{this.updatePublishTemplateData()})})
//         }
//       })
//     }

//   }

//   onRemove=(selectedItem,selectedList)=>{
//     //.log(selectedItem,'selectedItem',selectedList)
//     this.setState({
//       selected_category : selectedList
//     })

    
//   }








//   clearUsers = (data, index) => {
//     //.log(data, index, 'index')
//     var publishTemplate ={...this.state.publishTemplate}
//     Swal.fire({
//       icon: 'question',
//       title: 'Reconsider Again ?',
//       text: 'Are you sure want to Clear this Location Users ?',
//       showCancelButton: true,
//       confirmButtonColor: '#2ba92b',
//       confirmButtonText: 'Yes',
//       cancelButtonColor: '#d33',
//       cancelButtonText: 'No'
//       // confirmButtonText: 'Go to Published Audits',
//     }).then((result) => {

//       if (result.isConfirmed) {
//         //.log(data, index, 'index')
//         publishTemplate["endpoints"][index]["adt_users"] = []
//         publishTemplate["endpoints"][index]["rowValid"] =false
//         this.setState({ publishTemplate: publishTemplate, dataloaded: false }, () => { this.setState({ dataloaded: true }, () => { this.updatePublishTemplateData() }) })

//       }

//     })
//   }




//   toggle = () => {
//     this.setState(prevState => ({
//         modal: !prevState.modal
//     }));
// }

//   passWordBtn = () => {
//     //.log("triggree")
//     this.setState({ show: !this.state.show, })
//   }
// // }


// onSelectValues=(selectedList, selectedItem)=>{
//   //.log(selectedList, selectedItem,'selectedList, selectedItem, index')
//   this.setState({
//     selected_category : selectedList
//   })


// }





//   render() {
//     var treeNodes = this.buildTreeNodes(this.state.locationData);
//     if (this.state.dataloaded) {
//       const { locationData } = this.state
//       // const { SearchBar } = Search;
//       const { isEdit } = this.state
//       const options = {
//         page: this.state.paginationPage,

//         sizePerPage: 10,
//         totalSize: this.state.publishTemplate.endpoints.length, // replace later with size(users),
//         custom: true,
//         onPageChange: (e) => {
//           this.setState({ paginationPage: e })
//         }
//       };
//       var columns

//       if(this.state.methodSelected == "2"){
//        columns = [


//         {
//           accessor: 'hlevel_name',
//           Header: 'Location',
//           sort: true,
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (
//               <>
//                 <div style={{ display: 'flex', opacity: item.disabled ? "50%": "none", flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>
//                   {item.hlevel_name}
//                 </div>
//               </>
//             )
//           }
//         },
//         {
//           accessor: 'code',
//           Header: 'Code',
//           Cell: (cellProps) => {
//             var item = cellProps.row.original

//             return (
//               <div style={{ color: item.rowValid ? null : "#f46a6a" }}>
//                 {item.code}
//               </div>
//             )
//           }
//         },
//         {
//           accessor: 'hlevel',
//           Header: 'Category',
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (
//               <div style={{ color: item.rowValid ? null : "#f46a6a" ,opacity: item.disabled ? "50%": "none"}}>
//                 {item.hlevel}
//               </div>
//             )
//           }
//         },

//         {
//           accessor: '',
//           Header: 'Audit User',
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             var index = cellProps.row.index
//             //.log(item,'item')
//             var mappedUser
//             var auditor_index

//             if (this.state.publishTemplate.method_selected == "2") {

//               var getIndex = _.findIndex(item.adt_users, (user) => {
//                 return (user.review_user === undefined || user.review_user === false) && (user.cat_type === "1" || user.cat_type === "2" || user.cat_type === "3" || user.cat_type == null || user.cat_type == user.cat_type);
//               });
//               if(item.unique_users.length >0){
//               const auditor_idx = item.unique_users.findIndex(user => user.cat_type.includes('1'));
//               auditor_index = item.unique_users[auditor_idx]?.cat_type.indexOf('1');
//               //.log(auditor_index, 'catTypeIndex')
//             }

//             }
//             else {
//               var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
//             }
//             if (getIndex !== -1) {
//               mappedUser = item.adt_users[getIndex]
             
//               item["rowValid"] = true
//             }
//             else {
//               mappedUser = undefined
//             }
//             console.log(mappedUser,'mappedUser',getIndex)
//             return (
//               <>
//                 <div>
//                   <select
//                     type="select"
//                     name="adtuser"
//                     label="Name"
//                     value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
//                     className="form-select"
//                     id={"adtuser" + String(item._id)}
//                     disabled={item.disabled ? true : false}
//                     onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}>
//                     <option value="choose" disabled >Choose...</option>
//                     <option value="add" >Create New User</option>
//                     <option value="#import" >+ Add Users</option>

//                     {
//                       //.log(item,'item')
//                     }
//                     {
//                       item?.unique_users.map((data, idx) => {
//                         const isUserDisabled = item.adt_users.some(user => user.user_id === data.user_id &&  user.audit_type === "2");

//                         // const isUserDisabled = item.adt_users.some(user => user.user_id === data.user_id);
//                         return (
//                           <option value={data.user_id} disabled={isUserDisabled} key={idx}>{data.name} - {auditor_index !== undefined ? data.hirerachy_name[auditor_index] == undefined ? data.hirerachy_name[0] : data.hirerachy_name[auditor_index] :data.hirerachy_name[0]}</option>
//                         )
//                       })
//                     }
//                   </select>
//                 </div>
//               </>
//             )
//           }
//         },

//         // {
//         //   accessor: '',
//         //   Header: 'Review User',
//         //   // hidden: this.state.methodSelected === "2" ? true : false,
//         //   Cell: (cellProps) => {
//         //     var item = cellProps.row.original
//         //     var index = cellProps.row.index
//         //     var mappedUser
//         //     var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
//         //     if (getIndex !== -1) {
//         //       mappedUser = item.adt_users[getIndex]
//         //       this.updateCCLevel()
//         //     }
//         //     else {
//         //       mappedUser = undefined
//         //     }

//         //     return (
//         //       <>
//         //         {
//         //           !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null
//         //         }
//         //         {
//         //           this.state.customEnableReview ?
//         //             <div>
//         //               <select
//         //                 type="select"
//         //                 name="rvwuser"
//         //                 label="Name"
//         //                 value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
//         //                 className="form-select"
//         //                 id={"custrvwuser" + String(item._id)}
//         //                 // disabled={}
//         //                 onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
//         //                 <option value="choose" disabled >Choose...</option>
//         //                 <option value="add" >Add New User</option>
//         //                 {
//         //                   this.state.userdata.map((data, idx) => {
//         //                     return (
//         //                       <option value={data._id} selected key={idx}>{data.firstname}</option>
//         //                     )
//         //                   })
//         //                 }
//         //               </select>
//         //             </div> : null
//         //         }

//         //       </>
//         //     )
//         //   }
//         // },

//         {
//           accessor: '',
//           Header: 'Review User',
//           hidden: this.state.methodSelected === "2" ? false : true,
//           Cell: (cellProps) => {
//             var item =cellProps.row.original
//             var index =cellProps.row.index

//             var mappedUser
//             var catTypeIndex
//             //.log(this.state.publishTemplate.method_selected ,'this.state.publishTemplate.method_selected ')
//             if (this.state.publishTemplate.method_selected == "2") {
//               var getIndex = _.findIndex(item.adt_users, (user) => {
//                 return user.review_user === true && (user.cat_type === "1" || user.cat_type === "2" || user.cat_type === "3" || user.cat_type == null);
//               });
//               const reviwer_idx = item.unique_users.findIndex(user => user.cat_type.includes('2'));
//               //.log(reviwer_idx,'reviwer_idx')
//               if(reviwer_idx !== -1){
//               catTypeIndex = item.unique_users[reviwer_idx].cat_type.indexOf('2');
//             }
//             }
//             else {
//               var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
//             }
//             if (getIndex !== -1) {
//               mappedUser = item.adt_users[getIndex]
             
//               //.log('index',catTypeIndex)
//               // this.updateCCLevel()
//             }
//             else {
//               mappedUser = undefined
//             }
//             //.log(catTypeIndex,'catTypeIndex')
//             return (
//               <>
//                 {
//                   !this.state.enableReview ? <div className="text-secondary">Review not enable</div> : null
//                 }
//                 {
//                   this.state.enableReview ?
//                     <div>
//                       <select
//                         type="select"
//                         name="rvwuser"
//                         label="Name"
//                         value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
//                         className="form-select"
//                         id="rvwuser"
//                         disabled={!this.state.enableReview || item.disabled ? true : false}
//                         onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>
//                         <option value="choose" disabled >Choose...</option>
//                         <option value="add" >Create New User</option>
//                     <option value="#import" >+ Add Users</option>
                      
//                         {/* <option value="add" >Create New User</option> */}

//                         {
//                           item.unique_users.map((data, idx) => {
//                             console.log(data,'data')
//                             //.log(data.hirerachy_name[catTypeIndex],catTypeIndex)
//                             const isUserDisabled = item.adt_users.some(user => user.user_id === data.user_id &&  user.audit_type === "1");
//                             return (
//                               <option value={data._id} disabled={isUserDisabled} selected key={idx}>{data.name} - {catTypeIndex !== undefined ? data.hirerachy_name[catTypeIndex] == undefined ? data.hirerachy_name[0] : data.hirerachy_name[catTypeIndex] :data.hirerachy_name[0]}</option>
//                             )
//                           })
//                         }
//                       </select>
//                     </div> : null
//                 }

//               </>
//             )
//           }
//         },


//         {
//           accessor: "menus",
//           // isDummyField: true,
//           // editable: false,
//           Header: "Reset Users",
//           hidden: this.state.methodSelected === "2" && this.state.publishTemplate.enable_review == false ? true : false,
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             var index = cellProps.row.index

//             return(
//           <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
//              {
//                 this.state.methodSelected =="2" && this.state.publishTemplate.enable_review &&
//                 <Button className={"btn btn-primary"} onClick={(e)=>{this.clearUsers(item,index)}}>Clear </Button>
//              }

//             </div>
//             )
//             }
//         },

//         {
//           accessor: "menu",
//           // isDummyField: true,
//           // editable: false,
//           Header: "Action",
//           hidden: this.state.methodSelected === "2" ? false : false,
//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return(
//             <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
//               {
//                 this.state.methodSelected == "1" ?
//               <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>
//                 :
//                   <Button className={item.disabled ? "btn btn-success" :"btn btn-danger"} onClick={(e)=>{this.ignoreLocation(e,index,item,item.disabled)}}>{item.disabled ? "Reconsider" : "Ignore"} </Button>
//               }
//               {/* {
//                 this.state.methodSelected =="2" && this.state.publishTemplate.enable_review &&
//                 <Button className={"btn btn-primary"} onClick={(e)=>{this.clearUsers(item,index)}}>Clear </Button>
//               } */}
//             </div>
//           // ),
//           )
//             }
//         },
//       ];
//     }
//     if(this.state.methodSelected === "1"){
//        columns = [
//        {

//         accessor: 'hlevel_name',

//         Header: 'Location',

//             sort: true,

//             Cell: (cellProps) => {
//               var item = cellProps.row.original
//                 return (

//                     <>

//                         <div style={{ display: 'flex', flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>

//                             {item.hlevel_name}

//                         </div>

//                     </>

//                 )

//             }

//         },

//         {

//           accessor: 'code',

//           Header: 'Code',

//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//                 return (

//                     <div style={{ color: item.rowValid ? null : "#f46a6a" }}>

//                         {item.code}

//                     </div>

//                 )

//             }

//         },

//         {

//           accessor: 'hlevel',

//           Header: 'Category',

//             Cell: (cellProps) => {
//               var item = cellProps.row.original
//                 return (

//                     <div style={{ color: item.rowValid ? null : "#f46a6a" }}>

//                         {item.hlevel}

//                     </div>

//                 )

//             }

//         },



//         {

//           accessor: '',

//           Header: 'Audit User',

//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             var index = cellProps.row.index


//                 var mappedUser

//                 var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })

//                 if (getIndex !== -1) {

//                     mappedUser = item.adt_users[getIndex]

//                 }

//                 else {

//                     mappedUser = undefined

//                 }

//                 // console.log(cellContent, item, index)

//                 return (

//                     <>

//                         <div>

//                             <select

//                                 type="select"

//                                 name="adtuser"

//                                 label="Name"

//                                 value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

//                                 className="form-select"

//                                 id={"adtuser" + String(item._id)}

//                                 onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}>

//                                 <option value="choose" disabled >Choose...</option>

//                                 <option value="add" >Add New User</option>

//                                 {

//                                     this.state.userdata.map((data, idx) => {

//                                         return (

//                                             <option value={data._id} key={idx}>{data.firstname}</option>

//                                         )

//                                     })

//                                 }

//                             </select>

//                         </div>

//                     </>

//                 )

//             }

//         },



//         {

//           accessor: '',

//           Header: 'Review User',

//             hidden: this.state.methodSelected === "2" ? true : false,

//             Cell: (cellProps) => {
//               var item = cellProps.row.original
//               var index = cellProps.row.index


//               // return(

//                 var mappedUser

//                 var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })

//                 if (getIndex !== -1) {

//                     mappedUser = item.adt_users[getIndex]

//                 }

//                 else {

//                     mappedUser = undefined

//                 }



//                 return (

//                     <>

//                         {

//                             !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null

//                         }

//                         {

//                             this.state.customEnableReview ?

//                                 <div>

//                                     <select

//                                         type="select"

//                                         name="rvwuser"

//                                         label="Name"

//                                         value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

//                                         className="form-select"

//                                         id={"custrvwuser" + String(item._id)}

//                                         onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>

//                                         <option value="choose" disabled >Choose...</option>

//                                         <option value="add" >Add New User</option>

//                                         {

//                                             this.state.userdata.map((data, idx) => {

//                                                 return (

//                                                     <option value={data._id} selected key={idx}>{data.firstname}</option>

//                                                 )

//                                             })

//                                         }

//                                     </select>

//                                 </div> : null

//                         }



//                     </>

//                 )
//               // )

//             }

//         },



//         // {

//         //   accessor: '',

//         //   Header: 'Review User',

//         //     hidden: this.state.methodSelected === "2" ? false : true,

//         //     Cell: (cellProps) => {

//         //       var item = cellProps.row.original
//         //       var index = cellProps.row.index


//         //         var mappedUser

//         //         var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })

//         //         if (getIndex !== -1) {

//         //             mappedUser = item.adt_users[getIndex]

//         //         }

//         //         else {

//         //             mappedUser = undefined

//         //         }



//         //         return (

//         //             <>

//         //                 {

//         //                     !this.state.enableReview ? <div className="text-secondary">Review not enable</div> : null

//         //                 }

//         //                 {

//         //                     !this.state.reviewUserlevelSelected && this.state.enableReview ? <div className="text-secondary">Select Review user level</div> : null

//         //                 }

//         //                 {

//         //                     this.state.reviewUserlevelSelected ?

//         //                         <div>

//         //                             <select

//         //                                 type="select"

//         //                                 name="rvwuser"

//         //                                 label="Name"

//         //                                 value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

//         //                                 className="form-select"

//         //                                 id="rvwuser"

//         //                                 disabled={!this.state.reviewUserlevelSelected}

//         //                                 onChange={(e) => this.changeReviewUserHandler(e, item, index, "1")}>

//         //                                 <option value="choose" disabled >Choose...</option>

//         //                                 <option value="add" >Add New User</option>

//         //                                 {

//         //                                     this.state.userdata.map((data, idx) => {

//         //                                         return (

//         //                                             <option value={data._id} selected key={idx}>{data.firstname}</option>

//         //                                         )

//         //                                     })

//         //                                 }

//         //                             </select>

//         //                         </div> : null

//         //                 }



//         //             </>

//         //         )

//         //     }

//         // },

//         {

//           accessor: "menu",

//             // isDummyField: true,

//             // editable: false,

//             Header: "Action",

//             hidden: this.state.methodSelected === "2" ? true : false,

//             Cell: (cellProps) => {
//               var item = cellProps.row.original


//               return(

//                 <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>

//                     {/* <Link className="text-primary" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => { this.setState({ open: true, component: "endpoint", editItem: item }) }}></i></Link> */}

//                     <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>

//                 </div>
//                 )

//               },

//         },

//     ];
//     }
//       const defaultSorted = [{
//         dataField: 'hname', // if dataField is not match to any column you defined, it will be ignored.
//         order: 'asc' // desc or asc
//       }];
//       return (
//         <React.Fragment>
//           <div className="page-content">
//             <MetaTags>
//               <title>AuditVista | Publishing</title>
//             </MetaTags>
//             <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-2">
//               <div style={{ width: '80%', padding: '0 20px' }}>Publishing</div>
//               <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
//                 <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//               </div>
//             </div>
//             <Container fluid>
//               {
//                 this.state.methodSelected === "0" ? <div>
//                   <Card>
//                     <CardBody>
//                       <Label className="my-3">Add Location by</Label>

//                       <Row>
//                         <Col xl="6" sm="6">
//                           <div className="me-2 text-wrap">
//                             {/* <p className="card-title-desc">
//                               Use the <code>.badge-pill</code> modifier className to
//                               make badges more rounded (with a larger{" "}
//                               <code>border-radius</code>
//                               and additional horizontal <code>padding</code>).
//                               Useful if you miss the badges from v3.
//                             </p> */}
//                             <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("2")}>Hirerachy Level</button>
//                           </div>
//                         </Col>

//                         <Col xl="6" sm="6">

//                           <div className="me-2 text-wrap">
//                             {/* <p className="card-title-desc">
//                               Use the <code>.badge-pill</code> modifier className to
//                               make badges more rounded (with a larger{" "}
//                               <code>border-radius</code>
//                               and additional horizontal <code>padding</code>).
//                               Useful if you miss the badges from v3.
//                             </p> */}
//                             <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("1")}>Manual Location</button>
//                           </div>
//                         </Col>
//                       </Row>
//                     </CardBody>
//                   </Card>
//                 </div> : null
//               }

//               {
//                 this.state.methodSelected === "1" ?
//                   <div>

//                     <div className="checkout-tabs">
//                       <Row>
//                         <Col lg="2" sm="3">
//                           <Nav className="flex-column" pills>
//                             <NavItem>
//                               <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f' }}
//                                 className={classnames({
//                                   active: this.state.activeCustomTab === 1 && this.state.cc_level == 0,
//                                   activechange: this.state.activeCustomTab === 1 && this.state.cc_level > 0
//                                 })

//                                 }
//                                 onClick={() => {
//                                   this.toggleCustomTab(1)
//                                 }}
//                               >
//                                 <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />
//                                 <p className="font-weight-bold mb-4">Locations</p>
//                               </NavLink>
//                             </NavItem>
//                             <NavItem>
//                               <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6' }}
//                                 className={classnames({
//                                   active: this.state.activeCustomTab === 2 && this.state.cc_level > 1,
//                                   activechange: this.state.activeCustomTab === 2 && this.state.cc_level > 2
//                                 })}
//                                 onClick={() => {
//                                   this.toggleCustomTab(2)
//                                 }}
//                                 disabled={this.state.cc_level > 1 ? false : true}
//                               >
//                                 <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />
//                                 <p className="font-weight-bold mb-4">Configuration</p>
//                               </NavLink>
//                             </NavItem>
//                             <NavItem >
//                               <NavLink style={{ border: this.state.cc_level < 3 ? "0px" : this.state.cc_level > 3 ? '1px solid #34c38f' : '1px solid #556ee6' }}
//                                 className={classnames({
//                                   active: this.state.activeCustomTab === 3 && this.state.cc_level > 2,
//                                   activechange: this.state.activeCustomTab === 3 && this.state.cc_level >= 4
//                                 })}
//                                 onClick={() => {
//                                   this.toggleCustomTab(3)
//                                 }}
//                                 disabled={this.state.cc_level > 2 ? false : true}
//                               >
//                                 <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
//                                 <p className="font-weight-bold mb-4">Confirmation</p>
//                               </NavLink>
//                             </NavItem>

//                           </Nav>
//                         </Col>

//                         <Col lg="10" sm="9">
//                           <AvForm className="form-horizontal" >
//                             <Card>
//                               <CardBody>
//                                 <TabContent activeTab={this.state.activeCustomTab}>

//                                   <TabPane
//                                     tabId={1}>
//                                     <div>
//                                       {
//                                         this.state.auditUserlevelSelected ?
//                                           <>
//                                              <Row className="mb-2" style={{ paddingTop: 10 }}>
//                                                         <Col sm="3">
//                                                            <div className="search-box ms-2 mb-2 d-inline-block">
//                                                              <div className="position-relative">
//                                                                {/* <SearchBar {...toolkitprops.searchProps} /> */}
//                                                                {/* <i className="bx bx-search-alt search-icon" /> */}
//                                                            </div>
//                                                             </div>
//                                                          </Col>
//                                                        <Col sm="9" >
//                                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
//                                                               <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20, position: 'relative', }} >
//                                                               <div className="select-container me-2">
//                                                                   <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
//                                                                     <option value="">Select User Group</option>
//                                                                  <option value="all">All</option>
//                                                                    {
//                                                                       this.state.labelData.map((data, idx) => {
//                                                                         return (
//                                                                           <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
//                                                                         )
//                                                                       })
//                                                                     }

//                                                                   </select>
//                                                                 </div>
//                                                                 <div>
//                                                                   <Button color={"primary"} outline className="btn-md me-2 " style={{ padding: '7px' }}
//                                                                     onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadendpoint", editItem: null })}
//                                                                   >
//                                                                     Load locations
//                                                                   </Button>
//                                                                   <Button color={"primary"} outline className="btn-sm me-2" style={{ padding: '7px' }}
//                                                                     onClick={() => this.setState({ open: this.state.open ? false : true, component: "endpoint", editItem: null })}
//                                                                   >
//                                                                     Add new location
//                                                                   </Button>
//                                                                   <Button color={"danger"} outline className="btn-sm" style={{ padding: '7px' }}
//                                                                     onClick={() => { this.deleteAllRequest() }}
//                                                                   >
//                                                                     Delete all locations
//                                                                   </Button>

//                                                                 </div>
//                                                               </div>
//                                                             </div>
//                                                           </Col>
//                                                         </Row>
//                                                         <Row>
//                                                           <div className="form-check form-switch form-switch-sm mx-4">
//                                                             <input
//                                                               type="checkbox"
//                                                               className="form-check-input"
//                                                               id={"customreview"}
//                                                               onChange={(e) => { this.onchangeCustomEnableReview(e) }}
//                                                               checked={this.state.customEnableReview}
//                                                             />
//                                                             <label className={"form-check-label text-primary"} htmlFor={"customreview"}>
//                                                               Enable Review
//                                                             </label>
//                                                           </div>
//                                                         </Row>
//                                             {/* <div>
//                                               <Button color={"primary"} outline className="btn-md me-2 " style={{ padding: '7px' }}
//                                                 onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadendpoint", editItem: null })}
//                                               >
//                                                 Load locations
//                                               </Button>
//                                               <Button color={"primary"} outline className="btn-sm me-2" style={{ padding: '7px' }}
//                                                 onClick={() => this.setState({ open: this.state.open ? false : true, component: "endpoint", editItem: null })}
//                                               >
//                                                 Add new location
//                                               </Button>
//                                               <Button color={"danger"} outline className="btn-sm" style={{ padding: '7px' }}
//                                                 onClick={() => { this.deleteAllRequest() }}
//                                               >
//                                                 Delete all locations
//                                               </Button>

//                                             </div> */}
//                                             <TableContainer
//                                               columns={columns}
//                                               data={this.state.publishTemplate.endpoints}
//                                               // isGlobalFilter={true}
//                                               isAddOptions={false}
//                                               isJobListGlobalFilter={false}
//                                               customPageSize={10}
//                                               style={{ width: '100%' }}
//                                               // dynamicBtn={true}
//                                               // btnClick={(param1, param2) => this.navigateTo("", 1)}
//                                               // iscustomPageSizeOptions={true}
//                                               // dynamicParams={params1,params2}
//                                               isPagination={true}
//                                               filterable={false}
//                                               // btnName={"Add User"}
//                                               tableClass="align-middle table-nowrap table-check"
//                                               theadClass="table-light"
//                                               pagination="pagination pagination-rounded justify-content-end mb-2"

//                                             />
//                                                <Row>
//                                                        <Col md={11} className='text-end'>
//                                                         </Col>
//                                                          <Col md={1}>
//                                                             <Button className={this.state.cc_level === 0 ? "btn btn-secondary" : "btn btn-success"} disabled={this.state.cc_level === 0} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
//                                                          </Col>
//                                                     </Row>
//                                                    <br />
//                                                        <Row>
//                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
//                                                              <Button color={"danger"}
//                                                               onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method
//                                                            </Button>
//                                                           </div>
//                                                          </Row>
//                                           </>


//                                           // <PaginationProvider
//                                           //   keyField="id"
//                                           //   data={this.state.publishTemplate.endpoints}
//                                           //   columns={columns}
//                                           //   pagination={paginationFactory(options)}
//                                           // >
//                                           //   {
//                                           //     ({
//                                           //       paginationProps,
//                                           //       paginationTableProps
//                                           //     }) => (
//                                           //       <ToolkitProvider
//                                           //         keyField="id"
//                                           //         data={this.state.publishTemplate.endpoints}
//                                           //         columns={columns}
//                                           //         search
//                                           //       >
//                                           //         {
//                                           //           toolkitprops => (
//                                           //             <React.Fragment>
//                                           //               <Row className="mb-2" style={{ paddingTop: 10 }}>
//                                           //                 <Col sm="3">
//                                           //                   <div className="search-box ms-2 mb-2 d-inline-block">
//                                           //                     <div className="position-relative">
//                                           //                       <SearchBar {...toolkitprops.searchProps} />
//                                           //                       <i className="bx bx-search-alt search-icon" />
//                                           //                     </div>
//                                           //                   </div>
//                                           //                 </Col>
//                                           //                 <Col sm="9" >
//                                           //                   <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
//                                           //                     <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20, position: 'relative', }} >
//                                           //                       <div className="select-container me-2">
//                                           //                         <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
//                                           //                           <option value="">Select User Group</option>
//                                           //                           <option value="all">All</option>
//                                           //                           {
//                                           //                             this.state.labelData.map((data, idx) => {
//                                           //                               return (
//                                           //                                 <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
//                                           //                               )
//                                           //                             })
//                                           //                           }

//                                           //                         </select>
//                                           //                       </div>
//                                           //                       <div>
//                                           //                         <Button color={"primary"} outline className="btn-md me-2 " style={{ padding: '7px' }}
//                                           //                           onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadendpoint", editItem: null })}
//                                           //                         >
//                                           //                           Load locations
//                                           //                         </Button>
//                                           //                         <Button color={"primary"} outline className="btn-sm me-2" style={{ padding: '7px' }}
//                                           //                           onClick={() => this.setState({ open: this.state.open ? false : true, component: "endpoint", editItem: null })}
//                                           //                         >
//                                           //                           Add new location
//                                           //                         </Button>
//                                           //                         <Button color={"danger"} outline className="btn-sm" style={{ padding: '7px' }}
//                                           //                           onClick={() => { this.deleteAllRequest() }}
//                                           //                         >
//                                           //                           Delete all locations
//                                           //                         </Button>

//                                           //                       </div>
//                                           //                     </div>
//                                           //                   </div>
//                                           //                 </Col>
//                                           //               </Row>
//                                           //               <Row>
//                                           //                 <div className="form-check form-switch form-switch-sm mx-4">
//                                           //                   <input
//                                           //                     type="checkbox"
//                                           //                     className="form-check-input"
//                                           //                     id={"customreview"}
//                                           //                     onChange={(e) => { this.onchangeCustomEnableReview(e) }}
//                                           //                     checked={this.state.customEnableReview}
//                                           //                   />
//                                           //                   <label className={"form-check-label text-primary"} htmlFor={"customreview"}>
//                                           //                     Enable Review
//                                           //                   </label>
//                                           //                 </div>
//                                           //               </Row>
//                                           //               <hr />
//                                           //               <Row>
//                                           //                 <Col xl="12">
//                                           //                   <div className="table-responsive">
//                                           //                     <BootstrapTable
//                                           //                       {...toolkitprops.baseProps}
//                                           //                       {...paginationTableProps}
//                                           //                       defaultSorted={defaultSorted}
//                                           //                       classes={
//                                           //                         "table align-middle table-nowrap table-hover"
//                                           //                       }
//                                           //                       bordered={false}
//                                           //                       striped={false}
//                                           //                       responsive
//                                           //                       onTableChange={this.handleTableChange}
//                                           //                     />
//                                           //                   </div>
//                                           //                 </Col>
//                                           //               </Row>
//                                           //               <Row className="align-items-md-center mt-30">
//                                           //                 <Col className="pagination pagination-rounded justify-content-end mb-2">
//                                           //                   <PaginationListStandalone
//                                           //                     {...paginationProps}
//                                           //                   />
//                                           //                 </Col>
//                                           //               </Row>
//                                           //               <Row>
//                                           //                 <Col md={11} className='text-end'>
//                                           //                 </Col>
//                                           //                 <Col md={1}>
//                                           //                   <Button className={this.state.cc_level === 0 ? "btn btn-secondary" : "btn btn-success"} disabled={this.state.cc_level === 0} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
//                                           //                 </Col>
//                                           //               </Row>
//                                           //               <br />
//                                           //               <Row>
//                                           //                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
//                                           //                   <Button color={"danger"}
//                                           //                     onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method
//                                           //                   </Button>
//                                           //                 </div>
//                                           //               </Row>
//                                           //             </React.Fragment>
//                                           //           )}
//                                           //       </ToolkitProvider>
//                                           //     )}
//                                           // </PaginationProvider> 
//                                           : null
//                                       }

//                                     </div>
//                                   </TabPane>

//                                   <TabPane
//                                     tabId={2}
//                                     id="v-pills-confir"
//                                     role="tabpanel">
//                                     <div>
//                                       <div className='mb-3'>
//                                         <span className='font-size-17'>Configuration</span>
//                                       </div>

//                                       <hr className="my-4" />

//                                       <div>
//                                         <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>
//                                         <p className="card-title-desc">
//                                           Use the <code>.badge-pill</code> modifier className to
//                                           make badges more rounded (with a larger{" "}
//                                           <code>border-radius</code>
//                                           and additional horizontal <code>padding</code>).
//                                           Useful if you miss the badges from v3.
//                                         </p>
//                                         <div className="mb-3 col-6">
//                                           <select
//                                             type="select"
//                                             name="repeat_mode"
//                                             label="Name"
//                                             value={this.state.selectedRepeatMode}
//                                             className="form-select"
//                                             id="review_mode_level"
//                                             required
//                                             onChange={(e) => this.selectRepeatMode(e)}
//                                           >
//                                             <option value="choose" disabled>Choose...</option>
//                                             {
//                                               this.state.repeatModeData.map((data, idx) => {
//                                                 return (
//                                                   <option value={String(idx)} selected key={idx}>{data.mode}</option>
//                                                 )
//                                               })
//                                             }
//                                           </select>
//                                         </div>
//                                       </div>

//                                       <div>
//                                         {this.repeatModeComponent(this.state.selectedRepeatMode)}
//                                       </div>
//                                       <Row>
//                                         <Col md={11} className='text-end'>
//                                           <Button color="primary" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button>
//                                         </Col>
//                                         <Col md={1}>
//                                           <Button color="success" disabled={this.state.cc_level == 2} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
//                                         </Col>
//                                       </Row>
//                                     </div>
//                                   </TabPane>

//                                   <TabPane
//                                     tabId={3}
//                                     id="v-pills-confir"
//                                     role="tabpanel">
//                                     <div>
//                                       <div className='mb-2'>
//                                         <span className='font-size-17'>Confirmation</span> <br />
//                                         <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>
//                                       </div>

//                                       <Row className="justify-content-center">
//                                         <Col xl={10}>

//                                           <div className="mt-2">
//                                             <div className="d-flex flex-wrap">
//                                               <div className="me-2">
//                                                 <span className="me-2 font-size-18">Locations</span> <span
//                                                   onClick={() => {
//                                                     this.toggleCustomTab(1)
//                                                   }}
//                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                   edit
//                                                 </span>

//                                               </div>
//                                               <div className="ms-auto">
//                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                               </div>
//                                             </div>
//                                             <hr className="mt-2" />

//                                             <div className="list-group list-group-flush">
//                                               <div
//                                                 to="#"
//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Total Locations - <span className="text-success">{this.state.publishTemplate.endpoints.length}</span>
//                                               </div>
//                                             </div>
//                                           </div>


//                                           <div className="mt-5">
//                                             <div className="d-flex flex-wrap">
//                                               <div className="me-2">
//                                                 <span className="me-2 font-size-18">Configuration</span>
//                                                 <span
//                                                   onClick={() => {
//                                                     this.toggleCustomTab(2)
//                                                   }}
//                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                   edit
//                                                 </span>
//                                               </div>
//                                               <div className="ms-auto">
//                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                               </div>
//                                             </div>
//                                             <hr className="mt-2" />

//                                             <div className="list-group list-group-flush">

//                                               <div
//                                                 to="#"
//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Repeat Mode - <span className="text-success">{this.state.publishTemplate.repeat_mode}</span>
//                                               </div>
//                                               <div to="#"
//                                                 className="list-group-item text-muted d-flex">
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Display &nbsp;&nbsp;
//                                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                   <div>Audit Score:</div>&nbsp;&nbsp;
//                                                   <input type={"checkbox"} name='audit_score_preview' checked={this.state.publishTemplate.audit_score_preview === true ? true : false} onChange={(e) => this.handle_click(e)} />&nbsp;&nbsp;
//                                                 </div>
//                                                 {
//                                                   this.state.customEnableReview === true &&
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Review Score:</div>&nbsp;&nbsp;
//                                                     <input type={"checkbox"} name="review_score_preview" checked={this.state.publishTemplate.review_score_preview === true ? true : false} onChange={(e) => this.handle_click(e)} />
//                                                   </div>
//                                                 }


//                                               </div>

//                                               <div to="#"
//                                                 className="list-group-item text-muted d-flex">
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Track Location &nbsp;&nbsp;
//                                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                   <div>Auditor :</div>&nbsp;&nbsp;
//                                                   <input type={"checkbox"} name='audit_coords_enable' checked={this.state.publishTemplate.audit_coords_enable === true ? true : false} onChange={(e) => this.handle_click(e)} />&nbsp;&nbsp;
//                                                 </div>
//                                                 {
//                                                   this.state.customEnableReview === true &&
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Reviewer :</div>&nbsp;&nbsp;
//                                                     <input type={"checkbox"} name="review_coords_enable"  checked={this.state.publishTemplate.review_coords_enable === true ? true : false} onChange={(e) => this.handle_click(e)} />
//                                                   </div>
//                                                 }

//                                               </div>

//                                               <div
//                                                 to="#"
//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Publish on <span className="text-danger">*</span>
//                                                 <input
//                                                   className="form-control"
//                                                   type="date"
//                                                   min={this.state.fromate_date}
//                                                   selected={this.state.ptl_startdate}
//                                                   defaultValue={this.state.ptl_startdate}
//                                                   id="start_date"
//                                                   onChange={event => this.onChangeStartDate(event)}
//                                                   onKeyDown={(e)=>{e.preventDefault()}}
//                                                 />
//                                               </div>

//                                               <div
//                                                 to="#"
//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 Audit End on <span className="text-danger">*</span>
//                                                 <input
//                                                   className="form-control"
//                                                   type="date"
//                                                   min={this.state.ptl_startdate}
//                                                   value={this.state.ptl_startdate > this.state.ptl_enddate ? "" : this.state.ptl_enddate}
//                                                   selected={this.state.ptl_enddate}
//                                                   defaultValue={this.state.ptl_enddate}
//                                                   max={this.calculateMaxDate()}
//                                                   disabled={this.state.ptl_startdate == '' || this.state.ptl_startdate == undefined}
//                                                   id="end_date"
//                                                   onChange={event => this.onChangeEndDate(event)}
//                                                   onKeyDown={(e)=>{e.preventDefault()}}
//                                                 />
//                                               </div>



//                                             </div>
//                                           </div>

//                                           <div className="mt-5">
//                                             <div className="d-flex flex-wrap">
//                                               <div className="me-2">
//                                                 <span className="me-2 font-size-18">Check points</span> <span
//                                                   onClick={() => this.navigateTo(this.state.publishTemplate)}
//                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                   edit
//                                                 </span>
//                                               </div>
//                                               <div className="ms-auto">
//                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                               </div>
//                                             </div>
//                                             <hr className="mt-2" />

//                                             <div className="list-group list-group-flush">
//                                               <div

//                                                 className="list-group-item text-muted"
//                                               >
//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                 No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>
//                                               </div>

//                                             </div>
//                                           </div>

//                                         </Col>
//                                       </Row>
//                                       <Row>
//                                         <div className="wizard clearfix">
//                                           <div className="actions clearfix">
//                                             <Button color="primary" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button>
//                                             <button className='btn btn-primary mx-2' onClick={() => { window.location.href = "/mngpblhtempt" }}> Save </button>
//                                             <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>
//                                           </div>
//                                           <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>
//                                         </div>
//                                       </Row>

//                                     </div>

//                                   </TabPane>

//                                 </TabContent>
//                               </CardBody>
//                             </Card>
//                           </AvForm>
//                         </Col>

//                       </Row>
//                     </div>



//                   </div> :
//                   this.state.methodSelected === "2" ?
//                     <div>
//                       <div className="checkout-tabs">
//                         <Row>

//                           <Col lg="2" sm="3">
//                             <Nav className="flex-column" pills>
//                               <NavItem>
//                                 <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f' }}
//                                   className={classnames({
//                                     active: this.state.activeTab === 1 && this.state.cc_level == 0,
//                                     activechange: this.state.activeTab === 1 && this.state.cc_level > 0
//                                   })

//                                   }
//                                   onClick={() => {
//                                     this.toggleTab(1)
//                                   }}
//                                 >
//                                   <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />
//                                   <p className="font-weight-bold mb-4">Hirerachy Level</p>
//                                 </NavLink>
//                               </NavItem>
//                               <NavItem>
//                                 <NavLink style={{ border: this.state.cc_level == 0 ? "0px" : this.state.cc_level > 1 ? '1px solid #34c38f' : '1px solid #556ee6' }}
//                                   className={classnames({
//                                     active: this.state.activeTab === 2 && this.state.cc_level > 0,
//                                     activechange: this.state.activeTab === 2 && this.state.cc_level > 1
//                                   })}
//                                   onClick={() => {
//                                     this.toggleTab(2);
//                                   }}
//                                   disabled={this.state.cc_level > 0 ? false : true}
//                                 >
//                                   <i className="bx bxs-user d-block check-nav-icon mt-4 mb-2" />
//                                   <p className="font-weight-bold mb-4">Audit / Review</p>
//                                 </NavLink>
//                               </NavItem>
//                               <NavItem>
//                                 <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6' }}
//                                   className={classnames({
//                                     active: this.state.activeTab === 3 && this.state.cc_level > 1,
//                                     activechange: this.state.activeTab === 3 && this.state.cc_level > 2
//                                   })}
//                                   onClick={() => {
//                                     this.toggleTab(3)
//                                   }}
//                                   disabled={this.state.cc_level > 1 ? false : true}
//                                 >
//                                   <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />
//                                   <p className="font-weight-bold mb-4">Configuration</p>
//                                 </NavLink>
//                               </NavItem>
//                               <NavItem >
//                                 <NavLink style={{ border: this.state.cc_level < 3 ? "0px" : this.state.cc_level === 3 ? '1px solid #556ee6' : '1px solid #34c38f' }}
//                                   className={classnames({
//                                     active: this.state.activeTab === 4 && this.state.cc_level > 2,
//                                     activechange: this.state.activeTab === 4 && this.state.cc_level > 3
//                                   })}
//                                   onClick={() => {
//                                     this.toggleTab(4)
//                                   }}
//                                   disabled={this.state.cc_level > 2 ? false : true}
//                                 >
//                                   <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
//                                   <p className="font-weight-bold mb-4">Confirmation</p>
//                                 </NavLink>
//                               </NavItem>
//                             </Nav>
//                           </Col>
//                           <Col lg="10" sm="9">
//                             <AvForm className="form-horizontal" onValidSubmit={this.crudNode} onInvalidSubmit={this.handleInvalidSubmit}>
//                               <Card>
//                                 <CardBody>
//                                   <TabContent activeTab={this.state.activeTab}>

//                                     <TabPane
//                                       tabId={1}>
//                                       <div>

//                                         <Row className='mb-3'>
//                                           <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
//                                             <div className='col-8'>
//                                               <span className='font-size-17'>Hirerachy Level</span>
//                                             </div>
//                                             <div className='col-4'>
//                                               <button className="btn btn-danger" onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method</button>
//                                             </div>
//                                           </div>
//                                         </Row>

//                                         <div>
//                                           <div>
//                                             <Label className="text-primary" htmlFor="autoSizingSelect">Select Level</Label>
//                                             <p className="card-title-desc">
//                                               Select appropriate hirerachy level from dropdown or {" "}
//                                               <badge className='btn badge badge-soft-primary rounded-pill font-size-12 me-1'>Create New Hirerachy level </badge>
//                                             </p>
//                                             <div className="mb-3 col-6">

//                                               <AvInput
//                                                 type="select"
//                                                 name="hlevel"
//                                                 label="HirerachLevel"
//                                                 value={this.state.selectedHLevelValue}
//                                                 className="form-select"
//                                                 id="hlevel1"
//                                                 required
//                                                 onChange={(e) => this.selectLevel(e)}>
//                                                 <option value="0" defaultValue disabled >Choose...</option>
//                                                 {
//                                                   this.state.dataSource?.map((data, idx) => {
//                                                     return (
//                                                       <option value={String(idx + 1)} selected key={idx}>{data.hname}</option>
//                                                     )
//                                                   })
//                                                 }
//                                               </AvInput>
//                                             </div>
//                                           </div>

//                                           <hr className="my-4" />
//                                           {
//                                             this.state.hlevelIsSelected ?
//                                               <div>
//                                                 <div to="#"
//                                                   className="list-group-item text-primary d-flex">
//                                                   Select Hirerachy Type: &nbsp;&nbsp;
//                                                 </div>
//                                                 <div style={{ display: 'flex', alignItems: 'center', marginLeft: '19px' }} className='mt-3'>
//                                                   <input type={"radio"} checked={this.state.publishTemplate.hirearchy_type == "1" ? true : false} onChange={(e) => { this.hierarchyType("1") }} />&nbsp;&nbsp;<div onChange={(e) => { this.hierarchyType("1") }} >Organised Structure</div>&nbsp;&nbsp;
//                                                   <input type={"radio"} checked={this.state.publishTemplate.hirearchy_type == "2" ? true : false} onChange={(e) => { this.hierarchyType("2") }} />&nbsp;&nbsp;
//                                                   <div onChange={(e) => { this.hierarchyType("2") }} >Un-Organised Structure</div>&nbsp;&nbsp;
//                                                 </div>
//                                               </div>
//                                               : null
//                                           }
//                                           <hr className="my-4" />
//                                           {
//                                             this.state.publishTemplate.hirearchy_type == "1" && this.state.hlevelIsSelected ?
//                                               <div >
//                                                 <Label className="text-primary" htmlFor="autoSizingSelect">Select Location</Label>
//                                                 <p className="card-title-desc">
//                                                   Use the <code>.badge-pill</code> modifier className to
//                                                   make badges more rounded (with a larger{" "}
//                                                   <code>border-radius</code>
//                                                   and additional horizontal <code>padding</code>).
//                                                   Useful if you miss the badges from v3.
//                                                 </p>
//                                                 <div className="mb-3 col-6">
//                                                   <select
//                                                     type="select"
//                                                     name="location"
//                                                     label="Name"
//                                                     value={this.state.selectedEndpoint}
//                                                     className="form-select"
//                                                     id="location"
//                                                     required
//                                                     onChange={(e) => this.selectLocation(e)}>
//                                                     <option value="choose" disabled >Choose...</option>
//                                                     {
//                                                       this.state.hlevelData.map((data, idx) => {
//                                                         return (
//                                                           <option value={String(idx + 1)} selected key={idx}>{data.hlevel}</option>
//                                                         )
//                                                       })
//                                                     }
//                                                   </select>

//                                                 </div></div>
//                                               :
//                                               null
//                                           }

//                                           {this.state.hlevelData.length > 0 && this.state.publishTemplate.hirearchy_type == "1" ? <hr className="my-4" /> : null}
//                                           {
//                                             this.state.endpointSelected && this.state.publishTemplate.hirearchy_type == "1" ?
//                                               <div>
//                                                 <Label className="text-primary" htmlFor="autoSizingSelect">Select {this.state.hlevelSelected}</Label>
//                                                 <p className="card-title-desc">
//                                                   Use the <code>.badge-pill</code> modifier className to
//                                                   make badges more rounded (with a larger{" "}
//                                                   <code>border-radius</code>
//                                                   and additional horizontal <code>padding</code>).
//                                                   Useful if you miss the badges from v3.
//                                                 </p>
//                                                 <Tree
//                                                   style={{ borderColor: '#150101' }}
//                                                   defaultExpandAll={true}
//                                                   treeData={treeNodes}
//                                                   checkable
//                                                   checkedKeys={this.state.checkedKeys}
//                                                   onCheck={(checked, event) => { this.onCheck(checked, event) }}
//                                                   showLine={true}
//                                                   showIcon={true}
//                                                 />




//                                               </div>
//                                               :
//                                               this.state.publishTemplate.hirearchy_type == "2" ?
//                                                 <div>
//                                                   <Label className="text-primary" htmlFor="autoSizingSelect">Select Location</Label>
//                                                   <p className="card-title-desc">
//                                                     Use the <code>.badge-pill</code> modifier className to
//                                                     make badges more rounded (with a larger{" "}
//                                                     <code>border-radius</code>
//                                                     and additional horizontal <code>padding</code>).
//                                                     Useful if you miss the badges from v3.
//                                                   </p>
//                                                   <Tree
//                                                     style={{ borderColor: '#150101' }}
//                                                     defaultExpandAll={true}
//                                                     treeData={treeNodes}
//                                                     checkable
//                                                     checkedKeys={this.state.checkedKeys}
//                                                     onCheck={(checked, event) => { this.onCheck(checked, event, "2") }}
//                                                     showLine={true}
//                                                     showIcon={true}
//                                                     checkStrictly={true}
//                                                   />
//                                                 </div>
//                                                 :
//                                                 null
//                                           }


//                                         </div>

//                                       </div>
//                                     </TabPane>
//                                           {/* {
//                                             //.log(this.state.publishTemplate,'publish')
//                                           } */}
//                                     <TabPane
//                                       tabId={2}
//                                       id="v-pills-confir"
//                                       role="tabpanel">
//                                       <div>
//                                         <div className='mb-3'>
//                                           <span className='font-size-17'>Audit & Review</span>
//                                         </div>

//                                         <div>
//                                           <Label className="text-primary" htmlFor="autoSizingSelect">Select audit user level</Label>
//                                           <p className="card-title-desc">
//                                             Use the <code>.badge-pill</code> modifier className to
//                                             make badges more rounded (with a larger <code>border-radius</code>
//                                             and additional horizontal <code>padding</code>).
//                                             Useful if you miss the badges from v3.
//                                           </p>
//                                           <div className="row">
//                                             <div className="mb-3 col-6">
//                                               <select
//                                                 type="select"
//                                                 name="audit_userlevel"
//                                                 label="Name"
//                                                 value={this.state.publishTemplate.audit_userlevel_value == null ? "choose" : String(this.state.publishTemplate.audit_userlevel_value)}
//                                                 className="form-select"
//                                                 id="audit_user_level"
//                                                 required
//                                                 onChange={(e) => this.selectAuditUserlevel(e)}
//                                               >
//                                                 <option value="choose" disabled>Choose...</option>
//                                                 {
//                                                   this.state.hirerachy_user_level.map((data, idx) => {
//                                                     return (
//                                                       <option value={data.cat_type} key={idx}>{data.hirerachy_name}</option>
//                                                     )
//                                                   })
//                                                 }
//                                               </select>
//                                             </div>
//                                             {/* <div className="mb-3 col-6">
//                                               <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
//                                                 <option value="">Select User Group</option>
//                                                 <option value="all">All</option>
//                                                 {this.state.labelData.map((data, idx) => {
//                                                   return (
//                                                     <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
//                                                   )
//                                                 })}
//                                               </select>
//                                             </div> */}
//                                           </div>
//                                         </div>

//                                         <hr className="my-4" />

//                                         <div className="form-check form-switch form-switch-sm my-3">
//                                           <input
//                                             type="checkbox"
//                                             className="form-check-input"
//                                             id={"review"}
//                                             onChange={(e) => { this.onchangeEnableReview(e) }}
//                                             checked={this.state.enableReview}
//                                           />
//                                           <label className={"form-check-label text-primary"} htmlFor={"review"}>
//                                             Enable Review
//                                           </label>
//                                         </div>


//                                         {this.state.enableReview ?
//                                           <div>
//                                             <Label className="text-primary" htmlFor="autoSizingSelect">Select review user level</Label>
//                                             <p className="card-title-desc">
//                                               Use the <code>.badge-pill</code> modifier className to
//                                               make badges more rounded (with a larger{" "}
//                                               <code>border-radius</code>
//                                               and additional horizontal <code>padding</code>).
//                                               Useful if you miss the badges from v3.
//                                             </p>
//                                             <div className="mb-3 col-6">
//                                               <select
//                                                 type="select"
//                                                 name="review_userlevel"
//                                                 label="Name"
//                                                 value={this.state.publishTemplate.review_userlevel == null ? "choose" : String(this.state.publishTemplate.review_userlevel)}
//                                                 className="form-select"
//                                                 id="review_user_level"
//                                                 required
//                                                 onChange={(e) => this.selectReviewUserlevel(e)}
//                                               >
//                                                 <option value="choose" disabled>Choose...</option>
//                                                 {
//                                                   this.state.hirerachy_user_level.map((data, idx) => {
//                                                     return (
//                                                       <option value={data.cat_type} key={idx}>{data.hirerachy_name}</option>
//                                                     )
//                                                   })
//                                                 }
//                                               </select>
//                                             </div>
//                                             <hr className="my-4" />
//                                           </div>
//                                           : null}

//                                         {
//                                           this.state.auditUserlevelSelected ?
//                                             <div>
//                                               <TableContainer
//                                                 columns={columns}
//                                                 data={this.state.publishTemplate.endpoints}
//                                                 isGlobalFilter={true}
//                                                 isAddOptions={false}
//                                                 isJobListGlobalFilter={false}
//                                                 customPageSize={10}
//                                                 style={{ width: '100%' }}
//                                                 // dynamicBtn={true}
//                                                 // btnClick={(param1, param2) => this.navigateTo("", 1)}
//                                                 // iscustomPageSizeOptions={true}
//                                                 // dynamicParams={params1,params2}
//                                                 isPagination={true}
//                                                 filterable={false}
//                                                 // btnName={"Add User"}
//                                                 tableClass="align-middle table-nowrap table-check"
//                                                 theadClass="table-light"
//                                                 pagination="pagination pagination-rounded justify-content-end mb-2"

//                                               />
//                                               {/* <PaginationProvider
//                                                 keyField="id"
//                                                 data={this.state.publishTemplate.endpoints}
//                                                 columns={columns}
//                                                 pagination={paginationFactory(options)}
//                                               >
//                                                 {
//                                                   ({
//                                                     paginationProps,
//                                                     paginationTableProps
//                                                   }) => (
//                                                     <ToolkitProvider
//                                                       keyField="id"
//                                                       data={this.state.publishTemplate.endpoints}
//                                                       columns={columns}
//                                                       search
//                                                     >
//                                                       {
//                                                         toolkitprops => (
//                                                           <React.Fragment>
//                                                             <Row className="mb-2" style={{ paddingTop: 10 }}>
//                                                               <Col sm="4">
//                                                                 <div className="search-box ms-2 mb-2 d-inline-block">
//                                                                   <div className="position-relative">
//                                                                     <SearchBar {...toolkitprops.searchProps} />
//                                                                     <i className="bx bx-search-alt search-icon" />
//                                                                   </div>
//                                                                 </div>
//                                                               </Col>
//                                                             </Row>
//                                                             <Row>
//                                                               <Col xl="12">
//                                                                 <div className="table-responsive">
//                                                                   <BootstrapTable
//                                                                     {...toolkitprops.baseProps}
//                                                                     {...paginationTableProps}
//                                                                     defaultSorted={defaultSorted}
//                                                                     classes={
//                                                                       "table align-middle table-nowrap table-hover"
//                                                                     }
//                                                                     bordered={false}
//                                                                     striped={false}
//                                                                     responsive
//                                                                     onTableChange={this.handleTableChange}
//                                                                   />
//                                                                 </div>
//                                                               </Col>
//                                                             </Row>
//                                                             <Row className="align-items-md-center mt-30">
//                                                               <Col className="pagination pagination-rounded justify-content-end mb-2">
//                                                                 <PaginationListStandalone
//                                                                   {...paginationProps}
//                                                                 />
//                                                               </Col>
//                                                             </Row>
//                                                           </React.Fragment>
//                                                         )}
//                                                     </ToolkitProvider>
//                                                   )}
//                                               </PaginationProvider> */}
//                                             </div> 
//                                             : null
//                                         }

//                                       </div>
//                                     </TabPane>

//                                     <TabPane
//                                       tabId={3}
//                                       id="v-pills-config"
//                                       role="tabpanel"
//                                       aria-labelledby="v-pills-config-tab"
//                                     >
//                                       <div>
//                                         <div className='mb-3'>
//                                           <span className='font-size-17'>Configuration</span>
//                                         </div>

//                                         <hr className="my-4" />

//                                         <div>
//                                           <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>
//                                           <p className="card-title-desc">
//                                             Use the <code>.badge-pill</code> modifier className to
//                                             make badges more rounded (with a larger{" "}
//                                             <code>border-radius</code>
//                                             and additional horizontal <code>padding</code>).
//                                             Useful if you miss the badges from v3.
//                                           </p>
//                                           <div className="mb-3 col-6">
//                                             <select
//                                               type="select"
//                                               name="repeat_mode"
//                                               label="Name"
//                                               value={this.state.selectedRepeatMode}
//                                               className="form-select"
//                                               id="review_mode_level"
//                                               required
//                                               onChange={(e) => this.selectRepeatMode(e)}
//                                             >
//                                               <option value="choose" disabled>Choose...</option>
//                                               {
//                                                 this.state.repeatModeData.map((data, idx) => {
//                                                   return (
//                                                     <option value={String(idx)} selected key={idx}>{data.mode}</option>
//                                                   )
//                                                 })
//                                               }
//                                             </select>
//                                           </div>
//                                         </div>

//                                         <div>
//                                           {this.repeatModeComponent(this.state.selectedRepeatMode)}
//                                         </div>

//                                       </div>
//                                     </TabPane>

//                                     <TabPane
//                                       tabId={4}
//                                       id="v-pills-confir"
//                                       role="tabpanel">
//                                       <div>
//                                         <div className='mb-2'>
//                                           <span className='font-size-17'>Confirmation</span> <br />
//                                           <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>
//                                         </div>

//                                         <Row className="justify-content-center">
//                                           <Col xl={10}>

//                                             <div className="mt-2">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Hirerach Level</span> <span
//                                                     onClick={() => {
//                                                       this.toggleTab(1)
//                                                     }}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                     edit
//                                                   </span>

//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Hirerachy level - <span className="text-success">{this.state.publishTemplate.hlevel}</span>
//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Endpoint - <span className="text-success">{this.state.publishTemplate.ep_level} </span>
//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   No. of Endpoints selected - <span className="text-success">{this.state.publishTemplate.ep_selected.length}</span>
//                                                 </div>
//                                               </div>
//                                             </div>

//                                             {/* <div className="mt-5">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Audit / Review</span> <span
//                                                     onClick={() => {
//                                                       this.toggleTab(2)
//                                                     }}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                     edit
//                                                   </span>
//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Audit user level - <span className="text-success">{this.state.publishTemplate.audit_userlevel}</span>
//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Review user level - {this.state.publishTemplate.review_userlevel == null ? <span className="text-danger"> No Review </span> : <span className="text-success">{this.state.publishTemplate.review_userlevel}</span>}
//                                                 </div>

//                                               </div>
//                                               <div  to="#"
//                                                 className="list-group list-group-flush">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Display &nbsp;&nbsp;
//                                                   <div style={{display: 'flex', alignItems: 'center'}}>
//                                                   <div>Audit Score:</div>&nbsp;&nbsp;
//                                                   <input type={"checkbox"} name='audit_score_preview' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;
//                                                   </div>
//                                                 {
//                                                   this.state.customEnableReview === true &&
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Review Score:</div>&nbsp;&nbsp;
//                                                     <input type={"checkbox"} name="review_score_preview" onChange={(e) => this.handle_click(e)} />
//                                                   </div>
//                                                 }


//                                               </div>
//                                             </div> */}


//                                             <div className="mt-5">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Audit / Review</span>
//                                                   <span
//                                                     onClick={() => {
//                                                       this.toggleTab(2);
//                                                     }}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1"
//                                                   >
//                                                     edit
//                                                   </span>
//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div to="#" className="list-group-item text-muted">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Audit user level -{" "}
//                                                   <span className="text-success">
//                                                     {this.state.publishTemplate.audit_userlevel}
//                                                   </span>
//                                                 </div>

//                                                 <div to="#" className="list-group-item text-muted">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Review user level -{" "}
//                                                   {this.state.publishTemplate.review_userlevel == null ? (
//                                                     <span className="text-danger"> No Review </span>
//                                                   ) : (
//                                                     <span className="text-success">
//                                                       {this.state.publishTemplate.review_userlevel}
//                                                     </span>
//                                                   )}
//                                                 </div>

//                                                 <div to="#" className="list-group-item text-muted d-flex gap-3">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Display:{" "}
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Audit Score:</div>&nbsp;&nbsp;
//                                                     <input
//                                                       type={"checkbox"}
//                                                       name="audit_score_preview"
//                                                       checked={this.state.publishTemplate.audit_score_preview === true ? true : false}
//                                                       onChange={(e) => this.handle_click(e)}
//                                                     />
//                                                     &nbsp;&nbsp;
//                                                   </div>
//                                                   {this.state.publishTemplate.review_userlevel !== null && (
//                                                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                       <div>Review Score:</div>&nbsp;&nbsp;
//                                                       <input
//                                                         type={"checkbox"}
//                                                         name="review_score_preview"
//                                                         checked={this.state.publishTemplate.review_score_preview === true ? true : false}
//                                                         onChange={(e) => this.handle_click(e)}
//                                                       />
//                                                     </div>
//                                                   )}
//                                                 </div>

//                                                 <div to="#" className="list-group-item text-muted d-flex gap-3">
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Track Location :{" "}
//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <div>Auditor :</div>&nbsp;&nbsp;
//                                                     <input
//                                                       type={"checkbox"}
//                                                       name="audit_coords_enable"
//                                                       checked={this.state.publishTemplate.audit_coords_enable === true ? true : false}
//                                                       onChange={(e) => this.handle_click(e)}
//                                                     />
//                                                     &nbsp;&nbsp;
//                                                   </div>
//                                                   {this.state.publishTemplate.review_userlevel !== null && (
//                                                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                       <div>Reviewer:</div>&nbsp;&nbsp;
//                                                       <input
//                                                         type={"checkbox"}
//                                                         name="review_coords_enable"
//                                                         checked={this.state.publishTemplate.review_coords_enable === true ? true : false}
//                                                         onChange={(e) => this.handle_click(e)}
//                                                       />
//                                                     </div>
//                                                   )}
//                                                 </div>



//                                               </div>
//                                             </div>




//                                             <div className="mt-5">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Configuration</span> <span
//                                                     onClick={() => {
//                                                       this.toggleTab(3)
//                                                     }}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                     edit
//                                                   </span>
//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Publish on <span className="text-danger">*</span>
//                                                   {/* <input
//                                                     className="form-control"
//                                                     type="date"
//                                                     min={this.state.fromate_date}
//                                                     selected={this.state.ptl_startdate}
//                                                     defaultValue={this.state.ptl_startdate}
//                                                     id="start_date"
//                                                     onChange={event => this.onChangeStartDate(event)}
//                                                   /> */}

//                                                   <input
//                                                     className="form-control"
//                                                     type="date"
//                                                     min={this.state.fromate_date}
//                                                     selected={this.state.ptl_startdate}
//                                                     defaultValue={this.state.ptl_startdate}
//                                                     id="start_date"
//                                                     onChange={event => this.onChangeStartDate(event)}
//                                                     onKeyDown={(e) => { e.preventDefault() }}
//                                                   />






//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Audit End on <span className="text-danger">*</span>
//                                                   {/* <input
//                                                     className="form-control"
//                                                     type="date"
//                                                     min={this.state.fromate_date}
//                                                     selected={this.state.ptl_enddate}
//                                                     defaultValue={this.state.ptl_enddate}
//                                                     id="end_date"
//                                                     onChange={event => this.onChangeEndDate(event)}
//                                                   /> */}
//                                                   <input
//                                                     className="form-control"
//                                                     type="date"
//                                                     min={this.state.ptl_startdate}
//                                                     value={this.state.ptl_startdate > this.state.ptl_enddate ? "" : this.state.ptl_enddate}
//                                                     selected={this.state.ptl_enddate}
//                                                     defaultValue={this.state.ptl_enddate}
//                                                     max={this.calculateMaxDate()}
//                                                     disabled={this.state.ptl_startdate == '' || this.state.ptl_startdate == undefined}
//                                                     onKeyDown={(e) => { e.preventDefault() }}
//                                                     id="end_date"
//                                                     onChange={event => this.onChangeEndDate(event)}
//                                                   />





//                                                 </div>

//                                                 <div
//                                                   to="#"
//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   Frequency - <span className="text-success">{this.state.publishTemplate.frequency}</span>
//                                                 </div>

//                                               </div>
//                                             </div>

//                                             <div className="mt-5">
//                                               <div className="d-flex flex-wrap">
//                                                 <div className="me-2">
//                                                   <span className="me-2 font-size-18">Check points</span> <span
//                                                     onClick={() => this.navigateTo(this.state.publishTemplate)}
//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
//                                                     edit
//                                                   </span>
//                                                 </div>
//                                                 <div className="ms-auto">
//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
//                                                 </div>
//                                               </div>
//                                               <hr className="mt-2" />

//                                               <div className="list-group list-group-flush">
//                                                 <div

//                                                   className="list-group-item text-muted"
//                                                 >
//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
//                                                   No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>
//                                                 </div>

//                                               </div>
//                                             </div>

//                                           </Col>
//                                         </Row>
//                                         <Row>
//                                           <div className="wizard clearfix">
//                                             <div className="actions clearfix">
//                                               <button className='btn btn-primary mx-2'> Save </button>
//                                               <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>
//                                             </div>
//                                             <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>
//                                           </div>
//                                         </Row>
//                                       </div>
//                                     </TabPane>

//                                   </TabContent>
//                                 </CardBody>
//                               </Card>

//                               {/* <Row>
//                         <div className="wizard clearfix">
//                           <div className="actions clearfix">
//                             <ul>
//                               <li
//                                 className={
//                                   this.state.activeTab === 1 
//                                     ? "previous disabled"
//                                     : "previous"
//                                 }
//                               >
//                                 <Link
//                                   to="#"
//                                   onClick={() => {
//                                     this.toggleTab(this.state.activeTab - 1)
//                                   }}
//                                 >
//                                   Previous
//                                 </Link>
//                               </li>
//                               <li
//                                 className={
//                                   this.state.activeTab === 4 
//                                     ? "next disabled"
//                                     : "next"
//                                 }
//                               >
//                                 <Link
//                                   to="#"
//                                   onClick={() => {
//                                     this.toggleTab(this.state.activeTab + 1)
//                                   }}
//                                 >
//                                   Next
//                                 </Link>
//                               </li>
//                             </ul>
//                           </div>
//                         </div>
//                       </Row> */}
//                             </AvForm>
//                           </Col>
//                         </Row>
//                       </div>
//                     </div>
//                     : null
//               }

//             </Container>
//           </div>
//               {
//                 this.state.modal == true && this.state.publishTemplate.method_selected =="2" ?
//               <CrudUserHlvl
//                 modal={this.state.modal}
//                 toggle={this.toggle}
//                 onValidSubmit={this.locationUserCrud}
//                 email_format={this.emai_addrs}
//                 validate_email={this.validate_email}
//                 countries={this.state.client_info.countries}
//                 select_country={this.select_country}
//                 given_email={this.state.given_email}
//                 show_password={this.state.show}
//                 loading={this.state.loading}
//                 hirerachy_user_level={this.state.hirerachy_user_level}
//                 passWordBtn={this.passWordBtn}
//                 country_code_err ={this.state.country_code_err}
//                 email_err = {this.state.invalid_user}
//                 valid_email = {this.state.errorMessage}
//                 category_select ={this.onSelectValues}
//                 onRemove={this.onRemove}
//                 />
//               :
//               null
//               }






//           <ReactDrawer
//             open={this.state.open}
//             className="drawer-open"
//             position={this.state.position}
//             onClose={this.onDrawerClose}
//             style={{ maxHeight: 1000 }}
//             noOverlay={false}
//           >

//             <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>
//               {
//                 this.state.component === "user" ?
//                 // <CrudUserHlvl />
//                 // <>
//                 // </>


//                   <React.Fragment>
//                     <Container fluid>
//                       <Row >
//                         <Col >
//                           <Card className="overflow-hidden">
//                             {
//                               !this.state.formDisabled ?
//                                 <CardBody className="pt-0">
//                                   <div className="p-5">
//                                     <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


//                                       <Row className="my-4">
//                                         <div className="d-sm-flex align-items-center justify-content-between">
//                                           <div className="text-danger font-size-18">User Information</div>

//                                           <button className="btn btn-outline-dark " onClick={() => this.onDrawerClose()}> Close </button>
//                                         </div>
//                                         <hr className="my-4" />
//                                       </Row>

//                                       <Row>
//                                         <Col className="col-6">
//                                           <div className="mb-3" >
//                                             <label>Full Name :<span className="text-danger"> *</span></label>
//                                             <AvField
//                                               name="firstname"
//                                               // label="First Name"
//                                               className="form-control"
//                                               placeholder="Enter Full Name"
//                                               type="text"
//                                               required
//                                             />
//                                           </div>
//                                         </Col>
//                                         <Col className="col-6">
//                                           <div className="mb-3" >
//                                             <label>Email Id :<span className="text-danger"> *</span></label>
//                                             <AvField
//                                               name="email_id"
//                                               onChange={(e) => { this.emai_addrs(e) }}
//                                               onBlur={(e) => { this.validate_email(e, 1) }}
//                                               // label="User Email"
//                                               className="form-control"
//                                               placeholder="Enter User Email"
//                                               type="email"
//                                               required
//                                             />
//                                             {
//                                               this.state.errorMessage &&
//                                               <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>
//                                             }
//                                             {
//                                               this.state.invalid_user &&
//                                               <div className='text-danger' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
//                                             }
//                                           </div>
//                                         </Col>

//                                       </Row>
//                                       <Row>
//                                         <Col className="col-6">
//                                           <div className="mb-3">
//                                             <label>Phone Number:<span className="text-danger"> *</span></label>
//                                             <div className="input-group">
//                                               <div className="input-group-prepend">
//                                                 <select name="countrycode" onChange={(e) => { this.select_country(e) }} className="form-select" required>
//                                                   <option value="">Select</option>
//                                                   {
//                                                     this.state.client_info.countries.map((c, idx) => (
//                                                       <option key={idx} value={c.code}>
//                                                         {c.code}
//                                                       </option>
//                                                     ))
//                                                   }
//                                                 </select>
//                                               </div>
//                                               <div className="flex-grow-1">
//                                                 <AvField
//                                                   name="phone_number"
//                                                   className="form-control"
//                                                   placeholder="Enter Phone number"
//                                                   type="number"
//                                                   onBlur={(e) => { this.validate_email(e, 2) }}
//                                                   validate={{
//                                                     required: { value: true },
//                                                     minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                     maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                   }}
//                                                 />
//                                                 {
//                                                   this.state.invalid_phone_num &&
//                                                   <div className='text-danger' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
//                                                 }
//                                               </div>
//                                             </div>
//                                           </div>
//                                           {
//                                             this.state.country_code_err &&
//                                             <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


//                                           }
//                                         </Col>
//                                       </Row>
//                                       <Row className="my-4">
//                                         <div className="text-danger font-size-18">Login Information</div>
//                                         <hr className="my-4" />
//                                       </Row>

//                                       <Row>
//                                         <Col className="col-6">
//                                           <div className="mb-3">
//                                             <label>Username :<span className="text-danger"> *</span></label>
//                                             <AvField
//                                               name="username"
//                                               // label="Username"
//                                               type="text"
//                                               required
//                                               placeholder="Enter username"
//                                               disabled={true}
//                                               value={this.state.given_email}
//                                             />
//                                           </div>
//                                         </Col>
//                                         <Col className="col-5">
//                                           <div className="mb-3">
//                                             <label>Password :<span className="text-danger"> *</span></label>
//                                             <AvField
//                                               name="password"
//                                               autoComplete="new-password"
//                                               type={this.state.show ? "password" : "text"}
//                                               style={{ width: "105%" }} required

//                                               placeholder="Enter password"

//                                               validate={{
//                                                 required: { value: true, errorMessage: 'Password is required' }, minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' }, pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
//                                               }} />
//                                           </div>
//                                         </Col>
//                                         <Col xl={1} style={{ marginLeft: "-12px", marginTop: "27px" }} >
//                                           <button onClick={() => this.setState({ show: !this.state.show, })} className="btn btn-light " type="button" id="password-addon" >
//                                             <i className={this.state.show ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"} ></i>
//                                           </button>
//                                         </Col>
//                                       </Row>
//                                       <div className="mb-3" >

//                                       </div>
//                                       {
//                                         this.state.alertEnable ?
//                                           <div className="mt-4 d-grid">
//                                             <Alert color={this.state.alertColor}>
//                                               {this.state.message}
//                                             </Alert>
//                                           </div>
//                                           : null
//                                       }


//                                       <div className="mt-4 d-grid">
//                                         <button
//                                           className="btn btn-danger btn-block"
//                                           type="submit"
//                                           disabled={this.state.loading}
//                                         >
//                                           {this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Add User"}
//                                         </button>
//                                       </div>

//                                     </AvForm>
//                                   </div>
//                                 </CardBody> :
//                                 <CardBody>
//                                   <div className="mt-4 text-center">
//                                     <div className="mb-0" style={{ fontSize: 20 }}>
//                                       <span className="text-danger">{this.state.first_name}</span> has added successfully
//                                     </div>
//                                     <div style={{ marginTop: 20 }}>
//                                       <Link className="btn btn-success" onClick={() => this.props.onClose()}>
//                                         Back
//                                       </Link>
//                                     </div>
//                                   </div>
//                                 </CardBody>
//                             }
//                           </Card>

//                         </Col>
//                       </Row>
//                     </Container>
//                   </React.Fragment>






//                   // <AddUserNode
//                   //   onClose={this.onDrawerClose}
//                   //    updtUser = {this.changeAuditUserHandler}
//                   //    getAllUsers = {this.getTemplateMethod}
//                   //   rowData={this.state.emptData}
//                   //   getDidmountFunction={this.getDidmountFunction}
//                   //   didmount = {this.componentDidMount}

//                   // />
//                   : this.state.component === "endpoint" ?
//                     <AddEndpointNode
//                       endpoints={this.state.publishTemplate.endpoints}
//                       publishtemplateInfo={this.state.publishTemplate}
//                       onClose={this.onDrawerClose}
//                       editItem={this.state.editItem}
//                       onCancel={this.onCancelDrawerClose}
//                     /> : this.state.component === "loadendpoint" ?

//                       <LoadEndpoint
//                         userInfo={this.state.userInfo}
//                         publishtemplateInfo={this.state.publishTemplate}
//                         onClose={this.onDrawerClose}

//                       />
//                       :
//                       this.state.component === "import_usr" ?
//                       <ImportUser 
//                       user_data ={this.state.to_import_users}
//                       onClose={this.onDrawerClose}
//                       rowData ={this.state.location_info}
//                       coloumn_type={this.state.coloumn_type}
//                       hstructure={JSON.parse(sessionStorage.getItem("hstructure"))}
//                       updateInFlatCln={this.updateInFlatCln}
//                       />
//                       :
//                       // this.state.component === "loadgroupusers" ?
//                       // <LoadGroupUser
//                       // userInfo={this.state.userInfo}
//                       // endpoint_data ={this.state.endpoint_data}
//                       // publishtemplateInfo={this.state.publishTemplate}
//                       // onClose={this.onDrawerClose}
//                       // />:


//                       null
//               }

//             </div>
//           </ReactDrawer>

//           {this.state.changeMethodConfirmation ? (
//             <SweetAlert
//               title="Are you sure?"
//               warning
//               showCancel
//               confirmBtnBsStyle="success"
//               cancelBtnBsStyle="danger"
//               onConfirm={() => {
//                 this.state.triggerFrom === "hlevel" ? this.resetEndpointMethod() : this.state.triggerFrom === "endpoint" ? this.deleteEndpoint() : this.state.triggerFrom === "deleteall" && this.deleteAllEndpoints()
//               }
//               }
//               onCancel={() =>
//                 this.setState({
//                   changeMethodConfirmation: false,
//                   error_dlg: true,
//                   dynamic_title: "Cancelled",
//                   dynamic_description:
//                     "Your imaginary file is safe :)",
//                 })
//               }
//             >
//               {this.state.triggerFrom === "hlevel" ? "Changing the Add Location method will clear all saved data." : this.state.triggerFrom === "endpoint" ? "Deleting the Location can't revet." : this.state.triggerFrom === "deleteall" ? "Deleteing all Location can't revert" : ""}
//             </SweetAlert>
//           ) : null}
//           {this.state.success_dlg ? (
//             <SweetAlert
//               success
//               title={this.state.dynamic_title}
//               onConfirm={() => this.setState({ success_dlg: false })}
//             >
//               {this.state.dynamic_description}
//             </SweetAlert>
//           ) : null}
//           {this.state.dataAlert ? (
//             <SweetAlert
//               title={this.state.alertMessage}
//               onConfirm={() => this.setState({
//                 dataAlert: false,
//                 publishTemplate: this.state.publishTemplate,
//                 selectedReviewUserlevel: this.state.selectedReviewUserlevel,
//                 reviewUserlevelSelected: true,
//                 cc_level: this.state.cc_level,
//                 ptl_startdate: null,
//                 ptl_enddate: null,
//                 repeatMode: null,
//                 endpoints: this.state.endpoints,
//                 review_userlevel: this.state.review_userlevel,
//                 auditUserlevelSelected: true
//               })}
//             ></SweetAlert>
//           ) : null}

//           {this.state.tmpltPublishProcess ? (
//             <SweetAlert
//               title={this.state.alertMessage}
//               onConfirm={() => this.redirectTo(this.state.processStatus)}
//             ></SweetAlert>
//           ) : null}
//         </React.Fragment>
//       )
//     }
//     else {
//       // console.log("Here comes the else part")
//       return null
//     }
//   }
// }





























// import React, { Component } from "react"
// import MetaTags from 'react-meta-tags';
// import {
//   Container,
//   Row,
//   Col,
//   Table,
//   Input,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   TabPane,
//   Card,
//   Form,
//   FormGroup,
//   Label,
//   CardBody,
//   CardTitle,
//   CardSubtitle,
//   InputGroup,
//   Button,
//   Alert
// } from "reactstrap"
// import Select from "react-select"
// import { Link } from "react-router-dom"
// import facility from '../FacilityScreen'
// import SweetAlert from "react-bootstrap-sweetalert"
// import LoadGroupUser from './Components/LoadGroupUser'
// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// import TableContainer from "../../common/TableContainer";
// // import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// // import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// // import BootstrapTable from "react-bootstrap-table-next"
// import makeAnimated from "react-select/animated"
// import "flatpickr/dist/themes/material_blue.css"
// import Flatpickr from "react-flatpickr"
// import SortableTree, {
//   addNodeUnderParent,
//   removeNodeAtPath,
//   toggleExpandedForAll,
//   getTreeFromFlatData,
//   getNodeAtPath,
//   changeNodeAtPath,
//   getFlatDataFromTree,
//   walk,
//   map, find
// } from 'react-sortable-tree/dist/index.cjs.js';
// import ReactDrawer from 'react-drawer';
// import 'react-drawer/lib/react-drawer.css';
// import classnames from "classnames"

// //import AddNode from "./Components/AddNode"
// import AddUserNode from "./Components/AddUserNode"
// import AddEndpointNode from "./Components/AddEndpointNode"

// //Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"
// import CheckboxTree, {
//   getNode, flattenNodes, deserializeLists, serializeList
// } from 'react-checkbox-tree';
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// import { MdCheckBox, MdCheckBoxOutlineBlank, MdChevronRight, MdKeyboardArrowDown, MdAddBox, MdIndeterminateCheckBox, MdLocationCity, MdStore, MdFolder, MdCheckCircle, MdDescription } from "react-icons/md";
// import CryptoJS from 'crypto-js'
// import _, { repeat } from "lodash";
// import Validation from "./Functions/validation"
// import LoadEndpoint from "./Components/LoadEndpoints"
// import { LoadingOutlined } from '@ant-design/icons';
// import urlSocket from "../../helpers/urlSocket";
// // var urlSocket = require("../../helpers/urlSocket");
// var moment = require('moment')



// const icons = {
//   check: <MdCheckBox className="rct-icon rct-icon-check text-success" />,
//   uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
//   halfCheck: (
//     <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
//   ),
//   expandClose: (
//     <MdChevronRight className="rct-icon rct-icon-expand-close" />
//   ),
//   expandOpen: (
//     <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
//   ),
//   expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
//   collapseAll: (
//     <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
//   ),
//   parentClose: <MdLocationCity className="rct-icon rct-icon-parent-close" />,
//   parentOpen: <MdLocationCity className="rct-icon rct-icon-parent-open" />,
//   leaf: <MdStore className="rct-icon rct-icon-leaf-close" />
// }




// const nodes = [{
//   "value": 'Mars',
//   "label": 'Mars',
//   "children": [
//     { "value": 'Phobos', "label": 'Phobos' },
//     { "value": 'Deimos', "label": 'Deimos' },
//   ],
// }];







// export default class publishconfig extends Component {



//   constructor(props) {
//     super(props);
//     this.state = {
//       activeTab: 1,
//       activeCustomTab: 1,
//       selectedGroup: null,
//       dataloaded: false,
//       isEdit: false,
//       hirerachyData: [],
//       hlevelData: [],
//       hlevelIsSelected: false,
//       endpointSelected: false,
//       locationData: [],
//       checked: [],
//       expanded: [],
//       hlevelSelected: '',
//       auditUserlevelSelected: false,
//       reviewUserlevelSelected: false,
//       endpoints: [],
//       endpointsAvailable: false,
//       repeatModeData: [],
//       weekDays: [],
//       dayStartNumber: [],
//       userdata: [],
//       position: 'right',
//       open: false,
//       height: props.height,
//       methodSelected: "0",
//       component: "",
//       triggerFrom: "",
//       paginationPage: 1,
//       sderror: false,
//       ederror: false,
//       publishTemplate:{},
//       open_group : false,
//       // dataloaded: false,
//       alertEnable:false,
//       formDisabled:false,
//       userInfo:{},
//       secret_key: 'Pkdh347ey%3Tgs',
//       loading:false,
//       invalid_user: false,
//       invalid_phone_num: false,
//       invalid_usercode: false,
//       country_code_err:false,
//       permissionsadded:[],
//       given_email:'',
//     }

//     this.toggleTab = this.toggleTab.bind(this)
//     this.toggleCustomTab = this.toggleCustomTab.bind(this)
//     this.handleSelectGroup = this.handleSelectGroup.bind(this)
//     //this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this)
//     this.handleMStartGroupChange = this.handleMStartGroupChange.bind(this)
//     this.handleMEndGroupChange = this.handleMEndGroupChange.bind(this)
//     this.getDateFormateMethod = this.getDateFormateMethod.bind(this)
//     this.closeDrawer = this.closeDrawer.bind(this);
//     this.onDrawerClose = this.onDrawerClose.bind(this);
//     this.onCancelDrawerClose = this.onCancelDrawerClose.bind(this)
//     this.setPosition = this.setPosition.bind(this);
//     this.endpointMethod = this.endpointMethod.bind(this);
//   }



//   closeDrawer() {
//     this.setState({ open: false });
//   }

//   onDrawerClose() {
//     this.setState({ auditUserlevelSelected: false, component: "" })
//     this.setState({ open: false });
//     // this.state.publishTemplate.cc_level = 0
//     try {
//       urlSocket.post("webphlbconf/gettemplate", {
//         userInfo: {

//           encrypted_db_url:this.state.db_info.encrypted_db_url,
//           created_by: this.state.userInfo._id,
//           company_id: this.state.userInfo.company_id,
//         },
//         publishtemplateInfo: this.state.publishTemplate
//       })
//         .then(response => {
//           //(response,'response')
//           console.log(response,'july-13')
//           if (response.data.response_code === 500) {
//             //("response.data.data.endpoints", response.data.data.endpoints)

//             this.setState({

//               publishTemplate: response.data.data,

//               endpoints: response.data.data.endpoints,

//               auditUserlevelSelected: true,

//               // cc_level:response.data.data.cc_level

//             }, function () {

//               this.updateCCLevel()

//             })



//           }

//         })

//     } catch (error) {



//     }



//   }



//   onCancelDrawerClose() {

//     this.setState({ open: false, component: "" });

//   }



//   setPosition(e) {

//     this.setState({ position: e.target.value });

//   }



//   getDidmountFunction(){

//     //("22")

//     var data = JSON.parse(sessionStorage.getItem("authUser"));

//     var db_info = JSON.parse(sessionStorage.getItem("db_info"));

//     //(db_info,'db_info')

//     var publishTemplate = JSON.parse(sessionStorage.getItem("publishData"));

//     this.setState(

//       {

//         userInfo: data.user_data,

//         client_info : data.client_info[0],

//         configData: data.config_data,

//         db_info:db_info,

//         repeatModeData: data.config_data.repeat_mode,

//         dataloaded: false,

//         publishTemplate,

//         height: window.innerHeight,

//         dayStartNumber: this.generateNumber(1),

//         dayEndNumber: this.generateNumber(2),

//         hlevelIsSelected: false,

//         // selectedEndpoint:publishTemplate.ep_level

//       },

//       async function () {

//         await this.getTemplateMethod()

//         this.getDateFormateMethod()

//         this.loadUserLabels()

//       }

//     )









//   }



//   async componentDidMount() {

//     //("111")

//     this.getDidmountFunction()

//     var data = JSON.parse(sessionStorage.getItem("authUser"));

//     var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];

//     var db_info = JSON.parse(sessionStorage.getItem("db_info"));

//     data.config_data.facilities.map((ele, idx) => {

//       if (ele.id === "7") {

//         //(data.config_data.facilities)

//         data.config_data.facilities[idx].enabled = true

//         this.setState({ permissionsadded: [data.config_data.facilities[idx]] }, () => {

//           //  //(this.state.permissionsadded, 'permissions')

//            })

//       }

//     })



//     //("data.config_data", data.config_data)

//     var protect_routes = await facility(data.facility_data, 'mngpblhtempt')

//     if (protect_routes !== undefined) {

//       this.setState(

//         {

//           sessionUserInfo: data.user_data,

//           configData: data.config_data,



//           dataloaded: true,

//           db_info: db_info,

//           client_info: client_info



//         },

//         function () {

//           //    this.getEntityDetaitedData()

//         }

//       )

//     }

//     else {

//       this.props.history.push('/dashboard')

//     }

//   }



//   generateNumber(startNumber) {

//     var genNumber = []

//     for (var i = startNumber; i <= 31; i++) {

//       genNumber.push({

//         name: String(i),

//         value: String(i)

//       })

//     }

//     return genNumber

//   }



//   // async endpointMethod(method) {

//   //   this.state.publishTemplate.method_selected = method

//   //   await this.updatePublishTemplateData()

//   //   this.setState({

//   //     methodSelected: method,

//   //     dataloaded: false

//   //   }, function () {

//   //     if (method === "2") {

//   //       this.gethlevelMethod()

//   //     }

//   //     else {

//   //       this.getCustomMethod()

//   //     }

//   //   })

//   // }

//   async endpointMethod(method) {
//     await this.updatePublishTemplateData();

//     this.setState((prevState) => ({
//         publishTemplate: {
//             ...prevState.publishTemplate,
//             method_selected: method,
//         },
//         methodSelected: method,
//         dataloaded: false,
//     }), () => {
//         if (method === "2") {
//             this.gethlevelMethod();
//         } else {
//             this.getCustomMethod();
//         }
//     });
// }




//   resetEndpointMethod() {



//     urlSocket.post("webphlbconf/resettemplate", {

//       userInfo: {

//         encrypted_db_url:this.state.db_info.encrypted_db_url,

//         created_by: this.state.userInfo._id,

//         company_id: this.state.userInfo.company_id,

//       },

//       publishtemplateInfo: this.state.publishTemplate

//     })

//       .then(response => {

//         console.log(response,'response')

//         //("response -0-0-0", response)

//         if (response.data.response_code == 500) {

//           this.setState({

//             publishTemplate: response.data.data,

//             changeMethodConfirmation: false,

//             methodSelected: response.data.data.method_selected,

//             cc_level: response.data.data.cc_level,

//             dataloaded: response.data.data.method_selected === "0" ? true : false

//           })

//         }

//       })



//   }



//   async getTemplateMethod() {

//     urlSocket.post("webphlbconf/gettemplate", {

//       userInfo: {

//         encrypted_db_url:this.state.db_info.encrypted_db_url,

//         created_by: this.state.userInfo._id,

//         company_id: this.state.userInfo.company_id,

//       },

//       publishtemplateInfo: this.state.publishTemplate

//     })

//       .then(response => {

//         if (response.data.response_code == 500) {

//           //("response get template", response.data.data)

//           this.setState({

//             publishTemplate: response.data.data,

//             methodSelected: response.data.data.method_selected,

//             cc_level: response.data.data.cc_level,

//             dataloaded: response.data.data.method_selected === "0" ? true : false

//           },

//             function () {

//               if (response.data.data.method_selected === "2") {

//                 this.gethlevelMethod()

//               }

//               else if (response.data.data.method_selected === "1") {

//                 this.getCustomMethod()

//               }

//             }

//           )



//         }

//       })

//   }





//   loadUserLabels() {

//     try {

//         urlSocket.post("userSettings/get-user-group", {

//             userInfo: {

//                 encrypted_db_url: this.state.db_info.encrypted_db_url,

//                 _id: this.state.userInfo._id,

//                 company_id: this.state.userInfo.company_id

//             }

//         })

//             .then(response => {

//                 //("user label", response)

//                 this.setState({

//                     labelData: response.data.data,

//                     // dataLoaded: true



//                 })

//             })



//     } catch (error) {

//         //("catch error", error)

//     }

// }

















//   getCustomMethod() {

//     urlSocket.post("webphlbconf/gethlmethod", {

//       userInfo: {

//         encrypted_db_url:this.state.db_info.encrypted_db_url,

//         created_by: this.state.userInfo._id,

//         company_id: this.state.userInfo.company_id,

//       },

//       publishtemplateInfo: this.state.publishTemplate

//     })

//       .then(response => {

//         //("response custom", response.data.data)

//         if (response.data.response_code == 500) {



//           var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })

//           var wkdays = response.data.data.data.repeat_mode_config



//           var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]

//           var getHour = getTimeFromEvent.slice(0, 2)

//           var getMinTime = String(parseInt(getHour + 1)) + "00"



//           this.setState({

//             publishTemplate: response.data.data.data,

//             cc_level: response.data.data.data.cc_level,

//             dataSource: response.data.data.hlevelData,

//             auditUserlevelSelected: true,

//             customEnableReview: response.data.data.data.enable_review,

//             reviewUserlevelSelected: false,

//             endpoints: response.data.data.data.endpoints,

//             ptl_startdate: response.data.data.data.start_date,

//             ptl_enddate: response.data.data.data.end_date,

//             repeat_mode: response.data.data.data.repeat_mode,

//             rm_config: response.data.data.data.repeat_mode_config,

//             daily_endtime: response.data.data.data.repeat_mode_config.end_time,

//             daily_starttime: response.data.data.data.repeat_mode_config.start_time,

//             getMinTime,

//             selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',

//             weekDays: response.data.data.data.repeat_mode_config.days,

//             dsf: wkdays.start_on,

//             eoem: wkdays.ends_on,

//             mstartoption: wkdays.mstartoption,

//             mendoption: wkdays.mendoption,

//             dayStartNumber: this.generateNumber(1),

//             dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),

//             dataloaded: false,

//           }, function () {

//             this.getAllUsers()

//           })

//         }

//       })

//   }



//   async gethlevelMethod() {

//     urlSocket.post("webphlbconf/gethlmethod", {

//       userInfo: {

//         encrypted_db_url:this.state.db_info.encrypted_db_url,

//         created_by: this.state.userInfo._id,

//         company_id: this.state.userInfo.company_id,

//       },

//       publishtemplateInfo: this.state.publishTemplate

//     })

//       .then(response => {

//         //("response", response.data.data)

//         if (response.data.response_code == 500) {



//           var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })

//           var wkdays = response.data.data.data.repeat_mode_config



//           var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]

//           var getHour = getTimeFromEvent.slice(0, 2)

//           var getMinTime = String(parseInt(getHour + 1)) + "00"





//           if (response.data.data.data.hlevel != null) {

//             var getUniqueRecords = _.uniqBy(response.data.data.endpointData, el => `${el.hlevel}`)

//             var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.ep_level })

//             var getAuditUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.audit_userlevel })

//             var getReviewUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.review_userlevel })

//             var convertedFlatData

//             if (response.data.data.data.ep_structure.length == 0) {

//               convertedFlatData = this.convertFlatDataToTreeData(response.data.data.endpointData)

//             }

//             else {

//               convertedFlatData = response.data.data.data.ep_structure

//             }



//             this.setState({

//               publishTemplate: response.data.data.data,

//               cc_level: response.data.data.data.cc_level,

//               dataSource: response.data.data.hlevelData,

//               selectedHLevelValue: String(getSelectedHlevelIndex + 1),

//               hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,

//               hlevelData: getUniqueRecords,

//               hirerachyData: response.data.data.hlevelData[getSelectedHlevelIndex],

//               selectedEndpoint: endpointIndex != -1 ? String(endpointIndex + 1) : "choose",

//               endpointSelected: endpointIndex != -1 ? true : false,

//               hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',

//               locationData: convertedFlatData,

//               checked: response.data.data.data.ep_selected,

//               expanded: response.data.data.data.ep_expanded,

//               selectedAuditUserlevel: getAuditUserlevelIndex != -1 ? String(getAuditUserlevelIndex + 1) : "choose",

//               audit_userlevel: response.data.data.data.audit_userlevel,

//               review_userlevel: response.data.data.data.review_userlevel,

//               auditUserlevelSelected: getAuditUserlevelIndex != -1 ? true : false,

//               enableReview: response.data.data.data.enable_review,

//               selectedReviewUserlevel: getReviewUserlevelIndex != -1 ? String(getReviewUserlevelIndex + 1) : "choose",

//               reviewUserlevelSelected: getReviewUserlevelIndex != -1 ? true : false,

//               endpoints: response.data.data.data.endpoints,

//               ptl_startdate: response.data.data.data.start_date,

//               ptl_enddate: response.data.data.data.end_date,

//               repeat_mode: response.data.data.data.repeat_mode,

//               rm_config: response.data.data.data.repeat_mode_config,

//               selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',

//               daily_endtime: response.data.data.data.repeat_mode_config.end_time,

//               daily_starttime: response.data.data.data.repeat_mode_config.start_time,

//               getMinTime,

//               weekDays: response.data.data.data.repeat_mode_config.days,

//               dsf: wkdays.start_on,

//               eoem: wkdays.ends_on,

//               mstartoption: wkdays.mstartoption,

//               mendoption: wkdays.mendoption,

//               dayStartNumber: this.generateNumber(1),

//               dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),

//               dataloaded: false,

//             }, function () {

//               this.getAllUsers()

//             })

//           }

//           else {

//             this.setState({

//               publishTemplate: response.data.data.data,

//               cc_level: response.data.data.data.cc_level,

//               dataSource: response.data.data.hlevelData,

//               selectedHLevelValue: getSelectedHlevelIndex != -1 ? String(getSelectedHlevelIndex + 1) : "0",

//               hlevelIsSelected: false,

//               hlevelData: [],

//               selectedEndpoint: "choose",

//               endpointSelected: false,

//               hlevelSelected: '',

//               locationData: [],

//               selectedAuditUserlevel: 'choose',

//               audit_userlevel: response.data.data.data.audit_userlevel,

//               review_userlevel: response.data.data.data.review_userlevel,

//               auditUserlevelSelected: false,

//               enableReview: false,

//               selectedReviewUserlevel: 'choose',

//               reviewUserlevelSelected: false,

//               endpoints: response.data.data.data.endpoints,

//               ptl_startdate: response.data.data.data.start_date,

//               ptl_enddate: response.data.data.data.end_date,

//               repeat_mode: null,

//               rm_config: response.data.data.data.repeat_mode_config,

//               daily_endtime: response.data.data.data.repeat_mode_config.end_time,

//               daily_starttime: response.data.data.data.repeat_mode_config.start_time,

//               getMinTime,

//               selectedRepeatMode: 'choose',

//               weekDays: response.data.data.data.repeat_mode_config.days,

//               dsf: wkdays.start_on,

//               eoem: wkdays.ends_on,

//               mstartoption: wkdays.mstartoption,

//               mendoption: wkdays.mendoption,

//               dayStartNumber: this.generateNumber(1),

//               dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),

//               dataloaded: false,

//             }, function () {

//               this.getAllUsers()

//             })

//           }

//         }

//       })

//   }



//   async getAllUsers() {

//     //('getAllUsers')

//     try {

//       urlSocket.post("webphlbconf/getallusers", {

//         userInfo: {

//           encrypted_db_url:this.state.db_info.encrypted_db_url,

//           created_by: this.state.userInfo._id,

//           company_id: this.state.userInfo.company_id,

//         },

//         publishtemplateInfo: this.state.publishTemplate

//       })

//         .then((response) => {

//           //(response,'response')

//           this.setState({

//             userdata: response.data.data,

//             dataloaded: true

//           })

//         })

//     } catch (error) {

//       //("catch error", error)

//     }

//   }





//   getHStructureListData = () => {

//     try {



//       urlSocket.post("webphlbconf/gethirhylevels", {

//         userInfo: {

//           created_by: this.state.userInfo._id,

//           company_id: this.state.userInfo.company_id,

//         },

//         publishtemplateInfo: this.state.publishTemplate

//       })

//         .then((response) => {

//           var getSelectedHlevelIndex = _.findIndex(response.data.data, { hname: this.state.publishTemplate.hlevel })

//           this.setState({

//             dataSource: response.data.data,

//             selectedHLevelValue: String(getSelectedHlevelIndex + 1),

//             hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,

//             dataloaded: true,

//           })

//           if (getSelectedHlevelIndex !== -1) {

//             // this.selectLevel()

//           }

//         })

//     } catch (error) {

//       //("catch error", error)

//     }

//   }



//   toggleTab(tab) {

//     if (this.state.activeTab !== tab) {

//       if (tab === 2) {

//         this.setState({ auditUserlevelSelected: false })

//         setTimeout(() => {

//           this.setState({ auditUserlevelSelected: true })

//         }, 10)

//       }

//       if (tab >= 1 && tab <= 4) {

//         this.setState({

//           activeTab: tab,

//         })

//       }

//     }

//   }



//   toggleCustomTab(tab) {

//     console.log(tab,'tab')

//     if (this.state.activeCustomTab !== tab) {



//       if (tab >= 1 && tab <= 4) {

//         this.setState({

//           activeCustomTab: tab,

//         })

//       }

//     }

//   }



//   handleSelectGroup = selectedGroup => {

//     this.setState({ selectedGroup })

//   }



//   // selectLevel = (event) => {



//   //   var hirerachyData = this.state.dataSource[parseInt(event.target.value) - 1] //JSON.parse(event.target.value)

//   //   this.state.publishTemplate.hlevel = hirerachyData.hname

//   //   this.state.publishTemplate.hlevel_id = hirerachyData._id

//   //   this.state.publishTemplate.ep_level = null

//   //   this.state.publishTemplate.ep_structure = []

//   //   this.state.publishTemplate.eplevel_value = null

//   //   this.state.publishTemplate.ep_selected = []

//   //   this.state.publishTemplate.ep_expanded = []

//   //   this.state.publishTemplate.endpoints = []

//   //   this.state.publishTemplate.audit_userlevel = null

//   //   this.state.publishTemplate.audit_userlevel_value = null

//   //   this.state.publishTemplate.enable_review = false

//   //   this.state.publishTemplate.review_userlevel = null

//   //   this.state.publishTemplate.review_userlevel_value = null

//   //   this.state.publishTemplate.start_date = null

//   //   this.state.publishTemplate.end_date = null

//   //   this.state.publishTemplate.frequency = "One time"



//   //   // document.getElementById("start_date").value = null

//   //   // document.getElementById("end_date").value = null





//   //   this.setState({

//   //     checked: [],

//   //     selectedAuditUserlevel: 'choose',

//   //     auditUserlevelSelected: false,

//   //   })



//   //   if (this.state.endpointSelected) document.getElementById("location").value = "choose"



//   //   this.updateCCLevel()

//   //   try {

//   //     urlSocket.post("webphlbconf/getlocationlevels", {

//   //       userInfo: {

//   //         created_by: this.state.userInfo._id,

//   //         company_id: this.state.userInfo.company_id

//   //       },

//   //       hInfo: {

//   //         h_id: hirerachyData._id,

//   //         publishtemplateInfo: this.state.publishTemplate

//   //       }

//   //     })

//   //       .then(response => {

//   //         console.log(response,'response')

//   //         if (response.data.response_code == 500) {

//   //           var getUniqueRecords = _.uniqBy(response.data.data, el => `${el.hlevel}`)

//   //           var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: this.state.publishTemplate.ep_level })

//   //           this.setState({

//   //             cc_level: 0,

//   //             hlevelData: getUniqueRecords,

//   //             hirerachyData,

//   //             selectedEndpoint: 'choose',

//   //             endpointSelected: false,

//   //             hlevelIsSelected: true,

//   //             hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',

//   //             ptl_startdate: null,

//   //             ptl_enddate: null,

//   //             ptl_frequency: "One time",

//   //           })

//   //         }

//   //       })

//   //   } catch (error) {

//   //     //("catch error", error)

//   //   }



//   // }


//   selectLevel = async (event) => {
//     try {
//         // Get the hierarchy data based on the selected value
//         const hierarchyData = this.state.dataSource[parseInt(event.target.value) - 1];

//         // Create a new object to represent the updated publishTemplate
//         const updatedPublishTemplate = {
//             ...this.state.publishTemplate,
//             hlevel: hierarchyData.hname,
//             hlevel_id: hierarchyData._id,
//             ep_level: null,
//             ep_structure: [],
//             eplevel_value: null,
//             ep_selected: [],
//             ep_expanded: [],
//             endpoints: [],
//             audit_userlevel: null,
//             audit_userlevel_value: null,
//             enable_review: false,
//             review_userlevel: null,
//             review_userlevel_value: null,
//             start_date: null,
//             end_date: null,
//             frequency: "One time",
//         };

//         // Update the state using the new publishTemplate object
//         this.setState({
//             publishTemplate: updatedPublishTemplate,
//             checked: [],
//             selectedAuditUserlevel: 'choose',
//             auditUserlevelSelected: false,
//         });

//         // If endpointSelected is true, reset the location dropdown
//         if (this.state.endpointSelected) {
//             document.getElementById("location").value = "choose";
//         }

//         // Update the cc_level and other related data
//         this.updateCCLevel();

//         // Make an asynchronous request to get location levels
//         const response = await urlSocket.post("webphlbconf/getlocationlevels", {
//             userInfo: {
//                 created_by: this.state.userInfo._id,
//                 company_id: this.state.userInfo.company_id,
//             },
//             hInfo: {
//                 h_id: hierarchyData._id,
//                 publishtemplateInfo: updatedPublishTemplate, // Use the updated template
//             },
//         });

//         // Check the response and update the state accordingly
//         if (response.data.response_code === 500) {
//             const getUniqueRecords = _.uniqBy(response.data.data, (el) => `${el.hlevel}`);
//             const endpointIndex = _.findIndex(getUniqueRecords, { hlevel: updatedPublishTemplate.ep_level });

//             this.setState({
//                 cc_level: 0,
//                 hlevelData: getUniqueRecords,
//                 hirerachyData: hierarchyData,
//                 selectedEndpoint: 'choose',
//                 endpointSelected: false,
//                 hlevelIsSelected: true,
//                 hlevelSelected: endpointIndex !== -1 ? getUniqueRecords[endpointIndex].hlevel : '',
//                 ptl_startdate: null,
//                 ptl_enddate: null,
//                 ptl_frequency: "One time",
//             });
//         }
//     } catch (error) {
//         // Handle the error if needed
//     }
// };


// selectLocation = async (event) => {
//   try {
//       // Extract endpoint info based on the selected value
//       const endpointInfo = this.state.hlevelData[parseInt(event.target.value) - 1];

//       // Create an updatedPublishTemplate object with the necessary changes
//       const updatedPublishTemplate = {
//           ...this.state.publishTemplate,
//           ep_level: endpointInfo.hlevel,
//           eplevel_value: endpointInfo.hlevel_value,
//           ep_structure: [],
//           ep_selected: [],
//           ep_expanded: [],
//           audit_userlevel: null,
//           audit_userlevel_value: null,
//           enable_review: false,
//           review_userlevel: null,
//           review_userlevel_value: null,
//           start_date: null,
//           end_date: null,
//           frequency: "One time",
//       };

//       // Set the updatedPublishTemplate in the state and reset other state variables
//       this.setState({
//           publishTemplate: updatedPublishTemplate,
//           checked: [],
//           expanded: [],
//           selectedEndpoint: event.target.value,
//       });

//       // Call the updateCCLevel method
//       this.updateCCLevel();

//       // Make an asynchronous request to fetch location data
//       const response = await urlSocket.post("webphlbconf/getlocations", {
//           userInfo: {
//               created_by: this.state.userInfo._id,
//               company_id: this.state.userInfo.company_id,
//           },
//           hInfo: {
//               h_id: this.state.hirerachyData._id,
//               hlevelinfo: endpointInfo,
//               publishtemplateInfo: updatedPublishTemplate,
//           },
//       });

//       // Check if the response was successful (response code 500 indicates success)
//       if (response.data.response_code === 500) {
//           // Convert the flat data to tree data and update the state
//           const convertedFlatData = this.convertFlatDataToTreeData(response.data.data);
//           this.setState({
//               locationData: convertedFlatData,
//           });
//           // Call the updateCheckedepStructure method
//           this.updateCheckedepStructure();
//       }
//   } catch (error) {
//       // Handle any errors here
//       console.error("Error in selectLocation:", error);
//   }
// }






//   // selectLocation(event) {



//   //   var endpointInfo = this.state.hlevelData[parseInt(event.target.value) - 1]



//   //   this.state.publishTemplate.ep_level = endpointInfo.hlevel

//   //   this.state.publishTemplate.eplevel_value = endpointInfo.hlevel_value

//   //   this.state.publishTemplate.ep_structure = []

//   //   this.state.publishTemplate.ep_selected = []

//   //   this.state.publishTemplate.ep_expanded = []

//   //   this.state.publishTemplate.audit_userlevel = null

//   //   this.state.publishTemplate.audit_userlevel_value = null

//   //   this.state.publishTemplate.enable_review = false

//   //   this.state.publishTemplate.review_userlevel = null

//   //   this.state.publishTemplate.review_userlevel_value = null

//   //   this.state.publishTemplate.start_date = null

//   //   this.state.publishTemplate.end_date = null

//   //   this.state.publishTemplate.frequency = "One time"



//   //   // document.getElementById("start_date").value = null

//   //   // document.getElementById("end_date").value = null





//   //   this.setState({ checked: [], expanded: [], selectedEndpoint: event.target.value, })

//   //   this.updateCCLevel()

//   //   urlSocket.post("webphlbconf/getlocations", {

//   //     userInfo: {

//   //       created_by: this.state.userInfo._id,

//   //       company_id: this.state.userInfo.company_id

//   //     },

//   //     hInfo: {

//   //       h_id: this.state.hirerachyData._id,

//   //       hlevelinfo: endpointInfo,

//   //       publishtemplateInfo: this.state.publishTemplate

//   //     }

//   //   })

//   //     .then(response => {

//   //       if (response.data.response_code == 500) {

//   //         var convertedFlatData = this.convertFlatDataToTreeData(response.data.data)

//   //         this.state.publishTemplate.ep_structure = convertedFlatData

//   //         this.setState({

//   //           locationData: convertedFlatData,

//   //         },

//   //           function () {

//   //             this.updateCheckedepStructure()

//   //           }

//   //         )

//   //       }

//   //     })



//   //   this.setState({

//   //     endpointSelected: true,

//   //     hlevelSelected:

//   //       this.state.hlevelData[parseInt(event.target.value) - 1].hlevel,

//   //     selectedAuditUserlevel: 'choose',

//   //     auditUserlevelSelected: false,

//   //     enableReview: false,

//   //     selectedReviewUserlevel: 'choose',

//   //     reviewUserlevelSelected: false,

//   //     cc_level: 0,

//   //     ptl_startdate: null,

//   //     ptl_enddate: null,

//   //     ptl_frequency: "One time",

//   //   })

//   // }



//   convertFlatDataToTreeData = (arr) => {



//     var tree = [],

//       mappedArr = {},

//       arrElem,

//       mappedElem;



//     // First map the nodes of the array to an object -> create a hash table.

//     for (var i = 0, len = arr.length; i < len; i++) {

//       arrElem = {

//         "value": arr[i]._id,

//         "label": arr[i].hlevel_name,

//         "children": arr[i].children,

//         "id": arr[i].id,

//         "parent": arr[i].parent,

//         "node_id": arr[i]._id,

//         "ep_level":arr[i].hlevel,

//         "ep_level_value":arr[i].hlevel_value

//       }

//       mappedArr[arrElem.id] = arrElem;

//       mappedArr[arrElem.id]['children'] = []

//     }





//     for (var id in mappedArr) {

//       if (mappedArr.hasOwnProperty(id)) {

//         mappedElem = mappedArr[id];



//         // If the element is not at the root level, add it to its parent array of children.

//         if (mappedElem.parent) {

//           mappedArr[mappedElem['parent']]['children'].push(mappedElem);

//         }

//         // If the element is at the root level, add it to first level elements array.

//         else {

//           tree.push(mappedElem);

//         }

//       }

//     }



//     let update = () => obj => {

//       if (obj.children.length > 0)

//         obj.children.forEach(update());

//       else

//         obj.children = null

//     };



//     tree.forEach(update());

//     return tree;

//   }



//   // handleEndpointSelection = (checked, event) => {

//   //   //("event", event)

//   //   this.setState({ checked, cc_level: checked.length > 0 ? 1 : 0 }, async function () {

//   //     this.state.publishTemplate.ep_structure = this.state.locationData

//   //     this.state.publishTemplate.ep_selected = checked

//   //     this.updateCheckedepStructure()

//   //   });

//   // }


//   handleEndpointSelection = (checked, event) => {
//     // Update the state with the selected checkboxes and cc_level
//     this.setState({ checked, cc_level: checked.length > 0 ? 1 : 0 }, () => {
//         // Update publishTemplate with the selected endpoints and the entire location data
//         const updatedPublishTemplate = {
//             ...this.state.publishTemplate,
//             ep_structure: this.state.locationData,
//             ep_selected: checked,
//         };
//         this.setState({ publishTemplate: updatedPublishTemplate }, () => {
//             // Call the updateCheckedepStructure method
//             this.updateCheckedepStructure();
//         });
//     });
// }

// handleEndpointExpanded = (expanded) => {
//   // Create a copy of the publishTemplate with updated ep_structure and ep_expanded
//   const updatedPublishTemplate = {
//       ...this.state.publishTemplate,
//       ep_structure: this.state.locationData,
//       ep_expanded: expanded,
//   };

//   // Update the state with the new expanded value and updated publishTemplate
//   this.setState({ expanded, publishTemplate: updatedPublishTemplate }, () => {
//       // Call the updateCheckedepStructure method
//       this.updateCheckedepStructure();
//   });
// };



//   // handleEndpointExpanded = expanded => {

//   //   this.setState({ expanded }, function () {

//   //     this.state.publishTemplate.ep_structure = this.state.locationData

//   //     this.state.publishTemplate.ep_expanded = expanded

//   //     this.updateCheckedepStructure()

//   //   })

//   // }



//   updateCheckedepStructure() {

//     this.updateCCLevel()



//     //("this.state.publishTemplate", this.state.publishTemplate)

//     try {

//       urlSocket.post("webphlbconf/updateepstructure", {

//         userInfo: {

//           created_by: this.state.userInfo._id,

//           company_id: this.state.userInfo.company_id

//         },

//         hInfo: {

//           publishtemplateInfo: this.state.publishTemplate

//         }

//       })

//         .then(response => {

//           console.log(response)

//           //("response", response.data.data.ep_structure)

//         })

//     } catch (error) {



//     }

//   }

//   selectAuditUserlevel(event) {
//     const userlevelInfo = this.state.hlevelData[parseInt(event.target.value) - 1];

//     const updatedPublishTemplate = {
//         ...this.state.publishTemplate,
//         audit_userlevel: userlevelInfo.hlevel,
//         audit_userlevel_value: userlevelInfo.hlevel_value,
//         enable_review: false,
//         review_userlevel: null,
//         review_userlevel_value: null,
//         start_date: null,
//         end_date: null,
//         frequency: "One time",
//         endpoints: [],
//       };
      
//     this.setState({
//         selectedAuditUserlevel: event.target.value,
//         audit_userlevel: userlevelInfo.hlevel,
//         auditUserlevelSelected: false,
//         publishTemplate: updatedPublishTemplate,
//         endpoints: updatedPublishTemplate.endpoints,
//     });

//     this.updateCCLevel();

//     try {
//         urlSocket
//             .post("webphlbconf/onchangeaudituserlevel", {
//                 userInfo: {
//                     created_by: this.state.userInfo._id,
//                     company_id: this.state.userInfo.company_id,
//                 },
//                 hInfo: {
//                     h_id: this.state.hirerachyData._id,
//                     publishtemplateInfo: updatedPublishTemplate,
//                 },
//             })
//             .then((response) => {
//                 if (response.data.response_code === 500) {
//                     _.each(response.data.data.endpoints, (item) => {
//                         if (
//                             updatedPublishTemplate.enable_review &&
//                             (!_.some(item.adt_users, { audit_type: "2" }) ||
//                                 !_.some(item.adt_users, { audit_type: "1" }))
//                         ) {
//                             item["rowValid"] = false;
//                         } else if (
//                             !updatedPublishTemplate.enable_review &&
//                             !_.some(item.adt_users, { audit_type: "1" })
//                         ) {
//                             item["rowValid"] = false;
//                         } else {
//                             item["rowValid"] = true;
//                         }
//                     });
//                     updatedPublishTemplate.endpoints = response.data.data.endpoints;
//                     this.setState({
//                         endpoints: response.data.data.endpoints,
//                         endpointsAvailable: true,
//                         enableReview: false,
//                         selectedReviewUserlevel: "choose",
//                         review_userlevel: null,
//                         reviewUserlevelSelected: false,
//                         cc_level: response.data.data.cc_level,
//                         ptl_startdate: null,
//                         ptl_enddate: null,
//                         ptl_frequency: "One time",
//                         auditUserlevelSelected: true,
//                     });
//                 }
//                 this.updateCCLevel();
//             });
//     } catch (error) {
//         // Handle the error
//     }
// }




//   // selectAuditUserlevel(event) {



//   //   var userlevelInfo = this.state.hlevelData[parseInt(event.target.value) - 1]



//   //   this.state.publishTemplate.audit_userlevel = userlevelInfo.hlevel

//   //   this.state.publishTemplate.audit_userlevel_value = userlevelInfo.hlevel_value



//   //   this.state.publishTemplate.enable_review = false

//   //   this.state.publishTemplate.review_userlevel = null

//   //   this.state.publishTemplate.review_userlevel_value = null

//   //   //this.state.publishTemplate.cc_level = 2

//   //   this.state.publishTemplate.start_date = null

//   //   this.state.publishTemplate.end_date = null

//   //   this.state.publishTemplate.frequency = "One time"



//   //   // document.getElementById("start_date").value = null

//   //   // document.getElementById("end_date").value = null



   



//   //   ////("this.state.publishTemplate", this.state.publishTemplate)



//   //   this.setState({

//   //     selectedAuditUserlevel: event.target.value,

//   //     audit_userlevel: userlevelInfo.hlevel,

//   //     auditUserlevelSelected: false,

//   //     publishTemplate:this.state.publishTemplate,

//   //     endpoints:this.state.publishTemplate.endpoints

//   //   },

//   //   )



//   //   this.updateCCLevel()

//   //   try {

//   //     urlSocket.post("webphlbconf/onchangeaudituserlevel", {

//   //       userInfo: {

//   //         created_by: this.state.userInfo._id,

//   //         company_id: this.state.userInfo.company_id

//   //       },

//   //       hInfo: {

//   //         h_id: this.state.hirerachyData._id,

//   //         publishtemplateInfo: this.state.publishTemplate

//   //       }

//   //     })

//   //       .then(response => {

//   //         //("get endpoints", response.data.data, response.data.userdata)

//   //         if (response.data.response_code === 500) {

//   //           _.each(response.data.data.endpoints, item => {

//   //             if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {

//   //               item["rowValid"] = false

//   //             }

//   //             else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {

//   //               item["rowValid"] = false

//   //             }

//   //             else

//   //             {

//   //               item["rowValid"] = true

//   //             }

//   //           })

//   //           this.state.publishTemplate.endpoints = response.data.data.endpoints

//   //           this.setState({

//   //             endpoints: response.data.data.endpoints, endpointsAvailable: true,

//   //             //userdata: response.data.userdata,

//   //             enableReview: false,

//   //             selectedReviewUserlevel: 'choose',

//   //             review_userlevel: null,

//   //             reviewUserlevelSelected: false,

//   //             cc_level: response.data.data.cc_level,

//   //             ptl_startdate: null,

//   //             ptl_enddate: null,

//   //             ptl_frequency: "One time",

//   //             auditUserlevelSelected: true,

//   //           })

//   //         }

//   //         this.updateCCLevel()

//   //       })

//   //   } catch (error) {

//   //     //("catch error", error)

//   //   }



//   // }



//   // onchangeCustomEnableReview = (e) => {

//   //   this.setState({ auditUserlevelSelected: false })

//   //   this.state.publishTemplate.enable_review = e.target.checked

//   //   var checkedData = e.target.checked



//   //   //(checkedData)

//   //   _.each(this.state.publishTemplate.endpoints, item => {

//   //     if (checkedData && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {

//   //       item["rowValid"] = false

//   //     }

//   //     else if (!checkedData && !_.some(item.adt_users, { audit_type: "1" })) {

//   //       item["rowValid"] = false

//   //     }
//   //     else{
//   //       item["rowValid"] = true

//   //     }

//   //   })



//   //   //("this.state.publishTemplate", this.state.publishTemplate)









//   //   setTimeout(() => {

//   //     this.setState({

//   //       customEnableReview: checkedData,

//   //       auditUserlevelSelected: true

//   //     })

//   //   }, 10)



//   //   this.updatePublishTemplateData()



//   // }


//   onchangeCustomEnableReview = (e) => {
//     const checkedData = e.target.checked;
//     const updatedPublishTemplate = {
//         ...this.state.publishTemplate,
//         enable_review: checkedData,
//     };

//     this.setState({
//         auditUserlevelSelected: false,
//         publishTemplate: updatedPublishTemplate,
//     });

//     _.each(updatedPublishTemplate.endpoints, (item) => {
//         if (
//             checkedData &&
//             (!_.some(item.adt_users, { audit_type: "2" }) ||
//                 !_.some(item.adt_users, { audit_type: "1" }))
//         ) {
//             item["rowValid"] = false;
//         } else if (!checkedData && !_.some(item.adt_users, { audit_type: "1" })) {
//             item["rowValid"] = false;
//         } else {
//             item["rowValid"] = true;
//         }
//     });

//     // Using setTimeout to ensure that state changes are reflected in the next render cycle
//     setTimeout(() => {
//         this.setState({
//             customEnableReview: checkedData,
//             auditUserlevelSelected: true,
//         });

//         // Update the publish template data after the state changes are applied
//         this.updatePublishTemplateData();
//     }, 10);
// };






//   // onchangeEnableReview(e) {

//   //   this.setState({ auditUserlevelSelected: false })

//   //   this.state.publishTemplate.enable_review = e.target.checked

//   //   this.state.publishTemplate.review_userlevel = null

//   //   this.state.publishTemplate.review_userlevel_value = null

//   //   this.state.publishTemplate.start_date = null

//   //   this.state.publishTemplate.end_date = null

//   //   this.state.publishTemplate.frequency = "One time"



//   //   var checkedData = e.target.checked



//   //     _.each(this.state.publishTemplate.endpoints, item=>{



//   //       var getIndex = _.findIndex(item.adt_users, {audit_type:"2"})

//   //       if(getIndex !== -1){

//   //         item.adt_users.splice(getIndex, 1)

//   //       }



//   //     })





//   //   setTimeout(() => {

//   //     this.setState({

//   //       enableReview: checkedData,

//   //       reviewUserlevelSelected: false,

//   //       selectedReviewUserlevel: 'choose',

//   //      // cc_level: checkedData ? 1 : 2,

//   //       ptl_startdate: null,

//   //       ptl_enddate: null,

//   //       ptl_frequency: "One time",

//   //       review_userlevel: null,

//   //       auditUserlevelSelected: true

//   //     })

//   //   }, 10)



//   //   this.updatePublishTemplateData()

//   // }
//   onchangeEnableReview = (e) => {
//     const checkedData = e.target.checked;
//     this.setState((prevState) => {
//         const updatedPublishTemplate = {
//             ...prevState.publishTemplate,
//             enable_review: checkedData,
//             review_userlevel: null,
//             review_userlevel_value: null,
//             start_date: null,
//             end_date: null,
//             frequency: "One time",
//         };

//         _.each(updatedPublishTemplate.endpoints, (item) => {
//             const getIndex = _.findIndex(item.adt_users, { audit_type: "2" });
//             if (getIndex !== -1) {
//                 item.adt_users.splice(getIndex, 1);
//             }
//         });

//         return {
//             auditUserlevelSelected: false,
//             enableReview: checkedData,
//             reviewUserlevelSelected: false,
//             selectedReviewUserlevel: 'choose',
//             ptl_startdate: null,
//             ptl_enddate: null,
//             ptl_frequency: "One time",
//             review_userlevel: null,
//         };
//     }, () => {
//         // Callback function to ensure state is updated before further actions
//         this.updatePublishTemplateData();
//     });
// };



//   // selectReviewUserlevel(event) {

//   //   this.setState({ auditUserlevelSelected: false })

//   //   var userReviewlevelInfo = this.state.hlevelData[parseInt(event.target.value) - 1]



//   //   var eventValue = event.target.value



//   //   this.state.publishTemplate.review_userlevel = userReviewlevelInfo.hlevel

//   //   this.state.publishTemplate.review_userlevel_value = userReviewlevelInfo.hlevel_value

//   //  // this.state.publishTemplate.cc_level = 2

//   //   this.state.publishTemplate.start_date = null

//   //   this.state.publishTemplate.end_date = null

//   //   this.state.publishTemplate.repeat_mode = null



//   //   _.each(this.state.publishTemplate.endpoints, item=>{



//   //     var getIndex = _.findIndex(item.adt_users, {audit_type:"2"})

//   //     if(getIndex !== -1){

//   //       item.adt_users.splice(getIndex, 1)

//   //     }

//   //   })



   



//   //   this.updateCCLevel()

//   //   try {

//   //     urlSocket.post("webphlbconf/onchangereviewuserlevel", {

//   //       userInfo: {

//   //         created_by: this.state.userInfo._id,

//   //         company_id: this.state.userInfo.company_id

//   //       },

//   //       hInfo: {

//   //         h_id: this.state.hirerachyData._id,

//   //         publishtemplateInfo: this.state.publishTemplate

//   //       }

//   //     })

//   //       .then(response => {

//   //         ////("get endpoints", response.data.data, response.data.response_code)

//   //         if (response.data.response_code === 500) {

//   //           //("this.state.publishTemplate.enableReview", this.state.publishTemplate.enableReview)

//   //           if(this.state.publishTemplate.enable_review)

//   //           {

//   //             _.each(response.data.data.endpoints, item => {

//   //               if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {

//   //                 item["rowValid"] = false

//   //               }

//   //             })

//   //           }

//   //           this.setState({

//   //             publishTemplate: response.data.data,

//   //             selectedReviewUserlevel: eventValue,

//   //             reviewUserlevelSelected: true,

//   //             cc_level: response.data.data.cc_level,

//   //             ptl_startdate: null,

//   //             ptl_enddate: null,

//   //             repeatMode: null,

//   //             endpoints: response.data.data.endpoints,

//   //             review_userlevel: userReviewlevelInfo.hlevel,

//   //             auditUserlevelSelected: true

//   //           })



//   //           this.updateCCLevel()

//   //         }

//   //       })

//   //   } catch (error) {

//   //     //("catch error", error)

//   //   }





//   // }

//   selectReviewUserlevel(event) {
//     this.setState((prevState) => {
//         const userReviewlevelInfo = this.state.hlevelData[parseInt(event.target.value) - 1];
//         const eventValue = event.target.value;

//         const updatedPublishTemplate = {
//             ...prevState.publishTemplate,
//             review_userlevel: userReviewlevelInfo.hlevel,
//             review_userlevel_value: userReviewlevelInfo.hlevel_value,
//             start_date: null,
//             end_date: null,
//             repeat_mode: null,
//         };

//         // Update endpoints based on your logic
//         const updatedEndpoints = updatedPublishTemplate.endpoints.map((item) => {
//             const getIndex = _.findIndex(item.adt_users, { audit_type: "2" });
//             if (getIndex !== -1) {
//                 item.adt_users.splice(getIndex, 1);
//             }
//             return item;
//         });

//         // Update rowValid based on your logic
//         updatedEndpoints.forEach((item) => {
//             if (updatedPublishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
//                 item.rowValid = false;
//             }
//         });

//         const newState = {
//             publishTemplate: updatedPublishTemplate,
//             selectedReviewUserlevel: eventValue,
//             reviewUserlevelSelected: true,
//             cc_level: updatedPublishTemplate.cc_level,
//             ptl_startdate: null,
//             ptl_enddate: null,
//             repeatMode: null,
//             endpoints: updatedEndpoints,
//             auditUserlevelSelected: true,
//         };

//         return newState;
//     }, () => {
//         // Callback function to execute after state update if needed
//         // Place any additional logic or actions here
//         this.updateCCLevel();
//     });
// }



//   // changeAuditUserHandler(event, rowData, idx,parameter) {

//   //   // console.log(event.target.value, rowData, idx,parameter,'event, rowData, idx,parameter')

//   //   if(parameter === "1" && event.target.value !=="add"){

//   //     // console.log('1113')

//   //   var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })

//   // }

//   // else{

//   //   var checkUserAlreadyExist =  -1

//   //   // console.log('1118')

//   // }

//   //   //("event", rowData, checkUserAlreadyExist,this.state.publishTemplate)



//   //   if (checkUserAlreadyExist !== -1 && (this.state.customEnableReview || this.state.enableReview)) {

//   //   // console.log('1123')

//   //     this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })

//   //   }

//   //   else if(event.target.value === "add") {

//   //     // if () {

//   //       // console.log('1128')

//   //       this.setState({ open: this.state.open ? false : true, component: "user", emptData: rowData, selected_index: idx })

//   //     }

//   //     else if (event.target.value == "loadgroupusers") {

//   //       // console.log('1132')

//   //       this.setState({ open: this.state.open ? false : true, component: "loadgroupusers", endpoint_data: rowData })



//   //     }

//   //     else {



//   //       this.setState({ auditUserlevelSelected: false })

//   //       var auditUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]

//   //       var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })

//   //       var getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })

//   //       // console.log('1142')

//   //       //(auditUser,getIndex,getUserIndex,'getUserIndex')

//   //       if (getUserIndex == -1) {

//   //         // console.log('1145')

//   //         this.state.publishTemplate.endpoints[getIndex].adt_users.push({

//   //           user_id: auditUser._id,

//   //           name: auditUser.firstname,

//   //           designation: auditUser.designation,

//   //           user_code: auditUser.usercode,

//   //           audit_type: "1"

//   //         })

//   //         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })

//   //         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })



//   //         this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false



//   //       }

//   //       else {

//   //         this.state.publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)

//   //         // console.log('1161')

//   //         //(auditUser,getIndex,getUserIndex,'getUserIndex')

//   //         this.state.publishTemplate.endpoints[getIndex].adt_users.push({

//   //           user_id: auditUser._id,

//   //           name: auditUser.firstname,

//   //           designation: auditUser.designation,

//   //           user_code: auditUser.usercode,

//   //           audit_type: "1"

//   //         })

//   //         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })

//   //         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

//   //         this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false



//   //       }





//   //       this.updatePublishTemplateData()

//   //       setTimeout(() => {

//   //         this.setState({

//   //           endpoints: this.state.publishTemplate.endpoints,

//   //           auditUserlevelSelected: true,

//   //         })

//   //       }, 10)

//   //     }

//   //     this.setState({ user_type: 0 })

//   //   // }



//   // }

//   changeAuditUserHandler(event, rowData, idx, parameter) {
//     const checkUserAlreadyExist = parameter === "1" ? _.findIndex(rowData.adt_users, { user_id: event.target.value }) : -1;

//     if (checkUserAlreadyExist !== -1 && (this.state.customEnableReview || this.state.enableReview)) {
//         this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try a different user." });
//     } else if (event.target.value === "add") {
//         this.setState((prevState) => ({
//             open: !prevState.open,
//             component: "user",
//             emptData: rowData,
//             selected_index: idx,
//         }));
//     } else if (event.target.value === "loadgroupusers") {
//         this.setState((prevState) => ({
//             open: !prevState.open,
//             component: "loadgroupusers",
//             endpoint_data: rowData,
//         }));
//     } else {
//         this.setState({ auditUserlevelSelected: false }, () => {
//             const auditUser = _.filter(this.state.userdata, { _id: event.target.value })[0];
//             const getIndex = _.findIndex(this.state.endpoints, { _id: rowData._id });
//             const getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" });

//             const updatedEndpoints = [...this.state.publishTemplate.endpoints];
//             const endpoint = updatedEndpoints[getIndex];

//             if (getUserIndex === -1) {
//                 endpoint.adt_users.push({
//                     user_id: auditUser._id,
//                     name: auditUser.firstname,
//                     designation: auditUser.designation,
//                     user_code: auditUser.usercode,
//                     audit_type: "1",
//                 });
//             } else {
//                 endpoint.adt_users.splice(getUserIndex, 1);
//                 endpoint.adt_users.push({
//                     user_id: auditUser._id,
//                     name: auditUser.firstname,
//                     designation: auditUser.designation,
//                     user_code: auditUser.usercode,
//                     audit_type: "1",
//                 });
//             }

//             const checkAuditUserExist = _.some(endpoint.adt_users, { audit_type: "1" });
//             const checkReviewUserExist = _.some(endpoint.adt_users, { audit_type: "2" });

//             endpoint["rowValid"] =
//                 !this.state.customEnableReview && checkAuditUserExist
//                     ? true
//                     : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist
//                     ? true
//                     : false;

//             this.setState({
//                 publishTemplate: { ...this.state.publishTemplate, endpoints: updatedEndpoints },
//             });

//             this.updatePublishTemplateData();
//             setTimeout(() => {
//                 this.setState({
//                     endpoints: updatedEndpoints,
//                     auditUserlevelSelected: true,
//                 });
//             }, 10);
//         });
//     }
//     this.setState({ user_type: 0 });
// }























// //   changeAuditUserHandler(event, rowData, idx, parameter) {
// //     const checkUserAlreadyExist = parameter === "1" ? _.findIndex(rowData.adt_users, { user_id: event.target.value }) : -1;

// //     if (checkUserAlreadyExist !== -1 && (this.state.customEnableReview || this.state.enableReview)) {
// //         this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." });
// //     } else if (event.target.value === "add") {
// //         this.setState((prevState) => ({
// //             open: !prevState.open,
// //             component: "user",
// //             emptData: rowData,
// //             selected_index: idx,
// //         }));
// //     } else if (event.target.value === "loadgroupusers") {
// //         this.setState((prevState) => ({
// //             open: !prevState.open,
// //             component: "loadgroupusers",
// //             endpoint_data: rowData,
// //         }));
// //     } else {
// //         this.setState({ auditUserlevelSelected: false }, () => {
// //             const auditUser = _.filter(this.state.userdata, { _id: event.target.value })[0];
// //             const getIndex = _.findIndex(this.state.endpoints, { _id: rowData._id });
// //             const getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" });

// //             if (getUserIndex === -1) {
// //                 this.state.publishTemplate.endpoints[getIndex].adt_users.push({
// //                     user_id: auditUser._id,
// //                     name: auditUser.firstname,
// //                     designation: auditUser.designation,
// //                     user_code: auditUser.usercode,
// //                     audit_type: "1",
// //                 });
// //             } else {
// //                 this.state.publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1);
// //                 this.state.publishTemplate.endpoints[getIndex].adt_users.push({
// //                     user_id: auditUser._id,
// //                     name: auditUser.firstname,
// //                     designation: auditUser.designation,
// //                     user_code: auditUser.usercode,
// //                     audit_type: "1",
// //                 });
// //             }

// //             const checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" });
// //             const checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" });

// //             this.state.publishTemplate.endpoints[getIndex]["rowValid"] =
// //                 !this.state.customEnableReview && checkAuditUserExist
// //                     ? true
// //                     : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist
// //                     ? true
// //                     : false;

// //             this.updatePublishTemplateData();
// //             setTimeout(() => {
// //                 this.setState({
// //                     endpoints: this.state.publishTemplate.endpoints,
// //                     auditUserlevelSelected: true,
// //                 });
// //             }, 10);
// //         });
// //     }
// //     this.setState({ user_type: 0 });
// // }


//   // changeReviewUserHandler(event, rowData, idx, parameter) {
//   //   //("event", event.target.value, rowData, this.state.publishTemplate)
//   //   if(parameter ==="1"){
//   //   var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
//   // }
//   // else{
//   //   var checkUserAlreadyExist = -1
//   // }

//   //   if (checkUserAlreadyExist !== -1) {
//   //     this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
//   //   }
//   //   else {
//   //     if (event.target.value === "add") {
//   //       this.setState({ open: this.state.open ? false : true, component: "user" , selected_index: idx})
//   //     }
//   //     else {        //('this.state.endpoints', this.state.endpoints)

//   //       this.setState({ auditUserlevelSelected: false })
//   //       var reviewUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
//   //               var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })
//   //       var getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
//   //      if (getUserIndex == -1) {
//   //         this.state.publishTemplate.endpoints[getIndex].adt_users.push({
//   //           user_id: reviewUser._id,
//   //           name: reviewUser.firstname,
//   //           designation: reviewUser.designation,
//   //           user_code: reviewUser.usercode,
//   //           audit_type: "2"
//   //         })

//   //         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//   //         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
//   //         this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false
//   //       }
//   //       else {
//   //         this.state.publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
//   //         this.state.publishTemplate.endpoints[getIndex].adt_users.push({
//   //           user_id: reviewUser._id,
//   //           name: reviewUser.firstname,
//   //           designation: reviewUser.designation,
//   //           user_code: reviewUser.usercode,
//   //           audit_type: "2"
//   //         })

//   //         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
//   //         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
//   //         this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

//   //       }
//   //       this.updatePublishTemplateData()
//   //       setTimeout(() => {
//   //         this.setState({
//   //           auditUserlevelSelected: true,
//   //           // cc_level: checkep ? 2 : this.state.cc_level
//   //         })
//   //       }, 10)
//   //     }
//   //   }
//   //   this.setState({user_type: 1})
//   //   }

//   changeReviewUserHandler(event, rowData, idx, parameter) {
//     let checkUserAlreadyExist;
//     if (parameter === "1") {
//         checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value });
//     } else {
//         checkUserAlreadyExist = -1;
//     }

//     if (checkUserAlreadyExist !== -1) {
//         this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try a different user." });
//     } else {
//         if (event.target.value === "add") {
//             this.setState((prevState) => ({
//                 open: !prevState.open,
//                 component: "user",
//                 selected_index: idx,
//             }));
//         } else {
//             this.setState({ auditUserlevelSelected: false }, () => {
//                 const reviewUser = _.filter(this.state.userdata, { _id: event.target.value })[0];
//                 const getIndex = _.findIndex(this.state.endpoints, { _id: rowData._id });
//                 const getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" });

//                 const updatedEndpoints = [...this.state.publishTemplate.endpoints];
//                 const endpoint = updatedEndpoints[getIndex];

//                 if (getUserIndex === -1) {
//                     endpoint.adt_users.push({
//                         user_id: reviewUser._id,
//                         name: reviewUser.firstname,
//                         designation: reviewUser.designation,
//                         user_code: reviewUser.usercode,
//                         audit_type: "2",
//                     });
//                 } else {
//                     endpoint.adt_users.splice(getUserIndex, 1);
//                     endpoint.adt_users.push({
//                         user_id: reviewUser._id,
//                         name: reviewUser.firstname,
//                         designation: reviewUser.designation,
//                         user_code: reviewUser.usercode,
//                         audit_type: "2",
//                     });
//                 }

//                 const checkAuditUserExist = _.some(endpoint.adt_users, { audit_type: "1" });
//                 const checkReviewUserExist = _.some(endpoint.adt_users, { audit_type: "2" });
//                 endpoint["rowValid"] =
//                     !this.state.customEnableReview && checkAuditUserExist
//                         ? true
//                         : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist
//                         ? true
//                         : false;

//                 this.setState({
//                     publishTemplate: { ...this.state.publishTemplate, endpoints: updatedEndpoints },
//                 });

//                 this.updatePublishTemplateData();
//                 setTimeout(() => {
//                     this.setState({
//                         auditUserlevelSelected: true,
//                     });
//                 }, 10);
//             });
//         }
//     }
// }



//   checkEndpoints = () => {

//     var checkAudituser = _.some(this.state.publishTemplate.endpoints, { audit_user: null })

//     var checkReviewuser = _.some(this.state.publishTemplate.endpoints, { review_user: null })



//     if (!this.state.customEnableReview && !checkAudituser) {

//       return true //this.setState({cc_level:2})

//     }

//     else if (!this.state.checkAudituser && (this.state.customEnableReview && !checkReviewuser)) {

//       return true //this.setState({cc_level:2})

//     }

//     else {

//       return false

//     }

//   }



//   getDateFormateMethod = () => {

//     var today = new Date()

//     const dd = today.getDate().toString().length == 1 ? "0" + today.getDate().toString() : today.getDate().toString()

//     const mm = String(today.getMonth() + 1).length == 1 ? "0" + String(today.getMonth() + 1) : today.getMonth() + 1

//     const yyyy = today.getFullYear()

//     const fromate_date = (today = yyyy + "-" + mm + "-" + dd)

//     this.setState({ fromate_date })

//   }
// //   onChangeStartDate(event) {
// //     const newStartDate = event.target.value === '' ? null : event.target.value;
// //     const newEndDate = newStartDate > this.state.publishTemplate.end_date ? null : this.state.publishTemplate.end_date;
// //     const newCcLevel = newStartDate > this.state.publishTemplate.end_date ? 2 : newStartDate === '' ? 2 : 3;

// //     this.setState((prevState) => ({
// //         ptl_startdate: newStartDate,
// //         cc_level: newCcLevel,
// //         publishTemplate: {
// //             ...prevState.publishTemplate,
// //             start_date: newStartDate,
// //             end_date: newEndDate,
// //             cc_level: newCcLevel,
// //         },
// //     }));

// //     this.updatePublishTemplateData();
// // }

// onChangeStartDate(event) {
//   // Create a copy of the current state to make changes
//   const newState = { ...this.state };

//   newState.publishTemplate.start_date = event.target.value === '' ? null : event.target.value;
//   newState.publishTemplate.end_date = event.target.value > newState.publishTemplate.end_date ? null : newState.publishTemplate.end_date;

//   if (newState.ptl_startdate > newState.ptl_enddate) {
//     newState.publishTemplate.cc_level = 2;
//   } else {
//     newState.publishTemplate.cc_level = event.target.value === '' ? 2 : 3;
//   }

//   // Update the state using setState
//   this.setState({
//     ptl_startdate: event.target.value,
//     cc_level: newState.publishTemplate.cc_level,
//     publishTemplate: newState.publishTemplate
//   }, () => {
//     // Callback function after state is updated
//     this.updatePublishTemplateData();
//   });
// }

// onChangeEndDate(event) {
//   // Update state using setState
//   this.setState((prevState) => {
//     const updatedPublishTemplate = { ...prevState.publishTemplate };
//     updatedPublishTemplate.end_date = event.target.value === '' ? null : event.target.value;
//     updatedPublishTemplate.cc_level = event.target.value === '' ? 2 : 3;

//     return {
//       ptl_enddate: event.target.value,
//       cc_level: updatedPublishTemplate.cc_level,
//       publishTemplate: updatedPublishTemplate,
//     };
//   }, () => {
//     // Callback function after state is updated
//     this.updatePublishTemplateData();
//   });
// }




//   //   onChangeStartDate(event) {
//   //   this.state.publishTemplate.start_date = event.target.value === '' ? null : event.target.value
//   //  this.state.publishTemplate.end_date = event.target.value > this.state.publishTemplate.end_date ? null :this.state.publishTemplate.end_date
//   //   if(this.state.ptl_startdate > this.state.ptl_enddate ){
//   //    this.state.publishTemplate.cc_level =    2 
//   //   }else{
//   //    this.state.publishTemplate.cc_level =   event.target.value === '' ? 2 : 3

//   //   }
//   //   this.setState({ ptl_startdate: event.target.value, cc_level: this.state.publishTemplate.cc_level, publishTemplate: this.state.publishTemplate  })
//   //   this.updatePublishTemplateData()
//   // }

//   // onChangeEndDate(event) {
//   //   // console.log('event.target.value', event.target.value)
//   //   this.state.publishTemplate.end_date = event.target.value === '' ? null : event.target.value
//   //   this.state.publishTemplate.cc_level =   event.target.value === '' ? 2 : 3
//   //   this.setState({ ptl_enddate:  event.target.value , cc_level: this.state.publishTemplate.cc_level, publishTemplate: this.state.publishTemplate })
//   //   this.updatePublishTemplateData()
//   // }

// //   onChangeEndDate(event) {
// //     const newEndDate = event.target.value === '' ? null : event.target.value;
// //     const newCcLevel = newEndDate === '' ? 2 : 3;

// //     this.setState((prevState) => ({
// //         ptl_enddate: newEndDate,
// //         cc_level: newCcLevel,
// //         publishTemplate: {
// //             ...prevState.publishTemplate,
// //             end_date: newEndDate,
// //             cc_level: newCcLevel,
// //         },
// //     }));

// //     this.updatePublishTemplateData();
// // }




//   // onChangeStartDate(event) {

//   //   this.state.publishTemplate.start_date = event.target.value

//   //   this.state.publishTemplate.end_date = null

//   //   //this.state.publishTemplate.cc_level = 3

//   //   this.setState({ ptl_startdate: event.target.value, })

//   //   this.updatePublishTemplateData()

//   // }



//   // onChangeEndDate(event) {

//   //   this.state.publishTemplate.end_date = event.target.value

//   //   //this.state.publishTemplate.cc_level = 3

//   //   this.setState({ ptl_enddate: event.target.value,  })

//   //   this.updatePublishTemplateData()

//   // }



//   // onChangeStartTime(e) {



//   //   var getTimeFromEvent = e[0].toLocaleTimeString()

//   //   var getHour = getTimeFromEvent.slice(0, 2)

//   //   var getMinTime = String(parseInt(getHour + 1)) + "00"





//   //   this.state.publishTemplate.repeat_mode_config = {

//   //     mode: this.state.publishTemplate.repeat_mode_config.mode,

//   //     mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,

//   //     start_date: this.state.publishTemplate.repeat_mode_config.start_date,

//   //     end_date: this.state.publishTemplate.repeat_mode_config.end_date,

//   //     days: this.state.publishTemplate.repeat_mode_config.days,

//   //     start_time: [getTimeFromEvent],

//   //     end_time: this.state.publishTemplate.repeat_mode_config.end_time,

//   //     start_on: this.state.publishTemplate.repeat_mode_config.start_on,

//   //     ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,

//   //     mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,

//   //     mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,



//   //   }





//   //   this.setState({ daily_starttime: e, getMinTime })

//   // }


//   onChangeStartTime(e) {
//     const getTimeFromEvent = e[0].toLocaleTimeString();
//     const getHour = getTimeFromEvent.slice(0, 2);
//     const getMinTime = String(parseInt(getHour + 1)) + "00";

//     this.setState((prevState) => ({
//         daily_starttime: e,
//         getMinTime,
//         publishTemplate: {
//             ...prevState.publishTemplate,
//             repeat_mode_config: {
//                 ...prevState.publishTemplate.repeat_mode_config,
//                 start_time: [getTimeFromEvent],
//             },
//         },
//     }));
// }




//   // onChangeEndTime(e) {



//   //   var getTimeFromEvent = e[0].toLocaleTimeString()

//   //   this.state.publishTemplate.repeat_mode_config = {

//   //     mode: this.state.publishTemplate.repeat_mode_config.mode,

//   //     mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,

//   //     start_date: this.state.publishTemplate.repeat_mode_config.start_date,

//   //     end_date: this.state.publishTemplate.repeat_mode_config.end_date,

//   //     days: this.state.publishTemplate.repeat_mode_config.days,

//   //     start_time: this.state.publishTemplate.repeat_mode_config.start_time,

//   //     end_time: [getTimeFromEvent],

//   //     start_on: this.state.publishTemplate.repeat_mode_config.start_on,

//   //     ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,

//   //     mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,

//   //     mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,



//   //   }

//   //   this.setState({ daily_endtime: e })

//   // }

//   onChangeEndTime(e) {
//     const getTimeFromEvent = e[0].toLocaleTimeString();

//     this.setState((prevState) => ({
//         daily_endtime: e,
//         publishTemplate: {
//             ...prevState.publishTemplate,
//             repeat_mode_config: {
//                 ...prevState.publishTemplate.repeat_mode_config,
//                 end_time: [getTimeFromEvent],
//             },
//         },
//     }));
// }





//   // monthStartOn(event) {

//   //   this.state.publishTemplate.repeat_mode_config = {

//   //     mode: this.state.publishTemplate.repeat_mode_config.mode,

//   //     mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,

//   //     start_date: this.state.publishTemplate.repeat_mode_config.start_date,

//   //     end_date: this.state.publishTemplate.repeat_mode_config.end_date,

//   //     days: this.state.publishTemplate.repeat_mode_config.days,

//   //     start_time: this.state.publishTemplate.repeat_mode_config.start_time,

//   //     end_time: this.state.publishTemplate.repeat_mode_config.end_time,

//   //     start_on: event.target.value,

//   //     ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,

//   //     mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,

//   //     mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

//   //   }



//   //   this.setState({

//   //     dsf: event.target.value,

//   //     dayEndNumber: this.generateNumber(parseInt(event.target.value) + 1),

//   //     publishTemplate: this.state.publishTemplate

//   //   })

//   //   this.updatePublishTemplateData()

//   // }


//   monthStartOn(event) {
//     this.setState((prevState) => ({
//         publishTemplate: {
//             ...prevState.publishTemplate,
//             repeat_mode_config: {
//                 ...prevState.publishTemplate.repeat_mode_config,
//                 start_on: event.target.value,
//             },
//         },
//         dsf: event.target.value,
//         dayEndNumber: this.generateNumber(parseInt(event.target.value) + 1),
//     }), () => {
//         this.updatePublishTemplateData();
//     });
// }



// monthEndsOn(event) {
//   this.setState((prevState) => ({
//       publishTemplate: {
//           ...prevState.publishTemplate,
//           repeat_mode_config: {
//               ...prevState.publishTemplate.repeat_mode_config,
//               ends_on: event.target.value,
//           },
//       },
//       eoem: event.target.value,
//   }), () => {
//       this.updatePublishTemplateData();
//   });
// }




//   // monthEndsOn(event) {



//   //   this.state.publishTemplate.repeat_mode_config = {

//   //     mode: this.state.publishTemplate.repeat_mode_config.mode,

//   //     mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,

//   //     start_date: this.state.publishTemplate.repeat_mode_config.start_date,

//   //     end_date: this.state.publishTemplate.repeat_mode_config.end_date,

//   //     days: this.state.publishTemplate.repeat_mode_config.days,

//   //     start_time: this.state.publishTemplate.repeat_mode_config.start_time,

//   //     end_time: this.state.publishTemplate.repeat_mode_config.end_time,

//   //     start_on: this.state.publishTemplate.repeat_mode_config.start_on,

//   //     ends_on: event.target.value,

//   //     mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,

//   //     mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

//   //   }

//   //   this.setState({

//   //     eoem: event.target.value,

//   //     publishTemplate: this.state.publishTemplate

//   //   })

//   //   this.updatePublishTemplateData()

//   // }







//   updateCCLevel() {

//   var updateCCState = Validation.updatecclevel(this.state.methodSelected, this.state.publishTemplate)

//   this.setState({ publishTemplate: updateCCState, cc_level: updateCCState.cc_level })

//   }



  





//   async updatePublishTemplateData() {

//     // console.log('1414') 



//     this.updateCCLevel()



//     try {

//       urlSocket.post("webphlbconf/updatepublishtemplate", {

//         userInfo: {

//           encrypted_db_url:this.state.db_info.encrypted_db_url,

//           created_by: this.state.userInfo._id,

//           company_id: this.state.userInfo.company_id

//         },

//         hInfo: {

//           h_id: this.state.hirerachyData._id,

//           publishtemplateInfo: this.state.publishTemplate

//         }

//       })

//         .then(response => {

//           //(response,'response')

//           if (response.data.response_code == 500) {

//             //("update response", response.data.data.cc_level)

//             this.setState({

//               cc_level: response.data.data.cc_level,

//             })

//           }

//         })

//     } catch (error) {

//       //("catch error", error)

//     }

//   }



//   selectRepeatMode(event) {
//     const repeatMode = this.state.repeatModeData[parseInt(event.target.value)];

//     this.setState((prevState) => ({
//         cc_level: 3,
//         repeat_mode: repeatMode.mode,
//         weekDays: prevState.publishTemplate.repeat_mode_config.days,
//         rm_config: repeatMode,
//         daily_starttime: prevState.publishTemplate.repeat_mode_config.start_time,
//         daily_endtime: prevState.publishTemplate.repeat_mode_config.end_time,
//         dsf: prevState.publishTemplate.repeat_mode_config.start_on,
//         eoem: prevState.publishTemplate.repeat_mode_config.ends_on,
//         selectedRepeatMode: event.target.value,
//         publishTemplate: {
//             ...prevState.publishTemplate,
//             cc_level: 3,
//             repeat_mode: repeatMode.mode,
//             repeat_mode_config: {
//                 ...prevState.publishTemplate.repeat_mode_config,
//                 mode: repeatMode.mode,
//                 mode_id: repeatMode.mode_id,
//             },
//         },
//     }), () => {
//         this.updatePublishTemplateData();
//     });
// }






//   // selectRepeatMode(event) {

//   //   // console.log(event,'event')

//   //   var repeatMode = this.state.repeatModeData[parseInt(event.target.value)]



//   //   this.state.publishTemplate.repeat_mode = repeatMode.mode

//   //   this.state.publishTemplate.cc_level = 3

//   //   this.state.publishTemplate.repeat_mode_config = {

//   //     mode: repeatMode.mode,

//   //     mode_id: repeatMode.mode_id,

//   //     start_date: this.state.publishTemplate.repeat_mode_config.start_date,

//   //     end_date: this.state.publishTemplate.repeat_mode_config.end_date,

//   //     days: this.state.publishTemplate.repeat_mode_config.days,

//   //     start_time: this.state.publishTemplate.repeat_mode_config.start_time,

//   //     end_time: this.state.publishTemplate.repeat_mode_config.end_time,

//   //     start_on: this.state.publishTemplate.repeat_mode_config.start_on,

//   //     ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,

//   //     mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,

//   //     mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,



//   //   }



//   //   this.setState({

//   //     cc_level: 3,

//   //     repeat_mode: repeatMode.mode,

//   //     weekDays: this.state.publishTemplate.repeat_mode_config.days,

//   //     rm_config: repeatMode,

//   //     daily_starttime: this.state.publishTemplate.repeat_mode_config.start_time,

//   //     daily_endtime: this.state.publishTemplate.repeat_mode_config.end_time,

//   //     dsf: this.state.publishTemplate.repeat_mode_config.start_on,

//   //     eoem: this.state.publishTemplate.repeat_mode_config.ends_on,

//   //     selectedRepeatMode: event.target.value

//   //   })

//   //   this.updatePublishTemplateData()

//   // }



//   navigateTo = (data) => {

//     sessionStorage.removeItem("EditPublishedData");

//     sessionStorage.removeItem("nagiationMode");

//     sessionStorage.setItem("EditPublishedData", JSON.stringify(data));

//     sessionStorage.setItem("nagiationMode", "from_config");

//     this.props.history.push("/edtpblhtempt");

//   }



//   // handleWeekDays(e) {

//   //   var getIndex = _.findIndex(this.state.weekDays, { day: e.target.id })

//   //   this.state.weekDays[getIndex]["checked"] = e.target.checked

//   //   this.state.publishTemplate.repeat_mode_config = {

//   //     mode: this.state.publishTemplate.repeat_mode_config.mode,

//   //     mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,

//   //     start_date: this.state.publishTemplate.repeat_mode_config.start_date,

//   //     end_date: this.state.publishTemplate.repeat_mode_config.end_date,

//   //     days: [...this.state.weekDays],

//   //     start_time: this.state.publishTemplate.repeat_mode_config.start_time,

//   //     end_time: this.state.publishTemplate.repeat_mode_config.end_time,

//   //     start_on: this.state.publishTemplate.repeat_mode_config.start_on,

//   //     ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,

//   //     mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,

//   //     mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,



//   //   }



//   //   if (this.state.publishTemplate.repeat_mode === "Weekly" &&

//   //     _.some(this.state.publishTemplate.repeat_mode_config.days, { checked: true })) {

//   //     this.state.publishTemplate["cc_level"] = 3

//   //   }

//   //   else {

//   //     this.state.publishTemplate["cc_level"] = 2

//   //   }



//   //   this.setState({ weekDays: [...this.state.weekDays], publishTemplate: this.state.publishTemplate, cc_level: this.state.publishTemplate.cc_level })

//   // }


//   handleWeekDays(e) {
//     const updatedWeekDays = [...this.state.weekDays];
//     const getIndex = _.findIndex(updatedWeekDays, { day: e.target.id });
//     updatedWeekDays[getIndex]["checked"] = e.target.checked;

//     const updatedPublishTemplate = {
//         ...this.state.publishTemplate,
//         repeat_mode_config: {
//             ...this.state.publishTemplate.repeat_mode_config,
//             days: [...updatedWeekDays],
//         },
//     };

//     if (
//         updatedPublishTemplate.repeat_mode === "Weekly" &&
//         _.some(updatedPublishTemplate.repeat_mode_config.days, { checked: true })
//     ) {
//         updatedPublishTemplate["cc_level"] = 3;
//     } else {
//         updatedPublishTemplate["cc_level"] = 2;
//     }

//     this.setState({
//         weekDays: updatedWeekDays,
//         publishTemplate: updatedPublishTemplate,
//         cc_level: updatedPublishTemplate.cc_level,
//     });
// }




//   ordinal_suffix_of(i) {

//     var j = i % 10,

//       k = i % 100;

//     if (j == 1 && k != 11) {

//       return "st";

//     }

//     if (j == 2 && k != 12) {

//       return "nd";

//     }

//     if (j == 3 && k != 13) {

//       return "rd";

//     }

//     return "th";

//   }



//   // handleMStartGroupChange(event) {



//   //   //("event trrigered", event.target.name)



//   //   this.state.publishTemplate.repeat_mode_config = {

//   //     mode: this.state.publishTemplate.repeat_mode_config.mode,

//   //     mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,

//   //     start_date: this.state.publishTemplate.repeat_mode_config.start_date,

//   //     end_date: this.state.publishTemplate.repeat_mode_config.end_date,

//   //     days: this.state.publishTemplate.repeat_mode_config.days,

//   //     start_time: this.state.publishTemplate.repeat_mode_config.start_time,

//   //     end_time: this.state.publishTemplate.repeat_mode_config.end_time,

//   //     start_on: event.target.name === "month_start" ? null : this.state.publishTemplate.repeat_mode_config.start_on,

//   //     ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,

//   //     mstartoption: event.target.name,

//   //     mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

//   //   }



//   //   this.setState({

//   //     mstartoption: event.target.name,

//   //   })

//   //   this.updatePublishTemplateData()

//   // }


//   handleMStartGroupChange(event) {
//     const updatedMStartOption = event.target.name;

//     const updatedPublishTemplateConfig = {
//         mode: this.state.publishTemplate.repeat_mode_config.mode,
//         mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
//         start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//         end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//         days: this.state.publishTemplate.repeat_mode_config.days,
//         start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//         end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//         start_on: updatedMStartOption === "month_start" ? null : this.state.publishTemplate.repeat_mode_config.start_on,
//         ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
//         mstartoption: updatedMStartOption,
//         mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,
//     };

//     this.setState({
//         mstartoption: updatedMStartOption,
//         publishTemplate: {
//             ...this.state.publishTemplate,
//             repeat_mode_config: updatedPublishTemplateConfig,
//         },
//     });

//     this.updatePublishTemplateData();
// }




//   // handleMEndGroupChange(event) {



//   //   //("event trrigered --", event.target.name)

//   //   this.state.publishTemplate.repeat_mode_config = {

//   //     mode: this.state.publishTemplate.repeat_mode_config.mode,

//   //     mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,

//   //     start_date: this.state.publishTemplate.repeat_mode_config.start_date,

//   //     end_date: this.state.publishTemplate.repeat_mode_config.end_date,

//   //     days: this.state.publishTemplate.repeat_mode_config.days,

//   //     start_time: this.state.publishTemplate.repeat_mode_config.start_time,

//   //     end_time: this.state.publishTemplate.repeat_mode_config.end_time,

//   //     start_on: this.state.publishTemplate.repeat_mode_config.start_on,

//   //     ends_on: event.target.name === "month_end" ? null : this.state.publishTemplate.repeat_mode_config.ends_on,

//   //     mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,

//   //     mendoption: event.target.name,

//   //   }

//   //   this.setState({

//   //     mendoption: event.target.name,

//   //   })

//   //   this.updatePublishTemplateData()

//   // }



//   handleMEndGroupChange(event) {
//     const updatedMEndOption = event.target.name;

//     const updatedPublishTemplateConfig = {
//         mode: this.state.publishTemplate.repeat_mode_config.mode,
//         mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
//         start_date: this.state.publishTemplate.repeat_mode_config.start_date,
//         end_date: this.state.publishTemplate.repeat_mode_config.end_date,
//         days: this.state.publishTemplate.repeat_mode_config.days,
//         start_time: this.state.publishTemplate.repeat_mode_config.start_time,
//         end_time: this.state.publishTemplate.repeat_mode_config.end_time,
//         start_on: this.state.publishTemplate.repeat_mode_config.start_on,
//         ends_on: updatedMEndOption === "month_end" ? null : this.state.publishTemplate.repeat_mode_config.ends_on,
//         mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
//         mendoption: updatedMEndOption,
//     };

//     this.setState({
//         mendoption: updatedMEndOption,
//         publishTemplate: {
//             ...this.state.publishTemplate,
//             repeat_mode_config: updatedPublishTemplateConfig,
//         },
//     });

//     this.updatePublishTemplateData();
// }




//   deleteEndpoint = () => {

//     try {

//       urlSocket.post("webphlbconf/deleteendpoints", {

//         userInfo: {

//           encrypted_db_url: this.state.db_info.encrypted_db_url,

//           created_by: this.state.userInfo._id,

//           company_id: this.state.userInfo.company_id

//         },

//         hInfo: {

//           h_id: this.state.hirerachyData._id,

//           publishtemplateInfo: this.state.publishTemplate

//         },

//         endpointInfo: this.state.deleteItem

//       })

//         .then(response => {

//           if (response.data.response_code === 500) {



//             var publishTemplate = response.data.data





//             this.setState({

//               publishTemplate: publishTemplate,

//               endpoints: response.data.data.endpoints,

//               changeMethodConfirmation: false,

//             }, function () {

//               this.updateCCLevel()

//             })

//           }

//         })

//     } catch (error) {

//       //("catch error", error)

//     }

//   }



//   treeDataToFlat = async () => {

//     // //("this.state.treeData", this.state.treeData)

//     var getNodeKey = ({ treeIndex }) => treeIndex

//     var flatData = getFlatDataFromTree(

//       {

//         treeData: this.state.publishTemplate.template_structure,

//         getNodeKey,

//         ignoreCollapsed: false,

//       })



//     var explicitData = _.filter(flatData, item => {

//       return item

//     })



//     return explicitData

//   }



//   checkPublishDate = () => {



//     var currentDate = moment(new Date()).format("YYYY-MM-DD")

//     var pbd_startDate = moment(new Date(this.state.publishTemplate.start_date)).format("YYYY-MM-DD")

//     var pbd_endDate = moment(new Date(this.state.publishTemplate.end_date)).format("YYYY-MM-DD")



//     if (moment(pbd_startDate).isBefore(currentDate)) {

//       this.setState({ ederror: false, sderror: true })

//       //("check start date")

//     }



//     if (moment(pbd_endDate).isBefore(currentDate)) {

//       this.setState({ sderror: false, ederror: true })

//       //("check end date")

//     }



//     if (moment(pbd_startDate).isBefore(currentDate) == false && moment(pbd_endDate).isBefore(currentDate) == false) {

//       this.setState({ sderror: false, ederror: false })

//       return true

//     }

//     else {

//       return false

//     }

//   }



//   calculateMaxDate() {

//     const { ptl_startdate } = this.state;

  
//     try {
      
    
      
    
//     if (ptl_startdate === '' || ptl_startdate === undefined) {

//       return null; // Return null if start date is not set

//     }

  

//     const startDate = new Date(ptl_startdate);

//     startDate.setFullYear(startDate.getFullYear() + this.state.client_info.rept_mode_periodicity);

  

//     return startDate.toISOString().slice(0, 10); // Format the date as "YYYY-MM-DD"
//   } catch (error) {

//   }
//   }

  







//   publishTmpltAsAudit = async () => {



//     var checkpoints = await this.treeDataToFlat()
//     var checkDates = this.checkPublishDate()



//     //("checkDates", checkDates)

//     // console.log(this.state.publishTemplate,'this.state.publishTemplate')

//     if (checkDates) {



//       try {

//         urlSocket.post("webphlbprcs/publishtmpltasaudit", {

//           userInfo: {

//             encrypted_db_url:this.state.db_info.encrypted_db_url,

//             created_by: this.state.userInfo._id,

//             company_id: this.state.userInfo.company_id

//           },

//           hInfo: {

//             publishtemplateInfo: this.state.publishTemplate,

//             // checkpoints,

//           },

//         })

//           .then(response => {

//             //("response", response)

//             if (response.data.response_code === 500) {

//               this.setState({

//                 tmpltPublishProcess: true,

//                 alertMessage: "Audit publishing started successfully click",

//                 processStatus: "1"

//               })

//             }

//             else {

//               this.setState({

//                 tmpltPublishProcess: true,

//                 alertMessage: "Audit not publish. Please check the data",

//                 processStatus: "0"

//               })

//             }

//           })

//       } catch (error) {

//         //("catch error", error)

//       }

//     }

//     else {

//       //("check start or end date")

//     }



//   }



//   redirectTo(processStatus) {



//     this.setState({ tmpltPublishProcess: false })



//     if (processStatus == "1") {

//       sessionStorage.removeItem("EditPublishedData");

//       sessionStorage.removeItem("nagiationMode");

//       this.props.history.push("/mngpblhtempt");

//     }

//   }





//   deleteAllRequest(){



//     if(this.state.publishTemplate.endpoints.length === 0)

//     {

//       this.setState({dataAlert:true, alertMessage:"No end points to delete"})

//     }

//     else{

//       this.setState({triggerFrom:"deleteall", changeMethodConfirmation: true})

//     }





//   }





//   select_country=(e)=>{

//     //(e.target.value)

//     this.setState({countrycode:e.target.value})

// }





//   addPermissions(e, item){



//     var getIndex = _.findIndex(this.state.permissionsadded, {id:item.id})



//     if(getIndex === -1)

//     {

//         this.state.permissionsadded.push(item)

//         this.setState({

//             permissionsadded:this.state.permissionsadded

//         })

//     }

//     else

//     {

//         this.state.permissionsadded.splice(getIndex, 1)

//         this.setState({

//             permissionsadded:this.state.permissionsadded

//         })

//     }



//     //("permissionsadded", this.state.permissionsadded)



// }







//   validate_email = (e, mode) => {

//     //(e.target.value, "events",this.state.usercode)

//     var admn_info = {}

//     if (mode == 1) {

//         admn_info["email_id"] = e.target.value

//     }

//     if (mode === 2) {

//         admn_info["phone_number"] = e.target.value

//     }

//     if (mode === 3) {

//         admn_info["usercode"] = e.target.value

//     }

//     admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url

//     admn_info["db_name"] = this.state.db_info.db_name

//     admn_info["user_id"] = this.state.userInfo._id



//     if(this.state.usercode !==undefined || admn_info["email_id"] !== undefined || admn_info["phone_number"] !== undefined ){

//     try {

//         urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {

//             //(data, 'data')

//             if (mode == 1) {

//                 if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {

//                     this.setState({ invalid_user: true })

//                 }

//                 else {

//                     this.setState({ invalid_user: false })

//                 }

//             }

//             if (mode == 2) {



//                 if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {

//                     this.setState({ invalid_phone_num: true })

//                 }

//                 else {

//                     this.setState({ invalid_phone_num: false })

//                 }

//             }

//             if (mode === 3) {

//                 if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {

//                     this.setState({ invalid_usercode: true })

//                 }

//                 else {

//                     this.setState({ invalid_usercode: false })

//                 }





//             }

//         })



//     } catch (error) {



//     }

// }



// }





//   emai_addrs = (e) => {

//     var email = e.target.value

//     if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {

//         this.setState({ given_email: email , errorMessage : false})

//     }

//     else{

//         // console.log('enter a valid email id')

//         this.setState({ errorMessage: true ,given_email: email })

//     }



//     // this.setState({ given_email: e.target.value })

// }



// userCode=(e)=>{

//     this.setState({usercode : e.target.value})

// }





// loadEOPTS = () => {

//   //( 'userdetais')

//   this.setState({ tableDataloaded: false })

//   try {

//       urlSocket.post("cog/get-user-info",   

//       {  db_info :  this.state.db_info,

//         user_id :this.state.userInfo._id

//     }

//       )

//           .then((response) => {

//               //(response, 'response')

//               this.setState({

//                 userdata : response.data.user_list,

//                 auditUserlevelSelected :false

//                 // dataloaded : false,

//                   // statusBasedFilteredData: response.data.user_list,

//                   // tableDataloaded: true,

//                   // allowTableRowToSelect: true,

//                   // labelDefault: true,

//                   // label: {

//                   //     endpoints: []

//                   // },





//               }, function () {

//                   this.loadUserLabels()

//                   setTimeout(() => {

//                    this.setState({auditUserlevelSelected : true})

                    

//                   }, 50);

//               })



//           })

//   } catch (error) {



//   }

// }











//   handleValidSubmit = (event, values) => {

//     //(event, values)

//     // this.state.client_info.facilities.map((ele, idx) => {

//     //   this.state.permissionsadded.map((ele1, idx1) => {

//     //     if (ele1.id === ele.id) {

//     //       this.state.client_info.facilities[idx].enabled = true

//     //     }

//     //   })

//     // })
//     const updatedClientInfo = this.state.client_info.map((facility) => {
//       // Check if there is a corresponding element in permissionsadded
//       const matchingPermission = this.state.permissionsadded.find((permission) => permission.id === facility.id);

//       // Update the enabled property based on the matching permission
//       return {
//         ...facility,
//         enabled: matchingPermission ? true : false,
//       };
//     });

//     // Update the state with the new client_info array
//     this.setState({ client_info: updatedClientInfo });





//     var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()

//     values["password"] = password

//     values["confirmpassword"] = password

//     values["userPoolId"] = this.state.client_info.userPoolId

//     values["clientId"] = this.state.client_info.clientId

//     values["encrypted_db_url"] = this.state.db_info.encrypted_db_url

//     values["db_name"] = this.state.db_info.db_name

//     values["facilities"] = this.state.client_info.facilities

//     values["company_id"] = this.state.sessionUserInfo.m_client_id

//     values["created_by"] = this.state.sessionUserInfo._id

//     values["short_name"] = this.state.client_info.short_name

//     values["user_id"] = this.state.sessionUserInfo._id





//     //(values, 'values', this.state.permissionsadded)





//     if (this.state.countrycode === undefined) {

//       this.setState({ country_code_err: true })

//     }

//     else {

//       values["countrycode"] = this.state.countrycode

//       this.setState({ country_code_err: false })



//     }

//     //(this.state.client_info.facilities, 'this.state.client_info.facilities')

//     if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false) {

//       this.setState({ loading: true })



//       try {

//         urlSocket.post('cog/cruduser', values).then((res) => {

//           //(res, 'res')

//           if (res.data.response_code === 500) {

//             var created_user = res.data.admn_user[0]

//             //(created_user, 'created_user')

//             this.setState({ loading: false })

//             var event = {}

//             var target = {}

//             target["value"] = created_user._id

//             event["target"] = target

//             //(event, 'events')

//             // this.props.didmount()

//             this.state.userdata.push(created_user)

//             // this.state.endpoints.push(created_user)



//             //('this.state.publishTemplate.endpoints[this.state.selected_index]', this.state.publishTemplate.endpoints[this.state.selected_index])

//             if(this.state.user_type === 0 ){

//               //("auditer")

//             if (this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0) {

//               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.push({

//                 user_id: created_user._id,

//                 name: created_user.firstname,

//                 designation: created_user.designation,

//                 user_code: created_user.usercode,

//                 audit_type: "1"

//               })

//             } else {
//               // Create a copy of the publishTemplate
//               const updatedPublishTemplate = { ...this.state.publishTemplate };

//               // Create a copy of the selected endpoint
//               const selectedEndpoint = { ...updatedPublishTemplate.endpoints[this.state.selected_index] };

//               // Create a copy of the adt_users array
//               const adtUsers = [...selectedEndpoint.adt_users];

//               // Create a new adt_user object with updated values
//               const newAdtUser = {
//                 user_id: created_user._id,
//                 name: created_user.firstname,
//                 designation: created_user.designation,
//                 user_code: created_user.user_code,
//                 audit_type: '1',
//               };

//               // Replace the first element (index 0) in the adtUsers array with the newAdtUser
//               adtUsers[0] = newAdtUser;

//               // Update the selectedEndpoint with the new adt_users array
//               selectedEndpoint.adt_users = adtUsers;

//               // Replace the selected endpoint with the updated selectedEndpoint
//               updatedPublishTemplate.endpoints[this.state.selected_index] = selectedEndpoint;

//               // Update the state with the new publishTemplate
//               this.setState({ publishTemplate: updatedPublishTemplate });


//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_id'] = created_user._id

//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['name'] = created_user.firstname

//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['designation'] = created_user.designation

//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_code'] = created_user.user_code

//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['audit_type'] = '1'

//             }

//             }

//             else{

//               if(this.state.user_type === 1){

//                 //("reviewer")

//               if(this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.length === 1 || this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0 ){

//               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.push({

//                 user_id: created_user._id,

//                 name: created_user.firstname,

//                 designation: created_user.designation,

//                 user_code: created_user.usercode,

//                 audit_type: "2",

//               })

//             }

//             else{

//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_id'] = created_user._id

//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['name'] = created_user.firstname

//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['designation'] = created_user.designation

//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_code'] = created_user.user_code

//               // this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['audit_type'] = '2'

//               // Create a copy of the publishTemplate
//                 const updatedPublishTemplate = { ...this.state.publishTemplate };

//                 // Create a copy of the selected endpoint
//                 const selectedEndpoint = { ...updatedPublishTemplate.endpoints[this.state.selected_index] };

//                 // Create a copy of the adt_users array
//                 const adtUsers = [...selectedEndpoint.adt_users];

//                 // Create a new adt_user object with updated values
//                 const newAdtUser = {
//                   user_id: created_user._id,
//                   name: created_user.firstname,
//                   designation: created_user.designation,
//                   user_code: created_user.user_code,
//                   audit_type: '2',
//                 };

//                 // Replace the second element (index 1) in the adtUsers array with the newAdtUser
//                 adtUsers[1] = newAdtUser;

//                 // Update the selectedEndpoint with the new adt_users array
//                 selectedEndpoint.adt_users = adtUsers;

//                 // Replace the selected endpoint with the updated selectedEndpoint
//                 updatedPublishTemplate.endpoints[this.state.selected_index] = selectedEndpoint;

//                 // Update the state with the new publishTemplate
//                 this.setState({ publishTemplate: updatedPublishTemplate });


//             }

//           }



//             }



//             // .push({

//             //   user_id: created_user._id,

//             //   name: created_user.firstname,

//             //   designation: created_user.designation,

//             //   user_code: created_user.usercode,

//             //   audit_type: "1"

//             // })



//             this.setState({ userdata: this.state.userdata, publishTemplate: this.state.publishTemplate }, () => {



//               this.state.user_type === 0 ?

//                 this.changeAuditUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index,"0")

//                 : this.changeReviewUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index,"0")

//               this.onDrawerClose()

//             })

//             //('this.state.endpoints', this.state.endpoints)

//             //(this.state.userdata,)

//           }

//           else {

//             this.setState({ loading: false })



//           }

//         })



//       } catch (error) {

//         //(error, 'error')

//       }



//     }



//   }



//   handle_click=(e)=>{

//     // console.log(e.target.name, e.target.checked,this.state.publishTemplate)

//     // this.state.publishTemplate[e.target.name] =e.target.checked

//     // this.setState({publishTemplate : this.state.publishTemplate})
//     // Create a copy of the publishTemplate
//     const updatedPublishTemplate = { ...this.state.publishTemplate };

//     // Update the specific property using the event target name and value
//     updatedPublishTemplate[e.target.name] = e.target.checked;

//     // Update the state with the new publishTemplate
//     this.setState({ publishTemplate: updatedPublishTemplate });


//   }





//   labelSelected(data) {



//     if (data.target.value === "all") {

//         this.loadEOPTS()

//     }

//     else {

//         //("label data", this.state.labelData[data.target.value])

//       // console.log(data,this.state.labelData,data.target.value)

//         var mylabel = this.state.labelData[data.target.value]

//         // console.log(mylabel,'mylabel')

//         // this.setState({

//         //     tableDataloaded: false, selectedLabel: mylabel._id,

//         //     label: mylabel

//         // })



//         try {



//             urlSocket.post("userSettings/load-group-users", {

//                 labelInfo: mylabel,

//                 userInfo: {

//                     encrypted_db_url: this.state.db_info.encrypted_db_url,

//                     _id: this.state.userInfo._id,

//                     company_id: this.state.userInfo.company_id

//                 }

//             })

//                 .then(response => {

//                     //("response", response.data.data)

//                     this.setState({ statusBasedFilteredData: response.data.data, userdata: response.data.data, auditUserlevelSelected : false

//                     },()=>

//                     {

//                       setTimeout(() => {

//                       this.setState({auditUserlevelSelected:true});

                        

//                       }, 50);

                      

//                   })

//                 })

//                 // this.setState({dataloaded:true}) 

//         } catch (error) {

//             //("catch error", error)

//         }



//     }

//     this.setState({user_group_selected : data.target.value})



// }

































//   deleteAllEndpoints(){







//     try {

//       var loggedUserInfo = {

//           company_id: this.state.userInfo.company_id,

//           company_name: this.state.userInfo.company_name,

//           created_by:this.state.userInfo._id,

//           encrypted_db_url : this.state.db_info.encrypted_db_url

//       }

//       urlSocket.post("webphlbconf/deleteallendpoints", {

//           userInfo: loggedUserInfo,

//           publishtemplateInfo: this.state.publishTemplate

//       })

//       .then(response=>{

//           if(response.data.response_code === 500)

//           {

//                   this.setState({changeMethodConfirmation: false,

//                     }, function(){

//                     this.onDrawerClose()

//                   })

                  

//           }

//       })

//   } catch (error) {

//       //("catch error", error)

//   }



//   }







//   repeatModeComponent(mode) {

//     return (

//       <Form action="#">



//         {

//           mode == "0" ?



//             <div>

//               {/* <div className="mb-3 d-flex flex-column  ">

//                 <div className="d-flex flex-row align-items-center">

//                   <div className="col-3">

//                     <Label className="text-primary" htmlFor="autoSizingSelect">Start date</Label>

//                     <div className="col-md-10">

//                       <input

//                         className="form-control"

//                         type="date"

//                         min={this.state.fromate_date}

//                         selected={this.state.ptl_startdate}

//                         defaultValue={this.state.ptl_startdate}

//                         id="start_date"

//                         onChange={event => this.onChangeStartDate(event)}

//                       />

//                     </div>

//                   </div>

//                   <div className="col-3">

//                     <Label className="text-primary" htmlFor="autoSizingSelect">End date</Label>

//                     <div className="col-md-10">

//                       <input

//                         className="form-control"

//                         type="date"

//                         min={this.state.fromate_date}

//                         selected={this.state.ptl_enddate}

//                         defaultValue={this.state.ptl_enddate}

//                         id="end_date"

//                         onChange={event => this.onChangeEndDate(event)}

//                       />

//                     </div>

//                   </div>



//                 </div>

//               </div> */}

//             </div> : mode == "1" ?



//               <div>

//                 <Row >

//                   <FormGroup className="mb-3 col-3">

//                     <Label className="text-primary" htmlFor="autoSizingSelect">Start time</Label>

//                     <InputGroup>

//                       <Flatpickr

//                         className="form-control d-block"

//                         placeholder="Select time"

//                         options={{

//                           enableTime: true,

//                           noCalendar: true,

//                           dateFormat: "H:i K"

//                         }}

//                         value={this.state.daily_starttime}

//                         onChange={e => this.onChangeStartTime(e)}

//                       />

//                       <div className="input-group-append">

//                         <span className="input-group-text">

//                           <i className="mdi mdi-clock-outline" />

//                         </span>

//                       </div>

//                     </InputGroup>

//                   </FormGroup>



//                   <FormGroup className="mb-3 col-3">

//                     <Label className="text-primary" htmlFor="autoSizingSelect">End Time</Label>

//                     <InputGroup>

//                       <Flatpickr

//                         className="form-control d-block"

//                         placeholder="Select time"

//                         options={{

//                           minTime: this.state.getMinTime,

//                           enableTime: true,

//                           noCalendar: true,

//                           dateFormat: "H:i K"

//                         }}

//                         value={this.state.daily_endtime}

//                         onChange={e => this.onChangeEndTime(e)}

//                       />

//                       <div className="input-group-append">

//                         <span className="input-group-text">

//                           <i className="mdi mdi-clock-outline" />

//                         </span>

//                       </div>

//                     </InputGroup>

//                   </FormGroup>

//                 </Row>





//               </div> : mode == "2" ?

//                 <div style={{ display: 'flex', flexDirection: 'Column' }}>



//                   <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>

//                     {

//                       this.state.weekDays.map((item, idx) => {

//                         return (

//                           <div key={idx} className="form-check mx-2 ">

//                             <input

//                               type="checkbox"

//                               className="form-check-input"

//                               id={item.day}

//                               checked={item.checked}

//                               onChange={e => { this.handleWeekDays(e) }}

//                             />

//                             <label

//                               className="form-check-label"

//                               htmlFor={item.day}

//                             >

//                               {item.day}

//                             </label>

//                           </div>

//                         )

//                       })

//                     }

//                   </div>







//                 </div> : mode == "3" ?

//                   <div>

//                     <Row>



//                       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 30 }}>

//                         <span style={{ fontSize: 13, color: '#74788d' }}>Every month started on &nbsp;&nbsp;</span>

//                         <div className="me-2">

//                           <Label className="card-radio-label">

//                             <Input

//                               type="radio"

//                               name="month_start"

//                               id="ms-1"

//                               className="card-radio-input"

//                               value={this.state.mstartoption}

//                               checked={this.state.mstartoption === "month_start"}

//                               onChange={this.handleMStartGroupChange}

//                             />



//                             <div className="card-radio py-4">

//                               <span style={{

//                                 color: this.state.mstartoption !== "month_start" || this.state.mstartoption === undefined ? "#c0c1c5" : "#74788d"

//                               }}>Month 1st</span>

//                             </div>

//                           </Label>

//                         </div>

//                         <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>

//                         <div className="me-2">

//                           <Label className="card-radio-label">

//                             <Input

//                               type="radio"

//                               name="custom_start"

//                               id="ms-2"

//                               className="card-radio-input"

//                               value={this.state.mstartoption}

//                               checked={this.state.mstartoption === "custom_start"}

//                               onChange={this.handleMStartGroupChange}



//                             />



//                             <div className="card-radio d-flex flex-row align-items-center">

//                               <select

//                                 type="select"

//                                 name="m_starton"

//                                 label="Name"

//                                 value={this.state.mstartoption === "month_start" || this.state.mstartoption === null ? 'choose' : this.state.dsf}

//                                 className="form-select"

//                                 style={{ width: 60 }}

//                                 id="m_starton"

//                                 disabled={this.state.mstartoption === "month_start" || this.state.mstartoption === null}

//                                 onChange={(e) => this.monthStartOn(e)}>

//                                 <option value="choose" disabled >Choose...</option>

//                                 {

//                                   this.state.dayStartNumber.map((data, idx) => {

//                                     return (

//                                       <option value={data.value} selected key={idx}>{data.name}</option>

//                                     )

//                                   })

//                                 }

//                               </select>

//                               {this.state.mstartoption === "month_start" || this.state.mstartoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.dsf)}</span>}

//                             </div>

//                           </Label>

//                         </div>

//                         <span>&nbsp;and ends on &nbsp;&nbsp;</span>

//                         <div className="me-2">

//                           <Label className="card-radio-label">

//                             <Input

//                               type="radio"

//                               name="month_end"

//                               id="pay-methodoption1"

//                               className="card-radio-input"

//                               value={this.state.mendoption}

//                               checked={this.state.mendoption === "month_end"}

//                               onChange={this.handleMEndGroupChange}

//                             />



//                             <div className="card-radio py-4">

//                               <span style={{

//                                 color: this.state.mendoption !== "month_end" || this.state.mendoption === null ? "#c0c1c5" : "#74788d"

//                               }}>Month end</span>

//                             </div>

//                           </Label>

//                         </div>

//                         <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>

//                         <div className="me-2">

//                           <Label className="card-radio-label">

//                             <Input

//                               type="radio"

//                               name="custom_end"

//                               id="pay-methodoption3"

//                               className="card-radio-input"

//                               value={this.state.mendoption}

//                               checked={this.state.mendoption === "custom_end"}

//                               onChange={this.handleMEndGroupChange}



//                             />



//                             <div className="card-radio d-flex flex-row align-items-center">

//                               <select

//                                 type="select"

//                                 name="m_endson"

//                                 label="Name"

//                                 value={this.state.mendoption === "month_end" || this.state.mendoption === null ? 'choose' : this.state.eoem}

//                                 className="form-select"

//                                 style={{ width: 60 }}

//                                 id="m_endson"

//                                 disabled={this.state.mendoption === "month_end" || this.state.mendoption === null}

//                                 onChange={(e) => this.monthEndsOn(e)}>

//                                 <option value="choose" disabled >Choose...</option>

//                                 {

//                                   this.state.dayEndNumber.map((data, idx) => {

//                                     return (

//                                       <option value={data.value} selected key={idx}>{data.name}</option>

//                                     )

//                                   })

//                                 }

//                               </select>

//                               {this.state.mendoption === "month_end" || this.state.mendoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.eoem)}</span>}

//                             </div>

//                           </Label>

//                         </div>

//                       </div>

//                     </Row>

                    

//                   </div> : mode == "4" ?

//                     <div></div> : mode == "5" ?

//                       <div></div> :

//                       null



//         }



//       </Form>

//     )

//   }





//   render() {

//     // console.log(this.state.publishTemplate.endpoints,'this.state.publishTemplate')

//     if (this.state.dataloaded) {

//       const { locationData } = this.state

//       // const { SearchBar } = Search;

//       // const { users } = this.state



//       const { isEdit } = this.state

//       const options = {

//         // pageStartIndex: 0,

//         page: this.state.paginationPage,



//         sizePerPage: 10,

//         totalSize: this.state.publishTemplate.endpoints.length, // replace later with size(users),

//         custom: true,

//         onPageChange: (e) => {

//           //("on page change", e)

//           this.setState({ paginationPage: e })

//         }

//       };





//       const columns = [

       



//         {

//           accessor: 'hlevel_name',

//           Header: 'Location',

//           filterable: true,

//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (

//               <>

//                 <div style={{ display: 'flex', flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>

//                   {item.hlevel_name}

//                 </div>

//               </>

//             )

//           }

//         },

//         {

//           accessor: 'code',

//           Header: 'Code',

//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (

//               <div style={{ color: item.rowValid ? null : "#f46a6a" }}>

//                 {item.code}

//               </div>

//             )

//           }

//         },

//         {

//           accessor: 'hlevel',

//           Header: 'Category',

//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return (

//               <div style={{ color: item.rowValid ? null : "#f46a6a" }}>

//                 {item.hlevel}

//               </div>

//             )

//           }

//         },



//         {

//           accessor: '',

//           Header: 'Audit User',

//           Cell: (cellProps) => {
//             var index = cellProps.row.index
//             var item = cellProps.row.original
//             var mappedUser

//             var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })

//             if (getIndex !== -1) {

//               mappedUser = item.adt_users[getIndex]

//             }

//             else {

//               mappedUser = undefined

//             }

//             // console.log(cellContent, item, index)

//             return (

//               <>

//                 <div>

//                   <select

//                     type="select"

//                     name="adtuser"

//                     label="Name"

//                     value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

//                     className="form-select"

//                     id={"adtuser" + String(item._id)}

//                     onChange={(e) => this.changeAuditUserHandler(e, item, index,"1")}>

//                     <option value="choose" disabled >Choose...</option>

//                     <option value="add" >Add New User</option>

//                     {

//                       this.state.userdata.map((data, idx) => {

//                         return (

//                           <option value={data._id} key={idx}>{data.firstname}</option>

//                         )

//                       })

//                     }

//                   </select>

//                 </div>

//               </>

//             )

//           }

//         },



//         {

//           accessor: '',

//           Header: 'Review User',

//           hidden: this.state.methodSelected === "2" ? true : false,

//           Cell: (cellProps, index) => {

//             var item = cellProps.row.original

//             var mappedUser

//             var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })

//             if (getIndex !== -1) {

//               mappedUser = item.adt_users[getIndex]

//             }

//             else {

//               mappedUser = undefined

//             }



//             return (

//               <>

//                 {

//                   !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null

//                 }

//                 {

//                   this.state.customEnableReview ?

//                     <div>

//                       <select

//                         type="select"

//                         name="rvwuser"

//                         label="Name"

//                         value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

//                         className="form-select"

//                         id={"custrvwuser" + String(item._id)}

//                         onChange={(e) => this.changeReviewUserHandler(e, item, index,"1")}>

//                         <option value="choose" disabled >Choose...</option>

//                         <option value="add" >Add New User</option>

//                         {

//                           this.state.userdata.map((data, idx) => {

//                             return (

//                               <option value={data._id} selected key={idx}>{data.firstname}</option>

//                             )

//                           })

//                         }

//                       </select>

//                     </div> : null

//                 }



//               </>

//             )

//           }

//         },



//         // {

//         //   accessor: '',

//         //   Header: 'Review User',

//         //   hidden: this.state.methodSelected === "2" ? false : true,

//         //   Cell: (cellProps, index) => {


//         //     var item = cellProps.row.original
//         //     var mappedUser

//         //     var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })

//         //     if (getIndex !== -1) {

//         //       mappedUser = item.adt_users[getIndex]

//         //     }

//         //     else {

//         //       mappedUser = undefined

//         //     }



//         //     return (

//         //       <>

//         //         {

//         //           !this.state.enableReview ? <div className="text-secondary">Review not enable</div> : null

//         //         }

//         //         {

//         //           !this.state.reviewUserlevelSelected && this.state.enableReview ? <div className="text-secondary">Select Review user level</div> : null

//         //         }

//         //         {

//         //           this.state.reviewUserlevelSelected ?

//         //             <div>

//         //               <select

//         //                 type="select"

//         //                 name="rvwuser"

//         //                 label="Name"

//         //                 value={mappedUser != undefined ? mappedUser.user_id : 'choose'}

//         //                 className="form-select"

//         //                 id="rvwuser"

//         //                 disabled={!this.state.reviewUserlevelSelected}

//         //                 onChange={(e) => this.changeReviewUserHandler(e, item, index,"1")}>

//         //                 <option value="choose" disabled >Choose...</option>

//         //                 <option value="add" >Add New User</option>

//         //                 {

//         //                   this.state.userdata.map((data, idx) => {

//         //                     return (

//         //                       <option value={data._id} selected key={idx}>{data.firstname}</option>

//         //                     )

//         //                   })

//         //                 }

//         //               </select>

//         //             </div> : null

//         //         }



//         //       </>

//         //     )

//         //   }

//         // },



//         {

//           accessor: "menu",

//           // isDummyField: true,

//           // editable: false,

//           Header: "Action",

//           hidden: this.state.methodSelected === "2" ? true : false,

//           Cell: (cellProps) => {
//             var item = cellProps.row.original
//             return(
//             <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>

//               {/* <Link className="text-primary" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => { this.setState({ open: true, component: "endpoint", editItem: item }) }}></i></Link> */}

//               <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>

//             </div>
//             )

// },

//         },







//       ];

//       // //(columns,'columns')

//       const defaultSorted = [{

//         dataField: 'hname', // if dataField is not match to any column you defined, it will be ignored.

//         order: 'asc' // desc or asc

//       }];

     

//       // //("render",this.state.publishTemplate)



//       return (

//         <React.Fragment>

//           <div className="page-content">

//             <MetaTags>

//               <title>AuditVista | Publishing</title>

//             </MetaTags>

//             <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-2">

//               <div style={{ width: '80%', padding: '0 20px' }}>Publishing</div>

//               <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>

//                 <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>

//               </div>

//             </div>

//             <Container fluid>

//               {

//                 this.state.methodSelected === "0" ? <div>

//                   <Card>

//                     <CardBody>

//                       <Label className="my-3">Add Location by</Label>



//                       <Row>

//                         {/* <Col xl="6" sm="6"> */}

//                           {/* <div className="me-2 text-wrap">

//                             <p className="card-title-desc">

//                               Use the <code>.badge-pill</code> modifier className to

//                               make badges more rounded (with a larger{" "}

//                               <code>border-radius</code>

//                               and additional horizontal <code>padding</code>).

//                               Useful if you miss the badges from v3.

//                             </p>

//                             <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("2")}>Hirerachy Level</button>

//                           </div> */}

//                         {/* </Col> */}



//                         <Col xl="12" sm="12">



//                           <div className="me-2 text-wrap">

//                             <p className="card-title-desc">

//                               Use the <code>.badge-pill</code> modifier className to

//                               make badges more rounded (with a larger{" "}

//                               <code>border-radius</code>

//                               and additional horizontal <code>padding</code>).

//                               Useful if you miss the badges from v3.

//                             </p>

//                             <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("1")}>Create here</button>

//                           </div>

//                         </Col>

//                       </Row>

//                     </CardBody>

//                   </Card>

//                 </div> : null

//               }          



//               {

//                 this.state.methodSelected === "1" ?

//                   <div>



//                     <div className="checkout-tabs">

//                       <Row>

//                         <Col lg="2" sm="3">

//                           <Nav className="flex-column" pills>

//                             <NavItem>

//                               <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f' }}

//                                 className={classnames({

//                                   active: this.state.activeCustomTab === 1 && this.state.cc_level == 0,

//                                   activechange: this.state.activeCustomTab === 1 && this.state.cc_level > 0

//                                 })



//                                 }

//                                 onClick={() => {

//                                   this.toggleCustomTab(1)

//                                 }}

//                               >

//                                 <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />

//                                 <p className="font-weight-bold mb-4">Locations</p>

//                               </NavLink>

//                             </NavItem>

//                             <NavItem>

//                               <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6' }}

//                                 className={classnames({

//                                   active: this.state.activeCustomTab === 2 && this.state.cc_level > 1,

//                                   activechange: this.state.activeCustomTab === 2 && this.state.cc_level > 2

//                                 })}

//                                 onClick={() => {

//                                   this.toggleCustomTab(2)

//                                 }}

//                                 disabled={this.state.cc_level > 1 ? false : true}

//                               >

//                                 <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />

//                                 <p className="font-weight-bold mb-4">Configuration</p>

//                               </NavLink>

//                             </NavItem>

//                             <NavItem >

//                               <NavLink style={{ border: this.state.cc_level < 3 ? "0px" : this.state.cc_level > 3 ? '1px solid #34c38f' : '1px solid #556ee6' }}

//                                 className={classnames({

//                                   active: this.state.activeCustomTab === 3 && this.state.cc_level > 2,

//                                   activechange: this.state.activeCustomTab === 3 && this.state.cc_level >= 4

//                                 })}

//                                 onClick={() => {

//                                   this.toggleCustomTab(3)

//                                 }}

//                                 disabled={this.state.cc_level > 2 ? false : true}

//                               >

//                                 <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />

//                                 <p className="font-weight-bold mb-4">Confirmation</p>

//                               </NavLink>

//                             </NavItem>



//                           </Nav>

//                         </Col>



//                         <Col lg="10" sm="9">

//                           <AvForm className="form-horizontal" >

//                             <Card>

//                               <CardBody>

//                                 <TabContent activeTab={this.state.activeCustomTab}>



//                                   <TabPane

//                                     tabId={1}>

//                                     <div>

//                                       {

//                                         this.state.auditUserlevelSelected ?
//                                         <>
//                                                         <Row className="mb-2" style={{ paddingTop: 10 }}>

//                                                           <Col sm="3">

//                                                             <div className="search-box ms-2 mb-2 d-inline-block">

//                                                               <div className="position-relative">

//                                                                 {/* <SearchBar {...toolkitprops.searchProps} /> */}

//                                                                 {/* <i className="bx bx-search-alt search-icon" /> */}

//                                                               </div>

//                                                             </div>

//                                                           </Col>

//                                                           <Col sm="9" >

//                                                             <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>

//                                                               <div style={{  display: 'flex', flexDirection: 'row', marginRight: 20, position: 'relative', }} >

//                                                                 <div className="select-container me-2">

//                                                                   <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>

//                                                                     <option value="">Select User Group</option>

//                                                                     <option value="all">All</option>

//                                                                     {

//                                                                       this.state.labelData.map((data, idx) => {

//                                                                         // //("data", data)

//                                                                         return (

//                                                                           <option value={idx} key={"lbl" + idx}>{data.label_name}</option>

//                                                                         )

//                                                                       })

//                                                                     }



//                                                                   </select>

//                                                                 </div>



//                                                                 {/* <Button color={"primary"} outline className="me-2"

//                                                                   onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadgroupusers", editItem: null })}

//                                                                 >

//                                                                  Load Group Users

//                                                                 </Button> */}

//                                                                   <div>

//                                                                     <Button color={"primary"} outline className="btn-md me-2 " style={{padding: '7px'}}

//                                                                       onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadendpoint", editItem: null })}

//                                                                     >

//                                                                       Load locations

//                                                                     </Button>

//                                                                     <Button color={"primary"} outline className="btn-sm me-2" style={{padding: '7px'}}

//                                                                       onClick={() => this.setState({ open: this.state.open ? false : true, component: "endpoint", editItem: null })}

//                                                                     >

//                                                                       Add new location

//                                                                     </Button>

//                                                                     <Button color={"danger"} outline className="btn-sm" style={{padding: '7px'}}

//                                                                       onClick={() => { this.deleteAllRequest() }}

//                                                                     >

//                                                                       Delete all locations

//                                                                     </Button>



//                                                               </div>

//                                                               </div>

//                                                             </div>

//                                                           </Col>

//                                                         </Row>

//                                             <Row>

//                                               <div className="form-check form-switch form-switch-sm mx-4">

//                                                 <input

//                                                   type="checkbox"

//                                                   className="form-check-input"

//                                                   id={"customreview"}

//                                                   onChange={(e) => { this.onchangeCustomEnableReview(e) }}

//                                                   checked={this.state.customEnableReview}

//                                                 />

//                                                 <label className={"form-check-label text-primary"} htmlFor={"customreview"}>

//                                                   Enable Review

//                                                 </label>

//                                               </div>



//                                             </Row>











//                                         <TableContainer
//                                         columns={columns}
//                                         data={this.state.publishTemplate.endpoints}
//                                         isGlobalFilter={true}
//                                         isAddOptions={false}
//                                         isJobListGlobalFilter={false}
//                                         customPageSize={10}
//                                         style={{ width: '100%' }}
//                                         isPagination={true}
//                                         filterable={false}
//                                         btnName ={"Add User"}
//                                         tableClass="align-middle table-nowrap table-check"
//                                         theadClass="table-light"
//                                         pagination="pagination pagination-rounded justify-content-end mb-2"
//                                         // dynamicBtn={true}
//                                         // btnClick={(param1, param2) => this.navigateTo("",1)}
//                                         // // dynamicParams={params1,params2}
//                                         // btnName ={"Add User"}
          
//                                     />
// </>
//                                           // <PaginationProvider

//                                           //   keyField="id"

//                                           //   data={this.state.publishTemplate.endpoints}

//                                           //   columns={columns}

//                                           //   pagination={paginationFactory(options)}

//                                           // >

//                                           //   {

//                                           //     ({

//                                           //       paginationProps,

//                                           //       paginationTableProps

//                                           //     }) => (

//                                           //       <ToolkitProvider

//                                           //         keyField="id"

//                                           //         data={this.state.publishTemplate.endpoints}

//                                           //         columns={columns}

//                                           //         search

//                                           //       >

//                                           //         {

//                                           //           toolkitprops => (

//                                           //             <React.Fragment>

//                                           //               <Row className="mb-2" style={{ paddingTop: 10 }}>

//                                           //                 <Col sm="3">

//                                           //                   <div className="search-box ms-2 mb-2 d-inline-block">

//                                           //                     <div className="position-relative">

//                                           //                       {/* <SearchBar {...toolkitprops.searchProps} /> */}

//                                           //                       <i className="bx bx-search-alt search-icon" />

//                                           //                     </div>

//                                           //                   </div>

//                                           //                 </Col>

//                                           //                 <Col sm="9" >

//                                           //                   <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>

//                                           //                     <div style={{  display: 'flex', flexDirection: 'row', marginRight: 20, position: 'relative', }} >

//                                           //                       <div className="select-container me-2">

//                                           //                         <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>

//                                           //                           <option value="">Select User Group</option>

//                                           //                           <option value="all">All</option>

//                                           //                           {

//                                           //                             this.state.labelData.map((data, idx) => {

//                                           //                               // //("data", data)

//                                           //                               return (

//                                           //                                 <option value={idx} key={"lbl" + idx}>{data.label_name}</option>

//                                           //                               )

//                                           //                             })

//                                           //                           }



//                                           //                         </select>

//                                           //                       </div>



//                                           //                       {/* <Button color={"primary"} outline className="me-2"

//                                           //                         onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadgroupusers", editItem: null })}

//                                           //                       >

//                                           //                        Load Group Users

//                                           //                       </Button> */}

//                                           //                         <div>

//                                           //                           <Button color={"primary"} outline className="btn-md me-2 " style={{padding: '7px'}}

//                                           //                             onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadendpoint", editItem: null })}

//                                           //                           >

//                                           //                             Load locations

//                                           //                           </Button>

//                                           //                           <Button color={"primary"} outline className="btn-sm me-2" style={{padding: '7px'}}

//                                           //                             onClick={() => this.setState({ open: this.state.open ? false : true, component: "endpoint", editItem: null })}

//                                           //                           >

//                                           //                             Add new location

//                                           //                           </Button>

//                                           //                           <Button color={"danger"} outline className="btn-sm" style={{padding: '7px'}}

//                                           //                             onClick={() => { this.deleteAllRequest() }}

//                                           //                           >

//                                           //                             Delete all locations

//                                           //                           </Button>



//                                           //                     </div>

//                                           //                     </div>

//                                           //                   </div>

//                                           //                 </Col>

//                                           //               </Row>

//                                           //               <Row>

//                                           //                 <div className="form-check form-switch form-switch-sm mx-4">

//                                           //                   <input

//                                           //                     type="checkbox"

//                                           //                     className="form-check-input"

//                                           //                     id={"customreview"}

//                                           //                     onChange={(e) => { this.onchangeCustomEnableReview(e) }}

//                                           //                     checked={this.state.customEnableReview}

//                                           //                   />

//                                           //                   <label className={"form-check-label text-primary"} htmlFor={"customreview"}>

//                                           //                     Enable Review

//                                           //                   </label>

//                                           //                 </div>

                                                         

//                                           //               </Row>

//                                           //               <hr/>

//                                           //               <Row>

//                                           //                 <Col xl="12">

//                                           //                   <div className="table-responsive">

//                                           //                     <BootstrapTable

//                                           //                       {...toolkitprops.baseProps}

//                                           //                       {...paginationTableProps}

//                                           //                       defaultSorted={defaultSorted}

//                                           //                       classes={

//                                           //                         "table align-middle table-nowrap table-hover"

//                                           //                       }

//                                           //                       bordered={false}

//                                           //                       striped={false}

//                                           //                       responsive

//                                           //                       onTableChange={this.handleTableChange}

//                                           //                     />

//                                           //                   </div>

//                                           //                 </Col>

//                                           //               </Row>

//                                           //               <Row className="align-items-md-center mt-30">

//                                           //                 <Col className="pagination pagination-rounded justify-content-end mb-2">

//                                           //                   <PaginationListStandalone

//                                           //                     {...paginationProps}

//                                           //                   />

//                                           //                 </Col>

//                                           //               </Row>

//                                           //               <Row>

//                                           //                 {/* <Col md={6} className='text-end'> */}
//                                           //                 {/* <Button  color="primary"><i className="bx bx-chevron-left"></i>Previous</Button> */}
//                                           //                 {/* </Col> */}

//                                           //                 <Col md={12} className="text-end">
//                                           //                 <Button className={this.state.cc_level ===0 ?"btn btn-secondary" : "btn btn-success"} disabled={this.state.cc_level ===0} onClick={()=>this.toggleCustomTab(this.state.activeCustomTab+1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
//                                           //                 </Col>

//                                           //               {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

//                                           //                 <Button style={{marginRight:'10%'}} color="primary"><i className="bx bx-chevron-left"></i>Previous</Button>

//                                           //                 </div>

//                                           //                 <div>

//                                           //                 <Button color="success">Next<i className="bx bx-chevron-right"></i></Button>

//                                           //                 </div> */}

//                                           //               </Row>

//                                           //               {/* <Row>

//                                           //                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

//                                           //                   <Button color={"danger"}

//                                           //                     onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method

//                                           //                   </Button>

//                                           //                 </div>

//                                           //               </Row> */}

//                                           //             </React.Fragment>

//                                           //           )}

//                                           //       </ToolkitProvider>

//                                           //     )}

//                                           // </PaginationProvider> 
//                                           : null

//                                       }



//                                     </div>

//                                   </TabPane>



//                                   <TabPane

//                                     tabId={2}

//                                     id="v-pills-confir"

//                                     role="tabpanel">

//                                     <div>

//                                       <div className='mb-3'>

//                                         <span className='font-size-17'>Configuration</span>

//                                       </div>



//                                       <hr className="my-4" />



//                                       <div>

//                                         <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>

//                                         {/* <p className="card-title-desc">
//                                           Use the <code>.badge-pill</code> modifier className to
//                                           make badges more rounded (with a larger{" "}
//                                           <code>border-radius</code>
//                                           and additional horizontal <code>padding</code>).
//                                           Useful if you miss the badges from v3.
//                                         </p> */}

//                                         <div className="mb-3 col-6">

//                                           <select

//                                             type="select"

//                                             name="repeat_mode"

//                                             label="Name"

//                                             value={this.state.selectedRepeatMode}

//                                             className="form-select"

//                                             id="review_mode_level"

//                                             required

//                                             onChange={(e) => this.selectRepeatMode(e)}

//                                           >

//                                             <option value="choose" disabled>Choose...</option>

//                                             {

//                                               this.state.repeatModeData.map((data, idx) => {

//                                                 return (

//                                                   <option value={String(idx)} selected key={idx}>{data.mode}</option>

//                                                 )

//                                               })

//                                             }

//                                           </select>

//                                         </div>

//                                       </div>



//                                       <div>

//                                         {this.repeatModeComponent(this.state.selectedRepeatMode)}

//                                       </div>

//                                       <Row>

//                                         <Col md={12} className='text-end'>
//                                          <Button  color="primary" className="me-2" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button> 
//                                          <Button color="success" disabled={this.state.cc_level == 2} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
//                                         </Col>
//                                         {/* <Col md={1}>
//                                           <Button color="success" disabled={this.state.cc_level == 2} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
//                                         </Col> */}

//                                       </Row>

//                                     </div>

//                                   </TabPane>



//                                   <TabPane

//                                     tabId={3}

//                                     id="v-pills-confir"

//                                     role="tabpanel">

//                                     <div>

//                                       <div className='mb-2'>

//                                         <span className='font-size-17'>Confirmation</span> <br />

//                                         <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>

//                                       </div>



//                                       <Row className="justify-content-center">

//                                         <Col xl={10}>



//                                           <div className="mt-2">

//                                             <div className="d-flex flex-wrap">

//                                               <div className="me-2">

//                                                 <span className="me-2 font-size-18">Locations</span> <span

//                                                   onClick={() => {

//                                                     this.toggleCustomTab(1)

//                                                   }}

//                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">

//                                                   edit

//                                                 </span>



//                                               </div>

//                                               <div className="ms-auto">

//                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>

//                                               </div>

//                                             </div>

//                                             <hr className="mt-2" />



//                                             <div className="list-group list-group-flush">

//                                               <div

//                                                 to="#"

//                                                 className="list-group-item text-muted"

//                                               >

//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                 Total Locations - <span className="text-success">{this.state.publishTemplate.endpoints.length}</span>

//                                               </div>

//                                             </div>

//                                           </div>





//                                           <div className="mt-5">

//                                             <div className="d-flex flex-wrap">

//                                               <div className="me-2">

//                                                 <span className="me-2 font-size-18">Configuration</span> 

//                                                 <span

//                                                   onClick={() => {

//                                                     this.toggleCustomTab(2)

//                                                   }}

//                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">

//                                                   edit

//                                                 </span>

//                                               </div>

//                                               <div className="ms-auto">

//                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>

//                                               </div>

//                                             </div>

//                                             <hr className="mt-2" />



//                                             <div className="list-group list-group-flush">



//                                               <div

//                                                 to="#"

//                                                 className="list-group-item text-muted"

//                                               >

//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                 Repeat Mode - <span className="text-success">{this.state.publishTemplate.repeat_mode}</span>

//                                               </div>

//                                               <div  to="#"

//                                                 className="list-group-item text-muted d-flex">

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   Display &nbsp;&nbsp;

//                                                   <div style={{display: 'flex', alignItems: 'center'}}>

//                                                   <div>Audit Score:</div>&nbsp;&nbsp;

//                                                   <input type={"checkbox"} name='audit_score_preview' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;

//                                                   </div>

//                                                 {

//                                                   this.state.customEnableReview === true &&

//                                                   <div style={{ display: 'flex', alignItems: 'center' }}>

//                                                     <div>Review Score:</div>&nbsp;&nbsp;

//                                                     <input type={"checkbox"} name="review_score_preview" onChange={(e) => this.handle_click(e)} />

//                                                   </div>

//                                                 }





//                                               </div>



//                                               <div  to="#"

//                                                 className="list-group-item text-muted d-flex">

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                  Track Location &nbsp;&nbsp;

//                                                   <div style={{display: 'flex', alignItems: 'center'}}>

//                                                   <div>Auditor :</div>&nbsp;&nbsp;

//                                                   <input type={"checkbox"} name='audit_coords_enable' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;

//                                                   </div>

//                                                   {

//                                                     this.state.customEnableReview=== true &&

//                                                   <div style={{display: 'flex', alignItems: 'center'}}>

//                                                   <div>Reviewer :</div>&nbsp;&nbsp;

//                                                   <input type={"checkbox"} name="review_coords_enable" onChange={(e)=>this.handle_click(e)}/>

//                                                   </div>

//                                                   }



//                                               </div>



//                                               <div

//                                                 to="#"

//                                                 className="list-group-item text-muted"

//                                               >

//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                 Publish on <span className="text-danger">*</span>

//                                                 <input

//                                                   className="form-control"

//                                                   type="date"

//                                                   min={this.state.fromate_date}

//                                                   selected={this.state.ptl_startdate}

//                                                   defaultValue={this.state.ptl_startdate}

//                                                   id="start_date"

//                                                   onChange={event => this.onChangeStartDate(event)}
//                                                   onKeyDown={(e)=>{e.preventDefault()}}

//                                                 />

//                                               </div>



//                                               <div

//                                                 to="#"

//                                                 className="list-group-item text-muted"

//                                               >

//                                                 {/* {

//                                                   //(this.state.ptl_startdate,'this.state.ptl_startdate')

//                                                 } */}

//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                 Audit End on <span className="text-danger">*</span>

//                                                 <input

//                                                   className="form-control"

//                                                   type="date"

//                                                   min={this.state.ptl_startdate}

//                                                   value={this.state.ptl_startdate > this.state.ptl_enddate ? "" : this.state.ptl_enddate}

//                                                   // min={this.state.fromate_date}

//                                                   selected={this.state.ptl_enddate}

//                                                   defaultValue={this.state.ptl_enddate}

//                                                   max={this.calculateMaxDate()}

//                                                   disabled={this.state.ptl_startdate =='' || this.state.ptl_startdate ==undefined}
//                                                   onKeyDown={(e)=>{e.preventDefault()}}

//                                                   id="end_date"

//                                                   onChange={event => this.onChangeEndDate(event)}

//                                                 />

//                                               </div>







//                                             </div>

//                                           </div>



//                                           <div className="mt-5">

//                                             <div className="d-flex flex-wrap">

//                                               <div className="me-2">

//                                                 <span className="me-2 font-size-18">Check points</span> <span

//                                                   onClick={() => this.navigateTo(this.state.publishTemplate)}

//                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">

//                                                   edit

//                                                 </span>

//                                               </div>

//                                               <div className="ms-auto">

//                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>

//                                               </div>

//                                             </div>

//                                             <hr className="mt-2" />



//                                             <div className="list-group list-group-flush">

//                                               <div



//                                                 className="list-group-item text-muted"

//                                               >

//                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                 No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>

//                                               </div>



//                                             </div>

//                                           </div>



//                                         </Col>

//                                       </Row>

//                                       <Row>

//                                         <div className="wizard clearfix">

//                                           <div className="actions clearfix">

//                                           {/* <Col md={11} className='text-end'> */}

//                                          <Button color="primary" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button> 

//                                         {/* </Col> */}

//                                            <button className='btn btn-primary mx-2' onClick={()=>{window.location.href = "/mngpblhtempt"}}> Save </button>

//                                             <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>

//                                           </div>

//                                           <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>

//                                         </div>

//                                       </Row>

                                  

//                                     </div>

                                 

//                                   </TabPane>



//                                 </TabContent>

//                               </CardBody>

//                             </Card>

//                           </AvForm>

//                         </Col>



//                       </Row>

//                     </div>







//                   </div> : this.state.methodSelected === "2" ?

//                     <div>

//                       <div className="checkout-tabs">

//                         <Row>



//                           <Col lg="2" sm="3">

//                             <Nav className="flex-column" pills>

//                               <NavItem>

//                                 <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f' }}

//                                   className={classnames({

//                                     active: this.state.activeTab === 1 && this.state.cc_level == 0,

//                                     activechange: this.state.activeTab === 1 && this.state.cc_level > 0

//                                   })



//                                   }

//                                   onClick={() => {

//                                     this.toggleTab(1)

//                                   }}

//                                 >

//                                   <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />

//                                   <p className="font-weight-bold mb-4">Hirerachy Level</p>

//                                 </NavLink>

//                               </NavItem>

//                               <NavItem>

//                                 <NavLink style={{ border: this.state.cc_level == 0 ? "0px" : this.state.cc_level > 1 ? '1px solid #34c38f' : '1px solid #556ee6' }}

//                                   className={classnames({

//                                     active: this.state.activeTab === 2 && this.state.cc_level > 0,

//                                     activechange: this.state.activeTab === 2 && this.state.cc_level > 1

//                                   })}

//                                   onClick={() => {

//                                     this.toggleTab(2);

//                                   }}

//                                   disabled={this.state.cc_level > 0 ? false : true}

//                                 >

//                                   <i className="bx bxs-user d-block check-nav-icon mt-4 mb-2" />

//                                   <p className="font-weight-bold mb-4">Audit / Review</p>

//                                 </NavLink>

//                               </NavItem>

//                               <NavItem>

//                                 <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6' }}

//                                   className={classnames({

//                                     active: this.state.activeTab === 3 && this.state.cc_level > 1,

//                                     activechange: this.state.activeTab === 3 && this.state.cc_level > 2

//                                   })}

//                                   onClick={() => {

//                                     this.toggleTab(3)

//                                   }}

//                                   disabled={this.state.cc_level > 1 ? false : true}

//                                 >

//                                   <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />

//                                   <p className="font-weight-bold mb-4">Configuration</p>

//                                 </NavLink>

//                               </NavItem>

//                               <NavItem >

//                                 <NavLink style={{ border: this.state.cc_level < 3 ?"0px": this.state.cc_level === 3 ? '1px solid #556ee6': '1px solid #34c38f' }}

//                                   className={classnames({

//                                     active: this.state.activeTab === 4 && this.state.cc_level > 2,

//                                     activechange: this.state.activeTab === 4 && this.state.cc_level > 3

//                                   })}

//                                   onClick={() => {

//                                     this.toggleTab(4)

//                                   }}

//                                   disabled={this.state.cc_level > 2 ? false : true}

//                                 >

//                                   <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />

//                                   <p className="font-weight-bold mb-4">Confirmation</p>

//                                 </NavLink>

//                               </NavItem>

//                             </Nav>

//                           </Col>

//                           <Col lg="10" sm="9">

//                             <AvForm className="form-horizontal" onValidSubmit={this.crudNode} onInvalidSubmit={this.handleInvalidSubmit}>

//                               <Card>

//                                 <CardBody>

//                                   <TabContent activeTab={this.state.activeTab}>



//                                     <TabPane

//                                       tabId={1}>

//                                       <div>



//                                         <Row className='mb-3'>

//                                           <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>

//                                             <div className='col-8'>

//                                               <span className='font-size-17'>Hirerachy Level</span>

//                                             </div>

//                                             <div className='col-4'>

//                                               <button className="btn btn-danger" onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method</button>

//                                             </div>

//                                           </div>

//                                         </Row>



//                                         <div>

//                                           <div>

//                                             <Label className="text-primary" htmlFor="autoSizingSelect">Select Level</Label>

//                                             <p className="card-title-desc">

//                                               Select appropriate hirerachy level from dropdown or {" "}

//                                               <badge className='btn badge badge-soft-primary rounded-pill font-size-12 me-1'>Create New Hirerachy level </badge>

//                                             </p>

//                                             <div className="mb-3 col-6">



//                                               <AvInput

//                                                 type="select"

//                                                 name="hlevel"

//                                                 label="HirerachLevel"

//                                                 value={this.state.selectedHLevelValue}

//                                                 className="form-select"

//                                                 id="hlevel1"

//                                                 required

//                                                 onChange={(e) => this.selectLevel(e)}>

//                                                 <option value="0" defaultValue disabled >Choose...</option>

//                                                 {

//                                                   this.state.dataSource.map((data, idx) => {

//                                                     return (

//                                                       <option value={String(idx + 1)} selected key={idx}>{data.hname}</option>

//                                                     )

//                                                   })

//                                                 }

//                                               </AvInput>

//                                             </div>

//                                           </div>



//                                           <hr className="my-4" />

//                                           {

//                                             this.state.hlevelIsSelected ?

//                                               <div>

//                                                 <Label className="text-primary" htmlFor="autoSizingSelect">Select Endpoint</Label>

//                                                 <p className="card-title-desc">

//                                                   Use the <code>.badge-pill</code> modifier className to

//                                                   make badges more rounded (with a larger{" "}

//                                                   <code>border-radius</code>

//                                                   and additional horizontal <code>padding</code>).

//                                                   Useful if you miss the badges from v3.

//                                                 </p>

//                                                 <div className="mb-3 col-6">

//                                                   <select

//                                                     type="select"

//                                                     name="location"

//                                                     label="Name"

//                                                     value={this.state.selectedEndpoint}

//                                                     className="form-select"

//                                                     id="location"

//                                                     required

//                                                     onChange={(e) => this.selectLocation(e)}>

//                                                     <option value="choose" disabled >Choose...</option>

//                                                     {

//                                                       this.state.hlevelData.map((data, idx) => {

//                                                         return (

//                                                           <option value={String(idx + 1)} selected key={idx}>{data.hlevel}</option>

//                                                         )

//                                                       })

//                                                     }

//                                                   </select>



//                                                 </div></div> : null

//                                           }



//                                           {this.state.hlevelData.length > 0 ? <hr className="my-4" /> : null}



//                                           {

//                                             this.state.endpointSelected ?

//                                               <div>

//                                                 <Label className="text-primary" htmlFor="autoSizingSelect">Select {this.state.hlevelSelected}</Label>

//                                                 <p className="card-title-desc">

//                                                   Use the <code>.badge-pill</code> modifier className to

//                                                   make badges more rounded (with a larger{" "}

//                                                   <code>border-radius</code>

//                                                   and additional horizontal <code>padding</code>).

//                                                   Useful if you miss the badges from v3.

//                                                 </p>

//                                                 <CheckboxTree

//                                                   nodes={locationData}

//                                                   checked={this.state.checked}

//                                                   expanded={(this.state.expanded)}

//                                                   onCheck={(checked, event) => { this.handleEndpointSelection(checked, event) }}

//                                                   // onClick = {(nodes) => {//("node", nodes)}}

//                                                   onExpand={expanded => this.handleEndpointExpanded(expanded)}

//                                                   icons={icons}

//                                                 // showExpandAll={true}

//                                                 />



//                                               </div>

//                                               : null

//                                           }





//                                         </div>



//                                       </div>

//                                     </TabPane>



//                                     <TabPane

//                                       tabId={2}

//                                       id="v-pills-confir"

//                                       role="tabpanel">

//                                       <div>

//                                         <div className='mb-3'>

//                                           <span className='font-size-17'>Audit & Review</span>

//                                         </div>





//                                         <div>

//                                           <Label className="text-primary" htmlFor="autoSizingSelect">Select audit user level</Label>

//                                           <p className="card-title-desc">

//                                             Use the <code>.badge-pill</code> modifier className to

//                                             make badges more rounded (with a larger{" "}

//                                             <code>border-radius</code>

//                                             and additional horizontal <code>padding</code>).

//                                             Useful if you miss the badges from v3.

//                                           </p>

//                                           <div className="mb-3 col-6">

//                                             <select

//                                               type="select"

//                                               name="audit_userlevel"

//                                               label="Name"

//                                               value={this.state.selectedAuditUserlevel}

//                                               className="form-select"

//                                               id="audit_user_level"

//                                               required

//                                               onChange={(e) => this.selectAuditUserlevel(e)}

//                                             >

//                                               <option value="choose" disabled >Choose...</option>

//                                               {

//                                                 this.state.hlevelData.map((data, idx) => {

//                                                   if ((idx + 1) <= parseInt(this.state.selectedEndpoint)) {

//                                                     return (

//                                                       <option value={String(idx + 1)} selected key={idx}>{data.hlevel}</option>

//                                                     )

//                                                   }

//                                                 })

//                                               }

//                                             </select>

//                                           </div>

//                                         </div>

//                                         <hr className="my-4" />



//                                         <div className="form-check form-switch form-switch-sm my-3">

//                                           <input

//                                             type="checkbox"

//                                             className="form-check-input"

//                                             id={"review"}

//                                             onChange={(e) => { this.onchangeEnableReview(e) }}

//                                             checked={this.state.enableReview}

//                                           />

//                                           <label className={"form-check-label text-primary"} htmlFor={"review"}>

//                                             Enable Review

//                                           </label>

//                                         </div>

                                      



//                                         {this.state.enableReview ?

//                                           <div>

//                                             <Label className="text-primary" htmlFor="autoSizingSelect">Select review user level</Label>

//                                             <p className="card-title-desc">

//                                               Use the <code>.badge-pill</code> modifier className to

//                                               make badges more rounded (with a larger{" "}

//                                               <code>border-radius</code>

//                                               and additional horizontal <code>padding</code>).

//                                               Useful if you miss the badges from v3.

//                                             </p>

//                                             <div className="mb-3 col-6">

//                                               <select

//                                                 type="select"

//                                                 name="review_userlevel"

//                                                 label="Name"

//                                                 value={this.state.selectedReviewUserlevel}

//                                                 className="form-select"

//                                                 id="review_user_level"

//                                                 required

//                                                 onChange={(e) => this.selectReviewUserlevel(e)}

//                                               >

//                                                 <option value="choose" disabled>Choose...</option>

//                                                 {

//                                                   this.state.hlevelData.map((data, idx) => {

//                                                     if ((idx + 1) < parseInt(this.state.selectedAuditUserlevel)) {

//                                                       return (

//                                                         <option value={String(idx + 1)} selected key={idx}>{data.hlevel}</option>

//                                                       )

//                                                     }

//                                                   })

//                                                 }

//                                               </select>

//                                             </div>

//                                             <hr className="my-4" />

//                                           </div>

//                                           : null}



//                                         {

//                                           this.state.auditUserlevelSelected ?

//                                             <div>
                                                
//                               <TableContainer
//                               columns={columns}
//                               data={this.state.publishTemplate.endpoints}
//                               isGlobalFilter={true}
//                               isAddOptions={false}
//                               isJobListGlobalFilter={false}
//                               customPageSize={10}
//                               style={{ width: '100%' }}
//                               // dynamicBtn={true}
//                               // btnClick={(param1, param2) => this.navigateTo("",1)}
//                               // // dynamicParams={params1,params2}
//                               // btnName ={"Add User"}

//                           />

//                                               {/* <PaginationProvider

//                                                 keyField="id"

//                                                 data={this.state.publishTemplate.endpoints}

//                                                 columns={columns}

//                                                 pagination={paginationFactory(options)}

//                                               >

//                                                 {

//                                                   ({

//                                                     paginationProps,

//                                                     paginationTableProps

//                                                   }) => (

//                                                     <ToolkitProvider

//                                                       keyField="id"

//                                                       data={this.state.publishTemplate.endpoints}

//                                                       columns={columns}

//                                                       search

//                                                     >

//                                                       {

//                                                         toolkitprops => (

//                                                           <React.Fragment>

//                                                             <Row className="mb-2" style={{ paddingTop: 10 }}>

//                                                               <Col sm="4">

//                                                                 <div className="search-box ms-2 mb-2 d-inline-block">

//                                                                   <div className="position-relative">


//                                                                     <i className="bx bx-search-alt search-icon" />

//                                                                   </div>

//                                                                 </div>

//                                                               </Col>

                                                           
//                                                             </Row>

//                                                             <Row>

//                                                               <Col xl="12">

//                                                                 <div className="table-responsive">

//                                                                   <BootstrapTable

//                                                                     {...toolkitprops.baseProps}

//                                                                     {...paginationTableProps}

//                                                                     defaultSorted={defaultSorted}

//                                                                     classes={

//                                                                       "table align-middle table-nowrap table-hover"

//                                                                     }

//                                                                     bordered={false}

//                                                                     striped={false}

//                                                                     responsive

//                                                                     onTableChange={this.handleTableChange}

//                                                                   />

//                                                                 </div>

//                                                               </Col>

//                                                             </Row>

//                                                             <Row className="align-items-md-center mt-30">

//                                                               <Col className="pagination pagination-rounded justify-content-end mb-2">

//                                                                 <PaginationListStandalone

//                                                                   {...paginationProps}

//                                                                 />

//                                                               </Col>

//                                                             </Row>

//                                                           </React.Fragment>

//                                                         )}

//                                                     </ToolkitProvider>

//                                                   )}

//                                               </PaginationProvider> */}

//                                             </div> : null

//                                         }



//                                       </div>

//                                     </TabPane>



//                                     <TabPane

//                                       tabId={3}

//                                       id="v-pills-config"

//                                       role="tabpanel"

//                                       aria-labelledby="v-pills-config-tab"

//                                     >

//                                       <div>

//                                         <div className='mb-3'>

//                                           <span className='font-size-17'>Configuration</span>

//                                         </div>



//                                         <hr className="my-4" />



//                                         <div>

//                                           <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>

//                                           <p className="card-title-desc">

//                                             Use the <code>.badge-pill</code> modifier className to

//                                             make badges more rounded (with a larger{" "}

//                                             <code>border-radius</code>

//                                             and additional horizontal <code>padding</code>).

//                                             Useful if you miss the badges from v3.

//                                           </p>

//                                           <div className="mb-3 col-6">

//                                             <select

//                                               type="select"

//                                               name="repeat_mode"

//                                               label="Name"

//                                               value={this.state.selectedRepeatMode}

//                                               className="form-select"

//                                               id="review_mode_level"

//                                               required

//                                               onChange={(e) => this.selectRepeatMode(e)}

//                                             >

//                                               <option value="choose" disabled>Choose...</option>

//                                               {

//                                                 this.state.repeatModeData.map((data, idx) => {

//                                                   return (

//                                                     <option value={String(idx)} selected key={idx}>{data.mode}</option>

//                                                   )

//                                                 })

//                                               }

//                                             </select>

//                                           </div>

//                                         </div>



//                                         <div>

//                                           {this.repeatModeComponent(this.state.selectedRepeatMode)}

//                                         </div>



//                                       </div>

//                                     </TabPane>



//                                     <TabPane

//                                       tabId={4}

//                                       id="v-pills-confir"

//                                       role="tabpanel">

//                                       <div>

//                                         <div className='mb-2'>

//                                           <span className='font-size-17'>Confirmation</span> <br />

//                                           <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>

//                                         </div>



//                                         <Row className="justify-content-center">

//                                           <Col xl={10}>



//                                             <div className="mt-2">

//                                               <div className="d-flex flex-wrap">

//                                                 <div className="me-2">

//                                                   <span className="me-2 font-size-18">Hirerach Level</span> <span

//                                                     onClick={() => {

//                                                       this.toggleTab(1)

//                                                     }}

//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">

//                                                     edit

//                                                   </span>



//                                                 </div>

//                                                 <div className="ms-auto">

//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>

//                                                 </div>

//                                               </div>

//                                               <hr className="mt-2" />



//                                               <div className="list-group list-group-flush">

//                                                 <div

//                                                   to="#"

//                                                   className="list-group-item text-muted"

//                                                 >

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   Hirerachy level - <span className="text-success">{this.state.publishTemplate.hlevel}</span>

//                                                 </div>



//                                                 <div

//                                                   to="#"

//                                                   className="list-group-item text-muted"

//                                                 >

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   Endpoint - <span className="text-success">{this.state.publishTemplate.ep_level} </span>

//                                                 </div>



//                                                 <div

//                                                   to="#"

//                                                   className="list-group-item text-muted"

//                                                 >

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   No. of Endpoints selected - <span className="text-success">{this.state.publishTemplate.ep_selected.length}</span>

//                                                 </div>

//                                               </div>

//                                             </div>



//                                             <div className="mt-5">

//                                               <div className="d-flex flex-wrap">

//                                                 <div className="me-2">

//                                                   <span className="me-2 font-size-18">Audit / Review</span> <span

//                                                     onClick={() => {

//                                                       this.toggleTab(2)

//                                                     }}

//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">

//                                                     edit

//                                                   </span>

//                                                 </div>

//                                                 <div className="ms-auto">

//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>

//                                                 </div>

//                                               </div>

//                                               <hr className="mt-2" />



//                                               <div className="list-group list-group-flush">

//                                                 <div

//                                                   to="#"

//                                                   className="list-group-item text-muted"

//                                                 >

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   Audit user level - <span className="text-success">{this.state.publishTemplate.audit_userlevel}</span>

//                                                 </div>



//                                                 <div

//                                                   to="#"

//                                                   className="list-group-item text-muted"

//                                                 >

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   Review user level - {this.state.publishTemplate.review_userlevel == null ? <span className="text-danger"> No Review </span> : <span className="text-success">{this.state.publishTemplate.review_userlevel}</span>}

//                                                 </div>



//                                               </div>

//                                             </div>



//                                             <div className="mt-5">

//                                               <div className="d-flex flex-wrap">

//                                                 <div className="me-2">

//                                                   <span className="me-2 font-size-18">Configuration</span> <span

//                                                     onClick={() => {

//                                                       this.toggleTab(3)

//                                                     }}

//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">

//                                                     edit

//                                                   </span>

//                                                 </div>

//                                                 <div className="ms-auto">

//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>

//                                                 </div>

//                                               </div>

//                                               <hr className="mt-2" />



//                                               <div className="list-group list-group-flush">

//                                                 <div

//                                                   to="#"

//                                                   className="list-group-item text-muted"

//                                                 >

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   Publish on <span className="text-danger">*</span>

//                                                   <input

//                                                     className="form-control"

//                                                     type="date"

//                                                     min={this.state.fromate_date}

//                                                     selected={this.state.ptl_startdate}

//                                                     defaultValue={this.state.ptl_startdate}

//                                                     id="start_date"

//                                                     onChange={event => this.onChangeStartDate(event)}

//                                                   />

//                                                 </div>



//                                                 <div

//                                                   to="#"

//                                                   className="list-group-item text-muted"

//                                                 >

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   Audit End on <span className="text-danger">*</span>

//                                                   <input

//                                                     className="form-control"

//                                                     type="date"

//                                                     min={this.state.fromate_date}

//                                                     selected={this.state.ptl_enddate}

//                                                     defaultValue={this.state.ptl_enddate}

//                                                     id="end_date"

//                                                     onChange={event => this.onChangeEndDate(event)}

//                                                   />

//                                                 </div>



//                                                 <div

//                                                   to="#"

//                                                   className="list-group-item text-muted"

//                                                 >

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   Frequency - <span className="text-success">{this.state.publishTemplate.frequency}</span>

//                                                 </div>



//                                               </div>

//                                             </div>



//                                             <div className="mt-5">

//                                               <div className="d-flex flex-wrap">

//                                                 <div className="me-2">

//                                                   <span className="me-2 font-size-18">Check points</span> <span

//                                                     onClick={() => this.navigateTo(this.state.publishTemplate)}

//                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">

//                                                     edit

//                                                   </span>

//                                                 </div>

//                                                 <div className="ms-auto">

//                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>

//                                                 </div>

//                                               </div>

//                                               <hr className="mt-2" />



//                                               <div className="list-group list-group-flush">

//                                                 <div



//                                                   className="list-group-item text-muted"

//                                                 >

//                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}

//                                                   No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>

//                                                 </div>



//                                               </div>

//                                             </div>



//                                           </Col>

//                                         </Row>

//                                         <Row>

//                                           <div className="wizard clearfix">

//                                             <div className="actions clearfix">

//                                               <button className='btn btn-primary mx-2'> Save </button>

//                                               <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>

//                                             </div>

//                                             <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>

//                                           </div>

//                                         </Row>

//                                       </div>

//                                     </TabPane>



//                                   </TabContent>

//                                 </CardBody>

//                               </Card>



//                               {/* <Row>

//                         <div className="wizard clearfix">

//                           <div className="actions clearfix">

//                             <ul>

//                               <li

//                                 className={

//                                   this.state.activeTab === 1 

//                                     ? "previous disabled"

//                                     : "previous"

//                                 }

//                               >

//                                 <Link

//                                   to="#"

//                                   onClick={() => {

//                                     this.toggleTab(this.state.activeTab - 1)

//                                   }}

//                                 >

//                                   Previous

//                                 </Link>

//                               </li>

//                               <li

//                                 className={

//                                   this.state.activeTab === 4 

//                                     ? "next disabled"

//                                     : "next"

//                                 }

//                               >

//                                 <Link

//                                   to="#"

//                                   onClick={() => {

//                                     this.toggleTab(this.state.activeTab + 1)

//                                   }}

//                                 >

//                                   Next

//                                 </Link>

//                               </li>

//                             </ul>

//                           </div>

//                         </div>

//                       </Row> */}

//                             </AvForm>

//                           </Col>

//                         </Row>

//                       </div>

//                     </div>

//                     : null

//               }



//             </Container>

//           </div>

//           <ReactDrawer

//             open={this.state.open}

//             className="drawer-open"

//             position={this.state.position}

//             onClose={this.onDrawerClose}

//             style={{ maxHeight: 1000 }}

//             noOverlay={false}

//           >



//             <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>

//               {

//                 this.state.component === "user" ?

//                 <React.Fragment>

//                 <Container fluid>

//                     <Row >

//                         <Col >

//                             <Card className="overflow-hidden">

//                                 {

//                                     !this.state.formDisabled ?

//                                         <CardBody className="pt-0">

//                                             <div className="p-5">

//                                                 <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>





//                                                     <Row className="my-4">

//                                                     <div className="d-sm-flex align-items-center justify-content-between">

//                                                         <div className="text-danger font-size-18">User Information</div>



//                                                         <button className="btn btn-outline-dark " onClick={() => this.onDrawerClose()}> Close </button>

//                                                     </div>

//                                                         <hr className="my-4" />

//                                                     </Row>



//                                                     <Row>

//                                                         <Col className="col-6">

//                                                         <div className="mb-3" >

//                                                                 <label>Full Name :<span className="text-danger"> *</span></label>

//                                                                 <AvField

//                                                                     name="firstname"

//                                                                     // label="First Name"

//                                                                     className="form-control"

//                                                                     placeholder="Enter Full Name"

//                                                                     type="text"

//                                                                     required

//                                                                 />

//                                                             </div>

//                                                         </Col>

//                                                         <Col className="col-6">

//                                                         <div className="mb-3" >

//                                                                 <label>Email Id :<span className="text-danger"> *</span></label>

//                                                                 <AvField

//                                                                     name="email_id"

//                                                                     onChange={(e) => { this.emai_addrs(e) }}

//                                                                     onBlur={(e) => { this.validate_email(e, 1) }}

//                                                                     // label="User Email"

//                                                                     className="form-control"

//                                                                     placeholder="Enter User Email"

//                                                                     type="email"

//                                                                     required

//                                                                 />

//                                                                  {

//                                                                             this.state.errorMessage &&

//                                                                             <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>

//                                                                         }

//                                                                 {

//                                                                     this.state.invalid_user &&

//                                                                     <div className='text-danger' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>

//                                                                 }

//                                                             </div>

//                                                         </Col>

//                                                         {/* <Col className="col-6">

//                                                         <div className="mb-3" >

//                                                                 <label>Last Name :<span className="text-danger"> *</span></label>

//                                                                 <AvField

//                                                                     name="lastname"

//                                                                     // label="Last Name"

//                                                                     className="form-control"

//                                                                     placeholder="Enter Last Name"

//                                                                     type="text"

//                                                                 />

//                                                             </div>

//                                                         </Col> */}

//                                                     </Row>

//                                                     {/* <Row>

//                                                         <Col className="col-12">

//                                                         <div className="mb-3" >

//                                                         <AvField

//                                                                     name="addrss_1"

//                                                                     label="Address"

//                                                                     className="form-control"

//                                                                     placeholder="Enter Address"

//                                                                     type="text"

//                                                                 />

                                                            

//                                                             </div>

                                                            

//                                                         </Col>

//                                                     </Row> */}

//                                                 {/* <Row>

//                                                     <Col className="col-4">

//                                                         <div className="mb-3" >

//                                                             <AvField

//                                                                 name="city"

//                                                                 label="City"

//                                                                 className="form-control"

//                                                                 placeholder="Enter City"

//                                                                 type="text"

//                                                             />



//                                                         </div>



//                                                     </Col>

//                                                     <Col className="col-4">

//                                                         <div className="mb-3" >

//                                                             <AvField

//                                                                 name="state"

//                                                                 label="State"

//                                                                 className="form-control"

//                                                                 placeholder="Enter State"

//                                                                 type="text"

//                                                             />



//                                                         </div>



//                                                     </Col>

//                                                     <Col className="col-4">

//                                                         <div className="mb-3" >

//                                                             <AvField

//                                                                 name="country"

//                                                                 label="Country"

//                                                                 className="form-control"

//                                                                 placeholder="Enter Country"

//                                                                 type="text"

//                                                             />



//                                                         </div>



//                                                     </Col>

//                                                 </Row> */}



                                                    



//                                                     <Row>

//                                                         {/* <Col className="col-6">

//                                                         <div className="mb-3" >

//                                                                 <label>Email Id :<span className="text-danger"> *</span></label>

//                                                                 <AvField

//                                                                     name="email_id"

//                                                                     onChange={(e) => { this.emai_addrs(e) }}

//                                                                     onBlur={(e) => { this.validate_email(e, 1) }}

//                                                                     // label="User Email"

//                                                                     className="form-control"

//                                                                     placeholder="Enter User Email"

//                                                                     type="email"

//                                                                     required

//                                                                 />

//                                                                 {

//                                                                     this.state.invalid_user &&

//                                                                     <div className='text-danger' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>

//                                                                 }

//                                                             </div>

//                                                         </Col> */}

//                                                         <Col className="col-6">

//                                                         <div className="mb-3">

//                                                             <label>Phone Number:<span className="text-danger"> *</span></label>

//                                                             <div className="input-group">

//                                                                 <div className="input-group-prepend">

//                                                                     <select name="countrycode" onChange={(e) => { this.select_country(e) }} className="form-select" required>

//                                                                         <option value="">Select</option>

//                                                                         {

//                                                                             this.state.client_info.countries.map((c, idx) => (

//                                                                                 <option key={idx} value={c.code}>

//                                                                                     {c.code}

//                                                                                 </option>

//                                                                             ))

//                                                                         }

//                                                                     </select>

//                                                                 </div>

//                                                                 <div className="flex-grow-1">

//                                                                     <AvField

//                                                                         name="phone_number"

//                                                                         // label="Phone Number"

//                                                                         className="form-control"

//                                                                         placeholder="Enter Phone number"

//                                                                         type="number"

//                                                                         onBlur={(e) => { this.validate_email(e, 2) }}

//                                                                         validate={{

//                                                                             required: { value: true },

//                                                                             minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },

//                                                                             maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },

//                                                                         }}

//                                                                     />

//                                                                     {

//                                                                         this.state.invalid_phone_num &&

//                                                                         <div className='text-danger' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>

//                                                                     }

//                                                                 </div>

//                                                             </div>

//                                                         </div>

//                                                         {

//                                                             this.state.country_code_err &&

//                                                             <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>





//                                                         }









//                                                             {/* <div className="mb-3" >

//                                                                 <AvField

//                                                                     name="phone_number"

//                                                                     label="Phone Number"

//                                                                     className="form-control"

//                                                                     placeholder="Enter Phone number"

//                                                                     type="number"

//                                                                     validate={{

//                                                                         required: { value: true },

//                                                                         minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },

//                                                                         maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },

//                                                                     }}

//                                                                 />

//                                                             </div> */}

//                                                         </Col>

//                                                     </Row>



//                                                     {/* <Row>

//                                                         <Col className="col-6">

//                                                             <div className="mb-3">

//                                                                 <AvField

//                                                                     name="designation"

//                                                                     label="Designation"

//                                                                     type="text"

//                                                                     // required

//                                                                     placeholder="Enter Designation"

//                                                                 />

//                                                             </div>

//                                                         </Col>

//                                                         <Col className="col-6">

//                                                             <div className="mb-3">

//                                                                 <AvField

//                                                                     name="usercode"

//                                                                     label="User Code"

//                                                                     type="text"

//                                                                     // required

//                                                                     placeholder="Enter User Code"

//                                                                     onChange={(e)=>{this.userCode(e)}}

//                                                                     onBlur={(e) => this.validate_email(e, 3)}

//                                                                 />

//                                                                   {

//                                                                     this.state.invalid_usercode &&

//                                                                     <div className="text-danger" style={{ fontSize: 'smaller' }}>User Code already exist for another user</div>

//                                                                 }

//                                                             </div>

//                                                         </Col>

//                                                     </Row> */}



//                                                     <Row className="my-4">

//                                                         <div className="text-danger font-size-18">Login Information</div>

//                                                         <hr className="my-4" />

//                                                     </Row>



//                                                     <Row>

//                                                         <Col className="col-6">

//                                                         <div className="mb-3">

//                                                                 <label>Username :<span className="text-danger"> *</span></label>

//                                                                 <AvField

//                                                                     name="username"

//                                                                     // label="Username"

//                                                                     type="text"

//                                                                     required

//                                                                     placeholder="Enter username"

//                                                                     disabled={true}

//                                                                     value={this.state.given_email}

//                                                                 />

//                                                             </div>

//                                                         </Col>

//                                                     {/* </Row> */}



//                                                     {/* <Row> */}

//                                                         <Col className="col-6">

//                                                         <div className="mb-3">

//                                                                 <label>Password :<span className="text-danger"> *</span></label>

//                                                                 <AvField

//                                                                     name="password"

//                                                                     autoComplete = "new-password"

//                                                                     // label="Password"

//                                                                     type="password"

//                                                                     required

//                                                                     placeholder="Enter password"

//                                                                     validate={{

//                                                                         required: { value: true, errorMessage: 'Password is required' },

//                                                                         minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },

//                                                                         pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },

//                                                                     }}

//                                                                 />

//                                                             </div>

//                                                         </Col>

//                                                         {/* <Col className="col-6">

//                                                             <div className="mb-3">

//                                                                 <AvField

//                                                                     name="confirmpassword"

//                                                                     label="Confirm Password"

//                                                                     type="password"

//                                                                     required

//                                                                     placeholder="Enter Confirm password"

//                                                                 />

//                                                             </div>

//                                                         </Col> */}

//                                                     </Row>





//                                                     {/* <Row className="my-4">

//                                                         <div className="text-danger font-size-18">Set Permissions</div>

//                                                         <hr className="my-4" />

//                                                     </Row>    */}



//                                                 {/* <Row>



//                                                     {

//                                                         this.state.configData.facilities.map((item, idx)=>{

//                                                             return(

//                                                                 <Col className="col-auto" key={"pn"+idx}>

//                                                                     <div className="form-check mb-3">

//                                                                         <input

//                                                                             className="form-check-input"

//                                                                             type="checkbox"

//                                                                             value=""

//                                                                             id={"pmsn"+idx}

//                                                                             disabled={item.id === "7" ? false : true}

//                                                                             defaultChecked={item.id === "7"}

//                                                                             onChange={(e) => { this.addPermissions(e, item) }}

//                                                                             //checked={item.is_compliance}

//                                                                         />

//                                                                         <label

//                                                                             className="form-check-label"

//                                                                             htmlFor={"pmsn"+idx}

//                                                                         >

//                                                                             {item.interfacename}

//                                                                         </label>

//                                                                     </div>

//                                                                 </Col>

//                                                             )

//                                                         })

//                                                     }

                                                    

//                                                 </Row>          */}





//                                                     <div className="mb-3" >



//                                                     </div>

//                                                     {

//                                                         this.state.alertEnable ?

//                                                             <div className="mt-4 d-grid">

//                                                                 <Alert color={this.state.alertColor}>

//                                                                     {this.state.message}

//                                                                 </Alert>

//                                                             </div>

//                                                             : null

//                                                     }





//                                                     <div className="mt-4 d-grid">

//                                                         <button

//                                                             className="btn btn-danger btn-block"

//                                                             type="submit"

//                                                             disabled={this.state.loading}

//                                                         >

//                                                            {this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Add User"}

//                                                         </button>

//                                                     </div>



//                                                 </AvForm>

//                                             </div>

//                                         </CardBody> :

//                                         <CardBody>

//                                             <div className="mt-4 text-center">

//                                                 <div className="mb-0" style={{ fontSize: 20 }}>

//                                                     <span className="text-danger">{this.state.first_name}</span> has added successfully

//                                                 </div>

//                                                 <div style={{ marginTop: 20 }}>

//                                                     <Link  className="btn btn-success" onClick={()=>this.props.onClose()}>

//                                                         Back

//                                                     </Link>

//                                                 </div>

//                                             </div>

//                                         </CardBody>

//                                 }

//                             </Card>



//                         </Col>

//                     </Row>

//                 </Container>

//         </React.Fragment>

//                   // <AddUserNode

//                   //   onClose={this.onDrawerClose}

//                   //    updtUser = {this.changeAuditUserHandler}

//                   //    getAllUsers = {this.getTemplateMethod}

//                   //   rowData={this.state.emptData}

//                   //   getDidmountFunction={this.getDidmountFunction}

//                   //   didmount = {this.componentDidMount}

                    

//                   // />

//                   : this.state.component === "endpoint" ?

//                     <AddEndpointNode

//                       endpoints={this.state.publishTemplate.endpoints}

//                       publishtemplateInfo={this.state.publishTemplate}

//                       onClose={this.onDrawerClose}

//                       editItem={this.state.editItem}

//                       onCancel={this.onCancelDrawerClose}

//                     /> : this.state.component === "loadendpoint" ?

                    

//                     <LoadEndpoint

//                     userInfo={this.state.userInfo}

//                     publishtemplateInfo={this.state.publishTemplate}

//                     onClose={this.onDrawerClose}



//                     />

//                     :

//                     // this.state.component === "loadgroupusers" ?

//                     // <LoadGroupUser

//                     // userInfo={this.state.userInfo}

//                     // endpoint_data ={this.state.endpoint_data}

//                     // publishtemplateInfo={this.state.publishTemplate}

//                     // onClose={this.onDrawerClose}

//                     // />:

                      

                    

//                     null

//               }

//         {/* {

//           console.log(this.state.userInfo,'this.state.userInfo',this.state.publishTemplate)

//         } */}





//               {

//                 //(this.state.publishTemplate,'publish')

//               }

//             </div>

//           </ReactDrawer>

         

//           {this.state.changeMethodConfirmation ? (

//             <SweetAlert

//               title="Are you sure?"

//               warning

//               showCancel

//               confirmBtnBsStyle="success"

//               cancelBtnBsStyle="danger"

//               onConfirm={() => {

//                 this.state.triggerFrom === "hlevel" ? this.resetEndpointMethod() : this.state.triggerFrom === "endpoint" ? this.deleteEndpoint() : this.state.triggerFrom === "deleteall" && this.deleteAllEndpoints() 

//               }

//               }

//               onCancel={() =>

//                 this.setState({

//                   changeMethodConfirmation: false,

//                   error_dlg: true,

//                   dynamic_title: "Cancelled",

//                   dynamic_description:

//                     "Your imaginary file is safe :)",

//                 })

//               }

//             >

//               {this.state.triggerFrom === "hlevel" ? "Changing the Add Location method will clear all saved data." : this.state.triggerFrom === "endpoint" ? "Deleting the Location can't revet.": this.state.triggerFrom === "deleteall" ? "Deleteing all Location can't revert" : ""}

//             </SweetAlert>

//           ) : null}

//           {this.state.success_dlg ? (

//             <SweetAlert

//               success

//               title={this.state.dynamic_title}

//               onConfirm={() => this.setState({ success_dlg: false })}

//             >

//               {this.state.dynamic_description}

//             </SweetAlert>

//           ) : null}

//           {this.state.dataAlert ? (

//             <SweetAlert

//               title={this.state.alertMessage}

//               onConfirm={() => this.setState({ dataAlert: false })}

//             ></SweetAlert>

//           ) : null}



//           {this.state.tmpltPublishProcess ? (

//             <SweetAlert

//               title={this.state.alertMessage}

//               onConfirm={() => this.redirectTo(this.state.processStatus)}

//             ></SweetAlert>

//           ) : null}

//         </React.Fragment>

//       )

//     }

//     else {

//       return null

//     }

//   }

// }










// ///// 7-9-23 using bootstrap table
// // import React, { Component } from "react"

// // import MetaTags from 'react-meta-tags';
// // import {
// //   Container,
// //   Row,
// //   Col,
// //   Table,
// //   Input,
// //   Nav,
// //   NavItem,
// //   NavLink,
// //   TabContent,
// //   TabPane,
// //   Card,
// //   Form,
// //   FormGroup,
// //   Label,
// //   CardBody,
// //   CardTitle,
// //   CardSubtitle,
// //   InputGroup,
// //   Button,
// //   Alert
// // } from "reactstrap"
// // import Select from "react-select"
// // import { Link } from "react-router-dom"
// // import facility from '../FacilityScreen'
// // import SweetAlert from "react-bootstrap-sweetalert"
// // import LoadGroupUser from './Components/LoadGroupUser'

// // import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// // import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// // import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// // import BootstrapTable from "react-bootstrap-table-next"

// // import makeAnimated from "react-select/animated"
// // import "flatpickr/dist/themes/material_blue.css"
// // import Flatpickr from "react-flatpickr"

// // import SortableTree, {
// //   addNodeUnderParent,
// //   removeNodeAtPath,
// //   toggleExpandedForAll,
// //   getTreeFromFlatData,
// //   getNodeAtPath,
// //   changeNodeAtPath,
// //   getFlatDataFromTree,
// //   walk,
// //   map, find
// // } from 'react-sortable-tree/dist/index.cjs.js';
// // import ReactDrawer from 'react-drawer';
// // import 'react-drawer/lib/react-drawer.css';

// // import classnames from "classnames"

// // //import AddNode from "./Components/AddNode"

// // import AddUserNode from "./Components/AddUserNode"
// // import AddEndpointNode from "./Components/AddEndpointNode"

// // //Import Breadcrumb
// // import Breadcrumbs from "../../components/Common/Breadcrumb"

// // import CheckboxTree, {
// //   getNode, flattenNodes, deserializeLists, serializeList
// // } from 'react-checkbox-tree';
// // import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// // import { MdCheckBox, MdCheckBoxOutlineBlank, MdChevronRight, MdKeyboardArrowDown, MdAddBox, MdIndeterminateCheckBox, MdLocationCity, MdStore, MdFolder, MdCheckCircle, MdDescription } from "react-icons/md";
// // import CryptoJS from 'crypto-js'
// // import _, { repeat } from "lodash";

// // import Validation from "./Functions/validation"
// // import LoadEndpoint from "./Components/LoadEndpoints"
// // import { LoadingOutlined } from '@ant-design/icons';

// // import { FolderOutlined, FileOutlined } from '@ant-design/icons';
// // import classNames from 'classnames';
// // import { DownOutlined } from '@ant-design/icons';
// // import { Tree } from 'antd';






// // var urlSocket = require("../../helpers/urlSocket");
// // var moment = require('moment')

// // const icons = {
// //   check: <MdCheckBox className="rct-icon rct-icon-check text-success" />,
// //   uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
// //   halfCheck: (
// //     <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
// //   ),
// //   expandClose: (
// //     <MdChevronRight className="rct-icon rct-icon-expand-close" />
// //   ),
// //   expandOpen: (
// //     <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
// //   ),
// //   expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
// //   collapseAll: (
// //     <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
// //   ),
// //   parentClose: <MdLocationCity className="rct-icon rct-icon-parent-close" />,
// //   parentOpen: <MdLocationCity className="rct-icon rct-icon-parent-open" />,
// //   leaf: <MdStore className="rct-icon rct-icon-leaf-close" />
// // }


// // const nodes = [{
// //   "value": 'Mars',
// //   "label": 'Mars',
// //   "children": [
// //     { "value": 'Phobos', "label": 'Phobos' },
// //     { "value": 'Deimos', "label": 'Deimos' },
// //   ],
// // }];



// // export default class publishconfig extends Component {

// //   constructor(props) {
// //     super(props);

// //     this.state = {
// //       activeTab: 1,
// //       activeCustomTab: 1,
// //       selectedGroup: null,
// //       dataloaded: false,
// //       isEdit: false,
// //       hirerachyData: [],
// //       hlevelData: [],
// //       hlevelIsSelected: false,
// //       endpointSelected: false,
// //       locationData: [],
// //       checked: [],
// //       expanded: [],
// //       hlevelSelected: '',
// //       auditUserlevelSelected: false,
// //       reviewUserlevelSelected: false,
// //       endpoints: [],
// //       endpointsAvailable: false,
// //       repeatModeData: [],
// //       weekDays: [],
// //       dayStartNumber: [],
// //       userdata: [],
// //       position: 'right',
// //       open: false,
// //       height: props.height,
// //       methodSelected: "0",
// //       component: "",
// //       triggerFrom: "",
// //       paginationPage: 1,
// //       sderror: false,
// //       ederror: false,
// //       publishTemplate:{},
// //       open_group : false,
// //       hirerachy_user_level:[],
// //       // dataloaded: false,
// //       alertEnable:false,
// //       formDisabled:false,
// //       userInfo:{},
// //       secret_key: 'Pkdh347ey%3Tgs',
// //       loading:false,
// //       invalid_user: false,
// //       invalid_phone_num: false,
// //       invalid_usercode: false,
// //       country_code_err:false,
// //       permissionsadded:[],
// //       given_email:'',

// //       checkedKeys: []
// //     }
// //     this.toggleTab = this.toggleTab.bind(this)
// //     this.toggleCustomTab = this.toggleCustomTab.bind(this)
// //     this.handleSelectGroup = this.handleSelectGroup.bind(this)
// //     //this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this)
// //     this.handleMStartGroupChange = this.handleMStartGroupChange.bind(this)
// //     this.handleMEndGroupChange = this.handleMEndGroupChange.bind(this)
// //     this.getDateFormateMethod = this.getDateFormateMethod.bind(this)
// //     this.closeDrawer = this.closeDrawer.bind(this);
// //     this.onDrawerClose = this.onDrawerClose.bind(this);
// //     this.onCancelDrawerClose = this.onCancelDrawerClose.bind(this)
// //     this.setPosition = this.setPosition.bind(this);
// //     this.endpointMethod = this.endpointMethod.bind(this);

// //   }

// //   closeDrawer() {
// //     this.setState({ open: false });
// //   }
// //   onDrawerClose() {
// //     this.setState({ auditUserlevelSelected: false, component: "" })
// //     this.setState({ open: false });
// //     // this.state.publishTemplate.cc_level = 0
// //     try {
// //       urlSocket.post("webphlbconf/gettemplate", {
// //         userInfo: {
// //           encrypted_db_url:this.state.db_info.encrypted_db_url,
// //           created_by: this.state.userInfo._id,
// //           company_id: this.state.userInfo.company_id,
// //         },
// //         publishtemplateInfo: this.state.publishTemplate
// //       })
// //         .then(response => {
// //           //(response,'response')
// //           //(response,'july-13')
// //           if (response.data.response_code === 500) {
// //             //("response.data.data.endpoints", response.data.data.endpoints)
// //             this.setState({
// //               publishTemplate: response.data.data,
// //               endpoints: response.data.data.endpoints,
// //               auditUserlevelSelected: true,
// //               // cc_level:response.data.data.cc_level
// //             }, function () {
// //               this.updateCCLevel()
// //             })

// //           }
// //         })
// //     } catch (error) {

// //     }

// //   }

// //   onCancelDrawerClose() {
// //     this.setState({ open: false, component: "" });
// //   }

// //   setPosition(e) {
// //     this.setState({ position: e.target.value });
// //   }

// //   getDidmountFunction(){
// //     //("22")
// //     var data = JSON.parse(sessionStorage.getItem("authUser"));
// //     var db_info = JSON.parse(sessionStorage.getItem("db_info"));
// //     //(db_info,'db_info')
// //     var publishTemplate = JSON.parse(sessionStorage.getItem("publishData"));
// //     console.log(publishTemplate,'publishTemplate')

// //     this.setState(
// //       {
// //         userInfo: data.user_data,
// //         client_info : data.client_info[0],
// //         configData: data.config_data,
// //         db_info:db_info,
// //         repeatModeData: data.config_data.repeat_mode,
// //         dataloaded: false,
// //         publishTemplate,
// //         height: window.innerHeight,
// //         dayStartNumber: this.generateNumber(1),
// //         dayEndNumber: this.generateNumber(2),
// //         hlevelIsSelected: false,
// //         // selectedEndpoint:publishTemplate.ep_level
// //       },
// //       async function () {
// //         await this.getTemplateMethod()
// //         this.getDateFormateMethod()
// //         this.loadUserLabels()
// //       }
// //     )




// //   }

// //   getEndpointsData = () => {
// //     console.log("Endpoint data")
// //     try {
// //       urlSocket.post("webphlbconf/onchangeaudituserlevel", {
// //         userInfo: {
// //           created_by: this.state.userInfo._id,
// //           company_id: this.state.userInfo.company_id,
// //           encrypted_db_url: this.state.db_info.encrypted_db_url
// //         },
// //         hInfo: {
// //           h_id: this.state.hirerachyData._id,
// //           publishtemplateInfo: this.state.publishTemplate
// //         }
// //       })
// //         .then(response => {
// //           console.log(response, 'response')
// //           // if (response.data.data.endpoints.length > 0) {
// //           //   response.data.data.endpoints.map((data, idx) => {
// //           //     if (data.adt_users.length > 0) {
// //           //       var auditer = _.filter(data.adt_users, { audit_type: "1" })
// //           //       var reviewer = _.filter(data.adt_users, { audit_type: "2" })
// //           //       var external_auditer = _.filter(data.adt_users, { audit_type: "3" })
// //           //       console.log(auditer, reviewer, external_auditer)
// //           //       if (auditer.length < 2 && auditer.length !== 0) {
// //           //         auditer[0]["rowValid"] = true
// //           //       }
// //           //       if (reviewer.length < 2 && reviewer.length !== 0) {
// //           //         reviewer[0]["rowValid"] = true
// //           //       }
// //           //       if (external_auditer.length < 2 && external_auditer.length !== 0) {
// //           //         external_auditer[0]["rowValid"] = true
// //           //       }

// //           //     }
// //           //   })
// //           // }
// //           this.setState({ publishTemplate: response.data.data})
// //         })
// //     }
// //     catch (error) {

// //     }
// //   }

// //   async componentDidMount() {
// //     //("111")
// //     this.getDidmountFunction()
// //     var data = JSON.parse(sessionStorage.getItem("authUser"));
// //     var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
// //     var db_info = JSON.parse(sessionStorage.getItem("db_info"));
// //     var hirerachy_user_level = data.config_data.hirerachy_user_level
// //     data.config_data.facilities.map((ele, idx) => {
// //       if (ele.id === "7") {
// //         //(data.config_data.facilities)
// //         data.config_data.facilities[idx].enabled = true
// //         this.setState({ permissionsadded: [data.config_data.facilities[idx]] }, () => {
// //           //  //(this.state.permissionsadded, 'permissions')
// //            })
// //       }
// //     })

// //     //("data.config_data", data.config_data)
// //     var protect_routes = await facility(data.facility_data, 'mngpblhtempt')
// //     if (protect_routes !== undefined) {
// //       this.setState(
// //         {
// //           sessionUserInfo: data.user_data,
// //           configData: data.config_data,

// //           dataloaded: true,
// //           db_info: db_info,
// //           client_info: client_info,
// //           hirerachy_user_level

// //         },
// //         function () {
// //           //    this.getEntityDetaitedData()
// //         }
// //       )
// //     }
// //     else {
// //       this.props.history.push('/dashboard')
// //     }
// //   }

// //   generateNumber(startNumber) {
// //     var genNumber = []
// //     for (var i = startNumber; i <= 31; i++) {
// //       genNumber.push({
// //         name: String(i),
// //         value: String(i)
// //       })
// //     }
// //     return genNumber
// //   }

// //   async endpointMethod(method) {
// //     this.state.publishTemplate.method_selected = method
// //     await this.updatePublishTemplateData()
// //     this.setState({
// //       methodSelected: method,
// //       dataloaded: false
// //     }, function () {
// //       if (method === "2") {
// //         this.gethlevelMethod()
// //       }
// //       else {
// //         this.getCustomMethod()
// //       }
// //     })
// //   }

// //   resetEndpointMethod() {

// //     urlSocket.post("webphlbconf/resettemplate", {
// //       userInfo: {
// //         encrypted_db_url:this.state.db_info.encrypted_db_url,
// //         created_by: this.state.userInfo._id,
// //         company_id: this.state.userInfo.company_id,
// //       },
// //       publishtemplateInfo: this.state.publishTemplate
// //     })
// //       .then(response => {
// //         //(response,'response')
// //         //("response -0-0-0", response)
// //         if (response.data.response_code == 500) {
// //           this.setState({
// //             publishTemplate: response.data.data,
// //             changeMethodConfirmation: false,
// //             methodSelected: response.data.data.method_selected,
// //             cc_level: response.data.data.cc_level,
// //             dataloaded: response.data.data.method_selected === "0" ? true : false
// //           })
// //         }
// //       })

// //   }

// //   async getTemplateMethod() {
// //     urlSocket.post("webphlbconf/gettemplate", {
// //       userInfo: {
// //         encrypted_db_url:this.state.db_info.encrypted_db_url,
// //         created_by: this.state.userInfo._id,
// //         company_id: this.state.userInfo.company_id,
// //       },
// //       publishtemplateInfo: this.state.publishTemplate
// //     })
// //       .then(response => {
// //         //(response,'response')
// //         if (response.data.response_code == 500) {
// //           //("response get template", response.data.data)
// //           this.setState({
// //             publishTemplate: response.data.data,
// //             methodSelected: response.data.data.method_selected,
// //             cc_level: response.data.data.cc_level,
// //             dataloaded: response.data.data.method_selected === "0" ? true : false
// //           },
// //             function () {
// //               if (response.data.data.method_selected === "2") {
// //                 this.gethlevelMethod()
// //               }
// //               else if (response.data.data.method_selected === "1") {
// //                 this.getCustomMethod()
// //               }
// //             }
// //           )

// //         }
// //       })
// //   }


// //   loadUserLabels() {
// //     try {
// //         urlSocket.post("userSettings/get-user-group", {
// //             userInfo: {
// //                 encrypted_db_url: this.state.db_info.encrypted_db_url,
// //                 _id: this.state.userInfo._id,
// //                 company_id: this.state.userInfo.company_id
// //             }
// //         })
// //             .then(response => {
// //                 //("user label", response)
// //                 this.setState({
// //                     labelData: response.data.data,
// //                     // dataLoaded: true

// //                 })
// //             })

// //     } catch (error) {
// //         //("catch error", error)
// //     }
// // }








// //   getCustomMethod() {
// //     urlSocket.post("webphlbconf/gethlmethod", {
// //       userInfo: {
// //         encrypted_db_url:this.state.db_info.encrypted_db_url,
// //         created_by: this.state.userInfo._id,
// //         company_id: this.state.userInfo.company_id,
// //       },
// //       publishtemplateInfo: this.state.publishTemplate
// //     })
// //       .then(response => {
// //         //(response,'response')
// //         //("response custom", response.data.data)
// //         if (response.data.response_code == 500) {

// //           var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })
// //           var wkdays = response.data.data.data.repeat_mode_config

// //           var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]
// //           var getHour = getTimeFromEvent.slice(0, 2)
// //           var getMinTime = String(parseInt(getHour + 1)) + "00"

// //           this.setState({
// //             publishTemplate: response.data.data.data,
// //             cc_level: response.data.data.data.cc_level,
// //             dataSource: response.data.data.hlevelData,
// //             auditUserlevelSelected: true,
// //             customEnableReview: response.data.data.data.enable_review,
// //             reviewUserlevelSelected: false,
// //             endpoints: response.data.data.data.endpoints,
// //             ptl_startdate: response.data.data.data.start_date,
// //             ptl_enddate: response.data.data.data.end_date,
// //             repeat_mode: response.data.data.data.repeat_mode,
// //             rm_config: response.data.data.data.repeat_mode_config,
// //             daily_endtime: response.data.data.data.repeat_mode_config.end_time,
// //             daily_starttime: response.data.data.data.repeat_mode_config.start_time,
// //             getMinTime,
// //             selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',
// //             weekDays: response.data.data.data.repeat_mode_config.days,
// //             dsf: wkdays.start_on,
// //             eoem: wkdays.ends_on,
// //             mstartoption: wkdays.mstartoption,
// //             mendoption: wkdays.mendoption,
// //             dayStartNumber: this.generateNumber(1),
// //             dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
// //             dataloaded: false,
// //           }, function () {
// //             this.getAllUsers()
// //           })
// //         }
// //       })
// //   }

// //   async gethlevelMethod() {
// //     urlSocket.post("webphlbconf/gethlmethod", {
// //       userInfo: {
// //         encrypted_db_url:this.state.db_info.encrypted_db_url,
// //         created_by: this.state.userInfo._id,
// //         company_id: this.state.userInfo.company_id,
// //       },
// //       publishtemplateInfo: this.state.publishTemplate
// //     })
// //       .then(response => {
// //         //(response,'response477')
// //         //("response", response.data.data)
// //         console.log(response,'response')
// //         if (response.data.response_code == 500) {

// //           var getSelectedHlevelIndex = _.findIndex(response.data.data.hlevelData, { hname: response.data.data.data.hlevel })
// //           var wkdays = response.data.data.data.repeat_mode_config

// //           var getTimeFromEvent = response.data.data.data.repeat_mode_config.start_time[0]
// //           var getHour = getTimeFromEvent.slice(0, 2)
// //           var getMinTime = String(parseInt(getHour + 1)) + "00"


// //           if (response.data.data.data.hlevel != null) {
// //             var getUniqueRecords = _.uniqBy(response.data.data.endpointData, el => `${el.hlevel}`)
// //             var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.ep_level })
// //             var getAuditUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.audit_userlevel })
// //             var getReviewUserlevelIndex = _.findIndex(getUniqueRecords, { hlevel: response.data.data.data.review_userlevel })
// //             var convertedFlatData
// //             if (response.data.data.data.ep_structure.length == 0) {
// //               convertedFlatData = this.convertFlatDataToTreeData(response.data.data.endpointData)
// //             }
// //             else {
// //               // console.log(response.data.data.data.ep_structure,'response.data.data.data.ep_structure')
// //               convertedFlatData = response.data.data.data.ep_structure
// //               this.handleRecursive(convertedFlatData)
// //               console.log(convertedFlatData,'convertedFlatData')
// //               // _.each(convertedFlatData,(item,idx)=>{
// //               //   if (convertedFlatData[idx].children !== undefined && convertedFlatData[idx].children !== null){
// //               //     convertedFlatData[idx]["converted"]
// //               //   }
// //               // })

// //             }
// //             //(getUniqueRecords,'getUniqueRecords')
// //             this.setState({
// //               publishTemplate: response.data.data.data,
// //               cc_level: response.data.data.data.cc_level,
// //               dataSource: response.data.data.hlevelData,
// //               selectedHLevelValue: String(getSelectedHlevelIndex + 1),
// //               hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,
// //               hlevelData: getUniqueRecords,
// //               hirerachyData: response.data.data.hlevelData[getSelectedHlevelIndex],
// //               selectedEndpoint: endpointIndex != -1 ? String(endpointIndex + 1) : "choose",
// //               endpointSelected: endpointIndex != -1 ? true : false,
// //               hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',
// //               locationData: convertedFlatData,
// //               checked: response.data.data.data.ep_selected,
// //               checkedKeys: response.data.data.data.ep_selected,
// //               expanded: response.data.data.data.ep_expanded,
// //               selectedAuditUserlevel: getAuditUserlevelIndex != -1 ? String(getAuditUserlevelIndex + 1) : "choose",
// //               audit_userlevel: response.data.data.data.audit_userlevel,
// //               review_userlevel: response.data.data.data.review_userlevel,
// //               auditUserlevelSelected: getAuditUserlevelIndex != -1 ? true : false,
// //               enableReview: response.data.data.data.enable_review,
// //               selectedReviewUserlevel: getReviewUserlevelIndex != -1 ? String(getReviewUserlevelIndex + 1) : "choose",
// //               reviewUserlevelSelected: getReviewUserlevelIndex != -1 ? true : false,
// //               endpoints: response.data.data.data.endpoints,
// //               ptl_startdate: response.data.data.data.start_date,
// //               ptl_enddate: response.data.data.data.end_date,
// //               repeat_mode: response.data.data.data.repeat_mode,
// //               rm_config: response.data.data.data.repeat_mode_config,
// //               selectedRepeatMode: response.data.data.data.repeat_mode != null ? response.data.data.data.repeat_mode_config.mode_id : 'choose',
// //               daily_endtime: response.data.data.data.repeat_mode_config.end_time,
// //               daily_starttime: response.data.data.data.repeat_mode_config.start_time,
// //               getMinTime,
// //               weekDays: response.data.data.data.repeat_mode_config.days,
// //               dsf: wkdays.start_on,
// //               eoem: wkdays.ends_on,
// //               mstartoption: wkdays.mstartoption,
// //               mendoption: wkdays.mendoption,
// //               dayStartNumber: this.generateNumber(1),
// //               dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
// //               dataloaded: false,
// //             }, function () {
// //               this.getAllUsers()
// //             })
// //           }
// //           else {
// //             this.setState({
// //               publishTemplate: response.data.data.data,
// //               cc_level: response.data.data.data.cc_level,
// //               dataSource: response.data.data.hlevelData,
// //               selectedHLevelValue: getSelectedHlevelIndex != -1 ? String(getSelectedHlevelIndex + 1) : "0",
// //               hlevelIsSelected: false,
// //               hlevelData: [],
// //               selectedEndpoint: "choose",
// //               endpointSelected: false,
// //               hlevelSelected: '',
// //               locationData: [],
// //               selectedAuditUserlevel: 'choose',
// //               audit_userlevel: response.data.data.data.audit_userlevel,
// //               review_userlevel: response.data.data.data.review_userlevel,
// //               auditUserlevelSelected: false,
// //               enableReview: false,
// //               selectedReviewUserlevel: 'choose',
// //               reviewUserlevelSelected: false,
// //               endpoints: response.data.data.data.endpoints,
// //               ptl_startdate: response.data.data.data.start_date,
// //               ptl_enddate: response.data.data.data.end_date,
// //               repeat_mode: null,
// //               rm_config: response.data.data.data.repeat_mode_config,
// //               daily_endtime: response.data.data.data.repeat_mode_config.end_time,
// //               daily_starttime: response.data.data.data.repeat_mode_config.start_time,
// //               getMinTime,
// //               selectedRepeatMode: 'choose',
// //               weekDays: response.data.data.data.repeat_mode_config.days,
// //               dsf: wkdays.start_on,
// //               eoem: wkdays.ends_on,
// //               mstartoption: wkdays.mstartoption,
// //               mendoption: wkdays.mendoption,
// //               dayStartNumber: this.generateNumber(1),
// //               dayEndNumber: this.generateNumber(parseInt(wkdays.start_on === null ? 1 : wkdays.start_on) + 1),
// //               dataloaded: false,
// //             }, function () {
// //               this.getAllUsers()
// //             })
// //           }
// //         }
// //       })
// //   }



// //   handleRecursive = (epTreedata) => {
// //     return epTreedata.map((item) => {
// //       if (item.children !== undefined && item.children !== null) {
// //         item["ep"] = true;
// //         item.children = this.handleRecursive(item.children);
// //       }
// //       return item;
// //     }).filter((item) => {
// //       // Filter out elements with null children and ep_level not matching
// //       return item.children !== null || this.state.publishTemplate.ep_level === item.ep_level;
// //     });
// //   }



// //   // handleRecursive = (epTreedata) => {
// //   //   _.each(epTreedata, (item, idx) => {
// //   //     if (epTreedata[idx].children !== undefined && epTreedata[idx].children !== null) {
// //   //       epTreedata[idx]["ep"] = true
// //   //       this.handleRecursive(epTreedata[idx].children)
// //   //     }
// //   //     if (epTreedata[idx].children == null && (this.state.publishTemplate.ep_level !== epTreedata[idx].ep_level)) {
// //   //       // delete epTreedata[idx]
// //   //       epTreedata.splice(idx,1)
// //   //     }
// //   //   })
// //   // }

// //   async getAllUsers() {
// //     //('getAllUsers')
// //     try {
// //       urlSocket.post("webphlbconf/getallusers", {
// //         userInfo: {
// //           encrypted_db_url:this.state.db_info.encrypted_db_url,
// //           created_by: this.state.userInfo._id,
// //           company_id: this.state.userInfo.company_id,
// //         },
// //         publishtemplateInfo: this.state.publishTemplate
// //       })
// //         .then((response) => {
// //           //(response,'response')
// //           this.setState({
// //             userdata: response.data.data,
// //             dataloaded: true
// //           })
// //         })
// //     } catch (error) {
// //       //("catch error", error)
// //     }
// //   }


// //   getHStructureListData = () => {
// //     try {

// //       urlSocket.post("webphlbconf/gethirhylevels", {
// //         userInfo: {
// //           created_by: this.state.userInfo._id,
// //           company_id: this.state.userInfo.company_id,
// //         },
// //         publishtemplateInfo: this.state.publishTemplate
// //       })
// //         .then((response) => {
// //           var getSelectedHlevelIndex = _.findIndex(response.data.data, { hname: this.state.publishTemplate.hlevel })
// //           this.setState({
// //             dataSource: response.data.data,
// //             selectedHLevelValue: String(getSelectedHlevelIndex + 1),
// //             hlevelIsSelected: getSelectedHlevelIndex != -1 ? true : false,
// //             dataloaded: true,
// //           })
// //           if (getSelectedHlevelIndex !== -1) {
// //             // this.selectLevel()
// //           }
// //         })
// //     } catch (error) {
// //       //("catch error", error)
// //     }
// //   }

// //   toggleTab(tab) {
// //     if (this.state.activeTab !== tab) {
// //       if (tab === 2) {
// //         this.setState({ auditUserlevelSelected: false })
// //         setTimeout(() => {
// //           this.setState({ auditUserlevelSelected: true })
// //         }, 10)
// //       }
// //       if (tab >= 1 && tab <= 4) {
// //         this.setState({
// //           activeTab: tab,
// //         })
// //       }
// //     }
// //   }

// //   toggleCustomTab(tab) {
// //     //(tab,'tab')
// //     if (this.state.activeCustomTab !== tab) {

// //       if (tab >= 1 && tab <= 4) {
// //         this.setState({
// //           activeCustomTab: tab,
// //         })
// //       }
// //     }
// //   }

// //   handleSelectGroup = selectedGroup => {
// //     this.setState({ selectedGroup })
// //   }

// //   selectLevel = (event) => {

// //     var hirerachyData = this.state.dataSource[parseInt(event.target.value) - 1] //JSON.parse(event.target.value)
// //     this.state.publishTemplate.hlevel = hirerachyData.hname
// //     this.state.publishTemplate.hlevel_id = hirerachyData._id
// //     this.state.publishTemplate.ep_level = null
// //     this.state.publishTemplate.ep_structure = []
// //     this.state.publishTemplate.eplevel_value = null
// //     this.state.publishTemplate.ep_selected = []
// //     this.state.publishTemplate.ep_expanded = []
// //     this.state.publishTemplate.endpoints = []
// //     this.state.publishTemplate.audit_userlevel = null
// //     this.state.publishTemplate.audit_userlevel_value = null
// //     this.state.publishTemplate.enable_review = false
// //     this.state.publishTemplate.review_userlevel = null
// //     this.state.publishTemplate.review_userlevel_value = null
// //     this.state.publishTemplate.start_date = null
// //     this.state.publishTemplate.end_date = null
// //     this.state.publishTemplate.frequency = "One time"

// //     // document.getElementById("start_date").value = null
// //     // document.getElementById("end_date").value = null


// //     this.setState({
// //       checked: [],
// //       selectedAuditUserlevel: 'choose',
// //       auditUserlevelSelected: false,
// //       checkedKeys: []
// //     })

// //     if (this.state.endpointSelected) document.getElementById("location").value = "choose"

// //     this.updateCCLevel()
// //     try {
// //       urlSocket.post("webphlbconf/getlocationlevels", {
// //         userInfo: {
// //           created_by: this.state.userInfo._id,
// //           company_id: this.state.userInfo.company_id,
// //           encrypted_db_url : this.state.db_info.encrypted_db_url
// //         },
// //         hInfo: {
// //           h_id: hirerachyData._id,
// //           publishtemplateInfo: this.state.publishTemplate
// //         }
// //       })
// //         .then(response => {
// //           //(response,'response')
// //           if (response.data.response_code == 500) {
// //             var getUniqueRecords = _.uniqBy(response.data.data, el => `${el.hlevel}`)
// //             var endpointIndex = _.findIndex(getUniqueRecords, { hlevel: this.state.publishTemplate.ep_level })
// //             this.setState({
// //               cc_level: 0,
// //               hlevelData: getUniqueRecords,
// //               hirerachyData,
// //               selectedEndpoint: 'choose',
// //               endpointSelected: false,
// //               hlevelIsSelected: true,
// //               hlevelSelected: endpointIndex != -1 ? getUniqueRecords[endpointIndex].hlevel : '',
// //               ptl_startdate: null,
// //               ptl_enddate: null,
// //               ptl_frequency: "One time",
// //             })
// //           }
// //         })
// //     } catch (error) {
// //       //("catch error", error)
// //     }

// //   }


// //   selectLocation(event) {

// //     var endpointInfo = this.state.hlevelData[parseInt(event.target.value) - 1]

// //     this.state.publishTemplate.ep_level = endpointInfo.hlevel
// //     this.state.publishTemplate.eplevel_value = endpointInfo.hlevel_value
// //     this.state.publishTemplate.ep_structure = []
// //     this.state.publishTemplate.ep_selected = []
// //     this.state.publishTemplate.ep_expanded = []
// //     this.state.publishTemplate.audit_userlevel = null
// //     this.state.publishTemplate.audit_userlevel_value = null
// //     this.state.publishTemplate.enable_review = false
// //     this.state.publishTemplate.review_userlevel = null
// //     this.state.publishTemplate.review_userlevel_value = null
// //     this.state.publishTemplate.start_date = null
// //     this.state.publishTemplate.end_date = null
// //     this.state.publishTemplate.frequency = "One time"

// //     // document.getElementById("start_date").value = null
// //     // document.getElementById("end_date").value = null


// //     this.setState({ checked: [], expanded: [], selectedEndpoint: event.target.value,checkedKeys:[] })
// //     this.updateCCLevel()
// //     urlSocket.post("webphlbconf/getlocations", {
// //       userInfo: {
// //         created_by: this.state.userInfo._id,
// //         company_id: this.state.userInfo.company_id,
// //         encrypted_db_url : this.state.db_info.encrypted_db_url
// //       },
// //       hInfo: {
// //         h_id: this.state.hirerachyData._id,
// //         hlevelinfo: endpointInfo,
// //         publishtemplateInfo: this.state.publishTemplate
// //       }
// //     })
// //       .then(response => {
// //         //(response,'response')
// //         if (response.data.response_code == 500) {
// //           var convertedFlatData = this.convertFlatDataToTreeData(response.data.data)
// //           this.state.publishTemplate.ep_structure = convertedFlatData
// //           this.handleRecursive(convertedFlatData)
// //           this.setState({
// //             locationData: convertedFlatData,
// //           },
// //             function () {
// //               this.updateCheckedepStructure()
// //             }
// //           )
// //         }
// //       })

// //     this.setState({
// //       endpointSelected: true,
// //       hlevelSelected:
// //         this.state.hlevelData[parseInt(event.target.value) - 1].hlevel,
// //       selectedAuditUserlevel: 'choose',
// //       auditUserlevelSelected: false,
// //       enableReview: false,
// //       selectedReviewUserlevel: 'choose',
// //       reviewUserlevelSelected: false,
// //       cc_level: 0,
// //       ptl_startdate: null,
// //       ptl_enddate: null,
// //       ptl_frequency: "One time",
// //     })
// //   }

// //   convertFlatDataToTreeData = (arr) => {

// //     var tree = [],
// //       mappedArr = {},
// //       arrElem,
// //       mappedElem;

// //     // First map the nodes of the array to an object -> create a hash table.
// //     for (var i = 0, len = arr.length; i < len; i++) {
// //       arrElem = {
// //         "value": arr[i]._id,
// //         "label": arr[i].hlevel_name,
// //         "children": arr[i].children,
// //         "id": arr[i].id,
// //         "parent": arr[i].parent,
// //         "node_id": arr[i]._id,
// //         "ep_level":arr[i].hlevel,
// //         "ep_level_value":arr[i].hlevel_value
// //       }
// //       mappedArr[arrElem.id] = arrElem;
// //       mappedArr[arrElem.id]['children'] = []
// //     }


// //     for (var id in mappedArr) {
// //       if (mappedArr.hasOwnProperty(id)) {
// //         mappedElem = mappedArr[id];

// //         // If the element is not at the root level, add it to its parent array of children.
// //         if (mappedElem.parent) {
// //           mappedArr[mappedElem['parent']]['children'].push(mappedElem);
// //         }
// //         // If the element is at the root level, add it to first level elements array.
// //         else {
// //           tree.push(mappedElem);
// //         }
// //       }
// //     }

// //     let update = () => obj => {
// //       if (obj.children.length > 0)
// //         obj.children.forEach(update());
// //       else
// //         obj.children = null
// //     };

// //     tree.forEach(update());
// //     return tree;
// //   }

// //   handleEndpointSelection = (checked, event) => {
// //     //("event", event)
// //     this.setState({ checkedKeys: checked, cc_level: checked.length > 0 ? 1 : 0 }, async function () {
// //       this.state.publishTemplate.ep_structure = this.state.locationData
// //       this.state.publishTemplate.ep_selected = checked
// //       this.updateCheckedepStructure("allow")
// //     });
// //   }

// //   handleEndpointExpanded = expanded => {
// //     this.setState({ expanded }, function () {
// //       this.state.publishTemplate.ep_structure = this.state.locationData
// //       this.state.publishTemplate.ep_expanded = expanded
// //       this.updateCheckedepStructure()
// //     })
// //   }

// //   updateCheckedepStructure(access) {
// //     this.updateCCLevel()

// //     //("this.state.publishTemplate", this.state.publishTemplate)
// //     try {
// //       urlSocket.post("webphlbconf/updateepstructure", {
// //         userInfo: {
// //           created_by: this.state.userInfo._id,
// //           company_id: this.state.userInfo.company_id,
// //           encrypted_db_url : this.state.db_info.encrypted_db_url
// //         },
// //         hInfo: {
// //           publishtemplateInfo: this.state.publishTemplate
// //         }
// //       })
// //         .then(response => {
// //           //(response)
// //           if (access == "allow") {
// //             this.getEndpointsData()
// //           }
// //           //("response", response.data.data.ep_structure)
// //         })
// //     } catch (error) {

// //     }
// //   }

// //  selectAuditUserlevel(event) {
// //   var endpoints = this.state.publishTemplate.endpoints

// //   console.log(event.target.value,'evenet',this.state.publishTemplate,this.state.enableReview,endpoints,this.state.hlevelData)
// //   //  endpoints.map((data, idx) => {
// //   //    var filtered_data = _.filter(data.user_path, { audit_type: event.target.value })
// //   //    console.log(filtered_data, 'filtered_data')
// //   //    if (filtered_data.length < 2) {
// //   //      this.state.publishTemplate.endpoints[idx].adt_users = filtered_data
// //   //    }

// //   //  })

// //     //(event.target.value,this.state.hlevelData,'hlvldata',this.state.publishTemplate)
// //     // var userlevelInfo = this.state.hlevelData[parseInt(event.target.value) - 1]
// //     // console.log(userlevelInfo,'userlevelInfo')
// //     // //(userlevelInfo,'userlevelInfo')
// //     this.state.publishTemplate.audit_userlevel = event.target.value
// //    this.state.publishTemplate.audit_userlevel_value = Number(event.target.value)

// //     this.state.publishTemplate.enable_review = false
// //     this.state.publishTemplate.review_userlevel = null
// //     this.state.publishTemplate.review_userlevel_value = null
// //     //this.state.publishTemplate.cc_level = 2
// //     this.state.publishTemplate.start_date = null
// //     this.state.publishTemplate.end_date = null
// //     this.state.publishTemplate.frequency = "One time"

// //     // // document.getElementById("start_date").value = null
// //     // // document.getElementById("end_date").value = null

   

// //     // ////("this.state.publishTemplate", this.state.publishTemplate)
// //    var selected_audit_type = event.target.value
// //     this.setState({
// //       selectedAuditUserlevel: event.target.value,
// //       audit_userlevel: event.target.value == "1" ? "Auditer" : event.target.value == "2" ? "Reviewer" : event.target.value == "3" ? "Externa; Auditer" : null,
// //       auditUserlevelSelected: false,
// //       publishTemplate:this.state.publishTemplate,
// //       endpoints:this.state.publishTemplate.endpoints
// //     },
// //     )

// //     this.updateCCLevel()
// //     console.log(this.state.publishTemplate,'publish')
// //     // //(this.state.publishTemplate,'this.state.publishTemplate')
// //     try {
// //       urlSocket.post("webphlbconf/onchangeaudituserlevel", {
// //         userInfo: {
// //           created_by: this.state.userInfo._id,
// //           company_id: this.state.userInfo.company_id,
// //           encrypted_db_url : this.state.db_info.encrypted_db_url
// //         },
// //         hInfo: {
// //           h_id: this.state.hirerachyData._id,
// //           publishtemplateInfo: this.state.publishTemplate,
// //           selected_audit_type : event.target.value
// //         }
// //       })
// //         .then(response => {
// //           console.log(response,'response',selected_audit_type)
// //           //(response,'response')
// //           //("get endpoints", response.data.data, response.data.userdata)
// //           if (response.data.response_code === 500) {
// //             _.each(response.data.data.endpoints, item => {
// //               // console.log(!_.some(item.adt_users, { audit_type: "1"})
// //               if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
// //                 item["rowValid"] = false
// //               }
// //               else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { cat_type: selected_audit_type})) {
// //                 item["rowValid"] = false
// //               }
// //               else {
// //                 item["rowValid"] = true
// //               }
// //             })
// //             this.state.publishTemplate.endpoints = response.data.data.endpoints
// //             this.setState({
// //               endpoints: response.data.data.endpoints, endpointsAvailable: true,
// //               //userdata: response.data.userdata,
// //               publishTemplate:this.state.publishTemplate,
// //               enableReview: false,
// //               selectedReviewUserlevel: 'choose',
// //               review_userlevel: null,
// //               reviewUserlevelSelected: false,
// //               cc_level: response.data.data.cc_level,
// //               ptl_startdate: null,
// //               ptl_enddate: null,
// //               ptl_frequency: "One time",
// //               auditUserlevelSelected: true,
// //             },()=>{this.updatePublishTemplateData()})
// //           }
// //           this.updateCCLevel()
// //         })
// //     } catch (error) {
// //       //("catch error", error)
// //     }

// //   }

 


// //   // selectAuditUserlevel(event) {
// //   //   //(event.target.value,this.state.hlevelData,'hlvldata',this.state.publishTemplate)
// //   //   var userlevelInfo = this.state.hlevelData[parseInt(event.target.value) - 1]
// //   //   console.log(userlevelInfo,'userlevelInfo')
// //   //   //(userlevelInfo,'userlevelInfo')
// //   //   this.state.publishTemplate.audit_userlevel = userlevelInfo.hlevel
// //   //   this.state.publishTemplate.audit_userlevel_value = userlevelInfo.hlevel_value

// //   //   this.state.publishTemplate.enable_review = false
// //   //   this.state.publishTemplate.review_userlevel = null
// //   //   this.state.publishTemplate.review_userlevel_value = null
// //   //   //this.state.publishTemplate.cc_level = 2
// //   //   this.state.publishTemplate.start_date = null
// //   //   this.state.publishTemplate.end_date = null
// //   //   this.state.publishTemplate.frequency = "One time"

// //   //   // document.getElementById("start_date").value = null
// //   //   // document.getElementById("end_date").value = null

   
// //   //   console.log(this.state.publishTemplate,'publish')
// //   //   ////("this.state.publishTemplate", this.state.publishTemplate)

// //   //   this.setState({
// //   //     selectedAuditUserlevel: event.target.value,
// //   //     audit_userlevel: userlevelInfo.hlevel,
// //   //     auditUserlevelSelected: false,
// //   //     publishTemplate:this.state.publishTemplate,
// //   //     endpoints:this.state.publishTemplate.endpoints
// //   //   },
// //   //   )

// //   //   this.updateCCLevel()
// //   //   //(this.state.publishTemplate,'this.state.publishTemplate')
// //   //   try {
// //   //     urlSocket.post("webphlbconf/onchangeaudituserlevel", {
// //   //       userInfo: {
// //   //         created_by: this.state.userInfo._id,
// //   //         company_id: this.state.userInfo.company_id,
// //   //         encrypted_db_url : this.state.db_info.encrypted_db_url
// //   //       },
// //   //       hInfo: {
// //   //         h_id: this.state.hirerachyData._id,
// //   //         publishtemplateInfo: this.state.publishTemplate
// //   //       }
// //   //     })
// //   //       .then(response => {
// //   //         console.log(response,'response')
// //   //         //(response,'response')
// //   //         //("get endpoints", response.data.data, response.data.userdata)
// //   //         if (response.data.response_code === 500) {
// //   //           _.each(response.data.data.endpoints, item => {
// //   //             if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
// //   //               item["rowValid"] = false
// //   //             }
// //   //             else if (!this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" })) {
// //   //               item["rowValid"] = false
// //   //             }
// //   //             else
// //   //             {
// //   //               item["rowValid"] = true
// //   //             }
// //   //           })
// //   //           this.state.publishTemplate.endpoints = response.data.data.endpoints
// //   //           this.setState({
// //   //             endpoints: response.data.data.endpoints, endpointsAvailable: true,
// //   //             //userdata: response.data.userdata,
// //   //             enableReview: false,
// //   //             selectedReviewUserlevel: 'choose',
// //   //             review_userlevel: null,
// //   //             reviewUserlevelSelected: false,
// //   //             cc_level: response.data.data.cc_level,
// //   //             ptl_startdate: null,
// //   //             ptl_enddate: null,
// //   //             ptl_frequency: "One time",
// //   //             auditUserlevelSelected: true,
// //   //           })
// //   //         }
// //   //         this.updateCCLevel()
// //   //       })
// //   //   } catch (error) {
// //   //     //("catch error", error)
// //   //   }

// //   // }

// //   onchangeCustomEnableReview = (e) => {
// //     this.setState({ auditUserlevelSelected: false })
// //     this.state.publishTemplate.enable_review = e.target.checked
// //     var checkedData = e.target.checked
// //       //(checkedData,'checkedData',this.state.publishTemplate)
// //     //(checkedData)
// //     console.log(this.state.publishTemplate.endpoints,'this.state.publishTemplate.endpoints')
// //     _.each(this.state.publishTemplate.endpoints, item => {
// //       if (checkedData && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
// //         console.log("here")
// //         item["rowValid"] = false
// //       }
// //       else if (!checkedData && !_.some(item.adt_users, { audit_type: "1" })) {
// //         item["rowValid"] = false
// //       }
// //     })

// //     setTimeout(() => {
// //       this.setState({
// //         customEnableReview: checkedData,
// //         auditUserlevelSelected: true
// //       })
// //     }, 10)

// //     this.updatePublishTemplateData()

// //   }


// //   onchangeEnableReview(e) {
// //     this.setState({ auditUserlevelSelected: false })
// //     this.state.publishTemplate.enable_review = e.target.checked
// //     this.state.publishTemplate.review_userlevel = null
// //     this.state.publishTemplate.review_userlevel_value = null
// //     this.state.publishTemplate.start_date = null
// //     this.state.publishTemplate.end_date = null
// //     this.state.publishTemplate.frequency = "One time"

// //     var checkedData = e.target.checked
// //     console.log(checkedData, 'checkedData')
// //     _.each(this.state.publishTemplate.endpoints, item => {

// //       if (this.state.publishTemplate.enable_review && !_.some(item.adt_users, { audit_type: "1" }) || !_.some(item.adt_users, { audit_type: "2" })) {
// //         item["rowValid"] = false
// //       }
// //       else {
// //         item["rowValid"] = true
// //       }
// //     })

// //       //   var getIndex = _.findIndex(item.adt_users, {audit_type:"2"})
// //       //   if(getIndex !== -1){
// //       //     // item.adt_users.splice(getIndex, 1)
// //       //   }

// //       // })


// //     setTimeout(() => {
// //       this.setState({
// //         enableReview: checkedData,
// //         reviewUserlevelSelected: false,
// //         selectedReviewUserlevel: 'choose',
// //       //  cc_level: checkedData ? 1 : 2,
// //         ptl_startdate: null,
// //         ptl_enddate: null,
// //         ptl_frequency: "One time",
// //         review_userlevel: null,
// //         auditUserlevelSelected: true
// //       })
// //     }, 10)

// //     this.updatePublishTemplateData()
// //   }

// //   // selectReviewUserlevel(event) {
// //   //   this.setState({ auditUserlevelSelected: false })
// //   //   var userReviewlevelInfo = this.state.hlevelData[parseInt(event.target.value) - 1]

// //   //   var eventValue = event.target.value

// //   //   this.state.publishTemplate.review_userlevel = userReviewlevelInfo.hlevel
// //   //   this.state.publishTemplate.review_userlevel_value = userReviewlevelInfo.hlevel_value
// //   //  // this.state.publishTemplate.cc_level = 2
// //   //   this.state.publishTemplate.start_date = null
// //   //   this.state.publishTemplate.end_date = null
// //   //   this.state.publishTemplate.repeat_mode = null

// //   //   _.each(this.state.publishTemplate.endpoints, item=>{

// //   //     var getIndex = _.findIndex(item.adt_users, {audit_type:"2"})
// //   //     if(getIndex !== -1){
// //   //       item.adt_users.splice(getIndex, 1)
// //   //     }
// //   //   })

   

// //   //   this.updateCCLevel()
// //   //   try {
// //   //     urlSocket.post("webphlbconf/onchangereviewuserlevel", {
// //   //       userInfo: {
// //   //         created_by: this.state.userInfo._id,
// //   //         company_id: this.state.userInfo.company_id,
// //   //         encrypted_db_url : this.state.db_info.encrypted_db_url
// //   //       },
// //   //       hInfo: {
// //   //         h_id: this.state.hirerachyData._id,
// //   //         publishtemplateInfo: this.state.publishTemplate
// //   //       }
// //   //     })
// //   //       .then(response => {
// //   //         ////("get endpoints", response.data.data, response.data.response_code)
// //   //         if (response.data.response_code === 500) {
// //   //           //("this.state.publishTemplate.enableReview", this.state.publishTemplate.enableReview)
// //   //           if(this.state.publishTemplate.enable_review)
// //   //           {
// //   //             _.each(response.data.data.endpoints, item => {
// //   //               if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
// //   //                 item["rowValid"] = false
// //   //               }
// //   //             })
// //   //           }
// //   //           this.setState({
// //   //             publishTemplate: response.data.data,
// //   //             selectedReviewUserlevel: eventValue,
// //   //             reviewUserlevelSelected: true,
// //   //             cc_level: response.data.data.cc_level,
// //   //             ptl_startdate: null,
// //   //             ptl_enddate: null,
// //   //             repeatMode: null,
// //   //             endpoints: response.data.data.endpoints,
// //   //             review_userlevel: userReviewlevelInfo.hlevel,
// //   //             auditUserlevelSelected: true
// //   //           })

// //   //           this.updateCCLevel()
// //   //         }
// //   //       })
// //   //   } catch (error) {
// //   //     //("catch error", error)
// //   //   }


// //   // }





// //   selectReviewUserlevel(event) {
// //     this.setState({ auditUserlevelSelected: false })
// //   //   var userReviewlevelInfo = this.state.hlevelData[parseInt(event.target.value) - 1]

// //     var eventValue = event.target.value
// //     console.log(eventValue,'eventValue')
// //     this.state.publishTemplate.review_userlevel = eventValue
// //     this.state.publishTemplate.review_userlevel_value = Number(event.target.value)
// //   //  // this.state.publishTemplate.cc_level = 2
// //     this.state.publishTemplate.start_date = null
// //     this.state.publishTemplate.end_date = null
// //     this.state.publishTemplate.repeat_mode = null
// //     // var getIndex_dup =[]
// //     var getIndex
// //     console.log(this.state.publishTemplate,'this.state.publishTemplate')
// //     _.each(this.state.publishTemplate.endpoints, item=>{

// //        getIndex = _.findIndex(item.adt_users, {cat_type:eventValue})
// //       //  getIndex_dup.push(getIndex)
// //       console.log(getIndex,'getIndex',item.adt_users)
// //       if(getIndex !== -1){
// //         // item.adt_users.splice(getIndex, 1)
// //         // console.log(item.adt_users,'item.adt_users')
// //       }
// //     })

// //     console.log('getIndex',eventValue,this.state.publishTemplate)
// //     // var getIndex =getIndex.find(())
// //     if (this.state.publishTemplate.audit_userlevel !== eventValue) {
// //       this.updateCCLevel()

// //       console.log("inside api")
// //       try {
// //         urlSocket.post("webphlbconf/onchangereviewuserlevel", {
// //           userInfo: {
// //             created_by: this.state.userInfo._id,
// //             company_id: this.state.userInfo.company_id,
// //             encrypted_db_url: this.state.db_info.encrypted_db_url
// //           },
// //           hInfo: {
// //             h_id: this.state.hirerachyData._id,
// //             publishtemplateInfo: this.state.publishTemplate,
// //             selected_audit_type: eventValue
// //           }
// //         })
// //           .then(response => {
// //             console.log(response, 'response')
// //             ////("get endpoints", response.data.data, response.data.response_code)
// //             if (response.data.response_code === 500) {
// //               //("this.state.publishTemplate.enableReview", this.state.publishTemplate.enableReview)
// //               if (this.state.publishTemplate.enable_review) {
// //                 _.each(response.data.data.endpoints, item => {
// //                   // console.log(!_.some(item.adt_users, { audit_type: "1"})
// //                   if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { audit_type: "2" }) || !_.some(item.adt_users, { audit_type: "1" }))) {
// //                     item["rowValid"] = false

// //                   }
// //                   else{
// //                     item["rowValid"] = true

// //                   }
// //                   //  if (this.state.publishTemplate.enable_review && !_.some(item.adt_users, { cat_type: eventValue})) {
// //                   //   item["rowValid"] = false
// //                   // }
// //                   // else {
// //                   //   item["rowValid"] = true
// //                   // }
// //                 })
// //                 // _.each(response.data.data.endpoints, item => {
// //                 //   if (this.state.publishTemplate.enable_review && (!_.some(item.adt_users, { cat_type: eventValue }))) {
// //                 //     item["rowValid"] = false
// //                 //   }
// //                 // })
// //               }
// //               this.setState({
// //                 publishTemplate: response.data.data,
// //                 selectedReviewUserlevel: eventValue,
// //                 reviewUserlevelSelected: true,
// //                 cc_level: response.data.data.cc_level,
// //                 ptl_startdate: null,
// //                 ptl_enddate: null,
// //                 repeatMode: null,
// //                 endpoints: response.data.data.endpoints,
// //                 review_userlevel: eventValue,
// //                 auditUserlevelSelected: true,
// //                 cc_level: response.data.data.endpoints.length > 1 ? 2 : 1
// //               },
// //               // ()=>{this.updatePublishTemplateData()
// //               // }
// //               )

// //               // this.updateCCLevel()
// //             }
// //           })
// //       } catch (error) {
// //         //("catch error", error)
// //       }
// //     }
// //     else{
// //        this.setState({ dataAlert: true, alertMessage: "This user is already selected to auditer. Try different user." })
// //       // this.setState({
// //       //   publishTemplate: response.data.data,
// //       //   selectedReviewUserlevel: eventValue,
// //       //   reviewUserlevelSelected: true,
// //       //   cc_level: response.data.data.cc_level,
// //       //   ptl_startdate: null,
// //       //   ptl_enddate: null,
// //       //   repeatMode: null,
// //       //   endpoints: response.data.data.endpoints,
// //       //   review_userlevel: eventValue,
// //       //   auditUserlevelSelected: true
// //       // })
// //     }


// //   }



// //   changeAuditUserHandler(event, rowData, idx,parameter) {

// //     console.log(event.target.value, rowData, idx,parameter,'event, rowData, idx,parameter')

// //     if(parameter === "1" && event.target.value !=="add"){

// //       console.log('1113')

// //     var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })

// //   }

// //   else{

// //     var checkUserAlreadyExist =  -1

// //     console.log('1118')

// //   }

// //     //("event", rowData, checkUserAlreadyExist,this.state.publishTemplate)



// //     if (checkUserAlreadyExist !== -1 && (this.state.customEnableReview || this.state.enableReview)) {

// //     console.log('1123')

// //       this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })

// //     }

// //     else if(event.target.value === "add") {

// //       // if () {

// //         console.log('1128')

// //         this.setState({ open: this.state.open ? false : true, component: "user", emptData: rowData, selected_index: idx })

// //       }

// //       else if (event.target.value == "loadgroupusers") {

// //         console.log('1132')

// //         this.setState({ open: this.state.open ? false : true, component: "loadgroupusers", endpoint_data: rowData })



// //       }

// //       else {



// //         this.setState({ auditUserlevelSelected: false })

// //         var auditUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]

// //         var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })

// //         var getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })

// //         console.log('1142')

// //         //(auditUser,getIndex,getUserIndex,'getUserIndex')

// //         if (getUserIndex == -1) {

// //           console.log('1145')

// //           this.state.publishTemplate.endpoints[getIndex].adt_users.push({

// //             user_id: auditUser._id,

// //             name: auditUser.firstname,

// //             designation: auditUser.designation,

// //             user_code: auditUser.usercode,

// //             audit_type: "1"

// //           })

// //           var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })

// //           var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })



// //           this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false



// //         }

// //         else {

// //           this.state.publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)

// //           console.log('1161')

// //           //(auditUser,getIndex,getUserIndex,'getUserIndex')

// //           this.state.publishTemplate.endpoints[getIndex].adt_users.push({

// //             user_id: auditUser._id,

// //             name: auditUser.firstname,

// //             designation: auditUser.designation,

// //             user_code: auditUser.usercode,

// //             audit_type: "1"

// //           })

// //           var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })

// //           var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

// //           this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false



// //         }





// //         this.updatePublishTemplateData()

// //         setTimeout(() => {

// //           this.setState({

// //             endpoints: this.state.publishTemplate.endpoints,

// //             auditUserlevelSelected: true,

// //           })

// //         }, 10)

// //       }

// //       this.setState({ user_type: 0 })

// //     // }



// //   }



// //   changeReviewUserHandler(event, rowData, idx, parameter) {

// //     //("event", event.target.value, rowData, this.state.publishTemplate)



// //     if(parameter ==="1"){

// //     var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })

// //   }

// //   else{

// //     var checkUserAlreadyExist = -1

// //   }



// //     if (checkUserAlreadyExist !== -1) {

// //       this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })

// //     }

// //     else {

// //       if (event.target.value === "add") {

// //         this.setState({ open: this.state.open ? false : true, component: "user" , selected_index: idx})

// //       }

// //       else {

// //         //('this.state.endpoints', this.state.endpoints)



// //         this.setState({ auditUserlevelSelected: false })



// //         var reviewUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]

// //         var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })





// //         var getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })



// //         if (getUserIndex == -1) {

// //           this.state.publishTemplate.endpoints[getIndex].adt_users.push({

// //             user_id: reviewUser._id,

// //             name: reviewUser.firstname,

// //             designation: reviewUser.designation,

// //             user_code: reviewUser.usercode,

// //             audit_type: "2"

// //           })

// //           var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })

// //           var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

// //           this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false





// //         }

// //         else {

// //           this.state.publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)

// //           this.state.publishTemplate.endpoints[getIndex].adt_users.push({

// //             user_id: reviewUser._id,

// //             name: reviewUser.firstname,

// //             designation: reviewUser.designation,

// //             user_code: reviewUser.usercode,

// //             audit_type: "2"

// //           })

// //           var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })

// //           var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

// //           this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false



// //         }









// //         // var checkep = this.checkEndpoints()

// //         // //("checkep", checkep)



// //         // if(checkep)

// //         // {

// //         //   this.state.publishTemplate.cc_level = 2

// //         // }



// //         this.updatePublishTemplateData()

// //         setTimeout(() => {

// //           this.setState({

// //             auditUserlevelSelected: true,

// //             // cc_level: checkep ? 2 : this.state.cc_level

// //           })



// //         }, 10)

// //       }

// //     }

// //     this.setState({user_type: 1})





// //   }




// //   // changeAuditUserHandler(event, rowData, idx,parameter) {
// //   //   console.log(event.target.value, rowData, idx,parameter,'event, rowData, idx,parameter')
// //   //   if(parameter === "1" && event.target.value !=="add"){
// //   //     //('1113')
// //   //   var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
// //   // }
// //   // else{
// //   //   var checkUserAlreadyExist =  -1
// //   //   //('1118')
// //   // }
// //   //   //("event", rowData, checkUserAlreadyExist,this.state.publishTemplate)

// //   //   if (checkUserAlreadyExist !== -1 && (this.state.customEnableReview || this.state.enableReview)) {
// //   //   //('1123')
// //   //     this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
// //   //   }
// //   //   else if(event.target.value === "add") {
// //   //     // if () {
// //   //       //('1128')
// //   //       this.setState({ open: this.state.open ? false : true, component: "user", emptData: rowData, selected_index: idx })
// //   //     }
// //   //     else if (event.target.value == "loadgroupusers") {
// //   //       //('1132')
// //   //       this.setState({ open: this.state.open ? false : true, component: "loadgroupusers", endpoint_data: rowData })

// //   //     }
// //   //     else {

// //   //       this.setState({ auditUserlevelSelected: false })
// //   //       var auditUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
// //   //       //(auditUser,'auditUser')
// //   //       var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })
// //   //       var getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
// //   //       //('1142',getUserIndex, this.state.publishTemplate.endpoints)
// //   //       //(auditUser,getIndex,getUserIndex,'getUserIndex')
// //   //       if (getUserIndex == -1) {
// //   //         //('1145')
// //   //         this.state.publishTemplate.endpoints[getIndex].adt_users.push({
// //   //           user_id: auditUser._id,
// //   //           name: auditUser.firstname,
// //   //           designation: auditUser.designation,
// //   //           user_code: auditUser.usercode,
// //   //           cat_type : this.state.publishTemplate.audit_userlevel,
// //   //           audit_type: "1"
// //   //         })
// //   //         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
// //   //         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

// //   //         this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

// //   //       }
// //   //       else {
// //   //         this.state.publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
// //   //         //('1161')
// //   //         //(auditUser,getIndex,getUserIndex,'getUserIndex')
// //   //         this.state.publishTemplate.endpoints[getIndex].adt_users.push({
// //   //           user_id: auditUser._id,
// //   //           name: auditUser.firstname,
// //   //           designation: auditUser.designation,
// //   //           user_code: auditUser.usercode,
// //   //           cat_type : this.state.publishTemplate.audit_userlevel,
// //   //           audit_type: "1"
// //   //         })
// //   //         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
// //   //         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
// //   //         this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

// //   //       }


// //   //       this.updatePublishTemplateData()
// //   //       setTimeout(() => {
// //   //         this.setState({
// //   //           endpoints: this.state.publishTemplate.endpoints,
// //   //           auditUserlevelSelected: true,
// //   //         })
// //   //       }, 10)
// //   //     }
// //   //     this.setState({ user_type: 0 })
// //   //   // }

// //   // }

// //   // changeReviewUserHandler(event, rowData, idx, parameter) {
// //   //   //("event", event.target.value, rowData, this.state.publishTemplate)

// //   //   if(parameter ==="1"){
// //   //   var checkUserAlreadyExist = _.findIndex(rowData.adt_users, { user_id: event.target.value })
// //   // }
// //   // else{
// //   //   var checkUserAlreadyExist = -1
// //   // }

// //   //   if (checkUserAlreadyExist !== -1) {
// //   //     this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
// //   //   }
// //   //   else {
// //   //     if (event.target.value === "add") {
// //   //       this.setState({ open: this.state.open ? false : true, component: "user" , selected_index: idx})
// //   //     }
// //   //     else {
// //   //       //('this.state.endpoints', this.state.endpoints)

// //   //       this.setState({ auditUserlevelSelected: false })

// //   //       var reviewUser = _.filter(this.state.userdata, { _id: (event.target.value) })[0]
// //   //       var getIndex = _.findIndex(this.state.endpoints, { _id: (rowData._id) })


// //   //       var getUserIndex = _.findIndex(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })

// //   //       if (getUserIndex == -1) {
// //   //         this.state.publishTemplate.endpoints[getIndex].adt_users.push({
// //   //           user_id: reviewUser._id,
// //   //           name: reviewUser.firstname,
// //   //           designation: reviewUser.designation,
// //   //           user_code: reviewUser.usercode,
// //   //           cat_type:this.state.publishTemplate.review_userlevel,
// //   //           review_user:true,
// //   //           audit_type: "2"
// //   //         })
// //   //         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
// //   //         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
// //   //         this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false


// //   //       }
// //   //       else {
// //   //         this.state.publishTemplate.endpoints[getIndex].adt_users.splice(getUserIndex, 1)
// //   //         this.state.publishTemplate.endpoints[getIndex].adt_users.push({
// //   //           user_id: reviewUser._id,
// //   //           name: reviewUser.firstname,
// //   //           designation: reviewUser.designation,
// //   //           user_code: reviewUser.usercode,
// //   //           cat_type:this.state.publishTemplate.review_userlevel,
// //   //           review_user:true,
// //   //           audit_type: "2"
// //   //         })
// //   //         var checkAuditUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "1" })
// //   //         var checkReviewUserExist = _.some(this.state.publishTemplate.endpoints[getIndex].adt_users, { audit_type: "2" })
// //   //         this.state.publishTemplate.endpoints[getIndex]["rowValid"] = !this.state.customEnableReview && checkAuditUserExist ? true : this.state.customEnableReview && checkAuditUserExist && checkReviewUserExist ? true : false

// //   //       }




// //   //       // var checkep = this.checkEndpoints()
// //   //       // //("checkep", checkep)

// //   //       // if(checkep)
// //   //       // {
// //   //       //   this.state.publishTemplate.cc_level = 2
// //   //       // }

// //   //       this.updatePublishTemplateData()
// //   //       setTimeout(() => {
// //   //         this.setState({
// //   //           auditUserlevelSelected: true,
// //   //           // cc_level: checkep ? 2 : this.state.cc_level
// //   //         })

// //   //       }, 10)
// //   //     }
// //   //   }
// //   //   this.setState({user_type: 1})


// //   // }

// //   checkEndpoints = () => {
// //     var checkAudituser = _.some(this.state.publishTemplate.endpoints, { audit_user: null })
// //     var checkReviewuser = _.some(this.state.publishTemplate.endpoints, { review_user: null })

// //     if (!this.state.customEnableReview && !checkAudituser) {
// //       return true //this.setState({cc_level:2})
// //     }
// //     else if (!this.state.checkAudituser && (this.state.customEnableReview && !checkReviewuser)) {
// //       return true //this.setState({cc_level:2})
// //     }
// //     else {
// //       return false
// //     }
// //   }

// //   getDateFormateMethod = () => {
// //     var today = new Date()
// //     const dd = today.getDate().toString().length == 1 ? "0" + today.getDate().toString() : today.getDate().toString()
// //     const mm = String(today.getMonth() + 1).length == 1 ? "0" + String(today.getMonth() + 1) : today.getMonth() + 1
// //     const yyyy = today.getFullYear()
// //     const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
// //     this.setState({ fromate_date })
// //   }

// //   onChangeStartDate(event) {
// //     console.log('evnt', event, this.state.ptl_startdate > this.state.ptl_enddate )
// //     this.state.publishTemplate.start_date = event.target.value === '' ? null : event.target.value
// //    this.state.publishTemplate.end_date = event.target.value > this.state.publishTemplate.end_date ? null :this.state.publishTemplate.end_date
// //     // this.state.publishTemplate.end_date = null
// //     //this.state.publishTemplate.cc_level = 3
// //     if(this.state.ptl_startdate > this.state.ptl_enddate ){
// //      this.state.publishTemplate.cc_level =    2 
// //     }else{
// //      this.state.publishTemplate.cc_level =   event.target.value === '' ? 2 : 3

// //     }
// //     this.setState({ ptl_startdate: event.target.value, cc_level: this.state.publishTemplate.cc_level, publishTemplate: this.state.publishTemplate  })
// //     this.updatePublishTemplateData()
// //   }

// //   onChangeEndDate(event) {
// //     console.log('event.target.value', event.target.value)
// //     this.state.publishTemplate.end_date = event.target.value === '' ? null : event.target.value
// //     this.state.publishTemplate.cc_level =   event.target.value === '' ? 2 : 3
// //     this.setState({ ptl_enddate:  event.target.value , cc_level: this.state.publishTemplate.cc_level, publishTemplate: this.state.publishTemplate })
// //     this.updatePublishTemplateData()
// //   }

// //   onChangeStartTime(e) {

// //     var getTimeFromEvent = e[0].toLocaleTimeString()
// //     var getHour = getTimeFromEvent.slice(0, 2)
// //     var getMinTime = String(parseInt(getHour + 1)) + "00"


// //     this.state.publishTemplate.repeat_mode_config = {
// //       mode: this.state.publishTemplate.repeat_mode_config.mode,
// //       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
// //       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
// //       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
// //       days: this.state.publishTemplate.repeat_mode_config.days,
// //       start_time: [getTimeFromEvent],
// //       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
// //       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
// //       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
// //       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
// //       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

// //     }


// //     this.setState({ daily_starttime: e, getMinTime })
// //   }

// //   onChangeEndTime(e) {

// //     var getTimeFromEvent = e[0].toLocaleTimeString()
// //     this.state.publishTemplate.repeat_mode_config = {
// //       mode: this.state.publishTemplate.repeat_mode_config.mode,
// //       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
// //       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
// //       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
// //       days: this.state.publishTemplate.repeat_mode_config.days,
// //       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
// //       end_time: [getTimeFromEvent],
// //       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
// //       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
// //       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
// //       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

// //     }
// //     this.setState({ daily_endtime: e })
// //   }

// //   monthStartOn(event) {
// //     this.state.publishTemplate.repeat_mode_config = {
// //       mode: this.state.publishTemplate.repeat_mode_config.mode,
// //       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
// //       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
// //       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
// //       days: this.state.publishTemplate.repeat_mode_config.days,
// //       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
// //       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
// //       start_on: event.target.value,
// //       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
// //       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
// //       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,
// //     }

// //     this.setState({
// //       dsf: event.target.value,
// //       dayEndNumber: this.generateNumber(parseInt(event.target.value) + 1),
// //       publishTemplate: this.state.publishTemplate
// //     })
// //     this.updatePublishTemplateData()
// //   }

// //   monthEndsOn(event) {

// //     this.state.publishTemplate.repeat_mode_config = {
// //       mode: this.state.publishTemplate.repeat_mode_config.mode,
// //       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
// //       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
// //       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
// //       days: this.state.publishTemplate.repeat_mode_config.days,
// //       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
// //       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
// //       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
// //       ends_on: event.target.value,
// //       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
// //       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,
// //     }
// //     this.setState({
// //       eoem: event.target.value,
// //       publishTemplate: this.state.publishTemplate
// //     })
// //     this.updatePublishTemplateData()
// //   }



// //   updateCCLevel() {
// //     //(this.state.publishTemplate, 'this.state.publishTemplate')
// //     var updateCCState = Validation.updatecclevel(this.state.methodSelected, this.state.publishTemplate)
// //     //(updateCCState, 'updateCCState')
// //     console.log(updateCCState,'updateCCState')
// //     this.setState({ publishTemplate: updateCCState, cc_level: updateCCState.cc_level })
// //   }

  


// //   async updatePublishTemplateData() {
// //     //('1414',this.state.publishTemplate) 

// //     this.updateCCLevel()

// //     try {
// //       urlSocket.post("webphlbconf/updatepublishtemplate", {
// //         userInfo: {
// //           encrypted_db_url:this.state.db_info.encrypted_db_url,
// //           created_by: this.state.userInfo._id,
// //           company_id: this.state.userInfo.company_id
// //         },
// //         hInfo: {
// //           h_id: this.state.hirerachyData._id,
// //           publishtemplateInfo: this.state.publishTemplate
// //         }
// //       })
// //         .then(response => {
// //           console.log(response,'response')
// //           //(response,'response')
// //           if (response.data.response_code == 500) {
// //             //("update response", response.data.data.cc_level)
// //             this.setState({
// //               cc_level: response.data.data.cc_level,
// //             })
// //           }
// //         })
// //     } catch (error) {
// //       //("catch error", error)
// //     }
// //   }


// //   selectRepeatMode(event) {
// //     //(event,'event')
// //     var repeatMode = this.state.repeatModeData[parseInt(event.target.value)]

// //     this.state.publishTemplate.repeat_mode = repeatMode.mode
// //     this.state.publishTemplate.cc_level = 3
// //     this.state.publishTemplate.repeat_mode_config = {
// //       mode: repeatMode.mode,
// //       mode_id: repeatMode.mode_id,
// //       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
// //       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
// //       days: this.state.publishTemplate.repeat_mode_config.days,
// //       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
// //       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
// //       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
// //       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
// //       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
// //       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

// //     }

// //     this.setState({
// //       cc_level: 3,
// //       repeat_mode: repeatMode.mode,
// //       weekDays: this.state.publishTemplate.repeat_mode_config.days,
// //       rm_config: repeatMode,
// //       daily_starttime: this.state.publishTemplate.repeat_mode_config.start_time,
// //       daily_endtime: this.state.publishTemplate.repeat_mode_config.end_time,
// //       dsf: this.state.publishTemplate.repeat_mode_config.start_on,
// //       eoem: this.state.publishTemplate.repeat_mode_config.ends_on,
// //       selectedRepeatMode: event.target.value
// //     })
// //     this.updatePublishTemplateData()
// //   }

// //   navigateTo = (data) => {
// //     sessionStorage.removeItem("EditPublishedData");
// //     sessionStorage.removeItem("nagiationMode");
// //     sessionStorage.setItem("EditPublishedData", JSON.stringify(data));
// //     sessionStorage.setItem("nagiationMode", "from_config");
// //     this.props.history.push("/edtpblhtempt");
// //   }

// //   handleWeekDays(e) {
// //     var getIndex = _.findIndex(this.state.weekDays, { day: e.target.id })
// //     this.state.weekDays[getIndex]["checked"] = e.target.checked
// //     this.state.publishTemplate.repeat_mode_config = {
// //       mode: this.state.publishTemplate.repeat_mode_config.mode,
// //       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
// //       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
// //       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
// //       days: [...this.state.weekDays],
// //       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
// //       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
// //       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
// //       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
// //       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
// //       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,

// //     }

// //     if (this.state.publishTemplate.repeat_mode === "Weekly" &&
// //       _.some(this.state.publishTemplate.repeat_mode_config.days, { checked: true })) {
// //       this.state.publishTemplate["cc_level"] = 3
// //     }
// //     else {
// //       this.state.publishTemplate["cc_level"] = 2
// //     }

// //     this.setState({ weekDays: [...this.state.weekDays], publishTemplate: this.state.publishTemplate, cc_level: this.state.publishTemplate.cc_level })
// //   }

// //   ordinal_suffix_of(i) {
// //     var j = i % 10,
// //       k = i % 100;
// //     if (j == 1 && k != 11) {
// //       return "st";
// //     }
// //     if (j == 2 && k != 12) {
// //       return "nd";
// //     }
// //     if (j == 3 && k != 13) {
// //       return "rd";
// //     }
// //     return "th";
// //   }

// //   handleMStartGroupChange(event) {

// //     //("event trrigered", event.target.name)

// //     this.state.publishTemplate.repeat_mode_config = {
// //       mode: this.state.publishTemplate.repeat_mode_config.mode,
// //       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
// //       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
// //       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
// //       days: this.state.publishTemplate.repeat_mode_config.days,
// //       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
// //       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
// //       start_on: event.target.name === "month_start" ? null : this.state.publishTemplate.repeat_mode_config.start_on,
// //       ends_on: this.state.publishTemplate.repeat_mode_config.ends_on,
// //       mstartoption: event.target.name,
// //       mendoption: this.state.publishTemplate.repeat_mode_config.mendoption,
// //     }

// //     this.setState({
// //       mstartoption: event.target.name,
// //     })
// //     this.updatePublishTemplateData()
// //   }

// //   handleMEndGroupChange(event) {

// //     //("event trrigered --", event.target.name)
// //     this.state.publishTemplate.repeat_mode_config = {
// //       mode: this.state.publishTemplate.repeat_mode_config.mode,
// //       mode_id: this.state.publishTemplate.repeat_mode_config.mode_id,
// //       start_date: this.state.publishTemplate.repeat_mode_config.start_date,
// //       end_date: this.state.publishTemplate.repeat_mode_config.end_date,
// //       days: this.state.publishTemplate.repeat_mode_config.days,
// //       start_time: this.state.publishTemplate.repeat_mode_config.start_time,
// //       end_time: this.state.publishTemplate.repeat_mode_config.end_time,
// //       start_on: this.state.publishTemplate.repeat_mode_config.start_on,
// //       ends_on: event.target.name === "month_end" ? null : this.state.publishTemplate.repeat_mode_config.ends_on,
// //       mstartoption: this.state.publishTemplate.repeat_mode_config.mstartoption,
// //       mendoption: event.target.name,
// //     }
// //     this.setState({
// //       mendoption: event.target.name,
// //     })
// //     this.updatePublishTemplateData()
// //   }

// //   deleteEndpoint = () => {
// //     try {
// //       urlSocket.post("webphlbconf/deleteendpoints", {
// //         userInfo: {
// //           encrypted_db_url: this.state.db_info.encrypted_db_url,
// //           created_by: this.state.userInfo._id,
// //           company_id: this.state.userInfo.company_id
// //         },
// //         hInfo: {
// //           h_id: this.state.hirerachyData._id,
// //           publishtemplateInfo: this.state.publishTemplate
// //         },
// //         endpointInfo: this.state.deleteItem
// //       })
// //         .then(response => {
// //           if (response.data.response_code === 500) {

// //             var publishTemplate = response.data.data


// //             this.setState({
// //               publishTemplate: publishTemplate,
// //               endpoints: response.data.data.endpoints,
// //               changeMethodConfirmation: false,
// //             }, function () {
// //               this.updateCCLevel()
// //             })
// //           }
// //         })
// //     } catch (error) {
// //       //("catch error", error)
// //     }
// //   }

// //   treeDataToFlat = async () => {
// //     // //("this.state.treeData", this.state.treeData)
// //     var getNodeKey = ({ treeIndex }) => treeIndex
// //     var flatData = getFlatDataFromTree(
// //       {
// //         treeData: this.state.publishTemplate.template_structure,
// //         getNodeKey,
// //         ignoreCollapsed: false,
// //       })

// //     var explicitData = _.filter(flatData, item => {
// //       return item
// //     })

// //     return explicitData
// //   }

// //   checkPublishDate = () => {

// //     var currentDate = moment(new Date()).format("YYYY-MM-DD")
// //     var pbd_startDate = moment(new Date(this.state.publishTemplate.start_date)).format("YYYY-MM-DD")
// //     var pbd_endDate = moment(new Date(this.state.publishTemplate.end_date)).format("YYYY-MM-DD")

// //     if (moment(pbd_startDate).isBefore(currentDate)) {
// //       this.setState({ ederror: false, sderror: true })
// //       //("check start date")
// //     }

// //     if (moment(pbd_endDate).isBefore(currentDate)) {
// //       this.setState({ sderror: false, ederror: true })
// //       //("check end date")
// //     }

// //     if (moment(pbd_startDate).isBefore(currentDate) == false && moment(pbd_endDate).isBefore(currentDate) == false) {
// //       this.setState({ sderror: false, ederror: false })
// //       return true
// //     }
// //     else {
// //       return false
// //     }
// //   }

// //   calculateMaxDate() {
// //     const { ptl_startdate } = this.state;
// //     console.log('ptl_startdate', ptl_startdate)

// //     try {
// //       if (ptl_startdate === '' || ptl_startdate === undefined) {
// //         return null; // Return null if start date is not set
// //       }
    
// //       const startDate = new Date(ptl_startdate);
// //       startDate.setFullYear(startDate.getFullYear() + this.state.client_info.rept_mode_periodicity);  
// //       return startDate.toISOString().slice(0, 10); // Format the date as "YYYY-MM-DD"
// //     } catch (error) {
// //       // console.log('error', error)
// //     }  

// //   }
  



// //   publishTmpltAsAudit = async () => {

// //     var checkpoints = await this.treeDataToFlat()
// //     var checkDates = this.checkPublishDate()

// //     //("checkDates", checkDates)
// //     //(this.state.publishTemplate,'this.state.publishTemplate')
// //     if (checkDates) {

// //       try {
// //         urlSocket.post("webphlbprcs/publishtmpltasaudit", {
// //           userInfo: {
// //             encrypted_db_url:this.state.db_info.encrypted_db_url,
// //             created_by: this.state.userInfo._id,
// //             company_id: this.state.userInfo.company_id
// //           },
// //           hInfo: {
// //             publishtemplateInfo: this.state.publishTemplate,
// //             // checkpoints,
// //           },
// //         })
// //           .then(response => {
// //             //("response", response)
// //             if (response.data.response_code === 500) {
// //               this.setState({
// //                 tmpltPublishProcess: true,
// //                 alertMessage: "Audit publishing started successfully click",
// //                 processStatus: "1"
// //               })
// //             }
// //             else {
// //               this.setState({
// //                 tmpltPublishProcess: true,
// //                 alertMessage: "Audit not publish. Please check the data",
// //                 processStatus: "0"
// //               })
// //             }
// //           })
// //       } catch (error) {
// //         //("catch error", error)
// //       }
// //     }
// //     else {
// //       //("check start or end date")
// //     }

// //   }

// //   redirectTo(processStatus) {

// //     this.setState({ tmpltPublishProcess: false })

// //     if (processStatus == "1") {
// //       sessionStorage.removeItem("EditPublishedData");
// //       sessionStorage.removeItem("nagiationMode");
// //       this.props.history.push("/mngpblhtempt");
// //     }
// //   }


// //   deleteAllRequest(){

// //     if(this.state.publishTemplate.endpoints.length === 0)
// //     {
// //       this.setState({dataAlert:true, alertMessage:"No end points to delete"})
// //     }
// //     else{
// //       this.setState({triggerFrom:"deleteall", changeMethodConfirmation: true})
// //     }


// //   }


// //   select_country=(e)=>{
// //     //(e.target.value)
// //     this.setState({countrycode:e.target.value})
// // }


// //   addPermissions(e, item){

// //     var getIndex = _.findIndex(this.state.permissionsadded, {id:item.id})

// //     if(getIndex === -1)
// //     {
// //         this.state.permissionsadded.push(item)
// //         this.setState({
// //             permissionsadded:this.state.permissionsadded
// //         })
// //     }
// //     else
// //     {
// //         this.state.permissionsadded.splice(getIndex, 1)
// //         this.setState({
// //             permissionsadded:this.state.permissionsadded
// //         })
// //     }

// //     //("permissionsadded", this.state.permissionsadded)

// // }



// //   validate_email = (e, mode) => {
// //     //(e.target.value, "events",this.state.usercode)
// //     var admn_info = {}
// //     if (mode == 1) {
// //         admn_info["email_id"] = e.target.value
// //     }
// //     if (mode === 2) {
// //         admn_info["phone_number"] = e.target.value
// //     }
// //     if (mode === 3) {
// //         admn_info["usercode"] = e.target.value
// //     }
// //     admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
// //     admn_info["db_name"] = this.state.db_info.db_name
// //     admn_info["user_id"] = this.state.userInfo._id

// //     if(this.state.usercode !==undefined || admn_info["email_id"] !== undefined || admn_info["phone_number"] !== undefined ){
// //     try {
// //         urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
// //             //(data, 'data')
// //             if (mode == 1) {
// //                 if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
// //                     this.setState({ invalid_user: true })
// //                 }
// //                 else {
// //                     this.setState({ invalid_user: false })
// //                 }
// //             }
// //             if (mode == 2) {

// //                 if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
// //                     this.setState({ invalid_phone_num: true })
// //                 }
// //                 else {
// //                     this.setState({ invalid_phone_num: false })
// //                 }
// //             }
// //             if (mode === 3) {
// //                 if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
// //                     this.setState({ invalid_usercode: true })
// //                 }
// //                 else {
// //                     this.setState({ invalid_usercode: false })
// //                 }


// //             }
// //         })

// //     } catch (error) {

// //     }
// // }

// // }


// //   emai_addrs = (e) => {
// //     var email = e.target.value
// //     if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
// //         this.setState({ given_email: email , errorMessage : false})
// //     }
// //     else{
// //         //('enter a valid email id')
// //         this.setState({ errorMessage: true ,given_email: email })
// //     }

// //     // this.setState({ given_email: e.target.value })
// // }

// // userCode=(e)=>{
// //     this.setState({usercode : e.target.value})
// // }


// // loadEOPTS = () => {
// //   //( 'userdetais')
// //   this.setState({ tableDataloaded: false })
// //   try {
// //       urlSocket.post("cog/get-user-info",   
// //       {  db_info :  this.state.db_info,
// //         user_id :this.state.userInfo._id
// //     }
// //       )
// //           .then((response) => {
// //               //(response, 'response')
// //               this.setState({
// //                 userdata : response.data.user_list,
// //                 auditUserlevelSelected :false
// //                 // dataloaded : false,
// //                   // statusBasedFilteredData: response.data.user_list,
// //                   // tableDataloaded: true,
// //                   // allowTableRowToSelect: true,
// //                   // labelDefault: true,
// //                   // label: {
// //                   //     endpoints: []
// //                   // },


// //               }, function () {
// //                   this.loadUserLabels()
// //                   setTimeout(() => {
// //                    this.setState({auditUserlevelSelected : true})
                    
// //                   }, 50);
// //               })

// //           })
// //   } catch (error) {

// //   }
// // }





// //   handleValidSubmit = (event, values) => {
// //     //(event, values)
// //     this.state.client_info.facilities.map((ele, idx) => {
// //       this.state.permissionsadded.map((ele1, idx1) => {
// //         if (ele1.id === ele.id) {
// //           this.state.client_info.facilities[idx].enabled = true
// //         }
// //       })
// //     })


// //     var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
// //     values["password"] = password
// //     values["confirmpassword"] = password
// //     values["userPoolId"] = this.state.client_info.userPoolId
// //     values["clientId"] = this.state.client_info.clientId
// //     values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
// //     values["db_name"] = this.state.db_info.db_name
// //     values["facilities"] = this.state.client_info.facilities
// //     values["company_id"] = this.state.sessionUserInfo.m_client_id
// //     values["created_by"] = this.state.sessionUserInfo._id
// //     values["short_name"] = this.state.client_info.short_name
// //     values["user_id"] = this.state.sessionUserInfo._id


// //     //(values, 'values', this.state.permissionsadded)


// //     if (this.state.countrycode === undefined) {
// //       this.setState({ country_code_err: true })
// //     }
// //     else {
// //       values["countrycode"] = this.state.countrycode
// //       this.setState({ country_code_err: false })

// //     }
// //     //(this.state.client_info.facilities, 'this.state.client_info.facilities')
// //     if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false) {
// //       this.setState({ loading: true })

// //       try {
// //         urlSocket.post('cog/cruduser', values).then((res) => {
// //           //(res, 'res')
// //           if (res.data.response_code === 500) {
// //             var created_user = res.data.admn_user[0]
// //             //(created_user, 'created_user')
// //             this.setState({ loading: false })
// //             var event = {}
// //             var target = {}
// //             target["value"] = created_user._id
// //             event["target"] = target
// //             //(event, 'events')
// //             // this.props.didmount()
// //             this.state.userdata.push(created_user)
// //             // this.state.endpoints.push(created_user)

// //             //('this.state.publishTemplate.endpoints[this.state.selected_index]', this.state.publishTemplate.endpoints[this.state.selected_index])
// //             if(this.state.user_type === 0 ){
// //               //("auditer")
// //             if (this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0) {
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.push({
// //                 user_id: created_user._id,
// //                 name: created_user.firstname,
// //                 designation: created_user.designation,
// //                 user_code: created_user.usercode,
// //                 audit_type: "1"
// //               })
// //             } else {
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_id'] = created_user._id
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['name'] = created_user.firstname
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['designation'] = created_user.designation
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['user_code'] = created_user.user_code
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[0]['audit_type'] = '1'
// //             }
// //             }
// //             else{
// //               if(this.state.user_type === 1){
// //                 //("reviewer")
// //               if(this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.length === 1 || this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.length === 0 ){
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users.push({
// //                 user_id: created_user._id,
// //                 name: created_user.firstname,
// //                 designation: created_user.designation,
// //                 user_code: created_user.usercode,
// //                 audit_type: "2",
// //               })
// //             }
// //             else{
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_id'] = created_user._id
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['name'] = created_user.firstname
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['designation'] = created_user.designation
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['user_code'] = created_user.user_code
// //               this.state.publishTemplate.endpoints[this.state.selected_index].adt_users[1]['audit_type'] = '2'

// //             }
// //           }

// //             }

// //             // .push({
// //             //   user_id: created_user._id,
// //             //   name: created_user.firstname,
// //             //   designation: created_user.designation,
// //             //   user_code: created_user.usercode,
// //             //   audit_type: "1"
// //             // })

// //             this.setState({ userdata: this.state.userdata, publishTemplate: this.state.publishTemplate }, () => {

// //               this.state.user_type === 0 ?
// //                 this.changeAuditUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index,"0")
// //                 : this.changeReviewUserHandler(event, this.state.publishTemplate.endpoints[this.state.selected_index], this.state.selected_index,"0")
// //               this.onDrawerClose()
// //             })
// //             //('this.state.endpoints', this.state.endpoints)
// //             //(this.state.userdata,)
// //           }
// //           else {
// //             this.setState({ loading: false })

// //           }
// //         })

// //       } catch (error) {
// //         //(error, 'error')
// //       }

// //     }

// //   }

// //   handle_click=(e)=>{
// //     //(e.target.name, e.target.checked,this.state.publishTemplate)
// //     this.state.publishTemplate[e.target.name] =e.target.checked
// //     this.setState({publishTemplate : this.state.publishTemplate})
// //   }


// //   labelSelected(data) {

// //     if (data.target.value === "all") {
// //         this.loadEOPTS()
// //     }
// //     else {
// //         //("label data", this.state.labelData[data.target.value])
// //       //(data,this.state.labelData,data.target.value)
// //         var mylabel = this.state.labelData[data.target.value]
// //         //(mylabel,'mylabel')
// //         // this.setState({
// //         //     tableDataloaded: false, selectedLabel: mylabel._id,
// //         //     label: mylabel
// //         // })

// //         try {

// //             urlSocket.post("userSettings/load-group-users", {
// //                 labelInfo: mylabel,
// //                 userInfo: {
// //                     encrypted_db_url: this.state.db_info.encrypted_db_url,
// //                     _id: this.state.userInfo._id,
// //                     company_id: this.state.userInfo.company_id
// //                 }
// //             })
// //                 .then(response => {
// //                     //("response", response.data.data)
// //                     this.setState({ statusBasedFilteredData: response.data.data, userdata: response.data.data, auditUserlevelSelected : false
// //                     },()=>
// //                     {
// //                       setTimeout(() => {
// //                       this.setState({auditUserlevelSelected:true});
                        
// //                       }, 50);
                      
// //                   })
// //                 })
// //                 // this.setState({dataloaded:true}) 
// //         } catch (error) {
// //             //("catch error", error)
// //         }

// //     }
// //     this.setState({user_group_selected : data.target.value})

// // }
















// //   deleteAllEndpoints(){



// //     try {
// //       var loggedUserInfo = {
// //           company_id: this.state.userInfo.company_id,
// //           company_name: this.state.userInfo.company_name,
// //           created_by:this.state.userInfo._id,
// //           encrypted_db_url : this.state.db_info.encrypted_db_url
// //       }
// //       urlSocket.post("webphlbconf/deleteallendpoints", {
// //           userInfo: loggedUserInfo,
// //           publishtemplateInfo: this.state.publishTemplate
// //       })
// //       .then(response=>{
// //           if(response.data.response_code === 500)
// //           {
// //                   this.setState({changeMethodConfirmation: false,
// //                     }, function(){
// //                     this.onDrawerClose()
// //                   })
                  
// //           }
// //       })
// //   } catch (error) {
// //       //("catch error", error)
// //   }

// //   }



// //   repeatModeComponent(mode) {
// //     return (
// //       <Form action="#">

// //         {
// //           mode == "0" ?

// //             <div>
// //               {/* <div className="mb-3 d-flex flex-column  ">
// //                 <div className="d-flex flex-row align-items-center">
// //                   <div className="col-3">
// //                     <Label className="text-primary" htmlFor="autoSizingSelect">Start date</Label>
// //                     <div className="col-md-10">
// //                       <input
// //                         className="form-control"
// //                         type="date"
// //                         min={this.state.fromate_date}
// //                         selected={this.state.ptl_startdate}
// //                         defaultValue={this.state.ptl_startdate}
// //                         id="start_date"
// //                         onChange={event => this.onChangeStartDate(event)}
// //                       />
// //                     </div>
// //                   </div>
// //                   <div className="col-3">
// //                     <Label className="text-primary" htmlFor="autoSizingSelect">End date</Label>
// //                     <div className="col-md-10">
// //                       <input
// //                         className="form-control"
// //                         type="date"
// //                         min={this.state.fromate_date}
// //                         selected={this.state.ptl_enddate}
// //                         defaultValue={this.state.ptl_enddate}
// //                         id="end_date"
// //                         onChange={event => this.onChangeEndDate(event)}
// //                       />
// //                     </div>
// //                   </div>

// //                 </div>
// //               </div> */}
// //             </div> : mode == "1" ?

// //               <div>
// //                 <Row >
// //                   <FormGroup className="mb-3 col-3">
// //                     <Label className="text-primary" htmlFor="autoSizingSelect">Start time</Label>
// //                     <InputGroup>
// //                       <Flatpickr
// //                         className="form-control d-block"
// //                         placeholder="Select time"
// //                         options={{
// //                           enableTime: true,
// //                           noCalendar: true,
// //                           dateFormat: "H:i K"
// //                         }}
// //                         value={this.state.daily_starttime}
// //                         onChange={e => this.onChangeStartTime(e)}
// //                       />
// //                       <div className="input-group-append">
// //                         <span className="input-group-text">
// //                           <i className="mdi mdi-clock-outline" />
// //                         </span>
// //                       </div>
// //                     </InputGroup>
// //                   </FormGroup>

// //                   <FormGroup className="mb-3 col-3">
// //                     <Label className="text-primary" htmlFor="autoSizingSelect">End Time</Label>
// //                     <InputGroup>
// //                       <Flatpickr
// //                         className="form-control d-block"
// //                         placeholder="Select time"
// //                         options={{
// //                           minTime: this.state.getMinTime,
// //                           enableTime: true,
// //                           noCalendar: true,
// //                           dateFormat: "H:i K"
// //                         }}
// //                         value={this.state.daily_endtime}
// //                         onChange={e => this.onChangeEndTime(e)}
// //                       />
// //                       <div className="input-group-append">
// //                         <span className="input-group-text">
// //                           <i className="mdi mdi-clock-outline" />
// //                         </span>
// //                       </div>
// //                     </InputGroup>
// //                   </FormGroup>
// //                 </Row>


// //               </div> : mode == "2" ?
// //                 <div style={{ display: 'flex', flexDirection: 'Column' }}>

// //                   <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
// //                     {
// //                       this.state.weekDays.map((item, idx) => {
// //                         return (
// //                           <div className="form-check mx-2 ">
// //                             <input
// //                               type="checkbox"
// //                               className="form-check-input"
// //                               id={item.day}
// //                               checked={item.checked}
// //                               onChange={e => { this.handleWeekDays(e) }}
// //                             />
// //                             <label
// //                               className="form-check-label"
// //                               htmlFor={item.day}
// //                             >
// //                               {item.day}
// //                             </label>
// //                           </div>
// //                         )
// //                       })
// //                     }
// //                   </div>



// //                 </div> : mode == "3" ?
// //                   <div>
// //                     <Row>

// //                       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 30 }}>
// //                         <span style={{ fontSize: 13, color: '#74788d' }}>Every month started on &nbsp;&nbsp;</span>
// //                         <div className="me-2">
// //                           <Label className="card-radio-label">
// //                             <Input
// //                               type="radio"
// //                               name="month_start"
// //                               id="ms-1"
// //                               className="card-radio-input"
// //                               value={this.state.mstartoption}
// //                               checked={this.state.mstartoption === "month_start"}
// //                               onChange={this.handleMStartGroupChange}
// //                             />

// //                             <div className="card-radio py-4">
// //                               <span style={{
// //                                 color: this.state.mstartoption !== "month_start" || this.state.mstartoption === undefined ? "#c0c1c5" : "#74788d"
// //                               }}>Month 1st</span>
// //                             </div>
// //                           </Label>
// //                         </div>
// //                         <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>
// //                         <div className="me-2">
// //                           <Label className="card-radio-label">
// //                             <Input
// //                               type="radio"
// //                               name="custom_start"
// //                               id="ms-2"
// //                               className="card-radio-input"
// //                               value={this.state.mstartoption}
// //                               checked={this.state.mstartoption === "custom_start"}
// //                               onChange={this.handleMStartGroupChange}

// //                             />

// //                             <div className="card-radio d-flex flex-row align-items-center">
// //                               <select
// //                                 type="select"
// //                                 name="m_starton"
// //                                 label="Name"
// //                                 value={this.state.mstartoption === "month_start" || this.state.mstartoption === null ? 'choose' : this.state.dsf}
// //                                 className="form-select"
// //                                 style={{ width: 60 }}
// //                                 id="m_starton"
// //                                 disabled={this.state.mstartoption === "month_start" || this.state.mstartoption === null}
// //                                 onChange={(e) => this.monthStartOn(e)}>
// //                                 <option value="choose" disabled >Choose...</option>
// //                                 {
// //                                   this.state.dayStartNumber.map((data, idx) => {
// //                                     return (
// //                                       <option value={data.value} selected key={idx}>{data.name}</option>
// //                                     )
// //                                   })
// //                                 }
// //                               </select>
// //                               {this.state.mstartoption === "month_start" || this.state.mstartoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.dsf)}</span>}
// //                             </div>
// //                           </Label>
// //                         </div>
// //                         <span>&nbsp;and ends on &nbsp;&nbsp;</span>
// //                         <div className="me-2">
// //                           <Label className="card-radio-label">
// //                             <Input
// //                               type="radio"
// //                               name="month_end"
// //                               id="pay-methodoption1"
// //                               className="card-radio-input"
// //                               value={this.state.mendoption}
// //                               checked={this.state.mendoption === "month_end"}
// //                               onChange={this.handleMEndGroupChange}
// //                             />

// //                             <div className="card-radio py-4">
// //                               <span style={{
// //                                 color: this.state.mendoption !== "month_end" || this.state.mendoption === null ? "#c0c1c5" : "#74788d"
// //                               }}>Month end</span>
// //                             </div>
// //                           </Label>
// //                         </div>
// //                         <span>&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>
// //                         <div className="me-2">
// //                           <Label className="card-radio-label">
// //                             <Input
// //                               type="radio"
// //                               name="custom_end"
// //                               id="pay-methodoption3"
// //                               className="card-radio-input"
// //                               value={this.state.mendoption}
// //                               checked={this.state.mendoption === "custom_end"}
// //                               onChange={this.handleMEndGroupChange}

// //                             />

// //                             <div className="card-radio d-flex flex-row align-items-center">
// //                               <select
// //                                 type="select"
// //                                 name="m_endson"
// //                                 label="Name"
// //                                 value={this.state.mendoption === "month_end" || this.state.mendoption === null ? 'choose' : this.state.eoem}
// //                                 className="form-select"
// //                                 style={{ width: 60 }}
// //                                 id="m_endson"
// //                                 disabled={this.state.mendoption === "month_end" || this.state.mendoption === null}
// //                                 onChange={(e) => this.monthEndsOn(e)}>
// //                                 <option value="choose" disabled >Choose...</option>
// //                                 {
// //                                   this.state.dayEndNumber.map((data, idx) => {
// //                                     return (
// //                                       <option value={data.value} selected key={idx}>{data.name}</option>
// //                                     )
// //                                   })
// //                                 }
// //                               </select>
// //                               {this.state.mendoption === "month_end" || this.state.mendoption === null ? null : <span style={{ fontSize: 13, color: '#74788d' }}>&nbsp;{this.ordinal_suffix_of(this.state.eoem)}</span>}
// //                             </div>
// //                           </Label>
// //                         </div>
// //                       </div>
// //                     </Row>
                    
// //                   </div> : mode == "4" ?
// //                     <div></div> : mode == "5" ?
// //                       <div></div> :
// //                       null

// //         }

// //       </Form>
// //     )
// //   }


// //   buildTreeNodes = (nodes) => {
// //     const { disabledKeys, checkedKeys } = this.state;
// //     return nodes.map((node) => {
// //     const { value, label, children, ep_level, ep_level_value, id, node_id, parent } = node;
// //     const hasChildren = children && children.length > 0;
// //     const disabled = checkedKeys.includes(value);
// //     return {
// //     key: value,
// //     // title: label,
// //     value: value,
// //     // children: children,
// //     ep_level: ep_level,
// //     ep_level_value: ep_level_value,
// //     id: id,
// //     node_id: node_id,
// //     parent: value,
// //     value: parent,
// //     icon: hasChildren ? <FolderOutlined style={{cursor: 'unset', opacity: 0.5}} /> : <FileOutlined style={{cursor: 'pointer', color: '#556EE6', opacity: 1 }}/>,
// //     // icon: hasChildren ? <FolderOutlined style={{cursor: 'unset', opacity: disabled ? 1 : 0.5}} /> : <FileOutlined style={{cursor: 'pointer', color: '#556EE6', opacity: disabled ? 1 : 0.5}}/>,
// //     children: hasChildren ? this.buildTreeNodes(children) : [],
// //     title: (
// //     // <span style={{ opacity: 0.5 }}>{label}</span>
// //     <span style={{ opacity: hasChildren ? 0.5 : 1,cursor:'unset' }}>{label}</span>
// //     ),
// //     };
// //     });
// //     };
    
// //     // Callback for when checkbox status changes
// //     onCheck = (checked, event) => {
// //     console.log('checked, event', checked, event)
// //     this.handleEndpointSelection(checked, event) 
// //     // console.log('checkedKeys', checkedKeys)
// //     // this.setState({ checkedKeys });
    
// //     };

// //   render() {
// //     //(this.state.publishTemplate.endpoints,'this.state.publishTemplate')
// //     var treeNodes = this.buildTreeNodes(this.state.locationData);
// //     if (this.state.dataloaded) {
// //       const { locationData } = this.state
// //       const { SearchBar } = Search;
// //       // const { users } = this.state

// //       const { isEdit } = this.state
// //       const options = {
// //         // pageStartIndex: 0,
// //         page: this.state.paginationPage,

// //         sizePerPage: 10,
// //         totalSize: this.state.publishTemplate.endpoints.length, // replace later with size(users),
// //         custom: true,
// //         onPageChange: (e) => {
// //           //("on page change", e)
// //           this.setState({ paginationPage: e })
// //         }
// //       };


// //       const columns = [
       

// //         {
// //           dataField: 'hlevel_name',
// //           text: 'Location',
// //           sort: true,
// //           formatter: (cellContent, item) => {
// //             return (
// //               <>
// //                 <div style={{ display: 'flex', flexDirection: 'column', color: item.rowValid ? null : "#f46a6a" }}>
// //                   {item.hlevel_name}
// //                 </div>
// //               </>
// //             )
// //           }
// //         },
// //         {
// //           dataField: 'code',
// //           text: 'Code',
// //           formatter: (cellContent, item) => {
// //             return (
// //               <div style={{ color: item.rowValid ? null : "#f46a6a" }}>
// //                 {item.code}
// //               </div>
// //             )
// //           }
// //         },
// //         {
// //           dataField: 'hlevel',
// //           text: 'Category',
// //           formatter: (cellContent, item) => {
// //             return (
// //               <div style={{ color: item.rowValid ? null : "#f46a6a" }}>
// //                 {item.hlevel}
// //               </div>
// //             )
// //           }
// //         },

// //         {
// //           dataField: '',
// //           text: 'Audit User',
// //           formatter: (cellContent, item, index) => {
// //             // const matchingUsers = _.filter(item.user_path, { audit_type: "1" });
// //             //(matchingUsers,'matchingUsers')
// //             var mappedUser
// //             // if(matchingUsers.length < 2){
// //               // var getIndex = _.findIndex(item.adt_users, (user) => user.audit_type === "1" || user.audit_type === "2" || user.audit_type === "3");
// //               // console.log(this.state.publishTemplate,'publishtemp')
// //             if (this.state.publishTemplate.method_selected == "2") {

// //               var getIndex = _.findIndex(item.adt_users, (user) => {
// //                 return user.review_user === undefined && (user.cat_type === "1" || user.cat_type === "2" || user.cat_type === "3");
// //               });

// //             }
// //             else {
// //               var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
// //             }
// //             // var getIndex = _.findIndex(item.adt_users, { audit_type: "1" || audit_type :"2" || audit_type :"3" })
// //             //(getIndex,'getIndex',item)
// //             if (getIndex !== -1) {
// //               mappedUser = item.adt_users[getIndex]
// //               item["rowValid"]=true
// //             }
// //             else {
// //               mappedUser = undefined
// //             }
// //           // }
// //           // else{
// //           //   mappedUser = undefined
// //           // }
// //             //(mappedUser,'mapped')
// //             // console.log(cellContent, item, index)
// //             return (
// //               <>
// //                 <div>
// //                   <select
// //                     type="select"
// //                     name="adtuser"
// //                     label="Name"
// //                     value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
// //                     className="form-select"
// //                     id={"adtuser" + String(item._id)}
// //                     onChange={(e) => this.changeAuditUserHandler(e, item, index,"1")}>
// //                     <option value="choose" disabled >Choose...</option>
// //                     <option value="add" >Add New User</option>
// //                     {
// //                       this.state.userdata.map((data, idx) => {
// //                         return (
// //                           <option value={data._id} key={idx}>{data.firstname}</option>
// //                         )
// //                       })
// //                     }
// //                   </select>
// //                 </div>
// //               </>
// //             )
// //           }
// //         },

// //         {
// //           dataField: '',
// //           text: 'Review User',
// //           hidden: this.state.methodSelected === "2" ? true : false,
// //           formatter: (cellContent, item, index) => {
// //             console.log(item,'26660')
// //             var mappedUser
// //             var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
// //             if (getIndex !== -1) {
// //               mappedUser = item.adt_users[getIndex]
// //               this.updateCCLevel()
// //             }
// //             else {
// //               mappedUser = undefined
// //             }

// //             return (
// //               <>
// //                 {
// //                   !this.state.customEnableReview ? <div className="text-secondary">Review not enable</div> : null
// //                 }
// //                 {
// //                   this.state.customEnableReview ?
// //                     <div>
// //                       <select
// //                         type="select"
// //                         name="rvwuser"
// //                         label="Name"
// //                         value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
// //                         className="form-select"
// //                         id={"custrvwuser" + String(item._id)}
// //                         onChange={(e) => this.changeReviewUserHandler(e, item, index,"1")}>
// //                         <option value="choose" disabled >Choose...</option>
// //                         <option value="add" >Add New User</option>
// //                         {
// //                           this.state.userdata.map((data, idx) => {
// //                             return (
// //                               <option value={data._id} selected key={idx}>{data.firstname}</option>
// //                             )
// //                           })
// //                         }
// //                       </select>
// //                     </div> : null
// //                 }

// //               </>
// //             )
// //           }
// //         },

// //         {
// //           dataField: '',
// //           text: 'Review User',
// //           hidden: this.state.methodSelected === "2" ? false : true,
// //           formatter: (cellContent, item, index) => {
// //             console.log(item,'item')
// //             // const matchingUsers = _.filter(item.user_path, { audit_type: "2" });
// //             // console.log(matchingUsers,'matchingUsers')
// //             // if(matchingUsers.length <2){
// //             var mappedUser
// //             // var getIndex = _.findIndex(item.adt_users, (user) => user.audit_type === "1" || user.audit_type === "2" || user.audit_type === "3");
// //             if (this.state.publishTemplate.method_selected == "2") {
// //               var getIndex = _.findIndex(item.adt_users, (user) => {
// //                 return user.review_user === true && (user.cat_type === "1" || user.cat_type === "2" || user.cat_type === "3");
// //               });
// //             }
// //             else {
// //               var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
// //             } 
// //             // var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
// //             if (getIndex !== -1) {
// //               mappedUser = item.adt_users[getIndex]
// //               // this.updateCCLevel()
// //             }
// //             else {
// //               mappedUser = undefined
// //             }
// //           // }
// //           // else{
// //           //   mappedUser = undefined
// //           // }

// //             return (
// //               <>
// //                 {
// //                   !this.state.enableReview ? <div className="text-secondary">Review not enable</div> : null
// //                 }
// //                 {/* {
// //                   !this.state.reviewUserlevelSelected && this.state.enableReview ? <div className="text-secondary">Select Review user level</div> : null
// //                 } */}
// //                 {
// //                   this.state.enableReview ?
// //                     <div>
// //                       <select
// //                         type="select"
// //                         name="rvwuser"
// //                         label="Name"
// //                         value={mappedUser != undefined ? mappedUser.user_id : 'choose'}
// //                         className="form-select"
// //                         id="rvwuser"
// //                         disabled={!this.state.enableReview}
// //                         onChange={(e) => this.changeReviewUserHandler(e, item, index,"1")}>
// //                         <option value="choose" disabled >Choose...</option>
// //                         <option value="add" >Add New User</option>
// //                         {
// //                           this.state.userdata.map((data, idx) => {
// //                             return (
// //                               <option value={data._id} selected key={idx}>{data.firstname}</option>
// //                             )
// //                           })
// //                         }
// //                       </select>
// //                     </div> : null
// //                 }

// //               </>
// //             )
// //           }
// //         },

// //         {
// //           dataField: "menu",
// //           isDummyField: true,
// //           editable: false,
// //           text: "Action",
// //           hidden: this.state.methodSelected === "2" ? true : false,
// //           formatter: (cellContent, item) => (
// //             <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
// //               {/* <Link className="text-primary" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => { this.setState({ open: true, component: "endpoint", editItem: item }) }}></i></Link> */}
// //               <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ changeMethodConfirmation: true, deleteItem: item, triggerFrom: "endpoint" })}></i></Link>
// //             </div>
// //           ),
// //         },



// //       ];
// //       // //(columns,'columns')
// //       const defaultSorted = [{
// //         dataField: 'hname', // if dataField is not match to any column you defined, it will be ignored.
// //         order: 'asc' // desc or asc
// //       }];
// //       {
// //         //(this.state.userInfo)
// //       }
// //       // //("render",this.state.publishTemplate)
// //       console.log(this.state.publishTemplate,'publish',this.state.cc_level  )
// //       return (
// //         <React.Fragment>
// //           <div className="page-content">
// //             <MetaTags>
// //               <title>AuditVista | Publishing</title>
// //             </MetaTags>
// //             <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-2">
// //               <div style={{ width: '80%', padding: '0 20px' }}>Publishing</div>
// //               <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
// //                 <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
// //               </div>
// //             </div>
// //             <Container fluid>
// //               {
// //                 this.state.methodSelected === "0" ? <div>
// //                   <Card>
// //                     <CardBody>
// //                       <Label className="my-3">Add Endpoint by</Label>

// //                       <Row>
// //                         <Col xl="6" sm="6">
// //                           <div className="me-2 text-wrap">
// //                             <p className="card-title-desc">
// //                               Use the <code>.badge-pill</code> modifier className to
// //                               make badges more rounded (with a larger{" "}
// //                               <code>border-radius</code>
// //                               and additional horizontal <code>padding</code>).
// //                               Useful if you miss the badges from v3.
// //                             </p>
// //                             <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("2")}>Hirerachy Level</button>
// //                           </div>
// //                         </Col>

// //                         <Col xl="6" sm="6">

// //                           <div className="me-2 text-wrap">
// //                             <p className="card-title-desc">
// //                               Use the <code>.badge-pill</code> modifier className to
// //                               make badges more rounded (with a larger{" "}
// //                               <code>border-radius</code>
// //                               and additional horizontal <code>padding</code>).
// //                               Useful if you miss the badges from v3.
// //                             </p>
// //                             <button className="btn btn-outline-primary" onClick={() => this.endpointMethod("1")}>Create here</button>
// //                           </div>
// //                         </Col>
// //                       </Row>
// //                     </CardBody>
// //                   </Card>
// //                 </div> : null
// //               }
// //               {
// //                 //( this.state.cc_level,this.state.activeCustomTab,this.state.cc_level,'this.state.cc_level')
// //               }

// //               {
// //                 this.state.methodSelected === "1" ?
// //                   <div>

// //                     <div className="checkout-tabs">
// //                       <Row>
// //                         <Col lg="2" sm="3">
// //                           <Nav className="flex-column" pills>
// //                             <NavItem>
// //                               <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f' }}
// //                                 className={classnames({
// //                                   active: this.state.activeCustomTab === 1 && this.state.cc_level == 0,
// //                                   activechange: this.state.activeCustomTab === 1 && this.state.cc_level > 0
// //                                 })

// //                                 }
// //                                 onClick={() => {
// //                                   this.toggleCustomTab(1)
// //                                 }}
// //                               >
// //                                 <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />
// //                                 <p className="font-weight-bold mb-4">Locations</p>
// //                               </NavLink>
// //                             </NavItem>
// //                             <NavItem>
// //                               <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6' }}
// //                                 className={classnames({
// //                                   active: this.state.activeCustomTab === 2 && this.state.cc_level > 1,
// //                                   activechange: this.state.activeCustomTab === 2 && this.state.cc_level > 2
// //                                 })}
// //                                 onClick={() => {
// //                                   this.toggleCustomTab(2)
// //                                 }}
// //                                 disabled={this.state.cc_level > 1 ? false : true}
// //                               >
// //                                 <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />
// //                                 <p className="font-weight-bold mb-4">Configuration</p>
// //                               </NavLink>
// //                             </NavItem>
// //                             <NavItem >
// //                               <NavLink style={{ border: this.state.cc_level < 3 ? "0px" : this.state.cc_level > 3 ? '1px solid #34c38f' : '1px solid #556ee6' }}
// //                                 className={classnames({
// //                                   active: this.state.activeCustomTab === 3 && this.state.cc_level > 2,
// //                                   activechange: this.state.activeCustomTab === 3 && this.state.cc_level >= 4
// //                                 })}
// //                                 onClick={() => {
// //                                   this.toggleCustomTab(3)
// //                                 }}
// //                                 disabled={this.state.cc_level > 2 ? false : true}
// //                               >
// //                                 <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
// //                                 <p className="font-weight-bold mb-4">Confirmation</p>
// //                               </NavLink>
// //                             </NavItem>

// //                           </Nav>
// //                         </Col>

// //                         <Col lg="10" sm="9">
// //                           <AvForm className="form-horizontal" >
// //                             <Card>
// //                               <CardBody>
// //                                 <TabContent activeTab={this.state.activeCustomTab}>

// //                                   <TabPane
// //                                     tabId={1}>
// //                                     <div>
// //                                       {
// //                                         this.state.auditUserlevelSelected ?
// //                                           <PaginationProvider
// //                                             keyField="id"
// //                                             data={this.state.publishTemplate.endpoints}
// //                                             columns={columns}
// //                                             pagination={paginationFactory(options)}
// //                                           >
// //                                             {
// //                                               ({
// //                                                 paginationProps,
// //                                                 paginationTableProps
// //                                               }) => (
// //                                                 <ToolkitProvider
// //                                                   keyField="id"
// //                                                   data={this.state.publishTemplate.endpoints}
// //                                                   columns={columns}
// //                                                   search
// //                                                 >
// //                                                   {
// //                                                     toolkitprops => (
// //                                                       <React.Fragment>
// //                                                         <Row className="mb-2" style={{ paddingTop: 10 }}>
// //                                                           <Col sm="3">
// //                                                             <div className="search-box ms-2 mb-2 d-inline-block">
// //                                                               <div className="position-relative">
// //                                                                 <SearchBar {...toolkitprops.searchProps} />
// //                                                                 <i className="bx bx-search-alt search-icon" />
// //                                                               </div>
// //                                                             </div>
// //                                                           </Col>
// //                                                           <Col sm="9" >
// //                                                             <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
// //                                                               <div style={{  display: 'flex', flexDirection: 'row', marginRight: 20, position: 'relative', }} >
// //                                                                 <div className="select-container me-2">
// //                                                                   <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
// //                                                                     <option value="">Select User Group</option>
// //                                                                     <option value="all">All</option>
// //                                                                     {
// //                                                                       this.state.labelData.map((data, idx) => {
// //                                                                         // //("data", data)
// //                                                                         return (
// //                                                                           <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
// //                                                                         )
// //                                                                       })
// //                                                                     }

// //                                                                   </select>
// //                                                                 </div>

// //                                                                 {/* <Button color={"primary"} outline className="me-2"
// //                                                                   onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadgroupusers", editItem: null })}
// //                                                                 >
// //                                                                  Load Group Users
// //                                                                 </Button> */}
// //                                                                   <div>
// //                                                                     <Button color={"primary"} outline className="btn-md me-2 " style={{padding: '7px'}}
// //                                                                       onClick={() => this.setState({ open: this.state.open ? false : true, component: "loadendpoint", editItem: null })}
// //                                                                     >
// //                                                                       Load locations
// //                                                                     </Button>
// //                                                                     <Button color={"primary"} outline className="btn-sm me-2" style={{padding: '7px'}}
// //                                                                       onClick={() => this.setState({ open: this.state.open ? false : true, component: "endpoint", editItem: null })}
// //                                                                     >
// //                                                                       Add new location
// //                                                                     </Button>
// //                                                                     <Button color={"danger"} outline className="btn-sm" style={{padding: '7px'}}
// //                                                                       onClick={() => { this.deleteAllRequest() }}
// //                                                                     >
// //                                                                       Delete all locations
// //                                                                     </Button>

// //                                                               </div>
// //                                                               </div>
// //                                                             </div>
// //                                                           </Col>
// //                                                         </Row>
// //                                                         <Row>
// //                                                           <div className="form-check form-switch form-switch-sm mx-4">
// //                                                             <input
// //                                                               type="checkbox"
// //                                                               className="form-check-input"
// //                                                               id={"customreview"}
// //                                                               onChange={(e) => { this.onchangeCustomEnableReview(e) }}
// //                                                               checked={this.state.customEnableReview}
// //                                                             />
// //                                                             <label className={"form-check-label text-primary"} htmlFor={"customreview"}>
// //                                                               Enable Review
// //                                                             </label>
// //                                                           </div>
// //                                                           {
// //                                           //(this.state.customEnableReview,'this.state.enableReview')
// //                                         }
// //                                                         </Row>
// //                                                         <hr/>
// //                                                         <Row>
// //                                                           <Col xl="12">
// //                                                             <div className="table-responsive">
// //                                                               <BootstrapTable
// //                                                                 {...toolkitprops.baseProps}
// //                                                                 {...paginationTableProps}
// //                                                                 defaultSorted={defaultSorted}
// //                                                                 classes={
// //                                                                   "table align-middle table-nowrap table-hover"
// //                                                                 }
// //                                                                 bordered={false}
// //                                                                 striped={false}
// //                                                                 responsive
// //                                                                 onTableChange={this.handleTableChange}
// //                                                               />
// //                                                             </div>
// //                                                           </Col>
// //                                                         </Row>
// //                                                         <Row className="align-items-md-center mt-30">
// //                                                           <Col className="pagination pagination-rounded justify-content-end mb-2">
// //                                                             <PaginationListStandalone
// //                                                               {...paginationProps}
// //                                                             />
// //                                                           </Col>
// //                                                         </Row>
// //                                                         <Row>
// //                                                           <Col md={11} className='text-end'>
// //                                                           {/* <Button  color="primary"><i className="bx bx-chevron-left"></i>Previous</Button> */}
// //                                                           </Col>
// //                                                           <Col md={1}>
// //                                                           <Button className={this.state.cc_level ===0 ?"btn btn-secondary" : "btn btn-success"} disabled={this.state.cc_level ===0} onClick={()=>this.toggleCustomTab(this.state.activeCustomTab+1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
// //                                                           </Col>
// //                                                         {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
// //                                                           <Button style={{marginRight:'10%'}} color="primary"><i className="bx bx-chevron-left"></i>Previous</Button>
// //                                                           </div>
// //                                                           <div>
// //                                                           <Button color="success">Next<i className="bx bx-chevron-right"></i></Button>
// //                                                           </div> */}
// //                                                         </Row>
// //                                                         <br/>
// //                                                         <Row>
// //                                                           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
// //                                                             <Button color={"danger"}
// //                                                               onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method
// //                                                             </Button>
// //                                                           </div>
// //                                                         </Row>
// //                                                       </React.Fragment>
// //                                                     )}
// //                                                 </ToolkitProvider>
// //                                               )}
// //                                           </PaginationProvider> : null
// //                                       }

// //                                     </div>
// //                                   </TabPane>

// //                                   <TabPane
// //                                     tabId={2}
// //                                     id="v-pills-confir"
// //                                     role="tabpanel">
// //                                     <div>
// //                                       <div className='mb-3'>
// //                                         <span className='font-size-17'>Configuration</span>
// //                                       </div>

// //                                       <hr className="my-4" />

// //                                       <div>
// //                                         <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>
// //                                         <p className="card-title-desc">
// //                                           Use the <code>.badge-pill</code> modifier className to
// //                                           make badges more rounded (with a larger{" "}
// //                                           <code>border-radius</code>
// //                                           and additional horizontal <code>padding</code>).
// //                                           Useful if you miss the badges from v3.
// //                                         </p>
// //                                         <div className="mb-3 col-6">
// //                                           <select
// //                                             type="select"
// //                                             name="repeat_mode"
// //                                             label="Name"
// //                                             value={this.state.selectedRepeatMode}
// //                                             className="form-select"
// //                                             id="review_mode_level"
// //                                             required
// //                                             onChange={(e) => this.selectRepeatMode(e)}
// //                                           >
// //                                             <option value="choose" disabled>Choose...</option>
// //                                             {
// //                                               this.state.repeatModeData.map((data, idx) => {
// //                                                 return (
// //                                                   <option value={String(idx)} selected key={idx}>{data.mode}</option>
// //                                                 )
// //                                               })
// //                                             }
// //                                           </select>
// //                                         </div>
// //                                       </div>

// //                                       <div>
// //                                         {this.repeatModeComponent(this.state.selectedRepeatMode)}
// //                                       </div>
// //                                       <Row>
// //                                         <Col md={11} className='text-end'>
// //                                          <Button  color="primary" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button> 
// //                                         </Col>
// //                                         <Col md={1}>
// //                                           <Button color="success" disabled={this.state.cc_level == 2} onClick={() => this.toggleCustomTab(this.state.activeCustomTab + 1)}>Next&nbsp; <i className="bx bx-chevron-right"></i></Button>
// //                                         </Col>
// //                                       </Row>
// //                                     </div>
// //                                   </TabPane>

// //                                   <TabPane
// //                                     tabId={3}
// //                                     id="v-pills-confir"
// //                                     role="tabpanel">
// //                                     <div>
// //                                       <div className='mb-2'>
// //                                         <span className='font-size-17'>Confirmation</span> <br />
// //                                         <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>
// //                                       </div>

// //                                       <Row className="justify-content-center">
// //                                         <Col xl={10}>

// //                                           <div className="mt-2">
// //                                             <div className="d-flex flex-wrap">
// //                                               <div className="me-2">
// //                                                 <span className="me-2 font-size-18">Locations</span> <span
// //                                                   onClick={() => {
// //                                                     this.toggleCustomTab(1)
// //                                                   }}
// //                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
// //                                                   edit
// //                                                 </span>

// //                                               </div>
// //                                               <div className="ms-auto">
// //                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
// //                                               </div>
// //                                             </div>
// //                                             <hr className="mt-2" />

// //                                             <div className="list-group list-group-flush">
// //                                               <div
// //                                                 to="#"
// //                                                 className="list-group-item text-muted"
// //                                               >
// //                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                 Total Locations - <span className="text-success">{this.state.publishTemplate.endpoints.length}</span>
// //                                               </div>
// //                                             </div>
// //                                           </div>


// //                                           <div className="mt-5">
// //                                             <div className="d-flex flex-wrap">
// //                                               <div className="me-2">
// //                                                 <span className="me-2 font-size-18">Configuration</span> 
// //                                                 <span
// //                                                   onClick={() => {
// //                                                     this.toggleCustomTab(2)
// //                                                   }}
// //                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
// //                                                   edit
// //                                                 </span>
// //                                               </div>
// //                                               <div className="ms-auto">
// //                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
// //                                               </div>
// //                                             </div>
// //                                             <hr className="mt-2" />

// //                                             <div className="list-group list-group-flush">

// //                                               <div
// //                                                 to="#"
// //                                                 className="list-group-item text-muted"
// //                                               >
// //                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                 Repeat Mode - <span className="text-success">{this.state.publishTemplate.repeat_mode}</span>
// //                                               </div>
// //                                               <div  to="#"
// //                                                 className="list-group-item text-muted d-flex">
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Display &nbsp;&nbsp;
// //                                                   <div style={{display: 'flex', alignItems: 'center'}}>
// //                                                   <div>Audit Score:</div>&nbsp;&nbsp;
// //                                                   <input type={"checkbox"} name='audit_score_preview' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;
// //                                                   </div>
// //                                                 {
// //                                                   this.state.customEnableReview === true &&
// //                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
// //                                                     <div>Review Score:</div>&nbsp;&nbsp;
// //                                                     <input type={"checkbox"} name="review_score_preview" onChange={(e) => this.handle_click(e)} />
// //                                                   </div>
// //                                                 }


// //                                               </div>

// //                                               <div  to="#"
// //                                                 className="list-group-item text-muted d-flex">
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                  Track Location &nbsp;&nbsp;
// //                                                   <div style={{display: 'flex', alignItems: 'center'}}>
// //                                                   <div>Auditor :</div>&nbsp;&nbsp;
// //                                                   <input type={"checkbox"} name='audit_coords_enable' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;
// //                                                   </div>
// //                                                   {
// //                                                     this.state.customEnableReview=== true &&
// //                                                   <div style={{display: 'flex', alignItems: 'center'}}>
// //                                                   <div>Reviewer :</div>&nbsp;&nbsp;
// //                                                   <input type={"checkbox"} name="review_coords_enable" onChange={(e)=>this.handle_click(e)}/>
// //                                                   </div>
// //                                                   }

// //                                               </div>

// //                                               <div
// //                                                 to="#"
// //                                                 className="list-group-item text-muted"
// //                                               >
// //                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                 Publish on <span className="text-danger">*</span>
// //                                                 <input
// //                                                   className="form-control"
// //                                                   type="date"
// //                                                   min={this.state.fromate_date}
// //                                                   selected={this.state.ptl_startdate}
// //                                                   defaultValue={this.state.ptl_startdate}
// //                                                   id="start_date"
// //                                                   onChange={event => this.onChangeStartDate(event)}
// //                                                   onKeyDown={(e)=>{
// //                                                     e.preventDefault();
// //                                                   }}
// //                                                 />
// //                                               </div>

// //                                               <div
// //                                                 to="#"
// //                                                 className="list-group-item text-muted"
// //                                               >
// //                                                 {/* {
// //                                                   //(this.state.ptl_startdate,'this.state.ptl_startdate')
// //                                                 } */}
// //                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                 Audit End on <span className="text-danger">*</span>
// //                                                 <input
// //                                                   className="form-control"
// //                                                   type="date"
// //                                                   min={this.state.ptl_startdate}
// //                                                   value={this.state.ptl_startdate > this.state.ptl_enddate ? "" : this.state.ptl_enddate}
// //                                                   // min={this.state.fromate_date}
// //                                                   selected={this.state.ptl_enddate}
// //                                                   defaultValue={this.state.ptl_enddate}
// //                                                   max={this.calculateMaxDate()}
// //                                                   disabled={this.state.ptl_startdate =='' || this.state.ptl_startdate ==undefined}
// //                                                   id="end_date"
// //                                                   onChange={event => this.onChangeEndDate(event)}
// //                                                   onKeyDown={(e)=>{
// //                                                     e.preventDefault();
// //                                                   }}
// //                                                 />
// //                                               </div>



// //                                             </div>
// //                                           </div>

// //                                           <div className="mt-5">
// //                                             <div className="d-flex flex-wrap">
// //                                               <div className="me-2">
// //                                                 <span className="me-2 font-size-18">Check points</span> <span
// //                                                   onClick={() => this.navigateTo(this.state.publishTemplate)}
// //                                                   className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
// //                                                   edit
// //                                                 </span>
// //                                               </div>
// //                                               <div className="ms-auto">
// //                                                 <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
// //                                               </div>
// //                                             </div>
// //                                             <hr className="mt-2" />

// //                                             <div className="list-group list-group-flush">
// //                                               <div

// //                                                 className="list-group-item text-muted"
// //                                               >
// //                                                 <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                 No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>
// //                                               </div>

// //                                             </div>
// //                                           </div>

// //                                         </Col>
// //                                       </Row>
// //                                       <Row>
// //                                         <div className="wizard clearfix">
// //                                           <div className="actions clearfix">
// //                                             {
// //                                               console.log('this.state.cc_level', this.state.cc_level)
// //                                             }
// //                                           {/* <Col md={11} className='text-end'> */}
// //                                          <Button color="primary" onClick={() => this.toggleCustomTab(this.state.activeCustomTab - 1)}><i className="bx bx-chevron-left"></i>Previous</Button> 
// //                                         {/* </Col> */}
// //                                            <button className='btn btn-primary mx-2' onClick={()=>{window.location.href = "/mngpblhtempt"}}> Save </button>
// //                                             <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>
// //                                           </div>
// //                                           <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>
// //                                         </div>
// //                                       </Row>
                                  
// //                                     </div>
                                 
// //                                   </TabPane>

// //                                 </TabContent>
// //                               </CardBody>
// //                             </Card>
// //                           </AvForm>
// //                         </Col>

// //                       </Row>
// //                     </div>



// //                   </div> : 
// //                   this.state.methodSelected === "2" ?
// //                     <div>
// //                       <div className="checkout-tabs">
// //                         <Row>

// //                           <Col lg="2" sm="3">
// //                             <Nav className="flex-column" pills>
// //                               <NavItem>
// //                                 <NavLink style={{ border: this.state.cc_level == 0 ? '1px solid #556ee6' : '1px solid #34c38f' }}
// //                                   className={classnames({
// //                                     active: this.state.activeTab === 1 && this.state.cc_level == 0,
// //                                     activechange: this.state.activeTab === 1 && this.state.cc_level > 0
// //                                   })

// //                                   }
// //                                   onClick={() => {
// //                                     this.toggleTab(1)
// //                                   }}
// //                                 >
// //                                   <i className="bx bx bx-sitemap d-block check-nav-icon mt-4 mb-2" />
// //                                   <p className="font-weight-bold mb-4">Hirerachy Level</p>
// //                                 </NavLink>
// //                               </NavItem>
// //                               <NavItem>
// //                                 <NavLink style={{ border: this.state.cc_level == 0 ? "0px" : this.state.cc_level > 1 ? '1px solid #34c38f' : '1px solid #556ee6' }}
// //                                   className={classnames({
// //                                     active: this.state.activeTab === 2 && this.state.cc_level > 0,
// //                                     activechange: this.state.activeTab === 2 && this.state.cc_level > 1
// //                                   })}
// //                                   onClick={() => {
// //                                     this.toggleTab(2);
// //                                   }}
// //                                   disabled={this.state.cc_level > 0 ? false : true}
// //                                 >
// //                                   <i className="bx bxs-user d-block check-nav-icon mt-4 mb-2" />
// //                                   <p className="font-weight-bold mb-4">Audit / Review</p>
// //                                 </NavLink>
// //                               </NavItem>
// //                               <NavItem>
// //                                 <NavLink style={{ border: this.state.cc_level < 2 ? "0px" : this.state.cc_level > 2 ? '1px solid #34c38f' : '1px solid #556ee6' }}
// //                                   className={classnames({
// //                                     active: this.state.activeTab === 3 && this.state.cc_level > 1,
// //                                     activechange: this.state.activeTab === 3 && this.state.cc_level > 2
// //                                   })}
// //                                   onClick={() => {
// //                                     this.toggleTab(3)
// //                                   }}
// //                                   disabled={this.state.cc_level > 1 ? false : true}
// //                                 >
// //                                   <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />
// //                                   <p className="font-weight-bold mb-4">Configuration</p>
// //                                 </NavLink>
// //                               </NavItem>
// //                               <NavItem >
// //                                 <NavLink style={{ border: this.state.cc_level < 3 ?"0px": this.state.cc_level === 3 ? '1px solid #556ee6': '1px solid #34c38f' }}
// //                                   className={classnames({
// //                                     active: this.state.activeTab === 4 && this.state.cc_level > 2,
// //                                     activechange: this.state.activeTab === 4 && this.state.cc_level > 3
// //                                   })}
// //                                   onClick={() => {
// //                                     this.toggleTab(4)
// //                                   }}
// //                                   disabled={this.state.cc_level > 2 ? false : true}
// //                                 >
// //                                   <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
// //                                   <p className="font-weight-bold mb-4">Confirmation</p>
// //                                 </NavLink>
// //                               </NavItem>
// //                             </Nav>
// //                           </Col>
// //                           <Col lg="10" sm="9">
// //                             <AvForm className="form-horizontal" onValidSubmit={this.crudNode} onInvalidSubmit={this.handleInvalidSubmit}>
// //                               <Card>
// //                                 <CardBody>
// //                                   <TabContent activeTab={this.state.activeTab}>

// //                                     <TabPane
// //                                       tabId={1}>
// //                                       <div>

// //                                         <Row className='mb-3'>
// //                                           <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
// //                                             <div className='col-8'>
// //                                               <span className='font-size-17'>Hirerachy Level</span>
// //                                             </div>
// //                                             <div className='col-4'>
// //                                               <button className="btn btn-danger" onClick={() => this.setState({ changeMethodConfirmation: true, triggerFrom: "hlevel" })}>Change Add Location method</button>
// //                                             </div>
// //                                           </div>
// //                                         </Row>

// //                                         <div>
// //                                           <div>
// //                                             <Label className="text-primary" htmlFor="autoSizingSelect">Select Level</Label>
// //                                             <p className="card-title-desc">
// //                                               Select appropriate hirerachy level from dropdown or {" "}
// //                                               <badge className='btn badge badge-soft-primary rounded-pill font-size-12 me-1'>Create New Hirerachy level </badge>
// //                                             </p>
// //                                             <div className="mb-3 col-6">

// //                                               <AvInput
// //                                                 type="select"
// //                                                 name="hlevel"
// //                                                 label="HirerachLevel"
// //                                                 value={this.state.selectedHLevelValue}
// //                                                 className="form-select"
// //                                                 id="hlevel1"
// //                                                 required
// //                                                 onChange={(e) => this.selectLevel(e)}>
// //                                                 <option value="0" defaultValue disabled >Choose...</option>
// //                                                 {
// //                                                   this.state.dataSource.map((data, idx) => {
// //                                                     return (
// //                                                       <option value={String(idx + 1)} selected key={idx}>{data.hname}</option>
// //                                                     )
// //                                                   })
// //                                                 }
// //                                               </AvInput>
// //                                             </div>
// //                                           </div>

// //                                           <hr className="my-4" />
// //                                           {
// //                                             this.state.hlevelIsSelected ?
// //                                               <div>
// //                                                 <Label className="text-primary" htmlFor="autoSizingSelect">Select Location</Label>
// //                                                 <p className="card-title-desc">
// //                                                   Use the <code>.badge-pill</code> modifier className to
// //                                                   make badges more rounded (with a larger{" "}
// //                                                   <code>border-radius</code>
// //                                                   and additional horizontal <code>padding</code>).
// //                                                   Useful if you miss the badges from v3.
// //                                                 </p>
// //                                                 <div className="mb-3 col-6">
// //                                                   <select
// //                                                     type="select"
// //                                                     name="location"
// //                                                     label="Name"
// //                                                     value={this.state.selectedEndpoint}
// //                                                     className="form-select"
// //                                                     id="location"
// //                                                     required
// //                                                     onChange={(e) => this.selectLocation(e)}>
// //                                                     <option value="choose" disabled >Choose...</option>
// //                                                     {
// //                                                       this.state.hlevelData.map((data, idx) => {
// //                                                         return (
// //                                                           <option value={String(idx + 1)} selected key={idx}>{data.hlevel}</option>
// //                                                         )
// //                                                       })
// //                                                     }
// //                                                   </select>

// //                                                 </div></div> : null
// //                                           }

// //                                           {this.state.hlevelData.length > 0 ? <hr className="my-4" /> : null}

// //                                           {
// //                                             this.state.endpointSelected ?
// //                                               <div>
// //                                                 <Label className="text-primary" htmlFor="autoSizingSelect">Select {this.state.hlevelSelected}</Label>
// //                                                 <p className="card-title-desc">
// //                                                   Use the <code>.badge-pill</code> modifier className to
// //                                                   make badges more rounded (with a larger{" "}
// //                                                   <code>border-radius</code>
// //                                                   and additional horizontal <code>padding</code>).
// //                                                   Useful if you miss the badges from v3.
// //                                                 </p>
// //                                                 {/* <CheckboxTree
// //                                                   nodes={locationData}
// //                                                   checked={this.state.checked}
// //                                                   expanded={(this.state.expanded)}
// //                                                   onCheck={(checked, event) => { this.handleEndpointSelection(checked, event) }}
// //                                                   // onClick = {(nodes) => {//("node", nodes)}}
// //                                                   onExpand={expanded => this.handleEndpointExpanded(expanded)}
// //                                                   icons={icons}
// //                                                 // showExpandAll={true}
// //                                                 /> */}


// //                                                 {
// //                                                   console.log(treeNodes,'treeNodes')
// //                                                 }

// //                                                 <Tree
// //                                                  style={{ borderColor: '#150101' }} 
// //                                                   defaultExpandAll                                                 
// //                                                   treeData={treeNodes}
// //                                                   checkable
// //                                                   checkedKeys={this.state.checkedKeys}                                                  
// //                                                   onCheck={(checked, event) => { this.onCheck(checked, event) }}
// //                                                   showLine={true}
// //                                                   showIcon={true}
// //                                                 />




// //                                               </div>
// //                                               : null
// //                                           }


// //                                         </div>

// //                                       </div>
// //                                     </TabPane>

// //                                     <TabPane
// //                                       tabId={2}
// //                                       id="v-pills-confir"
// //                                       role="tabpanel">
// //                                       <div>
// //                                         <div className='mb-3'>
// //                                           <span className='font-size-17'>Audit & Review</span>
// //                                         </div>

// //                                         <div>
// //                                           <Label className="text-primary" htmlFor="autoSizingSelect">Select audit user level</Label>
// //                                           <p className="card-title-desc">
// //                                             Use the <code>.badge-pill</code> modifier className to
// //                                             make badges more rounded (with a larger <code>border-radius</code>
// //                                             and additional horizontal <code>padding</code>).
// //                                             Useful if you miss the badges from v3.
// //                                           </p>
// //                                           <div className="row"> {/* Added a row div to create a grid layout */}
// //                                           {
// //                                             console.log(this.state.publishTemplate.audit_userlevel_value,'this.state.selectedAuditUserlevel')
// //                                           }
// //                                             <div className="mb-3 col-6">
// //                                               <select
// //                                                 type="select"
// //                                                 name="audit_userlevel"
// //                                                 label="Name"
// //                                                 value={this.state.publishTemplate.audit_userlevel_value == null ? "choose" : String(this.state.publishTemplate.audit_userlevel_value)}
// //                                                 className="form-select"
// //                                                 id="audit_user_level"
// //                                                 required
// //                                                 onChange={(e) => this.selectAuditUserlevel(e)}
// //                                               >
// //                                                 <option value="choose" disabled>Choose...</option>
// //                                                 {
// //                                                   this.state.hirerachy_user_level.map((data, idx) => {
// //                                                     return (
// //                                                       <option value={data.cat_type} key={idx}>{data.hirerachy_name}</option>
// //                                                     )
// //                                                   })
// //                                                 }

// //                                                 {/* {this.state.hlevelData.map((data, idx) => {
// //                                                   if ((idx + 1) <= parseInt(this.state.selectedEndpoint)) {
// //                                                     return (
// //                                                       <option value={String(idx + 1)} selected key={idx}>{data.hlevel}</option>
// //                                                     )
// //                                                   }
// //                                                 })} */}
// //                                               </select>
// //                                             </div>
// //                                             <div className="mb-3 col-6">
// //                                               <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
// //                                                 <option value="">Select User Group</option>
// //                                                 <option value="all">All</option>
// //                                                 {this.state.labelData.map((data, idx) => {
// //                                                   return (
// //                                                     <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
// //                                                   )
// //                                                 })}
// //                                               </select>
// //                                             </div>
// //                                           </div>
// //                                         </div>



// //                                         {/* <div>
// //                                           <Label className="text-primary" htmlFor="autoSizingSelect">Select audit user level</Label>
// //                                           <p className="card-title-desc">
// //                                             Use the <code>.badge-pill</code> modifier className to
// //                                             make badges more rounded (with a larger{" "}
// //                                             <code>border-radius</code>
// //                                             and additional horizontal <code>padding</code>).
// //                                             Useful if you miss the badges from v3.
// //                                           </p>
// //                                           <div className="mb-3 col-6">
// //                                             <select
// //                                               type="select"
// //                                               name="audit_userlevel"
// //                                               label="Name"
// //                                               value={this.state.selectedAuditUserlevel}
// //                                               className="form-select"
// //                                               id="audit_user_level"
// //                                               required
// //                                               onChange={(e) => this.selectAuditUserlevel(e)}
// //                                             >
// //                                               <option value="choose" disabled >Choose...</option>
// //                                               {
// //                                                 this.state.hlevelData.map((data, idx) => {
// //                                                   if ((idx + 1) <= parseInt(this.state.selectedEndpoint)) {
// //                                                     return (
// //                                                       <option value={String(idx + 1)} selected key={idx}>{data.hlevel}</option>
// //                                                     )
// //                                                   }
// //                                                 })
// //                                               }
// //                                             </select>
// //                                           </div>
// //                                           <div className="mb-3 col-6">
// //                                             <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
// //                                               <option value="">Select User Group</option>
// //                                               <option value="all">All</option>
// //                                               {
// //                                                 this.state.labelData.map((data, idx) => {
// //                                                   // //("data", data)
// //                                                   return (
// //                                                     <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
// //                                                   )
// //                                                 })
// //                                               }

// //                                             </select>
// //                                           </div>
// //                                         </div> */}
// //                                         {/* <div>

// //                                           <select className="form-select form-control" onChange={(e) => this.labelSelected(e)} defaultValue={this.state.user_group_selected}>
// //                                             <option value="">Select User Group</option>
// //                                             <option value="all">All</option>
// //                                             {
// //                                               this.state.labelData.map((data, idx) => {
// //                                                 // //("data", data)
// //                                                 return (
// //                                                   <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
// //                                                 )
// //                                               })
// //                                             }

// //                                           </select>

// //                                         </div> */}
// //                                         <hr className="my-4" />

// //                                         <div className="form-check form-switch form-switch-sm my-3">
// //                                           <input
// //                                             type="checkbox"
// //                                             className="form-check-input"
// //                                             id={"review"}
// //                                             onChange={(e) => { this.onchangeEnableReview(e) }}
// //                                             checked={this.state.enableReview}
// //                                           />
// //                                           <label className={"form-check-label text-primary"} htmlFor={"review"}>
// //                                             Enable Review
// //                                           </label>
// //                                         </div>
                                      

// //                                         {this.state.enableReview ?
// //                                           <div>
// //                                             <Label className="text-primary" htmlFor="autoSizingSelect">Select review user level</Label>
// //                                             <p className="card-title-desc">
// //                                               Use the <code>.badge-pill</code> modifier className to
// //                                               make badges more rounded (with a larger{" "}
// //                                               <code>border-radius</code>
// //                                               and additional horizontal <code>padding</code>).
// //                                               Useful if you miss the badges from v3.
// //                                             </p>
// //                                             <div className="mb-3 col-6">
// //                                               <select
// //                                                 type="select"
// //                                                 name="review_userlevel"
// //                                                 label="Name"
// //                                                 // value={this.state.selectedReviewUserlevel}
// //                                                 value={this.state.publishTemplate.review_userlevel == null ? "choose" : String(this.state.publishTemplate.review_userlevel)}
// //                                                 className="form-select"
// //                                                 id="review_user_level"
// //                                                 required
// //                                                 onChange={(e) => this.selectReviewUserlevel(e)}
// //                                               >
// //                                                 <option value="choose" disabled>Choose...</option>
// //                                                  {
// //                                                   this.state.hirerachy_user_level.map((data,idx)=>{
// //                                                     return(
// //                                                       <option value={data.cat_type} key={idx}>{data.hirerachy_name}</option>
// //                                                     )
// //                                                   })
// //                                                 }
                                              
// //                                                 {/* {
// //                                                   this.state.hlevelData.map((data, idx) => {
// //                                                     if ((idx + 1) < parseInt(this.state.selectedAuditUserlevel)) {
// //                                                       return (
// //                                                         <option value={String(idx + 1)} selected key={idx}>{data.hlevel}</option>
// //                                                       )
// //                                                     }
// //                                                   })
// //                                                 } */}
// //                                               </select>
// //                                             </div>
// //                                             <hr className="my-4" />
// //                                           </div>
// //                                           : null}

// //                                         {
// //                                           this.state.auditUserlevelSelected ?
// //                                             <div>
// //                                               <PaginationProvider
// //                                                 keyField="id"
// //                                                 data={this.state.publishTemplate.endpoints}
// //                                                 columns={columns}
// //                                                 pagination={paginationFactory(options)}
// //                                               >
// //                                                 {
// //                                                   ({
// //                                                     paginationProps,
// //                                                     paginationTableProps
// //                                                   }) => (
// //                                                     <ToolkitProvider
// //                                                       keyField="id"
// //                                                       data={this.state.publishTemplate.endpoints}
// //                                                       columns={columns}
// //                                                       search
// //                                                     >
// //                                                       {
// //                                                         toolkitprops => (
// //                                                           <React.Fragment>
// //                                                             <Row className="mb-2" style={{ paddingTop: 10 }}>
// //                                                               <Col sm="4">
// //                                                                 <div className="search-box ms-2 mb-2 d-inline-block">
// //                                                                   <div className="position-relative">
// //                                                                     <SearchBar {...toolkitprops.searchProps} />
// //                                                                     <i className="bx bx-search-alt search-icon" />
// //                                                                   </div>
// //                                                                 </div>
// //                                                               </Col>
// //                                                               {/* <Col sm="8">
// //                                                               <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
// //                                                                 <div style={{ padding: 5, display: 'flex', flexDirection: 'row', marginRight: 20, position: 'relative', }} >
// //                                                                   <Button color={"primary"} outline
// //                                                                     onClick={this.handleUserClicks}
// //                                                                   >
// //                                                                     Add End point
// //                                                                   </Button>

// //                                                                 </div>
// //                                                               </div>
// //                                                             </Col> */}
// //                                                             </Row>
// //                                                             <Row>
// //                                                               <Col xl="12">
// //                                                                 <div className="table-responsive">
// //                                                                   <BootstrapTable
// //                                                                     {...toolkitprops.baseProps}
// //                                                                     {...paginationTableProps}
// //                                                                     defaultSorted={defaultSorted}
// //                                                                     classes={
// //                                                                       "table align-middle table-nowrap table-hover"
// //                                                                     }
// //                                                                     bordered={false}
// //                                                                     striped={false}
// //                                                                     responsive
// //                                                                     onTableChange={this.handleTableChange}
// //                                                                   />
// //                                                                 </div>
// //                                                               </Col>
// //                                                             </Row>
// //                                                             <Row className="align-items-md-center mt-30">
// //                                                               <Col className="pagination pagination-rounded justify-content-end mb-2">
// //                                                                 <PaginationListStandalone
// //                                                                   {...paginationProps}
// //                                                                 />
// //                                                               </Col>
// //                                                             </Row>
// //                                                           </React.Fragment>
// //                                                         )}
// //                                                     </ToolkitProvider>
// //                                                   )}
// //                                               </PaginationProvider>
// //                                             </div> : null
// //                                         }

// //                                       </div>
// //                                     </TabPane>

// //                                     <TabPane
// //                                       tabId={3}
// //                                       id="v-pills-config"
// //                                       role="tabpanel"
// //                                       aria-labelledby="v-pills-config-tab"
// //                                     >
// //                                       <div>
// //                                         <div className='mb-3'>
// //                                           <span className='font-size-17'>Configuration</span>
// //                                         </div>

// //                                         <hr className="my-4" />

// //                                         <div>
// //                                           <Label className="text-primary" htmlFor="autoSizingSelect">Select Repeat Mode</Label>
// //                                           <p className="card-title-desc">
// //                                             Use the <code>.badge-pill</code> modifier className to
// //                                             make badges more rounded (with a larger{" "}
// //                                             <code>border-radius</code>
// //                                             and additional horizontal <code>padding</code>).
// //                                             Useful if you miss the badges from v3.
// //                                           </p>
// //                                           <div className="mb-3 col-6">
// //                                             <select
// //                                               type="select"
// //                                               name="repeat_mode"
// //                                               label="Name"
// //                                               value={this.state.selectedRepeatMode}
// //                                               className="form-select"
// //                                               id="review_mode_level"
// //                                               required
// //                                               onChange={(e) => this.selectRepeatMode(e)}
// //                                             >
// //                                               <option value="choose" disabled>Choose...</option>
// //                                               {
// //                                                 this.state.repeatModeData.map((data, idx) => {
// //                                                   return (
// //                                                     <option value={String(idx)} selected key={idx}>{data.mode}</option>
// //                                                   )
// //                                                 })
// //                                               }
// //                                             </select>
// //                                           </div>
// //                                         </div>

// //                                         <div>
// //                                           {this.repeatModeComponent(this.state.selectedRepeatMode)}
// //                                         </div>

// //                                       </div>
// //                                     </TabPane>

// //                                     <TabPane
// //                                       tabId={4}
// //                                       id="v-pills-confir"
// //                                       role="tabpanel">
// //                                       <div>
// //                                         <div className='mb-2'>
// //                                           <span className='font-size-17'>Confirmation</span> <br />
// //                                           <span className='font-size-13 text-primary'>{this.state.publishTemplate.template_name}</span>
// //                                         </div>

// //                                         <Row className="justify-content-center">
// //                                           <Col xl={10}>

// //                                             <div className="mt-2">
// //                                               <div className="d-flex flex-wrap">
// //                                                 <div className="me-2">
// //                                                   <span className="me-2 font-size-18">Hirerach Level</span> <span
// //                                                     onClick={() => {
// //                                                       this.toggleTab(1)
// //                                                     }}
// //                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
// //                                                     edit
// //                                                   </span>

// //                                                 </div>
// //                                                 <div className="ms-auto">
// //                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
// //                                                 </div>
// //                                               </div>
// //                                               <hr className="mt-2" />

// //                                               <div className="list-group list-group-flush">
// //                                                 <div
// //                                                   to="#"
// //                                                   className="list-group-item text-muted"
// //                                                 >
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Hirerachy level - <span className="text-success">{this.state.publishTemplate.hlevel}</span>
// //                                                 </div>

// //                                                 <div
// //                                                   to="#"
// //                                                   className="list-group-item text-muted"
// //                                                 >
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Endpoint - <span className="text-success">{this.state.publishTemplate.ep_level} </span>
// //                                                 </div>

// //                                                 <div
// //                                                   to="#"
// //                                                   className="list-group-item text-muted"
// //                                                 >
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   No. of Endpoints selected - <span className="text-success">{this.state.publishTemplate.ep_selected.length}</span>
// //                                                 </div>
// //                                               </div>
// //                                             </div>

// //                                             {/* <div className="mt-5">
// //                                               <div className="d-flex flex-wrap">
// //                                                 <div className="me-2">
// //                                                   <span className="me-2 font-size-18">Audit / Review</span> <span
// //                                                     onClick={() => {
// //                                                       this.toggleTab(2)
// //                                                     }}
// //                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
// //                                                     edit
// //                                                   </span>
// //                                                 </div>
// //                                                 <div className="ms-auto">
// //                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
// //                                                 </div>
// //                                               </div>
// //                                               <hr className="mt-2" />

// //                                               <div className="list-group list-group-flush">
// //                                                 <div
// //                                                   to="#"
// //                                                   className="list-group-item text-muted"
// //                                                 >
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Audit user level - <span className="text-success">{this.state.publishTemplate.audit_userlevel}</span>
// //                                                 </div>

// //                                                 <div
// //                                                   to="#"
// //                                                   className="list-group-item text-muted"
// //                                                 >
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Review user level - {this.state.publishTemplate.review_userlevel == null ? <span className="text-danger"> No Review </span> : <span className="text-success">{this.state.publishTemplate.review_userlevel}</span>}
// //                                                 </div>

// //                                               </div>
// //                                               <div  to="#"
// //                                                 className="list-group list-group-flush">
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Display &nbsp;&nbsp;
// //                                                   <div style={{display: 'flex', alignItems: 'center'}}>
// //                                                   <div>Audit Score:</div>&nbsp;&nbsp;
// //                                                   <input type={"checkbox"} name='audit_score_preview' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;
// //                                                   </div>
// //                                                 {
// //                                                   this.state.customEnableReview === true &&
// //                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
// //                                                     <div>Review Score:</div>&nbsp;&nbsp;
// //                                                     <input type={"checkbox"} name="review_score_preview" onChange={(e) => this.handle_click(e)} />
// //                                                   </div>
// //                                                 }


// //                                               </div>
// //                                             </div> */}


// //                                             <div className="mt-5">
// //                                               <div className="d-flex flex-wrap">
// //                                                 <div className="me-2">
// //                                                   <span className="me-2 font-size-18">Audit / Review</span>
// //                                                   <span
// //                                                     onClick={() => {
// //                                                       this.toggleTab(2);
// //                                                     }}
// //                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1"
// //                                                   >
// //                                                     edit
// //                                                   </span>
// //                                                 </div>
// //                                                 <div className="ms-auto">
// //                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
// //                                                 </div>
// //                                               </div>
// //                                               <hr className="mt-2" />

// //                                               <div className="list-group list-group-flush">
// //                                                 <div to="#" className="list-group-item text-muted">
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Audit user level -{" "}
// //                                                   <span className="text-success">
// //                                                     {this.state.publishTemplate.audit_userlevel}
// //                                                   </span>
// //                                                 </div>

// //                                                 <div to="#" className="list-group-item text-muted">
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Review user level -{" "}
// //                                                   {this.state.publishTemplate.review_userlevel == null ? (
// //                                                     <span className="text-danger"> No Review </span>
// //                                                   ) : (
// //                                                     <span className="text-success">
// //                                                       {this.state.publishTemplate.review_userlevel}
// //                                                     </span>
// //                                                   )}
// //                                                 </div>

// //                                                 <div to="#" className="list-group-item text-muted d-flex gap-3">
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Display:{" "}
// //                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
// //                                                     <div>Audit Score:</div>&nbsp;&nbsp;
// //                                                     <input
// //                                                       type={"checkbox"}
// //                                                       name="audit_score_preview"
// //                                                       onChange={(e) => this.handle_click(e)}
// //                                                     />
// //                                                     &nbsp;&nbsp;
// //                                                   </div>
// //                                                   {this.state.publishTemplate.review_userlevel !== null && (
// //                                                     <div style={{ display: 'flex', alignItems: 'center' }}>
// //                                                       <div>Review Score:</div>&nbsp;&nbsp;
// //                                                       <input
// //                                                         type={"checkbox"}
// //                                                         name="review_score_preview"
// //                                                         onChange={(e) => this.handle_click(e)}
// //                                                       />
// //                                                     </div>
// //                                                   )}
// //                                                 </div>

// //                                                 <div to="#" className="list-group-item text-muted d-flex gap-3">
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Track Location :{" "}
// //                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
// //                                                     <div>Auditor :</div>&nbsp;&nbsp;
// //                                                     <input
// //                                                       type={"checkbox"}
// //                                                       name="audit_coords_enable"
// //                                                       onChange={(e) => this.handle_click(e)}
// //                                                     />
// //                                                     &nbsp;&nbsp;
// //                                                   </div>
// //                                                   {this.state.publishTemplate.review_userlevel !== null && (
// //                                                     <div style={{ display: 'flex', alignItems: 'center' }}>
// //                                                       <div>Reviewer:</div>&nbsp;&nbsp;
// //                                                       <input
// //                                                         type={"checkbox"}
// //                                                         name="review_coords_enable"
// //                                                         onChange={(e) => this.handle_click(e)}
// //                                                       />
// //                                                     </div>
// //                                                   )}
// //                                                 </div>



// //                                               </div>
// //                                             </div>




// //                                             <div className="mt-5">
// //                                               <div className="d-flex flex-wrap">
// //                                                 <div className="me-2">
// //                                                   <span className="me-2 font-size-18">Configuration</span> <span
// //                                                     onClick={() => {
// //                                                       this.toggleTab(3)
// //                                                     }}
// //                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
// //                                                     edit
// //                                                   </span>
// //                                                 </div>
// //                                                 <div className="ms-auto">
// //                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
// //                                                 </div>
// //                                               </div>
// //                                               <hr className="mt-2" />

// //                                               <div className="list-group list-group-flush">
// //                                                 <div
// //                                                   to="#"
// //                                                   className="list-group-item text-muted"
// //                                                 >
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Publish on <span className="text-danger">*</span>
// //                                                   <input
// //                                                     className="form-control"
// //                                                     type="date"
// //                                                     min={this.state.fromate_date}
// //                                                     selected={this.state.ptl_startdate}
// //                                                     defaultValue={this.state.ptl_startdate}
// //                                                     id="start_date"
// //                                                     onChange={event => this.onChangeStartDate(event)}
// //                                                   />
// //                                                 </div>

// //                                                 <div
// //                                                   to="#"
// //                                                   className="list-group-item text-muted"
// //                                                 >
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Audit End on <span className="text-danger">*</span>
// //                                                   <input
// //                                                     className="form-control"
// //                                                     type="date"
// //                                                     min={this.state.fromate_date}
// //                                                     selected={this.state.ptl_enddate}
// //                                                     defaultValue={this.state.ptl_enddate}
// //                                                     id="end_date"
// //                                                     onChange={event => this.onChangeEndDate(event)}
// //                                                   />
// //                                                 </div>

// //                                                 <div
// //                                                   to="#"
// //                                                   className="list-group-item text-muted"
// //                                                 >
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   Frequency - <span className="text-success">{this.state.publishTemplate.frequency}</span>
// //                                                 </div>

// //                                               </div>
// //                                             </div>

// //                                             <div className="mt-5">
// //                                               <div className="d-flex flex-wrap">
// //                                                 <div className="me-2">
// //                                                   <span className="me-2 font-size-18">Check points</span> <span
// //                                                     onClick={() => this.navigateTo(this.state.publishTemplate)}
// //                                                     className="btn badge badge-soft-primary rounded-pill float-end font-size-12 mt-1">
// //                                                     edit
// //                                                   </span>
// //                                                 </div>
// //                                                 <div className="ms-auto">
// //                                                   <i className="mdi mdi-check-all float-end font-size-17 text-success"></i>
// //                                                 </div>
// //                                               </div>
// //                                               <hr className="mt-2" />

// //                                               <div className="list-group list-group-flush">
// //                                                 <div

// //                                                   className="list-group-item text-muted"
// //                                                 >
// //                                                   <i className="mdi mdi-circle-medium me-1"></i>{" "}
// //                                                   No of check points - <span className="text-success">{this.state.publishTemplate.total_checkpoints}</span>
// //                                                 </div>

// //                                               </div>
// //                                             </div>

// //                                           </Col>
// //                                         </Row>
// //                                         <Row>
// //                                           <div className="wizard clearfix">
// //                                             <div className="actions clearfix">
// //                                               <button className='btn btn-primary mx-2'> Save </button>
// //                                               <button className='btn btn-success' disabled={this.state.cc_level <= 3} onClick={() => this.publishTmpltAsAudit()}> Save and Publish</button>
// //                                             </div>
// //                                             <div className="text-danger">{this.state.sderror ? "Check publish on date" : this.state.ederror ? "check audit end on date" : ''}</div>
// //                                           </div>
// //                                         </Row>
// //                                       </div>
// //                                     </TabPane>

// //                                   </TabContent>
// //                                 </CardBody>
// //                               </Card>

// //                               {/* <Row>
// //                         <div className="wizard clearfix">
// //                           <div className="actions clearfix">
// //                             <ul>
// //                               <li
// //                                 className={
// //                                   this.state.activeTab === 1 
// //                                     ? "previous disabled"
// //                                     : "previous"
// //                                 }
// //                               >
// //                                 <Link
// //                                   to="#"
// //                                   onClick={() => {
// //                                     this.toggleTab(this.state.activeTab - 1)
// //                                   }}
// //                                 >
// //                                   Previous
// //                                 </Link>
// //                               </li>
// //                               <li
// //                                 className={
// //                                   this.state.activeTab === 4 
// //                                     ? "next disabled"
// //                                     : "next"
// //                                 }
// //                               >
// //                                 <Link
// //                                   to="#"
// //                                   onClick={() => {
// //                                     this.toggleTab(this.state.activeTab + 1)
// //                                   }}
// //                                 >
// //                                   Next
// //                                 </Link>
// //                               </li>
// //                             </ul>
// //                           </div>
// //                         </div>
// //                       </Row> */}
// //                             </AvForm>
// //                           </Col>
// //                         </Row>
// //                       </div>
// //                     </div>
// //                     : null
// //               }

// //             </Container>
// //           </div>
// //           <ReactDrawer
// //             open={this.state.open}
// //             className="drawer-open"
// //             position={this.state.position}
// //             onClose={this.onDrawerClose}
// //             style={{ maxHeight: 1000 }}
// //             noOverlay={false}
// //           >

// //             <div style={{ padding: 10, maxHeight: this.state.height, overflow: 'auto' }}>
// //               {
// //                 this.state.component === "user" ?
// //                 <React.Fragment>
// //                 <Container fluid>
// //                     <Row >
// //                         <Col >
// //                             <Card className="overflow-hidden">
// //                                 {
// //                                     !this.state.formDisabled ?
// //                                         <CardBody className="pt-0">
// //                                             <div className="p-5">
// //                                                 <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


// //                                                     <Row className="my-4">
// //                                                     <div className="d-sm-flex align-items-center justify-content-between">
// //                                                         <div className="text-danger font-size-18">User Information</div>

// //                                                         <button className="btn btn-outline-dark " onClick={() => this.onDrawerClose()}> Close </button>
// //                                                     </div>
// //                                                         <hr className="my-4" />
// //                                                     </Row>

// //                                                     <Row>
// //                                                         <Col className="col-6">
// //                                                         <div className="mb-3" >
// //                                                                 <label>Full Name :<span className="text-danger"> *</span></label>
// //                                                                 <AvField
// //                                                                     name="firstname"
// //                                                                     // label="First Name"
// //                                                                     className="form-control"
// //                                                                     placeholder="Enter Full Name"
// //                                                                     type="text"
// //                                                                     required
// //                                                                 />
// //                                                             </div>
// //                                                         </Col>
// //                                                         <Col className="col-6">
// //                                                         <div className="mb-3" >
// //                                                                 <label>Email Id :<span className="text-danger"> *</span></label>
// //                                                                 <AvField
// //                                                                     name="email_id"
// //                                                                     onChange={(e) => { this.emai_addrs(e) }}
// //                                                                     onBlur={(e) => { this.validate_email(e, 1) }}
// //                                                                     // label="User Email"
// //                                                                     className="form-control"
// //                                                                     placeholder="Enter User Email"
// //                                                                     type="email"
// //                                                                     required
// //                                                                 />
// //                                                                  {
// //                                                                             this.state.errorMessage &&
// //                                                                             <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>
// //                                                                         }
// //                                                                 {
// //                                                                     this.state.invalid_user &&
// //                                                                     <div className='text-danger' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
// //                                                                 }
// //                                                             </div>
// //                                                         </Col>
// //                                                         {/* <Col className="col-6">
// //                                                         <div className="mb-3" >
// //                                                                 <label>Last Name :<span className="text-danger"> *</span></label>
// //                                                                 <AvField
// //                                                                     name="lastname"
// //                                                                     // label="Last Name"
// //                                                                     className="form-control"
// //                                                                     placeholder="Enter Last Name"
// //                                                                     type="text"
// //                                                                 />
// //                                                             </div>
// //                                                         </Col> */}
// //                                                     </Row>
// //                                                     {/* <Row>
// //                                                         <Col className="col-12">
// //                                                         <div className="mb-3" >
// //                                                         <AvField
// //                                                                     name="addrss_1"
// //                                                                     label="Address"
// //                                                                     className="form-control"
// //                                                                     placeholder="Enter Address"
// //                                                                     type="text"
// //                                                                 />
                                                            
// //                                                             </div>
                                                            
// //                                                         </Col>
// //                                                     </Row> */}
// //                                                 {/* <Row>
// //                                                     <Col className="col-4">
// //                                                         <div className="mb-3" >
// //                                                             <AvField
// //                                                                 name="city"
// //                                                                 label="City"
// //                                                                 className="form-control"
// //                                                                 placeholder="Enter City"
// //                                                                 type="text"
// //                                                             />

// //                                                         </div>

// //                                                     </Col>
// //                                                     <Col className="col-4">
// //                                                         <div className="mb-3" >
// //                                                             <AvField
// //                                                                 name="state"
// //                                                                 label="State"
// //                                                                 className="form-control"
// //                                                                 placeholder="Enter State"
// //                                                                 type="text"
// //                                                             />

// //                                                         </div>

// //                                                     </Col>
// //                                                     <Col className="col-4">
// //                                                         <div className="mb-3" >
// //                                                             <AvField
// //                                                                 name="country"
// //                                                                 label="Country"
// //                                                                 className="form-control"
// //                                                                 placeholder="Enter Country"
// //                                                                 type="text"
// //                                                             />

// //                                                         </div>

// //                                                     </Col>
// //                                                 </Row> */}

                                                    

// //                                                     <Row>
// //                                                         {/* <Col className="col-6">
// //                                                         <div className="mb-3" >
// //                                                                 <label>Email Id :<span className="text-danger"> *</span></label>
// //                                                                 <AvField
// //                                                                     name="email_id"
// //                                                                     onChange={(e) => { this.emai_addrs(e) }}
// //                                                                     onBlur={(e) => { this.validate_email(e, 1) }}
// //                                                                     // label="User Email"
// //                                                                     className="form-control"
// //                                                                     placeholder="Enter User Email"
// //                                                                     type="email"
// //                                                                     required
// //                                                                 />
// //                                                                 {
// //                                                                     this.state.invalid_user &&
// //                                                                     <div className='text-danger' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
// //                                                                 }
// //                                                             </div>
// //                                                         </Col> */}
// //                                                         <Col className="col-6">
// //                                                         <div className="mb-3">
// //                                                             <label>Phone Number:<span className="text-danger"> *</span></label>
// //                                                             <div className="input-group">
// //                                                                 <div className="input-group-prepend">
// //                                                                     <select name="countrycode" onChange={(e) => { this.select_country(e) }} className="form-select" required>
// //                                                                         <option value="">Select</option>
// //                                                                         {
// //                                                                             this.state.client_info.countries.map((c, idx) => (
// //                                                                                 <option key={idx} value={c.code}>
// //                                                                                     {c.code}
// //                                                                                 </option>
// //                                                                             ))
// //                                                                         }
// //                                                                     </select>
// //                                                                 </div>
// //                                                                 <div className="flex-grow-1">
// //                                                                     <AvField
// //                                                                         name="phone_number"
// //                                                                         // label="Phone Number"
// //                                                                         className="form-control"
// //                                                                         placeholder="Enter Phone number"
// //                                                                         type="number"
// //                                                                         onBlur={(e) => { this.validate_email(e, 2) }}
// //                                                                         validate={{
// //                                                                             required: { value: true },
// //                                                                             minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
// //                                                                             maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
// //                                                                         }}
// //                                                                     />
// //                                                                     {
// //                                                                         this.state.invalid_phone_num &&
// //                                                                         <div className='text-danger' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
// //                                                                     }
// //                                                                 </div>
// //                                                             </div>
// //                                                         </div>
// //                                                         {
// //                                                             this.state.country_code_err &&
// //                                                             <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


// //                                                         }




// //                                                             {/* <div className="mb-3" >
// //                                                                 <AvField
// //                                                                     name="phone_number"
// //                                                                     label="Phone Number"
// //                                                                     className="form-control"
// //                                                                     placeholder="Enter Phone number"
// //                                                                     type="number"
// //                                                                     validate={{
// //                                                                         required: { value: true },
// //                                                                         minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
// //                                                                         maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
// //                                                                     }}
// //                                                                 />
// //                                                             </div> */}
// //                                                         </Col>
// //                                                     </Row>

// //                                                     {/* <Row>
// //                                                         <Col className="col-6">
// //                                                             <div className="mb-3">
// //                                                                 <AvField
// //                                                                     name="designation"
// //                                                                     label="Designation"
// //                                                                     type="text"
// //                                                                     // required
// //                                                                     placeholder="Enter Designation"
// //                                                                 />
// //                                                             </div>
// //                                                         </Col>
// //                                                         <Col className="col-6">
// //                                                             <div className="mb-3">
// //                                                                 <AvField
// //                                                                     name="usercode"
// //                                                                     label="User Code"
// //                                                                     type="text"
// //                                                                     // required
// //                                                                     placeholder="Enter User Code"
// //                                                                     onChange={(e)=>{this.userCode(e)}}
// //                                                                     onBlur={(e) => this.validate_email(e, 3)}
// //                                                                 />
// //                                                                   {
// //                                                                     this.state.invalid_usercode &&
// //                                                                     <div className="text-danger" style={{ fontSize: 'smaller' }}>User Code already exist for another user</div>
// //                                                                 }
// //                                                             </div>
// //                                                         </Col>
// //                                                     </Row> */}

// //                                                     <Row className="my-4">
// //                                                         <div className="text-danger font-size-18">Login Information</div>
// //                                                         <hr className="my-4" />
// //                                                     </Row>

// //                                                     <Row>
// //                                                         <Col className="col-6">
// //                                                         <div className="mb-3">
// //                                                                 <label>Username :<span className="text-danger"> *</span></label>
// //                                                                 <AvField
// //                                                                     name="username"
// //                                                                     // label="Username"
// //                                                                     type="text"
// //                                                                     required
// //                                                                     placeholder="Enter username"
// //                                                                     disabled={true}
// //                                                                     value={this.state.given_email}
// //                                                                 />
// //                                                             </div>
// //                                                         </Col>
// //                                                     {/* </Row> */}

// //                                                     {/* <Row> */}
// //                                                         {/* <Col className="col-6">
// //                                                         <div className="mb-3">
// //                                                                 <label>Password :<span className="text-danger"> *</span></label>
// //                                                                 <AvField
// //                                                                     name="password"
// //                                                                     autoComplete = "new-password"
// //                                                                     // label="Password"
// //                                                                     type="password"
// //                                                                     required
// //                                                                     placeholder="Enter password"
// //                                                                     validate={{
// //                                                                         required: { value: true, errorMessage: 'Password is required' },
// //                                                                         minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
// //                                                                         pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
// //                                                                     }}
// //                                                                 />
// //                                                             </div>
// //                                                         </Col> */}

// //                                         <Col className="col-5">
// //                                           <div className="mb-3">
// //                                             <label>Password :<span className="text-danger"> *</span></label>
// //                                             <AvField
// //                                               name="password"
// //                                               autoComplete="new-password"
// //                                               // label="Password"    
// //                                               type={this.state.show ? "password" : "text"}
// //                                               style={{ width: "105%" }} required

// //                                               placeholder="Enter password"

// //                                               validate={{
// //                                                 required: { value: true, errorMessage: 'Password is required' }, minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' }, pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
// //                                               }} />
// //                                           </div>
// //                                         </Col>
// //                                         <Col xl={1} style={{ marginLeft: "-12px", marginTop: "27px" }} >
// //                                           <button onClick={() => this.setState({ show: !this.state.show, })} className="btn btn-light " type="button" id="password-addon" >
// //                                             <i className={this.state.show ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"} ></i>
// //                                           </button>
// //                                         </Col>
// //                                                         {/* <Col className="col-6">
// //                                                             <div className="mb-3">
// //                                                                 <AvField
// //                                                                     name="confirmpassword"
// //                                                                     label="Confirm Password"
// //                                                                     type="password"
// //                                                                     required
// //                                                                     placeholder="Enter Confirm password"
// //                                                                 />
// //                                                             </div>
// //                                                         </Col> */}
// //                                                     </Row>


// //                                                     {/* <Row className="my-4">
// //                                                         <div className="text-danger font-size-18">Set Permissions</div>
// //                                                         <hr className="my-4" />
// //                                                     </Row>    */}

// //                                                 {/* <Row>

// //                                                     {
// //                                                         this.state.configData.facilities.map((item, idx)=>{
// //                                                             return(
// //                                                                 <Col className="col-auto" key={"pn"+idx}>
// //                                                                     <div className="form-check mb-3">
// //                                                                         <input
// //                                                                             className="form-check-input"
// //                                                                             type="checkbox"
// //                                                                             value=""
// //                                                                             id={"pmsn"+idx}
// //                                                                             disabled={item.id === "7" ? false : true}
// //                                                                             defaultChecked={item.id === "7"}
// //                                                                             onChange={(e) => { this.addPermissions(e, item) }}
// //                                                                             //checked={item.is_compliance}
// //                                                                         />
// //                                                                         <label
// //                                                                             className="form-check-label"
// //                                                                             htmlFor={"pmsn"+idx}
// //                                                                         >
// //                                                                             {item.interfacename}
// //                                                                         </label>
// //                                                                     </div>
// //                                                                 </Col>
// //                                                             )
// //                                                         })
// //                                                     }
                                                    
// //                                                 </Row>          */}


// //                                                     <div className="mb-3" >

// //                                                     </div>
// //                                                     {
// //                                                         this.state.alertEnable ?
// //                                                             <div className="mt-4 d-grid">
// //                                                                 <Alert color={this.state.alertColor}>
// //                                                                     {this.state.message}
// //                                                                 </Alert>
// //                                                             </div>
// //                                                             : null
// //                                                     }


// //                                                     <div className="mt-4 d-grid">
// //                                                         <button
// //                                                             className="btn btn-danger btn-block"
// //                                                             type="submit"
// //                                                             disabled={this.state.loading}
// //                                                         >
// //                                                            {this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Add User"}
// //                                                         </button>
// //                                                     </div>

// //                                                 </AvForm>
// //                                             </div>
// //                                         </CardBody> :
// //                                         <CardBody>
// //                                             <div className="mt-4 text-center">
// //                                                 <div className="mb-0" style={{ fontSize: 20 }}>
// //                                                     <span className="text-danger">{this.state.first_name}</span> has added successfully
// //                                                 </div>
// //                                                 <div style={{ marginTop: 20 }}>
// //                                                     <Link  className="btn btn-success" onClick={()=>this.props.onClose()}>
// //                                                         Back
// //                                                     </Link>
// //                                                 </div>
// //                                             </div>
// //                                         </CardBody>
// //                                 }
// //                             </Card>

// //                         </Col>
// //                     </Row>
// //                 </Container>
// //         </React.Fragment>
// //                   // <AddUserNode
// //                   //   onClose={this.onDrawerClose}
// //                   //    updtUser = {this.changeAuditUserHandler}
// //                   //    getAllUsers = {this.getTemplateMethod}
// //                   //   rowData={this.state.emptData}
// //                   //   getDidmountFunction={this.getDidmountFunction}
// //                   //   didmount = {this.componentDidMount}
                    
// //                   // />
// //                   : this.state.component === "endpoint" ?
// //                     <AddEndpointNode
// //                       endpoints={this.state.publishTemplate.endpoints}
// //                       publishtemplateInfo={this.state.publishTemplate}
// //                       onClose={this.onDrawerClose}
// //                       editItem={this.state.editItem}
// //                       onCancel={this.onCancelDrawerClose}
// //                     /> : this.state.component === "loadendpoint" ?
                    
// //                     <LoadEndpoint
// //                     userInfo={this.state.userInfo}
// //                     publishtemplateInfo={this.state.publishTemplate}
// //                     onClose={this.onDrawerClose}

// //                     />
// //                     :
// //                     // this.state.component === "loadgroupusers" ?
// //                     // <LoadGroupUser
// //                     // userInfo={this.state.userInfo}
// //                     // endpoint_data ={this.state.endpoint_data}
// //                     // publishtemplateInfo={this.state.publishTemplate}
// //                     // onClose={this.onDrawerClose}
// //                     // />:
                      
                    
// //                     null
// //               }
// //         {/* {
// //           //(this.state.userInfo,'this.state.userInfo',this.state.publishTemplate)
// //         } */}


// //               {
// //                 //(this.state.publishTemplate,'publish')
// //               }
// //             </div>
// //           </ReactDrawer>
         
// //           {this.state.changeMethodConfirmation ? (
// //             <SweetAlert
// //               title="Are you sure?"
// //               warning
// //               showCancel
// //               confirmBtnBsStyle="success"
// //               cancelBtnBsStyle="danger"
// //               onConfirm={() => {
// //                 this.state.triggerFrom === "hlevel" ? this.resetEndpointMethod() : this.state.triggerFrom === "endpoint" ? this.deleteEndpoint() : this.state.triggerFrom === "deleteall" && this.deleteAllEndpoints() 
// //               }
// //               }
// //               onCancel={() =>
// //                 this.setState({
// //                   changeMethodConfirmation: false,
// //                   error_dlg: true,
// //                   dynamic_title: "Cancelled",
// //                   dynamic_description:
// //                     "Your imaginary file is safe :)",
// //                 })
// //               }
// //             >
// //               {this.state.triggerFrom === "hlevel" ? "Changing the Add Location method will clear all saved data." : this.state.triggerFrom === "endpoint" ? "Deleting the Location can't revet.": this.state.triggerFrom === "deleteall" ? "Deleteing all Location can't revert" : ""}
// //             </SweetAlert>
// //           ) : null}
// //           {this.state.success_dlg ? (
// //             <SweetAlert
// //               success
// //               title={this.state.dynamic_title}
// //               onConfirm={() => this.setState({ success_dlg: false })}
// //             >
// //               {this.state.dynamic_description}
// //             </SweetAlert>
// //           ) : null}
// //           {this.state.dataAlert ? (
// //             <SweetAlert
// //               title={this.state.alertMessage}
// //               onConfirm={() => this.setState({ dataAlert: false ,
// //                 publishTemplate: this.state.publishTemplate,
// //                 selectedReviewUserlevel: this.state.selectedReviewUserlevel,
// //                 reviewUserlevelSelected: true,
// //                 cc_level: this.state.cc_level,
// //                 ptl_startdate: null,
// //                 ptl_enddate: null,
// //                 repeatMode: null,
// //                 endpoints: this.state.endpoints,
// //                 review_userlevel: this.state.review_userlevel,
// //                 auditUserlevelSelected: true              
// //               })}
// //             ></SweetAlert>
// //           ) : null}

// //           {this.state.tmpltPublishProcess ? (
// //             <SweetAlert
// //               title={this.state.alertMessage}
// //               onConfirm={() => this.redirectTo(this.state.processStatus)}
// //             ></SweetAlert>
// //           ) : null}
// //         </React.Fragment>
// //       )
// //     }
// //     else {
// //       return null
// //     }
// //   }
// // }