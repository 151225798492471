import Drawer from '@material-ui/core/Drawer';
import { Link } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import{ makechangesinuser,addUserToGroup,emitdeleteGroup} from '../../helpers/socket'
import {  FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';


import {
    Button, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Col, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Collapse,
} from 'reactstrap';

const GroupInfoDrawer = ({ selectedUser, userData, open, toggle }) => {
    const dispatch = useDispatch();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const chatData = useSelector((state) => state.chatslice);
    const recentuserlist = chatData.recentuserlist;
    const userList = chatData.userlist; 
    
    const recentwithoutgroup = _.filter (recentuserlist,{type:"0"})
    const filteredRecent = _.filter(recentwithoutgroup, recentUser => 
        !_.some(selectedUser.group_users, user => user._id === recentUser.sender_id)
    );

    // const appusers = _.filter(userList, { _id: userData.user_data._id })
    
    const appusers = userList.filter((ele)=>{
        if(ele._id !== userData.user_data._id ){
            return ele
        }
    })


    const filteredusers = _.filter(appusers,user =>
        !_.some(selectedUser.group_users,ele => ele._id === user._id )
    )

    function formatDate(dateString) {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        return new Date(dateString).toLocaleString('en-US', options);
    }



    const onClickRemove = (selecteduser,user,type) =>{
        makechangesinuser(selecteduser,user,type)
    }


    const onClickLeave = (selecteduser,user,type) =>{
        makechangesinuser(selecteduser,user,type)
    }


    const onClickMakeAdmin = (selecteduser,user,type) =>{
        makechangesinuser(selecteduser,user,type)
    }



    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };


    const formik = useFormik({
        initialValues: {
          selectedUsers: [],
        },
        validationSchema: Yup.object({
          selectedUsers: Yup.array()
            .min(1, 'Please select at least one user')
            .required('Please select at least one user'),
        }),
        onSubmit: values => {
          console.log('Form values:', values);
          addUserToGroup(values,selectedUser)
          formik.resetForm()
        //   setStep(1);
          toggle(); 
        },
      });


      const handleCheckboxChange = user => {
        const selectedUsers = formik.values.selectedUsers.includes(user)
          ? formik.values.selectedUsers.filter(u => u !== user)
          : [...formik.values.selectedUsers, user];
        formik.setFieldValue('selectedUsers', selectedUsers);
      };



    const deleteGroup = () => {
        emitdeleteGroup(selectedUser)
    }


    const closeModal = () =>{
          toggle(); 
          formik.resetForm()
    }





    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={toggle}

        >
            <div style={{ width: '500px', padding: "10px" }}  >
            <div className="px-3 py-3 d-flex flex-row justify-content-between">
                <div className="d-flex flex-row gap-2">
                    <Nav tabs className="nav-tabs-custom " style={{ border: 0 }}>
                        <NavItem style={{ flexGrow: 0 }}>
                            <NavLink
                                style={{ cursor: "pointer", padding: 0 }}
                                className="me-2"

                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >

                                <div className="avatar-group-item" title={"Add More Users"}>
                                    <Link to="#" className="d-inline-block" defaultValue="member-4"
                                    >
                                        <div className={`rounded-circle avatar-xs ${customActiveTab === "1" ? "bg-primary" : "bg-light"}`} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'black',
                                        }}>
                                            <i className={`fas fa-users ${customActiveTab === "1" ? "text-white" : "text-dark"}`} />
                                        </div>
                                    </Link>
                                </div>

                            </NavLink>
                        </NavItem>
                       
                            <NavItem style={{ flexGrow: 0 }}>
                                <NavLink
                                    style={{ cursor: "pointer", padding: 0 }}
                                    className="me-2"
                                    onClick={() => {
                                        toggleCustom("2");
                                    }}
                                >
                                    <div className="avatar-group-item" title={"Add More Users"}>
                                        <Link to="#" className="d-inline-block" defaultValue="member-4"
                                        >
                                            <div className={`rounded-circle avatar-xs ${customActiveTab === "2" ? "bg-primary" : "bg-light"}`} style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: 'black',
                                            }}>
                                                <i className={`fas fa-user-plus ${customActiveTab === "2" ? "text-white" : "text-dark"}`} />
                                            </div>
                                        </Link>
                                    </div>

                                </NavLink>
                            </NavItem>
                    </Nav>
                </div>

                <div className="avatar-group-item" title={"Add More Users"}>
                    <Link to="#" className="d-inline-block" defaultValue="member-4"
                               onClick={() => closeModal()}
                    >
                        <div className={`rounded-circle avatar-xs bg-danger`} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                        }}>
                            <i className="mdi mdi-close text-white" />
                        </div>
                    </Link>
                </div>


            </div>
                <div className="col p-2">
                    <TabContent
                        activeTab={customActiveTab}
                        className=" text-muted"
                    >
                        <TabPane tabId="1" >
                                <div className="d-flex justify-content-center align-items-center pb-5 " style={{ height: '20%'}}>
                                    <div className="text-center">
                                        <div className="avatar-xs mb-3">
                                            <span className="avatar-title rounded-circle bg-primary-subtle text-primary d-flex justify-content-center align-items-center bg-secondary" style={{ width: '5rem', height: '5rem' }}>
                                                <i className="fas fa-users" style={{ fontSize: '2rem' }}></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mx-3  border p-3 rounded text-center" >
                                    <h4>{selectedUser.sender_name}</h4>
                                    <h6 >{`Group: ${selectedUser.group_users.length} Members`}</h6>
                                </div>
                                <div className="mx-3 my-3 border p-3 rounded">
                                    Group Description:<br />
                                    {selectedUser.group_description}
                                </div>

                                <div className="mx-3 my-3 border p-3 rounded">
                                    Created By: {selectedUser.owner_name}<br />
                                    Created At: {formatDate(selectedUser.createdAt)}
                                </div>
                                <div className=" ps-3 ">
                                    <p>Group Members:</p>
                                </div>
                                <div className="mx-3 my-3 border p-3 rounded" style={{ height: "600px", overflowY: 'scroll' }}>
                                    {
                                        selectedUser.group_users
                                        .filter(user => user.status === "0")
                                        .map((user, index) => {
                                            if (user !== undefined) {
                                                return <div key={"tu" + index}  >
                                                    <div className="d-flex flex-column mb-2 p-2 bg-light  " key={"tu" + index} style={{ borderRadius: 25 }}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex flex-row align-items-center">

                                                                <div className={`rounded-circle avatar-xs me-2  bg-success text-dark bg-opacity-25`} style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: 'black',
                                                                }}>
                                                                    {/* ${isActive !== -1 ? "bg-success text-dark" : "bg-secondary text-dark"} */}
                                                                    {user.username.charAt(0).toUpperCase()}
                                                                </div>
                                                                <div className="d-flex flex-column ">
                                                                    <div className="font-size-13 text-dark">{user.username} {user._id === userData.user_data._id && "(You)" }{user.owner === "1" && "(Admin)"}</div>
                                                                    <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                <div className="d-flex flex-row gap-1">
                                                                    <UncontrolledDropdown className="dropdown d-inline-block me-1" direction="left" style={{ zIndex: 9999 }} >
                                                                        {

                                                                            <DropdownToggle type="menu" tag="a" id="dropdownMenuButton1">
                                                                                <div className="avatar-group-item" title={"Add More Users"}>
                                                                                    <Link to="#" className="d-inline-block" defaultValue="member-4"
                                                                                    >
                                                                                        <div className={`rounded-circle avatar-xs bg-secondary`} style={{
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                            color: 'black',
                                                                                        }}>
                                                                                            <i className="mdi mdi-dots-vertical text-white" />
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </DropdownToggle>
                                                                        }
                                                                        <DropdownMenu style={{ zIndex: 9999 }} >
                                                                            {user._id === userData.user_data._id && (
                                                                                <li><DropdownItem onClick={() => { onClickLeave(selectedUser, user, 'Leave') }} href="#" className="my-2 text-danger">Left from Group</DropdownItem></li>
                                                                            )}
                                                                            {user.owner !== "1" && user.owner !== "0" && (
                                                                                <li><DropdownItem onClick={() => { onClickMakeAdmin(selectedUser, user, 'Admin') }} href="#" className="my-2 text-success">Make As Admin</DropdownItem></li>
                                                                            )}
                                                                            {user.owner !== "0" && user._id !== userData.user_data._id &&(
                                                                                <li><DropdownItem onClick={() => { onClickRemove(selectedUser, user, 'Remove') }} href="#" className="my-2 text-danger">Remove Person</DropdownItem></li>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        })
                                    }

                                </div>
                                <div className="mx-3 my-3 p-2 border rounded d-flex justify-content-end">
                                 <Button className='btn btn-danger' onClick={()=> deleteGroup()} >Delete Group</Button>
                                </div>

                        </TabPane>
                        <TabPane tabId="2" >
                            <>
                       
                                <div className="d-flex flex-column" style={{ height: "100vh", overflow: "auto" }} >
                                    <>
                                        <form onSubmit={formik.handleSubmit}>
                                        {formik.values.selectedUsers.length > 0 && (
                                                <div className="d-flex justify-content-end">
                                                    <Button type="submit" color="success">
                                                        Add users
                                                    </Button>
                                                </div>
                                            )}

                                         

                                            <FormGroup>
                                                <h6 className='mt-3'>Recents</h6>
                                                {filteredRecent.map((user, index) => (
                                                    <div className="d-flex flex-column mb-2 p-2 bg-light" key={"ru" + index} style={{ borderRadius: 25 }}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    className="me-2"
                                                                    checked={formik.values.selectedUsers.includes(user)}
                                                                    onChange={() => handleCheckboxChange(user)}
                                                                />
                                                                <div
                                                                    className={`rounded-circle avatar-xs me-2 bg-success text-dark bg-opacity-25`}
                                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
                                                                >
                                                                    {user.sender_name.charAt(0).toUpperCase()}
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <div className="font-size-13 text-dark">{user.sender_name}</div>
                                                                    <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </FormGroup>
                                            <FormGroup>
                                                <h6>Contacts</h6>
                                                {filteredusers.map((user, index) => (
                                                    <div className="d-flex flex-column mb-2 p-2 bg-light" key={"cu" + index} style={{ borderRadius: 25 }}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    className="me-2"
                                                                    checked={formik.values.selectedUsers.includes(user)}
                                                                    onChange={() => handleCheckboxChange(user)}
                                                                />
                                                                <div
                                                                    className={`rounded-circle avatar-xs me-2 bg-success text-dark bg-opacity-25`}
                                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
                                                                >
                                                                    {user.firstname.charAt(0).toUpperCase()}
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <div className="font-size-13 text-dark">{user.firstname}</div>
                                                                    <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {formik.touched.selectedUsers && formik.errors.selectedUsers ? (
                                                    <FormFeedback className="d-block">{formik.errors.selectedUsers}</FormFeedback>
                                                ) : null}
                                            </FormGroup>

                                        </form>
                                    </>
                                </div>
                            </>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </Drawer>

    );
};

export default GroupInfoDrawer;
 