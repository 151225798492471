import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
const _ = require('lodash')

class stackedbarchart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                colors: ['#3c4ccf', '#f0f1f4'],
                chart: {
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        columnWidth: '40%',
                    },
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },

                xaxis: {
                    categories: ["Not Started", "In progress", "Completed", "Submitted", "Reviewed"],
                    labels: {
                        formatter: function (val) {
                            return val
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                fill: {
                    opacity: 1
                },

                legend: {
                    show: false,
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            },
            series: [{
                name: 'Not Started',
                data: _.map(this.props.entityUDData, 'total_audit_score')
            },
            {
                name: 'Max Score',
                data: _.map(this.props.entityUDData, 'max_score')
            }],
        }
    }
    render() {
        return (
            <React.Fragment>
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="290" />
            </React.Fragment>
        );
    }
}

export default stackedbarchart;