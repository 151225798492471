import { createSlice } from "@reduxjs/toolkit";
import urlSocket from "../../helpers/urlSocket";
import { changeNodeAtPath, addNodeUnderParent, getFlatDataFromTree, getNodeAtPath, removeNodeAtPath, getVisibleNodeCount,getTreeFromFlatData } from 'react-sortable-tree';
import { dispatch } from "d3";
import actions from "redux-form/lib/actions";


const getNodeKey = ({ treeIndex }) => treeIndex;

const initialState = {
    incidentTypeList: [],
    aplnRequestLoading: false,
    aplnRequestError: null,
    autoMatedIncidentId: '',
    autoMatedSeverityId: '',
    editIncidentTypeInfo: {},
    escalationList: [],
    editSeverityTypeInfo: {},
    severityTypeExist: false,
    severityTypeList: [],
    incidentTypeExist:false,
    hierarchyList :[],
    maxEscalationLevel :0,
    editEscalationInfo :{},
    escalatedHList:[],
    incidentReportTypeList:[],
    hTreeData:[],
    flatData:[],
    incidentsList: [],
    incidentsInfo :{},
    publishHid:'',
    designationList:[],
    selectedDesignation:{},
    taskUsers :[],
    myTaskList :[],
    respDesignation:[],
    selectedApln : null,
    validUser :[],
    aplnMessages:[],
    markerState : null,
    enableReason: false ,
    incdseverityTypeList : [],
    raisedIncident:[],
    selectedEOPT : [],
    publishedIncidentTypeList :[],
    duphTreeData: [],
    dupTreeData: {},
    filteredPublishedIncdType:[],
    editPublishedIncidentTypeList:[],
    closeStatus :[],
    irDashBoardData :[],
    incidentsListFilterInfo:[]
    // dupIncidentList:[]
    // severityList:[]
}
const incdRptSlice = createSlice({
    name: "incdReportSlice",
    initialState: initialState,
    reducers: {
        setIncidentTypeList: (state, action) => {
            state.incidentTypeList = action.payload
        },
        setcloseStatus:(state,action)=>{
            state.closeStatus = action.payload
        },
        setPublishedIncidentTypeList:(state,action)=>{
            state.publishedIncidentTypeList = action.payload
        },
        seteditPublishedIncidentTypeList:(state,action)=>{
            state.editPublishedIncidentTypeList = action.payload
        },
        setFilteredPublishedIncdType:(state,action)=>{
            state.filteredPublishedIncdType = action.payload
        },
        setAplnRequestLoading: (state, action) => {
            state.aplnRequestLoading = action.payload
        },
        setAplnRequestError: (state, action) => {
            state.aplnRequestError = action.payload
        },
        setAutomatedIncidentId: (state, action) => {
            state.autoMatedIncidentId = action.payload
        },
        seteditIncidentTypeInfo: (state, action) => {
            state.editIncidentTypeInfo = action.payload
        },
        setEscalationList: (state, action) => {
            state.escalationList = action.payload
        },
        setSeverityTypeList: (state, action) => {
            state.severityTypeList = action.payload
        },
        setAutomatedSeverityId: (state, action) => {
            state.autoMatedSeverityId = action.payload
        },
        seteditSeverityTypeInfo: (state, action) => {
            state.editSeverityTypeInfo = action.payload
        },
        setseverityTypeExist: (state, action) => {
            state.severityTypeExist = action.payload
        },
        setincidentTypeExist:(state,action)=>{
            state.incidentTypeExist = action.payload
        },
        sethierarchyList:(state,action)=>{
            state.hierarchyList = action.payload
        },
        setMaxEscalationLvl :(state,action)=>{
            state.maxEscalationLevel = action.payload
        },
        seteditEscalationInfo:(state,action)=>{
            state.editEscalationInfo= action.payload
        },
        setEscalatedHlist:(state,action)=>{
            state.escalatedHList = action.payload
        },
        setIncidentReportList:(state,action)=>{
            state.incidentReportTypeList = action.payload
        },
        setHtreeData:(state,action)=>{
            state.hTreeData = action.payload
        },
        setFlatData:(state,action)=>{
            state.flatData = action.payload
        },
        setIncidentsList:(state,action)=>{
            state.incidentsList= action.payload
        },
        // setDupIncidentsList:(state,action)=>{
        //     state.dupIncidentList = action.payload
        // },
       setIncidentsInfo:(state,action)=>{
            state.incidentsInfo= action.payload
        },
        setSelectedPublishHId:(state,action)=>{
            state.publishHid = action.payload
        },
        setdesignationList :(state,action)=>{
            state.designationList = action.payload
        },
        setSelectedDesignation:(state,action)=>{
            state.selectedDesignation = action.payload
        },
        settaskUsers:(state,action)=>{
            console.log(action.payload,'vaction')
            state.taskUsers = action.payload
        },
        setMyTaskList:(state,action)=>{
            state.myTaskList = action.payload
        },
        setrespDesignation:(state,action)=>{
            state.respDesignation = action.payload
        },
        setSelectedApln:(state,action)=>{
            state.selectedApln = action.payload
        },
        setValidUser: (state, action) => {
            state.validUser = action.payload
        },
        setIrMessages:(state,action)=>{
            state.aplnMessages = action.payload
        },
        setenableReason:(state,action)=>{
            console.log(action.payload,'action')
            state.enableReason = action.payload
        },
        setDupTreeData:(state,action)=>{
            state.dupTreeData = action.payload
        },
        updateAplnData: (state, action) => {
            var getAplnList = [...state.myTaskList]
            var getActionPlan = _.filter(getAplnList, { _id: action.payload.actionplan_id })[0]
            getActionPlan.task_status = action.payload.task_status
            console.log(getActionPlan,'getActionPlan')
            if( action.payload.task_percentage !== undefined ){
                getActionPlan.task_completion_perc = action.payload.task_percentage
            }
            getActionPlan.task_modified_by = action.payload.task_modified_by
            getActionPlan.task_modifier_name = action.payload.task_modifier_name
            getActionPlan.task_users = action.payload.task_users
            getActionPlan.unreadCount = action.payload.unread
            var getAcplnIndex = _.findIndex(getAplnList, { _id: action.payload.actionplan_id })
            if (getAcplnIndex !== -1) { getAplnList[getAcplnIndex] = getActionPlan }
            console.log(getAplnList,'getAplnList')
            state.myTaskList = [...getAplnList]

        },
        addAplnMessages: (state, action) => {
            var msgs = [...state.aplnMessages];
            const index = msgs.findIndex(msg => msg._id === action.payload._id);
            if (index !== -1) {
                msgs[index] = { ...msgs[index], ...action.payload };
            } else {
                msgs.push(action.payload);
            }
            state.aplnMessages = msgs;
        },
        setIncidentsListFilterInfo:(state,action)=>{
            state.incidentsListFilterInfo = action.payload
        },
        setMarkerState(state, action) {
            state.markerState = action.payload;
        },

        setIncdSeverityTypeList: (state, action) => {
            state.incdseverityTypeList = action.payload
        }, 
        setRaisedIncidentList:(state,action)=>{
            state.raisedIncident = action.payload
        },
        setselectedEOPT:(state,action)=>{
            state.selectedEOPT = action.payload
        },
        setDupHtreeData:(state,action)=>{
            state.duphTreeData = action.payload
        },
        setDashBoardData:(state,action)=>{
            state.irDashBoardData = action.payload
        }


    }
})


// export const retriveMyTaskList=()=>{


//     return async dispatch =>{

//         dispatch(setAplnRequestLoading(true))
//         const authUser = JSON.parse(sessionStorage.getItem("authUser"))
//         try {

//           const responseData =await urlSocket.post("incident-report/retrive-my-task",{
//                 encrypted_db_url: authUser.db_info.encrypted_db_url,
//                 user_id : authUser.user_data._id
//             })
//             console.log(responseData,'responseData')

//             dispatch(setAplnRequestLoading(false))
//             if(responseData.status === 200){
//                 dispatch(setMyTaskList(responseData.data.data))
//             }
           
//         } catch (error) {
//             dispatch(setAplnRequestError(error))
//         }


//     }
// }



export const retriveMyTaskList = () => {
    return async dispatch => {
        dispatch(setAplnRequestLoading(true));
        const authUser = JSON.parse(sessionStorage.getItem("authUser"));

        try {
            const responseData = await urlSocket.post("incident-report/retrive-my-task", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                user_id: authUser.user_data._id
            });
            console.log(responseData, 'responseData');

            dispatch(setAplnRequestLoading(false));
            if (responseData.status === 200) {
                dispatch(setMyTaskList(responseData.data.data));
            }
            
            return responseData; // Return the response to make the function a promise
        } catch (error) {
            dispatch(setAplnRequestError(error));
            return Promise.reject(error); // Reject the promise with the error
        }
    };
};



export const IrDashboardAnalytics=()=>{

    return async dispatch =>{
        try {
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            var admin_data = _.filter(authUser.facility_data,{facility_id :"12"})
            var manage_data = _.filter(authUser.facility_data,{facility_id :"16"})
            var user_status = admin_data.length > 0 ? "1" : manage_data.length >0 ? "2" : "3" 
            var page_query= {
                incd_resp_person:{
                $elemMatch: { user_id: authUser.user_data._id },
                //  _.some(authUser.)
            }
            }
            console.log(admin_data.length,'admin_data',user_status);
            dispatch(setAplnRequestLoading(true))

           const responseData = await urlSocket.post("incident-report/retrive-icd-rep-dashboard",{
                encrypted_db_url :authUser.db_info.encrypted_db_url,
                page_query,
                user_status :user_status,
                user_id: authUser.user_data._id

            })
            console.log(responseData,'responseData')
            dispatch(setAplnRequestLoading(false))
            if(responseData.status === 200){
                dispatch(setDashBoardData(responseData.data.final_data))
            }
            else{
                dispatch(setAplnRequestError(responseData.statusText))
            }
            
        } catch (error) {
            console.log(error,'error');
            dispatch(setAplnRequestError(error))
        }
    }

}


export const checkIncidentTypeExists = (values, editInfo) => {

    return async dispatch => {
        dispatch(setAplnRequestLoading(true))
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            const responseData = await urlSocket.post("incident-report/validate-incident-name", {
                incd_type_name: values.toLowerCase(),
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                editInfo: editInfo
            })
            console.log(responseData, 'responseData')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                if (responseData.data.data.length > 0) {
                    dispatch(setincidentTypeExist(true))
                }
                else {
                    dispatch(setincidentTypeExist(false))
                }
            }

        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
    }
}



export const handleGlobalIncidentFilter =(value,page_query)=>{

    return async dispatch=>{
        dispatch(setAplnRequestLoading(true))
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            try {
              const responseData = await  urlSocket.post("incident-report/incident-list-global-filter",{
                    filter_value : value,
                    encrypted_db_url : authUser.db_info.encrypted_db_url,
                    page_query
                    // {
                    //     $elemMatch: { user_id: req.body.created_by }
                    // }
                })
                console.log(responseData,'responseData');
                dispatch(setAplnRequestLoading(false))
                if(responseData.data.data){
                    return responseData.data.data
                    // dispatch(setIncidentsList(responseData.data.data))
                }
                else{
                    dispatch(setAplnRequestError(responseData.statusText))
                }

            } catch (error) {
                dispatch(setAplnRequestError(error))
            }

    }
}


export const retriveHlist=()=>{

    return async dispatch =>{

        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        dispatch(setAplnRequestLoading(true))
        try {
          const responseData = await urlSocket.post("webhstre/incd-gethslist",{
                userInfo :{
                    created_by : authUser.user_data._id,
                    company_id : authUser.client_info[0].company_id,
                    encrypted_db_url : authUser.db_info.encrypted_db_url,
                    publish_status :'1'
                }
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData')
            if(responseData.status === 200){
                dispatch(sethierarchyList(responseData.data.data))
            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

            
        } catch (error) {
            dispatch(setAplnRequestError(error))
        }

    }
}


export const getHmaxLevel = (h_id) => {
    console.log(h_id,'h_id')
    return async dispatch => {  
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        dispatch(setAplnRequestLoading(true))

        try {
            const responseData = await urlSocket.post('incident-report/get-hierarchy-level', {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                h_id: h_id,
                created_by : authUser.user_data._id
            })
            console.log(responseData,'responseData')
            dispatch(setAplnRequestLoading(false))
            if(responseData.status === 200){
                dispatch(retriveSeverityTypeList());
                dispatch(setMaxEscalationLvl(responseData.data.data))
            }
            else{
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
    }

}





export const checkSeverityTypeExists = (values, editInfo) => {
    console.log("values,editInfo", values, editInfo)

    return async dispatch => {
        dispatch(setAplnRequestLoading(true))
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            const responseData = await urlSocket.post("incident-report/validate-severity-name", {
                severity_name: values.toLowerCase(),
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                editInfo: editInfo
            })
            console.log(responseData, 'responseData')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                if (responseData.data.data.length > 0) {
                    dispatch(setseverityTypeExist(true))
                }
                else {
                    dispatch(setseverityTypeExist(false))
                }
            }
        } catch (error) {
            console.log(error, 'error')
        }
    }
}


export const createEscalationInfo =(values)=>{

    return async dispatch =>{
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        dispatch(setAplnRequestLoading(true))
        try {
            const responseData = await urlSocket.post("incident-report/crud-escalation-info",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                escalation_info : values
            })
            dispatch(setAplnRequestLoading(false))
            dispatch(retriveEscalationList())
            console.log(responseData,'responseData')


        } catch (error) {
            
        }
    }
}




export const createSeverityType = (severity_info) => {
    return async dispatch => {
        dispatch(setAplnRequestLoading(true))
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        severity_info["created_by"] = authUser.user_data._id
        try {
            const responseData = await urlSocket.post('incident-report/crud-severitytype-info', {
                severity_info: severity_info,
                encrypted_db_url: authUser.db_info.encrypted_db_url
            })
            dispatch(setAplnRequestLoading(false))
            dispatch(retriveSeverityTypeList())
        } catch (error) {
            dispatch(setAplnRequestError(error))

        }
    }

}






export const createIncidentType = (incident_info) => {

    return async dispatch => {
        dispatch(setAplnRequestLoading(true))
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        incident_info["created_by"] = authUser.user_data._id
        try {
            const responseData = await urlSocket.post('incident-report/crud-incidenttype-info', {
                incident_info: incident_info,
                encrypted_db_url: authUser.db_info.encrypted_db_url
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData, 'responseData')
            dispatch(retriveIncidentTypeList())

        } catch (error) {
            dispatch(setAplnRequestError(error))

        }
    }

}



export const retriveIncidentTypeList = () => {

    return async dispatch => {
        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                created_by: authUser.user_data._id,
                encrypted_db_url: authUser.db_info.encrypted_db_url
            }
            const responseData = await urlSocket.post("incident-report/retrive-incidenttype-list", requestInfo)
            console.log(responseData, 'responseData')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(setIncidentTypeList(responseData.data.data))
                dispatch(setAutomatedIncidentId(responseData.data.nextId))
            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            console.log(error,'error')
            dispatch(setAplnRequestError(error))
        }
    }
}





export const retriveIncidentTypePublishList = (mode) => {

    return async dispatch => {
        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                created_by: authUser.user_data._id,
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                edit :mode
            }
            const responseData = await urlSocket.post("incident-report/retrive-incidenttype-publish-list", requestInfo)
            console.log(responseData, 'responseData')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(setFilteredPublishedIncdType(responseData.data.data))
                // dispatch(setIncidentTypeList(responseData.data.data))
                // dispatch(setAutomatedIncidentId(responseData.data.nextId))
            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            console.log(error,'error')
            dispatch(setAplnRequestError(error))
        }
    }
}




export const retrivePublishedIncidentTypeList = () => {

    return async dispatch => {
        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                created_by: authUser.user_data._id,
                encrypted_db_url: authUser.db_info.encrypted_db_url
            }
            const responseData = await urlSocket.post("incident-report/retrive-published-incidenttype-list", requestInfo)
            console.log(responseData, 'responseData')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(setPublishedIncidentTypeList(responseData.data.data))
                // dispatch(setAutomatedIncidentId(responseData.data.nextId))
            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            console.log(error,'error')
            dispatch(setAplnRequestError(error))
        }
    }
}


export const deleteIncidentType = (_id) => {


    return async dispatch => {

        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                _id: _id,
                encrypted_db_url: authUser.db_info.encrypted_db_url
            }

            const responseData = await urlSocket.post('incident-report/delete-incidenttype', requestInfo)
            console.log(responseData, 'responseData')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(retriveIncidentTypeList())

            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            dispatch(setAplnRequestError(error))

        }
    }
}


export const deleteEscalationInfo = (_id) => {


    return async dispatch => {

        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                _id: _id,
                encrypted_db_url: authUser.db_info.encrypted_db_url
            }

            const responseData = await urlSocket.post('incident-report/delete-escalation-info', requestInfo)
            console.log(responseData, 'responseData')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(retriveEscalationList())

            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            dispatch(setAplnRequestError(error))

        }
    }
}



export const retriveEscalationList = () => {

    return async dispatch => {

        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))

            const responseData = await urlSocket.post("incident-report/retrive-escalation-list", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                created_by: authUser.user_data._id,
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData, 'responseData')
            if (responseData.status === 200) {
                dispatch(setEscalationList(responseData.data.data))
            }


        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
    }


}


export const retriveSeverityTypeList = () => {

    return async dispatch => {
        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                created_by: authUser.user_data._id,
                encrypted_db_url: authUser.db_info.encrypted_db_url
            }
            const responseData = await urlSocket.post("incident-report/retrive-severitytype-list", requestInfo)
            console.log(responseData, 'responseData')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(setSeverityTypeList(responseData.data.data))
                dispatch(setAutomatedSeverityId(responseData.data.nextId))
            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
    }
}


export const deleteSeverityType = (_id) => {

    return async dispatch => {
        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                _id: _id,
                encrypted_db_url: authUser.db_info.encrypted_db_url
            }

            const responseData = await urlSocket.post('incident-report/delete-severitytype', requestInfo)
            console.log(responseData, 'responseData')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(retriveSeverityTypeList())
            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            dispatch(setAplnRequestError(error))

        }

    }
}








export const getHdata =(_id)=>{
    return async dispatch =>{

        const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            dispatch(setAplnRequestLoading(true))


        const responseData = await urlSocket.post("incident-report/get-flat-hstructure",{
                    h_id : _id,
                    company_id : authUser.client_info[0].company_id,
                    encrypted_db_url : authUser.db_info.encrypted_db_url,
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData')
            if(responseData.status === 200){
                dispatch(setFlatData(responseData.data.data))
                var convertedFlatData = convertFlatDataToTreeData(responseData.data.data)
                console.log(convertedFlatData,'convertedFlatData')

                dispatch(setHtreeData(convertedFlatData))

            }
            else{
                dispatch(setAplnRequestError(responseData.statusText))
            }

           
        } catch (error) {
                dispatch(setAplnRequestError(error))

        }
    }
}







const convertFlatDataToTreeData = (arr) => {

    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    console.log(arr,'arr')
    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = {
            "value": arr[i]._id,
            "label": arr[i].hlevel_name,
            "children": arr[i].children,
            "id": arr[i].id,
            "parent": arr[i].parent,
            "node_id": arr[i]._id,
            "ep_level": arr[i].hlevel,
            "ep_level_value": arr[i].hlevel_value,
            "user_path": arr[i].user_path,
            "unique_users": arr[i].unique_users,
            "node_positon":arr[i].node_positon,
            "user_permission_acpln" : arr[i].user_permission_acpln,
            "code" : arr[i].hlevel_code ,
            "company_id" : arr[i].company_id ,
            "h_id":arr[i].h_id ,
            "_id":arr[i].h_id
    
    
    
          }
    
        //   arrElem = {
    //     "value": arr[i]._id,
    //     "label": arr[i].hlevel_name,
    //     "children": arr[i].children,
    //     "id": arr[i].id,
    //     "parent": arr[i].parent,
    //     "node_id": arr[i]._id,
    //     "ep_level": arr[i].hlevel,
    //     "ep_level_value": arr[i].hlevel_value,
    //     "user_path": arr[i].user_path,
    //     "unique_users": arr[i].unique_users,
    //     "node_positon":arr[i].node_positon,
    //     "user_permission_acpln" : arr[i].user_permission_acpln,
    //     "code" : arr[i].hlevel_code

    //   }
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]['children'] = []
    }


    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        if (mappedElem.parent) {
          if (mappedArr[mappedElem['parent']] !== undefined) {
            mappedArr[mappedElem['parent']]['children'].push(mappedElem);
          }
        }
        else {
          tree.push(mappedElem);
        }
      }
    }

    let update = () => obj => {
      if (obj.children.length > 0)
        obj.children.forEach(update());
      else
        obj.children = null
    };

    tree.forEach(update());
    return tree;
  }














export const incidentSubmitConfiguration=()=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem('authUser'))
               const responseData = await urlSocket.post("incident-report/get-incident-submisson-config",{
                encrypted_db_url: authUser.db_info.encrypted_db_url
            })
            console.log(responseData,'responseData')
            dispatch(setAplnRequestLoading(false))
            if(responseData.status === 200){
                dispatch(setEscalatedHlist(responseData.data.h_type))
                if(responseData.data.h_type.length >0 && responseData.data.h_type.length < 2){
                    dispatch(getHdata(responseData.data.h_type[0]._id))
                }
                dispatch(setIncidentReportList(responseData.data.incident_type))
                dispatch(setrespDesignation(responseData.data.resp_desgn))

            }
            else{
                dispatch(setAplnRequestError(responseData.statusText))
            }
            
        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
    }
}


export const getHierarchyDesignation=(h_id)=>{
    console.log(h_id,'h_id')

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem('authUser'))
           const responseData = await urlSocket.post("incident-report/get-hierarchy-designation",{
                _id : h_id,
                encrypted_db_url :authUser.db_info.encrypted_db_url
            })
            console.log(responseData,'responseData')
            dispatch(setAplnRequestLoading(false))
            if(responseData.status === 200){
                dispatch(setdesignationList(responseData.data.data))
            }
            console.log(responseData,'responseData')
            
        } catch (error) {
                console.log(error,'error')
        }


    }
}



export const getPublishConsoleConfiguration=()=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem('authUser'))
            const responseData = await urlSocket.post("incident-report/get-publish-console-config",{
                encrypted_db_url : authUser.db_info.encrypted_db_url
            })

            console.log(responseData,'responseData')
            dispatch(setAplnRequestLoading(false))
            if(responseData.status === 200){
                if(responseData.data.h_type.length >0 && responseData.data.h_type.length < 2){
                        dispatch(setSelectedPublishHId(responseData.data.h_type[0]._id))
                        dispatch(setEscalatedHlist(responseData.data.h_type))
                        dispatch(setSelectedDesignation(responseData.data.console_info.length >0 ? responseData.data.console_info[0]:{} ))
                }
                else if(responseData.data.h_type.length >0){
                    dispatch(setEscalatedHlist(responseData.data.h_type))
                }
            }
            else{
                dispatch(setAplnRequestError(responseData.statusText))
            }
            
        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
    }
}

export const updatePublishConsole=(values)=>{

    return async dispatch =>{
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        dispatch(setAplnRequestLoading(true))

        try {
            const responseData = await urlSocket.post("incident-report/publish-console",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                console_info : values
            })
            console.log(responseData,'responseData')
            dispatch(setAplnRequestLoading(false))
            if(responseData.status === 200){

            }

            
        } catch (error) {
            dispatch(setAplnRequestError(error))
        }


    }


}



export const submitIncidentInfo =(values)=>{


    return async dispatch =>{

        try {
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            dispatch(setAplnRequestLoading(true))

         const responseData = await urlSocket.post("incident-report/create-incident-report",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                incident_report : values
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData')
            dispatch(RaisedIncidentList())

            
        } catch (error) {
            dispatch(setAplnRequestError(error))
        }

    }

}


export const getIRMessages =(selectedApln)=>{
    const userData = JSON.parse(sessionStorage.getItem("authUser"));

    var userInfo = _.filter(selectedApln.task_users, { "user_id": userData.user_data._id })[0]

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const dbInfo = JSON.parse(sessionStorage.getItem("db_info"));
            const responseData = await urlSocket.post('incident-report/get-ir-message', {
                encrypted_db_url: dbInfo.encrypted_db_url,
                user_id: userData.user_data._id,
                task_id: selectedApln._id,
                user: userInfo
            })

            console.log(responseData,'responseData')

            if(responseData.status === 200){
                dispatch(setIrMessages(responseData.data.data))
            }
        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
    }
}




export const deletePublishedIncidents=(_id)=>{

    return async dispatch =>{

        try {
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))

            const responseData = await urlSocket.post('incident-report/delete-pubsh-incd-type',{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                _id : _id,
            })

            console.log(responseData,'responseData')
            dispatch(retrivePublishedIncidentTypeList())

            
        } catch (error) {
            
        }



    }


}




export const publishIncidentInfo =(publishIncidents)=>{

    return async dispatch =>{

        console.log(publishIncidents,'publishIncidents')

        try {
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            dispatch(setAplnRequestLoading(true))
            const responseData = await  urlSocket.post('incident-report/publish-incd-type-console',{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                publishIncidentInfo : publishIncidents,
                _id : publishIncidents._id
            })
            console.log(responseData,'responseData')
            dispatch(retrivePublishedIncidentTypeList())

            dispatch(setAplnRequestLoading(false))

            
        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
       
    }
}






export const retriveIncidentsList = ( item ) => {

    return async dispatch => {
        try {
            console.log('item 466 :>> ', item);
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                created_by: authUser.user_data._id,
                encrypted_db_url: authUser.db_info.encrypted_db_url ,
            }
            console.log('requestInfo :>> ', requestInfo);
            const responseData = await urlSocket.post("incident-report/retrive-Incidents-list", requestInfo)
            console.log(responseData, 'responseData 465')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(setIncidentsList(responseData.data.data))
                dispatch(setIncidentsListFilterInfo(responseData.data.filterInfo))
                // dispatch(setDupIncidentsList(responseData.data.data))
                // dispatch(set)
            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            console.log(error,'error')
            dispatch(setAplnRequestError(error))
        }
    }
}

export const getTreeFromFlatDataAsync = (FlatData) => {
    // FlatData
    console.log(FlatData)
    try {
      return new Promise((resolve, reject) => {
        try {
          const treeData = getTreeFromFlatData({
            flatData: FlatData,
            getKey: (node) => node.id,
            getParentKey: (node) => node.parent,
            rootKey: null,
            expanded: true
          });
          console.log(treeData, 'treeData')
          resolve(treeData);
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {

    }

  }



export const retriveIncidentsInfo = ( item ) => {

    return async dispatch => {
        try {
            console.log('item 466 :>> ', item);
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                created_by: authUser.user_data._id,
                encrypted_db_url: authUser.db_info.encrypted_db_url ,
                incident_id : item._id,
                h_id : item.incd_h_id
            }
            const responseData = await urlSocket.post("incident-report/retrive-Incidents-list", requestInfo)
            console.log(responseData, 'responseData 465')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(setIncidentsInfo(responseData.data.data[0]))
                dispatch(setFlatData(responseData.data.h_data))
                dispatch(setSeverityTypeList(responseData.data.severity_types))
                const hTreeData = await getTreeFromFlatDataAsync(responseData.data.h_data)
                dispatch(setDupHtreeData(hTreeData))
                dispatch(setDupTreeData(responseData.data.treeData))
                var dupFlatData = await treeDataToFlat(responseData.data.treeData?.treeData)
                dupFlatData = _.map(dupFlatData,'node')
                console.log(dupFlatData,'dupFlatData')
                var convertedFlatData = convertFlatDataToTreeData(responseData.data.h_data)
                console.log(convertedFlatData,'convertedFlatData')

                dispatch(setHtreeData(convertedFlatData))
            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            console.log(error,'error')
            dispatch(setAplnRequestError(error))
        }
    }
}


export const treeDataToFlat = async(treeData) => {
    var flatData = getFlatDataFromTree({
        treeData: treeData,
        getNodeKey,
        ignoreCollapsed: false,
    });
    var explicitData = _.filter(flatData, (item) => {
        return item;
    });
    return explicitData;
};



export const notifyIncidentSubmittedUser=(selectedActionplan)=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo ={
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                userInfo : selectedActionplan.incd_submitted_by,
                incidentInfo : selectedActionplan
            }

          const responseData = await urlSocket.post('incident-report/incident-report-convey-email',requestInfo)
            console.log(responseData,'response')
            dispatch(setAplnRequestLoading(false))

        } catch (error) {
                console.log(error,'error')
        }


    }

}



export const retriveIncidentSeverityList = ( item ) => {

    return async dispatch => {
        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const requestInfo = {
                created_by: authUser.user_data._id,
                encrypted_db_url: authUser.db_info.encrypted_db_url ,
                hierarchy_id : item.incd_h_id
            }
            console.log('requestInfo :>> ', requestInfo);
            const responseData = await urlSocket.post("incident-report/get-incd_severity-list", requestInfo)
            console.log(responseData, 'responseData 978')
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(setIncdSeverityTypeList(responseData.data.data))
            }
            else {
                dispatch(setAplnRequestError(responseData.statusText))
            }

        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
    }
}


export const RaisedIncidentList =()=>{

    return async dispatch=>{

        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        
        try {
            dispatch(setAplnRequestLoading(true))
          const responseData = await urlSocket.post("incident-report/raised-incident-reports",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                user_id : authUser.user_data._id,
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData')
            if(responseData.status === 200){
                dispatch(setRaisedIncidentList(responseData.data.data))
            }
            else{
                dispatch(setAplnRequestError(responseData.statusText))
            }


        } catch (error) {
            dispatch(setAplnRequestError(error))
        }
    }

}







export const {setDupHtreeData,
    setDashBoardData,
    seteditPublishedIncidentTypeList,
    setIncidentsListFilterInfo,
    setFilteredPublishedIncdType,
    setDupTreeData,setPublishedIncidentTypeList,setcloseStatus,setselectedEOPT,setRaisedIncidentList,setenableReason,addAplnMessages,setMarkerState,updateAplnData,setIrMessages,settaskUsers,setValidUser,setMyTaskList,setSelectedApln,setrespDesignation,setSelectedDesignation,setdesignationList,setSelectedPublishHId,setIncidentReportList,setIncidentsInfo,setIncidentsList,setHtreeData,setFlatData,setEscalatedHlist, seteditEscalationInfo, setMaxEscalationLvl,sethierarchyList,setincidentTypeExist,seteditSeverityTypeInfo, setseverityTypeExist, setAutomatedSeverityId, setSeverityTypeList, seteditIncidentTypeInfo, setEscalationList, setAutomatedIncidentId, setIncidentTypeList, setAplnRequestError, setAplnRequestLoading  , setIncdSeverityTypeList} = incdRptSlice.actions


export default incdRptSlice.reducer;