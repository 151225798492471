import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Components/ChartsDynamicColor";
import _ from "lodash";

const LineChart = (props) => {
  // Generate chart colors based on props
//   const LineApexChartColors = getChartColorsArray(JSON.stringify([
//     "--primary-color", // CSS variable for a custom color
//     "#00bfff",         // Hex code for blue
//     "rgba(75, 192, 192, 0.6)", // RGBA color for light blue
//     "--secondary-color", // Another CSS variable
//     "#ff6384"          // Another hex code for red
//   ]));




const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const LineApexChartColors = Array(props.lineChartData.length)
  .fill()
  .map(() => generateRandomColor());
console.log(LineApexChartColors,'PieApexChartColors');

  // Extract series and labels from props
  const series = [{
    name: 'Count',
    data: _.map(props.lineChartData, "count") // Assuming props.lineChartData contains value for y-axis
  }];
  
  const options = {
    chart: {
      height: 320,
      type: 'line',
      toolbar: {
        show: true, // Show the toolbar for download
        tools: {
          download: true, // Enable the download button
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: "line-chart-data",
            columnDelimiter: ',',
            headerCategory: 'Category',
            headerValue: 'Value',
          },
          svg: {
            filename: "line-chart-image",
          },
          png: {
            filename: "line-chart-image",
          }
        },
      },
    },
    series: series,
    labels: _.map(props.lineChartData, (item) => item.name || 'Unknown'), 
    xaxis: {
      categories: _.map(props.lineChartData, "name"), // Assuming props.lineChartData contains category for x-axis
      title: {
        text: 'Severity type',
      },
    },
    yaxis: {
      title: {
        text: 'count',
      },
    },
    colors: LineApexChartColors, // Dynamic colors
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
      itemMargin: {
        horizontal: 10, // Space between legend items horizontally
        vertical: 5,    // Space between legend items vertically
      },
    },
    stroke: {
      curve: 'smooth', // Makes the line chart smooth
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          height: 240
        },
        legend: {
          show: false
        },
      }
    }]
  };

  if (!props.lineChartData || props.lineChartData.length === 0) {
    return <div>No Data Available</div>;  // Return if no data
  }

  return (
    <ReactApexChart options={options} series={series} type="line" height="320" />
  );
}

export default LineChart;
