import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
import TagsInput from 'react-tagsinput'
import TableContainer from "../../../../common/TableContainer";
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"

import 'react-tagsinput/react-tagsinput.css'



const _ = require('lodash')
var urlSocket = require("../../../../helpers/urlSocket")

export default class LoodEndpointNode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            selectedEOPT: [],
            dup_selectedEOPT: [],
            all_selectedEOPT: []

        };

    }


    async componentDidMount() {
        var db_info = JSON.parse(sessionStorage.getItem('db_info'))
        var user_data = JSON.parse(sessionStorage.getItem('authUser'))
        this.setState({ db_info ,user_data : user_data.user_data}, () => { this.loadEOPTS() })
    }

    loadEOPTS = () => {
        this.setState({ tableDataloaded: false })
        try {
            urlSocket.post("cog/get-user-info", 
            {  db_info :  this.state.db_info,
                user_id :this.state.user_data._id
            }
            )
                .then((response) => {
                    this.setState({
                        statusBasedFilteredData: response.data.user_list,
                        tableDataloaded: true,
                        allowTableRowToSelect: true,
                        labelDefault: true,
                        label: {
                            endpoints: []
                        },


                    }, function () {
                        this.loadUserLabels()
                    })

                })
        } catch (error) {

        }
    }


    loadUserLabels() {
        try {
            urlSocket.post("userSettings/get-user-group", {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.props.userInfo._id,
                    company_id: this.props.userInfo.company_id
                }
            })
                .then(response => {
                    this.setState({
                        labelData: response.data.data,
                        dataLoaded: true

                    })
                })

        } catch (error) {
            console.log("catch error", error)
        }
    }

    labelSelected(data) {

        if (data.target.value === "all") {
            this.loadEOPTS()
        }
        else {

            var mylabel = this.state.labelData[data.target.value]

            this.setState({
                tableDataloaded: false, selectedLabel: mylabel._id,
                label: mylabel
            })

            try {

                urlSocket.post("userSettings/load-group-users", {
                    labelInfo: mylabel,
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.props.userInfo._id,
                        company_id: this.props.userInfo.company_id
                    }
                })
                    .then(response => {
                        this.setState({
                            statusBasedFilteredData: response.data.data,
                            labelDefault: false,
                            tableDataloaded: true
                        })
                    })

            } catch (error) {
                console.log("catch error", error)
            }

        }

    }

    getSelectedEOPT = (row, isSelect, rowIndex, e) => {
        this.props.endpoint_data.adt_users.push({
            user_id: row._id,
            name: row.firstname,
            designation: row.designation,
            user_code: row.user_code,
            audit_type: '1'
        })
        var getEOPTId = _.map(this.state.selectedEOPT, "_id")
        var getIndex = getEOPTId.indexOf(row._id)

        if (getIndex === -1) {
            this.state.selectedEOPT.push(row)
            var temp = this.state.all_selectedEOPT
            temp.push(row)
            this.setState({ all_selectedEOPT: temp })
            this.setState({
                selectedEOPT: temp
            }, () => { this.handleSelectAll(isSelect, 0, this.state.selectedEOPT) })
        }
        else {
            this.state.selectedEOPT.splice(getIndex, 1)
            this.setState({
                selectedEOPT: this.state.selectedEOPT
            }, () => { this.handleSelectAll(this.state.selectedEOPT) })
        }


    }

    addEndpoints() {
        // try {
        //     var loggedUserInfo = {
        //         encrypted_db_url: this.state.db_info.encrypted_db_url,
        //         company_id: this.props.userInfo.company_id,
        //         company_name: this.props.userInfo.company_name,
        //         created_by:this.props.userInfo._id
        //     }
        //     urlSocket.post("webphlbconf/addmultipleendpoints", {
        //         userInfo: loggedUserInfo,
        //         publishtemplateInfo: this.props.publishtemplateInfo,
        //         endpointInfo: this.state.dup_selectedEOPT
        //     })
        //     .then(response=>{
        //         if(response.data.response_code === 500)
        //         {
        //                 this.props.onClose()
        //         }
        //     })
        // } catch (error) {
        // }
    }


    handleSelectAll = (e, mode, rows) => {
        const isChecked = e;
        const data = mode == 1 ? this.state.statusBasedFilteredData : rows;
        // Create a new array of selected row keys
        const selectedRowKeys = isChecked ? data.map(item => item._id) : [];
        // Update the selectedEOPT state
        this.setState({
            selectedEOPT: selectedRowKeys,
            dup_selectedEOPT: isChecked ? data : [],
        });
    };



    render() {
        if (this.state.dataLoaded) {
            const { SearchBar } = Search;
            // const { users } = this.state
            const options = {
                // pageStartIndex: 0,

                sizePerPage: 7,
                totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
                custom: true,
            };
            const columns = [
                // {
                //     text: "id",
                //     dataField: "_id",
                //     sort: true,
                //     hidden: true,
                //     formatter: (cellContent, item) => (
                //         <>
                //             {item._id}
                //         </>
                //     ),
                // },
                {
                    accessor: 'firstname',
                    Header: 'Name',
                    filterable: true,
                    Cell: (cellContent, item) => {
                        return (
                            <>
                                <div className="d-flex " style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className=" font-size-14 ">
                                        {item.firstname}
                                    </div>
                                   
                                    <div className=" font-size-11 ">
                                        {item.designation}
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'usercode',
                    Header: 'User Code'
                },
                {
                    accessor: 'email_id',
                    Header: 'Email ID'
                },
                // {
                //     dataField: 'phone_number',
                //     text: 'Phone Number'
                // },
                // {
                //     dataField: 'created_on',
                //     isDummyField: true,
                //     hidden: true,
                //     text: 'updated on'
                // },


            ];
            const defaultSorted = [{
                dataField: 'created_on', // if dataField is not match to any column you defined, it will be ignored.
                order: 'desc' // desc or asc
            }];
            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true,
                selected: this.state.selectedEOPT, // Pass the selected row keys here
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.getSelectedEOPT(row, isSelect, rowIndex, e);
                },
                onSelectAll: (row, isSelect, rowIndex, e) => {
                    this.handleSelectAll(row, 0, isSelect)
                }
            };
            return (
                <React.Fragment>
                    <Container fluid>
                        <Row >
                            <Col >
                                <div className="px-5">
                                    <Row className="my-4">
                                        <div className="d-sm-flex align-items-center justify-content-between">
                                            <div className="text-danger font-size-18">Load Group User</div>
                                            <button className="btn btn-outline-dark" onClick={() => this.props.onClose()}> Close </button>
                                        </div>

                                        <hr className="my-4" />
                                    </Row>
                                    <Row>
                                        <AvForm className="form-horizontal" onValidSubmit={this.submitData} onInvalidSubmit={this.handleInvalidSubmit}>
                                            <div className="mb-1">
                                                <Label className="" htmlFor="autoSizingSelect">Select label</Label>
                                                <AvInput
                                                    type="select"
                                                    name="hlevel_cat"
                                                    label="Name"
                                                    value={null}
                                                    className="form-select"
                                                    id="cate"
                                                    required
                                                    onChange={(e) => this.labelSelected(e)}>
                                                    <option value="" disabled selected>Choose...</option>
                                                    <option value="all"  >ALL</option>
                                                    {
                                                        this.state.labelData.map((data, idx) => {
                                                            return (
                                                                <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
                                                            )
                                                        })
                                                    }
                                                </AvInput>
                                            </div>
                                        </AvForm>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="p-5">
                                {
                                    this.state.tableDataloaded ?

                                    <TableContainer
                                    columns={columns}
                                    data={this.state.statusBasedFilteredData}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    isJobListGlobalFilter={false}
                                    customPageSize={10}
                                    style={{ width: '100%' }}
                                    // dynamicBtn={true}
                                    // btnClick={(param1, param2) => this.navigateTo("",1)}
                                    // // dynamicParams={params1,params2}
                                    // btnName ={"Add User"}
      
                                />


                                        // <PaginationProvider
                                        //     keyField="id"
                                        //     data={this.state.statusBasedFilteredData}
                                        //     columns={columns}
                                        //     pagination={paginationFactory(options)}
                                        // >
                                        //     {
                                        //         ({
                                        //             paginationProps,
                                        //             paginationTableProps
                                        //         }) => (
                                        //             <ToolkitProvider
                                        //                 keyField="_id"
                                        //                 data={this.state.statusBasedFilteredData}
                                        //                 columns={columns}
                                        //                 search
                                        //             >
                                        //                 {
                                        //                     toolkitprops => (
                                        //                         <React.Fragment>
                                        //                             <Row className="mb-2" style={{ paddingTop: 10 }}>
                                        //                                 <Col sm="10">
                                        //                                     <div className="search-box ms-2 d-inline-block">
                                        //                                         <div className="position-relative">
                                        //                                             <i className="bx bx-search-alt search-icon" />
                                        //                                         </div>
                                        //                                     </div>
                                        //                                 </Col>
                                        //                                 <Col md={2}>
                                        //                                     <input type="checkbox" onClick={(e) => { this.handleSelectAll(e.target.checked, 1) }} /> &nbsp;

                                        //                                     <label>Select All</label>

                                        //                                 </Col>
                                        //                             </Row>
                                        //                             <hr className="my-2" />

                                        //                             <Row>
                                        //                                 <Col xl="12">
                                        //                                     <div className="table-responsive">
                                        //                                         <BootstrapTable
                                        //                                             {...toolkitprops.baseProps}
                                        //                                             {...paginationTableProps}
                                        //                                             defaultSorted={defaultSorted}
                                        //                                             selectRow={selectRow}
                                        //                                             classes={
                                        //                                                 "table align-middle table-nowrap table-hover"
                                        //                                             }
                                        //                                             bordered={false}
                                        //                                             striped={false}
                                        //                                             responsive
                                        //                                             ref={this.tableRef}
                                        //                                         />

                                        //                                     </div>
                                        //                                 </Col>
                                        //                             </Row>
                                        //                             <Row className="align-items-md-center mt-30">
                                        //                                 <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        //                                     <PaginationListStandalone
                                        //                                         {...paginationProps}
                                        //                                     />
                                        //                                 </Col>
                                        //                             </Row>
                                        //                         </React.Fragment>
                                        //                     )}
                                        //             </ToolkitProvider>
                                        //         )}
                                        // </PaginationProvider>
                                        
                                        
                                        : null
                                }
                                <Button color="primary" onClick={() => { this.addEndpoints() }} > Add Selected User</Button>
                            </div>

                        </Row>
                    </Container>
                </React.Fragment>
            )
        }
        else {
            return null
        }
    }
}