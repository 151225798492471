import React, { Component } from "react";
import { Container, Row, Col, Button, Card, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import { withRouter, Link } from "react-router-dom"
import Select from "react-select";
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import facility from '../FacilityScreen'
import "react-perfect-scrollbar/dist/css/styles.css";
import "./manageAudit.css";
import _ from 'lodash'
import Breadcrumbs from '../../../components/Common/Breadcrumb';
//import CardProject from "./card-project";
import { map } from "lodash"
//Import Card
import CardContact from "./card-contact"
import MetaTags from 'react-meta-tags';


import { TableHeader, PaginationComponent } from "../../../common/DataTable"
import FuzzySearch from '../../../common/FuzzySearch';
import urlSocket from "../../../helpers/urlSocket";
import TableContainer from "./Components/TableContainer";


// var urlSocket = require("../../helpers/urlSocket");



class ManagePublishedTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
          department_id: null,
          department_Name: null,
          Year_Name: null,
          depName: '',
          company_id: null,
          departmentArray: [],
          user_id: null,
          auditData: [],
          error_data: [],
          yearData: [],
          dataloaded: false,
          modal: false,
          isEdit: false,
          entitiesAuditData:[],

          search_files: [],
          dup_search_files: [],
          currentPage: 1,
          totalItems: 0,
          items_per_page: 10,
          temp_search_files: []
        }
        this.handleUserClicks = this.handleUserClicks.bind(this)
        this.handleValidAuditSubmit = this.handleValidAuditSubmit.bind(this)
      }
    
      configData
      userData
      async componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"))

        this.configData = data.config_data
        this.userData = data.user_data
        var protect_routes = await facility(user_facilities , 'mngpblhtempt')
        if(protect_routes !== undefined){
        this.setState({
          userInfo: data.user_data,
          db_info:db_info,
          protect_routes:protect_routes
        }, function () {
          this.getAuditMasterTemplates()
        })
      }
      else{
        this.props.history.push('/dashboard')
      }
      }
    
      toggle = () => {
        this.setState(prevState => ({
          modal: !prevState.modal,
        }))
      }
    
      handleUserClicks = arg => {
        this.setState({ users: '', isEdit: false, convertionMode:"0" })
        this.toggle()
      }
    
      useTemplateHandlerClick(data){
        this.setState({ users: '', isEdit: false, convertionMode:"2", convertionTemplateData:data })
        this.toggle()
      }
    
      useThisTemplateAs(data){
    
      }
    
     
    
    
      getAuditMasterTemplates() {
        try {
          urlSocket.post('/webmngpbhtmplt/getpublishedtemplate', {
            userInfo: {
              encrypted_db_url: this.state.db_info.encrypted_db_url,
              company_id: this.state.userInfo.company_id,
              user_id: this.state.userInfo._id,
            },
          })
            .then((response) => {
              console.log(response,'response')
              var non_published_count =[]
               _.filter(response.data.data,e=>{
                if(e.publish_status == "0"){
                  non_published_count.push(e)
                }
              })
              var published_count =[]
               _.filter(response.data.data,e=>{
                if(e.publish_status == "2"){
                  published_count.push(e)
                }
              })


              // this.setState({
              //   auditData: response.data.data,
              //   entitiesAuditData: response.data.data,
              //   dataloaded: true,
              //   non_published_count:non_published_count.length,
              //   published_count:published_count.length,

              //   total_audit: response.data.data.length,
              // });


              this.setState({
                auditData: response.data.data,
                entitiesAuditData: response.data.data,
                dataloaded: true,
                non_published_count: non_published_count.length,
                published_count: published_count.length,
                total_audit: response.data.data.length,

                search_files: response.data.data,
                dup_search_files: response.data.data,
                temp_search_files: response.data.data,
              }, () => {
                //  this.pageClick(1) 
                });

            });
        } catch (error) {
          console.log(error.toString());
        }
      }
    
      handleDeleteTemplate = (values) => {
        try {
          urlSocket.post("webmngpbhtmplt/deletepublishedtemplate",{
            templateInfo:{
              template_id:values._id
            },
            userInfo: {
              encrypted_db_url:this.state.db_info.encrypted_db_url,
              company_id: this.state.userInfo.company_id,
              created_by: this.state.userInfo._id,
            },
          })
          .then(response=>{
            if(response.data.response_code==500)
            {
              this.setState({
                auditData: response.data.data,
                dataloaded: true,
                confirm_both: false,
                success_dlg: true,
                dynamic_title: "Deleted",
                dynamic_description: "Template has been deleted.",
              })
            }
          })
        } catch (error) {
          console.log("catch error", error)
        }
      }
    
      dateConvertion = (dateToConvert) => {
        if (dateToConvert != null) {
          var date = typeof (dateToConvert) == "object" ? String(dateToConvert.toISOString()) : String(dateToConvert)
          var convertedDate = date.slice(8, 10) + ' / ' + (date.slice(5, 7)) + ' / ' + date.slice(0, 4);//prints expected format. 
          if (convertedDate == "01 / 01 / 1970") {
            return "-- / -- / --"
          }
          else {
            return convertedDate
          }
        }
        else {
          return "-- / -- / --"
        }
      }
    
    
      handleValidAuditSubmit = (e, values) => {
    
        const { isEdit, users, selectedUser } = this.state
    
        if(this.state.convertionMode == "2")
        {
          const templateNameInfo = {
            template_name: values["template_name"],
            template_info: values["template_info"]
          }
          this.convertTemplateAs(this.state.convertionTemplateData, templateNameInfo)
        }
        else
        {
          if (isEdit) {
    
          } else {
      
            const templateInfo = {
              template_name: values["template_name"],
              template_info: values["template_info"]
            }
            // save new audit
            this.createNewAudit(templateInfo)
          }
        }
    
        
    
        this.setState({ selectedUser: null })
        this.toggle()
      }
    
      createNewAudit = (data) => {
        try {
          urlSocket.post('/webmngpbhtmplt/crudpublishedtemplate', {
            userInfo: {
              company_id: this.state.userInfo.company_id,
              company_name: this.state.userInfo.company_name,
              user_id: this.state.userInfo._id,
            },
            templateInfo: {
              template_id:data._id != undefined ? data._id : undefined,
              template_name: data.template_name,
              template_info: data.template_info,
              tmplt_ctd_by: data.tmplt_ctd_by
            }
    
          })
            .then((response) => {
              if (response.data.response_code === 500) {
                this.navigateTo(response.data.data)
              }
              else {
                console.log("Not Created")
              }
    
            })
        } catch (error) {
          console.log(error.toString())
        }
      }
    
      
      convertTemplateAs(data, templateNameInfo){
    
        try {
          urlSocket.post('/webmngpbhtmplt/converttemplate', {
            userInfo: {
              company_id: this.state.userInfo.company_id,
              company_name: this.state.userInfo.company_name,
              user_id: this.state.userInfo._id,
            },
            templateInfo: {
              data,
              template_name: templateNameInfo.template_name,
              template_info: templateNameInfo.template_info,
            }
          })
            .then((response) => {
              if(response.data.response_code == 500)
              {
                this.navigateTo()
              }
            })
        } catch (error) {
          console.log("catch error", error)
        }
    
      }
    
      OnCreateAudit = (data) => {
        sessionStorage.removeItem("EditData");
        this.props.history.push("/crttmplt");
    
      }
    
      navigateTo = (data) => {
        if(this.state.navMode == "0") //publish
        {
          sessionStorage.removeItem("publishData");
          sessionStorage.setItem("publishData", JSON.stringify(data));
          this.props.history.push("/pblhcfg")
        }
        else if(this.state.navMode == "1")
        {
          sessionStorage.removeItem("EditPublishedData");
          sessionStorage.setItem("EditPublishedData", JSON.stringify(data));
          this.props.history.push("/edtpblhtempt");
        }
        else if(this.state.navMode == "3")
        {
          if(data.method_selected === "2"){
            sessionStorage.removeItem("publishData");
            sessionStorage.removeItem("EditPublishedData");
            sessionStorage.removeItem("publishedAuditData");
            sessionStorage.setItem("publishedAuditData", JSON.stringify(data));
            this.props.history.push("/hlvlpbdrpt");
            }
          else
          {
            sessionStorage.removeItem("publishData");
            sessionStorage.removeItem("EditPublishedData");
            sessionStorage.removeItem("publishedAuditData");
            sessionStorage.setItem("publishedAuditData", JSON.stringify(data));
            this.props.history.push("/pbdrpt");
          }
        }
        else if(this.state.navMode == "4")
        {
          sessionStorage.removeItem("publishData");
          sessionStorage.removeItem("EditPublishedData");
          sessionStorage.removeItem("publishedAuditData");
          sessionStorage.setItem("publishedAuditData", JSON.stringify(data));
          this.props.history.push("/adtaltclrprt");
        }
      }



      filterStatus=(sort)=>{
        var filteredData;

        if(sort == 'pub'){
          filteredData = _.filter(this.state.entitiesAuditData, { "publish_status": "2" })
        }
        else if(sort == 'non'){
          filteredData = _.filter(this.state.entitiesAuditData, { "publish_status": "0" })

        }
        else{
          filteredData = this.state.entitiesAuditData
        }
        // else{

        // }
        // this.setState({
        //   auditData:filteredData,
        //   sort :sort
        // })
        this.setState({
          auditData: filteredData,
          search_files: filteredData,
          dup_search_files: filteredData,
          temp_search_files: filteredData,
          sort: sort
        })

      }


      pageClick = (currentPage) => {
        this.setState({ currentPage }, () => { this.paginationProcess() })
    }

    paginationProcess = () => {
        let { currentPage, items_per_page, search_files, dup_search_files } = this.state
        let result = search_files.slice((currentPage - 1) * items_per_page, (currentPage - 1) * items_per_page + items_per_page)
        if (result.length > 0) {
            this.setState({ auditData: result })
        } else {
            this.setState({ auditData: search_files, search_files })
        }
    }

    getFuzzySearch = (search_files) => {
        // this.setState({ auditData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files })
       this.setState({ auditData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files },()=>{ this.pageClick(this.state.currentPage)})

        // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })
    }

      

  render() {
    if (this.state.dataloaded) {
      // const { SearchBar } = Search;
      // const { users } = this.state

      const { isEdit } = this.state
      const options = {
        // pageStartIndex: 0,

        sizePerPage: 10,
        totalSize: this.state.auditData.length, // replace later with size(users),
        custom: true,
      };

      const columns = [
        {
          accessor: 'template_name',
          Header: 'Audit Name',
          filterable: true,
          width:"30%",
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
                  <div className="fw-bold text-primary text-wrap" style={{ marginBottom: 5 }}>
                    {item.template_name}
                  </div>
                {/* </div> */}
              </>
            )
          }
        },
        {
          accessor: 'total_checkpoints',
          Header: 'Check points',
          filterable: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                {item.total_checkpoints}
              </>
            )
          }
        },
        {
          accessor: 'created_on',
          Header: 'Created on',
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                {this.dateConvertion(item.created_on)}
              </>
            )
          }
        },
        {
          accessor: 'publish_status',
          Header: 'Publish status',
          Cell: (cellProps) => {
            var item = cellProps.row.original
              return (
                <>
                <span className={item.publish_status == "0"?"badge badge-soft-secondary font-size-11 m-1":
                item.publish_status == "1"?"badge badge-soft-warning font-size-11 m-1":
                item.publish_status == "2"?"badge badge-soft-success font-size-11 m-1":"badge badge-soft-danger font-size-11 m-1"}
                >
                  {item.publish_status=="0"?"Not publish":item.publish_status=="1"?"Publish In progress":item.publish_status=="2"?"Published":"Stopped"}
                  </span>
                </>
              )
            }
          },
          {
            accessor: 'published_on',
            Header: 'Published on',
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <>
                  {this.dateConvertion(item.published_on)}
                </>
              )
            }
          },
          // this.state.protect_routes.read_write_checked == true &&
        {
          accessor: "menu",
          // isDummyField: true,
          // editable: false,
          disableFilters: true,
          Header: "Action",
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return(
            <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
              {item.publish_status != "1" && item.publish_status != "2"  && this.state.protect_routes.read_write_checked === true ?
              <div className="d-flex gap-3">
              <Link className="text-success" to="#" onClick={() => {this.setState({navMode:"0"},function(){this.navigateTo(item)})}}>Publish <i className="mdi mdi-publish font-size-18" id="publishtooltip" ></i></Link>
              <Link className="text-primary" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => {this.setState({navMode:"1"},function(){this.navigateTo(item)})}}></i></Link>
              <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ confirm_both: true, deleteItem: item })}></i></Link>
              </div>
              :
              <div>
                <Button type="button" disabled={this.state.protect_routes.read_write_checked === true ? false : true} color="primary" size="sm" className="waves-effect waves-light me-2" onClick={() => {this.setState({navMode:"3"},function(){this.navigateTo(item)})}}>
              View Detail
            </Button>
            <Button  type="button" color="primary" size="sm" className="waves-effect waves-light" onClick={() => {this.setState({navMode:"4"},function(){this.navigateTo(item)})}}>
              Report
            </Button>
            </div>
            }
              {/* <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light" onClick={() => { this.navigateTo(item) }}>
                View Detail
              </Button> */}
            </div>
            )
          },
        },
      ];
      const defaultSorted = [{
        dataField: 'hname', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
      }];
      return (
        <React.Fragment>
          <div className="page-content" >
            <MetaTags>
              <title>AuditVista | Published Templates</title>
            </MetaTags>
           
            <Container fluid>
            <Breadcrumbs title="Manage Audits" breadcrumbItem="Templates" />

              <Row >
                <Col lg="12">
                  <Card >
                    <CardBody>


                      <TableContainer
                        columns={columns}
                        data={this.state.auditData}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        isJobListGlobalFilter={false}
                        customPageSize={10}
                        style={{ width: '100%' }}
                        isPagination={true}
                        total_audit={this.state.total_audit}
                        published_count={this.state.published_count}
                        non_published_count={this.state.non_published_count}
                        filterStatus={(data) => this.filterStatus(data)}
                        sort={this.state.sort}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                      />
                      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              
              <Modal
                isOpen={this.state.modal}
                className={this.props.className}
              >
                <ModalHeader toggle={this.toggle} tag="h4">
                  {!!isEdit && this.state.convertionMode != "2" ? "Edit Template" : !isEdit && this.state.convertionMode == "2"?"Use Template as":"Add New Template"}
                </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={
                      this.handleValidAuditSubmit
                    }
                  >
                    <Row form>
                      <Col className="col-12">
                        <div className="mb-3">
                          <AvField
                            name="template_name"
                            label="Template Name"
                            type="text"
                            errorMessage="Invalid name"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <AvField
                            name="template_info"
                            label="Description"
                            type="textarea"
                            errorMessage="Invalid name"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </div>
                      </Col>

                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">

                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
              <Row>
              {this.state.confirm_both ? (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() =>
                          this.handleDeleteTemplate(this.state.deleteItem)
                        }
                        onCancel={() =>
                          this.setState({
                            confirm_both: false,
                            error_dlg: true,
                            dynamic_title: "Cancelled",
                            dynamic_description:
                              "Your imaginary file is safe :)",
                          })
                        }
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    ) : null}
                    {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
              </Row>
            </Container>
          </div>
        </React.Fragment>

        
      );
    }
    else {
      return null
    }
  }
}

export default ManagePublishedTemplate;
