

import React, { Component } from "react";
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
    Input,
} from "reactstrap";
// import Dropzone from "react-dropzone"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"
import MediaPreview from "./media_preview";



import moment from 'moment'

const _ = require('lodash')
var urlSocket = require("../../../../helpers/urlSocket")




export default class ReviewOPType extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataloaded: false,
            previousData: {},
            attachImages: [],
            attachDocuments:[],
            selectedOption:null,
            docfileStatus:"clear",
            fileStatus:"clear",
            doc_warning_enabled:false,
            warning_enabled:false,
        }
    }

    componentDidMount() {
      
    }


    

   

    render() {
        return (
                <Row className="my-4">
                    {/* <div className="d-flex flex-row justify-content-start" key={"bbtngroup" + this.props.index}> */}
                    <div className="d-flex flex-row" role="group" aria-label="Basic radio toggle button group">
                            {
                                this.props.options.map((item, idx) => {
                                    return (
                                        <div key={"optns"+String(idx)} className="me-2">
                                            {/* <div className={"font-size-14 p-2 text-white" }>{item.option_text}</div> */}
                                            <input type="radio" className="btn-check" 
                                            name={ "btnradio" + idx + idx} 
                                            id={ "btnradio" + idx + idx} 
                                            autoComplete="off" 
                                            checked={item.is_selected} 
                                            onChange={() => { this.onSelected(item) }} 
                                            disabled={true}
                                            />
                                            <label className={item.is_selected ? "btn bg-primary text-white" : "btn btn-outline-primary"} htmlFor={ "btnradio" + idx + idx}><span>{item.option_text} {" "} {item.enable_img ? <i className='mdi mdi-camera-plus' /> : null}{item.enable_video ? <i className='mdi mdi-video-plus' /> : null} {" "} {item.enable_doc ? <i className='mdi mdi-file-document' /> : null}</span></label>
                                            {/* <label className="btn btn-outline-primary" htmlFor={ "btnradio" + idx + idx}><span>{item.option_text} {" "} {item.enable_img ? <i className='mdi mdi-camera-plus' /> : null} {" "} {item.enable_doc ? <i className='mdi mdi-file-document' /> : null}</span></label> */}

                                        </div>
                                    )
                                })
                            }
                            </div>
                    {/* </div>  */}
                </Row>


        )
    }

}