import React, { Component } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Reactstrap

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import profileLogoText from '../../assets/images/20230526_151501.jpg'

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

import { Row, Col } from "reactstrap";
import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/180@3x copy.png";
import logoDark from "../../assets/images/logo-dark.png";
import auditvista_logo from "../../assets/images/auditvista_logo.png";


//to get users location 
import axios from 'axios'


class Header extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var date =
      today.getDate() + '/ ' + (today.getMonth() + 1) + '/ ' + today.getFullYear();

    this.state = {
      isSearch: false,
      showTime: this.formatTime(today.getHours(), today.getMinutes(), today.getSeconds()),
      currentDate: date,
      countryName: '',
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  /**
   * Toggle sidebar
   */

  addLeadingZero(number) {
    return number < 10 ? '0' + number : number.toString();
  }

  formatTime(hours, minutes, seconds) {
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    hours = this.addLeadingZero(hours);
    minutes = this.addLeadingZero(minutes);
    seconds = this.addLeadingZero(seconds);
    return `${hours}:${minutes}:${seconds} ${ampm}`;
  }


  // formatTime(hours, minutes, seconds) {

  //   const ampm = hours >= 12 ? 'PM' : 'AM';
  //   hours = hours % 12 || 12; 
  //   return `${hours}:${minutes}:${seconds} ${ampm}`;
  // }

  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      this.setState({
        countryName: data.country_name,

      });
    }).catch((error) => {
      console.log(error);
    });
  };


  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  componentDidMount() {
    // Update time every second
    this.intervalID = setInterval(() => {
      const date = new Date();
      this.setState({
        showTime: this.formatTime(date.getHours(), date.getMinutes(), date.getSeconds()),
      });
    }, 1000);
    // this.getGeoInfo();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }


  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  render() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    // console.log(data,'data')
    return (
      <React.Fragment>
        <header id="page-topbar" style={{backgroundColor:"#fff", boxShadow:"none"}}>
          <div className="navbar-header" style={{height:50}}>

            <div className="d-flex"></div>

            <Link to="/Dashboard" className="logo logo-light" >
              <span className="logo-sm">
                <img src={logoLightSvg} alt="" height="25" />
              </span>
              <span className="logo-lg">
                <img src={auditvista_logo} alt="" height="25" />
              </span>
            </Link>


          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(Header);