import React, { Component } from 'react'
import SearchField from "react-search-field";
import Fuse from 'fuse.js';
import { Col, Container, Row} from "reactstrap";


export default class FuzzySearch extends Component {
    constructor(props) {
        super(props);
        this.state = {

            currentPage_stock: 1,
            items_per_page_stock: 100,
            // sorting: { field: "", order: "" },
        };
        this.fuse = new Fuse([], {
            keys: this.props.keyword,
            threshold: 0.2,
            includeScore: true,
            ignoreLocation: true,
        });
    }



    componentDidMount() {
        
    }

    onSearch(search) {        
        this.setState({ search: search.trim().toLowerCase() }, () => { this.dataListProcess() });
    }

    dataListProcess() {
        let { search, currentPage_stock, items_per_page_stock } = this.state;
        var search_files = this.props.search_files
        // console.log(search_files,'search_files')
        if (search) {            
            const result = this.fuse.search(search);
            search_files = result.map((r) => r.item);
            // console.log('ifffff', search_files)
        } else {            
            search_files = this.props.temp_search_files;
            // console.log('elseee', this.props.dup_search_files, search_files)
        }
        const result = search_files.slice((currentPage_stock - 1) * items_per_page_stock, (currentPage_stock - 1) * items_per_page_stock + items_per_page_stock);        
        this.props.getFuzzySearch(result)
    }

    highlightKeywords(text, search) {
        
        const keywords = search.split(' ');
       
        const regex = new RegExp(keywords.join('|').replace(/\[/g, '\\['), 'gi');
        
        const matches = text.match(regex);
        if (matches) {
            const highlighted = text.replace(regex, (match) => `<label style="background-color: yellow; color: red; text-decoration: underline;">${match}</label>`);
            return highlighted;
        } else {
            return null;
        }
    }




    render() {
        this.fuse.setCollection(this.props.dup_search_files);
        return (
            <div> 
                {/* FuzzySearch */}
                {/* <Row>
                    <Col md={12}>
                        <SearchField
                            placeholder="Search Name"
                            onChange={(e) => this.onSearch(e)}
                            classNames="test-class w-100"
                        />
                    </Col>
                </Row> */}

                <Row>
                    <Col md={12} className="rounded-container" style={{width:"97%"}}>
                        <SearchField
                            placeholder="Search Name"
                            onChange={(e) => this.onSearch(e)}
                            classNames="test-class w-100"
                        />
                    </Col>
                </Row>

            </div>
        )
    }
}