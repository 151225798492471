import React, { Component } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Modal, Row, Button } from "reactstrap";
import WebcamCapture from "../../pages/Audit/UserAudit/Components/WebCam_Comp";
import { UploadOutlined } from '@ant-design/icons'
import { Dropdown } from 'react-bootstrap';
import Webcam from 'react-webcam';
import urlSocket from "../../helpers/urlSocket";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';



class ImageCrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      showModal: false,
      croppedBlobUrl: null,
      takephoto: false,
      show_src: false,
      webcamCapturedBlob: null,

      userImg: []
    };
    this.cropperRef = React.createRef();
    this.webcamRef = React.createRef();
  }

  handleCapture = (blob) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      this.setState({ src: event.target.result, showModal: true });
    };
    reader.readAsDataURL(blob);
  };

  captureImage = () => {
    const screenshot = this.webcamRef.current.getScreenshot();
    if(screenshot !== null){
    // console.log('screenshot', screenshot)
    const blob = this.dataURLToBlob(screenshot);
    this.setState({ webcamCapturedBlob: blob, showModal: true, takephoto: false });
    this.handleCapture(blob); // Simulate handling the captured image as if it's uploaded
  }else{
    console.log('Waiting for camera....')
  }
  };


  dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };


  handleFileChange = (e) => {
    console.log('event', e.target.files[0])
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ image_name: e.target.files[0].name })
      const reader = new FileReader();
      reader.onload = (event) => {
        this.setState({ src: event.target.result, showModal: true });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  // handleCrop = () => {
  //   const croppedCanvas = this.cropperRef.current.cropper.getCroppedCanvas();
  //   croppedCanvas.toBlob((blob) => {
  //     const croppedBlobUrl = URL.createObjectURL(blob);
  //     this.setState({ croppedBlobUrl, showModal: false });
  //   });    
  // };



  handleDropdownSelect = (eventKey) => {
    if (eventKey === 'takePhoto') {
      this.setState({ takephoto: true })
    } else if (eventKey === 'browseGallery') {
      this.openFilePicker()
    }
  };

  openFilePicker = () => {
    console.log('openFilePicker called...')
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = this.handleFileChange;
    input.click();
  };

  onHideToggle = () => {
    this.setState({ takephoto: false })
  }



  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  handleCrop = () => {
    const croppedCanvas = this.cropperRef.current.cropper.getCroppedCanvas();
    console.log('handleCrop called...')
    var imageName
    if (this.state.image_name === undefined) {
      imageName = 'captured-image.jpg'
    } else {
      imageName = this.state.image_name
    }
    croppedCanvas.toBlob((blob) => {
      const croppedBlobUrl = URL.createObjectURL(blob);
      const croppedFile = new File([blob], imageName, {
        type: 'image/jpeg',
      });

      // Assign properties to the cropped file
      Object.assign(croppedFile, {
        preview: croppedBlobUrl,
        formattedSize: this.formatBytes(croppedFile.size),
        uploading: false,
        filetype: croppedFile.type,
        uploadingStatus: 'Not uploaded',
        originalName: imageName,
        captured_on: new Date(),
        path: imageName,
      });
      console.log('croppedFile', croppedFile)
      this.setState({ croppedBlobUrl: null, showModal: false });
      this.props.onSubmitProfileInfo(croppedFile)

    });
  };

 

  render() {
    const { src, showModal, croppedBlobUrl } = this.state;
    console.log('userImg', this.props.userImg)
    return (
      <div>
        {/* <input type="file" accept="image/*" onChange={this.handleFileChange} /> */}

        {/* {
          this.state.userImg.length > 0 ?
            <img src={this.props.base_url + this.state.userImg[0].originalname} alt="profile" className="rounded-circle header-profile-user avatar-lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102px', width: '102px', marginBottom: '8px', marginInlineEnd: '8px' }} />
            :

            <>
              {
                croppedBlobUrl ?
                  <img onClick={this.handleImageClick} src={croppedBlobUrl} alt="Cropped" className="rounded-circle header-profile-user avatar-lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102px', width: '102px', marginBottom: '8px', marginInlineEnd: '8px' }} />
                  :
                  <div onClick={(e) => e.stopPropagation()} className=" rounded-circle header-profile-user avatar-lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102px', width: '102px', marginBottom: '8px', marginInlineEnd: '8px' }}>
                    <Dropdown onSelect={this.handleDropdownSelect}>
                      <Dropdown.Toggle variant="white" id="dropdown-basic" style={{ border: 'none' }} className="hide-dropdown-arrow">
                        <h3>
                          <UploadOutlined />
                        </h3>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="takePhoto">Take Photo</Dropdown.Item>
                        <Dropdown.Item eventKey="browseGallery">Browse Gallery</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
              }
            </>
        } */}

      {
        console.log('this.props.userImg', this.props.userImg, croppedBlobUrl)
      }

        <div onClick={(e) => e.stopPropagation()}>
          {/* <div onClick={(e) => e.stopPropagation()} className=" rounded-circle header-profile-user avatar-lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102px', width: '102px', marginBottom: '8px', marginInlineEnd: '8px' }}> */}
          <Dropdown onSelect={this.handleDropdownSelect}>
            <Dropdown.Toggle variant="white" id="dropdown-basic" className="hide-dropdown-arrow rounded-circle header-profile-user avatar-lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102px', width: '102px', marginBottom: '8px', marginInlineEnd: '8px', border: '1px solid rgb(164 164 164 / 27%)' }}>

              {
                this.props.userImg.length > 0 ?
                  <img src={this.props.base_url + this.props.userImg[0].originalname} alt="profile" className="rounded-circle header-profile-user avatar-lg" style={{width: '100%'}}/>
                  // <img src={this.props.base_url + this.props.userImg[0].originalname} alt="profile" className="rounded-circle header-profile-user avatar-lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102px', width: '102px', marginBottom: '8px', marginInlineEnd: '8px' }} />
                  :
                  croppedBlobUrl ?
                    <img onClick={this.handleImageClick} src={croppedBlobUrl} alt="Cropped" className="rounded-circle header-profile-user avatar-lg" style={{width: '100%'}}/>
                    :
                    <h3> <UploadOutlined /> </h3>
              }

            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="takePhoto">Take Photo</Dropdown.Item>
              <Dropdown.Item eventKey="browseGallery">Browse Gallery</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>




        {/* <button onClick={this.openModal}>Open Crop Modal</button> */}
        {src && (
          <Modal isOpen={showModal} onRequestClose={this.closeModal} contentLabel="Crop Modal">
            <div className="modal-header">
              <h5 className="modal-title">Crop Image</h5>
              <button type="button" className="close" onClick={() => this.closeModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                <Cropper
                  ref={this.cropperRef}
                  src={src}
                  style={{ height: 400, width: '100%' }}
                  initialAspectRatio={1}
                  guides={false}
                />
              </div>
            </div>
            <div className="modal-footer">
            <Button className="btn btn-sm" color={"danger"} onClick={this.closeModal} outline>Cancel</Button>
              <Button className="btn btn-sm" color={"success"} onClick={this.handleCrop} outline>Crop</Button>
            </div>
          </Modal>
        )}


        {
          this.state.takephoto &&
          <>
            <label>Take photo</label>
            <Modal isOpen={this.state.takephoto} toggle={() => this.setState({ takephoto: true })} style={{ maxWidth: '600px' }}>
              <div className="modal-header">
                <h5 className="modal-title">Take Photo</h5>
                <button type="button" className="close" onClick={() => this.onHideToggle()}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Webcam
                    audio={false}
                    ref={this.webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{ facingMode: 'user' }}
                    width={500}
                  />
                </div>
                {/* <button onClick={this.captureImage} className='mt-2'>Capture Image</button> */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                <Button onClick={this.captureImage} color='primary' className='btn-sm  mt-4' outline >
                  <FontAwesomeIcon icon={faCamera}  />
                  <span style={{ marginLeft: '5px' }}>Capture</span>
                </Button>
                </div>
              </div>
            </Modal>
          </>
        }
      </div>
    );
  }
}
export default ImageCrop;











// import React, { Component } from 'react';
// import Cropper from 'react-cropper';
// import 'cropperjs/dist/cropper.css'; // Import the CSS file

// class ImageCrop extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       src: null,
//       croppedBlobUrl: null,
//     };
//     this.cropperRef = React.createRef();
//   }

//   handleFileChange = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       const reader = new FileReader();
//       reader.onload = (event) => {
//         this.setState({ src: event.target.result });
//       };
//       reader.readAsDataURL(e.target.files[0]);
//     }
//   };

//   handleCrop = () => {
//     const croppedCanvas = this.cropperRef.current.cropper.getCroppedCanvas();
//     croppedCanvas.toBlob((blob) => {
//       const croppedBlobUrl = URL.createObjectURL(blob);
//       console.log('croppedBlobUrl', croppedBlobUrl)
//       this.setState({ croppedBlobUrl });
//     });
//   };

//   render() {
//     const { src, croppedBlobUrl } = this.state;

//     return (
//       <div>
//         <input type="file" accept="image/*" onChange={this.handleFileChange} />
//         {src && (
//           <div>
//             <Cropper
//               ref={this.cropperRef}
//               src={src}
//               style={{ height: 400, width: '100%' }}
//             />
//             <button onClick={this.handleCrop}>Crop</button>
//           </div>
//         )}
//         {croppedBlobUrl && (
//           <img src={croppedBlobUrl} alt="Cropped" style={{ maxWidth: '100%' }} />
//         )}
//       </div>
//     );
//   }
// }

// export default ImageCrop;








