import React, { Component } from "react"
import { isEmpty } from "lodash"
import MetaTags from 'react-meta-tags';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import CryptoJS from 'crypto-js'
import { Row, Col, CardBody, Card, Container, Alert, Button, Input } from "reactstrap"
import { LoadingOutlined } from '@ant-design/icons';
import facility from '../FacilityScreen'
import _ from "lodash";
// var urlSocket = require("../../helpers/urlSocket");
import urlSocket from "../../../helpers/urlSocket";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { message } from "antd";


class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataloaded: false,
            alertEnable: false,
            formDisabled: false,
            userInfo: {},
            given_email: '',
            secret_key: 'Pkdh347ey%3Tgs',
            invalid_user: false,
            invalid_phone_num: false,
            invalid_usercode: false,
            loading:false,
            country_code_err:false,
            countrycode :"+91",
            show:true

        };
    }
    async componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0]
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"));
        
        
        var protect_routes = await facility(user_facilities ,'murs' )
        if(protect_routes!== undefined){
        this.setState(
            {
                client_info: client_info,
                db_info: db_info,
                sessionUserInfo: data,
                dataloaded: true,

            },
            function () {
                //this.getEntityDetaitedData()
            }
        )
    }
    else{
        this.props.history.push('/dashboard')
    }
    }


    handleValidSubmit = (event, values) => {
        var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
        values["password"] = password
        values["confirmpassword"] = password
        values["userPoolId"] = this.state.client_info.userPoolId
        values["clientId"] = this.state.client_info.clientId
        values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        values["db_name"] = this.state.db_info.db_name
        values["facilities"] = this.state.client_info.facilities
        values["company_id"] = this.state.sessionUserInfo.client_info[0]._id
        values["created_by"] = this.state.sessionUserInfo.user_data._id
        values["short_name"] = this.state.client_info.short_name
        values["user_id"] = this.state.sessionUserInfo.user_data._id


        if (this.state.countrycode === undefined) {
            this.setState({ country_code_err: true })
        }
        else {
            values["countrycode"] = this.state.countrycode
            this.setState({ country_code_err: false })

        }
        console.log(this.state.invalid_user,this.state.invalid_phone_num,this.state.country_code_err,values.countrycode)
        if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false && values["countrycode"] !== undefined) {
            this.setState({loading:true})

            try {
                urlSocket.post('cog/cruduser', values).then((res) => {
                    if (res.data.response_code === 500) {
                        this.setState({ loading: false })
                        this.props.history.push('/murs')
                    }
                })

            } catch (error) {
                console.log(error, 'error')
            }

        }

        //    values[] 


    }

    // email = (e) => {
    //     let input = e.target
    //     let email = e.target.value.trim()

    //     this.setState({ email_address: email })
    //     if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    //         this.setState({ validation: false })
    //     } else {
    //         this.setState({ validation: true });
    //         this.setState({ errorMessage: 'Please enter correct email adress' })
    //     }

    //     if (email.length === 0) {
    //         this.setState({ email_address_invalid: true })
    //     }
    //     else {
    //         this.setState({ email_address_invalid: false })

    //     }



    // }

    emai_addrs = (e) => {
        var email = e.target.value
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            this.setState({ given_email: email , errorMessage : false})
        }
        else{
            this.setState({ errorMessage: true ,given_email: email  })
        }

    }


    validate_email = (e, mode) => {
        var admn_info = {}
        if (mode == 1) {
            admn_info["email_id"] = e.target.value
        }
        if (mode === 2) {
            admn_info["phone_number"] = e.target.value
        }
        if (mode === 3) {
            admn_info["usercode"] = e.target.value
        }
        admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        admn_info["db_name"] = this.state.db_info.db_name
        admn_info["user_id"] = this.state.sessionUserInfo.user_data._id


        try {
            urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
                if (mode == 1) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_user: true })
                    }
                    else {
                        this.setState({ invalid_user: false })
                    }
                }
                if (mode == 2) {

                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_phone_num: true })
                    }
                    else {
                        this.setState({ invalid_phone_num: false })
                    }
                }
                if (mode === 3) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_usercode: true })
                    }
                    else {
                        this.setState({ invalid_usercode: false })
                    }


                }
            })

        } catch (error) {

        }


    }

    // handleValidSubmit = (event, values) => {
    //     try {
    //         var loggedUserInfo = {
    //             company_id: this.state.sessionUserInfo.company_id,
    //             company_name: this.state.sessionUserInfo.company_name,
    //             _id:this.state.sessionUserInfo._id
    //         }
    //         urlSocket.post("webUsers/cruduser", {
    //             loggedUserInfo: loggedUserInfo,
    //             newUserInfo: values
    //         })
    //             .then((response) => {
    //                 if (response.data.response_code == 504) {
    //                     this.setState({ formDisabled: false, alertEnable: true, alertColor: "danger", message: response.data.message })
    //                 }
    //                 else
    //                     if (response.data.response_code == 500) {
    //                         this.setState({
    //                             first_name: response.data.data.first_name,
    //                             formDisabled: true,
    //                             alertEnable: true,
    //                             alertColor: "success",
    //                             message: response.data.message
    //                         })
    //                     }
    //             })
    //     } catch (error) {

    //     }
    // }
    select_country=(e)=>{
        this.setState({countrycode:e.target.value})
    }

    gotoBack = () => {
        console.log(sessionStorage.getItem("map_location_page"))
        if(sessionStorage.getItem("map_location_page") =="true"){
            this.props.history.push('/map-user') 
        }
       else if(sessionStorage.getItem("redirect") =="1"){
            this.props.history.push('/mpusr') 
        }
        else{
            this.props.history.push('/murs') 
        }
        // this.props.history.push('/murs')
    }

    render() {
        if (this.state.dataloaded) {
            return (
                <div className="page-content">
                <MetaTags>
                        <title>AuditVista | Add New User</title>
                    </MetaTags>

                        <Container fluid>

                            <Breadcrumbs
                                title="Add New User"
                                breadcrumbItem="New User"
                                isBackButtonEnable={true}
                                gotoBack={() => this.gotoBack()}
                            />


                            <Row >
                                <Col >
                                    <Card className="overflow-hidden">
                                        {
                                            !this.state.formDisabled ?
                                                <CardBody className="pt-0 ">
                                                    <div className="p-2 ">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


                                                            <Row className="my-4">
                                                                <div className="text-primary font-size-15">User Information</div>
                                                                <hr className="my-2 border-secondary opacity-50" />
                                                            </Row>

                                                            <div className="col-8">

                                                            <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3" >
                                                                        <label>Full Name :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="firstname"
                                                                            // label="First Name"
                                                                            className="form-control"
                                                                            placeholder="Enter First Name"
                                                                            type="text"
                                                                            required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                <div className="mb-3" >
                                                                        <label>Email Id :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="email_id"
                                                                            onChange={(e) => { this.emai_addrs(e); this.validate_email(e, 1) }}            
                                                                         // label="User Email"                                                                           
                                                                            onKeyDown={(e)=>{e.key == "Enter"? e.preventDefault() :console.log("e.preventDefault()") }}
                                                                            // onBlur={(e) => { this.emai_addrs(e) }}
                                                                            // onChange={(e) => { this.validate_email(e, 1) }}
                                                                            // label="User Email"
                                                                            className="form-control"
                                                                            placeholder="Enter User Email"
                                                                            type="email"
                                                                            required
                                                                        />
                                                                        {
                                                                            this.state.errorMessage &&
                                                                            <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>
                                                                        }
                                                                        {
                                                                            this.state.invalid_user &&
                                                                            <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="col-6">
                                                                    <Row>
                                                                        <label>Phone Number:<span className="text-danger"> *</span></label>
                                                                        <Col md={3} className="pe-0">
                                                                            <select name="countrycode" onChange={(e) => { this.select_country(e) }} className="form-select" required>
                                                                                <option value="" disabled={true}>Select</option>
                                                                                {
                                                                                    this.state.client_info.countries.map((c, idx) => (
                                                                                        <option key={idx} value={c.code}>
                                                                                            {c.code}{""}&nbsp;{c.label}
                                                                                        </option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </Col>
                                                                        <Col md={9} className="ps-0">
                                                                            <AvField
                                                                                name="phone_number"
                                                                                className="form-control"
                                                                                placeholder="Enter Phone number"
                                                                                type="number"
                                                                                onKeyPress={(e) => {
                                                                                    if (!/[0-9]/.test(e.key)) {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                                // onBlur={(e) => { this.validate_email(e, 2) }}
                                                                                onKeyDown={(e)=>{e.key == "Enter"? e.preventDefault() :console.log("e.preventDefault()") }}
                                                                                onChange={(e) => { this.validate_email(e, 2)                                                                            }}
                                                                                validate={{
                                                                                    required: { value: true },
                                                                                    minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                    maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                    pattern: {
                                                                                        value: /^[0-9]+$/,
                                                                                        message: 'Number only allow',
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {
                                                                                this.state.invalid_phone_num &&
                                                                                <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
                                                                            }
                                                                        </Col>
                                                                    </Row>                                                                 
                                                                </Col>
                                                            </Row>


                                                            </div>









                                                            {/* <Row>
                                                                <Col className="col-12">
                                                                <div className="mb-3">
                                                                        <label>Phone Number:<span className="text-danger"> *</span></label>
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <select name="countrycode" onChange={(e)=>{this.select_country(e)}} className="form-select" required>
                                                                                    <option value="">Select</option>
                                                                                    {
                                                                                        this.state.client_info.countries.map((c,idx)=>(
                                                                                            <option key={idx} value={c.code}>
                                                                                                {c.code}{""}&nbsp;{c.label}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <AvField
                                                                                    name="phone_number"
                                                                                    // label="Phone Number"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Phone number"
                                                                                    type="number"
                                                                                    onBlur={(e) => { this.validate_email(e, 2) }}
                                                                                    validate={{
                                                                                        required: { value: true },
                                                                                        minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                        maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                    }}
                                                                                />
                                                                                {
                                                                                    this.state.invalid_phone_num &&
                                                                                    <div className='text-danger' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                    this.state.country_code_err &&
                                                                    <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


                                                                }
                                                                </Col>
                                                            </Row> */}
                                                            {/* <Row>
                                                                <Col className="col-4">
                                                                    <div className="mb-3" >
                                                                        <AvField
                                                                            name="city"
                                                                            label="City"
                                                                            className="form-control"
                                                                            placeholder="Enter City"
                                                                            type="text"
                                                                        //required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-4">
                                                                    <div className="mb-3" >
                                                                        <AvField
                                                                            name="state"
                                                                            label="State"
                                                                            className="form-control"
                                                                            placeholder="Enter State"
                                                                            type="text"
                                                                        // required
                                                                        />
                                                                    </div>
                                                                </Col>

                                                                <Col className="col-4">
                                                                    <div className="mb-3" >
                                                                        <AvField
                                                                            name="country"
                                                                            label="Country"
                                                                            className="form-control"
                                                                            placeholder="Enter Country"
                                                                            type="text"
                                                                        // required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row> */}
{/* 
                                                            <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3" >
                                                                        <label>Email Id :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="email_id"
                                                                            onChange={(e) => { this.emai_addrs(e) }}
                                                                            onBlur={(e) => { this.validate_email(e, 1) }}
                                                                            // label="User Email"
                                                                            className="form-control"
                                                                            placeholder="Enter User Email"
                                                                            type="email"
                                                                            required
                                                                        />
                                                                        {
                                                                            this.state.invalid_user &&
                                                                            <div className='text-danger' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
                                                                        }
                                                                    </div>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <label>Phone Number:<span className="text-danger"> *</span></label>
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <select name="countrycode" onChange={(e)=>{this.select_country(e)}} className="form-select" required>
                                                                                    <option value="">Select</option>
                                                                                    {
                                                                                        this.state.client_info.countries.map((c,idx)=>(
                                                                                            <option key={idx} value={c.code}>
                                                                                                {c.code}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <AvField
                                                                                    name="phone_number"
                                                                                    // label="Phone Number"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Phone number"
                                                                                    type="number"
                                                                                    onBlur={(e) => { this.validate_email(e, 2) }}
                                                                                    validate={{
                                                                                        required: { value: true },
                                                                                        minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                        maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                                                    }}
                                                                                />
                                                                                {
                                                                                    this.state.invalid_phone_num &&
                                                                                    <div className='text-danger' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                    this.state.country_code_err &&
                                                                    <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


                                                                }
                                                                </Col>                             

                                                            </Row> */}

                                                            {/* <Row className="my-4">
                                                                <div className="text-danger font-size-18">Company Information</div>
                                                                <hr className="my-4" />
                                                            </Row>

                                                            <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="qualification"
                                                                            label="Qualification"
                                                                            type="text"
                                                                            // required
                                                                            placeholder="Enter Qualification"
                                                                        //value = {this.state.userInfo.address_1}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="doj"
                                                                            label="Date of Joining"
                                                                            type="text"
                                                                            // required
                                                                            placeholder="Enter Date of Joining"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <label>Designation :</label>
                                                                        <AvField
                                                                            name="designation"
                                                                            // label="Designation"
                                                                            type="text"
                                                                            placeholder="Enter Designation"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="experience"
                                                                            label="Experience"
                                                                            type="number"
                                                                            // required
                                                                            placeholder="Enter Experience"
                                                                        //value = {this.state.userInfo.address_1}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="usercode"
                                                                            label="User Code"
                                                                            type="text"
                                                                            placeholder="Enter User Code"
                                                                            onBlur={(e) => this.validate_email(e, 3)}
                                                                        />
                                                                        {
                                                                            this.state.invalid_usercode &&
                                                                            <div className="text-danger" style={{ fontSize: 'smaller' }}>User Code already exist for another user</div>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row> */}

                                                            <Row className="my-4">
                                                                <div className="text-primary font-size-15">Login Information</div>
                                                                <hr className="my-2 border-secondary opacity-50" />
                                                            </Row>

                                                            {/* <Row>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <label>Username :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="username"
                                                                            // label="Username"
                                                                            type="text"
                                                                            required
                                                                            placeholder="Enter username"
                                                                            disabled={true}
                                                                            value={this.state.given_email}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <label>Password :<span className="text-danger"> *</span></label>
                                                                        <AvField
                                                                            name="password"
                                                                            // label="Password"
                                                                            autoComplete ="new-password"
                                                                            type="password"
                                                                            required
                                                                            placeholder="Enter password"
                                                                            validate={{
                                                                                required: { value: true, errorMessage: 'Password is required' },
                                                                                minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
                                                                                pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Col> */}
                                                                {/* <Col className="col-6">
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name="confirmpassword"
                                                                            label="Confirm Password"
                                                                            type="password"
                                                                            required
                                                                            placeholder="Enter Confirm password"
                                                                        />
                                                                    </div>
                                                                </Col> */}
                                                            {/* </Row> */}
                                                                
                                                        <div className="col-8">
                                                            <Row className="align-items-center">
                                                                <Col xs={6} md={6} className="mb-3">
                                                                    <label>Username :<span className="text-danger"> *</span></label>
                                                                    <AvField
                                                                        name="username"
                                                                        type="text"
                                                                        required placeholder="Enter username"
                                                                        disabled={true}
                                                                        value={this.state.given_email} />
                                                                </Col>
                                                                <Col xs={6} md={6} className="mb-3 ">
                                                                    <label>Password :<span className="text-danger"> *</span></label>
                                                                    <Row noGutters>
                                                                        <Col xs={10} md={11} className="" style={{ paddingRight: "0px" }}>
                                                                            <AvField
                                                                                name="password"
                                                                                autoComplete="new-password"
                                                                                type={this.state.show ? "password" : "text"}
                                                                                style={{ width: "100%" }}
                                                                                required
                                                                                placeholder="Enter password"
                                                                                validate={{
                                                                                    required: { value: true, errorMessage: 'Password is required' },
                                                                                    minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
                                                                                    pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                                                                }} />
                                                                        </Col>
                                                                        <Col xs={2} md={1} style={{ paddingLeft: "0px" }} >
                                                                            <button
                                                                                onClick={() => this.setState({ show: !this.state.show })}
                                                                                className="btn btn-light"
                                                                                type="button"
                                                                                id="password-addon"
                                                                            >
                                                                                <i className={this.state.show ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i>
                                                                            </button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                            {
                                                                this.state.alertEnable ?
                                                                    <div className="mt-4 d-grid">
                                                                        <Alert color={this.state.alertColor}>
                                                                            {this.state.message}
                                                                        </Alert>
                                                                    </div>
                                                                    : null
                                                            }


                                                        <div className="mt-4 d-flex justify-content-start">
                                                            <div>
                                                                <button
                                                                    className="btn btn-danger me-2"
                                                                    type="submit"
                                                                    disabled={this.state.loading || this.state.errorMessage}
                                                                    onClick={() => this.props.history.goBack()}
                                                                >
                                                                    Cancel

                                                                </button>
                                                                <button
                                                                    className="btn btn-primary"
                                                                    type="submit"
                                                                    disabled={this.state.loading || this.state.errorMessage}
                                                                >
                                                                    {this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Add User"}

                                                                </button>
                                                                {
                                                                    (this.state.country_code_err || this.state.invalid_user || this.state.invalid_phone_num) &&
                                                                    <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Please fill the above mandatory fields.</div>


                                                                }
                                                            </div>

                                                        </div>
                                                            

                                                        </AvForm>
                                                    </div>
                                                </CardBody> :
                                                <CardBody>
                                                    <div className="mt-4 text-center">
                                                        <div className="mb-0" style={{ fontSize: 20 }}>
                                                            <span className="text-danger">{this.state.first_name}</span> has added successfully
                                                        </div>
                                                        <div style={{ marginTop: 20 }}>
                                                            <Link className="btn btn-success" onClick={() => this.props.history.goBack()}>
                                                                Click here to redirect
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                        }
                                    </Card>

                                </Col>
                            </Row>
                        </Container>
                        </div>
            )
        }
        else {
            return null
        }
    }
}



export default AddUser


















//b4-26-06-23
// import React, { Component } from "react"
// import { isEmpty } from "lodash"
// import MetaTags from 'react-meta-tags';
// import { AvForm, AvField } from "availity-reactstrap-validation"
// import { Link } from "react-router-dom"
// import CryptoJS from 'crypto-js'
// import { Row, Col, CardBody, Card, Container, Alert, Button, Input } from "reactstrap"
// import { LoadingOutlined } from '@ant-design/icons';
// import facility from '../FacilityScreen'
// import _ from "lodash";
// var urlSocket = require("../../helpers/urlSocket");


// class AddUser extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             dataloaded: false,
//             alertEnable: false,
//             formDisabled: false,
//             userInfo: {},
//             given_email: '',
//             secret_key: 'Pkdh347ey%3Tgs',
//             invalid_user: false,
//             invalid_phone_num: false,
//             invalid_usercode: false,
//             loading:false,
//             country_code_err:false

//         };
//     }
//     async componentDidMount() {
//         var data = JSON.parse(sessionStorage.getItem("authUser"));
//         var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0]
//         var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//         console.log(client_info, db_info)
//         var protect_routes = await facility(data.facility_data ,'murs' )
//         console.log(protect_routes,'protect_routes')
//         if(protect_routes!== undefined){
//         this.setState(
//             {
//                 client_info: client_info,
//                 db_info: db_info,
//                 sessionUserInfo: data,
//                 dataloaded: true,

//             },
//             function () {
//                 //this.getEntityDetaitedData()
//             }
//         )
//     }
//     else{
//         this.props.history.push('/dashboard')
//     }
//     }


//     handleValidSubmit = (event, values) => {
//         console.log(values, 'values', event,this.state.sessionUserInfo)
//         var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
//         values["password"] = password
//         values["confirmpassword"] = password
//         values["userPoolId"] = this.state.client_info.userPoolId
//         values["clientId"] = this.state.client_info.clientId
//         values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//         values["db_name"] = this.state.db_info.db_name
//         values["facilities"] = this.state.client_info.facilities
//         values["company_id"] = this.state.sessionUserInfo.user_data.m_client_id
//         values["created_by"] = this.state.sessionUserInfo.user_data._id



//         if (this.state.countrycode === undefined) {
//             this.setState({ country_code_err: true })
//         }
//         else {
//             values["countrycode"] = this.state.countrycode
//             this.setState({ country_code_err: false })

//         }
//         console.log(this.state.client_info.facilities, 'this.state.client_info.facilities')
//         if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false) {
//             this.setState({loading:true})

//             try {
//                 urlSocket.post('cog/cruduser', values).then((res) => {
//                     console.log(res, 'res')
//                     if(res.data.response_code === 500){
//                          this.setState({loading:false})
//                     this.props.history.push('/murs')
//                 }
//                 })

//             } catch (error) {
//                 console.log(error, 'error')
//             }

//         }

//         //    values[] 


//     }

//     emai_addrs = (e) => {
//         this.setState({ given_email: e.target.value })
//     }


//     validate_email = (e, mode) => {
//         console.log(e.target.value, "events")
//         var admn_info = {}
//         if (mode == 1) {
//             admn_info["email_id"] = e.target.value
//         }
//         if (mode === 2) {
//             admn_info["phone_number"] = e.target.value
//         }
//         if (mode === 3) {
//             admn_info["usercode"] = e.target.value
//         }
//         admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//         admn_info["db_name"] = this.state.db_info.db_name


//         try {
//             urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
//                 console.log(data, 'data')
//                 if (mode == 1) {
//                     if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
//                         this.setState({ invalid_user: true })
//                     }
//                     else {
//                         this.setState({ invalid_user: false })
//                     }
//                 }
//                 if (mode == 2) {

//                     if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
//                         this.setState({ invalid_phone_num: true })
//                     }
//                     else {
//                         this.setState({ invalid_phone_num: false })
//                     }
//                 }
//                 if (mode === 3) {
//                     if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
//                         this.setState({ invalid_usercode: true })
//                     }
//                     else {
//                         this.setState({ invalid_usercode: false })
//                     }


//                 }
//             })

//         } catch (error) {

//         }


//     }

//     // handleValidSubmit = (event, values) => {
//     //     try {
//     //         var loggedUserInfo = {
//     //             company_id: this.state.sessionUserInfo.company_id,
//     //             company_name: this.state.sessionUserInfo.company_name,
//     //             _id:this.state.sessionUserInfo._id
//     //         }
//     //         urlSocket.post("webUsers/cruduser", {
//     //             loggedUserInfo: loggedUserInfo,
//     //             newUserInfo: values
//     //         })
//     //             .then((response) => {
//     //                 if (response.data.response_code == 504) {
//     //                     this.setState({ formDisabled: false, alertEnable: true, alertColor: "danger", message: response.data.message })
//     //                 }
//     //                 else
//     //                     if (response.data.response_code == 500) {
//     //                         this.setState({
//     //                             first_name: response.data.data.first_name,
//     //                             formDisabled: true,
//     //                             alertEnable: true,
//     //                             alertColor: "success",
//     //                             message: response.data.message
//     //                         })
//     //                     }
//     //             })
//     //     } catch (error) {

//     //     }
//     // }
//     select_country=(e)=>{
//         console.log(e.target.value)
//         this.setState({countrycode:e.target.value})
//     }

//     render() {
//         if (this.state.dataloaded) {
//             return (
//                 <React.Fragment>
//                     <MetaTags>
//                         <title>AuditVista | Add New User</title>
//                     </MetaTags>

//                     <div className="page-content">
//                         <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-2">
//                             <div style={{ width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
//                                 Add New User
//                             </div>
//                             <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
//                                 <Link to='/murs' ><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//                             </div>
//                         </div>
//                         <Container fluid>
//                             <Row >
//                                 <Col >
//                                     <Card className="overflow-hidden">
//                                         {
//                                             !this.state.formDisabled ?
//                                                 <CardBody className="pt-0">
//                                                     <div className="p-5">
//                                                         <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


//                                                             <Row className="my-4">
//                                                                 <div className="text-danger font-size-18">User Information</div>
//                                                                 <hr className="my-4" />
//                                                             </Row>

//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3" >
//                                                                         <label>First Name :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="firstname"
//                                                                             // label="First Name"
//                                                                             className="form-control"
//                                                                             placeholder="Enter First Name"
//                                                                             type="text"
//                                                                             required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3" >
//                                                                         <label>Last Name :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="lastname"
//                                                                             // label="Last Name"
//                                                                             className="form-control"
//                                                                             placeholder="Enter Last Name"
//                                                                             type="text"
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>
//                                                             <Row>
//                                                                 <Col className="col-12">
//                                                                     <div className="mb-3" >
//                                                                         <AvField
//                                                                             name="addrss_1"
//                                                                             label="Address"
//                                                                             className="form-control"
//                                                                             placeholder="Enter Address"
//                                                                             type="text"
//                                                                         //required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>
//                                                             <Row>
//                                                                 <Col className="col-4">
//                                                                     <div className="mb-3" >
//                                                                         <AvField
//                                                                             name="city"
//                                                                             label="City"
//                                                                             className="form-control"
//                                                                             placeholder="Enter City"
//                                                                             type="text"
//                                                                         //required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-4">
//                                                                     <div className="mb-3" >
//                                                                         <AvField
//                                                                             name="state"
//                                                                             label="State"
//                                                                             className="form-control"
//                                                                             placeholder="Enter State"
//                                                                             type="text"
//                                                                         // required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>

//                                                                 <Col className="col-4">
//                                                                     <div className="mb-3" >
//                                                                         <AvField
//                                                                             name="country"
//                                                                             label="Country"
//                                                                             className="form-control"
//                                                                             placeholder="Enter Country"
//                                                                             type="text"
//                                                                         // required
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>

//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3" >
//                                                                         <label>Email Id :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="email_id"
//                                                                             onChange={(e) => { this.emai_addrs(e) }}
//                                                                             onBlur={(e) => { this.validate_email(e, 1) }}
//                                                                             // label="User Email"
//                                                                             className="form-control"
//                                                                             placeholder="Enter User Email"
//                                                                             type="email"
//                                                                             required
//                                                                         />
//                                                                         {
//                                                                             this.state.invalid_user &&
//                                                                             <div className='text-danger' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
//                                                                         }
//                                                                     </div>
//                                                                 </Col>

//                                                                 <Col md={6}>
//                                                                     <div className="mb-3">
//                                                                         <label>Phone Number:<span className="text-danger"> *</span></label>
//                                                                         <div className="input-group">
//                                                                             <div className="input-group-prepend">
//                                                                                 <select name="countrycode" onChange={(e)=>{this.select_country(e)}} className="form-select" required>
//                                                                                     <option value="">Select</option>
//                                                                                     {
//                                                                                         this.state.client_info.countries.map((c,idx)=>(
//                                                                                             <option key={idx} value={c.code}>
//                                                                                                 {c.code}
//                                                                                             </option>
//                                                                                         ))
//                                                                                     }
//                                                                                 </select>
//                                                                             </div>
//                                                                             <div className="flex-grow-1">
//                                                                                 <AvField
//                                                                                     name="phone_number"
//                                                                                     // label="Phone Number"
//                                                                                     className="form-control"
//                                                                                     placeholder="Enter Phone number"
//                                                                                     type="number"
//                                                                                     onBlur={(e) => { this.validate_email(e, 2) }}
//                                                                                     validate={{
//                                                                                         required: { value: true },
//                                                                                         minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                                                         maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                                                     }}
//                                                                                 />
//                                                                                 {
//                                                                                     this.state.invalid_phone_num &&
//                                                                                     <div className='text-danger' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
//                                                                                 }
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                     {
//                                                                     this.state.country_code_err &&
//                                                                     <div className='text-danger' style={{ fontSize: 'smaller' }}>Please Select the country code.</div>


//                                                                 }
//                                                                 </Col>
                                

//                                                                 {/* <Col md={6}>
//                                                                     <div className="mb-3">
//                                                                         <label>Phone Number:<span className="text-danger"> *</span></label>
//                                                                         <div className="input-group">
//                                                                             <select name="countrycode" className="form-select" style={{ width: '50%' }} required>
//                                                                                 <option value="">Select a country code</option>
//                                                                                 <option value="+1">USA (+1)</option>
//                                                                                 <option value="+52">Mexico (+52)</option>
//                                                                                 <option value="+1">Canada (+1)</option>
//                                                                             </select>
//                                                                             <AvField
//                                                                                 name="phone_number"
//                                                                                 // label="Phone Number"
//                                                                                 className="form-control"
//                                                                                 placeholder="Enter Phone number"
//                                                                                 type="number"
//                                                                                 onBlur={(e) => { this.validate_email(e, 2) }}
//                                                                                 validate={{
//                                                                                     required: { value: true },
//                                                                                     minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                                                     maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
//                                                                                 }}
//                                                                             />
//                                                                             {
//                                                                                 this.state.invalid_phone_num &&
//                                                                                 <div className='text-danger' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
//                                                                             }
//                                                                         </div>
//                                                                     </div>
//                                                                 </Col> */}


//                                                             </Row>

//                                                             <Row className="my-4">
//                                                                 <div className="text-danger font-size-18">Company Information</div>
//                                                                 <hr className="my-4" />
//                                                             </Row>

//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="qualification"
//                                                                             label="Qualification"
//                                                                             type="text"
//                                                                             // required
//                                                                             placeholder="Enter Qualification"
//                                                                         //value = {this.state.userInfo.address_1}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="doj"
//                                                                             label="Date of Joining"
//                                                                             type="text"
//                                                                             // required
//                                                                             placeholder="Enter Date of Joining"
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>

//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <label>Designation :</label>
//                                                                         <AvField
//                                                                             name="designation"
//                                                                             // label="Designation"
//                                                                             type="text"
//                                                                             placeholder="Enter Designation"
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="experience"
//                                                                             label="Experience"
//                                                                             type="number"
//                                                                             // required
//                                                                             placeholder="Enter Experience"
//                                                                         //value = {this.state.userInfo.address_1}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>
//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="usercode"
//                                                                             label="User Code"
//                                                                             type="text"
//                                                                             placeholder="Enter User Code"
//                                                                             onBlur={(e) => this.validate_email(e, 3)}
//                                                                         />
//                                                                         {
//                                                                             this.state.invalid_usercode &&
//                                                                             <div className="text-danger" style={{ fontSize: 'smaller' }}>User Code already exist for another user</div>
//                                                                         }
//                                                                     </div>
//                                                                 </Col>
//                                                             </Row>

//                                                             <Row className="my-4">
//                                                                 <div className="text-danger font-size-18">Login Information</div>
//                                                                 <hr className="my-4" />
//                                                             </Row>

//                                                             <Row>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <label>Username :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="username"
//                                                                             // label="Username"
//                                                                             type="text"
//                                                                             required
//                                                                             placeholder="Enter username"
//                                                                             disabled={true}
//                                                                             value={this.state.given_email}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <label>Password :<span className="text-danger"> *</span></label>
//                                                                         <AvField
//                                                                             name="password"
//                                                                             // label="Password"
//                                                                             autoComplete ="new-password"
//                                                                             type="password"
//                                                                             required
//                                                                             placeholder="Enter password"
//                                                                             validate={{
//                                                                                 required: { value: true, errorMessage: 'Password is required' },
//                                                                                 minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
//                                                                                 pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
//                                                                             }}
//                                                                         />
//                                                                     </div>
//                                                                 </Col>
//                                                                 {/* <Col className="col-6">
//                                                                     <div className="mb-3">
//                                                                         <AvField
//                                                                             name="confirmpassword"
//                                                                             label="Confirm Password"
//                                                                             type="password"
//                                                                             required
//                                                                             placeholder="Enter Confirm password"
//                                                                         />
//                                                                     </div>
//                                                                 </Col> */}
//                                                             </Row>





//                                                             <div className="mb-3" >

//                                                             </div>
//                                                             {
//                                                                 this.state.alertEnable ?
//                                                                     <div className="mt-4 d-grid">
//                                                                         <Alert color={this.state.alertColor}>
//                                                                             {this.state.message}
//                                                                         </Alert>
//                                                                     </div>
//                                                                     : null
//                                                             }


//                                                             <div className="mt-4 d-grid">
//                                                                 <button
//                                                                     className="btn btn-danger btn-block"
//                                                                     type="submit"
//                                                                     disabled={this.state.loading}
//                                                                 >
//                                                                     {this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Add User"}
                                                                    
//                                                                 </button>
//                                                                 {
//                                                                     (this.state.country_code_err || this.state.invalid_user || this.state.invalid_phone_num )&&
//                                                                     <div className='text-danger' style={{ fontSize: 'smaller' }}>Please fill the above mandatory fields.</div>


//                                                                 }

//                                                             </div>

//                                                         </AvForm>
//                                                     </div>
//                                                 </CardBody> :
//                                                 <CardBody>
//                                                     <div className="mt-4 text-center">
//                                                         <div className="mb-0" style={{ fontSize: 20 }}>
//                                                             <span className="text-danger">{this.state.first_name}</span> has added successfully
//                                                         </div>
//                                                         <div style={{ marginTop: 20 }}>
//                                                             <Link className="btn btn-success" onClick={() => this.props.history.goBack()}>
//                                                                 Click here to redirect
//                                                             </Link>
//                                                         </div>
//                                                     </div>
//                                                 </CardBody>
//                                         }
//                                     </Card>

//                                 </Col>
//                             </Row>
//                         </Container>
//                     </div>
//                 </React.Fragment>
//             )
//         }
//         else {
//             return null
//         }
//     }
// }



// export default AddUser
