import React, { Component } from 'react';

import { Row, Col, CardBody, Card, Alert } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../../store/actions';

// import images
import profile from "../../../assets/images/logo_blue.png";

import Logo from "../../../assets/images/eaudit.jpg";


var urlSocket = require('../../../helpers/urlSocket');


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            login_status: false,
            loading: false,
            login_msg: '',
        }

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // Login Submit
    handleValidSubmit(event, values) {

        // this.props.loginUser(values, this.props.history);
        let deviceInfo = { ipAddress: "", macAddress: "" }
        try {
            urlSocket.post('/authentication/login', {
                username: values.username,
                password: values.password,
                mac_address: "",
                device_info: [deviceInfo],
                //   

            })
                .then((response) => {

                    if (response.data.response_code == 500) {
                            this.setState({ loading: true, login_status: true, login_msg: response.data.message })
                            const loginSession = response.data
                            sessionStorage.setItem("authUser", JSON.stringify(loginSession));
                            if(response.data.user_data.usertype == "99")
                            {
                                this.props.history.push("/admindashboard");
                            }
                            else
                            {
                                this.props.history.push("/dashboard");
                            }
                    }
                    else {
                        this.setState({ login_status: true, login_msg: response.data.message })

                    }
                })
        }
        catch (error) {
        }

        this.remove_sessions();

    }
    remove_sessions() {
        sessionStorage.removeItem("tm_list");
        sessionStorage.removeItem("entities_list_page");
        sessionStorage.removeItem("audit_parameters");
    }
    componentDidMount() {
        this.props.apiError("");
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ marginTop: "50px", visibility: "hidden" }}>Login</div>
                <div className="account-pages my-5 pt-sm-5">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div >
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Welcome</h5>
                                                    <p>Sign in to continue</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={Logo} className="img-fluid" style={{ maxWidth: "90%" }} />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light" >
                                                    <img src={profile} alt="" className="" height="50" />
                                                </span>

                                            </div>
                                        </div>
                                        <div className="p-2">

                                            <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                {this.state.login_status ? <Alert color="danger">{this.state.login_msg}</Alert> : null}
                                                {/* Check the Username and Password */}


                                                <div className="form-group">
                                                    <AvField name="username" label="Username" className="form-control" placeholder="Enter Username" required />
                                                </div>

                                                <div className="form-group">
                                                    <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                                </div>

                                                <div className="mt-3">
                                                    <button className="btn btn-primary btn-block waves-effect waves-light" type="submit"> {this.state.loading ? "Loading" : "Log In"} </button>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser, apiError })(Login));

