import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap"

import { withRouter, Link } from "react-router-dom"

//Import Images
// import user1 from '../../../assets/images/avatar.jpg';

function CardUser(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  const [userImg, setUserImg] = useState([])

  //Setting Menu
  useEffect(() => {
    // var user_img = 
    if (props.user_data.user_img !== undefined) {
      setUserImg(props.user_data.user_img)
    }

  }, [])

  // console.log("props.clientInfo", props.user_data)


  return (
    <React.Fragment>
      <Row>
        <Col lg="12" >
          <Card style={{ borderRadius: 100, backgroundColor: "transparent", boxShadow: "none" }}>
            <CardBody className="py-2">

              <Row>

                <Col lg="4">
                  <div className="d-flex flex-row align-items-center ">
                    {/* <div className="col-auto gx-2 me-2">
                      <img
                        alt="Header Avatar"
                        className="rounded-circle header-profile-user"
                        src={props.clientInfo.base_url + props.user_data.user_img[0].originalname}
                      />
                    </div> */}
                    <div className="col-auto gx-2">
                      <div className="text-muted">
                        <div className="text-dark" style={{ margin: 0, padding: 0, fontSize: 18, fontWeight:"500", lineHeight: 1 }}>Welcome, {props.user_data.firstname}</div>
                        <div className="mb-0" style={{ fontSize: 12 }}>{props.user_data.role_name}</div>
                      </div>
                    </div>

                  </div>                 
                </Col>
               
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
