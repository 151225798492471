import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col,Button } from "reactstrap";
import urlSocket from '../../../helpers/urlSocket';
//Import Images
// import error from "../../assets/images/error-img.png";
import error from "../../../assets/images/error-img.png";

import auditvista_logo from "../../../assets/images/auditvista_logo.png";

class Url404 extends Component {
    constructor(props) {
        super(props);
        this.state ={
            invalid_path:""
        }
    }

    componentDidMount(){
        var path = window.location.hostname
        var invalid_path = path.split(".")[0];
        this.setState({invalid_path})
        try {
            urlSocket.post('cog/find-short-name').then((response)=>{
              if(response.data.response_code === 500){
                sessionStorage.setItem('short_name',response.data.data.short_name)
                this.props.history.push('/login')
              }
              else{
                  sessionStorage.clear()
                //   this.props.history.push('/url-not-found')
              }
            })
            
          } catch (error) {
            
          }




    }


    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-5">
                    <Container>
                        <Row>
                            <Col lg="12">
                                
                                <div className="text-center mb-5">
                                <img
                                    src={auditvista_logo}
                                    alt=""
                                    height="30"
                                    className="auth-logo-dark text-center"
                                />
                                <br/>
                                <br/>
                                <br/>
                                {/* <br/>
                                <br/> */}

                                    {/* <h1 className="display-2 font-weight-medium">
                                        <span style={{ fontSize: '3rem' }}>4</span>
                                        <i className="bx bx-buoy bx-spin text-primary" style={{ fontSize: '2.5rem' }}></i>
                                        <span style={{ fontSize: '3rem' }}>4</span>
                                    </h1> */}
                                   {/* <h1 className="display-4 font-weight-medium">4<i className="bx bx-buoy bx-spin text-primary display-3"></i>4</h1> */}
                                    <h4 className="text-uppercase">Sorry, Sub domain <span className='text-danger'>{this.state.invalid_path}</span> is not exist</h4>
                                    <div className="mt-4 text-center">
                                        <Button className="btn btn-primary waves-effect waves-light" onClick={()=>{document.location.href=("http://register.auditvista.com/register")}}>Click here to Register</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md="8" xl="6">
                                <div>
                                    <img src={error} alt="" className="img-fluid" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Url404;