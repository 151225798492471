import React from 'react';
import { useEffect, useState, useHistory } from 'react';
import styled from 'styled-components';
import PropTypes, { element } from 'prop-types';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
    // Tag
} from "reactstrap";
import { Switch, Checkbox } from 'antd'
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import urlSocket from '../../../helpers/urlSocket';
import { _ } from 'core-js';
import Swal from 'sweetalert2';
import facility from '../FacilityScreen'
import MetaTags from 'react-meta-tags'

import Breadcrumbs from "../../../components/Common/Breadcrumb"


// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const CreateRoles = (props) => {
    const [screenInfo, setScreenInfo] = useState([])
    const [editData, setEditData] = useState({})
    const [userInfo, setUserInfo] = useState({})
    const [readCheckAll, setReadCheckAll] = useState(false)
    const [writeCheckAll, setWriteCheckAll] = useState(false)
    const [RoleStatus, setRoleStatus] = useState(true)
    const [dbUrl, setDbUrl] = useState('')
    const [roleNameErr, setRoleNameErr] = useState(false)

    const [oncreate, setOncreate] = useState(true)
    const [protect, setProtect] = useState({})



    useEffect(() => {

        var data = JSON.parse(sessionStorage.getItem('authUser'))
        var role_id = sessionStorage.getItem('role_id')
        setUserInfo(data.user_data)
        var protect_routes = facility(data.facility_data, 'mroles')
        var encrypted_db_url = data.db_info.encrypted_db_url
        // console.log(protect_routes, 'protect_routes')
        setDbUrl(encrypted_db_url)
        if (protect_routes !== undefined) {
            try {
                urlSocket.post('cog/get-screen-facilities', { encrypted_db_url: encrypted_db_url, user_id: userInfo._id }).then((response) => {
                    
                    console.log(response,'response')
                    var facility_array = response.data.data[0].facilities
                    facility_array.map((data, idx) => {
                        if (data.facility_id == "1") {
                            facility_array[idx].read_write_checked = true
                        }
                    })
                    if (response.data.response_code === 500) {
                        // setScreenInfo(response.data.data[0].facilities)
                        if (role_id !== undefined && role_id !== null && role_id !== '') {
                            setOncreate(false)
                            get_role_info(role_id, data.db_info.encrypted_db_url, response.data.data[0].facilities)
                            setProtect(protect_routes)
                        }
                        else {
                            setOncreate(true)
                            setScreenInfo(facility_array)
                        }
                    }
                })

            } catch (error) {

            }
        }
        else {
            window.location.href = '/dashboard'
        }

        // }
        // else{

        // }

    }, [])


    const get_role_info = (data, encrypted_db_url, state_data) => {

        try {
            urlSocket.post('cog/get-role-info', { role_id: data, encrypted_db_url: encrypted_db_url, user_id: userInfo._id }).then((response) => {
                console.log(response,'response')
                if (response.data.response_code === 500) {
                    setEditData(response.data.data[0])
                    response.data.data[0].role_status === true ? setRoleStatus(true) : setRoleStatus(false)
                    var facilities = response.data.data[0].facilities
                    console.log(state_data,'state_data',facilities)
                    state_data.map((data, idx) => {
                        facilities.map((ele, idx1) => {
                            if (data.id === ele.id) {
                                state_data[idx] = facilities[idx1]
                            }
                        })
                    })
                    setScreenInfo(state_data)


                }
            })
        } catch (error) {

        }


    }



    const checkRolenameExist = (value) => {
        var obj_data = {}
        obj_data["role_name"] = value
        obj_data["encrypted_db_url"] = dbUrl
        obj_data["user_id"] = userInfo._id
        if (Object.keys(editData).length > 0) {
            obj_data["_id"] = editData._id
        }
        if (value !== '' && value !== undefined) {
            try {
                urlSocket.post('cog/check-rolename-exist', obj_data).then((response) => {
                    if (response.data.response_code === 500 && response.data.data.length > 0) {
                        setRoleNameErr(true)
                    }
                    else {
                        setRoleNameErr(false)

                    }
                })

            } catch (error) {

            }
        }

    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            role_name: '' || editData.role_name
        },
        validationSchema: Yup.object({
            role_name: Yup.string().required("Please Enter Role Name")
        }),
        onSubmit: (values) => {
            if (!values.role_name.trim()) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Role name cannot be empty.',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                });
                return;
            }

            values["role_status"] = RoleStatus;
            values["encrypted_db_url"] = dbUrl;

            const validate_checkbox = _.filter(screenInfo, element => {
                return element.read_checked === true || element.read_write_checked === true;
            });

            values["facilities"] = validate_checkbox;
            values["_id"] = editData._id;

            if (roleNameErr === false) {
                Swal.fire({
                    icon: 'question',
                    title: 'Are you sure?',
                    text: 'Do you want to save this role?',
                    showCancelButton: true,
                    confirmButtonColor: '#2ba92b',
                    confirmButtonText: 'Yes',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.isConfirmed) {
                        saveRole(values);
                    } else {
                        // Handle cancel action
                    }
                });
            }

            if (validate_checkbox.length < 1) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: 'Minimum 1 Screen Information should be selected.',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    // Handle OK action
                });
            }
        }
    });


    // const validation = useFormik({
    //     enableReinitialize: true,

    //     initialValues: {
    //         role_name: '' || editData.role_name
    //     },
    //     validationSchema: Yup.object({
    //         role_name: Yup.string().required("Please Enter Role Name")
    //         // .test(async function (value) {
    //         //     return !(await checkRolenameExist(value));
    //         //   })
    //         ,

    //     }),
    //     onSubmit: (values) => {
    //         values["role_status"] = RoleStatus
    //         values["encrypted_db_url"] = dbUrl
    //         var validate_checkbox = []
    //         _.filter(screenInfo, element => {
    //             // if (element.facility_id !== "1") {
    //             if (element.read_checked === true || element.read_write_checked === true) {
    //                 validate_checkbox.push(element)
    //             }
    //             // }
    //         })
    //         values["facilities"] = validate_checkbox
    //         values["_id"] = editData._id
    //         if (roleNameErr === false) {
    //             Swal.fire({
    //                 icon: 'question',
    //                 title: 'Are you sure?',
    //                 text: 'Do you want to save this role?',
    //                 showCancelButton: true,
    //                 confirmButtonColor: '#2ba92b',
    //                 confirmButtonText: 'Yes',
    //                 cancelButtonColor: '#d33',
    //                 cancelButtonText: 'No'
    //             }).then((result) => {
    //                 if (result.isConfirmed) {
    //                     saveRole(values)
    //                 } else {
    //                     // Handle cancel action
    //                 }
    //             });
    //         }
    //         if (validate_checkbox.length == 1) {
    //             Swal.fire({
    //                 icon: 'warning',
    //                 title: 'Warning!',
    //                 text: `Minimum 1 Screen Information should be selected  `,
    //                 confirmButtonColor: '#3085d6',
    //                 confirmButtonText: 'OK'
    //             }).then((result) => {
    //                 // getClientList()

    //             })
    //         }
    //         else {


    //         }


    //     }
    // })

    const saveRole = (data) => {
        data["user_id"] = userInfo._id
        data["created_by"] = userInfo._id
        try {

            urlSocket.post('cog/crud-roles', data).then((response) => {

                if (response.data.response_code === 500) {
                    props.history.push('/mroles')
                }
            })

        } catch (error) {

        }



    }



    const onchange_switch = (e, name, idx) => {
        var screen_info = [...screenInfo]
        var dup_screen = []
        var dup_screen_1 = []
        if (name == 'read_write_checked') {
            screen_info[idx].read_write_checked = e.target.checked
            screen_info[idx].read_checked = false
            setWriteCheckAll(false)
            if (e.target.checked === false) {
                screen_info[idx].read_write_checked = false

                setReadCheckAll(false)
                // setWriteCheckAll(false)

            }

            _.filter(screen_info, e => {
                if (e.read_write_checked === true) {
                    dup_screen.push(e)
                    // console.log(dup_screen.length)
                }
            })
            if (dup_screen.length == screen_info.length) {
                setReadCheckAll(true)
            }
            setScreenInfo(screen_info)
        }
        if (name == 'read_checked') {
            screen_info[idx].read_checked = e.target.checked
            setScreenInfo(screen_info)
            if (e.target.checked === false) {
                setWriteCheckAll(false)
            }
            _.filter(screen_info, e => {
                if (e.read_checked === true) {
                    dup_screen_1.push(e)
                    // console.log(dup_screen.length)
                }
            })
            if (dup_screen_1.length == screen_info.length) {
                setWriteCheckAll(true)
            }

        }
        if (name == 'read_write_check_all') {
            if (e.target.checked === true) {
                screen_info.map((data, idx) => {
                    // if (data.facility_id !== "1") {
                    screen_info[idx].read_write_checked = true
                    screen_info[idx].read_checked = false
                    // }
                })
                setReadCheckAll(true)
                setWriteCheckAll(false)
            }
            if (e.target.checked === false) {
                screen_info.map((data, idx) => {
                    if (data.facility_id !== "1") {
                        screen_info[idx].read_write_checked = false
                    }
                    // screen_info[idx].write_checked = false
                })
                setReadCheckAll(false)
                setWriteCheckAll(false)

            }
            setScreenInfo(screen_info)
        }
        if (name == 'read_check_all') {
            if (e.target.checked === true) {
                screen_info.map((data, idx) => {
                    screen_info[idx].read_checked = true
                })
                setWriteCheckAll(true)

            }
            if (e.target.checked === false) {
                screen_info.map((data, idx) => {
                    screen_info[idx].read_checked = false
                })
                setWriteCheckAll(false)

            }
            setScreenInfo(screen_info)


        }




    }

    const switch_change = (e, name) => {
        setRoleStatus(e)
    }

    const gotoBack = () => {
        sessionStorage.removeItem('role_id')
        props.history.push('/mroles')
    }


    const handleRoleNameBlur = async () => {
        const value = validation.values.role_name;
        if (value) {
            try {
                await checkRolenameExist(value);
                // setRoleNameErr(true); // Role name exists
            } catch (error) {
                // setRoleNameErr(false); // Role name doesn't exist
            }
        }
    };

    const handleInputChange = (event) => {
        setRoleNameErr(false); // Reset the roleNameErr state
        validation.handleChange(event);
    };


    const handleKeyDown = async () => {
        const value = validation.values.role_name;
        if (value) {
            try {
                await checkRolenameExist(value);
                setRoleNameErr(true); // Role name exists
            } catch (error) {
                setRoleNameErr(false); // Role name doesn't exist
            }

        }
    };



    return (
        <div className='page-content'>

            <MetaTags><title>AuditVista | {oncreate ? 'Create Roles' : 'Edit Roles'}</title></MetaTags>

            <Container fluid>

                <Breadcrumbs
                    title="Create Role"
                    breadcrumbItem="Roles"
                    isBackButtonEnable={true}
                    gotoBack={() => gotoBack()}
                />

                <Form className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >

                    <Card style={{ marginBottom: 6 }}>
                        <CardBody>

                            <Row>
                                <Col md={4}>
                                    <Label className='font-size-15'>{oncreate ? "New Role Name" : "Role Name"}<label className='text-danger'>*</label></Label>
                                    <FormGroup >
                                        <Input
                                            name="role_name"
                                            placeholder="Please Enter the Role Name"
                                            type="text"
                                            className="form-control"
                                            // onChange={validation.handleChange}
                                            // onBlur={validation.handleBlur}
                                            onChange={handleInputChange}
                                            onBlur={handleRoleNameBlur}
                                            onKeyDown={handleKeyDown}
                                            value={validation.values.role_name || ""}
                                            invalid={
                                                validation.touched.role_name && validation.errors.role_name ? true : false
                                            }
                                        />
                                        {
                                            roleNameErr &&
                                            <label style={{ fontSize: 'smaller' }} className='text-danger'>Role Name already exist</label>
                                        }
                                        {validation.touched.role_name && validation.errors.role_name ? (
                                            <FormFeedback type="invalid">{validation.errors.role_name}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                {
                                    Object.keys(editData).length !== 0 &&
                                    <Col md={8} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                        <Label>Status :</Label>
                                        &nbsp;&nbsp;&nbsp;<Switch value={RoleStatus}
                                            checked={RoleStatus ? true : false}
                                            onChange={(e) => { switch_change(e, 'role_status') }} />
                                    </Col>
                                }
                            </Row>

                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>

                            <Label className='font-size-15'>Please select the screen for the Role<span className='text-danger'>*</span></Label>

                            <table className="table" >
                                <tr className="header-row " style={{ borderBottom: '1px solid #dedede' }}>
                                    <th width="80%" className='py-3'>Screens</th>
                                    <th width="10%" className='py-3'><span><Checkbox name='read_check_all' checked={readCheckAll} onClick={(e) => { onchange_switch(e, "read_write_check_all") }} /></span>Read {'&'} Write</th>
                                    <th width="10%" className='py-3'><span><Checkbox name='write_check_all' disabled={readCheckAll === false ? false : true} checked={writeCheckAll} onClick={(e) => { onchange_switch(e, "read_check_all") }} /></span>Read</th>
                                </tr>

                                <tbody>
                                    {
                                        screenInfo.map((data, index) => (
                                            <tr key={index} className="table-row-hover">
                                                <td className='text-primary'>{data.interfacename}</td>
                                                <td><Checkbox disabled={data.facility_id == "1"} checked={data.read_write_checked === true} onClick={(e) => { onchange_switch(e, "read_write_checked", index) }} /></td>
                                                <td><Checkbox checked={data.read_checked === true} disabled={data.read_write_checked === true} onClick={(e) => { onchange_switch(e, "read_checked", index) }} /></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>


                            <Row>
                                <Col md={12} className='text-end'>
                                    <Button className='me-2' color='danger' onClick={()=>props.history.push('/mroles')}>Cancel</Button>
                                    <Button color={oncreate ? 'success' : 'dark'}
                                        disabled={protect.read_checked === true ? true : false}
                                    >{oncreate ? "Create Role" : "Update Role"}</Button>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Form>
            </Container>
        </div>
    )

}

CreateRoles.propTypes = propTypes;
CreateRoles.defaultProps = defaultProps;
// #endregion

export default CreateRoles;