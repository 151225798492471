import React from 'react';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import ReactDrawer from 'react-drawer';
import CryptoJS from 'crypto-js'
// #region constants.
import {
    Container, Row, Col, Button, Alert, Input, Card, CardBody,
    Modal, ModalHeader, ModalBody,
    Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
} from "reactstrap"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import AddEndpointNode from "./NewLocation"
import Breadcrumbs from "./Components/Breadcrumb2"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import BootstrapTable from "react-bootstrap-table-next"
import _ from 'lodash'
import SweetAlert from "react-bootstrap-sweetalert"
import { LoadingOutlined } from '@ant-design/icons';
import LoadLocation from './LoadLocation';
// import index from '../ManageHirerachy';
import TableContainer from './LocationComponents/TableContainer';
import Swal from "sweetalert2";
import { TableHeader, PaginationComponent } from "../../../common/DataTable"
import FuzzySearch from '../../../common/FuzzySearch';

// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from '../../../helpers/urlSocket';


// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
// const propTypes = {};

// const defaultProps = {};

/**
 * 
 */
class Location extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            component: '',
            crt_location: false,
            load_location: false,
            open: false,
            position: 'right',
            secret_key: 'Pkdh347ey%3Tgs',
            dataLoaded: false,
            user_data: [],
            selectedEOPT: [],
            show_btn: false,
            review_id: '',
            auditer_id: '',
            labelData: [],
            show: true,

            search_files: [],
            dup_search_files: [],
            temp_search_files: [],
            currentPage: 1,
            totalItems: 0,
            items_per_page: 10,
            statusBasedFilteredData: [],
            dup_statusBasedFilteredData: []
        };
    }


    componentDidMount() {
        var user_data = JSON.parse(sessionStorage.getItem('authUser'))
        var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0]
        var db_info = JSON.parse(sessionStorage.getItem('db_info'))
        var publishedAuditData = JSON.parse(sessionStorage.getItem('publishedAuditData'))
        this.setState({
            userData: user_data,
            db_info: db_info,
            client_info: client_info,
            publishedAuditData: publishedAuditData
        },
            () => {
                this.getUnpublishedLocation()
            })
    }

    getAllUsr = () => {
        try {
            urlSocket.post('cog/get-user-info',
                {
                    db_info: this.state.db_info,
                    user_id: this.state.userData.user_data._id,
                    show_all: this.state.userData.user_data.created_by === null ? true : false

                }
            ).then((res) => {
                console.log(res.data.user_list,'res.data.user_list')
                if (res.data.response_code == 500) {
                    this.setState({
                        user_data: res.data.user_list,
                    }, () => { this.loadUserGroup() })
                }
            })

        } catch (error) {

        }
    }

    loadUserGroup = () => {
        try {
            urlSocket.post("userSettings/get-user-group", {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.state.userData.user_data._id,
                    company_id: this.state.userData.user_data.company_id
                }
            })
                .then(response => {
                    //("user label", response)
                    this.setState({
                        labelData: response.data.data,
                        dataLoaded: true,
                        tableDataloaded: true
                        // dataLoaded: true

                    })
                })

        } catch (error) {
            //("catch error", error)
        }


    }





    toggle = () => {
        // this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false)
        this.setState(prevState => ({
            modal: !prevState.modal,
            invalid_user: false,
            invalid_phone_num: false,
            country_code_err: false
        }));
    }



    // changeAuditUserHandler = (event, item, index,type) => {

    //     var checkUserAlreadyExist = _.findIndex(item.adt_users, { user_id: event.target.value });
    //     if (checkUserAlreadyExist !== -1) {
    //         this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
    //     }
    //     else if (event.target.value == "add") {
    //         this.setState({ modal: true ,given_email :'',add_user_type:type ,selected_index :index})
    //     }
    //     else {
    //         var getNewUserIndex = _.findIndex(this.state.user_data, { _id: event.target.value })
    //         var getUser = this.state.user_data[getNewUserIndex]
    //         var audit_user = {
    //             audit_type: "1",
    //             designation: getUser.designation,
    //             name: getUser.firstname,
    //             user_code: getUser.usercode,
    //             user_id: getUser._id,
    //         }
    //         var find_audit_idx = _.findIndex(item.adt_users, { audit_type: "1" })
    //         if (find_audit_idx == -1) {
    //             this.state.statusBasedFilteredData[index].adt_users.push(audit_user)
    //             this.state.statusBasedFilteredData[index].audit_pbd_users.push(audit_user)

    //         }
    //         else {
    //             item.adt_users[find_audit_idx] = audit_user
    //             this.state.statusBasedFilteredData[index].adt_users[find_audit_idx] = item.adt_users[find_audit_idx]
    //             this.state.statusBasedFilteredData[index].audit_pbd_users[find_audit_idx] = item.audit_pbd_users[find_audit_idx]

    //         }

    //         this.setState({ 
    //             statusBasedFilteredData: this.state.statusBasedFilteredData, 
    //             search_files: this.state.statusBasedFilteredData, 
    //             dup_search_files: this.state.statusBasedFilteredData, 
    //             temp_search_files: this.state.statusBasedFilteredData,
    //             dataLoaded: false }, () => { this.setState({ dataLoaded: true },()=>{
    //             this.AssignUser(item)
    //         }) })

    //     }

    // }

    changeAuditUserHandler = (event, item, index, type) => {
        var checkUserAlreadyExist = _.findIndex(item.adt_users, { user_id: event.target.value });
        if (checkUserAlreadyExist !== -1) {
            this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." });
        } else if (event.target.value === "add") {
            this.setState({ modal: true, given_email: '', add_user_type: type, selected_index: index });
        } else {
            var getNewUserIndex = _.findIndex(this.state.user_data, { _id: event.target.value });
            var getUser = this.state.user_data[getNewUserIndex];
            var audit_user = {
                audit_type: "1",
                designation: getUser.designation,
                name: getUser.firstname,
                user_code: getUser.usercode,
                user_id: getUser._id,
            };
            var updatedStatusBasedFilteredData = [...this.state.statusBasedFilteredData]; // Clone the array

            var find_audit_idx = _.findIndex(item.adt_users, { audit_type: "1" });
            if (find_audit_idx === -1) {
                updatedStatusBasedFilteredData[index].adt_users.push(audit_user);
                updatedStatusBasedFilteredData[index].audit_pbd_users.push(audit_user);
            } else {
                updatedStatusBasedFilteredData[index].adt_users[find_audit_idx] = audit_user;
                updatedStatusBasedFilteredData[index].audit_pbd_users[find_audit_idx] = audit_user;
            }
            console.log(updatedStatusBasedFilteredData,'updatedStatusBasedFilteredData',item)
            this.setState({
                statusBasedFilteredData: updatedStatusBasedFilteredData,
                search_files: updatedStatusBasedFilteredData,
                dup_search_files: updatedStatusBasedFilteredData,
                temp_search_files: updatedStatusBasedFilteredData,
                dataLoaded: false
            }, () => {
                this.setState({ dataLoaded: true }, () => {
                    this.AssignUser(item);
                });
            });
        }
    }





    // changeReviewUserHandler = (event, item, index,type) => {
    //     var checkUserAlreadyExist = _.findIndex(item.adt_users, { user_id: event.target.value });
    //     if (checkUserAlreadyExist !== -1) {
    //         this.setState({ statusBasedFilteredData: this.state.statusBasedFilteredData, 
    //             search_files: this.state.statusBasedFilteredData, 
    //             dup_search_files: this.state.statusBasedFilteredData, 
    //             temp_search_files: this.state.statusBasedFilteredData,
    //             dataAlert: true, 
    //             alertMessage: "This user is already selected to audit. Try different user." })
    //     }
    //     else if (event.target.value == "add") {
    //         this.setState({ modal: true,given_email :'',add_user_type:type ,selected_index :index})
    //     }
    //     else {
    //         var getNewUserIndex = _.findIndex(this.state.user_data, { _id: event.target.value })
    //         var getUser = this.state.user_data[getNewUserIndex]
    //         var review_user = {
    //             audit_type: "2",
    //             designation: getUser.designation,
    //             name: getUser.firstname,
    //             user_code: getUser.usercode,
    //             user_id: getUser._id,
    //         }
    //         var find_review_idx = _.findIndex(item.adt_users, { audit_type: "2" })
    //         if (find_review_idx == -1) {
    //             this.state.statusBasedFilteredData[index].adt_users.push(review_user)
    //             this.state.statusBasedFilteredData[index].audit_pbd_users.push(review_user)

    //         }
    //         else {
    //             //    delete this.state.statusBasedFilteredData[index].adt_user[find_review_idx]
    //             item.adt_users[find_review_idx] = review_user
    //             this.state.statusBasedFilteredData[index].adt_users[find_review_idx] = item.adt_users[find_review_idx]
    //             this.state.statusBasedFilteredData[index].audit_pbd_users[find_review_idx] = item.audit_pbd_users[find_review_idx]
    //         }


    //         this.setState({ statusBasedFilteredData: this.state.statusBasedFilteredData, 
    //             search_files: this.state.statusBasedFilteredData, 
    //             dup_search_files: this.state.statusBasedFilteredData,
    //             temp_search_files: this.state.statusBasedFilteredData,
    //             dataLoaded: false }, () => { this.setState({ dataLoaded: true },()=>{
    //             this.AssignUser(item)
    //         }) })



    //     }
    // }

    changeReviewUserHandler = (event, item, index, type) => {
        console.log(event, item, index, type, 'event, item, index, type')
        var checkUserAlreadyExist = _.findIndex(item.adt_users, { user_id: event.target.value });
        if (checkUserAlreadyExist !== -1) {
            this.setState({
                dataAlert: true,
                alertMessage: "This user is already selected to audit. Try different user."
            });
        } else if (event.target.value === "add") {
            this.setState({
                modal: true,
                given_email: '',
                add_user_type: type,
                selected_index: index
            });
        } else {
            var getNewUserIndex = _.findIndex(this.state.user_data, { _id: event.target.value });
            var getUser = this.state.user_data[getNewUserIndex];
            var review_user = {
                audit_type: "2",
                designation: getUser.designation,
                name: getUser.firstname,
                user_code: getUser.usercode,
                user_id: getUser._id,
            };

            var updatedStatusBasedFilteredData = [...this.state.statusBasedFilteredData]; // Clone the array

            var find_review_idx = _.findIndex(item.adt_users, { audit_type: "2" });
            if (find_review_idx === -1) {
                updatedStatusBasedFilteredData[index].adt_users.push(review_user);
                updatedStatusBasedFilteredData[index].audit_pbd_users.push(review_user);
            } else {
                updatedStatusBasedFilteredData[index].adt_users[find_review_idx] = review_user;
                updatedStatusBasedFilteredData[index].audit_pbd_users[find_review_idx] = review_user;
            }

            this.setState({
                statusBasedFilteredData: updatedStatusBasedFilteredData,
                search_files: updatedStatusBasedFilteredData,
                dup_search_files: updatedStatusBasedFilteredData,
                temp_search_files: updatedStatusBasedFilteredData,
                dataLoaded: false
            }, () => {
                this.setState({ dataLoaded: true }, () => {
                    this.AssignUser(item);
                });
            });
        }
    }







    AssignUser = (endpointInfo) => {

        try {
            urlSocket.post('cog/crud-new-location', {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.state.userData.user_data._id
                },
                endpointInfo,
                auditInfo: this.state.publishedAuditData
            }).then((response) => {
                console.log(response, 'response')
            })

        } catch (error) {
            console.log(error, 'error')

        }
    }

    delete_location = (item, index) => {

        Swal.fire({
            icon: 'error', // You can use 'warning', 'error', or any other appropriate icon
            title: 'Delete Confirmation',
            text: 'Are you sure you want to delete this item?', // Customize the confirmation message
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            // The 'result' variable will be true if the user clicks on the 'Delete' button,
            // or false if the user clicks on the 'Cancel' button or closes the alert.

            if (result.isConfirmed) {
                try {
                    urlSocket.post('cog/delete-location',
                        {
                            endpointInfo: item,
                            encrypted_db_url: this.state.db_info.encrypted_db_url,
                            user_id: this.state.userData.user_data._id

                        }).then((response) => {
                            if (response.data.response_code === 500) {
                                this.setState({ dataLoaded: false }, () => { this.setState({ dataLoaded: true }, () => { this.getUnpublishedLocation();; this.pageClick(1) }) })

                            }
                        })

                } catch (error) {

                }
            }
        })

    }


    publishLocation = (mode) => {
        if (mode == 0) {
            var not_user_assigned_location = []
            var user_assigned_location = _.filter(this.state.statusBasedFilteredData, e => {
                if (e.adt_users.length > 0) {
                    return e
                }
                else {
                    not_user_assigned_location.push(e)
                }
            })
            if (not_user_assigned_location.length == this.state.statusBasedFilteredData.length) {

                Swal.fire({
                    icon: 'warning',
                    title: 'Nothing to Skip !',
                    text: `Not even a single location has assigned user,you cannot publish !`,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                }).then((result) => {



                })








                // Swal.fire({
                //     icon: 'warning', // You can use 'warning', 'error', or any other appropriate icon
                //     title: 'Publish Confirmation',
                //     text: 'Are you sure you want to publish this location?', // Customize the confirmation message
                //     showCancelButton: true,
                //     confirmButtonColor: '#d33',
                //     cancelButtonColor: '#3085d6',
                //     confirmButtonText: 'Publish',
                //     cancelButtonText: 'Cancel',
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         console.log(this.state.statusBasedFilteredData, 'Publish Confirmation', this.state.publishedAuditData)

                //         if (this.state.publishedAuditData.enable_review == true) {

                //             var user_assigned_location = _.filter(this.state.statusBasedFilteredData, e => {
                //                 if (e.adt_users.length > 1) {
                //                     // console.log(e,'user_assigned_location')
                //                     return e
                //                 }
                //             })
                //         }
                //         else {
                //             var user_assigned_location = _.filter(this.state.statusBasedFilteredData, e => {
                //                 if (e.adt_users.length > 0) {
                //                     // console.log(e,'user_assigned_location')
                //                     return e
                //                 }
                //             })

                //         }
                //         console.log(user_assigned_location, 'user_assigned_location')
                //         this.publishBulkLocation(user_assigned_location)

                //     } else {
                //         // User clicked the 'Cancel' button or closed the dialog
                //         // Implement any additional logic if needed
                //     }
                // });
            }
            else {
                Swal.fire({
                    icon: 'warning', // You can use 'warning', 'error', or any other appropriate icon
                    title: 'Publish Confirmation',
                    text: 'Are you sure you want to publish this location?', // Customize the confirmation message
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Publish',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {

                        if (this.state.publishedAuditData.enable_review == true) {

                            var user_assigned_location = _.filter(this.state.statusBasedFilteredData, e => {
                                if (e.adt_users.length > 1) {
                                    return e
                                }
                            })
                        }
                        else {
                            var user_assigned_location = _.filter(this.state.statusBasedFilteredData, e => {
                                if (e.adt_users.length > 0) {
                                    return e
                                }
                            })

                        }
                        this.publishBulkLocation(user_assigned_location)

                    } else {
                        // User clicked the 'Cancel' button or closed the dialog
                        // Implement any additional logic if needed
                    }
                });

            }
        }
        if (mode == 1) {
            var not_user_assigned_location = []
            if (this.state.publishedAuditData.enable_review == true) {
                console.log(this.state.statusBasedFilteredData,'this.state.statusBasedFilteredData')
                var user_assigned_location = _.filter(this.state.statusBasedFilteredData, e => {
                    if (e.adt_users.length > 1) {
                        return e
                    }
                    else {
                        not_user_assigned_location.push(e)
                    }
                })
            }
            else {
                var user_assigned_location = _.filter(this.state.statusBasedFilteredData, e => {
                    if (e.adt_users.length > 0) {
                        return e
                    }
                    else {
                        not_user_assigned_location.push(e)
                    }
                })

            }
            if (not_user_assigned_location.length > 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'User Not Assigned',
                    text: `you cannot publish all location because ${not_user_assigned_location.length} location does'nt assigned any users !`,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getUnpublishedLocation()
                    }

                })

            }
            else {
                Swal.fire({
                    icon: 'warning', // You can use 'warning', 'error', or any other appropriate icon
                    title: 'Publish Confirmation',
                    text: 'Are you sure you want to publish this location?', // Customize the confirmation message
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Publish',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {

                        this.publishBulkLocation(user_assigned_location)
                    }
                })

            }
        }

    }


    publishBulkLocation = (user_assigned_location) => {
        var getEndPoint = JSON.parse(sessionStorage.getItem('Locationdata'))
        console.log(' this.state.db_info.encrypted_db_url', user_assigned_location, this.state.publishedAuditData, user_assigned_location)
        user_assigned_location.map((data, index) => {
            user_assigned_location[index]["audit_pbd_ends_on"] = getEndPoint[0]["audit_pbd_ends_on"]
        })
        console.log(this.state.publishedAuditData, 'this.state.publishedAuditData',user_assigned_location)
        try {
            urlSocket.post("webpbdadtdta/publish-bulk-location", {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.state.userData.user_data._id,
                    company_id: this.state.userData.user_data.company_id
                },
                endpointInfo: user_assigned_location,
                auditInfo: this.state.publishedAuditData
            })
                .then(response => {
                    if (response.data.response_code == 500) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Publish Successfully',
                            text: 'Location published successfully',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Go to Published Audits',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.props.history.push('/pbdrpt')
                            }
                            else {
                                this.getUnpublishedLocation()
                            }
                        });
                    }


                })

        } catch (error) {
            console.log("catch error", error)
        }
    }






    getUnpublishedLocation = () => {
        this.setState({ dataLoaded: false })
        var encrypted_db_url = this.state.db_info.encrypted_db_url
        try {
            urlSocket.post('cog/get-unpblshd-location', { encrypted_db_url, audit_pbd_id: this.state.publishedAuditData._id }).then((response) => {
                if (response.data.response_code == 500) {
                    this.setState({
                        dataLoaded: true,
                        statusBasedFilteredData: response.data.data,
                        dup_statusBasedFilteredData: response.data.data,
                        search_files: response.data.data,
                        dup_search_files: response.data.data,
                        temp_search_files: response.data.data,
                    }, () => this.getAllUsr())
                }
            })

        } catch (error) {

        }
    }


    emai_addrs = (e) => {
        var email = e.target.value
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            this.setState({ given_email: email, errorMessage: false, invalid_user: false })
        }
        else {
            this.setState({ given_email: email, errorMessage: true })
        }

    }

    select_country = (e) => {
        this.setState({ countrycode: e.target.value, country_code_err: false })
    }


    validate_email = (e, mode) => {
        var admn_info = {}
        if (mode == 1) {
            admn_info["email_id"] = e.target.value
        }
        if (mode === 2) {
            admn_info["phone_number"] = e.target.value
        }
        if (mode === 3) {
            admn_info["usercode"] = e.target.value
        }
        admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        admn_info["db_name"] = this.state.db_info.db_name
        admn_info["user_id"] = this.state.userData.user_data._id



        try {
            urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
                if (mode == 1) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_user: true })
                    }
                    else {
                        this.setState({ invalid_user: false })
                    }
                }
                if (mode == 2) {

                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_phone_num: true })
                    }
                    else {
                        this.setState({ invalid_phone_num: false })
                    }
                }
                if (mode === 3) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_usercode: true })
                    }
                    else {
                        this.setState({ invalid_usercode: false })
                    }


                }
            })

        } catch (error) {

        }


    }

    getSelectedEOPT = (row, isSelect, rowIndex, e) => {

        var getEOPTId = _.map(this.state.selectedEOPT, "_id")
        var getIndex = getEOPTId.indexOf(row._id)

        if (getIndex === -1) {
            this.state.selectedEOPT.push(row)

            this.setState({
                selectedEOPT: this.state.selectedEOPT
            })
        }
        else {
            this.state.selectedEOPT.splice(getIndex, 1)
            this.setState({
                selectedEOPT: this.state.selectedEOPT
            })
        }


    }
    Validsubmit = (event, values) => {
        // values["countrycode"] = this.state.countrycode
        var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
        values["password"] = password
        values["confirmpassword"] = password
        values["userPoolId"] = this.state.client_info.userPoolId
        values["clientId"] = this.state.client_info.clientId
        values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        values["db_name"] = this.state.db_info.db_name
        values["facilities"] = this.state.client_info.facilities
        values["short_name"] = this.state.client_info.short_name
        values["company_id"] = this.state.userData.user_data.m_client_id
        values["created_by"] = this.state.userData.user_data._id
        values["user_id"] = this.state.userData.user_data._id

        if (this.state.countrycode === undefined) {
            this.setState({ country_code_err: true })
        }
        else {
            values["countrycode"] = this.state.countrycode
            this.setState({ country_code_err: false })

        }

        if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false) {
            this.setState({ loading: true })
            try {
                urlSocket.post('cog/cruduser', values).then((response) => {
                    if (response.data.response_code == 500) {
                        var user_data = response.data.admn_user[0]
                        this.state.user_data.push(user_data)
                        this.setState({ loading: false, user_data: this.state.user_data, modal: false })


                    }
                })
            } catch (error) {

            }
        }



        // try {
        //     urlSocket.post('cog/cruduser')

        // } catch (error) {

        // }


    }

    onDrawerClose = () => {
        this.setState({ open: false })
        //    this.setState({open: false, dataLoaded: false},()=>{this.setState({ dataLoaded: true });this.pageClick(1)}) 
    }

    handleChange = (event, data) => {
        if (event.target.name == "audit") {
            var auditer_id = event.target.value
            this.setState({ auditer_id: auditer_id })
            if (this.state.review_id == event.target.value) {
                this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
            }
            else {
                if (this.state.publishedAuditData.enable_review == true && this.state.review_id !== '') {
                    this.setState({ dataAlert: false, alertMessage: "", show_btn: true })
                }
                else if (this.state.publishedAuditData.enable_review == false) {
                    this.setState({ dataAlert: false, alertMessage: "", show_btn: true })
                }
                else {
                    this.setState({ dataAlert: false, alertMessage: "" })
                }

            }
        }
        else {
            var review_id = event.target.value
            this.setState({ review_id: review_id })
            if (this.state.auditer_id == event.target.value) {
                this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
            }
            else {
                // this.setState({dataAlert : false ,alertMessage: "", show_btn : true})
                if (this.state.publishedAuditData.enable_review == true && this.state.auditer_id !== '') {
                    this.setState({ dataAlert: false, alertMessage: "", show_btn: true })
                }
                else {
                    this.setState({ dataAlert: false, alertMessage: "" })
                }

            }
        }
    }

    applicableUserAll = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'Want to apply this user for all locations?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                this.AssignUserAll(this.state.auditer_id, this.state.review_id, this.state.statusBasedFilteredData)

            } else {
                console.log("Cancelled");
            }
        });

    }

    AssignUserAll = (auditer, reviewer, data) => {
        if (auditer !== undefined && reviewer !== undefined && reviewer !== "") {
            var get_auditer_data = _.filter(this.state.user_data, { "_id": auditer })
            var get_reviewer_data = _.filter(this.state.user_data, { "_id": reviewer })
            var audit_user = {
                audit_type: "1",
                designation: get_auditer_data[0].designation,
                name: get_auditer_data[0].firstname,
                user_code: get_auditer_data[0].usercode,
                user_id: get_auditer_data[0]._id,
            }
            var review_user = {
                audit_type: "2",
                designation: get_reviewer_data[0].designation,
                name: get_reviewer_data[0].firstname,
                user_code: get_reviewer_data[0].usercode,
                user_id: get_reviewer_data[0]._id,
            }

            data.map((ele, idx) => {
                if (ele.adt_users.length > 0) {
                    var auditer_idx = _.findIndex(ele.adt_users, { audit_type: "1" })
                    var review_idx = _.findIndex(ele.adt_users, { audit_type: "2" })
                    if (review_idx == -1) {
                        ele.adt_users.push(review_user)
                        ele.audit_pbd_users.push(review_user)
                    }
                    if (auditer_idx == -1) {
                        ele.adt_users.push(audit_user)
                        ele.audit_pbd_users.push(audit_user)
                    }
                    if (auditer_idx !== -1) {
                        ele.adt_users[auditer_idx] = audit_user
                        ele.audit_pbd_users[auditer_idx] = audit_user
                    }
                    if (review_idx !== -1) {
                        ele.adt_users[review_idx] = review_user
                        ele.audit_pbd_users[review_idx] = review_user
                    }
                }
                else {
                    ele.adt_users.push(audit_user)
                    ele.audit_pbd_users.push(audit_user)
                    ele.adt_users.push(review_user)
                    ele.audit_pbd_users.push(review_user)
                }

            })
            this.updataAllLoc(data)

        }
        else {
            var get_auditer_data = _.filter(this.state.user_data, { "_id": auditer })
            var audit_user = {
                audit_type: "1",
                designation: get_auditer_data[0].designation,
                name: get_auditer_data[0].firstname,
                user_code: get_auditer_data[0].usercode,
                user_id: get_auditer_data[0]._id,
            }

            data.map((ele, idx) => {
                if (ele.adt_users.length > 0) {
                    var auditer_idx = _.findIndex(ele.adt_users, { audit_type: "1" })
                    ele.adt_users[auditer_idx] = audit_user
                    ele.audit_pbd_users[auditer_idx] = audit_user
                }
                else {
                    ele.adt_users.push(audit_user)
                    ele.audit_pbd_users.push(audit_user)
                }
            })
            this.updataAllLoc(data)
        }



    }







    // handleChange =(event,data)=>{
    //     console.log(event.target.value,data,'event',this.state.user_data)
    //     var filter_userdata =  _.filter(this.state.user_data,{"_id":event.target.value})
    //     console.log(filter_userdata,'filter_userdata')
    //     if(event.target.name == "audit"){
    //         var audit_user = {
    //             audit_type: "1",
    //             designation: filter_userdata[0].designation,
    //             name: filter_userdata[0].firstname,
    //             user_code: filter_userdata[0].usercode,
    //             user_id: filter_userdata[0]._id,
    //         }
    //         console.log(audit_user,'audit_user')
    //         var user_assigned = false
    //         data.map((ele, idx) => {
    //             if (ele.adt_users.length > 0) {
    //                 var auditer_idx = _.findIndex(ele.adt_users,{audit_type:"1"})
    //                 console.log(auditer_idx,'auditer_idx')
    //                 // var find_exist_user =  ele.adt_users.find((fcty) => fcty.user_id === event.target.value)
    //                 // console.log(find_exist_user,'find_exist_user')
    //                 // if (find_exist_user == undefined) {
    //                     ele.adt_users[auditer_idx] = audit_user
    //                     ele.audit_pbd_users[auditer_idx] = audit_user
    //                 // }
    //                 // else{
    //                 //     user_assigned = true
    //                 // }
    //                 // ele.audit_pbd_users.push(audit_user)
    //             }
    //             else{
    //                 ele.adt_users.push(audit_user)
    //                 ele.audit_pbd_users.push(audit_user)
    //             }
    //         })
    //         console.log(data,'data',user_assigned)
    //         var user_id_to_check=event.target.value
    //     //     var check_user_valid = _.filter(data, (e) =>
    //     //     // console.log(e.adt_users)
    //     //     e.adt_users.find((fcty) => fcty.user_id === user_id_to_check).length < 1
    //     //     // _.some(e.adt_users, { user_id: user_id_to_check }).length < 1
    //     //   )

    //     //   console.log(check_user_valid, 'check_user_valid');

    //         // this.setState({statusBasedFilteredData : data})
    //         this.updataAllLoc(data)

    //     }
    //     if(event.target.name == 'review'){
    //         var review_user ={
    //             audit_type: "2",
    //             designation: filter_userdata[0].designation,
    //             name: filter_userdata[0].firstname,
    //             user_code: filter_userdata[0].usercode,
    //             user_id: filter_userdata[0]._id,
    //         }
    //         data.map((ele, idx)=>{
    //             if (ele.adt_users.length > 1) {
    //                 var reviewer_idx = _.findIndex(ele.adt_users,{audit_type:"2"})
    //                 console.log(reviewer_idx,'reviewer_idx')
    //                 ele.adt_users[reviewer_idx]=review_user
    //                 ele.audit_pbd_users[reviewer_idx]=review_user
    //             }
    //             else{
    //                 ele.adt_users.push(review_user)
    //                 ele.audit_pbd_users.push(review_user)
    //             }
    //         })
    //         this.updataAllLoc(data)
    //     }

    // }   

    updataAllLoc = (data) => {

        try {
            urlSocket.post('cog/crud-new-location', {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.state.userData.user_data._id,
                    company_id: this.state.userData.user_data.company_id
                },
                endpointInfo: data,
                auditInfo: this.state.publishedAuditData,
                assignAll: true
            }).then((response) => {
                if (response.data.response_code == 500) {
                    this.setState({
                        statusBasedFilteredData: data,
                        search_files: data,
                        dup_search_files: data,
                        temp_search_files: data,
                        dataLoaded: false
                    }, () => { this.setState({ dataLoaded: true }) })
                }
            })

        } catch (error) {
            console.log(error, 'error')
        }

    }

    group_name = (event) => {
        if (event.target.value == "all") {
            this.getAllUsr()
        }
        else {
            // var findGroupIdx = _.findIndex(this.state.labelData,{"_id": event.target.value})          
            this.load_group_users(this.state.labelData[event.target.value])
        }
        this.setState({ group_selected: event.target.value })

    }

    load_group_users = (group_info) => {
        try {

            urlSocket.post("userSettings/load-group-users", {
                labelInfo: group_info,
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.state.userData.user_data._id,
                    company_id: this.state.userData.user_data.company_id
                }
            })
                .then(response => {
                    if (response.data.response_code == 500) {
                        this.setState({ user_data: response.data.data, dataLoaded: false }, () => { this.setState({ dataLoaded: true }) })
                    }
                })
        }
        catch (error) {
            console.log(error, 'error')
        }


    }


    deleteAllLocation = (event) => {
        Swal.fire({
            icon: 'error', // You can use 'warning', 'error', or any other appropriate icon
            title: 'Delete Confirmation',
            text: 'Are you sure you want to delete all location?', // Customize the confirmation message
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                var obj_data = {}
                obj_data["encrypted_db_url"] = this.state.db_info.encrypted_db_url
                obj_data["audit_pbd_id"] = this.state.publishedAuditData._id
                obj_data["user_id"] = this.state.userData.user_data._id

                try {
                    urlSocket.post("cog/delete-all-temp-location", obj_data).then((response) => {
                        if (response.data.response_code == 500) {
                            this.setState({ dataLoaded: false }, () => { this.setState({ dataLoaded: true }, () => { this.getUnpublishedLocation() }) })
                            // this.getUnpublishedLocation()
                        }
                    })
                } catch (error) {
                    console.log(error, 'error')
                }
            }
        })
    }

    toggle = () => {
        this.setState({
            modal: false,
            show: true
        });
    };


    pageClick = (currentPage) => {
        this.setState({ currentPage }, () => { this.paginationProcess() })
    }

    paginationProcess = () => {
        let { currentPage, items_per_page, search_files, dup_search_files } = this.state
        let result = search_files.slice((currentPage - 1) * items_per_page, (currentPage - 1) * items_per_page + items_per_page)
        console.log(search_files, 'search_files', result)

        if (result.length > 0) {
            this.setState({ statusBasedFilteredData: result })
        } else {
            this.setState({ statusBasedFilteredData: search_files, search_files })
        }
    }

    getFuzzySearch = (search_files) => {
        // this.setState({ statusBasedFilteredData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files })
        this.setState({ statusBasedFilteredData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files }, () => { this.pageClick(this.state.currentPage) })

        // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })
    }

    gotoBack = () => {
        this.props.history.push('/pbdrpt')
    }




    render() {
        if (this.state.dataLoaded == true) {
            // const { SearchBar } = Search;
            const options = {
                sizePerPage: 10,
                totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
                custom: true,
            };
            var columns = []
            if (this.state.publishedAuditData.enable_review) {
                columns = [
                    {
                        accessor: 'name',
                        Header: 'Name',
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
                            var getIndex_rev = this.state.publishedAuditData.enable_review ? _.findIndex(item.adt_users, { audit_type: "2" }) : 5
                            return (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className=" font-size-14 " style={{ marginBottom: 5, color: getIndex === -1 ? "#f46a6a" : getIndex_rev == -1 ? "#f46a6a" : null }}>
                                            {item.name}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    },
                    {
                        accessor: 'code',
                        Header: 'Code',
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
                            var getIndex_rev = this.state.publishedAuditData.enable_review ? _.findIndex(item.adt_users, { audit_type: "2" }) : 5
                            return (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className=" font-size-14 " style={{ marginBottom: 5, color: getIndex === -1 ? "#f46a6a" : getIndex_rev == -1 ? "#f46a6a" : null }}>
                                            {item.code}
                                        </div>
                                    </div>
                                </>
                            )

                        }

                    },
                    {
                        accessor: 'category',
                        Header: 'Category',
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
                            var getIndex_rev = this.state.publishedAuditData.enable_review ? _.findIndex(item.adt_users, { audit_type: "2" }) : 5
                            return (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className=" font-size-14 " style={{ marginBottom: 5, color: getIndex === -1 ? "#f46a6a" : getIndex_rev == -1 ? "#f46a6a" : null }}>
                                            {item.category}
                                        </div>
                                    </div>
                                </>
                            )

                        }
                    },
                    {
                        accessor: 'adt_users',
                        Header: 'Audit Assigned to',
                        // sort: true,
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var index = cellProps.row.index
                            var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
                            console.log(this.state.user_data,'this.state.user_data')
                            return (
                                <>
                                    <div>
                                        <select
                                            type="select"
                                            className="form-select"
                                            defaultValue={"choose"}
                                            name="audit"
                                            value={getIndex == -1 ? "choose" : item.adt_users[getIndex].user_id}
                                            // onChange={(e)=>{this.handleChange(e,item)}}
                                            onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}
                                            disabled={this.state.statusBasedFilteredData.length == 0 ? true : false}
                                        >
                                            <option value="choose" disabled >Choose...</option>
                                            <option value="add" >Add New User</option>
                                            {
                                                this.state.user_data.map((data, idx) => {
                                                    return (
                                                        <option value={data._id} key={idx}>{data.firstname}</option>
                                                    )
                                                })
                                            }


                                        </select>
                                    </div>

                                </>

                            )
                        }

                    },
                    {
                        accessor: '',
                        Header: 'Review Assigned to',
                        // show: this.state.publishedAuditData.enable_review ? false : true,
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var index = cellProps.row.index
                            var getIndex = _.findIndex(item.adt_users, { audit_type: "2" })
                            return (
                                <>
                                    <div>
                                        <select
                                            type="select"
                                            className="form-select"
                                            defaultValue={"choose"}
                                            name="review"
                                            value={getIndex == -1 ? "choose" : item.adt_users[getIndex].user_id}
                                            // onChange={(e)=>{this.handleChange(e,item)}}
                                            onChange={(e) => this.changeReviewUserHandler(e, item, index, "2")}
                                            disabled={this.state.statusBasedFilteredData.length == 0}
                                        >
                                            <option value="choose" disabled >Choose...</option>
                                            <option value="add" >Add New User</option>
                                            {
                                                this.state.user_data.map((data, idx) => {
                                                    return (
                                                        <option value={data._id} key={idx}>{data.firstname}</option>
                                                    )
                                                })
                                            }


                                        </select>
                                    </div>

                                </>

                            )
                        },
                    },
                    {
                        accessor: "menu",
                        // isDummyField: true,
                        // editable: false,
                        Header: "Action",
                        // hidden: this.state.methodSelected === "2" ? true : false,
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var index = cellProps.row.index
                            return (
                                <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                                    {/* <Link className="text-primary" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => { this.setState({ open: true, component: "endpoint", editItem: item }) }}></i></Link> */}
                                    <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => { this.delete_location(item, index) }}></i></Link>
                                </div>
                            )
                        },
                    },
                ]
            }
            else {
                columns = [
                    {
                        accessor: 'name',
                        Header: 'Name',
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
                            var getIndex_rev = this.state.publishedAuditData.enable_review ? _.findIndex(item.adt_users, { audit_type: "2" }) : 5
                            return (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className=" font-size-14 " style={{ marginBottom: 5, color: getIndex === -1 ? "#f46a6a" : getIndex_rev == -1 ? "#f46a6a" : null }}>
                                            {item.name}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    },
                    {
                        accessor: 'code',
                        Header: 'Code',
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
                            var getIndex_rev = this.state.publishedAuditData.enable_review ? _.findIndex(item.adt_users, { audit_type: "2" }) : 5
                            return (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className=" font-size-14 " style={{ marginBottom: 5, color: getIndex === -1 ? "#f46a6a" : getIndex_rev == -1 ? "#f46a6a" : null }}>
                                            {item.code}
                                        </div>
                                    </div>
                                </>
                            )

                        }

                    },
                    {
                        accessor: 'category',
                        Header: 'Category',
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
                            var getIndex_rev = this.state.publishedAuditData.enable_review ? _.findIndex(item.adt_users, { audit_type: "2" }) : 5
                            return (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className=" font-size-14 " style={{ marginBottom: 5, color: getIndex === -1 ? "#f46a6a" : getIndex_rev == -1 ? "#f46a6a" : null }}>
                                            {item.category}
                                        </div>
                                    </div>
                                </>
                            )

                        }
                    },
                    {
                        accessor: 'adt_users',
                        Header: 'Audit Assigned to',
                        // sort: true,
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var index = cellProps.row.index
                            var getIndex = _.findIndex(item.adt_users, { audit_type: "1" })
                            return (
                                <>
                                    <div>
                                        <select
                                            type="select"
                                            className="form-select"
                                            defaultValue={"choose"}
                                            name="audit"
                                            value={getIndex == -1 ? "choose" : item.adt_users[getIndex].user_id}
                                            // onChange={(e)=>{this.handleChange(e,item)}}
                                            onChange={(e) => this.changeAuditUserHandler(e, item, index, "1")}
                                            disabled={this.state.statusBasedFilteredData.length == 0 ? true : false}
                                        >
                                            <option value="choose" disabled >Choose...</option>
                                            <option value="add" >Add New User</option>
                                            {
                                                this.state.user_data.map((data, idx) => {
                                                    return (
                                                        <option value={data._id} key={idx}>{data.firstname}</option>
                                                    )
                                                })
                                            }


                                        </select>
                                    </div>

                                </>

                            )
                        }

                    },
                    {
                        accessor: "menu",
                        // isDummyField: true,
                        // editable: false,
                        Header: "Action",
                        // hidden: this.state.methodSelected === "2" ? true : false,
                        disableFilters: true,
                        Cell: (cellProps) => {
                            var item = cellProps.row.original
                            var index = cellProps.row.index
                            return (
                                <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                                    {/* <Link className="text-primary" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => { this.setState({ open: true, component: "endpoint", editItem: item }) }}></i></Link> */}
                                    <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => { this.delete_location(item, index) }}></i></Link>
                                </div>
                            )
                        },
                    },
                ]

            }

            // const selectRow = {
            //     mode: 'checkbox',
            //     clickToSelect: true,
            //     onSelect: (row, isSelect, rowIndex, e) => {
            //         console.log(row, isSelect, rowIndex, e)
            //         this.getSelectedEOPT(row, isSelect, rowIndex, e)
            //     },
            //     onSelectAll: (isSelect, rows, e) => {
            //         console.log(isSelect, rows, e)

            //     },
            // }




            return (
                <React.Fragment>
                    <div className="page-content">
                        <MetaTags>
                            <title>AuditVista | Location</title>
                        </MetaTags>
                        <Container fluid>

                            <Breadcrumbs
                                title={this.state.publishedAuditData.template_name}
                                link={"Audit / "}
                                description={""}
                                changeAuditEditMode={() => {
                                    this.setState(prevState => ({
                                        modal: !prevState.modal,
                                    }))
                                }}
                                breadcrumbItem="Template"
                                isBackButtonEnable={true}
                                gotoBack={() => this.gotoBack()}
                            />

                            <Card >
                                <CardBody>

                                    <TableContainer
                                        columns={columns}
                                        data={this.state.statusBasedFilteredData}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        isJobListGlobalFilter={false}
                                        customPageSize={10}
                                        style={{ width: '100%' }}
                                        isPagination={true}
                                        filterable={false}
                                        onClickNewLocation={() => {
                                            this.setState({ component: "create", open: true })
                                        }}
                                        onClickLoadLocation={() => {
                                            this.setState({ component: "load", open: true })
                                        }}

                                        group_selected={this.state.group_selected}
                                        statusBasedFilteredData={this.state.statusBasedFilteredData}
                                        labelData={this.state.labelData}
                                        group_name={(e) => this.group_name(e)}

                                        user_data={this.state.user_data}
                                        handleChange={(e) => this.handleChange(e, this.state.statusBasedFilteredData)}

                                        show_btn={this.state.show_btn}
                                        applicableUserAll={() => this.applicableUserAll()}
                                        deleteAllLocation={(e) => this.deleteAllLocation(e)}

                                        tableClass="align-middle table-nowrap table-check"
                                        theadClass="table-light"
                                        pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                    />


                                    <Row>
                                        {
                                            this.state.tableDataloaded ?
                                                <>

                                                    <Row className='mt-2'>
                                                        <Col style={{ textAlign: 'end' }} md={12}>
                                                            <Button disabled={this.state.statusBasedFilteredData.length == 0} onClick={() => { this.publishLocation(0) }} color='primary'>{"Skip & Publish Location"}</Button>&nbsp;
                                                            <Button disabled={this.state.statusBasedFilteredData.length == 0} onClick={() => { this.publishLocation(1) }} color='success'>{"Publish Location"}</Button>

                                                        </Col>
                                                    </Row>
                                                </>



                                                : null
                                        }
                                    </Row>
                                    <Col>

                                    </Col>

                                </CardBody>
                            </Card>

                        </Container>
                    </div>
                    <Modal isOpen={this.state.modal} toggle={this.toggle}  >
                        <ModalHeader toggle={this.toggle} tag="h4">
                            Add User
                        </ModalHeader>
                        <ModalBody>
                            <AvForm className="form-horizontal" onValidSubmit={this.Validsubmit}>
                                <div className="mb-1">
                                    <label>Full Name :<span className="text-danger"> *</span></label>
                                    <AvField
                                        name="firstname"
                                        // label="First Name"
                                        errorMessage="Please enter your name"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className="mb-1">
                                    <label>Email Id :<span className="text-danger"> *</span></label>
                                    <AvField
                                        name="email_id"
                                        onChange={(e) => { this.emai_addrs(e); this.validate_email(e, 1) }}
                                        errorMessage="Please enter your Email ID"
                                        onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("null") }}
                                        // onChange={(e) => { this.validate_email(e, 1) }}
                                        className="form-control"
                                        placeholder="Enter User Email"
                                        type="email"
                                        required
                                    />
                                </div>
                                {
                                    this.state.errorMessage &&
                                    <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>
                                }
                                {
                                    this.state.invalid_user &&
                                    <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
                                }
                                <Row>
                                    <Col className="col-12">
                                        <Row>
                                            <label>Phone Number:<span className="text-danger"> *</span></label>
                                            <Col md={3} className="pe-0">
                                                <select name="countrycode" onChange={(e) => { this.select_country(e) }} className="form-select" required>
                                                    <option value="">Select</option>
                                                    {
                                                        this.state.client_info.countries.map((c, idx) => (
                                                            <option key={idx} value={c.code}>
                                                                {c.code}{""}&nbsp;{c.label}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </Col>
                                            <Col md={9} className="ps-0">
                                                <AvField
                                                    name="phone_number"
                                                    className="form-control"
                                                    placeholder="Enter Phone number"
                                                    errorMessage="Please enter your Phone Number"
                                                    onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("null") }}
                                                    type="number"
                                                    onChange={(e) => { this.validate_email(e, 2) }}
                                                    validate={{
                                                        required: { value: true },
                                                        minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                        maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                    }}
                                                />

                                                {
                                                    this.state.country_code_err &&
                                                    <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Please select the country code</div>

                                                }
                                                {
                                                    this.state.invalid_phone_num &&
                                                    <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <div className="mb-3">
                                    <label>Username :<span className="text-danger"> *</span></label>
                                    <AvField
                                        name="username"
                                        type="text"
                                        required
                                        placeholder="Enter username"
                                        disabled={true}
                                        value={this.state.given_email}
                                    />
                                </div>


                                {/* <div className="mb-3">
                                    <label>Password :<span className="text-danger"> *</span></label>
                                    <AvField
                                        name="password"
                                        // label="Password"
                                        autoComplete="new-password"
                                        type="password"
                                        errorMessage="Please enter your Password"
                                        required
                                        placeholder="Enter password"
                                        // onKeyPress={(e)=>{this.handleKeyPress(e)}}
                                        validate={{
                                            required: { value: true, errorMessage: 'Password is required' },
                                            minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
                                            pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                        }}
                                    />
                                </div> */}

                                <Row>
                                    <Col xl={11} md={11} sm={11} xs={11}>
                                        <div className="mb-3">
                                            <AvField
                                                name="password"
                                                // label="Password"
                                                autoComplete="new-password"
                                                type={
                                                    this.state.show
                                                        ? "password"
                                                        : "text"
                                                }
                                                errorMessage="Please enter your Password"
                                                required
                                                placeholder="Enter password"
                                                // onKeyPress={(e)=>{this.handleKeyPress(e)}}
                                                validate={{
                                                    required: { value: true, errorMessage: 'Password is required' },
                                                    minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
                                                    pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                                }}
                                            />
                                            {/* <button onClick={() => this.setState({show:!this.state.show})} className="btn btn-light " type="button" id="password-addon">
                                                                            <i className={this.state.show ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i></button> */}
                                        </div>
                                    </Col>

                                    <Col
                                        xl={1} md={1} sm={1} xs={1}
                                        style={{ marginLeft: "-24px" }}
                                    >
                                        <button
                                            onClick={() =>
                                                this.setState({
                                                    show: !this.state.show,
                                                })
                                            }
                                            className="btn btn-light "
                                            type="button"
                                            id="password-addon"
                                        >
                                            <i
                                                className={
                                                    this.state.show
                                                        ? "mdi mdi-eye-off"
                                                        : "mdi mdi-eye-outline"
                                                }
                                            ></i>
                                        </button>
                                    </Col>
                                </Row>

                                <div className="mb-3 d-grid">
                                    <button type="submit" className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Add User"}
                                    </button>
                                    {
                                        (this.state.country_code_err || this.state.invalid_user || this.state.invalid_phone_num) &&
                                        <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Please fill the above mandatory fields.</div>


                                    }
                                </div>


                            </AvForm>


                        </ModalBody>
                    </Modal>

                    <ReactDrawer
                        open={this.state.open}
                        className="drawer-open"
                        position={this.state.position}
                        onClose={this.onDrawerClose}
                        style={{ maxHeight: 1000 }}
                        noOverlay={false}
                    >
                        {
                            this.state.component === "create" ?
                                <AddEndpointNode
                                    user_data={this.state.userData.user_data}
                                    // endpoints={this.props.location.state.endpoints}
                                    publishtemplateInfo={this.state.publishedAuditData}
                                    onClose={this.onDrawerClose}
                                    editItem={null}
                                    onCancel={this.onDrawerClose}
                                    getUnpublishedLocation={this.getUnpublishedLocation}
                                />
                                :
                                this.state.component == "load" ?
                                    <LoadLocation
                                        userInfo={this.state.userData.user_data}
                                        publishtemplateInfo={this.state.publishedAuditData}
                                        onClose={this.onDrawerClose}
                                        toFilterData={this.state.dup_statusBasedFilteredData}
                                        // endpoints={this.props.location.state.endpoints}
                                        getUnpublishedLocation={this.getUnpublishedLocation}
                                    />
                                    :
                                    null
                        }

                    </ReactDrawer>
                    {this.state.dataAlert ? (
                        <SweetAlert
                            title={this.state.alertMessage}
                            onConfirm={() => this.setState({ dataAlert: false, dataLoaded: false, auditer_id: '', review_id: '' }, () => { this.setState({ dataLoaded: true }) })}
                        ></SweetAlert>
                    ) : null}
                </React.Fragment>

            )
        }
        else {
            return null
        }
    }
}

// Location.propTypes = propTypes;
// Location.defaultProps = defaultProps;
// #endregion

export default Location;