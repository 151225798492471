import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Spinner
} from "reactstrap";
import TableContainer from "./Components/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getAplnAuditData } from "../../store/aplnfollowup/aplnflwupslice"
import Breadcrumbs from "../../components/Common/Breadcrumb"


const AplnAuditList = (props) => {

    const dispatch = useDispatch()
    const { aplnAuditList } = useSelector(state => state.acplnFollowUpSliceReducer)


    useEffect(() => {
        dispatch(getAplnAuditData());

        return () => {
        };
    }, [dispatch]);

    const loadEndpoints = (audit) => {
        sessionStorage.removeItem("auditData");
        sessionStorage.setItem("auditData", JSON.stringify(audit));
        props.history.push("/adtlctns");
    };

    const columns = useMemo(
        () => [
            {
                accessor: "activity_name",
                Header: "Audit",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark fw-bold"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                loadEndpoints(item);
                            }}
                        >
                            {item.activity_name}
                        </div>
                    );
                },
            },
            {
                accessor: "assigned_on",
                Header: "Publish On",
                disableFilters: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="">
                            {new Intl.DateTimeFormat("en-GB", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            }).format(new Date(item.assigned_on))}
                        </div>
                    )
                },
            },
            {
                accessor: "location_count",
                Header: "Total Locations",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark fw-500" >
                            {Number(item.location_count)}
                        </div>
                    );
                },
            },
            {
                accessor: "menu",
                disableFilters: true,
                Header: "Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div >
                            <i className="bx bx-right-arrow-alt text-primary" style={{ cursor: 'pointer', fontSize: '28px' }} onClick={() => { loadEndpoints(item) }} />
                        </div>
                    );
                },
            }
        ],
        []
    )



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>AuditVista | Action plan Audit</title>
                </MetaTags>

                <Container fluid>
                    <Breadcrumbs title="Audits" breadcrumbItem="User Audits" />
                    <Row>
                        {
                            aplnAuditList ?
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={aplnAuditList}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={false}
                                                customPageSize={10}
                                                style={{ width: "100%" }}
                                                isPagination={true}
                                                filterable={false}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col> :
                                <Col lg="12">
                                    <Card>
                                        <CardBody style={{ height: "100vh" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default AplnAuditList