import React, { useState, useEffect, useMemo, useRef } from "react";
import MetaTags from "react-meta-tags";
import {
    Toast,
    ToastBody,
    ToastHeader
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ChatBox from "../Components/ChatBox";
import ChatInputSection from "../Components/ChatInput";
import socket, { IRJoinRoom } from "../../../../helpers/socket"
import { getIRMessages, setValidUser, setMyTaskList, addAplnMessages, updateAplnData, setSelectedApln, setcloseStatus } from '../../../../store/incidentReport/incdreportslice'
import messageSound from '../../../../helpers/tap-notification-180637.mp3'
import store from '../../../../store'

const Conversation = ({
    selectedApln,
    userData,
    connectedRoomUsers,
    showUserPanel,

}) => {

    const dispatch = useDispatch();

    const { aplnMessages } = useSelector(state => state.incdreportslice)
    const [selectedMessage, setSelectedMessage] = useState(null)
    const followUpSlice = useSelector(state => state.incdreportslice)
    const validUser = followUpSlice.validUser
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('message received!!!');
    const audioRef = useRef(null);
    const authUser = JSON.parse(sessionStorage.getItem("authUser"))
    console.log(selectedApln, 'selectedApln')
    useEffect(() => {

        if (socket) {
            // const notificationSound = new Audio('/Users/eprmdev11/Documents/Project/Development/Auditvista-React-18-UI/src/helpers/tap-notification-180637.mp3');
            socket.on("notify_msg", msg => {
                var followSlice = store.getState().incdreportslice
                const selectedApln = _.cloneDeep(followSlice.selectedApln)
                console.log(msg, 'msg')
                setToastMessage(msg.message.text);
                setToast(true);
                setTimeout(() => {
                    setToast(false);
                }, 10000);
                var data = {
                    // checkpoint_id: msg.task_checkpoint_id,
                    actionplan_id: msg.room_id,
                    task_status: msg.task_status,
                    task_percentage: msg.task_percentage,
                    task_modified_by: msg.task_modifier_id,
                    task_modifier_name: msg.task_modifier_name,
                    task_users: msg.task_users,
                    unread: msg.unread
                }

               
                if (msg.msg.message_type !== "3" && selectedApln) {
                    selectedApln["task_completion_perc"] = msg.msg.task_percentage
                    // dispatch(setSelectedApln(selectedApln))
                }
                const sound = new Audio(messageSound)
                sound.play()
                // if (audioRef.current) {
                //     audioRef.current.play();
                //   }

                dispatch(updateAplnData(data))
                dispatch(addAplnMessages(msg.msg))


            });

            socket.on("ir_status", msg => {
                var followSlice = store.getState().incdreportslice
                const selectedApln = _.cloneDeep(followSlice.selectedApln)
                console.log(msg, 'msg', selectedApln,store.getState().incdreportslice)
                if (msg.msg.task_user_id !== authUser.user_data._id) {
                    const sound = new Audio(messageSound)
                    sound.play()
                }

                // if (audioRef.current) {
                //     audioRef.current.play();
                //   }
                // var data = {
                //     actionplan_id: msg.msg.task_id,
                //     task_status: msg.msg.task_status,
                //     task_percentage: msg.msg.message_type !== "3" ? msg.msg.task_percentage : selectedApln.task_completion_perc,
                //     task_modified_by: msg.msg.task_modifier_id,
                //     task_modifier_name: msg.msg.task_modifier_name,
                //     task_users: msg?.task_users === undefined ? selectedApln.task_users : msg.task_users
                // }
                if(msg.msg.message_type === "8" && selectedApln._id ===msg.msg.task_id ){
                    selectedApln["task_modifier_id"] = msg.msg.task_modifier_id
                    selectedApln["task_modifier_name"] = msg.msg.task_modifier_name
                    selectedApln["task_completion_perc"] = msg.msg.task_percentage
                    // console.log(selectedApln,'selectedApln')
                    dispatch(setSelectedApln(selectedApln))

                }
                
                // if (msg.msg.message_type !== "3" && selectedApln) {
                //     console.log(selectedApln,'selectedApln')
                //     selectedApln["task_completion_perc"] = msg.msg.task_percentage
                // }

                // if (msg.task_info.length > 0) {
                    if (msg.task_info && msg?.task_info?.length >0) {

                    var myTaskListInfo = _.cloneDeep(store.getState().incdreportslice.myTaskList)
                    var getIdx = _.findIndex(myTaskListInfo, { _id: msg.task_info[0]._id })
                    if (getIdx !== -1) {
                        myTaskListInfo[getIdx] = msg.task_info[0]
                    }
                    const closeInfo= _.filter(store.getState().incdreportslice.selectedApln.task_users,{allocated_person : true , user_id : authUser.user_data._id})
                    console.log(closeInfo,'closeInfo')
                    dispatch(setcloseStatus(closeInfo))
                    if(msg.task_info[0].task_status === "3"){
                        dispatch(setSelectedApln(msg.task_info[0]))
                    }
                    // console.log(myTaskListInfo,myTaskListInfo[getIdx],'myTaskList',msg.task_info[0])
                    dispatch(setMyTaskList(myTaskListInfo))
                }
                if (msg.msg.task_id === selectedApln._id) {
                    // dispatch(updateAplnData(data))
                    dispatch(addAplnMessages(msg.msg))
                }
            })
        }

        return () => { }

    }, [socket])


    useEffect(() => {

        if (selectedApln) {
            if (validUser.length > 0) {
                userData["user_status"] = validUser[0].user_status
            }
            dispatch(getIRMessages(selectedApln))
            IRJoinRoom(selectedApln, userData)
            setSelectedMessage(null)
            sessionStorage.removeItem("task_info")
        }
    }, [dispatch, selectedApln])

    const toggleToast = () => {
        setToast(!toast);
    };

    return (
        <div>
            {/* <audio ref={audioRef} src="/Users/eprmdev11/Documents/Project/Development/Auditvista-React-18-UI/src/helpers/tap-notification-180637.mp3" /> */}
            {
                console.log(followUpSlice, 'followUpSlice')
            }
            <ChatBox
                selectedActionplan={selectedApln}
                messages={aplnMessages}
                userData={userData}
                connectedRoomUsers={connectedRoomUsers}
                showUserPanel={() => { showUserPanel() }}
                selectedContent={(msg, mode) => {
                    setSelectedMessage({
                        message: msg,
                        mode: mode
                    })
                }}
                userFacilities={validUser.length > 0 ? validUser[0]["facilities"] : []}
            />
            {
                console.log(validUser, 'validUser')
            }

            {
                validUser[0]?.user_status !== "1" && validUser[0]?.user_status !== "2"
                && selectedApln && (selectedApln?.task_status !== "3")
                &&
                <ChatInputSection
                    selectedActionplan={selectedApln}
                    userData={userData}
                    selectedContent={selectedMessage}
                    clearAll={() => {
                        setSelectedMessage(null)
                    }}
                    userFacilities={validUser.length > 0 ? validUser[0]["facilities"] : []}

                />
            }

            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
                <Toast isOpen={toast}>
                    <ToastHeader toggle={toggleToast}>
                        NOTIFICATION!
                    </ToastHeader>
                    <ToastBody>
                        {toastMessage}
                    </ToastBody>
                </Toast>
            </div>
        </div>
    )
}

export default Conversation