import React, { Component } from "react";

import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from "react-router-dom"
import {
    Container, Row, Col, Card, CardBody, CardTitle, Table, Button, Badge,
    Progress, Pagination, PaginationItem, PaginationLink,
    Modal, ModalHeader, ModalBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from "reactstrap";
import Swal from 'sweetalert2';
import ReactDrawer from 'react-drawer';
import SweetAlert from "react-bootstrap-sweetalert"
import facility from '../FacilityScreen'
import TableContainer from "./Components/TableContainer";

// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import ADDEOP from "./Components/addeop"
import { AvForm, AvField } from "availity-reactstrap-validation"
import AddEndpointNode from "./Components/AddEndpointNode"
import _ from "lodash";
import { isEmpty, size, map } from "lodash"
import { TableHeader, PaginationComponent } from "../../../common/DataTable"
import FuzzySearch from '../../../common/FuzzySearch';
import { LoadingOutlined } from '@ant-design/icons';

import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'


// var urlSocket = require("../../helpers/urlSocket");
import urlSocket from "../../../helpers/urlSocket";
var randomColor = require('randomcolor');

class EntityList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            usertype: '',
            dataloaded: false,
            auditStatusData: [
                { status: "Active", count: 0, color: "#fbae17", id: "1", badgeClass: "success" },
                { status: "In active", count: 0, color: "#303335", id: "0", badgeClass: "danger" },
            ],
            userHLevel: "All",
            resultData: [],
            ddchange: false,
            open: false,
            labelData: [],
            selectedLabel: false,
            selectedEOPT: [],
            group_name_err: false,
            height: window.innerHeight,

            search_files: [],
            dup_search_files: [],
            temp_search_files: [],
            currentPage: 1,
            totalItems: 0,
            items_per_page: 10,
            btn_load: false,
            isSubmitting:false,
            search_group_name :[],
            dup_search_group_name :[],
            dup_labelData:[],
            dup_temp_search_group: [],

            isAllSelected:false


        };

        this.toggle = this.toggle.bind(this)
        this.toggle2 = this.toggle2.bind(this)
        this.closeDrawer = this.closeDrawer.bind(this)
        this.onDrawerClose = this.onDrawerClose.bind(this)

        this.toggleFolder = this.toggleFolder.bind(this)
        this.handleValidSubmit = this.handleValidSubmit.bind(this)

    }

    //Toggle Folder Menus
    toggleFolder() {
        this.setState(prevState => ({
            folder_Menu: !prevState.folder_Menu,
        }))
    }


    async componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var auditData = JSON.parse(sessionStorage.getItem("auditData"));
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"))

        var protect_routes = await facility(user_facilities, 'mels')
        if (protect_routes !== undefined) {
            this.setState(
                {
                    img_max_mb: data.img_max_mb,
                    userInfo: data.user_data,
                    usercode: data.user_data.user_code,
                    mappinginfo: data.user_data.mappinginfo,
                    designation: data.user_data.designation,
                    configData: data.config_data,
                    auditData: auditData,
                    db_info: db_info,
                    dataloaded: false,
                    protect_routes: protect_routes

                },
                function () {
                    this.loadEOPTS()
                }
            )
        }
        else {
            this.props.history.push('/dashboard')
        }
    }





    loadEOPTS = () => {
        this.setState({ tableDataloaded: false, isAllSelected:false })
        try {
            urlSocket.post("webEntities/endpointlist", {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    company_id: this.state.userInfo.company_id,
                    user_id: this.state.userInfo._id,
                },
            })
                .then((response) => {
                    console.log(response,'response')
                    // this.setState({
                    //     statusBasedFilteredData: response.data.data,
                    //     tableDataloaded: true,
                    //     allowTableRowToSelect: true,
                    //     labelDefault: true,
                    //     selectedLabel: false,
                    //     label: {
                    //         endpoints: []
                    //     }

                    // }, function () {
                    //     this.loadUserLabels()
                    // })
                    this.setState({
                        statusBasedFilteredData: response.data.data,
                        tableDataloaded: true,
                        allowTableRowToSelect: true,
                        labelDefault: true,
                        selectedLabel: false,
                        label: {
                            endpoints: []
                        },

                        search_files: response.data.data,
                        dup_search_files: response.data.data,
                        temp_search_files: response.data.data

                    }, function () {
                        this.loadUserLabels()
                    })
                    this.pageClick(1)

                })
        } catch (error) {

        }
    }


    dateConvertion = (dateToConvert) => {
        if (dateToConvert != null) {
            var date = typeof (dateToConvert) == "object" ? String(dateToConvert.toISOString()) : String(dateToConvert)
            var convertedDate = date.slice(8, 10) + ' / ' + (date.slice(5, 7)) + ' / ' + date.slice(0, 4);//prints expected format. 
            if (convertedDate == "01 / 01 / 1970") {
                return "-- / -- / --"
            }
            else {
                return convertedDate
            }
        }
        else {
            return "-- / -- / --"
        }
    }

    completedStatus = (data) => {
        var labels = []
        data == undefined ? labels.push({ status: "", color: "#303335", badgeClass: "danger" }) :
            data == "1" ? labels.push({ status: "Active", color: "#fbae17", badgeClass: "success" }) :
                labels.push({ status: "In active", color: "#303335", badgeClass: "danger" })
        return labels
    }

    navigateTo = (entityData, mode) => {
        if (mode === 0) {
            sessionStorage.removeItem("entityData");
            sessionStorage.removeItem("uniqueNextLevels");
            sessionStorage.setItem("entityData", JSON.stringify(entityData));
            sessionStorage.setItem("uniqueNextLevels", JSON.stringify(this.state.uniqueNextLevels))
            this.props.history.push("/edtent");
        }
        else
            if (mode === 1) {
                this.props.history.push("/adendpt");
            }
    }

    toggle2 = () => {
        this.setState(prevState => ({
            labelWindow: !prevState.labelWindow,
            group_name_err: false,
            btn_load : false
        }))
    }

    toggle() {
        this.setState(prevState => ({
            mainToggle: !prevState.mainToggle,
        }))
    }

    closeDrawer() {
        this.setState({ open: false });
    }

    onDrawerClose() {
        this.setState({ open: false });
        this.loadEOPTS()
    }

    addEndpoint(data) {
    }

    loadUserLabels() {
        try {
            urlSocket.post("userSettings/getuserlabels", {
                userInfo: {
                    _id: this.state.userInfo._id,
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    company_id: this.state.userInfo.company_id
                }
            })
                .then(response => {
                    this.setState({
                        labelData: response.data.data,
                        dup_labelData:response.data.data,
                        tableDataloaded: true,
                        dataloaded: true,
                        search_group_name :  response.data.data,
                        dup_search_group_name :  response.data.data,
                        dup_temp_search_group :  response.data.data,
                        allowTableRowToSelect: false,
                    })
                })

        } catch (error) {
        }
    }

    handleValidSubmit(event, values) {
        // this.setState({ btn_load: true })
        // if(!this.state.isSubmitting){
        if (this.state.group_name_err === false) {
            this.setState({btn_load:true})
            try {

                urlSocket.post("userSettings/createlabel", {
                    labelInfo: values,
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.state.userInfo._id,
                        company_id: this.state.userInfo.company_id
                    }
                })
                    .then(response => {
                        this.setState({
                            labelData: response.data.data,
                            dup_labelData:response.data.data,
                            labelWindow: false,
                            allowTableRowToSelect: true,
                            // btn_load : false,
                            // isSubmitting:true
                        })
                    })

            } catch (error) {
            }
        }
    // }
    // this.setState({ isSubmitting:false})
    }

    deleteLabel() {
        try {

            urlSocket.post("userSettings/deleteLabel", {
                labelInfo: this.state.label,
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.state.userInfo._id,
                    company_id: this.state.userInfo.company_id
                }
            })
                .then(response => {

                    this.setState({
                        labelData: response.data.data,
                        dup_labelData: response.data.data,
                        labelWindow: false,
                        allowTableRowToSelect: true
                    }, function () {
                        this.loadEOPTS()
                    })
                })

        } catch (error) {
        }
    }

    delete_endpoint = (data) => {
        var api_data = {}
        api_data["_id"] = data._id
        api_data["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        api_data["user_id"] = this.state.userInfo._id




        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Do you want to save this location?',
            showCancelButton: true,
            confirmButtonColor: '#2ba92b',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then((result) => {

            if (result.isConfirmed) {

                try {
                    urlSocket.post('webEntities/delete-end-point', api_data).then((response) => {
                        if (response.data.response_code == 500) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success!',
                                text: `Location deleted Successfully`,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.loadEOPTS()
                                }
                            })

                        }
                    })

                } catch (error) {
                }
            }





        })

        // api_data[""]= this.state.db_info.encrypted_db_url



    }


    labelSelected(data) {
        // console.log(data,'data')
        this.setState({
            tableDataloaded: false, selectedLabel: data._id,
            isAllSelected:false,
            label: data
        })

        try {

            urlSocket.post("webEntities/loadlabeleopts", {
                labelInfo: data,
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.state.userInfo._id,
                    company_id: this.state.userInfo.company_id
                }
            })
                .then(response => {
                    this.setState({
                        statusBasedFilteredData: response.data.data,
                        search_files: response.data.data,
                        dup_search_files: response.data.data,
                        temp_search_files: response.data.data,
                        labelDefault: false,
                        tableDataloaded: true,
                        selectedEOPT:[]
                    })
                })

        } catch (error) {
        }

    }

    getSelectedEOPT = (row, isSelect, rowIndex, e) => {

        var getIndex = this.state.selectedEOPT.indexOf(row._id)

        if (getIndex === -1) {
            this.state.selectedEOPT.push(row._id)

            this.setState({
                selectedEOPT: this.state.selectedEOPT
            })
        }
        else {
            this.state.selectedEOPT.splice(getIndex, 1)
            this.setState({
                selectedEOPT: this.state.selectedEOPT,
                
            })
        }

        this.setState({
            isAllSelected: this.state.selectedEOPT.length > 0 ? true : false
        })



    }

    moveToLabel(data) {

        if (this.state.selectedEOPT.length !== 0) {

            _.each(data.endpoints, item => {
                var getIndex = this.state.selectedEOPT.indexOf(item)

                if (getIndex !== -1) {
                    this.state.selectedEOPT.splice(getIndex, 1)

                }

            })
            var concated = this.state.selectedEOPT.concat(data.endpoints)

            this.setState({ tableDataloaded: false })

            try {

                urlSocket.post("userSettings/movetolabel", {
                    eopts: concated,
                    labelInfo: data,
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.state.userInfo._id,
                        company_id: this.state.userInfo.company_id
                    }
                })
                    .then(response => {

                        var getIndex = _.findIndex(this.state.labelData, item => { return item._id == response.data.data._id })
                        var labelData = [...this.state.labelData]
                        labelData[getIndex] = response.data.data

                        this.setState({
                            labelData: labelData,
                            dup_labelData:labelData,
                            selectedEOPT: [],
                            tableDataloaded: true
                        })
                    })

            } catch (error) {
            }
        } else {
            // toggle()
        }


    }


    removeFromLabel() {


        if (this.state.selectedEOPT.length !== 0) {
            this.setState({ tableDataloaded: false })
            _.each(this.state.selectedEOPT, item => {
                var getIndex = this.state.label.endpoints.indexOf(item)

                if (getIndex !== -1) {
                    this.state.label.endpoints.splice(getIndex, 1)

                }

            })



            try {

                urlSocket.post("userSettings/movetolabel", {
                    eopts: this.state.label.endpoints,
                    labelInfo: this.state.label,
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.state.userInfo._id,
                        company_id: this.state.userInfo.company_id
                    }
                })
                    .then(response => {

                        var getIndex = _.findIndex(this.state.labelData, item => { return item._id == response.data.data._id })
                        var labelData =[...this.state.labelData]
                        labelData[getIndex] = response.data.data

                        this.setState({
                            labelData: labelData,
                            dup_labelData : labelData,
                            label: response.data.data,
                            selectedEOPT: [],
                            tableDataloaded: true
                        }, () => {
                            this.labelSelected(response.data.data)
                        })
                    })

            } catch (error) {
            }
            //var concated = this.state.selectedEOPT.concat(data.endpoints)
        }

    }

    editEOPT(data) {
        this.setState({
            open: true,
            eopt: data
        })
    }


    handleGroupName = (event) => {
        var api_data = {}
        api_data["group_name"] = event.target.value
        api_data["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        api_data["user_id"] = this.state.userInfo._id
        // api_data["label_name"] = this.state.db_info.encrypted_db_url
        if (event.target.value !== "") {

            urlSocket.post('webEntities/validate-user-group-location', api_data).then((response) => {
                if (response.data.data.length > 0) {
                    this.setState({ group_name_err: true })
                }
                else {
                    this.setState({ group_name_err: false })

                }
            })


        }
    }


    pageClick = (currentPage) => {
        this.setState({ currentPage }, () => { this.paginationProcess() })
    }

    paginationProcess = () => {
        // let { currentPage, items_per_page, search_files, dup_search_files } = this.state
        // let result = search_files.slice((currentPage - 1) * items_per_page, (currentPage - 1) * items_per_page + items_per_page)
        // if (result.length > 0) {
        //     this.setState({ statusBasedFilteredData: result })
        // } else {
        //     this.setState({ statusBasedFilteredData: search_files, search_files })
        // }
    }


    // getFuzzySearch = (search_files) => {
    //     this.setState({ statusBasedFilteredData: search_files })
    //     // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })
    // }
    getFuzzySearch = (search_files,mode) => {
        // console.log('final search_files...', search_files)
        if (mode == "1") {
            this.setState({ dup_labelData: search_files, dup_search_group_name : this.state.dup_temp_search_group })
        }
        if(mode == "2"){
            this.setState({ labelData: search_files, dup_search_group_name : this.state.dup_temp_search_group})
        }
        else if(mode == undefined){
            // this.setState({ statusBasedFilteredData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files })
            this.setState({ statusBasedFilteredData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files },()=>{ this.pageClick(this.state.currentPage)})

        }        // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })
    }


    selectAllUser = (rows) => {
        var _id_grp = []
        rows.map((ele, idx) => {
            if(ele.selected){
                _id_grp.push(ele._id)
                }
        })
        var selectedEOPT = []
        selectedEOPT = _id_grp
        this.setState({
            selectedEOPT
        })
    }




    render() {

        if (this.state.dataloaded) {
           
            const columns = [
                {
                    Header: (cellProps) => <div className="form-check font-size-16" >
                        <input
                            className="form-check-input"
                            onChange={() => {
                                // Determine if all rows are currently selected
                                const allSelected = cellProps.page.every((row) => row.original.selected);
                                console.log(allSelected, 'allSelected', cellProps.page)
                                // Update the selection for each row
                                cellProps.page.forEach((row) => {
                                    row.original.selected = !allSelected;
                                }); 
                                this.setState({
                                    isAllSelected:cellProps.page.every((row) => row.original.selected === true)
                                })
                                this.selectAllUser(_.map(cellProps.page, "original"))
                            }}
                            type="checkbox"
                            checked={cellProps.page.every((row) => row.original.selected) && (cellProps.page.length === this.state.selectedEOPT.length) && this.state.statusBasedFilteredData.length !==0}
                            // checked={cellProps.page.length > 0 && cellProps.page.every((row) => row.original.selected)}
                            id="checkAll"
                        />
                        <label className="form-check-label" htmlFor="checkAll"></label>
                    </div>,
                    accessor: '#',
                    width: '5%',
                    filterable: true,
                    isSort:false,
                    Cell: ({ row }) => (
                        <div className="form-check font-size-16" >
                            <input className="form-check-input" checked={
                                row.original.selected && this.state.selectedEOPT.includes(row.original._id)
                                // row.original.selected
                                } type="checkbox" onChange={(e) => { row.original.selected = !row.original.selected; this.getSelectedEOPT(row.original) }} id="checkAll" />
                            <label className="form-check-label" htmlFor="checkAll"></label>
                        </div>
                    )
                },
                {
                    accessor: 'name',
                    Header: 'Location',
                    filterable: true,
                    isSort:true,
                    width: '50%',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <>
                                <div >
                                    {item.name}

                                    {
                                        item.endpoints !== undefined ?
                                            <div > {item.endpoints.length !== 0 ? <span className="font-size-10 text-secondary">Grouped in - </span> : null}
                                                {
                                                    item.endpoints.map(elm => {
                                                        return (
                                                            <label key={''} style={{ backgroundColor: elm.label_color }} className="badge  me-2 font-size-10">{String(elm.label_name).slice(0, 20) + (elm.label_name.length > 20 ? "..." : "")}</label>
                                                        )
                                                    })
                                                }
                                            </div> : null
                                    }


                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'code',
                    Header: 'Code',
                    isSort:true,
                    width: '20%',
                },
                {
                    accessor: 'category',
                    Header: 'Category',
                    isSort:true,
                    width: '20%',
                },
                {
                    accessor: "menu",
                    disableFilters: true,
                    Header: "Action",
                    isSort:false,
                    width: '5%',
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        return (
                            <div className="d-flex gap-1" style={{ display: 'flex', alignContent: 'center' }}>

                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        // href="#"
                                        className="card-drop"
                                        tag="a"
                                    >
                                        <i className="mdi mdi-dots-horizontal font-size-18" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end" style={{ marginTop: "-38px", width: "50%", padding: "0px" }}>

                                        <DropdownItem
                                            onClick={() => { this.editEOPT(item) }}
                                        >
                                            <i className="mdi mdi-domain font-size-12 text-primary me-1" />{" "}
                                            View Detail
                                        </DropdownItem>

                                        {
                                            this.state.protect_routes.read_write_checked === true &&
                                            <DropdownItem
                                                onClick={() => { this.props.history.push('/map-user'); sessionStorage.setItem('locationInfo', JSON.stringify(item)) }}
                                            >
                                               <i className="mdi mdi-account-network font-size-12 text-primary me-1" /> Map Users
                                            </DropdownItem>
                                        }

                                        {
                                            this.state.protect_routes.read_write_checked === true &&
                                            <DropdownItem onClick={() => { this.delete_endpoint(item) }}  >
                                                <i className="fas fa-trash-alt font-size-12 text-danger me-1" />{" "}Delete
                                            </DropdownItem>
                                        }

                                    </DropdownMenu>
                                </UncontrolledDropdown>                            
                            </div>
                        )
                    },
                },
            ];
            
            return (
                <React.Fragment>
                    <div className="page-content" style={{ minHeight: "100vh" }}>
                        <MetaTags>
                            <title>AuditVista | Manage Location</title>
                        </MetaTags>
                        
                        <Container fluid>

                            <Breadcrumbs title="Locations" breadcrumbItem="Locations" />

                            <div className="d-xl-flex">
                                <div className="w-100">
                                    <div className="d-md-flex">

                                        <Card className="me-md-2  pb-3" style={{maxWidth:250, height:'87vh', overflowY:"auto"}}>
                                          
                                            <CardBody style={{ overflow: 'auto' }} >

                                                <Row >
                                                    {this.state.protect_routes.read_write_checked ==
                                                        true && (
                                                            <div className="border-bottom pb-3 ">
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    lg="12"
                                                                    className="btn waves-effect waves-light"
                                                                    onClick={() => {
                                                                        this.setState({ labelWindow: true, btn_load: false }) 
                                                                    }}
                                                                >
                                                                    <i className="mdi mdi-domain text-white font-size-18" /> Create Location Group
                                                                </Button>
                                                            </div>
                                                        )}
                                                </Row>
                                               
                                                <div className="my-1"></div>

                                                <Row>
                                                    <h6 className="mt-4">Groups</h6>
                                                    <FuzzySearch
                                                        search_files={this.state.search_group_name}
                                                        getFuzzySearch={(search_files) => this.getFuzzySearch(search_files, '2')}
                                                        dup_search_files={this.state.dup_search_group_name}
                                                        temp_search_files={this.state.dup_temp_search_group}
                                                        keyword={['label_name']}
                                                    />


                                                    <div className="mail-list mt-4">

                                                        <div
                                                            className={!this.state.selectedLabel
                                                                ? " btn btn-sm btn-primary col-12 border-bottom pb-2"
                                                                : " btn btn-sm btn-white col-12 border-bottom pb-2"
                                                            }
                                                            style={{ textAlign:"left" }}
                                                            onClick={() => { this.loadEOPTS() }}
                                                        >
                                                            &nbsp;
                                                            <i className="mdi mdi-circle text-secondary font-size-10" /> &nbsp;&nbsp;ALL
                                                        </div>
                                                        {
                                                            this.state.labelData.map((item, idx) => {
                                                                return (
                                                                    <div className="col-12 py-2 border-bottom " key={"lbl" + idx}>
                                                                    <div                                  
                                                                      style={{ textAlign:"left" }}
                                                                      className={
                                                                        this.state.selectedLabel === item._id
                                                                          ? " btn btn-sm btn-primary col-12"
                                                                          : " btn btn-sm btn-white col-12"
                                                                      }
                                                                      onClick={() => {
                                                                        this.labelSelected(item);
                                                                      }}
                                                                    >
                                                                      &nbsp;{" "}
                                                                      <i
                                                                        style={{ color: item.label_color }}
                                                                        className={
                                                                          this.state.selectedLabel ===
                                                                            item._id
                                                                            ? "mdi mdi-circle font-size-10"
                                                                            : "mdi mdi-circle font-size-10"
                                                                        }
                                                                      />{" "}
                                                                      &nbsp;&nbsp;{item.label_name}
                                                                    </div>
                                                                    </div>
                                    
                                                                    // <div
                                                                    //     to="#"
                                                                    //     key={"lbl" + idx} 
                                                                    // style={{ cursor: 'pointer' }} 
                                                                    // className={this.state.selectedLabel === item._id ? " font-size-12 py-3 border-bottom text-info" : "border-bottom font-size-12 text-secondary py-3"} 
                                                                    // onClick={() => { this.labelSelected(item) }}>
                                                                    //     {/* <span className="mdi mdi-arrow-right-drop-circle text-info float-end"></span> */}
                                                                    //     &nbsp; <i style={{ color: item.label_color }} className={this.state.selectedLabel === item._id ? "mdi mdi-circle font-size-10" : "mdi mdi-circle font-size-10"} /> &nbsp;&nbsp;{item.label_name}
                                                                    // </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </Row>



                                            </CardBody>
                                        </Card>


                                        <div className="w-100" style={{height:'87vh', overflowY:"auto", overflowX:"hidden"}}>
                                            <Row style={{}}>
                                                <Col lg="12" className="px-2">
                                                    <Card>
                                                        <CardBody>
                                                            {
                                                                this.state.tableDataloaded ?
                                                                
                                                                    <React.Fragment>
                                                                        {
                                                                            !this.state.labelDefault ?
                                                                                <Row className="mb-2" style={{ paddingTop: 10 }}>
                                                                                    <div className="font-size-11 text-secondary">Group</div>
                                                                                    <div className="font-size-14">{this.state.label.label_name}</div>
                                                                                </Row> : null
                                                                        }

                                                                        
                                                                        <Row>
                                                                            <Col xl="12">
                                                                                {/* <div className="table-responsive"> */}
                                                                                <TableContainer
                                                                                    columns={columns}
                                                                                    data={this.state.statusBasedFilteredData}
                                                                                    isGlobalFilter={true}
                                                                                    isAddOptions={false}
                                                                                    isJobListGlobalFilter={false}
                                                                                    customPageSize={10}
                                                                                    style={{ width: '100%' }}
                                                                                    selectedEOPT={this.state.selectedEOPT}
                                                                                    labelDefault={this.state.labelDefault}
                                                                                    protect_routes={this.state.protect_routes}
                                                                                    search_group_name={this.state.search_group_name}
                                                                                    getFuzzySearch={(search_files) => this.getFuzzySearch(search_files, '1')}
                                                                                    dup_search_group_name={this.state.dup_search_group_name}
                                                                                    dup_temp_search_group={this.state.dup_temp_search_group}
                                                                                    dup_labelData={this.state.dup_labelData}
                                                                                    dynamicBtn={true}
                                                                                    btnClick={() => { this.setState({ open: true, eopt: null }) }}
                                                                                    moveToLabel={(data) => { this.moveToLabel(data) }}
                                                                                    removeFromLabel={() => { this.removeFromLabel() }}
                                                                                    confirmDelete={() => {
                                                                                        this.setState({
                                                                                          confirm_both: true,
                                                                                        });
                                                                                      }}
                                                                                    resultData={this.state.resultData}
                                                                                    toggle2={(e) => this.toggle2(e)}
                                                                                    isAllSelected={this.state.isAllSelected}
                                                                                    btnName={"Add Location"}
                                                                                    isPagination={true}
                                                                                    iscustomPageSizeOptions={false}
                                                                                    filterable={false}
                                                                                    tableClass="align-middle table-nowrap table-check"
                                                                                    theadClass="table-light"
                                                                                    paginationDiv="col-sm-12 col-md-7"
                                                                                    pagination="pagination justify-content-end pagination-rounded" 
                                                                                />
                                                                                  
                                                                            </Col>
                                                                        </Row>
                                                                      
                                                                    </React.Fragment>

                                                                 
                                                                    : null
                                                            }


                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Container>
                        <Modal
                            isOpen={this.state.labelWindow}
                            className={this.props.className}
                        >
                            <ModalHeader toggle={this.toggle2} tag="h5">
                                Create Group
                                {/* {!!isEdit && this.state.convertionMode != "2" ? "Edit Template" : !isEdit && this.state.convertionMode == "2"?"Use Template as":"Add New Template"} */}
                            </ModalHeader>
                            <ModalBody>
                                <AvForm
                                    onValidSubmit={
                                        this.handleValidSubmit
                                    }
                                >
                                    <Row form>
                                        <Col className="col-12">
                                            <div className="mb-3">
                                                <AvField
                                                    name="label_name"
                                                    label="Group Name"
                                                    type="text"
                                                    onChange={(e) => { this.handleGroupName(e) }}
                                                    // onChange={() => { this.setState({ group_name_err: false }) }}
                                                    errorMessage="Invalid name"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />
                                                {
                                                    this.state.group_name_err &&
                                                    <div className="text-danger" style={{ fontSize: 'smaller' }}>Group name already exist, Enter a new one.</div>
                                                }
                                            </div>
                                        </Col>


                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">

                                                <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                    disabled={this.state.btn_load}
                                                >
                                                    {
                                                        this.state.btn_load &&
                                                        <LoadingOutlined />
                                                    }
                                                    {
                                                        this.state.btn_load ? "..." : "Submit"
                                                    }

                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </ModalBody>
                        </Modal>

                        <Drawer
                            open={this.state.open}
                            onClose={this.onDrawerClose}
                            direction='right'
                            size={'500px'}
                            zIndex={9999}
                        // className='bla bla bla'
                        >

                            <div style={{ padding: 5, maxHeight: this.state.height, overflow: 'auto', }}>
                                {
                                    this.state.open ?
                                        <AddEndpointNode
                                            endpoints={this.state.statusBasedFilteredData}
                                            //publishtemplateInfo={this.state.publishTemplate}
                                            protected_routes={this.state.protect_routes}
                                            onClose={this.onDrawerClose}
                                            editItem={this.state.eopt}
                                            onCancel={this.onDrawerClose}
                                        /> : null
                                }
                            </div>


                        </Drawer>


                        {/* <ReactDrawer
                            open={this.state.open}
                            className="drawer-open"
                            position={this.state.position}
                            onClose={this.onDrawerClose}
                            style={{ maxHeight: 1000 }}
                            noOverlay={false}
                        >

                            
                        </ReactDrawer> */}

                        {this.state.confirm_both ? (
                            <SweetAlert
                                title="Are you sure?"
                                warning
                                showCancel
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                onConfirm={() =>
                                    this.setState({
                                        confirm_both: false,
                                        success_dlg: true,
                                        dynamic_title: "Deleted",
                                        dynamic_description: "Your file has been deleted.",
                                    },
                                        function () {
                                            this.deleteLabel()
                                        }
                                    )
                                }
                                onCancel={() =>
                                    this.setState({
                                        confirm_both: false,
                                        error_dlg: true,
                                        dynamic_title: "Cancelled",
                                        dynamic_description:
                                            "Your imaginary file is safe :)",
                                    })
                                }
                            >
                                You won't be able to revert this!
                            </SweetAlert>
                        ) : null}


                    </div>
                </React.Fragment>
            );
        }
        else {
            return null
        }

    }
}

export default EntityList;



