import React, { Component } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';

import {
  Container, Row, Col, Button, Alert, Input, Card, CardBody,
  Modal, ModalHeader, ModalBody,
  Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
} from "reactstrap"

import ReactDrawer from 'react-drawer';
import { DatePicker, Drawer, Badge } from 'antd';
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import TableContainer from "./ReportComponents/TableContainer";
import Breadcrumbs from "./Components/Breadcrumb2"
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import CardTitle from "reactstrap/lib/CardTitle";

import EditEP from "./Components/EditEP_PBD"
import AddEndpointNode from "./Components/NewEndPoint"
import UpdtAuditInfo from "./Components/UpdatePdbAuditInfo"
// import LoadEndpoint from "./Components/LoadEndpoints"
import LoadLocation from "./LoadLocation"
import FuzzySearch from "../../../common/FuzzySearch";
import { PaginationComponent } from "../../../common/DataTable";



const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from "../../../helpers/urlSocket";
var moment = require('moment')

export default class PublishedReport extends Component {


  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      position: 'right',
      open: false,
      dataloaded: false,

      temp_search_files: [],
      search_files: [],
      dup_search_files: [],
      currentPage: 1,
      totalItems: 0,
      items_per_page: 10,
    }
    this.onDrawerClose = this.onDrawerClose.bind(this);
  }

  async componentDidMount() {
    await this.getSessionData()
    this.getAuditInfo()
  }

  async getSessionData() {

    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var publishedAuditData = JSON.parse(sessionStorage.getItem("publishedAuditData"));

    this.setState({
      configData: data.config_data,
      userData: data.user_data,
      db_info: db_info,
      publishedAuditData
    })

  }

  getAuditInfo() {
    this.setState({ dataloaded: false })
    try {

      urlSocket.post("webpbdadtdta/getpublishedauditdata", {
        auditInfo: this.state.publishedAuditData,
        userInfo: this.state.userData,
        encrypted_db_url: this.state.db_info.encrypted_db_url,
        configData: this.state.configData
      })
        .then(response => {
          //          console.log(response,'response')
          // console.log(response.data.data,this.state.publishedAuditData)
          sessionStorage.setItem("Locationdata", JSON.stringify(response.data.data));
          // sessionStorage.setItem(JSON.stringify(response.data.data))

          var in_progress_count = _.filter(response.data.data, (e) => {
            return e.status === "1";
          });

          var not_started_count = _.filter(response.data.data, (e) => {
            return e.status === "0";
          });
          var completed_count = _.filter(response.data.data, (e) => {
            return e.status === "2";
          });
          var submitted_count = _.filter(response.data.data, (e) => {
            return e.status === "3";
          });
          var reviewed_count = _.filter(response.data.data, (e) => {
            return e.status === "7";
          });

          if (response.data.response_code === 500) {
            this.setState({
              endpoints: response.data.data,

              search_files: response.data.data,
              temp_search_files: response.data.data,
              dup_search_files: response.data.data,
              total_audit: response.data.data.length,
              in_progress_count: in_progress_count.length,
              not_started_count: not_started_count.length,
              completed_count: completed_count.length,
              submitted_count: submitted_count.length,
              reviewed_count :reviewed_count.length
            },
              function () {
                this.filterStatus('All')
              }, () => { this.pageClick(1) })
          }
        })

    } catch (error) {

    }

  }

  updateEPData = (updatedData) => {
    this.setState({ epdata: updatedData });
  }

  closeDrawer() {
    this.setState({ open: false, enableEdit: false });
  }
  onDrawerClose() {
    this.setState({ open: false, enableEdit: false,updatePbdAudit: false });
    this.getAuditInfo()
  }

  filterStatus = (data) => {
    var filteredData;
    if (data == "Not started") {
      filteredData = _.filter(this.state.endpoints, { "status": "0" })
    }
    else
      if (data == "In progress") {
        filteredData = _.filter(this.state.endpoints, { "status": "1" })
      }
      else
        if (data == "Completed") {
          filteredData = _.filter(this.state.endpoints, { "status": "2" })
        }
        else
          if (data == "Submitted") {
            filteredData = _.filter(this.state.endpoints, { "status": "3" })
          }
          else
          if (data == "Reviewed") {
            filteredData = _.filter(this.state.endpoints, { "status": "7" })
          }
          else
            if (data == "All") {
              filteredData = this.state.endpoints
            }
    this.setState({
      statusBasedFilteredData: filteredData,
      dataloaded: true,
      search_files: filteredData,
      dup_search_files: filteredData
    })
  }

  activeInactive = (item) => {
    if (item.active === "0") {
      // item["active"] = "1"
      this.setState({
        confirm_alert: true,
        epData: item,
        confirmButtonText: "Yes, make it active!",
        dynamic_title: "Active",
        dynamic_description: "This end point is activated",
        active_status: "1"

      })
    }
    else {
      // item["active"] = "0"
      this.setState({
        confirm_alert: true,
        epData: item,
        confirmButtonText: "Yes, make it inactive!",
        dynamic_title: "Inactive",
        dynamic_description: "This end point is inactivated",
        active_status: "0"

      })
    }

  }

  updateEndpoint() {
    var epData = { ...this.state.epData }
    epData["active"] = this.state.active_status
    try {

      urlSocket.post("webpbdadtdta/ainendpoints", {
        encrypted_db_url: this.state.db_info.encrypted_db_url,
        userInfo: this.state.userData,
        endpointInfo: epData,
        auditInfo: this.state.publishedAuditData
      })
        .then(response => {
          if (response.data.response_code === 500) {
            this.getAuditInfo()
            //this.props.onClose()
          }
        })

    } catch (error) {
      console.log("catch error", error)
    }
  }

  showEndpointReport(data) {
    // data.status === "0"
    // console.log(data,'data')
    if (data.status < "3") {
      this.setState({
        showWarning: true,
        submitMessage: "Audit is not submitted, you cannot view report"
      })
    }
    else {
      sessionStorage.removeItem("endpointInfo");
      sessionStorage.setItem("endpointInfo", JSON.stringify(data));
      this.props.history.push("/chkpntrprt");
    }
  }

  pageClick = (currentPage) => {
    this.setState({ currentPage }, () => { this.paginationProcess() })
  }

  paginationProcess = () => {
    let { currentPage, items_per_page, search_files, dup_search_files } = this.state
    let result = search_files.slice((currentPage - 1) * items_per_page, (currentPage - 1) * items_per_page + items_per_page)
    // console.log('result', result, search_files)
    if (result.length > 0) {
      this.setState({ statusBasedFilteredData: result })
    } else {
      this.setState({ statusBasedFilteredData: search_files, search_files })
    }
  }

  getFuzzySearch = (search_files) => {
    // console.log('search_files', search_files)
    // this.setState({ statusBasedFilteredData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files })
    this.setState({ statusBasedFilteredData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files }, () => { this.pageClick(this.state.currentPage) })

    // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })
  }

  gotoBack = () => {
    this.props.history.push('/mngpblhtempt')
  }

  render() {
    if (this.state.dataloaded) {
      // console.log(this.state.statusBasedFilteredData,'statusBasedFilteredData')
      //      console.log(this.state.statusBasedFilteredData,'CSVExport',this.state.publishedAuditData)

      // const { SearchBar } = Search;
      // const { ExportCSVButton } = CSVExport;
      // const { users } = this.state

      const { isEdit } = this.state
      const options = {
        // pageStartIndex: 0,

        sizePerPage: 10,
        totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
        custom: true,
      };
      var columns = []
      if (this.state.publishedAuditData.enable_review) {
        columns = [
          {
            accessor: 'name',
            Header: 'Location Name',
            filterable: true,
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className={item.active == "1" ? "font-size-14 text-dark fw-bold" : "font-size-14 text-black-50"} style={{ marginBottom: 5 }}>
                      <i
                        className={
                          item.status === '0'
                            ? "mdi mdi-circle text-secondary font-size-10" :
                            item.status === '1' ?
                              "mdi mdi-circle text-warning font-size-10" :
                              item.status === '2' ?
                                "mdi mdi-circle text-success font-size-10" :
                                item.status === '3' ?
                                  "mdi mdi-circle text-info font-size-10" : "mdi mdi-circle text-primary font-size-10"
                        }
                      />{" "} <span className="fw-bold text-primary">{item.name}</span>
                    </div>
                    <div>
                      <span className="font-size-10">Audit started on - {item.audit_started_on === null ? "-- / -- / --" : moment(item.audit_started_on).format("DD/MM/YYY")}</span> <br />
                      <span className="font-size-10">Audit submitted on - {item.audit_submitted_on === null ? "-- / -- / --" : moment(item.audit_submitted_on).format("DD/MM/YYY")}</span> <br />
                      <span className="font-size-10">Audit reviewed on - {item.audit_reviewed_on === null ? "-- / -- / --" : moment(item.audit_reviewed_on).format("DD/MM/YYY")}</span> <br />
                    </div>
                  </div>
                </>
              )
            }
          },
          {
            accessor: 'code',
            Header: 'Code',
            filterable: true,
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"} style={{ marginBottom: 5 }}>
                      {item.code}
                    </div>
                  </div>
                </>
              )
            }
          },
          {
            accessor: 'audit_pdb_total_cp',
            Header: 'Check points',
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <>
                  <span className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"}>{item.audit_pdb_total_cp}</span>
                </>
              )
            }
          },
          {
            accessor: 'audit_pbd_ends_on',
            Header: 'Ends on',
            disableFilters: true,
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <>
                  <span className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"}>{moment(item.audit_pbd_ends_on).format("DD/MM/YYYY")}</span>
                </>
              )
            }
          },
          {
            accessor: 'audit_pbd_users',
            Header: 'Audit assigned to',
            Cell: (cellProps) => {
              var item = cellProps.row.original
              var getUser = _.find(item.audit_pbd_users, { audit_type: "1" })
              // console.log(getUser,'getUser')
              return (
                <>
                  <div>
                    <span className={item.active == "1" ? null : "font-size-14 text-black-50"}>{getUser !== undefined ? getUser.name : ""}</span><br /><span className={item.active == "1" ? "font-size-10 text-primary" : "font-size-10 text-black-50"}>{getUser !== undefined ? getUser.designation : ''}</span>
                    {/* <span className={item.active == "1" ? null : "font-size-14 text-black-50"}>{getUser.name}</span><br /><span className={item.active == "1" ? "font-size-10 text-primary" : "font-size-10 text-black-50"}>{getUser.designation}</span> */}
                  </div>
                </>
              )
            }
          },
          // 
          {
            accessor: 'audit_pbd_users_',
            Header: 'Review assigned to',
            show: this.state.publishedAuditData.enable_review,
            Cell: (cellProps) => {
              // console.log(this.state.publishedAuditData.enable_review, 'this.state.publishedAuditData.enable_review')
              var item = cellProps.row.original
              var getUser = _.find(item.audit_pbd_users, { audit_type: "2" })
              // this.state.publishedAuditData.enable_review &&
              return (
                <>
                  <div>
                    <span className={item.active == "1" ? null : "font-size-14 text-black-50"}>{getUser !== undefined ? getUser.name : ''}</span><br /><span className={item.active == "1" ? "font-size-10 text-primary" : "font-size-10 text-black-50"}>{getUser !== undefined ? getUser.designation : ''}</span>
                  </div>
                </>
              )
            }
          },
          {
            accessor: 'status',
            Header: 'Status',
            disableFilters: true,
            Cell: (cellProps) => {
              // console.log(item,'item')
              var item = cellProps.row.original
              return (
                <>
                  <span className={item.status === "0" ? "badge badge-soft-secondary font-size-11 m-1" :
                    item.status === "1" ? "badge badge-soft-warning font-size-11 m-1" : item.status === "2" ? "badge badge-soft-success font-size-11 m-1" : item.status === "3" ? "badge badge-soft-info font-size-11 m-1" : "badge badge-soft-primary font-size-11 m-1"}
                  >
                    {item.status === "0" ? "Not started" : item.status === "1" ? "In progress" : item.status === "2" ? "Completed" : item.status === "3" ? "Submitted" : "Reviewed"}
                  </span>
                </>
              )
            }
          },
          {
            accessor: 'audit_score',
            Header: 'Audit Score',
            Cell: (cellProps) => {
              // console.log(item.audit_score.$numberDecimal)
              var item = cellProps.row.original
              return (

                <>
                  <span >{parseFloat(item.audit_score.$numberDecimal).toFixed(2)}</span>
                </>
              )
            }
          },
          {
            accessor: 'review_score',
            Header: 'Review Score',
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (

                <>
                  <span >{parseFloat(item.review_score.$numberDecimal).toFixed(2)}</span>
                </>
              )
            }
          },
          {
            accessor: "menu",
            Header: "Action",
            disableFilters: true,
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                  <div className="d-flex gap-1">
                    {
                      item.audit_pbd_users.length == 1 && item.active === "1" && item.status !== "3" && item.status !== "4" && item.status < "3" ?
                        <Link className="btn btn-primary btn-sm" to="#" onClick={() => { this.setState({ open: true, enableEdit: true, epData: item }) }}>Edit</Link>
                        :
                        item.audit_pbd_users.length == 2 && item.status < "7" ?
                          <Link className="btn btn-primary btn-sm" to="#" onClick={() => { this.setState({ open: true, enableEdit: true, epData: item }) }}>Edit</Link>
                          :
                          null
                    }
                    {/* {
                  item.active === "1" && item.status !== "3" && item.status !== "4" && item.status <"3" ? 
                    <Link className="btn btn-primary btn-sm" to="#" onClick={() => { this.setState({ open: true, enableEdit: true, epData: item }) }}>Edit</Link>
                    : 
                    null
                } */}
                    <Link className={item.active == "1" ? "btn btn-primary btn-sm" : "btn btn-secondary btn-sm"} to="#" onClick={() => { this.showEndpointReport(item) }}>Report</Link>
                    {
                      item.audit_pbd_users.length == 1 && item.status !== "3" && item.status !== "4" && item.status < "3" ?
                        <Link className={item.active == "1" ? "btn btn-danger btn-sm" : "btn btn-info btn-sm"} to="#" onClick={() => { this.activeInactive(item) }}>{item.active === "0" ? "Active" : "Make Inactive"}</Link>

                        :
                        item.audit_pbd_users.length == 2 && item.status < "7" ?
                          <Link className={item.active == "1" ? "btn btn-danger btn-sm" : "btn btn-info btn-sm"} to="#" onClick={() => { this.activeInactive(item) }}>{item.active === "0" ? "Active" : "Make Inactive"}</Link>
                          :
                          null
                    }
                  </div>
                </div>
              )
            },
          },

        ];
      }
      else {
        columns = [
          {
            accessor: 'name',
            Header: 'Location Name',
            filterable: true,
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className={item.active == "1" ? "font-size-14 text-dark fw-bold" : "font-size-14 text-black-50"} style={{ marginBottom: 5 }}>
                      <i
                        className={
                          item.status === '0'
                            ? "mdi mdi-circle text-secondary font-size-10" :
                            item.status === '1' ?
                              "mdi mdi-circle text-warning font-size-10" :
                              item.status === '2' ?
                                "mdi mdi-circle text-success font-size-10" :
                                item.status === '3' ?
                                  "mdi mdi-circle text-info font-size-10" : "mdi mdi-circle text-primary font-size-10"
                        }
                      />{" "} <span className="fw-bold text-primary">{item.name}</span>
                    </div>
                    <div>
                      <span className="font-size-10">Audit started on - {item.audit_started_on === null ? "-- / -- / --" : moment(item.audit_started_on).format("DD/MM/YYY")}</span> <br />
                      <span className="font-size-10">Audit submitted on - {item.audit_submitted_on === null ? "-- / -- / --" : moment(item.audit_submitted_on).format("DD/MM/YYY")}</span> <br />
                      <span className="font-size-10">Audit reviewed on - {item.audit_reviewed_on === null ? "-- / -- / --" : moment(item.audit_reviewed_on).format("DD/MM/YYY")}</span> <br />
                    </div>
                  </div>
                </>
              )
            }
          },
          {
            accessor: 'code',
            Header: 'Code',
            filterable: true,
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"} style={{ marginBottom: 5 }}>
                      {item.code}
                    </div>
                  </div>
                </>
              )
            }
          },
          {
            accessor: 'audit_pdb_total_cp',
            Header: 'Check points',
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <>
                  <span className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"}>{item.audit_pdb_total_cp}</span>
                </>
              )
            }
          },
          {
            accessor: 'audit_pbd_ends_on',
            Header: 'Ends on',
            disableFilters: true,
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <>
                  <span className={item.active == "1" ? "font-size-14 text-dark" : "font-size-14 text-black-50"}>{moment(item.audit_pbd_ends_on).format("DD/MM/YYYY")}</span>
                </>
              )
            }
          },
          {
            accessor: 'audit_pbd_users',
            Header: 'Audit assigned to',
            Cell: (cellProps) => {
              var item = cellProps.row.original
              var getUser = _.find(item.audit_pbd_users, { audit_type: "1" })
              // console.log(getUser,'getUser')
              return (
                <>
                  <div>
                    <span className={item.active == "1" ? null : "font-size-14 text-black-50"}>{getUser !== undefined ? getUser.name : ""}</span><br /><span className={item.active == "1" ? "font-size-10 text-primary" : "font-size-10 text-black-50"}>{getUser !== undefined ? getUser.designation : ''}</span>
                    {/* <span className={item.active == "1" ? null : "font-size-14 text-black-50"}>{getUser.name}</span><br /><span className={item.active == "1" ? "font-size-10 text-primary" : "font-size-10 text-black-50"}>{getUser.designation}</span> */}
                  </div>
                </>
              )
            }
          },
          {
            accessor: 'status',
            Header: 'Status',
            disableFilters: true,
            Cell: (cellProps) => {
              // console.log(item,'item')
              var item = cellProps.row.original
              console.log(item,'item')
              return (
                <>
                  <span className={item.status === "0" ? "badge badge-soft-secondary font-size-11 m-1" :
                    item.status === "1" ? "badge badge-soft-warning font-size-11 m-1" : item.status === "2" ? "badge badge-soft-success font-size-11 m-1" : item.status === "3" ? "badge badge-soft-info font-size-11 m-1" : "badge badge-soft-primary font-size-11 m-1"}
                  >
                    {item.status === "0" ? "Not started" : item.status === "1" ? "In progress" : item.status === "2" ? "Completed" : item.status === "3" ? "Submitted" : "Reviewed"}
                  </span>
                </>
              )
            }
          },
          {
            accessor: 'audit_score',
            Header: 'Audit Score',
            Cell: (cellProps) => {
              // console.log(item.audit_score.$numberDecimal)
              var item = cellProps.row.original
              return (

                <>
                  <span >{parseFloat(item.audit_score.$numberDecimal).toFixed(2)}</span>
                </>
              )
            }
          },
          {
            accessor: 'review_score',
            Header: 'Review Score',
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (

                <>
                  <span >{parseFloat(item.review_score.$numberDecimal).toFixed(2)}</span>
                </>
              )
            }
          },
          {
            accessor: "menu",
            Header: "Action",
            disableFilters: true,
            Cell: (cellProps) => {
              var item = cellProps.row.original
              return (
                <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                  <div className="d-flex gap-1">
                    {
                      item.audit_pbd_users.length == 1 && item.active === "1" && item.status !== "3" && item.status !== "4" && item.status < "3" ?
                        <Link className="btn btn-primary btn-sm" to="#" onClick={() => { this.setState({ open: true, enableEdit: true, epData: item }) }}>Edit</Link>
                        :
                        item.audit_pbd_users.length == 2 && item.status < "7" ?
                          <Link className="btn btn-primary btn-sm" to="#" onClick={() => { this.setState({ open: true, enableEdit: true, epData: item }) }}>Edit</Link>
                          :
                          null
                    }
                    {/* {
                item.active === "1" && item.status !== "3" && item.status !== "4" && item.status <"3" ? 
                  <Link className="btn btn-primary btn-sm" to="#" onClick={() => { this.setState({ open: true, enableEdit: true, epData: item }) }}>Edit</Link>
                  : 
                  null
              } */}
                    <Link className={item.active == "1" ? "btn btn-primary btn-sm" : "btn btn-secondary btn-sm"} to="#" onClick={() => { this.showEndpointReport(item) }}>Report</Link>
                    {
                      item.audit_pbd_users.length == 1 && item.status !== "3" && item.status !== "4" && item.status < "3" ?
                        <Link className={item.active == "1" ? "btn btn-danger btn-sm" : "btn btn-info btn-sm"} to="#" onClick={() => { this.activeInactive(item) }}>{item.active === "0" ? "Active" : "Make Inactive"}</Link>

                        :
                        item.audit_pbd_users.length == 2 && item.status < "7" ?
                          <Link className={item.active == "1" ? "btn btn-danger btn-sm" : "btn btn-info btn-sm"} to="#" onClick={() => { this.activeInactive(item) }}>{item.active === "0" ? "Active" : "Make Inactive"}</Link>
                          :
                          null
                    }
                  </div>
                </div>
              )
            },
          },

        ];
      }
      const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
      }];


      return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>AuditVista | Published Audit</title>
            </MetaTags>

            <Container fluid>

              <Breadcrumbs
                title={this.state.publishedAuditData.template_name}
                link={"Audit / "}
                description={""}
                changeAuditEditMode={() => {
                  this.setState(prevState => ({
                    modal: !prevState.modal,
                  }))
                }}
                breadcrumbItem="Template"
                isBackButtonEnable={true}
                gotoBack={() => this.gotoBack()}
              />


              <Card >
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={this.state.statusBasedFilteredData}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    isJobListGlobalFilter={false}
                    customPageSize={10}
                    style={{ width: '100%' }}
                    isPagination={true}
                    filterable={false}
                    total_audit={this.state.total_audit}
                    not_started_count={this.state.not_started_count}
                    in_progress_count={this.state.in_progress_count}
                    completed_count={this.state.completed_count}
                    submitted_count={this.state.submitted_count}
                    reviewed_count={this.state.reviewed_count}
                    filterStatus={(data) => this.filterStatus(data)}
                    onClickLocation={() => {
                      this.props.history.push({
                        pathname: "/location",
                        state: {
                          endpoints: this.state.publishedAuditData.endpoints,
                          publishtemplateInfo: this.state.publishedAuditData
                        }
                      })
                    }
                    }
                    onClickChangeAuditEndDate={() => this.setState({ updatePbdAudit: true, open: true })}
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                  />


                </CardBody>
              </Card>
              {/* </Col>
              </Row> */}

            </Container>
          </div>
          {
            console.log(this.state,'state')
          }
          <ReactDrawer
            open={this.state.open}
            className="drawer-open"
            position={this.state.position}
            onClose={this.onDrawerClose}
            style={{ maxHeight: 1000 }}
            noOverlay={this.state.component == "loadendpoint" ? true : false}
          >

            <div style={{ padding: 5, maxHeight: this.state.height, overflow: 'auto' }}>
              {this.state.enableEdit ? 
              <EditEP
                epdata={this.state.epData}
                user_data={this.state.userData}
                audit_data={this.state.publishedAuditData}
                onClose={this.onDrawerClose}
                updateEPData={this.updateEPData}
              /> :
                this.state.component == "add" ?
                  <AddEndpointNode
                    user_data={this.state.userData}
                    endpoints={this.state.publishedAuditData.endpoints}
                    publishtemplateInfo={this.state.publishedAuditData}
                    onClose={this.onDrawerClose}
                    editItem={null}
                    onCancel={this.onDrawerClose}
                  />
                  :
                  this.state.component == "loadendpoint" ?
                    <LoadLocation
                      userInfo={this.state.userData}
                      publishtemplateInfo={this.state.publishedAuditData}
                      onClose={this.onDrawerClose}
                      endpoints={this.state.publishedAuditData.endpoints}
                      // publishedAudit={true}
                      reviewer_status={this.state.endpoints[0].audit_pbd_users.length === 1 ? false : true}
                    />
                    :

                    this.state.updatePbdAudit ?
                      <UpdtAuditInfo
                        epdata={this.state.epData}
                        user_data={this.state.userData}
                        audit_data={this.state.publishedAuditData}
                        onClose={this.onDrawerClose}

                      /> : null
              }
            </div>
          </ReactDrawer>
          {this.state.confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText={this.state.confirmButtonText}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                this.setState({
                  confirm_alert: false,
                  success_dlg: true,
                  dynamic_title: this.state.dynamic_title,
                  dynamic_description: this.state.dynamic_description,
                  // epdata
                }, () => {
                  this.updateEndpoint()
                })
                // ; 
              }
              }
              onCancel={() =>
                this.setState({
                  confirm_alert: false,
                })
              }
            >
              You want to do this!
            </SweetAlert>
          ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => { this.setState({ success_dlg: false }) }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}
          {this.state.showWarning ? (
            <SweetAlert
              title="Message"
              warning
              onConfirm={() => this.setState({ showWarning: false })}
            >
              {this.state.submitMessage}
            </SweetAlert>
          ) : null}
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Audit Vista | Published Audit</title>
            </MetaTags>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 20, alignItems: 'center' }}>
              <div style={{ width: '80%', padding: '0 20px' }}><h4>Published Audit</h4></div>
              <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
              </div>
            </div>
            <Container >
            </Container>
          </div>
        </React.Fragment>
      )
    }
  }

}