import React, { Component } from "react";
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import {
  Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
  Input,Badge
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import CardTitle from "reactstrap/lib/CardTitle";
import facility from '../FacilityScreen'
import moment from 'moment'
import SweetAlert from "react-bootstrap-sweetalert"
import { TableHeader, PaginationComponent } from "../../../common/DataTable"
import FuzzySearch from '../../../common/FuzzySearch';
import TableContainer from "../../../common/TableContainer";

// import "flatpickr/dist/themes/material_blue.css"
// import Flatpickr from "react-flatpickr"

const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from "../../../helpers/urlSocket";


export default class UserAudit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataloaded: false,

      search_files: [],
      dup_search_files: [],
      temp_search_files: [],
      currentPage: 1,
      totalItems: 0,
      items_per_page: 10,
      dupAuditData:[]

    }
  }

  async componentDidMount() {
    await this.getSessionData()
   
  }

  async getSessionData() {

    var data = JSON.parse(sessionStorage.getItem("authUser"));
    // console.log(data,'data')
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"));


    var protect_routes = await facility(user_facilities , 'usradt')
   if(protect_routes !== undefined){
    this.setState({
      configData: data.config_data,
      userData: data.user_data,
      db_info:db_info
    },()=>{
      this.getUserAudit()
    })
  }
  else{
    this.props.history.push('/dashboard')
  }
  }
  getUserAudit() {

    var userInfo = this.state.userData
    console.log(userInfo,'userInfo')
    try { 
      urlSocket.post("webpbdadtdta/getuseraudit", {
        userInfo: {
          _id: userInfo._id,
          encrypted_db_url:this.state.db_info.encrypted_db_url,
          user_code: userInfo.user_code,
          company_id: userInfo.company_id
        }
      })
        .then(response => {
          console.log(response,'response')
          if (response.data.response_code === 500) {
            // urlSocket.post('cog/mob-dashboard-data',{user_id:userInfo._id ,encrypted_db_url:this.state.db_info.encrypted_db_url,  company_id :userInfo.company_id }).then(response=>{
            //   console.log(response,'response')
            // })
            // this.setState({
            //   auditData: response.data.data,
            //   dataloaded: true
            // })
            this.setState({
              dupAuditData:response.data.data,
                  auditData: response.data.data,
              dataloaded: true,
              search_files: response.data.data, 
              dup_search_files: response.data.data,  
              temp_search_files: response.data.data            
          }, ()=>{this.pageClick(1)})      

          }
        })
    } catch (error) {
      console.log("catch error", error)
    }
  }

  loadEndpoints(audit) {
    sessionStorage.removeItem("auditData");
    sessionStorage.setItem("auditData", JSON.stringify(audit));
    this.props.history.push("/usrenpdts");
  }

  showReport(audit) {
    sessionStorage.removeItem("auditData");
    sessionStorage.setItem("auditData", JSON.stringify(audit));
    this.props.history.push("/usrrprt");
  }


  pageClick = (currentPage) => {
    this.setState({ currentPage }, () => { this.paginationProcess() })
  }

  paginationProcess = () => {
    let { currentPage, items_per_page, search_files, dup_search_files } = this.state
    let result = search_files.slice((currentPage - 1) * items_per_page, (currentPage - 1) * items_per_page + items_per_page)
    if (result.length > 0) {
      this.setState({ auditData: result})
    } else {
      this.setState({ auditData: search_files, search_files })
    }
  }

  getFuzzySearch = (search_files) => {
    // this.setState({ auditData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files })
    this.setState({ auditData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files },()=>{ this.pageClick(this.state.currentPage)})

    
    // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })
  }


  render() {

    if (this.state.dataloaded) {
      // const { SearchBar } = Search;
      // const { ExportCSVButton } = CSVExport;
      // const { users } = this.state

      const { isEdit } = this.state
      const options = {
        // pageStartIndex: 0,

        sizePerPage: 10,
        totalSize: this.state.auditData.length, // replace later with size(users),
        custom: true,
      };
      const columns = [
        {
          accessor: 'audit_pbd_name',
          Header: 'Audit',
          filterable: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                <div className="fw-bold text-primary">
                  {item.audit_pbd_name}
                </div>
              </>
            )
          }
        },
        {
          accessor: 'started_on',
          Header: 'Started On',
          disableFilters: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            // console.log(item,'item')
            return (
              <>
              {
                item.started_on !== null &&item.started_on !==undefined ?
                <div className="">

                  {
                  new Intl.DateTimeFormat('en-GB', { month: 'short', day: '2-digit', year: 'numeric', }).format(new Date(item.started_on))
                  }
                </div>
                :
                <div className="">

                  {
                  new Intl.DateTimeFormat('en-GB', { month: 'short', day: '2-digit', year: 'numeric', }).format(new Date(item.updated_on))
                  }
                </div>

              }

              </>
            )
          }
        },
        {
          accessor: 'completed_on',
          Header: 'Completed On',
          disableFilters: true,
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                {
                  item.completed_on !== null &&item.completed_on !== undefined &&
                  <div className="">

                    {new Intl.DateTimeFormat('en-GB', { month: 'short', day: '2-digit', year: 'numeric', }).format(new Date(item.completed_on))}
                  </div>

                }
                
                  {/* {item.completed_on} */}
                  
              </>
            )
          }
        },
        {
          accessor: '',
          Header: 'Status',
          disableFilters: true,
          // style: { width: '40%' }, // Set the content width for the "Status" column
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                {/* <div className="d-flex gap-3 mb-3">
                  <Badge className={"font-size-12 badge-soft-dark"} color={'dark'} pill >
                    Total: {String(item.total)}
                  </Badge>
                  <Badge className={"font-size-12 badge-soft-secondary"} color={'dark'} pill >
                    Not Started: {String(item.not_started)}
                  </Badge>
                  <Badge className={"font-size-12 badge-soft-warning"} color={'dark'} pill >
                    In-progress: {String(item.in_progress)}
                  </Badge>                
                </div>
                <div className="d-flex gap-3">
                <Badge className={"font-size-12 badge-soft-success"} color={'dark'} pill >
                    Completed: {String(item.completed)}
                  </Badge>
                  <Badge className={"font-size-12 badge-soft-info"} color={'dark'} pill >
                    Submitted: {String(item.submitted)}
                  </Badge>
                  <Badge className={"font-size-12 badge-soft-danger"} color={'dark'} pill >
                    Retake: {String(item.retake)}
                  </Badge>
                  <Badge className={"font-size-12 badge-soft-primary"} color={'dark'} pill >
                    Reviewed: {String(item.reviewed)}
                  </Badge>
                </div> */}

                <div className="d-flex gap-3 mb-3">
                  <div className={"font-size-12 badge badge-soft-dark bg-none"} style={{ borderRadius: "15px" }}>
                    Total: {String(item.total)}
                  </div>
                  <div className={"font-size-12 badge badge-soft-secondary badge"} style={{ borderRadius: "15px" }} >
                    Not Started: {String(item.not_started)}
                  </div>
                  <div className={"font-size-12 badge badge-soft-warning badge"} style={{ borderRadius: "15px" }}  >
                    In-progress: {String(item.in_progress)}
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className={"font-size-12 badge badge-soft-success"} style={{ borderRadius: "15px" }}  >
                    Completed: {String(item.completed)}
                  </div>
                  <div className={"font-size-12 badge badge-soft-info"} style={{ borderRadius: "15px" }}  >
                    Submitted: {String(item.submitted)}
                  </div>
                  <div className={"font-size-12 badge badge-soft-danger"} style={{ borderRadius: "15px" }}  >
                    Retake: {String(item.retake)}
                  </div>
                  <div className={"font-size-12 badge badge-soft-primary"} style={{ borderRadius: "15px" }}  >
                    Reviewed: {String(item.reviewed)}
                  </div>
                </div>
              </>

            )
          },
        },

        {
          accessor: "menu",
          disableFilters: true,
          Header: "Action",
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return(
            <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
              <div className="d-flex gap-1">
                <span style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => { this.loadEndpoints(item) }}><i className="bx bx-right-arrow-alt text-primary" /></span>
                {/* <Button className="btn btn-sm " color="secondary" onClick={() => { this.showReport(item) }}>Report</Button> */}
              </div>
            </div>
            )
         },
        },
      ];



    

      const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
      }];
      return (
        <React.Fragment>
          <div className="page-content" >
            <MetaTags>
              <title>AuditVista | User Audits</title>
            </MetaTags>
            {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 24, alignItems: 'center' }} >
              <div style={{ fontWeight: 600, width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
                User Audits
              </div>
            </div> */}
            <Container fluid>

              <Row >
                <Col lg="12">
                  <Card >
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={this.state.dupAuditData}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        isJobListGlobalFilter={false}
                        customPageSize={10}
                        style={{ width: '100%' }}
                        // dynamicBtn={true}
                        // btnClick={(param1, param2) => this.navigateTo("", 1)}
                        // // dynamicParams={params1,params2}
                        // btnName={"Add User"}
                        isPagination={true}
                        filterable={false}
                        // btnName ={"Add User"}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"

                      />




                      {/* <PaginationProvider
                        keyField="id"
                        data={this.state.auditData}
                        columns={columns}
                        pagination={paginationFactory(options)}
                      >
                        {
                          ({
                            paginationProps,
                            paginationTableProps
                          }) => (
                            <ToolkitProvider
                              keyField="id"
                              data={this.state.auditData}
                              columns={columns}
                              search
                              exportCSV
                            >
                              {
                                toolkitprops => (
                                  <React.Fragment>
                                    <Row className="" style={{ paddingTop: 10 }}>
                                      <Col sm="3" >
                                        <div className="search-box ms-2 mb-2">
                                          <div className="position-relative">
                                            <FuzzySearch
                                              search_files={this.state.search_files}
                                              getFuzzySearch={(search_files) => this.getFuzzySearch(search_files)}
                                              dup_search_files={this.state.dup_search_files}
                                              temp_search_files= {this.state.temp_search_files}
                                              keyword={['audit_pbd_name']}
                                            />
                                          </div>
                                        </div>

                                      </Col>
                                      <Col sm="9">
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
                                          <ExportCSVButton className="btn-sm btn-outline-primary" {...toolkitprops.csvProps}>Export as CSV</ExportCSVButton>
                                        </div>
                                      </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={defaultSorted}
                                            classes={ "table align-middle table-nowrap table-hover"}
                                            bordered={false}
                                            striped={false}
                                            responsive
                                          />


                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationComponent
                                          total={this.state.search_files.length}
                                          itemsPerPage={this.state.items_per_page}
                                          currentPage={this.state.currentPage}
                                          onPageChange={currentPage => this.pageClick(currentPage)}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                            </ToolkitProvider>
                          )}
                      </PaginationProvider> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </Container>
          </div>
        </React.Fragment>
      )
    }















    // if (this.state.dataloaded) {
    //   const { SearchBar } = Search;
    //   const { ExportCSVButton } = CSVExport;
    //   // const { users } = this.state

    //   const { isEdit } = this.state
    //   const options = {
    //     // pageStartIndex: 0,

    //     sizePerPage: 10,
    //     totalSize: this.state.auditData.length, // replace later with size(users),
    //     custom: true,
    //   };

    //   const columns = [
    //     {
    //       dataField: 'audit_pbd_name',
    //       text: 'Audit',
    //       sort: true,
    //       formatter: (cellContent, item) => {
    //         return (
    //           <>
    //             <div className="fw-bold text-primary">
    //               {item.audit_pbd_name}
    //             </div>
    //           </>
    //         )
    //       }
    //     },
    //     {
    //       dataField: 'total',
    //       text: 'Total',
    //       sort: false,
    //       formatter: (cellContent, item) => {
    //         return (
    //           <>
    //             <div className="avatar-xs">
    //               <div className={"avatar-title rounded-circle bg-dark text-white font-size-16"}>
    //               {String(item.total)}
    //               </div>
    //             </div>
    //           </>
    //         )
    //       }
    //     },
    //     {
    //       dataField: 'not_started',
    //       text: 'Not started',
    //       sort: false,
    //       formatter: (cellContent, item) => {
    //         console.log("item", item.endpoint_status)
    //         return (
    //           <>
    //             <div className="avatar-xs">
    //               <div className={"avatar-title rounded-circle bg-secondary text-white font-size-16"}>
    //                 {String(item.not_started)}
    //               </div>
    //             </div>
    //           </>
    //         )
    //       }
    //     },
    //     {
    //       dataField: 'in_progress',
    //       text: 'In progress',
    //       sort: false,
    //       formatter: (cellContent, item) => {
    //         return (
    //           <>
    //             <div className="avatar-xs">
    //               <div className={"avatar-title rounded-circle bg-warning text-white font-size-16"}>
    //               {String(item.in_progress)}
    //               </div>
    //             </div>
    //           </>
    //         )
    //       }
    //     },
    //     {
    //       dataField: 'completed',
    //       text: 'Completed',
    //       sort: false,
    //       formatter: (cellContent, item) => {
    //         return (
    //           <>
    //             <div className="avatar-xs">
    //               <div className={"avatar-title rounded-circle bg-success text-white font-size-16"}>
    //               {String(item.completed)}
    //               </div>
    //             </div>
    //           </>
    //         )
    //       }
    //     },
    //     {
    //       dataField: 'submitted',
    //       text: 'Submitted',
    //       sort: false,
    //       formatter: (cellContent, item) => {
    //         return (
    //           <>
    //             <div className="avatar-xs">
    //               <div className={"avatar-title rounded-circle bg-info text-white font-size-16"}>
    //               {String(item.submitted)}
    //               </div>
    //             </div>
    //           </>
    //         )
    //       }
    //     },
    //     {
    //       dataField: 'retake',
    //       text: 'Retake',
    //       sort: false,
    //       formatter: (cellContent, item) => {
    //         return (
    //           <>
    //             <div className="avatar-xs">
    //               <div className={"avatar-title rounded-circle bg-danger text-white font-size-16"}>
    //               {String(item.retake)}
    //               </div>
    //             </div>
    //           </>
    //         )
    //       }
    //     },
    //     {
    //       dataField: 'reviewed',
    //       text: 'Reviewed',
    //       sort: false,
    //       formatter: (cellContent, item) => {
    //         return (
    //           <>
    //             <div className="avatar-xs">
    //               <div className={"avatar-title rounded-circle bg-primary text-white font-size-16"}>
    //               {String(item.reviewed)}
    //               </div>
    //             </div>
    //           </>
    //         )
    //       }
    //     },
    //     {
    //       dataField: "menu",
    //       isDummyField: true,
    //       editable: false,
    //       text: "Action",
    //       formatter: (cellContent, item) => (
    //         <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
    //           <div className="d-flex gap-1">
    //             <Button className="btn btn-sm me-2" color="primary" onClick={() => { this.loadEndpoints(item) }}>Select</Button>
    //             {/* <Button className="btn btn-sm " color="secondary" onClick={() => { this.showReport(item) }}>Report</Button> */}
    //           </div>
    //         </div>
    //       ),
    //     },

    //   ];
    //   const defaultSorted = [{
    //     dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
    //     order: 'asc' // desc or asc
    //   }];
    //   return (
    //     <React.Fragment>
    //       <div className="page-content" >
    //         <MetaTags>
    //           <title>AuditVista | User Audits</title>
    //         </MetaTags>
    //         <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 24, alignItems: 'center' }} >
    //           <div style={{fontWeight: 600, width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
    //             User Audits
    //           </div>
    //         </div>
    //         <Container fluid>

    //           <Row >
    //             <Col lg="12">
    //               <Card >
    //                 <CardBody>
    //                   <PaginationProvider
    //                     keyField="id"
    //                     data={this.state.auditData}
    //                     columns={columns}
    //                     pagination={paginationFactory(options)}
    //                   >
    //                     {
    //                       ({
    //                         paginationProps,
    //                         paginationTableProps
    //                       }) => (
    //                         <ToolkitProvider
    //                           keyField="id"
    //                           data={this.state.auditData}
    //                           columns={columns}
    //                           search
    //                           exportCSV
    //                         >
    //                           {
    //                             toolkitprops => (
    //                               <React.Fragment>
    //                                 <Row className="" style={{ paddingTop: 10 }}>
    //                                   <Col sm="8" >
    //                                     <div className="search-box ms-2 mb-1 d-inline-block">
    //                                       <div className="position-relative">
    //                                         <SearchBar {...toolkitprops.searchProps} />
    //                                         <i className="bx bx-search-alt search-icon" />
    //                                       </div>
    //                                     </div>

    //                                   </Col>
    //                                   <Col sm="4">
    //                                     <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff', width: '100%', justifyContent: 'flex-end' }}>
    //                                       <ExportCSVButton className="btn-sm btn-outline-primary" {...toolkitprops.csvProps}>Export as CSV</ExportCSVButton>
    //                                     </div>
    //                                   </Col>
    //                                 </Row>
    //                                 <hr/>
    //                                 <Row>
    //                                   <Col xl="12">
    //                                     <div className="table-responsive">
    //                                       <BootstrapTable
    //                                         {...toolkitprops.baseProps}
    //                                         {...paginationTableProps}
    //                                         defaultSorted={defaultSorted}
    //                                         classes={
    //                                           "table align-middle table-nowrap table-hover"
    //                                         }
    //                                         bordered={false}
    //                                         striped={false}
    //                                         responsive
    //                                       />


    //                                     </div>
    //                                   </Col>
    //                                 </Row>
    //                                 <Row className="align-items-md-center mt-30">
    //                                   <Col className="pagination pagination-rounded justify-content-end mb-2">
    //                                     <PaginationListStandalone
    //                                       {...paginationProps}
    //                                     />
    //                                   </Col>
    //                                 </Row>
    //                               </React.Fragment>
    //                             )}
    //                         </ToolkitProvider>
    //                       )}
    //                   </PaginationProvider>
    //                 </CardBody>
    //               </Card>
    //             </Col>
    //           </Row>

    //         </Container>
    //       </div>
    //     </React.Fragment>
    //   )
    // }
    else {
      return null
    }
  }
}