import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
  Collapse,
  UncontrolledAlert,
  Button,
  Alert,
  Label,
  Input,
  DropdownItem,
  Table,
  Modal, ModalHeader, ModalBody,
  Tooltip
} from "reactstrap"

import Select from "react-select"
import { DatePicker, Drawer, Badge, Space, Divider, Tag } from 'antd';

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  toggleExpandedForAll,
  defaultGetNodeKey,
  getTreeFromFlatData,
  getNodeAtPath,
  changeNodeAtPath,
  getFlatDataFromTree,
  getVisibleNodeCount
} from "@nosferatu500/react-sortable-tree";
import CrudNode from "./CrudNode";

// import './CSS/DragandDropTree.css'

import _ from "lodash";

// var urlSocket = require("../../helpers/urlSocket");
import urlSocket from "../../../helpers/urlSocket";


export default class FileList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lastSelectedHttpNodePath: [],
      treeData: [],
      addAsFirstChild: false,
      inputChange: false,
      childName: '',
      dataloaded: false,
      crud: false,
      endpoint: false,
      saving: false,
      successmsgshow: false,
      height: props.height,
      showDrawer: false,
      codeError: "Please enter the code",
      codeErrorEnabled: false,
      catErrorEnabled: false,
      enableCode: false,
      enableAddCode: true,
      parent_id: [],
      child_id: [],
      parent_path: [],
      parent_node: [],
      user_path: [],
      canDropStatus: true,
      showConnectedUsers: false,
      showDropdown: false,
      hirerachy_user_level: [],
      node_users: [],
      view_users: false,
      unique_users: [],
      invalid_title: false,
      flat_data: [],
      Nodecount: 1

    }
    this.optionRef = React.createRef()
  }

  componentDidMount() {
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var hInfo = JSON.parse(sessionStorage.getItem("hInfo"));
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var userNodeInfo = JSON.parse(sessionStorage.getItem("userNodeInfo"));
    var hirerachy_user_level = data.config_data.hirerachy_user_level
    this.setState(
      {
        userInfo: data.user_data,
        height: window.innerHeight,
        userNodeInfo,
        db_info: db_info,
        dataloaded: false,
        hirerachy_user_level,
        hInfo

      },
      function () {
        this.getHData()
      }
    )

  }


  toggleDropdown = () => {
    this.setState((prevState) => ({
      showDropdown: !prevState.showDropdown,
    }));
  };

  getHData = (mode) => {
    console.log(mode, 'mode')
    try {

      urlSocket.post("webhstre/gethstructure", {
        info: {
          _id: this.props.hInfo._id,
          company_id: this.props.hInfo.company_id,
          encrypted_db_url: this.state.db_info.encrypted_db_url

        }
      })
        .then((response) => {
          console.log(response.data.data.hstructure)
          if (response.data.response_code == 500) {
            var FlatData = response.data.data.hstructure
            console.log(FlatData, 'FlatData')
            if (mode == undefined) {
              this.setState(
                {
                  dataloaded: false,
                  h_id: response.data.data._id,
                  company_id: response.data.data.company_id,
                  company_name: response.data.data.company_name,
                  totalHLength: response.data.data.lastGeneratedId,
                  flat_data: FlatData
                },
                function () {
                  this.convertFlatDataToTreeData(FlatData)
                }
              )
            }
            else {
              this.setState(
                {
                  h_id: response.data.data._id,
                  company_id: response.data.data.company_id,
                  company_name: response.data.data.company_name,
                  totalHLength: response.data.data.lastGeneratedId,
                  flat_data: FlatData
                })
            }
          }
        })
    } catch (error) {

    }
  }

  convertFlatDataToTreeData = async (FlatData, mode) => {
    console.log(FlatData, mode);
    if (mode == undefined) {
      try {
        const treeData = await this.getTreeFromFlatDataAsync(FlatData);
        console.log(treeData, 'treeData')
        this.setState({
          treeData: treeData,
          dataloaded: true,
        }, () => {
          // console.log(FlatData,mode);
          this.setState({
            Nodecount: getVisibleNodeCount({ treeData: this.state.treeData }),
          })

          this.addUsers();
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
    else {
      try {
        console.log(FlatData);

        const treeData = await this.getTreeFromFlatDataAsync(FlatData);
        console.log(treeData, 'treeData')
        this.setState({
          treeData: treeData,
          dataloaded: true,
        });
        return treeData
      } catch (error) {
        console.error("Error:", error);
      }

    }
  }

  // Define a function that returns a Promise for getTreeFromFlatData
  getTreeFromFlatDataAsync = (FlatData) => {
    // FlatData
    console.log(FlatData)
    try {
      return new Promise((resolve, reject) => {
        try {
          const treeData = getTreeFromFlatData({
            flatData: FlatData,
            getKey: (node) => node.id,
            getParentKey: (node) => node.parent,
            rootKey: null,
            expanded: true
          });
          console.log(treeData, 'treeData')
          resolve(treeData);
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {

    }

  }


  treeDataToFlat = () => {
    var getNodeKey = ({ treeIndex }) => treeIndex
    var flatData = getFlatDataFromTree(
      {
        treeData: this.state.treeData,
        getNodeKey,
        ignoreCollapsed: false,
      })

    var explicitData = _.filter(flatData, item => {
      return item
    })

    return explicitData
  }

  saveHStructure = () => {
    var explicitData = this.treeDataToFlat()
    this.saveData(explicitData)
  }

  saveData = (flatData) => {
    this.setState({ saving: true })
    var mapped = _.map(flatData, "node")
    console.log(mapped, 'mapped')
    try {
      urlSocket.post("webhstre/savehstructure", {
        info: {
          _id: this.props.hInfo._id,
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.props.hInfo.company_id,
          flatData: _.map(flatData, "node"),
          lastGeneratedId: this.state.totalHLength
        }
      })
        .then((response) => {
          if (response.data.response_code == 500) {
            this.setState({ saving: false, crud: false, successmsgshow: true, alertMessage: response.data.message }, () => { this.getHData("stop") })
            setTimeout(() => { this.setState({ successmsgshow: false, }) }, 2500)

          }
        })
    } catch (error) {

    }
  }

  publishHStructure = () => {
    var explicitData = this.treeDataToFlat()
    this.publishData(explicitData)
  }

  publishData = (flatData) => {
    this.setState({ saving: true })
    // console.log( _.map(flatData, "node"),)
    try {
      urlSocket.post("webhstre/publishhstructure", {
        info: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.props.hInfo.company_id,
          user_id: this.state.userInfo._id,
          user_name: this.state.userInfo.first_name,
          h_id: this.state.h_id,
          flatData: _.map(flatData, "node"),
        }
      })
        .then((response) => {
          if (response.data.response_code == 500) {
            this.setState({ saving: false, successmsgshow: true, alertMessage: response.data.message })
            setTimeout(() => { this.setState({ successmsgshow: false, }) }, 2500)
          }
        })
    } catch (error) {

    }
  }

  listOutCategory = () => {
    var flatData = _.map(this.treeDataToFlat(), item => {
      return item.node.subtitle
    })

    var uniqueHlevels = _.uniqBy(flatData)
    return uniqueHlevels
  }

  editCat = (data) => {
    console.log(data, 'data')
    this.setState({ subtitle: data.value, edit_cat_name: true })
  }

  selectCat = (event, mode) => {
    console.log(event, mode)
    if (mode == undefined) {
      console.log(event.target.value)
      this.setState({ createNewCat: event.target.value == "1" ? true : false, catErrorEnabled: false, catError: '' })
    }
    else {
      this.optionRef.current.value = "0"
      this.setState({ createNewCat: false, catErrorEnabled: false, catError: '' })
    }
  }

  updateRecursiveTreeData = (childData, nodeData) => {
    console.log(childData, 'childData')
    _.each(childData, (item, idx) => {
      if (childData[idx].id == nodeData.id) {
        childData[idx] = nodeData
      }
      if (childData[idx].children !== undefined) {
        this.updateRecursiveTreeData(childData[idx].children, nodeData)
      }
    })
  }




  addUsers = async () => {
    console.log(this.state.userNodeInfo)
    if (this.state.userNodeInfo != undefined) {
      if (this.state.userNodeInfo.selectedUsers.length != 0 || this.state.userNodeInfo.selectedUsers.length == 0) {
        var treeData = this.state.treeData
        var getLength
        _.each(this.state.userNodeInfo.selectedUsers, (item, idx) => {
          getLength = this.state.totalHLength + idx + 1

        })
        var userNodeInfo = { ...this.state.userNodeInfo }
        try {
          this.setState({
            treeData,
            totalHLength: getLength,
            dataloaded: false
          })
          var getNodeData = this.state.userNodeInfo.nodeInfo.node
          sessionStorage.removeItem("userNodeInfo");
          console.log(this.state.userNodeInfo, this.state.treeData, this.state.userNodeInfo.nodeInfo.flat_data)
          const index = userNodeInfo.nodeInfo.flat_data.findIndex(node => node.id === userNodeInfo.nodeInfo.node.id);
          console.log(index, 'index')
          if (index !== -1) {
            userNodeInfo.nodeInfo.flat_data[index] = userNodeInfo.nodeInfo.node;
          }
          var treeData = await this.convertFlatDataToTreeData(userNodeInfo.nodeInfo.flat_data, true)
          console.log(treeData, 'tree', this.state.flat_data, this.state.treeData)
          this.setState({
            treeData
          }, () => {
            this.saveHStructure()
            this.setState({ crud: false, dataloaded: true })
          })

        } catch (error) {
          console.log(error, 'error')
        }
      }
      else {
        this.saveHStructure()
      }
    }
  }



  // addUsers = async () => {
  //   console.log(this.state.userNodeInfo);
  //   if (this.state.userNodeInfo != undefined) {
  //     if (this.state.userNodeInfo.selectedUsers.length !== 0 || this.state.userNodeInfo.selectedUsers.length === 0) {
  //       var treeData = this.state.treeData;
  //       var getLength;
  //       _.each(this.state.userNodeInfo.selectedUsers, (item, idx) => {
  //         getLength = this.state.totalHLength + idx + 1;
  //       });
  //       try {
  //         var getNodeData = this.state.userNodeInfo.nodeInfo.node;
  //         sessionStorage.removeItem("userNodeInfo");
  //         console.log(this.state.userNodeInfo, this.state.treeData, this.state.userNodeInfo.nodeInfo.flat_data);
  //         const index = this.state.userNodeInfo.nodeInfo.flat_data.findIndex(node => node.id === this.state.userNodeInfo.nodeInfo.node.id);
  //         console.log(index, 'index');
  //         if (index !== -1) {
  //           // Create a copy of the flat_data array
  //           const updatedFlatData = [...this.state.userNodeInfo.nodeInfo.flat_data];
  //           updatedFlatData[index] = this.state.userNodeInfo.nodeInfo.node;

  //           // Update the state using setState
  //           this.setState({
  //             treeData,
  //             totalHLength: getLength,
  //             dataloaded: false,
  //             userNodeInfo: {
  //               ...this.state.userNodeInfo,
  //               nodeInfo: {
  //                 ...this.state.userNodeInfo.nodeInfo,
  //                 flat_data: updatedFlatData,
  //               },
  //             },
  //           });
  //         }

  //         var treeData = await this.convertFlatDataToTreeData(this.state.userNodeInfo.nodeInfo.flat_data, true);
  //         console.log(treeData, 'tree', this.state.flat_data);
  //         this.saveHStructure();
  //         this.setState({ crud: false, dataloaded: true });
  //       } catch (error) {
  //         console.log(error, 'error');
  //       }
  //     } else {
  //       this.saveHStructure();
  //     }
  //   }
  // };





  async addUsersToChildrens(nodeInfo, getTreeData, user_path) {
    _.each(nodeInfo, (child, index) => {
      var index = _.findIndex(user_path, function (item) { return item.id == child.id })
      if (index == -1) { child.user_path = child.user_path.concat(user_path) }
      if (child.children) {
        this.addUsersToChildrens(child.children, getTreeData, user_path)
      }
    })
  }



  navigateTo = (node, path, getNodeKey, type, cat_type) => {
    this.setState({ showConnectedUsers: false, })
    var flatData = getFlatDataFromTree(
      {
        treeData: this.state.treeData,
        getNodeKey: getNodeKey,
        ignoreCollapsed: false,
      })
    var nodeInfo = {
      node, path, type, totalLength: flatData.length, cat_type: cat_type, treeData: this.state.treeData, flat_data: this.state.flat_data
    }
    sessionStorage.removeItem("nodeInfo");
    sessionStorage.setItem("nodeInfo", JSON.stringify(nodeInfo));
    this.setState({
      nodeInfo
    })

    if (type === 2) {
      this.props.history.push("/mpusr");
    }
    else
      if (type === 1) {
        this.props.history.push("/mpendpt");
      }
  }




  deleteNodedup = async (user_data, node, path, getNodeKey, idx, mode) => {
    console.log(user_data, node, path, getNodeKey, idx, 'user_data, node, path, getNodeKey, idx')
    // var previousNodepath = [...path]
    // previousNodepath = previousNodepath.splice(0, previousNodepath.length - 1)
    // var getPreviousNode = await this.findNode(node.parent_node.id)

    if (mode == "delete_all") {
      var filter_userpath = node.user_path.filter(ele => ele._id !== user_data._id)
      var filtered_unique_user = node.unique_users.filter(ele => ele._id !== user_data._id)
      node["user_path"] = filter_userpath
      node["unique_users"] = filtered_unique_user
    }
    else {

      var filtered_data = node.user_path.filter((ele, idx_) => {
        if (idx_ !== idx) {
          return ele
        }

      })
      node.user_path = filtered_data
    }
    console.log(node, 'node')
    this.setState({
      node_users: node.unique_users,
      dataloaded: false
    }, () => { this.setState({ dataloaded: true }); this.saveHStructure() })
  }







  deleteNode = async (node, path, getNodeKey) => {
    this.setState(state => ({
      treeData: removeNodeAtPath({
        treeData: state.treeData,
        path,
        getNodeKey,
      }),
      crud: false,
      showConnectedUsers: false,
    }),
      async function () {
        this.saveHStructure()
      })
  }

  async updateChildrens(nodeInfo, node) {
    _.each(nodeInfo, (child, idx) => {

      _.each(node, childItem => {
        var index = _.findIndex(child.user_path, function (item) { return item.id == childItem.id })
        if (index != -1) { child.user_path.splice(index, 1) }
      })
      if (child.children) {
        this.updateChildrens(child.children, node)
      }
    })
  }

  async findNode(nodeID) {
    var getData = this.treeDataToFlat()
    console.log(getData, 'getData')
    var findNode
    _.each(getData, item => {
      if (nodeID == item.node.id) {
        findNode = item.node
      }
    })
    return findNode
  }




  dndNode = async (droppedNode, getNodeKey) => {
    console.log(droppedNode,'droppedNode')
    if (droppedNode.node.type == 2) {
      if (droppedNode.nextParentNode != null && droppedNode.nextParentNode.type != 2) {
        var previousNodepath = [...droppedNode.prevPath]
        previousNodepath = previousNodepath.splice(0, previousNodepath.length - 1)

        var previousNodeChildrens = await this.findNode(droppedNode.node.parent_node.id)


        var childNodes = []
        await this.updateChildrens(previousNodeChildrens.children, [droppedNode.node])
        await this.getAllChildNodes(droppedNode.nextParentNode.children, childNodes)
        droppedNode.node.parent_path = droppedNode.nextParentNode.parent_path
        var getParentNodeInfo = {
          id: droppedNode.nextParentNode.id,
          parent: droppedNode.nextParentNode.parent,
          title: droppedNode.nextParentNode.title,
          subtitle: droppedNode.nextParentNode.subtitle,
          code: droppedNode.nextParentNode.code,
          type: droppedNode.nextParentNode.type,
          h_id: this.state.h_id,
          company_id: this.state.company_id,
          company_name: this.state.company_name,
          owner_id: this.state.userInfo._id,
          user_id: droppedNode.nextParentNode.user_id

        }

        this.setState(state => ({
          treeData: changeNodeAtPath({
            treeData: state.treeData,
            path: droppedNode.path,
            getNodeKey,
            newNode: {
              title: droppedNode.node.title,
              subtitle: droppedNode.node.subtitle,
              code: droppedNode.node.code,
              id: droppedNode.node.id,
              parent: droppedNode.nextParentNode.id,
              children: droppedNode.node.children,
              type: droppedNode.node.type,
              parent_node: getParentNodeInfo,
              parent_path: droppedNode.node.parent_path.concat([getParentNodeInfo]),
              h_id: this.state.h_id,
              company_id: this.state.company_id,
              company_name: this.state.company_name,
              owner_id: this.state.userInfo._id,
              user_path: droppedNode.nextParentNode.user_path,
              unique_users: droppedNode.nextParentNode.unique_users,

              user_id: droppedNode.node.user_id,
              node_level: droppedNode.path.length - 1,
              child_nodes: childNodes
            },
          }),
          crud: false,
          showConnectedUsers: false,
        }),
          async function () {
            var droppedNodeInfo = {
              id: droppedNode.node.id,
              parent: droppedNode.node.parent,
              title: droppedNode.node.title,
              subtitle: droppedNode.node.subtitle,
              code: droppedNode.node.code,
              type: droppedNode.node.type,
              h_id: this.state.h_id,
              company_id: this.state.company_id,
              company_name: this.state.company_name,
              owner_id: this.state.userInfo._id,
              user_id: droppedNode.node.user_id,
              parent_node: getParentNodeInfo,
            }
            await this.updateUsersToChildrens(droppedNode.nextParentNode.children, droppedNodeInfo)
            this.saveHStructure()
          }
        )
      }

    }
    else {
      if (droppedNode.nextParentNode != null && droppedNode.nextParentNode.type != 2) {

        droppedNode.node.parent_path = droppedNode.nextParentNode.parent_path
        var getParentNodeInfo = {
          id: droppedNode.nextParentNode.id,
          parent: droppedNode.nextParentNode.parent,
          title: droppedNode.nextParentNode.title,
          subtitle: droppedNode.nextParentNode.subtitle,
          code: droppedNode.nextParentNode.code,
          type: droppedNode.nextParentNode.type,
          h_id: this.state.h_id,
          company_id: this.state.company_id,
          company_name: this.state.company_name,
          owner_id: this.state.userInfo._id,
        }


        console.log(droppedNode, 'dropp')
        if (droppedNode.node.children !== undefined) {
          if (droppedNode.node.children.length > 0) {
            this.recursiveUpdata(droppedNode, droppedNode.node.children, 0, droppedNode.path.length)
          }
        }
        console.log(droppedNode, 'dropp')


        var returnUsers = []
        var users = _.filter(droppedNode.nextParentNode.children, item => {
          if (item.type === 2) {
            //("itm","812")
            returnUsers.push({
              id: item.id,
              parent: item.parent,
              title: item.title,
              subtitle: item.subtitle,
              code: item.code,
              type: item.type,
              h_id: this.state.h_id,
              company_id: this.state.company_id,
              company_name: this.state.company_name,
              owner_id: this.state.userInfo._id,
            })
          }
        })

        this.setState(state => ({
          treeData: changeNodeAtPath({
            treeData: state.treeData,
            path: droppedNode.path,
            getNodeKey,
            newNode: {
              title: droppedNode.node.title,
              code: droppedNode.node.code,
              id: droppedNode.node.id,
              parent: droppedNode.nextParentNode.id,
              children: droppedNode.node.children == undefined ? [] : droppedNode.node.children,
              type: droppedNode.node.type,
              parent_node: getParentNodeInfo,
              parent_path: droppedNode.node.parent_path.concat([getParentNodeInfo]),
              h_id: this.state.h_id,
              company_id: this.state.company_id,
              company_name: this.state.company_name,
              owner_id: this.state.userInfo._id,
              user_path: droppedNode.node.user_path,
              unique_users: droppedNode.node.unique_users == undefined ? [] : droppedNode.node.unique_users,
              node_level: droppedNode.path.length - 1,
              category_input: droppedNode.node.category_input,
              subtitle: droppedNode.node.category_input == false ? "Level " + droppedNode.path.length : droppedNode.node.subtitle,
              node_positon: "Level " + droppedNode.path.length


            },
          }),
          crud: false,
          showConnectedUsers: false,
        }),
          async function () {
            var droppedNodeInfo = {
              id: droppedNode.node.id,
              parent: droppedNode.node.parent,
              title: droppedNode.node.title,
              subtitle: droppedNode.node.subtitle,
              children: droppedNode.node.children,
              code: droppedNode.node.code,
              type: droppedNode.node.type,
              parent_node: getParentNodeInfo,
              parent_path: droppedNode.nextParentNode.parent_path.concat([getParentNodeInfo]),
              h_id: this.state.h_id,
              company_id: this.state.company_id,
              company_name: this.state.company_name,
              owner_id: this.state.userInfo._id,
              node_level: droppedNode.path.length - 1,
              user_path: droppedNode.node.user_path,

            }
            this.saveHStructure()
          })
      }
      else {
        //("Main Level",droppedNode)
        if (droppedNode.node.children !== undefined) {
          if (droppedNode.node.children.length > 0) {
            this.recursiveUpdata(droppedNode, droppedNode.node.children, 0, droppedNode.path.length)
          }
        }
        console.log(droppedNode, 'dropp')

        this.setState(state => ({
          treeData: changeNodeAtPath({
            treeData: state.treeData,
            path: droppedNode.path,
            getNodeKey,
            newNode: {
              title: droppedNode.node.title,
              code: droppedNode.node.code,
              id: droppedNode.node.id,
              parent: null,
              children: droppedNode.node.children == undefined ? [] : droppedNode.node.children,
              type: droppedNode.node.type,
              parent_node: [],
              parent_path: [],
              h_id: this.state.h_id,
              company_id: this.state.company_id,
              company_name: this.state.company_name,
              owner_id: this.state.userInfo._id,
              user_path: droppedNode.node.user_path,
              unique_users: droppedNode.node.unique_users == undefined ? [] : droppedNode.node.unique_users,
              node_level: droppedNode.path.length - 1,
              // subtitle: droppedNode.node.subtitle,
              // subtitle: "Level " + droppedNode.path.length
              category_input: droppedNode.node.category_input,
              subtitle: droppedNode.node.category_input == false ? "Level " + droppedNode.path.length : droppedNode.node.subtitle,
              node_positon: "Level " + droppedNode.path.length


            },
          }),
          crud: false,
          showConnectedUsers: false,
        }),
          function () {
            this.saveHStructure()
          });
      }
    }

  }

  recursiveUpdata = (nodeData, childData, count, node_level_length) => {
    console.log(childData, 'childData',node_level_length)
    _.each(childData, (item, idx) => {
      childData[idx].node_level = node_level_length
      childData[idx].node_positon = "Level " + (node_level_length + 1)
      // //(idx,'idx',item)
      if (childData[idx].children !== undefined) {
        count++;
        var level_idx = childData[idx].node_level + 1
        //(childData[idx].node_level, 'childData[idx].node_level')
        this.recursiveUpdata(nodeData, childData[idx].children, count, level_idx)
      }
    })

    // _.each(childData,(item,idx)=>{
    //   if(childData[idx].children !== undefined){
    //     count ++;
    //     // node_level_length++;
    //     var level_idx = childData[idx].node_level +1
    //     //(childData[idx].node_level,'childData[idx].node_level')
    //     this.recursiveUpdata(nodeData, childData[idx].children, count, level_idx)
    //   }
    // })

    // if (childData[count].children !== undefined) {
    //     childData[count].node_level = node_level_length
    //     childData[count].subtitle = "Level " + (node_level_length+1)
    //     //(childData, 'childData', node_level_length)
    //     node_level_length++;
    //     this.recursiveUpdata(nodeData, childData[count].children, count, node_level_length)
    // }
    // else {
    //   //("stop",childData)
    //   childData[count].node_level = node_level_length
    //   childData[count].subtitle = "Level " + (node_level_length+1)
    // }
  }



  async updateUsersToChildrens(nodeInfo, user_path) {
    _.each(nodeInfo, (child, index) => {
      var index = _.findIndex(child.user_path, function (item) { return item.id == user_path.id })
      if (index == -1) { child.user_path = child.user_path.concat(user_path) }
      if (child.children) {
        this.updateUsersToChildrens(child.children, user_path)
      }
    })
  }

  async updateToChildNode(childElement, user_node) {

    var addObject = {
      id: childElement.id,
      parent: childElement.parent,
      title: childElement.title,
      subtitle: childElement.subtitle,
      code: childElement.code,
      type: childElement.type,
      h_id: this.state.h_id,
      company_id: this.state.company_id,
      company_name: this.state.company_name,
      owner_id: this.state.userInfo._id,
    }

    // //("childrens", childElement.parent_path.concat([addObject]))
    var childParentpath = childElement.parent_path.concat([addObject])


    _.each(childElement.children, child => {

      child.parent_node = addObject
      child.parent_path = childParentpath
      child.node_level = childElement.node_level + 1
      //  child.user_path = getNextNodeUserChildren
      var users = []
      _.each(user_node, childItem => {
        var index = _.findIndex(child.user_path, function (item) { return item.id == childItem.id })
        //("index -- ", index, child.user_path, childItem.title)
        if (index == -1) {
          users = users.concat([childItem])
        }
      })
      child.user_path = child.user_path.concat(users)
      //("users", users)
      if (child.children) {
        var droppedNodeInfo = {
          id: child.id,
          parent: child.parent,
          title: child.title,
          subtitle: child.subtitle,
          children: child.children,
          code: child.code,
          type: child.type,
          parent_node: childElement,
          parent_path: childParentpath,
          h_id: this.state.h_id,
          company_id: this.state.company_id,
          company_name: this.state.company_name,
          owner_id: this.state.userInfo._id,
          user_path: child.user_path,
        }
        this.updateToChildNode(droppedNodeInfo, user_node)
      }

    })

  }

  async getAllChildNodes(childElement, childNodes) {
    _.each(childElement, item => {
      if (item.type != 2) {
        var childItem = {
          company_id: item.company_id,
          company_name: item.company_name,
          h_id: item.h_id,
          id: item.id,
          node_level: item.node_level,
          owner_id: item.owner_id,
          parent: item.parent,
          subtitle: item.subtitle,
          title: item.title,
          type: item.type,
        }
        childNodes.push(childItem)
      }
      if (item.children) {
        this.getAllChildNodes(item.children, childNodes)
      }
    })
  }

  addNode = (node, path, getNodeKey, type) => {
    console.log(path, 'path')
    var getNodeInfo = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
    })
    console.log(getNodeInfo,'getNodeInfo')
    var getNextNodeUserChildren = _.filter(node.children, item => {
      if (item.type == 2) {
        return item
      }
    })
    getNextNodeUserChildren.concat(node.user_path)
    var getPranetInfo = {
      id: getNodeInfo.node.id,
      parent: getNodeInfo.node.parent,
      title: getNodeInfo.node.title,
      subtitle: getNodeInfo.node.subtitle,
      code: getNodeInfo.node.code,
      type: getNodeInfo.node.type,
      h_id: this.state.h_id,
      company_id: this.state.company_id,
      company_name: this.state.company_name,
      owner_id: this.state.userInfo._id

    }
    console.log(getNodeInfo, 'getNodeInfo', this.state.crud)



    this.setState({
      main_level: false,
      edit_node: false,
      edit_cat_name: false,
      node: node,
      path: path,
      getNodeKey: getNodeKey,
      crud: true,
      crudStatus: 1,
      title: getNodeInfo.node.title,
      subtitle: getNodeInfo.node.subtitle,
      getTitle: "",
      getSubTitle: "",
      getSubTitledd: "0",
      getCode: "",
      catErrorEnabled: false,
      codeErrorEnabled: false,
      catError: "",
      type,
      id: getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0 ? getNodeInfo.node.id : getNodeInfo.node.children.length + 1,
      parent: getNodeInfo.node.id,
      enableCode: false,
      enableAddCode: true,
      child_id: [],
      parent_node: getPranetInfo,
      parent_path: node.parent_path.concat([getPranetInfo]),
      h_id: this.state.h_id,
      company_id: this.state.company_id,
      company_name: this.state.company_name,
      owner_id: this.state.userInfo._id,
      showConnectedUsers: false,
      view_users: false,
    })
  }

  editNode = (path, getNodeKey) => {
    console.log(path, 'path')
    var getNodeInfo = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
    })
    console.log(getNodeInfo, 'getnode')

    //("edit", path, getNodeInfo)

    this.setState({
      edit_node: true,
      crud: true,
      edit_cat_name: false,
      main_level: false,
      crudStatus: 2,
      path: path,
      node: getNodeInfo.node,
      type: getNodeInfo.node.type,
      getNodeInfo: getNodeInfo.node,
      title: getNodeInfo.node.title,
      subtitle: getNodeInfo.node.subtitle,
      getNodeKey: getNodeKey,
      getCode: getNodeInfo.node.code,
      getTitle: getNodeInfo.node.title,
      getSubTitle: getNodeInfo.node.subtitle,
      getSubTitledd: getNodeInfo.node.subtitle,
      catErrorEnabled: false,
      codeErrorEnabled: false,
      catError: "",
      enableCode: getNodeInfo.node.code == null || getNodeInfo.node.code == "" ? false : true,
      enableAddCode: getNodeInfo.node.code == null || getNodeInfo.node.code == "" ? true : false,
      parent_node: getNodeInfo.node.parent_node,
      parent_path: getNodeInfo.node.parent_path,
      h_id: this.state.h_id,
      company_id: this.state.company_id,
      company_name: this.state.company_name,
      owner_id: this.state.userInfo._id,
      user_path: getNodeInfo.node.user_path,
      unique_users: getNodeInfo.node.unique_users,
      showConnectedUsers: false,
    })

  }


  async getAllNodes(childrens, childNodes) {
    _.each(childrens, item => {
      //("item", item)
      var childItem = {
        company_id: item.company_id,
        company_name: item.company_name,
        h_id: item.h_id,
        id: item.id,
        node_level: item.node_level,
        owner_id: item.owner_id,
        parent: item.parent,
        subtitle: item.subtitle,
        title: item.title,
        type: item.type,
      }
      if (item.type != 2) {
        childNodes.push(childItem)
      }
      // //("childNodes", childNodes)
      if (item.children) {
        this.getAllNodes(item.children, childNodes)
      }
    })
  }

  async addNodesToUsers(nodeChildren, node) {
    var childNodes = []
    if (node.children) { await this.getAllNodes(node.children, childNodes) }
    var concatedElements = node.children ? childNodes.concat([node]) : [node]
    //("concatedElements", concatedElements)
    //var concatedElements = node.children ? node.children.concat([node]) : [node]
    var nodeChildrenUsers = _.filter(nodeChildren.children, { type: 2 })
    nodeChildrenUsers = nodeChildrenUsers.concat(nodeChildren.user_path)
    _.each(nodeChildrenUsers, async item => {
      var getNode = await this.findNode(item.id)
      _.each(concatedElements, childElem => {
        getNode.child_nodes = getNode.child_nodes.concat([childElem])
      })
    })
  }

  updateNodesToUsers(user_path, node) {
    //(user_path, node,'user_path, node')
    _.each(user_path, async item => {
      var getNode = await this.findNode(item.id)
      var childIds = _.map(getNode.child_nodes, "id")
      //("item ---- ", item, getNode, childIds, node)
      if (childIds.indexOf(node.id) == -1) {
        getNode.child_nodes = getNode.child_nodes.concat([node])
      }
    })
  }

  validateNode = (values) => {
    //(values,'values')
    console.log(values, 'values')
    var flatData = getFlatDataFromTree(
      {
        treeData: this.state.treeData,
        getNodeKey: this.state.getNodeKey,
        ignoreCollapsed: false,
      })

    var returnValue = true
    var getCatValue = -1

    // //("values.subtitledd", values.subtitledd)
    if (values.subtitledd == "1") {
      var getSubtitle = String(values.subtitle).replace(/\s/g, '').toLowerCase()
      getCatValue = _.findIndex(flatData, function (o) { if (String(o.node.subtitle).replace(/\s/g, '').toLowerCase() == getSubtitle) { return true } else { return false } });
      if (getCatValue != -1) {
        this.setState({
          catErrorEnabled: true,
          getSubTitle: values.subtitle,
          getSubTitledd: values.subtitledd,
          catError: "Category is already available",
        })
        returnValue = false
      }
    }
    else {
      this.setState({
        catErrorEnabled: false,
      })
    }

    if (this.state.enableCode) {
      var getCode = String(values.code).replace(/\s/g, '').toLowerCase()
      //(flatData,'flatData',getCode)
      console.log(flatData, 'flatData')
      if (this.state.edit_node === true) {
        var updated_flatData = _.map(flatData, 'node')
        console.log(updated_flatData, 'updated_flatData', this.state.node)
        const to_filter_data = updated_flatData.filter(item =>
          item.title !== this.state.node.title
        );
        console.log(to_filter_data, 'to_filter_data', this.state.edit_node)
        var getCodeValue = _.findIndex(to_filter_data, function (o) { if (String(o.code).replace(/\s/g, '').toLowerCase() == getCode) { return true } else { return false } });
      }
      else {
        var getCodeValue = _.findIndex(flatData, function (o) { if (String(o.node.code).replace(/\s/g, '').toLowerCase() == getCode) { return true } else { return false } });
      }
      //(getCodeValue,'getCodeValue')

      if (getCodeValue != -1) {
        this.setState({
          codeErrorEnabled: true,
          getCode: values.code,
          codeError: "Code is already available"
        })
        returnValue = false
      }
      else {
        this.setState({
          codeErrorEnabled: false,
        })
      }
    }


    return returnValue
  }


  upDateCatName = (flatData, values) => {
    flatData.map((data, idx) => {
      if (data.subtitle === this.state.node.subtitle) {
        flatData[idx]["subtitle"] = values.subtitledd
      }
    })


  }

  //   upDateCatName=(childData,values,count)=>{
  //   _.each(childData, (item, idx) => {
  //     console.log(item,this.state.node.subtitle)
  //     if(item.subtitle === this.state.node.subtitle){
  //       console.log( childData[idx],' childData[idx]')
  //     childData[idx].subtitle = values.subtitledd
  //   }
  //     // if (childData[idx].children !== undefined) {
  //       count++;
  //       console.log(childData[idx].children,'childData[idx].children')
  //       this.upDateCatName(childData[idx].children, values,count)
  //     // }
  //   })
  // }

  crudNode = async (event, values, errors) => {
    console.log(values, 'values',this.state.crudStatus, this.state.path, this.state.getNodeKey, this.state.node)

    if (values.subtitledd == "1" && /^\s*$/.test(values.subtitle)) {
      console.log("validater")
      this.setState({
        catErrorEnabled: true,
        catError: "Please Enter the Category"
      })
    }
    else {
      var allowToWrite = this.validateNode(values)
      console.log(allowToWrite, 'allowToWrite')
      if (allowToWrite) {
        if (this.state.crudStatus == 1) { //Add new node
          console.log(values, 'values', this.state.node)

          // var flatData = getFlatDataFromTree(
          //   {
          //     treeData: this.state.treeData,
          //     getNodeKey: this.state.getNodeKey,
          //     ignoreCollapsed: false,
          //   })
          //("id", this.state.totalHLength + 1)
          this.setState(state => ({
            treeData: addNodeUnderParent({
              treeData: state.treeData,
              parentKey: this.state.path[this.state.path.length - 1],
              expandParent: true,
              getNodeKey: this.state.getNodeKey,
              newNode: {
                title: values.title,
                subtitle: values.subtitledd == "" || (values.subtitledd === "1" && values.subtitle === "") ? "Level " + (this.state.node == undefined ? 0 + 1 : this.state.main_level ? this.state.node.node_level + 1 : this.state.node.node_level + 2) : values.subtitledd == "0" || values.subtitledd === "" ? "Level " + (this.state.path.length + 1) : values.subtitledd === "1" ? values.subtitle : values.subtitledd,
                category_input: (values.subtitledd !== "" && values.subtitledd !== "0") || (values.subtitledd == "1" && values.subtitle !== "") ? true : false,
                node_positon: "Level " + (this.state.node == undefined ? 0 + 1 : this.state.main_level ? this.state.node.node_level + 1 : this.state.node.node_level + 2), //node level automation
                code: values.code,
                id: this.state.totalHLength + 1,
                parent: this.state.parent,
                type: this.state.type,
                child_id: this.state.child_id,
                parent_node: this.state.parent_node,
                parent_path: this.state.parent_path,
                h_id: this.state.h_id,
                children: [],
                company_id: this.state.company_id,
                company_name: this.state.company_name,
                owner_id: this.state.userInfo._id,
                user_path: this.state.user_path,
                node_level: this.state.path.length
              },
              addAsFirstChild: state.addAsFirstChild,
            }).treeData,
            crud: false,
            dataloaded: false,
            totalHLength: this.state.totalHLength + 1,
            createNewCat: false,
            getSubTitledd: values.subtitledd
          }
          ),
            function () {
              this.setState({ dataloaded: true }, () => { this.saveHStructure() })
            })

        }
        else
          if (this.state.crudStatus == 2) // Edit node
          {
            console.log(this.state.getNodeInfo, this.state.node, this.state.totalHLength + 1, 'this.state.totalHLength + 1', this.state.parent, this.state.getNodeInfo.parent)
            this.setState(state => ({
              treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: this.state.path,
                expandParent: true,
                newNode: {
                  title: values.title,
                  category_input: this.state.node.category_input,
                  node_positon: "Level " + (this.state.node.node_level + 1),
                  subtitle: this.state.node.category_input == false ? "Level " + (this.state.node.node_level + 1) : values.subtitledd == "0" || values.subtitledd == "1" ? values.subtitle : this.state.node.subtitle,
                  code: values.code,
                  children: this.state.getNodeInfo.children,
                  child_id: [],
                  id: this.state.totalHLength + 1,
                  parent: this.state.getNodeInfo.parent,
                  type: this.state.type,
                  parent_node: this.state.parent_node,
                  parent_path: this.state.parent_path,
                  h_id: this.state.h_id,
                  company_id: this.state.company_id,
                  company_name: this.state.company_name,
                  owner_id: this.state.userInfo._id,
                  user_path: this.state.user_path,
                  unique_users: this.state.unique_users,
                  node_level: this.state.node.node_level
                },
                getNodeKey: this.state.getNodeKey,
              }),
              crud: false,
              dataloaded: false,
              createNewCat: false,
              getSubTitledd: values.subtitledd
            }),
              function () {
                this.setState({
                  dataloaded: true
                }, () => { this.saveHStructure() })
              }
            )
          }
          else
            if (this.state.crudStatus == 0) {

              console.log("allowToWrite")
              this.setState(state => ({
                treeData: addNodeUnderParent({
                  treeData: state.treeData,
                  parentKey: this.state.path[this.state.path.length - 1],
                  expandParent: true,
                  getNodeKey: this.state.getNodeKey,
                  newNode: {
                    title: values.title,
                    subtitle: values.subtitledd == "0" || values.subtitledd === "" || (values.subtitledd === "1" && (values.subtitle === "")) ? "Level " + (this.state.path.length + 1) : values.subtitledd === "1" ? values.subtitle : values.subtitledd,
                    node_positon: "Level " + (this.state.path.length + 1),
                    category_input: values.subtitledd == "" || (values.subtitledd === "1" && values.subtitle === "") ? false : true,
                    code: values.code,
                    id: this.state.totalHLength + 1,
                    parent: null,
                    type: this.state.type,
                    child_id: this.state.child_id,
                    parent_node: this.state.parent_node,
                    parent_path: this.state.parent_path,
                    h_id: this.state.h_id,
                    company_id: this.state.company_id,
                    company_name: this.state.company_name,
                    owner_id: this.state.userInfo._id,
                    user_path: this.state.user_path,
                    unique_users: this.state.unique_users,
                    node_level: this.state.path.length,
                    category_input: values.subtitledd == "0" || values.subtitledd === "" ? false : true
                  },
                  addAsFirstChild: state.addAsFirstChild,
                }).treeData,
                crud: false,
                totalHLength: this.state.totalHLength + 1,
                createNewCat: false,
                getSubTitledd: values.subtitledd
              }
              ),
                function () {
                  this.saveHStructure()
                }
              )
            }
      }
      else {


      }
    }
  }

  handleInvalidSubmit = (values, errors) => {
    ////("errors", errors)
    this.setState({ errors, values });

  }

  addMainNode(type) {
    console.log(this.state.getSubTitle, 'getSubTitle', this.state.getSubTitledd)
    const getNodeKey = ({ treeIndex }) => treeIndex;
    console.log(getNodeKey, 'getNodeKey')
    this.setState({
      path: [],
      getNodeKey: getNodeKey,
      crud: true,
      crudStatus: 0,
      type,
      parent_id: [],
      showConnectedUsers: false,
      main_level: true,
      view_users: false,
      edit_node: false,
      getTitle: "",
      getSubTitle: "",
      getSubTitledd: "",
      catError: "",
      enableAddCode: true,
      getCode: "",
      enableCode: false

    })
  }

  onClose = () => {
    this.setState({ showDrawer: false })
  }


  validateNodeName = (e) => {
    // console.log(e.target.value, this.state.treeData)
    var validate_node = _.filter(this.state.treeData, { node_level: 0 })
    // console.log(validate_node, 'validate_node')
    const formattedNameToValidate = e.target.value.replace(/\s/g, '').toLowerCase();
    const isNameValid = validate_node.some((name) =>
      name.title.replace(/\s/g, '').toLowerCase() === formattedNameToValidate
    );
    // console.log(isNameValid, 'isNameValid')
    this.setState({ invalid_title: isNameValid })

  }

  ViewNodeUsers = (node, path, getNodeKey) => {

    var getNodeInfo = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
    })
    console.log(getNodeInfo.node.user_path, 'path', path, getNodeInfo.node)
    this.setState({
      node_users: getNodeInfo.node.unique_users == undefined ? [] : getNodeInfo.node.unique_users,
      view_users: true,
      title: getNodeInfo.node.title,
      path,
      getNodeKey,
      node,
      crud: false
    })

  }

  cancelDropDown = (mode) => {
    console.log(mode, 'mode')
    if (mode == "checkbox") {
      this.setState({ enableCode: !this.state.enableCode ? true : false })
    }
    else {
      this.setState({ crud: false, createNewCat: false, edit_node: false, node: null, getTitle: "", dataloaded: false }, () => { this.setState({ dataloaded: true }) })

    }
  }



  // handleTagClose = (event,user_data,cat_data, cat_idx,node_index,h_name) => {
  //   console.log(event, node_index, user_data, cat_data, cat_idx, user_data, this.state.node, this.state.path, this.state.getNodeKey, node_index)
  //   // var 
  //   var filter_cat_data = this.state.node_users[node_index].cat_type.filter(ele => ele !== cat_data)
  //   var filter_h_data = this.state.node_users[node_index].hirerachy_name.filter(ele => ele !== h_name)

  //   this.state.node_users[node_index].cat_type = filter_cat_data
  //   this.state.node_users[node_index].hirerachy_name = filter_h_data

  //   console.log(filter_cat_data,'filter_cat_data',this.state.node_users)
  //   if (filter_cat_data.length == 0) {
  //     var updated_unique_userdata = this.state.node.unique_users.filter((ele, index) => index !== node_index);
  //     console.log(updated_unique_userdata, 'updated_unique_userdata')
  //     this.state.node.unique_users = updated_unique_userdata

  //   }
  //   var find_user_path_idx = _.findIndex(this.state.node.user_path, { _id: user_data._id, cat_type: cat_data });

  //   console.log(find_user_path_idx,'find_user_path_idx',this.state.node)
  //   this.deleteNodedup(
  //     this.state.node.user_path[find_user_path_idx],
  //     this.state.node,
  //     this.state.path,
  //     this.state.getNodeKey,
  //     find_user_path_idx,
  //     this.state.node_users
  //   )

  // }

  // handleTagClose = (event, user_data, cat_data, cat_idx, node_index, h_name) => {
  //   console.log(event, node_index, user_data, cat_data, cat_idx, user_data, this.state.node, this.state.path, this.state.getNodeKey, node_index);

  //   const updatedNodeUsers = [...this.state.node_users];
  //   const updatedNode = { ...this.state.node };

  //   // Remove cat_data and h_name from node_users
  //   const filter_cat_data = updatedNodeUsers[node_index].cat_type.filter(ele => ele !== cat_data);
  //   const filter_h_data = updatedNodeUsers[node_index].hirerachy_name.filter(ele => ele !== h_name);

  //   updatedNodeUsers[node_index].cat_type = filter_cat_data;
  //   updatedNodeUsers[node_index].hirerachy_name = filter_h_data;

  //   console.log(filter_cat_data, 'filter_cat_data', updatedNodeUsers);

  //   if (filter_cat_data.length === 0) {
  //     // If no cat_type data exists for the node, remove the node from unique_users
  //     const updatedUniqueUsers = updatedNode.unique_users.filter((ele, index) => index !== node_index);
  //     console.log(updatedUniqueUsers, 'updated_unique_users');
  //     updatedNode.unique_users = updatedUniqueUsers;
  //   }

  //   const find_user_path_idx = _.findIndex(updatedNode.user_path, { _id: user_data._id, cat_type: cat_data });
  //   console.log(find_user_path_idx, 'find_user_path_idx', updatedNode);

  //   this.deleteNodedup(
  //     updatedNode.user_path[find_user_path_idx],
  //     updatedNode,
  //     this.state.path,
  //     this.state.getNodeKey,
  //     find_user_path_idx,
  //     updatedNodeUsers
  //   );

  //   // Update the state using setState
  //   this.setState({
  //     node_users: updatedNodeUsers,
  //     node: updatedNode,
  //   });
  // };



  handleTagClose = async (event, user_data, cat_data, cat_idx, node_index, h_name) => {
    console.log(event, node_index, user_data, cat_data, cat_idx, user_data, this.state.node, this.state.path, this.state.getNodeKey, node_index)
    // var 
    var node_users = [...this.state.node_users]
    var node = { ...this.state.node }
    var filter_cat_data = node_users[node_index].cat_type.filter(ele => ele !== cat_data)
    var filter_h_data = node_users[node_index].hirerachy_name.filter(ele => ele !== h_name)

    node_users[node_index].cat_type = filter_cat_data
    node_users[node_index].hirerachy_name = filter_h_data

    console.log(filter_cat_data, 'filter_cat_data', this.state.node_users)
    if (filter_cat_data.length == 0) {
      var updated_unique_userdata = node.unique_users.filter((ele, index) => index !== node_index);
      console.log(updated_unique_userdata, 'updated_unique_userdata')
      node.unique_users = updated_unique_userdata
    }
    var update_node_data = []

    node.user_path.map((data, idx1) => {
      if ((data.cat_type == cat_data) && (data._id == user_data._id)) {

      }
      else {
        update_node_data.push(data)
      }
    })
    var find_user_path_idx = _.findIndex(node.user_path, { _id: user_data._id, cat_type: cat_data });

    console.log(node, find_user_path_idx, 'find_user_path_idx', this.state.node)
    var flat_data = [...this.state.flat_data]
    var flat_data_idx = _.findIndex(flat_data, function (item) { return item.id == node.id })
    // var flat_data_idx = _.findIndex(flat_data,{title : this.state.node.title , subtitle : this.state.node.subtitle , id:this.state.node.id})[0]
    console.log(flat_data_idx, 'updateFlatdata', flat_data)
    if (flat_data_idx !== -1) {
      flat_data[flat_data_idx]["unique_users"] = node.unique_users
      flat_data[flat_data_idx]["user_path"] = update_node_data
      var treeData = await this.convertFlatDataToTreeData(flat_data, true)
      console.log(treeData, 'treeData')
    }

    this.setState({
      node,
      node_users,
      flat_data
    }, () => {
      console.log(this.state.node, node, node_users, this.state.node_users, this.state.treeData, this.state.flat_data)
      this.deleteNodedup(
        this.state.node.user_path[find_user_path_idx],
        this.state.node,
        this.state.path,
        this.state.getNodeKey,
        find_user_path_idx,
        this.state.node_users
      )
    })

  }


  toggle = () => {
    this.setState(prevState => ({
      crud: !prevState.crud,
      createNewCat: !prevState.createNewCat,
      edit_node: !prevState.edit_node,
      node: null,
      getTitle: ""

    }))

  }

  DeleteAllUsers = (node, path) => {
    node["unique_users"] = []
    node["user_path"] = []
    console.log(node, path)

    this.setState({
      node_users: node.unique_users,
      dataloaded: false
    }, () => { this.setState({ dataloaded: true }); this.saveHStructure() })

  }


  saveAllnode = async (event, values, error) => {
    console.log("conditionally func", values, this.state.node)
    this.upDateCatName(this.state.flat_data, values)
    var treeData = await this.convertFlatDataToTreeData(this.state.flat_data, true)
    console.log(treeData, 'tree', this.state.flat_data)
    this.saveHStructure()
    if (values.title !== this.state.title) {
      this.crudNode(event, values, error)
    }
  }




  render() {
    if (this.state.dataloaded) {
      const getNodeKey = ({ treeIndex }) => treeIndex;
      const optionGroup = this.listOutCategory()
      return (
        <React.Fragment>

          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">

                <div className="w-100 me-md-2 ">
                  <Card>
                    <CardBody className="py-2">

                      <div style={{ display: 'flex', flexDirection: 'row', }}>
                        <div className="m-2">
                          <Button type="button"
                            color="primary" size="sm"
                            outline onClick={() => this.addMainNode(0)}>
                            Add Main Level  {" "}<i className="mdi mdi-file-tree font-size-18"></i>
                          </Button>
                        </div>

                      </div>
                      <hr className="m-1" />
                      {
                        this.state.treeData.length != 0 ?
                          <div className="mt-4 mb-4" style={{ minHeight: "70vh", height: this.state.Nodecount * 65, }}>
                            <SortableTree
                              treeData={this.state.treeData}
                              onChange={(treeData, node) => {
                                this.setState({ treeData, crud: false, }, () => {
                                  this.setState({
                                    Nodecount: getVisibleNodeCount({ treeData: this.state.treeData }),
                                  })
                                })
                              }}
                              canDrop={(object) =>
                                object.nextParent !== undefined && object.nextParent !== null && object.nextParent.type == 2
                                  ? false
                                  : true
                              }
                              onMoveNode={object => this.dndNode(object, getNodeKey)}
                              generateNodeProps={({ node, path }) => ({
                                listIndex: 0,
                                lowerSiblingCounts: [],
                                rowdirection: "rtl",
                                canDrop: node.type == 2 ? false : true,
                                className: "icon-drag",
                                icon: (<i className="mdi mdi-folder-multiple-plus font-size-18" style={{ margin: 5 }} ></i>),
                                style: {
                                  display: 'flex',
                                  border: '1px solid #c3cacd',
                                },
                                title: (
                                  <div>
                                    {
                                      node.type == 1 ?
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                          <i className="mdi mdi-connection font-size-18 text-warning"
                                          ></i>
                                          <div style={{ height: '100%', width: '100%', marginLeft: 10, color: node.type == 1 ? "#f1b44c" : node.type == 2 ? "#343a40 " : "#556ee6" }} >
                                            {node.title}
                                          </div>
                                        </div>
                                        : node.type == 2 ?
                                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <i className="mdi mdi-account-circle font-size-20"
                                            ></i>
                                            <div style={{ height: '100%', width: '100%', marginLeft: 10, color: node.type == 1 ? "#f1b44c" : node.type == 2 ? "#343a40 " : "#556ee6" }} >
                                              {node.title}
                                            </div>
                                          </div> :
                                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ height: '100%', width: '100%', color: node.type == 1 ? "#f1b44c" : node.type == 2 ? "#343a40 " : "#556ee6" }} >
                                              {node.title}
                                            </div></div>
                                    }
                                  </div>

                                ),
                                subtitle: (
                                  <div style={{ height: '100%', width: '100%', marginTop: 4, marginLeft: node.type == 1 || node.type == 2 ? 28 : 0 }} >
                                    {node.cat_type === undefined ? node.node_positon !== node.subtitle ? node.subtitle + " (" + node.node_positon + ")" : node.subtitle : node.cat_type == "1" ? "Auditer" : node.cat_type == "2" ? "Reviewer" : node.cat_type == "3" ? "External Auditer" : "NA"}
                                  </div>),
                                buttons: [
                                  <div key={""} style={{ width: 40 }}></div>,
                                  <div key={""}>
                                    <UncontrolledDropdown
                                      direction="end"
                                    >
                                      <DropdownToggle
                                        className="card-drop"
                                        tag="a"
                                      >
                                        {
                                          node.type == 1 || node.type == 2 ? null :
                                            <Link className="text-primary" to="#">
                                              <i className="mdi mdi-layers-plus font-size-18"
                                                style={{ margin: 5 }}
                                                title={"Add Node"}
                                                onClick={
                                                  () => this.addNode(node, path, getNodeKey, 0)
                                                }
                                              ></i>
                                            </Link>}
                                      </DropdownToggle>
                                      {
                                        this.state.crud ?
                                          <CrudNode
                                            title={this.state.title}
                                            subtitle={this.state.subtitle}
                                            crudNode={this.crudNode}
                                            handleInvalidSubmit={this.handleInvalidSubmit}
                                            getTitle={this.state.getTitle}
                                            edit_node={this.state.edit_node}
                                            node={this.state.node}
                                            main_level={this.state.main_level}
                                            enableAddCode={this.state.enableAddCode}
                                            enableCode={this.state.enableCode}
                                            getCode={this.state.getCode}
                                            codeErrorEnabled={this.state.codeErrorEnabled}
                                            catError={this.state.catError}
                                            crudStatus={this.state.crudStatus}
                                            Oncancel={this.cancelDropDown}
                                            optionGroup={optionGroup}
                                            selectCat={this.selectCat}
                                            createNewCat={this.state.createNewCat}
                                            validate_node={this.state.validate_node}
                                            flatData={this.state.flat_data}
                                          />
                                          :
                                          null
                                      }
                                    </UncontrolledDropdown>
                                  </div>,
                                  <div key={""}>
                                    <UncontrolledDropdown
                                      direction="end"

                                    >
                                      <DropdownToggle
                                        className="card-drop"
                                        tag="a"
                                      >
                                        <Link className="text-success" to="#">
                                          <i className="mdi mdi-pencil font-size-18"
                                            style={{ margin: 5 }}
                                            title={"Edit Node"}
                                            onClick={
                                              () => this.editNode(path, getNodeKey)
                                            }
                                          ></i>
                                        </Link>
                                      </DropdownToggle>
                                      {
                                        this.state.crud ?
                                          <CrudNode
                                            title={this.state.title}
                                            subtitle={this.state.subtitle}
                                            crudNode={this.crudNode}
                                            handleInvalidSubmit={this.handleInvalidSubmit}
                                            getTitle={this.state.getTitle}
                                            edit_node={this.state.edit_node}
                                            node={this.state.node}
                                            main_level={this.state.main_level}
                                            enableAddCode={this.state.enableAddCode}
                                            enableCode={this.state.enableCode}
                                            getCode={this.state.getCode}
                                            codeErrorEnabled={this.state.codeErrorEnabled}
                                            catError={this.state.catError}
                                            crudStatus={this.state.crudStatus}
                                            Oncancel={this.cancelDropDown}
                                            editCat={this.editCat}
                                            saveAllnode={this.saveAllnode}
                                            edit_cat_name={this.state.edit_cat_name}
                                            optionGroup={optionGroup}
                                            flatData={this.state.flat_data}
                                            createNewCat={this.state.createNewCat}
                                          />
                                          :
                                          null
                                      }

                                    </UncontrolledDropdown>
                                  </div>,
                                  <Link key={""} className="text-danger" to="#"><i className="mdi mdi-delete font-size-18"
                                    style={{ margin: 5 }}
                                    title={"Delete Node"}
                                    onClick={() => this.deleteNode(node, path, getNodeKey)}
                                  ></i></Link>,
                                  <div key={""}>
                                    <UncontrolledDropdown >
                                      <DropdownToggle
                                        // href="#"
                                        className="card-drop"
                                        tag="a"
                                        onClick={() => { this.ViewNodeUsers(node, path, getNodeKey, 1) }}
                                      >
                                        <Space size="middle">
                                          <Badge count={node.unique_users !== undefined ? node.unique_users.length > 0 ? node.unique_users.length : 0 : 0} color="#556EE6">
                                            <i className="bx bx-user-pin font-size-20" title={"Users"} style={{ marginTop: '4px', marginLeft: '5px' }}
                                            ></i>
                                          </Badge>
                                        </Space>
                                        {/* } */}

                                      </DropdownToggle>
                                      <DropdownMenu className="ms-4 " style={{ minWidth: "500px", zIndex: 2, padding: 10, marginLeft: '13px', marginTop: '-15px' }}>
                                        {this.state.view_users ? (
                                          <div className="mt-2">
                                            <div className="mt-2">
                                              Users of&nbsp;
                                              <span className="font-size-13 text-primary mt-2">
                                                {this.state.title}
                                              </span>
                                            </div>
                                            <div className="row">
                                              <div className="col-12">
                                                Total Users:&nbsp;
                                                <span className="font-size-13 text-danger mt-2">
                                                  {this.state.node_users.length == undefined ? 0 : this.state.node_users.length}
                                                </span>
                                                <div className="mt-2">
                                                  <button className="btn btn-primary btn-sm" onClick={() => this.navigateTo(node, path, getNodeKey, 2, null)}>Add User</button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 text-end">
                                              <button className="btn btn-danger btn-sm" onClick={() => this.DeleteAllUsers(node, path, getNodeKey, 2, null)}>Delete all Users</button>
                                            </div>

                                            <div className="table-responsive mt-3" style={{ maxHeight: "300px" }}>
                                              <table className="table custom-table-style">
                                                <thead>
                                                  <tr>
                                                    <th>S.No</th>
                                                    <th>Name</th>
                                                    <th>Category</th>
                                                    <th>Action</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {this.state.node_users.map((data, idx) => {

                                                    return (
                                                      data.cat_type.length > 0 ?
                                                        <tr key={idx}>
                                                          <td>{idx + 1}</td>
                                                          <td>{data.name}</td>
                                                          <td>
                                                            {
                                                              data.cat_type.map((ele, index) => (
                                                                <Space key={index} size={[0, 'small']} wrap>
                                                                  <Tag bordered={false} closable onClose={(e) => this.handleTagClose(e, data, ele, index, idx, data.hirerachy_name[index])}>
                                                                    {ele == "1" ? "Auditor" : ele == "2" ? "Reviewer" : data.hirerachy_name == undefined ? "" : data.hirerachy_name[index]}
                                                                  </Tag>
                                                                </Space>
                                                              ))
                                                            }
                                                          </td>
                                                          <td>
                                                            <Link className="text-danger" to="#">
                                                              <i
                                                                className="mdi mdi-delete font-size-18"
                                                                style={{ margin: 5 }}
                                                                onClick={() =>
                                                                  this.deleteNodedup(
                                                                    data,
                                                                    this.state.node,
                                                                    this.state.path,
                                                                    this.state.getNodeKey,
                                                                    idx,
                                                                    "delete_all"
                                                                  )
                                                                }
                                                              ></i>
                                                            </Link>
                                                          </td>
                                                        </tr>
                                                        :
                                                        null
                                                    );
                                                  })}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        ) : null}

                                      </DropdownMenu>

                                    </UncontrolledDropdown>
                                  </div>

                                ]
                              })}
                            />
                          </div> :
                          <div className="mt-4" style={{ height: 700 }} >
                            <div>
                              <div className="p-2 mt-4">
                                <h4>Hirerachy Level</h4>
                                <p className="text-muted">
                                  No Records found, <br />
                                  Add Main Level node by clicking Main Level Button .
                                </p>
                              </div>
                            </div>
                          </div>
                      }



                    </CardBody>
                  </Card>
                </div>


                <Modal
                  isOpen={this.state.crud && this.state.main_level && (this.state.type == 0 || this.state.type == 1)}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggle} tag="h5">
                    {"Add Main Level"}
                  </ModalHeader>
                  <ModalBody>
                    <div className="d-flex flex-column h-100">
                      {
                        this.state.crud && this.state.main_level && (this.state.type == 0 || this.state.type == 1) ? <div>
                          <div className="">
                            <div className="mb-2">Add Main Level to {this.state.hInfo.hname}</div>
                          </div>
                          <div className="mt-1">
                            <AvForm className="form-horizontal" onValidSubmit={this.crudNode} onInvalidSubmit={this.handleInvalidSubmit}>
                              <div className="mb-3">
                                <AvField
                                  name="title"
                                  label="Name"
                                  type="text"
                                  value={this.state.getTitle}
                                  onChange={(e) => { this.validateNodeName(e) }}
                                  required
                                  placeholder="Enter Name"
                                />
                              </div>
                              {
                                this.state.invalid_title &&
                                <div style={{ fontSize: 'smaller' }} className='text-danger'>Title name should not same</div>
                              }
                              <div className="mb-3">

                                <Label className="" htmlFor="autoSizingSelect">Category</Label>
                                {
                                  this.state.edit_node ?
                                    <AvInput
                                      name="subtitledd"
                                      label="Name"
                                      type="text"
                                      id="cat"
                                      placeholder="Enter Category">

                                    </AvInput>
                                    :
                                    <AvInput
                                      type="select"
                                      name="subtitledd"
                                      label="Name"
                                      value={this.state.getSubTitledd}
                                      className="form-select"
                                      id="cat"
                                      ref={this.optionRef}
                                      onChange={(e) => this.selectCat(e)}>
                                      <option value="0" defaultValue>Choose...</option>
                                      <option value="1" selected >Create New</option>
                                      {
                                        optionGroup.map((data, idx) => {
                                          return (
                                            <option value={data} selected key={idx}>{data}</option>
                                          )
                                        })
                                      }
                                    </AvInput>
                                }
                              </div>
                              {
                                this.state.createNewCat ?
                                  <div className="mb-3">
                                    <AvField
                                      name="subtitle"
                                      type="text"
                                      value={this.state.getSubTitle}
                                      placeholder="Enter New Category"
                                    />
                                  </div> : null
                              }
                              {this.state.catErrorEnabled ? <Label className="text-danger" style={{ margin: '0 0 7px 0' }} htmlFor="autoSizingSelect">{this.state.catError}</Label> : null}
                              {
                                this.state.enableAddCode ?
                                 <div className="form-check">
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="invalidCheck"
                                    onChange={() => this.setState({ enableCode: !this.state.enableCode ? true : false })}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="invalidCheck"
                                  >{" "}
                                    Add Code
                                  </Label>
                                </div>
                                 : null
                              }

                              {
                                this.state.enableCode ?
                                  <div className="mb-3">
                                    <AvField
                                      name="code"
                                      label="Code"
                                      type="text"
                                      value={this.state.getCode}
                                      validate={{
                                        required: { value: true, errorMessage: this.state.codeError },
                                      }}
                                      placeholder="Enter Code"
                                    />
                                    {this.state.codeErrorEnabled ? <Label className="text-danger" style={{ margin: '5px 0 7px 0' }} htmlFor="autoSizingSelect">{this.state.codeError}</Label> : null}

                                  </div> : null
                              }



                              <div className="mt-4 d-flex flex-row">
                                <button
                                  className="btn btn-success btn-block m-1"
                                  type="submit"
                                  disabled={this.state.invalid_title}
                                >
                                  {this.state.crudStatus == 1 ? "Add" : this.state.crudStatus == 2 ? "Update" : "Add Main Level"}
                                </button>
                                {
                                  this.state.createNewCat &&
                                  <button className="btn btn-primary btn-block m-1" type="submit" onClick={(e) => { this.selectCat(e, true) }}>
                                    Remove Category Input
                                  </button>
                                }
                                <button
                                  className="btn btn-danger btn-block m-1"
                                  type="submit"
                                  onClick={() => { this.setState({ crud: false, createNewCat: false, edit_node: false, node: null, getTitle: "" }) }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </AvForm>

                          </div>
                        </div> :
                          null

                      }
                      {
                        this.state.showConnectedUsers ? <div>
                          <div>
                            <div className="text-primary " style={{ fontSize: 25 }}>{this.state.nodeUsers.node.title}</div>
                            <div className="font-size-15 my-1 text-primary">{this.state.nodeUsers.node.type != 2 ? "Mapped Users" : "Report to"}</div>
                            {
                              this.state.nodeUsers.node.user_path.length > 0 ?
                                this.state.nodeUsers.node.user_path.map((data, idx) => {
                                  //("data", data)
                                  return (<div className="my-3 border-bottom border-1 py-1" key={idx}>
                                    <div className="font-size-15">{data.title}</div>
                                    <div className="font-size-12">{data.subtitle}</div>
                                  </div>)
                                }) : <div>Not Avaialble</div>
                            }
                          </div>
                        </div> : null
                      }
                    </div>

                  </ModalBody>
                </Modal>




              </div>
            </div>
          </div>
          <footer 
          style={{ 
            display: 'flex', 
            alignItems:"center", 
            height: 70, 
            background: "#fff", 
            width: "100%", 
            position: "fixed", 
            bottom: 0, 
            zIndex: 9998,
            borderTop:"1px solid #dedede"
            }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems:"center", justifyContent:"center", paddingLeft:20 }}>
              {
                this.state.flat_data.length > 0 &&
                <>
                  <div>
                    <button
                      className="btn btn-danger btn-block m-1"
                      type="submit"
                      onClick={() => this.saveHStructure()}
                    >
                      {this.state.saving ? "Saving" : "Save"}
                    </button>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <button
                      className="btn btn-success btn-block m-1"
                      type="submit"
                      onClick={() => this.publishHStructure()}
                    >
                      Publish
                    </button>
                  </div>
                </>
              }


              <div>
                <UncontrolledAlert
                  color="success"
                  className="alert-dismissible fade show"
                  role="alert"
                  isOpen={this.state.successmsgshow}
                >
                  <i className="mdi mdi-check-all me-2"></i>{this.state.alertMessage}
                </UncontrolledAlert>
              </div>
            </div>
          </footer>
        </React.Fragment>
      )
    }
    else {
      return null
    }
  }
}


