import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { sortFunc, sortArea, sortDescending, imgDownloadSvg, imgDownloadPng } from '../../../../store/reportd3/reportslice';
// import { sortFunc, sortarea, sortDescending } from '../../../../store/report/Slice/SortingSlice';
// import { handleDownload_bar, imgdownloadsvg, imgdownloadpng } from '../../../../store/report/Slice/DownloadSlice';

const D3AreaChart = (props) => {
    const dispatch = useDispatch();
    const svgRef = useRef()
    var containerWidth = props.containerWidth
    var containerHeight = props.containerHeight
    var chart_data = props.chart_data
    var chart_color = props.chart_color
    var i = props.id
    var label_name = props.label
    var mouseovered = props.mouseovered
    var mouseovered_type = props.mouseovered_type
    var enable_table = props.show_table
    var show_Grid = props.show_Grid
    var temp_containerWidth = props.temp_containerWidth
    var temp_containerHeight = props.temp_containerHeight
    var fullScreen_enabled = props.show_Full_Screen_toggle
    var YLabel = props.YLabel
    var show_Square = props.show_Square
    var svgHeight = props.chart_height
    var show_bar_values = props.show_bar_values
    var text_color_arr = props.text_color
  var barLabel = props.label;

    const [chartData, setchartData] = useState(chart_data);
    const [textColorBar, settextColorBar] = useState([])
    const [showOptions, setShowOptions] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [mouseoverEnabled, setMouseoverEnabled] = useState(mouseovered)
    const [enabledTable, setenabledTable] = useState(enable_table)
    const [showGridEnabled, setShowGridEnabled] = useState(show_Grid)
    const [sortShowOptions, setSortShowOptions] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [arrValues, setarrValues] = useState([])
    const [sortOrder, setSortOrder] = useState('')
    const SortArr = useSelector(state => state.reportSliceReducer);
    const [sortdata, setsortdata] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [scrollDelta, setScrollDelta] = useState(0);

    useEffect(() => {
        if (chart_data !== undefined && chart_data.length > 0) {
            setchartData(chart_data)
            settextColorBar(chart_color)
            setMouseoverEnabled(mouseovered)
            setenabledTable(enable_table)
            setShowGridEnabled(show_Grid)
        }
    }, [chart_data, chart_color, mouseovered, mouseovered_type, enable_table, show_Grid, temp_containerWidth, fullScreen_enabled, temp_containerHeight, svgHeight])
    if (props.chart_data.length !== 0) {
        var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
        var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
    }
    const svg = d3.select(`#my_dataviz${i}`);
    useEffect(() => {
        if (sortOrder === 'asc') {
            handleSortAscending()
        }
        else if (sortOrder === 'desc') {
            handleSortDescending()
        }
        else {
            handleSortDefault()
        }
    }, [chart_data])

    useEffect(() => {
        var mod_data;
        if (datakeys !== undefined) {
            var chart_id = i;
            var dataSeries1;
            console.log('sortArr', SortArr)
            if (SortArr[chart_id] && SortArr[chart_id].linesorted) {
                mod_data = SortArr[chart_id].linesorted;
            } else {
                if (chartData === undefined || chartData.length === 0) {
                    mod_data = [
                        { year: '10006', y1: 316, y2: 20, y3: 15 },
                        { year: '10008', y1: 405, y2: 25, y3: 40 },
                        { year: '10015', y1: 203, y2: 15, y3: 100 },
                    ];
                } else {
                    mod_data = chart_data;
                }
            }
            if (Array.isArray(mod_data)) {
                dataSeries1 = [mod_data];
            }
            const keys = Object.keys(dataSeries1[0][0]).filter((key) => key !== 'year');
            const stack = d3.stack()
                .keys(keys)
                .order(d3.stackOrderNone)
                .offset(d3.stackOffsetNone);
            const stackedData = stack(dataSeries1[0]);
            const margin = { top: 70, right: 30, bottom: 80, left: 40 };
            if (svgHeight !== undefined && svgHeight !== '') {
                containerHeight = containerHeight - 200
            }
            var width
            var height
            if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
                width = temp_containerWidth - margin.left - margin.right;
                height = temp_containerHeight - margin.top - margin.bottom - (enabledTable ? 200 : 0)
                containerWidth = width
            }
            else {
                width = containerWidth - margin.left - margin.right;
                height = containerHeight - margin.top - margin.bottom;
            }
            const svg1 = d3.selectAll(`#my_dataviz${i}`);
            svg1.selectAll('*').remove();
            const drag = d3.drag()
                .on("drag", dragged)

            const svg = d3.select(`#my_dataviz${i}`)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .data(dataSeries1[0])
                .attr("transform", `translate(${margin.left},${margin.top})`)

            d3.select(`#my_dataviz${i}`)
                .call(drag);



                // function dragged(event, d) {
                //     const isChartAreaPath = !d3.select('.area').empty(); // Check if .area element exists
                //     const isXAxis = !d3.select('.x-axis').empty(); // Check if .x-axis element exists
                
                //     if (!isChartAreaPath && !isXAxis) {
                //         return; // Exit early if neither element is found
                //     }
                
                //     const deltaX = event.dx; // Get the delta change in X direction
                //     let newX = 0;
                
                //     // Determine the current transform value for newX
                //     if (isChartAreaPath) {
                //         newX = parseFloat(svg.attr("transform").split("(")[1].split(",")[0]) + deltaX;
                //     } else if (isXAxis) {
                //         newX = parseFloat(xScale.attr("transform").split("(")[1].split(",")[0]) + deltaX;
                //     }
                
                //     // Define the boundaries
                //     const minDragX = -522; // Minimum allowable X position
                //     const originalX = 0; // Original X position
                
                //     // Ensure newX stays within the defined boundaries
                //     if (newX < minDragX) {
                //         newX = minDragX;
                //     }
                
                //     // Apply the new transform based on the element type
                //     if (isChartAreaPath) {
                //         svg.attr("transform", `translate(${newX},${margin.top})`);
                //     } else if (isXAxis) {
                //         xScale.attr("transform", `translate(${newX},0)`);
                //     }
                // }
                

            function dragged(event, d) {
                const isChartAreaPath = d3.select('.area')
                const isXAxis = d3.select('.x-axis');
                if (isChartAreaPath || isXAxis) {
                    const deltaX = event.dx;
                    let newX = 0;
                    if (isChartAreaPath) {
                        newX = parseFloat(svg.attr("transform").split("(")[1].split(",")[0]) + deltaX;
                    } else if (isXAxis) {
                        newX = parseFloat(svg.attr("transform").split("(")[1].split(",")[0]) + deltaX;
                    }
                    console.log('newX', newX)
                    if (isChartAreaPath) {
                        if (newX >= 40) {
                            
                            svg.attr("transform", `translate(${margin.left},${margin.top})`);
                        }
                        // else if (newX) {
                        //     console.log('147', 147)
                            
                        // }
                        else if (newX < 0) {
                            svg.attr("transform", `translate(${newX},${margin.top})`);
                        }
                        else {
                            svg.attr("transform", `translate(${newX},${margin.top})`);
                        }
                    } else if (isXAxis) {
                        xScale.attr("transform", `translate(${newX},0)`);
                    }
                }
            }

            // function dragged(event, d) {
            //     const isChartAreaPath = !d3.select('.area').empty();
            //     const isXAxis = !d3.select('.x-axis').empty();
                
            //     if (isChartAreaPath || isXAxis) {
            //         const deltaX = event.dx;
            //         const currentTransform = svg.attr("transform");
            
            //         // Parse current transform values
            //         let currentX = 0;
            //         if (currentTransform) {
            //             const transformValues = currentTransform.split("(")[1].split(")")[0].split(",");
            //             currentX = parseFloat(transformValues[0]);
            //         }
            
            //         let newX = currentX + deltaX;
            
            //         // Define bounds for movement
            //         const minX = 0; // Minimum x-value (left bound)
            //         const maxX = 40; // Maximum x-value (right bound)
            
            //         // Ensure newX stays within bounds
            //         if (newX > maxX) newX = maxX;
            //         if (newX < minX) newX = minX;
            
            //         if (isChartAreaPath) {
            //             svg.attr("transform", `translate(${newX},${margin.top})`);
            //         } else if (isXAxis) {
            //             xScale.attr("transform", `translate(${newX},0)`);
            //         }
            //     }
            // }
            
            const color = d3.scaleOrdinal()
                .domain(datakeys)
                .range(chart_color !== undefined && chart_color.length > 0
                    ? chart_color
                    : d3.quantize(d3.interpolateRainbow, datakeys.length + 2));

            const text_color = d3.scaleOrdinal()
                .domain(datakeys)
                .range(text_color_arr !== undefined && text_color_arr.length > 0
                    ? text_color_arr
                    : d3.quantize(d3.interpolateRainbow, datakeys.length + 2));

            const xScale = d3.scalePoint()
                .domain(dataSeries1[0].map(d => d.year))
                .range([0, width])
            const yScale = d3.scaleLinear()
                .domain([0, d3.max(stackedData[stackedData.length - 1], d => d[1])])
                .range([height, 0]);
            yScale.domain([0, d3.max(stackedData[stackedData.length - 1], d => d[1])]);
            datakeys.forEach((key, index) => {
                const gradient = svg.append("defs")
                    .append("linearGradient")
                    .attr("id", `gradient-${key}`)
                    .attr("class", "area overlay")
                    .attr("x1", "0%")
                    .attr("x2", "0%")
                    .attr("y1", "0%")
                    .attr("y2", "100%");
                gradient.append("stop")
                    .attr("offset", "80%")
                    .attr("stop-color", (chart_color?.length > 0 && chart_color[index] !== undefined) ? chart_color[index] : color(key));
                gradient.append("stop")
                    .attr("offset", "100%")
                    .attr("stop-color", (chart_color?.length > 0 && chart_color[index] !== undefined) ? chart_color[index] : color[index])
                    .attr("stop-opacity", 0);
            });

            const hoverLine = svg.append("line")
                .attr("class", "hover-line")
                .style("stroke", "black")
                .style("stroke-width", 2)
                .style("stroke-dasharray", "3,3")
                .attr("x1", 0)
                .attr("x2", 0)
                .attr("y1", 0)
                .attr("y2", height);
            const g = svg.append('g')
            svg.on('mouseover', Multimouseverd)
            svg.on('mousemove', Multimouseverd)
            svg.on("mouseout", function (event, d) {
                const tooltip = d3.select(`#tooltip${i}`);
                tooltip.transition()
                    .duration(50)
                    .style("opacity", 0);
                hoverLine.transition()
                    .duration(50)
                    .style("opacity", 0);
                setShowOptions(false)
            })

            function Multimouseverd(event, d) {
                if (mouseoverEnabled) {
                    if (mouseovered_type) {
                        const mouseX = d3.pointer(event)[0];
                        const mouseY = d3.pointer(event)[1];
                        hoverLine.attr("x1", mouseX).attr("x2", mouseX);
                        let isMouseOverNode = false;
                        areaGroups.selectAll('circle, rect').each(function (nodeData) {
                            const dataPoint = nodeData;
                            if (dataPoint !== undefined) {
                                const node = d3.select(this);
                                const nodeX = parseFloat(node.attr('cx') || node.attr('x'));
                                const nodeY = parseFloat(node.attr('cy') || node.attr('y'));
                                if (Math.abs(mouseX - nodeX) < 8 && Math.abs(mouseY - nodeY) < 8) {
                                    isMouseOverNode = true;
                                }
                            }
                        });
                        const tooltip = d3.select(`#tooltip${i}`);
                        if (isMouseOverNode) {
                            tooltip
                                // .transition()
                                //   .duration(200)
                                .style("opacity", 0.9);
                            let tooltipLeft, tooltipTop;
                            if (mouseX < containerWidth / 2) {
                                tooltipLeft = mouseX + 70;
                            } else {
                                tooltipLeft = mouseX - 100;
                            }
                            if (mouseY < containerHeight / 2) {
                                tooltipTop = mouseY + 80;
                            } else {
                                tooltipTop = mouseY - 20;
                            }
                        } else {
                            tooltip
                                .transition()
                                .duration(50)
                                .style("opacity", 0);
                        }
                        hoverLine.transition()
                            .duration(0)
                            .style("opacity", isMouseOverNode ? 9 : 0);
                    }
                }
            }
            const area = d3.area()
                .x(d => xScale(d.data.year) + xScale.bandwidth() / 2)
                .y0(d => yScale(d[0]))
                .y1(d => yScale(d[1]))
                .curve(d3.curveCardinal)
            const areaGroups = svg.selectAll(`g.area-group${i}`)
                .data(stackedData)
                .enter()
                .append("g")
                .attr("class", `area-group${i}`)
            areaGroups
                .append("path")
                .attr("class", `area${i}`)
                .attr("fill", (d, i) => `url(#gradient-${d.key})`)
                .attr("d", area)
            areaGroups.each(function () {
                this.parentNode.insertBefore(this, this.parentNode.firstChild);
            });
            var newContainerWidth = containerWidth + scrollDelta;
            var newXscale = xScale.range([0, newContainerWidth - margin.left - margin.right]);
            if (newContainerWidth > containerWidth) {
                xScale.range([0, newContainerWidth - margin.left - margin.right]);
                g.append("g").attr('width', newContainerWidth);
            } else {
                xScale.range([0, containerWidth - margin.left - margin.right]);
                g.append("g").attr('width', containerWidth);
            }
            d3.selectAll(`.area${i}`).remove()
            areaGroups
                .append("path")
                .attr("class", `area${i}`)
                .attr("fill", (d, i) => `url(#gradient-${d.key})`)
                .attr("d", area)
            if (newContainerWidth >= containerWidth) {
                d3.select(`#my_dataviz${i}`).on('wheel', handleScroll);
            }
            else {
                setScrollDelta(0)
            }
            if (!show_Square) {
                var rectangles = areaGroups.selectAll('circle')
                    .data(d => {
                        return ((d.key != '_id') ? d : '');
                    })
                    .enter().append('rect')
                    .attr('x', (d) => xScale(d.data.year) + xScale.bandwidth() / 2 - 5)
                    .attr('y', (d) => yScale(d[1]) - 5)
                    .attr('width', 12)
                    .attr('height', 12)
                    .attr('fill', 'white')
                    .attr('stroke', 'black')
                    .style('pointer-events', 'all')
                    .on('mouseover', handleMouseOver)
                    .on('mousemove', handleMouseOver)
                    .on('mouseout', handleMouseOut)
                    .style('cursor', 'pointer')
                    .raise()
                rectangles.each(function () {
                    this.parentNode.appendChild(this);
                });
            } else {
                areaGroups.selectAll('circle')
                    .data(d => {
                        return ((d.key != '_id') ? d : '');
                    })
                    .enter().append('circle')
                    .attr('cx', (d) => xScale(d.data.year) + xScale.bandwidth() / 2)
                    .attr('cy', (d) => yScale(d[1]))
                    .attr('r', 8)
                    .attr('fill', 'white')
                    .attr('stroke', 'black')
                    .attr('stroke-width', 2)
                    .style('pointer-events', 'all')
                    .on('mouseover', handleMouseOver)
                    .on('mousemove', handleMouseOver)
                    .on('mouseout', handleMouseOut)
                    .style('cursor', 'pointer')
                    .raise()
            }

            const renderText = (areaGroups, stackedData, text_color) => {
                areaGroups.selectAll('text').remove();
                stackedData.forEach((seriesData, seriesIndex) => {
                    areaGroups
                        .selectAll(`.text-${seriesIndex}`)
                        .data(seriesData)
                        .enter()
                        .append('text')
                        .attr('class', `text-${seriesIndex}`)
                        .text(d => {
                            const textValue = d[1] - d[0];
                            return isNaN(textValue) ? '' : textValue;
                        })
                        .attr('x', d => {
                            const xPos = xScale(d.data.year);
                            return isNaN(xPos) ? 0 : xPos + xScale.bandwidth() / 2;
                        })
                        .attr('y', d => {
                            const yPos = yScale(d[1]);
                            return isNaN(yPos) ? 0 : yPos - 20;
                        })
                        .attr('text-anchor', 'middle')
                        .attr('fill', text_color(seriesData.key))
                        .attr('dominant-baseline', 'middle');
                });
            };

            if (show_bar_values) {
                renderText(areaGroups, stackedData, text_color);
            }
            const axisLabels = svg.append("g")
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(xScale))
                .selectAll('text')
                .style("text-anchor", "middle")
                .attr("font-size", "14px")
                .attr('fill', 'black')
                .style("text-transform", "capitalize")
                .style("font-weight", (d, i) => i % 2 === 0 ? "bold" : "normal")
                .style('cursor', 'pointer')
            let rotationAngle = 0;
            axisLabels.each(function (_, i) {
                const label = this;
                d3.select(label).on('click', function () {
                    const currentRotation = rotationAngle === 0 ? -45 : 0;
                    const currentAnchor = rotationAngle === 0 ? 'end' : "middle";
                    axisLabels.attr('transform', `rotate(${currentRotation})`)
                        .style("text-anchor", currentAnchor)
                    rotationAngle = currentRotation;
                });
            });

            const yAxisContainer = d3.select(`#my_dataviz${i}`)
                .attr('class', 'y-axis')
                .append("div")
                .style("position", "absolute")
                .style("top", `${0}px`)
                .style("left", "0")
                .style("width", `${margin.left}px`)
                .style("height", `${containerHeight - 100}px`);
            const yAxis = yAxisContainer.append("svg")
                .attr("width", '100%')
                .attr("height", fullScreen_enabled ? temp_containerHeight : containerHeight)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)
                .call(d3.axisLeft(yScale).ticks(fullScreen_enabled ? 20 : containerHeight / 50))
                .selectAll('.domain, text')
                .attr('stroke', fullScreen_enabled ? 'black' : 'black')
                .style("font-size", '10px')
                .call(g => g.select(".domain").remove())

            yAxis.select(".domain")
                .attr("transform", `translate(${-60}, 0)`);
            yAxis.select(".domain")
                .style("stroke", 'green');
            yAxis.select(".domain")
                .style("stroke-width", 2);
            yAxis.selectAll("text")
                .attr('class', 'yAxis-text')
                .attr("x", -10)
                .attr('fill', 'black')
                .attr("dx", "-3.99em")
                .style('font-weight', 'bold')
                .style("font-size", '12px');
            yAxis.selectAll("line")
                .attr("transform", `translate(${-50}, 0)`)
                .attr('stroke', 'black')
                .attr("dx", "-2em");
            svg
                .attr("width", 2000)
            if (show_Grid) {
                svg.insert('g', ':first-child')
                    .attr('class', 'grid')
                    .attr('transform', `translate(0, ${height})`)
                    .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'green');
            }

            if (show_Grid) {
                svg.insert('g', ':first-child')
                    .attr('class', 'grid')
                    .call(d3.axisLeft(yScale).ticks(5).tickSize(-newContainerWidth).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'lightgrey');
            } else {
                svg.selectAll('.grid').remove();
            }
            var datakeys_mod
            if (YLabel.length > 0) {
                datakeys_mod = YLabel.slice(1)
            }
            else {
                datakeys_mod = datakeys
            }
            d3.selectAll('.line-label ,label-group ').remove();
            // const legendContainer = d3.select(`#legend${i}`);
            // const legendWidth = datakeys.length * 120;
            // const legendY = height + margin.bottom - 40;
            // legendContainer.selectAll('*').remove();

            // datakeys_mod.forEach((key, i) => {
            //     const legendItemGroup = legendContainer.append('div')
            //         .attr('class', 'legend-item')

            //     legendItemGroup.append('div')
            //         .attr('class', 'legend-rect')
            //         .style('background-color', (d) => {
            //             var colorToUse = (chart_color?.length > 0 ? (chart_color[i] != undefined) ? chart_color[i] : chart_color[i + 1] : color(key));
            //             return colorToUse;
            //         })

            //     const legend = legendContainer.attr('transform', `translate(${250}, ${legendY})`);
            //     legendItemGroup.append('text')
            //         .attr('fill', color(key))
            //         .text(key)
            // });


            const legendContainer = d3.selectAll(`#legend${i}`);
            const legendWidth = datakeys.length * 120;
            const legendX = (containerWidth - legendWidth) / 2;
            const legendY = height + margin.bottom - 40;
            legendContainer.selectAll('*').remove(); // Clear previous legend content
            
            datakeys_mod.forEach((key, i) => {
              const legendItemGroup = legendContainer.append('div')
                .attr('class', 'legend-item')
                .style('display', 'flex')
                .style('align-items', 'center')
                .style('margin-right', '10px'); // Add some space between legend items
            
              legendItemGroup.append('div')
                .attr('class', 'legend-rect')
                .style('width', '13px')
                .style('height', '13px')
                .style('background-color', () => {
                  var colorToUse = (chart_color?.length > 0 ? (chart_color[i] != undefined) ? chart_color[i] : chart_color[i + 1] : color(key));
                  return colorToUse;
                })
                .style('margin-right', '5px'); // Space between rectangle and text
            
              legendItemGroup.append('text')
                .attr('class', 'legend-text')
                .style('color', 'green')
                .text(key);
            });
            if (enabledTable) {
                show_table_fn(true)
            }
            else {
                show_table_fn(false)
            }

            const handleResetButtonClick = () => {
                setScrollDelta(0);
                xScale.range([0, containerWidth - margin.left - margin.right])
                svg.attr("width", containerWidth)
                svg.attr("transform", `translate(${margin.left},${margin.top})`)
            };
            document.getElementById(`togglereset-${i}`).addEventListener('click', function (event) {
                handleResetButtonClick()
            })

        }
    }, [containerWidth, containerHeight, chart_color, textColorBar, showGridEnabled, temp_containerWidth, fullScreen_enabled, temp_containerHeight, sortdata, YLabel, enable_table, enabledTable, svgHeight, show_Square, mouseovered_type, mouseovered, show_bar_values, SortArr, zoomLevel, scrollDelta, text_color_arr])



    const handleMenuClick = (e) => {
        setShowOptions(!showOptions);
    };

    const handleSortIconClick = (e) => {
        setSortShowOptions(!sortShowOptions)
    };

    
    const show_table_fn = async (val) => {
        const fieldNames = Object.keys(chartData[0]).filter(key => key !== "_id");
        if (val) {
            await tabulate(chartData, fieldNames)
        }
        else {
            d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
        }
    }

    async function handlePlusIconClick(value) {
        let deltaYExtension;
        if (value) {
            deltaYExtension = 100;
        } else {
            if (scrollDelta < 0) {
                deltaYExtension = 0;
            }
            else {
                deltaYExtension = -100;
            }

        }
        const wheelEvent = new WheelEvent("wheel", {
            deltaY: deltaYExtension,
            view: window,
            bubbles: true,
            cancelable: true
        });
        handleScroll(wheelEvent)
    }
    const handleScroll = (event) => {
        event.preventDefault()
        const delta = event.deltaY;
        setScrollDelta(prevDelta => prevDelta + delta);
        const newZoomLevel = zoomLevel + (delta > 0 ? -0.1 : 0.1);
        if (newZoomLevel > 0) {
            setZoomLevel(newZoomLevel);
        }
    };
    const tabulate = async (data, columns, y_axis_name) => {
        const header = columns;
        var data_exist;
        if (data !== undefined) {
            data_exist = data;
        } else {
            data_exist = data;
        }

        var tableContainer = document.getElementById(`tableContainer${i}`);
        tableContainer.innerHTML = "";
        var table = d3.select(`#tableContainer${i}`)
            .attr("class", "table-responsive")
            .append("table")
            .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth - 12}px`);

        var thead = table.append("thead");
        var tbody = table.append("tbody");

        d3.select(tableContainer)
            .attr('class', 'table_body')
            .style("overflow-y", "scroll")
            .style("overflow-x", "hidden");

        thead.append("tr")
            .selectAll("th")
            .data(header)
            .enter()
            .append("th")
            .text(function (column) { return column; })
            .attr("style", "text-align: center")
            .style('color', 'black')

        var rows = tbody.selectAll("tr")
            .data(data_exist)
            .enter()
            .append("tr");

        var cells = rows.selectAll("td")
            .data(function (row) {
                return columns.map(function (column) {
                    return { column: column, value: row[column] };
                });
            })
            .enter()
            .append("td")
            .attr("class", function (d) { return "cell " + d.column; })
            .html(function (d) { return d.value; })
            .attr("style", "text-align: center")
            .style('color', 'black')
        return table;
    }
    const handlesort = () => {
        var chart_id = i;
        dispatch(sortFunc({ data: chart_data, arrValues, chart_id }));
    }
    const handlesortdesc = () => {
        var chart_id = i;
        dispatch(sortDescending({ data: chart_data, arrValues, chart_id }));
    }
    const handleSortDefault = () => {
        dispatch(sortArea({ data: chart_data, chart_id: i }));
        setsortdata([...chart_data]);
    };

    const handleCheckboxChange = (e, value) => {
        if (e.target.checked) {
            setarrValues((prevData) => [
                ...prevData,
                value
            ]);
        } else {
            setarrValues(arrValues.filter((val) => val !== value));
        }

        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((val) => val !== value));
        } else {
            setSelectedValues([...selectedValues, value]);
        }
    };
    const handleMouseOver = (event, d) => {
        if (mouseoverEnabled) {
            if (mouseovered_type) {
                console.log("668");
                const svg = d3.select(`#my_dataviz${i}`)
                const hoverLine = svg.append("line")
                const [mouseX, mouseY] = d3.pointer(event, this);
                hoverLine.attr("x1", mouseX).attr("x2", mouseX);
                let isMouseOverNode = false;
                const areaGroups = svg.selectAll(`g.area-group${i}`)
                areaGroups.selectAll('circle, rect').each(function (nodeData) {
                    const dataPoint = nodeData;

                    if (dataPoint !== undefined) {
                        const node = d3.select(this);
                        const nodeX = parseFloat(node.attr('cx') || node.attr('x'));
                        const nodeY = parseFloat(node.attr('cy') || node.attr('y'));

                        if (Math.abs(mouseX - nodeX) < 8 && Math.abs(mouseY - nodeY) < 8) {
                            isMouseOverNode = true;
                        }
                    }
                });
                const tooltip = d3.select(`#tooltip${i}`);
                if (isMouseOverNode) {
                    tooltip.transition()
                        .duration(200)
                        .style("opacity", 0.9);

                    let tooltipLeft, tooltipTop;

                    if (mouseY < containerHeight / 2) {
                        tooltipTop = mouseY + 80;
                    } else {
                        tooltipTop = mouseY - 20;
                    }

                    const numericValues = Object.entries(d.data)
                        .filter(([key, value]) => key !== 'year' && !isNaN(value))
                        .map(([key, value]) => parseFloat(value));
                    const totalValue = numericValues.reduce((acc, value) => acc + parseFloat(value), 0);
                    const totalContent = `<div class="tooltip-content"><strong style="color: black;">Total:</strong> <span style="color: black;">${totalValue}</span></div>`;
                    const tooltipContent = Object.entries(d.data)
                        .filter(([key, value]) => key !== '_id' && key !== 'year')
                        .map(([key, value]) => `<div class="tooltip-content" ><strong style="color: black;">${key}:</strong> <span style="color: black;">${value}</span></div>`)
                        .join('');

                    tooltip.html(`Name: ${d.data.year}<br>${tooltipContent}${totalContent}`)
                        .style("left", `${event.offsetX}px`)
                        .style("top", `${tooltipTop}px`);
                } else {
                    tooltip.transition()
                        .duration(50)
                        .style("opacity", 0);
                }

                hoverLine.transition()
                    .duration(0)
                    .style("opacity", isMouseOverNode ? 9 : 0);
            } else {
                const tooltip = d3.select(`#tooltip${i}`);
                const year = d.data.year;
                const mouseY = d3.pointer(event)[1];
                const mouseX = event.offsetX
                let tooltipLeft = mouseX < containerWidth / 2 ? mouseX + 90 : mouseX - 120;
                let tooltipTop = mouseY < containerHeight / 2 ? mouseY + 100 : mouseY - 40;

                tooltip.html(
                    `<div class="tooltip-content"><strong style="color: black;">${barLabel}:</strong> <span style="color: black;">${year}</span></div>` +
                    `<div class="tooltip-content"><strong style="color: black;">Value:</strong> <span style="color: black;">${d[1] - d[0]}</span></div>`
                )
                    .style('left', `${tooltipLeft}px`)
                    .style('top', `${tooltipTop}px`)
                    .style('opacity', 0.8);
            }
        }
    };
    const handleMouseOut = () => {
        const tooltip = d3.selectAll(`#tooltip${i}`);
        tooltip.transition().duration(100).remove()
    };
    return (
        <div>
            <div id={`tooltip${i}`} style={{ position: 'absolute', opacity: 0.9, padding: '10px', borderRadius: '5px', backgroundColor: 'White' }}></div>
            <div id={`my_dataviz${i}`} onMouseLeave={() => { setShowOptions(false); setSortShowOptions(false); }}
            >
                <svg ref={svgRef} width={(fullScreen_enabled ? temp_containerWidth : containerWidth)} ></svg> </div>
            <div className="legend" id={`legend${i}`} style={{ position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'centre', marginLeft: containerWidth / 3, marginTop: '-40px', boxShadow: 'none' }}></div>

            {showOptions && (
                <div
                    className="download-options"
                    onMouseLeave={() => setShowOptions(false)}
                    style={{
                        position: 'absolute',
                        top: '36px',
                        right: '95px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        zIndex: 10
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
                >
                    <p onClick={() => dispatch(handleDownloadBar('0', datakeys_name, datakeys, chart_data))}>Download as CSV</p>
                    <p onClick={() => dispatch(imgDownloadSvg(`my_dataviz${i}`))}>Download as SVG</p>
                    <p onClick={() => dispatch(imgDownloadPng(i))} className='mt-1'>Download as PNG</p>
                </div>
            )}
            <span onMouseOver={() => { handleSortIconClick(); setShowOptions(false); }} >
                <i
                    className="bx bx-sort"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        width: '35px',
                        position: 'absolute',
                        top: '9px',
                        right: '100px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                ></i>

            </span >
            <i className="bx bx-reset"
                style={{
                    cursor: 'pointer',
                    fontSize: '20px',
                    width: '35px',
                    position: 'absolute',
                    top: '9px',
                    right: '303px',
                    zIndex: '1',
                    color: '#6666B2',
                }}

                id={`togglereset-${i}`}
            ></i>
            <span >
                <i className="bx bx-zoom-in"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        width: '35px',
                        position: 'absolute',
                        top: '9px',
                        right: '252px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                    onClick={() => handlePlusIconClick(true)}
                    id='plus-icon'
                ></i>
            </span>
            <span >
                <i
                    className="bx bx-zoom-out"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        width: '35px',
                        position: 'absolute',
                        top: '9px',
                        right: '277px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                    id='minus-icon'
                    onClick={() => handlePlusIconClick(false)}
                ></i>
            </span>
            <span onMouseOver={() => { handleMenuClick(); setSortShowOptions(false); }} >
                <i
                    className="bx bx-download"
                    style={{
                        cursor: 'pointer',
                        fontSize: '25px',
                        width: '45px',
                        position: 'absolute',
                        top: '7px',
                        right: '60px',
                        zIndex: '1',
                        color: '#6666B2',
                        height: '40px'
                    }}
                    onMouseOut={() => { setShowOptions(false) }}
                ></i>
            </span>
            {sortShowOptions && (
                <div
                    onMouseOver={() => setSortShowOptions(true)}
                    className="download-options"
                    style={{
                        position: 'absolute',
                        top: '45px',
                        right: '116px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        boxShadow: 'none',
                        maxHeight: (`${containerHeight - 120}px`),
                        overflow: 'auto',
                        zIndex: '5',
                    }}
                >
                    {selectedValues.length > 0 && (
                        <div style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button className='btn btn-sm btn-primary me-2' onClick={handlesort}>
                                    Ascending
                                </button>
                                <button className='btn btn-sm btn-primary me-2' onClick={handlesortdesc}>
                                    Descending
                                </button>
                                <button className='btn btn-sm btn-primary me-2' onClick={handleSortDefault}>
                                    Default
                                </button>
                            </div>
                        </div>
                    )}
                    <div>
                        {datakeys.map((value) => (
                            <div key={value} style={{ marginBottom: '10px', boxShadow: 'none' }}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={value}
                                        checked={selectedValues.includes(value)}
                                        onChange={(e) => handleCheckboxChange(e, value)}
                                    />
                                    {value.toUpperCase()}
                                </label>
                            </div>

                        ))}
                    </div>
                </div>
            )}
            {isLoading &&
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>}
            {enabledTable ? (
                <>
                    <div style={{
                        bottom: 0,
                        left: 0,
                        backgroundColor: '#fff',
                        height: (fullScreen_enabled ? '240px' : '200px')
                    }} id={`tableContainer${i}`}>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default D3AreaChart;
