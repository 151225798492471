import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReportTemplateList, crudReportPage ,setopenModal,setSelectedReportInfo} from '../../store/reportd3/reportslice';
import { useFormik } from "formik";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import MetaTags from 'react-meta-tags';
import { Link , useHistory} from 'react-router-dom'
import {
    CardBody, Badge, Container,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Button,
    Row, Col, Card, Spinner, Modal, Form, FormGroup, Label, Input, FormFeedback
} from 'reactstrap';
import TableContainer from './Components/TableContainer';
import { Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import * as Yup from "yup";

const ReportTemplate = () => {
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [editInfo, seteditInfo] = useState({})
    // const [pageSize,setpageSize] = useState(10)
    // const [pageIndex,setpageIndex] = useState(0)

    const dispatch = useDispatch()
    const reportSlice = useSelector(state => state.reportSliceReducer)
    const history = useHistory()
    const reportTemplateList = reportSlice.reportTemplateList
    const pageSize = reportSlice.pageInfo.pageSize
    const pageIndex = reportSlice.pageInfo.pageIndex
    const openModal = reportSlice.openModal


    useEffect(() => {
        // dispatch(getReportTemplateList(pageSize,pageIndex))
        dispatch(getReportTemplateList())
        
      
    }, [])


    const publishPage = (data, mode, reuse) => {
        console.log(data, 'data', mode, reuse)
        var reportInfo = { ...data }
        if (reuse === "1") {
            sessionStorage.setItem("page_data", JSON.stringify(reportInfo))
            //  sessionStorage.setItem("report_publish_type", "1")
            //  history.push("/report-users")
            history.push("/publish_type")

        }
        if (reuse === "2") {
            reportInfo = {
                ...reportInfo,
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                status: mode
            }
        }
        if (reuse === "3") {
            reportInfo = {
                ...reportInfo,
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                publish_status: mode
            }
        }
        if(reuse === "3" || reuse === "2"){
        dispatch(crudReportPage(reportInfo))
        }
    }



    const page = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '' || editInfo.name,
            description: '' || editInfo.description,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Page Name"),
            description: Yup.string().required("Please Enter Your Page Description"),
        }),
        onSubmit: (values) => {
            if (Object.keys(editInfo).length > 0) {
                values["created_by"] = authUser.user_data._id
                values["encrypted_db_url"] = authUser.db_info.encrypted_db_url
                values["_id"] = editInfo._id
                values["status"] = editInfo.status
                values["publish_status"] = editInfo.publish_status
                values["published_by"] = editInfo.published_by
                values["published_on"] = editInfo.published_on
            }
            else {
                values["created_by"] = authUser.user_data._id
                values["encrypted_db_url"] = authUser.db_info.encrypted_db_url
            }
            dispatch(crudReportPage(values))
        }
    })


    const columns = useMemo(() => [
        {
            accessor: 'name',
            Header: <span className="">Template Name</span>,
            filterable: true,
            width: "30%",
            Cell: (cellProps) => {
                var item = cellProps.row.original
                return (
                    <div className="fw-bold">
                        <span style={{ fontSize: '13px', color: '#848484' }}>{item.name}</span>
                    </div>
                )

            }
        },
        {
            accessor: 'createdAt',
            Header: <span className="">Created On</span>,
            filterable: true,
            width: "15%",
            Cell: (cellProps) => {
                var item = cellProps.row.original
                return (
                    <>
                        <div className=" text-secondary">
                            {item.createdAt === null ? "-- / -- / --" : moment(item.createdAt).format("DD / MMM / YYYY")}
                        </div>
                    </>
                )

            }
        },
        {
            accessor: 'publish_status',
            Header: <span className="">Publish Status</span>,
            filterable: true,
            width: "15%",
            Cell: (cellProps) => {
                var item = cellProps.row.original
                return (
                    <>
                        <div className="d-flex gap-3">
                            {
                                item.publish_status == "0" ?
                                    <Badge className="bg-secondry" style={{ fontSize: "11px" }}>Not Published</Badge>
                                    :
                                    item.publish_status == "1" ?
                                        <Badge className="bg-success" style={{ fontSize: "11px" }}> Published</Badge>
                                        :
                                        <Badge className="bg-danger" style={{ fontSize: "11px" }}> Stopped</Badge>
                            }
                        </div>
                    </>
                )

            }
        },
        {
            accessor: 'status',
            Header: <span className="">Page Status</span>,
            filterable: true,
            width: "15%",
            Cell: (cellProps) => {
                var item = cellProps.row.original
                return (
                    <>
                        <div className="d-flex gap-3">
                            {
                                item.status == "1" ?
                                    <Badge className="bg-success" style={{ fontSize: "11px" }}>Active</Badge>
                                    :
                                    <Badge className="bg-danger" style={{ fontSize: "11px" }}> Inactive</Badge>
                            }

                        </div>
                    </>
                )
            }
        },
        {
            accessor: 'published_on',
            Header: <span className="">Published On</span>,
            filterable: true,
            width: "15%",
            Cell: (cellProps) => {
                var item = cellProps.row.original
                return (
                    <>
                        <div className="text-secondary">
                            {
                                item.published_on == null ?
                                    "-- / -- / --"
                                    :
                                    moment(item.published_on).format("DD / MMM / YYYY")

                            }
                        </div>
                    </>
                )
            }
        },
        {
            accessor: 'action',
            Header: <span className="">Action</span>,
            filterable: true,
            width: "10%",
            Cell: (cellProps) => {
                var item = cellProps.row.original
                return (
                    <>
                        <div
                            className="d-flex gap-5 text-end"
                        >
                            {
                                item.publish_status === "0" ?
                                    <>
                                            <button
                                                className={`${item.publish_status === '1' ? 'btn btn-danger' : 'btn btn-success'
                                                    } waves-effect waves-light`}
                                                style={{ fontSize: '11.357px', padding: "0px 21px" }}
                                                onClick={()=>{publishPage(item, item.publish_status === "1" ? "0" : "1", "1")}}
                                            >
                                                Publish
                                            </button>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    className="card-drop"
                                    tag="a"
                                >
                                    <i className="mdi mdi-dots-horizontal font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end" style={{ marginTop: "-38px", width: "50%", padding: "0px" }}>
                                    <DropdownItem
                                        onClick={() => { publishPage(item, item.status === "1" ? "0" : "1", "2") }}

                                    >
                                        <i className="mdi mdi-account-circle font-size-12 text-info me-1" />{" "}
                                        {item.status === "1" ? "Make Inactive" : "Active"}
                                    </DropdownItem>
                                    {
                                        item.publish_status === "1" &&
                                        <DropdownItem
                                            onClick={() => { publishPage(item, "2", "3") }}
                                        >
                                            <i className="bx bx-stop-circle font-size-12 text-danger me-1" />{" "}
                                            Stop
                                        </DropdownItem>
                                    }
                                    <DropdownItem
                                        onClick={() => { seteditInfo(item); dispatch(setopenModal(true)) }}
                                    >
                                        <i className="mdi mdi-calendar font-size-12 text-primary me-1" />{" "}
                                        Edit
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <h2>
                                <Link to="/page_tree"><i className="bx bx-right-arrow-alt text-primary" style={{ cursor: 'pointer', fontSize: '28px' }}
                                    onClick={() => { sessionStorage.setItem("page_data", JSON.stringify(item));dispatch(setSelectedReportInfo(item))}}
                                /></Link>
                            </h2>
                        </div>
                    </>
                )
            }
        }

    ], reportTemplateList)



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>AuditVista | Report Templates</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Report Templates" breadcrumbItem="User Templates" />
                    <Row>
                        {
                            reportTemplateList ?
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={reportTemplateList}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={false}
                                                customPageSize={10}
                                                style={{ width: "100%" }}
                                                isPagination={true}
                                                filterable={false}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                                resetForm ={()=>{
                                                    page.resetForm()}
                                                }
                                                pageIndex={pageIndex}
                                                pageSize={pageSize}
                                                showBtn ={true}
                                            />
                                        </CardBody>
                                    </Card>

                                </Col>
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody style={{ height: "100vh" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                        <Modal
                            size="xl"
                            isOpen={openModal}
                            toggle={() => {
                                dispatch(setopenModal(!openModal))
                            }}
                            backdrop="static"
                            centered
                        >
                            <Row>
                                <Col xl={12} md={12} sm={12}>
                                    <div className="modal-header">
                                        <h5
                                            className="modal-title mt-0"
                                            style={{ fontSize: "16px", fontFamily: "poppins,sans-serif" }}
                                        >
                                            Template Info
                                        </h5>
                                        <button
                                            onClick={() => { dispatch(setopenModal(false)) }}
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <br />
                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            page.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col xl={11} md={10} sm={8}>
                                                <FormGroup style={{ marginLeft: '18px' }}>
                                                    <Label >Template Name:</Label>
                                                    <Input
                                                        name="name"
                                                        style={{ width: "106%" }}
                                                        placeholder="Enter Page Name"
                                                        type='text'
                                                        className="form-control"
                                                        onChange={page.handleChange}
                                                        onBlur={page.handleBlur}
                                                        value={page.values.name || "" || editInfo.name}
                                                        invalid={
                                                            page.touched.name && page.errors.name ? true : false
                                                        }
                                                    />
                                                    {page.touched.name && page.errors.name ? (
                                                        <FormFeedback type="invalid">{page.errors.name}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col xl={11} md={10} sm={8}>

                                                <FormGroup style={{ marginLeft: '18px' }}>
                                                    <Label >Description :</Label>
                                                    <Input
                                                        name="description"
                                                        style={{ width: "106%" }}
                                                        placeholder="Enter Page description"
                                                        type='textarea'
                                                        className="form-control"
                                                        onChange={page.handleChange}
                                                        onBlur={page.handleBlur}
                                                        value={page.values.description || "" || editInfo.description}
                                                        invalid={
                                                            page.touched.description && page.errors.description ? true : false
                                                        }
                                                    />
                                                    {page.touched.description && page.errors.description ? (
                                                        <FormFeedback type="invalid">{page.errors.description}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={9} md={7} sm={5}>
                                            </Col>
                                            <Col xl={3} md={5} sm={7}>
                                                <Button type="submit" color="danger" style={{ marginLeft: '10px' }} onClick={() => dispatch(setopenModal(false))}>Cancel</Button>
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="ms-2"
                                                >
                                                    {
                                                        Object.keys(editInfo).length > 0 ?
                                                            "Update" : "Submit"
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                            <br />
                            <br />
                        </Modal>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
// #endregion

export default ReportTemplate;