import React, { useEffect, useState, useRef } from "react";

import styled from 'styled-components';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Progress,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    Form,
    FormGroup,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Label,
    FormFeedback,
    Offcanvas,    
    Toast,
    ToastBody,
    ToastHeader

} from "reactstrap";
import { isEmpty, map } from "lodash";
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers,getRecentUsers,getSingleChatMsg,setSelectedUser,setSingleMessages ,setactiveuser,setRecentUsers,getRoomUserList,getGroupChatMsg,setGroupMessages,setRoomList,updateGroup,updateRecentuser} from "../../store/chat/chatslice";
import socket, {addRecentUser} from "../../helpers/socket"
import ContentEditor from "../Chat/Components/contentEditor";
import WebcamCapture from "../Chat/Components/webcam_component";
import PerfectScrollbar from "react-perfect-scrollbar";
import chaticon from "../../assets/images/icons/chat.png"
import CreateGroup from "./componenets/creategroup"
import ChatComponent from "./componenets/singleconversation";
import CreateGroupDrawer from "./componenets/creategroupdrawer";
import store from "../../store";




const propTypes = {};

const defaultProps = {};


const Chat = () => {
    const dispatch = useDispatch()
    const chatData = useSelector((state) => state.chatslice) 
    const recentuserlist = chatData.recentuserlist





    
    const selectedUser = store.getState().chatslice.selecteduser
    const roomlist = chatData.roomlist
    const messages = selectedUser?.type === "0" ? store.getState().chatslice.singlemessages : store.getState().chatslice.groupmessages
    const [activeTab, setactiveTab] = useState("1");
    const activeuser = chatData.activeuser;
    // const [isSliderOpen, setSliderOpen] = useState(false)
    const [EditTimer, setEditTimer] = useState(3);
    const [messageBox, setMessageBox] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('message received!!!');
    const [messageFrom, setMessageFrom] = useState('message from');
    const [messagegroup, setMessagegroup] = useState('Group');
    

    

    const userData = JSON.parse(sessionStorage.getItem("authUser"));


    const containerRef = useRef(null);


    useEffect(() => {
        dispatch(getRecentUsers())
    }, [])




    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messages]);




    if(socket){
        socket.on("notify_message",msg=>{
            setToastMessage(msg.msg.message.text);
            setMessageFrom(msg.msg.message_from)
            setMessagegroup("group")
            setToast(true);
            setTimeout(() => {
                setToast(false);
            }, 10000);
            console.log("message",msg)

            dispatch(updateRecentuser(msg))



        })
    }




    if(socket){
        socket.on("user_single_connected", (data) => {
            console.log("data", data)
            dispatch(setactiveuser(data))
        });

        socket.on("group_created",(data)=>{
            var updatedData = [...roomlist]
            updatedData.push(data)
            dispatch(setRoomList(updatedData))

        })
    }




    if (socket) {
    
        socket.on("receive_Single_Message", msg => {

            if (msg.message_type === "3" || msg.message_type === "4") {
                const datePart = msg.createdAt.split("T")[0];
                if (messages.length > 0) {
                    const updatedMessages = messages.map(item => {
                        if (item.date === datePart) {
                            console.log("item", item);
                            const updatedMessagesArray = item.messages.map(message => {
                                if (message._id === msg._id) {
                                    return msg; 
                                }
                                return message; 
                            });
            
                            return {
                                ...item,
                                messages: updatedMessagesArray
                            };
                        }
                        return item;
                    });
            
                    dispatch(setSingleMessages(updatedMessages));
                }
            }else{
                    
            if (messages.length > 0) {
                let lastObjectIndex = messages.length - 1;
    
                const updatedMessages = messages.map((item, index) => {
                    if (index === lastObjectIndex) {
                        return {
                            ...item,
                            messages: [...item.messages, msg]
                        };
                    }
                    return item;
                });
    
                dispatch(setSingleMessages(updatedMessages));
            } else {
            }

            }
            
        
        });


        socket.on("receive_group_message",( msg ,users)=> {

            if( users !== undefined &&  users.user.length !== 0 ){
                dispatch(updateGroup(users.user))
            }

            
            console.log("msg",users)
            if (msg.message_type === "3" || msg.message_type === "4") {
                const datePart = msg.createdAt.split("T")[0];
                if (messages.length > 0) {
                    const updatedMessages = messages.map(item => {
                        if (item.date === datePart) {
                            console.log("item", item);
                            const updatedMessagesArray = item.messages.map(message => {
                                if (message._id === msg._id) {
                                    return msg;
                                }
                                return message;
                            });

                            return {
                                ...item,
                                messages: updatedMessagesArray
                            };
                        }
                        return item;
                    });

                    dispatch(setGroupMessages(updatedMessages));
                }
            }
            else {
                if (messages.length > 0) {
                    let lastObjectIndex = messages.length - 1;

                    const updatedMessages = messages.map((item, index) => {
                        if (index === lastObjectIndex) {
                            return {
                                ...item,
                                messages: [...item.messages, msg]
                            };
                        }
                        return item;
                    });

                    dispatch(setGroupMessages(updatedMessages));
                } else {
                }
            }
        })
    }
    




    const scrollToBottom = () => {
        if (messageBox) {
            console.log("messageBox.scrollHeight", messageBox.scrollHeight)
            messageBox.scrollTop = messageBox.scrollHeight + 1000;
        }
    };

    useEffect(() => {
        if (!isEmpty(messages)) scrollToBottom();
    }, [messages]);





    const searchUsers = () => {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("search-user");
        filter = input.value.toUpperCase();
        ul = document.getElementById("recent-list");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    };

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
        if (tab === "3") {
            dispatch(getAllUsers("0"))
        }
        if (tab === "2") {
            dispatch(getRoomUserList())
        }
    };



    const onJoinRoom = (newRoom) => {
        const ownuser = _.filter (newRoom.group_users,{_id  : userData.user_data._id})
        if(ownuser[0].status === "0"  ){
            const dataObject = {
                roomname: newRoom.sender_id,
                username : userData.user_data.firstname,
                userid : userData.user_data._id,
                email_id :userData.user_data.email_id,
            }
            socket.emit("joinsingleroom",  dataObject );
        }
    };
    

    


    const openChat = async (chat) => {
        if (chat.type === "0") {
            try {
                const dataobject = {
                    user: chat,
                    userData: userData.user_data
                }
                dispatch(getSingleChatMsg(dataobject))
                dispatch(setSelectedUser(chat))
            } catch (error) {
                console.error('error', error);
            }
        } else {
            try {
                dispatch(setSelectedUser(chat))    
                dispatch(getGroupChatMsg(chat))
                onJoinRoom(chat)
            } catch (error) {
                console.error('error', error);
            }
        }
        dispatch(updateGroup(chat))
    };

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
      };

      const toggleToast = () => {
        setToast(!toast);
    };


      
    let user = _.groupBy(chatData.userlist, item => item.firstname[0].toUpperCase());


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>AuditVista | Chat</title>
                </MetaTags>
                <Container fluid>
                    <Row className="mt-1 bg-white" >
                        <Col lg="12">
                            <div className="d-lg-flex">
                                <div className="chat-leftsidebar border-end border-secondary border-opacity-25" style={{ height: "93vh" }}>
                                    <div >
                                        <div className="py-3 px-2 border-bottom border-secondary border-opacity-50">
                                            <h5 className="mb-0">chats</h5>
                                        </div>
                                        <div className="chat-leftsidebar-nav position-relative py-2 pe-1">
                                            <Nav pills justified style={{ width: "45%" }} className="pb-2">
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("1");
                                                        }}
                                                    >

                                                        <span className="d-none d-sm-block"><i className="bx bx-chat font-size-20" /></span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("2");
                                                        }}
                                                    >

                                                        <span className="d-none d-sm-block"><i className="bx bx-group font-size-20" /></span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === "3",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("3");
                                                        }}
                                                    >

                                                        <span className="d-none d-sm-block"><i className="bx bx-book-content font-size-20" /></span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab} className="py-4 px-2 border-top border-secondary border-opacity-50">
                                                <TabPane tabId="1">
                                                    <div>
                                                        <h5 className="font-size-14 mb-1">Recent</h5>
                                                        <div className="search-box chat-search-box py-2 border-bottom border-secondary border-opacity-50">
                                                            <div className="position-relative px-2">
                                                                <Input
                                                                    onKeyUp={searchUsers}
                                                                    id="search-user"
                                                                    type="text"
                                                                    className="form-control bg-light"
                                                                    placeholder="Search..."
                                                                    style={{ borderRadius: 20 }}
                                                                />
                                                                <i className="bx bx-search-alt search-icon ps-2" />
                                                            </div>
                                                        </div>
                                                        <ul className="list-unstyled chat-list" id="recent-list">
                                                            {
                                                                recentuserlist && activeuser &&
                                                                <SimpleBar style={{ maxHeight: "410px" }}>
                                                                    {recentuserlist.map((chat, idx) => {
                                                                        var isActive = _.findIndex(activeuser, { "userid": chat.sender_id })
                                                                        return (
                                                                            <li
                                                                                key={idx}
                                                                                className={
                                                                                    selectedUser && selectedUser.sender_id === chat.sender_id
                                                                                        ? "border-bottom border-secondary border-opacity-50"
                                                                                        : "border-bottom border-secondary border-opacity-50"
                                                                                }
                                                                                style={{ background: selectedUser && selectedUser.sender_id === chat.sender_id ? "#e6f7fc" : "" }}
                                                                            >
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        openChat(chat);
                                                                                    }}
                                                                                >

                                                                                    <div className="d-flex">
                                                                                        {
                                                                                            chat.type === "0" ?
                                                                                                (
                                                                                                    <div className="align-self-center me-3">
                                                                                                        <i
                                                                                                            className={
                                                                                                                isActive !== -1
                                                                                                                    ? "mdi mdi-circle text-success font-size-10"
                                                                                                                    // : chat.status === "intermediate"
                                                                                                                    //     ? "mdi mdi-circle text-warning font-size-10"
                                                                                                                    : "mdi mdi-circle text-danger font-size-10"
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                ) : (<div className="ms-4">
                                                                                                    </div>)
                                                                                        }
                                                                                       
                                                                                        {chat.isImg ? (
                                                                                            <div className="avatar-xs align-self-center me-3">
                                                                                                <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                                                                    {chat.profile}
                                                                                                </span>
                                                                                            </div>
                                                                                        ) : (
                                                                                            chat.type === "0" ? (
                                                                                                <div className="avatar-xs align-self-center me-3">
                                                                                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                                                                        <i className="fas fa-user"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className="avatar-xs align-self-center me-3">
                                                                                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                                                                        <i className="fas fa-users"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            )
                                                                                        )}


                                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                                            <h5 className="text-truncate font-size-14 mb-1 text-dark">
                                                                                                {chat.sender_name}
                                                                                            </h5>
                                                                                            <p className="text-truncate mb-0 font-size-10 fs-italic">
                                                                                                {"last message text"}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="font-size-11">
                                                                                            {chat.time}
                                                                                        </div>


                                                                                        {
                                                                                            chat.unreadCount > 0 && chat.unreadCount !== undefined &&
                                                                                            <div >
                                                                                                <span className="text-white bg-primary avatar-xs rounded-circle d-flex justify-content-center align-items-center  "  >
                                                                                                    {chat.unreadCount}
                                                                                                </span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </SimpleBar>
                                                            }
                                                        </ul>
                                                    </div>
                                                </TabPane>

                                                <TabPane tabId="2">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h5 className="font-size-14 mb-0">Group</h5>
                                                        <Button className="btn btn-sm btn-success ml-auto"  onClick={toggleDrawer} >Create Group</Button>
                                                        <CreateGroupDrawer open={isDrawerOpen} toggle={toggleDrawer}  userData={userData} />
                                                        
                                                    </div>

                                                    <ul className="list-unstyled chat-list">
                                                        <SimpleBar style={{ height: "410px" }}>

                                                            {roomlist &&
                                                                roomlist.map((group, idx) => (
                                                                    
                                                                    // <li key={idx} className={currentRoomId === group.roomId ? "active" : ""}>
                                                                    <li key={idx}>
                                                                        {/* <Link
                                                                            to="#"
                                                                            onClick={() => startGroupChat(group)}
                                                                        > */}
                                                                            <div className="d-flex align-items-center">
                                                                                {group.isImg ?
                                                                                    <div className="avatar-xs align-self-center me-3">
                                                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                                                            {group.profile}
                                                                                        </span>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="avatar-xs align-self-center me-3">
                                                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                                                            {group.room_name.charAt(0).toUpperCase()}
                                                                                        </span>
                                                                                    </div>
                                                                                }


                                                                                <div className="flex-grow-1">
                                                                                    <h5 className="font-size-14 mb-0">
                                                                                        {group.room_name}
                                                                                    </h5>
                                                                                </div>
                                                                            </div>
                                                                        {/* </Link> */}
                                                                    </li>
                                                                ))}
                                                        </SimpleBar>
                                                    </ul>
                                                </TabPane>

                                                <TabPane tabId="3">
                                                    <h5 className="font-size-14 mb-3">Contact</h5>

                                                        
                                                    <div>
                                                        
                                                        <SimpleBar style={{  maxHeight: "950px" }}>
                                                            {user &&
                                                                Object.entries(user).map(([letter, items]) => {
                                                                    if (Array.isArray(items)) {
                                                                        return (
                                                                            <div
                                                                                key={letter}
                                                                                className={
                                                                                    "mt-4"
                                                                                }
                                                                            >
                                                                                <div className="avatar-xs mb-3">
                                                                                    <span className="avatar-title rounded-circle bg-primary bg-soft text-white">
                                                                                        {letter}
                                                                                    </span>
                                                                                </div>

                                                                                <ul className="list-unstyled chat-list">
                                                                                    {items.map((array, idx) => (
                                                                                        <li key={"contact_" + letter + "_" + idx} >
                                                                                            <Link
                                                                                                to="#"
                                                                                                onClick={() => addRecentUser(array)}
                                                                                                className="border-top border-secondary border-opactiy-25 "
                                                                                            >
                                                                                                <h5 className="font-size-14 mb-0">
                                                                                                    {array.firstname}
                                                                                                </h5>
                                                                                            </Link>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                           
                                                                        )
                                                                    }
                                                                })}
                                                        </SimpleBar>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </div>
                                </div>


                                <ChatComponent
                                    selectedUser={selectedUser}
                                    messages={messages}
                                    userData={userData}
                                    // isSliderOpen={isSliderOpen}
                                    EditTimer={EditTimer}
                                    setMessageBox={setMessageBox}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
                <Toast isOpen={toast}>
                    <ToastHeader toggle={toggleToast}>
                      ({messagegroup}):{messageFrom}
                    </ToastHeader>
                    <ToastBody>
                        {toastMessage}
                    </ToastBody>
                </Toast>
            </div>
            </div>
        </React.Fragment>
    )

}

Chat.propTypes = propTypes;
Chat.defaultProps = defaultProps;
// #endregion

export default Chat;