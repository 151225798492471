import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import html2canvas from 'html2canvas';

const D3Table = (props) => {
  const sortOrders = {};
  const [showOptions, setShowOptions] = useState(false)

  var containerWidth = props.containerWidth
  var containerHeight = props.containerHeight
  var chart_data = props.chart_data
  var i = props.id
  var enable_table = props.show_table
  var label_arr = props.label_name

  const [data, setData] = useState(chart_data)
  const [TableHeight, setTableHeight] = useState(containerHeight)

  useEffect(() => {
    if (chart_data !== undefined && chart_data.length > 0) {
      setData(chart_data)
      setTableHeight(containerHeight)
      if (enable_table) {
        showTableFn(true)
      }
      else {
        showTableFn(false)
      }
    }
  }, [chart_data, enable_table, containerHeight, TableHeight, containerWidth])


  useEffect(() => {

    if (label_arr.length > 0) {
      const modifiedData = data.map(obj => {
        const newObj = {};
        Object.keys(obj).forEach((key, index) => {
          if (key === '_id') {
            newObj[key] = obj[key];
          } else {
            newObj[label_arr[index - 1]] = obj[key];
          }
        });
        return newObj;
      });
      tabulate(modifiedData, label_arr)
    }

  }, [label_arr]);


  if (props.chart_data.length !== 0) {
    var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
    var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
  }

  const margin = { top: 70, right: 30, bottom: 80, left: 40 };
  useEffect(() => {
    if (chart_data.lenght > 0) {
      showTableFn(true)
    }

  }, [chart_data, containerWidth])

  const handleMenuClick = (e) => {
    setShowOptions(!showOptions);
  };

  const handleDownloadBar = (value) => {
    datakeys_name
    const newKeys = {
      label: datakeys_name,
      value: datakeys,
    };
    const renamedData = data.map(obj => renameKeys(obj, newKeys));
    if (value === "0") {
      const datas = renamedData.map(obj => {
        const { _id, ...rest } = obj;
        return rest
      })
      const csv = convertToCSV(datas);
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'table.csv';
      a.click();
      URL.revokeObjectURL(url);
    }
    else {
      const data1 = data.filter(item => item !== null);
      const data = data1;
      const csv = convertToCSV(data);
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'table.csv';
      a.click();
      URL.revokeObjectURL(url);
    }
  }

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(obj => Object.values(obj).join(',')).join('\n');
    return header + rows;
  }

  const renameKeys = (obj, newKeys) => {
    const keyEntries = Object.entries(obj);
    const renamedObj = keyEntries.reduce((acc, [key, value]) => {
      const newKey = newKeys[key] || key;
      acc[newKey] = value;
      return acc;
    }, {});
    return renamedObj;
  };

  const imgDownloadSvg = async (id) => {
    const tableContainer = document.getElementById(id);
    if (!tableContainer) {
      console.error("Table container not found");
      return;
    }

    const originalOverflow = tableContainer.style.overflow;
    const clonedTable = document.createElement('table');
    clonedTable.classList.add('table-responsive');
    clonedTable.style.position = 'absolute';
    clonedTable.style.left = '-9999px';
    clonedTable.style.height = `${containerHeight - margin.top}px`;
    clonedTable.style.width = `${containerWidth}px`;
    const clonedThead = document.createElement('thead');
    clonedTable.appendChild(clonedThead);
    const clonedTbody = document.createElement('tbody');
    clonedTable.appendChild(clonedTbody);
    const originalThead = tableContainer.querySelector('thead');
    clonedThead.innerHTML = originalThead.innerHTML;
    const originalTbody = tableContainer.querySelector('tbody');
    clonedTbody.innerHTML = originalTbody.innerHTML;
    tableContainer.style.overflow = 'hidden';
    document.body.appendChild(clonedTable);


    try {
      const canvas = await html2canvas(clonedTable);
      const imgData = canvas.toDataURL('image/png');
      tableContainer.style.overflow = originalOverflow;
      const downloadLink = document.createElement("a");
      downloadLink.href = imgData;
      downloadLink.download = "table.png";
      downloadLink.click();
      document.body.removeChild(clonedTable);

    } catch (error) {
      console.error("Error capturing table as image:", error);
      tableContainer.style.overflow = originalOverflow;
    }
  };


  const showTableFn = async (val) => {
    const fieldNames = Object.keys(chart_data[0]).filter(key => key !== "_id");
    if (val) {
      await tabulate(chart_data, fieldNames)
    }
    else {
      d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
    }
  }

  const tabulate = async (data, columns, y_axis_name) => {
    const header = columns;
    var data_exist;
    if (data !== undefined) {
      data_exist = data;
    } else {
      data_exist = data;
    }

    var tableContainer = document.getElementById(`tableContainer${i}`);
    tableContainer.innerHTML = "";
    var table = d3.select(`#tableContainer${i}`)
      .attr("class", "table-responsive")
      .append("table")
      .style("height", `${containerHeight - margin.top}px`)
      .style("width", `${containerWidth}px`);

    var thead = table.append("thead");
    var tbody = table.append("tbody");

    d3.select(tableContainer)
      .attr('class', 'table_body')
      .style("position", "relative")
      .style("height", `${containerHeight - margin.top}px`)
      .style("width", `${containerWidth}px`)

      .style("overflow-y", "scroll")
      .style("overflow-x", "hidden");


    var originalData = data ? data.slice() : [];
    var temp_order_data


    thead
      .append("tr")
      .selectAll("th")
      .data(header)
      .enter()
      .append("th")
      .text(function (column) {

        return column?.replace(/\b\w/g, function (l) {
          return l.toUpperCase();
        });
      })
      .attr("style", "text-align: center")
      .style('color', 'black')
      .on("click", function (column, event) {
        updateTable(column, event);
      })
      .append("span")
      .html(function (column) {
        var sortClass = sortOrders[column] === 'asc' ? 'ascending' : sortOrders[column] === 'desc' ? 'descending' : 'no-sort';
        var arrowSize = "18px";
        var arrowColor = "blue";
        var iconClass = 'bx bx-sort';

        return '<span class="' + sortClass + ' ' + ((sortOrders[column] != 'asc' && sortOrders[column] != 'desc') ? iconClass : '') + '" style="font-size:' + arrowSize + '; color:' + arrowColor + ';">' +
          (sortOrders[column] === 'asc' ? ' &#x2191;' : sortOrders[column] === 'desc' ? ' &#x2193;' : '') +
          '</span>';
      });

    const updateTable = (column, event) => {
      if (sortOrders[event] === 'asc') {
        sortOrders[event] = 'desc';
      } else if (sortOrders[event] === 'desc') {
        sortOrders[event] = undefined;
      } else {
        sortOrders[event] = 'asc';
      }

      temp_order_data = data_exist.slice();

      var bg = temp_order_data.sort(function (a, b) {
        if (sortOrders[event] === 'asc') {
          return d3.ascending(a[event], b[event]);
        } else if (sortOrders[event] === 'desc') {
          return d3.descending(a[event], b[event]);
        } else {
          return d3.ascending(a[event], b[event]);
        }
      });

      temp_order_data = sortOrders[event] === undefined ? originalData : bg;
      Object.keys(sortOrders).forEach((key) => {
        if (key !== event) {
          sortOrders[key] = undefined;
        }
      });
      updateTableDisplay();
    };


    const updateTableDisplay = () => {
      var rows = tbody.selectAll("tr")
        .data(temp_order_data);
      rows.exit().remove();
      var cells = rows.enter()
        .append("tr")
        .merge(rows)
        .selectAll("td")
        .data(function (row) {
          return columns.map(function (column) {
            return { column: column, value: row[column] };
          });
        });
      cells.enter()
        .append("td")
        .merge(cells)
        .attr("class", function (d) { return "cell " + d.column; })
        .html(function (d) { return d.value; })
        .attr("style", "text-align: center")
        .style('color', 'black');
      thead.selectAll("th").style("color", 'black');

      thead.selectAll("th")
        .style("color", function (column) {
          var sortOrder = sortOrders[column];
          var ascendingColor = "green";
          var descendingColor = "orange";
          return sortOrder === 'asc' ? ascendingColor : sortOrder === 'desc' ? descendingColor : 'black';
        });

      thead.selectAll("th span")
        .html(function (column) {
          var arrowSize = "18px";
          var arrowColor = "green";
          var iconClass = 'bx bx-sort';
          var sortOrder = sortOrders[column];
          var ascendingColor = "green";
          var descendingColor = "orange";
          var color_updt = (sortOrder === 'asc' ? ascendingColor : sortOrder === 'desc' ? descendingColor : 'black');
          var sortClass = sortOrders[column] === 'asc' ? 'ascending' : sortOrders[column] === 'desc' ? 'descending' : 'no-sort';

          return '<span class="' + sortClass + ' ' + ((sortOrders[column] != 'asc' && sortOrders[column] != 'desc') ? iconClass : '') + '" style="font-size:' + arrowSize + '; color:' + color_updt + ';">' +
            (sortOrders[column] === 'asc' ? ' &#x2191;' : sortOrders[column] === 'desc' ? ' &#x2193;' : '') +
            '</span>';
        });

      return table;
    };


    thead.selectAll("th").style("color", 'black');

    var rows = tbody.selectAll("tr")
      .data(data_exist)
      .enter()
      .append("tr");

    var cells = rows.selectAll("td")
      .data(function (row) {
        return columns.map(function (column) {
          return { column: column, value: row[column] };
        });
      })
      .enter()
      .append("td")
      .attr("class", function (d) { return "cell " + d.column; })
      .html(function (d) { return d.value; })
      .attr("style", "text-align: center")
      .style('color', 'black')
    return table;
  }

  return (
    <div onMouseLeave={() => { setShowOptions(false) }}>
      <div >
        <span onMouseOver={handleMenuClick}>
          <i
            className="bx bx-download"
            style={{
              cursor: 'pointer',
              fontSize: '30px',
              width: '45px',
              position: 'absolute',
              top: '4px',
              right: '65px',
              zIndex: '1',
              color: '#6666B2',
            }}
          ></i>
        </span>
      </div>


      {showOptions && (
        <div
          className="download-options"
          style={{
            position: 'absolute',
            top: '44px',
            right: '73px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            color: '#000080',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            zIndex: '1',
            cursor: 'pointer',
            zIndex: '5',
          }}
          onClick={(e) => e.stopPropagation()}
          onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
        >
          <p onClick={() => handleDownloadBar('0')}>Download as CSV</p>
          <p onClick={() => imgDownloadSvg(`tableContainer${i}`)}>Download as SVG</p>
        </div>
      )}
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        top: '40px',
        backgroundColor: '#fff',
      }} id={`tableContainer${i}`}>
      </div>

    </div>
  );
};

export default D3Table;