import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { handleDownloadBar, imgDownloadPng, imgDownloadSvg, sortFunc, sortDescending, sortHorstack } from '../../../../store/reportd3/reportslice';

// import { handleDownload_bar, img_download_svg, img_download_png_mod } from '../../../../store/report/Slice/DownloadSlice';
// import { sortFunc, sortDescending, sorthorstack } from '../../../../store/report/Slice/SortingSlice';


const HorizontalStackChart = (props) => {
  const dispatch = useDispatch();
  var containerWidth = props.containerWidth
  var containerHeight = props.containerHeight
  var chart_data = props.chart_data
  var chart_color = props.chart_color
  var i = props.id
  var show_bar_values = props.show_bar_values
  var mouseovered = props.mouseovered
  var mouseovered_type = props.mouseovered_type
  var enable_table = props.show_table
  var svgHeight = props.chart_height
  var show_Grid = props.show_Grid
  var temp_containerWidth = props.temp_containerWidth
  var temp_containerHeight = props.temp_containerHeight
  var fullScreen_enabled = props.show_Full_Screen_toggle
  var curved_line = props.curved_line;
  var show_Square = props.show_Square
  var YLabel = props.YLabel
  var BarWidth = props.BarWidth
  var text_color_arr = props.text_color
  var barLabel = props.label;


  const [data, setData] = useState(chart_data);
  const [textColorBar, setTextColorBar] = useState([])
  const [showOptions, setShowOptions] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [showValues, setShowValues] = useState(show_bar_values);
  const [mouseoverEnabled, setMouseoverEnabled] = useState(mouseovered)
  const [mouseoverSwitchType, setMouseoverSwitchType] = useState(mouseovered_type)
  const [enabledTable, setEnabledTable] = useState(enable_table)
  const [showGridEnabled, setShowGridEnabled] = useState(show_Grid);
  const [sortShowOptions, setShowSortoption] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [arrValues, setarrValues] = useState([])
  const [showLine, setShow_Line] = useState(showline)
  const [sortOrder, setSortOrder] = useState('')
  // const SortArr = useSelector(state => state.SortArr);
  const SortArr = useSelector(state => state.reportSliceReducer);
  const [sortData, setSortData] = useState([]);
  var showline = props.show_Line

  useEffect(() => {
    if (chart_data !== undefined && chart_data.length > 0) {
      setData(chart_data)
      setTextColorBar(chart_color)
      setShowValues(show_bar_values)
      setMouseoverEnabled(mouseovered)
      setMouseoverSwitchType(mouseovered_type)
      setEnabledTable(enable_table)
      setShowGridEnabled(show_Grid)
      setShow_Line(showline)
    }
  }, [chart_data, chart_color, show_bar_values, mouseovered, mouseovered_type, enable_table, show_Grid, temp_containerWidth, fullScreen_enabled, temp_containerHeight, showline])
  if (props.chart_data.length !== 0) {
    var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
    var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
  }
  const margin = { top: 70, right: 40, bottom: 80, left: 100 };
  useEffect(() => {
    if (sortOrder === 'asc') {
      handleSortAscending()
    }
    else if (sortOrder === 'desc') {
      handleSortDescending()
    }
    else {
      handleSortDefault()
    }
  }, [chart_data])

  useEffect(() => {
    const data = chart_data;
    if (datakeys !== undefined) {
      var mod_data;
      var chart_id = i;
      if (SortArr[chart_id] && SortArr[chart_id].horstack) {
        mod_data = SortArr[chart_id].horstack;
      } else {
        mod_data = data;
      }
      if (svgHeight !== undefined && svgHeight !== '') {
        containerHeight = containerHeight - 200
      }
      else {
        containerHeight = containerHeight
      }
      var width
      var height
      if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
        width = temp_containerWidth - margin.left - margin.right;
        height = temp_containerHeight - margin.top - margin.bottom - (enabledTable ? 200 : 0);
        containerWidth = temp_containerWidth
        containerHeight = height + margin.bottom
      } else {
        width = containerWidth - margin.left - margin.right;
        height = containerHeight - margin.top - margin.bottom;
      }
      const temp_barHeight = BarWidth !== undefined ? Number(BarWidth) : 45;
      const marginTop = margin.top;
      const marginRight = margin.right;
      const marginBottom = margin.bottom;
      const marginLeft = margin.left;
      d3.selectAll(`#tooltip${i}`).remove()
      d3.selectAll(`.x-axis${i}`).remove()
      d3.selectAll(`.top-layer${i}`).remove()

      const extent = [
        [marginLeft, marginTop], // Top-left corner
        [marginLeft, containerHeight - marginBottom] // Bottom-left corner
      ];

      const zoom = d3.zoom()
        .scaleExtent([1, 10])
        .translateExtent(extent)
        .extent(extent)
        .on("zoom", zoomed);

      const svg = d3.select(`#my_dataviz${i}`)
        .attr('width', containerWidth)
        .attr('height', containerHeight)
        .call(zoom)

      const yScale = d3.scaleBand()
        .domain(mod_data.map(d => d.year))
        .range([containerHeight - marginBottom, marginTop]) 
        .padding(0.1);

      const xScale = d3.scaleLinear()
        .domain([0, d3.max(mod_data, d => d3.sum(datakeys.map(key => d[key])))])
        .nice()
        .range([0, width]);

      const text_color = d3.scaleOrdinal()
        .domain(datakeys)
        .range(text_color_arr !== undefined && text_color_arr.length > 0
          ? text_color_arr
          : d3.quantize(d3.interpolateRainbow, datakeys.length + 2));

      const g = svg.select('g');
      g.selectAll('*').remove();
      g.attr('transform', `translate(${margin.left},${margin.bottom - margin.top})`);

      const color = d3.scaleOrdinal()
        .domain(datakeys)
        .range((textColorBar !== '' && textColorBar !== undefined && textColorBar.length > 0) ? textColorBar : ['red', 'blue', 'green', 'orange', 'purple', 'cyan']);

      const stack = d3.stack().keys(datakeys)
      const series = stack(mod_data);

      const tooltip = d3.select(fullScreen_enabled ? `#my_dataviz${i}` : 'body')
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "10px");


      // const newWidth = Math.max(temp_barHeight + margin.bottom + margin.top, containerWidth); 
      // setChrtWidth(BarWidth !== undefined ? newWidth : containerHeight)
      // g.selectAll('g')
      //   .data(series)
      //   .enter().append('g')
      //   .attr('fill', d => color(d.key))
      //   .selectAll('rect')
      //   .data(d => d)
      //   .enter().append('rect')
      //   .attr('y', d => yScale(d.data.year))
      //   .attr('x', d => xScale(d[0]))
      //   .attr('width', d => xScale(d[1]) - xScale(d[0]))
      //   .attr('height', yScale.bandwidth())
      //   .style('cursor', 'pointer')
      //   // .transition()
      //   // .duration(1000)
      //   .attr('x', d => xScale(d[0]))
      //   .attr('width', d => xScale(d[1]) - xScale(d[0]))
      // .on("mouseover", multi_mouseover)
      // .on("mousemove", multi_mouseover)
      // .on('mouseleave', multi_mouseleave)
      // .on("mouseout", multi_mouseout)


      const newWidth = Math.max(temp_barHeight + margin.bottom + margin.top, containerWidth); 
      
      const bars = g.selectAll('g')
        .data(series)
        .enter().append('g')
        .attr('fill', d => color(d.key))
        .selectAll('rect')
        .data(d => d)
        .enter().append('rect')
        .attr('y', d => yScale(d.data.year))
        .attr('x', 0) 
        .attr('width', 0) 
        .attr('height', yScale.bandwidth())
        .style('cursor', 'pointer')
        .on("mouseover", multi_mouseover)
        .on("mousemove", multi_mouseover)
        .on('mouseleave', multi_mouseleave)
        .on("mouseout", multi_mouseout)
      
      bars.transition()
        .duration(1000)
        .attr('x', d => xScale(d[0]))
        .attr('width', d => xScale(d[1]) - xScale(d[0]));
        
      d3.select(`#my_dataviz${i}`).selectAll(" .x-axis").remove()

      const XAxisContainer = d3.select(`#my_dataviz${i}`)
        .append("svg")
        .attr('class', `x-axis${i}`)
        .style("position", "absolute")
        .style('background-color', 'white')
        .style("top", `${containerHeight - margin.bottom}px`)
        .style("left", '0px')
        .style("width", `${containerWidth - 15}px`)
        .style("height", `${margin.bottom}px`);

      const backgroundRect = XAxisContainer.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", containerWidth)
        .attr("height", '100%')
        .attr("fill", "white");

      const xAxisSvg = XAxisContainer.append("svg")
        .attr('class', "xContainer")
        .attr("width", containerWidth)
        .attr("height", '100%')
        .append("g")
        .attr("transform", `translate(${100}, 0)`)
        .call(d3.axisBottom(xScale).ticks())
        .call(g => {
          g.selectAll('.domain, text')
            .attr('stroke', fullScreen_enabled ? 'black' : 'black')
            .style("font-size", '10px');
        });

      const TopContainer = d3.select(`#my_dataviz${i}`)
        .append("svg")
        .attr('class', `top-layer${i}`)
        .style("position", "absolute")
        .style('background-color', 'white')
        .style("top", `${0}px`)
        .style("left", '0px')
        .style("width", `${containerWidth}px`)
        .style("height", `${margin.top - 15}px`);

      const TopbackgroundRect = TopContainer.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", containerWidth)
        .attr("height", '100%')
        .attr("fill", "white");

      if (showLine) {
        createLineChart(data, datakeys, props.chart_color);
      }

      function createLineChart(data, keys, colors) {
        const stack = d3.stack().keys(keys);
        const series = stack(mod_data);
        keys.forEach((key, index) => {
          const lineGenerator = curved_line ?
            d3.line().curve(d3.curveCardinal) :
            d3.line();

          lineGenerator.x(d => xScale(d[1]))
            .y(d => yScale(d.data.year) + yScale.bandwidth() / 2);

          g.append('path')
            .datum(series[index])
            .attr('class', `line-${key}`)
            .attr('d', lineGenerator)
            .attr('fill', 'none')
            .attr('stroke', 'black')
            .attr('stroke-width', 2.5)
            .attr('stroke-dasharray', '5,5');

          series[index].forEach((point, pointIndex) => {
            const x = xScale(point[1]);
            const y = yScale(point.data.year) + yScale.bandwidth() / 2;

            if (!show_Square) {
              g.append('rect')
                .datum(point)
                .attr('class', `node-${key}`)
                .attr('x', x - 5)
                .attr('y', y - 5)
                .attr('width', 10)
                .attr('height', 10)
                .attr('fill', "pink")
                .on('mouseover', function (event, d) {
                  const tooltipX = event.pageX + 10;
                  const tooltipY = event.pageY - 50;
                  let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                  if (tooltip.empty()) {
                    tooltip = d3.select(fullScreen_enabled ? `#my_dataviz${i}` : 'body').append('div')
                      .attr('id', 'tooltip')
                      .attr('class', 'tooltip')
                      .style('position', 'absolute')
                      .style('background-color', 'white')
                      .style('border', '1px solid black')
                      .style('padding', '5px')
                      .style('opacity', 0);
                  }
                  tooltip.html(`Value: ${d[1] - d[0]}, ${barLabel}: ${d.data.year}`)
                    .style("left", ` ${fullScreen_enabled ? event.offsetX - 10 : event.pageX - 10}px`)
                    .style("top", `${fullScreen_enabled ? event.offsetY - 50 : event.pageY - 50}px`)
                    .style('opacity', 0.9).raise()
                })
                .on('mouseout', function (event, d) {
                  d3.select('#tooltip')
                    .style('opacity', 0);
                });
            } else {
              g.append('circle')
                .datum(point)
                .attr('class', `node-${key}`)
                .attr('cx', x)
                .attr('cy', y)
                .attr('r', 5)
                .attr('fill', "black")
                .on('mouseover', function (event, d) {
                  const tooltipX = event.pageX + 10;
                  const tooltipY = event.pageY - 50;
                  let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                  if (tooltip.empty()) {
                    tooltip = d3.select(fullScreen_enabled ? `#my_dataviz${i}` : 'body').append('div')
                      .attr('id', 'tooltip')
                      .attr('class', 'tooltip')
                      .style('position', 'absolute')
                      .style('background-color', 'white')
                      .style('border', '1px solid black')
                      .style('padding', '5px')
                      .style('opacity', 0);
                  }
                  tooltip.html(`<div>Value: ${d[1] - d[0]}</div><div>${barLabel}: ${d.data.year}</div>`)
                    .style("left", ` ${fullScreen_enabled ? event.offsetX - 10 : event.pageX - 10}px`)
                    .style("top", `${fullScreen_enabled ? event.offsetY - 50 : event.pageY - 50}px`)
                    .style('opacity', 0.9);
                })
                .on('mouseout', function (event, d) {
                  d3.select('#tooltip')
                    .style('opacity', 0);
                });
            }
          });
        });
      }

      g.selectAll('g')
        .data(series)
        .selectAll('text')
        .data(d => d)
        .enter().append('text')
        .attr('x', d => xScale(d[1]) + (xScale(d[0]) - xScale(d[1])) / 2)
        .attr('y', d => yScale(d.data.year) + yScale.bandwidth() / 2)
        .text(d => d3.format(",")(d[1] - d[0]))
        .attr('text-anchor', 'middle')
        .attr('dy', '0.35em')
        .style('fill', 'black')
        .style("display", function () {
          return showValues ? "block" : "none";
        });

      const textGroup = g.selectAll('g');
      const renderText = () => {
        textGroup.selectAll('text').remove();
        series.forEach((seriesData, seriesIndex) => {
          textGroup.selectAll(`.text-${seriesIndex}`)
            .data(seriesData)
            .enter().append('text')
            .attr('class', `text-${seriesIndex}`)
            .attr('x', d => xScale(d[1]) + (xScale(d[0]) - xScale(d[1])) / 2)
            .attr('y', d => yScale(d.data.year) + yScale.bandwidth() / 2)
            .text(d => d3.format(",")(d[1] - d[0]))
            .attr('text-anchor', 'middle')
            .attr('dy', '0.35em')
            .style('fill', text_color(datakeys[seriesIndex]))
            .style("display", function () {
              return showValues ? "block" : "none";
            });
        });
      };

      renderText();

      if (show_Grid) {
        g.insert('g', ':first-child')
          .attr('class', 'grid')
          .attr('transform', `translate(0, ${containerHeight - marginBottom})`)
          .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''))
          .selectAll('line')
          .attr('stroke', 'red');

        g.insert('g', ':first-child')
          .attr('class', 'grid')
          .call(d3.axisLeft(yScale).ticks(5).tickSize(-width).tickFormat(''))
          .selectAll('line')
          .attr('stroke', 'lightgrey');
      } else {
        g.selectAll('.grid').remove();
      }

      const axisLabels =
        g.append('g')
          .call(d3.axisLeft(yScale).ticks(5).tickFormat(d => typeof d === 'string' ? d.toUpperCase() : d))
          .selectAll(' text')
          .attr('stroke', 'Black')
          .style("font-size", '11px')
          .style("font-style", "normal")
          .style('cursor', 'pointer')

      let rotationAngle = 0;
      axisLabels.each(function (_, i) {
        const label = this;
        d3.select(label).on('click', function () {
          const currentRotation = rotationAngle === 0 ? -45 : 0;
          const currentAnchor = rotationAngle === 0 ? 'end' : "middle";
          axisLabels.attr('transform', `rotate(${currentRotation})`)
            .style("text-anchor", 'end')
          rotationAngle = currentRotation;
        });
      });
      var datakeys_mod

      if (YLabel.length > 0) {
        datakeys_mod = YLabel.slice(1)
      }
      else {
        datakeys_mod = datakeys
      }

      // const legendContainer = d3.select(`#legend${i}`);
      // const legendWidth = datakeys.length * 120;
      // const legendX = (containerWidth - legendWidth) / 2;
      // const legendY = height + margin.bottom - 40;
      // legendContainer.selectAll('*').remove();

      // datakeys_mod.forEach((key, i) => {
      //   const legendItemGroup = legendContainer.append('div')
      //     .attr('class', 'legend-item')

      //   legendItemGroup.append('div')
      //     .attr('class', 'legend-rect')
      //     .style('background-color', (chart_color?.length > 0 && chart_color[i + 1] != null) ? chart_color[i] : color(key))

      //   const legend = legendContainer.attr('transform', `translate(${250}, ${legendY})`);
      //   legendItemGroup.append('text')
      //     .attr('fill', color(key))
      //     .text(key)

      // });

      const legendContainer = d3.selectAll(`#legend${i}`);
      const legendWidth = datakeys.length * 120;
      const legendX = (containerWidth - legendWidth) / 2;
      const legendY = height + margin.bottom - 40;
      legendContainer.selectAll('*').remove(); // Clear previous legend content
      
      datakeys_mod.forEach((key, i) => {
        const legendItemGroup = legendContainer.append('div')
          .attr('class', 'legend-item')
          .style('display', 'flex')
          .style('align-items', 'center')
          .style('margin-right', '10px'); // Add some space between legend items
      
        legendItemGroup.append('div')
          .attr('class', 'legend-rect')
          .style('width', '13px')
          .style('height', '13px')
          .style('background-color', () => {
            var colorToUse = (chart_color?.length > 0 ? (chart_color[i] != undefined) ? chart_color[i] : chart_color[i + 1] : color(key));
            return colorToUse;
          })
          .style('margin-right', '5px'); // Space between rectangle and text
      
        legendItemGroup.append('text')
          .attr('class', 'legend-text')
          .style('color', 'green')
          .text(key);
      });

      if (enabledTable) {
        console.log("439");
        show_table_fn(true)
      }
      else {
        show_table_fn(false)
      }
      var deltaY
      function multi_mouseover(event, d) {
        d3.selectAll(`#tooltip${i}`).remove()
        if (mouseoverEnabled) {
          if (mouseoverSwitchType) {
            d3.selectAll(`.tooltip`).remove()
            var totalValue = d3.sum(datakeys.map(key => d.data[key]));
            d3.select(this)
            let tooltipContent = `<span style="color:red;">${barLabel}:</span> ${d.data.year}<br>`;
            datakeys.forEach(key => {
              tooltipContent += `<span style="color:red;">${key}:</span> <span style="color:black;">${d.data[key]}</span><br>`;
            });
            tooltipContent += `<span style="color:red;">Total:</span> <span style="color:black;">${totalValue}</span>`;
            const tooltip = d3.select(fullScreen_enabled ? `#my_dataviz${i}` : 'body')
              .append("div")
              .style("opacity", 0)
              .attr("class", "tooltip")
              .style("position", "absolute")
              .style("background-color", "white")
              .style("border", "solid")
              .style("border-width", "1px")
              .style("border-radius", "5px")
              .style("padding", "10px");

            tooltip
              .html(tooltipContent)
              .style("opacity", 1)
              .style("background-color", "white")
              .style("color", "black")
              .style("border", "solid")
              .style("border-width", "1px")
              .style("border-radius", "5px")
              .style("padding", "10px")
              .style("left", (fullScreen_enabled ? event.offsetX + 40 : event.pageX + 40) + "px") // Adjust the offset as needed
              .style("top", (fullScreen_enabled ? event.offsetY - 10 : event.pageY - 10) + "px");
            tooltip.style("opacity", 1);

          }
          else {
            d3.selectAll(`.tooltip`).remove()
            var subgroupName = d3.select(this.parentNode).datum().key;
            var subgroupValue = d.data[subgroupName];
            const tooltip = d3.select(`#my_dataviz${i}`)
              .append("div")
              .style("opacity", 0)
              .attr("class", "tooltip")
              .style("position", "absolute")
              .style("background-color", "white")
              .style("border", "solid")
              .style("border-width", "1px")
              .style("border-radius", "5px")
              .style("padding", "10px");

            tooltip
              .html("Label: " + d.data.year + "<br>" + "Value: " + subgroupValue)
              .style("opacity", 1)
              .style("background-color", "white")
              .style("color", "black")
              .style("border", "solid")
              .style("border-width", "1px")
              .style("border-radius", "5px")
              .style("padding", "10px")
              .style("left", (event.offsetX + 30) + "px")
              .style("top", (event.offsetY) + "px");
            tooltip.style("opacity", 1);
          }
        }
      }
      function multi_mouseout() {
        const tooltip = d3.selectAll(`#tooltip${i}`).remove()
        tooltip.transition().duration(500)
          .style("opacity", 0);
      }

      function multi_mouseleave() {
        d3.select(this)
          .attr('class', 'stack-group')
          .style('fill-opacity', 1)
          .style('stroke', 'none');
        tooltip
          .style("opacity", 0)
        d3.selectAll(`.tooltip`).remove()
      }

      function zoomed(event) {
        deltaY = event.sourceEvent.deltaY
        const factor = 1 + deltaY / containerHeight;
        const newYDomain = yScale.domain().map(d => d);
        const newYScale = yScale.domain(newYDomain);
        const newBarHeight = temp_barHeight / event.transform.k;
        yScale.range([containerHeight - marginBottom, marginTop].map(d => event.transform.applyY(d)));

        g.selectAll('*').remove()
        g.selectAll('g')
          .data(series)
          .enter().append('g')
          .attr('fill', d => color(d.key))
          .selectAll('rect')
          .data(d => d)
          .enter().append('rect')
          .attr('y', d => yScale(d.data.year))
          .attr('x', d => xScale(d[0]))
          .attr('width', d => xScale(d[1]) - xScale(d[0]))
          .attr('height', yScale.bandwidth())
          .style('cursor', 'pointer')
          .on("mouseover", multi_mouseover)
          .on("mousemove", multi_mouseover)
          .on("mouseleave", multi_mouseleave)
          .on("mouseout", multi_mouseout)

        const axisLabels =
          g.append('g')
            .call(d3.axisLeft(yScale).ticks(5).tickFormat(d => typeof d === 'string' ? d.toUpperCase() : d))
            .selectAll(' text')
            .attr('stroke', 'Black')
            .style("font-size", '11px')
            .style("font-style", "normal")

        let rotationAngle = 0;
        axisLabels.each(function (_, i) {
          const label = this;
          d3.select(label).on('click', function () {
            const currentRotation = rotationAngle === 0 ? -45 : 0;
            const currentAnchor = rotationAngle === 0 ? 'end' : "middle";
            axisLabels.attr('transform', `rotate(${currentRotation})`)
              .style("text-anchor", 'end')
            rotationAngle = currentRotation;
          });
        })


        g.selectAll('g')
          .data(series)
          .selectAll('text')
          .data(d => d)
          .enter().append('text')
          .attr('x', d => xScale(d[1]) + (xScale(d[0]) - xScale(d[1])) / 2)
          .attr('y', d => yScale(d.data.year) + yScale.bandwidth() / 2)
          .text(d => d3.format(",")(d[1] - d[0]))
          .attr('text-anchor', 'middle')
          .attr('dy', '0.35em')
          .style('fill', 'black')
          .style("display", function () {
            return showValues ? "block" : "none";
          });

        if (show_Grid) {
          g.insert('g', ':first-child')
            .attr('class', 'grid')
            .attr('transform', `translate(0, ${containerHeight - marginBottom})`)
            .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''))
            .selectAll('line')
            .attr('stroke', 'red');

          g.insert('g', ':first-child')
            .attr('class', 'grid')
            .call(d3.axisLeft(yScale).ticks(5).tickSize(-width).tickFormat(''))
            .selectAll('line')
            .attr('stroke', 'lightgrey');

        } else {
          g.selectAll('.grid').remove();
        }

        if (showLine) {
          createLineChart(data, datakeys, props.chart_color);
        }

        function createLineChart(data, keys, colors) {
          const stack = d3.stack().keys(keys);
          const series = stack(mod_data);

          keys.forEach((key, index) => {
            const lineGenerator = curved_line ?
              d3.line().curve(d3.curveCardinal) :
              d3.line();

            lineGenerator.x(d => xScale(d[1]))
              .y(d => yScale(d.data.year) + yScale.bandwidth() / 2);

            g.append('path')
              .datum(series[index])
              .attr('class', `line-${key}`)
              .attr('d', lineGenerator)
              .attr('fill', 'none')
              .attr('stroke', 'black')
              .attr('stroke-width', 2.5)
              .attr('stroke-dasharray', '5,5');

            series[index].forEach((point, pointIndex) => {
              const x = xScale(point[1]);
              const y = yScale(point.data.year) + yScale.bandwidth() / 2;

              if (!show_Square) {
                g.append('rect')
                  .datum(point)
                  .attr('class', `node-${key}`)
                  .attr('x', x - 5)
                  .attr('y', y - 5)
                  .attr('width', 10)
                  .attr('height', 10)
                  .attr('fill', "pink")
                  .on('mouseover', function (event, d) {
                    const tooltipX = event.pageX + 10;
                    const tooltipY = event.pageY - 50;
                    let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                    if (tooltip.empty()) {
                      tooltip = d3.select(fullScreen_enabled ? `#my_dataviz${i}` : 'body').append('div')
                        .attr('id', 'tooltip')
                        .attr('class', 'tooltip')
                        .style('position', 'absolute')
                        .style('background-color', 'white')
                        .style('border', '1px solid black')
                        .style('padding', '5px')
                        .style('opacity', 0);
                    }
                    tooltip.html(`Value: ${d[1] - d[0]}, ${barLabel}: ${d.data.year}`)
                      .style("left", ` ${fullScreen_enabled ? event.offsetX - 10 : event.pageX - 10}px`)
                      .style("top", `${fullScreen_enabled ? event.offsetY - 50 : event.pageY - 50}px`)
                      .style('opacity', 0.9).raise()
                  })
                  .on('mouseout', function (event, d) {
                    d3.select('#tooltip')
                      .style('opacity', 0);
                  });
              } else {
                g.append('circle')
                  .datum(point)
                  .attr('class', `node-${key}`)
                  .attr('cx', x)
                  .attr('cy', y)
                  .attr('r', 5)
                  .attr('fill', "black")
                  .on('mouseover', function (event, d) {
                    const tooltipX = event.pageX + 10;
                    const tooltipY = event.pageY - 50;
                    let tooltip = d3.selectAll(fullScreen_enabled ? `#tooltip${i}` : '#tooltip');
                    if (tooltip.empty()) {
                      tooltip = d3.select(fullScreen_enabled ? `#my_dataviz${i}` : 'body').append('div')
                        .attr('id', 'tooltip')
                        .attr('class', 'tooltip')
                        .style('position', 'absolute')
                        .style('background-color', 'white')
                        .style('border', '1px solid black')
                        .style('padding', '5px')
                        .style('opacity', 0);
                    }
                    tooltip.html(`<div>Value: ${d[1] - d[0]}</div><div>${barLabel}: ${d.data.year}</div>`)
                      .style("left", ` ${fullScreen_enabled ? event.offsetX - 10 : event.pageX - 10}px`)
                      .style("top", `${fullScreen_enabled ? event.offsetY - 50 : event.pageY - 50}px`)
                      .style('opacity', 0.9);
                  })
                  .on('mouseout', function (event, d) {
                    d3.select('#tooltip')
                      .style('opacity', 0);
                  });
              }
            });
          });
        }
      }

      const handleResetButtonClick = () => {
        const newYDomain = yScale.domain().map(d => d);
        const newYScale = yScale.domain(newYDomain).range([containerHeight - marginBottom, marginTop]);
        const newXDomain = [0, d3.max(mod_data, d => d3.sum(datakeys.map(key => d[key])))];
        const newXScale = xScale.domain(newXDomain).range([0, containerWidth - marginLeft - marginRight]);
        g.selectAll('*').remove();
        g.selectAll('g')
          .data(series)
          .enter()
          .append('g')
          .attr('fill', d => color(d.key))
          .selectAll('rect')
          .data(d => d)
          .enter()
          .append('rect')
          .attr('y', d => newYScale(d.data.year))
          .attr('x', d => newXScale(d[0]))
          .attr('width', d => newXScale(d[1]) - newXScale(d[0]))
          .attr('height', yScale.bandwidth())
          .style('cursor', 'pointer')
          .on("mouseover", multi_mouseover)
          .on("mousemove", multi_mouseover)
          .on("mouseout", multi_mouseout)
          .on("mouseleave", multi_mouseleave)
        g.append('g')
          .call(d3.axisLeft(newYScale).ticks(5).tickFormat(d => typeof d === 'string' ? d.toUpperCase() : d))
          .selectAll('.domain, text')
          .attr('stroke', 'Black')
          .style("font-size", '11px');

        g.append('g')
          .attr('transform', `translate(0, ${containerHeight - marginTop})`)
          .call(d3.axisBottom(xScale))
          .selectAll('text')
          .style('text-anchor', 'middle')
          .style('font-size', '14px')
          .attr('fill', 'black');
        g.selectAll('g')
          .data(series)
          .selectAll('text')
          .data(d => d)
          .enter()
          .append('text')
          .attr('x', d => newXScale(d[1]) + (newXScale(d[0]) - newXScale(d[1])) / 2)
          .attr('y', d => newYScale(d.data.year) + yScale.bandwidth() / 2)
          .text(d => d3.format(",")(d[1] - d[0]))
          .attr('text-anchor', 'middle')
          .attr('dy', '0.35em')
          .style('fill', 'black')
          .style("display", function () {
            return showValues ? "block" : "none";
          });
        if (showLine) {
          createLineChart(data, datakeys, props.chart_color);
        }

        function createLineChart(data, keys, colors) {
          const stack = d3.stack().keys(keys);
          const series = stack(mod_data);
          keys.forEach((key, index) => {
            const lineGenerator = curved_line ?
              d3.line().curve(d3.curveCardinal) :
              d3.line();
            lineGenerator.x(d => xScale(d[1]))
              .y(d => yScale(d.data.year) + yScale.bandwidth() / 2);

            g.append('path')
              .datum(series[index])
              .attr('class', `line-${key}`)
              .attr('d', lineGenerator)
              .attr('fill', 'none')
              .attr('stroke', 'black')
              .attr('stroke-width', 2.5)
              .attr('stroke-dasharray', '5,5');

            series[index].forEach((point, pointIndex) => {
              const x = xScale(point[1]);
              const y = yScale(point.data.year) + yScale.bandwidth() / 2;

              if (!show_Square) {
                g.append('rect')
                  .datum(point)
                  .attr('class', `node-${key}`)
                  .attr('x', x - 5)
                  .attr('y', y - 5)
                  .attr('width', 10)
                  .attr('height', 10)
                  .attr('fill', "pink")
                  .on('mouseover', function (event, d) {
                    const tooltipX = event.pageX + 10;
                    const tooltipY = event.pageY - 50;
                    let tooltip = d3.select('#tooltip');
                    if (tooltip.empty()) {
                      tooltip = d3.select('body').append('div')
                        .attr('id', 'tooltip')
                        .attr('class', 'tooltip')
                        .style('position', 'absolute')
                        .style('background-color', 'white')
                        .style('border', '1px solid black')
                        .style('padding', '5px')
                        .style('opacity', 0);
                    }

                    tooltip.html(`Value: ${d[1] - d[0]}, ${barLabel}: ${d.data.year}`)
                      .style("left", `${tooltipX}px`)
                      .style("top", `${tooltipY}px`)
                      .style('opacity', 0.9);
                  })
                  .on('mouseout', function (event, d) {
                    d3.select('#tooltip')
                      .style('opacity', 0);
                  });
              }
              else {
                g.append('circle')
                  .datum(point)
                  .attr('class', `node-${key}`)
                  .attr('cx', x)
                  .attr('cy', y)
                  .attr('r', 5)
                  .attr('fill', "black")
                  .on('mouseover', function (event, d) {
                    const tooltipX = event.pageX + 10;
                    const tooltipY = event.pageY - 50;
                    let tooltip = d3.select('#tooltip');
                    if (tooltip.empty()) {
                      tooltip = d3.select('body').append('div')
                        .attr('id', 'tooltip')
                        .attr('class', 'tooltip')
                        .style('position', 'absolute')
                        .style('background-color', 'white')
                        .style('border', '1px solid black')
                        .style('padding', '5px')
                        .style('opacity', 0);
                    }

                    tooltip.html(`Value: ${d[1] - d[0]}, ${barLabel}: ${d.data.year}`)
                      .style("left", `${tooltipX}px`)
                      .style("top", `${tooltipY}px`)
                      .style('opacity', 0.9);
                  })
                  .on('mouseout', function (event, d) {
                    d3.select('#tooltip')
                      .style('opacity', 0);
                  });
              }
            });
          });

          if (show_Grid) {
            g.insert('g', ':first-child')
              .attr('class', 'grid')
              .attr('transform', `translate(0, ${containerHeight - marginBottom})`)
              .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''))
              .selectAll('line')
              .attr('stroke', 'red');

            g.insert('g', ':first-child')
              .attr('class', 'grid')
              .call(d3.axisLeft(yScale).ticks(5).tickSize(-width).tickFormat(''))
              .selectAll('line')
              .attr('stroke', 'lightgrey');
          } else {
            g.selectAll('.grid').remove();
          }
        }
      };
      document.getElementById(`togglereset-${i}`).addEventListener('click', function (event) {
        handleResetButtonClick();
      });
    }

  }, [containerWidth, BarWidth, containerHeight, textColorBar, showValues, mouseoverEnabled, mouseoverSwitchType, showGridEnabled, temp_containerWidth, fullScreen_enabled, temp_containerHeight, sortData, showLine, svgHeight, enable_table, curved_line, show_Square, SortArr, text_color_arr, enabledTable]);

  const handleMenuClick = (e) => {
    setShowOptions(!showOptions);
  };

  const show_table_fn = async (val) => {
    const fieldNames = Object.keys(data[0]).filter(key => key !== "_id");
    if (val) {
      await tabulate(data, fieldNames)
    }
    else {
      d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
    }
  }

  const tabulate = async (data, columns, y_axis_name) => {
    const header = columns;
    var data_exist;
    if (data !== undefined) {
      data_exist = data;
    } else {
      data_exist = data;
    }
    var tableContainer = document.getElementById(`tableContainer${i}`);
    tableContainer.innerHTML = "";

    var table = d3.select(`#tableContainer${i}`)
      .attr("class", "table-responsive")
      .append("table")
      .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth - 12}px`);
    var thead = table.append("thead");
    var tbody = table.append("tbody");
    d3.select(tableContainer)
      .attr('class', 'table_body')
      .style("overflow-y", "scroll")
      .style("overflow-x", "hidden");
    thead.append("tr")
      .selectAll("th")
      .data(header)
      .enter()
      .append("th")
      .text(function (column) { return column; })
      .attr("style", "text-align: center")
      .style('color', 'black')
    var rows = tbody.selectAll("tr")
      .data(data_exist)
      .enter()
      .append("tr");
    var cells = rows.selectAll("td")
      .data(function (row) {
        return columns.map(function (column) {
          return { column: column, value: row[column] };
        });
      })
      .enter()
      .append("td")
      .attr("class", function (d) { return "cell " + d.column; })
      .html(function (d) { return d.value; })
      .attr("style", "text-align: center")
      .style('color', 'black')
    return table;
  }

  const handleSortIconClick = (e) => {
    setShowSortoption(!sortShowOptions)
  };
  const handlesort = () => {
    var chart_id = i;
    dispatch(sortFunc({ data, arrValues, chart_id }));
  }

  const handlesortdesc = () => {
    var chart_id = i;
    dispatch(sortDescending({ data, arrValues, chart_id }));
  }
  const handleSortDefault = () => {
    dispatch(sortHorstack({ data: chart_data, chart_id: i }));
    setSortData([...chart_data]);
  };

  const handleCheckboxChange = (e, value) => {
    if (e.target.checked) {
      setarrValues((prevData) => [
        ...prevData,
        value
      ]);
    } else {
      setarrValues(arrValues.filter((val) => val !== value));
    }
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((val) => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };


  return (
    <div>
      <div id={`tooltip${i}`} style={{ position: 'absolute', opacity: 0, background: 'white', padding: '10px', borderRadius: '5px' }}></div>
      <div id={`my_dataviz${i}`} onMouseLeave={() => { setShowOptions(false); setShowSortoption(false); }}>
        <svg width={(fullScreen_enabled ? temp_containerWidth : containerWidth)} height={(fullScreen_enabled ? temp_containerHeight : containerHeight)} >
          <g></g>
        </svg>
      </div>
      <div className="legend" id={`legend${i}`} style={{ position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'centre', marginLeft: containerWidth / 3, marginTop: enable_table ? (fullScreen_enabled ? '-290px' : '-230px') : '-40px', boxShadow: 'none' }}></div>

      {showOptions && (
        <div
          className="download-options"
          onMouseLeave={() => setShowOptions(false)}
          style={{
            position: 'absolute',
            top: '46px',
            right: '110px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            color: '#000080',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            cursor: 'pointer',
            justifyContent: 'center'
          }}
          onClick={(e) => e.stopPropagation()}
          onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
        >
          <p onClick={() => dispatch(handleDownloadBar('0', datakeys_name, datakeys, data))}>Download as CSV</p>
          <p onClick={() => dispatch(imgDownloadSvg(`my_dataviz${i}`))}>Download as SVG</p>
            <p onClick={() => dispatch(imgDownloadPng(i))} className='mt-1'>Download as PNG</p>
        </div>
      )}
      <span onMouseOver={() => { handleSortIconClick(); setShowOptions(false); }} >
        <i
          className="bx bx-sort"
          style={{
            cursor: 'pointer',
            fontSize: '20px',
            position: 'absolute',
            top: '9px',
            right: '112px',
            zIndex: '1',
            color: '#6666B2',
          }}
        ></i>
      </span>

      <i className="bx bx-reset"
        style={{
          cursor: 'pointer',
          fontSize: '20px',
          width: '35px',
          position: 'absolute',
          top: '9px',
          right: '262px',
          zIndex: '1',
          color: '#6666B2',
        }}

        id={`togglereset-${i}`}
        title="Reset"
      ></i>
      <span onMouseOver={() => { handleMenuClick(); setShowSortoption(false); }} >
        <i
          className="bx bx-download"
          style={{
            cursor: 'pointer',
            fontSize: '20px',
            position: 'absolute',
            top: '10px',
            right: '80px',
            zIndex: '1',
            color: '#6666B2',
          }}
          title="Download"
        ></i>
      </span>
      {sortShowOptions && (
        <div
          onMouseOver={() => setShowSortoption(true)}
          className="download-options"
          style={{
            position: 'absolute',
            top: '45px',
            right: '116px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            color: '#000080',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            boxShadow: 'none',
            maxHeight: (`${containerHeight - 120}px`),
            overflow: 'auto',
            zIndex: '5',
          }}
          title="Sort"
        >
          {selectedValues.length > 0 && (
            <div style={{ marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button className='btn btn-sm btn-primary me-2' onClick={handlesort}>
                  Ascending
                </button>
                <button className='btn btn-sm btn-primary me-2' onClick={handlesortdesc}>
                  Descending
                </button>
                <button className='btn btn-sm btn-primary me-2' onClick={handleSortDefault}>
                  Default
                </button>
              </div>
            </div>
          )}
          <div>
            {datakeys.map((value) => (
              <div key={value} style={{ marginBottom: '10px', boxShadow: 'none' }}>
                <label>
                  <input
                    type="checkbox"
                    value={value}
                    checked={selectedValues.includes(value)}
                    onChange={(e) => handleCheckboxChange(e, value)}
                  />
                  {value.toUpperCase()}
                </label>
              </div>
            ))}

          </div>
        </div>
      )}
      {isLoading &&
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>}
      {enabledTable ? (
        <>
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: '#fff',
            height: (fullScreen_enabled ? '240px' : '200px')

          }} id={`tableContainer${i}`}>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default HorizontalStackChart;
