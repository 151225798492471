import React, { useEffect, useState } from 'react'
import {
    Row, Col, FormGroup, Card, CardBody, Container, Alert, Label, Form, Progress,
    FormFeedback, Button, Spinner,
    Badge,
} from "reactstrap";
import { Link, useHistory } from 'react-router-dom';
import { Input, Space, Image } from 'antd';//Select,
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { setIncidentsInfo } from '../../../../store/incidentReport/incdreportslice';



const RaisedInfo = (props) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))


    const incidentReport = useSelector(state => state.incdreportslice)
    const incidentsInfo = incidentReport.incidentsInfo


    const gotoBack = () => {
        sessionStorage.removeItem("incidentInfo")
        dispatch(setIncidentsInfo({}))
        props.history.push('/raised_incd')
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Breadcrumbs
                        title="Submitted Incident"
                        breadcrumbItem="Manage Incidents"
                        isBackButtonEnable={true}
                        gotoBack={() => gotoBack()}
                    />
                    {
                        Object.keys(incidentsInfo).length > 0 ?
                            <Card style={{ height: '90vh', overflow: 'auto' }} >
                                <CardBody className="pt-0" >
                                    <hr />
                                    <Row className='w-100 ' style={{ backgroundColor: '' }}>
                                        <Col style={{ borderRight: '1px solid black' }}>
                                            <div >
                                                <section className="incident-details">
                                                    <Row>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary"> Incident Type</div>
                                                            <div className="font-size-15 text-dark">{incidentsInfo.incd_type?.incd_type_name}</div>
                                                        </Col>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Location</div>
                                                            <div className="font-size-15 text-dark">{incidentsInfo.incd_h_location.node_name}</div>
                                                        </Col>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Status</div>
                                                            <div className="font-size-15 text-dark">
                                                                {(() => {
                                                                    let statusLabel = "";
                                                                    let badgeVariant = "";


                                                                    if (incidentsInfo.incd_rejec_by) {
                                                                        statusLabel = "Rejected";
                                                                        badgeVariant = "danger"; // Red
                                                                    }
                                                                    else if (incidentsInfo.task_users.length < 1) {
                                                                        statusLabel = "Not Allocated";
                                                                        badgeVariant = "secondary"; // Light Grey
                                                                    }



                                                                    switch (incidentsInfo.task_status) {
                                                                        case "0":
                                                                            statusLabel = "Not Started";
                                                                            badgeVariant = "secondary"; // Grey
                                                                            break;
                                                                        case "1":
                                                                            statusLabel = "In Progress";
                                                                            badgeVariant = "warning"; // Blue
                                                                            break;
                                                                        case "2":
                                                                            statusLabel = "Completed";
                                                                            badgeVariant = "primary"; // Green
                                                                            break;
                                                                        case "3":
                                                                            statusLabel = "Closed";
                                                                            badgeVariant = "success"; // Dark Grey/Black
                                                                            break;
                                                                        case "4":
                                                                            statusLabel = "Overdue";
                                                                            badgeVariant = "danger"; // Red
                                                                            break;
                                                                        case "5":
                                                                            statusLabel = "Reopen";
                                                                            badgeVariant = "warning"; // Yellow/Orange
                                                                            break;

                                                                    }

                                                                    return (
                                                                        <Badge className={"badge-soft-" + badgeVariant}>
                                                                            {statusLabel}
                                                                        </Badge>
                                                                    );
                                                                })()}
                                                            </div>
                                                            {/* <div className="font-size-15 text-dark">{incidentsInfo.incd_status === "0" ? "Not Allocated" : incidentsInfo.incd_status === "1" ? "Rejected" : incidentsInfo.incd_status === "2" ? "Approved" : null}</div> */}
                                                        </Col>
                                                        {/* <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Incident ID</div>
                                                            <div className="font-size-15 text-dark">{incidentsInfo.incd_type.incd_type_id}</div>
                                                        </Col> */}
                                                    </Row>

                                                    <Row>
                                                        <Col className="col-12 mt-3 mb-1" >
                                                            <div className="text-secondary font-size-14" > Observation </div>
                                                            <p className="text-dark font-size-15 " style={{ lineHeight: 1.5 }} >{incidentsInfo.action}</p>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        incidentsInfo.attachments.length > 0 &&
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <div className="text-secondary font-size-14" > Attachments </div>
                                                                    <div className="my-2 d-flex flex-row gap-2">




                                                                        {incidentsInfo.attachments.map((ele, idx) => {
                                                                            const fileExtension = ele.originalname.split('.').pop().toLowerCase();
                                                                            const isVideo = fileExtension === 'mp4' || fileExtension === 'avi' || fileExtension === 'mov'; // Add other video formats as needed

                                                                            return isVideo ? (
                                                                                <video
                                                                                    key={idx}
                                                                                    height="100px"
                                                                                    controls
                                                                                    style={{ borderRadius: '10px' }}
                                                                                    className="border border-secondary border-opacity-50 m-0"
                                                                                >
                                                                                    <source src={authUser.client_info[0].base_url + ele.originalname} type={`video/${fileExtension}`} />
                                                                                    Your browser does not support the video tag.
                                                                                </video>
                                                                            ) : (
                                                                                <Image
                                                                                    key={idx}
                                                                                    src={authUser.client_info[0].base_url + ele.originalname}
                                                                                    alt="caption"
                                                                                    height="80px"
                                                                                    style={{ borderRadius: '10px' }}
                                                                                    className="border border-secondary border-opacity-50 m-0"
                                                                                />
                                                                            );
                                                                        })}



                                                                        {/* {
 incidentsInfo.attachments.map((ele, idx) => {
 return (
 <Image
 key={idx}
 src={authUser.client_info[0].base_url + ele.originalname}
 alt="caption"
 height="80px"
 style={{ borderRadius: '10px' }}
 className="border border-secondary border-opacity-50 m-0"
 />
 )

 })
 } */}

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                        </>

                                                    }

                                                    <Row>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Submitted by</div>
                                                            <div className="font-size-15 text-dark">{incidentsInfo.incd_annyms_status ? "Anonymous User" : incidentsInfo.incd_submitted_by.user_name}</div>
                                                        </Col>
                                                        <Col className="col-auto me-2" >
                                                            <div className="font-size-14 text-secondary">Submitted on</div>
                                                            <div className="font-size-15 text-dark"> {new Intl.DateTimeFormat("en-GB", {
                                                                month: "short",
                                                                day: "2-digit",
                                                                year: "numeric",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit", // Optional: include this if you want seconds
                                                                hour12: true
                                                            }).format(new Date(incidentsInfo.createdAt))}</div>
                                                        </Col>
                                                        {
                                                            incidentsInfo.task_status === "3" &&
                                                            <>
                                                                <Col className="col-auto me-2" >
                                                                    <div className="font-size-14 text-secondary">Issue Status</div>
                                                                    {/* <div className="font-size-15 text-success">Closed</div> */}
                                                                    <Badge className={"font-size-12 badge-soft-success"}>Closed</Badge>
                                                                </Col>
                                                                <Col className="col-auto me-2" >
                                                                    <div className="font-size-14 text-secondary">Email Status</div>
                                                                    <Badge className={"font-size-12 badge-soft-info"}>Email sent to the Incident raised user</Badge>

                                                                    {/* <div className="font-size-15 text-success">Email sent to the Incident raised user</div> */}
                                                                </Col>
                                                                <Col className="col-auto me-2" >
                                                                    <div className="font-size-14 text-secondary">Email sent on:</div>
                                                                    <div className="font-size-15 text-dark"> {new Intl.DateTimeFormat("en-GB", {
                                                                        month: "short",
                                                                        day: "2-digit",
                                                                        year: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        second: "2-digit", // Optional: include this if you want seconds
                                                                        hour12: true
                                                                    }).format(new Date(incidentsInfo.incd_submitted_by.email_sent_on))}</div>
                                                                </Col>
                                                            </>
                                                        }

                                                    </Row>
                                                    <br />
                                                    {
                                                        incidentsInfo.incd_status === "2" ?
                                                            <>
                                                                <Row>
                                                                    <Col className="col-auto me-2" >
                                                                        <div className="font-size-14 text-secondary">Rejected Reason :</div>
                                                                        <div className="font-size-15 text-dark">{incidentsInfo.incd_rejec_reason}</div>
                                                                        {/* <Badge className={"font-size-12 badge-soft-danger"}>{incidentsInfo.incd_rejec_reason}</Badge> */}

                                                                    </Col>
                                                                    <Col className="col-auto me-2" >
                                                                        <div className="font-size-14 text-secondary">Rejected by : </div>
                                                                        <div className="font-size-15 text-dark"> {incidentsInfo.incd_rejec_by.user_name}({incidentsInfo.incd_rejec_by.email_id})</div>
                                                                    </Col>
                                                                </Row>
                                                                <br />
                                                                <Row>
                                                                    <Col className="col-auto me-2" >
                                                                        <div className="font-size-14 text-secondary">Rejected On :</div>
                                                                        <div className="font-size-15 text-dark">{new Intl.DateTimeFormat("en-GB", {
                                                                            month: "short",
                                                                            day: "2-digit",
                                                                            year: "numeric",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            second: "2-digit", // Optional: include this if you want seconds
                                                                            hour12: true
                                                                        }).format(new Date(incidentsInfo.incd_rejec_by.rejected_on))}</div>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            incidentsInfo.incd_status === "1" ?
                                                                <>
                                                                    <Row>
                                                                        <Col className="col-auto me-2" >
                                                                            <div className="font-size-14 text-secondary">Approved by : </div>
                                                                            <div className="font-size-15 text-dark"> {incidentsInfo.incd_approved_by.user_name}({incidentsInfo.incd_approved_by.email_id})</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row>
                                                                        <Col className="col-auto me-2" >
                                                                            <div className="font-size-14 text-secondary">Approved On :</div>
                                                                            <div className="font-size-15 text-dark">{new Intl.DateTimeFormat("en-GB", {
                                                                                month: "short",
                                                                                day: "2-digit",
                                                                                year: "numeric",
                                                                                hour: "2-digit",
                                                                                minute: "2-digit",
                                                                                second: "2-digit", // Optional: include this if you want seconds
                                                                                hour12: true
                                                                            }).format(new Date(incidentsInfo.incd_approved_by.approved_on))}</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <br />


                                                                </>
                                                                :
                                                                null

                                                    }

                                                    {
                                                        incidentsInfo.task_status === "6" && incidentsInfo.incd_submitted_status === "2" &&
                                                        incidentsInfo.task_user_reject_info.map((rejectInfo, index) => (
                                                            <Row key={index}>
                                                                <Col className="col-auto me-2">
                                                                    <div className="font-size-14 text-secondary">Rejected by:</div>
                                                                    <div className="font-size-15 text-dark">
                                                                        {rejectInfo.user_name} ({rejectInfo.email_id})
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-auto me-2">
                                                                    <div className="font-size-13 text-secondary mb-1">Rejected Reason</div>
                                                                    <Badge className={"font-size-12 badge-soft-danger"}>{rejectInfo.reason}</Badge>

                                                                    {/* <div className="font-size-14 text-dark"><span className='text-danger'>{rejectInfo.reason}</span></div> */}
                                                                </Col>
                                                                <Col className="col-auto me-2">
                                                                    <div className="font-size-13 text-secondary mb-1">Rejected On</div>
                                                                    <div className="font-size-14 text-dark">
                                                                        {new Intl.DateTimeFormat("en-GB", {
                                                                            month: "short",
                                                                            day: "2-digit",
                                                                            year: "numeric",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            second: "2-digit",
                                                                            hour12: true
                                                                        }).format(new Date(rejectInfo.rejected_on))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        ))
                                                    }
                                                </section>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <CardBody style={{ height: "100vh" }}>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div>Loading...</div>
                                        <Spinner className="ms-2" color="primary" />
                                    </div>
                                </CardBody>
                            </Card>
                    }


                </Container>
            </div>
        </React.Fragment>
    )
}


export default RaisedInfo