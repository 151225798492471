
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { } from "../../store/actions";
// MetisMenu
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import _ from "lodash"
import urlSocket from "../../helpers/urlSocket";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import socket from "../../pages/ActionPlan/FollowUpUserAudit/components/socket";

let screens = [];

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screensDisplay: [],
            followUpMenus: [],
            reportMenus: [],
            showAuditMenu: true,
            showActionMenu: false,
            select_menu: 1,

            dataLoaded: false

        };
    }

    async componentDidMount() {
        // await this.onSocketConnection()
        // await this.onSocketConnection(this.state.select_menu);
        await this.initMenu();
        await this.get_basicData();

        var AuthuserData = JSON.parse(sessionStorage.getItem("authUser"));
        this.setState({ AuthuserData: AuthuserData })
        const followUpMenusData = AuthuserData.config_data.follow_up_menus;
        var select_menu = sessionStorage.getItem('select_menu')
        //("Follow Up Menus Data:", followUpMenusData);
        if (followUpMenusData) {
            const followUpMenus = followUpMenusData;
            this.setState({ followUpMenus });
        }

        const reportMenusData = AuthuserData.config_data.report_menus;
        if (reportMenusData) {
            const reportMenus = reportMenusData;
            this.setState({ reportMenus });
        }
        if (select_menu !== null && select_menu !== undefined) {
            var showActionMenu
            var showAuditMenu
            var showReportMenu
            var showIncidentReportMenu
            if (Number(select_menu) === 1) {
                showAuditMenu = true
            }
            if (Number(select_menu) === 2) {
                showActionMenu = true
            }
            if (Number(select_menu) === 3) {
                showReportMenu = true
            }
            if (Number(select_menu) === 4) {
                showIncidentReportMenu = true
            }
            this.setState({
                select_menu: Number(select_menu),
                showActionMenu,
                showAuditMenu,
                showReportMenu,
                showIncidentReportMenu,
                dataLoaded: true

            })

        }
        else {
            this.setState({
                select_menu: 1,
                showActionMenu,
                showAuditMenu: true,
                showReportMenu,
                dataLoaded: true

            })
        }

    }




    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }


    handleDisconnect = () => {

        // Disconnect the socket when a specific action occurs (e.g., button click)
        socket.disconnect();
        console.log('Socket disconnected');
    };


    


    async get_basicData() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var facilities = await this.get_user_facilities(data.user_data, data.db_info)
        if (facilities === null) {
            facilities = []
        }
        console.log(facilities,'facilities')
        screens = facilities
        sessionStorage.setItem('user_facilities', JSON.stringify(screens))
        this.setState({
            screensDisplay: screens,
        })
    }

    get_user_facilities = async (user_data, db_info) => {
        try {
            const response = await urlSocket.post('cog/get-role-facilities', {
                encrypted_db_url: db_info.encrypted_db_url,
                user_data: user_data,
            });

            if (response.data.response_code === 500) {
                return response.data.data[0].facilities; // Return the response instead of 'facilities'
            }
        } catch (error) {
            return null; // Return null or an appropriate error indicator
        }
    };



    async initMenu() {
        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var redirect = sessionStorage.getItem("redirect")
        if (ul != null) {
            var items = ul.getElementsByTagName("a");

            setTimeout(() => {
                for (var i = 0; i < items.length; ++i) {
                    let url = this.props.location.pathname.replace(/\d+/g, '')
                    let pathname = items[i].pathname.replace(/\d+/g, '')
                    if (url === pathname) {
                        matchingMenuItem = items[i];
                        break;
                    }
                    if (url == "/createroles") {
                        matchingMenuItem = items[1];
                        break;
                    }
                    if (url == "/adusrs") {
                        matchingMenuItem = items[2];
                        break;
                    }
                    if (url == "/edtusr") {
                        matchingMenuItem = items[2];
                        break;
                    }
                    if (url == '/crttmplt') {
                        matchingMenuItem = items[4];
                        break;
                    }
                    if (url == "/edtpblhtempt") {
                        matchingMenuItem = items[4];
                        break;
                    }
                    if (url == "/pblhcfg") {
                        matchingMenuItem = items[4];
                        break;
                    }
                    if (url == '/pbdrpt') {
                        matchingMenuItem = items[5];
                        break;
                    }
                    if (url == '/location') {
                        matchingMenuItem = items[5];
                        break;
                    }
                    if (url == '/chkpntrprt') {
                        matchingMenuItem = items[redirect == 1 ? 6 : 5];
                        break;
                    }
                    if (url == "/usrenpdts") {
                        matchingMenuItem = items[6];
                        break;
                    }
                    if (url == '/viewcpts') {
                        matchingMenuItem = items[6];
                        break;
                    }
                    if (url == '/enpcpts') {
                        matchingMenuItem = items[6];
                        break;
                    }
                }
                if (matchingMenuItem) {
                    this.activateParentDropdown(matchingMenuItem);
                }

            }, 1000);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };


    SidebarContent = (props) => {
            console.log(screens,'screens')
        
        return (
            <>
            
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {
                            this.state.select_menu === 1 ?
                            screens.map((data, index) => (
                                <li key={index}>
                                <Link to={data.url} className="waves-effect text-dark bg-light my-1 mx-2" style={{ padding: "0.45rem 1rem", borderRadius: 20 }}>
                                    <span>{data.interfacename}</span>
                                </Link>
                            </li>
                        ))
                        :
                        null
                        }
                        {/* {
                            this.state.select_menu === 1 ?
                                screens
                                    .filter(data => Number(data.id) >= 1 && Number(data.id) <= 8)
                                    .map((data, index) => (
                                        <li key={index}>
                                            <Link to={data.url} className="waves-effect text-dark bg-light my-1 mx-2" style={{ padding: "0.45rem 1rem", borderRadius: 20 }}>
                                                <span>{data.interfacename}</span>
                                            </Link>
                                        </li>
                                    ))
                                :
                                this.state.select_menu === 2 ?
                                    screens
                                        .filter(data => Number(data.id) > 8 && Number(data.id) <= 10)
                                        .map((data, index) => (
                                            <li key={index}>
                                                <Link to={data.url} className="waves-effect text-dark bg-light my-1 mx-2" style={{ padding: "0.45rem 1rem", borderRadius: 20 }}>
                                                    <span>{data.interfacename}</span>
                                                </Link>
                                            </li>
                                        ))
                                    :
                                    this.state.select_menu === 3 ?
                                    screens
                                    .filter(data => Number(data.id) > 10 && Number(data.id) <= 12)
                                    .map((data, index) => (
                                        <li key={index}>
                                            <Link to={data.url} className="waves-effect text-dark bg-light my-1 mx-2" style={{ padding: "0.45rem 1rem", borderRadius: 20 }}>
                                                <span>{data.interfacename}</span>
                                            </Link>
                                        </li>
                                    ))
                                    :
                                    this.state.select_menu === 4 ?
                                    screens
                                    .filter(data => Number(data.id) > 12)
                                    .map((data, index) => (
                                        <li key={index}>
                                            <Link to={data.url} className="waves-effect text-dark bg-light my-1 mx-2" style={{ padding: "0.45rem 1rem", borderRadius: 20 }}>
                                                <span>{data.interfacename}</span>
                                            </Link>
                                        </li>
                                    ))
                                    :
                                    null

                        } */}




                        {/* {
                            screens.map((data, index) => {
                                return (
                                <li key={index}>
                                    {
                                        this.state.select_menu === 1 ?
                                        Number(data.id === "1" &&  )

                                    }
                                    <Link to={data.url} className="waves-effect text-dark bg-light my-1 mx-2" style={{ padding: "0.45rem 1rem", borderRadius:20 }} >
                                        <span>{data.interfacename}</span>
                                    </Link>
                                </li>)
                            })
                        } */}
                    </ul>
                </div>
            </>
        )
    };


    isActive = (child) => {
        for (let index = 0; index < child.length; index++) {
            if (window.location.pathname.split("/").pop() === child[index]) {
                return '#d9e021'
            }
            else {
                return null
            }

        }
    }

    AuditVistaSection = (event, select_menu) => {
        //(event)
        sessionStorage.setItem('select_menu', select_menu)
        console.log(select_menu,'select_menu')
        if (select_menu === 1) {
            this.setState({
                showAuditMenu: true,
                showActionMenu: false,
                showReportMenu: false,
                showIncidentReportMenu : false,
                select_menu: select_menu,
                dataLoaded: false
            }, () => { this.setState({ dataLoaded: true }) })
        }
        if (select_menu === 2) {

            this.setState({
                showActionMenu: true,
                showAuditMenu: false,
                showReportMenu: false,
                showIncidentReportMenu : false,
                select_menu: select_menu,
                dataLoaded: false
            }, () => { this.setState({ dataLoaded: true }) })
        }
        if (select_menu === 3) {
            this.setState({
                showReportMenu: true,
                showAuditMenu: false,
                showActionMenu: false,
                showIncidentReportMenu : false,
                select_menu: select_menu,
                dataLoaded: false
            }, () => { this.setState({ dataLoaded: true }) })
        }
        if (select_menu === 4) {
            this.setState({
                showReportMenu: false,
                showAuditMenu: false,
                showActionMenu: false,
                showIncidentReportMenu : true,
                select_menu: select_menu,
                dataLoaded: false
            }, () => { this.setState({ dataLoaded: true }) })
        }
    }



    logOut() {

        sessionStorage.clear()
        this.props.history.push({ pathname: '/login' })
    }


    render() {

        var data = JSON.parse(sessionStorage.getItem("authUser"));
        const { followUpMenus } = this.state;
        const { reportMenus } = this.state;
        const authUserData = JSON.parse(sessionStorage.getItem("authUser"));
        const allowData1 = authUserData.client_info[0].allowFollowup
        const showFollowUpIcon = authUserData && authUserData.client_info[0].allowFollowup;
        const showReportIcon = authUserData && authUserData.client_info[0].allowReport;
        const showIncidentReportMenu = authUserData && authUserData.client_info[0].allowIncidentReport;

        if (this.state.dataLoaded) {
            return (
                <div>
                    <div className="vertical-menu" style={{ paddingTop: 0 }} >

                        <div className="d-flex flex-row" style={{ height: "100vh", position: "relative", }}>

                            {/* Level 1 menu */}
                            <div
                                style={{
                                    height: "100vh",
                                    width: "fit-content",
                                    position: "relative",
                                }}
                                className="bg-primary"
                            >
                                <div className="d-flex flex-column justify-content-center " style={{ height: "100vh", background: "white !important" }}>

                                    <ul className="nav-link text-center" style={{ background: "white !important" }}>

                                        <Link to="/dashboard" onClick={(e) => { this.AuditVistaSection(e, 1) }}
                                            style={{
                                                // background: this.state.select_menu === 1 ? '#556EE6' : "#f1f6fd",
                                                // borderRight: this.state.select_menu === 1 ? '3px solid #556EE6' : '',
                                                // borderRadius:"5px",
                                            }}
                                            className={`nav-link py-3 text-decoration-none ${this.state.select_menu === 1 ?  "bg-white text-primary" : "bg-primary text-white " }`}
                                            title="Audit" data-bs-toggle="tooltip"
                                            data-bs-placement="right"
                                        >
                                            <li className="nav-item" style={{ listStyleType: 'none' }}>
                                                <i className='bx bx-clipboard' style={{ fontSize: '18px' }}></i>
                                                <div className="sidebarIconText" style={{ fontSize: '12px', }}><span>Incident Report</span></div>
                                            </li>
                                        </Link>

                                        {/* {showFollowUpIcon && (
                                            <Link to="#" onClick={(e) => { this.AuditVistaSection(e, 2) }}
                                                style={{
                                                   // background: this.state.select_menu === 2 ? '#556EE6' : "#f1f6fd",
                                                    // borderRight: this.state.select_menu === 2 ? '3px solid #556EE6' : '',
                                                    // borderRadius:"5px",
                                                }}
                                                className={`nav-link py-3 text-decoration-none ${this.state.select_menu === 2 ? "bg-white text-primary" : "bg-primary text-white " }`}
                                                aria-current="page"
                                                title="Follow up "
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="right"
                                            >
                                                <li className="nav-item">
                                                    <i className='bx bx-run' style={{ fontSize: '18px' }}></i>
                                                    <div className="sidebarIconText" style={{ fontSize: '12px', }}><span>Follow up </span></div>
                                                </li>
                                            </Link>
                                        )} */}

                                        {showReportIcon && (
                                            <Link to="#" onClick={(e) => { this.AuditVistaSection(e, 3) }}
                                                style={{
                                                   // background: this.state.select_menu === 3 ? '#556EE6' : "#f1f6fd",
                                                    // borderRight: this.state.select_menu === 3 ? '3px solid #556EE6' : '',
                                                    // borderRadius:"5px",
                                                }}
                                                className={`nav-link py-3 text-decoration-none ${this.state.select_menu === 3 ?  "bg-white text-primary" : "bg-primary text-white " }`}
                                                title="Report"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="right"
                                            >
                                                <li className="nav-item">
                                                    <i className='bx bxs-pie-chart-alt-2' style={{ fontSize: '18px' }} ></i>
                                                    <div className="sidebarIconText" style={{ fontSize: '12px', }}><span>Report</span></div>
                                                </li>
                                            </Link>
                                        )}

                                        {showIncidentReportMenu && (
                                            <Link to="#" onClick={(e) => { this.AuditVistaSection(e, 4) }}
                                                style={{
                                                    // background: this.state.select_menu === 3 ? '#556EE6' : "#f1f6fd",
                                                    // borderRight: this.state.select_menu === 3 ? '3px solid #556EE6' : '',
                                                    // borderRadius:"5px",
                                                }}
                                                className={`nav-link py-3 text-decoration-none ${this.state.select_menu === 4 ? "bg-white text-primary" : "bg-primary text-white "}`}
                                                title="Report"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="right"
                                            >
                                                <li className="nav-item">
                                                    <i className='bx bxs-pie-chart-alt-2' style={{ fontSize: '18px' }} ></i>
                                                    <div className="sidebarIconText" style={{ fontSize: '12px', }}><span>Incident Report</span></div>
                                                </li>
                                            </Link>
                                        )}

                                    </ul>

                                </div>
                            </div>

                            {/* Level 2 menu */}
                            <div
                                style={{
                                    height: "100vh",
                                    backgroundColor: "white",
                                    borderRight: "#e3e6e8 1px solid",
                                    position: "relative",
                                    width: "180px"
                                }}
                            >
                                <div className="d-flex flex-column py-3" >

                                    <div className="d-flex flex-column justify-content-center align-items-center" >
                                        {
                                            data.client_info[0].client_logo_rzd !== undefined &&
                                            data.client_info[0].client_logo_rzd !== null &&
                                                <img
                                                    alt="Header Avatar"
                                                    className="mb-1"
                                                    src={data.client_info[0].base_url + data.client_info[0]?.client_logo?.originalname}
                                                    style={{ width: '30%', objectFit: 'contain', borderRadius:5  }}
                                                />
                                        }
                                        <div style={{textTransform:"uppercase"}}>{data.client_info[0].client_name}</div>
                                    </div>

                                    <div className="d-flex flex-row mx-1 bg-light mt-3 mb-3" style={{ borderRadius:25 }} >
                                        <ProfileMenu
                                            userData={this.state.userInfo}

                                        />
                                    </div>
                                    {
                                        this.state.showAuditMenu &&
                                        <div data-simplebar>
                                            {this.props.type !== "condensed" ? (
                                                <SimpleBar style={{ maxHeight: "100%", width: "178px" }}>
                                                    {
                                                        this.SidebarContent(this.state.screensDisplay)
                                                    }
                                                </SimpleBar>
                                            ) :
                                                this.SidebarContent(this.state.screensDisplay)
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.showActionMenu && 
                                        <div data-simplebar>
                                        {this.props.type !== "condensed" ? (
                                            <SimpleBar style={{ maxHeight: "100%", width: "178px" }}>
                                                {
                                                    this.SidebarContent(this.state.screensDisplay)
                                                }
                                            </SimpleBar>
                                        ) :
                                            this.SidebarContent(this.state.screensDisplay)
                                        }
                                    </div>
                                    }

{
                                        this.state.showReportMenu && 
                                        <div data-simplebar>
                                        {this.props.type !== "condensed" ? (
                                            <SimpleBar style={{ maxHeight: "100%", width: "178px" }}>
                                                {
                                                    this.SidebarContent(this.state.screensDisplay)
                                                }
                                            </SimpleBar>
                                        ) :
                                            this.SidebarContent(this.state.screensDisplay)
                                        }
                                    </div>
                                    }
                        {
                                        this.state.showIncidentReportMenu && 
                                        <div data-simplebar>
                                        {this.props.type !== "condensed" ? (
                                            <SimpleBar style={{ maxHeight: "100%", width: "178px" }}>
                                                {
                                                    this.SidebarContent(this.state.screensDisplay)
                                                }
                                            </SimpleBar>
                                        ) :
                                            this.SidebarContent(this.state.screensDisplay)
                                        }
                                    </div>
                                    }







                                    {/* {
                                        this.state.showReportMenu && (
                                            <div id="sidebar-menu" >
                                                <ul className="metismenu list-unstyled" id="side-menu">
                                                    {reportMenus.map((data, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link to={data.url} className="waves-effect text-dark bg-light my-1 mx-2" style={{ padding: "0.35rem 1rem", borderRadius:20 }}>
                                                                    <span>{data.interfacename}</span>
                                                                </Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        )} */}



                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            );
        } else {
            return null
        }
    }
}

const mapStatetoProps = state => {
    return {
        layout: state.Layout
    };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));















// import React, { Component } from "react";

// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import { } from "../../store/actions";

// // MetisMenu
// import MetisMenu from "metismenujs";
// import SimpleBar from "simplebar-react";

// import { Link } from "react-router-dom";

// import logo from "../../assets/images/logo.svg";
// import logoLightSvg from "../../assets/images/180@3x copy.png";
// import logoDark from "../../assets/images/logo-dark.png";

// // import images
// import auditvista_logo from "../../assets/images/auditvista_logo.png";
// import _ from "lodash"
// import urlSocket from "../../helpers/urlSocket";
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// let screens = [];





// class Sidebar extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             screensDisplay: [],
//             followUpMenus: [],
//             reportMenus: [],
//             showAuditMenu: true,
//             showActionMenu: false,
//             //set icon defaultly active
//             select_menu: 1
//             // load_data : false
//         };
//     }

//     async componentDidMount() {
//         //("followUpMenus")
//         this.initMenu();
//         await this.get_basicData();

//         const AuthuserData = JSON.parse(sessionStorage.getItem("authUser"));
//         const followUpMenusData = AuthuserData.config_data.follow_up_menus;
//         var select_menu = sessionStorage.getItem('select_menu')
//         //("Follow Up Menus Data:", followUpMenusData);
//         if (followUpMenusData) {
//             const followUpMenus = followUpMenusData;
//             this.setState({ followUpMenus });
//         }


//         const reportMenusData = AuthuserData.config_data.report_menus;
//         // console.log(select_menu, 'select_menu', followUpMenusData, reportMenusData)

//         //("Report Menus Data:", reportMenusData);
//         if (reportMenusData) {
//             const reportMenus = reportMenusData;
//             this.setState({ reportMenus });
//         }

//         if (select_menu !== null && select_menu !== undefined) {
//             var showActionMenu
//             var showAuditMenu
//             var showReportMenu
//             if (Number(select_menu) === 1) {
//                 showAuditMenu = true
//             }
//             if (Number(select_menu) === 2) {
//                 showActionMenu = true
//             }
//             if (Number(select_menu) === 3) {
//                 showReportMenu = true
//             }
//             this.setState({
//                 select_menu: Number(select_menu),
//                 showActionMenu,
//                 showAuditMenu,
//                 showReportMenu

//             })

//         }
//         else {
//             this.setState({
//                 select_menu: 1,
//                 showActionMenu,
//                 showAuditMenu: true,
//                 showReportMenu

//             })
//         }

//     }

//     componentDidUpdate(prevProps) {
//         if (this.props.type !== prevProps.type) {
//             this.initMenu();
//         }
//     }
//     async get_basicData() {
//         var data = JSON.parse(sessionStorage.getItem("authUser"));
//         // var redirect = sessionStorage.getItem("redirect")
//         // //(redirect)
//         var facilities = await this.get_user_facilities(data.user_data, data.db_info)
//         if (facilities === null) {
//             facilities = []
//         }
//         screens = facilities
//         sessionStorage.setItem('user_facilities', JSON.stringify(screens))
//         // screens = _.filter(data.user_data.facilities,e=>{
//         // return e.enabled === true
//         // })
//         // //(screens,'screens')
//         this.setState({
//             screensDisplay: screens,
//             // redirect :redirect
//             // load_data : true
//         })
//     }

//     get_user_facilities = async (user_data, db_info) => {
//         // //(user_data, 'user_data');

//         try {
//             const response = await urlSocket.post('cog/get-role-facilities', {
//                 encrypted_db_url: db_info.encrypted_db_url,
//                 user_data: user_data,
//             });

//             if (response.data.response_code === 500) {
//                 //('first', response.data)
//                 return response.data.data[0].facilities; // Return the response instead of 'facilities'
//             }
//         } catch (error) {
//             // Handle or return the error here
//             // console.error(error);
//             return null; // Return null or an appropriate error indicator
//         }
//     };



//     async initMenu() {
//         // if (this.props.type !== "condensed" || this.props.isMobile) {
//         // await new MetisMenu("#side-menu");

//         var matchingMenuItem = null;
//         var ul = document.getElementById("side-menu");
//         var redirect = sessionStorage.getItem("redirect")
//         //(ul, 'ul')
//         if (ul != null) {
//             var items = ul.getElementsByTagName("a");

//             setTimeout(() => {
//                 for (var i = 0; i < items.length; ++i) {
//                     let url = this.props.location.pathname.replace(/\d+/g, '')
//                     let pathname = items[i].pathname.replace(/\d+/g, '')
//                     if (url === pathname) {
//                         matchingMenuItem = items[i];
//                         break;
//                     }
//                     if (url == "/createroles") {
//                         matchingMenuItem = items[1];
//                         break;
//                     }
//                     if (url == "/adusrs") {
//                         matchingMenuItem = items[2];
//                         break;
//                     }
//                     if (url == "/edtusr") {
//                         matchingMenuItem = items[2];
//                         break;
//                     }
//                     if (url == '/crttmplt') {
//                         matchingMenuItem = items[4];
//                         break;
//                     }
//                     if (url == "/edtpblhtempt") {
//                         matchingMenuItem = items[4];
//                         break;
//                     }
//                     if (url == "/pblhcfg") {
//                         matchingMenuItem = items[4];
//                         break;
//                     }
//                     if (url == '/pbdrpt') {
//                         matchingMenuItem = items[5];
//                         break;
//                     }
//                     if (url == '/location') {
//                         matchingMenuItem = items[5];
//                         break;
//                     }
//                     if (url == '/chkpntrprt') {
//                         matchingMenuItem = items[redirect == 1 ? 6 : 5];
//                         break;
//                     }
//                     if (url == "/usrenpdts") {
//                         matchingMenuItem = items[6];
//                         break;
//                     }
//                     if (url == '/viewcpts') {
//                         matchingMenuItem = items[6];
//                         break;
//                     }
//                     if (url == '/enpcpts') {
//                         matchingMenuItem = items[6];
//                         break;
//                     }
//                 }
//                 if (matchingMenuItem) {
//                     this.activateParentDropdown(matchingMenuItem);
//                 }

//             }, 1000);
//         }
//     }

//     activateParentDropdown = item => {
//         item.classList.add("active");
//         const parent = item.parentElement;

//         if (parent) {
//             parent.classList.add("mm-active");
//             const parent2 = parent.parentElement;

//             if (parent2) {
//                 parent2.classList.add("mm-show");

//                 const parent3 = parent2.parentElement;

//                 if (parent3) {
//                     parent3.classList.add("mm-active"); // li
//                     parent3.childNodes[0].classList.add("mm-active"); //a
//                     const parent4 = parent3.parentElement;
//                     if (parent4) {
//                         parent4.classList.add("mm-active");
//                     }
//                 }
//             }
//             return false;
//         }
//         return false;
//     };


//     SidebarContent = (props) => {
//         //(screens, 'screm')
//         return (
//             <>
//                 <div id="sidebar-menu">
//                     <ul className="metismenu list-unstyled" id="side-menu">
//                         {
//                             screens.map((data, index) => {
//                                 return <li key={index}>
//                                     <Link to={data.url} className="waves-effect" style={{ padding: "0.65rem 1rem"}} >
//                                         <span>{data.interfacename}</span>
//                                     </Link>
//                                 </li>
//                             })
//                         }
//                     </ul>
//                 </div>
//             </>
//         )
//     };


//     isActive = (child) => {
//         for (let index = 0; index < child.length; index++) {
//             if (window.location.pathname.split("/").pop() === child[index]) {
//                 return '#d9e021'
//             }
//             else {
//                 return null
//             }

//         }
//     }

//     AuditVistaSection = (event, select_menu) => {
//         //(event)
//         sessionStorage.setItem('select_menu', select_menu)
//         if (select_menu === 1) {
//             this.setState({
//                 showAuditMenu: true,
//                 showActionMenu: false,
//                 showReportMenu: false,
//                 select_menu: select_menu
//             })
//         }
//         if (select_menu === 2) {
//             this.setState({
//                 showActionMenu: true,
//                 showAuditMenu: false,
//                 showReportMenu: false,
//                 select_menu: select_menu
//             })
//         }
//         if (select_menu === 3) {
//             this.setState({
//                 showReportMenu: true,
//                 showAuditMenu: false,
//                 showActionMenu: false,
//                 select_menu: select_menu
//             })
//         }
//     }



//     logOut() {

//         sessionStorage.clear()
//         this.props.history.push({ pathname: '/login' })
//     }


//     render() {

//         var data = JSON.parse(sessionStorage.getItem("authUser"));
//         const { followUpMenus } = this.state;
//         const { reportMenus } = this.state;
//         const authUserData = JSON.parse(sessionStorage.getItem("authUser"));
//         const allowData1 = authUserData.client_info[0].allowFollowup
//         const showFollowUpIcon = authUserData && authUserData.client_info[0].allowFollowup;
//         const showReportIcon = authUserData && authUserData.client_info[0].allowReport;

//         return (
//             <div>
//                 <div className="vertical-menu" style={{paddingTop:0}} >

//                     <div className="d-flex flex-row" style={{ height: "100vh", position: "relative", }}>

//                         {/* Level 1 menu */}
//                         <div
//                             style={{
//                                 height: "100vh",
//                                 width: "fit-content",
//                                 borderRight: "#e3e6e8 1px solid",
//                                 position: "relative",
//                                 backgroundColor: "#f1f6fd"
//                             }}
//                         >
//                             <div className="d-flex flex-column justify-content-center " style={{ height: "100vh", background: "white !important" }}>

//                                 <ul className="nav-link text-center" style={{ background: "white !important" }}>

//                                     <Link to="/dashboard" onClick={(e) => { this.AuditVistaSection(e, 1) }}
//                                         style={{
//                                             background: this.state.select_menu === 1 ? '#556EE6' : "#f1f6fd",
//                                             borderRight: this.state.select_menu === 1 ? '3px solid #556EE6' : '',
//                                             color: this.state.select_menu == 1 ? '#fff' : ''
//                                         }}
//                                         className="nav-link py-3 text-decoration-none"
//                                         title="Audit" data-bs-toggle="tooltip"
//                                         data-bs-placement="right"
//                                     >
//                                         <li className="nav-item" style={{ listStyleType: 'none' }}>
//                                             <i className='bx bx-clipboard' style={{ fontSize: '18px' }}></i>
//                                             <div className="sidebarIconText" style={{ fontSize: '12px', color: this.state.select_menu === 1 ? '#fff' : '' }}><span>Audit</span></div>
//                                         </li>
//                                     </Link>

//                                     {showFollowUpIcon && (
//                                         <Link to="#" className="nav-link py-3" onClick={(e) => { this.AuditVistaSection(e, 2) }}
//                                             style={{
//                                                 background: this.state.select_menu === 2 ? '#556EE6' : "#f1f6fd",
//                                                 borderRight: this.state.select_menu === 2 ? '3px solid #556EE6' : '',
//                                                 color: this.state.select_menu == 2 ? '#fff' : ''
//                                             }}
//                                             aria-current="page"
//                                             title="Follow up "
//                                             data-bs-toggle="tooltip"
//                                             data-bs-placement="right"
//                                         >
//                                             <li className="nav-item">
//                                                 <i className='bx bx-run' style={{ fontSize: '18px' }}></i>
//                                                 <div className="sidebarIconText" style={{ fontSize: '12px', color: this.state.select_menu === 2 ? '#fff' : '' }}><span>Follow up </span></div>
//                                             </li>
//                                         </Link>
//                                     )}

//                                     {showReportIcon && (
//                                         <Link to="#" className="nav-link  py-3" onClick={(e) => { this.AuditVistaSection(e, 3) }}
//                                             style={{
//                                                 background: this.state.select_menu === 3 ? '#556EE6' : "#f1f6fd",
//                                                 borderRight: this.state.select_menu === 3 ? '3px solid #556EE6' : '',
//                                                 color: this.state.select_menu == 3 ? '#fff' : ''
//                                             }}
//                                             title="Report"
//                                             data-bs-toggle="tooltip"
//                                             data-bs-placement="right"
//                                         >
//                                             <li className="nav-item">
//                                                 <i className='bx bxs-pie-chart-alt-2' style={{ fontSize: '18px' }} ></i>
//                                                 <div className="sidebarIconText" style={{ fontSize: '12px', color: this.state.select_menu === 3 ? '#fff' : '' }}><span>Report</span></div>
//                                             </li>
//                                         </Link>
//                                     )}
//                                 </ul>

//                             </div>
//                         </div>

//                         {/* Level 2 menu */}
//                         <div
//                             style={{
//                                 height: "100vh",
//                                 backgroundColor: "white",
//                                 borderRight: "#e3e6e8 1px solid",
//                                 position: "relative",
//                                 width: "225px"
//                             }}
//                         >
//                             <div className="d-flex flex-column py-3" >
//                                 <div className="d-flex flex-row ms-3 mb-3" >
//                                     {data.client_info[0].client_logo_rzd === undefined ||
//                                         data.client_info[0].client_logo_rzd === null ||
//                                         Object.keys(data.client_info[0].client_logo_rzd).length === 0 ? (
//                                             <div style={{ height: '50px', width: '60px', }}>
//                                             <img
//                                                 alt="Header Avatar"
//                                                 className=""
//                                                 src={data.client_info[0].base_url + data.client_info[0]?.client_logo?.originalname}
//                                                 style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                                             />

//                                         </div>
//                                     ) : (

//                                         <div style={{ height: '50px', width: '60px', }}>
//                                             <img
//                                                 alt="Header Avatar"
//                                                 className=""
//                                                 src={data.client_info[0].base_url + data.client_info[0].client_logo_rzd.originalname}
//                                                 style={{ height: '100%', width: '100%', objectFit: 'contain' }}
//                                             />

//                                         </div>

//                                     )}
//                                 </div>

//                                 <div className="d-flex flex-row my-3" style={{ borderBottom: "1px solid #dedede" }} >
//                                     <ProfileMenu
//                                         userData={this.state.userInfo}
//                                     />
//                                 </div>
//                                 {
//                                     this.state.showAuditMenu &&
//                                     <div data-simplebar>
//                                         {this.props.type !== "condensed" ? (
//                                             <SimpleBar style={{ maxHeight: "100%", width: "178px" }}>
//                                                 {
//                                                     this.SidebarContent(this.state.screensDisplay)
//                                                 }
//                                             </SimpleBar>
//                                         ) :
//                                             this.SidebarContent(this.state.screensDisplay)
//                                         }
//                                     </div>
//                                 }
//                                 {
//                                     this.state.showActionMenu && (
//                                         <div id="sidebar-menu" >
//                                             <ul className="metismenu list-unstyled" id="side-menu">
//                                                 {followUpMenus.map((data, index) => {
//                                                     return (
//                                                         <li key={index}>
//                                                             <Link to={data.url} className="waves-effect" style={{ padding: "0.65rem 1rem" }}>
//                                                                 <span>{data.interfacename}</span>
//                                                             </Link>
//                                                         </li>
//                                                     )
//                                                 })}
//                                             </ul>
//                                         </div>
//                                     )}


//                                 {
//                                     this.state.showReportMenu && (
//                                         <div id="sidebar-menu" >
//                                             <ul className="metismenu list-unstyled" id="side-menu">
//                                                 {reportMenus.map((data, index) => {
//                                                     return (
//                                                         <li key={index}>
//                                                             <Link to={data.url} className="waves-effect" style={{ padding: "0.65rem 1rem"}}>
//                                                                 <span>{data.interfacename}</span>
//                                                             </Link>
//                                                         </li>
//                                                     )
//                                                 })}
//                                             </ul>
//                                         </div>
//                                     )}



//                             </div>

//                         </div>

//                     </div>

//                 </div>
//             </div>

//         );
//     }
// }

// const mapStatetoProps = state => {
//     return {
//         layout: state.Layout
//     };
// };
// export default connect(mapStatetoProps, {})(withRouter(Sidebar));

