import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    Media,
    Row,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    CardImg
} from "reactstrap"

import { Image } from 'antd'

import Lightbox from "react-image-lightbox";
const _ = require('lodash')

export default class PreviewImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            dataloaded: false,
            photoIndex: 0,
        };
    }


    render() {

        var images = []
        var imageInfo = []

        _.each(this.props.images, data => {
            if (data.preview != undefined) {
                images.push(this.props.imagePreviewUrl + data.originalName)
                imageInfo.push(data)
                // imageInfo.push()
            }

        })

        const { photoIndex } = this.state;
        return (
            <Row >
                {/* {this.state.isGallery ? (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={
                            images[(photoIndex + images.length - 1) % images.length]
                        }
                        enableZoom={false}
                        onCloseRequest={() => this.setState({ isGallery: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    // imageCaption={"Location - "+ String(imageInfo[photoIndex].address) + " | Captured on - " + this.dateConvertion(imageInfo[photoIndex].date)}
                    />
                ) : null} */}
                {
                    this.props.images.map((item, idx) => {
                        return (
                            <Col mg={6} xl={3} key={idx} className="p-2" >
                                <div >
                                <Link to="#" onClick={() =>
                                        this.setState({ isGallery: true, photoIndex: idx })
                                    }>
                                         <Image
                                      alt="Skote"
                                      className=""
                                       src={this.props.imagePreviewUrl + item.originalname  }
                                    // src={"https://d3pnv0bkd16srd.cloudfront.net/eaudit-file/" + item.originalname}

                                      
                                      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                                    />
                                        {/* <CardImg  style={{width: "100%",objectFit: "cover"}}  src={this.props.imagePreviewUrl + item.originalname} alt="Skote" /> */}
                                {/* <img
                                    data-dz-thumbnail=""
                                    //height="80"
                                    className="rounded-circle avatar-sm border border-2 border-outline-seconndary"
                                    src={this.props.imagePreviewUrl + item.preview}
                                    
                                /> */}
                                </Link>
                                </div>
                            </Col>

                        )
                    })


                }
            </Row>

        )
    }


}
