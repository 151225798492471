import React, { Component, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

// users
import _ from "lodash";
import user1 from '../../../assets/images/avatar.jpg';
import { useSocketContext } from "../../../helpers/socket";

var urlSocket = require("../../../helpers/urlSocket");
import socket, { onSocketConnection } from '../../../helpers/socket';

const ProfileMenu = (props) => {

    const [dpMenu, setDpMenu] = useState(false)
    const [userInfo, setUserInfo] = useState(null)
    const [isSocketConnected, setSocketConneccted] = useState(false)

    useEffect(() => {
        var data = JSON.parse(sessionStorage.getItem("authUser"))
        console.log("userInfo", data)
        setUserInfo(data)
        if (!socket.connected) {
            onSocketConnection(data)
        }
        return () => { }
    }, [socket])

    useEffect(() => {
        if (socket.connected) {
            setSocketConneccted(true)
        }
        else {
            setSocketConneccted(false)
        }
        return () => { }

    }, [socket])

    const toggleMenu = () => {
        setDpMenu(!dpMenu)
    }

    const logout = () => {
        sessionStorage.clear()
        socket.disconnect();
        props.history.push('/login')
    }

    return (
        <div>
            {userInfo &&
                <Dropdown isOpen={dpMenu} toggle={toggleMenu} className="d-inline-block" >
                    <DropdownToggle
                        className="btn m-0 p-0 px-1 py-1 text-truncate"
                        id="page-header-user-dropdown"
                        tag="button"
                    >
                        {
                            userInfo && userInfo.user_data.user_img !== undefined && userInfo.user_data.user_img.length > 0 ?
                                <span className="position-relative avatar-xs rounded-circle me-2">
                                    <img

                                        // style={{ border: isSocketConnected ? '2px solid green' : '2px solid #caeffe' }}

                                        className="rounded-circle header-profile-user"
                                        src={userInfo.client_info[0].base_url + userInfo.user_data.user_img[0].originalname}
                                        alt="Header Avatar"
                                    />
                                    {isSocketConnected && <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-1"><span className="visually-hidden">unread messages</span></span>}
                                </span>
                                :
                                <span className="position-relative avatar-xs rounded-circle me-2">
                                    <img
                                        // style={{ border: isSocketConnected ? '2px solid green' : '2px solid #caeffe' }}
                                        className="rounded-circle header-profile-user"
                                        src={user1}
                                        alt="Header Avatar"
                                    />
                                    {isSocketConnected && <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-1"><span className="visually-hidden">unread messages</span></span>}
                                </span>
                        }

                        <span className="d-none d-xl-inline-block ms-2 me-1 font-size-12 ">{userInfo.user_data.firstname}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" href="/profile"><i className="bx bx-user font-size-16 align-middle mr-1"></i>Profile</DropdownItem>
                        {/* <DropdownItem tag="a" href="#"><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>Settings</DropdownItem> */}
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item" onClick={() => logout()} style={{ cursor: "pointer" }}>
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>Logout</span>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            }
        </div>
    )

}



export default withRouter(ProfileMenu);



