
import React, { Component } from 'react'
import { Tabs } from 'antd';
import { CardBody, CardTitle, Row, Col, Button, TabContent, TabPane, NavLink, NavItem, Nav, FormGroup, Card, Form } from "reactstrap";
import classnames from "classnames";
import Guideline from './Guideline'
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import '../manageAudit.css'

export default class TrueFalse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: "1",

        }
    }

    componentDidMount() {
    }
    onChangeTab = (tab) => {
        if (this.state.currentTab !== tab) {
            this.setState({ currentTab: tab })
        }
    }

    onChangeimgReq = (e) => {
        let value = e.target.checked ? true : false
        this.props.parameterData.img_availability = value
        this.setState({ refresh: true })
    }

    onChangeAplnReq = (e) => {
        let value = e.target.checked ? true : false
        this.props.parameterData.apln_available = value
        this.setState({ refresh: true })
    }



    ValidateAndSend = () => {
        this.setState({ currentTab: "1" })
        let data = this.props.parameterData
        data.min_score=this.get_minScore(this.props.parameter_options)
        data.max_score=this.get_maxScore(this.props.parameter_options)
        data.parameter_options = this.props.parameter_options
        this.props.onSave(data)

    }

    onChangePhotoReq = (e, data) => {
        let value = e.target.checked ? true : false
        data.img_availability = value
        this.setState({ refresh: true })
    }

    get_minScore = (data) => {
        try {
            return isNaN(Math.min.apply(Math, data.map((o) => { return o.score }))) ? 0 : Math.min.apply(Math, data.map((o) => { return o.score }))
        } catch (error) {
            return 0
        }
    }

    get_maxScore = (data) => {
        try {
            return isNaN(Math.max.apply(Math, data.map((o) => { return o.score }))) ? 0 : Math.max.apply(Math, data.map((o) => { return o.score }))
        } catch (error) {
            return 0
        }
    }

    isManditoryChange = (e, data) => {
        let value = e.target.checked ? true : false
        data.is_manditory = value
        this.setState({ refresh: true })
    }

    render() {
        return (
            <div >
                <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: this.state.currentTab === "1" })} onClick={() => { this.onChangeTab("1") }} >
                            <span className="d-none d-sm-block">Parameter</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: this.state.currentTab === "2" })} onClick={() => { this.onChangeTab("2"); }} >
                            <span className="d-none d-sm-block">Guideline</span>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.currentTab}>
                    <TabPane tabId="1" >
                        <div className="blueDiv">
                            <AvForm onValidSubmit={this.ValidateAndSend}>
                                <Row>
                                    <Col md={12}>
                                        <AvField
                                            name="Parameter"
                                            label="Enter Audit Parameter"
                                            type="textarea"
                                            errorMessage="Please enter Parameter"
                                            value={this.props.parameterData.parameter}
                                            onChange={(e) => this.props.parameterData.parameter = e.target.value}
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} xs={12} sm={12}>
                                        <Row>
                                            {
                                                this.props.parameter_options.map((data, idx) => (
                                                    <Col md={5} key={idx}>
                                                        <Card outline color="primary" className="border">
                                                            <CardBody>
                                                                <CardTitle className="mt-0 text-primary">{data.option_text}</CardTitle>
                                                                <Row>
                                                                    <Col md={6} sm={12} >
                                                                        <AvField
                                                                            name={"score" + idx}
                                                                            type="number"
                                                                            label={"Score"}
                                                                            errorMessage="Score"
                                                                            value={String(data.score)}
                                                                            style={{ width: 100, }}
                                                                            onChange={(e) => { this.props.parameter_options[idx].score = parseFloat(e.target.value); this.setState({ refresh: true }) }}
                                                                            validate={{ required: { value: true } }}
                                                                        />
                                                                    </Col>
                                                                    <Col md={6} sm={12} >
                                                                        <div>
                                                                            <label >Photos Required</label>
                                                                        </div>
                                                                        <div className="square-switch" >
                                                                            <input type="checkbox" id={this.props.parameterData._id + idx + "apln"} data-switch="info" checked={data.img_availability == "1" ? true : false} onChange={(e) => { this.onChangePhotoReq(e, data) }} />
                                                                            <label htmlFor={this.props.parameterData._id + idx + "apln"} data-on-label="Yes" data-off-label="No"  ></label>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                {
                                                                    data.img_availability == "1" &&
                                                                    <Row >
                                                                        <Col md={6} >
                                                                            <AvField
                                                                                name={"img" + idx}
                                                                                type="number"
                                                                                label="Enter No. of Photos  "
                                                                                errorMessage="Enter No. of Photos"
                                                                                placeHolder="No. of Photos"
                                                                                value={data.no_of_img}
                                                                                style={{ width: 100, }}
                                                                                onChange={(e) => { this.props.parameter_options[idx].no_of_img = parseInt(e.target.value) }}
                                                                                validate={{
                                                                                    required: { value: data.img_availability },
                                                                                    pattern: { value: "^[0-9]+$" }
                                                                                }}
                                                                            />



                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div style={{ width: "110px" }}>
                                                                                {
                                                                                    data.img_availability == "1" &&
                                                                                    <>
                                                                                        <div>
                                                                                            <label >Manditory</label>
                                                                                        </div>
                                                                                        <div style={{ padding: '8px 0px' }}>
                                                                                            <div className="square-switch">
                                                                                                <input type="checkbox" id={this.props.parameterData._id + idx + "manditory"} data-switch="info" checked={data.is_manditory == "1" ? true : false} onChange={(e) => { this.isManditoryChange(e, data) }} /><label htmlFor={this.props.parameterData._id + idx + "manditory"} data-on-label="Yes" data-off-label="No"  ></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                ))
                                            }
                                        </Row>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} xs={12} sm={12}>
                                        <Row className="mt-2">
                                            <Col md={3} >
                                                <div>
                                                    <label >Action Plan Required</label>
                                                </div>
                                                <div style={{ padding: '8px 2px' }}>
                                                    <div className="square-switch" style={{ marginTop: 7 }}>
                                                        <input type="checkbox" id={this.props.parameterData._id + "apln"} data-switch="info" checked={this.props.parameterData.apln_available == "1" ? true : false} onChange={(e) => { this.onChangeAplnReq(e) }} /><label htmlFor={this.props.parameterData._id + "apln"} data-on-label="Yes" data-off-label="No"  ></label>
                                                    </div>
                                                </div>
                                            </Col>

                                            {
                                                this.props.parameterData.apln_available == "1" &&
                                                <>
                                                    <Col md={3} className="align-center udb" >
                                                        <AvField
                                                            name="Action Plan Eligibility %"
                                                            label="Enter Eligibility %"
                                                            type="number"
                                                            placeholder="Eligibility %"
                                                            errorMessage="Enter Eligibility %"
                                                            value={String(this.props.parameterData.apln_eligibility_per)}
                                                            onChange={(e) => this.props.parameterData.apln_eligibility_per = parseFloat(e.target.value)}
                                                            validate={{
                                                                required: { value: this.state.apln_available },
                                                                pattern: {
                                                                    value: "^[0-9]+$",
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={6} >
                                                        <AvField
                                                            name="Observation"
                                                            label="Enter Observation"
                                                            type="textarea"
                                                            errorMessage="Observation"
                                                            value={String(this.props.parameterData.apln_observation == null ? '' : this.props.parameterData.apln_observation)}
                                                            onChange={(e) => this.props.parameterData.apln_observation = e.target.value}
                                                            validate={{
                                                                required: { value: this.state.apln_available },
                                                            }}
                                                        />
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col md={3}>
                                        <label >Periodicity</label>
                                        <div>
                                            <Select
                                                value={this.props.parameterData.periodicity[0]}
                                                onChange={(e) => { this.props.parameterData.periodicity = [e]; this.setState({ refresh: true }) }}
                                                options={this.props.periodicity_info}
                                                getOptionLabel={(option) => option.periodicity}

                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <label >Mode</label>
                                        <div>
                                            <Select
                                                value={{ label: this.props.parameterData.entry_mode == null ? 'Select' : this.props.parameterData.entry_mode }}
                                                onChange={(e) => { this.props.parameterData.entry_mode = e.label; this.setState({ refresh: true }) }}
                                                options={this.props.entry_mode}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3} className="align-self-end">
                                        <div>
                                            <label>Minimum Score : {this.get_minScore(this.props.parameter_options)}</label>
                                        </div>
                                    </Col>
                                    <Col md={3} className="align-self-end">
                                        <div>
                                            <label>Maximum Score : {this.get_maxScore(this.props.parameter_options)}</label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={12}>
                                        <FormGroup>
                                            <Button color="primary" type="submit" >Save</Button>
                                            <Button color="danger" onClick={() => { this.props.deleteParameter(this.props.parameterData) }} style={{ marginLeft: "10px" }} >Delete</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </AvForm>
                        </div>
                    </TabPane>
                    <TabPane tabId="2" >
                        <Guideline
                            ValidateAndSend={() => this.ValidateAndSend()}
                            guideline_image={this.props.parameterData.guideline_image}
                            _id={this.props.parameterData._id}
                            parameterData={this.props.parameterData}
                        />

                    </TabPane>
                    <TabPane tabId="3" >

                    </TabPane>
                </TabContent>
            </div>
        )
    }
}
