import React, { Component } from "react";
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
    Input,
} from "reactstrap";
// import Dropzone from "react-dropzone"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"
import { Multiselect } from 'multiselect-react-dropdown';
import CrudUser from './crudUser'


import moment from 'moment'

const _ = require('lodash')




export default class CAPA extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataloaded: false,
            previousData: {},
            assigned_task_users: [],
            location_users: [],
            assign_users: false,
            ac_impact_valid: false
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.multiRef = React.createRef()
    }

    componentDidMount() {
        // console.log(this.props.location_info,'location_info')
        var client_info = JSON.parse(sessionStorage.getItem('client_info'))[0]



        var location_users

        console.log(this.props.location_info.audit_pbd_users,this.props.location_info,'this.props.location_info.audit_pbd_users',this.props.location_info.location_unique_users)

        if (this.props.location_info.audit_pbd_users.length > 1) {
            // var loca
            var review_user = _.filter(this.props.location_info.audit_pbd_users, { audit_type: "2" })
            if(review_user.length >0){
            var location_users = _.reject(this.props.location_info.location_unique_users, { _id: review_user[0]._id === undefined ? review_user[0].user_id : review_user[0]._id })
            }
        }
        else {
            var location_users = this.props.location_info.location_unique_users
        }
        console.log(location_users,'location_users')
        if (location_users !== undefined) {
            var createNewExists = location_users?.some(user => user.name === "+Create New");
            if (!createNewExists) {
                location_users.unshift({
                    name: "+Create New"
                });
            }
        }
        if(location_users === undefined){
            location_users=[]
            location_users.unshift({
                name: "+Create New"
            });
        }

        this.setState({ data: this.props.data }, () => {
            this.setState({ dataloaded: true, client_info, location_users, assigned_task_users: this.state.data.responsible_person === undefined ? [] : this.state.data.responsible_person })
        })
    }


    addapln(checkpoint, actionplan, index) {
        var location_info = JSON.parse(sessionStorage.getItem("endpointData"))


        console.log(this.state.assigned_task_users,'this.state.assigned_task_users',location_info.location_permission_acpln )

        if(location_info?.location_permission_acpln !== undefined && location_info?.location_permission_acpln !== null){
        _.forEach(this.state.assigned_task_users, user => {
            // Find the corresponding user in location_info.user_permission_acpln
            const correspondingUser = _.find(location_info.location_permission_acpln, { 'user_id': user.user_id });
            console.log('correspondingUser', correspondingUser);
        
            // If a corresponding user is found
            if (correspondingUser) {
                // Add fields or perform any necessary operations
                _.assign(user, _.pick(correspondingUser, ['id', 'role_description', 'role_name','facilities']));
            }
        });
        }
        // Now this.state.assigned_task_users will have the added fields
        console.log(this.state.assigned_task_users);



        checkpoint.cp_actionplans[index]["observation"] = actionplan.observation
        checkpoint.cp_actionplans[index]["root_cause"] = actionplan.root_cause
        checkpoint.cp_actionplans[index]["actionplan"] = actionplan.actionplan
        checkpoint.cp_actionplans[index]["target_date"] = actionplan.target_date
        // checkpoint.cp_actionplans[index]["to_email"] = actionplan.to_email
        checkpoint.cp_actionplans[index]["to_email"] = this.state.asignee_email
        checkpoint.cp_actionplans[index]["responsible_person"] = this.state.assigned_task_users
        // checkpoint.cp_actionplans[index]["to_phone"] = actionplan.to_phone
        checkpoint.cp_actionplans[index]["status"] = "1"
        checkpoint.cp_actionplans[index]["isEdit"] = false
        console.log(checkpoint, 'checkpoint')
        this.props.saveCheckpoint(checkpoint)
    }

    editapln(checkpoint, actionplan) {
        var index = _.findIndex(checkpoint.cp_actionplans, { id: actionplan.id })
        if (index !== -1) {
            checkpoint.cp_actionplans[index]["isEdit"] = true
        }
        this.setState({
            refresh: true
        })
    }

    // deleteapln(checkpoint, actionplan, index) {
    // console.log('deleteapln', checkpoint, actionplan, index)

    // // var getIndex = _.findIndex(checkpoint.cp_actionplans, { id: actionplan.id })
    // // console.log('getIndex', getIndex)
    // if (index !== -1 || index !== undefined) {
    // // checkpoint.cp_actionplans.splice(getIndex, 1)
    // checkpoint.cp_actionplans.splice(index, 1)
    // console.log('checkpoint', checkpoint)
    // // this.props.getDeletediles(checkpoint)
    // this.setState({ dataloaded: false},()=>{this.setState({dataloaded: true},()=>{this.props.saveCheckpoint(checkpoint)})})


    // }
    // // this.props.saveCheckpoint(checkpoint)
    // }



    // deleteapln(checkpoint, actionplan, index) {

    // if (index >= 0 && index < checkpoint.cp_actionplans.length) {
    // checkpoint.cp_actionplans.splice(index, -1);

    // // Save the updated checkpoint
    // this.setState({ dataloaded: true }, () => {
    // this.props.saveCheckpoint(checkpoint);
    // this.setState({ dataloaded: false});
    // });
    // }
    // }

    deleteapln = (checkpoint, actionplan, index) => {
        var temp_cp_actionplan = []
        checkpoint.cp_actionplans.map((data, idx) => {
            if (index !== idx) {
                temp_cp_actionplan.push(data)
            }
        })
        console.log(temp_cp_actionplan,'temp_cp_actionplan')
        checkpoint['cp_actionplans'] = temp_cp_actionplan
        this.props.saveCheckpoint(checkpoint)
    }







    cancelapln(checkpoint, actionplan) {
        var index = _.findIndex(checkpoint.cp_actionplans, { id: actionplan.id })
        if (index !== -1) {
            checkpoint.cp_actionplans[index]["isEdit"] = false
        }
        this.setState({
            refresh: true
        })
    }

    handleValidSubmit(evet, values) {
        var index = _.findIndex(this.props.selectedCheckpoint.cp_actionplans, { id: this.state.data.id })
        console.log('handleValidSubmit', this.state.data.ac_impact,this.state.data)
        if (this.state.assigned_task_users.length > 0 && this.state.data.ac_impact !== '' && this.state.data.ac_impact !== undefined) {
            this.addapln(this.props.selectedCheckpoint, values, index)
        }
        if (this.state.assigned_task_users.length === 0) {
            this.setState({ assign_users: true })
        }
        if (this.state.data.ac_impact === undefined) {
            console.log('Error')
            this.setState({ ac_impact_valid: true })

        }
    }

    onRemove = (selectedItem) => {

        if (selectedItem !== undefined) {
            console.log(selectedItem, this.state.assigned_task_users)
            var assigned_task_users = [...this.state.assigned_task_users]
            assigned_task_users = _.reject(assigned_task_users, { user_id: selectedItem._id === undefined ? selectedItem.user_id : selectedItem._id })
            console.log(assigned_task_users, 'assigned_task_users')
            this.setState({
                assigned_task_users
            }, () => {
                console.log(this.state.assigned_task_users, 'assigned_task_users')
                this.state.assigned_task_users.length === 0 && this.setState({ assign_users: true })
            })
        }

    }

    onSelectValues = (selectedList, selectedItem) => {
        console.log(selectedItem,'selectedItem',selectedList)
        if (selectedItem.name === "+Create New") {
            console.log(selectedList, selectedItem, this.state.data.responsible_person, this.state.location_users, this.multiRef)
            var selectedList = _.reject(selectedList, { name: "+Create New" })
            this.multiRef.current.state.selectedValues = selectedList
            this.setState({
                dataloaded: false
            }, () => {
                this.setState({
                    dataloaded: true,
                    modal: true
                })
            })
        }
        else {
            var assigned_task_users = [...this.state.assigned_task_users]
            var auditor_info = _.filter(this.props.location_info.audit_pbd_users, { audit_type: "1" })
            assigned_task_users.push({
                name: selectedItem.name,
                user_id: selectedItem.user_id,
                user_code: selectedItem.user_code !== undefined ? selectedItem.user_code : '',
                user_category_name: selectedItem.user_lvl_name,
                user_category_type: selectedItem.cat_type,
                auditor_name: auditor_info[0].name,
                auditor_id: auditor_info[0].user_id !== undefined ? auditor_info[0].user_id : auditor_info[0]._id,
                user_status:"0"
            })

            this.setState({
                assigned_task_users,
                asignee_email: selectedItem.name
            }, () => {
                console.log(this.state.assigned_task_users, 'assigned_task_users')
                this.state.assigned_task_users.length > 0 && this.setState({ assign_users: false })
            })
        }


    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }


    handleRadioChange = (event) => {
        console.log(this.state.data, event.target.name, event.target.value, this.state.data)
        var data = this.state.data
        console.log('data', data)
        data["ac_impact"] = event.target.name

        this.setState({ data: data }, () => { this.setState({ ac_impact_valid: false }) })
        // this.setState({dataloaded : false},()=>{
        // this.setState({dataloaded : true})
        // })

    }




    render() {
        if (this.state.dataloaded) {
            var today = new Date()
            const dd = today.getDate().toString().length == 1 ? "0" + today.getDate().toString() : today.getDate().toString()
            const mm = String(today.getMonth() + 1).length == 1 ? "0" + String(today.getMonth() + 1) : today.getMonth() + 1
            const yyyy = today.getFullYear()
            const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
            // console.log(this.state.data,'this.state.data',this.props.location_info)
            return (
                <Container className="border border-1 border-amber-300 p-3">
                    {
                        this.state.data.isEdit ?
                            <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                                {/* <label>delete index: {this.state.data.addindex !== undefined ? this.state.data.addindex : null}</label> */}
                                <Row className="mb-3">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div className=" font-size-14">Action {this.props.index + 1}</div>
                                        <div >
                                            {this.state.data.status === "1" ? <Button className="btn me-3" color="danger" onClick={() => { this.cancelapln(this.props.selectedCheckpoint, this.state.data) }}>Cancel</Button> : null}
                                        </div>
                                    </div>
                                </Row>

                                <Row className="align-items-center">
                                    <Col sm={"12"} lg={"12"}>
                                        <div className="mb-3" >

                                            <label>Observation<span className="text-danger">*</span> </label>
                                            <AvField
                                                name="observation"
                                                value={this.state.data.observation}
                                                onChange={(e) => { this.setState({ actpln: e.target.value }) }}
                                                className="form-control"
                                                placeholder="Enter observation"
                                                type="textarea"
                                                required
                                            />
                                        </div>

                                    </Col>
                                </Row>

                                <Row className="align-items-center">
                                    <Col sm={"12"} lg={"12"}>
                                        <div className="mb-3" >
                                            <label>Root Cause</label>
                                            <AvField
                                                name="root_cause"
                                                value={this.state.data.root_cause}
                                                onChange={(e) => { this.setState({ root_cause: e.target.value }) }}
                                                className="form-control"
                                                placeholder="Enter Root Cause"
                                                type="textarea"
                                            />
                                        </div>

                                    </Col>
                                </Row>

                                <Row className="align-items-center">
                                    <Col sm={"12"} lg={"12"}>
                                        <div className="mb-3" >
                                            <label>Action plan <span className="text-danger">*</span></label>
                                            <AvField
                                                name="actionplan"
                                                value={this.state.data.actionplan}
                                                onChange={(e) => { this.setState({ actpln: e.target.value }) }}
                                                className="form-control"
                                                placeholder="Enter action plan"
                                                type="textarea"
                                                required
                                            />
                                        </div>

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={"12"} lg={"4"} className="mb-2">
                                        <label>Target date<span className="text-danger">*</span></label>
                                        <AvField
                                            name="target_date"
                                            type="date"
                                            errorMessage="Please provide a valid date."
                                            className="form-control"
                                            min={fromate_date}
                                            value={this.state.data.target_date}
                                            onKeyDown={(e) => { e.preventDefault(); }}
                                            onChange={(e) => { this.setState({ target_date: e.target.value }) }}
                                            validate={{ required: { value: true } }}
                                            id="td"
                                        />
                                    </Col>

                                    <Col sm={"12"} lg={"4"}>
                                        <label>Assign to<span className="text-danger">*</span></label>
                                        <div className="mb-3" >
                                            <Multiselect
                                                ref={this.multiRef}
                                                onRemove={(selectedList, selectedItem) => {
                                                    this.onRemove(selectedItem)
                                                }
                                                }
                                                onSelect={(selectedList, selectedItem) => {
                                                    this.onSelectValues(selectedList, selectedItem);
                                                }}
                                                options={this.state.location_users}
                                                displayValue="name"
                                                closeOnSelect={false}
                                                // selectedValues={this.state.data.responsible_person}
                                                selectedValues={this.state.data.responsible_person == undefined ? this.state.assigned_task_users : this.state.data.responsible_person}
                                            />
                                            {
                                                this.state.assign_users &&
                                                <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Assign Users</div>
                                            }




                                            {/* <AvField
 name="to_email"
 value={this.state.data.to_email}
 onChange={(e) => { this.setState({ asignee_email: e.target.value }) }}
 className="form-control"
 placeholder="Assign to"
 type="email"
 errorMessage="Invalid Email"
 // validate={{
 // required: { value: true },
 // email: { value: true },
 // }}
 validate={{
 required: { value: true },
 pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, errorMessage: "Please enter a valid email address" },
 }}
 /> */}
                                        </div>

                                    </Col>

                                    {/* <Col sm={"12"} lg={"4"}>
 <label>phone number</label>
 <div className="mb-3" >
 <AvField
 name="to_phone"
 value={this.state.data.to_phone}
 onChange={(e) => { this.setState({ asignee_phone: e.target.value }) }}
 className="form-control"
 placeholder="Assign to"
 type="number"
 validate={{
 required: { value: true },
 maxLength: { value: 10, errorMessage: "Min 6 chars." },
 }}

 />
 </div>

 </Col> */}


                                </Row>
                                <Row>
                                    <Col sm={"12"} lg={"12"}>
                                        <label>Action Plan Impact Level :
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="mb-3" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div className="form-check mx-2 form-radio-danger">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Critical"
                                                        id="exampleRadios1"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        checked={this.state.data["ac_impact"] === "Critical"}
                                                        value={this.state.data["ac_impact"]}

                                                    />
                                                    <label
                                                        className="form-check-label text-danger"
                                                        htmlFor="exampleRadios1"
                                                    >
                                                        Critical
                                                    </label>
                                                </div>
                                                <div className="form-check mx-2 form-radio-warning">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="High"
                                                        id="exampleRadios2"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        checked={this.state.data["ac_impact"] === "High"}
                                                        value={this.state.data["ac_impact"]}


                                                    />
                                                    <label
                                                        className="form-check-label text-warning"
                                                        htmlFor="exampleRadios2"
                                                    >
                                                        High
                                                    </label>
                                                </div>
                                                <div className="form-check mx-2 form-radio-info">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Medium"
                                                        id="exampleRadios3"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        checked={this.state.data["ac_impact"] === "Medium"}
                                                        value={this.state.data["ac_impact"]}


                                                    />
                                                    <label
                                                        className="form-check-label text-info"
                                                        htmlFor="exampleRadios3"
                                                    >
                                                        Medium
                                                    </label>
                                                </div>
                                                <div className="form-check mx-2 form-radio-success">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Low"
                                                        id="exampleRadios4"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        checked={this.state.data["ac_impact"] === "Low"}
                                                        value={this.state.data["ac_impact"]}


                                                    />
                                                    <label
                                                        className="form-check-label text-success"
                                                        htmlFor="exampleRadios4"
                                                    >
                                                        Low
                                                    </label>
                                                </div>
                                                <div className="form-check mx-2 form-radio-primary">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="No impact"
                                                        id="exampleRadios5"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        value={this.state.data["ac_impact"]}
                                                        checked={this.state.data["ac_impact"] === "No impact"}

                                                    />
                                                    <label
                                                        className="form-check-label text-primary"
                                                        htmlFor="exampleRadios5"
                                                    >
                                                        No impact
                                                    </label>
                                                    {/* {
 console.log(this.state.data,'this.state.data')
 } */}
                                                </div>
                                            </div>
                                            {
                                                this.state.ac_impact_valid &&
                                                <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Select Imapct Level</div>
                                            }


                                        </div>
                                    </Col>
                                </Row>

                                <Row >
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div className=" font-size-14"></div>
                                        <div >
                                            <Button className="btn me-2" color="success">Save</Button>
                                            <Button className="btn me-2" color="danger" onClick={() => { this.deleteapln(this.props.selectedCheckpoint, this.state.data, this.props.index) }}>Delete</Button>
                                            {/* <Button className="btn" color="primary" onClick={() => { this.props.onadd() }}>Add Another Action plan</Button> */}
                                        </div>
                                    </div>
                                </Row>

                            </AvForm> :
                            <div>
                                <Row className="mb-3">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div>Action {this.props.index + 1}</div>
                                        {
                                            this.props.audit_status !== "3" && this.props.audit_status !== "4" ?
                                                <div >
                                                    <Button className="btn" color="primary" onClick={() => { this.editapln(this.props.selectedCheckpoint, this.state.data) }}>Edit</Button>
                                                </div> : null
                                        }

                                    </div>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col sm={"12"} lg={"12"}>
                                        <label className="text-primary font-size-12">Observation<span className="text-danger">*</span></label>
                                        <div>{this.state.data.observation}</div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col sm={"12"} lg={"12"}>
                                        <label className="text-primary font-size-12">Root Cause</label>
                                        <div>{this.state.data.root_cause}</div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col sm={"12"} lg={"12"}>
                                        <label className="text-primary font-size-12">Action plan<span className="text-danger">*</span> </label>
                                        <div>{this.state.data.actionplan}</div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col sm={"12"} lg={"4"}>
                                        <label className="text-primary font-size-12">Target date<span className="text-danger">*</span></label>
                                        {/* <div>{this.state.data.target_date}</div> */}
                                        <div>{moment(this.state.data.target_date).format("DD-MMM-YYYY")}</div>
                                    </Col>

                                    <Col sm={"12"} lg={"4"}>
                                        <label className="text-primary font-size-12">Assign to email<span className="text-danger">*</span></label>
                                        {console.log('!!!!!', this.state.data)}
                                        {/* <div>{this.state.data.to_email}</div> */}

                                        <div className="d-flex gap-1">
                                            {this.state.data.responsible_person.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <label>{item.name}</label>
                                                    {index < this.state.data.responsible_person.length - 1 ? ',' : '.'}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={"12"} lg={"12"}>
                                        <label>Action Plan Impact Level :
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="mb-3" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div className="form-check mx-2 form-radio-danger">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Critical"
                                                        id="exampleRadios1"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        checked={this.state.data["ac_impact"] === "Critical"}
                                                        value={this.state.data["ac_impact"]}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label text-danger"
                                                        htmlFor="exampleRadios1"
                                                    >
                                                        Critical
                                                    </label>
                                                </div>
                                                <div className="form-check mx-2 form-radio-warning">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="High"
                                                        id="exampleRadios2"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        checked={this.state.data["ac_impact"] === "High"}
                                                        value={this.state.data["ac_impact"]}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label text-warning"
                                                        htmlFor="exampleRadios2"
                                                    >
                                                        High
                                                    </label>
                                                </div>
                                                <div className="form-check mx-2 form-radio-info">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Medium"
                                                        id="exampleRadios3"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        checked={this.state.data["ac_impact"] === "Medium"}
                                                        value={this.state.data["ac_impact"]}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label text-info"
                                                        htmlFor="exampleRadios3"
                                                    >
                                                        Medium
                                                    </label>
                                                </div>
                                                <div className="form-check mx-2 form-radio-success">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Low"
                                                        id="exampleRadios4"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        checked={this.state.data["ac_impact"] === "Low"}
                                                        value={this.state.data["ac_impact"]}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label text-success"
                                                        htmlFor="exampleRadios4"
                                                    >
                                                        Low
                                                    </label>
                                                </div>
                                                <div className="form-check mx-2 form-radio-primary">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="No impact"
                                                        id="exampleRadios5"
                                                        onChange={(e) => this.handleRadioChange(e)}
                                                        value={this.state.data["ac_impact"]}
                                                        checked={this.state.data["ac_impact"] === "No impact"}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label text-primary"
                                                        htmlFor="exampleRadios5"
                                                    >
                                                        No impact
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                    }
                    {
                        this.state.modal &&
                        <CrudUser modal={this.state.modal} toggle={this.toggle} client_info={this.state.client_info} location_info={this.props.location_info} />
                    }
                </Container>

            )
        } else {
            return null
        }
    }

}














// import React, { Component } from "react";
// import ReactDOM from 'react-dom';
// import MetaTags from 'react-meta-tags';
// import {
//     Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
//     Input,
// } from "reactstrap";
// // import Dropzone from "react-dropzone"
// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// import { Link, withRouter } from "react-router-dom"
// import { Multiselect } from 'multiselect-react-dropdown';
// import CrudUser from './crudUser'


// import moment from 'moment'

// const _ = require('lodash')




// export default class CAPA extends React.Component {

//     constructor(props) {
//         super(props)
//         this.state = {
//             dataloaded: false,
//             previousData: {},
//             assigned_task_users:[],
//             location_users:[],
//             assign_users : false
//         }
//         this.handleValidSubmit = this.handleValidSubmit.bind(this)
//         this.multiRef = React.createRef()
//     }

//     componentDidMount(){
//         console.log(this.props.location_info,'location_info')
//         var client_info = JSON.parse(sessionStorage.getItem('client_info'))[0]



//         var location_users
//         if(this.props.location_info.audit_pbd_users.length >1){
//             // var loca
//             var review_user = _.filter(this.props.location_info.audit_pbd_users, { audit_type: "2" })
//             console.log(review_user,'review_user')
//             var location_users = _.reject(this.props.location_info.location_unique_users,{_id : review_user[0]._id === undefined ?review_user[0].user_id :review_user[0]._id  })
//             console.log(location_users,'location_users')
//             location_users.unshift({
//                 name :"+Create New"
//             })
//         }
//         else{
//             var location_users = this.props.location_info.location_unique_users
//             // location_users.unshift({
//             //     name :"+Create New"
//             // })
//         }
//         this.setState({dataloaded: true ,client_info, location_users ,assigned_task_users : this.props.data.responsible_person === undefined ?[]: this.props.data.responsible_person})
//     }


//     addapln(checkpoint, actionplan, index) {
//         checkpoint.cp_actionplans[index]["observation"] = actionplan.observation
//         checkpoint.cp_actionplans[index]["root_cause"] = actionplan.root_cause
//         checkpoint.cp_actionplans[index]["actionplan"] = actionplan.actionplan
//         checkpoint.cp_actionplans[index]["target_date"] = actionplan.target_date
//         // checkpoint.cp_actionplans[index]["to_email"] = actionplan.to_email
//         checkpoint.cp_actionplans[index]["to_email"] = this.state.asignee_email
//         checkpoint.cp_actionplans[index]["responsible_person"] = this.state.assigned_task_users
//         // checkpoint.cp_actionplans[index]["to_phone"] = actionplan.to_phone
//         checkpoint.cp_actionplans[index]["status"] = "1"
//         checkpoint.cp_actionplans[index]["isEdit"] = false
//         console.log(checkpoint,'checkpoint')
//         this.props.saveCheckpoint(checkpoint)
//     }

//     editapln(checkpoint, actionplan) {
//         var index = _.findIndex(checkpoint.cp_actionplans, { id: actionplan.id })
//         if (index !== -1) {
//             checkpoint.cp_actionplans[index]["isEdit"] = true
//         }
//         this.setState({
//             refresh: true
//         })
//     }

//     // deleteapln(checkpoint, actionplan, index) {
//     //     console.log('deleteapln', checkpoint, actionplan, index)

//     //     // var getIndex = _.findIndex(checkpoint.cp_actionplans, { id: actionplan.id })
//     //     // console.log('getIndex', getIndex)
//     //     if (index !== -1 || index !== undefined) {
//     //         // checkpoint.cp_actionplans.splice(getIndex, 1)
//     //         checkpoint.cp_actionplans.splice(index, 1)
//     //         console.log('checkpoint', checkpoint)
//     //         // this.props.getDeletediles(checkpoint)
//     //         this.setState({ dataloaded: false},()=>{this.setState({dataloaded: true},()=>{this.props.saveCheckpoint(checkpoint)})})
            

//     //     }
//     //     // this.props.saveCheckpoint(checkpoint)
//     // }



//     // deleteapln(checkpoint, actionplan, index) {
    
//     //     if (index >= 0 && index < checkpoint.cp_actionplans.length) {
//     //         checkpoint.cp_actionplans.splice(index, -1);
            
//     //         // Save the updated checkpoint
//     //         this.setState({ dataloaded: true }, () => {
//     //             this.props.saveCheckpoint(checkpoint);
//     //             this.setState({ dataloaded: false});
//     //         });
//     //     }
//     // }

//     deleteapln = (checkpoint, actionplan, index) => {
//         var temp_cp_actionplan = []
//         checkpoint.cp_actionplans.map((data, idx) => {
//             if (index !== idx) {
//                 temp_cp_actionplan.push(data)
//             }
//         })
//         checkpoint['cp_actionplans'] = temp_cp_actionplan
//         this.props.saveCheckpoint(checkpoint)
//     }







//     cancelapln(checkpoint, actionplan) {
//         var index = _.findIndex(checkpoint.cp_actionplans, { id: actionplan.id })
//         if (index !== -1) {
//             checkpoint.cp_actionplans[index]["isEdit"] = false
//         }
//         this.setState({
//             refresh: true
//         })
//     }

//     handleValidSubmit(evet, values) {
//         var index = _.findIndex(this.props.selectedCheckpoint.cp_actionplans, { id: this.props.data.id })
//         if(this.state.assigned_task_users.length >0){
//         this.addapln(this.props.selectedCheckpoint, values, index)
//         }
//         else{
//             this.setState({
//                 assign_users: true
//             })
//         }
//         console.log(values,'values')
//     }

//     onRemove=(selectedItem)=>{
        
//         if(selectedItem !== undefined){
//         console.log(selectedItem,this.state.assigned_task_users)
//         var assigned_task_users =[...this.state.assigned_task_users]
//         assigned_task_users =_.reject(assigned_task_users,{user_id : selectedItem._id === undefined ? selectedItem.user_id :selectedItem._id })
//         console.log(assigned_task_users,'assigned_task_users')
//         this.setState({
//             assigned_task_users
//         })
//     }

//     }

//     onSelectValues=(selectedList,selectedItem)=>{
//         if(selectedItem.name === "+Create New"){
//             console.log(selectedList,selectedItem,this.props.data.responsible_person,this.state.location_users,this.multiRef)
//             var selectedList= _.reject(selectedList,{name:"+Create New"})
//             this.multiRef.current.state.selectedValues =selectedList
//             this.setState({
//                 dataloaded : false
//             },()=>{
//                 this.setState({
//                     dataloaded : true,
//                     modal : true
//                 })
//             })
//         }
//         else{
//         var assigned_task_users=[...this.state.assigned_task_users]
//         var auditor_info = _.filter(this.props.location_info.audit_pbd_users,{audit_type :"1"})
//         assigned_task_users.push({
//             name : selectedItem.name,
//             user_id: selectedItem.user_id,
//             user_code : selectedItem.user_code !== undefined ? selectedItem.user_code :'',
//             user_category_name : selectedItem.user_lvl_name,
//             user_category_type : selectedItem.cat_type,
//             auditor_name : auditor_info[0].name,
//             auditor_id : auditor_info[0].user_id !== undefined ?auditor_info[0].user_id : auditor_info[0]._id ,


//         })

//         this.setState({
//             assigned_task_users,
//             asignee_email:selectedItem.name
//         },()=>{
//             console.log(this.state.assigned_task_users,'assigned_task_users')
//         })
//     }


//     }

//     toggle = () => {
//         this.setState(prevState => ({
//             modal: !prevState.modal
//         }));
//     }


//     handleRadioChange=(event)=>{
//         console.log(this.props.data, event.target.name, event.target.value,this.props.data)
//         this.props.data["ac_impact"]=event.target.name
//         this.setState({dataloaded : false},()=>{
//             this.setState({dataloaded : true})
//         })

//     }




//     render() {
//         if (this.state.dataloaded) {
//             var today = new Date()
//             const dd = today.getDate().toString().length == 1 ? "0" + today.getDate().toString() : today.getDate().toString()
//             const mm = String(today.getMonth() + 1).length == 1 ? "0" + String(today.getMonth() + 1) : today.getMonth() + 1
//             const yyyy = today.getFullYear()
//             const fromate_date = (today = yyyy + "-" + mm + "-" + dd) 
//             console.log(this.props.data,'this.props.data',this.props.location_info)
//             return (
//                 <Container className="border border-1 border-amber-300 p-3">
//                     {
//                         this.props.data.isEdit ?
//                             <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
//                                 {/* <label>delete index: {this.props.data.addindex !== undefined ? this.props.data.addindex : null}</label> */}
//                                 <Row className="mb-3">
//                                     <div className="d-sm-flex align-items-center justify-content-between">
//                                         <div className=" font-size-14">Action {this.props.index + 1}</div>
//                                         <div >
//                                             {this.props.data.status === "1" ? <Button className="btn me-3" color="danger" onClick={() => { this.cancelapln(this.props.selectedCheckpoint, this.props.data) }}>Cancel</Button> : null}
//                                         </div>
//                                     </div>
//                                 </Row>

//                                 <Row className="align-items-center">
//                                     <Col sm={"12"} lg={"12"}>
//                                         <div className="mb-3" >
                                           
//                                             <label>Observation<span className="text-danger">*</span> </label>
//                                             <AvField
//                                                 name="observation"
//                                                 value={this.props.data.observation}
//                                                 onChange={(e) => { this.setState({ actpln: e.target.value }) }}
//                                                 className="form-control"
//                                                 placeholder="Enter observation"
//                                                 type="textarea"
//                                                 required
//                                             />
//                                         </div>

//                                     </Col>
//                                 </Row>

//                                 <Row className="align-items-center">
//                                     <Col sm={"12"} lg={"12"}>
//                                         <div className="mb-3" >
//                                             <label>Root Cause</label>
//                                             <AvField
//                                                 name="root_cause"
//                                                 value={this.props.data.root_cause}
//                                                 onChange={(e) => { this.setState({ root_cause: e.target.value }) }}
//                                                 className="form-control"
//                                                 placeholder="Enter Root Cause"
//                                                 type="textarea"
//                                             />
//                                         </div>

//                                     </Col>
//                                 </Row>

//                                 <Row className="align-items-center">
//                                     <Col sm={"12"} lg={"12"}>
//                                         <div className="mb-3" >
//                                             <label>Action plan <span className="text-danger">*</span></label>
//                                             <AvField
//                                                 name="actionplan"
//                                                 value={this.props.data.actionplan}
//                                                 onChange={(e) => { this.setState({ actpln: e.target.value }) }}
//                                                 className="form-control"
//                                                 placeholder="Enter action plan"
//                                                 type="textarea"
//                                                 required
//                                             />
//                                         </div>

//                                     </Col>
//                                 </Row>
//                                 <Row >
//                                     <Col sm={"12"} lg={"4"} className="mb-2">
//                                         <label>Target date<span className="text-danger">*</span></label>
//                                         <AvField
//                                             name="target_date"
//                                             type="date"
//                                             errorMessage="Please provide a valid date."
//                                             className="form-control"
//                                             min={fromate_date}
//                                             value={this.props.data.target_date}
//                                             onKeyDown={(e)=>{e.preventDefault();}}
//                                             onChange={(e) => { this.setState({ target_date: e.target.value }) }}
//                                             validate={{ required: { value: true } }}
//                                             id="td"
//                                         />
//                                     </Col>

//                                     <Col sm={"12"} lg={"4"}>
//                                         <label>Assign to<span className="text-danger">*</span></label>
//                                         <div className="mb-3" >
//                                             <Multiselect
//                                             ref={this.multiRef}
//                                                 onRemove={(selectedList, selectedItem) => {
//                                                     this.onRemove(selectedItem)
//                                                 }
//                                                 }
//                                                 onSelect={(selectedList, selectedItem) => {
//                                                     this.onSelectValues(selectedList, selectedItem);
//                                                 }}
//                                                 options={this.state.location_users}
//                                                 displayValue="name"
//                                                 closeOnSelect={false}
//                                                 // selectedValues={this.props.data.responsible_person}
//                                                 selectedValues={this.props.data.responsible_person == undefined ? this.state.assigned_task_users : this.props.data.responsible_person}
//                                                 />
//                                                 {
//                                                     this.state.assign_users &&
//                                                     <div className="text-danger" style={{fontSize : 'smaller'}}>Please Assign Users</div>
//                                                 }




//                                             {/* <AvField
//                                                 name="to_email"
//                                                 value={this.props.data.to_email}
//                                                 onChange={(e) => { this.setState({ asignee_email: e.target.value }) }}
//                                                 className="form-control"
//                                                 placeholder="Assign to"
//                                                 type="email"
//                                                 errorMessage="Invalid Email"
//                                                 // validate={{
//                                                 //     required: { value: true },
//                                                 //     email: { value: true },
//                                                 // }}
//                                                 validate={{
//                                                     required: { value: true },
//                                                     pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, errorMessage: "Please enter a valid email address" },
//                                                 }}
//                                             /> */}
//                                         </div>

//                                     </Col>
                                    
//                                     {/* <Col sm={"12"} lg={"4"}>
//                                     <label>phone number</label>
//                                     <div className="mb-3" >
//                                         <AvField
//                                             name="to_phone"
//                                             value={this.props.data.to_phone}
//                                             onChange={(e) => { this.setState({ asignee_phone: e.target.value }) }}
//                                             className="form-control"
//                                             placeholder="Assign to"
//                                             type="number"
//                                             validate={{
//                                                 required: { value: true },
//                                                 maxLength: { value: 10, errorMessage: "Min 6 chars." },
//                                             }}

//                                         />
//                                     </div>

//                                 </Col> */}


//                                 </Row>
//                                 <Row>
//                                 <Col sm={"12"} lg={"12"}>
//                                     <label>Action Plan Impact Level :
//                                         {/* <span className="text-danger">*</span> */}
//                                         </label>
//                                     <div className="mb-3" >
//                                     <div style={{ display: 'flex', flexDirection: 'row' }}>
//                                                     <div className="form-check mx-2 form-radio-danger">
//                                                         <input
//                                                             className="form-check-input"
//                                                             type="radio"
//                                                             name="Critical"
//                                                             id="exampleRadios1"
//                                                             onChange={(e)=>this.handleRadioChange(e)}
//                                                             checked={this.props.data["ac_impact"]==="Critical"}
//                                                             value={this.props.data["ac_impact"]}

//                                                         />
//                                                         <label
//                                                             className="form-check-label text-danger"
//                                                             htmlFor="exampleRadios1"
//                                                         >
//                                                             Critical
//                                                         </label>
//                                                     </div>
//                                                     <div className="form-check mx-2 form-radio-warning">
//                                                         <input
//                                                             className="form-check-input"
//                                                             type="radio"
//                                                             name="High"
//                                                             id="exampleRadios2"
//                                                             onChange={(e)=>this.handleRadioChange(e)}
//                                                             checked={this.props.data["ac_impact"]==="High"}
//                                                             value={this.props.data["ac_impact"]}


//                                                         />
//                                                         <label
//                                                             className="form-check-label text-warning"
//                                                             htmlFor="exampleRadios2"
//                                                         >
//                                                             High
//                                                         </label>
//                                                     </div>
//                                                     <div className="form-check mx-2 form-radio-info">
//                                                         <input
//                                                             className="form-check-input"
//                                                             type="radio"
//                                                             name="Medium"
//                                                             id="exampleRadios3"
//                                                             onChange={(e)=>this.handleRadioChange(e)}
//                                                             checked={this.props.data["ac_impact"]==="Medium"}
//                                                             value={this.props.data["ac_impact"]}


//                                                         />
//                                                         <label
//                                                             className="form-check-label text-info"
//                                                             htmlFor="exampleRadios3"
//                                                         >
//                                                             Medium
//                                                         </label>
//                                                     </div>
//                                                     <div className="form-check mx-2 form-radio-success">
//                                                         <input
//                                                             className="form-check-input"
//                                                             type="radio"
//                                                             name="Low"
//                                                             id="exampleRadios4"
//                                                             onChange={(e)=>this.handleRadioChange(e)}
//                                                             checked={this.props.data["ac_impact"]==="Low"}
//                                                             value={this.props.data["ac_impact"]}


//                                                         />
//                                                         <label
//                                                             className="form-check-label text-success"
//                                                             htmlFor="exampleRadios4"
//                                                         >
//                                                             Low
//                                                         </label>
//                                                     </div>
//                                                     <div className="form-check mx-2 form-radio-primary">
//                                                         <input
//                                                             className="form-check-input"
//                                                             type="radio"
//                                                             name="No impact"
//                                                             id="exampleRadios5"
//                                                             onChange={(e)=>this.handleRadioChange(e)}
//                                                             value={this.props.data["ac_impact"]}
//                                                             checked={this.props.data["ac_impact"]==="No impact"}

//                                                         />
//                                                         <label
//                                                             className="form-check-label text-primary"
//                                                             htmlFor="exampleRadios5"
//                                                         >
//                                                             No impact
//                                                         </label>
//                                                         {/* {
//                                                             console.log(this.props.data,'this.props.data')
//                                                         } */}
//                                                     </div>
//                                                 </div>


//                                         </div>
//                                     </Col>
//                                 </Row>

//                                 <Row >
//                                     <div className="d-sm-flex align-items-center justify-content-between">
//                                         <div className=" font-size-14"></div>
//                                         <div >
//                                             <Button className="btn me-2" color="success">Save</Button>
//                                             <Button className="btn me-2" color="danger" onClick={() => { this.deleteapln(this.props.selectedCheckpoint, this.props.data, this.props.index) }}>Delete</Button>
//                                             {/* <Button className="btn" color="primary" onClick={() => { this.props.onadd() }}>Add Another Action plan</Button> */}
//                                         </div>
//                                     </div>
//                                 </Row>

//                             </AvForm> :
//                             <div>
//                                 <Row className="mb-3">
//                                     <div className="d-sm-flex align-items-center justify-content-between">
//                                         <div>Action {this.props.index + 1}</div>
//                                         {
//                                             this.props.audit_status !== "3" && this.props.audit_status !== "4" ?
//                                                 <div >
//                                                     <Button className="btn" color="primary" onClick={() => { this.editapln(this.props.selectedCheckpoint, this.props.data) }}>Edit</Button>
//                                                 </div> : null
//                                         }

//                                     </div>
//                                 </Row>
//                                 <Row className="align-items-center mb-3">
//                                     <Col sm={"12"} lg={"12"}>
//                                         <label className="text-primary font-size-12">Observation<span className="text-danger">*</span></label>
//                                         <div>{this.props.data.observation}</div>
//                                     </Col>
//                                 </Row>
//                                 <Row className="align-items-center mb-3">
//                                     <Col sm={"12"} lg={"12"}>
//                                         <label className="text-primary font-size-12">Root Cause</label>
//                                         <div>{this.props.data.root_cause}</div>
//                                     </Col>
//                                 </Row>
//                                 <Row className="align-items-center mb-3">
//                                     <Col sm={"12"} lg={"12"}>
//                                         <label className="text-primary font-size-12">Action plan<span className="text-danger">*</span> </label>
//                                         <div>{this.props.data.actionplan}</div>
//                                     </Col>
//                                 </Row>
//                                 <Row >
//                                     <Col sm={"12"} lg={"4"} className="mb-2">
//                                         <label className="text-primary font-size-12">Target date<span className="text-danger">*</span></label>
//                                         {/* <div>{this.props.data.target_date}</div> */}
//                                         <div>{moment(this.props.data.target_date).format("DD-MMM-YYYY")}</div>
//                                     </Col>

//                                     <Col sm={"12"} lg={"4"}>
//                                         <label className="text-primary font-size-12">Assign to email<span className="text-danger">*</span></label>
//                                         <div>{this.props.data.to_email}</div>
//                                     </Col>

//                                     {/* <Col sm={"12"} lg={"4"}>
//                                     <label className="text-primary font-size-12">Phone number</label>
//                                     <div>{this.props.data.to_phone}</div>
//                                 </Col> */}


//                                 </Row>
//                             </div>
//                     }
//                     {
//                         this.state.modal &&
//                         <CrudUser modal={this.state.modal} toggle={this.toggle} client_info={this.state.client_info} location_info={this.props.location_info}/>



//                     }

//                 </Container>
                



//             )
//         } else {
//             return null
//         }
//     }

// }











// // import React, { Component } from "react";
// // import ReactDOM from 'react-dom';
// // import MetaTags from 'react-meta-tags';
// // import {
// //     Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
// //     Input,
// // } from "reactstrap";
// // import Dropzone from "react-dropzone"
// // import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// // import { Link, withRouter } from "react-router-dom"



// // import moment from 'moment'

// // const _ = require('lodash')




// // export default class CAPA extends React.Component {

// //     constructor(props) {
// //         super(props)
// //         this.state = {
// //             dataloaded: false,
// //             previousData: {}
// //         }
// //         this.handleValidSubmit = this.handleValidSubmit.bind(this)
// //     }


// //     addapln(checkpoint, actionplan, index) {
// //         checkpoint.cp_actionplans[index]["observation"] = actionplan.observation
// //         checkpoint.cp_actionplans[index]["root_cause"] = actionplan.root_cause
// //         checkpoint.cp_actionplans[index]["actionplan"] = actionplan.actionplan
// //         checkpoint.cp_actionplans[index]["target_date"] = actionplan.target_date
// //         checkpoint.cp_actionplans[index]["to_email"] = actionplan.to_email
// //         // checkpoint.cp_actionplans[index]["to_phone"] = actionplan.to_phone
// //         checkpoint.cp_actionplans[index]["status"] = "1"
// //         checkpoint.cp_actionplans[index]["isEdit"] = false
// //         this.props.saveCheckpoint(checkpoint)
// //     }

// //     editapln(checkpoint, actionplan) {
// //         var index = _.findIndex(checkpoint.cp_actionplans, { id: actionplan.id })
// //         if (index !== -1) {
// //             checkpoint.cp_actionplans[index]["isEdit"] = true
// //         }
// //         this.setState({
// //             refresh: true
// //         })
// //     }

// //     deleteapln(checkpoint, actionplan) {
// //         console.log(checkpoint, actionplan)
// //         var getIndex = _.findIndex(checkpoint.cp_actionplans, { id: actionplan.id })
// //         if (getIndex !== -1) {
// //             checkpoint.cp_actionplans.splice(getIndex, 1)
// //         }
// //         this.props.saveCheckpoint(checkpoint)
// //     }

// //     cancelapln(checkpoint, actionplan) {
// //         var index = _.findIndex(checkpoint.cp_actionplans, { id: actionplan.id })
// //         if (index !== -1) {
// //             checkpoint.cp_actionplans[index]["isEdit"] = false
// //         }
// //         this.setState({
// //             refresh: true
// //         })
// //     }

// //     handleValidSubmit(evet, values) {
// //         console.log(values)
// //         var index = _.findIndex(this.props.selectedCheckpoint.cp_actionplans, { id: this.props.data.id })
// //         console.log("index", index, this.props.data)
// //         this.addapln(this.props.selectedCheckpoint, values, index)
// //     }






// //     render() {
// //         return (
// //             <Container className="border border-1 border-amber-300 p-3">
// //                 {
// //                     this.props.data.isEdit ?
// //                         <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
// //                             <Row className="mb-3">
// //                                 <div className="d-sm-flex align-items-center justify-content-between">
// //                                     <div className=" font-size-14">Action {this.props.index + 1}</div>
// //                                     <div >
// //                                         {this.props.data.status === "1" ? <Button className="btn me-3" color="danger" onClick={() => { this.cancelapln(this.props.selectedCheckpoint, this.props.data) }}>Cancel</Button> : null}
// //                                     </div>
// //                                 </div>
// //                             </Row>

// //                             <Row className="align-items-center">
// //                                 <Col sm={"12"} lg={"12"}>
// //                                     <div className="mb-3" >
// //                                         <label>Observation<span className="text-danger">*</span> </label>
// //                                         <AvField
// //                                             name="observation"
// //                                             value={this.props.data.observation}
// //                                             onChange={(e) => { this.setState({ actpln: e.target.value }) }}
// //                                             className="form-control"
// //                                             placeholder="Enter observation"
// //                                             type="textarea"
// //                                             required
// //                                         />
// //                                     </div>

// //                                 </Col>
// //                             </Row>

// //                             <Row className="align-items-center">
// //                                 <Col sm={"12"} lg={"12"}>
// //                                     <div className="mb-3" >
// //                                         <label>Root Cause</label>
// //                                         <AvField
// //                                             name="root_cause"
// //                                             value={this.props.data.root_cause}
// //                                             onChange={(e) => { this.setState({ root_cause: e.target.value }) }}
// //                                             className="form-control"
// //                                             placeholder="Enter Root Cause"
// //                                             type="textarea"
// //                                         />
// //                                     </div>

// //                                 </Col>
// //                             </Row>

// //                             <Row className="align-items-center">
// //                                 <Col sm={"12"} lg={"12"}>
// //                                     <div className="mb-3" >
// //                                         <label>Action plan <span className="text-danger">*</span></label>
// //                                         <AvField
// //                                             name="actionplan"
// //                                             value={this.props.data.actionplan}
// //                                             onChange={(e) => { this.setState({ actpln: e.target.value }) }}
// //                                             className="form-control"
// //                                             placeholder="Enter action plan"
// //                                             type="textarea"
// //                                             required
// //                                         />
// //                                     </div>

// //                                 </Col>
// //                             </Row>
// //                             <Row >
// //                                 <Col sm={"12"} lg={"4"} className="mb-2">
// //                                     <label>Target date<span className="text-danger">*</span></label>
// //                                     <AvField
// //                                         name="target_date"
// //                                         type="date"
// //                                         errorMessage="Please provide a valid date."
// //                                         className="form-control"
// //                                         value={this.props.data.target_date}
// //                                         onChange={(e) => { this.setState({ target_date: e.target.value }) }}
// //                                         validate={{ required: { value: true } }}
// //                                         id="td"
// //                                     />
// //                                 </Col>

// //                                 <Col sm={"12"} lg={"4"}>
// //                                     <label>Assign to<span className="text-danger">*</span></label>
// //                                     <div className="mb-3" >
// //                                         <AvField
// //                                             name="to_email"
// //                                             value={this.props.data.to_email}
// //                                             onChange={(e) => { this.setState({ asignee_email: e.target.value }) }}
// //                                             className="form-control"
// //                                             placeholder="Assign to"
// //                                             type="text"
// //                                             errorMessage="Please Assign to the user"
// //                                             validate={{
// //                                                 required: { value: true },
// //                                                 // email: { value: true },
// //                                             }}
// //                                         />
// //                                     </div>

// //                                 </Col>
// //                                 {/* <Col sm={"12"} lg={"4"}>
// //                                     <label>phone number</label>
// //                                     <div className="mb-3" >
// //                                         <AvField
// //                                             name="to_phone"
// //                                             value={this.props.data.to_phone}
// //                                             onChange={(e) => { this.setState({ asignee_phone: e.target.value }) }}
// //                                             className="form-control"
// //                                             placeholder="Assign to"
// //                                             type="number"
// //                                             validate={{
// //                                                 required: { value: true },
// //                                                 maxLength: { value: 10, errorMessage: "Min 6 chars." },
// //                                             }}

// //                                         />
// //                                     </div>

// //                                 </Col> */}


// //                             </Row>
// //                             <Row >
// //                                 <div className="d-sm-flex align-items-center justify-content-between">
// //                                     <div className=" font-size-14"></div>
// //                                     <div >
// //                                         <Button className="btn me-2" color="success">Save</Button>
// //                                         <Button className="btn me-2" color="danger" onClick={() => { this.deleteapln(this.props.selectedCheckpoint, this.props.data) }}>Delete</Button>
// //                                         <Button className="btn" color="primary" onClick={() => { this.props.onadd() }}>Add Another Action plan</Button>
// //                                     </div>
// //                                 </div>
// //                             </Row>

// //                         </AvForm> :
// //                         <div>
// //                             <Row className="mb-3">
// //                                 <div className="d-sm-flex align-items-center justify-content-between">
// //                                     <div>Action {this.props.index + 1}</div>
// //                                     {
// //                                         this.props.audit_status !== "3" && this.props.audit_status !== "4" ?
// //                                             <div >
// //                                                 <Button className="btn" color="primary" onClick={() => { this.editapln(this.props.selectedCheckpoint, this.props.data) }}>Edit</Button>
// //                                             </div> : null
// //                                     }

// //                                 </div>
// //                             </Row>
// //                             <Row className="align-items-center mb-3">
// //                                 <Col sm={"12"} lg={"12"}>
// //                                     <label className="text-primary font-size-12">Observation<span className="text-danger">*</span></label>
// //                                     <div>{this.props.data.observation}</div>
// //                                 </Col>
// //                             </Row>
// //                             <Row className="align-items-center mb-3">
// //                                 <Col sm={"12"} lg={"12"}>
// //                                     <label className="text-primary font-size-12">Root Cause</label>
// //                                     <div>{this.props.data.root_cause}</div>
// //                                 </Col>
// //                             </Row>
// //                             <Row className="align-items-center mb-3">
// //                                 <Col sm={"12"} lg={"12"}>
// //                                     <label className="text-primary font-size-12">Action plan<span className="text-danger">*</span> </label>
// //                                     <div>{this.props.data.actionplan}</div>
// //                                 </Col>
// //                             </Row>
// //                             <Row >
// //                                 <Col sm={"12"} lg={"4"} className="mb-2">
// //                                     <label className="text-primary font-size-12">Target date<span className="text-danger">*</span></label>
// //                                     <div>{this.props.data.target_date}</div>
// //                                 </Col>

// //                                 <Col sm={"12"} lg={"4"}>
// //                                     <label className="text-primary font-size-12">Assign to email<span className="text-danger">*</span></label>
// //                                     <div>{this.props.data.to_email}</div>
// //                                 </Col>

// //                                 {/* <Col sm={"12"} lg={"4"}>
// //                                     <label className="text-primary font-size-12">Phone number</label>
// //                                     <div>{this.props.data.to_phone}</div>
// //                                 </Col> */}


// //                             </Row>
// //                         </div>
// //                 }

// //             </Container>
// //         )
// //     }

// // }