import React, { Component } from "react"
import PropTypes from "prop-types"
import { map, get } from "lodash"
import { Card, CardBody, Col, Media, Row } from "reactstrap"

class EntityDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false
    }
  }

  render()
  {
  return (
    <Card>
      <CardBody>
        <Media>

          <Media className="overflow-hidden" body>
            <h5 className="text-truncate font-size-15">{this.props.project.entity_name}</h5>
            <p className="text-muted">{this.props.project.entity_code}</p>
          </Media>
        </Media>
      </CardBody>
    </Card>
  )
  }
}


export default EntityDetail