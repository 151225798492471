import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button } from "reactstrap"
import JobListGlobalFilter from "../components/Common/GlobalSearchFilter";
import { Link } from "react-router-dom"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col xxl={3} lg={6}>
        <input type="search" className="form-control" id="search-bar-0" value={value || ""} placeholder={`${count} records...`} onChange={e => { setValue(e.target.value); onChange(e.target.value) }} />
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  btnClick,
  btnName,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  customPageSizeOptions,
  iscustomPageSizeOptions,
  isPagination,
  dynamicBtn,
  isShowingPageLength,
  paginationDiv,
  pagination,
  tableClass,
  theadClass
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          // {
          //   desc: true,
          // },
        ],
      },
    },
    useGlobalFilter,
    // useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  return (
    <Fragment>
      <Row className="mb-2">
        {iscustomPageSizeOptions &&
          <Col md={customPageSizeOptions ? 2 : 1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        }
  
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {dynamicBtn && (
          // <Col sm="7" xxl="8">
            <div className="text-sm-end">
         
                <Button onClick={() => { btnClick() }} className="btn-sm" type="button" outline color="primary">
                <i className="mdi mdi-plus-circle-outline me-1" />
                {btnName}
              </Button>
              {/* } */}
            
              {/* <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button> */}
            </div>
          // </Col>
        )}
        {isAddCustList && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive">
        <Table {...getTableProps()} className={tableClass}>
          {/* <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.isSort ? "sorting" : ''}>
                    <div className="m-0" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead> */}
          <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.isSort ? "sorting" : ''}>
                    <div className="m-0" >
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      {
        isPagination && (
          <Row className="justify-content-between align-items-center">
            {isShowingPageLength && <div className="col-sm">
              <div className="text-muted">Showing <span className="fw-semibold">{page.length}</span> of <span className="fw-semibold">{data.length}</span> entries</div>
            </div>}
            <div className={paginationDiv}>
              <ul className={pagination}>
                <li className={`page-item ${!canPreviousPage ? "disabled" : ''}`}>
                  <Link to="#" className="page-link" onClick={previousPage}>
                    <i className="mdi mdi-chevron-left"></i>
                  </Link>
                </li>
                {pageOptions.map((item, key) => (
                  <React.Fragment key={key}>
                    <li className={pageIndex === item ? "page-item active" : "page-item"}>
                      <Link to="#" className="page-link" onClick={() => {gotoPage(item);console.log(item,'item')}}>{item + 1}</Link>
                    </li>
                  </React.Fragment>
                ))}
                <li className={`page-item ${!canNextPage ? "disabled" : ''}`}>
                  <Link to="#" className="page-link" onClick={nextPage}>
                    <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </Row>
        )
      }
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer















// import React, { Fragment } from "react";
// import PropTypes from "prop-types";
// import {
//   useTable,
//   useGlobalFilter,
//   useAsyncDebounce,
//   useSortBy,
//   useFilters,
//   useExpanded,
//   usePagination,
// } from "react-table";
// import { Link } from "react-router-dom";
// import { Table, Row, Col, Button, Input, CardBody,Card } from "reactstrap";
// import { Filter, DefaultColumnFilter } from "./filters";
// import JobListGlobalFilter from "../components/Common/GlobalSearchFilter";

// // Define a default UI for filtering
// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
//   isJobListGlobalFilter
// }) {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce(value => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <React.Fragment>
//       <Col md={4}>
//         <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
//           <div className="position-relative">
//             <label htmlFor="search-bar-0" className="search-label">
//               <span id="search-bar-0-label" className="sr-only">
//                 Search this table
//               </span>
//               <input
//                 onChange={e => {
//                   setValue(e.target.value);
//                   onChange(e.target.value);
//                 }}
//                 id="search-bar-0"
//                 type="text"
//                 className="form-control"
//                 placeholder={`${count} records...`}
//                 value={value || ""}
//               />
//             </label>
//             <i className="bx bx-search-alt search-icon"></i>
//           </div>
//         </div>

//       </Col>
//       {isJobListGlobalFilter && (
//         <JobListGlobalFilter />
//       )}

//     </React.Fragment>
//   );
// }

// const TableContainer = ({
//   columns,
//   data,
//   btnName,
//   dynamicBtn,
//   btnClick,
//   isGlobalFilter,
//   isJobListGlobalFilter,
//   isAddOptions,
//   isAddUserList,
//   handleOrderClicks,
//   handleUserClick,
//   handleCustomerClick,
//   isAddCustList,
//   customPageSize,
//   className,
//   customPageSizeOptions,

// }) => {
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     prepareRow,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     pageCount,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     state,
//     preGlobalFilteredRows,
//     setGlobalFilter,
//     state: { pageIndex, pageSize },
//   } = useTable(
//     {
//       columns,
//       data,
//       defaultColumn: { Filter: DefaultColumnFilter },
//       initialState: {
//         pageIndex: 0,
//         pageSize: customPageSize,
//         sortBy: [
//           {
//             desc: true,
//           },
//         ],
//       },
//     },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     useExpanded,
//     usePagination
//   );

//   const generateSortingIndicator = column => {
//     return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
//   };

//   const onChangeInSelect = event => {
//     setPageSize(Number(event.target.value));
//   };

//   const onChangeInInput = event => {
//     const page = event.target.value ? Number(event.target.value) - 1 : 0;
//     gotoPage(page);
//   };
//   return (
//     <Fragment>
//       {
//         console.log(dynamicBtn,'dynamicBtn')
//       }
//       <Row className="mb-2">
//         <Col md={customPageSizeOptions ? 2 : 1}>
//           {/* <select
//             className="form-select"
//             value={pageSize}
//             onChange={onChangeInSelect}
//           >
//             {[10, 20, 30, 40, 50].map(pageSize => (
//               <option key={pageSize} value={pageSize}>
//                 Show {pageSize}
//               </option>
//             ))}
//           </select> */}
//         </Col>
//         {isGlobalFilter && (
//           <GlobalFilter
//             preGlobalFilteredRows={preGlobalFilteredRows}
//             globalFilter={state.globalFilter}
//             setGlobalFilter={setGlobalFilter}
//             isJobListGlobalFilter={isJobListGlobalFilter}
//           />
//         )}
//         {isAddOptions && (
//           <Col sm="7">
//             <div className="text-sm-end">
//               <Button
//                 type="button"
//                 color="success"
//                 className="btn-rounded  mb-2 me-2"
//                 onClick={handleOrderClicks}
//               >
//                 <i className="mdi mdi-plus me-1" />
//                 Add New Order
//               </Button>
//             </div>
//           </Col>
//         )}
//         {isAddUserList && (
//           <Col sm="7">
//             <div className="text-sm-end">
//               <Button
//                 type="button"
//                 color="primary"
//                 className="btn mb-2 me-2"
//                 onClick={handleUserClick}
//               >
//                 <i className="mdi mdi-plus-circle-outline me-1" />
//                 Create New User
//               </Button>
//             </div>
//           </Col>
//         )}
//         {isAddCustList && (
//           <Col sm="7">
//             <div className="text-sm-end">
//               <Button
//                 type="button"
//                 color="success"
//                 className="btn-rounded mb-2 me-2"
//                 onClick={handleCustomerClick}
//               >
//                 <i className="mdi mdi-plus me-1" />
//                 New Customers
//               </Button>
//             </div>
//           </Col>
//         )}
//       </Row>
//       <Card>
//                                <CardBody>
//       <Row className="mb-3 mt-2">
//          <Col md={6}>
//           <div className="select-container">
//              <select
//               value={pageSize}
//               onChange={onChangeInSelect}
//               style={{
//                 fontFamily: 'Arial, sans-serif',
//                 fontSize: '14px',
//                 width: '25vh',
//                 padding: '8px',
//                 borderRadius: '4px',
//                 border: '1px solid #ccc'
//               }}
//             >
//               {[10, 20, 30, 40, 50].map((pageSize) => (
//                 <option key={pageSize} value={pageSize}>
//                   Show {pageSize}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="text-sm-end">
//             {/* <Link to="/add-new-client"> */}
//               {/* <Button
//                 style={{
//                   fontFamily: 'Arial, sans-serif',
//                   fontSize: '14px',
//                   width: '25vh',
//                   padding: '8px',
//                   borderRadius: '4px',
//                   border: '1px solid #ccc'
//                 }}
//                 type="button"
//                 color="primary"
//                 className="btn"
//                 outline
//                 onClick={() => { sessionStorage.removeItem('client_id') }}
//               > */}
//               {
//                 dynamicBtn &&
//                 <Button onClick={() => { btnClick() }} className="btn-sm" type="button" outline color="primary">
//                 <i className="mdi mdi-plus-circle-outline me-1" />
//                 {btnName}
//               </Button>
//               }
            
//             {/* </Link> */}
//           </div>
//         </Col>
//       </Row>
//       </CardBody>
//       </Card>
     

//       <div className="table-responsive react-table">
//         <Table bordered hover {...getTableProps()} className={className}>
//           <thead className="table-light table-nowrap">
           
//             {headerGroups.map(headerGroup => (
//               <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map(column => (
//                   <th key={column.id}>
//                     <div className="mb-2" {...column.getSortByToggleProps()}>
//                       {column.render("Header")}
//                       {generateSortingIndicator(column)}
//                     </div>
//                     <Filter column={column} />
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>

//           <tbody >
//             {page.map(row => {
//               prepareRow(row);
//               return (
//                 <Fragment key={row.getRowProps().key}>
//                   <tr>
//                     {row.cells.map(cell => {
//                       return (
//                         <td key={cell.id} {...cell.getCellProps()}>
//                           {cell.render("Cell")}
//                         </td>
//                       );
//                     })}
//                   </tr>
//                 </Fragment>
//               );
//             })}
//           </tbody>
//         </Table>
//       </div>

//       <Row className="justify-content-md-end justify-content-center align-items-center">
//         <Col className="col-md-auto">
//           <div className="d-flex gap-1">
//             <Button
//               color="primary"
//               onClick={() => gotoPage(0)}
//               disabled={!canPreviousPage}
//             >
//               {"<<"}
//             </Button>
//             <Button
//               color="primary"
//               onClick={previousPage}
//               disabled={!canPreviousPage}
//             >
//               {"<"}
//             </Button>
//           </div>
//         </Col>
//         <Col className="col-md-auto d-none d-md-block">
//           Page{" "}
//           <strong>
//             {pageIndex + 1} of {pageOptions.length}
//           </strong>
//         </Col>
//         <Col className="col-md-auto">
//           <Input
//             type="number"
//             min={1}
//             style={{ width: 70 }}
//             max={pageOptions.length}
//             defaultValue={pageIndex + 1}
//             onChange={onChangeInInput}
//           />
//         </Col>

//         <Col className="col-md-auto">
//           <div className="d-flex gap-1">
//             <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
//               {">"}
//             </Button>
//             <Button
//               color="primary"
//               onClick={() => gotoPage(pageCount - 1)}
//               disabled={!canNextPage}
//             >
//               {">>"}
//             </Button>
//           </div>
//         </Col>
//       </Row>
//     </Fragment>
//   );
// };

// TableContainer.propTypes = {
//   preGlobalFilteredRows: PropTypes.any,
// };

// export default TableContainer;



















// // import React, { Fragment } from "react";
// // import PropTypes from "prop-types";
// // import {
// //   useTable,
// //   useGlobalFilter,
// //   useAsyncDebounce,
// //   useSortBy,
// //   useFilters,
// //   useExpanded,
// //   usePagination,
// // } from "react-table";
// // import { Table, Row, Col, Button, Input, CardBody, Card } from "reactstrap";
// // import { Filter, DefaultColumnFilter } from "./filters";
// // import JobListGlobalFilter from "./GlobalSearchFilter";
// // import { Link } from 'react-router-dom';

// // // Define a default UI for filtering
// // function GlobalFilter({
// //   preGlobalFilteredRows,
// //   globalFilter,
// //   setGlobalFilter,
// //   isJobListGlobalFilter
// // }) {
// //   const count = preGlobalFilteredRows.length;
// //   const [value, setValue] = React.useState(globalFilter);
// //   const onChange = useAsyncDebounce(value => {
// //     setGlobalFilter(value || undefined);
// //   }, 200);

// //   return (
// //     <React.Fragment>
// //       <Col md={4}>
// //         <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
// //           <div className="position-relative">
// //             <label htmlFor="search-bar-0" className="search-label">
// //               <span id="search-bar-0-label" className="sr-only">
// //                 Search this table
// //               </span>
// //               <input
// //                 onChange={e => {
// //                   setValue(e.target.value);
// //                   onChange(e.target.value);
// //                 }}
// //                 id="search-bar-0"
// //                 type="text"
// //                 className="form-control"
// //                 placeholder={`${count} records...`}
// //                 value={value || ""}
// //               />
// //             </label>
// //             <i className="bx bx-search-alt search-icon"></i>
// //           </div>
// //         </div>

// //       </Col>
// //       {isJobListGlobalFilter && (
// //         <JobListGlobalFilter />
// //       )}

// //     </React.Fragment>
// //   );
// // }

// // const TableContainer = ({
// //   columns,
// //   data,
// //   isGlobalFilter,
// //   isJobListGlobalFilter,
// //   isAddOptions,
// //   isAddUserList,
// //   handleOrderClicks,
// //   handleUserClick,
// //   handleCustomerClick,
// //   isAddCustList,
// //   customPageSize,
// //   className,
// //   customPageSizeOptions,

// // }) => {
// //   const {
// //     getTableProps,
// //     getTableBodyProps,
// //     headerGroups,
// //     page,
// //     prepareRow,
// //     canPreviousPage,
// //     canNextPage,
// //     pageOptions,
// //     pageCount,
// //     gotoPage,
// //     nextPage,
// //     previousPage,
// //     setPageSize,
// //     state,
// //     preGlobalFilteredRows,
// //     setGlobalFilter,
// //     state: { pageIndex, pageSize },
// //   } = useTable(
// //     {
// //       columns,
// //       data,
// //       defaultColumn: { Filter: DefaultColumnFilter },
// //       initialState: {
// //         pageIndex: 0,
// //         pageSize: customPageSize,
// //         sortBy: [
// //           {
// //             desc: true,
// //           },
// //         ],
// //       },
// //     },
// //     useGlobalFilter,
// //     useFilters,
// //     useSortBy,
// //     useExpanded,
// //     usePagination
// //   );

// //   const generateSortingIndicator = column => {
// //     return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
// //   };

// //   const onChangeInSelect = event => {
// //     setPageSize(Number(event.target.value));
// //   };

// //   const onChangeInInput = event => {
// //     const page = event.target.value ? Number(event.target.value) - 1 : 0;
// //     gotoPage(page);
// //   };
// //   return (
// //     <Fragment>
// //       {/* <Row className="mb-2">
// //         <Col md={customPageSizeOptions ? 2 : 1}>
// //           <div className="select-container">
// //             <select
// //               value={pageSize}
// //               onChange={onChangeInSelect}
// //               style={{
// //                 fontFamily: 'Arial, sans-serif',
// //                 fontSize: '14px',
// //                 width: '25vh',
// //                 padding: '8px',
// //                 borderRadius: '4px',
// //                 border: '1px solid #ccc'
// //               }}
// //             >
// //               {[10, 20, 30, 40, 50].map((pageSize) => (
// //                 <option key={pageSize} value={pageSize}>
// //                   Show {pageSize}
// //                 </option>
// //               ))}
// //             </select>
// //           </div>        
// //         </Col>
// //         {isGlobalFilter && (
// //           <GlobalFilter
// //             preGlobalFilteredRows={preGlobalFilteredRows}
// //             globalFilter={state.globalFilter}
// //             setGlobalFilter={setGlobalFilter}
// //             isJobListGlobalFilter={isJobListGlobalFilter}
// //           />
// //         )}
// //         {isAddOptions && (
// //           <Col sm="7">
// //             <div className="text-sm-end">
// //               <Button
// //                 type="button"
// //                 color="success"
// //                 className="btn-rounded  mb-2 me-2"
// //                 onClick={handleOrderClicks}
// //               >
// //                 <i className="mdi mdi-plus me-1" />
// //                 Add New Order
// //               </Button>
// //             </div>
// //           </Col>
// //         )}
// //         {isAddUserList && (
// //           <Col sm="7">
// //             <div className="text-sm-end">
// //               <Button
// //                 type="button"
// //                 color="primary"
// //                 className="btn mb-2 me-2"
// //                 onClick={handleUserClick}
// //               >
// //                 <i className="mdi mdi-plus-circle-outline me-1" />
// //                 Create New User
// //               </Button>
// //             </div>
// //           </Col>
// //         )}
// //         {isAddCustList && (
// //           <Col sm="7">
// //             <div className="text-sm-end">
// //               <Button
// //                 type="button"
// //                 color="success"
// //                 className="btn-rounded mb-2 me-2"
// //                 onClick={handleCustomerClick}
// //               >
// //                 <i className="mdi mdi-plus me-1" />
// //                 New Customers
// //               </Button>
// //             </div>
// //           </Col>
// //         )}
// //       </Row> */}
// //  <Card>
// //                                 <CardBody>
// //       <Row className="mb-3 mt-2">
// //         <Col md={6}>
// //           <div className="select-container">
// //             <select
// //               value={pageSize}
// //               onChange={onChangeInSelect}
// //               style={{
// //                 fontFamily: 'Arial, sans-serif',
// //                 fontSize: '14px',
// //                 width: '25vh',
// //                 padding: '8px',
// //                 borderRadius: '4px',
// //                 border: '1px solid #ccc'
// //               }}
// //             >
// //               {[10, 20, 30, 40, 50].map((pageSize) => (
// //                 <option key={pageSize} value={pageSize}>
// //                   Show {pageSize}
// //                 </option>
// //               ))}
// //             </select>
// //           </div>
// //         </Col>
// //         <Col md={6}>
// //           <div className="text-sm-end">
// //             <Link to="/add-new-client">
// //               {/* <Button
// //                 style={{
// //                   fontFamily: 'Arial, sans-serif',
// //                   fontSize: '14px',
// //                   width: '25vh',
// //                   padding: '8px',
// //                   borderRadius: '4px',
// //                   border: '1px solid #ccc'
// //                 }}
// //                 type="button"
// //                 color="primary"
// //                 className="btn"
// //                 outline
// //                 onClick={() => { sessionStorage.removeItem('client_id') }}
// //               > */}
// //               <Button onClick={() => { sessionStorage.removeItem('client_id') }} className="btn-sm" type="button" outline color="primary">
// //                 <i className="mdi mdi-plus-circle-outline me-1" />
// //                 Add new Client
// //               </Button>
// //             </Link>
// //           </div>
// //         </Col>
// //       </Row>
// //       </CardBody>
// //       </Card>
     
// //       <Card>
// //         <CardBody>
// //           <div className="table-responsive react-table">
// //             <Table bordered hover {...getTableProps()} className={className}>
// //               <thead className="table-light table-nowrap">
// //                 {headerGroups.map(headerGroup => (
// //                   <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
// //                     {headerGroup.headers.map(column => (
// //                       <th key={column.id}>
// //                         <div className="mb-2" {...column.getSortByToggleProps()}>
// //                           {column.render("Header")}
// //                           {generateSortingIndicator(column)}
// //                         </div>
// //                         <Filter column={column} />
// //                       </th>
// //                     ))}
// //                   </tr>
// //                 ))}
// //               </thead>
// //                       {
// //                         console.log(page)
// //                       }
// //               <tbody {...getTableBodyProps()}>
// //                 {page.map(row => {
// //                   prepareRow(row);
// //                   return (
// //                     <Fragment key={row.getRowProps().key}>
// //                       <tr>
// //                         {row.cells.map(cell => {
// //                           return (
// //                             <td key={cell.id} {...cell.getCellProps()}>
// //                               {cell.render("Cell")}
// //                             </td>
// //                           );
// //                         })}
// //                       </tr>
// //                     </Fragment>
// //                   );
// //                 })}
// //               </tbody>
// //             </Table>
// //           </div>
// //         </CardBody>
// //       </Card>

// //       <Row className="justify-content-md-end justify-content-center align-items-center">
// //         <Col className="col-md-auto">
// //           <div className="d-flex gap-1">
// //             <Button
// //               color="primary"
// //               onClick={() => gotoPage(0)}
// //               disabled={!canPreviousPage}
// //             >
// //               {"<<"}
// //             </Button>
// //             <Button
// //               color="primary"
// //               onClick={previousPage}
// //               disabled={!canPreviousPage}
// //             >
// //               {"<"}
// //             </Button>
// //           </div>
// //         </Col>
// //         <Col className="col-md-auto d-none d-md-block">
// //           Page{" "}
// //           <strong>
// //             {pageIndex + 1} of {pageOptions.length}
// //           </strong>
// //         </Col>
// //         <Col className="col-md-auto">
// //           <Input
// //             type="number"
// //             min={1}
// //             style={{ width: 70 }}
// //             max={pageOptions.length}
// //             defaultValue={pageIndex + 1}
// //             onChange={onChangeInInput}
// //           />
// //         </Col>

// //         <Col className="col-md-auto">
// //           <div className="d-flex gap-1">
// //             <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
// //               {">"}
// //             </Button>
// //             <Button
// //               color="primary"
// //               onClick={() => gotoPage(pageCount - 1)}
// //               disabled={!canNextPage}
// //             >
// //               {">>"}
// //             </Button>
// //           </div>
// //         </Col>
// //       </Row>
// //     </Fragment>
// //   );
// // };

// // TableContainer.propTypes = {
// //   preGlobalFilteredRows: PropTypes.any,
// // };

// // export default TableContainer;