import React, { useEffect, useState } from 'react'
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
    FormFeedback
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { createIncidentType, retriveIncidentTypeList, checkIncidentTypeExists  ,setincidentTypeExist } from '../../../../store/incidentReport/incdreportslice';

const IncidentTypeForm = (props) => {
    const dispatch = useDispatch()
    const incidentReport = useSelector(state => state.incdreportslice)
    const [charCount, setCharCount] = useState(0);

    // const [incidentTypeExist, setincidentTypeExist] = useState(false)
    const incidentTypeExist = incidentReport.incidentTypeExist
    console.log(incidentReport,'incidentReport')

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            incd_type_name: incidentReport.editIncidentTypeInfo.incd_type_name || '',
            incd_type_id: incidentReport.autoMatedIncidentId,
            incd_type_descrption: incidentReport.editIncidentTypeInfo.incd_type_descrption || '',
        },
        validationSchema: Yup.object({
            incd_type_name: Yup.string().required("Please Enter Your Incident Type")
                .test(async function (value) {
                    console.log(incidentReport.editIncidentTypeInfo,'incidentReport.editSeverityTypeInfo')
                    return !(await dispatch(checkIncidentTypeExists(value, incidentReport.editIncidentTypeInfo)));
                })
                .matches(/\S+/, "Incident Type cannot be just spaces").min(2, "Incident name must be at least 2 characters")
                ,
            incd_type_id: Yup.string().required("Please Enter Your Incident Id"),
            incd_type_descrption: Yup.string().required("Description is required")
            .matches(/\S+/, "Description cannot be just spaces").min(10, "Description must be at least 10 characters")
            ,
        }),
        onSubmit: (values) => {
            console.log(values, 'values')
            if (Object.keys(incidentReport.editIncidentTypeInfo).length > 0) {
                values["_id"] = incidentReport.editIncidentTypeInfo._id
            }
            if (!incidentTypeExist) {
                props.onClose()
                dispatch(createIncidentType(values))
                
            }

        }
    });



    const handleChangeIncident=(event)=>{
        console.log(event.target.value)
            if(String(event.target.value.trim()).length >0){
            validation.handleChange(event)
            dispatch(checkIncidentTypeExists(event.target.value, incidentReport.editIncidentTypeInfo))
        }

    }


    const handleInputChange=(e)=>{
        const { value } = e.target;
        if(value.length <=200){
            setCharCount(value.length)
            validation.setFieldValue("incd_type_descrption",value)
        }


    }


    return (
        <React.Fragment>
            <Container fluid>
                <Card >
                    <CardBody className="pt-0">
                        <Row >
                            <Col >
                                <div className="p-2 mb-3">
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row className="my-4">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-primary font-size-18">{Object.keys(incidentReport.editIncidentTypeInfo).length > 0 ? "Edit" : "Create"} Incident Type</div>
                                                <button className="btn btn-outline-dark " onClick={() => {props.onClose();validation.resetForm();dispatch(setincidentTypeExist(false))}}> Close </button>
                                            </div>
                                            {/* <hr className="my-4" /> */}
                                        </Row>
                                        <div className="mb-3">
                                            <Label className="form-label">Incident Type Name :<label className="text-danger"> *</label></Label>
                                            <Input
                                                name="incd_type_name"
                                                className="form-control"
                                                placeholder="Enter Incident Type Name"
                                                type="text"
                                                onChange={validation.handleChange}
                                                // onChange={(e)=>{handleChangeIncident(e)}}

                                                onBlur={validation.handleBlur}
                                                value={validation.values.incd_type_name || ""}
                                                invalid={
                                                    validation.touched.incd_type_name && validation.errors.incd_type_name ? true : false
                                                }
                                            />
                                            {validation.touched.incd_type_name && validation.errors.incd_type_name ? (
                                                <FormFeedback type="invalid">{validation.errors.incd_type_name}</FormFeedback>
                                            ) : null}
                                            {incidentTypeExist &&
                                                <div className='text-danger' style={{ fontSize: 'smaller' }}>
                                                    Incident type already exist
                                                </div>

                                            }
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Description :<label className="text-danger"> *</label></Label>

                                            <Input
                                                name="incd_type_descrption"
                                                className="form-control"
                                                placeholder="Enter Incident Description"
                                                type="textarea"
                                                // onChange={validation.handleChange}
                                                onChange={handleInputChange}

                                                onBlur={validation.handleBlur}
                                                value={validation.values.incd_type_descrption || ""}
                                                invalid={
                                                    validation.touched.incd_type_descrption && validation.errors.incd_type_descrption ? true : false
                                                }
                                            />
                                            {validation.touched.incd_type_descrption && validation.errors.incd_type_descrption ? (
                                                <FormFeedback type="invalid">{validation.errors.incd_type_descrption}</FormFeedback>
                                            ) : null}
                                               <div className="text-end">
                                                    <small>{charCount} / 200 characters</small>
                                                </div>
                                        </div>
                                        <Row>
                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    type="submit"
                                                >
                                                    {Object.keys(incidentReport.editIncidentTypeInfo).length > 0 ? "Update" : "Submit"}
                                                </button>
                                            </div>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </React.Fragment>
    )
}

export default IncidentTypeForm
