

import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    Media,
    Row,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Card, CardBody,
    CardText,
  CardTitle,
} from "reactstrap"

import Dropzone from "react-dropzone"
import MediaPreview from "./media_preview";

const _ = require('lodash')

// var urlSocket = require("../../../helpers/urlSocket")
import urlSocket from '../../../../helpers/urlSocket';


export default class CRUD_Documents extends Component {

    constructor(props){
        super(props)
        this.state={
            attachDocuments:[],
            docfileStatus:"clear",
            doc_warning_enabled:false,
        }
        this.handleDocumentAcceptedFiles = this.handleDocumentAcceptedFiles.bind(this)

    }


    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }
    
      handleDocumentAcceptedFiles = files => {

        this.setState({ docUploading: true, docfileStatus: "clear", doc_warning_enabled: false })
        files.map(file => {
          if (file.size > 5120000) {
            this.setState({
              docfileStatus: "exceed",
            })
          }
          else {
            let preview = URL.createObjectURL(file)
            
            Object.assign(file, {
              preview: preview,
              formattedSize: this.formatBytes(file.size),
              uploading: true,
              filetype: file.type,
              originalName: file.name,
              uploadingStatus: "Uploading"
            })
          }
        }
        )

        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });
    
        if (this.state.docfileStatus !== "exceed" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached.length + files.length <= 10) {


          //this.props.selectedCheckpoint.cp_documents = this.props.selectedCheckpoint.cp_documents.concat(files)
    
          this.setState({
            attachDocuments: this.state.attachDocuments.concat(files)
          },async () => {
            let formData = new FormData();
    
          for (const key of Object.keys(this.state.attachDocuments)) {
            this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached.push(this.state.attachDocuments[key])
            formData.append('imagesArray', this.state.attachDocuments[key])
          }
    
          try {
           await urlSocket.post("storeImage/awswebupload", formData,{
            headers: {
              'Content-Type': 'multipart/form-data', // Set the Content-Type header
            },
           }, {
              onUploadProgress: progressEvent => {
                if (progressEvent.loaded === progressEvent.total) {
                  //this.progress.current++
                }
              }
            }).then(response => {
              console.log(response,'response')
              if (response.data.response_code == 500) {

                this.setState({attachDocuments:[]})

                var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
                  is_selected: true,
                });

                _.each(response.data.data, item => {
                  _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached, child => {
                      console.log(child.name == item.originalname,'child.name == item.originalname')
                    if (child.name == files[0].originalName) {
                      let splitString = item.key.split("/");
                      let getFileName = splitString[splitString.length - 1];
                      child["uploading"] = false
                      child["uploadingStatus"] = "Uploaded"
                    child["preview"] = this.props.imagePreviewUrl + getFileName
                    child["originalname"] = response.data.data[0]["originalname"]
                    child["originalName"] = response.data.data[0]["originalname"]
                    }
                  })
                })
                this.setState({ docUploading: false, refresh: true })
                this.props.selectedCheckpoint.cp_documents = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached
                this.updateCheckpointDocuments(this.props.selectedCheckpoint.cp_documents, this.props.selectedCheckpoint)
              }
              else {
                _.each(this.props.selectedCheckpoint.cp_documents, child => {
                  child["uploading"] = false
                  child["uploadingStatus"] = "Not Uploaded"
                })
                this.setState({ docUploading: false, refresh: true })
              }
    
            })
          } catch (error) {
            console.log("error", error)
          }
          })
    
          
        }
        else {
          if (this.state.docfileStatus === "exceed") {
            this.setState({
              doc_warning_enabled: true,
              doc_warning_message: "One of selected file size is exceed more than 5mb"
            })
          }
          else {
            this.setState({
              doc_warning_enabled: true,
              doc_warning_message: "Maximum Number of files is 5"
            })
          }
        }
      }

      updateCheckpointDocuments(data, item) {
        item["cp_documents"] = data
        this.props.saveCheckpoint(item)
      }
    
      deleteDocuments = item => {

        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });

        var getIndex = _.findIndex(
          this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached,
          { originalName: item.originalName }
        );

        this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached.splice(getIndex, 1);
        this.props.selectedCheckpoint.cp_documents = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached
        this.updateCheckpointDocuments(this.props.selectedCheckpoint.cp_documents, this.props.selectedCheckpoint)
        this.setState({ docfileStatus: "clear", doc_warning_enabled: false })
        this.setState({ refresh: true })
      }



    render() {

        var selected_option = this.props.selected_option

        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });



        return (
            <div >
              {
                this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached.length !==  this.props.selectedCheckpoint.cp_noof_documents?
                <div>
                  <label>Add Documents</label>
            <Row>
              {
                _.filter(this.props.selectedCheckpoint.checkpoint_options, { is_selected: true }).length !== 0 ?
                  _.filter(this.props.selectedCheckpoint.checkpoint_options, { is_selected: true })[0].documents.map((item, idx) => {
                    return (
                      <Col className="col-auto" key={"doc"+String(idx)}>
                        <label className="badge badge-soft-primary font-size-12">{item}</label>
                      </Col>)
                  }) : null
              }
            </Row>
            <Row className="my-3">
              <Col sm={"12"} lg={"12"}>
                <div style={{ zoom: 0.5 }}>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles =>
                        this.handleDocumentAcceptedFiles(acceptedFiles)
                      }
                      accept={['.doc', ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".pdf"]}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop Documents here or click to upload.</h4>

                          </div>
                        </div>
                      )}
                    </Dropzone>

                  </Form>
                </div>
                <div className="mt-2 font-size-11 text-dark text-center">
                  <span >Files accepted - .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span >Maximum individual file size - 5mb</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span >Minimum Number of files - {selected_option.documents.length}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span >Maximum upto 10 files</span>
                </div>
              </Col>
            </Row>
                </div>: this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.cp_noof_documents !== 0 ?
                <div style={{padding:10}}><label style={{fontSize:12, color:"#ff6666"}}>You are reached the Maximum requirment. If you want to upload document, delete one of the document uploaded and upload the document </label></div> : null
              }
            
            {
              this.state.doc_warning_enabled ? <Row>
                <div className="my-2 font-size-12 text-danger">{this.state.doc_warning_message}</div>
              </Row> : null
            }
            <Row>
              <Col sm={"12"} lg={"12"}>
                <Row
                  className="dropzone-previews"
                  id="file-previews"

                >
                  {this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached.length !== 0 &&  this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].documents_attached.map((f, i) => {
                    return (
                      <Col lg={"12"} key={i + "doc-file"}>
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          
                        >
                          <div className="p-2 w-100" key={"media" + i} >
                           
                            <MediaPreview
                              f={f}
                              index={i}
                              deletedocuments={(index) => { this.deleteDocuments(f) }}
                              audit_status={this.props.audit_status}
                              imagePreviewUrl={this.props.imagePreviewUrl}
                            />
                          </div>
                        </Card>
                      </Col>
                    )
                  })}
                </Row>

              </Col>
            </Row>
          </div>
        )

    }

}