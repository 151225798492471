import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
  Collapse,
  UncontrolledAlert,
  Button,
  Alert,
  Label,
  Input,
  DropdownItem,
  Table,
  Modal, ModalHeader, ModalBody,
  Tooltip
} from "reactstrap"

import TreeStructure from "./Component/TreeStructure";

// import './CSS/DragandDropTree.css'

import _ from "lodash";

// var urlSocket = require("../../helpers/urlSocket");


export default class NewHstructure extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          dataloaded: true,
        }
      }
    

  render() {
    if (this.state.dataloaded) {
    return (
        <React.Fragment>

        <div className="d-xl-flex page-content">
          <div className="w-100">
            <div className="d-md-flex">

              <div className="w-100 me-md-2 ">
                <Card>
                  <CardBody className="py-2">

                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                                     </div>
                    <hr className="m-1" />
                    {/* {
                      this.state.treeData.length != 0 ?
                        <div className="mt-4 mb-4" style={{}}>
                          
                          <TreeStructure optionGroup={optionGroup} />
                        </div> :
                        <div className="mt-4"  >
                          <TreeStructure optionGroup={optionGroup} />
                        </div>
                    } */}
                      <TreeStructure />


                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
       
      </React.Fragment>
    )
    }
    else{
        return null
    }
  }
}


















// import React, { useEffect,useState } from 'react'
// import urlSocket from '../../../helpers/urlSocket'

// export default function NewHstructure() {

//     const [authUser,setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
//     const [userNodeInfo,setuserNodeInfo] = useState(JSON.parse(sessionStorage.getItem("userNodeInfo")))
//     const [hInfo,setHinfo] = useState(JSON.parse(sessionStorage.getItem("hInfo")))

//     useEffect(()=>{
//         console.log("new page",authUser,userNodeInfo)
//         getHData()
//     },[])


//     const getHData =(mode)=>{
     
//             try {
//                 urlSocket.post("webhstre/gethstructure",{
//                     info: {
//                         _id: hInfo._id,
//                         company_id: hInfo.company_id,
//                         encrypted_db_url: authUser.db_info.encrypted_db_url
//                       }
//                 }).then((response)=>{
//                     console.log(response,'response')
//                     var FlatData = response.data.data.hstructure
//                     if (mode == undefined) {
//                         this.setState(
//                           {
//                             dataloaded: false,
//                             h_id: response.data.data._id,
//                             company_id: response.data.data.company_id,
//                             company_name: response.data.data.company_name,
//                             totalHLength: response.data.data.lastGeneratedId,
//                             flat_data: FlatData
//                           },
//                           function () {
//                             this.convertFlatDataToTreeData(FlatData)
//                           }
//                         )
//                       }
//                       else {
//                         this.setState(
//                           {
//                             h_id: response.data.data._id,
//                             company_id: response.data.data.company_id,
//                             company_name: response.data.data.company_name,
//                             totalHLength: response.data.data.lastGeneratedId,
//                             flat_data: FlatData
//                           })
//                       }


//                 })
                
//             } catch (error) {
                
//             }


//     }


//   return (
//     <div>
      
//     </div>
//   )
// }
