import React, { Component } from 'react'
import { Row, Col, Button, TabContent, TabPane, NavLink, NavItem, Nav, FormGroup } from "reactstrap";
import classnames from "classnames";
import Guideline from './Guideline'
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { Badge } from 'antd';

import '../manageAudit.css'

export default class InputType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: "1",

        }
    }

    componentDidMount() {

    }
    onChangeTab = (tab) => {
        if (this.state.currentTab !== tab) {
            this.setState({ currentTab: tab })
        }
    }

    onChangeimgReq = (e) => {
        let value = e.target.checked ? "1" : "0"
        this.props.parameterData.img_availability = value
        this.setState({ refresh: true })
    }

    onChangeAplnReq = (e) => {
        let value = e.target.checked ? "1" : "0"
        this.props.parameterData.apln_available = value
        this.setState({ refresh: true })
    }



    ValidateAndSend = () => {
        this.setState({ currentTab: "1" })
        let data = this.props.parameterData
        this.props.onSave(data)
    }

    render() {
        return (
            <div >
                <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: this.state.currentTab === "1" })} onClick={() => { this.onChangeTab("1") }} >
                            <span className="d-none d-sm-block">Parameter</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: this.state.currentTab === "2" })} onClick={() => { this.onChangeTab("2"); }} >
                            <span className="d-none d-sm-block">Guideline</span>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.currentTab}>
                    <TabPane tabId="1" >
                        <Badge.Ribbon text="">
                            <div className="blueDiv">
                                <AvForm onValidSubmit={this.ValidateAndSend}>
                                    <Row>
                                        <Col md={12}>
                                            <AvField
                                                name="Parameter"
                                                label="Enter Audit Parameter"
                                                type="textarea"
                                                errorMessage="Please enter Parameter"
                                                value={this.props.parameterData.parameter}
                                                onChange={(e) => this.props.parameterData.parameter = e.target.value}
                                                validate={{ required: { value: true } }}
                                            />
                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col md={12}>
                                            <div className="flexsb">
                                                <div>
                                                    <AvField
                                                        name="Minimum Score"
                                                        label="Minimum Score "
                                                        type="number"
                                                        errorMessage="Minimum Score"
                                                        value={this.props.parameterData.min_score}
                                                        onChange={(e) => this.props.parameterData.min_score = parseFloat(e.target.value)}
                                                        validate={{ required: { value: true } }}
                                                        style={{ width: 100 }}
                                                    />
                                                </div>
                                                <div>
                                                    <AvField
                                                        name="Maximum Score"
                                                        label="Maximum Score "
                                                        type="number"
                                                        errorMessage="Maximum Score"
                                                        value={this.props.parameterData.max_score}
                                                        onChange={(e) => this.props.parameterData.max_score = parseFloat(e.target.value)}
                                                        validate={{ required: { value: true } }}
                                                        style={{ width: 100 }}
                                                    />
                                                </div>
                                                <div className="flexsb">
                                                    <div>
                                                        <div>
                                                            <label >Photo Required</label>
                                                        </div>
                                                        <div style={{ padding: '8px 0px' }}>
                                                            <div className="square-switch">
                                                                <input type="checkbox" id={this.props.parameterData._id + "photos"} data-switch="info" checked={this.props.parameterData.img_availability == "1" ? true : false} onChange={(e) => { this.onChangeimgReq(e) }} /><label htmlFor={this.props.parameterData._id + "photos"} data-on-label="Yes" data-off-label="No"  ></label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="align-self-end mb-2 ml-2">
                                                        {
                                                            this.props.parameterData.img_availability == "1" &&
                                                            <AvField
                                                                name="No. of Images"
                                                                label='No. of Images'
                                                                type="number"
                                                                errorMessage="Enter No. of Photos"
                                                                placeholder="No. of Photos"
                                                                min={1}
                                                                value={String(this.props.parameterData.no_of_img)}
                                                                onChange={(e) => this.props.parameterData.no_of_img = parseInt(e.target.value)}
                                                                style={{ width: 150 }}
                                                                validate={{
                                                                    required: { value: this.state.img_availability },
                                                                    pattern: { value: "^[0-9]+$" }
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div >
                                                    <div>
                                                        <label >Action Plan Required</label>
                                                    </div>
                                                    <div style={{ padding: '8px 2px' }}>
                                                        <div className="square-switch" style={{ marginTop: 7 }}>
                                                            <input type="checkbox" id={this.props.parameterData._id + "apln"} data-switch="info" checked={this.props.parameterData.apln_available == "1" ? true : false} onChange={(e) => { this.onChangeAplnReq(e) }} /><label htmlFor={this.props.parameterData._id + "apln"} data-on-label="Yes" data-off-label="No"  ></label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {
                                            this.props.parameterData.apln_available == "1" &&
                                            <>
                                                <Col md={3} className="align-center udb" >
                                                    <AvField
                                                        name="Action Plan Eligibility %"
                                                        label="Enter Eligibility %"
                                                        type="number"
                                                        placeholder="Eligibility %"
                                                        errorMessage="Enter Eligibility %"
                                                        value={String(this.props.parameterData.apln_eligibility_per)}
                                                        onChange={(e) => this.props.parameterData.apln_eligibility_per = parseFloat(e.target.value)}
                                                        validate={{
                                                            required: { value: this.state.apln_available },
                                                            pattern: { value: "^[0-9]+$" }
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={9} >
                                                    <AvField
                                                        name="Observation"
                                                        label="Enter Observation"
                                                        type="textarea"
                                                        errorMessage="Observation"
                                                        value={String(this.props.parameterData.apln_observation == null ? '' : this.props.parameterData.apln_observation)}
                                                        onChange={(e) => this.props.parameterData.apln_observation = e.target.value}
                                                        validate={{
                                                            required: { value: this.state.apln_available },
                                                        }}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <label >Periodicity</label>
                                            <div>
                                                <Select
                                                    value={this.props.parameterData.periodicity[0]}
                                                    onChange={(e) => { this.props.parameterData.periodicity = [e]; this.setState({ refresh: true }) }}
                                                    options={this.props.periodicity_info}
                                                    getOptionLabel={(option) => option.periodicity}

                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <label >Mode</label>
                                            <div>
                                                <Select
                                                    value={{ label: this.props.parameterData.entry_mode == null ? 'Select' : this.props.parameterData.entry_mode }}
                                                    onChange={(e) => { this.props.parameterData.entry_mode = e.label; this.setState({ refresh: true }) }}
                                                    options={this.props.entry_mode}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <label >Data Type</label>
                                            <div>
                                                <Select
                                                    value={{ label: this.props.parameterData.data_type == null ? 'Select' : this.props.parameterData.data_type }}
                                                    onChange={(e) => { this.props.parameterData.data_type = e.label; this.setState({ refresh: true }) }}
                                                    options={this.props.data_type_info}
                                                />

                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div style={{ width: 150 }}>
                                                <AvField
                                                    name="No. of Charachters"
                                                    label="No. of Charachters"
                                                    type="number"
                                                    errorMessage="No. of Charachters"
                                                    value={String(this.props.parameterData.no_of_chars)}
                                                    onChange={(e) => this.props.parameterData.no_of_chars = parseInt(e.target.value)}
                                                    validate={{
                                                        required: { value: true },
                                                        pattern: {
                                                            value: "^[0-9]+$",
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <FormGroup>
                                                <Button color="primary" type="submit" >Save</Button>
                                                <Button color="danger" onClick={() => { this.props.deleteParameter(this.props.parameterData) }} style={{ marginLeft: "10px" }} >Delete</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </div>
                        </Badge.Ribbon>

                    </TabPane>
                    <TabPane tabId="2" >
                        <Guideline
                            ValidateAndSend={() => this.ValidateAndSend()}
                            guideline_image={this.props.parameterData.guideline_image}
                            _id={this.props.parameterData._id}
                            parameterData={this.props.parameterData}
                        />

                    </TabPane>
                    <TabPane tabId="3" >

                    </TabPane>
                </TabContent>
            </div>
        )
    }
}
