import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    Media,
    Row,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Card, CardBody,
    CardText,
  CardTitle,
} from "reactstrap"

import Dropzone from "react-dropzone"
import MediaPreview from "./media_preview";

import WebcamCapture from './WebCam_Comp';

const _ = require('lodash')
import urlSocket from '../../../../helpers/urlSocket';
import Swal from 'sweetalert2';

export default class CRUD_videos extends Component {

    constructor(props){
        super(props)
        this.state={
            attachVideos: [],
            fileStatus:"clear",
            warning_enabled:false,
            max_video_length:''
        }
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    }

    componentDidMount(){
      var data = JSON.parse(sessionStorage.getItem("authUser"))
      console.log(data,'data')
      this.setState({
        max_video_length : data.client_info[0].max_video_duration.$numberDecimal,
        max_video_size : data.client_info[0].max_video_size
      })
    }



    handleAcceptedFiles = async files => {
      console.log(files, 'files');
  
      // Update file properties
      const updatedFiles = await Promise.all(files.map(async file => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.src = URL.createObjectURL(file);
  
        // Wrap the onloadedmetadata event in a promise for async/await
        const durationPromise = new Promise(resolve => {
          video.onloadedmetadata = () => {
            resolve(video.duration);
          };
        });
  
        // Get the duration asynchronously
        const duration = await durationPromise;
  
        // Clean up the video element
        URL.revokeObjectURL(video.src);
  
        return {
          ...file,
          preview: URL.createObjectURL(file),
          formattedSize: this.formatBytes(file.size),
          uploading: false,
          filetype: file.type,
          uploadingStatus: 'Not uploaded',
          originalName: file.name,
          startTime: 0, // Default start time
          endTime: duration, // Set the video duration as the end time
        };
      }));
  
      console.log(updatedFiles);
  
      console.log('updatedFiles', this.props.selected_option.default_video_duration * 60, updatedFiles[0].endTime)
  

      var maxSize = this.state.max_video_size * 1024 * 1024
    if (files[0].size > maxSize) {
      console.log("video size is greater")
      Swal.fire({
        icon: 'warning',
        title: `File size cannot be greater than ${this.state.max_video_size} MB`,
        text: `Please upload the video less than ${this.state.max_video_size} MB `,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      }).then((result) => {


      })
    }
    else {

      if (this.props.selected_option.default_video_duration * 60 > updatedFiles[0].endTime) {
        console.log('correct file')

        this.setState({
          attachVideos: this.state.attachVideos.concat(files),
        }, async () => {
          const formData = new FormData();
          for (const key of Object.keys(this.state.attachVideos)) {
            formData.append('imagesArray', this.state.attachVideos[key]);
          }

          try {
            const response = await urlSocket.post("storeImage/awswebupload", formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: progressEvent => {
                if (progressEvent.loaded === progressEvent.total) {
                  // Upload completed
                }
              },
            });

            console.log(response, 'response');

            if (response.data.response_code == 500) {
              this.setState({ attachVideos: [] })

              var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
                is_selected: true,
              });
              if (this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos === undefined) {
                this.props.selectedCheckpoint.checkpoint_options[getOptionIndex]["videos"] = response.data.data

              }
              else {
                this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos.push(response.data.data[0])

              }
              console.log(this.props.selectedCheckpoint.checkpoint_options, 'this.props.selectedCheckpoint.checkpoint_options')
              _.each(response.data.data, item => {
                _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos, async child => {
                  console.log(child, item.originalname, 'child.name == item.originalname')

                  if (child.originalname == item.originalname) {
                    var track_loc = await this.access_location()

                    let splitString = item.key.split("/");
                    let getFileName = splitString[splitString.length - 1];
                    child["uploading"] = false
                    child["uploadingStatus"] = "Uploaded"
                    child["preview"] = getFileName
                    child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
                    child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
                    child["source"] = "camera"
                    await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_videos, this.props.selectedCheckpoint);
                  }
                })
              })
              this.setState({ imageUploading: false, refresh: true })
              this.props.selectedCheckpoint.cp_attach_videos = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos
            }
            // if (response.data.response_code === 500) {
            //   this.setState({ attachVideos: [] });

            //   const getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
            //     is_selected: true,
            //   });

            //   if (!this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos) {
            //     this.props.selectedCheckpoint.checkpoint_options[getOptionIndex]["videos"] = response.data.data;
            //   } else {
            //     this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos.push(response.data.data[0]);
            //   }

            //   console.log(this.props.selectedCheckpoint.checkpoint_options, 'this.props.selectedCheckpoint.checkpoint_options');

            //   await Promise.all(response.data.data.map(async item => {
            //     await Promise.all(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos.map(async child => {
            //       if (child.originalname === item.originalname) {
            //         const track_loc = await this.access_location();
            //         const splitString = item.key.split("/");
            //         const getFileName = splitString[splitString.length - 1];

            //         // Create a video element to get the duration
            //         const video = document.createElement('video');
            //         video.preload = 'metadata';
            //         video.onloadedmetadata = () => {
            //           console.log('Video duration:', video.duration);

            //           // Update start and end times
            //           child.startTime = 0;  // Set your desired start time
            //           child.endTime = video.duration; // Set your desired end time

            //           // Clean up the video element
            //           URL.revokeObjectURL(video.src);
            //         };
            //         video.src = URL.createObjectURL(file);

            //         child["uploading"] = false;
            //         child["uploadingStatus"] = "Uploaded";
            //         child["preview"] = getFileName;
            //         child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null;
            //         child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null;
            //         child["source"] = "camera";

            //         await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_videos, this.props.selectedCheckpoint);
            //       }
            //     }));
            //   }));

            //   this.setState({ imageUploading: false, refresh: true });
            //   this.props.selectedCheckpoint.cp_attach_videos = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos;
            // } 
            else {
              this.props.selectedCheckpoint.cp_attach_videos.forEach(child => {
                child["uploading"] = false;
                child["uploadingStatus"] = "Not Uploaded";
              });

              this.setState({ imageUploading: false, refresh: true });
            }
          } catch (error) {
            console.log("error", error);
          }

        })
      }
      else{
        Swal.fire({
          icon: 'warning',
          title: `File size cannot be greater than ${this.props.selected_option.default_video_duration} Minutes`,
          text: `Please upload the video less than ${this.props.selected_option.default_video_duration} Minutes `,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        }).then((result) => {
  
  
        })
      }
    }
  // });
};




    //working
    // handleAcceptedFiles = files => {
    //   console.log(files,'files')

    //   console.log(files)

    //   var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
    //     is_selected: true,
    //   });
    //   files.map(file => {
    //       Object.assign(file, {
    //           "preview": URL.createObjectURL(file),
    //           "formattedSize": this.formatBytes(file.size),
    //           "uploading": false,
    //           "filetype": file.type,
    //           "uploadingStatus": 'Not uploaded',
    //           "originalName": file.name,
    //       })
    //     }
    //   )
    //     console.log(files)
    //     var maxSize = this.state.max_video_size * 1024 * 1024
    //   if (files[0].size > maxSize) {
    //     console.log("video size is greater")
    //     Swal.fire({
    //       icon: 'warning',
    //       title: `File size cannot be greater than ${this.state.max_video_size} MB`,
    //       text: `Please upload the video less than ${this.state.max_video_size} MB `,
    //       confirmButtonColor: '#3085d6',
    //       confirmButtonText: 'OK',
    //     }).then((result) => {


    //     })
    //   }
    //   else {
    //     this.setState({
    //       attachVideos: this.state.attachVideos.concat(files)
    //     }, () => {

    //       let formData = new FormData();
    //       console.log(this.state.attachVideos, 'this.state.attachVideos')
    //       for (const key of Object.keys(this.state.attachVideos)) {
    //         formData.append('imagesArray', this.state.attachVideos[key])
    //       }

    //       try {
    //         urlSocket.post("storeImage/awswebupload", formData, {
    //           headers: {
    //             'Content-Type': 'multipart/form-data', // Set the Content-Type header
    //           }
    //         },
    //           {
    //             onUploadProgress: progressEvent => {
    //               if (progressEvent.loaded === progressEvent.total) {
    //               }
    //             }
    //           }).then(response => {
    //             console.log(response, 'response')
    //             if (response.data.response_code == 500) {
    //               this.setState({ attachVideos: [] })

    //               var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
    //                 is_selected: true,
    //               });
    //               if (this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos === undefined) {
    //                 this.props.selectedCheckpoint.checkpoint_options[getOptionIndex]["videos"] = response.data.data

    //               }
    //               else {
    //                 this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos.push(response.data.data[0])

    //               }
    //               console.log(this.props.selectedCheckpoint.checkpoint_options, 'this.props.selectedCheckpoint.checkpoint_options')
    //               _.each(response.data.data, item => {
    //                 _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos, async child => {
    //                   console.log(child, item.originalname, 'child.name == item.originalname')

    //                   if (child.originalname == item.originalname) {
    //                     var track_loc = await this.access_location()

    //                     let splitString = item.key.split("/");
    //                     let getFileName = splitString[splitString.length - 1];
    //                     child["uploading"] = false
    //                     child["uploadingStatus"] = "Uploaded"
    //                     child["preview"] = getFileName
    //                     child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
    //                     child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
    //                     child["source"] = "camera"
    //                     await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_videos, this.props.selectedCheckpoint);
    //                   }
    //                 })
    //               })
    //               this.setState({ imageUploading: false, refresh: true })
    //               this.props.selectedCheckpoint.cp_attach_videos = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos
    //             }
    //             else {
    //               _.each(this.props.selectedCheckpoint.cp_attach_videos, child => {
    //                 child["uploading"] = false
    //                 child["uploadingStatus"] = "Not Uploaded"
    //               })
    //               this.setState({ imageUploading: false, refresh: true })
    //             }
    //           })
    //           .catch(error => {
    //             console.log("error", error)
    //           })
    //       }
    //       catch (error) {
    //         console.log("catch error", error)
    //       }
    //     })
    //   }
    // }



    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }
    
     

      access_location = () => {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            position => {
              var location_data = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              };
              resolve(location_data);
            },
            error => {
              console.log(error, 'error');
              reject(null);
            }
          );
        });
      };
      

      uploadWebCamVedio=(file)=>{
        console.log(file,'file')
        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });

        let formData = new FormData();
    
            formData.append('imagesArray', file)
      
            try {
              urlSocket.post("storeImage/awswebupload", formData,{
                headers: {
                  'Content-Type': 'multipart/form-data', // Set the Content-Type header
                }},
                 {
                onUploadProgress: progressEvent => {
                  if (progressEvent.loaded === progressEvent.total) {
                  }
                }
              }).then(response => {
                console.log(response,'response')
                if (response.data.response_code == 500) {
                  this.setState({attachVideos:[]})

                  var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
                    is_selected: true,
                  });
  
            this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos.push(response.data.data[0])
                    console.log(this.props.selectedCheckpoint.checkpoint_options,'this.props.selectedCheckpoint.checkpoint_options')
                  _.each(response.data.data, item => {
                    _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos, async child => {
                      console.log(child,item.originalname,'child.name == item.originalname')

                      if (child.originalname == item.originalname) {
                        var track_loc = await this.access_location()

                        let splitString = item.key.split("/");
                        let getFileName = splitString[splitString.length - 1];
                        child["uploading"] = false
                        child["uploadingStatus"] = "Uploaded"
                        child["preview"] = getFileName
                        child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
                        child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
                        child["source"] = "camera"
                        await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_videos, this.props.selectedCheckpoint);
                      }
                    })
                  })
                  this.setState({ imageUploading: false, refresh: true })
                  this.props.selectedCheckpoint.cp_attach_videos = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos
                }
                else {
                  _.each(this.props.selectedCheckpoint.cp_attach_videos, child => {
                    child["uploading"] = false
                    child["uploadingStatus"] = "Not Uploaded"
                  })
                  this.setState({ imageUploading: false, refresh: true })
                }
              })
              .catch(error => {
                console.log("error", error)
              })
            }
            catch (error) {
              console.log("catch error", error)
            }


      }








    //   uploadWebCamImage = async(file) => {

    //     Object.assign(file, {
    //       "preview": file.name, //URL.createObjectURL(file),
    //       "formattedSize": this.formatBytes(file.size),
    //       "uploading": false,
    //       "filetype": file.type,
    //       "uploadingStatus": 'Not uploaded',
    //       "originalName": file.name,
    //        "captured_on": new Date(),
    //         "path": file.name,
    //       //  "name": image_info.name,
    //       // "type": file.type,
    //         //"uri": URL.createObjectURL(image_info),
    //   })
    //   //window.location = URL.createObjectURL(file);

    //     var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
    //       is_selected: true,
    //     });

    //     let formData = new FormData();
    
    //         formData.append('imagesArray', file)
      
    //         try {
    //           urlSocket.post("storeImage/awswebupload", formData,{
    //             headers: {
    //               'Content-Type': 'multipart/form-data', // Set the Content-Type header
    //             }},
    //              {
    //             onUploadProgress: progressEvent => {
    //               if (progressEvent.loaded === progressEvent.total) {
    //                 //this.progress.current++
    //               }
    //             }
    //           }).then(response => {
    //             console.log(response,'response')
    //             if (response.data.response_code == 500) {
    //               this.setState({attachVideos:[]})

    //               var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
    //                 is_selected: true,
    //               });
    //               this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images.push(response.data.data[0])
  
                  
    //               _.each(response.data.data, item => {
    //                 _.each(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images, async child => {
    //                  console.log(child,item.originalname,'child.name == item.originalname')
    //                   if (child.originalname == item.originalname) {
    //                     var track_loc = await this.access_location()

    //                     let splitString = item.key.split("/");
    //                     let getFileName = splitString[splitString.length - 1];
    //                     child["uploading"] = false
    //                     child["uploadingStatus"] = "Uploaded"
    //                     child["preview"] = getFileName
    //                     child["lat"] = this.props.endpointData.audit_coords_enable ? String(track_loc.latitude) : null
    //                     child["long"] = this.props.endpointData.audit_coords_enable ? String(track_loc.longitude) : null
    //                     child["source"] = "camera"
    //                     await this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint);
    //                   }
    //                 })
    //               })
    //               this.setState({ imageUploading: false, refresh: true })
    //               this.props.selectedCheckpoint.cp_attach_images = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].images
    //               // this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_images, this.props.selectedCheckpoint)
    //             }
    //             else {
    //               _.each(this.props.selectedCheckpoint.cp_attach_images, child => {
    //                 child["uploading"] = false
    //                 child["uploadingStatus"] = "Not Uploaded"
    //               })
    //               this.setState({ imageUploading: false, refresh: true })
    //             }
    //           })
    //           .catch(error => {
    //             console.log("error", error)
    //           })
    //         }
    //         catch (error) {
    //           console.log("catch error", error)
    //         }
    //   }

      updateCheckpointImages(data, item) {
        this.props.saveCheckpoint(item)
      }
    
      deleteImage = item => {

        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });

        var getIndex = _.findIndex(
          this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos,
          { originalName: item.originalName }
        );

        this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos.splice(getIndex, 1);
        this.props.selectedCheckpoint.cp_attach_videos = this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos
        this.updateCheckpointImages(this.props.selectedCheckpoint.cp_attach_videos, this.props.selectedCheckpoint)
        this.setState({ fileStatus: "clear", warning_enabled: false, refresh: true  })

      }

      




    render() {

        var selected_option = this.props.selected_option

        var getOptionIndex = _.findIndex(this.props.selectedCheckpoint.checkpoint_options, {
          is_selected: true,
        });
        if(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos === undefined){
            this.props.selectedCheckpoint.checkpoint_options[getOptionIndex]["videos"]=[]
        }
        console.log(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex],'this.props.selectedCheckpoint')

        return (
            <div >
              {
                this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos.length !==  this.props.selectedCheckpoint.cp_noof_videos?
                <div>
                  <label>Add Videos</label>
                  <Row className="my-3">
                    <WebcamCapture
                    video = {true}
                    //  uploadWebCamImage = {(data)=>{this.uploadWebCamImage(data)}}
                      uploadWebCamVedio = {(data)=>{this.uploadWebCamVedio(data)}} max_video_length={this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].default_video_duration !== undefined && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].default_video_duration !== null ?Number(this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].default_video_duration) : Number(this.state.max_video_length) } />
                  </Row>
                <Row className="my-3">
                    <Col sm={"12"} lg={"12"}>
                        <div style={{ zoom: 0.5 }}>
                            <Form>
                                <Dropzone
                                    onDrop={acceptedFiles =>
                                        this.handleAcceptedFiles(acceptedFiles)
                                    }
                                    accept={[".avi" ,".mov",".mkv",".mp4"]}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Drop Videos here or click to upload.</h4>

                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                            </Form>
                        </div>
                        <div className="mt-2 font-size-11 text-dark text-center">
                            <span >Files accepted - .avi ,.mov,.mkv </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span >Maximum individual file size - 5mb</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span >Minimum Number of files - {selected_option.no_of_video}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span >Maximum upto 10 files</span>
                        </div>
                    </Col>
                </Row>
                </div>: this.props.audit_status !== "3" && this.props.audit_status !== "4" && this.props.selectedCheckpoint.cp_noof_videos !== 0 ?
                <div style={{padding:10}}><label style={{fontSize:12, color:"#ff6666"}}>You are reached the Maximum requirment. If you want to retake or upload image, delete one of the image uploaded and capture/upload the picture </label></div>:null
              }
                
                {
                    this.state.warning_enabled ? <Row>
                        <div className="my-2 font-size-12 text-danger">{this.state.warning_message}</div>
                    </Row> : null
                }
                <Row>
                    <Col sm={"12"} lg={"12"}>
                        <Row
                            className="dropzone-previews"
                            id="file-previews"

                        >
                            {this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos.length !== 0 && this.props.selectedCheckpoint.checkpoint_options[getOptionIndex].videos.map((f, i) => {
                                return (
                                    <Col lg={"8"} key={String(i) + "-file"}>
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'row' }} >
                                                <div className="p-2 col-11" >
                                                    <Row className="align-items-center">
                                                        <Col className="col-12">
                                                            <video
                                                    style={{ width: "500px" }}
                                                   src={this.props.imagePreviewUrl + f.originalname}
                                                    // src={"https://d3pnv0bkd16srd.cloudfront.net/eaudit-file/" + f.originalname}
                                                    controls
                                                  />
                                                        </Col>
                                                    </Row>
                                                    <div className="mt-1">
                                                        <span className={f.uploading ? "font-size-10 text-danger" : "font-size-10 text-success"}>{f.uploadingStatus}</span>
                                                    </div>

                                                </div>
                                                {
                                                  this.props.audit_status !== "3" && this.props.audit_status !== "4"?
                                                  <div className="col-1 text-end me-2" style={{width : "30px"}}>
                                                    <Link to="#" onClick={() => this.deleteImage(f)}><i className="mdi mdi-close-circle-outline font-size-20 text-danger" /></Link>
                                                </div>:null
                                                }
                                                
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>

                    </Col>
                </Row>
            </div>
        )

    }

}