import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Offcanvas,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,

} from "reactstrap";
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash";

import Dropzone, { useDropzone } from "react-dropzone";
// import ImageProcess from "./imageprocess";
import SingleMarkerImage from "./singlemarkerimage";

import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";
import { setMarkerState } from '../../../store/actionPlan/Slice/actionplaninfoslice';


const SingleWebGallery = ({
    uploadGalleryImageAndEmit
}) => {
    const dispatch = useDispatch();
    const [isImageSelected, setIsImageSelected] = useState()
    const [selectedFiles, setselectedFiles] = useState([]);
    const [previewUrl, setpreviewUrl] = useState(null)
    const [captured_url, setCaptureUrl] = useState(null)
    const [currentMessage, setCurrentMessage] = useState("");
    const [markerEnable, setMarkerEnable] = useState(false)
    const [isEdited, setIsEdited] = useState(null)
    const [imageBLOBURL, setImageBLOBURL] = React.useState(null)


    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
        setpreviewUrl(files[0].preview)
        setCaptureUrl(files[0].preview)
        setIsImageSelected(true)
    }

    const reload = () => {
        setselectedFiles([])
        setpreviewUrl(null)
        setIsImageSelected(false)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const countWords = (text) => {
        const words = text.trim().split(/\s+/);
        return words.length;
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage()
        }
    };

    const sendMessage = () => {
        dispatch(setMarkerState(null))
        uploadGalleryImageAndEmit(isEdited ? imageBLOBURL : selectedFiles[0], currentMessage)
    }

    return (
        <div>
            {
                markerEnable ?
               
                    <SingleMarkerImage
                        onCancelModal={() => { setMarkerEnable(false) }}
                        onEditedImage={(data) => {
                            setpreviewUrl(data.previewUrl);
                            setImageBLOBURL(data.fileUrl);
                            setMarkerEnable(false)
                        }}
                        preview_url={isEdited ? captured_url : previewUrl}
                        actionPlan={true}
                        isEdited={(status) => { setIsEdited(status) }}
                    /> 
                    :
                    !isImageSelected ?
                        <div className="d-flex flex-row gap-2 align-items-center justify-content-center">
                            <div className="col-11">
                                <div className="d-flex flex-row gap-2 align-items-center" >
                                    <div className="d-flex flex-column  align-items-center">
                                        <Dropzone
                                            onDrop={acceptedFiles => {
                                                handleAcceptedFiles(acceptedFiles)
                                            }}
                                            maxFiles={1}
                                            accept={[".jpg", ".jpeg", ".png"]}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone">
                                                    <div
                                                        className="dz-message needsclick mt-2"
                                                        {...getRootProps()}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <div className="mb-3">
                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                        </div>
                                                        <h4>Drop files here or click to upload.</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <label className="py-2 text-danger">* Only one file to select. Accepted format .jpeg, .jpg, .png</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1 d-flex flex-column align-items-center justify-content-center gap-2">
                                <div>

                                </div>
                            </div>
                        </div>
                        :
                        <div className="d-flex flex-column gap-2" >
                            <div className="d-flex flex-row gap-2 mb-2 align-items-center justify-content-center" >
                                <div className="col-11 d-flex align-items-center justify-content-center">
                                    <img src={previewUrl} width={"70%"} />
                                </div>
                                <div className="col-1 d-flex flex-column align-items-center justify-content-center gap-2">
                                    <div className="d-flex flex-column align-items-center gap-1">
                                        <Link to="#" className="btn btn-sm btn-outline-danger" onClick={() => {
                                            reload()
                                        }}><i className="mdi mdi-signature-image" style={{ fontSize: 20 }} /></Link>
                                        <label>Cancel</label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center gap-1">
                                        <Link to="#" className="btn btn-sm btn-outline-success" onClick={() => {
                                            setMarkerEnable(true)
                                        }} ><i className="mdi mdi-image-edit" style={{ fontSize: 20 }} /></Link>
                                        <label>Edit</label>
                                    </div>

                                </div>
                            </div>
                            <div className="d-flex flex-row gap-2 py-2">
                                <textarea
                                    type="text"
                                    value={currentMessage}
                                    onKeyUp={handleKeyPress}
                                    onChange={(e) => {
                                        if (countWords(e.target.value) <= 500) {
                                            setCurrentMessage(e.target.value);
                                        } else {
                                            alert("Error: Message should not exceed 500 words.");
                                        }
                                    }}
                                    className="form-control chat-input ps-4"
                                    placeholder="Enter Message..."
                                    style={{ minHeight: 70 }}
                                />
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                        sendMessage()
                                    }}
                                    className="btn btn-primary btn-rounded chat-send w-md "
                                >
                                    <span className="d-none d-sm-inline-block me-2">
                                        Send
                                    </span>{" "}
                                    <i className="mdi mdi-send" />
                                </Button>
                            </div>
                        </div>

            }
        </div>

    )
}

export default SingleWebGallery