import React, { Component } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';

import {
  Container, Row, Col, Button, Alert, Input, Card, CardBody, CardText, Table,
  Modal, ModalHeader, ModalBody, CardTitle,
  Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
} from "reactstrap"


import StackedBarChart from "./Chart/stackedbarchart";
import BarChart from "./Chart/barChart";
import BarChartIL from "./Chart/barChart_IL";
import RadialChart from "./Chart/RadialChart";



const _ = require('lodash')
import urlSocket from "../../../helpers/urlSocket";
// var urlSocket = require("../../helpers/urlSocket")
var moment = require('moment')

export default class AuditAnalyticalReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      position: 'right',
      open: false,
      dataloaded: false,
      filterStatus: "all",
      doc_warning_enabled: false,
      warning_enabled: false,
    }
  }

  async componentDidMount() {
    await this.getSessionData()
    this.getAuditReportData()

  }


  async getSessionData() {

    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var publishedAuditData = JSON.parse(sessionStorage.getItem("publishedAuditData"));


    this.setState({
      configData: data.config_data,
      userData: data.user_data,
      publishedAuditData,
      db_info:db_info,
      imagePreviewUrl: data.config_data.img_url,
      imageToken: data.config_data.img_url
    })

  }

  getAuditReportData() {

    var userInfo = this.state.userData
    
    try {
      urlSocket.post("webpbdadtdta/getauditreport", {
        userInfo: {
          _id: userInfo._id,
          encrypted_db_url:this.state.db_info.encrypted_db_url,
          user_code: userInfo.user_code,
          company_id: userInfo.company_id
        },
        auditInfo: this.state.publishedAuditData
      })
        .then(response => {
          if (response.data.response_code === 500) {
            this.setState({
              auditData: response.data.data,
              dataloaded: true
            })
          }
        })
    } catch (error) {
      console.log("catch error", error)
    }
  }

  

  render() {
    if (this.state.dataloaded) {
      return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>AuditVista | Analytical Report</title>
            </MetaTags>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-1">
              <div style={{fontWeight: 600, width: '80%', padding: '0 20px' }}>
                Analytical Report
                </div>
              <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Link to="/mngpblhtempt"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
              </div>
            </div>
            <Container fluid >
                <Card className="mb-1">
                  <CardBody>
                    <CardTitle className="mb-4 text-primary">
                      {this.state.publishedAuditData.template_name}
                    </CardTitle>
                    <Row className="justify-content-center">
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.total}</h5>
                          <p className="text-dark">Total</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.not_started}</h5>
                          <p className="text-secondary">Not Started</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.in_progress}</h5>
                          <p className="text-warning">In progress</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.completed}</h5>
                          <p className="text-success">Completed</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.submitted}</h5>
                          <p className="text-info">Submitted</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.reviewed}</h5>
                          <p className="text-primary">Reviewed</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>









              {/* <Row className="mx-1">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 text-primary">
                      {this.state.publishedAuditData.template_name}
                    </CardTitle>
                    <Row className="justify-content-center">
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.total}</h5>
                          <p className="text-dark">Total</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.not_started}</h5>
                          <p className="text-secondary">Not Started</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.in_progress}</h5>
                          <p className="text-warning">In progress</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.completed}</h5>
                          <p className="text-success">Completed</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.submitted}</h5>
                          <p className="text-info">Submitted</p>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">{this.state.auditData.reviewed}</h5>
                          <p className="text-primary">Reviewed</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Row> */}
                <Card className="mb-1">
                  <CardBody>
                    <CardTitle className="mb-4"> Audit Status </CardTitle>
                    <BarChart
                      auditData={this.state.auditData}
                      height={"300"}
                    />
                  </CardBody>
                </Card>
              <Row className="equal-height-row">
                <Col lg={6} className="pe-0">
                  <Card className="h-100">
                    <CardBody>
                      <CardTitle className="mb-4">Compliant / Non-compliant</CardTitle>
                      <RadialChart
                        userInfo={this.state.userData}
                        auditInfo={this.state.publishedAuditData}
                        encrypted_db_url={this.state.db_info.encrypted_db_url}
                        total_locations={this.state.auditData.total}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6} className="px-1">
                  <Card className="h-100">
                    <CardBody>
                      <CardTitle className="mb-4">Impact level</CardTitle>
                      <BarChartIL
                        userInfo={this.state.userData}
                        auditInfo={this.state.publishedAuditData}
                        encrypted_db_url={this.state.db_info.encrypted_db_url}
                        total_locations={this.state.auditData.total}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>





              {/* <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Compliance / Non-compliance</CardTitle>
                      <RadialChart 
                      userInfo={this.state.userData}
                      auditInfo={this.state.publishedAuditData}
                      encrypted_db_url = {this.state.db_info.encrypted_db_url}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Impact level</CardTitle>
                      <BarChartIL 
                      userInfo={this.state.userData}
                      auditInfo={this.state.publishedAuditData}
                      encrypted_db_url = {this.state.db_info.encrypted_db_url}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
            </Container>
          </div>
        </React.Fragment>
      )
    }
    else {
      return null
    }
  }
}