import React, { Component } from "react";
import { ReactSketchCanvas } from 'react-sketch-canvas';
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import {
  Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
  Input,
  CardText,
  CardTitle,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal, ModalBody, ModalHeader,
  Spinner
} from "reactstrap";
import Countdown from 'react-countdown';
// import Dropzone from "react-dropzone"
import MediaPreview from "./Components/media_preview";
import PreviewAuditScore from "./Components/preview_audit_score";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"



import moment from 'moment'
import SweetAlert from "react-bootstrap-sweetalert"


import OptionComponent from "./Components/option_component";
import Observation from "./Components/observation";
import CAPA from "./Components/capa";
import classnames from "classnames"
import ValidateCP from "./Functions/validate_cp"

import CRUD_Images from "./Components/CRUD_Images";
import CRUD_Videos from "./Components/CRUD_videos";

import CRUD_Documents from "./Components/CRUD_Documents";

import CRUDSign from "./Functions/add_signature"
import uuid from 'react-uuid'

import ReviewOPType from "./Components/review_optype";
import PreviewImage from "./Components/preview_images";
import PreviewDocuments from "./Components/preview_documents";
import PreviewObservation from "./Components/preview_observation";
import PreviewCAPA from "./Components/preview_CAPA";
import Swal from "sweetalert2";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  toggleExpandedForAll,
  defaultGetNodeKey,
  getTreeFromFlatData,
  getNodeAtPath,
  changeNodeAtPath,
  getFlatDataFromTree,
  walk,
  map, find
} from 'react-sortable-tree/dist/index.cjs.js';

// import "flatpickr/dist/themes/material_blue.css"
// import Flatpickr from "react-flatpickr"

const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from "../../../helpers/urlSocket";

const styles = {
  border: '0.0625rem solid #9c9c9c',
  borderRadius: '0.25rem',
};


const statusText = ["Not started", "In progress", "Completed", "Retake", "Rejected", "Approved"]
const statusColor = ["#555657", "#FDA705", "#31D9AC", "#F76518", "#E22E2E", "#49AF30"]
export default class Checkpoints extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataloaded: false,
      activeTab: "1",
      height: window.innerHeight,
      selectedCheckpoint: null,
      filterStatus: "all",
      showOption: false,
      attachImages: [],
      attachDocuments: [],
      selectedOption: null,
      docfileStatus: "clear",
      fileStatus: "clear",
      signeeDesignation:'',
      doc_warning_enabled: false,
      warning_enabled: false,
      issign_empty: true,
      signee: '',
      sign_valid : true,
      preview_audit_score: false,
      latitude :'',
      longitude :'',
      btn_disabled:false,

      isInputFieldOpen: false,

    }


    this.toggle = this.toggle.bind(this)
    this.canvas = React.createRef();


  }

  async componentDidMount() {
    this.canvas = React.createRef();
    try {
      const {data} = this.props.location.state
      this.setState({
        latitude : data.latitude,
        longitude : data.longitude
      })
    } catch (error) {
      // console.log(error,'error')
    }
 
    // this.access_location()
    console.log(this.state.selectedCheckpoint,'selectedCheckpoint')
    await this.getSessionData()
    await this.getEndpointInfo()
    await this.checkPointProcess()
  }


  modaltoggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }


  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  getCheckpointStatus() {
    console.log("llll")
    var ns = _.filter(this.state.checkpoints, { cp_status: "0" , document_type:"2" }).length
    this.setState({
      ns: _.filter(this.state.checkpoints, { cp_status: "0" , document_type:"2" }).length,
      ip: _.filter(this.state.checkpoints, { cp_status: "1", document_type:"2" }).length,
      cpt: _.filter(this.state.checkpoints, { cp_status: "2", document_type:"2" }).length,
      rtk: _.filter(this.state.checkpoints, { cp_status: "3", document_type:"2" }).length,
      apvd: _.filter(this.state.checkpoints, { cp_status: "5", document_type:"2" }).length,
      rjd: _.filter(this.state.checkpoints, { cp_status: "4", document_type:"2" }).length,
      rwd: _.filter(this.state.checkpoints, item => {
        return item.cp_review_status !== null
      }).length,
      // dataloaded: true
    })

  }

  async getSessionData() {

    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var endpointData = JSON.parse(sessionStorage.getItem("endpointData"));

    this.setState({
      configData: data.config_data,
      userData: data.user_data,
      endpointData,
      db_info:db_info,
      imagePreviewUrl: data.config_data.img_url,
      imageToken: data.config_data.img_url,
      signee: data.user_data.firstname + data.user_data.lastname
    })

  }

  async getEndpointInfo() {
    try {

      urlSocket.post("webpbdadtdta/getendpointInfo", {
        endpointInfo: this.state.endpointData,
        userInfo: {
          _id: this.state.userData._id,
          encrypted_db_url:this.state.db_info.encrypted_db_url,
          company_id: this.state.userData.company_id
        }
      })
        .then(response => {
          this.setState({
            endpointData: response.data.data,
          })
        })

    } catch (error) {
    }
  }

  async checkPointProcess() {
    if (this.state.endpointData.audit_cp_status === "0") {
      await this.createCheckPoints()
    }
    else if (this.state.endpointData.audit_cp_status === "1") {
      await this.loadCheckpoints()
    }
    else
    {
      await this.loadCheckpoints()
    }
  }

  async createCheckPoints() {
    try {

      urlSocket.post("epadtprcs/clonecpfmcpmaster", {
        auditInfo: {
          audit_pbd_id: this.state.endpointData.audit_pbd_id
        },
        userInfo: {
          _id: this.state.userData._id,
          encrypted_db_url : this.state.db_info.encrypted_db_url,
          company_id: this.state.userData.company_id
        },
        endpointInfo: this.state.endpointData
      })
        .then(response => {
          if (response.data.response_code === 500) {
            var endpointData = {...this.state.endpointData}
            endpointData["audit_cp_status"]="1"
            sessionStorage.setItem("endpointData", JSON.stringify(endpointData));
            this.setState({
              checkpoints: response.data.data,
              endpointData
              // _.filter(response.data.data, { document_type: "2" }),
            }, function () {
              this.getCheckpointStatus()
              this.filterCheckpoints(this.state.filterStatus)
            })
          }

        })

    } catch (error) {
      console.log("catch error", error)
    }

  }


  async loadCheckpoints() {
    try {

      urlSocket.post("epadtprcs/getepcheckpoints", {
        auditInfo: {
          audit_pbd_id: this.state.endpointData.audit_pbd_id
        },
        userInfo: {
          _id: this.state.userData._id,
          company_id: this.state.userData.company_id,
          encrypted_db_url:this.state.db_info.encrypted_db_url
        },
        endpointInfo: this.state.endpointData
      })
        .then(async response => {
          if (response.data.response_code === 500) {
            var flatData= await this.convertFlatDataToTreeData(response.data.data)
            this.setState({
              checkpoints:flatData,
              //  _.filter(response.data.data, { document_type: "2" }),

            }, function () {
              this.getCheckpointStatus()
              this.filterCheckpoints(this.state.filterStatus)
            })
          }

        })

    } catch (error) {
      console.log("catch error", error)
    }
  }

  updateObservation(checkpoint) {
  }

  updateCheckpoint(data, item) {
    this.updateAudit(item)
   
  }

  updateAudit = (item) => {
    this.saveCheckpoint(item)
    var endpointData ={...this.state.endpointData}
    if(endpointData.status === "5")
    {
      endpointData.status = "6"
      endpointData.audit_cp_status = "Review In progresss";
      this.setState({endpointData}, () => {
        this.saveCheckpoint(item)
      })
      return;
    }
    else
    if(endpointData.status === "6" && this.state.checkpoints.length === (this.state.cpt + this.state.apvd + this.state.rjd)){
      endpointData.audit_completed_on = new Date();
      endpointData.audit_cp_status = "Completed";
      endpointData.status = "2";
      endpointData.review_status = "0";
      this.setState({endpointData}, () => {
        this.saveCheckpoint(item)
      })
      return
    }

    if (endpointData.status !== "3" ) {
           
      if (this.state.ns !== 0) {
        if (
          endpointData.status === "1" &&
          this.state.ns === this.state.cpt
        ) {
          endpointData.audit_completed_on = new Date();
          endpointData.audit_cp_status = "Completed";
          endpointData.status = "2";
          this.setState({endpointData}, () => {
            this.saveCheckpoint(item)
          })
          return;
        }

        if (
          (endpointData.status === "0" || endpointData.status === "1" || endpointData.status === "2") &&
          (this.state.cpt.value !== this.state.ns)
        ) {
          endpointData.audit_started_on = endpointData.audit_started_on === null ? new Date() : endpointData.audit_started_on;
          endpointData.audit_cp_status = "In progress";
          endpointData.status = "1";
          this.setState({endpointData}, () => {
            this.saveCheckpoint(item)
          })
          return;
        }

      }
    }
  }



//   updateAudit = (item) => {
//     this.saveCheckpoint(item);

//     if (this.state.endpointData.status === "5") {
//         this.setState((prevState) => ({
//             endpointData: {
//                 ...prevState.endpointData,
//                 status: "6",
//                 audit_cp_status: "Review In Progress",
//             },
//         }), () => {
//             this.saveCheckpoint(item);
//         });
//         return;
//     } else if (this.state.endpointData.status === "6" && this.state.checkpoints.length === (this.state.cpt + this.state.apvd + this.state.rjd)) {
//         this.setState((prevState) => ({
//             endpointData: {
//                 ...prevState.endpointData,
//                 audit_completed_on: new Date(),
//                 audit_cp_status: "Completed",
//                 status: "2",
//                 review_status: "0",
//             },
//         }), () => {
//             this.saveCheckpoint(item);
//         });
//         return;
//     }

//     if (this.state.endpointData.status !== "3") {
//         if (this.state.ns !== 0) {
//             if (
//                 (this.state.endpointData.status === "1" || this.state.endpointData.status === "0" || this.state.endpointData.status === "2") &&
//                 (this.state.cpt.value !== this.state.ns)
//             ) {
//                 this.setState((prevState) => ({
//                     endpointData: {
//                         ...prevState.endpointData,
//                         audit_started_on: prevState.endpointData.audit_started_on === null ? new Date() : prevState.endpointData.audit_started_on,
//                         audit_cp_status: "In Progress",
//                         status: "1",
//                     },
//                 }), () => {
//                     this.saveCheckpoint(item);
//                 });
//                 return;
//             }
//         }
//     }
// }


  // addCAPA(checkpoint) {
  //   var cp_apln_length = checkpoint.cp_actionplans.length + 1
  //   //.log("cp_apln_length", cp_apln_length)
  //   checkpoint["cp_actionplans"] = checkpoint["cp_actionplans"].concat([
  //     {
  //       "id": checkpoint._id + String(cp_apln_length),
  //       "observation": "",
  //       "actionplan": "",
  //       "target_date": null,
  //       "to_email": "",
  //       "to_phone": "",
  //       "status": "0",
  //       "isEdit": true,
  //     }
  //   ])

  //   // this.setState({ refresh: true })
  //   this.setState({ isInputFieldOpen: true, refresh: true, isCheckpointSaved: true })
  // }

  addCAPA(checkpoint) {    
    var cp_apln_length = checkpoint.cp_actionplans.length + 1;   
     checkpoint["cp_actionplans"] = checkpoint["cp_actionplans"].concat([      
      {       
         "id": checkpoint._id + String(cp_apln_length),       
         "observation": "",       
          "actionplan": "",        
          "target_date": null,        
          "to_email": "",        
          "to_phone": "",        
          "status": "0",        
          "isEdit": true,      
        }    
      ]);    
      this.setState({ isInputFieldOpen: true, refresh: true, isCheckpointSaved: true });  
    }



  saveCheckpoint(checkpoint) {
    console.log(checkpoint,'checkpoint',this.state.selectedCheckpoint)
    var cpStatus = ValidateCP.checkpointStatus(this.state.selectedCheckpoint)
    var endpointData={...this.state.endpointData}
    if (endpointData.audit_coords_enable === true) {
      endpointData["audit_lat"] = this.state.latitude
      endpointData["audit_long"] = this.state.longitude
    }
    console.log(cpStatus,'cpStatus')
    checkpoint.cp_status = cpStatus
    // checkpoint.cp_actionplans = _.filter(checkpoint.cp_actionplans, { status: "1" })
    //.log("checkpoint ---", checkpoint,cpStatus)
    // checkpoint.cp_status = cpStatus
    // checkpoint.cp_actionplans = _.filter(checkpoint.cp_actionplans, { status: "1" })
    try {

      urlSocket.post("epadtprcs/updatecheckpoints", {
        userInfo: {
          encrypted_db_url:this.state.db_info.encrypted_db_url,
          _id: this.state.userData._id,
          company_id: this.state.userData.company_id
        },
        endpointInfo: endpointData,
        checkpointInfo: checkpoint,
       
        auditInfo: {
          audit_pbd_id: endpointData.audit_pbd_id
        },
      })
        .then(response => {
          console.log(response,'response')
          //.log(response,'response')
          if (response.data.response_code === 500) {
            // //.log("save", response.data)
            var checkpoints =[...this.state.checkpoints]
            var getIndex = _.findIndex(checkpoints, { _id: response.data.data._id })
            if (getIndex !== -1) {
              checkpoints[getIndex] = response.data.data
            }
            this.setState({
              checkpoints: checkpoints,
              endpointInfo: response.data.endpoint,
              endpointData,
              isInputFieldOpen: false,
            }, function () {
              this.getCheckpointStatus()
              //this.filterCheckpoints(this.state.filterStatus)
            })
          }
        })

    } catch (error) {
      console.log(error,'error')
      //.log("catch error", error)
    }

  }


 async filterCheckpoints(filterStatus) {
    if (filterStatus === "all") {
      this.setState({
        filteredData: this.state.checkpoints,

        showOption: false,
        idx: -1,
      dataloaded: true

      })
    }
    else
      if (filterStatus === "rwd") {
        this.setState({
          
           filteredData: _.filter(this.state.checkpoints, item => { return item.cp_review_status !== null }),
          showOption: false,
          selectedCheckpoint: null,
          idx: -1,
      dataloaded: true

        })
      }
      else {
        this.setState({
          // filteredData: _.filter(flatData, { cp_status: filterStatus , document_type :'2' }),

           filteredData: _.filter(this.state.checkpoints, { cp_status: filterStatus , document_type :'2' }),
          showOption: false,
          selectedCheckpoint: null,
          idx: -1,
      dataloaded: true

        })
      }
  }

  loadSelectedCheckpoint(item, i) {
    this.setState({ showOption: false, optionSelected: false });
    setTimeout(() => {
      this.setState({
        selectedCheckpoint: item,
        idx: i,
        optionSelected: _.filter(item.checkpoint_options, { is_selected: true }).length !== 0 ? true : false,
        selected_option: _.filter(item.checkpoint_options, { is_selected: true })[0]
      })
      this.setState({ showOption: true });
    }, 10);
  }


  getactionplanCount() {
    var count = _.filter(this.state.selectedCheckpoint.cp_actionplans, { status: "1" }).length
    return count
  }

  submitCheckpoint() {
    var cpStatus = ValidateCP.checkpointStatus(this.state.selectedCheckpoint)
    ////.log("cpStatus", cpStatus)
    var selectedCheckpoint = {...this.state.selectedCheckpoint}
    selectedCheckpoint.cp_status = cpStatus
    this.saveCheckpoint(selectedCheckpoint)
  }

//   submitCheckpoint() {
//     var cpStatus = ValidateCP.checkpointStatus(this.state.selectedCheckpoint);
//     ////.log("cpStatus", cpStatus);

//     // Create a copy of the selectedCheckpoint and update its cp_status
//     const updatedCheckpoint = { ...this.state.selectedCheckpoint, cp_status: cpStatus };

//     // Call saveCheckpoint with the updatedCheckpoint
//     this.saveCheckpoint(updatedCheckpoint);
// }


  confirmSubmit() {
    var checkpoints = _.filter(this.state.checkpoints,{document_type :"2"})
    if (checkpoints.length !== (this.state.cpt + this.state.apvd + this.state.rjd)) {
      this.setState({
        showWarning: true,
        submitMessage: "Cannot submit till you complete all check points"
      })
    }
    else if (this.state.endpointData.audit_signature.length === 0) {
      this.setState({
        showWarning: true,
        submitMessage: "Auditor signature is required."
      })
    }
    else {
      this.setState({ confirm_both: true })
    }
  }


  // treeDataToFlat = (treeData) => {
  //   // //("this.state.treeData", this.state.treeData)
  //   var getNodeKey = ({ treeIndex }) => treeIndex
  //   var flatData = getFlatDataFromTree(
  //     {
  //       treeData: treeData,
  //       getNodeKey,
  //       ignoreCollapsed: false,
  //     })

  //   var explicitData = _.filter(flatData, item => {
  //     return item
  //   })

  //   return explicitData
  // }


  treeDataToFlat = (treeData) => {
    var getNodeKey = ({ treeIndex }) => treeIndex;
    var flatData = getFlatDataFromTree(
        {
            treeData: treeData,
            getNodeKey,
            ignoreCollapsed: false,
        });

    // Create a new array with the desired data
    var explicitData = flatData.filter((item) => !!item);

    return explicitData;
};



  convertFlatDataToTreeData =async (flatData) => {
    if (flatData !== undefined) {
      var parent_data = flatData.filter(item2 => item2.parent_id === null);
      parent_data.sort(function (a, b) {
        return a.document_id - b.document_id;
      });
  
      const treeData = parent_data.map((parentItem) => {
        parentItem.children = this.getChildren(parentItem.document_id, flatData);
        return parentItem;
      });
  
     var converted_treeDataToFlat =await this.treeDataToFlat(treeData)
    var removed_node= _.map(converted_treeDataToFlat, "node")
     return removed_node
    }
  }
  
  getChildren = (parentId, flatData) => {
    var children = flatData.filter(item => item.parent_id === parentId);
    children.sort(function (a, b) {
      return a.document_id - b.document_id;
    });
  
    return children.map((childItem) => {
      childItem.children = this.getChildren(childItem.document_id, flatData);
      return childItem;
    });
  }



  submitAudit() {
    var checkpoints = _.filter(this.state.checkpoints, { document_type: "2" });
    const sum_of_auditscore = _.sumBy(checkpoints, obj => parseFloat(obj.cp_otpion_score.$numberDecimal));
    const updatedEndpointData = { ...this.state.endpointData, audit_score: sum_of_auditscore };
    // console.log(updatedEndpointData, 'updatedEndpointData', checkpoints)
    const action_plan_checkpoints = _.filter(checkpoints, checkpoint => checkpoint.cp_actionplans.length > 0);
    const allResponsiblePersons = _.flatMap(action_plan_checkpoints, 'cp_actionplans')
      .flatMap(actionPlan => _.get(actionPlan, 'responsible_person', []));

    const uniqueUsers = _.uniqBy(allResponsiblePersons, 'user_id');

    console.log(uniqueUsers)

    updatedEndpointData["audit_ac_plan_resp_persons"] = uniqueUsers
    updatedEndpointData["activity_id"]= JSON.parse(sessionStorage.getItem("auditData")).audit_pbd_id

    // var unique_task_users = []
    // action_plan_checkpoints.map((ele,idx)=>{
    //   // if(ele.)
    //   ele.cp_actionplans.map((action,pos)=>{
    //     if(unique_task_users.length === 0){
    //       unique_task_users.push
    //     }
    //   })
    // })
    console.log(updatedEndpointData,'updatedEndpointData')

    if (checkpoints.length === (this.state.cpt + this.state.apvd + this.state.rjd)) {
        try {
          
          // urlSocket.post("task/task-cln"

            urlSocket.post("epadtprcs/updateendpoint"
            , {
                auditInfo: {
                    audit_pbd_id: updatedEndpointData.audit_pbd_id
                },
                userInfo: {
                    _id: this.state.userData._id,
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    company_id: this.state.userData.company_id,
                    // designation:this.state.signeeDesignation
                },
                endpointInfo: updatedEndpointData
            })
            .then(async response => {
                if (response.data.response_code === 500) {
                    // this.props.history.push("/usrenpdts");
                    if(uniqueUsers.length >0){
                    updatedEndpointData["location_permission_acpln"]= uniqueUsers
                    var createActionPlan = await this.crudActionPlanTask(updatedEndpointData,this.state.userData,this.state.db_info)
                  }
                  else{
                    this.props.history.push("/usrenpdts");
                  }
                  }
            });
        } catch (error) {
            //.log("catch error", error)
        }
    } else {
        this.setState({
            showWarning: true
        });
    }
}


  crudActionPlanTask = (updatedEndpointData, userData, db_info) => {

    // try {
      try {

        urlSocket.post("task/task-cln"
          , {
            auditInfo: {
              audit_pbd_id: updatedEndpointData.audit_pbd_id
            },
            userInfo: {
              _id: userData._id,
              encrypted_db_url: db_info.encrypted_db_url,
              company_id: userData.company_id,
            },
            endpointInfo: updatedEndpointData
          })
          .then(async response => {
            console.log(response,'response')
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Audit Published & Action Plan Task Created Successfully',
                text: 'Audit published successfully',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Go to Locations',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.props.history.push("/usrenpdts");
                }

              })
            }
          });
      } catch (error) {
        //.log("catch error", error)
      }

    // } catch (error) {

    // }

  }



  //-------------- signature ----------------------------

  checkAuditeeSigned = () => {
    var indexOfAuditSigned = _.findIndex(this.state.endpointData.audit_signature, {
      audit_user_id: this.state.userData._id,
    });
    this.setState({isAuditSigned:indexOfAuditSigned === -1 ? false : true },() => {
      this.setState({
        btn_disabled:false,
        signee:this.state.isAuditSigned ? "" :this.state.endpointData.audit_signature.length == 0 ? this.state.userData.firstname : '',
        signeeDesignation:this.state.isAuditSigned ? "":this.state.endpointData.audit_signature.length == 0 ? this.state.signeeDesignation : this.state.userData.designation,
        isDisabled:this.state.isAuditSigned ? false : true,
        custom_div: true,
        modal: true ,
        // issign_empty: true
      })
    })
   
  } 

  // checkAuditeeSigned = () => {
  //   var indexOfAuditSigned = _.findIndex(this.state.endpointData.audit_signature, {
  //     audit_user_id: this.state.userData._id,
  //   });
  //   this.setState({isAuditSigned:indexOfAuditSigned === -1 ? false : true },() => {
  //     this.setState({
  //       signee:this.state.isAuditSigned ? "" :this.state.endpointData.audit_signature.length == 0 ? this.state.userData.firstname : '',
  //       signeeDesignation:this.state.isAuditSigned ? "":this.state.endpointData.audit_signature.length == 0 ? this.state.signeeDesignation : this.state.userData.designation,
  //       isDisabled:this.state.isAuditSigned ? false : true,
  //       custom_div: true,
  //       modal: true ,
  //       // issign_empty: true
  //     })
  //   })
   
  // }


  submit = () => {
    //.log(this.state.issign_empty,'this.state.issign_empty')
    if (this.state.signee === "" || this.state.signee === null || this.state.signeeDesignation === null) {
      this.setState({ sign_valid: true, signboard_message: "Enter Name / Designation" })
    }
    else {
      if (!this.state.issign_empty) {

        this.setState({ modal: false,btn_disabled:true })
        


        this.canvas.current.exportImage("png").then(data => {
          this.setState({ sign_url: data })
          var ImageURL = data
          var block = ImageURL.split(";");
          var contentType = block[0].split(":")[1];// In this case "image/gif"
          var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
          var blob = this.b64toBlob(realData, contentType);

          this.sign_upload(blob)
        })
          .catch((error) => {
            // //.log("--catch error ---", error)
           })

      }
      else {
        this.setState({ sign_valid: true, signboard_message: "No signature in board" })
      }
    }



  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }




  clear = () => {
    //.log('clear')
     this.canvas.current.clearCanvas(); this.setState({ sign_url: null, up_sign_url: null, issign_empty: true, sign_valid: false })
    }
  undo = () => { this.canvas.current.undo() }
  redo = () => { this.canvas.current.redo() }

  deleteSignature(id) {
    var getIndex = _.findIndex(this.state.endpointData.audit_signature, { id: id })
    this.state.endpointData.audit_signature.splice(getIndex, 1)

    urlSocket.post('epadtprcs/updatesignature', {
      auditInfo: {
        audit_pbd_id: this.state.endpointData.audit_pbd_id
      },
      userInfo: {
        encrypted_db_url:this.state.db_info.encrypted_db_url,
        _id: this.state.userData._id,
        company_id: this.state.userData.company_id
      },
      endpointInfo: this.state.endpointData
    })
      .then((response) => {
        if (response.data.response_code === 500) {

          this.setState({
            signee: null,
            issign_empty: true,
            refresh: true,
            signeeDesignation : ""
          })
        }
      })

  }

  sign_upload = async (image_info) => {
    const img = new FormData()
    let u_id = uuid()

    img.append('image', image_info, u_id + 'sign.png')
    //.log(u_id + 'sign.png');

    await urlSocket.post('storeImage/awswebupload', img,{
      headers: {
        'Content-Type': 'multipart/form-data', // Set the Content-Type header
      },
    })
      .then((res) => {
        if (res.status == 200) {
          //.log(res)
          let splitString = res.data.data[0].key.split("/");
          let getFileName = splitString[splitString.length - 1];
          //.log("getFileName", getFileName)
          //this.setState({ up_sign_url: String(u_id) + 'sign.png' })
          // //.log("uploaded _sign", res)
          this.update_sign(u_id, getFileName)
        }
      })
  }

  // update_sign = (u_id, fileName) => {
  //   //.log("this.state.signee", this.state.signee, this.state.signeeDesignation)
  //   var signature = {
  //     "id": String(u_id),
  //     "name": fileName,
  //     "sign_by": this.state.signee,
  //     "designation": this.state.signeeDesignation !== "" ?this.state.signeeDesignation : this.state.userData.designation,
  //     "preview": fileName,
  //     "uploading": false,
  //     "filetype": "image/png",
  //     "uploadingStatus": "Uploaded",
  //     "originalName": fileName,
  //     "captured_on": new Date(),
  //     "path": fileName,
  //     "type": "image/png",
  //     "uri": fileName,
  //     "audit_user_id": !this.state.isAuditSigned ? this.state.userData._id : "",
  //     "review_user_id": "",

  //   }

  //   this.state.endpointData["audit_signature"] = this.state.endpointData["audit_signature"].concat([signature])


  //    //.log("this.state.endpointData", this.state.endpointData)
  //   // //.log("signature", signature)
  //   urlSocket.post('epadtprcs/updatesignature', {
  //     auditInfo: {
  //       audit_pbd_id: this.state.endpointData.audit_pbd_id
  //     },
  //     userInfo: {
  //       _id: this.state.userData._id,
  //       company_id: this.state.userData.company_id,
  //       encrypted_db_url:this.state.db_info.encrypted_db_url,
  //     },
  //     endpointInfo: this.state.endpointData
  //   })
  //     .then((response) => {
  //       //.log(response,'response')
  //       if (response.data.response_code === 500) {

  //         this.setState({
  //           signee: null,
  //           issign_empty: true,
  //           refresh: true
  //         })
  //       }
  //     })
  // }


  update_sign = (u_id, fileName) => {
    //.log("this.state.signee", this.state.signee, this.state.signeeDesignation)
    var signature = {
        "id": String(u_id),
        "name": fileName,
        "sign_by": this.state.signee,
        "designation": this.state.signeeDesignation !== "" ? this.state.signeeDesignation : this.state.userData.designation,
        "preview": fileName,
        "uploading": false,
        "filetype": "image/png",
        "uploadingStatus": "Uploaded",
        "originalName": fileName,
        "captured_on": new Date(),
        "path": fileName,
        "type": "image/png",
        "uri": fileName,
        "audit_user_id": !this.state.isAuditSigned ? this.state.userData._id : "",
        "review_user_id": "",
    };

    // Create a copy of this.state.endpointData and update its audit_signature
    const updatedEndpointData = { ...this.state.endpointData };
    updatedEndpointData["audit_signature"] = updatedEndpointData["audit_signature"].concat([signature]);

    //.log("updatedEndpointData", updatedEndpointData);
    //.log("signature", signature);
    urlSocket.post('epadtprcs/updatesignature', {
        auditInfo: {
            audit_pbd_id: updatedEndpointData.audit_pbd_id
        },
        userInfo: {
            _id: this.state.userData._id,
            company_id: this.state.userData.company_id,
            encrypted_db_url: this.state.db_info.encrypted_db_url,
        },
        endpointInfo: updatedEndpointData
    })
    .then((response) => {
        //.log(response,'response')
        if (response.data.response_code === 500) {
            this.setState({
                signee: null,
                issign_empty: true,
                refresh: true,
                endpointData:updatedEndpointData
            });
        }
    });
}




  del_sign = () => {
    this.props.del_sign(this.props.entity_id, this.props.current_data)
  }

  OpenSign =()=>{
    //.log('vv')
  }

  //-----------------------------------------------------


  render() {
    
    if (this.state.dataloaded) {
      this.canvas = React.createRef();
      var checkpoint = _.filter(this.state.checkpoints,{document_type :"2"})
      return (
        <React.Fragment>
          <div className="page-content" >
            <MetaTags>
              <title>Auditvista | Check points</title>
            </MetaTags>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-1">
              <div style={{ width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
                Check points
              </div>
              <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Link to="/usrenpdts"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
              </div>
            </div>
            <Container fluid>
              <Card className="mb-1" body>
               
               
                <Row className="my-3" style={{ paddingTop: 10 }}>
                  <Col sm='4'>
                    <CardTitle className="h3 mt-0">
                      {this.state.endpointData.audit_pbd_name}
                    </CardTitle>
                    <CardText>
                      {this.state.endpointData.name}{" / "}{this.state.endpointData.code}
                    </CardText>
                  </Col>
                  <Col sm='8'>

                    <div className="button-items mb-1">
                      <div className="btn-group " role="group" aria-label="Basic radio toggle button group">
                        <Button className="btn-sm" color="dark" outline onClick={() => { this.setState({ filterStatus: "all" }); this.filterCheckpoints("all") }}>
                          Total check points{" - "}{checkpoint.length}
                        </Button>
                        <Button  className="btn-sm" color="secondary" outline onClick={() => { this.setState({ filterStatus: "0" }); this.filterCheckpoints("0") }}>
                          Not started{" - "}{this.state.ns}
                        </Button>
                        <Button  className="btn-sm" color="warning" outline onClick={() => { this.setState({ filterStatus: "1" }); this.filterCheckpoints("1") }}>
                          In progress{" - "}{this.state.ip}
                        </Button>
                        <Button className="btn-sm" color="success" outline onClick={() => { this.setState({ filterStatus: "2" }); this.filterCheckpoints("2") }}>
                          Completed{" - "}{this.state.cpt}
                        </Button>
                        <Button  className="btn-sm" color="primary" outline onClick={() => { this.setState({ filterStatus: "rwd" }); this.filterCheckpoints("rwd") }}>
                          Reviewed{" - "}{this.state.rwd}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
              <div className="d-xl-flex">
                <div className="w-100">
                  <div className="d-md-flex">

                    {/* 2nd left bar  */}
                    <Card className="w-50 me-md-1  pb-3 ">
                      <CardBody style={{ maxHeight: this.state.height - 250 }} className="overflow-auto">
                        {
                          this.state.filteredData.map((item, i) => {
                            var getColor = item.cp_review_status !== null ? "#556ee6" : item.cp_status == '0' ? '#74788d' :
                              item.cp_status == '1' ? '#f1b44c' :
                                item.cp_status == '2' ? '#34c38f' : '#50a5f1'
                            return (
                              <div className="card shadow-none mb-1" key={"chkpt" + String(i)}>
                                <div style={{
                                  borderLeft:item.document_type =="2" ? item.cp_review_status !== null ? "5px solid #556ee6" : item.cp_status === "0" ? "5px solid #74788d" : item.cp_status === "1" ? "5px solid #f1b44c" : item.cp_status === "2" ? "5px solid #34c38f" : "5px solid #50a5f1" : "",
                                  borderRight: this.state.idx == i ? "1px solid #556ee6" : "1px solid #74788d2e",
                                  borderTop: this.state.idx == i ? "1px solid #556ee6" : "1px solid #74788d2e",
                                  borderBottom: this.state.idx == i ? "1px solid #556ee6" : "1px solid #74788d2e",
                                }}>
                                  {
                                    item.document_type == "2" ?
                                  <Link to="#" className="text-body" onClick={() => { this.loadSelectedCheckpoint(item, i) }}>
                                    <div className="p-2">
                                      <div className="d-flex">
                                        <div className="overflow-hidden me-auto">
                                          <h5 className="font-size-13  mb-1">
                                            <span className=''>{item.checkpoint}</span>
                                          </h5>
                                     
                                          {
                                            this.state.endpointData.status === "2" || this.state.endpointData.status === "5" || this.state.endpointData.status === "6" || this.state.endpointData.status === "7" || this.state.endpointData.status === "1" || this.state.endpointData.status == "0" ?
                                             
                                            <span
                                                className="badge badge-soft font-size-10"
                                                style={{ backgroundColor: statusColor[Number(item.cp_status)] }}
                                              >
                                                {statusText[Number(item.cp_status)]}</span> : null
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                  :
                                  <div style={{backgroundColor :item.parent_id ==null? '#525252' :"#808080"}} className="p-2">
                                  <div className="d-flex">
                                    <div className="overflow-hidden me-auto">
                                      <h5 className="font-size-13  mb-1">
                                        <span style={{color:'#fffffff5'}}>{item.checkpoint}</span>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                  }

                                </div>
                              </div>
                            )
                          })
                        }
                      </CardBody>
                    </Card>
                    <Card className="w-100 pb-3 me-md-1">
                     
                      <CardBody style={{ maxHeight: this.state.height - 250 }} className="overflow-auto">
                        {
                          
                          this.state.showOption && this.state.selectedCheckpoint.cp_status !== "4" && this.state.selectedCheckpoint.cp_status !== "5" ?
                            <div className='d-flex flex-column bg-white' >

                              <div className="my-1">
                                <span
                                  className={this.state.selectedCheckpoint.cp_review_status !== null ? "badge badge-soft-primary me-2" : this.state.selectedCheckpoint.cp_status === "0" ? "badge badge-soft-secondary me-2" : this.state.selectedCheckpoint.cp_status === "1" ? "badge badge-soft-warning me-2" : this.state.selectedCheckpoint.cp_status === "2" ? "badge badge-soft-success me-2" : "badge badge-soft-info me-2"}
                                >{this.state.selectedCheckpoint.cp_review_status !== null ? "Reviewed" : this.state.selectedCheckpoint.cp_status === "0" ? "Not started" : this.state.selectedCheckpoint.cp_status === "1" ? "In progress" : this.state.selectedCheckpoint.cp_status === "2" ? "Completed" : ""}</span>
                                {
                                  this.state.endpointData.status === "4" ?
                                    <span
                                      className={this.state.selectedCheckpoint.cp_review_status === "0" ? "badge badge-soft-danger" : "badge badge-soft-success"}
                                    >
                                      {this.state.selectedCheckpoint.cp_review_status === "0" ? "Rejected" : "Accepted"}</span> : null
                                }
                              </div>

                              <CardTitle className="h4">{this.state.selectedCheckpoint.checkpoint}</CardTitle>


                              <div className='my-2'>
                                {
                                  <OptionComponent
                                    options={this.state.selectedCheckpoint.checkpoint_options}
                                    index={this.state.idx}
                                    checkpoint={this.state.selectedCheckpoint}
                                    imagePreviewUrl={this.state.imagePreviewUrl}
                                    imageToken={this.state.imageToken}
                                    selectionoption={(data, options) => { this.setState({ selected_option: data, optionSelected: true }, function () { this.updateCheckpoint(options, this.state.selectedCheckpoint) }) }}
                                    //update={(data) => { this.updateCheckpoint(data, this.state.selectedCheckpoint) }}
                                    updateimages={(data) => { this.updateCheckpointImages(data, this.state.selectedCheckpoint) }}
                                    updatedocuments={(data) => { this.updateCheckpointDocuments(data, this.state.selectedCheckpoint) }}
                                    audit_status={this.state.endpointData.status}

                                  />
                                }
                              </div>
                              {
                                this.state.optionSelected 
                                //  this.state.selectedCheckpoint.cp_compliance.id !=="4" 
                                 ?
                                  <>
                                    <Nav tabs>
                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: this.state.activeTab === "1",
                                          })}
                                          onClick={() => {
                                            this.toggle("1")
                                          }}
                                        >
                                          <div>
                                            Images
                                          </div>
                                          {
                                            this.state.selectedCheckpoint.cp_noof_images !== 0 ?
                                              <span className={this.state.selected_option.images === undefined
                                                ? "badge badge-soft-danger"
                                                : this.state.selected_option.images.length >=
                                                  this.state.selectedCheckpoint.cp_noof_images
                                                  ? "badge badge-soft-success"
                                                  : "badge badge-soft-danger"}
                                              >{this.state.selected_option.images === undefined
                                                ? '0 / ' + this.state.selectedCheckpoint.cp_noof_images
                                                : this.state.selected_option.images.length +
                                                ' / ' +
                                                this.state.selectedCheckpoint.cp_noof_images} required </span> : <span>&nbsp;</span>
                                          }

                                        </NavLink>
                                      </NavItem>

                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: this.state.activeTab === "6",
                                          })}
                                          onClick={() => {
                                            this.toggle("6")
                                          }}
                                        >
                                          <div>
                                            Videos
                                          </div>
                                          {
                                            console.log(this.state.selectedCheckpoint,this.state.selected_option,'this.state.selectedCheckpoint')
                                          }
                                          {
                                            this.state.selectedCheckpoint.cp_noof_videos !== 0 ?
                                              <span className={this.state.selected_option.videos === undefined
                                                ? "badge badge-soft-danger"
                                                : this.state.selected_option.videos.length >=
                                                  this.state.selectedCheckpoint.cp_noof_videos
                                                  ? "badge badge-soft-success"
                                                  : "badge badge-soft-danger"}
                                              >{this.state.selected_option.videos === undefined
                                                ? '0 / ' + this.state.selectedCheckpoint.cp_noof_videos
                                                : this.state.selected_option.videos.length +
                                                ' / ' +
                                                this.state.selectedCheckpoint.cp_noof_videos} required </span> : <span>&nbsp;</span>
                                          }

                                        </NavLink>
                                      </NavItem>

                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: this.state.activeTab === "2",
                                          })}
                                          onClick={() => {
                                            this.toggle("2")
                                          }}
                                        >
                                          <div>
                                            Documents
                                          </div>
                                          {
                                            this.state.selectedCheckpoint.cp_noof_documents !== 0 ?
                                              <span className={
                                                this.state.selected_option.documents_attached.length >=
                                                  this.state.selectedCheckpoint.cp_noof_documents
                                                  ? "badge badge-soft-success"
                                                  : "badge badge-soft-danger"}>
                                                {this.state.selected_option.documents_attached === undefined
                                                  ? '0 / ' + this.state.selectedCheckpoint.cp_noof_documents
                                                  : this.state.selected_option.documents_attached.length +
                                                  ' / ' +
                                                  this.state.selectedCheckpoint.cp_noof_documents} required </span>
                                              : <span>&nbsp;</span>
                                          }
                                        </NavLink>
                                      </NavItem>
                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: this.state.activeTab === "4",
                                          })}
                                          onClick={() => {
                                            this.toggle("4")
                                          }}
                                        >
                                          <div>
                                            Comments
                                          </div>
                                          <span>&nbsp;</span>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: this.state.activeTab === "3",
                                          })}
                                          onClick={() => {
                                            this.toggle("3")
                                          }}
                                        >
                                          <div>
                                            CAPA
                                          </div>
                                          {
                                            this.state.selected_option.nc_mandatory ? <span
                                              className={this.getactionplanCount() > 0 ? "badge badge-soft-success" : "badge badge-soft-danger"}
                                            >Required</span> : <span>&nbsp;</span>
                                          }
                                          <span>&nbsp;</span>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: this.state.activeTab === "5",
                                          })}
                                          onClick={() => {
                                            this.toggle("5")
                                          }}
                                        >
                                          <div>
                                            Guideline
                                          </div>
                                          <span>&nbsp;</span>
                                        </NavLink>
                                      </NavItem>
                                    </Nav>

                                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                      <TabPane tabId="1">
                                        <Row>
                                          <Col sm="12">
                                            <CRUD_Images
                                              selected_option={this.state.selected_option}
                                              selectedCheckpoint={this.state.selectedCheckpoint}
                                              saveCheckpoint={(data) => this.saveCheckpoint(data)}
                                              imagePreviewUrl={this.state.imagePreviewUrl}
                                              audit_status={this.state.endpointData.status}
                                              latitude = {this.state.latitude}
                                              longitude = {this.state.longitude}
                                              endpointData ={this.state.endpointData}
                                            />
                                          </Col>
                                        </Row>
                                      </TabPane>
                                      <TabPane tabId="6">
                                        <Row>
                                          <Col sm="12">
                                            <CRUD_Videos
                                              selected_option={this.state.selected_option}
                                              selectedCheckpoint={this.state.selectedCheckpoint}
                                              saveCheckpoint={(data) => this.saveCheckpoint(data)}
                                              imagePreviewUrl={this.state.imagePreviewUrl}
                                              audit_status={this.state.endpointData.status}
                                              latitude = {this.state.latitude}
                                              longitude = {this.state.longitude}
                                              endpointData ={this.state.endpointData}
                                            />
                                          </Col>
                                        </Row>
                                      </TabPane>


                                      <TabPane tabId="2">
                                        
                                        <Row>
                                          <Col sm="12">
                                            <CRUD_Documents
                                              selected_option={this.state.selected_option}
                                              selectedCheckpoint={this.state.selectedCheckpoint}
                                              saveCheckpoint={(data) => this.saveCheckpoint(data)}
                                              imagePreviewUrl={this.state.imagePreviewUrl}
                                              audit_status={this.state.endpointData.status}
                                            />
                                          </Col>
                                        </Row>
                                      </TabPane>
                                      <TabPane tabId="3">
                                        <Row>
                                          <Col sm="12">
                                          {
                                            console.log(this.state.isInputFieldOpen,'isInputFieldOpen',this.state.selectedCheckpoint)
                                          }
                                            {(
                                            (this.state.selectedCheckpoint.cp_actionplans.every(item => item.actionplan !== "" )  &&!this.state.isInputFieldOpen) || this.state.endpointData.status === "3" || this.state.endpointData.status === "4") && (
                                                <div className="mb-2">
                                                  <Button className="btn-sm" color="primary" onClick={() => { this.addCAPA(this.state.selectedCheckpoint) }} >
                                                    Add Action</Button>
                                                </div>
                                              )}

                                            {
                                              this.state.selectedCheckpoint.cp_actionplans.map((apln, idx) => {
                                                return (
                                                  <CAPA
                                                    key={"capa" + String(idx)}
                                                    index={idx}
                                                    data={apln}
                                                    onsave={(data) => { this.addapln(this.state.selectedCheckpoint, data, idx) }}
                                                    onadd={() => { this.addCAPA(this.state.selectedCheckpoint) }}
                                                    saveCheckpoint={(data) => this.saveCheckpoint(data)}
                                                    getDeletediles={(data) => this.getDeletediles(data)}
                                                    selectedCheckpoint={this.state.selectedCheckpoint}
                                                    audit_status={this.state.endpointData.status}
                                                    location_info={this.state.endpointData}
                                                  />
                                                )
                                              })
                                            }
                                          </Col>

                                        </Row>
                                      </TabPane>
                                      <TabPane tabId="4">
                                        <Row>
                                          <Col sm="12">
                                            <Observation
                                              selectedCheckpoint={this.state.selectedCheckpoint}
                                              saveCheckpoint={(data) => this.saveCheckpoint(data)}
                                              audit_status={this.state.endpointData.status}
                                            />
                                          </Col>
                                        </Row>
                                      </TabPane>
                                      <TabPane tabId="5">
                                        <Row>
                                          <Col sm="12">
                                            <label>Guideline</label>
                                            <div>
                                              {
                                                this.state.selectedCheckpoint.guideline
                                              }
                                              <Row>
                                                {
                                                  this.state.selectedCheckpoint.guideline_image.map(item => {
                                                    return (<div className="m-2" key={""}>
                                                      <img src={item.preview} className="img-fluid" />
                                                    </div>)
                                                  })
                                                }
                                              </Row>
                                            </div>
                                          </Col>
                                        </Row>
                                      </TabPane>
                                    </TabContent>
                                  </> : null
                              }
                            </div> :
                            this.state.showOption ?
                              <div className='d-flex flex-column bg-white' >
                                <div className="mb-2">
                                  <label className={this.state.selectedCheckpoint.cp_review_status !== null ? "badge badge-soft-primary font-size-10 me-2" : this.state.selectedCheckpoint.cp_status === "0" ? "badge badge-soft-secondary font-size-10 me-2" : this.state.selectedCheckpoint.cp_status === "1" ? "badge badge-soft-warning font-size-10 me-2" : "badge badge-soft-success font-size-10 me-2"}>{this.state.selectedCheckpoint.cp_review_status !== null ? "Reviewed" : this.state.selectedCheckpoint.cp_status === "0" ? "Not started" : this.state.selectedCheckpoint.cp_status === "1" ? "In progress" : "Completed"}</label>
                                  <label className={this.state.selectedCheckpoint.cp_is_compliance ? "badge badge-soft-danger font-size-10" : "badge badge-soft-success font-size-10"}>{this.state.selectedCheckpoint.cp_is_compliance ? "Compliance" : "Non-Compliance"}</label>
                                </div>
                                <div className="mb-1">
                                  <span className="font-size-13">{this.state.selectedCheckpoint.checkpoint}</span>
                                </div>

                                {
                                  this.state.selectedCheckpoint.checkpoint_type_id === "1" || this.state.selectedCheckpoint.checkpoint_type_id === "2" ?
                                    <ReviewOPType
                                      options={this.state.selectedCheckpoint.checkpoint_options}

                                    />
                                    : null
                                }

                                {
                                  this.state.selectedCheckpoint.cp_attach_images.length !== 0 ?
                                    <div className="mt-3 mb-4">
                                      <label>Images Attached</label>
                                      <PreviewImage
                                        imagePreviewUrl={this.state.imagePreviewUrl}
                                        images={this.state.selectedCheckpoint.cp_attach_images}
                                      />
                                    </div> : null
                                }
                              

                                {
                                  this.state.selectedCheckpoint.cp_documents.length !== 0 ?
                                    <div className="my-4">
                                      <label>Documents Attached</label>
                                      <PreviewDocuments
                                        imagePreviewUrl={this.state.imagePreviewUrl}
                                        images={this.state.selectedCheckpoint.cp_documents}
                                      />
                                    </div> : null

                                }

                                {
                                  this.state.selectedCheckpoint.cp_observation !== null ?
                                    <div className="my-4">
                                      <label>Observation</label>
                                      <PreviewObservation
                                        observation={this.state.selectedCheckpoint.cp_observation}
                                      />
                                    </div> : null
                                }

                                {
                                  this.state.selectedCheckpoint.cp_actionplans.length !== 0 ?
                                    <div className="my-4">
                                      <label>CAPA</label>
                                      <PreviewCAPA
                                        actionplans={this.state.selectedCheckpoint.cp_actionplans}
                                      />
                                    </div> : null
                                }
                              </div> :
                              <div className='d-flex p-3 bg-white justify-content-center align-item-center' >
                                <p>Select check point to audit</p>
                              </div>
                        }
                      </CardBody>
                    </Card>
                    {/* 1st left bar  */}
                    <Card className="filemanager-sidebar ">
                      <CardBody style={{ height: this.state.height - 250 }} className="overflow-auto">
                       

                        {
                          this.state.endpointData.audit_signature.length !== 0 ?
                            <div>
                              {
                                this.state.endpointData.audit_signature.map((item, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-signfile"}
                                    >
                                      <div style={{ display: 'flex', flexDirection: 'row' }} >
                                        <div className="p-2 col-10" >
                                          <label className="mb-1 font-size-11">{item.sign_by}<br />{item.designation}</label>
                                          <img height="80" src={this.state.imagePreviewUrl + item.name} />
                                        </div>
                                        {
                                          this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
                                            <div className="col-2 text-end me-2" style={{ lineHeight: 1.3 }}>
                                              <Link to="#" onClick={() => this.deleteSignature(item.id)}><i className="mdi mdi-close-circle-outline font-size-20 text-danger" /></Link>
                                            </div> : null
                                        }

                                      </div>
                                    </Card>

                                  )
                                })
                              }
                            </div> : null
                        }
                        {
                          this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
                            <div className="my-2">
                              <Col className="mb-2">
                                <div className="p-3">
                                  <p>Signature</p>
                                  <Button
                                    color="primary"
                                    onClick={() => { this.checkAuditeeSigned() }}
                                    id="custom-padding-width-alert"
                                  >
                                    Add signature
                                  </Button>
                                </div>


                              </Col>
                            </div> : null
                        }


                        {
                          this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
                            <div className="my-2">
                              <Col className="mb-2">
                                <div className="p-3">
                                  <p>Submit Audit</p>
                                  <Button
                                    color="success"
                                    onClick={() => this.confirmSubmit()}
                                    id="custom-padding-width-alert"
                                  >
                                    Submit and close audit
                                  </Button>
                                </div>
                              </Col>
                            </div> :
                            <div>
                              <label>Audit Started on</label>
                              <p>{moment(this.state.endpointData.audit_started_on).format("DD/MM/YYYY")}</p>
                              <label>Audit Completed on</label>
                              <p>{moment(this.state.endpointData.audit_completed_on).format("DD/MM/YYYY")}</p>
                              <label>Audit Submitted on</label>
                              <p>{moment(this.state.endpointData.audit_submitted_on).format("DD/MM/YYYY")}</p>
                            </div>
                        }


                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>

              <Modal
                isOpen={this.state.modal}
                className={this.props.className}
              >
                <ModalHeader toggle={this.modaltoggle} tag="h4">
                  Signature
                </ModalHeader>
                <ModalBody>
                  <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                    <div className="mb-3" >
                      <AvField
                        name="signee"
                        value={this.state.endpointData.audit_signature.length ==0 ? this.state.userData.firstname :''}
                        onChange={(e) => { this.setState({ signee: e.target.value }) }}
                        className="form-control"
                        placeholder="Enter signee name"
                        type="input"
                        disabled={this.state.isDisabled}
                        required
                      />
                    </div>
                    <div className="mb-3" >
                      <AvField
                        name="designation"
                        value={this.state.endpointData.audit_signature.length ==0  ?this.state.userData.designation :""}
                        onChange={(e) => { this.setState({ signeeDesignation: e.target.value }) }}
                        className="form-control"
                        placeholder="Enter designation"
                        type="input"
                        required
                      />
                    </div>


                    <ReactSketchCanvas
                      ref={this.canvas}
                      style={styles}
                      width="600"
                      height="400"
                      strokeWidth={4}
                      strokeColor="red"
                      onStroke= {() => this.setState({ issign_empty: false, sign_valid: false })}
                    />
                    
                    {
                      this.state.sign_valid ? <div className="m-2"><label className="text-danger">{this.state.signboard_message}</label></div> : null
                    }

                    <Button className="my-2 me-2" color="primary"  disabled={this.state.btn_disabled} onClick={()=>{this.submit()}}>Submit</Button>
                    <Button className="my-2 me-2" color="warning" onClick={()=>{this.clear()}}>Clear</Button>
                    <Button className="my-2" color="danger" onClick={() => this.setState({ modal: false })}>Cancel</Button>
                  </AvForm>
                </ModalBody>
              </Modal>

              {this.state.showWarning ? (
                <SweetAlert
                  title="Message"
                  warning
                  onConfirm={() => this.setState({ showWarning: false })}
                >
                  {this.state.submitMessage}
                </SweetAlert>
              ) : null}

              {this.state.confirm_both ? (
                <SweetAlert
                  title="Are you sure?"
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() =>
                    this.setState({
                      confirm_both: false,
                      success_dlg: true,
                      dynamic_title: "Deleted",
                      dynamic_description: "Your file has been deleted.",
                    },
                      function () {
                        this.submitAudit()
                      }
                    )
                  }
                  onCancel={() =>
                    this.setState({
                      confirm_both: false,
                      error_dlg: true,
                      dynamic_title: "Cancelled",
                      dynamic_description:
                        "Your imaginary file is safe :)",
                    })
                  }
                >
                  You won't be able to revert this!
                </SweetAlert>
              ) : null}



            </Container>
          </div>
        </React.Fragment>
      )
    }
    else if(!this.state.dataloaded) {
      // return null
      return (
        <React.Fragment>
            <div className="page-content" style={{ marginTop: 0 }}  >
                <Container fluid >
                    <Row style={{ minHeight: "calc(100vh - 450px)" }}>
                        <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                            <Spinner className="mb-2" color="primary" />
                            <div>Loading ...</div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
  }
  else{
    return null
  }
  }

}









































// import React, { Component } from "react";
// import { ReactSketchCanvas } from 'react-sketch-canvas';
// import ReactDOM from 'react-dom';
// import MetaTags from 'react-meta-tags';
// import {
//   Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
//   Input,
//   CardText,
//   CardTitle,
//   Collapse,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   TabPane,
//   Modal, ModalBody, ModalHeader
// } from "reactstrap";
// import Countdown from 'react-countdown';
// import Dropzone from "react-dropzone"
// import MediaPreview from "./Components/media_preview";
// import PreviewAuditScore from "./Components/preview_audit_score";

// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// import { Link, withRouter } from "react-router-dom"



// import moment from 'moment'
// import SweetAlert from "react-bootstrap-sweetalert"


// import OptionComponent from "./Components/option_component";
// import Observation from "./Components/observation";
// import CAPA from "./Components/capa";
// import classnames from "classnames"
// import ValidateCP from "./Functions/validate_cp"

// import CRUD_Images from "./Components/CRUD_Images";
// import CRUD_Documents from "./Components/CRUD_Documents";

// import CRUDSign from "./Functions/add_signature"
// import uuid from 'react-uuid'

// import ReviewOPType from "./Components/review_optype";
// import PreviewImage from "./Components/preview_images";
// import PreviewDocuments from "./Components/preview_documents";
// import PreviewObservation from "./Components/preview_observation";
// import PreviewCAPA from "./Components/preview_CAPA";
// import Swal from "sweetalert2";

// // import "flatpickr/dist/themes/material_blue.css"
// // import Flatpickr from "react-flatpickr"

// const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")

// const styles = {
//   border: '0.0625rem solid #9c9c9c',
//   borderRadius: '0.25rem',
// };


// const statusText = ["Not started", "In progress", "Completed", "Retake", "Rejected", "Approved"]
// const statusColor = ["#555657", "#FDA705", "#31D9AC", "#F76518", "#E22E2E", "#49AF30"]
// export default class Checkpoints extends React.Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       dataloaded: false,
//       activeTab: "1",
//       height: window.innerHeight,
//       selectedCheckpoint: null,
//       filterStatus: "all",
//       showOption: false,
//       attachImages: [],
//       attachDocuments: [],
//       selectedOption: null,
//       docfileStatus: "clear",
//       fileStatus: "clear",
//       signeeDesignation:'',
//       doc_warning_enabled: false,
//       warning_enabled: false,
//       issign_empty: true,
//       signee: '',
//       sign_valid : true,
//       preview_audit_score: false,
//       latitude :'',
//       longitude :''

//     }


//     this.toggle = this.toggle.bind(this)
//     this.canvas = React.createRef();


//   }

//   async componentDidMount() {
//     this.canvas = React.createRef();
//     console.log('navigate')
//     try {
//       const {data} = this.props.location.state
//       console.log(data,'data')
//       this.setState({
//         latitude : data.latitude,
//         longitude : data.longitude
//       })
//     } catch (error) {
//       // console.log(error,'error')
//     }
 
//     // this.access_location()
//     await this.getSessionData()
//     await this.getEndpointInfo()
//     await this.checkPointProcess()
//   }

//   // access_location =()=>{
//   //   navigator.geolocation.getCurrentPosition(
//   //     position =>{
//   //       // console.log(postion,'posi')
//   //       this.setState({
//   //         latitude: position.coords.latitude,
//   //         longitude: position.coords.longitude,
//   //       },()=>{console.log(this.state.latitude,'latitude', this.state.longitude)});
//   //     },
//   //     error =>{
//   //       console.log('Location permission denied:', error.message,error);
//   //       if(error.code ==1){
//   //         Swal.fire({
//   //           icon: 'warning',
//   //           title: 'Location Permission Required!',
//   //           text: 'Please allow access to your location to continue.',
//   //           confirmButtonColor: '#3085d6',
//   //           confirmButtonText: 'OK'
//   //         }).then((result) => {

//   //           if (result.isConfirmed) {
//   //             Swal.fire({
//   //               icon: 'info',
//   //               title: 'Enable Location Access',
//   //               text: 'To enable location access, please go to your browser settings and grant permission for this website to access your location.',
//   //               confirmButtonColor: '#3085d6',
//   //               confirmButtonText: 'OK'
//   //             });
//   //           }
//   //         });
//   //       }
//   //     }
//   //   )
   
//   // }


//   modaltoggle = () => {
//     this.setState(prevState => ({
//       modal: !prevState.modal,
//     }))
//   }


//   toggle(tab) {
//     if (this.state.activeTab !== tab) {
//       this.setState({
//         activeTab: tab,
//       })
//     }
//   }

//   getCheckpointStatus() {
//     console.log("count", _.countBy(this.state.checkpoints, { cp_status: "1" }))
//     this.setState({
//       ns: _.filter(this.state.checkpoints, { cp_status: "0",document_type :"2" }).length,
//       ip: _.filter(this.state.checkpoints, { cp_status: "1",document_type :"2" }).length,
//       cpt: _.filter(this.state.checkpoints, { cp_status: "2",document_type :"2" }).length,
//       rtk: _.filter(this.state.checkpoints, { cp_status: "3" ,document_type :"2"}).length,
//       apvd: _.filter(this.state.checkpoints, { cp_status: "5",document_type :"2" }).length,
//       rjd: _.filter(this.state.checkpoints, { cp_status: "4" ,document_type :"2"}).length,
//       rwd: _.filter(this.state.checkpoints, item => {
//         return item.cp_review_status !== null
//       }).length,
//       dataloaded: true
//     })

//   }

//   async getSessionData() {

//     var data = JSON.parse(sessionStorage.getItem("authUser"));
//     var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//     var endpointData = JSON.parse(sessionStorage.getItem("endpointData"));

//     this.setState({
//       configData: data.config_data,
//       userData: data.user_data,
//       endpointData,
//       db_info:db_info,
//       imagePreviewUrl: data.config_data.img_url,
//       imageToken: data.config_data.img_url,
//       signee: data.user_data.firstname + data.user_data.lastname
//     },()=>{console.log(this.state.userData,"userdata")})

//   }

//   async getEndpointInfo() {
//     try {

//       urlSocket.post("webpbdadtdta/getendpointInfo", {
//         endpointInfo: this.state.endpointData,
//         userInfo: {
//           _id: this.state.userData._id,
//           encrypted_db_url:this.state.db_info.encrypted_db_url,
//           company_id: this.state.userData.company_id
//         }
//       })
//         .then(response => {
//           console.log("endpoint response", response.data.data)
//           this.setState({
//             endpointData: response.data.data,
//           })
//         })

//     } catch (error) {
//       console.log("catch error", error)
//     }
//   }

//   async checkPointProcess() {
//     console.log("audit_cp_status", this.state.endpointData.audit_cp_status)
//     if (this.state.endpointData.audit_cp_status === "0") {
//       await this.createCheckPoints()
//     }
//     else if (this.state.endpointData.audit_cp_status === "1") {
//       await this.loadCheckpoints()
//     }
//     else
//     {
//       await this.loadCheckpoints()
//     }
//   }

//   async createCheckPoints() {
//     console.log("clone checkpoints")
//     try {

//       urlSocket.post("epadtprcs/clonecpfmcpmaster", {
//         auditInfo: {
//           audit_pbd_id: this.state.endpointData.audit_pbd_id
//         },
//         userInfo: {
//           _id: this.state.userData._id,
//           encrypted_db_url : this.state.db_info.encrypted_db_url,
//           company_id: this.state.userData.company_id
//         },
//         endpointInfo: this.state.endpointData
//       })
//         .then(response => {
//           console.log(response,'response')
//           if (response.data.response_code === 500) {
//             this.setState({
//               checkpoints: _.filter(response.data.data, { document_type: "2" }),
//             }, function () {
//               this.getCheckpointStatus()
//               this.filterCheckpoints(this.state.filterStatus)
//             })
//           }

//         })

//     } catch (error) {
//       console.log("catch error", error)
//     }

//   }

//   async loadCheckpoints() {
//     console.log("load checkpoints")
//     try {

//       urlSocket.post("epadtprcs/getepcheckpoints", {
//         auditInfo: {
//           audit_pbd_id: this.state.endpointData.audit_pbd_id
//         },
//         userInfo: {
//           _id: this.state.userData._id,
//           company_id: this.state.userData.company_id,
//           encrypted_db_url:this.state.db_info.encrypted_db_url
//         },
//         endpointInfo: this.state.endpointData
//       })
//         .then(response => {
//           console.log(response,'response',this.state.filterStatus)
//           if (response.data.response_code === 500) {
//             console.log("response.data.data", response.data.data)
//             this.setState({
//               checkpoints:response.data.data 
//               // _.filter(response.data.data, { document_type: "2" }),

//             }, function () {
//               this.getCheckpointStatus()
//               this.filterCheckpoints(this.state.filterStatus)
//             })
//           }

//         })

//     } catch (error) {
//       console.log("catch error", error)
//     }
//   }

//   updateObservation(checkpoint) {
//     // checkpoint.cp_observation = this.state.
//   }

//   updateCheckpoint(data, item) {
//     // item["checkpoint_options"] = data
//     // console.log("item", item)
//     this.updateAudit(item)
    
//   }

//   updateAudit = (item) => {
//     // console.log("this.state.filteredData", this.state.filteredData,this.state.endpointData)
//     console.log(item)
//     this.saveCheckpoint(item)
//     if(this.state.endpointData.status === "5")
//     {
//       this.state.endpointData.status = "6"
//       this.state.endpointData.audit_cp_status = "Review In progresss";
//       this.setState({endpointData:this.state.endpointData}, () => {
//         this.saveCheckpoint(item)
//       })
//       return;
//     }
//     else
//     if(this.state.endpointData.status === "6" && this.state.checkpoints.length === (this.state.cpt + this.state.apvd + this.state.rjd)){
//       this.state.endpointData.audit_completed_on = new Date();
//       this.state.endpointData.audit_cp_status = "Completed";
//       this.state.endpointData.status = "2";
//       this.state.endpointData.review_status = "0";
//       this.setState({endpointData:this.state.endpointData}, () => {
//         this.saveCheckpoint(item)
//       })
//       return
//     }

//     if (this.state.endpointData.status !== "3" ) {
            
//       if (this.state.ns !== 0) {
//         if (
//           this.state.endpointData.status === "1" &&
//           this.state.ns === this.state.cpt
//         ) {
//           this.state.endpointData.audit_completed_on = new Date();
//           this.state.endpointData.audit_cp_status = "Completed";
//           this.state.endpointData.status = "2";
//           this.setState({endpointData:this.state.endpointData}, () => {
//             this.saveCheckpoint(item)
//           })
//           return;
//         }

//         if (
//           (this.state.endpointData.status === "0" || this.state.endpointData.status === "1" || this.state.endpointData.status === "2") &&
//           (this.state.cpt.value !== this.state.ns)
//         ) {
//           this.state.endpointData.audit_started_on = this.state.endpointData.audit_started_on === null ? new Date() : this.state.endpointData.audit_started_on;
//           this.state.endpointData.audit_cp_status = "In progress";
//           this.state.endpointData.status = "1";
//           this.setState({endpointData:this.state.endpointData}, () => {
//             this.saveCheckpoint(item)
//           })
//           return;
//         }

//       }
//     }
//   }


//   addCAPA(checkpoint) {
//     var cp_apln_length = checkpoint.cp_actionplans.length + 1
//     //.log("cp_apln_length", cp_apln_length)
//     checkpoint["cp_actionplans"] = checkpoint["cp_actionplans"].concat([
//       {
//         "id": checkpoint._id + String(cp_apln_length),
//         "observation": "",
//         "actionplan": "",
//         "target_date": null,
//         "to_email": "",
//         "to_phone": "",
//         "status": "0",
//         "isEdit": true,
//       }
//     ])

//     this.setState({ refresh: true })
//   }



//   saveCheckpoint(checkpoint) {
    
//     var cpStatus = ValidateCP.checkpointStatus(this.state.selectedCheckpoint)
//     console.log(cpStatus,this.state.endpointData)
//     if (this.state.endpointData.audit_coords_enable === true) {
//       this.state.endpointData["audit_lat"] = this.state.latitude
//       this.state.endpointData["audit_long"] = this.state.longitude
//     }

//     //.log("checkpoint ---", checkpoint,cpStatus)
//     checkpoint.cp_status = cpStatus
//     checkpoint.cp_actionplans = _.filter(checkpoint.cp_actionplans, { status: "1" })
//     try {

//       urlSocket.post("epadtprcs/updatecheckpoints", {
//         userInfo: {
//           encrypted_db_url:this.state.db_info.encrypted_db_url,
//           _id: this.state.userData._id,
//           company_id: this.state.userData.company_id
//         },
//         endpointInfo: this.state.endpointData,
//         checkpointInfo: checkpoint,
//         auditInfo: {
//           audit_pbd_id: this.state.endpointData.audit_pbd_id
//         },
//       })
//         .then(response => {
//           console.log(response,'response')
//           //.log(response,'response')
//           if (response.data.response_code === 500) {
//             // //.log("save", response.data)
//             var getIndex = _.findIndex(this.state.checkpoints, { _id: response.data.data._id })
//             if (getIndex !== -1) {
//               this.state.checkpoints[getIndex] = response.data.data
//             }
//             this.setState({
//               checkpoints: this.state.checkpoints,
//               endpointInfo: response.data.endpoint,
//             }, function () {
//               this.getCheckpointStatus()
//               //this.filterCheckpoints(this.state.filterStatus)
//             })
//           }
//         })

//     } catch (error) {
//       //.log("catch error", error)
//     }

//   }

//   filterCheckpoints(filterStatus) {
//     if (filterStatus === "all") {
//       this.setState({
//         filteredData: this.state.checkpoints,
//         showOption: false,
//         //selectedCheckpoint:null,
//         idx: -1
//       })
//     }
//     else
//       if (filterStatus === "rwd") {
//         this.setState({
//           filteredData: _.filter(this.state.checkpoints, item => { return item.cp_review_status !== null && item.document_type =="2" }),
//           showOption: false,
//           selectedCheckpoint: null,
//           idx: -1
//         })
//       }
//       else {
//         this.setState({
//           filteredData: _.filter(this.state.checkpoints, { cp_status: filterStatus  ,document_type :"2" }),
//           showOption: false,
//           selectedCheckpoint: null,
//           idx: -1
//         })
//       }
//   }

//   loadSelectedCheckpoint(item, i) {
//     this.setState({ showOption: false, optionSelected: false });
//     setTimeout(() => {
//       this.setState({
//         selectedCheckpoint: item,
//         idx: i,
//         optionSelected: _.filter(item.checkpoint_options, { is_selected: true }).length !== 0 ? true : false,
//         selected_option: _.filter(item.checkpoint_options, { is_selected: true })[0]
//       })
//       this.setState({ showOption: true });
//     }, 10);
//   }


//   getactionplanCount() {
//     var count = _.filter(this.state.selectedCheckpoint.cp_actionplans, { status: "1" }).length
//     return count
//   }

//   submitCheckpoint() {
//     var cpStatus = ValidateCP.checkpointStatus(this.state.selectedCheckpoint)
//     ////.log("cpStatus", cpStatus)
//     this.state.selectedCheckpoint.cp_status = cpStatus
//     this.saveCheckpoint(this.state.selectedCheckpoint)
//   }

//   confirmSubmit() {
//     var checkpoints = _.filter(this.state.checkpoints,{document_type :"2"})
//     console.log(checkpoints,this.state.cpt + this.state.apvd + this.state.rjd)
//     if (checkpoints.length !== (this.state.cpt + this.state.apvd + this.state.rjd)) {
//       this.setState({
//         showWarning: true,
//         submitMessage: "Cannot submit till you complete all check points"
//       })
//     }
//     else if (this.state.endpointData.audit_signature.length === 0) {
//       this.setState({
//         showWarning: true,
//         submitMessage: "Auditor signature is required."
//       })
//     }
//     else {
//       this.setState({ confirm_both: true })
//     }
//   }

//   submitAudit() {
//     console.log(this.state.checkpoints,this.state.endpointData,'this.state.checkpoints')
//     // const max_sum_value = _.sumBy(this.state.checkpoints, 'cp_otpion_score.$numberDecimal');
//     // console.log(max_sum_value,'max_sum_value')
//     var filter_out_checkpoints = _.filter(this.state.checkpoints,{document_type :"2"})
//     const sum_of_auditscore = _.sumBy(filter_out_checkpoints, obj => parseFloat(obj.cp_otpion_score.$numberDecimal));
//     console.log(sum_of_auditscore,'sum')
//     this.state.endpointData.audit_score= sum_of_auditscore
//     if (filter_out_checkpoints.length === (this.state.cpt + this.state.apvd + this.state.rjd)) {
//       console.log( this.state.endpointData,' this.state.endpointData')

//       try {

//         urlSocket.post("epadtprcs/updateendpoint", {
//           auditInfo: {
//             audit_pbd_id: this.state.endpointData.audit_pbd_id
//           },
//           userInfo: {
//             _id: this.state.userData._id,
//             encrypted_db_url:this.state.db_info.encrypted_db_url,
//             company_id: this.state.userData.company_id,
//             designation:this.state.signeeDesignation
//           },
//           endpointInfo: this.state.endpointData
//         })
//           .then(response => {
//             console.log(response,'response',this.state.endpointData)
//             //.log(response,'response')
//             if (response.data.response_code === 500) {
//               //.log("response.data.data", response.data.data)
//               console.log(response,'response',this.state.endpointData)
//               this.props.history.push("/usrenpdts");
//             //   if(this.state.endpointData.audit_score_preview == true){
//             //     console.log(response,'response',this.state.endpointData)
//             //     this.setState({dataloaded : false},()=>{this.setState({preview_audit_score: true })})
//             //     // return this.state.endpointData.audit_score_preview === true ?  <PreviewAuditScore/> : null
//             //     // return this.state.endpointData.audit_score_preview === true ?  <PreviewAuditScore/> : null
                
//             //   }
//             //   else{
//             //   this.props.history.push("/usrenpdts");
//             // }
//             }

//           })

//       } catch (error) {
//         //.log("catch error", error)
//       }

//     }
//     else {
//       this.setState({
//         showWarning: true
//       })
//     }

//   }


//   //-------------- signature ----------------------------



//   checkAuditeeSigned = () => {
//     var indexOfAuditSigned = _.findIndex(this.state.endpointData.audit_signature, {
//       audit_user_id: this.state.userData._id,
//     });
//     this.setState({isAuditSigned:indexOfAuditSigned === -1 ? false : true },() => {
//       console.log( this.state.isAuditSigned)
//       this.setState({ 
//         signee:this.state.isAuditSigned ? "" :this.state.endpointData.audit_signature.length == 0 ? this.state.userData.firstname : '',
//         signeeDesignation:this.state.isAuditSigned ? this.state.signeeDesignation : this.state.userData.designation,
//         isDisabled:this.state.isAuditSigned ? false : true,
//         custom_div: true, 
//         modal: true ,
//         // issign_empty: true
//       })
//     })
    
//   }


//   submit = () => {
//     //.log(this.state.issign_empty,'this.state.issign_empty')
//       console.log(this.state.signee,this.state.issign_empty)
//     if (this.state.signee === "" || this.state.signee === null || this.state.signeeDesignation === null) {
//       this.setState({ sign_valid: true, signboard_message: "Enter Name / Designation" })
//     }
//     else {
//       if (!this.state.issign_empty) {

//         this.setState({ modal: false })
//         //.log("canvas", this.canvas)
//         //.log(this.state.issign_empty , this.state.sign_valid)


//         this.canvas.current.exportImage("png").then(data => {
//           //.log(data,'data')
//           this.setState({ sign_url: data })
//           var ImageURL = data
//           //.log(ImageURL)
//           var block = ImageURL.split(";");
//           var contentType = block[0].split(":")[1];// In this case "image/gif"
//           var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
//           var blob = this.b64toBlob(realData, contentType);

//           this.sign_upload(blob)
//         })
//           .catch((error) => { 
//             // //.log("--catch error ---", error)
//            })

//       }
//       else {
//         this.setState({ sign_valid: true, signboard_message: "No signature in board" })
//       }
//     }



//   }

//   b64toBlob(b64Data, contentType, sliceSize) {
//     contentType = contentType || '';
//     sliceSize = sliceSize || 512;

//     var byteCharacters = atob(b64Data);
//     var byteArrays = [];

//     for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//       var slice = byteCharacters.slice(offset, offset + sliceSize);

//       var byteNumbers = new Array(slice.length);
//       for (var i = 0; i < slice.length; i++) {
//         byteNumbers[i] = slice.charCodeAt(i);
//       }

//       var byteArray = new Uint8Array(byteNumbers);

//       byteArrays.push(byteArray);
//     }

//     var blob = new Blob(byteArrays, { type: contentType });
//     return blob;
//   }




//   clear = () => {
//     //.log('clear')
//      this.canvas.current.clearCanvas(); this.setState({ sign_url: null, up_sign_url: null, issign_empty: true, sign_valid: false }) 
//     }
//   undo = () => { this.canvas.current.undo() }
//   redo = () => { this.canvas.current.redo() }

//   deleteSignature(id) {
//     var getIndex = _.findIndex(this.state.endpointData.audit_signature, { id: id })
//     this.state.endpointData.audit_signature.splice(getIndex, 1)

//     urlSocket.post('epadtprcs/updatesignature', {
//       auditInfo: {
//         audit_pbd_id: this.state.endpointData.audit_pbd_id
//       },
//       userInfo: {
//         encrypted_db_url:this.state.db_info.encrypted_db_url,
//         _id: this.state.userData._id,
//         company_id: this.state.userData.company_id
//       },
//       endpointInfo: this.state.endpointData
//     })
//       .then((response) => {
//         if (response.data.response_code === 500) {

//           this.setState({
//             signee: null,
//             issign_empty: true,
//             refresh: true
//           })
//         }
//       })

//   }

//   sign_upload = async (image_info) => {

//     const img = new FormData()
//     let u_id = uuid()

//     img.append('image', image_info, u_id + 'sign.png')
//     //.log(u_id + 'sign.png');

//     await urlSocket.post('storeImage/awswebupload', img)
//       .then((res) => {
//         if (res.status == 200) {
//           //.log(res)
//           let splitString = res.data.data[0].key.split("/");
//           let getFileName = splitString[splitString.length - 1];
//           //.log("getFileName", getFileName)
//           //this.setState({ up_sign_url: String(u_id) + 'sign.png' })
//           // //.log("uploaded _sign", res)
//           this.update_sign(u_id, getFileName)
//         }
//       })
//   }

//   update_sign = (u_id, fileName) => {
//     //.log("this.state.signee", this.state.signee, this.state.signeeDesignation)
//     var signature = {
//       "id": String(u_id),
//       "name": fileName,
//       "sign_by": this.state.signee,
//       "designation": this.state.signeeDesignation,
//       "preview": fileName,
//       "uploading": false,
//       "filetype": "image/png",
//       "uploadingStatus": "Uploaded",
//       "originalName": fileName,
//       "captured_on": new Date(),
//       "path": fileName,
//       "type": "image/png",
//       "uri": fileName,
//       "audit_user_id": !this.state.isAuditSigned ? this.state.userData._id : "",
//       "review_user_id": "",

//     }

//     this.state.endpointData["audit_signature"] = this.state.endpointData["audit_signature"].concat([signature])


//      //.log("this.state.endpointData", this.state.endpointData)
//     // //.log("signature", signature)
//     urlSocket.post('epadtprcs/updatesignature', {
//       auditInfo: {
//         audit_pbd_id: this.state.endpointData.audit_pbd_id
//       },
//       userInfo: {
//         _id: this.state.userData._id,
//         company_id: this.state.userData.company_id,
//         encrypted_db_url:this.state.db_info.encrypted_db_url,
//       },
//       endpointInfo: this.state.endpointData
//     })
//       .then((response) => {
//         //.log(response,'response')
//         if (response.data.response_code === 500) {

//           this.setState({
//             signee: null,
//             issign_empty: true,
//             refresh: true
//           })
//         }
//       })
//   }

//   del_sign = () => {
//     this.props.del_sign(this.props.entity_id, this.props.current_data)
//   }

//   OpenSign =()=>{
//     //.log('vv')
//   }

//   //-----------------------------------------------------


//   render() {
//     if (this.state.dataloaded) {
//       this.canvas = React.createRef();
//       var checkpoints = _.filter(this.state.checkpoints,{document_type:"2"})
//       return (
//         <React.Fragment>
//           <div className="page-content" >
//             <MetaTags>
//               <title>Auditvista | Check points</title>
//             </MetaTags>
//             <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-2">
//               <div style={{ width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
//                 Check points
//               </div>
//               <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
//                 <Link to="/usrenpdts"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//               </div>
//             </div>
//             <Container fluid>
//               <Card body>
               
                
//                 <Row className="my-3" style={{ paddingTop: 10 }}>
//                   <Col sm='4'>
//                     <CardTitle className="h3 mt-0">
//                       {this.state.endpointData.audit_pbd_name}
//                     </CardTitle>
//                     <CardText>
//                       {this.state.endpointData.name}{" / "}{this.state.endpointData.code}
//                     </CardText>
//                   </Col>
//                   <Col sm='8'>

//                     <div className="button-items mb-1">
//                       <div className="btn-group " role="group" aria-label="Basic radio toggle button group">
//                         <Button className="btn-sm" color="dark" outline onClick={() => { this.setState({ filterStatus: "all" }); this.filterCheckpoints("all") }}>
//                           Total check points{" - "}{checkpoints.length}
//                         </Button>
//                         <Button  className="btn-sm" color="secondary" outline onClick={() => { this.setState({ filterStatus: "0" }); this.filterCheckpoints("0") }}>
//                           Not started{" - "}{this.state.ns}
//                         </Button>
//                         <Button  className="btn-sm" color="warning" outline onClick={() => { this.setState({ filterStatus: "1" }); this.filterCheckpoints("1") }}>
//                           In progress{" - "}{this.state.ip}
//                         </Button>
//                         <Button className="btn-sm" color="success" outline onClick={() => { this.setState({ filterStatus: "2" }); this.filterCheckpoints("2") }}>
//                           Completed{" - "}{this.state.cpt}
//                         </Button>
//                         <Button  className="btn-sm" color="primary" outline onClick={() => { this.setState({ filterStatus: "rwd" }); this.filterCheckpoints("rwd") }}>
//                           Reviewed{" - "}{this.state.rwd}
//                         </Button>
//                       </div>
//                     </div>
//                     {/* <PreviewAuditScore/> */}

//                     {/* <div className="button-items mb-1">
//                       <div className="btn-group " role="group" aria-label="Basic radio toggle button group">
//                         <Link to="#" className="badge badge-soft-dark font-size-14" onClick={() => { this.setState({ filterStatus: "all" }); this.filterCheckpoints("all") }}>
//                           Total check points{" - "}{this.state.checkpoints.length}
//                         </Link>
//                         <Link to="#" className="badge badge-soft-secondary font-size-14" onClick={() => { this.setState({ filterStatus: "0" }); this.filterCheckpoints("0") }}>
//                           Not started{" - "}{this.state.ns}
//                         </Link>
//                         <Link to="#" className="badge badge-soft-warning font-size-14" onClick={() => { this.setState({ filterStatus: "1" }); this.filterCheckpoints("1") }}>
//                           In progress{" - "}{this.state.ip}
//                         </Link>
//                         <Link to="#" className="badge badge-soft-success font-size-14" onClick={() => { this.setState({ filterStatus: "2" }); this.filterCheckpoints("2") }}>
//                           Completed{" - "}{this.state.cpt}
//                         </Link>
//                         <Link to="#" className="badge badge-soft-primary font-size-14" onClick={() => { this.setState({ filterStatus: "rwd" }); this.filterCheckpoints("rwd") }}>
//                           Reviewed{" - "}{this.state.rwd}
//                         </Link>
//                       </div>
//                     </div> */}
//                   </Col>
//                 </Row>
//               </Card>


//               {/* <Card body>
//                 <CardTitle className="h3 mt-0">
//                   {this.state.endpointData.audit_pbd_name}
//                 </CardTitle>
//                 <CardText>
//                   {this.state.endpointData.name}{" / "}{this.state.endpointData.code}
//                 </CardText>

//                 <div>

//                   <Link to="#" className="badge badge-soft-dark font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "all" }); this.filterCheckpoints("all") }}>
//                     Total check points{" - "}{this.state.checkpoints.length}
//                   </Link>
//                   <Link to="#" className="badge badge-soft-secondary font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "0" }); this.filterCheckpoints("0") }}>
//                     Not started{" - "}{this.state.ns}
//                   </Link>
//                   <Link to="#" className="badge badge-soft-warning font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "1" }); this.filterCheckpoints("1") }}>
//                     In progress{" - "}{this.state.ip}
//                   </Link>
//                   <Link to="#" className="badge badge-soft-success font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "2" }); this.filterCheckpoints("2") }}>
//                     Completed{" - "}{this.state.cpt}
//                   </Link>
//                   <Link to="#" className="badge badge-soft-primary font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "rwd" }); this.filterCheckpoints("rwd") }}>
//                     Reviewed{" - "}{this.state.rwd}
//                   </Link>
//                 </div>
//               </Card> */}
//               <div className="d-xl-flex">
//                 <div className="w-100">
//                   <div className="d-md-flex">

//                     {/* 2nd left bar  */}
//                     <Card className="w-50 me-md-2  pb-3 ">
//                       <CardBody style={{ maxHeight: this.state.height - 250 }} className="overflow-auto">
//                         {
//                           this.state.filteredData.map((item, i) => {
//                             var getColor = item.cp_review_status !== null ? "#556ee6" : item.cp_status == '0' ? '#74788d' :
//                               item.cp_status == '1' ? '#f1b44c' :
//                                 item.cp_status == '2' ? '#34c38f' : '#50a5f1'
//                             return (
//                               <div className="card shadow-none mb-2" key={"chkpt" + String(i)}>
//                                 <div style={{
//                                   borderLeft: item.document_type == "2"? item.cp_review_status !== null ? "5px solid #556ee6" : item.cp_status === "0" ? "5px solid #74788d" : item.cp_status === "1" ? "5px solid #f1b44c" : item.cp_status === "2" ? "5px solid #34c38f" : "5px solid #50a5f1" : null,
//                                   borderRight: this.state.idx == i ? "1px solid #556ee6" : "1px solid #74788d2e",
//                                   borderTop: this.state.idx == i ? "1px solid #556ee6" : "1px solid #74788d2e",
//                                   borderBottom: this.state.idx == i ? "1px solid #556ee6" : "1px solid #74788d2e",
//                                 }}>
//                                   {
//                                     item.document_type == "2" ?
//                                       <Link to="#" className="text-body" onClick={() => { this.loadSelectedCheckpoint(item, i) }}>
//                                         <div className="p-2">
//                                           <div className="d-flex">
//                                             <div className="overflow-hidden me-auto">
//                                               <h5 className="font-size-13  mb-1">
//                                                 <span className=''>{item.checkpoint}</span>
//                                               </h5>

//                                               {
//                                                 this.state.endpointData.status === "2" || this.state.endpointData.status === "5" || this.state.endpointData.status === "6" || this.state.endpointData.status === "7" || this.state.endpointData.status === "1" ?
//                                                   <span
//                                                     className="badge badge-soft font-size-10"
//                                                     style={{ backgroundColor: statusColor[Number(item.cp_status)] }}
//                                                   >
//                                                     {statusText[Number(item.cp_status)]}</span> : null
//                                               }
//                                             </div>
//                                           </div>
//                                         </div>
//                                       </Link>
//                                       :
//                                       <div style={{backgroundColor :item.parent_id ==null? '#525252' :"#808080"}} className="p-2">
//                                       <div className="d-flex">
//                                         <div className="overflow-hidden me-auto">
//                                           <h5 className="font-size-13  mb-1">
//                                             <span style={{color:'#fffffff5'}}>{item.checkpoint}</span>
//                                           </h5>

//                                           {/* {
//                                             this.state.endpointData.status === "2" || this.state.endpointData.status === "5" || this.state.endpointData.status === "6" || this.state.endpointData.status === "7" || this.state.endpointData.status === "1" ?
//                                               <span
//                                                 className="badge badge-soft font-size-10"
//                                                 style={{ backgroundColor: statusColor[Number(item.cp_status)] }}
//                                               >
//                                                 {statusText[Number(item.cp_status)]}</span> : null
//                                           } */}
//                                         </div>
//                                       </div>
//                                     </div>
//                                   }

//                                 </div>
//                               </div>
//                             )
//                           })
//                         }
//                       </CardBody>
//                     </Card>
//                     {/* 3rd right bar  */}
//                     <Card className="w-100 pb-3 me-md-2">
//                       <CardBody style={{ maxHeight: this.state.height - 250 }} className="overflow-auto">
  
//                         {

//                           this.state.showOption && this.state.selectedCheckpoint.cp_status !== "4" && this.state.selectedCheckpoint.cp_status !== "5" ?
//                             <div className='d-flex flex-column bg-white' >

//                               <div className="my-1">
                                
//                                 <span
//                                   className={this.state.selectedCheckpoint.cp_review_status !== null ? "badge badge-soft-primary me-2" : this.state.selectedCheckpoint.cp_status === "0" ? "badge badge-soft-secondary me-2" : this.state.selectedCheckpoint.cp_status === "1" ? "badge badge-soft-warning me-2" : this.state.selectedCheckpoint.cp_status === "2" ? "badge badge-soft-success me-2" : "badge badge-soft-info me-2"}
//                                 >{this.state.selectedCheckpoint.cp_review_status !== null ? "Reviewed" : this.state.selectedCheckpoint.cp_status === "0" ? "Not started" : this.state.selectedCheckpoint.cp_status === "1" ? "In progress" : this.state.selectedCheckpoint.cp_status === "2" ? "Completed" : ""}</span>
//                                 {
//                                   this.state.endpointData.status === "4" ?
//                                     <span
//                                       className={this.state.selectedCheckpoint.cp_review_status === "0" ? "badge badge-soft-danger" : "badge badge-soft-success"}
//                                     >
//                                       {this.state.selectedCheckpoint.cp_review_status === "0" ? "Rejected" : "Accepted"}</span> : null
//                                 }
//                               </div>

//                               <CardTitle className="h4">{this.state.selectedCheckpoint.checkpoint}</CardTitle>


//                               <div className='my-2'>
//                                 {
//                                   //.log(this.state.selectedCheckpoint,'this.state.selectedCheckpoint')
//                                 }
//                                 {
//                                   <OptionComponent
//                                     options={this.state.selectedCheckpoint.checkpoint_options}
//                                     index={this.state.idx}
//                                     checkpoint={this.state.selectedCheckpoint}
//                                     imagePreviewUrl={this.state.imagePreviewUrl}
//                                     imageToken={this.state.imageToken}
//                                     selectionoption={(data, options) => { this.setState({ selected_option: data, optionSelected: true }, function () { this.updateCheckpoint(options, this.state.selectedCheckpoint) }) }}
//                                     //update={(data) => { this.updateCheckpoint(data, this.state.selectedCheckpoint) }}
//                                     updateimages={(data) => { this.updateCheckpointImages(data, this.state.selectedCheckpoint) }}
//                                     updatedocuments={(data) => { this.updateCheckpointDocuments(data, this.state.selectedCheckpoint) }}
//                                     audit_status={this.state.endpointData.status}

//                                   />
//                                 }
//                               </div>
//                               {
//                                 this.state.optionSelected && this.state.selectedCheckpoint.cp_compliance.id !=="4" ?
//                                   <>
//                                     <Nav tabs>
//                                       <NavItem>
//                                         <NavLink

//                                           // disabled={
//                                           //   this.state.selectedCheckpoint.cp_status === '0'
//                                           //     ? true
//                                           //     : this.state.selectedCheckpoint.cp_noof_images === 0
//                                           //     ? true
//                                           //     : false
//                                           // }
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "1",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("1")
//                                           }}
//                                         >
//                                           <div>
//                                             Images
//                                           </div>
//                                           {
//                                             this.state.selectedCheckpoint.cp_noof_images !== 0 ?
//                                               <span className={this.state.selected_option.images === undefined
//                                                 ? "badge badge-soft-danger"
//                                                 : this.state.selected_option.images.length >=
//                                                   this.state.selectedCheckpoint.cp_noof_images
//                                                   ? "badge badge-soft-success"
//                                                   : "badge badge-soft-danger"}
//                                               >{this.state.selected_option.images === undefined
//                                                 ? '0 / ' + this.state.selectedCheckpoint.cp_noof_images
//                                                 : this.state.selected_option.images.length +
//                                                 ' / ' +
//                                                 this.state.selectedCheckpoint.cp_noof_images} required </span> : <span>&nbsp;</span>
//                                           }

//                                         </NavLink>
//                                       </NavItem>
//                                       <NavItem>
//                                         <NavLink
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "2",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("2")
//                                           }}
//                                         >
//                                           <div>
//                                             Documents
//                                           </div>
//                                           {
//                                             this.state.selectedCheckpoint.cp_noof_documents !== 0 ?
//                                               <span className={
//                                                 this.state.selected_option.documents_attached.length >=
//                                                   this.state.selectedCheckpoint.cp_noof_documents
//                                                   ? "badge badge-soft-success"
//                                                   : "badge badge-soft-danger"}>
//                                                 {this.state.selected_option.documents_attached === undefined
//                                                   ? '0 / ' + this.state.selectedCheckpoint.cp_noof_documents
//                                                   : this.state.selected_option.documents_attached.length +
//                                                   ' / ' +
//                                                   this.state.selectedCheckpoint.cp_noof_documents} required </span>
//                                               : <span>&nbsp;</span>
//                                           }
//                                         </NavLink>
//                                       </NavItem>
//                                       <NavItem>
//                                         <NavLink
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "4",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("4")
//                                           }}
//                                         >
//                                           <div>
//                                             Comments
//                                           </div>
//                                           <span>&nbsp;</span>
//                                         </NavLink>
//                                       </NavItem>
//                                       <NavItem>
//                                         <NavLink
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "3",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("3")
//                                           }}
//                                         >
//                                           <div>
//                                             CAPA
//                                           </div>
//                                           {
//                                             this.state.selected_option.nc_mandatory ? <span
//                                               className={this.getactionplanCount() > 0 ? "badge badge-soft-success" : "badge badge-soft-danger"}
//                                             >Required</span> : <span>&nbsp;</span>
//                                           }
//                                           <span>&nbsp;</span>
//                                         </NavLink>
//                                       </NavItem>
//                                       {/* <NavItem>
//                                         <NavLink
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "4",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("4")
//                                           }}
//                                         >
//                                           <div>
//                                             Comments
//                                           </div>
//                                           <span>&nbsp;</span>
//                                         </NavLink>
//                                       </NavItem> */}
//                                       <NavItem>
//                                         <NavLink
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "5",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("5")
//                                           }}
//                                         >
//                                           <div>
//                                             Guideline
//                                           </div>
//                                           <span>&nbsp;</span>
//                                         </NavLink>
//                                       </NavItem>
//                                     </Nav>

//                                     <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
//                                       <TabPane tabId="1">
//                                         <Row>
//                                           <Col sm="12">
//                                             <CRUD_Images
//                                               selected_option={this.state.selected_option}
//                                               selectedCheckpoint={this.state.selectedCheckpoint}
//                                               saveCheckpoint={(data) => this.saveCheckpoint(data)}
//                                               imagePreviewUrl={this.state.imagePreviewUrl}
//                                               audit_status={this.state.endpointData.status}
//                                               latitude = {this.state.latitude}
//                                               longitude = {this.state.longitude}
//                                               endpointData ={this.state.endpointData}


//                                               // {...this.state.latitude}
//                                               // {...this.state.longitude}
//                                             />
//                                           </Col>
//                                         </Row>
//                                       </TabPane>
//                                       <TabPane tabId="2">
//                                         {
//                                           console.log(this.state.imagePreviewUrl,'this.state.imagePreviewUrl')
//                                         }
//                                         <Row>
//                                           <Col sm="12">
//                                             <CRUD_Documents
//                                               selected_option={this.state.selected_option}
//                                               selectedCheckpoint={this.state.selectedCheckpoint}
//                                               saveCheckpoint={(data) => this.saveCheckpoint(data)}
//                                               imagePreviewUrl={this.state.imagePreviewUrl}
//                                               audit_status={this.state.endpointData.status}
//                                             />
//                                           </Col>
//                                         </Row>
//                                       </TabPane>
//                                       <TabPane tabId="3">
//                                         <Row>
//                                           <Col sm="12">
//                                             {
//                                               this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
//                                                 <div className="mb-2">
//                                                   <Button className="btn-sm" color="primary" onClick={() => { this.addCAPA(this.state.selectedCheckpoint) }} >Add Action</Button>
//                                                 </div> : null
//                                             }

//                                             {
//                                               this.state.selectedCheckpoint.cp_actionplans.map((apln, idx) => {
//                                                 return (
//                                                   <CAPA
//                                                     key={"capa" + String(idx)}
//                                                     index={idx}
//                                                     data={apln}
//                                                     onsave={(data) => { this.addapln(this.state.selectedCheckpoint, data, idx) }}
//                                                     onadd={() => { this.addCAPA(this.state.selectedCheckpoint) }}
//                                                     saveCheckpoint={(data) => this.saveCheckpoint(data)}
//                                                     selectedCheckpoint={this.state.selectedCheckpoint}
//                                                     audit_status={this.state.endpointData.status}
//                                                   />
//                                                 )
//                                               })
//                                             }
//                                           </Col>

//                                         </Row>
//                                       </TabPane>
//                                       <TabPane tabId="4">
//                                         <Row>
//                                           <Col sm="12">
//                                             <Observation
//                                               selectedCheckpoint={this.state.selectedCheckpoint}
//                                               saveCheckpoint={(data) => this.saveCheckpoint(data)}
//                                               audit_status={this.state.endpointData.status}
//                                             />
//                                           </Col>
//                                         </Row>
//                                       </TabPane>
//                                       <TabPane tabId="5">
//                                         <Row>
//                                           <Col sm="12">
//                                             <label>Guideline</label>
//                                             <div>
//                                               {
//                                                 this.state.selectedCheckpoint.guideline
//                                               }
//                                               <Row>
//                                                 {
//                                                   this.state.selectedCheckpoint.guideline_image.map(item => {
//                                                     return (<div className="m-2">
//                                                       <img src={item.preview} className="img-fluid" />
//                                                     </div>)
//                                                   })
//                                                 }
//                                               </Row>
//                                             </div>
//                                           </Col>
//                                         </Row>
//                                       </TabPane>
//                                     </TabContent>
//                                   </> : null
//                               }


//                               {/* <div className="row my-3 px-3">
//                               <Button color="dark" onClick={()=>this.submitCheckpoint()}>Submit</Button>
//                             </div> */}

//                             </div> :
//                             this.state.showOption ?
//                               <div className='d-flex flex-column bg-white' >
//                                 <div className="mb-2">
//                                   <label className={this.state.selectedCheckpoint.cp_review_status !== null ? "badge badge-soft-primary font-size-10 me-2" : this.state.selectedCheckpoint.cp_status === "0" ? "badge badge-soft-secondary font-size-10 me-2" : this.state.selectedCheckpoint.cp_status === "1" ? "badge badge-soft-warning font-size-10 me-2" : "badge badge-soft-success font-size-10 me-2"}>{this.state.selectedCheckpoint.cp_review_status !== null ? "Reviewed" : this.state.selectedCheckpoint.cp_status === "0" ? "Not started" : this.state.selectedCheckpoint.cp_status === "1" ? "In progress" : "Completed"}</label>
//                                   <label className={this.state.selectedCheckpoint.cp_is_compliance ? "badge badge-soft-danger font-size-10" : "badge badge-soft-success font-size-10"}>{this.state.selectedCheckpoint.cp_is_compliance ? "Compliance" : "Non-Compliance"}</label>
//                                 </div>
//                                 <div className="mb-1">
//                                   <span className="font-size-13">{this.state.selectedCheckpoint.checkpoint}</span>
//                                 </div>

//                                 {
//                                   this.state.selectedCheckpoint.checkpoint_type_id === "1" || this.state.selectedCheckpoint.checkpoint_type_id === "2" ?
//                                     <ReviewOPType
//                                       options={this.state.selectedCheckpoint.checkpoint_options}

//                                     />
//                                     : null
//                                 }

//                                 {
//                                   this.state.selectedCheckpoint.cp_attach_images.length !== 0 ?
//                                     <div className="mt-3 mb-4">
//                                       <label>Images Attached</label>
//                                       <PreviewImage
//                                         imagePreviewUrl={this.state.imagePreviewUrl}
//                                         images={this.state.selectedCheckpoint.cp_attach_images}
//                                       />
//                                     </div> : null
//                                 }
//                                 {
//                                   console.log(this.state.imagePreviewUrl,"image")
//                                 }

//                                 {
//                                   this.state.selectedCheckpoint.cp_documents.length !== 0 ?
//                                     <div className="my-4">
//                                       <label>Documents Attached</label>
//                                       <PreviewDocuments
//                                         imagePreviewUrl={this.state.imagePreviewUrl}
//                                         images={this.state.selectedCheckpoint.cp_documents}
//                                       />
//                                     </div> : null

//                                 }

//                                 {
//                                   this.state.selectedCheckpoint.cp_observation !== null ?
//                                     <div className="my-4">
//                                       <label>Observation</label>
//                                       <PreviewObservation
//                                         observation={this.state.selectedCheckpoint.cp_observation}
//                                       />
//                                     </div> : null
//                                 }

//                                 {
//                                   this.state.selectedCheckpoint.cp_actionplans.length !== 0 ?
//                                     <div className="my-4">
//                                       <label>CAPA</label>
//                                       <PreviewCAPA
//                                         actionplans={this.state.selectedCheckpoint.cp_actionplans}
//                                       />
//                                     </div> : null
//                                 }
//                               </div> :
//                               <div className='d-flex p-3 bg-white justify-content-center align-item-center' >
//                                 <p>Select check point to audit</p>
//                               </div>
//                         }
//                       </CardBody>
//                     </Card>
//                     {/* 1st left bar  */}
//                     <Card className="filemanager-sidebar ">
//                       <CardBody style={{ height: this.state.height - 250 }} className="overflow-auto">
//                         {
//                           console.log(this.state.endpointData.audit_signature,'this.state.endpointData.audit_signature')
//                         }

//                         {
//                           this.state.endpointData.audit_signature.length !== 0 ?
//                             <div>
//                               {
//                                 this.state.endpointData.audit_signature.map((item, i) => {
//                                   //.log(item,'item',this.state.endpointData.audit_signature)
//                                   return (
//                                     <Card
//                                       className="mt-1 mb-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
//                                       key={i + "-signfile"}
//                                     >
//                                       <div style={{ display: 'flex', flexDirection: 'row' }} >
//                                         <div className="p-2 col-10" >
//                                           <label className="mb-1 font-size-11">{item.sign_by}<br />{item.designation}</label>
//                                           <img height="80" src={this.state.imagePreviewUrl + item.name} />
//                                         </div>
//                                         {
//                                           this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
//                                             <div className="col-2 text-end me-2" style={{ lineHeight: 1.3 }}>
//                                               <Link to="#" onClick={() => this.deleteSignature(item.id)}><i className="mdi mdi-close-circle-outline font-size-20 text-danger" /></Link>
//                                             </div> : null
//                                         }

//                                       </div>
//                                     </Card>

//                                   )
//                                 })
//                               }
//                             </div> : null
//                         }
//                         {
//                           this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
//                             <div className="my-2">
//                               <Col className="mb-2">
//                                 <div className="p-3">
//                                   <p>Signature</p>
//                                   <Button
//                                     color="primary"
//                                     onClick={() => { this.checkAuditeeSigned() }}
//                                     id="custom-padding-width-alert"
//                                   >
//                                     Add signature
//                                   </Button>
//                                 </div>


//                               </Col>
//                             </div> : null
//                         }


//                         {
//                           this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
//                             <div className="my-2">
//                               <Col className="mb-2">
//                                 <div className="p-3">
//                                   <p>Submit Audit</p>
//                                   <Button
//                                     color="success"
//                                     onClick={() => this.confirmSubmit()}
//                                     id="custom-padding-width-alert"
//                                   >
//                                     Submit and close audit
//                                   </Button>
//                                 </div>
//                               </Col>
//                             </div> :
//                             <div>
//                               <label>Audit Started on</label>
//                               <p>{moment(this.state.endpointData.audit_started_on).format("DD/MM/YYYY")}</p>
//                               <label>Audit Completed on</label>
//                               <p>{moment(this.state.endpointData.audit_completed_on).format("DD/MM/YYYY")}</p>
//                               <label>Audit Submitted on</label>
//                               <p>{moment(this.state.endpointData.audit_submitted_on).format("DD/MM/YYYY")}</p>
//                             </div>
//                         }


//                       </CardBody>
//                     </Card>
//                   </div>
//                 </div>
//               </div>

//               <Modal
//                 isOpen={this.state.modal}
//                 className={this.props.className}
//               >
//                 <ModalHeader toggle={this.modaltoggle} tag="h4">
//                   Signature
//                 </ModalHeader>
//                 <ModalBody>
//                   <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
//                     <div className="mb-3" >
//                       <AvField
//                         name="signee"
//                         value={this.state.endpointData.audit_signature.length ==0 ? this.state.userData.firstname :''}
//                         onChange={(e) => { this.setState({ signee: e.target.value }) }}
//                         className="form-control"
//                         placeholder="Enter signee name"
//                         type="input"
//                         disabled={this.state.isDisabled}
//                         required
//                       />
//                     </div>
//                     <div className="mb-3" >
//                       <AvField
//                         name="designation"
//                         value={this.state.userData.designation === "" ? "" : this.state.userData.designation}
//                         onChange={(e) => { this.setState({ signeeDesignation: e.target.value }) }}
//                         className="form-control"
//                         placeholder="Enter designation"
//                         type="input"
//                         // disabled={this.state.userData.designation === "" ? false : true}
//                         required
//                       />
//                     </div>


//                     <ReactSketchCanvas
//                       ref={this.canvas}
//                       style={styles}
//                       width="600"
//                       height="400"
//                       strokeWidth={4}
//                       strokeColor="red"
//                       onStroke= {() => this.setState({ issign_empty: false, sign_valid: false })}
//                       // onChange={() => this.setState({ issign_empty: false, sign_valid: false })}
//                       // onChange={() => this.OpenSign()}
//                     />
//                     {
//                       //.log(this.state.issign_empty , this.state.sign_valid)
//                     }
//                     {
//                       this.state.sign_valid ? <div className="m-2"><label className="text-danger">{this.state.signboard_message}</label></div> : null
//                     }

//                     <Button className="my-2 me-2" color="primary" onClick={()=>{this.submit()}}>Submit</Button>
//                     <Button className="my-2 me-2" color="warning" onClick={()=>{this.clear()}}>Clear</Button>
//                     <Button className="my-2" color="danger" onClick={() => this.setState({ modal: false })}>Cancel</Button>
//                   </AvForm>
//                 </ModalBody>
//               </Modal>

//               {this.state.showWarning ? (
//                 <SweetAlert
//                   title="Message"
//                   warning
//                   onConfirm={() => this.setState({ showWarning: false })}
//                 >
//                   {this.state.submitMessage}
//                 </SweetAlert>
//               ) : null}

//               {this.state.confirm_both ? (
//                 <SweetAlert
//                   title="Are you sure?"
//                   warning
//                   showCancel
//                   confirmBtnBsStyle="success"
//                   cancelBtnBsStyle="danger"
//                   onConfirm={() =>
//                     this.setState({
//                       confirm_both: false,
//                       success_dlg: true,
//                       dynamic_title: "Deleted",
//                       dynamic_description: "Your file has been deleted.",
//                     },
//                       function () {
//                         this.submitAudit()
//                       }
//                     )
//                   }
//                   onCancel={() =>
//                     this.setState({
//                       confirm_both: false,
//                       error_dlg: true,
//                       dynamic_title: "Cancelled",
//                       dynamic_description:
//                         "Your imaginary file is safe :)",
//                     })
//                   }
//                 >
//                   You won't be able to revert this!
//                 </SweetAlert>
//               ) : null}



//             </Container>
//           </div>
//         </React.Fragment>
//       )
//     }
//     else {
//         return null
//     }
//   }

// }

// const renderer = ({ hours, minutes, seconds, completed }) => {
//   if (completed) {
//     // Render a completed state
//     console.log(completed,'completed')
//     window.location.href=('/usrenpdts')
//     return null;
//     // document.location.href('/')
//     // window.location.href=('/usrenpdts')
//     // this.props.hsi
//     // return ;
//   } else {
//     // Render a countdown
//     return <span>{hours}:{minutes}:{seconds}</span>;
//   }
// };












//venu-13-06-23
// import React, { Component } from "react";
// import { ReactSketchCanvas } from 'react-sketch-canvas';
// import ReactDOM from 'react-dom';
// import MetaTags from 'react-meta-tags';
// import {
//   Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
//   Input,
//   CardText,
//   CardTitle,
//   Collapse,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   TabPane,
//   Modal, ModalBody, ModalHeader
// } from "reactstrap";
// import Dropzone from "react-dropzone"
// import MediaPreview from "./Components/media_preview";

// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// import { Link, withRouter } from "react-router-dom"

// import facility from '../FacilityScreen'

// import moment from 'moment'
// import SweetAlert from "react-bootstrap-sweetalert"


// import OptionComponent from "./Components/option_component";
// import Observation from "./Components/observation";
// import CAPA from "./Components/capa";
// import classnames from "classnames"
// import ValidateCP from "./Functions/validate_cp"

// import CRUD_Images from "./Components/CRUD_Images";
// import CRUD_Documents from "./Components/CRUD_Documents";

// import CRUDSign from "./Functions/add_signature"
// import uuid from 'react-uuid'

// import ReviewOPType from "./Components/review_optype";
// import PreviewImage from "./Components/preview_images";
// import PreviewDocuments from "./Components/preview_documents";
// import PreviewObservation from "./Components/preview_observation";
// import PreviewCAPA from "./Components/preview_CAPA";


// // import "flatpickr/dist/themes/material_blue.css"
// // import Flatpickr from "react-flatpickr"

// const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")

// const styles = {
//   border: '0.0625rem solid #9c9c9c',
//   borderRadius: '0.25rem',
// };


// const statusText = ["Not started", "In progress", "Completed", "Retake", "Rejected", "Approved"]
// const statusColor = ["#555657", "#FDA705", "#31D9AC", "#F76518", "#E22E2E", "#49AF30"]
// export default class Checkpoints extends React.Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       dataloaded: false,
//       activeTab: "1",
//       height: window.innerHeight,
//       selectedCheckpoint: null,
//       filterStatus: "all",
//       showOption: false,
//       attachImages: [],
//       attachDocuments: [],
//       selectedOption: null,
//       docfileStatus: "clear",
//       fileStatus: "clear",
//       signeeDesignation:'',
//       doc_warning_enabled: false,
//       warning_enabled: false,
//       issign_empty: true,
//       signee: null

//     }


//     this.toggle = this.toggle.bind(this)
//     this.canvas = React.createRef();


//   }

//   async componentDidMount() {
//     this.canvas = React.createRef();
//     await this.getSessionData()
//     await this.getEndpointInfo()
//     await this.checkPointProcess()
//   }

//   modaltoggle = () => {
//     this.setState(prevState => ({
//       modal: !prevState.modal,
//     }))
//   }


//   toggle(tab) {
//     if (this.state.activeTab !== tab) {
//       this.setState({
//         activeTab: tab,
//       })
//     }
//   }

//   getCheckpointStatus() {
//     console.log("count", _.countBy(this.state.checkpoints, { cp_status: "1" }))
//     this.setState({
//       ns: _.filter(this.state.checkpoints, { cp_status: "0" }).length,
//       ip: _.filter(this.state.checkpoints, { cp_status: "1" }).length,
//       cpt: _.filter(this.state.checkpoints, { cp_status: "2" }).length,
//       rtk: _.filter(this.state.checkpoints, { cp_status: "3" }).length,
//       apvd: _.filter(this.state.checkpoints, { cp_status: "5" }).length,
//       rjd: _.filter(this.state.checkpoints, { cp_status: "4" }).length,
//       rwd: _.filter(this.state.checkpoints, item => {
//         return item.cp_review_status !== null
//       }).length,
//       dataloaded: true
//     })

//   }

//   async getSessionData() {

//     var data = JSON.parse(sessionStorage.getItem("authUser"));
//     var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//     var endpointData = JSON.parse(sessionStorage.getItem("endpointData"));
//     var protect_routes = await facility(data.facility_data, 'usradt')
//     if(protect_routes!== undefined){
//     this.setState({
//       configData: data.config_data,
//       userData: data.user_data,
//       endpointData,
//       db_info:db_info,
//       imagePreviewUrl: data.config_data.img_url,
//       imageToken: data.config_data.img_url
//     },()=>{console.log(this.state.userData,"userdata")})
//   }
//   else{
//     this.props.history.push('/dashboard')
//   }
//   }

//   async getEndpointInfo() {
//     try {

//       urlSocket.post("webpbdadtdta/getendpointInfo", {
//         endpointInfo: this.state.endpointData,
//         userInfo: {
//           _id: this.state.userData._id,
//           encrypted_db_url:this.state.db_info.encrypted_db_url,
//           company_id: this.state.userData.company_id
//         }
//       })
//         .then(response => {
//           console.log("response", response.data.data)
//           this.setState({
//             endpointData: response.data.data,
//           })
//         })

//     } catch (error) {
//       console.log("catch error", error)
//     }
//   }

//   async checkPointProcess() {
//     if (this.state.endpointData.audit_cp_status === "0") {
//       await this.createCheckPoints()
//     }
//     else if (this.state.endpointData.audit_cp_status === "1") {
//       await this.loadCheckpoints()
//     }
//   }

//   async createCheckPoints() {

//     try {

//       urlSocket.post("epadtprcs/clonecpfmcpmaster", {
//         auditInfo: {
//           audit_pbd_id: this.state.endpointData.audit_pbd_id
//         },
//         userInfo: {
//           _id: this.state.userData._id,
//           encrypted_db_url : this.state.db_info.encrypted_db_url,
//           company_id: this.state.userData.company_id
//         },
//         endpointInfo: this.state.endpointData
//       })
//         .then(response => {
//           console.log(response,'response')
//           if (response.data.response_code === 500) {
//             this.setState({
//               checkpoints: _.filter(response.data.data, { document_type: "2" }),
//             }, function () {
//               this.getCheckpointStatus()
//               this.filterCheckpoints(this.state.filterStatus)
//             })
//           }

//         })

//     } catch (error) {
//       console.log("catch error", error)
//     }

//   }

//   async loadCheckpoints() {
//     console.log("load")
//     try {

//       urlSocket.post("epadtprcs/getepcheckpoints", {
//         auditInfo: {
//           audit_pbd_id: this.state.endpointData.audit_pbd_id
//         },
//         userInfo: {
//           _id: this.state.userData._id,
//           company_id: this.state.userData.company_id,
//           encrypted_db_url:this.state.db_info.encrypted_db_url
//         },
//         endpointInfo: this.state.endpointData
//       })
//         .then(response => {
//           console.log(response,'response')
//           if (response.data.response_code === 500) {
//             console.log("response.data.data", response.data.data)
//             this.setState({
//               checkpoints: _.filter(response.data.data, { document_type: "2" }),

//             }, function () {
//               this.getCheckpointStatus()
//               this.filterCheckpoints(this.state.filterStatus)
//             })
//           }

//         })

//     } catch (error) {
//       console.log("catch error", error)
//     }
//   }

//   updateObservation(checkpoint) {
//     // checkpoint.cp_observation = this.state.
//   }

//   updateCheckpoint(data, item) {
//     // item["checkpoint_options"] = data
//     console.log("item", item)
//     this.updateAudit(item)
    
//   }

//   updateAudit = (item) => {
//     console.log("this.state.filteredData", this.state.filteredData,this.state.endpointData.status)

//     if(this.state.endpointData.status === "5")
//     {
//       this.state.endpointData.status = "6"
//       this.state.endpointData.audit_cp_status = "Review In progresss";
//       this.setState({endpointData:this.state.endpointData}, () => {
//         this.saveCheckpoint(item)
//       })
//       return;
//     }
//     else
//     if(this.state.endpointData.status === "6" && this.state.checkpoints.length === (this.state.cpt + this.state.apvd + this.state.rjd)){
//       this.state.endpointData.audit_completed_on = new Date();
//       this.state.endpointData.audit_cp_status = "Completed";
//       this.state.endpointData.status = "2";
//       this.state.endpointData.review_status = "0";
//       this.setState({endpointData:this.state.endpointData}, () => {
//         this.saveCheckpoint(item)
//       })
//       return
//     }

//     if (this.state.endpointData.status !== "3" ) {
            
//       if (this.state.ns !== 0) {
//         if (
//           this.state.endpointData.status === "1" &&
//           this.state.ns === this.state.cpt
//         ) {
//           this.state.endpointData.audit_completed_on = new Date();
//           this.state.endpointData.audit_cp_status = "Completed";
//           this.state.endpointData.status = "2";
//           this.setState({endpointData:this.state.endpointData}, () => {
//             this.saveCheckpoint(item)
//           })
//           return;
//         }

//         if (
//           (this.state.endpointData.status === "0" || this.state.endpointData.status === "1" || this.state.endpointData.status === "2") &&
//           (this.state.cpt.value !== this.state.ns)
//         ) {
//           this.state.endpointData.audit_started_on = this.state.endpointData.audit_started_on === null ? new Date() : this.state.endpointData.audit_started_on;
//           this.state.endpointData.audit_cp_status = "In progress";
//           this.state.endpointData.status = "1";
//           this.setState({endpointData:this.state.endpointData}, () => {
//             this.saveCheckpoint(item)
//           })
//           return;
//         }

//       }
//     }
//   }


//   addCAPA(checkpoint) {
//     var cp_apln_length = checkpoint.cp_actionplans.length + 1
//     console.log("cp_apln_length", cp_apln_length)
//     checkpoint["cp_actionplans"] = checkpoint["cp_actionplans"].concat([
//       {
//         "id": checkpoint._id + String(cp_apln_length),
//         "observation": "",
//         "actionplan": "",
//         "target_date": null,
//         "to_email": "",
//         "to_phone": "",
//         "status": "0",
//         "isEdit": true,
//       }
//     ])

//     this.setState({ refresh: true })
//   }



//   saveCheckpoint(checkpoint) {
//     var cpStatus = ValidateCP.checkpointStatus(this.state.selectedCheckpoint)
//     console.log("checkpoint ---", checkpoint)
//     checkpoint.cp_status = cpStatus
//     checkpoint.cp_actionplans = _.filter(checkpoint.cp_actionplans, { status: "1" })
//     try {

//       urlSocket.post("epadtprcs/updatecheckpoints", {
//         userInfo: {
//           encrypted_db_url:this.state.db_info.encrypted_db_url,
//           _id: this.state.userData._id,
//           company_id: this.state.userData.company_id
//         },
//         endpointInfo: this.state.endpointData,
//         checkpointInfo: checkpoint,
//         auditInfo: {
//           audit_pbd_id: this.state.endpointData.audit_pbd_id
//         },
//       })
//         .then(response => {
//           if (response.data.response_code === 500) {
//             console.log("save", response.data)
//             var getIndex = _.findIndex(this.state.checkpoints, { _id: response.data.data._id })
//             if (getIndex !== -1) {
//               this.state.checkpoints[getIndex] = response.data.data
//             }
//             this.setState({
//               checkpoints: this.state.checkpoints,
//               endpointInfo: response.data.endpoint,
//             }, function () {
//               this.getCheckpointStatus()
//               //this.filterCheckpoints(this.state.filterStatus)
//             })
//           }
//         })

//     } catch (error) {
//       console.log("catch error", error)
//     }

//   }

//   filterCheckpoints(filterStatus) {
//     if (filterStatus === "all") {
//       this.setState({
//         filteredData: this.state.checkpoints,
//         showOption: false,
//         //selectedCheckpoint:null,
//         idx: -1
//       })
//     }
//     else
//       if (filterStatus === "rwd") {
//         this.setState({
//           filteredData: _.filter(this.state.checkpoints, item => { return item.cp_review_status !== null }),
//           showOption: false,
//           selectedCheckpoint: null,
//           idx: -1
//         })
//       }
//       else {
//         this.setState({
//           filteredData: _.filter(this.state.checkpoints, { cp_status: filterStatus }),
//           showOption: false,
//           selectedCheckpoint: null,
//           idx: -1
//         })
//       }
//   }

//   loadSelectedCheckpoint(item, i) {
//     this.setState({ showOption: false, optionSelected: false });
//     setTimeout(() => {
//       this.setState({
//         selectedCheckpoint: item,
//         idx: i,
//         optionSelected: _.filter(item.checkpoint_options, { is_selected: true }).length !== 0 ? true : false,
//         selected_option: _.filter(item.checkpoint_options, { is_selected: true })[0]
//       })
//       this.setState({ showOption: true });
//     }, 10);
//   }


//   getactionplanCount() {
//     var count = _.filter(this.state.selectedCheckpoint.cp_actionplans, { status: "1" }).length
//     return count
//   }

//   submitCheckpoint() {
//     var cpStatus = ValidateCP.checkpointStatus(this.state.selectedCheckpoint)
//     console.log("cpStatus", cpStatus)
//     this.state.selectedCheckpoint.cp_status = cpStatus
//     this.saveCheckpoint(this.state.selectedCheckpoint)
//   }

//   confirmSubmit() {
//     if (this.state.checkpoints.length !== (this.state.cpt + this.state.apvd + this.state.rjd)) {
//       this.setState({
//         showWarning: true,
//         submitMessage: "Cannot submit till you complete all check points"
//       })
//     }
//     else if (this.state.endpointData.audit_signature.length === 0) {
//       this.setState({
//         showWarning: true,
//         submitMessage: "Auditor signature is required."
//       })
//     }
//     else {
//       this.setState({ confirm_both: true })
//     }
//   }

//   submitAudit() {

//     if (this.state.checkpoints.length === (this.state.cpt + this.state.apvd + this.state.rjd)) {

//       try {

//         urlSocket.post("epadtprcs/updateendpoint", {
//           auditInfo: {
//             audit_pbd_id: this.state.endpointData.audit_pbd_id
//           },
//           userInfo: {
//             _id: this.state.userData._id,
//             encrypted_db_url:this.state.db_info.encrypted_db_url,
//             company_id: this.state.userData.company_id,
//             designation:this.state.signeeDesignation
//           },
//           endpointInfo: this.state.endpointData
//         })
//           .then(response => {
//             console.log(response,'response')
//             if (response.data.response_code === 500) {
//               console.log("response.data.data", response.data.data)
//               this.props.history.push("/usrenpdts");
//             }

//           })

//       } catch (error) {
//         console.log("catch error", error)
//       }

//     }
//     else {
//       this.setState({
//         showWarning: true
//       })
//     }

//   }


//   //-------------- signature ----------------------------



//   checkAuditeeSigned = () => {
//     var indexOfAuditSigned = _.findIndex(this.state.endpointData.audit_signature, {
//       audit_user_id: this.state.userData._id,
//     });
//     this.setState({isAuditSigned:indexOfAuditSigned === -1 ? false : true },() => {
//       this.setState({ 
//         signee:this.state.isAuditSigned ? this.state.signee : this.state.userData.first_name,
//         signeeDesignation:this.state.isAuditSigned ? this.state.signeeDesignation : this.state.userData.designation,
//         isDisabled:this.state.isAuditSigned ? false : true,
//         custom_div: true, 
//         modal: true 
//       })
//     })
    
//   }


//   submit = () => {

//     console.log("this.state.signee", this.state.signee, this.state.issign_empty,this.state.signeeDesignation)
//     if (this.state.signee === null || this.state.signeeDesignation === null) {
//       this.setState({ sign_valid: true, signboard_message: "Enter Name / Designation" })
//     }
//     else {
//       if (!this.state.issign_empty) {

//         this.setState({ modal: false })
//         console.log("canvas", this.canvas)

//         this.canvas.current.exportImage("png").then(data => {

//           this.setState({ sign_url: data })
//           var ImageURL = data
//           var block = ImageURL.split(";");
//           var contentType = block[0].split(":")[1];// In this case "image/gif"
//           var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
//           var blob = this.b64toBlob(realData, contentType);

//           this.sign_upload(blob)
//         })
//           .catch((error) => { console.log("--catch error ---", error) })

//       }
//       else {
//         this.setState({ sign_valid: true, signboard_message: "No signature in board" })
//       }
//     }



//   }

//   b64toBlob(b64Data, contentType, sliceSize) {
//     contentType = contentType || '';
//     sliceSize = sliceSize || 512;

//     var byteCharacters = atob(b64Data);
//     var byteArrays = [];

//     for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//       var slice = byteCharacters.slice(offset, offset + sliceSize);

//       var byteNumbers = new Array(slice.length);
//       for (var i = 0; i < slice.length; i++) {
//         byteNumbers[i] = slice.charCodeAt(i);
//       }

//       var byteArray = new Uint8Array(byteNumbers);

//       byteArrays.push(byteArray);
//     }

//     var blob = new Blob(byteArrays, { type: contentType });
//     return blob;
//   }




//   clear = () => { this.canvas.current.clearCanvas(); this.setState({ sign_url: null, up_sign_url: null, issign_empty: true, sign_valid: false }) }
//   undo = () => { this.canvas.current.undo() }
//   redo = () => { this.canvas.current.redo() }

//   deleteSignature(id) {
//     var getIndex = _.findIndex(this.state.endpointData.audit_signature, { id: id })
//     this.state.endpointData.audit_signature.splice(getIndex, 1)

//     urlSocket.post('epadtprcs/updatesignature', {
//       auditInfo: {
//         audit_pbd_id: this.state.endpointData.audit_pbd_id
//       },
//       userInfo: {
//         encrypted_db_url:this.state.db_info.encrypted_db_url,
//         _id: this.state.userData._id,
//         company_id: this.state.userData.company_id
//       },
//       endpointInfo: this.state.endpointData
//     })
//       .then((response) => {
//         if (response.data.response_code === 500) {

//           this.setState({
//             signee: null,
//             issign_empty: true,
//             refresh: true
//           })
//         }
//       })

//   }

//   sign_upload = async (image_info) => {

//     const img = new FormData()
//     let u_id = uuid()

//     img.append('image', image_info, u_id + 'sign.png')
//     console.log(u_id + 'sign.png');

//     await urlSocket.post('storeImage/awswebupload', img)
//       .then((res) => {
//         if (res.status == 200) {
//           console.log(res)
//           let splitString = res.data.data[0].key.split("/");
//           let getFileName = splitString[splitString.length - 1];
//           console.log("getFileName", getFileName)
//           //this.setState({ up_sign_url: String(u_id) + 'sign.png' })
//           // console.log("uploaded _sign", res)
//           this.update_sign(u_id, getFileName)
//         }
//       })
//   }

//   update_sign = (u_id, fileName) => {

//     var signature = {
//       "id": String(u_id),
//       "name": fileName,
//       "sign_by": this.state.signee,
//       "designation": this.state.signeeDesignation,
//       "preview": fileName,
//       "uploading": false,
//       "filetype": "image/png",
//       "uploadingStatus": "Uploaded",
//       "originalName": fileName,
//       //"captured_on": new Date(),
//       "path": fileName,
//       "type": "image/png",
//       "uri": fileName,
//       "audit_user_id": !this.state.isAuditSigned ? this.state.userData._id : "",
//       "review_user_id": "",

//     }

//     this.state.endpointData["audit_signature"] = this.state.endpointData["audit_signature"].concat([signature])


//     // console.log("entity_id", this.props.entity_id)
//     // console.log("signature", signature)
//     urlSocket.post('epadtprcs/updatesignature', {
//       auditInfo: {
//         audit_pbd_id: this.state.endpointData.audit_pbd_id
//       },
//       userInfo: {
//         _id: this.state.userData._id,
//         company_id: this.state.userData.company_id,
//         encrypted_db_url:this.state.db_info.encrypted_db_url,
//       },
//       endpointInfo: this.state.endpointData
//     })
//       .then((response) => {
//         console.log(response,'response')
//         if (response.data.response_code === 500) {

//           this.setState({
//             signee: null,
//             issign_empty: true,
//             refresh: true
//           })
//         }
//       })
//   }

//   del_sign = () => {
//     this.props.del_sign(this.props.entity_id, this.props.current_data)
//   }

//   //-----------------------------------------------------


//   render() {
//     if (this.state.dataloaded) {
//       this.canvas = React.createRef();

//       return (
//         <React.Fragment>
//           <div className="page-content" >
//             <MetaTags>
//               <title>Auditvista | Check points</title>
//             </MetaTags>
//             <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 20, alignItems: 'center' }}>
//               <div style={{ width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
//                 Check points
//               </div>
//               <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
//                 <Link to="/usrenpdts"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
//               </div>
//             </div>
//             <Container fluid>
//               <Card body>
//                 <CardTitle className="h3 mt-0">
//                   {this.state.endpointData.audit_pbd_name}
//                 </CardTitle>
//                 <CardText>
//                   {this.state.endpointData.name}{" / "}{this.state.endpointData.code}
//                 </CardText>

//                 <div>

//                   {/* style={{ border: this.state.filterStatus == "all" ? "1px solid #556ee6" : "1px solid #74788d2e" }} */}
//                   <Link to="#" className="badge badge-soft-dark font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "all" }); this.filterCheckpoints("all") }}>
//                     Total check points{" - "}{this.state.checkpoints.length}
//                   </Link>
//                   <Link to="#" className="badge badge-soft-secondary font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "0" }); this.filterCheckpoints("0") }}>
//                     Not started{" - "}{this.state.ns}
//                   </Link>
//                   <Link to="#" className="badge badge-soft-warning font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "1" }); this.filterCheckpoints("1") }}>
//                     In progress{" - "}{this.state.ip}
//                   </Link>
//                   <Link to="#" className="badge badge-soft-success font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "2" }); this.filterCheckpoints("2") }}>
//                     Completed{" - "}{this.state.cpt}
//                   </Link>
//                   <Link to="#" className="badge badge-soft-primary font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "rwd" }); this.filterCheckpoints("rwd") }}>
//                     Reviewed{" - "}{this.state.rwd}
//                   </Link>
//                 </div>


//               </Card>
//               <div className="d-xl-flex">
//                 <div className="w-100">
//                   <div className="d-md-flex">

//                     {/* 2nd left bar  */}
//                     <Card className="w-50 me-md-2  pb-3 ">
//                       <CardBody style={{ maxHeight: this.state.height - 250 }} className="overflow-auto">
//                         {
//                           this.state.filteredData.map((item, i) => {
//                             var getColor = item.cp_review_status !== null ? "#556ee6" : item.cp_status == '0' ? '#74788d' :
//                               item.cp_status == '1' ? '#f1b44c' :
//                                 item.cp_status == '2' ? '#34c38f' : '#50a5f1'
//                             return (
//                               <div className="card shadow-none mb-2" key={"chkpt" + String(i)}>
//                                 <div style={{
//                                   borderLeft: item.cp_review_status !== null ? "5px solid #556ee6" : item.cp_status === "0" ? "5px solid #74788d" : item.cp_status === "1" ? "5px solid #f1b44c" : item.cp_status === "2" ? "5px solid #34c38f" : "5px solid #50a5f1",
//                                   borderRight: this.state.idx == i ? "1px solid #556ee6" : "1px solid #74788d2e",
//                                   borderTop: this.state.idx == i ? "1px solid #556ee6" : "1px solid #74788d2e",
//                                   borderBottom: this.state.idx == i ? "1px solid #556ee6" : "1px solid #74788d2e",
//                                 }}>
//                                   <Link to="#" className="text-body" onClick={() => { this.loadSelectedCheckpoint(item, i) }}>
//                                     <div className="p-2">
//                                       <div className="d-flex">
//                                         <div className="overflow-hidden me-auto">
//                                           <h5 className="font-size-13  mb-1">
//                                             <span className=''>{item.checkpoint}</span>
//                                           </h5>
//                                           {/* {
//                                             item.impact_level != "" ?
//                                               <span className='badge badge-soft p-1 font-size-10 me-2' style={{ backgroundColor: getColor }}>{item.cp_review_status !== null ? "Reviewed" : item.cp_status === "0" ? "Not started" : item.cp_status === "1" ? "In progress" : item.cp_status === "2" ? "Completed" : "Approved"}</span>
//                                               : null
//                                           } */}
//                                           {
//                                             this.state.endpointData.status === "2" || this.state.endpointData.status === "5" || this.state.endpointData.status === "6" || this.state.endpointData.status === "7" ?
//                                               <span
//                                                 className="badge badge-soft font-size-10"
//                                                 style={{backgroundColor:statusColor[Number(item.cp_status)]}}
//                                               >
//                                                {statusText[Number(item.cp_status)]}</span> : null
//                                           }
//                                         </div>
//                                       </div>
//                                     </div>
//                                   </Link>
//                                 </div>
//                               </div>
//                             )
//                           })
//                         }
//                       </CardBody>
//                     </Card>
//                     {/* 3rd right bar  */}
//                     <Card className="w-100 pb-3 me-md-2">
//                       <CardBody style={{ maxHeight: this.state.height - 250 }} className="overflow-auto">
//                         {

//                           this.state.showOption && this.state.selectedCheckpoint.cp_status !== "4" && this.state.selectedCheckpoint.cp_status !== "5" ?
//                             <div className='d-flex flex-column bg-white' >

//                               <div className="my-1">
//                                 <span
//                                   className={this.state.selectedCheckpoint.cp_review_status !== null ? "badge badge-soft-primary me-2" : this.state.selectedCheckpoint.cp_status === "0" ? "badge badge-soft-secondary me-2" : this.state.selectedCheckpoint.cp_status === "1" ? "badge badge-soft-warning me-2" : this.state.selectedCheckpoint.cp_status === "2" ? "badge badge-soft-success me-2" : "badge badge-soft-info me-2"}
//                                 >{this.state.selectedCheckpoint.cp_review_status !== null ? "Reviewed" : this.state.selectedCheckpoint.cp_status === "0" ? "Not started" : this.state.selectedCheckpoint.cp_status === "1" ? "In progress" : this.state.selectedCheckpoint.cp_status === "2" ? "Completed" : ""}</span>
//                                 {
//                                   this.state.endpointData.status === "4" ?
//                                     <span
//                                       className={this.state.selectedCheckpoint.cp_review_status === "0" ? "badge badge-soft-danger" : "badge badge-soft-success"}
//                                     >
//                                       {this.state.selectedCheckpoint.cp_review_status === "0" ? "Rejected" : "Accepted"}</span> : null
//                                 }
//                               </div>

//                               <CardTitle className="h4">{this.state.selectedCheckpoint.checkpoint}</CardTitle>


//                               <div className='my-2'>
//                                 {
//                                   <OptionComponent
//                                     options={this.state.selectedCheckpoint.checkpoint_options}
//                                     index={this.state.idx}
//                                     checkpoint={this.state.selectedCheckpoint}
//                                     imagePreviewUrl={this.state.imagePreviewUrl}
//                                     imageToken={this.state.imageToken}
//                                     selectionoption={(data, options) => { this.setState({ selected_option: data, optionSelected: true }, function () { this.updateCheckpoint(options, this.state.selectedCheckpoint) }) }}
//                                     //update={(data) => { this.updateCheckpoint(data, this.state.selectedCheckpoint) }}
//                                     updateimages={(data) => { this.updateCheckpointImages(data, this.state.selectedCheckpoint) }}
//                                     updatedocuments={(data) => { this.updateCheckpointDocuments(data, this.state.selectedCheckpoint) }}
//                                     audit_status={this.state.endpointData.status}

//                                   />
//                                 }
//                               </div>
//                               {
//                                 this.state.optionSelected ?
//                                   <>
//                                     <Nav tabs>
//                                       <NavItem>
//                                         <NavLink

//                                           // disabled={
//                                           //   this.state.selectedCheckpoint.cp_status === '0'
//                                           //     ? true
//                                           //     : this.state.selectedCheckpoint.cp_noof_images === 0
//                                           //     ? true
//                                           //     : false
//                                           // }
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "1",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("1")
//                                           }}
//                                         >
//                                           <div>
//                                             Images
//                                           </div>
//                                           {
//                                             this.state.selectedCheckpoint.cp_noof_images !== 0 ?
//                                               <span className={this.state.selected_option.images === undefined
//                                                 ? "badge badge-soft-danger"
//                                                 : this.state.selected_option.images.length >=
//                                                   this.state.selectedCheckpoint.cp_noof_images
//                                                   ? "badge badge-soft-success"
//                                                   : "badge badge-soft-danger"}
//                                               >{this.state.selected_option.images === undefined
//                                                 ? '0 / ' + this.state.selectedCheckpoint.cp_noof_images
//                                                 : this.state.selected_option.images.length +
//                                                 ' / ' +
//                                                 this.state.selectedCheckpoint.cp_noof_images} required </span> : <span>&nbsp;</span>
//                                           }

//                                         </NavLink>
//                                       </NavItem>
//                                       <NavItem>
//                                         <NavLink
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "2",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("2")
//                                           }}
//                                         >
//                                           <div>
//                                             Documents
//                                           </div>
//                                           {
//                                             this.state.selectedCheckpoint.cp_noof_documents !== 0 ?
//                                               <span className={
//                                                 this.state.selected_option.documents_attached.length >=
//                                                   this.state.selectedCheckpoint.cp_noof_documents
//                                                   ? "badge badge-soft-success"
//                                                   : "badge badge-soft-danger"}>
//                                                 {this.state.selected_option.documents_attached === undefined
//                                                   ? '0 / ' + this.state.selectedCheckpoint.cp_noof_documents
//                                                   : this.state.selected_option.documents_attached.length +
//                                                   ' / ' +
//                                                   this.state.selectedCheckpoint.cp_noof_documents} required </span>
//                                               : <span>&nbsp;</span>
//                                           }
//                                         </NavLink>
//                                       </NavItem>
//                                       <NavItem>
//                                         <NavLink
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "3",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("3")
//                                           }}
//                                         >
//                                           <div>
//                                             CAPA
//                                           </div>
//                                           {
//                                             this.state.selected_option.nc_mandatory ? <span
//                                               className={this.getactionplanCount() > 0 ? "badge badge-soft-success" : "badge badge-soft-danger"}
//                                             >Required</span> : <span>&nbsp;</span>
//                                           }
//                                           <span>&nbsp;</span>
//                                         </NavLink>
//                                       </NavItem>
//                                       <NavItem>
//                                         <NavLink
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "4",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("4")
//                                           }}
//                                         >
//                                           <div>
//                                             Observation
//                                           </div>
//                                           <span>&nbsp;</span>
//                                         </NavLink>
//                                       </NavItem>
//                                       <NavItem>
//                                         <NavLink
//                                           style={{ cursor: "pointer" }}
//                                           className={classnames({
//                                             active: this.state.activeTab === "5",
//                                           })}
//                                           onClick={() => {
//                                             this.toggle("5")
//                                           }}
//                                         >
//                                           <div>
//                                             Guideline
//                                           </div>
//                                           <span>&nbsp;</span>
//                                         </NavLink>
//                                       </NavItem>
//                                     </Nav>

//                                     <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
//                                       <TabPane tabId="1">
//                                         <Row>
//                                           <Col sm="12">
//                                             <CRUD_Images
//                                               selected_option={this.state.selected_option}
//                                               selectedCheckpoint={this.state.selectedCheckpoint}
//                                               saveCheckpoint={(data) => this.saveCheckpoint(data)}
//                                               imagePreviewUrl={this.state.imagePreviewUrl}
//                                               audit_status={this.state.endpointData.status}
//                                             />
//                                           </Col>
//                                         </Row>
//                                       </TabPane>
//                                       <TabPane tabId="2">
//                                         <Row>
//                                           <Col sm="12">
//                                             <CRUD_Documents
//                                               selected_option={this.state.selected_option}
//                                               selectedCheckpoint={this.state.selectedCheckpoint}
//                                               saveCheckpoint={(data) => this.saveCheckpoint(data)}
//                                               imagePreviewUrl={this.state.imagePreviewUrl}
//                                               audit_status={this.state.endpointData.status}
//                                             />
//                                           </Col>
//                                         </Row>
//                                       </TabPane>
//                                       <TabPane tabId="3">
//                                         <Row>
//                                           <Col sm="12">
//                                             {
//                                               this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
//                                                 <div className="mb-2">
//                                                   <Button className="btn-sm" color="primary" onClick={() => { this.addCAPA(this.state.selectedCheckpoint) }} >Add Action</Button>
//                                                 </div> : null
//                                             }

//                                             {
//                                               this.state.selectedCheckpoint.cp_actionplans.map((apln, idx) => {
//                                                 return (
//                                                   <CAPA
//                                                     key={"capa" + String(idx)}
//                                                     index={idx}
//                                                     data={apln}
//                                                     onsave={(data) => { this.addapln(this.state.selectedCheckpoint, data, idx) }}
//                                                     onadd={() => { this.addCAPA(this.state.selectedCheckpoint) }}
//                                                     saveCheckpoint={(data) => this.saveCheckpoint(data)}
//                                                     selectedCheckpoint={this.state.selectedCheckpoint}
//                                                     audit_status={this.state.endpointData.status}
//                                                   />
//                                                 )
//                                               })
//                                             }
//                                           </Col>

//                                         </Row>
//                                       </TabPane>
//                                       <TabPane tabId="4">
//                                         <Row>
//                                           <Col sm="12">
//                                             <Observation
//                                               selectedCheckpoint={this.state.selectedCheckpoint}
//                                               saveCheckpoint={(data) => this.saveCheckpoint(data)}
//                                               audit_status={this.state.endpointData.status}
//                                             />
//                                           </Col>
//                                         </Row>
//                                       </TabPane>
//                                       <TabPane tabId="5">
//                                         <Row>
//                                           <Col sm="12">
//                                             <label>Guideline</label>
//                                             <div>
//                                               {
//                                                 this.state.selectedCheckpoint.guideline
//                                               }
//                                               <Row>
//                                                 {
//                                                   this.state.selectedCheckpoint.guideline_image.map(item => {
//                                                     return (<div className="m-2">
//                                                       <img src={item.preview} className="img-fluid" />
//                                                     </div>)
//                                                   })
//                                                 }
//                                               </Row>
//                                             </div>
//                                           </Col>
//                                         </Row>
//                                       </TabPane>
//                                     </TabContent>
//                                   </> : null
//                               }


//                               {/* <div className="row my-3 px-3">
//                               <Button color="dark" onClick={()=>this.submitCheckpoint()}>Submit</Button>
//                             </div> */}

//                             </div> :
//                             this.state.showOption ? 
//                             <div className='d-flex flex-column bg-white' >
//                               <div className="mb-2">
//                                     <label className={this.state.selectedCheckpoint.cp_review_status !== null ? "badge badge-soft-primary font-size-10 me-2" : this.state.selectedCheckpoint.cp_status === "0" ? "badge badge-soft-secondary font-size-10 me-2" : this.state.selectedCheckpoint.cp_status === "1" ? "badge badge-soft-warning font-size-10 me-2" : "badge badge-soft-success font-size-10 me-2"}>{this.state.selectedCheckpoint.cp_review_status !== null ? "Reviewed" : this.state.selectedCheckpoint.cp_status === "0" ? "Not started" : this.state.selectedCheckpoint.cp_status === "1" ? "In progress" : "Completed"}</label>
//                                     <label className={this.state.selectedCheckpoint.cp_is_compliance ? "badge badge-soft-danger font-size-10" : "badge badge-soft-success font-size-10"}>{this.state.selectedCheckpoint.cp_is_compliance ? "Compliance" : "Non-Compliance"}</label>
//                                   </div>
//                                   <div className="mb-1">
//                                     <span className="font-size-13">{this.state.selectedCheckpoint.checkpoint}</span>
//                                   </div>

//                                   {
//                                     this.state.selectedCheckpoint.checkpoint_type_id === "1" || this.state.selectedCheckpoint.checkpoint_type_id === "2" ?
//                                       <ReviewOPType
//                                         options={this.state.selectedCheckpoint.checkpoint_options}

//                                       />
//                                       : null
//                                   }

//                                   {
//                                     this.state.selectedCheckpoint.cp_attach_images.length !== 0 ?
//                                       <div className="mt-3 mb-4">
//                                         <label>Images Attached</label>
//                                         <PreviewImage
//                                           imagePreviewUrl={this.state.imagePreviewUrl}
//                                           images={this.state.selectedCheckpoint.cp_attach_images}
//                                         />
//                                       </div> : null
//                                   }

//                                   {
//                                     this.state.selectedCheckpoint.cp_documents.length !== 0 ?
//                                       <div className="my-4">
//                                         <label>Documents Attached</label>
//                                         <PreviewDocuments
//                                           imagePreviewUrl={this.state.imagePreviewUrl}
//                                           images={this.state.selectedCheckpoint.cp_documents}
//                                         />
//                                       </div> : null

//                                   }

//                                   {
//                                     this.state.selectedCheckpoint.cp_observation !== null ?
//                                       <div className="my-4">
//                                         <label>Observation</label>
//                                         <PreviewObservation
//                                           observation={this.state.selectedCheckpoint.cp_observation}
//                                         />
//                                       </div> : null
//                                   }

//                                   {
//                                     this.state.selectedCheckpoint.cp_actionplans.length !== 0 ?
//                                       <div className="my-4">
//                                         <label>CAPA</label>
//                                         <PreviewCAPA
//                                           actionplans={this.state.selectedCheckpoint.cp_actionplans}
//                                         />
//                                       </div> : null
//                                   }
//                             </div> :
//                               <div className='d-flex p-3 bg-white justify-content-center align-item-center' >
//                                 <p>Select check point to audit</p>
//                               </div>
//                         }
//                       </CardBody>
//                     </Card>
//                     {/* 1st left bar  */}
//                     <Card className="filemanager-sidebar ">
//                       <CardBody style={{ height: this.state.height - 250 }} className="overflow-auto">

//                         {
//                           this.state.endpointData.audit_signature.length !== 0 ?
//                             <div>
//                               {
//                                 this.state.endpointData.audit_signature.map((item, i) => {
//                                   return (
//                                     <Card
//                                       className="mt-1 mb-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
//                                       key={i + "-signfile"}
//                                     >
//                                       <div style={{ display: 'flex', flexDirection: 'row' }} >
//                                         <div className="p-2 col-10" >
//                                           <label className="mb-1 font-size-11">{item.sign_by}<br/>{item.designation}</label> 
//                                           <img height="80" src={this.state.imagePreviewUrl + item.name} />
//                                         </div>
//                                         {
//                                           this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
//                                             <div className="col-2 text-end me-2" style={{lineHeight:1.3}}>
//                                               <Link to="#" onClick={() => this.deleteSignature(item.id)}><i className="mdi mdi-close-circle-outline font-size-20 text-danger" /></Link>
//                                             </div> : null
//                                         }

//                                       </div>
//                                     </Card>

//                                   )
//                                 })
//                               }
//                             </div> : null
//                         }
//                         {
//                           this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
//                             <div className="my-2">
//                               <Col className="mb-2">
//                                 <div className="p-3">
//                                   <p>Signature</p>
//                                   <Button
//                                     color="primary"
//                                     onClick={() => {this.checkAuditeeSigned()}}
//                                     id="custom-padding-width-alert"
//                                   >
//                                     Add signature
//                                   </Button>
//                                 </div>


//                               </Col>
//                             </div> : null
//                         }


//                         {
//                           this.state.endpointData.status !== "3" && this.state.endpointData.status !== "4" ?
//                             <div className="my-2">
//                               <Col className="mb-2">
//                                 <div className="p-3">
//                                   <p>Submit Audit</p>
//                                   <Button
//                                     color="success"
//                                     onClick={() => this.confirmSubmit()}
//                                     id="custom-padding-width-alert"
//                                   >
//                                     Submit and close audit
//                                   </Button>
//                                 </div>
//                               </Col>
//                             </div> :
//                             <div>
//                               <label>Audit Started on</label>
//                               <p>{moment(this.state.endpointData.audit_started_on).format("DD/MM/YYYY")}</p>
//                               <label>Audit Completed on</label>
//                               <p>{moment(this.state.endpointData.audit_completed_on).format("DD/MM/YYYY")}</p>
//                               <label>Audit Submitted on</label>
//                               <p>{moment(this.state.endpointData.audit_submitted_on).format("DD/MM/YYYY")}</p>
//                             </div>
//                         }


//                       </CardBody>
//                     </Card>
//                   </div>
//                 </div>
//               </div>

//               <Modal
//                 isOpen={this.state.modal}
//                 className={this.props.className}
//               >
//                 <ModalHeader toggle={this.modaltoggle} tag="h4">
//                   Signature
//                 </ModalHeader>
//                 <ModalBody>
//                   <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
//                     <div className="mb-3" >
//                       <AvField
//                         name="signee"
//                         value={this.state.userData.firstname }
//                         onChange={(e) => { this.setState({ signee: e.target.value }) }}
//                         className="form-control"
//                         placeholder="Enter signee name"
//                         type="input"
//                         disabled = {this.state.isDisabled}
//                         required
//                       />
//                     </div>
//                     <div className="mb-3" >
//                       <AvField
//                         name="designation"
//                         value={this.state.userData.designation}
//                         onChange={(e) => { this.setState({ signeeDesignation: e.target.value }) }}
//                         className="form-control"
//                         placeholder="Enter designation"
//                         type="input"
//                         disabled = {this.state.userData.designation=== ""? false :true}
//                         required
//                       />
//                     </div>


//                     <ReactSketchCanvas
//                       ref={this.canvas}
//                       style={styles}
//                       width="600"
//                       height="400"
//                       strokeWidth={4}
//                       strokeColor="red"
//                       onChange={() => this.setState({ issign_empty: false, sign_valid: false })}
//                     />
//                     {
//                       this.state.sign_valid ? <div className="m-2"><label className="text-danger">{this.state.signboard_message}</label></div> : null
//                     }

//                     <Button className="my-2 me-2" color="primary" onClick={this.submit}>Submit</Button>
//                     <Button className="my-2 me-2" color="warning" onClick={this.clear}>Clear</Button>
//                     <Button className="my-2" color="danger" onClick={() => this.setState({ modal: false })}>Cancel</Button>
//                   </AvForm>
//                 </ModalBody>
//               </Modal>

//               {this.state.showWarning ? (
//                 <SweetAlert
//                   title="Message"
//                   warning
//                   onConfirm={() => this.setState({ showWarning: false })}
//                 >
//                   {this.state.submitMessage}
//                 </SweetAlert>
//               ) : null}

//               {this.state.confirm_both ? (
//                 <SweetAlert
//                   title="Are you sure?"
//                   warning
//                   showCancel
//                   confirmBtnBsStyle="success"
//                   cancelBtnBsStyle="danger"
//                   onConfirm={() =>
//                     this.setState({
//                       confirm_both: false,
//                       success_dlg: true,
//                       dynamic_title: "Deleted",
//                       dynamic_description: "Your file has been deleted.",
//                     },
//                       function () {
//                         this.submitAudit()
//                       }
//                     )
//                   }
//                   onCancel={() =>
//                     this.setState({
//                       confirm_both: false,
//                       error_dlg: true,
//                       dynamic_title: "Cancelled",
//                       dynamic_description:
//                         "Your imaginary file is safe :)",
//                     })
//                   }
//                 >
//                   You won't be able to revert this!
//                 </SweetAlert>
//               ) : null}



//             </Container>
//           </div>
//         </React.Fragment>
//       )
//     }
//     else {
//       return null
//     }
//   }

// }