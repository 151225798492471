import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const PartiallyCompliantMultiStackedBarChart = ({
  complaint_critical_count,
  complaint_high_count,
  complaint_low_count,
  complaint_medium_count,
  complaint_no_impact_count,

  
  critical_type_info,
  high_type_info,
  low_type_info,
  medium_type_info,
  no_impact_type_info
}) => {
  const chartRef = useRef();
  // const margin = { top: 20, right: 100, bottom: 50, left: 100 }; // Adjusted right margin for the legend
  const margin = { top: 20, right: 200, bottom: 50, left: 100 }; // Adjusted right margin for the legend

  const width = 1000 - margin.left - margin.right;
  const height = 400 - margin.top - margin.bottom;


  useEffect(() => {
    const svg = d3.select(chartRef.current);
    const getOrderedKeys = (dataArray) => {
      const keysSet = new Set();
      dataArray.forEach(categoryData => {
        Object.keys(categoryData).forEach(key => {
          // Exclude 'category' and 'count' keys
          if (key !== 'category' && key !== 'count') {
            keysSet.add(key);
          }
        });
      });

      // Convert the Set to an array and order it based on desiredOrder
      const orderedKeys = Array.from(keysSet).sort((a, b) => {
        return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
      });

      return orderedKeys;
    };

    const updateData = (dataArray, critical_type_info, high_type_info, low_type_info, medium_type_info, no_impact_type_info) => {
      const updateCategoryData = (categoryData, typeArray) => {
        for (let j = 0; j < typeArray.length; j++) {
          const complaintType = typeArray[j];
          const typeName = complaintType.type_name.toLowerCase();
          categoryData[typeName] = complaintType.count;
        }
      };

      for (let i = 0; i < dataArray.length; i++) {
        const categoryData = dataArray[i];

        switch (categoryData.category) {
          case 'Critical':
            updateCategoryData(categoryData, critical_type_info);
            break;
          case 'High':
            updateCategoryData(categoryData, high_type_info);
            break;
          case 'Medium':
            updateCategoryData(categoryData, medium_type_info);
            break;
          case 'Low':
            updateCategoryData(categoryData, low_type_info);
            break;
          case 'No Impact':
            updateCategoryData(categoryData, no_impact_type_info);
            break;
          // Add more cases if needed for other categories
          default:
            break;
        }
      }
    };

    const getRandomColor = () => {
      const component = () => Math.floor(Math.random() * 100 + 150); // Adjust the range for lighter colors
      return `rgb(${component()}, ${component()}, ${component()})`;
    };




    const data = [
      {
        category: 'Critical',
        count: complaint_critical_count,

      },
      {
        category: 'High',
        count: complaint_high_count,

      },
      {
        category: 'Medium',
        count: complaint_medium_count,

      },
      {
        category: 'Low',
        count: complaint_low_count,

      },
      {
        category: 'No Impact',
        count: complaint_no_impact_count,

      },
    ];
    updateData(data, critical_type_info, high_type_info, low_type_info, medium_type_info, no_impact_type_info);


    const totalByCategory = data.map(d => d.count);
    const domain = Array.from(new Set(data.flatMap(d => Object.keys(d).filter(key => key !== 'category' && key !== 'count'))));



    const yScale = d3
      .scaleBand()
      .domain(data.map(d => d.category))
      .range([margin.top, height + margin.top]) // Adjusted top margin for the legend
      .padding(0.1);


    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(totalByCategory)])
      .range([margin.left, width + margin.left]);

    const colorScale = d3
      .scaleOrdinal()
      .domain(domain)
      .range(domain.map(() => getRandomColor()));
    const desiredOrder = domain.map(item => item.charAt(0).toUpperCase() + item.slice(1));
    const stackedData = d3.stack().keys(getOrderedKeys(data))(data);


    svg.selectAll('.bar-group')
      .data(stackedData)
      .enter().append('g')
      .attr('class', 'bar-group')
      .attr('fill', d => colorScale(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
      .attr('y', d => yScale(d.data.category))
      .attr('x', d => xScale(d[0]))
      .attr('width', d => xScale(d[1]) - xScale(d[0]))
      .attr('height', yScale.bandwidth())
      .on('mouseover', function (event, d) {
        const [x, y] = d3.pointer(event);
        d3.select(this).style('opacity', 0.7);
        showTooltip(d.key, d.data[d.key], x + 10, y - 10);
      })
      .on('mouseout', function () {
        d3.select(this).style('opacity', 1);
        // hideTooltip();
      });

    svg.selectAll('.bar-label')
      .data(data)
      .enter().append('text')
      .attr('class', 'bar-label')
      .attr('x', d => xScale(d.count) + 5) // Adjusted position for x-axis labels
      .attr('y', d => yScale(d.category) + yScale.bandwidth() / 2)
      .style('text-anchor', 'start') // Adjusted text-anchor for x-axis labels
      .style('font-size', '12px')
      .text(d => d.count > 0 ? d.count : 'No Data Available');

    svg.selectAll('.bar-label-group')
      .data(stackedData)
      .enter().append('g')
      .attr('class', 'bar-label-group')
      .selectAll('.bar-label')
      .data(d => d)
      .enter().append('text')
      .attr('class', 'bar-label')
      .attr('x', d => (xScale(d[0]) + xScale(d[1])) / 2) // Adjusted position for middle of the stack bar
      .attr('y', d => yScale(d.data.category) + yScale.bandwidth() / 2)
      .style('text-anchor', 'middle')
      .style('font-size', '8px')
      .text(function (d) {
        const key = d3.select(this.parentNode).datum().key;
        const formattedKey = key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        const value = d.data[key];
        if (value !== 0 && value !== undefined) {
          return `${formattedKey}: ${value}`;
        }
      });

    svg.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale));

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height + margin.top})`)
      .call(d3.axisBottom(xScale));

    // Add legend
    const legend = svg.append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${width + margin.left + 30}, ${margin.top})`);

    // const legendItems = ['No Impact', 'Low', 'Medium', 'High', 'Critical'];
    const legendItems = domain.map(item => item.charAt(0).toUpperCase() + item.slice(1)).reverse();;

    legend.selectAll('.legend-item')
      .data(legendItems)
      .enter().append('g')
      .attr('class', 'legend-item')
      .attr('transform', (d, i) => `translate(0,${i * 20})`)
      .call(g => {
        g.append('rect')
          .attr('width', 18)
          .attr('height', 18)
          .attr('fill', d => colorScale(d));

        g.append('text')
          .attr('x', 24)
          .attr('y', 9)
          .attr('dy', '0.35em')
          .style('font-size', '12px')
          .text(d => d);
      });

    const tooltip = svg.append('g')
      .attr('class', 'tooltip')
      .style('display', 'none');

    tooltip.append('rect')
      .attr('width', 120)
      .attr('height', 50)
      .attr('fill', 'white')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .style('opacity', 0.8);

    tooltip.append('text')
      .attr('x', 10)
      .attr('y', 20)
      .style('font-size', '12px')
      .style('font-weight', 'bold');

    function showTooltip(label, value, x, y) {
      tooltip.style('display', 'block');
      tooltip.attr('transform', `translate(${x},${y})`);
      tooltip.select('text').html(`${label}: ${value}`);
      tooltip.select('rect')
        .attr('width', 120)
        .attr('height', 50)
        .attr('fill', 'white')
        .attr('stroke', 'black')
        .attr('stroke-width', 1)
        .style('opacity', 0.8);
    }

  }, [
    complaint_critical_count,
    complaint_high_count,
    complaint_low_count,
    critical_type_info,
    high_type_info,
    low_type_info,
    medium_type_info,
    no_impact_type_info
  ]);

  return (
    <svg ref={chartRef} width={width + margin.left + margin.right} height={height + margin.top + margin.bottom}></svg>
  );
};

export default PartiallyCompliantMultiStackedBarChart;