// import React, { Component } from "react"
// import { Bar } from "react-chartjs-2"
import React, { Component } from "react"
import { Pie } from "react-chartjs-2"

const _ = require('lodash')

// var urlSocket = require("../../../helpers/urlSocket")
import urlSocket from "../../../../helpers/urlSocket"
var moment = require('moment')

// class BarChartIL extends Component {

//   constructor(props) {
//     super(props)

//     this.state = {
//      dataLoaded:false
//     }
//     this.getCheckpointReportData()
//   }

//   getCheckpointReportData() {

//     var userInfo = this.props.userInfo

//     try {
//       urlSocket.post("webpbdadtdta/getCNCreport", {
//         userInfo: {
//           _id: userInfo._id,
//           user_code: userInfo.user_code,
//           company_id: userInfo.company_id
//         },
//         auditInfo: this.props.auditInfo
//       })
//         .then(response => {
//           if (response.data.response_code === 500) {
//             var totalEnties = _.sumBy(response.data.data, "total")
//             var critical = _.sum(_.map(response.data.data, "critical"))
//             var high = _.sum(_.map(response.data.data, "high"))
//             var medium = _.sum(_.map(response.data.data, "medium"))
//             var low = _.sum(_.map(response.data.data, "low"))
//             var no_impact = _.sum(_.map(response.data.data, "no_impact"))

//             this.setState({
//               totalEnties,critical, high, medium, low, no_impact, dataLoaded:true
//             })
//           }
//         })
//       }
//       catch(error){
//       }
//     }

//   render() {
//     if(this.state.dataLoaded)
//     {
//     const data = {
//       labels: ["Total Endpoints","Critical", "High", "Medium", "Low", "No impact"],
//       datasets: [
//         {
//           label: this.props.auditInfo.audit_pbd_name,
//           backgroundColor: ["#343a40","#f46a6a","#f1b44c", "#50a5f1", "#34c38f", "#556ee6"],
//           borderColor: ["#343a40","#f46a6a","#f1b44c", "#50a5f1", "#34c38f", "#556ee6"],
//           borderWidth: 1,
//           hoverBackgroundColor: ["#343a40","#f46a6a","#f1b44c", "#50a5f1", "#34c38f", "#556ee6"],
//           hoverBorderColor: ["#343a40","#f46a6a","#f1b44c", "#50a5f1", "#34c38f", "#556ee6"],
//           data: [this.state.totalEnties,this.state.critical, this.state.high, this.state.medium, this.state.low, this.state.no_impact],
//         },
//       ],
//     }

//     const option = {
//       scales: {
//         dataset: [
//           {
//             barPercentage: 0.4,
//           },
//         ],
//       },
//     }

//     return (
//       <React.Fragment>
//         <Bar width={474} height={270} data={data} options={option} />
//       </React.Fragment>
//     )
//     }
//     else
//     {
//       return null
//     }
//   }
// }

// export default BarChartIL



class BarChartIL extends Component {

  constructor(props) {
    super(props)

    this.state = {
     dataLoaded:false
    }
    this.getCheckpointReportData()
  }

  getCheckpointReportData() {

    var userInfo = this.props.userInfo

    try {
      urlSocket.post("webpbdadtdta/getCNCreport", {
        userInfo: {
          _id: userInfo._id,
          user_code: userInfo.user_code,
          company_id: userInfo.company_id,
        },
        encrypted_db_url : this.props.encrypted_db_url,
        auditInfo: this.props.auditInfo
      })
        .then(response => {
          if (response.data.response_code === 500) {
            // var totalEnties = _.sumBy(response.data.data, "total")
            var totalEnties = response.data.data.length
            totalEnties = (totalEnties*this.props.total_locations)
            var critical = _.sum(_.map(response.data.data, "critical"))
            var high = _.sum(_.map(response.data.data, "high"))
            var medium = _.sum(_.map(response.data.data, "medium"))
            var low = _.sum(_.map(response.data.data, "low"))
            var no_impact = _.sum(_.map(response.data.data, "no_impact"))

            this.setState({
              totalEnties,critical, high, medium, low, no_impact, dataLoaded:true
            })
          }
        })
      }
      catch(error){
        console.log("catch error", error)
      }
    }

  render() {
    if(this.state.dataLoaded)
    {
    const data = {
      labels: ["Total checkpoints","Critical", "High", "Medium", "Low", "No impact"],
      datasets: [
        {
          data: [this.state.totalEnties,this.state.critical, this.state.high, this.state.medium, this.state.low, this.state.no_impact],
          backgroundColor: ["#343a40","#f46a6a","#f1b44c", "#50a5f1", "#34c38f", "#556ee6"],
          hoverBackgroundColor: ["#343a40","#f46a6a","#f1b44c", "#50a5f1", "#34c38f", "#556ee6"],
          hoverBorderColor: "#fff",
        },
      ],
    }

    return (
      <React.Fragment>
        <Pie width={474} height={260} data={data} />
      </React.Fragment>
    )
    }
    else{
      return null
    }
  }
  
}

export default BarChartIL
