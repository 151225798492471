import React, { Component } from "react";
import { Row, Col, Button, Form, FormGroup } from "reactstrap";
import Dropzone from "react-dropzone";
import { Image } from 'antd';
import { Popconfirm } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import uuid from 'react-uuid'
import imageCompression from 'browser-image-compression'

import '../manageAudit.css'
let imageFiles = []

export default class Guideline extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ImageFiles: [],
            img_max_mb: 0,
        }
    }

    componentDidMount() {
        this.getSessionData()
    }

    getSessionData() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        let img_max_mb = data.config_data.img_max_mb
        this.setState({ img_max_mb })
    }

    onChangeRemarks = (e) => {
        let value = e.target.checked ? "1" : "0"
        this.props.parameterData.enable_remarks = value
        this.setState({ refresh: true })
    }

    isChecked = (data) => {
        if (data == "") {
            return false
        }
        else if (data == "1") {
            return true
        }
        else {
            return false
        }
    }

    uploadImages = async (files) => {

        if (files.length != 0) {
            files.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: this.formatBytes(file.size)
                })
            )
            for (let i = 0; i < files.length; i++) {
                imageFiles.push(files[i])
                this.setState({ ImageFiles: imageFiles, selectedImgCount: imageFiles.length });
            }
            await this.azureFileUpload(this.state.ImageFiles)

        } else {
            alert("Invalid format")
        }
    }

    fileUpload = async (image_info) => {
        let uploadedImgInfo = []
        if (image_info.length == 0) {
            this.setState({
                alert_view1: true,
                alert_title: "Alert",
                modal_body: "Please Upload Image"
            })
        }
        else {
            for (let i = 0; i < image_info.length; i++) {
                const img = new FormData()
                let u_id = uuid()
                const options = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true }
                image_info[i] = await imageCompression(image_info[i], options);

                img.append('image', image_info[i], u_id + '-guide.jpg')
                img.append('path', u_id + '-guide.jpg')
                uploadedImgInfo.push(u_id + '-guide.jpg')

            }
            this.props.parameterData.guideline_image = uploadedImgInfo
            this.setState({ refresh: true })
        }
    }

    azureFileUpload = async (info) => {
        // const img = new FormData()
        // img.append('photo', info.imageData[0][0])
        // urlSocket.post('storeImage/savedata', img)
        //     .then(response => {
        //     })
        //     .catch(error => {
        //     })
        await this.fileUpload(this.state.ImageFiles)

    }

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }



    deleteImage = (ImageFiles, index) => {
        ImageFiles = [...ImageFiles]
        ImageFiles.splice(index, 1)
        this.setState({ ImageFiles })
    }


    render() {
        return (
            <div className="blueDiv">
                <label >Guideline</label>
                <textarea className="description" name="Text1" rows="5" style={{ padding: 10 }} onChange={(e) => this.props.parameterData.guideline = e.target.value} defaultValue={this.props.parameterData.guideline}></textarea>
                <div className="mt-2">
                    <label >Guideline Images ( Max {this.state.img_max_mb} MB)</label>
                </div>
                <Form>
                    <Dropzone accept="image/*" onDrop={acceptedFiles => this.uploadImages(acceptedFiles)} >
                        {
                            ({ getRootProps, getInputProps }) => (
                                <div className="dropzone" style={{ height: 200, position: "relative" }}>
                                    <div style={{ height: "100%" }} className="dz-message needsclick" {...getRootProps()} >
                                        <input {...getInputProps()} />
                                        <h3> Drag and drop to upload Images</h3>
                                    </div>
                                </div>
                            )
                        }
                    </Dropzone>
                    <hr />

                    <Row className="mt-3">
                        <Col md={2} className="align-self-center text-center">
                            <div>
                                <div>
                                    <label >Enable Remarks </label>
                                </div>
                                <div style={{ padding: '8px 2px' }}>
                                    <div className="square-switch" style={{ marginTop: 7 }}>
                                        <input type="checkbox" id={this.props._id + "gdd"} data-switch="info" onChange={(e) => { this.onChangeRemarks(e) }} checked={this.isChecked(this.props.parameterData.enable_remarks)} />
                                        <label htmlFor={this.props._id + "gdd"} data-on-label="Yes" data-off-label="No"  ></label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={10} >
                            {
                                this.state.selectedImgCount > 0 &&
                                <>
                                    <label className="title_blue">Uploaded Images</label>
                                    <Row >
                                        {
                                            this.state.ImageFiles.map((data, index) => (
                                                <Col key={index} className="imgDiv">
                                                    <Image style={{ height: 80, width: 'auto' }} className="rounded me-2 imageSize" alt={data.name} src={data.preview} />
                                                    <div className="align-self-center">
                                                        <Popconfirm placement="leftBottom" title={`Are You sure to delete  ?`} onConfirm={() => { this.deleteImage(this.state.ImageFiles, index) }} >
                                                            <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
                                                            <label className="text-danger">Delete</label>
                                                        </Popconfirm>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </>
                            }
                        </Col>
                    </Row>
                    <hr />

                </Form>
                <Row style={{ margin: "20px 0" }}>
                    <FormGroup>
                        <Button color="primary" onClick={this.props.ValidateAndSend}>Save</Button>
                        <Button color="danger" onClick={this.deleteData} style={{ margin: "0 10px" }} >Delete</Button>
                    </FormGroup>
                </Row>
            </div>
        )
    }
}
