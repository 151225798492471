import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
// #region constants
import { CardBody, Container, Button,  Col,  PaginationItem,   PaginationLink,  Row, Card } from "reactstrap"

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
class PreviewAuditScore extends React.Component {
constructor(props) {
    super(props);

    this.state = {
    };
}

    render() {
        return (
        <div>


            <MetaTags>
                <title>Preview Audit Score | Audit Vista</title>
            </MetaTags>


            <Container fluid style={{ maxHeight: '100vh' }}>
                <Card >
                    <CardBody style={{ overflow: '' }}>
                        <Row >
                            <Col md={12}>
                                <div className="thank-you">
                                    <h6>Audit Score Page</h6>
                                    {/* <img src={auditvista_logo} /> */}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>

        </div>
        )
    }
}

PreviewAuditScore.propTypes = propTypes;
PreviewAuditScore.defaultProps = defaultProps;
// #endregion

export default PreviewAuditScore;










// import React from 'react';
// import { useEffect } from 'react';
// // import styled from 'styled-components';
// // import PropTypes from 'prop-types';
// import MetaTags from 'react-meta-tags';
// // #region constants
// import { CardBody, Container, Button,  Col,  PaginationItem,   PaginationLink,  Row, Card } from "reactstrap"

// // #endregion

// // #region styled-components

// // #endregion

// // #region functions

// // #endregion

// // #region component
// const propTypes = {};

// const defaultProps = {};

// /**
//  * 
//  * 
//  */

// const PreviewAuditScore = () => {

 
//     useEffect(() => {
//         console.log('audit score pahe')
//     }, [])

//     return( 
//          <div>
//             {
//                 console.log("lllllll")
//             }
//             <MetaTags>
//                 <title>Preview Audit Score | Audit Vista</title>
//             </MetaTags>


//             <Container fluid style={{ maxHeight: '100vh' }}>
//             <Card >
//                     <CardBody style={{overflow: ''}}>
//                     <Row >
//                             <Col md={12}>
//                             <div className="thank-you">
//                                 <h6>Audit Score Page</h6>
//                                     {/* <img src={auditvista_logo} /> */}
//                                 </div>
//                             </Col>
//                         </Row>
//                         </CardBody>
//                         </Card>
//                 </Container>
//                 </div>
//                 )
// }

// // preview_audit_score.propTypes = propTypes;
// // preview_audit_score.defaultProps = defaultProps;
// // #endregion

// export default PreviewAuditScore;