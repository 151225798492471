import React, { Component } from "react";
import { Container, Row, Col, 
  Button, Card, CardBody, 
  Modal, ModalHeader, ModalBody, Dropdown, 
  DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown,
  NavItem, NavLink, UncontrolledTooltip, Badge, Input
} from "reactstrap";
import { withRouter, Link } from "react-router-dom"
import {useAsyncDebounce} from "react-table"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import facility from '../FacilityScreen'
import "react-perfect-scrollbar/dist/css/styles.css";
import "./manageAudit.css";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { map } from "lodash"
import { LoadingOutlined, DeleteTwoTone, } from '@ant-design/icons'
import CardContact from "./card-contact"
import MetaTags from 'react-meta-tags';
import TableContainer from "./Components/TemplateTableContainer";
import urlSocket from "../../../helpers/urlSocket";
import tmplIcon from '../../../assets/images/template/tmpl-icon-1.png';

const _ = require('lodash');





class ManageAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      department_id: null,
      department_Name: null,
      Year_Name: null,
      depName: '',
      company_id: null,
      departmentArray: [],
      user_id: null,
      auditData: [],
      error_data: [],
      yearData: [],
      dataloaded: false,
      modal: false,
      isEdit: false,
      chnage_view: false,
      audit_name_exist: false,
      btn_load: false,
      temp_auditData :[],

      search_files: [],
      dup_search_files: [],
      temp_search_files: [],
      currentPage: 1,
      totalItems: 0,
      items_per_page: 8,

      gridView:"block",

    }
    this.handleUserClicks = this.handleUserClicks.bind(this)
    this.handleValidAuditSubmit = this.handleValidAuditSubmit.bind(this)
  }

  configData
  userData
  async componentDidMount() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"));

    this.configData = data.config_data
    this.userData = data.user_data
    var protect_routes = await facility(user_facilities, 'mngmtrtmplt')
    if (protect_routes !== undefined) {
      this.setState({
        userInfo: data.user_data,
        config: data.config_data,
        db_info: db_info,
        protect_routes: protect_routes
      }, function () {
        this.getAuditDefaultTemplates()
      })
    }
    else {
      this.props.history.push('/dashboard')
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      convertionMode: "0"
    }))
    this.setState({
      audit_name_exist: false,
    });
  }

  handleUserClicks = arg => {
    this.setState({ users: '', isEdit: false, convertionMode: "0", audit_name_exist: false }, () => {
      this.setState(prevState => ({
        modal: !prevState.modal,
        audit_name_exist: false,
      }))
    })
    // this.toggle()
  }

  useTemplateHandlerClick(data) {
    this.setState({ users: '', isEdit: false, convertionMode: "2", convertionTemplateData: data }, () => {
      this.setState(prevState => ({
        modal: !prevState.modal,
        audit_name_exist: false,
      }))
    })
    // this.toggle()
  }

  useThisTemplateAs(data) {

  }

  getAuditDefaultTemplates() {
    try {
      urlSocket.post('/webmngtmplt/gettemplates', {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.state.userInfo.company_id,
          user_id: this.state.userInfo._id,
        },
      })
        .then((response) => {
          console.log(response,'response')
          if (response.data.response_code === 500) {
            this.setState({
              templatesData: response.data.data,
              //dataloaded: true,
            },
              function () {
                this.getAuditMasterTemplates()
                this.setState({ dataloaded: true })
              }
            )
          }
          else {
            this.setState({ dataloaded: true })

          };
        });
    } catch (error) {
      console.log(error.toString());
    }
  }


  getAuditMasterTemplates() {
    try {
      urlSocket.post('/webmngtmplt/getusertemplatemaster', {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.state.userInfo.company_id,
          user_id: this.state.userInfo._id,
        },
      })
        .then((response) => {
          console.log(response,'response')
          // this.setState({
          //   auditData: response.data.data,
          //   dataloaded: true,
          // });
          this.setState({
            auditData: response.data.data,
            temp_auditData:response.data.data,
            dataloaded: true,

            search_files: response.data.data,
            dup_search_files: response.data.data,
            temp_search_files: response.data.data
          }, () => { 
            // this.pageClick(1)
           })
        });
    } catch (error) {
      console.log(error.toString());
    }
  }

  handleDeleteTemplate = (values) => {
    try {
      urlSocket.post("webmngtmplt/deletetemplate", {
        templateInfo: {
          template_id: values._id
        },
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.state.userInfo.company_id,
          created_by: this.state.userInfo._id,
        },
      })
        .then(response => {
          if (response.data.response_code == 500) {
            this.setState({
              auditData: response.data.data,
              temp_auditData :response.data.data,
              search_files: response.data.data,
              dup_search_files: response.data.data,
              temp_search_files: response.data.data,
              dataloaded: true,
              confirm_both: false,
              success_dlg: true,
              dynamic_title: "Deleted",
              dynamic_description: "Template has been deleted.",
            })


            // this.setState({
            //   auditData: response.data.data,
            //   dataloaded: true,
            //   confirm_both: false,
            //   success_dlg: true,
            //   dynamic_title: "Deleted",
            //   dynamic_description: "Template has been deleted.",
            // })
          }
        })
    } catch (error) {
      console.log("catch error", error)
    }
  }

  dateConvertion = (dateToConvert) => {
    if (dateToConvert != null) {
      var date = typeof (dateToConvert) == "object" ? String(dateToConvert.toISOString()) : String(dateToConvert)
      var convertedDate = date.slice(8, 10) + ' / ' + (date.slice(5, 7)) + ' / ' + date.slice(0, 4);//prints expected format. 
      if (convertedDate == "01 / 01 / 1970") {
        return "-- / -- / --"
      }
      else {
        return convertedDate
      }
    }
    else {
      return "-- / -- / --"
    }
  }


  handleValidAuditSubmit = (e, values) => {
    const { isEdit, users, selectedUser } = this.state
    this.setState({ btn_load: true })
    setTimeout(() => {
      if (this.state.audit_name_exist == false) {

        if (this.state.convertionMode == "2") {
          const templateNameInfo = {
            template_name: values["template_name"],
            template_info: values["template_info"]
          }
          this.convertTemplateAs(this.state.convertionTemplateData, templateNameInfo)
        }
        else {
          if (isEdit) {
            this.setState({
              update_temp: {
                ...this.state.update_temp,
                template_name: values.template_name,
                template_info: values.template_info
              }
            }, () => {
              this.createNewAudit(this.state.update_temp);
            });



          } else {

            const templateInfo = {
              template_name: values["template_name"],
              template_info: values["template_info"]
            }
            // save new audit
            this.createNewAudit(templateInfo)
          }
        }
        this.setState({ selectedUser: null }, () => {
          this.setState(prevState => ({
            modal: !prevState.modal,
            audit_name_exist: false,
          }))
        })
        // this.toggle()
      }
    }, 1500);

  }

  audit_name = (e) => {
    // console.log(e.target.value, 'e')

    var audit_name = e.target.value
    console.log(audit_name,'audit_name')
    if ((audit_name !== undefined || audit_name === '') && (!this.state.isEdit && this.state.convertionMode == "2")) {

      try {

        urlSocket.post('cog/validate-audit-name', { audit_name, db_url: this.state.db_info.encrypted_db_url, user_id: this.state.userInfo._id }).then((response) => {
          console.log(response,'response')
          if (response.data.data.length > 0) {
            this.setState({ audit_name_exist: true })
          }
          else {
            this.setState({ audit_name_exist: false })

          }
        })
      } catch (error) {

      }
    }
    else {
      console.log("validate questionire name")
      try {
        urlSocket.post('webmngtmplt/validate-user-templatemaster', { audit_name, encrypted_db_url: this.state.db_info.encrypted_db_url, user_id: this.state.userInfo._id }).then((response) => {
          console.log(response, 'response')
          if (response.data.response_code == 500 && response.data.data.length > 0) {
            this.setState({ audit_name_exist: true })

          }
          else {
            this.setState({ audit_name_exist: false })

          }
        })

      } catch (error) {

      }
    }
  }



  createNewAudit = (data) => {
    try {
      urlSocket.post('/webmngtmplt/crudtemplate', {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.state.userInfo.company_id,
          company_name: this.state.config.client_name,
          user_id: this.state.userInfo._id,
        },
        templateInfo: {
          template_id: data._id != undefined ? data._id : undefined,
          template_name: data.template_name.trim(),
          template_info: data.template_info,
          tmplt_ctd_by: data.tmplt_ctd_by
        }

      })
        .then((response) => {
          this.setState({ btn_load: false })
          if (response.data.response_code === 500 && response.data.message === 'Template created successfully') {
            this.navigateTo(response.data.data)
          }
          else {
            this.getAuditMasterTemplates()
          }

        })
    } catch (error) {
      console.log(error.toString())
    }
  }


  convertTemplateAs(data, templateNameInfo) {
    try {
      urlSocket.post('/webmngtmplt/converttemplate', {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.state.userInfo.company_id,
          company_name: this.state.userInfo.company_name,
          user_id: this.state.userInfo._id,
        },
        templateInfo: {
          data,
          template_name: templateNameInfo.template_name,
          template_info: templateNameInfo.template_info,
        }
      })
        .then((response) => {
          this.setState({ btn_load: false })
          if (response.data.response_code == 500) {
            this.navigateTo()
          }
        })
    } catch (error) {
      console.log("catch error", error)
    }

  }

  OnCreateAudit = (data) => {
    sessionStorage.removeItem("EditData");
    this.props.history.push("/crttmplt");

  }

  changeView = () => {
    this.setState(prevState => ({
      chnage_view: !prevState.chnage_view,
    }))
    // this.getAuditDefaultTemplates()
    // this.getAuditMasterTemplates()
    this.pageClick(1)
  }

  navigateTo = (data) => {
    console.log(this.state.convertionMode, 'this.state.convertionMode')
    if (this.state.convertionMode == "2") {
      sessionStorage.removeItem("EditData");
      this.props.history.push("/mngpblhtempt");
    }
    else {
      sessionStorage.removeItem("EditData");
      sessionStorage.setItem("EditData", JSON.stringify(data));
      this.props.history.push("/crttmplt");
    }
  }

  renameTemp = (data) => {
    this.setState({ users: '', update_temp: data, isEdit: true, convertionMode: "0", template_name: data.template_name, template_info: data.template_info }, () => {
      this.setState(prevState => ({
        modal: !prevState.modal,
        audit_name_exist: false,
      }))
    })
    // this.toggle()
  }



  pageClick = (currentPage) => {
    this.setState({ currentPage }, () => { this.paginationProcess() })
  }

  paginationProcess = () => {
    let { currentPage, items_per_page, search_files, dup_search_files } = this.state
    let result = search_files.slice((currentPage - 1) * items_per_page, (currentPage - 1) * items_per_page + items_per_page)
    if (result.length > 0) {
      this.setState({ auditData: result })
    } else {
      this.setState({ auditData: search_files, search_files })
    }
  }

  getFuzzySearch = (search_files) => {
    // this.setState({ auditData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files })
    this.setState({ auditData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files }, () => { this.pageClick(this.state.currentPage) })

    // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })
  }


  SearchTempName = (value) => {
    console.log(value, 'value', this.state.auditData)
    if (value.length > 0) {
      console.log(value, '462', this.state.auditData, this.state.temp_auditData)

      var filteredDate = this.state.temp_auditData.filter(item =>
        // console.log(item.template_name)
        item.template_name.toLowerCase().includes(value.toLowerCase())
      )
      console.log(filteredDate, 'filteredDate')
      this.setState({
        auditData: filteredDate
      })
    } else {
      console.log(value, '474', this.state.auditData, this.state.temp_auditData)
      this.setState({
        auditData: this.state.temp_auditData
      })
    }
  }


  render() {
    if (this.state.dataloaded) {

      const { isEdit } = this.state

      const columns = [
        {
          accessor: 'template_name',
          Header: 'Template Name',
          isSort: true,
          width:"35%",
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <div className="d-flex flex-row">
                <div className="me-2">
                  <div
                    style={{
                      height: "60px",
                      width: "60px",
                      padding: 10,
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "1rem",
                      alignItems: "center",
                      background: "rgb(243, 246, 251)",
                    }}
                  >
                    <img
                      src={tmplIcon}
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column pe-2">
                  <div className="font-size-14 mb-1 fw-bold" style={{ textWrap: "wrap" }}>
                    {item.template_name}
                  </div>
                  <div >
                    <p className="text-muted font-size-12" style={{ textWrap: "wrap" }}>{item.template_info}</p>
                  </div>
                </div>
              </div>
            )
          }
        },
       
        {
          accessor: 'total_checkpoints',
          Header: 'Total Checkpoint',
          isSort: false,
          width:"25%",
          Cell: (cellProps) => {
            var item = cellProps.row.original

            return (
              <Badge className={item.total_checkpoints === 0 ? "badge-soft-secondary font-size-15" :"badge-soft-success font-size-15"} pill>
                {item.total_checkpoints}
              </Badge>
            )

          }
        },
        {
          accessor: 'created_on',
          Header: 'Created on',
          isSort: false,
          width:"30%",
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                {this.dateConvertion(item.created_on)}
              </>
            )
          }
        },
        {
          accessor: "menu",
          Header: "Action",
          isSort: false,
          width:"10%",
          Cell: (cellProps) => {
            var docItem = cellProps.row.original
            return (
              // <UncontrolledDropdown>
              //   <DropdownToggle
              //     // href="#"
              //     className="card-drop"
              //     tag="a"
              //   >
              //     <i className="mdi mdi-dots-horizontal font-size-18" />
              //   </DropdownToggle>
              //   <DropdownMenu className="dropdown-menu-end" style={{ marginTop: "-38px", width: "50%", padding: "0px" }} right>
              //     {
              //       item.total_checkpoints > 0 && this.state.protect_routes.read_write_checked === true &&
              //       <DropdownItem
              //         onClick={() => { this.useTemplateHandlerClick(item) }}                >
              //         <i className="bx bx-check-double font-size-12 text-info me-1" />{" "}
              //         Use This
              //       </DropdownItem>
              //     }
              //     {
              //       this.state.protect_routes.read_write_checked === true &&
              //       <DropdownItem
              //         onClick={() => this.navigateTo(item)}
              //       >
              //         <i className="bx bx-edit-alt font-size-12 text-info me-1" />{" "}
              //         Edit
              //       </DropdownItem>
              //     }


              //     {/* } */}
              //     {
              //       this.state.protect_routes.read_write_checked === true &&
              //       <DropdownItem
              //         onClick={() => this.setState({ confirm_both: true, deleteItem: item })}
              //       >
              //         <i className="fas fa-trash-alt font-size-12 text-danger me-1" />{" "}
              //         Delete
              //       </DropdownItem>
              //     }
              //   </DropdownMenu>

              // </UncontrolledDropdown>
              <Row className="">
              <ul className="list-unstyled hstack gap-1 mb-0 ">
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-primary"
                    id={`viewtooltip-${docItem._id}`}
                    onClick={() => this.setState({ convertionMode: "1" }, function () { this.navigateTo(docItem) })}
                  >
                    <i className="mdi mdi-clipboard-text-outline" />
                  </Link>
                </li>
                <UncontrolledTooltip placement="top" target={`viewtooltip-${docItem._id}`}>
                  Edit Template
                </UncontrolledTooltip>

                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => { this.renameTemp(docItem)  }}
                    id={`edittooltip-${docItem._id}`}
                  >
                    <i className="mdi mdi-pencil-outline" />
                    <UncontrolledTooltip placement="top" target={`edittooltip-${docItem._id}`} >
                      Rename Template
                    </UncontrolledTooltip>
                  </Link>
                </li>

                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => this.setState({ confirm_both: true, deleteItem: docItem })}
                    id={`deletetooltip-${docItem._id}`}
                  >
                    <i className="mdi mdi-delete-outline" />
                    <UncontrolledTooltip placement="top" target={`deletetooltip-${docItem._id}`}>
                      Delete Template
                    </UncontrolledTooltip>
                  </Link>
                </li>

                <li>
                  <Link
                    to="#"
                    className={this.state.protect_routes.read_write_checked === true && docItem.total_checkpoints !== 0 ? "btn btn-sm btn-success" : "btn btn-sm btn-secondary"}
                    onClick={() => {
                      this.state.protect_routes.read_write_checked === true && docItem.total_checkpoints !== 0 &&
                      this.useTemplateHandlerClick(docItem)
                    }}
                    id={`shareaudit-${docItem._id}`}
                  >
                    <i className="mdi mdi-clipboard-arrow-right-outline" />
                    <UncontrolledTooltip placement="top" target={`shareaudit-${docItem._id}`}>
                      {docItem.total_checkpoints === 0 ? "Add check points and use this template" : "Use this template as"}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
            </Row>

            )
          },
        },
      ];

      return (
        <React.Fragment>
          <div className="page-content" >

            <MetaTags>
              <title>AuditVista | Manage Template</title>
            </MetaTags>

            <Container fluid>

              <Breadcrumbs title="Audit Templates" breadcrumbItem="Templates" />

              <Card>
                <CardBody>

                  <Row className="mb-2 pb-2 border-bottom">
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:"end" }} >
                     
                        {
                          this.state.protect_routes.read_write_checked == true &&
                          <Button color={"primary"} outline
                            onClick={this.handleUserClicks}
                          >
                            Create Template
                          </Button>

                        }
                        <div className="float-end">
                            <ul className="nav nav-pills">
                              <NavItem>
                                <NavLink
                                  className="disabled"
                                  to="#"
                                  tabIndex="-1"
                                >
                                  View :
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <Link 
                                to="#" 
                                className={this.state.gridView === "block" ? "nav-link active" : "nav-link"}
                                onClick={()=> this.setState({gridView:"block"},()=>{
                                  this.getAuditDefaultTemplates()
                                })}
                                >
                                  <i className="mdi mdi-view-grid-outline"></i>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link
                                  className={this.state.gridView === "list" ? "nav-link active" : "nav-link"}
                                  to="#"
                                  onClick={()=> this.setState({gridView:"list"},()=>{
                                    this.getAuditDefaultTemplates()
                                  })}

                                >
                                  <i className="mdi mdi-format-list-bulleted"></i>
                                </Link>
                              </NavItem>
                              
                            </ul>
                          </div>


                    </div>
                  </Row>

                  {
                    this.state.gridView == "block" &&
                    <Row>
                      <div className="pt-2 row justify-content-center align-items-center">
                        <div className="col-6">
                          <Input type="text" className="form-control" id="searchJob" placeholder="Search your audit" onChange={(e)=>{this.SearchTempName(e.target.value)}} />
                        </div>
                      </div>
                      <div className="p-3 row">
                      {map(this.state.auditData, (docItem, contactkey) => (
                        <CardContact
                          docItem={docItem}
                          key={"_docItem_" + contactkey}
                          contactkey={contactkey}
                          onRename={(data) => { this.renameTemp(data) }}
                          onEdit={(data) => this.setState({ convertionMode: "1" }, function () { this.navigateTo(data) })}
                          onDelete={(data) => this.setState({ confirm_both: true, deleteItem: data })}
                          useThisTemplate={(data) => this.useTemplateHandlerClick(data)}
                          protect_routes={this.state.protect_routes}
                        />
                      ))}
                      <Row>
                      </Row>
                      {/* <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationComponent
                            total={this.state.search_files.length}
                            itemsPerPage={this.state.items_per_page}
                            currentPage={this.state.currentPage}
                            onPageChange={currentPage => this.pageClick(currentPage)}
                            paginationDiv="col-sm-12 col-md-7"
                            paginationClass="pagination justify-content-end pagination-rounded"
                          />
                        </Col>
                      </Row> */}
                      </div>
                    </Row>
                  }

                  {
                    this.state.gridView == "list" &&
                    <Row>
                      <TableContainer
                        columns={columns}
                        data={this.state.auditData}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        isJobListGlobalFilter={false}
                        customPageSize={10}
                        style={{ width: '100%' }}
                        iscustomPageSizeOptions={false}
                        isPagination={true}
                        filterable={false}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination justify-content-end pagination-rounded my-2" 

                      />
                    </Row>
                  }


                </CardBody>
              </Card>

              <Modal
                isOpen={this.state.modal}
                className={this.props.className}
              >
                <ModalHeader toggle={this.toggle} tag="h5">
                  {!!isEdit && this.state.convertionMode != "2" ? "Rename Template" : !isEdit && this.state.convertionMode == "2" ? "Use Template as" : "Add New Template"}
                </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={
                      this.handleValidAuditSubmit
                    }
                    onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("null") }}
                  >
                    <Row form>
                      <Col className="col-12">
                        <div className="mb-3">
                          <AvField
                            name="template_name"
                            label="Template Name"
                            type="text"
                            onChange={(e) => { this.audit_name(e) }}
                            // onChange={()=>{this.setState({audit_name_exist : false})}}
                            value={isEdit ? this.state.template_name : ''}
                            errorMessage="Invalid name"
                            validate={{
                              required: { value: true },
                            }}
                          />
                          {
                            this.state.audit_name_exist &&
                            <div className="text-danger" style={{ fontSize: 'smaller' }}>This name already exist , give another name.</div>
                          }
                        </div>
                      </Col>


                      <Col className="col-12">
                        <div className="mb-3">
                          <AvField
                            name="template_info"
                            label="Description"
                            type="textarea"
                            value={isEdit ? this.state.template_info : ''}
                            errorMessage="Invalid name"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </div>
                      </Col>

                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">

                          <button
                            type="submit"
                            className="btn btn-success save-user"
                            disabled={this.state.btn_load || this.state.audit_name_exist}
                          // onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("null") }}
                          >
                            {
                              this.state.btn_load &&
                              <LoadingOutlined />
                            }
                            {
                              this.state.btn_load ? "..." : isEdit ? "Update" : "Save"
                            }

                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
              <Row>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.handleDeleteTemplate(this.state.deleteItem)
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                        error_dlg: true,
                        dynamic_title: "Cancelled",
                        dynamic_description:
                          "Your imaginary file is safe :)",
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.success_dlg ? (
                  <SweetAlert
                    success
                    title={this.state.dynamic_title}
                    onConfirm={() => this.setState({ success_dlg: false })}
                  >
                    {this.state.dynamic_description}
                  </SweetAlert>
                ) : null}
              </Row>
            </Container>
          </div>
        </React.Fragment>


      );
    }
    else {
      return null
    }
  }
}

export default ManageAudit;
