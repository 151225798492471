import React, { Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from "react-router-dom"
import CryptoJS from 'crypto-js'
import {
  Container, Row, Col, Card, CardBody, CardTitle, Table, Button, Badge,
  Progress, Pagination, PaginationItem, PaginationLink,
  Modal, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Form, ModalHeader, ModalBody, FormGroup, Label, Input, FormFeedback, UncontrolledDropdown
} from "reactstrap";
import Swal from 'sweetalert2';
import SweetAlert from "react-bootstrap-sweetalert"
import * as Yup from 'yup';
import TableContainer from "./Components/TableContainer";
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import facility from '../FacilityScreen'
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import SETPERMISSION from './Components/userPermission'
import { AvForm, AvField } from "availity-reactstrap-validation"
import _ from "lodash";
import { isEmpty, size, map } from "lodash"
import { withFormik } from "formik";
import { TableHeader, PaginationComponent } from "../../../common/DataTable"
import FuzzySearch from '../../../common/FuzzySearch';
import { LoadingOutlined } from '@ant-design/icons';

import Breadcrumbs from "../../../components/Common/Breadcrumb"


import urlSocket from "../../../helpers/urlSocket";
// var urlSocket = require("../../helpers/urlSocket");

class UserLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      usertype: '',
      dataloaded: false,
      auditStatusData: [
        { status: "Active", count: 0, color: "#fbae17", id: "1", badgeClass: "success" },
        { status: "In active", count: 0, color: "#303335", id: "0", badgeClass: "danger" },
      ],
      userHLevel: "All",
      resultData: [],
      ddchange: false,
      show_modal: false,
      show: true,
      secret_key: 'Pkdh347ey%3Tgs',
      roles_info: [],
      group_name_exist: false,
      selectedEOPT: [],
      height: window.innerHeight,

      search_files: [],
      dup_search_files: [],
      temp_search_files: [],
      currentPage: 1,
      totalItems: 0,
      items_per_page: 10,
      isSaving: false,
      btn_load: false,
      isSubmitting: false,

      show_1: true,

      search_group_name: [],
      dup_search_group_name: [],
      dup_labelData: [],
      dup_temp_search_group: [],
      isRowSelected:false,
      isAllSelected:false

    };
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.handleValidGroupSubmit = this.handleValidGroupSubmit.bind(this)
    this.toggleFolder = this.toggleFolder.bind(this);
  }


  async componentDidMount() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
    var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"))
    //("data", data)
    console.log(data.facility_data,'data.facility_data')
    var protect_routes = await facility(user_facilities, 'murs')
    //(protect_routes, 'protect_routes')
    if (protect_routes !== undefined) {
      var auditData = JSON.parse(sessionStorage.getItem("auditData"));
      this.setState(
        {
          userInfo: data,
          client_info: client_info,
          db_info: db_info,
          configData: data.config_data,
          dataloaded: false,
          protect_routes: protect_routes


        },
        () => {
          this.getUserList()
          // this.getRoles(db_info)
          // this.loadUserLabels()
          // this.validateData()
        }
      )
    }
    else {
      this.props.history.push('/dashboard')
    }
  }

  getRoles = (db_info) => {

    try {
      urlSocket.post('cog/manage-roles', { encrypted_db_url: db_info.encrypted_db_url, user_id: this.state.userInfo.user_data._id , show_all : true }).then((response) => {
//        console.log(response, 'response')
        if (response.data.response_code == 500) {
          this.setState({ roles_info: response.data.data, dataloaded: true })

        }
      })

    } catch (error) {

    }





  }

  validateData = () => {
    var userNextLevel = _.map(_.filter(this.state.configData.hierarchy_info, ({ hierarchy_level }) => hierarchy_level >= this.state.userInfo.hierarchy_level_value), "hierarchy_level_name")
    var hlData = ""
    _.each(userNextLevel, (item, index) => {
      if (index == 1) {
        hlData = this.state.userInfo.departmentandhierarchy[0][item]
      }
      else if (index > 1) {
        if (this.state.userInfo.departmentandhierarchy[0][item] != "" && this.state.userInfo.departmentandhierarchy[0][item] != undefined)
          hlData = hlData + " / " + this.state.userInfo.departmentandhierarchy[0][item]
      }
    })

    this.setState({ hlData })
  }

  // getUserList = () => {
  //     try {

  //         urlSocket.post('cog/get-user-info', {  db_info :  this.state.db_info,
  //             user_id :this.state.userInfo.user_data._id
  //         }).then((res) => {
  //             //(res)
  //             if (res.data.response_code === 500) {
  //                 this.setState({
  //                     dataSource: res.data.user_list,
  //                     entitiesAuditData: res.data.user_list,
  //                     statusBasedFilteredData: res.data.user_list,
  //                     dataloaded: false,
  //                     labelDefault: true,
  //                     totalEntities: res.data.user_list.length,
  //                     selectedLabel :'',
  //                     search_files: res.data.user_list, 
  //                    dup_search_files: res.data.user_list,      
  //                 }, () => this.createStatus(),this.loadUserLabels(), this.pageClick(1))



  //             }
  //         })


  //     } catch (error) {

  //     }
  // }


  getUserList = () => {
//    console.log(this.state.userInfo.user_data,'this.state.userInfo')
    var show_all ;
    if(this.state.userInfo.user_data.created_by === null ){
      show_all = true
    }
    else{
      show_all = false
    }
    try {
      urlSocket.post('cog/get-user-info', { db_info: this.state.db_info, user_id: this.state.userInfo.user_data._id ,show_all :show_all }).then((res) => {
      //  console.log(res, 'res', 'url_1')
        //(res)
        if (res.data.response_code === 500) {
          this.setState({
            dataSource: res.data.user_list,
            entitiesAuditData: res.data.user_list,
            statusBasedFilteredData: res.data.user_list,
            dataloaded: false,
            labelDefault: true,
            totalEntities: res.data.user_list.length,
            selectedLabel: '',
            search_files: res.data.user_list,
            dup_search_files: res.data.user_list,
            temp_search_files: res.data.user_list
          }, () => this.createStatus(), this.loadUserLabels(), this.pageClick(1))
        }
      })
    } catch (error) {

    }
  }

  


  dateConvertion = (dateToConvert) => {
    if (dateToConvert != null) {
      var date = typeof (dateToConvert) == "object" ? String(dateToConvert.toISOString()) : String(dateToConvert)
      var convertedDate = date.slice(8, 10) + ' / ' + (date.slice(5, 7)) + ' / ' + date.slice(0, 4);//prints expected format. 
      if (convertedDate == "01 / 01 / 1970") {
        return "-- / -- / --"
      }
      else {
        return convertedDate
      }
    }
    else {
      return "-- / -- / --"
    }
  }

  completedStatus = (data) => {
    //(data, 'data')
    var labels = []
    data == false ? labels.push({ status: "In active", color: "#303335", badgeClass: "danger" }) :
      data == true ? labels.push({ status: "Active", color: "#fbae17", badgeClass: "success" }) :
        labels.push({ status: "In active", color: "#303335", badgeClass: "danger" })
    return labels
  }

  navigateTo = (userData, mode) => {
    //(userData, 'userData')
    if (mode === 0) {
      sessionStorage.removeItem("selectedUser");
      sessionStorage.removeItem("map_location_page");
      sessionStorage.removeItem("redirect");
      sessionStorage.setItem("selectedUser", JSON.stringify(userData));
      this.props.history.push("/edtusr");
    }
    else
      if (mode === 1) {
      sessionStorage.removeItem("map_location_page");
      sessionStorage.removeItem("redirect");
        this.props.history.push("/adusrs");
      }
  }

  toggle2 = (e) => {
    this.setState(prevState => ({
      labelWindow: !prevState.labelWindow,
      group_name_exist: false,
      btn_load: false
    }))
    e.preventDefault();
  }


  loadEOPTS = () => {
    this.setState({ tableDataloaded: false, isAllSelected:false })
    try {
      urlSocket.post("webEntities/grouplist", {
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.state.userInfo.client_info[0]._id,
          user_id: this.state.userInfo.user_data._id,
        },
      })
        .then((response) => {
          //("response.data.data",response, response.data.data)
          this.setState({
            statusBasedFilteredData: response.data.data,
            tableDataloaded: true,
            allowTableRowToSelect: true,
            labelDefault: true,
            selectedLabel: false,
            label: {
              endpoints: []
            }

          }, function () {
            this.loadUserLabels()
          })

        })
    } catch (error) {

    }






  }


  filterEntityData = (event, h_level, hlevelNameValue, selectedIndex) => {
    this.setState({ dataloaded: false })
    var h_levelValue = event.target.value
    var selectedValue = this.state.selectedValue

    // //("levels--", h_level, selectedIndex, h_levelValue, hlevelNameValue, this.state.selectedValueName, selectedValue)

    var sourceAll = _.filter(this.state.dataSource, item => {
      var mapInfo = _.filter(item.departmentandhierarchy, { "department_name": this.state.userInfo.departmentandhierarchy[0].department_name })[0]

      if ((selectedIndex == 0 && h_levelValue == "All")) {
        return item
      }
      else {
        if (h_levelValue == "All") {
          if ((selectedIndex - 1 == 0 && selectedValue[selectedIndex - 1] == "All")) {
            return item
          }
          else {
            if (mapInfo[String(this.state.selectedValueName[selectedIndex - 1])] == selectedValue[selectedIndex - 1]) {
              return item
            }
          }
        }
        else {
          if (mapInfo[String(h_level)] == h_levelValue) {
            return item
          }
        }

      }


    })

    //("source", sourceAll)

    var mappingInfo = _.map(sourceAll, item => {
      let h_level = _.filter(item.departmentandhierarchy, { "department_name": this.state.userInfo.departmentandhierarchy[0].department_name })[0]
      return h_level
    })
    var userNextLevel = _.filter(this.state.configData.hierarchy_info, ({ hierarchy_level }) => hierarchy_level < hlevelNameValue)
    if (userNextLevel.length > 0) {
      var uniqueHlevels

      _.map(userNextLevel, item => {
        uniqueHlevels = _.uniqBy(mappingInfo, el => `${el[item.hierarchy_level_name]}`)
      })

      var getUniqueRecords = _.uniqWith(mappingInfo, _.isEqual)

      var uniqueNextLevels = []
      _.map(userNextLevel, (item, idx) => {


        var findLevel = _.find(this.state.uniqueNextLevels, ["hlevelName", item.hierarchy_level_name]);
        findLevel.hlevelValues = []

        uniqueNextLevels.push({
          hlevelName: item.hierarchy_level_name,
          hlevelNameValue: item.hierarchy_level,
          hlevelValues: _.uniqBy(getUniqueRecords, el => `${el[item.hierarchy_level_name]}`)
        })


        let index = this.state.uniqueNextLevels.findIndex(i => i.hlevelName === item.hierarchy_level_name);
        let getuniqueLevelIndex = uniqueNextLevels.findIndex(i => i.hlevelName === item.hierarchy_level_name)
        if (index != -1) {
          this.state.uniqueNextLevels.splice(index, 1, uniqueNextLevels[getuniqueLevelIndex]);
          //this.state.uniqueNextLevels[index] = uniqueNextLevels[getuniqueLevelIndex]
        }



        selectedValue[index] = "All"
        selectedValue[selectedIndex] = h_levelValue
        //_.unionBy(uniqueNextLevels, this.state.uniqueNextLevels, 'hlevelName');
      })

    }
    else {
      selectedValue[selectedIndex] = h_levelValue
    }


    var filteredData = this.state.dataSource
    _.map(selectedValue, (data, idx) => {
      // //("data", data, this.state.selectedValueName[idx])
      filteredData = _.filter(filteredData, item => {
        if (data == "All") {
          return item

        }
        else {
          var mapInfo = _.filter(item.departmentandhierarchy, { "department_name": this.state.userInfo.departmentandhierarchy[0].department_name })[0]
          if (mapInfo[this.state.selectedValueName[idx]] == data) {
            return item
          }

        }
      })

    })


    this.setState({
      entitiesAuditData: filteredData,
      uniqueNextLevels: this.state.uniqueNextLevels,
      selectedValue: selectedValue,
      h_levelName: h_level,
      // dataloaded: true
    }, () => {
      this.createStatus()
      this.filterStatus('All')
    })



  }

  toggleFolder() {
    this.setState(prevState => ({
      folder_Menu: !prevState.folder_Menu,
    }))
  }


  createStatus = () => {
    //(this.state.entitiesAuditData)
    var entityStatus = _.map(_.countBy(this.state.entitiesAuditData, "active"), (val, key) => ({ status: key, count: val }))
    //("entityStatus", entityStatus)
    var totalEntities = 0
    var result = []
    result = _.each(this.state.auditStatusData, function (audititem) {
      audititem.count = 0;
      _.each(entityStatus, itemStatus => {
        if (itemStatus.status == audititem.id) {
          audititem.count = itemStatus.count
          totalEntities = totalEntities + itemStatus.count
        }
      })
    })

    //("result", result)

    this.setState({ resultData: result, totalEntities })
  }


  filterStatus = (data) => {
//    console.log("data -->", data)
    var filteredData;
    if (data == "In active") {
      filteredData = _.filter(this.state.entitiesAuditData, { "active": "0" })
    }
    else
      if (data == "Active") {
        filteredData = _.filter(this.state.entitiesAuditData, { "active": "1" })
      }
      else
        if (data == "All") {
          filteredData = this.state.entitiesAuditData
        }
    this.setState({
      statusBasedFilteredData: filteredData,
      search_files: filteredData,
      show_selected: data
    })



  }

  handleValidSubmit = (event, values) => {
    //(values, 'values', this.state.selectedUser)
    var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
    values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
    values["db_name"] = this.state.db_info.db_name
    values["userPoolId"] = this.state.client_info.userPoolId
    values["clientId"] = this.state.client_info.clientId
    values["clientId"] = this.state.client_info.clientId
    values["password"] = password
    values["_id"] = this.state.selectedUser._id
    values["email_id"] = this.state.selectedUser.email_id
    values["short_name"] = this.state.client_info.short_name
    values["user_id"] = this.state.userInfo.user_data._id




    try {
      urlSocket.post('cog/reset-client-user-pwd', values).then((res) => {
        //(res, 'response')
        if (res.data.data.response_code === 500) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Your request has been processed successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            //(result)
            this.setState({ show_modal: false }, () => { this.getUserList() })

          })

        }
      })
    } catch (error) {

    }
  }





  handleValidGroupSubmit = (event, values) => {
    //(this.state.db_info, this.state.userInfo, 'userInfo')        
    // this.setState({ btn_load: true })
    // if (!this.state.isSubmitting) {
      if (this.state.group_name_exist === false) {
        this.setState({ btn_load: true })
        try {
          urlSocket.post("userSettings/create-user-group", {
            labelInfo: values,
            userInfo: {
              encrypted_db_url: this.state.db_info.encrypted_db_url,
              _id: this.state.userInfo.user_data._id,
              company_id: this.state.userInfo.client_info[0]._id
            }
          })
            .then(response => {
              //("response", response, response.data.data)
              this.setState({
                labelData: response.data.data,
                dup_labelData: response.data.data,
                labelWindow: false,
                allowTableRowToSelect: true,
                // btn_load : false,
                // isSubmitting: true
              })
            })
        } catch (error) {
          //("catch error", error)
        }
      }
    // }
    // this.setState({ isSubmitting: false })
  }

  handleGroupName = (event) => {
    //(event.target.value, 'event')
    var api_data = {}
    api_data["group_name"] = event.target.value
    api_data["encrypted_db_url"] = this.state.db_info.encrypted_db_url
    api_data["user_id"] = this.state.userInfo.user_data._id
    // api_data["label_name"] = this.state.db_info.encrypted_db_url
    if (event.target.value !== "") {

      try {
        urlSocket.post('userSettings/validate-user-group', api_data).then((response) => {
          //(response, 'response')
          if (response.data.data.length > 0) {
            this.setState({ group_name_exist: true })
          }
          else {
            this.setState({ group_name_exist: false })

          }
        })



      } catch (error) {

      }

    }


  }

  labelSelected(data) {
    //(data, 'data')
    this.setState({
      tableDataloaded: false, selectedLabel: data._id,
      isAllSelected:false,
      label: data
    })


    try {

      urlSocket.post("userSettings/load-group-users", {
        labelInfo: data,
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          _id: this.state.userInfo.user_data._id,
          company_id: this.state.userInfo.client_info[0].company_id
        }
      })
        .then(response => {
          console.log(response,'response')
          //("response", response.data.data)
          this.setState({
            statusBasedFilteredData: response.data.data,
            entitiesAuditData: response.data.data,
            search_files: response.data.data,
            dup_search_files: response.data.data,
            temp_search_files: response.data.data,
            selectedEOPT:[],
            // resultData: result, totalEntities 
            labelDefault: false,
            tableDataloaded: true
          }, () => { this.createStatus(); this.loadUserLabels() })
        })

    } catch (error) {
      //("catch error", error)
    }


  }


  removeFromLabel() {


    if (this.state.selectedEOPT.length !== 0) {
      this.setState({ tableDataloaded: false })
      _.each(this.state.selectedEOPT, item => {
        var getIndex = this.state.label.group_users.indexOf(item)

        if (getIndex !== -1) {
          this.state.label.group_users.splice(getIndex, 1)

        }

      })

      //("remove", this.state.selectedEOPT)


      try {

        urlSocket.post("userSettings/move-to-group", {
          eopts: this.state.label.group_users,
          labelInfo: this.state.label,
          userInfo: {
            encrypted_db_url: this.state.db_info.encrypted_db_url,
            _id: this.state.userInfo.user_data._id,
            company_id: this.state.userInfo.client_info[0]._id
          }
        })
          .then(response => {
            ////("response", response.data.data)
            var getIndex = _.findIndex(this.state.labelData, item => { return item._id == response.data.data._id })
            ////("get Index", getIndex)
            var labelData =[...this.state.labelData]
            labelData[getIndex] = response.data.data
            //("this.state.labelData", this.state.labelData)

            this.setState({
              labelData: labelData,
              dup_labelData: labelData,
              label: response.data.data,
              selectedEOPT: [],
              tableDataloaded: true
            }, () => {
              this.labelSelected(response.data.data)
            })
          })

      } catch (error) {
        //("catch error", error)
      }
      //var concated = this.state.selectedEOPT.concat(data.endpoints)
    }

  }



  loadUserLabels() {
    //(this.state.userInfo, 'userinfo', this.state.db_info)
    try {
      urlSocket.post("userSettings/get-user-group", {
        userInfo: {
          _id: this.state.userInfo.user_data._id,
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          company_id: this.state.userInfo.client_info[0].company_id
        }
      })
        .then(response => {
          //(response, 'response')
          this.setState({
            labelData: response.data.data,
            dup_labelData: response.data.data,
            tableDataloaded: true,
            search_group_name: response.data.data,
            dup_search_group_name: response.data.data,
            dup_temp_search_group: response.data.data,
            // dataloaded: true,
            allowTableRowToSelect: false,
          })
          this.getRoles(this.state.db_info)
        })

    } catch (error) {
      //("catch error", error)
    }
  }



  onDrawerClose() {

    this.setState({ open: false, dataloaded: false }, () => { this.getUserList() });

  }

  activeInactive(userInfo) {
    //(userInfo, 'userInfo')
    if (userInfo.active === "0") {
      userInfo.active = "1"
    }
    else {
      userInfo.active = "0"
    }

    try {
      urlSocket.post("cog/updtactive", {
        userInfo: userInfo,
        db_info: this.state.db_info
      })
        .then((response) => {
          //(response)
          if (response.data.response_code == 500) {
            this.setState({ dataloaded: false }, () => { this.getUserList() });
          }

        })

    } catch (error) {
      //("error")
    }
  }

  moveToLabel(data) {
    //(data, 'data', this.state.selectedEOPT)
    if (this.state.selectedEOPT.length !== 0) {

      _.each(data.group_users, item => {
        var getIndex = this.state.selectedEOPT.indexOf(item)

        if (getIndex !== -1) {
          this.state.selectedEOPT.splice(getIndex, 1)

        }

      })
      var concated = this.state.selectedEOPT.concat(data.group_users)

      this.setState({ tableDataloaded: false })
      //(concated,'concated')
      try {

        urlSocket.post("userSettings/move-to-user-group", {
          eopts: concated,
          labelInfo: data,
          userInfo: {
            encrypted_db_url: this.state.db_info.encrypted_db_url,
            _id: this.state.userInfo.user_data._id,
            company_id: this.state.userInfo.client_info[0]._id
          }
        })
          .then(response => {
            console.log(response,'response')
            //("response",response, response.data.data)
            var getIndex = _.findIndex(this.state.labelData, item => { return item._id == response.data.data._id })
            var labelData =[...this.state.labelData]
            labelData[getIndex] = response.data.data

            this.setState({
              labelData: labelData,
              dup_labelData: labelData,
              selectedEOPT: [],
              tableDataloaded: true,
              // dataloaded : false
            })
          })

      } catch (error) {
        //("catch error", error)
      }
    } else {
      // toggle()
    }






  }


  select_roles = (event, user) => {
    //(event.target.value, 'event', user)
    var role_name = event.target.value
    var role_idx = this.state.roles_info.findIndex(i => i.role_name === role_name)
    var role_id = this.state.roles_info[role_idx]._id
    //(role_id)
    user['role_name'] = role_name
    user['role_id'] = role_id
    user['encrypted_db_url'] = this.state.db_info.encrypted_db_url
    user['db_name'] = this.state.db_info.db_name
    user["user_id"] = this.state.userInfo.user_data._id
    try {
      urlSocket.post('cog/cruduser', user).then((res) => {
        //(res, 'res')
        if (res.data.response_code === 500) {
          // this.getUserList()
          //      this.setState({loading:false})
          // this.props.history.push('/murs')
        }
      })

    } catch (error) {

    }




  }


  deleteLabel() {
    try {

      urlSocket.post("userSettings/delete-user-group", {
        labelInfo: this.state.label,
        userInfo: {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          _id: this.state.userInfo.user_data._id,
          company_id: this.state.userInfo.client_info[0]._id
        }
      })
        .then(response => {
          //("response", response.data.data)

          this.setState({
            labelData: response.data.data,
            dup_labelData: response.data.data,
            labelWindow: false,
            allowTableRowToSelect: true
          }, function () {
            this.loadEOPTS()
          })
        })

    } catch (error) {
      //("catch error", error)
    }
  }



  delete_user = (data) => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure?',
      text: 'Do you want to delete this user ?',
      showCancelButton: true,
      confirmButtonColor: '#2ba92b',
      confirmButtonText: 'Yes',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        urlSocket.post('cog/delete-user', {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          user_id: data._id,
          email: data.email_id,
          client_info: this.state.userInfo.client_info[0]

        }).then((response) => {
          console.log(response, 'response')
          if (response.data.response_code === 500) {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: `User deleted Successfully`,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                this.getUserList()
              }
            })
          }
        })
      }
    })
  }


  getSelectedEOPT = (row, isSelect, rowIndex, e) => {
    //(row, isSelect, rowIndex, e)
    var getIndex = this.state.selectedEOPT.indexOf(row._id)
    //(getIndex,'getIndex')
    console.log(this.state.selectedEOPT,'this.state.selectedEOPT')
    if (getIndex === -1) {
      this.state.selectedEOPT.push(row._id)

      this.setState({
        selectedEOPT: this.state.selectedEOPT
      })
    }
    else {
      this.state.selectedEOPT.splice(getIndex, 1)
      this.setState({
        selectedEOPT: this.state.selectedEOPT
      })
    }

    this.setState({
      isAllSelected: this.state.selectedEOPT.length > 0 ? true : false
  })



  }

  pageClick = (currentPage) => {
    this.setState({ currentPage }, () => { this.paginationProcess() })
  }

  paginationProcess = () => {
  }



  getFuzzySearch = (search_files, mode) => {
    console.log('final search_files...', search_files, mode)
    if (mode == "1") {
      console.log("search group name")
      this.setState({ dup_labelData: search_files, dup_search_group_name: this.state.dup_temp_search_group })
    }
    if (mode == "2") {
      this.setState({ labelData: search_files, dup_search_group_name: this.state.dup_temp_search_group })
    }
    else if (mode == undefined) {
      console.log("Here")
      // this.setState({ statusBasedFilteredData: search_files, search_files: search_files , dup_search_files: this.state.temp_search_files })
      this.setState({ statusBasedFilteredData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files }, () => { this.pageClick(this.state.currentPage) })

    }
  }
  // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })    }


  handleKeyDown = (event) => {
    if (this.state.group_name_exist) {
    } else {
    }
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter" && this.state.group_name_exist) {
      event.preventDefault();
    }
  };


  handleCloseModal = () => {
    this.setState({
      show_modal: false,
      show: true,
      show_1: true,
    });
  };

  selectAllUser=(rows)=>{
    var _id_grp = []
    rows.map((ele, idx) => {
      if(ele.selected){
      _id_grp.push(ele._id)
      }
    })
    //(_id_grp,'_id_grp')
    var selectedEOPT = []
    selectedEOPT = _id_grp
    this.setState({
      selectedEOPT,
    })
  }




  render() {

    if (this.state.dataloaded) {
      console.log(this.state.selectedEOPT,'selectedEOPT')
     
      const columns = [

        {
          Header: (cellProps) => 
            <div className="form-check font-size-16" >
              <input 
              className="form-check-input" 
              onChange={() => {
                const allSelected = cellProps.page.every((row) => row.original.selected);
                console.log(allSelected, 'allSelected', cellProps.page)

                this.setState({isRowSelected:allSelected})

                // Update the selection for each row
                cellProps.page.forEach((row) => {
                  row.original.selected = !allSelected;
                }); 
                this.setState({
                  isAllSelected:cellProps.page.every((row) => row.original.selected === true)
              })
                this.selectAllUser(_.map(cellProps.page, "original"))
                
              }} 
              type="checkbox" 
              // checked={cellProps.page.length > 0 && cellProps.page.every((row) => row.original.selected)} 
              checked={cellProps.page.every((row) => row.original.selected) && (cellProps.page.length === this.state.selectedEOPT.length) && this.state.statusBasedFilteredData.length !==0}
              id="checkAll" 
              />
              <label className="form-check-label" htmlFor="checkAll"></label>
            </div>,
          accessor: '#',
          width: '20px',
          filterable: true,
          isSort:false,
          Cell: ({ row }) => (
            <div className="form-check font-size-16" >
              <input className="form-check-input" checked={
                row.original.selected && this.state.selectedEOPT.includes(row.original._id)
                } type="checkbox" onChange={(e) => { row.original.selected = !row.original.selected; this.getSelectedEOPT(row.original) }} id="checkAll" />
              <label className="form-check-label" htmlFor="checkAll"></label>
            </div>
          )
        },

        {
          accessor: 'firstname',
          Header: 'Name',
          filterable: true,
          isSort:true,
        },

        {
          accessor: 'email_id',
          Header: 'Email ID',
          filterable: true,
          isSort:true,
        },
        {
          accessor: 'phone_number',
          Header: 'Phone Number',
          filterable: true,
          isSort:true,
        },
        {
          accessor: 'status',
          Header: 'Status',
          disableFilters: true,
          isSort:true,
          Cell: (cellProps) => {
            var itemStatus = this.completedStatus(cellProps.row.original.status)[0]
            return (
              <>
                <div className="d-flex gap-3">
                  <Badge className={"font-size-12 badge-soft-" + itemStatus.badgeClass}
                    color={itemStatus.color}
                    pill
                  >
                    {itemStatus.status}
                  </Badge>
                </div>
              </>
            )
          }
        },
        {
          accessor: 'roles',
          Header: 'Roles',
          // sort: true,
          disableFilters: true,
          Cell: (cellProps) => {
            // var user = 
            var user = cellProps.row.original
            var itemStatus = this.completedStatus(cellProps.row.original.status)[0]
            // console.log(this.state.roles_info,'this.state.roles_info')
            return (
              <>


                {
                  this.state.protect_routes.read_write_checked === true ?
                    <div className="select-container">
                      <select disabled={itemStatus.status !== "Active"} defaultValue={user.role_name == null ? "Select Role" : user.role_name}  onChange={(e) => { this.select_roles(e, user) }} style={{
                        width: '200px',
                        padding: '8px',
                        borderRadius: '4px',
                        border: '1px solid #ccc'
                      }}>
                        <option disabled value={'Select Role'}>{"Select Role"}</option>
                        {
                          this.state.roles_info.map((data, idx) => (
                            <option key={idx} value={data.role_name}>{data.role_name} </option>
                          ))
                        }

                      </select>
                    </div>
                    :
                    <label>{user.role_name === undefined ? "No Role Assigned" : user.role_name}</label>
                }

              </>
            )
          }
        },
        {
          accessor: "menu",
          Header: "Action",
          disableFilters: true,
          Cell: (cellProps) => {
            var user = cellProps.row.original
            return (
              <div className="d-flex gap-1" style={{ display: 'flex', alignContent: 'center' }}>


                <UncontrolledDropdown>
                  <DropdownToggle
                    // href="#"
                    className="card-drop"
                    tag="a"
                  >
                    <i className="mdi mdi-dots-horizontal font-size-18" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end" style={{ marginTop: "-38px", width: "50%", padding: "0px" }}>
                    {/* <DropdownMenu className="dropdown-menu-end" style={{overflowY: 'auto', maxHeight: '100px'}}> */}
                    <DropdownItem
                      onClick={() => { this.navigateTo(user, 0) }}
                    >
                      <i className="mdi mdi-calendar font-size-12 text-primary me-1" />{" "}
                      View Detail
                    </DropdownItem>
                    {
                      user._id !== this.state.userInfo.user_data._id && this.state.protect_routes.read_write_checked === true &&
                      <DropdownItem
                        onClick={() => { this.activeInactive(user) }}
                      >
                        <i className="mdi mdi-account-circle font-size-12 text-info me-1" />{" "}
                        {user.active === "0" ? "Active" : "Make Inactive"}
                      </DropdownItem>
                    }
                    {
                      user._id !== this.state.userInfo.user_data._id && this.state.protect_routes.read_write_checked === true &&
                      <DropdownItem
                        onClick={() => { this.setState({ show_modal: true, selectedUser: user }) }}
                      >
                        <i className="bx bx-edit-alt font-size-12 text-info me-1" />{" "}
                        Reset Password
                      </DropdownItem>
                    }
                    {
                      this.state.protect_routes.read_write_checked === true &&
                      <DropdownItem
                        onClick={() => { this.delete_user(user) }}
                      >
                        <i className="fas fa-trash-alt font-size-12 text-danger me-1" />{" "}
                        Delete
                      </DropdownItem>
                    }

                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )
          },
        },


      ];
     





      return (
        <React.Fragment>
          <div className="page-content" style={{ minHeight: "100vh" }}>
            <MetaTags>
              <title>AuditVista | Manage Users</title>
            </MetaTags>

            <Container fluid>

            <Breadcrumbs title="Users" breadcrumbItem="Users" />

              <div className="d-xl-flex">
                <div className="w-100">
                  <div className="d-md-flex">

                    <Card className="me-md-2 " style={{maxWidth:250, height:'87vh', overflowY:"auto"}}>
                      <CardBody
                        style={{
                          maxHeight: this.state.height - 150,
                          overflow: "auto",
                        }}
                        className="overflow-auto"
                      >
                        <Row >
                          {this.state.protect_routes.read_write_checked ==
                            true && (
                              <div className="border-bottom pb-3 ">
                              <Button
                                type="button"
                                color="primary"
                                lg="12"
                                className="btn waves-effect waves-light"
                                onClick={() => {
                                  this.setState({ labelWindow: true, btn_load: false });
                                }}
                              >
                                <i className="mdi mdi-account-group text-white font-size-18" /> Create User Group
                              </Button>
                              </div>
                            )}
                        </Row>
                        <div className="my-1"></div>
                        <Row>
                          <div>
                          <h6 className="mt-4 mb-3">Groups</h6>
                          </div>
                          <div >
                            <FuzzySearch
                              search_files={this.state.search_group_name}
                              getFuzzySearch={(search_files) => this.getFuzzySearch(search_files, '2')}
                              dup_search_files={this.state.dup_search_group_name}
                              temp_search_files={this.state.dup_temp_search_group}
                              keyword={['label_name']}
                            />
                          </div>
                          <div className="mail-list mt-4">

                            <div
                              className={
                                !this.state.selectedLabel
                                  ? " btn btn-sm btn-primary col-12 border-bottom pb-2"
                                  : " btn btn-sm btn-white col-12 border-bottom pb-2"
                              }
                              style={{ textAlign:"left" }}
                              onClick={() => {
                                this.getUserList();
                                console.log("show all")
                              }}
                            >
                              &nbsp;{" "}
                              <i className="mdi mdi-circle text-secondary font-size-10" />{" "}
                              &nbsp;&nbsp;ALL
                            </div>
                            <div className="row">
                            {this.state.labelData?.map((item, idx) => {
                              return (
                                <div className="col-12 py-2 border-bottom " key={"lbl" + idx}>
                                <div                                  
                                  style={{ textAlign:"left" }}
                                  className={
                                    this.state.selectedLabel === item._id
                                      ? " btn btn-sm btn-primary col-12"
                                      : " btn btn-sm btn-white col-12"
                                  }
                                  onClick={() => {
                                    this.labelSelected(item);
                                  }}
                                >
                                  &nbsp;{" "}
                                  <i
                                    style={{ color: item.label_color }}
                                    className={
                                      this.state.selectedLabel ===
                                        item._id
                                        ? "mdi mdi-circle font-size-10"
                                        : "mdi mdi-circle font-size-10"
                                    }
                                  />{" "}
                                  &nbsp;&nbsp;{item.label_name}
                                </div>
                                </div>
                              );
                            })}
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>

                    <div className="w-100" style={{height:'87vh', overflowY:"auto", overflowX:"hidden"}}>
                      <Row>
                        <Col lg="12" className="px-2">
                          <Card>
                            <CardBody>
                              {
                                console.log(this.state.resultData,'this.state.resultData')
                              }
                              {
                                this.state.tableDataloaded ? (
                                  <>
                                    <TableContainer
                                      columns={columns}
                                      data={this.state.statusBasedFilteredData}
                                      isGlobalFilter={true}
                                      isAddOptions={false}
                                      isJobListGlobalFilter={false}
                                      customPageSize={10}
                                      style={{ width: '100%' }}
                                      selectedEOPT={this.state.selectedEOPT}
                                      protect_routes={this.state.protect_routes}
                                      search_group_name={this.state.search_group_name}
                                      getFuzzySearch={(search_files) => this.getFuzzySearch(search_files, '1')}
                                      dup_search_group_name={this.state.dup_search_group_name}
                                      dup_temp_search_group={this.state.dup_temp_search_group}
                                      dup_labelData={this.state.dup_labelData}
                                      moveToLabel={(data) => { this.moveToLabel(data) }}
                                      toggle2={(e) => this.toggle2(e)}
                                      labelDefault={this.state.labelDefault}
                                      removeFromLabel={() => { this.removeFromLabel() }}
                                      loadUserLabels={()=>{this.loadUserLabels()}}
                                      confirmDelete={() => {
                                        this.setState({
                                          confirm_both: true,
                                        });
                                      }}
                                      resultData={this.state.resultData}
                                      filterStatus={(data) => this.filterStatus(data)}
                                      show_selected={this.state.show_selected}
                                      totalEntities={this.state.totalEntities}
                                      dynamicBtn={true}
                                      isAllSelected={this.state.isAllSelected}
                                      btnClick={() => { this.navigateTo("", 1) }}
                                      btnName={"Add User"}
                                      isPagination={true}
                                      iscustomPageSizeOptions={false}
                                      filterable={false}
                                      tableClass="align-middle table-nowrap table-check"
                                      theadClass="table-light"
                                      paginationDiv="col-sm-12 col-md-7"
                                      pagination="pagination justify-content-end pagination-rounded"/>
                                  </>
                                ) : null}
                              {this.state.show_modal && (
                                <Modal
                                  size="xl"
                                  isOpen={this.state.show_modal}
                                  toggle={this.handleCloseModal}
                                  centered
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0">
                                      Change Password
                                    </h5>
                                    <button
                                      onClick={this.handleCloseModal}
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">
                                        &times;
                                      </span>
                                    </button>
                                  </div>
                                  <br />

                                  <Row>
                                    <Col md={1} className="pe-0">
                                      <label
                                        style={{ marginLeft: "17px" }}
                                      >
                                        Email ID:
                                      </label>
                                    </Col>
                                    <Col md={3} className="p-0">
                                      <label>
                                        {this.state.selectedUser.email_id}
                                      </label>
                                    </Col>
                                  </Row>
                                  <br />
                                  <AvForm
                                    style={{ marginLeft: "18px" }}
                                    className="form-horizontal"
                                    onValidSubmit={this.handleValidSubmit}
                                  >
                                    <Row>
                                      <Col xl={11}>
                                        <div className="mb-3">
                                          <AvField
                                            name="newpassword"
                                            label="New Password"
                                            className="form-control"
                                            style={{ width: "106%" }}
                                            placeholder="Enter New Password"
                                            type={
                                              this.state.show
                                                ? "password"
                                                : "text"
                                            }
                                            validate={{
                                              required: {
                                                value: true,
                                                errorMessage:
                                                  "Password is required",
                                              },
                                              minLength: {
                                                value: 8,
                                                errorMessage:
                                                  "Password must be at least 8 characters long",
                                              },
                                              // match: {
                                              //     value: "confirmpassword",
                                              //     errorMessage: "Passwords do not match",
                                              //   },
                                              pattern: {
                                                value: /^(?=.*[A-Z]).+$/,
                                                errorMessage:
                                                  "Password must contain at least one uppercase letter",
                                              },
                                            }}
                                            required
                                          />
                                          {/* <button onClick={() => this.setState({show:!this.state.show})} className="btn btn-light " type="button" id="password-addon">
                                                                            <i className={this.state.show ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i></button> */}
                                        </div>
                                      </Col>
                                      <Col
                                        xl={1}
                                        style={{ marginTop: "27px" }}
                                      >
                                        <button
                                          onClick={() =>
                                            this.setState({
                                              show: !this.state.show,
                                            })
                                          }
                                          className="btn btn-light "
                                          type="button"
                                          id="password-addon"
                                        >
                                          <i
                                            className={
                                              this.state.show
                                                ? "mdi mdi-eye-off"
                                                : "mdi mdi-eye-outline"
                                            }
                                          ></i>
                                        </button>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xl={11}>
                                        <div className="mb-3">
                                          <AvField
                                            name="confirmpassword"
                                            style={{ width: "106%" }}
                                            validate={{
                                              required: {
                                                value: true,
                                                errorMessage:
                                                  "Confirm password is required",
                                              },
                                              match: {
                                                value: "newpassword",
                                                errorMessage:
                                                  "Passwords do not match",
                                              },
                                            }}
                                            label="Confirm Password"
                                            className="form-control"
                                            placeholder="Enter Confirm Password"
                                            type={
                                              this.state.show_1
                                                ? "password"
                                                : "text"
                                            }
                                            required
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        xl={1}
                                        style={{ marginTop: "27px" }}
                                      >
                                        <button
                                          onClick={() =>
                                            this.setState({
                                              show_1: !this.state.show_1,
                                            })
                                          }
                                          className="btn btn-light "
                                          type="button"
                                          id="password-addon"
                                        >
                                          <i
                                            className={
                                              this.state.show_1
                                                ? "mdi mdi-eye-off"
                                                : "mdi mdi-eye-outline"
                                            }
                                          ></i>
                                        </button>
                                      </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col md={9}></Col>
                                      <Col md={3}>
                                        <Button
                                          type="submit"
                                          color="danger"
                                          style={{ marginLeft: "25px" }}
                                          onClick={this.handleCloseModal}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          type="submit"
                                          color="success"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          Change Password
                                        </Button>
                                      </Col>
                                    </Row>
                                  </AvForm>

                                  <br />
                                  <br />
                                </Modal>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>

                  </div>
                </div>
              </div>
            </Container>
            <Modal
              isOpen={this.state.labelWindow}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle2} tag="h5">
                Create Group
                {/* {!!isEdit && this.state.convertionMode != "2" ? "Edit Template" : !isEdit && this.state.convertionMode == "2"?"Use Template as":"Add New Template"} */}
              </ModalHeader>
              <ModalBody>
                <AvForm
                  onValidSubmit={
                    this.handleValidGroupSubmit
                  }

                // onValidSubmit={(e)=>{this.setState({btn_load:true},()=>{this.handleValidGroupSubmit()})}}
                >
                  <Row form>
                    <Col className="col-12">
                      <div className="mb-3">
                        <AvField
                          name="label_name"
                          label="Group Name"
                          type="text"
                          errorMessage="Invalid name"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => this.handleGroupName(e)}
                          onKeyPress={(e) => this.handleKeyPress(e)}

                        />
                        {this.state.group_name_exist && (
                          <label
                            className="text-danger"
                            style={{ fontSize: "smaller" }}
                          >
                            Group name already exist
                          </label>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          // type="submit"
                          className="btn btn-success save-user"
                          disabled={this.state.btn_load || this.state.group_name_exist}
                        >
                          {this.state.btn_load && <LoadingOutlined />}
                          {this.state.btn_load ? "..." : "Submit"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>

            <ReactDrawer
              open={this.state.open}
              className="drawer-open"
              position={this.state.position}
              onClose={this.onDrawerClose}
              style={{ maxHeight: 1000 }}
              noOverlay={false}
            >
              <div
                style={{
                  padding: 10,
                  maxHeight: this.state.height,
                  overflow: "auto",
                }}
              >
                {this.state.open ? (
                  <SETPERMISSION
                    userData={this.state.selectedUser}
                    onClose={this.onDrawerClose}
                  />
                ) : null}
              </div>
            </ReactDrawer>
            {this.state.confirm_both ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() =>
                  this.setState(
                    {
                      confirm_both: false,
                      success_dlg: true,
                      dynamic_title: "Deleted",
                      dynamic_description: "Your file has been deleted.",
                    },
                    function () {
                      this.deleteLabel();
                    }
                  )
                }
                onCancel={() =>
                  this.setState({
                    confirm_both: false,
                    error_dlg: true,
                    dynamic_title: "Cancelled",
                    dynamic_description: "Your imaginary file is safe :)",
                  })
                }
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </div>
        </React.Fragment>
      );
    }
    else {
      return null
    }

  }
}



export default UserLists;


























// import React, { Component } from "react";
// import PropTypes from "prop-types"
// import { connect } from "react-redux"
// import MetaTags from 'react-meta-tags';
// import { withRouter, Link } from "react-router-dom"
// import CryptoJS from 'crypto-js'
// import {
//   Container, Row, Col, Card, CardBody, CardTitle, Table, Button, Badge,
//   Progress, Pagination, PaginationItem, PaginationLink,
//   Modal, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Form, ModalHeader, ModalBody, FormGroup, Label, Input, FormFeedback, UncontrolledDropdown
// } from "reactstrap";
// import Swal from 'sweetalert2';
// import SweetAlert from "react-bootstrap-sweetalert"
// import * as Yup from 'yup';
// // import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// // import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// // import BootstrapTable from "react-bootstrap-table-next"
// import facility from '../FacilityScreen'
// import ReactDrawer from 'react-drawer';
// import 'react-drawer/lib/react-drawer.css';
// import SETPERMISSION from './Components/userPermission'
// import { AvForm, AvField } from "availity-reactstrap-validation"
// import _ from "lodash";
// import { isEmpty, size, map } from "lodash"
// import { withFormik } from "formik";
// import { TableHeader, PaginationComponent } from "../../common/DataTable"
// import FuzzySearch from '../../common/FuzzySearch';
// import { LoadingOutlined } from '@ant-design/icons';
// import TableContainer from "../../common/TableContainer";


// // var urlSocket = require("../../helpers/urlSocket");
// import urlSocket from "../../helpers/urlSocket";

// class UserLists extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       modal: false,
//       usertype: '',
//       dataloaded: false,
//       auditStatusData: [
//         { status: "Active", count: 0, color: "#fbae17", id: "1", badgeClass: "success" },
//         { status: "In active", count: 0, color: "#303335", id: "0", badgeClass: "danger" },
//       ],
//       userHLevel: "All",
//       resultData: [],
//       ddchange: false,
//       show_modal: false,
//       show: true,
//       secret_key: 'Pkdh347ey%3Tgs',
//       roles_info: [],
//       group_name_exist: false,
//       selectedEOPT: [],
//       height: window.innerHeight,

//       search_files: [],
//       dup_search_files: [],
//       temp_search_files: [],
//       currentPage: 1,
//       totalItems: 0,
//       items_per_page: 10,
//       isSaving: false,
//       btn_load: false,
//       isSubmitting: false,

//       show_1: true,
//       userList:[],

//       search_group_name: [],
//       dup_search_group_name: [],
//       dup_labelData: [],
//       dup_temp_search_group: []

//     };
//     this.onDrawerClose = this.onDrawerClose.bind(this);
//     this.handleValidGroupSubmit = this.handleValidGroupSubmit.bind(this)
//     this.toggleFolder = this.toggleFolder.bind(this);
//   }


//   async componentDidMount() {
//     var data = JSON.parse(sessionStorage.getItem("authUser"));
//     var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//     var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
//     //("data", data)
//     console.log(data,'data')
//     var protect_routes = await facility(data.facility_data, 'murs')
//     //(protect_routes, 'protect_routes')
//     if (protect_routes !== undefined) {
//       var auditData = JSON.parse(sessionStorage.getItem("auditData"));
//       this.setState(
//         {
//           userInfo: data,
//           client_info: client_info,
//           db_info: db_info,
//           configData: data.config_data,
//           dataloaded: false,
//           protect_routes: protect_routes


//         },
//         () => {
//           this.getUserList()
//         }
//       )
//     }
//     else {
//       this.props.history.push('/dashboard')
//     }
//   }

//   getRoles = (db_info) => {

//     try {
//       urlSocket.post('cog/manage-roles', { encrypted_db_url: db_info.encrypted_db_url, user_id: this.state.userInfo.user_data._id }).then((response) => {
//         //(response, 'response')
//         console.log(response,'response')
//         if (response.data.response_code == 500) {
//           this.setState({ roles_info: response.data.data, dataloaded: true })

//         }
//       })

//     } catch (error) {

//     }





//   }

//   validateData = () => {
//     var userNextLevel = _.map(_.filter(this.state.configData.hierarchy_info, ({ hierarchy_level }) => hierarchy_level >= this.state.userInfo.hierarchy_level_value), "hierarchy_level_name")
//     var hlData = ""
//     _.each(userNextLevel, (item, index) => {
//       if (index == 1) {
//         hlData = this.state.userInfo.departmentandhierarchy[0][item]
//       }
//       else if (index > 1) {
//         if (this.state.userInfo.departmentandhierarchy[0][item] != "" && this.state.userInfo.departmentandhierarchy[0][item] != undefined)
//           hlData = hlData + " / " + this.state.userInfo.departmentandhierarchy[0][item]
//       }
//     })

//     this.setState({ hlData })
//   }

//   // getUserList = () => {
//   //     try {

//   //         urlSocket.post('cog/get-user-info', {  db_info :  this.state.db_info,
//   //             user_id :this.state.userInfo.user_data._id
//   //         }).then((res) => {
//   //             //(res)
//   //             if (res.data.response_code === 500) {
//   //                 this.setState({
//   //                     dataSource: res.data.user_list,
//   //                     entitiesAuditData: res.data.user_list,
//   //                     statusBasedFilteredData: res.data.user_list,
//   //                     dataloaded: false,
//   //                     labelDefault: true,
//   //                     totalEntities: res.data.user_list.length,
//   //                     selectedLabel :'',
//   //                     search_files: res.data.user_list,
//   //                    dup_search_files: res.data.user_list,      
//   //                 }, () => this.createStatus(),this.loadUserLabels(), this.pageClick(1))



//   //             }
//   //         })


//   //     } catch (error) {

//   //     }
//   // }

//   getUserList = () => {
//     console.log("184")
//     try {
//       urlSocket.post('cog/get-user-info', { db_info: this.state.db_info, user_id: this.state.userInfo.user_data._id }).then((res) => {
//         console.log(res, 'res', 'url_1')
//         //(res)
//         if (res.data.response_code === 500) {
//           this.setState({
//             userList:res.data.user_list,
//             dataSource: res.data.user_list,
//             entitiesAuditData: res.data.user_list,
//             statusBasedFilteredData: res.data.user_list,
//             dataloaded: false,
//             labelDefault: true,
//             totalEntities: res.data.user_list.length,
//             selectedLabel: '',
//             search_files: res.data.user_list,
//             dup_search_files: res.data.user_list,
//             temp_search_files: res.data.user_list
//           }, () => this.createStatus(), this.loadUserLabels(), this.pageClick(1))
//         }
//       })
//     } catch (error) {
//       console.log(error,'error')

//     }
//   }


//   // getUserList = () => {
//   //     try {
//   //         var userInfo = {
//   //             company_id: this.state.userInfo.company_id,
//   //             usertype: this.state.userInfo.usertype,
//   //             hirerachy_level: this.state.userInfo.hierarchy_level,
//   //             hirerachyinfo: this.state.userInfo.departmentandhierarchy[0]
//   //         }
//   //         urlSocket.post("webUsers/usersList", {
//   //             userInfo: userInfo,
//   //         })
//   //             .then((response) => {
//   //                 //("response", response.data.data)
//   //                 var selectedValue = []
//   //                 var selectedValueName = []
//   //                 _.orderBy(response.data.data, ['active'], ['asc'])
//   //                 var user1 = response.data.data
//   //                 var mappingInfo = response.data.data

//   //                 var userNextLevel = _.filter(this.state.configData.hierarchy_info, ({ hierarchy_level }) => hierarchy_level < this.state.userInfo.hierarchy_level_value)

//   //                 var getUniqueRecords = _.uniqWith(mappingInfo, _.isEqual)

//   //                 var uniqueHlevels
//   //                 _.map(userNextLevel, item => {
//   //                     uniqueHlevels = _.uniqBy(getUniqueRecords, el => `${el[item.hierarchy_level_name]}`)
//   //                 })

//   //                 var uniqueNextLevels = []
//   //                 _.map(userNextLevel, item => {
//   //                     selectedValue.push("All")
//   //                     selectedValueName.push(item.hierarchy_level_name)
//   //                     uniqueNextLevels.push({ hlevelName: item.hierarchy_level_name, hlevelNameValue: item.hierarchy_level, hlevelValues: _.uniqBy(getUniqueRecords, el => `${el[item.hierarchy_level_name]}`) })
//   //                 })


//   //                 ////("uniqueHlevels", uniqueHlevels);
//   //                 this.setState({
//   //                     dataSource: response.data.data,
//   //                     entitiesAuditData: response.data.data,
//   //                     statusBasedFilteredData: response.data.data,
//   //                     uniqueNextLevels,
//   //                     selectedValue,
//   //                     selectedValueName,
//   //                     dataloaded: false,

//   //                 })
//   //                 this.createStatus()

//   //             })
//   //     } catch (error) {

//   //     }
//   // }


//   dateConvertion = (dateToConvert) => {
//     if (dateToConvert != null) {
//       var date = typeof (dateToConvert) == "object" ? String(dateToConvert.toISOString()) : String(dateToConvert)
//       var convertedDate = date.slice(8, 10) + ' / ' + (date.slice(5, 7)) + ' / ' + date.slice(0, 4);//prints expected format.
//       if (convertedDate == "01 / 01 / 1970") {
//         return "-- / -- / --"
//       }
//       else {
//         return convertedDate
//       }
//     }
//     else {
//       return "-- / -- / --"
//     }
//   }

//   completedStatus = (data) => {
//     //(data, 'data')
//     console.log(data,'data')
//     var labels = []
//     data == false ? labels.push({ status: "In active", color: "#303335", badgeClass: "danger" }) :
//       data == true ? labels.push({ status: "Active", color: "#fbae17", badgeClass: "success" }) :
//         labels.push({ status: "In active", color: "#303335", badgeClass: "danger" })
//     return labels
//   }

//   navigateTo = (userData, mode) => {
//     //(userData, 'userData')
//     if (mode === 0) {
//       sessionStorage.removeItem("selectedUser");
//       sessionStorage.setItem("selectedUser", JSON.stringify(userData));
//       this.props.history.push("/edtusr");
//     }
//     else
//       if (mode === 1) {
//         this.props.history.push("/adusrs");
//       }
//   }

//   toggle2 = (e) => {
//     this.setState(prevState => ({
//       labelWindow: !prevState.labelWindow,
//       group_name_exist: false,
//       btn_load:false
//     }))
//     e.preventDefault();
//   }


//   loadEOPTS = () => {
//     this.setState({ tableDataloaded: false })
//     try {
//       urlSocket.post("webEntities/grouplist", {
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           company_id: this.state.userInfo.client_info[0]._id,
//           user_id: this.state.userInfo.user_data._id,
//         },
//       })
//         .then((response) => {
//           //("response.data.data",response, response.data.data)
//           this.setState({
//             statusBasedFilteredData: response.data.data,
//             tableDataloaded: true,
//             allowTableRowToSelect: true,
//             labelDefault: true,
//             selectedLabel: false,
//             label: {
//               endpoints: []
//             }

//           }, function () {
//             this.loadUserLabels()
//           })

//         })
//     } catch (error) {

//     }






//   }


//   filterEntityData = (event, h_level, hlevelNameValue, selectedIndex) => {
//     this.setState({ dataloaded: false })
//     var h_levelValue = event.target.value
//     var selectedValue = this.state.selectedValue

//     // //("levels--", h_level, selectedIndex, h_levelValue, hlevelNameValue, this.state.selectedValueName, selectedValue)

//     var sourceAll = _.filter(this.state.dataSource, item => {
//       var mapInfo = _.filter(item.departmentandhierarchy, { "department_name": this.state.userInfo.departmentandhierarchy[0].department_name })[0]

//       if ((selectedIndex == 0 && h_levelValue == "All")) {
//         return item
//       }
//       else {
//         if (h_levelValue == "All") {
//           if ((selectedIndex - 1 == 0 && selectedValue[selectedIndex - 1] == "All")) {
//             return item
//           }
//           else {
//             if (mapInfo[String(this.state.selectedValueName[selectedIndex - 1])] == selectedValue[selectedIndex - 1]) {
//               return item
//             }
//           }
//         }
//         else {
//           if (mapInfo[String(h_level)] == h_levelValue) {
//             return item
//           }
//         }

//       }


//     })

//     //("source", sourceAll)

//     var mappingInfo = _.map(sourceAll, item => {
//       let h_level = _.filter(item.departmentandhierarchy, { "department_name": this.state.userInfo.departmentandhierarchy[0].department_name })[0]
//       return h_level
//     })
//     var userNextLevel = _.filter(this.state.configData.hierarchy_info, ({ hierarchy_level }) => hierarchy_level < hlevelNameValue)
//     if (userNextLevel.length > 0) {
//       var uniqueHlevels

//       _.map(userNextLevel, item => {
//         uniqueHlevels = _.uniqBy(mappingInfo, el => `${el[item.hierarchy_level_name]}`)
//       })

//       var getUniqueRecords = _.uniqWith(mappingInfo, _.isEqual)

//       var uniqueNextLevels = []
//       _.map(userNextLevel, (item, idx) => {


//         var findLevel = _.find(this.state.uniqueNextLevels, ["hlevelName", item.hierarchy_level_name]);
//         findLevel.hlevelValues = []

//         uniqueNextLevels.push({
//           hlevelName: item.hierarchy_level_name,
//           hlevelNameValue: item.hierarchy_level,
//           hlevelValues: _.uniqBy(getUniqueRecords, el => `${el[item.hierarchy_level_name]}`)
//         })


//         let index = this.state.uniqueNextLevels.findIndex(i => i.hlevelName === item.hierarchy_level_name);
//         let getuniqueLevelIndex = uniqueNextLevels.findIndex(i => i.hlevelName === item.hierarchy_level_name)
//         if (index != -1) {
//           this.state.uniqueNextLevels.splice(index, 1, uniqueNextLevels[getuniqueLevelIndex]);
//           //this.state.uniqueNextLevels[index] = uniqueNextLevels[getuniqueLevelIndex]
//         }



//         selectedValue[index] = "All"
//         selectedValue[selectedIndex] = h_levelValue
//         //_.unionBy(uniqueNextLevels, this.state.uniqueNextLevels, 'hlevelName');
//       })

//     }
//     else {
//       selectedValue[selectedIndex] = h_levelValue
//     }


//     var filteredData = this.state.dataSource
//     _.map(selectedValue, (data, idx) => {
//       // //("data", data, this.state.selectedValueName[idx])
//       filteredData = _.filter(filteredData, item => {
//         if (data == "All") {
//           return item

//         }
//         else {
//           var mapInfo = _.filter(item.departmentandhierarchy, { "department_name": this.state.userInfo.departmentandhierarchy[0].department_name })[0]
//           if (mapInfo[this.state.selectedValueName[idx]] == data) {
//             return item
//           }

//         }
//       })

//     })


//     this.setState({
//       entitiesAuditData: filteredData,
//       uniqueNextLevels: this.state.uniqueNextLevels,
//       selectedValue: selectedValue,
//       h_levelName: h_level,
//       // dataloaded: true
//     }, () => {
//       this.createStatus()
//       this.filterStatus('All')
//     })



//   }

//   toggleFolder() {
//     this.setState(prevState => ({
//       folder_Menu: !prevState.folder_Menu,
//     }))
//   }


//   createStatus = () => {
//     //(this.state.entitiesAuditData)
//     var entityStatus = _.map(_.countBy(this.state.entitiesAuditData, "active"), (val, key) => ({ status: key, count: val }))
//     //("entityStatus", entityStatus)
//     var totalEntities = 0
//     var result = []
//     result = _.each(this.state.auditStatusData, function (audititem) {
//       audititem.count = 0;
//       _.each(entityStatus, itemStatus => {
//         if (itemStatus.status == audititem.id) {
//           audititem.count = itemStatus.count
//           totalEntities = totalEntities + itemStatus.count
//         }
//       })
//     })

//     //("result", result)

//     this.setState({ resultData: result, totalEntities })
//   }


//   filterStatus = (data) => {
//     var filteredData;
//     if (data == "In active") {
//       filteredData = _.filter(this.state.entitiesAuditData, { "active": "0" })
//     }
//     else
//       if (data == "Active") {
//         filteredData = _.filter(this.state.entitiesAuditData, { "active": "1" })
//       }
//       else
//         if (data == "All") {
//           filteredData = this.state.entitiesAuditData
//         }
//     this.setState({
//       statusBasedFilteredData: filteredData,
//       search_files: filteredData,

//       show_selected: data
//     })



//   }

//   handleValidSubmit = (event, values) => {
//     //(values, 'values', this.state.selectedUser)
//     var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
//     values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//     values["db_name"] = this.state.db_info.db_name
//     values["userPoolId"] = this.state.client_info.userPoolId
//     values["clientId"] = this.state.client_info.clientId
//     values["clientId"] = this.state.client_info.clientId
//     values["password"] = password
//     values["_id"] = this.state.selectedUser._id
//     values["email_id"] = this.state.selectedUser.email_id
//     values["short_name"] = this.state.client_info.short_name
//     values["user_id"] = this.state.userInfo.user_data._id




//     try {
//       urlSocket.post('cog/reset-client-user-pwd', values).then((res) => {
//         //(res, 'response')
//         if (res.data.data.response_code === 500) {
//           Swal.fire({
//             icon: 'success',
//             title: 'Success!',
//             text: 'Your request has been processed successfully.',
//             confirmButtonColor: '#3085d6',
//             confirmButtonText: 'OK'
//           }).then((result) => {
//             //(result)
//             this.setState({ show_modal: false }, () => { this.getUserList() })

//           })

//         }
//       })
//     } catch (error) {

//     }
//   }





//   handleValidGroupSubmit = (event, values) => {
//     //(this.state.db_info, this.state.userInfo, 'userInfo')        
//     // this.setState({ btn_load: true })
//     if (!this.state.isSubmitting) {
//       if (this.state.group_name_exist === false) {
//         this.setState({ btn_load: true })
//         try {
//           urlSocket.post("userSettings/create-user-group", {
//             labelInfo: values,
//             userInfo: {
//               encrypted_db_url: this.state.db_info.encrypted_db_url,
//               _id: this.state.userInfo.user_data._id,
//               company_id: this.state.userInfo.client_info[0]._id
//             }
//           })
//             .then(response => {
//               //("response", response, response.data.data)
//               this.setState({
//                 labelData: response.data.data,
//                 dup_labelData: response.data.data,
//                 labelWindow: false,
//                 allowTableRowToSelect: true,
//                 // btn_load : false,
//                 isSubmitting: true
//               })
//             })
//         } catch (error) {
//           //("catch error", error)
//         }
//       }
//     }
//     this.setState({ isSubmitting: false })
//   }

//   handleGroupName = (event) => {
//     //(event.target.value, 'event')
//     var api_data = {}
//     api_data["group_name"] = event.target.value
//     api_data["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//     api_data["user_id"] = this.state.userInfo.user_data._id
//     // api_data["label_name"] = this.state.db_info.encrypted_db_url
//     if (event.target.value !== "") {

//       try {
//         urlSocket.post('userSettings/validate-user-group', api_data).then((response) => {
//           //(response, 'response')
//           if (response.data.data.length > 0) {
//             this.setState({ group_name_exist: true })
//           }
//           else {
//             this.setState({ group_name_exist: false })

//           }
//         })



//       } catch (error) {

//       }

//     }


//   }

//   labelSelected(data) {
//     //(data, 'data')
//     this.setState({
//       tableDataloaded: false, selectedLabel: data._id,
//       label: data
//     })


//     try {

//       urlSocket.post("userSettings/load-group-users", {
//         labelInfo: data,
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           _id: this.state.userInfo.user_data._id,
//           company_id: this.state.userInfo.client_info[0].company_id
//         }
//       })
//         .then(response => {
//           //("response", response.data.data)
//           this.setState({
//             statusBasedFilteredData: response.data.data,
//             entitiesAuditData: response.data.data,
//             search_files: response.data.data,
//             dup_search_files: response.data.data,
//             temp_search_files: response.data.data,
//             // resultData: result, totalEntities
//             labelDefault: false,
//             tableDataloaded: true
//           }, () => { this.createStatus(); this.loadUserLabels() })
//         })

//     } catch (error) {
//       //("catch error", error)
//     }


//   }


//   removeFromLabel() {


//     if (this.state.selectedEOPT.length !== 0) {
//       this.setState({ tableDataloaded: false })
//       _.each(this.state.selectedEOPT, item => {
//         var getIndex = this.state.label.group_users.indexOf(item)

//         if (getIndex !== -1) {
//           this.state.label.group_users.splice(getIndex, 1)

//         }

//       })

//       //("remove", this.state.selectedEOPT)


//       try {

//         // urlSocket.post("userSettings/move-to-group", {
//         //   eopts: this.state.label.group_users,
//         //   labelInfo: this.state.label,
//         //   userInfo: {
//         //     encrypted_db_url: this.state.db_info.encrypted_db_url,
//         //     _id: this.state.userInfo.user_data._id,
//         //     company_id: this.state.userInfo.client_info[0]._id
//         //   }
//         // })
//         //   .then(response => {
//         //     ////("response", response.data.data)
//         //     var getIndex = _.findIndex(this.state.labelData, item => { return item._id == response.data.data._id })
//         //     ////("get Index", getIndex)

//         //     this.state.labelData[getIndex] = response.data.data
//         //     //("this.state.labelData", this.state.labelData)

//         //     this.setState({
//         //       labelData: this.state.labelData,
//         //       dup_labelData: this.state.labelData,
//         //       label: response.data.data,
//         //       selectedEOPT: [],
//         //       tableDataloaded: true
//         //     }, () => {
//         //       this.labelSelected(response.data.data)
//         //     })
//         //   })

//         urlSocket.post("userSettings/move-to-group", {
//           eopts: this.state.label.group_users,
//           labelInfo: this.state.label,
//           userInfo: {
//               encrypted_db_url: this.state.db_info.encrypted_db_url,
//               _id: this.state.userInfo.user_data._id,
//               company_id: this.state.userInfo.client_info[0]._id
//           }
//       })
//           .then(response => {
//               ////("response", response.data.data)
//               var getIndex = _.findIndex(this.state.labelData, item => { return item._id == response.data.data._id })
//               ////("get Index", getIndex)
     
//               // Create a copy of labelData
//               const updatedLabelData = [...this.state.labelData];
     
//               // Update the specific item in the copy
//               updatedLabelData[getIndex] = response.data.data;
     
//               this.setState({
//                   labelData: updatedLabelData,
//                   dup_labelData: updatedLabelData,
//                   label: response.data.data,
//                   selectedEOPT: [],
//                   tableDataloaded: true
//               }, () => {
//                   this.labelSelected(response.data.data);
//               });
//           });
     




//       } catch (error) {
//         //("catch error", error)
//       }
//       //var concated = this.state.selectedEOPT.concat(data.endpoints)
//     }

//   }



//   // loadUserLabels() {
//   //     //(this.state.userInfo, 'userinfo', this.state.db_info)
//   //     try {
//   //         urlSocket.post("userSettings/get-user-group", {
//   //             userInfo: {
//   //                 _id: this.state.userInfo.user_data._id,
//   //                 encrypted_db_url: this.state.db_info.encrypted_db_url,
//   //                 company_id: this.state.userInfo.client_info[0].company_id
//   //             }
//   //         })
//   //             .then(response => {
//   //                 //(response, 'response')
//   //                 this.setState({
//   //                     labelData: response.data.data,
//   //                     dup_labelData: response.data.data,
//   //                     tableDataloaded: true,
//   //                     search_group_name :  response.data.data,
//   //                     dup_search_group_name :  response.data.data,
//   //                     // dataloaded: true,
//   //                     allowTableRowToSelect: false,
//   //                 })
//   //                 this.getRoles(this.state.db_info)
//   //             })

//   //     } catch (error) {
//   //         //("catch error", error)
//   //     }
//   // }

//   loadUserLabels() {
//     //(this.state.userInfo, 'userinfo', this.state.db_info)
//     try {
//       urlSocket.post("userSettings/get-user-group", {
//         userInfo: {
//           _id: this.state.userInfo.user_data._id,
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           company_id: this.state.userInfo.client_info[0].company_id
//         }
//       })
//         .then(response => {
//           console.log(response,'response')
//           //(response, 'response')
//           this.setState({
//             labelData: response.data.data,
//             dup_labelData: response.data.data,
//             tableDataloaded: true,
//             search_group_name: response.data.data,
//             dup_search_group_name: response.data.data,
//             dup_temp_search_group: response.data.data,
//             // dataloaded: true,
//             allowTableRowToSelect: false,
//           })
//           this.getRoles(this.state.db_info)
//         })

//     } catch (error) {
//       //("catch error", error)
//     }
//   }



//   onDrawerClose() {

//     this.setState({ open: false, dataloaded: false }, () => { this.getUserList() });

//   }

//   activeInactive(userInfo) {
//     //(userInfo, 'userInfo')
//     if (userInfo.active === "0") {
//       userInfo.active = "1"
//     }
//     else {
//       userInfo.active = "0"
//     }

//     try {
//       urlSocket.post("cog/updtactive", {
//         userInfo: userInfo,
//         db_info: this.state.db_info
//       })
//         .then((response) => {
//           //(response)
//           if (response.data.response_code == 500) {
//             this.setState({ dataloaded: false }, () => { this.getUserList() });
//           }

//         })

//     } catch (error) {
//       //("error")
//     }
//   }

//   moveToLabel(data) {
//     //(data, 'data', this.state.selectedEOPT)
//     if (this.state.selectedEOPT.length !== 0) {

//       _.each(data.group_users, item => {
//         var getIndex = this.state.selectedEOPT.indexOf(item)

//         if (getIndex !== -1) {
//           this.state.selectedEOPT.splice(getIndex, 1)

//         }

//       })
//       var concated = this.state.selectedEOPT.concat(data.group_users)

//       this.setState({ tableDataloaded: false })
//       //(concated,'concated')
//       try {

//         // urlSocket.post("userSettings/move-to-user-group", {
//         //   eopts: concated,
//         //   labelInfo: data,
//         //   userInfo: {
//         //     encrypted_db_url: this.state.db_info.encrypted_db_url,
//         //     _id: this.state.userInfo.user_data._id,
//         //     company_id: this.state.userInfo.client_info[0]._id
//         //   }
//         // })
//         //   .then(response => {
//         //     //("response",response, response.data.data)
//         //     var getIndex = _.findIndex(this.state.labelData, item => { return item._id == response.data.data._id })
//         //     this.state.labelData[getIndex] = response.data.data

//         //     this.setState({
//         //       labelData: this.state.labelData,
//         //       dup_labelData: this.state.labelData,
//         //       selectedEOPT: [],
//         //       tableDataloaded: true
//         //     })
//         //   })

//         urlSocket.post("userSettings/move-to-user-group", {
//           eopts: concated,
//           labelInfo: data,
//           userInfo: {
//               encrypted_db_url: this.state.db_info.encrypted_db_url,
//               _id: this.state.userInfo.user_data._id,
//               company_id: this.state.userInfo.client_info[0]._id
//           }
//       })
//           .then(response => {
//               //("response", response, response.data.data)
//               var getIndex = _.findIndex(this.state.labelData, item => { return item._id == response.data.data._id });
     
//               // Create a copy of labelData
//               const updatedLabelData = [...this.state.labelData];
     
//               // Update the specific item in the copy
//               updatedLabelData[getIndex] = response.data.data;
     
//               this.setState({
//                   labelData: updatedLabelData,
//                   dup_labelData: updatedLabelData,
//                   selectedEOPT: [],
//                   tableDataloaded: true
//               });
//           });
     

//       } catch (error) {
//         //("catch error", error)
//       }
//     } else {
//       // toggle()
//     }






//   }

//   //    changepwd = withFormik({

//   //    })

//   select_roles = (event, user) => {
//     //(event.target.value, 'event', user)
//     var role_name = event.target.value
//     var role_idx = this.state.roles_info.findIndex(i => i.role_name === role_name)
//     var role_id = this.state.roles_info[role_idx]._id
//     //(role_id)
//     user['role_name'] = role_name
//     user['role_id'] = role_id
//     user['encrypted_db_url'] = this.state.db_info.encrypted_db_url
//     user['db_name'] = this.state.db_info.db_name
//     user["user_id"] = this.state.userInfo.user_data._id
//     try {
//       urlSocket.post('cog/cruduser', user).then((res) => {
//         //(res, 'res')
//         if (res.data.response_code === 500) {
//           // this.getUserList()
//           //      this.setState({loading:false})
//           // this.props.history.push('/murs')
//         }
//       })

//     } catch (error) {

//     }




//   }


//   deleteLabel() {
//     try {

//       urlSocket.post("userSettings/delete-user-group", {
//         labelInfo: this.state.label,
//         userInfo: {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           _id: this.state.userInfo.user_data._id,
//           company_id: this.state.userInfo.client_info[0]._id
//         }
//       })
//         .then(response => {
//           //("response", response.data.data)

//           this.setState({
//             labelData: response.data.data,
//             dup_labelData: response.data.data,
//             labelWindow: false,
//             allowTableRowToSelect: true
//           }, function () {
//             this.loadEOPTS()
//           })
//         })

//     } catch (error) {
//       //("catch error", error)
//     }
//   }



//   delete_user = (data) => {
//     Swal.fire({
//       icon: 'question',
//       title: 'Are you sure?',
//       text: 'Do you want to delete this user ?',
//       showCancelButton: true,
//       confirmButtonColor: '#2ba92b',
//       confirmButtonText: 'Yes',
//       cancelButtonColor: '#d33',
//       cancelButtonText: 'No'
//     }).then((result) => {
//       if (result.isConfirmed) {
//         urlSocket.post('cog/delete-user', {
//           encrypted_db_url: this.state.db_info.encrypted_db_url,
//           user_id: data._id,
//           email: data.email_id,
//           client_info: this.state.userInfo.client_info[0]

//         }).then((response) => {
//           console.log(response, 'response')
//           if (response.data.response_code === 500) {
//             Swal.fire({
//               icon: 'success',
//               title: 'Success!',
//               text: `User deleted Successfully`,
//               confirmButtonColor: '#3085d6',
//               confirmButtonText: 'OK'
//             }).then((result) => {
//               if (result.isConfirmed) {
//                 this.getUserList()
//               }
//             })
//           }
//         })
//       }
//     })
//   }








//   getSelectedEOPT = (row, isSelect, rowIndex, e) => {
//     //(row, isSelect, rowIndex, e)
//     var getIndex = this.state.selectedEOPT.indexOf(row._id)
//     //(getIndex,'getIndex')
//     if (getIndex === -1) {
//       this.state.selectedEOPT.push(row._id)

//       this.setState({
//         selectedEOPT: this.state.selectedEOPT
//       })
//     }
//     else {
//       this.state.selectedEOPT.splice(getIndex, 1)
//       this.setState({
//         selectedEOPT: this.state.selectedEOPT
//       })
//     }



//   }

//   pageClick = (currentPage) => {
//     this.setState({ currentPage }, () => { this.paginationProcess() })
//   }

//   paginationProcess = () => {
//     // let { currentPage, items_per_page, search_files, dup_search_files } = this.state
//     // let result = search_files.slice((currentPage - 1) * items_per_page, (currentPage - 1) * items_per_page + items_per_page)
//     // console.log(search_files, 'search_files', result)

//     // if (result.length > 0) {
//     //   this.setState({ statusBasedFilteredData: result })
//     // } else {
//     //   this.setState({ statusBasedFilteredData: search_files, search_files })
//     // }
//   }

//   // getFuzzySearch = (search_files) => {
//   //     this.setState({ statusBasedFilteredData: search_files })
//   //     // this.setState({ search_files }, () => { this.pageClick(this.state.currentPage) })
//   // }

//   getFuzzySearch = (search_files, mode) => {
//     console.log('final search_files...', search_files, mode)
//     if (mode == "1") {
//       console.log("search group name")
//       this.setState({ dup_labelData: search_files, dup_search_group_name: this.state.dup_temp_search_group })
//     }
//     if (mode == "2") {
//       this.setState({ labelData: search_files, dup_search_group_name: this.state.dup_temp_search_group })
//     }
//     // else if (mode == undefined) {
//     //   console.log("Here")
//     //   // this.setState({ statusBasedFilteredData: search_files, search_files: search_files , dup_search_files: this.state.temp_search_files })
//     //   this.setState({ statusBasedFilteredData: search_files, search_files: search_files, dup_search_files: this.state.temp_search_files }, () => { this.pageClick(this.state.currentPage) })

//     // }
//   }

//   handleKeyDown = (event) => {
//     //(event.target.value, 'event')

//     if (this.state.group_name_exist) {
//     } else {
//     }
//     // var api_data = {}
//     // api_data["group_name"] = event.target.value
//     // api_data["encrypted_db_url"] = this.state.db_info.encrypted_db_url
//     // api_data["user_id"] = this.state.userInfo.user_data._id
//     // // api_data["label_name"] = this.state.db_info.encrypted_db_url
//     // if (event.target.value !== "") {

//     //     try {
//     //         urlSocket.post('userSettings/validate-user-group', api_data).then((response) => {
//     //             console.log(response,'url_5')
//     //             //(response, 'response')
//     //             if (response.data.data.length > 0) {
//     //                 this.setState({ group_name_exist: true })
//     //             }
//     //             else {
//     //                 this.setState({ group_name_exist: false })

//     //             }
//     //         })
//     //     } catch (error) {

//     //     }
//     // }
//   }
//   handleKeyPress = (event) => {
//     if (event.key === "Enter" && this.state.group_name_exist) {
//       event.preventDefault();
//     }
//   };


//   handleCloseModal = () => {
//     this.setState({
//       show_modal: false,
//       show: true,
//       show_1: true,
//     });
//   };


//   select_func=(isSelect,rows)=>{

//     var _id_grp = [];
//     rows.map((ele, idx) => {
//         _id_grp.push(ele._id);
//     });
//     //(_id_grp,'_id_grp')

//     // Create a copy of selectedEOPT
//     const updatedSelectedEOPT = [...this.state.selectedEOPT];
//     updatedSelectedEOPT.splice(0, updatedSelectedEOPT.length, ..._id_grp);

//     this.setState({
//         selectedEOPT: updatedSelectedEOPT
//     });

//   }



//   render() {

//     if (this.state.dataloaded) {
//       // const { SearchBar } = Search;
//       {
//         //(this.state.statusBasedFilteredData, 'statusdata')
//       }
//       // const { users } = this.state
//       console.log(this.state.statusBasedFilteredData,'this.state.statusBasedFilteredData')
//       const options = {
//         // pageStartIndex: 0,

//         sizePerPage: 10,
//         totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
//         custom: true,
//       };
//       const columns = [
//         {
//           Header: ({ rows }) => <div className="form-check font-size-16" >
//               <input className="form-check-input" onChange={() => {
//                   // Determine if all rows are currently selected
//                   const allSelected = rows.every((row) => row.original.selected);
//                   console.log(allSelected,'allSelected',rows)
//                   // Update the selection for each row
//                   rows.forEach((row) => {
//                       row.original.selected = !allSelected;
//                   }); this.select_func(!allSelected, this.state.statusBasedFilteredData, "e", '1')
//               }} type="checkbox" checked={rows.length > 0 && rows.every((row) => row.original.selected)} id="checkAll" />
//               <label className="form-check-label" htmlFor="checkAll"></label>
//           </div>,
//           accessor: '#',
//           width: '20px',
//           filterable: true,
//           Cell: ({ row }) => (
//               <div className="form-check font-size-16" >
//                   <input className="form-check-input" checked={row.original.selected} type="checkbox" onChange={(e) => { row.original.selected = !row.original.selected; this.getSelectedEOPT(row.original) }} id="checkAll" />
//                   <label className="form-check-label" htmlFor="checkAll"></label>
//               </div>
//           )
//       },
//         {
//           accessor: 'firstname',
//           Header: 'Name',
//           filterable: true,
          
//           },
      
//         {
//           accessor: 'email_id',
//           Header: 'Email ID',
//           filterable: true,
//         },
//         {
//           accessor: 'phone_number',
//           Header: 'Phone Number',
//           filterable: true,
//         },
//         {
//           accessor: 'status',
//           Header: 'Status',
//           disableFilters: true,
//           // sort: true,
//           Cell: (cellProps) => {
//             // console.log(cellProps,'cellProps')
//               var itemStatus = this.completedStatus(cellProps.row.original.status)[0]
//               return (
//                   <>
//                       <div className="d-flex gap-3">
//                           <Badge className={"font-size-12 badge-soft-" + itemStatus.badgeClass}
//                               color={itemStatus.color}
//                               pill
//                           >
//                               {itemStatus.status}
//                           </Badge>
//                       </div>
//                       </>
//               )
//           }
//       },
//       {
//         accessor: 'roles',
//         Header: 'Roles',
//           // sort: true,
//           disableFilters:true,
//           Cell: (cellProps) => {
//             // var user = 
//             var user = cellProps.row.original

//               return (
//                   <>
                      

//                       {
//                           this.state.protect_routes.read_write_checked === true ?
//                               <div className="select-container">
//                                   <select defaultValue={user.role_name == null ? "Select Role" : user.role_name} name="roles" onChange={(e) => { this.select_roles(e, user) }} style={{
//                                       fontFamily: 'Arial, sans-serif',
//                                       fontSize: '14px',
//                                       width: '25vh',
//                                       padding: '8px',
//                                       borderRadius: '4px',
//                                       border: '1px solid #ccc'
//                                   }}>
//                                       <option disabled value={'Select Role'}>{"Select Role"}</option>
//                                       {
//                                           this.state.roles_info.map((data, idx) => (
//                                               <option key={idx} value={data.role_name}>{data.role_name} </option>
//                                           ))
//                                       }

//                                   </select>
//                               </div>
//                               :
//                               <label>{user.role_name === undefined ? "No Role Assigned" : user.role_name}</label>
//                       }

//                   </>
//               )
//           }
//       },

//       this.state.protect_routes.read_write_checked === true &&
//       {
//         accessor: "menu",
//           Header: "Action",
//           disableFilters:true,
//           Cell: (cellProps) => {
//             var user = cellProps.row.original
//             return(
//               <div className="d-flex gap-1" style={{ display: 'flex', alignContent: 'center' }}>
                  

//                   <UncontrolledDropdown>
//                       <DropdownToggle
//                           // href="#"
//                           className="card-drop"
//                           tag="a"
//                       >
//                           <i className="mdi mdi-dots-horizontal font-size-18" />
//                       </DropdownToggle>
//                       <DropdownMenu className="dropdown-menu-end" style={{marginTop:"-38px", width:"50%" ,padding:"0px"}}>
//                       {/* <DropdownMenu className="dropdown-menu-end" style={{overflowY: 'auto', maxHeight: '100px'}}> */}
//                          <DropdownItem
//                             onClick={() => { this.navigateTo(user, 0) }}
//                           >
//                               <i className="mdi mdi-calendar font-size-12 text-primary me-1" />{" "}
//                               View Detail
//                           </DropdownItem>
//                           {
//                               user._id !== this.state.userInfo.user_data._id &&
//                               <DropdownItem
//                                   onClick={() => { this.activeInactive(user) }}
//                               >
//                                   <i className="mdi mdi-account-circle font-size-12 text-info me-1" />{" "}
//                                   {user.active === "0" ? "Active" : "Make Inactive"}
//                               </DropdownItem>
//                           }
//                           {
//                               user._id !== this.state.userInfo.user_data._id &&
//                               <DropdownItem
//                               onClick={() => { this.setState({ show_modal: true, selectedUser: user }) }}
//                               >
//                                   <i className="bx bx-edit-alt font-size-12 text-info me-1" />{" "}
//                                   Reset Password
//                               </DropdownItem>
//                           }
//                          <DropdownItem
//                               onClick={() => { this.delete_user(user) }}
//                           >
//                               <i className="fas fa-trash-alt font-size-12 text-danger me-1" />{" "}
//                               Delete
//                           </DropdownItem>
//                       </DropdownMenu>

//                   </UncontrolledDropdown> 


//               </div>
//               )
//           },
//       },


//       ];
//       const defaultSorted = [{
//         dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
//         order: 'desc' // desc or asc
//       }];

//       // const selectRow = {
//       //   mode: 'checkbox',
//       //   clickToSelect: true, // this.state.allowTableRowToSelect,
//       //   onSelect: (row, isSelect, rowIndex, e) => {
//       //     //("row selectedd", row, isSelect, rowIndex, e)
//       //     this.getSelectedEOPT(row, isSelect, rowIndex, e)
//       //   },
//       //   onSelectAll: (isSelect, rows, e) => {
//       //     //("select all", rows,isSelect)
//       //     var _id_grp = []
//       //     rows.map((ele, idx) => {
//       //       _id_grp.push(ele._id)
//       //     })
//       //     //(_id_grp,'_id_grp')
//       //     this.state.selectedEOPT = _id_grp
//       //     this.setState({
//       //       selectedEOPT: _id_grp
//       //     })
//       //   },
//       //   // nonSelectable: this.state.label.endpoints

//       // };

//       const selectRow = {
//         mode: 'checkbox',
//         clickToSelect: true, // this.state.allowTableRowToSelect,
//         onSelect: (row, isSelect, rowIndex, e) => {
//             //("row selectedd", row, isSelect, rowIndex, e)
//             this.getSelectedEOPT(row, isSelect, rowIndex, e);
//         },
//         onSelectAll: (isSelect, rows, e) => {
//             //("select all", rows, isSelect)
//             var _id_grp = [];
//             rows.map((ele, idx) => {
//                 _id_grp.push(ele._id);
//             });
//             //(_id_grp,'_id_grp')
   
//             // Create a copy of selectedEOPT
//             const updatedSelectedEOPT = [...this.state.selectedEOPT];
//             updatedSelectedEOPT.splice(0, updatedSelectedEOPT.length, ..._id_grp);
   
//             this.setState({
//                 selectedEOPT: updatedSelectedEOPT
//             });
//         },
//         // nonSelectable: this.state.label.endpoints
//     };
   





//       return (
//         <React.Fragment>
//           <div className="page-content" style={{ minHeight: "100vh" }}>
//             <MetaTags>
//               <title>AuditVista | Manage Users</title>
//             </MetaTags>
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "row",
//                 marginBottom: 24,
//                 alignItems: "center",
//               }}
//             >
//               <div
//                 style={{
//                   fontWeight: 600,
//                   width: "80%",
//                   padding: "0 20px",
//                   display: "flex",
//                   justifyContent: "flex-start",
//                 }}
//               >
//                 Manage Users
//               </div>
//             </div>

//             <Container fluid>
//               <div className="d-xl-flex">
//                 <div className="w-100">
//                   <div className="d-md-flex">
//                     <Card className="w-25 me-md-2  pb-3 ">
//                       <CardBody
//                         style={{
//                           maxHeight: this.state.height - 150,
//                           overflow: "auto",
//                         }}
//                         className="overflow-auto"
//                       >
//                         <Row className="mx-1">
//                           {this.state.protect_routes.read_write_checked ==
//                             true && (
//                               <Button
//                                 type="button"
//                                 color="primary"
//                                 lg="12"
//                                 className="btn waves-effect waves-light mt-2"
//                                 onClick={() => {
//                                   this.setState({ labelWindow: true, btn_load: false });
//                                 }}
//                               >
//                                 Create User Group
//                               </Button>
//                             )}
//                         </Row>
//                         <div className="my-3"></div>
//                         <Row>
//                           <h6 className="mt-4">Groups</h6>
//                           <div>
//                             <FuzzySearch
//                               search_files={this.state.search_group_name}
//                               getFuzzySearch={(search_files) => this.getFuzzySearch(search_files, '2')}
//                               dup_search_files={this.state.dup_search_group_name}
//                               temp_search_files={this.state.temp_search_files}
//                               keyword={['label_name']}
//                             />
//                             {/* <FuzzySearch
//                               search_files={this.state.search_group_name}
//                               getFuzzySearch={(search_files) => this.getFuzzySearch(search_files, '2')}
//                               dup_search_files={this.state.dup_search_group_name}
//                               temp_search_files={this.state.dup_temp_search_group}
//                               keyword={['label_name']}
//                             /> */}
//                           </div>
//                           <div className="mail-list mt-1">

//                             <div
//                               to="#"
//                               style={{ cursor: "pointer" }}
//                               className={
//                                 !this.state.selectedLabel
//                                   ? "font-size-12 py-3 border-bottom text-info "
//                                   : "border-bottom font-size-12 text-secondary py-3 "
//                               }
//                               onClick={() => {
//                                 this.getUserList();
//                               }}
//                             >
//                               &nbsp;{" "}
//                               <i className="mdi mdi-circle text-secondary font-size-10" />{" "}
//                               &nbsp;&nbsp;ALL
//                             </div>
//                             {this.state.labelData?.map((item, idx) => {
//                               return (
//                                 <div
//                                   to="#"
//                                   key={"lbl" + idx}
//                                   style={{ cursor: "pointer" }}
//                                   className={
//                                     this.state.selectedLabel === item._id
//                                       ? " font-size-12 py-3 border-bottom text-info"
//                                       : "border-bottom font-size-12 text-secondary py-3"
//                                   }
//                                   onClick={() => {
//                                     this.labelSelected(item);
//                                   }}
//                                 >
//                                   &nbsp;{" "}
//                                   <i
//                                     style={{ color: item.label_color }}
//                                     className={
//                                       this.state.selectedLabel ===
//                                         item._id
//                                         ? "mdi mdi-circle font-size-10"
//                                         : "mdi mdi-circle font-size-10"
//                                     }
//                                   />{" "}
//                                   &nbsp;&nbsp;{item.label_name}
//                                 </div>
//                               );
//                             })}
//                           </div>
//                         </Row>
//                       </CardBody>
//                     </Card>

//                     <div className="w-75">
//                       <Row>
//                         <Col lg="12">
//                           <Card>
//                             <Col lg="12" className="mt-4">
//                               <div className="button-items mb-1 ms-2 text-center">
//                                 <div
//                                   className="btn-group btn-sm"
//                                   role="group"
//                                   aria-label="Basic radio toggle button group"
//                                 >
//                                   <Button
//                                     color={"dark"}
//                                     className="btn-sm"
//                                     outline
//                                     onClick={() => this.filterStatus("All")}
//                                   >
//                                     {" "}
//                                     Total &nbsp;
//                                     {
//                                       this.state
//                                         .totalEntities
//                                     }{" "}
//                                   </Button>
//                                   {this.state.resultData.map(
//                                     (itemStatus, idx) => {
//                                       return (
//                                         <Button key={idx} color={itemStatus.badgeClass} className="btn-sm" outline onClick={() => this.filterStatus(itemStatus.status)} >
//                                           {" "}
//                                           {
//                                             itemStatus.status
//                                           }{" "}
//                                           &nbsp;&nbsp;&nbsp;
//                                           {
//                                             itemStatus.count
//                                           }{" "}
//                                         </Button>
//                                       );
//                                     }
//                                   )}
//                                 </div>
//                               </div>
//                             </Col>
//                             <Row>
//                               <Col lg="12" className="my-1 mx-4">
//                                 {this.state.protect_routes
//                                   .read_write_checked ==
//                                   true && (
//                                     <Dropdown
//                                       isOpen={
//                                         this.state.folder_Menu
//                                       }
//                                       toggle={
//                                         this.toggleFolder
//                                       }
//                                       className="btn-group  mb-2 mb-sm-0"
//                                     >
//                                       <DropdownToggle
//                                         className="btn btn-primary dropdown-toggle"
//                                         tag="i"
//                                       >
//                                         {" "}
//                                         Move to {"  "}
//                                         <i className="fa fa-folder ms-2" />{" "}
//                                         <i className="mdi mdi-chevron-down ms-1" />
//                                       </DropdownToggle>
//                                       <DropdownMenu>
//                                         <FuzzySearch
//                                           search_files={this.state.search_group_name}
//                                           getFuzzySearch={(search_files) => this.getFuzzySearch(search_files, '1')}
//                                           dup_search_files={this.state.dup_search_group_name}
//                                           keyword={['label_name']}
//                                         />


//                                         {this.state.dup_labelData.map(
//                                           (item) => {
//                                             return (
//                                               <DropdownItem
//                                                 key={""}
//                                                 to="#"
//                                                 onClick={() => {
//                                                   this.moveToLabel(
//                                                     item
//                                                   );
//                                                 }}
//                                               >
//                                                 {" "}
//                                                 {
//                                                   item.label_name
//                                                 }
//                                               </DropdownItem>
//                                             );
//                                           }
//                                         )}
//                                         <div className="dropdown-divider"></div>
//                                         <DropdownItem
//                                           to="#"
//                                           onClick={
//                                             this.toggle2
//                                           }
//                                         >
//                                           Create User Group
//                                         </DropdownItem>
//                                         {/* <DropdownItem to="#">Team Manage</DropdownItem> */}
//                                       </DropdownMenu>
//                                     </Dropdown>
//                                   )}

//                                 {!this.state
//                                   .labelDefault ? (
//                                   <>
//                                     <Button
//                                       className="btn bbtn-sm mx-2"
//                                       color="danger"
//                                       onClick={() => {
//                                         this.removeFromLabel();
//                                       }}
//                                     >
//                                       Remove selected group{" "}
//                                     </Button>
//                                     <Button
//                                       className="btn bbtn-sm "
//                                       color="danger"
//                                       onClick={() => {
//                                         this.setState({
//                                           confirm_both: true,
//                                         });
//                                       }}
//                                     >
//                                       Delete this group
//                                     </Button>
//                                   </>
//                                 ) : null}
//                               </Col>
//                             </Row>
//                             <CardBody>
//                               {this.state.tableDataloaded ? (
                              
//                               <TableContainer
//                               columns={columns}
//                               data={this.state.statusBasedFilteredData}
//                               isGlobalFilter={true}
//                               isAddOptions={false}
//                               isJobListGlobalFilter={false}
//                               customPageSize={10}
//                               style={{ width: '100%' }}
//                               dynamicBtn={true}
//                               btnClick={(param1, param2) => this.navigateTo("",1)}
//                               iscustomPageSizeOptions={true}
//                               // dynamicParams={params1,params2}
//                               isPagination={true}
//                               filterable={false}
//                               btnName ={"Add User"}
//                               tableClass="align-middle table-nowrap table-check"
//                               theadClass="table-light"
//                               pagination="pagination pagination-rounded justify-content-end mb-2"

//                           />
//      ):null} 
//                               {this.state.show_modal && (
//                                 <Modal
//                                   size="xl"
//                                   isOpen={this.state.show_modal}
//                                   toggle={this.handleCloseModal}
//                                   centered
//                                 >
//                                   <div className="modal-header">
//                                     <h5 className="modal-title mt-0">
//                                       Change Password
//                                     </h5>
//                                     <button
//                                       onClick={this.handleCloseModal}
//                                       type="button"
//                                       className="close"
//                                       data-dismiss="modal"
//                                       aria-label="Close"
//                                     >
//                                       <span aria-hidden="true">
//                                         &times;
//                                       </span>
//                                     </button>
//                                   </div>
//                                   <br />

//                                   <Row>
//                                     <Col md={1} className="pe-0">
//                                       <label
//                                         style={{ marginLeft: "17px" }}
//                                       >
//                                         Email ID:
//                                       </label>
//                                     </Col>
//                                     <Col md={3} className="p-0">
//                                       <label>
//                                         {this.state.selectedUser.email_id}
//                                       </label>
//                                     </Col>
//                                   </Row>
//                                   <br />
//                                   <AvForm
//                                     style={{ marginLeft: "18px" }}
//                                     className="form-horizontal"
//                                     onValidSubmit={this.handleValidSubmit}
//                                   >
//                                     <Row>
//                                       <Col xl={11}>
//                                         <div className="mb-3">
//                                           <AvField
//                                             name="newpassword"
//                                             label="New Password"
//                                             className="form-control"
//                                             style={{ width: "106%" }}
//                                             placeholder="Enter New Password"
//                                             type={
//                                               this.state.show
//                                                 ? "password"
//                                                 : "text"
//                                             }
//                                             validate={{
//                                               required: {
//                                                 value: true,
//                                                 errorMessage:
//                                                   "Password is required",
//                                               },
//                                               minLength: {
//                                                 value: 8,
//                                                 errorMessage:
//                                                   "Password must be at least 8 characters long",
//                                               },
//                                               // match: {
//                                               //     value: "confirmpassword",
//                                               //     errorMessage: "Passwords do not match",
//                                               //   },
//                                               pattern: {
//                                                 value: /^(?=.*[A-Z]).+$/,
//                                                 errorMessage:
//                                                   "Password must contain at least one uppercase letter",
//                                               },
//                                             }}
//                                             required
//                                           />
//                                           {/* <button onClick={() => this.setState({show:!this.state.show})} className="btn btn-light " type="button" id="password-addon">
//                                                                             <i className={this.state.show ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i></button> */}
//                                         </div>
//                                       </Col>
//                                       <Col
//                                         xl={1}
//                                         style={{ marginTop: "27px" }}
//                                       >
//                                         <button
//                                           onClick={() =>
//                                             this.setState({
//                                               show: !this.state.show,
//                                             })
//                                           }
//                                           className="btn btn-light "
//                                           type="button"
//                                           id="password-addon"
//                                         >
//                                           <i
//                                             className={
//                                               this.state.show
//                                                 ? "mdi mdi-eye-off"
//                                                 : "mdi mdi-eye-outline"
//                                             }
//                                           ></i>
//                                         </button>
//                                       </Col>
//                                     </Row>
//                                     <Row>
//                                       <Col xl={11}>
//                                         <div className="mb-3">
//                                           <AvField
//                                             name="confirmpassword"
//                                             style={{ width: "106%" }}
//                                             validate={{
//                                               required: {
//                                                 value: true,
//                                                 errorMessage:
//                                                   "Confirm password is required",
//                                               },
//                                               match: {
//                                                 value: "newpassword",
//                                                 errorMessage:
//                                                   "Passwords do not match",
//                                               },
//                                             }}
//                                             label="Confirm Password"
//                                             className="form-control"
//                                             placeholder="Enter Confirm Password"
//                                             type={
//                                               this.state.show_1
//                                                 ? "password"
//                                                 : "text"
//                                             }
//                                             required
//                                           />
//                                         </div>
//                                       </Col>
//                                       <Col
//                                         xl={1}
//                                         style={{ marginTop: "27px" }}
//                                       >
//                                         <button
//                                           onClick={() =>
//                                             this.setState({
//                                               show_1: !this.state.show_1,
//                                             })
//                                           }
//                                           className="btn btn-light "
//                                           type="button"
//                                           id="password-addon"
//                                         >
//                                           <i
//                                             className={
//                                               this.state.show_1
//                                                 ? "mdi mdi-eye-off"
//                                                 : "mdi mdi-eye-outline"
//                                             }
//                                           ></i>
//                                         </button>
//                                       </Col>
//                                     </Row>
//                                     <br />
//                                     <Row>
//                                       <Col md={9}></Col>
//                                       <Col md={3}>
//                                         <Button
//                                           type="submit"
//                                           color="danger"
//                                           style={{ marginLeft: "25px" }}
//                                           onClick={this.handleCloseModal}
//                                         >
//                                           Cancel
//                                         </Button>
//                                         <Button
//                                           type="submit"
//                                           color="success"
//                                           style={{ marginLeft: "10px" }}
//                                         >
//                                           Change Password
//                                         </Button>
//                                       </Col>
//                                     </Row>
//                                   </AvForm>

//                                   <br />
//                                   <br />
//                                 </Modal>
//                               )}
//                             </CardBody>
//                           </Card>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Container>
//             <Modal
//               isOpen={this.state.labelWindow}
//               className={this.props.className}
//             >
//               <ModalHeader toggle={this.toggle2} tag="h5">
//                 Create Group
//                 {/* {!!isEdit && this.state.convertionMode != "2" ? "Edit Template" : !isEdit && this.state.convertionMode == "2"?"Use Template as":"Add New Template"} */}
//               </ModalHeader>
//               <ModalBody>
//                 <AvForm
//                   onValidSubmit={
//                     this.handleValidGroupSubmit
//                   }

//                 // onValidSubmit={(e)=>{this.setState({btn_load:true},()=>{this.handleValidGroupSubmit()})}}
//                 >
//                   <Row form>
//                     <Col className="col-12">
//                       <div className="mb-3">
//                         <AvField
//                           name="label_name"
//                           label="Group Name"
//                           type="text"
//                           errorMessage="Invalid name"
//                           validate={{
//                             required: { value: true },
//                           }}
//                           onChange={(e) => this.handleGroupName(e)}
//                           onKeyPress={(e) => this.handleKeyPress(e)}

//                         />
//                         {this.state.group_name_exist && (
//                           <label
//                             className="text-danger"
//                             style={{ fontSize: "smaller" }}
//                           >
//                             Group name already exist
//                           </label>
//                         )}
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col>
//                       <div className="text-end">
//                         <button
//                           // type="submit"
//                           className="btn btn-success save-user"
//                           disabled={this.state.btn_load || this.state.group_name_exist}
//                         >
//                           {this.state.btn_load && <LoadingOutlined />}
//                           {this.state.btn_load ? "..." : "Submit"}
//                         </button>
//                       </div>
//                     </Col>
//                   </Row>
//                 </AvForm>
//               </ModalBody>
//             </Modal>

//             <ReactDrawer
//               open={this.state.open}
//               className="drawer-open"
//               position={this.state.position}
//               onClose={this.onDrawerClose}
//               style={{ maxHeight: 1000 }}
//               noOverlay={false}
//             >
//               <div
//                 style={{
//                   padding: 10,
//                   maxHeight: this.state.height,
//                   overflow: "auto",
//                 }}
//               >
//                 {this.state.open ? (
//                   <SETPERMISSION
//                     userData={this.state.selectedUser}
//                     onClose={this.onDrawerClose}
//                   />
//                 ) : null}
//               </div>
//             </ReactDrawer>
//             {this.state.confirm_both ? (
//               <SweetAlert
//                 title="Are you sure?"
//                 warning
//                 showCancel
//                 confirmBtnBsStyle="success"
//                 cancelBtnBsStyle="danger"
//                 onConfirm={() =>
//                   this.setState(
//                     {
//                       confirm_both: false,
//                       success_dlg: true,
//                       dynamic_title: "Deleted",
//                       dynamic_description: "Your file has been deleted.",
//                     },
//                     function () {
//                       this.deleteLabel();
//                     }
//                   )
//                 }
//                 onCancel={() =>
//                   this.setState({
//                     confirm_both: false,
//                     error_dlg: true,
//                     dynamic_title: "Cancelled",
//                     dynamic_description: "Your imaginary file is safe :)",
//                   })
//                 }
//               >
//                 You won't be able to revert this!
//               </SweetAlert>
//             ) : null}
//           </div>
//         </React.Fragment>
//       );
//     }
//     else {
//       return null
//     }

//   }
// }



// export default UserLists;