import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useState, useMemo, useRef } from 'react';
import {
    Container, Row, Col, Card, CardBody, CardTitle, Table, Button, Badge,
    Progress, Pagination, PaginationItem, PaginationLink,
    Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link, useHistory } from 'react-router-dom';
import urlSocket from '../../../../helpers/urlSocket';
// import TableContainer from '../../../../common/TableContainer'; 
import TableContainer from './mapUserTableContainer';
import { Multiselect } from 'multiselect-react-dropdown';
// import ReactDrawer from 'react-drawer';
import CrudManualCategory from './crudManualCategory';
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Swal from "sweetalert2";

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import LocationOwner from './LocationOwner';


const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const mapUserManualLocation = (props) => {
    const [locationData, setlocationData] = useState({})
    const [statusBasedFilteredData, setstatusBasedFilteredData] = useState([])
    const [roleUser,setRoleUser] = useState(null) 
    const [selectedUsers, setselectedUsers] = useState([])
    const [addedUsers, setaddedUsers] = useState([])

    const [columns, setColumns] = useState([])
    const [labelData, setlabelData] = useState([])
    const [userInfo, setUserInfo] = useState({})
    const [configData, setconfigData] = useState({})
    const [db_info, setDbInfo] = useState({})
    const [location_info, setLocationInfo] = useState({})
    const [viewUser, setviewUser] = useState({})
    const [dataloaded, setdataloaded] = useState(false)
    const [modal, setmodal] = useState(false)
    const [open, setOpen] = useState(false)
    const [position, setposition] = useState("")
    const [component, setcomponent] = useState("")
    const childCrudCategory = React.useRef(null)
    const history = useHistory()


    const getSessionInfo = async () => {
        var locationInfo = JSON.parse(sessionStorage.getItem("locationInfo"))
        var data = JSON.parse(sessionStorage.getItem("authUser"))
        var dbInfo = JSON.parse(sessionStorage.getItem("db_info"))
        setDbInfo(dbInfo)
        setUserInfo(data.user_data)
        setLocationInfo(locationInfo)
        setconfigData(data.config_data)
        //  setlocationData(locationInfo)
        retriveExistCategory(dbInfo, locationInfo)
        getUserList(data.user_data, dbInfo)
        loadUserLabels(dbInfo, data.user_data)
        // retriveExistCategory(dbInfo,locationInfo)
        console.log("mapUserManualLocatotin", locationInfo, locationData.user_level, locationData)


    }



    const updateLocationData =(locationData)=>{

        setlocationData(locationData)


    }



    const retriveExistCategory = (db_info, locationData) => {
        console.log(db_info, 'db_info', locationData)
        try {
            urlSocket.post('cog/retrive-categories', {
                encrypted_db_url: db_info.encrypted_db_url,
                location_id: locationData._id,
                manual_location: true
            }).then((response) => {
                console.log(response, 'response')
                if (response.data.response_code === 500) {
                    console.log(response.data.data[0], 'response.data.data[0]')
                    setlocationData(response.data.data[0])
                    setaddedUsers(response.data.data[0].user_path)
                    setdataloaded(true)
                }
            })
        } catch (error) {
            console.log(error, 'error')
        }
    }




    const onDrawerClose = () => {
        setOpen(false)
        //    retriveExistCategory(db_info,locationData)
        //    setdataloaded(false)
        //    setdataloaded(true)
        // getSessionInfo()

        //    console.log   
        // this.setState({ open: false, dataloaded: false }, () => { this.setState({ dataloaded: true }); this.retriveExistCategory() })
    }


    useEffect(() => {

        getSessionInfo()

        // setdataloaded(true)
    }, [])

    const getUserList = (userData, dbData) => {
        console.log(userData, 'userInfo')
        try {
            var data = {
                company_id: userData.company_id,
                encrypted_db_url: dbData.encrypted_db_url
            }

            urlSocket.post("webUsers/usersList", data).then((response) => {
                console.log(response, 'response')
                if (response.data.response_code === 500) {
                    var active_usrs = _.filter(response.data.data, { active: "1" })
                    setstatusBasedFilteredData(active_usrs)
                    // retriveExistCategory()
                    setdataloaded(true)
                }
            })



        } catch (error) {

        }
    }

    const onSelectValues = async (event, data, index) => {
        console.log(event, data, locationData, index, 'event, data, index', statusBasedFilteredData)
        var userInfo = {
            title: locationData.name,
            designation: statusBasedFilteredData[index].designation,
            name: statusBasedFilteredData[index].firstname,
            // type: this.state.nodeInfo.type,
            // parent: this.state.nodeInfo.node.id,
            user_id: statusBasedFilteredData[index]._id,
            _id: statusBasedFilteredData[index]._id,
            cat_type: data.cat_type,
            user_lvl_name: data.user_lvl_name
        }
        console.log(userInfo, 'userInfo')
        selectedUsers.push(userInfo)
        locationData["user_path"].push(userInfo)
        var location_data = { ...locationData }
        if (location_data["unique_users"] == undefined || location_data["unique_users"].length == 0) {
            console.log(location_data, 'location_data', locationData)

            location_data["unique_users"] = []
            location_data["unique_users"].push({ ...userInfo })
            location_data["unique_users"][0]["cat_type"] = [location_data["unique_users"][0]["cat_type"]]
            location_data["unique_users"][0]["user_lvl_name"] = [location_data["unique_users"][0]["user_lvl_name"]]

        }
        else {
            var find_exist_user = _.find(locationData.unique_users, { _id: userInfo._id })
            console.log(find_exist_user, 'find_exist_user')
            if (find_exist_user !== undefined) {
                find_exist_user["cat_type"].push(data.cat_type)
                find_exist_user["user_lvl_name"].push(data.user_lvl_name)
            }
            else {
                location_data["unique_users"].push({ ...userInfo })
                var find_exist_user_unique = _.findIndex(location_data.unique_users, { _id: userInfo._id })
                location_data["unique_users"][find_exist_user_unique]["cat_type"] = [location_data["unique_users"][find_exist_user_unique]["cat_type"]]
                location_data["unique_users"][find_exist_user_unique]["user_lvl_name"] = [location_data["unique_users"][find_exist_user_unique]["user_lvl_name"]]

            }
        }
        var unique_userpath = await uniqueByKeys(location_data.user_path, ['_id', 'cat_type', 'user_lvl_name'])
        location_data["user_path"] = unique_userpath
        console.log(location_data, 'location_data')
        setlocationData(location_data)
        setaddedUsers(unique_userpath)
    }


    const onRemove = async (item, idx) => {
        console.log(item, idx)
        item["_id"] = statusBasedFilteredData[idx]._id
        var removed_data = []
        _.filter(addedUsers, e => {
            if ((e.cat_type == item.cat_type) && (e._id == statusBasedFilteredData[idx]._id)) {
                console.log(e, 'e', item)
            }
            else {
                removed_data.push(e)
            }
        })
        var update_node_data = []
        locationData.user_path.map((data, idx1) => {
            if ((data.cat_type == item.cat_type) && (data._id == statusBasedFilteredData[idx]._id)) {

            }
            else {
                update_node_data.push(data)
            }
        })
        var location_data = { ...locationData }
        location_data["user_path"] = update_node_data
        var find_unique_user_idx = _.findIndex(locationData.unique_users, { _id: item._id })
        console.log(find_unique_user_idx, 'find_unique_user_idx')
        if (find_unique_user_idx !== -1) {
            var filtered_unique_user = locationData.unique_users[find_unique_user_idx].cat_type.filter(ele => ele !== item.cat_type);
            var filtered_unique_h_name = locationData.unique_users[find_unique_user_idx].user_lvl_name.filter(ele => ele !== item.user_lvl_name);

            console.log(filtered_unique_user, 'user')
            location_data.unique_users[find_unique_user_idx].cat_type = filtered_unique_user
            location_data.unique_users[find_unique_user_idx].user_lvl_name = filtered_unique_h_name

            if (filtered_unique_user.length == 0) {
                var updated_unique_userdata = locationData.unique_users.filter((ele, index) => index !== find_unique_user_idx);
                location_data["unique_users"] = updated_unique_userdata
                // console.log(updated_unique_userdata, 'updated_unique_userdata',locationData)
            }

            var unique_userpath = await uniqueByKeys(location_data.user_path, ['_id', 'cat_type', 'user_lvl_name'])
            console.log(unique_userpath, 'unique_userpath')
            location_data["user_path"] = unique_userpath

        }
        console.log(location_data, 'location_data')
        // setlocationData(location_data)
        setaddedUsers(unique_userpath)
        setlocationData(location_data)



    }

    const uniqueByKeys = (data, keys) => {
        const seen = new Set();
        return data.filter(item => {
            const key = keys.map(k => item[k]).join('-');
            if (!seen.has(key)) {
                seen.add(key);
                return true;
            }
            return false;
        });
    }




    const viewUserInfo = (data) => {
        console.log(data, 'data')
        setviewUser(data)
        setmodal(true)


    }




    useEffect(() => {
        setColumns([
            {
                accessor: 'firstname',
                Header: 'User Name',
                isSort: true,
                width: "30%",
                Cell: (cellProps) => {
                    var user_idx = _.filter(addedUsers, { _id: cellProps.row.original._id })

                    var item = cellProps.row.original
                    const filteredOptions = locationData.user_level?.filter((option) => {
                        return !user_idx.some((user) => user.cat_type === option.cat_type);
                    });
                    const highlightRow = user_idx.length > 0;
                    return (
                        <div className={`row-highlight ${highlightRow ? 'highlighted' : ''}`}>
                            <div className="d-flex " style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className=" font-size-14 ">
                                    {item.firstname}
                                </div>
                                <div className=" font-size-11 ">
                                    {item.designation}
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                accessor: 'mapping',
                Header: 'Category',
                isSort: false,
                width: "60%",
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    var index = cellProps.row.index
                    // const filteredOptions = locationData.user_level
                    // .filter((option) => {
                    //     return !user_idx.some((user) => user.cat_type === option.cat_type);
                    // });
                    var user_idx = _.filter(addedUsers, { _id: item._id })
                    user_idx.map((data, idx) => {
                        if (data.cat_type == "1") {
                            user_idx[idx]['user_lvl_name'] = "Auditor"
                        }
                        if (data.cat_type == "2") {
                            user_idx[idx]['user_lvl_name'] = "Reviewer"
                        }
                        if (data.cat_type == "3") {
                            user_idx[idx]['user_lvl_name'] = user_idx[idx]['user_lvl_name']
                        }
                    })
                    // console.log(addedUsers, 'filteredOptions',user_idx)
                    const filteredOptions = locationData.user_level?.filter((option) => {
                        return !user_idx.some((user) => user.cat_type === option.cat_type);
                    });
                    const highlightRow = user_idx.length > 0;

                    return (
                        <div className={`row-highlight ${highlightRow ? 'highlighted' : ''}`}>
                            <Multiselect
                                onRemove={(selectedList, selectedItem) => {
                                    onRemove(selectedItem, index)
                                }
                                }
                                onSelect={(selectedList, selectedItem) => {
                                    onSelectValues(selectedList, selectedItem, index);
                                }}
                                options={filteredOptions}
                                displayValue="user_lvl_name"
                                closeOnSelect={false}
                                selectedValues={user_idx}
                            />
                         </div>

                    )

                }
            },
            {
                accessor: "menu",
                Header: "Action",
                width: "10%",
                Cell: (cellProps) => {
                    var user = cellProps.row.original
                    return (
                        <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                            <Button type="button" color="primary" size="sm" className="waves-effect waves-light
                        "
                                onClick={() => { viewUserInfo(user) }}
                            >
                                User Detail
                            </Button>
                        </div>
                    )
                },
            },
            {
                accessor: "assign",
                Header: "Role for Action Plan user",
                width: "10%",
                Cell: (cellProps) => {
                    var user = cellProps.row.original
                    var user_idx = _.filter(addedUsers, { _id: user._id })                  
                    const filteredOptions = locationData.user_level?.filter((option) => {
                        return !user_idx.some((user) => user.cat_type === option.cat_type);
                    });
                    const highlightRow = user_idx.length > 0;
                    return (
                        <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                            <Button type="button" color="success" size="sm" className="waves-effect waves-light " 
                            onClick={() => {   setOpen(true);
                                    setcomponent("role");
                                    setposition("relative");
                                    setRoleUser(user)
                                }}
                                disabled={!highlightRow}
                            >
                                Assign User as
                            </Button>
                        </div>
                    )
                },
            },
        ])
        // getSessionInfo()
    }, [statusBasedFilteredData, addedUsers , roleUser])
    // const columns = useMemo(() => [



    // ], [])

    const mapSelectedUser = () => {
        console.log(locationData, 'location', childCrudCategory)
        reusableCrudApi(db_info, locationData)
        // history.push("/mels")
        // history("/mels")



    }


    const reusableCrudApi = (dbInfo, locationData) => {
        console.log(dbInfo, locationData, 'dbInfo,locationData')
        if(locationData.unique_users.length === locationData?.user_permission_acpln.length){
            try {
                urlSocket.post('cog/crud-category', {
                    confiGuration: {
                        encrypted_db_url: dbInfo.encrypted_db_url,
                        hirerachyUserlvl: locationData,
                        _id: locationData._id,
                        manual_location: true
                    }

                }).then((response) => {
                    console.log(response, 'response')
                    if (response.data.response_code === 500) {
                        history.push("/mels")
                        // setEdit(false)
                        // retriveExistCategory()
                        // props.onClose()
                    }
                })

            } catch (error) {
                console.log(error, 'error')
            }
        }
        else{
            Swal.fire({
                icon: 'error', // You can use 'warning', 'error', or any other appropriate icon
                title: 'Role Confirmation',
                text: 'Please Assign Role for the selected users !', // Customize the confirmation message
                // showCancelButton: true,
                confirmButtonColor: '#d33',
                // cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                // cancelButtonText: 'Cancel',
            }).then((result) => {



            })



        }
        // try {
        //     urlSocket.post('cog/crud-category', {
        //         confiGuration: {
        //             encrypted_db_url: dbInfo.encrypted_db_url,
        //             hirerachyUserlvl: locationData,
        //             _id: locationData._id,
        //             manual_location: true
        //         }

        //     }).then((response) => {
        //         console.log(response, 'response')
        //         if (response.data.response_code === 500) {
        //             // setEdit(false)
        //             // retriveExistCategory()
        //             // props.onClose()
        //         }
        //     })

        // } catch (error) {
        //     console.log(error, 'error')
        // }
    }

    const toggle = () => {
        setmodal(!modal)


    }


    const loadUserLabels = (dbInfo, userData) => {
        console.log(dbInfo, userData)
        try {
            urlSocket.post("userSettings/get-user-group", {
                userInfo: {
                    encrypted_db_url: dbInfo.encrypted_db_url,
                    _id: userData._id,
                    company_id: userData.company_id
                }
            })
                .then(response => {
                    console.log(response, 'response')
                    setlabelData(response.data.data)
                    // this.setState({
                    //     labelData: response.data.data,

                    // }, () => { console.log(this.state.labelData) })
                })

        } catch (error) {
        }
    }







    const labelSelected = (data) => {

        // console.log("data.target.value -->", data.target.value)

        if (data.target.value === "all") {
            getUserList(userInfo,db_info)
            // retriveExistCategory(db_info, location_info)
        }
        else {
            var mylabel = labelData[data.target.value]

            try {

                urlSocket.post("userSettings/load-group-users", {
                    labelInfo: mylabel,
                    userInfo: {
                        encrypted_db_url: db_info.encrypted_db_url,
                        _id: userInfo._id,
                        company_id: userInfo.company_id
                    }
                })
                    .then(response => {
                        console.log(response, 'response')
                        setstatusBasedFilteredData(response.data.data)
                    })
            } catch (error) {
            }

        }
    }


    const gotoBack = () => {
        props.history.push('/mels')
    }


    if (dataloaded) {
        return (
            <React.Fragment>
                <div className="page-content" style={{ minHeight: "100vh" }}>
                    <Container fluid>

                        <Breadcrumbs
                            title={"Location / " + locationData.name}
                            breadcrumbItem="Locations"
                            isBackButtonEnable={true}
                            gotoBack={() => gotoBack()}
                        />

                        <Card className="me-md-2  pb-3" >
                            <CardBody style={{ overflow: 'auto' }} >

                                <Row>
                                    <TableContainer
                                        columns={columns}
                                        data={statusBasedFilteredData}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        isJobListGlobalFilter={false}
                                        customPageSize={10}
                                        style={{ width: '100%' }}

                                        labelData={labelData}
                                        labelSelected={(data) => labelSelected(data)}
                                        newCategory={() => {
                                            setOpen(true);
                                            setcomponent("crud");
                                            setposition("relative");
                                            console.log(childCrudCategory);
                                            childCrudCategory.current?.retriveExistCategory(locationData, db_info)
                                        }
                                        }
                                        filteredDatalength={statusBasedFilteredData.length}
                                        history={history}

                                        isPagination={true}
                                        iscustomPageSizeOptions={false}
                                        filterable={false}
                                        tableClass="align-middle table-nowrap table-check"
                                        theadClass="table-light"
                                        paginationDiv="col-sm-12 col-md-7"
                                        pagination="pagination justify-content-end pagination-rounded"
                                    />
                                </Row>

                                <div className="mt-4 d-grid">
                                    <button
                                        className="btn btn-danger btn-block"
                                        type="submit"
                                        onClick={() => { mapSelectedUser() }}
                                    >
                                        Map Selected Users
                                    </button>
                                </div>


                            </CardBody>
                        </Card>




                        <Modal isOpen={modal} toggle={toggle}  >
                            <ModalHeader toggle={toggle} tag="h4">
                                User Info
                            </ModalHeader>
                            <ModalBody>
                                <AvForm className="form-horizontal"
                                //  onValidSubmit={Validsubmit}
                                >
                                    <div className="mb-3">
                                        <label>Full Name :<span className="text-danger"> *</span></label>
                                        <AvField
                                            name="firstname"
                                            value={viewUser !== undefined ? viewUser.firstname : ''}
                                            disabled={true}
                                            errorMessage="Please enter your name"
                                            className="form-control"
                                            placeholder="Enter First Name"
                                            type="text"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label>Email Id :<span className="text-danger"> *</span></label>
                                        <AvField
                                            name="email_id"
                                            value={viewUser !== undefined ? viewUser.email_id : ''}
                                            disabled={true}
                                            errorMessage="Please enter your Email ID"
                                            className="form-control"
                                            placeholder="Enter User Email"
                                            type="email"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Row>
                                            <Col className="col-12">
                                                <Row>
                                                    <label>Phone Number:<span className="text-danger"> *</span></label>
                                                    <Col md={3} className="pe-0">
                                                        <input
                                                            type="text"
                                                            value={viewUser !== undefined ? viewUser.countrycode : ''}
                                                            className="form-control"
                                                            disabled={true}
                                                        />
                                                    </Col>
                                                    <Col md={9} className="ps-0">
                                                        <AvField
                                                            name="phone_number"
                                                            className="form-control"
                                                            placeholder="Enter Phone number"
                                                            errorMessage="Please enter your Phone Number"
                                                            value={viewUser !== undefined ? viewUser.phone_number : ''}
                                                            disabled={true}
                                                            type="number"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="mb-3">
                                        <label>Username :<span className="text-danger"> *</span></label>
                                        <AvField
                                            name="username"
                                            type="text"
                                            required
                                            placeholder="Enter username"
                                            disabled={true}
                                            value={viewUser !== undefined ? viewUser.username : ''}
                                        />
                                    </div>
                                </AvForm>


                            </ModalBody>
                        </Modal>
                            {
                                open &&
                                <Drawer
                                open={open ? true : false}
                                onClose={onDrawerClose}
                                direction='right'
                                size={'500px'}
                                zIndex={9999}
                            >
                                {
                                    console.log(roleUser,'roleUser')
                                }
                                {
                                    component == "crud" ?
                                        <CrudManualCategory
                                            onClose={onDrawerClose}
                                            locationData={locationData}
                                            ref={childCrudCategory}
                                        />
                                        :
                                        component == "role" ?
                                        <LocationOwner updateLocationData={updateLocationData} roleUser={roleUser} userInfo={statusBasedFilteredData} locationData={locationData} config_data ={configData} onClose={onDrawerClose}/>
                                        :
                                        null
                                }
    
    
    
                            </Drawer>

                            }        
                      
                    </Container>
                </div>
            </React.Fragment>


        )
    }
    else {
        return null
    }
}

mapUserManualLocation.propTypes = propTypes;
mapUserManualLocation.defaultProps = defaultProps;
// #endregion

export default mapUserManualLocation;