import React, { Component } from "react";
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
    Input,
    CardText,
    CardTitle,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal, ModalBody, ModalHeader
} from "reactstrap";
// import Dropzone from "react-dropzone"
import MediaPreview from "./Components/media_preview";
import facility from '../FacilityScreen'
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"
import { Image } from 'antd'
import moment from 'moment'
import SweetAlert from "react-bootstrap-sweetalert"
import { ReactSketchCanvas } from 'react-sketch-canvas';

import ReviewOPType from "./Components/review_optype";
import PreviewImage from "./Components/preview_images";
import PreviewDocuments from "./Components/preview_documents";
import PreviewObservation from "./Components/preview_observation";
import PreviewCAPA from "./Components/preview_CAPA";

import uuid from 'react-uuid'
import SortableTree, {
    addNodeUnderParent,
    removeNodeAtPath,
    toggleExpandedForAll,
    defaultGetNodeKey,
    getTreeFromFlatData,
    getNodeAtPath,
    changeNodeAtPath,
    getFlatDataFromTree,
    walk,
    map, find
  } from 'react-sortable-tree/dist/index.cjs.js';

const _ = require('lodash')

// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from "../../../helpers/urlSocket";
import PreviewVideo from "../ManagePublishedTemplate/Components/preview_videos";

const styles = {
    border: '0.0625rem solid #9c9c9c',
    borderRadius: '0.25rem',
};

const statusText = ["Not started", "In progress", "Completed", "Retake", "Rejected", "Approved"]
const statusColor = ["#555657", "#FDA705", "#31D9AC", "#F76518", "#E22E2E", "#49AF30"]



export default class ViewCheckpoints extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataloaded: false,
            activeTab: "1",
            height: window.innerHeight,
            selectedCheckpoint: null,
            filterStatus: "all",
            showOption: false,
            attachImages: [],
            attachDocuments: [],
            selectedOption: null,
            docfileStatus: "clear",
            fileStatus: "clear",
            doc_warning_enabled: false,
            warning_enabled: false,
            issign_empty: true,
            signee: null,
            remarks: "",
            isReviewerSigned: false,
            isRemDisabled: false

        }

        this.toggle = this.toggle.bind(this)

    }

    modaltoggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    signmodaltoggle = () => {
        this.setState(prevState => ({
            signmodal: !prevState.signmodal,
        }))
    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    async componentDidMount() {
        await this.getSessionData()
        // await this.loadCheckpoints()
    }


    async getSessionData() {

        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        var endpointData = JSON.parse(sessionStorage.getItem("endpointData"));
        console.log(endpointData,'endpointData')
        var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"));
      
      
        var protect_routes = await facility(user_facilities, 'usradt')
        if (protect_routes !== undefined) {
            this.setState({
                configData: data.config_data,
                userData: data.user_data,
                endpointData,
                imagePreviewUrl: data.config_data.img_url,
                db_info: db_info,
                imageToken: data.config_data.img_url
            },()=>{
                this.loadCheckpoints()
            })
        }
        else {
            this.props.history.push('/dashboard')
        }

    }

    convertFlatDataToTreeData = async (flatData) => {
        if (flatData !== undefined) {
            var parent_data = flatData.filter(item2 => item2.parent_id === null);
            parent_data.sort(function (a, b) {
                return a.document_id - b.document_id;
            });

            const treeData = parent_data.map((parentItem) => {
                parentItem.children = this.getChildren(parentItem.document_id, flatData);
                return parentItem;
            });

            // Remove objects with empty children array
            console.log(treeData, 'treedata')
            var converted_treeDataToFlat = await this.treeDataToFlat(treeData)
            var removed_node = _.map(converted_treeDataToFlat, "node")
            console.log(converted_treeDataToFlat, 'converted_treeDataToFlat', removed_node)
            return removed_node





        }
    }

    treeDataToFlat = (treeData) => {
        var getNodeKey = ({ treeIndex }) => treeIndex
        var flatData = getFlatDataFromTree(
            {
                treeData: treeData,
                getNodeKey,
                ignoreCollapsed: false,
            })

        var explicitData = _.filter(flatData, item => {
            return item
        })

        return explicitData
    }


    getChildren = (parentId, flatData) => {
        var children = flatData.filter(item => item.parent_id === parentId);
        children.sort(function (a, b) {
            return a.document_id - b.document_id;
        });

        return children.map((childItem) => {
            childItem.children = this.getChildren(childItem.document_id, flatData);
            return childItem;
        });
    }


    async loadCheckpoints() {
        console.log(this.state.endpointData,'this.state.endpointData')
        try {

            urlSocket.post("epadtprcs/getepcheckpoints", {
                auditInfo: {
                    audit_pbd_id: this.state.endpointData.audit_pbd_id
                },
                userInfo: {
                    _id: this.state.userData._id,
                    company_id: this.state.userData.company_id,
                    encrypted_db_url : this.state.db_info.encrypted_db_url
                },
                endpointInfo: this.state.endpointData
            })
                .then(async response => {
                    var flatData= await this.convertFlatDataToTreeData(response.data.data)
                    if (response.data.response_code === 500) {
                        this.setState({
                            checkpoints: flatData
                            // _.filter(response.data.data, { document_type: "2" }),

                        }, function () {
                            this.getCheckpointStatus()
                            this.filterCheckpoints(this.state.filterStatus)
                        })
                    }

                })

        } catch (error) {
            console.log("catch error", error)
        }
    }



    getCheckpointStatus() {
        this.setState({
            nr: _.filter(this.state.checkpoints, { cp_review_status: null }).length,
            rwd: _.filter(this.state.checkpoints, item => {
                return item.cp_review_status !== null
            }).length,
            dataloaded: true
        })

    }

    filterCheckpoints(filterStatus) {
        if (filterStatus === "all") {
            this.setState({
                filteredData: this.state.checkpoints,
                showOption: false,
                //selectedCheckpoint:null,
                idx: -1
            })
        }
        else
            if (filterStatus === "ntrvd") {
                this.setState({
                    filteredData: _.filter(this.state.checkpoints, item => {
                        return item.cp_review_status === null && item.document_type == "2"
                    }),
                    showOption: false,
                    selectedCheckpoint: null,
                    idx: -1
                })
            }
            else {
                this.setState({
                    filteredData: _.filter(this.state.checkpoints, item => {
                        return item.cp_review_status === filterStatus && item.document_type == "2"
                    }),
                    showOption: false,
                    selectedCheckpoint: null,
                    idx: -1
                })
            }
    }

    loadSelectedCheckpoint(item, i) {
        this.setState({ showOption: false, optionSelected: false });
        setTimeout(() => {
            this.setState({
                selectedCheckpoint: item,
                idx: i,
                optionSelected: _.filter(item.checkpoint_options, { is_selected: true }).length !== 0 ? true : false,
                selected_option: _.filter(item.checkpoint_options, { is_selected: true })[0]
            })
            this.setState({ showOption: true });
        }, 10);
    }







    onSelected(item, value) {


        if (value === "0" || value === "-2") {
            this.setState({ modal: true, selectedItem: item, selectedValue: value })
            this.updateEndpointData()
        }
        else {
            this.setState({ selectedValue: value, reason: "" }, () => {
                item["cp_review_status"] = value

                if (value === "1") {
                    item["cp_status"] = "5";
                    item["cp_review_status"] = "1"
                }

                this.getCheckpointStatus()
                this.updateEndpointData()
                this.updateCheckpoint(item)

            })
        }

    }

    getCheckpointStatus() {
        var checkpoints = _.filter(this.state.checkpoints,{document_type :"2"})
        this.setState({
            rtk: _.filter(this.state.checkpoints, { cp_review_status: "-2" , document_type :'2' }).length,
            apvd: _.filter(this.state.checkpoints, { cp_review_status: "1", document_type :'2' }).length,
            rjd: _.filter(this.state.checkpoints, { cp_review_status: "0", document_type :'2' }).length,
            dataloaded: true
        }, () => {
            this.setState({
                rvd: this.state.rtk + this.state.apvd + this.state.rjd,
                ntrvd: checkpoints.length - (this.state.rtk + this.state.apvd + this.state.rjd)
            })

        })

    }

    // updateEndpointData = () => {
    //     if (this.state.checkpoints.length === this.state.rvd) {
    //         if (this.state.endpointData.status == "4") {
    //             this.state.endpointData.audit_cp_status = "Reviewed Completed";
    //             this.state.endpointData.review_status = "1";
    //         }
    //     }
    //     else {
    //         if (this.state.endpointData.status === "3" || this.state.endpointData.status == "4") {
    //             this.state.endpointData.audit_cp_status = "Review in progress";
    //             this.state.endpointData.status = "4";
    //             this.state.endpointData.review_status = "1";
    //         }
    //     }

    // }

    // updateEndpointData = () => {
    //     if (this.state.checkpoints.length === this.state.rvd) {
    //       if (this.state.endpointData.status === "4") {
    //         this.state.endpointData.audit_cp_status = "Reviewed Completed";
    //         this.state.endpointData.review_status = "1";
    //       }
    //     } else {
    //       if (this.state.endpointData.status === "3" || this.state.endpointData.status === "4") {
    //         this.state.endpointData.audit_cp_status = "Review in progress";
    //         this.state.endpointData.status = "4";
    //         this.state.endpointData.review_status = "1";
    //       }
    //     }
      
    //     // Assuming you want to update the state after making changes
    //     this.setState({ endpointData: this.state.endpointData });
    //   };
      
    updateEndpointData = () => {
        const { checkpoints, rvd, endpointData } = this.state;
      
        if (checkpoints.length === rvd) {
          if (endpointData.status === "4") {
            const updatedEndpointData = {
              ...endpointData,
              audit_cp_status: "Reviewed Completed",
              review_status: "1",
            };
            this.setState({ endpointData: updatedEndpointData });
          }
        } else {
          if (endpointData.status === "3" || endpointData.status === "4") {
            const updatedEndpointData = {
              ...endpointData,
              audit_cp_status: "Review in progress",
              status: "4",
              review_status: "1",
            };
            this.setState({ endpointData: updatedEndpointData });
          }
        }
      };
      



    // updateCheckpoint(item) {
    //     try {

    //         urlSocket.post("epadtprcs/reviewcheckpoints", {
    //             userInfo: {
    //                 _id: this.state.userData._id,
    //                 company_id: this.state.userData.company_id,
    //                 encrypted_db_url : this.state.db_info.encrypted_db_url
    //             },
    //             endpointInfo: this.state.endpointData,
    //             checkpointInfo: item,
    //             auditInfo: {
    //                 audit_pbd_id: this.state.endpointData.audit_pbd_id
    //             },
    //         })
    //             .then(response => {
    //                 if (response.data.response_code === 500) {
    //                     var getIndex = _.findIndex(this.state.checkpoints, { _id: response.data.data._id })
    //                     if (getIndex !== -1) {
    //                         this.state.checkpoints[getIndex] = response.data.data
    //                     }
    //                     this.setState({
    //                         checkpoints: this.state.checkpoints,
    //                     }, function () {
    //                         this.getCheckpointStatus()
    //                         this.filterCheckpoints(this.state.filterStatus)
    //                     })
    //                 }
    //             })

    //     } catch (error) {
    //         console.log("catch error", error)
    //     }
    // }


    updateCheckpoint(item) {
        try {
          urlSocket.post("epadtprcs/reviewcheckpoints", {
            userInfo: {
              _id: this.state.userData._id,
              company_id: this.state.userData.company_id,
              encrypted_db_url: this.state.db_info.encrypted_db_url
            },
            endpointInfo: this.state.endpointData,
            checkpointInfo: item,
            auditInfo: {
              audit_pbd_id: this.state.endpointData.audit_pbd_id
            },
          })
          .then(response => {
            if (response.data.response_code === 500) {
              const updatedCheckpoints = [...this.state.checkpoints];
              const updatedCheckpointIndex = updatedCheckpoints.findIndex(cp => cp._id === response.data.data._id);
              
              if (updatedCheckpointIndex !== -1) {
                updatedCheckpoints[updatedCheckpointIndex] = response.data.data;
                this.setState({
                  checkpoints: updatedCheckpoints,
                }, () => {
                  this.getCheckpointStatus();
                  this.filterCheckpoints(this.state.filterStatus);
                });
              }
            }
          });
        } catch (error) {
          console.log("catch error", error);
        }
      }
      


    // onSubmitReason(item) {

    //     if (this.state.reason === "" || this.state.reason === undefined) {
    //         this.setState({
    //             showTxtMessage: true
    //         })
    //     }
    //     this.setState({ modal: false })
    //     if (this.state.selectedValue === "0") {
    //         item["cp_status"] = "4";
    //         item["cp_review_status"] = "0"
    //     }
    //     if (this.state.selectedValue === "-2") {
    //         item["cp_status"] = "3";
    //         item["cp_review_status"] = "-2"
    //     }

    //     item["cp_review_reason"] = this.state.reason


    //     try {

    //         urlSocket.post("epadtprcs/reviewcheckpoints", {
    //             userInfo: {
    //                 _id: this.state.userData._id,
    //                 company_id: this.state.userData.company_id,
    //                 encrypted_db_url : this.state.db_info.encrypted_db_url
    //             },
    //             endpointInfo: this.state.endpointData,
    //             checkpointInfo: item,
    //             auditInfo: {
    //                 audit_pbd_id: this.state.endpointData.audit_pbd_id
    //             },
    //         })
    //             .then(response => {
    //                 if (response.data.response_code === 500) {
    //                     var getIndex = _.findIndex(this.state.checkpoints, { _id: response.data.data._id })
    //                     if (getIndex !== -1) {
    //                         this.state.checkpoints[getIndex] = response.data.data
    //                     }
    //                     this.setState({
    //                         checkpoints: this.state.checkpoints,
    //                     }, function () {
    //                         this.getCheckpointStatus()
    //                         this.filterCheckpoints(this.state.filterStatus)
    //                     })
    //                 }
    //             })

    //     } catch (error) {
    //         console.log("catch error", error)
    //     }


    // }


    onSubmitReason(item) {
        const { reason, selectedValue, userData, db_info, endpointData, checkpoints } = this.state;
      
        if (reason === "" || reason === undefined) {
          this.setState({
            showTxtMessage: true
          });
        }
      
        this.setState({ modal: false });
      
        if (selectedValue === "0") {
          item["cp_status"] = "4";
          item["cp_review_status"] = "0";
        } else if (selectedValue === "-2") {
          item["cp_status"] = "3";
          item["cp_review_status"] = "-2";
        }
      
        item["cp_review_reason"] = reason;
      
        try {
          urlSocket
            .post("epadtprcs/reviewcheckpoints", {
              userInfo: {
                _id: userData._id,
                company_id: userData.company_id,
                encrypted_db_url: db_info.encrypted_db_url
              },
              endpointInfo: endpointData,
              checkpointInfo: item,
              auditInfo: {
                audit_pbd_id: endpointData.audit_pbd_id
              },
            })
            .then((response) => {
              if (response.data.response_code === 500) {
                const updatedCheckpoints = [...checkpoints];
                const updatedCheckpointIndex = updatedCheckpoints.findIndex(
                  (cp) => cp._id === response.data.data._id
                );
      
                if (updatedCheckpointIndex !== -1) {
                  updatedCheckpoints[updatedCheckpointIndex] = response.data.data;
                  this.setState(
                    {
                      checkpoints: updatedCheckpoints,
                    },
                    () => {
                      this.getCheckpointStatus();
                      this.filterCheckpoints(this.state.filterStatus);
                    }
                  );
                }
              }
            });
        } catch (error) {
          console.log("catch error", error);
        }
      }
      






    //-------------- signature ----------------------------



    checkReviewerSigned = () => {
        var indexOfAuditSigned = _.findIndex(this.state.endpointData.audit_signature, {
            audit_user_id: this.state.userData._id,
        });
        this.setState({ isReviewerSigned: indexOfAuditSigned === -1 ? false : true }, () => {
            this.setState({
                signee: this.state.isReviewerSigned ? this.state.signee : this.state.userData.first_name,
                signeeDesignation: this.state.isReviewerSigned ? this.state.signeeDesignation : this.state.userData.designation,
                isDisabled: this.state.isReviewerSigned ? false : true,
                custom_div: true,
                signmodal: true
            })
        })

    }


    submit = () => {

        if (this.state.signee === null || this.state.signeeDesignation === null) {
            this.setState({ sign_valid: true, signboard_message: "Enter Name / Designation" })
        }
        else {
            if (!this.state.issign_empty) {

                this.setState({ signmodal: false })

                this.canvas.current.exportImage("png").then(data => {

                    this.setState({ sign_url: data })
                    var ImageURL = data
                    var block = ImageURL.split(";");
                    var contentType = block[0].split(":")[1];// In this case "image/gif"
                    var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
                    var blob = this.b64toBlob(realData, contentType);

                    this.sign_upload(blob)
                })
                    .catch((error) => { console.log("--catch error ---", error) })

            }
            else {
                this.setState({ sign_valid: true, signboard_message: "No signature in board" })
            }
        }



    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }




    clear = () => { this.canvas.current.clearCanvas(); this.setState({ sign_url: null, up_sign_url: null, issign_empty: true, sign_valid: false }) }
    undo = () => { this.canvas.current.undo() }
    redo = () => { this.canvas.current.redo() }

    deleteSignature(id) {
        var getIndex = _.findIndex(this.state.endpointData.audit_signature, { id: id })
        this.state.endpointData.audit_signature.splice(getIndex, 1)

        urlSocket.post('epadtprcs/updatesignature', {
            auditInfo: {
                audit_pbd_id: this.state.endpointData.audit_pbd_id
            },
            userInfo: {
                _id: this.state.userData._id,
                company_id: this.state.userData.company_id
            },
            endpointInfo: this.state.endpointData
        })
            .then((response) => {
                if (response.data.response_code === 500) {

                    this.setState({
                        signee: null,
                        issign_empty: true,
                        refresh: true
                    })
                }
            })

    }

    sign_upload = async (image_info) => {

        const img = new FormData()
        let u_id = uuid()

        img.append('image', image_info, u_id + 'sign.png')

        await urlSocket.post('storeImage/awswebupload', img)
            .then((res) => {
                if (res.status == 200) {
                    let splitString = res.data.data[0].key.split("/");
                    let getFileName = splitString[splitString.length - 1];
                    //this.setState({ up_sign_url: String(u_id) + 'sign.png' })
                    // console.log("uploaded _sign", res)
                    this.update_sign(u_id, getFileName)
                }
            })
    }

    // update_sign = (u_id, fileName) => {

    //     var signature = {
    //         "id": String(u_id),
    //         "name": fileName,
    //         "sign_by": this.state.signee,
    //         "designation": this.state.signeeDesignation,
    //         "preview": fileName,
    //         "uploading": false,
    //         "filetype": "image/png",
    //         "uploadingStatus": "Uploaded",
    //         "originalName": fileName,
    //         //"captured_on": new Date(),
    //         "path": fileName,
    //         "type": "image/png",
    //         "uri": fileName,
    //         "audit_user_id": !this.state.isAuditSigned ? this.state.userData._id : "",
    //         "review_user_id": "",

    //     }

    //     this.state.endpointData["audit_signature"] = this.state.endpointData["audit_signature"].concat([signature])


    //     // console.log("entity_id", this.props.entity_id)
    //     // console.log("signature", signature)
    //     urlSocket.post('epadtprcs/updatesignature', {
    //         auditInfo: {
    //             audit_pbd_id: this.state.endpointData.audit_pbd_id
    //         },
    //         userInfo: {
    //             _id: this.state.userData._id,
    //             company_id: this.state.userData.company_id
    //         },
    //         endpointInfo: this.state.endpointData
    //     })
    //         .then((response) => {
    //             if (response.data.response_code === 500) {

    //                 this.setState({
    //                     signee: null,
    //                     issign_empty: true,
    //                     refresh: true
    //                 })
    //             }
    //         })
    // }



    updateSignature = (userId, fileName) => {
        const { signee, signeeDesignation, isAuditSigned, userData, endpointData } = this.state;
      
        const signature = {
          id: String(userId),
          name: fileName,
          sign_by: signee,
          designation: signeeDesignation,
          preview: fileName,
          uploading: false,
          filetype: "image/png",
          uploadingStatus: "Uploaded",
          originalName: fileName,
          path: fileName,
          type: "image/png",
          uri: fileName,
          audit_user_id: !isAuditSigned ? userData._id : "",
          review_user_id: "",
        };
      
        // Update the audit_signature array in endpointData
        endpointData.audit_signature = [...endpointData.audit_signature, signature];
      
        // Send a POST request to update the signature
        urlSocket
          .post('epadtprcs/updatesignature', {
            auditInfo: {
              audit_pbd_id: endpointData.audit_pbd_id,
            },
            userInfo: {
              _id: userData._id,
              company_id: userData.company_id,
            },
            endpointInfo: endpointData,
          })
          .then((response) => {
            if (response.data.response_code === 500) {
              // Handle the response as needed
              this.setState({
                signee: null,
                issign_empty: true,
                refresh: true,
              });
            }
          });
      };
      


    del_sign = () => {
        this.props.del_sign(this.props.entity_id, this.props.current_data)
    }

    //-----------------------------------------------------

    validateReview() {

        var indexOfAuditSigned = _.findIndex(this.state.endpointData.audit_signature, {
            audit_user_id: this.state.userData._id,
        });

        var isrevSigned = indexOfAuditSigned === -1 ? false : true


        if (this.state.rvd !== this.state.checkpoints.length) {

            this.setState({
                showWarning: true,
                submitMessage: "Cannot submit till all the checkpoints reviewed"
            })


        }
        else {
            if (this.state.rtk > 0) {
                if (this.state.remarks !== "" && this.state.remarks.length >= 5) {
                    this.setState({
                        confirm_both: true,
                        confirmFrom: "reviewSubmit"
                    })
                }
                else {
                    this.setState({
                        showWarning: true,
                        submitMessage: "Enter remarks or remarks should be 5 or more characters"
                    })
                }
            }
            else if (this.state.rjd > 0) {
                if (this.state.remarks === "" && this.state.remarks.length < 5) {
                    this.setState({
                        showWarning: true,
                        submitMessage: "Enter remarks or remarks should be 5 or more characters"
                    })
                    return
                }
                if (!isrevSigned) {
                    this.setState({
                        showWarning: true,
                        submitMessage: "Reviewer Signature is required"
                    })
                }
                else {
                    this.setState({
                        confirm_both: true,
                        confirmFrom: "reviewSubmit"
                    })
                }
            }
            else {
                if (!isrevSigned) {
                    this.setState({
                        showWarning: true,
                        submitMessage: "Reviewer Signature is required"
                    })
                }
                else {
                    this.setState({
                        confirm_both: true,
                        confirmFrom: "reviewSubmit"
                    })
                }
            }
        }






        // else {
        //   this.setState({
        //     confirm_both: true,
        //     confirmFrom: "reviewSubmit"
        //   })
        // }

    }

    // submitReviewAudit() {

    //     var historyData = {
    //         "submitted_by": this.state.userData._id,
    //         "submitted_on": new Date(),
    //         "remarks": this.state.remarks
    //     }

    //     this.state.endpointData.ep_review_history.push(historyData)
    //     this.state.endpointData.updated_on = new Date();
    //     this.state.endpointData.audit_reviewed_on = new Date();

    //     if (this.state.rtk > 0) {
    //         this.state.endpointData.status = "5";
    //         this.state.endpointData.audit_cp_status = "Retake";
    //     }
    //     else {
    //         this.state.endpointData.status = "7";
    //         this.state.endpointData.audit_cp_status = "Reviewed";
    //     }


    //     this.state.endpointData.review_status = "1";

    //     this.setState({
    //         endpointData: this.state.endpointData
    //     }, () => {
    //         try {

    //             urlSocket.post("epadtprcs/updatereviewendpoint", {
    //                 auditInfo: {
    //                     audit_pbd_id: this.state.endpointData.audit_pbd_id
    //                 },
    //                 userInfo: {
    //                     _id: this.state.userData._id,
    //                     company_id: this.state.userData.company_id
    //                 },
    //                 endpointInfo: this.state.endpointData
    //             })
    //                 .then(response => {
    //                     if (response.data.response_code === 500) {
    //                         this.props.history.push("/usrenpdts");
    //                     }

    //                 })

    //         } catch (error) {
    //             console.log("catch error", error)
    //         }
    //     })





    // }

    submitReviewAudit() {
        const {
          userData,
          remarks,
          rtk,
          endpointData
        } = this.state;
      
        const historyData = {
          submitted_by: userData._id,
          submitted_on: new Date(),
          remarks: remarks
        };
      
        endpointData.ep_review_history.push(historyData);
        endpointData.updated_on = new Date();
        endpointData.audit_reviewed_on = new Date();
      
        if (rtk > 0) {
          endpointData.status = "5";
          endpointData.audit_cp_status = "Retake";
        } else {
          endpointData.status = "7";
          endpointData.audit_cp_status = "Reviewed";
        }
      
        endpointData.review_status = "1";
      
        this.setState({
          endpointData: endpointData
        }, () => {
          try {
            urlSocket.post("epadtprcs/updatereviewendpoint", {
              auditInfo: {
                audit_pbd_id: endpointData.audit_pbd_id
              },
              userInfo: {
                _id: userData._id,
                company_id: userData.company_id
              },
              endpointInfo: endpointData
            })
            .then(response => {
              if (response.data.response_code === 500) {
                this.props.history.push("/usrenpdts");
              }
            });
          } catch (error) {
            console.log("catch error", error);
          }
        });
      }
      


    editReason(item) {

        this.setState({
            modal: true,
            selectedItem: item,
            reason: item.cp_review_reason
        })

    }

    retakeAuditConfirm() {
        this.setState({
            confirm_both: true,
            confirmFrom: "retakeSubmit"
        })
    }

    submitReviewAll = (review_status) => {
        this.state.checkpoints.forEach(item => {
            if (review_status === "1") {
                item.cp_status = "5";
                item.cp_review_status = "1"
            }

            if (review_status === "0") {
                item.cp_status = "4";
                item.cp_review_status = "0"
            }

            if (review_status === "-2") {
                item.cp_status = "3";
                item.cp_review_status = "-2";
            }
            item.cp_reviewed_on = new Date()
            item.updated_on = new Date();
            item.cp_review_by_id = this.state.userData._id
        })

        this.setState({ checkpoints: this.state.checkpoints }, () => {
            var rtk = _.filter(this.state.checkpoints, { cp_review_status: "-2" }).length
            var apvd = _.filter(this.state.checkpoints, { cp_review_status: "1" }).length
            var rjd = _.filter(this.state.checkpoints, { cp_review_status: "0" }).length
            var rvd = rtk + apvd + rjd
            this.setState({
                rtk, apvd, rjd, rvd
            }, () => {
                this.updateEndpointData()
                this.updateReviewAll()
            })


        })

    }

    updateReviewAll = () => {
        try {

            urlSocket.post("epadtprcs/updateAllCheckpoints", {
                userInfo: {
                    _id: this.state.userData._id,
                    company_id: this.state.userData.company_id,
                    encrypted_db_url : this.state.db_info.encrypted_db_url
                },
                endpointInfo: this.state.endpointData,
                checkpoints: this.state.checkpoints,
                auditInfo: {
                    audit_pbd_id: this.state.endpointData.audit_pbd_id
                },
            })
                .then(response => {
                    if (response.data.response_code === 500) {
                        var responseCheckpoints = response.data.data
                        this.setState({
                            checkpoints: _.filter(responseCheckpoints, { document_type: "2" }),
                        }, () => {
                            this.filterCheckpoints(this.state.filterStatus)
                        })

                    }
                })

        } catch (error) {
            console.log("catch error", error)
        }
    }

    render() {
        if (this.state.dataloaded) {
            this.canvas = React.createRef();
            var checkpoints = _.filter(this.state.checkpoints,{document_type :"2"})
            return (
                <React.Fragment>
                    <div className="page-content" >
                        <MetaTags>
                            <title>AuditVista | Review Check points</title>
                        </MetaTags>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-1">
                            <div style={{fontWeight: 600, width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
                                Review Check points
                            </div>
                            <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Link to="/usrenpdts"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
                            </div>
                        </div>
                        <Container fluid>

                            <Card body style={{marginBottom:"4px"}}>
                                <Row>
                                    <Col lg={4}>
                                        <CardTitle className="h3 mt-0">
                                            {this.state.endpointData.audit_pbd_name}
                                        </CardTitle>
                                        <CardText>
                                            {this.state.endpointData.name}{" / "}{this.state.endpointData.code}
                                        </CardText>
                                    </Col>

                                    {/* <Row> */}
                                    <Col lg={8} style={{alignSelf: 'center'}}>
                                        <div>
                                            <Link to="#" className="badge badge-soft-dark font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "all" }); this.filterCheckpoints("all") }}>
                                                Total check points{" - "}{checkpoints.length}
                                            </Link>
                                            <Link to="#" className="badge badge-soft-secondary font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "ntrvd" }); this.filterCheckpoints("ntrvd") }}>
                                                Not Reviewed{" - "}{this.state.ntrvd}
                                            </Link>
                                            <Link to="#" className="badge badge-soft-dark font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "r" }); this.filterCheckpoints("-2") }}>
                                                Retake{" - "}{this.state.rtk}
                                            </Link>
                                            <Link to="#" className="badge badge-soft-success font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "r" }); this.filterCheckpoints("1") }}>
                                                Approved{" - "}{this.state.apvd}
                                            </Link>
                                            <Link to="#" className="badge badge-soft-danger font-size-14 me-3" onClick={() => { this.setState({ filterStatus: "r" }); this.filterCheckpoints("0") }}>
                                                Rejected{" - "}{this.state.rjd}
                                            </Link>
                                        </div>
                                    </Col>

                                    {/* </Row> */}



                                </Row>
                            </Card>

                            <div className="d-xl-flex">
                                <div className="w-100">
                                    <div className="d-md-flex">

                                        {/* 1st block */}

                                        <div className="w-100 pb-3 me-md-1 overflow-auto" style={{ maxHeight: this.state.height - 250 }} >

                                            <div className="d-flex flex-column">

                                                {
                                                    this.state.filteredData.map((item, i) => {
                                                        // console.log("item", item)
                                                        return (
                                                            <>
                                                            {
                                                                item.document_type == "2" ?
                                                            <Card key={i + "cpoints"} className="mb-1">
                                                                <CardBody>
                                                                    <div className="mb-2">
                                                                        <label className={item.cp_review_status !== null ? "badge badge-soft-primary font-size-10 me-2" : item.cp_status === "0" ? "badge badge-soft-secondary font-size-10 me-2" : item.cp_status === "1" ? "badge badge-soft-warning font-size-10 me-2" : "badge badge-soft-success font-size-10 me-2"}>{item.cp_review_status !== null ? "Reviewed" : item.cp_status === "0" ? "Not started" : item.cp_status === "1" ? "In progress" : "Completed"}</label>
                                                                        <label className={item.cp_is_compliance ? "badge badge-soft-danger font-size-10 me-2" : "badge badge-soft-success font-size-10 me-2"}>{item.cp_compliance.name}</label>
                                                                        <span
                                                                            className="badge badge-soft font-size-10"
                                                                            style={{ backgroundColor: statusColor[Number(item.cp_status)] }}
                                                                        >
                                                                            {statusText[Number(item.cp_status)]}</span>
                                                                    </div>
                                                                    <div className="mb-1">
                                                                        <span className="font-size-14 fw-bold">{item.checkpoint}</span>
                                                                    </div>


                                                                    {
                                                                        item.checkpoint_type_id === "1" || item.checkpoint_type_id === "2" ||item.checkpoint_type_id === "3" ||item.checkpoint_type_id === "4" || item.checkpoint_type_id === "5"?
                                                                            <ReviewOPType
                                                                                options={item.checkpoint_options}

                                                                            />
                                                                            : null
                                                                    }

                                                                    {
                                                                        item.cp_attach_images.length !== 0 ?
                                                                            <div className="mt-3 mb-4">
                                                                                <label>Images Attached</label>
                                                                                <PreviewImage
                                                                                    imagePreviewUrl={this.state.imagePreviewUrl}
                                                                                    images={item.cp_attach_images}
                                                                                />
                                                                            </div> : null
                                                                    }
                                                                    {
                                                                        console.log(item,'item')
                                                                    }
                                                                
                                                                {
                                                                        item.cp_attach_videos.length !== 0 ?
                                                                            <div className="mt-3 mb-4">
                                                                                <label>Videos Attached</label>
                                                                                <PreviewVideo
                                                                                    imagePreviewUrl={this.state.imagePreviewUrl}
                                                                                    videos={item.cp_attach_videos}
                                                                                />
                                                                            </div> : null
                                                                    }

                                                                    {
                                                                        item.cp_documents.length !== 0 ?
                                                                            <div className="my-4">
                                                                                <label>Documents Attached</label>
                                                                                <PreviewDocuments
                                                                                    imagePreviewUrl={this.state.imagePreviewUrl}
                                                                                    images={item.cp_documents}
                                                                                />
                                                                            </div> : null

                                                                    }

                                                                    {
                                                                        item.cp_observation !== null ?
                                                                            <div className="my-4">
                                                                                <label>Observation</label>
                                                                                <PreviewObservation
                                                                                    observation={item.cp_observation}
                                                                                />
                                                                            </div> : null
                                                                    }

                                                                    {
                                                                        item.cp_actionplans.length !== 0 ?
                                                                            <div className="my-4">
                                                                                <label>CAPA</label>
                                                                                <PreviewCAPA
                                                                                    actionplans={item.cp_actionplans}
                                                                                />
                                                                            </div> : null
                                                                    }

                                                                    {
                                                                        item.cp_review_status === "0" || item.cp_review_status === "-2" ?
                                                                            <div>
                                                                                <label>Reason </label> {this.state.endpointData.status === "3" ? <Link to="#" onClick={() => this.editReason(item)}><i className="mdi mdi-pencil font-size-20 text-primary" /></Link> : null}
                                                                                <p>{item.cp_review_reason}</p>
                                                                            </div> : null
                                                                    }


                                                                </CardBody>
                                                            </Card>
                                                            :
                                                            <div style={{backgroundColor :item.parent_id ==null? '#525252' :"#808080"}} className="p-2 my-1">
                                                            <div className="d-flex">
                                                              <div className="overflow-hidden me-auto">
                                                                <h5 className="font-size-13  mb-1">
                                                                  <span style={{color:'#fffffff5'}}>{item.checkpoint}</span>
                                                                </h5>
                                                              </div>
                                                            </div>
                                                          </div>

                                                            }

                                                            </>
                                                        )
                                                    })
                                                }

                                            </div>

                                        </div>



                                        {/* 2nd block */}
                                        <Card className="filemanager-sidebar mb-1 " body>
                                            {
                                                <div className="my-2">
                                                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                                                        <div className="" >
                                                            <label>Remarks</label>
                                                            {
                                                                this.state.endpointData.ep_review_history.length > 0 &&
                                                                this.state.endpointData.ep_review_history.map((item, idx) => {
                                                                    return (
                                                                        <div key={"rmks" + String(idx)} className="mb-2">
                                                                            <div>{moment(item.submitted_on).format("DD/MM/YYYY")}</div>
                                                                            <div>{item.remarks}</div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }                                                            
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            }
                                            {
                                                this.state.endpointData.audit_signature.length !== 0 ?
                                                    <div>
                                                        {
                                                            this.state.endpointData.audit_signature.map((item, i) => {
                                                                return (
                                                                    <Card
                                                                        className="mt-1 mb-1 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={i + "-signfile"}
                                                                    >
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                                                                            <div className="p-2 col-10" >
                                                                                <label className="mb-1 font-size-11">{item.sign_by}<br />{item.designation}</label>
                                                                                <img height="80" src={this.state.imagePreviewUrl + item.name} />
                                                                            </div>
                                                                        </div>
                                                                    </Card>

                                                                )
                                                            })
                                                        }
                                                    </div> : null
                                            }

                                        </Card>

                                    </div>
                                </div>
                            </div>

                        </Container>
                    </div>
                </React.Fragment>
            )
        }
        else {
            return null
        }

    }

}
