import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'



const _ = require('lodash')
var urlSocket = require("../../../../helpers/urlSocket")

export default class UserPermission extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataloaded: false,
            alertEnable: false,
            formDisabled: false,
            userInfo: {}

        };


    }

    componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        this.setState(
            {
                sessionUserInfo: data.user_data,
                configData: data,
                permissionsadded: [],
                db_info:db_info
            },
             ()=>{
                 this.getUserPermissions()
                
            }
        )

    }

    getUserPermissions(){
        this.props.userData.facilities.map((item,idx)=>{
            this.state.permissionsadded.push(item)
        })
        this.setState({ dataloaded: true})
        
    
        }
    

    //  getUserPermissions(){

    //     _.each(this.state.configData.facilities, item=>{

    //         var getIndex = _.findIndex(this.props.userData.facilities, {id:item.id})
    //         if(getIndex !== -1)
    //         {
    //             item["enabled"] = true
    //             this.state.permissionsadded.push(item)
    //         }
    //         else
    //         {
    //             item["enabled"] = false
    //             this.state.permissionsadded.push(item)
    //         }

    //     })
    //     this.setState({
    //         dataloaded: true,
    //     })

    // }


    // addPermissions(e, item) {
    //      var getIndex = _.findIndex(this.state.permissionsadded, { id: item.id })

    //     if (item.enabled) {
    //         item.enabled = false
    //        this.state.permissionsadded[getIndex] = item
    //         this.setState({
    //             permissionsadded: this.state.permissionsadded
    //         })
    //     }
    //     else {
    //         item.enabled = true
    //         this.state.permissionsadded[getIndex] = item
    //         this.setState({
    //             permissionsadded: this.state.permissionsadded
    //         })
    //     }
    // }

    // addPermissions(e, item, idx) {
    //     this.state.permissionsadded[idx].enabled = e.target.checked
    //     this.setState({ permissionsadded: this.state.permissionsadded })
    // }

    addPermissions(e, item, idx) {
        // Create a copy of permissionsadded
        const updatedPermissions = [...this.state.permissionsadded];
    
        // Update the specific item in the copy
        updatedPermissions[idx].enabled = e.target.checked;
    
        // Update the state with the new permissionsadded array
        this.setState({ permissionsadded: updatedPermissions });
    }
    


    // updatePermissions = () => {
    //     try {
    //         var loggedUserInfo = {
    //             company_id: this.state.sessionUserInfo.company_id,
    //             company_name: this.state.sessionUserInfo.company_name,
    //             _id:this.state.sessionUserInfo._id
    //         }
    //         urlSocket.post("webUsers/updatepermissions", {
    //             updateUserInfo:this.props.userData,
    //             loggedUserInfo: loggedUserInfo,
    //             permissionInfo:_.filter(this.state.permissionsadded, {enabled:true})
    //         })
    //             .then((response) => {
    //                 if (response.data.response_code == 504) {
    //                     this.setState({ formDisabled: false, alertEnable: true, alertColor: "danger", message: response.data.message })
    //                 }
    //                 else
    //                     if (response.data.response_code == 500) {
    //                         this.setState({
    //                             permissionsadded:[]
    //                         }, ()=>{
    //                             this.props.onClose()
    //                         })
    //                     }
    //             })
    //     } catch (error) {

    //     }
    // }


    updatePermissions = () => {

        try {
            urlSocket.post("cog/updatepermissions", {
                user_access:this.state.permissionsadded,
                user_info:this.props.userData,
                db_info:this.state.db_info
            }).then((res)=>{
                if(res.data.response_code === 500){
                    this.setState({permissionsadded:[]},()=>{
                        this.props.onClose()
                    })
                }
            })

        } catch (error) {
            
        }
        
    }





    render() {
        if (this.state.dataloaded) {
            return (
                <React.Fragment>
                    <Container fluid>
                        <Row >
                            <Col >

                                <div className="p-5">
                                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


                                        <Row className="my-4">
                                        <div className="d-sm-flex align-items-center justify-content-between">
                                        <div className="text-danger font-size-18">Permissions to access</div>
                                                <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
                                            </div>
                                            <hr className="my-4" />
                                        </Row>

                                        <Row>

                                            {
                                                this.state.permissionsadded.map((item, idx) => {
                                                    return (
                                                        <Col className="col-auto" key={"pn" + idx}>
                                                            <div className="form-check mb-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={"pmsn" + idx}
                                                                    onChange={(e) => { this.addPermissions(e, item,idx) }}
                                                                    checked={item.enabled}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={"pmsn" + idx}
                                                                >
                                                                    {item.interfacename}
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }

                                        </Row>
                                        <Row >
                                            <Button  className="my-4" color="warning" onClick={()=>{this.updatePermissions()}}>Update</Button>
                                        </Row>

                                    </AvForm>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            )
        }
        else {
            return null
        }
    }

}