import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Offcanvas,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Badge,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Collapse,
    Alert
} from "reactstrap";
import moment from 'moment'
import _, { isEmpty, map, } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Modal, Image } from "antd";
import { Timeline, ConfigProvider } from 'antd';
import classnames from "classnames";
import CreateNewUser from "./createNewUser";
import ImportUser from "./ImportUser"
import UserRoleInformation from "./userRoleInformation";
import Drawer from 'react-modern-drawer'
import { removeAplnUser,setSelectedUser } from '../../../store/aplnfollowup/aplnflwupslice'
// import { setSelectedUser } from "../../../store/aplnfollowup/aplnflwupslice";


const TaskUsers = ({
    selectedCheckpoint,
    selectedApln,
    endpointData,
    userData,
    connectedRoomUsers,
    showUserPanel
}) => {

    const dispatch = useDispatch();

    const followUpSlice = useSelector(state => state.acplnFollowUpSliceReducer)

    const [loogedUser, setLoggedUser] = useState(null)
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [customActiveUserTab, setcustomActiveUserTab] = useState("1");
    const [drawerOpen, setdrawerOpen] = useState(false)
    const [addUserType, setaddUserType] = useState("")
    const [locationInfo, setLocationInfo] = useState(JSON.parse(sessionStorage.getItem("endpointData")))
    const [hideImportUser, sethideImportUser] = useState(false)
    const [ColToggle, setColToggle] = useState(false)


    const validUser = followUpSlice.validUser
    const userFacilities = validUser.length > 0 ? validUser[0]["facilities"] : []
    const addUserStatus = useMemo(() => _.some(userFacilities, facility => {
        return facility.id === 1 && facility.role_status === true && (facility.checked === undefined || facility.checked === true);
    }), [userFacilities]);

    const deleteUserStatus = useMemo(() => _.some(userFacilities, facility => {
        return facility.id === 2 && facility.role_status === true && (facility.checked === undefined || facility.checked === true);
    }), [userFacilities]);

    const changeRoleStatus =
        useMemo(() => _.some(userFacilities, facility => {
            return facility.id === 3 && facility.role_status === true && (facility.checked === undefined || facility.checked === true);
        }), [userFacilities]);


    useEffect(() => {

        if (selectedApln) {
            var getUser = _.filter(selectedApln.task_users, { "user_id": userData._id })[0]
            setLoggedUser(getUser)
        }



    }, [selectedApln, userData])


    useEffect(() => {
        const locationUsersWithIds = locationInfo.location_unique_users.filter(user => user.user_id);

        const allUserIdsExist = locationUsersWithIds.every(locationUser => {
            return selectedApln.task_users.some(taskUser => taskUser.user_id === locationUser.user_id);
        });

        sethideImportUser(allUserIdsExist)

    }, [])



    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const toggleUserCustom = tab => {
        if (customActiveUserTab !== tab) {
            setcustomActiveUserTab(tab);
        }
    };

    const onDrawerClose = () => {
        setdrawerOpen(false)
    }


    const onClickRemove = (selecteduser, selectedApln, type) => {

        let newuser = {
            ...selecteduser,
            user_status: type === 'remove' ? "1" : "2"
        };
    
        dispatch(removeAplnUser(newuser, selectedApln._id,selectedApln,selectedCheckpoint,locationInfo));
    }




    return (
        <div>
            <div className="px-3 py-3 d-flex flex-row justify-content-between">
                <div className="d-flex flex-row gap-2">
                    <Nav tabs className="nav-tabs-custom " style={{ border: 0 }}>
                        <NavItem style={{ flexGrow: 0 }}>
                            <NavLink
                                style={{ cursor: "pointer", padding: 0 }}
                                className="me-2"

                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >

                                <div className="avatar-group-item" title={"Add More Users"}>
                                    <Link to="#" className="d-inline-block" defaultValue="member-4"
                                    >
                                        <div className={`rounded-circle avatar-xs ${customActiveTab === "1" ? "bg-primary" : "bg-light"}`} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'black',
                                        }}>
                                            <i className={`fas fa-users ${customActiveTab === "1" ? "text-white" : "text-dark"}`} />
                                        </div>
                                    </Link>
                                </div>

                            </NavLink>
                        </NavItem>
                        {
                            addUserStatus &&
                            <NavItem style={{ flexGrow: 0 }}>
                                <NavLink
                                    style={{ cursor: "pointer", padding: 0 }}
                                    className="me-2"
                                    onClick={() => {
                                        toggleCustom("2");
                                    }}
                                >
                                    <div className="avatar-group-item" title={"Add More Users"}>
                                        <Link to="#" className="d-inline-block" defaultValue="member-4"
                                        >
                                            <div className={`rounded-circle avatar-xs ${customActiveTab === "2" ? "bg-primary" : "bg-light"}`} style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: 'black',
                                            }}>
                                                <i className={`fas fa-user-plus ${customActiveTab === "2" ? "text-white" : "text-dark"}`} />
                                            </div>
                                        </Link>
                                    </div>

                                </NavLink>
                            </NavItem>
                        }
                        <NavItem style={{ flexGrow: 0 }}>
                            <NavLink
                                style={{ cursor: "pointer", padding: 0 }}
                                className="me-2"

                                onClick={() => {
                                    toggleCustom("3");
                                }}
                            >

                                <div className="avatar-group-item" title={"Add More Users"}>
                                    <Link to="#" className="d-inline-block" defaultValue="member-4"
                                        onClick={() => {
                                            toggleCustom("3");
                                        }}
                                    >
                                        <div className={`rounded-circle avatar-xs ${customActiveTab === "3" ? "bg-primary" : "bg-light"}`} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'black',
                                        }}>

                                            <i className={`mdi mdi-message-settings-outline ${customActiveTab === "3" ? "text-white" : "text-dark"}`} />
                                        </div>
                                    </Link>
                                </div>

                            </NavLink>
                        </NavItem>

                    </Nav>
                </div>

                <div className="avatar-group-item" title={"Add More Users"}>
                    <Link to="#" className="d-inline-block" defaultValue="member-4"
                        onClick={() => { showUserPanel() }}
                    >
                        <div className={`rounded-circle avatar-xs bg-danger`} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                        }}>
                            <i className="mdi mdi-close text-white" />
                        </div>
                    </Link>
                </div>


            </div>
            <div className="col p-2">
                <TabContent
                    activeTab={customActiveTab}
                    className=" text-muted"
                >
                    <TabPane tabId="1" >
                        <div style={{ height: "72vh", overflow: "auto" }} >
                            {
                                connectedRoomUsers && selectedApln.task_users.map((user, index) => {
                                    var isActive = _.findIndex(connectedRoomUsers.users, { "userid": user.user_id })
                                    if (user.user_status === "0" || user.user_status === undefined) {
                                        return <div className="d-flex flex-column mb-2 p-2 bg-light " key={"tu" + index} style={{ borderRadius: 25 }}>

                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className={`rounded-circle avatar-xs me-2 ${isActive !== -1 ? "bg-success text-dark" : "bg-secondary text-dark"} bg-opacity-25`} style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        color: 'black',
                                                    }}>
                                                        {user.name.charAt(0).toUpperCase()}
                                                    </div>
                                                    <div className="d-flex flex-column ">
                                                        <div className="font-size-13 text-dark">{user.name} {user.user_id === userData._id && "(You)"}</div>
                                                        <div className="text-secondary" style={{ fontSize: "0.7rem" }}>{user.role_name}</div>
                                                    </div>
                                                </div>
                                                {
                                                    <div className="d-flex flex-row gap-1">

                                                        <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                            {

                                                                <DropdownToggle type="menu" tag="a" id="dropdownMenuButton1">
                                                                    <div className="avatar-group-item" title={"Add More Users"}>
                                                                        <Link to="#" className="d-inline-block" defaultValue="member-4"
                                                                        >
                                                                            <div className={`rounded-circle avatar-xs bg-secondary`} style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                color: 'black',
                                                                            }}>
                                                                                <i className="mdi mdi-dots-vertical text-white" />
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </DropdownToggle>
                                                            }
                                                            <DropdownMenu>
                                                                {user.user_id === userData._id && (
                                                                    <li><DropdownItem onClick={() => { onClickRemove(user, selectedApln, 'leave') }} href="#" className="my-2 text-danger">Left from room</DropdownItem></li>
                                                                )}

                                                                {
                                                                    user.user_id !== userData._id && changeRoleStatus && user.id >= followUpSlice.validUser[0].id && (
                                                                        <li onClick={() => {
                                                                            setdrawerOpen(true);
                                                                            setaddUserType("changerole");
                                                                            dispatch(setSelectedUser(user))
                                                                            ColToggle !== index ? setColToggle(index) : setColToggle(-1)
                                                                        }}><DropdownItem href="#" className="my-2" >Change role</DropdownItem></li>

                                                                    )
                                                                }


                                                                {user.user_id !== userData._id && <li><DropdownItem href="#" className="my-2">Hide History</DropdownItem></li>}

                                                                {
                                                                    user.user_id !== userData._id && deleteUserStatus === true &&
                                                                    <li><DropdownItem onClick={() => { onClickRemove(user, selectedApln, 'remove') }} href="#" className="text-danger my-2 py-2 border-top border-secondary border-opacity-25">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div>Remove </div>
                                                                            <div><i className="bx bx-user-minus font-size-18"></i></div>
                                                                        </div></DropdownItem>
                                                                    </li>
                                                                }
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                }
                                            </div>

                                            <Collapse isOpen={ColToggle === index}>
                                                {
                                                    addUserType === "changerole" &&
                                                    <UserRoleInformation
                                                        onClose={() => {
                                                            ColToggle !== index ? setColToggle(index) : setColToggle(-1)
                                                        }}
                                                        selectedApln={selectedApln}
                                                    />}
                                            </Collapse>


                                        </div>
                                    }
                                }
                                )
                            }
                        </div>
                    </TabPane>
                    <TabPane tabId="2" >
                        <div className="d-flex flex-column" style={{ height: "72vh", overflow: "auto" }} >
                            <Nav tabs className="nav-tabs-custom " style={{ border: 0 }}>
                                <NavItem style={{ flexGrow: 0 }}>
                                    <NavLink
                                        style={{ cursor: "pointer", padding: 0 }}
                                        className="me-2"

                                        onClick={() => {
                                            toggleUserCustom("1");
                                        }}
                                    >
                                        <div className="avatar-group-item" title={"Add More Users"}>
                                            <Link to="#" className="d-inline-block" defaultValue="member-4"
                                            >
                                                <div className={` py-1 px-3 font-size-12 ${customActiveUserTab === "1" ? "bg-primary  text-white" : "bg-light text-secondary"}`} style={{
                                                    borderRadius: 25,
                                                    fontWeight: 400
                                                }}>
                                                    <i className={`fas fa-users me-2`} /> Create New User
                                                </div>
                                            </Link>
                                        </div>

                                    </NavLink>
                                </NavItem>
                                <NavItem style={{ flexGrow: 0 }}>
                                    <NavLink
                                        style={{ cursor: "pointer", padding: 0 }}
                                        className="me-2"
                                        disabled={hideImportUser}
                                        onClick={() => {
                                            toggleUserCustom("2");
                                        }}
                                    >
                                        <div className="avatar-group-item" title={"Add More Users"}>
                                            <Link to="#" className="d-inline-block" defaultValue="member-4"
                                            >
                                                <div className={`py-1 px-3 font-size-12 ${hideImportUser && "text-secondary text-opacity-25"} ${customActiveUserTab === "2" ? "bg-primary text-white" : "bg-light text-secondary"}`} style={{
                                                    borderRadius: 25,
                                                    fontWeight: 400
                                                }}>
                                                    <i className={`fas fa-user-plus me-2`} /> Import Users
                                                </div>
                                            </Link>
                                        </div>

                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent
                                activeTab={customActiveUserTab}
                                className=" text-muted"
                            >
                                <TabPane tabId="1" >
                                    <div >
                                        <CreateNewUser
                                            onClose={() => {
                                                toggleCustom("1");
                                            }}
                                            selectedActionplan={selectedApln}
                                            selectedCheckpoint = {selectedCheckpoint}
                                            locationInfo={endpointData}
                                        />
                                    </div>
                                </TabPane>
                                <TabPane tabId="2" >
                                    <div >
                                        <ImportUser
                                            onClose={() => {
                                                toggleCustom("1");
                                            }}
                                            selectedCheckpoint = {selectedCheckpoint}
                                            selectedApln={selectedApln}
                                            locationInfo={endpointData}
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>

                        </div>
                    </TabPane>
                    <TabPane tabId="3" >
                        <div >
                            <div style={{ height: "65vh" }} >
                                <div>
                                    <Alert className="alert-light" role="alert">
                                        <p className="mb-0">Messages here are temporary and are deleted when you refresh or log in again</p>
                                    </Alert>

                                </div>

                            </div>

                            <div className="position-relative">
                                <textarea
                                    type="text"
                                    className="form-control chat-input ps-4"
                                    placeholder="Enter Message..."
                                    style={{ minHeight: 30 }}
                                />
                                <div className="chat-input-links">
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <Button
                                                type="button"
                                                color="primary"
                                                onClick={() => {
                                                    if (isSliderOpen) { setSliderOpen(false); setChatSliderOpen(""); setPreviousData(null) }
                                                    if (selectedAction === "Reply") {
                                                        setIsReply(false)
                                                        setCurrentMessage("")
                                                    }
                                                    else {
                                                        sendMessage(currentMessage)
                                                        setCurrentMessage("")
                                                    }
                                                }}
                                                className="btn btn-primary btn-rounded chat-send "
                                            >
                                               
                                                <i className="mdi mdi-send" />
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </TabPane>
                </TabContent>

            </div>
        </div>
    )
}

export default TaskUsers


