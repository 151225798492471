import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import { Container, Label,Row, UncontrolledTooltip, DropdownMenu, DropdownToggle, UncontrolledDropdown, Tooltip } from "reactstrap"
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SortableTree from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import { addNode, crudNode, dndNode, setMenuName,setReportName, setTreeData, deleteNode, getNodeData, setState, updateTreeData, editNode, updateNode, onTreeChange } from '../../../../store/reportd3/treedataSlice'
import { setNodeInfo } from '../../../../store/reportd3/treedataSlice';
import { updateLayoutInfo } from '../../../../store/reportd3/reportslice';
import Select from "react-select";

const TreeStructure = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [dataLoaded, setDataLoaded] = useState(true);
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [dropdownStates, setDropdownStates] = useState({});
    const [dropdownReportStates, setDropdownReportStates] = useState(false);
    const [selectedButton,setSelectedButton] = useState("")
    const [selectedCln,setSelectedCln] = useState({})
    const [dbCollections,setdbCollections] = useState(JSON.parse(sessionStorage.getItem('authUser')).config_data.report_collection_name)
    console.log(dbCollections,'dbCollections')

    const state = useSelector(state => state.treeData);
    const treeData = state.treeData


    const toggle = () => {
        dispatch(setState({ mainToggle: !state.mainToggle }));
    };

    const addCrudNode = (event, values) => {
        console.log('values', values, state.crudStatus,state)
        values["selected_mode"] = selectedButton
        values["selected_cln_name"] = selectedCln


        const action = state.crudStatus === 2 ? updateNode : crudNode;
        dispatch(action(values,history));
        setDropdownStates({})
        setDropdownReportStates({})
    };

    const toggleToolTip = (targetName) => {
    };

    const handleTreeChange = (newTreeData) => {
        onTreeChange(newTreeData, dispatch);
    };

    const toggleDropdown = (id) => {
        setDropdownStates({
            ...dropdownStates,
            [id]: !dropdownStates[id] // Toggle the state for the clicked dropdown menu
        });
    };

    const toggleReportDropdown = (id) => {
        setDropdownReportStates({
            ...dropdownReportStates,
            [id]: !dropdownReportStates[id] // Toggle the state for the clicked dropdown menu
        });


    }


    const selectedClnInfo=(event)=>{
        console.log(event.target.value,'value')
        var selectedValue = _.filter(dbCollections,{cln_name: event.target.value})
        console.log(selectedValue,'selectedValue')
        if(selectedValue.length >0){
            setSelectedCln(selectedValue[0])
        }
    }

    return (
        <React.Fragment>
            {
                console.log(selectedCln,'selectedCln')
            }
            {dataLoaded ? (
                <Container fluid>
                    <div className="d-flex flex-row" style={{ position: "relative", width: "100%", minHeight: "100vh" }}>
                        <div style={{ background: "white", width: "100%", transition: "width .35s ease-in-out", position: "absolute", float: "left", }} className="p-3 me-2" >
                            <div className="row">
                                <div className="mb-2 col-10 ">
                                    <UncontrolledDropdown isOpen={state.mainToggle} toggle={toggle}>
                                        <DropdownToggle className="btn btn-primary" color="#eff2f7" onClick={() => dispatch(setState({ path: [], crud: true, crudStatus: 0, type: 0, children: [], mainToggle: true }))} >
                                            <i className="mdi mdi-plus me-1 "></i> Create New
                                        </DropdownToggle>
                                        <DropdownMenu style={{ width: 250 }} className="">
                                            <div className="px-4">



                                                <AvForm onValidSubmit={addCrudNode}>
                                                    <div className="my-2">
                                                        <AvField
                                                            name="title"
                                                            label="Report Name"
                                                            placeholder="Enter Report"
                                                            type="text"
                                                            errorMessage="Enter Report"
                                                            defaultValue=""
                                                            onChange={(e) => dispatch(setReportName(e.target.value))}
                                                        />
                                                    </div>
                                                    <div className="my-2">
                                                        <Label>Choose Data Source :</Label>
                                                        <select defaultValue={"Select"} className='form-control' onChange={(e)=>{console.log(e.target.value,'value');selectedClnInfo(e)}}>
                                                            <option disabled value={"Select"}>Select</option>
                                                            {
                                                                dbCollections.map((ele,ind)=>{
                                                                    return(
                                                                        <option value={ele.cln_name} key={ind}>{ele.user_friendly_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        
                                                    </div>
                                                    <div className="my-3">
                                                        <button onClick={()=>{setSelectedButton("0")}} className="btn btn-success btn-block m-1" type="submit" style={{ marginRight: 5 }}>
                                                            Add Report
                                                        </button>
                                                    </div>
                                                    <div className="my-2">
                                                        <AvField
                                                            name="menuName"
                                                            label="Menu Name"
                                                            placeholder="Enter Menu"
                                                            type="text"
                                                            errorMessage="Enter Menu"
                                                            defaultValue=""
                                                            onChange={(e) => dispatch(setMenuName(e.target.value))}
                                                        />
                                                    </div>
                                                    <div className="my-3">
                                                        <button onClick={()=>{setSelectedButton("1")}}  className="btn btn-primary btn-block m-1" type="submit" style={{ marginRight: 5 }}>
                                                            Add Menu
                                                        </button>
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                            <div style={{ overflow: "auto", height: "90vh", }}>
                                <SortableTree
                                    treeData={treeData}
                                    onChange={handleTreeChange}
                                    onMoveNode={(object) => dispatch(dndNode(object))}
                                    canDrop={(object) => object.nextParent !== undefined && object.nextParent.type == 2 ? false : true}
                                    scaffoldBlockPxWidth={40}
                                    slideRegionSize={25}
                                    generateNodeProps={({ node, path }) => {
                                        const updatedPath = [...path];
                                        return {
                                            listIndex: 0,
                                            lowerSiblingCounts: [],
                                            className: node.type === 2 ? "icon-a" : "icon-b",
                                            onClick: (event) => {
                                                if (event.target.className.includes("collapseButton") || event.target.className.includes("expandButton")) {
                                                } else {
                                                    setSelectedNodeId(node.id);
                                                    dispatch(getNodeData(node));
                                                }
                                            },
                                            style: {
                                                border: selectedNodeId === node.id ? "2px solid #556EE6" : '1px solid #c3cacd',
                                                backgroundImage: "url('../../../../assets/images/drag-and-drop-7.png')"
                                            },
                                            title: (
                                                <div>
                                                    <div style={{ maxWidth: 450 }} key={`div-${node.id}`}>
                                                        {state.editcrud && state.id === node.id ? (
                                                            <AvForm onValidSubmit={addCrudNode}>
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <div className="me-2 p-0">
                                                                        <input name="title" placeholder="Enter Menu Name" className="form-control py-1 m-0" type="text" value={state.menuName} onChange={(e) => { dispatch(setMenuName(e.target.value)) }} />
                                                                    </div>
                                                                    <div className="d-flex flex-row">
                                                                        <button className="btn btn-sm btn-secondary " type="submit" style={{ marginRight: 3 }}>
                                                                            Update
                                                                        </button>
                                                                        <Link to="#" className="btn btn-sm btn-soft-danger" onClick={() => { dispatch(setState({ editcrud: false, id: null })) }} id={`closetooltip-${node.id}`} >
                                                                            <i className="mdi mdi-close" />
                                                                            <UncontrolledTooltip placement="top" target={`closetooltip-${node.id}`} >
                                                                                {"Close"}
                                                                            </UncontrolledTooltip>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </AvForm>
                                                        ) : (
                                                            <div>
                                                                <Tooltip
                                                                    placement="bottom"
                                                                    target={`btn-${node.id}`}
                                                                    toggle={() => toggleToolTip(`btn-${node.id}`)}
                                                                >
                                                                    {node.title}
                                                                </Tooltip>
                                                                <Link
                                                                    to="#"
                                                                    id={`btn-${node.id}`}
                                                                    style={{ fontSize: 12, fontWeight: 400 }}
                                                                >
                                                                    {String(node.title).slice(0, 40) +
                                                                        (node.title?.length > 40 ? "..." : "")}
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ),
                                            buttons: [
                                                <Row className="" key={node.id}>
                                                    <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
                                                        {state.editcrud && state.id === node.id ? null : (
                                                            <li>
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-sm btn-soft-info"
                                                                    onClick={() => node.type !== 2 && dispatch(editNode(updatedPath, node))}
                                                                    id={`edittooltip-${node.id}`}
                                                                >
                                                                    <i className="mdi mdi-pencil-outline" />
                                                                    <UncontrolledTooltip placement="top" target={`edittooltip-${node.id}`} >
                                                                        {node.type !== 2 && "Edit Menu"}
                                                                    </UncontrolledTooltip>
                                                                </Link>
                                                            </li>
                                                        )}
                                                        {
                                                            node.type === 1 &&(
                                                                <li>
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-sm btn-soft-info"
                                                                    onClick={() =>{console.log(node,'node');sessionStorage.setItem("pageNodeInfo", JSON.stringify(node));dispatch(setNodeInfo(node));history.push("/report_page");dispatch(updateLayoutInfo([]))}}
                                                                    id={`ViewReport-${node.id}`}
                                                                >
                                                                    <i className="mdi mdi-layers-plus" />
                                                                    <UncontrolledTooltip placement="top" target={`ViewReport-${node.id}`} >
                                                                        {node.type !== 2 && "Create / Edit"}
                                                                    </UncontrolledTooltip>
                                                                </Link>
                                                            </li>

                                                            )


                                                        }
                                                        {node.type === 0 && (
                                                            <UncontrolledDropdown direction="end" isOpen={dropdownStates[node.id]} toggle={() => toggleDropdown(node.id)}>
                                                                <DropdownToggle className="card-drop" tag="a">
                                                                    <Link to="#" className="btn btn-sm btn-soft-primary" id={`viewtooltip-${node.id}`} onClick={() => dispatch(addNode(node, updatedPath, 0))} >
                                                                        <i className="mdi mdi-file-tree" />
                                                                        <UncontrolledTooltip placement="top" target={`viewtooltip-${node.id}`}>
                                                                            Add Submenu
                                                                        </UncontrolledTooltip>
                                                                    </Link>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)}>
                                                                    <div className="px-4">
                                                                        <AvForm onValidSubmit={addCrudNode} >
                                                                            <div className="my-2">
                                                                                <AvField
                                                                                    name="title"
                                                                                    label="Sub Menu Name "
                                                                                    placeholder="Enter Sub Menu"
                                                                                    type="text"
                                                                                    errorMessage="Enter Menu"
                                                                                    validate={{
                                                                                        required: { value: true },
                                                                                        minLength: {
                                                                                            value: 4,
                                                                                            errorMessage: "Min 4 chars.",
                                                                                        },
                                                                                    }}
                                                                                    defaultValue={""}
                                                                                    onChange={(e) => {
                                                                                        dispatch(setMenuName(e.target.value));
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="my-3">
                                                                                <button className="btn btn-primary btn-block m-1" type="submit" style={{ marginRight: 5 }}>
                                                                                    Add Menu
                                                                                </button>
                                                                            </div>
                                                                        </AvForm>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        )}

                                                        <li>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-danger"
                                                                onClick={() => dispatch(deleteNode(node, path, "udp" + String(node.id), "dp" + String(node.id)))}
                                                                id={`deletetooltip-${node.id}`}
                                                            >
                                                                <i className="mdi mdi-delete-outline" />
                                                                <UncontrolledTooltip placement="top" target={`deletetooltip-${node.id}`}>
                                                                    {node.type !== 2 && "Delete Menu"}
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </li>
                                                        {node.type === 0 && (
                                                            <UncontrolledDropdown direction="end" isOpen={dropdownReportStates[node.id]} toggle={() => toggleReportDropdown(node.id)}>
                                                                <DropdownToggle className="card-drop" tag="a">
                                                                    <Link to="#" className="btn btn-sm btn-soft-success" id={`addreport-${node.id}`} onClick={() => dispatch(addNode(node, updatedPath, 1))} >
                                                                        <i className="mdi mdi-plus" />
                                                                        <UncontrolledTooltip placement="top" target={`addreport-${node.id}`}>
                                                                            Add Report
                                                                        </UncontrolledTooltip>
                                                                    </Link>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)}>
                                                                    <div className="px-4">
                                                                        <AvForm onValidSubmit={addCrudNode}>
                                                                            <div className="my-2">
                                                                                <AvField
                                                                                    name="title"
                                                                                    label="Report Name "
                                                                                    placeholder="Enter report Name"
                                                                                    type="text"
                                                                                    errorMessage="Enter Name"
                                                                                    validate={{
                                                                                        required: { value: true },
                                                                                        minLength: {
                                                                                            value: 4,
                                                                                            errorMessage: "Min 4 chars.",
                                                                                        },
                                                                                    }}
                                                                                    defaultValue={""}
                                                                                    onChange={(e) => {
                                                                                        dispatch(setMenuName(e.target.value));
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="my-3">
                                                                                <button className="btn btn-primary btn-block m-1" type="submit" style={{ marginRight: 5 }}>
                                                                                    Add Report
                                                                                </button>
                                                                            </div>
                                                                        </AvForm>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        )}

                                                    </ul>
                                                </Row>
                                            ],
                                        };
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            ) : (
                <div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div className="spinner-border text-secondary m-1" role="status"> </div>
                        <div>Loading, Please wait.</div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default TreeStructure;
























// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { Link,useHistory } from 'react-router-dom';
// import { Container, Row, UncontrolledTooltip, DropdownMenu, DropdownToggle, UncontrolledDropdown, Tooltip } from "reactstrap"
// import { AvForm, AvField } from 'availity-reactstrap-validation';
// import SortableTree from '@nosferatu500/react-sortable-tree';
// import '@nosferatu500/react-sortable-tree/style.css';
// import { addNode, crudNode, dndNode, setMenuName, setTreeData, deleteNode, getNodeData, setState, updateTreeData, editNode, updateNode, onTreeChange } from '../../../../store/report/Slice/treeDataSlice';
// import { setNodeInfo } from '../../../../store/report/Slice/LayoutInfoSlice';

// const TreeStructure = () => {
//     const dispatch = useDispatch();
//     const history = useHistory()
//     const [dataLoaded, setDataLoaded] = useState(true);
//     const [selectedNodeId, setSelectedNodeId] = useState(null);
//     const [dropdownStates, setDropdownStates] = useState({});
//     const [dropdownReportStates, setDropdownReportStates] = useState(false);



//     const treeData =
//     [
//         {
//             "children" :[],
//             "expanded": true,
//             "id" :1,
//             "parent" : null,
//             "subtitle" :'',
//             "title" :'Report',
//             "type" :1
//         },
//         {
//             "children" :[{
//                 "id": 3,
//                 "parent" : 2,
//                 "subtitle" :"",
//                 "title" :"report-1",
//                 "type" : 1
//             }],
//             "expanded": true,
//             "id" :2,
//             "parent" : null,
//             "subtitle" :'',
//             "title" :'Menu',
//             "type" :0

//         }
//     ]

//     //  useSelector(updateTreeData);
//     const state = useSelector(state => state.treeData);

//     const toggle = () => {
//         dispatch(setState({ mainToggle: !state.mainToggle }));
//     };

//     const addCrudNode = (event, values) => {
//         console.log(values,'values')
//         const action = state.crudStatus === 2 ? updateNode : crudNode;
//         dispatch(action(values,history));
//         setDropdownStates({})
//         setDropdownReportStates({})
//     };

//     const toggleToolTip = (targetName) => {
//         // console.log('called..')
//     };

//     const handleTreeChange = (newTreeData) => {
//         onTreeChange(newTreeData, dispatch);
//     };

//     const handleBackButtonClick = () => {
//         dispatch(setTreeData([])); // Reset the treeData state
//         dispatch(setState({}));

//     };
//     const toggleDropdown = (id) => {
//         setDropdownStates({
//             ...dropdownStates,
//             [id]: !dropdownStates[id] // Toggle the state for the clicked dropdown menu
//         });
//         // dispatch(addNode(node, updatedPath, 0))
//     };

//     const toggleReportDropdown = (id) => {
//         // setDropdownOpen(!dropdownOpen);
//         setDropdownReportStates({
//             ...dropdownReportStates,
//             [id]: !dropdownReportStates[id] // Toggle the state for the clicked dropdown menu
//         });
//     }

//     const handleCreateNewClick = () => {
//         dispatch(setState({ path: [], crud: true, crudStatus: 0, type: 0, children: [], mainToggle: true }));
//     };

//     return (
//         <React.Fragment>
//             {dataLoaded ? (
//                 <Container fluid>
//                     <div className="d-flex flex-row" style={{ position: "relative", width: "100%", minHeight: "100vh" }}>
//                         <div style={{ background: "white", width: "100%", transition: "width .35s ease-in-out", position: "absolute", float: "left", }} className="p-3 me-2" >
//                             <div className="row">
//                                 <div className="mb-2 col-10 ">
//                                     <UncontrolledDropdown isOpen={state.mainToggle} toggle={toggle}>
//                                         <DropdownToggle className="btn btn-primary" color="#eff2f7" onClick={() => dispatch(setState({ path: [], crud: true, crudStatus: 0, type: 0, children: [], mainToggle: true }))} >
//                                             <i className="mdi mdi-plus me-1 "></i> Create New
//                                         </DropdownToggle>
//                                         <DropdownMenu style={{ width: 250 }} className="">
//                                             <div className="px-4">
//                                                 <AvForm onValidSubmit={addCrudNode}>
//                                                     <div className="my-2">
//                                                         <AvField name="title" label="Menu Name " placeholder="Enter Menu" type="text" errorMessage="Enter Menu" validate={{ required: { value: true }, minLength: { value: 4, errorMessage: "Min 4 chars.", } }} defaultValue={""} onChange={(e) => { dispatch(setMenuName(e.target.value)) }} />
//                                                     </div>
//                                                     <div className="my-3">
//                                                         <button className="btn btn-primary btn-block m-1" type="submit" style={{ marginRight: 5 }}>
//                                                             Add Menu
//                                                         </button>
//                                                     </div>
//                                                 </AvForm>
//                                             </div>
//                                         </DropdownMenu>
//                                     </UncontrolledDropdown>
//                                 </div>
//                                 {/* <div className="mb-2 col-2 text-end">
//  <Link to={"/course-list"}>
//  <button className='btn btn-info' onClick={() => { handleBackButtonClick() }}>Back</button>
//  </Link>
//  </div> */}
//                             </div>
//                             <div style={{ overflow: "auto", height: "90vh", }}>
//                                 <SortableTree
//                                     treeData={treeData}
//                                     onChange={handleTreeChange}
//                                     onMoveNode={(object) => dispatch(dndNode(object))}
//                                     canDrop={(object) => object.nextParent !== undefined && object.nextParent.type == 2 ? false : true}
//                                     scaffoldBlockPxWidth={40}
//                                     slideRegionSize={25}
//                                     generateNodeProps={({ node, path }) => {
//                                         const updatedPath = [...path];
//                                         return {
//                                             listIndex: 0,
//                                             lowerSiblingCounts: [],
//                                             className: node.type === 2 ? "icon-a" : "icon-b",
//                                             onClick: (event) => {
//                                                 if (event.target.className.includes("collapseButton") || event.target.className.includes("expandButton")) {
//                                                     // Handle collapse/expand button click
//                                                 } else {
//                                                     setSelectedNodeId(node.id);
//                                                     dispatch(getNodeData(node));
//                                                 }
//                                             },
//                                             style: {
//                                                 border: selectedNodeId === node.id ? "2px solid #556EE6" : '1px solid #c3cacd',
//                                                 backgroundImage: "url('../../../../assets/images/drag-and-drop-7.png')"
//                                             },
//                                             title: (
//                                                 <div>
//                                                     <div style={{ maxWidth: 450 }} key={`div-${node.id}`}>
//                                                         {state.editcrud && state.id === node.id ? (
//                                                             <AvForm onValidSubmit={addCrudNode}>
//                                                                 <div className="d-flex flex-row align-items-center">
//                                                                     <div className="me-2 p-0">
//                                                                         <input name="title" placeholder="Enter Menu Name" className="form-control py-1 m-0" type="text" value={state.menuName} onChange={(e) => { dispatch(setMenuName(e.target.value)) }} />
//                                                                     </div>
//                                                                     <div className="d-flex flex-row">
//                                                                         <button className="btn btn-sm btn-secondary " type="submit" style={{ marginRight: 3 }}>
//                                                                             Update
//                                                                         </button>
//                                                                         <Link to="#" className="btn btn-sm btn-soft-danger" onClick={() => { dispatch(setState({ editcrud: false, id: null })) }} id={`closetooltip-${node.id}`} >
//                                                                             <i className="mdi mdi-close" />
//                                                                             <UncontrolledTooltip placement="top" target={`closetooltip-${node.id}`} >
//                                                                                 {"Close"}
//                                                                             </UncontrolledTooltip>
//                                                                         </Link>
//                                                                     </div>
//                                                                 </div>
//                                                             </AvForm>
//                                                         ) : (
//                                                             <div>
//                                                                 <Tooltip
//                                                                     placement="bottom"
//                                                                     target={`btn-${node.id}`}
//                                                                     toggle={() => toggleToolTip(`btn-${node.id}`)}
//                                                                 >
//                                                                     {node.title}
//                                                                 </Tooltip>
//                                                                 <Link
//                                                                     to="#"
//                                                                     id={`btn-${node.id}`}
//                                                                     style={{ fontSize: 12, fontWeight: 400 }}
//                                                                 >
//                                                                     {String(node.title).slice(0, 40) +
//                                                                         (node.title?.length > 40 ? "..." : "")}
//                                                                 </Link>
//                                                             </div>
//                                                         )}
//                                                     </div>
//                                                 </div>
//                                             ),
//                                             buttons: [
//                                                 <Row className="" key={node.id}>
//                                                     <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
//                                                         {state.editcrud && state.id === node.id ? null : (
//                                                             <li>
//                                                                 <Link
//                                                                     to="#"
//                                                                     className="btn btn-sm btn-soft-info"
//                                                                     onClick={() => node.type !== 2 && dispatch(editNode(updatedPath, node))}
//                                                                     id={`edittooltip-${node.id}`}
//                                                                 >
//                                                                     <i className="mdi mdi-pencil-outline" />
//                                                                     <UncontrolledTooltip placement="top" target={`edittooltip-${node.id}`} >
//                                                                         {node.type !== 2 && "Edit Menu"}
//                                                                     </UncontrolledTooltip>
//                                                                 </Link>
//                                                             </li>
//                                                         )}
//                                                         {
//                                                             node.type === 1 &&(
//                                                                 <li>
//                                                                 <Link
//                                                                     to="#"
//                                                                     className="btn btn-sm btn-soft-info"
//                                                                     onClick={() =>{console.log(node,'node');sessionStorage.setItem("pageNodeInfo", JSON.stringify(node));dispatch(setNodeInfo(node));history.push("/report_page")}}
//                                                                     id={`ViewReport-${node.id}`}
//                                                                 >
//                                                                     <i className="mdi mdi-layers-plus" />
//                                                                     <UncontrolledTooltip placement="top" target={`ViewReport-${node.id}`} >
//                                                                         {node.type !== 2 && "View Report"}
//                                                                     </UncontrolledTooltip>
//                                                                 </Link>
//                                                             </li>

//                                                             )


//                                                         }
//                                                         {node.type === 0 && (
//                                                             <UncontrolledDropdown direction="end" isOpen={dropdownStates[node.id]} toggle={() => toggleDropdown(node.id)}>
//                                                                 <DropdownToggle className="card-drop" tag="a">
//                                                                     <Link to="#" className="btn btn-sm btn-soft-primary" id={`viewtooltip-${node.id}`} onClick={() => dispatch(addNode(node, updatedPath, 0))} >
//                                                                         <i className="mdi mdi-file-tree" />
//                                                                         <UncontrolledTooltip placement="top" target={`viewtooltip-${node.id}`}>
//                                                                             Add Submenu
//                                                                         </UncontrolledTooltip>
//                                                                     </Link>
//                                                                 </DropdownToggle>
//                                                                 {/* {state.crud && node.type === 0 ? ( */}
//                                                                 <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)}>
//                                                                     <div className="px-4">
//                                                                         <AvForm onValidSubmit={addCrudNode} >
//                                                                             <div className="my-2">
//                                                                                 <AvField
//                                                                                     name="title"
//                                                                                     label="Sub Menu Name "
//                                                                                     placeholder="Enter Sub Menu"
//                                                                                     type="text"
//                                                                                     errorMessage="Enter Menu"
//                                                                                     validate={{
//                                                                                         required: { value: true },
//                                                                                         minLength: {
//                                                                                             value: 4,
//                                                                                             errorMessage: "Min 4 chars.",
//                                                                                         },
//                                                                                     }}
//                                                                                     defaultValue={""}
//                                                                                     onChange={(e) => {
//                                                                                         dispatch(setMenuName(e.target.value));
//                                                                                     }}
//                                                                                 />
//                                                                             </div>
//                                                                             <div className="my-3">
//                                                                                 <button className="btn btn-primary btn-block m-1" type="submit" style={{ marginRight: 5 }}>
//                                                                                     Add Menu
//                                                                                 </button>
//                                                                             </div>
//                                                                         </AvForm>
//                                                                     </div>
//                                                                 </DropdownMenu>
//                                                                 {/* ) : null} */}
//                                                             </UncontrolledDropdown>
//                                                         )}

//                                                         <li>
//                                                             <Link
//                                                                 to="#"
//                                                                 className="btn btn-sm btn-soft-danger"
//                                                                 onClick={() => dispatch(deleteNode(node, path, "udp" + String(node.id), "dp" + String(node.id)))}
//                                                                 id={`deletetooltip-${node.id}`}
//                                                             >
//                                                                 <i className="mdi mdi-delete-outline" />
//                                                                 <UncontrolledTooltip placement="top" target={`deletetooltip-${node.id}`}>
//                                                                     {node.type !== 2 && "Delete Menu"}
//                                                                 </UncontrolledTooltip>
//                                                             </Link>
//                                                         </li>
//                                                         {/* <li>
//                                                             <Link
//                                                                 to="#"
//                                                                 className="btn btn-sm btn-soft-success"
//                                                                 // onClick={() => dispatch(deleteNode(node, path, "udp" + String(node.id), "dp" + String(node.id)))}
//                                                                 id={`addchart-${node.id}`}
//                                                             >
//                                                                 <i className="mdi mdi-plus" />
//                                                                 <UncontrolledTooltip placement="top" target={`addchart-${node.id}`}>
//                                                                     {node.type !== 2 && "Add charts"}
//                                                                 </UncontrolledTooltip>
//                                                             </Link>
//                                                         </li>                                                                                     */}
//                                                         {node.type === 0 && (
//                                                             <UncontrolledDropdown direction="end" isOpen={dropdownReportStates[node.id]} toggle={() => toggleReportDropdown(node.id)}>
//                                                                 <DropdownToggle className="card-drop" tag="a">
//                                                                     <Link to="#" className="btn btn-sm btn-soft-success" id={`addreport-${node.id}`} onClick={() => dispatch(addNode(node, updatedPath, 1))} >
//                                                                         <i className="mdi mdi-plus" />
//                                                                         <UncontrolledTooltip placement="top" target={`addreport-${node.id}`}>
//                                                                             Add Report
//                                                                         </UncontrolledTooltip>
//                                                                     </Link>
//                                                                 </DropdownToggle>
//                                                                 {/* {state.crud && node.type === 0 ? ( */}
//                                                                 <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)}>
//                                                                     <div className="px-4">
//                                                                         <AvForm onValidSubmit={addCrudNode}>
//                                                                             <div className="my-2">
//                                                                                 <AvField
//                                                                                     name="title"
//                                                                                     label="Report Name "
//                                                                                     placeholder="Enter report Name"
//                                                                                     type="text"
//                                                                                     errorMessage="Enter Name"
//                                                                                     validate={{
//                                                                                         required: { value: true },
//                                                                                         minLength: {
//                                                                                             value: 4,
//                                                                                             errorMessage: "Min 4 chars.",
//                                                                                         },
//                                                                                     }}
//                                                                                     defaultValue={""}
//                                                                                     onChange={(e) => {
//                                                                                         dispatch(setMenuName(e.target.value));
//                                                                                         // console.log(e.target.value,'e.target.value')
//                                                                                     }}
//                                                                                 />
//                                                                             </div>
//                                                                             <div className="my-3">
//                                                                                 <button className="btn btn-primary btn-block m-1" type="submit" style={{ marginRight: 5 }}>
//                                                                                     Add Report
//                                                                                 </button>
//                                                                             </div>
//                                                                         </AvForm>
//                                                                     </div>
//                                                                 </DropdownMenu>
//                                                                 {/* ) : null} */}
//                                                             </UncontrolledDropdown>
//                                                         )}

//                                                     </ul>
//                                                 </Row>
//                                             ],
//                                         };
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </Container>
//             ) : (
//                 <div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
//                     <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
//                         <div className="spinner-border text-secondary m-1" role="status"> </div>
//                         <div>Loading, Please wait.</div>
//                     </div>
//                 </div>
//             )}
//         </React.Fragment>
//     );
// };

// export default TreeStructure;