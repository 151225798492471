import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Container, Row, Col, Button, Alert, Input, Card, CardBody,
  Modal, ModalHeader, ModalBody, UncontrolledTooltip,
  Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Tooltip,
} from "reactstrap"
import ReactDrawer from 'react-drawer';
import { DatePicker, Badge } from 'antd';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import SweetAlert from "react-bootstrap-sweetalert"
import ParentChildBtn from "../../../components/ParentChildBtn"
import Breadcrumbs from "./Components/Breadcrumb2"
import InputTemplate from "./Components/InputTemplate"
import 'react-drawer/lib/react-drawer.css';
import facility from '../FacilityScreen'
import $ from 'jquery';

import SortableTree, {
  toggleExpandedForAll,
  addNodeUnderParent,
  getTreeFromFlatData,
  getFlatDataFromTree,
  changeNodeAtPath,
  getNodeAtPath,
  removeNodeAtPath,
  getVisibleNodeCount
} from "@nosferatu500/react-sortable-tree";

// import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import "@nosferatu500/react-sortable-tree/style.css";
import { FaGripVertical } from "react-icons/fa";
import "react-perfect-scrollbar/dist/css/styles.css"
import "./manageAudit.css"
import "react-rangeslider/lib/index.css"
import MetaTags from 'react-meta-tags';
import './react-tree-style.css'

import './CSS/DGT.css'

// import component 👇
import Drawer from 'react-modern-drawer'



const _ = require('lodash')
// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from "../../../helpers/urlSocket";

export default class manage_audit_Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height,
      successmessage: false,
      changeToUpdate: false,
      dataLoaded: false,
      open: false,
      position: "right",
      successmsgshow: false,
      nodeChildren: [],
      nodeTitle: "",
      nodeSelected: false,
      treeData: [],
      audit_name_exist: false,
      breadcrumb_titles: [],
      modal: false,
      expanded:false,
      Nodecount:1


    };
    this.toggle = this.toggle.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.childtoggle = this.childtoggle.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);

    this.closeDrawer = this.closeDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.setPosition = this.setPosition.bind(this);
  }

  async componentDidMount() {
    var templateData = JSON.parse(sessionStorage.getItem("EditData"));
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"))

    var protect_routes = await facility(user_facilities, "mngmtrtmplt");
    if (protect_routes !== undefined) {
      this.setState(
        {
          height: window.innerHeight,
          configData: data.config_data,
          userData: data.user_data,
          templateData,
          db_info,
          template_name: templateData.template_name,
          template_info: templateData.template_info,
          configdatacheckpoint: data.config_data.question_type_info,
          dataLoaded: false,
          client_info: data.client_info[0],

        },
        function () {
          this.getDocuments();
        }
      );
    } else {
      this.props.history.push("/dashboard");
    }
  }

  // handleValidUserSubmit = (event, values) => {
  //   try {
  //     urlSocket
  //       .post("webmngtmplt/crudtemplate", {
  //         templateInfo: {
  //           template_id: this.state.templateData._id,
  //           template_name: values.template_name,
  //           template_info: values.template_info,
  //         },
  //         userInfo: {
  //           encrypted_db_url: this.state.db_info.encrypted_db_url,
  //           created_by: this.state.userData._id,
  //           company_id: this.state.userData.company_id,
  //         },
  //       })
  //       .then((response) => {
  //         if (response.data.response_code == 500) {
  //           this.setState({
  //             successmessage: true,
  //             updateTemplateStatus: response.data.message,
  //             changeToUpdate: false,
  //             template_name: this.state.template_name,
  //             template_info: this.state.template_info,
  //           });
  //           setTimeout(() => {
  //             this.setState({ successmessage: false });
  //           }, 2500);
  //         }
  //       });
  //   } catch (error) {}
  // };

  togglePopup = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  treeDataToFlat = () => {
    var getNodeKey = ({ treeIndex }) => treeIndex;
    var flatData = getFlatDataFromTree({
      treeData: this.state.treeData,
      getNodeKey,
      ignoreCollapsed: false,
    });

    var explicitData = _.filter(flatData, (item) => {
      return item;
    });
    console.log("droppedNode, getNodeKey.,162")

    return explicitData;
  };

  saveHStructure = () => {
    console.log("droppedNode, getNodeKey.,167")
    var explicitData = this.treeDataToFlat();
    console.log('explicitData', explicitData)
    this.saveData(explicitData);
  };

  saveData = (flatData) => {
    this.setState({ saving: true });
    console.log("explicitData,175")
    try {
      urlSocket
        .post("webmngtmplt/savecpstructure", {
          templateInfo: {
            template_id: this.state.templateData._id,
            flatData: _.map(flatData, "node"),
            checkpointlength: _.filter(_.map(flatData, "node"), { type: 2 })
              .length,
            lastGeneratedId: this.state.totalHLength,
          },
          userInfo: {
            encrypted_db_url: this.state.db_info.encrypted_db_url,
            created_by: this.state.userData._id,
            company_id: this.state.userData.company_id,
          },
        })
        .then((response) => {
          console.log(response, 'response')
          if (response.data.response_code == 500) {
            this.setState({
              saving: false,
              successmsgshow: true,
              alertMessage: response.data.message,
            },()=>{
              this.getDocuments()
            });
            setTimeout(() => {
              this.setState({ successmsgshow: false });
            }, 2500);
          }
        });
    } catch (error) { }
  };

  getDocuments() {
    try {
      urlSocket
        .post("/webmngtmplt/gettemplatestructure", {
          templateInfo: {
            template_id: this.state.templateData._id,
            tmplatedBy: this.state.templateData.tmplt_ctd_by,
          },
          userInfo: {
            db_info: this.state.db_info.encrypted_db_url,
            created_by: this.state.userData._id,
            company_id: this.state.userData.company_id,
          },
        })
        .then((response) => {
          // console.log(response,'response')
          var FlatData = response.data.data.template_structure;
          this.setState(
            {
              dataLoaded: FlatData.length > 0 ? false : true,
              totalHLength: response.data.data.lastGeneratedId,
            },
            function () {
              this.convertFlatDataToTreeData(FlatData);
            }
          );
        })
        .catch((error) => {
          console.log(error, "catch1");
        });
    } catch (error) {
      console.log("catch error", error);
    }
  }

  convertFlatDataToTreeData = (FlatData) => {
    this.setState({
      treeData: getTreeFromFlatData({
        flatData: FlatData,
        getKey: (node) => node.id,
        getParentKey: (node) => node.parent,
        rootKey: null,
        expanded: true,
      }),
      dataLoaded: true,
    }, () => {
      this.setState({
        Nodecount:getVisibleNodeCount({treeData:this.state.treeData}),
      })
    });
  };

  addMainNode(type) {
    // console.log('addMainNode...')
    const getNodeKey = ({ treeIndex }) => treeIndex;
    this.setState({
      path: [],
      getNodeKey: getNodeKey,
      crud: true,
      crudStatus: 0,
      type,
    });
  }

  addParameterAsMain(type) {
    const getNodeKey = ({ treeIndex }) => treeIndex;
    this.setState({
      path: [],
      getNodeKey: getNodeKey,
      crud: false,
      crudStatus: 0,
      type,
      position: "right",
      mainToggle: false,
      open: !this.state.open,
      //qTypeInfo: this.state.configData.question_type_info,
      configdatacheckpoint: this.state.configData.question_type_info,
    });
  }

  addCheckpoint = (node, path, getNodeKey, type, udp, dp) => {

    this.setState({ position: "right" });
    this.setState({ open: !this.state.open });
    $("#" + udp).attr("class", "mb-0 bg-white dropright dropdown");
    $("#" + dp).attr("class", "dropdown-menu-end dropdown-menu");

    var getNodeInfo = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
    });
    this.setState({
      type,
      path: path,
      getNodeKey: getNodeKey,
      crud: false,
      editcrud: false,
      crudStatus: 1,
      title: getNodeInfo.node.title,
      getTitle: "",
      getSubTitle: "",
      getSubTitledd: "0",
      id:
        getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0
          ? getNodeInfo.node.id
          : getNodeInfo.node.children.length + 1,
      parent: getNodeInfo.node.id,
      mode: "0",
      //checkpointInfo: this.state.configData.question_type_info,
      configdatacheckpoint: this.state.configData.question_type_info,
    });
  };

  // AECheckPointUnderNode(values, mode) {
  //   console.log(
  //     "this.state.configdatacheckpoint",
  //     this.state.configdatacheckpoint
  //   );
  //   this.setState({ open: !this.state.open });
  //   if (mode == "0") {
  //     this.addCheckPointUnderNode(values);
  //   } else if (mode == "1") {
  //     this.editCheckpointAtPath(values);
  //   }
  // }

  AECheckPointUnderNode(values, mode) {
    var validate_no_of_img = values.checkpoint_options
    var max_img = validate_no_of_img.filter((e => {
      if (e.no_of_img > this.state.client_info.max_photos || e.score > this.state.client_info.max_score_value) {
        return e
      }
    }))

    if (mode == "0") {
      if (max_img.length == 0) {
        this.addCheckPointUnderNode(values)
        this.setState({ open: !this.state.open, })
      }
    }
    else
      if (mode == "1") {
        if (max_img.length == 0) {
          this.editCheckpointAtPath(values)
          this.setState({ open: !this.state.open, })
        }
      }
  }


  addCheckPointUnderNode(values) {
    const maxValue = values.checkpoint_options.reduce(
      (max, obj) => (obj.score > max ? obj.score : max),
      -Infinity
    );
    const minValue = values.checkpoint_options.reduce(
      (min, obj) => (obj.score < min ? obj.score : min),
      Infinity
    );

    values["max_score"] = maxValue;
    values["min_score"] = minValue;
    this.setState(
      (state) => ({
        treeData: addNodeUnderParent({
          treeData: state.treeData,
          parentKey: this.state.path[this.state.path.length - 1],
          expandParent: true,
          getNodeKey: this.state.getNodeKey,
          newNode: {
            company_id: this.state.userData.company_id,
            owner_id: this.state.userData._id,
            template_id: this.state.templateData._id,
            document_id: this.state.totalHLength + 1,
            parent_id: this.state.parent,
            document_type: String(this.state.type),
            checkpoint: values.checkpoint,
            checkpoint_type_id: values.checkpoint_type_id,
            checkpoint_type: values.checkpoint_type,
            enable_addOptns: values.enable_addOptns,
            checkpoint_options: values.checkpoint_options,
            min_score: values.min_score,
            max_score: values.max_score,
            custom_tbx: values.custom_tbx,
            impact_level: values.impact_level,
            compl_type: values.compl_type,
            creator_remarks: values.creator_remarks,
            guideline: values.guideline,
            guideline_image: values.guideline_image,
            id: this.state.totalHLength + 1,
            parent: this.state.parent,
            title: values.checkpoint,
            subtitle: "",
            type: this.state.type,
          },
          //addAsFirstChild: state.addAsFirstChild,
        }).treeData,
        crud: false,
        childToggle: false,
        mainToggle: false,
        editcrud: false,
        totalHLength: this.state.totalHLength + 1,
      }),
      function () {
        this.saveHStructure();
      }
    );
  }

  editCheckpoint = (path, getNodeKey, udp, dp) => {
    this.setState({ position: "right" });
    this.setState({ open: !this.state.open });
    $("#" + udp).attr("class", "mb-0 bg-white dropright dropdown");
    $("#" + dp).attr("class", "dropdown-menu-end dropdown-menu");

    var getNodeInfo = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
    });

this
    this.setState({
      crud: false,
      editcrud: false,
      crudStatus: 2,
      path: path,
      getNodeInfo: getNodeInfo.node,
      getNodeKey: getNodeKey,
      getCode: getNodeInfo.node.code,
      getTitle: getNodeInfo.node.title,
      type: getNodeInfo.node.type,
      mode: "1",
      checkpointInfo: [getNodeInfo.node],
      
    });
  };

  editCheckpointAtPath(values) {
    const maxValue = values.checkpoint_options.reduce(
      (max, obj) => (obj.score > max ? obj.score : max),
      -Infinity
    );
    const minValue = values.checkpoint_options.reduce(
      (min, obj) => (obj.score < min ? obj.score : min),
      Infinity
    );
    values["max_score"] = maxValue;
    values["min_score"] = minValue;
    this.setState(
      (state) => ({
        treeData: changeNodeAtPath({
          treeData: this.state.treeData,
          path: this.state.path,
          expandParent: true,
          newNode: {
            children: this.state.getNodeInfo.children,
            company_id: values.company_id,
            owner_id: values.owner_id,
            template_id: values.template_id,
            document_id: values.document_id,
            parent_id: values.parent_id,
            document_type: values.document_type,
            checkpoint: values.checkpoint,
            checkpoint_type_id: values.checkpoint_type_id,
            checkpoint_type: values.checkpoint_type,
            enable_addOptns: values.enable_addOptns,
            checkpoint_options: values.checkpoint_options,
            min_score: values.min_score,
            max_score: values.max_score,
            custom_tbx: values.custom_tbx,
            impact_level: values.impact_level,
            compl_type: values.compl_type,
            creator_remarks: values.creator_remarks,
            guideline: values.guideline,
            guideline_image: values.guideline_image,
            id: values.id,
            parent: values.parent,
            title: values.checkpoint,
            subtitle: "",
            type: this.state.type,
          },
          getNodeKey: this.state.getNodeKey,
        }),
        crud: false,
        editcrud: false,
        childToggle: false,
        mainToggle: false,
      }),
      function () {
        this.saveHStructure();
      }
    );
  }

  addNode = (node, path, getNodeKey, type) => {
    var getNodeInfo = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
    });
    this.setState({
      type,
      path: path,
      getNodeKey: getNodeKey,
      crud: true,
      editcrud: false,
      crudStatus: 1,
      title: getNodeInfo.node.title,
      getTitle: "",
      getSubTitle: "",
      getSubTitledd: "0",
      id:
        getNodeInfo.node.children == undefined || getNodeInfo.node.children == 0
          ? getNodeInfo.node.id
          : getNodeInfo.node.children.length + 1,
      parent: getNodeInfo.node.id,
    });
  };

  editNode = (path, getNodeKey,node) => {
    var getNodeInfo = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
    });

    this.setState({
      crud: false,
      editcrud: true,
      crudStatus: 2,
      path: path,
      getNodeInfo: getNodeInfo.node,
      getNodeKey: getNodeKey,
      getCode: getNodeInfo.node.code,
      getTitle: getNodeInfo.node.title,
      type: getNodeInfo.node.type,
      id: getNodeInfo.node.id,
      parent: getNodeInfo.node.parent,

      menuName: node.title,
    });
  };

  deleteNode = async (node, path, getNodeKey, udp, dp) => {
    $("#" + udp).attr("class", "mb-0 bg-white dropright dropdown");
    $("#" + dp).attr("class", "dropdown-menu-end dropdown-menu");

    this.setState(
      (state) => ({
        treeData: removeNodeAtPath({
          treeData: state.treeData,
          path,
          getNodeKey,
        }),
        crud: false,
      }),
      function () {
        this.saveHStructure();
      }
    );
  };

  crudNode = (event, values) => {
    this.onDrawerClose();
    console.log('this.state.crudStatus', this.state.crudStatus, values,this.state.menuName)
    let breadcrumb = this.state.breadcrumb + ' ' + '/' + ' ' + values.title
    if (this.state.crudStatus == 1 && this.state.menuName!=='') {
      //Add new node
      this.setState(
        (state) => ({
          treeData: addNodeUnderParent({
            treeData: state.treeData,
            parentKey: this.state.path[this.state.path.length - 1],
            expandParent: true,
            getNodeKey: this.state.getNodeKey,
            newNode: {
              company_id: this.state.userData.company_id,
              owner_id: this.state.userData._id,
              template_id: this.state.templateData._id,
              document_id: this.state.totalHLength + 1,
              parent_id: this.state.parent,
              document_type: String(this.state.type),
              checkpoint: values.title,
              checkpoint_type_id: null,
              checkpoint_type: null,
              enable_addOptns: false,
              checkpoint_options: [],
              min_score: 0,
              max_score: 0,
              custom_tbx: [],
              impact_level: null,
              compl_type: [],
              creator_remarks: " ",
              guideline: "",
              guideline_image: [],
              id: this.state.totalHLength + 1,
              parent: this.state.parent,
              title: values.title,
              subtitle: "",
              type: this.state.type,

              breadcrumb: breadcrumb
            },
            addAsFirstChild: state.addAsFirstChild,
          }).treeData,
          crud: false,
          childToggle: false,
          mainToggle: false,
          editcrud: false,
          totalHLength: this.state.totalHLength + 1,
          breadcrumb: breadcrumb
        }),
        function () {
          this.saveHStructure();
        }
      );
      this.setState({ dataLoaded: false }, () => { this.setState({ dataLoaded: true }) })
    } else if (this.state.crudStatus == 2) {
      // Edit node
      this.setState(
        (state) => ({
          treeData: changeNodeAtPath({
            treeData: this.state.treeData,
            path: this.state.path,
            expandParent: true,
            newNode: {
              children: this.state.getNodeInfo.children,
              company_id: this.state.getNodeInfo.company_id,
              owner_id: this.state.getNodeInfo.owner_id,
              template_id: this.state.getNodeInfo.template_id,
              document_type: this.state.getNodeInfo.document_type,
              document_id: this.state.getNodeInfo.document_id,
              parent_id: this.state.getNodeInfo.parent_id,
              checkpoint: this.state.menuName,


              checkpoint_type_id: this.state.getNodeInfo.checkpoint_type_id,
              checkpoint_type: this.state.getNodeInfo.checkpoint_type,
              enable_addOptns: this.state.getNodeInfo.enable_addOptns,
              checkpoint_options: this.state.getNodeInfo.checkpoint_options,
              min_score: this.state.getNodeInfo.min_score,
              max_score: this.state.getNodeInfo.max_score,
              custom_tbx: this.state.getNodeInfo.custom_tbx,
              impact_level: this.state.getNodeInfo.impact_level,
              compl_type: this.state.getNodeInfo.compl_type,
              creator_remarks: this.state.getNodeInfo.creator_remarks,
              guideline: this.state.getNodeInfo.guideline,
              guideline_image: this.state.getNodeInfo.guideline_image,
              title: this.state.menuName,
              subtitle: "",
              id: this.state.getNodeInfo.id,
              parent: this.state.getNodeInfo.parent,
              type: this.state.getNodeInfo.type,
            },
            getNodeKey: this.state.getNodeKey,
          }),
          crud: false,
          editcrud: false,
          childToggle: false,
          mainToggle: false,
        }),
        function () {
          this.saveHStructure();
        }
      );
    } else if (this.state.crudStatus == 0) {
      // let title = values.title
      // var temp = this.state.breadcrumb_titles
      // // console.log('temp', temp)
      // temp.push(title)    
      let breadcrumb = values.title + ' '
      // console.log('breadcrumb', breadcrumb, temp)
      this.setState(
        (state) => ({
          treeData: addNodeUnderParent({
            treeData: state.treeData,
            parentKey: this.state.path[this.state.path.length - 1],
            expandParent: true,
            getNodeKey: this.state.getNodeKey,
            newNode: {
              company_id: this.state.userData.company_id,
              owner_id: this.state.userData._id,
              template_id: this.state.templateData._id,
              document_id: this.state.totalHLength + 1,
              parent_id: null,
              document_type: String(this.state.type),
              checkpoint: values.title,
              checkpoint_type_id: null,
              checkpoint_type: null,
              enable_addOptns: false,
              checkpoint_options: [],
              min_score: 0,
              max_score: 0,
              custom_tbx: [],
              impact_level: null,
              compl_type: [],
              creator_remarks: [],
              guideline: "",
              guideline_image: [],
              id: this.state.totalHLength + 1,
              parent: null,
              title: values.title,
              subtitle: "",
              type: this.state.type,

              breadcrumb: breadcrumb
            },
            addAsFirstChild: state.addAsFirstChild,
          }).treeData,
          crud: false,
          mainToggle: false,
          totalHLength: this.state.totalHLength + 1,
          breadcrumb: breadcrumb
        }),
        function () {
          this.saveHStructure();
        }
      );
    }
  };

  dndNode = (droppedNode, getNodeKey) => {
    // if(droppedNode.nextParentNode !== null){
    console.log(droppedNode, getNodeKey)
    if (droppedNode.nextParentNode !== null && droppedNode.nextParentNode !== undefined) {
    this.setState(
      (state) => ({
        treeData: changeNodeAtPath({
          treeData: state.treeData,
          path: droppedNode.path,
          getNodeKey,
          newNode: {
            company_id: droppedNode.node.company_id,
            owner_id: droppedNode.node.owner_id,
            template_id: droppedNode.node.template_id,
            document_id: droppedNode.node.document_id,
            parent_id: droppedNode.nextParentNode !== null && droppedNode.nextParentNode !== undefined ? droppedNode.nextParentNode.id : null,
            parent: droppedNode.nextParentNode !== null && droppedNode.nextParentNode !== undefined ? droppedNode.nextParentNode.id : null,
            document_type: droppedNode.node.document_type,
            checkpoint: droppedNode.node.checkpoint,
            checkpoint_type_id: droppedNode.node.checkpoint_type_id,
            checkpoint_type: droppedNode.node.checkpoint_type,
            enable_addOptns: droppedNode.node.enable_addOptns,
            checkpoint_options: droppedNode.node.checkpoint_options,
            min_score: droppedNode.node.min_score,
            max_score: droppedNode.node.max_score,
            custom_tbx: droppedNode.node.custom_tbx,
            impact_level: droppedNode.node.impact_level,
            compl_type: droppedNode.node.compl_type,
            creator_remarks: droppedNode.node.creator_remarks,
            guideline: droppedNode.node.guideline,
            guideline_image: droppedNode.node.guideline_image,
            id: droppedNode.node.id,
            title: droppedNode.node.title,
            subtitle: droppedNode.node.subtitle,
            type: droppedNode.node.type,
            children: droppedNode.node.children,
          },
        }),
        crud: false,
      }),
      function () {
    console.log("765")
        this.saveHStructure();
      }
    );
    }
    // }else{
    // }
  };

  getNodeData(nodeData) {
    this.setState({ nodeTitle: nodeData.type != 2 ? nodeData.title : "", breadcrumb: nodeData.breadcrumb });
    if (nodeData.children != undefined) {
      if (nodeData.children.length != 0) {
        var getNodeKey = ({ treeIndex }) => treeIndex;
        var flatData = getFlatDataFromTree({
          treeData: nodeData.children,
          getNodeKey,
          ignoreCollapsed: false,
        });

        var explicitData = _.filter(flatData, (item) => {
          if (item.node.type == 2)
            return { node: item.node, parent: item.parentNode };
        });

        this.setState({ nodeChildren: explicitData });
      }
    } else {
      if (nodeData.type == 2) {
        this.setState({ nodeChildren: [{ node: nodeData, parentNode: null }] });
      } else {
        this.setState({ nodeChildren: [] });
      }
    }
  }

  toggle() {
    this.setState((prevState) => ({
      mainToggle: !prevState.mainToggle,
    }));
  }

  childtoggle(udp, dp) {
    $("#" + udp).attr("class", "mb-0 bg-white dropright dropdown");
    $("#" + dp).attr("class", "dropdown-menu-end dropdown-menu");
  }

  handleUserClicks = (arg) => {
    this.setState({ users: "", isEdit: false });
    this.toggle();
  };

  changeAuditEditMode = (status) => {
    this.setState({
      changeToUpdate: status,
      showCloseButton: status,
      modal: !this.state.modal ? true : false,
    });
  };

  setPosition(e) {
    this.setState({ position: e.target.value });
  }

  closeDrawer() {
    this.setState({ open: false });
  }
  onDrawerClose() {
    console.log(this.state.checkpointInfo,'checkpointInfo')
    var checkpointInfo =_.cloneDeep(this.state.checkpointInfo)
    //  [...this.state.checkpointInfo]
    this.setState({checkpointInfo: checkpointInfo}, ()=>{this.setState({ open: false });this.getDocuments()})
    
  }

  toggleToolTip = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }
  };

  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  getBoxShadowColor = (error, node, selectedNodeId) => {
    try {
      let color = "";
      if (error) {
        color = "3px 5px 10px 0px #e65555bf";
      } else if (selectedNodeId === node.type) {
        color = "3px 5px 7px 3px #556ee6bf";
      }
      return color;
    } catch (error) { }
  };

  getBorderColor = (error, node, selectedNodeId) => {
    try {
      let border = "1px solid #74788d";
      if (selectedNodeId === node.type) {
        border = "2px solid #556ee6";
      } else if (error) {
        border = "2px solid red";
      }
      return border;
    } catch (error) { }
  };

  getIcon = (node) => {
    try {
      let icon = "icon-c";
      if (node.error) {
        icon = "icon-e";
      } else if (node.type === 0) {
        icon = "icon-a";
      }

      if (node.type === 1) {
        icon = "icon-b";
      }
      if (node.type === 2) {
        icon = "icon-c";
      }
      if (node.type === 3) {
        icon = "icon-d";
      }
      return icon;
    } catch (error) {
      return "icon-a";
    }
  };
  rawerClose() {
    this.setState({ open: false, enableEdit: false });
  }

  audit_name = (e) => {
    var audit_name = e.target.value

    try {
      urlSocket.post('webmngtmplt/validate-user-templatemaster', { audit_name, encrypted_db_url: this.state.db_info.encrypted_db_url, user_id: this.state.userData._id, edit_mode: true, template_id: this.state.templateData._id }).then((response) => {
        console.log(response, 'response')
        if (response.data.response_code == 500 && response.data.data.length > 0) {
          this.setState({ audit_name_exist: true })

        }
        else {
          this.setState({ audit_name_exist: false})

        }
      })

    } catch (error) {

    }
    // this.setState({
    //   template_name: audit_name
    // })
  }

  handleValidUserSubmit = (event, values) => {
    console.log('handleValidUserSubmit',values)
    if (this.state.audit_name_exist !== true) {
      try {
        urlSocket
          .post("webmngtmplt/crudtemplate", {
            templateInfo: {
              template_id: this.state.templateData._id,
              template_name: values.template_name.trim(),
              template_info: values.template_info,
            },
            userInfo: {
              encrypted_db_url: this.state.db_info.encrypted_db_url,
              created_by: this.state.userData._id,
              company_id: this.state.userData.company_id,
            },
          })
          .then((response) => {
            if (response.data.response_code == 500) {
              sessionStorage.removeItem('EditData')
              sessionStorage.setItem("EditData", JSON.stringify(response.data.data))

              this.togglePopup()
              this.setState({
                successmessage: true,
                updateTemplateStatus: response.data.message,
                changeToUpdate: false,
                template_name: response.data.data.template_name,
                template_info: response.data.data.template_info,
              });
              setTimeout(() => {
                this.setState({ successmessage: false });
              }, 2500);
            }
          });
      } catch (error) { }
    }
  };

  gotoBack = () => {
    this.props.history.push('/mngmtrtmplt')
  }

  render() {
    const { department_name } = this.state;
    const { isEdit } = this.state;
    if (this.state.dataLoaded) {
      const getNodeKey = ({ treeIndex }) => treeIndex;
      return (
        <React.Fragment>
          <div className="page-content">

            <MetaTags>
              <title>AuditVista | Edit Template</title>
            </MetaTags>

            <Container fluid>

              <Breadcrumbs
                title={this.state.template_name}
                link={"Template / "}
                description={this.state.template_info}
                changeAuditEditMode={() => {
                  this.setState(prevState => ({
                    modal: !prevState.modal,
                    audit_name_exist : false
                  }))
                }}
                breadcrumbItem="Template"
                isBackButtonEnable={true}
                gotoBack={() => this.gotoBack()}
              />



              <div className="d-flex flex-row" style={{position: "relative", width:"100%", minHeight:"100vh" }} >

                <div 
                style={{ 
                  background: "white", 
                  width: this.state.nodeSelected  ? "calc(100% - 600px)" : "100%",
                  transition: "width .35s ease-in-out",
                  position: "absolute",
                  float: "left",
                }} 
                className="p-3 me-2">
                  <div className="row">
                    <div className="mb-2 col-10 ">
                      <UncontrolledDropdown
                        isOpen={this.state.mainToggle}
                        toggle={() => this.toggle()}
                      >
                        <DropdownToggle
                          className="btn btn-primary"
                          color="#eff2f7"
                          onClick={() => this.addMainNode(0)}
                        >
                          <i className="mdi mdi-plus me-1 "></i> Create New
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ width: 250 }}
                          className=""
                        >
                          <div className="px-4">
                            <AvForm onValidSubmit={this.crudNode}>
                              <div className="my-2">
                                <AvField
                                  name="title"
                                  label="Menu Name "
                                  placeholder="Enter Menu"
                                  type="text"
                                  errorMessage="Enter Menu"
                                  validate={{
                                    required: { value: true },
                                    minLength: {
                                      value: 4,
                                      errorMessage: "Min 4 chars.",
                                    },
                                  }}
                                  defaultValue={""}
                                  onChange={(e) => {
                                    this.setState({ menuName: e.target.value });
                                  }}
                                />
                              </div>
                              <div className="my-3">
                                <button
                                  className="btn btn-primary btn-block m-1"
                                  type="submit"
                                  style={{ marginRight: 5 }}
                                >
                                  Add Menu
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                      {
                        this.state.nodeSelected &&
                        <div>
                        <button className="btn btn-md btn-primary" onClick={() => {
                          this.setState({
                            selectedNodeId:null,
                            nodeSelected: false
                          })
                        }}>Expand <i className="mdi mdi-arrow-right" /></button>
                        </div>
                      }
                    </div>
                  </div>

                  <div style={{overflow: "visible",  minHeight:"70vh", height: this.state.Nodecount * 70,   }}>
                    <SortableTree
                      treeData={this.state.treeData}
                      onChange={(treeData, node) => {
                        this.setState({ treeData, crud: false },
                          () => {
                            this.setState({
                              Nodecount:getVisibleNodeCount({treeData:this.state.treeData}),
                            })
                          }
                          );
                      }}
                      onMoveNode={(object) => this.dndNode(object, getNodeKey)}
                      canDrop={(object) =>
                        object.nextParent !== undefined && object.nextParent.type == 2
                          ? false
                          : true
                      }
                      scaffoldBlockPxWidth={40}
                      slideRegionSize={25}
                      // rowHeight={50}
                      generateNodeProps={({ node, path }) => {
                        // console.log("node", node)
                        return (
                          {
                            listIndex: 0,
                            lowerSiblingCounts: [],
                            className: node.type === 2 ? "icon-a" : "icon-b",
                            onClick: (event) => {
                              if (
                                event.target.className.includes("collapseButton") ||
                                event.target.className.includes("expandButton")
                              ) {
                              } else {
                                this.setState({
                                  selectedNodeId: node.id,
                                  nodeSelected: true,
                                });
                                this.getNodeData(node);
                              }
                            },
                            style: {
                              border: this.state.selectedNodeId === node.id ? "2px solid #556EE6" : '1px solid #c3cacd',
                              backgroundImage: "url('../../../../assets/images/drag-and-drop-7.png')"
                            },

                            title: (
                              <div>
                                <div style={{ maxWidth: 450 }} key={`div-${node.id}`}>

                                  {
                                    this.state.editcrud && this.state.id === node.id ?
                                      <AvForm onValidSubmit={this.crudNode}>
                                        <div className="d-flex flex-row align-items-center">
                                          <div className="me-2 p-0 mt-3">
                                            <AvField
                                              name="title"
                                              placeholder="Enter Menu Name"
                                              className="form-control py-1 m-0"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                                minLength: {
                                                  value: 4,
                                                  errorMessage: "Min 4 chars.",
                                                },
                                              }}
                                              // value={!this.state.menuName ? this.state.getTitle : this.state.menuName}
                                              value={this.state.menuName}
                                              onChange={(e) => {
                                                this.setState({
                                                  menuName: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="d-flex flex-row">
                                            <button
                                              className="btn btn-sm btn-secondary "
                                              type="submit"
                                              // onClick={(event) =>
                                              //   this.childtoggle(
                                              //     "udp" + String(node.id),
                                              //     "dp" + String(node.id)
                                              //   )
                                              // }
                                              style={{ marginRight: 3 }}
                                            >
                                              Update
                                            </button>
                                            <Link
                                              to="#"
                                              className="btn btn-sm btn-soft-danger"
                                              onClick={() =>
                                                this.setState({ editcrud: false, id: null })
                                              }
                                              id={`closetooltip-${node.id}`}
                                            >
                                              <i className="mdi mdi-close" />
                                              <UncontrolledTooltip placement="top" target={`closetooltip-${node.id}`} >
                                                {"Close"}
                                              </UncontrolledTooltip>
                                            </Link>
                                          </div>
                                        </div>
                                      </AvForm>
                                      :
                                      <div>
                                        <Tooltip
                                          placement="bottom"
                                          isOpen={this.isToolTipOpen(`btn-${node.id}`)}
                                          target={`btn-${node.id}`}
                                          toggle={() =>
                                            this.toggleToolTip(`btn-${node.id}`)
                                          }
                                        >
                                          {node.title}
                                        </Tooltip>
                                        <Link
                                          to="#"
                                          id={`btn-${node.id}`}
                                          style={{ fontSize: 12, fontWeight: 400 }}
                                        >
                                          {String(node.title).slice(0, 40) +
                                            (node.title?.length > 40 ? "..." : "")}
                                          {/* {node.title} */}
                                        </Link>
                                      </div>
                                  }
                                </div>
                              </div>
                            ),

                            buttons: [
                              <Row className="" key={node.id}>
                                <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
                                  {
                                    this.state.editcrud && this.state.id === node.id ?
                                      null :
                                      <li>
                                        <Link
                                          to="#"
                                          className="btn btn-sm btn-soft-info"
                                          onClick={() =>
                                            node.type === 2 ?
                                              this.editCheckpoint(path, getNodeKey, "udp" + String(node.id), "dp" + String(node.id)) :
                                              this.editNode(path, getNodeKey,node)
                                          }
                                          id={`edittooltip-${node.id}`}
                                        >
                                          <i className="mdi mdi-pencil-outline" />
                                          <UncontrolledTooltip placement="top" target={`edittooltip-${node.id}`} >
                                            {node.type == 2 ? "Edit Checkpoint" : "Edit Menu"}
                                          </UncontrolledTooltip>
                                        </Link>
                                      </li>

                                  }

                                  {node.type === 0 &&
                                    <UncontrolledDropdown
                                      direction="end"
                                    >
                                      <DropdownToggle
                                        className="card-drop"
                                        tag="a"
                                      >
                                        <Link
                                          to="#"
                                          className="btn btn-sm btn-soft-primary"
                                          id={`viewtooltip-${node.id}`}
                                          onClick={() => this.addNode(node, path, getNodeKey, 0)}
                                        >
                                          <i className="mdi mdi-file-tree" />
                                          <UncontrolledTooltip placement="top" target={`viewtooltip-${node.id}`}>
                                            Add Submenu
                                          </UncontrolledTooltip>
                                        </Link>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-end "
                                        style={{ width: 220, }}
                                        id={"dp" + String(node.id)}
                                      >
                                        {this.state.crud && node.type == 0 ? (
                                          <div className="px-4">
                                            <AvForm onValidSubmit={this.crudNode} >
                                              <div className="my-2">
                                                <AvField
                                                  name="title"
                                                  label="Sub Menu Name "
                                                  placeholder="Enter Sub Menu"
                                                  type="text"
                                                  errorMessage="Enter Menu"
                                                  validate={{
                                                    required: { value: true },
                                                    minLength: {
                                                      value: 4,
                                                      errorMessage: "Min 4 chars.",
                                                    },
                                                  }}
                                                  defaultValue={""}
                                                  onChange={(e) => {
                                                    this.setState({
                                                      menuName: e.target.value,
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="my-3">
                                                <button
                                                  className="btn btn-primary btn-block m-1"
                                                  type="submit"
                                                  // onClick={() =>
                                                  //   this.childtoggle(
                                                  //     "udp" + String(node.id),
                                                  //     "dp" + String(node.id)
                                                  //   )
                                                  // }
                                                  style={{ marginRight: 5 }}
                                                >
                                                  Add Menu
                                                </button>
                                              </div>
                                            </AvForm>
                                          </div>
                                        ) : null}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  }


                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      onClick={() => this.deleteNode(
                                        node,
                                        path,
                                        getNodeKey,
                                        "udp" + String(node.id),
                                        "dp" + String(node.id)
                                      )}
                                      id={`deletetooltip-${node.id}`}
                                    >
                                      <i className="mdi mdi-delete-outline" />
                                      <UncontrolledTooltip placement="top" target={`deletetooltip-${node.id}`}>
                                        {node.type == 2 ? "Delete Checkpoint" : "Delete Menu"}
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>

                                  {
                                    node.type === 0 &&
                                    <li>
                                      <Link
                                        to="#"
                                        className={"btn btn-sm btn-success"}
                                        onClick={() =>
                                          this.addCheckpoint(
                                            node,
                                            path,
                                            getNodeKey,
                                            2,
                                            "udp" + String(node.id),
                                            "dp" + String(node.id)
                                          )
                                        }
                                        id={`shareaudit-${node.id}`}
                                      >
                                        <i className="mdi mdi-clipboard-text-outline" />
                                        <UncontrolledTooltip placement="top" target={`shareaudit-${node.id}`}>
                                          {"Add Checkpoint"}
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>
                                  }


                                </ul>
                              </Row>

                            ],
                          })
                      }
                      }
                    />
                  </div>
                </div>

                {
                  <div style={{
                    position:"absolute",
                    right:this.state.nodeSelected || this.state.expanded ? 0 : -620,
                    transition: "right .35s ease-in-out",
                    width:600,
                    }}>
                  <div className="ms-2">
                    {
                      <div style={{ height: "100vh", overflow: "auto" }}>
                        <div
                          className={
                            this.state.nodeTitle != "" ? "mb-1 p-3 bg-white" : ""
                          }
                        >
                          <span>{this.state.nodeTitle}</span>
                        </div>
                        {this.state.nodeChildren.map((item, i) => {
                          var getColor =
                            item.node.impact_level == "Critical"
                              ? "#f46a6a"
                              : item.node.impact_level == "High"
                                ? "#f1b44c"
                                : item.node.impact_level == "Medium"
                                  ? "#50a5f1"
                                  : item.node.impact_level == "Low"
                                    ? "#34c38f"
                                    : "#556ee6";
                          return (
                            <div
                              className="mb-1 d-flex flex-column p-3 bg-white"
                              key={"cp" + i}
                              style={{
                                borderBottom: "1px solid",
                                borderColor: getColor,
                              }}
                            >
                              <div>
                                <span className="font-size-10 text-secondary">
                                  {item.parentNode != null
                                    ? item.parentNode.title
                                    : ""}
                                </span>
                              </div>
                              <div className="my-2">
                                <span style={{ color: getColor }}>
                                  {String(i).length > 1 ? i + 1 : "0" + (i + 1)}
                                </span>{" "}
                                <span className="font-size-13">
                                  {item.node.checkpoint}
                                </span>
                              </div>
                              <div className="my-2">
                                {item.node.checkpoint_options.map(
                                  (child, idx) => (
                                    <div
                                      className="d-flex flex-column p-2 my-1"
                                      style={{
                                        border: "1px solid #f0f0f0",
                                        borderRadius: 10,
                                      }}
                                      key={"cp" + i + "ptn" + idx}
                                    >
                                      <span>
                                        {child.option_text}{" "}
                                        {child.enable_img ? (
                                          <i className="mdi mdi-camera-plus" />
                                        ) : null}{" "}
                                        {child.enable_video ? (
                                          <i className="mdi mdi-video-plus" />
                                        ) : null}{" "}
                                        {child.enable_doc ? (
                                          <i className="mdi mdi-file-document" />
                                        ) : null}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                              {item.node.impact_level != "" ? (
                                <div className="my-2">
                                  <span
                                    className="badge badge-soft p-1 font-size-12"
                                    style={{ backgroundColor: getColor }}
                                  >
                                    {item.node.impact_level}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    }
                  </div>
                </div>

                }


              </div>

              {this.state.saveAndSuccess && (
                <SweetAlert
                  title={this.state.messageHeading}
                  danger={this.state.messageHeading == "Delete" ? true : false}
                  success={this.state.messageHeading == "Success" ? true : false}
                  showCancel={this.state.messageHeading == "Success" ? false : true}
                  confirmBtnBsStyle={
                    this.state.messageHeading == "Success" ? "success" : "danger"
                  }
                  cancelBtnBsStyle={
                    this.state.messageHeading == "Delete" ? "success" : "danger"
                  }
                  onConfirm={() => this.confirmation()}
                  onCancel={() => this.setState({ saveAndSuccess: false })}
                >
                  {this.state.message}
                </SweetAlert>
              )}


              <Drawer
                open={this.state.open}
                onClose={this.onDrawerClose}
                direction='right'
                size={'700px'}
                zIndex={9999}
                noOverlay={false}
              // className='bla bla bla'
              >
                <div
                  style={{
                    padding: 10,
                    maxHeight: this.state.height,
                    overflow: "auto",
                  }}
                >
                {
                  console.log(this.state.checkpointInfo,'this.state.checkpointInfo',this.state.mode,this.state.treeData)
                }
                  {this.state.open ? (
                    <InputTemplate
                      checkpointinfo={this.state.checkpointInfo}
                      configdatacheckpoint={
                        this.state.configData.question_type_info
                      }
                      mode={this.state.mode}
                      parameterData={true}
                      onClose={()=>{this.onDrawerClose()}}
                      onSubmit={(values) => {
                        this.AECheckPointUnderNode(values, this.state.mode);
                      }}
                    />
                  ) : null}
                </div>
              </Drawer>

              <Modal
                isOpen={this.state.modal}
                className={this.props.className}
              >
                <ModalHeader toggle={this.togglePopup} tag="h5">
                  {"Edit Template Information"}
                </ModalHeader>
                <ModalBody>

                  <AvForm onValidSubmit={this.handleValidUserSubmit} onKeyDown={(e) => { e.key === "Enter" && e.preventDefault() }}>
                    <Row>
                      <Col>
                        <div className="my-2">
                          <AvField
                            name="template_name"
                            label="Audit Name"
                            placeholder="Enter Audit Name"
                            type="text"
                            onChange={(e) => { this.audit_name(e) }}
                            errorMessage="Enter Audit Name"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 6,
                                errorMessage: "Min 6 chars.",
                              },
                            }}
                            defaultValue={this.state.template_name}
                          />
                          {
                            this.state.audit_name_exist &&
                            <div className="text-danger" style={{ fontSize: 'smaller' }}>This name already exists, please give another name.</div>
                          }
                        </div>
                        <div className="my-1">
                          <label>Description</label>
                          <AvField
                            type="textarea"
                            name="template_info"
                            id="message"
                            defaultValue={this.state.template_info}
                            onChange={(e) => {
                              this.setState({
                                template_info: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="my-3">
                          <button
                            className="btn btn-secondary btn-block"
                            style={{ marginRight: 5 }}
                            disabled={this.state.audit_name_exist}
                            type="submit"
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-danger btn-block"
                            type="button"
                            onClick={() =>
                              this.setState({
                                template_name: this.state.templateData.template_name,
                                modal: false
                              })
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>




                  {/* <AvForm onValidSubmit={this.handleValidUserSubmit} onKeyDown={(e) => { e.key == "Enter" ? e.preventDefault() : console.log("null") }} >
                    <Row>
                      <Col>
                        <div className="my-2">
                          <AvField
                            name="template_name"
                            label="Audit Name "
                            placeholder="Enter Audit Name"
                            type="text"
                          
                            onChange={(e) => { this.audit_name(e) }}
                            // onChange={(e) => {
                            //   this.setState({
                            //     template_name: e.target.value
                            //   })
                            // }}
                            errorMessage="Enter Audit Name"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 6,
                                errorMessage: "Min 6 chars.",
                              },
                            }}
                            defaultValue={this.state.template_name}
                          />
                          {
                            this.state.audit_name_exist &&
                            <div className="text-danger" style={{ fontSize: 'smaller' }}>This name already exist , give another name.</div>
                          }
                        </div>
                        <div className="my-1">
                          <label>Description</label>
                          <AvField
                            type="textarea"
                            name="template_info"
                            id="message"
                            defaultValue={this.state.template_info}
                            onChange={(e) => {
                              this.setState({
                                template_info: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="my-3">
                          <button
                            className="btn btn-secondary btn-block"
                            style={{ marginRight: 5 }}
                            disabled={this.state.audit_name_exist}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-danger btn-block"
                            onClick={() =>
                              this.setState({
                                // modal: false,
                                template_name : this.state.templateData.template_name
                              })
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm> */}

                </ModalBody>
              </Modal>


            </Container>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className="page-content"
            style={{
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="spinner-border text-secondary m-1" role="status">
                {" "}
              </div>
              <div>Loading, Please wait.</div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}






