import React, { Component } from "react"
import { isEmpty } from "lodash"
import MetaTags from 'react-meta-tags';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

import { Row, Col, CardBody, Card, Container, Alert, Button } from "reactstrap"



class AddEOP extends Component {


    handleValidSubmit(event, values){


        var endpointInfo = values
        this.props.onsubmit(values)

    }

    render() {
        return (
            <CardBody className="pt-0">
                <div className="p-5">
                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


                        <Row className="my-4">
                            <div className="text-danger font-size-18">Location Information</div>
                            <hr className="my-4" />
                        </Row>

                        <Row>
                            <Col className="col-12">
                                <div className="mb-3" >
                                    <AvField
                                        name="entity_name"
                                        label="Name"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        type="text"
                                        required
                                    />
                                </div>
                            </Col>
                            </Row>
                            <Row>
                            <Col className="col-12">
                                <div className="mb-3" >
                                    <AvField
                                        name="entity_code"
                                        label="Code"
                                        className="form-control"
                                        placeholder="Enter Code"
                                        type="text"
                                        required
                                    />
                                </div>
                            </Col>
                            </Row>
                            <Row>
                            <Col className="col-12">
                                <div className="mb-3" >
                                    <AvField
                                        name="endpoint_type"
                                        label="Location Type"
                                        className="form-control"
                                        placeholder="Enter Location Type"
                                        type="text"
                                        required
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className="mb-3" >

                        </div>
                        {/* {
                            this.state.alertEnable ?
                                <div className="mt-4 d-grid">
                                    <Alert color={this.state.alertColor}>
                                        {this.state.message}
                                    </Alert>
                                </div>
                                : null
                        } */}


                        <div className="mt-4 d-grid">
                            <button
                                className="btn btn-danger btn-block"
                                type="submit"
                            >
                                Add Location
                            </button>
                        </div>

                    </AvForm>
                </div>
            </CardBody>
        )
    }
}

export default AddEOP