import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList } from '../../store/reportd3/reportslice';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './Components/TableContainer';
import { setReportUser, setSelectedReportUser,publishSelectedUser } from '../../store/reportd3/reportslice';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, Button, Spinner } from 'reactstrap'
import { Link ,useHistory} from 'react-router-dom';
import Swal from "sweetalert2";
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const ReportUser = () => {
    const [authuser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [pageInfo, setpageInfo] = useState(JSON.parse(sessionStorage.getItem("page_data")))
    const [selectedId, setselectedId] = useState([])
    const dispatch = useDispatch()
    const reportSlice = useSelector(state => state.reportSliceReducer)
    const history = useHistory()
    const reportUsers = reportSlice.reportUsers
    const selectedReportUser = reportSlice.selectedReportUser
    console.log(reportUsers, 'reportUsers')

    useEffect(() => {
        dispatch(getUserList(authuser, pageInfo))

    }, [])


    const selectUser = (row, index) => {
        var selected_user = []
        console.log(reportUsers, 'reportUsers')
        const userInfo = reportUsers.map(user => {
            if (user._id === row._id) {
                if (row.selected) {
                    selected_user.push(user)
                }
                return { ...user, selected: row.selected };
            }
            if (user.selected) {
                selected_user.push(user)
            }
            return user;
        });

        var getIndex = selectedId.indexOf(row._id)
        if (getIndex === -1) {
            selectedId.push(row._id)
        }
        else {
            selectedId.splice(getIndex, 1)
        }
        console.log(userInfo, 'userInfo')
        dispatch(setReportUser(userInfo))
        dispatch(setSelectedReportUser(selected_user))
        setselectedId(selectedId)
    }

    const addselectedUser=()=>{

        Swal.fire({
            icon: 'warning', // You can use 'warning', 'error', or any other appropriate icon
            title: 'Publish Confirmation',
            text: 'Are you sure you want to publish this users?', // Customize the confirmation message
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Publish',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if(result.isConfirmed){
                dispatch(publishSelectedUser(selectedReportUser,pageInfo,authuser,history))
            }
        })
    }


    const selectAllFun = (isSelect, rows, e, mode) => {
        let selectedEOPT = [];
        let selected_id = [];
        if (isSelect) {
            selectedEOPT = rows;
            rows.forEach((data) => {
                selected_id.push(data._id);
            });
        }
        dispatch(setSelectedReportUser(selectedEOPT))
        setselectedId(selected_id)
    }


    const columns = [
        {
            Header: (cellProps) =>
                <div className="form-check font-size-16" >
                    <input
                        className="form-check-input"
                        onChange={() => {
                            console.log(cellProps.page, 'console')
                            const allSelected = cellProps.page.every((row) => row.original.selected);
                            console.log(allSelected, 'allSelected', cellProps.page)
                            const updatedPage = cellProps.page.map(row => ({
                                ...row,
                                original: {
                                    ...row.original,
                                    selected: !allSelected
                                }
                            }));
                            console.log(updatedPage, 'updatedPage')
                            dispatch(setReportUser(_.map(updatedPage, "original")))
                            selectAllFun(!allSelected, _.map(updatedPage, "original"), "e", '1')
                        }}
                        type="checkbox"
                        checked={cellProps.page.length > 0 && cellProps.page.every((row) => row.original.selected)}
                        id="checkAll"
                    />
                    <label className="form-check-label" htmlFor="checkAll"></label>
                </div>,
            accessor: '#',
            width: '10%',
            filterable: true,
            isSort: false,
            Cell: ({ row }) => (
                <div className="form-check font-size-16" >
                    <input className="form-check-input" checked={row.original.selected} type="checkbox" onChange={(e) => {
                        const updatedRow = { ...row.original, selected: !row.original.selected };
                        console.log(updatedRow, 'updatedRow');
                        selectUser(updatedRow, row.index)
                    }}
                        id="checkAll" />
                    <label className="form-check-label" htmlFor="checkAll"></label>
                </div>
            )
        },

        {
            accessor: 'name',
            Header: <span className="">Name</span>,
            filterable: true,
            width: "30%",
            Cell: (cellProps) => {
                var item = cellProps.row.original
                return (
                    <div className="fw-bold">
                        <span style={{ fontSize: '13px', color: '#848484' }}>{item.firstname}</span>

                    </div>


                )

            }
        },
        {
            accessor: 'email_id',
            Header: <span className="">Email ID</span>,
            filterable: true,
            width: "15%",
            Cell: (cellProps) => {
                var item = cellProps.row.original
                return (
                    <>
                        <div className=" text-secondary">
                            <span style={{ fontSize: '13px', color: '#848484' }}>{item.email_id}</span>
                        </div>
                    </>
                )

            }
        },
        {
            accessor: 'role',
            Header: <span className="">Role Info</span>,
            filterable: true,
            width: "15%",
            Cell: (cellProps) => {
                var item = cellProps.row.original
                return (
                    <>
                        <div className=" text-secondary">
                            <span style={{ fontSize: '13px', color: '#848484' }}>{item.role}</span>
                        </div>
                    </>


                )

            }
        }

    ]



    return (<React.Fragment>
        <div className="page-content">
            <MetaTags>
                <title>AuditVista | Manage Users</title>
            </MetaTags>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-1">
                <div style={{ width: '80%', padding: '0 20px' }}>Publishing</div>
                <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Link to={"/report"}><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
                </div>
            </div>
            <Container fluid>
                <Breadcrumbs title="Report Users" breadcrumbItem="Report Users" />
                <Row>
                    {
                        reportUsers ?
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <TableContainer
                                            data={reportUsers}
                                            columns={columns}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            isJobListGlobalFilter={false}
                                            customPageSize={10}
                                            style={{ width: '100%' }}
                                            isPagination={true}
                                            filterable={false}
                                            tableClass="align-middle table-nowrap table-check"
                                            theadClass="table-light"
                                            pagination="pagination pagination-rounded justify-content-end mb-2"
                                            showBtn ={false}

                                        />
                                        {
                                            selectedReportUser.length > 0 &&
                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn btn-danger btn-block"
                                                    type="submit"
                                                    onClick={() => { addselectedUser() }}
                                                >
                                                    Publish Report for Selected Users
                                                </button>
                                            </div>

                                        }
                                    </CardBody>
                                </Card>

                            </Col>
                            :
                            <Col lg="12">
                                <Card>
                                    <CardBody style={{ height: "100vh" }}>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <div>Loading...</div>
                                            <Spinner className="ms-2" color="primary" />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                    }
                </Row>
            </Container>
        </div>
    </React.Fragment>);
}

ReportUser.propTypes = propTypes;
ReportUser.defaultProps = defaultProps;
// #endregion

export default ReportUser;