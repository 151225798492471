import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    Media,
    Row,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap"

export default class MediaPreview extends Component {

    mediaStatus(message) {
        return (
            <UncontrolledDropdown>
                <DropdownToggle
                    href="#"
                    className="dropdown-toggle"
                    tag="a"
                >
                    <i className="mdi mdi-alert-circle-outline text-danger" />
                </DropdownToggle>
                <DropdownMenu direction="left">
                    <DropdownItem href="#" onClick={() => this.props.retryUpload(message)}>
                        Retry
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    render() {
        var f = this.props.f
        var preview = this.props.f.originalName
        return (
            <Row >
                {
                    f.filetype === "application/zip" ?
                        <Col className="col-auto">
                            <i className="fas fa-file-archive text-warning" style={{ fontSize: 50 }} />
                        </Col>
                        :
                        f.filetype === "application/pdf" ?
                            <Col className="col-auto">
                                <i className="fas fa-file-pdf text-danger" style={{ fontSize: 50 }} />
                            </Col>
                            :
                            f.filetype === "application/xls" || f.filetype === "application/xlsx" || f.filetype === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                <Col className="col-auto">
                                    <i className="fas fa-file-excel text-success" style={{ fontSize: 50 }} />
                                </Col>
                                :
                                f.filetype === "image/jpeg" ?
                                    <Col className="col-auto">
                                        <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.originalName}
                                            src={this.props.imagePreviewUrl + preview}
                                        />
                                    </Col> :
                                    <Col className="col-auto">
                                        <i className="fas fa-file text-primary" style={{ fontSize: 50 }} />
                                    </Col>


                }

                <Col >
                {/* <a href={`${this.props.imagePreviewUrl + preview}`} target="_blank" >

                    <Form method="get" action={preview}>
                        <button
                            type="submit"
                            className="text-muted font-weight-bold "
                            style={{ textAlign: 'left', padding: 0, background: 'none', border: 'none' }}
                        >{f.originalName}
                        </button>
                        </a> */}
                    {/* </Form> */}


                    <a href={`${this.props.imagePreviewUrl + preview}`} target="_blank" rel="noreferrer">
                        <button
                            type="submit"
                            className="text-muted font-weight-bold "
                            style={{ textAlign: 'left', padding: 0, background: 'none', border: 'none' }}
                        >
                            {f.originalName}
                        </button>
                    </a>


                    <p className="mb-0 font-size-11">
                        <strong>{f.formattedSize}</strong>
                    </p>
                </Col>
                {
                    this.props.audit_status !== "3" && this.props.audit_status !== "4" ?
                    <>
                    <div className="col-2 text-end me-2">
                    <Link to="#" onClick={() => this.props.deletedocuments(this.props.index)}><i className="mdi mdi-close-circle-outline font-size-20 text-danger" /></Link>
                </div>
                <div className="mt-1">
                    <span className={f.uploading ? "font-size-10 text-danger" : "font-size-10 text-success"}>{f.uploadingStatus}</span>
                </div>
                    </>:null
                }
                

            </Row>

        )
    }


}
