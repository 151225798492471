import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

const CalendarPreview = props => {

  const [event, setEvent] = useState([]);

  const repeat_start_className ='bg-pink external-event fc-event text-white'
  const repeat_ends_className ='bg-dark external-event fc-event text-white'

  const calculateEndDate = (start_date, occurence,mode) => {
    console.log(start_date, occurence,'start_date, occurence',mode)
    if(mode === "Day"){
    var startDate = new Date(start_date);
    var occurrence = parseInt(occurence-1, 10);

    if (!isNaN(occurrence)) {
      startDate.setDate(startDate.getDate() + occurrence);

      const endYear = startDate.getFullYear();
      const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
      const endDay = String(startDate.getDate()).padStart(2, '0');
      console.log(`${endYear}-${endMonth}-${endDay}`, '`${endYear}-${endMonth}-${endDay}`;')
      return `${endYear}-${endMonth}-${endDay}`;
    }
    }
    if(mode === "Week"){
      const startsOn = new Date(start_date);
      const occurrenceCount = occurence;
      if (props.event_data.repeat_duration === undefined) {
        props.event_data["repeat_duration"] = "1"
      }
      const repeatWeeks = props.event_data.repeat_duration; // Assuming weekly repeat, adjust as needed

      const endDate = new Date(startsOn);
      endDate.setDate(startsOn.getDate() + (occurrenceCount * repeatWeeks * 7));

      console.log(endDate.toISOString().split('T')[0]);  // Output: "2023-12-15"
      return endDate.toISOString().split('T')[0]
    }
  }

   useEffect(() => {
    console.log(props)
    var audit_info = JSON.parse(sessionStorage.getItem('publishData'))
    console.log(audit_info,'audiy',props.event_data)
    if (props.event_data.selected_repeat === "Day") {
      if (props.occurence === true) {

        var end_date = calculateEndDate(props.event_data.starts_on, props.event_data.occurence,props.event_data.selected_repeat)
        console.log(end_date, 'end_date')
        var eventsData = [{
          className
            :
            "bg-success external-event fc-event text-white",
          id
            :
            52,
          start
            :
            "2023-11-06T07:59:50.000Z",
          title
            :
            // "Audit Day"
            audit_info.template_name
        }]
        const selected_repeat = "Day";
        var formattedDate
        if(props.event_data.starts_on !=="immediate"){
          var formattedDate =props.event_data.starts_on
        }
        else{
          const date = new Date();
          var formattedDate = date.toISOString().split('T')[0];
          console.log(formattedDate, 'formattedDate')
        }
       
        if (props.event_data.repeat_duration === undefined) {
          props.event_data["repeat_duration"] = "1"
        }

        var events = generateEvents(formattedDate, end_date, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
        console.log(events, 'events')
        setEvent(events)
      }
      else {
        var eventsData = [{
          className
            :
            "bg-success external-event fc-event text-white",
          id
            :
            52,
          start
            :
            "2023-11-06T07:59:50.000Z",
          title
            :
            // "Audit Day"
            audit_info.template_name

        }]
        // { title: "Repeat Starts", start: "2023-11-29",className: 'bg-pink external-event fc-event text-white' },
        const selected_repeat = "Day";
        if(props.event_data.starts_on !=="immediate"){
          var formattedDate =props.event_data.starts_on
          console.log(formattedDate,'formattedDate')
        }
        else{
          const date = new Date();
          var formattedDate = date.toISOString().split('T')[0];
          console.log(formattedDate,date, 'formattedDate')
        }
        if (props.event_data.repeat_duration === undefined) {
          props.event_data["repeat_duration"] = "1"
        }
        var events = generateEvents(formattedDate, props.event_data.ends, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
        var repeat_starts={
          title :"Repeat Starts",
          start : formattedDate,
          className :repeat_start_className
        }
        var repeat_ends={
          title :"Repeat Ends",
          start : props.event_data.ends,
          className :repeat_ends_className
        }

        //   { title: "Repeat Ends", start: "2023-12-08",className: 'bg-dark external-event fc-event text-white' },
        events.push(repeat_starts,repeat_ends)
        console.log(events, 'events')
        
        setEvent(events)

      }
    }
    if (props.event_data.selected_repeat === "Week") {
      console.log("week functionality")
      if (props.occurence === true) {
        var end_date = calculateEndDate(props.event_data.starts_on, props.event_data.occurence,props.event_data.selected_repeat)
        console.log(end_date, 'end_date')
        var eventsData = [{
          className
            :
            "bg-success external-event fc-event text-white",
          id
            :
            52,
          start
            :
            "2023-11-06T07:59:50.000Z",
          title
            :
            // "Audit Day"
            audit_info.template_name

        }]
        const selected_repeat = "Day";
        var formattedDate
        if(props.event_data.starts_on !=="immediate"){
          var formattedDate =props.event_data.starts_on
        }
        else{
          const date = new Date();
          var formattedDate = date.toISOString().split('T')[0];
          console.log(formattedDate, 'formattedDate')
        }
       
        if (props.event_data.repeat_duration === undefined) {
          props.event_data["repeat_duration"] = "1"
        }

        var events = generateEvents(formattedDate, end_date, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
        console.log(events, 'events')
        setEvent(events)

      }
      else{
      var eventsData = [{
        className
          :
          "bg-success external-event fc-event text-white",
        id
          :
          52,
        start
          :
          "2023-11-06T07:59:50.000Z",
        title
          :
            audit_info.template_name
            // "Audit Day"
      }]
      if (props.event_data.repeat_duration === undefined) {
        props.event_data["repeat_duration"] = "1"
      }
      const selected_repeat = "Day";
      const date = new Date();
      if(props.event_data.starts_on !=="immediate"){
        var formattedDate =props.event_data.starts_on
      }
      else{
        const date = new Date();
        var formattedDate = date.toISOString().split('T')[0];
        console.log(formattedDate, 'formattedDate')
      }
      var getSelectedWeekDays = _.filter(props.weekDays, { checked: true })
      console.log(getSelectedWeekDays, 'getSelectedWeekDays')
      var events = generateEvents(formattedDate, props.event_data.ends, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
      console.log(events, 'events')
      const filteredEvents = events.filter(event => {
        var eventDayId = event.start.getDay().toString();
        var isDayChecked = getSelectedWeekDays.some(day => day.day_id === eventDayId)
        if (isDayChecked) {
          return eventDayId;
        }
      });
      console.log(filteredEvents, 'filteredEvents',events)
      setEvent(filteredEvents.length === 0 ? events : filteredEvents)
    }
    }
  }, [])



  


  const generateEvents = (start, end, repeatType, repeatDuration, eventData, mode) => {
    if (mode === "Day") {
      const events = [];
      let currentDate = new Date(start);

      // Convert end date string to a Date object
      const endDate = new Date(end);
      while (currentDate <= endDate) {
      console.log(currentDate,endDate)
        
        events.push({
          ...eventData,
          start: new Date(currentDate),
        });

        // Increment the date based on the repeat type and duration
        switch (repeatType) {
          case "Day":
            currentDate.setDate(currentDate.getDate() + repeatDuration);
            break;
          // Add more cases for other repeat types if needed
          default:
            break;
        }
      }

      return events;
    }
    if (mode === "Week") {

      const events = [];
      let currentDate = new Date(start);
      const endDate = new Date(end);
    
      while (currentDate <= endDate) {
        events.push({
          ...eventData,
          start: new Date(currentDate),
        });
    
        // Increment the date by 7 days
        currentDate.setDate(currentDate.getDate() + 7 * repeatDuration);
      }
    
      // Check if the last generated date is beyond the end date
      if (events.length > 0 && events[events.length - 1].start > endDate) {
        events.pop(); // Remove the last event if it's beyond the end date
      }
    
      return events;

    }
  };


  // const event = [
  //   { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Sat Dec 09 2023 03:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //   // { className: 'bg-primary external-event fc-event text-white', id: 52, start: new Date('Sat Dec 09 2023 05:30:00 GMT+0530').toISOString(),end: new Date('Sun Dec 11 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //   { className: 'bg-primary external-event fc-event text-white', id: 52, start: new Date('Sat Dec 09 2023 05:30:00 GMT+0530').toISOString(),end: new Date('Sun Dec 11 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
   
  //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Mon Dec 11 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Tue Dec 12 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Wed Dec 13 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //  // { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Thu Dec 14 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //  // { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Fri Dec 15 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //  // { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Sat Dec 16 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //  // { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Sun Dec 17 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Mon Dec 18 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
  //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Tue Dec 19 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' }
  // ];

  // const event = [
  //   { title: "Repeat Starts", start: "2023-11-29",className: 'bg-pink external-event fc-event text-white' },
  //   { title: "Q1-Audit Publish", start: "2023-12-01",className: 'bg-success external-event fc-event text-white' },
  //   { title: "Q1-Audit Publish", start: "2023-12-03",className: 'bg-success external-event fc-event text-white' },
  //   { title: "Audit Opens", start: "2023-12-03", end: "2023-12-05" },
  //   { title: "Q1-Audit Publish", start: "2023-12-05",className: 'bg-success external-event fc-event text-white' },
  //   { title: "Audit Opens", start: "2023-12-05", end: "2023-12-07" },
  //   { title: "Audit Opens", start: "2023-12-01", end: "2023-12-03" },
  //   { title: "Q1-Audit Publish", start: "2023-12-08",className: 'bg-success external-event fc-event text-white' },
  //   { title: "Audit Ends", start: "2023-12-02",className: 'bg-danger external-event fc-event text-white' },
  //   { title: "Audit Ends", start: "2023-12-04",className: 'bg-danger external-event fc-event text-white' },
  //   { title: "Audit Ends", start: "2023-12-06",className: 'bg-danger external-event fc-event text-white' },
  //   { title: "Repeat Ends", start: "2023-12-08",className: 'bg-dark external-event fc-event text-white' },
  //   { title: "Audit Opens", start: "2023-12-08T15:00:00", end: "2023-12-08T16:00:00" },
  // ];
  
  
  // console.log(event);
  
  const legendItems = [
    { color: '#556ee6', label: 'Audit Opens' },
    { color: '#33c38f', label: 'Audit Publish' },
    { color: '#f46969', label: 'Audit Ends' },
    { color: '#e83d8c', label: 'Repeat Starts' },
    { color: '#74788c', label: 'Repeat Ends' },


    // Add more items as needed
  ];
 

  return (
    <React.Fragment>
      <Row>
      <Col xl={9}>
        <Card>
          <CardBody>
            {
              console.log(event,'event')
            }
            <FullCalendar
              plugins={[
                BootstrapTheme,
                dayGridPlugin,
                listPlugin,
                interactionPlugin,
              ]}
              // slotDuration={"00:15:00"}
              handleWindowResize={true}
              themeSystem="bootstrap"
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
              }}
              events={event}
              editable={true}
              droppable={true}
              selectable={true}
              slotDuration="00:30:00" // Set the duration of each slot to 30 minutes
              slotMinTime="02:00:00" // Set the earliest time to 2 am
              slotMaxTime="08:00:00"
            // dateClick={handleDateClick}
            // eventClick={handleEventClick}
            // drop={onDrop}
            />
          </CardBody>
        </Card>
      </Col>
      <Col md={3}>
      <label>Color code</label>
      <ul>
        {legendItems.map((item, index) => (
          <li key={index}>
            <span style={{ backgroundColor: item.color, width: '20px', height: '20px', display: 'inline-block', marginRight: '5px' }}></span>
            {item.label}
          </li>
        ))}
      </ul>
      </Col>
      </Row>
    </React.Fragment>
  );
};

export default CalendarPreview;















// import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// // import { isEmpty } from "lodash";

// import {
//   Button,
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Form,
//   FormFeedback,
//   Input,
//   Label,
//   Modal,
//   ModalBody,
//   ModalHeader,
//   Row,
// } from "reactstrap";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import listPlugin from '@fullcalendar/list';
// import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
// import BootstrapTheme from "@fullcalendar/bootstrap";

// const CalendarPreview = props => {

//   const [event, setEvent] = useState([]);

//   const repeat_start_className ='bg-pink external-event fc-event text-white'
//   const repeat_ends_className ='bg-dark external-event fc-event text-white'

//   const calculateEndDate = (start_date, occurence,mode) => {
//     console.log(start_date, occurence,'start_date, occurence',mode)
//     if(mode === "Day"){
//     var startDate = new Date(start_date);
//     var occurrence = parseInt(occurence-1, 10);

//     if (!isNaN(occurrence)) {
//       startDate.setDate(startDate.getDate() + occurrence);

//       const endYear = startDate.getFullYear();
//       const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');
//       const endDay = String(startDate.getDate()).padStart(2, '0');
//       // publishTemplate["repeat_mode_config"]["end_date"] = `${endYear}-${endMonth}-${endDay}`
//       console.log(`${endYear}-${endMonth}-${endDay}`, '`${endYear}-${endMonth}-${endDay}`;')
//       return `${endYear}-${endMonth}-${endDay}`;
//     }
//     }
//     if(mode === "Week"){
//       const startsOn = new Date(start_date);
//       const occurrenceCount = occurence;
//       if (props.event_data.repeat_duration === undefined) {
//         props.event_data["repeat_duration"] = "1"
//       }
//       const repeatWeeks = props.event_data.repeat_duration; // Assuming weekly repeat, adjust as needed

//       const endDate = new Date(startsOn);
//       endDate.setDate(startsOn.getDate() + (occurrenceCount * repeatWeeks * 7));

//       console.log(endDate.toISOString().split('T')[0]);  // Output: "2023-12-15"
//       return endDate.toISOString().split('T')[0]
//     }
//   }




//   // useEffect(() => {
//   //   console.log(props)
//   //   var audit_info = JSON.parse(sessionStorage.getItem('publishData'))
//   //   console.log(audit_info,'audiy')
//   //   if (props.event_data.selected_repeat === "Day") {
//   //     if (props.occurence === true) {

//   //       var end_date = calculateEndDate(props.event_data.starts_on, props.event_data.occurence,props.event_data.selected_repeat)
//   //       console.log(end_date, 'end_date')
//   //       var eventsData = [{
//   //         className
//   //           :
//   //           "bg-success external-event fc-event text-white",
//   //         id
//   //           :
//   //           52,
//   //         start
//   //           :
//   //           "2023-11-06T07:59:50.000Z",
//   //         title
//   //           :
//   //           // "Audit Day"
//   //           audit_info.template_name
//   //       }]
//   //       const selected_repeat = "Day";
//   //       var formattedDate
//   //       if(props.event_data.starts_on !=="immediate"){
//   //         var formattedDate =props.event_data.starts_on
//   //       }
//   //       else{
//   //         const date = new Date();
//   //         var formattedDate = date.toISOString().split('T')[0];
//   //         console.log(formattedDate, 'formattedDate')
//   //       }
       
//   //       if (props.event_data.repeat_duration === undefined) {
//   //         props.event_data["repeat_duration"] = "1"
//   //       }

//   //       var events = generateEvents(formattedDate, end_date, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
//   //       console.log(events, 'events')
//   //       setEvent(events)
//   //     }
//   //     else {
//   //       var eventsData = [{
//   //         className
//   //           :
//   //           "bg-success external-event fc-event text-white",
//   //         id
//   //           :
//   //           52,
//   //         start
//   //           :
//   //           "2023-11-06T07:59:50.000Z",
//   //         title
//   //           :
//   //           // "Audit Day"
//   //           audit_info.template_name

//   //       }]
//   //       const selected_repeat = "Day";
//   //       if(props.event_data.starts_on !=="immediate"){
//   //         var formattedDate =props.event_data.starts_on
//   //       }
//   //       else{
//   //         const date = new Date();
//   //         var formattedDate = date.toISOString().split('T')[0];
//   //         console.log(formattedDate, 'formattedDate')
//   //       }
//   //       if (props.event_data.repeat_duration === undefined) {
//   //         props.event_data["repeat_duration"] = "1"
//   //       }
//   //       var events = generateEvents(formattedDate, props.event_data.ends, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
//   //       console.log(events, 'events')
//   //       setEvent(events)

//   //     }
//   //   }
//   //   if (props.event_data.selected_repeat === "Week") {
//   //     console.log("week functionality")
//   //     if (props.occurence === true) {
//   //       var end_date = calculateEndDate(props.event_data.starts_on, props.event_data.occurence,props.event_data.selected_repeat)
//   //       console.log(end_date, 'end_date')
//   //       var eventsData = [{
//   //         className
//   //           :
//   //           "bg-success external-event fc-event text-white",
//   //         id
//   //           :
//   //           52,
//   //         start
//   //           :
//   //           "2023-11-06T07:59:50.000Z",
//   //         title
//   //           :
//   //           // "Audit Day"
//   //           audit_info.template_name

//   //       }]
//   //       const selected_repeat = "Day";
//   //       var formattedDate
//   //       if(props.event_data.starts_on !=="immediate"){
//   //         var formattedDate =props.event_data.starts_on
//   //       }
//   //       else{
//   //         const date = new Date();
//   //         var formattedDate = date.toISOString().split('T')[0];
//   //         console.log(formattedDate, 'formattedDate')
//   //       }
       
//   //       if (props.event_data.repeat_duration === undefined) {
//   //         props.event_data["repeat_duration"] = "1"
//   //       }

//   //       var events = generateEvents(formattedDate, end_date, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
//   //       console.log(events, 'events')
//   //       setEvent(events)

//   //     }
//   //     else{
//   //     var eventsData = [{
//   //       className
//   //         :
//   //         "bg-success external-event fc-event text-white",
//   //       id
//   //         :
//   //         52,
//   //       start
//   //         :
//   //         "2023-11-06T07:59:50.000Z",
//   //       title
//   //         :
//   //           audit_info.template_name
//   //           // "Audit Day"
//   //     }]
//   //     if (props.event_data.repeat_duration === undefined) {
//   //       props.event_data["repeat_duration"] = "1"
//   //     }
//   //     const selected_repeat = "Day";
//   //     const date = new Date();
//   //     if(props.event_data.starts_on !=="immediate"){
//   //       var formattedDate =props.event_data.starts_on
//   //     }
//   //     else{
//   //       const date = new Date();
//   //       var formattedDate = date.toISOString().split('T')[0];
//   //       console.log(formattedDate, 'formattedDate')
//   //     }
//   //     var getSelectedWeekDays = _.filter(props.weekDays, { checked: true })
//   //     console.log(getSelectedWeekDays, 'getSelectedWeekDays')
//   //     var events = generateEvents(formattedDate, props.event_data.ends, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
//   //     console.log(events, 'events')
//   //     const filteredEvents = events.filter(event => {
//   //       var eventDayId = event.start.getDay().toString();
//   //       var isDayChecked = getSelectedWeekDays.some(day => day.day_id === eventDayId)
//   //       if (isDayChecked) {
//   //         return eventDayId;
//   //       }
//   //     });
//   //     console.log(filteredEvents, 'filteredEvents',events)
//   //     setEvent(filteredEvents.length === 0 ? events : filteredEvents)
//   //   }
//   //   }
//   // }, [])

//    useEffect(() => {
//     console.log(props)
//     var audit_info = JSON.parse(sessionStorage.getItem('publishData'))
//     console.log(audit_info,'audiy',props.event_data)
//     if (props.event_data.selected_repeat === "Day") {
//       if (props.occurence === true) {

//         var end_date = calculateEndDate(props.event_data.starts_on, props.event_data.occurence,props.event_data.selected_repeat)
//         console.log(end_date, 'end_date')
//         var eventsData = [{
//           className
//             :
//             "bg-success external-event fc-event text-white",
//           id
//             :
//             52,
//           start
//             :
//             "2023-11-06T07:59:50.000Z",
//           title
//             :
//             // "Audit Day"
//             audit_info.template_name
//         }]
//         const selected_repeat = "Day";
//         var formattedDate
//         if(props.event_data.starts_on !=="immediate"){
//           var formattedDate =props.event_data.starts_on
//         }
//         else{
//           const date = new Date();
//           var formattedDate = date.toISOString().split('T')[0];
//           console.log(formattedDate, 'formattedDate')
//         }
       
//         if (props.event_data.repeat_duration === undefined) {
//           props.event_data["repeat_duration"] = "1"
//         }

//         var events = generateEvents(formattedDate, end_date, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
//         console.log(events, 'events')
//         setEvent(events)
//       }
//       else {
//         var eventsData = [{
//           className
//             :
//             "bg-success external-event fc-event text-white",
//           id
//             :
//             52,
//           start
//             :
//             "2023-11-06T07:59:50.000Z",
//           title
//             :
//             // "Audit Day"
//             audit_info.template_name

//         }]
//         // { title: "Repeat Starts", start: "2023-11-29",className: 'bg-pink external-event fc-event text-white' },
//         const selected_repeat = "Day";
//         if(props.event_data.starts_on !=="immediate"){
//           var formattedDate =props.event_data.starts_on
//           console.log(formattedDate,'formattedDate')
//         }
//         else{
//           const date = new Date();
//           var formattedDate = date.toISOString().split('T')[0];
//           console.log(formattedDate,date, 'formattedDate')
//         }
//         if (props.event_data.repeat_duration === undefined) {
//           props.event_data["repeat_duration"] = "1"
//         }
//         var events = generateEvents(formattedDate, props.event_data.ends, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
//         var repeat_starts={
//           title :"Repeat Starts",
//           start : formattedDate,
//           className :repeat_start_className
//         }
//         var repeat_ends={
//           title :"Repeat Ends",
//           start : props.event_data.ends,
//           className :repeat_ends_className
//         }

//         //   { title: "Repeat Ends", start: "2023-12-08",className: 'bg-dark external-event fc-event text-white' },
//         events.push(repeat_starts,repeat_ends)
//         console.log(events, 'events')
        
//         setEvent(events)

//       }
//     }
//     if (props.event_data.selected_repeat === "Week") {
//       console.log("week functionality")
//       if (props.occurence === true) {
//         var end_date = calculateEndDate(props.event_data.starts_on, props.event_data.occurence,props.event_data.selected_repeat)
//         console.log(end_date, 'end_date')
//         var eventsData = [{
//           className
//             :
//             "bg-success external-event fc-event text-white",
//           id
//             :
//             52,
//           start
//             :
//             "2023-11-06T07:59:50.000Z",
//           title
//             :
//             // "Audit Day"
//             audit_info.template_name

//         }]
//         const selected_repeat = "Day";
//         var formattedDate
//         if(props.event_data.starts_on !=="immediate"){
//           var formattedDate =props.event_data.starts_on
//         }
//         else{
//           const date = new Date();
//           var formattedDate = date.toISOString().split('T')[0];
//           console.log(formattedDate, 'formattedDate')
//         }
       
//         if (props.event_data.repeat_duration === undefined) {
//           props.event_data["repeat_duration"] = "1"
//         }

//         var events = generateEvents(formattedDate, end_date, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
//         console.log(events, 'events')
//         setEvent(events)

//       }
//       else{
//       var eventsData = [{
//         className
//           :
//           "bg-success external-event fc-event text-white",
//         id
//           :
//           52,
//         start
//           :
//           "2023-11-06T07:59:50.000Z",
//         title
//           :
//             audit_info.template_name
//             // "Audit Day"
//       }]
//       if (props.event_data.repeat_duration === undefined) {
//         props.event_data["repeat_duration"] = "1"
//       }
//       const selected_repeat = "Day";
//       const date = new Date();
//       if(props.event_data.starts_on !=="immediate"){
//         var formattedDate =props.event_data.starts_on
//       }
//       else{
//         const date = new Date();
//         var formattedDate = date.toISOString().split('T')[0];
//         console.log(formattedDate, 'formattedDate')
//       }
//       var getSelectedWeekDays = _.filter(props.weekDays, { checked: true })
//       console.log(getSelectedWeekDays, 'getSelectedWeekDays')
//       var events = generateEvents(formattedDate, props.event_data.ends, selected_repeat, Number(props.event_data.repeat_duration), eventsData[0], props.event_data.selected_repeat)
//       console.log(events, 'events')
//       const filteredEvents = events.filter(event => {
//         var eventDayId = event.start.getDay().toString();
//         var isDayChecked = getSelectedWeekDays.some(day => day.day_id === eventDayId)
//         if (isDayChecked) {
//           return eventDayId;
//         }
//       });
//       console.log(filteredEvents, 'filteredEvents',events)
//       setEvent(filteredEvents.length === 0 ? events : filteredEvents)
//     }
//     }
//   }, [])



  


//   const generateEvents = (start, end, repeatType, repeatDuration, eventData, mode) => {
//     if (mode === "Day") {
//       const events = [];
//       let currentDate = new Date(start);

//       // Convert end date string to a Date object
//       const endDate = new Date(end);

//       while (currentDate <= endDate) {
//         events.push({
//           ...eventData,
//           start: new Date(currentDate),
//         });

//         // Increment the date based on the repeat type and duration
//         switch (repeatType) {
//           case "Day":
//             currentDate.setDate(currentDate.getDate() + repeatDuration);
//             break;
//           // Add more cases for other repeat types if needed
//           default:
//             break;
//         }
//       }

//       return events;
//     }
//     if (mode === "Week") {

//       const events = [];
//       let currentDate = new Date(start);
//       const endDate = new Date(end);
    
//       while (currentDate <= endDate) {
//         events.push({
//           ...eventData,
//           start: new Date(currentDate),
//         });
    
//         // Increment the date by 7 days
//         currentDate.setDate(currentDate.getDate() + 7 * repeatDuration);
//       }
    
//       // Check if the last generated date is beyond the end date
//       if (events.length > 0 && events[events.length - 1].start > endDate) {
//         events.pop(); // Remove the last event if it's beyond the end date
//       }
    
//       return events;



//       //make audit covered
//       // const startDate = new Date(start);
//       // const endDate = new Date(end);
      
//       // const dates = [];
//       // let currentDate = startDate;
      
//       // for (let i = 0; i < repeatDuration; i++) {
//       //   dates.push({
//       //     start: new Date(currentDate),
//       //     end: new Date(currentDate.setDate(currentDate.getDate() + 6)), // Assuming a week is 7 days
//       //   });
    
//       //   // Skip to the next occurrence
//       //   currentDate.setDate(currentDate.getDate() + 7);
//       // }
    
//       // // Filter out dates beyond the specified end date
//       // return dates.filter(dateRange => dateRange.start <= endDate && dateRange.end <= endDate);








//     //   if( repeatDuration === 1){

//     //     const events = [];
//     //   let currentDate = new Date(start);

//     //   // Convert end date string to a Date object
//     //   const endDate = new Date(end);

//     //   while (currentDate <= endDate) {
//     //     events.push({
//     //       ...eventData,
//     //       start: new Date(currentDate),
//     //     });

//     //     // Increment the date based on the repeat type and duration
//     //     switch (repeatType) {
//     //       case "Day":
//     //         currentDate.setDate(currentDate.getDate() + repeatDuration);
//     //         // currentDate.setDate(currentDate.getDate() + 7 * repeatDuration); // Increment by weeks
//     //         break;
//     //       // Add more cases for other repeat types if needed
//     //       default:
//     //         break;
//     //     }
//     //   }

//     //   return events;
//     // }
//     // else{


//     //   const startDate = new Date(start);
//     //   const endDate = new Date(end);
      
//     //   const events = [];
//     //   let currentDate = startDate;
      
//     //   while (currentDate <= endDate) {
//     //     // Generate events for the current week
//     //     for (let day = 0; day < 7; day++) {
//     //       if (currentDate <= endDate) {
//     //         events.push({
//     //           className: "bg-success external-event fc-event text-white",
//     //           id: 52,
//     //           start: new Date(currentDate),
//     //           title: "Audit Day",
//     //         });
      
//     //         currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
//     //       }
//     //     }
      
//     //     // Skip to the next occurrence after the specified duration (in days)
//     //     currentDate.setDate(currentDate.getDate() + repeatDuration * 7 - 7);
//     //   }
      
//     //   // Filter out dates beyond the specified end date
//     //   return events.filter((event) => event.start <= endDate);

//     // }

//       // const events = [];
//       // let currentDate = new Date(start);

//       // // Convert end date string to a Date object
//       // const endDate = new Date(end);

//       // while (currentDate <= endDate) {
//       //   events.push({
//       //     ...eventData,
//       //     start: new Date(currentDate),
//       //   });

//       //   // Increment the date based on the repeat type and duration
//       //   switch (repeatType) {
//       //     case "Day":
//       //       currentDate.setDate(currentDate.getDate() + repeatDuration);
//       //       // currentDate.setDate(currentDate.getDate() + 7 * repeatDuration); // Increment by weeks
//       //       break;
//       //     // Add more cases for other repeat types if needed
//       //     default:
//       //       break;
//       //   }
//       // }

//       // return events;

//     }
//   };


//   // const event = [
//   //   { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Sat Dec 09 2023 03:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //   // { className: 'bg-primary external-event fc-event text-white', id: 52, start: new Date('Sat Dec 09 2023 05:30:00 GMT+0530').toISOString(),end: new Date('Sun Dec 11 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //   { className: 'bg-primary external-event fc-event text-white', id: 52, start: new Date('Sat Dec 09 2023 05:30:00 GMT+0530').toISOString(),end: new Date('Sun Dec 11 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
   
//   //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Mon Dec 11 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Tue Dec 12 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Wed Dec 13 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //  // { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Thu Dec 14 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //  // { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Fri Dec 15 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //  // { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Sat Dec 16 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //  // { className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Sun Dec 17 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Mon Dec 18 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' },
//   //   //{ className: 'bg-success external-event fc-event text-white', id: 52, start: new Date('Tue Dec 19 2023 05:30:00 GMT+0530').toISOString(), title: 'example_1' }
//   // ];

//   // const event = [
//   //   { title: "Repeat Starts", start: "2023-11-29",className: 'bg-pink external-event fc-event text-white' },
//   //   { title: "Q1-Audit Publish", start: "2023-12-01",className: 'bg-success external-event fc-event text-white' },
//   //   { title: "Q1-Audit Publish", start: "2023-12-03",className: 'bg-success external-event fc-event text-white' },
//   //   { title: "Audit Opens", start: "2023-12-03", end: "2023-12-05" },
//   //   { title: "Q1-Audit Publish", start: "2023-12-05",className: 'bg-success external-event fc-event text-white' },
//   //   { title: "Audit Opens", start: "2023-12-05", end: "2023-12-07" },
//   //   { title: "Audit Opens", start: "2023-12-01", end: "2023-12-03" },
//   //   { title: "Q1-Audit Publish", start: "2023-12-08",className: 'bg-success external-event fc-event text-white' },
//   //   { title: "Audit Ends", start: "2023-12-02",className: 'bg-danger external-event fc-event text-white' },
//   //   { title: "Audit Ends", start: "2023-12-04",className: 'bg-danger external-event fc-event text-white' },
//   //   { title: "Audit Ends", start: "2023-12-06",className: 'bg-danger external-event fc-event text-white' },
//   //   { title: "Repeat Ends", start: "2023-12-08",className: 'bg-dark external-event fc-event text-white' },
//   //   { title: "Audit Opens", start: "2023-12-08T15:00:00", end: "2023-12-08T16:00:00" },
//   // ];
  
  
//   // console.log(event);
  
//   const legendItems = [
//     { color: '#556ee6', label: 'Audit Opens' },
//     { color: '#33c38f', label: 'Audit Publish' },
//     { color: '#f46969', label: 'Audit Ends' },
//     { color: '#e83d8c', label: 'Repeat Starts' },
//     { color: '#74788c', label: 'Repeat Ends' },


//     // Add more items as needed
//   ];
 

//   return (
//     <React.Fragment>
//       <Row>
//       <Col xl={9}>
//         <Card>
//           <CardBody>
//             {
//               console.log(event,'event')
//             }
//             <FullCalendar
//               plugins={[
//                 BootstrapTheme,
//                 dayGridPlugin,
//                 listPlugin,
//                 interactionPlugin,
//               ]}
//               // slotDuration={"00:15:00"}
//               handleWindowResize={true}
//               themeSystem="bootstrap"
//               headerToolbar={{
//                 left: "prev,next today",
//                 center: "title",
//                 right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
//               }}
//               events={event}
//               editable={true}
//               droppable={true}
//               selectable={true}
//               slotDuration="00:30:00" // Set the duration of each slot to 30 minutes
//               slotMinTime="02:00:00" // Set the earliest time to 2 am
//               slotMaxTime="08:00:00"
//             // dateClick={handleDateClick}
//             // eventClick={handleEventClick}
//             // drop={onDrop}
//             />
//           </CardBody>
//         </Card>
//       </Col>
//       <Col md={3}>
//       <label>Color code</label>
//       <ul>
//         {legendItems.map((item, index) => (
//           <li key={index}>
//             <span style={{ backgroundColor: item.color, width: '20px', height: '20px', display: 'inline-block', marginRight: '5px' }}></span>
//             {item.label}
//           </li>
//         ))}
//       </ul>
//       </Col>
//       </Row>
//     </React.Fragment>
//   );
// };

// export default CalendarPreview;
