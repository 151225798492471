import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
} from "reactstrap";
import CryptoJS from 'crypto-js'
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
import TagsInput from 'react-tagsinput'
import { LoadingOutlined } from '@ant-design/icons';
import TableContainer from "./LocationComponents/LoadTableContainer";

// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import SweetAlert from "react-bootstrap-sweetalert"
import 'react-tagsinput/react-tagsinput.css'
// import "../ManagePublishedTemplate/CSS"



const _ = require('lodash')
// var urlSocket = require("../../helpers/urlSocket")
import urlSocket from "../../../helpers/urlSocket";

export default class LoadLocation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            selectedEOPT:[],
            dup_selectedEOPT:[],
            all_selectedEOPT:[],
            selected_id :[],
            secret_key: 'Pkdh347ey%3Tgs',
            userdata :[],
            over_all_data :[],
            modal :false,
            country_code_err : false,
            invalid_user : false,
            dataAlert : false,
            LocationInfo:[],
            labelData :[],            
            statusBasedFilteredData:[],
            dsbl_slctd_lctn: false
        };

    }


    toggle =()=>{
        this.setState(prevState => ({
            modal: !prevState.modal
          }));
    }


    async componentDidMount() {
        var db_info = JSON.parse(sessionStorage.getItem('db_info'))
        var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0]
        var sessionUserInfo = JSON.parse(sessionStorage.getItem("authUser"))
        var LocationInfo = JSON.parse(sessionStorage.getItem("Locationdata"))
        this.setState({
            db_info,
            client_info,
            sessionUserInfo :sessionUserInfo,
            user_data :sessionUserInfo.user_data,
            LocationInfo

        },()=>{this.loadEOPTS()})        
    }
    

    loadEOPTS = () => {
        this.setState({ tableDataloaded: false })
        try {
            urlSocket.post("webEntities/endpointlist", {
                userInfo: {
                    encrypted_db_url:this.state.db_info.encrypted_db_url,
                    company_id: this.props.userInfo.company_id,
                    user_id: this.props.userInfo._id, 
                },
            })
                .then((response) => {
                    console.log(response,'response',this.state.selectedEOPT)
                    const filtered_locations = response.data.data.filter((item) => !this.state.LocationInfo.some((el) => el.code=== item.code));
                    const updt_filterlocaion =   filtered_locations.filter((item)=> !this.props.toFilterData.some((el) => el.code === item.code));
                    console.log(updt_filterlocaion,'updt_filterlocaion',this.props.toFilterData)
                    updt_filterlocaion.map((data,idx)=>{
                        updt_filterlocaion[idx]["adt_user"]=[]
                    })

                    this.setState({
                        statusBasedFilteredData: updt_filterlocaion,
                        over_all_data: response.data.data,
                        tableDataloaded: true,
                        allowTableRowToSelect: true,
                        labelDefault: true,
                        label: {
                            endpoints: []
                        },


                    }, function () {
                        this.get_user_list()
                        this.loadUserLabels()
                    })

                })
        } catch (error) {

        }
    }


    loadUserLabels() {
        try {
            urlSocket.post("userSettings/getuserlabels", {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.props.userInfo._id,
                    company_id: this.props.userInfo.company_id
                }
            })
                .then(response => {
                    console.log('response', response)
                    this.setState({
                        labelData: response.data.data,
                        dataLoaded: true

                    })
                })

        } catch (error) {
            console.log("catch error", error)
        }
    }

    get_user_list = () => {

        try {
            urlSocket.post("cog/get-user-info", { db_info: this.state.db_info }
            )
                .then((response) => {
                    if (response.data.response_code === 500) {
                        this.setState({
                            userdata: response.data.user_list,
                            dataLoaded: true

                        })
                    }
                })
        }
        catch (error) {
            console.log(error, 'error')
        }

    }

    labelSelected(data) {
        console.log('data', data.target.value)
        if (data.target.value === "all") {
            this.loadEOPTS()
        }else {
            var mylabel = this.state.labelData[data.target.value]
            this.setState({
                tableDataloaded: false, selectedLabel: mylabel._id,
                label: mylabel
            })

            try {
    
                urlSocket.post("webEntities/loadlabeleopts", {
                    labelInfo: mylabel,
                    userInfo: {
                        encrypted_db_url: this.state.db_info.encrypted_db_url,
                        _id: this.props.userInfo._id,
                        company_id: this.props.userInfo.company_id
                    }
                })
                    .then(response => {
                    const filtered_locations = response.data.data.filter((item) => !this.state.LocationInfo.some((el) => el.code === item.code));
                        const updt_filterlocaion =   filtered_locations.filter((item)=> !this.props.toFilterData.some((el) => el.code === item.code));
                        updt_filterlocaion.map((data,idx)=>{
                            updt_filterlocaion[idx]["adt_user"]=[]
                        })
                        console.log('updt_filterlocaion', updt_filterlocaion)
                        this.setState({
                            statusBasedFilteredData: updt_filterlocaion,
                            labelDefault: false,
                            tableDataloaded: true
                        })
                    })
    
            } catch (error) {
                console.log("catch error", error)
            }
    
        }

    }

    // getSelectedEOPT = (row, isSelect, rowIndex, e) => {
    //     console.log('this.state.selectedEOPT',this.state.selectedEOPT,row, isSelect, rowIndex)
    //     var getEOPTId = _.map(this.state.selectedEOPT, "_id")
    //     var getIndex = getEOPTId.indexOf(row._id)
    //     console.log(getIndex,'getIndex',getEOPTId)
    //     if (getIndex === -1) {
    //         this.state.selectedEOPT.push(row)
    //         var temp = this.state.all_selectedEOPT
    //         console.log('temp', temp)
    //         temp.push(row)
    //         console.log('temp', temp)
    //         this.setState({ all_selectedEOPT: temp })
    //         // console.log(this.state.selectedEOPT[rowIndex]._id)
    //         this.setState({
    //             selectedEOPT: temp
    //         },()=>{this.handleSelectAll(isSelect,0,this.state.selectedEOPT);console.log(this.state.selectedEOPT,'selectedEOPT')})
    //     }
    //     else {
    //         this.state.selectedEOPT.splice(getIndex, 1)
    //         console.log("else")
    //         this.setState({
    //             selectedEOPT: this.state.selectedEOPT
    //         },()=>{ this.handleSelectAll(this.state.selectedEOPT)})
    //     }

    //     console.log("this.state.selectedEOPT", this.state.selectedEOPT)

    // }

    getSelectedEOPT = (row, isSelect, rowIndex, e) => {

        var getEOPTId = _.map(this.state.selectedEOPT, "_id")
        var getIndex = getEOPTId.indexOf(row._id)

        if (getIndex === -1) {
            this.state.selectedEOPT.push(row)

            this.setState({
                selectedEOPT: this.state.selectedEOPT
            })
        }
        else {
            this.state.selectedEOPT.splice(getIndex, 1)
            this.setState({
                selectedEOPT: this.state.selectedEOPT
            })
        }


    }






    addEndpoints(){
        console.log(this.state.selectedEOPT,'this.state.selectedEOPT')
        try {
            urlSocket.post('cog/crud-new-location', {
                userInfo: {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    _id: this.state.user_data._id,
                    company_id: this.state.user_data.company_id,
                },
                endpointInfo:this.state.selectedEOPT,
                auditInfo:this.props.publishtemplateInfo
            }).then((response)=>{
                if(response.data.response_code == 500){
                    // this.setState({ dataLoaded: false }, () => {
                    //     this.setState({dataLoaded:true},()=>{    
                        // this.loadEOPTS()
                        this.setState({dataLoaded: false}, ()=>{this.props.onClose();this.props.getUnpublishedLocation()})
                        
                        
                    // })
                // })

                }
                else{
                    var errorMessage = response.data.error
                    console.log(errorMessage)
                }
            })
            
        } catch (error) {
                console.log(error,'error')
        }

        // try {
        //     var loggedUserInfo = {
        //         encrypted_db_url: this.state.db_info.encrypted_db_url,
        //         company_id: this.props.userInfo.company_id,
        //         company_name: this.props.userInfo.company_name,
        //         created_by:this.props.userInfo._id
        //     }
        //     urlSocket.post("webphlbconf/addmultipleendpoints", {
        //         userInfo: loggedUserInfo,
        //         publishtemplateInfo: this.props.publishtemplateInfo,
        //         endpointInfo: this.state.selectedEOPT
        //     })
        //     .then(response=>{
        //         console.log(response,'response')
        //         if(response.data.response_code === 500)
        //         {
        //                 this.props.onClose()
        //         }
        //     })
        // } catch (error) {
        //     console.log("catch error", error)
        // }
    }


    handleSelectAll = (e,mode,rows) => {
        const isChecked = e;
        const data = mode==1 ? this.state.statusBasedFilteredData :rows ;
        // Create a new array of selected row keys
        const selectedRowKeys = isChecked ? data.map(item => item._id) : [];
        // Update the selectedEOPT state
        this.setState({
          selectedEOPT: selectedRowKeys,
          dup_selectedEOPT: isChecked? data :[],
        });
      };


      select_func = (isSelect, rows, e, mode) => {
        console.log(rows,'rows',isSelect)
        if (mode === "1" || mode !== "1") {
          let selectedEOPT = [];
          let selected_id = [];
      
          if (isSelect) {
            selectedEOPT = rows;
            rows.forEach((data) => {
              selected_id.push(data._id);
            });
          }
      
          this.setState({
            selectedEOPT,
            selected_id,
          });
        }
      };
      


    //   select_func =(isSelect, rows, e,mode)=>{
    //     if(mode == "1"){
    //       if (isSelect) {
    //         var selected_id =[]
    //           this.state.selectedEOPT = rows
    //           rows.map((data,idx)=>{
    //             selected_id.push(data._id)
    //           })
    //           this.setState({
    //               selectedEOPT: this.state.selectedEOPT,
    //               selected_id : selected_id
    //           })
    //       }
    //       else {
    //           this.setState({
    //               selectedEOPT: [],
    //               selected_id:[]
    //           })
    //       }
          
    //     }
    //     else{
    //         var selected_id=[]
    //         if (isSelect) {
    //             this.state.selectedEOPT = rows
    //             rows.map((data,idx)=>{
    //                 selected_id.push(data._id)
    //               })
    //             this.setState({
    //                 selectedEOPT: this.state.selectedEOPT,
    //                 selected_id:selected_id
    //             })
    //         }
    //         else {
    //             this.setState({
    //                 selectedEOPT: [],
    //                 selected_id :[]
    //             })
    //         }


    //     }


    //   }

      emai_addrs = (e) => {
        var email = e.target.value
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            this.setState({ given_email: email , errorMessage : false})
        }
        else{
            this.setState({given_email: email, errorMessage: true })
        }

    }

    validate_email = (e, mode) => {
        var admn_info = {}
        if (mode == 1) {
            admn_info["email_id"] = e.target.value
        }
        if (mode === 2) {
            admn_info["phone_number"] = e.target.value
        }
        if (mode === 3) {
            admn_info["usercode"] = e.target.value
        }
        admn_info["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        admn_info["db_name"] = this.state.db_info.db_name
        admn_info["user_id"] = this.state.user_data._id

        try {
            urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
                if (mode == 1) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_user: true })
                    }
                    else {
                        this.setState({ invalid_user: false })
                    }
                }
                if (mode == 2) {

                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_phone_num: true })
                    }
                    else {
                        this.setState({ invalid_phone_num: false })
                    }
                }
                if (mode === 3) {
                    if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                        this.setState({ invalid_usercode: true })
                    }
                    else {
                        this.setState({ invalid_usercode: false })
                    }


                }
            })

        } catch (error) {

        }


    }
    // changeAuditUserHandler=(event,item,index)=>{
   
    //     var checkUserAlreadyExist = _.findIndex(item.adt_user, { user_id: event.target.value });
    //     if (checkUserAlreadyExist !== -1) {
    //         this.setState({ auditUser:event.target.value,dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
    //     }
    //     else if(event.target.value =="add"){
    //         this.setState({modal : true})
    //     }
    //     else{
    //         var getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value })
    //         var getUser = this.state.userdata[getNewUserIndex]
    //         var audit_user = {
    //             audit_type: "1",
    //             designation: getUser.designation,
    //             name: getUser.firstname,
    //             user_code: getUser.usercode,
    //             user_id: getUser._id,
    //         }
    //         var find_audit_idx = _.findIndex(item.adt_user, { audit_type: "1" })
    //         if (find_audit_idx == -1) {
    //             this.state.statusBasedFilteredData[index].adt_user.push(audit_user)
    //         }
    //         else {
    //             item.adt_user[find_audit_idx] = audit_user
    //             this.state.statusBasedFilteredData[index].adt_user[find_audit_idx] = item.adt_user[find_audit_idx]
    //         }

    //         this.setState({ audit_user, auditUser: String(event.target.value), statusBasedFilteredData: this.state.statusBasedFilteredData, dataLoaded: false }, () => { this.setState({ dataLoaded: true }) })

    //     }

    // }
    changeAuditUserHandler = (event, item, index) => {
        const checkUserAlreadyExist = _.findIndex(item.adt_user, { user_id: event.target.value });
      
        if (checkUserAlreadyExist !== -1) {
          this.setState({
            auditUser: event.target.value,
            dataAlert: true,
            alertMessage: "This user is already selected to review. Try a different user.",
          });
        } else if (event.target.value === "add") {
          this.setState({ modal: true });
        } else {
          const getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value });
          const getUser = this.state.userdata[getNewUserIndex];
          const audit_user = {
            audit_type: "1",
            designation: getUser.designation,
            name: getUser.firstname,
            user_code: getUser.usercode,
            user_id: getUser._id,
          };
      
          const updatedStatusBasedFilteredData = [...this.state.statusBasedFilteredData];
          const updatedItem = { ...item };
          const find_audit_idx = _.findIndex(updatedItem.adt_user, { audit_type: "1" });
      
          if (find_audit_idx === -1) {
            updatedItem.adt_user.push(audit_user);
          } else {
            updatedItem.adt_user[find_audit_idx] = audit_user;
          }
      
          updatedStatusBasedFilteredData[index] = updatedItem;
      
          this.setState({
            audit_user,
            auditUser: String(event.target.value),
            statusBasedFilteredData: updatedStatusBasedFilteredData,
            dataLoaded: false,
          }, () => {
            this.setState({ dataLoaded: true });
          });
        }
      };
      




    // changeReviewUserHandler =(event,item,index)=>{
    //     var checkUserAlreadyExist = _.findIndex(item.adt_user, { user_id: event.target.value });
    //     if (checkUserAlreadyExist !== -1) {
    //         this.setState({ reviewUser:event.target.value,statusBasedFilteredData:this.state.statusBasedFilteredData,dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
    //     }
    //     // if (String(event.target.value) === this.state.auditUser) {
    //     //     this.setState({ reviewUser:event.target.value,statusBasedFilteredData:this.state.statusBasedFilteredData,dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
    //     // }
    //     else if(event.target.value =="add"){
    //         this.setState({modal : true})
    //     }
    //     else{
    //         var getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value })
    //         var getUser = this.state.userdata[getNewUserIndex]
    //         var review_user = {
    //             audit_type: "2",
    //             designation: getUser.designation,
    //             name: getUser.firstname,
    //             user_code: getUser.usercode,
    //             user_id: getUser._id,
    //         }
    //         var find_review_idx = _.findIndex(item.adt_user,{audit_type : "2"})
    //         if(find_review_idx == -1){
    //             this.state.statusBasedFilteredData[index].adt_user.push(review_user)
    //         }
    //         else{
    //         //    delete this.state.statusBasedFilteredData[index].adt_user[find_review_idx]
    //         item.adt_user[find_review_idx] = review_user
    //         this.state.statusBasedFilteredData[index].adt_user[find_review_idx] = item.adt_user[find_review_idx]
    //         // console.log(item.adt_user[find_audit_idx], this.state.statusBasedFilteredData[index].adt_user[find_audit_idx], 'item.adt_user[find_audit_idx]', item.adt_user, item)
    //         }


    //         this.setState({ review_user, reviewUser: String(event.target.value),statusBasedFilteredData : this.state.statusBasedFilteredData , dataLoaded : false },()=>{this.setState({dataLoaded : true})})



    //     }
    //   }

    changeReviewUserHandler = (event, item, index) => {
        const checkUserAlreadyExist = _.findIndex(item.adt_user, { user_id: event.target.value });
      
        if (checkUserAlreadyExist !== -1) {
          this.setState({
            reviewUser: event.target.value,
            statusBasedFilteredData: this.state.statusBasedFilteredData,
            dataAlert: true,
            alertMessage: "This user is already selected to audit. Try a different user.",
          });
        } else if (event.target.value === "add") {
          this.setState({ modal: true });
        } else {
          const getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value });
          const getUser = this.state.userdata[getNewUserIndex];
          const review_user = {
            audit_type: "2",
            designation: getUser.designation,
            name: getUser.firstname,
            user_code: getUser.usercode,
            user_id: getUser._id,
          };
      
          const updatedStatusBasedFilteredData = [...this.state.statusBasedFilteredData];
          const updatedItem = { ...item };
          const find_review_idx = _.findIndex(updatedItem.adt_user, { audit_type: "2" });
      
          if (find_review_idx === -1) {
            updatedItem.adt_user.push(review_user);
          } else {
            updatedItem.adt_user[find_review_idx] = review_user;
          }
      
          updatedStatusBasedFilteredData[index] = updatedItem;
      
          this.setState({
            review_user,
            reviewUser: String(event.target.value),
            statusBasedFilteredData: updatedStatusBasedFilteredData,
            dataLoaded: false,
          }, () => {
            this.setState({ dataLoaded: true });
          });
        }
      };
      







    handleSelectAllCheckbox = (event) => {
        const isChecked = event.target.checked;
        const rows = this.state.statusBasedFilteredData;

        this.setState({
            selectedEOPT: isChecked ? rows : [],
        });
    };

    select_country=(e)=>{
        this.setState({countrycode:e.target.value ,country_code_err : false})
    }



    Validsubmit = (event, values) => {
        // values["countrycode"] = this.state.countrycode
        var password = CryptoJS.AES.encrypt(values.password, this.state.secret_key).toString()
        values["password"] = password
        values["confirmpassword"] = password
        values["userPoolId"] = this.state.client_info.userPoolId
        values["clientId"] = this.state.client_info.clientId
        values["encrypted_db_url"] = this.state.db_info.encrypted_db_url
        values["db_name"] = this.state.db_info.db_name
        values["facilities"] = this.state.client_info.facilities
        values["short_name"] = this.state.client_info.short_name
        values["company_id"] = this.state.sessionUserInfo.user_data.m_client_id
        values["created_by"] = this.state.sessionUserInfo.user_data._id
        values["user_id"] = this.state.sessionUserInfo.user_data._id

        if (this.state.countrycode === undefined) {
            this.setState({ country_code_err: true })
        }
        else {
            values["countrycode"] = this.state.countrycode
            this.setState({ country_code_err: false })

        }

        if (this.state.invalid_user === false && this.state.invalid_phone_num === false && this.state.country_code_err === false) {
            this.setState({ loading: true })
            try {
                urlSocket.post('cog/cruduser', values).then((response) => {
                    if(response.data.response_code == 500){
                        var user_data = response.data.admn_user[0]
                        this.state.userdata.push(user_data)
                         this.setState({ loading: false ,userdata : this.state.userdata , modal : false })


                    }
                })
            } catch (error) {

            }
        }



        // try {
        //     urlSocket.post('cog/cruduser')
            
        // } catch (error) {
            
        // }


    }

    handleKeyPress =(event)=>{
        if(event.key == "Enter"){
            this.Validsubmit()
        }
    }

      

    render() {
        if (this.state.dataLoaded) {
            // const { SearchBar } = Search;
            // const { users } = this.state
            const options = {
                // pageStartIndex: 0,

                sizePerPage: 7,
                totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
                custom: true,
            };
            const columns = [
                // {
                //     text: "id",
                //     dataField: "_id",
                //     sort: true,
                //     hidden: true,
                //     formatter: (cellContent, item) => (
                //         <>
                //             {item._id}
                //         </>
                //     ),
                // },
                {
                    Header: ({ rows }) => <div className="form-check font-size-16" >
                        <input className="form-check-input" onChange={() => {
                            // Determine if all rows are currently selected
                            const allSelected = rows.every((row) => row.original.selected);
                            console.log(allSelected, 'allSelected', rows)
                            // Update the selection for each row
                            rows.forEach((row) => {
                                row.original.selected = !allSelected;
                            }); this.select_func(!allSelected, this.state.statusBasedFilteredData, "e", '1')
                        }} type="checkbox" checked={rows.length > 0 && rows.every((row) => row.original.selected)} id="checkAll" />
                        <label className="form-check-label" htmlFor="checkAll"></label>
                    </div>,
                    accessor: '#',
                    width: '20px',
                    filterable: true,
                    Cell: ({row}) => (
                        <div className="form-check font-size-16" >
                            <input className="form-check-input" checked={row.original.selected} onChange={(e) => { row.original.selected = !row.original.selected; this.getSelectedEOPT(row.original) }} type="checkbox" id="checkAll" />
                            <label className="form-check-label" htmlFor="checkAll"></label>
                        </div>
                    )
                },
                {
                    accessor: 'name',
                    Header: 'Name',
                    // sort: true,
                    Cell: (cellProps) => {
                        var item = cellProps.row.original
                        // console.log(item,'item')
                        return (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className=" font-size-14 text-dark" style={{ marginBottom: 5 }}>
                                        {item.name}
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    accessor: 'code',
                    Header: 'Code'
                },
                {
                    accessor: 'category',
                    Header: 'Category'
                },
                // {
                //     dataField: '',
                //     text: 'Audit assigned to',
                //     // sort: true,
                //     formatter: (cellContent, item,index) => {
                //         var getIndex = _.findIndex(item.adt_user,{audit_type : "1"})
                //         return (
                //             <>
                //                 <div>
                //                     <select
                //                         type="select"
                //                         className="form-select"
                //                         defaultValue={"choose"}
                //                         name="audit"
                //                         value={getIndex == -1 ? "choose" : item.adt_user[getIndex].user_id}
                //                         // onChange={(e)=>{this.handleChange(e,item)}}
                //                         onChange={(e) => this.changeAuditUserHandler(e,item,index)}
                //                         >
                //                         <option value="choose" disabled >Choose...</option>
                //                         <option value="add" >Add New User</option>
                //                         {
                //                             this.state.userdata.map((data, idx) => {
                //                                 return (
                //                                     <option value={data._id}  key={idx}>{data.firstname}</option>
                //                                 )
                //                             })
                //                         }


                //                     </select>
                //                 </div>

                //             </>


                //         )
                //     }
                // },
                // this.props.publishtemplateInfo.enable_review &&
                // {
                //     dataField: '',
                //     text: 'Review assigned to' ,
                //     // sort: true,
                //     formatter: (cellContent, item,index) => {
                //         // console.log(item, 'item',this.state.reviewUser)
                //         var getIndex = _.findIndex(item.adt_user,{audit_type : "2"})
                //         // console.log(getIndex,'getIndex')

                //         return (
                //             <>
                //                 <div>
                //                     <select
                //                         type="select"
                //                         className="form-select"
                //                         defaultValue={"choose"}
                //                         value={getIndex ==-1 ? "choose" : item.adt_user[getIndex].user_id }
                //                         name="review"
                //                         onChange={(e) => this.changeReviewUserHandler(e,item,index)}
                //                         >
                //                         <option value="choose" disabled >Choose...</option>
                //                         <option value="add" >Add New User</option>
                //                         {
                //                             this.state.userdata.map((data, idx) => {
                //                                 return (
                //                                     <option value={data._id} key={idx}>{data.firstname}</option>
                //                                 )
                //                             })
                //                         }


                //                     </select>
                //                 </div>

                //             </>


                //         )
                //     }
                // },
                // {
                //     accessor: 'created_on',
                //     // isDummyField: true,
                //     // hidden: true,
                //     Header: 'updated on'
                // },
                
                
            ];
            // const defaultSorted = [{
            //     dataField: 'created_on', // if dataField is not match to any column you defined, it will be ignored.
            //     order: 'desc' // desc or asc
            // }];

            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true, // this.state.allowTableRowToSelect,
                // selected : this.state.selected_id,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.getSelectedEOPT(row, isSelect, rowIndex, e)
                },
                onSelectAll: (isSelect, rows, e) => {
                    this.select_func(isSelect, rows, e,'1')
          
                },
            }


            // const selectRow = {
            //     mode: 'checkbox',
            //     clickToSelect: true,
            //     selected: this.state.selectedEOPT, // Pass the selected row keys here
            //     onSelect: (row, isSelect, rowIndex, e) => {
            //       console.log("row selected", row, isSelect, rowIndex, e);
            //       this.getSelectedEOPT(row, isSelect, rowIndex, e);
            //     },
            //     onSelectAll:(row, isSelect, rowIndex, e) => {
            //         console.log("row selected", row, isSelect, rowIndex, e);
            //     this.handleSelectAll(row,0,isSelect)
            //     }
            //   };
            return (
                <div className="p-3">
                    <Row className="">

                        <Col >
                            <div >
                                <Row className="my-2 pb-2" style={{ borderBottom: "1px solid #dedede" }}>
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div className="text-secondary font-size-18">Load Location</div>
                                        <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
                                    </div>
                                </Row>

                                <Row className="mt-3">
                                    <AvForm className="form-horizontal" onValidSubmit={this.submitData} onInvalidSubmit={this.handleInvalidSubmit}>
                                        <div className="mb-1">
                                            <Label className="" htmlFor="autoSizingSelect">Select Group</Label>
                                            <AvInput
                                                type="select"
                                                name="hlevel_cat"
                                                label="Name"
                                                value={null}
                                                className="form-select"
                                                id="cate"
                                                required
                                                onChange={(e) => this.labelSelected(e)}>
                                                <option value="" disabled selected>Choose...</option>
                                                <option value="all"  >ALL</option>
                                                {
                                                    this.state.labelData.map((data, idx) => {
                                                        return (
                                                            <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
                                                        )
                                                    })
                                                }
                                            </AvInput>
                                        </div>
                                    </AvForm>
                                </Row>

                            </div>
                        </Col>
                    </Row>

                    <Row className='react-drawer-drawer-modal '>
                        <Modal isOpen={this.state.modal} toggle={this.toggle}  >
                            <ModalHeader toggle={this.toggle} tag="h4">
                                Add User
                            </ModalHeader>
                            <ModalBody>
                                <AvForm className="form-horizontal" onValidSubmit={this.Validsubmit}>
                                    <div className="mb-1">
                                        <label>Full Name :<span className="text-danger"> *</span></label>
                                        <AvField
                                            name="firstname"
                                            // label="First Name"
                                            errorMessage="Please enter your name"
                                            className="form-control"
                                            placeholder="Enter First Name"
                                            type="text"
                                            required
                                        />
                                    </div>
                                    <div className="mb-1">
                                        <label>Email Id :<span className="text-danger"> *</span></label>
                                        <AvField
                                            name="email_id"
                                            onChange={(e) => { this.emai_addrs(e) }}
                                            errorMessage="Please enter your Email ID"
                                            onBlur={(e) => { this.validate_email(e, 1) }}
                                            className="form-control"
                                            placeholder="Enter User Email"
                                            type="email"
                                            required
                                        />
                                    </div>
                                    {
                                        this.state.errorMessage &&
                                        <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Please Enter a valid Email ID</div>
                                    }
                                    {
                                        this.state.invalid_user &&
                                        <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Email Id already used for another user</div>
                                    }
                                    <Row>
                                        <Col className="col-12">
                                            <Row>
                                                <label>Phone Number:<span className="text-danger"> *</span></label>
                                                <Col md={3} className="pe-0">
                                                    <select name="countrycode" onChange={(e) => { this.select_country(e) }} className="form-select" required>
                                                        <option value="">Select</option>
                                                        {
                                                            this.state.client_info.countries.map((c, idx) => (
                                                                <option key={idx} value={c.code}>
                                                                    {c.code}{""}&nbsp;{c.label}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </Col>
                                                <Col md={9} className="ps-0">
                                                    <AvField
                                                        name="phone_number"
                                                        className="form-control"
                                                        placeholder="Enter Phone number"
                                                        errorMessage="Please enter your Phone Number"

                                                        type="number"
                                                        onBlur={(e) => { this.validate_email(e, 2) }}
                                                        validate={{
                                                            required: { value: true },
                                                            minLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                            maxLength: { value: 10, errorMessage: "Enter 10 Digit Number." },
                                                        }}
                                                    />
                                                    {
                                                        this.state.country_code_err &&
                                                        <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Please select the country code</div>

                                                    }
                                                    {
                                                        this.state.invalid_phone_num &&
                                                        <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Phone Number already used for another user</div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <div className="mb-3">
                                        <label>Username :<span className="text-danger"> *</span></label>
                                        <AvField
                                            name="username"
                                            type="text"
                                            required
                                            placeholder="Enter username"
                                            disabled={true}
                                            value={this.state.given_email}
                                        />
                                    </div>


                                    <div className="mb-3">
                                        <label>Password :<span className="text-danger"> *</span></label>
                                        <AvField
                                            name="password"
                                            // label="Password"
                                            autoComplete="new-password"
                                            type="password"
                                            errorMessage="Please enter your Password"
                                            required
                                            placeholder="Enter password"
                                            // onKeyPress={(e)=>{this.handleKeyPress(e)}}
                                            validate={{
                                                required: { value: true, errorMessage: 'Password is required' },
                                                minLength: { value: 8, errorMessage: 'Password must be at least 8 characters long' },
                                                pattern: { value: /^(?=.*[A-Z]).+$/, errorMessage: 'Password must contain at least one uppercase letter' },
                                            }}
                                        />
                                    </div>

                                    <div className="mb-3 d-grid">
                                        <button type="submit" className="btn btn-primary btn-block" disabled={this.state.loading}>
                                            {this.state.loading && <LoadingOutlined />}   {this.state.loading ? "..." : "Add User"}
                                        </button>
                                        {
                                            (this.state.country_code_err || this.state.invalid_user || this.state.invalid_phone_num) &&
                                            <div className='text-danger mt-1' style={{ fontSize: 'smaller' }}>Please fill the above mandatory fields.</div>


                                        }
                                    </div>


                                </AvForm>


                            </ModalBody>
                        </Modal>
                    </Row>

                    <Row className="mt-3">

                        <TableContainer
                            columns={columns}
                            data={this.state.statusBasedFilteredData}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            isJobListGlobalFilter={false}
                            customPageSize={10}
                            style={{ width: '100%' }}
                            isPagination={true}
                            filterable={false}
                            btnName={"Add User"}
                            tableClass="align-middle table-nowrap table-check"
                            theadClass="table-light"
                            pagination="pagination pagination-rounded justify-content-end mb-2 my-2"
                        />

                        {
                            this.state.statusBasedFilteredData.length > 0 &&
                            <Button color="primary" onClick={() => { this.setState({ dsbl_slctd_lctn: true }); this.addEndpoints() }} disabled={this.state.dsbl_slctd_lctn}> Add Selected Location</Button>
                        }




                    </Row>
                    {this.state.dataAlert ? (
                        <SweetAlert
                            title={this.state.alertMessage}
                            onConfirm={() => this.setState({ dataAlert: false })}
                        ></SweetAlert>
                    ) : null}
                </div>
            )
        }
        else {
            return null
        }
    }
}




























// import React, { Component } from "react";
// import ReactDOM from 'react-dom';
// import {
//     Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
//     Input,
// } from "reactstrap";
// import Select from "react-select";
// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
// import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
// import TagsInput from 'react-tagsinput'

// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"

// import 'react-tagsinput/react-tagsinput.css'



// const _ = require('lodash')
// var urlSocket = require("../../../helpers/urlSocket")

// export default class LoodEndpointNode extends React.Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             dataLoaded: false,
//             selectedEOPT:[]

//         };
//         this.table = React.createRef();  
//     }



//     async componentDidMount() {
//         var db_info = JSON.parse(sessionStorage.getItem('db_info'))
//         this.setState({db_info},()=>{this.loadEOPTS()})        
//         console.log("pb info", this.props.publishtemplateInfo.endpoints)
//     }

//     loadEOPTS = () => {
//         this.setState({ tableDataloaded: false })
//         try {
//             urlSocket.post("webEntities/endpointlist", {
//                 userInfo: {
//                     encrypted_db_url:this.state.db_info.encrypted_db_url,
//                     company_id: this.props.userInfo.company_id,
//                     user_id: this.props.userInfo._id,
//                 },
//             })
//                 .then((response) => {
//                     this.setState({
//                         statusBasedFilteredData: response.data.data,
//                         tableDataloaded: true,
//                         allowTableRowToSelect: true,
//                         labelDefault: true,
//                         label: {
//                             endpoints: []
//                         },


//                     }, function () {
//                         this.loadUserLabels()
//                     })

//                 })
//         } catch (error) {

//         }
//     }


//     loadUserLabels() {
//         try {
//             urlSocket.post("userSettings/getuserlabels", {
//                 userInfo: {
//                     encrypted_db_url: this.state.db_info.encrypted_db_url,
//                     _id: this.props.userInfo._id,
//                     company_id: this.props.userInfo.company_id
//                 }
//             })
//                 .then(response => {
//                     console.log("user label", response)
//                     this.setState({
//                         labelData: response.data.data,
//                         dataLoaded: true

//                     })
//                 })

//         } catch (error) {
//             console.log("catch error", error)
//         }
//     }

//     labelSelected(data) {

//         if(data.target.value === "all")
//         {
//             this.loadEOPTS()
//         }
//         else
//         {
//             console.log("label data", this.state.labelData[data.target.value])

//             var mylabel = this.state.labelData[data.target.value]
    
//             this.setState({
//                 tableDataloaded: false, selectedLabel: mylabel._id,
//                 label: mylabel
//             })
    
//             try {
    
//                 urlSocket.post("webEntities/loadlabeleopts", {
//                     labelInfo: mylabel,
//                     userInfo: {
//                         encrypted_db_url: this.state.db_info.encrypted_db_url,
//                         _id: this.props.userInfo._id,
//                         company_id: this.props.userInfo.company_id
//                     }
//                 })
//                     .then(response => {
//                         console.log("response", response.data.data)
//                         this.setState({
//                             statusBasedFilteredData: response.data.data,
//                             labelDefault: false,
//                             tableDataloaded: true
//                         })
//                     })
    
//             } catch (error) {
//                 console.log("catch error", error)
//             }
    
//         }

//     }

//     getSelectedEOPT = (row, isSelect, rowIndex, e) => {
//         console.log(row, isSelect, rowIndex)

//         var getEOPTId = _.map(this.state.selectedEOPT, "_id")
//         var getIndex = getEOPTId.indexOf(row._id)

//         if (getIndex === -1) {
//             this.state.selectedEOPT.push(row)

//             this.setState({
//                 selectedEOPT: this.state.selectedEOPT
//             })
//         }
//         else {
//             this.state.selectedEOPT.splice(getIndex, 1)
//             this.setState({
//                 selectedEOPT: this.state.selectedEOPT
//             })
//         }

//         console.log("this.state.selectedEOPT", this.state.selectedEOPT)

//     }

//     // selectAll(event,data){
//     //     console.log(event,'events',data)
//     //     this.setState({selectedEOPT : data, disabled_box:true})
        
        
//     // }

//     // selectAll = (event, data,selectRows) => {
//     //     console.log(event.target.checked, 'events', data,selectRows);
//     //     var row = data.slice(0,7)
//     //     console.log(row)
//     //     selectRows.onSelectAll(event.target.checked,row)
//     //     // this.setState({ selectedEOPT: data });
      
//     //     // const { selectRow } = selectRows;
//     //     // const { onSelectAll } = selectRow;

//     //     // if (onSelectAll) {
//     //     //   const isSelect = true;
//     //     //   const selectedRows = data.map(row => {
//     //     //     return { ...row, selected: isSelect }; // Set the 'selected' property to 'true'
//     //     //   });
//     //     //   onSelectAll(isSelect, selectedRows, event);
//     //     // }
//     //   };


//     selectAll = (event, data, selectRows) => {
//          console.log(event.target.checked, 'events', data, selectRows);
//         const row = data.slice(0, 7);
//         console.log(row);
        
//         // Update the selected property of each row to isSelect value
//         const updatedRows = row.map(rowItem => ({
//           ...rowItem,
//           selected: event.target.checked
//         }));
//         console.log(updatedRows,'updatedRows')
//         selectRows.onSelectAll(event.target.checked, updatedRows);
//       }



//     addEndpoints(){
//         try {
//             var loggedUserInfo = {
//                 encrypted_db_url: this.state.db_info.encrypted_db_url,
//                 company_id: this.props.userInfo.company_id,
//                 company_name: this.props.userInfo.company_name,
//                 created_by:this.props.userInfo._id
//             }
//             urlSocket.post("webphlbconf/addmultipleendpoints", {
//                 userInfo: loggedUserInfo,
//                 publishtemplateInfo: this.props.publishtemplateInfo,
//                 endpointInfo: this.state.selectedEOPT
//             })
//             .then(response=>{
//                 console.log(response,'response')
//                 if(response.data.response_code === 500)
//                 {
//                         this.props.onClose()
//                 }
//             })
//         } catch (error) {
//             console.log("catch error", error)
//         }


//     }


//     onSelectAll = (isSelected) => {
//         console.log(this.table,'this.table')
//         if (isSelected) {
//           return this.state.statusBasedFilteredData.map(row => row.id);
//         }
//       }




//     render() {
//         if (this.state.dataLoaded) {
//             const { SearchBar } = Search;
//             const options = {
//                 // pageStartIndex: 0,

//                 sizePerPage: 7,
//                 totalSize: this.state.statusBasedFilteredData.length, // replace later with size(users),
//                 custom: true,
//             };
//             const columns = [
//                 {
//                     text: "id",
//                     dataField: "_id",
//                     sort: true,
//                     hidden: true,
//                     formatter: (cellContent, item) => (
//                         <>
//                             {item._id}
//                         </>
//                     ),
//                 },
//                 {
//                     dataField: 'name',
//                     text: 'Name',
//                     sort: true,
//                     formatter: (cellContent, item) => {
//                         return (
//                             <>
//                                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                                     <div className=" font-size-14 text-dark" style={{ marginBottom: 5 }}>
//                                         {item.name}
//                                     </div>
//                                 </div>
//                             </>
//                         )
//                     }
//                 },
//                 {
//                     dataField: 'code',
//                     text: 'Code'
//                 },
//                 {
//                     dataField: 'category',
//                     text: 'Category'
//                 },
//                 {
//                     dataField: 'created_on',
//                     isDummyField: true,
//                     hidden: true,
//                     text: 'updated on'
//                 },
                
                
//             ];
//             const defaultSorted = [{
//                 dataField: 'created_on', // if dataField is not match to any column you defined, it will be ignored.
//                 order: 'desc' // desc or asc
//             }];

//             const selectRow = {
//                 mode: 'checkbox',
//                 clickToSelect: true, 
//                 // this.state.allowTableRowToSelect,
//                 onSelect: (row, isSelect, rowIndex, e) => {
//                     console.log("row selectedd", row, isSelect, rowIndex)
//                     this.getSelectedEOPT(row, isSelect, rowIndex, e)
//                 },
//                 onSelectAll: (isSelect, rows, e) => {
//                     console.log("select all",isSelect, rows)
//                     this.state.selectedEOPT = rows
//                     this.setState({
//                         selectedEOPT:this.state.selectedEOPT
//                     })
            
            
//                 },

//                 // onSelectAll: this.onSelectAll
//                 // nonSelectable: this.state.label.endpoints

//             };
//             return (
//                 <React.Fragment>
//                     <Container fluid>
//                         <Row >
//                             <Col >
//                                 <div className="px-5">
//                                     <Row className="my-4">
//                                     <div className="d-sm-flex align-items-center justify-content-between">
//                                     <div className="text-danger font-size-18">Load End points</div>

//                                                 <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
//                                             </div>
//                                         <hr className="my-4" />
//                                     </Row>
//                                     <Row>
//                                         <AvForm className="form-horizontal" onValidSubmit={this.submitData} onInvalidSubmit={this.handleInvalidSubmit}>
//                                             <div className="mb-1">
//                                                 <Label className="" htmlFor="autoSizingSelect">Select label</Label>
//                                                 <AvInput
//                                                     type="select"
//                                                     name="hlevel_cat"
//                                                     label="Name"
//                                                     value={null}
//                                                     className="form-select"
//                                                     id="cate"
//                                                     required
//                                                     onChange={(e) => this.labelSelected(e)}>
//                                                     <option value="" disabled selected>Choose...</option>
//                                                     <option value="all"  >ALL</option>
//                                                     {
//                                                         this.state.labelData.map((data, idx) => {
//                                                             return (
//                                                                 <option value={idx} key={"lbl" + idx}>{data.label_name}</option>
//                                                             )
//                                                         })
//                                                     }
//                                                 </AvInput>
//                                             </div>
//                                         </AvForm>
//                                     </Row>
//                                 </div>
//                             </Col>
//                         </Row>
//                         <Row>
//                             <div className="p-5">
//                                 {
//                                     this.state.tableDataloaded ?

//                                         <PaginationProvider
//                                             keyField="id"
//                                             data={this.state.statusBasedFilteredData}
//                                             columns={columns}
//                                             pagination={paginationFactory(options)}
//                                         >
//                                             {
//                                                 ({
//                                                     paginationProps,
//                                                     paginationTableProps
//                                                 }) => (
//                                                     <ToolkitProvider
//                                                     // ref="table"
//                                                         keyField="_id"
//                                                         data={this.state.statusBasedFilteredData}
//                                                         columns={columns}
//                                                         search
//                                                     >
//                                                         {
//                                                             toolkitprops => (
//                                                                 <React.Fragment>
//                                                                     <Row className="mb-2" style={{ paddingTop: 10 }}>
//                                                                         <Col sm="10">
//                                                                             <div className="search-box ms-2 d-inline-block">
//                                                                                 <div className="position-relative">
//                                                                                     <SearchBar {...toolkitprops.searchProps} />
//                                                                                     <i className="bx bx-search-alt search-icon" />
//                                                                                 </div>
//                                                                             </div>
//                                                                         </Col>
//                                                                         <Col md={2}>
//                                                                         {/* <input type="checkbox" onClick={(e)=>{selectRow.onSelectAll(e.target.checked,this.state.statusBasedFilteredData)}}/> &nbsp;

//                                                                         <label>Select All</label> */}
                                                                        
//                                                                         </Col>
//                                                                     </Row>
//                                                                     <hr className="my-2" />

//                                                                     <Row>
//                                                                         <Col xl="12">
//                                                                             <div className="table-responsive">
//                                                                                 <BootstrapTable
//                                                                                     {...toolkitprops.baseProps}
//                                                                                     {...paginationTableProps}
//                                                                                     defaultSorted={defaultSorted}
//                                                                                     selectRow={selectRow}
//                                                                                     classes={
//                                                                                         "table align-middle table-nowrap table-hover"
//                                                                                     }
//                                                                                     bordered={false}
//                                                                                     striped={false}
//                                                                                     responsive
//                                                                                 />
//                                                                             </div>
//                                                                         </Col>
//                                                                         {/* {
//                                                                             console.log(selectRow,defaultSorted)
//                                                                         } */}
//                                                                     </Row>
//                                                                     <Row className="align-items-md-center mt-30">
//                                                                         <Col className="pagination pagination-rounded justify-content-end mb-2">
//                                                                             <PaginationListStandalone
//                                                                                 {...paginationProps}
//                                                                             />
//                                                                         </Col>
//                                                                     </Row>
//                                                                 </React.Fragment>
//                                                             )}
//                                                     </ToolkitProvider>
//                                                 )}
//                                         </PaginationProvider> : null
//                                 }
//                                 <Button color="primary" onClick={()=>{this.addEndpoints()}} > Add Selected End points</Button>
//                             </div>
                            
//                         </Row>
//                     </Container>
//                 </React.Fragment>
//             )
//         }
//         else {
//             return null
//         }
//     }
// }