
import React, { Component } from "react";
import { Link } from "react-router-dom"
import { Row, Col,
    Card,
    CardBody,
    Collapse,
    DropdownMenu,
    DropdownToggle,
    UncontrolledAlert,
    UncontrolledDropdown, } from "reactstrap";

class ParentChildBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            menuEditable: false,
            file_collapse: false,
        }
        this.file = this.file.bind(this)
    }

      file() {
        this.setState({
          file_collapse: !this.state.file_collapse,
        })
      }

    editMenuName = () => { this.setState({ menuEditable: true }) }

    callFunctions = () => {
        this.props.hidePreviousElement()
        this.props.IdSelect()
    }

    updateFunction = () => {
        this.setState({ menuEditable: false })
        this.props.updateItem()
    }

    render() {
        //console.log("data", this.props.data.document_id)
        return (
            // <div className="mb-2 border" style={{ padding:5 }}>
            //     <div className="custom-accordion" style={{display:'flex',flexDirection:'row'}}>
            //         <div style={{width:'90%'}}>
            //         <Link
            //             className="text-body fw-medium py-1 d-flex align-items-center"
            //             onClick={this.file}
            //             to="#"
                        
            //           >
            //             <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
            //             {this.props.menuName}{" "}
            //             {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
            //             <i
            //               className={
            //                 this.state.file_collapse
            //                   ? "mdi mdi-chevron-up accor-down-icon ms-auto"
            //                   : "mdi mdi-chevron-down accor-down-icon ms-auto"
            //               }
            //             />
                        
            //           </Link>
            //         <Collapse isOpen={this.state.file_collapse}>
            //             <div className="card border-0 shadow-none ps-2 mb-0">
            //               <ul className="list-unstyled mb-0">

            //                 <li>
            //                   <Link
            //                     to="#"
            //                     className="d-flex align-items-center"
            //                   >
            //                     <span className="me-auto">Design</span>
            //                   </Link>
            //                 </li>
                           
            //               </ul>
            //             </div>
            //           </Collapse>
            //         </div>

            //         <div style={{width:'10%'}}>
                     
            //           <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}} >
            //             <UncontrolledDropdown direction="left">
            //               <DropdownToggle
            //                 color="white"
            //                 className="font-size-16 text-muted dropdown-toggle"
            //                 tag="a"
            //               >
            //                 <i className="mdi mdi-dots-horizontal"></i>
            //               </DropdownToggle>

            //               <DropdownMenu className="dropdown-menu-end">
            //                 <Link className="dropdown-item" to="#">
            //                   Add Folder
            //                 </Link>
            //                 <Link className="dropdown-item" to="#">
            //                   Add Document
            //                 </Link>
            //                 <Link className="dropdown-item" to="#">
            //                   Edit
            //                 </Link>
            //                 <div className="dropdown-divider"></div>
            //                 <Link className="dropdown-item" to="#">
            //                   Remove
            //                 </Link>
            //               </DropdownMenu>
            //             </UncontrolledDropdown>
            //           </div>
            //           </div>
                      
                      
            //         </div>
            <div style={{ padding:5 }}>
                <button type="button" className="btn btn-sm waves-effect waves-light" style={{ marginRight: 5, width: '100%', textAlign: 'left', background: this.props.colors, color: '#ffffff' }} onClick={() => this.setState({ isShow: !this.state.isShow })}>
                    <Row >
                        <Col md={12}>
                            <Row>
                                <Col md={10}>
                                    {
                                        this.state.menuEditable ?
                                            <Row  >
                                                <Col md={12}>
                                                    <input style={{ flex: 3, color: 'black' }} type="text" placeholder="Enter Sub Category Name" autoFocus defaultValue={this.props.menuName} onChange={(e) => this.props.SubmenuChange(e.target.value)} onFocus={(e) => this.props.SubmenuChange(e.target.value)} ref={(input) => { this.textInput = input; }} ></input>
                                                    <div type="button" className="btn btn-primary btn-sm waves-effect waves-light" style={{ marginLeft: 5 }} onClick={() => this.updateFunction()} ><i className="bx bxs-save" ></i> </div>
                                                </Col>
                                            </Row>
                                            :
                                            <div style={{ lineHeight: 2, }} onClick={() => this.callFunctions()}>{this.props.menuName} <i onClick={() => this.editMenuName()} className="bx bx-edit-alt" style={{ fontSize: "16px", marginRight: 5, }}></i></div>
                                    }
                                </Col>
                                <Col md={2} className="text-right">
                                    <div onClick={() => this.props.deleteItem()} ><i className="bx bx-trash" style={{ fontSize: "16px" }}></i></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </button>
                {
                    this.props.show &&
                    <>
                        <Row>
                            <Col md={12}>
                                <input className="menuClass" type="text" placeholder="Enter Sub Category Name" onChange={(e) => this.props.SubmenuChange(e.target.value)}></input>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <button type="button" className="btn btn-success btn-sm waves-effect waves-light" onClick={this.props.subMenu}><i className="bx bx-plus font-size-16 align-middle mr-2"></i> Add Sub Category </button>
                            </Col>
                            <Col md={6}>
                                <button type="button" className="btn btn-primary btn-sm waves-effect waves-light" onClick={this.props.subMenuParameter}><i className="bx bx-plus font-size-16 align-middle mr-2"></i> Add Parameter</button>
                            </Col>
                        </Row>
                    </>
                } 
            </div>
        );
    }
}

export default ParentChildBtn;


