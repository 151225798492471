

import React, { Component } from "react";
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
    Input,
} from "reactstrap";
// import Dropzone from "react-dropzone"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"
import MediaPreview from "./media_preview";



import moment from 'moment'

const _ = require('lodash')
var urlSocket = require("../../../../helpers/urlSocket")




export default class OptionComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataloaded: false,
            previousData: {},
            attachImages: [],
            attachDocuments:[],
            selectedOption:null,
            docfileStatus:"clear",
            fileStatus:"clear",
            doc_warning_enabled:false,
            warning_enabled:false,
        }
    }

    componentDidMount() {
      console.log("option component")
    }


    onSelected(data) {
        // console.log(data,'data')
        _.each(this.props.options, item => {
            item["is_selected"] = false
        })
        data["is_selected"] = true

        this.props.checkpoint.checkpoint_options = this.props.options;
        // if( data.show_config){
        this.props.checkpoint.cp_noof_images = data.enable_img ? data.no_of_img : 0
        this.props.checkpoint.cp_ai_is_mandatory = data.enable_img
        this.props.checkpoint.cp_noof_videos = data.enable_video ? data.no_of_video == undefined ? 1 : data.no_of_video : 0
    // }
    // else{
    //     this.props.checkpoint.cp_noof_images =  0
    //     this.props.checkpoint.cp_ai_is_mandatory = false
    //     this.props.checkpoint.cp_noof_videos =  0
    // }

        // if (data.enable_img) {
        //     this.props.checkpoint.cp_ai_is_mandatory = data.enable_img
        //     this.props.checkpoint.cp_noof_images = data.no_of_img
        // }
        // if (data.enable_video) {
        //     this.props.checkpoint.cp_ai_is_mandatory = data.enable_video
        //     this.props.checkpoint.cp_noof_videos = data.no_of_video
        // }
        // else {
        //     this.props.checkpoint.cp_ai_is_mandatory = data.enable_img
        //     this.props.checkpoint.cp_noof_images = 0
        //     this.props.checkpoint.cp_noof_videos = 0

        // }
        this.props.checkpoint.cp_apln_is_mandatory = data.nc_mandatory
        this.props.checkpoint.cp_noof_documents = data.documents.length
        this.props.checkpoint.cp_is_compliance = data.is_compliance
        this.props.checkpoint.cp_compliance = data.compliance
        this.props.checkpoint.cp_otpion_score = data.score

        if (data.images !== undefined) {
            this.props.checkpoint.cp_attach_images = data.images;
          } 
          else {
            this.props.checkpoint.cp_attach_images = [];
          }
          if (data.videos !== undefined) {
            // this.props.checkpoint.cp_attach_videos = data.images;
          } 
          else {
            this.props.checkpoint.cp_attach_videos = [];
          }

          if (data.documents_attached !== undefined) {
            this.props.checkpoint.cp_documents = data.documents_attached;
          }
           else {
            this.props.checkpoint.cp_documents = [];
          }
  
          this.props.checkpoint.updated_on = new Date();
        this.setState({ selectedOption: data })
        this.props.selectionoption(data, this.props.options)
        
    }

    updateCPStatus() {
        if (this.props.checkpoint.cp_status === "0") {
            this.props.checkpoint.cp_status = "1"
        }
    }

   

    render() {
        return (
            <Container fluid>
                <Row>
                    <div className="button-items mb-1 " >
                        <div className="btn-group-horizontal mt-2 mt-xl-0" role="group" aria-label="Basic radio toggle button group">
                            {
                                this.props.options.map((item, idx) => {
                                    return (
                                        <React.Fragment key={"optns"+String(idx)}>
                                            <input type="radio" className="btn-check" 
                                            name={this.props.index + "btnradio" + idx + idx} 
                                            id={this.props.index + "btnradio" + idx + idx} 
                                            autoComplete="off" 
                                            checked={item.is_selected} 
                                            onChange={() => { this.onSelected(item) }} 
                                            disabled={this.props.checkpoint.cp_status !== "4" && this.props.checkpoint.cp_status !== "5" ? false:true}
                                            />
                                            <label className="btn btn-outline-primary" htmlFor={this.props.index + "btnradio" + idx + idx}><span>{item.option_text} {" "} { item.enable_img ? <i className='mdi mdi-camera-plus' /> : null} {" "}{item.enable_video ? <i className='mdi mdi-video-plus' /> : null} {" "} {item.enable_doc ? <i className='mdi mdi-file-document' /> : null}</span></label>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Row>

            </Container>
        )
    }

}