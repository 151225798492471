import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap"

import classnames from "classnames"

import InputMask from "react-input-mask"

//import images
import small from "../../../assets/images/img-2.jpg"
import small2 from "../../../assets/images/img-6.jpg"

export default class BlogList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
    }
    this.toggleTab = this.toggleTab.bind(this)
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  render() {

    
    const entityInfo = this.props.entityInfo
    const entityGenralInfo = this.props.entityInfo.general_info[0]
    return (
      <React.Fragment>
        <Col xl={8} lg={8}>
          <Card>


            <Row className="justify-content-center">
              <Col xl={10} lg={9}>
                <div>

                  <hr className="my-5" />
                  <div>
                    <h5 className="text-dark">
                      {entityInfo.entity_name}
                    </h5>
                    <p className="text-muted">{entityInfo.entity_code}</p>
                  </div>

                  <hr className="my-5" />
                  <div>
                        <div className="h4 mb-4">General Information</div>
                        <Form>
                          <FormGroup className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">Address </Label>
                            <div style={{display:'flex', flexDirection:'column'}}>
                              <div style={{padding:'10px 0px'}}>
                                  <Input
                                  type="text"
                                  value={entityGenralInfo.address_1}
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                />
                              </div>
                              <div style={{padding:'10px 0px'}}>
                                  <Input
                                  type="text"
                                  value={entityGenralInfo.address_2}
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                />
                              </div>
                            </div>
                           
                            
                          </FormGroup>

                          <Row>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="formrow-email-Input">Email</Label>
                                <Input
                                  type="email"
                                  value={entityGenralInfo.email_id}
                                  className="form-control"
                                  id="formrow-email-Input"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="formrow-password-Input">Mobile Number</Label>
                                <InputMask
                                  mask="9999999999"
                                  value={entityGenralInfo.email_id}
                                  className="form-control input-mask"
                                  //onChange={}
                                >
                                </InputMask>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={6} xl={3}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="formrow-InputCity">City</Label>
                                <Input
                                  type="text"
                                  value={entityGenralInfo.city}
                                  className="form-control"
                                  id="formrow-InputCity"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={6} xl={3}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="formrow-InputState">State</Label>
                                <Input
                                  type="text"
                                  value={entityGenralInfo.state}
                                  className="form-control"
                                  id="formrow-InputState"
                                />
                                
                              </FormGroup>
                            </Col>

                            <Col lg={6} xl={3}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="formrow-InputZip">Pincode</Label>
                                <Input
                                  type="text"
                                  value={entityGenralInfo.pincode}
                                  className="form-control"
                                  id="formrow-InputZip"
                                />
                              </FormGroup>
                            </Col>

                            <Col lg={6} xl={3}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="formrow-InputZip">Country</Label>
                                <Input
                                  type="text"
                                  value={entityGenralInfo.country}
                                  className="form-control"
                                  id="formrow-InputZip"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                     
                          <div>
                            <button type="submit" className="btn btn-primary w-md">
                              Submit
                        </button>
                          </div>
                        </Form>
                  </div>

                  <hr className="my-5" />

                </div>
              </Col>
            </Row>

          </Card>
        </Col>
      </React.Fragment>
    )
  }
}
