import React, { Component } from "react"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom";
import { Col, Container, Row, Button } from "reactstrap"

//Import Breadcrumb
//import Breadcrumbs from "components/Common/Breadcrumb"
import BlogList from "./BlogList"
import RightBar from "./RightBar"
import ProjectDetail from "./projectDetail"
import OrgMapInfo from "./orgmapInfo"
import _ from "lodash";
var urlSocket = require("../../../helpers/urlSocket");


class EntitiesOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataloaded: false,
    };
  }
  componentDidMount() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var auditData = JSON.parse(sessionStorage.getItem("auditData"));
    var entityData = JSON.parse(sessionStorage.getItem("entityData"));
    var uniqueNextLevels = JSON.parse(sessionStorage.getItem("uniqueNextLevels"));



    this.setState(
      {
        img_max_mb: data.img_max_mb,
        userInfo: data.user_data,
        usercode: data.user_data.user_code,
        mappinginfo: data.user_data.mappinginfo,
        designation: data.user_data.designation,
        auditData: auditData,
        entityData: entityData,
        imageDownloadURL: data.config_data.img_url,
        img_token: data.config_data.img_token,
        uniqueNextLevels,
        dataloaded: true,

      },
      function () {
        //this.getEntityDetaitedData()
      }
    )
  }

  getEntityDetaitedData = () => {
    try {
      var userInfo = {
        company_id: this.state.userInfo.company_id,
        hirerachy_level: this.state.userInfo.hierarchy_level,
        hirerachyinfo: this.state.userInfo.departmentandhierarchy[0]
      }
      urlSocket.post("webEntities/getEntityInfo", {
        userInfo: userInfo,
        entityData: this.state.entityData
      })
        .then((response) => {
        })
    } catch (error) {

    }
  }

  render() {
    if (this.state.dataloaded) {
      return (
        <React.Fragment>
          <div className="page-content">
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 20, alignItems: 'center' }}>
              <div style={{ width: '100%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Link to="/mels"><Button color="primary">Back <i className="mdi mdi-arrow-left"></i></Button></Link>
              </div>
            </div>
            <Container fluid>
              <Row>
                <BlogList entityInfo={this.state.entityData} />
                <RightBar
                  entityInfo={this.state.entityData}
                  userInfo={this.state.userInfo}
                  uniqueNextLevels={this.state.uniqueNextLevels}
                />
              </Row>
            </Container>
          </div>
        </React.Fragment>
      )
    }
    else {
      return null
    }
  }
}



export default EntitiesOverview
