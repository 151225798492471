import React, { Component } from "react"
import {
  Card, CardBody, Container,
  Row, Col, CardTitle, Table, Button, Badge, Modal, ModalHeader, ModalBody
} from "reactstrap"
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from "react-router-dom"
// import facility from '../FacilityScreen'
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
import { LoadingOutlined } from '@ant-design/icons';
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import TableContainer from "./Component/TableContainer";
import { isEmpty, size, map } from "lodash"

import HStructure from "./HStructure"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


import _ from "lodash";
// var urlSocket = require("../../helpers/urlSocket");
import urlSocket from "../../../helpers/urlSocket";

export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      isEdit: false,
      hierarchy_name_exist: false,
      blur_loading: false
    }
    this.handleUserClicks = this.handleUserClicks.bind(this)
    this.handleValidHrchySubmit = this.handleValidHrchySubmit.bind(this)
  }

  async componentDidMount() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var db_info = JSON.parse(sessionStorage.getItem("db_info"));
    var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"))
    console.log(user_facilities,'user_facilities')
    // var protect_routes = await facility(user_facilities, 'hirchy')
    // if (protect_routes !== undefined) {
      this.setState(
        {
          img_max_mb: data.img_max_mb,
          userInfo: data.user_data,
          client_info: data.client_info[0],
          usercode: data.user_data.user_code,
          mappinginfo: data.user_data.mappinginfo,
          designation: data.user_data.designation,
          configData: data.config_data,
          dataloaded: false,
          isEdit: false,
          db_info: db_info,

        },
        function () {
          this.getHStructureListData()
        }
      )
    // }
    // else {
    //   this.props.history.push('/dashboard')
    // }
  }




  getHStructureListData = () => {
    try {

      urlSocket.post("webhstre/incd-gethslist", {
        userInfo: {
          created_by: this.state.userInfo._id,
          company_id: this.state.userInfo.company_id,
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          // publish_status : ""
        },
      })
        .then((response) => {
          console.log("response", response.data.data)
          // _.orderBy(response.data.data, ['active'], ['asc'])
          this.setState({
            dataloaded: false
          },()=>{
          this.setState({
            dataSource: response.data.data,
            dataloaded: true,
          })
          })

        }).catch((error)=>{
          console.log(error,'error')
        })
    } catch (error) {
      console.log(error,'error')
    }
  }

  dateConvertion = (dateToConvert) => {
    if (dateToConvert != null) {
      var date = typeof (dateToConvert) == "object" ? String(dateToConvert.toISOString()) : String(dateToConvert)
      var convertedDate = date.slice(8, 10) + ' / ' + (date.slice(5, 7)) + ' / ' + date.slice(0, 4);//prints expected format. 
      if (convertedDate == "01 / 01 / 1970") {
        return "-- / -- / --"
      }
      else {
        return convertedDate
      }
    }
    else {
      return "-- / -- / --"
    }
  }

  navigateTo = (hInfo) => {
    sessionStorage.removeItem("userNodeInfo");
    sessionStorage.removeItem("hInfo");
    sessionStorage.setItem("hInfo", JSON.stringify(hInfo));
    this.props.history.push("/incd-crudhstre");
    // this.props.history.push("/new-hstructure")
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  handleUserClicks = arg => {
    this.setState({ users: '', isEdit: false, hname: '', _id: null, hierarchy_name_exist: false })
    this.toggle()
  }

  handleValidHrchySubmit = (e, values) => {
    const { isEdit, users, selectedUser } = this.state

    const hInfo = {
      hname: values["hname"],
    }
    // save new hirerachy
    console.log("id", this.state._id)
    if (this.state.hierarchy_name_exist == false && isEdit == false) {
      this.createNewHirerachy(hInfo)
      this.setState({ selectedUser: null })
      this.toggle()
    }
    else if (isEdit == true) {
      this.createNewHirerachy(hInfo)
      this.setState({ selectedUser: null })
      this.toggle()

    }
    // else{
    //   console.log(this.state.isEdit)
    // }
  }



  handleDeleteHrchy = (values) => {
    console.log("delete", values)

    this.setState({blur_loading: true, confirm_both: false})

    try {
      urlSocket.post("webhstre/incd-deletehstructure", {
        info: values,
        encrypted_db_url: this.state.db_info.encrypted_db_url
      })
        .then(response => {
          console.log(response, 'response')
          if (response.data.response_code == 500) {
            this.setState({
              // dataSource:response.data.data,
              confirm_both: false,
              success_dlg: true,
              dynamic_title: "Deleted",
              dynamic_description: "Your file has been deleted.",
              blur_loading: false
            }, () => { 
              // this.setState({
              //   dataloaded : false
              // },()=>{
              this.getHStructureListData() })
            // })
          }
        })
    } catch (error) {
      console.log("catch error", error)
    }
  }

  createNewHirerachy = (hInfo) => {
    try {

      urlSocket.post("webhstre/incd-crudhrchy", {
        hInfo: {
          company_id: this.state.userInfo.company_id,
          company_name: this.state.client_info.client_name,
          created_by: this.state.userInfo._id,
          updated_by: this.state.userInfo._id,
          active: 1,
          hname: hInfo.hname,
          _id: this.state._id,
          encrypted_db_url: this.state.db_info.encrypted_db_url
        },
      })
        .then((response) => {
          console.log("response", response.data.data)
          if (response.data.response_code === 500) {
            this.state.isEdit ? this.getHStructureListData() : this.navigateTo(response.data.data)
          }
          else {
            console.log("Not Created")
          }

        })
    } catch (error) {

    }
  }



  validateHierarchyName = (event) => {
    console.log(event.target.value, this.state.isEdit)
    if (this.state.isEdit === false) {
      try {
        urlSocket.post('cog/validate-incd-hierarchy-name', {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          hname: event.target.value,
          created_by: this.state.userInfo._id,
          create: true
        }).then((response) => {
          console.log(response, 'respp')
          if (response.data.data.length > 0) {
            this.setState({ hierarchy_name_exist: true })
          }
          else {
            this.setState({ hierarchy_name_exist: false })

          }
        })
      } catch (error) {

      }
    }
    else {
      try {
        urlSocket.post('cog/validate-incd-hierarchy-name', {
          encrypted_db_url: this.state.db_info.encrypted_db_url,
          hname: event.target.value,
          created_by: this.state.userInfo._id,
          create: false,
          _id: this.state._id
        }).then((response) => {
          console.log(response, 'respp')
          if (response.data.data.length > 0) {
            this.setState({ hierarchy_name_exist: true })
          }
          else {
            this.setState({ hierarchy_name_exist: false })

          }
        })
      } catch (error) {

      }
    }


  }




  render() {

    if (this.state.dataloaded) {
      // const { SearchBar } = Search;
      // const { users } = this.state

      const { isEdit } = this.state
      const options = {
        // pageStartIndex: 0,

        sizePerPage: 10,
        totalSize: this.state.dataSource.length, // replace later with size(users),
        custom: true,
      };
      const columns = [
        {
          accessor: 'hname',
          Header: 'Hierarchy Name',
          filterable: true,
          width: "50%",
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <div className="d-flex flex-row" >
                <div className="me-2">
                  <i
                    className={
                      item.publish_status == "0" ? "mdi mdi-file-tree font-size-15 text-secondary" :
                        item.publish_status == "1" ? "mdi mdi-file-tree font-size-15 text-success" :
                          "mdi mdi-file-tree font-size-15 text-danger"
                    }
                  />
                </div>
                <div className="text-dark">
                  {item.hname}
                </div>
              </div>
            )
          }
        },
        // {
        //   accessor: 'created_on',
        //   Header: 'Created On',
        //   width: "10%",
        //   Cell: (cellProps) => {
        //     var item = cellProps.row.original
        //     return (
        //       <>
        //        {new Intl.DateTimeFormat("en-GB", {
        //                         month: "short",
        //                         day: "2-digit",
        //                         year: "numeric",
        //                     }).format(new Date(item.created_on))}
              
        //         {/* {this.dateConvertion(item.created_on)} */}
        //       </>
        //     )
        //   }
        // },
        {
          accessor: 'publish_status',
          Header: 'Publish Status',
          width: "10%",
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <>
                <span className={item.publish_status == "0" ? "badge bg-secondary font-size-11 m-1" :
                  item.publish_status == "1" ? "badge bg-success font-size-11 m-1" : "badge badge-danger font-size-11 m-1"}
                >
                  {item.publish_status == "0" ? "Not publish" : "Published"}
                </span>
              </>
            )
          }
        },
        {
          accessor: "menu",
          // isDummyField: true,
          // editable: false,
          Header: "Action",
          width: "10%",
          Cell: (cellProps) => {
            var item = cellProps.row.original
            return (
              <div className="d-flex gap-3" style={{ display: 'flex', alignContent: 'center' }}>
                <Button type="button" color="primary" size="sm" className="btn waves-effect waves-light" onClick={() => { this.navigateTo(item) }}>
                  View Detail
                </Button>
                <Link className="text-success" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => { this.setState({ isEdit: true, hname: item.hname, _id: item._id, hierarchy_name_exist: false}); this.toggle() }}></i></Link>
                <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ confirm_both: true, hitem: item })}></i></Link>

              </div>
            )
          },
        },
      ]
      const defaultSorted = [{
        dataField: 'hname', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
      }];

      return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>AuditVista | Manage Hierarchy</title>
            </MetaTags>
            <Container fluid>

              <Breadcrumbs title="Hierarchy" breadcrumbItem="Hierarchy" />

              <Row >
                <Col lg="12">
                  <Card >
                    <CardBody>

{/*                       
                      <TableContainer
                        columns={columns}
                        data={this.state.dataSource}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        isJobListGlobalFilter={false}
                        customPageSize={10}
                        style={{ width: '100%' }}
                        dynamicBtn={true}
                        btnClick={this.handleUserClicks}
                        iscustomPageSizeOptions={false}
                        // dynamicParams={params1,params2}
                        isPagination={true}
                        filterable={false}
                        btnName={"Create Hirerachy"}
                        tableClass="table align-middle table-nowrap table-hover"
                        theadClass="table-light"
                        paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination justify-content-end pagination-rounded"

                      /> */}
                      <div className='text-end my-2 pe-2' >

                        <Button
                          color="primary"

                          onClick={this.handleUserClicks}
                        >
                          Create Hierarchy
                        </Button>
                      </div>


                      <TableContainer
                        columns={columns}
                        data={this.state.dataSource}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        isJobListGlobalFilter={false}
                        customPageSize={10}
                        style={{ width: '100%' }}
                        // dynamicBtn={true}
                        // btnClick={this.handleUserClicks}
                        iscustomPageSizeOptions={false}
                        // dynamicParams={params1,params2}
                        isPagination={true}
                        filterable={false}
                        // btnName={"Create Hirerachy"}
                        tableClass="table align-middle table-nowrap table-hover"
                        theadClass="table-light"
                        // paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination pagination-rounded justify-content-center mb-2 my-2"

                      />

                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Modal
                  isOpen={this.state.modal}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggle} tag="h5">
                    {!!isEdit ? "Edit Hierarchy" : "Add Hierarchy"}
                  </ModalHeader>
                  <ModalBody>
                    <AvForm
                      onValidSubmit={
                        this.handleValidHrchySubmit
                      }
                    >
                      <Row form>
                        <Col className="col-12">
                          <div className="mb-3">

                            <AvField
                              name="hname"
                              label="Hierarchy Name"
                              type="text"
                              errorMessage="Invalid name"
                              value={this.state.hname}
                              onChange={(e) => { this.validateHierarchyName(e) }}
                              validate={{
                                required: { value: true },
                              }}
                            //value={this.state.users.name || ""}
                            />
                            {
                              this.state.hierarchy_name_exist &&
                              <div className="text-danger" style={{ fontSize: 'smaller' }}>Hierarchy Name already exist </div>
                            }
                          </div>



                        </Col>

                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">

                            <button
                              type="submit"
                              className="btn btn-success save-user"
                              disabled={this.state.hierarchy_name_exist}
                            >
                              {!!isEdit ? "Update" : "Create"}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </ModalBody>
                </Modal>
                {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.handleDeleteHrchy(this.state.hitem)
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                        error_dlg: true,
                        dynamic_title: "Cancelled",
                        dynamic_description:
                          "Your imaginary file is safe :)",
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.success_dlg ? (
                  <SweetAlert
                    success
                    title={this.state.dynamic_title}
                    onConfirm={() => this.setState({ success_dlg: false })}
                  >
                    {this.state.dynamic_description}
                  </SweetAlert>
                ) : null}
              </Row>
            </Container>
          </div>



          {this.state.blur_loading && (
            <>
              <div className="loader-overlay">
                <div className="loader">
                  <LoadingOutlined />
                </div>
              </div>

            </>
          )}



        </React.Fragment>
      )
    }
    else {
      return null
    }
  }
}
