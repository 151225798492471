import React, { useRef, useEffect } from 'react';

const WebcamSketch = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const isDrawingRef = useRef(false);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const constraints = {
      video: true
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        video.srcObject = stream;
      })
      .catch((error) => {
        console.error('Error accessing webcam:', error);
      });

    const draw = () => {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      requestAnimationFrame(draw);
    };

    video.addEventListener('loadedmetadata', () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      draw();
    });

    const startDrawing = (event) => {
      isDrawingRef.current = true;
      drawLine(event);
    };

    const stopDrawing = () => {
      isDrawingRef.current = false;
    };

    const drawLine = (event) => {
      if (!isDrawingRef.current) return;

      const { offsetX, offsetY } = event?.nativeEvent;
      ctx.lineWidth = 5;
      ctx.lineCap = 'round';
      ctx.strokeStyle = 'red';

      ctx.lineTo(offsetX, offsetY);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(offsetX, offsetY);
    };

    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', drawLine);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseout', stopDrawing);
    canvas.addEventListener('touchstart', startDrawing);
    canvas.addEventListener('touchmove', drawLine);
    canvas.addEventListener('touchend', stopDrawing);

    return () => {
      video.srcObject.getTracks().forEach(track => track.stop());
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mousemove', drawLine);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mouseout', stopDrawing);
      canvas.removeEventListener('touchstart', startDrawing);
      canvas.removeEventListener('touchmove', drawLine);
      canvas.removeEventListener('touchend', stopDrawing);
    };
  }, []);

  return (
    <div>
      <video ref={videoRef} autoPlay></video>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default WebcamSketch;







// import React, { useRef, useEffect } from 'react';

// const WebcamSketch = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);

//   useEffect(() => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext('2d');

//     const constraints = {
//       video: true
//     };

//     navigator.mediaDevices.getUserMedia(constraints)
//       .then((stream) => {
//         video.srcObject = stream;
//       })
//       .catch((error) => {
//         console.error('Error accessing webcam:', error);
//       });

//     const draw = () => {
//       ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
//       // You can add your sketching logic here
//       // For example: ctx.strokeStyle = 'red'; ctx.lineWidth = 5; ctx.strokeRect(50, 50, 100, 100);
//       requestAnimationFrame(draw);
//     };

//     video.addEventListener('loadedmetadata', () => {
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;
//       draw();
//     });

//     return () => {
//       // Cleanup
//       video.srcObject.getTracks().forEach(track => track.stop());
//     };
//   }, []);

//   return (
//     <div>
//       <video ref={videoRef} autoPlay></video>
//       <canvas ref={canvasRef}></canvas>
//     </div>
//   );
// };

// export default WebcamSketch;
