import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
// import { apiReducer } from '../pages/Report/Redux/ApiSlice'
import { treeDataSliceReducer } from './reportd3/treedataSlice'
import { sessionSliceReducer } from "./report/Slice/SessionInfoSlice";
import { LayoutSliceReducer } from "./report/Slice/LayoutInfoSlice";

//actionplan
import { chatSliceReducer } from "../store/actionPlan/Slice/GroupChatSlice";
import { infosliceReducer } from "../store/actionPlan/Slice/actionplaninfoslice";
import { indexsliceReducer } from "../store/actionPlan/Slice/indexslice";
import { auditlocationsReducer } from "../store/actionPlan/Slice/auditlocationslice";
import { actionplansliceReducer } from "../store/actionPlan/Slice/actionplanslice";
import DownloadSlice from "./report/Slice/DownloadSlice";
import SortingSlice from "./report/Slice/SortingSlice";
import { layoutSliceInfoReducer } from "./report/Slice/LayoutSlice";
import { chatInfoSliceReducer } from "./actionPlan/Slice/chatInfoSlice";


//contacts
import contacts from "./contacts/reducer"

import acplnFollowUpSliceReducer from "./aplnfollowup/aplnflwupslice";
import socketSliceReducer from "./socket/socketSlice"

import { reportUserSliceReducer } from "./report/Slice/ReportUserSlice";
//report rework
import reportSliceReducer  from "./reportd3/reportslice";

import chatslice from './chat/chatslice'
import {HtreeDataSliceReducer} from '../store/audit/htree'
import { IncidentHtreeDataSliceReducer } from "./incidentReport/htree";


import incdreportslice from "./incidentReport/incdreportslice";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  contacts,
  HtreeData: HtreeDataSliceReducer,
  IncidentHtreeData: IncidentHtreeDataSliceReducer,
  treeData: treeDataSliceReducer,
  sessioninfo: sessionSliceReducer,
  layoutslice: LayoutSliceReducer,
  groupchat: chatSliceReducer,
  auditinfoslice: infosliceReducer,
  followupindexslice: indexsliceReducer,
  auditlocationslice: auditlocationsReducer,
  followupactionplanslice: actionplansliceReducer,
  LayoutArray: layoutSliceInfoReducer,
  SortArr: SortingSlice,
  download: DownloadSlice,
  chatInfoSlice: chatInfoSliceReducer,
  acplnFollowUpSliceReducer,
  socketSliceReducer,
  reportSliceReducer,
  reportUser : reportUserSliceReducer,
  chatslice,
  incdreportslice
});

export default rootReducer;
