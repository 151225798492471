import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { sortFunc, sortDescending,sortVerticalline,handleDownloadBar,imgDownloadSvg,imgDownloadPng } from '../../../../store/reportd3/reportslice';


const VerticalLineChart = (props) => {
    const dispatch = useDispatch();
    const chartRef = useRef();
    var containerWidth = props.containerWidth
    var containerHeight = props.containerHeight
    var chart_data = props.chart_data
    var chart_color = props.chart_color
    var curved_line = props.curved_line
    var mouseovered = props.mouseovered
    var mouseovered_type = props.mouseovered_type
    var i = props.id
    var enable_table = props.show_table
    var svgHeight = props.chart_height
    var show_Grid = props.show_Grid
    var temp_containerWidth = props.temp_containerWidth
    var temp_containerHeight = props.temp_containerHeight
    var fullScreen_enabled = props.show_Full_Screen_toggle
    var show_bar_values = props.show_bar_values
    var show_Square = props.show_Square
    var YLabel = props.YLabel
    var text_color_arr = props.text_color
  var barLabel = props.label;


    const [data, setData] = useState(chart_data);
    const [textColorbar, SeTextColorBar] = useState([])
    const [curved, setCurved] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [mouseoverEnabled, setMouseoverEnabled] = useState(mouseovered)
    const [mouseoverSwitchType, setMouseoverSwitchType] = useState(mouseovered_type)
    const [showGridEnabled, setShowGridEnabled] = useState(show_Grid);
    const [sortShowOptions, setShowSortoption] = useState(false);
    const [showValues, setShowValues] = useState(show_bar_values);
    const [selectedValues, setSelectedValues] = useState([]);
    const [arrValues, setarrValues] = useState([])
    const SortArr = useSelector(state => state.reportSliceReducer);
    const [sortData, setSortData] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [scrollDelta, setScrollDelta] = useState(0);
    
    useEffect(() => {
        if (chart_data !== undefined && chart_data.length > 0) {
            setData(chart_data)
            SeTextColorBar(chart_color)
            setCurved(curved_line)
            setMouseoverEnabled(mouseovered)
            setMouseoverSwitchType(mouseovered_type)
            setShowGridEnabled(show_Grid)
            setShowValues(show_bar_values)
        }
    }, [chart_data, chart_color, mouseovered, mouseovered_type, enable_table, show_Grid, temp_containerWidth, fullScreen_enabled, temp_containerHeight, show_bar_values])
    if (props.chart_data.length !== 0) {
        var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
        var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
    }
    useEffect(() => {
        const data = chart_data;
        if (datakeys !== undefined) {
            var mod_data;
            var chart_id = i;
            if (SortArr[chart_id] && SortArr[chart_id].linesorted) {
                mod_data = SortArr[chart_id].linesorted;
            } else {
                mod_data = data;
            }
            const margin = { top: 70, right: 40, bottom: 80, left: 100 };
            if (svgHeight !== undefined && svgHeight !== '') {
                containerHeight = containerHeight - 200
            }
            else {
                containerHeight = containerHeight
            }
            var width;
            var height;
            if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
                width = temp_containerWidth - margin.left - margin.right;
                height = temp_containerHeight - margin.top - margin.bottom - (enable_table ? 200 : 0);
                containerWidth = temp_containerWidth
                containerHeight = temp_containerHeight - (enable_table ? 200 : 0);
            } else {
                width = containerWidth - margin.left - margin.right;
                height = containerHeight - margin.top - margin.bottom;
            }
            d3.select(chartRef.current).selectAll('*').remove();
            const drag = d3.drag()
                .on("drag", dragged)
                .on("end", dragEnded)
            const svg = d3
                .select(chartRef.current)
                .attr('width', containerWidth)
                .attr('height', containerHeight)
                .append('g')
                .style("overflow-y", "scroll")
            d3.select(`#my_dataviz${i}`)
                .call(drag);
            const chartGroup = svg
                .append("g")
                .attr('transform', `translate(${margin.left},${margin.top})`);
            let initialMouseX, initialMouseY;
            let initialX, initialY;

            function dragged(event, d) {
                if (!initialMouseY) {
                    initialMouseX = d3.pointer(event)[0];
                    initialMouseY = d3.pointer(event)[1];
                    const initialTransform = svg.attr("transform");
                    initialX = initialTransform ? parseFloat(initialTransform.split("(")[1].split(",")[0]) : 0;
                    initialY = initialTransform ? parseFloat(initialTransform.split(",")[1].split(")")[0]) : 0;
                }
                const mouseY = d3.pointer(event)[1];
                const deltaY = mouseY - initialMouseY;
                const newY = initialY + deltaY;
                if (scrollDelta) {
                    if (newY >= 0 && !(newY >= newContainerHeight - containerHeight + 100)) {
                        svg.attr("transform", `translate(${initialX},${newY})`);
                    }
                    else if (newY <= newContainerHeight - containerHeight) {
                    }
                }
            }

            function dragEnded(event, d) {
                initialMouseX = null;
                initialMouseY = null;
            }

            const xScale = d3
                .scaleLinear()
                .domain([0, d3.max(mod_data, (d) => d3.max(datakeys.map((key) => d[key])))])
                .nice()
                .range([0, width]);
            const yScale = d3
                .scalePoint()
                .domain(mod_data.map((d) => d.year))
                .range([height, 0]);
            const color = d3.scaleOrdinal().range(
                textColorbar !== '' && textColorbar !== undefined && textColorbar.length > 0
                    ? textColorbar
                    : ['red', 'blue', 'green', 'orange', 'purple', 'cyan']
            );

            const text_color = d3.scaleOrdinal()
                .domain(datakeys)
                .range(text_color_arr !== undefined && text_color_arr.length > 0
                    ? text_color_arr
                    : d3.quantize(d3.interpolateRainbow, datakeys.length + 2));

            var lineClass;
            const yDomain = d3.max(mod_data, (d) => d.year);
            const defaultYValue = yDomain[0];
            var newContainerHeight = containerHeight + scrollDelta;
            if (newContainerHeight > containerHeight) {
                const newRange = [newContainerHeight - margin.bottom, 0];
                var y1 = yScale.range(newRange);
                svg.append('g').attr('height', newContainerHeight)

                const axisGroup = svg.append('g')
                    .call(d3.axisLeft(yScale).ticks(5).tickFormat(d => typeof d === 'string' ? d.toUpperCase() : d));

                const axisLabels = axisGroup.selectAll('text')
                    .attr("fill", "black")
                    .style("font-size", '11px')
                    .style("font-style", "normal")
                    .attr('transform', `translate(${margin.left}, ${margin.top - margin.bottom - scrollDelta})`);
                let rotationAngle = 0;
                axisGroup.on('click', function () {
                    rotationAngle = rotationAngle === 0 ? -45 : 0;
                    const textAnchor = rotationAngle === 0 ? 'middle' : 'end';
                    // axisGroup.attr('transform', `translate(${margin.left}, ${margin.top - margin.bottom })`);
                    axisLabels.attr('transform', `translate(${margin.left}, ${margin.top - margin.bottom })) rotate(${rotationAngle})`)
                });
            } else {
                yScale.range([containerHeight - margin.top - margin.bottom, 0]);
                svg.append("g").attr('height', containerHeight);
                const axisGroup = svg.append('g')
                    .call(d3.axisLeft(yScale).ticks(5).tickFormat(d => typeof d === 'string' ? d.toUpperCase() : d));

                const axisLabels = axisGroup.selectAll('text')
                    .attr("fill", "black")
                    .style("font-size", '11px')
                    .style("font-style", "normal")
                    .attr('transform', `translate(${margin.left}, ${margin.bottom - 20})`);
                let rotationAngle = 0;
                axisGroup.on('click', function () {
                    rotationAngle = rotationAngle === 0 ? -45 : 0;
                    const textAnchor = rotationAngle === 0 ? 'middle' : 'end';
                    axisLabels.attr('transform', `translate(${margin.left}, ${margin.bottom - 20}) rotate(${rotationAngle})`)
                });
            }
            d3.selectAll(`line ${lineClass}`).remove()
            if (newContainerHeight >= containerHeight) {
                d3.select(`#my_dataviz${i}`).on('wheel', handleScroll);
            }
            else {
                setScrollDelta(0)
            }
            datakeys.forEach((valueKey, index) => {
                const line = getLineGenerator(curved, valueKey);
                lineClass = `line-${valueKey}`;
                function getLineGenerator(usecurvedLines, key) {
                    return usecurvedLines
                        ? d3
                            .line()
                            .x((d) => xScale(d[key]))
                            .y((d) => yScale(d.year))
                            .curve(d3.curveCatmullRom.alpha(0.5))
                        : d3
                            .line()
                            .x((d) => xScale(d[key]))
                            .y((d) => yScale(d.year))
                }
                const path = chartGroup
                    .append('path')
                    .datum(mod_data)
                    .attr('class', `line ${lineClass}`)
                    .attr('fill', 'none')
                    .attr('stroke', color(valueKey))
                    .attr('stroke-width', 2)
                    .attr('d', line)
                    .style('stroke-dasharray', function () {
                        const totalLength = this.getTotalLength();
                        return totalLength + ' ' + totalLength;
                    })
                    .style('stroke-dashoffset', function () {
                        return this.getTotalLength();
                    })
                    .style('stroke-dashoffset', 0)
                if (scrollDelta <= 0) {
                    path
                        .attr('transform', `translate(${0}, ${margin.top - margin.bottom})`);
                }
                else {
                    path
                        .attr('transform', `translate(${0}, ${- scrollDelta - margin.top - 10})`);
                }
                if (scrollDelta <= 0) {
                }
                const labelGroup = chartGroup.append('g')
                    .attr('class', 'label-group');

                const circles = labelGroup.selectAll('.line-circle')
                    .data(mod_data)
                    .enter()
                    .append('circle')
                    .attr('class', 'line-circle')
                    .attr('cx', d => xScale(d[valueKey]))
                    .attr('cy', d => yScale(d.year) - (scrollDelta !== 0 ? 10 : 0))
                    .attr('r', 6)
                    .attr('fill', 'green')
                    .style("opacity", 0)
                    .style("opacity", 1)
                    .each(function (d) {
                        const circle = d3.select(this);
                        const xPos = parseFloat(circle.attr('cx'));
                        const yPos = parseFloat(circle.attr('cy'));

                        const tooltipGroup = chartGroup.append('g')
                            .attr('class', 'tooltip-group')
                            .style('opacity', 0);

                        tooltipGroup.append('rect')
                            .attr('class', 'tooltip-background')
                            .attr('x', xPos + 20)
                            .attr('y', yPos - 25)
                            .attr('width', 40)
                            .attr('height', 40)
                            .attr('fill', 'white')
                            .attr('rx', 3)
                            .attr('ry', 3);

                        const valueText = tooltipGroup.append('text')
                            .attr('class', 'tooltip-text')
                            .attr('x', xPos + 20)
                            .attr('y', yPos - 10)
                            .attr('fill', 'red')
                            .style('font-size', '12px')
                            .style('opacity', 1);

                        const yearText = tooltipGroup.append('text')
                            .attr('class', 'tooltip-text')
                            .attr('x', xPos + 20)
                            .attr('y', yPos + 5)
                            .attr('fill', 'black')
                            .style('font-size', '12px')
                            .style('opacity', 1);

                        const tooltipGroup_circle = chartGroup.append('g')
                            .attr('class', 'tooltip-group')
                            .style('opacity', 0);

                        tooltipGroup_circle.selectAll('.tooltip-background')
                            .data([d])
                            .join('rect')
                            .attr('class', 'tooltip-background')
                            .attr('x', xPos + 20)
                            .attr('y', yPos - 25)

                            .attr('width', function (d) {
                                const maxWidth = 100;
                                const contentWidth = 150;
                                return Math.min(maxWidth, contentWidth);
                            })
                            .attr('height', function (d) {
                                const lineHeight = 17;
                                const numLines = Object.keys(d).length - 1;
                                const minHeight = 30;
                                const calculatedHeight = numLines * lineHeight + 20;
                                return Math.max(minHeight, calculatedHeight);
                            })
                            .attr('fill', 'white')
                            .attr('rx', 3)
                            .attr('ry', 3);

                        const tooltipText = tooltipGroup_circle.selectAll('.tooltip-text')
                            .data(Object.entries(d).filter(([key, value]) => key !== '_id'))
                            .join('text')
                            .attr('class', 'tooltip-text')
                            .attr('x', xPos + 30)
                            .attr('y', (d, i) => yPos - 10 + i * 15)
                            .attr('fill', 'red')
                            .style('font-size', '12px')
                            .text(([key, value]) => `${key}: ${value}`);

                        circle.on("mouseover", mouseoverPoints)
                            .on('mouseout', mouseoutPoints)
                            .on('mouseleave', mouseoutPoints)
                    });

                d3.select(`#my_dataviz${i}`).selectAll(".xContainer , .x-axis").remove()
                d3.select(`#my_dataviz${i}`).selectAll(`.x-axis${i}`).remove()

                const XAxisContainer = d3.select(`#my_dataviz${i}`)
                    .append("svg")
                    .attr('class', `x-axis${i}`)
                    .style("position", "absolute")
                    .style('background-color', 'white')
                    .style("top", `${containerHeight - margin.bottom}px`)
                    .style("left", '0px')
                    .style("width", `${containerWidth - 15}px`)
                    .style("height", `${margin.bottom}px`);

                const backgroundRect = XAxisContainer.append("rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("width", containerWidth)
                    .attr("height", '100%')
                    .attr("fill", "white");

                const xAxisSvg = XAxisContainer.append("svg")
                    .attr('class', "xContainer")
                    .attr("width", containerWidth)
                    .attr("height", '100%')
                    .append("g")
                    .attr("transform", `translate(${100}, 0)`)
                    .call(d3.axisBottom(xScale).ticks())
                    .call(g => {
                        g.selectAll('.domain, text')
                            .attr('stroke', fullScreen_enabled ? 'black' : 'black')
                            .style("font-size", '10px');
                    });

                d3.selectAll(`.top-layer${i}`).remove()

                const TopContainer = d3.select(`#my_dataviz${i}`)
                    .append("svg")
                    .attr('class', `top-layer${i}`)
                    .style("position", "absolute")
                    .style('background-color', 'white')
                    .style("top", `${0}px`)
                    .style("left", '0px')
                    .style("width", `${containerWidth}px`)
                    .style("height", `${margin.top - 15}px`);

                const TopbackgroundRect = TopContainer.append("rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("width", containerWidth)
                    .attr("height", '100%')
                    .attr("fill", "white");

                if (!show_Square) {
                    const tooltipGroup_square = chartGroup.append('g')
                    d3.selectAll(".line-circle").remove()
                    const squares = labelGroup.selectAll('.line-square')
                        .data(mod_data);
                    squares.exit()
                        .transition()
                        .duration(100)
                        .style('opacity', 0)
                        .remove();

                    const squaresEnter = squares.enter()
                        .append('rect')
                        .attr('class', 'line-square')
                        .attr('x', d => xScale(d[valueKey]) - 4)
                        .attr('y', d => yScale(d.year) - (scrollDelta !== 0 ? 14 : 6))
                        .attr('width', 10)
                        .attr('height', 10)
                        .attr('fill', 'red')
                        .style('opacity', 0)
                        .transition()
                        .duration(500)
                        .style('opacity', 1);

                    squares.merge(squaresEnter)
                        .on('mouseover', mouseoverPoints)
                        .on('mouseout', mouseoutPoints)
                        .on('mouseleave', mouseoutPoints)
                }

                if (scrollDelta <= 0) {
                    labelGroup
                        .attr('transform', `translate(${0}, ${margin.top - margin.bottom})`);

                    const labelGroup1 = chartGroup.append('g')
                        .attr('class', 'label-group')
                        .style('fill', color(valueKey));
                    labelGroup1.selectAll('.line-label')
                        .data(mod_data)
                        .enter()
                        .append('text')
                        .attr('class', 'line-label')
                        .attr('x', d => xScale(d[valueKey]))
                        .attr('y', d => yScale(d.year) - 20)
                        .text(d => showValues ? d[valueKey] : '')
                        .attr('text-anchor', 'middle')
                        .attr('fill', text_color(valueKey))
                        .style("opacity", 0)
                        .style("opacity", 1);
                }
                else {
                    labelGroup
                        .attr('transform', `translate(${0}, ${- scrollDelta - margin.top})`);

                    const chartGroup = svg.append("g")
                        .attr('transform', `translate(${margin.left},${margin.top})`);
                    const labelGroup1 = chartGroup.append('g')
                        .attr('class', 'label-group')
                        .style('fill', color(valueKey));
                    labelGroup1.selectAll('.line-label')
                        .data(mod_data)
                        .enter()
                        .append('text')
                        .attr('class', 'line-label')
                        .attr('x', d => xScale(d[valueKey]))
                        .attr('y', d => yScale(d.year) - 20)
                        .text(d => showValues ? d[valueKey] : '')
                        .attr('text-anchor', 'middle')
                        .attr('fill', text_color(valueKey))
                        .style("opacity", 0)
                        .transition()
                        .duration(800)
                        .style("opacity", 1).attr('transform', `translate(${0}, ${- scrollDelta - margin.top})`)
                }

                function mouseoverPoints(event, d) {
                    console.log('463', 463)
                    d3.selectAll('.tooltip').remove()
                    const [mouseX, mouseY] = d3.pointer(event);
                    const xPos = xScale(d[valueKey]);
                    const yPos = scrollDelta ? event.pageY - (fullScreen_enabled ? 0 : 200) : yScale(d.year);
                    const tooltip = d3.select(`#my_dataviz${i}`)
                        .append("div")
                        .style("opacity", 0)
                        .attr("class", "tooltip")
                        .style("position", "absolute")
                        .style("background-color", "white")
                        .style("border", "solid")
                        .style("border-width", "1px")
                        .style("border-radius", "5px")
                        .style("padding", "10px");

                    if (mouseoverEnabled) {
                        if (!mouseoverSwitchType) {
                            let tooltipContent = `<span style="color:red;">${barLabel}:</span> ${d.year}<br>`;
                            tooltipContent += `<span style="color:red;">Value:</span> <span style="color:black;">${d[valueKey]}</span><br>`;
                            tooltip
                                .html(tooltipContent)
                                .style("opacity", 1)
                                .style("background-color", "white")
                                .style("color", "black")
                                .style("border", "solid")
                                .style("border-width", "1px")
                                .style("border-radius", "5px")
                                .style("padding", "10px")
                                .style('border', `2px solid ${color(valueKey)}`)
                                .style("left", (event.offsetX + 30) + "px")
                                .style("top", (event.offsetY) + "px")
                        } else {
                            var totalValue = d3.sum(datakeys.map(key => d[key]));
                            let tooltipContent = `<span style="color:red;">Label:</span> ${d.year}<br>`;
                            datakeys.forEach(key => {
                                tooltipContent += `<span style="color:red;">${key}:</span> <span style="color:black;">${d[key]}</span><br>`;
                            });
                            tooltipContent += `<span style="color:red;">Total:</span> <span style="color:black;">${totalValue}</span>`;
                            tooltip
                                .html(tooltipContent)
                                .style("opacity", 1)
                                .style("left", (xPos + 120) + "px")
                                .style("top", (yPos - 10) + "px");
                        }
                    }
                }
                function mouseoutPoints(event, d) {
                    d3.selectAll('.tooltip').remove()
                    chartGroup.selectAll('.tooltip-group').remove();
                    chartGroup.selectAll('.tooltip-group-square').remove();
                }
            });

            if (show_Grid) {
                chartGroup.insert('g', ':first-child')
                    .attr('class', 'x-grid')
                    .attr('transform', `translate(0, ${height})`)
                    .call(d3.axisBottom(xScale).tickSize(scrollDelta !== 0 ? (margin.bottom - newContainerHeight - 10) : (margin.bottom - height - margin.top - 20)).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'lightgrey')
                chartGroup.insert('g', ':first-child')
                    .attr('class', 'y-grid')
                    .attr('transform', `translate(${0}, ${scrollDelta !== 0 ? (- scrollDelta - margin.top - 10) : -10})`)
                    .call(d3.axisLeft(yScale).ticks(5).tickSize(-width).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'lightgrey');
            } else {
                chartGroup.selectAll('.grid').remove();
            }
            
            var datakeys_mod

            if (YLabel.length > 0) {
                datakeys_mod = YLabel.slice(1)
            }
            else {
                datakeys_mod = datakeys
            }
            // const legendContainer = d3.select(`#legend${i}`);
            // const legendY = height + margin.bottom - 40;
            // legendContainer.selectAll('*').remove();

            // datakeys_mod.forEach((key, i) => {
            //     const legendItemGroup = legendContainer.append('div')
            //         .attr('class', 'legend-item')
            //     legendItemGroup.append('div')
            //         .attr('class', 'legend-rect')
            //         .style('background-color', (chart_color?.length > 0 && chart_color[i + 1] != null) ? chart_color[i] : color(key))
            //     const legend = legendContainer.attr('transform', `translate(${250}, ${legendY})`);
            //     legendItemGroup.append('text')
            //         .attr('fill', color(key))
            //         .text(key)
            // });

            const legendContainer = d3.selectAll(`#legend${i}`);
            const legendWidth = datakeys.length * 120;
            const legendX = (containerWidth - legendWidth) / 2;
            const legendY = height + margin.bottom - 40;
            legendContainer.selectAll('*').remove(); // Clear previous legend content
            
            datakeys_mod.forEach((key, i) => {
              const legendItemGroup = legendContainer.append('div')
                .attr('class', 'legend-item')
                .style('display', 'flex')
                .style('align-items', 'center')
                .style('margin-right', '10px'); // Add some space between legend items
            
              legendItemGroup.append('div')
                .attr('class', 'legend-rect')
                .style('width', '13px')
                .style('height', '13px')
                .style('background-color', () => {
                  var colorToUse = (chart_color?.length > 0 ? (chart_color[i] != undefined) ? chart_color[i] : chart_color[i + 1] : color(key));
                  return colorToUse;
                })
                .style('margin-right', '5px'); // Space between rectangle and text
            
              legendItemGroup.append('text')
                .attr('class', 'legend-text')
                .style('color', 'green')
                .text(key);
            });

            if (enable_table) {
                showTableFn(true)
            }
            else {
                showTableFn(false)
            }
            const handleResetButtonClick = () => {
                setScrollDelta(0);
                xScale.range([0, containerWidth - margin.left - margin.right])
                svg.attr("width", containerWidth)
                svg.attr("transform", `translate(${0},${margin.top - margin.bottom})`)
            };
            document.getElementById(`togglereset-${i}`).addEventListener('click', function (event) {
                handleResetButtonClick()
            })
        }
    }, [containerWidth, YLabel, containerHeight, chart_data, textColorbar, curved, mouseoverEnabled, mouseoverSwitchType, showGridEnabled, temp_containerWidth, fullScreen_enabled, temp_containerHeight, sortData, showValues, enable_table, svgHeight, show_Square, chart_color, SortArr, scrollDelta, text_color_arr]);
    const handleMenuClick = (e) => {
        setShowOptions(!showOptions);
    };
    const showTableFn = async (val) => {
        const fieldNames = Object.keys(data[0]).filter(key => key !== "_id");
        if (val) {
            await tabulate(data, fieldNames)
        }
        else {
            d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
        }
    }
    const tabulate = async (data, columns, y_axis_name) => {
        const header = columns;
        var data_exist;
        if (data !== undefined) {
            data_exist = data;
        } else {
            data_exist = data;
        }
        var tableContainer = document.getElementById(`tableContainer${i}`);
        tableContainer.innerHTML = "";
        var table = d3.select(`#tableContainer${i}`)
            .attr("class", "table-responsive")
            .append("table")
            .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth - 12}px`);
        var thead = table.append("thead");
        var tbody = table.append("tbody");
        d3.select(tableContainer)
            .attr('class', 'table_body')
            .style("overflow-y", "scroll")
            .style("overflow-x", "hidden");
        thead.append("tr")
            .selectAll("th")
            .data(header)
            .enter()
            .append("th")
            .text(function (column) { return column; })
            .attr("style", "text-align: center")
            .style('color', 'black')
        var rows = tbody.selectAll("tr")
            .data(data_exist)
            .enter()
            .append("tr");
        var cells = rows.selectAll("td")
            .data(function (row) {
                return columns.map(function (column) {
                    return { column: column, value: row[column] };
                });
            })
            .enter()
            .append("td")
            .attr("class", function (d) { return "cell " + d.column; })
            .html(function (d) { return d.value; })
            .attr("style", "text-align: center")
            .style('color', 'black')
        return table;
    }

    const handleSortIconClick = (e) => {
        setShowSortoption(!sortShowOptions)
    };
    async function handlePlusIconClick(value) {
        let deltaYExtension;
        if (value) {
            deltaYExtension = 100;
        } else {
            deltaYExtension = -100;
        }
        const wheelEvent = new WheelEvent("wheel", {
            deltaY: deltaYExtension,
            view: window,
            bubbles: true,
            cancelable: true
        });
        handleScroll(wheelEvent)
    }
    const handleScroll = (event) => {
        event.preventDefault()
        const delta = event.deltaY;
        setScrollDelta(prevDelta => prevDelta + delta);
        const newZoomLevel = zoomLevel + (delta > 0 ? -0.1 : 0.1);
        if (newZoomLevel > 0) {
            setZoomLevel(newZoomLevel);
        }
    };
    const handleSort = () => {
        var chart_id = i;
        dispatch(sortFunc({ data, arrValues, chart_id }));
    }
    const handleSortDesc = () => {
        var chart_id = i;
        dispatch(sortDescending({ data, arrValues, chart_id }));
    }
    const handleSortDefault = () => {
        dispatch(sortVerticalline({ data: chart_data, chart_id: i }));
        setSortData([...chart_data]);
    };

    const handleCheckboxChange = (e, value) => {
        if (e.target.checked) {
            setarrValues((prevData) => [
                ...prevData,
                value
            ]);
        } else {
            setarrValues(arrValues.filter((val) => val !== value));
        }
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((val) => val !== value));
        } else {
            setSelectedValues([...selectedValues, value]);
        }
    };
    return (
        <div>
            <div id={`tooltip${i}`} style={{ position: 'absolute', opacity: 0, background: 'lightgray', padding: '10px', borderRadius: '5px' }} ></div>
            <div id={`my_dataviz${i}`} width={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerWidth}
                onMouseLeave={() => { setShowOptions(false); setShowSortoption(false); }} >
                <svg ref={chartRef} width={(fullScreen_enabled ? temp_containerWidth : containerWidth)} height={(fullScreen_enabled ? temp_containerHeight : containerHeight)} >
                    <g ></g>
                </svg>
            </div>
            <div className="legend" id={`legend${i}`} style={{ position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'centre', marginLeft: containerWidth / 3, marginTop: '-40px', boxShadow: 'none' }}></div>
            {showOptions && (
                <div
                    className="download-options"
                    onMouseLeave={() => setShowOptions(false)}
                    style={{
                        position: 'absolute',
                        top: '46px',
                        right: '70px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        cursor: 'pointer',
                        justifyContent: 'center'
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
                >
                    <p onClick={() => dispatch(handleDownloadBar('0', datakeys_name, datakeys, data))}>Download as CSV</p>
                    <p onClick={() => dispatch(imgDownloadSvg(`my_dataviz${i}`))}>Download as SVG</p>
                    <p onClick={() => dispatch(imgDownloadPng(i))} className='mt-1'>Download as PNG</p>

                </div>
            )}
            <span onMouseOver={() => { handleSortIconClick(); setShowOptions(false); }} >
                <i
                    className="bx bx-sort"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        width: '35px',
                        height: '10 px',
                        position: 'absolute',
                        top: '9px',
                        right: '100px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                ></i>
            </span>
            <i className="bx bx-reset"
                style={{
                    cursor: 'pointer',
                    fontSize: '20px',
                    width: '35px',
                    position: 'absolute',
                    top: '9px',
                    right: '319px',
                    zIndex: '1',
                    color: '#6666B2',
                }}

                id={`togglereset-${i}`}
            ></i>
            <span >
                <i className="bx bx-zoom-in"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        width: '35px',
                        position: 'absolute',
                        top: '9px',
                        right: '264px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                    onClick={() => handlePlusIconClick(true)}
                    id='plus-icon'
                ></i>
            </span>
            <span >
                <i
                    className="bx bx-zoom-out"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        width: '35px',
                        position: 'absolute',
                        top: '9px',
                        right: '288px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                    id='minus-icon'
                    onClick={() => handlePlusIconClick(false)}
                ></i>
            </span>
            <span onMouseOver={() => { handleMenuClick(); setShowSortoption(false); }} >
                <i
                    className="bx bx-download"
                    style={{
                        cursor: 'pointer',
                        fontSize: '25px',
                        width: '45px',
                        position: 'absolute',
                        top: '7px',
                        right: '60px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                ></i>
            </span>
            {sortShowOptions && (
                <div
                    className="download-options"
                    style={{
                        position: 'absolute',
                        top: '45px',
                        right: '116px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        boxShadow: 'none',
                        maxHeight: (`${containerHeight - 120}px`),
                        overflow: 'auto',
                        zIndex: '5',
                    }}
                    onMouseOver={(e) => e.target.style.color = 'green'} onMouseOut={(e) => e.target.style.color = 'blue'}
                    onMouseLeave={() => setShowSortoption(false)}
                >
                    {selectedValues.length > 0 && (
                        <div style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button className='btn btn-sm btn-primary me-2' onClick={handleSort}>
                                    Ascending
                                </button>
                                <button className='btn btn-sm btn-primary me-2' onClick={handleSortDesc}>
                                    Descending
                                </button>
                                <button className='btn btn-sm btn-primary me-2' onClick={handleSortDefault}>
                                    Default
                                </button>
                            </div>
                        </div>
                    )}
                    <div>
                        {datakeys.map((value) => (
                            <div key={value} style={{ marginBottom: '10px', boxShadow: 'none' }}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={value}
                                        checked={selectedValues.includes(value)}
                                        onChange={(e) => handleCheckboxChange(e, value)}
                                    />
                                    {value.toUpperCase()}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {isLoading &&
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>}
            {enable_table ? (
                <>
                    <div style={{
                        bottom: 0,
                        left: 0,
                        backgroundColor: '#fff',
                        height: (fullScreen_enabled ? '240px' : '200px')

                    }} id={`tableContainer${i}`}>
                    </div>
                </>
            ) : null}
        </div>
    );
};
export default VerticalLineChart;