import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"

import moment from 'moment'
import SweetAlert from "react-bootstrap-sweetalert"

// import "flatpickr/dist/themes/material_blue.css"
// import Flatpickr from "react-flatpickr"

const _ = require('lodash')

// var urlSocket = require("../../../helpers/urlSocket")
import urlSocket from "../../../../helpers/urlSocket";


export default class EditEndPoints extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: true,
            target_date: moment(this.props.epdata.audit_pbd_ends_on,).format('YYYY-MM-DD'),
            dataLoaded: false,
            dataExtended : false,
            invalidDate: false

        }
    }

    componentDidMount = async () => {
        this.getadtRevUsers()
        
        var db_info = JSON.parse(sessionStorage.getItem('db_info'))
        var publishedAuditData = JSON.parse(sessionStorage.getItem('publishedAuditData'))
        this.setState({db_info,publishedAuditData},()=>{this.getAllUsers()})
    }

    async getAllUsers() {
        try {
            urlSocket.post("webphlbconf/getallusers", {
                userInfo: {
                    encrypted_db_url:this.state.db_info.encrypted_db_url,
                    created_by: this.props.user_data._id,
                    company_id: this.props.user_data.company_id,
                },
                publishtemplateInfo: this.props.audit_data
            })
                .then((response) => {
                    this.setState({
                        userdata: response.data.data,
                        dataloaded: true
                    })
                })
        } catch (error) {
            console.log("catch error", error)
        }
    }

    changeAuditUserHandler = (event) => {

        var checkUserAlreadyExist = _.findIndex(this.props.epdata.audit_pbd_users, { user_id: event.target.value })
        if (checkUserAlreadyExist !== -1) {
            this.setState({ dataAlert: true, alertMessage: "This user is already selected to review. Try different user." })
        }
        else {
            var getIndex = _.findIndex(this.props.epdata.audit_pbd_users, { audit_type: "1" })
            if (getIndex !== -1) {
                this.props.epdata.audit_pbd_users.splice(getIndex, 1)
                var getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value })
                var getUser = this.state.userdata[getNewUserIndex]
                this.props.epdata.audit_pbd_users.push({
                    audit_type: "1",
                    designation: getUser.designation,
                    name: getUser.firstname,
                    user_code: getUser.user_code,
                    user_id: getUser._id,
                })
                this.getadtRevUsers()
            }
        }

    }

    handle_click=(e)=>{
        // const { name, checked } = e.target;
        const updatedData = this.props.epdata[e.target.name] = e.target.checked
        // const updatedData = { ...this.props.epdata, [name]: checked };
        console.log(updatedData,'updatedData')
        this.props.updateEPData(updatedData)


    }

    changeReviewUserHandler = (event) => {

        var checkUserAlreadyExist = _.findIndex(this.props.epdata.audit_pbd_users, { user_id: event.target.value })
        if (checkUserAlreadyExist !== -1) {
            this.setState({ dataAlert: true, alertMessage: "This user is already selected to audit. Try different user." })
        }
        else {
            var getIndex = _.findIndex(this.props.epdata.audit_pbd_users, { audit_type: "2" })
            if (getIndex !== -1) {
                this.props.epdata.audit_pbd_users.splice(getIndex, 1)
                var getNewUserIndex = _.findIndex(this.state.userdata, { _id: event.target.value })
                var getUser = this.state.userdata[getNewUserIndex]
                this.props.epdata.audit_pbd_users.push({
                    audit_type: "2",
                    designation: getUser.designation,
                    name: getUser.firstname,
                    user_code: getUser.user_code,
                    user_id: getUser._id,
                })
                this.getadtRevUsers()
            }
        }

    }

    getadtRevUsers() {
        var auditUser
        var getIndex = _.findIndex(this.props.epdata.audit_pbd_users, { audit_type: "1" })
        if (getIndex !== -1) {
            auditUser = this.props.epdata.audit_pbd_users[getIndex]
        }
        else {
            auditUser = undefined
        }

        var reviewUser
        var getIndex = _.findIndex(this.props.epdata.audit_pbd_users, { audit_type: "2" })
        if (getIndex !== -1) {
            reviewUser = this.props.epdata.audit_pbd_users[getIndex]
        }
        else {
            reviewUser = undefined
        }
        this.setState({
            auditUser,
            reviewUser
        })
    }

    updateEndpoint() {
        // this.props.epdata["audit_pbd_ends_on"] = this.state.target_date
        if (this.state.dataExtended == true) {
            this.props.epdata["dataExtended"] = this.state.dataExtended
            this.props.epdata["audit_pbd_ends_on"] = this.state.target_date
        }
        else {
            this.props.epdata["audit_end_date"] = this.props.epdata.audit_pbd_ends_on
        }
        console.log(this.props.epdata, 'this.props.epdata', this.state.dataExtended, this.state.target_date)
        if (this.state.target_date === "Invalid date" || String(this.state.target_date).length ===0) {
            this.setState({invalidDate : true})
        }
        else {
            try {

                urlSocket.post("webpbdadtdta/updateauditendpoints", {
                    encrypted_db_url: this.state.db_info.encrypted_db_url,
                    userInfo: this.props.user_data,
                    endpointInfo: this.props.epdata,
                    auditInfo: this.props.audit_data
                })
                    .then(response => {
                        if (response.data.response_code === 500) {
                            this.props.onClose()
                        }
                    })

            } catch (error) {
                console.log("catch error", error)
            }
        }
    }


    extend_location_data =(event)=>{
        this.setState({ target_date: event.target.value ,dataExtended : true,invalidDate : false})
    }


    render() {
        if (this.state.dataloaded) {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            const tomorrowFormatted = tomorrow.toISOString().split("T")[0];


            return (
                <div>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={12}>
                                <AvForm className="form-horizontal"
                                //  onValidSubmit={this.handleValidUserSubmit} 
                                // onInvalidSubmit={() => this.checkUsersSelected()}
                                >
                                    <div className="p-3">
                                        <Row className="my-3 pb-3" style={{borderBottom:"1px solid #dedede"}}>
                                            <div className="d-sm-flex align-items-center justify-content-between">
                                                <div className=" font-size-14">Edit End points</div>
                                                <Button className="btn" color="dark" outline onClick={() => this.props.onClose()}> Close </Button>
                                                {/* <button className="btn btn-dark" onClick={() => this.props.onClose()}> Close </button> */}
                                            </div>
                                        </Row>

                                        <Row className="mb-3">
                                            <div className="text-primary">{this.props.epdata.name}  {this.props.epdata.code !== "" && this.props.epdata.code !== null ? "- " + this.props.epdata.code : null}</div>
                                        </Row>

                                        <Row className="">

                                            <div className="mb-3">
                                                <AvField
                                                    name="target_date"
                                                    type="date"
                                                    label="Audit end date"
                                                    errorMessage="Please provide a valid date."
                                                    className="form-control"
                                                    value={this.state.target_date}
                                                    // onChange={(e) => { this.setState({ target_date: e.target.value }) }}
                                                    onChange={(e) => { this.extend_location_data(e) }}
                                                    onKeyDown={(e)=>{e.preventDefault();}}
                                                    min={tomorrowFormatted}
                                                    validate={{ required: { value: true } }}
                                                    id="td"
                                                />
                                                 {/* {
                                                this.state.invalidDate &&
                                                <div className="text-danger" style={{ fontSize: "smaller" }}>Please Enter a Valid date</div>
                                            } */}

                                            </div>
                                           
                                            <div className="mb-3">
                                                <label>Audit assigned to</label>
                                                <select
                                                    type="select"
                                                    name="adtuser"
                                                    label="Name"
                                                    value={this.state.auditUser != undefined ? this.state.auditUser.user_id : 'choose'}
                                                    className="form-select"
                                                    id={"adtuser"}
                                                    onChange={(e) => this.changeAuditUserHandler(e)}>
                                                    <option value="choose" disabled >Choose...</option>
                                                    {/* <option value="add" >Add New User</option> */}
                                                    {
                                                        this.state.userdata.map((data, idx) => {
                                                            return (
                                                                <option value={data._id} selected key={idx}>{data.firstname} ({data.usercode}) - {data.designation}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            {
                                                this.props.audit_data.enable_review ?
                                                    <div className="mb-3">
                                                        <label>Review assigned to</label>
                                                        <select
                                                            type="select"
                                                            name="rvwuser"
                                                            label="Reviewer"
                                                            value={this.state.reviewUser != undefined ? this.state.reviewUser.user_id : 'choose'}
                                                            className="form-select"
                                                            id={"rvwUser"}
                                                            onChange={(e) => this.changeReviewUserHandler(e)}>
                                                            <option value="choose" disabled >Choose...</option>
                                                            {/* <option value="add" >Add New User</option> */}
                                                            {
                                                                this.state.userdata.map((data, idx) => {
                                                                    return (
                                                                        <option value={data._id} selected key={idx}>{data.firstname} ({data.usercode}) - {data.designation}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div> : null
                                            }

                                        </Row>
                                        {/* <br/> */}

                                        <div className="mb-3">
                                                        <label>Display </label>
                                                        {/* <hr className="my-4"/> */}
                                        <div  to="#"
                                                className="list-group-item text-muted d-flex">
                                                  {/* <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Display &nbsp;&nbsp; */}
                                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                                  <div>Audit Score:</div>&nbsp;&nbsp;
                                                  <input checked={this.props.epdata.audit_score_preview} type={"checkbox"} name='audit_score_preview' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;
                                                  </div>
                                                  
                                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                                  <div>Review Score:</div>&nbsp;&nbsp;
                                                  <input checked={this.props.epdata.review_score_preview} type={"checkbox"} name="review_score_preview" onChange={(e)=>this.handle_click(e)}/>
                                                  </div>

                                              </div>
                                              {/* <div className="mb-3">
                                              <label>Capture Location </label>
                                              <div to="#" className="list-group-item text-muted d-flex">


                                                
                                              </div>
                                              </div> */}
                                              <br/>
                                              <label>Track Location</label>
                                              <div  to="#"
                                                className="list-group-item text-muted d-flex">
                                                  {/* <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                 Track Location &nbsp;&nbsp; */}
                                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                                  <div>Auditor :</div>&nbsp;&nbsp;
                                                  <input type={"checkbox"} checked={this.props.epdata.audit_coords_enable ? true : false} name='audit_coords_enable' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp;
                                                  </div>
                                                  {
                                                    this.state.publishedAuditData.enable_review=== true &&
                                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                                  <div>Reviewer :</div>&nbsp;&nbsp;
                                                  <input type={"checkbox"} checked={this.props.epdata.review_coords_enable ? true : false} name="review_coords_enable" onChange={(e)=>this.handle_click(e)}/>
                                                  </div>
                                                  }

                                              </div>
                                              {/* <div  to="#"
                                                className="list-group-item text-muted d-flex"> */}
                                                
                                                  {/* <input checked={this.props.epdata.audit_coords_enable ? true : false} type={"checkbox"} name='audit_coords_enable' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp; */}

                                                  {/* <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                  Display &nbsp;&nbsp; */}
                                                  {/* <div style={{display: 'flex', alignItems: 'center'}}> */}
                                                  {/* <div>Audit Score:</div>&nbsp;&nbsp; */}
                                                  {/* <input checked={this.props.epdata.audit_score_preview} type={"checkbox"} name='audit_score_preview' onChange={(e)=>this.handle_click(e)}/>&nbsp;&nbsp; */}
                                                  {/* </div> */}
                                                  
                                                  {/* <div style={{display: 'flex', alignItems: 'center'}}>
                                                  <div>Review Score:</div>&nbsp;&nbsp;
                                                  <input checked={this.props.epdata.review_score_preview} type={"checkbox"} name="review_score_preview" onChange={(e)=>this.handle_click(e)}/>
                                                  </div> */}

                                              {/* </div> */}




                                        <Row>
                                            <hr className="my-4" />
                                            <div className="d-flex flex-row flex-end gap-3">
                                                <button className="btn btn-danger" onClick={() => this.props.onClose()}> Cancel </button>
                                                <button className="btn btn-primary" onClick={()=>{this.updateEndpoint()}}>Update</button>
                                            </div>
                                        </Row>
                                    </div>
                                    </div>


                                </AvForm>
                            </Col>
                        </Row>
                        {this.state.dataAlert ? (
                            <SweetAlert
                                title={this.state.alertMessage}
                                onConfirm={() => this.setState({ dataAlert: false })}
                            ></SweetAlert>
                        ) : null}
                    </Container>
                </div>
            )
        }
        else {
            return null
        }
    }


}