
import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { handleDownloadBar, imgDownloadSvg, imgDownloadPng } from '../../../../store/reportd3/reportslice';
import { capitalize } from 'lodash';

const PieChart = (props) => {
    var containerWidth = props.containerWidth
    var containerHeight = props.containerHeight
    var chart_data = props.chart_data
    var i = props.id
    var mouseovered = props.mouseovered
    var enable_table = props.show_table
    var svgHeight = props.chart_height
    var show_bar_values = props.show_bar_values
    var temp_containerWidth = props.temp_containerWidth
    var temp_containerHeight = props.temp_containerHeight
    var fullScreen_enabled = props.show_Full_Screen_toggle

    const [showOptions, setShowOptions] = useState(false)
    const [dropShowOptions, setDropShowOptions] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(chart_data);
    const [pieColor, setpieColor] = useState(d3.schemeCategory10)
    const [mouseoverEnabled, setMouseoverEnabled] = useState(mouseovered)
    const [enabledTable, setEnabledTable] = useState(enable_table)
    const [sortShowOptions, setSortShowOptions] = useState(false);
    const [chart_height, setchart_height] = useState(svgHeight);
    const [cornerRadius, setCornerRadius] = useState(0);
    const [innerRadius, setInnerRadius] = useState(0);
    const [padAngle, setPadAngle] = useState(0);
    const [chartSize, setChartSize] = useState(props.containerHeight);

    

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showvalues, setshowvalues] = useState(show_bar_values);
    var width
    var height
    useEffect(() => {
        if (chart_data !== undefined) {
            setData(chart_data)
            setpieColor(d3.schemeCategory10)
            setMouseoverEnabled(mouseovered)
            setEnabledTable(enable_table)
            setshowvalues(show_bar_values)
            setchart_height(svgHeight)
            if (enable_table) {
                showTableFn(true)
            }
            else {
                showTableFn(false)
            }
        }
    }, [chart_data, pieColor, mouseovered, enable_table, show_bar_values, temp_containerWidth, fullScreen_enabled, temp_containerHeight, svgHeight])
    if (props.chart_data.length !== 0) {
        var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
        var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
    }
    const margin = { top: 35, right: 30, bottom: 10, left: 40 };
    useEffect(() => {
        if (svgHeight !== undefined && svgHeight !== '') {
            containerHeight = containerHeight - 200
        }
        else {
            containerHeight = containerHeight
        }
        if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
            width = temp_containerWidth - margin.left - margin.right;
            height = temp_containerHeight - margin.top - margin.bottom - (enabledTable ? 200 : 0)
            containerWidth = width
            containerHeight = height
        }
        else {
            width = containerWidth - margin.left - margin.right;
            height = containerHeight - margin.top - margin.bottom;
        }
        const radius = Math.min(chartSize, chartSize) / 2.5;
        const svg = d3.select(`#my_dataviz${i}`)
            .attr('width', containerWidth)
            .attr('height', containerHeight);

        const g = svg.select('g');
        g.selectAll('*').remove();
        g.attr('transform', `translate(${(fullScreen_enabled ? temp_containerWidth : containerWidth) / 2}, ${containerHeight / 2})`);

        var color = d3.scaleOrdinal()
            .range(pieColor);

        const pie = d3.pie()
            .value(d => d.count);

        const arcs = pie(data);
        const arc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius);

        const arcLabel = d3.arc()
            .innerRadius(radius * 0.7)
            .outerRadius(radius * 0.7);

        g.selectAll('path')
            .data(arcs)
            .enter()
            .append('path')
            .attr('fill', (d, i) => color(i))
            .transition()
            .duration(1000)
            .attrTween('d', function (d) {
                const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
                return function (t) {
                    return arc(interpolate(t));
                }
            })
            .on('end', function () {
                d3.select(this)
                    .on('mouseover', handleMouseOverAnimate)
                    .on('mousemove', handleMouseMove)
                    .on('mouseout', handleMouseOutAnimate)
                    .on('mouseleave', handleMouseLeaveAnimate);
            })



        // g.selectAll('text')
        //     .data(arcs)
        //     .enter()
        //     .append('text')
        //     .attr('transform', d => {
        //         const pos = arcLabel.centroid(d);
        //         pos[0] = radius * 1.3 * (midAngle(d) < Math.PI ? 1 : -1);
        //         return `translate(${pos})`;
        //     })
        //     .attr('dy', '0.35em')
        //     .style('text-anchor', d => midAngle(d) < Math.PI ? 'start' : 'end')
        //     .style("text-transform", 'capitalize')
        //     .text(d => showvalues ? d.data.name : '')
        //     .style("opacity", 0)
        //     .transition()
        //     .duration(1000)
        //     .style("opacity", 1)
        //     // .style("text-transform", capitalize)
        //     .attrTween('transform', function (d) {
        //         const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
        //         return function (t) {
        //             const pos = arcLabel.centroid(interpolate(t));
        //             pos[0] = radius * 1.1 * (midAngle(interpolate(t)) < Math.PI ? 1 : -1);
        //             return `translate(${pos})`;
        //         };
        //     });

        // showvalues && g.selectAll('polyline')
        //     .data(arcs)
        //     .enter()
        //     .append('polyline')
        //     .attr('points', d => {
        //         const posA = arc.centroid(d);
        //         const posB = arcLabel.centroid(d);
        //         const posC = arcLabel.centroid(d);
        //         posC[0] = radius * 1 * (midAngle(d) < Math.PI ? 1 : -1);
        //         return [posA, posB, posC];
        //     })
        //     .style('fill', 'none')
        //     .style('stroke', 'lightgrey')
        //     .style('stroke-width', '1.5px')
        // .style('stroke-dasharray', '4, 4'); 

        function midAngle(d) {
            return d.startAngle + (d.endAngle - d.startAngle) / 2;
        }


        function handleMouseOverAnimate(event, d) {
            if (mouseoverEnabled) {
                d3.select(this)
                    .style('filter', 'url(#glow)')
                    .transition()
                    .duration(200)
                    .attr('transform', `translate(${0.1 * radius * Math.cos(event.startAngle + (event.endAngle - event.startAngle) / 2)}, ${0.1 * radius * Math.sin(event.startAngle + (event.endAngle - event.startAngle) / 2)})`)
                    .style('opacity', 1)

                d3.select(this)
                    .transition()
                    .duration(100)
                    .attr('transform', `scale(1.1)`)
                const tooltip = d3.selectAll(`#tooltip${i}`);
                tooltip.transition().duration(100).style('opacity', 0.9);
                const svgContainer = d3.selectAll(`#my_dataviz${i}`).selectAll('g');
                const [x1, y1] = d3.pointer(event, svgContainer.node());
                const xPosition = x1 + (fullScreen_enabled ? temp_containerWidth : containerWidth) / 2 + 30;
                const yPosition = y1 + containerHeight / 2
                tooltip.html(`<strong>${d.data.name}</strong>: ${d.data.count}`)
                    .style('transform', `translate(${xPosition}px, ${yPosition}px)`)
                    .style("color", "red")
                    .style("background-color", "white")
            }
            else {
            }
        }

        function handleMouseMove(event) {
            const tooltip = d3.selectAll(`#tooltip${i}`);
            const svgContainer = d3.selectAll(`#my_dataviz${i}`).selectAll('g');
            const [x1, y1] = d3.pointer(event, svgContainer.node());
            const xPosition = x1 + (fullScreen_enabled ? temp_containerWidth : containerWidth) / 2 + 30;
            const yPosition = y1 + containerHeight / 2;
            tooltip.style('transform', `translate(${xPosition}px, ${yPosition}px)`);
        }

        async function handleMouseOutAnimate(event, d) {
            d3.select(this).style('opacity', 1);
            d3.selectAll(`#tooltip${i}`).style('opacity', 0);
            d3.select(this)
                .style('filter', null)
                .transition()
                .duration(200)
                .attr('transform', 'translate(0,0)')
        }
        const handleMouseLeaveAnimate = () => {
            const tooltip = d3.select(`#tooltip${i}`);
            tooltip.transition().duration(100).style('opacity', 0);
            d3.selectAll(`#tooltip${i}`).style('opacity', 0);
        }

        // const legend = g.selectAll('.legend')
        //     .data(data)
        //     .enter().append('g')
        //     .attr('class', 'legend')
        //     .attr('transform', (d, i) => {
        //         if (containerWidth < 700) {
        //             const legendWidth = 100;
        //             const legendSpacing = 10;
        //             const totalWidth = data.length * (legendWidth + legendSpacing);
        //             const xOffset = (containerWidth - totalWidth) / 2;
        //             return `translate(${xOffset + i * (legendWidth + legendSpacing)}, ${containerHeight - 50})`;
        //         } else {
        //             return `translate(-${containerWidth / 2 - margin.left - 20}, ${i * 20 - 100})`;
        //         }
        //     });
        // legend.append('rect')
        //     .attr('width', 12)
        //     .attr('height', 12)
        //     .style('fill', (d, i) => color(i));
        // legend.append('text')
        //     .attr('x', 24)
        //     .attr('y', 9)
        //     .attr('dy', '.35em')
        //     .style('text-anchor', 'start')
        //     .text(d => d.name);
        if (enable_table) {
            showTableFn(true)
        }
        else {
            showTableFn(false)
        }
        const updateChart = () => {
            const arc = d3.arc()
                .innerRadius(innerRadius)
                .outerRadius(Math.min(chartSize, chartSize) / 2.5)
                .cornerRadius(cornerRadius);



            const arcLabel = d3.arc()
                .innerRadius(radius * 0.7)
                .outerRadius(Math.min(chartSize, chartSize) / 2.5);



            const pie = d3.pie()
                .value(d => d.count)
                .padAngle(padAngle);
            const arcs = pie(data);

            g.selectAll('polyline , text,.legend ').remove()



            g.selectAll('text')
                .data(arcs)
                .enter()
                .append('text')
                .attr('transform', d => {
                    const pos = arcLabel.centroid(d);
                    pos[0] = radius * 1.3 * (midAngle(d) < Math.PI ? 1 : -1);
                    return `translate(${pos})`;
                })
                .attr('dy', '0.35em')
                .style('text-anchor', d => midAngle(d) < Math.PI ? 'start' : 'end')
                .style("text-transform", 'capitalize')
                .text(d => showvalues ? d.data.name : '')
                .style("opacity", 0)
                .transition()
                .duration(1000)
                .style("opacity", 1)
                // .style("text-transform", capitalize)
                .attrTween('transform', function (d) {
                    const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
                    return function (t) {
                        const pos = arcLabel.centroid(interpolate(t));
                        pos[0] = radius * 1.1 * (midAngle(interpolate(t)) < Math.PI ? 1 : -1);
                        return `translate(${pos})`;
                    };
                });



            showvalues && g.selectAll('polyline')
                .data(arcs)
                .enter()
                .append('polyline')
                .attr('points', d => {
                    const posA = arc.centroid(d);
                    const posB = arcLabel.centroid(d);
                    const posC = arcLabel.centroid(d);
                    posC[0] = radius * 1 * (midAngle(d) < Math.PI ? 1 : -1);
                    return [posA, posB, posC];
                })
                .style('fill', 'none')
                .style('stroke', 'lightgrey')
                .style('stroke-width', '1.5px')

            function midAngle(d) {
                return d.startAngle + (d.endAngle - d.startAngle) / 2;
            }

            svg.selectAll('path')
                .data(arcs)
                .transition()
                .duration(1000)
                .attrTween('d', function (d) {
                    const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
                    return function (t) {
                        return arc(interpolate(t));
                    }
                })
                .on('end', function () {
                    d3.select(this)
                        .on('mouseover', handleMouseOverAnimate)
                        .on('mousemove', handleMouseMove)
                        .on('mouseout', handleMouseOutAnimate)
                        .on('mouseleave', handleMouseLeaveAnimate);
                });



            const legend = g.selectAll('.legend')
                .data(data)
                .enter().append('g')
                .attr('class', 'legend')
                .attr('transform', (d, i) => {
                    if (containerWidth < 700) {
                        const legendWidth = 100;
                        const legendSpacing = 10;
                        const totalWidth = data.length * (legendWidth + legendSpacing);
                        const xOffset = (containerWidth - totalWidth) / 2;
                        return `translate(${xOffset + i * (legendWidth + legendSpacing)}, ${chartSize - 50})`;
                    } else {
                        return `translate(-${containerWidth / 2 - margin.left - 20}, ${i * 20 - 100})`;
                    }
                });
            legend.append('rect')
                .attr('width', 12)
                .attr('height', 12)
                .style('fill', (d, i) => color(i));
            legend.append('text')
                .attr('x', 24)
                .attr('y', 9)
                .attr('dy', '.35em')
                .style('text-anchor', 'start')
                .text(d => d.name);

        };
        updateChart();
    }, [containerWidth, containerHeight, data, pieColor, mouseoverEnabled, enabledTable, showvalues, temp_containerWidth, fullScreen_enabled, temp_containerHeight, enable_table, svgHeight, cornerRadius, innerRadius, padAngle, chartSize]);
    const handleMenuClick = (e) => {
        setShowOptions(!showOptions);
    };

    const handleDropMenuClick = (e) => {
        setDropShowOptions(!dropShowOptions);
    };

    const showTableFn = async (val) => {
        const fieldNames = Object.keys(data[0]);
        if (val) {
            await tabulate(data, fieldNames)
        }
        else {
            d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
        }
    }
    const handleRadiusChange = (event) => {
        const { name, value } = event.target;
        if (name === 'cornerRadius') {
            setCornerRadius(Number(value));
        } else if (name === 'innerRadius') {
            setInnerRadius(Number(value));
        } else if (name === 'padAngle') {
            setPadAngle(Number(value));
        } else if (name === 'ChartSize') {
            setChartSize(Number(value));
        }
    };
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const tabulate = async (data, columns, y_axis_name) => {
        const header = columns;
        var data_exist;
        if (data !== undefined) {
            data_exist = data;
        } else {
            data_exist = data;
        }
        var tableContainer = document.getElementById(`tableContainer${i}`);
        if (tableContainer !== null) {
            tableContainer.innerHTML = "";

        }
        var table = d3.select(`#tableContainer${i}`)
            .attr("class", "table-responsive")
            .append("table")
            .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth - 8}px`);
        var thead = table.append("thead");
        var tbody = table.append("tbody");
        d3.select(tableContainer)
            .attr('class', 'table_body')
            .style("overflow-y", "scroll")
            .style("overflow-x", "hidden");
        thead.append("tr")
            .selectAll("th")
            .data(header)
            .enter()
            .append("th")
            .text(function (column) { return column.toUpperCase(); })
            .attr("style", "text-align: center")
            .style('color', 'black')
        var rows = tbody.selectAll("tr")
            .data(data_exist)
            .enter()
            .append("tr");
        var cells = rows.selectAll("td")
            .data(function (row) {
                return columns.map(function (column) {
                    return { column: column, value: row[column] };
                });
            })
            .enter()
            .append("td")
            .attr("class", function (d) { return "cell " + d.column; })
            .html(function (d) {
                return d.column === "percentage" ? d.value.toFixed(2) + "%" : d.value;
            })
            .attr("style", "text-align: center")
            .style('color', 'black')
        return table;
    }
    return (
        <div>
            <div id={`tooltip${i}`} style={{ position: 'absolute', opacity: 0, background: 'lightgray', padding: '10px', borderRadius: '5px' }}></div>
            <div id={`my_dataviz${i}`} width={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerWidth} onMouseLeave={() => { setShowOptions(false); setSortShowOptions(false); }} >
                <svg width={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerWidth} height={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerHeight} >
                    <g></g>
                </svg>
            </div>

            {showOptions && (
                <div
                    className="download-options"
                    onMouseLeave={() => setShowOptions(false)}
                    style={{
                        position: 'absolute',
                        top: '46px',
                        right: '80px',
                        height: '120px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        cursor: 'pointer',
                        justifyContent: 'center'
                    }}
                    onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
                >
                    <p onClick={() => handleDownloadBar('0', datakeys_name, datakeys, data)}>Download as CSV</p>
                    <p onClick={() => imgDownloadSvg(`my_dataviz${i}`)}>Download as SVG</p>
                    <p onClick={() => imgDownloadPng(i)} className='mt-1'>Download as PNG</p>
                </div>
            )}
            <span onMouseOver={() => { handleMenuClick(); setSortShowOptions(false); }} onMouseUp={() => { setShowOptions(false); setSortShowOptions(false); }} >
                <i
                    className="bx bx-download"
                    style={{
                        cursor: 'pointer',
                        fontSize: '25px',
                        width: '45px',
                        position: 'absolute',
                        top: '7px',
                        right: '55px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                ></i>
            </span>
            <span onMouseOver={() => { handleDropMenuClick(); }} onMouseUp={() => { setDropShowOptions(false) }} >
                <i
                    className="bx bx-caret-down"
                    style={{
                        cursor: 'pointer',
                        fontSize: '30px',
                        width: '45px',
                        position: 'absolute',
                        top: '4px',
                        right: '224px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                ></i>
            </span>
            {dropShowOptions && (
                <div
                    className="download-options"
                    onMouseLeave={() => setDropShowOptions(false)}
                    style={{
                        position: 'absolute',
                        top: '46px',
                        right: '55px',
                        height: '150px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        cursor: 'pointer',
                        justifyContent: 'center'
                    }}
                    onMouseOver={(e) => { e.target.style.color = 'green'; setDropShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
                >
                    <label className="block px-2 pt-2 text-sm text-gray-100">
                        Corner Radius
                        <input type="range" name="cornerRadius" min="0" max="40" step="1" value={cornerRadius} onChange={handleRadiusChange} className="mt-1 block w-full" />
                    </label>
                    <label className="block px-2 text-sm text-gray-100">
                        Inner Radius
                        {/* <input type="range" name="innerRadius" min="0" max={fullScreen_enabled ? '500' : '100'} step={fullScreen_enabled ? '5' : '1'} value={innerRadius} onChange={handleRadiusChange} className="mt-1 block w-full" /> */}
                        <input type="range" name="innerRadius" min="0" max={'100'} step={'1'} value={innerRadius} onChange={handleRadiusChange} className="mt-1 block w-full" />

                    </label>
                    <label className="block px-2 text-sm text-gray-100">
                        Pad Angle
                        <input type="range" name="padAngle" min="0" max="0.5" step="0.01" value={padAngle} onChange={handleRadiusChange} className="mt-1 block w-full" />
                    </label>

                    <label className="block px-2 text-sm text-gray-100" >
                        Pie Size
                        <input type="range" name="ChartSize" id="sizeSlider" min={props.containerHeight / 2} max={props.containerHeight}  value={chartSize} onChange={handleRadiusChange} className="mt-1 block w-full" />
                    </label>
                </div>
            )}
            <span style={{
                cursor: 'pointer',
                fontSize: '25px',
                width: '45px',
                position: 'absolute',
                top: '-4px',
                right: '190px',
                zIndex: '1',
                color: '#6666B2',
            }}>
            </span>
            {isLoading &&
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>}
            {enabledTable ? (
                <>
                    <div style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        backgroundColor: '#fff',
                        height: (fullScreen_enabled ? '240px' : '200px')

                    }} id={`tableContainer${i}`}>
                    </div>
                </>
            ) : null}
        </div>
    );
};
export default PieChart;

// import React, { useEffect, useState } from 'react';
// import * as d3 from 'd3';
// import { handleDownloadBar, imgDownloadSvg, imgDownloadPng } from '../../../../store/reportd3/reportslice';

// const PieChart = (props) => {
//     var containerWidth = props.containerWidth
//     var containerHeight = props.containerHeight
//     var chart_data = props.chart_data
//     var i = props.id
//     var mouseovered = props.mouseovered
//     var enable_table = props.show_table
//     var svgHeight = props.chart_height
//     var show_bar_values = props.show_bar_values
//     var temp_containerWidth = props.temp_containerWidth
//     var temp_containerHeight = props.temp_containerHeight
//     var fullScreen_enabled = props.show_Full_Screen_toggle

//     const [showOptions, setShowOptions] = useState(false)
//     const [dropShowOptions, setDropShowOptions] = useState(false)
//     const [isLoading, setIsLoading] = useState(false);
//     const [data, setData] = useState(chart_data);
//     const [pieColor, setpieColor] = useState(d3.schemeCategory10)
//     const [mouseoverEnabled, setMouseoverEnabled] = useState(mouseovered)
//     const [enabledTable, setEnabledTable] = useState(enable_table)
//     const [sortShowOptions, setSortShowOptions] = useState(false);
//     const [chart_height, setchart_height] = useState(svgHeight);
//     const [cornerRadius, setCornerRadius] = useState(0);
//     const [innerRadius, setInnerRadius] = useState(0);
//     const [padAngle, setPadAngle] = useState(0);
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [showvalues, setshowvalues] = useState(show_bar_values);


//     var width
//     var height

//     useEffect(() => {
//         if (chart_data !== undefined) {
//             setData(chart_data)
//             setpieColor(d3.schemeCategory10)
//             setMouseoverEnabled(mouseovered)
//             setEnabledTable(enable_table)
//             setshowvalues(show_bar_values)
//             setchart_height(svgHeight)
//             if (enable_table) {
//                 showTableFn(true)
//             }
//             else {
//                 showTableFn(false)
//             }
//         }

//     }, [chart_data, pieColor, mouseovered, enable_table, show_bar_values, temp_containerWidth, fullScreen_enabled, temp_containerHeight, svgHeight])

//     if (props.chart_data.length !== 0) {
//         var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
//         var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
//     }
//     const margin = { top: 35, right: 30, bottom: 10, left: 40 };

//     useEffect(() => {
//         if (svgHeight !== undefined && svgHeight !== '') {
//             containerHeight = containerHeight - 200
//         }
//         else {
//             containerHeight = containerHeight
//         }
//         if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
//             width = temp_containerWidth - margin.left - margin.right;
//             height = temp_containerHeight - margin.top - margin.bottom - (enabledTable ? 200 : 0)
//             containerWidth = width
//             containerHeight = height
//         }
//         else {
//             width = containerWidth - margin.left - margin.right;
//             height = containerHeight - margin.top - margin.bottom;
//         }
//         const radius = Math.min(containerWidth, containerHeight) / 2.5;
//         const svg = d3.select(`#my_dataviz${i}`)
//             .attr('width', containerWidth)
//             .attr('height', containerHeight);

//         const g = svg.select('g');
//         g.selectAll('*').remove();
//         g.attr('transform', `translate(${(fullScreen_enabled ? temp_containerWidth : containerWidth) / 2}, ${containerHeight / 2})`);

//         var color = d3.scaleOrdinal()
//             .range(pieColor);

//         const pie = d3.pie()
//             .value(d => d.count);

//         const arcs = pie(data);
//         const arc = d3.arc()
//             .innerRadius(0)
//             .outerRadius(radius);

//         const arcLabel = d3.arc()
//             .innerRadius(radius * 0.7)
//             .outerRadius(radius * 0.7);

//         g.selectAll('path')
//             .data(arcs)
//             .enter()
//             .append('path')
//             .attr('fill', (d, i) => color(i))
//             .transition()
//             .duration(1000)
//             .attrTween('d', function (d) {
//                 const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
//                 return function (t) {
//                     return arc(interpolate(t));
//                 }
//             })
//             .on('end', function () {
//                 d3.select(this)
//                     .on('mouseover', handleMouseOverAnimate)
//                     .on('mousemove', handleMouseMove)
//                     .on('mouseout', handleMouseOutAnimate)
//                     .on('mouseleave', handleMouseLeaveAnimate);
//             })

//         g.selectAll('text')
//             .data(arcs)
//             .enter()
//             .append('text')
//             .attr('transform', d => `translate(${arcLabel.centroid(d)})`)
//             .attr('dy', '0.35em')
//             .text(d => showvalues ? d.data.name : '')
//             .style("opacity", 0)
//             .transition()
//             .duration(1000)
//             .style("opacity", 1)
//             .attrTween('transform', function (d) {
//                 const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
//                 return function (t) {
//                     return `translate(${arcLabel.centroid(interpolate(t))})`;
//                 };
//             });

//         function handleMouseOverAnimate(event, d) {
//             if (mouseoverEnabled) {
//                 d3.select(this)
//                     .style('filter', 'url(#glow)')
//                     .transition()
//                     .duration(200)
//                     .attr('transform', `translate(${0.1 * radius * Math.cos(event.startAngle + (event.endAngle - event.startAngle) / 2)}, ${0.1 * radius * Math.sin(event.startAngle + (event.endAngle - event.startAngle) / 2)})`)
//                     .style('opacity', 1)

//                 d3.select(this)
//                     .transition()
//                     .duration(100)
//                     .attr('transform', `scale(1.1)`)
//                 const tooltip = d3.selectAll(`#tooltip${i}`);
//                 tooltip.transition().duration(100).style('opacity', 0.9);
//                 const svgContainer = d3.selectAll(`#my_dataviz${i}`).selectAll('g');
//                 const [x1, y1] = d3.pointer(event, svgContainer.node());
//                 const xPosition = x1 + (fullScreen_enabled ? temp_containerWidth : containerWidth) / 2 + 30;
//                 const yPosition = y1 + containerHeight / 2
//                 tooltip.html(`<strong>${d.data.name}</strong>: ${d.data.count}`)
//                     .style('transform', `translate(${xPosition}px, ${yPosition}px)`)
//                     .style("color", "red")
//                     .style("background-color", "white")
//             }
//             else {
//             }
//         }

//         function handleMouseMove(event) {
//             const tooltip = d3.selectAll(`#tooltip${i}`);
//             const svgContainer = d3.selectAll(`#my_dataviz${i}`).selectAll('g');
//             const [x1, y1] = d3.pointer(event, svgContainer.node());
//             const xPosition = x1 + (fullScreen_enabled ? temp_containerWidth : containerWidth) / 2 + 30;
//             const yPosition = y1 + containerHeight / 2;
//             tooltip.style('transform', `translate(${xPosition}px, ${yPosition}px)`);
//         }

//         async function handleMouseOutAnimate(event, d) {
//             d3.select(this).style('opacity', 1);
//             d3.selectAll(`#tooltip${i}`).style('opacity', 0);
//             d3.select(this)
//                 .style('filter', null)
//                 .transition()
//                 .duration(200)
//                 .attr('transform', 'translate(0,0)')
//         }
//         const handleMouseLeaveAnimate = () => {
//             const tooltip = d3.select(`#tooltip${i}`);
//             tooltip.transition().duration(100).style('opacity', 0);
//             d3.selectAll(`#tooltip${i}`).style('opacity', 0);
//         }

//         const legend = g.selectAll('.legend')
//             .data(data)
//             .enter().append('g')
//             .attr('class', 'legend')
//             .attr('transform', (d, i) => {
//                 if (containerWidth < 700) {
//                     const legendWidth = 100;
//                     const legendSpacing = 10;
//                     const totalWidth = data.length * (legendWidth + legendSpacing);
//                     const xOffset = (containerWidth - totalWidth) / 2;
//                     return `translate(${xOffset + i * (legendWidth + legendSpacing)}, ${containerHeight - 50})`;
//                 } else {
//                     return `translate(-${containerWidth / 2 - margin.left - 20}, ${i * 20 - 100})`;
//                 }
//             });
//         legend.append('rect')
//             .attr('width', 12)
//             .attr('height', 12)
//             .style('fill', (d, i) => color(i));
//         legend.append('text')
//             .attr('x', 24)
//             .attr('y', 9)
//             .attr('dy', '.35em')
//             .style('text-anchor', 'start')
//             .text(d => d.name);
//         if (enable_table) {
//             showTableFn(true)
//         }
//         else {
//             showTableFn(false)
//         }
//         const updateChart = () => {
//             const arc = d3.arc()
//                 .innerRadius(innerRadius)
//                 .outerRadius(Math.min(containerWidth, containerHeight) / 2.5)
//                 .cornerRadius(cornerRadius);
//             const pie = d3.pie()
//                 .value(d => d.count)
//                 .padAngle(padAngle);
//             const arcs = pie(data);
//             svg.selectAll('path')
//                 .data(arcs)
//                 .transition()
//                 .duration(1000)
//                 .attrTween('d', function (d) {
//                     const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
//                     return function (t) {
//                         return arc(interpolate(t));
//                     }
//                 })
//                 .on('end', function () {
//                     d3.select(this)
//                         .on('mouseover', handleMouseOverAnimate)
//                         .on('mousemove', handleMouseMove)
//                         .on('mouseout', handleMouseOutAnimate)
//                         .on('mouseleave', handleMouseLeaveAnimate);
//                 });
//         };
//         updateChart();
//     }, [containerWidth, containerHeight, data, pieColor, mouseoverEnabled, enabledTable, showvalues, temp_containerWidth, fullScreen_enabled, temp_containerHeight, enable_table, svgHeight, cornerRadius, innerRadius, padAngle,]);
//     const handleMenuClick = (e) => {
//         setShowOptions(!showOptions);
//     };

//     const handleDropMenuClick = (e) => {
//         setDropShowOptions(!dropShowOptions);
//     };

//     const showTableFn = async (val) => {
//         const fieldNames = Object.keys(data[0]);
//         if (val) {
//             await tabulate(data, fieldNames)
//         }
//         else {
//             d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
//         }
//     }
//     const handleRadiusChange = (event) => {
//         const { name, value } = event.target;
//         if (name === 'cornerRadius') {
//             setCornerRadius(Number(value));
//         } else if (name === 'innerRadius') {
//             setInnerRadius(Number(value));
//         } else if (name === 'padAngle') {
//             setPadAngle(Number(value));
//         }
//     };
//     const toggleDropdown = () => {
//         setIsDropdownOpen(!isDropdownOpen);
//     };
//     const tabulate = async (data, columns, y_axis_name) => {
//         const header = columns;
//         var data_exist;
//         if (data !== undefined) {
//             data_exist = data;
//         } else {
//             data_exist = data;
//         }
//         var tableContainer = document.getElementById(`tableContainer${i}`);
//         if (tableContainer !== null) {
//             tableContainer.innerHTML = "";

//         }
//         var table = d3.select(`#tableContainer${i}`)
//             .attr("class", "table-responsive")
//             .append("table")
//             .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth - 8}px`);
//         var thead = table.append("thead");
//         var tbody = table.append("tbody");
//         d3.select(tableContainer)
//             .attr('class', 'table_body')
//             .style("overflow-y", "scroll")
//             .style("overflow-x", "hidden");
//         thead.append("tr")
//             .selectAll("th")
//             .data(header)
//             .enter()
//             .append("th")
//             .text(function (column) { return column.toUpperCase(); })
//             .attr("style", "text-align: center")
//             .style('color', 'black')
//         var rows = tbody.selectAll("tr")
//             .data(data_exist)
//             .enter()
//             .append("tr");
//         var cells = rows.selectAll("td")
//             .data(function (row) {
//                 return columns.map(function (column) {
//                     return { column: column, value: row[column] };
//                 });
//             })
//             .enter()
//             .append("td")
//             .attr("class", function (d) { return "cell " + d.column; })
//             .html(function (d) {
//                 return d.column === "percentage" ? d.value.toFixed(2) + "%" : d.value;
//             })
//             .attr("style", "text-align: center")
//             .style('color', 'black')
//         return table;
//     }
//     return (
//         <div>
//             <div id={`tooltip${i}`} style={{ position: 'absolute', opacity: 0, background: 'lightgray', padding: '10px', borderRadius: '5px' }}></div>
//             <div id={`my_dataviz${i}`} width={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerWidth} onMouseLeave={() => { setShowOptions(false); setSortShowOptions(false); }} >
//                 <svg width={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerWidth} height={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerHeight} >
//                     <g></g>
//                 </svg>
//             </div>

//             {showOptions && (
//                 <div
//                     className="download-options"
//                     onMouseLeave={() => setShowOptions(false)}
//                     style={{
//                         position: 'absolute',
//                         top: '46px',
//                         right: '80px',
//                         height: '120px',
//                         backgroundColor: '#fff',
//                         border: '1px solid #ccc',
//                         borderRadius: '4px',
//                         color: '#000080',
//                         padding: '5px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'flex-end',
//                         cursor: 'pointer',
//                         justifyContent: 'center'
//                     }}
//                     onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
//                 >
//                     <p onClick={() => handleDownloadBar('0', datakeys_name, datakeys, data)}>Download as CSV</p>
//                     <p onClick={() => imgDownloadSvg(`my_dataviz${i}`)}>Download as SVG</p>
//                     <p onClick={() => imgDownloadPng(i)} className='mt-1'>Download as PNG</p>
//                 </div>
//             )}
//             <span onMouseOver={() => { handleMenuClick(); setSortShowOptions(false); }} onMouseUp={() => { setShowOptions(false); setSortShowOptions(false); }} >
//                 <i
//                     className="bx bx-download"
//                     style={{
//                         cursor: 'pointer',
//                         fontSize: '25px',
//                         width: '45px',
//                         position: 'absolute',
//                         top: '7px',
//                         right: '70px',
//                         zIndex: '1',
//                         color: '#6666B2',
//                     }}
//                 ></i>
//             </span>
//             <span onMouseOver={() => { handleDropMenuClick(); }} onMouseUp={() => { setDropShowOptions(false) }} >
//                 <i
//                     className="bx bx-caret-down"
//                     style={{
//                         cursor: 'pointer',
//                         fontSize: '30px',
//                         width: '45px',
//                         position: 'absolute',
//                         top: '4px',
//                         right: '220px',
//                         zIndex: '1',
//                         color: '#6666B2',
//                     }}
//                 ></i>
//             </span>
//             {dropShowOptions && (
//                 <div
//                     className="download-options"
//                     onMouseLeave={() => setDropShowOptions(false)}
//                     style={{
//                         position: 'absolute',
//                         top: '46px',
//                         right: '80px',
//                         height: '120px',
//                         backgroundColor: '#fff',
//                         border: '1px solid #ccc',
//                         borderRadius: '4px',
//                         color: '#000080',
//                         padding: '5px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'flex-end',
//                         cursor: 'pointer',
//                         justifyContent: 'center'
//                     }}
//                     onMouseOver={(e) => { e.target.style.color = 'green'; setDropShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
//                 >
//                     <label className="block px-2 pt-2 text-sm text-gray-100">
//                         Corner Radius
//                         <input type="range" name="cornerRadius" min="0" max="40" step="1" value={cornerRadius} onChange={handleRadiusChange} className="mt-1 block w-full" />
//                     </label>
//                     <label className="block px-2 text-sm text-gray-100">
//                         Inner Radius
//                         <input type="range" name="innerRadius" min="0" max={fullScreen_enabled ? '500' : '100'} step={fullScreen_enabled ? '5' : '1'} value={innerRadius} onChange={handleRadiusChange} className="mt-1 block w-full" />
//                     </label>
//                     <label className="block px-2 text-sm text-gray-100">
//                         Pad Angle
//                         <input type="range" name="padAngle" min="0" max="0.5" step="0.01" value={padAngle} onChange={handleRadiusChange} className="mt-1 block w-full" />
//                     </label>
//                 </div>
//             )}
//             <span style={{
//                 cursor: 'pointer',
//                 fontSize: '25px',
//                 width: '45px',
//                 position: 'absolute',
//                 top: '-4px',
//                 right: '220px',
//                 zIndex: '1',
//                 color: '#6666B2',
//             }}>
//             </span>
//             {isLoading &&
//                 <div className="loader-overlay">
//                     <div className="loader"></div>
//                 </div>}
//             {enabledTable ? (
//                 <>
//                     <div style={{
//                         position: 'absolute',
//                         bottom: 0,
//                         left: 0,
//                         backgroundColor: '#fff',
//                         height: (fullScreen_enabled ? '240px' : '200px')

//                     }} id={`tableContainer${i}`}>
//                     </div>
//                 </>
//             ) : null}
//         </div>
//     );
// };
// export default PieChart;







// import React, { useEffect, useState } from 'react';
// import * as d3 from 'd3';
// import { handleDownloadBar, imgDownloadSvg, imgDownloadPng } from '../../../../store/reportd3/reportslice';
// import { capitalize } from 'lodash';

// const PieChart = (props) => {
//     var containerWidth = props.containerWidth
//     var containerHeight = props.containerHeight
//     var chart_data = props.chart_data
//     var i = props.id
//     var mouseovered = props.mouseovered
//     var enable_table = props.show_table
//     var svgHeight = props.chart_height
//     var show_bar_values = props.show_bar_values
//     var temp_containerWidth = props.temp_containerWidth
//     var temp_containerHeight = props.temp_containerHeight
//     var fullScreen_enabled = props.show_Full_Screen_toggle

//     const [showOptions, setShowOptions] = useState(false)
//     const [dropShowOptions, setDropShowOptions] = useState(false)
//     const [isLoading, setIsLoading] = useState(false);
//     const [data, setData] = useState(chart_data);
//     const [pieColor, setpieColor] = useState(d3.schemeCategory10)
//     const [mouseoverEnabled, setMouseoverEnabled] = useState(mouseovered)
//     const [enabledTable, setEnabledTable] = useState(enable_table)
//     const [sortShowOptions, setSortShowOptions] = useState(false);
//     const [chart_height, setchart_height] = useState(svgHeight);
//     const [cornerRadius, setCornerRadius] = useState(0);
//     const [innerRadius, setInnerRadius] = useState(0);
//     const [padAngle, setPadAngle] = useState(0);
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [showvalues, setshowvalues] = useState(show_bar_values);
//     var width
//     var height
//     useEffect(() => {
//         if (chart_data !== undefined) {
//             setData(chart_data)
//             setpieColor(d3.schemeCategory10)
//             setMouseoverEnabled(mouseovered)
//             setEnabledTable(enable_table)
//             setshowvalues(show_bar_values)
//             setchart_height(svgHeight)
//             if (enable_table) {
//                 showTableFn(true)
//             }
//             else {
//                 showTableFn(false)
//             }
//         }
//     }, [chart_data, pieColor, mouseovered, enable_table, show_bar_values, temp_containerWidth, fullScreen_enabled, temp_containerHeight, svgHeight])
//     if (props.chart_data.length !== 0) {
//         var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
//         var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
//     }
//     const margin = { top: 35, right: 30, bottom: 10, left: 40 };
//     useEffect(() => {
//         if (svgHeight !== undefined && svgHeight !== '') {
//             containerHeight = containerHeight - 200
//         }
//         else {
//             containerHeight = containerHeight
//         }
//         if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
//             width = temp_containerWidth - margin.left - margin.right;
//             height = temp_containerHeight - margin.top - margin.bottom - (enabledTable ? 200 : 0)
//             containerWidth = width
//             containerHeight = height
//         }
//         else {
//             width = containerWidth - margin.left - margin.right;
//             height = containerHeight - margin.top - margin.bottom;
//         }
//         const radius = Math.min(containerWidth, containerHeight) / 2.5;
//         const svg = d3.select(`#my_dataviz${i}`)
//             .attr('width', containerWidth)
//             .attr('height', containerHeight);

//         const g = svg.select('g');
//         g.selectAll('*').remove();
//         g.attr('transform', `translate(${(fullScreen_enabled ? temp_containerWidth : containerWidth) / 2}, ${containerHeight / 2})`);

//         var color = d3.scaleOrdinal()
//             .range(pieColor);

//         const pie = d3.pie()
//             .value(d => d.count);

//         const arcs = pie(data);
//         const arc = d3.arc()
//             .innerRadius(0)
//             .outerRadius(radius);

//         const arcLabel = d3.arc()
//             .innerRadius(radius * 0.7)
//             .outerRadius(radius * 0.7);

//         g.selectAll('path')
//             .data(arcs)
//             .enter()
//             .append('path')
//             .attr('fill', (d, i) => color(i))
//             .transition()
//             .duration(1000)
//             .attrTween('d', function (d) {
//                 const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
//                 return function (t) {
//                     return arc(interpolate(t));
//                 }
//             })
//             .on('end', function () {
//                 d3.select(this)
//                     .on('mouseover', handleMouseOverAnimate)
//                     .on('mousemove', handleMouseMove)
//                     .on('mouseout', handleMouseOutAnimate)
//                     .on('mouseleave', handleMouseLeaveAnimate);
//             })

 

//         g.selectAll('text')
//         .data(arcs)
//         .enter()
//         .append('text')
//         .attr('transform', d => {
//             const pos = arcLabel.centroid(d);
//             pos[0] = radius * 1.3 * (midAngle(d) < Math.PI ? 1 : -1);
//             return `translate(${pos})`;
//         })
//         .attr('dy', '0.35em')
//         .style('text-anchor', d => midAngle(d) < Math.PI ? 'start' : 'end')
//         .style("text-transform", 'capitalize')
//         .text(d => showvalues ? d.data.name : '')
//         .style("opacity", 0)
//         .transition()
//         .duration(1000)
//         .style("opacity", 1)
//         // .style("text-transform", capitalize)
//         .attrTween('transform', function (d) {
//             const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
//             return function (t) {
//                 const pos = arcLabel.centroid(interpolate(t));
//                 pos[0] = radius * 1.1 * (midAngle(interpolate(t)) < Math.PI ? 1 : -1);
//                 return `translate(${pos})`;
//             };
//         });

//    showvalues&& g.selectAll('polyline')
//         .data(arcs)
//         .enter()
//         .append('polyline')
//         .attr('points', d => {
//             const posA = arc.centroid(d);
//             const posB = arcLabel.centroid(d);
//             const posC = arcLabel.centroid(d);
//             posC[0] = radius * 1 * (midAngle(d) < Math.PI ? 1 : -1);
//             return [posA, posB, posC];
//         })
//         .style('fill', 'none')
//         .style('stroke', 'lightgrey')
//         .style('stroke-width', '1.5px')
//         // .style('stroke-dasharray', '4, 4'); 

//     function midAngle(d) {
//         return d.startAngle + (d.endAngle - d.startAngle) / 2;
//     }

  
//         function handleMouseOverAnimate(event, d) {
//             if (mouseoverEnabled) {
//                 d3.select(this)
//                     .style('filter', 'url(#glow)')
//                     .transition()
//                     .duration(200)
//                     .attr('transform', `translate(${0.1 * radius * Math.cos(event.startAngle + (event.endAngle - event.startAngle) / 2)}, ${0.1 * radius * Math.sin(event.startAngle + (event.endAngle - event.startAngle) / 2)})`)
//                     .style('opacity', 1)

//                 d3.select(this)
//                     .transition()
//                     .duration(100)
//                     .attr('transform', `scale(1.1)`)
//                 const tooltip = d3.selectAll(`#tooltip${i}`);
//                 tooltip.transition().duration(100).style('opacity', 0.9);
//                 const svgContainer = d3.selectAll(`#my_dataviz${i}`).selectAll('g');
//                 const [x1, y1] = d3.pointer(event, svgContainer.node());
//                 const xPosition = x1 + (fullScreen_enabled ? temp_containerWidth : containerWidth) / 2 + 30;
//                 const yPosition = y1 + containerHeight / 2
//                 tooltip.html(`<strong>${d.data.name}</strong>: ${d.data.count}`)
//                     .style('transform', `translate(${xPosition}px, ${yPosition}px)`)
//                     .style("color", "red")
//                     .style("background-color", "white")
//             }
//             else {
//             }
//         }

//         function handleMouseMove(event) {
//             const tooltip = d3.selectAll(`#tooltip${i}`);
//             const svgContainer = d3.selectAll(`#my_dataviz${i}`).selectAll('g');
//             const [x1, y1] = d3.pointer(event, svgContainer.node());
//             const xPosition = x1 + (fullScreen_enabled ? temp_containerWidth : containerWidth) / 2 + 30;
//             const yPosition = y1 + containerHeight / 2;
//             tooltip.style('transform', `translate(${xPosition}px, ${yPosition}px)`);
//         }

//         async function handleMouseOutAnimate(event, d) {
//             d3.select(this).style('opacity', 1);
//             d3.selectAll(`#tooltip${i}`).style('opacity', 0);
//             d3.select(this)
//                 .style('filter', null)
//                 .transition()
//                 .duration(200)
//                 .attr('transform', 'translate(0,0)')
//         }
//         const handleMouseLeaveAnimate = () => {
//             const tooltip = d3.select(`#tooltip${i}`);
//             tooltip.transition().duration(100).style('opacity', 0);
//             d3.selectAll(`#tooltip${i}`).style('opacity', 0);
//         }

//         const legend = g.selectAll('.legend')
//             .data(data)
//             .enter().append('g')
//             .attr('class', 'legend')
//             .attr('transform', (d, i) => {
//                 if (containerWidth < 700) {
//                     const legendWidth = 100;
//                     const legendSpacing = 10;
//                     const totalWidth = data.length * (legendWidth + legendSpacing);
//                     const xOffset = (containerWidth - totalWidth) / 2;
//                     return `translate(${xOffset + i * (legendWidth + legendSpacing)}, ${containerHeight - 50})`;
//                 } else {
//                     return `translate(-${containerWidth / 2 - margin.left - 20}, ${i * 20 - 100})`;
//                 }
//             });
//         legend.append('rect')
//             .attr('width', 12)
//             .attr('height', 12)
//             .style('fill', (d, i) => color(i));
//         legend.append('text')
//             .attr('x', 24)
//             .attr('y', 9)
//             .attr('dy', '.35em')
//             .style('text-anchor', 'start')
//             .text(d => d.name);
//         if (enable_table) {
//             showTableFn(true)
//         }
//         else {
//             showTableFn(false)
//         }
//         const updateChart = () => {
//             const arc = d3.arc()
//                 .innerRadius(innerRadius)
//                 .outerRadius(Math.min(containerWidth, containerHeight) / 2.5)
//                 .cornerRadius(cornerRadius);



//                 const arcLabel = d3.arc()
//                 .innerRadius(radius * 0.7)
//                 .outerRadius(Math.min(containerWidth, containerHeight) / 2.5);



//             const pie = d3.pie()
//                 .value(d => d.count)
//                 .padAngle(padAngle);
//             const arcs = pie(data);

//             g.selectAll('polyline').remove()

//             showvalues&& g.selectAll('polyline')
//             .data(arcs)
//             .enter()
//             .append('polyline')
//             .attr('points', d => {
//                 const posA = arc.centroid(d);
//                 const posB = arcLabel.centroid(d);
//                 const posC = arcLabel.centroid(d);
//                 posC[0] = radius * 1 * (midAngle(d) < Math.PI ? 1 : -1);
//                 return [posA, posB, posC];
//             })
//             .style('fill', 'none')
//             .style('stroke', 'lightgrey')
//             .style('stroke-width', '1.5px')

//             function midAngle(d) {
//                 return d.startAngle + (d.endAngle - d.startAngle) / 2;
//             }

//             svg.selectAll('path')
//                 .data(arcs)
//                 .transition()
//                 .duration(1000)
//                 .attrTween('d', function (d) {
//                     const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
//                     return function (t) {
//                         return arc(interpolate(t));
//                     }
//                 })
//                 .on('end', function () {
//                     d3.select(this)
//                         .on('mouseover', handleMouseOverAnimate)
//                         .on('mousemove', handleMouseMove)
//                         .on('mouseout', handleMouseOutAnimate)
//                         .on('mouseleave', handleMouseLeaveAnimate);
//                 });
                
//         };
//         updateChart();
//     }, [containerWidth, containerHeight, data, pieColor, mouseoverEnabled, enabledTable, showvalues, temp_containerWidth, fullScreen_enabled, temp_containerHeight, enable_table, svgHeight, cornerRadius, innerRadius, padAngle,]);
//     const handleMenuClick = (e) => {
//         setShowOptions(!showOptions);
//     };

//     const handleDropMenuClick = (e) => {
//         setDropShowOptions(!dropShowOptions);
//     };

//     const showTableFn = async (val) => {
//         const fieldNames = Object.keys(data[0]);
//         if (val) {
//             await tabulate(data, fieldNames)
//         }
//         else {
//             d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
//         }
//     }
//     const handleRadiusChange = (event) => {
//         const { name, value } = event.target;
//         if (name === 'cornerRadius') {
//             setCornerRadius(Number(value));
//         } else if (name === 'innerRadius') {
//             setInnerRadius(Number(value));
//         } else if (name === 'padAngle') {
//             setPadAngle(Number(value));
//         }
//     };
//     const toggleDropdown = () => {
//         setIsDropdownOpen(!isDropdownOpen);
//     };
//     const tabulate = async (data, columns, y_axis_name) => {
//         const header = columns;
//         var data_exist;
//         if (data !== undefined) {
//             data_exist = data;
//         } else {
//             data_exist = data;
//         }
//         var tableContainer = document.getElementById(`tableContainer${i}`);
//         if (tableContainer !== null) {
//             tableContainer.innerHTML = "";

//         }
//         var table = d3.select(`#tableContainer${i}`)
//             .attr("class", "table-responsive")
//             .append("table")
//             .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth - 8}px`);
//         var thead = table.append("thead");
//         var tbody = table.append("tbody");
//         d3.select(tableContainer)
//             .attr('class', 'table_body')
//             .style("overflow-y", "scroll")
//             .style("overflow-x", "hidden");
//         thead.append("tr")
//             .selectAll("th")
//             .data(header)
//             .enter()
//             .append("th")
//             .text(function (column) { return column.toUpperCase(); })
//             .attr("style", "text-align: center")
//             .style('color', 'black')
//         var rows = tbody.selectAll("tr")
//             .data(data_exist)
//             .enter()
//             .append("tr");
//         var cells = rows.selectAll("td")
//             .data(function (row) {
//                 return columns.map(function (column) {
//                     return { column: column, value: row[column] };
//                 });
//             })
//             .enter()
//             .append("td")
//             .attr("class", function (d) { return "cell " + d.column; })
//             .html(function (d) {
//                 return d.column === "percentage" ? d.value.toFixed(2) + "%" : d.value;
//             })
//             .attr("style", "text-align: center")
//             .style('color', 'black')
//         return table;
//     }
//     return (
//         <div>
//             <div id={`tooltip${i}`} style={{ position: 'absolute', opacity: 0, background: 'lightgray', padding: '10px', borderRadius: '5px' }}></div>
//             <div id={`my_dataviz${i}`} width={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerWidth} onMouseLeave={() => { setShowOptions(false); setSortShowOptions(false); }} >
//                 <svg width={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerWidth} height={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerHeight} >
//                     <g></g>
//                 </svg>
//             </div>

//             {showOptions && (
//                 <div
//                     className="download-options"
//                     onMouseLeave={() => setShowOptions(false)}
//                     style={{
//                         position: 'absolute',
//                         top: '46px',
//                         right: '80px',
//                         height: '120px',
//                         backgroundColor: '#fff',
//                         border: '1px solid #ccc',
//                         borderRadius: '4px',
//                         color: '#000080',
//                         padding: '5px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'flex-end',
//                         cursor: 'pointer',
//                         justifyContent: 'center'
//                     }}
//                     onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
//                 >
//                     <p onClick={() => handleDownloadBar('0', datakeys_name, datakeys, data)}>Download as CSV</p>
//                     <p onClick={() => imgDownloadSvg(`my_dataviz${i}`)}>Download as SVG</p>
//                     <p onClick={() => imgDownloadPng(i)} className='mt-1'>Download as PNG</p>
//                 </div>
//             )}
//             <span onMouseOver={() => { handleMenuClick(); setSortShowOptions(false); }} onMouseUp={() => { setShowOptions(false); setSortShowOptions(false); }} >
//                 <i
//                     className="bx bx-download"
//                     style={{
//                         cursor: 'pointer',
//                         fontSize: '25px',
//                         width: '45px',
//                         position: 'absolute',
//                         top: '7px',
//                         right: '55px',
//                         zIndex: '1',
//                         color: '#6666B2',
//                     }}
//                 ></i>
//             </span>
//             <span onMouseOver={() => { handleDropMenuClick(); }} onMouseUp={() => { setDropShowOptions(false) }} >
//                 <i
//                     className="bx bx-caret-down"
//                     style={{
//                         cursor: 'pointer',
//                         fontSize: '30px',
//                         width: '45px',
//                         position: 'absolute',
//                         top: '4px',
//                         right: '194px',
//                         zIndex: '1',
//                         color: '#6666B2',
//                     }}
//                 ></i>
//             </span>
//             {dropShowOptions && (
//                 <div
//                     className="download-options"
//                     onMouseLeave={() => setDropShowOptions(false)}
//                     style={{
//                         position: 'absolute',
//                         top: '46px',
//                         right: '55px',
//                         height: '120px',
//                         backgroundColor: '#fff',
//                         border: '1px solid #ccc',
//                         borderRadius: '4px',
//                         color: '#000080',
//                         padding: '5px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'flex-end',
//                         cursor: 'pointer',
//                         justifyContent: 'center'
//                     }}
//                     onMouseOver={(e) => { e.target.style.color = 'green'; setDropShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
//                 >
//                     <label className="block px-2 pt-2 text-sm text-gray-100">
//                         Corner Radius
//                         <input type="range" name="cornerRadius" min="0" max="40" step="1" value={cornerRadius} onChange={handleRadiusChange} className="mt-1 block w-full" />
//                     </label>
//                     <label className="block px-2 text-sm text-gray-100">
//                         Inner Radius
//                         {/* <input type="range" name="innerRadius" min="0" max={fullScreen_enabled ? '500' : '100'} step={fullScreen_enabled ? '5' : '1'} value={innerRadius} onChange={handleRadiusChange} className="mt-1 block w-full" /> */}
//                         <input type="range" name="innerRadius" min="0" max={ '100'} step={ '1'} value={innerRadius} onChange={handleRadiusChange} className="mt-1 block w-full" />

//                     </label>
//                     <label className="block px-2 text-sm text-gray-100">
//                         Pad Angle
//                         <input type="range" name="padAngle" min="0" max="0.5" step="0.01" value={padAngle} onChange={handleRadiusChange} className="mt-1 block w-full" />
//                     </label>
//                 </div>
//             )}
//             <span style={{
//                 cursor: 'pointer',
//                 fontSize: '25px',
//                 width: '45px',
//                 position: 'absolute',
//                 top: '-4px',
//                 right: '190px',
//                 zIndex: '1',
//                 color: '#6666B2',
//             }}>
//             </span>
//             {isLoading &&
//                 <div className="loader-overlay">
//                     <div className="loader"></div>
//                 </div>}
//             {enabledTable ? (
//                 <>
//                     <div style={{
//                         position: 'absolute',
//                         bottom: 0,
//                         left: 0,
//                         backgroundColor: '#fff',
//                         height: (fullScreen_enabled ? '240px' : '200px')

//                     }} id={`tableContainer${i}`}>
//                     </div>
//                 </>
//             ) : null}
//         </div>
//     );
// };
// export default PieChart;






