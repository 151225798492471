

import React, { Component } from "react";
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress, Badge,
    Input,
} from "reactstrap";
// import Dropzone from "react-dropzone"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"
import MediaPreview from "./media_preview";



import moment from 'moment'

const _ = require('lodash')
var urlSocket = require("../../../../helpers/urlSocket")




export default class ReviewOPType extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataloaded: false,
            previousData: {},
            attachImages: [],
            attachDocuments:[],
            selectedOption:null,
            docfileStatus:"clear",
            fileStatus:"clear",
            doc_warning_enabled:false,
            warning_enabled:false,
        }
    }

    componentDidMount() {
      
    }


    

   

    render() {
        return (
                <Row className="my-2 mx-2">
                    {/* <div className="d-flex flex-row justify-content-start" key={"bbtngroup" + this.props.index}> */}
                        
                    {
                                this.props.options.map((item, idx) => {
                                    return (
                                        <Col key={idx} style={{background: item.is_selected &&  this.props.get_btn_color.cp_compliance.color !== undefined && this.props.get_btn_color.cp_compliance.color === 'success' ?  '#34c38f' 
                                        : item.is_selected &&  this.props.get_btn_color.cp_compliance.color !== undefined && this.props.get_btn_color.cp_compliance.color === 'danger' ?  '#f46a6a'  
                                        : item.is_selected &&  this.props.get_btn_color.cp_compliance.color !== undefined && this.props.get_btn_color.cp_compliance.color === 'warning' ?  '#f1b44c' 
                                        : item.is_selected && this.props.get_btn_color.cp_compliance.color !== undefined && this.props.get_btn_color.cp_compliance.color === 'secondary' ? 'rgb(108 117 125 / 79%)'
                                        :
                                        'white', filter: !item.is_selected  && ' blur(1px)'}}
                                         className= {item.is_selected &&  this.props.get_btn_color.cp_compliance.color !== undefined ? ' rounded-3 col-auto  my-2 me-2': "border border-info rounded-3  col-auto my-2 me-2"} disabled={item.is_selected ? false : true}>
                                           
                                        <div className={item.is_selected &&  this.props.get_btn_color.cp_compliance.color !== undefined? "font-size-14 p-2 text-white": "font-size-14 p-2 text-info"}>{item.option_text}</div>
                                    </Col>
                                    )
                                })
                            }
                         
                         
                         
                         
                            {/* {
                                this.props.options.map((item, idx) => {
                                    return (
                                        <Col className= {item.is_selected?"bg-info rounded-3 col-auto  my-2 me-2":"border border-info rounded-3  col-auto my-2 me-2"} disabled>
                                        <div className={item.is_selected ?"font-size-14 p-2 text-white": "font-size-14 p-2 text-info"}>{item.option_text}</div>
                                    </Col>
                                    )
                                })
                            } */}
                    {/* </div> */}
                </Row>


        )
    }

}