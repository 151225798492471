import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
import { initFirebaseBackend,getFirebaseBackend } from "./helpers/authUtils";
import urlSocket from "./helpers/urlSocket";
// import { onSocketConnection } from "./helpers/socket";

const firebaseConfig = {

};

// init firebase backend
initFirebaseBackend(firebaseConfig);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getLayout = this.getLayout.bind(this);
    }

    componentDidMount() {
        var authUser = JSON.parse(sessionStorage.getItem('authUser'))
        // console.log(authUser,'authUser')

        if (authUser !== null) {
            this.getUserList(authUser.db_info, authUser.client_info[0].short_name)
        }

        // onSocketConnection(authUser)
    }

    getUserList = (db_info,short_name) =>{
       
        try{
            urlSocket.post('cog/get-client-configuration', {db_info: db_info,short_name:short_name}).then((res) =>{
                // console.log(res,"res")
                if(res.data.response_code === 500){
                    var data =  JSON.parse(sessionStorage.getItem('authUser'))
                    var client_info = res.data.data
                    data["client_info"]= client_info
                    sessionStorage.setItem("authUser",JSON.stringify(data))
                }
            })
        }
        catch (error) {
            console.log(error,'error')
        }
    
    }

    /**
     * Returns the layout
     */
    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    render() {
        const Layout = this.getLayout();

        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                            />
                        ))}
                        {/* {
                            console.log(authProtectedRoutes,'authProtectedRoutes',publicRoutes)
                        } */}


                        {
                        authProtectedRoutes.map((route, idx) => (
                            // <>
                            <AppRoute
                                path={route.path}
                                layout={Layout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={true}
                            />
                            // {
                            //  console.log(route,'route')
                            // }
                            // </>
                            
                        ))}
                        
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, null)(App);





















// import PropTypes from 'prop-types';
// import React from "react";
// import { useSelector } from "react-redux";
// import { createSelector } from "reselect";
// import { Routes, Route } from "react-router-dom";
// import { layoutTypes } from "./constants/layout";
// // Import Routes all
// import { authProtectedRoutes, publicRoutes } from "./routes";

// // Import all middleware
// import Authmiddleware from "./routes/route";

// // layouts Format
// import VerticalLayout from "./components/VerticalLayout/";
// import HorizontalLayout from "./components/HorizontalLayout/";
// import NonAuthLayout from "./components/NonAuthLayout";

// // Import scss
// import "./assets/scss/theme.scss";

// // Import Firebase Configuration file
// // import { initFirebaseBackend } from "./helpers/firebase_helper";

// // import fakeBackend from "../../src/helpers/AuthType/fakeBackend";
// import fakeBackend from 'helpers/AuthType/fakeBackend';

// // Activating fake backend
// fakeBackend();

// // const firebaseConfig = {
// //   apiKey: process.env.REACT_APP_APIKEY,
// //   authDomain: process.env.REACT_APP_AUTHDOMAIN,
// //   databaseURL: process.env.REACT_APP_DATABASEURL,
// //   projectId: process.env.REACT_APP_PROJECTID,
// //   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
// //   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
// //   appId: process.env.REACT_APP_APPID,
// //   measurementId: process.env.REACT_APP_MEASUREMENTID,
// // };

// // init firebase backend
// // initFirebaseBackend(firebaseConfig);


// const getLayout = (layoutType) => {
//   let Layout = VerticalLayout;
//   switch (layoutType) {
//     case layoutTypes.VERTICAL:
//       Layout = VerticalLayout;
//       break;
//     case layoutTypes.HORIZONTAL:
//       Layout = HorizontalLayout;
//       break;
//     default:
//       break;
//   }
//   return Layout;
// };

// const App = () => {


//   const selectLayoutState = (state) => state.Layout;
//   const LayoutProperties = createSelector(
//     selectLayoutState,
//       (layout) => ({
//         layoutType: layout.layoutType,
//       })
//   );

//     const {
//       layoutType
//   } = useSelector(LayoutProperties);

//   const Layout = getLayout(layoutType);

//   return (
//     <React.Fragment>
//       <Route>
//         {
//           console.log(publicRoutes,'publicRoutes')
//         }
//         {publicRoutes.map((route, idx) => (
//           <Route
//             path={route.path}
//             element={
//               <NonAuthLayout>
//                 {route.component}
//               </NonAuthLayout>
//             }
//             key={idx}
//             exact={true}
//           />
//         ))}

//         {authProtectedRoutes.map((route, idx) => (
//           <Route
//             path={route.path}
//             element={
//               <Authmiddleware>
//                 <Layout>{route.component}</Layout>
//               </Authmiddleware>}
//             key={idx}
//             exact={true}
//           />
//         ))}
//       </Route>
//     </React.Fragment>
//   );
// };

// App.propTypes = {
//   layout: PropTypes.any
// };

// export default App;