import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Label,
    Input,
    FormFeedback,
    Form,
    Modal, FormGroup
} from "reactstrap";
import CryptoJS from 'crypto-js'
import { Space, Spin,Upload, Image, Popconfirm } from 'antd';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from 'sweetalert2';
import { LoadingOutlined, DeleteTwoTone, UploadOutlined, InboxOutlined } from '@ant-design/icons'
import Dropzone from "react-dropzone";
import reactImageSize from 'react-image-size';
import Resizer from 'react-image-file-resizer'
import Header from "../VerticalLayout/Header";

//redux
// import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import ImgCrop from "antd-img-crop";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// import "antd/dist/antd.css";
import avatar from "../../assets/images/149071.png";
import urlSocket from "../../helpers/urlSocket";
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import WebcamCapture from "../../pages/Audit/UserAudit/Components/WebCam_Comp";
import Webcam from "react-webcam";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ImageCrop from "./ImageCrop";
import facility from '../../pages/Audit/FacilityScreen'



// actions

const UserProfile = () => {
    const history = useHistory();
    //meta title
    document.title = "Profile | AuditVista";

    const [email, setemail] = useState("");
    const [name, setname] = useState("");
    const [idx, setidx] = useState(1);
    const [previewUser, setPreviewUser] = useState(true)
    const [userInfo, setUserInfo] = useState({})
    const [openModal, setOpenModal] = useState(false)
    // const [show, setShow] = useState(false);
    const [dbInfo, setDbInfo] = useState({})
    const [clientInfo, setClientInfo] = useState({})
    const [selectedFiles, setselectedFiles] = useState([]);
    const [secret_key, setSecret_key] = useState('Pkdh347ey%3Tgs')
    const [showUpload, setshowUpload] = useState(false);
    const [renderHeader, setrenderHeader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [required_img_width,set_required_img_width] = useState(1700)
    const [required_img_height,set_required_img_height] = useState(1700)
    const [userImg, setUserImg] = useState([]);
    
    // const [takephoto, setTakephoto] = useState(false);
    const [galleryphoto, setGalleryphoto] = useState(false);

    const webcamRef = React.useRef(null)
  const [cameraID, setCameraId] = React.useState(null)
  
  const [takephoto, setTakePhoto] = useState(false);
  const [uploadphoto, setUploadPhoto] = useState(false);
  const [showDropdown, setshowDropdown] = useState(true);
  const [mobNumExist, setmobNumExist] = useState(false);

//   const [crop, setCrop] = useState({ aspect: 1 / 1 }); // Aspect ratio for cropping
const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [modalVisible, setModalVisible] = useState(false);

  const [show0, setShow0] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

    useEffect(() => {
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];     
        console.log(client_info,'client_info')
        setshowUpload(client_info.client_logo === undefined || client_info.client_logo === null  ? true : false)
        console.log(db_info, 'db_info')
        setDbInfo(db_info)
        setClientInfo(client_info)
        if (sessionStorage.getItem("authUser")) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            console.log(obj, 'obj')
            session_data(obj)
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName);
                setemail(obj.user_data.email);
                setidx(obj.uid);
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            )
                //   {
                //     setname(obj.username);
                //     setemail(obj.email);
                //     setidx(obj.uid);
                //   }
                setTimeout(() => {
                    // dispatch(resetProfileFlag());
                }, 3000);
        }
    }, []);

    const session_data = (data) => {
        console.log(data, 'data')
        setUserInfo(data.user_data)
        setUserImg(data.user_data.user_img !== undefined ? data.user_data.user_img :[])
        setname(data.user_data.firstname)
        setemail(data.user_data.email_id);
        setidx(data.uid);

    }


    const changepwd = useFormik({
        enableReinitialize: true,
        initialValues: {
            current_password: '',
            new_password: '',
            confirm_password: ''
        },
        // validationSchema: Yup.object({
        //     current_password: Yup.string().required("Please Enter Your Current Password"),
        //     new_password: Yup.string().min(8, "Password must be at least 8 characters long")
        //         .matches(
        //             /^(?=.*[A-Z]).+$/,
        //             "Password must contain at least one uppercase letter"
        //         )
        //         .required("Please Enter the New Password"),
        //     confirm_password: Yup.string().oneOf([Yup.ref("new_password"), null], "Passwords must match")
        //         .required("Please Confirm Your New Password"),
        // }),
        validationSchema: Yup.object({
            current_password: Yup.string().required("Please Enter Your Current Password"),
            new_password: Yup.string().min(8, "Password must be at least 8 characters long")
                .matches(
                    /^(?=.*[A-Z]).+$/,
                    "Password must contain at least one uppercase letter"
                )
                .notOneOf([Yup.ref("current_password"), null], "New password cannot be the same as current password")
                .required("Please Enter the New Password"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("new_password"), null], "Passwords must match")
                .required("Please Confirm Your New Password"),
        }),
        onSubmit: (values) => {
            console.log(values, dbInfo, clientInfo)
            values["encrypted_db_url"] = dbInfo.encrypted_db_url
            values["db_name"] = dbInfo.db_name
            values["userPoolId"] = clientInfo.userPoolId
            values["clientId"] = clientInfo.clientId
            let temp_curr_pwd = values.current_password
            let temp_new_pwd = values.new_password
            var curr_pwd = CryptoJS.AES.encrypt(temp_curr_pwd, secret_key).toString()
            var new_pwd = CryptoJS.AES.encrypt(temp_new_pwd, secret_key).toString()
            values["current_password"] = curr_pwd
            values["new_password"] = new_pwd
            values["confirm_password"] = new_pwd
            values["email_id"] = userInfo.email_id
            console.log(values, dbInfo, clientInfo) 
            setLoading(true);

            try {
                urlSocket.post('cog/change-pwd', values).then((res) => {
                    console.log(res, 'res')
                    values["current_password"] = temp_curr_pwd
                    values["new_password"] = temp_new_pwd
                    values["confirm_password"] = temp_new_pwd
                    if (res.data.data.response_code === 500) {       
                                  
                        // window.location.reload()
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: 'Your request has been processed successfully.',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            console.log(result, 'result')
                            if (result.isConfirmed) {
                                setLoading(false);
                                setOpenModal(false); 
                                sessionStorage.clear()
                                window.location.reload();
                            }
                            else{
                                setLoading(false);
                                setOpenModal(false);    
                                sessionStorage.clear()
                                window.location.reload();
                            }
                        })
                    }else if(res.data.data.error.code === "NotAuthorizedException" || res.data.data.error.name === "NotAuthorizedException"){
                        Swal.fire({
                            icon: 'warning',
                            title: 'Incorrect!',
                            text: 'Current password incorrect.',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            console.log(result, 'result')
                            setOpenModal(false);
                            setLoading(false); 
                            
                            if (result.isConfirmed) {                               
                                setLoading(false);
                                // sessionStorage.clear()
                                // window.location.reload();

                            }
                        })
                    }
                })
            } catch (error) {
                console.log(error, 'error')
            }
        }
    })



    const checkMobExists=(value)=>{
        console.log(value,'value')
        var admn_info = {}
        admn_info["phone_number"] =value

        admn_info["encrypted_db_url"] =  dbInfo.encrypted_db_url
        admn_info["db_name"] =  dbInfo.db_name
        admn_info["user_id"] = userInfo._id
        admn_info["edit"] = true


        try {
            urlSocket.post('cog/check-user-exist', { admn_info: admn_info }).then((data) => {
                console.log(data, 'data')
                // if (mode == 2) {
                if (data.data.response_code === 504 && data.data.message === "Email Id already exist for another user") {
                    setmobNumExist(true)
                } else {
                    setmobNumExist(false)
                    // this.setState({ invalid_phone_num: false })
                }
            })
        } catch (error) {

        }


    }






    // })

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            username: userInfo.username || '',
            firstname: userInfo.firstname || '',
            // lastname: userInfo.lastname || '',
            addrss_1: userInfo.addrss_1 || '',
            city: userInfo.city || '',
            state: userInfo.state || '',
            country: userInfo.country || '',
            email_id: userInfo.email_id || '',
            phone_number: userInfo.phone_number || '',
            qualification: userInfo.qualification || '',
            doj: userInfo.doj || '',
            designation: userInfo.designation || '',
            experience: userInfo.experience || '',
            usercode: userInfo.usercode || '',
            password: userInfo.password || '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your UserName"),
            firstname: Yup.string().required("Please Enter Your firstname"),
            // lastname: Yup.string().required("Please Enter Your lastname"),
            email_id: Yup.string().required("Please Enter Your Email Id"),
            // phone_number: Yup.string().required("Please Enter Your Mobile Number"),
            phone_number: Yup.string().required("Please Enter Your Mobile Number").matches(/^\d{10}$/, "Mobile Number should be exactly 10 digits").test(async function (value) {
                return !(await checkMobExists(value));
              }), 



            // designation: Yup.string().required("Please Enter Your Designation"),
            // usercode: Yup.string().required("Please Enter Your firstname"),
            // password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            // var password = CryptoJS.AES.encrypt(values.password, secret_key).toString()
            values["password"] = userInfo.password
            values["confirmpassword"] = userInfo.password
            values["userPoolId"] = clientInfo.userPoolId
            values["clientId"] = clientInfo.clientId
            values["encrypted_db_url"] = dbInfo.encrypted_db_url
            values["db_name"] = dbInfo.db_name
            values["facilities"] = userInfo.facilities
            values["_id"] = userInfo._id
            values["status"] = userInfo.status
            values["active"] = userInfo.active
            values["company_id"] = userInfo.company_id
            values["role_id"] = userInfo.role_id
            values["role_name"] = userInfo.role_name
            values["user_id"] = userInfo._id
            values["created_by"] = userInfo.created_by
            console.log(values)
            if(!mobNumExist){
            try {
                urlSocket.post('cog/cruduser', values).then((res) => {
                    console.log(res, 'res')
                    if (res.data.response_code === 500) {
                        var authUser = JSON.parse(sessionStorage.getItem('authUser'))
                        authUser["user_data"]= res.data.userInfo[0]
                        sessionStorage.setItem('authUser',JSON.stringify(authUser))
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: 'User Information has been saved successfully.',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                        })
                            .then((result) => {
                                console.log(result, 'result')
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                            })
                        // window.location.reload()
                    }
                })

            } catch (error) {
                console.log(error, 'error')
            }
            }

            //   dispatch(editProfile(values));
        }
    });

    const  imageSizeValid = (imageUrl) => {
        console.log(imageUrl,'imageUrl')
        try {
          // const { width, height } = await reactImageSize(imageUrl)
          return reactImageSize(imageUrl)
            .then(({ width, height }) => {
              console.log({ width, height })
              let data = { width, height }
              data["width"]=width
              data["height"]=height
              return data
            })
            .catch((error) => {
              console.log(error)
            })
        } catch {
    
        }
      }



    // async function handleAcceptedFiles(files) {
    //     console.log(files, 'files')
    //     const formData = new FormData();
    //     clientInfo["client_logo"] = {}
    //     files.map(async file => {
    //         const formattedFile = Object.assign(file, {
    //             preview: URL.createObjectURL(file),
    //             formattedSize: formatBytes(file.size),
    //             filetype: file.type,
    //             uploadingStatus: 'Not uploaded',
    //             originalName: file.name,
    //         });

    //         let image_size = await imageSizeValid(files[0].preview)
    //         formattedFile["width"] = image_size.width
    //         formattedFile["height"] = image_size.height
    //         console.log(image_size, 'image_size', formattedFile)

    //         formData.append('files', formattedFile);
    //         if (clientInfo._id !== undefined) {
    //             console.log("update_img", clientInfo._id)
    //             formData.append('client_logo', clientInfo._id);
    //             formData.append('email_id', clientInfo.email_id);
    //             formData.append('encrypted_db_url',dbInfo.encrypted_db_url );
    //             // formData.append('client_info', state);
    //             // var client_info = state
    //         }
    //         console.log(image_size, files, files[0].size > 500000 && files[0].size <= 1000000)
    //         if (files[0].size > 500000 && files[0].size <= 1000000) {
    //             console.log(image_size, 'image_size', files)
    //             Swal.fire({
    //                 icon: 'warning',
    //                 title: 'Warning!',
    //                 text: `Image Size is more than 1MB `,
    //                 confirmButtonColor: '#3085d6',
    //                 confirmButtonText: 'OK'
    //             }).then((result) => {
    //                 console.log(result, 'result')
    //                 // getClientList()


    //             })
    //         }

    //         // formData.append('files', formattedFile);
    //         if (image_size.width < required_img_width && image_size.height < required_img_height) {
    //             console.log('Matched', files)
    //             try {
    //                 const config = {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data'
    //                     }
    //                 };
    //                 urlSocket.post('storeImage/logo_awswebupload', formData, config).then((response) => {
    //                     console.log(response, 'response')
    //                     if (response.data.response_code === 500) {
    //                         var session_data = JSON.parse(sessionStorage.getItem('authUser'))
    //                         session_data.client_info[0]["client_logo"]=response.data.client_logo
    //                         var client_data = JSON.parse(sessionStorage.getItem('client_info'))
    //                         client_data[0]["client_logo"]=response.data.client_logo
    //                         sessionStorage.setItem('authUser',JSON.stringify(session_data))
    //                         sessionStorage.setItem('client_info',JSON.stringify(client_data))
    //                         setshowUpload(false)
    //                         // clientInfo["client_logo"]=response.data.data[0]
    //                     }
    //                 })

    //             } catch (error) {

    //             }
    //         }
    //          else {
    //             console.log('Not Matched')
    //             Swal.fire({
    //                 icon: 'warning',
    //                 title: 'Warning!',
    //                 text: `Please Upload image size exactly on ${required_img_width}px X ${required_img_height}px`,
    //                 confirmButtonColor: '#3085d6',
    //                 confirmButtonText: 'OK'
    //             }).then((result) => {
    //                 console.log(result, 'result')
    //                 // getClientList()


    //             })
    //             // this.toastWarning(`Please Upload image size exactly on ${this.state.required_img_width}px X ${this.state.required_img_height}px`)
    //             return
    //         }

    //     });

    //     setselectedFiles(files)
    //     console.log(files)


    // }



    async function handleAcceptedFiles(files) {
        setLoading(true)
        const formData = new FormData();
        clientInfo["client_logo"] = {}
        files.map(async file => {
            const formattedFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                filetype: file.type,
                uploadingStatus: 'Not uploaded',
                originalName: file.name,
            });
    
            let image_size = await imageSizeValid(files[0].preview)
            formattedFile["width"] = image_size.width
            formattedFile["height"] = image_size.width
            console.log(image_size, 'image_size', formattedFile)  
            formData.append('files', formattedFile);
            if (clientInfo._id !== undefined) {
                console.log("update_img", clientInfo._id)
                formData.append('client_logo', clientInfo._id);  
                formData.append('email_id', clientInfo.email_id);
                formData.append('short_name', clientInfo.short_name);
                formData.append('encrypted_db_url',dbInfo.encrypted_db_url );          
            }
            if (files[0].size > 1000000) {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: `Image Size is more than 1MB `,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    console.log(result, 'result')
                    // getClientList()
    
                })
            }
            else{
            try {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };
                urlSocket.post('storeImage/logo_awswebupload', formData, config).then(async(response) => {
                    console.log(response, 'response')
                    if (response.data.response_code === 500) {
                        // setshowUpload(false)
                        // formData.append('client_logo', state._id); 
                        var upload = await updt_rszd_img(formattedFile)
                        console.log(upload,'upload')
                        var rszed_img = await rszed_upload_img(upload,config,files)
    
    
                    }
                })
    
            } catch (error) {
                console.log(error,'error')
            }
        }
        });
    
    }
    
        async function rszed_upload_img(upload, config,files) {
            console.log(files,'files')
            urlSocket.post('storeImage/logo_awswebupload', upload, config).then(async (response) => {
                console.log(response, 'response')
                if(response.data.response_code === 500){
                     var session_data = JSON.parse(sessionStorage.getItem('authUser'))
                    var client_data = JSON.parse(sessionStorage.getItem('client_info'))
                    client_data = response.data.client_info
                    session_data.client_info = response.data.client_info
                    console.log(session_data,'session_data')
                    sessionStorage.setItem('client_info',JSON.stringify(client_data))
                    // var client_data = JSON.parse(sessionStorage.getItem('client_info'))
                    // client_data[0]["client_logo_rzd"]=response.data.client_logo
                    sessionStorage.setItem('authUser',JSON.stringify(session_data))
                    // sessionStorage.setItem('client_info',JSON.stringify(client_data))
                    setLoading(false)
                    setshowUpload(false)
                    setselectedFiles(files)
                    history.push(window.location.pathname);
                }
            })
    
    
        }
    
    
    
    
    
    
    
    
    
        async function updt_rszd_img(files){
    
            console.log(files,'files')
            const formData = new FormData()
            if(clientInfo._id !== undefined){
                formData.append('client_logo',clientInfo._id)
            }
    
            if (files.size > 500000 && files.size <= 1000000) {
    
                const resizedFile = await resizeImage(files, 0.8, 512, 512)
                resizedFile.formattedSize= formatBytes(resizedFile.size)
                resizedFile.preview= URL.createObjectURL(resizedFile)
                
                console.log('resizedFile', resizedFile,resizedFile.name +"_rzd")
                const dotIndex = resizedFile.name.lastIndexOf('.');
                const modifiedFilename = resizedFile.name.slice(0, dotIndex) + "_rzd" + resizedFile.name.slice(dotIndex);
                resizedFile["originalname"]= modifiedFilename
                
                formData.append('files', resizedFile);
                formData.append('email_id', clientInfo.email_id);
                formData.append('short_name', clientInfo.short_name);
                formData.append('encrypted_db_url',dbInfo.encrypted_db_url );
                formData.append('resized', true);     
                return formData
    
            } else {
                formData.append('email_id', clientInfo.email_id);
                formData.append('encrypted_db_url',dbInfo.encrypted_db_url );
                formData.append('short_name', clientInfo.short_name);
                formData.append('resized', true);     
                formData.append('files', files);
                return formData
    
            }     
    
        } 
    
        async function resizeImage(file, resizeRatio, targetWidth, targetHeight) {
            const dotIndex = file.name.lastIndexOf('.');
            const modifiedFilename = file.name.slice(0, dotIndex) + "_rzd" + file.name.slice(dotIndex);
            var originalName =modifiedFilename
            return new Promise((resolve, reject) => {
                Resizer.imageFileResizer(
                    file,
                    targetWidth,
                    targetHeight,
                    'JPEG',
                    80,
                    0,
                    (resizedFile) => {
                        const modifiedResizedFile = new File([resizedFile], originalName, { type: resizedFile.type });
                        resolve(modifiedResizedFile);
                    },
                    'blob'
                );
            });
        }
    
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    function deleteImage(data) {
        console.log(data, 'data')
        if (Object.keys(clientInfo.client_logo).length > 0) {
            delete clientInfo["client_logo"]
            console.log(clientInfo,'state')
            var delete_data ={}
            delete_data["userPoolId"] = clientInfo.userPoolId
            delete_data["clientId"] = clientInfo.clientId
            delete_data["_id"] = clientInfo._id
            delete_data["email_id"] = clientInfo.email_id
            delete_data["short_name"] = clientInfo.short_name
            delete_data["encrypted_db_url"] = dbInfo.encrypted_db_url
            delete_data["db_name"] = dbInfo.db_name
            urlSocket.post('webmngtmplt/delete-client-logo', { "client_info": delete_data }).then((response) => {
                console.log(response, 'response')
                setselectedFiles([])
                if(response.data.response_code === 500){
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: `Image has been deleted successfully`,
                        confirmButtonColor: '#FF0000',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        console.log(result, 'result')
                        var session_data = JSON.parse(sessionStorage.getItem('authUser'))
                        session_data["client_info"] = response.data.client_data
                        sessionStorage.setItem('authUser',JSON.stringify(session_data))
                        sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
                        if (result.isConfirmed) {
                            setrenderHeader(true)
                        }
                        // getClientList()
    
                    })

                }
                // if(response.data.response_code === 500){
                //         var values ={}
                //         values[]
                //     try {
                //         urlSocket.post('cog/login-authenticate', values).then((res) => {


                //         })
                //     }
                //     catch(error){
                //         console.log(error,'erro')
                //     }


                // }
                setshowUpload(true)


            })
        }
        else {
            setselectedFiles([])
        }
        setshowUpload(true)
    }


    const edit_user_info = () => {
        console.log("edit usersss")
        setPreviewUser(false)
    }

    const changePassword = (data) => {
        console.log("change passowrd")
        changepwd.resetForm();
        setOpenModal(true)
        setLoading(false)
        if (data === "1") {
            setOpenModal(!openModal)
        }

    }

    const onFileChange = (files) => {
        console.log('e', [files]);
        [files].map(async file => {
            const formattedFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                filetype: file.type,
                uploadingStatus: 'Not uploaded',
                originalName: file.name,
            });
            const formData = new FormData();
            console.log(formattedFile,'formattedFile')
            formData.append('files',formattedFile)
            formData.append('encrypted_db_url',dbInfo.encrypted_db_url)
            formData.append('_id',userInfo._id)

            try {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };
                urlSocket.post('storeImage/user-img-upload',formData,config).then((response)=>{
                    console.log(response,'response')
                    if(response.data.response_code === 500){
                        setUserImg(response.data.data)
                        var session_data = JSON.parse(sessionStorage.getItem('authUser'))
                        session_data["user_data"] = response.data.user_data[0]
                        sessionStorage.setItem('authUser',JSON.stringify(session_data))
                        setUploadPhoto(false)
                        // sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
                    }
                })
                
            } catch (error) {
                
            }
        })
        console.log('e', [files]);
    }

    const deleteUserImg =(data)=>{
        console.log("user image delete",data)
        var obj_data ={}
        obj_data["user_data"]=data
        obj_data["encrypted_db_url"]=dbInfo.encrypted_db_url

        try {
                urlSocket.post('storeImage/user-delete-img',obj_data).then((response)=>{
                    console.log(response,'response')
                    if(response.data.response_code == 500){
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted Successfully',
                            text: 'Profile Image Deleted successfully',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            if(result.isConfirmed){
                                console.log("success")
                                var session_data = JSON.parse(sessionStorage.getItem('authUser'))
                                session_data["user_data"] = response.data.user_data[0]
                                sessionStorage.setItem('authUser',JSON.stringify(session_data))
                                setUserImg([])
                            }

                        })
                    }
                })
        } catch (error) {
            
        }

    }


    // const handleDropdownSelect = (eventKey) => {
    //     if (eventKey === 'takePhoto') {
    //         handleTakePhoto()
    //     } else if (eventKey === 'browseGallery') {
    //         handleBrowseGallery()
    //     }
    // };

    // const handleTakePhoto = () => {
    //     // Handle "Take Photo" logic here
    //     console.log('handleTakePhoto called...')
    //     setTakephoto(true)
    //     setGalleryphoto(false)
    //     setShowdropdown(false)
    // };

    // const handleBrowseGallery = () => {
    //     // Handle "Browse Gallery" logic here
    //     console.log('handleBrowseGallery called...')
    //     setTakephoto(false)
    //     setGalleryphoto(true)
    //     setShowdropdown(false)
    // };



    //// working
//      const handleTakePhoto = () => {
//         // Handle "Take Photo" logic here
//         console.log('handleTakePhoto called...')
//         setTakephoto(true)
//         setGalleryphoto(false)
//         setShowdropdown(false)
//     };


//     const handleDropdownSelect = (eventKey) => {
//         if (eventKey === 'takePhoto') {
//             handleTakePhoto();
//         } else if (eventKey === 'browseGallery') {
//             // Instead of setting states here, open file picker dialog
//             openFilePicker();
//         }
//     };

//     const openFilePicker = () => {
//         const input = document.createElement('input');
//         input.type = 'file';
//         input.accept = 'image/*';
//         input.onchange = onFileChange; // Define your file change handler
//         input.click();
//     };

//     const onFileChange = async (event) => {
//         const selectedFile = event.target.files[0];
//         console.log('selectedFile', selectedFile)
//         if (selectedFile) {
//             // const formattedFile = {
//             //     preview: URL.createObjectURL(selectedFile),
//             //     formattedSize: formatBytes(selectedFile.size),
//             //     filetype: selectedFile.type,
//             //     uploadingStatus: 'Not uploaded',
//             //     originalName: selectedFile.name,
//             // };
//             selectedFile.preview = URL.createObjectURL(selectedFile)
//             selectedFile.formattedSize = formatBytes(selectedFile.size)
//             selectedFile.filetype = selectedFile.type
//             selectedFile.originalName = selectedFile.name
//             console.log('upload image', [selectedFile])
//             // console.log('formattedFile', formattedFile)
//             const formData = new FormData();
//             formData.append('files', selectedFile);
//             formData.append('encrypted_db_url', dbInfo.encrypted_db_url);
//             formData.append('_id', userInfo._id);
    
//                      try {
//                 const config = {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 };
//                 urlSocket.post('storeImage/user-img-upload',formData,config).then((response)=>{
//                     console.log(response,'response')
//                     if(response.data.response_code === 500){
//                         setUserImg(response.data.data)
//                         var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//                         session_data["user_data"] = response.data.user_data[0]
//                         sessionStorage.setItem('authUser',JSON.stringify(session_data))
//                         // sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
//                     }
//                 })
                
//             } catch (error) {
                
//             }
//         }
//     };

//     const handleFileChange = (event) => {
//         const selectedFile = event.target.files[0];
//         console.log('selectedFile', selectedFile)
//         if (selectedFile) {
//             // Handle the selected file here (e.g., upload, process, etc.)
//         }
//     };


   const uploadWebCamImage = async (file) => {
    console.log('file', file)
        Object.assign(file, {
            "preview": URL.createObjectURL(file), //URL.createObjectURL(file),
            "formattedSize": formatBytes(file.size),
            "uploading": false,
            "filetype": file.type,
            "uploadingStatus": 'Not uploaded',
            "originalName": file.name,
            "captured_on": new Date(),
            "path": file.name,
            //  "name": image_info.name,
            // "type": file.type,
            //"uri": URL.createObjectURL(image_info),
        })
        //window.location = URL.createObjectURL(file);
        console.log("pictureSrc", file)
        setUserImg([file])
        setTakePhoto(false)
        setModalVisible(true)
        let formData = new FormData();        
        formData.append('imagesArray', file)

        // try {
        //   urlSocket.post("storeImage/awswebupload", formData, {
        //     onUploadProgress: progressEvent => {
        //       console.log("this.progress", progressEvent.loaded * 100 / progressEvent.total)
        //       if (progressEvent.loaded === progressEvent.total) {
        //         //this.progress.current++
        //       }
        //     }
        //   }).then(response => {
        //     console.log((response.data))
        //     if (response.data.response_code == 500) {          

        //     }

        //   })
        //   .catch(error => {
        //     console.log("error", error)
        //   })
        // }
        // catch (error) {
        //   console.log("catch error", error)
        // }
    }

 const handleDropdownSelect = (eventKey) => {
        if (eventKey === 'takePhoto') {
            setTakePhoto(true);
            setshowDropdown(false)
            setModalVisible(true)
        } else if (eventKey === 'browseGallery') {
            // openFilePicker();
            setUploadPhoto(true)
            setshowDropdown(false)
        }
    };

    const openFilePicker = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = onFileChange;
        input.click();
    };

    // const onFileChange = (event) => {
    //     const selectedFile = event.target.files[0];
    //     // if (selectedFile) {
    //     //     const reader = new FileReader();
    //     //     reader.onload = () => {
    //     //         setUserImg([{ originalname: selectedFile.name, preview: reader.result }]);
    //     //         setTakePhoto(false);
    //     //     };
    //     //     reader.readAsDataURL(selectedFile);


    //     //     const selectedFile = event.target.files[0];
    //     console.log('selectedFile', selectedFile)
    //     if (selectedFile) {
    //         // const formattedFile = {
    //         //     preview: URL.createObjectURL(selectedFile),
    //         //     formattedSize: formatBytes(selectedFile.size),
    //         //     filetype: selectedFile.type,
    //         //     uploadingStatus: 'Not uploaded',
    //         //     originalName: selectedFile.name,
    //         // };
    //         selectedFile.preview = URL.createObjectURL(selectedFile)
    //         selectedFile.formattedSize = formatBytes(selectedFile.size)
    //         selectedFile.filetype = selectedFile.type
    //         selectedFile.originalName = selectedFile.name
    //         console.log('upload image', [selectedFile])
    //         setUserImg([selectedFile])
    //         setModalVisible(true)
    //     }
    //         // console.log('formattedFile', formattedFile)
    //     // }
    // };

    const handleImageLoaded = (image) => {
        console.log('image', image)
        // Handle image loading
    };

    const handleCropComplete = (crop) => {
        console.log('crop', crop)
        // Handle crop completion
    };

    const handleCropChange = (newCrop) => {
        console.log('newCrop', newCrop)
        setCrop(newCrop);
    };

    // const handleCropSave = () => {
    //   console.log('final', crop)
    // };

    // const handleCropSave = () => {
    //     const originalImage = document.getElementById('myImage'); // Replace with the actual image element's ID
      
    //     const canvas = document.createElement('canvas');
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
      
    //     const ctx = canvas.getContext('2d');
    //     ctx.drawImage(
    //       originalImage,
    //       crop.x,
    //       crop.y,
    //       crop.width,
    //       crop.height,
    //       0,
    //       0,
    //       crop.width,
    //       crop.height
    //     );
      
    //     // Export the cropped image as a data URL
    //     const croppedDataURL = canvas.toDataURL('image/jpeg'); // Change format if needed
    //     // Now you can use the data URL or send it to the server, etc.
    //     console.log('Cropped image data URL:', croppedDataURL);
    //   };



    // const handleCropSave = () => {
    //     const originalImage = document.getElementById('myImage'); // Replace with the actual image element's ID
      
    //     const canvas = document.createElement('canvas');
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
      
    //     const ctx = canvas.getContext('2d');
    //     ctx.drawImage(
    //       originalImage,
    //       crop.x,
    //       crop.y,
    //       crop.width,
    //       crop.height,
    //       0,
    //       0,
    //       crop.width,
    //       crop.height
    //     );
      
    //     // Export the cropped image as a data URL
    //     const croppedDataURL = canvas.toDataURL('image/jpeg'); // Change format if needed
      
    //     // Create an object with updated file properties
    //     const updatedFile = Object.assign({}, file, {
    //       "preview": croppedDataURL,
    //       "formattedSize": formatBytes(crop.width * crop.height), // Adjust if needed
    //       "uploading": false,
    //       "filetype": "image/jpeg", // Assuming JPEG format
    //       "uploadingStatus": 'Not uploaded',
    //       "originalName": "cropped_image.jpg", // Adjust the name
    //       "captured_on": new Date(),
    //       "path": "cropped_image.jpg", // Adjust the path
    //     });
      
    //     console.log("updatedFile", updatedFile);
      
    //     // Update user image state
    //     setUserImg([updatedFile]);
      
    //     // Set modal visible
    //     setModalVisible(true);
      
    //     // Create a FormData object
    //     let formData = new FormData();
    //     formData.append('imagesArray', updatedFile);
      
    //     // Now you can use the formData for your upload process
    //     // (e.g., send it to an API endpoint using fetch or axios)
    //   };
      

    // const handleCropSave = (file) => {
    //     const originalImage = document.getElementById('myImage'); // Replace with the actual image element's ID
      
    //     const canvas = document.createElement('canvas');
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
      
    //     const ctx = canvas.getContext('2d');
    //     ctx.drawImage(
    //       originalImage,
    //       crop.x,
    //       crop.y,
    //       crop.width,
    //       crop.height,
    //       0,
    //       0,
    //       crop.width,
    //       crop.height
    //     );
      
    //     // Export the cropped image as a data URL
    //     const croppedDataURL = canvas.toDataURL('image/jpeg'); // Change format if needed
      
    //     // Create an object with updated file properties
    //     const updatedFile = Object.assign({}, file, {
    //         "preview": URL.createObjectURL(file),
    //         "formattedSize": formatBytes(crop.width * crop.height), // Adjust if needed
    //         "uploading": false,
    //         "filetype": file.type,
    //         "uploadingStatus": 'Not uploaded',
    //         "originalName": file.name,
    //         "captured_on": new Date(),
    //         "path": file.name,
    //     });
      
    //     console.log("updatedFile", [updatedFile]);
      
    //   };




    const handleCropSave = (file) => {
        const originalImage = document.getElementById('myImage'); // Replace with the actual image element's ID
      console.log('file', file, originalImage)
        const canvas = document.createElement('canvas');
        canvas.width = crop.width;
        canvas.height = crop.height;
      
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          originalImage,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );
      
        // Create a new Blob with the cropped image data
        canvas.toBlob((blob) => {
          // Create a new File from the Blob
          const updatedFile = new File([blob], file.name, {
            type: 'image/jpeg', // Adjust if needed
          });
          console.log('blob.size', blob)
          // Update the file properties
          updatedFile.preview = URL.createObjectURL(file);
          updatedFile.formattedSize = blob !== null ? formatBytes(blob.size) : file.formattedSize;
          updatedFile.uploading = false;
          updatedFile.uploadingStatus = 'Not uploaded';
          updatedFile.path = file.name;
      
          console.log("updatedFile", [updatedFile], file);
            const formData = new FormData();
            formData.append('files', blob !== null ? updatedFile : file);
            formData.append('encrypted_db_url', dbInfo.encrypted_db_url);
            formData.append('_id', userInfo._id);

            try {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };
                urlSocket.post('storeImage/user-img-upload', formData, config).then((response) => {
                    console.log(response, 'response')
                    if (response.data.response_code === 500) {
                        setUserImg(response.data.data)
                        var session_data = JSON.parse(sessionStorage.getItem('authUser'))
                        session_data["user_data"] = response.data.user_data[0]
                        sessionStorage.setItem('authUser', JSON.stringify(session_data))
                        setModalVisible(false)
                        // sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
                    }
                })

            } catch (error) {

            }


        }, 'image/jpeg'); // Change format if needed
      };


    // const handleCropSave = (selectedFile) => {
    //     const originalImage = document.getElementById('myImage');

    //     const canvas = document.createElement('canvas');
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;

    //     const ctx = canvas.getContext('2d');
    //     ctx.drawImage(
    //         originalImage,
    //         crop.x,
    //         crop.y,
    //         crop.width,
    //         crop.height,
    //         0,
    //         0,
    //         crop.width,
    //         crop.height
    //     );

    //     // Use URL.createObjectURL(selectedFile) as the preview URL
    //     const croppedPreviewURL = URL.createObjectURL(selectedFile);

    //     // Create an object with updated file properties
    //     const updatedFile = Object.assign({}, selectedFile, {
    //         "preview": croppedPreviewURL,
    //         "formattedSize": formatBytes(crop.width * crop.height),
    //         "uploading": false,
    //         "filetype": selectedFile.type, // Assuming JPEG format
    //         // "originalName": croppedPreviewURL,
    //         "path": selectedFile.name,
    //     });

    //     console.log("updatedFile", updatedFile);

    //     // Update user image state
    //     setUserImg([updatedFile]);

    //     // Set modal visible
    //     setModalVisible(true);

    //     let formData = new FormData();
    //     // formData.append('imagesArray', updatedFile);
    //     formData.append('files', updatedFile);
    //     formData.append('encrypted_db_url', dbInfo.encrypted_db_url);
    //     formData.append('_id', userInfo._id);

    //     // try {
    //     //     const config = {
    //     //         headers: {
    //     //             'Content-Type': 'multipart/form-data'
    //     //         }
    //     //     };
    //     //     urlSocket.post('storeImage/user-img-upload', formData, config).then((response) => {
    //     //         console.log(response, 'response')
    //     //         if (response.data.response_code === 500) {
    //     //             setUserImg(response.data.data)
    //     //             var session_data = JSON.parse(sessionStorage.getItem('authUser'))
    //     //             session_data["user_data"] = response.data.user_data[0]
    //     //             sessionStorage.setItem('authUser', JSON.stringify(session_data))
    //     //             // sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
    //     //         }
    //     //     })

    //     // } catch (error) {

    //     // }
    //     // Now you can use the formData for your upload process
    //     // (e.g., send it to an API endpoint using fetch or axios)
    // };
      
   
    // const handleCropSave = (selectedFile) => {
    //     // const originalImage = document.getElementById('myImage');
      
    //     // const canvas = document.createElement('canvas');
    //     // canvas.width = crop.width;
    //     // canvas.height = crop.height;
      
    //     // const ctx = canvas.getContext('2d');
    //     // ctx.drawImage(
    //     //   originalImage,
    //     //   crop.x,
    //     //   crop.y,
    //     //   crop.width,
    //     //   crop.height,
    //     //   0,
    //     //   0,
    //     //   crop.width,
    //     //   crop.height
    //     // );
      


    //     const originalImage = document.getElementById('myImage');
  
    //     const canvas = document.createElement('canvas');
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
        
    //     const ctx = canvas.getContext('2d');
    //     ctx.drawImage(
    //       originalImage,
    //       crop.x, crop.y, crop.width, crop.height,
    //       0, 0, crop.width, crop.height
    //     );


    //     // Convert canvas content to Blob
    //     // canvas.toBlob((blob) => {
    //     //   // Create a new File from the Blob
    //     //   const updatedFile = new File([blob], selectedFile.name, {
    //     //     type: selectedFile.type,
    //     //   });
    //     canvas.toBlob((blob) => {
    //         const updatedFile = new File([blob], selectedFile.name, {
    //           type: selectedFile.type,
    //         });
    //       // Update the file properties
    //       const updatedFileProperties = {
    //         preview: URL.createObjectURL(selectedFile),
    //         formattedSize: formatBytes(blob.size),
    //         uploading: false,
    //         filetype: selectedFile.type,
    //         path: selectedFile.name,
    //       };
      
    //       // Merge updated properties with the updatedFile
    //       Object.assign(updatedFile, updatedFileProperties);
    //       console.log('updatedFile', updatedFile, updatedFileProperties)
      
    //       // Update user image state
    //     //   setUserImg([updatedFile]);
      
    //       // Set modal visible
    //       setModalVisible(true);
      
    //       // Prepare FormData for uploading
    //       const formData = new FormData();
    //       formData.append('files', updatedFile);
    //       formData.append('encrypted_db_url', dbInfo.encrypted_db_url);
    //       formData.append('_id', userInfo._id);
      
    //       console.log("Updated file object:", updatedFile);
    //       console.log("FormData for upload:", formData);
      
    //         //        const formData = new FormData();
    //         // formData.append('files', updatedFile);
    //         // formData.append('encrypted_db_url', dbInfo.encrypted_db_url);
    //         // formData.append('_id', userInfo._id);

    //         try {
    //             const config = {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data'
    //                 }
    //             };
    //             urlSocket.post('storeImage/user-img-upload', formData, config).then((response) => {
    //                 console.log(response, 'response')
    //                 if (response.data.response_code === 500) {
    //                     setUserImg(response.data.data)
    //                     var session_data = JSON.parse(sessionStorage.getItem('authUser'))
    //                     session_data["user_data"] = response.data.user_data[0]
    //                     sessionStorage.setItem('authUser', JSON.stringify(session_data))
    //                     // setModalVisible(false)
    //                     // sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
    //                 }
    //             })

    //         } catch (error) {

    //         }
    //     }, selectedFile.type);
    //   };
      
    const onHideToggle = () => {
        setModalVisible(false)
        setUploadPhoto(false)
        setTakePhoto(false)
       
    }

    const onHideCropModal =()=>{
        setModalVisible(false)
        setUserImg([])
        setUploadPhoto(false)
        setTakePhoto(false)
    }



    const onSubmitProfileInfo = (croppedFile) => {
        const formData = new FormData();
        formData.append('files', croppedFile)
        formData.append('encrypted_db_url', dbInfo.encrypted_db_url);
        formData.append('_id', userInfo._id);
        console.log('final data :', croppedFile)
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            urlSocket.post('storeImage/user-img-upload', formData, config).then((response) => {
                console.log(response, 'response')
                if (response.data.response_code === 500) {
                    setUserImg(response.data.data)
                    var session_data = JSON.parse(sessionStorage.getItem('authUser'))
                    session_data["user_data"] = response.data.user_data[0]
                    sessionStorage.setItem('authUser', JSON.stringify(session_data))
                    // setUploadPhoto(false)
                }
            })

        } catch (error) {

        }

    }


    const handleCloseModal = () => {
        setOpenModal(false);
        setShow0(false);
        setShow1(false);
        setShow2(false);
    };


    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumb title="User Profile" breadcrumbItem="Profile" />
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg="10">
                                <div className="d-flex">                                    
                                        <div className='' id="file-previews">                                         
                                            <ImageCrop db_info={dbInfo} user_Info={userInfo} userImg={userImg} base_url={clientInfo.base_url} onSubmitProfileInfo={(data) => { onSubmitProfileInfo(data) }} />
                                            {
                                                userImg.length > 0 &&
                                                <div style={{textAlign: 'right'}}>
                                                <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteUserImg(userInfo) }} >
                                                    <DeleteTwoTone twoToneColor="red" style={{ fontSize: '14px' }} />
                                                </Popconfirm>
                                                </div>
                                            }
                                            </div>

                                        <div className='' id="file-previews">

                                            {/* {
                                                userImg.length > 0 && userImg[0].originalname !== undefined ?
                                                    <div>
                                                        <img  src={clientInfo.base_url + userImg[0].originalname} alt="Preview" className="rounded-circle header-profile-user avatar-lg" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102px', width: '102px', marginBottom: '8px', marginInlineEnd: '8px'}}/>
                                                    </div>
                                                    :
                                                    !uploadphoto && !takephoto ?
                                                        <div onClick={(e) => e.stopPropagation()} className=" rounded-circle header-profile-user avatar-lg" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '102px', width: '102px', marginBottom: '8px', marginInlineEnd: '8px'}}>
                                                            <Dropdown onSelect={handleDropdownSelect}>
                                                                <Dropdown.Toggle variant="white" id="dropdown-basic" style={{ border: 'none' }} className="hide-dropdown-arrow">
                                                                    <h3>
                                                                    <UploadOutlined /> 
                                                                    </h3>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item eventKey="takePhoto">Take Photo</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="browseGallery">Browse Gallery</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        :
                                                        null
                                            } */}

                                            {userImg.length > 0 &&
                                                <Modal isOpen={modalVisible} toggle={() => setModalVisible(modalVisible)}>
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Crop Image</h5>
                                                        <button type="button" className="close" onClick={() => onHideCropModal()}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                       
                                                        <ReactCrop
                                                            crop={crop}
                                                            onImageLoaded={handleImageLoaded}
                                                            onComplete={handleCropComplete}
                                                            onChange={handleCropChange}
                                                        >
                                                            
                                                            <img src={userImg[0].preview} alt="Preview" style={{ width: "100%" }} id="myImage"/>
                                                        </ReactCrop>

                                                    </div>
                                                    <div className="modal-footer">
                                                        {/* <Button color="secondary" onClick={() => setModalVisible(false)}>
                                                            Cancel
                                                        </Button> */}
                                                        <Button color="primary" onClick={() => handleCropSave(userImg[0])}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                </Modal>
                                            }




                                            {
                                                takephoto ?
                                                    <>
                                                        <label>Take photo</label>
                                                        {/* <Row>
                                                            <WebcamCapture uploadWebCamImage={(data) => { uploadWebCamImage(data) }} />
                                                        </Row> */}
                                                        <Modal isOpen={modalVisible} toggle={() => setModalVisible(true)} style={{ maxWidth: '600px'}}>
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">Take Photo</h5>
                                                                <button type="button" className="close" onClick={() => onHideToggle()}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            {/* <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
                                                            <div className="modal-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                                                               
                                                                        <Row>
                                                                            <WebcamCapture uploadWebCamImage={(data) => { uploadWebCamImage(data) }} />
                                                                        </Row>
                                                                       
                                                            </div>                                                           
                                                        </Modal>
                                                    </>
                                                    :

                                                    uploadphoto ?
                                                    <div>
                                                        <ImgCrop showGrid showReset rotationSlider zoomSlider cropShape="round">
                                                            <Upload
                                                                listType="picture-circle"
                                                                showUploadList={false}
                                                                beforeUpload={onFileChange}
                                                            >                                                               
                                                                    {'+ Click Here'}
                                                              
                                                            </Upload>
                                                        </ImgCrop>
                                                        <div style={{cursor: 'pointer'}} className="mt-2 text-primary" onClick={() => onHideToggle()}>
                                                        <i className="fas fa-arrow-left" ></i> Back
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }                                            
                                          

                                            {/* {
                                                userImg.length > 0 &&
                                                <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteUserImg(userInfo) }}>
                                                    <DeleteTwoTone twoToneColor="red" style={{ fontSize: '15px', display: 'flex', justifyContent: 'right' }} />
                                                </Popconfirm>
                                            } */}

                                        </div>

                                        
                                    <div className="flex-grow-1 align-self-center">
                                        <div className="text-muted" style={{ marginLeft: '15px' }}>
                                            <h5>{name}</h5>
                                            <p className="mb-1" style={{ marginTop: "-6px" }}>{email}</p>
                                            <p className="mb-1" style={{ marginTop: "-5px" }}><span className="text-dark">Login ID :</span> {userInfo.username}</p>
                                        </div>
                                    </div>
                                </div>
                                

                            </Col>
                            <Col lg='2' className="d-flex align-items-center justify-content-end">
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col sm='6'>
                                <Button
                                    outline
                                    color="success"
                                    style={{ paddingLeft: 'inherit' }}
                                    onClick={() => { edit_user_info() }}
                                    type="button"
                                    className="btn-sm waves-effect waves-light mt-2"
                                >
                                    <i className="mdi mdi-pencil font-size-7"></i> Edit User Profile
                                </Button>
                            </Col>

                            <Col sm='6' className="text-end">
                                    <Button
                                        outline
                                        color="primary"
                                        onClick={() => { changePassword() }}
                                        className="btn-sm waves-effect waves-light mt-2"
                                        disabled={previewUser}
                                    >
                                        Change Password
                                    </Button>
                                {/* </div> */}
                            </Col>
                            {/* <Col sm='6' className="d-flex align-items-center justify-content-end">
                                <div className="button-items mb-1 ">
                                    <Button
                                        outline
                                        color="primary"
                                        onClick={() => { changePassword() }}
                                        className="btn-sm waves-effect waves-light"
                                    >
                                        Change Password
                                    </Button>
                                </div>
                            </Col> */}

                        </Row>
                    </CardBody>
                </Card>
                {/* <Row>
                    <Col md={10} className="card-title mb-4" style={{ textAlign: 'left' }}>
                        <button
                            style={{ paddingLeft: 'inherit' }}
                            onClick={() => { edit_user_info() }}
                            type="button"
                            className="btn btn-success  btn-label"
                        >
                            <i className="mdi mdi-pencil font-size-7"></i> Edit User Profile
                        </button>
                    </Col>

                    <Col md={2} className="card-title mb-4" style={{ textAlign: 'right' }}>

                    </Col>
                </Row> */}

                {/* <Row>
                    <Col>
                        <Row >
                            <Col md={2}>
                                <Button color="dark">
                                    Back
                                </Button>
                            </Col>
                        </Row>
                                        
                    </Col>

                    </Row> */}
                   
                    {
                        renderHeader &&
                        <Header/>
                    }

                <Card>
                    <CardBody>
                        <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Row className="my-4">
                                <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>User Information</div>
                                <hr className="my-2" />
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="form-group">
                                        <Label className="form-label">Full Name :<label className="text-danger"> *</label></Label>
                                        <Input
                                            name="firstname"
                                            // value={name}
                                            className="form-control"
                                            placeholder="Enter First Name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.firstname || ""}
                                            disabled={previewUser}
                                            invalid={
                                                validation.touched.firstname && validation.errors.firstname ? true : false
                                            }
                                        />
                                        {validation.touched.firstname && validation.errors.firstname ? (
                                            <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                {/* <Col md={6}>
                                    <div className="form-group">
                                        <Label className="form-label">Last Name :<label className="text-danger"> *</label></Label>
                                        <Input
                                            name="lastname"
                                            className="form-control"
                                            placeholder="Enter Last Name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.lastname || ""}
                                            disabled={previewUser}
                                            invalid={
                                                validation.touched.lastname && validation.errors.lastname ? true : false
                                            }
                                        />
                                        {validation.touched.lastname && validation.errors.lastname ? (
                                            <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col> */}
                            </Row>
                            <br />
                            <Row>
                                <Col md={12}>
                                    <div className="form-group">
                                        <Label className="form-label">Address:</Label>
                                        <Input
                                            name="addrss_1"
                                            className="form-control"
                                            placeholder="Enter Address Name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            disabled={previewUser}
                                            value={validation.values.addrss_1 || ""}
                                        />
                                    </div>

                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={4}>
                                    <div className="form-group">
                                        <Label className="form-label">City:</Label>
                                        <Input
                                            name="city"
                                            className="form-control"
                                            placeholder="Enter City Name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.city || ""}
                                            disabled={previewUser}
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <Label className="form-label">State :</Label>
                                        <Input
                                            name="state"
                                            className="form-control"
                                            placeholder="Enter State Name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.state || ""}
                                            disabled={previewUser}
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <Label className="form-label">Country:</Label>
                                        <Input
                                            name="country"
                                            className="form-control"
                                            placeholder="Enter Country Name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.country || ""}
                                            disabled={previewUser}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Label className="form-label">Email ID :<label className="text-danger"> *</label></Label>
                                        <Input
                                            name="email_id"
                                            className="form-control"
                                            placeholder="Enter Email ID"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.email_id || ""}
                                            disabled={true}
                                            invalid={
                                                validation.touched.email_id && validation.errors.email_id ? true : false
                                            }
                                        />

                                       
                                        {validation.touched.email_id && validation.errors.email_id ? (
                                            <FormFeedback type="invalid">{validation.errors.email_id}</FormFeedback>
                                        ) : null}
                                    </div>
                                    

                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Label className="form-label">Mobile Number :<label className="text-danger"> *</label></Label>
                                        <Input
                                            name="phone_number"
                                            className="form-control"
                                            placeholder="Enter Mobile Number"
                                            type="number"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.phone_number || ""}
                                            disabled={previewUser}
                                            invalid={
                                                validation.touched.phone_number && validation.errors.phone_number ? true : false
                                            }
                                        />
                                        {validation.touched.phone_number && validation.errors.phone_number ? (
                                            <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                                        ) : null}
                                    </div>
                                    {
                                            mobNumExist && 
                                            <div className="text-danger" style={{fontSize:"smaller"}}>
                                                Mobile Number already exist

                                                </div>
                                        }
                                </Col>
                            </Row>
                            <br />
                            <Row className="my-4">
                                <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>Company Information</div>
                                <hr className="my-2" />
                            </Row>
                          
                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Label className="form-label">Qualification:</Label>
                                        <Input
                                            name="qualification"
                                            className="form-control"
                                            placeholder="Enter Qualification"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.qualification || ""}
                                            disabled={previewUser}

                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Label className="form-label">Date of Joining:</Label>
                                        {/* <Input
                                            name="doj"
                                            className="form-control"
                                            placeholder="Enter Date of Joining"
                                            type="date"
                                            onChange={validation.handleChange}
                                            onKeyDown={(e)=>{e.preventDefault()}}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.doj || ""}
                                            disabled={previewUser}

                                        /> */}
                                            <Input
                                                name="doj"
                                                className="form-control"
                                                placeholder="Enter Date of Joining"
                                                // type="text"
                                                type="date"
                                                onChange={validation.handleChange}
                                                onKeyDown={(e) => { e.preventDefault() }}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.doj || ""}
                                                disabled={previewUser}

                                            />
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={4}>
                                    <div className="form-group">
                                        <Label className="form-label">Designation :</Label>
                                        <Input
                                            name="designation"
                                            className="form-control"
                                            placeholder="Enter your Designation"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.designation || ""}
                                            disabled={previewUser}

                                            // invalid={
                                            //     validation.touched.designation && validation.errors.designation ? true : false
                                            // }
                                        />
                                        {/* {validation.touched.designation && validation.errors.designation ? (
                                            <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
                                        ) : null} */}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <Label className="form-label">Experience:</Label>
                                        <Input
                                            name="experience"
                                            className="form-control"
                                            placeholder="Enter your Experience"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.experience || ""}
                                            disabled={previewUser}

                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <Label className="form-label">User Code:</Label>
                                        <Input
                                            name="usercode"
                                            className="form-control"
                                            placeholder="Enter your User Code"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.usercode || ""}
                                            disabled={previewUser}

                                            invalid={
                                                validation.touched.usercode && validation.errors.usercode ? true : false
                                            }
                                        />
                                        {validation.touched.usercode && validation.errors.usercode ? (
                                            <FormFeedback type="invalid">{validation.errors.usercode}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                                <br/>
                                <br />
                            {/* <Label>Upload Company Logo:</Label>
                            <Row>
                                <Col md={3}>                        
                                {
                                // console.log(userInfo,"user info",clientInfo)
                                userInfo.role == "Super Admin" &&
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                            {
                                                Object.keys(clientInfo.client_logo).length > 0 ?


                                                    <Card style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12} style={{ textAlign: 'center' }}>
                                                                    <Image className="text-center" src={clientInfo.base_url + clientInfo.client_logo.originalname} width={'50%'} height={'auto'} alt="Thumbnail" />
                                                                </Col>
                                                                <div className="mt-2 contentInfo text-center">
                                                                    <Label style={{ marginBottom: 0 }}>File name: <span>{clientInfo.client_logo.originalname}</span></Label><br />
                                                                    <Label style={{ marginBottom: 0 }}>Size: <span>{clientInfo.client_logo.formattedSize}</span></Label>
                                                                </div>
                                                                <div className='mt-2 text-end'>
                                                                    <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(clientInfo.client_logo) }}>
                                                                        <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
                                                                    </Popconfirm>
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>

                                                        :
                                                        <>
                                                        </>

                                                }
                                            </div>

                                
                                
                            }
                                
                                </Col>


                            </Row> */}
                         {  userInfo.role === "Super Admin" && <Label>Upload Company Logo:</Label>}
                            <Row>
                                <Col md={3}>

                                    {
                                        showUpload && userInfo.role === "Super Admin" ?
                                            <div className={loading ? "loading-container" : ""}>
                                                <div className={`dropzone ${loading ? "blur" : ""}`}>
                                                    <Dropzone
                                                        onDrop={(acceptedFiles) => {
                                                            handleAcceptedFiles(acceptedFiles);
                                                        }}
                                                        disabled={previewUser}
                                                        accept={[".jpg", ".jpeg", ".png"]}
                                                        className={previewUser ? "dropzone-disabled" : ""}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div className={`dz-message needsclick mt-2 ${previewUser ? "dropzone-blur" : ""}`} {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                </div>
                                                                <h4>Drop files here or click to upload.</h4>
                                                                <div className="mt-2 font-size-11 text-dark text-center">
                                                                    <span className="text-danger">Files accepted - .jpg, .jpeg, .png </span> <br />
                                                                    <span className="text-danger">Maximum individual file size - 1mb</span>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                                {loading && <div className="loading">Loading...</div>}
                                            </div>
                                            :

                                            userInfo.role === "Super Admin" && (Object.keys(clientInfo.client_logo
                                            ).length > 0) ?

                                                <div className="dropzone-previews mt-3" id="file-previews">
                                                    <Card  style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12} style={{ textAlign: 'center' }}>
                                                                    <Image  src={clientInfo.base_url + clientInfo.client_logo.originalname} width={'50%'} height={'auto'} alt="Thumbnail" />
                                                                </Col>
                                                                <div className="mt-2 contentInfo text-center">
                                                                    <Label style={{ marginBottom: 0 }}>File name: <span>{clientInfo.client_logo.originalname}</span></Label><br />
                                                                    <Label style={{ marginBottom: 0 }}>Size: <span>{clientInfo.client_logo.formattedSize}</span></Label>
                                                                </div>
                                                                <div className='mt-2 text-end'>
                                                                <span style={{ pointerEvents: previewUser ? 'none' : 'auto' }}>
                                                                    <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(clientInfo.client_logo) }}>
                                                                        <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
                                                                    </Popconfirm>
                                                                    </span>
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>


                                                </div>
                                                :
                                                selectedFiles.map((f, i) => {
                                                    return (
                                                        // <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"} >
                                                        <div key={i + "-file"} >
                                                            <Card style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md={12} style={{ textAlign: 'center' }}>
                                                                            <Image className="text-center" src={f.preview} width={'50%'} height={'auto'} alt="Thumbnail" />
                                                                        </Col>
                                                                        <div className="mt-2 contentInfo text-center">
                                                                            <Label style={{ marginBottom: 0 }}>File name: <span>{f.name}</span></Label><br />
                                                                            <Label style={{ marginBottom: 0 }}>Size: <span>{f.formattedSize}</span></Label>
                                                                        </div>
                                                                        <div className='mt-2 text-end'>
                                                                            <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(f) }}>
                                                                                <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
                                                                            </Popconfirm>
                                                                        </div>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </div>
                                                    )
                                                })
                                    }
                                </Col>
                            </Row>

                            <br />
                            {/* <Row className="my-4">
                                <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>Login Information</div>
                                <hr className="my-2" />
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Label className="form-label">User Name :<label className="text-danger"> *</label></Label>
                                        <Input
                                            name="username"
                                            // value={name}
                                            className="form-control"
                                            placeholder="Enter User Name"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.username || ""}
                                            disabled={true}
                                            invalid={
                                                validation.touched.username && validation.errors.username ? true : false
                                            }
                                        />
                                        {validation.touched.username && validation.errors.username ? (
                                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                        ) : null}
                                        <Input name="idx" value={idx} type="hidden" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <br />
                                    <Button color="primary"
                                        onClick={() => { changePassword() }}
                                        style={{ marginTop: '14px' }}
                                        className="btn-rounded ">
                                        Change Password
                                    </Button>
                                </Col>
                            </Row> */}
                            <br />
                            <Row>
                                <Col xl={10}>

                                </Col>
                                <Col xl={2} className="text-end">
                                <Button type="submit" color="danger" disabled={previewUser === true? true : false}>
                                    Update User Info
                                </Button>
                                    </Col>
                            </Row>
                            
                        </Form>
                        {openModal &&
                            <Modal
                                size="xl"
                                isOpen={openModal}
                                toggle={() => {
                                    changePassword("1");
                                }}
                                backdrop="static"
                                centered
                            >
                                <Row>
                                    <Col xl={12} md={12} sm={12}>
                                <div className="modal-header">
                                    <h5
                                        className="modal-title mt-0"
                                    >
                                        Change Password
                                    </h5>
                                    <button
                                        onClick={handleCloseModal}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <br />

                                <Row>
                                    <Col md={1}>
                                      <label style={{ marginLeft: '17px' }}>Email ID:</label>

                                    </Col>
                                    <Col md={2}>
                                        <label>{userInfo.email_id}</label>
                                    </Col>
                                </Row>
                                <br />
                                <Form className="needs-validation"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        changepwd.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col xl={11} md={10} sm={8}>

                                            <FormGroup style={{ marginLeft: '18px' }}>
                                                <Label >Current Password:</Label>
                                                <Input
                                                    name="current_password"
                                                    style={{ width: "106%" }}
                                                    placeholder="Current Password"
                                                    type={show0 ? "text" : "password"}
                                                    className="form-control"
                                                    onChange={changepwd.handleChange}
                                                    onBlur={changepwd.handleBlur}
                                                    value={changepwd.values.current_password || ""}
                                                    invalid={
                                                        changepwd.touched.current_password && changepwd.errors.current_password ? true : false
                                                    }
                                                />
                                                {changepwd.touched.current_password && changepwd.errors.current_password ? (
                                                    <FormFeedback type="invalid">{changepwd.errors.current_password}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                            <Col xl={1} md={2} sm={2} style={{ marginTop: '27px' }}>
                                            <button onClick={() => setShow0(!show0)} className="btn btn-light " type="button">
                                                    <i className={show0 ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                        <Col xl={11} md={10} sm={8}>
                                            <FormGroup style={{ marginLeft: '18px' }}>
                                                <Label >New Password:</Label>
                                                <Input
                                                    name="new_password"
                                                    placeholder="New Password"
                                                    style={{ width: "106%" }}
                                                    type={show1 ? "text" : "password"}
                                                    className="form-control"
                                                    onChange={changepwd.handleChange}
                                                    onBlur={changepwd.handleBlur}
                                                    value={changepwd.values.new_password || ""}
                                                    invalid={
                                                        changepwd.touched.new_password && changepwd.errors.new_password ? true : false
                                                    }
                                                />
                                                {changepwd.touched.new_password && changepwd.errors.new_password ? (
                                                    <FormFeedback type="invalid">{changepwd.errors.new_password}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xl={1} md={2} sm={2} style={{ marginTop: '27px' }}>
                                            <button onClick={() => setShow1(!show1)} className="btn btn-light " type="button">
                                                    <i className={show1 ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                        <Col xl={11} md={10} sm={8}>
                                            <FormGroup style={{ marginLeft: '18px' }}>
                                                <Label >Confirm Password:</Label>
                                                <Input
                                                    name="confirm_password"
                                                    placeholder="Confirm Password"
                                                    style={{ width: "106%" }}
                                                    type={show2 ? "text" : "password"}
                                                    className="form-control"
                                                    onChange={changepwd.handleChange}
                                                    onBlur={changepwd.handleBlur}
                                                    value={changepwd.values.confirm_password || ""}
                                                    invalid={
                                                        changepwd.touched.confirm_password && changepwd.errors.confirm_password ? true : false
                                                    }
                                                />                                                  
                                                {changepwd.touched.confirm_password && changepwd.errors.confirm_password ? (
                                                    <FormFeedback type="invalid">{changepwd.errors.confirm_password}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xl={1} md={2} sm={2} style={{ marginTop: '27px' }}>
                                            <button onClick={() => setShow2(!show2)} className="btn btn-light " type="button">
                                                    <i className={show2 ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
                                            </Col>
                                        </Row>
                                        <br/>
                                    <Row>
                                        <Col xl={9} md={7} sm={5}>
                                        </Col>
                                        <Col xl={3} md={5}  sm={7}>
                                            <Button type="submit" color="danger" style={{ marginLeft: '10px' }} onClick={handleCloseModal} >Cancel</Button>
                                            {/* <Button type="submit" color="success" className="ms-2" >Change Password</Button> */}
                                                        <Button
                                                            type="submit"
                                                            color="success"
                                                            className="ms-2"
                                                            disabled={loading}
                                                            // onClick={(e) => {
                                                            //     if (!loading) {
                                                            //         setLoading(true);
                                                            //         changepwd.handleSubmit(e);
                                                            //     }
                                                            // }}
                                                        >
                                                            {loading ? (
                                                                <>
                                                                    <LoadingOutlined style={{ marginRight: '0.5rem' }} />
                                                                    Loading... </>
                                                            ) : ('Change Password')}
                                                        </Button>
                                        </Col>

                                    </Row>
                                </Form>
                                </Col>
                                </Row>
                                <br />
                                <br />

                            </Modal>
                        }


                    </CardBody>
                </Card>
            </Container>
        </div>
    </React.Fragment>

);
};

export default UserProfile










///// 17-8-23 take poto and browse gallery 
// import React, { useState, useEffect } from "react";
// import {
//     Container,
//     Row,
//     Col,
//     Card,
//     Alert,
//     CardBody,
//     Button,
//     Label,
//     Input,
//     FormFeedback,
//     Form,
//     Modal, FormGroup
// } from "reactstrap";
// import CryptoJS from 'crypto-js'
// import { Space, Spin,Upload, Image, Popconfirm } from 'antd';
// // Formik Validation
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import Swal from 'sweetalert2';
// import { LoadingOutlined, DeleteTwoTone, } from '@ant-design/icons'
// import Dropzone from "react-dropzone";
// import reactImageSize from 'react-image-size';
// import Resizer from 'react-image-file-resizer'
// import Header from "../VerticalLayout/Header";
// //redux
// // import { useSelector, useDispatch } from "react-redux";

// //Import Breadcrumb
// import Breadcrumb from "../../components/Common/Breadcrumb";

// import ImgCrop from "antd-img-crop";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// // import "antd/dist/antd.css";


// import avatar from "../../assets/images/149071.png";
// import urlSocket from "../../helpers/urlSocket";
// import { useHistory } from 'react-router-dom';
// // actions

// const UserProfile = () => {
//     const history = useHistory();
//     //meta title
//     document.title = "Profile | AuditVista";

//     const [email, setemail] = useState("");
//     const [name, setname] = useState("");
//     const [idx, setidx] = useState(1);
//     const [previewUser, setPreviewUser] = useState(true)
//     const [userInfo, setUserInfo] = useState({})
//     const [openModal, setOpenModal] = useState(false)
//     const [show, setShow] = useState(false);
//     const [dbInfo, setDbInfo] = useState({})
//     const [clientInfo, setClientInfo] = useState({})
//     const [selectedFiles, setselectedFiles] = useState([]);
//     const [secret_key, setSecret_key] = useState('Pkdh347ey%3Tgs')
//     const [showUpload, setshowUpload] = useState(false);
//     const [renderHeader, setrenderHeader] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [required_img_width,set_required_img_width] = useState(1700)
//     const [required_img_height,set_required_img_height] = useState(1700)
//     const [userImg, setUserImg] = useState([]);



//     useEffect(() => {
//         var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//         var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
//         console.log(client_info,'client_info')
//         setshowUpload(client_info.client_logo === undefined || client_info.client_logo === null  ? true : false)
//         console.log(db_info, 'db_info')
//         setDbInfo(db_info)
//         setClientInfo(client_info)
//         if (sessionStorage.getItem("authUser")) {
//             const obj = JSON.parse(sessionStorage.getItem("authUser"));
//             console.log(obj, 'obj')
//             session_data(obj)
//             if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//                 setname(obj.displayName);
//                 setemail(obj.user_data.email);
//                 setidx(obj.uid);
//             } else if (
//                 process.env.REACT_APP_DEFAULTAUTH === "fake" ||
//                 process.env.REACT_APP_DEFAULTAUTH === "jwt"
//             )
//                 //   {
//                 //     setname(obj.username);
//                 //     setemail(obj.email);
//                 //     setidx(obj.uid);
//                 //   }
//                 setTimeout(() => {
//                     // dispatch(resetProfileFlag());
//                 }, 3000);
//         }
//     }, []);

//     const session_data = (data) => {
//         console.log(data, 'data')
//         setUserInfo(data.user_data)
//         setUserImg(data.user_data.user_img !== undefined ? data.user_data.user_img :[])
//         setname(data.user_data.firstname)
//         setemail(data.user_data.email_id);
//         setidx(data.uid);

//     }


//     const changepwd = useFormik({
//         enableReinitialize: true,
//         initialValues: {
//             current_password: '',
//             new_password: '',
//             confirm_password: ''
//         },
//         validationSchema: Yup.object({
//             current_password: Yup.string().required("Please Enter Your Current Password"),
//             new_password: Yup.string().min(8, "Password must be at least 8 characters long")
//                 .matches(
//                     /^(?=.*[A-Z]).+$/,
//                     "Password must contain at least one uppercase letter"
//                 )
//                 .required("Please Enter the New Password"),
//             confirm_password: Yup.string().oneOf([Yup.ref("new_password"), null], "Passwords must match")
//                 .required("Please Confirm Your New Password"),
//         }),
//         onSubmit: (values) => {
//             console.log(values, dbInfo, clientInfo)
//             values["encrypted_db_url"] = dbInfo.encrypted_db_url
//             values["db_name"] = dbInfo.db_name
//             values["userPoolId"] = clientInfo.userPoolId
//             values["clientId"] = clientInfo.clientId
//             var curr_pwd = CryptoJS.AES.encrypt(values.current_password, secret_key).toString()
//             var new_pwd = CryptoJS.AES.encrypt(values.new_password, secret_key).toString()
//             values["current_password"] = curr_pwd
//             values["new_password"] = new_pwd
//             values["confirm_password"] = new_pwd
//             values["email_id"] = userInfo.email_id
//             console.log(values, dbInfo, clientInfo)

//             try {
//                 urlSocket.post('cog/change-pwd', values).then((res) => {
//                     console.log(res, 'res')
//                     if (res.data.data.response_code === 500) {
//                         // window.location.reload()
//                         Swal.fire({
//                             icon: 'success',
//                             title: 'Success!',
//                             text: 'Your request has been processed successfully.',
//                             confirmButtonColor: '#3085d6',
//                             confirmButtonText: 'OK'
//                         }).then((result) => {
//                             console.log(result, 'result')
//                             if (result.isConfirmed) {
//                                 sessionStorage.clear()
//                                 window.location.reload();
//                             }
//                         })
//                     }
//                 })
//             } catch (error) {
//                 console.log(error, 'error')
//             }
//         }
//     })


//     // })

//     const validation = useFormik({
//         enableReinitialize: true,

//         initialValues: {
//             username: userInfo.username || '',
//             firstname: userInfo.firstname || '',
//             // lastname: userInfo.lastname || '',
//             addrss_1: userInfo.addrss_1 || '',
//             city: userInfo.city || '',
//             state: userInfo.state || '',
//             country: userInfo.country || '',
//             email_id: userInfo.email_id || '',
//             phone_number: userInfo.phone_number || '',
//             qualification: userInfo.qualification || '',
//             doj: userInfo.doj || '',
//             designation: userInfo.designation || '',
//             experience: userInfo.experience || '',
//             usercode: userInfo.usercode || '',
//             password: userInfo.password || '',
//         },
//         validationSchema: Yup.object({
//             username: Yup.string().required("Please Enter Your UserName"),
//             firstname: Yup.string().required("Please Enter Your firstname"),
//             // lastname: Yup.string().required("Please Enter Your lastname"),
//             email_id: Yup.string().required("Please Enter Your Email Id"),
//             phone_number: Yup.string().required("Please Enter Your Mobile Number"),
//             // designation: Yup.string().required("Please Enter Your Designation"),
//             // usercode: Yup.string().required("Please Enter Your firstname"),
//             // password: Yup.string().required("Please Enter Your Password"),
//         }),
//         onSubmit: (values) => {
//             // var password = CryptoJS.AES.encrypt(values.password, secret_key).toString()
//             values["password"] = userInfo.password
//             values["confirmpassword"] = userInfo.password
//             values["userPoolId"] = clientInfo.userPoolId
//             values["clientId"] = clientInfo.clientId
//             values["encrypted_db_url"] = dbInfo.encrypted_db_url
//             values["db_name"] = dbInfo.db_name
//             values["facilities"] = userInfo.facilities
//             values["_id"] = userInfo._id
//             values["status"] = userInfo.status
//             values["active"] = userInfo.active
//             values["company_id"] = userInfo.company_id
//             values["role_id"] = userInfo.role_id
//             values["role_name"] = userInfo.role_name
//             values["user_id"] = userInfo._id




//             console.log(values)
//             try {
//                 urlSocket.post('cog/cruduser', values).then((res) => {
//                     console.log(res, 'res')
//                     if (res.data.response_code === 500) {
//                         Swal.fire({
//                             icon: 'success',
//                             title: 'Success!',
//                             text: 'User Information has been saved successfully.',
//                             confirmButtonColor: '#3085d6',
//                             confirmButtonText: 'OK'
//                         })
//                             .then((result) => {
//                                 console.log(result, 'result')
//                                 if (result.isConfirmed) {
//                                     window.location.reload();
//                                 }
//                             })
//                         // window.location.reload()
//                     }
//                 })

//             } catch (error) {
//                 console.log(error, 'error')
//             }

//             //   dispatch(editProfile(values));
//         }
//     });

//     const  imageSizeValid = (imageUrl) => {
//         console.log(imageUrl,'imageUrl')
//         try {
//           // const { width, height } = await reactImageSize(imageUrl)
//           return reactImageSize(imageUrl)
//             .then(({ width, height }) => {
//               console.log({ width, height })
//               let data = { width, height }
//               data["width"]=width
//               data["height"]=height
//               return data
//             })
//             .catch((error) => {
//               console.log(error)
//             })
//         } catch {
   
//         }
//       }



//     // async function handleAcceptedFiles(files) {
//     //     console.log(files, 'files')
//     //     const formData = new FormData();
//     //     clientInfo["client_logo"] = {}
//     //     files.map(async file => {
//     //         const formattedFile = Object.assign(file, {
//     //             preview: URL.createObjectURL(file),
//     //             formattedSize: formatBytes(file.size),
//     //             filetype: file.type,
//     //             uploadingStatus: 'Not uploaded',
//     //             originalName: file.name,
//     //         });

//     //         let image_size = await imageSizeValid(files[0].preview)
//     //         formattedFile["width"] = image_size.width
//     //         formattedFile["height"] = image_size.height
//     //         console.log(image_size, 'image_size', formattedFile)

//     //         formData.append('files', formattedFile);
//     //         if (clientInfo._id !== undefined) {
//     //             console.log("update_img", clientInfo._id)
//     //             formData.append('client_logo', clientInfo._id);
//     //             formData.append('email_id', clientInfo.email_id);
//     //             formData.append('encrypted_db_url',dbInfo.encrypted_db_url );
//     //             // formData.append('client_info', state);
//     //             // var client_info = state
//     //         }
//     //         console.log(image_size, files, files[0].size > 500000 && files[0].size <= 1000000)
//     //         if (files[0].size > 500000 && files[0].size <= 1000000) {
//     //             console.log(image_size, 'image_size', files)
//     //             Swal.fire({
//     //                 icon: 'warning',
//     //                 title: 'Warning!',
//     //                 text: `Image Size is more than 1MB `,
//     //                 confirmButtonColor: '#3085d6',
//     //                 confirmButtonText: 'OK'
//     //             }).then((result) => {
//     //                 console.log(result, 'result')
//     //                 // getClientList()


//     //             })
//     //         }

//     //         // formData.append('files', formattedFile);
//     //         if (image_size.width < required_img_width && image_size.height < required_img_height) {
//     //             console.log('Matched', files)
//     //             try {
//     //                 const config = {
//     //                     headers: {
//     //                         'Content-Type': 'multipart/form-data'
//     //                     }
//     //                 };
//     //                 urlSocket.post('storeImage/logo_awswebupload', formData, config).then((response) => {
//     //                     console.log(response, 'response')
//     //                     if (response.data.response_code === 500) {
//     //                         var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//     //                         session_data.client_info[0]["client_logo"]=response.data.client_logo
//     //                         var client_data = JSON.parse(sessionStorage.getItem('client_info'))
//     //                         client_data[0]["client_logo"]=response.data.client_logo
//     //                         sessionStorage.setItem('authUser',JSON.stringify(session_data))
//     //                         sessionStorage.setItem('client_info',JSON.stringify(client_data))
//     //                         setshowUpload(false)
//     //                         // clientInfo["client_logo"]=response.data.data[0]
//     //                     }
//     //                 })

//     //             } catch (error) {

//     //             }
//     //         }
//     //          else {
//     //             console.log('Not Matched')
//     //             Swal.fire({
//     //                 icon: 'warning',
//     //                 title: 'Warning!',
//     //                 text: `Please Upload image size exactly on ${required_img_width}px X ${required_img_height}px`,
//     //                 confirmButtonColor: '#3085d6',
//     //                 confirmButtonText: 'OK'
//     //             }).then((result) => {
//     //                 console.log(result, 'result')
//     //                 // getClientList()


//     //             })
//     //             // this.toastWarning(`Please Upload image size exactly on ${this.state.required_img_width}px X ${this.state.required_img_height}px`)
//     //             return
//     //         }

//     //     });

//     //     setselectedFiles(files)
//     //     console.log(files)


//     // }



//     async function handleAcceptedFiles(files) {
//         setLoading(true)
//         const formData = new FormData();
//         clientInfo["client_logo"] = {}
//         files.map(async file => {
//             const formattedFile = Object.assign(file, {
//                 preview: URL.createObjectURL(file),
//                 formattedSize: formatBytes(file.size),
//                 filetype: file.type,
//                 uploadingStatus: 'Not uploaded',
//                 originalName: file.name,
//             });
   
//             let image_size = await imageSizeValid(files[0].preview)
//             formattedFile["width"] = image_size.width
//             formattedFile["height"] = image_size.width
//             console.log(image_size, 'image_size', formattedFile)  
//             formData.append('files', formattedFile);
//             if (clientInfo._id !== undefined) {
//                 console.log("update_img", clientInfo._id)
//                 formData.append('client_logo', clientInfo._id);  
//                 formData.append('email_id', clientInfo.email_id);
//                 formData.append('short_name', clientInfo.short_name);
//                 formData.append('encrypted_db_url',dbInfo.encrypted_db_url );          
//             }
//             if (files[0].size > 1000000) {
//                 setLoading(false)
//                 Swal.fire({
//                     icon: 'warning',
//                     title: 'Warning!',
//                     text: `Image Size is more than 1MB `,
//                     confirmButtonColor: '#3085d6',
//                     confirmButtonText: 'OK'
//                 }).then((result) => {
//                     console.log(result, 'result')
//                     // getClientList()
   
//                 })
//             }
//             else{
//             try {
//                 const config = {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 };
//                 urlSocket.post('storeImage/logo_awswebupload', formData, config).then(async(response) => {
//                     console.log(response, 'response')
//                     if (response.data.response_code === 500) {
//                         // setshowUpload(false)
//                         // formData.append('client_logo', state._id);
//                         var upload = await updt_rszd_img(formattedFile)
//                         console.log(upload,'upload')
//                         var rszed_img = await rszed_upload_img(upload,config,files)
   
   
//                     }
//                 })
   
//             } catch (error) {
   
//             }
//         }
//         });
   
//     }
   
//         async function rszed_upload_img(upload, config,files) {
//             console.log(files,'files')
//             urlSocket.post('storeImage/logo_awswebupload', upload, config).then(async (response) => {
//                 console.log(response, 'response')
//                 if(response.data.response_code === 500){
//                      var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//                     var client_data = JSON.parse(sessionStorage.getItem('client_info'))
//                     client_data = response.data.client_info
//                     session_data.client_info = response.data.client_info
//                     console.log(session_data,'session_data')
//                     sessionStorage.setItem('client_info',JSON.stringify(client_data))
//                     // var client_data = JSON.parse(sessionStorage.getItem('client_info'))
//                     // client_data[0]["client_logo_rzd"]=response.data.client_logo
//                     sessionStorage.setItem('authUser',JSON.stringify(session_data))
//                     // sessionStorage.setItem('client_info',JSON.stringify(client_data))
//                     setLoading(false)
//                     setshowUpload(false)
//                     setselectedFiles(files)
//                     history.push(window.location.pathname);
//                 }
//             })
   
   
//         }
   
   
   
   
   
   
   
   
   
//         async function updt_rszd_img(files){
   
//             console.log(files,'files')
//             const formData = new FormData()
//             if(clientInfo._id !== undefined){
//                 formData.append('client_logo',clientInfo._id)
//             }
   
//             if (files.size > 500000 && files.size <= 1000000) {
   
//                 const resizedFile = await resizeImage(files, 0.8, 512, 512)
//                 resizedFile.formattedSize= formatBytes(resizedFile.size)
//                 resizedFile.preview= URL.createObjectURL(resizedFile)
               
//                 console.log('resizedFile', resizedFile,resizedFile.name +"_rzd")
//                 const dotIndex = resizedFile.name.lastIndexOf('.');
//                 const modifiedFilename = resizedFile.name.slice(0, dotIndex) + "_rzd" + resizedFile.name.slice(dotIndex);
//                 resizedFile["originalname"]= modifiedFilename
               
//                 formData.append('files', resizedFile);
//                 formData.append('email_id', clientInfo.email_id);
//                 formData.append('short_name', clientInfo.short_name);
//                 formData.append('encrypted_db_url',dbInfo.encrypted_db_url );
//                 formData.append('resized', true);    
//                 return formData
   
//             } else {
//                 formData.append('email_id', clientInfo.email_id);
//                 formData.append('encrypted_db_url',dbInfo.encrypted_db_url );
//                 formData.append('short_name', clientInfo.short_name);
//                 formData.append('resized', true);    
//                 formData.append('files', files);
//                 return formData
   
//             }    
   
//         }
   
//         async function resizeImage(file, resizeRatio, targetWidth, targetHeight) {
//             const dotIndex = file.name.lastIndexOf('.');
//             const modifiedFilename = file.name.slice(0, dotIndex) + "_rzd" + file.name.slice(dotIndex);
//             var originalName =modifiedFilename
//             return new Promise((resolve, reject) => {
//                 Resizer.imageFileResizer(
//                     file,
//                     targetWidth,
//                     targetHeight,
//                     'JPEG',
//                     80,
//                     0,
//                     (resizedFile) => {
//                         const modifiedResizedFile = new File([resizedFile], originalName, { type: resizedFile.type });
//                         resolve(modifiedResizedFile);
//                     },
//                     'blob'
//                 );
//             });
//         }
   
//     function formatBytes(bytes, decimals = 2) {
//         if (bytes === 0) return "0 Bytes"
//         const k = 1024
//         const dm = decimals < 0 ? 0 : decimals
//         const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

//         const i = Math.floor(Math.log(bytes) / Math.log(k))
//         return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
//     }


//     function deleteImage(data) {
//         console.log(data, 'data')
//         if (Object.keys(clientInfo.client_logo).length > 0) {
//             delete clientInfo["client_logo"]
//             console.log(clientInfo,'state')
//             var delete_data ={}
//             delete_data["userPoolId"] = clientInfo.userPoolId
//             delete_data["clientId"] = clientInfo.clientId
//             delete_data["_id"] = clientInfo._id
//             delete_data["email_id"] = clientInfo.email_id
//             delete_data["short_name"] = clientInfo.short_name
//             delete_data["encrypted_db_url"] = dbInfo.encrypted_db_url
//             delete_data["db_name"] = dbInfo.db_name
//             urlSocket.post('webmngtmplt/delete-client-logo', { "client_info": delete_data }).then((response) => {
//                 console.log(response, 'response')
//                 setselectedFiles([])
//                 if(response.data.response_code === 500){
//                     Swal.fire({
//                         icon: 'success',
//                         title: 'Success!',
//                         text: `Image has been deleted successfully`,
//                         confirmButtonColor: '#FF0000',
//                         confirmButtonText: 'OK'
//                     }).then((result) => {
//                         console.log(result, 'result')
//                         var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//                         session_data["client_info"] = response.data.client_data
//                         sessionStorage.setItem('authUser',JSON.stringify(session_data))
//                         sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
//                         if (result.isConfirmed) {
//                             setrenderHeader(true)
//                         }
//                         // getClientList()
   
//                     })

//                 }
//                 // if(response.data.response_code === 500){
//                 //         var values ={}
//                 //         values[]
//                 //     try {
//                 //         urlSocket.post('cog/login-authenticate', values).then((res) => {


//                 //         })
//                 //     }
//                 //     catch(error){
//                 //         console.log(error,'erro')
//                 //     }


//                 // }
//                 setshowUpload(true)


//             })
//         }
//         else {
//             setselectedFiles([])
//         }
//         setshowUpload(true)
//     }


//     const edit_user_info = () => {
//         console.log("edit usersss")
//         setPreviewUser(false)
//     }

//     const changePassword = (data) => {
//         console.log("change passowrd")
//         changepwd.resetForm();
//         setOpenModal(true)
//         if (data === "1") {
//             setOpenModal(!openModal)
//         }

//     }

//     const onFileChange = (files) => {
//         console.log('e', [files]);
//         [files].map(async file => {
//             const formattedFile = Object.assign(file, {
//                 preview: URL.createObjectURL(file),
//                 formattedSize: formatBytes(file.size),
//                 filetype: file.type,
//                 uploadingStatus: 'Not uploaded',
//                 originalName: file.name,
//             });
//             const formData = new FormData();
//             console.log(formattedFile,'formattedFile')
//             formData.append('files',formattedFile)
//             formData.append('encrypted_db_url',dbInfo.encrypted_db_url)
//             formData.append('_id',userInfo._id)

//             try {
//                 const config = {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 };
//                 urlSocket.post('storeImage/user-img-upload',formData,config).then((response)=>{
//                     console.log(response,'response')
//                     if(response.data.response_code === 500){
//                         setUserImg(response.data.data)
//                         var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//                         session_data["user_data"] = response.data.user_data[0]
//                         sessionStorage.setItem('authUser',JSON.stringify(session_data))
//                         // sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
//                     }
//                 })
               
//             } catch (error) {
               
//             }
//         })
//         console.log('e', [files]);
//     }

//     const deleteUserImg =(data)=>{
//         console.log("user image delete",data)
//         var obj_data ={}
//         obj_data["user_data"]=data
//         obj_data["encrypted_db_url"]=dbInfo.encrypted_db_url

//         try {
//                 urlSocket.post('storeImage/user-delete-img',obj_data).then((response)=>{
//                     console.log(response,'response')
//                     if(response.data.response_code == 500){
//                         Swal.fire({
//                             icon: 'success',
//                             title: 'Deleted Successfully',
//                             text: 'Profile Image Deleted successfully',
//                             confirmButtonColor: '#3085d6',
//                             confirmButtonText: 'Ok',
//                         }).then((result) => {
//                             if(result.isConfirmed){
//                                 console.log("success")
//                                 var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//                                 session_data["user_data"] = response.data.user_data[0]
//                                 sessionStorage.setItem('authUser',JSON.stringify(session_data))
//                                 setUserImg([])
//                             }

//                         })
//                     }
//                 })
//         } catch (error) {
           
//         }


//     }



//     return (
//         <React.Fragment>
//         <div className="page-content">
//             <Container fluid>
//                 {/* Render Breadcrumb */}
//                 <Breadcrumb title="Skote" breadcrumbItem="Profile" />
//                 <Card>
//                     <CardBody>
//                         <Row>
//                             <Col lg="10">
//                                 <div className="d-flex">
//                                     {/* <div className="ms-3">
//                                         <img
//                                             src={avatar}
//                                             alt=""
//                                             className="avatar-md rounded-circle img-thumbnail"
//                                         />
//                                     </div> */}
//                                             <div className="ms-3" id="file-previews">
//                                             <ImgCrop showGrid showReset rotationSlider zoomSlider cropShape="round">
//                                                 <Upload
//                                                     listType="picture-circle"
//                                                     showUploadList={false}
//                                                     beforeUpload={onFileChange}
//                                                 >
//                                                     {
//                                                         console.log(userImg,'userImg')
//                                                     }
//                                                     {
//                                                         // userImg !== undefined ?
//                                                         // <>
//                                                             userImg.length > 0 ?
//                                                                 <div>
//                                                                     <img src={clientInfo.base_url  + userImg[0].originalname} alt="Preview" className="rounded-circle" style={{ width: "100%" }} />
//                                                                 </div>
                                                               
                                                           
//                                                         // </>
//                                                         :
//                                                         "+ Upload"
//                                                     }

//                                                 </Upload>
//                                             </ImgCrop>
//                                             {
//                                                  userImg.length > 0  &&
//                                                 <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteUserImg(userInfo) }}>
//                                                     <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//                                                 </Popconfirm>
//                                             }

//                                             </div>

                                       
//                                     <div className="flex-grow-1 align-self-center">
//                                         <div className="text-muted" style={{ marginLeft: '15px' }}>
//                                             <h5>{name}</h5>
//                                             <p className="mb-1" style={{ marginTop: "-6px" }}>{email}</p>
//                                             <p className="mb-1" style={{ marginTop: "-5px" }}><span className="text-dark">Login ID :</span> {userInfo.username}</p>
//                                         </div>
//                                     </div>
//                                 </div>
                               

//                             </Col>
//                             <Col lg='2' className="d-flex align-items-center justify-content-end">
//                             </Col>
//                         </Row>
//                         <Row className="mt-2">
//                             <Col sm='6'>
//                                 <Button
//                                     outline
//                                     color="success"
//                                     style={{ paddingLeft: 'inherit' }}
//                                     onClick={() => { edit_user_info() }}
//                                     type="button"
//                                     className="btn-sm waves-effect waves-light mt-2"
//                                 >
//                                     <i className="mdi mdi-pencil font-size-7"></i> Edit User Profile
//                                 </Button>
//                             </Col>

//                             <Col sm='6' className="text-end">
//                                     <Button
//                                         outline
//                                         color="primary"
//                                         onClick={() => { changePassword() }}
//                                         className="btn-sm waves-effect waves-light mt-2"
//                                     >
//                                         Change Password
//                                     </Button>
//                                 {/* </div> */}
//                             </Col>
//                             {/* <Col sm='6' className="d-flex align-items-center justify-content-end">
//                                 <div className="button-items mb-1 ">
//                                     <Button
//                                         outline
//                                         color="primary"
//                                         onClick={() => { changePassword() }}
//                                         className="btn-sm waves-effect waves-light"
//                                     >
//                                         Change Password
//                                     </Button>
//                                 </div>
//                             </Col> */}

//                         </Row>
//                     </CardBody>
//                 </Card>
//                 {/* <Row>
//                     <Col md={10} className="card-title mb-4" style={{ textAlign: 'left' }}>
//                         <button
//                             style={{ paddingLeft: 'inherit' }}
//                             onClick={() => { edit_user_info() }}
//                             type="button"
//                             className="btn btn-success  btn-label"
//                         >
//                             <i className="mdi mdi-pencil font-size-7"></i> Edit User Profile
//                         </button>
//                     </Col>

//                     <Col md={2} className="card-title mb-4" style={{ textAlign: 'right' }}>

//                     </Col>
//                 </Row> */}

//                 {/* <Row>
//                     <Col>
//                         <Row >
//                             <Col md={2}>
//                                 <Button color="dark">
//                                     Back
//                                 </Button>
//                             </Col>
//                         </Row>
                                       
//                     </Col>

//                     </Row> */}
//                     {
//                         console.log(renderHeader,'renderHeader')
//                     }
//                     {
//                         renderHeader &&
//                         <Header/>
//                     }

//                 <Card>
//                     <CardBody>
//                         <Form
//                             className="form-horizontal"
//                             onSubmit={(e) => {
//                                 e.preventDefault();
//                                 validation.handleSubmit();
//                                 return false;
//                             }}
//                         >
//                             <Row className="my-4">
//                                 <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>User Information</div>
//                                 <hr className="my-2" />
//                             </Row>
//                             <Row>
//                                 <Col md={12}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Full Name :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="firstname"
//                                             // value={name}
//                                             className="form-control"
//                                             placeholder="Enter First Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.firstname || ""}
//                                             disabled={previewUser}
//                                             invalid={
//                                                 validation.touched.firstname && validation.errors.firstname ? true : false
//                                             }
//                                         />
//                                         {validation.touched.firstname && validation.errors.firstname ? (
//                                             <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
//                                         ) : null}
//                                     </div>
//                                 </Col>
//                                 {/* <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Last Name :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="lastname"
//                                             className="form-control"
//                                             placeholder="Enter Last Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.lastname || ""}
//                                             disabled={previewUser}
//                                             invalid={
//                                                 validation.touched.lastname && validation.errors.lastname ? true : false
//                                             }
//                                         />
//                                         {validation.touched.lastname && validation.errors.lastname ? (
//                                             <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
//                                         ) : null}
//                                     </div>
//                                 </Col> */}
//                             </Row>
//                             <br />
//                             <Row>
//                                 <Col md={12}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Address:</Label>
//                                         <Input
//                                             name="addrss_1"
//                                             className="form-control"
//                                             placeholder="Enter Address Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             disabled={previewUser}
//                                             value={validation.values.addrss_1 || ""}
//                                         />
//                                     </div>

//                                 </Col>
//                             </Row>
//                             <br />
//                             <Row>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">City:</Label>
//                                         <Input
//                                             name="city"
//                                             className="form-control"
//                                             placeholder="Enter City Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.city || ""}
//                                             disabled={previewUser}
//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">State :</Label>
//                                         <Input
//                                             name="state"
//                                             className="form-control"
//                                             placeholder="Enter State Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.state || ""}
//                                             disabled={previewUser}
//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Country:</Label>
//                                         <Input
//                                             name="country"
//                                             className="form-control"
//                                             placeholder="Enter Country Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.country || ""}
//                                             disabled={previewUser}
//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <br />
//                             <Row>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Email ID :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="email_id"
//                                             className="form-control"
//                                             placeholder="Enter Email ID"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.email_id || ""}
//                                             disabled={true}
//                                             invalid={
//                                                 validation.touched.email_id && validation.errors.email_id ? true : false
//                                             }
//                                         />
//                                         {validation.touched.email_id && validation.errors.email_id ? (
//                                             <FormFeedback type="invalid">{validation.errors.email_id}</FormFeedback>
//                                         ) : null}
//                                     </div>

//                                 </Col>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Mobile Number :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="phone_number"
//                                             className="form-control"
//                                             placeholder="Enter Mobile Number"
//                                             type="number"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.phone_number || ""}
//                                             disabled={previewUser}
//                                             invalid={
//                                                 validation.touched.phone_number && validation.errors.phone_number ? true : false
//                                             }
//                                         />
//                                         {validation.touched.phone_number && validation.errors.phone_number ? (
//                                             <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
//                                         ) : null}
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <br />
//                             <Row className="my-4">
//                                 <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>Company Information</div>
//                                 <hr className="my-2" />
//                             </Row>
                         
//                             <Row>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Qualification:</Label>
//                                         <Input
//                                             name="qualification"
//                                             className="form-control"
//                                             placeholder="Enter Qualification"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.qualification || ""}
//                                             disabled={previewUser}

//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Date of Joining:</Label>
//                                         <Input
//                                             name="doj"
//                                             className="form-control"
//                                             placeholder="Enter Date of Joining"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.doj || ""}
//                                             disabled={previewUser}

//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <br />
//                             <Row>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Designation :</Label>
//                                         <Input
//                                             name="designation"
//                                             className="form-control"
//                                             placeholder="Enter your Designation"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.designation || ""}
//                                             disabled={previewUser}

//                                             // invalid={
//                                             //     validation.touched.designation && validation.errors.designation ? true : false
//                                             // }
//                                         />
//                                         {/* {validation.touched.designation && validation.errors.designation ? (
//                                             <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
//                                         ) : null} */}
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Experience:</Label>
//                                         <Input
//                                             name="experience"
//                                             className="form-control"
//                                             placeholder="Enter your Experience"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.experience || ""}
//                                             disabled={previewUser}

//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">User Code:</Label>
//                                         <Input
//                                             name="usercode"
//                                             className="form-control"
//                                             placeholder="Enter your User Code"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.usercode || ""}
//                                             disabled={previewUser}

//                                             invalid={
//                                                 validation.touched.usercode && validation.errors.usercode ? true : false
//                                             }
//                                         />
//                                         {validation.touched.usercode && validation.errors.usercode ? (
//                                             <FormFeedback type="invalid">{validation.errors.usercode}</FormFeedback>
//                                         ) : null}
//                                     </div>
//                                 </Col>
//                             </Row>
//                                 <br/>
//                                 <br />
//                             {/* <Label>Upload Company Logo:</Label>
//                             <Row>
//                                 <Col md={3}>                        
//                                 {
//                                 // console.log(userInfo,"user info",clientInfo)
//                                 userInfo.role == "Super Admin" &&
//                                         <div className="dropzone-previews mt-3" id="file-previews">
//                                             {
//                                                 Object.keys(clientInfo.client_logo).length > 0 ?


//                                                     <Card style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
//                                                         <CardBody>
//                                                             <Row>
//                                                                 <Col md={12} style={{ textAlign: 'center' }}>
//                                                                     <Image className="text-center" src={clientInfo.base_url + clientInfo.client_logo.originalname} width={'50%'} height={'auto'} alt="Thumbnail" />
//                                                                 </Col>
//                                                                 <div className="mt-2 contentInfo text-center">
//                                                                     <Label style={{ marginBottom: 0 }}>File name: <span>{clientInfo.client_logo.originalname}</span></Label><br />
//                                                                     <Label style={{ marginBottom: 0 }}>Size: <span>{clientInfo.client_logo.formattedSize}</span></Label>
//                                                                 </div>
//                                                                 <div className='mt-2 text-end'>
//                                                                     <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(clientInfo.client_logo) }}>
//                                                                         <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//                                                                     </Popconfirm>
//                                                                 </div>
//                                                             </Row>
//                                                         </CardBody>
//                                                     </Card>

//                                                         :
//                                                         <>
//                                                         </>

//                                                 }
//                                             </div>

                               
                               
//                             }
                               
//                                 </Col>


//                             </Row> */}
//                          {  userInfo.role === "Super Admin" && <Label>Upload Company Logo:</Label>}
//                             <Row>
//                                 <Col md={3}>

//                                     {
//                                         showUpload && userInfo.role === "Super Admin" ?
//                                             <div className={loading ? "loading-container" : ""}>
//                                                 <div className={`dropzone ${loading ? "blur" : ""}`}>
//                                                     <Dropzone
//                                                         onDrop={(acceptedFiles) => {
//                                                             handleAcceptedFiles(acceptedFiles);
//                                                         }}
//                                                         disabled={previewUser}
//                                                         accept={[".jpg", ".jpeg", ".png"]}
//                                                         className={previewUser ? "dropzone-disabled" : ""}
//                                                     >
//                                                         {({ getRootProps, getInputProps }) => (
//                                                             <div className={`dz-message needsclick mt-2 ${previewUser ? "dropzone-blur" : ""}`} {...getRootProps()}>
//                                                                 <input {...getInputProps()} />
//                                                                 <div className="mb-3">
//                                                                     <i className="display-4 text-muted bx bxs-cloud-upload" />
//                                                                 </div>
//                                                                 <h4>Drop files here or click to upload.</h4>
//                                                                 <div className="mt-2 font-size-11 text-dark text-center">
//                                                                     <span className="text-danger">Files accepted - .jpg, .jpeg, .png </span> <br />
//                                                                     <span className="text-danger">Maximum individual file size - 1mb</span>
//                                                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                                                                 </div>
//                                                             </div>
//                                                         )}
//                                                     </Dropzone>
//                                                 </div>
//                                                 {loading && <div className="loading">Loading...</div>}
//                                             </div>
//                                             :

//                                             userInfo.role === "Super Admin" && (Object.keys(clientInfo.client_logo
//                                             ).length > 0) ?

//                                                 <div className="dropzone-previews mt-3" id="file-previews">
//                                                     <Card  style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
//                                                         <CardBody>
//                                                             <Row>
//                                                                 <Col md={12} style={{ textAlign: 'center' }}>
//                                                                     <Image  src={clientInfo.base_url + clientInfo.client_logo.originalname} width={'50%'} height={'auto'} alt="Thumbnail" />
//                                                                 </Col>
//                                                                 <div className="mt-2 contentInfo text-center">
//                                                                     <Label style={{ marginBottom: 0 }}>File name: <span>{clientInfo.client_logo.originalname}</span></Label><br />
//                                                                     <Label style={{ marginBottom: 0 }}>Size: <span>{clientInfo.client_logo.formattedSize}</span></Label>
//                                                                 </div>
//                                                                 <div className='mt-2 text-end'>
//                                                                 <span style={{ pointerEvents: previewUser ? 'none' : 'auto' }}>
//                                                                     <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(clientInfo.client_logo) }}>
//                                                                         <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//                                                                     </Popconfirm>
//                                                                     </span>
//                                                                 </div>
//                                                             </Row>
//                                                         </CardBody>
//                                                     </Card>


//                                                 </div>
//                                                 :
//                                                 selectedFiles.map((f, i) => {
//                                                     return (
//                                                         // <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"} >
//                                                         <div key={i + "-file"} >
//                                                             <Card style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
//                                                                 <CardBody>
//                                                                     <Row>
//                                                                         <Col md={12} style={{ textAlign: 'center' }}>
//                                                                             <Image className="text-center" src={f.preview} width={'50%'} height={'auto'} alt="Thumbnail" />
//                                                                         </Col>
//                                                                         <div className="mt-2 contentInfo text-center">
//                                                                             <Label style={{ marginBottom: 0 }}>File name: <span>{f.name}</span></Label><br />
//                                                                             <Label style={{ marginBottom: 0 }}>Size: <span>{f.formattedSize}</span></Label>
//                                                                         </div>
//                                                                         <div className='mt-2 text-end'>
//                                                                             <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(f) }}>
//                                                                                 <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//                                                                             </Popconfirm>
//                                                                         </div>
//                                                                     </Row>
//                                                                 </CardBody>
//                                                             </Card>
//                                                         </div>
//                                                     )
//                                                 })
//                                     }
//                                 </Col>
//                             </Row>

//                             <br />
//                             {/* <Row className="my-4">
//                                 <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>Login Information</div>
//                                 <hr className="my-2" />
//                             </Row>
//                             <Row>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">User Name :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="username"
//                                             // value={name}
//                                             className="form-control"
//                                             placeholder="Enter User Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.username || ""}
//                                             disabled={true}
//                                             invalid={
//                                                 validation.touched.username && validation.errors.username ? true : false
//                                             }
//                                         />
//                                         {validation.touched.username && validation.errors.username ? (
//                                             <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
//                                         ) : null}
//                                         <Input name="idx" value={idx} type="hidden" />
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <br />
//                                     <Button color="primary"
//                                         onClick={() => { changePassword() }}
//                                         style={{ marginTop: '14px' }}
//                                         className="btn-rounded ">
//                                         Change Password
//                                     </Button>
//                                 </Col>
//                             </Row> */}
//                             <br />
//                             <Row>
//                                 <Col xl={10}>

//                                 </Col>
//                                 <Col xl={2} className="text-end">
//                                 <Button type="submit" color="danger" disabled={previewUser === true? true : false}>
//                                     Update User Info
//                                 </Button>
//                                     </Col>
//                             </Row>
                           
//                         </Form>
//                         {openModal &&
//                             <Modal
//                                 size="xl"
//                                 isOpen={openModal}
//                                 toggle={() => {
//                                     changePassword("1");
//                                 }}
//                                 centered
//                             >
//                                 <div className="modal-header">
//                                     <h5
//                                         className="modal-title mt-0"
//                                     >
//                                         Change Password
//                                     </h5>
//                                     <button
//                                         onClick={() => {
//                                             setOpenModal(false);
//                                         }}
//                                         type="button"
//                                         className="close"
//                                         data-dismiss="modal"
//                                         aria-label="Close"
//                                     >
//                                         <span aria-hidden="true">&times;</span>
//                                     </button>
//                                 </div>
//                                 <br />

//                                 <Row>
//                                     <Col md={1}>
//                                       <label style={{ marginLeft: '17px' }}>Email ID:</label>

//                                     </Col>
//                                     <Col md={2}>
//                                         <label>{userInfo.email_id}</label>
//                                     </Col>
//                                 </Row>
//                                 <br />
//                                 <Form className="needs-validation"
//                                     onSubmit={(e) => {
//                                         e.preventDefault();
//                                         changepwd.handleSubmit();
//                                         return false;
//                                     }}
//                                 >
//                                     <Row>
//                                         <Col xl={11}>

//                                             <FormGroup style={{ marginLeft: '18px' }}>
//                                                 <Label >Current Password:</Label>
//                                                 <Input
//                                                     name="current_password"
//                                                     style={{ width: "106%" }}
//                                                     placeholder="Current Password"
//                                                     type={show ? "text" : "password"}
//                                                     className="form-control"
//                                                     onChange={changepwd.handleChange}
//                                                     onBlur={changepwd.handleBlur}
//                                                     value={changepwd.values.current_password || ""}
//                                                     invalid={
//                                                         changepwd.touched.current_password && changepwd.errors.current_password ? true : false
//                                                     }
//                                                 />
//                                                 {changepwd.touched.current_password && changepwd.errors.current_password ? (
//                                                     <FormFeedback type="invalid">{changepwd.errors.current_password}</FormFeedback>
//                                                 ) : null}
//                                             </FormGroup>
//                                         </Col>
//                                             <Col xl={1} style={{ marginTop: '27px' }}>
//                                             <button onClick={() => setShow(!show)} className="btn btn-light " type="button">
//                                                     <i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//                                             </Col>
//                                         </Row>
//                                         <br/>
//                                         <Row>
//                                         <Col xl={11}>
//                                             <FormGroup style={{ marginLeft: '18px' }}>
//                                                 <Label >New Password:</Label>
//                                                 <Input
//                                                     name="new_password"
//                                                     placeholder="New Password"
//                                                     style={{ width: "106%" }}
//                                                     type={show ? "text" : "password"}
//                                                     className="form-control"
//                                                     onChange={changepwd.handleChange}
//                                                     onBlur={changepwd.handleBlur}
//                                                     value={changepwd.values.new_password || ""}
//                                                     invalid={
//                                                         changepwd.touched.new_password && changepwd.errors.new_password ? true : false
//                                                     }
//                                                 />
//                                                 {changepwd.touched.new_password && changepwd.errors.new_password ? (
//                                                     <FormFeedback type="invalid">{changepwd.errors.new_password}</FormFeedback>
//                                                 ) : null}
//                                             </FormGroup>
//                                         </Col>
//                                         <Col xl={1} style={{ marginTop: '27px' }}>
//                                             <button onClick={() => setShow(!show)} className="btn btn-light " type="button">
//                                                     <i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//                                             </Col>
//                                         </Row>
//                                         <br/>
//                                         <Row>
//                                         <Col xl={11}>
//                                             <FormGroup style={{ marginLeft: '18px' }}>
//                                                 <Label >Confirm Password:</Label>
//                                                 <Input
//                                                     name="confirm_password"
//                                                     placeholder="Confirm Password"
//                                                     style={{ width: "106%" }}
//                                                     type={show ? "text" : "password"}
//                                                     className="form-control"
//                                                     onChange={changepwd.handleChange}
//                                                     onBlur={changepwd.handleBlur}
//                                                     value={changepwd.values.confirm_password || ""}
//                                                     invalid={
//                                                         changepwd.touched.confirm_password && changepwd.errors.confirm_password ? true : false
//                                                     }
//                                                 />                                                  
//                                                 {changepwd.touched.confirm_password && changepwd.errors.confirm_password ? (
//                                                     <FormFeedback type="invalid">{changepwd.errors.confirm_password}</FormFeedback>
//                                                 ) : null}
//                                             </FormGroup>
//                                         </Col>
//                                         <Col xl={1} style={{ marginTop: '27px' }}>
//                                             <button onClick={() => setShow(!show)} className="btn btn-light " type="button">
//                                                     <i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//                                             </Col>
//                                         </Row>
//                                         <br/>
//                                     <Row>
//                                         <Col md={9}>
//                                         </Col>
//                                         <Col md={3}>
//                                             <Button type="submit" color="danger" style={{ marginLeft: '25px' }} onClick={() => setOpenModal(false)} >Cancel</Button>
//                                             <Button type="submit" color="success" style={{ marginLeft: '10px' }} >Change Password</Button>
//                                         </Col>

//                                     </Row>
//                                 </Form>

//                                 <br />
//                                 <br />

//                             </Modal>
//                         }


//                     </CardBody>
//                 </Card>
//             </Container>
//         </div>
//     </React.Fragment>
//     );
// };

// export default UserProfile









// import React, { useState, useEffect } from "react";
// import {
//     Container,
//     Row,
//     Col,
//     Card,
//     Alert,
//     CardBody,
//     Button,
//     Label,
//     Input,
//     FormFeedback,
//     Form,
//     Modal, FormGroup
// } from "reactstrap";
// import CryptoJS from 'crypto-js'
// import { Space, Spin,Upload, Image, Popconfirm } from 'antd';
// // Formik Validation
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import Swal from 'sweetalert2';
// import { LoadingOutlined, DeleteTwoTone, UploadOutlined } from '@ant-design/icons'
// import Dropzone from "react-dropzone";
// import reactImageSize from 'react-image-size';
// import Resizer from 'react-image-file-resizer'
// import Header from "../VerticalLayout/Header";
// //redux
// // import { useSelector, useDispatch } from "react-redux";

// //Import Breadcrumb
// import Breadcrumb from "../../components/Common/Breadcrumb";

// import ImgCrop from "antd-img-crop";
// // import ReactCrop from "react-image-crop";
// // import "react-image-crop/dist/ReactCrop.css";
// // import "antd/dist/antd.css";
// import avatar from "../../assets/images/149071.png";
// import urlSocket from "../../helpers/urlSocket";
// import { useHistory } from 'react-router-dom';
// import AvatarUploaderModal from "./AvatarUpload";
// import { Dropdown } from 'react-bootstrap';




// // actions

// const UserProfile = () => {
//     const history = useHistory();
//     //meta title
//     document.title = "Profile | AuditVista";

//     const [email, setemail] = useState("");
//     const [name, setname] = useState("");
//     const [idx, setidx] = useState(1);
//     const [previewUser, setPreviewUser] = useState(true)
//     const [userInfo, setUserInfo] = useState({})
//     const [openModal, setOpenModal] = useState(false)
//     const [show, setShow] = useState(false);
//     const [dbInfo, setDbInfo] = useState({})
//     const [clientInfo, setClientInfo] = useState({})
//     const [selectedFiles, setselectedFiles] = useState([]);
//     const [secret_key, setSecret_key] = useState('Pkdh347ey%3Tgs')
//     const [showUpload, setshowUpload] = useState(false);
//     const [renderHeader, setrenderHeader] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [required_img_width,set_required_img_width] = useState(1700)
//     const [required_img_height,set_required_img_height] = useState(1700)
//     const [userImg, setUserImg] = useState([]);
    
//     const [takephoto, setTakephoto] = useState(false);
//     const [galleryphoto, setGalleryphoto] = useState(false);
//     const [show_dropdown, setShowdropdown] = useState(true);

    


//     useEffect(() => {
//         var db_info = JSON.parse(sessionStorage.getItem("db_info"));
//         var client_info = JSON.parse(sessionStorage.getItem("client_info"))[0];
//         console.log(client_info,'client_info')
//         setshowUpload(client_info.client_logo === undefined || client_info.client_logo === null  ? true : false)
//         console.log(db_info, 'db_info')
//         setDbInfo(db_info)
//         setClientInfo(client_info)
//         if (sessionStorage.getItem("authUser")) {
//             const obj = JSON.parse(sessionStorage.getItem("authUser"));
//             console.log(obj, 'obj')
//             session_data(obj)
//             if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//                 setname(obj.displayName);
//                 setemail(obj.user_data.email);
//                 setidx(obj.uid);
//             } else if (
//                 process.env.REACT_APP_DEFAULTAUTH === "fake" ||
//                 process.env.REACT_APP_DEFAULTAUTH === "jwt"
//             )
//                 //   {
//                 //     setname(obj.username);
//                 //     setemail(obj.email);
//                 //     setidx(obj.uid);
//                 //   }
//                 setTimeout(() => {
//                     // dispatch(resetProfileFlag());
//                 }, 3000);
//         }
//     }, []);

//     const session_data = (data) => {
//         console.log(data, 'data')
//         setUserInfo(data.user_data)
//         setUserImg(data.user_data.user_img !== undefined ? data.user_data.user_img :[])
//         setname(data.user_data.firstname)
//         setemail(data.user_data.email_id);
//         setidx(data.uid);

//     }


//     const changepwd = useFormik({
//         enableReinitialize: true,
//         initialValues: {
//             current_password: '',
//             new_password: '',
//             confirm_password: ''
//         },
//         validationSchema: Yup.object({
//             current_password: Yup.string().required("Please Enter Your Current Password"),
//             new_password: Yup.string().min(8, "Password must be at least 8 characters long")
//                 .matches(
//                     /^(?=.*[A-Z]).+$/,
//                     "Password must contain at least one uppercase letter"
//                 )
//                 .required("Please Enter the New Password"),
//             confirm_password: Yup.string().oneOf([Yup.ref("new_password"), null], "Passwords must match")
//                 .required("Please Confirm Your New Password"),
//         }),
//         onSubmit: (values) => {
//             console.log(values, dbInfo, clientInfo)
//             values["encrypted_db_url"] = dbInfo.encrypted_db_url
//             values["db_name"] = dbInfo.db_name
//             values["userPoolId"] = clientInfo.userPoolId
//             values["clientId"] = clientInfo.clientId
//             var curr_pwd = CryptoJS.AES.encrypt(values.current_password, secret_key).toString()
//             var new_pwd = CryptoJS.AES.encrypt(values.new_password, secret_key).toString()
//             values["current_password"] = curr_pwd
//             values["new_password"] = new_pwd
//             values["confirm_password"] = new_pwd
//             values["email_id"] = userInfo.email_id
//             console.log(values, dbInfo, clientInfo)

//             try {
//                 urlSocket.post('cog/change-pwd', values).then((res) => {
//                     console.log(res, 'res')
//                     if (res.data.data.response_code === 500) {
//                         // window.location.reload()
//                         Swal.fire({
//                             icon: 'success',
//                             title: 'Success!',
//                             text: 'Your request has been processed successfully.',
//                             confirmButtonColor: '#3085d6',
//                             confirmButtonText: 'OK'
//                         }).then((result) => {
//                             console.log(result, 'result')
//                             if (result.isConfirmed) {
//                                 sessionStorage.clear()
//                                 window.location.reload();
//                             }
//                         })
//                     }
//                 })
//             } catch (error) {
//                 console.log(error, 'error')
//             }
//         }
//     })


//     // })

//     const validation = useFormik({
//         enableReinitialize: true,

//         initialValues: {
//             username: userInfo.username || '',
//             firstname: userInfo.firstname || '',
//             // lastname: userInfo.lastname || '',
//             addrss_1: userInfo.addrss_1 || '',
//             city: userInfo.city || '',
//             state: userInfo.state || '',
//             country: userInfo.country || '',
//             email_id: userInfo.email_id || '',
//             phone_number: userInfo.phone_number || '',
//             qualification: userInfo.qualification || '',
//             doj: userInfo.doj || '',
//             designation: userInfo.designation || '',
//             experience: userInfo.experience || '',
//             usercode: userInfo.usercode || '',
//             password: userInfo.password || '',
//         },
//         validationSchema: Yup.object({
//             username: Yup.string().required("Please Enter Your UserName"),
//             firstname: Yup.string().required("Please Enter Your firstname"),
//             // lastname: Yup.string().required("Please Enter Your lastname"),
//             email_id: Yup.string().required("Please Enter Your Email Id"),
//             phone_number: Yup.string().required("Please Enter Your Mobile Number"),
//             // designation: Yup.string().required("Please Enter Your Designation"),
//             // usercode: Yup.string().required("Please Enter Your firstname"),
//             // password: Yup.string().required("Please Enter Your Password"),
//         }),
//         onSubmit: (values) => {
//             // var password = CryptoJS.AES.encrypt(values.password, secret_key).toString()
//             values["password"] = userInfo.password
//             values["confirmpassword"] = userInfo.password
//             values["userPoolId"] = clientInfo.userPoolId
//             values["clientId"] = clientInfo.clientId
//             values["encrypted_db_url"] = dbInfo.encrypted_db_url
//             values["db_name"] = dbInfo.db_name
//             values["facilities"] = userInfo.facilities
//             values["_id"] = userInfo._id
//             values["status"] = userInfo.status
//             values["active"] = userInfo.active
//             values["company_id"] = userInfo.company_id
//             values["role_id"] = userInfo.role_id
//             values["role_name"] = userInfo.role_name
//             values["user_id"] = userInfo._id




//             console.log(values)
//             try {
//                 urlSocket.post('cog/cruduser', values).then((res) => {
//                     console.log(res, 'res')
//                     if (res.data.response_code === 500) {
//                         Swal.fire({
//                             icon: 'success',
//                             title: 'Success!',
//                             text: 'User Information has been saved successfully.',
//                             confirmButtonColor: '#3085d6',
//                             confirmButtonText: 'OK'
//                         })
//                             .then((result) => {
//                                 console.log(result, 'result')
//                                 if (result.isConfirmed) {
//                                     window.location.reload();
//                                 }
//                             })
//                         // window.location.reload()
//                     }
//                 })

//             } catch (error) {
//                 console.log(error, 'error')
//             }

//             //   dispatch(editProfile(values));
//         }
//     });

//     const  imageSizeValid = (imageUrl) => {
//         console.log(imageUrl,'imageUrl')
//         try {
//           // const { width, height } = await reactImageSize(imageUrl)
//           return reactImageSize(imageUrl)
//             .then(({ width, height }) => {
//               console.log({ width, height })
//               let data = { width, height }
//               data["width"]=width
//               data["height"]=height
//               return data
//             })
//             .catch((error) => {
//               console.log(error)
//             })
//         } catch {
    
//         }
//       }



//     // async function handleAcceptedFiles(files) {
//     //     console.log(files, 'files')
//     //     const formData = new FormData();
//     //     clientInfo["client_logo"] = {}
//     //     files.map(async file => {
//     //         const formattedFile = Object.assign(file, {
//     //             preview: URL.createObjectURL(file),
//     //             formattedSize: formatBytes(file.size),
//     //             filetype: file.type,
//     //             uploadingStatus: 'Not uploaded',
//     //             originalName: file.name,
//     //         });

//     //         let image_size = await imageSizeValid(files[0].preview)
//     //         formattedFile["width"] = image_size.width
//     //         formattedFile["height"] = image_size.height
//     //         console.log(image_size, 'image_size', formattedFile)

//     //         formData.append('files', formattedFile);
//     //         if (clientInfo._id !== undefined) {
//     //             console.log("update_img", clientInfo._id)
//     //             formData.append('client_logo', clientInfo._id);
//     //             formData.append('email_id', clientInfo.email_id);
//     //             formData.append('encrypted_db_url',dbInfo.encrypted_db_url );
//     //             // formData.append('client_info', state);
//     //             // var client_info = state
//     //         }
//     //         console.log(image_size, files, files[0].size > 500000 && files[0].size <= 1000000)
//     //         if (files[0].size > 500000 && files[0].size <= 1000000) {
//     //             console.log(image_size, 'image_size', files)
//     //             Swal.fire({
//     //                 icon: 'warning',
//     //                 title: 'Warning!',
//     //                 text: `Image Size is more than 1MB `,
//     //                 confirmButtonColor: '#3085d6',
//     //                 confirmButtonText: 'OK'
//     //             }).then((result) => {
//     //                 console.log(result, 'result')
//     //                 // getClientList()


//     //             })
//     //         }

//     //         // formData.append('files', formattedFile);
//     //         if (image_size.width < required_img_width && image_size.height < required_img_height) {
//     //             console.log('Matched', files)
//     //             try {
//     //                 const config = {
//     //                     headers: {
//     //                         'Content-Type': 'multipart/form-data'
//     //                     }
//     //                 };
//     //                 urlSocket.post('storeImage/logo_awswebupload', formData, config).then((response) => {
//     //                     console.log(response, 'response')
//     //                     if (response.data.response_code === 500) {
//     //                         var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//     //                         session_data.client_info[0]["client_logo"]=response.data.client_logo
//     //                         var client_data = JSON.parse(sessionStorage.getItem('client_info'))
//     //                         client_data[0]["client_logo"]=response.data.client_logo
//     //                         sessionStorage.setItem('authUser',JSON.stringify(session_data))
//     //                         sessionStorage.setItem('client_info',JSON.stringify(client_data))
//     //                         setshowUpload(false)
//     //                         // clientInfo["client_logo"]=response.data.data[0]
//     //                     }
//     //                 })

//     //             } catch (error) {

//     //             }
//     //         }
//     //          else {
//     //             console.log('Not Matched')
//     //             Swal.fire({
//     //                 icon: 'warning',
//     //                 title: 'Warning!',
//     //                 text: `Please Upload image size exactly on ${required_img_width}px X ${required_img_height}px`,
//     //                 confirmButtonColor: '#3085d6',
//     //                 confirmButtonText: 'OK'
//     //             }).then((result) => {
//     //                 console.log(result, 'result')
//     //                 // getClientList()


//     //             })
//     //             // this.toastWarning(`Please Upload image size exactly on ${this.state.required_img_width}px X ${this.state.required_img_height}px`)
//     //             return
//     //         }

//     //     });

//     //     setselectedFiles(files)
//     //     console.log(files)


//     // }



//     async function handleAcceptedFiles(files) {
//         setLoading(true)
//         const formData = new FormData();
//         clientInfo["client_logo"] = {}
//         files.map(async file => {
//             const formattedFile = Object.assign(file, {
//                 preview: URL.createObjectURL(file),
//                 formattedSize: formatBytes(file.size),
//                 filetype: file.type,
//                 uploadingStatus: 'Not uploaded',
//                 originalName: file.name,
//             });
    
//             let image_size = await imageSizeValid(files[0].preview)
//             formattedFile["width"] = image_size.width
//             formattedFile["height"] = image_size.width
//             console.log(image_size, 'image_size', formattedFile)  
//             formData.append('files', formattedFile);
//             if (clientInfo._id !== undefined) {
//                 console.log("update_img", clientInfo._id)
//                 formData.append('client_logo', clientInfo._id);  
//                 formData.append('email_id', clientInfo.email_id);
//                 formData.append('short_name', clientInfo.short_name);
//                 formData.append('encrypted_db_url',dbInfo.encrypted_db_url );          
//             }
//             if (files[0].size > 1000000) {
//                 setLoading(false)
//                 Swal.fire({
//                     icon: 'warning',
//                     title: 'Warning!',
//                     text: `Image Size is more than 1MB `,
//                     confirmButtonColor: '#3085d6',
//                     confirmButtonText: 'OK'
//                 }).then((result) => {
//                     console.log(result, 'result')
//                     // getClientList()
    
//                 })
//             }
//             else{
//             try {
//                 const config = {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 };
//                 urlSocket.post('storeImage/logo_awswebupload', formData, config).then(async(response) => {
//                     console.log(response, 'response')
//                     if (response.data.response_code === 500) {
//                         // setshowUpload(false)
//                         // formData.append('client_logo', state._id); 
//                         var upload = await updt_rszd_img(formattedFile)
//                         console.log(upload,'upload')
//                         var rszed_img = await rszed_upload_img(upload,config,files)
    
    
//                     }
//                 })
    
//             } catch (error) {
    
//             }
//         }
//         });
    
//     }
    
//         async function rszed_upload_img(upload, config,files) {
//             console.log(files,'files')
//             urlSocket.post('storeImage/logo_awswebupload', upload, config).then(async (response) => {
//                 console.log(response, 'response')
//                 if(response.data.response_code === 500){
//                      var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//                     var client_data = JSON.parse(sessionStorage.getItem('client_info'))
//                     client_data = response.data.client_info
//                     session_data.client_info = response.data.client_info
//                     console.log(session_data,'session_data')
//                     sessionStorage.setItem('client_info',JSON.stringify(client_data))
//                     // var client_data = JSON.parse(sessionStorage.getItem('client_info'))
//                     // client_data[0]["client_logo_rzd"]=response.data.client_logo
//                     sessionStorage.setItem('authUser',JSON.stringify(session_data))
//                     // sessionStorage.setItem('client_info',JSON.stringify(client_data))
//                     setLoading(false)
//                     setshowUpload(false)
//                     setselectedFiles(files)
//                     history.push(window.location.pathname);
//                 }
//             })
    
    
//         }
    
    
    
    
    
    
    
    
    
//         async function updt_rszd_img(files){
    
//             console.log(files,'files')
//             const formData = new FormData()
//             if(clientInfo._id !== undefined){
//                 formData.append('client_logo',clientInfo._id)
//             }
    
//             if (files.size > 500000 && files.size <= 1000000) {
    
//                 const resizedFile = await resizeImage(files, 0.8, 512, 512)
//                 resizedFile.formattedSize= formatBytes(resizedFile.size)
//                 resizedFile.preview= URL.createObjectURL(resizedFile)
                
//                 console.log('resizedFile', resizedFile,resizedFile.name +"_rzd")
//                 const dotIndex = resizedFile.name.lastIndexOf('.');
//                 const modifiedFilename = resizedFile.name.slice(0, dotIndex) + "_rzd" + resizedFile.name.slice(dotIndex);
//                 resizedFile["originalname"]= modifiedFilename
                
//                 formData.append('files', resizedFile);
//                 formData.append('email_id', clientInfo.email_id);
//                 formData.append('short_name', clientInfo.short_name);
//                 formData.append('encrypted_db_url',dbInfo.encrypted_db_url );
//                 formData.append('resized', true);     
//                 return formData
    
//             } else {
//                 formData.append('email_id', clientInfo.email_id);
//                 formData.append('encrypted_db_url',dbInfo.encrypted_db_url );
//                 formData.append('short_name', clientInfo.short_name);
//                 formData.append('resized', true);     
//                 formData.append('files', files);
//                 return formData
    
//             }     
    
//         } 
    
//         async function resizeImage(file, resizeRatio, targetWidth, targetHeight) {
//             const dotIndex = file.name.lastIndexOf('.');
//             const modifiedFilename = file.name.slice(0, dotIndex) + "_rzd" + file.name.slice(dotIndex);
//             var originalName =modifiedFilename
//             return new Promise((resolve, reject) => {
//                 Resizer.imageFileResizer(
//                     file,
//                     targetWidth,
//                     targetHeight,
//                     'JPEG',
//                     80,
//                     0,
//                     (resizedFile) => {
//                         const modifiedResizedFile = new File([resizedFile], originalName, { type: resizedFile.type });
//                         resolve(modifiedResizedFile);
//                     },
//                     'blob'
//                 );
//             });
//         }
    
//     function formatBytes(bytes, decimals = 2) {
//         if (bytes === 0) return "0 Bytes"
//         const k = 1024
//         const dm = decimals < 0 ? 0 : decimals
//         const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

//         const i = Math.floor(Math.log(bytes) / Math.log(k))
//         return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
//     }


//     function deleteImage(data) {
//         console.log(data, 'data')
//         if (Object.keys(clientInfo.client_logo).length > 0) {
//             delete clientInfo["client_logo"]
//             console.log(clientInfo,'state')
//             var delete_data ={}
//             delete_data["userPoolId"] = clientInfo.userPoolId
//             delete_data["clientId"] = clientInfo.clientId
//             delete_data["_id"] = clientInfo._id
//             delete_data["email_id"] = clientInfo.email_id
//             delete_data["short_name"] = clientInfo.short_name
//             delete_data["encrypted_db_url"] = dbInfo.encrypted_db_url
//             delete_data["db_name"] = dbInfo.db_name
//             urlSocket.post('webmngtmplt/delete-client-logo', { "client_info": delete_data }).then((response) => {
//                 console.log(response, 'response')
//                 setselectedFiles([])
//                 if(response.data.response_code === 500){
//                     Swal.fire({
//                         icon: 'success',
//                         title: 'Success!',
//                         text: `Image has been deleted successfully`,
//                         confirmButtonColor: '#FF0000',
//                         confirmButtonText: 'OK'
//                     }).then((result) => {
//                         console.log(result, 'result')
//                         var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//                         session_data["client_info"] = response.data.client_data
//                         sessionStorage.setItem('authUser',JSON.stringify(session_data))
//                         sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
//                         if (result.isConfirmed) {
//                             setrenderHeader(true)
//                         }
//                         // getClientList()
    
//                     })

//                 }
//                 // if(response.data.response_code === 500){
//                 //         var values ={}
//                 //         values[]
//                 //     try {
//                 //         urlSocket.post('cog/login-authenticate', values).then((res) => {


//                 //         })
//                 //     }
//                 //     catch(error){
//                 //         console.log(error,'erro')
//                 //     }


//                 // }
//                 setshowUpload(true)


//             })
//         }
//         else {
//             setselectedFiles([])
//         }
//         setshowUpload(true)
//     }


//     const edit_user_info = () => {
//         console.log("edit usersss")
//         setPreviewUser(false)
//     }

//     const changePassword = (data) => {
//         console.log("change passowrd")
//         changepwd.resetForm();
//         setOpenModal(true)
//         if (data === "1") {
//             setOpenModal(!openModal)
//         }

//     }

//     const onFileChange = (files) => {
//         console.log('e', [files]);
//         [files].map(async file => {
//             const formattedFile = Object.assign(file, {
//                 preview: URL.createObjectURL(file),
//                 formattedSize: formatBytes(file.size),
//                 filetype: file.type,
//                 uploadingStatus: 'Not uploaded',
//                 originalName: file.name,
//             });
//             const formData = new FormData();
//             console.log(formattedFile,'formattedFile')
//             formData.append('files',formattedFile)
//             formData.append('encrypted_db_url',dbInfo.encrypted_db_url)
//             formData.append('_id',userInfo._id)

//             try {
//                 const config = {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 };
//                 urlSocket.post('storeImage/user-img-upload',formData,config).then((response)=>{
//                     console.log(response,'response')
//                     if(response.data.response_code === 500){
//                         setUserImg(response.data.data)
//                         var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//                         session_data["user_data"] = response.data.user_data[0]
//                         sessionStorage.setItem('authUser',JSON.stringify(session_data))
//                         // sessionStorage.setItem('client_info',JSON.stringify(response.data.client_data))
//                     }
//                 })
                
//             } catch (error) {
                
//             }
//         })
//         console.log('e', [files]);
//     }

//     const deleteUserImg =(data)=>{
//         console.log("user image delete",data)
//         var obj_data ={}
//         obj_data["user_data"]=data
//         obj_data["encrypted_db_url"]=dbInfo.encrypted_db_url

//         try {
//                 urlSocket.post('storeImage/user-delete-img',obj_data).then((response)=>{
//                     console.log(response,'response')
//                     if(response.data.response_code == 500){
//                         Swal.fire({
//                             icon: 'success',
//                             title: 'Deleted Successfully',
//                             text: 'Profile Image Deleted successfully',
//                             confirmButtonColor: '#3085d6',
//                             confirmButtonText: 'Ok',
//                         }).then((result) => {
//                             if(result.isConfirmed){
//                                 console.log("success")
//                                 var session_data = JSON.parse(sessionStorage.getItem('authUser'))
//                                 session_data["user_data"] = response.data.user_data[0]
//                                 sessionStorage.setItem('authUser',JSON.stringify(session_data))
//                                 setUserImg([])
//                             }

//                         })
//                     }
//                 })
//         } catch (error) {
            
//         }

//     }


//     const handleDropdownSelect = (eventKey) => {
//         if (eventKey === 'takePhoto') {
//             handleTakePhoto()
//         } else if (eventKey === 'browseGallery') {
//             handleBrowseGallery()
//         }
//     };

//     const handleTakePhoto = () => {
//         // Handle "Take Photo" logic here
//         console.log('handleTakePhoto called...')
//         setTakephoto(true)
//         setGalleryphoto(false)
//         setShowdropdown(false)
//     };

//     const handleBrowseGallery = () => {
//         // Handle "Browse Gallery" logic here
//         console.log('handleBrowseGallery called...')
//         setTakephoto(false)
//         setGalleryphoto(true)
//         setShowdropdown(false)
//     };



//     return (
//         <React.Fragment>
//         <div className="page-content">
//             <Container fluid>
//                 {/* Render Breadcrumb */}
//                 <Breadcrumb title="Skote" breadcrumbItem="Profile" />
//                 <Card>
//                     <CardBody>
//                         <Row>
//                             <Col lg="10">
//                                 <div className="d-flex">
                                    
//                                         <div className="ms-3" id="file-previews">
//                                             {
//                                                 console.log('takephoto', takephoto, userImg)
//                                             }

//                                             {
//                                                 userImg.length > 0 &&
//                                                 <div>
//                                                     <img src={clientInfo.base_url + userImg[0].originalname} alt="Preview" className="rounded-circle avatar-lg" style={{ width: "100%" }} />
//                                                 </div>
//                                             }
//                                             {
//                                                 takephoto &&
//                                                 <>
//                                                     <label>Take photo</label>
//                                                 </>
//                                             }
//                                             {
//                                                 galleryphoto &&
//                                                 <>
//                                                     <ImgCrop showGrid showReset rotationSlider zoomSlider cropShape="round">
//                                                         <Upload
//                                                             listType="picture-circle"
//                                                             showUploadList={false}
//                                                             beforeUpload={onFileChange}
//                                                         >
//                                                             {/* {

//                                                                 userImg.length > 0 ?
//                                                                     <div>
//                                                                         <img src={clientInfo.base_url + userImg[0].originalname} alt="Preview" className="rounded-circle avatar-lg" style={{ width: "100%" }} />
//                                                                     </div>
//                                                                     : */}
//                                                                     {'+ upload'}
//                                                             {/* } */}

//                                                         </Upload>
//                                                     </ImgCrop>
//                                                 </>
//                                             }
                                            

//                                             {
//                                                 show_dropdown &&
//                                                 <div style={{ height: '50px', width: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="rounded-circle avatar-lg">
//                                                     <Dropdown onSelect={handleDropdownSelect}>
//                                                         <Dropdown.Toggle variant="white" id="dropdown-basic" style={{border: 'none'}}>
//                                                             <UploadOutlined /> Upload
//                                                         </Dropdown.Toggle>
//                                                         <Dropdown.Menu>
//                                                             <Dropdown.Item eventKey="takePhoto">Take Photo</Dropdown.Item>
//                                                             <Dropdown.Item eventKey="browseGallery">Browse Gallery</Dropdown.Item>
//                                                         </Dropdown.Menu>
//                                                     </Dropdown>
//                                                     {/* <AvatarUploaderModal /> */}
//                                                 </div>
//                                             }
                                           


//                                             {
//                                                 userImg.length > 0 &&
//                                                 <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteUserImg(userInfo) }}>
//                                                     <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//                                                 </Popconfirm>
//                                             }

//                                         </div>

                                        
//                                     <div className="flex-grow-1 align-self-center">
//                                         <div className="text-muted" style={{ marginLeft: '15px' }}>
//                                             <h5>{name}</h5>
//                                             <p className="mb-1" style={{ marginTop: "-6px" }}>{email}</p>
//                                             <p className="mb-1" style={{ marginTop: "-5px" }}><span className="text-dark">Login ID :</span> {userInfo.username}</p>
//                                         </div>
//                                     </div>
//                                 </div>
                                

//                             </Col>
//                             <Col lg='2' className="d-flex align-items-center justify-content-end">
//                             </Col>
//                         </Row>
//                         <Row className="mt-2">
//                             <Col sm='6'>
//                                 <Button
//                                     outline
//                                     color="success"
//                                     style={{ paddingLeft: 'inherit' }}
//                                     onClick={() => { edit_user_info() }}
//                                     type="button"
//                                     className="btn-sm waves-effect waves-light mt-2"
//                                 >
//                                     <i className="mdi mdi-pencil font-size-7"></i> Edit User Profile
//                                 </Button>
//                             </Col>

//                             <Col sm='6' className="text-end">
//                                     <Button
//                                         outline
//                                         color="primary"
//                                         onClick={() => { changePassword() }}
//                                         className="btn-sm waves-effect waves-light mt-2"
//                                     >
//                                         Change Password
//                                     </Button>
//                                 {/* </div> */}
//                             </Col>
//                             {/* <Col sm='6' className="d-flex align-items-center justify-content-end">
//                                 <div className="button-items mb-1 ">
//                                     <Button
//                                         outline
//                                         color="primary"
//                                         onClick={() => { changePassword() }}
//                                         className="btn-sm waves-effect waves-light"
//                                     >
//                                         Change Password
//                                     </Button>
//                                 </div>
//                             </Col> */}

//                         </Row>
//                     </CardBody>
//                 </Card>
//                 {/* <Row>
//                     <Col md={10} className="card-title mb-4" style={{ textAlign: 'left' }}>
//                         <button
//                             style={{ paddingLeft: 'inherit' }}
//                             onClick={() => { edit_user_info() }}
//                             type="button"
//                             className="btn btn-success  btn-label"
//                         >
//                             <i className="mdi mdi-pencil font-size-7"></i> Edit User Profile
//                         </button>
//                     </Col>

//                     <Col md={2} className="card-title mb-4" style={{ textAlign: 'right' }}>

//                     </Col>
//                 </Row> */}

//                 {/* <Row>
//                     <Col>
//                         <Row >
//                             <Col md={2}>
//                                 <Button color="dark">
//                                     Back
//                                 </Button>
//                             </Col>
//                         </Row>
                                        
//                     </Col>

//                     </Row> */}
                   
//                     {
//                         renderHeader &&
//                         <Header/>
//                     }

//                 <Card>
//                     <CardBody>
//                         <Form
//                             className="form-horizontal"
//                             onSubmit={(e) => {
//                                 e.preventDefault();
//                                 validation.handleSubmit();
//                                 return false;
//                             }}
//                         >
//                             <Row className="my-4">
//                                 <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>User Information</div>
//                                 <hr className="my-2" />
//                             </Row>
//                             <Row>
//                                 <Col md={12}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Full Name :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="firstname"
//                                             // value={name}
//                                             className="form-control"
//                                             placeholder="Enter First Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.firstname || ""}
//                                             disabled={previewUser}
//                                             invalid={
//                                                 validation.touched.firstname && validation.errors.firstname ? true : false
//                                             }
//                                         />
//                                         {validation.touched.firstname && validation.errors.firstname ? (
//                                             <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
//                                         ) : null}
//                                     </div>
//                                 </Col>
//                                 {/* <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Last Name :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="lastname"
//                                             className="form-control"
//                                             placeholder="Enter Last Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.lastname || ""}
//                                             disabled={previewUser}
//                                             invalid={
//                                                 validation.touched.lastname && validation.errors.lastname ? true : false
//                                             }
//                                         />
//                                         {validation.touched.lastname && validation.errors.lastname ? (
//                                             <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
//                                         ) : null}
//                                     </div>
//                                 </Col> */}
//                             </Row>
//                             <br />
//                             <Row>
//                                 <Col md={12}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Address:</Label>
//                                         <Input
//                                             name="addrss_1"
//                                             className="form-control"
//                                             placeholder="Enter Address Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             disabled={previewUser}
//                                             value={validation.values.addrss_1 || ""}
//                                         />
//                                     </div>

//                                 </Col>
//                             </Row>
//                             <br />
//                             <Row>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">City:</Label>
//                                         <Input
//                                             name="city"
//                                             className="form-control"
//                                             placeholder="Enter City Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.city || ""}
//                                             disabled={previewUser}
//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">State :</Label>
//                                         <Input
//                                             name="state"
//                                             className="form-control"
//                                             placeholder="Enter State Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.state || ""}
//                                             disabled={previewUser}
//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Country:</Label>
//                                         <Input
//                                             name="country"
//                                             className="form-control"
//                                             placeholder="Enter Country Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.country || ""}
//                                             disabled={previewUser}
//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <br />
//                             <Row>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Email ID :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="email_id"
//                                             className="form-control"
//                                             placeholder="Enter Email ID"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.email_id || ""}
//                                             disabled={true}
//                                             invalid={
//                                                 validation.touched.email_id && validation.errors.email_id ? true : false
//                                             }
//                                         />
//                                         {validation.touched.email_id && validation.errors.email_id ? (
//                                             <FormFeedback type="invalid">{validation.errors.email_id}</FormFeedback>
//                                         ) : null}
//                                     </div>

//                                 </Col>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Mobile Number :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="phone_number"
//                                             className="form-control"
//                                             placeholder="Enter Mobile Number"
//                                             type="number"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.phone_number || ""}
//                                             disabled={previewUser}
//                                             invalid={
//                                                 validation.touched.phone_number && validation.errors.phone_number ? true : false
//                                             }
//                                         />
//                                         {validation.touched.phone_number && validation.errors.phone_number ? (
//                                             <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
//                                         ) : null}
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <br />
//                             <Row className="my-4">
//                                 <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>Company Information</div>
//                                 <hr className="my-2" />
//                             </Row>
                          
//                             <Row>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Qualification:</Label>
//                                         <Input
//                                             name="qualification"
//                                             className="form-control"
//                                             placeholder="Enter Qualification"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.qualification || ""}
//                                             disabled={previewUser}

//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Date of Joining:</Label>
//                                         <Input
//                                             name="doj"
//                                             className="form-control"
//                                             placeholder="Enter Date of Joining"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.doj || ""}
//                                             disabled={previewUser}

//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <br />
//                             <Row>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Designation :</Label>
//                                         <Input
//                                             name="designation"
//                                             className="form-control"
//                                             placeholder="Enter your Designation"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.designation || ""}
//                                             disabled={previewUser}

//                                             // invalid={
//                                             //     validation.touched.designation && validation.errors.designation ? true : false
//                                             // }
//                                         />
//                                         {/* {validation.touched.designation && validation.errors.designation ? (
//                                             <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
//                                         ) : null} */}
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">Experience:</Label>
//                                         <Input
//                                             name="experience"
//                                             className="form-control"
//                                             placeholder="Enter your Experience"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.experience || ""}
//                                             disabled={previewUser}

//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="form-group">
//                                         <Label className="form-label">User Code:</Label>
//                                         <Input
//                                             name="usercode"
//                                             className="form-control"
//                                             placeholder="Enter your User Code"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.usercode || ""}
//                                             disabled={previewUser}

//                                             invalid={
//                                                 validation.touched.usercode && validation.errors.usercode ? true : false
//                                             }
//                                         />
//                                         {validation.touched.usercode && validation.errors.usercode ? (
//                                             <FormFeedback type="invalid">{validation.errors.usercode}</FormFeedback>
//                                         ) : null}
//                                     </div>
//                                 </Col>
//                             </Row>
//                                 <br/>
//                                 <br />
//                             {/* <Label>Upload Company Logo:</Label>
//                             <Row>
//                                 <Col md={3}>                        
//                                 {
//                                 // console.log(userInfo,"user info",clientInfo)
//                                 userInfo.role == "Super Admin" &&
//                                         <div className="dropzone-previews mt-3" id="file-previews">
//                                             {
//                                                 Object.keys(clientInfo.client_logo).length > 0 ?


//                                                     <Card style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
//                                                         <CardBody>
//                                                             <Row>
//                                                                 <Col md={12} style={{ textAlign: 'center' }}>
//                                                                     <Image className="text-center" src={clientInfo.base_url + clientInfo.client_logo.originalname} width={'50%'} height={'auto'} alt="Thumbnail" />
//                                                                 </Col>
//                                                                 <div className="mt-2 contentInfo text-center">
//                                                                     <Label style={{ marginBottom: 0 }}>File name: <span>{clientInfo.client_logo.originalname}</span></Label><br />
//                                                                     <Label style={{ marginBottom: 0 }}>Size: <span>{clientInfo.client_logo.formattedSize}</span></Label>
//                                                                 </div>
//                                                                 <div className='mt-2 text-end'>
//                                                                     <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(clientInfo.client_logo) }}>
//                                                                         <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//                                                                     </Popconfirm>
//                                                                 </div>
//                                                             </Row>
//                                                         </CardBody>
//                                                     </Card>

//                                                         :
//                                                         <>
//                                                         </>

//                                                 }
//                                             </div>

                                
                                
//                             }
                                
//                                 </Col>


//                             </Row> */}
//                          {  userInfo.role === "Super Admin" && <Label>Upload Company Logo:</Label>}
//                             <Row>
//                                 <Col md={3}>

//                                     {
//                                         showUpload && userInfo.role === "Super Admin" ?
//                                             <div className={loading ? "loading-container" : ""}>
//                                                 <div className={`dropzone ${loading ? "blur" : ""}`}>
//                                                     <Dropzone
//                                                         onDrop={(acceptedFiles) => {
//                                                             handleAcceptedFiles(acceptedFiles);
//                                                         }}
//                                                         disabled={previewUser}
//                                                         accept={[".jpg", ".jpeg", ".png"]}
//                                                         className={previewUser ? "dropzone-disabled" : ""}
//                                                     >
//                                                         {({ getRootProps, getInputProps }) => (
//                                                             <div className={`dz-message needsclick mt-2 ${previewUser ? "dropzone-blur" : ""}`} {...getRootProps()}>
//                                                                 <input {...getInputProps()} />
//                                                                 <div className="mb-3">
//                                                                     <i className="display-4 text-muted bx bxs-cloud-upload" />
//                                                                 </div>
//                                                                 <h4>Drop files here or click to upload.</h4>
//                                                                 <div className="mt-2 font-size-11 text-dark text-center">
//                                                                     <span className="text-danger">Files accepted - .jpg, .jpeg, .png </span> <br />
//                                                                     <span className="text-danger">Maximum individual file size - 1mb</span>
//                                                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                                                                 </div>
//                                                             </div>
//                                                         )}
//                                                     </Dropzone>
//                                                 </div>
//                                                 {loading && <div className="loading">Loading...</div>}
//                                             </div>
//                                             :

//                                             userInfo.role === "Super Admin" && (Object.keys(clientInfo.client_logo
//                                             ).length > 0) ?

//                                                 <div className="dropzone-previews mt-3" id="file-previews">
//                                                     <Card  style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
//                                                         <CardBody>
//                                                             <Row>
//                                                                 <Col md={12} style={{ textAlign: 'center' }}>
//                                                                     <Image  src={clientInfo.base_url + clientInfo.client_logo.originalname} width={'50%'} height={'auto'} alt="Thumbnail" />
//                                                                 </Col>
//                                                                 <div className="mt-2 contentInfo text-center">
//                                                                     <Label style={{ marginBottom: 0 }}>File name: <span>{clientInfo.client_logo.originalname}</span></Label><br />
//                                                                     <Label style={{ marginBottom: 0 }}>Size: <span>{clientInfo.client_logo.formattedSize}</span></Label>
//                                                                 </div>
//                                                                 <div className='mt-2 text-end'>
//                                                                 <span style={{ pointerEvents: previewUser ? 'none' : 'auto' }}>
//                                                                     <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(clientInfo.client_logo) }}>
//                                                                         <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//                                                                     </Popconfirm>
//                                                                     </span>
//                                                                 </div>
//                                                             </Row>
//                                                         </CardBody>
//                                                     </Card>


//                                                 </div>
//                                                 :
//                                                 selectedFiles.map((f, i) => {
//                                                     return (
//                                                         // <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"} >
//                                                         <div key={i + "-file"} >
//                                                             <Card style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
//                                                                 <CardBody>
//                                                                     <Row>
//                                                                         <Col md={12} style={{ textAlign: 'center' }}>
//                                                                             <Image className="text-center" src={f.preview} width={'50%'} height={'auto'} alt="Thumbnail" />
//                                                                         </Col>
//                                                                         <div className="mt-2 contentInfo text-center">
//                                                                             <Label style={{ marginBottom: 0 }}>File name: <span>{f.name}</span></Label><br />
//                                                                             <Label style={{ marginBottom: 0 }}>Size: <span>{f.formattedSize}</span></Label>
//                                                                         </div>
//                                                                         <div className='mt-2 text-end'>
//                                                                             <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(f) }}>
//                                                                                 <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//                                                                             </Popconfirm>
//                                                                         </div>
//                                                                     </Row>
//                                                                 </CardBody>
//                                                             </Card>
//                                                         </div>
//                                                     )
//                                                 })
//                                     }
//                                 </Col>
//                             </Row>

//                             <br />
//                             {/* <Row className="my-4">
//                                 <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>Login Information</div>
//                                 <hr className="my-2" />
//                             </Row>
//                             <Row>
//                                 <Col md={6}>
//                                     <div className="form-group">
//                                         <Label className="form-label">User Name :<label className="text-danger"> *</label></Label>
//                                         <Input
//                                             name="username"
//                                             // value={name}
//                                             className="form-control"
//                                             placeholder="Enter User Name"
//                                             type="text"
//                                             onChange={validation.handleChange}
//                                             onBlur={validation.handleBlur}
//                                             value={validation.values.username || ""}
//                                             disabled={true}
//                                             invalid={
//                                                 validation.touched.username && validation.errors.username ? true : false
//                                             }
//                                         />
//                                         {validation.touched.username && validation.errors.username ? (
//                                             <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
//                                         ) : null}
//                                         <Input name="idx" value={idx} type="hidden" />
//                                     </div>
//                                 </Col>
//                                 <Col md={4}>
//                                     <br />
//                                     <Button color="primary"
//                                         onClick={() => { changePassword() }}
//                                         style={{ marginTop: '14px' }}
//                                         className="btn-rounded ">
//                                         Change Password
//                                     </Button>
//                                 </Col>
//                             </Row> */}
//                             <br />
//                             <Row>
//                                 <Col xl={10}>

//                                 </Col>
//                                 <Col xl={2} className="text-end">
//                                 <Button type="submit" color="danger" disabled={previewUser === true? true : false}>
//                                     Update User Info
//                                 </Button>
//                                     </Col>
//                             </Row>
                            
//                         </Form>
//                         {openModal &&
//                             <Modal
//                                 size="xl"
//                                 isOpen={openModal}
//                                 toggle={() => {
//                                     changePassword("1");
//                                 }}
//                                 centered
//                             >
//                                 <div className="modal-header">
//                                     <h5
//                                         className="modal-title mt-0"
//                                     >
//                                         Change Password
//                                     </h5>
//                                     <button
//                                         onClick={() => {
//                                             setOpenModal(false);
//                                         }}
//                                         type="button"
//                                         className="close"
//                                         data-dismiss="modal"
//                                         aria-label="Close"
//                                     >
//                                         <span aria-hidden="true">&times;</span>
//                                     </button>
//                                 </div>
//                                 <br />

//                                 <Row>
//                                     <Col md={1}>
//                                       <label style={{ marginLeft: '17px' }}>Email ID:</label>

//                                     </Col>
//                                     <Col md={2}>
//                                         <label>{userInfo.email_id}</label>
//                                     </Col>
//                                 </Row>
//                                 <br />
//                                 <Form className="needs-validation"
//                                     onSubmit={(e) => {
//                                         e.preventDefault();
//                                         changepwd.handleSubmit();
//                                         return false;
//                                     }}
//                                 >
//                                     <Row>
//                                         <Col xl={11}>

//                                             <FormGroup style={{ marginLeft: '18px' }}>
//                                                 <Label >Current Password:</Label>
//                                                 <Input
//                                                     name="current_password"
//                                                     style={{ width: "106%" }}
//                                                     placeholder="Current Password"
//                                                     type={show ? "text" : "password"}
//                                                     className="form-control"
//                                                     onChange={changepwd.handleChange}
//                                                     onBlur={changepwd.handleBlur}
//                                                     value={changepwd.values.current_password || ""}
//                                                     invalid={
//                                                         changepwd.touched.current_password && changepwd.errors.current_password ? true : false
//                                                     }
//                                                 />
//                                                 {changepwd.touched.current_password && changepwd.errors.current_password ? (
//                                                     <FormFeedback type="invalid">{changepwd.errors.current_password}</FormFeedback>
//                                                 ) : null}
//                                             </FormGroup>
//                                         </Col>
//                                             <Col xl={1} style={{ marginTop: '27px' }}>
//                                             <button onClick={() => setShow(!show)} className="btn btn-light " type="button">
//                                                     <i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//                                             </Col>
//                                         </Row>
//                                         <br/>
//                                         <Row>
//                                         <Col xl={11}>
//                                             <FormGroup style={{ marginLeft: '18px' }}>
//                                                 <Label >New Password:</Label>
//                                                 <Input
//                                                     name="new_password"
//                                                     placeholder="New Password"
//                                                     style={{ width: "106%" }}
//                                                     type={show ? "text" : "password"}
//                                                     className="form-control"
//                                                     onChange={changepwd.handleChange}
//                                                     onBlur={changepwd.handleBlur}
//                                                     value={changepwd.values.new_password || ""}
//                                                     invalid={
//                                                         changepwd.touched.new_password && changepwd.errors.new_password ? true : false
//                                                     }
//                                                 />
//                                                 {changepwd.touched.new_password && changepwd.errors.new_password ? (
//                                                     <FormFeedback type="invalid">{changepwd.errors.new_password}</FormFeedback>
//                                                 ) : null}
//                                             </FormGroup>
//                                         </Col>
//                                         <Col xl={1} style={{ marginTop: '27px' }}>
//                                             <button onClick={() => setShow(!show)} className="btn btn-light " type="button">
//                                                     <i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//                                             </Col>
//                                         </Row>
//                                         <br/>
//                                         <Row>
//                                         <Col xl={11}>
//                                             <FormGroup style={{ marginLeft: '18px' }}>
//                                                 <Label >Confirm Password:</Label>
//                                                 <Input
//                                                     name="confirm_password"
//                                                     placeholder="Confirm Password"
//                                                     style={{ width: "106%" }}
//                                                     type={show ? "text" : "password"}
//                                                     className="form-control"
//                                                     onChange={changepwd.handleChange}
//                                                     onBlur={changepwd.handleBlur}
//                                                     value={changepwd.values.confirm_password || ""}
//                                                     invalid={
//                                                         changepwd.touched.confirm_password && changepwd.errors.confirm_password ? true : false
//                                                     }
//                                                 />                                                  
//                                                 {changepwd.touched.confirm_password && changepwd.errors.confirm_password ? (
//                                                     <FormFeedback type="invalid">{changepwd.errors.confirm_password}</FormFeedback>
//                                                 ) : null}
//                                             </FormGroup>
//                                         </Col>
//                                         <Col xl={1} style={{ marginTop: '27px' }}>
//                                             <button onClick={() => setShow(!show)} className="btn btn-light " type="button">
//                                                     <i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//                                             </Col>
//                                         </Row>
//                                         <br/>
//                                     <Row>
//                                         <Col md={9}>
//                                         </Col>
//                                         <Col md={3}>
//                                             <Button type="submit" color="danger" style={{ marginLeft: '25px' }} onClick={() => setOpenModal(false)} >Cancel</Button>
//                                             <Button type="submit" color="success" style={{ marginLeft: '10px' }} >Change Password</Button>
//                                         </Col>

//                                     </Row>
//                                 </Form>

//                                 <br />
//                                 <br />

//                             </Modal>
//                         }


//                     </CardBody>
//                 </Card>
//             </Container>
//         </div>
//     </React.Fragment>









//         // <React.Fragment>
//         //     <div className="page-content">
//         //         <Container fluid>
//         //             {/* Render Breadcrumb */}
//         //             <Breadcrumb title="Skote" breadcrumbItem="Profile" />
//         //             <Card>
//         //                 <CardBody>
//         //                     <Row>
//         //                         <Col lg="10">
//         //                             <div className="d-flex">
//         //                                 <div className="ms-3">
//         //                                     <img
//         //                                         src={avatar}
//         //                                         alt=""
//         //                                         className="avatar-md rounded-circle img-thumbnail"
//         //                                     />
//         //                                 </div>
//         //                                 <div className="flex-grow-1 align-self-center">
//         //                                     <div className="text-muted" style={{ marginLeft: '15px' }}>
//         //                                         <h5>{name}</h5>
//         //                                         <p className="mb-1" style={{ marginTop: "-6px" }}>{email}</p>
//         //                                         <p className="mb-1" style={{ marginTop: "-5px" }}><span className="text-dark">Login ID :</span> {userInfo.username}</p>

//         //                                         {/* <p className="mb-0">Id no: #{idx}</p> */}
//         //                                     </div>
//         //                                 </div>
//         //                             </div>

//         //                         </Col>
//         //                         <Col md={2}>
//         //                             <Button color="primary"
//         //                                 onClick={() => { changePassword() }}
//         //                                 style={{ marginTop: '14px' , marginLeft:'108px' }}
//         //                                 className="btn-rounded ">
//         //                                 Change Password
//         //                             </Button>

//         //                         </Col>
//         //                     </Row>
//         //                 </CardBody>
//         //             </Card>
//         //             <Row>
//         //                 <Col md={10} className="card-title mb-4" style={{ textAlign: 'left' }}>
//         //                 <button
//         //                         style={{ paddingLeft: 'inherit' }}
//         //                         onClick={() => { edit_user_info() }}
//         //                         type="button"
//         //                         className="btn btn-success  btn-label"
//         //                     >
//         //                         <i className="mdi mdi-pencil font-size-7"></i> Edit User Profile
//         //                     </button>
//         //                     {/* <h4 className="card-title mb-4">Change User Info</h4> */}
//         //                 </Col>

//         //                 <Col md={2} className="card-title mb-4" style={{ textAlign: 'right' }}>
//         //                     {/* <button
//         //                         style={{ paddingLeft: 'inherit' }}
//         //                         onClick={() => { edit_user_info() }}
//         //                         type="button"
//         //                         className="btn btn-success  btn-label"
//         //                     >
//         //                         <i className="mdi mdi-pencil font-size-7"></i> Edit User Profile
//         //                     </button> */}

//         //                     {/* <Button color="success"
//         //                                     className="btn-rounded "><i className="mdi mdi-pencil d-block font-size-7"></i> Edit User Profile</Button> */}
//         //                 </Col>
//         //             </Row>

//         //             {/* <Row>
//         //                 <Col>
//         //                     <Row >
//         //                         <Col md={2}>
//         //                             <Button color="dark">
//         //                                 Back
//         //                             </Button>
//         //                         </Col>
//         //                     </Row>
                                            
//         //                 </Col>

//         //                 </Row> */}

//         //             <Card>
//         //                 <CardBody>
//         //                     <Form
//         //                         className="form-horizontal"
//         //                         onSubmit={(e) => {
//         //                             e.preventDefault();
//         //                             validation.handleSubmit();
//         //                             return false;
//         //                         }}
//         //                     >
//         //                         <Row className="my-4">
//         //                             <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>User Information</div>
//         //                             <hr className="my-2" />
//         //                         </Row>
//         //                         <Row>
//         //                             <Col md={6}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">First Name :<label className="text-danger"> *</label></Label>
//         //                                     <Input
//         //                                         name="firstname"
//         //                                         // value={name}
//         //                                         className="form-control"
//         //                                         placeholder="Enter First Name"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.firstname || ""}
//         //                                         disabled={previewUser}
//         //                                         invalid={
//         //                                             validation.touched.firstname && validation.errors.firstname ? true : false
//         //                                         }
//         //                                     />
//         //                                     {validation.touched.firstname && validation.errors.firstname ? (
//         //                                         <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
//         //                                     ) : null}
//         //                                 </div>
//         //                             </Col>
//         //                             <Col md={6}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">Last Name :<label className="text-danger"> *</label></Label>
//         //                                     <Input
//         //                                         name="lastname"
//         //                                         className="form-control"
//         //                                         placeholder="Enter Last Name"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.lastname || ""}
//         //                                         disabled={previewUser}
//         //                                         invalid={
//         //                                             validation.touched.lastname && validation.errors.lastname ? true : false
//         //                                         }
//         //                                     />
//         //                                     {validation.touched.lastname && validation.errors.lastname ? (
//         //                                         <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
//         //                                     ) : null}
//         //                                 </div>
//         //                             </Col>
//         //                         </Row>
//         //                         <br />
//         //                         <Row>
//         //                             <Col md={12}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">Address:</Label>
//         //                                     <Input
//         //                                         name="addrss_1"
//         //                                         className="form-control"
//         //                                         placeholder="Enter Address Name"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         disabled={previewUser}
//         //                                         value={validation.values.addrss_1 || ""}
//         //                                     />
//         //                                 </div>

//         //                             </Col>
//         //                         </Row>
//         //                         <br />
//         //                         <Row>
//         //                             <Col md={4}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">City:</Label>
//         //                                     <Input
//         //                                         name="city"
//         //                                         className="form-control"
//         //                                         placeholder="Enter City Name"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.city || ""}
//         //                                         disabled={previewUser}
//         //                                     />
//         //                                 </div>
//         //                             </Col>
//         //                             <Col md={4}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">State :</Label>
//         //                                     <Input
//         //                                         name="state"
//         //                                         className="form-control"
//         //                                         placeholder="Enter State Name"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.state || ""}
//         //                                         disabled={previewUser}
//         //                                     />
//         //                                 </div>
//         //                             </Col>
//         //                             <Col md={4}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">Country:</Label>
//         //                                     <Input
//         //                                         name="country"
//         //                                         className="form-control"
//         //                                         placeholder="Enter Country Name"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.country || ""}
//         //                                         disabled={previewUser}
//         //                                     />
//         //                                 </div>
//         //                             </Col>
//         //                         </Row>
//         //                         <br />
//         //                         <Row>
//         //                             <Col md={6}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">Email ID :<label className="text-danger"> *</label></Label>
//         //                                     <Input
//         //                                         name="email_id"
//         //                                         className="form-control"
//         //                                         placeholder="Enter Email ID"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.email_id || ""}
//         //                                         disabled={true}
//         //                                         invalid={
//         //                                             validation.touched.email_id && validation.errors.email_id ? true : false
//         //                                         }
//         //                                     />
//         //                                     {validation.touched.email_id && validation.errors.email_id ? (
//         //                                         <FormFeedback type="invalid">{validation.errors.email_id}</FormFeedback>
//         //                                     ) : null}
//         //                                 </div>

//         //                             </Col>
//         //                             <Col md={6}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">Mobile Number :<label className="text-danger"> *</label></Label>
//         //                                     <Input
//         //                                         name="phone_number"
//         //                                         className="form-control"
//         //                                         placeholder="Enter Mobile Number"
//         //                                         type="number"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.phone_number || ""}
//         //                                         disabled={previewUser}
//         //                                         invalid={
//         //                                             validation.touched.phone_number && validation.errors.phone_number ? true : false
//         //                                         }
//         //                                     />
//         //                                     {validation.touched.phone_number && validation.errors.phone_number ? (
//         //                                         <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
//         //                                     ) : null}
//         //                                 </div>
//         //                             </Col>
//         //                         </Row>
//         //                         <br />
//         //                         <Row className="my-4">
//         //                             <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>Company Information</div>
//         //                             <hr className="my-2" />
//         //                         </Row>
                              
//         //                         <Row>
//         //                             <Col md={6}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">Qualification:</Label>
//         //                                     <Input
//         //                                         name="qualification"
//         //                                         className="form-control"
//         //                                         placeholder="Enter Mobile Number"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.qualification || ""}
//         //                                         disabled={previewUser}

//         //                                     />
//         //                                 </div>
//         //                             </Col>
//         //                             <Col md={6}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">Date of Joining:</Label>
//         //                                     <Input
//         //                                         name="doj"
//         //                                         className="form-control"
//         //                                         placeholder="Enter Date of Joining"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.doj || ""}
//         //                                         disabled={previewUser}

//         //                                     />
//         //                                 </div>
//         //                             </Col>
//         //                         </Row>
//         //                         <br />
//         //                         <Row>
//         //                             <Col md={4}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">Designation :</Label>
//         //                                     <Input
//         //                                         name="designation"
//         //                                         className="form-control"
//         //                                         placeholder="Enter your Designation"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.designation || ""}
//         //                                         disabled={previewUser}

//         //                                         // invalid={
//         //                                         //     validation.touched.designation && validation.errors.designation ? true : false
//         //                                         // }
//         //                                     />
//         //                                     {/* {validation.touched.designation && validation.errors.designation ? (
//         //                                         <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
//         //                                     ) : null} */}
//         //                                 </div>
//         //                             </Col>
//         //                             <Col md={4}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">Experience:</Label>
//         //                                     <Input
//         //                                         name="experience"
//         //                                         className="form-control"
//         //                                         placeholder="Enter your Experience"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.experience || ""}
//         //                                         disabled={previewUser}

//         //                                     />
//         //                                 </div>
//         //                             </Col>
//         //                             <Col md={4}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">User Code:</Label>
//         //                                     <Input
//         //                                         name="usercode"
//         //                                         className="form-control"
//         //                                         placeholder="Enter your User Code"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.usercode || ""}
//         //                                         disabled={previewUser}

//         //                                         invalid={
//         //                                             validation.touched.usercode && validation.errors.usercode ? true : false
//         //                                         }
//         //                                     />
//         //                                     {validation.touched.usercode && validation.errors.usercode ? (
//         //                                         <FormFeedback type="invalid">{validation.errors.usercode}</FormFeedback>
//         //                                     ) : null}
//         //                                 </div>
//         //                             </Col>
//         //                         </Row>
//         //                             <br/>
//         //                             <br />
//         //                         {/* <Label>Upload Company Logo:</Label>
//         //                         <Row>
//         //                             <Col md={3}>                        
//         //                             {
//         //                             // console.log(userInfo,"user info",clientInfo)
//         //                             userInfo.role == "Super Admin" &&
//         //                                     <div className="dropzone-previews mt-3" id="file-previews">
//         //                                         {
//         //                                             Object.keys(clientInfo.client_logo).length > 0 ?


//         //                                                 <Card style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
//         //                                                     <CardBody>
//         //                                                         <Row>
//         //                                                             <Col md={12} style={{ textAlign: 'center' }}>
//         //                                                                 <Image className="text-center" src={clientInfo.base_url + clientInfo.client_logo.originalname} width={'50%'} height={'auto'} alt="Thumbnail" />
//         //                                                             </Col>
//         //                                                             <div className="mt-2 contentInfo text-center">
//         //                                                                 <Label style={{ marginBottom: 0 }}>File name: <span>{clientInfo.client_logo.originalname}</span></Label><br />
//         //                                                                 <Label style={{ marginBottom: 0 }}>Size: <span>{clientInfo.client_logo.formattedSize}</span></Label>
//         //                                                             </div>
//         //                                                             <div className='mt-2 text-end'>
//         //                                                                 <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(clientInfo.client_logo) }}>
//         //                                                                     <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//         //                                                                 </Popconfirm>
//         //                                                             </div>
//         //                                                         </Row>
//         //                                                     </CardBody>
//         //                                                 </Card>

//         //                                                     :
//         //                                                     <>
//         //                                                     </>

//         //                                             }
//         //                                         </div>

                                    
                                    
//         //                         }
                                    
//         //                             </Col>


//         //                         </Row> */}
//         //                      {  userInfo.role === "Super Admin" && <Label>Upload Company Logo:</Label>}
//         //                         <Row>
//         //                             <Col md={3}>
                                  
//         //                                 {
//         //                             showUpload && userInfo.role === "Super Admin" ?
//         //                             <div className={loading ? "loading-container" : ""}>
//         //                             <div className={`dropzone ${loading ? "blur" : ""}`}>
//         //                                 <Dropzone
//         //                                     onDrop={(acceptedFiles) => {
//         //                                         handleAcceptedFiles(acceptedFiles);
//         //                                     }}
//         //                                     accept={[".jpg", ".jpeg", ".png"]}
//         //                                 >
//         //                                     {({ getRootProps, getInputProps }) => (
//         //                                         <div className="dz-message needsclick mt-2" {...getRootProps()}>
//         //                                             <input {...getInputProps()} />
//         //                                             <div className="mb-3">
//         //                                                 <i className="display-4 text-muted bx bxs-cloud-upload" />
//         //                                             </div>
//         //                                             <h4>Drop files here or click to upload.</h4>
//         //                                             <div className="mt-2 font-size-11 text-dark text-center">
//         //                                                 <span className="text-danger">Files accepted - .jpg, .jpeg, .png </span> <br />
//         //                                                 <span className="text-danger">Maximum individual file size - 1mb</span>
//         //                                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//         //                                             </div>
//         //                                         </div>
//         //                                     )}
//         //                                 </Dropzone>
//         //                             </div>
//         //                             {loading && <div className="loading">Loading...</div>}
//         //                         </div>
//         //                                 :

                                                        
                                        
//         //                                     userInfo.role === "Super Admin" && (Object.keys(clientInfo.client_logo
//         //                                         ).length >0)?
                                                
//         //                                         <div className="dropzone-previews mt-3" id="file-previews">
//         //                                                     <Card style={{ background: '#d3d3d35e', borderRadius: '12px' }}>
//         //                                                         <CardBody>
//         //                                                             <Row>
//         //                                                                 <Col md={12} style={{ textAlign: 'center' }}>
//         //                                                                     <Image className="text-center" src={clientInfo.base_url + clientInfo.client_logo.originalname} width={'50%'} height={'auto'} alt="Thumbnail" />
//         //                                                                 </Col>
//         //                                                                 <div className="mt-2 contentInfo text-center">
//         //                                                                     <Label style={{ marginBottom: 0 }}>File name: <span>{clientInfo.client_logo.originalname}</span></Label><br />
//         //                                                                     <Label style={{ marginBottom: 0 }}>Size: <span>{clientInfo.client_logo.formattedSize}</span></Label>
//         //                                                                 </div>
//         //                                                                 <div className='mt-2 text-end'>
//         //                                                                     <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(clientInfo.client_logo) }}>
//         //                                                                         <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//         //                                                                     </Popconfirm>
//         //                                                                 </div>
//         //                                                             </Row>
//         //                                                         </CardBody>
//         //                                                     </Card>
                                                           

//         //                                         </div>
//         //                                         :
//         //                                         selectedFiles.map((f, i) => {
//         //                                             return (
//         //                                                 // <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"} >
//         //                                                 <div key={i + "-file"} >
//         //                                                             <Card style={{background: '#d3d3d35e', borderRadius: '12px'}}>
//         //                                                                 <CardBody>
//         //                                                                     <Row>
//         //                                                                         <Col md={12} style={{textAlign: 'center'}}>
//         //                                                                             <Image className="text-center" src={f.preview} width={'50%'} height={'auto'} alt="Thumbnail" />                                                                                               
//         //                                                                         </Col>
//         //                                                                          <div className="mt-2 contentInfo text-center">
//         //                                                                                 <Label style={{ marginBottom: 0 }}>File name: <span>{f.name}</span></Label><br/>
//         //                                                                                 <Label style={{ marginBottom: 0 }}>Size: <span>{f.formattedSize}</span></Label>
//         //                                                                             </div>
//         //                                                                         <div className='mt-2 text-end'>
//         //                                                                                 <Popconfirm title="Are you sure you want to delete?" okText="Yes" cancelText="No" onConfirm={() => { deleteImage(f) }}>
//         //                                                                                     <DeleteTwoTone twoToneColor="red" style={{ fontSize: '18px' }} />
//         //                                                                                 </Popconfirm>
//         //                                                                             </div>
//         //                                                                     </Row>
//         //                                                                 </CardBody>
//         //                                                             </Card>
//         //                                                 </div>
//         //                                             )
//         //                                         })
//         //                                 }
//         //                             </Col>
//         //                         </Row>

//         //                         <br />
//         //                         {/* <Row className="my-4">
//         //                             <div className="text-primary font-size-18" style={{ fontWeight: 'bold' }}>Login Information</div>
//         //                             <hr className="my-2" />
//         //                         </Row>
//         //                         <Row>
//         //                             <Col md={6}>
//         //                                 <div className="form-group">
//         //                                     <Label className="form-label">User Name :<label className="text-danger"> *</label></Label>
//         //                                     <Input
//         //                                         name="username"
//         //                                         // value={name}
//         //                                         className="form-control"
//         //                                         placeholder="Enter User Name"
//         //                                         type="text"
//         //                                         onChange={validation.handleChange}
//         //                                         onBlur={validation.handleBlur}
//         //                                         value={validation.values.username || ""}
//         //                                         disabled={true}
//         //                                         invalid={
//         //                                             validation.touched.username && validation.errors.username ? true : false
//         //                                         }
//         //                                     />
//         //                                     {validation.touched.username && validation.errors.username ? (
//         //                                         <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
//         //                                     ) : null}
//         //                                     <Input name="idx" value={idx} type="hidden" />
//         //                                 </div>
//         //                             </Col>
//         //                             <Col md={4}>
//         //                                 <br />
//         //                                 <Button color="primary"
//         //                                     onClick={() => { changePassword() }}
//         //                                     style={{ marginTop: '14px' }}
//         //                                     className="btn-rounded ">
//         //                                     Change Password
//         //                                 </Button>
//         //                             </Col>
//         //                         </Row> */}
//         //                         <br />
//         //                         <Row>
//         //                             <Col xl={10}>

//         //                             </Col>
//         //                             <Col xl={2}>
//         //                             <Button style={{marginLeft:'40%'}} type="submit" color="danger" disabled={previewUser === true? true : false}>
//         //                                 Update User Info
//         //                             </Button>
//         //                                 </Col>
//         //                         </Row>
                                
//         //                     </Form>
//         //                     {openModal &&
//         //                         <Modal
//         //                             size="xl"
//         //                             isOpen={openModal}
//         //                             toggle={() => {
//         //                                 changePassword("1");
//         //                             }}
//         //                             centered
//         //                         >
//         //                             <div className="modal-header">
//         //                                 <h5
//         //                                     className="modal-title mt-0"
//         //                                 >
//         //                                     Change Password
//         //                                 </h5>
//         //                                 <button
//         //                                     onClick={() => {
//         //                                         setOpenModal(false);
//         //                                     }}
//         //                                     type="button"
//         //                                     className="close"
//         //                                     data-dismiss="modal"
//         //                                     aria-label="Close"
//         //                                 >
//         //                                     <span aria-hidden="true">&times;</span>
//         //                                 </button>
//         //                             </div>
//         //                             <br />

//         //                             <Row>
//         //                                 <Col md={1}>
//         //                                   <label style={{ marginLeft: '17px' }}>Email ID:</label>

//         //                                 </Col>
//         //                                 <Col md={2}>
//         //                                     <label>{userInfo.email_id}</label>
//         //                                 </Col>
//         //                             </Row>
//         //                             <br />
//         //                             <Form className="needs-validation"
//         //                                 onSubmit={(e) => {
//         //                                     e.preventDefault();
//         //                                     changepwd.handleSubmit();
//         //                                     return false;
//         //                                 }}
//         //                             >
//         //                                 <Row>
//         //                                     <Col xl={11}>

//         //                                         <FormGroup style={{ marginLeft: '18px' }}>
//         //                                             <Label >Current Password:</Label>
//         //                                             <Input
//         //                                                 name="current_password"
//         //                                                 style={{ width: "106%" }}
//         //                                                 placeholder="Current Password"
//         //                                                 type={show ? "text" : "password"}
//         //                                                 className="form-control"
//         //                                                 onChange={changepwd.handleChange}
//         //                                                 onBlur={changepwd.handleBlur}
//         //                                                 value={changepwd.values.current_password || ""}
//         //                                                 invalid={
//         //                                                     changepwd.touched.current_password && changepwd.errors.current_password ? true : false
//         //                                                 }
//         //                                             />
//         //                                             {changepwd.touched.current_password && changepwd.errors.current_password ? (
//         //                                                 <FormFeedback type="invalid">{changepwd.errors.current_password}</FormFeedback>
//         //                                             ) : null}
//         //                                         </FormGroup>
//         //                                     </Col>
//         //                                         <Col xl={1} style={{ marginTop: '27px' }}>
//         //                                         <button onClick={() => setShow(!show)} className="btn btn-light " type="button">
//         //                                                 <i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//         //                                         </Col>
//         //                                     </Row>
//         //                                     <br/>
//         //                                     <Row>
//         //                                     <Col xl={11}>
//         //                                         <FormGroup style={{ marginLeft: '18px' }}>
//         //                                             <Label >New Password:</Label>
//         //                                             <Input
//         //                                                 name="new_password"
//         //                                                 placeholder="New Password"
//         //                                                 style={{ width: "106%" }}
//         //                                                 type={show ? "text" : "password"}
//         //                                                 className="form-control"
//         //                                                 onChange={changepwd.handleChange}
//         //                                                 onBlur={changepwd.handleBlur}
//         //                                                 value={changepwd.values.new_password || ""}
//         //                                                 invalid={
//         //                                                     changepwd.touched.new_password && changepwd.errors.new_password ? true : false
//         //                                                 }
//         //                                             />
//         //                                             {changepwd.touched.new_password && changepwd.errors.new_password ? (
//         //                                                 <FormFeedback type="invalid">{changepwd.errors.new_password}</FormFeedback>
//         //                                             ) : null}
//         //                                         </FormGroup>
//         //                                     </Col>
//         //                                     <Col xl={1} style={{ marginTop: '27px' }}>
//         //                                         <button onClick={() => setShow(!show)} className="btn btn-light " type="button">
//         //                                                 <i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//         //                                         </Col>
//         //                                     </Row>
//         //                                     <br/>
//         //                                     <Row>
//         //                                     <Col xl={11}>
//         //                                         <FormGroup style={{ marginLeft: '18px' }}>
//         //                                             <Label >Confirm Password:</Label>
//         //                                             <Input
//         //                                                 name="confirm_password"
//         //                                                 placeholder="Confirm Password"
//         //                                                 style={{ width: "106%" }}
//         //                                                 type={show ? "text" : "password"}
//         //                                                 className="form-control"
//         //                                                 onChange={changepwd.handleChange}
//         //                                                 onBlur={changepwd.handleBlur}
//         //                                                 value={changepwd.values.confirm_password || ""}
//         //                                                 invalid={
//         //                                                     changepwd.touched.confirm_password && changepwd.errors.confirm_password ? true : false
//         //                                                 }
//         //                                             />                                                  
//         //                                             {changepwd.touched.confirm_password && changepwd.errors.confirm_password ? (
//         //                                                 <FormFeedback type="invalid">{changepwd.errors.confirm_password}</FormFeedback>
//         //                                             ) : null}
//         //                                         </FormGroup>
//         //                                     </Col>
//         //                                     <Col xl={1} style={{ marginTop: '27px' }}>
//         //                                         <button onClick={() => setShow(!show)} className="btn btn-light " type="button">
//         //                                                 <i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off"}></i></button>
//         //                                         </Col>
//         //                                     </Row>
//         //                                     <br/>
//         //                                 <Row>
//         //                                     <Col md={9}>
//         //                                     </Col>
//         //                                     <Col md={3}>
//         //                                         <Button type="submit" color="danger" style={{ marginLeft: '25px' }} onClick={() => setOpenModal(false)} >Cancel</Button>
//         //                                         <Button type="submit" color="success" style={{ marginLeft: '10px' }} >Change Password</Button>
//         //                                     </Col>

//         //                                 </Row>
//         //                             </Form>

//         //                             <br />
//         //                             <br />

//         //                         </Modal>
//         //                     }


//         //                 </CardBody>
//         //             </Card>
//         //         </Container>
//         //     </div>
//         // </React.Fragment>
//     );
// };

// export default UserProfile
