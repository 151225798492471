import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useEffect, useState } from "react"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import urlSocket from "../../../helpers/urlSocket"
import SalesAnalytics from '../AdminDashboard/SalesAnalytics'



//Import Components
import CardUser from "./CardUser"
// import CardWelcome from "./CardWelcome"
import MiniWidget from "./MiniWidget"
// const _ = require("lodash");
import _ from 'lodash'
import Earning from '../AdminDashboard/Earning'
var randomColor = require('randomcolor');

// import Earning from "./earning"
// import SalesAnalytics from "./sales-analytics"
// import TotalSellingProduct from "./total-selling-product"
// import Tasks from "./tasks"
// import ChatBox from "./chat-box"

const DashboardSaas = props => {

  const [totalUser,setTotalUser] = useState({})
  const [userInfo,setuserInfo] = useState()
  const [clientInfo,setClientInfo] = useState({})
  const [facility,setFacility] = useState({})
  const [dashBoardList,setdashBoardList] = useState([])

const earningChartData =[31,40,36,51,49,72,69,56,68,82,,68,,76]
var color = randomColor();
 

  // const reports = [
  //   {
  //     icon: "dripicons-user-group",
  //     title: "Total Users",
  //     value: totalUser.Total_Users,
  //     //   badgeValue: "+ 0.2%",
  //     color: "success",
  //     id: '3'
  //     //   desc: "From previous period",
  //   },
  //   {
  //     icon: "mdi mdi-account-circle active-user-icon text-success",
  //     title: "Active Users",
  //     value: totalUser.active_user,
  //     //   badgeValue: "+ 0.2%",
  //     color: "success",
  //     id: '3'

  //     //   desc: "From previous period",
  //   },
  //   {
  //     icon: "mdi mdi-account-circle-outline inactive-user-icon text-danger",
  //     title: "Inactive Users",
  //     value: totalUser.in_active_user,
  //     //   badgeValue: "+ 0.2%",
  //     color: "success",
  //     id: '3'

  //     //   desc: "From previous period",
  //   },

  //   {
  //     icon: "mdi mdi-google-spreadsheet",
  //     title: "Total Templates",
  //     value: totalUser.total_templates,
  //     //   badgeValue: "+ 0.2%",
  //     color: "success",
  //     id: '5'

  //     //   desc: "From previous period",
  //   },
  //   {
  //     icon: "bx bx-check-double",
  //     title: "Published Templates",
  //     value: totalUser.published_audit,
  //     //   badgeValue: "0%",
  //     color: "warning",
  //     id: '6'

  //     //   desc: "From previous period",
  //   },
  //   {
  //     icon: "mdi mdi-note-remove",
  //     title: "UnPublished Templates",
  //     value: totalUser.un_published_audit,
  //     //   badgeValue: "0%",
  //     color: "warning",
  //     id: '6'

  //     //   desc: "From previous period",
  //   },
  //   {
  //     icon: "mdi mdi-map-marker text-danger",
  //     title: "Total Locations",
  //     value: totalUser.total_locations,
  //     color: "success",
  //     id: '4'
  //   },
  //   {
  //     icon: "mdi mdi-account-group total-roles-icon",
  //     title: "Total Roles",
  //     value: totalUser.total_roles,
  //     color: "success",
  //     id: '2'
  //   },
  //   {
  //     icon: "mdi mdi-file-check total-roles-icon",
  //     title: "Total Audits",
  //     value: totalUser.total_audits,
  //     color: "success",
  //     id: '7'
  //   }
  // ]

  useEffect(() => {
    const fetchData = async () => {
    var userInfo = JSON.parse(sessionStorage.getItem('authUser'))
    var client_info = userInfo.client_info[0]
    setClientInfo(client_info)
    // var user_facility = JSON.parse(sessionStorage.getItem('user_facilities'))
    var user_facility = await get_user_facilities(userInfo.user_data,userInfo.db_info)
    setFacility(user_facility)
    // _.intersectionBy(arr, arr2, 'id');
    var comanyInfo = userInfo.client_info[0]
    var user_data = userInfo.user_data
    var db_info = userInfo.db_info
    setuserInfo(user_data)
    db_info["user_id"]=user_data._id
    var data = { db_info }
    try {
      urlSocket.post('cog/dashboard-data', data).then((response) => {
        // console.log(data,'data')
        if(response.data.response_code == 500){
          setTotalUser(response.data)
          const reports = [
            {
              icon: "dripicons-user-group",
              title: "Total Users",
              value: response.data.Total_Users,
              //   badgeValue: "+ 0.2%",
              color: "success",
              url:'/murs',
              id: '3'
              //   desc: "From previous period",
            },
            {
              icon: "mdi mdi-account-circle active-user-icon text-success",
              title: "Active Users",
              value: response.data.active_user,
              //   badgeValue: "+ 0.2%",
              color: "success",
              url:'/murs',
              id: '3'
        
              //   desc: "From previous period",
            },
            {
              icon: "mdi mdi-account-circle-outline inactive-user-icon text-danger",
              title: "Inactive Users",
              value: response.data.in_active_user,
              url:'/murs',
              //   badgeValue: "+ 0.2%",
              color: "success",
              id: '3'
        
              //   desc: "From previous period",
            },
        
            {
              icon: "mdi mdi-google-spreadsheet",
              title: "Total Templates",
              value: response.data.total_templates,
              url:'/mngmtrtmplt',
              //   badgeValue: "+ 0.2%",
              color: "success",
              id: '5'
        
              //   desc: "From previous period",
            },
            {
              icon: "bx bx-check-double",
              title: "Published Templates",
              value: response.data.published_audit,
              url :'/mngpblhtempt',
              //   badgeValue: "0%",
              color: "warning",
              id: '6'
        
              //   desc: "From previous period",
            },
            {
              icon: "mdi mdi-note-remove",
              title: "UnPublished Templates",
              value: response.data.un_published_audit,
              url :'/mngpblhtempt',
              //   badgeValue: "0%",
              color: "warning",
              id: '6'
        
              //   desc: "From previous period",
            },
            {
              icon: "mdi mdi-map-marker text-danger",
              title: "Total Locations",
              value: response.data.total_locations,
              color: "success",
              url:'/mels',
              id: '4'
            },
            {
              icon: "mdi mdi-account-group total-roles-icon",
              title: "Total Roles",
              value: response.data.total_roles,
              color: "success",
              url:'/mroles',
              id: '2'
            },
            {
              icon: "mdi mdi-file-check total-roles-icon",
              title: "Total Audits",
              value: response.data.total_audits,
              color: "success",
              url:'/usradt',
              id: '7'
            }
          ]
          const facility_based_dashboard = reports.filter(item1 => {
            return user_facility?.some(item2 => item2.id === item1.id);
          });
          setdashBoardList(facility_based_dashboard)
        }


        
      })

    } catch (error) {

    }
  }
  fetchData()

  }, [])


const  get_user_facilities = async (user_data, db_info) => {
  
    try {
      const response = await urlSocket.post('cog/get-role-facilities', {
        encrypted_db_url: db_info.encrypted_db_url,
        user_data: user_data,
      });
       return response.data.data[0].facilities
    }
    catch(error){

    }
  }




  //meta title
  document.title = "Dashboard | AuditVista"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="px-3">

          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard"  />

          {/* Card User */}
          {
            userInfo && <CardUser user_data ={userInfo} clientInfo = {clientInfo} />
          }
          
         
          <MiniWidget reports={dashBoardList} user_data ={userInfo} />

          <Row>
          <Earning dataColors='["--bs-primary"]' />

            {/* earning */}
            {/* <Earning dataColors='["--bs-primary"]' /> */}

            {/* sales anytics */}
            <SalesAnalytics dataColors='["--bs-primary", "--bs-success", "--bs-danger"]' />

          </Row>

          <Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardSaas
