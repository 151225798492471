import axios from 'axios'

var urlSocket = axios.create({
  /* LIVE URL*/
  //  baseURL: 'http://localhost:8050/',
  // baseURL: 'https://auditvista.com/audit-vista-v2-api/',
  baseURL: 'https://www.incidentum.com/incidentum-beta-api/',


  mode: 'no-cors',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Authorization: `${process.env.REACT_APP_APIKEY}`,
  },

  timeout: 0,


})

export default urlSocket

