import React, { useEffect, useMemo, useState } from 'react'
import { getPublishConsoleConfiguration,seteditPublishedIncidentTypeList,retriveIncidentTypePublishList, setselectedEOPT, deletePublishedIncidents, publishIncidentInfo, getHierarchyDesignation, updatePublishConsole, retrivePublishedIncidentTypeList } from '../../../store/incidentReport/incdreportslice'
import { useDispatch, useSelector } from 'react-redux'
import MetaTags from "react-meta-tags";
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Multiselect } from 'multiselect-react-dropdown';
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Spinner,
    Label,
    Badge , Button
} from "reactstrap";
import Swal from 'sweetalert2';
import TableContainer from './Components/TableContainer'
import Drawer from 'react-modern-drawer'
import PublishIncidentTypeForm from './Components/PublishIncidentTypeForm';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm } from 'antd';

const PublishConsole = () => {

    const dispatch = useDispatch()
    const [designation, setDesignation] = useState([])
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const incidentReportSlice = useSelector(state => state.incdreportslice)
    const [incidentTypeList, setincidentTypeList] = useState([])
    const [drawerOpen, setdrawerOpen] = useState(false)
    const selectedEOPT = incidentReportSlice.selectedEOPT
    const [dataLoaded, setdataLoaded] = useState(true)
    console.log(incidentReportSlice, 'incidentReportSlice')

    useEffect(() => {
        // dispatch(setIncidentTypeList([]))
        dispatch(getPublishConsoleConfiguration())
        dispatch(retrivePublishedIncidentTypeList())

    }, [])

    useEffect(() => {
        if (incidentReportSlice.publishHid) {
            dispatch(getHierarchyDesignation(incidentReportSlice.publishHid))
        }
    }, [incidentReportSlice.publishHid])

    useEffect(() => {
        const listInfo = _.cloneDeep(incidentReportSlice.publishedIncidentTypeList)
        setincidentTypeList(listInfo)

    }, [incidentReportSlice.publishedIncidentTypeList])

    // const onSelectValues = (selectedList, selectedItem) => {
    //     console.log(selectedList, selectedItem, 'selectedList, selectedItem')
    //     setDesignation(selectedList)
    // }

    // const submitConsole = () => {

    //     if (designation.length > 0) {
    //         var console_info = {
    //             created_by: authUser.user_data._id,
    //             hierarchy_id: incidentReportSlice.publishHid,
    //             hierarchy_name: _.filter(incidentReportSlice.escalatedHList, { _id: incidentReportSlice.publishHid })[0].hierarchy_name,
    //             incd_resp_desgntion: designation
    //         }
    //         if (Object.keys(incidentReportSlice.selectedDesignation).length > 0) {
    //             console_info["_id"] = incidentReportSlice.selectedDesignation._id
    //         }

    //         dispatch(updatePublishConsole(console_info))

    //         Swal.fire({
    //             icon: 'success',
    //             title: 'Updated successfully',
    //             text: 'Console Updated successfully',
    //             confirmButtonColor: '#3085d6',
    //             confirmButtonText: 'Close',
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 dispatch(getPublishConsoleConfiguration())
    //                 // sessionStorage.setItem(JSON.stringify(location_info), 'endpointData')
    //                 // window.location.reload()
    //             }
    //         })
    //     }
    // }


    const selectAllUser = (rows) => {
        var _id_grp = []
        rows.map((ele, idx) => {
            if (ele.selected) {
                _id_grp.push(ele._id)
            }
        })
        dispatch(setselectedEOPT(_id_grp))

    }

    const getSelectedEOPT = (row) => {
        var updtselectedEOPT = _.cloneDeep(selectedEOPT)
        var getIdx = updtselectedEOPT.indexOf(row.original._id)
        if (getIdx === -1) {
            updtselectedEOPT.push(row.original._id)
        }
        else {
            updtselectedEOPT.splice(getIdx, 1)
        }

        dispatch(setselectedEOPT(updtselectedEOPT))


    }


    const columns =
        //  useMemo(
        //     () => 
        [
            // {
            //     Header: (cellProps) =>
            //         <div className='form-check font-size-16'>
            //             <input
            //                 className='form-check-input'
            //                 onChange={() => {
            //                     const allSelected = cellProps.page.every((row) => row.original.selected);
            //                     cellProps.page.forEach((row) => {
            //                         row.original.selected = !allSelected;
            //                     });
            //                     selectAllUser(_.map(cellProps.page, "original"))
            //                 }}
            //                 type='checkbox'
            //                 checked={cellProps.page.every((row) => row.original.selected) && (cellProps.page.length === selectedEOPT.length) && incidentTypeList.length !== 0}
            //             />
            //             <label className="form-check-label" htmlFor="checkAll"></label>
            //         </div>,
            //     accessor: '#',
            //     width: '20px',
            //     filterable: true,
            //     isSort: false,
            //     Cell: ({ row }) => {
            //         return (
            //             <div className="form-check font-size-16" >
            //                 <input className="form-check-input"
            //                     checked={
            //                         row.original.selected
            //                     }
            //                     onChange={() => {
            //                         row.original.selected = !row.original.selected;
            //                         getSelectedEOPT(row)
            //                     }
            //                     }
            //                     type="checkbox"
            //                     id="checkAll" />
            //                 <label className="form-check-label" htmlFor="checkAll"></label>
            //             </div>
            //         )

            //     }

            // },
            {
                // accessor: "incd_type_info.incd_type_name",
                accessor: (row) => {
                    return row.incd_type_info.map((ele) => ele.incd_type_name).join(', ');
                  },
                Header: "Incident Type Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.incd_type_info.map((ele,idx)=>{
                                return(
                                    <span key={idx}>{ele.incd_type_name}
                                    {idx < item.incd_type_info.length - 1  ? ', ' : ''}
                                    </span>
                                )

                            }
                        )}
                        </div>

                    )
                }
            },
            {
                accessor: "createdAt",
                Header: "Created On",
                disableFilters: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="">
                            {new Intl.DateTimeFormat("en-GB", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            }).format(new Date(item.createdAt))}
                        </div>
                    )
                },
            },
            {
                // accessor: "incd_resp_desgntion",
                accessor: (row) => {
                    return row.incd_resp_desgntion.map((ele) => ele.hirerachy_name).join(', ');
                  },
                Header: "Designation",
                disableFilters: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="">
                            {
                                item.pblsh_id !== null ?
                                    item.incd_resp_desgntion.map((ele, pos) => (
                                        <span key={pos}>{ele.hirerachy_name}</span>
                                    ))
                                    :
                                    <span >-</span>
                            }
                            {/* {new Intl.DateTimeFormat("en-GB", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            }).format(new Date(item.createdAt))} */}
                        </div>
                    )
                },
            },
            {
                accessor:'publish_status',
                Header:'Publish Status',
                disableFilters: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    let statusLabel = "";
                    let badgeVariant = "";
    
                    switch (item.publish_status) {
                        case "0":
                            statusLabel = "Not Published";
                            badgeVariant = "secondary"; // Grey
                            break;
                        case "1":
                            statusLabel = "Published";
                            badgeVariant = "success"; // Blue
                            break;
                    }
                    return(
                        <Badge 
                    className={"font-size-12 badge-soft-" + badgeVariant}
                     >
                        {statusLabel}
                    </Badge>
                    )


                }
            },
            {
                accessor: "menu",
                Header: "Action",
                disableFilters: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="">
                              <button onClick={() =>
                                 {
                                    dispatch(seteditPublishedIncidentTypeList(item))
                                    dispatch(retriveIncidentTypePublishList(true));
                                    // ;dispatch(setAutomatedIncidentId(item.incd_type_id));
                                    setdrawerOpen(true)
                                }}
                                  className="btn btn-sm btn-primary mx-1">
                            <FontAwesomeIcon icon={faEdit} />
                            
                        </button>
                        <Popconfirm
                                description="Are you sure you want to delete this?"
                                title="Warning"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={()=>{
                                    deleteIncident(item)
                                }}
                             
                                zIndex={10000}
                            >
                            {/* {
                                item.pblsh_id !== null ? */}
                                    <button className="btn btn-sm btn-danger mx-1">
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                    </Popconfirm>
                            <Popconfirm
                                description="Are you sure you want to change this Action?"
                                title="Warning"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => { 
                                    item["publish_status"] = item.publish_status == "1" ? "0" : "1"
                                      dispatch(publishIncidentInfo(item))
                                      // deleteIncident(item)
                                       }}
                                zIndex={10000}
                            >
                                    <button className={item.publish_status === "1" ? "btn btn-sm btn-secondary mx-1" : "btn btn-sm btn-success mx-1"} 
                                    // onClick={() => { 
                                    //   item["publish_status"] = item.publish_status == "1" ? "0" : "1"
                                    //     dispatch(publishIncidentInfo(item))
                                    //     // deleteIncident(item)
                                    //      }}
                                         >
                                       {item.publish_status === "1" ? "Stop" : "Publish"}
                                    </button>
                                    </Popconfirm>
                            
                            {/* {new Intl.DateTimeFormat("en-GB", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            }).format(new Date(item.createdAt))} */}
                        </div>
                    )
                },
            },
        ]
    // )



    const deleteIncident = (item) => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Do you want to delete this Incident type ?',
            showCancelButton: true,
            confirmButtonColor: '#2ba92b',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(item, 'item')
                dispatch(deletePublishedIncidents(item._id))


            }

        })



    }

    const moveToLabel = (data) => {
        console.log("moveToLabel", data, selectedEOPT, incidentTypeList)
        const matchingItems = _.filter(incidentTypeList, item => _.includes(selectedEOPT, item._id));
        var apiData = []
        matchingItems.map((ele, pos) => {
            apiData.push({
                incd_type_name: ele.incd_type_name,
                incd_type_id: ele.incd_type_id,
                incd_type_descrption: ele.incd_type_descrption,
                incd_master_id: ele._id,
                incd_resp_desgntion: data,
                incd_pubsh_h_id: incidentReportSlice.publishHid,
                incd_pubsh_by: authUser.user_data._id,
                pblsh_id: ele.pblsh_id


            })
        })
        console.log(apiData, 'matchingItems')
        dispatch(publishIncidentInfo(apiData))
        dispatch(setselectedEOPT([]))
    }

    const publishIncidentType=()=>{
        setdrawerOpen(true)
        console.log("open drawer")
        dispatch(retriveIncidentTypePublishList(false))
        
    }





    if (dataLoaded) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>AuditVista | Publish Console</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Publish Console" breadcrumbItem="Publish Console" />
                        {/* <Row>
                            <Col lg="6">
                                <Card style={{ height: "50vh" }}>
                                    <CardBody className="d-flex flex-column">
                                        <h4>Allow user to choose designation to manage submitted incidents :</h4>
                                        <Multiselect
                                            onRemove={(selectedList, selectedItem) => { onSelectValues(selectedList, selectedItem) }}
                                            onSelect={(selectedList, selectedItem) => {
                                                onSelectValues(selectedList, selectedItem);
                                            }}
                                            options={incidentReportSlice.designationList}
                                            displayValue="hirerachy_name"
                                            closeOnSelect={false}
                                            selectedValues={
                                                incidentReportSlice.selectedDesignation.incd_resp_desgntion
                                          
                                        }
                                        />
                                        <div className="d-flex justify-content-end">
                                        <button className='btn btn-success' onClick={()=>{submitConsole()}}>Submit</button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}
                        {/* {
                                incidentReportSlice.escalatedHList.length > 1 &&  */}

                        {/* } */}


                        <Row>
                            <Card>
                                <CardBody>
                                    {/* <Col md={3}>
                                    <Col>
                                        <h6>Choose Hirerachy</h6>
                                        <select 
                                        className='form-control'
                                        defaultValue={"Select"}
                                        onChange={(e)=>{dispatch(getHierarchyDesignation(e.target.value))}}
                                        >
                                            <option disabled value={"Select"}> Select</option>
                                            {
                                                incidentReportSlice.escalatedHList.map((ele,pos)=>{
                                                    return(
                                                        <option  key={pos} value={ele._id}>{ele.hierarchy_name}</option>
                                                    )
                                                })

                                            }

                                        </select>
                                    </Col>
                                
                                </Col> */}

                                    {
                                        incidentTypeList ?
                                            <Col lg="12">
                                                {/* <Card>
                                            <CardBody> */}

                                                <div className='text-end my-2 pe-2' >

                                                    <Button
                                                        color="primary"

                                                        onClick={() => {dispatch(seteditPublishedIncidentTypeList({}));publishIncidentType();console.log(incidentReportSlice,'incidentReportSlice') }}
                                                    >
                                                        Configure Incident Types
                                                      {/* Publish Incident Type */}
                                                    </Button>
                                                </div>

                                                <TableContainer
                                                    columns={columns}
                                                    data={incidentTypeList}
                                                    isGlobalFilter={true}
                                                    isAddOptions={false}
                                                    isJobListGlobalFilter={false}
                                                    customPageSize={10}
                                                    style={{ width: "100%" }}
                                                    isPagination={true}
                                                    filterable={false}
                                                    tableClass="align-middle table-nowrap table-check"
                                                    theadClass="table-light"
                                                    pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                                    // btnName={"Publish Incident Type"}
                                                    selectedEOPT={selectedEOPT}
                                                    designationList={incidentReportSlice.designationList}
                                                    moveToLabel={moveToLabel}
                                                    // dynamicBtn={true}
                                                    // btnClick={() => {dispatch(seteditPublishedIncidentTypeList({}));publishIncidentType();console.log(incidentReportSlice,'incidentReportSlice') }}
                                                // btnClick={() => { dispatch(seteditIncidentTypeInfo({})); createIncidentType(); dispatch(setincidentTypeExist(false)) }}
                                                />

                                                    {
                                                        console.log(drawerOpen,'drawerOpen')
                                                    }

                                                <Drawer
                                                    open={drawerOpen}
                                                    onClose={() => { setdrawerOpen(false) }}
                                                    direction="right"
                                                    size={'700px'}
                                                    zIndex={9999}
                                                    noOverlay={false}
                                                >
                                                    <div
                                                        style={{
                                                            padding: 10,
                                                            maxHeight: '100vh',
                                                            overflow: "auto",
                                                        }}
                                                    >
                                                        {
                                                            drawerOpen &&
                                                            <PublishIncidentTypeForm
                                                                onClose={() => { setdrawerOpen(false) }}
                                                            />
                                                        }
                                                    </div>
                                                </Drawer>



                                            </Col>
                                            :
                                            <Col lg="12">
                                                {/* <Card>
                                            <CardBody style={{ height: "100vh" }}> */}
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div>Loading...</div>
                                                    <Spinner className="ms-2" color="primary" />
                                                </div>
                                                {/* </CardBody>
                                        </Card> */}
                                            </Col>
                                    }
                                </CardBody>
                            </Card>


                        </Row>
                    </Container>
                </div>
            </React.Fragment>

        )
    }
    else {
        return (<Col lg="12">
            <Card>
                <CardBody style={{ height: "100vh" }}>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div>Loading...</div>
                        <Spinner className="ms-2" color="primary" />
                    </div>
                </CardBody>
            </Card>
        </Col>)
    }

}


export default PublishConsole
