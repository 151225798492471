import React, { useState, useEffect, useRef,useMemo } from "react";
import { Card, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'; 
import PerfectScrollbar from 'react-perfect-scrollbar';
import chaticon from '../../../assets/images/icons/chat.png'
import SingleChatInput from './singlechatinput'; 
import SingleChatBox from './singlechatbox';
import GroupChatBox from "../../GroupChat/groupchatbox";
import GroupChatInput from "../../GroupChat/groupchatinput"
import GroupInfoDrawer from "../../GroupChat/groupinfodrawer"
import Drawer from '@material-ui/core/Drawer';

const ChatComponent = ({
    selectedUser,
    messages,
    userData,
    isSliderOpen,
    EditTimer,
    setMessageBox,
    replyMsg,
    forwardMsg,
    deleteMsg
}) => {

    const [selectedMessage, setSelectedMessage] = useState(null)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const ownuser = _.filter (selectedUser?.group_users,{_id  : userData.user_data._id})



    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
      };

    return (
        <div className="w-100 user-chat">
                
            {selectedUser ? (
                <Card>
                    <div className="p-4 border-bottom">
                        <Row>
                            {selectedUser && (
                                <>
                                    <Col lg="9" md="6" xs="12" className="" >
                                        <h5 className="font-size-15 mb-1">{selectedUser.sender_name}</h5>

                                        {selectedUser.type === "0" ?
                                            (<p className="text-muted mb-0">
                                                <i
                                                    className={
                                                        selectedUser.active
                                                            ? "mdi mdi-circle text-success align-middle me-2"
                                                            : "mdi mdi-circle align-middle me-1"
                                                    }
                                                />

                                                {selectedUser.active === true ? "Active" : "Inactive"}
                                            </p>) : (null)}
                                    </Col>


                                    {selectedUser.type !== "0" ?
                                        (
                                            <Col lg="3" md="6" xs="12">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={() => toggleDrawer()}
                                                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                                    >
                                                        <i className="mdi mdi-cog-outline font-size-20 text-sucess large-icon " />
                                                    </button>
                                                </div>
                                            </Col>

                                        ) : (null)
                                    }
                                    
                                    
                                            <GroupInfoDrawer selectedUser={selectedUser} userData={userData}  open={isDrawerOpen} toggle={toggleDrawer} />

                                    

                                </>
                            )}
                        </Row>
                    </div>


                    {
                        selectedUser.type === "0" ? (
                            <>
                                <SingleChatBox
                                    selectedUser={selectedUser}
                                    messages={messages}
                                    userData={userData}
                                    selectedContent={(msg, mode) => {
                                        setSelectedMessage({
                                            message: msg,
                                            mode: mode
                                        })
                                    }}
                                />

                                <SingleChatInput
                                    selectedUser={selectedUser}
                                    userData={userData}
                                    selectedContent={selectedMessage}
                                    clearAll={() => {
                                        setSelectedMessage(null)
                                    }}
                                />
                            </>
                        ) : (<>
                                <GroupChatBox
                                    selectedUser={selectedUser}
                                    messages={messages}
                                    userData={userData}
                                    selectedContent={(msg, mode) => {
                                        setSelectedMessage({
                                            message: msg,
                                            mode: mode
                                        })
                                    }}
                                />
                                {
                                    ownuser[0]?.status === "0" ? (<GroupChatInput
                                        selectedUser={selectedUser}
                                        userData={userData}
                                        selectedContent={selectedMessage}
                                        clearAll={() => {
                                            setSelectedMessage(null)
                                        }}
                                    />) : (null)
                                }       
                        </>)
                    }
                </Card>
            ) : (
                <Card>
                    <div className="w-100 d-flex flex-column justify-content-center align-items-center" style={{ height: "93vh" }}>
                        <img src={chaticon} alt="Chat icon" style={{ width: "25%" }} className="pb-2" />
                        <p>Your conversation starts here</p>
                    </div>
                </Card>
            )}

    


        </div>
    );
};

export default ChatComponent;
