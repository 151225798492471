import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// import { Bar } from 'react-chartjs-2';
import { sortBar, barSorting, barDescending, handleDownloadBar, imgDownloadSvg, imgDownloadPng } from '../../../../store/reportd3/reportslice';

const HorizontalBarChart = (props) => {
    const dispatch = useDispatch();
    const chartRef = useRef();
    const [showOptions, setShowOptions] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [sortshowOptions, setshowsortoption] = useState(false);
    const [tableColumns, settableColumns] = useState(["year", "value"]);
    var containerWidth = props.containerWidth
    var containerHeight = props.containerHeight
    var chart_data = props.chart_data
    var i = props.id
    var label_name = props.label
    var barColor = props.chart_color;
    var YLabel = props.YLabel
    var mouseovered = props.mouseovered
    var barLabel = props.label;

    var showline = props.show_Line

    var xLabel = 'Name'
    var enable_table = props.show_table
    var svgHeight = props.chart_height
    var show_Grid = props.show_Grid
    var temp_containerWidth = props.temp_containerWidth
    var temp_containerHeight = props.temp_containerHeight
    var curved_line = props.curved_line
    var show_Square = props.show_Square
    var Ylabel = props.YLabel
    var BarWidth = props.BarWidth
    var text_color_arr = props.text_color
    const [data, setData] = useState(chart_data)

    const [mouseoverEnabled, setmouseoverEnabled] = useState(mouseovered)
    const [yLabelName, setYLabelName] = useState('value')
    const [showLine, setShowLine] = useState(showline)
    const [enabledTable, setEnabledTable] = useState(enable_table)
    const [showGridEnabled, setshowGridEnabled] = useState(show_Grid)
    const [sortData, setsortData] = useState([]);
    var show_bar_values = props.show_bar_values
    const [showValues, setShowValues] = useState(show_bar_values);
    var fullScreen_enabled = props.show_Full_Screen_toggle
    const SortArr = useSelector(state => state.reportSliceReducer);
    // const [chartWidth, setChrtWidth] = useState(BarWidth === undefined ? containerWidth : '500')
console.log('barLabel', barLabel,props)
    useEffect(() => {
        if (chart_data !== undefined && chart_data.length > 0) {
            setData(chart_data)
            setmouseoverEnabled(mouseovered)
            setShowLine(showline)
            setEnabledTable(enable_table)
            // setchart_height(svgHeight)
            setshowGridEnabled(show_Grid)
            setShowValues(show_bar_values)
        }
    }, [chart_data, barColor, label_name, mouseovered, showline, enable_table, svgHeight, show_Grid, show_bar_values, fullScreen_enabled])

    if (props.chart_data.length !== 0) {
        var datakeys = Object.keys(props.chart_data[0]).filter(key => key !== 'year' && key !== "_id");
        var datakeys_name = Object.keys(props.chart_data[0]).filter(key => key === 'year' && key !== "_id");
    }
    const margin = { top: 70, right: 40, bottom: 80, left: 100 };

    useEffect(() => {
        legendHolder()
    }, [Ylabel])
    useEffect(() => {
        var mod_data;
        var chart_id = i;
        console.log('YLabel 81 :>> ', YLabel);

        if (SortArr[chart_id] && SortArr[chart_id].horbarsorted) {
            mod_data = SortArr[chart_id].horbarsorted;
        } else {
            mod_data = data;
        }
        if (svgHeight !== undefined && svgHeight !== '') {
            containerHeight = containerHeight - 200
        }
        else {
            containerHeight = containerHeight
        }
        var width
        var height
        if (fullScreen_enabled !== undefined && fullScreen_enabled !== false) {
            width = temp_containerWidth - margin.left - margin.right;
            height = temp_containerHeight - margin.top - margin.bottom - (enabledTable ? 200 : 0);
            containerWidth = temp_containerWidth
            containerHeight = temp_containerHeight - (enabledTable ? 200 : 0) + margin.top
        }
        else {
            width = containerWidth - margin.left - margin.right;
            height = containerHeight - margin.top - margin.bottom;

        }
        const temp_barHeight = BarWidth !== undefined ? Number(BarWidth) : 45;
        const marginTop = margin.top;
        const marginRight = margin.right;
        const marginBottom = margin.bottom;
        const marginLeft = margin.left;
        d3.select(`#my_dataviz${i}`).selectAll("div").remove();
        d3.selectAll(`#my_dataviz${i}`).selectAll("div").remove();
        d3.select(`#my_dataviz${i}`).selectAll('div').remove();
        const extent = [
            [marginLeft, marginTop],
            [marginLeft, containerHeight - marginBottom]
        ];

        const zoom = d3.zoom()
            .scaleExtent([1, 10])
            .translateExtent(extent)
            .extent(extent)
            .on("zoom", zoomed);

        const svg = d3.select(`#my_dataviz${i}`)
            .attr('width', containerWidth)
            .attr('height', containerHeight)
            .call(zoom)

        const yScale = d3.scaleBand()
            .domain(mod_data.map(d => d.year))
            .range([containerHeight - marginBottom, margin.top])
            .padding(0.1);

        const xScale = d3.scaleLinear()
            .domain([0, d3.max(mod_data, d => d.value)])
            .nice()
            .range([0, width]);

        const g = svg.select('g');
        g.selectAll('*').remove();
        svg.attr('transform', `translate(${margin.left},${marginTop - marginBottom})`);
        g.attr('transform', `translate(${margin.left},${marginBottom - marginTop})`);

        if (show_Grid) {
            g.insert('g', ':first-child')
                .attr('class', 'grid')
                .call(d3.axisLeft(yScale).ticks(5).tickSize(-width).tickFormat(''))
                .selectAll('line')
                .attr('stroke', 'lightgrey');
        } else {
            g.selectAll('.grid').remove();
        }

        const axisLabels =
            g.append('g')
                .call(d3.axisLeft(yScale).ticks(fullScreen_enabled ? 20 : 5).tickFormat(d => typeof d === 'string' ? d.toUpperCase() : d))
                .selectAll('text')
                .style("text-anchor", "end")
                .attr("font-size", "11px")
                .attr('fill', 'black')
                .style("font-style", "normal")
                .style('cursor', 'pointer')

        let rotationAngle = 0;
        axisLabels.each(function (_, i) {
            const label = this;
            d3.select(label).on('click', function () {
                const currentRotation = rotationAngle === 0 ? -45 : 0;
                const currentAnchor = rotationAngle === 0 ? 'end' : "middle";
                axisLabels.attr('transform', `rotate(${currentRotation})`)
                    .style("text-anchor", 'end')
                rotationAngle = currentRotation;
            });
        });

        g.selectAll('.bar-label')
            .data(mod_data)
            .enter()
            .append('text')
            .attr('class', 'bar-label')
            .attr('x', d => xScale(d.value) + 5)
            .attr('y', d => yScale(d.year) + yScale.bandwidth() / 2)
            .attr('text-anchor', 'start')
            .text(d => showValues ? d.value : '')
            .attr('fill', d => text_color_arr)
            .attr('text-anchor', 'start')
            .style('font-size', '12px')

        g.selectAll('.bar')
            .data(mod_data)
            .attr('y', d => yScale(d.year))
            .attr('x', 0)
            .attr('height', yScale.bandwidth())
            .attr('width', d => xScale(d.value))
            .attr('fill', `${props.chart_color}`)

        function handleMouseOver(event, d) {
            console.log('192', 192)
            if (mouseoverEnabled) {
                d3.select(this).attr('fill', brightenColor(barColor, 0.2))
                    .style('cursor', 'pointer');
                const tooltip = d3.select(`#tooltip${i}`);
                tooltip.transition().duration(200)
                    .style("opacity", .9);
                tooltip.html(`${barLabel}: ${d.year}<br>Value: ${d.value}`)
                // tooltip.html(`${barLabel}: ${d.year}<br>Value: ${d.value}`)

                    .style("left", `${fullScreen_enabled ? event.offsetX + 30 : event.pageX + 30}px`)
                    .style("top", `${fullScreen_enabled ? event.offsetY : event.pageY}px`)
                    .style("color", "red")
                    .style("background-color", "white");
            }
        }

        function handleMousemove(event, d) {
            if (mouseoverEnabled) {
                d3.select(this)
                    .style('cursor', 'pointer');
                const tooltip = d3.select(`#tooltip${i}`);
                // tooltip.html(`Year456: ${d.year}<br>Value: ${d.value}`)
                tooltip.html(`${barLabel}: ${d.year}<br>Value: ${d.value}`)

                    .style("left", `${fullScreen_enabled ? event.pageX + 30 : event.offsetX + 30}px`)
                    .style("top", `${fullScreen_enabled ? event.pageY : event.offsetY}px`)
            }
        }

        function handleMouseOut(event, d) {
            d3.select(this).attr('fill', barColor);
            const tooltip = d3.select(`#tooltip${i}`);
            tooltip.transition().duration(500)
                .style("opacity", 0);
        }

        function brightenColor(color, factor) {
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            const brightenedR = Math.min(255, r + factor * 255);
            const brightenedG = Math.min(255, g + factor * 255);
            const brightenedB = Math.min(255, b + factor * 255);
            return `rgb(${brightenedR.toFixed(0)}, ${brightenedG.toFixed(0)}, ${brightenedB.toFixed(0)})`;
        }
        const newWidth = Math.max(temp_barHeight + margin.bottom + margin.top, containerWidth);
        // setChrtWidth(BarWidth !== undefined ? newWidth : height);

        g.selectAll('bar')
            .data(mod_data)
            .enter()
            .append('rect')
            .attr('x', 0)
            .attr('y', d => yScale(d.year))
            .attr('width', 0)
            .attr('height', yScale.bandwidth())
            .attr('fill', `${props.chart_color}`)
            .on('mouseover', handleMouseOver)
            .on('mousemove', handleMousemove)
            .on('mouseout', handleMouseOut)
            .attr('width', d => xScale(d.value));
        mod_data.forEach(d => {
            d3.select("body").append("div")
                .attr("class", "tooltip")
                .attr("id", `tooltip${d.year}`)
                .style("position", "absolute")
                .style("opacity", 0)
                .style("pointer-events", "none");
        });

        d3.select(`#my_dataviz${i}`).selectAll(".xContainer , .x-axis").remove()
        d3.selectAll(`.x-axis${i}`).remove()

        const XAxisContainer = d3.select(`#my_dataviz${i}`)
            .append("svg")
            .attr('class', `x-axis${i}`)
            .style("position", "absolute")
            .style('background-color', 'white')
            .style("top", `${containerHeight - margin.bottom}px`)
            .style("left", '0px')
            .style("width", `${containerWidth - 15}px`)
            .style("height", `${enable_table ? 100 : margin.bottom}px`);

        const backgroundRect = XAxisContainer.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", containerWidth)
            .attr("height", '100%')
            .attr("fill", "white");

        const xAxisSvg = XAxisContainer.append("svg")
            .attr('class', "xContainer")
            .attr("width", containerWidth)
            .attr("height", '100%')
            .append("g")
            .attr("transform", `translate(${100}, 0)`)
            .call(d3.axisBottom(xScale).ticks())
            .call(g => {
                g.selectAll('.domain, text')
                    .attr('stroke', fullScreen_enabled ? 'black' : 'black')
                    .style("font-size", '10px');

            });

        d3.selectAll(`.top-layer${i}`).remove()

        const TopContainer = d3.select(`#my_dataviz${i}`)
            .append("svg")
            .attr('class', `top-layer${i}`)
            .style("position", "absolute")
            .style('background-color', 'white')
            .style("top", `${0}px`)
            .style("left", '0px')
            .style("width", `${containerWidth}px`)
            .style("height", `${40}px`);

        const TopbackgroundRect = TopContainer.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", containerWidth)
            .attr("height", '100%')
            .attr("fill", "white");

        if (enable_table) {
            showTableFn(true)
        }
        else {
            showTableFn(false)
        }

        if (show_Grid) {
            g.insert('g', ':first-child')
                .attr('class', 'grid')
                .attr('transform', `translate(0, ${marginBottom})`)
                .call(d3.axisBottom(xScale).tickSize(height).tickFormat(''))
                .selectAll('line')
                .attr('stroke', 'lightgrey');
        } else {
            g.selectAll('.grid').remove();
        }

        legendHolder()


        console.log('showLine', showLine , showline)
        if (showLine) {
            const line = d3.line()
                .x(d => xScale(d.value))
                .y(d => yScale(d.year) + yScale.bandwidth() / 2);

            if (curved_line) {
                line.curve(d3.curveCatmullRom.alpha(0.5));
            }
            const path = g.append('path')
                .datum(mod_data)
                .attr('fill', 'none')
                .attr('stroke', 'blue')
                .attr('stroke-width', 2)
                .attr('d', line)
                .style('cursor', 'pointer')
                .on("mouseover", function (event, d) {
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .attr('stroke-width', 4);
                })
                .on("mouseout", function (event, d) {
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .attr('stroke-width', 2);
                });
            const totalLength = path.node().getTotalLength();
            path.attr('stroke-dasharray', `${totalLength} ${totalLength}`)
                .attr('stroke-dashoffset', totalLength)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0);

            if (!show_Square) {
                g.selectAll('bar')
                    .data(mod_data)
                    .enter()
                    .append('rect')
                    .attr('x', d => xScale(d.value) - 5)
                    .attr('y', d => yScale(d.year) + yScale.bandwidth() / 2 - 5)
                    .attr('width', 10)
                    .attr('height', 10)
                    .attr('fill', 'blue')
                    .style('cursor', 'pointer')
                    .on("mouseover", rectMouseover)
                    .on('mousemove', rectMouseover)
                    .on("mouseout", function (event, d) {
                        d3.select(this.parentNode).selectAll(".tooltip-box").remove();
                        d3.select(this.parentNode).selectAll(".tooltip-text").remove();
                    });

            }
            else {
                const circles = g.selectAll('circle')
                    .data(mod_data)
                    .enter()
                    .append('circle')
                    .attr('cx', d => xScale(d.value) + 2)
                    .attr('cy', d => yScale(d.year) + yScale.bandwidth() / 2)
                    .attr('r', 5) // Radius of the circle
                    .attr('fill', 'red')
                    .style('cursor', 'pointer')
                    .on("mouseover", circleHover)
                    .on('mousemove', circleHover)
                    .on("mouseout", function (event, d) {
                        d3.select(this.parentNode).selectAll(".tooltip-box").remove();
                        d3.select(this.parentNode).selectAll(".tooltip-text").remove();
                        d3.select(this)
                            .transition()
                            .duration(200)
                            .attr('cx', d => xScale(d.value) + 2)
                            .attr('r', 5);
                    });

                function circleHover(event, d) {
                    const parent = d3.select(this.parentNode);
                    const circle = d3.select(this);
                    const xPos = parseFloat(circle.attr('cx'));
                    const yPos = parseFloat(circle.attr('cy'));
                    const tooltipWidth = 120;
                    const tooltipHeight = 50;
                    let tooltipX = xPos + 10;
                    let tooltipY = yPos - 30;
                    if (tooltipX + tooltipWidth > width) {
                        tooltipX = xPos - tooltipWidth - 10;
                    }
                    if (tooltipX < 0) {
                        tooltipX = xPos + 10;
                    }
                    if (tooltipY < 0) {
                        tooltipY = yPos + 10;
                    }
                    if (tooltipY + tooltipHeight > height) {
                        tooltipY = yPos - tooltipHeight - 10;
                    }

                    const tooltip = parent.append("rect")
                        .attr("class", "tooltip-box")
                        .attr("x", tooltipX)
                        .attr("y", tooltipY)
                        .attr("height", tooltipHeight)
                        .attr("width", tooltipWidth)
                        .attr("fill", "white")
                        .attr("stroke", "black")
                        .attr("stroke-width", 1);

                    parent.append("text")
                        .attr("class", "tooltip-text")
                        .attr("x", tooltipX + tooltipWidth / 2)
                        .attr("y", tooltipY + 20)
                        .attr("text-anchor", "middle")
                        .text(`Value: ${d.value}`)
                        .style("fill", "red");

                    parent.append("text")
                        .attr("class", "tooltip-text")
                        .attr("x", tooltipX + tooltipWidth / 2)
                        .attr("y", tooltipY + 40)
                        .attr("text-anchor", "middle")
                        .text(`${barLabel}: ${d.year}`)
                        .style("fill", "red");
                    circle
                        .transition()
                        .duration(200)
                        .attr('r', 10);
                }


                function circleMouseout(event, d) {
                    const parent = d3.select(this.parentNode);


                    parent.selectAll(".tooltip-box").remove();
                    parent.selectAll(".tooltip-text").remove();


                    d3.select(this)
                        .transition()
                        .duration(200)
                        .attr('r', 5);
                }

                d3.selectAll("circle")
                    .on("mouseover", circleHover)
                    .on("mouseout", circleMouseout);

            }

            function rectMouseover(event, d) {
                const parent = d3.select(this.parentNode);
                const rectX = xScale(d.value);
                const rectY = yScale(d.year);
                let tooltipX = rectX + 10;
                let tooltipY = rectY - 20;


                const tooltipWidth = 120;
                const tooltipHeight = 40;


                if (tooltipX + tooltipWidth > width) {
                    tooltipX = rectX - tooltipWidth - 10;
                }


                if (tooltipX < 0) {
                    tooltipX = rectX + 10;
                }


                if (tooltipY < 0) {
                    tooltipY = rectY + 20;
                }


                const tooltipGroup = parent.append("g")
                    .attr("class", "tooltip-group")
                    .attr("transform", `translate(${tooltipX}, ${tooltipY})`);


                const tooltipRect = tooltipGroup.append("rect")
                    .attr("class", "tooltip-box")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("height", tooltipHeight)
                    .attr("width", tooltipWidth)
                    .attr("fill", "white")
                    .attr("stroke", "black")
                    .attr("stroke-width", 1);


                tooltipGroup.append("text")
                    .attr("class", "tooltip-text")
                    .attr("x", tooltipWidth / 2)
                    .attr("y", 15)
                    .attr("text-anchor", "middle")
                    .text(`Value: ${d.value}`)
                    .style("fill", "red");


                tooltipGroup.append("text")
                    .attr("class", "tooltip-text")
                    .attr("x", tooltipWidth / 2)
                    .attr("y", 35)
                    .attr("text-anchor", "middle")
                    .text(`${barLabel}: ${d.year}`)
                    .style("fill", "red");


                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr('width', 10)
                    .attr('height', 10);
            }
        } else {
            g.selectAll('.node').remove();
            g.select('path').remove();
        }
        var deltaY

        function zoomed(event) {
            const newYDomain = yScale.domain().map(d => d);
            const newYScale = yScale.domain(newYDomain);
            g.selectAll('*').remove()
            g.append('g').attr('height', height)

            yScale.range([containerHeight - marginBottom, marginTop].map(d => event.transform.applyY(d)));
            g.selectAll('bar')
                .data(mod_data)
                .enter()
                .append('rect')
                .attr('x', 0)
                .attr('y', d => newYScale(d.year))
                .attr('width', 0)
                .attr('height', yScale.bandwidth())
                .attr('fill', `${props.chart_color}`)
                .on('mouseover', handleMouseOver)
                .on('mousemove', handleMousemove)
                .on('mouseleave', handleMouseOut)
                .attr('width', d => xScale(d.value))

            const axisLabels =
                g.append('g')
                    .call(d3.axisLeft(yScale).ticks(fullScreen_enabled ? 20 : 5).tickFormat(d => typeof d === 'string' ? d.toUpperCase() : d))
                    .selectAll('text')
                    .style("text-anchor", "end")
                    .attr("font-size", "11px")
                    .attr('fill', 'black')
                    .style("font-style", "normal")
                    .style('cursor', 'pointer')

            let rotationAngle = 0;
            axisLabels.each(function (_, i) {
                const label = this;
                d3.select(label).on('click', function () {
                    const currentRotation = rotationAngle === 0 ? -45 : 0;
                    const currentAnchor = rotationAngle === 0 ? 'end' : "middle";
                    axisLabels.attr('transform', `rotate(${currentRotation})`)
                        .style("text-anchor", 'end')
                    rotationAngle = currentRotation;
                });
            });

            g.selectAll('.bar-label')
                .data(mod_data)
                .enter()
                .append('text')
                .attr('class', 'bar-label')
                .attr('x', d => xScale(d.value) + 5)
                .attr('y', d => yScale(d.year) + yScale.bandwidth() / 2)
                .attr('text-anchor', 'start')
                .text(d => showValues ? d.value : '')
                .attr('text-anchor', 'start')
                .style('font-size', '12px')
                .attr('fill', d => text_color_arr)
            if (show_Grid) {
                g.insert('g', ':first-child')
                    .attr('class', 'grid')
                    .attr('transform', `translate(0, ${30})`)
                    .call(d3.axisBottom(xScale).tickSize(containerHeight - marginBottom).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'lightgrey');

                g.insert('g', ':first-child')
                    .attr('class', 'grid')
                    .call(d3.axisLeft(yScale).ticks(5).tickSize(-width).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'lightgrey');


            } else {
                g.selectAll('.grid').remove();
            }
            if (showLine) {
                const line = d3.line()
                    .x(d => xScale(d.value))
                    .y(d => yScale(d.year) + yScale.bandwidth() / 2);

                if (curved_line) {
                    line.curve(d3.curveCatmullRom.alpha(0.5));
                }
                const path = g.append('path')
                    .datum(mod_data)
                    .attr('fill', 'none')
                    .attr('stroke', 'blue')
                    .attr('stroke-width', 2)
                    .attr('d', line)
                    .style('cursor', 'pointer')
                    .on("mouseover", function (event, d) {
                        d3.select(this)
                            .transition()
                            .duration(200)
                            .attr('stroke-width', 4);
                    })
                    .on("mouseout", function (event, d) {
                        d3.select(this)
                            .transition()
                            .duration(200)
                            .attr('stroke-width', 2);
                    });
                const totalLength = path.node().getTotalLength();
                path.attr('stroke-dasharray', `${totalLength} ${totalLength}`)
                    .attr('stroke-dashoffset', totalLength)
                    .transition()
                    .duration(1000)
                    .ease(d3.easeLinear)
                    .attr('stroke-dashoffset', 0);

                if (!show_Square) {
                    g.selectAll('bar')
                        .data(mod_data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.value) - 5)
                        .attr('y', d => yScale(d.year) + yScale.bandwidth() / 2 - 5)
                        .attr('width', 10)
                        .attr('height', 10)
                        .attr('fill', 'blue')
                        .style('cursor', 'pointer')
                        .on("mouseover", rectMouseover)
                        .on('mousemove', rectMouseover)
                        .on("mouseout", function (event, d) {
                            d3.select(this.parentNode).selectAll(".tooltip-box").remove();
                            d3.select(this.parentNode).selectAll(".tooltip-text").remove();
                        });

                }
                else {
                    const circles = g.selectAll('circle')
                        .data(mod_data)
                        .enter()
                        .append('circle')
                        .attr('cx', d => xScale(d.value) + 2)
                        .attr('cy', d => yScale(d.year) + yScale.bandwidth() / 2)
                        .attr('r', 5)
                        .attr('fill', 'red')
                        .style('cursor', 'pointer')
                        .on("mouseover", circleHover)
                        .on('mousemove', circleHover)
                        .on("mouseout", function (event, d) {
                            d3.select(this.parentNode).selectAll(".tooltip-box").remove();
                            d3.select(this.parentNode).selectAll(".tooltip-text").remove();
                            d3.select(this)
                                .transition()
                                .duration(200)
                                .attr('cx', d => xScale(d.value) + 2)
                                .attr('r', 5);
                        });

                    function circleHover(event, d) {
                        const parent = d3.select(this.parentNode);
                        const circle = d3.select(this);
                        const xPos = parseFloat(circle.attr('cx'));
                        const yPos = parseFloat(circle.attr('cy'));
                        const tooltipWidth = 120;
                        const tooltipHeight = 50;
                        let tooltipX = xPos + 10;
                        let tooltipY = yPos - 30;

                        if (tooltipX + tooltipWidth > width) {
                            tooltipX = xPos - tooltipWidth - 10;
                        }

                        if (tooltipX < 0) {
                            tooltipX = xPos + 10;
                        }
                        if (tooltipY < 0) {
                            tooltipY = yPos + 10;
                        }
                        if (tooltipY + tooltipHeight > height) {
                            tooltipY = yPos - tooltipHeight - 10;
                        }

                        const tooltip = parent.append("rect")
                            .attr("class", "tooltip-box")
                            .attr("x", tooltipX)
                            .attr("y", tooltipY)
                            .attr("height", tooltipHeight)
                            .attr("width", tooltipWidth)
                            .attr("fill", "white")
                            .attr("stroke", "black")
                            .attr("stroke-width", 1);
                        parent.append("text")
                            .attr("class", "tooltip-text")
                            .attr("x", tooltipX + tooltipWidth / 2)
                            .attr("y", tooltipY + 20)
                            .attr("text-anchor", "middle")
                            .text(`Value: ${d.value}`)
                            .style("fill", "red");
                        parent.append("text")
                            .attr("class", "tooltip-text")
                            .attr("x", tooltipX + tooltipWidth / 2)
                            .attr("y", tooltipY + 40)
                            .attr("text-anchor", "middle")
                            .text(`${barLabel}: ${d.year}`)
                            .style("fill", "red");
                        circle
                            .transition()
                            .duration(200)
                            .attr('r', 10);
                    }

                    function circleMouseout(event, d) {
                        const parent = d3.select(this.parentNode);
                        parent.selectAll(".tooltip-box").remove();
                        parent.selectAll(".tooltip-text").remove();

                        d3.select(this)
                            .transition()
                            .duration(200)
                            .attr('r', 5);
                    }

                    d3.selectAll("circle")
                        .on("mouseover", circleHover)
                        .on("mouseout", circleMouseout);

                }

                function rectMouseover(event, d) {
                    const parent = d3.select(this.parentNode);
                    const rectX = xScale(d.value);
                    const rectY = yScale(d.year);
                    let tooltipX = rectX + 10;
                    let tooltipY = rectY - 20;
                    const tooltipWidth = 120;
                    const tooltipHeight = 40;

                    if (tooltipX + tooltipWidth > width) {
                        tooltipX = rectX - tooltipWidth - 10;
                    }

                    if (tooltipX < 0) {
                        tooltipX = rectX + 10;
                    }
                    if (tooltipY < 0) {
                        tooltipY = rectY + 20;
                    }

                    const tooltipGroup = parent.append("g")
                        .attr("class", "tooltip-group")
                        .attr("transform", `translate(${tooltipX}, ${tooltipY})`);

                    const tooltipRect = tooltipGroup.append("rect")
                        .attr("class", "tooltip-box")
                        .attr("x", 0)
                        .attr("y", 0)
                        .attr("height", tooltipHeight)
                        .attr("width", tooltipWidth)
                        .attr("fill", "white")
                        .attr("stroke", "black")
                        .attr("stroke-width", 1);
                    tooltipGroup.append("text")
                        .attr("class", "tooltip-text")
                        .attr("x", tooltipWidth / 2)
                        .attr("y", 15)
                        .attr("text-anchor", "middle")
                        .text(`Value: ${d.value}`)
                        .style("fill", "red");
                    tooltipGroup.append("text")
                        .attr("class", "tooltip-text")
                        .attr("x", tooltipWidth / 2)
                        .attr("y", 35)
                        .attr("text-anchor", "middle")
                        .text(`${barLabel}: ${d.year}`)
                        .style("fill", "red");
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .attr('width', 10)
                        .attr('height', 10);
                }

            } else {
                g.selectAll('.node').remove();
                g.select('path').remove();
            }
        }

        const handleResetButtonClick = () => {
            const newYDomain = yScale.domain().map(d => d);
            const newYScale = yScale.domain(newYDomain).range([containerHeight - marginBottom, marginTop]);
            const newXScale = xScale.range([0, containerWidth - marginLeft - marginRight]);
            g.selectAll('*').remove();
            g.selectAll('bar')
                .data(mod_data)
                .enter()
                .append('rect')
                .attr('x', 0)
                .attr('y', d => newYScale(d.year))
                .attr('width', d => newXScale(d.value))
                .attr('height', yScale.bandwidth())
                .attr('fill', `${props.chart_color}`)
                .on('mouseover', handleMouseOver)
                .on('mousemove', handleMousemove)
                .on('mouseleave', handleMouseOut);
            g.append('g')
                .call(d3.axisLeft(newYScale).ticks(fullScreen_enabled ? 20 : 5).tickFormat(d => typeof d === 'string' ? d.toUpperCase() : d))
                .selectAll('text')
                .style("text-anchor", "end")
                .attr("font-size", "11px")
                .attr('fill', 'black')
                .style("font-style", "normal");





            if (showLine) {
                const line = d3.line()
                    .x(d => xScale(d.value))
                    .y(d => yScale(d.year) + yScale.bandwidth() / 2);

                if (curved_line) {
                    line.curve(d3.curveCatmullRom.alpha(0.5));
                }

                const path = g.append('path')
                    .datum(mod_data)
                    .attr('fill', 'none')
                    .attr('stroke', 'blue')
                    .attr('stroke-width', 2)
                    .attr('d', line)
                    .style('cursor', 'pointer')
                    .on("mouseover", function (event, d) {
                        d3.select(this)
                            .transition()
                            .duration(200)
                            .attr('stroke-width', 4);
                    })
                    .on("mouseout", function (event, d) {
                        d3.select(this)
                            .transition()
                            .duration(200)
                            .attr('stroke-width', 2);
                    });

                const totalLength = path.node().getTotalLength();
                path.attr('stroke-dasharray', `${totalLength} ${totalLength}`)
                    .attr('stroke-dashoffset', totalLength)
                    .transition()
                    .duration(1000)
                    .ease(d3.easeLinear)
                    .attr('stroke-dashoffset', 0);

                if (!show_Square) {
                    g.selectAll('bar')
                        .data(mod_data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.value) - 5)
                        .attr('y', d => yScale(d.year) + yScale.bandwidth() / 2 - 5)
                        .attr('width', 10)
                        .attr('height', 10)
                        .attr('fill', 'blue')
                        .style('cursor', 'pointer')
                        .on("mouseover", rectMouseover)
                        .on('mousemove', rectMouseover)
                        .on("mouseout", function (event, d) {
                            d3.select(this.parentNode).selectAll(".tooltip-box").remove();
                            d3.select(this.parentNode).selectAll(".tooltip-text").remove();
                        });

                }
                else {
                    const circles = g.selectAll('circle')
                        .data(mod_data)
                        .enter()
                        .append('circle')
                        .attr('cx', d => xScale(d.value) + 2)
                        .attr('cy', d => yScale(d.year) + yScale.bandwidth() / 2)
                        .attr('r', 5)
                        .attr('fill', 'red')
                        .style('cursor', 'pointer')
                        .on("mouseover", circleHover)
                        .on('mousemove', circleHover)
                        .on("mouseout", function (event, d) {
                            d3.select(this.parentNode).selectAll(".tooltip-box").remove();
                            d3.select(this.parentNode).selectAll(".tooltip-text").remove();
                            d3.select(this)
                                .transition()
                                .duration(200)
                                .attr('cx', d => xScale(d.value) + 2)
                                .attr('r', 5);
                        });

                    function circleHover(event, d) {
                        const parent = d3.select(this.parentNode);
                        const circle = d3.select(this);
                        const xPos = parseFloat(circle.attr('cx'));
                        const yPos = parseFloat(circle.attr('cy'));
                        const tooltipWidth = 120;
                        const tooltipHeight = 50;
                        let tooltipX = xPos + 10;
                        let tooltipY = yPos - 30;
                        if (tooltipX + tooltipWidth > width) {
                            tooltipX = xPos - tooltipWidth - 10;
                        }


                        if (tooltipX < 0) {
                            tooltipX = xPos + 10;
                        }


                        if (tooltipY < 0) {
                            tooltipY = yPos + 10;
                        }


                        if (tooltipY + tooltipHeight > height) {
                            tooltipY = yPos - tooltipHeight - 10;
                        }


                        const tooltip = parent.append("rect")
                            .attr("class", "tooltip-box")
                            .attr("x", tooltipX)
                            .attr("y", tooltipY)
                            .attr("height", tooltipHeight)
                            .attr("width", tooltipWidth)
                            .attr("fill", "white")
                            .attr("stroke", "black")
                            .attr("stroke-width", 1);


                        parent.append("text")
                            .attr("class", "tooltip-text")
                            .attr("x", tooltipX + tooltipWidth / 2)
                            .attr("y", tooltipY + 20)
                            .attr("text-anchor", "middle")
                            .text(`Value: ${d.value}`)
                            .style("fill", "red");


                        parent.append("text")
                            .attr("class", "tooltip-text")
                            .attr("x", tooltipX + tooltipWidth / 2)
                            .attr("y", tooltipY + 40)
                            .attr("text-anchor", "middle")
                            .text(`${barLabel}: ${d.year}`)
                            .style("fill", "red");

                        circle
                            .transition()
                            .duration(200)
                            .attr('r', 10);
                    }
                }

                function rectMouseover(event, d) {
                    const parent = d3.select(this.parentNode);
                    const rectX = xScale(d.value);
                    const rectY = yScale(d.year);
                    let tooltipX = rectX + 10;
                    let tooltipY = rectY - 20;

                    const tooltipWidth = 120;
                    const tooltipHeight = 40;

                    if (tooltipX + tooltipWidth > width) {
                        tooltipX = rectX - tooltipWidth - 10;
                    }
                    if (tooltipX < 0) {
                        tooltipX = rectX + 10;
                    }
                    if (tooltipY < 0) {
                        tooltipY = rectY + 20;
                    }
                    const tooltipGroup = parent.append("g")
                        .attr("class", "tooltip-group")
                        .attr("transform", `translate(${tooltipX}, ${tooltipY})`);
                    const tooltipRect = tooltipGroup.append("rect")
                        .attr("class", "tooltip-box")
                        .attr("x", 0)
                        .attr("y", 0)
                        .attr("height", tooltipHeight)
                        .attr("width", tooltipWidth)
                        .attr("fill", "white")
                        .attr("stroke", "black")
                        .attr("stroke-width", 1);
                    tooltipGroup.append("text")
                        .attr("class", "tooltip-text")
                        .attr("x", tooltipWidth / 2)
                        .attr("y", 15)
                        .attr("text-anchor", "middle")
                        .text(`Value: ${d.value}`)
                        .style("fill", "red");


                    tooltipGroup.append("text")
                        .attr("class", "tooltip-text")
                        .attr("x", tooltipWidth / 2)
                        .attr("y", 35)
                        .attr("text-anchor", "middle")
                        .text(`${barLabel}: ${d.year}`)
                        .style("fill", "red");


                    d3.select(this)
                        .transition()
                        .duration(200)
                        .attr('width', 10)
                        .attr('height', 10);
                }
            } else {
                g.selectAll('.node').remove();
                g.select('path').remove();
            }

            if (show_Grid) {
                g.insert('g', ':first-child')
                    .attr('class', 'grid')
                    .attr('transform', `translate(0, ${70})`)
                    .call(d3.axisBottom(xScale).tickSize(height).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'red');

                g.insert('g', ':first-child')
                    .attr('class', 'grid')
                    .call(d3.axisLeft(yScale).ticks(5).tickSize(-width).tickFormat(''))
                    .selectAll('line')
                    .attr('stroke', 'lightgrey');
            } else {
                g.selectAll('.grid').remove();
            }
            g.selectAll('.bar-label')
                .data(mod_data)
                .enter()
                .append('text')
                .attr('class', 'bar-label')
                .attr('x', d => xScale(d.value) + 5)
                .attr('y', d => yScale(d.year) + yScale.bandwidth() / 2)
                .attr('text-anchor', 'start')
                .text(d => showValues ? d.value : '')
                .attr('text-anchor', 'start')
                .style('font-size', '12px')

        };
        document.getElementById(`togglereset-${i}`).addEventListener('click', function (event) {
            handleResetButtonClick();
        });

    }, [containerWidth, BarWidth, containerHeight, data, barColor, mouseoverEnabled, showLine, enabledTable, showGridEnabled, show_Grid, sortData, showline, showValues, fullScreen_enabled, svgHeight, curved_line, show_Square, enable_table, SortArr, temp_containerWidth, temp_containerHeight, text_color_arr, YLabel]);

    function legendHolder() {
        console.log("1302");
        d3.selectAll(`.legends1${i}`).remove()
        d3.selectAll(`.legends${i}`).selectAll('div').remove()
        const legendWidth = 50;
        const legendRectSize = 15;
        const legendOffset = 20;
        const legendContainer = d3.selectAll(`#legend${i}`)
            .attr("class", `legends${i}`)
            .style("boxShadow", "none");
        legendContainer.append("div")
            .attr("class", "legend-rect")
            .style("width", `${legendRectSize}px`)
            .style("height", `${legendRectSize}px`)
            .style("background-color", barColor)
            .style("margin-right", "7px");

        console.log('Ylabel 437:>> ', Ylabel);

        legendContainer.append("div")
            .attr("class", "legend-text")
            .style("lineHeight", `${legendRectSize}px`)
            .text(Ylabel);
    }
    const handleMenuClick = (e) => {
        setShowOptions(!showOptions);
    };
    const handleSortIconClick = (e) => {
        setshowsortoption(!sortshowOptions)
    };
    const handleSortAscending = () => {
        var chart_id = i;
        dispatch(barSorting({ data, chart_id }));

    };
    const handleSortDescending = () => {
        var chart_id = i;
        dispatch(barDescending({ data, chart_id }));

    };
    const handleSortDefault = () => {
        dispatch(sortBar({ data: chart_data, chart_id: i }));
        setsortData([...chart_data]);
    };
    const showTableFn = async (val1) => {
        var val = true
        if (val1) {
             setEnabledTable(true)
             tabulate(data, tableColumns)
        }
        else {
            d3.select(`#tableContainer${i}.table_body`).remove();
            d3.selectAll(`#tableContainer${i}`).selectAll("table").remove();
        }

    }
    const tabulate = async (data, columns, y_axis_name) => {
        y_axis_name = y_axis_name ? y_axis_name : yLabelName;
        const header = [xLabel, y_axis_name];
        var data_exist;
        if (data !== undefined) {
            data_exist = data;
        } else {
            data_exist = chart;
        }
        var tableContainer = document.getElementById(`tableContainer${i}`);
        if (tableContainer !== null) {
            tableContainer.innerHTML = "";
        }
        var table = d3.select(`#tableContainer${i}`)
            .attr("class", "table-responsive")
            .append("table")
            .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth}px`)
        var thead = table.append("thead");
        var tbody = table.append("tbody");
        d3.select(tableContainer)
            .attr('class', 'table_body')
            .style("width", `${fullScreen_enabled ? temp_containerWidth : containerWidth}px`)
            .style("overflow-y", "scroll")
            .style("overflow-x", "hidden");

        thead.append("tr")
            .selectAll("th")
            .data(header)
            .enter()
            .append("th")
            .text(function (column) { return column; })
            .attr("style", "text-align: center")
            .style('color', 'black')
        var rows = tbody.selectAll("tr")
            .data(data_exist)
            .enter()
            .append("tr");
        var cells = rows.selectAll("td")
            .data(function (row) {
                return tableColumns.map(function (column) {
                    return { column: column, value: row[column] };
                });
            })
            .enter()
            .append("td")
            .attr("class", function (d) { return "cell " + d.column; })
            .html(function (d) { return d.value; })
            .attr("style", "text-align: center")
            .style('color', 'black')
        return table;
    }
    return (
        <>
            <div id={`tooltip${i}`} style={{ position: 'absolute', opacity: 0, background: 'lightgray', padding: '10px', borderRadius: '5px' }}></div>
            <div id={`my_dataviz${i}`} width={(fullScreen_enabled !== undefined && fullScreen_enabled !== false) ? temp_containerWidth : containerWidth}
                onMouseLeave={() => { setShowOptions(false); setshowsortoption(false); }}
            >
                <svg ref={chartRef} width={(fullScreen_enabled ? temp_containerWidth : containerWidth)} height={(fullScreen_enabled ? temp_containerHeight : containerHeight)} >
                    <g ></g>
                </svg>

            </div>

            <div id={`legend${i}`} style={{ position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'centre', marginLeft: containerWidth / 2, marginTop: enable_table ? (fullScreen_enabled ? '-290px' : '-230px') : '-40px', boxShadow: 'none' }}></div>
            {showOptions && (
                <div
                    className="download-options"
                    onMouseLeave={() => setShowOptions(false)}
                    style={{
                        position: 'absolute',
                        top: '50px',
                        right: '110px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        cursor: 'pointer',
                        justifyContent: 'center'
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseOver={(e) => { e.target.style.color = 'green'; setShowOptions(true); }} onMouseOut={(e) => e.target.style.color = 'blue'}
                >
                    <p onClick={() => handleDownloadBar('0', datakeys_name, datakeys, data)}>Download as CSV</p>
                    <p onClick={() => imgDownloadSvg(`my_dataviz${i}`)}>Download as SVG</p>
                    <p onClick={() => imgDownloadPng(`my_dataviz${i}`)} className='mt-1'>Download as PNG</p>

                </div>
            )}

            <span onMouseOver={() => { handleSortIconClick(); setShowOptions(false); }} >
                <i
                    className="bx bx-sort"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        position: 'absolute',
                        top: '9px',
                        right: '116px',
                        zIndex: '1',
                        color: '#6666B2',
                    }}
                ></i>
            </span>
            <i className="bx bx-reset"
                style={{
                    cursor: 'pointer',
                    fontSize: '20px',
                    width: '35px',
                    position: 'absolute',
                    top: '9px',
                    right: '260px',
                    zIndex: '1',
                    color: '#6666B2',
                }}

                id={`togglereset-${i}`}
            ></i>
            <span onMouseOver={() => { handleMenuClick(); setshowsortoption(false); }} >
                <i
                    className="bx bx-download"
                    style={{
                        cursor: 'pointer',
                        fontSize: '25px',
                        position: 'absolute',
                        top: '7px',
                        right: '80px',
                        color: '#6666B2',
                    }}
                ></i>
            </span>
            {sortshowOptions && (
                <div
                    className="download-options"
                    style={{

                        position: 'absolute',
                        top: '45px',
                        right: '116px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#000080',
                        padding: '10px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        zIndex: '999',
                        minWidth: '150px',
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseOver={(e) => e.target.style.color = 'green'} onMouseOut={(e) => e.target.style.color = 'blue'}
                    onMouseLeave={() => setshowsortoption(false)}
                >
                    <p onClick={() => handleSortAscending('ascending')}>Sort Ascending</p>
                    <p onClick={() => handleSortDescending('descending')}>Sort Descending</p>
                    <p onClick={() => handleSortDefault('default')} >Default Sorting</p>
                </div>
            )}
            {isLoading &&
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>}
            {enable_table ? (
                <>
                    <div style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        marginTop: 10,
                        backgroundColor: '#fff',
                        height: (fullScreen_enabled ? '240px' : '200px')

                    }} id={`tableContainer${i}`}>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default HorizontalBarChart;
