import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    Media,
    Row,
    UncontrolledDropdown,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap"

export default class PreviewDocuments extends Component {

    
    render() {
        return (
            <Row >
                {
                    this.props.images.map(item=>{
                        return(
                            <Col md={3} key={''}>
                            
                                {
                                    item.filetype === "application/zip" ?
                                        <Col >
                                            <Link to={"//"+this.props.imagePreviewUrl+item.preview} target="_blank" download>
                                                <i className="fas fa-file-archive text-warning me-2 mt-2" style={{ fontSize: 3 }} />
                                                <span>{item.originalName}</span>
                                            </Link>
                                        </Col>
                                    :
                                    item.filetype === "application/pdf" ?
                                    <Col>  
                                                <a href={`${this.props.imagePreviewUrl}${item.originalName}`} target="_blank" rel="noreferrer">
                                                    <i className="fas fa-file-pdf text-danger me-2 mt-2" style={{ fontSize: 35 }} />
                                                    <span>{item.originalName}</span>
                                                </a>
                                 
                                            {/* <a href={`${this.props.imagePreviewUrl}${item.originalName}`} target="_blank" >
                                                <i className="fas fa-file-pdf text-danger me-2 mt-2" style={{ fontSize: 35 }} />
                                                <span>{item.originalName}</span>
                                            </a> */}
                                    </Col>
                                        // <Col >
                                        // <Link to={"//"+this.props.imagePreviewUrl+item.preview} target="_blank" download>
                                        //     <i className="fas fa-file-pdf text-danger me-2" style={{ fontSize: 35 }} />
                                        //     <span>{item.originalName}</span>
                                        //     </Link>
                                        // </Col>
                                        :
                                        item.filetype === "application/xls" || item.filetype === "application/xlsx" || item.filetype === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                            <Col >
                                            <Link to={"//"+this.props.imagePreviewUrl+item.preview} target="_blank" download>
                                                <i className="fas fa-file-excel text-success me-2 mt-2" style={{ fontSize: 35 }} />
                                                <span>{item.originalName}</span>
                                                </Link>
                                            </Col>
                                            :
                                                <Col >
                                                <Link to={"//"+this.props.imagePreviewUrl+item.preview} target="_blank" download>
                                                    <i className="fas fa-file text-primary me-2 mt-2" style={{ fontSize: 35 }} />
                                                    <span>{item.originalName}</span>
                                                    </Link>
                                                </Col>
            
                                }
                            </Col>
                            
                            
                        )
                    })
                    

                }
            </Row>

        )
    }


}
