// import React from "react";
// import ReactApexChart from "react-apexcharts";
// import getChartColorsArray from "../Components/ChartsDynamicColor";
// import _ from "lodash";

// const BarChart = (props) => {
//   const colorArrayString = JSON.stringify(props.dataColors);
//   console.log(props.dataColors,'props.dataColors');
//   const getChartColorsArrayInfo = (colorArrayString) => {
//     const colorArray = JSON.parse(colorArrayString);
//     return colorArray.map(color => getComputedStyle(document.documentElement).getPropertyValue(color.trim()));
//   };
  



//   const barChartColors = getChartColorsArrayInfo(colorArrayString);;

//   // Extract series and labels from props
//   const series = [{
//     name: 'Count',
//     data: _.map(props.irDashBoardData, "count")
//   }];

//   const options = {
//     chart: {
//       height: 320,
//       type: 'bar',
//     },
//     series: series,
//     xaxis: {
//       categories: _.map(props.irDashBoardData, "name"),
//       title: {
//         text: 'Incident Status',
//       },
//     },
//     yaxis: {
//       title: {
//         text: 'Counts',
//       },
//     },
//     colors: barChartColors,
//     legend: {
//       show: true,
//       position: 'bottom',
//       horizontalAlign: 'center',
//       verticalAlign: 'middle',
//       floating: false,
//       fontSize: '14px',
//       offsetX: 0,
//     },
//     responsive: [{
//       breakpoint: 600,
//       options: {
//         chart: {
//           height: 240
//         },
//         legend: {
//           show: false
//         },
//       }
//     }]
//   };

//   return (
//     <ReactApexChart options={options} series={series} type="bar" height="320" />
//   );
// };

// export default BarChart;





import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Components/ChartsDynamicColor";
import _ from "lodash";

const PieChart = (props) => {
  console.log(props, 'props');

  // Safely fetch chart colors
  
  // getChartColorsArray(JSON.stringify([
  //   "--primary-color", // CSS variable for a custom color
  //   "#ff5733",         // Hex code
  //   "rgba(54, 162, 235, 0.6)", // RGBA color
  //   "--secondary-color", // Another CSS variable
  //   "#28a745"          // Another hex code
  // ]));

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const PieApexChartColors = Array(props.irDashBoardData.length)
  .fill()
  .map(() => generateRandomColor());
console.log(PieApexChartColors,'PieApexChartColors');

  // Ensure props.irDashBoardData is valid
  if (!props.irDashBoardData || props.irDashBoardData.length === 0) {
    return <div>No Data Available</div>;  // Early return if no data
  }

  // Map the series and labels safely
  const series = _.map(props.irDashBoardData, (item) => item.count || 0); // Ensure count is valid
  const labels = _.map(props.irDashBoardData, (item) => item.name || 'Unknown'); // Ensure name is valid

  // const options = {
  //   chart: {
  //     height: 320,
  //     type: 'pie',
  //   },
  //   series: series,
  //   labels: labels,
  //   colors: PieApexChartColors,
  //   legend: {
  //     show: true,
  //     position: 'bottom',
  //     horizontalAlign: 'center',
  //     verticalAlign: 'middle',
  //     floating: false,
  //     fontSize: '14px',
  //     offsetX: 0,
  //   },
  //   responsive: [{
  //     breakpoint: 600,
  //     options: {
  //       chart: {
  //         height: 240
  //       },
  //       legend: {
  //         show: false
  //       },
  //     }
  //   }]
  // };


  const options = {
    chart: {
      height: 320,
      type: 'pie',
      
      toolbar: {
        show: true, // Show the toolbar for download
        tools: {
          download: true, // Enable the download button
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: "pie-chart-data",
            columnDelimiter: ',',
            headerCategory: 'Category',
            headerValue: 'Value',
          },
          svg: {
            filename: "pie-chart-image",
          },
          png: {
            filename: "pie-chart-image",
          }
        },
      },
    },
    series: series, // Assuming series is defined elsewhere
    labels: labels, // Assuming labels are defined based on props.irDashBoardData
    colors: PieApexChartColors, // Assuming dynamic colors are generated for pie chart
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
      itemMargin: {
        horizontal: 10, // Space between legend items horizontally
        vertical: 5,    // Space between legend items vertically
      },
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          height: 240,
        },
        legend: {
          show: false,
        },
      },
    }],
  };

  console.log(series, 'series', labels);

  // Render the chart if data is available
  return (
    <ReactApexChart options={options} series={series} type="pie" height="320" />
  );
}

export default PieChart;















// import React from "react"
// import ReactApexChart from "react-apexcharts"
// import getChartColorsArray from "../Components/ChartsDynamicColor";

// const PieChart = (props) => {
//   console.log(props,'props');
//   const PieApexChartColors = getChartColorsArray(JSON.stringify([
//     "--primary-color", // CSS variable for a custom color
//     "#ff5733",         // Hex code
//     "rgba(54, 162, 235, 0.6)", // RGBA color
//     "--secondary-color", // Another CSS variable
//     "#28a745"          // Another hex code
//   ]));

//   const series = _.map(props.irDashBoardData,"count")
//   const options = {
//     chart: {
//       height: 320,
//       type: 'pie',
//     },
//     series: _.map(props.irDashBoardData,"count"),
//     labels: _.map(props.irDashBoardData,"name"),
//     colors: PieApexChartColors,
//     legend: {
//       show: true,
//       position: 'bottom',
//       horizontalAlign: 'center',
//       verticalAlign: 'middle',
//       floating: false,
//       fontSize: '14px',
//       offsetX: 0,
//     },
//     responsive: [{
//       breakpoint: 600,
//       options: {
//         chart: {
//           height: 240
//         },
//         legend: {
//           show: false
//         },
//       }
//     }]
//   }
//   console.log(series,'series',_.map(props.irDashBoardData,"name"),);
//   if (!props.irDashBoardData || props.irDashBoardData.length === 0) {
//     return <div>No Data Available</div>;  // Early return if no data
//   }
// else{  
//   return (
    
//     <ReactApexChart options={options} series={series} type="pie" height="320" />
//   )
// }
// }

// export default PieChart
