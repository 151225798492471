import React, { useEffect, useMemo, useState} from 'react'
import { RaisedIncidentList,retriveIncidentsInfo } from '../../../store/incidentReport/incdreportslice'
import { useDispatch, useSelector } from 'react-redux'
import MetaTags from "react-meta-tags";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Spinner,
    Badge , Button
} from "reactstrap";
import TableContainer from './Components/TableContainer';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useHistory } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';


const RaisedIncidents = (props) => {

    const dispatch = useDispatch()
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))

    const incidentReport = useSelector(state => state.incdreportslice)
    const history = useHistory()
    console.log(incidentReport, 'incidentReport')

    useEffect(() => {
        console.log("raised incidents")
        dispatch(RaisedIncidentList())
    }, [])


    const columns = useMemo(() => [
        // {
        //     accessor: "action",
        //     Header: "Incidents",
        //     filterable: true,
        //     Cell: ({ cell: { value } }) => <>{value}</>,
        // },

        {
            accessor: "action",
            Header: "Incidents",
            filterable: true,
            Cell: ({ cell: { value } }) =>  <div style={{
                maxWidth: '500px',  // Set your desired max width
                whiteSpace: 'wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}>
                {value}
            </div>,
        },
        {
            accessor: "incd_type",
            Header: "Incident Type",
            filterable: true,
            Cell: ({ cell: { value } }) => <>{value.incd_type_name}</>,
        },
        {
            accessor: "incd_h_location",
            Header: "Location",
            filterable: true,
            Cell: ({ cell: { value } }) => <>{value.node_name}</>,
        },

        {
            accessor: "task_priority",
            Header: "Severity",
            filterable: true,
            Cell: ({ cell: { value } }) => <>{value === null ? "-" : value?.severity_name}</>,
        },

        // {
        //     accessor: "task_users",
        //     Header: "Allocated To",
        //     filterable: true,
        //     Cell: ({ cell: { value } }) => <>{value.map((ele, idx) => {
        //         return (
        //             <>{ele.name},{" "}</>
        //         )
        //     })}</>,
        // },
        // {
        //     accessor: "task_status",
        //     Header: "Status",
        //     filterable: true,
        //     Cell: ({ cell: { value } }) => {
        //         let statusLabel = "";
        //         let badgeVariant = "";

        //         switch (value) {
        //             case "0":
        //                 statusLabel = "Not Started";
        //                 badgeVariant = "secondary"; // Grey
        //                 break;
        //             case "1":
        //                 statusLabel = "In Progress";
        //                 badgeVariant = "primary"; // Blue
        //                 break;
        //             case "2":
        //                 statusLabel = "Completed";
        //                 badgeVariant = "success"; // Green
        //                 break;
        //             case "3":
        //                 statusLabel = "Closed";
        //                 badgeVariant = "success"; // Dark Grey/Black
        //                 break;
        //             case "4":
        //                 statusLabel = "Overdue";
        //                 badgeVariant = "danger"; // Red
        //                 break;
        //             case "5":
        //                 statusLabel = "Reopen";
        //                 badgeVariant = "warning"; // Yellow/Orange
        //                 break;
        //             case "6":
        //                 statusLabel = "Rejected";
        //                 badgeVariant = "danger"; // Red
        //                 break;
        //             default:
        //                 statusLabel = "Not Allocated";
        //                 badgeVariant = "primary"; // Light Grey
        //         }

        //         return (
        //             <Badge
        //                 className={"font-size-12 badge-soft-" + badgeVariant}
        //             >
        //                 {statusLabel}
        //             </Badge>
        //         );
        //     },
        // },

        {
            accessor: "task_status",
            Header: "Status",
            filterable: true,
            // Cell: ({ cell: { value } }) => {

                Cell: ({ cell: { value }, row: { original } }) => {

                
                let statusLabel = "";
                let badgeVariant = "";

                if(original.incd_rejec_by){
                    statusLabel = "Rejected";
                    badgeVariant = "danger";
                }
                else  if(original.task_users.length <  1){
                    statusLabel = "Not Allocated";
                    badgeVariant = "secondary"; // Light Grey
                }

        
                switch (value) {
                    case "0":
                        statusLabel = "Not Started";
                        badgeVariant = "secondary"; 
                        break;
                    case "1":
                        statusLabel = "In Progress";
                        badgeVariant = "warning";
                        break;
                    case "2":
                        statusLabel = "Completed";
                        badgeVariant = "primary"; 
                        break;
                    case "3":
                        statusLabel = "Closed";
                        badgeVariant = "success";
                        break;
                    case "4":
                        statusLabel = "Overdue";
                        badgeVariant = "danger"; 
                        break;
                    case "5":
                        statusLabel = "Reopen";
                        badgeVariant = "dark"; 
                        break;
                 
                }
        
                return (
                    <span 
                    className={"font-size-12 badge bg-" +badgeVariant}
                     >
                        {statusLabel}
                    </span>
                );
            },
        } ,
    
    
        // {
        //     accessor: "task_status",
        //     Header: "Status",
        //     filterable: true,
        //     Cell: ({ cell: { value }, row: { original } }) => {
        //         let statusLabel = "";
        //         let badgeVariant = "";
        
        //         console.log('value 777 :>> ', value, original);
        //         switch (value) {
        //             case "0":
        //                 statusLabel = "Not Started";
        //                 badgeVariant = "secondary"; // Grey
        //                 break;
        //             case "1":
        //                 statusLabel = "In Progress";
        //                 badgeVariant = "warning"; // Blue
        //                 break;
        //             case "2":
        //                 statusLabel = "Completed";
        //                 badgeVariant = "primary"; // Green
        //                 break;
        //             case "3":
        //                 statusLabel = "Closed";
        //                 badgeVariant = "success"; // Dark Grey/Black
        //                 break;
        //             case "4":
        //                 statusLabel = "Overdue";
        //                 badgeVariant = "danger"; // Red
        //                 break;
        //             case "5":
        //                 statusLabel = "Reopen";
        //                 badgeVariant = "dark"; // Yellow/Orange
        //                 break;
        //             case "6":
        //                 statusLabel = "Rejected";
        //                 badgeVariant = "danger"; // Red
        //                 break;
                        
        //             default:
        //                 statusLabel = "Not Allocated";
        //                 badgeVariant = "info"; // Light Grey
        //         }
        
        //         return (
        //             <span className={"font-size-12 badge bg-" + badgeVariant}>
        //                 {statusLabel}
        //             </span>
        //         );
        //     },
        // },

        {
            accessor: "createdAt",
            Header: "Submitted on",
            filterable: true,
            Cell: ({ cell: { value } }) => <>{new Intl.DateTimeFormat("en-GB", {
                month: "short",
                day: "2-digit",
                year: "numeric",
            }).format(new Date(value))}</>,
        },
        {
            accessor: "id",
            Header: "Action",
            Cell: ({ row }) => {
                const handleClick = () => {
                    sessionStorage.setItem("incidentInfo", JSON.stringify(row.original))
                    dispatch(retriveIncidentsInfo(row.original))
                    props.history.push('/raised_info');

                };
                return (
                    <div>
                        <button
                            onClick={handleClick}
                            style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '15px' }}
                        >
                            <FaArrowRight />
                        </button>
                            
                    </div>
                );
            }
        }



    ], [])


    return (
        <React.Fragment>
            <div className="page-content">

                <MetaTags>
                    <title>AuditVista | Submitted Incidents</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Submitted Incidents" breadcrumbItem="Submitted Incidents" />
                    <Row>
                        {
                            incidentReport.raisedIncident ?
                                <Col className="col-12 mt-3 mb-1">



                                    <div className='text-end mb-2' >

                                        <Button
                                            color="primary"

                                            onClick={() => { history.push("/submit_incident") }}
                                        >
                                            Submit Incident
                                        </Button>
                                    </div>




                                    <TableContainer
                                        columns={columns}
                                        data={incidentReport.raisedIncident}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        isJobListGlobalFilter={true}
                                        customPageSize={10}
                                        style={{ width: "100%" }}
                                        isPagination={true}
                                        filterable={false}
                                        tableClass="align-middle table-nowrap table-check"
                                        theadClass="table-light"
                                        pagination="pagination pagination-rounded justify-content-center mb-2 my-2"
                                        // btnName={"Create Incidents"}
                                        // dynamicBtn={true}
                                        // btnClick={() => { history.push("/submit_incident")}}
                                    />

                                </Col>
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody style={{ height: "100vh" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                        }


                    </Row>

                </Container>
            </div>
        </React.Fragment>

    )
}
export default RaisedIncidents
