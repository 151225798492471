// MultiStackedBarChart.jsx
import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const MultiStackedBarChart = ({
  total_checkpoints,
  compliantCount,
  nonCompliantCount,
  partiallyCompliantCount,
  notApplicableCount,
  complaint_critical_count,
  complaint_high_count,
  complaint_low_count,
  complaint_medium_count,
  complaint_no_impact_count,
  non_compliant_critical_count,
  non_compliant_high_count,
  non_compliant_low_count,
  non_compliant_medium_count,
  non_compliant_no_impact_count,
  partially_compliant_critical_count,
  partially_compliant_high_count,
  partially_compliant_low_count,
  partially_compliant_medium_count,
  partially_compliant_no_impact_count,

  not_applicable_critical_count,
  not_applicable_high_count,
  not_applicable_low_count,
  not_applicable_medium_count,
  not_applicable_no_impact_count
}) => {
  const chartRef = useRef();
  const margin = { top: 20, right: 120, bottom: 50, left: 15 }; // Adjusted right margin for the legend
  // console.log('margin.left - margin.right', margin.left - margin.right)
  const width = 500 - margin.left - margin.right;
  // console.log('margin.left - margin.right', margin.left - margin.right)
  // const width = 800 - margin.left - margin.right;
  const height = 300 - margin.top - margin.bottom;

  useEffect(() => {
    const svg = d3.select(chartRef.current);


    var data = [
      {
        category: 'Compliant',
        count: compliantCount,

        critical: complaint_critical_count,
        high: complaint_high_count,
        low: complaint_low_count,
        medium: complaint_medium_count,
        no_impact: complaint_no_impact_count,
      },
      {
        category: 'Non Compliant',
        count: nonCompliantCount,

        critical: non_compliant_critical_count,
        high: non_compliant_high_count,
        low: non_compliant_low_count,
        medium: non_compliant_medium_count,
        no_impact: non_compliant_no_impact_count,
      },
      {
        category: 'Partially Compliant',
        count: partiallyCompliantCount,

        critical: partially_compliant_critical_count,
        high: partially_compliant_high_count,
        low: partially_compliant_low_count,
        medium: partially_compliant_medium_count,
        no_impact: partially_compliant_no_impact_count,
      },
      {
        category: 'Not Applicable',
        count: notApplicableCount,

        critical: not_applicable_critical_count,
        high: not_applicable_high_count,
        low: not_applicable_low_count,
        medium: not_applicable_medium_count,
        no_impact: not_applicable_no_impact_count,
      },
    ];




    const totalByCategory = data.map(d => d.count);

    // Set up the scales
    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.category))
      .range([margin.left, width + margin.left])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(totalByCategory)])
      .range([height + margin.top, margin.top]);

    // Set up the color scale
    const colorScale = d3
      .scaleOrdinal()
      .domain(['no_impact', 'low', 'medium', 'high', 'critical'])
      .range(['rgb(85, 110, 230)', '#34c38f', '#50a5f1', '#f1b44c', '#f46a6a']);
    const stackedData = d3.stack().keys(['critical', 'high', 'medium', 'low', 'no_impact'])(data);

    // Draw the bars
    svg.selectAll('.bar-group')
      .data(stackedData)
      .enter().append('g')
      .attr('class', 'bar-group')
      .attr('fill', d => colorScale(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
      .attr('x', d => xScale(d.data.category))
      .attr('y', d => yScale(d[1]))
      .attr('height', d => yScale(d[0]) - yScale(d[1]))
      .attr('width', xScale.bandwidth())
      .on('mouseover', function (event, d) {
        const [x, y] = d3.pointer(event);
        d3.select(this).style('opacity', 0.7);
        // Access the key from the parent stack (represents the category)
        const category = d3.select(this.parentNode).datum().key;
        // Access the value from the specific data point in the stack
        const value = d.data[category];
        if (category !== undefined && value !== undefined) {
          showTooltip(category, value, x + 10, y - 10);
        }
      })
      .on('mouseout', function () {
        d3.select(this).style('opacity', 1);
        // hideTooltip();
      });

    // Add labels for each category
    svg.selectAll('.bar-label')
      .data(data)
      .enter().append('text')
      .attr('class', 'bar-label')
      .attr('x', d => xScale(d.category) + xScale.bandwidth() / 2)
      .attr('y', d => yScale(d.count) - 5)
      .style('text-anchor', 'middle')
      .style('font-size', '9px')
      // .text(d => d.count);
      .text(d => d.count > 0 ? d.count : 'No Data Available');

    svg.selectAll('.bar-label-group')
      .data(stackedData)
      .enter().append('g')
      .attr('class', 'bar-label-group')
      .selectAll('.bar-label')
      .data(d => d)
      .enter().append('text')
      .attr('class', 'bar-label')
      .attr('x', d => xScale(d.data.category) + xScale.bandwidth() / 2)
      .attr('y', d => (yScale(d[0]) + yScale(d[1])) / 2) // Position the label in the middle of the stack bar
      .style('text-anchor', 'middle')
      .style('font-size', '12px')
      .text(function (d) {
        // Access the key (e.g., 'no_impact', 'low', 'medium', 'high', 'critical')
        const key = d3.select(this.parentNode).datum().key;
        // Capitalize the first letter of each word in the key
        const formattedKey = key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        // Access the value for the specific key and category
        const value = d.data[key];
        if (value !== 0) {
          // Display the key name and its value
          return `${formattedKey}: ${value}`;
        }
      });


    // Draw x-axis
    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height + margin.top})`)
      .call(d3.axisBottom(xScale));

    // Draw y-axis
    svg.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale));

    // Add legend
    const legend = svg.append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${width + 10}, 0)`);

    const legendItems = ['No Impact', 'Low', 'Medium', 'High', 'Critical'];
    const barGroups = svg.selectAll(".bar-group");
    legend.selectAll('.legend-item')
      .data(legendItems)
      .enter().append('g')
      .attr('class', 'legend-item')
      .attr('transform', (d, i) => `translate(0,${i * 20})`)
      .call(g => {
        g.append('rect')
          .attr('width', 18)
          .attr('height', 18)
          .attr('fill', d => colorScale(d));

        g.append('text')
          .attr('x', 24)
          .attr('y', 9)
          .attr('dy', '0.35em')
          .style('font-size', '12px')
          .text(d => d);
      });

    // Tooltip functions
    const tooltip = svg.append('g')
      .attr('class', 'tooltip')
      .style('display', 'none');

    tooltip.append('rect')
      .attr('width', 120)  // Adjusted width for longer text
      .attr('height', 50)
      .attr('fill', 'white')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .style('opacity', 0.8);

    tooltip.append('text')
      .attr('x', 10)
      .attr('y', 20)
      .style('font-size', '12px')
      .style('font-weight', 'bold');

    // ...

    function showTooltip(label, value, x, y) {
      // console.log('showTooltip', label, value, x, y);

      // Make sure the tooltip is displayed
      tooltip.style('display', 'block');

      // Set the position of the tooltip
      tooltip.attr('transform', `translate(${x},${y})`);

      // Set the content of the tooltip
      tooltip.select('text').html(`${label}: ${value}`);

      // Additional styling for the tooltip rect
      tooltip.select('rect')
        .attr('width', 120)  // Adjusted width for longer text
        .attr('height', 50)
        .attr('fill', 'white')
        .attr('stroke', 'black')
        .attr('stroke-width', 1)
        .style('opacity', 0.8);
    }
    svg.selectAll('.bar-group')
      .data(stackedData)
      .enter().append('g')
      .attr('class', 'bar-group')
      .attr('fill', d => colorScale(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
      .attr('x', d => xScale(d.data.category))
      .attr('y', d => yScale(d[1]))
      .attr('height', d => yScale(d[0]) - yScale(d[1]))
      .attr('width', xScale.bandwidth())
      .on('mouseover', function (event, d) {
        const [x, y] = d3.pointer(event);
        d3.select(this).style('opacity', 0.7);
        showTooltip(d.key, d.data[d.key], x + 10, y - 10);
      })
      .on('mouseout', function () {
        d3.select(this).style('opacity', 1);
        // hideTooltip();
      });
  }, [
    total_checkpoints,
    compliantCount,
    nonCompliantCount,
    partiallyCompliantCount,
    notApplicableCount,
    complaint_critical_count,
    complaint_high_count,
    complaint_low_count,
    complaint_medium_count,
    complaint_no_impact_count,
    non_compliant_critical_count,
    non_compliant_high_count,
    non_compliant_low_count,
    non_compliant_medium_count,
    non_compliant_no_impact_count,
    partially_compliant_critical_count,
    partially_compliant_high_count,
    partially_compliant_low_count,
    partially_compliant_medium_count,
    partially_compliant_no_impact_count,

    not_applicable_critical_count,
    not_applicable_high_count,
    not_applicable_low_count,
    not_applicable_medium_count,
    not_applicable_no_impact_count
  ]);

  return (
    <svg ref={chartRef} width={width + margin.left + margin.right} height={height + margin.top + margin.bottom}></svg>
  );
};

export default MultiStackedBarChart;











/////28-12-23
// // MultiStackedBarChart.jsx
// import React, { useRef, useEffect } from 'react';
// import * as d3 from 'd3';

// const MultiStackedBarChart = ({
//   total_checkpoints,
//   compliantCount,
//   nonCompliantCount,
//   partiallyCompliantCount,
//   notApplicableCount,
//   complaint_critical_count,
//   complaint_high_count,
//   complaint_low_count,
//   complaint_medium_count,
//   complaint_no_impact_count,
//   non_compliant_critical_count,
//   non_compliant_high_count,
//   non_compliant_low_count,
//   non_compliant_medium_count,
//   non_compliant_no_impact_count,
//   partially_compliant_critical_count,
//   partially_compliant_high_count,
//   partially_compliant_low_count,
//   partially_compliant_medium_count,
//   partially_compliant_no_impact_count,

//   not_applicable_critical_count,
//   not_applicable_high_count,
//   not_applicable_low_count,
//   not_applicable_medium_count,
//   not_applicable_no_impact_count
// }) => {
//   const chartRef = useRef();
//   const margin = { top: 20, right: 100, bottom: 50, left: 5 }; // Adjusted right margin for the legend
//   const width = 500 - margin.left - margin.right;
//   // const width = 800 - margin.left - margin.right;
//   const height = 300 - margin.top - margin.bottom;

//   useEffect(() => {
//     const svg = d3.select(chartRef.current);


//     var data = [
//       {
//         category: 'Compliant',
//         count: compliantCount,

//         critical: complaint_critical_count,
//         high: complaint_high_count,
//         low: complaint_low_count,
//         medium: complaint_medium_count,
//         no_impact: complaint_no_impact_count,
//       },
//       {
//         category: 'Non Compliant',
//         count: nonCompliantCount,

//         critical: non_compliant_critical_count,
//         high: non_compliant_high_count,
//         low: non_compliant_low_count,
//         medium: non_compliant_medium_count,
//         no_impact: non_compliant_no_impact_count,
//       },
//       {
//         category: 'Partially Compliant',
//         count: partiallyCompliantCount,

//         critical: partially_compliant_critical_count,
//         high: partially_compliant_high_count,
//         low: partially_compliant_low_count,
//         medium: partially_compliant_medium_count,
//         no_impact: partially_compliant_no_impact_count,
//       },
//       {
//         category: 'Not Applicable',
//         count: notApplicableCount,

//         critical: not_applicable_critical_count,
//         high: not_applicable_high_count,
//         low: not_applicable_low_count,
//         medium: not_applicable_medium_count,
//         no_impact: not_applicable_no_impact_count,
//       },
//     ];




//     console.log('data', data)
//     const totalByCategory = data.map(d => d.count);

//     // Set up the scales
//     const xScale = d3
//       .scaleBand()
//       .domain(data.map(d => d.category))
//       .range([margin.left, width + margin.left])
//       .padding(0.1);

//     const yScale = d3
//       .scaleLinear()
//       .domain([0, d3.max(totalByCategory)])
//       .range([height + margin.top, margin.top]);

//     // Set up the color scale
//     const colorScale = d3
//       .scaleOrdinal()
//       .domain(['no_impact', 'low', 'medium', 'high', 'critical'])
//       .range(['rgb(85, 110, 230)', '#34c38f', '#50a5f1', '#f1b44c', '#f46a6a']);
//     const stackedData = d3.stack().keys(['critical', 'high', 'medium', 'low', 'no_impact'])(data);

//     // Draw the bars
//     svg.selectAll('.bar-group')
//       .data(stackedData)
//       .enter().append('g')
//       .attr('class', 'bar-group')
//       .attr('fill', d => colorScale(d.key))
//       .selectAll('rect')
//       .data(d => d)
//       .enter().append('rect')
//       .attr('x', d => xScale(d.data.category))
//       .attr('y', d => yScale(d[1]))
//       .attr('height', d => yScale(d[0]) - yScale(d[1]))
//       .attr('width', xScale.bandwidth())
//       .on('mouseover', function (event, d) {
//         const [x, y] = d3.pointer(event);
//         d3.select(this).style('opacity', 0.7);
//         // Access the key from the parent stack (represents the category)
//         const category = d3.select(this.parentNode).datum().key;
//         // Access the value from the specific data point in the stack
//         const value = d.data[category];
//         if (category !== undefined && value !== undefined) {
//           showTooltip(category, value, x + 10, y - 10);
//         }
//       })
//       .on('mouseout', function () {
//         d3.select(this).style('opacity', 1);
//         // hideTooltip();
//       });

//     // Add labels for each category
//     svg.selectAll('.bar-label')
//       .data(data)
//       .enter().append('text')
//       .attr('class', 'bar-label')
//       .attr('x', d => xScale(d.category) + xScale.bandwidth() / 2)
//       .attr('y', d => yScale(d.count) - 5)
//       .style('text-anchor', 'middle')
//       .style('font-size', '12px')
//       // .text(d => d.count);
//       .text(d => d.count > 0 ? d.count : 'No Data Available');

//     svg.selectAll('.bar-label-group')
//       .data(stackedData)
//       .enter().append('g')
//       .attr('class', 'bar-label-group')
//       .selectAll('.bar-label')
//       .data(d => d)
//       .enter().append('text')
//       .attr('class', 'bar-label')
//       .attr('x', d => xScale(d.data.category) + xScale.bandwidth() / 2)
//       .attr('y', d => (yScale(d[0]) + yScale(d[1])) / 2) // Position the label in the middle of the stack bar
//       .style('text-anchor', 'middle')
//       .style('font-size', '12px')
//       .text(function (d) {
//         // Access the key (e.g., 'no_impact', 'low', 'medium', 'high', 'critical')
//         const key = d3.select(this.parentNode).datum().key;
//         // Capitalize the first letter of each word in the key
//         const formattedKey = key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
//         // Access the value for the specific key and category
//         const value = d.data[key];
//         if (value !== 0) {
//           // Display the key name and its value
//           return `${formattedKey}: ${value}`;
//         }
//       });


//     // Draw x-axis
//     svg.append('g')
//       .attr('class', 'x-axis')
//       .attr('transform', `translate(0,${height + margin.top})`)
//       .call(d3.axisBottom(xScale));

//     // Draw y-axis
//     svg.append('g')
//       .attr('class', 'y-axis')
//       .attr('transform', `translate(${margin.left},0)`)
//       .call(d3.axisLeft(yScale));

//     // Add legend
//     const legend = svg.append('g')
//       .attr('class', 'legend')
//       .attr('transform', `translate(${width + 10}, 0)`);

//     const legendItems = ['No Impact', 'Low', 'Medium', 'High', 'Critical'];
//     const barGroups = svg.selectAll(".bar-group");
//     legend.selectAll('.legend-item')
//       .data(legendItems)
//       .enter().append('g')
//       .attr('class', 'legend-item')
//       .attr('transform', (d, i) => `translate(0,${i * 20})`)
//       .call(g => {
//         g.append('rect')
//           .attr('width', 18)
//           .attr('height', 18)
//           .attr('fill', d => colorScale(d));

//         g.append('text')
//           .attr('x', 24)
//           .attr('y', 9)
//           .attr('dy', '0.35em')
//           .style('font-size', '12px')
//           .text(d => d);
//       });

//     // Tooltip functions
//     const tooltip = svg.append('g')
//       .attr('class', 'tooltip')
//       .style('display', 'none');

//     tooltip.append('rect')
//       .attr('width', 120)  // Adjusted width for longer text
//       .attr('height', 50)
//       .attr('fill', 'white')
//       .attr('stroke', 'black')
//       .attr('stroke-width', 1)
//       .style('opacity', 0.8);

//     tooltip.append('text')
//       .attr('x', 10)
//       .attr('y', 20)
//       .style('font-size', '12px')
//       .style('font-weight', 'bold');

//     // ...

//     function showTooltip(label, value, x, y) {
//       // console.log('showTooltip', label, value, x, y);

//       // Make sure the tooltip is displayed
//       tooltip.style('display', 'block');

//       // Set the position of the tooltip
//       tooltip.attr('transform', `translate(${x},${y})`);

//       // Set the content of the tooltip
//       tooltip.select('text').html(`${label}: ${value}`);

//       // Additional styling for the tooltip rect
//       tooltip.select('rect')
//         .attr('width', 120)  // Adjusted width for longer text
//         .attr('height', 50)
//         .attr('fill', 'white')
//         .attr('stroke', 'black')
//         .attr('stroke-width', 1)
//         .style('opacity', 0.8);
//     }
//     svg.selectAll('.bar-group')
//       .data(stackedData)
//       .enter().append('g')
//       .attr('class', 'bar-group')
//       .attr('fill', d => colorScale(d.key))
//       .selectAll('rect')
//       .data(d => d)
//       .enter().append('rect')
//       .attr('x', d => xScale(d.data.category))
//       .attr('y', d => yScale(d[1]))
//       .attr('height', d => yScale(d[0]) - yScale(d[1]))
//       .attr('width', xScale.bandwidth())
//       .on('mouseover', function (event, d) {
//         const [x, y] = d3.pointer(event);
//         d3.select(this).style('opacity', 0.7);
//         showTooltip(d.key, d.data[d.key], x + 10, y - 10);
//       })
//       .on('mouseout', function () {
//         d3.select(this).style('opacity', 1);
//         // hideTooltip();
//       });
//   }, [
//     total_checkpoints,
//     compliantCount,
//     nonCompliantCount,
//     partiallyCompliantCount,
//     notApplicableCount,
//     complaint_critical_count,
//     complaint_high_count,
//     complaint_low_count,
//     complaint_medium_count,
//     complaint_no_impact_count,
//     non_compliant_critical_count,
//     non_compliant_high_count,
//     non_compliant_low_count,
//     non_compliant_medium_count,
//     non_compliant_no_impact_count,
//     partially_compliant_critical_count,
//     partially_compliant_high_count,
//     partially_compliant_low_count,
//     partially_compliant_medium_count,
//     partially_compliant_no_impact_count,

//     not_applicable_critical_count,
//     not_applicable_high_count,
//     not_applicable_low_count,
//     not_applicable_medium_count,
//     not_applicable_no_impact_count
//   ]);

//   return (
//     <svg ref={chartRef} width={width + margin.left + margin.right} height={height + margin.top + margin.bottom}></svg>
//   );
// };

// export default MultiStackedBarChart;










//////working 8-12-2023
// // MultiStackedBarChart.jsx
// import React, { useRef, useEffect } from 'react';
// import * as d3 from 'd3';

// const MultiStackedBarChart = ({
//   total_checkpoints,
//   compliantCount,
//   nonCompliantCount,
//   partiallyCompliantCount,
//   notApplicableCount,
//   complaint_critical_count,
//   complaint_high_count,
//   complaint_low_count,
//   complaint_medium_count,
//   complaint_no_impact_count,
//   non_compliant_critical_count,
//   non_compliant_high_count,
//   non_compliant_low_count,
//   non_compliant_medium_count,
//   non_compliant_no_impact_count,
//   partially_compliant_critical_count,
//   partially_compliant_high_count,
//   partially_compliant_low_count,
//   partially_compliant_medium_count,
//   partially_compliant_no_impact_count,

//   not_applicable_critical_count,
//   not_applicable_high_count,
//   not_applicable_low_count,
//   not_applicable_medium_count,
//   not_applicable_no_impact_count
// }) => {
//   const chartRef = useRef();
//   const margin = { top: 20, right: 100, bottom: 50, left: 5 }; // Adjusted right margin for the legend
//   // const width = 500 - margin.left - margin.right;
//   const width = 800 - margin.left - margin.right;
//   const height = 300 - margin.top - margin.bottom;

//   useEffect(() => {
//     const svg = d3.select(chartRef.current);
  

//     var data = [
//       {
//         category: 'Compliant',
//         count: compliantCount,
        
//         critical: complaint_critical_count,
//         high: complaint_high_count,
//         low: complaint_low_count,
//         medium: complaint_medium_count,
//         no_impact: complaint_no_impact_count,
//       },
//       {
//         category: 'Non Compliant',
//         count: nonCompliantCount,

//         critical: non_compliant_critical_count,
//         high: non_compliant_high_count,
//         low: non_compliant_low_count,
//         medium: non_compliant_medium_count,
//         no_impact: non_compliant_no_impact_count,
//       },
//       {
//         category: 'Partially Compliant',
//         count: partiallyCompliantCount,

//         critical: partially_compliant_critical_count,
//         high: partially_compliant_high_count,
//         low: partially_compliant_low_count,
//         medium: partially_compliant_medium_count,
//         no_impact: partially_compliant_no_impact_count,
//       },      
//       {        
//         category: 'Not Applicable',
//         count: notApplicableCount,

//         critical: not_applicable_critical_count,
//         high: not_applicable_high_count,
//         low: not_applicable_low_count,
//         medium: not_applicable_medium_count,
//         no_impact: not_applicable_no_impact_count,
//       },
//     ];
  



//     console.log('data', data)
//     const totalByCategory = data.map(d => d.count);

//     // Set up the scales
//     const xScale = d3
//       .scaleBand()
//       .domain(data.map(d => d.category))
//       .range([margin.left, width + margin.left])
//       .padding(0.1);

//     const yScale = d3
//       .scaleLinear()
//       .domain([0, d3.max(totalByCategory)])
//       .range([height + margin.top, margin.top]);

//     // Set up the color scale
//     const colorScale = d3
//       .scaleOrdinal()
//       // .domain(['no_impact', 'low', 'medium', 'high', 'critical'])
//       // .range(['rgb(85, 110, 230)', '#34c38f', '#50a5f1', '#f1b44c', '#f46a6a']);
//       // .domain(['no_impact', 'low', 'medium', 'high', 'critical'])
//       // .range(['rgb(85, 110, 230)', '#34c38f', '#50a5f1', '#f1b44c', '#f46a6a']);
//       .domain(['no_impact', 'low', 'medium', 'high', 'critical'])
//       .range(['rgb(85, 110, 230)', '#34c38f', '#50a5f1', '#f1b44c', '#f46a6a']);

//     // .range(['#f46a6a', '#f1b44c', '#50a5f1', '#34c38f', 'rgb(85, 110, 230)']);


//     // Create the stacked data
//     // const stackedData = d3.stack().keys(['no_impact', 'low', 'medium', 'high', 'critical'])(data);

//     // const stackedData = d3.stack().keys(['no_impact', 'low', 'medium', 'high', 'critical'])(data);
//     const stackedData = d3.stack().keys(['critical', 'high', 'medium', 'low', 'no_impact'])(data);



//     // const desiredOrder = ['no_impact', 'Low', 'Medium', 'High', 'critical'];
//     // const desiredOrder = ['critical', 'High', 'Medium', 'Low', 'no_impact'];


//     // const sortedData = stackedData.map(stack => {
//     //   stack.sort((a, b) => {
//     //     // return desiredOrder.indexOf(b.data.category) - desiredOrder.indexOf(a.data.category);
//     //   });
//     //   return stack;
//     // });


//     // Draw the bars
//     svg.selectAll('.bar-group')
//       .data(stackedData)
//       .enter().append('g')
//       .attr('class', 'bar-group')
//       .attr('fill', d => colorScale(d.key))
//       .selectAll('rect')
//       .data(d => d)
//       .enter().append('rect')
//       .attr('x', d => xScale(d.data.category))
//       .attr('y', d => yScale(d[1]))
//       .attr('height', d => yScale(d[0]) - yScale(d[1]))
//       .attr('width', xScale.bandwidth())
//       .on('mouseover', function (event, d) {
//         const [x, y] = d3.pointer(event);
//         d3.select(this).style('opacity', 0.7);
//         // Access the key from the parent stack (represents the category)
//         const category = d3.select(this.parentNode).datum().key;
//         // Access the value from the specific data point in the stack
//         const value = d.data[category];
//         if (category !== undefined && value !== undefined) {
//           showTooltip(category, value, x + 10, y - 10);
//         }
//       })
//       .on('mouseout', function () {
//         d3.select(this).style('opacity', 1);
//         // hideTooltip();
//       });

//     // Add labels for each category
//     svg.selectAll('.bar-label')
//       .data(data)
//       .enter().append('text')
//       .attr('class', 'bar-label')
//       .attr('x', d => xScale(d.category) + xScale.bandwidth() / 2)
//       .attr('y', d => yScale(d.count) - 5)
//       .style('text-anchor', 'middle')
//       .style('font-size', '12px')
//       // .text(d => d.count);
//       .text(d => d.count > 0 ? d.count : 'No Data Available');
//     // // code for Display The Values inside the Stack Bar's
//     // svg.selectAll('.bar-label-group')
//     //   .data(stackedData)
//     //   .enter().append('g')
//     //   .attr('class', 'bar-label-group')
//     //   .selectAll('.bar-label')
//     //   .data(d => d)
//     //   .enter().append('text')
//     //   .attr('class', 'bar-label')
//     //   .attr('x', d => xScale(d.data.category) + xScale.bandwidth() / 2)
//     //   .attr('y', d => (yScale(d[0]) + yScale(d[1])) / 2) // Position the label in the middle of the stack bar
//     //   .style('text-anchor', 'middle')
//     //   .style('font-size', '12px')
//     //   .text(function (d) {
//     //     // Access the key (e.g., 'no_impact', 'low', 'medium', 'high', 'critical')
//     //     const key = d3.select(this.parentNode).datum().key;
//     //     // Access the value for the specific key and category
//     //     const value = d.data[key];
//     //     if (value !== 0) {
//     //       // Display the key name and its value
//     //       return `${key}: ${value}`;
//     //     }
//     //   });


//     svg.selectAll('.bar-label-group')
//       .data(stackedData)
//       .enter().append('g')
//       .attr('class', 'bar-label-group')
//       .selectAll('.bar-label')
//       .data(d => d)
//       .enter().append('text')
//       .attr('class', 'bar-label')
//       .attr('x', d => xScale(d.data.category) + xScale.bandwidth() / 2)
//       .attr('y', d => (yScale(d[0]) + yScale(d[1])) / 2) // Position the label in the middle of the stack bar
//       .style('text-anchor', 'middle')
//       .style('font-size', '12px')
//       .text(function (d) {
//         // Access the key (e.g., 'no_impact', 'low', 'medium', 'high', 'critical')
//         const key = d3.select(this.parentNode).datum().key;
//         // Capitalize the first letter of each word in the key
//         const formattedKey = key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
//         // Access the value for the specific key and category
//         const value = d.data[key];
//         if (value !== 0) {
//           // Display the key name and its value
//           return `${formattedKey}: ${value}`;
//         }
//       });


//     // Draw x-axis
//     svg.append('g')
//       .attr('class', 'x-axis')
//       .attr('transform', `translate(0,${height + margin.top})`)
//       .call(d3.axisBottom(xScale));

//     // Draw y-axis
//     svg.append('g')
//       .attr('class', 'y-axis')
//       .attr('transform', `translate(${margin.left},0)`)
//       .call(d3.axisLeft(yScale));

//     // Add legend
//     const legend = svg.append('g')
//       .attr('class', 'legend')
//       .attr('transform', `translate(${width + 10}, 0)`);

//     const legendItems = ['No Impact', 'Low', 'Medium', 'High', 'Critical'];

//     const barGroups = svg.selectAll(".bar-group");
//     // console.log("1611",barGroups); // Check the value in the console


//     // barGroups.selectAll("text")
//     //   .data(function (d) { return d; })
//     //   .enter()
//     //   .append("text")
//     //   .attr("class", "stack-value-label")
//     //   .attr("x", function (d) { return xScale(d[1] - (d[1] - d[0]) / 2) })
//     //   // .attr("y", function (d) { return y(d.data.category) + y.bandwidth() / 2; })
//     //   .attr("dy", ".35em")
//     //   .attr("text-anchor", "middle")
//     //   .text(function (d) { return d3.format(".2f")(d[1] - d[0]); })
//     //   .style("font-size", "12px")
//     //   .style("fill", "black")
//     //   .style("display", function () {
//     //     // Adjust the visibility of value labels based on the toggle state
//     //     return "block";
//     //   });
//     legend.selectAll('.legend-item')
//       .data(legendItems)
//       .enter().append('g')
//       .attr('class', 'legend-item')
//       .attr('transform', (d, i) => `translate(0,${i * 20})`)
//       .call(g => {
//         g.append('rect')
//           .attr('width', 18)
//           .attr('height', 18)
//           .attr('fill', d => colorScale(d));

//         g.append('text')
//           .attr('x', 24)
//           .attr('y', 9)
//           .attr('dy', '0.35em')
//           .style('font-size', '12px')
//           .text(d => d);
//       });

//     // Tooltip functions
//     const tooltip = svg.append('g')
//       .attr('class', 'tooltip')
//       .style('display', 'none');

//     tooltip.append('rect')
//       .attr('width', 120)  // Adjusted width for longer text
//       .attr('height', 50)
//       .attr('fill', 'white')
//       .attr('stroke', 'black')
//       .attr('stroke-width', 1)
//       .style('opacity', 0.8);

//     tooltip.append('text')
//       .attr('x', 10)
//       .attr('y', 20)
//       .style('font-size', '12px')
//       .style('font-weight', 'bold');

//     // ...

//     function showTooltip(label, value, x, y) {
//       // console.log('showTooltip', label, value, x, y);

//       // Make sure the tooltip is displayed
//       tooltip.style('display', 'block');

//       // Set the position of the tooltip
//       tooltip.attr('transform', `translate(${x},${y})`);

//       // Set the content of the tooltip
//       tooltip.select('text').html(`${label}: ${value}`);

//       // Additional styling for the tooltip rect
//       tooltip.select('rect')
//         .attr('width', 120)  // Adjusted width for longer text
//         .attr('height', 50)
//         .attr('fill', 'white')
//         .attr('stroke', 'black')
//         .attr('stroke-width', 1)
//         .style('opacity', 0.8);
//     }


//     // ...

//     svg.selectAll('.bar-group')
//       .data(stackedData)
//       .enter().append('g')
//       .attr('class', 'bar-group')
//       .attr('fill', d => colorScale(d.key))
//       .selectAll('rect')
//       .data(d => d)
//       .enter().append('rect')
//       .attr('x', d => xScale(d.data.category))
//       .attr('y', d => yScale(d[1]))
//       .attr('height', d => yScale(d[0]) - yScale(d[1]))
//       .attr('width', xScale.bandwidth())
//       .on('mouseover', function (event, d) {
//         const [x, y] = d3.pointer(event);
//         d3.select(this).style('opacity', 0.7);
//         showTooltip(d.key, d.data[d.key], x + 10, y - 10);
//       })
//       .on('mouseout', function () {
//         d3.select(this).style('opacity', 1);
//         // hideTooltip();
//       });
//   }, [
//     total_checkpoints,
//     compliantCount,
//     nonCompliantCount,
//     partiallyCompliantCount,
//     notApplicableCount,
//     complaint_critical_count,
//     complaint_high_count,
//     complaint_low_count,
//     complaint_medium_count,
//     complaint_no_impact_count,
//     non_compliant_critical_count,
//     non_compliant_high_count,
//     non_compliant_low_count,
//     non_compliant_medium_count,
//     non_compliant_no_impact_count,
//     partially_compliant_critical_count,
//     partially_compliant_high_count,
//     partially_compliant_low_count,
//     partially_compliant_medium_count,
//     partially_compliant_no_impact_count,

//     not_applicable_critical_count,
//     not_applicable_high_count,
//     not_applicable_low_count,
//     not_applicable_medium_count,
//     not_applicable_no_impact_count
//   ]);

//   return (
//     <svg ref={chartRef} width={width + margin.left + margin.right} height={height + margin.top + margin.bottom}></svg>
//   );
// };

// export default MultiStackedBarChart;







