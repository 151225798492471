import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LocationDetails = ({ latitude, longitude }) => {
  const [locationInfo, setLocationInfo] = useState({
    amenity: 'Loading...',
    city: 'Loading...',
    country: 'Loading...',
    country_code: 'Loading...',
    neighbourhood: 'Loading...',
    postcode: 'Loading...',
    road: 'Loading...',
    state: 'Loading...',
    state_district: 'Loading...',
    suburb: 'Loading...',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
          params: {
            lat: latitude,
            lon: longitude,
            format: 'json',
          },
        });

        const address = response.data.address;
        console.log('response.data', response.data)

        setLocationInfo({
          amenity: address.amenity || 'Unknown',
          city: address.city || 'Unknown',
          country: address.country || 'Unknown',
          country_code: address.country_code || 'Unknown',
          neighbourhood: address.neighbourhood || 'Unknown',
          postcode: address.postcode || 'Unknown',
          road: address.road || 'Unknown',
          state: address.state || 'Unknown',
          state_district: address.state_district || 'Unknown',
          suburb: address.suburb || 'Unknown',
        });
      } catch (error) {
        console.error('Error fetching location details:', error);
        setLocationInfo({
          amenity: 'Error',
          city: 'Error',
          country: 'Error',
          country_code: 'Error',
          neighbourhood: 'Error',
          postcode: 'Error',
          road: 'Error',
          state: 'Error',
          state_district: 'Error',
          suburb: 'Error',
        });
      }
    };

    fetchData();
  }, [latitude, longitude]);

  return (
    <div>
      <h4>Location Details</h4>
      <p>Amenity: {locationInfo.amenity}</p>
      <p>City: {locationInfo.city}</p>
      <p>Country: {locationInfo.country}</p>
      <p>Country Code: {locationInfo.country_code}</p>
      <p>Neighbourhood: {locationInfo.neighbourhood}</p>
      <p>Postcode: {locationInfo.postcode}</p>
      <p>Road: {locationInfo.road}</p>
      <p>State: {locationInfo.state}</p>
      <p>State District: {locationInfo.state_district}</p>
      <p>Suburb: {locationInfo.suburb}</p>
    </div>
  );
};

export default LocationDetails;