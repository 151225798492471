import React, { Component } from 'react';
import { Collapse, CardBody, Card, Row, Col, Container } from 'reactstrap';
import { FaChevronDown, FaLessThanEqual } from 'react-icons/fa';
import ReviewOPType from "./Components/review_optype";
import PreviewImage from "./Components/preview_images";
import PreviewVideo from "./Components/preview_videos";

import PreviewDocuments from "./Components/preview_documents";
import PreviewObservation from "./Components/preview_observation";
import PreviewCAPA from "./Components/preview_CAPA";
import _, { set } from 'lodash';

class NestedChildren extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dataloaded: false
        };
        this.divRef = React.createRef()
    }

    componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        this.setState({ imagePreviewUrl: data.config_data.img_url, dataloaded: true })
    }

    toggleCollapse = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    render() {
        const { children, endpointData, filter_cp_compliant } = this.props;
        const { isOpen } = this.state;
        const color = ["#F4F7F7", "#E4EAEA", "#D3DDDC", "#C3D0CF", "#C3D0CF",]

        if(this.state.dataloaded){
        return (
            <div >
                {
                    this.props.children.parent_id === null || this.props.children.children.length > 0 && _.some(this.props.children.children, { document_type: '2' }) ?
                        <>
                            <Card
                                style={{
                                    background: "rgb(233, 238, 246)",
                                    boxShadow: 'none',
                                    borderRadius: "1rem",
                                    borderBottom:  '1px solid rgb(164 164 164 / 27%)' ,
                                }}
                                className='mb-0'
                            >
                                <CardBody style={{ padding: "10px 25px" }} >
                                    <div onClick={this.toggleCollapse} className='d-flex' style={{ cursor: 'pointer' }}>
                                        {
                                            !isOpen ? (
                                                <div>

                                                    <FaChevronDown size={13} className="me-3" />
                                                </div>
                                            ) : (
                                                <div>
                                                    <FaChevronDown
                                                        size={13}
                                                        className="me-3"
                                                        style={{ transform: "rotate(270deg)" }}
                                                    />
                                                </div>
                                            )}
                                            
                                            
                                        {children.checkpoint}
                                            
                                        




                                    </div>
                                </CardBody>
                            </Card>
                        </>
                        : null
                }

                <Collapse isOpen={!isOpen} >
                    {children.document_type === "2" && (
                        <ul style={{ paddingLeft: 0, marginBottom:0 }} className='bg-light'>
                            {
                                !isOpen &&
                                <Card
                                    className="mt-2"
                                    style={{
                                        // borderTop: children.cp_review_status !== null
                                        //     ? '5px solid #556ee6'
                                        //     : children.cp_status === '0'
                                        //         ? '5px solid #74788d'
                                        //         : children.cp_status === '1'
                                        //             ? '5px solid #f1b44c'
                                        //             : children.cp_status === '2'
                                        //                 ? '5px solid #34c38f'
                                        //                 : '5px solid #50a5f1',
                                        borderRadius: "0.5rem",
                                        background: 'white',
                                        marginBottom:0

                                    }}
                                >

                                        <CardBody style={{ padding: "10px 25px" }} >
                                        <div>
                                            <Row className='mb-3'>
                                                <Col md={3}>
                                                    {children.cp_status !== '0' && (
                                                        <>
                                                            <span className="font-size-10">Compliance status</span><br />

                                                            <label
                                                                className={
                                                                    children.cp_compliance.color !== undefined && children.cp_compliance.color === 'success' ? 'text-success font-size-12'
                                                                        : children.cp_compliance.color !== undefined && children.cp_compliance.color === 'danger' ? 'text-danger font-size-12'
                                                                            : children.cp_compliance.color !== undefined && children.cp_compliance.color === 'warning' ? 'text-warning font-size-12'
                                                                                : children.cp_compliance.color !== undefined && children.cp_compliance.color === 'secondary' ? 'text-secondary font-size-12'
                                                                                    : 'font-size-12'
                                                                }
                                                            >
                                                                {children.cp_compliance.name}
                                                            </label>
                                                        </>
                                                    )}
                                                </Col>
                                                <Col md={2}>
                                                    <>
                                                        <span className="font-size-10">Impact Level</span><br />

                                                        {

                                                            children.impact_level === 'Low'
                                                                ? <label className="font-size-12 badge badge-soft-success">Low</label>
                                                                : children.impact_level === 'Medium'
                                                                    ? <label className="font-size-12 badge badge-soft-info">Medium</label>
                                                                    : children.impact_level === 'High'
                                                                        ? <label className="font-size-12 badge badge-soft-warning">High</label>
                                                                        : children.impact_level === 'Critical'
                                                                            ? <label className="font-size-12 badge badge-soft-danger">Critical</label>
                                                                            : <label className="font-size-12 badge badge-soft-primary ">No impact</label>

                                                        }
                                                    </>
                                                </Col>
                                                <Col md={7}>
                                                    <>


                                                        <span className="font-size-10">Type </span><br />
                                                        <label className="font-size-12">
                                                            {children.compl_type.map((item, index) => {
                                                                const formattedItem = index === children.compl_type.length - 1 ? item + '.' : item;
                                                                return formattedItem;
                                                            }).join(', ')}
                                                        </label>
                                                    </>
                                                </Col>
                                            </Row>
                                        </div>


                                        <div className="mb-1">
                                            <span className="font-size-14 fw-bold">{children.checkpoint}</span>
                                        </div>




                                        {/* <label style={{ fontWeight: 500 }}>Options</label> */}
                                        {(children.checkpoint_type_id >= '1' && children.checkpoint_type_id <= '5') && (
                                            <ReviewOPType options={children.checkpoint_options} get_btn_color={children} />
                                        )}
                                        {
                                            console.log(children,'children.cp_attach_images')
                                        }

                                        {children.cp_attach_images.length !== 0 && (
                                            <div className="mt-4">
                                                <label>Images Attached</label>
                                                <PreviewImage
                                                    imagePreviewUrl={this.state.imagePreviewUrl}
                                                    images={children.cp_attach_images}
                                                />
                                            </div>
                                        )}
                                          {children.cp_attach_videos !== undefined && children.cp_attach_videos.length !== 0 && (
                                            <div className="mt-4">
                                                <label>Videos Attached</label>
                                                <PreviewVideo
                                                    imagePreviewUrl={this.state.imagePreviewUrl}
                                                    videos={children.cp_attach_videos}
                                                />
                                            </div>
                                        )}

                                        {children.cp_documents.length !== 0 && (
                                            <div className="mt-4">
                                                <label>Documents Attached</label>
                                                <PreviewDocuments
                                                    imagePreviewUrl={this.state.imagePreviewUrl}
                                                    images={children.cp_documents}
                                                />
                                            </div>
                                        )}

                                        {children.cp_observation !== null && (
                                            <div className="mt-4">
                                                <label>Observation</label>
                                                <PreviewObservation observation={children.cp_observation} />
                                            </div>
                                        )}

                                        {children.cp_actionplans.length !== 0 && (
                                            <div className="mt-4">
                                                <label>Corrective And Preventive Action(CAPA)</label>
                                                <PreviewCAPA actionplans={children.cp_actionplans} />
                                            </div>
                                        )}




                                    </CardBody>

                                </Card>
                            }

                        </ul>
                    )}
                    {children.children && children.children.length > 0 && (
                        <div style={{ marginLeft: '20px' }}>
                            {children.children.map((child, index) => (
                                <NestedChildren key={child._id} isOpen={isOpen} >{child}</NestedChildren>
                            ))}
                        </div>
                    )}
                </Collapse>
            </div>
        );
    }else{
        return null
    }

    }
}

export default NestedChildren;















{/* <> */}
{/* <Card
    style={{
        background: "rgb(233, 238, 246)",
        boxShadow: 'none',
        borderBottom: children.document_type === '2' ? '1px solid rgb(164 164 164 / 27%)' : null,
    }}
    className='my-2'
>
    <CardBody>
        <div onClick={this.toggleCollapse} className='d-flex'>
            {
                children.document_type !== '2' &&
                <>

                    {
                        !isOpen ? (
                            <div>

                                <FaChevronDown size={13} className="me-3" />
                            </div>
                        ) : (
                            <div>
                                <FaChevronDown
                                    size={13}
                                    className="me-3"
                                    style={{ transform: "rotate(270deg)" }}
                                />
                            </div>
                        )}
                </>
            }
            {
                children.document_type !== '2' &&
                <>
                    {children.checkpoint}
                </>
            }
        </div>



        <Collapse isOpen={!isOpen || children.document_type == "2"}>
            {children.document_type == "2" && (
                <ul style={{ marginLeft: '' }}>
                    {
                        !isOpen &&
                        <Card
                            className="mt-2"
                            style={{
                                borderTop: children.cp_review_status !== null
                                    ? '5px solid #556ee6'
                                    : children.cp_status === '0'
                                        ? '5px solid #74788d'
                                        : children.cp_status === '1'
                                            ? '5px solid #f1b44c'
                                            : children.cp_status === '2'
                                                ? '5px solid #34c38f'
                                                : '5px solid #50a5f1',
                                borderRadius: '1rem',
                                background: 'white',
                            }}
                        >

                            <CardBody>
                                <div>
                                    <Row className='mb-3'>
                                        <Col md={3}>
                                            {children.cp_status !== '0' && (
                                                <>
                                                    <span className="font-size-10">Compliance status</span><br />

                                                    <label
                                                        className={
                                                            children.cp_compliance.color !== undefined && children.cp_compliance.color === 'success' ? 'text-success font-size-17'
                                                                : children.cp_compliance.color !== undefined && children.cp_compliance.color === 'danger' ? 'text-danger font-size-17'
                                                                    : children.cp_compliance.color !== undefined && children.cp_compliance.color === 'warning' ? 'text-warning font-size-17'
                                                                        : children.cp_compliance.color !== undefined && children.cp_compliance.color === 'secondary' ? 'text-secondary font-size-17'
                                                                            : 'font-size-17'
                                                        }
                                                    >
                                                        {children.cp_compliance.name}
                                                    </label>
                                                </>
                                            )}
                                        </Col>
                                        <Col md={2}>
                                            <>
                                                <span className="font-size-10">Impact Level</span><br />

                                                {

                                                    children.impact_level === 'Low'
                                                        ? <label className="font-size-17 badge badge-soft-success">Low</label>
                                                        : children.impact_level === 'Medium'
                                                            ? <label className="font-size-17 badge badge-soft-info">Medium</label>
                                                            : children.impact_level === 'High'
                                                                ? <label className="font-size-17 badge badge-soft-warning">High</label>
                                                                : children.impact_level === 'Critical'
                                                                    ? <label className="font-size-17 badge badge-soft-danger">Critical</label>
                                                                    : <label className="font-size-17 badge badge-soft-primary ">No impact</label>

                                                }
                                            </>
                                        </Col>
                                        <Col md={7}>
                                            <>

                                                <span className="font-size-10">Type </span><br />
                                                <label className="font-size-17">
                                                    {children.compl_type.map((item, index) => {
                                                        const formattedItem = index === children.compl_type.length - 1 ? item + '.' : item;
                                                        return formattedItem;
                                                    }).join(', ')}
                                                </label>
                                            </>
                                        </Col>
                                    </Row>
                                </div>


                                <div className="mb-3">
                                    <span className="font-size-14 fw-bold">{children.checkpoint}</span>
                                </div>




                                <label style={{ fontWeight: 500 }}>Options</label>
                                {(children.checkpoint_type_id >= '1' && children.checkpoint_type_id <= '5') && (
                                    <ReviewOPType options={children.checkpoint_options} get_btn_color={children} />
                                )}

                                {children.cp_attach_images.length !== 0 && (
                                    <div className="mt-4">
                                        <label>Images Attached</label>
                                        <PreviewImage
                                            imagePreviewUrl={this.state.imagePreviewUrl}
                                            images={children.cp_attach_images}
                                        />
                                    </div>
                                )}

                                {children.cp_documents.length !== 0 && (
                                    <div className="mt-4">
                                        <label>Documents Attached</label>
                                        <PreviewDocuments
                                            imagePreviewUrl={this.state.imagePreviewUrl}
                                            images={children.cp_documents}
                                        />
                                    </div>
                                )}

                                {children.cp_observation !== null && (
                                    <div className="mt-4">
                                        <label>Observation</label>
                                        <PreviewObservation observation={children.cp_observation} />
                                    </div>
                                )}

                                {children.cp_actionplans.length !== 0 && (
                                    <div className="mt-4">
                                        <label>Corrective & Preventive Correction(CAPA)</label>
                                        <PreviewCAPA actionplans={children.cp_actionplans} />
                                    </div>
                                )}




                            </CardBody>

                        </Card>
                    }

                </ul>
            )}

            {
                console.log('children.children', children.children)
            }

           

            

            {children.children && children.children.length > 0 && (
                <div style={{ marginLeft: '20px' }}>
                    {children.children.map((child, index) => (
                        <NestedChildren key={child._id} children={child} isOpen={isOpen} />
                    ))}
                </div>
            )}
        </Collapse>
    </CardBody>
</Card> */}

{/* if (document_type === '2') {
print 'check points'
} else {
if (props.children.children.length > 0) {
print 'menu'
} else {
null
}
} */}

// </>





// {children.children && children.children.length > 0 && (
//     <div style={{ marginLeft: '20px' }}>
//         {children.children.map((child, index) => {

//             if (child.document_type !== '2') {
//                 var get_length = _.filter(children.children, { parent_id: child.document_id }).length
//                 console.log('get_length', get_length)
//             }
//             return <NestedChildren key={child._id} children={child} isOpen={isOpen} />
//         })}
//     </div>
// )}


// import React, { Component } from 'react';
// import { Collapse, CardBody, Card, Row, Col } from 'reactstrap';
// import { FaChevronDown } from 'react-icons/fa';
// import ReviewOPType from "./Components/review_optype";
// import PreviewImage from "./Components/preview_images";
// import PreviewDocuments from "./Components/preview_documents";
// import PreviewObservation from "./Components/preview_observation";
// import PreviewCAPA from "./Components/preview_CAPA";

// class NestedChildren extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isOpen: false,
//         };
//         this.divRef = React.createRef()
//     }

//     componentDidMount() {
//         var data = JSON.parse(sessionStorage.getItem("authUser"));
//         this.setState({ imagePreviewUrl: data.config_data.img_url })
//     }

//     toggleCollapse = () => {
//         console.log("open")
//         this.setState((prevState) => ({
//             isOpen: !prevState.isOpen,
//         }));
//     };

//     render() {
//         const { children, endpointData, filter_cp_compliant } = this.props;
//         const { isOpen } = this.state;
//         // Check if the compliance object exists and has a name property

//         // Check if the compliance status of the checkpoint matches the filter
//         const isComplianceMatchingFilter = children.cp_compliance === null ? '' : children.cp_compliance.name;
//         // console.log('isComplianceMatchingFilter', isComplianceMatchingFilter, filter_cp_compliant)
//         // console.log('isExpanded', this.props.isExpanded)
//         // console.log('isExpanded', this.props.isExpanded, filter_cp_compliant)

//         return (
//             <div>

//                 {
//                     this.props.download_audit ?
//                         <>
//                             <Card
//                                 style={{
//                                     background: "rgb(233, 238, 246)",
//                                     boxShadow: 'none',
//                                     borderBottom: children.document_type === '2' ? '1px solid rgb(164 164 164 / 27%)' : null,
//                                     // cursor: 'pointer'
//                                 }}
//                                 className='my-2'
//                             >
//                                 <CardBody>

//                                     <div onClick={this.toggleCollapse} className='d-flex'>
//                                         {
//                                             children.document_type !== '2' &&
//                                             <>

//                                                 {
//                                                     !isOpen ? (
//                                                         <div>

//                                                             <FaChevronDown size={13} className="me-3" />
//                                                         </div>
//                                                     ) : (
//                                                         <div>
//                                                             <FaChevronDown
//                                                                 size={13}
//                                                                 className="me-3"
//                                                                 style={{ transform: "rotate(270deg)" }}
//                                                             />
//                                                         </div>
//                                                     )}
//                                             </>
//                                         }
//                                         {
//                                             children.document_type !== '2' &&
//                                             <>
//                                                 {children.checkpoint}
//                                             </>
//                                         }


//                                     </div>


//                                     <Collapse isOpen={!isOpen || children.checkpoint_options.length > 0}>
//                                         {children.checkpoint_options && children.checkpoint_options.length > 0 && (
//                                             <ul style={{ marginLeft: '' }}>
//                                                 {
//                                                     !isOpen &&
//                                                     <Card
//                                                         className="mt-2"
//                                                         style={{
//                                                             borderTop: children.cp_review_status !== null
//                                                                 ? '5px solid #556ee6'
//                                                                 : children.cp_status === '0'
//                                                                     ? '5px solid #74788d'
//                                                                     : children.cp_status === '1'
//                                                                         ? '5px solid #f1b44c'
//                                                                         : children.cp_status === '2'
//                                                                             ? '5px solid #34c38f'
//                                                                             : '5px solid #50a5f1',
//                                                             borderRadius: '1rem',
//                                                             background: 'white',
//                                                         }}
//                                                     >

//                                                         <CardBody>
//                                                             <div className="mb-2">
//                                                                 {/* Audit status */}
//                                                                 {/* <span className="font-size-10">Audit status - </span>
//                                                                 <label
//                                                                     className={
//                                                                         children.cp_status === '0'
//                                                                             ? 'badge badge-soft-secondary font-size-10 me-2'
//                                                                             : children.cp_status === '1'
//                                                                                 ? 'badge badge-soft-warning font-size-10 me-2'
//                                                                                 : 'badge badge-soft-success font-size-10 me-2'
//                                                                     }
//                                                                 >
//                                                                     {children.cp_status === '0'
//                                                                         ? 'Not started'
//                                                                         : children.cp_status === '1'
//                                                                             ? 'In progress'
//                                                                             : 'Completed'}
//                                                                 </label> */}

//                                                                 {/* Compliance status */}
//                                                                 {children.cp_status !== '0' && (
//                                                                     <>
//                                                                         <span className="font-size-10">Compliance status - </span>
//                                                                         <label
//                                                                             className={
//                                                                                 children.cp_is_compliance
//                                                                                     ? 'badge badge-soft-success font-size-10'
//                                                                                     : 'badge badge-soft-danger font-size-10'
//                                                                             }
//                                                                         >
//                                                                             {children.cp_compliance.name}
//                                                                         </label>
//                                                                     </>
//                                                                 )}
//                                                             </div>

//                                                             {/* Checkpoint */}
//                                                             {/* <label>Checkpoint</label> */}
//                                                             <div className="mb-3">
//                                                                 <span className="font-size-14 fw-bold">{children.checkpoint}</span>
//                                                             </div>




//                                                             {/* Options */}
//                                                             <label style={{ fontWeight: 500 }}>Options</label>
//                                                             {(children.checkpoint_type_id >= '1' && children.checkpoint_type_id <= '5') && (
//                                                                 <ReviewOPType options={children.checkpoint_options} get_btn_color={children} />
//                                                             )}

//                                                             {/* Images Attached */}
//                                                             {children.cp_attach_images.length !== 0 && (
//                                                                 <div className="mt-4">
//                                                                     <label>Images Attached</label>
//                                                                     <PreviewImage
//                                                                         imagePreviewUrl={this.state.imagePreviewUrl}
//                                                                         images={children.cp_attach_images}
//                                                                     />
//                                                                 </div>
//                                                             )}

//                                                             {/* Documents Attached */}
//                                                             {children.cp_documents.length !== 0 && (
//                                                                 <div className="mt-4">
//                                                                     <label>Documents Attached</label>
//                                                                     <PreviewDocuments
//                                                                         imagePreviewUrl={this.state.imagePreviewUrl}
//                                                                         images={children.cp_documents}
//                                                                     />
//                                                                 </div>
//                                                             )}

//                                                             {/* Observation */}
//                                                             {children.cp_observation !== null && (
//                                                                 <div className="mt-4">
//                                                                     <label>Observation</label>
//                                                                     <PreviewObservation observation={children.cp_observation} />
//                                                                 </div>
//                                                             )}

//                                                             {/* CAPA */}
//                                                             {children.cp_actionplans.length !== 0 && (
//                                                                 <div className="mt-4">
//                                                                     <label>CAPA</label>
//                                                                     <PreviewCAPA actionplans={children.cp_actionplans} />
//                                                                 </div>
//                                                             )}




//                                                         </CardBody>

//                                                     </Card>
//                                                 }

//                                             </ul>
//                                         )}


//                                         {children.children && children.children.length > 0 && (
//                                             <div style={{ marginLeft: '20px' }}>
//                                                 {children.children.map((child, index) => (
//                                                     <NestedChildren key={child._id} children={child} isOpen={isOpen} />
//                                                 ))}

//                                             </div>

//                                         )}
//                                     </Collapse>
//                                 </CardBody>
//                             </Card>
//                         </>
//                         :
//                         <>
//                             {
//                                 console.log('elseeeeee')
//                             }


//                             <Card
//                                 style={{
//                                     background: "rgb(233, 238, 246)",
//                                     boxShadow: 'none',
//                                     borderBottom: children.document_type === '2' ? '1px solid rgb(164 164 164 / 27%)' : null,
//                                     // cursor: 'pointer'
//                                 }}
//                                 className='my-2'
//                             >
//                                 <CardBody>


//                                     <div onClick={this.toggleCollapse} className='d-flex'>
//                                         {
//                                             children.document_type !== '2' &&
//                                             <>

//                                                 {
//                                                     !isOpen ? (
//                                                         <div>

//                                                             <FaChevronDown size={13} className="me-3" />
//                                                         </div>
//                                                     ) : (
//                                                         <div>
//                                                             <FaChevronDown
//                                                                 size={13}
//                                                                 className="me-3"
//                                                                 style={{ transform: "rotate(270deg)" }}
//                                                             />
//                                                         </div>
//                                                     )}
//                                             </>
//                                         }
//                                         {
//                                             children.document_type !== '2' &&
//                                             <>
//                                                 {children.checkpoint}
//                                             </>
//                                         }
//                                     </div>



//                                     <Collapse isOpen={!isOpen || children.document_type == "2"}>
//                                         {children.document_type == "2" && (
//                                             <ul style={{ marginLeft: '' }}>
//                                                 {
//                                                     !isOpen &&
//                                                     <Card
//                                                         className="mt-2"
//                                                         style={{
//                                                             borderTop: children.cp_review_status !== null
//                                                                 ? '5px solid #556ee6'
//                                                                 : children.cp_status === '0'
//                                                                     ? '5px solid #74788d'
//                                                                     : children.cp_status === '1'
//                                                                         ? '5px solid #f1b44c'
//                                                                         : children.cp_status === '2'
//                                                                             ? '5px solid #34c38f'
//                                                                             : '5px solid #50a5f1',
//                                                             borderRadius: '1rem',
//                                                             background: 'white',
//                                                         }}
//                                                     >

//                                                         <CardBody>
//                                                             <div>
//                                                                 <Row className='mb-3'>
//                                                                     <Col md={3}>
//                                                                         {children.cp_status !== '0' && (
//                                                                             <>
//                                                                                 <span className="font-size-10">Compliance status</span><br />

//                                                                                 <label
//                                                                                     className={
//                                                                                         children.cp_compliance.color !== undefined && children.cp_compliance.color === 'success' ? 'text-success font-size-17'
//                                                                                             : children.cp_compliance.color !== undefined && children.cp_compliance.color === 'danger' ? 'text-danger font-size-17'
//                                                                                                 : children.cp_compliance.color !== undefined && children.cp_compliance.color === 'warning' ? 'text-warning font-size-17'
//                                                                                                     : children.cp_compliance.color !== undefined && children.cp_compliance.color === 'secondary' ? 'text-secondary font-size-17'
//                                                                                                         : 'font-size-17'
//                                                                                     }
//                                                                                 >
//                                                                                     {children.cp_compliance.name}
//                                                                                 </label>
//                                                                             </>
//                                                                         )}
//                                                                     </Col>
//                                                                     <Col md={2}>
//                                                                         <>
//                                                                             <span className="font-size-10">Impact Level</span><br />

//                                                                             {/* <label className="font-size-15"> */}
//                                                                             {

//                                                                                 children.impact_level === 'Low'
//                                                                                     ? <label className="font-size-17 badge badge-soft-success">Low</label>
//                                                                                     : children.impact_level === 'Medium'
//                                                                                         ? <label className="font-size-17 badge badge-soft-info">Medium</label>
//                                                                                         : children.impact_level === 'High'
//                                                                                             ? <label className="font-size-17 badge badge-soft-warning">High</label>
//                                                                                             : children.impact_level === 'Critical'
//                                                                                                 ? <label className="font-size-17 badge badge-soft-danger">Critical</label>
//                                                                                                 : <label className="font-size-17 badge badge-soft-primary ">No impact</label>

//                                                                             }
//                                                                             {/* </label> */}
//                                                                         </>
//                                                                     </Col>
//                                                                     <Col md={7}>
//                                                                         <>

//                                                                             <span className="font-size-10">Type </span><br />
//                                                                             <label className="font-size-17">
//                                                                                 {/* {children.compl_type +  children.compl_type.map((list,idx)=>{ })} */}
//                                                                                 {children.compl_type.map((item, index) => {
//                                                                                     const formattedItem = index === children.compl_type.length - 1 ? item + '.' : item;
//                                                                                     return formattedItem;
//                                                                                 }).join(', ')}
//                                                                             </label>
//                                                                         </>
//                                                                     </Col>
//                                                                 </Row>





//                                                             </div>

//                                                             {/* Checkpoint */}
//                                                             {/* <label>Checkpoint</label> */}
//                                                             <div className="mb-3">
//                                                                 <span className="font-size-14 fw-bold">{children.checkpoint}</span>
//                                                             </div>




//                                                             {/* Options */}
//                                                             <label style={{ fontWeight: 500 }}>Options</label>
//                                                             {(children.checkpoint_type_id >= '1' && children.checkpoint_type_id <= '5') && (
//                                                                 <ReviewOPType options={children.checkpoint_options} get_btn_color={children} />
//                                                             )}

//                                                             {/* Images Attached */}
//                                                             {children.cp_attach_images.length !== 0 && (
//                                                                 <div className="mt-4">
//                                                                     <label>Images Attached</label>
//                                                                     <PreviewImage
//                                                                         imagePreviewUrl={this.state.imagePreviewUrl}
//                                                                         images={children.cp_attach_images}
//                                                                     />
//                                                                 </div>
//                                                             )}

//                                                             {/* Documents Attached */}
//                                                             {children.cp_documents.length !== 0 && (
//                                                                 <div className="mt-4">
//                                                                     <label>Documents Attached</label>
//                                                                     <PreviewDocuments
//                                                                         imagePreviewUrl={this.state.imagePreviewUrl}
//                                                                         images={children.cp_documents}
//                                                                     />
//                                                                 </div>
//                                                             )}

//                                                             {/* Observation */}
//                                                             {children.cp_observation !== null && (
//                                                                 <div className="mt-4">
//                                                                     <label>Observation</label>
//                                                                     <PreviewObservation observation={children.cp_observation} />
//                                                                 </div>
//                                                             )}

//                                                             {/* CAPA */}
//                                                             {children.cp_actionplans.length !== 0 && (
//                                                                 <div className="mt-4">
//                                                                     <label>Corrective & Preventive Correction(CAPA)</label>
//                                                                     <PreviewCAPA actionplans={children.cp_actionplans} />
//                                                                 </div>
//                                                             )}




//                                                         </CardBody>

//                                                     </Card>
//                                                 }

//                                             </ul>
//                                         )}

//                                         {
//                                             console.log('children.children', children.children)
//                                         }

//                                         {children.children && children.children.length > 0 && (
//                                             <div style={{ marginLeft: '20px' }}>
//                                                 {children.children.map((child, index) => (
//                                                     <NestedChildren key={child._id} children={child} isOpen={isOpen} />
//                                                 ))}
//                                             </div>
//                                         )}
//                                     </Collapse>
//                                 </CardBody>
//                             </Card>
//                         </>
//                 }



//             </div>
//         );

//     }
// }

// export default NestedChildren;




// if (document_type === '2') {
//     print 'check points'
// } else {
//     if (props.children.children.length > 0) {
//         print 'menu'
//     } else {
//         null
//     }
// }








// import React, { Component } from 'react';
// import { Collapse, CardBody, Card } from 'reactstrap';
// import { FaChevronDown } from 'react-icons/fa';
// import ReviewOPType from "./Components/review_optype";
// import PreviewImage from "./Components/preview_images";
// import PreviewDocuments from "./Components/preview_documents";
// import PreviewObservation from "./Components/preview_observation";
// import PreviewCAPA from "./Components/preview_CAPA";

// class NestedChildren extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isCollapseOpen: false,
//     };
//   }

//   componentDidMount(){
//     var data = JSON.parse(sessionStorage.getItem("authUser"));
//     this.setState({imagePreviewUrl: data.config_data.img_url})
//   }

//   toggleCollapse = () => {
//     this.setState((prevState) => ({
//       isCollapseOpen: !prevState.isCollapseOpen,
//     }));
//   };

//   render() {
//     const { children, endpointData } = this.props;
//     const { isCollapseOpen } = this.state;
   

//     return (
//       <div>
//             <Card
//                 style={{
//                     // borderRadius: "1rem",
//                     background: "rgb(233, 238, 246)",
//                     boxShadow: 'none',
//                     // boxShadow:
//                     // isCollapseOpen &&
//                     //     "0 0 10px rgba(0, 0, 255, 0.3)",
//                     borderBottom: '1px solid rgb(164 164 164 / 27%)',
//                     cursor: 'pointer'
//                 }}
//                 className='my-2'
//             >
//                 <CardBody>
//                     <div onClick={this.toggleCollapse} className='d-flex'>
//                         {/* <FaChevronDown
//             size={16}
//             className={isCollapseOpen ? 'me-3' : 'me-3 rotate'}
//           /> */}

//                         {isCollapseOpen ? (
//                             <div>
//                                 <FaChevronDown size={16} className="me-3" />
//                             </div>
//                         ) : (
//                             <div>
//                                 <FaChevronDown
//                                     size={16}
//                                     className="me-3"
//                                     style={{ transform: 'rotate(270deg)' }}
//                                 />
//                             </div>
//                         )}
//                         {children.checkpoint}
//                     </div>

//                     <Collapse isOpen={isCollapseOpen ||  children.checkpoint_options.length > 0 && isCollapseOpen}>
//                         {children.checkpoint_options && children.checkpoint_options.length > 0 && (
//                             <ul style={{ marginLeft: '20px' }}>
                                
//                                 {children.checkpoint_options.map((item, index) => (
//                                     <Card
//                                         key={index}
//                                         className="mt-2"
//                                         style={{
//                                             borderTop:
//                                                 children.cp_review_status !== null
//                                                     ? '5px solid #556ee6'
//                                                     : children.cp_status === '0'
//                                                         ? '5px solid #74788d'
//                                                         : children.cp_status === '1'
//                                                             ? '5px solid #f1b44c'
//                                                             : children.cp_status === '2'
//                                                                 ? '5px solid #34c38f'
//                                                                 : '5px solid #50a5f1',
//                                             borderRadius: '1rem',
//                                             background: 'white',
                                            
//                                         }}
//                                     >
//                                         <CardBody>
//                                             <div className="mb-2">
//                                                 {/* Audit status */}
//                                                 <span className="font-size-10">Audit status - </span>

//                                                 <label
//                                                     className={
//                                                         children.cp_status === '0'
//                                                             ? 'badge badge-soft-secondary font-size-10 me-2'
//                                                             : children.cp_status === '1'
//                                                                 ? 'badge badge-soft-warning font-size-10 me-2'
//                                                                 : 'badge badge-soft-success font-size-10 me-2'
//                                                     }
//                                                 >
//                                                     {children.cp_status === '0'
//                                                         ? 'Not started'
//                                                         : children.cp_status === '1'
//                                                             ? 'In progress'
//                                                             : 'Completed'}
//                                                 </label>

//                                                 {/* Review status */}

//                                                 {/* {this.props.endpointData.status === '4' &&
//                                                     children.cp_review_status !== null ? (
//                                                     <>
//                                                         <span className="font-size-10">Review Status - </span>
//                                                         <span
//                                                             className={
//                                                                 children.cp_review_status === null
//                                                                     ? 'badge badge-soft-secondary font-size-10 me-2'
//                                                                     : 'badge badge-soft-primary font-size-10 me-2'
//                                                             }
//                                                         >
//                                                             {children.cp_review_status === null
//                                                                 ? 'Not Reviewed'
//                                                                 : 'Reviewed'}
//                                                         </span>
//                                                         <span
//                                                             className={
//                                                                 children.cp_review_status === '0'
//                                                                     ? 'badge badge-soft-danger font-size-10 me-2'
//                                                                     : 'badge badge-soft-success font-size-10 me-2'
//                                                             }
//                                                         >
//                                                             {children.cp_review_status === '0' ? 'Rejected' : 'Approved'}
//                                                         </span>{' '}
//                                                     </>
//                                                 ) : null} */}

//                                                 {/* Compliance status */}
//                                                 {children.cp_status !== '0' ? (
//                                                     <>
//                                                         <span className="font-size-10">Compliance status - </span>
//                                                         <label
//                                                             className={
//                                                                 children.cp_is_compliance
//                                                                     ? 'badge badge-soft-success font-size-10'
//                                                                     : 'badge badge-soft-danger font-size-10'
//                                                             }
//                                                         >
//                                                             {children.cp_compliance.name}
//                                                         </label>{' '}
//                                                     </>
//                                                 ) : null}
//                                             </div>

//                                             {/* Checkpoint */}
//                                             <label>Checkpoint</label>
//                                             <div className="mb-3">
//                                                 <span className="font-size-14 fw-bold">{children.checkpoint}</span>
//                                             </div>

//                                             {/* Options */}
//                                             <label style={{ fontWeight: 500 }}>Options</label>
//                                             {children.checkpoint_type_id === '1' ||
//                                                 children.checkpoint_type_id === '2' ||
//                                                 children.checkpoint_type_id === '3' ||
//                                                 children.checkpoint_type_id === '4' ||
//                                                 children.checkpoint_type_id === '5' ? (
//                                                 <ReviewOPType options={children.checkpoint_options} />
//                                             ) : null}

//                                             {/* Images Attached */}
//                                             {children.cp_attach_images.length !== 0 ? (
//                                                 <div className="mt-4">
//                                                     <label>Images Attached</label>
//                                                     <PreviewImage
//                                                         imagePreviewUrl={this.state.imagePreviewUrl}
//                                                         images={children.cp_attach_images}
//                                                     />
//                                                 </div>
//                                             ) : null}

//                                             {/* Documents Attached */}
//                                             {children.cp_documents.length !== 0 ? (
//                                                 <div className="mt-4">
//                                                     <label>Documents Attached</label>
//                                                     <PreviewDocuments
//                                                         imagePreviewUrl={this.state.imagePreviewUrl}
//                                                         images={children.cp_documents}
//                                                     />
//                                                 </div>
//                                             ) : null}

//                                             {/* Observation */}
//                                             {children.cp_observation !== null ? (
//                                                 <div className="mt-4">
//                                                     <label>Observation</label>
//                                                     <PreviewObservation observation={children.cp_observation} />
//                                                 </div>
//                                             ) : null}

//                                             {/* CAPA */}
//                                             {children.cp_actionplans.length !== 0 ? (
//                                                 <div className="mt-4">
//                                                     <label>CAPA</label>
//                                                     <PreviewCAPA actionplans={children.cp_actionplans} />
//                                                 </div>
//                                             ) : null}
//                                         </CardBody>
//                                     </Card>
//                                 ))}
//                             </ul>
//                         )}

//                         {children.children && children.children.length > 0 && (
//                             <div style={{ marginLeft: '20px' }}>
//                                 {children.children.map((child) => (
//                                     <NestedChildren key={child._id} children={child} />
//                                 ))}
//                             </div>
//                         )}
//                     </Collapse>
//                 </CardBody>
//             </Card>
//       </div>
//     );
//   }
// }

// export default NestedChildren;
































// import React from 'react';
// import { Collapse } from 'reactstrap';
// import { FaChevronDown } from 'react-icons/fa';

// const NestedChildren = ({ children }) => {
//   const [isCollapseOpen, setIsCollapseOpen] = React.useState(false);

//   const toggleCollapse = () => {
//     setIsCollapseOpen(!isCollapseOpen);
//   };

//   return (
//     <div>
//       <div onClick={toggleCollapse}>
//         <FaChevronDown size={16} className={isCollapseOpen ? 'me-3' : 'me-3 rotate'} />
//         {children.checkpoint}
//       </div>
//       <Collapse isOpen={isCollapseOpen}>
//         {children.children && children.children.length > 0 && (
//           <div style={{ marginLeft: '20px' }}>
//             {children.children.map((child) => (
//               <NestedChildren key={child._id} children={child} />
//             ))}
//           </div>
//         )}
//       </Collapse>
//     </div>
//   );
// };

// export default NestedChildren;