import { createSlice } from "@reduxjs/toolkit"
import { dispatch } from "d3";
import urlSocket from "../../helpers/urlSocket";

const initialState = {
    aplnRequestError: null,
    userlist:[],
    recentuserlist:[],
    singlemessages:[],
    groupmessages:[],
    aplnRequestLoading: false,
    selecteduser:null,
    roomlist:[],
    activeuser:[],
    markerState:null,
}


const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setRecentUsers: (state, action) => {
            state.recentuserlist = action.payload
        },
        setRoomList: (state, action) => {
            state.roomlist = action.payload
        },
        setUserList: (state, action) => {
            state.userlist = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selecteduser = action.payload
        },
        setSingleMessages: (state, action) => {
            state.singlemessages = action.payload
        },
        setGroupMessages: (state, action) => {
            state.groupmessages = action.payload
        },
        setRequestError: (state, action) => {
            state.aplnRequestError = action.payload
        },
        setRequestLoading: (state, action) => {
            state.aplnRequestLoading = action.payload
        },
        setactiveuser:(state,action)=>{
            state.activeuser = action.payload
        },
        setMarkerState(state, action) {
            state.markerState = action.payload;
        },
        updateGroup(state, action){
            console.log("action.payload",action.payload)
            var getrecentuser = [...state.recentuserlist]
            var getselecteduser = state.selecteduser
            if (action.payload.type === undefined) {
                console.log("group")
                var useridx = _.findIndex(getrecentuser, { sender_id: getselecteduser.sender_id })
                if (useridx !== -1) getrecentuser[useridx].group_users = action.payload
                state.selecteduser.group_users = action.payload

            } else {
                console.log("single")
                var dynamicKeyName = action.payload.group_id === null ? "sender_id" : "group_id"
                var dynamicValue = action.payload.group_id === null ? action.payload.sender_id : action.payload.group_id
                var useridx = _.findIndex(getrecentuser, { [dynamicKeyName]: dynamicValue })
                if(useridx !==-1){
                    getrecentuser[useridx].unreadCount =0
                    state.recentuserlist = getrecentuser
                }
                // state.selecteduser.unreadCount = 0
                // console.log("selecteduser",state.selecteduser)
            }
        },
        updateRecentuser(state, action){
            console.log("action",action.payload)
            var getrecentuser = [...state.recentuserlist]
            var dynamicKeyName = action.payload.msg.group_id === undefined ? "sender_id" : "group_id"
            var dynamicValue = action.payload.msg.group_id === undefined ? action.payload.msg.sender_id : action.payload.msg.group_id
            var useridx = _.findIndex(getrecentuser,{[dynamicKeyName]:dynamicValue})   
            if(useridx !== -1) getrecentuser[useridx].unreadCount = action.payload.unreadcount     
        }
    }
});


export const {
    addMessage,
    setUserList,
    setRecentUsers,
    setSingleMessages,
    setSelectedUser,
    setRoomList,
    setRequestLoading,
    setRequestError ,
    setactiveuser,
    setMarkerState,
    setGroupMessages,
    updateGroup,
    updateRecentuser
} = chatSlice.actions;
export default chatSlice.reducer;




export const getAllUsers = (mode) => {
    return async dispatch => {
        const dbInfo = JSON.parse(sessionStorage.getItem("db_info"));
        const userData = JSON.parse(sessionStorage.getItem("authUser"));
        try {
            dispatch(setRequestLoading(true))
            const responseData = await urlSocket.post("task/get-users", { dbInfo, userData, mode })
            if (responseData.status === 200) {
                let user = _.groupBy(responseData.data.users, item => item.firstname[0].toUpperCase());
                dispatch(setUserList(responseData.data.users))
            }
            else {
                dispatch(setRequestError(responseData.statusText))
            }
            dispatch(setRequestLoading(false))
        } catch (error) {
            console.log("error", error)
        }
    }
}



export const getRecentUsers = () => {
    const dbInfo = JSON.parse(sessionStorage.getItem("db_info"));
    const userData = JSON.parse(sessionStorage.getItem("authUser"));
    return async dispatch => {
        try {
            dispatch(setRequestLoading(true))
            const responseData = await urlSocket.post("task/get-recent-user", { dbInfo, userData })
            if (responseData.status === 200) {
                dispatch(setRecentUsers(responseData.data.users))
            }
            else {
                dispatch(setRequestError(responseData.statusText))
            }
            dispatch(setRequestLoading(false))
        } catch (error) {
            console.log("error", error)

        }
    }
}


export const getSingleChatMsg = (data) => {


    const dbInfo = JSON.parse(sessionStorage.getItem("db_info"));
    return async dispatch => {
        try {
            dispatch(setRequestLoading(true))
            const responseData = await urlSocket.post("task/get-single-chat", { dbInfo, data })
            if (responseData.status === 200) {
                dispatch(setSingleMessages(responseData.data.data))
                console.log("responseData.data.data",responseData.data.data)
            }
            else {
                dispatch(setRequestError(responseData.statusText))
            }
            dispatch(setRequestLoading(false))
        } catch (error) {
            console.log("error", error)
        }
    }
}


export const getRoomUserList = (data) => {
    return async dispatch => {
        try {
            dispatch(setRequestLoading(true))
            const dbInfo = JSON.parse(sessionStorage.getItem("db_info"));
            const userData = JSON.parse(sessionStorage.getItem("authUser"));
            const responseData = await urlSocket.post("task/get-room-user", { dbInfo, userData })
            if (responseData.status === 200) {
                dispatch(setRoomList(responseData.data.rooms))
            }
            else {
                dispatch(setRequestError(responseData.statusText))
            }
            console.log("responseData", responseData)
            dispatch(setRequestLoading(false))
        } catch (error) {
            console.log("error", error)
        }
    }
}


function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const uploadImageToAws = (file) => {
    const enhancedFile = {
        "preview": URL.createObjectURL(file),
        "formattedSize": formatBytes(file.size),
        "uploading": false,
        "filetype": file.type,
        "uploadingStatus": 'Not uploaded',
        "originalName": file.name,
        "captured_on": new Date(),
        "path": file.name,
    };

    const formData = new FormData();

    Object.keys(enhancedFile).forEach(key => {
        formData.append(key, enhancedFile[key]);
    });

    formData.append('image', file);



    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return async dispatch => {
        try {
            dispatch(setRequestLoading(true))

            console.log("image try");

            const response = await urlSocket.post("task/uploads-aws", formData, config, {});

            console.log("response from aws", response.data.data[0].originalname);
            return response.data.data[0].originalname;
        } catch (error) {
            console.log("error", error);
            throw error;
        }
        
    };
};


export const uploadDocsToAws = (file) => {
    console.log("file", file);
    const formData = new FormData();
    formData.append("document", file);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    return async dispatch => {
        try {
            const response = await urlSocket.post("task/uploads-aws", formData, config, {});
            console.log("response from aws", response.data.data[0].originalname);
            return response.data.data[0].originalname;
        } catch (error) {
            console.log("error", error);
            throw error;
        }
    };
};





export const uploadRecordedAudioToAws = (file) => {
    console.log("file", file.blob);
    const formData = new FormData();
    formData.append("video", file.blob);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    return async (dispatch) => {
        try {
            const response = await urlSocket.post("task/uploads-aws", formData, config, {});
            console.log("response from aws", response);
            return response.data.data[0].originalname;
        } catch (error) {
            console.log("error", error);
            throw error;
        }
    };
};



export const uploadVideoToAws = (file) => {
    const formData = new FormData();
    formData.append("video", file);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    return async (dispatch) =>{
        try {
            const response = await urlSocket.post("task/uploads-aws", formData, config, {})
            return response.data.data[0].originalname
        } catch (error) {
            console.log("error",error)       
        }
    } 
}



export const getGroupChatMsg = (data) => {

    console.log("data",data)




    const dbInfo = JSON.parse(sessionStorage.getItem("db_info"));
    const userData = JSON.parse(sessionStorage.getItem("authUser"));

    var ownuser = data.group_users.filter(item => item._id === userData.user_data._id)

    console.log("ownuser",ownuser)

    var mode = ownuser[0].left_at === undefined ? "1" : "2"

    console.log("mode",mode)

    return async dispatch => {
        try {
            dispatch(setRequestLoading(true))
            const responseData = await urlSocket.post('task/get-room-single-messages', {
                encrypted_db_url: dbInfo.encrypted_db_url,
                group_id: data.sender_id,
                userData: userData.user_data,
                mode:mode,
                left_at :ownuser[0].left_at
            })
            if (responseData.status === 200) {
                console.log("responseData.data.messages",responseData.data.messages)
                dispatch(setGroupMessages(responseData.data.messages))
            }
            else {
                dispatch(setRequestError(responseData.statusText))
            }
        } catch (error) {
            console.log("error", error)
        }
        dispatch(setRequestLoading(false))
    }
}



