import React, { Component } from "react"
import { isEmpty } from "lodash"
import MetaTags from 'react-meta-tags';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

import { Row, Col, CardBody, Card, Container, Alert, Button } from "reactstrap"

import _ from "lodash";
var urlSocket = require("../../../helpers/urlSocket");


class AddEndpoint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataloaded: false,
            alertEnable:false,
            formDisabled:false,
      
        };
    }
    componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        this.setState(
            {
                userInfo: data.user_data,
                dataloaded: true,

            },
            function () {
                //this.getEntityDetaitedData()
            }
        )
    }

    handleValidSubmit = (event, values) => {
        try {
            var loggedUserInfo = {
                company_id: this.state.userInfo.company_id,
                company_name: this.state.userInfo.company_name,
            }
            urlSocket.post("webEntities/crudendpoint", {
                loggedUserInfo: loggedUserInfo,
                newEndpointInfo: values
            })
                .then((response) => {
                    if (response.data.response_code == 504) {
                        this.setState({ formDisabled: false, alertEnable: true, alertColor: "danger", message: response.data.message })
                    }
                    else
                        if (response.data.response_code == 500) {
                            this.setState({
                                entity_name: response.data.data.entity_name,
                                formDisabled: true,
                                alertEnable: true,
                                alertColor: "success",
                                message: response.data.message
                            })
                        }
                })
        } catch (error) {

        }
    }

    render() {
        if (this.state.dataloaded) {
            return (
                <React.Fragment>
                    <MetaTags>
                        <title>AuditVista | Add Location</title>
                    </MetaTags>

                    <div className="page-content">
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 20, alignItems: 'center' }}>
                            <div style={{ width: '80%', padding: '0 20px', display: 'flex', justifyContent: 'flex-start' }}>
                                Add New Location
                            </div>
                            <div style={{ width: '20%', padding: '0 20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Link ><Button color="primary" onClick={()=>this.props.history.goBack()}>Back <i className="mdi mdi-arrow-left"></i></Button></Link>
                            </div>
                        </div>
                        <Container fluid>
                            <Row >
                                <Col >
                                    <Card className="overflow-hidden">
                                        {
                                            !this.state.formDisabled ?
                                                <CardBody className="pt-0">
                                                    <div className="p-5">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>


                                                            <Row className="my-4">
                                                                <div className="text-danger font-size-18">Location Information</div>
                                                                <hr className="my-4" />
                                                            </Row>

                                                            <Row>
                                                                <Col className="col-4">
                                                                    <div className="mb-3" >
                                                                        <AvField
                                                                            name="entity_name"
                                                                            label="Name"
                                                                            className="form-control"
                                                                            placeholder="Enter Name"
                                                                            type="text"
                                                                            required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-4">
                                                                    <div className="mb-3" >
                                                                        <AvField
                                                                            name="entity_code"
                                                                            label="Code"
                                                                            className="form-control"
                                                                            placeholder="Enter Code"
                                                                            type="text"
                                                                            required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-4">
                                                                    <div className="mb-3" >
                                                                        <AvField
                                                                            name="endpoint_type"
                                                                            label="Location Type"
                                                                            className="form-control"
                                                                            placeholder="Enter Endpoint Type"
                                                                            type="text"
                                                                            required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                </Row>

                                                            <div className="mb-3" >

                                                            </div>
                                                            {
                                                                this.state.alertEnable ?
                                                                    <div className="mt-4 d-grid">
                                                                        <Alert color={this.state.alertColor}>
                                                                            {this.state.message}
                                                                        </Alert>
                                                                    </div>
                                                                    : null
                                                            }


                                                            <div className="mt-4 d-grid">
                                                                <button
                                                                    className="btn btn-danger btn-block"
                                                                    type="submit"
                                                                >
                                                                    Add Location
                                                                </button>
                                                            </div>

                                                        </AvForm>
                                                    </div>
                                                </CardBody> :
                                                <CardBody>
                                                    <div className="mt-4 text-center">
                                                        <div className="mb-0" style={{ fontSize: 20 }}>
                                                            <span className="text-danger">{this.state.entity_name}</span> has Added successfully
                                                        </div>
                                                        <div style={{ marginTop: 20 }}>
                                                            <Link className="btn btn-success" onClick={()=>this.props.history.goBack()}>
                                                                Click here to Redirect
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                        }
                                    </Card>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            )
        }
        else {
            return null
        }
    }
}



export default AddEndpoint
