import React, { useState, useEffect, useRef, useMemo } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { withRouter, Link, useHistory } from "react-router-dom"
import Swal from 'sweetalert2';
import SidePanel from '../ReportD3/Components/SidePanel';
// import DeleteModal_default from './Delete_Modal'
import { Icon } from '@mdi/react';
import { mdiTableLarge, mdiTableOff } from '@mdi/js';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { v4 as uuidv4 } from 'uuid';

// Images Assets path
import one_row from '../../assets/images/row.jpg'
import two_col from '../../assets/images/2-col.jpg'
import three_col from '../../assets/images/3-col.jpg'
import four_col from '../../assets/images/4-col.jpg'
import five_col from '../../assets/images/5-col.jpg'
import barChart from '../../../src/assets/images/crp_bar.png'
import hor_barChart from '../../../src/assets/images/Picsart_24-01-25_19-04-12-866.jpg'
import areaChart from '../../../src/assets/images/crp_Areas.png'
import stackChart from '../../../src/assets/images/crp_stack.png'
import hor_stackChart from '../../../src/assets/images/Picsart_24-01-25_19-06-10-286.png'
import pieChart from '../../../src/assets/images/crp_piechrt.png'
import lineChart from '../../../src/assets/images/crp_linechrt.png'
import rectangle from '../../assets/images/png-clipart-rectangle-shape-shape-angle-rectangle-thumbnail.png'

//charts JS path
import BarChart from './Components/D3Charts/BarChart'
import StackChart from './Components/D3Charts/StackChart'
import AreaChart from './Components/D3Charts/AreaChart'
import PieChart from './Components/D3Charts/PieChart'
import LineChart from "./Components/D3Charts/LineChart";
import HorizontalbarChart from './Components/D3Charts/HorizontalBarChart'
import HorizontalStackChart from './Components/D3Charts/HorizontalStackChart'
import vertical from '../../../src/assets/images/crp_linechrt2.png'
import VerticalLineChart from './Components/D3Charts/VerticalLineChart'
import D3Table from "./Components/D3Charts/D3Table";

import {
    Row, Col, Button, Container, Label, Modal, CardBody,
    Card,
    CardTitle, UncontrolledTooltip, DropdownMenu,
    DropdownToggle, UncontrolledDropdown,
    DropdownItem,
} from "reactstrap";
import { Space, Spin, Empty, Input, Tooltip } from 'antd';

import urlSocket from "../../helpers/urlSocket";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
// import { getPageLayoutData } from './Redux/ApiSlice'
import { useDispatch, useSelector } from 'react-redux';
import TreeStructure from "./Components/SortableTree/TreeStructure";
import Breadcrumbs from "../Audit/ManageMasterTemplate/Components/Breadcrumb2";
import { updateLayoutInfo, createLayout, textBlock, updateChartData, updateLayoutData,retrivePageLayout } from '../../store/reportd3/reportslice'


const ResponsiveGridLayout = WidthProvider(Responsive);
const LoadingOverlay = () => {
    return (
        <div className="loading-overlay">
            <div className="spinner"></div>
        </div>
    );
};

const Report = ({ route, navigate }) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const reportSlice = useSelector((state) => state.reportSliceReducer)
    const nodeInfo = reportSlice.pageNodeInfo
    const line_data =
        [
            { year: 2010, value: 20 },
            { year: 2011, value: 40 },
            { year: 2012, value: 10 },
            { year: 2013, value: 60 },
            { year: 2014, value: 30 },
            { year: 2015, value: 50 },
        ];

    const area_data =
        [
            { year: 2001, value: 10 },
            { year: 2010, value: 20 },
            { year: 2011, value: 40 },
            { year: 2012, value: 10 },
            { year: 2013, value: 60 },
            { year: 2014, value: 30 },
            { year: 2015, value: 50 },
            { year: 2015, value: 50 },
        ]
    const layoutRef = useRef(null)
    const [targetBlock, setTargetBlock] = useState(null);
    const [icon_data, set_icon_data] = useState(null);
    const [icon_name, set_icon_name] = useState(null);
    const [isResizing, setIsResizing] = useState(false);
    const [updt_data, set_updt_data] = useState();
    const [showSaveAnimation, setShowSaveAnimation] = useState(false);
    const [standard_layout, setStandard_layout] = useState(true);
    const [cached_layout, set_cached_layout] = useState([])
    const [cached_data, setcached_data] = useState(false);
    const [auto_layout, setAuto_layout] = useState(false);
    const [charts, setcharts] = useState(false);
    const [grid, setgrid] = useState(false);
    const [Text, setText] = useState(false);
    const [select_menu, set_select_menu] = useState('1');
    const [temp_layout, settemp_layout] = useState([])
    const [old_item_swap, setold_item_swap] = useState([]);
    const [new_item_swap, setnew_item_swap] = useState([]);
    const [isSidePanelOpen, setSidePanelOpen] = useState(false);
    const [sidepanel_data, setSidepanel_data] = useState([]);
    const [dbInfo, setDbInfo] = useState(JSON.parse(sessionStorage.getItem('db_info')))
    const [pageInfo, setpageInfo] = useState(JSON.parse(sessionStorage.getItem('page_data')))
    const [userInfo, setuserInfo] = useState(JSON.parse(sessionStorage.getItem('authUser')).user_data)
    // const [layoutId, setlayoutId] = useState('');
    const [dbCollections, setdbCollections] = useState(JSON.parse(sessionStorage.getItem('authUser')).config_data.report_collection_name)
    const [defaultBarChartValue, setDefaultBarChartValue] = useState(JSON.parse(sessionStorage.getItem('authUser')).config_data.default_bar_chart_values)
    const [defaultPieChartValue, setDefaultPieChartValue] = useState(JSON.parse(sessionStorage.getItem('authUser')).config_data.default_pie_chart_values)
    const [defaultStackChartValue, setDefaultStackChartValue] = useState(JSON.parse(sessionStorage.getItem('authUser')).config_data.default_stack_chart_values)
    const [defaultLineChartValue, setDefaultLineChartValue] = useState(JSON.parse(sessionStorage.getItem('authUser')).config_data.default_line_chart_values)
    const [deleteModal, setDeleteModal] = useState(false);
    const [showTable, setshowTable] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [result, setresult] = useState([])
    const [mod_layout, setmod_layout] = useState([])
    const [rendercomp, setrendercomp] = useState()
    const [prvpage, setprvpage] = useState(JSON.parse(sessionStorage.getItem('layout_preview')) || false)


    const [fullscreenSize, setFullscreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // const LayoutArray = useSelector(state => state.LayoutArray);
    // console.log("layoutInfo", LayoutArray)

    const layout = reportSlice.layoutInfo
    const layoutId = reportSlice.layoutId
    const [data_loaded, setdata_loaded] = useState(true)
    const db_data = {
        dbInfo, layoutId, pageInfo, userInfo
    }
    useEffect(() => {
        dispatch(retrivePageLayout())
        // retriveDynamicDbCollections()
    }, [dispatch])

    useEffect(() => {
        setTimeout(() => {
            // console.log("llllllllll", layout, updt_data, LayoutArray.layoutInfo);
        }, 100);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        //  var data =    handleResize()
    }, [mod_layout])


    useEffect(() => {
        rearrange_blocks(layout)
            .then(arr => {
                handleResize(arr)
            })
            .catch(error => {
                console.error('Error rearranging blocks:', error);
            });

    }, [layout, data_loaded])
    // const handleResize = async () => {
    //     if (!isFullScreen) {
    //         setFullscreenSize({
    //             width: window.innerWidth,
    //             height: window.innerHeight,
    //         });
    //     }

    //     function keydownHandler(event) {
    //         // console.log("eventevent", event);
    //         if (event.key === "Meta" || event.key === "Escape") {
    //         }
    //     }
    //     // Add event listener for keydown events
    //     document.addEventListener('keydown', keydownHandler);
    // };


    const handleResize = async (data) => {
        var loadedData
        if (mod_layout !== undefined && mod_layout.length > 0) {
            loadedData = mod_layout
        }
        else {
            loadedData = layout
        }
        setFullscreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        var responsed = await responsive_chrts(loadedData)
        if (responsed !== undefined && responsed.length > 0) {

            //  await   setlayout(responsed)
            rearrange_blocks(responsed)
            // set_updt_data(responsed)
            // dispatch(updateLayoutInfo(responsed))
            // dispatch(updateLayoutData(responsed))
        }
      

    };



    const responsive_chrts = async (updt_data) => {
        if (updt_data?.length > 0) {
            let clone_lay = [...layout]
            await updt_data.map(async (chrt, index) => {
                const itemId = chrt.i;
                let width = 0;
                let height = 0;
                const targetdiv_Element = await document.getElementById(itemId);
                if (targetdiv_Element) {
                    const style = targetdiv_Element.style;
                    width = targetdiv_Element.offsetWidth - 10; // Get the width
                    height = targetdiv_Element.offsetHeight; // Get the height

                    clone_lay[index] = {
                        ...chrt,
                        containerWidth: width,
                        containerHeight: height,
                        resized: true
                    };
                }

            })
            return clone_lay
        }
    }
    // const retriveDynamicDbCollections = () => {
    //     setdbCollections(JSON.parse(sessionStorage.getItem('authUser')).config_data.report_collection_name)
    // }

    // const retrivePageLayout = () => {
    //     try {
    //         urlSocket.post('report/find-page-layout', {
    //             encrypted_db_url: dbInfo.encrypted_db_url,
    //             template_id: pageInfo._id,
    //             created_by: userInfo._id,
    //             node_id: nodeInfo.pageNodeInfo.id
    //         }).then(async (response) => {
    //             if (response.status === 200) {
    //                 if (response.data.data[0]?.layout.length > 0) {
    //                     var data = response.data.data[0]?.layout
    //                     set_updt_data(data)
    //                 }
    //                 let res_data = response.data.data[0]?.layout.length > 0 ? response.data.data[0].layout : []
    //                 // dispatch(updateLayoutInfo(res_data));
    //                 // setlayout(res_data)
    //                 // setlayout(response.data.data[0]?.layout.length > 0 ? response.data.data[0].layout : [])
    //                 setlayoutId(response.data.data[0]?.layout !== undefined ? response.data.data[0]._id : undefined)
    //                 var data = await rearrange_blocks(res_data)

    //                 if (data) {
    //                     setdata_loaded(true)
    //                     // await handleResize(data)
    //                 }




    //             }
    //         })
    //     } catch (error) {
    //         console.log(error, 'error')
    //     }
    // }

    useEffect(() => {
        if (layout?.length > 0) {
            const clonedLayoutInfo = [...layout]; // Shallow copy using spread operator
            dispatch(updateLayoutData(clonedLayoutInfo, db_data));
            // setlayout(clonedLayoutInfo);
        }
    }, [layout]);



    const create_layouts = (val) => {
        dispatch(createLayout(val));
    };

    const handleIconDragStart = (e, iconSrc, name) => {
        e.dataTransfer.setData('text/plain', iconSrc);
        set_icon_data(iconSrc)
        set_icon_name(name)
    };

    const handleDragOver = (e, item) => {
        e.preventDefault();
        setTargetBlock(item);
    };

    const handleDrop1 = async (e, item) => {
        // Get the div element by its class name
        let updatedLayout = [...layout]
        e.preventDefault();
        const itemIndex = layout.findIndex((layoutItem) => layoutItem.i === item.i);
        if (itemIndex !== -1) {
            const itemId = layout[itemIndex].i;
            let width = 0;
            let height = 0;
            const targetElement = await document.getElementById(String(itemId));
            const divElement = await document.getElementById(item.i);
            // Get the width of the div element
            const divWidth = divElement.offsetWidth;

            if (divElement) {
                width = divWidth - 10; // Get the width
                height = 320//divElement.offsetHeight; // Get the height
            }
            if (icon_data && targetBlock === item) {
                const layoutItem = updatedLayout[itemIndex];

                if (layoutItem.type !== 'text') {
                    if (layoutItem.imgSrc === undefined) {
                        if (icon_name === 'rectangle_card') {
                            updatedLayout[itemIndex] = {
                                ...layoutItem,
                                name: icon_name,
                                card_name: icon_name,
                                text: 'Labels',
                                count: '900',
                                h: 2,
                                minH: 2,
                                maxH: 2
                            };
                        }
                        else if (icon_name === 'table') {
                            updatedLayout[itemIndex] = {
                                ...layoutItem,
                                name: icon_name,
                                Table: true,
                                containerWidth: width,
                                containerHeight: height,
                            };
                        }
                        else {
                            updatedLayout[itemIndex] = {
                                ...layoutItem,
                                imgSrc: icon_data,
                                name: icon_name,
                                chart_name: icon_name,
                                containerWidth: width,
                                containerHeight: height,

                            };
                        }
                    } else {
                        var confirmation = await showAlert(layoutItem, icon_data, icon_name, width, height, updatedLayout, itemIndex);
                    }
                }
                else {
                    Swal.fire({
                        title: 'Alert!',
                        text: 'Not Allowed for Headers',
                        icon: 'info',
                        confirmButtonText: 'OK',
                    });
                }
            }
            var updated_chart_data = updatedLayout.find((data) => item.i === data.i)
            if (updated_chart_data !== undefined) {
                dispatch(updateChartData(updated_chart_data, layoutId))
            }
            dispatch(updateLayoutInfo(updatedLayout));
            onLayoutChange(updatedLayout)
            // dispatch(updateLayoutData(updatedLayout, db_data));

        }
        dispatch(updateLayoutInfo(updatedLayout));
        dispatch(updateLayoutData(updatedLayout, db_data));
    };

    // const updateChartData = (chart_data) => {
    //     try {
    //         urlSocket.post('report/updt-chart-data', {
    //             chart_data: chart_data,
    //             encrypted_db_url: dbInfo.encrypted_db_url,
    //             layout_id: layoutId || '',
    //             template_id: pageInfo._id,
    //             template_name: pageInfo.name,
    //             chart_name: chart_data.name,
    //             node_id: nodeInfo.pageNodeInfo.id
    //         }).then((response) => {
    //         })

    //     } catch (error) {
    //     }
    // }

    const onLayoutChange = async (layout) => {
        // setlayout(layout);
        dispatch(updateLayoutInfo(layout));

    };


    const rearrange = async (layout, oldItem, newItem, adjustedLayout, updatedLayout, from_rsz_func) => {
        adjustedLayout.map((adjusted_data, adj_indx) => {
            if (adjusted_data.type === 'text') {

            }
            else {
                return { adjusted_data }
            }
        })
        let filteredLayout_no_headers = adjustedLayout.filter(item => item.type !== 'text');
        let filteredLayout_with_headers = adjustedLayout.filter(item => item.type === 'text');
        var updatedLayout_mod
        var updatedLayout1
        if (filteredLayout_with_headers.length > 0) {        // height control of header in blocks
            adjustedLayout = filteredLayout_no_headers
            const rowYValues = [...new Set(adjustedLayout.map((item) => item.y))];
            const rows = {};
            adjustedLayout.forEach((item) => {
                if (!rows[item.y]) {
                    rows[item.y] = [];
                }
                rows[item.y].push(item);
            });

            for (const yValue of rowYValues) {
                const itemsInRow = rows[yValue];
                const totalWidthInRow = itemsInRow.reduce((total, item) => total + item.w, 0);
                let currentX = 0;
                const remainingWidth = 12 - totalWidthInRow;
                const additionalWidthPerItem = Math.floor(remainingWidth / itemsInRow.length);
                const remainder = remainingWidth % itemsInRow.length;

                itemsInRow.forEach((item, index) => {
                    item.w += additionalWidthPerItem;
                    if (index < remainder) {
                        item.w += 1;
                    }
                    item.x = currentX;
                    currentX += item.w;
                });

            }
            updatedLayout1 = Object.values(rows).flat();
            const itemsInSameRow = updatedLayout1.filter((item) => item.y === newItem.y);
            const newHeight = (12 / newItem.w) * newItem.h;
            var heightForOtherItems = Math.round(newHeight / itemsInSameRow.length);
            if (itemsInSameRow.length === 1 && newHeight > 5) {
                heightForOtherItems = 2
            }
            updatedLayout_mod = updatedLayout1.map((item) => {
                if (itemsInSameRow.some((rowItem) => rowItem.i === item.i)) {
                    return { ...item, h: heightForOtherItems };
                }
                return item;
            });
            const insertIndex = 1;
            updatedLayout_mod.splice(insertIndex, 0, ...filteredLayout_with_headers);
        }
        else {
            const rowYValues = [...new Set(adjustedLayout.map((item) => item.y))];
            const rows = {};
            adjustedLayout.forEach((item) => {
                if (!rows[item.y]) {
                    rows[item.y] = [];
                }
                rows[item.y].push(item);
            });
            for (const yValue of rowYValues) {
                const itemsInRow = rows[yValue];
                const totalWidthInRow = itemsInRow.reduce((total, item) => total + item.w, 0);
                let currentX = 0;
                const remainingWidth = 12 - totalWidthInRow;
                const additionalWidthPerItem = Math.floor(remainingWidth / itemsInRow.length);
                const remainder = remainingWidth % itemsInRow.length;

                itemsInRow.forEach((item, index) => {
                    item.w += additionalWidthPerItem;
                    if (index < remainder) {
                        item.w += 1;
                    }
                    item.x = currentX;
                    currentX += item.w;
                });
            }
            updatedLayout1 = Object.values(rows).flat();
            const itemsInSameRow = updatedLayout1.filter((item) => item.y === newItem.y);
            if (itemsInSameRow.length > 1) {
                const newHeight = (12 / newItem.w) * newItem.h;
                var heightForOtherItems = Math.round(newHeight / itemsInSameRow.length);
                if (itemsInSameRow.length === 1) {
                    heightForOtherItems = 2
                }
                updatedLayout_mod = updatedLayout1.map((item) => {
                    if (itemsInSameRow.some((rowItem) => rowItem.i === item.i)) {
                        return { ...item, h: heightForOtherItems };
                    }
                    return item;
                });
            }
            else {
                updatedLayout_mod = updatedLayout1
            }
        }
        setlayout(updatedLayout_mod);
        dispatch(updateLayoutInfo(updatedLayout_mod));

        onLayoutChange(updatedLayout_mod)

        if (isResizing && !standard_layout && from_rsz_func) {
            onResize(updatedLayout_mod, oldItem, newItem, false, updatedLayout1)
        }
        setIsResizing(true)
        let targetElement = ''
        let mod_data = []
        if (updt_data !== undefined) {
            await updt_data.map(async (ele, idx) => {
                await updatedLayout_mod.map(async (item, pos) => {
                    if (ele.i === item.i) {
                        if (ele.imgSrc !== undefined && ele.imgSrc !== null) {
                            updatedLayout_mod[pos]["imgSrc"] = ele.imgSrc
                            updatedLayout_mod[pos]["name"] = ele.name
                            const itemId = ele.i;
                            let width = 0
                            let height = 0
                            let style = ''
                            targetElement = await document.getElementById(itemId);
                            if (targetElement) {
                                style = targetElement.style;
                                setSidePanelOpen(false)
                                style = targetElement.style;
                                width = parseFloat(style.width)
                                height = parseFloat(style.height); // Get the height
                            }
                            updatedLayout_mod[pos]["containerWidth"] = width
                            updatedLayout_mod[pos]["containerHeight"] = height
                        }
                        mod_data = updatedLayout_mod
                    }
                    else {
                        mod_data = updatedLayout_mod
                    }
                })
            })
        }
        else {
            mod_data = updatedLayout_mod
        }
        if (mod_data.length === 0) {
            mod_data = updatedLayout_mod
        }
        set_updt_data(mod_data)
        setlayout(mod_data)
        dispatch(updateLayoutInfo(mod_data));

        onLayoutChange(mod_data)
        return mod_data
    };

    const blocks_resized = async (layout, oldItem, newItem) => {
        const itemIndex = layout.findIndex((layoutItem) => layoutItem.i === newItem.i);
        const itemId = layout[itemIndex].i;
        let width = 0
        let height = 0
        const targetElement = await document.getElementById(itemId);
        if (targetElement) {
            const style = targetElement.style;
            width = await parseFloat(style.width);
            height = await parseFloat(style.height);
        }
        if (updt_data !== undefined) {
            await updt_data.map(async (ele, idx) => {
                layout.map((item, pos) => {
                    if (ele.i === item.i) {
                        if (ele.imgSrc !== undefined && ele.imgSrc !== null) {
                            layout[pos]["imgSrc"] = ele.imgSrc
                            layout[pos]["name"] = ele.name
                            layout[pos]["chart_name"] = ele.chart_name
                            layout[pos]["containerWidth"] = width
                            layout[pos]["containerHeight"] = height
                            layout[pos]["data"] = ele?.data !== undefined ? ele.data : undefined
                            layout[pos]["chart_customize_clr"] = ele?.chart_customize_clr !== undefined ? ele.chart_customize_clr : undefined
                            layout[pos]["selected_cln_name"] = ele?.selected_cln_name !== undefined ? ele.selected_cln_name : undefined
                            layout[pos]["selected_primary_key"] = ele?.selected_primary_key !== undefined ? ele.selected_primary_key : undefined
                            layout[pos]["selected_primary_value"] = ele?.selected_primary_value !== undefined ? ele.selected_primary_value : undefined
                            layout[pos]["x_axis_key"] = ele?.x_axis_key !== undefined ? ele.x_axis_key : undefined
                            layout[pos]["y_axis_key"] = ele?.y_axis_key !== undefined ? ele.y_axis_key : undefined
                            layout[pos]["show_table"] = ele?.show_table !== undefined ? ele.show_table : false
                            layout[pos]["chart_height"] = ele?.chart_height !== undefined ? ele.chart_height : ''
                            layout[pos]["minH"] = ele?.minH !== undefined ? ele.minH : ''
                        }
                        else if (ele.name === 'rectangle_card') {
                            layout[pos]["name"] = ele.name
                            layout[pos]["containerWidth"] = width
                            layout[pos]["containerHeight"] = height
                            layout[pos]["text"] = ele?.text !== undefined ? ele.text : ''
                            layout[pos]["count"] = ele?.count !== undefined ? ele.count : ''
                            layout[pos]["minH"] = ele?.minH !== undefined ? 2 : 2
                            layout[pos]["maxH"] = ele?.maxH !== undefined ? 2 : 2
                        }
                    }
                })
            })
        }
        return layout
    }

    const height_match = async (layout, oldItem, newItem) => {
        const itemsInSameRow = layout.filter((item) => item.y === newItem.y);
        const newHeight = (12 / newItem.w) * newItem.h;
        const heightForOtherItems = newItem.h;
        const updatedLayout = layout.map((item) => {
            if (itemsInSameRow.some((rowItem) => rowItem.i === item.i)) {
                return { ...item, h: heightForOtherItems };
            }
            return item;
        });
        setIsResizing(true);
        return updatedLayout
    }

    const style_func = async (itemId, updatedLayout1, pos) => {
        var width = 0;
        var height = 0;
        const targetElement = document.getElementById(itemId);
        await new Promise(resolve => setTimeout(resolve, 0));
        if (targetElement) {
            const style = await window.getComputedStyle(targetElement);
            width = await parseFloat(style.width);
            height = await parseFloat(style.height);
            updatedLayout1[pos]["containerWidth"] = width;
            updatedLayout1[pos]["containerHeight"] = height;
        }
        return updatedLayout1
    }

    const updating_func = async (updt_data, updatedLayout1) => {
        const updatedData = await Promise.all(updt_data.map(async (ele, idx) => {
            const data_modified = Promise.all(await updatedLayout1.map(async (item, pos) => {
                if (ele.i === item.i) {
                    if (ele.imgSrc !== undefined && ele.imgSrc !== null) {
                        updatedLayout1[pos]["imgSrc"] = ele.imgSrc;
                        updatedLayout1[pos]["name"] = ele.name;
                        updatedLayout1[pos]["chart_name"] = ele.chart_name;
                        const itemId = ele.i;
                        var awaited_data = await style_func(itemId, updatedLayout1, pos)
                        updatedLayout1 = awaited_data
                        setlayout(updatedLayout1)
                        dispatch(updateLayoutInfo(updatedLayout1));

                    }
                } else {
                    return item;
                }
            }));
            return updatedLayout1;
        }));
        return updatedLayout1;
    };

    const row_width_match_func = async (layout, oldItem, newItem, updated_data, updatedLayout123) => {
        let updatedLayout1 = []
        const rowYValues = [...new Set(updatedLayout123.map((item) => item.y))];
        await rowYValues.forEach(async (yValue) => {
            const itemsInRow = updatedLayout123.filter((item) => item.y === yValue);
            const totalWidthInRow = itemsInRow.reduce((total, item) => total + item.w, 0);
            if (totalWidthInRow !== 12) {
                const array = itemsInRow
                const nameToFind = newItem.i;
                const index_arr = array.findIndex(item => item.i === nameToFind);
                if (index_arr !== -1) {
                    const currentIndex = index_arr;
                    const nextIndex = currentIndex + 1;
                    var targetRow_new = array.filter((item) => item.y === newItem.y);
                    const totalWidth_new = targetRow_new.reduce((total, item) => total + item.w, 0);
                    const availableSpace_new = 12 - totalWidth_new;
                    if (nextIndex < array.length) {
                        itemsInRow[nextIndex].w = added_width
                        let cumulativeX = 0;
                        for (let i = 0; i < itemsInRow.length; i++) {
                            itemsInRow[i].x = cumulativeX;
                            cumulativeX += itemsInRow[i].w;
                        }
                    }
                    else {
                        var item_data = itemsInRow[nextIndex - 1]
                        var added_width = item_data.w + availableSpace_new
                        item_data.w = added_width
                        itemsInRow.forEach((item, index) => {
                            if (array[index].i === itemsInRow[nextIndex - 1].i) {
                            }
                        })
                    }
                    itemsInRow.forEach((item, index) => {
                        const updatedItem = { ...item, w: item.w };
                        updatedLayout1.push(updatedItem);
                    })
                }
                else {
                    var targetRow_new = itemsInRow;
                    const availableSpace_new = 12 - totalWidthInRow;
                    var added_width = itemsInRow[0].w + availableSpace_new
                    itemsInRow[0].w = added_width
                    let cumulativeX = 0;
                    for (let i = 0; i < itemsInRow.length; i++) {
                        itemsInRow[i].x = cumulativeX;
                        cumulativeX += itemsInRow[i].w;
                    }
                    var width = 0;
                    var height = 0;
                    const targetElement = await document.getElementById(itemsInRow[0].i);
                    if (targetElement) {
                        const style = targetElement.style;
                        width = await parseFloat(style.width);
                        height = await parseFloat(style.height);
                        itemsInRow[0]["containerWidth"] = width;
                        itemsInRow[0]["containerHeight"] = height;
                    }
                    itemsInRow.forEach((item, index) => {
                        const updatedItem = { ...item, w: item.w };
                        updatedLayout1.push(updatedItem);
                    })
                }
            } else {
                updatedLayout1.push(...itemsInRow);
            }
        });
        return updatedLayout1
    }

    const onResize = async (layout, oldItem, newItem, from_rsz_func_retrn, final) => {
        setSidePanelOpen(false)
        if (standard_layout) {
            let cloned_lay = [...LayoutArray.layoutInfo]
            const itemIndex = cloned_lay.findIndex((layoutItem) => layoutItem.i === newItem.i);
            const itemId = cloned_lay[itemIndex].i;
            let width = 0
            let height = 0
            const targetElement = await document.getElementById(itemId);
            if (targetElement) {
                const style = targetElement.style;
                width = await parseFloat(style.width);         // Get the width
                height = await parseFloat(style.height); // Get the height
            }
            if (LayoutArray.layoutInfo !== undefined) {
                await LayoutArray.layoutInfo.map(async (ele, idx) => {
                    layout.map((item, pos) => {
                        if (ele.i === item.i) {
                            const clonedItem = { ...ele };

                            if (ele.imgSrc !== undefined && ele.imgSrc !== null) {
                                clonedItem.imgSrc = ele.imgSrc
                                clonedItem.name = ele.name
                                clonedItem.chart_name = ele.chart_name
                                clonedItem.containerWidth = width
                                clonedItem.containerHeight = height
                                clonedItem.data = ele?.data !== undefined ? ele.data : undefined
                                clonedItem.chart_customize_clr = ele?.chart_customize_clr !== undefined ? ele.chart_customize_clr : undefined
                                clonedItem.selected_cln_name = ele?.selected_cln_name !== undefined ? ele.selected_cln_name : undefined
                                clonedItem.selected_primary_key = ele?.selected_primary_key !== undefined ? ele.selected_primary_key : undefined
                                clonedItem.selected_primary_value = ele?.selected_primary_value !== undefined ? ele.selected_primary_value : undefined
                                clonedItem.x_axis_key = ele?.x_axis_key !== undefined ? ele.x_axis_key : undefined
                                clonedItem.y_axis_key = ele?.y_axis_key !== undefined ? ele.y_axis_key : undefined
                                clonedItem.show_Line = ele?.show_Line !== undefined ? ele.show_Line : undefined
                                clonedItem.show_Grid = ele?.show_Grid !== undefined ? ele.show_Grid : undefined
                                clonedItem.show_bar_values = ele?.show_bar_values !== undefined ? ele.show_bar_values : undefined
                                clonedItem.label_arr_data = ele?.label_arr_data !== undefined ? ele.label_arr_data : []
                                clonedItem.y_axis_label = ele?.y_axis_label !== undefined ? ele.y_axis_label : 'Ylabel'
                                clonedItem.curved_line_chrt = ele?.curved_line_chrt !== undefined ? ele.curved_line_chrt : false
                                clonedItem.chart_customize_clr_arr = ele?.chart_customize_clr_arr !== undefined ? ele.chart_customize_clr_arr : undefined
                                clonedItem.chart_height = ele?.chart_height !== undefined ? ele.chart_height : undefined
                                clonedItem.minH = ele?.minH !== undefined ? ele.minH : ''
                                clonedItem.show_table = ele?.show_table !== undefined ? ele.show_table : undefined
                                clonedItem.yAxis_arr = ele?.yAxis_arr !== undefined ? ele.yAxis_arr : undefined
                                clonedItem.add_transition_data = ele?.add_transition_data !== undefined ? ele.add_transition_data : undefined
                                clonedItem.num_add_axes = ele?.num_add_axes !== undefined ? ele.num_add_axes : undefined
                                clonedItem.combined_arr = ele?.combined_arr !== undefined ? ele.combined_arr : undefined
                                clonedItem.merged_arr = ele?.merged_arr !== undefined ? ele.merged_arr : undefined
                                clonedItem.X_axis_value = ele?.X_axis_value !== undefined ? ele.X_axis_value : undefined
                                clonedItem.x_axis_label = ele?.x_axis_label !== undefined ? ele.x_axis_label : undefined
                                clonedItem.mouseovered_type = ele?.mouseovered_type !== undefined ? ele.mouseovered_type : undefined
                                clonedItem.mouseovered = ele?.mouseovered !== undefined ? ele.mouseovered : undefined
                                clonedItem.show_Square = ele?.show_Square !== undefined ? ele.show_Square : undefined
                                cloned_lay[idx] = clonedItem
                            }
                            else if (ele.name === 'rectangle_card') {
                                clonedItem.name = ele.name
                                clonedItem.text = ele?.text !== undefined ? ele.text : 'lab'
                                clonedItem.count = ele?.count !== undefined ? ele.count : '800'
                                clonedItem.h = ele?.h !== undefined ? ele.h : 2
                                clonedItem.minH = ele?.minH !== undefined ? ele.minH : 2
                                clonedItem.maxH = ele?.maxH !== undefined ? ele.maxH : 2
                                cloned_lay[idx] = clonedItem
                            }
                            else if (ele.name === 'table') {
                                clonedItem.name = ele?.name !== undefined ? ele.name : ''
                                clonedItem.containerWidth = width
                                clonedItem.containerHeight = height
                                clonedItem.selected_cln_name = ele?.selected_cln_name !== undefined ? ele.selected_cln_name : undefined
                                clonedItem.selected_primary_key = ele?.selected_primary_key !== undefined ? ele.selected_primary_key : undefined
                                clonedItem.selected_primary_value = ele?.selected_primary_value !== undefined ? ele.selected_primary_value : undefined
                                clonedItem.x_axis_key = ele?.x_axis_key !== undefined ? ele.x_axis_key : undefined
                                clonedItem.y_axis_key = ele?.y_axis_key !== undefined ? ele.y_axis_key : undefined
                                clonedItem.data = ele?.data !== undefined ? ele.data : undefined
                                clonedItem.label_arr_data = ele?.label_arr_data !== undefined ? ele.label_arr_data : []
                                clonedItem.yAxis_arr = ele?.yAxis_arr !== undefined ? ele.yAxis_arr : undefined
                                clonedItem.add_transition_data = ele?.add_transition_data !== undefined ? ele.add_transition_data : undefined
                                clonedItem.num_add_axes = ele?.num_add_axes !== undefined ? ele.num_add_axes : undefined
                                clonedItem.combined_arr = ele?.combined_arr !== undefined ? ele.combined_arr : undefined
                                clonedItem.merged_arr = ele?.merged_arr !== undefined ? ele.merged_arr : undefined
                                clonedItem.X_axis_value = ele?.X_axis_value !== undefined ? ele.X_axis_value : undefined
                                clonedItem.x_axis_label = ele?.x_axis_label !== undefined ? ele.x_axis_label : undefined
                                cloned_lay[idx] = clonedItem
                            }
                            else {
                                clonedItem.name = ele?.name !== undefined ? ele.name : ''
                                clonedItem.containerWidth = width
                                clonedItem.containerHeight = height
                                cloned_lay[idx] = clonedItem
                            }
                            cloned_lay[idx] = clonedItem
                        }
                        else {
                            return item
                        }
                    })
                })
            }



            let updatedLayout1 = [...layout];
            if (updt_data !== undefined) {
                for (const ele of cloned_lay) {
                    const modifiedItems = await Promise.all(updatedLayout1.map(async (item) => {
                        if (ele.i === item.i) {
                            if (ele.imgSrc !== undefined && ele.imgSrc !== null) {
                                const itemId = ele.i;
                                const targetElement = await document.getElementById(itemId);
                                if (targetElement) {
                                    const style = await targetElement.style;
                                    const width = parseFloat(style.width);
                                    const height = parseFloat(style.height);
                                    return {
                                        ...ele,
                                        w: item.w,
                                        h: item.h,
                                        containerWidth: width,
                                        containerHeight: height,
                                        chart_height: (ele.show_table !== undefined && ele.show_table !== false) ? ele.containerHeight : ele.chart_height,
                                        data: ele?.data !== undefined ? ele.data : undefined,
                                        imgSrc: ele.imgSrc,
                                        name: ele.name,
                                        chart_name: ele.chart_name,
                                    };
                                }
                            } else {
                                const targetElement = await document.getElementById(ele.i);
                                const style = await targetElement.style;
                                const width = parseFloat(style.width);
                                const height = parseFloat(style.height);
                                return {
                                    ...ele,
                                    Table: ele.Table,
                                    name: ele.name,
                                    w: item.w,
                                    h: item.h,
                                    containerWidth: width,
                                    containerHeight: height,
                                    chart_height: (ele.show_table !== undefined && ele.show_table !== false) ? ele.containerHeight : ele.chart_height,
                                    data: ele?.data !== undefined ? ele.data : undefined,
                                };
                            }
                        }
                        return item; // return original item if condition doesn't match
                    }));
                    updatedLayout1 = modifiedItems;
                }
                set_updt_data(updatedLayout1)
                dispatch(updateLayoutInfo(updatedLayout1));
                // dispatch(updateLayoutData(updatedLayout1, db_data));

            }

        }
        else {
            temp_layout.map((data, idx) => {
                layout.map((ele, pos) => {
                    if (data.i == ele.i) {
                        layout[pos]["type"] = temp_layout[idx]["type"]
                        layout[pos]["value"] = temp_layout[idx]["value"]
                        layout[pos]["fontsize"] = temp_layout[idx]["fontsize"]
                        layout[pos]["h"] = temp_layout[idx]["h"]
                    }
                })
            })
            var layout = await blocks_resized(layout, oldItem, newItem)
            let updatedLayout1 = [];
            var updatedLayout = await height_match(layout, oldItem, newItem)
            if (updt_data !== undefined) {
                var updated_data = await updating_func(updt_data, updatedLayout)
                setIsResizing(false)
                let from_rsz_func = ((from_rsz_func_retrn === undefined || from_rsz_func_retrn === null) ? true : from_rsz_func_retrn)
                var row_width_match = await row_width_match_func(layout, oldItem, newItem, updt_data, updatedLayout)
                set_updt_data(row_width_match)
                setlayout(row_width_match)
                // dispatch(updateLayoutInfo(row_width_match));

                onLayoutChange(row_width_match)
                return
            }
            row_width_match = await row_width_match_func(layout, oldItem, newItem, updt_data, updatedLayout)
            set_updt_data(updatedLayout !== undefined ? updatedLayout : updt_data)
            setlayout(updatedLayout !== undefined ? updatedLayout : updt_data)
            // dispatch(updateLayoutInfo(updatedLayout !== undefined ? updatedLayout : updt_data));

            await onLayoutChange(updatedLayout !== undefined ? updatedLayout : updt_data)
            // dispatch(updateLayoutData(updatedLayout !== undefined ? updatedLayout : updt_data, db_data));
        }
    };


    const showAlert = async (layoutItem, icon_data, icon_name, width, height, updatedLayout, index) => {
        await Swal.fire({
            title: 'Are you Sure?',
            text: 'Do you want to modify and Remove its Config data ?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (icon_name === 'rectangle_card') {
                    updatedLayout[index] = {
                        ...layoutItem,
                        name: icon_name,
                        card_name: icon_name,
                        text: 'Labels',
                        count: '900',
                        h: 2,
                        minH: 2,
                        maxH: 2
                    }
                }
                else {
                    updatedLayout[index] = {
                        ...layoutItem,
                        imgSrc: icon_data,
                        name: icon_name,
                        chart_name: icon_name,
                        containerWidth: width,
                        containerHeight: height,
                        data: undefined,
                        chart_customize_clr: undefined,
                        chart_customize_clr_arr: undefined,
                        selected_primary_key: undefined,
                        selected_cln_name: {},
                        selected_primary_key: {},
                        selected_primary_value: {},
                        x_axis_key: '',
                        y_axis_key: '',
                    };
                }
                dispatch(updateLayoutInfo(updatedLayout));
                dispatch(updateLayoutData(updatedLayout, db_data));
                Swal.fire('Success', 'Chart modified successfully!', 'success');
            } else if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelled', 'Chart modification cancelled.', 'error');
            }
        });
    };

    const text_block = (indx) => {
        dispatch(textBlock(indx));
    };

    const del_block = async (e, item1, indx) => {
        let arr = [...mod_layout]
        var targetRow_new = arr.filter((item) => item.y === item1.y);
        arr.splice(indx, 1);
        targetRow_new = arr.filter((item) => item.y === item1.y);

        var mod_lay

        if (targetRow_new.length > 0) {
            mod_lay = await Promise.all(arr.map(async (item, idx) => {
                const arr_item = Object.assign({}, item); // Create a new object by copying properties from the original
                if (arr_item.i === targetRow_new[0].i) {
                    arr_item.w = targetRow_new[0].w; // Modify the cloned item's 'w' property
                    const targetElement = await document.getElementById(arr_item.i);
                    var width = 0;
                    if (targetElement) {
                        setTimeout(async () => {
                            const style = targetElement.style;
                            width = await parseFloat(style.width);
                            const updatedItem = Object.assign({}, arr_item, { containerWidth: width });
                            dispatch(updateLayoutInfo(arr));
                            dispatch(updateLayoutData(arr, db_data));
                        }, 100);
                    }
                }
                return arr_item; // Return the modified or unmodified cloned item
            }));
        }
        dispatch(updateLayoutInfo(arr !== undefined ? arr : []));
        dispatch(updateLayoutData(arr !== undefined ? arr : [], db_data));
    }



    const updt_txt_layout = async (e, item, indx) => {
        var updt_layout = LayoutArray.layoutInfo.map((layout, index) => {
            if (index === indx) {
                return { ...layout, value: e.target.value };
            }
            return layout;
        });
        dispatch(updateLayoutInfo(updt_layout));
        dispatch(updateLayoutData(updt_layout, db_data));

    };

    const onlayoutClick = (item, index1, layout) => {
        if (item.name !== undefined) {
            setSidePanelOpen(!isSidePanelOpen)
            setSidepanel_data(item)
            sessionStorage.setItem('blockdata', JSON.stringify(item));
            sessionStorage.setItem('blockIdx', JSON.stringify(index1));
        }
    }

    const updateLayout = async (layout, rerender) => {
        // dispatch(updateLayoutData(layout, db_data));
        if (rerender) {
            // dispatch(updateLayoutData(layout, db_data));
            onLayoutChange(layout)
        }
    }

    async function show_table_function(e, item, indx, lay) {
       
        let temp_layout = mod_layout.map(obj => ({ ...obj }));
        let show_table = mod_layout[indx]["show_table"]
        if (show_table === false || show_table === undefined) {
            temp_layout[indx] = { ...temp_layout[indx] }; // Create a shallow copy of the object at temp_layout[indx]
            temp_layout[indx]["h"] = mod_layout[indx]["h"] + 2;
            temp_layout[indx]["chart_height"] = mod_layout[indx]["containerHeight"] + 200;
            temp_layout[indx]["minH"] = temp_layout[indx]["h"]
            temp_layout[indx]["show_table"] = true;
            temp_layout[indx]["containerHeight"] = mod_layout[indx]["containerHeight"] + 200;
        }

        if (show_table === true) {
            temp_layout[indx] = { ...temp_layout[indx] }; // Create a shallow copy of the object at temp_layout[indx]
            temp_layout[indx]["containerHeight"] = mod_layout[indx]["containerHeight"] - 200;
            temp_layout[indx]["minH"] = '';
            temp_layout[indx]["h"] = (mod_layout[indx]["h"]) - 2;
            temp_layout[indx]["show_table"] = undefined;
            temp_layout[indx]["chart_height"] = '';
        }

        var rersized = await table_resized(mod_layout, item, temp_layout[indx], temp_layout)
     rearrange_blocks(rersized)
        dispatch(updateLayoutInfo(rersized !== undefined ? rersized : updt_data));
        // dispatch(updateLayoutData(rersized !== undefined ? rersized : updt_data, db_data));
    }

    const table_resized = async (layout, oldItem, newItem, tempArr) => {
        const itemIndex = layout.findIndex((layoutItem) => layoutItem.i === newItem.i);
        const itemId = layout[itemIndex].i;
        let width = 0;
        let height = 0;
        const targetElement = await document.getElementById(itemId);
        if (targetElement) {
            const style = targetElement.style;
            width = parseFloat(style.width);
            height = parseFloat(style.height);
        }
        if (tempArr !== undefined) {
            const updatedLayout = [...layout];
            await Promise.all(tempArr.map(async (ele, idx) => {
                layout.forEach((item, pos) => {
                    if (ele.i === item.i) {
                        const updatedItem = { ...item };
                        if (ele.imgSrc !== undefined && ele.imgSrc !== null) {
                            updatedItem.imgSrc = ele.imgSrc;
                            updatedItem.name = ele.name;
                            updatedItem.chart_name = ele.chart_name;
                            updatedItem.containerWidth = ele.containerWidth;
                            updatedItem.containerHeight = ele.containerHeight;
                            updatedItem.data = ele?.data !== undefined ? ele.data : undefined;
                            updatedItem.chart_customize_clr = ele?.chart_customize_clr !== undefined ? ele.chart_customize_clr : undefined;
                            updatedItem.selected_cln_name = ele?.selected_cln_name !== undefined ? ele.selected_cln_name : undefined;
                            updatedItem.selected_primary_key = ele?.selected_primary_key !== undefined ? ele.selected_primary_key : undefined;
                            updatedItem.selected_primary_value = ele?.selected_primary_value !== undefined ? ele.selected_primary_value : undefined;
                            updatedItem.x_axis_key = ele?.x_axis_key !== undefined ? ele.x_axis_key : undefined;
                            updatedItem.y_axis_key = ele?.y_axis_key !== undefined ? ele.y_axis_key : undefined;
                            updatedItem.show_table = ele?.show_table !== undefined ? ele.show_table : false;
                            updatedItem.chart_height = ele?.chart_height !== undefined ? ele.chart_height : '';
                            updatedItem.minH = ele?.minH !== undefined ? ele.minH : '';
                            updatedItem.h = ele?.h !== undefined ? ele.h : '';
                        } else if (ele.name === 'rectangle_card') {
                            updatedItem.name = ele.name;
                            updatedItem.containerWidth = width;
                            updatedItem.containerHeight = height;
                            updatedItem.text = ele?.text !== undefined ? ele.text : '';
                            updatedItem.count = ele?.count !== undefined ? ele.count : '';
                            updatedItem.minH = ele?.minH !== undefined ? 2 : 2;
                            updatedItem.maxH = ele?.maxH !== undefined ? 2 : 2;
                        }
                        updatedLayout[pos] = updatedItem;
                    }
                });
            }));
            return updatedLayout;
        }
        return layout;
    };


    const handleToggleFullScreen = async (item, obj, lay, indx) => {
        const chartElement = document.getElementById(`${item}`);
        if (chartElement) {
            if (!isFullScreen) {
                const lay1 = { ...layout[indx] };
                lay1.temp_containerWidth = fullscreenSize.width;
                lay1.temp_containerHeight = fullscreenSize.height + 150;
                lay1.fullScreen_enabled = true;
                console.log(lay1, 'lay1')
                var updateLayout = [...layout]
                updateLayout[indx] = lay1
                console.log(updateLayout, 'updateLayout')
                dispatch(updateLayoutInfo(updateLayout))
                if (chartElement.requestFullscreen) {
                    chartElement.requestFullscreen();
                } else if (chartElement.mozRequestFullScreen) {
                    chartElement.mozRequestFullScreen();
                } else if (chartElement.webkitRequestFullscreen) {
                    chartElement.webkitRequestFullscreen();
                } else if (chartElement.msRequestFullscreen) {
                    chartElement.msRequestFullscreen();
                }
                const rightArrowIcon = createArrowIcon("bx bx-right-arrow-alt", "right", () => handleNextChart(item, indx, lay1), !hasNextChart(item));
                chartElement.appendChild(rightArrowIcon);
                const leftArrowIcon = createArrowIcon("bx bx-left-arrow-alt", "left", () => handlePreviousChart(item, indx, lay1), !hasPreviousChart(item));
                chartElement.appendChild(leftArrowIcon);
                setIsFullScreen(true);
                setdata_loaded(true)
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                const rightArrowElement = chartElement.querySelector('.bx-right-arrow-alt');
                const leftArrowElement = chartElement.querySelector('.bx-left-arrow-alt');
                if (rightArrowElement) {
                    rightArrowElement.remove();
                }
                if (leftArrowElement) {
                    leftArrowElement.remove();
                }
                setIsFullScreen(false);
            }
        } else {
        }
    };

    const createArrowIcon = (className, direction, onClick, isDisabled = false) => {
        const arrowIcon = document.createElement("i");
        arrowIcon.className = className;
        arrowIcon.style.cssText = `
            cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
            font-size: 40px;
            font-weight: bold;
            position: absolute;
            top: 50%;
            ${direction}: 20px;
            z-index: 2;
            color: ${isDisabled ? '#999' : '#6666B2'};
            transform: translateY(-50%);
        `;
        if (!isDisabled) {
            arrowIcon.addEventListener("click", onClick);
        }
        return arrowIcon;
    };

    const hasNextChart = (item) => {
        const itemIndex = mod_layout.findIndex((layoutItem) => layoutItem.i === item);
        return itemIndex < mod_layout.length - 1;
    };

    const hasPreviousChart = (item) => {
        const itemIndex = mod_layout.findIndex((layoutItem) => layoutItem.i === item);
        return itemIndex > 0;
    };


    const handleNextChart = async (item, idx, lay) => {
        setIsFullScreen(true);
        let nextIndex = idx + 1;

        while (nextIndex < mod_layout.length) {
            // const nextChart = LayoutArray.layoutInfo[nextIndex];
            const nextChart = mod_layout[nextIndex];

            if (nextChart && nextChart.chart_name) {
                await handleToggleFullScreen(nextChart.i, '', lay, nextIndex);
                return; // Exit the function after navigating
            } else {
                nextIndex++;
            }
        }
        console.log("Next chart not available");
    };


    const handlePreviousChart = async (item, indx, lay) => {
        let previousIndex = indx - 1;

        while (previousIndex >= 0) {
            const previousChart =mod_layout[previousIndex];

            if (previousChart && previousChart.chart_name) {
                await handleToggleFullScreen(previousChart.i, '', lay, previousIndex);
                return;
            } else {
                previousIndex--;
            }
        }
        console.log("Previous chart not available");
    };


    const getStyle = (item, val) => {
        if (val === '1') {
            const style = { fontSize: `${item.label_fontsize ? item.label_fontsize : 14}px`, color: `${item.label_fontColor ? item.label_fontColor : 'black'}`, };
            if (item.label_bold || item.label_italic || item.label_underline) {
                if (item.label_bold) style.fontWeight = 'bold';
                if (item.label_italic) style.fontStyle = 'italic';
                if (item.label_underline) style.textDecoration = 'underline';
                return style;
            }
            else {
                return style;
            }
        }
        else {
            const style = { fontSize: `${item.value_fontsize ? item.value_fontsize : 25}px`, color: `${item.value_fontColor ? item.value_fontColor : 'red'}`, };
            if (item.value_bold || item.value_italic || item.value_underline) {
                if (item.value_bold) style.fontWeight = 'bold';
                if (item.value_italic) style.fontStyle = 'italic';
                if (item.value_underline) style.textDecoration = 'underline';
                return style;
            }
            else {
                return style;
            }
        }
    };

    const exit_screen = async (item, indx) => {
        if (document.exitFullscreen) {
            document.exitFullscreen().then(() => {
                exit_screen()
            }).catch((err) => {
                console.error('Error attempting to exit fullscreen:', err);
            });
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }

        setIsFullScreen(false)
        const chartElement = document.getElementById(`${item}`);

        if (indx !== undefined) {
            const updatedLayout = [...layout];
            updatedLayout.map((data, FullScr_index) => {
                let leftArrowElement = document.querySelector('.bx-left-arrow-alt');
                let rightArrowElement = document.querySelector('.bx-right-arrow-alt');
                if (leftArrowElement) {
                    leftArrowElement.remove();
                    rightArrowElement.remove()
                } else {
                }
                setIsFullScreen(!isFullScreen)
                const elementInFullscreen = getFullscreenElement();
                if (elementInFullscreen) {
                } else {
                    console.log('No element in fullscreen.');
                }

                updatedLayout[FullScr_index] = {
                    ...updatedLayout[FullScr_index],
                    fullScreen_enabled: false
                };

                const parentElement = document.getElementById(data.i);
                if (parentElement) {
                    const leftArrowElements = parentElement.querySelectorAll('.bx-left-arrow-alt');
                    const rightArrowElements = parentElement.querySelectorAll('.bx-right-arrow-alt');

                    leftArrowElements.forEach((element) => {
                        element.remove();
                    });

                    rightArrowElements.forEach((element) => {
                        element.remove();
                    });
                } else {
                    console.error(`Parent element with ID '${parentId}' not found`);
                }

            })
            dispatch(updateLayoutInfo(updatedLayout))
        }
        return indx
    }

    function getFullscreenElement() {
        const fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement;
        return fullscreenElement;
    }

    const convrt_chrt = (name, items, indx) => {
        var clone_lay = [...mod_layout]
        var updating_layObj = { ...clone_lay[indx] }
        if (name === 'Stack') {
            updating_layObj.imgSrc = stackChart
            updating_layObj.name = 'stack_bar'
            updating_layObj.chart_name = 'stack_bar'
            updating_layObj.data = items.data
            updating_layObj.num_add_axes = items.num_add_axes
            updating_layObj.x_axis_label = items.label_arr_data?.[0]
            updating_layObj.label_arr_data = items.label_arr_data

        }
        else if (name === 'area_chart') {
            updating_layObj.imgSrc = areaChart
            updating_layObj.name = 'area_chart'
            updating_layObj.chart_name = 'area_chart'
            updating_layObj.data = items.data
            updating_layObj.num_add_axes = items.num_add_axes
            updating_layObj.x_axis_label = items.label_arr_data?.[0]
            updating_layObj.label_arr_data = items.label_arr_data
        }
        else if (name === 'line_chart') {
            updating_layObj.imgSrc = lineChart
            updating_layObj.name = 'line_chart'
            updating_layObj.chart_name = 'line_chart'
            updating_layObj.data = items.data
            updating_layObj.num_add_axes = items.num_add_axes
            updating_layObj.x_axis_label = items.label_arr_data?.[0]
            updating_layObj.label_arr_data = items.label_arr_data
        }
        else if (name === 'hor_barcharts') {
            updating_layObj.imgSrc = hor_barChart
            updating_layObj.name = 'hor_barcharts'
            updating_layObj.chart_name = 'hor_barcharts'
            updating_layObj.data = items.data
        }
        else if (name === 'hor_stack') {
            updating_layObj.imgSrc = hor_stackChart
            updating_layObj.name = 'hor_stack'
            updating_layObj.chart_name = 'hor_stack'
            updating_layObj.data = items.data
            updating_layObj.num_add_axes = items.num_add_axes
            updating_layObj.x_axis_label = items.label_arr_data?.[0]
        }
        else if (name === 'table') {
            updating_layObj.imgSrc = hor_stackChart
            updating_layObj.name = 'table'
            updating_layObj.chart_name = 'table'
            updating_layObj.data = items.data
            updating_layObj.label_arr_data = items.label_arr_data
            if (!Object.isExtensible(updating_layObj)) {
                updating_layObj = { ...updating_layObj };
                updating_layObj["label_arr_data"][0] = items?.x_axis_label;
            }
            updating_layObj.Table = true
        }
        else if (name === 'bar_charts') {
            updating_layObj.imgSrc = barChart
            updating_layObj.name = 'bar_charts'
            updating_layObj.chart_name = 'bar_charts'
            updating_layObj.data = items.data
        }
        else if (name === 'Vertical_linechart') {
            updating_layObj.imgSrc = hor_barChart
            updating_layObj.name = 'Vertical_linechart'
            updating_layObj.chart_name = 'Vertical_linechart'
            updating_layObj.data = items.data
            updating_layObj.num_add_axes = items.num_add_axes
            updating_layObj.x_axis_label = items.label_arr_data?.[0]
            updating_layObj.label_arr_data = items.label_arr_data
        }
        clone_lay[indx] = updating_layObj;
        setmod_layout(clone_lay)
        // dispatch(updateLayoutInfo(clone_lay))
        // dispatch(updateLayoutData(clone_lay))


    }

    const reseted_data = async () => {
        try {
            dispatch(updateLayoutInfo([]));
            setlayout([])
            // dispatch(updateLayoutData([], db_data));
        } catch (error) {
        }
    }

    const preview_enabl = async () => {
        setprvpage(!prvpage)
        history.push("/report_page")

    }


    const rearrange_blocks = async (map_data) => {
        let data = [...map_data]
        const sortedArray = data.sort((a, b) => {
            return a.y - b.y
        });

        // setlayout(sortedArray)
        setmod_layout(sortedArray)





        return sortedArray


    }
    return (
        <React.Fragment>
            <div style={{}}>
                {loading && <LoadingOverlay />}
            </div>


            {data_loaded && <div className="page-content" >
                <Container fluid style={{ paddingTop: "16px", paddingRight: "7px" }}>
                    <Row style={{ width: "100%" }} >

                        {!prvpage && <Col md={4} lg={2}>
                            <Row>
                                <Col md={5} lg={4} style={{ borderRight: "#e3e6e8 1px solid" }} className="pe-0">
                                    <div className="p-2" style={{ backgroundColor: 'white', height: '100vh', position: 'fixed' }}>
                                        <div className="mt-0" style={{
                                            display: "flex",
                                            justifyContent: 'space-evenly',
                                            flexWrap: "wrap",
                                            padding: "1rem",
                                            rowGap: "1rem",
                                            columnGap: "1rem",
                                        }}>
                                            <ul className="metismenu list-unstyled" id="side-menu">
                                                <li className="h-100 p-2 px-0" >
                                                    <div className="custom-grid" style={{ textAlign: 'center', color: select_menu === '1' ? '#556ee6' : '', padding: '10px', cursor: 'pointer' }}
                                                        onClick={() => { setgrid(!grid); setcharts(false); set_select_menu('1') }}>
                                                        <span className="bx bx-grid">
                                                            <div style={{ fontSize: '13px', fontFamily: "poppins, sans-serif" }}>Grid</div>
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="custom-grid" style={{ textAlign: 'center', color: select_menu === '2' ? '#556ee6' : '', padding: '10px', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setText(!Text); setgrid(false);
                                                            set_select_menu('2')
                                                        }}>
                                                        <span className="bx bx-text">
                                                            <div style={{ fontSize: '13px', fontFamily: "poppins, sans-serif" }}>Text</div>
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="custom-grid" style={{ textAlign: 'center', color: select_menu === '3' ? '#556ee6' : '', padding: '10px', cursor: 'pointer' }}
                                                        onClick={() => { setcharts(!charts); setgrid(false); set_select_menu('3') }}>
                                                        <span className="bx bx-bar-chart-square">
                                                            <i className="waves-effect">
                                                            </i>
                                                            <div style={{ fontSize: '13px', fontFamily: "poppins, sans-serif" }}>Charts</div>
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="custom-grid" style={{ textAlign: 'center', color: select_menu === '4' ? '#556ee6' : '', padding: '10px', cursor: 'pointer' }}
                                                        onClick={() => { setcharts(!charts); setgrid(!false); set_select_menu('4') }}>
                                                        <span className="bx bx-rectangle">
                                                            <i className="waves-effect">
                                                            </i>
                                                            <div style={{ fontSize: '13px', fontFamily: "poppins, sans-serif" }}>Cards</div>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={5} lg={6} className="px-0" style={{}} >
                                    <div style={{
                                        backgroundColor: 'white', height: '100vh', position: 'fixed',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '10%'
                                    }}  >
                                        {
                                            select_menu === '1' ?
                                                <div className="p-2" style={{}}>
                                                    <div id="" className="mt-2" style={{
                                                        display: "flex",
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        paddingTop: "35px",
                                                        rowGap: "2rem",
                                                        columnGap: "1rem",
                                                        overflow: 'none',
                                                        overflowY: 'none',
                                                        overflowX: 'none'
                                                    }}>

                                                        <div>
                                                            <div style={{ cursor: 'pointer' }} >
                                                                <img src={one_row} draggable onClick={() => create_layouts('1')} />
                                                            </div>
                                                            <div style={{ cursor: 'pointer' }}>
                                                                <img src={two_col} draggable onClick={() => create_layouts('2')} />
                                                            </div>
                                                        </div>

                                                        <div >
                                                            <div style={{ cursor: 'pointer' }}>
                                                                <img src={three_col} draggable onClick={() => create_layouts('3')} />
                                                            </div>
                                                            <div style={{ cursor: 'pointer' }} >
                                                                <img src={four_col} draggable onClick={() => create_layouts('4')} />
                                                            </div>
                                                        </div>

                                                        <div >
                                                            <div style={{ cursor: 'pointer' }} >
                                                                <img src={five_col} draggable onClick={() => create_layouts('5')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                select_menu === '2' ?
                                                    <div className="p-2" style={{ backgroundColor: 'white', height: '100vh' }}>

                                                        <div className="ps-2" style={{ marginTop: '42px' }} >
                                                            <Row>
                                                                <Col md={6} style={{ cursor: 'pointer', width: "100%" }}>
                                                                    <h2 onClick={() => text_block("1")}> HEADER 1</h2>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row >
                                                                <Col md={6} style={{ cursor: 'pointer', width: "100%" }}>
                                                                    <h3 onClick={() => text_block("2")}> HEADER 2</h3>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row >
                                                                <Col md={6} style={{ cursor: 'pointer', width: "100%", display: "flex" }}>
                                                                    <h4 onClick={() => text_block("3")} > HEADER 3</h4>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row >
                                                                <Col md={6} style={{ cursor: 'pointer', width: "100%", display: "flex" }}>
                                                                    <h5 onClick={() => text_block("4")}> HEADER 4</h5>
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                    </div>
                                                    :
                                                    select_menu === '3' ?
                                                        <div className="p-2" style={{ backgroundColor: 'white', height: '100vh' }} >

                                                            <div className="mt-0" style={{
                                                                display: "flex",
                                                                justifyContent: 'space-evenly',
                                                                flexWrap: "wrap",
                                                                paddingTop: "35px",
                                                                rowGap: "1rem",
                                                                columnGap: "1rem",
                                                            }}>
                                                                <div>
                                                                    <div style={{ cursor: 'pointer' }}>
                                                                        <img src={barChart} draggable onDragStart={(e) => handleIconDragStart(e, barChart, "bar_charts")} />
                                                                    </div>
                                                                    <div style={{ cursor: 'pointer' }} className="items mt-2">
                                                                        <img src={areaChart} draggable onDragStart={(e) => handleIconDragStart(e, areaChart, "area_chart")} />
                                                                    </div>
                                                                </div>


                                                                <div>
                                                                    <div style={{ cursor: 'pointer' }} className="items">
                                                                        <img src={stackChart} draggable onDragStart={(e) => handleIconDragStart(e, stackChart, "stack_bar")} />
                                                                    </div>
                                                                    <div style={{ cursor: 'pointer' }} className="items mt-3 ms-2">
                                                                        <img src={pieChart} draggable onDragStart={(e) => handleIconDragStart(e, pieChart, "pie_chart")} />
                                                                    </div>
                                                                </div>


                                                                <div>
                                                                    <div style={{ cursor: 'pointer' }} className="items">
                                                                        <img src={lineChart} draggable onDragStart={(e) => handleIconDragStart(e, lineChart, "line_chart")} />
                                                                    </div>
                                                                    <br />
                                                                    <div style={{ cursor: 'pointer' }} >
                                                                        <img src={hor_barChart} height={60} width={90} draggable onDragStart={(e) => handleIconDragStart(e, hor_barChart, "hor_barcharts")} />
                                                                    </div>
                                                                </div>


                                                                <div>

                                                                    <div style={{ cursor: 'pointer' }} className="">
                                                                        <img src={hor_stackChart} height={66} width={94} draggable onDragStart={(e) => handleIconDragStart(e, hor_stackChart, "hor_stack")} />
                                                                    </div>
                                                                    <div style={{ cursor: 'pointer' }} className="">
                                                                        <span>
                                                                            <i style={{ fontSize: '70px', color: '#2d93ea' }} className="bx bx-table" draggable onDragStart={(e) => handleIconDragStart(e, 'table', "table")} >
                                                                            </i>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div style={{ cursor: 'pointer' }} className="">
                                                                        <img src={vertical} height={66} width={94} draggable onDragStart={(e) => handleIconDragStart(e, 'Vertical_linechart', "Vertical_linechart")} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        :
                                                        select_menu === '4' ?
                                                            <div className="p-2" style={{ backgroundColor: 'white', height: '100vh' }}>
                                                                <div className="mt-0" style={{
                                                                    display: "flex",
                                                                    justifyContent: 'space-evenly',
                                                                    flexWrap: "wrap",
                                                                    paddingTop: "35px",
                                                                    rowGap: "1rem",
                                                                    columnGap: "1rem",
                                                                }}>
                                                                    <div>
                                                                        <div style={{ cursor: 'pointer', mixBlendMode: 'hard-light' }}>
                                                                            <img height={80} src={rectangle} draggable onDragStart={(e) => handleIconDragStart(e, rectangle, "rectangle_card")} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null}
                                    </div>
                                </Col>
                            </Row>
                        </Col>}


                        <Col md={!prvpage && 8} lg={!prvpage && 10} className="p-0 ps-2">
                            <Card id="cardPrv" style={{ border: '1px solid lightgrey', overflowX: 'scroll', height: '100%' }}>
                                <CardBody style={{ background: 'lightgrey' }} >
                                    {/* '#f7f7f7' */}
                                    <Row className="d-flex">
                                        <Col md={3}>
                                            <Label style={{ fontFamily: "Poppins" , fontSize:'16px'  , fontWeight:'bold'}}> Report Page Title</Label>
                                        </Col>
                                        
                                        <Col md={9} className="">
                                            <div className="d-flex flex-row-reverse gap-2 ">
                                            <div>
                                                <Button className="btn btn-primary "
                                                    onClick={() => {
                                                        history.push("/page_tree")
                                                    }}> Back</Button>
                                            </div>
                                            <div className="text-end">
                                                <Button className="btn btn-danger"
                                                    style={{
                                                        fontSize: '13px',
                                                        padding: '6px 20px',
                                                        cursor: 'pointer',
                                                        fontFamily: 'poppins,sans-serif bold',
                                                        color: 'white',
                                                        borderRadius: '5px'
                                                    }}
                                                    onClick={() => {
                                                        reseted_data()
                                                    }}> RESET</Button>
                                            </div>

                                            <Button onClick={() => { preview_enabl() }}>{prvpage ? "MAIN" : "PREVIEW"}</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={10}></Col>
                                        <Col md={2}>
                                            {/* <Button onClick={() => { preview_enabl() }}>{prvpage ? "MAIN" : "PREVIEW"}</Button> */}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} >
                                            <div >
                                                {
                                                    isSidePanelOpen &&
                                                    <div>
                                                        <SidePanel
                                                            overlay={true} updateLayout={(data, rerender) => { updateLayout(data, rerender) }} layout={layout} dbCollections={dbCollections} isOpen={isSidePanelOpen} onClose={() => { setSidePanelOpen(false); }} data={sidepanel_data}
                                                            db_data={db_data}
                                                            show_table_function={(val, item, index, layoutarr) => show_table_function(val, item, index, layoutarr)} />
                                                    </div>
                                                }
                                            </div>
                                            {/* {
                                                console.log('layout  1836:>> ', layout, LayoutArray?.layoutInfo, fullscreenSize, "mod_layout", mod_layout)
                                            } */}



                                            {
                                                mod_layout?.length > 0  ?  //&& mod_layout !== undefined
                                                    <>
                                                        <Row >
                                                            {mod_layout.map((item, index1) => (

                                                                <Col key={item.i}
                                                                    md={12}
                                                                    sm={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                    xxl={item.w}

                                                                    breakpoints={{ xxl: 2400 , xl:2200 , lg: 2000, md: 900, sm: 768, xs: 480 }}
                                                                    // lg={item.w} sm={item.w} xl={item.w} xxl={item.w} xsm={item.w}
                                                                >

                                                                    <Card id={item.i}
                                                                        style={{
                                                                            cursor: 'default', border: '1px solid #e4e4e4', 
                                                                            // height: '320px',
                                                                            height: item.h* 100,
                                                                             backgroundColor: 'lightblue',
                                                                            //  maxWidth:'860px',
                                                                            //  minWidth:item.w * 100
                                                                            // width: item.containerWidth,
                                                                            // left: `${item.x * 185}px`,
                                                                            // top: `${item.y}px`,
                                                                        }}
                                                                        onDragOver={(e) => handleDragOver(e, item)}
                                                                        onDrop={(e) => handleDrop1(e, item)}
                                                                    >
                                                                        <CardBody className="p-0">
                                                                            {
                                                                                (item.fullScreen_enabled === undefined || item.fullScreen_enabled === false)
                                                                                    && !prvpage ? <div
                                                                                    className="drag-handle"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0px',
                                                                                        left: '0px',
                                                                                        padding: '5px',
                                                                                        width: '50px',
                                                                                        height: "40px",
                                                                                        cursor: 'move',
                                                                                        background: 'lightblue',
                                                                                        zIndex: 1
                                                                                    }}
                                                                                />
                                                                                    :
                                                                                    null}
                                                                            {
                                                                                <>
                                                                                    <p style={{
                                                                                        position: 'absolute',
                                                                                        top: '2%',
                                                                                        left: '5%',
                                                                                        zIndex: '10',
                                                                                        backgroundColor: 'transparent',
                                                                                        padding: '0.5em',
                                                                                        fontWeight: 'bold',
                                                                                        color: 'black',
                                                                                        fontSize: '0.5vw', //'0.5vw'
                                                                                    }}>
                                                                                        {item.chart_name?.toUpperCase()}&nbsp;&nbsp;
                                                                                        {item.i?.slice(-3)}
                                                                                    </p>
                                                                                    <style>
                                                                                        {`
            @media (max-width: 600px) {
                p {
                    font-size: 10px; // Set font size to 10px on smaller screens
                }
            }
        `}
                                                                                    </style>
                                                                                </>
                                                                            }
                                                                            {
                                                                                (item.name !== undefined && item.name !== 'rectangle_card' && item.name !== '' && item.name !== 'pie_chart')
                                                                                &&
                                                                                <div className="outLay"
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        fontSize: '25px',
                                                                                        width: '45px',
                                                                                        position: 'absolute',
                                                                                        top: '2px',
                                                                                        right: '100px',
                                                                                        zIndex: '1',
                                                                                        color: '#6666B2',
                                                                                        boxShadow: 'none',
                                                                                        backgroundColor: 'transparent'
                                                                                    }}
                                                                                >
                                                                                    <UncontrolledDropdown className="" style={{ marginLeft: '-118px', width: '25px', boxShadow: 'none', backgroundColor: 'transparent' }}>
                                                                                        <DropdownToggle tag="a" className="" role="button">

                                                                                            {
                                                                                                (item.fullScreen_enabled !== true) && <span>
                                                                                                    <i className="bx bx-bar-chart-alt-2"
                                                                                                        style={{
                                                                                                            cursor: 'pointer',
                                                                                                            fontSize: '25px',
                                                                                                            width: '45px',
                                                                                                            zIndex: '1',
                                                                                                            color: '#6666B2',
                                                                                                            boxShadow: 'none'
                                                                                                        }}
                                                                                                    ></i>
                                                                                                </span>}
                                                                                            </DropdownToggle>
                                                                                            {/* <DropdownMenu className="dropdown-menu-end" style={{ top: '-282px', right: '250px' }}>
                                                                                                {['Stack', 'bar_charts', 'area_chart', 'line_chart', 'hor_barcharts', 'hor_stack', 'Vertical_linechart', 'table'].map((chartType, i) => (
                                                                                                    <DropdownItem key={i} className="dropdown-item" href="#" onClick={() => convrt_chrt(chartType, item, index1)}>
                                                                                                        {chartType === 'bar_charts' ? 'Bar Chart' : chartType === 'hor_barcharts' ? 'Horizontal Bar chart' : chartType === 'hor_stack' ? 'Horizontal Stack chart' : chartType === 'Vertical_linechart' ? 'Vertical Line chart' : chartType.charAt(0).toUpperCase() + chartType.slice(1)}
                                                                                                    </DropdownItem>
                                                                                                ))}
                                                                                            </DropdownMenu> */}

                                                                                        <DropdownMenu className="dropdown-menu-end" style={{ top: '-282px', right: '250px' }} >
                                                                                            <DropdownItem className="dropdown-item" href="#" onClick={() => convrt_chrt('Stack', item, index1)}  >Stack</DropdownItem>
                                                                                            <DropdownItem className="dropdown-item" href="#" onClick={() => convrt_chrt('bar_charts', item, index1)}  >Bar Chart</DropdownItem>
                                                                                            <DropdownItem className="dropdown-item" href="#" onClick={() => convrt_chrt('area_chart', item, index1)}   disabled={item.name == 'area_chart'}   >Area</DropdownItem>
                                                                                            <DropdownItem className="dropdown-item" href="#" onClick={() => convrt_chrt('line_chart', item, index1)}>LineChart</DropdownItem>
                                                                                            <DropdownItem className="dropdown-item" href="#" onClick={() => convrt_chrt('hor_barcharts', item, index1)}  disabled={item.name == 'area_chart'} >Horizontal Bar chart</DropdownItem>
                                                                                            <DropdownItem className="dropdown-item" href="#" onClick={() => convrt_chrt('hor_stack', item, index1)}>Horizontal Stack chart</DropdownItem>
                                                                                            <DropdownItem className="dropdown-item" href="#" onClick={() => convrt_chrt('Vertical_linechart', item, index1)}>Vertical Line  chart</DropdownItem>
                                                                                            <DropdownItem className="dropdown-item" href="#" onClick={() => convrt_chrt('table', item, index1)}>Table</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>

                                                                                </div>
                                                                            }
                                                                            {

                                                                                (item.name !== undefined && item.name !== 'rectangle_card' && item.name !== '') &&
                                                                                <>
                                                                                    {
                                                                                        (item.fullScreen_enabled !== true) &&

                                                                                        !prvpage &&
                                                                                        <span>
                                                                                            <i className="bx bx-edit-alt"
                                                                                                style={{
                                                                                                    cursor: 'pointer',
                                                                                                    fontSize: '25px',
                                                                                                    width: '26px',
                                                                                                    position: 'absolute',
                                                                                                    top: '5px',
                                                                                                    right: '274px',   ///''254px',
                                                                                              
                                                                                                    zIndex: '1',
                                                                                                    color: '#6666B2',
                                                                                                }}
                                                                                                onClick={(e) => {
                                                                                                    onlayoutClick(item, index1, layout);
                                                                                                    sessionStorage.setItem('blockdata', JSON.stringify(item));
                                                                                                    sessionStorage.setItem('blockIdx', JSON.stringify(index1))
                                                                                                }}
                                                                                            ></i>

                                                                                        </span>}


                                                                                    {

                                                                                        (item.name != 'table') &&
                                                                                        <>

                                                                                            {
                                                                                                (item.show_table === true && item.fullScreen_enabled !== true) ?
                                                                                                    <button style={{
                                                                                                        cursor: 'pointer',
                                                                                                        fontSize: '35px',
                                                                                                        width: '28px',
                                                                                                        position: 'absolute',
                                                                                                        top: '6px',
                                                                                                        right: '183px',
                                                                                                        zIndex: '1',
                                                                                                        height: '25px',
                                                                                                        backgroundColor: 'transparent',
                                                                                                        border: 'none'
                                                                                                    }}
                                                                                                        onClick={async (e) => {
                                                                                                            sessionStorage.setItem('blockdata', JSON.stringify(item));
                                                                                                            sessionStorage.setItem('blockIdx', JSON.stringify(index1));
                                                                                                            show_table_function(e, item, index1, layout);
                                                                                                        }}
                                                                                                    >
                                                                                                        <span>
                                                                                                            <Icon
                                                                                                                path={mdiTableOff} // with strike slash
                                                                                                                style={{
                                                                                                                    cursor: 'pointer',
                                                                                                                    fontSize: '35px',
                                                                                                                    width: '25px',
                                                                                                                    // marginLeft: '22px',//'-6px',
                                                                                                                    marginLeft:item.name == 'pie_chart' ? '50px': '22px', 
                                                                                                                    marginTop: '-38px',
                                                                                                                }}
                                                                                                            // onClick={(e) => {show_table_function(e, item, index1) }}
                                                                                                            />
                                                                                                        </span>

                                                                                                    </button>
                                                                                                    :
                                                                                                    ((item.show_table === false || item.show_table === undefined) && item.fullScreen_enabled !== true) ?
                                                                                                        <button style={{
                                                                                                            cursor: 'cursor',
                                                                                                            fontSize: '35px',
                                                                                                            width: '28px',
                                                                                                            position: 'absolute',
                                                                                                            top: '6px',
                                                                                                            right: '183px',
                                                                                                            zIndex: '1',
                                                                                                            height: '25px',
                                                                                                            backgroundColor: 'transparent',
                                                                                                            border: 'none'
                                                                                                        }}
                                                                                                            onClick={async (e) => {
                                                                                                                sessionStorage.setItem('blockdata', JSON.stringify(item));
                                                                                                                sessionStorage.setItem('blockIdx', JSON.stringify(index1));
                                                                                                                show_table_function(e, item, index1, layout);
                                                                                                            }}
                                                                                                        >
                                                                                                            <Icon
                                                                                                                path={mdiTableLarge}
                                                                                                                style={{
                                                                                                                    cursor: 'pointer',
                                                                                                                    fontSize: '35px',
                                                                                                                    width: '25px',
                                                                                                                    // marginLeft: '22px',//'-6px',
                                                                                                                    marginLeft:item.name == 'pie_chart' ? '50px': '22px', 
                                                                                                                    marginTop: '-38px',
                                                                                                                }}
                                                                                                            />

                                                                                                        </button>
                                                                                                        :
                                                                                                        null
                                                                                            }
                                                                                            <div onClick={() => { !isFullScreen ? handleToggleFullScreen(item.i, item, layout, index1) : exit_screen(item.i, index1) }}>
                                                                                                <span>
                                                                                                    <i
                                                                                                        className={`bx ${isFullScreen ? ' bx-exit-fullscreen' : 'bx-fullscreen'}`}
                                                                                                        style={{
                                                                                                            cursor: 'pointer',
                                                                                                            fontSize: '25px',
                                                                                                            position: 'absolute',
                                                                                                            top: '5px',
                                                                                                            // right: '199px',//'226px',
                                                                                                            right:item.name == 'pie_chart' ? '165px': '199px', 
                                                                                                            zIndex: '1',
                                                                                                            color: '#6666B2',
                                                                                                        }}
                                                                                                    />
                                                                                                </span>
                                                                                            </div>

                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }

                                                                            {
                                                                                (item.name !== 'rectangle_card' && item.fullScreen_enabled !== true)
                                                                                &&
                                                                                <i
                                                                                    className="bx bx-x "
                                                                                    onClick={(e) => del_block(e, item, index1)}
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        fontSize: '35px',
                                                                                        width: '45px',
                                                                                        position: 'absolute',
                                                                                        top: '2px',
                                                                                        right: '25px',
                                                                                        zIndex: '1',
                                                                                    }}
                                                                                >
                                                                                </i>}

                                                                            {
                                                                                item.name === 'bar_charts' ?
                                                                                    <div id={`${'bar1' + item.i}`} style={{}}>
                                                                                        <div  >
                                                                                            <BarChart
                                                                                                BarWidth={item.barWidth}
                                                                                                load={item.charts_loaded}
                                                                                                temp_containerWidth={item.temp_containerWidth}
                                                                                                temp_containerHeight={item.temp_containerHeight}
                                                                                                containerWidth={item.containerWidth}
                                                                                                containerHeight={item.containerHeight}
                                                                                                chart_height={item?.chart_height !== undefined ? item.chart_height : undefined}
                                                                                                chart_data={item?.data === undefined ? defaultBarChartValue : item.data}
                                                                                                chart_color={item?.chart_customize_clr !== undefined ? item.chart_customize_clr : 'steelblue'}
                                                                                                id={item.i}
                                                                                                label={item?.x_axis_label !== undefined ? item.x_axis_label : 'label'}
                                                                                                YLabel={item?.y_axis_label !== undefined ? item.y_axis_label : 'Ylabel'}
                                                                                                mouseovered={item?.mouseovered !== undefined ? item.mouseovered : false}
                                                                                                mouseovered_type={item?.mouseovered_type !== undefined ? item.mouseovered_type : false}
                                                                                                show_Line={item?.show_Line !== undefined ? item.show_Line : false}
                                                                                                show_table={item?.show_table !== undefined ? item.show_table : false}
                                                                                                show_Full_Screen_toggle={item.fullScreen_enabled !== undefined ? item.fullScreen_enabled : false}
                                                                                                show_Grid={item.show_Grid !== undefined ? item.show_Grid : false}
                                                                                                show_bar_values={item?.show_bar_values !== undefined ? item.show_bar_values : false}
                                                                                                resized={item.resized}
                                                                                                show_Square={item?.show_Square !== undefined ? item.show_Square : false}
                                                                                                curved_line={item?.curved_line_chrt === undefined ? false : item.curved_line_chrt}
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                    :
                                                                                    item.name === 'stack_bar' ? (
                                                                                        <div id={`${'bar1' + item.i}`} >
                                                                                            <div style={{ position: 'absolute', }}>
                                                                                                <StackChart
                                                                                                    BarWidth={item.barWidth}
                                                                                                    temp_containerWidth={item.temp_containerWidth}
                                                                                                    temp_containerHeight={item.temp_containerHeight}
                                                                                                    containerWidth={item.containerWidth}
                                                                                                    containerHeight={item.containerHeight}
                                                                                                    chart_height={item?.chart_height !== undefined ? item.chart_height : ''}
                                                                                                    chart_data={item?.data === undefined ? defaultStackChartValue : item.data}
                                                                                                    chart_color={item?.chart_customize_clr_arr !== undefined ? item.chart_customize_clr_arr : []}
                                                                                                    id={item.i}
                                                                                                    label={item?.x_axis_label !== undefined ? item.x_axis_label : 'label'}
                                                                                                    YLabel={item?.label_arr_data !== undefined ? item.label_arr_data : []}
                                                                                                    mouseovered={item?.mouseovered !== undefined ? item.mouseovered : false}
                                                                                                    mouseovered_type={item?.mouseovered_type !== undefined ? item.mouseovered_type : false}
                                                                                                    show_Line={item?.show_Line !== undefined ? item.show_Line : false}
                                                                                                    show_table={item?.show_table !== undefined ? item.show_table : false}
                                                                                                    show_Full_Screen_toggle={item.fullScreen_enabled !== undefined ? item.fullScreen_enabled : false}
                                                                                                    show_bar_values={item?.show_bar_values !== undefined ? item.show_bar_values : false}
                                                                                                    show_Grid={item.show_Grid !== undefined ? item.show_Grid : false}
                                                                                                    curved_line={item?.curved_line_chrt === undefined ? false : item.curved_line_chrt}
                                                                                                    show_Square={item?.show_Square !== undefined ? item.show_Square : false}
                                                                                                    resized={item.resized}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                    )
                                                                                        :
                                                                                        item.name === 'area_chart' ?
                                                                                            (
                                                                                                <div id={`${'bar1' + item.i}`} >
                                                                                                    <div style={{ position: 'relative' }}>
                                                                                                        <AreaChart
                                                                                                            temp_containerWidth={item.temp_containerWidth}
                                                                                                            temp_containerHeight={item.temp_containerHeight}
                                                                                                            containerWidth={item.containerWidth}
                                                                                                            containerHeight={item.containerHeight}
                                                                                                            chart_data={item?.data === undefined ? area_data : item.data}
                                                                                                            chart_height={item?.chart_height !== undefined ? item.chart_height : undefined}
                                                                                                            chart_color={item?.chart_customize_clr_arr !== undefined ? item.chart_customize_clr_arr : undefined}
                                                                                                            id={item.i}
                                                                                                            label={item?.x_axis_label !== undefined ? item.x_axis_label : 'label'}
                                                                                                            mouseovered={item?.mouseovered !== undefined ? item.mouseovered : false}
                                                                                                            mouseovered_type={item?.mouseovered_type !== undefined ? item.mouseovered_type : false}
                                                                                                            show_table={item?.show_table !== undefined ? item.show_table : false}
                                                                                                            show_Grid={item.show_Grid !== undefined ? item.show_Grid : false}
                                                                                                            show_Full_Screen_toggle={item.fullScreen_enabled !== undefined ? item.fullScreen_enabled : false}
                                                                                                            YLabel={item?.label_arr_data !== undefined ? item.label_arr_data : []}
                                                                                                            show_Square={item?.show_Square !== undefined ? item.show_Square : false}
                                                                                                            curved_line={item?.curved_line_chrt === undefined ? false : item.curved_line_chrt}
                                                                                                            show_bar_values={item?.show_bar_values !== undefined ? item.show_bar_values : false}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                            :
                                                                                            item.name === 'pie_chart' ?
                                                                                                (
                                                                                                    <div id={`${'bar1' + item.i}`} style={{ boxShadow: 'none', border: 'none' }} >
                                                                                                        <div style={{ boxShadow: 'none', border: 'none' }} >
                                                                                                            <PieChart
                                                                                                                temp_containerWidth={item.temp_containerWidth}
                                                                                                                temp_containerHeight={item.temp_containerHeight}
                                                                                                                containerWidth={item.containerWidth}
                                                                                                                containerHeight={item.containerHeight}
                                                                                                                chart_height={item?.chart_height !== undefined ? item.chart_height : undefined}
                                                                                                                chart_data={item?.data === undefined ? defaultPieChartValue : item.data}
                                                                                                                id={item.i}
                                                                                                                label={item?.x_axis_label !== undefined ? item.x_axis_label : 'label'}
                                                                                                                mouseovered={item?.mouseovered !== undefined ? item.mouseovered : false}
                                                                                                                show_table={item?.show_table !== undefined ? item.show_table : false}
                                                                                                                show_bar_values={item?.show_bar_values !== undefined ? item.show_bar_values : false}
                                                                                                                show_Full_Screen_toggle={item.fullScreen_enabled !== undefined ? item.fullScreen_enabled : false}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                                :
                                                                                                item.name === 'line_chart' ?
                                                                                                    (
                                                                                                        <div id={`${'bar1' + item.i}`} >
                                                                                                            <div style={{ position: 'relative' }}>
                                                                                                                <LineChart
                                                                                                                    temp_containerWidth={item.temp_containerWidth}
                                                                                                                    temp_containerHeight={item.temp_containerHeight}
                                                                                                                    containerWidth={item.containerWidth}
                                                                                                                    containerHeight={item.containerHeight}
                                                                                                                    chart_height={item?.chart_height !== undefined ? item.chart_height : undefined}
                                                                                                                    chart_data={item?.data === undefined ? defaultLineChartValue : item.data}
                                                                                                                    chart_color={item?.chart_customize_clr_arr !== undefined ? item.chart_customize_clr_arr : undefined}
                                                                                                                    curved_line={item?.curved_line_chrt === undefined ? false : item.curved_line_chrt}
                                                                                                                    id={item.i}
                                                                                                                    label={item?.x_axis_label !== undefined ? item.x_axis_label : 'label'}
                                                                                                                    mouseovered={item?.mouseovered !== undefined ? item.mouseovered : false}
                                                                                                                    mouseovered_type={item?.mouseovered_type !== undefined ? item.mouseovered_type : false}
                                                                                                                    show_table={item?.show_table !== undefined ? item.show_table : false}
                                                                                                                    show_Grid={item.show_Grid !== undefined ? item.show_Grid : false}
                                                                                                                    show_Full_Screen_toggle={item.fullScreen_enabled !== undefined ? item.fullScreen_enabled : false}
                                                                                                                    show_bar_values={item?.show_bar_values !== undefined ? item.show_bar_values : false}
                                                                                                                    YLabel={item?.label_arr_data !== undefined ? item.label_arr_data : []}
                                                                                                                    show_Square={item?.show_Square !== undefined ? item.show_Square : false}
                                                                                                                />

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                    : item.name === "rectangle_card" ?
                                                                                                        <Card style={{ height: '100%' }}>
                                                                                                            <CardBody >
                                                                                                                <div style={{ textAlign: 'right', border: 'none', boxShadow: 'none' }}>
                                                                                                                    <i className="bx bx-edit-alt" style={{ cursor: 'pointer', color: '#6666b2', fontSize: '25px' }} onClick={(e) => { onlayoutClick(item, index1, layout); sessionStorage.setItem('blockdata', JSON.stringify(item)); sessionStorage.setItem('blockIdx', JSON.stringify(index1)) }}  ></i>
                                                                                                                    <span>
                                                                                                                        <i className="bx bx-x" onClick={(e) => del_block(e, item, index1)} style={{ cursor: 'pointer', fontSize: '35px', width: "45px", zIndex: '0' }} ></i>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <br />
                                                                                                                <div className="d-flex" style={{ boxShadow: 'none' }}>
                                                                                                                    <div className="flex-grow-1" style={{ boxShadow: 'none' }}>
                                                                                                                        <p className=" " style={getStyle(item, '1')}>
                                                                                                                            {item.text}
                                                                                                                        </p>
                                                                                                                        <h4 className="mb-0" style={getStyle(item, '2')}>{(item.count !== null && item.count !== undefined) ? item.count : 'IN- VALID'}</h4>
                                                                                                                    </div>
                                                                                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                                                                                        <span className="avatar-title rounded-circle bg-primary">
                                                                                                                            <i className={"bx bx-copy-alt font-size-24"}
                                                                                                                            ></i>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </CardBody>
                                                                                                        </Card>
                                                                                                        :
                                                                                                        item.name === 'table' ?
                                                                                                            <div >
                                                                                                                <D3Table
                                                                                                                    containerWidth={item.containerWidth}
                                                                                                                    containerHeight={item.containerHeight}
                                                                                                                    show_table={true}
                                                                                                                    chart_data={item?.data === undefined ? defaultStackChartValue : item.data}
                                                                                                                    id={item.i}
                                                                                                                    label_name={item?.label_arr_data === undefined ? '' : item.label_arr_data} />

                                                                                                            </div>
                                                                                                            :

                                                                                                            item.name === 'hor_barcharts' ?
                                                                                                                <div id={`${'bar1' + item.i}`} style={{}}>
                                                                                                                    <div >
                                                                                                                        <HorizontalbarChart
                                                                                                                            temp_containerWidth={item.temp_containerWidth}
                                                                                                                            temp_containerHeight={item.temp_containerHeight}
                                                                                                                            containerWidth={item.containerWidth}
                                                                                                                            containerHeight={item.containerHeight}
                                                                                                                            chart_height={item?.chart_height !== undefined ? item.chart_height : ''}
                                                                                                                            chart_data={item?.data === undefined ? defaultBarChartValue : item.data}
                                                                                                                            chart_color={item?.chart_customize_clr !== undefined ? item.chart_customize_clr : '#4682b4'}
                                                                                                                            id={item.i}
                                                                                                                            label={item?.x_axis_label !== undefined ? item.x_axis_label : 'label'}
                                                                                                                            mouseovered={item?.mouseovered !== undefined ? item.mouseovered : false}
                                                                                                                            mouseovered_type={item?.mouseovered_type !== undefined ? item.mouseovered_type : false}
                                                                                                                            show_Line={item?.show_Line !== undefined ? item.show_Line : false}
                                                                                                                            show_table={item?.show_table !== undefined ? item.show_table : false}
                                                                                                                            show_Full_Screen_toggle={item.fullScreen_enabled !== undefined ? item.fullScreen_enabled : false}
                                                                                                                            show_Grid={item.show_Grid !== undefined ? item.show_Grid : false}
                                                                                                                            show_bar_values={item?.show_bar_values !== undefined ? item.show_bar_values : false}
                                                                                                                            curved_line={item?.curved_line_chrt === undefined ? false : item.curved_line_chrt}
                                                                                                                            show_Square={item?.show_Square !== undefined ? item.show_Square : false}
                                                                                                                            YLabel={item?.y_axis_label !== undefined ? item.y_axis_label : 'Ylabel'}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                item.name === 'hor_stack' ?
                                                                                                                    <div id={`${'bar1' + item.i}`} style={{}}>
                                                                                                                        <div >
                                                                                                                            <HorizontalStackChart
                                                                                                                                temp_containerWidth={item.temp_containerWidth}
                                                                                                                                temp_containerHeight={item.temp_containerHeight}
                                                                                                                                containerWidth={item.containerWidth}
                                                                                                                                containerHeight={item.containerHeight}
                                                                                                                                chart_height={item?.chart_height !== undefined ? item.chart_height : undefined}
                                                                                                                                chart_data={item?.data === undefined ? defaultStackChartValue : item.data}
                                                                                                                                chart_color={item?.chart_customize_clr_arr !== undefined ? item.chart_customize_clr_arr : undefined}
                                                                                                                                id={item.i}
                                                                                                                                label={item?.x_axis_label !== undefined ? item.x_axis_label : 'label'}
                                                                                                                                show_bar_values={item?.show_bar_values !== undefined ? item.show_bar_values : false}
                                                                                                                                mouseovered={item?.mouseovered !== undefined ? item.mouseovered : false}
                                                                                                                                mouseovered_type={item?.mouseovered_type !== undefined ? item.mouseovered_type : false}
                                                                                                                                show_table={item?.show_table !== undefined ? item.show_table : false}
                                                                                                                                show_Grid={item.show_Grid !== undefined ? item.show_Grid : false}
                                                                                                                                show_Full_Screen_toggle={item.fullScreen_enabled !== undefined ? item.fullScreen_enabled : false}
                                                                                                                                show_Line={item?.show_Line !== undefined ? item.show_Line : false}
                                                                                                                                curved_line={item?.curved_line_chrt === undefined ? false : item.curved_line_chrt}
                                                                                                                                show_Square={item?.show_Square !== undefined ? item.show_Square : false}
                                                                                                                                YLabel={item?.label_arr_data !== undefined ? item.label_arr_data : []}

                                                                                                                            />

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    item.name === 'Vertical_linechart' ?
                                                                                                                        (
                                                                                                                            <div id={`${'bar1' + item.i}`} style={{ boxShadow: 'none', border: 'none' }} >
                                                                                                                                <div style={{ boxShadow: 'none', border: 'none' }} >
                                                                                                                                    <VerticalLineChart
                                                                                                                                        temp_containerWidth={item.temp_containerWidth}
                                                                                                                                        temp_containerHeight={item.temp_containerHeight}
                                                                                                                                        containerWidth={item.containerWidth}
                                                                                                                                        containerHeight={item.containerHeight}
                                                                                                                                        chart_height={item?.chart_height !== undefined ? item.chart_height : undefined}
                                                                                                                                        chart_data={item?.data === undefined ? line_data : item.data}
                                                                                                                                        chart_color={item?.chart_customize_clr_arr !== undefined ? item.chart_customize_clr_arr : undefined}
                                                                                                                                        curved_line={item?.curved_line_chrt === undefined ? false : item.curved_line_chrt}
                                                                                                                                        id={item.i}
                                                                                                                                        label={item?.x_axis_label !== undefined ? item.x_axis_label : 'label'}
                                                                                                                                        show_table={item?.show_table !== undefined ? item.show_table : false}
                                                                                                                                        show_Grid={item.show_Grid !== undefined ? item.show_Grid : false}
                                                                                                                                        show_Full_Screen_toggle={item.fullScreen_enabled !== undefined ? item.fullScreen_enabled : false}
                                                                                                                                        show_bar_values={item?.show_bar_values !== undefined ? item.show_bar_values : false}
                                                                                                                                        mouseovered={item?.mouseovered !== undefined ? item.mouseovered : false}
                                                                                                                                        mouseovered_type={item?.mouseovered_type !== undefined ? item.mouseovered_type : false}
                                                                                                                                        show_Square={item?.show_Square !== undefined ? item.show_Square : false}
                                                                                                                                        YLabel={item?.label_arr_data !== undefined ? item.label_arr_data : []}


                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                        :
                                                                                                                        null
                                                                            }
                                                                            {
                                                                                item.type !== undefined &&
                                                                                item.type === 'text' &&
                                                                                <div className="d-flex" style={{ justifyContent: 'space-between', boxShadow: 'none' }}>
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center',
                                                                                        maxWidth: '500px',
                                                                                        marginLeft: '50px',
                                                                                        boxShadow: 'none'
                                                                                    }}>
                                                                                        <Input
                                                                                            defaultValue={item.value}
                                                                                            style={{ fontSize: item.fontsize, border: '' }}
                                                                                            onChange={(e) => updt_txt_layout(e, item, index1)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                        </CardBody>
                                                                    </Card>

                                                                </Col>

                                                            ))}
                                                        </Row>
                                                    </>
                                                    :
                                                    <div  style={{ color:'red' , fontWeight:'bold'}}>
                                                    <p className="error-message">Something went wrong. Please Try Again.</p>
                                                    <Button  onClick={()=>{ location.reload()}}>Refresh</Button>
                                                </div>
                                            }

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>}
        </React.Fragment>
    );
}
export default Report;
