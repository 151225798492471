import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
    Row, Col, FormGroup, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
// import Dropzone from "react-dropzone"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'



const _ = require('lodash')
// var urlSocket = require("../../../helpers/urlSocket")
import urlSocket from "../../../../helpers/urlSocket";

export default class AddEndpointNode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataloaded: false,
            alertEnable: false,
            formDisabled: false,
            userInfo: {},
            optionGroup:[],
            codeError: "Please enter the code",
            codeErrorEnabled: false,
            catErrorEnabled: false,
            endpoint_name:this.props.editItem === null ? "" : this.props.editItem.name,
            otp_code:this.props.editItem === null ? "" : this.props.editItem.code,

            getCode:this.props.editItem === null ? "" : this.props.editItem.code,
            endpoint_cat:this.props.editItem === null ? "" : this.props.editItem.category,
            enableAddCode:true,
            enableCode:this.props.editItem === null ? false : true,

        };


    }

   async componentDidMount() {
        var data = JSON.parse(sessionStorage.getItem("authUser"));
        var db_info = JSON.parse(sessionStorage.getItem("db_info"));
        console.log(data,'data')
        this.setState(
            {
                sessionUserInfo: data.user_data,
                db_info:db_info,
                dataloaded: true,
            },()=>{ this.generateUniqueCode()}
           
        )
    }

    componentWillUnmount(){
    }

   async generateUniqueCode(){
        try {

            urlSocket.post("webEntities/gencode",{
                encrypted_db_url:this.state.db_info.encrypted_db_url,
                userInfo: this.state.sessionUserInfo,
            })
            .then(response=>{
                this.setState({
                    getCode:response.data.data,
                    enableCode:true
                })
            })
            
        } catch (error) {
        }
    }

    listOutCategory=()=>{
        var category = _.map(this.props.endpoints, item => {
            return item.category
        })
        
        var uniqueHlevels = _.uniqBy(category)

        return uniqueHlevels
    }

    selectCat = (event) => {
        this.setState({ createNewCat: event.target.value == "1" ? true : false, catErrorEnabled:false, catError:"" })
    
      }


 

      submitData=(event, values)=>{
        console.log(values,'values')
          if(this.props.editItem === null)
          {
            try {
                var loggedUserInfo = {
                    encrypted_db_url:this.state.db_info.encrypted_db_url,
                    company_id: this.state.sessionUserInfo.company_id,
                    user_id:this.state.sessionUserInfo._id
                }
                urlSocket.post("webEntities/crudeopt", {
                    userInfo: loggedUserInfo,
                    endpointInfo: {
                        values,
                    }
                })
                .then(response=>{
                    if(response.data.response_code === 502)
                    {
                        this.setState({
                            eoptexist:true
                        })
                    }
                    else
                    if(response.data.response_code === 500)
                    {
                            this.props.onClose()
                    }
                })
            } catch (error) {
            }
        }
        else{
            try {
                var loggedUserInfo = {
                    company_id: this.state.sessionUserInfo.company_id,
                    user_id:this.state.sessionUserInfo._id,
                    encrypted_db_url:this.state.db_info.encrypted_db_url
                }
                urlSocket.post("webEntities/updateeopt", {
                    userInfo: loggedUserInfo,
                    endpointInfo: {
                        values,
                    },
                    eopt:this.props.editItem
                })
                .then(response=>{
                    if(response.data.response_code === 502)
                    {
                        this.setState({
                            eoptexist:true
                        })
                    }
                    else
                    if(response.data.response_code === 500)
                    {
                            this.props.onClose()
                    }
                })
            } catch (error) {
            }
        }
      }
      


    render() {
        if (this.state.dataloaded) {
            const optionGroup = this.listOutCategory()
            return (
                <React.Fragment>
                    <Container fluid>
                        <Row >
                            <Col >
                                <Card className="overflow-hidden">
                                    {
                                        !this.state.formDisabled ?
                                            <CardBody className="pt-0">
                                                <div className="p-2">
                                                    <AvForm className="form-horizontal" onValidSubmit={this.submitData} onInvalidSubmit={this.handleInvalidSubmit}>
                                                        <Row className="my-4">
                                                            <div className="d-sm-flex align-items-center justify-content-between">
                                                                <div className="text-danger font-size-18">Location Information</div>
                                                                <button className="btn btn-outline-dark " onClick={() => this.props.onClose()}> Close </button>
                                                            </div>
                                                        
                                                                <hr className="my-4" />
                                                            </Row>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="endpoint_name"
                                                                label="Name"
                                                                type="text"
                                                                //defaultValue={this.state.hlevel_name}
                                                                value={this.state.endpoint_name}
                                                                required
                                                                placeholder="Enter Name"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="" htmlFor="autoSizingSelect">Category</Label>
                                                            <AvInput
                                                                type="select"
                                                                name="endpoint_cat"
                                                                label="Name"
                                                                value={this.state.endpoint_cat}
                                                                className="form-select"
                                                                id="cate"
                                                                required
                                                                onChange={(e) => this.selectCat(e)}>
                                                                <option value="" disabled selected>Choose...</option>
                                                                <option value="1"  >Create New</option>
                                                                {
                                                                    optionGroup.map((data, idx) => {
                                                                        return (
                                                                            <option value={data}  key={idx}>{data}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </AvInput>
                                                        </div>
                                                        {
                                                            this.state.createNewCat ?
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        name="category"
                                                                        type="text"
                                                                        value={this.state.category}
                                                                        required
                                                                        onChange={()=>this.setState({catErrorEnabled:false, catError:""})}
                                                                        placeholder="Enter New Category"
                                                                    />
                                                                </div> : null
                                                        }
                                                        {this.state.catErrorEnabled ? <Label className="text-danger" style={{ margin: '0 0 7px 0' }} htmlFor="autoSizingSelect">{this.state.catError}</Label> : null}
                                                        {/* {
                                                            this.state.enableAddCode ? <div className="form-check">
                                                                <AvInput
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    name="code_enable"
                                                                    id="invalidCheck"
                                                                    value={this.state.enableCode}
                                                                    onChange={() => this.setState({ enableCode: !this.state.enableCode ? true : false })}
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    htmlFor="invalidCheck"
                                                                >{" "}
                                                                    Add Code
                                                                </Label>
                                                            </div> : null
                                                        } */}

                                                        {
                                                            this.state.enableCode ?
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        name="code"
                                                                        label="Code"
                                                                        type="text"
                                                                        //errorMessage={this.state.codeError}
                                                                        // value={this.state.getCode}
                                                                        value={this.state.otp_code === "" ?this.state.getCode :this.state.otp_code }
                                                                        validate={{
                                                                            required: { value: true, errorMessage: this.state.codeError },
                                                                            pattern: { value: /^[a-zA-Z0-9-_]+$/, errorMessage: "Only numbers, letters, underscores, and hyphens are allowed." },

                                                                        }}

                                                                        placeholder="Enter Code"
                                                                    />
                                                                    {this.state.codeErrorEnabled ? <Label className="text-danger" style={{ margin: '5px 0 7px 0' }} htmlFor="autoSizingSelect">{this.state.codeError}</Label> : null}

                                                                </div> : null
                                                        }
                                                        {/* <button
                                                            className="btn btn-danger btn-block m-1"
                                                            // onClick={() => this.props.onCancel()}
                                                        >
                                                            Map Users for this Location
                                                        </button> */}


                                                    {  this.state.otp_code === "" &&  <label className="font-size-11">This code is auto generated. You can also edit or enter your own code</label>}

                                                        {
                                                            this.state.eoptexist?
                                                            <div className="mt-4 d-flex flex-row">
                                                                <label className="text-danger">Already Exist</label>
                                                            </div>:null
                                                        }
                                                        <div className="mt-4 d-flex flex-row">
                                                            <button
                                                                className={this.props.editItem !== null ? "btn btn-secondary btn-block m-1" : "btn btn-success btn-block m-1"}
                                                                type="submit"
                                                                disabled={this.props.protected_routes.read_write_checked === true ? false : true}
                                                            >
                                                                {this.props.editItem !== null ? "Update Location" : "Add Location"}
                                                            </button>
                                                            <button
                                                                className="btn btn-danger btn-block m-1"
                                                                onClick={() => this.props.onCancel()}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </CardBody> :
                                            <CardBody>
                                                <div className="mt-4 text-center">
                                                    <div className="mb-0" style={{ fontSize: 20 }}>
                                                        <span className="text-danger">{this.state.first_name}</span> has added successfully
                                                    </div>
                                                    <div style={{ marginTop: 20 }}>
                                                        <Link className="btn btn-success" onClick={() => this.props.onClose()}>
                                                            Back
                                                        </Link>
                                                    </div>
                                                </div>
                                            </CardBody>
                                    }
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            )
        }
        else {
            return null
        }
    }
}


