import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useExpanded,
    usePagination,
} from "react-table"
import {
    Table, Row, Col, Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import JobListGlobalFilter from "./GlobalSearchFilter"
import { Link } from "react-router-dom"
import FuzzySearch from '../../../../common/FuzzySearch';


// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <React.Fragment>
            <Col lg={4} md={4} >
                <div className="col-12">
                    <input type="search" className="form-control" id="search-bar-0" value={value || ""} placeholder={`${count} records...`} onChange={e => { setValue(e.target.value); onChange(e.target.value) }} />
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}
        </React.Fragment>
    )
}

const RoleTableContainer = ({
    selectedEOPT,
    columns,
    data,
    isGlobalFilter,
    isJobListGlobalFilter,
    isAddOptions,
    isAddUserList,
    handleOrderClicks,
    handleUserClick,
    handleCustomerClick,
    isAddCustList,
    customPageSize,
    customPageSizeOptions,
    iscustomPageSizeOptions,
    isPagination,
    isShowingPageLength,
    paginationDiv,
    pagination,
    tableClass,
    theadClass,
    dynamicBtn,
    btnName,
    btnClick,
    protect_routes,
    search_group_name,
    getFuzzySearch,
    dup_search_group_name,
    dup_temp_search_group,
    dup_labelData,
    moveToLabel,
    toggle2,
    labelDefault,
    removeFromLabel,
    confirmDelete,
    resultData,
    filterStatus,
    show_selected,
    totalEntities,
    isAllSelected
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            // defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0,
                pageSize: customPageSize,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        // useFilters,
        useSortBy,
        useExpanded,
        usePagination
    )

    const [folder_Menu, setFolderMenu] = React.useState(false)

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const toggleFolder = () => {
        page.forEach((row) => {
            row.original.selected =false;
          }); 
        setFolderMenu(!folder_Menu)
    }

    return (
        <Fragment>
            <Row className="border-bottom  align-items-center pb-3">

                {iscustomPageSizeOptions &&
                    <Col md={customPageSizeOptions ? 2 : 1}>
                        <select
                            className="form-select"
                            value={pageSize}
                            onChange={onChangeInSelect}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </Col>
                }

                {isGlobalFilter && (
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        isJobListGlobalFilter={isJobListGlobalFilter}
                    />
                )}

                {/* {
                    <Col lg={6} md={6} className="justify-content-center align-items-center" >
                        <div className="button-items mb-1 ms-2 text-center">
                            <div
                                className="btn-group btn-sm"
                                role="group"
                                aria-label="Basic radio toggle button group"
                            >
                                <Button
                                    color={"dark"}
                                    className="btn-sm"
                                    outline
                                    onClick={() => filterStatus("All")}
                                >
                                    {" "}
                                    Total &nbsp;
                                    {totalEntities}{" "}
                                </Button>
                                {resultData.map(
                                    (itemStatus, idx) => {
                                        return (
                                            <Button key={idx} 
                                            color={itemStatus.badgeClass} 
                                            className="btn-sm" 
                                            outline={itemStatus.status !== show_selected} 
                                            onClick={() => filterStatus(itemStatus.status)} >
                                                {" "}
                                                {
                                                    itemStatus.status
                                                }{" "}
                                                &nbsp;&nbsp;&nbsp;
                                                {
                                                    itemStatus.count
                                                }{" "}
                                            </Button>
                                        );
                                    }
                                )}
                            </div>
                        </div>

                    </Col>
                } */}





                {isAddOptions && (
                    <Col sm="7" xxl="8">
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={handleOrderClicks}
                            >
                                <i className="mdi mdi-plus me-1" />
                                Add New Order
                            </Button>
                        </div>
                    </Col>
                )}

                {dynamicBtn && (
                    <Col lg={8} md={8} >
                        <div className="text-sm-end">

                            <Button onClick={() => { btnClick() }} className="btn-md" type="button" color="primary">
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                {btnName}
                            </Button>
                        </div>
                    </Col>
                )}
            </Row>

            {

                <Row className="py-2">
                    <div className="d-flex" >
                        {
                            protect_routes.read_write_checked && isAllSelected && selectedEOPT.length >0 && (
                                <div >
                                    <Dropdown
                                        isOpen={
                                            folder_Menu
                                        }
                                        toggle={
                                            () => toggleFolder()
                                        }
                                        className=""
                                    >
                                        <DropdownToggle
                                            className="btn btn-sm btn-primary"
                                            tag="i"
                                        >
                                            <i className="fa fa-folder ms-2" />{" "}
                                            <i className="mdi mdi-arrow-right ms-1" />
                                        </DropdownToggle>

                                        <DropdownMenu className="p-4" style={{ minWidth: "450px", zIndex: 2, }}>
                                            <div className="pb-4 border-bottom">
                                                <FuzzySearch
                                                    search_files={search_group_name}
                                                    getFuzzySearch={(search_files) => getFuzzySearch(search_files)}
                                                    dup_search_files={dup_search_group_name}
                                                    temp_search_files={dup_temp_search_group}
                                                    keyword={['label_name']}
                                                    className="form-control"
                                                />
                                            </div>
                                            {dup_labelData.map(
                                                (item) => {
                                                    return (
                                                        <DropdownItem
                                                            key={""}
                                                            to="#"
                                                            onClick={() => {
                                                                moveToLabel(
                                                                    item
                                                                );
                                                            }}
                                                            className="py-2 font-size-12"
                                                            style={{ borderBottom: "1px solid #dedede" }}
                                                        >
                                                            {" "}
                                                            {
                                                                item.label_name
                                                            }
                                                        </DropdownItem>
                                                    );
                                                }
                                            )}
                                            {/* <div className="dropdown-divider"></div> */}
                                            <DropdownItem
                                                to="#"

                                                className="pt-4"
                                            >
                                                <button onClick={
                                                    toggle2
                                                }
                                                    className="btn btn-primary"
                                                >Create User Group</button>

                                            </DropdownItem>
                                        </DropdownMenu>

                                    </Dropdown>
                                </div>
                            )
                        }


                        <div>
                            {
                                !labelDefault && protect_routes.read_write_checked === true && isAllSelected && (
                                    <Button
                                        className="btn btn-sm mx-2"
                                        color="danger"
                                        onClick={() => {
                                            removeFromLabel();
                                        }}
                                    >
                                        Ungroup selected
                                    </Button>)
                            }
                            {
                                !labelDefault && protect_routes.read_write_checked === true &&
                                <Button
                                    className="btn btn-sm "
                                    color="danger"
                                    onClick={() => {
                                        confirmDelete()
                                    }}
                                >
                                    Delete this group
                                </Button>
                            }

                        </div>
                    </div>
                </Row>
            }


            <div className="table-responsive" style={{ minHeight: 500 }}>
                <Table {...getTableProps()} className={tableClass}>
                    <thead className={theadClass}>
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th key={column.id} className={column.isSort ? "sorting" : ''} width={column.width}>
                                        {
                                            column.isSort ?
                                                <div className="m-0" {...column.getSortByToggleProps()}>
                                                    {column.render("Header")}
                                                </div> :
                                                <div className="m-0" >
                                                    {column.render("Header")}
                                                </div>
                                        }

                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr>
                                        {row.cells.map(cell => {
                                            return (
                                                <td key={cell.id} {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </Fragment>
                            )
                        })}
                    </tbody>
                </Table>
            </div>

            {
                isPagination && (
                    <Row className="justify-content-between align-items-center">
                        {isShowingPageLength && <div className="col-sm">
                            <div className="text-muted">Showing <span className="fw-semibold">{page.length}</span> of <span className="fw-semibold">{data.length}</span> entries</div>
                        </div>}
                        <div className={paginationDiv}>
                            <ul className={pagination}>
                                <li className={`page-item ${!canPreviousPage ? "disabled" : ''}`}>
                                    <Link to="#" className="page-link" onClick={previousPage}>
                                        <i className="mdi mdi-chevron-left"></i>
                                    </Link>
                                </li>
                                {pageOptions.map((item, key) => (
                                    <React.Fragment key={key}>
                                        <li className={pageIndex === item ? "page-item active" : "page-item"}>
                                            <Link to="#" className="page-link" onClick={() => gotoPage(item)}>{item + 1}</Link>
                                        </li>
                                    </React.Fragment>
                                ))}
                                <li className={`page-item ${!canNextPage ? "disabled" : ''}`}>
                                    <Link to="#" className="page-link" onClick={nextPage}>
                                        <i className="mdi mdi-chevron-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Row>
                )
            }
        </Fragment>
    )
}

RoleTableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default RoleTableContainer
