import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { useSelector } from "react-redux";
import { addActionPlanUser } from '../../../store/aplnfollowup/aplnflwupslice';
import { useDispatch } from 'react-redux';


const ImportUser = (props) => {


    const dispatch = useDispatch()

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [historyPermission, setHistoryPermission] = useState({});
    const [errorValue, setErrorValue] = useState(false)
    const [locationInfo, setLocationInfo] = useState(JSON.parse(sessionStorage.getItem("endpointData")))
    const [sessionUserInfo, setsessionUserInfo] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [acplanRoleInfo, setacplanRoleInfo] = useState([])
    const followUpSlice = useSelector(state => state.acplnFollowUpSliceReducer)
    const [loading, setLoading] = useState(false);

    const location_level_user = locationInfo.location_unique_users.filter(user => user.user_id && sessionUserInfo.user_data._id !== user.user_id);


    const otherUsers = location_level_user.filter(user => user.user_id !== sessionUserInfo.user_data._id);
    const responsible_person = props.selectedApln.task_users;
    const filteredOtherUsers = otherUsers.filter(user => {
        return !responsible_person.some(rp => rp.user_id === user.user_id && rp.user_status === "0")&& user.userstatus !== 0;
    });


    useEffect(() => {
        var roleInfo = JSON.parse(sessionStorage.getItem("authUser")).config_data.action_plan_roles

        var roleInfo = roleInfo.filter((e)=>{
            if(e.id >= followUpSlice.validUser[0].id){
                return e
            }
        })

        setacplanRoleInfo(roleInfo)


    }, [])


    const handleUserSelectionChange = (user, isSelected) => {
        if (isSelected) {
            setSelectedUsers(prevSelectedUsers => [...prevSelectedUsers, user]);
            setHistoryPermission(prevPermission => ({ ...prevPermission, [user.user_id]: '0' }));
        } else {
            setSelectedUsers(prevSelectedUsers => prevSelectedUsers.filter(u => u.user_id !== user.user_id));
            setHistoryPermission(prevPermission => {
                const { [user.user_id]: omit, ...rest } = prevPermission;
                return rest;
            });
        }
    };

    const handleHistoryPermissionChange = (user, isAllowed) => {
        const updatedPermission = isAllowed ? '1' : '0';
        const updatedUser = { ...user, History_permission: updatedPermission };
        const updatedSelectedUsers = selectedUsers.map(selectedUser =>
            selectedUser.user_id === user.user_id ? updatedUser : selectedUser
        );
        setSelectedUsers(updatedSelectedUsers);
        setHistoryPermission(prevPermission => ({ ...prevPermission, [user.user_id]: updatedPermission }));
    };


    const handleSaveClick = async () => {
        // e.preventdefault()
        setLoading(true);
        if (selectedUsers.some(user => !user.role_name)) {
            setErrorValue(true)
        } else {
            setErrorValue(false)
            locationInfo["action_id"] = props.selectedApln.action_id
            const dataobject = {
                selectedUsers: selectedUsers.map(user => ({ ...user, History_permission: historyPermission[user.user_id] })),
                location_info: locationInfo,
                task_id: props.selectedApln._id,
                action_id: props.selectedApln.action_id,
                dbInfo: sessionUserInfo.db_info,
                userdata: sessionUserInfo.user_data
            };
            setLoading(false);
            dispatch(addActionPlanUser(dataobject, props.selectedApln, props.selectedCheckpoint, props.locationInfo));
            props.onClose()

        };

    }



    const assignRoleForUser = (event, userInfo) => {
        setErrorValue(false)
        var getSelectedRole = _.filter(acplanRoleInfo, { id: Number(event.target.value) })
        var selectedUser = [...selectedUsers]
        if (getSelectedRole.length > 0) {
            var getIdx = _.findIndex(selectedUser, { _id: userInfo._id })
            if (getIdx !== -1) {
                selectedUser[getIdx]["id"] = getSelectedRole[0].id
                selectedUser[getIdx]["role_name"] = getSelectedRole[0].role_name
                selectedUser[getIdx]["role_description"] = getSelectedRole[0].role_description
                selectedUser[getIdx]["facilities"] = getSelectedRole[0].facilities
                selectedUser[getIdx]["user_status"] = "0"
                
            }
        }
        setSelectedUsers(selectedUser);

    };



    return (<div className='mt-3'>

        <table className="table" >
            <thead>
                <tr>
                    <th>Select</th>
                    <th>Name</th>
                    {/* <th>Designation</th>
                    <th>Chat History</th>
                    <th>Assign User as</th> */}
                </tr>
            </thead>
            <tbody>
                {filteredOtherUsers.map((user, idx) => (
                    <tr key={idx} >
                        <td width={"70px"}>
                            <input
                                type="checkbox"
                                id={`checkbox_${idx}`}
                                onChange={(e) => handleUserSelectionChange(user, e.target.checked)}
                                checked={selectedUsers.some((selectedUser) => selectedUser.user_id === user.user_id)}
                            />
                        </td>
                        <td>
                            <div className='d-flex flex-column gap-2'>
                                <div>{user.name}</div>
                                {user.designation && <div>{user.designation}</div> }
                                {selectedUsers.some((selectedUser) => selectedUser.user_id === user.user_id) && (
                                    <>
                                        <div style={{}}>

                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <select defaultValue={user?.id == null ? "Select Role" : user.id}
                                                    onChange={(e) => {
                                                        assignRoleForUser(e, user)
                                                    }}
                                                    className='form-select'
                                                    >
                                                    <option disabled value={'Select Role'}>{"Select Role"}</option>
                                                    {
                                                        acplanRoleInfo.map((data, idx) => (
                                                            <option defaultValue={user?.id === data.id} key={idx} value={data.id}>{data.role_name} </option>
                                                        ))
                                                    }

                                                </select>
                                            </div>
                                        </div>

                                        {
                                            errorValue === true &&
                                            <p className='text-danger'><span>*</span> Please Select the Roll</p>
                                        }
                                    </>
                                )}
                                {selectedUsers.some((selectedUser) => selectedUser.user_id === user.user_id) && (
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id={`flexSwitchCheck_${idx}`}
                                            onChange={(e) => handleHistoryPermissionChange(user, e.target.checked)}
                                            checked={historyPermission[user.user_id] === '1'}
                                        />
                                        <label className="form-check-label" htmlFor={`flexSwitchCheck_${idx}`}>
                                            Show conversation history
                                        </label>
                                    </div>
                                )}
                                
                            </div>
                        </td>


                    </tr>
                ))}
            </tbody>
        </table>

        {
            selectedUsers.length !== 0 &&
            <button  className='btn btn-danger mx-2' onClick={handleSaveClick}>
            Import
        </button>
        }

      

    </div>);
}


export default ImportUser;