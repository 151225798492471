import React, { Component } from "react"
import {
  Card, CardBody, Container,
  Row, Col, CardTitle, Table, Button, Badge,
} from "reactstrap"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import facility from '../FacilityScreen'

import HStructure from "./HStructure"
import NewHstructure from "./NewHstructure";
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Breadcrumb

import _ from "lodash";
// var urlSocket = require("../../helpers/urlSocket");
import urlSocket from "../../../helpers/urlSocket";

export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  async componentDidMount() {
    var data = JSON.parse(sessionStorage.getItem("authUser"));
    var hInfo = JSON.parse(sessionStorage.getItem("hInfo"));
    var user_facilities = JSON.parse(sessionStorage.getItem("user_facilities"))


    var protect_routes = await facility(user_facilities , 'hirchy')


    console.log("uniqueNextLevels", hInfo)
    if(protect_routes !== undefined){
    this.setState(
      {
        userInfo: data.user_data,
        hInfo:hInfo,
        dataloaded: true,

      },
      function () {
        //this.getEntityDetaitedData()
      }
    )
    }
    else{
      this.props.history.push('/dashboard')
    }

  }

  gotoBack = () => {
    this.props.history.push('/hirchy')
}


  render() {
      if(this.state.dataloaded){
      return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>AuditVista | Manage Hirerachy</title>
            </MetaTags>
            <Container fluid>

            <Breadcrumbs
                    title={"Hirerachy / "+this.state.hInfo.hname}
                    breadcrumbItem="Roles"
                    isBackButtonEnable={true}
                    gotoBack={() => this.gotoBack()}
                />

              <div>
                <HStructure 
                    hInfo={this.state.hInfo}
                    userInfo={this.state.userInfo}
                    history={this.props.history}
                />

                {/* <NewHstructure
  hInfo={this.state.hInfo}
  userInfo={this.state.userInfo}
  history={this.props.history}/> */}

                
              </div>
            </Container>
          </div>
        </React.Fragment>
      )
      }
      else
      {
          return null
      }
    }
}
